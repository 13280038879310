module.exports = {
  loadingText: 'Build your dream Hyundai',
  packagestitle: "Find Tires, Wheels and Packages for Your Hyundai",
  packagessubtitle: "Whether you are in the market for new tires or you want to give your Hyundai a facelift, use our Tire Finder to find exactly what you need. We carry top brands, a large selection, and packages that provide additional value. Check out our Tire Finder below and if you have any questions don't hesitate to contact your Hyundai dealer who will be happy to assist.",
  bronze: 'Good',
  silver: 'Better',
  gold: 'Best',
  'product': {
    approvedTire: 'Approved Tire',
    approvedTires: 'Approved Tire'
  }
};
import { vehicleSelectionStore } from "../../stores/VehicleSelectionStore.js";
import { vehicleSelectionActions } from "../../actions/VehicleSelectionActions.js";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { applicationActions } from "../../actions/ApplicationActions.js";
import { searchActions } from "../../actions/SearchActions.js";

import { ComboBox } from "../ux/forms/ComboBox.jsx";
import { ComboBoxValue } from "../ux/forms/ComboBoxValue.jsx";

import { SearchTypes } from "../../constants/SearchTypes";
import { ProductTypes } from "../../constants/ProductTypes.js";

import {
  getAppLanguage,
  isEmpty,
  getTrackingEventCategory,
  getTrackingEventObject,
  runTiresizeSearch,
  getAppDealerId,
  isDealerSite,
  openSiteLink,
} from "../../service/UtilityService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var Cx = require("classnames");

export class PanelLauncher extends I18nComponent {
  state = {
    vehicleSearchIsValid: false,
    showVehicleSearchErrorModal: false,
    tireSizeSearchIsValid: false,

    showTireSizeSearchErrorModal: false,
    showPartNumberSearchErrorModal: false,

    year: vehicleSelectionStore.data.year,
    selectedMakeId: null,
    category: vehicleSelectionStore.data.category,
    model: vehicleSelectionStore.data.model,

    vehicleYears: vehicleSelectionStore.data.vehicleYears,
    vehicleMakes: vehicleSelectionStore.data.primaryMakes,
    vehicleSecondaryMakes: vehicleSelectionStore.data.secondaryMakes,
    vehicleModels: vehicleSelectionStore.data.models,

    clickedOnPart: {
      tires: false,
      wheels: false,
      accessories: false,
      packages: false,
    },
    clickedOnTireSearchType: {
      byVehicle: false,
      byTireSize: false,
      byPartNumber: false,
    },

    baseUrl: "",
    trackungCodeShouldRun: false,
    trackingCodeHasRun: false,
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
    vehicleSelectionStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
    vehicleSelectionStore.removeChangeListener(this.onChange);
  }

  shouldComponentUpdate(nextProps, lastState) {
    return this.state !== lastState;
  }

  render() {
    var launcherClass = Cx({
      "tire-wizard-panel-launcher": true,
    });
    var vehicleErrorModalClassName = Cx({
      "widget-vehicle-search-error-modal": true,
      "search-error-modal-hide": !this.state.showVehicleSearchErrorModal,
    });
    var tireSizeErrorModalClassName = Cx({
      "widget-tire-size-search-error-modal": true,
      "search-error-modal-hide":
        !this.state.showTireSizeSearchErrorModal &&
        !this.state.showPartNumberSearchErrorModal,
    });

    return (
      <div className={launcherClass} ref="widget-launcher">
        {applicationStore.data.productTypes.indexOf(ProductTypes.TIRES) > -1
          ? this.renderTireSection()
          : false}
        {applicationStore.data.productTypes.indexOf(ProductTypes.ALLOY_WHEELS) >
        -1
          ? this.renderPartSection(ProductTypes.ALLOY_WHEELS)
          : false}
        {applicationStore.data.productTypes.indexOf(ProductTypes.ACCESSORIES) >
        -1
          ? this.renderPartSection(ProductTypes.ACCESSORIES)
          : false}
        {applicationStore.data.productTypes.indexOf(ProductTypes.PACKAGES) > -1
          ? this.renderPartSection(ProductTypes.PACKAGES)
          : false}

        <div className={vehicleErrorModalClassName}>
          <div className="widget-vehicle-search-error-content">
            <h2 className="widget-vehicle-search-error-title">
              {this.t("launcher.errorTitle")}
            </h2>
            <p className="widget-vehicle-search-error-text">
              {this.t("launcher.vehicleSearchError")}
            </p>
          </div>
          <i
            className="fa fa-close"
            onClick={this.toggleVehicleSearchErrorModal}
          ></i>
        </div>

        <div className={tireSizeErrorModalClassName}>
          <div className="widget-tire-size-search-error-content">
            <h2 className="widget-tire-size-search-error-title">
              {this.t("launcher.errorTitle")}
            </h2>
            <p className="widget-tire-size-search-error-text">
              {this.state.showTireSizeSearchErrorModal
                ? this.t("launcher.tireSizeSearchError")
                : this.t("launcher.partNumberSearchError")}
            </p>
          </div>
          <i
            className="fa fa-close"
            onClick={
              this.state.showTireSizeSearchErrorModal
                ? this.toggleTireSizeSearchErrorModal
                : this.togglePartNumberSearchErrorModal
            }
          ></i>
        </div>
      </div>
    );
  }

  renderTireSection = () => {
    var sectionClass = Cx({
      "search-for-section": true,
      tires: true,
    });
    var sectionSelectedClass = Cx({
      "selection-icon": true,
      "selection-arrow": true,
      tires: true,
      selected: this.state.clickedOnPart.tires,
    });
    var sectionBoxClass = Cx({
      "selection-box-text": true,
      selected: this.state.clickedOnPart.tires,
    });
    var sectionSelectedVehicleClass = Cx({
      "selection-icon": true,
      "selection-arrow": true,
      tires: true,
      selected: this.state.clickedOnTireSearchType.byVehicle,
    });
    var sectionSelectedTiresizeClass = Cx({
      "selection-icon": true,
      "selection-arrow": true,
      tires: true,
      selected: this.state.clickedOnTireSearchType.byTireSize,
    });
    var sectionSelectedPartnumberClass = Cx({
      "selection-icon": true,
      "selection-arrow": true,
      tires: true,
      selected: this.state.clickedOnTireSearchType.byPartNumber,
    });
    return (
      <div className={sectionClass}>
        <div
          className="selection-box"
          onClick={this.onTogglePartPanel.bind(this, ProductTypes.TIRES)}
        >
          <div className={sectionBoxClass}>
            <i className="selection-icon tires" />
            <span className="selection-text">
              {this.t("launcher.searchForTires")}
            </span>
            <i className={sectionSelectedClass} />
          </div>
        </div>

        {this.state.clickedOnPart.tires ? (
          <div className="panel-select-box">
            <div className="search-for-section">
              <div
                className={
                  this.state.clickedOnTireSearchType.byVehicle
                    ? "selection-box tire-box selected"
                    : "selection-box tire-box"
                }
                onClick={this.onToggleTireSearchType.bind(this, "vehicle")}
              >
                <div className="selection-box-text">
                  <i className="selection-icon tires-vehicle" />
                  <span className="selection-text">
                    {this.t("launcher.searchByVehicle")}
                  </span>
                  <i className={sectionSelectedVehicleClass} />
                </div>
              </div>

              {this.state.clickedOnTireSearchType.byVehicle ? (
                <div className="panel-select-box action-box search-by-vehicle">
                  {this.renderVehicleSearch()}
                </div>
              ) : (
                false
              )}
            </div>

            <div className="search-for-section">
              <div
                className={
                  this.state.clickedOnTireSearchType.byTireSize
                    ? "selection-box tire-box selected"
                    : "selection-box tire-box"
                }
                onClick={this.onToggleTireSearchType.bind(this, "tiresize")}
              >
                <div className="selection-box-text">
                  <i className="selection-icon tires-tiresize" />
                  <span className="selection-text">
                    {this.t("launcher.searchByTiresize")}
                  </span>
                  <i className={sectionSelectedTiresizeClass} />
                </div>
              </div>

              {this.state.clickedOnTireSearchType.byTireSize ? (
                <div className="panel-select-box action-box">
                  <div className="vehicle-selection-options">
                    <div className="vehicle-selection-title search-tire-size">
                      <h3>{this.t("launcher.tiresizeSelectionTitle")}</h3>
                    </div>
                    <div className="search-selection">
                      <div className="input-container">
                        <label>
                          {this.t("launcher.tireSize")}
                          <span>{this.t("search.tireSizePlaceholder")}</span>
                        </label>
                        <input
                          type="text"
                          ref="tiresizesearch"
                          onKeyDown={this.openSearchTireSizeLinkOnKeyDown}
                        />
                      </div>
                      <div className="input-container tire-size">
                        <span
                          className="tire-size-helper"
                          onClick={this.toggleTireSizeHelp}
                        >
                          {this.t("findMyTireSize.howTo")}
                        </span>
                      </div>
                    </div>
                    <div className="vehicle-selection-button-search">
                      <button
                        className="btn"
                        onClick={this.openSearchTireSizeLink}
                        onKeyDown={this.openSearchTireSizeLinkOnKeyDown}
                      >
                        {this.t("searchText")}
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                false
              )}
            </div>

            <div className="search-for-section">
              <div
                className={
                  this.state.clickedOnTireSearchType.byPartNumber
                    ? "selection-box tire-box selected"
                    : "selection-box tire-box"
                }
                onClick={this.onToggleTireSearchType.bind(this, "partnumber")}
              >
                <div className="selection-box-text">
                  <i className="selection-icon tires-partnumber" />
                  <span className="selection-text">
                    {this.t("launcher.searchByPartNumber")}
                  </span>
                  <i className={sectionSelectedPartnumberClass} />
                </div>
              </div>

              {this.state.clickedOnTireSearchType.byPartNumber ? (
                <div className="panel-select-box action-box">
                  <div className="vehicle-selection-options">
                    <div className="vehicle-selection-title search-part-number">
                      <h3>{this.t("launcher.partnumberSelectionTitle")}</h3>
                    </div>
                    <div className="search-selection">
                      <div className="input-container">
                        <label>{this.t("launcher.partNumber")}</label>
                        <input
                          type="text"
                          ref="partnumbersearch"
                          placeholder={this.t(
                            "search.placeholderSearchPartNumber"
                          )}
                          onKeyDown={this.openSearchPartNumberLinkOnKeyDown}
                        />
                      </div>
                    </div>
                    <div className="vehicle-selection-button-search extra-padding">
                      <button
                        className="btn"
                        onClick={this.openSearchPartNumberLink}
                        onKeyDown={this.openSearchPartNumberLinkOnKeyDown}
                      >
                        {this.t("searchText")}
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                false
              )}
            </div>
          </div>
        ) : (
          false
        )}
      </div>
    );
  };

  renderPartSection = (type) => {
    var partText = "",
      searchForText = "";
    switch (type) {
      case ProductTypes.ALLOY_WHEELS:
        partText = "wheels";
        searchForText = this.t("launcher.searchForWheels");
        break;
      case ProductTypes.ACCESSORIES:
        partText = "accessories";
        searchForText = this.t("launcher.searchForAccessories");
        break;
      case ProductTypes.PACKAGES:
        partText = "packages";
        searchForText = this.t("launcher.searchForPackages");
        break;
      default:
        break;
    }
    var wheelSelected =
        this.state.clickedOnPart.wheels && type === ProductTypes.ALLOY_WHEELS,
      accessorySelected =
        this.state.clickedOnPart.accessories &&
        type === ProductTypes.ACCESSORIES,
      packageSelected =
        this.state.clickedOnPart.packages && type === ProductTypes.PACKAGES;
    var sectionClass = Cx({
      "search-for-section": true,
      wheels: type === ProductTypes.ALLOY_WHEELS,
      accessories: type === ProductTypes.ACCESSORIES,
      packages: type === ProductTypes.PACKAGES,
    });
    var sectionBoxClass = Cx({
      "selection-box-text": true,
      selected: wheelSelected || accessorySelected || packageSelected,
    });
    var sectionSelectedClass = Cx({
      "selection-icon": true,
      "selection-arrow": true,
      wheels: type === ProductTypes.ALLOY_WHEELS,
      accessories: type === ProductTypes.ACCESSORIES,
      packages: type === ProductTypes.PACKAGES,
      selected: wheelSelected || accessorySelected || packageSelected,
    });
    return (
      <div className={sectionClass}>
        <div
          className="selection-box"
          onClick={this.onTogglePartPanel.bind(this, type)}
        >
          <div className={sectionBoxClass}>
            <i className={"selection-icon " + partText} />
            <span className="selection-text">{searchForText}</span>
            <i className={sectionSelectedClass} />
          </div>
        </div>

        {wheelSelected || accessorySelected || packageSelected ? (
          <div className="panel-select-box action-box">
            {this.renderVehicleSearch()}
          </div>
        ) : (
          false
        )}
      </div>
    );
  };

  renderVehicleSearch = () => {
    var yearList = this.state.vehicleYears,
      modelList = this.state.vehicleModels,
      lang = getAppLanguage();
    if (vehicleSelectionStore.shouldShowSecondaryMakes())
      var makeList = this.state.vehicleMakes.concat(
        this.state.vehicleSecondaryMakes
      );
    else var makeList = this.state.vehicleMakes;
    var year = this.state.year,
      selectedMakeId = this.state.selectedMakeId,
      category = this.state.category,
      model = this.state.model;
    return (
      <div className="vehicle-selection-options">
        <div className="vehicle-selection-title">
          <h3>{this.t("launcher.vehicleSelectionTitle")}</h3>
        </div>
        <div className="vehicle-selection-options-year">
          {yearList.length > 0 ? (
            <ComboBox
              value={year}
              onChange={this.onYearChange}
              scrollBar={false}
              emptyText={this.t("launcher.selectYear")}
              ref="search-modal-select-year"
            >
              {yearList.map(function (year) {
                return (
                  <ComboBoxValue
                    key={"year-alt-" + year["id"]}
                    value={year["id"]}
                  >
                    {year["value"]}
                  </ComboBoxValue>
                );
              })}
            </ComboBox>
          ) : (
            false
          )}
        </div>
        <div className="vehicle-selection-options-make">
          {makeList ? (
            <ComboBox
              value={selectedMakeId}
              onChange={this.onMakeChange}
              scrollBar={true}
              emptyText={this.t("launcher.selectMake")}
              ref="search-modal-select-make"
            >
              {makeList.map(function (make, index) {
                return (
                  <ComboBoxValue
                    key={"make-alt-" + make["id"] + "-" + index}
                    value={make["id"]}
                  >
                    {make["name"]}
                  </ComboBoxValue>
                );
              })}
            </ComboBox>
          ) : (
            false
          )}
        </div>
        <div className="vehicle-selection-options-model">
          {modelList ? (
            <ComboBox
              value={model}
              onChange={this.onModelChange}
              scrollBar={false}
              emptyText={this.t("launcher.selectModel")}
              ref="search-modal-select-model"
            >
              {modelList.map(function (model) {
                return (
                  <ComboBoxValue
                    key={"model-alt-" + model["vehicleModelId"]}
                    value={
                      model["vehicleModelClassId"] +
                      "-" +
                      model["vehicleModelId"]
                    }
                  >
                    {model["vehicleModelClassId"] > 0
                      ? model["vehicleModelClassName"][lang] +
                        " - " +
                        model["vehicleModelName"][lang]
                      : model["vehicleModelName"][lang]}
                  </ComboBoxValue>
                );
              })}
            </ComboBox>
          ) : (
            false
          )}
        </div>
        <div className="vehicle-selection-button-search">
          <button className="btn" onClick={this.submitVehicleSearch}>
            {this.t("searchText")}
          </button>
        </div>
      </div>
    );
  };

  toggleTireSizeHelp = () => {
    applicationActions.onOpenTireSizeHelper();
  };

  submitVehicleSearch = () => {
    var baseUrl = "";
    if (window && window.location && window.location.origin) {
      baseUrl = window.location.origin;
    } else if (document && document.location && document.location.origin) {
      baseUrl = document.location.origin;
    }

    var model = this.state.selectedModel;
    var category = "null";
    if (model.indexOf("-") > -1) {
      model = model.split("-");
      category = model[0];
      model = model[1];
    }

    var partType = "";
    if (this.state.clickedOnPart.tires) partType = ProductTypes.TIRES;
    else if (this.state.clickedOnPart.wheels)
      partType = ProductTypes.ALLOY_WHEELS;
    else if (this.state.clickedOnPart.accessories)
      partType = ProductTypes.ACCESSORIES;
    else if (this.state.clickedOnPart.packages)
      partType = ProductTypes.PACKAGES;

    applicationActions.setProductTypeValue(partType);
    setTimeout(() => {
      searchActions.loadVehicleSimpleSearch(
        this.state.selectedYear,
        this.state.selectedMake,
        category,
        model
      );
    }, 100);

    /*
    if (this.state.selectedYear !== null && this.state.selectedYear !== "null" && this.state.selectedMake !== null && this.state.selectedMake !== "null" && this.state.selectedModel !== null && this.state.selectedModel !== "null" && partType !== null && partType !== "") {
      googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'Panel Launcher - VehicleSearch');
      if (!applicationStore.data.dealerSite) {
        baseUrl = this.state.baseUrl + "/national/" + applicationStore.data.nationalUrlCode;
        if (checkSiteHasCampaign()) baseUrl = this.state.baseUrl + getNationalCampaignBaseUrl(applicationStore.data.nationalUrlCode);
        var link = {
          link: baseUrl + "#/" + getAppLanguage() + "/dealer/" + getAppDealerId() + "/product/" + this.checkProductType(partType) + "/type/vehicle-search/year/" + this.state.selectedYear + "/make/" + this.state.selectedMake + "/category/" + category + "/model/" + model,
          error: null
        };
      } else {
        baseUrl = this.state.baseUrl + "/" + applicationStore.data.dealerUrlCode;
        if (checkSiteHasCampaign()) baseUrl = this.state.baseUrl + getDealerCampaignBaseUrl(applicationStore.data.dealerUrlCode);
        var link = {
          link: baseUrl + "#/" + getAppLanguage() + "/dealer/" + getAppDealerId() + "/product/" + this.checkProductType(partType) + "/type/vehicle-search/year/" + this.state.selectedYear + "/make/" + this.state.selectedMake + "/category/" + category + "/model/" + model,
          error: null
        };
      }
    } else {
      var error = 0;
      var link = {
        link: null,
        error: error
      };
    }
    if (link.link === null && link.error !== null) {
      this.toggleVehicleSearchErrorModal();
    } else {
      window.location.href = link.link;
    }
    */
  };

  checkProductType = (productType) => {
    if (productType === "wheels") return "alloy_wheels";
    else return productType;
  };

  openSearchTireSizeLink = () => {
    var tiresize = this.refs.tiresizesearch.value,
      link = this.getSearchTireSizeLink();
    if (link.link === null) {
      this.toggleTireSizeSearchErrorModal();
    } else {
      if (isDealerSite() || !isEmpty(getAppDealerId())) {
        runTiresizeSearch(
          false,
          SearchTypes.SEARCH_BY_TIRESIZE,
          link,
          tiresize
        );
      } else {
        openSiteLink(link.link);
      }
    }
  };

  openSearchTireSizeLinkOnKeyDown = (evt) => {
    if (evt) {
      checkForEvent: if ("undefined" != typeof evt.keyCode) {
        if (evt.keyCode == 13) {
          evt.preventDefault();
          evt.stopPropagation();
          break checkForEvent;
        }
        return;
      }
    }
    this.openSearchTireSizeLink();
  };

  getSearchTireSizeLink = () => {
    var tiresize = encodeURIComponent(this.refs.tiresizesearch.value);
    if (tiresize) {
      googleAnalyticsEnhancedTrackEvent(
        getTrackingEventCategory(),
        "Panel Launcher - TireSizeSearch"
      );
      if (applicationStore.data.nationalSite) {
        return {
          link:
            this.state.baseUrl +
            "/national/" +
            applicationStore.data.nationalUrlCode +
            "/" +
            getAppLanguage() +
            "/dealer/" +
            getAppDealerId() +
            "/search/tiresize-search/" +
            tiresize,
          error: null,
        };
      } else if (applicationStore.data.dealerSite) {
        return {
          link:
            this.state.baseUrl +
            "/" +
            applicationStore.data.dealerUrlCode +
            "/" +
            getAppLanguage() +
            "/dealer/" +
            getAppDealerId() +
            "/search/tiresize-search/" +
            tiresize,
          error: null,
        };
      } else if (applicationStore.data.groupSite) {
        return {
          link:
            this.state.baseUrl +
            "/group/" +
            applicationStore.data.groupUrlCode +
            "/" +
            getAppLanguage() +
            "/dealer/" +
            getAppDealerId() +
            "/search/tiresize-search/" +
            tiresize,
          error: null,
        };
      }
    } else {
      var error = 0;
      return {
        link: null,
        error: error,
      };
    }
  };

  openSearchPartNumberLink = () => {
    var partNumber = encodeURIComponent(this.refs.partnumbersearch.value);
    var link = this.getSearchPartNumberLink();
    if (link.link === null) {
      this.togglePartNumberSearchErrorModal();
    } else {
      if (isDealerSite() || !isEmpty(getAppDealerId())) {
        runTiresizeSearch(
          false,
          SearchTypes.SEARCH_BY_PARTNUMBER,
          link,
          partNumber
        );
      } else {
        openSiteLink(link.link);
      }

      /*
      window.location.href = link.link;
      window.location.reload();
      //*/
    }
  };

  openSearchPartNumberLinkOnKeyDown = (evt) => {
    if (evt) {
      checkForEvent: if ("undefined" != typeof evt.keyCode) {
        if (evt.keyCode == 13) {
          evt.preventDefault();
          evt.stopPropagation();
          break checkForEvent;
        }
        return;
      }
    }
    this.openSearchPartNumberLink();
  };

  getSearchPartNumberLink = () => {
    var partNumber = encodeURIComponent(this.refs.partnumbersearch.value);
    if (partNumber) {
      googleAnalyticsEnhancedTrackEvent(
        getTrackingEventCategory(),
        "Panel Launcher - PartNumberSearch"
      );
      if (applicationStore.data.nationalSite) {
        return {
          link:
            this.state.baseUrl +
            "/national/" +
            applicationStore.data.nationalUrlCode +
            "/" +
            getAppLanguage() +
            "/dealer/" +
            getAppDealerId() +
            "/search/partnumber-search/" +
            partNumber,
          error: null,
        };
      } else if (applicationStore.data.dealerSite) {
        return {
          link:
            this.state.baseUrl +
            "/" +
            applicationStore.data.dealerUrlCode +
            "/" +
            getAppLanguage() +
            "/dealer/" +
            getAppDealerId() +
            "/search/partnumber-search/" +
            partNumber,
          error: null,
        };
      } else if (applicationStore.data.groupSite) {
        return {
          link:
            this.state.baseUrl +
            "/group/" +
            applicationStore.data.groupUrlCode +
            "/" +
            getAppLanguage() +
            "/dealer/" +
            getAppDealerId() +
            "/search/partnumber-search/" +
            partNumber,
          error: null,
        };
      }
    } else {
      var error = 0;
      return {
        link: null,
        error: error,
      };
    }
  };

  onYearChange = (yearId) => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Panel Launcher - YearSelected",
      getTrackingEventObject({ yearId: yearId })
    );
    var partType = "";
    if (this.state.clickedOnPart.tires) partType = ProductTypes.TIRES;
    else if (this.state.clickedOnPart.wheels)
      partType = ProductTypes.ALLOY_WHEELS;
    else if (this.state.clickedOnPart.accessories)
      partType = ProductTypes.ACCESSORIES;
    else if (this.state.clickedOnPart.packages)
      partType = ProductTypes.PACKAGES;
    this.setState({ selectedYear: yearId });
    setTimeout(() => {
      vehicleSelectionActions.loadMakesForSearchModal(partType, yearId);
      setTimeout(() => {
        var makeSelection = this.refs["search-modal-select-make"];
        if (makeSelection) makeSelection.onToggleComponent();
      }, 10);
    }, 100);
  };

  onMakeChange = (makeId) => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Panel Launcher - ManufacturerSelected",
      getTrackingEventObject({ makeId: makeId })
    );
    var partType = "";
    if (this.state.clickedOnPart.tires) partType = ProductTypes.TIRES;
    else if (this.state.clickedOnPart.wheels)
      partType = ProductTypes.ALLOY_WHEELS;
    else if (this.state.clickedOnPart.accessories)
      partType = ProductTypes.ACCESSORIES;
    else if (this.state.clickedOnPart.packages)
      partType = ProductTypes.PACKAGES;
    this.setState({ selectedMake: makeId });
    setTimeout(() => {
      vehicleSelectionActions.loadModelsForSearchModal(
        partType,
        this.state.selectedYear,
        makeId
      );
      setTimeout(() => {
        var modelSelection = this.refs["search-modal-select-model"];
        if (modelSelection) modelSelection.onToggleComponent();
      }, 10);
    }, 100);
  };

  onModelChange = (modelId) => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Panel Launcher - ModelSelected",
      getTrackingEventObject({ modelId: modelId })
    );
    this.setState({ selectedModel: modelId });
  };

  toggleVehicleSearchErrorModal = () => {
    this.setState({
      showVehicleSearchErrorModal: !this.state.showVehicleSearchErrorModal,
    });
  };

  toggleTireSizeSearchErrorModal = () => {
    this.setState({
      showTireSizeSearchErrorModal: !this.state.showTireSizeSearchErrorModal,
    });
  };

  togglePartNumberSearchErrorModal = () => {
    this.setState({
      showPartNumberSearchErrorModal:
        !this.state.showPartNumberSearchErrorModal,
    });
  };

  onTogglePartPanel = (partType) => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Panel Launcher - PartSelected",
      getTrackingEventObject({ type: partType })
    );
    if (partType === ProductTypes.TIRES) {
      this.setState({
        clickedOnPart: {
          tires: !this.state.clickedOnPart.tires,
          wheels: false,
          accessories: false,
          packages: false,
        },
        clickedOnTireSearchType: {
          byVehicle: false,
          byTireSize: false,
          byPartNumber: false,
        },
      });
    } else if (partType === ProductTypes.ALLOY_WHEELS) {
      if (!this.state.clickedOnPart.wheels) {
        vehicleSelectionActions.resetVehicleSearch();
        vehicleSelectionActions.loadYearsForSearchModal(
          ProductTypes.ALLOY_WHEELS
        );
      }
      this.setState({
        clickedOnPart: {
          tires: false,
          wheels: !this.state.clickedOnPart.wheels,
          accessories: false,
          packages: false,
        },
        clickedOnTireSearchType: {
          byVehicle: false,
          byTireSize: false,
          byPartNumber: false,
        },
      });
    } else if (partType === ProductTypes.ACCESSORIES) {
      if (!this.state.clickedOnPart.accessories) {
        vehicleSelectionActions.resetVehicleSearch();
        vehicleSelectionActions.loadYearsForSearchModal(
          ProductTypes.ACCESSORIES
        );
      }
      this.setState({
        clickedOnPart: {
          tires: false,
          wheels: false,
          accessories: !this.state.clickedOnPart.accessories,
          packages: false,
        },
        clickedOnTireSearchType: {
          byVehicle: false,
          byTireSize: false,
          byPartNumber: false,
        },
      });
    } else if (partType === ProductTypes.PACKAGES) {
      if (!this.state.clickedOnPart.packages) {
        vehicleSelectionActions.resetVehicleSearch();
        vehicleSelectionActions.loadYearsForSearchModal(ProductTypes.PACKAGES);
      }
      this.setState({
        clickedOnPart: {
          tires: false,
          wheels: false,
          accessories: false,
          packages: !this.state.clickedOnPart.packages,
        },
        clickedOnTireSearchType: {
          byVehicle: false,
          byTireSize: false,
          byPartNumber: false,
        },
      });
    }
  };

  onToggleTireSearchType = (searchType) => {
    if (searchType === "vehicle") {
      if (!this.state.clickedOnTireSearchType.byVehicle) {
        vehicleSelectionActions.resetVehicleSearch();
        vehicleSelectionActions.loadYearsForSearchModal(ProductTypes.TIRES);
      }
      this.setState({
        clickedOnTireSearchType: {
          byVehicle: !this.state.clickedOnTireSearchType.byVehicle,
          byTireSize: false,
          byPartNumber: false,
        },
      });
    } else if (searchType === "tiresize")
      this.setState({
        clickedOnTireSearchType: {
          byVehicle: false,
          byTireSize: !this.state.clickedOnTireSearchType.byTireSize,
          byPartNumber: false,
        },
      });
    else if (searchType === "partnumber")
      this.setState({
        clickedOnTireSearchType: {
          byVehicle: false,
          byTireSize: false,
          byPartNumber: !this.state.clickedOnTireSearchType.byPartNumber,
        },
      });
  };

  onChange = () => {
    this.setState({
      year: vehicleSelectionStore.data.year,
      category: vehicleSelectionStore.data.category,
      model: vehicleSelectionStore.data.model,

      vehicleYears: vehicleSelectionStore.data.vehicleYears,
      vehicleMakes: vehicleSelectionStore.data.primaryMakes,
      vehicleSecondaryMakes: vehicleSelectionStore.data.secondaryMakes,
      vehicleModels: vehicleSelectionStore.data.models,
    });
  };
}

import { applicationStore } from '../stores/ApplicationStore.js';
import { languageStore } from '../stores/LanguageStore.js';
import { shoppingCartViewStore } from '../stores/ShoppingCartViewStore.js';

import { ProductTypes } from '../constants/ProductTypes';
import { logoList } from '../constants/PdfHeaderLogo';

import { buildHeader, buildCustomerInformation, buildLegalFooter, getStyles, templateStyle, templateSltClr } from "./PdfService";
import { isEmpty, sizeOf, getAppTemplate, money_format, getAppLanguage } from '../service/UtilityService';

import { getPackageItemTypeInverse } from '../api/RequestBuilderHelper';
import { showMsrpColumn, showSavingsColumn } from "./WishlistService";

var _ = require('lodash');

/*
 *
 *  WISHLIST PDF
 *
 */

export const getWishlistPdfFilename = (orderVId = 0) => {
  var orderId = orderVId, template = applicationStore.data.defaultTemplate.toUpperCase();
  return template + "-#" + orderId + ".pdf";
};

export const getWishlistDocDefinition = (dealerInfo, orderData, tableFooterData, encodedImageList, vehicleTitle, orderId, cartItems) => {
  var hasCustomLogo = !isEmpty(applicationStore.data.dealerRetailSiteCustomLogo) && getAppTemplate() !== 'mazdatransition' ? true : false;
  var logo = !hasCustomLogo ? logoList[getAppTemplate()] : encodedImageList.logo;
  var document = {
    header: buildHeader(dealerInfo),
    content: [
      buildPageTitle(orderId, vehicleTitle),
      buildCustomerInformation(),
      buildPageTable(hasCustomLogo, encodedImageList, cartItems),
      buildPromotionSavings(cartItems),
      buildTableFooter(tableFooterData, cartItems),
      buildFooterOrderInfo(orderData),
      buildLegalFooter()
    ],
    styles: getStyles(),
    defaultStyle: { font: getAppTemplate() },
    images: { logo: logo }
  };

  return (
    document
  );
};

const getCartItemList = (cartItems) => {
  var tireCartList, wheelCartList, accessoryCartList, packageCartList;
  tireCartList = cartItems.get(ProductTypes.TIRES);
  wheelCartList = cartItems.get(ProductTypes.ALLOY_WHEELS);
  accessoryCartList = cartItems.get(ProductTypes.ACCESSORIES);
  packageCartList = cartItems.get(ProductTypes.PACKAGES);

  var itemList = [];
  if (sizeOf(tireCartList) > 0) tireCartList.map(function(cartItem) { itemList.push(getCartItem(cartItem)) });
  if (sizeOf(wheelCartList) > 0) wheelCartList.map(function(cartItem) { itemList.push(getCartItem(cartItem)) });
  if (sizeOf(accessoryCartList) > 0) accessoryCartList.map(function(cartItem) { itemList.push(getCartItem(cartItem)) });
  if (sizeOf(packageCartList) > 0) packageCartList.map(function(cartItem) { itemList.push(getCartItem(cartItem)) });

  return itemList;
};

const getTableBody = (hasCustomLogo, dictionary, encodedImageList, cartItems) => {

  //console.log('getTableBody');
  //console.log(encodedImageList);

  var itemList = getCartItemList(cartItems), selectedColor = templateSltClr(), imageSelector, imageSelectorOffset = 1, body = [];
  for (var i in itemList) {
    i = parseInt(i);

    body.push(getPartRow(dictionary, selectedColor, encodedImageList, itemList[i].itemInfo.id, itemList, i, cartItems));

    if (sizeOf(itemList[i].lineItems.mandatory) > 0) {
      itemList[i].lineItems.mandatory.map(function(lineItem) {
        body.push(getLineItemRow(dictionary, cartItems, lineItem, true));
      });
    }

    if (sizeOf(itemList[i].lineItems.includedOptional) > 0) {
      itemList[i].lineItems.includedOptional.map(function(lineItem) {
        body.push(getLineItemRow(dictionary, cartItems, lineItem, true));
      });
    }

    /*
    if (sizeOf(itemList[i].lineItems.optional) > 0) {
      itemList[i].lineItems.optional.map(function(lineItem) {
        body.push(getLineItemRow(dictionary, itemList lineItem, false));
      });
    }
    */

  }

  body.unshift(buildTableTitleRow(dictionary, cartItems));

  return body;
};

const getCartItem = (cartItem) => {
  var pricing = cartItem.get('pricing'), savings = getSavings(cartItem);
  return (
    {
      itemInfo: {
        type: cartItem.get('type'),
        id: cartItem.get('id'),
        name: { en: cartItem.get('title').get('en'), fr: cartItem.get('title').get('fr') },
        mfnName: cartItem.get('tireManufacturerName'),
        pkgMfnName: cartItem.get('packageManufacturerName'),
        partNumber: cartItem.get('partNumber'),
        ntlPartNumber: cartItem.get('nationalPartNumber'),
        tiresize: cartItem.get('tiresize'),
        diameter: cartItem.get('diameter'),
        load: cartItem.get('loadRating'),
        speed: cartItem.get('speedRating')
      },
      msrp: pricing.get('listPrice'),
      price: pricing.get('displayPrice'),
      displaySavings: pricing.get('displaySavings'),
      privatePrice: pricing.get('privatePrice'),
      showUnitPrice: pricing.get('showUnitPrice'),
      savings: savings,
      total: cartItem.get('price'),
      promotion: {
        hasPromotion: cartItem.get('hasPromotion'),
        id: cartItem.get('promotionId'),
        savings: !isEmpty(cartItem.get('savings')) ? cartItem.get('savings') : "",
        name: { en: cartItem.get('promotionName').get('en'), fr: cartItem.get('promotionName').get('fr') }
      },
      lineItems: {
        items: cartItem.get('lineItems'),
        mandatory: cartItem.get('mandatoryItems'),
        optional: cartItem.get('optionalItems'),
        includedOptional: cartItem.get('includedOptionalItems'),
      },
      listItems: {
        packageItems: cartItem.get('items'),
        mandatory: cartItem.get('mandatoryLineItems')
      },
      quantity: cartItem.get('quantity')
    }
  );
};

const getSavings = (cartItem) => {

  //var pricing = cartItem.get('pricing'), savings = (parseFloat(pricing.get('listPrice')) - parseFloat(pricing.get('displayPrice'))) * parseFloat(cartItem.get('quantity').get('value'));
  var pricing = cartItem.get('pricing'), savings = parseFloat(pricing.get('displaySavings')) * parseFloat(cartItem.get('quantity').get('value'));

  return savings > 0 ? savings : 0;
};

const getPartRow = (dictionary, selectedColor, encodedImageList, imageSelector, itemList, itemSelector, cartItems) => {

  if (showSavingsColumn() && showMsrpColumn()) {
    return (
      [
        buildPartRowImageCell(encodedImageList[imageSelector]),
        buildPartRowCell(getPartInfo(dictionary, itemList[itemSelector]), 10, false, false),
        buildPartRowMSRPCell(itemList[itemSelector].msrp, itemList[itemSelector]),
        buildPartRowPriceCell(dictionary, selectedColor, itemList[itemSelector]),
        buildPartRowCell(itemList[itemSelector].quantity.get('value'), 15, false, true),
        buildPartRowCell(money_format(itemList[itemSelector].savings), 15, true, true),
        buildPartRowCellTotal(money_format(itemList[itemSelector].total), 15, false, true, encodedImageList, itemList[itemSelector].itemInfo.type)
      ]
    );
  } else if (showSavingsColumn()) {
    return (
      [
        buildPartRowImageCell(encodedImageList[imageSelector]),
        buildPartRowCell(getPartInfo(dictionary, itemList[itemSelector]), 10, false, false),
        buildPartRowPriceCell(dictionary, selectedColor, itemList[itemSelector]),
        buildPartRowCell(itemList[itemSelector].quantity.get('value'), 15, false, true),
        buildPartRowCell(money_format(itemList[itemSelector].savings), 15, true, true),
        buildPartRowCellTotal(money_format(itemList[itemSelector].total), 15, false, true, encodedImageList, itemList[itemSelector].itemInfo.type)
      ]
    );
  } else if (showMsrpColumn()) {
    return (
      [
        buildPartRowImageCell(encodedImageList[imageSelector]),
        buildPartRowCell(getPartInfo(dictionary, itemList[itemSelector]), 10, false, false),
        buildPartRowMSRPCell(itemList[itemSelector].msrp, itemList[itemSelector]),
        buildPartRowPriceCell(dictionary, selectedColor, itemList[itemSelector]),
        buildPartRowCell(itemList[itemSelector].quantity.get('value'), 15, false, true),
        buildPartRowCellTotal(money_format(itemList[itemSelector].total), 15, false, true, encodedImageList, itemList[itemSelector].itemInfo.type)
      ]
    );
  } else {
    return (
      [
        buildPartRowImageCell(encodedImageList[imageSelector]),
        buildPartRowCell(getPartInfo(dictionary, itemList[itemSelector]), 10, false, false),
        buildPartRowPriceCell(dictionary, selectedColor, itemList[itemSelector]),
        buildPartRowCell(itemList[itemSelector].quantity.get('value'), 15, false, true),
        buildPartRowCellTotal(money_format(itemList[itemSelector].total), 15, false, true, encodedImageList, itemList[itemSelector].itemInfo.type)
      ]
    );
  }

};

const buildPartRowImageCell = (image) => {

  //console.log('buildPartRowImageCell, image: '+image);

  return (
    {
      margin: [ 0, 3, 0, 1 ],
      width: 65,
      image: image
    }
  );
};

const buildPartRowCell = (text, fontSize, isSavings, alignRight) => {
  return (
    {
      margin: [ 0, 3, 0, 1 ],
      fontSize: fontSize,
      color: isSavings ? templateStyle[getAppTemplate()].selectedColor :  '#000000',
      alignment: alignRight ? 'right' : 'left',
      text: text
    }
  );
};

const buildPartRowMSRPCell = (msrp, item) => {
  return (
    {
      margin: [ 0, 3, 0, 1 ],
      fontSize: 15,
      color: '#000000',
      alignment: 'right',
      text: !item.promotion.hasPromotion && parseFloat(msrp) > 0 ? money_format(msrp) : ""
    }
  );
};

const buildPartRowCellTotal = (text, fontSize, isSavings, alignRight, encodedImageList, type) => {

  //console.log('buildPartRowCellTotal');
  //console.log(encodedImageList);

  if (!isEmpty(encodedImageList.promotionalImage) && type === ProductTypes.TIRES) {

    return (
      [
        {
          stack: [
            {
              margin: [0, 3, 0, 1],
              fontSize: fontSize,
              color: isSavings ? templateStyle[getAppTemplate()].selectedColor : '#000000',
              alignment: alignRight ? 'right' : 'left',
              text: text
            },
            {
              margin: [ 0, 3, 0, 1 ],
              width: 50,
              alignment: 'right',
              image: encodedImageList.promotionalImage
            }
          ]
        }
      ]
    );

  } else {

    return (
      {
        margin: [ 0, 3, 0, 1 ],
        fontSize: fontSize,
        color: isSavings ? templateStyle[getAppTemplate()].selectedColor :  '#000000',
        alignment: alignRight ? 'right' : 'left',
        text: text
      }
    );

  }

};

const buildPartRowPriceCell = (dictionary, selectedColor, item) => {
  var showOriginalPrice = item.promotion.hasPromotion && (item.displaySavings > 0) && item.showUnitPrice ? true : false;
  if (!showOriginalPrice) {
    return { margin: [ 0, 3, 0, 1 ], fontSize: 15, color: '#000000', alignment: 'right', text: money_format(item.price) };
  } else {

    var originalprice = money_format(parseFloat(item.price) + parseFloat(item.displaySavings));

    return (
      [
        {
          stack: [
            { margin: [ 0, 3, 0, 1 ], fontSize: 15, color: '#000000', decoration: 'lineThrough', alignment: 'right', text: originalprice },
            { margin: [ 0, 3, 0, 1 ], fontSize: 15, color: selectedColor, alignment: 'right', text: dictionary.common.now + " " + money_format(item.price) }
          ]
        }
      ]
    );
  }
};

const getLineItemRow = (dictionary, cartItems, listItem, inCart) => {
  var title, price, quantity, total;
  title = listItem.get('title').get(getAppLanguage()) + (listItem.get('description').get(getAppLanguage()) !== "" ? " (" + listItem.get('description').get(getAppLanguage()) + ")" : "");
  price = listItem.get('price') > 0 ? money_format(listItem.get('price') / parseInt(listItem.get('qty'))) : "";
  quantity = inCart ? listItem.get('qty') : "";
  total = inCart ? (listItem.get('price') > 0 ? money_format(parseFloat(listItem.get('price'))) : "") : "";

  if (showSavingsColumn() && showMsrpColumn()) {
    return (
      [
        "",
        buildLineItemCell(inCart, title, false),
        buildLineItemCell(inCart, "", true),
        buildLineItemCell(inCart, price, true),
        buildLineItemCell(inCart, quantity, true),
        buildLineItemCell(inCart, "", true),
        buildLineItemCell(inCart, total, true)
      ]
    );
  } else if (showSavingsColumn()) {
    return (
      [
        "",
        buildLineItemCell(inCart, title, false),
        buildLineItemCell(inCart, price, true),
        buildLineItemCell(inCart, quantity, true),
        buildLineItemCell(inCart, "", true),
        buildLineItemCell(inCart, total, true)
      ]
    );
  } else if (showMsrpColumn()) {
    return (
      [
        "",
        buildLineItemCell(inCart, title, false),
        buildLineItemCell(inCart, "", true),
        buildLineItemCell(inCart, price, true),
        buildLineItemCell(inCart, quantity, true),
        buildLineItemCell(inCart, total, true)
      ]
    );
  } else {
    return (
      [
        "",
        buildLineItemCell(inCart, title, false),
        buildLineItemCell(inCart, price, true),
        buildLineItemCell(inCart, quantity, true),
        buildLineItemCell(inCart, total, true)
      ]
    );
  }

};

const buildLineItemCell = (inCart, text, alignRight) => {
  return (
    {
      margin: [0, 3, 0, 1],
      fontSize: 9,
      color: '#000000',
      decoration: inCart ? '' : 'lineThrough',
      alignment: alignRight ? 'right' : 'left',
      text: text
    }
  );
};

const getPartInfo = (dictionary, item) => {
  var itemInfo = item.itemInfo, name, manufacturer, partNumber, ntlPartNumber, tiresize, loadSpeed, diameter, itemList;
  if (itemInfo.type === ProductTypes.TIRES) {

    name = itemInfo.name[getAppLanguage()] + "\n";
    manufacturer = !isEmpty(itemInfo.mfnName) ? dictionary.brand + ": " + itemInfo.mfnName + "\n" : "";
    partNumber = !isEmpty(itemInfo.partNumber) ? dictionary.product.partNumber + ": " + itemInfo.partNumber + "\n" : "";
    ntlPartNumber = isEmpty(itemInfo.partNumber) && !isEmpty(itemInfo.ntlPartNumber) ? dictionary.product.nationalPartNumber + ": " + itemInfo.ntlPartNumber + "\n" : "";
    tiresize = !isEmpty(itemInfo.tiresize) ? dictionary.product.tireSize + ": " + itemInfo.tiresize + "\n" : "";
    loadSpeed = !isEmpty(itemInfo.load) ? dictionary.product.loadSpeed + ": " + itemInfo.load + " / " + itemInfo.speed + "\n" : "";

    return (
      name +
      manufacturer +
      partNumber +
      ntlPartNumber +
      tiresize +
      loadSpeed
    );

  } else if (itemInfo.type === ProductTypes.ALLOY_WHEELS) {

    name = itemInfo.name[getAppLanguage()] + "\n";
    partNumber = !isEmpty(itemInfo.partNumber) ? dictionary.product.partNumber + ": " + itemInfo.partNumber + "\n" : "";
    ntlPartNumber = isEmpty(itemInfo.partNumber) && !isEmpty(itemInfo.ntlPartNumber) ? dictionary.product.nationalPartNumber + ": " + itemInfo.ntlPartNumber + "\n" : "";
    diameter = !isEmpty(itemInfo.diameter) ? dictionary.product.wheelDiameter + ": " + itemInfo.diameter + "\n" : "";

    return (
      name +
      partNumber +
      ntlPartNumber +
      diameter
    );

  } else if (itemInfo.type === ProductTypes.ACCESSORIES) {

    name = itemInfo.name[getAppLanguage()] + "\n";
    partNumber = !isEmpty(itemInfo.partNumber) ? dictionary.product.partNumber + ": " + itemInfo.partNumber + "\n" : "";
    ntlPartNumber = isEmpty(itemInfo.partNumber) && !isEmpty(itemInfo.ntlPartNumber) ? dictionary.product.nationalPartNumber + ": " + itemInfo.ntlPartNumber + "\n" : "";

    return (
      name +
      partNumber +
      ntlPartNumber
    );

  } else {

    name = itemInfo.name[getAppLanguage()] + "\n";
    partNumber = !isEmpty(itemInfo.partNumber) ? dictionary.product.partNumber + ": " + itemInfo.partNumber + "\n" : "";
    ntlPartNumber = isEmpty(itemInfo.partNumber) && !isEmpty(itemInfo.ntlPartNumber) ? dictionary.product.nationalPartNumber + ": " + itemInfo.ntlPartNumber + "\n" : "";
    itemList = getListItems(dictionary, item.listItems.packageItems);

    return (
      name +
      partNumber +
      ntlPartNumber +
      itemList
    );

  }
};

const getListItems = (dictionary, packageItems) => {
  var itemList = "";
  if (sizeOf(packageItems) > 0) {
    packageItems.map(function (listItem, index) {
      itemList += listItem.get('quantity') + " " + getPackageItemTypeInverse(listItem.get('partType'), getAppLanguage(), listItem.get('typeDescription').get(getAppLanguage())) + " - " + listItem.get('description').get(getAppLanguage()) + " " + dictionary.product.partNumber + ": " + listItem.get('partNumber') + (index < sizeOf(packageItems) ? "\n" : "");
    });
  }
  return itemList;
};

const buildPageTitle = (orderId, vehicleTitle) => {
  var dictionary = languageStore.getDictionary();
  return (
    {
      margin: [ -17.5, 52.5, -17.5, 0 ],
      text: [
        {
          text: dictionary.wishlist.title + " ",
          style: 'title'
        },
        {
          text: "( Order # " + orderId + " )" + " ",
          style: 'titleInfo'
        },
        {
          text: vehicleTitle,
          style: 'titleInfo'
        }
      ]
    }
  );
};

const buildPageTable = (hasCustomLogo, encodedImageList, cartItems) => {
  var dictionary = languageStore.getDictionary(), rowWidthList = getRowWidth(cartItems);
  return (
    {
      style: 'table',
      table: {

        headerRows: 1,
        widths: rowWidthList,
        body: getTableBody(hasCustomLogo, dictionary, encodedImageList, cartItems)

      },
      layout: {
        hLineWidth: function (i, node) {
          return (i === 0 || i === node.table.body.length) ? 2 : 1
        },
        vLineWidth: function () {
          return 0
        },
        hLineColor: function (i, node) {
          return (i === 0 || i === node.table.body.length) ? '#000000' : '#CDCDCD'
        },
        vLineColor: function () {
          return '#FFFFFF'
        },
        fillColor: function (i) {
          return (i % 2 === 1) ?  '#EDEDED' : '#FFFFFF'
        }
      }
    }
  );
};

const getRowWidth = (cartItems) => {

  if (showSavingsColumn() && showMsrpColumn()) {
    return [ 'auto', '*', '*', '*', '*', '*', '*' ];
  } else if (showSavingsColumn()) {
    return [ 'auto', '*', '*', '*', '*', '*' ];
  } else if (showMsrpColumn()) {
    return [ 'auto', '*', '*', '*', '*', '*' ];
  } else {
    return [ 'auto', '*', '*', '*', '*' ];
  }

};

const buildFooterOrderInfo = (orderData) => {
  var dictionary = languageStore.getDictionary();
  return (
    {
      margin: [ -17.5, 20, 0, 0 ],
      stack: [
        {
          text: dictionary.wishlist.personalizedWishlist,
          color: "#000000",
          fontSize: 15
        },
        {
          margin: [ 0, 10, 0, 0 ],
          text: dictionary.wishlist.orderReferemce + orderData.orderId,
          color: "#000000",
          fontSize: 13.5
        },
        {
          margin: [ 0, 10, 0, 0 ],
          text: orderData.dateString + " - " + orderData.time,
          color: "#000000",
          fontSize: 12
        },
        {
          margin: [ 0, 10, 0, 0 ],
          text: !isEmpty(orderData.notes) ? dictionary.quote.noteFieldLabel + ": " + orderData.notes : "",
          color: "#000000",
          fontSize: 12
        }
      ]
    }
  );
};

const buildPromotionSavings = (cartItems) => {
  var dictionary = languageStore.getDictionary(), itemList = getCartItemList(cartItems), promotionList = {}, savings;
  for (var i in itemList) {
    if (itemList[i].promotion.hasPromotion) {
      if (typeof promotionList[itemList[i].promotion.id] === 'undefined') {
        savings = itemList[i].promotion.savings;
        promotionList[itemList[i].promotion.id] = {
          name: itemList[i].promotion.name,
          savings: !isEmpty(savings) ? savings : ""
        };
      } else {
        savings = parseFloat(promotionList[itemList[i].promotion.id].savings) + parseFloat(itemList[i].promotion.savings);
        promotionList[itemList[i].promotion.id].savings = !isEmpty(savings) ? savings : "";
      }
    }
  }

  var promotionObject = [];
  for (i in promotionList) {
    promotionObject.push(
      {
        text: dictionary.quote.savedThroughOnePromotion.replace('{0}', money_format(promotionList[i].savings)).replace('{1}', promotionList[i].name[getAppLanguage()]),
        color: "#000000",
        fontSize: 13
      }
    );
  }

  if (sizeOf(promotionObject) > 0) {
    return (
      {
        margin: [ -17.5, 10, 0, 0 ],
        stack: promotionObject
      }
    );
  } else {
    return ({});
  }
};

const buildTableFooter = (tableFooterData, cartItems) => {
  var dictionary = languageStore.getDictionary(), rowWidthList = getRowWidth(cartItems);

  return (
    {
      style: 'table',
      table: {

        headerRows: 1,
        widths: rowWidthList,
        body: [
          buildTableFooterFirstRow(dictionary, tableFooterData, cartItems),
          buildTableFooterSecondRow(dictionary, tableFooterData, cartItems),
          buildTableFooterThirdRow(dictionary, tableFooterData, cartItems)
        ]

      },
      layout: {
        hLineWidth: function (i, node) {
          return 1
        },
        vLineWidth: function () {
          return 0
        },
        hLineColor: function (i, node) {
          return '#CDCDCD'
        },
        vLineColor: function () {
          return '#FFFFFF'
        },
        fillColor: function (i) {
          return (i % 2 === 1) ?  '#FFFFFF' : '#EDEDED'
        }
      }
    }
  );
};

const buildTableFooterFirstRow = (dictionary, tableFooterData, cartItems) => {

  if (showSavingsColumn() && showMsrpColumn()) {
    return (
      [
        { margin: [0, 5, 0, 3], fontSize: 13, width: 65, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: dictionary.quote.subtotal + ":" },
        { margin: [0, 5, 0, 3], fontSize: 13, alignment: 'right', text: money_format(tableFooterData.subtotal) }
      ]
    );
  } else if (showSavingsColumn()) {
    return (
      [
        { margin: [0, 5, 0, 3], fontSize: 13, width: 65, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: dictionary.quote.subtotal + ":" },
        { margin: [0, 5, 0, 3], fontSize: 13, alignment: 'right', text: money_format(tableFooterData.subtotal) }
      ]
    );
  } else if (showMsrpColumn()) {
    return (
      [
        { margin: [0, 5, 0, 3], fontSize: 13, width: 65, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: dictionary.quote.subtotal + ":" },
        { margin: [0, 5, 0, 3], fontSize: 13, alignment: 'right', text: money_format(tableFooterData.subtotal) }
      ]
    );
  } else {
    return (
      [
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: dictionary.quote.subtotal + ":" },
        { margin: [0, 5, 0, 3], fontSize: 13, alignment: 'right', text: money_format(tableFooterData.subtotal) }
      ]
    );
  }

};

const buildTableFooterSecondRow = (dictionary, tableFooterData, cartItems) => {

  if (showSavingsColumn() && showMsrpColumn()) {
    return (
      [
        { margin: [0, 5, 0, 3], fontSize: 13, width: 65, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: dictionary.quote.tax + " (" + tableFooterData.tax + "%):" },
        { margin: [0, 5, 0, 3], fontSize: 13, alignment: 'right', text: money_format(tableFooterData.taxtotal) }
      ]
    );
  } else if (showSavingsColumn()) {
    return (
      [
        { margin: [0, 5, 0, 3], fontSize: 13, width: 65, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: dictionary.quote.tax + " (" + tableFooterData.tax + "%):" },
        { margin: [0, 5, 0, 3], fontSize: 13, alignment: 'right', text: money_format(tableFooterData.taxtotal) }
      ]
    );
  } else if (showMsrpColumn()) {
    return (
      [
        { margin: [0, 5, 0, 3], fontSize: 13, width: 65, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: dictionary.quote.tax + " (" + tableFooterData.tax + "%):" },
        { margin: [0, 5, 0, 3], fontSize: 13, alignment: 'right', text: money_format(tableFooterData.taxtotal) }
      ]
    );
  } else {
    return (
      [
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: dictionary.quote.tax + " (" + tableFooterData.tax + "%):" },
        { margin: [0, 5, 0, 3], fontSize: 13, alignment: 'right', text: money_format(tableFooterData.taxtotal) }
      ]
    );
  }

};

const buildTableFooterThirdRow = (dictionary, tableFooterData, cartItems) => {

  if (showSavingsColumn() && showMsrpColumn()) {
    return (
      [
        { margin: [0, 5, 0, 3], fontSize: 13, width: 65, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: dictionary.quote.total + ":" },
        { margin: [0, 5, 0, 3], fontSize: 13, alignment: 'right', text: money_format(tableFooterData.total) }
      ]
    );
  } else if (showSavingsColumn()) {
    return (
      [
        { margin: [0, 5, 0, 3], fontSize: 13, width: 65, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: dictionary.quote.total + ":" },
        { margin: [0, 5, 0, 3], fontSize: 13, alignment: 'right', text: money_format(tableFooterData.total) }
      ]
    );
  } else if (showMsrpColumn()) {
    return (
      [
        { margin: [0, 5, 0, 3], fontSize: 13, width: 65, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: dictionary.quote.total + ":" },
        { margin: [0, 5, 0, 3], fontSize: 13, alignment: 'right', text: money_format(tableFooterData.total) }
      ]
    );
  } else {
    return (
      [
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: "" },
        { margin: [0, 5, 0, 3], fontSize: 13, text: dictionary.quote.total + ":" },
        { margin: [0, 5, 0, 3], fontSize: 13, alignment: 'right', text: money_format(tableFooterData.total) }
      ]
    );
  }

};

const buildTableTitleRow = (dictionary, cartItems) => {

  if (showSavingsColumn() && showMsrpColumn()) {
    return (
      [
        '',
        { margin: [0, 5, 0, 3], fontSize: 18, text: dictionary.wishlist.item },
        { margin: [0, 5, 0, 3], fontSize: 18, alignment: 'right', text: dictionary.wishlist.msrp },
        { margin: [0, 5, 0, 3], fontSize: 18, alignment: 'right', text: dictionary.product.retailPrice },
        { margin: [0, 5, 0, 3], fontSize: 18, alignment: 'right', text: dictionary.wishlist.quantity },
        { margin: [0, 5, 0, 3], fontSize: 18, alignment: 'right', text: dictionary.wishlist.youSave },
        { margin: [0, 5, 0, 3], fontSize: 18, alignment: 'right', text: dictionary.wishlist.total }
      ]
    );
  } else if (showSavingsColumn()) {
    return (
      [
        '',
        { margin: [0, 5, 0, 3], fontSize: 18, text: dictionary.wishlist.item },
        { margin: [0, 5, 0, 3], fontSize: 18, alignment: 'right', text: dictionary.product.retailPrice },
        { margin: [0, 5, 0, 3], fontSize: 18, alignment: 'right', text: dictionary.wishlist.quantity },
        { margin: [0, 5, 0, 3], fontSize: 18, alignment: 'right', text: dictionary.wishlist.youSave },
        { margin: [0, 5, 0, 3], fontSize: 18, alignment: 'right', text: dictionary.wishlist.total }
      ]
    );
  } else if (showMsrpColumn()) {
    return (
      [
        '',
        { margin: [0, 5, 0, 3], fontSize: 18, text: dictionary.wishlist.item },
        { margin: [0, 5, 0, 3], fontSize: 18, alignment: 'right', text: dictionary.wishlist.msrp },
        { margin: [0, 5, 0, 3], fontSize: 18, alignment: 'right', text: dictionary.product.retailPrice },
        { margin: [0, 5, 0, 3], fontSize: 18, alignment: 'right', text: dictionary.wishlist.quantity },
        { margin: [0, 5, 0, 3], fontSize: 18, alignment: 'right', text: dictionary.wishlist.total }
      ]
    );
  } else {
    return (
      [
        '',
        { margin: [0, 5, 0, 3], fontSize: 18, text: dictionary.wishlist.item },
        { margin: [0, 5, 0, 3], fontSize: 18, alignment: 'right', text: dictionary.product.retailPrice },
        { margin: [0, 5, 0, 3], fontSize: 18, alignment: 'right', text: dictionary.wishlist.quantity },
        { margin: [0, 5, 0, 3], fontSize: 18, alignment: 'right', text: dictionary.wishlist.total }
      ]
    );
  }

};
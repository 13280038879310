module.exports = {
    'product': {
        'contactDealerForPricing': 'Contactez le concessionnaire pour les prix',
        'requiresOtherItems': 'Autres éléments requis',
        'requiredItems': 'Éléments requis',
        'price': 'Prix',
        'partNumber': 'No. de pièce',
        'youSave': 'Vous économisez',
        'carColour': 'Couleur de votre véhicule',
        'wheelDiameter': 'Diamètre',
        'accessoryCategories': 'Catégories d\'accessoires',
        'collectionCategories': 'Collection categories',
        'tireSize': 'Taille du pneu',
        'tireSizeAndSeason': 'Taille du pneu et Saison',
        'tireBrands': 'Marques de pneus',
        'loadRating': 'Indice de charge',
        'speedRating': 'Indice de vitesse',
        'loadAndSpeedRating': 'Indice de charge (minimum)',
        'quantity': 'Quantité',
        'total': 'Total',
        'requiresInstallation': 'Installation requise',
        'perTire': 'par pneu',
        'perFrontTire': 'par pneu avant',
        'perRearTire': 'par pneu arrière',
        'perWheel': 'par roue',
        'perFrontWheel': 'par roue avant',
        'perRearWheel': 'par roue arrière',
        'perPackage': 'par ensemble',
        'perItem': 'par article',
        'inStock': 'en stock',
        'categories': 'Cetégorie',
        'pricingNotes': {
            'title': 'Remarques sur les prix',
            'description': 'Les frais environnementaux peuvent s\'appliquer. Les frais d\'installation sont vendus séparément. Des frais supplémentaires peuvent s\'appliquer.'
        },
        'wheelFinishTypes': {
            'alloy': 'Alliage',
            'steel': 'Acier'
        },
        'addToQuote': 'Ajouter au panier',
        'removeFromQuote': 'Enlever l\'article',
        'appointment': 'Rendez-vous',
        'front': 'Avant',
        'rear': 'Arrière',
        'loadSpeed': 'Spécification',
        'tire': 'Pneu',
        'rimDiameter': 'Diamètre de jante',
        'wheelFinish': 'Finition de la roue',
        'details': 'Détails',
        'approvedTire': 'PneusApprovés',
        'frontAndRearWheel': 'Roues avant et arrière',
        'frontWheel': 'Roue avant',
        'rearWheel': 'Roue arrière',
        'frontTire': 'Pneu avant',
        'rearTire': 'Pneu arrière',
        'staggeredFittment': 'Staggered Fitment',
        'selectAccessoryCategory': 'Toutes Catégories',
        'requestAppointment': 'Contacter le concessionnaire',
        'runFlat': 'Run flat tire',
        'filters': {
            'filterResults': 'Affichage des résultats pour',
            'brand': {
                'noSelection': 'Sélectionnez une marque',
                'allSelected': 'Toutes les marques',
                'otherSelected': 'Marques',
                'selectAll': 'Tout sélectionnez',
                'deselectAll': 'Tout déselectionnez'
            },
            'season': {
                'noSelection': 'Sélectionnez saison',
                'allSeason': 'Toute saison',
                'winter': 'Hiver',
                'summer': 'Été'
            },
            'loadRating': {
                'noSelection': 'Sélectionnez l\'indice de charger'
            },
            'tireSize': {
                'noSelection': 'Sélectionnez le taille des pneus'
            },
            'speedRating': {
                'noSelection': 'Sélectionnez l\'indice de vitesse'
            },
            'plyRating': {
                'noSelection': 'Sélectionnez l\'indice de capacité'
            },
            'diameter': {
                'noSelection': 'Sélectionnez diamèter',
                'original': 'Grandeur originale:',
                'inchDiameter': 'pouces en diamèter'
            }
        }
    }
};

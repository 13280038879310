import { breadcrumbActions } from "../../actions/BreadcrumbActions.js";
import { vehicleSelectionActions } from "../../actions/VehicleSelectionActions.js";
import { languageStore } from "../../stores/LanguageStore.js";
import { vehicleSelectionStore } from "../../stores/VehicleSelectionStore.js";

import {
  getTabIndex,
  scrollToPageTop,
  getTrackingEventCategory,
} from "../../service/UtilityService";

import { RouterStates } from "../../constants/RouterStates.js";

var _ = require("lodash");
var React = require("react");

class VehicleSelectionCategories extends React.Component {
  state = {
    categories: [],
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("Vehicle Select Category");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Vehicle Select Category Access"
    );
    vehicleSelectionStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    vehicleSelectionStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    //setTimeout(() => { vehicleSelectionActions.loadCategories(); }, 250);
    vehicleSelectionActions.loadCategories();
  }

  componentDidUpdate(lastProps, lastState) {
    scrollToPageTop(this.state.categories, lastState.categories);
  }

  render() {
    return (
      <div className="main">
        <div className="container">
          <ul className="select-simple-2-lines">
            {_.map(
              this.state.categories,
              function (category, index) {
                return (
                  <li
                    key={category.vehicleCategoryId}
                    tabIndex={getTabIndex()}
                    onClick={this.onCategoryClick.bind(
                      this,
                      category.vehicleCategoryId,
                      category.vehicleCategoryName
                    )}
                    onKeyDown={(evt) =>
                      this.onCategoryClick(
                        category.vehicleCategoryId,
                        category.vehicleCategoryName,
                        evt
                      )
                    }
                  >
                    <a>
                      {languageStore.getTranslated(
                        category.vehicleCategoryName
                      )}
                    </a>
                  </li>
                );
              }.bind(this)
            )}
          </ul>
        </div>
      </div>
    );
  }

  onChange = () => {
    this.setState({
      categories: vehicleSelectionStore.data.categories,
    });
  };

  onCategoryClick = (categoryId, category, evt) => {
    checkForEvent: if ("undefined" != typeof evt.keyCode) {
      if (evt.keyCode == 13 || evt.keyCode == 32) {
        evt.preventDefault();
        evt.stopPropagation();
        break checkForEvent;
      }
      return;
    }
    vehicleSelectionActions.categorySelected(categoryId);
    breadcrumbActions.pushState(
      RouterStates.CATEGORY,
      categoryId,
      category,
      false,
      true
    );
  };
}

export default VehicleSelectionCategories;


export const PricingFilterPackage = {
  0: { min: 0, max: 99.99 },
  1: { min: 100, max: 199.99 },
  2: { min: 200, max: 299.99 },
  3: { min: 300, max: 399.99 },
  4: { min: 400, max: 499.99 },
  5: { min: 500, max: 599.99 },
  6: { min: 600, max: 699.99 },
  7: { min: 700, max: 799.99 },
  8: { min: 800, max: null }
};

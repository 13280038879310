import { applicationStore } from "../stores/ApplicationStore.js";
import { ProductTypes } from "../constants/ProductTypes.js";

import { getOrderIdentifier } from "../service/OrderService";

const CONTACT_EMAIL = "email";
const CONTACT_PHONE = "phone";

export var getDestination = function (productType) {
  switch (productType?.toUpperCase()) {
    case ProductTypes.TIRES:
      return "TRE";
    case ProductTypes.ALLOY_WHEELS:
      return "WHL";
    case ProductTypes.ACCESSORIES:
      return "ACC";
    case ProductTypes.PACKAGES:
      return "PKG";
    default:
      throw new Error("Missing product type " + productType);
  }
};

export var getDestinationInverse = function (productType) {
  switch (productType) {
    case "TRE":
      return ProductTypes.TIRES;
    case "WHL":
      return ProductTypes.ALLOY_WHEELS;
    case "ACC":
      return ProductTypes.ACCESSORIES;
    case "PKG":
      return ProductTypes.PACKAGES;
    default:
      throw new Error("Missing product type " + productType);
  }
};

export var getPackageItemTypeInverse = function (
  productType,
  language,
  typeDescription
) {
  if (typeof productType !== "undefined") {
    switch (productType) {
      case "TRE":
        if (language === "fr") {
          return "Pneus";
        } else {
          return "Tires";
        }
      case "WHL":
        if (language === "fr") {
          return "Jantes";
        } else {
          return "Wheels";
        }
      case "ACC":
        if (language === "fr") {
          return "Accessoires";
        } else {
          return "Accessories";
        }
      case "PKG":
        if (language === "fr") {
          return "Ensembles";
        } else {
          return "Packages";
        }
      case "MCH":
        if (language === "fr") {
          return "Collections";
        } else {
          return "Collections";
        }
      case "CTM":
        return typeDescription;
      default:
        return null;
    }
  } else {
    return false;
  }
};

export function getSearch(
  dealerId,
  tireSize,
  season,
  loadRating,
  speedIndex,
  brands,
  originalEquipmentFlag
) {
  const request = {
    dealerId: dealerId,
    tireSize: tireSize,
    season: season,
    originalEquipmentFilter: originalEquipmentFlag,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };

  if (loadRating) {
    request.loadRating = loadRating;
  }

  if (speedIndex) {
    request.speedRating = speedIndex;
  }

  if (brands && brands.length > 0) {
    request.tireManufacturerIds = brands;
  }

  return request;
}

export function getSecondaryMakes(productType, yearId) {
  return {
    vehicleYearId: yearId,
    partType: getDestination(productType),
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function getBreadcrumbs(applicationState) {
  var request = {
    isNational: applicationState.isNational,
  };
  if (applicationState.year) {
    request.vehicleYearId = applicationState.year;
  }
  if (applicationState.make) {
    request.vehicleManufacturerId = applicationState.make;
  }
  if (applicationState.category) {
    request.vehicleCategoryId = applicationState.category;
  }
  if (applicationState.model) {
    request.vehicleModelId = applicationState.model;
  }
  if (applicationState.trim) {
    request.vehicleSubmodelId = applicationState.trim;
  }
  if (applicationState.dealerId) {
    request.dealerId = applicationState.dealerId;
  }

  return request;
}

export function getVehicleInfo(yearId, makeId, modelId, trimId, dealerId) {
  return {
    vehicleManufacturerId: makeId,
    vehicleYearId: yearId,
    vehicleModelId: modelId,
    vehicleSubmodelId: trimId,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
    dealerId: dealerId,
  };
}

export function getTires(
  dealerId,
  vehicleId,
  brand,
  season,
  tireSize,
  loadRating,
  speedRating,
  secondaryLoadRating,
  secondarySpeedIndex,
  staggered,
  plyRating,
  originalEquipmentFlag,
  failOverType
) {
  var request = {
    dealerId: dealerId,
    vehicleId: vehicleId,
    vehicleSubmodelTireSizeId: tireSize,
    season: season,
    originalEquipmentFilter: originalEquipmentFlag,
    searchFailoverMethod: failOverType,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };

  if (brand && brand.length > 0) {
    request.tireManufacturerIds = brand;
  }

  if (loadRating) {
    request.loadRating = loadRating;
  }

  if (speedRating) {
    request.speedRating = speedRating;
  }

  if (secondaryLoadRating && staggered) {
    request.secondary_load_rating = secondaryLoadRating;
  }

  if (secondarySpeedIndex && staggered) {
    request.secondary_speed_index = secondarySpeedIndex;
  }

  if (plyRating) {
    request.plyRating = plyRating;
  }

  return request;
}

export function getTiresFromModel(
  dealerId,
  yearId,
  modelId,
  originalEquipmentFlag,
  season,
  vehicleSubmodelTireSizeId,
  failOverType
) {
  var request = {
    dealerId: dealerId,
    vehicleYearId: yearId,
    vehicleModelId: modelId,
    originalEquipmentFilter: originalEquipmentFlag,
    season: season,
    vehicleSubmodelTireSizeId: vehicleSubmodelTireSizeId,
    searchFailoverMethod: failOverType,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };

  return request;
}

export function getTiresFromChassis(
  dealerId,
  search,
  originalEquipmentFlag,
  season,
  vehicleSubmodelTireSizeId,
  failOverType
) {
  var request = {
    dealerId: dealerId,
    vehicleChassis: search,
    originalEquipmentFilter: originalEquipmentFlag,
    season: season,
    vehicleSubmodelTireSizeId: vehicleSubmodelTireSizeId,
    searchFailoverMethod: failOverType,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };

  return request;
}

export function getWheels(dealerId, vehicleId) {
  var request = {
    dealerId: dealerId,
    vehicleId: vehicleId,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };

  return request;
}

export function getCollections(dealerId, categoryId, categories) {
  var request = {
    dealerId: dealerId,
    categoryId: categoryId,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };

  if (categories && categories.length > 0) {
    request.categoryIdFilter = categories.join(",");
  }

  return request;
}

export function getAccessories(dealerId, vehicleId) {
  var request = {
    dealerId: dealerId,
    vehicleId: vehicleId,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };

  return request;
}

export function getPackages(dealerId, vehicleId) {
  return {
    dealerId: dealerId,
    vehicleId: vehicleId,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function getFeaturedTires(dealerId, vehicleId, season, tireSize) {
  var request = {
    dealerId: dealerId,
    vehicleId: vehicleId,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };

  if (season !== null) {
    request.season = season;
  }

  if (tireSize) {
    request.vehicleSubmodelTiresizeId = tireSize;
  }

  return request;
}

export function getFeaturedTiresByTiresize(dealerId, tireSize, season) {
  var request = {
    dealerId: dealerId,
    tiresize: tireSize,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };

  if (season !== null) {
    request.season = season;
  }

  return request;
}

export function getAuth(
  username,
  password,
  dealerId,
  nationalAccountId,
  rememberMe,
  userIpAddress
) {
  var authRequest = {
    username: username,
    password: password,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };

  if (dealerId) {
    authRequest.dealerId = dealerId;
  } else {
    authRequest.nationalAccountId = nationalAccountId;
  }

  if (rememberMe) {
    authRequest.rememberMe = true;
  }

  if (userIpAddress) {
    authRequest.userIpAddress = userIpAddress;
  }

  if (applicationStore.data.dealerUrlCode) {
    authRequest.dealerUrlCode = applicationStore.data.dealerUrlCode;
  }

  if (applicationStore.data.nationalUrlCode) {
    authRequest.nationalAccountCode = applicationStore.data.nationalUrlCode;
  }

  if (applicationStore.data.groupUrlCode) {
    authRequest.dealerGroupCode = applicationStore.data.groupUrlCode;
  }

  return authRequest;
}

export function getAuthDealer(dealerId, dealerUrlCode, userIpAddress) {
  var authRequest = {
    username: "",
    password: "",
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };

  if (dealerId) {
    authRequest.dealerId = dealerId;
  }

  if (userIpAddress) {
    authRequest.userIpAddress = userIpAddress;
  }

  if (dealerUrlCode) {
    authRequest.dealerUrlCode = dealerUrlCode;
  }

  return authRequest;
}

export function guestAccountCreation(
  dealerId,
  dealerUrlCode,
  emailAddress,
  password,
  passwordConfirm
) {
  var authRequest = {
    dealerId: dealerId,
    dealerUrlCode: dealerUrlCode,
    email: emailAddress,
    password: password,
    passwordConfirm: passwordConfirm,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };

  return authRequest;
}

export function forgotPassword(lang, emailAddress) {
  var authRequest = {
    customerLanguage: lang,
    email: emailAddress,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };

  return authRequest;
}

export function getCartContents(dealerId, cartId, fromWishlist) {
  return {
    dealerId: dealerId,
    cartId: cartId,
    orderNumber: getOrderIdentifier(),
    markAsQuote: fromWishlist,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function addItemToCart(
  dealerId,
  cartId,
  productId,
  productType,
  quantity,
  vehicleId,
  optionId,
  pricingContextId
) {
  var request = {
    dealerId: dealerId,
    cartId: cartId,
    orderNumber: getOrderIdentifier(),
    itemId: productId,
    itemType: getDestination(productType),
    itemQty: quantity,
    vehicleId: vehicleId,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };

  if (optionId) {
    request.optionId = optionId;
  }

  if (pricingContextId) {
    request.pricingContextId = pricingContextId;
  }

  return request;
}

export function addLineItemToCart(
  dealerId,
  cartId,
  cartItemId,
  lineItemId,
  lineItemPrice,
  lineItemWorkOrderNumber,
  lineItemNotes
) {
  return {
    dealerId: dealerId,
    cartId: cartId,
    orderNumber: getOrderIdentifier(),
    cartItemId: cartItemId,
    lineItemId: lineItemId,
    lineItemPrice: lineItemPrice,
    lineItemWorkOrderNumber: lineItemWorkOrderNumber,
    lineItemNotes: lineItemNotes,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function removeItemFromCart(dealerId, cartId, cartItemId) {
  return {
    dealerId: dealerId,
    cartId: cartId,
    orderNumber: getOrderIdentifier(),
    cartItemId: cartItemId,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

/*
 export function removeItemFromCart(dealerId, cartId, cartItemId) {
 return {
 dealerId: dealerId,
 cartId: cartId,
 cartItemId: cartItemId
 };
 }
 */

export function removeLineItemFromCart(
  dealerId,
  cartId,
  cartItemId,
  lineItemId
) {
  return {
    dealerId: dealerId,
    cartId: cartId,
    orderNumber: getOrderIdentifier(),
    cartItemId: cartItemId,
    lineItemId: lineItemId,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function generateQuote(dealerId, cartId, vehicleId) {
  var request = {
    dealerId: dealerId,
    cartId: cartId,
    orderNumber: getOrderIdentifier(),
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };

  if (vehicleId) {
    request.vehicleId = vehicleId;
  }

  return request;
}

export function getQuote(dealerId, quoteId) {
  return {
    dealerId: dealerId,
    quoteNumber: quoteId,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function addLineItemToQuote(dealerId, quoteId, itemId, lineItemId) {
  return {
    dealerId: dealerId,
    quoteNumber: quoteId,
    quoteItemId: itemId,
    lineItemId: lineItemId,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function removeLineItemFromQuote(dealerId, quoteId, itemId, lineItemId) {
  return {
    dealerId: dealerId,
    quoteNumber: quoteId,
    quoteItemId: itemId,
    lineItemId: lineItemId,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

// export function saveQuote(dealerId, quoteId) {
// 	return {
// 		dealerId: dealerId,
// 		quoteNumber: quoteId,
// 		isNational: applicationStore.data.nationalSite ? 1 : 0
// 	};
// }

export function printQuote(dealerId, quoteId) {
  return {
    dealerId: dealerId,
    quoteNumber: quoteId,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function emailQuote(
  dealerId,
  quoteId,
  name,
  email,
  language,
  phone,
  note,
  contactType
) {
  return {
    dealerId: dealerId,
    quoteNumber: quoteId,
    customerName: name,
    customerEmail: email,
    customerLanguage: language,
    customerPhone: phone,
    notes: note,
    customerContactMethodPreference: contactType === CONTACT_EMAIL ? 0 : 1,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function transferQuoteToEmail(
  dealerId,
  cartId,
  name,
  email,
  language,
  phone,
  note,
  contactType
) {
  return {
    dealerId: dealerId,
    cartId: cartId,
    orderNumber: getOrderIdentifier(),
    customerName: name,
    customerEmail: email,
    customerLanguage: language,
    customerPhone: phone,
    notes: note,
    customerContactMethodPreference: contactType === CONTACT_EMAIL ? 0 : 1,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function transferQuoteToAppointment(
  dealerId,
  cartId,
  name,
  email,
  language,
  phone,
  date,
  time,
  note,
  contactType
) {
  return {
    dealerId: dealerId,
    cartId: cartId,
    orderNumber: getOrderIdentifier(),
    customerName: name,
    customerEmail: email,
    customerLanguage: language,
    customerPhone: phone,
    appointmentRequestDate: date,
    appointmentRequestTime: time,
    notes: note,
    customerContactMethodPreference: contactType === CONTACT_EMAIL ? 0 : 1,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function transferQuoteToOrderLead(
  dealerId,
  cartId,
  name,
  email,
  language,
  phone,
  date,
  time,
  note,
  contactType
) {
  return {
    targetDealerId: dealerId,
    cartId: cartId,
    customerName: name,
    customerEmail: email,
    customerLanguage: language,
    customerPhone: phone,
    appointmentRequestDate: date,
    appointmentRequestTime: time,
    notes: note,
    customerContactMethodPreference: contactType === CONTACT_EMAIL ? 0 : 1,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function addItemToCartView(
  dealerId,
  cartId,
  itemId,
  itemQty,
  vehicleId,
  pricingContextId
) {
  var request = {
    dealerId: dealerId,
    cartId: cartId,
    orderNumber: getOrderIdentifier(),
    cartItemId: itemId,
    itemQty: itemQty,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };

  if (vehicleId) {
    request.vehicleId = vehicleId;
  }

  if (pricingContextId) {
    request.pricingContextId = pricingContextId;
  }

  return request;
}

export function updateItemInCartView(
  dealerId,
  cartId,
  itemId,
  optionId,
  itemQty,
  vehicleId,
  pricingContextId
) {
  var request = {
    dealerId: dealerId,
    cartId: cartId,
    orderNumber: getOrderIdentifier(),
    cartItemId: itemId,
    itemQty: itemQty,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };

  if (optionId) {
    request.optionId = optionId;
  }

  if (vehicleId) {
    request.vehicleId = vehicleId;
  }

  if (pricingContextId) {
    request.pricingContextId = pricingContextId;
  }

  return request;
}

export function removeItemFromCartView(dealerId, cartId, cartItemId) {
  return {
    dealerId: dealerId,
    cartId: cartId,
    orderNumber: getOrderIdentifier(),
    cartItemId: cartItemId,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function generateFoxyCartFromCart(dealerId, cartId, customerLanguage) {
  return {
    dealerId: dealerId,
    cartId: cartId,
    orderNumber: getOrderIdentifier(),
    customerLanguage: customerLanguage,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function getCanadaPostShippingEstimateForOrder(
  callback,
  cartId,
  customerPostalCode,
  customerLanguage
) {
  return {
    callback: callback,
    cartId: cartId,
    orderNumber: getOrderIdentifier(),
    customerPostalCode: customerPostalCode,
    customerLanguage: customerLanguage,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function generateCommerceFromCart(cartId) {
  return {
    cartId: cartId,
    orderNumber: getOrderIdentifier(),
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function getCommerce(commerceId) {
  return {
    commerceId: commerceId,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function clearCart(dealerId, cartId) {
  return {
    dealerId: dealerId,
    cartId: cartId,
    orderNumber: getOrderIdentifier(),
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function printCart(dealerId, cartId) {
  return {
    dealerId: dealerId,
    cartId: cartId,
    orderNumber: getOrderIdentifier(),
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function appointmentQuote(
  dealerId,
  quoteId,
  name,
  email,
  language,
  phone,
  date,
  time,
  note,
  contactType
) {
  return {
    dealerId: dealerId,
    quoteNumber: quoteId,
    customerName: name,
    customerEmail: email,
    customerLanguage: language,
    customerPhone: phone,
    appointmentRequestDate: date,
    appointmentRequestTime: time,
    notes: note,
    customerContactMethodPreference: contactType === CONTACT_EMAIL ? 0 : 1,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function saveQuote(
  dealerId,
  quoteId,
  name,
  email,
  phone,
  date,
  time,
  note,
  contactType
) {
  return {
    dealerId: dealerId,
    quoteNumber: quoteId,
    customerName: name,
    customerEmail: email,
    customerPhone: phone,
    appointmentRequestDate: date,
    appointmentRequestTime: time,
    notes: note,
    customerContactMethodPreference: contactType === CONTACT_EMAIL ? 0 : 1,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function appointmentTransfer(
  cartId,
  regionalDealerId,
  transferDealerId,
  vehicleId,
  language,
  name,
  email,
  phone,
  date,
  time,
  note
) {
  return {
    cartId: cartId,
    orderNumber: getOrderIdentifier(),
    regionalDealerId: regionalDealerId,
    transferDealerId: transferDealerId,
    vehicleId: vehicleId,
    customerName: name,
    customerEmail: email,
    customerLanguage: language,
    customerPhone: phone,
    appointmentRequestDate: date,
    appointmentRequestTime: time,
    notes: note,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

export function appointmentForm(
  name,
  email,
  phone,
  contactType,
  message,
  lang,
  vehicleId,
  itemId,
  itemType,
  dealerSite,
  dealerId
) {
  var payload = {
    isNational: !dealerSite,
    dealerId: dealerId,
    vehicleId: vehicleId,
    customerName: name,
    customerEmail: email,
    customerPhone: phone,
    customerContactMethodPreference: contactType === CONTACT_EMAIL ? 0 : 1,
    customerCurrentLanguage: lang,
  };

  if (message) {
    payload.customerMessage = message;
  }

  if (itemId) {
    payload.itemId = itemId;
  }

  if (itemType) {
    payload.itemType = getDestination(itemType);
  }

  return payload;
}

export function emailPromotion(
  dealerId,
  itemType,
  itemId,
  pricingContextId,
  vehicleId,
  lang,
  customerEmail
) {
  var payload = {
    dealerId: dealerId,
    itemType: getDestination(itemType),
    itemId: itemId,
    pricingContextId: pricingContextId,
    vehicleId: vehicleId,
    customerEmail: customerEmail,
    customerLanguage: lang,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };

  return payload;
}

export function saveWishlist(
  dealerId,
  cartId,
  name,
  email,
  phone,
  date,
  time,
  note,
  contactType
) {
  return {
    dealerId: dealerId,
    cartId: cartId,
    orderNumber: getOrderIdentifier(),
    customerName: name,
    customerEmail: email,
    customerPhone: phone,
    appointmentRequestDate: date,
    appointmentRequestTime: time,
    notes: note,
    customerContactMethodPreference: contactType === CONTACT_EMAIL ? 0 : 1,
    isNational: applicationStore.data.nationalSite ? 1 : 0,
  };
}

import { DispatcherActions } from "../constants/DispatcherActions.js";
import { applicationStore } from "../stores/ApplicationStore.js";
import { AbstractAction } from "./AbstractAction.js";

import { isUserLoggedIn } from "../api/AuthService.js";
import { RouterStates } from "../constants/RouterStates.js";

import {
  getAppDealerId,
  getAppLanguage,
  getDealerUrlCode,
  getSecurityTokenRequestObject,
  getSecurityTokenWrapper,
  getUserIpAddress,
  isEmpty,
  sizeOf,
  checkSiteHasCampaign,
} from "../service/UtilityService.js";
import { setLogin } from "../service/LoginService.js";
import store from "../stores/configureStore.js";
import { onLoginFailure } from "../redux/features/loginInfo.js";
import {
  loadMakeLauncher,
  loadModelLauncher,
  loadYearLauncher,
  setExpiredLogin,
  setLauncherVehicleProductTypes,
  toggleAuthenticationStateModal,
  toggleLoginDialog,
  toggleSearch,
} from "../redux/features/applicationInfo";
import {
  getDestination,
  getDestinationInverse,
} from "../api/RequestBuilderHelper";
import { getOrderIdentifier } from "../service/OrderService";
import { ProductTypes } from "../constants/ProductTypes.js";

var ApplicationActions = AbstractAction.extend({
  changeStateFromUrl: function (routerState, routerParams) {
    this.dispatch(DispatcherActions.CHANGE_STATE_FROM_URL, {
      routerState: routerState,
      routerParams: routerParams,
    });
  },

  goBack: function () {
    this.dispatch(DispatcherActions.GO_BACK, {});
  },

  initializeAppState: function (appState) {
    this.dispatch(DispatcherActions.INITIALIZE_APP_STATE, appState);
  },

  decodeUrlHash: (href, search) => {
    this.dispatch(DispatcherActions.DECODE_URL_HASH, {
      href,
      search,
    });
  },

  loadRecaptchaModal: function (callback) {
    this.dispatch(DispatcherActions.RECAPTCHA_OPEN_MODAL, callback);
  },

  closeRecaptchaModal: function () {
    this.dispatch(DispatcherActions.RECAPTCHA_CLOSE_MODAL, {});
  },

  changeLanguage: function (lang) {
    this.dispatch(DispatcherActions.CHANGE_LANG, lang);
  },

  // toggleSupport: function() {
  //   this.dispatch(DispatcherActions.TOGGLE_SUPPORT, {});
  // },

  //TODO: is this still being using used action
  //TODO: not being used

  // setSelectedBrandId: function(brandId) {
  //   console.log(brandId,'this is where our brandID')
  //   this.dispatch(DispatcherActions.SET_SELECTED_BRAND_ID, brandId);
  // },
  //TODO is this still a working action creator
  // setSelectedSeason: function(season) {
  //   this.dispatch(DispatcherActions.SET_SELECTED_SEASON, season);
  // },

  productTypeSelected: function (productType) {
    this.dispatch(DispatcherActions.PRODUCT_TYPE_SELECTED, productType);
  },

  setProductTypeValue: function (productType) {
    this.dispatch(DispatcherActions.SET_PRODUCT_TYPE_VALUE, productType);
  },

  setSearchProductTypeValue: function (productType) {
    this.dispatch(DispatcherActions.SET_SEARCH_PRODUCT_TYPE_VALUE, productType);
  },

  changeRouterStateDirectly: function (routerstate, goback = false) {
    this.dispatch(DispatcherActions.CHANGE_ROUTER_STATE_DIRECTLY, {
      routerstate: routerstate,
      goback: goback,
    });
  },

  changeProductType: function (productType) {
    this.dispatch(DispatcherActions.CHANGE_PRODUCT_TYPE, productType);
  },
  //TODO this allow to login, and create user
  // TODO this can be located at the LoginDialog
  // openLoginTextDialog: function(createAccount = false) {
  //   this.dispatch(DispatcherActions.LOGIN_TEXT_DIALOG_OPEN, createAccount);
  // },

  // closeLoginTextDialog: function() {
  //   this.dispatch(DispatcherActions.LOGIN_TEXT_DIALOG_CLOSE, {});
  // },

  // openForceLogin: function() {
  //   this.dispatch(DispatcherActions.FORCE_LOGIN_OPEN, {});
  // },

  //TODO need
  openLoginDialog: function () {
    this.dispatch(DispatcherActions.LOGIN_DIALOG_OPEN, {});
  },

  closeLoginDialog: function () {
    this.dispatch(DispatcherActions.LOGIN_DIALOG_CLOSE, {});
  },

  // displayErrorPage: function(status) {
  //   this.dispatch(DispatcherActions.DISPLAY_ERROR_PAGE, status);
  // },
  // *******************************************
  // TODO this is our toggle search action creator
  toggleSearchFlag: function () {
    store.dispatch(toggleSearch(true));
    this.dispatch(DispatcherActions.TOGGLE_SEARCH_FLAG, {});
  },

  activateSearchFlag: function () {
    this.dispatch(DispatcherActions.ACTIVATE_SEARCH_FLAG, {});
  },

  deactivateSearchFlag: function () {
    this.dispatch(DispatcherActions.DEACTIVATE_SEARCH_FLAG, {});
  },

  onOpenNtdModal: function (enableXtimePrompt = false) {
    this.dispatch(DispatcherActions.NTD_OPEN_MODAL, enableXtimePrompt);
  },

  onCloseNtdModal: function () {
    this.dispatch(DispatcherActions.NTD_CLOSE_MODAL, {});
  },

  // NOTACTIVE
  onOpenTireSizeHelper: function (fromTrim) {
    this.dispatch(DispatcherActions.TIRE_SIZE_HELPER_OPEN, fromTrim);
  },

  onCloseTireSizeHelper: function () {
    this.dispatch(DispatcherActions.TIRE_SIZE_HELPER_CLOSE, {});
  },
  //***************************************************** */
  quoteReportUpdateSort: function (type, direction) {
    this.dispatch(DispatcherActions.QUOTE_REPORT_UPDATE_SORT, {
      selectedType: type,
      direction: direction,
    });
  },

  quoteReportUpdateDateFilter: function (date) {
    this.dispatch(DispatcherActions.QUOTE_REPORT_UPDATE_FILTER_DATE, date);
  },

  quoteReportUpdateFilterCount: function (count) {
    this.dispatch(DispatcherActions.QUOTE_REPORT_UPDATE_FILTER_COUNT, count);
  },

  quoteReportUpdateSearchInput: function (search) {
    this.dispatch(DispatcherActions.QUOTE_REPORT_UPDATE_SEARCH_INPUT, search);
  },

  pricingAnalysisToggle: function () {
    this.dispatch(DispatcherActions.PRICING_ANALYSIS_TOGGLE, {});
  },

  pricingIncentiveToggle: function () {
    this.dispatch(DispatcherActions.PRICING_INCENTIVE_TOGGLE, {});
  },

  openQuoteReport: function (loadOrderReport, openLeads = false) {
    this.dispatch(DispatcherActions.QUOTE_REPORT_REQUEST_OPEN, {
      openOrders: loadOrderReport,
      openLeads: openLeads,
    });
  },

  closeQuoteReport: function () {
    this.dispatch(DispatcherActions.QUOTE_REPORT_REQUEST_CLOSE, {});
  },

  openOrderReport: function (loadOrderReport) {
    this.dispatch(DispatcherActions.ORDER_REPORT_REQUEST_OPEN, loadOrderReport);
  },

  closeOrderReport: function () {
    this.dispatch(DispatcherActions.ORDER_REPORT_REQUEST_CLOSE, {});
  },

  openGuestAccountCreationModal: function () {
    this.dispatch(DispatcherActions.OPEN_GUEST_ACCOUNT_CREATION_MODAL, {});
  },

  closeGuestAccountCreationModal: function () {
    this.dispatch(DispatcherActions.CLOSE_GUEST_ACCOUNT_CREATION_MODAL, {});
  },

  openForgotPasswordModal: function () {
    this.dispatch(DispatcherActions.OPEN_FORGOT_PASSWORD_MODAL, {});
  },

  closeForgotPasswordModal: function () {
    this.dispatch(DispatcherActions.CLOSE_FORGOT_PASSWORD_MODAL, {});
  },

  loadTireModelInfo: function (brandId, modelId, modelName) {
    this.apiGet("/parts/tireModel/searchTireModelsByModelName.php", {
      dealerId: getAppDealerId(),
      brandId: brandId ? brandId : applicationStore.data.selectedBrandId,
      modelId: modelId,
      modelName: modelName,
      isNational: applicationStore.data.nationalSite ? 1 : 0,
    }).then(
      function (response) {
        if (response) {
          applicationStore.data.selectedBrandId = response.tire_manufacturer_id;
          applicationStore.data.selectedBrandName = response.manufacturer_name;
          applicationStore.data.selectedModelId = response.tire_model_id;
          applicationStore.data.selectedModelData = {
            brandId: response.tire_manufacturer_id,
            modelName: response.model_name,
            modelImage: response.model_image,
            description: {
              en: response.model_description,
              fr: response.model_description_fr,
            },
            season: response.season,
            allWeather: response.all_weather,
          };
          applicationActions.changeRouterStateDirectly(
            RouterStates.SEARCH_MODELS
          );
        }
      }.bind(this)
    );
  },

  submitGuestAccountCreation: function (
    dealerUrlCode,
    emailAddress,
    password,
    passwordConfirm,
    successfunc = null
  ) {
    this.apiPost("/authentication/createGuestUserAccount.php", {
      dealerId: getAppDealerId(),
      dealerUrlCode: dealerUrlCode,
      email: emailAddress,
      password: password,
      passwordConfirm: passwordConfirm,
      isNational: applicationStore.data.nationalSite ? 1 : 0,
    }).then(
      function (response) {
        if (!!successfunc && response.success) {
          successfunc(1);
        } else if (!!successfunc) {
          successfunc(2);
        }
      }.bind(this)
    );
  },

  submitCustomerAccountCreation: function (
    firstname,
    lastname,
    email,
    password,
    passwordconfirm,
    successfunc = null
  ) {
    this.apiPost("/authentication/createCustomerUserAccount.php", {
      dealerId: getAppDealerId(),
      dealerUrlCode: getDealerUrlCode(),
      firstName: firstname,
      lastName: lastname,
      email: email,
      password: password,
      passwordConfirm: passwordconfirm,
      userIpAddress: getUserIpAddress(),
      isNational: applicationStore.data.nationalSite ? 1 : 0,
    }).then(
      function (response) {
        if (!!successfunc && response.success) {
          successfunc(1);
          setTimeout(() => {
            if (isUserLoggedIn(true))
              this.dispatch(DispatcherActions.LOGIN_SUCCESSFUL, {});
          }, 100);
        } else if (!!successfunc) {
          successfunc(2);
        }
      }.bind(this)
    );
  },
  //FIX this need to update to give results to user
  submitForgotPassword: function (emailAddress) {
    this.apiPost("/authentication/sendForgotPasswordEmail.php", {
      customerLanguage: getAppLanguage(),
      email: emailAddress,
      isNational: applicationStore.data.nationalSite ? 1 : 0,
    }).then(
      function (response) {
        this.dispatch(DispatcherActions.SUBMIT_FORGOT_PASSWORD, response);
      }.bind(this)
    );
  },
  //NOTACTIVE
  selectedDealer: function (dealerId, season = "") {
    this.apiGet("/dealer/getDealerAccountInfoByDealerId.php", {
      dealerId: dealerId,
      isNational: applicationStore.data.nationalSite ? 1 : 0,
    }).then(
      function (response) {
        this.dispatch(DispatcherActions.DEALER_SELECTED, {
          response: response,
          season: season,
        });
      }.bind(this)
    );
  },
  // NOTACTIVE
  loadVehicleSearchParameters: function (
    partType,
    year,
    make,
    category,
    model,
    trim
  ) {
    this.apiGet("/vehicle/validateVehicleKeys.php", {
      dealerId: getAppDealerId(),
      nationalAccountId: applicationStore.data.nationalAccountId,
      partType: partType,
      vehicleYear: decodeURIComponent(year),
      vehicleManufacturerName: decodeURIComponent(make),
      vehicleCategoryName: decodeURIComponent(category),
      vehicleModelName: decodeURIComponent(model),
      vehicleSubmodelName: decodeURIComponent(trim),
      isNational: applicationStore.data.nationalSite ? 1 : 0,
    }).then(
      function (response) {
        this.dispatch(
          DispatcherActions.INITIALIZE_VEHICLE_PARAMETERS,
          response
        );
      }.bind(this)
    );
  },

  loadTireById: function (identifier) {
    this.apiGet("/parts/tire/getTireByPartInfo.php", {
      dealerId: getAppDealerId(),
      brandId: applicationStore.data.selectedBrandId,
      partId: identifier,
      partNumber: identifier,
      isNational: applicationStore.data.nationalSite ? 1 : 0,
    }).then(
      function (response) {
        if (response) {
          applicationStore.data.selectedTireId =
            response.partDetails.primaryPart.itemId;
          applicationStore.data.selectedTirePartNumber =
            response.partDetails.primaryPart.tireManufacturerPartNumber;
        }
        this.dispatch(DispatcherActions.TIRE_LOADED_FROM_PART_DATA, response);
      }.bind(this)
    );
  },
  //NOTACTIVE
  setSelectedTirePart: function (tire) {
    this.dispatch(DispatcherActions.SET_SELECTED_TIRE_PART, tire);
  },

  loadCampaignIsInactive: function () {
    this.dispatch(DispatcherActions.CAMPAIGN_IS_INACTIVE, {});
  },

  resetCampaignIsInactive: function () {
    this.dispatch(DispatcherActions.CAMPAIGN_IS_INACTIVE_RESET, {});
  },

  loadDealerCampaign: function (vehicleData) {
    var campaignData = window.campaignData,
      partType = campaignData.campaign.campaignPartType,
      destination = "",
      vehicleYear = "",
      vehicleManufacturerName = "",
      vehicleModelName = "";
    if (
      !isEmpty(vehicleData) &&
      sizeOf(vehicleData) > 0 &&
      checkSiteHasCampaign()
    ) {
      for (var i in vehicleData) {
        if (vehicleData[i]) {
          switch (vehicleData[i][0]) {
            case "vehicleYear":
              vehicleYear = vehicleData[i][1];
              break;
            case "vehicleManufacturerName":
              vehicleManufacturerName = vehicleData[i][1];
              break;
            case "vehicleModelName":
              vehicleModelName = vehicleData[i][1];
              break;
          }
        }
      }
    }

    var validVehicleData = false;
    if (!isEmpty(vehicleYear)) {
      validVehicleData = true;
    }

    if (validVehicleData) {
      this.apiGet("/vehicle/validateVehicleKeys.php", {
        dealerId: getAppDealerId(),
        nationalAccountId: applicationStore.data.nationalAccountId,
        partType: partType,
        vehicleYear: vehicleYear,
        vehicleManufacturerName: vehicleManufacturerName,
        vehicleModelName: vehicleModelName,
        vehicleSubmodelName: "",
        isNational: applicationStore.data.nationalSite ? 1 : 0,
      }).then(
        function (response) {
          if (response) {
            if (
              response.vehicleYearId &&
              parseInt(response.vehicleYearId) > 0
            ) {
              applicationStore.data.year = response.vehicleYearId;
              productListStore.data.searchType = SearchTypes.SEARCH_BY_VEHICLE;
              destination = RouterStates.MAKE;
            }
            if (
              response.vehicleManufacturerId &&
              parseInt(response.vehicleManufacturerId) > 0
            ) {
              applicationStore.data.make = response.vehicleManufacturerId;
              destination = RouterStates.MODEL;
            }
            if (
              response.vehicleModelId &&
              parseInt(response.vehicleModelId) > 0
            ) {
              applicationStore.data.model = response.vehicleModelId;
              destination = RouterStates.TRIM;
            }
            if (
              response.vehicleSubmodelId &&
              parseInt(response.vehicleSubmodelId) > 0
            ) {
              applicationStore.data.trim = response.vehicleSubmodelId;
              destination = RouterStates.PRODUCT_LIST;
            }

            if (partType) {
              applicationStore.data.productType =
                getDestinationInverse(partType);
            }

            this.dispatch(DispatcherActions.CAMPAIGN_LOAD_FOR_DEALER, {
              destination: destination,
            });
          }
        }.bind(this)
      );
    } else {
      var partType = campaignData.campaign.campaignPartType;

      if (partType) {
        applicationStore.data.productType = getDestinationInverse(partType);
      }

      this.dispatch(DispatcherActions.CAMPAIGN_LOAD_FOR_DEALER, {
        destination: true,
      });
    }
  },

  loadDealerVehicleData: function (vehicleData) {
    if (!isEmpty(vehicleData) && sizeOf(vehicleData)) {
      var vehicleYear = "",
        vehicleManufacturerName = "",
        vehicleModelName = "";
      for (var i in vehicleData) {
        if (vehicleData[i]) {
          switch (vehicleData[i][0]) {
            case "vehicleYear":
              vehicleYear = vehicleData[i][1];
              break;
            case "vehicleManufacturerName":
              vehicleManufacturerName = vehicleData[i][1];
              break;
            case "vehicleModelName":
              vehicleModelName = vehicleData[i][1];
              break;
          }
        }
      }

      this.apiGet("/vehicle/validateVehicleKeys.php", {
        dealerId: "",
        nationalAccountId: applicationStore.data.nationalAccountId,
        partType: getDestination(ProductTypes.TIRES),
        vehicleYear: vehicleYear,
        vehicleManufacturerName: vehicleManufacturerName,
        vehicleModelName: vehicleModelName,
        vehicleSubmodelName: "",
        isNational: applicationStore.data.nationalSite ? 1 : 0,
      }).then(
        function (response) {
          if (response) {
            var routerState = null;

            applicationStore.data.productType = ProductTypes.TIRES;

            if (
              response.vehicleYearId &&
              parseInt(response.vehicleYearId) > 0
            ) {
              routerState = RouterStates.MAKE;
              applicationStore.data.year = response.vehicleYearId;
            }
            if (
              response.vehicleManufacturerId &&
              parseInt(response.vehicleManufacturerId) > 0
            ) {
              routerState = RouterStates.MODEL;
              applicationStore.data.make = response.vehicleManufacturerId;
            }
            if (
              response.vehicleModelId &&
              parseInt(response.vehicleModelId) > 0
            ) {
              routerState = RouterStates.TRIM;
              applicationStore.data.model = response.vehicleModelId;
            }
            if (
              response.vehicleSubmodelId &&
              parseInt(response.vehicleSubmodelId) > 0
            ) {
              routerState = RouterStates.PRODUCT_LIST;
              applicationStore.data.trim = response.vehicleSubmodelId;
            }

            if (routerState !== null) {
              setTimeout(() => {
                this.changeRouterStateDirectly(routerState);
              }, 100);
            }
          }
        }.bind(this)
      );
    }
  },

  loadCampaignVehicleData: function (vehicleData) {
    var campaignData = window.campaignData;
    if (!isEmpty(vehicleData) && sizeOf(vehicleData) > 0 && !!campaignData) {
      var partType = campaignData.campaign.campaignPartType,
        vehicleYear = "",
        vehicleManufacturerName = "",
        vehicleModelName = "";
      for (var i in vehicleData) {
        if (vehicleData[i]) {
          switch (vehicleData[i][0]) {
            case "vehicleYear":
              vehicleYear = vehicleData[i][1];
              break;
            case "vehicleManufacturerName":
              vehicleManufacturerName = vehicleData[i][1];
              break;
            case "vehicleModelName":
              vehicleModelName = vehicleData[i][1];
              break;
          }
        }
      }

      this.apiGet("/vehicle/validateVehicleKeys.php", {
        dealerId: "",
        nationalAccountId: applicationStore.data.nationalAccountId,
        partType: partType,
        vehicleYear: vehicleYear,
        vehicleManufacturerName: vehicleManufacturerName,
        vehicleModelName: vehicleModelName,
        vehicleSubmodelName: "",
        isNational: applicationStore.data.nationalSite ? 1 : 0,
      }).then(
        function (response) {
          if (response) {
            if (
              response.vehicleYearId &&
              parseInt(response.vehicleYearId) > 0
            ) {
              applicationStore.data.year = response.vehicleYearId;
            }
            if (
              response.vehicleManufacturerId &&
              parseInt(response.vehicleManufacturerId) > 0
            ) {
              applicationStore.data.make = response.vehicleManufacturerId;
            }
            if (
              response.vehicleModelId &&
              parseInt(response.vehicleModelId) > 0
            ) {
              applicationStore.data.model = response.vehicleModelId;
            }
            if (
              response.vehicleSubmodelId &&
              parseInt(response.vehicleSubmodelId) > 0
            ) {
              applicationStore.data.trim = response.vehicleSubmodelId;
            }

            if (partType) {
              applicationStore.data.productType =
                getDestinationInverse(partType);
            }

            this.dispatch(
              DispatcherActions.CAMPAIGN_DEALER_SELECTION_VEHICLE_LOADED,
              response
            );
          }
        }.bind(this)
      );
    }
  },

  selectCampaignDealer: function (dealerId, season, vehicleData, language) {
    var campaignData = window.campaignData,
      destination = "";
    if (!isEmpty(vehicleData) && sizeOf(vehicleData) > 0 && !!campaignData) {
      var partType = campaignData.campaign.campaignPartType,
        vehicleYear = "",
        vehicleManufacturerName = "",
        vehicleModelName = "";
      for (var i in vehicleData) {
        if (vehicleData[i]) {
          switch (vehicleData[i][0]) {
            case "vehicleYear":
              vehicleYear = vehicleData[i][1];
              break;
            case "vehicleManufacturerName":
              vehicleManufacturerName = vehicleData[i][1];
              break;
            case "vehicleModelName":
              vehicleModelName = vehicleData[i][1];
              break;
          }
        }
      }

      this.apiGet("/vehicle/validateVehicleKeys.php", {
        dealerId: dealerId,
        nationalAccountId: applicationStore.data.nationalAccountId,
        partType: partType,
        vehicleYear: vehicleYear,
        vehicleManufacturerName: vehicleManufacturerName,
        vehicleModelName: vehicleModelName,
        vehicleSubmodelName: "",
        isNational: applicationStore.data.nationalSite ? 1 : 0,
      }).then(
        function (response) {
          if (response) {
            destination = RouterStates.YEAR;
            if (
              response.vehicleYearId &&
              parseInt(response.vehicleYearId) > 0
            ) {
              applicationStore.data.year = response.vehicleYearId;
              productListStore.data.searchType = SearchTypes.SEARCH_BY_VEHICLE;
              destination = RouterStates.MAKE;
            }
            if (
              response.vehicleManufacturerId &&
              parseInt(response.vehicleManufacturerId) > 0
            ) {
              applicationStore.data.make = response.vehicleManufacturerId;
              destination = RouterStates.MODEL;
            }
            if (
              response.vehicleModelId &&
              parseInt(response.vehicleModelId) > 0
            ) {
              applicationStore.data.model = response.vehicleModelId;
              destination = RouterStates.TRIM;
            }
            if (
              response.vehicleSubmodelId &&
              parseInt(response.vehicleSubmodelId) > 0
            ) {
              applicationStore.data.trim = response.vehicleSubmodelId;
              destination = RouterStates.PRODUCT_LIST;
            }

            if (partType) {
              applicationStore.data.productType =
                getDestinationInverse(partType);
            }

            this.apiGet("/dealer/getDealerAccountInfoByDealerId.php", {
              dealerId: dealerId,
              isNational: applicationStore.data.nationalSite ? 1 : 0,
            }).then(
              function (response) {
                this.dispatch(DispatcherActions.DEALER_SELECTED, {
                  response: response,
                  season: season,
                  destination: destination,
                  language: language,
                });
              }.bind(this)
            );
          }
        }.bind(this)
      );
    } else {
      if (checkSiteHasCampaign()) {
        destination = RouterStates.YEAR;
      }

      var partType = campaignData.campaign.campaignPartType;

      if (partType) {
        applicationStore.data.productType = getDestinationInverse(partType);
      }

      this.apiGet("/dealer/getDealerAccountInfoByDealerId.php", {
        dealerId: dealerId,
        isNational: applicationStore.data.nationalSite ? 1 : 0,
      }).then(
        function (response) {
          this.dispatch(DispatcherActions.DEALER_SELECTED, {
            response: response,
            season: season,
            destination: destination,
            language: language,
          });
        }.bind(this)
      );
    }
  },

  setSelectedDealerForToken: function (dealer) {
    this.dispatch(DispatcherActions.DEALER_SELECTED_FOR_TOKEN, dealer);
  },

  loadListOfProvincialDealers: function () {
    this.apiGet("/national/getNationalAccountDealerList.php", {
      nationalAccountId: applicationStore.data.nationalAccountId,
      showOnlyRegionalDealers: 1,
      isNational: applicationStore.data.nationalSite ? 1 : 0,
    }).then(
      function (listOfDealers) {
        this.dispatch(
          DispatcherActions.LIST_OF_PROVINCIAL_DEALERS_LOADED,
          listOfDealers
        );
      }.bind(this)
    );
  },

  loadProvincialDealerListOfDealers: function () {
    this.apiGet("/national/getNationalAccountDealerList.php", {
      nationalAccountId: applicationStore.data.nationalAccountId,
      stateCodeFilter: applicationStore.data.dealerInfo.province,
      isNational: applicationStore.data.nationalSite ? 1 : 0,
    }).then(
      function (listOfDealers) {
        this.dispatch(
          DispatcherActions.PROVINCIAL_DEALERS_LIST_OF_DEALERS_LOADED,
          listOfDealers
        );
      }.bind(this)
    );
  },

  provincialDealerListSelectDealer: function (dealer) {
    this.dispatch(
      DispatcherActions.PROVINCIAL_DEALERS_LIST_OF_DEALERS_SELECTED,
      dealer
    );
  },

  loadDealerAppointmentInfo: function (dealerUrlCode) {
    this.dispatch(
      DispatcherActions.LOAD_DEALER_APPOINTMENT_INFO,
      dealerUrlCode
    );
  },

  wishlistDealerSelectModalSearch: function (search) {
    this.dispatch(
      DispatcherActions.WISHLIST_DEALER_SELECT_MODAL_SEARCH,
      search
    );
  },

  getNationalPartStockByPartId: function (partType, partId, partOptionId) {
    this.apiGet("/parts/utility/checkNationalStockLevelByPartId.php", {
      dealerId: getAppDealerId(),
      partType: getDestination(partType),
      partId: partId,
      partOptionId: partOptionId,
      isNational: applicationStore.data.nationalSite ? 1 : 0,
    }).then(
      function (response) {
        this.dispatch(
          DispatcherActions.LOAD_NATIONAL_PART_STOCK_INFO,
          response
        );
      }.bind(this)
    );
  },

  closeNationalPartStockModal: function () {
    this.dispatch(DispatcherActions.CLOSE_NATIONAL_PART_STOCK_MODAL, {});
  },

  getNationalPartPricingByPartId: function (partType, partId, partOptionId) {
    this.apiGet("/parts/utility/checkNationalPricingByPartId.php", {
      dealerId: getAppDealerId(),
      partType: getDestination(partType),
      partId: partId,
      partOptionId: partOptionId,
      vehicleId: vehicleSelectionStore.data.vehicleId,
      isNational: applicationStore.data.nationalSite ? 1 : 0,
    }).then(
      function (response) {
        this.dispatch(
          DispatcherActions.LOAD_NATIONAL_PART_PRICING_INFO,
          response
        );
      }.bind(this)
    );
  },

  closeNationalPartPriceModal: function () {
    this.dispatch(DispatcherActions.CLOSE_NATIONAL_PART_PRICING_MODAL, {});
  },

  openReturnToDealerSelectionWarning: function () {
    this.dispatch(
      DispatcherActions.OPEN_RETURN_TO_DEALER_SELECTION_WARNING,
      {}
    );
  },

  closeReturnToDealerSelectionWarning: function () {
    this.dispatch(
      DispatcherActions.CLOSE_RETURN_TO_DEALER_SELECTION_WARNING,
      {}
    );
  },

  showExternalLinkWarningModal: function (link) {
    this.dispatch(DispatcherActions.SHOW_EXTERNAL_LINK_WARNING, link);
  },

  closeExternalLinkWarningModal: function () {
    this.dispatch(DispatcherActions.CLOSE_EXTERNAL_LINK_WARNING, {});
  },

  attemptTireWheelPackageCreation: function () {
    this.dispatch(DispatcherActions.ATTEMPT_TIRE_WHEEL_PACKAGE_CREATION, {});
  },

  attemptedTireWheelPackageCreation: function () {
    this.dispatch(DispatcherActions.ATTEMPTED_TIRE_WHEEL_PACKAGE_CREATION, {});
  },

  openStartNewQuoteModal: function () {
    this.dispatch(DispatcherActions.START_NEW_QUOTE_OPEN_MODAL, {});
  },

  closeStartNewQuoteModal: function () {
    this.dispatch(DispatcherActions.START_NEW_QUOTE_CLOSE_MODAL, {});
  },

  // openAuthenticationStateModal: function() {
  // 	this.dispatch(DispatcherActions.AUTHENTICATION_STATE_OPEN_MODAL, {});
  // },

  closeAuthenticationStateModal: function () {
    this.dispatch(DispatcherActions.AUTHENTICATION_STATE_CLOSE_MODAL, {});
  },

  setWidgetSearch: function (key, value) {
    this.dispatch(DispatcherActions.SET_WIDGET_SEARCH, {
      key: key,
      value: value,
    });
  },

  apiWebserviceHasFailed: function (httpStatus = 500) {
    this.dispatch(DispatcherActions.API_WEBSERVICE_HAS_FAILED, httpStatus);
  },

  launcherSetValues: function (data) {
    this.dispatch(DispatcherActions.LAUNCHER_UPDATE_VALUES, data);
  },

  loadLauncherYearSelection: function (product = null) {
    this.apiGet("/vehicle/getVehicleYears.php", {
      partType: !isEmpty(product) ? getDestination(product) : product,
      nationalAccountId: applicationStore.data.nationalAccountId,
      dealerId: getAppDealerId(),
      isNational: applicationStore.data.nationalSite ? 1 : 0,
    }).then(
      function (response) {
        store.dispatch(loadYearLauncher(response));
        // this.dispatch(DispatcherActions.LAUNCHER_VEHICLE_YEARS_LOADED, response);
      }.bind(this)
    );
  },

  loadLauncherMakeSelection: function (year, product = null) {
    this.apiGet("/vehicle/getVehicleManufacturers.php", {
      partType: !isEmpty(product) ? getDestination(product) : product,
      vehicleYearId: year,
      dealerId: getAppDealerId(),
      nationalAccountId: applicationStore.data.nationalAccountId,
      isNational: applicationStore.data.nationalSite ? 1 : 0,
    }).then(
      function (response) {
        store.dispatch(
          loadMakeLauncher({
            primaryMakes: response.primaryVehicleManufacturers,
            secondaryMakes: response.secondaryVehicleManufacturers,
          })
        );
        // this.dispatch(DispatcherActions.LAUNCHER_VEHICLE_MAKES_LOADED, {
        // 	primaryMakes: response.primaryVehicleManufacturers,
        // 	secondaryMakes: response.secondaryVehicleManufacturers,
        // });
      }.bind(this)
    );
  },

  loadLauncherModelSelection: function (year, make, product = null) {
    this.apiGet("/vehicle/getVehicleModels.php", {
      partType: !isEmpty(product) ? getDestination(product) : product,
      vehicleYearId: year,
      vehicleManufacturerId: make,
      dealerId: getAppDealerId(),
      nationalAccountId: applicationStore.data.nationalAccountId,
      isNational: applicationStore.data.nationalSite ? 1 : 0,
    }).then(
      function (response) {
        store.dispatch(
          loadModelLauncher({
            response: response,
            year: year,
            make: make,
          })
        );
      }.bind(this)
    );
  },

  loadLauncherProductTypeSelection: function (year, make, category, model) {
    this.apiGet("/vehicle/getVehiclePartTypes.php", {
      nationalAccountId: applicationStore.data.nationalAccountId,
      dealerId: getAppDealerId(),
      vehicleYearId: year,
      vehicleManufacturerId: make,
      vehicleCategoryId: category,
      vehicleModelId: model,
      isNational: applicationStore.data.nationalSite ? 1 : 0,
    }).then(function (response) {
      store.dispatch(
        setLauncherVehicleProductTypes({
          response: response,
        })
      );
    });
  },

  closeOrderStatusModal: function () {
    this.dispatch(DispatcherActions.ORDER_STATUS_RESET, {});
  },

  loginSuccessful: function () {
    this.dispatch(DispatcherActions.LOGIN_SUCCESSFUL, {});
  },

  loginHasExpired: function () {
    this.dispatch(DispatcherActions.LOGIN_HAS_EXPIRED, {});
  },

  resetLoginHasExpired: function () {
    this.dispatch(DispatcherActions.LOGIN_HAS_EXPIRED_RESET, {});
  },

  loadDealerInfo: function () {
    this.apiGet("/dealer/getDealerAccountInfoByDealerId.php", {
      dealerId: getAppDealerId(),
      isNational: applicationStore.data.nationalSite ? 1 : 0,
    }).then(
      function (response) {
        this.dispatch(DispatcherActions.DEALER_INFO_LOAD, response);
      }.bind(this)
    );
  },

  login: function (email, password, rememberMe) {
    // console.trace()
    store.dispatch(onLoginFailure(false));
    this.apiPost(
      getSecurityTokenWrapper("/authentication/getSecurityToken.php"),
      getSecurityTokenRequestObject(email, password)
    ).then(
      function () {
        if (isUserLoggedIn(true)) {
          //if (rememberMe) setLogin(email, password);

          var captchaicon = document.getElementsByClassName("grecaptcha-badge");
          if (!isEmpty(captchaicon) && captchaicon[0]) {
            captchaicon[0].className = "grecaptcha-badge hide";
          }
          setLogin(email, password);
          store.dispatch(toggleLoginDialog(false));
          setTimeout(() => {
            store.dispatch(toggleAuthenticationStateModal(true));
            store.dispatch(setExpiredLogin(false));
          }, 1000);
          this.loadDealerInfo();
        } else {
          store.dispatch(onLoginFailure(true));
          this.dispatch(DispatcherActions.LOGIN_FAILURE, {});
        }
      }.bind(this)
    );
  },

  logOut: function () {
    this.apiGet("/authentication/terminateSecurityToken.php").then(
      function () {
        var captchaicon = document.getElementsByClassName("grecaptcha-badge");
        if (!isEmpty(captchaicon) && captchaicon[0]) {
          captchaicon[0].className = "grecaptcha-badge";
        }

        this.dispatch(DispatcherActions.LOGOUT_SUCCESSFUL, {});
      }.bind(this)
    );
  },

  getDealerSecurityToken: function () {
    setTimeout(() => {
      this.apiPost(
        getSecurityTokenWrapper("/authentication/getSecurityToken.php"),
        getSecurityTokenRequestObject()
      ).then(
        function (response) {
          if (isUserLoggedIn(true))
            this.dispatch(DispatcherActions.LOGIN_SUCCESSFUL, {});
        }.bind(this)
      );
    }, 250);
  },

  appRunningOutdatedVersion: function () {
    this.dispatch(DispatcherActions.APPLICATION_VERSION_IS_OUTDATED, {});
  },

  gatherXtimeLinkUrl: function (name = "", email = "", phone = "", note = "") {
    let orderTypeCode = store.getState().shoppingCart.orderTypeCode;
    this.apiPost("/shopping/thirdParty/getXtimeAppointmentIntegrationUrl.php", {
      dealerId: getAppDealerId(),
      orderNumber: getOrderIdentifier(),
      orderType: orderTypeCode,
      customerLanguage: getAppLanguage(),
      customerName: name,
      customerEmail: email,
      customerPhone: phone,
      notes: note,
      isNational: applicationStore.data.nationalSite ? 1 : 0,
    }).then(
      function (response) {
        this.dispatch(
          DispatcherActions.XTIME_INTEGRATION_URL_GATHERED,
          response
        );
      }.bind(this)
    );
  },

  resetXtimeLinkError: function () {
    this.dispatch(DispatcherActions.XTIME_INTEGRATION_RESET_LINK_ERROR, {});
  },

  requestDealerContact: function (firstName, lastName, email, phone, notes) {
    this.apiPost("/shopping/lead/createItemContactLead.php", {
      targetDealerId: getAppDealerId(),
      vehicleId: null,
      customerFirstName: firstName,
      customerLastName: lastName,
      customerEmail: email,
      customerPhone: phone,
      customerContactMethodPreference: 0,
      customerMessage: notes,
      partId: null,
      partType: "TRE",
      customerLanguage: getAppLanguage(),
      isNational: applicationStore.data.nationalSite ? 1 : 0,
    }).then(
      function (response) {
        this.handleFormEventResponse(response);
      }.bind(this)
    );
  },

  handleFormEventResponse: function (response) {
    if (response && !!response.success) {
      this.wishlistFormModalSuccess();
    } else {
      this.wishlistFormModalError();
    }
  },

  wishlistFormModalSuccess: function () {
    this.dispatch(DispatcherActions.SHOPPING_CART_FORM_SUCCESS, {});
  },

  wishlistFormModalError: function () {
    this.dispatch(DispatcherActions.SHOPPING_CART_FORM_ERROR, {});
  },
});

export const applicationActions = new ApplicationActions();

import { applicationActions } from '../actions/ApplicationActions.js';
import { dealerSelectionActions } from '../actions/DealerSelectionActions.js';

import { applicationStore } from '../stores/ApplicationStore.js';
import { shoppingCartViewStore } from '../stores/ShoppingCartViewStore.js';
import { languageStore } from '../stores/LanguageStore.js';
import { productListStore } from "../stores/ProductListStore";

import { RouterStates } from '../constants/RouterStates';

import { setToken } from '../api/AuthService.js';

import { saveStorageFormData } from './StorageService';
import {
  sizeOf, isEmpty, getAppLanguage, getAppDealerId, checkSiteHasCampaign, decodeChassisSearchLanguage, loadWishlistModal,
  loadWishlistDealerModal, leadWasActioned, getAppUrl, getCampaignCode, isDealerSite, isNationalSite, isGroupSite, isConsumerSite
} from './UtilityService';
import store from '../stores/configureStore';
import { appState } from '../redux/features/applicationInfo.js';
import { loadDealerInfo } from '../redux/features/dealer.js';
const URL_NEEDS_DECODED = "url_needs_decoded";
const URL_DECODED = "url_decoded";

const HASH_NEEDS_DECODED = "hash_needs_decoded";
const HASH_DECODED = "hash_decoded";

const SCREENMODES = {
  REGULAR: 'regular',
  IFRAME: 'iframe',
  GUIDE: 'guide',
};

var currentState = {
  url: URL_NEEDS_DECODED,
  hash: HASH_NEEDS_DECODED
};

const hashWasDecoded = () => {
  currentState.hash = HASH_DECODED;
};

export const hashServiceShouldRun = () => {
  return currentState.hash.localeCompare(HASH_NEEDS_DECODED) === 0;
};

export const parseLocation = () => {
  var hash = location.hash.substring(1);
  if (hash.indexOf('?') > -1) {
    return hash.split('?')[0];
  } else {
    return hash;
  }
};

export const decodeUrlHash = (hash, search) => {

  var fromCampaign = false, fromWidget = false, fromChassis = false, fromVehicleList = false, fromWishlist = false, fromAutoAdd = false, screenMode = false;

  //console.log('decodeUrlHash, hashServiceShouldRun: '+hashServiceShouldRun());

  if (hashServiceShouldRun()) {

    //console.log('hashServiceShouldRun, hash: '+hash);

    hashWasDecoded();

    //Widget -> auto select dealer, auto select vehicle (to trim)
    //Chassis -> auto select dealer, auto select vehicle (to chassis)
    //Campaign -> auto select dealer, auto select vehicle (to trim)
    //Wishlist -> select form modal to show

    if (hash && checkSiteHasCampaign()) {
      fromCampaign = true;
    }

    if (hash && (hash.indexOf('vehicleYear=') > -1 || hash.indexOf('vehicle=') > -1 || hash.indexOf('tiresize=') > -1)) {
      fromWidget = true;
    }

    if (hash && (hash.indexOf('user_locale=') > -1 || hash.indexOf('currentBaumuster=') > -1 || hash.indexOf('vehicle_servicedealerid=') > -1)) {
      fromChassis = true;
    }

    if (search && (search.indexOf('season=') > -1 || search.indexOf('submodeltiresizeid=') > -1)) {
      fromVehicleList = true;
    }

    if (hash && hash.indexOf('showForm=') > -1) {
      fromWishlist = true;
    }

    if (hash && hash.indexOf('autoAdd=') > -1) {
      fromAutoAdd = true;
    }

    if (hash && hash.indexOf('screen=') > -1) {
      screenMode = true;
    }

    var splitParameters, splitParams, parameters = [], params = [], key, value, language, tempParams;

    if (screenMode) {
      if (hash) {
        tempParams = hash.split('?');
        if (sizeOf(tempParams) > 1 && tempParams[1].indexOf("&") > -1) {
          splitParameters = tempParams[1].split("&");
          for (var i in splitParameters) {
            if (splitParameters[i].indexOf("=") > -1) {
              parameters.push(splitParameters[i].split("="));
            }
          }
        } else if (sizeOf(tempParams) > 1) {
          parameters.push(tempParams[1].split("="));
        }
      }

      if (sizeOf(parameters[0]) > 0) {
        if (parameters[0][0] === 'screen') {

          if (parameters[0][1] === SCREENMODES.IFRAME) {
            applicationStore.data.screenMode = SCREENMODES.IFRAME;
          } else if (parameters[0][1] === SCREENMODES.GUIDE) {
            applicationStore.data.screenMode = SCREENMODES.GUIDE;
          }

        }
      }

    }

    if (fromCampaign) {

      if (isDealerSite()) {

        if (hash) {
          tempParams = hash.split('?');
          if (sizeOf(tempParams) > 1 && tempParams[1].indexOf("&") > -1) {
            splitParameters = tempParams[1].split("&");
            for (var i in splitParameters) {
              if (splitParameters[i].indexOf("=") > -1) {
                parameters.push(splitParameters[i].split("="));
              }
            }
          } else if (sizeOf(tempParams) > 1) {
            parameters.push(tempParams[1].split("="));
          }
        }
        setTimeout(() => { applicationActions.loadDealerCampaign(parameters); }, 0);

      } else if (isNationalSite()) {

        if (hash) {
          tempParams = hash.split('?');
          var tempLanguage = tempParams[0].split('/');
          language = tempLanguage[1];
          languageStore.data.lang = language;
          if (sizeOf(tempParams) > 1 && tempParams[1].indexOf("&") > -1) {
            splitParams = tempParams[1].split("&");
            for (var i in splitParams) {
              if (splitParams[i].indexOf("=") > -1) {
                params.push(splitParams[i].split("="));
              }
            }
          } else if (sizeOf(tempParams) > 1) {
            params.push(tempParams[1].split("="));
          }
        }

        var campaignData = window.campaignData;

        if (sizeOf(campaignData.campaignDealerList) === 1) {
          setTimeout(() => { applicationActions.selectCampaignDealer(campaignData.campaignDealerList[0].dealerId, campaignData.campaign.campaignSeason, params, language); }, 0);
        } else if (sizeOf(campaignData.campaignDealerList) > 1) {
          setTimeout(() => { applicationActions.loadCampaignVehicleData(params, language); }, 0);
        }

      }

    } else if (fromWidget) {

      if (hash && (hash.indexOf('vehicleManufacturerName=') > -1)) {

        params = splitParamters(hash);

        applicationActions.loadDealerVehicleData(params);
        //setTimeout(() => { applicationActions.loadDealerVehicleData(params); }, 0);

      } else {

        tempParams = hash.split('?');

        if (sizeOf(tempParams) > 0 && !isEmpty(tempParams[1]) && tempParams[1].indexOf('&') > -1) {
          splitParams = tempParams[1].split('&');
        } else {
          splitParams = tempParams;
        }

        if (sizeOf(splitParams) > 0 && !isEmpty(splitParams[1])) {
          params = splitParams[1].split("=");
        }

        if (!isEmpty(params)) {
          if (!isEmpty(params[0]) && !isEmpty(params[1])) {
            key = params[0];
            value = decodeURIComponent(params[1]);
            applicationActions.setWidgetSearch(key, value);
            //setTimeout(() => { applicationActions.setWidgetSearch(key, value); }, 500);
          }
        }

      }

    } else if (fromChassis) {

      params = splitParamters(hash);

      var languageText, chassisText, dealerText;
      for (var j in params) {
        if (params[j][0] === 'user_locale') languageText = decodeChassisSearchLanguage(params[j][1]);
        if (params[j][0] === 'currentBaumuster') chassisText = params[j][1];
        if (params[j][0] === 'vehicle_servicedealerid') dealerText = params[j][1];
      }

      setTimeout(() => { dealerSelectionActions.onDealerChassisSearchFromUrl({ language: languageText, chassis: chassisText, dealer: dealerText }); }, 0);

    } else if (fromVehicleList) {

      params = splitParamters(search);

      var season, tiresize;
      for (var j in params) {
        if (params[j][0] === 'season') season = params[j][1];
        if (params[j][0] === 'submodeltiresizeid') tiresize = params[j][1];
      }

      if (typeof(season) !== 'undefined') {
        productListStore.data.season = season;
        productListStore.data.loadFilterFromUrl = true;
      }
      if (typeof(tiresize) !== 'undefined') {
        productListStore.data.tireSize = tiresize;
        productListStore.data.loadFilterFromUrl = true;
      }

    } else if (fromWishlist) {

      params = splitParamters(hash);

      var type, leadId, login, markAsComplete = false;
      for (var j in params) {
        if (params[j][0] === "showForm") type = params[j][1];
        if (params[j][0] === "leadId") leadId = params[j][1];
        if (params[j][0] === "forceLogin") login = params[j][1];
        if (params[j][0] === "markAsComplete") markAsComplete = params[j][1];
      }

      if (login === "true") {

        if (markAsComplete) loadWishlistDealerModal(type, leadId);
        saveStorageFormData(type, leadId);
        setTimeout(() => {
          applicationActions.openForceLogin();
          leadWasActioned(leadId);
        }, 100);

      } else {

        loadWishlistModal(type, leadId);

      }

    } else if (fromAutoAdd) {

      params = splitParamters(hash);

      var manufacturer, partnumber;
      for (var j in params) {
        if (params[j][0] === "manufacturer") manufacturer = params[j][1];
        if (params[j][0] === "partnumber") partnumber = params[j][1];
      }

      productListStore.data.autoAddToCart = true;
      productListStore.data.autoAddProduct = { manufacturer: manufacturer, partnumber: partnumber };

    }

  }
};

const splitParamters = (hash) => {
  var tempParams, splitParams, params = [], temp = [], decoded = [];
  if (hash.indexOf('?') > -1) {
    tempParams = hash.split('?');
    splitParams = tempParams[1].split('&');
  } else {
    splitParams = hash.split('&');
  }
  for (var i in splitParams) {
    if (splitParams[i].indexOf("=") > -1) {
      temp = splitParams[i].split("=");
      decoded = [ decodeURIComponent(temp[0]), decodeURIComponent(temp[1]) ];
      params.push(decoded);
    }
  }
  return params;
};

export const decodeUrlSSO = (hash) => {
  var urlParams = hash.substring(1).split('/');
  var ssoFound = false, isoFound = false;
  for (var i = 0; i < urlParams.length; i++) {
    if (ssoFound || isoFound) {
      var tokenParams = urlParams[i].split("_");
      if (tokenParams[0].search(/^[A-F0-9]{32}$/) != -1) {
        var token = tokenParams[0];
        var dateCreated = new Date(parseFloat(tokenParams[1]) * 1000).toISOString();
        var dateUpdated = new Date(parseFloat(tokenParams[2]) * 1000).toISOString();
        var tokenAuthenticated = tokenParams[3] === '1';
        var sessionReferenceNumber = tokenParams[4];
        var applicationVersionNumber = tokenParams[5];

        var resetSecurityCheckpoint = tokenParams[6];
        var firstName = tokenParams[7];
        var lastName = tokenParams[8];
        var userRoleId = tokenParams[9];
        var userRoleName = tokenParams[10];

        applicationStore.data.loggedIn = true;
        setToken({
          tokenId: token,
          tokenCreatedTimestamp: dateCreated,
          tokenExpirationTimestamp: dateUpdated,
          tokenIsAuthenticated: tokenAuthenticated,
          sessionReferenceNumber: sessionReferenceNumber,
          applicationVersionNumber: applicationVersionNumber,
          resetSecurityCheckpoint: resetSecurityCheckpoint,
          firstName: firstName,
          lastName: lastName,
          userRoleId: userRoleId,
          userRoleName: userRoleName,
        });

        if (isoFound) {
          var url = hash.split('/'), routerState = RouterStates.HOMEPAGE;

          var initializeApp = {
            lang: url[1],
            dealerId: url[5]
          };

          if (url.length >= 7 && url[7] !== null) {
            initializeApp.productType = url[7];
            routerState = RouterStates.YEAR;
          }
          if (url.length >= 9 && url[9] !== null) {
            initializeApp.searchType = url[9];
            routerState = RouterStates.YEAR;
          }
          if (url.length >= 11 && url[11] !== null) {
            initializeApp.year = url[11];
            routerState = RouterStates.MAKE;
          }
          if (url.length >= 13 && url[13] !== null) {
            initializeApp.make = url[13];
            routerState = RouterStates.CATEGORY;
          }
          if (url.length >= 15 && url[15] !== null) {
            initializeApp.category = url[15];
            routerState = RouterStates.MODEL;
          }
          if (url.length >= 17 && url[17] !== null) {
            initializeApp.model = url[17];
            routerState = RouterStates.TRIM;
          }
          if (url.length >= 19 && url[19] !== null) {
            initializeApp.trim = url[19];
            routerState = RouterStates.PRODUCT_LIST;
          }

          initializeApp.routerState = routerState;
          initializeApp.showLoadingPage = true;
          // this is dispatching from the store without connect/ direct disptach
          store.dispatch(appState(initializeApp))
          applicationActions.initializeAppState(initializeApp);

          applicationActions.changeRouterStateDirectly(routerState);

          location.href = location.href;
          setTimeout(function() { location.reload(); }, 10);
        } else {
          setTimeout(function() {
            store.dispatch(loadDealerInfo())
             applicationActions.loadDealerInfo(); 
            }, 100);
        }

        ssoFound = false;
        isoFound = false;
      }
    }
    if (urlParams[i] == "sso") {
      ssoFound = true;
    }
    if (urlParams[i] == "iso") {
      isoFound = true;
    }
  }
};

export const redirectSearchToWishlist = (search) => {
  if (search.indexOf('?orderNumber=') > -1) {
    var orderNumberTemp, orderNumber;
    orderNumberTemp = search.split('?orderNumber=');
    orderNumber = orderNumberTemp[1];

    shoppingCartViewStore.updateOrderNumber(orderNumber);
    applicationActions.changeRouterStateDirectly(RouterStates.WISHLIST);

    location.search = '';
    location.hash = '#/' + getAppLanguage() + '/dealer/' + getAppDealerId() + '/wishlist/' + orderNumber;
  }
};

export const processNewHash = (newHash) => {
  return { fireHashChange: shouldFireHash(newHash), hash: newHash };
};

const shouldFireHash = (newHash) => {
  return !isEmpty(newHash)
    && ((location.hash.substring(1) !== newHash
      && newHash.indexOf('/year/null') === -1
      && newHash.indexOf('/make/null') === -1
      && newHash.indexOf('/model/null') === -1
      && newHash.indexOf('/trim/undefined') === -1
      && newHash.indexOf('/tires/season/null') === -1
      && newHash.indexOf('/year/null/make/null/category/null/model/null') === -1)
    || newHash.indexOf('/year/null/make/null/category/null/model/null/trim/null/vehicle/null/product/') > -1);
};

export const getUrlOrigin = (hasHash) => {
  var origin = "";
  //var origin = window.location.origin;
  if (isDealerSite()) {

    if (checkSiteHasCampaign()) {
      return {
        url: origin + '/marketing/dealer/' + getAppUrl() + '/campaign/' + getCampaignCode(),
        offset: hasHash ? 4 : 9
      };
    } else {
      return {
        url: origin + '/' + getAppUrl(),
        offset: hasHash ? 4 : 5
      };
    }

  } else if (isNationalSite()) {

    if (checkSiteHasCampaign()) {

      var searchCode = window.campaignData.campaignSearchCode, searchText = window.campaignData.campaignSearchText;
      if (!isEmpty(searchCode) && !isEmpty(searchText)) {
        return {
          url: origin + '/marketing/national/' + getAppUrl() + '/campaign/' + getCampaignCode() + '/dealerNumber/' + searchCode + '/dealerSearch/' + searchText,
          offset: hasHash ? 4 : 13
        };
      } else if (!isEmpty(searchCode)) {
        return {
          url: origin + '/marketing/national/' + getAppUrl() + '/campaign/' + getCampaignCode() + '/dealerNumber/' + searchCode,
          offset: hasHash ? 4 : 11
        };
      } else if (!isEmpty(searchText)) {
        return {
          url: origin + '/marketing/national/' + getAppUrl() + '/campaign/' + getCampaignCode() + '/dealerSearch/' + searchText,
          offset: hasHash ? 4 : 11
        };
      } else {
        return {
          url: origin + '/marketing/national/' + getAppUrl() + '/campaign/' + getCampaignCode(),
          offset: hasHash ? 4 : 9
        };
      }

    } else {
      return {
        url: origin + '/national/' + getAppUrl(),
        offset: hasHash ? 4 : 6
      };
    }

  } else if (isGroupSite()) {
    return {
      url: origin + '/group/' + getAppUrl(),
      offset: hasHash ? 4 : 6
    };
  } else if (isConsumerSite()) {
    return {
      url: origin,
      offset: hasHash ? 4 : 6
    };
  }
};
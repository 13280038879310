import React from "react";
import ReactDOM from "react-dom";

import App from "./LegacyApplication.jsx";

var counter = 0;

function run() {
  const node = document.getElementById("app");
  if (node) {
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(<App />, node);
  } else if (counter < 5) {
    counter++;
    setTimeout(() => {
      run();
    }, 100);
  }
}

if (
  ["complete", "interactive", "loaded"].includes(document.readyState) &&
  document.body
) {
  run();
} else {
  window.addEventListener("DOMContentLoaded", run, false);
}

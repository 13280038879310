import { AbstractStore } from './AbstractStore.js';
import { Router } from './Router.js';

import { DispatcherActions } from '../constants/DispatcherActions.js';
import { Languages } from '../constants/Languages.js';

import { getAppLanguage } from "../service/UtilityService";

var DotAccess = require('dot-access');
var _ = require('lodash');

require('../translates/dealers/acura_en');
require('../translates/dealers/acura_fr');
require('../translates/dealers/audi_2021_en');
require('../translates/dealers/audi_2021_fr');
require('../translates/dealers/audi_en');
require('../translates/dealers/audi_fr');
require('../translates/dealers/bmw_en');
require('../translates/dealers/bmw_fr');
require('../translates/dealers/chrysler_en');
require('../translates/dealers/chrysler_fr');
require('../translates/dealers/consumer_en');
require('../translates/dealers/consumer_fr');
require('../translates/dealers/ford_en');
require('../translates/dealers/ford_fr');
require('../translates/dealers/fungroup_en');
require('../translates/dealers/fungroup_fr');
require('../translates/dealers/generic_en');
require('../translates/dealers/generic_fr');
require('../translates/dealers/gmcanada_en');
require('../translates/dealers/gmcanada_fr');
require('../translates/dealers/dealerplus_en');
require('../translates/dealers/dealerplus_fr');
require('../translates/dealers/honda_en');
require('../translates/dealers/honda_fr');
require('../translates/dealers/hyundai_en');
require('../translates/dealers/hyundai_fr');
require('../translates/dealers/integra_en');
require('../translates/dealers/integra_fr');
require('../translates/dealers/kia_en');
require('../translates/dealers/kia_fr');
require('../translates/dealers/kia_2021_en');
require('../translates/dealers/kia_2021_fr');
require('../translates/dealers/lexus_en');
require('../translates/dealers/lexus_fr');
require('../translates/dealers/mazda_en');
require('../translates/dealers/mazda_fr');
require('../translates/dealers/mazdatransition_en');
require('../translates/dealers/mazdatransition_fr');
require('../translates/dealers/mecanipneu_en');
require('../translates/dealers/mecanipneu_fr');
require('../translates/dealers/mercedes_en');
require('../translates/dealers/mercedes_2022_en');
require('../translates/dealers/mercedes_2022_fr');
require('../translates/dealers/mercedes_fr');
require('../translates/dealers/mercedestransition_en');
require('../translates/dealers/mercedestransition_fr');
require('../translates/dealers/mini_en');
require('../translates/dealers/mini_fr');
require('../translates/dealers/mrlube_en');
require('../translates/dealers/mrlube_fr');
require('../translates/dealers/ohda_en');
require('../translates/dealers/ohda_fr');
require('../translates/dealers/oregans_en');
require('../translates/dealers/oregans_fr');
require('../translates/dealers/pneuselect_en');
require('../translates/dealers/pneuselect_fr');
require('../translates/dealers/southeasttoyota_en');
require('../translates/dealers/southeasttoyota_fr');
require('../translates/dealers/subaru_en');
require('../translates/dealers/subaru_fr');
require('../translates/dealers/tirecraft_en');
require('../translates/dealers/tirecraft_fr');
require('../translates/dealers/toyota_en');
require('../translates/dealers/toyota_fr');
require('../translates/dealers/toyotausa_en');
require('../translates/dealers/toyotausa_fr');
require('../translates/dealers/volkswagen_en');
require('../translates/dealers/volkswagen_fr');
require('../translates/dealers/volkswagen_2020_en');
require('../translates/dealers/volkswagen_2020_fr');
require('../translates/dealers/template_en');
require('../translates/dealers/template_fr');

/**
 * LanguageStore is responsible for holding current language and providing right Locales and Messages.
 * This is intentionally not in RouterStore as RouterStore's responsibility is only routing
 * although lang attribute has to be on two places. It is also separated so that we can easily
 * bind callbacks on language change -> because when language is changed entire Application is
 * re-rendered.
 */
var LanguageStore = AbstractStore.extend({

  constructor: function() {
    this.data = {};
    this.data.lang = '';

    LanguageStore.__super__.constructor.apply(this, arguments);
  },

  getActionHandlers: function() {
    var handlers = {};

    handlers[DispatcherActions.CHANGE_LANG] = this.onChangeLang;
    handlers[DispatcherActions.INITIALIZE_APP_STATE] = this.onInitializeAppState;

    return handlers;
  },

  onInitializeAppState: function(appState) {
    var routerState = Router.decodeRouterState(appState);

    this.loadAppState('lang', appState, routerState.params);

    this.emitChange();
  },

  loadAppState: function(key, appState, params) {
    var temp;
    if (params[key]) {
      temp = params;
      this.data[key] = temp[key];
    } else if (appState[key]) {
      temp = appState;
      this.data[key] = temp[key];
    }
  },

  onChangeLang: function(lang) {
    this.data.lang = lang;
    this.emitChange();
  },

  getTranslated: function(value) {
    if (value && value[this.data.lang]) {
      return value[this.data.lang];
    } else {
      return '';
    }
  },

  getDictionary: function() {
    return this.getMessages();
  },

  getDictionaryTranslate: function(key, lang) {
    return DotAccess.get(this.getMessages(lang), key);
  },

  getLocale: function() {
    switch (getAppLanguage()) {
      case Languages.EN:
        return 'en-CA';
      case Languages.FR:
        return 'fr-FR';
      default:
        throw new Error('Not supported language');
    }
  },

  getMessages: function(lang) {
    var defaultMessages, brandSpecificMessages;
    switch (getAppLanguage()) {
      case Languages.EN:
        defaultMessages = require('../translates/en');
        brandSpecificMessages = this.data.languageTemplate ? require('../translates/dealers/' + this.data.languageTemplate + '_en') : {};
        return _.merge(defaultMessages, brandSpecificMessages);
      case Languages.FR:
        defaultMessages = require('../translates/fr');
        brandSpecificMessages = this.data.languageTemplate ? require('../translates/dealers/' + this.data.languageTemplate + '_fr') : {};
        return _.merge(defaultMessages, brandSpecificMessages);
      default:
        defaultMessages = require('../translates/en');
        brandSpecificMessages = this.data.languageTemplate ? require('../translates/dealers/' + this.data.languageTemplate + '_en') : {};
        return _.merge(defaultMessages, brandSpecificMessages);
    }
  },

  setCurrency: function(currency) {
    this.data.currency = currency;
  }

});


export var languageStore = new LanguageStore();

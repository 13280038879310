import { i18nMixin } from "../../i18n/i18nMixin.js";

import { Modal } from "../ux/Modal.jsx";
import { Image } from "../ux/Image.jsx";

import { applicationStore } from "../../stores/ApplicationStore.js";
import { applicationActions } from "../../actions/ApplicationActions.js";

import { shoppingCartStore } from "../../stores/ShoppingCartStore.js";
import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";

import { RouterStates } from "../../constants/RouterStates.js";

import {
  get_browser,
  isEmpty,
  sizeOf,
  money_format,
  getDealerName,
  getAppLanguage,
  getTrackingEventCategory,
  getRouterState,
} from "../../service/UtilityService";
import { getOrderPickUpStatus } from "../../service/OrderService";
import { loadWishlistWithContext } from "../../service/WishlistService";
import { isUserLoggedIn } from "../../api/AuthService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var _ = require("lodash");
var Immutable = require("immutable");
var Moment = require("moment");
var Cx = require("classnames");

const ORDER_STATUS_FILLED = "FILLED";

export class NTDOrderModal extends I18nComponent {
  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("NTD Order Modal");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "NTD Order Modal Access"
    );
    applicationStore.addChangeListener(this.onChange);
    shoppingCartStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
    shoppingCartStore.removeChangeListener(this.onChange);
    this.clearModalData();
  }

  componentDidMount() {
    if (!this.state.allowWebserviceCallFlag) {
      if (this.props.enablePrompt) this.onGatherAndStoreXtimeLink();
      setTimeout(() => {
        shoppingCartActions.loadNTDOrderPreview();
      }, 100);
      this.setState({ allowWebserviceCallFlag: true });
    }
  }

  shouldComponentUpdate(lastProps, nextState) {
    if (
      (isEmpty(this.state.data) && !isEmpty(nextState.data)) ||
      (!this.state.customerInfoEditable && nextState.customerInfoEditable)
    ) {
      var customerFirstName = "",
        customerLastName = "",
        customerPhone = "",
        customerEmail = "";
      if (!isEmpty(nextState.orderData)) {
        if (
          !isEmpty(nextState.orderData.customerName) &&
          nextState.orderData.customerName.indexOf(" ") > -1
        ) {
          customerFirstName = nextState.orderData.customerName
            .trim()
            .split(" ")[0];
          customerLastName = nextState.orderData.customerName
            .trim()
            .split(" ")[1];
        } else {
          customerFirstName = !isEmpty(nextState.orderData.customerName)
            ? nextState.orderData.customerName
            : "";
        }
        customerPhone = !isEmpty(nextState.orderData.customerPhone)
          ? nextState.orderData.customerPhone
          : "";
        customerEmail = !isEmpty(nextState.orderData.customerEmail)
          ? nextState.orderData.customerEmail
          : "";
      } else if (!isEmpty(nextState.data)) {
        if (
          !isEmpty(nextState.data.customerName) &&
          nextState.data.customerName.indexOf(" ") > -1
        ) {
          customerFirstName = nextState.data.customerName.trim().split(" ")[0];
          customerLastName = nextState.data.customerName.trim().split(" ")[1];
        } else {
          customerFirstName = !isEmpty(nextState.data.customerName)
            ? nextState.data.customerName
            : "";
        }
        customerPhone = !isEmpty(nextState.data.customerPhone)
          ? nextState.data.customerPhone
          : "";
        customerEmail = !isEmpty(nextState.data.customerEmail)
          ? nextState.data.customerEmail
          : "";
      }
      setTimeout(() => {
        if (
          !isEmpty(customerFirstName) ||
          !isEmpty(customerLastName) ||
          !isEmpty(customerPhone) ||
          !isEmpty(customerEmail)
        ) {
          if (document.getElementById("customer-first-name"))
            document.getElementById("customer-first-name").value = !isEmpty(
              customerFirstName
            )
              ? customerFirstName.trim()
              : "";
          if (document.getElementById("customer-last-name"))
            document.getElementById("customer-last-name").value = !isEmpty(
              customerLastName
            )
              ? customerLastName.trim()
              : "";
          if (document.getElementById("customer-phone"))
            document.getElementById("customer-phone").value = !isEmpty(
              customerPhone
            )
              ? customerPhone.trim()
              : "";
          if (document.getElementById("customer-email"))
            document.getElementById("customer-email").value = !isEmpty(
              customerEmail
            )
              ? customerEmail.trim()
              : "";
        }
      }, 250);
    }
    return this.props !== lastProps || this.state !== nextState;
  }

  clearModalData = () => {
    this.setState({
      data: null,
      orderData: null,
      previewFailed: false,
      orderPickUpStatus: false,
      placeOrderShippingDisclaimerAgreementModal: false,
      placeOrderShippingDisclaimerAgreementWithBookAppointmentModal: false,
      placeOrderPurchaseOrderNumberModal: false,
      placeOrderReturnData: null,
      placeOrderShowModal: false,
      showNonOrderableItemsList: true,
      shippingChargesCheck: true,
      customerInfoEditable: false,
    });
  };

  renderModalIsValid = () => {
    return (
      !isEmpty(this.state.data) &&
      !this.state.previewFailed &&
      !this.state.orderFailed &&
      (!this.state.placeOrderShowModal || !isEmpty(this.state.orderData)) &&
      !this.showOnlyXtime()
    );
  };

  renderDeliveriesIsValid = () => {
    var orderShippedWithOtherGoods = this.state.data
      ? this.state.data.orderShippedWithOtherGoods
      : false;
    return (
      !orderShippedWithOtherGoods &&
      this.state.data &&
      !this.state.data.hasFreightItems &&
      !this.state.data.hasLocalPlusItems &&
      isEmpty(this.state.orderData)
    );
  };

  renderDeliveryMessageIsValid = () => {
    var orderShippedWithOtherGoods = this.state.data
      ? this.state.data.orderShippedWithOtherGoods
      : false;
    return (
      orderShippedWithOtherGoods &&
      this.state.data &&
      isEmpty(this.state.orderData)
    );
  };

  showOnlyXtime = () => {
    return (
      !this.state.dealerEnablePromptToOrderTires && this.props.enablePrompt
    );
  };

  state = {
    data: shoppingCartStore.data.get("ntdOrderData"),
    previewFailed: shoppingCartStore.data.get("ntdPreviewFailed"),
    orderData: shoppingCartStore.data.get("ntdPlaceOrderData"),
    orderFailed: shoppingCartStore.data.get("ntdPlaceOrderFailed"),
    orderPickUpStatus: getOrderPickUpStatus(
      shoppingCartStore.data.get("ntdPlaceOrderData")
    ),
    loggedIn: isUserLoggedIn(),
    placeOrderShippingDisclaimerAgreementModal: false,
    placeOrderShippingDisclaimerAgreementWithBookAppointmentModal: false,
    placeOrderPurchaseOrderNumberModal: false,
    placeOrderReturnData: null,
    placeOrderShowModal: false,
    allowWebserviceCallFlag: false,
    showNonOrderableItemsList: true,
    shippingChargesCheck: false,
    customerInfoEditable: false,
    dealerXtimeAppointmentIntegrationLink:
      applicationStore.data.dealerXtimeAppointmentIntegrationLink,
    dealerEnablePromptToOrderTires:
      applicationStore.data.dealerEnablePromptToOrderTires,
  };

  render() {
    var nonOrderItemList = [];
    if (
      !isEmpty(this.state.data) &&
      this.state.data.hasOwnProperty("nonOrderItemList")
    ) {
      nonOrderItemList = this.state.data.nonOrderItemList;
    }
    var image =
      getAppLanguage() === "en"
        ? "/static/img/ntd_logo_en.png"
        : "/static/img/ntd_logo_fr.png";
    return (
      <Modal
        className="ntd-modal"
        applyTemplateStyle={true}
        fullHeader={true}
        requestUnmount={this.onCloseModal}
      >
        {this.renderModalIsValid() ? (
          <div className="ntd-order-modal">
            {!this.props.enablePrompt ? this.renderHeader() : false}
            {this.props.enablePrompt ? this.renderHasBookingHeader() : false}
            {this.props.enablePrompt ? this.renderOrderItemsList() : false}
            {this.props.enablePrompt
              ? this.renderHasBookingPlaceOrder()
              : false}
            {this.props.enablePrompt
              ? this.renderHasBookingOrderPlaced()
              : false}
            {this.props.enablePrompt &&
            this.state.dealerXtimeAppointmentIntegrationLink
              ? this.renderHasBookingIframe()
              : false}

            {this.renderOrderDisclaimer()}
            {this.renderOrderSecondDisclaimer()}

            {this.renderDeliveriesIsValid()
              ? this.renderOrderDeliveries()
              : false}

            {this.renderDeliveryMessageIsValid()
              ? this.renderOrderDeliveryMessage()
              : false}

            {this.renderOrderTable()}
          </div>
        ) : (
          false
        )}

        {(isEmpty(this.state.data) &&
          !this.props.enablePrompt &&
          !this.state.previewFailed) ||
        (this.state.placeOrderShowModal &&
          isEmpty(this.state.orderData) &&
          !this.state.orderFailed) ? (
          <div className="ntd-order-modal">
            <div className="title-bar">
              <div className="title-logo">
                <Image className="ntd-image" src={image} />
              </div>
              <h2 className="ntd-order-title">
                {this.state.placeOrderShowModal
                  ? this.t("ntdModal.sendingOrder")
                  : this.t("ntdModal.previewLoading")}
              </h2>
            </div>
            <div className="loading-gif">
              <Image src="/static/img/loading_icon.gif" />
            </div>
          </div>
        ) : (
          false
        )}

        {(isEmpty(this.state.data) &&
          this.props.enablePrompt &&
          !this.showOnlyXtime() &&
          !this.state.previewFailed) ||
        (this.state.placeOrderShowModal &&
          isEmpty(this.state.orderData) &&
          !this.state.orderFailed) ? (
          <div className="ntd-order-modal">
            {this.renderHasBookingHeader()}
            <div className="loading-gif">
              <Image src="/static/img/loading_icon.gif" />
            </div>
          </div>
        ) : (
          false
        )}

        {(isEmpty(this.state.data) &&
          !this.props.enablePrompt &&
          this.state.previewFailed) ||
        this.state.orderFailed ? (
          <div className="ntd-order-modal">
            <div className="title-bar">
              <div className="title-logo">
                <Image className="ntd-image" src={image} />
              </div>
              <h2 className="ntd-order-title">
                {this.state.previewFailed
                  ? this.t("ntdModal.previewFailed")
                  : this.t("ntdModal.failedToSend")}
              </h2>
            </div>
            <div className="error-message">
              <p>{this.t("ntdModal.ntdFailed")}</p>
            </div>
          </div>
        ) : (
          false
        )}

        {(isEmpty(this.state.data) &&
          this.props.enablePrompt &&
          this.state.previewFailed) ||
        this.state.orderFailed ||
        this.showOnlyXtime() ? (
          <div className="ntd-order-modal">
            {this.renderHasBookingHeader()}
            {this.renderOrderItemsList()}
            {!this.showOnlyXtime() ? this.renderHasBookingPlaceOrder() : false}
            {!this.showOnlyXtime() ? this.renderHasBookingOrderPlaced() : false}
            {this.renderHasBookingIframe()}
          </div>
        ) : (
          false
        )}

        {this.state.placeOrderShippingDisclaimerAgreementModal ||
        this.state.placeOrderPurchaseOrderNumberModal ? (
          <Modal
            applyTemplateStyle={true}
            useShortHeaderBar={true}
            requestUnmount={this.onCloseWarningModal}
          >
            <div className="order-return">
              <div className="title-bar">
                <div className="title-logo">
                  <Image className="ntd-image" src={image} />
                </div>
                <h2 className="ntd-order-title">
                  {this.t("ntdModal.orderWarning")}
                </h2>
              </div>
              <div className="message">
                {this.state.placeOrderShippingDisclaimerAgreementModal ? (
                  <span>{this.t("ntdModal.warningShipping")}</span>
                ) : (
                  false
                )}
                {this.state.placeOrderPurchaseOrderNumberModal ? (
                  <span>{this.t("ntdModal.warningPO")}</span>
                ) : (
                  false
                )}
              </div>
              <div className="controls">
                {!this.state.placeOrderShippingDisclaimerAgreementModal ? (
                  <button
                    className="btn btn-continue"
                    onClick={this.onContinuePlaceOrder}
                  >
                    {this.t("ntdModal.continue")}
                  </button>
                ) : (
                  false
                )}
                <button
                  className={
                    !this.state.placeOrderShippingDisclaimerAgreementModal
                      ? "btn btn-go-back"
                      : "btn"
                  }
                  onClick={this.onCloseWarningModal}
                >
                  {this.t("ntdModal.goBack")}
                </button>
              </div>
            </div>
          </Modal>
        ) : (
          false
        )}

        {this.state
          .placeOrderShippingDisclaimerAgreementWithBookAppointmentModal ? (
          <Modal
            applyTemplateStyle={true}
            useShortHeaderBar={true}
            requestUnmount={this.onCloseWarningModal}
          >
            <div className="order-return">
              <div className="title-bar">
                <div className="title-logo">
                  <Image className="ntd-image" src={image} />
                </div>
                <h2 className="ntd-order-title">
                  {this.t("ntdModal.orderWarning")}
                </h2>
              </div>
              <div className="message">
                <span>{this.t("ntdModal.warningPOBookAppointment")}</span>
              </div>
              <div className="input">
                <span>{this.t("ntdModal.poNumber")}</span>
                <input
                  type="text"
                  className="purchase-order-input"
                  id="purchase-order-input-has-booking"
                />
              </div>
              <div className="controls">
                <button
                  className="btn"
                  onClick={this.onContinuePlaceOrder.bind(this, true)}
                >
                  {this.t("ntdModal.placeOrder")}
                </button>
              </div>
            </div>
          </Modal>
        ) : (
          false
        )}

        {this.state.showNonOrderableItemsList &&
        sizeOf(nonOrderItemList) > 0 ? (
          <Modal
            applyTemplateStyle={true}
            useShortHeaderBar={true}
            requestUnmount={this.onCloseWarningModal}
          >
            <div className="order-return">
              <div className="title-bar">
                <div className="title-logo">
                  <Image className="ntd-image" src={image} />
                </div>
                <h2 className="ntd-order-title">
                  {this.t("ntdModal.orderWarning")}
                </h2>
              </div>
              <div className="message">
                <span>{this.t("ntdModal.itemsNotAvailableToOrder") + ":"}</span>
                <div className="nonorderable-list">
                  {nonOrderItemList.map(function (itemTitle, index) {
                    return <span key={"item-title-" + index}>{itemTitle}</span>;
                  })}
                </div>
              </div>
              <div className="controls">
                <button
                  className="btn"
                  onClick={this.onCloseNonOrderableItemsList}
                >
                  {this.t("ntdModal.continue")}
                </button>
              </div>
            </div>
          </Modal>
        ) : (
          false
        )}
      </Modal>
    );
  }

  renderOrderItemsList = () => {
    var language = getAppLanguage();
    return (
      <div className="order-items-list">
        {this.state.data
          ? this.state.data.orderItems.map(function (
              orderItem,
              orderItemIndex
            ) {
              return (
                <div className="list-item">
                  {orderItem.totalQuantity ? (
                    <span>{orderItem.totalQuantity}</span>
                  ) : (
                    false
                  )}
                  {orderItem.totalQuantity &&
                  orderItem.productTitle[language] ? (
                    <span> - </span>
                  ) : (
                    false
                  )}
                  {orderItem.productTitle[language] ? (
                    <span>{orderItem.productTitle[language]}</span>
                  ) : (
                    false
                  )}
                  {orderItem.productTitle[language] &&
                  orderItem.productPartNumber ? (
                    <span> </span>
                  ) : (
                    false
                  )}
                  {orderItem.productPartNumber ? (
                    <span>( {orderItem.productPartNumber} )</span>
                  ) : (
                    false
                  )}
                </div>
              );
            })
          : false}
      </div>
    );
  };

  renderHeader = () => {
    var image =
      getAppLanguage() === "en"
        ? "/static/img/ntd_logo_en.png"
        : "/static/img/ntd_logo_fr.png";
    return (
      <div className="title-bar">
        <div className="title-logo">
          <Image className="ntd-image" src={image} />
        </div>
        <h2 className="ntd-order-title">
          {isEmpty(this.state.orderData)
            ? this.t("ntdModal.title")
            : this.t("ntdModal.titlePlaced")}
        </h2>
      </div>
    );
  };

  renderHasBookingHeader = () => {
    return (
      <div className="title">
        <h2 className="appointment-title">
          {this.t("ntdModal.appointmentScheduler")}
        </h2>
      </div>
    );
  };

  renderHasBookingPlaceOrder = () => {
    if (isEmpty(this.state.orderData) && this.state.loggedIn) {
      return (
        <div className="order-disclaimer xtime-prompt place-order-btn">
          <button
            className="btn place-order"
            onClick={this.onPlaceOrder.bind(this, true)}
          >
            {this.t("ntdModal.placeOrder")}
          </button>
        </div>
      );
    } else return false;
  };

  renderHasBookingOrderPlaced = () => {
    if (!isEmpty(this.state.orderData)) {
      return (
        <div className="order-disclaimer xtime-prompt order-placed">
          <i className="fa fa-check" />
          <span>{this.t("ntdModal.orderProcessed")}</span>
          <span className="order-number">
            {" #" + this.state.orderData.orderNumber}
          </span>
        </div>
      );
    } else return false;
  };

  renderHasBookingIframe = () => {
    return (
      <div className="xtime-container">
        <iframe
          className="xtime-iframe"
          src={this.state.dealerXtimeAppointmentIntegrationLink}
          scrolling="yes"
        />
      </div>
    );
  };

  renderOrderDisclaimer = () => {
    var isTestOrder = this.state.data && this.state.data.isTest ? true : false;
    var orderHistoryLink = (
      <a className="order-history" onClick={this.onOpenOrderHistory}>
        {this.t("ntdModal.orderHistoryHere")}
      </a>
    );
    return (
      <div className="order-disclaimer">
        <p>
          {isEmpty(this.state.orderData) && isTestOrder ? (
            <span className="test-order-text">
              {this.t("ntdModal.orderIsTest")}
            </span>
          ) : (
            false
          )}
          {isEmpty(this.state.orderData)
            ? this.t("ntdModal.orderDisclaimer")
            : this.t("ntdModal.confirmationNumber_part1") +
              this.state.orderData.orderNumber +
              this.t("ntdModal.confirmationNumber_part2")}
          {!isEmpty(this.state.orderData) ? orderHistoryLink : false}
        </p>
      </div>
    );
  };

  renderOrderSecondDisclaimer = () => {
    return (
      <div className="order-disclaimer">
        <p>{this.t("ntdModal.orderDisclaimer2")}</p>
      </div>
    );
  };

  renderOrderDeliveries = () => {
    return (
      <div className="order-delivery">
        <div className="delivery-toggle">
          <label className="switch">
            <input
              type="checkbox"
              value={this.state.orderPickUpStatus}
              onClick={this.togglePickUpOrder}
            />
            <div className="slider round"></div>
          </label>
        </div>
        <div className="delivery-label">
          <label>
            {this.state.orderPickUpStatus
              ? this.t("ntdModal.togglePickUp")
              : this.t("ntdModal.toggleDeliver")}
          </label>
        </div>
      </div>
    );
  };

  renderOrderDeliveryMessage = () => {
    return (
      <div className="order-delivery">
        <label className="other-goods-text">
          <span className="title">{this.t("ntdModal.shippingMethod")}</span>
          {this.t("ntdModal.toggleWithOtherGoods")}
        </label>
      </div>
    );
  };

  renderOrderTable = () => {
    var _this = this,
      browser = get_browser();
    var partNumberText = this.t("product.partNumber") + ":";
    var dealerName = getDealerName();
    var dealerInfo = applicationStore.data.dealerInfo;
    var orderPickUpStatus = this.state.orderPickUpStatus;
    var orderSubtotal = 0,
      freightTotal = 0;
    var orderShippedWithOtherGoods = this.state.data
      ? this.state.data.orderShippedWithOtherGoods
      : false;
    var customerFirstName = "",
      customerLastName = "",
      customerPhone = "",
      customerEmail = "";
    if (!isEmpty(this.state.orderData)) {
      if (
        !isEmpty(this.state.orderData.customerName) &&
        this.state.orderData.customerName.indexOf(" ") > -1
      ) {
        customerFirstName = this.state.orderData.customerName
          .trim()
          .split(" ")[0];
        customerLastName = this.state.orderData.customerName
          .trim()
          .split(" ")[1];
      } else {
        customerFirstName = !isEmpty(this.state.orderData.customerName)
          ? this.state.orderData.customerName
          : "";
      }
      customerPhone = !isEmpty(this.state.orderData.customerPhone)
        ? this.state.orderData.customerPhone
        : "";
      customerEmail = !isEmpty(this.state.orderData.customerEmail)
        ? this.state.orderData.customerEmail
        : "";
    }

    var isTestOrder = this.state.data && this.state.data.isTest ? true : false;
    return (
      <div className="product-table">
        <div className="product-table-title">
          <h3>{this.t("ntdModal.itemInformation")}</h3>
        </div>
        <div className="table">
          <table className="order-table">
            <thead>
              <tr className="title-row">
                <th className="title-cell">
                  {this.t("ntdModal.tableProduct")}
                </th>
                <th>{this.t("ntdModal.tablePayment")}</th>
                <th>{this.t("ntdModal.tableQuantity")}</th>
                <th>{this.t("ntdModal.tableDeliveryDate")}</th>
                <th>{this.t("ntdModal.tableUnitCost")}</th>
                <th>{this.t("ntdModal.tableTotal")}</th>
                <th>{this.t("ntdModal.tableDelivery")}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data
                ? this.state.data.orderItems.map(function (
                    orderItem,
                    orderItemIndex
                  ) {
                    var orderQuantityBreakDown = false,
                      orderShipMethod = false,
                      orderEstDeliveryDate = false,
                      orderProductCost = false,
                      orderProductTotal = false,
                      orderProductDelivery = false,
                      orderProductDeliveryIcon = false,
                      orderProductDeliveryText = false,
                      orderProductDeliveryClassName = "",
                      tdClassName = "";
                    if (orderItem.orderBreakDown.hasOwnProperty("status")) {
                      //single delivery
                      if (
                        orderItem.orderBreakDown.status === ORDER_STATUS_FILLED
                      ) {
                        orderQuantityBreakDown = (
                          <div className="delivery-quantity">
                            <span>
                              {orderItem.orderBreakDown.quantity}{" "}
                              {_this.t("ntdModal.of")} {orderItem.totalQuantity}
                            </span>
                          </div>
                        );
                        var shipMethod = "";
                        if (parseFloat(orderItem.orderBreakDown.freight) > 0)
                          shipMethod = _this.t("ntdModal.shipMethodNational");
                        else if (!orderItem.hasLocalStock)
                          shipMethod = _this.t("ntdModal.shipMethodLocalPlus");
                        else shipMethod = _this.t("ntdModal.shipMethodLocal");
                        orderShipMethod = (
                          <div className="delivery-shipment-method">
                            <span>{shipMethod}</span>
                          </div>
                        );
                        orderEstDeliveryDate = (
                          <div
                            className={
                              !!orderItem.orderBreakDown.estimatedDelivery
                                ? "delivery-est-date"
                                : "delivery-est-date empty-cell"
                            }
                          >
                            <span>
                              {!orderShippedWithOtherGoods
                                ? Moment(
                                    orderItem.orderBreakDown.estimatedDelivery
                                  ).format("llll")
                                : false}
                            </span>
                          </div>
                        );
                        orderProductCost = (
                          <div className="delivery-unit-cost">
                            <span>
                              {parseFloat(
                                orderItem.orderBreakDown.productCost
                              ) > 0
                                ? money_format(
                                    orderItem.orderBreakDown.productCost
                                  )
                                : _this.t("ntdModal.nationalPricing")}
                            </span>
                          </div>
                        );
                        orderProductTotal = (
                          <div className="delivery-total">
                            <span>
                              {parseFloat(orderItem.orderBreakDown.orderTotal) >
                              0
                                ? money_format(
                                    orderItem.orderBreakDown.orderTotal
                                  )
                                : _this.t("ntdModal.nationalPricing")}
                            </span>
                          </div>
                        );
                        orderProductDeliveryIcon = orderPickUpStatus ? (
                          <i className="fa fa-clipboard"></i>
                        ) : (
                          <i className="fa fa-truck"></i>
                        );
                        orderProductDeliveryText =
                          !orderShippedWithOtherGoods ? (
                            orderPickUpStatus ? (
                              <label className="delivery-type-label">
                                {_this.t("ntdModal.pickUp")}
                              </label>
                            ) : (
                              <label className="delivery-type-label">
                                {_this.t("ntdModal.delivery")}
                              </label>
                            )
                          ) : (
                            <label className="delivery-type-label">
                              {_this.t("ntdModal.withOtherGoods")}
                            </label>
                          );
                        orderProductDeliveryClassName = Cx({
                          "delivery-method": true,
                          "pick-up": orderPickUpStatus,
                          delivery: !orderPickUpStatus,
                          "has-freight-charge":
                            parseFloat(orderItem.orderBreakDown.freight) > 0,
                        });
                        orderProductDelivery = (
                          <div className={orderProductDeliveryClassName}>
                            {orderProductDeliveryIcon}
                            {orderProductDeliveryText}
                            {parseFloat(orderItem.orderBreakDown.freight) >
                            0 ? (
                              <span className="delivery-freight">
                                {_this.t("ntdModal.freight")}
                                {money_format(
                                  parseFloat(orderItem.orderBreakDown.freight)
                                )}
                              </span>
                            ) : (
                              false
                            )}
                          </div>
                        );
                        if (parseFloat(orderItem.orderBreakDown.freight) > 0)
                          freightTotal += parseFloat(
                            orderItem.orderBreakDown.freight
                          );
                        if (parseFloat(orderItem.orderBreakDown.orderTotal) > 0)
                          orderSubtotal += parseFloat(
                            orderItem.orderBreakDown.orderTotal
                          );
                      } else {
                        orderQuantityBreakDown = (
                          <div className="delivery-quantity">
                            <span className="not-available">
                              {orderItem.orderBreakDown.quantity}{" "}
                              {_this.t("ntdModal.of")} {orderItem.totalQuantity}
                            </span>
                          </div>
                        );
                        orderShipMethod = (
                          <div className="delivery-shipment-method">
                            <span className="not-available">
                              {_this.t("ntdModal.notAvailable")}
                            </span>
                          </div>
                        );
                        orderEstDeliveryDate = (
                          <div className="delivery-est-date">
                            <span className="not-available">
                              {_this.t("ntdModal.notAvailable")}
                            </span>
                          </div>
                        );
                        orderProductCost = (
                          <div className="delivery-unit-cost">
                            <span className="not-available">
                              {parseFloat(
                                orderItem.orderBreakDown.productCost
                              ) > 0
                                ? money_format(
                                    orderItem.orderBreakDown.productCost
                                  )
                                : _this.t("ntdModal.nationalPricing")}
                            </span>
                          </div>
                        );
                        orderProductTotal = (
                          <div className="delivery-total">
                            <span className="not-available">
                              {parseFloat(orderItem.orderBreakDown.orderTotal) >
                              0
                                ? money_format(
                                    orderItem.orderBreakDown.orderTotal
                                  )
                                : _this.t("ntdModal.nationalPricing")}
                            </span>
                          </div>
                        );
                        orderProductDeliveryClassName = Cx({
                          "delivery-method": true,
                          "pick-up": orderPickUpStatus,
                          delivery: !orderPickUpStatus,
                          "has-freight-charge":
                            parseFloat(orderItem.orderBreakDown.freight) > 0,
                        });
                        orderProductDelivery = (
                          <div className={orderProductDeliveryClassName}>
                            <span className="delivery-freight not-available">
                              {_this.t("ntdModal.notAvailable") +
                                " - " +
                                _this.t("ntdModal.willNotBeShipped")}
                            </span>
                          </div>
                        );
                      }
                    } else {
                      //multiple deliveries
                      var quantityClassName = "";
                      if (orderItemIndex > 0) tdClassName = "align-bottom";
                      else quantityClassName = "more-space";

                      orderQuantityBreakDown = (
                        <div className="delivery-quantity">
                          {orderItem.orderBreakDown.map(function (
                            delivery,
                            index
                          ) {
                            if (delivery.status === ORDER_STATUS_FILLED)
                              return (
                                <div key={"quantity-" + index}>
                                  {index !== 0 ? <hr /> : false}
                                  <span
                                    className={
                                      index === 0
                                        ? quantityClassName
                                        : quantityClassName + " not-first"
                                    }
                                    key={"span-quantity-" + index}
                                  >
                                    {delivery.quantity} {_this.t("ntdModal.of")}{" "}
                                    {orderItem.totalQuantity}
                                  </span>
                                </div>
                              );
                            else
                              return (
                                <div key={"quantity-" + index}>
                                  {index !== 0 ? <hr /> : false}
                                  <span
                                    className={
                                      index === 0
                                        ? quantityClassName + " not-available"
                                        : quantityClassName +
                                          " not-first not-available"
                                    }
                                    key={"span-quantity-" + index}
                                  >
                                    {delivery.quantity} {_this.t("ntdModal.of")}{" "}
                                    {orderItem.totalQuantity}
                                  </span>
                                </div>
                              );
                          })}
                        </div>
                      );

                      orderShipMethod = (
                        <div className="delivery-shipment-method">
                          {orderItem.orderBreakDown.map(function (
                            delivery,
                            index
                          ) {
                            var shipMethod = "";
                            if (parseFloat(delivery.freight) > 0)
                              shipMethod = _this.t(
                                "ntdModal.shipMethodNational"
                              );
                            else if (!orderItem.hasLocalStock || index > 0)
                              shipMethod = _this.t(
                                "ntdModal.shipMethodLocalPlus"
                              );
                            else
                              shipMethod = _this.t("ntdModal.shipMethodLocal");
                            if (delivery.status === ORDER_STATUS_FILLED)
                              return (
                                <div key={"shipment-method-" + index}>
                                  {index !== 0 ? <hr /> : false}
                                  <span
                                    key={"span-shipment-method-" + index}
                                    className="extend-height"
                                  >
                                    {shipMethod}
                                  </span>
                                </div>
                              );
                            else
                              return (
                                <div key={"shipment-method-" + index}>
                                  {index !== 0 ? <hr /> : false}
                                  <span
                                    key={"span-shipment-method-" + index}
                                    className="extend-height not-available"
                                  >
                                    {_this.t("ntdModal.notAvailable")}
                                  </span>
                                </div>
                              );
                          })}
                        </div>
                      );

                      orderEstDeliveryDate = (
                        <div className="delivery-est-date">
                          {orderItem.orderBreakDown.map(function (
                            delivery,
                            index
                          ) {
                            if (orderShippedWithOtherGoods)
                              return (
                                <div key={"est-date-" + index}>
                                  <span
                                    key={"span-est-date-" + index}
                                    className="extend-height empty-cell"
                                  ></span>
                                </div>
                              );
                            else if (delivery.status === ORDER_STATUS_FILLED)
                              return (
                                <div key={"est-date-" + index}>
                                  {index !== 0 ? <hr /> : false}
                                  <span
                                    key={"span-est-date-" + index}
                                    className={
                                      !!delivery.estimatedDelivery
                                        ? "extend-height"
                                        : "extend-height empty-cell"
                                    }
                                  >
                                    {Moment(delivery.estimatedDelivery).format(
                                      "llll"
                                    )}
                                  </span>
                                </div>
                              );
                            else
                              return (
                                <div key={"est-date-" + index}>
                                  {index !== 0 ? <hr /> : false}
                                  <span
                                    key={"span-est-date-" + index}
                                    className="extend-height delivery-date not-available"
                                  >
                                    {_this.t("ntdModal.notAvailable")}
                                  </span>
                                </div>
                              );
                          })}
                        </div>
                      );

                      orderProductCost = (
                        <div className="delivery-unit-cost">
                          {orderItem.orderBreakDown.map(function (
                            delivery,
                            index
                          ) {
                            if (delivery.status === ORDER_STATUS_FILLED)
                              return (
                                <div key={"unit-cost-" + index}>
                                  {index !== 0 ? <hr /> : false}
                                  <span
                                    key={"span-unit-cost-" + index}
                                    className="extend-height"
                                  >
                                    {parseFloat(delivery.productCost) > 0
                                      ? money_format(delivery.productCost)
                                      : _this.t("ntdModal.nationalPricing")}
                                  </span>
                                </div>
                              );
                            else
                              return (
                                <div key={"unit-cost-" + index}>
                                  {index !== 0 ? <hr /> : false}
                                  <span
                                    key={"span-unit-cost-" + index}
                                    className="extend-height not-available"
                                  >
                                    {parseFloat(delivery.productCost) > 0
                                      ? money_format(delivery.productCost)
                                      : _this.t("ntdModal.nationalPricing")}
                                  </span>
                                </div>
                              );
                          })}
                        </div>
                      );

                      orderProductTotal = (
                        <div className="delivery-total">
                          {orderItem.orderBreakDown.map(function (
                            delivery,
                            index
                          ) {
                            if (delivery.status === ORDER_STATUS_FILLED)
                              return (
                                <div key={"unit-cost-" + index}>
                                  {index !== 0 ? <hr /> : false}
                                  <span
                                    key={"span-unit-cost-" + index}
                                    className="extend-height"
                                  >
                                    {parseFloat(delivery.orderTotal) > 0
                                      ? money_format(delivery.orderTotal)
                                      : _this.t("ntdModal.nationalPricing")}
                                  </span>
                                </div>
                              );
                            else
                              return (
                                <div key={"unit-cost-" + index}>
                                  {index !== 0 ? <hr /> : false}
                                  <span
                                    key={"span-unit-cost-" + index}
                                    className="extend-height not-available"
                                  >
                                    {_this.t("ntdModal.nationalPricing")}
                                  </span>
                                </div>
                              );
                          })}
                        </div>
                      );

                      orderProductDeliveryIcon = orderPickUpStatus ? (
                        <i className="fa fa-clipboard"></i>
                      ) : (
                        <i className="fa fa-truck"></i>
                      );

                      orderProductDeliveryText = !orderShippedWithOtherGoods ? (
                        orderPickUpStatus ? (
                          <label className="delivery-type-label">
                            {_this.t("ntdModal.pickUp")}
                          </label>
                        ) : (
                          <label className="delivery-type-label">
                            {_this.t("ntdModal.delivery")}
                          </label>
                        )
                      ) : (
                        <label className="delivery-type-label">
                          {_this.t("ntdModal.withOtherGoods")}
                        </label>
                      );

                      orderProductDelivery = (
                        <div className="delivery-toggle">
                          {orderItem.orderBreakDown.map(function (
                            delivery,
                            index
                          ) {
                            var firstRowClassName = "extend-height";
                            if (orderItemIndex === 0 && index === 0)
                              firstRowClassName =
                                "extend-height delivery-column";
                            if (parseFloat(delivery.freight) > 0)
                              freightTotal += parseFloat(delivery.freight);
                            orderProductDeliveryClassName = Cx({
                              "delivery-method": true,
                              "pick-up": orderPickUpStatus,
                              delivery: !orderPickUpStatus,
                              "has-freight-charge":
                                parseFloat(delivery.freight) > 0,
                            });
                            if (delivery.status === ORDER_STATUS_FILLED) {
                              return (
                                <div
                                  key={"delivery-method-" + index}
                                  className="delivery-container"
                                >
                                  {index !== 0 ? (
                                    <hr className="last-row" />
                                  ) : (
                                    false
                                  )}
                                  <span className={firstRowClassName}>
                                    <div
                                      className={orderProductDeliveryClassName}
                                    >
                                      {orderProductDeliveryIcon}
                                      {orderProductDeliveryText}
                                    </div>
                                  </span>
                                  {parseFloat(delivery.freight) > 0 ? (
                                    <span className="delivery-freight">
                                      {_this.t("ntdModal.freight")}
                                      {money_format(
                                        parseFloat(delivery.freight)
                                      )}
                                    </span>
                                  ) : (
                                    false
                                  )}
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  key={"delivery-method-" + index}
                                  className="delivery-container"
                                >
                                  {index !== 0 ? (
                                    <hr className="last-row" />
                                  ) : (
                                    false
                                  )}
                                  <span
                                    className={
                                      firstRowClassName + " not-available"
                                    }
                                  >
                                    <div className="delivery-method">
                                      {_this.t("ntdModal.notAvailable") +
                                        " - " +
                                        _this.t("ntdModal.willNotBeShipped")}
                                    </div>
                                  </span>
                                </div>
                              );
                            }
                          })}
                        </div>
                      );
                      if (parseFloat(orderItem.orderBreakDown.orderTotal) > 0)
                        orderSubtotal += parseFloat(
                          orderItem.orderBreakDown.orderTotal
                        );
                    }

                    var className = Cx({
                      "body-row": true,
                      "border-bottom": true,
                    });

                    return (
                      <tr
                        key={"body-row-" + orderItemIndex}
                        className={className}
                      >
                        <td>
                          <div className="delivery-product-info">
                            <span className="product-name">
                              {orderItem.productTitle[getAppLanguage()]}
                            </span>
                            <span className="product-info">
                              {partNumberText} {orderItem.productPartNumber} -{" "}
                              {orderItem.productTireSize} -{" "}
                              {orderItem.productLoadSpeedPly}
                            </span>
                            {isEmpty(_this.state.orderData) ? (
                              <button
                                className="remove-button"
                                role="button"
                                aria-label={_this.t("ntdModal.remove")}
                                onKeyDown={(e) =>
                                  _this.onRemoveItemOnKeyDown(
                                    orderItem.productId,
                                    orderItem.productOrderItemId,
                                    orderItem,
                                    e
                                  )
                                }
                                onClick={_this.onRemoveItem.bind(
                                  _this,
                                  orderItem.productId,
                                  orderItem.productOrderItemId,
                                  orderItem
                                )}
                              >
                                <i className="fa fa-close"></i>
                                <span>{_this.t("ntdModal.remove")}</span>
                              </button>
                            ) : (
                              false
                            )}
                          </div>
                        </td>
                        <td>
                          <span className="delivery-bill-to">
                            {orderItem.billTo}
                          </span>
                          {orderQuantityBreakDown}
                        </td>
                        <td className={tdClassName}>{orderShipMethod}</td>
                        <td className={tdClassName}>{orderEstDeliveryDate}</td>
                        <td className={tdClassName}>{orderProductCost}</td>
                        <td className={tdClassName}>{orderProductTotal}</td>
                        <td className={tdClassName}>{orderProductDelivery}</td>
                      </tr>
                    );
                  })
                : false}
            </tbody>
          </table>
          <table className="order-table summary-table">
            <tbody>
              <tr className="order-info">
                <td className="purchase-order-info">
                  <div className="info-left">
                    <div className="customer-number">
                      <span>{this.t("ntdModal.customer")}</span>
                      <span>{"#"}</span>
                      <span>
                        {applicationStore.data.dealerAtdConnectCustomerNumber}
                      </span>
                      <span>{this.t("ntdModal.locationCode")}</span>
                      <span>{"#"}</span>
                      <span>
                        {applicationStore.data.dealerAtdConnectLocationCode}
                      </span>
                    </div>
                    <div className="dealer-info">
                      <span>{dealerName}</span>
                      <span>
                        {Immutable.fromJS([
                          dealerInfo.address,
                          dealerInfo.city,
                          dealerInfo.province,
                          dealerInfo.postalCode,
                        ])
                          .filter(function (value) {
                            return value;
                          })
                          .toArray()
                          .join(", ")}
                      </span>
                    </div>
                    {isEmpty(this.state.orderData) ||
                    (!isEmpty(this.state.orderData) &&
                      this.state.orderData.hasOwnProperty(
                        "purchaseOrderNumber"
                      ) &&
                      !isEmpty(this.state.orderData.purchaseOrderNumber)) ? (
                      <div className="purchase-order">
                        <span>{this.t("ntdModal.poNumber")}</span>
                        {isEmpty(this.state.orderData) ? (
                          <input
                            type="text"
                            className="purchase-order-input"
                            id="purchase-order-input"
                          />
                        ) : (
                          false
                        )}
                        {!isEmpty(this.state.orderData) ? (
                          <span className="purchase-order-number">
                            {this.state.orderData.purchaseOrderNumber}
                          </span>
                        ) : (
                          false
                        )}
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                  <div className="info-right">
                    <div
                      className={
                        isEmpty(this.state.orderData) ||
                        this.state.customerInfoEditable
                          ? "customer-info title"
                          : "customer-info title ordered"
                      }
                    >
                      <h3>{this.t("ntdModal.customerInfo")}</h3>
                    </div>
                    <div
                      className={
                        isEmpty(this.state.orderData) ||
                        this.state.customerInfoEditable
                          ? "customer-info input"
                          : "customer-info input ordered"
                      }
                    >
                      <div className="input-left">
                        <label>
                          {this.t("ntdModal.customerFirstName") + ":"}
                        </label>
                        {isEmpty(this.state.orderData) ||
                        this.state.customerInfoEditable ? (
                          <input
                            type="text"
                            className="input-info first-name-input"
                            id="customer-first-name"
                          />
                        ) : (
                          false
                        )}
                        {!isEmpty(this.state.orderData) &&
                        !isEmpty(customerFirstName) &&
                        !this.state.customerInfoEditable ? (
                          <span className="input-info first-name-span">
                            {customerFirstName}
                          </span>
                        ) : (
                          false
                        )}
                      </div>
                      <div className="input-right">
                        <label>
                          {this.t("ntdModal.customerLastName") + ":"}
                        </label>
                        {isEmpty(this.state.orderData) ||
                        this.state.customerInfoEditable ? (
                          <input
                            type="text"
                            className="input-info"
                            id="customer-last-name"
                          />
                        ) : (
                          false
                        )}
                        {!isEmpty(this.state.orderData) &&
                        !isEmpty(customerLastName) &&
                        !this.state.customerInfoEditable ? (
                          <span className="input-info">{customerLastName}</span>
                        ) : (
                          false
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        isEmpty(this.state.orderData) ||
                        this.state.customerInfoEditable
                          ? "customer-info input"
                          : "customer-info input ordered"
                      }
                    >
                      <label>{this.t("ntdModal.customerPhone") + ":"}</label>
                      {isEmpty(this.state.orderData) ||
                      this.state.customerInfoEditable ? (
                        <input
                          type="text"
                          className="input-info"
                          id="customer-phone"
                        />
                      ) : (
                        false
                      )}
                      {!isEmpty(this.state.orderData) &&
                      !isEmpty(customerPhone) &&
                      !this.state.customerInfoEditable ? (
                        <span className="input-info">{customerPhone}</span>
                      ) : (
                        false
                      )}
                    </div>
                    <div
                      className={
                        isEmpty(this.state.orderData) ||
                        this.state.customerInfoEditable
                          ? "customer-info input"
                          : "customer-info input ordered"
                      }
                    >
                      <label>{this.t("ntdModal.customerEmail") + ":"}</label>
                      {isEmpty(this.state.orderData) ||
                      this.state.customerInfoEditable ? (
                        <input
                          type="text"
                          className="input-info email"
                          id="customer-email"
                        />
                      ) : (
                        false
                      )}
                      {!isEmpty(this.state.orderData) &&
                      !isEmpty(customerEmail) &&
                      !this.state.customerInfoEditable ? (
                        <span className="input-info">{customerEmail}</span>
                      ) : (
                        false
                      )}
                    </div>

                    {!isEmpty(this.state.orderData) ? (
                      <div className="customer-info-controls">
                        {!this.state.customerInfoEditable ? (
                          <button
                            className="btn edit-info"
                            onClick={this.editCustomerInfo}
                          >
                            <i className="fa fa-edit" />
                          </button>
                        ) : (
                          false
                        )}
                        {this.state.customerInfoEditable ? (
                          <button
                            className="btn save-info"
                            onClick={this.saveCustomerInfo}
                          >
                            <i className="fa fa-save" />
                          </button>
                        ) : (
                          false
                        )}
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                </td>
                <td className="summary-info">
                  <div className="summary-line">
                    <span className="left">
                      {this.t("ntdModal.summarySubtotal")}
                    </span>
                    <span className="right">
                      {orderSubtotal > 0
                        ? money_format(orderSubtotal)
                        : this.t("ntdModal.nationalPricing")}
                    </span>
                  </div>
                  <div className="summary-line">
                    <span className="left">
                      {this.t("ntdModal.summaryFreight")}
                    </span>
                    <span className="right">{money_format(freightTotal)}</span>
                  </div>
                  <div className="summary-line">
                    <hr />
                  </div>
                  <div className="summary-total">
                    <span className="left">
                      {this.t("ntdModal.summaryOrderTotal")}
                    </span>
                    <span className="right">
                      {parseFloat(orderSubtotal + freightTotal) > 0
                        ? money_format(parseFloat(orderSubtotal + freightTotal))
                        : this.t("ntdModal.nationalPricing")}
                    </span>
                  </div>
                  {this.state.data &&
                  this.state.data.hasFreightItems &&
                  isEmpty(this.state.orderData) ? (
                    <div className="summary-disclaimer">
                      <p>{this.t("ntdModal.agreeToCharges")}</p>
                      <p className="bold">
                        {this.t("ntdModal.chargeOnInvoice")}
                      </p>
                      <div className="disclaimer-checkbox">
                        {browser.name === "Chrome" ? (
                          <div
                            className="checkbox-container"
                            onClick={this.toggleShippingCharges}
                          >
                            <input
                              type="checkbox"
                              id="select-agree-shipping-charges"
                              className="select-agree-shipping-charges"
                            ></input>
                            <i
                              className={
                                this.state.shippingChargesCheck
                                  ? "fa fa-check checked"
                                  : "fa fa-check"
                              }
                            ></i>
                          </div>
                        ) : (
                          <div className="checkbox-container">
                            <input
                              type="checkbox"
                              id="select-agree-shipping-charges"
                              className="select-agree-shipping-charges"
                            ></input>
                          </div>
                        )}
                        <label>{this.t("ntdModal.agreement")}</label>
                      </div>
                    </div>
                  ) : (
                    false
                  )}

                  {isEmpty(this.state.orderData) && isTestOrder ? (
                    <span className="test-order-text">
                      {this.t("ntdModal.orderIsTest")}
                    </span>
                  ) : (
                    false
                  )}

                  {isEmpty(this.state.orderData) ? (
                    <div className="order-button">
                      <button
                        className="place-order"
                        id="place-order"
                        onClick={this.onPlaceOrder}
                      >
                        {this.t("ntdModal.placeOrder")}
                      </button>
                    </div>
                  ) : (
                    false
                  )}
                </td>
              </tr>

              <tr className="disclaimer-info">
                <div className="disclaimer-line">
                  <span>{this.t("ntdModal.tireWizardDisclaimer")}</span>
                </div>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  onGatherAndStoreXtimeLink = () => {
    var firstName = document.getElementById("firstNameQuoteField")
      ? document.getElementById("firstNameQuoteField").value
      : "";
    var lastName = document.getElementById("lastNameQuoteField")
      ? document.getElementById("lastNameQuoteField").value
      : "";
    var phone = document.getElementById("telQuoteField")
      ? document.getElementById("telQuoteField").value
      : "";
    var email = document.getElementById("userEmailField")
      ? document.getElementById("userEmailField").value
      : "";
    var name = firstName.trim();
    if (lastName.trim() !== "") {
      name += " " + lastName.trim();
    }
    setTimeout(() => {
      applicationActions.gatherXtimeLinkUrl(name, email, phone, "");
    }, 100);
  };

  onRemoveItemOnKeyDown = (tireId, orderItemId, item, evt) => {
    checkForEvents: if ("undefined" != typeof evt) {
      if (evt.keyCode == 13 || evt.keyCode == 32) {
        evt.preventDefault();
        evt.stopPropagation();
        break checkForEvents;
      }
      return;
    }
    this.onRemoveItem(tireId, orderItemId, item);
  };

  onRemoveItem = (tireId, orderItemId, item) => {
    shoppingCartActions.removeItemFromBasket(orderItemId, tireId, item);
    if (sizeOf(this.state.data.orderItems) > 1) {
      setTimeout(() => {
        shoppingCartActions.loadNTDOrderPreview();
        if (getRouterState() === RouterStates.WISHLIST) {
          loadWishlistWithContext();
        }
      }, 100);
    } else {
      this.onCloseModal();
      if (getRouterState() === RouterStates.WISHLIST) {
        loadWishlistWithContext();
      }
    }
  };

  editCustomerInfo = () => {
    this.setState({
      customerInfoEditable: true,
    });
  };

  saveCustomerInfo = () => {
    //save customer info
    var firstName = document.getElementById("customer-first-name")
      ? document.getElementById("customer-first-name").value
      : "";
    var lastName = document.getElementById("customer-last-name")
      ? document.getElementById("customer-last-name").value
      : "";
    var phone = document.getElementById("customer-phone")
      ? document.getElementById("customer-phone").value
      : "";
    var email = document.getElementById("customer-email")
      ? document.getElementById("customer-email").value
      : "";
    if (
      firstName.trim() !== "" ||
      lastName.trim() !== "" ||
      phone.trim() !== "" ||
      email.trim() !== ""
    ) {
      var name = firstName.trim();
      if (lastName.trim() !== "") {
        name += " " + lastName.trim();
      }
      this.setState({ customerInfoEditable: false });
      setTimeout(() => {
        shoppingCartActions.saveOrderCustomerInfo(
          name.trim(),
          email.trim(),
          phone.trim(),
          "",
          "",
          "",
          ""
        );
      }, 10);
    } else {
      this.setState({ customerInfoEditable: true });
    }
  };

  onCloseModal = () => {
    this.setState({ allowWebserviceCallFlag: true });
    applicationActions.onCloseNtdModal();
  };

  onPlaceOrder = (fromBookAppointment = false) => {
    //Check if is national delivery, shipping charges accepted
    var passShippingChargesCheck = !this.state.data.hasFreightItems;
    if (this.state.data.hasFreightItems) {
      var checkbox = document.getElementById("select-agree-shipping-charges");
      if (checkbox && this.state.shippingChargesCheck)
        passShippingChargesCheck = true;
    }
    if (passShippingChargesCheck) {
      //Check if purchase order number was entered, confirm if blank
      var purchaseOrderInput = document.getElementById("purchase-order-input");
      if (purchaseOrderInput) {
        if (purchaseOrderInput.value.trim()) {
          this.setState({
            placeOrderShowModal: true,
            placeOrderShippingDisclaimerAgreementModal: false,
            placeOrderShippingDisclaimerAgreementWithBookAppointmentModal: false,
            placeOrderPurchaseOrderNumberModal: false,
          });
          this.saveCustomerInfo();
          setTimeout(() => {
            shoppingCartActions.loadPlaceOrderNTD(
              purchaseOrderInput.value.trim(),
              this.state.orderPickUpStatus
            );
          }, 100);
        } else if (!fromBookAppointment) {
          this.setState({ placeOrderPurchaseOrderNumberModal: true });
        } else {
          this.setState({
            placeOrderShippingDisclaimerAgreementWithBookAppointmentModal: true,
          });
        }
      }
    } else if (!fromBookAppointment) {
      this.setState({ placeOrderShippingDisclaimerAgreementModal: true });
    } else {
      this.setState({
        placeOrderShippingDisclaimerAgreementWithBookAppointmentModal: true,
      });
    }
  };

  onOpenOrderHistory = () => {
    this.onCloseModal();
    setTimeout(() => {
      applicationActions.openQuoteReport(true);
    }, 100);
  };

  onContinuePlaceOrder = (fromBookAppointment = false) => {
    this.setState({
      placeOrderShowModal: true,
      placeOrderShippingDisclaimerAgreementModal: false,
      placeOrderShippingDisclaimerAgreementWithBookAppointmentModal: false,
      placeOrderPurchaseOrderNumberModal: false,
    });
    //save customer info
    var firstName = document.getElementById("customer-first-name")
      ? document.getElementById("customer-first-name").value
      : "";
    var lastName = document.getElementById("customer-last-name")
      ? document.getElementById("customer-last-name").value
      : "";
    var phone = document.getElementById("customer-phone")
      ? document.getElementById("customer-phone").value
      : "";
    var email = document.getElementById("customer-email")
      ? document.getElementById("customer-email").value
      : "";
    if (
      firstName.trim() !== "" ||
      lastName.trim() !== "" ||
      phone.trim() !== "" ||
      email.trim() !== ""
    ) {
      var name = firstName.trim();
      if (lastName.trim() !== "") {
        name += " " + lastName.trim();
      }
      shoppingCartActions.saveWishlist(
        name.trim(),
        email.trim(),
        phone.trim(),
        "",
        "",
        "",
        ""
      );
    }
    var purchaseOrderInput = "";
    if (fromBookAppointment)
      purchaseOrderInput = document.getElementById(
        "purchase-order-input-has-booking"
      );
    if (purchaseOrderInput)
      purchaseOrderInput = purchaseOrderInput.value.trim();
    setTimeout(() => {
      shoppingCartActions.loadPlaceOrderNTD(
        purchaseOrderInput,
        this.state.orderPickUpStatus
      );
    }, 100);
  };

  onCloseWarningModal = () => {
    this.setState({
      placeOrderShippingDisclaimerAgreementModal: false,
      placeOrderShippingDisclaimerAgreementWithBookAppointmentModal: false,
      placeOrderPurchaseOrderNumberModal: false,
    });
  };

  onCloseNonOrderableItemsList = () => {
    this.setState({
      showNonOrderableItemsList: false,
    });
  };

  togglePickUpOrder = () => {
    this.setState({
      orderPickUpStatus: !this.state.orderPickUpStatus,
    });
  };

  toggleShippingCharges = () => {
    this.setState({
      shippingChargesCheck: !this.state.shippingChargesCheck,
    });
  };

  onChange = () => {
    this.setState({
      data: shoppingCartStore.data.get("ntdOrderData"),
      previewFailed: shoppingCartStore.data.get("ntdPreviewFailed"),
      orderData: shoppingCartStore.data.get("ntdPlaceOrderData"),
      orderFailed: shoppingCartStore.data.get("ntdPlaceOrderFailed"),
      orderPickUpStatus: getOrderPickUpStatus(
        shoppingCartStore.data.get("ntdPlaceOrderData")
      ),
      loggedIn: isUserLoggedIn(),
      dealerXtimeAppointmentIntegrationLink:
        applicationStore.data.dealerXtimeAppointmentIntegrationLink,
      dealerEnablePromptToOrderTires:
        applicationStore.data.dealerEnablePromptToOrderTires,
    });
  };
}

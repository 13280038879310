var React = require('react');

import {i18nMixin} from '../../i18n/i18nMixin.js'

export var DateUtilities = {

  mixins: [i18nMixin],

  pad: function (value, length) {
    while (value.length < length) value = "0" + value;
    return value;
  },

  clone: function (date) {
    if (typeof(date) === 'object' || typeof(date) === 'string') {
      return new Date(date);
    } else if (date !== null && typeof date !== 'undefined') {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
    } else {
      return null;
    }
  },

  toString: function (date) {
    return date.getFullYear() + "-" + DateUtilities.pad((date.getMonth() + 1).toString(), 2) + "-" + DateUtilities.pad(date.getDate().toString(), 2);
  },

  toDayOfMonthString: function (date) {
    return DateUtilities.pad(date.getDate().toString());
  },

  toMonthAndYearString: function (date) {
    var months = [
      this.mixins[0].t('calendar.january'),
      this.mixins[0].t('calendar.february'),
      this.mixins[0].t('calendar.march'),
      this.mixins[0].t('calendar.april'),
      this.mixins[0].t('calendar.may'),
      this.mixins[0].t('calendar.june'),
      this.mixins[0].t('calendar.july'),
      this.mixins[0].t('calendar.august'),
      this.mixins[0].t('calendar.september'),
      this.mixins[0].t('calendar.october'),
      this.mixins[0].t('calendar.november'),
      this.mixins[0].t('calendar.december')
    ];

    return months[date.getMonth()] + " " + date.getFullYear();
  },

  moveToDayOfWeek: function (date, dayOfWeek) {
    while (date.getDay() !== dayOfWeek) date.setDate(date.getDate() - 1);
    return date;
  },

  isSameDay: function (first, second) {
    return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();
  },

  isBefore: function (first, second) {
    return first.getTime() < second.getTime();
  },

  isAfter: function (first, second) {
    return first.getTime() > second.getTime();
  }
};

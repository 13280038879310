module.exports = {
	'MO': 'Pneus approuvés par BMW',
	

	homepage_tire_title: 'Pneus approuvés par BMW',
	homepage_tire_subtitle: 'PAS SEULEMENT UNE MARQUE. UN SCEAU D’APPROBATION.',
	homepage_tire_text: 'Aucune autre pièce d’équipement n’a un plus grand impact sur les performances et la sécurité de votre véhicule que les pneus. Les pneus approuvés par BMW, facilement identifiés par le marquage en étoile sur le flanc extérieur, sont spécialement développés en étroite collaboration avec les principaux fabricants de pneus et conçus avec précision pour correspondre aux spécifications exactes de votre BMW. Le résultat: des performances améliorées, une sécurité accrue et un confort de conduite optimal. Pourquoi se contenter de quelque chose d’autre?',
	homepage_wheel_title: 'Roues Authentiques',
	homepage_wheel_subtitle: 'ENSEMBLES DE ROUES HAUTES PERFORMANCES BMW.',
	homepage_wheel_text: 'Souci du détail: les roues hautes performances BMW offrent une conception sophistiqué, une stabilité inégalée et des propriétés de roulement idéales. Les combinaisons de roues et de pneus sont précisément adaptées aux caractéristiques de performance globales de votre BMW, contribuant ainsi à offrir la meilleure interaction possible entre tous les composants du châssis.',
	
	'product': {
		'showOnlyApproved': 'Show Only Approved Tires',
		'approvedTires': 'Pneus approuvés',
		'nonApprovedTires': 'Pneus non-approuvés',
		'good': '',
		'better': '',
		'best': '',
		'dealerPrice': 'Prix',
		'retailPrice': 'Prix',
		'tire': 'Pneus approuvés par BMW',
		'approvedTire': 'Pneus approuvés par BMW',
	},
};
import { DispatcherActions } from '../constants/DispatcherActions.js';
import { applicationStore } from '../stores/ApplicationStore.js';
import { AbstractAction } from './AbstractAction.js';

import {
  checkSiteHasCampaign,
  getAppDealerId,
  getAppLanguage,
  getCampaignId,
  isEmpty, isNationalSite,
  sizeOf,
  getUserIpAddress
} from "../service/UtilityService";

import { getDestination } from "../api/RequestBuilderHelper";
import store from "../stores/configureStore";
import {onDealerLoaded} from "../redux/features/dealerSections";

var DealerSelectionActions = AbstractAction.extend({

  loadListOfDealers: function() {
    this.apiGet('/national/getNationalAccountDealerList.php', {
      nationalAccountId: applicationStore.data.nationalAccountId,
	    isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      // this.dispatch(DispatcherActions.LIST_OF_DEALERS_LOADED, response);
      store.dispatch(onDealerLoaded(response));
    }).catch(function(error) {
      console.error(error);
    });
  },

	loadListOfDealersForGroup: function() {
		this.apiGet('/dealerGroup/getDealerGroupDealerList.php', {
			dealerGroupId: applicationStore.data.groupAccountId,
			isNational: applicationStore.data.nationalSite ? 1 : 0
		}).then(function(response) {
			this.dispatch(DispatcherActions.LIST_OF_DEALERS_LOADED, response);
		}.bind(this));
	},

  // return to this 
  //TODO: Return to this to refactor the GET REQUEST
  loadDealersForProductPage: function(year, make, category, model, tiresize, product, postal) {
    this.apiGet('/national/getNationalAccountDealerList.php', {
      nationalAccountId: applicationStore.data.nationalAccountId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {

			applicationStore.data.forceLoadingScreen = true;

      var GoogleMaps = window.google, getDistance = this.getDistance, apiGetContextless = this.apiGetContextless;
      if (GoogleMaps) {
        var GoogleGeoCoder = new GoogleMaps.maps.Geocoder();

        if (GoogleGeoCoder) {

          var lat, lng;
          GoogleGeoCoder.geocode( { 'address': postal}, function(results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
              lat = results[0].geometry.location.lat();
              lng = results[0].geometry.location.lng();
            }

            var distance = 9999, id, tempdistance;
            if (!isEmpty(lat) && !isEmpty(lng) && sizeOf(response) > 0) {
							for (var i in response) {

								if (response[i]) {
                  tempdistance = getDistance({ lat: lat, lng: lng }, { lat: response[i].dealerLatitude, lng: response[i].dealerLongitude });

                  if (parseInt(tempdistance) < parseInt(distance)) {
                  	distance = tempdistance;
                    id = response[i].dealerId;
									}
								}

							}

							if (!isEmpty(id)) {

                if (!isEmpty(tiresize)) {
///*
                  ///*
                  window.location.href =
                    window.location.origin
                    + '/national/'
                    + applicationStore.data.nationalUrlCode + '/'
                    + getAppLanguage() + '/'
                    + 'dealer/' + id + '/'
                    + 'search/' + 'tiresize-search' + '/' + encodeURIComponent(tiresize)
                  ;
                  //*/

                } else {

                  apiGetContextless('/authentication/getSecurityToken.php', {
                    username: '',
                    password: '',
                    dealerId: id,
                    userIpAddress: getUserIpAddress(),
                    campaignId: checkSiteHasCampaign() ? getCampaignId() : null,
                    nationalAccountCode: applicationStore.data.nationalUrlCode,
                    isNational: 1,
                  }).then(function(token) {


                    switch(applicationStore.data.defaultTemplate) {
                      case 'hyundai':

                        if (sizeOf(response) > 0) {

                          window.location.href =
                            window.location.origin
                            + '/national/'
                            + applicationStore.data.nationalUrlCode + '/'
                            + getAppLanguage() + '/'
                            + 'dealer/' + id + '/'
                            + 'product/' + product.toUpperCase() + '/'
                            + 'type/' + 'vehicle-search' + '/'
                            + 'year/' + year + '/'
                            + 'make/' + make + '/'
                            + 'category/' + category + '/'
                            + 'model/' + model
                          ;

                        } else {
                          applicationStore.data.forceLoadingScreen = false;
                        }

                        break;

                      default:

                        apiGetContextless('/vehicle/getVehicleSubmodels.php', {
                          partType: getDestination(product),
                          vehicleYearId: year,
                          vehicleManufacturerId: make,
                          vehicleCategoryId: category,
                          vehicleModelId: model,
                          isNational: 1,
                          dealerId: id
                        }).then(function(response) {

                          if (sizeOf(response) > 0) {

                            window.location.href =
                              window.location.origin
                              + '/national/'
                              + applicationStore.data.nationalUrlCode + '/'
                              + getAppLanguage() + '/'
                              + 'dealer/' + id + '/'
                              + 'product/' + product.toUpperCase() + '/'
                              + 'type/' + 'vehicle-search' + '/'
                              + 'year/' + year + '/'
                              + 'make/' + make + '/'
                              + 'category/' + category + '/'
                              + 'model/' + model + '/'
                              + 'trim/' + response[0].vehicleSubmodelId
                            ;

                          } else {
                            applicationStore.data.forceLoadingScreen = false;
                          }

                        }.bind(this));

                    }


                  }.bind(this));

                }


              } else {
                applicationStore.data.forceLoadingScreen = false;
							}

						} else {
              applicationStore.data.forceLoadingScreen = false;
						}

          });
        }
      }

    }.bind(this));
  },

  getDistance : function(p1, p2) {
  	var getRadian = function(x) {
      return x * Math.PI / 180;
    };
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = getRadian(p2.lat - p1.lat);
    var dLong = getRadian(p2.lng - p1.lng);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(getRadian(p1.lat)) * Math.cos(getRadian(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return parseFloat(d / 1000).toFixed(0); // returns the distance in kilometers
  },

  // mapSetInitialUserLocation: function(userLocation, mapCenter) {
  //   this.dispatch(DispatcherActions.MAP_SET_USER_LOCATION, {
	// 		userLocation: userLocation,
	// 		mapCenter: mapCenter
  //   });
  // },

	mapSetLocation: function(mapCenter, mapZoom) {
		this.dispatch(DispatcherActions.MAP_SET_LOCATION, {
			mapCenter: mapCenter,
			mapZoom: mapZoom
		});
	},

  // selectedDealer: function(dealerId, season, dealer) {
  //   this.dispatch(DispatcherActions.DEALER_SELECTED, {
  //     dealerId: dealerId,
  //     season: season,
  //     dealer: dealer
  //   });
  // },

	// setDealerSelectionSearchTerm: function(search) {
	// 	this.dispatch(DispatcherActions.DEALER_SELECTION_CHANGE_SEARCH, search);
	// },

	// selectDealerSearchType: function(searchType) {
	// 	this.dispatch(DispatcherActions.DEALER_SELECTION_CHANGE_SEARCH_TYPE, searchType);
	// },

	// dealerSearchIsEmpty: function() {
	// 	this.dispatch(DispatcherActions.DEALER_SELECTION_SEARCH_IS_EMPTY, {});
  //   break;
  // },

	// dealerSearchResetEmptySearch: function() {
	// 	this.dispatch(DispatcherActions.DEALER_SELECTION_RESET_EMPTY_SEARCH, {});
	// },

	// setDealerDistance: function(distance) {
	// 	this.dispatch(DispatcherActions.DEALER_SELECTION_SET_DISTANCE, distance);
	// },

	// resetDealerSearch: function() {
	// 	this.dispatch(DispatcherActions.DEALER_SELECTION_RESET_DEALER_SEARCH, {});
	// },

	// filterDealersPosition: function(position) {
	// 	this.dispatch(DispatcherActions.DEALER_SELECTION_FILTER_DEALERS_POSITION, position);
	// },

	// closeDealerNameSearchList: function() {
	// 	this.dispatch(DispatcherActions.DEALER_SELECTION_CLOSE_DEALER_NAME_SEARCH_LIST, {});
	// },

	closeInitialUserLocationModal: function() {
		this.dispatch(DispatcherActions.DEALER_SELECTION_CLOSE_INITIAL_LOCATION_MODAL, {});
	},

  // showLeavingSiteWarningModal: function(dealer) {
  //   this.dispatch(DispatcherActions.DEALER_SELECTION_SHOW_WARNING_MODAL, dealer);
  // },

  // closeLeavingSiteWarningModal: function() {
  //   this.dispatch(DispatcherActions.DEALER_SELECTION_CLOSE_WARNING_MODAL, {});
  // },

	// onDealerChassisSearchFromUrl: function(chassisObject) {
  //   this.dispatch(DispatcherActions.DEALER_SELECTION_CHASSIS_SEARCH_FROM_URL, chassisObject);
	// },


 /*
	*
	*   LEGACY MAP FUNCTIONS
	*
  */

	unselectDealer: function() {
		this.dispatch(DispatcherActions.DEALER_UNSELECTED, {});
	},

	clickedOnDealer: function(dealer) {
		this.dispatch(DispatcherActions.CLICKED_ON_DEALER, {
			dealer: dealer,
			dealerId: dealer.get('id')
		});
	},

	locationChanged: function(location, viewport) {
		this.dispatch(DispatcherActions.LOCATION_CHANGED, {
			location: location,
			viewport: viewport
		});
	},

  //  This is using element to the redux store 
  // refactor this always application 
  // Down Below this are dependent on createElement from React, need to refactor
  // or find alternative to save element into redux state
	dealerNameSearched: function(search) {
		this.dispatch(DispatcherActions.DEALER_NAME_SEARCHED, search);
	},

	// dealerIndexSearched: function(search) {
	// 	this.dispatch(DispatcherActions.DEALER_INDEX_SEARCHED, search);
	// },

  //element dependent 
	dealerCitySearched: function(search) {
		this.dispatch(DispatcherActions.DEALER_CITY_SEARCHED, search);
	},
  //element dependent
	dealerNameReset: function() {
		this.dispatch(DispatcherActions.DEALER_NAME_RESET, {});
	},

	// provinceSelected: function(province) {
	// 	this.dispatch(DispatcherActions.MAP_FILTER_PROVINCE_SELECTED, province);
	// },

	// mapCenterChanged: function(mapCenter) {
	// 	this.dispatch(DispatcherActions.MAP_CENTER_CHANGED, mapCenter);
	// },

	// mapZoomChanged: function(mapZoom) {
	// 	this.dispatch(DispatcherActions.MAP_ZOOM_CHANGED, mapZoom);
	// },

	// mapBoundsChanged: function(bounds) {
	// 	this.dispatch(DispatcherActions.MAP_BOUNDS_CHANGED, bounds);
	// },

	// mapUpdateWindowWidth: function() {
	// 	this.dispatch(DispatcherActions.MAP_UPDATE_WINDOW_WIDTH, {});
	// }
});

export const dealerSelectionActions = new DealerSelectionActions();

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import baseInstance from '../features/api/api';
// loadDealerInfo: function() {
//     this.apiGet('/dealer/getDealerAccountInfoByDealerId.php', {
//       dealerId: getAppDealerId(),
//       isNational: applicationStore.data.nationalSite ? 1 : 0
//     }).then(function (response) {
//       this.dispatch(DispatcherActions.DEALER_INFO_LOAD, response);
//     }.bind(this));
//   },

export const loadDealerInfo = createAsyncThunk(
    'dealerInfo/loadDealerInfo',
    async (arg,{getState})=>{
        let {tokenId,dealerId} = getState().info;
        let res = await baseInstance.get('/dealer/getDealerAccountInfoByDealerId.php',{
            dealerId:dealerId,
            isNational:0,
            tokenId:tokenId
        });
        if(!res.ok) throw new Error(res.statusText);
        return res.data;
    }
)

// getDealerSecurityToken: function() {
//     setTimeout(() => {
//       this.apiPost(
//         getSecurityTokenWrapper('/authentication/getSecurityToken.php'),
//         getSecurityTokenRequestObject()
//       ).then(function (response) {
//         if (isUserLoggedIn(true)) this.dispatch(DispatcherActions.LOGIN_SUCCESSFUL, {});
//       }.bind(this));
//     }, 250);
//   },

const dealerInfo = createSlice({
    name:'dealerInfo',
    initialState:{
        dealerTokenId:null,
    },
    reducers:{
        setDealerToken: (state,{payload}) => {
            state.dealerTokenId = payload;
        },
        
    },
    extraReducers:{
        [loadDealerInfo.pending]:(state,action) =>{
            state.status = 'loading';
        },
        [loadDealerInfo.fulfilled]:(state,{payload})=>{
            state.status = 'sucess'
        },
        [loadDealerInfo.rejected]:(state,action)=>{
            state.status = 'failed'
        }
    }
})

export const  {
    setDealerToken
} = dealerInfo.actions;
export default dealerInfo.reducer;
module.exports = {
  'title': 'Fitting Room',
  'shoppingCartWarnings': {
    'ITEM_WARNING_TIRE_NOT_ORIGINAL_SIZE': 'Vous avez demandé d\'augmenter ou de réduire la taille de vos pneus de l\'équipement d\'origine du véhicule. S\'il vous plaît être conscient que lorsque vous sélectionnez les pneus augmenter ou réduites, vous devez également acheter des roues de taille correspondantes.'
  },
  'product': {
    'good': 'Bronze',
    'better': 'Argent',
    'best': 'Or',
    'colourSelectionTitle': 'COULEUR'
  }
};
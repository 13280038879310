module.exports = {
    'quote': {
        'quote': 'Estimé',
        'quoteFor': 'Estimé pour',
        'addToQuote': 'Ajouter',
        'remove': 'Enlever l\'article',
        'subtotal': 'Sous-total',
        'tax': 'Taxes',
        'total': 'Total',
        'emailQuote': 'Envoyer un courriel de la estimé',
        'printQuote': 'Imprimer une estimé',
        'saveQuote': 'Demander un rendez-vous',
        'emailHasBeenSent': 'Soumission a été envoyé à l\'adresse courriel fournie.',
        'appointmentSent': 'Appointment has been successfully requested',
        'emailFieldLabel': 'Courriel',
        'nameFieldLabel': 'Nom',
        'savedThroughOnePromotion': 'You have saved {0} through our {1} Promotion',
        'savedThroughMultiplePromotions': 'You have saved {0} through {1} Promotions',
        'included': 'Inclus',
        'requestAppointment': 'Demande de rendez-vous',
        'day': 'Jour',
        'partOfDay': 'Partie d\'un jour'
    },
    'cart': {
        'myquotes': 'Mes estimés'
    },
    'dealerFilter': {
        'titlePrefix': 'Trouvez un concessionnaire',
        'titleSuffix': 'Canada'
    }
};
var React = require("react");

import { I18nComponent } from "../../i18n/I18nComponent.jsx";

export class WeekHeader extends I18nComponent {
  render() {
    return React.createElement(
      "div",
      { className: "week-header" },
      React.createElement("span", null, this.t("calendar.sundayShort")),
      React.createElement("span", null, this.t("calendar.mondayShort")),
      React.createElement("span", null, this.t("calendar.tuesdayShort")),
      React.createElement("span", null, this.t("calendar.wednesdayShort")),
      React.createElement("span", null, this.t("calendar.thursdayShort")),
      React.createElement("span", null, this.t("calendar.fridayShort")),
      React.createElement("span", null, this.t("calendar.saturdayShort"))
    );
  }
}

import { languageStore } from "../stores/LanguageStore.js";
import { applicationStore } from "../stores/ApplicationStore.js";
import { shoppingCartViewStore } from "../stores/ShoppingCartViewStore.js";

import { Image } from "../components/ux/Image.jsx";
import {
  arraySearch,
  checkArrayFieldsExist,
  getAppLanguage,
  isEmpty,
  money_format,
  sizeOf,
} from "./UtilityService";

import { isUserLoggedIn } from "../api/AuthService";
import { ADOBELAUNCHER_CLASS, setAdobeLaunchClass } from "./AdobeLaunchService";
import store from "../stores/configureStore";

var React = require("react");
var _ = require("lodash");
var Cx = require("classnames");

export const getHashFromURL = () => {
  try {
    const { pathname } = window.location;
    const match = pathname.match(/wishlist(?:\/print)?\/([A-F0-9]+)/);
    return match?.[1];
  } catch (error) {
    console.error("An error occurred:", error);
    return undefined;
  }
};

export const removeAmpParameters = (guid) => {
  var temp;
  if (guid && guid.indexOf("?") > -1) {
    temp = guid.split("?");
    return temp[0];
  } else if (guid && guid.indexOf("&") > -1) {
    temp = guid.split("&");
    return temp[0];
  } else {
    return guid;
  }
};

// export const getOrderIdentifier = (guidOnly=false) => {
//   var cartId = [];
//   cartId[0] = shoppingCartViewStore.data.get('orderGUID');
//   cartId[1] = guidOnly?null:shoppingCartViewStore.data.get('orderId');
//   cartId[2] = guidOnly?null:store.getState().shoppingCart.orderNumber;
//   cartId[3] = store.getState().shoppingCart.orderGUID;
//   cartId[4] = guidOnly?null:localStorage.getItem('orderNumber');
//   cartId[5] = getHashFromURL();
//   for (var i in cartId) {
//     if (cartId[i] && typeof(cartId[i]) !== 'undefined' && cartId[i] !== 'null') {
//       return removeAmpParameters(cartId[i]);
//     }
//   }
//   return null;
// };

export const getOrderIdentifier = () => {
  const state = store.getState().shoppingCart;
  const [orderNumber] = [
    state.orderNumber,
    state.orderId,
    getHashFromURL(),
    localStorage.getItem("orderNumber"),
  ].filter(Boolean);
  return orderNumber ? removeAmpParameters(orderNumber) : null;
};

export const checkOrderIdentifierIsGUID = (orderIdentifier) => {
  var pattern = new RegExp("^[A-F0-9]{32}$"); //^[A-F0-9]{32}$
  return pattern.test(orderIdentifier);
};

export const getDeliveryDetails = (orderFulfillmentList) => {
  var valid = false,
    deliveryList = [],
    orderLine = null;
  if (orderFulfillmentList) {
    valid = true;
    orderFulfillmentList = orderFulfillmentList.filter(
      (order) => order.fulfillment_type === "ATD_ORDER_STATUS"
    );

    if (sizeOf(orderFulfillmentList) === 0) valid = false;
    else {
      orderFulfillmentList = orderFulfillmentList[0];
      if (orderFulfillmentList.is_valid === false) valid = false;
      else {
        if (
          checkArrayFieldsExist(orderFulfillmentList, [
            "fulfillment_value",
            "orderLines",
            "orderLine",
          ])
        ) {
          orderLine =
            orderFulfillmentList.fulfillment_value.orderLines.orderLine;
          if (checkArrayFieldsExist(orderLine, ["description"])) {
            deliveryList.push({
              name: orderLine.description,
              orderNumber: orderLine.fulfillments.fulfillment.orderNumber,
              status: orderLine.fulfillments.fulfillment.status,
              quantity: orderLine.fulfillments.fulfillment.quantity,
              sourceType: orderLine.fulfillments.fulfillment.sourceType,
              sourceTypeDC: orderLine.fulfillments.fulfillment.sourceTypeDC,
              deliveryDate:
                orderLine.fulfillments.fulfillment.actualDeliveryDate,
            });
          } else {
            for (var i in orderLine) {
              deliveryList.push({
                name: orderLine[i].description,
                orderNumber: orderLine[i].fulfillments.fulfillment.orderNumber,
                status: orderLine[i].fulfillments.fulfillment.status,
                quantity: orderLine[i].fulfillments.fulfillment.quantity,
                sourceType: orderLine[i].fulfillments.fulfillment.sourceType,
                sourceTypeDC:
                  orderLine[i].fulfillments.fulfillment.sourceTypeDC,
                deliveryDate:
                  orderLine[i].fulfillments.fulfillment.actualDeliveryDate,
              });
            }
          }
        }
      }
    }
  }
  return {
    deliveryList: deliveryList,
    isValid: function () {
      return valid;
    },
  };
};

export const getEstimatedDeliveryDetails = (orderFulfillmentList) => {
  var valid = false,
    deliveryList = [],
    orderLine = null;
  if (orderFulfillmentList) {
    valid = true;
    orderFulfillmentList = orderFulfillmentList.filter(
      (order) => order.fulfillment_type === "ATD_PLACE_ORDER"
    );
    if (sizeOf(orderFulfillmentList) === 0) valid = false;
    else {
      orderFulfillmentList = orderFulfillmentList[0];
      if (orderFulfillmentList.is_valid === false) valid = false;
      else {
        if (
          checkArrayFieldsExist(orderFulfillmentList, [
            "fulfillment_value",
            "orderLines",
            "orderLine",
          ])
        ) {
          orderLine =
            orderFulfillmentList.fulfillment_value.orderLines.orderLine;
          if (checkArrayFieldsExist(orderLine, ["description"])) {
            deliveryList.push({
              shipMethod: orderLine.fulfillments.fulfillments.shipMethod,
              status: orderLine.fulfillments.fulfillments.status,
              quantity: orderLine.fulfillments.fulfillments.quantity,
              freight: orderLine.fulfillments.fulfillments.freight,
              deliveryDate:
                orderLine.fulfillments.fulfillments.estimatedDelivery,
            });
          } else {
            for (var i in orderLine) {
              deliveryList.push({
                shipMethod: orderLine[i].fulfillments.fulfillments.shipMethod,
                status: orderLine[i].fulfillments.fulfillments.status,
                quantity: orderLine[i].fulfillments.fulfillments.quantity,
                freight: orderLine[i].fulfillments.fulfillments.freight,
                deliveryDate:
                  orderLine[i].fulfillments.fulfillments.estimatedDelivery,
              });
            }
          }
        }
      }
    }
  }
  return {
    deliveryList: deliveryList,
    isValid: function () {
      return valid;
    },
  };
};

export const getOrderATDConfirmationNumber = (orderFulfillmentList) => {
  var valid = false,
    orderNumber = null;
  if (orderFulfillmentList) {
    valid = true;
    orderFulfillmentList = orderFulfillmentList.filter(
      (order) => order.fulfillment_type === "ATD_PLACE_ORDER"
    );
    if (sizeOf(orderFulfillmentList) === 0) valid = false;
    else {
      orderFulfillmentList = orderFulfillmentList[0];
      if (orderFulfillmentList.is_valid === false) valid = false;
      else {
        if (
          checkArrayFieldsExist(orderFulfillmentList, [
            "fulfillment_value",
            "orderNumber",
          ])
        ) {
          orderNumber = orderFulfillmentList.fulfillment_value.orderNumber;
        } else valid = false;
      }
    }
  }

  return {
    orderNumber: orderNumber,
    isValid: function () {
      return valid;
    },
  };
};

export const checkNTDStockExists = (tire) => {
  var ntdOrderStockEnabled = false;
  if (
    tire &&
    tire.get("frontTire") &&
    tire.get("frontTire").get("supportDealerOrder")
  ) {
    ntdOrderStockEnabled = true;
  } else if (
    tire &&
    tire.get("rearTire") &&
    tire.get("rearTire").get("supportDealerOrder")
  ) {
    ntdOrderStockEnabled = true;
  } else if (tire && tire.get("supportDealerOrder")) {
    ntdOrderStockEnabled = true;
  }
  return ntdOrderStockEnabled;
};

export const renderNTDButton = (
  func,
  buttonType,
  isPartList = false,
  total = "",
  testId = "",
  enableFinancing = false,
  datadtm = ""
) => {
  const funcOnKeyDown = (origFunc, evt) => {
    if (evt) {
      checkForEvent: if (typeof evt != "undefined") {
        if (evt.keyCode == 13 || evt.keyCode == 32) {
          evt.preventDefault();
          evt.stopPropagation();
          break checkForEvent;
        }
        return;
      }
    }
    origFunc();
  };

  var dictionary = languageStore.getDictionary(),
    buttonClassName = "",
    orderLanguage = "",
    orderImage = "",
    orderClassName = "";
  switch (buttonType) {
    case "order":
      buttonClassName = "ntd-btn";
      if (isPartList) orderLanguage = dictionary.cart.addToOrderNtd;
      else orderLanguage = dictionary.cart.orderNtd;
      orderImage = "/static/img/ntd_truck.png";
      if (applicationStore.data.dealerSite) {
        if (applicationStore.data.dealerInfo.country.toLowerCase() === "us") {
          orderImage = "/static/img/atd_logo.png";
        }
      } else {
        if (applicationStore.data.nationalCountryCode.toLowerCase() === "us") {
          orderImage = "/static/img/atd_logo.png";
        }
      }
      orderClassName = "ntd-image";
      break;
    case "purchase":
      buttonClassName = "ntd-btn tile-btn";

      orderLanguage = getPurchasNtdButtonText(total, enableFinancing);

      orderImage = "/static/img/shopping_cart.png";
      orderClassName = "ntd-image shopping-cart";
      break;
    case "checkout":
      buttonClassName = "ntd-btn";
      orderLanguage = dictionary.cart.checkoutNtd;
      orderImage = "/static/img/shopping_cart.png";
      orderClassName = "ntd-image shopping-cart checkout";
      break;
  }

  if (buttonType === "purchase") {
    return (
      <button
        id={testId}
        role="button"
        aria-label={orderLanguage}
        className={setAdobeLaunchClass(
          getPurchaseButtonClass(enableFinancing),
          ADOBELAUNCHER_CLASS.BUTTON.LINK
        )}
        data-dtm={datadtm}
        onKeyDown={(e) => funcOnKeyDown(func, e)}
        onClick={func}
      >
        {orderLanguage}
      </button>
    );
  } else {
    return (
      <div
        onKeyDown={(e) => funcOnKeyDown(func, e)}
        onClick={func}
        className={buttonClassName}
      >
        <div className="order-button">
          <button
            id={testId}
            role="button"
            aria-label={orderLanguage}
            className={getNonPurchaseButtonClass(enableFinancing)}
          >
            {orderLanguage}
          </button>
        </div>
        <div className="order-image">
          <Image className={orderClassName} src={orderImage} />
        </div>
      </div>
    );
  }
};

const getPurchaseButtonClass = (enableFinancing) => {
  return Cx({
    btn: true,
    "financing-btn": enableFinancing,
  });
};

const getNonPurchaseButtonClass = (enableFinancing) => {
  return Cx({
    button: true,
    "order-ntd-tires": true,
    "financing-btn": enableFinancing,
  });
};

export const getPurchasNtdButtonText = (total, enableFinancing) => {
  var dictionary = languageStore.getDictionary();

  if (enableFinancing) {
    return dictionary.payInFull;
  } else if (!isUserLoggedIn() && applicationStore.data.supportConsumerOrder) {
    return dictionary.buyNow + " " + money_format(total);
  } else {
    return dictionary.cart.purchaseNtd;
  }
};

export const extractNationalDeliveries = (
  id,
  name,
  partNumber,
  fulfillments
) => {
  var deliveries = fulfillments.fulfillments,
    nationalDeliveriesId = [],
    nationalDeliveries = [];
  if (deliveries.hasOwnProperty("status")) {
    // single delivery
    if (parseFloat(deliveries.freight) > 0) {
      nationalDeliveriesId.push(id);
      nationalDeliveries.push(name + partNumber);
    }
  } else {
    // multiple deliveries
    for (var i in deliveries) {
      if (parseFloat(deliveries[i].freight) > 0) {
        nationalDeliveriesId.push(id);
        nationalDeliveries.push(name + partNumber);
      }
    }
  }
  return {
    deliveryIds: nationalDeliveriesId,
    deliveryNames: nationalDeliveries,
  };
};

export const getOrderPickUpStatus = (order) => {
  if (isEmpty(order)) {
    return false;
  } else {
    return order.isPickUpOrder;
  }
};

export const checkForLocalStock = (orderItem) => {
  var hasLocalStock = false;
  if (orderItem && orderItem.hasOwnProperty("product")) {
    if (orderItem.product.hasOwnProperty("availability")) {
      if (orderItem.product.availability.hasOwnProperty("local")) {
        hasLocalStock = true;
      }
      if (parseFloat(orderItem.product.availability.local) === 0) {
        hasLocalStock = false;
      }
    }
  }
  return hasLocalStock;
};

export const checkForLocalPlusDeliveries = (orderItems) => {
  var hasLocalPlusDelivery = false;
  if (orderItems && orderItems.orderLine) {
    for (var i in orderItems.orderLine) {
      if (
        orderItems.orderLine[i].hasOwnProperty("fulfillments") &&
        orderItems.orderLine[i].fulfillments.hasOwnProperty("fulfillments")
      ) {
        if (
          !orderItems.orderLine[i].fulfillments.fulfillments.hasOwnProperty(
            "status"
          )
        ) {
          hasLocalPlusDelivery = true;
        } else if (
          orderItems.orderLine[i].hasOwnProperty("product") &&
          orderItems.orderLine[i].product.hasOwnProperty("availability")
        ) {
          if (
            orderItems.orderLine[i].product.availability.hasOwnProperty(
              "local"
            ) &&
            parseFloat(orderItems.orderLine[i].product.availability.local) === 0
          ) {
            hasLocalPlusDelivery = true;
          }
        }
      }
    }
  }
  return hasLocalPlusDelivery;
};

export const checkForNationalDeliveries = (orderItems) => {
  var hasNationalDelivery = false;
  if (orderItems && orderItems.orderLine) {
    for (var i in orderItems.orderLine) {
      if (
        orderItems.orderLine[i].hasOwnProperty("fulfillments") &&
        orderItems.orderLine[i].fulfillments.hasOwnProperty("fulfillments")
      ) {
        if (
          orderItems.orderLine[i].fulfillments.fulfillments.hasOwnProperty(
            "status"
          )
        ) {
          if (orderItems.orderLine[i].fulfillments.fulfillments.freight > 0) {
            hasNationalDelivery = true;
            break;
          }
        } else {
          for (var j in orderItems.orderLine[i].fulfillments.fulfillments) {
            if (
              orderItems.orderLine[i].fulfillments.fulfillments[j].freight > 0
            ) {
              hasNationalDelivery = true;
              break;
            }
          }
          if (hasNationalDelivery) break;
        }
      }
    }
  }
  return hasNationalDelivery;
};

export const getNonOrderItems = (orderItems, cartItems, itemTitles) => {
  var nonNtdOrderableList = [];
  if (sizeOf(orderItems) > 0 && sizeOf(cartItems) > 0) {
    if (sizeOf(cartItems) > sizeOf(orderItems)) {
      for (var i in cartItems) {
        if (arraySearch(orderItems, cartItems[i]) === false) {
          nonNtdOrderableList.push(
            itemTitles[i][getAppLanguage()] + " - " + cartItems[i]
          );
        }
      }
    }
  }
  return nonNtdOrderableList;
};

export const getIsOrderShippedWithOtherGoods = (orderItems) => {
  orderItems = orderItems[0];

  var isShippedWithOtherGoods = false;

  if (checkArrayFieldsExist(orderItems, ["fulfillment_value"])) {
    orderItems = orderItems.fulfillment_value;
    if (checkArrayFieldsExist(orderItems, ["orderLines"])) {
      orderItems = orderItems.orderLines;
      if (checkArrayFieldsExist(orderItems, ["orderLine"])) {
        orderItems = orderItems.orderLine;
        if (sizeOf(orderItems) > 0) {
          for (var i in orderItems) {
            if (checkArrayFieldsExist(orderItems[i], ["fulfillments"])) {
              var orderItem = orderItems[i].fulfillments;
              if (checkArrayFieldsExist(orderItem, ["fulfillments"])) {
                var orderFulfillments = orderItem.fulfillments;

                if (checkArrayFieldsExist(orderFulfillments, ["status"])) {
                  if (
                    checkArrayFieldsExist(orderFulfillments, ["shipMethod"])
                  ) {
                    if (orderFulfillments.shipMethod === "With Other Goods") {
                      isShippedWithOtherGoods = true;
                      break;
                    }
                  }
                } else {
                  for (var j in orderFulfillments) {
                    if (
                      checkArrayFieldsExist(orderFulfillments[j], [
                        "shipMethod",
                      ])
                    ) {
                      if (
                        orderFulfillments[j].shipMethod === "With Other Goods"
                      ) {
                        isShippedWithOtherGoods = true;
                        break;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return isShippedWithOtherGoods;
};

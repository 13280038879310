import { applicationStore } from '../stores/ApplicationStore.js';

export const styleHeaderLogo = () => {
  var logo = null, headerNav = null;
  if (!applicationStore.data.enableMercedesFeatures) {
    if (applicationStore.data.dealerRetailSiteCustomLogo !== "" && typeof applicationStore.data.dealerRetailSiteCustomLogo !== 'undefined') {
      if (!applicationStore.data.enableSubaruFeatures && !applicationStore.data.enableToyotaFeatures) {
        logo = document.getElementsByClassName('logo')[0];
        if (logo) {
          logo.classList.add("custom-logo");
          logo.setAttribute('style', "background-image: url('" + applicationStore.data.dealerRetailSiteCustomLogo + "');");
        }
      } else {
        logo = document.getElementsByClassName('logo-image')[0];
        if (logo) {
          logo.classList.add("custom-logo");
          logo.src = applicationStore.data.dealerRetailSiteCustomLogo;
          logo.setAttribute('style', "width:180px; padding:12px 15px;");
        }
      }

      // move header navigation to correspond
      headerNav = document.getElementsByClassName('header-navigation')[0];
      if (headerNav) {
        headerNav.setAttribute('style', headerNavigationOffset(applicationStore.data.defaultTemplate).style);
      }
    }
    if (applicationStore.data.groupRetailSiteCustomLogo !== "" && typeof applicationStore.data.groupRetailSiteCustomLogo !== 'undefined') {
      if (!applicationStore.data.enableSubaruFeatures && !applicationStore.data.enableToyotaFeatures) {
        logo = document.getElementsByClassName('logo')[0];
        if (logo) {
          logo.classList.add("custom-logo");
          logo.setAttribute('style', "background-image: url('" + applicationStore.data.groupRetailSiteCustomLogo + "');");
        }
      } else {
        logo = document.getElementsByClassName('logo-image')[0];
        if (logo) {
          logo.classList.add("custom-logo");
          logo.src = applicationStore.data.dealerRetailSiteCustomLogo;
          logo.setAttribute('style', "width:180px; padding:12px 15px;");
        }
      }

      // move header navigation to correspond
      headerNav = document.getElementsByClassName('header-navigation')[0];
      if (headerNav) {
        headerNav.setAttribute('style', headerNavigationOffset(applicationStore.data.defaultTemplate).style);
      }
    }
  }
};

export const headerNavigationOffset = (template) => {
  switch(template) {
    case 'acura':
      return { style: "left:185px;" };
    case 'audi':
      return { style: "left:auto;right:-20px;" };
    case 'audi_2021':
      return { style: "left:185px;" };
    case 'chrysler':
      return { style: "left:185px;" };
    case 'consumer':
      return { style: "left:185px;" };
    case 'ford':
      return { style: "left:185px;" };
    case 'fungroup':
      return { style: "left:185px;" };
    case 'generic':
      return { style: "left:185px;" };
    case 'gmcanada':
      return { style: "left:185px;" };
    case 'honda':
      return { style: "left:185px;" };
    case 'hyundai':
      return { style: "left:200px;" };
    case 'integra':
      return { style: "left:185px;" };
    case 'kia':
      return { style: "left:185px;" };
    case 'kia_2021':
      return { style: "left:185px;" };
    case 'lexus':
      return { style: "left:185px;" };
    case 'mazda':
      return { style: "left:185px;" };
    case 'mazdatransition':
      return { style: "left:185px;" };
    case 'mercedes':
      return { style: "left:185px;" };
    case 'mercedes_2022':
      return  {style:"left:185px;"};
    case 'mercedestransition':
      return { style: "left:185px;" };
    case 'mini':
      return { style: "left:185px;" };
    case 'mrlube':
      return { style: "left:185px;" };
    case 'southeasttoyota':
      return { style: "left:185px;" };
    case 'tirecraft':
      return { style: "left:185px;" };
    case 'toyota':
      return { style: "left:225px;" };
    case 'toyotausa':
      return { style: "left:185px;" };
    case 'volkswagen':
      return { style: "left:200px;" };
    default:
      return { style: "left:130px;" };
  }
};
module.exports = {
  'homepageTitleText': 'Your Volkswagen Dealership is the best place to buy tires',
  'homepageText': 'Find your perfect tire fit. Simply tell us about your Volkswagen and we\'ll recommend the right options for optimal performance, safety, and fuel economy. Not only is from your Volkswagen Dealer easy and convenient, having them installed by a Volkswagen Certified Technician can help reduce overall wear and tear. They\'ll make sure fit, alignment, and tire pressure, are perfect.',
  onTheRoad: 'On the road',
  withCompleteWheels: 'with complete wheels',
  forTires: 'for Tires',
  needNewWheels: 'Need new wheels? From sizing to seasonal to best-selling brands, your Volkswagen dealer can help determine the best fit for your speific model.',
  youreTiresAre: 'Your tires are your only contact to the road. Discover information, Volkswagen products and services concerning your wheels and tires - right here.',
  rememberToBuy: 'Remember to buy wheels when you purchase your tires. Volkswagen offers a wide selection of alloy and steel wheels to go along with your new tires, making it easier to install and uninstall, and store them each season.',
	'common': {
		'featuredItems': "Popular Items"
	},
	'productTypes': {
		'tireSelectText': 'Find Winter, All Season and summer tires for your vehicle for top brands like: Michelin, Goodyear, Firestone, Continental, Pirelli and more',
		'wheelSelectText': 'Find volkswagen original wheels for your vehicle and see what they look like on your vehicle',
		'accessorySelectText': '',
		'packageSelectText': 'Find mounted Tires and Wheels for your turn key solution for your winter tire needs.'
	},
	'dealerFilter': {
		'titlePrefix': 'Locate a'
	},
	'search': {
		'placeholderSearchTireSize': 'Enter your tire size: ex. 195/65R15'
	},
	'launcher': {
		'enterTiresize': 'Enter your tire size : ex.195/65R15'
	}
};
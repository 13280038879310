import { DispatcherActions } from '../constants/DispatcherActions.js'
import { applicationStore } from '../stores/ApplicationStore.js';
import { productListStore } from '../stores/ProductListStore.js';
import { searchStore } from '../stores/SearchStore.js';
import { AbstractAction } from './AbstractAction.js'
import { ProductTypes } from '../constants/ProductTypes.js';

import { isEmpty, getAppDealerId } from '../service/UtilityService.js';
import { SearchTypes } from "../constants/SearchTypes";
import {getTrackingEventCategory} from "../service/UtilityService";
import store from "../stores/configureStore";
import {loadTires, loadVehicleInfo, onListTiresLoaded, productListLoading} from "../redux/features/productList";

const SHOW_OE_TIRES_ONLY = "show-only-oe";
const SHOW_ALL_TIRES = "show-all";


var SearchActions = AbstractAction.extend({

	loadVehicleSimpleSearch: function(year, make, category, model) {
    this.dispatch(DispatcherActions.LOAD_VEHICLE_SIMPLE_SEARCH, { year: year, make: make, category: category, model: model });
	},

	loadSearch: function(searchType, search) {
    this.dispatch(DispatcherActions.SET_SEARCH, { type: searchType, search: search });
	},

  loadConsumerSearch: function() {
    this.dispatch(DispatcherActions.SET_SEARCH, { type: SearchTypes.SEARCH_BY_TIRESIZE, search: '' });
	},

  loadConsumerTires: function(season = null) {

    this.apiGet('/parts/tire/getTirePriceByTireSize.php', {
      tireSize: applicationStore.data.consumerTiresize,
      lat: applicationStore.data.consumerPosition.lat,
      long: applicationStore.data.consumerPosition.lng,
      distance: applicationStore.data.consumerDistance,
      season: !isEmpty(season) ? season : applicationStore.data.consumerSeason,
      isNational: false,
    }).then(function(response) {

      this.dispatch(DispatcherActions.PRODUCT_LIST_TIRES_LOADED, response);

    }.bind(this));

  },

  loadSearchResults: function () {
    this.apiPost('/parts/tire/searchTiresByTiresize.php', {
      dealerId: getAppDealerId(),
      tireSize: applicationStore.data.search,
      season: productListStore.data.season,
      originalEquipmentFilter: productListStore.data.originalEquipmentOnly == 1 ? "OE" : "ALL",
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
			store.dispatch(onListTiresLoaded(response));
      var label = 'tire tiresize' + ' - ' + '{' + applicationStore.data.search + '}';
      // this.runGoogleAnalytics(label);

    }).catch((e)=>{
			console.error(e);
		});

  },

  runGoogleAnalytics: function(label) {
    setTimeout(() => {

      googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'open search result', label);

    }, 100);
  },

	loadSearchResultsByPartNumber: function (token) {
    var requestUrl = "";
		switch (searchStore.data.searchProductType) {
			case ProductTypes.TIRES:
				requestUrl = '/parts/tire/searchTiresByPartNumber.php';
				break;
			case ProductTypes.ALLOY_WHEELS:
				requestUrl = '/parts/wheel/searchWheelsByPartNumber.php';
				break;
			case ProductTypes.ACCESSORIES:
				requestUrl = '/parts/accessory/searchAccessoriesByPartNumber.php';
				break;
			case ProductTypes.PACKAGES:
				requestUrl = '/parts/package/searchPackagesByPartNumber.php';
				break;
			default:
				break;
		}

		this.apiGet(requestUrl, {
			dealerId: getAppDealerId(),
			partNumber: applicationStore.data.search,
			isNational: applicationStore.data.nationalSite ? 1 : 0
		}).then(function (response) {

      switch (searchStore.data.searchProductType) {
        case ProductTypes.TIRES:
          store.dispatch(onListTiresLoaded(response));
          break;
        default:
          this.dispatch(DispatcherActions.SEARCH_RESULT_LOADED, response);
          break;
      }

      var label = 'tire partnumber' + ' - ' + '{' + applicationStore.data.search + '}';
      this.runGoogleAnalytics(label);

		}.bind(this));
	},

	stockRefreshed: function (payload) {
		this.dispatch(DispatcherActions.SEARCH_RESULT_STOCK_REFRESHED, payload);
	},

	requestStockRefresh: function (url, stockLocationKey, frontTireId) {
		this.dispatch(DispatcherActions.SEARCH_RESULT_REQUEST_STOCK_REFRESH, {
			stockLocationKey: stockLocationKey,
			frontTireId: frontTireId,
			rearTireId: 0,
			url: url
		});
	},

	showOrHideBrandFilter: function () {
		this.dispatch(DispatcherActions.SEARCH_SHOW_OR_HIDE_BRAND_FILTER, {});
	},

	showOrHidePlyFilter: function () {
		this.dispatch(DispatcherActions.SEARCH_SHOW_OR_HIDE_PLY_FILTER, {});
	},

  showOrHidePricingFilter: function() {
    this.dispatch(DispatcherActions.SEARCH_SHOW_OR_HIDE_PRICING_FILTER, {});
  },

	filterSeason: function (season, token) {
		this.dispatch(DispatcherActions.SEARCH_FILTER_SEASON, { season: season, token: token });
	},

	filterLoadRating: function (loadRating) {
		this.dispatch(DispatcherActions.SEARCH_FILTER_LOAD_RATING, loadRating);
	},

	filterSpeedIndex: function (speedIndex) {
		this.dispatch(DispatcherActions.SEARCH_FILTER_SPEED_INDEX, speedIndex);
	},

	filterPlyRating: function (plyRating) {
		this.dispatch(DispatcherActions.SEARCH_FILTER_PLY_RATING, plyRating);
	},

  filterTag: function (tag) {
    this.dispatch(DispatcherActions.SEARCH_FILTER_TAG, tag);
  },

	filterBrands: function (brands) {
		this.dispatch(DispatcherActions.SEARCH_FILTER_BRANDS, brands);
	},

  filterByPricing: function(pricing) {
    this.dispatch(DispatcherActions.SEARCH_PRICING_SELECTED, pricing);
  },

  filterPricingToggleAll: function(toggleAll) {
    this.dispatch(DispatcherActions.SEARCH_PRICING_TOGGLE_ALL, toggleAll);
  },

	setSearchType: function (type) {
		this.dispatch(DispatcherActions.SEARCH_SET_TYPE, type);
	},

	setSearchProductType: function (type) {
		this.dispatch(DispatcherActions.SEARCH_SET_PRODUCT_TYPE, type);
	},

	requestItemStockRefresh: function (frontTireId, rearTireId) {
		this.dispatch(DispatcherActions.SEARCH_REQUEST_ITEM_STOCK_REFRESH, {
			frontTireId: frontTireId,
			rearTireId: rearTireId
		});
	},

	loadSeasonFilter: function (filters) {
		this.dispatch(DispatcherActions.SEARCH_LOAD_SEASON_FILTER, filters);
	},

	loadTireBrandFilter: function (filters) {
		this.dispatch(DispatcherActions.SEARCH_LOAD_TIRE_BRAND_FILTER, filters);
	},

	loadAccessoryCategoryFilter: function (filters) {
		this.dispatch(DispatcherActions.SEARCH_LOAD_ACCESSORY_CATEGORY_FILTER, filters);
	},

	tireListIncrementPageIndex: function () {
		this.dispatch(DispatcherActions.SEARCH_INCREMENT_PAGE_INDEX, {});
	}

});

export const searchActions = new SearchActions();

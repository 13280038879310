import { createSlice } from "@reduxjs/toolkit";

const quoteSlice = createSlice({
  name: "quote",
  initialState: {
    sortType: "date",
    sortFilterDate: "4 months",
    sortByFilpFlop: -1,
    numQuotesToShow: "50",
    searchInput: "",
    showQuoteReport: false,
    showLeadReport: false,
  },
  reducers: {
    quoteReportUpdateSort: (state, { payload }) => {
      state.sortType = payload.newType;
      state.sortByFilpFlop = payload.load;
    },
    quoteDateFilter: (state, { payload }) => {
      state.sortFilterDate = payload;
    },
    quoteFilterCount: (state, { payload }) => {
      state.numQuotesToShow = payload;
    },
    quoteSearchInput: (state, { payload }) => {
      state.searchInput = payload;
    },
    showQuoteReport: (state, { payload }) => {
      state.showQuoteReport = payload;
    },
    showLeadReport: (state, { payload }) => {
      state.showLeadReport = payload;
    },
  },
});

export const {
  quoteReportUpdateSort,
  quoteDateFilter,
  quoteFilterCount,
  quoteSearchInput,
  showQuoteReport,
  showLeadReport,
} = quoteSlice.actions;
export default quoteSlice.reducer;

module.exports = {
	'product': {
		'winterSizesDesc': 'Meilleures options lorsque vous remplacez votre pneu  avec celui qui est venu avec votre véhicule',
		'originalEqSizesDesc': ''
	},
	'launcher': {
		defaultDisclaimer: "",
		disclaimer: "Service Certifié",
		disclaimer1: "Vous quittez <a href='mycertifiedservice.ca'>mycertifiedservice.ca</a>",
		disclaimer2: "Vous quittez <a href='mycertifiedservice.ca'>mycertifiedservice.ca</a> pour vous rendre sur un site Web géré indépendamment et sur lequel General Motors du Canada n’effectue aucun service de maintenance. De même, la politique sur la protection de la vie privée de General Motors du Canada ne s’applique pas à ce site.",
		disclaimer3: "Ce lien vous est fournit à des fins de commodité mais ne signifie en aucun cas que General Motors du Canada approuve les renseignements ou le contenu se trouvant sur ce site."
	}
};
import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";
import { ProductTypes } from "../../constants/ProductTypes.js";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { applicationActions } from "../../actions/ApplicationActions.js";
import { searchStore } from "../../stores/SearchStore.js";
import { productListStore } from "../../stores/ProductListStore.js";
import { shoppingCartStore } from "../../stores/ShoppingCartStore.js";
import { shoppingCartViewStore } from "../../stores/ShoppingCartViewStore.js";

import { WishlistDealerSelectionModal } from "./WishlistDealerSelectionModal.jsx";
import { WishlistEmailSimpleModal } from "./WishlistEmailSimpleModal.jsx";
import WishlistAppointmentModal from "./WishlistAppointmentModal.jsx";

import { Router } from "../../stores/Router.js";

import { EmailField } from "../ux/forms/EmailField.jsx";
import { TextField } from "../ux/forms/TextField.jsx";
import { TelField } from "../ux/forms/TelField.jsx";
import { ComboBox } from "../ux/forms/ComboBox.jsx";
import { ComboBoxValue } from "../ux/forms/ComboBoxValue.jsx";
import { Modal } from "../ux/Modal.jsx";

import {
  isUserLoggedIn,
  tokenHasSessionReference,
} from "../../api/AuthService.js";

import {
  formTypes,
  hasVehicle,
  getVehicleName,
  isEmpty,
  money_format,
  sizeOf,
  getAppLanguage,
  getTrackingEventCategory,
  getTrackingEventOrderId,
  getDealerName,
  getDealerContactInfo,
} from "../../service/UtilityService.js";
import {
  checkNTDStockExists,
  renderNTDButton,
  getDeliveryDetails,
  getEstimatedDeliveryDetails,
  getOrderATDConfirmationNumber,
} from "../../service/OrderService.js";
import {
  convertDateToFrench,
  decodeDayAbbrev,
  decodeStoreHours,
} from "../../service/CalendarService.js";
import {
  renderWishlistHeader,
  renderSaving,
  renderProductsNonOrder,
  renderProductsNonOrderDisclaimer,
  renderWishlistSummaryTable,
  renderBanner,
  renderSummary,
  renderControls,
  loadWishlistWithContext,
  renderEcommerceAppointmentModal,
  renderEcommerceTimeSelectionModal,
  renderFoxyCart,
  renderFoxyCartError,
  renderCompleteEcommerceModal,
  toggleScheduleEcommerceAppointment,
  openMarkAsComplete,
  getSupportConsumerOrder,
  getOrderDeposit,
  getTotalCost,
  getOrderDisclaimerList,
} from "../../service/WishlistService.js";

import { renderProducts } from "../../service/WishlistService.js";
import {
  initializeWishlistPDF,
  componentMountPrintService,
  componentUpdatePrintService,
  renderWishlistPdfLoading,
} from "../../service/PrintService.js";
import { getQuoteTrackingLabelInfo } from "../../service/WishlistService.js";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService.js";
import { connect } from "react-redux";
import {
  markAsCompleteModal,
  requestModalToggle,
  shoppingCartOpenForm,
  toggleCart,
  toggleShoppingModal,
} from "../../redux/features/shoppingCart.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var ReactDOM = require("react-dom");
var Immutable = require("immutable");

const INCOMPLETE_ORDER = "NEW";

class WishlistReview extends I18nComponent {
  state = {
    cartItems: this.props.shoppingCartItems,
    shoppingCartViewCreated: shoppingCartViewStore.data.get(
      "shoppingCartViewCreated"
    ),
    appointmentRequestDate: shoppingCartViewStore.data.get(
      "appointmentRequestDate"
    ),
    appointmentRequestTime: shoppingCartViewStore.data.get(
      "appointmentRequestTime"
    ),
    appointmentRequestNotes: "",
    subtotal: shoppingCartViewStore.data.get("subtotal"),
    tax: shoppingCartViewStore.data.get("tax"),
    total: shoppingCartViewStore.data.get("total"),
    taxtotal: shoppingCartViewStore.data.get("taxtotal"),
    orderRestockingFee: shoppingCartViewStore.data.get("orderRestockingFee"),
    orderDueAtDealer: shoppingCartViewStore.data.get("orderDueAtDealer"),
    numberOfPromotions: shoppingCartViewStore.data.get("numberOfPromotions"),
    totalSavings: shoppingCartViewStore.data.get("totalSavings"),
    totalMSRPSavings: shoppingCartViewStore.data.get("totalMSRPSavings"),
    firstNonNullPromotion: shoppingCartViewStore.data.get(
      "firstNonNullPromotion"
    ),
    shoppingCartViewNotes: shoppingCartViewStore.data.get(
      "shoppingCartViewNotes"
    ),
    orderStatusDescription: shoppingCartViewStore.data.get(
      "orderStatusDescription"
    ),
    vehicleInformation: shoppingCartViewStore.data.get("vehicleInformation"),

    cartId: applicationStore.data.cart,
    orderId: shoppingCartViewStore.data.get("orderId"),
    orderNumber: shoppingCartViewStore.data.get("orderGUID"),
    orderStatus: shoppingCartViewStore.data.get("orderStatus"),
    orderFulfillmentList: shoppingCartViewStore.data.get(
      "orderFulfillmentList"
    ),

    showScheduleWishlistModal: shoppingCartStore.data.get(
      "scheduleWishlistModal"
    ),
    showEmailWishlistModal: shoppingCartStore.data.get("emailWishlistModal"),
    showScheduleAppointmentModal: shoppingCartStore.data.get(
      "scheduleAppointmentWishlistModal"
    ),
    saveWishlistSuccess: shoppingCartStore.data.get("saveWishlistSuccess"),
    emailWishlistModalEmailSent: shoppingCartStore.data.get(
      "emailWishlistModalEmailSent"
    ),
    emailWishlistModalEmailFailed: shoppingCartStore.data.get(
      "emailWishlistModalEmailFailed"
    ),
    showModal: shoppingCartStore.data.get("showModal"),

    showSessionReferenceModal: shoppingCartStore.data.get(
      "saveSessionReferenceShowModal"
    ),
    saveSessionReferenceSent: shoppingCartStore.data.get(
      "saveSessionReferenceSent"
    ),
    saveSessionReferenceFailed: shoppingCartStore.data.get(
      "saveSessionReferenceFailed"
    ),

    orderDeposit: shoppingCartViewStore.data.get("orderDeposit"),
    orderDepositDealer: shoppingCartViewStore.data.get("orderDepositDealer"),
    orderDepositInternal: shoppingCartViewStore.data.get(
      "orderDepositInternal"
    ),
    orderDisclaimerList: shoppingCartViewStore.data.get("orderDisclaimerList"),

    banner: applicationStore.data.wishlistBanner,

    emailSent: shoppingCartViewStore.data.get("emailSent"),
    appointmentSent: shoppingCartViewStore.data.get("appointmentSent"),

    foxyCart: shoppingCartViewStore.data.get("foxyCart"),
    foxyCartError: shoppingCartViewStore.data.get("foxyCartError"),
    runFoxyCartForm: false,

    userConfigSupportDealerOrder: applicationStore.data.supportDealerOrder,
    userConfigSupportDealerOrderAdminPrivilege:
      applicationStore.data.supportUserDealerOrder,
    userConfigSupportConsumerOrder: applicationStore.data.supportConsumerOrder,
    userConfigSupportConsumerOrderAdminPrivilege:
      applicationStore.data.supportUserConsumerOrder,

    supportDealerOrder: shoppingCartViewStore.data.get("supportDealerOrder"),
    supportConsumerOrder: shoppingCartViewStore.data.get(
      "supportConsumerOrder"
    ),

    enableNTDOrderModal: false,
    showMarkAsComplete: false,
    workOrderNumber: "",
    completeEcommerceModal: false,

    showDepositInfoModal: false,
    ecommerceAppointmentModal: false,
    ecommerceAppointmentCalendar: false,
    ecommerceAppointmentTimeModal: false,
    ecommerceAppointmentTimeAmOrPm: true,
    ecommerceAppointmentTempHour: "",
    ecommerceAppointmentTempMinute: "",
    loggedIn: isUserLoggedIn(),

    cancelOrderModal: false,
    reasonForCancelling: 0,
    cancelEmailIsValid: false,

    dealerEnableXtimeAppointmentIntegration:
      applicationStore.data.dealerEnableXtimeAppointmentIntegration,
    dealerEnablePromptToOrderTires:
      applicationStore.data.dealerEnablePromptToOrderTires,

    shoppingContext: Router.collectShoppingContext(),
    wishlistTracked: false,

    pdfLauncher: null,
    wishlistPdf: initializeWishlistPDF(),
    renderSavingToggle: false,
  };

  updateState = (state) => {
    this.setState(state);
  };

  componentWillMount() {
    if (this.props.renderSavingToggle) {
      loadWishlistWithContext();
    }

    applicationStore.addChangeListener(this.onChange);
    searchStore.addChangeListener(this.onChange);
    productListStore.addChangeListener(this.onChange);
    shoppingCartStore.addChangeListener(this.onChange);
    shoppingCartViewStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
    searchStore.removeChangeListener(this.onChange);
    productListStore.removeChangeListener(this.onChange);
    shoppingCartStore.removeChangeListener(this.onChange);
    shoppingCartViewStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    componentMountPrintService(this.updateState);
    shoppingCartStore.onShoppingCartClose();
    this.props.dispatch(toggleCart(false));
    // loadWishlistWithContext();
    setTimeout(() => {
      loadWishlistWithContext();
    }, 100);
  }

  shouldComponentUpdate(lastProps, nextState) {
    if (!this.state.loggedIn && nextState.loggedIn) {
      loadWishlistWithContext();
    }
    if (
      !this.state.foxyCart &&
      nextState.foxyCart &&
      !nextState.foxyCartError &&
      nextState.shoppingContext.isWishlist
    ) {
      this.setState({ runFoxyCartForm: true });
    }
    if (this.state.runFoxyCartForm) {
      this.setState({ runFoxyCartForm: false });
      var form = document.getElementById("orderRedirectForm");
      if (form) form.submit();
    }
    return this.props !== lastProps || this.state !== nextState;
  }

  componentDidUpdate(nextProps, lastState) {
    if (lastState.cartItems !== this.state.cartItems) {
      this.onChange();
    }
    if (this.refs.btnNewQuote)
      ReactDOM.findDOMNode(this.refs.btnNewQuote).focus();
    if (
      this.state.ecommerceAppointmentTimeModal &&
      !lastState.ecommerceAppointmentTimeModal
    ) {
      this.updateAppointmentAmOrPm();
    }
    if (!this.state.wishlistTracked && sizeOf(this.state.cartItems) > 0) {
      this.setState({ wishlistTracked: true });
      googleAnalyticsEnhancedTrackPageview("Shopping Cart Review");
      googleAnalyticsEnhancedTrackEvent(
        getTrackingEventCategory(),
        "Shopping Cart Review Access",
        money_format(shoppingCartViewStore.data.get("total"))
      );
      //googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'Shopping Cart Review Access', getTrackingEventOrderId(), money_format(shoppingCartViewStore.data.get('total')));
    }
    componentUpdatePrintService(pdfMake, this.state, this.updateState);
  }

  render() {
    var vehicleId = 0,
      cartItemsValid = false,
      ntdOrderStockEnabled = false;

    var cartItems = this.state.cartItems;
    if (cartItems) {
      for (var i in ProductTypes) {
        var items = cartItems.get(i);
        if (items && typeof items !== "undefined") {
          items.map(function (item) {
            if (!ntdOrderStockEnabled)
              ntdOrderStockEnabled = checkNTDStockExists(item);
          });
        }
      }
    }

    if (typeof this.state.vehicleInformation !== "undefined") {
      if (
        typeof this.state.cartItems.get(ProductTypes.PACKAGES) !== "undefined"
      ) {
        this.state.cartItems
          .get(ProductTypes.PACKAGES)
          .forEach(function (item) {
            if (item.get("vehicleId") > 0 && vehicleId === 0) {
              vehicleId = item.get("vehicleId");
            }
          });
      }
    }

    if (typeof this.state.cartItems !== "undefined") {
      if (this.state.cartItems.size > 0) {
        cartItemsValid = true;
      }
    }
    console.log(cartItemsValid, "this is our carITMEMSVALID");
    return (
      <box className="main">
        {this.renderScheduleWishlistModal()}
        {this.renderEmailWishlistModal()}
        {this.renderAppointmentWishlistModal()}
        {this.renderEmailAppointmentSentModal()}
        {this.renderSessionReferenceModal()}
        {this.renderMarkAsCompleteModal()}
        {renderWishlistPdfLoading(this.state)}
        <box className="wishlist">
          <box className="container">
            <box className="wishlist-body no-stage">
              <column className="fixwishlistproducts">
                <box className="wishlist-products">
                  {renderWishlistHeader(
                    false,
                    !this.state.supportConsumerOrder ||
                      !applicationStore.data.quoteImmediately,
                    this.state,
                    this.updateState,
                    this.printQuote,
                    this.downloadQuote
                  )}

                  {renderProducts(ProductTypes.TIRES)}
                  {renderProducts(ProductTypes.ALLOY_WHEELS)}
                  {renderProducts(ProductTypes.ACCESSORIES)}
                  {renderProducts(ProductTypes.PACKAGES)}

                  {this.props.renderSavingToggle && renderSaving()}

                  {cartItemsValid && renderWishlistSummaryTable()}

                  {cartItemsValid ? (
                    <div
                      className={
                        this.state.banner
                          ? "wishlist-info-bottom has-wishlist-banner"
                          : "wishlist-info-bottom"
                      }
                    >
                      <div className="vehicle-info">
                        {!this.isCartReview() &&
                        !this.removeOptionalFeatures() ? (
                          <h3>{this.t("wishlist.personalizedWishlist")}</h3>
                        ) : (
                          <h3>
                            {this.t("wishlist.orderReferemce")}
                            {this.props.cart?.orderId}
                          </h3>
                        )}
                      </div>

                      {renderSummary()}

                      {this.renderOrderStatus()}
                      {this.renderCancelOrder()}

                      {!this.removeOptionalFeatures() ? (
                        <div className="wishlist-note-header">
                          {this.state.shoppingCartViewNotes ? (
                            <span className="wishlist-note">
                              <b>{this.td(this.state.shoppingCartViewNotes)}</b>
                            </span>
                          ) : (
                            false
                          )}
                        </div>
                      ) : (
                        false
                      )}

                      {this.enableDealerInfo() ? (
                        <div>
                          <container className="side small">
                            <box>
                              <text>{getDealerName()}</text>
                            </box>
                            <box>
                              <text>{getDealerContactInfo()}</text>
                            </box>
                          </container>
                        </div>
                      ) : (
                        false
                      )}

                      {renderControls(
                        false,
                        this.state,
                        this.updateState,
                        this.printQuote,
                        this.downloadQuote,
                        this.emailQuote
                      )}
                    </div>
                  ) : (
                    false
                  )}

                  {renderProductsNonOrderDisclaimer()}
                  {renderProductsNonOrder(ProductTypes.TIRES)}
                  {renderProductsNonOrder(ProductTypes.ALLOY_WHEELS)}
                  {renderProductsNonOrder(ProductTypes.ACCESSORIES)}
                  {renderProductsNonOrder(ProductTypes.PACKAGES)}

                  {renderBanner()}
                </box>
              </column>
              <column className="fixwishlistactions">
                {this.renderWishlistActions()}
                {this.renderWishlistActionsNTDEnabled(ntdOrderStockEnabled)}
                {this.renderWishlistActionsNTDConsumerEnabled()}
                {this.renderWishlistActionsNTDConsumerReviewEnabled()}
              </column>
              {this.renderFoxyCart()}
              {this.renderFoxyCartErrorModal()}
              {this.renderCancelOrderModal()}
              {this.renderEcommerceAppointmentModal()}
              {this.renderTimeSelectionModal()}
              {this.renderCompleteEcommerceModal()}
              {this.renderDepositInfoModal()}
            </box>
          </box>
        </box>
      </box>
    );
  }

  enableDealerInfo = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "hyundai":
        return true;
      default:
        return false;
    }
  };

  callbackRequest = () => {
    //googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'Shopping Cart Review - Open Request Callback Form', getTrackingEventOrderId());
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "open lead form",
      getQuoteTrackingLabelInfo("callback"),
      shoppingCartViewStore.data.get("total")
    );
    this.props.dispatch(shoppingCartOpenForm(formTypes.CALLBACK));
    shoppingCartActions.openWishlistFormModal(formTypes.CALLBACK);
  };

  bookAppointment = () => {
    //googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'Shopping Cart Review - Open Book Appointment Form', getTrackingEventOrderId());
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "open lead form",
      getQuoteTrackingLabelInfo("appointment"),
      shoppingCartViewStore.data.get("total")
    );
    this.props.dispatch(shoppingCartOpenForm(formTypes.APPOINTMENT));
    shoppingCartActions.openWishlistFormModal(formTypes.APPOINTMENT);
  };

  reserveQuote = () => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Shopping Cart Review - Open Reserve Quote Form",
      getTrackingEventOrderId()
    );
    this.props.dispatch(shoppingCartOpenForm(formTypes.RESERVE));
    // shoppingCartActions.openWishlistFormModal(formTypes.RESERVE);
  };

  compareQuote = () => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Shopping Cart Review - Open Compare Quote Form",
      getTrackingEventOrderId()
    );
    this.props.dispatch(shoppingCartOpenForm(formTypes.PRICEMATCH));

    // shoppingCartActions.openWishlistFormModal(formTypes.PRICEMATCH);
  };

  emailQuote = () => {
    //googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'Shopping Cart Review - Open Email Form', getTrackingEventOrderId());
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "open lead form",
      getQuoteTrackingLabelInfo("email"),
      shoppingCartViewStore.data.get("total")
    );
    this.props.dispatch(shoppingCartOpenForm(formTypes.SEND));
    shoppingCartActions.openWishlistFormModal(formTypes.SEND);
  };

  printQuote = () => {
    //googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'Shopping Cart Review - Open Print Form', getTrackingEventOrderId());
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "open lead form",
      getQuoteTrackingLabelInfo("print"),
      shoppingCartViewStore.data.get("total")
    );
    this.props.dispatch(shoppingCartOpenForm(formTypes.PRINT));
    shoppingCartActions.openWishlistFormModal(formTypes.PRINT);
  };

  downloadQuote = () => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Shopping Cart Review - Open Download Form",
      getTrackingEventOrderId()
    );
    this.props.dispatch(shoppingCartOpenForm(formTypes.DOWNLOAD));
    shoppingCartActions.openWishlistFormModal(formTypes.DOWNLOAD);
  };

  onBookXtimeAppointment = () => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "X-time Booking Access"
    );
    applicationActions.onOpenNtdModal(true);
  };

  saveWishlist = () => {
    //googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'Shopping Cart Review - Open Save Form', getTrackingEventOrderId());
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "open lead form",
      getQuoteTrackingLabelInfo("save"),
      shoppingCartViewStore.data.get("total")
    );
    shoppingCartActions.openWishlistFormModal(formTypes.SAVE);
    this.props.dispatch(shoppingCartOpenForm(formTypes.SAVE));
  };

  renderFoxyCart = () => {
    return <box>{renderFoxyCart(this.state)}</box>;
  };

  renderFoxyCartErrorModal = () => {
    return renderFoxyCartError(this.state);
  };

  shouldRenderAppointmentRequest = () => {
    return (
      !this.state.supportDealerOrder &&
      !getSupportConsumerOrder() &&
      (!applicationStore.data.dealerAllowNtdStock || !this.state.loggedIn) &&
      !this.isEcommerce() &&
      !this.isOrder()
    );
  };

  renderWishlistActions = () => {
    if (this.shouldRenderAppointmentRequest() || this.isOrderReview()) {
      var date = new Date(this.state.shoppingCartViewCreated);
      var totalMSRPSavings = parseFloat(this.state.totalMSRPSavings);
      var total = this.state.total;
      var hasPromotion = false;
      var cartItems = this.state.cartItems;
      if (cartItems) {
        cartItems.forEach(function (productType) {
          productType.forEach(function (cartItem) {
            if (
              cartItem.get("hasPromotion") ||
              applicationStore.data.dealerShowMsrp
            ) {
              hasPromotion = true;
            }
          });
        });
      }
      var showSavings = hasPromotion && totalMSRPSavings > 0;

      totalMSRPSavings = money_format(totalMSRPSavings);

      // total = money_format(total);
      total = money_format(getTotalCost("total"));

      var hours = "",
        dealerHourList = applicationStore.data.dealer
          ? applicationStore.data.dealer.get("hours")
          : null,
        isMazda =
          applicationStore.data.defaultTemplate === "mazda" ||
          applicationStore.data.defaultTemplate === "mazdatransition";
      if (applicationStore.data.dealer && dealerHourList) {
        for (var i in dealerHourList) {
          if (i !== "SUN")
            hours +=
              decodeDayAbbrev(i) +
              " " +
              decodeStoreHours(
                dealerHourList[i].open,
                dealerHourList[i].close
              ) +
              ", ";
          else
            hours +=
              decodeDayAbbrev(i) +
              " " +
              decodeStoreHours(dealerHourList[i].open, dealerHourList[i].close);
        }
      }

      var atdConfirmationNumber = null;
      if (this.isOrderReview()) {
        atdConfirmationNumber = getOrderATDConfirmationNumber(
          this.state.orderFulfillmentList
        );
      }

      return (
        <box className="wishlist-actions no-print">
          {applicationStore.data.dealerInfo &&
          !applicationStore.data.useProvincialDealers ? (
            <div className="vehicle-info">
              <h2 className="dealer-name">
                {applicationStore.data.dealerInfo.name}
              </h2>
              <span className="dealer-address">
                {Immutable.fromJS([
                  applicationStore.data.dealerInfo.address,
                  applicationStore.data.dealerInfo.city,
                  applicationStore.data.dealerInfo.province,
                  applicationStore.data.dealerInfo.postalCode,
                  applicationStore.data.dealerInfo.phone,
                  applicationStore.data.dealerInfo.email,
                ])
                  .filter(function (value) {
                    return value;
                  })
                  .toArray()
                  .join(", ")}
              </span>
              {!isMazda ? (
                <span className="dealer-address">{hours}</span>
              ) : (
                false
              )}
            </div>
          ) : (
            false
          )}
          {showSavings && false ? <hr className="closable" /> : false}
          {showSavings && false ? (
            <div className="wishlist-summary-savings">
              <span className="title-left">
                {this.t("wishlist.totalSavings")}:
              </span>
              <span className="value-right">{totalMSRPSavings}</span>
            </div>
          ) : (
            false
          )}
          <hr className="closable" />
          <div className="wishlist-summary-totals">
            <span className="title-left">{this.t("wishlist.total")}:</span>
            <span className="value-right">{total}</span>
          </div>
          {this.isOrderReview() && atdConfirmationNumber.isValid() ? (
            <div className="wishlist-summary-totals">
              <span className="title-left">
                {this.t("wishlist.atdConfirmationNumber")}:
              </span>
              <span className="value-right">
                {atdConfirmationNumber.orderNumber}
              </span>
            </div>
          ) : (
            false
          )}
          {this.isOrderReview() ? (
            <hr className="closable extra-space" />
          ) : (
            false
          )}
          {this.isOrderReview() ? (
            <span className="payment-details">
              {this.t("wishlist.deliveryDetails")}
            </span>
          ) : (
            false
          )}
          {this.isOrderReview() ? this.renderEstimatedDeliveryDetails() : false}
          <hr className="total-price-divider" />
          {!applicationStore.data.useProvincialDealers ? (
            <div className="contact-quick-info no-print">
              <form>
                <div className="inputs">
                  <fieldset className="contact-information">
                    <div className="contact-names-field">
                      <TextField
                        label={this.t("wishlist.form.firstName")}
                        className={setAdobeLaunchClass(
                          "",
                          ADOBELAUNCHER_CLASS.INPUT
                        )}
                        datadtm={setAdobeLaunchDTM(
                          "",
                          ADOBELAUNCHER_DTM.CONTACT.BASE
                        )}
                        id="firstNameQuoteField"
                        required={false}
                      />
                      <TextField
                        label={this.t("wishlist.form.lastName")}
                        className={setAdobeLaunchClass(
                          "",
                          ADOBELAUNCHER_CLASS.INPUT
                        )}
                        datadtm={setAdobeLaunchDTM(
                          "",
                          ADOBELAUNCHER_DTM.CONTACT.BASE
                        )}
                        id="lastNameQuoteField"
                        required={false}
                      />
                    </div>
                    <EmailField
                      label={this.t("wishlist.form.email")}
                      id="userEmailField"
                      ref="userEmailField"
                      className={setAdobeLaunchClass(
                        "",
                        ADOBELAUNCHER_CLASS.INPUT
                      )}
                      datadtm={setAdobeLaunchDTM(
                        "",
                        ADOBELAUNCHER_DTM.CONTACT.BASE
                      )}
                      required={true}
                      specialRequirementField={true}
                      errorInvalidEmail={this.t("validation.email")}
                    />
                    <TelField
                      label={this.t("wishlist.form.phone")}
                      id="telQuoteField"
                      className={setAdobeLaunchClass(
                        "",
                        ADOBELAUNCHER_CLASS.INPUT
                      )}
                      datadtm={setAdobeLaunchDTM(
                        "",
                        ADOBELAUNCHER_DTM.CONTACT.BASE
                      )}
                      required={false}
                    />
                    {this.state.loggedIn ? (
                      <div className="quote-user-notes">
                        <div className="quote-user-notes-label">
                          <p>{this.t("quote.noteFieldLabel")}</p>
                        </div>
                        <div className="quote-user-notes-textarea">
                          <textarea
                            rows="4"
                            cols="35"
                            id="userNoteQuoteField"
                          />
                        </div>
                      </div>
                    ) : (
                      false
                    )}
                  </fieldset>
                </div>
              </form>
            </div>
          ) : (
            false
          )}
          {!applicationStore.data.enableMercedesFeatures ? (
            <div className="wishlist-static-note">
              <p>{this.t("quote.note")}</p>
            </div>
          ) : (
            false
          )}
          {this.renderWishlistButtons()}
        </box>
      );
    } else {
      return false;
    }
  };

  renderWishlistActionsNTDEnabled = (ntdOrderStockEnabled) => {
    if (
      this.state.loggedIn &&
      applicationStore.data.dealerAllowNtdStock &&
      this.state.shoppingContext.isWishlist
    ) {
      return (
        <box className="wishlist-actions no-print">
          {ntdOrderStockEnabled &&
          applicationStore.data.dealerAllowNtdStockOrder ? (
            <div className="wishlist-actions-order">
              {renderNTDButton(this.onOpenNTDOrderModal, "order")}
            </div>
          ) : (
            false
          )}
          <div className="wishlist-actions-title">
            <h2>{this.t("common.otherActions") + ":"}</h2>
          </div>
          {!applicationStore.data.useProvincialDealers ? (
            <div className="contact-quick-info no-print">
              <form>
                <div className="inputs">
                  <fieldset className="contact-information">
                    <div className="contact-names-field">
                      <TextField
                        label={this.t("wishlist.form.firstName")}
                        id="firstNameQuoteField"
                        className={setAdobeLaunchClass(
                          "",
                          ADOBELAUNCHER_CLASS.INPUT
                        )}
                        datadtm={setAdobeLaunchDTM(
                          "",
                          ADOBELAUNCHER_DTM.CONTACT.BASE
                        )}
                        required={false}
                      />
                      <TextField
                        label={this.t("wishlist.form.lastName")}
                        id="lastNameQuoteField"
                        className={setAdobeLaunchClass(
                          "",
                          ADOBELAUNCHER_CLASS.INPUT
                        )}
                        datadtm={setAdobeLaunchDTM(
                          "",
                          ADOBELAUNCHER_DTM.CONTACT.BASE
                        )}
                        required={false}
                      />
                    </div>
                    <EmailField
                      label={this.t("wishlist.form.email")}
                      id="userEmailField"
                      ref="userEmailField"
                      className={setAdobeLaunchClass(
                        "",
                        ADOBELAUNCHER_CLASS.INPUT
                      )}
                      datadtm={setAdobeLaunchDTM(
                        "",
                        ADOBELAUNCHER_DTM.CONTACT.BASE
                      )}
                      required={true}
                      specialRequirementField={true}
                      errorInvalidEmail={this.t("validation.email")}
                    />
                    <TelField
                      label={this.t("wishlist.form.phone")}
                      id="telQuoteField"
                      className={setAdobeLaunchClass(
                        "",
                        ADOBELAUNCHER_CLASS.INPUT
                      )}
                      datadtm={setAdobeLaunchDTM(
                        "",
                        ADOBELAUNCHER_DTM.CONTACT.BASE
                      )}
                      required={false}
                    />
                    {this.state.loggedIn ? (
                      <div className="quote-user-notes">
                        <div className="quote-user-notes-label">
                          <p>{this.t("quote.noteFieldLabel")}</p>
                        </div>
                        <div className="quote-user-notes-textarea">
                          <textarea
                            rows="4"
                            cols="35"
                            id="userNoteQuoteField"
                          />
                        </div>
                      </div>
                    ) : (
                      false
                    )}
                  </fieldset>
                </div>
              </form>
            </div>
          ) : (
            false
          )}
          {this.renderWishlistButtons()}
        </box>
      );
    } else {
      return false;
    }
  };

  renderWishlistButtons = () => {
    if (!applicationStore.data.quoteImmediately) {
      return (
        <div className="wishlist-actions-buttons">
          {!applicationStore.data.useProvincialDealers ? (
            <button
              className={setAdobeLaunchClass(
                "btn",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.CONTACT.BASE)}
              onClick={this.saveWishlist}
            >
              {this.t("wishlist.saveWishlist")}
            </button>
          ) : (
            false
          )}
          <button
            className={setAdobeLaunchClass(
              "btn",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.CONTACT.BASE)}
            onClick={this.emailQuote}
          >
            {this.t("wishlist.emailWishlist")}
          </button>
          <button
            className={setAdobeLaunchClass(
              "btn",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.CONTACT.BASE)}
            onClick={this.onScheduleAppointment}
          >
            {this.isXtimeEnabled()
              ? this.t("xtime.bookAppointment")
              : this.t("wishlist.scheduleAppointment")}
          </button>
          {tokenHasSessionReference() ? (
            <button className="btn" onClick={this.saveSessionReference}>
              {this.t("wishlist.saveSessionReference")}
            </button>
          ) : (
            false
          )}
          {this.state.loggedIn &&
          this.state.orderStatus !== INCOMPLETE_ORDER &&
          !this.isOrderReview() ? (
            <button className="btn" onClick={this.onShowMarkAsComplete}>
              {this.t("wishlist.modal.markAsComplete")}
            </button>
          ) : (
            false
          )}
        </div>
      );
    } else if (!this.state.loggedIn) {
      return (
        <div className="wishlist-actions-buttons">
          <button
            className={setAdobeLaunchClass(
              "btn",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.CONTACT.BASE)}
            onClick={this.callbackRequest}
          >
            {this.t("wishlist.modal.requestCallback")}
          </button>
          <button
            className={setAdobeLaunchClass(
              "btn",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.CONTACT.BASE)}
            onClick={this.bookAppointment}
          >
            {this.t("wishlist.modal.bookAppointment")}
          </button>
          <button
            className={setAdobeLaunchClass(
              "btn",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.CONTACT.BASE)}
            onClick={this.reserveQuote}
          >
            TEST 1{this.t("wishlist.modal.reserveItems")}
          </button>
          <button
            className={setAdobeLaunchClass(
              "btn",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.CONTACT.BASE)}
            onClick={this.compareQuote}
          >
            {this.t("wishlist.modal.titlePriceMatchCustomer")}
          </button>
        </div>
      );
    } else {
      return (
        <div className="wishlist-actions-buttons">
          <button
            className={setAdobeLaunchClass(
              "btn",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.CONTACT.BASE)}
            onClick={this.emailQuote}
          >
            {this.t("wishlist.modal.emailCustomerQuote")}
          </button>
          <button
            className={setAdobeLaunchClass(
              "btn",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.CONTACT.BASE)}
            onClick={this.reserveQuote}
          >
            {this.t("wishlist.modal.reserveItemsCustomer")}
          </button>
          <button
            className={setAdobeLaunchClass(
              "btn",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.CONTACT.BASE)}
            onClick={this.bookAppointment}
          >
            {this.t("wishlist.modal.bookAppointment")}
          </button>
        </div>
      );
    }
  };

  renderWishlistActionsNTDConsumerEnabled = () => {
    if (getSupportConsumerOrder() && !this.removeOptionalFeatures()) {
      var total = money_format(getTotalCost("total"));
      var orderDeposit = money_format(getOrderDeposit());
      var cartItemList =
        this.state.cartItems && this.state.cartItems.get(ProductTypes.TIRES)
          ? this.state.cartItems
              .get(ProductTypes.TIRES)
              .filter(
                (item) =>
                  item.get("supportDealerOrder") ||
                  item.get("supportConsumerOrder")
              )
          : null;
      var translate = this.t,
        language = getAppLanguage();
      var hours = "",
        dealerHourList = applicationStore.data.dealer.get("hours");
      if (applicationStore.data.dealer && dealerHourList) {
        for (var i in dealerHourList) {
          if (i !== "SUN")
            hours +=
              decodeDayAbbrev(i) +
              " " +
              decodeStoreHours(
                dealerHourList[i].open,
                dealerHourList[i].close
              ) +
              ", ";
          else
            hours +=
              decodeDayAbbrev(i) +
              " " +
              decodeStoreHours(dealerHourList[i].open, dealerHourList[i].close);
        }
      }

      return (
        <box className="wishlist-actions foxy-cart-enabled no-print">
          <div className="wishlist-actions-buttons">
            <button
              className="btn foxy-cart-launch"
              onClick={this.onFoxyCartLaunch}
            >
              <i className="fa fa-lock" />
              <span>{this.t("wishlist.proceedToCheckout")}</span>
            </button>
          </div>
          {getSupportConsumerOrder() && !this.state.loggedIn ? (
            <div className="vehicle-info ecommerce">
              <h3 className="delivery-title">
                {this.t("wishlist.deliveryDetails")}
              </h3>
              <span className="delivery-text">
                {this.t("wishlist.deliveryDetailsText")}
              </span>
              <hr className="closable" />
            </div>
          ) : (
            false
          )}
          {applicationStore.data.dealerInfo &&
          !applicationStore.data.useProvincialDealers ? (
            <div className="vehicle-info ecommerce">
              <h3 className="delivery-title">
                {this.t("wishlist.installerDetails")}
              </h3>
              <span className="dealer-address">
                {Immutable.fromJS([
                  applicationStore.data.dealerInfo.name,
                  applicationStore.data.dealerInfo.address,
                  applicationStore.data.dealerInfo.city,
                  applicationStore.data.dealerInfo.province,
                  applicationStore.data.dealerInfo.postalCode,
                  applicationStore.data.dealerInfo.phone,
                  applicationStore.data.dealerInfo.email,
                ])
                  .filter(function (value) {
                    return value;
                  })
                  .toArray()
                  .join(", ")}
              </span>
              <span className="dealer-address">{hours}</span>
            </div>
          ) : (
            false
          )}
          <hr className="closable" />

          {getSupportConsumerOrder() && hasVehicle() ? (
            <div className="vehicle-info ecommerce">
              <h3 className="delivery-title">
                {this.t("wishlist.vehicleDetails")}
              </h3>
              <span className="delivery-text">{getVehicleName()}</span>
              <span className="vehicle-text vehicle-tiresize-disclaimer">
                <span>{this.t("wishlist.vehicleDetailsDisclaimer_0")}</span>
                <span
                  className="vehicle-link"
                  onClick={this.openTireSizeHelperModal}
                >
                  {this.t("wishlist.vehicleDetailsDisclaimer_1")}
                </span>
              </span>
              <hr className="closable" />
            </div>
          ) : (
            false
          )}

          <h2 className="payment-details">
            {this.t("wishlist.paymentDetails")}
          </h2>
          <hr className="closable" />
          <div className="wishlist-summary-totals">
            <h3 className="title-left">{this.t("wishlist.total")}:</h3>
            <span className="value-right">{total}</span>
          </div>
          <div className="wishlist-summary-totals deposit-due">
            <h3 className="title-left">{this.t("wishlist.depositDue")}:</h3>
            <span className="value-right">{orderDeposit}</span>
          </div>
          <hr className="total-price-divider" />

          {cartItemList && sizeOf(cartItemList) > 0 ? (
            <div className="cart-item-list">
              <h3 className="item-list-title">
                {this.t("wishlist.orderDetails")}
              </h3>
              {cartItemList.map(function (cartItem) {
                return (
                  <span>
                    {translate("wishlist.deposit")} {" - "}{" "}
                    {"(" + cartItem.get("quantity").get("value") + ")"}{" "}
                    {cartItem.get("title").get(language)} {" - "}{" "}
                    {cartItem.get("tiresize")}{" "}
                    {translate("common.partnumberSearch")}{" "}
                    {cartItem.get("partNumber")}
                  </span>
                );
              })}
              <hr className="total-price-divider" />
            </div>
          ) : (
            false
          )}

          {sizeOf(getOrderDisclaimerList()) > 0 ? (
            <div className="order-disclaimer-list">
              <h3 className="disclaimer-title">
                {this.t("wishlist.disclaimerTitle")}
              </h3>
              {getOrderDisclaimerList().map(function (disclaimer, index) {
                return (
                  <div key={"disclaimer-" + index} className="disclaimer">
                    <span>{"- " + disclaimer[getAppLanguage()]}</span>
                  </div>
                );
              })}
            </div>
          ) : (
            false
          )}

          {sizeOf(getOrderDisclaimerList()) > 0 ? (
            <hr className="total-price-divider" />
          ) : (
            false
          )}

          <div className="wishlist-actions-buttons">
            <button
              className="btn foxy-cart-launch"
              onClick={this.onFoxyCartLaunch}
            >
              <i className="fa fa-lock" />
              <span>{this.t("wishlist.proceedToCheckout")}</span>
            </button>
          </div>
        </box>
      );
    } else {
      return false;
    }
  };

  renderWishlistActionsNTDConsumerReviewEnabled = () => {
    if (getSupportConsumerOrder() && this.isEcommerce()) {
      var total = money_format(getTotalCost("total"));
      var orderDeposit = money_format(getOrderDeposit());
      var orderDepositDealer = this.state.orderDepositDealer
        ? money_format(this.state.orderDepositDealer)
        : null;
      var orderDepositInternal = this.state.orderDepositInternal
        ? money_format(this.state.orderDepositInternal)
        : null;
      var remainderDue = money_format(this.state.orderDueAtDealer);
      var date = new Date(this.state.shoppingCartViewCreated);
      var dateString = date.toDateString();
      var contactInfoLabel = this.t("wishlist.yourContactInfo");
      if (this.state.loggedIn)
        contactInfoLabel = this.t("wishlist.customerContactInfo");
      if (getAppLanguage() === "fr")
        dateString = convertDateToFrench(dateString);
      var cartItemList =
        this.state.cartItems && this.state.cartItems.get(ProductTypes.TIRES)
          ? this.state.cartItems
              .get(ProductTypes.TIRES)
              .filter(
                (item) =>
                  item.get("supportDealerOrder") ||
                  item.get("supportConsumerOrder")
              )
          : null;
      var translate = this.t,
        language = getAppLanguage();
      var hours = "",
        dealerHourList = applicationStore.data.dealer.get("hours");
      if (applicationStore.data.dealer && dealerHourList) {
        for (var i in dealerHourList) {
          if (i !== "SUN")
            hours +=
              decodeDayAbbrev(i) +
              " " +
              decodeStoreHours(
                dealerHourList[i].open,
                dealerHourList[i].close
              ) +
              ", ";
          else
            hours +=
              decodeDayAbbrev(i) +
              " " +
              decodeStoreHours(dealerHourList[i].open, dealerHourList[i].close);
        }
      }
      return (
        <box className="wishlist-actions foxy-cart-enabled no-print">
          {getSupportConsumerOrder() &&
          !this.state.loggedIn &&
          !this.isCancelledOrder() ? (
            <div className="vehicle-info ecommerce">
              <h3 className="delivery-title">
                {this.t("wishlist.deliveryDetails")}
              </h3>
              <span className="delivery-text">
                {this.t("wishlist.deliveryDetailsText")}
              </span>
              <hr className="closable" />
            </div>
          ) : (
            false
          )}
          {applicationStore.data.dealerInfo &&
          !applicationStore.data.useProvincialDealers ? (
            <div className="vehicle-info ecommerce">
              <h3 className="delivery-title">
                {this.t("wishlist.installerDetails")}
              </h3>
              <span className="dealer-address">
                {Immutable.fromJS([
                  applicationStore.data.dealerInfo.name,
                  applicationStore.data.dealerInfo.address,
                  applicationStore.data.dealerInfo.city,
                  applicationStore.data.dealerInfo.province,
                  applicationStore.data.dealerInfo.postalCode,
                  applicationStore.data.dealerInfo.phone,
                  applicationStore.data.dealerInfo.email,
                ])
                  .filter(function (value) {
                    return value;
                  })
                  .toArray()
                  .join(", ")}
              </span>
              <span className="dealer-address">{hours}</span>
            </div>
          ) : (
            false
          )}
          <hr className="closable" />

          {getSupportConsumerOrder() && hasVehicle() ? (
            <div className="vehicle-info ecommerce">
              <h2 className="vehicle-title">
                {this.t("wishlist.vehicleDetails")}
              </h2>
              <span className="vehicle-text vehicle-name">
                {getVehicleName()}
              </span>
              {!this.isCompleteOrder() ? (
                <span className="vehicle-text vehicle-tiresize-disclaimer">
                  <span>{this.t("wishlist.vehicleDetailsDisclaimer_0")}</span>
                  <span
                    className="vehicle-link"
                    onClick={this.openTireSizeHelperModal}
                  >
                    {this.t("wishlist.vehicleDetailsDisclaimer_1")}
                  </span>
                </span>
              ) : (
                false
              )}
              <hr className="closable" />
            </div>
          ) : (
            false
          )}

          {!this.isCompleteOrder() ? (
            <span className="payment-details">
              {this.t("wishlist.paymentDetails")}
            </span>
          ) : (
            false
          )}
          {!this.isCompleteOrder() ? <hr className="closable" /> : false}

          {!this.isCancelledOrder() && !this.isCompleteOrder() ? (
            <div className="wishlist-summary-totals">
              <span className="title-left">{this.t("wishlist.total")}:</span>
              <span className="value-right">{total}</span>
            </div>
          ) : (
            false
          )}
          {this.isCancelledOrder() && !this.isCompleteOrder() ? (
            <div className="wishlist-summary-totals">
              <span className="title-left">
                {this.t("wishlist.transactionComplete")}
              </span>
            </div>
          ) : (
            false
          )}
          {!this.isCompleteOrder() ? <hr className="closable" /> : false}
          {this.state.userConfigSupportConsumerOrderAdminPrivilege &&
          orderDepositDealer &&
          !this.isCompleteOrder() ? (
            <div className="wishlist-summary-totals deposit-info deposit-breakdown">
              <span className="title-left">
                {this.t("wishlist.customerDeposit")}:
              </span>
              <span className="value-right">{orderDepositDealer}</span>
            </div>
          ) : (
            false
          )}
          {this.state.userConfigSupportConsumerOrderAdminPrivilege &&
          orderDepositInternal &&
          !this.isCompleteOrder() ? (
            <div className="wishlist-summary-totals deposit-info deposit-breakdown">
              <span className="title-left">
                {this.t("wishlist.tireWizardFee")}:
              </span>
              <span className="value-right">{orderDepositInternal}</span>
            </div>
          ) : (
            false
          )}
          {!this.isCompleteOrder() ? (
            <div className="wishlist-summary-totals deposit-info deposit-value">
              <span className="title-left">
                {this.t("wishlist.totalPaid")}:
              </span>
              <span className="value-right">({orderDeposit})</span>
            </div>
          ) : (
            false
          )}
          {!this.isCancelledOrder() && !this.isCompleteOrder() ? (
            <div className="wishlist-summary-totals deposit-info deposit-highlight">
              <span className="title-left">
                {this.t("wishlist.remainderDue")}:
              </span>
              <span className="value-right highlight">{remainderDue}</span>
            </div>
          ) : (
            false
          )}
          {!this.isCompleteOrder() ? <hr className="closable" /> : false}

          {!this.isCancelledOrder() &&
          cartItemList &&
          sizeOf(cartItemList) > 0 ? (
            <div className="cart-item-list">
              <span className="item-list-title">
                {this.t("wishlist.orderDetails")}
              </span>
              {cartItemList.map(function (cartItem) {
                return (
                  <span>
                    {translate("wishlist.deposit")} {" - "}{" "}
                    {"(" + cartItem.get("quantity").get("value") + ")"}{" "}
                    {cartItem.get("title").get(language)} {" - "}{" "}
                    {cartItem.get("tiresize")}{" "}
                    {translate("common.partnumberSearch")}{" "}
                    {cartItem.get("partNumber")}
                  </span>
                );
              })}
              <hr className="total-price-divider" />
            </div>
          ) : (
            false
          )}

          {sizeOf(getOrderDisclaimerList()) > 0 ? (
            <div className="order-disclaimer-list">
              <span className="disclaimer-title">
                {this.t("wishlist.disclaimerTitle")}
              </span>
              {getOrderDisclaimerList().map(function (disclaimer, index) {
                return (
                  <div key={"disclaimer-" + index} className="disclaimer">
                    <span>{"- " + disclaimer[getAppLanguage()]}</span>
                  </div>
                );
              })}
            </div>
          ) : (
            false
          )}

          {sizeOf(getOrderDisclaimerList()) > 0 ? (
            <hr className="closable" />
          ) : (
            false
          )}

          <span className="payment-details">{contactInfoLabel}</span>
          <div className="wishlist-summary-totals contact-info">
            <span className="title-left">{this.t("wishlist.modal.name")}:</span>
            <span className="value-right">
              {shoppingCartViewStore.data.get("customerName")}
            </span>
          </div>
          <div className="wishlist-summary-totals contact-info">
            <span className="title-left">
              {this.t("wishlist.modal.phone")}:
            </span>
            <span className="value-right">
              {shoppingCartViewStore.data.get("customerPhone")}
            </span>
          </div>
          <div className="wishlist-summary-totals contact-info">
            <span className="title-left">
              {this.t("wishlist.modal.email")}:
            </span>
            <span className="value-right">
              {shoppingCartViewStore.data.get("customerEmail")}
            </span>
          </div>
          <div className="wishlist-summary-totals contact-info">
            <span className="title-left">
              {this.t("wishlist.transactionsDate")}:
            </span>
            <span className="value-right">{dateString}</span>
          </div>
          {this.state.userConfigSupportConsumerOrderAdminPrivilege &&
          !this.isCompleteOrder() ? (
            <button
              className="btn schedule-appointment-button"
              onClick={this.toggleScheduleEcommerceAppointment}
            >
              {this.t("wishlist.scheduleEcommerceAppointment")}
            </button>
          ) : (
            false
          )}
          {this.state.userConfigSupportConsumerOrderAdminPrivilege &&
          !this.isCompleteOrder() ? (
            <div>
              <hr className="closable extra-space" />
              <span className="payment-details">
                {this.t("wishlist.deliveryDetails")}
              </span>
              {this.renderDeliveryDetails()}
              {this.isCancelledOrder() ? (
                <div className="delivery-list disclaimer">
                  <span>
                    {this.t("wishlist.orderCancelledDeliveryDisclaimer")}
                  </span>
                </div>
              ) : (
                false
              )}
            </div>
          ) : (
            false
          )}
        </box>
      );
    } else {
      return false;
    }
  };

  toggleScheduleEcommerceAppointment = () => {
    toggleScheduleEcommerceAppointment(this.state, this.updateState);
  };

  renderDeliveryDetails = () => {
    var deliveryDetails = getDeliveryDetails(this.state.orderFulfillmentList);
    var translate = this.t;
    if (deliveryDetails.isValid()) {
      return Immutable.fromJS(deliveryDetails.deliveryList).map(function (
        delivery,
        index
      ) {
        var deliveryDate = new Date(delivery.get("deliveryDate"));
        return (
          <div key={"deivery-" + index} className="delivery-list">
            <span className="title">
              {delivery.get("name")} - {translate("wishlist.quantity") + ":"}{" "}
              {delivery.get("quantity")}
            </span>
            <span>
              {translate("wishlist.sourceTypeDC") + ":"}{" "}
              {delivery.get("sourceTypeDC")}
            </span>
            <span>
              {translate("wishlist.sourceType") + ":"}{" "}
              {delivery.get("sourceType")}
            </span>
            <span>
              {translate("wishlist.status") + ":"} {delivery.get("status")}
            </span>
            <span>
              {translate("wishlist.deliveryDate") + ":"}{" "}
              {deliveryDate.toDateString()}
            </span>
          </div>
        );
      });
    } else {
      return false;
    }
  };

  renderEstimatedDeliveryDetails = () => {
    var deliveryDetails = getEstimatedDeliveryDetails(
      this.state.orderFulfillmentList
    );
    var translate = this.t;
    if (deliveryDetails.isValid()) {
      return Immutable.fromJS(deliveryDetails.deliveryList).map(function (
        delivery,
        index
      ) {
        var deliveryDate = new Date(delivery.get("deliveryDate"));
        return (
          <div key={"deivery-" + index} className="delivery-list">
            <span>
              {translate("wishlist.quantity") + ":"} {delivery.get("quantity")}
            </span>
            <span>
              {translate("wishlist.shipMethod") + ":"}{" "}
              {delivery.get("shipMethod")}
            </span>
            <span>
              {translate("wishlist.freight") + ":"} {delivery.get("freight")}
            </span>
            <span>
              {translate("wishlist.status") + ":"} {delivery.get("status")}
            </span>
            <span>
              {translate("wishlist.deliveryDate") + ":"}{" "}
              {deliveryDate.toDateString()}
            </span>
          </div>
        );
      });
    } else {
      return false;
    }
  };

  renderDepositInfoModal = () => {
    if (this.state.showDepositInfoModal) {
      return (
        <Modal refactor={true} requestUnmount={this.toggleDepositInfoModal}>
          <div className="wrapper">
            <div className="modal-container">
              <span></span>
              <span></span>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  renderOrderStatus = () => {
    if (
      (this.state.loggedIn || this.removeOptionalFeatures()) &&
      !this.isOrderReview()
    ) {
      var orderStatus = this.state.orderStatusDescription,
        orderStatusText = false;
      if (orderStatus && this.isUnscheduledOrder())
        orderStatusText = (
          <span className="order-status-value success">
            {this.t("wishlist.orderStatusSchedule")}
          </span>
        );
      else if (orderStatus && this.isScheduledOrder())
        orderStatusText = (
          <span className="order-status-value success">
            {this.t("wishlist.orderStatusScheduled")}
          </span>
        );
      else if (orderStatus && this.isCancelledOrder())
        orderStatusText = (
          <span className="order-status-value success">
            {this.t("wishlist.orderStatusCancelled")}
          </span>
        );
      else if (orderStatus && this.isCompleteOrder())
        orderStatusText = (
          <span className="order-status-value success">
            {this.t("wishlist.orderStatusComplete")}
          </span>
        );
      else if (orderStatus)
        orderStatusText = (
          <span className="order-status-value">
            {orderStatus.get(getAppLanguage())}
          </span>
        );
      return (
        <div className="wishlist-order-status">
          <span className="order-status-label">
            {this.t("wishlist.orderStatus") + ":"}
          </span>
          {orderStatusText}
        </div>
      );
    } else {
      return false;
    }
  };

  renderCancelOrder = () => {
    if (
      this.isConsumerReview() &&
      !this.isCancelledOrder() &&
      !this.isCompleteOrder()
    ) {
      if (this.state.userConfigSupportDealerOrder) {
        return (
          <div className="wishlist-cancel-order">
            <span>
              {this.t("wishlist.cancelOrderDealer_1")}{" "}
              <a onClick={this.toggleCancelOrderModal}>
                {this.t("wishlist.clickHere")}
              </a>
            </span>
            <span>
              {this.t("wishlist.cancelOrderDealer_2").replace(
                "[]",
                money_format(this.state.orderRestockingFee)
              )}
            </span>
            <span>{this.t("wishlist.cancelOrderDealer_3")}</span>
          </div>
        );
      } else if (this.state.userConfigSupportConsumerOrder) {
        return (
          <div className="wishlist-cancel-order">
            <span>
              {this.t("wishlist.cancelOrder_1")}{" "}
              <a onClick={this.toggleCancelOrderModal}>
                {this.t("wishlist.clickHere")}
              </a>
            </span>
            <span>
              {this.t("wishlist.cancelOrder_2").replace(
                "[]",
                money_format(this.state.orderRestockingFee)
              )}
            </span>
          </div>
        );
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  renderCancelOrderModal = () => {
    if (this.state.cancelOrderModal) {
      return (
        <Modal
          requestUnmount={this.toggleCancelOrderModal}
          applyTemplateStyle={true}
        >
          <div className="wrapper">
            <div className="modal-content modal-cancel-order">
              <span className="cancellation-text">
                {this.t("wishlist.cancelOrderModal_1")
                  .replace("[]", money_format(this.state.orderRestockingFee))
                  .replace("{}", money_format(this.state.orderDeposit))}
              </span>
              <span className="cancellation-text">
                {this.t("wishlist.cancelOrderModal_2").replace(
                  "[]",
                  money_format(
                    parseFloat(this.state.orderDeposit) -
                      parseFloat(this.state.orderRestockingFee)
                  )
                )}
              </span>

              <div className="cancellation-input">
                <span>{this.t("wishlist.cancelOrderReason")}</span>
                <ComboBox
                  onChange={this.onChangeReasonForCancelling}
                  value={this.state.reasonForCancelling}
                >
                  <ComboBoxValue key={0} value={0}>
                    {this.t("wishlist.cancelOrderReason_0")}
                  </ComboBoxValue>
                  <ComboBoxValue key={1} value={1}>
                    {this.t("wishlist.cancelOrderReason_1")}
                  </ComboBoxValue>
                  <ComboBoxValue key={2} value={2}>
                    {this.t("wishlist.cancelOrderReason_2")}
                  </ComboBoxValue>
                </ComboBox>
              </div>

              <button
                className="btn cancel-order-button"
                onClick={this.onCancelEcommerceOrder}
              >
                {this.t("wishlist.cancelOrderButton")}
              </button>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  renderAppointmentWishlistModal = () => {
    if (this.props.showScheduleAppointmentModal) {
      var firstName = "",
        firstNameInput = null,
        lastName = "",
        lastNameInput = null,
        email = "",
        emailInput = null,
        phone = "",
        phoneInput = null;
      firstNameInput = document.getElementById("firstNameQuoteField");
      lastNameInput = document.getElementById("lastNameQuoteField");
      emailInput = document.getElementById("userEmailField");
      phoneInput = document.getElementById("telQuoteField");
      if (firstNameInput && !isEmpty(firstNameInput.value))
        firstName = firstNameInput.value.trim();
      if (lastNameInput && !isEmpty(lastNameInput.value))
        lastName = lastNameInput.value.trim();
      if (emailInput && !isEmpty(emailInput.value))
        email = emailInput.value.trim();
      if (phoneInput && !isEmpty(phoneInput.value))
        phone = phoneInput.value.trim();
      return (
        <WishlistAppointmentModal
          customerFirstName={firstName}
          customerLastName={lastName}
          email={email}
          phone={phone}
        />
      );
    } else {
      return false;
    }
  };

  renderEcommerceAppointmentModal = () => {
    return renderEcommerceAppointmentModal(this.state, this.updateState);
  };

  renderTimeSelectionModal = () => {
    return renderEcommerceTimeSelectionModal(this.state, this.updateState);
  };

  updateAppointmentAmOrPm = (timeCategoryFlag) => {
    var timeDisplay = document.getElementsByClassName("timepicker-info")[0],
      timeCategoryState =
        typeof timeCategoryFlag !== "undefined"
          ? timeCategoryFlag
          : this.state.ecommerceAppointmentTimeAmOrPm;
    if (timeDisplay) {
      while (sizeOf(timeDisplay.children) > 2)
        timeDisplay.removeChild(
          timeDisplay.children[sizeOf(timeDisplay.children) - 1]
        );
      var timeCategory = document.createElement("span");
      timeCategory.className = "timepicker-info-digits time-category";
      timeCategory.innerHTML = timeCategoryState
        ? " " + this.t("wishlist.modal.AM")
        : " " + this.t("wishlist.modal.PM");
      timeDisplay.append(timeCategory);
    }
  };

  closeFoxyCartErrorModal = () => {
    shoppingCartActions.closeFoxyCartErrorModal();
  };

  renderCompleteEcommerceModal = () => {
    return renderCompleteEcommerceModal(
      this.state,
      this.updateState,
      this.refs["workOrderNumber"]
    );
  };

  openTireSizeHelperModal = () => {
    applicationActions.onOpenTireSizeHelper();
  };

  onChangeReasonForCancelling = (reason) => {
    this.setState({
      reasonForCancelling: reason,
    });
  };

  toggleCancelOrderModal = () => {
    this.setState({
      cancelOrderModal: !this.state.cancelOrderModal,
    });
  };

  onCancelEcommerceOrder = () => {
    this.toggleCancelOrderModal();
    var reasonForCancelling = "";
    switch (this.state.reasonForCancelling) {
      case 0:
        reasonForCancelling = this.t("wishlist.cancelOrderReason_0");
        break;
      case 1:
        reasonForCancelling = this.t("wishlist.cancelOrderReason_1");
        break;
      case 2:
        reasonForCancelling = this.t("wishlist.cancelOrderReason_2");
        break;
      case 3:
        reasonForCancelling = this.t("wishlist.cancelOrderReason_3");
        break;
    }
    shoppingCartActions.cancelConsumerEcommerce(reasonForCancelling);
  };

  toggleDepositInfoModal = () => {
    this.setState({
      showDepositInfoModal: !this.state.showDepositInfoModal,
    });
  };

  onFoxyCartLaunch = () => {
    setTimeout(() => {
      shoppingCartActions.loadConsumerOrder();
    }, 10);
  };

  onOpenNTDOrderModal = () => {
    applicationActions.onOpenNtdModal();
  };

  saveSessionReference = () => {
    var sessionReference = tokenHasSessionReference();
    shoppingCartActions.saveSessionReference(sessionReference);
  };

  onScheduleAppointment = () => {
    if (this.isXtimeEnabled()) this.onBookXtimeAppointment();
    else this.props.dispatch(requestModalToggle(true));
  };

  onShowMarkAsComplete = () => {
    openMarkAsComplete();
    this.props.dispatch(markAsCompleteModal(true));

    /*
    this.setState({
      showMarkAsComplete: true
    });
    */
  };

  onHideMarkAsComplete = () => {
    this.props.dispatch(markAsCompleteModal(false));
    /*
    this.setState({
      showMarkAsComplete: false
    });
    */
  };

  onMarkAsComplete = () => {
    shoppingCartActions.markOrderStatusAsComplete(this.state.cartId);
  };

  renderMarkAsCompleteModal = () => {
    if (this.state.showMarkAsComplete) {
      return (
        <Modal
          refactor={true}
          useShortHeaderBar={true}
          requestUnmount={this.onHideMarkAsComplete}
        >
          <div className="wrapper">
            <div className="modal-content">
              <span className="title">
                {this.t("wishlist.markAsCompleteQuestion")}
              </span>
              <span>{this.t("wishlist.cannotUndo")}</span>
              <div className="button-container">
                <button
                  className="btn new-quote"
                  ref="btnNewQuote"
                  tabIndex="1"
                  aria-label={this.t("startNewQuote")}
                  onClick={this.onMarkAsComplete}
                >
                  {this.t("wishlist.markAsComplete")}
                </button>
                <button
                  className="btn close"
                  tabIndex="1"
                  aria-label={this.t("common.close")}
                  onClick={this.onHideMarkAsComplete}
                >
                  {this.t("common.close")}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  onStartNewQuoteFromModal = () => {
    this.props.dispatch(toggleShoppingModal(false));
    // shoppingCartActions.closeModal();
    applicationActions.openStartNewQuoteModal();
  };

  renderScheduleWishlistModal = () => {
    if (this.state.showScheduleWishlistModal) {
      return <WishlistDealerSelectionModal />;
    } else {
      return false;
    }
  };

  renderEmailWishlistModal = () => {
    if (this.state.showEmailWishlistModal) {
      return <WishlistEmailSimpleModal />;
    } else {
      return false;
    }
  };

  removeOptionalFeatures = () => {
    return (
      this.state.shoppingContext.isEcommerce ||
      this.state.shoppingContext.isOrder
    );
  };

  isCartReview = () => {
    return (
      this.state.userConfigSupportDealerOrder ||
      this.state.userConfigSupportConsumerOrder
    );
  };

  isEcommerce = () => {
    return this.state.shoppingContext.isEcommerce;
  };

  isOrder = () => {
    return this.state.shoppingContext.isOrder;
  };

  isConsumerComplete = () => {
    return (
      this.isEcommerce() && this.state.shoppingContext.context === "complete"
    );
  };

  isConsumerReview = () => {
    return (
      this.isEcommerce() && this.state.shoppingContext.context === "review"
    );
  };

  isOrderReview = () => {
    return this.isOrder() && this.state.shoppingContext.context === "review";
  };

  isScheduledOrder = () => {
    return this.state.orderStatus === "SCH";
  };

  isUnscheduledOrder = () => {
    return this.state.orderStatus === "USC";
  };

  isCancelledOrder = () => {
    return (
      this.state.orderStatus === "CRQ" ||
      this.state.orderStatus === "CNR" ||
      this.state.orderStatus === "CFR" ||
      this.state.orderStatus === "FFL"
    );
  };

  isCompleteOrder = () => {
    return this.state.orderStatus === "DLD";
  };

  isXtimeEnabled = () => {
    return (
      this.checkDealerXtimeEnabled() &&
      (this.state.loggedIn ||
        (!this.state.loggedIn &&
          this.state.shoppingContext.isWishlist &&
          !this.state.supportConsumerOrder))
    );
  };

  checkDealerXtimeEnabled = () => {
    if (this.state.loggedIn) {
      return this.state.dealerEnableXtimeAppointmentIntegration !== "HIDE";
    } else {
      return this.state.dealerEnableXtimeAppointmentIntegration === "SHOW";
    }
  };

  renderEmailAppointmentSentModal = () => {
    if (!this.state.showEmailWishlistModal && this.state.showModal) {
      var message = "";
      if (this.state.saveWishlistSuccess)
        message = this.t("wishlist.modal.wishlistSaved");
      else if (this.state.emailWishlistModalEmailSent)
        message = this.t("wishlist.modal.emailSent");
      else if (this.state.emailWishlistModalEmailFailed)
        message = this.t("wishlist.modal.emailFailed");
      else message = this.t("wishlist.modal.wishlistSaveFailed");
      return (
        <Modal
          refactor={true}
          useShortHeaderBar={true}
          requestUnmount={this.onCloseModal}
        >
          <div className="wrapper">
            <div className="modal-content">
              <span>{message}</span>
              <div className="button-container">
                <button
                  className="btn new-quote"
                  ref="btnNewQuote"
                  tabIndex="1"
                  aria-label={this.t("startNewQuote")}
                  onClick={this.onStartNewQuoteFromModal}
                >
                  {this.t("startNewQuote")}
                </button>
                <button
                  className="btn close"
                  tabIndex="1"
                  aria-label={this.t("common.close")}
                  onClick={this.onCloseModal}
                >
                  {this.t("common.close")}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  onCloseModal = () => {
    this.props.dispatch(false);
    // shoppingCartActions.closeModal();
  };

  renderSessionReferenceModal = () => {
    if (this.state.showSessionReferenceModal) {
      var message = "";
      if (this.state.saveSessionReferenceSent) {
        message = this.t("wishlist.modal.sessionReferenceSaved");
      } else if (this.state.saveSessionReferenceFailed) {
        message = this.t("wishlist.modal.sessionReferenceFailure");
      }
      return (
        <Modal
          refactor={true}
          useShortHeaderBar={true}
          requestUnmount={this.onCloseSessionReferenceModal}
        >
          <div className="wrapper">
            <div className="modal-content">
              <span>{message}</span>
              <div className="button-container">
                <button
                  className="btn new-quote"
                  onClick={this.onCloseSessionReferenceModal}
                >
                  {this.t("common.close")}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  onCloseSessionReferenceModal = () => {
    shoppingCartActions.closeSessionReferenceModal();
  };

  onChange = () => {
    this.setState({
      cartItems: this.props.shoppingCartItems,
      shoppingCartViewCreated: this.props.shoppingCartInformation.get(
        "shoppingCartViewCreated"
      ),
      appointmentRequestDate: this.props.shoppingCartInformation.get(
        "appointmentRequestDate"
      ),
      appointmentRequestTime: this.props.shoppingCartInformation.get(
        "appointmentRequestTime"
      ),
      subtotal: this.props.shoppingCartInformation.get("subtotal"),
      tax: this.props.shoppingCartInformation.get("tax"),
      total: this.props.shoppingCartInformation.get("total"),
      taxtotal: this.props.shoppingCartInformation.get("taxtotal"),
      orderRestockingFee:
        this.props.shoppingCartInformation.get("orderRestockingFee"),
      orderDueAtDealer:
        this.props.shoppingCartInformation.get("orderDueAtDealer"),
      numberOfPromotions:
        this.props.shoppingCartInformation.get("numberOfPromotions"),
      totalSavings: this.props.shoppingCartInformation.get("totalSavings"),
      totalMSRPSavings:
        this.props.shoppingCartInformation.get("totalMSRPSavings"),
      firstNonNullPromotion: this.props.shoppingCartInformation.get(
        "firstNonNullPromotion"
      ),
      shoppingCartViewNotes: this.props.shoppingCartInformation.get(
        "shoppingCartViewNotes"
      ),
      orderStatusDescription: this.props.shoppingCartInformation.get(
        "orderStatusDescription"
      ),
      vehicleInformation:
        this.props.shoppingCartInformation.get("vehicleInformation"),

      cartId: applicationStore.data.cart,
      orderId: this.props.shoppingCartInformation.get("orderId"),
      orderNumber: this.props.shoppingCartInformation.get("orderGUID"),
      orderStatus: this.props.shoppingCartInformation.get("orderStatus"),
      orderFulfillmentList: this.props.shoppingCartInformation.get(
        "orderFulfillmentList"
      ),

      showScheduleWishlistModal: shoppingCartStore.data.get(
        "scheduleWishlistModal"
      ),
      showEmailWishlistModal: shoppingCartStore.data.get("emailWishlistModal"),
      showScheduleAppointmentModal: shoppingCartStore.data.get(
        "scheduleAppointmentWishlistModal"
      ),
      saveWishlistSuccess: shoppingCartStore.data.get("saveWishlistSuccess"),
      emailWishlistModalEmailSent: shoppingCartStore.data.get(
        "emailWishlistModalEmailSent"
      ),
      emailWishlistModalEmailFailed: shoppingCartStore.data.get(
        "emailWishlistModalEmailFailed"
      ),
      showModal: shoppingCartStore.data.get("showModal"),

      showSessionReferenceModal: shoppingCartStore.data.get(
        "saveSessionReferenceShowModal"
      ),
      saveSessionReferenceSent: shoppingCartStore.data.get(
        "saveSessionReferenceSent"
      ),
      saveSessionReferenceFailed: shoppingCartStore.data.get(
        "saveSessionReferenceFailed"
      ),

      orderDeposit: this.props.shoppingCartInformation.get("orderDeposit"),
      orderDepositDealer:
        this.props.shoppingCartInformation.get("orderDepositDealer"),
      orderDepositInternal: this.props.shoppingCartInformation.get(
        "orderDepositInternal"
      ),
      orderDisclaimerList: this.props.shoppingCartInformation.get(
        "orderDisclaimerList"
      ),

      banner: applicationStore.data.wishlistBanner,

      emailSent: this.props.shoppingCartInformation.get("emailSent"),
      appointmentSent:
        this.props.shoppingCartInformation.get("appointmentSent"),

      foxyCart: this.props.shoppingCartInformation.get("foxyCart"),
      foxyCartError: this.props.shoppingCartInformation.get("foxyCartError"),

      userConfigSupportDealerOrder: applicationStore.data.supportDealerOrder,
      userConfigSupportDealerOrderAdminPrivilege:
        applicationStore.data.supportUserDealerOrder,
      userConfigSupportConsumerOrder:
        applicationStore.data.supportConsumerOrder,
      userConfigSupportConsumerOrderAdminPrivilege:
        applicationStore.data.supportUserConsumerOrder,

      loggedIn: isUserLoggedIn(),

      supportDealerOrder:
        this.props.shoppingCartInformation.get("supportDealerOrder"),
      supportConsumerOrder: this.props.shoppingCartInformation.get(
        "supportConsumerOrder"
      ),

      shoppingContext: Router.collectShoppingContext(),

      dealerEnableXtimeAppointmentIntegration:
        applicationStore.data.dealerEnableXtimeAppointmentIntegration,
      dealerEnablePromptToOrderTires:
        applicationStore.data.dealerEnablePromptToOrderTires,
    });
  };
}

function mapStateToProps(state) {
  // we are passing new Map to each, the .get function is lost when we pass the state directly
  return {
    cart: state.shoppingCart.cartInformation,
    shoppingCartItems: new Map(state.shoppingCart.cartItems),
    shoppingCartInformation: new Map(
      state.shoppingCart.shoppingCartInformation
    ),
    showScheduleAppointmentModal: state.shoppingCart.requestModalAppointment,
    renderSavingToggle: state.productList.renderSavingToggle,
    // shoppingCartViewCreated:new Map(state.shoppingCart.shoppingCartViewCreated),
    // appointmentRequestDate:new Map(state.shoppingCart.appointmentRequestDate),
    // appointmentRequestTime:new Map(state.shoppingCart.appointmentRequestTime),
    // subtotal:new Map(state.shoppingCart.subtotal),
    // total:new Map(state.shoppingCart.total),
  };
}
export default connect(mapStateToProps, null)(WishlistReview);

import { DateUtilities } from "../calendar/DateUtilities.jsx";
import { Week } from "../calendar/Week.jsx";

var React = require("react");

export class Weeks extends React.Component {
  componentWillMount() {
    if (this.refs && this.refs.current) {
      setTimeout(() => {
        this.refs.current.addEventListener(
          "transitionend",
          this.onTransitionEnd
        );
      }, 500);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.view !== null && typeof nextProps.view !== "undefined") {
      this.setState({
        view: DateUtilities.clone(nextProps.view),
        selected: DateUtilities.clone(nextProps.selected),
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props !== nextProps || this.state !== nextState;
  }

  onTransitionEnd = () => {
    this.setState({
      sliding: null,
      view: DateUtilities.clone(this.state.other),
    });

    this.props.onTransitionEnd();
  };

  getWeekStartDates = (view) => {
    if (view) {
      view.setDate(1);
      view = DateUtilities.moveToDayOfWeek(DateUtilities.clone(view), 0);
    } else {
      view = new Date();
    }

    var current = DateUtilities.clone(view);
    current.setDate(current.getDate() + 7);

    var starts = [view],
      month = current.getMonth();

    while (current.getMonth() === month) {
      starts.push(DateUtilities.clone(current));
      current.setDate(current.getDate() + 7);
    }

    return starts;
  };

  moveTo = (view, isForward) => {
    this.setState({
      sliding: isForward ? "left" : "right",
      other: DateUtilities.clone(view),
    });
  };

  onSelectDay = (day) => {
    this.setState({
      selected: day,
    });
  };

  state = {
    selected: DateUtilities.clone(this.props.selected),
    view: DateUtilities.clone(this.props.view),
    other: DateUtilities.clone(this.props.view),
    sliding: null,
  };

  render() {
    //{ className: setAdobeLaunchClass("weeks", ADOBELAUNCHER_CLASS.BUTTON.LINK), 'data-dtm': setAdobeLaunchDTM('', ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.DATE) }
    return React.createElement(
      "div",
      { className: "weeks" },
      React.createElement(
        "div",
        {
          ref: "current",
          className:
            "current" +
            (this.state.sliding ? " sliding " + this.state.sliding : ""),
        },
        this.renderWeeks(this.state.view)
      ),
      React.createElement(
        "div",
        {
          ref: "other",
          className:
            "other" +
            (this.state.sliding ? " sliding " + this.state.sliding : ""),
        },
        this.renderWeeks(this.state.other)
      )
    );
  }

  renderWeeks = (view) => {
    if (view) {
      var starts = this.getWeekStartDates(view),
        month = starts[1].getMonth();
      return starts.map(
        function (s, i) {
          return React.createElement(Week, {
            key: i,
            start: s,
            month: month,
            calendarProps: this.props.calendarProps,
            isDateAvailable: this.props.isDateAvailable,
            selected: this.state.selected,
            onSelect: this.props.onSelect,
            onSelectDay: this.onSelectDay,
            minDate: this.props.minDate,
            maxDate: this.props.maxDate,
          });
        }.bind(this)
      );
    } else {
      return false;
    }
  };
}

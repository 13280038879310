import { createSlice } from "@reduxjs/toolkit";



const breadCrumbs = createSlice({
    name:'breadCrumbs',
    initialState:{
        state: null,
        id: null,
        value: null,
        unclickable: null,
        translatable: null,
        year:null,
        make:null,
        category:null
    },
    reducers:{
        pushState:(state,{payload})=>{
            state.state = payload.state;
            state.id = payload.id;
            state.value = payload.value;
            state.unclickable = playload.unclickable;
            state.translatable = payload.translatable;
        },
        loadBreadCrumbs:(state,{payload}) => {
            state.year = payload.year;
            state.make = payload.make;
            state.category = payload.category;
            state.model = payload.model;
            state.trim = payload.trim;
        }
    }
})

export const {
    pushState,
    loadBreadCrumbs
} = breadCrumbs.actions;
export default breadCrumbs.reducer
module.exports = {
	'product': {
		'winterSizesDesc': 'Best options when you replace your tire with one that came with your vehicle',
		'originalEqSizesDesc': ''
	},
	'launcher': {
		defaultDisclaimer: "You are leaving <a href='mycertifiedservice.ca'>mycertifiedservice.ca</a> to visit a website that is operated independently and not maintained by General Motors of Canada and where the General Motors of Canada privacy policy does not apply. This link is provided to you for convenience and does not serve as an endorsement by General Motors of Canada of information or content that you may find on this site.",
		disclaimer: "Certified Service",
		disclaimer1: "You are leaving <a href='http://mycertifiedservice.ca' target='_blank'>mycertifiedservice.ca</a>",
		disclaimer2: "You are leaving <a href='http://mycertifiedservice.ca' target='_blank'>mycertifiedservice.ca</a> to visit a website that is operated independently and not maintained by General Motors of Canada and where the General Motors of Canada privacy policy does not apply.",
		disclaimer3: "This link is provided to you for convenience and does not serve as an endorsement by General Motors of Canada of information or content that you may find on this site."
	}
};
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApiBaseUrl } from "../../api/ApiService";


function getDetailUrl(productType) {
    switch (productType) {
      case ProductTypes.TIRES:
        return '/parts/tire/getTireByPartId.php';
      case ProductTypes.ALLOY_WHEELS:
        return '/parts/wheel/getWheelByPartId.php';
      case ProductTypes.ACCESSORIES:
        return '/parts/accessory/getAccessoryByPartId.php';
      case ProductTypes.PACKAGES:
        return '/parts/partPackage/getPartPackageByPartId.php';
      default:
        throw new Error();
    }
  }

export const loadDetail = createAsyncThunk(
    'productDetail/loadDetail',
    async(arg,{getState}) =>{
        let {tokenId} = getState().info;
        let {productType} = getState().productType;
        return fetch(`${getDetailUrl(productType)}/retailSiteWebService/`,{
            
        })
    }
)

const productDetail = createSlice({
    name:'productDetail',
    initialState:{
        openDetail:{
            id:null,
            productType:null,
            secondaryProductId:null
        },
        loadDetail:{

        },
        cartDetailTireQuantity:null,
        cartDetailWheelQuantity:null,
        cartDetailQuantity:null,
        itemQuantity:{
          productIndex:null,
          quantity:null
        }
    },
    reducers:{
        openDetail:(state,{payload})=>{
            state.openDetail.id = payload.id;
            state.openDetail.productType = payload.productType;
            state.openDetail.secondaryProductId = payload.secondaryProductId;

        },
        changeCartDetailTireQuantity:(state,{payload})=>{
          state.cartDetailTireQuantity = payload;

        },
        changeCartDetailWheelQuantity:(state,{payload})=>{
          state.cartDetailWheelQuantity = payload
        },
        changeCartDetailQuantity:(state,{payload})=>{
          state.cartDetailQuantity = payload;
        },
        changeRequiredItemQuantity:(state,{payload})=>{
          state.itemQuantity.productIndex = payload.productIndex;
          state.quantity = payload.quantity;
        }
    },
    extraReducers:{
      [loadDetail.pending]:(state,{payload})=>{
        state.status = 'pending';
      },
      [loadDetail.fulfilled]:(state,{payload})=>{
        state.status = 'fulfilled';
      },
      [loadDetail.rejected]:(state,{payload})=>{
        state.status = 'rejected';
      }
    }
});

export const { 
    openDetail,
    changeCartDetailTireQuantity,
    changeCartDetailWheelQuantity,
    changeCartDetailQuantity,
    changeRequiredItemQuantity
}  = productDetail.actions;

export default productDetail.reducer;
import { connect } from "react-redux";
import { dealerSelectionActions } from "../../actions/DealerSelectionActions.js";
import {
  mapCenterChanged,
  mapZoomChanged,
  mapBoundsChanged,
} from "../../redux/features/dealerSections.js";
import { applicationStore } from "../../stores/ApplicationStore.js";
import store from "../../stores/configureStore.js";

var React = require("react");
var _ = require("lodash");
var $ = require("jquery");
var ReactGoogleMaps = require("react-googlemaps");
var GoogleMap = ReactGoogleMaps.Map;
var Marker = ReactGoogleMaps.Marker;

var debouncedBounceChanged = _.debounce(function (bounds) {
  store.dispatch(mapBoundsChanged(bounds));
  // dealerSelectionActions.mapBoundsChanged(bounds);
}, 300);

class DealerMapLegacy extends React.Component {
  render() {
    return (
      <GoogleMap
        zoom={this.props.zoom}
        center={this.props.center}
        width={this.props.width}
        height={this.props.height}
        onZoomChange={this.onMapZoomChange}
        onCenterChange={this.onMapCenterChange}
        onBoundsChange={this.onBoundsChange}
        panControl={true}
        zoomControl={true}
        mapTypeControl={true}
        scaleControl={true}
        streetViewControl={true}
        overviewMapControl={true}
        rotateControl={true}
      >
        {this.props.dealers
          ? this.props.dealers
              .map(
                function (dealer, key) {
                  return (
                    <Marker
                      key={key}
                      title={dealer.get("name")}
                      icon={this.getMarkerIcon(dealer)}
                      position={dealer.get("position")}
                      onClick={this.onDealerClicked.bind(this, dealer)}
                    />
                  );
                }.bind(this)
              )
              .toArray()
          : false}

        {this.props.userLocation ? (
          <Marker
            key={"user"}
            position={this.props.userLocation}
            icon={this.getUserMarkerIcon()}
          ></Marker>
        ) : (
          ""
        )}
      </GoogleMap>
    );
  }

  getMarkerIcon = (dealer) => {
    switch (applicationStore.data.defaultTemplate) {
      case "lexus":
      case "mercedes":
      case "mrlube":
      case "toyota":
        if (dealer == this.props.activeDealer) {
          return (
            "/static/img/" +
            applicationStore.data.defaultTemplate +
            "/marker_green.png"
          );
        } else {
          return (
            "/static/img/" +
            applicationStore.data.defaultTemplate +
            "/marker_blue.png"
          );
        }
      case "gmcanada":
        if (dealer == this.props.activeDealer) {
          return (
            "/static/img/" +
            applicationStore.data.defaultTemplate +
            "/marker_blue.png"
          );
        } else {
          return (
            "/static/img/" +
            applicationStore.data.defaultTemplate +
            "/marker_green.png"
          );
        }
      default:
        if (dealer == this.props.activeDealer) {
          return "/static/img/marker_green.png";
        } else {
          return "/static/img/marker_blue.png";
        }
    }
  };

  getUserMarkerIcon = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "lexus":
        return (
          "/static/img/" +
          applicationStore.data.defaultTemplate +
          "/marker_user.png"
        );
      default:
        return "/static/img/marker_user.png";
    }
  };

  onDealerClicked = (dealer) => {
    if (window.pageYOffset > 175)
      $(function () {
        $("body").animate({ scrollTop: 0 }, "slow");
      });
    dealerSelectionActions.clickedOnDealer(dealer);
  };

  onMapCenterChange = (map) => {
    this.props.dispatch(mapCenterChanged(map.getCenter()));
    // dealerSelectionActions.mapCenterChanged(map.getCenter());
  };

  onMapZoomChange = (map) => {
    var zoom = map.getZoom();
    if (zoom >= 3) this.props.dispatch(mapZoomChanged(zoom));
  };

  onBoundsChange = (map) => {
    debouncedBounceChanged(map.getBounds());
  };
}

export default connect(null)(DealerMapLegacy);

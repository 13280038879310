import { applicationStore } from '../stores/ApplicationStore.js';
import { productListStore } from '../stores/ProductListStore.js';

import { applicationActions } from '../actions/ApplicationActions.js';

import { isEmpty, sizeOf, getTabIndex, generateGUID, getRequireCaptcha } from './UtilityService';

var React = require('react');

export const captchaOnComponentDidMount = (isProductList, isSearchList, loadFunc, loadCondition) => {
  setTimeout(() => {
    if (getRequireCaptcha()) {
      if (loadCondition) setTimeout(() => { applicationActions.loadRecaptchaModal(loadFunc); }, 100);
    } else {
      setTimeout(() => { loadFunc(); }, 100);
    }
  }, 1500);
};

export const guardRequiresTokenEvent = (callback) => {
  if (getRequireCaptcha()) {
    setTimeout(() => { applicationActions.loadRecaptchaModal(callback); }, 100);
  } else {
    callback();
  }
};

export const getCaptchaObjectId = (numberId, isSeasonFilter = false, isTiresizeFilter = false, optionalId = '') => {
  var id = "recaptcha-";
  if (applicationStore.data.productType) id = id + applicationStore.data.productType.toLowerCase() + "-";
  if (productListStore.data.searchType) id = id + productListStore.data.searchType.toLowerCase() + "-";
  if (isSeasonFilter) id = id + "season-";
  if (isTiresizeFilter) id = id + "tiresize-";
  if (!isEmpty(optionalId)) id = id + optionalId + "-";
  if (numberId) id = id + numberId;
  return id;
};

export const inivisCaptchaOnComponentDidUpdate = (state, updateState, callbackList, overwriteValidation = false) => {

  // id: captcha-button
  // className: captcha-btn

  var captchaIdList = [];
  if (typeof(state['captchaIdList']) === 'undefined') {

    for (var j in callbackList) {
      captchaIdList.push(generateGUID(true));
    }

    updateState({ captchaIdList: captchaIdList });

  } else {

    for (var i in callbackList) {

      i = parseInt(i);

      var callback = callbackList[i];

      captchaIdList = state.captchaIdList;

      if (callback) {

        var stateIndex = "captchaLoaded_" + parseInt(i + 1);
        var captchaId = captchaIdList[i];
        var elementId = captchaId + "-captcha-button";
        var elementClass = captchaId + "-captcha-btn";
        var element = document.getElementById(elementId);

        if (element) {

          if (typeof(state[stateIndex]) === 'undefined') {
            updateState({[stateIndex]: false});
          } else if (getRequireCaptcha() && !state[stateIndex]) {
            updateState({[stateIndex]: true});
            onload(elementId, elementClass, callback, overwriteValidation);
          }
        }

      }

    }

  }

};

export const buildCaptchaButton = (state, index, classList, buttonText, testId = '', datadtm = '') => {
  var captchaIdList = state.captchaIdList;
  if (typeof(captchaIdList) === 'object' && sizeOf(captchaIdList) > 0) {

    var captchaId = captchaIdList[index - 1];
    var elementId = captchaId + "-captcha-button";
    var elementClass = captchaId + "-captcha-btn";

    if (!classList.endsWith(' ')) {
      classList = classList + ' ';
    }

    return (
      <button id={elementId} className={classList + elementClass} data-dtm={datadtm} tabIndex={getTabIndex()}>{buttonText}</button>
    );

  } else {
    return false;
  }
};
import { applicationActions } from "../../actions/ApplicationActions.js";
import { ProductTypes } from "../../constants/ProductTypes.js";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { Image } from "../ux/Image.jsx";

import { getTabIndex } from "../../service/UtilityService";
import { inivisCaptchaOnComponentDidUpdate } from "../../service/CaptchaService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var _ = require("lodash");

export class ProductTypeSelectorRefactored extends I18nComponent {
  state = {
    productTypes: applicationStore.data.productTypes,
  };

  updateState = (state) => {
    this.setState(state);
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
  }

  componentDidUpdate(nextProps, lastState) {
    var callbackList = [];
    for (var i in this.state.productTypes) {
      switch (this.state.productTypes[i]) {
        case ProductTypes.TIRES:
          callbackList.push(this.selectTires);
          break;
        case ProductTypes.ALLOY_WHEELS:
          callbackList.push(this.selectAlloyWheels);
          break;
        case ProductTypes.ACCESSORIES:
          callbackList.push(this.selectAccessories);
          break;
        case ProductTypes.PACKAGES:
          callbackList.push(this.selectPackages);
          break;
      }
    }

    inivisCaptchaOnComponentDidUpdate(
      this.state,
      this.updateState,
      callbackList
    );
  }

  render() {
    var packagesContent = false,
      indexOffset = 0;
    if (_.contains(this.state.productTypes, ProductTypes.PACKAGES)) {
      packagesContent = (
        <li
          role="button"
          tabIndex={getTabIndex()}
          className="product-type product-type-even show-focus"
          key={"homepage-select-" + ProductTypes.PACKAGES}
          onClick={this.selectPackages}
        >
          <div className="product-type-title">
            <h1>{this.t("product.package")}</h1>
          </div>
          <div className="product-type-image">
            <Image
              src={
                "/static/img/" +
                applicationStore.data.defaultTemplate +
                "/packages.jpg"
              }
              alt="Image Select Packages"
            />
          </div>
          <div className="product-type-btn">
            <button className="btn">{this.t("searchText")}</button>
          </div>
          <div className="product-type-tag">
            <p>{this.t("product.packageTag")}</p>
          </div>
        </li>
      );
    }

    var content = _.map(
      this.state.productTypes,
      function (productType, index) {
        var tireClassName = "product-type product-type-even";
        if (_.contains(this.state.productTypes, ProductTypes.PACKAGES)) {
          tireClassName = "product-type product-type-odd";
        }
        switch (productType) {
          case ProductTypes.TIRES:
            return (
              <li
                role="button"
                type="button"
                tabIndex={getTabIndex()}
                className={tireClassName + " show-focus"}
                key={"homepage-select-" + productType}
                onClick={this.selectTires}
              >
                <div className="product-type-title">
                  <h1>{this.t("product.tire")}</h1>
                </div>
                <div className="product-type-image">
                  <Image
                    src={
                      "/static/img/" +
                      applicationStore.data.defaultTemplate +
                      "/tires.jpg"
                    }
                    alt="Image Select Tires"
                  />
                </div>
                <div className="product-type-btn">
                  <button className="btn">{this.t("searchText")}</button>
                </div>
                <div className="product-type-tag">
                  <p>{this.t("product.tireTag")}</p>
                </div>
              </li>
            );

          case ProductTypes.ALLOY_WHEELS:
            return (
              <li
                role="button"
                tabIndex={getTabIndex()}
                className="product-type show-focus"
                key={"homepage-select-" + productType}
                onClick={this.selectAlloyWheels}
              >
                <div className="product-type-title">
                  <h1>{this.t("product.wheel")}</h1>
                </div>
                <div className="product-type-image">
                  <Image
                    src={
                      "/static/img/" +
                      applicationStore.data.defaultTemplate +
                      "/wheels.jpg"
                    }
                    alt="Image Select Wheels"
                  />
                </div>
                <div className="product-type-btn">
                  <button className="btn">{this.t("searchText")}</button>
                </div>
                <div className="product-type-tag">
                  <p>{this.t("product.wheelTag")}</p>
                </div>
              </li>
            );

          case ProductTypes.ACCESSORIES:
            return (
              <li
                role="button"
                tabIndex={getTabIndex()}
                className="product-type show-focus"
                key={"homepage-select-" + productType}
                onClick={this.selectAccessories}
              >
                <div className="product-type-title">
                  <h1>{this.t("product.accessory")}</h1>
                </div>
                <div className="product-type-image">
                  <Image
                    src={
                      "/static/img/" +
                      applicationStore.data.defaultTemplate +
                      "/accessories.jpg"
                    }
                    alt="Image Select Accessories"
                  />
                </div>
                <div className="product-type-btn">
                  <button className="btn">{this.t("searchText")}</button>
                </div>
                <div className="product-type-tag">
                  <p>{this.t("product.accessoryTag")}</p>
                </div>
              </li>
            );
        }
      },
      this
    );

    var productTypeSelectorRefactored = (
      <div className="select-product-type">
        <ul>
          {packagesContent}
          {content}
        </ul>
      </div>
    );

    return productTypeSelectorRefactored;
  }

  onChange = () => {
    this.setState({
      productTypes: applicationStore.data.productTypes,
    });
  };

  selectTires = (token) => {
    applicationActions.activateSearchFlag();
  };

  selectAlloyWheels = (token) => {
    applicationActions.activateSearchFlag();
  };

  selectAccessories = (token) => {
    applicationActions.activateSearchFlag();
  };

  selectPackages = (token) => {
    applicationActions.activateSearchFlag();
  };
}

import { applicationStore } from "../../stores/ApplicationStore.js";
import { searchActions } from "../../actions/SearchActions.js";

import { SelectBox } from "../ux/SelectBox.jsx";

import {
  isEmpty,
  getTrackingEventCategory,
} from "../../service/UtilityService";
import { renderCarousel } from "../../service/RenderService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var Cx = require("classnames");

const SEASON_DEFAULT = null;
const DISTANCE_DEFAULT = 10;

export class ConsumerHomepage extends I18nComponent {
  state = {
    carousel: applicationStore.data.carousel,
    carouselItems: applicationStore.data.carouselItems,
    featuredItems: applicationStore.data.featuredItems,

    tiresize: "",
    season: SEASON_DEFAULT,
    distance: DISTANCE_DEFAULT,
    position: { lat: null, lng: null },
    postal: "",

    run: false,
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("Consumer Homepage");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Consumer Homepage Access"
    );
    applicationStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
  }

  componentDidUpdate(lastProps, lastState) {
    if (
      !this.state.run &&
      !isEmpty(this.state.tiresize) &&
      !isEmpty(this.state.position.lat) &&
      !isEmpty(this.state.position.lng)
    ) {
      this.setState({ run: true });
      this.runSearch();
    }
  }

  render() {
    return (
      <div className={this.getMainClass()}>
        <div className="container">
          <div className="carousel-container">
            {renderCarousel(this.state.carouselItems, false, 0, 0, null)}
          </div>

          <box className="floatbox">
            <box className="consumersearch">
              <box>
                <container className="top large">
                  <h1>{this.t("consumer.landingtitle")}</h1>
                </container>
              </box>

              <box>
                <container className="top large">
                  <column>
                    <container className="side">
                      <input
                        id="search-tiresize"
                        className="consumerinput"
                        placeholder={this.t("consumer.search")}
                      />
                    </container>
                  </column>
                  <column>
                    <container className="side">
                      <SelectBox
                        selected={this.state.season}
                        placeholder={this.t(
                          "product.filters.season.noSelection"
                        )}
                        dataList={[
                          {
                            key: 0,
                            value: this.t("product.filters.season.winter"),
                          },
                          {
                            key: 1,
                            value: this.t("product.filters.season.allSeason"),
                          },
                          {
                            key: 2,
                            value: this.t("product.filters.season.summer"),
                          },
                        ]}
                        onSelect={this.setSeason}
                      />
                    </container>
                  </column>
                  <column>
                    <container className="side">
                      <input
                        id="search-postal"
                        placeholder={this.t("dealerFilter.enterPostal")}
                      />
                    </container>
                  </column>
                  <column>
                    <container className="side">
                      <SelectBox
                        selected={this.state.distance}
                        dataList={[
                          {
                            key: 5,
                            value: "5 " + this.t("product.kilometers"),
                          },
                          {
                            key: 10,
                            value: "10 " + this.t("product.kilometers"),
                          },
                          {
                            key: 15,
                            value: "15 " + this.t("product.kilometers"),
                          },
                          {
                            key: 20,
                            value: "20 " + this.t("product.kilometers"),
                          },
                        ]}
                        onSelect={this.setDistance}
                      />
                    </container>
                  </column>
                  <column>
                    <container className="side">
                      <button className="btn" onClick={this.startSearch}>
                        <img src="/static/img/search.png" />
                      </button>
                    </container>
                  </column>
                </container>
              </box>
            </box>
          </box>

          <box className="consumerhomepage">
            <box className="locations">
              <container>
                <h2>{this.t("consumer.subtitleLocations")}</h2>
              </container>
            </box>
            <box className="help">
              <container>
                <h2>{this.t("consumer.subtitleHelp")}</h2>
              </container>
            </box>
            <box className="images">
              <container className="side large">
                <column>
                  <container className="top">
                    <box>
                      <img src="/static/img/secondaryimage1.png" />
                    </box>
                  </container>
                  <container className="top">
                    <box>
                      <h3>{"1. " + this.t("consumer.subtitlePointTitle1")}</h3>
                    </box>
                  </container>
                  <container>
                    <box className="text">
                      <text>{this.t("consumer.subtitlePointBody1")}</text>
                    </box>
                  </container>
                </column>
                <column>
                  <container className="top">
                    <box>
                      <img src="/static/img/secondaryimage2.png" />
                    </box>
                  </container>
                  <container className="top">
                    <box>
                      <h3>{"2. " + this.t("consumer.subtitlePointTitle2")}</h3>
                    </box>
                  </container>
                  <container>
                    <box className="text">
                      <text>{this.t("consumer.subtitlePointBody2")}</text>
                    </box>
                  </container>
                </column>
                <column>
                  <container className="top">
                    <box>
                      <img src="/static/img/secondaryimage3.png" />
                    </box>
                  </container>
                  <container className="top">
                    <box>
                      <h3>{"3. " + this.t("consumer.subtitlePointTitle3")}</h3>
                    </box>
                  </container>
                  <container>
                    <box className="text">
                      <text>{this.t("consumer.subtitlePointBody3")}</text>
                    </box>
                  </container>
                </column>
              </container>
            </box>
          </box>
        </div>
      </div>
    );
  }

  getMainClass = () => {
    return Cx({
      main: true,
      "homepage-refactor": true,
      "homepage-consumer": true,
    });
  };

  startSearch = () => {
    this.setState({
      tiresize: "",
      position: { lat: null, lng: null },
      postal: "",
      run: false,
    });

    var tiresize = "",
      postal = "";

    if (document.getElementById("search-tiresize")) {
      tiresize = document.getElementById("search-tiresize").value;
    }
    if (document.getElementById("search-postal")) {
      postal = document.getElementById("search-postal").value;
    }

    if (!isEmpty(tiresize) && !isEmpty(postal)) {
      this.setTiresize(tiresize);
      this.setPostal(postal);
      this.calculatePosition(postal);
    }
  };

  runSearch = () => {
    applicationStore.data.consumerTiresize = this.state.tiresize;
    applicationStore.data.consumerSeason = this.state.season;
    applicationStore.data.consumerDistance = this.state.distance;
    applicationStore.data.consumerPosition = this.state.position;
    applicationStore.data.consumerPostal = this.state.postal;

    searchActions.loadConsumerSearch();
  };

  calculatePosition = (postal) => {
    var GoogleMaps = window.google,
      lat = null,
      lng = null,
      setPosition = this.setPosition;
    if (GoogleMaps) {
      var GoogleGeoCoder = new GoogleMaps.maps.Geocoder();
      GoogleGeoCoder.geocode({ address: postal }, function (results, status) {
        if (status == GoogleMaps.maps.GeocoderStatus.OK) {
          lat = results[0].geometry.location.lat();
          lng = results[0].geometry.location.lng();
        } else {
          console.log("Geocode was not successful: " + status);
        }

        setPosition({ lat: lat, lng: lng });
      });
    }
  };

  setTiresize = (tiresize) => {
    this.setState({
      tiresize: tiresize,
    });
  };

  setSeason = (season) => {
    this.setState({
      season: season,
    });
  };

  setDistance = (distance) => {
    this.setState({
      distance: distance,
    });
  };

  setPosition = (position) => {
    this.setState({
      position: position,
    });
  };

  setPostal = (postal) => {
    this.setState({
      postal: postal,
    });
  };

  onChange = () => {
    this.setState({
      carousel: applicationStore.data.carousel,
      carouselItems: applicationStore.data.carouselItems,
      featuredItems: applicationStore.data.featuredItems,
    });
  };
}

import { languageStore } from '../stores/LanguageStore.js'

var _ = require('lodash');
var Moment = require('moment');
var DotAccess = require('dot-access');
var Numeral = require('numeral');

var DATETIME_FORMAT = 'MMMM Do YYYY, h:mm:ss a';

Numeral.language('fr-FR', {
  delimiters: {
    thousands: ' ',
    decimal: ','
  },
  currency: {
    symbol: '$'
  }
});

Numeral.language('en-CA', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  currency: {
    symbol: '$'
  }
});

export var i18nMixin = {

  /**
   * Dictionary translate
   */
  t: function(key, lang) {
    return DotAccess.get(languageStore.getMessages(lang), key);
  },

  tp: function(key, value, lang) {
    var translate = DotAccess.get(languageStore.getMessages(lang), key);

    if (parseInt(value) === 1) {
      return translate.singular.replace('{}', value);
    } else {
      return translate.plural.replace('{}', value);
    }
  },

  /*
   * Translate dynamic value
   */
  td: function(value) {
    if (value && value[languageStore.data.lang]) {
      return value[languageStore.data.lang];
    } else {
      return '';
    }
  },

  /**
   * Currency
   */
  c: function(value) {
    var locale = languageStore.getLocale();
    Numeral.language(locale);

    switch (locale) {
      case 'en-CA':
        if (_.isNumber(value) && !isNaN(value)) {
          return Numeral(value).format('$0.00');
        } else {
          return value;
        }
      break;
      case 'fr-FR':
        if (_.isNumber(value) && !isNaN(value)) {
          return Numeral(value).format('0.00$');
        } else {
          return value;
        }
      break;
      default:
        throw new Error('Not supported locale ' + locale);
    }
  },

  /**
   * DateTime
   */
  dt: function(datetime) {
    if (Moment.isMoment(datetime)) {
      return datetime.format(DATETIME_FORMAT);
    } else {
      return datetime;
    }
  },

  /**
   * Number
   */
  n: function(number) {
    return number;
  }
};

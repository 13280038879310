import { applicationStore } from "../../stores/ApplicationStore.js";

import { ProductTypes } from "../../constants/ProductTypes";

import { getTestId, TEST_LIST } from "../../service/TestingService";
import {
  ADOBELAUNCHER_CLASS,
  setAdobeLaunchClass,
} from "../../service/AdobeLaunchService";
import {
  getTabIndex,
  isEmpty,
  sizeOf,
  generateGUID,
  money_format,
  isEdgeBrowser,
  isIEBrowser,
} from "../../service/UtilityService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var Immutable = require("immutable");

var count = 0,
  current = null;

export class SelectBox extends I18nComponent {
  state = {
    id: generateGUID(true),
    expanded: false,
    expandedOnDataLoad: false,
    selectedOnDataLoad: false,
    count: count,
    width: window.innerWidth,
  };

  updateState = (state) => {
    this.setState(state);
  };
  reSize = () => {
    this.updateState({
      width: window.innerWidth,
    });
  };

  componentWillMount() {
    count++;
  }

  componentWillUnmount() {
    count--;
    window.removeEventListener("resize", this.reSize);
  }

  componentDidMount() {
    // var updateState = this.updateState;
    window.addEventListener("resize", this.reSize);
  }

  componentWillUpdate() {
    this.closeNonFocusedSelects();
  }

  componentDidUpdate(lastProps) {
    this.expandedOnDataLoad();
    this.autoSelectValue();
    if (this.props.openOnDataLoad !== lastProps.openOnDataLoad) {
      this.setState({
        expandedOnDataLoad: false,
        selectedOnDataLoad: false,
      });
    }
    if (this.props.extOpen && this.props.extOpen !== lastProps.extOpen) {
      current = this.state.id;
      this.setState({
        expanded: true,
      });
    }
    this.closeNonFocusedSelects();
  }

  closeNonFocusedSelects = () => {
    if (this.state.expanded && current !== this.state.id) {
      this.setState({
        expanded: false,
      });
    }
  };

  expandedOnDataLoad = () => {
    if (
      !this.state.expandedOnDataLoad &&
      !!this.props.openOnDataLoad &&
      sizeOf(this.props.dataList) > 0
    ) {
      current = this.state.id;
      this.setState({
        expanded: true,
        expandedOnDataLoad: true,
      });
    }
  };

  autoSelectValue = () => {
    if (
      this.state.expandedOnDataLoad &&
      !this.state.selectedOnDataLoad &&
      this.props.autoSelect &&
      sizeOf(this.props.dataList) === 1
    ) {
      var onSelected, value;
      for (var i in this.props.dataList) {
        onSelected = this.props.onSelect;
        value = this.props.dataList[i].key;
        this.setState({
          expanded: false,
          selectedOnDataLoad: true,
        });
      }
      if (typeof onSelected === "function") {
        onSelected(value);
      }
    }
  };

  render() {
    //className={this.getSelectClass()} data-dtm={this.props.datadtm}
    return (
      <selectbox
        id={this.state.id}
        className={this.props.className}
        tabIndex={getTabIndex()}
        onClick={this.toggleExpanded}
      >
        {this.renderSelected()}
        <optionbox
          id={this.state.optionId}
          className={this.getOptionClass()}
          style={{ zIndex: 175 - this.state.count }}
        >
          {this.renderOptionSelect()}
        </optionbox>
      </selectbox>
    );
  }

  getOptionSelectClass = () => {
    if (!isEmpty(this.props.datadtm)) {
      return setAdobeLaunchClass(
        this.getOptionClass(),
        ADOBELAUNCHER_CLASS.DROPDOWN
      );
    } else {
      return "";
    }
  };

  getColumnCount = () => {
    return Math.ceil(sizeOf(this.props.dataList) / 10).toFixed(0);
  };

  renderOptionSelect = () => {
    var list = this.props.dataList,
      count = 0,
      columnCount = this.getColumnCount(),
      temp = columnCount,
      columns = [],
      onSelected = this.props.onSelect,
      key = "selectbox",
      getObject = this.props.getObject,
      isDesktop = parseInt(this.state.width) >= 1366,
      getColumnClass = this.getColumnClass,
      getTestIdSuffix = this.getTestIdSuffix(),
      getOptionSelectClass = this.getOptionSelectClass,
      datadtm = this.props.datadtm;

    if (sizeOf(list) > 0 && columnCount > 1 && isDesktop) {
      while (columnCount > 0) {
        columns.push({});
        columnCount--;
      }

      return Immutable.fromJS(columns).map(function (data, column) {
        return (
          <column className={getColumnClass(temp)}>
            {Immutable.fromJS(list)
              .skip(column * 10)
              .take(10)
              .map(function (data, index) {
                return (
                  <optionselect
                    key={key + "-" + index}
                    id={getTestId(
                      TEST_LIST.SELECTBOX.OPTION,
                      getTestIdSuffix + " " + data.get("value")
                    )}
                    className={getOptionSelectClass()}
                    data-dtm={datadtm}
                    tabIndex={getTabIndex()}
                    onClick={onSelected.bind(
                      null,
                      !getObject ? data.get("key") : data
                    )}
                  >
                    {data.get("value")}
                  </optionselect>
                );
              })}
          </column>
        );
      });
    } else if (sizeOf(list) > 0) {
      return Immutable.fromJS(list).map(function (data, index) {
        return (
          <optionselect
            key={key + "-" + index}
            id={getTestId(
              TEST_LIST.SELECTBOX.OPTION,
              getTestIdSuffix + " " + data.get("value")
            )}
            className={getOptionSelectClass()}
            data-dtm={datadtm}
            tabIndex={getTabIndex()}
            onClick={onSelected.bind(null, !getObject ? data.get("key") : data)}
          >
            {data.get("value")}
          </optionselect>
        );
      });
    } else {
      return <optionselect />;
    }
  };

  getTestIdSuffix = () => {
    if (!isEmpty(this.props.testId)) {
      return this.props.testId;
    } else {
      return "";
    }
  };

  getColumnClass = (columns) => {
    if (this.requiresMinWidth()) {
      return "c" + columns + " edge";
    } else {
      return "c" + columns;
    }
  };

  requiresMinWidth = () => {
    return isEdgeBrowser() || isIEBrowser();
  };

  renderSelected = () => {
    return [
      <left key="select-left">
        <container className={this.getSelectBoxContainerClassName()}>
          <span
            id={getTestId(
              TEST_LIST.SELECTBOX.VALUE,
              this.getTestIdSuffix() + " " + this.props.placeholder
            )}
          >
            {this.getSelected()}
          </span>
        </container>
      </left>,
      <right key="select-right">
        <i
          id={getTestId(
            TEST_LIST.SELECTBOX.ARROW,
            this.getTestIdSuffix() + " " + this.props.placeholder
          )}
          className={this.getIconClass()}
        ></i>
      </right>,
    ];
  };

  getSelectBoxContainerClassName = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "audi_2021":
        return "side";
      default:
        return "side small";
    }
  };

  getSelected = () => {
    if (!isEmpty(this.props.selected)) {
      return this.getSelectedValue();
    } else if (!isEmpty(this.props.placeholder)) {
      return this.props.placeholder;
    } else {
      return this.t("common.select");
    }
  };

  getSelectedValue = () => {
    var value = null;
    for (var i in this.props.dataList) {
      if (this.props.dataList[i].key == this.props.selected) {
        if (this.props.showUnitPrice) {
          value =
            this.props.dataList[i].key +
            " - " +
            money_format(this.props.dataList[i].displayPrice) +
            " " +
            this.getSelectedText();
        } else if (this.props.displayKey) {
          value = this.props.dataList[i].key;
        } else {
          value = this.props.dataList[i].value;
        }
      }
    }
    return value;
  };

  getSelectedText = () => {
    switch (applicationStore.data.productType) {
      case ProductTypes.ACCESSORIES:
        return this.t("product.perAccessory");
      case ProductTypes.ALLOY_WHEELS:
        return this.t("product.perWheel");
      case ProductTypes.TIRES:
        return this.t("product.perTire");
      case ProductTypes.PACKAGES:
        return this.t("product.perPackage");
    }
  };

  getIconClass = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "audi_2021":
      case "bmw":
      case "hyundai":
      case "mazda":
      case "volkswagen":
      case "volkswagen_2020":
        return this.state.expanded ? "fa fa-angle-down" : "fa fa-angle-up";
      default:
        return this.state.expanded ? "fa fa-sort-down" : "fa fa-sort-up";
    }
  };

  getOptionClass = () => {
    var temp = "";
    if (this.requiresMinWidth()) {
      temp = "c" + this.getColumnCount();
    }
    if (this.state.expanded) {
      return (
        (this.props.rightAlign ? "alignright" + " " : "") +
        "expanded" +
        " " +
        temp
      );
    } else {
      return temp;
    }
  };

  toggleExpanded = () => {
    if (!this.state.expanded) {
      current = this.state.id;
    }

    this.setState({
      expanded: !this.state.expanded,
    });
  };
}

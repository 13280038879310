import { i18nMixin } from "../../i18n/i18nMixin.js";
import { shoppingCartViewStore } from "../../stores/ShoppingCartViewStore";
import { shoppingCartActions } from "../../actions/ShoppingCartActions";

import { Modal } from "../ux/Modal.jsx";
import {
  formTypes,
  sizeOf,
  isEmpty,
  phonenumber_format,
  moment_format,
  getTrackingEventCategory,
  getTrackingEventOrderId,
} from "../../service/UtilityService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");

export class WishlistDealerFormModal extends I18nComponent {
  state = {
    actionSuccess: false,
    apiFailure: false,
    actionFailure: false,
    actionFailureMsg: "",
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview(
      "Shopping Cart Dealer Quick View Form - " + this.props.formType
    );
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Shopping Cart Dealer Quick View Form Access - " + this.props.formType,
      getTrackingEventOrderId()
    );
    shoppingCartViewStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    shoppingCartViewStore.removeChangeListener(this.onChange);
  }

  render() {
    /* {this.renderFormSection()} */
    return (
      <Modal refactor={true} requestUnmount={this.closeWislistDealerFormModal}>
        <div className="wrapper">
          <div className="modal-container">
            <div className="information-form">
              <div className="form-header">
                <h1>{this.getFormTitle()}</h1>
                <span>{this.getFormSubtitle()}</span>
              </div>

              {this.renderActionResults()}
              {this.renderCustomerInformation()}

              <div className="form-buttons">{this.getFormActions()}</div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderActionResults = () => {
    if (this.state.actionSuccess) {
      return this.getFormSuccess();
    } else if (this.state.apiFailure) {
      return this.renderErrorSection(true);
    } else if (this.state.actionFailure) {
      return this.renderErrorSection(false);
    } else {
      return false;
    }
  };

  renderCustomerInformation = () => {
    return (
      <div className="contact-details">
        <table>
          <thead>
            <tr>
              <th colSpan="2">
                <h4>{this.t("wishlist.modal.contactDetails")}</h4>
              </th>
            </tr>
          </thead>

          <tbody>
            {this.renderRequestedDate(
              this.t("wishlist.modal.requestedDate"),
              shoppingCartViewStore.data.get("appointmentRequestDate")
            )}
            {this.renderCustomerInfo(
              this.t("wishlist.modal.firstName"),
              shoppingCartViewStore.data.get("formFirstName")
            )}
            {this.renderCustomerInfo(
              this.t("wishlist.modal.lastName"),
              shoppingCartViewStore.data.get("formLastName")
            )}
            {this.renderCustomerInfo(
              this.t("wishlist.modal.email"),
              shoppingCartViewStore.data.get("formEmail")
            )}
            {this.renderCustomerInfo(
              this.t("wishlist.modal.phone"),
              phonenumber_format(shoppingCartViewStore.data.get("formPhone"))
            )}
            {this.renderCustomerInfo(
              this.t("wishlist.modal.notes"),
              shoppingCartViewStore.data.get("formNotes")
            )}
          </tbody>
        </table>
      </div>
    );
  };

  renderRequestedDate = (label, date) => {
    if (!isEmpty(date) && this.showDate() && date.isValid()) {
      return (
        <tr>
          <td>{label}:</td>
          <td>{moment_format(date)}</td>
        </tr>
      );
    } else {
      return false;
    }
  };

  renderCustomerInfo = (label, text) => {
    if (!isEmpty(text)) {
      return (
        <tr>
          <td>{label}:</td>
          <td>{text}</td>
        </tr>
      );
    } else {
      return false;
    }
  };

  showDate = () => {
    switch (this.props.formType) {
      case formTypes.APPOINTMENT:
      case formTypes.RESERVE:
        return true;
      case formTypes.CALLBACK:
      case formTypes.SAVE:
      case formTypes.SEND:
      case formTypes.PRICEMATCH:
      case formTypes.PRINT:
      case formTypes.DOWNLOAD:
      default:
        return false;
    }
  };

  getFormSuccess = () => {
    switch (this.props.formType) {
      case formTypes.APPOINTMENT:
      case formTypes.CALLBACK:
      case formTypes.RESERVE:
      case formTypes.SAVE:
      case formTypes.SEND:
      case formTypes.PRICEMATCH:
      case formTypes.PRINT:
      case formTypes.DOWNLOAD:
      default:
        return this.renderSuccessSection(this.t("successful"));
    }
  };

  renderSuccessSection = (message) => {
    return (
      <div className="order-success-div">
        <span className="title">
          <div className="title-div no-button">
            <i className="fa fa-check" />
          </div>
        </span>
        <div className="text">{message}</div>
      </div>
    );
  };

  renderErrorSection = (api) => {
    return (
      <div className="order-error-div">
        <span className="title">
          <div className="title-div no-button">
            <i className="fa fa-close" />
          </div>
        </span>
        <div className="text">
          {api
            ? this.t("wishlist.modal.errorNoReturn")
            : this.state.actionFailureMsg}
        </div>
      </div>
    );
  };

  getFormActions = () => {
    /* <button className="btn" onClick={this.sendRespond.bind(this, false)}>{this.t('wishlist.modal.send')}</button> */
    switch (this.props.formType) {
      case formTypes.APPOINTMENT:
      case formTypes.CALLBACK:
      case formTypes.RESERVE:
      case formTypes.SEND:
      case formTypes.PRICEMATCH:
      case formTypes.PRINT:
      case formTypes.DOWNLOAD:
      default:
        return (
          <div>
            <button
              className="btn"
              onClick={this.markAsComplete.bind(this, true)}
            >
              {this.t("wishlist.modal.markAsComplete")}
            </button>
          </div>
        );
    }
  };

  sendRespond = () => {
    this.resetErrors();
    shoppingCartActions.wishlistFormDealerModalResetError();
    if (this.messageValid()) {
      googleAnalyticsEnhancedTrackEvent(
        getTrackingEventCategory(),
        "Shopping Cart Dealer Quick View Form - Send Message",
        getTrackingEventOrderId()
      );
      setTimeout(() => {
        shoppingCartActions.submitFormResponse(this.getMessage());
      }, 0);
    }
  };

  markAsComplete = () => {
    this.resetErrors();
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Shopping Cart Dealer Quick View Form - Mark as Complete",
      getTrackingEventOrderId()
    );
    shoppingCartActions.submitFormMarkAsComplete();
  };

  getMessage = () => {
    return document.getElementById("formMessage").value;
  };

  messageValid = () => {
    var message = document.getElementById("formMessage");
    if (message && sizeOf(this.getMessage()) > 0) {
      return true;
    } else {
      this.hasError(this.t("wishlist.modal.errorNoMessage"));
      return false;
    }
  };

  renderFormSection = () => {
    return (
      <div className="form-section">
        <ul>
          <li className="message">
            {this.renderFormElement(
              this.t("wishlist.modal.message"),
              "message",
              "formMessage"
            )}
          </li>
        </ul>
      </div>
    );
  };

  renderFormElement = (title, className, id) => {
    return (
      <div className={"form-element form-" + className}>
        <h3>{title + " "}</h3>
        <textarea className={className} id={id} maxLength="1000" />
      </div>
    );
  };

  getFormTitle = () => {
    switch (this.props.formType) {
      case formTypes.APPOINTMENT:
        return this.t("wishlist.modal.titleReceiveAppointment");
      case formTypes.CALLBACK:
        return this.t("wishlist.modal.titleReceiveCallback");
      case formTypes.RESERVE:
        return this.t("wishlist.modal.titleReceiveReserve");
      case formTypes.SAVE:
        return this.t("wishlist.modal.titleReceiveSave");
      case formTypes.SEND:
        return this.t("wishlist.modal.titleReceiveEmail");
      case formTypes.PRICEMATCH:
        return this.t("wishlist.modal.titleReceiveCompare");
      case formTypes.PRINT:
        return this.t("wishlist.modal.titleReceivePrint");
      case formTypes.DOWNLOAD:
        return this.t("wishlist.modal.titleReceiveDownload");
      default:
        return this.t("wishlist.modal.markAsComplete");
    }
  };

  getFormSubtitle = () => {
    switch (this.props.formType) {
      case formTypes.APPOINTMENT:
      case formTypes.CALLBACK:
      case formTypes.RESERVE:
      case formTypes.SAVE:
      case formTypes.SEND:
      case formTypes.PRICEMATCH:
      case formTypes.PRINT:
      case formTypes.DOWNLOAD:
        return this.t("wishlist.modal.subtitleReceiveLead");
      default:
        return this.t("wishlist.modal.subtitleDealerMarkAsComplete");
    }
  };

  closeWislistDealerFormModal = () => {
    shoppingCartActions.closeWishlistDealerFormModal();
  };

  resetErrors = () => {
    this.setState({
      actionSuccess: false,
      actionFailure: false,
      actionFailureMsg: "",
    });
  };

  hasError = (message) => {
    this.setState({
      actionSuccess: false,
      actionFailure: true,
      actionFailureMsg: message,
    });
  };

  onChange = () => {
    this.setState({
      actionSuccess: shoppingCartViewStore.data.get(
        "shoppingCartDealerFormSuccess"
      ),
      apiFailure: shoppingCartViewStore.data.get("shoppingCartDealerFormError"),
    });
  };
}

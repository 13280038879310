module.exports = {
  'startNewQuote':'Nouvelle Soumission',
  'title':'Mercedes-Benz',
	'ourBrands': 'Nos Marques',
  'MO':'Mercedes Original',
  'MOE':'Mercedes Original étendu',

  homepage_long_1: 'Pourquoi choisir les pneus MO? Les pneus MO sont les seuls pneus approuvés qui livrent le contrôle, la sécurité et l\'euphorie d\'un vrai véhicule de performance. Les pneus MO sont conçus spécifiquement et rigoureusement testés pour un ajustement précis, et ils sont configurés pour travailler en parfaite harmonie avec les systèmes de sécurité de votre Mercedes-Benz. Cela se traduit par une fiabilité et une durabilité maximales, une douceur de roulement impeccable et un faible niveau de bruit.',
  homepage_long_2: 'Les pneus MOE peuvent rouler à plat grâce à leurs flancs renforcés. Cette technologie fournit une mobilité prolongée temporaire – pour vous permettre de rouler sécuritairement à 80 km/h sur une distance de 80 km, dans l\'éventualité d\'une perte de pression inattendue. Les pneus MOE sont utilisés uniquement en combinaison avec un système de surveillance de la pression des pneus. *La perte de pression affectera les caractéristiques de maniabilité.',
  homepage_long_3_a: 'Les bons pneus conçus pour travailler en parfaite harmonie avec les systèmes de sécurité de votre Mercedes',
  homepage_long_3_b: 'Les Pneus d\'hiver Approuvés Mercedes-Benz possèdent des sculptures de semelle et des composés de caoutchouc évolués qui fournissent une traction supérieure sur les routes froides et glacées',
  homepage_long_3_c: 'Prix des pneus concurrentiels',
  homepage_long_3_d: 'Installation experte par des techniciens formés par Mercedes-Benz',
  homepage_long_3_e: 'Toutes les grandes marques, incluant Pirelli, Continental, Michelin, Dunlop et plus encore',
  homepage_long_4: 'Mercedes-Benz a conçu avec précision des jantes qui s\'harmonisent parfaitement à la carrosserie et contribuent à la qualité de design globale de ses véhicules. Nos jantes passent par un programme complet de développement et de mise à l\'essai afin de mesurer le profil de résistance réel des jantes en alliage léger dans de vraies conditions d\'utilisation. Ceci fait en sorte que nos jantes en alliage léger sont parmi les plus sécuritaires, fiables et durables présentement sur le marché. De plus, seuls les fournisseurs les meilleurs et les plus réputés au monde sont choisis pour fabriquer nos jantes en alliage léger, selon les spécifications de production très strictes de Mercedes-Benz.',
  homepage_title: 'PneusApprouvés Mercedes-Benz',
  homepage_winterpackages: 'Ensembles de pneu et roue d\'hiver',
  homepage_genuinewheels: 'Jantes d\'origine',
  homepage_wheeldevelopment: 'Mercedes-Benz Original (MO)',
  homepage_design: 'Mercedes-Benz Original Extended (MOE)',
  homepage_quality: 'Raisons d\'acheter les PneusApprouvés chez un concessionnaire Mercedes-Benz agréé',

  nonOEWarrning: 'Vous avez sélectionné montrer des pneus non approuvés. Mercedes recommande toujours d\'utiliser des pneus approuvés et adaptés à votre véhicule.',

  'logo': {
    'text': 'Les pneus approuvés Mercedes-Benz'
  },
  'header': {
    'mercedesHeader': 'Centre de pneus approuvés'
  },
  'common': {
    'viewTires': 'Voir les pneus',
    'viewWheels': 'Voir les jantes',
    'viewAccessories': 'Voir les accessoires',
    'viewPackages': 'Voir les packages',
    'welcomeMessage': 'Bienvenue au Centre de pneus apprové et jantes d\'origine de Mercedes-Benz',
    'getStarted': 'Démarrer la recherche',
    'customizeYourRide': 'Personnalisez votre vehicle',
    'myWishlist': 'Liste de souhaits',
    'select': 'Sélectionnez',
    'yes': 'Oui',
    'no': 'Non',
    'featured': 'Ensembles de pneu et roue d\'hiver',
    'enter': 'Entrer',
    'dealerSelectionPlaceholder': 'Recherche par ville, province ou code postal',
    'selectByProvinceTitle': 'Sélectionnez votre province',
    'selectByProvinceHeaderTitle': 'Centre de PneusApprouvés',
    'myCart': 'Liste de souhaits'
  },
  'breadcrumbs': {
    'staticHome': 'Accueil'
  },
  'cart': {
    'getCartReview': 'Revoir ma liste de souhaits',
    'cartBarTitlePrefix': 'Aperçu de ma liste de souhaits',
    'cartBarTitleSuffix1': 'article',
    'cartBarTitleSuffix2': 'articles',
    'mywishlist': 'Liste de souhaits'
  },
  'search': {
    'placeholderSearchTireSize': '225/45R17 ou 2254517',
    'placeholderSearchPartNumber': 'CON15480150000AS ou 15480150000',
    'placeholderSearchChassis': 'w204 ou w204.012',
    'searchChassisTag': 'Recherche de Châssis'
  },
  'productTypes': {
    'packages': 'Ensemble de jantes et pneus',
    'packagesTab': 'Ensemble de jantes et pneus',
	  'searchTires': 'Pneus d\'Origine Mercedes',//'Les pneus approuvés Mercedes-Benz',
	  'searchWheels': 'Genuine Wheels',
	  'searchPackages': 'Ensemble de jantes et pneus',
	  'tireSelectText': 'Les pneus d\'hiver approuvés Mercedes-Benz ont des bandes de roulement et des composés de caoutchouc perfectionnés qui offrent une adhérence supérieure sur les routes froides et verglacées. Ils offrent également une meilleure stabilité en cas de freinage et une meilleure maniabilité.',
	  'wheelSelectText': 'Mercedes-Benz suit un programme intensif de développement et d’essais pour assurer la qualité de ses différents modèles de jantes. Chez Mercedes-Benz, nous ajustons le développement et la phase d’essais au profil de résistance des jantes en alliage léger sous des conditions réelles. Cela nous permet de nous assurer que nos jantes en alliage léger figurent parmi les plus sécuritaires, les plus fiables et les plus durables sur le marché automobile d’aujourd’hui.',
	  'accessorySelectText': '',
	  'packageSelectText': 'Les pneus font partie intégrante des performances de votre Mercedes-Benz. En effet, seuls les pneus unissent votre voiture à la route. Ils ont donc un impact sur l\'accélération, le freinage, la prise de virage et la sécurité globale de votre véhicule.'
  },
  'quote': {
    'noteFieldLabel': 'Remarques',
    'userNoteLabel': 'Remarques'
  },
  'quoteReport': {
    'quoteNotes': 'Remarques'
   },
  'product': {
    'showOnlyApproved': 'Afficher les PneusApprouvés',
    'approvedTires': 'PneusApprouvés',
    'nonApprovedTires': 'Non PneusApprouvés',
    'good': '',
    'better': '',
    'best': '',
    'dealerPrice': 'Prix de vente',
    'retailPrice': 'Prix de détail',
    'wheel': 'Jante',
    'wheelTag': '',
    'tire': 'Pneus d\'Origine Mercedes',//'Les pneus approuvés Mercedes-Benz',
    'tireTag': 'Trouver les pneus approuvés par Mercedes-Benz soit par véhicule ou par grandeur de pneu',
    'accessory': 'Accessoire',
    'accessoryTag': '',
    'package': 'Ensemble de jantes et pneus',
    'packageTag': 'Trouver le meilleur ensemble de pneus d\'hiver et de roues pour votre véhicule',
    'collection': 'Collection',
    'collectionTag': '',
    'addToQuote': 'Ajouter au panier',
    'addToWishlist': 'Ajouter au panier',
    'removeFromWishlist': 'Enlevé de panier',
    'wheelComment': 'Remarques',
    'approvedTire': 'Pneus d\'Origine Mercedes',//'Les pneus approuvés Mercedes-Benz',
    'itemAlert': '!S\'il vous plaît voir plus de détails pour les véhicules qui vont avec ce pneu',
    'modal': {
      'wishlistSaved': 'La liste de souhaits a été enregistrée avec succès.',
      'wishlistSaveFailed': 'La liste de souhaits n\'a pas pu être enregistrée.'
    }
  },
  'partInfoModal': {
    'regionalPrice': 'Prix MB (Région)'
  },
  'appointmentForm': {
    'printWishlist': 'Imprimer la Liste de souhaits'
  },
  'wishlist': {
    'title': 'Votre liste de souhaits',
    'clear': 'Supprimer la liste de souhaits',
    'saveWishlist': 'Enregistrer la liste de souhaits',
    'emailWishlist': 'Expédier la liste de souhaits par courriel',
    'scheduleAppointmentMsg': 'Planifier un rendez-vous avec votre détaillant pour votre liste de souhaits',
    'personalizedWishlist': 'Votre liste de souhaits personnalisée',
    'wishlistSaved': 'Votre liste de souhaits a été enregistré avec succès.',
    'wishlistSaveFailed': 'L\'enregistrement de votre liste de souhaits a échoué.'
  },
  'guestAccountCreation': {
    'noEmail': 'Crée un compte'
  }
};

import React from "react";
import { Provider } from "react-redux";

import { applicationActions } from "./actions/ApplicationActions.js";
import ApplicationRefactor from "./components/ApplicationRefactor.jsx";
import { Languages } from "./constants/Languages.js";
import { onDisplayErrorPage } from "./redux/features/applicationInfo.js";
import {
  loadConsumerConfig,
  loadDealerConfig,
  loadGroupConfig,
  loadNationalConfig,
} from "./service/SiteConfigService.js";
import { isEmpty } from "./service/UtilityService.js";
import { applicationStore } from "./stores/ApplicationStore.js";
import { Router } from "./stores/Router.js";
import store from "./stores/configureStore";

// IE11 legacy support
require("react-app-polyfill/ie9");

// IE8 legacy support
require("console-polyfill");

// Map Support
require("@babel/polyfill");

new Router().bootstrap();
const LegacyApplication = () => {
  return (
    <Provider store={store}>
      <ApplicationRefactor />
    </Provider>
  );
};

export default LegacyApplication;

if (!isEmpty(window.dealerAccountInfoPayload)) {
  loadDealerConfig(window.dealerAccountInfoPayload);
} else if (!isEmpty(window.nationalAccountInfoPayload)) {
  loadNationalConfig(window.nationalAccountInfoPayload);
} else if (!isEmpty(window.groupAccountInfoPayload)) {
  loadGroupConfig(window.groupAccountInfoPayload);
} else if (!isEmpty(window.consumerAccountInfoPayload)) {
  loadConsumerConfig(window.consumerAccountInfoPayload);
} else {
  applicationStore.data.lang = Languages.EN;
  store.dispatch(onDisplayErrorPage(window.httpStatusCode));
}

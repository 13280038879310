import { applicationStore } from '../stores/ApplicationStore.js';
import { languageStore } from '../stores/LanguageStore.js';

import { isEmpty, getAppTemplate, getAppLanguage } from '../service/UtilityService';
import { encodeBase64 } from '../api/ApiService';

var _ = require('lodash');

export const templateStyle = {
  acura: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#1a93d7'
  },
  audi: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#bb0a30'
  },
  audi_2021: {
    headerTextColor: '#FFFFFF',
    selectedColor: '#1A93D7',
    buttonColor: '#000000'
  },
  bmw: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#176db7'
  },
  chrysler: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#6690a1'
  },
  consumer: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#6690a1'
  },
  ford: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#003478'
  },
  fungroup: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#6690a1'
  },
  generic: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#6690a1'
  },
  gmcanada: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#b1c100'
  },
  honda: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#eb2934'
  },
  hyundai: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#002c5f'
  },
  integra: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#eb2934'
  },
  kia: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#bc162b'
  },
  kia_2021: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#05141F'
  },
  lexus: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#6690a1'
  },
  mazda: {
    headerTextColor: '#FFFFFF',
    selectedColor: '#1A93D7',
    buttonColor: '#4a606d'
  },
  mazdatransition: {
    headerTextColor: '#FFFFFF',
    selectedColor: '#1A93D7',
    buttonColor: '#4a606d'
  },
  mecanipneu: {
    headerTextColor: '#FFFFFF',
    selectedColor: '#1A93D7',
    buttonColor: '#DE0002'
  },
  mercedes: {
    headerTextColor: '#FFFFFF',
    selectedColor: '#1A93D7',
    buttonColor: '#01adef'
  },
  mercedes_2022:{
    headerTextColor: '#FFFFFF',
    selectedColor: '#1A93D7',
    buttonColor: '#01adef'
  },
  mercedestransition: {
    headerTextColor: '#FFFFFF',
    selectedColor: '#1A93D7',
    buttonColor: '#01adef'
  },
  mini: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#000000'
  },
  mrlube: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#eb2934'
  },
  pneuselect: {
    headerTextColor: '#FFFFFF',
    selectedColor: '#1A93D7',
    buttonColor: '#ffe600'
  },
  setoyota: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#e72530'
  },
  subaru: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#2981ba'
  },
  tirecraft: {
    headerTextColor: '#FFFFFF',
    selectedColor: '#1A93D7',
    buttonColor: '#eb2127'
  },
  toyota: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#0079C0'
  },
  toyotausa: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#e72530'
  },
  volkswagen: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#0db1f4'
  },
  volkswagen_2020: {
    headerTextColor: '#000000',
    selectedColor: '#1A93D7',
    buttonColor: '#001E50'
  },
};

export const loadPdfMakeFonts = () => {
  pdfMake.fonts = {
    acura: {
      normal: "acura.ttf",
      bold: "acura.ttf",
      italics: "acura.ttf",
      bolditalics: "acura.ttf"
    },
    audi: {
      normal: "audi.ttf",
      bold: "audi.ttf",
      italics: "audi.ttf",
      bolditalics: "audi.ttf"
    },
    audi_2021: {
      normal: "audi_2021.ttf",
      bold: "audi_2021.ttf",
      italics: "audi_2021.ttf",
      bolditalics: "audi_2021.ttf"
    },
    bmw: {
      normal: "bmw.ttf",
      bold: "bmw.ttf",
      italics: "bmw.ttf",
      bolditalics: "bmw.ttf"
    },
    chrysler: {
      normal: "chrysler.ttf",
      bold: "chrysler.ttf",
      italics: "chrysler.ttf",
      bolditalics: "chrysler.ttf"
    },
    consumer: {
      normal: "generic.ttf",
      bold: "generic.ttf",
      italics: "generic.ttf",
      bolditalics: "generic.ttf"
    },
    ford: {
      normal: "ford.ttf",
      bold: "ford.ttf",
      italics: "ford.ttf",
      bolditalics: "ford.ttf"
    },
    fungroup: {
      normal: "fungroup.ttf",
      bold: "fungroup.ttf",
      italics: "fungroup.ttf",
      bolditalics: "fungroup.ttf"
    },
    generic: {
      normal: "generic.ttf",
      bold: "generic.ttf",
      italics: "generic.ttf",
      bolditalics: "generic.ttf"
    },
    gmcanada: {
      normal: "gmcanada.ttf",
      bold: "gmcanada.ttf",
      italics: "gmcanada.ttf",
      bolditalics: "gmcanada.ttf"
    },
    honda: {
      normal: "honda.ttf",
      bold: "honda.ttf",
      italics: "honda.ttf",
      bolditalics: "honda.ttf"
    },
    hyundai: {
      normal: "hyundai.ttf",
      bold: "hyundai.ttf",
      italics: "hyundai.ttf",
      bolditalics: "hyundai.ttf"
    },
    integra: {
      normal: "integra.ttf",
      bold: "integra.ttf",
      italics: "integra.ttf",
      bolditalics: "integra.ttf"
    },
    kia: {
      normal: "kia.ttf",
      bold: "kia.ttf",
      italics: "kia.ttf",
      bolditalics: "kia.ttf"
    },
    kia_2021: {
      normal: "kia_2021.ttf",
      bold: "kia_2021.ttf",
      italics: "kia_2021.ttf",
      bolditalics: "kia_2021.ttf"
    },
    lexus: {
      normal: "lexus.ttf",
      bold: "lexus.ttf",
      italics: "lexus.ttf",
      bolditalics: "lexus.ttf"
    },
    mazda: {
      normal: "mazda.ttf",
      bold: "mazda.ttf",
      italics: "mazda.ttf",
      bolditalics: "mazda.ttf"
    },
    mazdatransition: {
      normal: "mazda.ttf",
      bold: "mazda.ttf",
      italics: "mazda.ttf",
      bolditalics: "mazda.ttf"
    },
    mecanipneu: {
      normal: "mecanipneu.ttf",
      bold: "mecanipneu.ttf",
      italics: "mecanipneu.ttf",
      bolditalics: "mecanipneu.ttf"
    },
    mercedes: {
      normal: "mercedes.ttf",
      bold: "mercedes.ttf",
      italics: "mercedes.ttf",
      bolditalics: "mercedes.ttf"
    },
    mercedes_2022: {
      normal: "mercedes.ttf",
      bold: "mercedes.ttf",
      italics: "mercedes.ttf",
      bolditalics: "mercedes.ttf"
    },
    mercedestransition: {
      normal: "mercedes.ttf",
      bold: "mercedes.ttf",
      italics: "mercedes.ttf",
      bolditalics: "mercedes.ttf"
    },
    mini: {
      normal: "mini.ttf",
      bold: "mini.ttf",
      italics: "mini.ttf",
      bolditalics: "mini.ttf"
    },
    mrlube: {
      normal: "mrlube.ttf",
      bold: "mrlube.ttf",
      italics: "mrlube.ttf",
      bolditalics: "mrlube.ttf"
    },
    pneuselect: {
      normal: "pneuselect.ttf",
      bold: "pneuselect.ttf",
      italics: "pneuselect.ttf",
      bolditalics: "pneuselect.ttf"
    },
    setoyota: {
      normal: "setoyota.ttf",
      bold: "setoyota.ttf",
      italics: "setoyota.ttf",
      bolditalics: "setoyota.ttf"
    },
    subaru: {
      normal: "subaru.ttf",
      bold: "subaru.ttf",
      italics: "subaru.ttf",
      bolditalics: "subaru.ttf"
    },
    tirecraft: {
      normal: "tirecraft.ttf",
      bold: "tirecraft.ttf",
      italics: "tirecraft.ttf",
      bolditalics: "tirecraft.ttf"
    },
    toyota: {
      normal: "toyota.ttf",
      bold: "toyota.ttf",
      italics: "toyota.ttf",
      bolditalics: "toyota.ttf"
    },
    toyotausa: {
      normal: "toyotausa.ttf",
      bold: "toyotausa.ttf",
      italics: "toyotausa.ttf",
      bolditalics: "toyotausa.ttf"
    },
    volkswagen: {
      normal: "volkswagen.ttf",
      bold: "volkswagen.ttf",
      italics: "volkswagen.ttf",
      bolditalics: "volkswagen.ttf"
    },
    volkswagen_2020: {
      normal: "volkswagen_2020.ttf",
      bold: "volkswagen_2020.ttf",
      italics: "volkswagen_2020.ttf",
      bolditalics: "volkswagen_2020.ttf"
    },
  };
};

export const getStyles = () => {
  return (
    {
      headerLogo: {
        width: 600,
        height: 75,
        absolutePosition: { x: 0, y: 0 }
      },
      headerCustomLogo: {
        absolutePosition: { x: 100, y: 25 }
      },
      title: {
        color: '#000000',
        fontSize: 22,
        margin: [ 0, 0, 10, 0 ],
      },
      titleInfo: {
        color: '#000000',
        fontSize: 17,
        margin: [ 0, 0, 10, 0 ],
      },
      text: {
        color: '#000000',
        fontSize: 9,
        margin: [ 0, 5, 0, 0 ],
      },
      table: {
        margin: [ -17.5, 10, -17.5, 0 ]
      },
      tableHead: {
        margin: [ 0, 5, 0, 5 ],
        fontSize: 16,
        bold: true
      },
      tableBody: {
        fontSize: 15,
      },
      vehicleTitle: {
        color: '#000000',
        fontSize: 13,
        margin: [ 0, 0, 10, 0 ],
      },
      titleTable: {
        margin: [ 9, -5, 9, 7.5 ],
      },
      guideTable: {
        margin: [ 0, 2.5, 0, 2.5 ],
      },
    }
  );
};

export const templateHdrTxt = (hasCustomLogo) => {
  if (hasCustomLogo) {
    return "#000000";
  } else if (templateStyle.hasOwnProperty(getAppTemplate())) {
    return templateStyle[getAppTemplate()].headerTextColor;
  } else {
    return templateStyle["generic"].headerTextColor;
  }
};

export const templateSltClr = () => {
  if (templateStyle.hasOwnProperty(getAppTemplate())) {
    return templateStyle[getAppTemplate()].selectedColor;
  } else {
    return templateStyle["generic"].selectedColor;
  }
};

export const templateBtnClr = () => {
  if (templateStyle.hasOwnProperty(getAppTemplate())) {
    return templateStyle[getAppTemplate()].buttonColor;
  } else {
    return templateStyle["generic"].buttonColor;
  }
};

export const encodeBase64List = (list, callback) => {
  for (var i in list) {
    if (!isEmpty(list[i])) encodeBase64(list[i], callback);
    else callback('');
  }
};

export const buildCustomerInformation = () => {
  var dictionary = languageStore.getDictionary();

  var customerInformation = [], firstName = '', lastName = '', email = '', phone = '', notes = '';
  if (document.getElementById("formFirstName") && !isEmpty(document.getElementById("formFirstName").value)) firstName = document.getElementById("formFirstName").value;
  if (document.getElementById("formLastName") && !isEmpty(document.getElementById("formLastName").value)) lastName = document.getElementById("formLastName").value;
  if (document.getElementById("formEmail") && !isEmpty(document.getElementById("formEmail").value)) email = document.getElementById("formEmail").value;
  if (document.getElementById("formPhone") && !isEmpty(document.getElementById("formPhone").value)) phone = document.getElementById("formPhone").value;
  if (document.getElementById("formNotes") && !isEmpty(document.getElementById("formNotes").value)) notes = document.getElementById("formNotes").value;

  if (!isEmpty(firstName)) {
    customerInformation.push({
      text: dictionary.wishlist.modal.firstName + ": " + firstName,
      style: 'text'
    });
  }
  if (!isEmpty(lastName)) {
    customerInformation.push({
      text: dictionary.wishlist.modal.lastName + ": " + lastName,
      style: 'text'
    });
  }
  if (!isEmpty(email)) {
    customerInformation.push({
      text: dictionary.wishlist.modal.email + ": " + email,
      style: 'text'
    });
  }
  if (!isEmpty(phone)) {
    customerInformation.push({
      text: dictionary.wishlist.modal.phone + ": " + phone,
      style: 'text'
    });
  }
  if (!isEmpty(notes)) {
    customerInformation.push({
      text: dictionary.wishlist.modal.notes + ": " + notes,
      style: 'text'
    });
  }

  return (
    {
      margin: [ -17.5, 0, -17.5, 0 ],
      stack: customerInformation
    }
  );
};

export const buildLegalFooter = (isGuide = false) => {
  var language = getAppLanguage(), footerObject = applicationStore.data.dealerInfo ? applicationStore.data.dealerInfo.legalFooter : null, footer = "";
  if (footerObject && !isEmpty(footerObject[language])) {
    footer = _.trim(footerObject[language]);
  }
  return (
    {
      margin: isGuide ? [ 0, 25, 0, 0 ] : [ -17.5, 10, -17.5, 0 ],
      fontSize: 8,
      width: '*',
      alignment: 'justify',
      lineHeight: 1,
      text: footer,
      color: isGuide ? '#FFFFFF' : '#000000',
    }
  );
};

export const buildHeader = (dealerInfo) => {
  return (
    function(currentPage) {
      if (currentPage === 1) {
        return buildPageHeader(dealerInfo);
      } else {
        return "";
      }
    }
  );
};

const buildPageHeader = (dealerInfo) => {
  var hasCustomLogo = !isEmpty(applicationStore.data.dealerRetailSiteCustomLogo) && getAppTemplate() !== 'mazdatransition' ? true : false, dealerAddressLine = "";
  if (dealerInfo.city) dealerAddressLine = dealerInfo.city;
  if (dealerInfo.province) dealerAddressLine += (isEmpty(dealerAddressLine) ? "" : ", ") + dealerInfo.province;
  if (dealerInfo.country) dealerAddressLine += (isEmpty(dealerAddressLine) ? "" : ", ") + dealerInfo.country;
  var logoStyle = !hasCustomLogo ? 'headerLogo' : 'headerCustomLogo';

  if (!darkHeaderTemplate()) {
    return (
      {
        table: {
          widths: ['75%', '25%'],
          body: [
            [
              {
                image: 'logo',
                width: !hasCustomLogo ? 450 : 166,
                absolutePosition: { x: 5, y: 5 }
              },
              {
                margin: [0, 0, 5, 0],
                stack: [
                  {
                    text: !isEmpty(dealerInfo.name) ? dealerInfo.name : "",
                    color: templateHdrTxt(),
                    fontSize: 9,
                    alignment: 'right'
                  },
                  {
                    text: !isEmpty(dealerInfo.address) ? dealerInfo.address : "",
                    color: templateHdrTxt(),
                    fontSize: 9,
                    alignment: 'right'
                  },
                  {
                    text: !isEmpty(dealerAddressLine) ? dealerAddressLine : "",
                    color: templateHdrTxt(),
                    fontSize: 9,
                    alignment: 'right'
                  }
                ]
              }
            ]
          ]
        },
        layout: {
          hLineWidth: function () { return 0 },
          vLineWidth: function () { return 0 },
          hLineColor: function () { return 0 },
          vLineColor: function () { return '#FFFFFF' },
          fillColor: function () { return '#FFFFFF' }
        }
      }
    );
  } else {
    return (
      {
        stack: [
          {
            image: 'logo',
            style: logoStyle,
            absolutePosition: { x: 0, y: 0 }
          },
          {
            text: !isEmpty(dealerInfo.name) ? applyPseudoMargin(dealerInfo.name) : "",
            color: !hasCustomLogo ? templateStyle[getAppTemplate()].headerTextColor : "#000000",
            fontSize: 9,
            alignment: 'right',
            absolutePosition: { y: 10 }
          },
          {
            text: !isEmpty(dealerInfo.address) ? applyPseudoMargin(dealerInfo.address) : "",
            color: !hasCustomLogo ? templateStyle[getAppTemplate()].headerTextColor : "#000000",
            fontSize: 9,
            alignment: 'right',
            absolutePosition: { y: 20 }
          },
          {
            text: !isEmpty(dealerAddressLine) ? applyPseudoMargin(dealerAddressLine) : "",
            color: !hasCustomLogo ? templateStyle[getAppTemplate()].headerTextColor : "#000000",
            fontSize: 9,
            alignment: 'right',
            absolutePosition: { y: 30 }
          }
        ]
      }
    );
  }
};

const applyPseudoMargin = (text) => {
  return text + "   " + String.fromCharCode('&zwnj;');
};

const darkHeaderTemplate = () => {
  switch(getAppTemplate()) {
    case 'mazda':
    case 'mercedes':
    case "mercedes_2022":
    case 'mercedestransition':
      return true;
    default:
      return false;
  }
};

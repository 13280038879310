import { applicationActions } from "../../actions/ApplicationActions.js";
import { RouterStates } from "../../constants/RouterStates.js";

import { getTabIndex, money_format } from "../../service/UtilityService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");

export class WishlistReviewSummaryTable extends I18nComponent {
  render() {
    if (this.props.hasCartItems) {
      return (
        <table className="total">
          <tbody className="total-tbody">
            {this.renderSubtotal()}
            {this.renderTax()}
            {this.renderTotalForPrint()}
            {this.renderTotal()}
          </tbody>
        </table>
      );
    } else {
      return (
        <table className="total">
          <tbody className="total-tbody">
            <tr className="total-row-total">
              <td>{this.t("wishlist.cartIsEmpty")}</td>
              <td>
                <button className="btn" onClick={this.onGoHome}>
                  {this.t("appointmentForm.startOver")}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
  }

  onGoHome = () => {
    applicationActions.changeRouterStateDirectly(RouterStates.HOMEPAGE);
  };

  renderSubtotal = () => {
    var date = new Date(this.props.referenceDate);
    var subtotal = money_format(this.props.subtotal);
    return (
      <tr className="total-row-subtotal">
        <td
          className="total-row-subtotal-first"
          tabIndex={getTabIndex()}
          aria-label={
            this.t("wishlist.reference") + " #" + this.props.referenceNumber
          }
        >
          <div className="summary-table-number"></div>
          <div className="summary-table-date"></div>
          <div className="summary-table-time"></div>
        </td>
        <td
          className="item-name-optional"
          tabIndex={getTabIndex()}
          aria-label={this.t("shoppingCartView.subtotal")}
        >
          <span>{this.t("shoppingCartView.subtotal")}:</span>
        </td>
        <td></td>
        <td
          className="price total"
          tabIndex={getTabIndex()}
          aria-label={subtotal}
        >
          <span className="total-row-value">{subtotal}</span>
        </td>
      </tr>
    );
  };

  renderTax = () => {
    if (this.props.showTax) {
      var taxtotal = money_format(this.props.taxtotal);
      return (
        <tr className="total-row-tax">
          <td className="total-row-tax-first"></td>
          <td
            className="item-name-optional"
            tabIndex={getTabIndex()}
            aria-label={this.t("shoppingCartView.tax").replace(
              "[]",
              this.props.tax
            )}
          >
            <span>{this.t("quote.tax")}</span>
            <span>({this.props.tax}%):</span>
          </td>
          <td></td>
          <td className="price" tabIndex={getTabIndex()} aria-label={taxtotal}>
            <span className="total-row-value">{taxtotal}</span>
          </td>
        </tr>
      );
    } else {
      return false;
    }
  };

  renderTotal = () => {
    if (this.props.showTotal) {
      var total = money_format(this.props.total);
      return (
        <tr className="total-row-total has-bold no-print">
          <td className="total-row-total-first"></td>
          <td
            className="item-name-optional"
            tabIndex={getTabIndex()}
            aria-label={this.t("shoppingCartView.total")}
          >
            <span>{this.t("shoppingCartView.total")}:</span>
          </td>
          <td></td>
          <td
            className="price total"
            tabIndex={getTabIndex()}
            aria-label={total}
          >
            <span className="total-row-value">{total}</span>
          </td>
        </tr>
      );
    } else {
      return false;
    }
  };

  renderTotalForPrint = () => {
    if (this.props.showTotal) {
      var total = money_format(this.props.total);
      return (
        <tr className="total-row-total print-only">
          <td className="total-row-total-first"></td>
          <td
            className="item-name-optional"
            tabIndex={getTabIndex()}
            aria-label={this.t("shoppingCartView.total")}
          >
            <span>{this.t("shoppingCartView.total")}:</span>
          </td>
          <td></td>
          <td
            className="price total"
            tabIndex={getTabIndex()}
            aria-label={total}
          >
            <span className="total-row-value">{total}</span>
          </td>
        </tr>
      );
    } else {
      return false;
    }
  };
}

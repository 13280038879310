import { globalState } from "../GlobalState.js";

import { applicationStore } from "../stores/ApplicationStore.js";
import { applicationActions } from "../actions/ApplicationActions.js";
import { shoppingCartViewStore } from "../stores/ShoppingCartViewStore.js";
import { shoppingCartStore } from "../stores/ShoppingCartStore.js";
import { shoppingCartActions } from "../actions/ShoppingCartActions.js";
import { modalStore } from "../stores/ModalStore.js";
import { breadcrumbsStore } from "../stores/BreadcrumbsStore.js";
import { productListStore } from "../stores/ProductListStore.js";

import { Router } from "../stores/Router.js";

import { RouterStates } from "../constants/RouterStates.js";
import { ProductTypes } from "../constants/ProductTypes.js";

import Breadcrumbs from "./breadcrumbs/Breadcrumbs.jsx";

import SupportPane from "./support/SupportPane.jsx";

import ShoppingCart from "./cart/ShoppingCart.jsx";

import ShoppingCartDetail from "./cart/ShoppingCartDetail.jsx";

import WishlistFormModal from "./cart/WishlistFormModal.jsx";

import { WishlistDealerFormModal } from "./cart/WishlistDealerFormModal.jsx";

import { WishlistAppointmentIntegrationModal } from "./cart/WishlistAppointmentIntegrationModal.jsx";

import Homepage from "./homepage/Homepage.jsx";

import { ConsumerHomepage } from "./homepage/ConsumerHomepage.jsx";
import Landingpage from "./homepage/Landingpage.jsx";
import { ContactDealer } from "./homepage/ContactDealer.jsx";
import { LoadingPage } from "./homepage/LoadingPage.jsx";
import { NoConnectionPage } from "./homepage/NoConnectionPage.jsx";
import { OutdatedApplicationVersion } from "./homepage/OutdatedApplicationVersion.jsx";
import LoginDialog from "./login/LoginDialog.jsx";
import LoginDialogRefactored from "./login/LoginDialogRefactored.jsx";
import { ForceLoginDialog } from "./login/ForceLoginDialog.jsx";
import WarningModal from "./productlist/WarningModal.jsx";
import ProductDetail from "./productdetail/ProductDetail.jsx";
import VehicleSelection from "./vehicleselection/VehicleSelection.jsx";
import SearchModal from "./search/SearchModal.jsx";
import { SearchModalFullScreen } from "./search/SearchModalFullScreen.jsx";
import { RecaptchaModal } from "./recaptcha/RecaptchaModal.jsx";
import { NationalPartStock } from "./partinfomodal/NationalPartStock.jsx";
import { NationalPartPrice } from "./partinfomodal/NationalPartPrice.jsx";
import { DictionaryViewer } from "./overview/DictionaryViewer.jsx";
import { SearchBrandsBySeason } from "./search/SearchBrandsBySeason.jsx";
import { SearchBrandsByBrandId } from "./search/SearchBrandsByBrandId.jsx";
import SearchModelsBySeason from "./search/SearchModelsBySeason.jsx";
import SearchModelsByBrand from "./search/SearchModelsByBrand.jsx";
import SearchTire from "./search/SearchTire.jsx";

import CartModal from "./cart/CartModal.jsx";

import ProductListRefactor from "./product/ProductListRefactor.jsx";

import { Popover } from "./ux/Popover.jsx";
import { Image } from "./ux/Image.jsx";
import { Modal } from "./ux/Modal.jsx";

import ProvinceSelection from "./dealerselection/ProvinceSelection.jsx";
import { GuestAccountCreationModal } from "./login/GuestAccountCreationModal.jsx";
import ForgotPasswordModal from "./login/ForgotPasswordModal.jsx";
import EmailPromotionModal from "./productlist/EmailPromotionModal.jsx";

import WishlistReview from "./cart/WishlistReview.jsx";
import WishlistModal from "./cart/WishlistModal.jsx";
import { PrintWishlist } from "./cart/PrintWishlist.jsx";
import { PricingAnalysis } from "./quote/PricingAnalysis.jsx";
import { PricingIncentive } from "./quote/PricingIncentive.jsx";
import QuoteReport from "./quote/QuoteReport.jsx";
import OrderReport from "./quote/OrderReport.jsx";
import { NTDOrderModal } from "./cart/NTDOrderModal.jsx";
import DealerSelection from "./dealerselection/DealerSelection.jsx";
import { DealerSelectionLegacy } from "./dealerselection/DealerSelectionLegacy.jsx";
import { ComparisonPage } from "./productlist/ComparisonPage.jsx";
import { getLoginData } from "../service/LoginService";

import {
  getCustomerAuthenticationName,
  isCustomerAuthentication,
  isPricingAnalysisEnabled,
  isUserLoggedIn,
  tokenIsAuthenticated,
} from "../api/AuthService";

import { styleHeaderLogo } from "../service/StyleService";
import { getTestId, TEST_LIST } from "../service/TestingService";
import { renderMarkAsCompleteModal } from "../service/WishlistService";
import { getTemplateLogo, renderTemplateLogo } from "../service/LogoService";
import {
  renderCampaignDealerInfo,
  renderSiteAdvancedFooter,
  renderDealerSupportInfo,
  renderSupportInfo,
} from "../service/RenderService";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  enableAdobeLaunch,
  runAdobeServicePageView,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
  setAdobeLauncherDigitalData,
} from "../service/AdobeLaunchService";
import {
  isEmpty,
  getTabIndex,
  getDealerInfo,
  getDealerContactInfo,
  getShowDealerInfo,
  allowHeaderNavigation,
  sizeOf,
  money_format,
  isDealerSite,
  isNationalSite,
  isGroupSite,
  isConsumerSite,
  isMobile,
  isTablet,
  nationalSiteHasDealerSelected,
  getDealerName,
  checkSiteHasCampaign,
  getCampaignName,
  enableAodaHighlighting,
  updateBodyForExplorerPrint,
  getAppLanguage,
  skipBreadcrumbsLoad,
  isIEBrowser,
  getPaybrightUrl,
  getBookingUrl,
  getRouterState,
  getDealerContactPhone,
  getDealerContactEmail,
  getDealerContactWebsite,
  isCarouselBreak,
  isDesktop,
} from "../service/UtilityService";
import { connect } from "react-redux";
import {
  changeLang,
  toggleLoginDialog,
  toggleSearch,
  toggleSupport,
  forgotPasswordToggle,
  setLogin,
  toggleAuthenticationStateModal,
} from "../redux/features/applicationInfo.js";
import { showQuoteReport, showLeadReport } from "../redux/features/quote.js";
import { loadDealerInfo } from "../redux/features/dealer.js";
import {
  renderSavingToggleState,
  signUpRequestModal,
} from "../redux/features/productList.js";
import {
  cartItemSeen,
  emptyShoppingCart,
  getNewCart,
  selectCartItems,
  toggleCart,
  toggleShoppingModal,
} from "../redux/features/shoppingCart.js";
import { I18nComponent } from "../i18n/I18nComponent.jsx";

import _ from "lodash";
// import $ from "jquery";
import React from "react";
import ReactDOM from "react-dom";
import Immutable from "immutable";
import Cx from "classnames";

const TRANSITION_FADE = "content-transition-fade";

const LOADING_IMAGE_SKINNY = "loading-image-skinny";

const SCREENMODES = {
  REGULAR: "regular",
  IFRAME: "iframe",
  GUIDE: "guide",
};

class ApplicationRefactor extends I18nComponent {
  componentWillMount() {
    globalState
      .select("applicationState", "routerState")
      .on("update", this.onChange);
    applicationStore.addChangeListener(this.onChange);
    modalStore.addChangeListener(this.onChange);
    shoppingCartStore.addChangeListener(this.onChange);
    productListStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    clearInterval(this.state.loadingSuffixFunc);
    this.setState({ loadingSuffixFunc: function () {} });
  }

  componentDidMount() {
    styleHeaderLogo();
    enableAodaHighlighting();
    this.runLoadingIntervalDisplay();

    if (enableAdobeLaunch()) {
      setAdobeDigitalData(setAdobeLauncherDigitalData());
    }

    if (this.state.login && !tokenIsAuthenticated()) {
      setTimeout(() => {
        this.props.dispatch(loadDealerInfo());
        applicationActions.loadDealerInfo();
      }, 100);
    }
    switch (applicationStore.data.defaultTemplate) {
      case "audi_2021":
      case "kia_2021":
        window.addEventListener("scroll", (event) => {
          let scroll = window.scrollY;
          this.setState({ scrollY: window.scrollY });
        });
    }
    //todo
    // switch(getCookieLanguage()) {
    //   case 'en':
    //   case 'fr':
    //     if (this.state.lang !== getCookieLanguage()) this.changeLang(getCookieLanguage());
    // }
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.state.loggedIn !== nextState.loggedIn) {
      if (this.state.loggedIn) {
        this.props.dispatch(loadDealerInfo());
        applicationActions.loadDealerInfo();
      } else {
        this.forceUpdate();
      }
    }
  }

  updateSuffix = (suffix) => {
    suffix = suffix + ".";
    if (sizeOf(suffix) === 4) suffix = "";
    return suffix;
  };

  incrementCounter = (counter, limit) => {
    if (counter >= limit) return 0;
    else return counter;
  };

  updateCount = () => {
    var counter = this.state.loadingCounter + 1,
      incrementCounter = this.incrementCounter;
    window.loadingSuffix = this.updateSuffix(window.loadingSuffix);
    this.setState({ loadingCounter: incrementCounter(counter, 3) });
  };

  runLoadingIntervalDisplay = () => {
    var updateCount = this.updateCount;
    this.setState({
      loadingSuffixFunc: (function () {
        setInterval(() => {
          updateCount();
        }, 1000);
      })(),
    });
  };

  componentDidUpdate(lastProps, lastState) {
    setInitialTabIndex();
    updateBodyForExplorerPrint();
    if (this.refs.btnStartNewQuote) {
      ReactDOM.findDOMNode(this.refs.btnStartNewQuote).focus();
    }
    if (this.props.total !== lastProps.total) {
      this.onChange();
    }
    if (this.state.loggedIn !== lastState.loggedIn) {
      this.onChange();
    }
    if (this.props.loginHasExpired !== lastProps.loginHasExpired) {
      this.onChange();
    }

    if (
      !isEmpty(this.state.routerState) &&
      this.state.routerState !== lastState.routerState
    ) {
      if (this.state.routerState !== RouterStates.CATEGORY) {
        runAdobeServicePageView();
      }
    }
    if (this.state.routerState !== lastState.routerState) {
      this.onChange();
    }
    if (this.state.lang !== lastState.lang) {
      setTimeout(() => {
        runAdobeServicePageView();
      }, 100);
    }
    if (this.props.applicationLanguage !== lastProps.applicationLanguage) {
      this.onChange();
      setTimeout(() => {
        runAdobeServicePageView();
      }, 100);
    }
    if (
      this.state.showMobileAlwaysMenu &&
      this.state.loginTextDialogShown &&
      !lastState.loginTextDialogShown
    ) {
      var login = getLoginData();
      if (!login.error) {
        document.getElementById("menu-email").value = login.usr;
        document.getElementById("menu-password").value = login.pwd;
      }
    }
  }

  render() {
    if (this.state.siteDisabled || this.state.errorPage) {
      return this.renderNoConnection(this.state.errorPage);
    } else if (this.state.campaignIsInactive) {
      return this.renderCampaignIsInactive();
    } else if (
      this.state.routerState === RouterStates.PRINT_WISHLIST ||
      this.state.routerState === RouterStates.PRINT_WISHLIST_TEST
    ) {
      return this.renderPrintWishlist();
    } else if (
      this.state.routerState === RouterStates.COMPARISON ||
      this.state.routerState === RouterStates.PRINT_COMPARISON ||
      this.state.routerState === RouterStates.PRINT_COMPARISON_FROM_TIRESIZE
    ) {
      return this.renderPrintComparison();
    } else if (this.state.appRunningOutdatedVersion) {
      return this.renderOutdatedApp();
    } else if (this.state.routerState === RouterStates.WEBSERVICE_ERROR) {
      return this.renderNoConnection(
        parseInt(applicationStore.data.webserviceFailedHttpStatus)
      );
    } else if (this.state.longInitialDataLoad) {
      return (
        <root>
          {this.renderAODAHeader()}
          {this.renderLoadingScreen()}
          {this.renderHeader()}
          <box className={this.getBodyClass()}>
            <box className="body-container">
              <box className={this.getContentWrapperClass()}></box>
            </box>
          </box>
          {this.renderFooter()}
        </root>
      );
    } else {
      return (
        <root>
          {this.renderAODAHeader()}

          {this.renderLoadingScreen()}
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}

          {this.renderSearchModal()}
          {this.renderCaptchaModal()}
          {this.renderTireSizeHelperModal()}

          {this.renderPricingAnalysisModal()}
          {this.renderPricingIncentiveModal()}
          {this.renderQuoteReportModal()}
          {this.renderOrderReportModal()}
          {this.renderPartStockModal()}
          {this.renderPartPriceModal()}

          {this.renderGuestAccountCreationModal()}
          {this.renderForgotPasswordModal()}

          {this.renderShoppingCartDetail()}
          {this.renderNTDOrderModal()}

          {this.renderProductWarning()}

          {this.returnToDealerSelectionWarningModal()}
          {this.renderExternalLinkWarningModal()}
          {this.renderStartNewQuoteModal()}
          {this.renderContactDealerModal()}
          {this.renderXtimeLinkFailedModal()}

          {this.renderWishlistFormModal()}
          {this.renderWishlistDealerFormModal()}
          {this.renderWishlistAppointmentIntegrationModal()}

          {this.renderMarkAsCompleteModal()}

          {this.renderPromotionModal()}

          {this.loginExpiredModal()}
          {this.authenticationStateModal()}
          {this.renderDealerForceLoginModal()}

          {this.renderSupportPanel()}
          {this.renderMobileAlwaysMenu()}
        </root>
      );
    }
  }

  renderLoadingScreen = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "volkswagen":
        return (
          <stage className={this.getLoadingScreenClass()}>
            <curtain />
            <float>
              {this.renderLoadingImage()}
              <h2 className="loading">
                {this.t("loadingText") + window.loadingSuffix}
              </h2>
            </float>
          </stage>
        );
      default:
        return (
          <stage className={this.getLoadingScreenClass()}>
            <curtain />
            <float className="circle">
              <div className="loader">
                <div></div>
                <div></div>
              </div>
            </float>
          </stage>
        );
    }
  };

  getLoadingScreenClass = () => {
    let shouldShowLoader;
    if (!this.props.reduxCall) {
      return "loading";
    } else {
      setTimeout(() => {
        shouldShowLoader = false;
      }, 5000); // 2 seconds delay before removing the "loading" class

      return shouldShowLoader ? "loading" : "loading fade-out";
    }
  };

  getLoadingImage = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "volkswagen":
        return "/static/img/volkswagen/gifs/loading.gif";
      default:
        return "/static/gifs/loader-sm.gif";
    }
  };

  getCustomLoadingImage = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "volkswagen":
        return true;
      default:
        return false;
    }
  };

  renderLoadingImage = () => {
    if (this.getCustomLoadingImage()) {
      return (
        <box className="customloading">
          <img
            className={this.getCustomLoadingImageClass(
              parseInt(this.state.loadingCounter) + 2
            )}
            src={this.getLoadingImage()}
          />
          <img
            className={this.getCustomLoadingImageClass(
              parseInt(this.state.loadingCounter) + 1
            )}
            src={this.getLoadingImage()}
          />
          <img
            className={this.getCustomLoadingImageClass(
              parseInt(this.state.loadingCounter)
            )}
            src={this.getLoadingImage()}
          />
        </box>
      );
    } else {
      return <img src={this.getLoadingImage()} />;
    }
  };

  getCustomLoadingImageClass = (index) => {
    switch (index % 3) {
      case 0:
        return "first";
      case 1:
        return "second";
      case 2:
        return "third";
      default:
        return "";
    }
  };

  renderHeader = () => {
    if (applicationStore.data.screenMode === SCREENMODES.IFRAME) {
      return (
        <fixed className={this.getFixedClassName()}>
          {this.renderFinancingBar()}
          {this.renderBreadcrumbs()}
          {this.renderLogin()}
          {this.renderShoppingCartModal()}
        </fixed>
      );
    } else if (applicationStore.data.screenMode === SCREENMODES.GUIDE) {
      return false;
    } else {
      switch (applicationStore.data.defaultTemplate) {
        case "audi":
          return this.renderLogoRight();
        case "audi_2021":
          return this.renderAudiHeader();
        case "bmw":
          return this.renderProductSelectionLeftHeader();
        case "hyundai":
          return this.renderNarrowHeader();
        case "mazda":
          return this.renderProductSelectionHeader();
        case "mercedes":
          return this.renderReducedHeader();
        case "mercedes_2022":
          return this.renderReducedHeader();
        case "mini":
          return this.renderHeaderSecondary();
        case "mrlube":
          return this.renderReducedHeaderSecondary();
        case "kia_2021":
        case "volkswagen_2020":
          return this.renderMobileAlwaysHeader();
        default:
          return this.renderLogoLeft();
      }
    }
  };

  getFixedClassName = () => {
    if (applicationStore.data.screenMode === SCREENMODES.IFRAME) {
      return "has-iframe";
    } else {
      return "";
    }
  };

  renderNarrowHeader = () => {
    return (
      <fixed className={this.getFixedClassName()}>
        {this.renderFinancingBar()}
        <box className="navigation narrow">
          <container className="side large">
            <column className="link" onClick={this.onGoHomepage}>
              {renderTemplateLogo()}
              <h2 className="title">{this.t("title")}</h2>
            </column>
            {isDealerSite() || nationalSiteHasDealerSelected() ? (
              <column className="dealername desktop">
                <container className="side">
                  <column>
                    <container className="side small no-left">
                      <h2>{getDealerName()}</h2>
                    </container>
                  </column>
                  {!isEmpty(getDealerContactInfo()) ? (
                    <box className="contactinfo">
                      <text>{getDealerContactInfo(true)}</text>
                    </box>
                  ) : (
                    false
                  )}
                  {nationalSiteHasDealerSelected() ? (
                    <column>
                      <a
                        tabIndex={getTabIndex()}
                        onClick={this.goToDealerSelection}
                      >
                        {"-"} {this.t("changeDealer")}
                      </a>
                    </column>
                  ) : (
                    false
                  )}
                </container>
              </column>
            ) : (
              false
            )}
            {nationalSiteHasDealerSelected() ? (
              <column className="dealername mobile">
                <container className="side">
                  <column>
                    <a
                      tabIndex={getTabIndex()}
                      onClick={this.goToDealerSelection}
                    >
                      {this.t("changeDealer")}
                    </a>
                  </column>
                </container>
              </column>
            ) : (
              false
            )}
            {isDealerSite() || nationalSiteHasDealerSelected() ? (
              <right>
                <box className="right">
                  <column>
                    <container className="side no-left">
                      <box className="pointer">
                        <i
                          id={getTestId(TEST_LIST.HEADER.SEARCH)}
                          onClick={this.onToggleSearch}
                          className="fa fa-search"
                        />
                      </box>
                    </container>
                  </column>
                  <column>
                    <container className="side no-left">
                      <box className="pointer">
                        <i
                          id={getTestId(TEST_LIST.HEADER.CART)}
                          onClick={this.onToggleCartBar}
                          className="fa fa-shopping-cart"
                        />
                      </box>
                    </container>
                  </column>
                  {isUserLoggedIn() ? (
                    <column>
                      <a
                        tabIndex={getTabIndex()}
                        onClick={this.onGoQuoteReport}
                      >
                        {this.t("quoteReportText")}
                      </a>
                    </column>
                  ) : (
                    false
                  )}
                  {isUserLoggedIn() ? (
                    <column>
                      <container className="side small">
                        <text>{"|"}</text>
                      </container>
                    </column>
                  ) : (
                    false
                  )}

                  {isUserLoggedIn() &&
                  isPricingAnalysisEnabled(
                    applicationStore.data.dealerEnablePricingAnalysis
                  ) ? (
                    <column>
                      <a
                        tabIndex={getTabIndex()}
                        onClick={this.onGoPricingAnalysis}
                      >
                        {this.t("pricingAnalysisText")}
                      </a>
                    </column>
                  ) : (
                    false
                  )}
                  {isUserLoggedIn() &&
                  isPricingAnalysisEnabled(
                    applicationStore.data.dealerEnablePricingAnalysis
                  ) ? (
                    <column>
                      <container className="side small">
                        <span>|</span>
                      </container>
                    </column>
                  ) : (
                    false
                  )}

                  <column>
                    <a
                      id={getTestId(TEST_LIST.HEADER.LANGUAGE)}
                      tabIndex={getTabIndex()}
                      onClick={
                        getAppLanguage() === "fr"
                          ? this.changeLang.bind(this, "en")
                          : this.changeLang.bind(this, "fr")
                      }
                    >
                      {this.t("languageSwitchShort")}
                    </a>
                  </column>
                  {isDealerSite() ? (
                    <column>
                      <container className="side small">
                        <text>{"|"}</text>
                      </container>
                    </column>
                  ) : (
                    false
                  )}
                  {isDealerSite() ? (
                    <column>
                      <a
                        id={
                          isUserLoggedIn()
                            ? getTestId(TEST_LIST.HEADER.LOGOUT)
                            : getTestId(TEST_LIST.HEADER.LOGIN)
                        }
                        tabIndex={getTabIndex()}
                        onClick={
                          isUserLoggedIn()
                            ? this.onLogOut
                            : this.onToggleLoginTextDialog
                        }
                      >
                        {isUserLoggedIn() ? this.t("logout") : this.t("login")}
                      </a>
                    </column>
                  ) : (
                    false
                  )}
                </box>
              </right>
            ) : (
              false
            )}
            {isNationalSite() && !nationalSiteHasDealerSelected() ? (
              <right>
                <box className="right">
                  <column>
                    <a
                      id={getTestId(TEST_LIST.HEADER.LANGUAGE)}
                      tabIndex={getTabIndex()}
                      onClick={
                        getAppLanguage() === "fr"
                          ? this.changeLang.bind(this, "en")
                          : this.changeLang.bind(this, "fr")
                      }
                    >
                      {this.t("languageSwitchShort")}
                    </a>
                  </column>
                </box>
              </right>
            ) : (
              false
            )}
          </container>
        </box>
        {this.renderBreadcrumbs()}
        {this.renderLogin()}
        {this.renderShoppingCart()}
        {this.renderShoppingCartModal()}
      </fixed>
    );
  };

  goToDealerSelection = () => {
    window.location.href =
      window.location.origin +
      "/" +
      "national" +
      "/" +
      applicationStore.data.defaultTemplate +
      "/" +
      getAppLanguage() +
      "?vehicle=" +
      this.getProductType() +
      "-" +
      encodeURIComponent(applicationStore.data.year) +
      "-" +
      encodeURIComponent(applicationStore.data.make) +
      "-" +
      encodeURIComponent(applicationStore.data.category) +
      "-" +
      encodeURIComponent(applicationStore.data.model) +
      "-" +
      encodeURIComponent(getAppLanguage());
  };

  getProductType = () => {
    switch (applicationStore.data.productType) {
      case ProductTypes.ACCESSORIES:
        return "accessories";
      case ProductTypes.ALLOY_WHEELS:
        return "alloy_wheels";
      case ProductTypes.TIRES:
        return "tires";
      case ProductTypes.PACKAGES:
        return "packages";
    }
  };

  renderProductSelectionHeader = () => {
    var getProductTypeName = this.getProductTypeName,
      getProductTypeSelection = this.getProductTypeSelection,
      searchIsActive = this.state.searchIsActiveFlag;
    return (
      <fixed className={this.getFixedClassName()}>
        {this.renderFinancingBar()}
        <box className="navigation">
          <column>
            <column className="link" onClick={this.onGoHomepage}>
              {renderTemplateLogo()}
            </column>
            <column className="desktop">
              <h2 className="title">{this.t("title").toUpperCase()}</h2>
            </column>
            {!isEmpty(getDealerName()) ? (
              <column className="desktop">
                <text className="name">{getDealerName()}</text>
              </column>
            ) : (
              false
            )}
          </column>
          {this.enableHeaderLinks() ? (
            <right className="custom-s1 gamdd">
              <column>
                <box className="links">
                  {Immutable.fromJS(applicationStore.data.productTypes).map(
                    function (producttype, index) {
                      return (
                        <column key={"product-select-" + index}>
                          <text
                            tabIndex={getTabIndex()}
                            className={
                              searchIsActive &&
                              String(applicationStore.data.searchProductType) ==
                                String(producttype)
                                ? "selected"
                                : "pointer"
                            }
                            onClick={getProductTypeSelection.bind(
                              null,
                              producttype
                            )}
                          >
                            {getProductTypeName(producttype).toUpperCase()}
                          </text>
                        </column>
                      );
                    }
                  )}
                </box>
              </column>
              <column>
                <i
                  id={getTestId(TEST_LIST.HEADER.CART)}
                  tabIndex={getTabIndex()}
                  onClick={this.onToggleCartModal}
                  className="fa fa-shopping-cart pointer"
                />
              </column>
            </right>
          ) : (
            false
          )}
          {isDealerSite() || nationalSiteHasDealerSelected() ? (
            <right className="custom-s1f">
              <column>
                <i
                  id={getTestId(TEST_LIST.HEADER.CART)}
                  tabIndex={getTabIndex()}
                  onClick={this.onToggleCartModal}
                  className="fa fa-shopping-cart pointer"
                />
              </column>
              <column>
                <span>|</span>
              </column>
              <column>
                <a
                  tabIndex={getTabIndex()}
                  onClick={
                    getAppLanguage() === "fr"
                      ? this.changeLang.bind(this, "en")
                      : this.changeLang.bind(this, "fr")
                  }
                >
                  {getAppLanguage() === "en" ? "FR" : "EN"}
                </a>
              </column>
            </right>
          ) : (
            false
          )}
        </box>
        {this.renderBreadcrumbs()}
        {this.renderLogin()}
        {this.props.toggleCart ? this.renderCartModal() : null}
      </fixed>
    );
  };

  renderProductSelectionLeftHeader = () => {
    var getProductTypeName = this.getProductTypeName,
      getProductTypeSelection = this.getProductTypeSelection,
      searchIsActive = this.state.searchIsActiveFlag;

    /*
    <column>
                <a tabIndex={getTabIndex()}
                   onClick={getAppLanguage() === 'fr' ? this.changeLang.bind(this, "en") : this.changeLang.bind(this, "fr")}>{getAppLanguage() === 'en' ? "FR" : "EN"}</a>
              </column>
              */

    return (
      <fixed className={this.getFixedClassName()}>
        {this.renderFinancingBar()}
        <box className={this.getProductSelectionHeaderClass()}>
          <container className="side large">
            {this.enableHeaderLinksLeft() ? (
              <column>
                <box className="links">
                  {Immutable.fromJS(applicationStore.data.productTypes).map(
                    function (producttype, index) {
                      return (
                        <column key={"product-select-" + index}>
                          <text
                            tabIndex={getTabIndex()}
                            className={
                              searchIsActive &&
                              String(applicationStore.data.searchProductType) ==
                                String(producttype)
                                ? "selected"
                                : "pointer"
                            }
                            onClick={getProductTypeSelection.bind(
                              null,
                              producttype
                            )}
                          >
                            {getProductTypeName(producttype).toUpperCase()}
                          </text>
                        </column>
                      );
                    }
                  )}
                </box>
              </column>
            ) : (
              false
            )}

            <right className="custom-s1">
              {isDealerSite() ? (
                <box className="links">
                  {isUserLoggedIn() &&
                  applicationStore.data.dealerEnablePricingAnalysis ===
                    "AUTH" ? (
                    <a
                      tabIndex={getTabIndex()}
                      onClick={this.onGoPricingAnalysis}
                    >
                      {this.t("pricingAnalysisText")}
                    </a>
                  ) : (
                    false
                  )}
                  {isUserLoggedIn() &&
                  applicationStore.data.dealerEnablePricingAnalysis ===
                    "AUTH" ? (
                    <span>|</span>
                  ) : (
                    false
                  )}
                  {isUserLoggedIn() ? (
                    <a tabIndex={getTabIndex()} onClick={this.onGoLeadReport}>
                      {this.t("leadReportText")}
                    </a>
                  ) : (
                    false
                  )}
                  {isUserLoggedIn() ? <span>|</span> : false}
                  {isUserLoggedIn() ? (
                    <a tabIndex={getTabIndex()} onClick={this.onGoQuoteReport}>
                      {this.t("quoteReportText")}
                    </a>
                  ) : (
                    false
                  )}
                  {isUserLoggedIn() ? <span>|</span> : false}
                  {isUserLoggedIn() &&
                  (applicationStore.data.dealerEnableEcommerceRetailSite ||
                    applicationStore.data.dealerAllowNtdConsumerPurchase ||
                    applicationStore.data.supportUserConsumerOrder) ? (
                    <a tabIndex={getTabIndex()} onClick={this.onGoOrderReport}>
                      {this.t("orderReportText")}
                    </a>
                  ) : (
                    false
                  )}
                  {isUserLoggedIn() &&
                  (applicationStore.data.dealerEnableEcommerceRetailSite ||
                    applicationStore.data.dealerAllowNtdConsumerPurchase) ? (
                    <span>|</span>
                  ) : (
                    false
                  )}
                  {isUserLoggedIn() ? (
                    <a
                      id={getTestId(TEST_LIST.HEADER.LOGOUT)}
                      tabIndex={getTabIndex()}
                      onClick={this.onLogOut}
                    >
                      {this.t("logout")}
                    </a>
                  ) : (
                    <a
                      id={getTestId(TEST_LIST.HEADER.LOGIN)}
                      tabIndex={getTabIndex()}
                      onClick={this.onToggleLoginTextDialog}
                    >
                      {this.t("login")}
                    </a>
                  )}
                  <span>|</span>
                  <a
                    id={getTestId(TEST_LIST.HEADER.LANGUAGE)}
                    tabIndex={getTabIndex()}
                    onClick={
                      getAppLanguage() === "fr"
                        ? this.changeLang.bind(this, "en")
                        : this.changeLang.bind(this, "fr")
                    }
                  >
                    {this.t("languageSwitch")}
                  </a>
                </box>
              ) : (
                false
              )}

              {isNationalSite() ? (
                <box className="links">
                  <a
                    id={getTestId(TEST_LIST.HEADER.LANGUAGE)}
                    tabIndex={getTabIndex()}
                    onClick={
                      getAppLanguage() === "fr"
                        ? this.changeLang.bind(this, "en")
                        : this.changeLang.bind(this, "fr")
                    }
                  >
                    {this.t("languageSwitch")}
                  </a>
                  {!isEmpty(this.getDealerName()) &&
                  this.enableDealerSelection() ? (
                    <span>|</span>
                  ) : (
                    false
                  )}
                  {this.enableDealerSelection() ? (
                    <a
                      tabIndex={getTabIndex()}
                      onClick={this.returnToDealerSelection}
                    >
                      {this.t("dealerSelection")}
                    </a>
                  ) : (
                    false
                  )}
                </box>
              ) : (
                false
              )}

              <column>
                {!isEmpty(getDealerName()) &&
                getRouterState() !== RouterStates.DEALER_SELECTION &&
                getRouterState() !== RouterStates.LANDING_PAGE ? (
                  <column className="desktop">
                    <text className="name">{getDealerName()}</text>
                    {!isEmpty(getDealerInfo(true)) ? (
                      <box className="contactinfo">
                        <text className="dealerinfo">
                          {getDealerInfo(true)}
                        </text>
                        {!isEmpty(getDealerContactInfo()) ? (
                          <text>{getDealerContactInfo()}</text>
                        ) : (
                          false
                        )}
                      </box>
                    ) : (
                      false
                    )}
                  </column>
                ) : (
                  false
                )}
              </column>
              {(isDealerSite() || nationalSiteHasDealerSelected()) &&
              getRouterState() !== RouterStates.DEALER_SELECTION ? (
                <column>
                  <column>
                    <i
                      id={getTestId(TEST_LIST.HEADER.CART)}
                      tabIndex={getTabIndex()}
                      onClick={this.onToggleCart}
                      className="fa fa-shopping-cart pointer"
                    />
                  </column>
                  <column>
                    <i
                      id={getTestId(TEST_LIST.HEADER.SEARCH)}
                      tabIndex={getTabIndex()}
                      onClick={this.onToggleSearch}
                      className="fa fa-search pointer"
                    />
                  </column>
                </column>
              ) : (
                false
              )}
              <column className="link" onClick={this.onGoHomepage}>
                {renderTemplateLogo()}
              </column>
            </right>
          </container>
        </box>
        {this.renderBreadcrumbs()}
        {this.renderLogin()}
        {this.renderShoppingCart()}
        {this.renderShoppingCartModal()}
      </fixed>
    );
  };

  getProductSelectionHeaderClass = () => {
    if (getRouterState() === RouterStates.DEALER_SELECTION) {
      return "navigation dealer-selection";
    } else {
      return "navigation";
    }
  };

  getProductTypeName = (producttype) => {
    switch (producttype) {
      case ProductTypes.TIRES:
        return this.t("productTypes.tires");
      case ProductTypes.ALLOY_WHEELS:
        return this.t("productTypes.wheels");
      case ProductTypes.ACCESSORIES:
        return this.t("productTypes.accessories");
      case ProductTypes.PACKAGES:
        return this.t("productTypes.packages");
    }
  };

  getProductTypeSelection = (producttype) => {
    switch (producttype) {
      case ProductTypes.TIRES:
        this.onSubmitSearch(ProductTypes.TIRES);
        break;
      case ProductTypes.ALLOY_WHEELS:
        this.onSubmitSearch(ProductTypes.ALLOY_WHEELS);
        break;
      case ProductTypes.ACCESSORIES:
        this.onSubmitSearch(ProductTypes.ACCESSORIES);
        break;
      case ProductTypes.PACKAGES:
        this.onSubmitSearch(ProductTypes.PACKAGES);
        break;
    }
  };

  onSubmitSearch = (producttype) => {
    applicationActions.setSearchProductTypeValue(producttype);
    setTimeout(() => {
      this.props.dispatch(toggleSearch(true));
      applicationActions.activateSearchFlag();
    }, 100);
  };

  renderReducedHeader = () => {
    return (
      <fixed className={this.getFixedClassName()}>
        {this.renderFinancingBar()}
        <box className="navigation reduced">
          <column className="link" onClick={this.onGoHomepage}>
            {renderTemplateLogo()}
            <h2 className="title">{this.t("title")}</h2>
          </column>
          {this.enableHeaderLinks() ? (
            <right>
              {isDealerSite() ? (
                <box className="links">
                  {isUserLoggedIn() &&
                  isPricingAnalysisEnabled(
                    applicationStore.data.dealerEnablePricingAnalysis
                  ) ? (
                    <a
                      tabIndex={getTabIndex()}
                      onClick={this.onGoPricingAnalysis}
                    >
                      {this.t("pricingAnalysisText")}
                    </a>
                  ) : (
                    false
                  )}
                  {isUserLoggedIn() &&
                  isPricingAnalysisEnabled(
                    applicationStore.data.dealerEnablePricingAnalysis
                  ) ? (
                    <span>|</span>
                  ) : (
                    false
                  )}
                  {isUserLoggedIn() ? (
                    <a tabIndex={getTabIndex()} onClick={this.onGoLeadReport}>
                      {this.t("leadReportText")}
                    </a>
                  ) : (
                    false
                  )}
                  {isUserLoggedIn() ? <span>|</span> : false}
                  {isUserLoggedIn() ? (
                    <a tabIndex={getTabIndex()} onClick={this.onGoQuoteReport}>
                      {this.t("quoteReportText")}
                    </a>
                  ) : (
                    false
                  )}
                  {isUserLoggedIn() ? <span>|</span> : false}
                  {isUserLoggedIn() &&
                  (applicationStore.data.dealerEnableEcommerceRetailSite ||
                    applicationStore.data.dealerAllowNtdConsumerPurchase ||
                    applicationStore.data.supportUserConsumerOrder) ? (
                    <a tabIndex={getTabIndex()} onClick={this.onGoOrderReport}>
                      {this.t("orderReportText")}
                    </a>
                  ) : (
                    false
                  )}
                  {isUserLoggedIn() &&
                  (applicationStore.data.dealerEnableEcommerceRetailSite ||
                    applicationStore.data.dealerAllowNtdConsumerPurchase) ? (
                    <span>|</span>
                  ) : (
                    false
                  )}
                  {isUserLoggedIn() ? (
                    <a
                      id={getTestId(TEST_LIST.HEADER.LOGOUT)}
                      tabIndex={getTabIndex()}
                      onClick={this.onLogOut}
                    >
                      {this.t("logout")}
                    </a>
                  ) : (
                    <a
                      id={getTestId(TEST_LIST.HEADER.LOGIN)}
                      tabIndex={getTabIndex()}
                      onClick={this.onToggleLoginTextDialog}
                    >
                      {this.t("login")}
                    </a>
                  )}
                  <span>|</span>
                  <a
                    id={getTestId(TEST_LIST.HEADER.LANGUAGE)}
                    tabIndex={getTabIndex()}
                    onClick={
                      getAppLanguage() === "fr"
                        ? this.changeLang.bind(this, "en")
                        : this.changeLang.bind(this, "fr")
                    }
                  >
                    {this.t("languageSwitch")}
                  </a>
                  <span>|</span>
                  <span className="bold">{this.getDealerName()}</span>
                </box>
              ) : (
                false
              )}
              {isNationalSite() ? (
                <box className="links">
                  <a
                    id={getTestId(TEST_LIST.HEADER.LANGUAGE)}
                    tabIndex={getTabIndex()}
                    onClick={
                      getAppLanguage() === "fr"
                        ? this.changeLang.bind(this, "en")
                        : this.changeLang.bind(this, "fr")
                    }
                  >
                    {this.t("languageSwitch")}
                  </a>
                  {!isEmpty(this.getDealerName()) ? <span>|</span> : false}
                  {!isEmpty(this.getDealerName()) ? (
                    <span className="bold">{this.getDealerName()}</span>
                  ) : (
                    false
                  )}
                  {!isEmpty(this.getDealerName()) &&
                  this.enableDealerSelection() ? (
                    <span>|</span>
                  ) : (
                    false
                  )}
                  {this.enableDealerSelection() ? (
                    <a
                      tabIndex={getTabIndex()}
                      onClick={this.returnToDealerSelection}
                    >
                      {this.t("dealerSelection")}
                    </a>
                  ) : (
                    false
                  )}
                </box>
              ) : (
                false
              )}
              {this.enableHeaderIcons() ? (
                <box className="right">
                  <column>
                    <i
                      id={getTestId(TEST_LIST.HEADER.SEARCH)}
                      onClick={this.onToggleSearch}
                      className="fa fa-search"
                    />
                  </column>
                  <column>
                    <box className="pointer">
                      <i
                        id={getTestId(TEST_LIST.HEADER.CART)}
                        onClick={this.onToggleCartBar}
                        className="fa fa-shopping-cart"
                      />
                      <text>{this.getCartCount()}</text>
                    </box>
                  </column>
                </box>
              ) : (
                false
              )}
            </right>
          ) : (
            <right>
              <box className="links">
                <a
                  id={getTestId(TEST_LIST.HEADER.LANGUAGE)}
                  tabIndex={getTabIndex()}
                  onClick={
                    getAppLanguage() === "fr"
                      ? this.changeLang.bind(this, "en")
                      : this.changeLang.bind(this, "fr")
                  }
                >
                  {this.t("languageSwitch")}
                </a>
              </box>
            </right>
          )}
        </box>
        {this.renderBreadcrumbs()}
        {this.renderLogin()}
        {this.renderShoppingCart()}
        {this.renderShoppingCartModal()}
      </fixed>
    );
  };

  renderReducedHeaderSecondary = () => {
    return (
      <fixed className={this.getFixedClassName()}>
        {this.renderFinancingBar()}
        <box className="navigation reduced">
          <container className="side">
            <column className="link" onClick={this.onGoHomepage}>
              {renderTemplateLogo()}
              {isDealerSite() || nationalSiteHasDealerSelected() ? (
                <h2 className="title">{getDealerName()}</h2>
              ) : (
                false
              )}
            </column>
            {this.enableHeaderLinks() ? (
              <right>
                {isDealerSite() ? (
                  <box className="links">
                    {isUserLoggedIn() &&
                    isPricingAnalysisEnabled(
                      applicationStore.data.dealerEnablePricingAnalysis
                    ) ? (
                      <a
                        tabIndex={getTabIndex()}
                        onClick={this.onGoPricingAnalysis}
                      >
                        {this.t("pricingAnalysisText")}
                      </a>
                    ) : (
                      false
                    )}
                    {isUserLoggedIn() &&
                    isPricingAnalysisEnabled(
                      applicationStore.data.dealerEnablePricingAnalysis
                    ) ? (
                      <span>|</span>
                    ) : (
                      false
                    )}
                    {isUserLoggedIn() ? (
                      <a tabIndex={getTabIndex()} onClick={this.onGoLeadReport}>
                        {this.t("leadReportText")}
                      </a>
                    ) : (
                      false
                    )}
                    {isUserLoggedIn() ? <span>|</span> : false}
                    {isUserLoggedIn() ? (
                      <a
                        tabIndex={getTabIndex()}
                        onClick={this.onGoQuoteReport}
                      >
                        {this.t("quoteReportText")}
                      </a>
                    ) : (
                      false
                    )}
                    {isUserLoggedIn() ? <span>|</span> : false}
                    {isUserLoggedIn() &&
                    (applicationStore.data.dealerEnableEcommerceRetailSite ||
                      applicationStore.data.dealerAllowNtdConsumerPurchase ||
                      applicationStore.data.supportUserConsumerOrder) ? (
                      <a
                        tabIndex={getTabIndex()}
                        onClick={this.onGoOrderReport}
                      >
                        {this.t("orderReportText")}
                      </a>
                    ) : (
                      false
                    )}
                    {isUserLoggedIn() &&
                    (applicationStore.data.dealerEnableEcommerceRetailSite ||
                      applicationStore.data.dealerAllowNtdConsumerPurchase) ? (
                      <span>|</span>
                    ) : (
                      false
                    )}
                    {isUserLoggedIn() ? (
                      <a
                        id={getTestId(TEST_LIST.HEADER.LOGOUT)}
                        tabIndex={getTabIndex()}
                        onClick={this.onLogOut}
                      >
                        {this.t("logout")}
                      </a>
                    ) : (
                      <a
                        id={getTestId(TEST_LIST.HEADER.LOGIN)}
                        tabIndex={getTabIndex()}
                        onClick={this.onToggleLoginTextDialog}
                      >
                        {this.t("login")}
                      </a>
                    )}
                    <span>|</span>
                    <a
                      id={getTestId(TEST_LIST.HEADER.LANGUAGE)}
                      tabIndex={getTabIndex()}
                      onClick={
                        getAppLanguage() === "fr"
                          ? this.changeLang.bind(this, "en")
                          : this.changeLang.bind(this, "fr")
                      }
                    >
                      {this.t("languageSwitch")}
                    </a>
                    <span>|</span>
                    <span className="bold">{this.getDealerName()}</span>
                  </box>
                ) : (
                  false
                )}
                {isNationalSite() ? (
                  <box className="links">
                    <a
                      id={getTestId(TEST_LIST.HEADER.LANGUAGE)}
                      tabIndex={getTabIndex()}
                      onClick={
                        getAppLanguage() === "fr"
                          ? this.changeLang.bind(this, "en")
                          : this.changeLang.bind(this, "fr")
                      }
                    >
                      {this.t("languageSwitch")}
                    </a>
                    {!isEmpty(this.getDealerName()) ? <span>|</span> : false}
                    {!isEmpty(this.getDealerName()) ? (
                      <span className="bold">{this.getDealerName()}</span>
                    ) : (
                      false
                    )}
                    {!isEmpty(this.getDealerName()) &&
                    this.enableDealerSelection() ? (
                      <span>|</span>
                    ) : (
                      false
                    )}
                    {this.enableDealerSelection() ? (
                      <a
                        tabIndex={getTabIndex()}
                        onClick={this.returnToDealerSelection}
                      >
                        {this.t("dealerSelection")}
                      </a>
                    ) : (
                      false
                    )}
                  </box>
                ) : (
                  false
                )}
                {this.enableHeaderIcons() ? (
                  <box className="right">
                    <column>
                      <i
                        id={getTestId(TEST_LIST.HEADER.SEARCH)}
                        onClick={this.onToggleSearch}
                        className="fa fa-search"
                      />
                    </column>
                    <column>
                      <box className="pointer">
                        <i
                          id={getTestId(TEST_LIST.HEADER.CART)}
                          onClick={this.onToggleCartBar}
                          className="fa fa-shopping-cart"
                        />
                        <text>{this.getCartCount()}</text>
                      </box>
                    </column>
                  </box>
                ) : (
                  false
                )}
              </right>
            ) : (
              false
            )}
          </container>
        </box>
        {this.renderBreadcrumbs()}
        {this.renderLogin()}
        {this.renderShoppingCart()}
        {this.renderShoppingCartModal()}
      </fixed>
    );
  };

  renderHeaderSecondary = () => {
    return (
      <fixed className={this.getFixedClassName()}>
        {this.renderFinancingBar()}
        <box className="navigation reduced">
          <container className="side">
            <column className="link" onClick={this.onGoHomepage}>
              {renderTemplateLogo()}
              {isDealerSite() || nationalSiteHasDealerSelected() ? (
                <box className="dealer-title">
                  <h2>{getDealerName().toUpperCase()}</h2>
                  <text>{getDealerInfo()}</text>
                </box>
              ) : (
                false
              )}
            </column>
            {this.enableHeaderLinks() ? (
              <right>
                {isDealerSite() ? (
                  <box className="links">
                    {isUserLoggedIn() &&
                    isPricingAnalysisEnabled(
                      applicationStore.data.dealerEnablePricingAnalysis
                    ) ? (
                      <a
                        tabIndex={getTabIndex()}
                        onClick={this.onGoPricingAnalysis}
                      >
                        {this.t("pricingAnalysisText")}
                      </a>
                    ) : (
                      false
                    )}
                    {isUserLoggedIn() &&
                    isPricingAnalysisEnabled(
                      applicationStore.data.dealerEnablePricingAnalysis
                    ) ? (
                      <span>|</span>
                    ) : (
                      false
                    )}
                    {isUserLoggedIn() ? (
                      <a tabIndex={getTabIndex()} onClick={this.onGoLeadReport}>
                        {this.t("leadReportText")}
                      </a>
                    ) : (
                      false
                    )}
                    {isUserLoggedIn() ? <span>|</span> : false}
                    {isUserLoggedIn() ? (
                      <a
                        tabIndex={getTabIndex()}
                        onClick={this.onGoQuoteReport}
                      >
                        {this.t("quoteReportText")}
                      </a>
                    ) : (
                      false
                    )}
                    {isUserLoggedIn() ? <span>|</span> : false}
                    {isUserLoggedIn() &&
                    (applicationStore.data.dealerEnableEcommerceRetailSite ||
                      applicationStore.data.dealerAllowNtdConsumerPurchase ||
                      applicationStore.data.supportUserConsumerOrder) ? (
                      <a
                        tabIndex={getTabIndex()}
                        onClick={this.onGoOrderReport}
                      >
                        {this.t("orderReportText")}
                      </a>
                    ) : (
                      false
                    )}
                    {isUserLoggedIn() &&
                    (applicationStore.data.dealerEnableEcommerceRetailSite ||
                      applicationStore.data.dealerAllowNtdConsumerPurchase) ? (
                      <span>|</span>
                    ) : (
                      false
                    )}
                    {isUserLoggedIn() ? (
                      <a
                        id={getTestId(TEST_LIST.HEADER.LOGOUT)}
                        tabIndex={getTabIndex()}
                        onClick={this.onLogOut}
                      >
                        {this.t("logout")}
                      </a>
                    ) : (
                      <a
                        id={getTestId(TEST_LIST.HEADER.LOGIN)}
                        tabIndex={getTabIndex()}
                        onClick={this.onToggleLoginTextDialog}
                      >
                        {this.t("login")}
                      </a>
                    )}
                    <span>|</span>
                    <a
                      id={getTestId(TEST_LIST.HEADER.LANGUAGE)}
                      tabIndex={getTabIndex()}
                      onClick={
                        getAppLanguage() === "fr"
                          ? this.changeLang.bind(this, "en")
                          : this.changeLang.bind(this, "fr")
                      }
                    >
                      {this.t("languageSwitch")}
                    </a>
                    <span>|</span>
                    <span className="bold">{this.getDealerName()}</span>
                  </box>
                ) : (
                  false
                )}
                {isNationalSite() ? (
                  <box className="links">
                    <a
                      id={getTestId(TEST_LIST.HEADER.LANGUAGE)}
                      tabIndex={getTabIndex()}
                      onClick={
                        getAppLanguage() === "fr"
                          ? this.changeLang.bind(this, "en")
                          : this.changeLang.bind(this, "fr")
                      }
                    >
                      {this.t("languageSwitch")}
                    </a>
                    {!isEmpty(this.getDealerName()) ? <span>|</span> : false}
                    {!isEmpty(this.getDealerName()) ? (
                      <span className="bold">{this.getDealerName()}</span>
                    ) : (
                      false
                    )}
                    {!isEmpty(this.getDealerName()) &&
                    this.enableDealerSelection() ? (
                      <span>|</span>
                    ) : (
                      false
                    )}
                    {this.enableDealerSelection() ? (
                      <a
                        tabIndex={getTabIndex()}
                        onClick={this.returnToDealerSelection}
                      >
                        {this.t("dealerSelection")}
                      </a>
                    ) : (
                      false
                    )}
                  </box>
                ) : (
                  false
                )}
                {this.enableHeaderIcons() ? (
                  <box className="right">
                    <column>
                      <box>
                        <button
                          id={getTestId(TEST_LIST.HEADER.SEARCH)}
                          className={setAdobeLaunchClass(
                            "iconbutton",
                            ADOBELAUNCHER_CLASS.BUTTON.LINK
                          )}
                          data-dtm={setAdobeLaunchDTM(
                            "",
                            ADOBELAUNCHER_DTM.GLOBALNAV.BASE
                          )}
                          tabIndex={getTabIndex()}
                          onClick={this.onToggleSearch}
                        >
                          <column>
                            <i className="fa fa-search" />
                          </column>
                          <column>
                            <h2>{this.t("searchText")}</h2>
                          </column>
                        </button>
                      </box>
                    </column>
                    <column>
                      <box>
                        <button
                          id={getTestId(TEST_LIST.HEADER.CART)}
                          className={setAdobeLaunchClass(
                            "iconbutton",
                            ADOBELAUNCHER_CLASS.BUTTON.LINK
                          )}
                          data-dtm={setAdobeLaunchDTM(
                            "",
                            ADOBELAUNCHER_DTM.GLOBALNAV.BASE
                          )}
                          tabIndex={getTabIndex()}
                          onClick={this.onToggleCartBar}
                        >
                          <column>
                            <column>
                              <img src={this.getHeaderQuoteButtonIcon()} />
                            </column>
                            <column>
                              <h2 className="iconbuttontext">
                                {"(" + this.props.itemsCount + ")"}
                              </h2>
                            </column>
                          </column>
                          <column>
                            <h2>
                              {this.getMyQuoteText()}
                              {this.showMyQuoteTotal()
                                ? " " + this.props.total
                                : ""}
                            </h2>
                          </column>
                        </button>
                      </box>
                    </column>
                  </box>
                ) : (
                  false
                )}
              </right>
            ) : (
              false
            )}
          </container>
        </box>
        {this.renderBreadcrumbs()}
        {this.renderLogin()}
        {this.renderShoppingCart()}
        {this.renderShoppingCartModal()}
      </fixed>
    );
  };

  renderMobileAlwaysHeader = () => {
    return (
      <fixed className={this.getMobileAlwaysFixedClass()}>
        {this.renderFinancingBar()}

        <box
          className="navigation mobile-always"
          style={
            isDesktop() && this.state.scrollY > 75 ? { background: "#fff" } : {}
          }
        >
          {this.renderMobileAlwaysIcon()}
          {this.renderMobileAlwaysLogo()}
          {this.renderMobileAlwaysButtons()}
        </box>
        {this.renderBreadcrumbs()}
        {this.renderLogin()}
        {this.renderShoppingCart()}
        {this.renderShoppingCartModal()}
      </fixed>
    );
  };
  renderAudiHeader = () => {
    return (
      <fixed className={this.getMobileAlwaysFixedClass()}>
        {this.renderFinancingBar()}
        <box
          className="navigation mobile-always"
          style={
            isDesktop() && this.state.scrollY > 75 ? { background: "#fff" } : {}
          }
        >
          {this.renderMobileAlwaysIcon()}
          {this.renderMobileAlwaysButtons()}
        </box>
        {this.renderBreadcrumbs()}
        {this.renderLogin()}
        {this.renderShoppingCart()}
        {this.renderShoppingCartModal()}
      </fixed>
    );
  };
  getMobileAlwaysMenuIcon = () => {
    switch (this.state.routerState) {
      case RouterStates.HOMEPAGE:
      case RouterStates.LANDING_PAGE:
        if (isCarouselBreak()) {
          return "/static/img/menu_icon_blue.png";
        } else {
          return "/static/img/menu_icon.png";
        }
      default:
        return "/static/img/menu_icon_blue.png";
    }
  };

  getMobileAlwaysFixedClass = () => {
    return Cx({
      "no-background":
        applicationStore.data.defaultTemplate !== "audi_2021" &&
        !isMobile() &&
        (this.state.routerState === RouterStates.HOMEPAGE ||
          this.state.routerState === RouterStates.LANDING_PAGE),
      [this.getFixedClassName()]: true,
    });
  };

  toggleMobileAlwaysMenu = () => {
    if (this.state.showMobileAlwaysMenu && this.state.loginTextDialogShown) {
      this.props.dispatch(toggleLoginDialog(!this.props.loginVisible));
    }
    this.setState({
      showMobileAlwaysMenu: !this.state.showMobileAlwaysMenu,
    });
  };

  closeMobileAlwaysMenu = () => {
    this.setState({
      showMobileAlwaysMenu: false,
    });
  };

  renderMobileAlwaysMenu = () => {
    if (this.state.showMobileAlwaysMenu) {
      return (
        <menu>
          <box className="mobile-always-menu">
            <container className="large">
              <box>
                <container className="xlarge">
                  <column>
                    <img
                      className="close-icon pointer"
                      src={this.getMobileAlwaysCloseImage()}
                      onClick={this.toggleMobileAlwaysMenu}
                    />
                  </column>
                  <column>
                    <a onClick={this.toggleMobileAlwaysMenu}>
                      {this.t("common.closeMenu")}
                    </a>
                  </column>
                </container>
              </box>
              <box>
                <container className="xlarge">
                  <column className="info-column">
                    {isDealerSite() || nationalSiteHasDealerSelected() ? (
                      <box>
                        <container className="top no-top xlarge">
                          <h2 className="dealer-name">
                            {this.getDealerName()}
                          </h2>
                        </container>
                      </box>
                    ) : (
                      false
                    )}
                    {isDealerSite() || nationalSiteHasDealerSelected() ? (
                      <box>
                        <container className="top">
                          <text>{getDealerInfo()}</text>
                        </container>
                      </box>
                    ) : (
                      false
                    )}
                    {isDealerSite() || nationalSiteHasDealerSelected() ? (
                      <box>
                        <box>
                          <column>
                            <img
                              className="icon"
                              src="/static/img/phone_white.png"
                            />
                          </column>
                          <column>
                            <text>{getDealerContactPhone()}</text>
                          </column>
                        </box>
                        <box>
                          <column>
                            <img
                              className="icon"
                              src="/static/img/email_white.png"
                            />
                          </column>
                          <column>
                            <text>{getDealerContactEmail()}</text>
                          </column>
                        </box>
                        <box>
                          <column>
                            <img
                              className="icon"
                              src="/static/img/website_white.png"
                            />
                          </column>
                          <column>
                            <text>{getDealerContactWebsite()}</text>
                          </column>
                        </box>
                      </box>
                    ) : (
                      false
                    )}
                    <box>
                      {this.renderHeaderLinks(true)}
                      {this.renderMenuLogin()}
                    </box>
                  </column>
                  <column className={!isMobile() && !isTablet() ? "right" : ""}>
                    {isUserLoggedIn()
                      ? renderSupportInfo(true)
                      : renderDealerSupportInfo()}
                  </column>
                </container>
              </box>
            </container>
          </box>
        </menu>
      );
    } else {
      return false;
    }
  };
  renderMobileAlwaysIcon = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "audi_2021":
        return (
          <left>
            {renderTemplateLogo()}
            <h3
              className="desktop"
              style={{ display: "inline", fontSize: "20px" }}
            >
              {getDealerName()}
            </h3>
          </left>
        );
      case "kia_2021":
        return (
          <left>
            <i
              className="fa fa-bars"
              style={this.getMobileAlwaysIconStyle()}
              onClick={this.toggleMobileAlwaysMenu}
            />
            <h3
              className="desktop"
              style={this.getMobileAlwaysIconDealerNameStyle()}
            >
              {getDealerName()}
            </h3>
          </left>
        );
      case "volkswagen_2020":
        return (
          <left>
            <img
              className="menu-icon"
              src={this.getMobileAlwaysMenuIcon()}
              onClick={this.toggleMobileAlwaysMenu}
            />
          </left>
        );
      default:
        return false;
    }
  };

  getMobileAlwaysIconStyle = () => {
    if (
      isDesktop() &&
      this.state.scrollY <= 75 &&
      (getRouterState() === RouterStates.HOMEPAGE ||
        getRouterState() === RouterStates.LANDING_PAGE)
    ) {
      return {
        fontSize: "40px",
        margin: "5px 15px 5px 7.5px",
        cursor: "pointer",
        color: "#fff",
      };
    } else {
      return {
        fontSize: "40px",
        margin: "5px 15px 5px 7.5px",
        cursor: "pointer",
      };
    }
  };

  getMobileAlwaysIconDealerNameStyle = () => {
    if (
      isDesktop() &&
      this.state.scrollY <= 75 &&
      (getRouterState() === RouterStates.HOMEPAGE ||
        getRouterState() === RouterStates.LANDING_PAGE)
    ) {
      return {
        display: "inline",
        verticalAlign: "super",
        fontSize: "20px",
        color: "#fff",
      };
    } else {
      return { display: "inline", verticalAlign: "super", fontSize: "20px" };
    }
  };

  renderMobileAlwaysLogo = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "kia_2021":
        var displayalternate =
          isDesktop() &&
          this.state.scrollY <= 75 &&
          (getRouterState() === RouterStates.HOMEPAGE ||
            getRouterState() === RouterStates.LANDING_PAGE);
        return (
          <box className="nav-logo" style={{ position: "absolute" }}>
            {renderTemplateLogo(displayalternate)}
          </box>
        );
      case "volkswagen_2020":
        return (
          <right>
            <column className="third"></column>
            <column className="third"></column>
            <column className="no-border">{renderTemplateLogo()}</column>
            <column
              className={isIEBrowser() ? "custom-width is-ie" : "custom-width"}
            ></column>
          </right>
        );
      default:
        return false;
    }
  };
  renderMobileAlwaysButtons = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "audi_2021":
        return (
          <right>
            <box
              style={{ padding: "25px", top: "-5px" }}
              className={Cx({ desktop: true })}
            >
              {this.renderSearchButton()}
              {this.renderQuoteButton(true)}
              <i
                className="fa fa-bars"
                style={{
                  fontSize: "40px",
                  margin: "5px 25px 5px 35px",
                  cursor: "pointer",
                  verticalAlign: "middle",
                }}
                onClick={this.toggleMobileAlwaysMenu}
              />
            </box>
            <box className="mobile">
              <column>
                <img
                  src="/static/img/search.png"
                  onClick={this.onToggleSearch}
                  className={setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.IMAGE)}
                  data-dtm={setAdobeLaunchDTM(
                    "",
                    ADOBELAUNCHER_DTM.GLOBALNAV.BASE
                  )}
                  style={{
                    background: "#000",
                    borderRadius: "5px",
                    marginTop: "15px",
                    height: "25px",
                  }}
                />
              </column>
              <column>
                <img
                  src="/static/img/bullet_list.png"
                  onClick={this.onToggleCartBar}
                  className={setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.IMAGE)}
                  data-dtm={setAdobeLaunchDTM(
                    "",
                    ADOBELAUNCHER_DTM.GLOBALNAV.BASE
                  )}
                  style={{
                    background: "#000",
                    borderRadius: "5px",
                    marginTop: "15px",
                    height: "25px",
                  }}
                />
              </column>
              <column>
                <i
                  className="fa fa-bars"
                  style={{
                    fontSize: "40px",
                    margin: "5px 25px 5px 35px",
                    cursor: "pointer",
                    verticalAlign: "middle",
                  }}
                  onClick={this.toggleMobileAlwaysMenu}
                />
              </column>
            </box>
          </right>
        );
      case "kia_2021":
        return (
          <right>
            <box
              style={{ padding: "25px" }}
              className={Cx({
                desktop: true,
                homepage:
                  isDesktop() &&
                  this.state.scrollY <= 75 &&
                  (getRouterState() === RouterStates.HOMEPAGE ||
                    getRouterState() === RouterStates.LANDING_PAGE),
              })}
            >
              {this.renderSearchButton()}
              {this.renderQuoteButton()}
            </box>
            <box className="mobile">
              <column>
                <img
                  src="/static/img/search.png"
                  onClick={this.onToggleSearch}
                  className={setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.IMAGE)}
                  data-dtm={setAdobeLaunchDTM(
                    "",
                    ADOBELAUNCHER_DTM.GLOBALNAV.BASE
                  )}
                  style={{
                    background: "#000",
                    borderRadius: "5px",
                    marginTop: "15px",
                    height: "25px",
                  }}
                />
              </column>
              <column>
                <img
                  src="/static/img/bullet_list.png"
                  onClick={this.onToggleCartBar}
                  className={setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.IMAGE)}
                  data-dtm={setAdobeLaunchDTM(
                    "",
                    ADOBELAUNCHER_DTM.GLOBALNAV.BASE
                  )}
                  style={{
                    background: "#000",
                    borderRadius: "5px",
                    marginTop: "15px",
                    height: "25px",
                  }}
                />
              </column>
            </box>
          </right>
        );
      default:
        return false;
    }
  };

  renderMenuLogin = () => {
    if (this.props.loginVisible) {
      return (
        <box className="menu-login">
          {this.state.failure ? (
            <box>
              <container className="top small">
                <box className="menu-error">
                  <container className="side small">
                    <text className="bold">
                      {this.t("loginDialog.loginHasFailed")}
                    </text>
                  </container>
                </box>
              </container>
            </box>
          ) : (
            false
          )}
          <box>
            <container className="top small">
              <text>{this.t("loginDialog.email")}</text>
            </container>
          </box>
          <box>
            <container className="top small">
              <input
                onFocus={this.clearEmailError}
                id="menu-email"
                type="email"
              />
              {this.state.errorMenuEmail ? (
                <box className="menu-error">
                  <container className="side small">
                    <text className="bold">
                      {this.t("wishlist.modal.errorNoEmail")}
                    </text>
                  </container>
                </box>
              ) : (
                false
              )}
            </container>
          </box>
          <box>
            <container className="top small">
              <column>
                <text>{this.t("loginDialog.password")}</text>
              </column>
              <column className="right">
                <text
                  onClick={this.onForgotPassword}
                  className="underline pointer"
                >
                  {"("}
                  {this.t("forgotPassword.forgotPassword")}
                  {")"}
                </text>
              </column>
            </container>
          </box>
          <box>
            <container className="top small">
              <input
                onFocus={this.clearPasswordError}
                id="menu-password"
                type="password"
              />
              {this.state.errorMenuPassword ? (
                <box className="menu-error">
                  <container className="side small">
                    <text className="bold">
                      {this.t("wishlist.modal.errorNoPassword")}
                    </text>
                  </container>
                </box>
              ) : (
                false
              )}
            </container>
          </box>
          <box>
            <container className="top small">
              <input
                id="menu-rememberMe"
                type="checkbox"
                className="menu-checkbox"
              />
              <text>{this.t("loginDialog.rememberMe")}</text>
            </container>
          </box>
          <box>
            <container className="top small">
              <button onClick={this.onLogin}>
                {this.t("loginDialog.signIn")}
              </button>
            </container>
          </box>
        </box>
      );
    } else {
      return false;
    }
  };

  clearEmailError = () => {
    this.setState({ errorMenuEmail: false });
  };

  clearPasswordError = () => {
    this.setState({ errorMenuPassword: false });
  };

  onForgotPassword = () => {
    this.props.dispatch(forgotPasswordToggle(true));
    applicationActions.openForgotPasswordModal();
  };

  onLogin = () => {
    var email, password, rememberMe;

    email = document.getElementById("menu-email").value;
    password = document.getElementById("menu-password").value;
    rememberMe = document.getElementById("menu-rememberMe").checked;

    if (isEmpty(email)) {
      this.setState({ errorMenuEmail: true });
    } else if (isEmpty(password)) {
      this.setState({ errorMenuPassword: true });
    } else {
      // this.props.dispatch(login({email,password,ip:'::1'}))
      applicationActions.login(email, password, rememberMe);
    }
  };

  getMobileAlwaysCloseImage = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "volkswagen_2020":
        return "/static/img/volkswagen/close_icon.png";
      default:
        return "";
    }
  };

  getDealerName = () => {
    if (
      applicationStore.data.dealerInfo &&
      applicationStore.data.dealerInfo.name
    ) {
      return applicationStore.data.dealerInfo.name;
    } else {
      return "";
    }
  };

  getCartCount = () => {
    if (shoppingCartStore.getItemsCount() > 0) {
      return shoppingCartStore.getItemsCount();
    } else {
      return "";
    }
  };

  enableDealerSelection = () => {
    return (
      nationalSiteHasDealerSelected() ||
      this.state.routerState !== RouterStates.LANDING_PAGE
    );
  };

  enableHeaderLinks = () => {
    return this.state.routerState !== RouterStates.DEALER_SELECTION;
  };

  enableHeaderLinksLeft = () => {
    if (isNationalSite()) {
      return (
        this.state.routerState !== RouterStates.DEALER_SELECTION &&
        this.state.routerState !== RouterStates.LANDING_PAGE
      );
    } else {
      return this.state.routerState !== RouterStates.DEALER_SELECTION;
    }
  };

  enableHeaderIcons = () => {
    return (
      isDealerSite() ||
      (this.state.routerState !== RouterStates.LANDING_PAGE &&
        !isConsumerSite())
    );
  };

  renderLogoLeft = () => {
    return (
      <fixed className={this.getFixedClassName()}>
        {this.renderFinancingBar()}
        <box className="navigation">
          <container className="side">
            <left>
              {this.renderLogo()}
              {this.renderConsumerTitle()}
              {this.renderNavigation()}
            </left>
            <right>
              <container className="top desktop">
                {this.renderHeaderLinks()}
                {this.renderHeaderButtons()}
              </container>
              <container className="mobile">
                {this.renderHeaderIcons()}
              </container>
            </right>
          </container>
        </box>
        {this.renderBreadcrumbs()}
        {this.renderLogin()}
        {this.renderShoppingCart()}
        {this.renderShoppingCartModal()}
        {this.renderFinancingModal()}
      </fixed>
    );
  };

  renderFinancingBar = () => {
    if (applicationStore.data.enableFinancing) {
      return (
        <box className="financingbar">
          <a onClick={this.toggleFinancingModal} className="bold">
            {this.getFinancingBarText()}
          </a>
        </box>
      );
    } else {
      return false;
    }
  };

  getFinancingBarText = () => {
    switch (applicationStore.data.enableFinancing) {
      case "2":
        return this.t("financeAvailable") + " - " + "0%";
      default:
        return this.t("financingAvailable");
    }
  };

  renderFinancingModal = () => {
    if (this.state.showFinancingModal) {
      return (
        <Modal
          refactor={true}
          fullWidth={true}
          requestUnmount={this.toggleFinancingModal}
        >
          <div className="wrapper">
            <div className="shopping-cart-modal">
              <box className="fullpage-financing">
                <container className="side">
                  <iframe src={applicationStore.data.financingUrl} />
                </container>
              </box>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  toggleFinancingModal = () => {
    this.setState({
      showFinancingModal: !this.state.showFinancingModal,
    });
  };

  renderLogoRight = () => {
    return (
      <fixed className={this.getFixedClassName()}>
        {this.renderFinancingBar()}
        <box className="navigation">
          <container className="side">
            <left>
              <column>
                <container className="top desktop">
                  {this.renderHeaderLinks()}
                  {this.renderHeaderButtons()}
                </container>
                <container className="mobile">
                  {this.renderHeaderIcons()}
                </container>
              </column>
              <column>{this.renderNavigation()}</column>
            </left>
            <right>{this.renderLogo()}</right>
          </container>
        </box>
        {this.renderBreadcrumbs()}
        {this.renderLogin()}
        {this.renderShoppingCart()}
        {this.renderShoppingCartModal()}
      </fixed>
    );
  };

  renderNavigation = () => {
    return (
      <column className="desktop">
        <container className="side">
          {this.renderNavigationItem(
            true,
            "text-small",
            <span>{getShowDealerInfo() ? getDealerInfo() : ""}</span>
          )}
          {this.renderNavigationItem(
            getShowDealerInfo(),
            "",
            <span>{getDealerContactInfo()}</span>
          )}
          {this.renderNavigationItem(
            checkSiteHasCampaign(),
            "",
            <span>{getCampaignName()}</span>
          )}
          {this.renderNavigationItem(
            allowHeaderNavigation(),
            "links",
            this.renderNavigationLinks()
          )}
        </container>
      </column>
    );
  };

  renderNavigationItem = (enable, className, element) => {
    if (enable) {
      return <box className={className}>{element}</box>;
    } else {
      return false;
    }
  };

  renderLogo = () => {
    return (
      <column>
        <container className={this.getLogoContainerClass()}>
          {renderTemplateLogo()}
        </container>
      </column>
    );
  };

  renderConsumerTitle = () => {
    if (isConsumerSite()) {
      return (
        <column>
          <container>
            <h1>{this.t("consumer.title")}</h1>
          </container>
        </column>
      );
    } else {
      return false;
    }
  };

  getLogoContainerClass = () => {
    if (this.hasLargeLogo()) {
      return "top front";
    } else {
      return "top";
    }
  };

  renderLogin = () => {
    return (
      <Popover
        show={this.props.loginVisible && !this.state.showMobileAlwaysMenu}
        onClose={this.onLoginTextPopoverRequestClose}
      >
        <LoginDialog failure={this.state.failure} />
      </Popover>
    );
  };

  renderSearchButton = () => {
    if (this.showSearchButton()) {
      return (
        <button
          id={getTestId(TEST_LIST.HEADER.SEARCH)}
          className={setAdobeLaunchClass(
            "iconbutton",
            ADOBELAUNCHER_CLASS.BUTTON.LINK
          )}
          data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.GLOBALNAV.BASE)}
          tabIndex={getTabIndex()}
          onClick={this.onToggleSearch}
        >
          <column>
            <img src={this.getHeaderSearchButtonIcon()} />
          </column>
          <column>
            <h2>{this.t("searchText")}</h2>
          </column>
        </button>
      );
    } else {
      return false;
    }
  };

  showSearchButton = () => {
    return (
      applicationStore.data.allowAdvancedSearch &&
      this.state.routerState !== RouterStates.DEALER_SELECTION &&
      this.state.routerState !== RouterStates.LANDING_PAGE &&
      !applicationStore.data.enableMercedesFeatures
    );
  };

  renderQuoteButton = () => {
    if (!isConsumerSite() && this.isValidStateForCart()) {
      return (
        <button
          id={getTestId(TEST_LIST.HEADER.CART)}
          className={setAdobeLaunchClass(
            "iconbutton",
            ADOBELAUNCHER_CLASS.BUTTON.LINK
          )}
          data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.GLOBALNAV.BASE)}
          onClick={this.onToggleCartBar}
        >
          <column className="background">
            <column>
              <img src={this.getHeaderQuoteButtonIcon()} />
            </column>
            <column>
              <h2 className="iconbuttontext">
                {"(" + this.props.itemsCount + ")"}
              </h2>
            </column>
          </column>
          <column>
            <h2>
              {this.getMyQuoteText()}
              {this.showMyQuoteTotal()
                ? " " + money_format(this.props.total)
                : ""}
            </h2>
          </column>
        </button>
      );
    } else {
      return false;
    }
  };

  getHeaderSearchButtonIcon = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "audi_2021":
        return (
          "/static/img/" +
          applicationStore.data.defaultTemplate +
          "/search_icon.png"
        );
      case "hyundai":
      case "pneuselect":
        return (
          "/static/img/" + applicationStore.data.defaultTemplate + "/search.png"
        );
      case "kia_2021":
        if (
          isDesktop() &&
          this.state.scrollY <= 75 &&
          (getRouterState() === RouterStates.HOMEPAGE ||
            getRouterState() === RouterStates.LANDING_PAGE)
        ) {
          return (
            "/static/img/" +
            applicationStore.data.defaultTemplate +
            "/search_white.png"
          );
        } else {
          return (
            "/static/img/" +
            applicationStore.data.defaultTemplate +
            "/search.png"
          );
        }
      default:
        return "/static/img/search.png";
    }
  };

  getHeaderQuoteButtonIcon = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "hyundai":
      case "mercedes":
      case "mercedes_2022":
      case "mercedestransition":
        return "";
      case "mini":
      case "pneuselect":
        return (
          "/static/img/" +
          applicationStore.data.defaultTemplate +
          "/bullet_list.png"
        );
      case "audi_2021":
      case "kia_2021":
        if (
          isDesktop() &&
          this.state.scrollY <= 75 &&
          (getRouterState() === RouterStates.HOMEPAGE ||
            getRouterState() === RouterStates.LANDING_PAGE)
        ) {
          return (
            "/static/img/" +
            applicationStore.data.defaultTemplate +
            "/bullet_list_white.png"
          );
        } else {
          return (
            "/static/img/" +
            applicationStore.data.defaultTemplate +
            "/bullet_list.png"
          );
        }
      default:
        return "/static/img/bullet_list.png";
    }
  };

  getMyQuoteText = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "audi":
      case "mazdatransition":
      case "volkswagen":
      case "volkswagen_2020":
        return applicationStore.data.useCartReviewPage
          ? this.t("common.myWishlist")
          : this.t("common.myCart");
      default:
        return applicationStore.data.useCartReviewPage
          ? this.t("common.myWishlist") + ":"
          : this.t("common.myCart") + ":";
    }
  };

  showMyQuoteTotal = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "audi":
      case "mazdatransition":
      case "volkswagen":
      case "volkswagen_2020":
        return false;
      default:
        return true;
    }
  };

  renderNavigationLinks = () => {
    var navList = [{ name: this.t("home"), onClick: this.onGoHomepage }];
    if (_.contains(this.state.productTypes, ProductTypes.TIRES)) {
      navList.push({
        name: this.t("productTypes.tires"),
        onClick: this.openSelectedSearch.bind(this, ProductTypes.TIRES),
      });
    }
    if (_.contains(this.state.productTypes, ProductTypes.ALLOY_WHEELS)) {
      navList.push({
        name: this.t("productTypes.wheels"),
        onClick: this.openSelectedSearch.bind(this, ProductTypes.ALLOY_WHEELS),
      });
    }
    if (_.contains(this.state.productTypes, ProductTypes.ACCESSORIES)) {
      navList.push({
        name: this.t("productTypes.accessories"),
        onClick: this.openSelectedSearch.bind(this, ProductTypes.ACCESSORIES),
      });
    }
    if (_.contains(this.state.productTypes, ProductTypes.PACKAGES)) {
      navList.push({
        name: this.t("productTypes.packages"),
        onClick: this.openSelectedSearch.bind(this, ProductTypes.PACKAGES),
      });
    }
    if (isUserLoggedIn()) {
      navList.push({
        name: this.t("support.support"),
        onClick: this.toggleSupportPane,
      });
    }
    return this.renderHeaderTextLinks(navList);
  };
  // this is our headerFunction
  renderHeaderIcons = () => {
    var linkList = this.getHeaderActionList();
    if (this.state.routerState !== RouterStates.DEALER_SELECTION) {
      linkList.push({
        name: "",
        icon: "/static/img/bullet_list.png",
        onClick: this.onToggleCartBar,
        id: getTestId(TEST_LIST.HEADER.CART),
      });
    }
    return (
      <box className="icons">
        <right>
          <container className="top">
            {Immutable.fromJS(linkList).map(function (link) {
              if (link.get("icon")) {
                return (
                  <column key={link.get("id")}>
                    <img
                      id={link.get("id")}
                      src={link.get("icon")}
                      onClick={link.get("onClick")}
                      className={setAdobeLaunchClass(
                        "",
                        ADOBELAUNCHER_CLASS.IMAGE
                      )}
                      data-dtm={setAdobeLaunchDTM(
                        "",
                        ADOBELAUNCHER_DTM.GLOBALNAV.BASE
                      )}
                    />
                  </column>
                );
              } else {
                return false;
              }
            })}
          </container>
        </right>
      </box>
    );
  };

  renderHeaderButtons = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "audi":
        return (
          <box>
            <left className="swap">{this.renderButtonContainer()}</left>
          </box>
        );
      default:
        return (
          <box>
            <right>{this.renderButtonContainer()}</right>
          </box>
        );
    }
  };

  renderButtonContainer = () => {
    return (
      <container className="top">
        {this.renderSearchButton()}
        {this.renderQuoteButton()}
      </container>
    );
  };

  getHeaderActionList = (ismenu = false) => {
    var linkList = [];

    if (
      !isConsumerSite() &&
      applicationStore.data.allowedLanguages &&
      sizeOf(applicationStore.data.allowedLanguages) > 1 &&
      !applicationStore.data.enableVolkswagenFeatures
    ) {
      linkList.push({
        name: this.t("languageSwitch"),
        icon: this.getLanguageIcon(),
        onClick:
          getAppLanguage() === "fr"
            ? this.changeLang.bind(this, "en")
            : this.changeLang.bind(this, "fr"),
        id: getTestId(TEST_LIST.HEADER.LANGUAGE),
      });
    }

    if (shoppingCartStore.getItemsCount() > 0) {
      linkList.push({
        name: this.t("startNewQuote"),
        icon: "/static/img/new_quote.png",
        onClick: this.onOpenStartNewQuoteModal,
      });
    }

    if (isUserLoggedIn()) {
      if (
        applicationStore.data.dealerEnableEcommerceRetailSite ||
        applicationStore.data.dealerAllowNtdConsumerPurchase ||
        (isUserLoggedIn() && applicationStore.data.supportUserConsumerOrder)
      ) {
        linkList.push({
          name: this.t("orderReportText"),
          icon: "/static/img/quote_report.png",
          onClick: this.onGoOrderReport,
        });
      } else {
        linkList.push({
          name: this.t("leadReportText"),
          icon: "/static/img/quote_report.png",
          onClick: this.onGoLeadReport,
        });
      }
      linkList.push({
        name: this.t("quoteReportText"),
        icon: "/static/img/quote_report.png",
        onClick: this.onGoQuoteReport,
      });
      if (
        isPricingAnalysisEnabled(
          applicationStore.data.dealerEnablePricingAnalysis
        )
      ) {
        linkList.push({
          name: this.t("pricingAnalysisText"),
          icon: "/static/img/quote_report.png",
          onClick: this.onGoPricingAnalysis,
        });
      }
      if (applicationStore.data.dealerEnablePricingIncentive) {
        linkList.push({
          name: this.t("pricingIncentive"),
          icon: "/static/img/quote_report.png",
          onClick: this.onGoPricingIncentive,
        });
      }
      if (applicationStore.data.enableFinancing) {
        linkList.push({
          name: this.t("financeText"),
          icon: "/static/img/quote_report.png",
          onClick() {
            window.open(getPaybrightUrl(), "_blank");
          },
          id: getTestId(TEST_LIST.HEADER.FINANCE),
        });
      }
      if (applicationStore.data.dealerEnableBookingSystem) {
        linkList.push({
          name: this.t("bookingText"),
          icon: "/static/img/quote_report.png",
          onClick() {
            window.open(getBookingUrl(), "_blank");
          },
          id: getTestId(TEST_LIST.HEADER.BOOKING),
        });
      }
    }

    if (isUserLoggedIn(true)) {
      linkList.push({
        name: this.t("logout"),
        icon: "/static/img/login.png",
        onClick: this.onLogOut,
        id: getTestId(TEST_LIST.HEADER.LOGOUT),
      });
    }

    if (
      !isDealerSite() &&
      !isConsumerSite() &&
      this.state.routerState !== RouterStates.DEALER_SELECTION &&
      ((checkSiteHasCampaign() &&
        sizeOf(window.campaignData.campaignDealerList) > 1) ||
        !checkSiteHasCampaign()) &&
      applicationStore.data.useProvincialDealers &&
      (!applicationStore.data.enableMercedesFeatures ||
        applicationStore.data.dealerIsRegionalOnly)
    ) {
      linkList.push({
        name: this.t("provinceSelection"),
        icon: "/static/img/mapmarker.png",
        onClick: this.openReturnToDealerSelectionWarning,
      });
    }

    if (
      !isDealerSite() &&
      !isConsumerSite() &&
      this.state.routerState !== RouterStates.DEALER_SELECTION &&
      ((checkSiteHasCampaign() &&
        sizeOf(window.campaignData.campaignDealerList) > 1) ||
        !checkSiteHasCampaign()) &&
      !applicationStore.data.useProvincialDealers &&
      !applicationStore.data.enableMercedesFeatures
    ) {
      linkList.push({
        name: this.getDealerSelectionText(),
        icon: "/static/img/mapmarker.png",
        onClick: this.returnToDealerSelection,
      });
    }

    if (!ismenu && isDealerSite() && !isUserLoggedIn(true)) {
      linkList.push({
        name: this.t("login"),
        icon: "/static/img/login.png",
        onClick: this.onToggleLoginTextDialog,
        id: getTestId(TEST_LIST.HEADER.LOGIN),
      });
    }

    if (
      !isConsumerSite() &&
      this.state.routerState !== RouterStates.DEALER_SELECTION &&
      this.state.routerState !== RouterStates.LANDING_PAGE &&
      !applicationStore.data.enableVolkswagenFeatures
    ) {
      if (isCustomerAuthentication()) {
        linkList.push({
          name: getCustomerAuthenticationName(),
          icon: false,
          onClick: false,
        });
      } else {
        linkList.push({ name: getDealerName(), icon: false, onClick: false });
      }
    }

    if (
      applicationStore.data.allowedLanguages &&
      sizeOf(applicationStore.data.allowedLanguages) > 1 &&
      applicationStore.data.enableVolkswagenFeatures
    ) {
      linkList.push({
        name: this.t("languageSwitch"),
        icon: this.getLanguageIcon(),
        onClick:
          getAppLanguage() === "en"
            ? this.changeLang.bind(this, "fr")
            : this.changeLang.bind(this, "en"),
        id: getTestId(TEST_LIST.HEADER.LANGUAGE),
      });
    }

    if (ismenu && isDealerSite() && !isUserLoggedIn(true)) {
      linkList.push({
        name: this.t("login"),
        icon: "/static/img/login.png",
        onClick: this.onToggleLoginTextDialog,
        id: getTestId(TEST_LIST.HEADER.LOGIN),
      });
    }

    if (ismenu && this.getCartCount() > 0) {
      linkList.push({
        name: this.t("shoppingCartView.shoppingCartViewFor"),
        icon: "/static/img/cart.png",
        onClick: this.onToggleCartBar,
        id: getTestId(TEST_LIST.HEADER.CART),
      });
    }

    return linkList;
  };

  getLanguageIcon = () => {
    if (getAppLanguage() === "fr") {
      return "/static/img/icon_fr.png";
    } else {
      return "/static/img/icon_en.png";
    }
  };

  renderHeaderLinks = (ismenu) => {
    // console.trace()
    return (
      <box className={this.getHeaderLinksClass()}>
        {this.renderHeaderTextLinks(this.getHeaderActionList(ismenu), true)}
      </box>
    );
  };

  getHeaderLinksClass = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "audi":
        return "links left";
      default:
        return "links right";
    }
  };

  getHeaderLinksHourzClass = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "audi":
        return "";
      default:
        return "right";
    }
  };

  getDealerSelectionText = () => {
    switch (this.state.routerState) {
      case RouterStates.LANDING_PAGE:
        return this.t("wishlist.modal.findADealer");
      default:
        return this.t("dealerSelection");
    }
  };

  renderHeaderTextLinks = (dataList, rightSide = false) => {
    var addToNav = this.hasLargeLogo(),
      getHeaderLinksHourzClass = this.getHeaderLinksHourzClass;
    return Immutable.fromJS(dataList).map(function (data, index) {
      var classList = Cx({
        last: index === sizeOf(dataList) - 1,
        [getHeaderLinksHourzClass()]: rightSide,
      });

      return [
        addToNav && sizeOf(dataList) > 1 && index === 0 ? (
          <span
            className={
              rightSide ? "first" + " " + getHeaderLinksHourzClass() : "first"
            }
          >
            |
          </span>
        ) : (
          false
        ),
        data.get("onClick") === false ? (
          <span className="bold" tabIndex={getTabIndex()}>
            {data.get("name")}
          </span>
        ) : (
          <a
            tabIndex={getTabIndex()}
            className={setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.TEXT)}
            data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.GLOBALNAV.BASE)}
            onClick={data.get("onClick")}
          >
            {data.get("name")}
          </a>
        ),
        addToNav || (sizeOf(dataList) > 1 && index < sizeOf(dataList) - 1) ? (
          <span className={classList}>|</span>
        ) : (
          false
        ),
      ];
    });
  };

  hasLargeLogo = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "toyota":
      case "subaru":
        return true;
      default:
        return false;
    }
  };

  getCustomLogo = () => {
    if (isDealerSite() || isNationalSite()) {
      return applicationStore.data.dealerRetailSiteCustomLogo;
    } else {
      return applicationStore.data.groupRetailSiteCustomLogo;
    }
  };

  renderBody = () => {
    return (
      <box className={this.getBodyClass()}>
        {this.renderiFrameCart()}

        <box className="body-container">
          <box className={this.getContentWrapperClass()}>
            {renderCampaignDealerInfo(this.isValidStateForCampaignDealerInfo())}

            {this.renderBodyContent()}
          </box>
        </box>
      </box>
    );
  };

  renderiFrameCart = () => {
    if (
      applicationStore.data.screenMode === SCREENMODES.IFRAME &&
      this.isValidStateForCart() &&
      shoppingCartStore.getItemsCount() > 0
    ) {
      return (
        <hovercart onClick={this.onClickiFrameCart} className="pointer">
          <i
            id={getTestId(TEST_LIST.HEADER.CART)}
            className="fa fa-shopping-cart"
          />

          <text>{shoppingCartStore.getItemsCount()}</text>
        </hovercart>
      );
    } else {
      return false;
    }
  };

  onClickiFrameCart = () => {
    setTimeout(() => {
      this.props.dispatch(toggleShoppingModal(true));
      // shoppingCartActions.openCartModal();
    }, 100);
  };

  getBodyClass = () => {
    return Cx({
      body: applicationStore.data.screenMode !== SCREENMODES.GUIDE,
      "has-navigation":
        !isConsumerSite() &&
        !skipBreadcrumbsLoad() &&
        applicationStore.data.screenMode !== SCREENMODES.GUIDE,
      "has-financing": applicationStore.data.enableFinancing,
      "is-explorer": isIEBrowser(),
      homepage:
        (this.state.routerState === RouterStates.HOMEPAGE ||
          this.state.routerState === RouterStates.LANDING_PAGE) &&
        applicationStore.data.enableMazdaFeatures,
      "screenmode-iframe":
        applicationStore.data.screenMode === SCREENMODES.IFRAME,
    });
  };

  getContentWrapperClass = () => {
    return Cx({
      "content-wrapper": true,
      "loading-page": this.state.showOnlyLoadingPage,
      "no-padding":
        this.state.routerState === RouterStates.HOMEPAGE ||
        this.state.routerState === RouterStates.LANDING_PAGE,
      "no-footer":
        this.state.routerState === RouterStates.DEALER_SELECTION ||
        this.state.routerState === RouterStates.LANDING_PAGE,
    });
  };

  renderFooter = () => {
    if (
      applicationStore.data.screenMode === SCREENMODES.IFRAME ||
      applicationStore.data.screenMode === SCREENMODES.GUIDE
    ) {
      return this.renderEnhancedFooter();
    } else {
      switch (applicationStore.data.defaultTemplate) {
        case "audi_2021":
        case "kia_2021":
        case "mazda":
          return this.renderEnhancedFooter();
        default:
          return (
            <box className={this.getFooterClass()}>
              {renderSiteAdvancedFooter()}
            </box>
          );
      }
    }
  };

  renderEnhancedFooter = () => {
    return (
      <box className={this.getFooterClass()}>
        <column
          className={isUserLoggedIn() ? "fixcolwidth loggedin" : "fixcolwidth"}
        >
          {renderSiteAdvancedFooter()}
        </column>
        <right
          className={
            isUserLoggedIn() ? "fixrightwidth loggedin" : "fixrightwidth"
          }
        >
          <container className="side large">
            {isDealerSite() && isUserLoggedIn() ? (
              <column>
                <container className="no-left">
                  <text
                    className="bold pointer"
                    tabIndex={getTabIndex()}
                    onClick={this.onGoQuoteReport}
                  >
                    {this.t("quoteReportText")}
                  </text>
                </container>
              </column>
            ) : (
              false
            )}
            <column>
              <container>
                <text
                  id={getTestId(TEST_LIST.HEADER.LANGUAGE)}
                  className="bold pointer"
                  tabIndex={getTabIndex()}
                  onClick={
                    getAppLanguage() === "fr"
                      ? this.changeLang.bind(this, "en")
                      : this.changeLang.bind(this, "fr")
                  }
                >
                  {this.t("languageSwitch")}
                </text>
              </container>
            </column>
            {isDealerSite() ? (
              <column>
                <container className="no-right">
                  {isUserLoggedIn() ? (
                    <text
                      id={getTestId(TEST_LIST.HEADER.LOGOUT)}
                      className="bold pointer"
                      tabIndex={getTabIndex()}
                      onClick={this.onLogOut}
                    >
                      {this.t("logout")}
                    </text>
                  ) : (
                    <text
                      id={getTestId(TEST_LIST.HEADER.LOGIN)}
                      className="bold pointer"
                      tabIndex={getTabIndex()}
                      onClick={this.onToggleLoginTextDialog}
                    >
                      {this.t("login")}
                    </text>
                  )}
                </container>
              </column>
            ) : (
              false
            )}
          </container>
        </right>
      </box>
    );
  };

  getFooterClass = () => {
    return Cx({
      footer: true,
      hassearch: this.enableFullScreenSearch() && this.state.searchIsActiveFlag,
      homepage:
        (this.state.routerState === RouterStates.HOMEPAGE ||
          this.state.routerState === RouterStates.LANDING_PAGE) &&
        applicationStore.data.enableMazdaFeatures,
      enhanced:
        applicationStore.data.defaultTemplate === "mazda" ||
        applicationStore.data.screenMode === SCREENMODES.IFRAME ||
        applicationStore.data.screenMode === SCREENMODES.GUIDE,
    });
  };

  renderAODAHeader = () => {
    var title = breadcrumbsStore.getLastCrumb();
    if (title && typeof title === "string")
      return (
        <h1
          className="aoda-hidden-header"
          aria-live="assertive"
          aria-hidden="false"
          role="heading"
        >
          {title}
        </h1>
      );
    else return false;
  };

  renderBreadcrumbs = () => {
    if (!isConsumerSite() && !skipBreadcrumbsLoad()) {
      return <Breadcrumbs />;
    } else {
      return false;
    }
  };

  renderBodyContent = () => {
    if (!this.state.showOnlyLoadingPage) {
      switch (this.state.routerState) {
        case RouterStates.DEALER_SELECTION:
          if (applicationStore.data.useProvincialDealers)
            return <ProvinceSelection />;
          else if (checkShouldUseLegacyMap) return <DealerSelectionLegacy />;
          else return <DealerSelection />;
        case RouterStates.LANDING_PAGE:
          return <Landingpage />;
        case RouterStates.HOMEPAGE:
          if (isConsumerSite()) {
            return <ConsumerHomepage />;
          } else {
            return <Homepage />;
          }
        case RouterStates.TRANSLATION_DICTIONARY:
          return <DictionaryViewer />;
        case RouterStates.SEARCH_BRANDS_BY_SEASON:
          return <SearchBrandsBySeason />;
        case RouterStates.SEARCH_BRANDS:
          return <SearchBrandsByBrandId />;
        case RouterStates.SEARCH_MODELS_BY_SEASON:
          return <SearchModelsBySeason />;
        case RouterStates.SEARCH_MODELS:
          return <SearchModelsByBrand />;
        case RouterStates.SEARCH_TIRE_BY_SEASON:
        case RouterStates.SEARCH_TIRE:
          return <SearchTire />;
        case RouterStates.CONTACT_DEALER:
          return <ContactDealer />;
        case RouterStates.YEAR:
        case RouterStates.MAKE:
        case RouterStates.CATEGORY:
        case RouterStates.MODEL:
        case RouterStates.TRIM:
          return <VehicleSelection routerstate={this.state.routerState} />;
        case RouterStates.PRODUCT_LIST:
          return <ProductListRefactor />;
        case RouterStates.WISHLIST:
        case RouterStates.WISHLIST_WITH_ID:
          var packageVisualizerEnabled = false;
          if (
            productListStore.data.visualizerPart &&
            !productListStore.data.hideProductList &&
            (this.state.routerState === RouterStates.WISHLIST ||
              (this.state.routerState === RouterStates.PRODUCT_LIST &&
                applicationStore.data.productType === ProductTypes.PACKAGES))
          ) {
            packageVisualizerEnabled = true;
          }
          return (
            <WishlistReview
              packageVisualizerEnabled={packageVisualizerEnabled}
            />
          );
        case RouterStates.PRINT_WISHLIST:
        case RouterStates.PRINT_WISHLIST_TEST:
          return null;
        case RouterStates.SEARCH_RESULT:
          return <ProductListRefactor />;
        case RouterStates.PRODUCT_DETAIL:
          return <ProductDetail />;
        case RouterStates.COMPARISON:
        case RouterStates.PRINT_COMPARISON:
        case RouterStates.PRINT_COMPARISON_FROM_TIRESIZE:
          return null;
        case RouterStates.WEBSERVICE_ERROR:
          return (
            <NoConnectionPage
              httpStatusCode={parseInt(
                applicationStore.data.webserviceFailedHttpStatus
              )}
            />
          );
        case null:
          return <LoadingPage />;
        default:
          return <div />;
      }
    } else {
      return <LoadingPage />;
    }
  };

  isValidStateForCart = (isModal = false) => {
    return (
      !this.shouldHideCart() &&
      (this.state.routerState === RouterStates.HOMEPAGE ||
        this.state.routerState === RouterStates.CONTACT_DEALER ||
        this.state.routerState === RouterStates.YEAR ||
        this.state.routerState === RouterStates.MAKE ||
        this.state.routerState === RouterStates.CATEGORY ||
        this.state.routerState === RouterStates.MODEL ||
        this.state.routerState === RouterStates.TRIM ||
        this.state.routerState === RouterStates.PRODUCT_LIST ||
        this.state.routerState === RouterStates.SEARCH_RESULT ||
        this.state.routerState === RouterStates.PRODUCT_DETAIL ||
        this.state.routerState === RouterStates.COMPARISON ||
        this.state.routerState === RouterStates.LANDING_PAGE ||
        this.state.routerState === RouterStates.SEARCH_BRANDS ||
        this.state.routerState === RouterStates.SEARCH_BRANDS_BY_SEASON ||
        this.state.routerState === RouterStates.SEARCH_MODELS ||
        this.state.routerState === RouterStates.SEARCH_MODELS_BY_SEASON ||
        this.state.routerState === RouterStates.SEARCH_TIRE ||
        (!isModal && this.state.routerState === RouterStates.WISHLIST))
    );
  };

  isValidStateForNavOffset = () => {
    return (
      this.state.routerState !== RouterStates.DEALER_SELECTION &&
      this.state.routerState !== RouterStates.HOMEPAGE &&
      this.state.routerState !== RouterStates.CONTACT_DEALER &&
      this.state.routerState !== RouterStates.COMPARISON &&
      this.state.routerState !== RouterStates.PRINT_COMPARISON &&
      this.state.routerState !== RouterStates.PRINT_COMPARISON_FROM_TIRESIZE &&
      this.state.routerState !== RouterStates.LANDING_PAGE
    );
  };

  isValidStateForCampaignDealerInfo = () => {
    return (
      isNationalSite() &&
      (this.state.routerState === RouterStates.YEAR ||
        this.state.routerState === RouterStates.MAKE ||
        this.state.routerState === RouterStates.CATEGORY ||
        this.state.routerState === RouterStates.MODEL ||
        this.state.routerState === RouterStates.TRIM)
    );
  };

  renderSearchModal = () => {
    if (this.props.toggleSearch) {
      if (this.enableFullScreenSearch()) {
        return <SearchModalFullScreen />;
      } else {
        return <SearchModal />;
      }
    } else {
      return false;
    }
  };

  enableFullScreenSearch = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mazda":
        return true;
      default:
        return false;
    }
  };

  renderCaptchaModal = () => {
    if (this.state.getCaptchaToken) {
      return <RecaptchaModal />;
    } else {
      return false;
    }
  };

  renderTireSizeHelperModal = () => {
    var tireSizeHelperImage =
      this.state.lang == "fr"
        ? "/static/img/tyre_bg_fr.jpg"
        : "/static/img/tyre_bg.jpg";
    if (this.state.showTireSizeHelper) {
      return (
        <Modal
          refactor={true}
          closeButtonAdobeInfo={true}
          requestUnmount={this.onCloseTireSizeHelperModal}
          datadtm={
            applicationStore.data.tireSizeHelperFromTrim
              ? ADOBELAUNCHER_DTM.FINDTIRESIZE
              : ADOBELAUNCHER_DTM.SHOPBYTIRESIZE
          }
        >
          <div className="wrapper">
            <div className="find-tire-size-modal">
              <div className="helper-image">
                <div className="title">
                  <span>{this.t("findMyTireSize.title")}</span>
                </div>
                <div className="image">
                  <Image src={tireSizeHelperImage} />
                </div>
              </div>
              <div className="helper-tag">
                <div className="tag">
                  <span>{this.t("findMyTireSize.tag")}</span>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  renderPricingAnalysisModal = () => {
    if (this.state.showPricingAnalysis) {
      return <PricingAnalysis />;
    } else {
      return false;
    }
  };

  renderPricingIncentiveModal = () => {
    if (this.state.showPricingIncentive) {
      return <PricingIncentive />;
    } else {
      return false;
    }
  };

  renderQuoteReportModal = () => {
    if (this.props.quoteReport) {
      return <QuoteReport />;
    } else {
      return false;
    }
  };

  renderOrderReportModal = () => {
    if (this.props.leadReport) {
      return <OrderReport />;
    }
  };

  renderPartStockModal = () => {
    if (this.state.showNationalPartStockModal) {
      return <NationalPartStock />;
    } else {
      return false;
    }
  };

  renderPartPriceModal = () => {
    if (this.state.showNationalPartPricingModal) {
      return <NationalPartPrice />;
    } else {
      return false;
    }
  };

  renderGuestAccountCreationModal = () => {
    if (this.state.guestAccountCreationModal) {
      return <GuestAccountCreationModal />;
    } else {
      return false;
    }
  };

  renderForgotPasswordModal = () => {
    if (this.props.forgotPassword) {
      return <ForgotPasswordModal />;
    } else {
      return false;
    }
  };

  renderWishlistFormModal = () => {
    if (this.props.formModal) {
      return <WishlistFormModal formType={this.props.formType} />;
    } else {
      return false;
    }
  };

  renderWishlistDealerFormModal = () => {
    if (shoppingCartStore.data.get("enableWishlistDealerFormModal")) {
      return (
        <WishlistDealerFormModal
          formType={shoppingCartStore.data.get("wishlistDealerFormType")}
        />
      );
    } else {
      return false;
    }
  };

  renderWishlistAppointmentIntegrationModal = () => {
    if (
      shoppingCartStore.data.get("enableWishlistAppointmentInegrationModal")
    ) {
      return <WishlistAppointmentIntegrationModal />;
    } else {
      return false;
    }
  };

  renderMarkAsCompleteModal = () => {
    return renderMarkAsCompleteModal();
  };

  renderPromotionModal = () => {
    if (productListStore.data.showPromotionModal) {
      return (
        <EmailPromotionModal
          itemType={productListStore.data.promotionModalItemType}
          itemId={productListStore.data.promotionModalItemId}
          pricingContextId={
            productListStore.data.promotionModalPricingContextId
          }
        />
      );
    } else {
      return false;
    }
  };

  renderSupportPanel = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "volkswagen_2020":
        return false;
      default:
        if (isUserLoggedIn()) {
          return (
            <SupportPane
              ref="tirewizard-support-pane"
              adjustSupportPane={this.isValidStateForNavOffset()}
            />
          );
        } else {
          return false;
        }
    }
  };

  renderCartModal = () => {
    if (this.props.cart.length !== 0 && this.props.toggleCart) {
      return <CartModal shoppingCart={this.props.cartInfo} />;
    } else {
      return false;
    }
  };

  renderShoppingCart = () => {
    if (this.props.cart.length !== 0 && this.props.toggleCart) {
      return <ShoppingCart />;
    } else {
      return false;
    }
  };

  renderShoppingCartModal = () => {
    if (this.isValidStateForCart(true) && this.props.toggleCartModal) {
      return <WishlistModal />;
    } else {
      return false;
    }
  };

  shouldHideCart = () => {
    return (
      this.state.shoppingContext.isEcommerce ||
      this.state.shoppingContext.isOrder
    );
  };

  renderContactDealerModal = () => {
    if (this.state.showContactDealerInfo) {
      return (
        <Modal
          refactor={true}
          useShortHeaderBar={true}
          requestUnmount={this.toggleContactDealerModal}
        >
          <div className="wrapper">
            <div className="modal-content">
              <span className="title">{this.state.dealerInfo.name}</span>
              <span>
                {Immutable.fromJS([
                  this.state.dealerInfo.address,
                  this.state.dealerInfo.city,
                  this.state.dealerInfo.province,
                  this.state.dealerInfo.postalCode,
                  this.state.dealerInfo.phone,
                  this.state.dealerInfo.email,
                ])
                  .filter(function (value) {
                    return value;
                  })
                  .toArray()
                  .join(", ")}
              </span>
              {false ? (
                <span
                  className="open-contact-page"
                  tabIndex={getTabIndex()}
                  onClick={this.renderContactDealerModal}
                >
                  {this.t("contactUs")}
                </span>
              ) : (
                false
              )}
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  renderXtimeLinkFailedModal = () => {
    if (this.state.dealerXtimeAppointmentIntegrationError) {
      return (
        <Modal
          refactor={true}
          useShortHeaderBar={true}
          requestUnmount={this.closeXtimeLinkFailedModal}
        >
          <div className="wrapper">
            <div className="modal-content">
              <span>{"Xtime link has failed to load, please try again."}</span>
              <div className="button-container">
                <button
                  onClick={this.closeXtimeLinkFailedModal}
                  tabIndex={getTabIndex()}
                  aria-label={this.t("common.close")}
                  className="btn"
                >
                  {this.t("common.close")}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  closeXtimeLinkFailedModal = () => {
    applicationActions.resetXtimeLinkError();
  };

  renderNoConnection = (httpStatus) => {
    return <NoConnectionPage httpStatusCode={httpStatus} />;
  };

  renderOutdatedApp = () => {
    return <OutdatedApplicationVersion />;
  };

  renderCampaignIsInactive = () => {
    return (
      <div className="application-wrapper">
        {this.renderLoadingScreen()}
        <div className="application-header">
          <div className="header-container">
            <div className="header">
              <div className="container header-cont">{this.renderLogo()}</div>
            </div>
          </div>
        </div>
        <div className="application-body">
          <div className="body-container">
            <div className="content-wrapper">
              <div className="campaign-inactive-page">
                <img
                  id={getTestId(TEST_LIST.HEADER.LOGO)}
                  className="campaign-inactive-logo"
                  src={getTemplateLogo()}
                />
                <span className="campaign-inactive-message">
                  {this.t("campaign.campaignInactiveMessage")}
                </span>
                <span className="campaign-inactive-message">
                  {isNationalSite()
                    ? this.t("campaign.campaignInactiveMessageNational")
                    : this.t("campaign.campaignInactiveMessageDealer")}
                </span>
                <button
                  className="btn campaign-inactive-button"
                  onClick={this.resetCampaignIsInactive}
                >
                  {isNationalSite()
                    ? this.t("campaign.campaignInactiveButtonNational")
                    : this.t("campaign.campaignInactiveButtonDealer")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderPrintWishlist = () => {
    return (
      <div className="application-print">
        <PrintWishlist />
      </div>
    );
  };

  renderPrintComparison = () => {
    return (
      <div className="application-print comparison-print">
        <ComparisonPage />
      </div>
    );
  };

  toggleContactDealerModal = () => {
    this.setState({ showContactDealerInfo: !this.state.showContactDealerInfo });
  };

  toggleSupportPanel = () => {
    this.props.dispatch(toggleSupport(!this.props.supportVisible));
  };

  renderNTDOrderModal = () => {
    if (this.state.enableNTDOrderModal) {
      return (
        <NTDOrderModal
          onClose={this.onCloseNTDOrderModal}
          enablePrompt={this.state.enableNTDOrderModalXtimePrompt}
        />
      );
    } else {
      return false;
    }
  };

  renderProductWarning = () => {
    if (this.state.showWarning) {
      return <WarningModal />;
    } else {
      return false;
    }
  };

  onCloseTireSizeHelperModal = () => {
    applicationActions.onCloseTireSizeHelper();
  };

  onCloseNTDOrderModal = () => {
    this.setState({ enableNTDOrderModal: false });
  };

  returnToDealerSelection = () => {
    shoppingCartStore.emptyCart();
    shoppingCartViewStore.resetShoppingCartView();
    applicationStore.data.cart = null;
    applicationActions.changeRouterStateDirectly(RouterStates.DEALER_SELECTION);
  };

  openReturnToDealerSelectionWarning = () => {
    applicationActions.openReturnToDealerSelectionWarning();
  };

  closeReturnToDealerSelectionWarning = () => {
    applicationActions.closeReturnToDealerSelectionWarning();
  };

  onCloseExternalLinkWarningModal = () => {
    applicationActions.closeExternalLinkWarningModal();
  };

  renderShoppingCartDetail = () => {
    if (this.state.shoppingCartDetail) {
      return <ShoppingCartDetail />;
    } else {
      return false;
    }
  };

  renderStartNewQuoteModal = () => {
    if (this.state.showStartNewQuoteModal) {
      return (
        <Modal
          refactor={true}
          useShortHeaderBar={true}
          requestUnmount={this.onCloseStartNewQuoteModal}
          datadtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.GLOBALNAV.RESTART)}
        >
          <div className="wrapper">
            <div className="modal-content">
              <span>
                {this.state.routerState === RouterStates.WISHLIST
                  ? this.t("common.startNewQuoteFromHomeTag")
                  : this.t("common.startNewQuoteTag")}
              </span>
              <div className="button-container">
                <button
                  onClick={this.onStartNewQuote}
                  tabIndex={getTabIndex()}
                  ref="btnStartNewQuote"
                  aria-label={this.t("common.continue")}
                  className={setAdobeLaunchClass(
                    "btn continue",
                    ADOBELAUNCHER_CLASS.BUTTON.LINK
                  )}
                  data-dtm={setAdobeLaunchDTM(
                    "",
                    ADOBELAUNCHER_DTM.GLOBALNAV.RESTART
                  )}
                >
                  {this.t("common.continue")}
                </button>
                <button
                  onClick={this.onCloseStartNewQuoteModal}
                  tabIndex={getTabIndex()}
                  aria-label={this.t("common.close")}
                  className={setAdobeLaunchClass(
                    "btn",
                    ADOBELAUNCHER_CLASS.BUTTON.LINK
                  )}
                  data-dtm={setAdobeLaunchDTM(
                    "",
                    ADOBELAUNCHER_DTM.GLOBALNAV.RESTART
                  )}
                >
                  {this.t("common.close")}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  authenticationStateModal = () => {
    if (this.props.toggleAuthModal && !this.props.expiredToken) {
      var loginText = isUserLoggedIn(true)
        ? this.t("common.loginSuccessful")
        : this.t("common.logoutSuccessful");
      return (
        <Modal
          refactor={true}
          useShortHeaderBar={true}
          requestUnmount={this.onCloseAuthenticationStateModal}
        >
          <div className="wrapper">
            <div className="modal-content">
              <span>{loginText}</span>
              <div className="button-container">
                <button
                  onClick={this.onCloseAuthenticationStateModal}
                  tabIndex={getTabIndex()}
                  aria-label={this.t("common.close")}
                  className="btn"
                >
                  {this.t("common.close")}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  loginExpiredModal = () => {
    if (
      !applicationStore.data.enableMercedesFeatures &&
      this.state.loginHasExpired &&
      !this.props.toggleAuthModal
    ) {
      return (
        <Modal
          refactor={true}
          useShortHeaderBar={true}
          requestUnmount={this.onCloseLoginHasExpiredModal}
        >
          <div className="wrapper">
            <div className="modal-content">
              <span>{this.t("loginExpired")}</span>
              <div className="button-container">
                <button
                  onClick={this.onToggleLoginDialogFromExpiredSession}
                  tabIndex={getTabIndex()}
                  aria-label={this.t("login")}
                  className="btn"
                >
                  {this.t("login")}
                </button>
                <button
                  onClick={this.onCloseLoginHasExpiredModal}
                  tabIndex={getTabIndex()}
                  aria-label={this.t("common.close")}
                  className="btn"
                >
                  {this.t("common.close")}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  onCloseLoginHasExpiredModal = () => {
    applicationActions.resetLoginHasExpired();
  };

  onStartNewQuote = () => {
    this.props.dispatch(renderSavingToggleState(false));
    // this.props.dispatch(emptyShoppingCart());
    this.props.dispatch(getNewCart());
    // shoppingCartActions.getNewShoppingCart();
    applicationActions.closeStartNewQuoteModal();
    if (this.state.routerState === RouterStates.WISHLIST) {
      applicationActions.changeRouterStateDirectly(RouterStates.HOMEPAGE);
    }
  };

  onOpenStartNewQuoteModal = () => {
    applicationActions.openStartNewQuoteModal();
  };

  onCloseStartNewQuoteModal = () => {
    applicationActions.closeStartNewQuoteModal();
  };

  onCloseAuthenticationStateModal = () => {
    this.props.dispatch(toggleAuthenticationStateModal(false));
    // applicationActions.closeAuthenticationStateModal();
  };

  renderExternalLinkWarningModal = () => {
    if (this.state.externalLinkModalFlag && this.state.externalLink !== "") {
      return (
        <Modal
          refactor={true}
          useShortHeaderBar={true}
          requestUnmount={this.onCloseExternalLinkWarningModal}
        >
          <div className="wrapper">
            <div className="modal-content">
              <span>{this.t("common.externalLinkWarning")}</span>
              <span>{this.state.externalLink}</span>
              <div className="button-container">
                <a
                  href={this.state.externalLink}
                  target="_blank"
                  className="btn"
                >
                  {this.t("common.continue")}
                </a>
                <button
                  onClick={this.onCloseExternalLinkWarningModal}
                  className="btn"
                >
                  {this.t("common.close")}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  returnToDealerSelectionWarningModal = () => {
    if (
      this.state.showReturnToDealerSelectionWarning &&
      applicationStore.data.useProvincialDealers
    ) {
      return (
        <Modal
          refactor={true}
          useShortHeaderBar={true}
          requestUnmount={this.closeReturnToDealerSelectionWarning}
        >
          <div className="wrapper">
            <div className="modal-content">
              <span>{this.t("provinceSelectionWarning")}</span>
              <span className="paragraph-continue">
                {this.t("wishToContinue") + "?"}
              </span>
              <div className="button-container">
                <button
                  onClick={this.onGoDealerSelect}
                  className="btn button-continue"
                >
                  {this.t("common.continue")}
                </button>
                <button
                  onClick={this.closeReturnToDealerSelectionWarning}
                  className="btn button-cancel"
                >
                  {this.t("common.cancel")}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  renderDealerForceLoginModal = () => {
    if (
      !this.state.guestAccountCreationModal &&
      !this.state.forgotPasswordModal &&
      applicationStore.data.dealerSite &&
      this.state.routerState !== RouterStates.WISHLIST &&
      applicationStore.data.dealerForceSiteLogin &&
      !isUserLoggedIn()
    ) {
      if (applicationStore.data.enableMercedesFeatures) {
        return <LoginDialogRefactored failure={this.state.failure} />;
      } else {
        return (
          <Modal>
            <div className="login-modal">
              <div className="login-modal-title">
                <h2>
                  {applicationStore.data.dealerInfo.name}{" "}
                  {this.t("loginModal.dealerRequiresUserLogin")}
                </h2>
              </div>
              <LoginDialog failure={this.state.failure} />
            </div>
          </Modal>
        );
      }
    } else if (this.props.forceUserLogin && !isUserLoggedIn()) {
      if (applicationStore.data.enableMercedesFeatures)
        return <LoginDialogRefactored failure={this.state.failure} />;
      else return <ForceLoginDialog failure={this.state.failure} />;
    } else {
      return false;
    }
  };

  openSelectedSearch = (producttype) => {
    applicationActions.setSearchProductTypeValue(producttype);
    setTimeout(() => {
      this.props.dispatch(toggleSearch(!this.props.toggleSearch));
    }, 100);
  };

  changeLang = (lang) => {
    var routerState = getRouterState();
    if (routerState !== RouterStates.LANDING_PAGE) {
      this.props.dispatch(changeLang(lang));
      applicationActions.changeLanguage(lang);
      // setTimeout(function () { applicationActions.changeLanguage(lang); }, 100);
    } else {
      if (isNationalSite())
        window.location.href =
          "/national/" +
          applicationStore.data.nationalUrlCode +
          "/" +
          lang +
          "/landingpage";
      else if (isGroupSite())
        window.location.href =
          "/group/" +
          applicationStore.data.groupUrlCode +
          "/" +
          lang +
          "/landingpage";
    }

    if (enableAdobeLaunch()) setAdobeDigitalData(setAdobeLauncherDigitalData());
  };

  onGoHomepage = () => {
    if (isConsumerSite()) {
      window.location.reload();
    } else {
      var routerState = getRouterState();
      if (
        routerState !== RouterStates.LANDING_PAGE &&
        routerState !== RouterStates.DEALER_SELECTION
      ) {
        if (routerState === RouterStates.WISHLIST)
          shoppingCartActions.duplicateCart();
        applicationActions.changeRouterStateDirectly(RouterStates.HOMEPAGE);
      }
    }
  };

  onGoDealerSelect = () => {
    applicationActions.changeRouterStateDirectly(RouterStates.DEALER_SELECTION);
    applicationActions.closeReturnToDealerSelectionWarning();
  };

  onGoPricingAnalysis = () => {
    applicationActions.pricingAnalysisToggle();
  };

  onGoPricingIncentive = () => {
    applicationActions.pricingIncentiveToggle();
  };

  onGoQuoteReport = () => {
    this.props.dispatch(showQuoteReport(true));
    // applicationActions.openQuoteReport(false);
  };

  onGoOrderReport = () => {
    this.props.dispatch(showLeadReport(true));
    applicationActions.openOrderReport(true);
  };

  onGoLeadReport = () => {
    shoppingCartActions.loadQuoteReport();
    this.props.dispatch(showLeadReport(true));
    applicationActions.openQuoteReport(false, true);
  };

  onToggleSearch = () => {
    this.props.dispatch(toggleSearch(!this.props.toggleSearch));
    this.cartItemWasSeen();
  };

  onToggleCartModal = () => {
    this.props.dispatch(toggleCart(!this.props.toggleCart));
  };

  onToggleCartBar = () => {
    this.props.dispatch(toggleCart(!this.props.toggleCart));
  };

  onLoginTextPopoverRequestClose = () => {
    // console.trace()
    this.props.dispatch(toggleLoginDialog(false));
    applicationActions.closeLoginTextDialog();
  };

  resetCampaignIsInactive = () => {
    applicationActions.resetCampaignIsInactive();
  };

  onToggleLoginDialog = () => {
    if (this.state.loginDialogShown) {
      applicationActions.closeLoginDialog();
    } else {
      applicationActions.openLoginDialog();
    }
  };

  onToggleLoginTextDialog = () => {
    this.props.dispatch(signUpRequestModal(false));
    this.props.dispatch(toggleLoginDialog(!this.props.loginVisible));
    // if (this.state.loginTextDialogShown) {
    //   applicationActions.closeLoginTextDialog();
    // } else {
    //   applicationActions.openLoginTextDialog();
    //   this.cartItemWasSeen();
    // }
  };

  onToggleLoginDialogFromExpiredSession = () => {
    applicationActions.resetLoginHasExpired();
    this.props.dispatch(toggleLoginDialog(!this.props.loginVisible));
    // setTimeout(() => {
    //   applicationActions.openLoginTextDialog();
    // }, 100);
  };

  onLogOut = () => {
    applicationActions.logOut();
    this.props.dispatch(toggleAuthenticationStateModal(true));
  };

  cartItemWasSeen = () => {
    setTimeout(() => {
      this.props.dispatch(cartItemSeen(true));
      // shoppingCartActions.cartNewItemSeen()
    }, 10);
  };

  onChange = () => {
    this.setState({
      lang: getAppLanguage(),
      routerState: getRouterState(),
      apiCallInProgress: false,
      loggedIn: applicationStore.data.loggedIn,
      loginDialogShown: applicationStore.data.loginDialogShown,
      loginTextDialogShown: this.props.loginVisible,
      loginHasExpired: this.props.expiredToken,
      failure: applicationStore.data.loginFailure,
      dealerInfo: applicationStore.data.dealerInfo,
      siteDisabled: applicationStore.data.siteDisabled,
      productType: applicationStore.data.productType,
      productTypes: applicationStore.data.productTypes,
      shoppingCartDetail: shoppingCartStore.data.get("detail"),
      searchIsActiveFlag: applicationStore.data.searchIsActiveFlag,
      showPricingAnalysis: applicationStore.data.showPricingAnalysis,
      showPricingIncentive: applicationStore.data.showPricingIncentive,
      showQuoteReport: this.props.quoteReport,
      showOrderReport: applicationStore.data.showOrderReport,
      guestAccountCreationModal:
        applicationStore.data.guestAccountCreationModal,
      forgotPasswordModal: applicationStore.data.forgotPasswordModal,
      showNationalPartStockModal:
        applicationStore.data.showNationalPartStockModal,
      showNationalPartPricingModal:
        applicationStore.data.showNationalPartPricingModal,
      showReturnToDealerSelectionWarning:
        applicationStore.data.returnToDealerSelectionWarning,
      externalLinkModalFlag: applicationStore.data.externalLinkModalFlag,
      externalLink: applicationStore.data.externalLink,
      showStartNewQuoteModal: applicationStore.data.showStartNewQuoteModal,
      enableNTDOrderModal: applicationStore.data.enableNTDOrderModal,
      enableNTDOrderModalXtimePrompt:
        applicationStore.data.enableNTDOrderModalXtimePrompt,
      showWarning: productListStore.data.showWarning,
      showTireSizeHelper: applicationStore.data.showTireSizeHelper,
      forceUserLogin: applicationStore.data.forceUserLogin,
      shoppingContext: Router.collectShoppingContext(),
      getCaptchaToken: applicationStore.data.getCaptchaToken,
      campaignIsInactive: applicationStore.data.campaignIsInactive,
      appRunningOutdatedVersion:
        applicationStore.data.appRunningOutdatedVersion,
      longInitialDataLoad: applicationStore.data.longInitialDataLoad,
      dealerXtimeAppointmentIntegrationError:
        applicationStore.data.dealerXtimeAppointmentIntegrationError,
    });
  };

  state = {
    lang: getAppLanguage(),
    routerState: getRouterState(),
    apiCallInProgress: false,
    loggedIn: this.props.dispatch(setLogin()),
    loginDialogShown: applicationStore.data.loginDialogShown,
    loginTextDialogShown: this.props.loginVisible,
    loginHasExpired: this.props.expiredToken,
    dealerInfo: applicationStore.data.dealerInfo,
    siteDisabled: applicationStore.data.siteDisabled,
    errorPage: applicationStore.errorPage,
    productType: applicationStore.data.productType,
    productTypes: applicationStore.data.productTypes,
    // shoppingCartDetail: shoppingCartStore.data.get('detail'),
    searchIsActiveFlag: applicationStore.data.searchIsActiveFlag,
    showPricingAnalysis: applicationStore.data.showPricingAnalysis,
    showPricingIncentive: applicationStore.data.showPricingIncentive,
    showQuoteReport: this.props.quoteReport,
    showOrderReport: applicationStore.data.showOrderReport,
    guestAccountCreationModal: applicationStore.data.guestAccountCreationModal,
    forgotPasswordModal: applicationStore.data.forgotPasswordModal,
    showNationalPartStockModal:
      applicationStore.data.showNationalPartStockModal,
    showNationalPartPricingModal:
      applicationStore.data.showNationalPartPricingModal,
    showReturnToDealerSelectionWarning:
      applicationStore.data.returnToDealerSelectionWarning,
    externalLinkModalFlag: applicationStore.data.externalLinkModalFlag,
    externalLink: applicationStore.data.externalLink,
    showStartNewQuoteModal: applicationStore.data.showStartNewQuoteModal,
    showOnlyLoadingPage: applicationStore.data.showOnlyLoadingPage,
    enableNTDOrderModal: applicationStore.data.enableNTDOrderModal,
    enableNTDOrderModalXtimePrompt:
      applicationStore.data.enableNTDOrderModalXtimePrompt,
    showWarning: productListStore.data.showWarning,
    showContactDealerInfo: false,
    showMobileAlwaysMenu: false,
    showTireSizeHelper: applicationStore.data.showTireSizeHelper,
    loadingCounter: 0,
    loadingSuffixFunc: function () {},
    forceUserLogin: applicationStore.data.forceUserLogin,
    shoppingContext: Router.collectShoppingContext(),
    apiContentTransition: TRANSITION_FADE,
    apiContentTransitionImage: LOADING_IMAGE_SKINNY,
    getCaptchaToken: applicationStore.data.getCaptchaToken,
    campaignIsInactive: applicationStore.data.campaignIsInactive,
    appRunningOutdatedVersion: applicationStore.data.appRunningOutdatedVersion,
    longInitialDataLoad: applicationStore.data.longInitialDataLoad,
    showFinancingModal: false,
    errorMenuEmail: false,
    errorMenuPassword: false,
    dealerXtimeAppointmentIntegrationError:
      applicationStore.data.dealerXtimeAppointmentIntegrationError,
  };
}

function mapStateToProps(state) {
  const cart = selectCartItems(state);
  return {
    supportVisible: state.info.supportVisible,
    loginVisible: state.info.loginDialog,
    toggleLoginTextDialog: state.info.loginTextDialogShown,
    forceUserLogin: state.info.forceUserLogin,
    toggleSearch: state.info.toggleSearchFlag,
    quoteReport: state.quote.showQuoteReport,
    leadReport: state.quote.showLeadReport,
    forgotPassword: state.info.forgotPasswordModal,
    loginStatus: state.login.loginStatus,
    toggleCart: state.shoppingCart.toggleCart,
    toggleCartModal: state.shoppingCart.toggleCartModal,
    total: state.shoppingCart.total,
    cart,
    itemsCount: cart.length,
    isLoggedIn: state.info.loginIn,
    routeState: state.routes.routerState,
    formType: state.shoppingCart.formType,
    formModal: state.shoppingCart.formModal,
    toggleAuthModal: state.info.openAuthenticationStateModal,
    expiredToken: state.info.expiredToken,
    cartInfo: state.shoppingCart.cartInformation,
    reduxCall: state.productList.reduxCall,
    applicationLanguage: state.info.lang,
  };
}

export default connect(mapStateToProps)(ApplicationRefactor);

import { breadcrumbActions } from "../../actions/BreadcrumbActions.js";
import { vehicleSelectionActions } from "../../actions/VehicleSelectionActions.js";
import { vehicleSelectionStore } from "../../stores/VehicleSelectionStore.js";

import { Image } from "../ux/Image.jsx";

import {
  getTabIndex,
  scrollToPageTop,
  getTrackingEventCategory,
  getAppLanguage,
} from "../../service/UtilityService";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";

import { RouterStates } from "../../constants/RouterStates.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var _ = require("lodash");
var React = require("react");

class VehicleSelectionModels extends I18nComponent {
  state = {
    models: [],
    skipModels: false,
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("Vehicle Select Model");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Vehicle Select Model Access"
    );
    vehicleSelectionStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    vehicleSelectionStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    //setTimeout(() => { vehicleSelectionActions.loadModels(); }, 250);
    vehicleSelectionActions.loadModels();
  }
  componentDidUpdate(lastProps, lastState) {
    scrollToPageTop(this.state.models, lastState.models);
  }

  render() {
    return (
      <div className="main">
        <div className="container">
          <ul className="select-model">
            {_.map(
              this.state.models,
              function (model, index) {
                var image = false;
                if (model.vehicleModelImage) {
                  image = (
                    <Image
                      src={model.vehicleModelImage}
                      alt={model.vehicleModelName[getAppLanguage()]}
                      className={setAdobeLaunchClass(
                        "",
                        ADOBELAUNCHER_CLASS.IMAGE
                      )}
                      datadtm={setAdobeLaunchDTM(
                        "",
                        ADOBELAUNCHER_DTM.MODELSELECTION
                      )}
                    />
                  );
                }

                return (
                  <li
                    key={model.vehicleModelId}
                    role="button"
                    tabIndex={getTabIndex()}
                    aria-label={
                      this.t("breadcrumbs.model") +
                      ": " +
                      model.vehicleModelName[getAppLanguage()]
                    }
                    onKeyDown={(e) =>
                      this.onModelClick(
                        model.vehicleModelId,
                        model.vehicleModelName,
                        e
                      )
                    }
                    onClick={this.onModelClick.bind(
                      this,
                      model.vehicleModelId,
                      model.vehicleModelName
                    )}
                  >
                    {image}
                    <span
                      className={setAdobeLaunchClass(
                        "name",
                        ADOBELAUNCHER_CLASS.BUTTON.LINK
                      )}
                      data-dtm={setAdobeLaunchDTM(
                        "",
                        ADOBELAUNCHER_DTM.MODELSELECTION
                      )}
                    >
                      {model.vehicleModelName[getAppLanguage()]}
                    </span>
                    <span
                      className={setAdobeLaunchClass(
                        "btn",
                        ADOBELAUNCHER_CLASS.BUTTON.LINK
                      )}
                      data-dtm={setAdobeLaunchDTM(
                        "",
                        ADOBELAUNCHER_DTM.MODELSELECTION
                      )}
                    >
                      {this.t("common.select")}
                    </span>
                  </li>
                );
              },
              this
            )}
          </ul>
        </div>
      </div>
    );
  }

  onChange = () => {
    this.setState({
      models: vehicleSelectionStore.data.models,
    });
  };

  onModelClick = (modelId, model, evt) => {
    checkForEvent: if ("undefined" != typeof evt.keyCode) {
      if (evt.keyCode == 13 || evt.keyCode == 32) {
        evt.preventDefault();
        evt.stopPropagation();
        break checkForEvent;
      }
      return;
    }
    vehicleSelectionActions.modelSelected(modelId);
    breadcrumbActions.pushState(
      RouterStates.MODEL,
      modelId,
      model,
      false,
      true
    );
  };
}

export default VehicleSelectionModels;

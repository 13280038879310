import { applicationStore } from "../../stores/ApplicationStore";
import { shoppingCartViewStore } from "../../stores/ShoppingCartViewStore";
import { shoppingCartActions } from "../../actions/ShoppingCartActions";

import { Modal } from "../ux/Modal.jsx";

import {
  getAppLanguage,
  getTrackingEventCategory,
  getTrackingEventOrderId,
  isEmpty,
  money_format,
} from "../../service/UtilityService";
import { ProductTypes } from "../../constants/ProductTypes";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");

export class WishlistAppointmentIntegrationModal extends I18nComponent {
  state = {
    initialized: false,
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview(
      "Shopping Cart Quick View Form - Appointment Integration"
    );
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Shopping Cart Quick View Form Access - Appointment Integration",
      getTrackingEventOrderId()
    );
    shoppingCartViewStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    shoppingCartViewStore.removeChangeListener(this.onChange);
  }

  render() {
    return (
      <Modal refactor={true} requestUnmount={this.closeModal}>
        <div className="wrapper">
          <div className="modal-container">
            <div>
              <iframe
                className="appointment-integration-modal"
                src={this.getiFrameSource()}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  getiFrameSource = () => {
    var year = "",
      make = "",
      model = "",
      items = "",
      lang = "en-CA",
      vehicleInformation = shoppingCartViewStore.data.get("vehicleInformation"),
      id = applicationStore.data.dealerAppointmentIntegrationId,
      cartItems = shoppingCartViewStore.data.get("cartItems");
    if (!isEmpty(vehicleInformation.get(0))) {
      year = vehicleInformation.get(0).get("vehicleYear");
      make = vehicleInformation.get(0).get("vehicleMake");
      model = vehicleInformation.get(0).get("vehicleModel");
    }
    if (!isEmpty(cartItems)) {
      // &service:lineitems=4%20BRIDGESTONE%20DUELER%20A/T%20RH-S%20(PartNumber:19145327)%20for%20$203.85/EA.%20Installation%20for%20$16.00/EA.#!

      if (!isEmpty(cartItems.get(ProductTypes.TIRES))) {
        var item = cartItems.get(ProductTypes.TIRES).get(0),
          quantity = item.get("quantity").get("value"),
          brand = item.get("tireManufacturerName"),
          name = item.get("title").get(getAppLanguage()),
          partnumber = item.get("partNumber"),
          price = item.get("price"),
          installprice = false;

        if (!isEmpty(item.get("lineItems"))) {
        }

        items =
          "lineitems=" +
          quantity +
          "%20" +
          encodeURIComponent(brand) +
          "%20" +
          encodeURIComponent(name) +
          "%20" +
          "(PartNumber:" +
          partnumber +
          ")%20" +
          "for%20" +
          money_format(price) +
          "%20/EA." +
          (installprice
            ? "%20Installation%20for%20" +
              money_format(installprice) +
              "%20/EA."
            : "") +
          "#!/ymm";
      }
    }
    return (
      "https://oss.gm.com/oss/o/d/CA/" +
      id +
      "?vehicle:buy:year=" +
      year +
      "&vehicle:buy:make=" +
      make +
      "&vehicle:buy:model=" +
      model +
      "&langCd=" +
      lang +
      "&origin=tirewizard" +
      "&hideReturn=true" +
      "&service:" +
      items
    );
  };

  closeModal = () => {
    shoppingCartActions.closeWishlistAppointmentIntegrationModal();
  };

  onChange = () => {
    this.setState({
      initialized: true,
    });
  };
}

module.exports = {
    'product': {
        'contactDealerForPricing': 'Contact dealer for pricing',
        'requiresOtherItems': 'Requires other items',
        'requiredItems': 'Required Items',
        'price': 'Price',
        'partNumber': 'Part #',
        'youSave': 'You Save',
        'carColour': 'Colour of your vehicle',
        'wheelDiameter': 'Wheel Diameter',
        'accessoryCategories': 'Accessory categories',
        'collectionCategories': 'Collection categories',
        'tireSize': 'Tire Size',
        'tireSizeAndSeason': 'Tire Size and Season',
        'tireBrands': 'Tire Brands',
        'loadRating': 'Load Rating',
        'speedRating': 'Speed Rating',
        'loadAndSpeedRating': 'Load / Speed (minimum)',
        'quantity': 'Quantity',
        'total': 'Total',
        'requiresInstallation': 'Requires Installation',
        'perTire': 'per Tire',
        'perFrontTire': 'per Front Tire',
        'perRearTire': 'per Rear Tire',
        'perWheel': 'per Wheel',
        'perFrontWheel': 'per Front Wheel',
        'perRearWheel': 'per Rear Wheel',
        'perPackage': 'per Package',
        'perItem': 'per Item',
        'inStock': 'in stock',
        'categories': 'Category',
        'pricingNotes': {
            'title': 'Pricing Notes',
            'description': 'Environmental fees may apply. Installation fees are priced separately. Additional charges may apply.'
        },
        'wheelFinishTypes': {
            'alloy': 'Alloy',
            'steel': 'Steel'
        },
        'addToQuote': 'Add to cart',
        'removeFromQuote': 'Remove from cart',
        'appointment': 'Appointment',
        'front': 'Front',
        'rear': 'Rear',
        'loadSpeed': 'Load / Speed',
        'tire': 'Tire',
        'rimDiameter': 'Rim Diameter',
        'wheelFinish': 'Wheel Finish',
        'details': 'Details',
        'approvedTire': 'Original Equipment',
        'frontAndRearWheel': 'Front and Rear Wheel',
        'frontWheel': 'Front Wheel',
        'rearWheel': 'Rear Wheel',
        'frontTire': 'Front Tire',
        'rearTire': 'Rear Tire',
        'staggeredFittment': 'Staggered Fitment',
        'selectAccessoryCategory': 'All Categories',
        'requestAppointment': 'Get A Quote',
        'runFlat': 'Run flat tire',
        'filters': {
            'filterResults': 'Showing Results For',
            'brand': {
                'noSelection': 'Select Brand',
                'allSelected': 'All Brands',
                'otherSelected': 'Brands',
                'selectAll': 'Select All',
                'deselectAll': 'Deselect All'
            },
            'season': {
                'noSelection': 'Select Season',
                'allSeason': 'All Season',
                'winter': 'Winter',
                'summer': 'Summer'
            },
            'loadRating': {
                'noSelection': 'Select Load Rating'
            },
            'tireSize': {
                'noSelection': 'Select Tire Size'
            },
            'speedRating': {
                'noSelection': 'Select Speed Rating'
            },
            'plyRating': {
                'noSelection': 'Select Ply Rating'
            },
            'diameter': {
                'noSelection': 'Select Diameter',
                'original': 'Original size:',
                'inchDiameter': 'inch Diameter'
            }
        }
    }
};

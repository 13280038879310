import { DateUtilities } from "../calendar/DateUtilities.jsx";

var React = require("react");

export class Week extends React.Component {
  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ selected: DateUtilities.clone(nextProps.selected) });
  }

  buildDays = (start) => {
    var days = [DateUtilities.clone(start)],
      clone = DateUtilities.clone(start);
    for (var i = 1; i <= 6; i++) {
      clone = DateUtilities.clone(clone);
      clone.setDate(clone.getDate() + 1);
      days.push(clone);
    }
    return days;
  };

  isOtherMonth = (day) => {
    return this.props.month !== day.month();
  };

  getDayClassName = (day) => {
    var className = "day";
    if (this.props.month !== day.getMonth()) className += " other-month";
    if (
      this.state.selected &&
      DateUtilities.isSameDay(day, this.state.selected)
    )
      className += " selected";
    if (this.isDisabled(day)) className += " disabled";
    return className;
  };

  onSelect = (day) => {
    if (!this.isDisabled(day)) {
      this.props.onSelectDay(day);
      this.props.onSelect(day);
    }
  };

  isDisabled = (day) => {
    var minDate = this.props.minDate,
      maxDate = this.props.maxDate;
    return (
      !this.props.isDateAvailable(
        day,
        this.props.calendarProps.skipDaysAvailable
      ) ||
      DateUtilities.isBefore(day, minDate) ||
      DateUtilities.isAfter(day, maxDate)
    );
  };

  state = {
    selected: DateUtilities.clone(this.props.selected),
  };

  render() {
    var days = this.buildDays(this.props.start);
    return React.createElement(
      "div",
      { className: "week" },
      days.map(
        function (day, i) {
          return React.createElement(
            "div",
            {
              key: i,
              onClick: this.onSelect.bind(null, day),
              className: this.getDayClassName(day),
            },
            DateUtilities.toDayOfMonthString(day)
          );
        }.bind(this)
      )
    );
  }
}

import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";
import { shoppingCartStore } from "../../stores/ShoppingCartStore.js";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { applicationActions } from "../../actions/ApplicationActions.js";
import ShoppingCartItem from "./ShoppingCartItem.jsx";

import { Modal } from "../ux/Modal.jsx";
import { globalState } from "../../GlobalState.js";
import { ProductTypes } from "../../constants/ProductTypes.js";
import { isUserLoggedIn } from "../../api/AuthService";
import { getCodeText } from "../../service/ProductList.js";
import { renderNTDButton } from "../../service/OrderService.js";
import {
  getTabIndex,
  sizeOf,
  getAppLanguage,
  getTrackingEventCategory,
} from "../../service/UtilityService";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";
import { connect } from "react-redux";
import {
  cartItemSeen,
  emptyShoppingCart,
  selectCartItems,
  toggleCart,
  wishListReview,
} from "../../redux/features/shoppingCart.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var Cx = require("classnames");

var CartWarnings = {
  ITEM_WARNING_TIRE_NOT_ORIGINAL_SIZE: "ITEM_WARNING_TIRE_NOT_ORIGINAL_SIZE",
  ITEM_WARNING_TIRE_NOT_OE: "ITEM_WARNING_TIRE_NOT_OE",
  ITEM_WARNING_TIRE_NOT_RUNFLAT: "ITEM_WARNING_TIRE_NOT_RUNFLAT",
  ITEM_WARNING_TIRE_WHEEL_MISMATCH: "ITEM_WARNING_TIRE_WHEEL_MISMATCH",
  ITEM_WARNING_TIRE_WHEEL_IDEAL_SIZE_MISMATCH:
    "ITEM_WARNING_TIRE_WHEEL_IDEAL_SIZE_MISMATCH",
};

class ShoppingCart extends I18nComponent {
  /**
   * This is saving our state that is coming in
   */
  componentDidUpdate(prevProps) {
    if (this.props.cart !== prevProps.cart) {
      this.setState({
        items: this.props.cart,
      });
    }
  }
  componentWillMount() {
    if (shoppingCartStore.isCartOpened()) {
      googleAnalyticsEnhancedTrackPageview("Shopping Cart");
      googleAnalyticsEnhancedTrackEvent(
        getTrackingEventCategory(),
        "Shopping Cart Access"
      );
    }
    globalState.select("applicationState").on("update", this.onChange);
    this.props.shoppingCart;
    // shoppingCartStore.addChangeListener(this.onChange);
    // shoppingCartViewStore.addChangeListener(this.onChange);
    // applicationStore.addChangeListener(this.onChange);
    // productListStore.addChangeListener(this.onChange);
    // searchStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    globalState.select("applicationState").off("update", this.onChange);

    // shoppingCartStore.removeChangeListener(this.onChange);
    // shoppingCartViewStore.removeChangeListener(this.onChange);
    // applicationStore.removeChangeListener(this.onChange);
    // productListStore.removeChangeListener(this.onChange);
    // searchStore.removeChangeListener(this.onChange);
  }

  shouldComponentUpdate(lastProps, nextState) {
    console.log(this.props !== lastProps || this.state !== nextState);
    return this.props !== lastProps || this.state !== nextState;
  }

  state = {
    expanded: this.props.expanded,
    items: this.props.cart,
    itemsCount: this.props.cartCount,
    totalPrice: shoppingCartStore.getTotalPrice(),
    quoteLink: shoppingCartStore.getQuoteLink(),
    wishListReviewLink: shoppingCartStore.getWishlistReviewLink(),
    shoppingCartViewLink: shoppingCartStore.getShoppingCartViewLink(),
    enableEcommerceRetailSite: shoppingCartStore.getEnableEcommerceRetailSite(),
    showWarning: shoppingCartStore.checkForWarnings(),
    showCartQuantityLimitWarning: shoppingCartStore.data.get(
      "showCartQuantityLimitWarning"
    ),
    loggedIn: isUserLoggedIn(),
    newItemAddedFlag: shoppingCartStore.data.get("newItemAddedFlag"),
    supportDealerOrder: this.props.supportDealerOrder,
    supportConsumerOrder: this.props.supportConsumerOrder,
  };

  render() {
    var cartClass = Cx({
      "shopping-cart-bar": true,
      expanded: this.state.expanded,
    });

    var cartBarClass = Cx({
      "cart-bar": true,
      expanded: this.state.expanded,
      empty: this.state.itemsCount === 0,
    });

    var ntdStockEnabled =
      this.state.loggedIn && applicationStore.data.dealerAllowNtdStockOrder;

    var itemCount = 0,
      partCount = this.state.items.length + 10,
      hasNtdOrderableItem =
        this.state.supportDealerOrder || this.props.supportConsumerOrder,
      supportQuoteAccess = this.props.supportDealerOrder;

    try {
      var ntdPartsList = this.state.items
        .filter((item) => item.supportDealerOrder || item.supportConsumerOrder)
        .map(function (item, index) {
          let quantity = item.quantity;
          if (typeof quantity === "object") quantity = quantity.value;
          itemCount += parseInt(quantity, 10);
          var className = Cx({
            "cart-item-div": true,
            "has-ntd-stock":
              (item.supportDealerOrder || item.supportConsumerOrder) &&
              index === 0,
          });

          var stock = [],
            stockCount = 0;
          if (item.type === ProductTypes.TIRES && item.supportDealerOrder) {
            if (item.id === item["product"]["frontTire"]["id"]) {
              stock = item["product"]["frontTire"]["stock"].filter(
                (stock) => stock["stockLocationType"] === "RLT"
              );
              if (sizeOf(stock) > 0) {
                stock.map(function (stock) {
                  stockCount += parseFloat(stock.stockQtyLocal);
                  stockCount += parseFloat(stock.stockQtyLocalPlus);
                  stockCount += parseFloat(stock.stockQtyNationWide);
                });
              }
            } else if (item.get("id") === item["product"]["rearTire"]["id"]) {
              stock = item["product"]["rearTire"]["id"].filter(
                (stock) => stock["stockLocationType"] === "RLT"
              );
              if (sizeOf(stock) > 0) {
                stock.map(function (stock) {
                  stockCount += parseFloat(stock.stockQtyLocal);
                  stockCount += parseFloat(stock.stockQtyLocalPlus);
                  stockCount += parseFloat(stock.stockQtyNationWide);
                });
              }
            }
          }

          return (
            <div key={index} className={className}>
              <ShoppingCartItem
                key={item["id"]}
                cartIndex={index}
                partCount={partCount}
                item={item}
                hasNTDStock={item["supportDealerOrder"]}
                ntdStockCount={stockCount}
              />
              <hr></hr>
            </div>
          );
        });
    } catch (error) {
      ntdPartsList = [];
      console.error(error);
    }

    var nonNtdPartsList = this.state.items
      .filter(
        (item) => !item["supportDealerOrder"] && !item["supportConsumerOrder"]
      )
      .map(function (item, index) {
        var quantity = item["quantity"];
        if (typeof quantity === "object") quantity = quantity["value"];
        itemCount += parseInt(quantity, 10);
        var className = Cx({
          "cart-item-div": true,
        });

        return (
          <div key={index} className={className}>
            <ShoppingCartItem
              key={item["id"]}
              cartIndex={index}
              partCount={partCount}
              item={item}
              hasNTDStock={false}
              ntdStockCount={0}
            />
            <hr></hr>
          </div>
        );
      });

    var itemAddMessage = false;
    if (this.state.newItemAddedFlag) {
      itemAddMessage = (
        <div className="cart-new-item-added-msg">
          <p>
            {this.t("common.itemAddedToCart")} {this.t("common.myCart")}.
          </p>
        </div>
      );
    }

    var cartItemClassNameNtd = Cx({
      "two-items": sizeOf(ntdPartsList) == 2,
      "three-items": sizeOf(ntdPartsList) == 3,
      "has-orderable-items": hasNtdOrderableItem,
    });

    var cartItemClassNameNonNtd = Cx({
      "two-items": sizeOf(nonNtdPartsList) == 2,
      "three-items": sizeOf(nonNtdPartsList) == 3,
      "has-orderable-items": hasNtdOrderableItem,
    });

    var title = false;
    var ariaHeaderLabel = "";
    var newQuoteBtnClassName = "start-new-quote bold pointer";
    if (
      !this.state.loggedIn ||
      (this.state.loggedIn &&
        !applicationStore.data.dealerAllowNtdStockOrder) ||
      !hasNtdOrderableItem
    ) {
      if (
        this.state.items.length > 0 &&
        this.t("cart.cartBarTitlePrefix") != "" &&
        this.t("cart.cartBarTitleSuffix1") != "" &&
        this.t("cart.cartBarTitleSuffix2") != ""
      ) {
        ariaHeaderLabel =
          itemCount +
          " " +
          (itemCount == 1
            ? this.t("cart.cartBarTitleSuffix1")
            : this.t("cart.cartBarTitleSuffix2"));
        title = (
          <h2
            tabIndex={getTabIndex()}
            className="shopping-cart-title"
            ref="quoteModalHeader"
            aria-label={ariaHeaderLabel}
          >
            {" "}
            ({itemCount}){" "}
            {itemCount == 1
              ? this.t("cart.cartBarTitleSuffix1")
              : this.t("cart.cartBarTitleSuffix2")}
          </h2>
        );
      }
    } else if (ntdStockEnabled) {
      title = (
        <span className="ntd-cart-title">
          {this.t("common.myCart") +
            " (" +
            itemCount +
            " " +
            this.t("cart.cartBarTitleSuffix2") +
            ")"}
        </span>
      );
      newQuoteBtnClassName = "start-new-quote has-ntd-stock-order bold pointer";
    }

    var closeImageLink = "/static/img/close_circle.png";
    switch (applicationStore.data.defaultTemplate) {
      case "mercedes":
        closeImageLink = "/static/img/mercedes/close.png";
        break;
      case "mercedes_2022":
        closeImageLink = "/static/img/mercedes/close.png";
        break;
      case "volkswagen":
        closeImageLink = "/static/img/volkswagen/close_icon.png";
        break;
      default:
        break;
    }

    return (
      <div className={cartClass}>
        <div className={cartBarClass}>
          <div className="shopping-cart-bar-header">
            <img
              className={setAdobeLaunchClass(
                "close-icon",
                ADOBELAUNCHER_CLASS.BUTTON.CLOSE
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                ADOBELAUNCHER_DTM.MYQUOTEMODAL.BASE
              )}
              alt="Icon Close"
              src={closeImageLink}
              onClick={this.onCloseCart}
            />
          </div>
          {this.renderCartQuantityLimitWarningModal()}
          {this.renderWarningModal()}
          <div className="container">
            {this.state.expanded ? title : false}
            {this.state.expanded &&
            !applicationStore.data.enableMercedesFeatures ? (
              <h4
                className={newQuoteBtnClassName}
                tabIndex={getTabIndex()}
                onClick={this.onStartNewQuote}
              >
                {this.t("startNewQuote")}
              </h4>
            ) : (
              false
            )}
            <div
              className={
                hasNtdOrderableItem && this.state.items.size > 7
                  ? "cart-items double-width"
                  : "cart-items"
              }
            >
              {sizeOf(ntdPartsList) > 0 ? (
                <ul className={cartItemClassNameNtd}>
                  {itemAddMessage}
                  <hr className="cart-item-hr" />
                  {ntdPartsList}
                </ul>
              ) : (
                false
              )}
              {hasNtdOrderableItem ? this.renderNTDOrderTires() : false}
              {hasNtdOrderableItem && supportQuoteAccess
                ? this.renderNTDOrderDivider()
                : false}
              {hasNtdOrderableItem && sizeOf(nonNtdPartsList) > 0 ? (
                <div className="non-ntd-orderable-disclaimer">
                  <span className="disclaimer-text">
                    ({sizeOf(nonNtdPartsList)}){" "}
                    {this.t("cart.confirmAvailability")}
                  </span>
                </div>
              ) : (
                false
              )}
              {sizeOf(nonNtdPartsList) > 0 ? (
                <ul className={cartItemClassNameNonNtd}>
                  {itemAddMessage}
                  <hr className="cart-item-hr" />
                  {nonNtdPartsList}
                </ul>
              ) : (
                false
              )}
              {!hasNtdOrderableItem || supportQuoteAccess
                ? this.renderGetQuote()
                : false}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNTDOrderTires = () => {
    if (this.props.supportDealerOrder) {
      return renderNTDButton(
        this.onOpenNTDOrderModal.bind(this, false),
        "order"
      );
    } else if (this.props.supportConsumerOrder) {
      return renderNTDButton(
        this.onOpenWishlistReview.bind(this, true),
        "checkout"
      );
    } else {
      return false;
    }
  };

  onOpenNTDOrderModal = () => {
    applicationActions.onOpenNtdModal();
  };

  onOpenWishlistReview = (enableNtd) => {
    console.log("we are the enableNtd", enableNtd);
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Shopping Cart - OpenShoppingCartReview"
    );
    this.props.dispatch(wishListReview(enableNtd));
    shoppingCartActions.onOpenWishlistReview(enableNtd);
  };

  trackShoppingCartReview = () => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Shopping Cart - OpenShoppingCartReview"
    );
  };

  onOpenWishlistReviewOnKeyDown = (evt) => {
    checkForEvent: if ("undefined" != typeof evt.keyCode) {
      if (evt.keyCode == 13 || evt.keyCode == 32) {
        evt.preventDefault();
        evt.stopPropagation();
        break checkForEvent;
      }
      return;
    }
    this.props.dispatch(wishListReview(false));
    shoppingCartActions.onOpenWishlistReview(false);
  };

  renderNTDOrderDivider = () => {
    if (this.state.loggedIn && applicationStore.data.dealerAllowNtdStockOrder) {
      return (
        <div className="ntd-cart-btn-divider">
          <hr />
          <span>{this.t("cart.or")}</span>
        </div>
      );
    } else {
      return false;
    }
  };

  renderGetQuote = () => {
    var text = "";
    if (
      applicationStore.data.useCartReviewPage &&
      !this.state.enableEcommerceRetailSite
    ) {
      text =
        this.state.loggedIn && applicationStore.data.dealerAllowNtdStockOrder
          ? this.t("cart.generateQuote")
          : this.t("cart.getCartReview");
      return (
        <button
          role="button"
          tabIndex={getTabIndex()}
          onKeyDown={(e) => this.onOpenWishlistReviewOnKeyDown(e)}
          onClick={this.onOpenWishlistReview.bind(this, false)}
          aria-label={this.t("cart.getCartReview")}
          className={setAdobeLaunchClass(
            "button btn-get-quote show-focus",
            ADOBELAUNCHER_CLASS.BUTTON.LINK
          )}
          data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.MYQUOTEMODAL.BASE)}
        >
          <h3>{text}</h3>
        </button>
      );
    } else if (this.state.enableEcommerceRetailSite) {
      text =
        this.state.loggedIn && applicationStore.data.dealerAllowNtdStockOrder
          ? this.t("cart.generateQuote")
          : this.t("cart.getCheckout");
      return (
        <button
          role="button"
          // href={this.state.shoppingCartViewLink}
          // onClick={this.trackShoppingCartReview}
          onClick={() => this.onOpenWishlistReview(true)}
          tabIndex={getTabIndex()}
          aria-label={this.t("cart.getCheckout")}
          className={setAdobeLaunchClass(
            "button btn-get-quote show-focus",
            ADOBELAUNCHER_CLASS.BUTTON.LINK
          )}
          data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.MYQUOTEMODAL.BASE)}
        >
          <h3>{text}</h3>
        </button>
      );
    } else {
      text =
        this.state.loggedIn && applicationStore.data.dealerAllowNtdStockOrder
          ? this.t("cart.generateQuote")
          : this.t("cart.getQuote");
      return (
        <a
          id="btn-get-quote show-focus"
          role="button"
          href={this.state.quoteLink}
          onClick={this.trackShoppingCartReview}
          tabIndex={getTabIndex()}
          aria-label={this.t("cart.getQuote")}
          target="_blank"
          className={setAdobeLaunchClass(
            "button btn-get-quote show-focus",
            ADOBELAUNCHER_CLASS.BUTTON.LINK
          )}
          data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.MYQUOTEMODAL.BASE)}
        >
          <h3>{text}</h3>
        </a>
      );
    }
  };

  onStartNewQuote = () => {
    applicationActions.openStartNewQuoteModal();
  };

  renderCartQuantityLimitWarningModal = () => {
    if (this.state.showCartQuantityLimitWarning) {
      return (
        <Modal
          refactor={true}
          useShortHeaderBar={true}
          requestUnmount={this.closeWarningModal}
        >
          <div className="wrapper">
            <div className="modal-content">
              <h2 className="title">
                {this.t("shoppingCartWarningsModal.notification")}
              </h2>
              <span>{this.t("cart.modal.quantityLimitWarning")}</span>
              <div className="button-container">
                <button
                  className="btn close-warning"
                  onClick={this.closeCartQuantityLimitWarningModal}
                >
                  {this.t("common.close")}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  closeCartQuantityLimitWarningModal = () => {
    shoppingCartActions.closeCartQuantityLimitWarnings();
  };

  renderWarningModal = () => {
    if (this.state.showWarning && !shoppingCartStore.isCartModalOpened()) {
      return (
        <Modal
          refactor={true}
          useShortHeaderBar={true}
          requestUnmount={this.closeWarningModal}
        >
          <div className="wrapper">
            <div className="modal-content">
              <h2 className="title">
                {this.t("shoppingCartWarningsModal.notification")}
              </h2>
              <span>{this.renderWarnings()}</span>
              <div className="button-container">
                <button
                  className="btn close-warning"
                  onClick={this.closeWarningModal}
                >
                  {this.t("common.close")}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  closeWarningModal = () => {
    shoppingCartActions.closeWarnings();
  };

  renderWarnings = () => {
    var items = this.state.items;
    var warningArray = [],
      itemNameById = [],
      itemNames = [],
      i = 0;
    this.state.items.forEach(function (item) {
      itemNameById[item.get("cartItemId")] = item
        .get("name")
        .get(getAppLanguage());
      var warnings = item.get("itemWarnings");
      if (warnings && warnings.size > 0) {
        warningArray[i] = new Array();
        itemNames[i] = item.get("name").get(getAppLanguage());
        warnings.forEach(function (warning) {
          if (warning.get("isNew")) {
            if (
              warning.get("code") ===
              CartWarnings.ITEM_WARNING_TIRE_WHEEL_IDEAL_SIZE_MISMATCH
            ) {
              var tiresize = "";
              if (item.get("type") === ProductTypes.ALLOY_WHEELS) {
                if (item && item.get("product"))
                  tiresize = item
                    .get("product")
                    .get("description")
                    .get(getAppLanguage());
                if (tiresize.indexOf("Recommended") > -1)
                  tiresize = tiresize.replace("Recommended", "recommended");
              } else if (item.get("type") === ProductTypes.TIRES) {
                items.map(function (tempItem) {
                  if (
                    tempItem.get("cartItemId") ===
                    warning.get("associateCartItemId")
                  ) {
                    if (tempItem && tempItem.get("product"))
                      tiresize = tempItem
                        .get("product")
                        .get("description")
                        .get(getAppLanguage());
                    if (tiresize.indexOf("Recommended") > -1)
                      tiresize = tiresize.replace("Recommended", "recommended");
                  }
                });
              }

              warningArray[i].push(
                getCodeText(warning.get("code")).replace("{}", tiresize)
              );
            } else {
              warningArray[i].push(getCodeText(warning.get("code")));
            }
          }
        });
        i++;
      }
    });

    return warningArray.map((warning, key) => (
      <div key={key}>
        <span className="modal-warning-item-name">{itemNames[key]}</span>
        <p>{warning.map((warning) => warning)}</p>
      </div>
    ));
  };

  onChange = () => {
    this.setState({
      items: shoppingCartStore.getItems(),
      itemsCount: shoppingCartStore.getItemsCount(),
      totalPrice: shoppingCartStore.getTotalPrice(),
      quoteLink: shoppingCartStore.getQuoteLink(),
      wishListReviewLink: shoppingCartStore.getWishlistReviewLink(),
      shoppingCartViewLink: shoppingCartStore.getShoppingCartViewLink(),
      expanded: shoppingCartStore.isCartOpened(),
      showWarning: shoppingCartStore.checkForWarnings(),
      showCartQuantityLimitWarning: shoppingCartStore.data.get(
        "showCartQuantityLimitWarning"
      ),
      loggedIn: isUserLoggedIn(),
      newItemAddedFlag: shoppingCartStore.data.get("newItemAddedFlag"),
      // supportDealerOrder: shoppingCartViewStore.data.get('supportDealerOrder'),
      // supportConsumerOrder: shoppingCartViewStore.data.get('supportConsumerOrder')
    });
  };

  onCloseCart = () => {
    this.props.dispatch(toggleCart(false));
    this.props.dispatch(cartItemSeen(true));
    // shoppingCartActions.cartNewItemSeen();
    // shoppingCartActions.closeCart();
  };
}

function mapStateToProps(state) {
  const cart = selectCartItems(state);
  return {
    // cart: state.shoppingCart.shoppingCart,
    cart,
    cartCount: cart.length,
    expanded: state.shoppingCart.toggleCart,
    supportConsumerOrder: state.shoppingCart.supportConsumerOrder,
    supportDealerOrder: state.shoppingCart.supportDealerOrder,
  };
}
export default connect(mapStateToProps)(ShoppingCart);

module.exports = {

  'MO':'BMW ApprovedTires',


  homepage_tire_title: 'BMW Approved Tires',
  homepage_tire_subtitle: 'NOT JUST A MARK. A STAMP OF APPROVAL.',
  homepage_tire_text: 'No other piece of equipment has a greater impact on your vehicle\'s performance and safety than tires. BMW Approved Tires, easily identified by the star marking on the exterior sidewall, are specially developed in close collaboration with leading tire manufacturers and precision crafted to match your BMW\'s exact specifications. The result: Improved performance, enhanced safety, and the highest degree of ride comfort. Why settle for anything else?',
  homepage_wheel_title: 'Genuine Wheels',
  homepage_wheel_subtitle: 'BMW HIGH-PERFORMANCE WHEEL PACKAGES.',
  homepage_wheel_text: 'Attention to detail: BMW High-Performance Wheels offer a sophisticated design, unmatched stability, and ideal running properties. The wheel and tire combinations are precisely tailored to suit the overall performance characteristics of your BMW, thereby helping to provide the best possible interplay between all chassis components.',

  changeDealer: 'Change Retailer',
  contactDealer: 'Contact Retailer',
  dealerSelection: 'Retailer Selection',
  'common': {
    viewPackages: 'Find Wheel Packages',
    contactDealer: 'Contact Retailer',
  },
  'breadcrumbs': {
    packagesProductList: 'View Wheel Packages',
    dealerSelection: 'Select Your Retailer',
  },
  'cart': {
    'modal': {
      quantityLimitWarning: 'We\'re sorry. You\'ve requested to purchase more than the allotted quantity of the same item at one time. For larger purchases please contact your retailer.',
    }
  },
  'shoppingCartWarnings': {
    ITEM_WARNING_TIRE_WHEEL_MISMATCH: 'Please be advised the quote you are creating includes incompatible tires and wheels. If necessary, contact the retailer for more information. ',
    ITEM_WARNING_TIRE_WHEEL_IDEAL_SIZE_MISMATCH: 'Please be advised the quote you are creating includes tires that do not meet the wheels {}. Please change your selection or contact the retailer for additional information.',
  },
  'search': {
    selectDealer: 'Contact Retailer for Pricing',
    selectDealerShort: 'Contact Retailer for Pricing',
    retailer: 'Retailer',
    dealer: 'Retailer',
  },
  'guestAccountCreation': {
    dealerUrlCode: 'Retailer Code',
  },
  'wishlist': {
    nonOrderableDisclaimer_2: '(Retailer will confirm availability upon arranging installation). ',
    orderDealerContact: 'You will be contacted within 1 - 2 business days by phone to book an appointment or you can contact the retailer at [], or by email at {}.',
    orderContactDisclaimer: 'You may be asked for your email during the appointment, if you have any questions you may contact the retailer at',
    appointmentDisclaimer: 'Thank you. An email has been sent to the retailer. You will be contacted within 24 hours.',
    titleRequestContact: 'Would you like the retailer to contact you to review your tire needs?',
    titleReserveItemsDealer: 'Reserve items from retailer',
    subtitleReserveItemsDealer: 'Fill in the the form below to reserve items from the retailer. Retailer will confirm availability.',
    appointmentSuccess: 'Thank you. A message has been sent to the retailer. You will be contacted within 24 hours to confirm appointment time.',
    errorNoDealerUrlCode: 'Retailer Url Code is required.',
  },
  'partInfoModal': {
    dealerPrice: 'Retailer Price',
  },
  'launcher': {
    packages: "Wheel Packages",
    searchForPackages: "Search for Wheel Packages",
    selectYourDealer: "Select Your Retailer",
    dealerError: "A retailer must be selected.",
  },
  'comparison': {
    comparisonFailed: "We're sorry, comparison is unavailable because one or more of the items selected does not have pricing. Please contact the retailer for further details.",
  },
  'campaign': {
    campaignInactiveMessageDealer: 'Please select retailer to see their current offers.',
    campaignInactiveMessageNational: 'Please select a retailer to see their current offers.',
    campaignInactiveButtonDealer: 'Retailer Homepage',
    campaignInactiveButtonNational: 'Select a Retailer',
  },
  'dealerFilter': {
    titleSuffix: 'Retailer',
  },

  packageOptions: 'Wheel Kit Options',

  'productTypes': {
    packages: 'Wheel Packages',
    packagesAlt: 'Wheel Packages',
    searchPackages: 'Search Wheel Packages',
    packagesTab: 'Wheel Packages',
    packageDisclaimer: 'Please note not all dealerships have pre-configured wheel and tire wheel packages',
  },
  'stocks': {
    packages: 'Wheel Packages',
    packagePricing: 'Wheel Kit Pricing',
  },

  'product': {

    contactDealerForPricing: 'Contact retailer for Pricing',
    dealerTotal: 'Retailer Total',
    noTiresResultsAvailable: 'Sorry, there are no tires available for this vehicle. Please contact retailer for more information.',
    noTiresResultsAvailableForChassis: 'Sorry, there are no tires available for this chassis. Please contact retailer for more information.',
    noTiresResultsAvailableForPartNumber: 'Sorry, there are no tires available for this part number. Please contact retailer for more information.',
    noTiresResultsAvailableForTireSize: 'Sorry, there are no tires available for this tire size. Please contact retailer for more information.',
    noWheelResultsAvailable: 'Sorry, there are no wheels available for this vehicle. Please contact retailer for more information.',
    noAccessoryResultsAvailable: 'Sorry, there are no accessories available for this vehicle. Please contact retailer for more information.',
    noPackageResultsAvailable: 'Sorry, there are no wheel packages available for this vehicle. Please contact retailer for more information.',
    requestAppointment: 'Contact retailer',
    requestAppointment_alt: 'Contact retailer',
    warrantyMissing: 'Please contact retailer for warranty information.',

    createPackage: 'Create Wheel Kit',
    package: 'Wheel Kit',
    packageItems: 'Wheel Kit Items',
    packagePartNumber: 'Wheel Kit Part Number',
    perPackage: 'per Wheel Kit',
    perPackageLwr: 'per Wheel Kit',
    packagePricing: 'Wheel Kit Pricing',
    emptyPackagesList: 'Please contact your dealership for wheel packages specific to your vehicle.',

    tireWarranty: 'Tire Manufacturer Warranty',

    addToQuote: 'Add to Quote',
    addToWishlist: 'Add to Quote',

    'showOnlyApproved': 'Show Only Approved Tires',
    'approvedTires': 'Approved Tires',
    'nonApprovedTires': 'Non-Approved Tires',
    'good': '',
    'better': '',
    'best': '',
    'dealerPrice': 'Price',
    'retailPrice': 'Price',
    'tire': 'BMW ApprovedTires',
    'approvedTire': 'BMW Star Approved Tires',
  },
};
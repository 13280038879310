import { globalState } from "../../GlobalState.js";
import { Modal } from "../ux/Modal.jsx";
import { RouterStates } from "../../constants/RouterStates.js";
import { ProductTypes } from "../../constants/ProductTypes.js";
import { applicationActions } from "../../actions/ApplicationActions.js";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { vehicleSelectionStore } from "../../stores/VehicleSelectionStore.js";
import { vehicleSelectionActions } from "../../actions/VehicleSelectionActions.js";
import { searchActions } from "../../actions/SearchActions.js";
import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";
import { productListActions } from "../../actions/ProductListActions.js";
import { productListStore } from "../../stores/ProductListStore.js";
import { searchStore } from "../../stores/SearchStore.js";

import { SearchTypes } from "../../constants/SearchTypes.js";

import { Image } from "../ux/Image.jsx";

import { ComboBox } from "../ux/forms/ComboBox.jsx";
import { ComboBoxValue } from "../ux/forms/ComboBoxValue.jsx";

import { isUserLoggedIn } from "../../api/AuthService";

import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";
import {
  getAppDealerId,
  getAppLanguage,
  getTrackingEventCategory,
  getTrackingEventSearch,
  isDealerSite,
  isEmpty,
  openSiteLink,
  runTiresizeSearch,
} from "../../service/UtilityService";

import { connect } from "react-redux";
import { tireId, toggleSearch } from "../../redux/features/applicationInfo";
import {
  setChassicSearchValue,
  setProductListSearchType,
} from "../../redux/features/productList.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var ReactDOM = require("react-dom");
var Immutable = require("immutable");
var Cx = require("classnames");
var _ = require("lodash");

class SearchModal extends I18nComponent {
  state = {
    productType: applicationStore.data.searchProductType ?? applicationStore.data.defaultProductType,

    searchType: productListStore.data.searchType,
    defaultSearchType: searchStore.data.defaultSearchType,

    yearList: vehicleSelectionStore.data.vehicleYears,
    categoryList: vehicleSelectionStore.data.categories,
    modelList: vehicleSelectionStore.data.models,

    year: this.props.searchYear,
    selectedMakeId: null,
    category: vehicleSelectionStore.data.category,
    model: vehicleSelectionStore.data.model,

    vehicleYears: vehicleSelectionStore.data.vehicleYears,
    vehicleMakes: vehicleSelectionStore.data.primaryMakes,
    vehicleSecondaryMakes: vehicleSelectionStore.data.secondaryMakes,
    vehicleModels: vehicleSelectionStore.data.models,

    selectedYear: null,
    selectedMake: null,
    selectedModel: null,

    runRecaptcha: false,
  };

  updateState = (state) => {
    this.setState(state);
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("Search Modal");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Search Modal Access"
    );
    applicationStore.addChangeListener(this.onChange);
    productListStore.addChangeListener(this.onChange);
    vehicleSelectionStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
    productListStore.removeChangeListener(this.onChange);
    vehicleSelectionStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    this.setState({ searchType: this.state.defaultSearchType });
    if (
      applicationStore.data.allowSearchByVehicleModel &&
      vehicleSelectionStore.data.fullVehicleSelectionTree == null
    ) {
      vehicleSelectionActions.loadVehicleSelectionModal();
      //setTimeout(() => { vehicleSelectionActions.loadVehicleSelectionModal(); }, 100);
    } else if (
      applicationStore.data.allowSearchByVehicleSimple ||
      applicationStore.data.allowAuthenticatedSearchByVehicleSimple
    ) {
      const { productType } = this.state;
      vehicleSelectionActions.loadYearsForSearchModal(productType);
      //setTimeout(() => { vehicleSelectionActions.loadYearsForSearchModal(productType); }, 100);
    }
    var keypress = this.keypress;
    window.addEventListener("keypress", keypress);
  }

  keypress = (evt) => {
    if (evt.keyCode === 13) {
      this.onSearchSubmit();
    }
  };

  componentDidUpdate(lastProps, nextState) {
    if (this.refs.search) ReactDOM.findDOMNode(this.refs.search).focus();
    if (
      applicationStore.data.allowSearchByVehicleSimple &&
      this.state.productType !== nextState.productType
    ) {
      vehicleSelectionActions.loadYearsForSearchModal(this.state.productType);
    }
    //inivisCaptchaOnComponentDidUpdate(this.state, this.updateState, [this.onSearchSubmit, this.onSearchSubmit, this.submitVehicleSimpleSearch]);
  }
  onHandlerChange = (e) => {
    let handler = e.target.value;
    console.log(handler, "this is our handler");
    this.props.dispatch(tireId(handler));
  };

  render() {
    var buttonClassTiresize =
      this.state.searchType == SearchTypes.SEARCH_BY_TIRESIZE ? "selected" : "";
    var buttonClassPartNumber =
      this.state.searchType == SearchTypes.SEARCH_BY_PARTNUMBER
        ? "selected"
        : "";
    var buttonClassVehicleModel =
      this.state.searchType == SearchTypes.SEARCH_BY_MODEL ? "selected" : "";
    var buttonClassChassis =
      this.state.searchType == SearchTypes.SEARCH_BY_CHASSIS ? "selected" : "";
    var buttonClassVehicleSimple =
      this.state.searchType == SearchTypes.SEARCH_BY_VEHICLE_SIMPLE
        ? "selected"
        : "";

    var productTypeList = new Array();
    var productTypeValueList = new Array();
    if (applicationStore.data.productTypes.indexOf(ProductTypes.TIRES) > -1) {
      productTypeList[productTypeList.length] = this.t("productTypes.tires");
      productTypeValueList[productTypeValueList.length] = ProductTypes.TIRES;
    }
    if (
      applicationStore.data.productTypes.indexOf(ProductTypes.ALLOY_WHEELS) > -1
    ) {
      productTypeList[productTypeList.length] = this.t("productTypes.wheels");
      productTypeValueList[productTypeValueList.length] =
        ProductTypes.ALLOY_WHEELS;
    }
    if (
      applicationStore.data.productTypes.indexOf(ProductTypes.ACCESSORIES) > -1
    ) {
      productTypeList[productTypeList.length] = this.t(
        "productTypes.accessories"
      );
      productTypeValueList[productTypeValueList.length] =
        ProductTypes.ACCESSORIES;
    }
    if (
      applicationStore.data.productTypes.indexOf(ProductTypes.PACKAGES) > -1
    ) {
      productTypeList[productTypeList.length] = this.t("productTypes.packages");
      productTypeValueList[productTypeValueList.length] = ProductTypes.PACKAGES;
    }
    /*
    if (applicationStore.data.productTypes.indexOf(ProductTypes.PACKAGES) > -1
      && (applicationStore.data.allowSearchByVehicleModel
      || (applicationStore.data.loggedIn && applicationStore.data.allowAuthenticatedSearchByVehicleModel))) { // Only model search is implemented for packages
      productTypeList[productTypeList.length] = this.t('productTypes.packages');
      productTypeValueList[productTypeValueList.length] = ProductTypes.PACKAGES;
    }
    */

    var selectedProductType = this.state.productType;
    var allowSearchByTireSize =
      applicationStore.data.allowSearchByTireSize &&
      this.state.productType == ProductTypes.TIRES;
    var allowSearchByPartNumber =
      applicationStore.data.allowSearchByPartNumber &&
      this.state.productType == ProductTypes.TIRES;
    var allowSearchByVehicleModel =
      applicationStore.data.allowSearchByVehicleModel &&
      (this.state.productType == ProductTypes.TIRES ||
        this.state.productType == ProductTypes.PACKAGES);
    var allowSearchByChassis =
      applicationStore.data.allowSearchByChassis &&
      this.state.productType == ProductTypes.TIRES;
    var allowSearchByVehicleSimple =
      applicationStore.data.allowSearchByVehicleSimple;
    if (isUserLoggedIn()) {
      allowSearchByTireSize =
        applicationStore.data.allowAuthenticatedSearchByTireSize &&
        this.state.productType == ProductTypes.TIRES;
      allowSearchByPartNumber =
        applicationStore.data.allowAuthenticatedSearchByPartNumber &&
        this.state.productType == ProductTypes.TIRES;
      allowSearchByVehicleModel =
        applicationStore.data.allowAuthenticatedSearchByVehicleModel &&
        (this.state.productType == ProductTypes.TIRES ||
          this.state.productType == ProductTypes.PACKAGES);
      allowSearchByChassis =
        applicationStore.data.allowAuthenticatedSearchByChassis &&
        this.state.productType == ProductTypes.TIRES;
      allowSearchByVehicleSimple =
        applicationStore.data.allowAuthenticatedSearchByVehicleSimple;
    }

    var searchBarPlaceHolder = "",
      searchBarTag = "";
    switch (this.state.searchType) {
      case SearchTypes.SEARCH_BY_MODEL:
        break;
      case SearchTypes.SEARCH_BY_TIRESIZE:
        searchBarPlaceHolder = this.t("search.placeholderSearchTireSize");
        searchBarTag = (
          <div className="search-bar-tag">
            {this.t("search.searchTireSizeTag") + ":"}
          </div>
        );
        break;
      case SearchTypes.SEARCH_BY_PARTNUMBER:
        searchBarPlaceHolder = this.t("search.placeholderSearchPartNumber");
        searchBarTag = (
          <div className="search-bar-tag">
            {this.t("search.searchPartNumberTag") + ":"}
          </div>
        );
        break;
      case SearchTypes.SEARCH_BY_CHASSIS:
        searchBarPlaceHolder = this.t("search.placeholderSearchChassis");
        searchBarTag = (
          <div className="search-bar-tag">
            {this.t("search.searchChassisTag") + ":"}
          </div>
        );
        break;
    }

    var yearList = [];
    var makeList = [];
    var categoryList = [];
    var modelList = [];
    var lang = getAppLanguage();

    if (allowSearchByVehicleModel) {
      yearList = this.state.yearList;
      categoryList = this.state.categoryList.map(
        ({ name: nameObj, ...rest }) => {
          const name = nameObj[lang] || nameObj["en"];
          return { name, ...rest };
        }
      );
      modelList = this.state.modelList;
    } else if (allowSearchByVehicleSimple) {
      yearList = this.state.vehicleYears;
      if (vehicleSelectionStore.shouldShowSecondaryMakes()) {
        makeList = this.state.vehicleMakes.concat(
          this.state.vehicleSecondaryMakes
        );
      } else {
        makeList = this.state.vehicleMakes;
      }
      modelList = this.state.vehicleModels;
    }

    var year = this.state.year;
    if (Array.isArray(year)) {
      year = year[0] ? year[0] : null;
    }
    var selectedMakeId = this.state.selectedMakeId;
    var category = this.state.category;
    var model = this.state.model;

    var selectedYear = this.state.selectedYear;
    var selectedMake = this.state.selectedMake;
    var selectedModel = this.state.selectedModel;

    var image = false;
    if (applicationStore.data.enableMercedesFeatures) {
      image = (
        <div className="vehicle-selection-modal-image">
          <Image
            src="/static/img/mercedes/vehicle_selection_modal_image.png"
            alt="Image Vehicle Selection"
          />
        </div>
      );
    }

    var onChangeProductType = this.onChangeProductType;

    var closeImageLink = "/static/img/close_circle.png";
    switch (applicationStore.data.defaultTemplate) {
      case "mercedes":
        closeImageLink = "/static/img/mercedes/close.png";
        break;
      default:
        break;
    }

    var searchHelper = false;
    switch (this.state.searchType) {
      case SearchTypes.SEARCH_BY_VEHICLE:
      case SearchTypes.SEARCH_BY_VEHICLE_SIMPLE:
      case SearchTypes.SEARCH_BY_MODEL:
        break;
      case SearchTypes.SEARCH_BY_TIRESIZE:
        searchHelper = (
          <i
            className={setAdobeLaunchClass(
              "fa fa-question-circle",
              ADOBELAUNCHER_CLASS.IMAGE
            )}
            data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.SEARCH.TIRESIZE)}
            onClick={this.showTireSizeHelp}
          ></i>
        );
        break;
      case SearchTypes.SEARCH_BY_PARTNUMBER:
        break;
      case SearchTypes.SEARCH_BY_CHASSIS:
        break;
    }

    return (
      <Modal requestUnmount={this.onCloseSearchModal} ref="search-modal">
        <div className="search-wrapper">
          <div className="modal-header-bar search-header">
            <Image
              className={setAdobeLaunchClass(
                "close-x",
                ADOBELAUNCHER_CLASS.BUTTON.CLOSE
              )}
              datadtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.SEARCH.BASE)}
              src={closeImageLink}
              onClick={this.onCloseSearchModalThroughModal}
              alt="Icon Close"
            />
          </div>
          <div className="search-modal">
            <div className="search-modal-container">
              <div className="product-type-selector">
                <div className="search-product-select">
                  {Immutable.fromJS(productTypeList)
                    .map(
                      function (productType, index) {
                        var selectedClass = Cx({
                          selected:
                            productTypeValueList[index] === selectedProductType,
                        });

                        switch (productTypeValueList[index]) {
                          case ProductTypes.TIRES:
                            return (
                              <li
                                key={
                                  "select-product-" +
                                  productTypeValueList[index]
                                }
                                className={setAdobeLaunchClass(
                                  selectedClass,
                                  ADOBELAUNCHER_CLASS.TAB
                                )}
                                data-dtm={setAdobeLaunchDTM(
                                  "",
                                  ADOBELAUNCHER_DTM.SEARCH.BASE
                                )}
                                onClick={onChangeProductType.bind(
                                  this,
                                  ProductTypes.TIRES
                                )}
                              >
                                {productType}
                              </li>
                            );
                          case ProductTypes.ALLOY_WHEELS:
                            return (
                              <li
                                key={
                                  "select-product-" +
                                  productTypeValueList[index]
                                }
                                className={setAdobeLaunchClass(
                                  selectedClass,
                                  ADOBELAUNCHER_CLASS.TAB
                                )}
                                data-dtm={setAdobeLaunchDTM(
                                  "",
                                  ADOBELAUNCHER_DTM.SEARCH.BASE
                                )}
                                onClick={onChangeProductType.bind(
                                  this,
                                  ProductTypes.ALLOY_WHEELS
                                )}
                              >
                                {productType}
                              </li>
                            );
                          case ProductTypes.ACCESSORIES:
                            return (
                              <li
                                key={
                                  "select-product-" +
                                  productTypeValueList[index]
                                }
                                className={setAdobeLaunchClass(
                                  selectedClass,
                                  ADOBELAUNCHER_CLASS.TAB
                                )}
                                data-dtm={setAdobeLaunchDTM(
                                  "",
                                  ADOBELAUNCHER_DTM.SEARCH.BASE
                                )}
                                onClick={onChangeProductType.bind(
                                  this,
                                  ProductTypes.ACCESSORIES
                                )}
                              >
                                {productType}
                              </li>
                            );
                          case ProductTypes.PACKAGES:
                            return (
                              <li
                                key={
                                  "select-product-" +
                                  productTypeValueList[index]
                                }
                                className={setAdobeLaunchClass(
                                  selectedClass,
                                  ADOBELAUNCHER_CLASS.TAB
                                )}
                                data-dtm={setAdobeLaunchDTM(
                                  "",
                                  ADOBELAUNCHER_DTM.SEARCH.BASE
                                )}
                                onClick={onChangeProductType.bind(
                                  this,
                                  ProductTypes.PACKAGES
                                )}
                              >
                                {productType}
                              </li>
                            );
                        }
                      }.bind(this)
                    )
                    .toArray()}
                </div>
              </div>
              <div className="search-select">
                {allowSearchByVehicleSimple ? (
                  <button
                    className={setAdobeLaunchClass(
                      buttonClassVehicleSimple,
                      ADOBELAUNCHER_CLASS.BUTTON.LINK
                    )}
                    data-dtm={setAdobeLaunchDTM(
                      "",
                      ADOBELAUNCHER_DTM.SEARCH.TIRESWHEELS,
                      selectedProductType
                    )}
                    onClick={this.toggleVehicleSimpleSearch}
                  >
                    <span>{this.t("search.vehicleSimpleSearch")}</span>
                  </button>
                ) : (
                  false
                )}
                {allowSearchByVehicleModel ? (
                  <button
                    className={buttonClassVehicleModel}
                    onClick={this.toggleVehicleModelSearch}
                  >
                    <span>{this.t("search.vehicleModelSearch")}</span>
                  </button>
                ) : (
                  false
                )}
                {allowSearchByTireSize ? (
                  <button
                    className={setAdobeLaunchClass(
                      buttonClassTiresize,
                      ADOBELAUNCHER_CLASS.BUTTON.LINK
                    )}
                    data-dtm={setAdobeLaunchDTM(
                      "",
                      ADOBELAUNCHER_DTM.SEARCH.TIRESWHEELS,
                      selectedProductType
                    )}
                    onClick={this.toggleTiresizeSearch}
                  >
                    <span>{this.t("search.tiresizeSearch")}</span>
                  </button>
                ) : (
                  false
                )}
                {allowSearchByPartNumber ? (
                  <button
                    className={buttonClassPartNumber}
                    onClick={this.togglePartNumberSearch}
                  >
                    <span>{this.t("search.partNumberSearch")}</span>
                  </button>
                ) : (
                  false
                )}
                {allowSearchByChassis ? (
                  <button
                    className={buttonClassChassis}
                    onClick={this.toggleChassisSearch}
                  >
                    <span>{this.t("search.chassisSearch")}</span>
                  </button>
                ) : (
                  false
                )}
              </div>
              {this.state.searchType == SearchTypes.SEARCH_BY_PARTNUMBER ||
              this.state.searchType == SearchTypes.SEARCH_BY_TIRESIZE ||
              this.state.searchType == SearchTypes.SEARCH_BY_CHASSIS ? (
                <div className="input-search-section">
                  <div className="search-input">
                    {image}
                    {searchBarTag}
                    <form onSubmit={this.doNothing}>
                      <input
                        id={"searchFormInput " + this.state.searchType}
                        className={setAdobeLaunchClass(
                          "recaptcha-field",
                          ADOBELAUNCHER_CLASS.INPUT
                        )}
                        onChange={(e) => this.onHandlerChange(e)}
                        data-dtm={setAdobeLaunchDTM(
                          "",
                          ADOBELAUNCHER_DTM.SEARCH.TIRESIZE
                        )}
                        type="text"
                        ref="search"
                        placeholder={searchBarPlaceHolder}
                      />
                      {searchHelper}
                      <button
                        type="button"
                        className={setAdobeLaunchClass(
                          "btn",
                          ADOBELAUNCHER_CLASS.BUTTON.LINK
                        )}
                        data-dtm={setAdobeLaunchDTM(
                          "",
                          ADOBELAUNCHER_DTM.SEARCH.TIRESIZE
                        )}
                        onClick={this.onSearchSubmit}
                      >
                        {this.t("searchText")}
                      </button>
                    </form>
                  </div>
                </div>
              ) : (
                false
              )}
              {allowSearchByVehicleModel &&
              this.state.searchType == SearchTypes.SEARCH_BY_MODEL ? (
                <div className="vehicle-selection-modal">
                  {image}
                  <div className="vehicle-selection-options">
                    <div className="vehicle-selection-options-year">
                      {yearList.length > 0 ? (
                        <ComboBox
                          className={setAdobeLaunchClass(
                            "",
                            ADOBELAUNCHER_CLASS.DROPDOWN
                          )}
                          datadtm={setAdobeLaunchDTM(
                            "",
                            ADOBELAUNCHER_DTM.SEARCH.TIRESWHEELSVEHICLE,
                            this.state.productType
                          )}
                          value={year}
                          onChange={this.onYearChange}
                          scrollBar={false}
                          list={yearList}
                        >
                          {yearList.map(function (year) {
                            return (
                              <ComboBoxValue
                                key={"year-" + year["id"]}
                                value={year["id"]}
                              >
                                {year["value"]}
                              </ComboBoxValue>
                            );
                          })}
                        </ComboBox>
                      ) : (
                        false
                      )}
                    </div>
                    <div className="vehicle-selection-options-category">
                      {categoryList.length > 0 ? (
                        <ComboBox
                          debug
                          className={setAdobeLaunchClass(
                            "",
                            ADOBELAUNCHER_CLASS.DROPDOWN
                          )}
                          datadtm={setAdobeLaunchDTM(
                            "",
                            ADOBELAUNCHER_DTM.SEARCH.TIRESWHEELSVEHICLE,
                            this.state.productType
                          )}
                          value={category}
                          onChange={this.onCategoryChange}
                          scrollBar={true}
                          list={categoryList}
                        >
                          {categoryList.map(function (category) {
                            return (
                              <ComboBoxValue
                                key={"category-" + category["id"]}
                                value={category["id"]}
                              >
                                {category["name"][lang]}
                              </ComboBoxValue>
                            );
                          })}
                        </ComboBox>
                      ) : (
                        false
                      )}
                    </div>
                    <div className="vehicle-selection-options-model">
                      {modelList.length > 0 ? (
                        <ComboBox
                          className={setAdobeLaunchClass(
                            "",
                            ADOBELAUNCHER_CLASS.DROPDOWN
                          )}
                          datadtm={setAdobeLaunchDTM(
                            "",
                            ADOBELAUNCHER_DTM.SEARCH.TIRESWHEELSVEHICLE,
                            this.state.productType
                          )}
                          value={model}
                          onChange={this.onModelChange}
                          scrollBar={false}
                          list={modelList}
                        >
                          {modelList.map(function (model) {
                            return (
                              <ComboBoxValue
                                key={"model-" + model["id"]}
                                value={model["id"]}
                              >
                                {model["name"][lang]}
                              </ComboBoxValue>
                            );
                          })}
                        </ComboBox>
                      ) : (
                        false
                      )}
                    </div>
                  </div>
                  <div className="vehicle-selection-button-search">
                    <button
                      type="button"
                      className={setAdobeLaunchClass(
                        "btn",
                        ADOBELAUNCHER_CLASS.BUTTON.LINK
                      )}
                      data-dtm={setAdobeLaunchDTM(
                        "",
                        ADOBELAUNCHER_DTM.SEARCH.TIRESWHEELSVEHICLE,
                        this.state.productType
                      )}
                      onClick={this.onSearchSubmit}
                    >
                      {this.t("searchText")}
                    </button>
                  </div>
                </div>
              ) : (
                false
              )}
              {allowSearchByVehicleSimple &&
              this.state.searchType == SearchTypes.SEARCH_BY_VEHICLE_SIMPLE ? (
                <div className="vehicle-selection-modal simple-search">
                  <div className="vehicle-selection-options">
                    <div className="vehicle-selection-options-year">
                      {yearList.length > 0 ? (
                        <ComboBox
                          className={setAdobeLaunchClass(
                            "",
                            ADOBELAUNCHER_CLASS.DROPDOWN
                          )}
                          datadtm={setAdobeLaunchDTM(
                            "",
                            ADOBELAUNCHER_DTM.SEARCH.TIRESWHEELSVEHICLE,
                            this.state.productType
                          )}
                          value={selectedYear}
                          onChange={this.onYearChangeSimple}
                          list={yearList}
                          scrollBar={false}
                          emptyText={this.t("launcher.selectYear")}
                          ref="search-modal-select-year"
                        >
                          {yearList.map(function (year) {
                            return (
                              <ComboBoxValue
                                key={"year-alt-" + year["id"]}
                                value={year["id"]}
                              >
                                {year["value"]}
                              </ComboBoxValue>
                            );
                          })}
                        </ComboBox>
                      ) : (
                        false
                      )}
                    </div>
                    <div className="vehicle-selection-options-make">
                      {makeList ? (
                        <ComboBox
                          className={setAdobeLaunchClass(
                            "",
                            ADOBELAUNCHER_CLASS.DROPDOWN
                          )}
                          datadtm={setAdobeLaunchDTM(
                            "",
                            ADOBELAUNCHER_DTM.SEARCH.TIRESWHEELSVEHICLE,
                            this.state.productType
                          )}
                          value={selectedMake}
                          onChange={this.onMakeChangeSimple}
                          list={makeList}
                          scrollBar={true}
                          emptyText={this.t("launcher.selectMake")}
                          ref="search-modal-select-make"
                        >
                          {makeList.map(function (make, index) {
                            return (
                              <ComboBoxValue
                                key={"make-alt-" + make["id"] + "-" + index}
                                value={make["id"]}
                              >
                                {make["name"]}
                              </ComboBoxValue>
                            );
                          })}
                        </ComboBox>
                      ) : (
                        false
                      )}
                    </div>
                    <div className="vehicle-selection-options-model">
                      {modelList ? (
                        <ComboBox
                          className={setAdobeLaunchClass(
                            "",
                            ADOBELAUNCHER_CLASS.DROPDOWN
                          )}
                          datadtm={setAdobeLaunchDTM(
                            "",
                            ADOBELAUNCHER_DTM.SEARCH.TIRESWHEELSVEHICLE,
                            this.state.productType
                          )}
                          value={selectedModel}
                          onChange={this.onModelChangeSimple}
                          list={modelList}
                          scrollBar={false}
                          emptyText={this.t("launcher.selectModel")}
                          ref="search-modal-select-model"
                        >
                          {modelList.map(function (model) {
                            return (
                              <ComboBoxValue
                                key={"model-alt-" + model["vehicleModelId"]}
                                value={
                                  model["vehicleModelClassId"] +
                                  "-" +
                                  model["vehicleModelId"]
                                }
                              >
                                {model["vehicleModelClassId"] > 0
                                  ? model["vehicleModelClassName"][lang] +
                                    " - " +
                                    model["vehicleModelName"][lang]
                                  : model["vehicleModelName"][lang]}
                              </ComboBoxValue>
                            );
                          })}
                        </ComboBox>
                      ) : (
                        false
                      )}
                    </div>
                  </div>
                  <div className="vehicle-selection-button-search">
                    <button
                      className={setAdobeLaunchClass(
                        "btn",
                        ADOBELAUNCHER_CLASS.BUTTON.LINK
                      )}
                      data-dtm={setAdobeLaunchDTM(
                        "",
                        ADOBELAUNCHER_DTM.SEARCH.TIRESWHEELSVEHICLE,
                        this.state.productType
                      )}
                      onClick={this.submitVehicleSimpleSearch}
                    >
                      {this.t("searchText")}
                    </button>
                  </div>
                </div>
              ) : (
                false
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  doNothing = (evt) => {
    if (typeof evt !== "undefined") {
      evt.preventDefault();
      evt.stopPropagation();
    }
  };

  showTireSizeHelp = () => {
    applicationActions.onOpenTireSizeHelper();
  };

  toggleTiresizeSearch = () => {
    this.setState({
      searchType: SearchTypes.SEARCH_BY_TIRESIZE,
    });
  };

  togglePartNumberSearch = () => {
    this.setState({
      searchType: SearchTypes.SEARCH_BY_PARTNUMBER,
    });
  };

  toggleVehicleSimpleSearch = () => {
    this.setState({
      searchType: SearchTypes.SEARCH_BY_VEHICLE_SIMPLE,
    });
  };

  toggleVehicleModelSearch = () => {
    this.setState({
      searchType: SearchTypes.SEARCH_BY_MODEL,
    });
  };

  toggleChassisSearch = () => {
    this.setState({
      searchType: SearchTypes.SEARCH_BY_CHASSIS,
    });
  };

  onChangeProductType = (productType) => {
    var searchType = applicationStore.data.allowSearchByVehicleModel
      ? SearchTypes.SEARCH_BY_MODEL
      : null;
    if (applicationStore.data.allowSearchByVehicleSimple) {
      searchType = SearchTypes.SEARCH_BY_VEHICLE_SIMPLE;
    } else if (productType != ProductTypes.TIRES) {
      if (productType != ProductTypes.PACKAGES) {
        if (
          this.state.searchType == SearchTypes.SEARCH_BY_MODEL ||
          this.state.searchType == SearchTypes.SEARCH_BY_TIRESIZE
        ) {
          if (applicationStore.data.allowSearchByPartNumber) {
            searchType = SearchTypes.SEARCH_BY_PARTNUMBER;
          } else if (applicationStore.data.allowSearchByChassis) {
            searchType = SearchTypes.SEARCH_BY_CHASSIS;
          }
        }
      } else {
        if (this.state.searchType == SearchTypes.SEARCH_BY_TIRESIZE) {
          if (applicationStore.data.allowSearchByVehicleModel) {
            searchType = SearchTypes.SEARCH_BY_MODEL;
          } else if (applicationStore.data.allowSearchByPartNumber && false) {
            //part number search not yet implemented for packages
            searchType = SearchTypes.SEARCH_BY_PARTNUMBER;
          } else if (applicationStore.data.allowSearchByChassis && false) {
            //chassis search not yet implemented for packages
            searchType = SearchTypes.SEARCH_BY_CHASSIS;
          }
        } else if (this.state.searchType == SearchTypes.SEARCH_BY_PARTNUMBER) {
          if (applicationStore.data.allowSearchByVehicleModel) {
            searchType = SearchTypes.SEARCH_BY_MODEL;
          } else if (applicationStore.data.allowSearchByChassis && false) {
            //chassis search not yet implemented for packages
            searchType = SearchTypes.SEARCH_BY_CHASSIS;
          }
        } else if (this.state.searchType == SearchTypes.SEARCH_BY_CHASSIS) {
          if (applicationStore.data.allowSearchByVehicleModel) {
            searchType = SearchTypes.SEARCH_BY_MODEL;
          } else if (applicationStore.data.allowSearchByPartNumber && false) {
            //part number search not yet implemented for packages
            searchType = SearchTypes.SEARCH_BY_PARTNUMBER;
          }
        }
      }
    }

    if (searchType !== null) {
      this.setState({
        selectedYear: null,
        selectedMake: null,
        selectedModel: null,
        searchType: searchType,
        productType: productType,
      });
    }
  };

  onCloseSearchModal = () => {
    this.props.dispatch(toggleSearch(!this.props.toggleSearch));
    // applicationActions.deactivateSearchFlag();
  };

  onCloseSearchModalThroughModal = () => {
    var modal = this.refs["search-modal"];
    if (modal) modal.onClickClose();
  };

  onYearChange = (yearId) => {
    vehicleSelectionActions.yearSelectedFromModal(yearId);
  };

  onCategoryChange = (categoryId) => {
    vehicleSelectionActions.categorySelectedFromModal(categoryId);
  };

  onModelChange = (modelId) => {
    vehicleSelectionActions.modelSelectedFromModal(modelId);
  };

  onYearChangeSimple = (yearId) => {
    this.setState({
      selectedYear: yearId,
      selectedMake: null,
      selectedModel: null,
    });
    vehicleSelectionActions.loadMakesForSearchModal(
      this.state.productType,
      yearId
    );

    var makeSelection = this.refs["search-modal-select-make"];
    setTimeout(() => {
      if (makeSelection) makeSelection.onExpandComponent();
    }, 0);

    /*
            setTimeout(() => {
                vehicleSelectionActions.loadMakesForSearchModal(this.state.productType, yearId);
                setTimeout(() => {
                    var makeSelection = this.refs["search-modal-select-make"];
                    if (makeSelection) makeSelection.onToggleComponent();
                }, 10);
            }, 100);
            */
  };

  onMakeChangeSimple = (makeId) => {
    this.setState({ selectedMake: makeId, selectedModel: null });
    vehicleSelectionActions.loadModelsForSearchModal(
      this.state.productType,
      this.state.selectedYear,
      makeId
    );

    var modelSelection = this.refs["search-modal-select-model"];
    setTimeout(() => {
      if (modelSelection) modelSelection.onExpandComponent();
    }, 0);

    /*
            setTimeout(() => {
                vehicleSelectionActions.loadModelsForSearchModal(this.state.productType, this.state.selectedYear, makeId);
                setTimeout(() => {
                    var modelSelection = this.refs["search-modal-select-model"];
                    if (modelSelection) modelSelection.onToggleComponent();
                }, 10);
            }, 100);
            */
  };

  onModelChangeSimple = (modelId) => {
    this.setState({ selectedModel: modelId });
  };

  onSearchSubmit = (token, evt) => {
    if (typeof evt !== "undefined") {
      evt.preventDefault();
      evt.stopPropagation();
    }
    // searchActions.loadSearchResults();
    // productListActions.loadSearchResults();
    // todo: this was maybe added by mistake
    // this.props.dispatch(fetchTireSearch());
    var routerState = globalState
      .select("applicationState", "routerState")
      .get();
    if (routerState === RouterStates.WISHLIST)
      shoppingCartActions.duplicateCart();

    searchActions.setSearchType(this.state.searchType);

    if (
      this.state.searchType == SearchTypes.SEARCH_BY_TIRESIZE ||
      this.state.searchType == SearchTypes.SEARCH_BY_PARTNUMBER
    ) {
      const value = this.refs.search.value;
      googleAnalyticsEnhancedTrackEvent(
        getTrackingEventCategory(),
        "Search Modal - SubmitSearch",
        getTrackingEventSearch(this.state.searchType, value)
      );

      if (value) {
        if (isDealerSite() || !isEmpty(getAppDealerId())) {
          console.log("we hit the search Button");
          if (
            routerState !== RouterStates.SEARCH_RESULT ||
            value !== applicationStore.data.search
          ) {
            console.log(
              this.getSearchTireSizeLink(value),
              "this is our link here"
            );
            runTiresizeSearch(
              true,
              this.state.searchType,
              this.getSearchTireSizeLink(value),
              value,
              true
            );
          }
          this.props.dispatch(toggleSearch(false));
        } else {
          var link = this.getSearchTireSizeLink(value);
          openSiteLink(link.link);
        }
      }
    } else if (this.state.searchType == SearchTypes.SEARCH_BY_MODEL) {
      var routerState = globalState
        .select("applicationState", "routerState")
        .get();
      googleAnalyticsEnhancedTrackEvent(
        getTrackingEventCategory(),
        "Search Modal - SubmitSearch",
        getTrackingEventSearch(this.state.searchType, null)
      );

      switch (this.state.productType) {
        case ProductTypes.TIRES:
          if (routerState !== RouterStates.PRODUCT_LIST) {
            this.props.dispatch(
              setProductListSearchType(SearchTypes.SEARCH_BY_MODEL)
            );
            productListActions.setProductListSearchType(
              SearchTypes.SEARCH_BY_MODEL
            );
            applicationActions.setProductTypeValue(ProductTypes.TIRES);
            productListActions.setLoadTiresFromModel();
            applicationActions.deactivateSearchFlag();
            this.props.dispatch(toggleSearch(false));

            /*
                                    setTimeout(() => {
                                        productListActions.setProductListSearchType(SearchTypes.SEARCH_BY_MODEL);
                                        setTimeout(() => {
                                            applicationActions.setProductTypeValue(ProductTypes.TIRES);
                                            setTimeout(() => {
                                                productListActions.setLoadTiresFromModel();
                              applicationActions.deactivateSearchFlag();
                                            }, 10);
                                        }, 10);
                                    }, 10);
                                    */
          } else {
            if (
              this.state.productType !== ProductTypes.TIRES ||
              this.state.searchType !== SearchTypes.SEARCH_BY_MODEL
            ) {
              this.props.dispatch(
                setProductListSearchType(SearchTypes.SEARCH_BY_MODEL)
              );
              productListActions.setProductListSearchType(
                SearchTypes.SEARCH_BY_MODEL
              );

              applicationActions.setProductTypeValue(ProductTypes.TIRES);
              applicationActions.deactivateSearchFlag();
              this.props.dispatch(toggleSearch(false));
            } else {
              vehicleSelectionActions.loadVehicleInfoByModel().then(() => {
                productListActions.loadTiresFromModel();
                applicationActions.deactivateSearchFlag();
                this.props.dispatch(toggleSearch(false));
              });
              /*
                                          setTimeout(() => {
                                              vehicleSelectionActions.loadVehicleInfoByModel().then(function() {
                                                  productListActions.loadTiresFromModel();
                                                  applicationActions.deactivateSearchFlag();
                                              });
                                          }, 10);
                                          */
            }
          }
          break;
        case ProductTypes.PACKAGES:
          if (routerState !== RouterStates.PRODUCT_LIST) {
            this.props.dispatch(
              setProductListSearchType(SearchTypes.SEARCH_BY_MODEL)
            );
            productListActions.setProductListSearchType(
              SearchTypes.SEARCH_BY_MODEL
            );
            applicationActions.setProductTypeValue(ProductTypes.PACKAGES);
            productListActions.setLoadPackagesFromModel();
            applicationActions.deactivateSearchFlag();
            this.props.dispatch(toggleSearch(false));

            /*
                                    setTimeout(() => {
                                        productListActions.setProductListSearchType(SearchTypes.SEARCH_BY_MODEL);
                                        setTimeout(() => {
                                            applicationActions.setProductTypeValue(ProductTypes.PACKAGES);
                                            setTimeout(() => {
                                                productListActions.setLoadPackagesFromModel();
                                                applicationActions.deactivateSearchFlag();
                                            }, 10);
                                        }, 10);
                                    }, 10);
                                    */
          } else {
            if (
              this.state.productType !== ProductTypes.PACKAGES ||
              this.state.searchType !== SearchTypes.SEARCH_BY_MODEL
            ) {
              this.props.dispatch(
                setProductListSearchType(SearchTypes.SEARCH_BY_MODEL)
              );
              productListActions.setProductListSearchType(
                SearchTypes.SEARCH_BY_MODEL
              );
              applicationActions.setProductTypeValue(ProductTypes.PACKAGES);
              applicationActions.deactivateSearchFlag();
              this.props.dispatch(toggleSearch(false));
            } else {
              vehicleSelectionActions.loadVehicleInfoByModel().then(() => {
                productListActions.loadPackagesFromModel();
                applicationActions.deactivateSearchFlag();
                this.props.dispatch(toggleSearch(false));
              });
              /*
                                          setTimeout(() => {
                                              vehicleSelectionActions.loadVehicleInfoByModel().then(function () {
                                                  productListActions.loadPackagesFromModel();
                                                  applicationActions.deactivateSearchFlag();
                                              });
                                          }, 10);
                                          */
            }
          }
          break;
        default:
          break;
      }
    } else if (this.state.searchType == SearchTypes.SEARCH_BY_CHASSIS) {
      const value = this.refs.search.value;
      googleAnalyticsEnhancedTrackEvent(
        getTrackingEventCategory(),
        "Search Modal - SubmitSearch",
        getTrackingEventSearch(this.state.searchType, value)
      );
      var routerState = globalState
        .select("applicationState", "routerState")
        .get();
      if (value) {
        switch (this.state.productType) {
          case ProductTypes.TIRES:
            if (routerState !== RouterStates.PRODUCT_LIST) {
              this.props.dispatch(setChassicSearchValue(value));
              productListActions.setChassisSearchValue(value);
              this.props.dispatch(
                setProductListSearchType(SearchTypes.SEARCH_BY_CHASSIS)
              );
              productListActions.setProductListSearchType(
                SearchTypes.SEARCH_BY_CHASSIS
              );
              console.log("Tracker is going");
              productListActions.setLoadTireFromChassis();
              applicationActions.deactivateSearchFlag();
              this.props.dispatch(toggleSearch(false));

              /*
                                          setTimeout(() => {
                                              productListActions.setChassisSearchValue(value);
                                              setTimeout(() => {
                                                  productListActions.setProductListSearchType(SearchTypes.SEARCH_BY_CHASSIS);
                                                  setTimeout(() => {
                                                      productListActions.setLoadTireFromChassis();
                                                      applicationActions.deactivateSearchFlag();
                                                  }, 10);
                                              }, 10);
                                          }, 10);
                                          */
            } else {
              if (
                this.state.productType !== ProductTypes.TIRES ||
                this.state.searchType !== SearchTypes.SEARCH_BY_CHASSIS
              ) {
                this.props.dispatch(setChassicSearchValue(value));
                productListActions.setChassisSearchValue(value);
                this.props.dispatch(
                  setProductListSearchType(SearchTypes.SEARCH_BY_CHASSIS)
                );
                applicationActions.setProductTypeValue(ProductTypes.TIRES);
                applicationActions.deactivateSearchFlag();
                this.props.dispatch(toggleSearch(false));
              } else {
                productListActions.setChassisSearchValue(value);
                vehicleSelectionActions
                  .loadVehicleInfoByChassis()
                  .then(function () {
                    productListActions.loadTiresFromChassis();
                    applicationActions.deactivateSearchFlag();
                    this.props.dispatch(toggleSearch(false));
                  });
                /*
                                                setTimeout(() => {
                                                    vehicleSelectionActions.loadVehicleInfoByChassis().then(function () {
                                                        productListActions.loadTiresFromChassis();
                                                        applicationActions.deactivateSearchFlag();
                                                    });
                                                }, 10);
                                                */
              }
            }
            break;
          case ProductTypes.PACKAGES:
            break;
          default:
            break;
        }
      }
    }
  };

  getSearchTireSizeLink = (tiresize) => {
    if (tiresize) {
      if (applicationStore.data.nationalSite) {
        return {
          link:
            window.location.origin +
            "/national/" +
            applicationStore.data.nationalUrlCode +
            "/" +
            getAppLanguage() +
            "/dealer/" +
            getAppDealerId() +
            "/search/tiresize-search/" +
            tiresize,
          error: null,
        };
      } else if (applicationStore.data.dealerSite) {
        return {
          link:
            window.location.origin +
            "/" +
            applicationStore.data.dealerUrlCode +
            "/" +
            getAppLanguage() +
            "/dealer/" +
            getAppDealerId() +
            "/search/tiresize-search/" +
            tiresize,
          error: null,
        };
      } else if (applicationStore.data.groupSite) {
        return {
          link:
            window.location.origin +
            "/group/" +
            applicationStore.data.groupUrlCode +
            "/" +
            getAppLanguage() +
            "/dealer/" +
            getAppDealerId() +
            "/search/tiresize-search/" +
            tiresize,
          error: null,
        };
      }
    } else {
      var error = 0;
      return {
        link: null,
        error: error,
      };
    }
  };

  submitVehicleSimpleSearch = (token) => {
    if (this.state.searchType == SearchTypes.SEARCH_BY_VEHICLE_SIMPLE) {
      var model = this.state.selectedModel,
        category = "null";
      if (model.indexOf("-") > -1) {
        model = model.split("-");
        category = model[0];
        model = model[1];
      }
      this.props.dispatch(toggleSearch(!this.props.toggleSearch));
      //   applicationActions.deactivateSearchFlag();
      applicationActions.setProductTypeValue(this.state.productType);
      searchActions.loadVehicleSimpleSearch(
        this.state.selectedYear,
        this.state.selectedMake,
        category,
        model
      );

      //setTimeout(() => { searchActions.loadVehicleSimpleSearch(this.state.selectedYear, this.state.selectedMake, category, model) }, 100);
    }
  };

  onChange = () => {
    this.setState({
      yearList: vehicleSelectionStore.data.vehicleYears,
      categoryList: vehicleSelectionStore.data.categories,
      modelList: vehicleSelectionStore.data.models,

      year: vehicleSelectionStore.data.year,
      category: vehicleSelectionStore.data.category,
      model: vehicleSelectionStore.data.model,

      vehicleYears: vehicleSelectionStore.data.vehicleYears,
      vehicleMakes: vehicleSelectionStore.data.primaryMakes,
      vehicleSecondaryMakes: vehicleSelectionStore.data.secondaryMakes,
      vehicleModels: vehicleSelectionStore.data.models,
    });
  };
}

function mapStateToProps(state) {
  return {
    toggleSearch: state.info.toggleSearchFlag,
    searchYear: state.tire.vehicleYear,
  };
}

export default connect(mapStateToProps)(SearchModal);

import { applicationStore } from "../../stores/ApplicationStore.js";

import { getTrackingEventCategory } from "../../service/UtilityService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");

export class OutdatedApplicationVersion extends I18nComponent {
  state = {
    initialize: true,
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("Application Outdated Version Page");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Application Outdated Version Page Access"
    );
    applicationStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
  }

  render() {
    return (
      <div className="application-wrapper outdated-version-page">
        <h1>{this.t("common.outdatedApp")}</h1>
        <button className="btn" onClick={this.onReload}>
          {this.t("common.reload")}
        </button>
      </div>
    );
  }

  onReload = () => {
    window.location.reload();
  };

  onChange = () => {
    this.setState({
      initialize: true,
    });
  };
}

import { applicationStore } from "../../stores/ApplicationStore.js";
import { applicationActions } from "../../actions/ApplicationActions.js";
import { productListStore } from "../../stores/ProductListStore.js";
import { productListActions } from "../../actions/ProductListActions.js";
import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";

import { Image } from "../ux/Image.jsx";

import { ProductTypes } from "../../constants/ProductTypes.js";
import { RouterStates } from "../../constants/RouterStates.js";

import {
  renderTirePricing,
  getTirePricingLabel,
} from "../../service/RenderService.js";
import {
  renderNTDButton,
  checkNTDStockExists,
} from "../../service/OrderService.js";
import {
  getTabIndex,
  sizeOf,
  getAppLanguage,
  setComboBoxZIndex,
  sortTires,
} from "../../service/UtilityService";
import { captchaOnComponentDidMount } from "../../service/CaptchaService";
import { isUserLoggedIn } from "../../api/AuthService";
import { connect } from "react-redux";
import { changeQuantityOfTires } from "../../redux/features/productList.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var $ = require("jquery");

class SearchModelsByBrand extends I18nComponent {
  state = {
    modelId: applicationStore.data.selectedModelId,
    modelInfo: applicationStore.data.selectedModelData,
    listOfTires: productListStore.data.filteredListOfTires,
    stockQueryEmpty: productListStore.data.stockQueryEmpty,
    selectedTab: 0,
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    $(function () {
      $("body").animate({ scrollTop: 0 }, "slow");
    });
    captchaOnComponentDidMount(
      true,
      false,
      this.loadTireModelPartList,
      sizeOf(this.state.listOfTires) === 0
    );
  }

  componentDidUpdate(lastProps, lastState) {
    setComboBoxZIndex(this.state.listOfTires, lastState.listOfTires);
  }

  loadTireModelPartList = (token = "") => {
    productListActions.loadTireModelPartList(token);
  };

  render() {
    var _this = this,
      firstTire = null,
      language = getAppLanguage();
    if (sizeOf(this.state.listOfTires) > 0)
      this.state.listOfTires.map(function (tire, index) {
        if (index === 0) firstTire = tire;
      });

    return (
      <div className="tire-model-list">
        <div className="tire-model-header">
          <div className="model-title">
            <h2>{this.state.modelInfo.modelName}</h2>
          </div>
        </div>

        <div className="tire-model-body">
          <div className="tire-model-image">
            <Image src={this.state.modelInfo.modelImage} />
          </div>

          <div className="info-section">
            <div className="tire-model-tab-selection">
              <div
                className={
                  this.state.selectedTab === 0
                    ? "tab-select first selected"
                    : "tab-select first"
                }
                onClick={this.onSelectModelTab.bind(this, 0)}
              >
                <span>{this.t("tireModel.sizeAndPricing")}</span>
              </div>
              <div
                className={
                  this.state.selectedTab === 1
                    ? "tab-select selected"
                    : "tab-select"
                }
                onClick={this.onSelectModelTab.bind(this, 1)}
              >
                <span>{this.t("tireModel.tireDetails")}</span>
              </div>
              <div
                className={
                  this.state.selectedTab === 2
                    ? "tab-select last selected"
                    : "tab-select last"
                }
                onClick={this.onSelectModelTab.bind(this, 2)}
              >
                <span>{this.t("tireModel.warranty")}</span>
              </div>
            </div>

            <div className="tire-model-info">
              {this.state.selectedTab === 0 ? (
                <table className="model-part-table tire-info">
                  <thead>
                    <tr className="title-row">
                      <th className="title-column">
                        {this.t("product.tireSize")}
                      </th>
                      <th className="title-column">
                        {this.t("product.partNumber")}
                      </th>
                      <th className="title-column">
                        {this.t("product.speedRating")}
                      </th>
                      <th className="title-column">
                        {this.t("product.loadRating")}
                      </th>
                      <th className="title-column">{getTirePricingLabel()}</th>
                      <th className="title-column last-column"></th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.state.listOfTires
                      .sort(sortTires)
                      .map(function (tire, index) {
                        var ntdOrderStockEnabled =
                          checkNTDStockExists(tire.get("frontTire")) &&
                          applicationStore.data.dealerAllowNtdStockOrder;

                        //Collect Stock
                        var stockList = tire.get("frontTire").get("stock"),
                          stockTotal = 0;
                        if (stockList)
                          stockList.map(function (stock) {
                            stockTotal += parseInt(stock.get("stockQty"));
                          });

                        //TBC Stock
                        var inStock =
                          (!applicationStore.data.dealerHidePublicPricing(
                            isUserLoggedIn()
                          ) ||
                            isUserLoggedIn()) &&
                          (_this.state.stockQueryEmpty
                            ? _this.state.stockQueryEmpty.indexOf(
                                tire.get("frontTire").get("id")
                              ) === -1
                            : true) &&
                          (tire.get("frontTire").get("hasStock") ||
                            applicationStore.data.dealerIgnoreTireStock);

                        //Add To Cart
                        var hasAddToCart =
                          tire.get("frontTire").get("hasPricing") &&
                          tire
                            .get("frontTire")
                            .get("pricing")
                            .get("displayPrice") > 0 &&
                          (!applicationStore.data.dealerHidePublicPricing(
                            isUserLoggedIn()
                          ) ||
                            isUserLoggedIn()) &&
                          (inStock || stockTotal > 0) &&
                          !ntdOrderStockEnabled;

                        //Order Tire
                        var hasNtdOrder = tire
                          .get("frontTire")
                          .get("supportDealerOrder");

                        //Purchase Tire
                        var hasNtdPurchase =
                          !hasNtdOrder &&
                          hasAddToCart &&
                          tire.get("frontTire").get("supportConsumerOrder");

                        return (
                          <tr
                            className={
                              index % 2 === 0 ? "body-row even-row" : "body-row"
                            }
                          >
                            <td
                              className="body-column link"
                              onClick={_this.onSelectTire.bind(_this, tire)}
                            >
                              {tire.get("frontTire").get("tireSize")}
                            </td>
                            <td
                              className="body-column link"
                              onClick={_this.onSelectTire.bind(_this, tire)}
                            >
                              {tire.get("frontTire").get("partNumber")}
                            </td>
                            <td className="body-column rating-column">
                              {tire.get("frontTire").get("speedRating")}
                            </td>
                            <td className="body-column rating-column">
                              {tire.get("frontTire").get("loadRating")}
                            </td>
                            <td className="body-column rating-column">
                              {renderTirePricing(
                                tire,
                                _this.onChangeTireQuantity.bind(
                                  _this,
                                  tire.get("frontTire").get("id"),
                                  tire.get("frontTire").get("quantity")
                                )
                              )}
                            </td>
                            <td className="body-column action-column">
                              {hasNtdOrder
                                ? renderNTDButton(
                                    _this.onAddToBasket.bind(
                                      _this,
                                      tire,
                                      "order"
                                    ),
                                    "order",
                                    true
                                  )
                                : false}
                              {hasNtdPurchase
                                ? renderNTDButton(
                                    _this.onAddToBasket.bind(
                                      _this,
                                      tire,
                                      "purchase"
                                    ),
                                    "purchase",
                                    true
                                  )
                                : false}
                              {hasAddToCart &&
                              !hasNtdOrder &&
                              !hasNtdPurchase &&
                              (inStock || stockTotal > 0) ? (
                                <li>
                                  <a
                                    className="btn"
                                    role="button"
                                    tabIndex={getTabIndex()}
                                    aria-label={_this.t(
                                      "product.addToWishlist"
                                    )}
                                    onKeyDown={(e) =>
                                      _this.onAddToBasketOnKeyDown(tire, e)
                                    }
                                    onClick={_this.onAddToBasket.bind(
                                      _this,
                                      tire,
                                      ""
                                    )}
                                  >
                                    {_this.t("product.addToWishlist")}
                                  </a>
                                </li>
                              ) : (
                                false
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              ) : (
                false
              )}

              {this.state.selectedTab === 1 ? (
                <table className="model-part-table tire-details">
                  <thead>
                    <tr className="title-row">
                      <th className="title-column">
                        {this.t("tireModel.tireDetails")}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {firstTire ? (
                      <tr className="body-row even-row">
                        <td className="body-column description">
                          {firstTire.get("description").get(language)}
                        </td>
                      </tr>
                    ) : (
                      false
                    )}
                  </tbody>
                </table>
              ) : (
                false
              )}

              {this.state.selectedTab === 2 ? (
                <table className="model-part-table tire-warranty">
                  <thead>
                    <tr className="title-row">
                      <th className="title-column">
                        {this.t("tireModel.warranty")}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {firstTire ? (
                      <tr className="body-row even-row">
                        <td className="body-column warranty">
                          {firstTire.get("tireTreadlifeWarranty").get(language)}
                        </td>
                      </tr>
                    ) : (
                      false
                    )}
                  </tbody>
                </table>
              ) : (
                false
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  onSelectTire = (tire) => {
    applicationActions.setSelectedTirePart({
      partId: tire.get("frontTire").get("id"),
      partNumber: tire.get("frontTire").get("partNumber"),
    });
    setTimeout(() => {
      applicationActions.changeRouterStateDirectly(RouterStates.SEARCH_TIRE);
    }, 100);
  };

  onChangeTireQuantity = (tire, quantity) => {
    this.props.dispatch(
      changeQuantityOfTires({
        tireKey: tire.get("frontTire").get("id"),
        rearTire: null,
        quantity: quantity,
        front: true,
        featured: false,
        staggered: false,
      })
    );
  };

  onSelectModelTab = (tab) => {
    this.setState({
      selectedTab: tab,
    });
  };

  onAddToBasketOnKeyDown = (product, evt) => {
    checkForEvent: if ("undefined" != typeof evt.keyCode) {
      if (evt.keyCode == 13 || evt.keyCode == 32) {
        evt.preventDefault();
        evt.stopPropagation();
        break checkForEvent;
      }
      return;
    }
    this.onAddToBasket(product, "");
  };

  onAddToBasket = (product, source) => {
    $(function () {
      $("body").animate({ scrollTop: 0 }, "slow");
    });
    shoppingCartActions.addItemToShoppingCart(
      product.get("frontTire").get("id"),
      {
        en: product.get("name"),
        fr: product.get("name"),
      },
      product.get("smallImage"),
      product.get("frontTire").get("pricing").get("displayPrice"),
      product.get("frontTire").get("pricing").get("privatePrice"),
      product.get("frontTire").get("pricing").get("promotionPricingText"),
      parseInt(product.get("frontTire").get("quantity").get("value"), 10),
      ProductTypes.TIRES,
      product,
      0,
      product.get("frontTire").get("pricing").get("pricingContextId"),
      product.get("relatedParts"),
      0,
      product.get("frontTire").get("pricing").get("listPrice"),
      source === "purchase",
      source === "order",
      "Tire Model List"
    );
  };

  onChange = () => {
    this.setState({
      modelId: applicationStore.data.selectedModelId,
      modelInfo: applicationStore.data.selectedModelData,
      stockQueryEmpty: productListStore.data.stockQueryEmpty,
      listOfTires: productListStore.data.filteredListOfTires,
    });
  };
}

export default connect(null)(SearchModelsByBrand);

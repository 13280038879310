import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";

import loadApplicationInfo from "../redux/features/applicationInfo";
import breadCrumbs from "../redux/features/breadCrumbs";
import dealerId from "../redux/features/dealer";
import dealerSections from "../redux/features/dealerSections";
import loginInfo from "../redux/features/loginInfo";
import modal from "../redux/features/modal";
import productDetails from "../redux/features/productDetails";
import productList from "../redux/features/productList";
import quoteSlice from "../redux/features/quote";
import routes from "../redux/features/routes";
import searchTire from "../redux/features/searchTires";
import shoppingCart from "../redux/features/shoppingCart";
import vehicleInfo from "../redux/features/vehicleInfo";

function loggerMiddleware(store) {
  return function (next) {
    return function (action) {
      console.log(action);
      next(action);
    };
  };
}

const reducer = combineReducers({
  //reducers will come here
  breadCrumbs: breadCrumbs,
  dealer: dealerId,
  dealerSections: dealerSections,
  info: loadApplicationInfo,
  login: loginInfo,
  modal,
  productDetails,
  productList,
  quote: quoteSlice,
  routes,
  shoppingCart,
  tire: searchTire,
  vehicle: vehicleInfo,
});

const store = configureStore({
  middleware: [
    ...getDefaultMiddleware({ serializableCheck: false }),
    loggerMiddleware,
  ],
  reducer,
});

export default store;

import { applicationStore } from '../stores/ApplicationStore.js';
import { applicationActions } from '../actions/ApplicationActions.js';
import { shoppingCartActions } from "../actions/ShoppingCartActions";

import { globalState } from "../GlobalState";
import { RouterStates } from "../constants/RouterStates";

import { getTestId, TEST_LIST } from "./TestingService";
import { ADOBELAUNCHER_CLASS, ADOBELAUNCHER_DTM, setAdobeLaunchClass, setAdobeLaunchDTM } from "./AdobeLaunchService";
import {
  isEmpty,
  getTabIndex,
  isDealerSite,
  isNationalSite,
  getAppLanguage,
  isConsumerSite,
  getRouterState,
  isCarouselBreak,
} from './UtilityService';

var React = require('react');

export const renderTemplateLogo = (displayalternate = false) => {
  if (!isEmpty(getTemplateLogo())) {
    switch (applicationStore.data.defaultTemplate) {
      case 'audi_2021':
        return(
          <a style={{ margin: '0', cursor: 'default', verticalAlign: 'middle', }}><img id={getTestId(TEST_LIST.HEADER.LOGO)} tabIndex={getTabIndex()} className={setAdobeLaunchClass(getLogoClass(), ADOBELAUNCHER_CLASS.IMAGE)} data-dtm={setAdobeLaunchDTM('', ADOBELAUNCHER_DTM.GLOBALNAV.BASE)} src={getTemplateLogo(displayalternate)} onClick={onGoHomepage} alt=""/></a>
        );
      case 'mini':
        if (getRouterState() !== RouterStates.DEALER_SELECTION && getRouterState() !== RouterStates.LANDING_PAGE) {
          return(
            <a style={{ margin: '0' }}><img id={getTestId(TEST_LIST.HEADER.LOGO)} tabIndex={getTabIndex()} className={setAdobeLaunchClass(getLogoClass(), ADOBELAUNCHER_CLASS.IMAGE)} data-dtm={setAdobeLaunchDTM('', ADOBELAUNCHER_DTM.GLOBALNAV.BASE)} src={getTemplateLogo()} onClick={onGoHomepage} alt=""/></a>
          );
        } else {
          return false;
        }
      case 'bmw':
        if (getRouterState() !== RouterStates.DEALER_SELECTION && getRouterState() !== RouterStates.LANDING_PAGE && getRouterState() !== RouterStates.PRODUCT_LIST && getRouterState() !== RouterStates.SEARCH_RESULT) {
          return(
            <a style={{ margin: '0' }}><img id={getTestId(TEST_LIST.HEADER.LOGO)} tabIndex={getTabIndex()} className={setAdobeLaunchClass(getLogoClass(), ADOBELAUNCHER_CLASS.IMAGE)} data-dtm={setAdobeLaunchDTM('', ADOBELAUNCHER_DTM.GLOBALNAV.BASE)} src={getTemplateLogo()} onClick={onGoHomepage} alt=""/></a>
          );
        } else {
          return false;
        }
      case 'kia_2021':
        return(
          <a style={{ margin: '0', cursor: 'default' }}><img id={getTestId(TEST_LIST.HEADER.LOGO)} tabIndex={getTabIndex()} className={setAdobeLaunchClass(getLogoClass(), ADOBELAUNCHER_CLASS.IMAGE)} data-dtm={setAdobeLaunchDTM('', ADOBELAUNCHER_DTM.GLOBALNAV.BASE)} src={getTemplateLogo(displayalternate)} onClick={onGoHomepage} alt=""/></a>
        );
      case 'gmcanada':
        if (getRouterState() === RouterStates.WISHLIST || getRouterState() === RouterStates.PRINT_WISHLIST) {
          return(
            <a style={{ margin: '0' }}><img id={getTestId(TEST_LIST.HEADER.LOGO)} tabIndex={getTabIndex()} className={setAdobeLaunchClass(getLogoClass(), ADOBELAUNCHER_CLASS.IMAGE)} data-dtm={setAdobeLaunchDTM('', ADOBELAUNCHER_DTM.GLOBALNAV.BASE)} src={getTemplateLogo()} style={{ height: '50px' }} onClick={onGoHomepage} alt=""/></a>
          );
        } else {
          return(
            <a style={{ margin: '0' }}><img id={getTestId(TEST_LIST.HEADER.LOGO)} tabIndex={getTabIndex()} className={setAdobeLaunchClass(getLogoClass(), ADOBELAUNCHER_CLASS.IMAGE)} data-dtm={setAdobeLaunchDTM('', ADOBELAUNCHER_DTM.GLOBALNAV.BASE)} src={getTemplateLogo()} onClick={onGoHomepage} alt=""/></a>
          );
        }
      default:
        return(
          <a style={{ margin: '0' }}><img id={getTestId(TEST_LIST.HEADER.LOGO)} tabIndex={getTabIndex()} className={setAdobeLaunchClass(getLogoClass(), ADOBELAUNCHER_CLASS.IMAGE)} data-dtm={setAdobeLaunchDTM('', ADOBELAUNCHER_DTM.GLOBALNAV.BASE)} src={getTemplateLogo()} onClick={onGoHomepage} alt=""/></a>
        );
    }
  } else {
    return false;
  }
};

const getLogoClass = () => {
  switch (applicationStore.data.defaultTemplate) {
    case 'audi_2021':
      return "logo-image";
    case "toyota":
    case "subaru":
      return 'large';
    case "mercedes":
    case "mercedes_2022":
    case "mercedestransition":
      return 'reduced';
    default:
      return hasCustomLogo() ? 'custom' : '';
  }
};

const hasCustomLogo = () => {
  return !isEmpty(getCustomLogo());
};

const getCustomLogo = () => {
  if (isDealerSite() || isNationalSite()) {
    return applicationStore.data.dealerRetailSiteCustomLogo;
  } else {
    return applicationStore.data.groupRetailSiteCustomLogo;
  }
};

export const getTemplateLogo = (displayalternate = false) => {
  var customLogo = getCustomLogo();
  if (!isEmpty(customLogo)) {
    return customLogo;
  } else {
    switch (applicationStore.data.defaultTemplate) {
      case "gm":
      case "gmcanada":

        if (getRouterState() === RouterStates.WISHLIST || getRouterState() === RouterStates.PRINT_WISHLIST) {
          return getAppLanguage() == 'fr' ? "/static/img/gmcanada/CSCrossBrand_fr.png" : "/static/img/gmcanada/CSCrossBrand_en.png";
        } else {
        return getAppLanguage() == 'fr' ? "/static/img/gmcanada/logo_fr.png" : "/static/img/gmcanada/logo.png";
        }

      case "mercedestransition":
        return "/static/img/mercedestransition/logo_old.png";
      case "toyota":
        return "/static/img/toyota/toyota_logo.png";
        // return "/static/img/toyota/toyota_logo_red.png";
      case 'audi_2021':
      case "kia_2021":
        if (displayalternate) {
          return getAppLanguage() == 'fr' ?  "/static/img/" + applicationStore.data.defaultTemplate + "/logo_white_fr.png" : "/static/img/" + applicationStore.data.defaultTemplate + "/logo_white.png";
        } else {
          return getAppLanguage() == 'fr' ?  "/static/img/" + applicationStore.data.defaultTemplate + "/logo_fr.png" : "/static/img/" + applicationStore.data.defaultTemplate + "/logo.png";
        }
      case "volkswagen_2020":
        // if (!isCarouselBreak() && getRouterState() === RouterStates.HOMEPAGE || getRouterState() === RouterStates.LANDING_PAGE) {
        //   return "/static/img/" + applicationStore.data.defaultTemplate + "/logo_white.png";
        // } else {
        //   return "/static/img/" + applicationStore.data.defaultTemplate + "/logo.png";
        // }
        if (!isCarouselBreak() && getRouterState() === RouterStates.HOMEPAGE || getRouterState() === RouterStates.LANDING_PAGE) {
          return "/static/img/volkswagen_2020/logo_white.png";
        } else {
          return "/static/img/volkswagen_2020/logo.png";
        }
      default:
        return "/static/img/" + applicationStore.data.defaultTemplate + "/logo.png";
    }
  }
};

const onGoHomepage = () => {
  if (isConsumerSite()) {
    window.location.reload();
  } else {
    var routerState = globalState.select('applicationState', 'routerState').get();
    if (routerState !== RouterStates.LANDING_PAGE && routerState !== RouterStates.DEALER_SELECTION) {
      if (routerState === RouterStates.WISHLIST) shoppingCartActions.duplicateCart();
      applicationActions.changeRouterStateDirectly(RouterStates.HOMEPAGE);
    }
  }
};


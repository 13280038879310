import { applicationStore } from "../../stores/ApplicationStore.js";
import { languageStore } from "../../stores/LanguageStore.js";
import { productListStore } from "../../stores/ProductListStore.js";
import { productListActions } from "../../actions/ProductListActions.js";
import { ProductTypes } from "../../constants/ProductTypes.js";

import { Image } from "../ux/Image.jsx";

import {
  getPricingFromQuantityAndDetailsRefactored,
  getPricingRebateByQuantity,
} from "../../service/ProductList";
import {
  isEmpty,
  sizeOf,
  money_format,
  getAppLanguage,
  getTrackingEventCategory,
} from "../../service/UtilityService";
import { isUserLoggedIn } from "../../api/AuthService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var Cx = require("classnames");

export class ComparisonPage extends I18nComponent {
  state = {
    comparisonData: productListStore.data.comparisonPartData,
    comparisonList: productListStore.data.comparisonPartList,
    comparisonQuantityList: productListStore.data.comparisonQuantityList,
    comparisonErrorModal: productListStore.data.failedAddToComparison,
    comparisonErrorType: productListStore.data.comparisonErrorType,
    comparisonLoadingError: productListStore.data.comparisonLoadingError,

    compareProductdata: applicationStore.data.compareProductdata,

    showProductInformation: true,
    showProductInformationTransition: false,
    showWarranty: false,
    showDescription: false,
    showOtherInfo: false,

    showPricingInformation: true,
    showPricingInformationTransition: false,

    quantityModalToggle: false,
    quantityModalPart: null,

    customerFirstName: "",
    customerLastName: "",
    customerEmail: "",
    customerPhone: "",
    customerNotes: "",

    printHasRun: false,
    runPrintFunction: function () {},
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("Comparison Page");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Comparison Page Access"
    );
    productListStore.addChangeListener(this.onChange);
    applicationStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    productListStore.removeChangeListener(this.onChange);
    applicationStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    var partIdList = [];
    if (applicationStore.data.compareDataLoad.indexOf(",") > -1) {
      partIdList = applicationStore.data.compareDataLoad.split(",");
    } else if (applicationStore.data.compareDataLoad) {
      partIdList = [applicationStore.data.compareDataLoad];
    }

    var firstPartId =
      partIdList && partIdList.length >= 1 ? partIdList[0] : "0";
    var secondPartId =
      partIdList && partIdList.length >= 2 ? partIdList[1] : "0";
    var thirdPartId =
      partIdList && partIdList.length >= 3 ? partIdList[2] : "0";

    if (
      !isEmpty(firstPartId) &&
      !isEmpty(secondPartId) &&
      !isEmpty(thirdPartId)
    ) {
      productListActions.onGetProductComparison(
        ProductTypes.TIRES,
        applicationStore.data.vehicle,
        firstPartId,
        secondPartId,
        thirdPartId
      );
    } else {
      var productDataList = [];
      if (applicationStore.data.compareProductdata.indexOf("-") > -1) {
        productDataList = applicationStore.data.compareProductdata.split("-");
      }

      var dealerId =
        productDataList && sizeOf(productDataList) >= 1
          ? productDataList[0]
          : null;
      var vehicleId =
        productDataList && sizeOf(productDataList) >= 2
          ? productDataList[1]
          : null;
      var trimId =
        productDataList && sizeOf(productDataList) >= 3
          ? productDataList[2]
          : null;
      var tireSizeId =
        productDataList && sizeOf(productDataList) >= 4
          ? productDataList[3]
          : null;

      if (dealerId !== null && tireSizeId !== null)
        productListActions.onLoadProductComparsionFromTireList(
          dealerId,
          vehicleId,
          tireSizeId
        );
    }

    if (window.location.href.indexOf("?") > -1) {
      var split = window.location.href.split("?")[1];

      var params = split.split("&");
      var firstName = "",
        lastName = "",
        email = "",
        phone = "",
        notes = "";

      for (var i in params) {
        var key = params[i].split("=");
        if (key[0] == "firstName") firstName = key[1];
        else if (key[0] == "lastName") lastName = key[1];
        else if (key[0] == "email") email = key[1];
        else if (key[0] == "phone") phone = key[1];
        else if (key[0] == "notes") notes = key[1];
      }

      this.setState({
        customerFirstName: decodeURIComponent(firstName),
        customerLastName: decodeURIComponent(lastName),
        customerEmail: decodeURIComponent(email),
        customerPhone: decodeURIComponent(phone),
        customerNotes: decodeURIComponent(notes),
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props != nextProps || this.state != nextState;
  }

  componentDidUpdate() {
    if (!this.state.printHasRun && sizeOf(this.state.comparisonList) > 0) {
      this.setState({
        printHasRun: true,
        suffixFunc: setInterval(() => {
          this.runWindowPrint();
        }, 1000),
      });
    }
  }

  runWindowPrint = () => {
    if (this.state.cart !== null) {
      this.setState({ printHasRun: true, runPrintFunction: function () {} });
      clearInterval(this.state.suffixFunc);
      setTimeout(() => {
        window.print();
      }, 2500);
    }
  };

  render() {
    var comparisonList = this.state.comparisonList,
      _this = this,
      noCost = this.t("comparison.noCost");

    var tableClassName = Cx({
      "comparison-table": true,
      "hide-product-information": !this.state.showProductInformation,
      "hide-pricing-information": !this.state.showPricingInformation,
    });

    var dealerTax = " (" + applicationStore.data.dealerTaxPercent + "%) ";
    var dealerInfo = applicationStore.data.dealerInfo;

    var headerLogo =
      "/static/img/" + applicationStore.data.defaultTemplate + "/logo.png";
    if (applicationStore.data.dealerRetailSiteCustomLogo)
      headerLogo = applicationStore.data.dealerRetailSiteCustomLogo;

    var customerInformation = false,
      firstName = "",
      lastName = "",
      email = "",
      phone = "",
      notes = "";
    if (!isEmpty(this.state.customerFirstName))
      firstName = this.state.customerFirstName;
    if (!isEmpty(this.state.customerLastName))
      lastName = this.state.customerLastName;
    if (!isEmpty(this.state.customerEmail)) email = this.state.customerEmail;
    if (!isEmpty(this.state.customerPhone)) phone = this.state.customerPhone;
    if (!isEmpty(this.state.customerNotes)) notes = this.state.customerNotes;

    if (
      !isEmpty(firstName) ||
      !isEmpty(lastName) ||
      !isEmpty(email) ||
      !isEmpty(phone) ||
      !isEmpty(notes)
    ) {
      customerInformation = (
        <div className="comparison-customer-info">
          {!isEmpty(firstName) ? (
            <div>
              <span>{this.t("wishlist.modal.firstName")}: </span>
              <span>{firstName}</span>
            </div>
          ) : (
            false
          )}
          {!isEmpty(lastName) ? (
            <div>
              <span>{this.t("wishlist.modal.lastName")}: </span>
              <span>{lastName}</span>
            </div>
          ) : (
            false
          )}
          {!isEmpty(email) ? (
            <div>
              <span>{this.t("wishlist.modal.email")}: </span>
              <span>{email}</span>
            </div>
          ) : (
            false
          )}
          {!isEmpty(phone) ? (
            <div>
              <span>{this.t("wishlist.modal.phone")}: </span>
              <span>{phone}</span>
            </div>
          ) : (
            false
          )}
          {!isEmpty(notes) ? (
            <div>
              <span>{this.t("wishlist.modal.notes")}: </span>
              <span>{notes}</span>
            </div>
          ) : (
            false
          )}
        </div>
      );
    }

    return (
      <div className="comparison-page print-comparison">
        <div className="print-header">
          <div className="print-logo">
            <Image className="logo-image" src={headerLogo} />
          </div>
          <div className="print-dealer-info">
            {dealerInfo ? (
              <ul className="dealer-info-list">
                <li className="dealer-info">{dealerInfo["name"]}</li>
                <li className="dealer-info">
                  {dealerInfo["address"] + ", " + dealerInfo["city"]}
                </li>
                <li className="dealer-info">
                  {dealerInfo["province"] + ", " + dealerInfo["postalCode"]}
                </li>
                <li className="dealer-info">{dealerInfo["phone"]}</li>
                <li className="dealer-info">{dealerInfo["email"]}</li>
              </ul>
            ) : (
              false
            )}
          </div>
        </div>

        <div className="modal-wrapper comparison-modal-content">
          <div className="comparison-header">
            <h2>{this.t("comparison.title")}</h2>
          </div>
          {customerInformation}
          <div className="comparison-body">
            <table className={tableClassName}>
              <tbody>
                <tr className="first-row">
                  <th className="first-column"></th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        return (
                          <td key={"first-row-part-" + index}>
                            <div className="cell-top">
                              <div className="part-name">
                                <h3>{part.get("name")}</h3>
                              </div>
                              <div className="part-image">
                                <Image
                                  className="part-main-image"
                                  src={part.get("largeImage")}
                                  alt="Part Image"
                                />
                                <Image
                                  src={part.get("brandImage")}
                                  className="part-brand"
                                  alt={
                                    "Image Brand " + part.get("manufacturer")
                                  }
                                />
                              </div>
                            </div>
                          </td>
                        );
                      })
                    : false}
                </tr>

                <tr className="section-label-row">
                  <th className="first-column first-cell">
                    <div className="section-title">
                      <span>{this.t("comparison.product")}</span>
                    </div>
                  </th>
                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        switch (index) {
                          case comparisonList.size - 1:
                            return (
                              <td
                                key={"first-section-label-row-" + index}
                                className="last-cell"
                              >
                                <div className="section-control"></div>
                              </td>
                            );
                          default:
                            return (
                              <td key={"first-section-label-row-" + index}></td>
                            );
                        }
                      })
                    : false}
                </tr>

                <tr className="second-row even">
                  <th className="first-column">
                    {this.t("comparison.productDetails") + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        // Get Part Info
                        var productPartNumberLabel = "",
                          partNumber = "";
                        if (
                          typeof part
                            .get("frontTire")
                            .get("nationalPartNumber") !== "undefined" &&
                          part.get("frontTire").get("nationalPartNumber") !==
                            null &&
                          part.get("frontTire").get("nationalPartNumber") !== ""
                        ) {
                          partNumber = part
                            .get("frontTire")
                            .get("nationalPartNumber");
                          productPartNumberLabel = _this.t(
                            "product.nationalPartNumber"
                          );
                        } else {
                          partNumber = part.get("frontTire").get("partNumber");
                          productPartNumberLabel =
                            _this.t("product.partNumber");
                        }
                        var dualLoadRating = false;
                        if (
                          part.get("frontTire").get("dualFitmentLoadRating") > 0
                        ) {
                          dualLoadRating =
                            " / " +
                            part.get("frontTire").get("dualFitmentLoadRating");
                        }
                        var plyRating = false;
                        if (part.get("frontTire").get("plyRating")) {
                          plyRating =
                            " / " + part.get("frontTire").get("plyRating");
                        }
                        var hasMileageWarranty = false,
                          mileageWarranty = false,
                          milageText = "";
                        if (
                          part.get("tireTreadlifeWarranty") &&
                          part
                            .get("tireTreadlifeWarranty")
                            .get(applicationStore.data.dealerInfo.country) !==
                            "" &&
                          typeof part
                            .get("tireTreadlifeWarranty")
                            .get(applicationStore.data.dealerInfo.country) !==
                            "undefined"
                        ) {
                          hasMileageWarranty = true;
                          mileageWarranty = part
                            .get("tireTreadlifeWarranty")
                            .get(applicationStore.data.dealerInfo.country);
                          switch (applicationStore.data.dealerInfo.country) {
                            case "CA":
                              milageText =
                                getAppLanguage() === "fr" ? "km" : "Km";
                              break;
                            case "US":
                              milageText =
                                getAppLanguage() === "fr" ? "mi." : "mi.";
                              break;
                          }
                        }

                        return (
                          <td key={"second-row-part-" + index}>
                            <div className="cell-top">
                              <div className="part-info">
                                <div className="first">
                                  <span>{productPartNumberLabel + ": "}</span>
                                  <span className="feature-value part-number">
                                    {partNumber}
                                  </span>
                                </div>
                                <div className="middle">
                                  <span>
                                    {_this.t("product.tireSize") + ": "}
                                  </span>
                                  <span className="feature-value">
                                    {part
                                      .get("frontTire")
                                      .get("tireVehicleClass") +
                                      part.get("frontTire").get("tireSize")}
                                  </span>
                                </div>
                                <div className="middle">
                                  <span>
                                    {_this.t("product.loadSpeedPly") + ": "}
                                  </span>
                                  <span className="feature-value">
                                    {part.get("loadRating")} {dualLoadRating} /{" "}
                                    {part.get("speedRating")}
                                    {plyRating}
                                  </span>
                                </div>
                                {mileageWarranty ? (
                                  <div className="last">
                                    <span>
                                      {_this.t("product.mileageWarranty") +
                                        ": "}
                                    </span>
                                    <span className="feature-value">
                                      {mileageWarranty + " " + milageText}
                                    </span>
                                  </div>
                                ) : (
                                  false
                                )}
                              </div>
                            </div>
                          </td>
                        );
                      })
                    : false}
                </tr>

                <tr className="third-row">
                  <th className="first-column">
                    {this.t("product.season") + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        return (
                          <td key={"third-row-part-" + index}>
                            <div className="cell-top">
                              {part.get("tireSeason") === "0" ? (
                                <div className="part-season">
                                  <span>
                                    {_this.t("product.filters.season.winter")}
                                  </span>
                                  <Image
                                    className="season-icon"
                                    src="/static/img/winter.png"
                                  />
                                </div>
                              ) : (
                                false
                              )}
                              {part.get("tireSeason") === "1" ? (
                                <div className="part-season">
                                  <span>
                                    {_this.t(
                                      "product.filters.season.allSeason"
                                    )}
                                  </span>
                                  <Image
                                    className="season-icon"
                                    src="/static/img/allseason.png"
                                  />
                                </div>
                              ) : (
                                false
                              )}
                              {part.get("tireSeason") === "2" ? (
                                <div className="part-season">
                                  <span>
                                    {_this.t("product.filters.season.summer")}
                                  </span>
                                  <Image
                                    className="season-icon"
                                    src="/static/img/summer.png"
                                  />
                                </div>
                              ) : (
                                false
                              )}
                            </div>
                          </td>
                        );
                      })
                    : false}
                </tr>

                <tr className="fourth-row even">
                  <th className="first-column">
                    {this.t("product.description") + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        var description = languageStore.getTranslated(
                          part.get("description").toJS()
                        );

                        return (
                          <td key={"fourth-row-part-" + index}>
                            <div className="cell-top">
                              {description ? (
                                <div className="part-description">
                                  <div className="tire-description">
                                    <span
                                      className="description-link text-small"
                                      dangerouslySetInnerHTML={{
                                        __html: description,
                                      }}
                                    ></span>
                                  </div>
                                </div>
                              ) : (
                                false
                              )}
                            </div>
                          </td>
                        );
                      })
                    : false}
                </tr>

                <tr className="fifth-row">
                  <th className="first-column">
                    {this.t("product.warranty") + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        var itemWarranty = false,
                          warranty = false;
                        if (part.get("frontTire").get("warranty")) {
                          part
                            .get("frontTire")
                            .get("warranty")
                            .forEach(function (warranty) {
                              if (
                                warranty &&
                                applicationStore.data.dealerInfo &&
                                applicationStore.data.dealerInfo.country &&
                                getAppLanguage()
                              ) {
                                if (
                                  warranty.get("warrantyCountryCode") &&
                                  String(
                                    warranty.get("warrantyCountryCode")
                                  ).toUpperCase() ==
                                    applicationStore.data.dealerInfo.country &&
                                  warranty.get("warrantyLanguageCode") &&
                                  String(
                                    warranty.get("warrantyLanguageCode")
                                  ).toLowerCase() == getAppLanguage()
                                ) {
                                  itemWarranty = warranty.get("warrantyText");
                                }
                              }
                            });
                        }
                        if (
                          itemWarranty &&
                          itemWarranty.indexOf(
                            "Treadlife Warranty: 45,000 - 65,000 miles - - -"
                          ) !== -1
                        ) {
                          itemWarranty =
                            itemWarranty.substr(
                              0,
                              itemWarranty.indexOf("miles - - -") + 5
                            ) + "...";
                          warranty = (
                            <div className="tire-warranty">
                              <span
                                className="warranty-link text-small"
                                dangerouslySetInnerHTML={{
                                  __html: itemWarranty,
                                }}
                              ></span>
                            </div>
                          );
                        } else if (itemWarranty && itemWarranty.length <= 75) {
                          warranty = (
                            <div className="tire-warranty">
                              <span
                                className="warranty-link text-small"
                                dangerouslySetInnerHTML={{
                                  __html: itemWarranty,
                                }}
                              ></span>
                            </div>
                          );
                        } else {
                          if (itemWarranty && itemWarranty.length > 0) {
                            warranty = (
                              <div className="tire-warranty">
                                <span
                                  className="warranty-link text-small"
                                  dangerouslySetInnerHTML={{
                                    __html: itemWarranty,
                                  }}
                                ></span>
                              </div>
                            );
                          }
                        }

                        return (
                          <td key={"fifth-row-part-" + index}>
                            <div className="cell-top">
                              {part.get("frontTire").get("warranty") ? (
                                <div className="part-warranty">{warranty}</div>
                              ) : (
                                false
                              )}
                            </div>
                          </td>
                        );
                      })
                    : false}
                </tr>

                <tr className="sixth-row even">
                  <th className="first-column">
                    {this.t("comparison.otherInformation") + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        return (
                          <td key={"sixth-row-part-" + index}>
                            <div className="cell-top">
                              {!!part.get("originalEquipment") ? (
                                <span className="original-text">
                                  {_this.t("product.approvedTire")}
                                </span>
                              ) : (
                                false
                              )}
                            </div>
                          </td>
                        );
                      })
                    : false}
                </tr>

                <tr className="section-label-row">
                  <th className="first-column first-cell">
                    <div className="section-title">
                      <span>{this.t("comparison.pricing")}</span>
                    </div>
                  </th>
                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        switch (index) {
                          case comparisonList.size - 1:
                            return (
                              <td
                                key={"second-section-label-row-" + index}
                                className="last-cell"
                              >
                                <div className="section-control"></div>
                              </td>
                            );
                          default:
                            return (
                              <td
                                key={"second-section-label-row-" + index}
                              ></td>
                            );
                        }
                      })
                    : false}
                </tr>

                <tr className="seventh-row">
                  <th className="first-column">
                    {this.t("comparison.pricingDetails") + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        if (!_this.hidePricing(part)) {
                          var priceLabel = _this.t("product.retailPrice");
                          if (
                            applicationStore.data.dealerSite &&
                            applicationStore.data.dealerSellingPriceLabel[
                              ProductTypes.TIRES
                            ][getAppLanguage()]
                          ) {
                            priceLabel =
                              applicationStore.data.dealerSellingPriceLabel[
                                ProductTypes.TIRES
                              ][getAppLanguage()];
                          }

                          var showMSRP =
                            applicationStore.data.dealerShowMsrp &&
                            parseFloat(
                              part
                                .get("frontTire")
                                .get("pricing")
                                .get("listPrice")
                            ) >
                              parseFloat(
                                part
                                  .get("frontTire")
                                  .get("pricing")
                                  .get("displayPrice")
                              );

                          var enableMinimumTag =
                            !isUserLoggedIn() &&
                            part
                              .get("frontTire")
                              .get("pricing")
                              .get("marketMinimum");
                          var enableMarketMinimumTag =
                            isUserLoggedIn() &&
                            parseFloat(
                              part
                                .get("frontTire")
                                .get("pricing")
                                .get("oldPrice")
                            ) > 0;

                          var quantityDropDown = false;
                          if (part.get("frontTire").get("hasPricing")) {
                            quantityDropDown = (
                              <div>
                                <span className="quantity">
                                  {part
                                    .get("frontTire")
                                    .get("quantity")
                                    .get("value")}
                                </span>
                              </div>
                            );
                          }

                          var pricing = false,
                            total =
                              parseFloat(
                                part
                                  .get("frontTire")
                                  .get("pricing")
                                  .get("privatePrice")
                              ) *
                              parseFloat(
                                part
                                  .get("frontTire")
                                  .get("quantity")
                                  .get("value")
                              );
                          if (part.get("hasPromotion")) {
                            // has promotion
                            var price =
                              getPricingFromQuantityAndDetailsRefactored(
                                part.get("frontTire").get("pricingDetails"),
                                part.get("frontTire").get("quantity")
                              );

                            var promotionPricingText =
                              price["promotionPricingText"];
                            var displaySavings = price["displaySavings"];

                            //var promotionText = _this.td(promotionPricingText.toJS());
                            var promotionText = "";
                            if (typeof promotionPricingText === "object")
                              promotionText = promotionPricingText.get(
                                getAppLanguage()
                              );

                            if (
                              price &&
                              price["url"] &&
                              price["url"][getAppLanguage()] !== ""
                            ) {
                              promotionText = (
                                <a
                                  className="promotion-link"
                                  href={price["url"].get(getAppLanguage())}
                                  target="_blank"
                                >
                                  <span>{promotionText}</span>
                                </a>
                              );
                            }

                            var quantityText =
                              _this.t("product.quantity") + ":";
                            if (
                              part
                                .get("frontTire")
                                .get("pricing")
                                .get("showUnitPrice")
                            ) {
                              quantityText =
                                money_format(
                                  part
                                    .get("frontTire")
                                    .get("pricing")
                                    .get("displayPrice")
                                ) +
                                " " +
                                _this.t("product.perTireLwr");
                            }

                            pricing = (
                              <ul className="tire-promoted-pricing">
                                <li className="promoted-title">
                                  <span>{promotionText}</span>
                                </li>
                                <li className="promoted-quantity">
                                  <span className="pricing-label">
                                    {quantityText}
                                  </span>
                                  <span className="pricing-value">
                                    {quantityDropDown}
                                  </span>
                                </li>
                                {displaySavings > 0 ? (
                                  <li className="promoted-savings">
                                    <span className="pricing-label">
                                      {_this.t("product.youSave") + ":"}
                                    </span>
                                    <span className="pricing-value">
                                      {money_format(
                                        displaySavings *
                                          parseInt(
                                            part
                                              .get("frontTire")
                                              .get("quantity")
                                              .get("value"),
                                            10
                                          )
                                      )}
                                    </span>
                                  </li>
                                ) : (
                                  false
                                )}
                                <li className="pricing-total">
                                  <span className="pricing-label">
                                    {_this.t("product.total") + ":"}
                                  </span>
                                  <span className="pricing-value">
                                    {money_format(total)}
                                  </span>
                                </li>
                              </ul>
                            );
                          } else if (
                            part.get("frontTire").get("hasPricing") &&
                            part
                              .get("frontTire")
                              .get("pricing")
                              .get("displayPrice") > 0
                          ) {
                            var totalList =
                                parseFloat(
                                  part
                                    .get("frontTire")
                                    .get("pricing")
                                    .get("listPrice")
                                ) *
                                parseFloat(
                                  part
                                    .get("frontTire")
                                    .get("quantity")
                                    .get("value")
                                ),
                              savings = totalList - total;

                            //{enableMinimumTag ? <li className="pricing-msrp"><span className="pricing-label"><img src="/static/img/downarrow_icon.png" />{_this.t('marketMinimum')}</span></li> : false}

                            pricing = (
                              <ul className="tire-non-promoted-pricing">
                                {enableMarketMinimumTag ? (
                                  <li className="pricing-msrp">
                                    <span className="pricing-label">
                                      <img
                                        src="/static/img/alert_icon.png"
                                        className="alert"
                                      />
                                      {_this.t("belowMarket")}{" "}
                                      {money_format(
                                        part
                                          .get("frontTire")
                                          .get("pricing")
                                          .get("oldPrice")
                                      )}
                                    </span>
                                  </li>
                                ) : (
                                  false
                                )}
                                {showMSRP ? (
                                  <li className="pricing-msrp">
                                    <span className="pricing-label">
                                      {_this.t("product.msrp") + ":"}
                                    </span>
                                    <span className="pricing-value">
                                      {money_format(
                                        part
                                          .get("frontTire")
                                          .get("pricing")
                                          .get("listPrice")
                                      )}
                                    </span>
                                  </li>
                                ) : (
                                  false
                                )}
                                <li className="pricing-retail-price">
                                  <span className="pricing-label">
                                    {priceLabel + ":"}
                                  </span>
                                  <span className="pricing-value">
                                    {money_format(
                                      part
                                        .get("frontTire")
                                        .get("pricing")
                                        .get("displayPrice")
                                    )}
                                  </span>
                                </li>
                                <li className="pricing-quantity">
                                  <span className="pricing-label">
                                    {_this.t("product.quantity") + ":"}
                                  </span>
                                  <span className="pricing-value">
                                    {quantityDropDown}
                                  </span>
                                </li>
                                <li className="pricing-total">
                                  <span className="pricing-label">
                                    {_this.t("product.total") + ":"}
                                  </span>
                                  <span className="pricing-value">
                                    {money_format(total)}
                                  </span>
                                </li>
                                {applicationStore.data.dealerShowMsrp &&
                                savings > 0 ? (
                                  <li className="pricing-savings">
                                    <span className="pricing-label">
                                      {_this.t("product.totalSavings") + ":"}
                                    </span>
                                    <span className="pricing-value">
                                      {money_format(savings)}
                                      {enableMinimumTag ? "*" : false}
                                    </span>
                                  </li>
                                ) : (
                                  false
                                )}
                              </ul>
                            );
                          } else {
                            pricing = (
                              <ul className="tire-non-promoted-pricing">
                                <li className="pricing-msrp">
                                  <p className="contact-message">
                                    {_this.t("product.contactDealerForPricing")}
                                  </p>
                                </li>
                              </ul>
                            );
                          }

                          return (
                            <td key={"seventh-row-part-" + index}>
                              <div className="cell-top">
                                <div className="tire-pricing">{pricing}</div>
                              </div>
                            </td>
                          );
                        } else {
                          return <td></td>;
                        }
                      })
                    : false}
                </tr>

                <tr className="eighth-row even">
                  <th className="first-column">
                    {this.t("comparison.promotion") + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        if (!_this.hidePricing(part)) {
                          var link = false,
                            promotionText = false;
                          if (
                            part
                              .get("frontTire")
                              .get("pricing")
                              .get("promotionText")
                              .toJS().en !== ""
                          ) {
                            var price =
                                getPricingFromQuantityAndDetailsRefactored(
                                  part.get("frontTire").get("pricingDetails"),
                                  part.get("frontTire").get("quantity")
                                ),
                              link = getPricingRebateByQuantity(
                                part.get("frontTire").get("pricingDetails"),
                                part.get("frontTire").get("quantity")
                              );
                            var promotionPricingText =
                              price["promotionPricingText"];
                            var displaySavings = price["displaySavings"];
                            var promotionText = "";
                            if (typeof promotionPricingText === "object")
                              promotionText = promotionPricingText.get(
                                getAppLanguage()
                              );
                            if (
                              price &&
                              price["url"] &&
                              price["url"][getAppLanguage()] !== ""
                            ) {
                              promotionText = (
                                <a
                                  className="promotion-link"
                                  href={price["url"].get(getAppLanguage())}
                                  target="_blank"
                                >
                                  <span>{promotionText}</span>
                                </a>
                              );
                            }
                          }

                          return (
                            <td key={"eighth-row-part-" + index}>
                              <div className="cell-top">{promotionText}</div>
                            </td>
                          );
                        } else {
                          return <td></td>;
                        }
                      })
                    : false}
                </tr>

                <tr className="ninth-row">
                  <th className="first-column">
                    {this.t("comparison.additionalCosts") + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        if (!_this.hidePricing(part)) {
                          var quantityValue = part
                            .get("frontTire")
                            .get("quantity")
                            .get("value");
                          var quantityContextId = part
                            .get("frontTire")
                            .get("quantity")
                            .get("pricingContextId");

                          var lineItemList = false;
                          var quantityContext =
                            _this.state.comparisonQuantityList[index];
                          if (quantityContext)
                            quantityContext = quantityContext[quantityValue];
                          if (quantityContext)
                            quantityContext = quantityContext["contextList"];

                          var quantityObject = null;
                          if (quantityContext) {
                            quantityContext.map(function (quantity, index) {
                              if (
                                quantity["pricingContextId"] ===
                                quantityContextId
                              ) {
                                quantityObject = quantity;
                              }
                            });
                          }

                          if (quantityObject)
                            lineItemList = quantityObject
                              ? quantityObject.lineItemList
                              : false;

                          return (
                            <td key={"ninth-row-part-" + index}>
                              <div className="cell-top">
                                <ul>
                                  {lineItemList
                                    ? lineItemList.map(function (
                                        lineItem,
                                        index
                                      ) {
                                        return (
                                          <li
                                            key={
                                              part.get("frontTire").get("id") +
                                              "-line-item-row-" +
                                              index
                                            }
                                          >
                                            <span className="left">
                                              {lineItem["qty"] > 1
                                                ? lineItem["qty"] +
                                                  " - " +
                                                  lineItem["title"][
                                                    getAppLanguage()
                                                  ]
                                                : lineItem["title"][
                                                    getAppLanguage()
                                                  ]}
                                            </span>
                                            <span className="right">
                                              {parseFloat(lineItem["price"]) > 0
                                                ? money_format(
                                                    lineItem["price"]
                                                  )
                                                : noCost}
                                            </span>
                                          </li>
                                        );
                                      })
                                    : false}
                                </ul>
                              </div>
                            </td>
                          );
                        } else {
                          return <td></td>;
                        }
                      })
                    : false}
                </tr>

                <tr className="tenth-row even">
                  <th className="first-column">
                    {this.t("comparison.subtotal") + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        if (!_this.hidePricing(part)) {
                          var quantityValue = part
                            .get("frontTire")
                            .get("quantity")
                            .get("value");
                          var quantityContextId = part
                            .get("frontTire")
                            .get("quantity")
                            .get("pricingContextId");

                          var subtotal = _this.t("notApplicable");
                          var quantityContext =
                            _this.state.comparisonQuantityList[index];
                          if (quantityContext)
                            quantityContext = quantityContext[quantityValue];
                          if (quantityContext)
                            quantityContext = quantityContext["contextList"];

                          var quantityObject = null;
                          if (quantityContext) {
                            quantityContext.map(function (quantity, index) {
                              if (
                                quantity["pricingContextId"] ===
                                quantityContextId
                              ) {
                                quantityObject = quantity;
                              }
                            });
                          }

                          if (quantityObject)
                            subtotal = quantityObject
                              ? money_format(quantityObject.subTotal)
                              : _this.t("notApplicable");

                          return (
                            <td key={"tenth-row-part-" + index}>
                              <div className="cell-top">
                                <span className="pricing-detail">
                                  {subtotal}
                                </span>
                              </div>
                            </td>
                          );
                        } else {
                          return <td></td>;
                        }
                      })
                    : false}
                </tr>

                <tr className="eleventh-row">
                  <th className="first-column">
                    {this.t("comparison.tax") + dealerTax + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        if (!_this.hidePricing(part)) {
                          var quantityValue = part
                            .get("frontTire")
                            .get("quantity")
                            .get("value");
                          var quantityContextId = part
                            .get("frontTire")
                            .get("quantity")
                            .get("pricingContextId");

                          var taxTotal = _this.t("notApplicable");
                          var quantityContext =
                            _this.state.comparisonQuantityList[index];
                          if (quantityContext)
                            quantityContext = quantityContext[quantityValue];
                          if (quantityContext)
                            quantityContext = quantityContext["contextList"];

                          var quantityObject = null;
                          if (quantityContext) {
                            quantityContext.map(function (quantity, index) {
                              if (
                                quantity["pricingContextId"] ===
                                quantityContextId
                              ) {
                                quantityObject = quantity;
                              }
                            });
                          }

                          if (quantityObject)
                            taxTotal = quantityObject
                              ? money_format(quantityObject.taxTotal)
                              : _this.t("notApplicable");

                          return (
                            <td key={"eleventh-row-part-" + index}>
                              <div className="cell-top">
                                <span className="pricing-detail">
                                  {taxTotal}
                                </span>
                              </div>
                            </td>
                          );
                        } else {
                          return <td></td>;
                        }
                      })
                    : false}
                </tr>

                <tr className="twelfth-row even">
                  <th className="first-column">
                    {this.t("comparison.total") + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        if (!_this.hidePricing(part)) {
                          var quantityValue = part
                            .get("frontTire")
                            .get("quantity")
                            .get("value");
                          var quantityContextId = part
                            .get("frontTire")
                            .get("quantity")
                            .get("pricingContextId");

                          var grandTotal = _this.t("notApplicable");
                          var quantityContext =
                            _this.state.comparisonQuantityList[index];
                          if (quantityContext)
                            quantityContext = quantityContext[quantityValue];
                          if (quantityContext)
                            quantityContext = quantityContext["contextList"];

                          var quantityObject = null;
                          if (quantityContext) {
                            quantityContext.map(function (quantity, index) {
                              if (
                                quantity["pricingContextId"] ===
                                quantityContextId
                              ) {
                                quantityObject = quantity;
                              }
                            });
                          }

                          if (quantityObject)
                            grandTotal = quantityObject
                              ? money_format(quantityObject.grandTotal)
                              : _this.t("notApplicable");

                          return (
                            <td key={"twelfth-row-part-" + index}>
                              <div className="cell-top">
                                <span className="pricing-detail">
                                  {grandTotal}
                                </span>
                              </div>
                            </td>
                          );
                        } else {
                          return <td></td>;
                        }
                      })
                    : false}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {!this.state.printHasRun ? <div className="mask-full"></div> : false}
        {!this.state.printHasRun ? (
          <div className="loading-mask">
            <div className="please-wait">
              <span>{this.t("common.pleaseWait")}</span>
            </div>
          </div>
        ) : (
          false
        )}
      </div>
    );
  }

  hidePricing = (part) => {
    return (
      part.get("frontTire").get("pricing").get("marketMinimum") &&
      !isUserLoggedIn(true) &&
      applicationStore.data.dealerEnableCustomerLogin
    );
  };

  onChange = () => {
    this.setState({
      comparisonData: productListStore.data.comparisonPartData,
      comparisonList: productListStore.data.comparisonPartList,
      comparisonQuantityList: productListStore.data.comparisonQuantityList,
      comparisonErrorModal: productListStore.data.failedAddToComparison,
      comparisonErrorType: productListStore.data.comparisonErrorType,
      comparisonLoadingError: productListStore.data.comparisonLoadingError,

      compareProductdata: applicationStore.data.compareProductdata,
    });
  };
}

import { Modal } from "../ux/Modal.jsx";
import { Image } from "../ux/Image.jsx";

import { applicationStore } from "../../stores/ApplicationStore.js";
import { applicationActions } from "../../actions/ApplicationActions.js";

import { shoppingCartViewStore } from "../../stores/ShoppingCartViewStore.js";
import { shoppingCartStore } from "../../stores/ShoppingCartStore.js";
import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";

import { ComboBox } from "../ux/forms/ComboBox.jsx";
import { ComboBoxValue } from "../ux/forms/ComboBoxValue.jsx";
import { RouterStates } from "../../constants/RouterStates.js";

import { EmailField } from "../ux/forms/EmailField.jsx";
import { TextField } from "../ux/forms/TextField.jsx";
import { TelField } from "../ux/forms/TelField.jsx";

import {
  isEmpty,
  getAppLanguage,
  removeFrenchCharacters,
  getTrackingEventCategory,
} from "../../service/UtilityService";
import {
  convertDateToFrench,
  isDateAvailable,
  getTimeOptions,
} from "../../service/CalendarService";

import { Calendar } from "../calendar/Calendar.jsx";
import { isUserLoggedIn } from "../../api/AuthService";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  runAdobeServiceFormStart,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";
import { requestModalToggle } from "../../redux/features/shoppingCart.js";
import { connect } from "react-redux";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

export const CONTACT_EMAIL = "email";
export const CONTACT_PHONE = "phone";

var React = require("react");
var Cx = require("classnames");

class WishlistAppointmentModal extends I18nComponent {
  constructor(props) {
    super(props);
    var customerNameFlag = !isEmpty(
      shoppingCartViewStore.data.get("customerName")
    );
    var customerEmailFlag = !isEmpty(
      shoppingCartViewStore.data.get("customerEmail")
    );
    var customerPhoneFlag = !isEmpty(
      shoppingCartViewStore.data.get("customerPhone")
    );
    var customerNoteFlag = !isEmpty(
      shoppingCartViewStore.data.get("customerNote")
    );

    var firstName = "",
      lastName = "";
    if (customerNameFlag) {
      if (shoppingCartViewStore.data.get("customerName").indexOf(" ") > -1) {
        var temp = shoppingCartViewStore.data.get("customerName").split(" ");
        firstName = temp[0];
        lastName = temp[1];
      } else {
        firstName = shoppingCartViewStore.data.get("customerName");
      }
    }

    this.state = {
      dealerList: applicationStore.data.provincialDealerDealersList,
      selectedProvince: applicationStore.data.dealerInfo.province,
      selectedDealer:
        applicationStore.data.useProvincialDealers &&
        applicationStore.data.dealerIsRegionalOnly
          ? applicationStore.data.provincialDealerDealersListSelectedDealer
          : applicationStore.data.dealer,
      searchTerm: applicationStore.data.dealerModalSearchTerm,
      appointmentHasBeenSent: shoppingCartStore.data.get(
        "scheduleAppointmentWishlistModalAppointmentSent"
      ),
      appointmentHasFailed: shoppingCartStore.data.get(
        "scheduleAppointmentWishlistModalAppointmentFailed"
      ),

      firstNameTouched: !isEmpty(this.props.customerFirstName)
        ? true
        : customerNameFlag
        ? true
        : false,
      firstNameValid: !isEmpty(this.props.customerFirstName)
        ? true
        : customerNameFlag
        ? true
        : false,
      firstNameValue: !isEmpty(this.props.customerFirstName)
        ? this.props.customerFirstName
        : firstName,

      lastNameTouched: !isEmpty(this.props.customerLastName)
        ? true
        : customerNameFlag
        ? true
        : false,
      lastNameValid: !isEmpty(this.props.customerLastName)
        ? true
        : customerNameFlag
        ? true
        : false,
      lastNameValue: !isEmpty(this.props.customerLastName)
        ? this.props.customerLastName
        : lastName,

      emailTouched: !isEmpty(this.props.email)
        ? true
        : customerEmailFlag
        ? true
        : false,
      emailValid: !isEmpty(this.props.email)
        ? true
        : customerEmailFlag
        ? true
        : false,
      emailValue: !isEmpty(this.props.email)
        ? this.props.email
        : customerEmailFlag
        ? shoppingCartViewStore.data.get("customerEmail")
        : "",

      phoneValid: !isEmpty(this.props.phone)
        ? true
        : customerPhoneFlag
        ? true
        : false,
      phoneTouched: !isEmpty(this.props.phone)
        ? true
        : customerPhoneFlag
        ? true
        : false,
      phoneValue: !isEmpty(this.props.phone)
        ? this.props.phone
        : customerPhoneFlag
        ? shoppingCartViewStore.data.get("customerPhone")
        : "",

      noteTouched: customerNoteFlag ? true : false,
      noteValid: customerNoteFlag ? true : false,
      noteValue: customerNoteFlag
        ? true
        : customerNoteFlag
        ? shoppingCartViewStore.data.get("customerNotes")
        : "",

      userNoteValue: false,
      timeSelected: false,
      showTimeSelectionError: false,

      contactType: CONTACT_EMAIL,
      showCalendar: false,
      hideDealerSelection: false,

      windowWidth: window.innerWidth,

      date: shoppingCartViewStore.data.get("date"),
      time: shoppingCartViewStore.data.get("time"),
      timeOptions: getTimeOptions(shoppingCartViewStore.data.get("date")),
    };
  }

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview(
      "Shopping Cart Review Appointment Modal"
    );
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Shopping Cart Review Appointment Modal Access"
    );
    applicationStore.addChangeListener(this.onChange);
    shoppingCartStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
    shoppingCartStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    if (
      applicationStore.data.dealerIsRegionalOnly &&
      applicationStore.data.useProvincialDealers
    ) {
      setTimeout(() => {
        applicationActions.loadProvincialDealerListOfDealers();
      }, 100);
    }
    runAdobeServiceFormStart();
  }

  shouldComponentUpdate(lastProps, nextState) {
    return this.props !== lastProps || this.state !== nextState;
  }

  render() {
    var lang = getAppLanguage();
    var requiredWarning = false;
    if (this.state.time === null && this.state.showTimeSelectionError) {
      requiredWarning = (
        <p className="note required">
          <span>{this.t("quote.requiredFields")}</span>
        </p>
      );
    }

    var bodyClassName = "dealer-selection",
      useShortHeaderBar = false;
    var dealerLat = 0;
    var dealerLng = 0;
    var googleMapsLink = "#";
    if (this.state.selectedDealer == null) {
      bodyClassName = "dealer-selection full";
      useShortHeaderBar = true;
    } else {
      if (this.state.selectedDealer.get("googleMapsUrl")) {
        googleMapsLink = this.state.selectedDealer.get("googleMapsUrl");
      } else {
        dealerLat = this.state.selectedDealer.get("latitude");
        dealerLng = this.state.selectedDealer.get("longitude");
        googleMapsLink =
          "https://www.google.ca/maps?q=loc:" + dealerLat + "," + dealerLng;
      }
    }

    var modalClassName = Cx({
      "wishlist-dealer-selection": true,
      "image-not-present": !applicationStore.data.enableMercedesFeatures,
    });

    var infoSectionClassName = Cx({
      "info-selection": true,
      "has-dealer-selection":
        applicationStore.data.enableMercedesFeatures &&
        !applicationStore.data.dealerSite,
      "image-not-present": !applicationStore.data.enableMercedesFeatures,
    });

    var dealerListClassName = "dealer-list";
    if (isUserLoggedIn()) {
      modalClassName = "wishlist-dealer-selection stretch";
      dealerListClassName = "dealer-list stretch";
      if (applicationStore.data.enableMercedesFeatures) {
        modalClassName = "wishlist-dealer-selection stretch image-present";
      }
    }

    var hideDealerSelection = null;
    if (this.state.windowWidth < 1024 && this.state.selectedDealer != null) {
      hideDealerSelection = true;
    } else {
      hideDealerSelection = false;
    }

    var formClassName = "dealer-info";
    if (
      !(
        applicationStore.data.useProvincialDealers &&
        applicationStore.data.dealerIsRegionalOnly
      ) ||
      hideDealerSelection
    )
      formClassName = "dealer-info full";

    var today = new Date();
    var tomorrow = new Date(today.getTime() + 1000 * 60 * 60 * 24);
    var date = false,
      dateString = false;

    if (this.state.date) {
      date = new Date(this.state.date);
      var dateString = date.toDateString();
      if (getAppLanguage() == "fr") {
        dateString = convertDateToFrench(dateString);
      }
    } else {
      dateString = this.t("appointmentForm.selectDate");
    }

    var selectTimeIsNotValid =
      this.state.time === null && this.state.showTimeSelectionError;
    var selectedDealer = this.state.selectedDealer;
    var onSelectedDealer = this.onSelectedDealer;
    var _this = this;
    return (
      <Modal
        requestUnmount={this.onRequestCloseModal}
        refactor={!applicationStore.data.useProvincialDealers || true}
        useShortHeaderBar={useShortHeaderBar}
        dealerSelectionModal={true}
        className="appointment-modal"
        datadtm={setAdobeLaunchDTM(
          "",
          ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.BASE
        )}
      >
        <div className="wrapper no-transition">
          <div className={modalClassName}>
            {hideDealerSelection ? (
              <div className="expand-dealer-selection-list">
                <p onClick={this.expandDealerSelection}>
                  {this.t("wishlist.modal.back")}
                </p>
              </div>
            ) : (
              false
            )}
            {!hideDealerSelection &&
            applicationStore.data.useProvincialDealers &&
            applicationStore.data.dealerIsRegionalOnly ? (
              <div className={bodyClassName}>
                <div className="title">
                  <h1>{this.t("wishlist.modal.findADealer")}</h1>
                </div>
                <div className="search-box">
                  <form onSubmit={this.onSubmitSearch}>
                    <input
                      className="search-input"
                      id="search-input"
                      type="text"
                      placeholder={this.t("wishlist.modal.placeholder")}
                    />
                    <button className="btn" type="submit">
                      {this.t("wishlist.modal.search")}
                    </button>
                  </form>
                </div>
                <div className="search-box search-reset">
                  <button
                    className="btn"
                    type="submit"
                    onClick={this.onClearSearch}
                  >
                    {this.t("wishlist.modal.showAllLocations")}
                  </button>
                </div>
                <div className={dealerListClassName}>
                  <hr className="hr-top" />
                  <hr className="hr-bottom first" />
                  <ul>
                    {this.filterDealerList().map(function (dealer, index) {
                      var className = "";
                      if (
                        selectedDealer !== null &&
                        dealer.get("id") == selectedDealer.get("id")
                      )
                        className = "selected";
                      return (
                        <li
                          key={"dealer-select-" + index}
                          onClick={onSelectedDealer.bind(_this, dealer)}
                        >
                          <Image
                            className="dealer-list-image"
                            src="/static/img/mercedes/button_left.png"
                            alt={"Button Select " + dealer.get("name")}
                          />
                          <span className={className}>
                            {dealer.get("name")}
                          </span>
                          <hr className="hr-top" />
                          <hr className="hr-bottom" />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            ) : (
              false
            )}
            <div className={infoSectionClassName}>
              {this.state.selectedDealer != null ? (
                <div className={formClassName}>
                  <div className="image">
                    {applicationStore.data.enableMercedesFeatures ? (
                      <Image
                        className="appointment-modal-image"
                        src={
                          "/static/img/" +
                          applicationStore.data.defaultTemplate +
                          "/dealer_image.png"
                        }
                        alt={
                          "Image Dealership " +
                          this.state.selectedDealer.get("name")
                        }
                      />
                    ) : (
                      false
                    )}
                  </div>
                  <div className="title">
                    <h1>{this.state.selectedDealer.get("name")}</h1>
                  </div>
                  <div className="address">
                    {this.state.selectedDealer.get("address") ? (
                      <span>
                        {this.state.selectedDealer.get("address") + ","}
                      </span>
                    ) : (
                      false
                    )}
                    {this.state.selectedDealer.get("city") ? (
                      <span>{this.state.selectedDealer.get("city") + ","}</span>
                    ) : (
                      false
                    )}
                    {this.state.selectedDealer.get("province") ? (
                      <span>{this.state.selectedDealer.get("province")}</span>
                    ) : (
                      false
                    )}
                    {this.state.selectedDealer.get("postalCode") ? (
                      <span>{this.state.selectedDealer.get("postalCode")}</span>
                    ) : (
                      false
                    )}
                  </div>
                  <div className="view-on-map">
                    <a
                      href={googleMapsLink}
                      target="_blank"
                      className={setAdobeLaunchClass(
                        "",
                        ADOBELAUNCHER_CLASS.TEXT
                      )}
                      data-dtm={setAdobeLaunchDTM(
                        "",
                        ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.BASE
                      )}
                    >
                      {"> " + this.t("wishlist.modal.viewOnMap")}
                    </a>
                  </div>
                  {!this.state.appointmentHasBeenSent ? (
                    <ul className="appointment-dealer-info dealer-info-half">
                      <li>
                        {this.t("appointmentForm.speakToPartsSpecialist")}
                      </li>
                      {this.state.selectedDealer.get("phone") ? (
                        <li className="info-li">
                          <Image
                            className="appointment-dealer-icon"
                            src="/static/img/phone.png"
                            alt="Icon Phone"
                          />
                          <span>{this.state.selectedDealer.get("phone")}</span>
                        </li>
                      ) : (
                        false
                      )}
                      {this.state.selectedDealer.get("email") ? (
                        <li className="info-li">
                          <Image
                            className="appointment-dealer-icon"
                            src="/static/img/email.png"
                            alt="Icon Email"
                          />
                          <span>{this.state.selectedDealer.get("email")}</span>
                        </li>
                      ) : (
                        false
                      )}
                      {this.state.selectedDealer.get("website") ? (
                        <li className="info-li">
                          <Image
                            className="appointment-dealer-icon"
                            src="/static/img/website.png"
                            alt="Icon Website"
                          />
                          <span>
                            {this.state.selectedDealer.get("website")}
                          </span>
                        </li>
                      ) : (
                        false
                      )}
                      {this.state.selectedDealer.get("phone") ? (
                        <li className="request-by-phone">
                          {this.t("appointmentForm.requestAppointmentByPhone")}
                        </li>
                      ) : (
                        false
                      )}
                      {this.state.selectedDealer.get("phone") ? (
                        <li className="info-li">
                          <Image
                            className="appointment-dealer-icon"
                            src="/static/img/phone.png"
                            alt="Icon Phone"
                          />
                          <span>{this.state.selectedDealer.get("phone")}</span>
                        </li>
                      ) : (
                        false
                      )}
                    </ul>
                  ) : (
                    false
                  )}
                  {!this.state.appointmentHasBeenSent ? (
                    <ul className="appointment-dealer-info dealer-info-half social-media-icons">
                      {applicationStore.data.dealerMediaUrlFacebook ? (
                        <a
                          href={applicationStore.data.dealerMediaUrlFacebook}
                          target="_blank"
                        >
                          <Image
                            src="/static/img/facebook.png"
                            alt="Icon Facebook"
                          />
                        </a>
                      ) : (
                        false
                      )}
                      {applicationStore.data.dealerMediaUrlTwitter ? (
                        <a
                          href={applicationStore.data.dealerMediaUrlTwitter}
                          target="_blank"
                        >
                          <Image
                            src="/static/img/twitter.png"
                            alt="Icon Twitter"
                          />
                        </a>
                      ) : (
                        false
                      )}
                      {applicationStore.data.dealerMediaUrlInstagram ? (
                        <a
                          href={applicationStore.data.dealerMediaUrlInstagram}
                          target="_blank"
                        >
                          <Image
                            src="/static/img/instagram.png"
                            alt="Icon Instagram"
                          />
                        </a>
                      ) : (
                        false
                      )}
                    </ul>
                  ) : (
                    false
                  )}
                  {!this.state.appointmentHasBeenSent ? (
                    <ul className="appointment-dealer-info">
                      <li className="to-request-appointment">
                        <span className="bold">
                          {this.t(
                            "appointmentForm.requestAppointmentByEmailPart1"
                          )}
                        </span>
                        <span>
                          {this.t(
                            "appointmentForm.requestAppointmentByEmailPart2"
                          )}
                        </span>
                      </li>
                    </ul>
                  ) : (
                    false
                  )}
                  {!this.state.appointmentHasBeenSent ? (
                    <div className="quote-controls no-print">
                      <form onSubmit={this.onFormSubmit}>
                        <div className="inputs">
                          <fieldset className="contact-information">
                            <div className="contact-names-field">
                              <TextField
                                onChange={this.onFirstNameChange}
                                touched={this.state.firstNameTouched}
                                label={this.t("wishlist.form.firstName") + "*"}
                                id="firstNameQuoteField"
                                className={setAdobeLaunchClass(
                                  "",
                                  ADOBELAUNCHER_CLASS.INPUT
                                )}
                                datadtm={setAdobeLaunchDTM(
                                  "",
                                  ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.BASE
                                )}
                                required={true}
                                value={this.state.firstNameValue}
                                errorNotEmpty={this.t(
                                  "validation.errorNotEmpty"
                                )}
                              />
                              <TextField
                                onChange={this.onLastNameChange}
                                touched={this.state.lastNameTouched}
                                label={this.t("wishlist.form.lastName") + "*"}
                                id="lastNameQuoteField"
                                className={setAdobeLaunchClass(
                                  "",
                                  ADOBELAUNCHER_CLASS.INPUT
                                )}
                                datadtm={setAdobeLaunchDTM(
                                  "",
                                  ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.BASE
                                )}
                                value={this.state.lastNameValue}
                              />
                            </div>
                            <div className="contact-names-field">
                              <EmailField
                                onChange={this.onEmailChange}
                                touched={this.state.emailTouched}
                                value={this.state.emailValue}
                                label={this.t("quote.emailFieldLabel") + "*"}
                                id="userEmailField"
                                className={setAdobeLaunchClass(
                                  "",
                                  ADOBELAUNCHER_CLASS.INPUT
                                )}
                                datadtm={setAdobeLaunchDTM(
                                  "",
                                  ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.BASE
                                )}
                                required={true}
                                errorInvalidEmail={this.t("validation.email")}
                              />
                              <TelField
                                onChange={this.onChangePhone}
                                touched={this.state.phoneTouched}
                                value={this.state.phoneValue}
                                label={this.t("appointmentForm.phone")}
                                id="telQuoteField"
                                className={setAdobeLaunchClass(
                                  "",
                                  ADOBELAUNCHER_CLASS.INPUT
                                )}
                                datadtm={setAdobeLaunchDTM(
                                  "",
                                  ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.BASE
                                )}
                                required={false}
                              />
                            </div>
                            <div className="contact-method-selection">
                              <label>
                                {this.t(
                                  "appointmentForm.preferredContactMethod"
                                ) + "*"}
                              </label>
                              <ComboBox
                                className={setAdobeLaunchClass(
                                  "",
                                  ADOBELAUNCHER_CLASS.DROPDOWN
                                )}
                                datadtm={setAdobeLaunchDTM(
                                  "",
                                  ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.BASE
                                )}
                                onChange={this.onChangeContactType}
                                value={this.state.contactType}
                                emptyText={this.t("common.select")}
                              >
                                <ComboBoxValue
                                  key={CONTACT_EMAIL}
                                  value={CONTACT_EMAIL}
                                >
                                  {this.t("appointmentForm.email")}
                                </ComboBoxValue>
                                <ComboBoxValue
                                  key={CONTACT_PHONE}
                                  value={CONTACT_PHONE}
                                >
                                  {this.t("appointmentForm.phone")}
                                </ComboBoxValue>
                              </ComboBox>
                            </div>
                            <div className="contact-select-field">
                              <div className="form-input btn-open-calendar">
                                <label>
                                  {this.t(
                                    "appointmentForm.preferredAppointmentDate"
                                  ) + "*"}
                                </label>
                                <a
                                  className={setAdobeLaunchClass(
                                    selectTimeIsNotValid
                                      ? "btn invalid"
                                      : "btn",
                                    ADOBELAUNCHER_CLASS.BUTTON.LINK
                                  )}
                                  data-dtm={setAdobeLaunchDTM(
                                    "",
                                    ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.BASE
                                  )}
                                  onClick={this.openAppointmentCalendar}
                                >
                                  <span>{dateString}</span>
                                  <i className="fa fa-calendar"></i>
                                </a>
                              </div>
                              <div className="contact-method-select">
                                <label>
                                  {this.t(
                                    "appointmentForm.preferredContactTime"
                                  ) + "*"}
                                </label>
                                <ComboBox
                                  className={setAdobeLaunchClass(
                                    selectTimeIsNotValid ? "invalid" : "",
                                    ADOBELAUNCHER_CLASS.DROPDOWN
                                  )}
                                  datadtm={setAdobeLaunchDTM(
                                    "",
                                    ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.BASE
                                  )}
                                  onChange={this.onTimeChanged}
                                  value={this.state.time}
                                  emptyText={this.t("common.select")}
                                >
                                  {this.state.timeOptions
                                    .map(function (key, value) {
                                      return (
                                        <ComboBoxValue
                                          key={value}
                                          value={value}
                                        >
                                          {key}
                                        </ComboBoxValue>
                                      );
                                    })
                                    .toArray()}
                                </ComboBox>
                              </div>
                            </div>
                            {isUserLoggedIn() ? (
                              <div className="quote-user-notes">
                                <div className="quote-user-notes-label">
                                  <p>{this.t("quote.noteFieldLabel")}</p>
                                </div>
                                <div className="quote-user-notes-textarea">
                                  <textarea
                                    rows="4"
                                    cols="35"
                                    onChange={this.onNoteChange}
                                    id="userNoteQuoteField"
                                  />
                                </div>
                              </div>
                            ) : (
                              false
                            )}
                          </fieldset>
                          {this.state.showCalendar ? (
                            <Modal
                              applyTemplateStyle={true}
                              useShortHeaderBar={true}
                              slideHeaderDown={true}
                              requestUnmount={this.closeAppointmentCalendar}
                              datadtm={setAdobeLaunchDTM(
                                "",
                                ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.DATE
                              )}
                            >
                              <div className="wrapper calendar-modal">
                                <fieldset className="appointment-reservation">
                                  <div className="formInput">
                                    <Calendar
                                      onSelect={this.onDateChanged}
                                      selected={this.state.date}
                                      view={tomorrow}
                                      isDateAvailable={isDateAvailable}
                                    />
                                  </div>
                                  <button
                                    onClick={this.closeAppointmentCalendar}
                                    className={setAdobeLaunchClass(
                                      "btn calendar-select",
                                      ADOBELAUNCHER_CLASS.BUTTON.LINK
                                    )}
                                    data-dtm={setAdobeLaunchDTM(
                                      "",
                                      ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.DATE
                                    )}
                                  >
                                    {this.t("common.close")}
                                  </button>
                                </fieldset>
                              </div>
                            </Modal>
                          ) : (
                            false
                          )}
                          {requiredWarning}
                        </div>
                        <button
                          onClick={this.onFormSubmit}
                          className={setAdobeLaunchClass(
                            "btn request-appointment",
                            ADOBELAUNCHER_CLASS.BUTTON.LINK
                          )}
                          data-dtm={setAdobeLaunchDTM(
                            "",
                            ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.DATE
                          )}
                        >
                          {this.t("quote.requestAppointment")}
                        </button>
                      </form>
                    </div>
                  ) : this.state.appointmentHasFailed ? (
                    this.renderAppointmentFailed()
                  ) : (
                    this.renderAppointmentSent()
                  )}
                </div>
              ) : (
                false
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  minimizeDealerSelection = () => {
    this.setState({
      selectedDealer: null,
      hideDealerSelection: true,
    });
  };

  expandDealerSelection = () => {
    this.setState({
      selectedDealer: null,
      hideDealerSelection: false,
    });
  };

  openAppointmentCalendar = () => {
    this.setState({
      showCalendar: true,
    });
  };

  closeAppointmentCalendar = () => {
    this.setState({
      showCalendar: false,
    });
  };

  renderAppointmentSent = () => {
    var name = "";
    if (this.state.firstNameValue) name = this.state.firstNameValue;
    if (this.state.lastNameValue) name += " " + this.state.lastNameValue;

    var email = this.state.emailValue;
    var phone = this.state.phoneValue;

    var date = new Date(this.state.date);
    var dateString = date.toDateString();
    if (getAppLanguage() == "fr") {
      dateString = convertDateToFrench(dateString);
    }
    var contactType =
      this.state.contactType == "email"
        ? this.t("wishlist.modal.contactByEmail")
        : this.t("wishlist.modal.contactByPhone");
    var time =
      this.state.time == "AM"
        ? this.t("wishlist.modal.AM")
        : this.t("wishlist.modal.PM");

    return (
      <div className="appointment-sent-info">
        <div className="appointment-contact-info">
          <h3>{this.t("wishlist.modal.contactInformation")}</h3>
          <ul>
            <li>
              <span>{name}</span>
            </li>
            <li>
              s<span>{email}</span>
            </li>
            {phone ? (
              <li>
                <span>{phone}</span>
              </li>
            ) : (
              false
            )}
          </ul>
        </div>
        <div className="appointment-contact-details">
          <h3>{this.t("wishlist.modal.appointmentDetails")}</h3>
          <ul>
            <li>
              <span>{dateString}</span>
            </li>
            <li>
              <span>{time}</span>
            </li>
            <li>
              <span>{contactType}</span>
            </li>
          </ul>
        </div>
        <div className="return-message">
          <ul>
            <li>{this.t("wishlist.modal.appointmentDisclaimer")}</li>
          </ul>
        </div>
        <div className="start-over-button">
          <ul>
            <li>
              <button
                className={setAdobeLaunchClass(
                  "btn",
                  ADOBELAUNCHER_CLASS.BUTTON.LINK
                )}
                data-dtm={setAdobeLaunchDTM(
                  "",
                  ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.BASE
                )}
                onClick={this.returnToHomePage}
              >
                {this.t("appointmentForm.startOver")}
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  returnToHomePage = () => {
    applicationActions.changeRouterStateDirectly(RouterStates.HOMEPAGE);
  };

  renderAppointmentFailed = () => {
    return (
      <div className="appointment-sent-info">
        <h1 className="failure">
          {this.t("wishlist.modal.appointmentFailed")}
        </h1>
      </div>
    );
  };

  filterDealerList = () => {
    if (this.state.searchTerm != null) {
      var searchTerm = removeFrenchCharacters(
        this.state.searchTerm.toLowerCase()
      );
      return this.state.dealerList.filter(function (dealer) {
        var dealerName = removeFrenchCharacters(dealer.get("name"));
        var dealerCity = removeFrenchCharacters(dealer.get("city"));
        return (
          dealerName.toLowerCase().indexOf(searchTerm) != -1 ||
          dealerCity.toLowerCase().indexOf(searchTerm) != -1
        );
      });
    } else {
      return this.state.dealerList;
    }
  };

  onFirstNameChange = (value, valid) => {
    this.setState({
      firstNameValue: value,
      firstNameValid: valid,
      firstNameTouched: true,
    });
  };

  onLastNameChange = (value, valid) => {
    this.setState({
      lastNameValue: value,
      lastNameValid: valid,
      lastNameTouched: true,
    });
  };

  onEmailChange = (value, valid) => {
    this.setState({
      emailValue: value,
      emailValid: valid,
      emailTouched: true,
    });
  };

  onChangePhone = (value, valid) => {
    this.setState({
      phoneValue: value,
      phoneValid: valid,
      phoneTouched: true,
    });
  };

  onChangeContactType = (value) => {
    this.setState({
      contactType: value,
    });
  };

  onDateChanged = (date) => {
    var dateObject = new Date(date);
    var currentDateObject = new Date();
    if (isDateAvailable(dateObject) && dateObject > currentDateObject) {
      this.state.date = dateObject;
      this.state.timeOptions = getTimeOptions(dateObject);
    }
  };

  onNoteChange = () => {
    var value = document.getElementById("userNoteQuoteField")
      ? document.getElementById("userNoteQuoteField").value
      : "";
    this.setState({
      noteValue: value,
      noteValid: true,
      noteTouched: true,
    });
  };

  onTimeChanged = (time) => {
    this.state.timeSelected = true;
    this.state.time = time;
  };

  onFormSubmit = (ev) => {
    this.setState({
      emailTouched: true,
      firstNameTouched: true,
      phoneTouched: true,
    });

    var valueIsValid = false,
      touchIsValid = false;
    var formInputs = document.getElementsByClassName("formInput");
    if (formInputs[0]) {
      valueIsValid = formInputs[0]["classList"].contains("valid");
    }
    if (valueIsValid) {
      var newClassName = formInputs[0]["classList"]
        .toString()
        .replace("untouched", "touched");
      if (document.getElementById("firstNameQuoteField").value.length === 0) {
        newClassName = newClassName.replace("valid", "invalid");
        valueIsValid = false;
      }
      formInputs[0].setAttribute("class", newClassName);
    }

    var name = "";
    if (this.state.firstNameValue) name = this.state.firstNameValue;
    if (this.state.lastNameValue) name += " " + this.state.lastNameValue;

    if (this.appointmentCanBeSubmitted() && valueIsValid) {
      googleAnalyticsEnhancedTrackEvent(
        getTrackingEventCategory(),
        "Shopping Cart Review Appointment Modal - RequestAppointment"
      );
      if (
        applicationStore.data.useProvincialDealers &&
        applicationStore.data.dealerIsRegionalOnly
      ) {
        shoppingCartActions.transferQuoteToAppointment(
          this.state.selectedDealer.get("id"),
          name,
          this.state.emailValue,
          this.state.phoneValue,
          this.state.date,
          this.state.time,
          this.state.noteValue,
          this.state.contactType
        );
      } else {
        shoppingCartActions.requestAppointmentFromWishlist(
          this.state.firstNameValue,
          this.state.lastNameValue,
          this.state.emailValue,
          this.state.phoneValue,
          this.state.date,
          this.state.time,
          this.state.noteValue,
          this.state.contactType
        );
      }
    }
    ev.preventDefault();
    ev.stopPropagation();
  };

  appointmentCanBeSubmitted = () => {
    this.state.showTimeSelectionError = true;
    return (
      this.state.emailTouched &&
      this.state.emailValid &&
      this.state.firstNameTouched &&
      this.state.firstNameValid &&
      this.state.timeSelected
    );
  };

  onRequestCloseModal = () => {
    this.props.toggleAppointmentModal(!this.props.showScheduleAppointmentModal);
    // shoppingCartActions.closeAppointmentWishlistModal();
  };

  onSubmitSearch = (ev) => {
    var value = document.getElementById("search-input").value;
    if (value) applicationActions.wishlistDealerSelectModalSearch(value);
    else applicationActions.wishlistDealerSelectModalSearch(null);
    ev.preventDefault();
    ev.stopPropagation();
  };

  onClearSearch = () => {
    applicationActions.wishlistDealerSelectModalSearch(null);
  };

  onSelectedDealer = (dealer) => {
    if (!this.state.appointmentHasBeenSent) {
      applicationActions.provincialDealerListSelectDealer(dealer);
      setTimeout(() => {
        applicationActions.loadDealerAppointmentInfo(dealer.get("urlCode"));
      }, 10);
    }
  };

  onChange = () => {
    this.setState({
      dealerList: applicationStore.data.provincialDealerDealersList,
      selectedProvince: applicationStore.data.dealerInfo.province,
      selectedDealer:
        applicationStore.data.useProvincialDealers &&
        applicationStore.data.dealerIsRegionalOnly
          ? applicationStore.data.provincialDealerDealersListSelectedDealer
          : applicationStore.data.dealer,
      searchTerm: applicationStore.data.dealerModalSearchTerm,
      appointmentHasBeenSent: shoppingCartStore.data.get(
        "scheduleAppointmentWishlistModalAppointmentSent"
      ),
      appointmentHasFailed: shoppingCartStore.data.get(
        "scheduleAppointmentWishlistModalAppointmentFailed"
      ),
      timeOptions: getTimeOptions(this.state.date),
      windowWidth: window.innerWidth,
    });
  };
}

function mapStateToProps(state) {
  return {
    applicationStore: state.applicationStore,
    showScheduleAppointmentModal: state.shoppingCart.requestModalAppointment,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    toggleAppointmentModal: function (toggleValue) {
      dispatch(requestModalToggle(toggleValue));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WishlistAppointmentModal);

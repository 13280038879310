import { ProductTypes } from '../constants/ProductTypes.js';
import { applicationStore } from '../stores/ApplicationStore.js';
import { productListStore } from '../stores/ProductListStore.js';
import { productListActions } from '../actions/ProductListActions.js';
import { languageStore } from '../stores/LanguageStore.js';

import { Image } from '../components/ux/Image.jsx';

import { renderWheelPricing, renderAccessoryPricing, renderPackagePricing } from '../service/RenderService';
import { money_format, isEmpty, sizeOf, getAppLanguage } from '../service/UtilityService';
import { isUserLoggedIn } from "../api/AuthService";

var React = require('react');
var Immutable = require('immutable');
var _ = require('lodash');
var Cx = require('classnames');

export var collectVisualizerPartList = (partType, payload) => {
  var visualizerPartList = [];
  for (var i in payload) {
    if (!!payload[i] && !payload[i].partSummary.primaryPart.hideItem) {
      var rebates = payload[i].partPricing.primaryPart.price, rebatePromotions = [], displayPrice = null;
      for (var j in rebates) {
        if (rebates[j]) {
          var contextList = rebates[j].contextList;
          for (var k in contextList) {
            if (contextList[k]) {
              displayPrice = contextList[k].displayPrice;
              rebatePromotions.push({
                quantity: j,
                price: contextList[k].displayPrice,
                pricingContextId: contextList[k].pricingContextId,
                text: contextList[k].promotionText,
                hasText: contextList[k].promotionText.en !== ''
              });
            }
          }
        }
      }
      visualizerPartList.push({
        id: payload[i].partSummary.primaryPart.itemId,
        name: getPartName(payload[i], partType),
        image: getPartImage(payload[i], partType),
        partNumber: payload[i].partSummary.primaryPart.itemPartNumber,
        displayPrice: displayPrice,
        partType: partType,
        partInfo: {
          diameter: partType === ProductTypes.ALLOY_WHEELS ? payload[i].partDetails.primaryPart.wheelDiameter : null
        },
        filterInfo: {
          wheel: partType === ProductTypes.ALLOY_WHEELS ? collectWheelFilterData(payload[i]) : {},
          accessory: partType === ProductTypes.ACCESSORIES ? collectAccessoryFilterData(payload[i]) : {},
          pkg: partType === ProductTypes.PACKAGES ? collectPackageFilterData(payload[i]) : {}
        },
        rebatePromotions: rebatePromotions
      });
    }
  }

  return visualizerPartList;
};

export var getPartName = (part, partType) => {
  if (partType === ProductTypes.ALLOY_WHEELS) return part.partDetails.primaryPart.wheelName;
  else if (partType === ProductTypes.ACCESSORIES) return part.partDetails.primaryPart.accessoryName;
  else if (partType === ProductTypes.PACKAGES) {
    if (part.partDynamicName && part.partDynamicName.en !== '') return part.partDynamicName;
    return part.partDetails.primaryPart.packageName;
  }
};

export var getPartImage = (part, partType) => {
  if (partType === ProductTypes.ALLOY_WHEELS) return part.partDetails.primaryPart.wheelImageLink;
  else if (partType === ProductTypes.ACCESSORIES) return part.partDetails.primaryPart.accessoryImageLink;
  else if (partType === ProductTypes.PACKAGES) return part.partDetails.primaryPart.packageImageLink;
};

export var collectWheelFilterData = function (wheel) {
  if (wheel) {
    return {
      seasonalSizeCode: wheel.partDetails.primaryPart.wheelSeasonalSizeCode,
      pricingContextId: checkForPricingContext(wheel.partPricing.primaryPart.price),
      hasRearWheel: !isNaN(parseInt(wheel.partDetails.secondaryParts.wheelWidth)),
      frontWheel: {
        diameter: parseInt(wheel.partDetails.primaryPart.wheelDiameter),
        width: parseFloat(wheel.partDetails.primaryPart.wheelWidth)
      },
      rearWheel: {
        diameter: parseInt(wheel.partDetails.secondaryParts.wheelDiameter),
        width: parseFloat(wheel.partDetails.secondaryParts.wheelWidth)
      }
    };
  } else return {};
};

export var collectAccessoryFilterData = function (accessory) {
  if (accessory) {

  } else return {};
};

export var collectPackageFilterData = function (pkg) {
  if (pkg) {
    return {
      pricingContextId: checkForPricingContext(pkg.partPricing.primaryPart.price),
    };
  } else return {};
};

export var checkForPricingContext = function (pricing) {
  if (pricing) {
    var index = Object.keys(pricing)[0];
    var pricingContext = pricing[index].contextList.find(price => price.pricingContextId > 0);
    return typeof(pricingContext) !== 'undefined' ? pricingContext.pricingContextId : 0;
  } else return null;
};

export var renderPartVisualizerPartInfo = (selectedPart, partList) => {
  if (!!selectedPart && sizeOf(partList) > 0) {
    var dictionary = languageStore.getDictionary(), part = getFilteredPart(selectedPart, partList);
    if (part) {
      return (
        <div className="part-info">

          <h2>{part['name'][getAppLanguage()]}</h2>
          {part['partType'] === ProductTypes.ALLOY_WHEELS ? <span>{(2.54 * part['partInfo']['diameter']) + " " + dictionary.common.cm + " - " + part['partInfo']['diameter'] + '", ' + part['partNumber']}</span> : false}
          {part['partType'] === ProductTypes.PACKAGES ? <span>{part['partNumber']}</span> : false}

        </div>
      );
    } else return false;
  } else return false;
};

export var renderPartVisualizerVehicleColorSelection = (selectedPart, selectedColor, colorList, thumbnailSelectedIndex, onSelectPackageThumbnail) => {
  if (!!selectedPart && !!selectedColor && sizeOf(colorList) > 0) {
    var selectedColor = colorList.find(color => color.get('code') === selectedColor);
    var shadowImage = selectedColor.get('shadowImage');
    var foregroundImage = selectedColor.get('foregroundImage');

    var partImage = '';
    if (!!selectedPart['part']) if (typeof(selectedPart['part'].get('visualizerImage')) !== 'undefined') if (sizeOf(selectedPart['part'].get('visualizerImage')) > 0) partImage = selectedPart['part'].get('visualizerImage').get(0);

    if (applicationStore.data.productType === ProductTypes.ALLOY_WHEELS || (!isEmpty(shadowImage) && !isEmpty(foregroundImage) && !isEmpty(partImage))) {
      return (
        <div className="vehicle-color-selection">

          {colorList.map(function(color, index) {

            var setColourForVisualizer = function (color) { if (selectedColor !== color.get('code')) productListActions.changeColour(color.get('code')); }.bind(null, color);
            var className = selectedColor.get('code') === color.get('code') ? "vehicle-color-selection-container selected" : "vehicle-color-selection-container";

            return (
              <div key={"vehicle-selection-color-" + index} className={className} onClick={setColourForVisualizer}>
                <Image src={color.get('image')} alt="Image Vehicle Color"/>
              </div>
            );

          })}

          <span>{selectedColor.get('name').get(getAppLanguage())}</span>

        </div>
      );
    } else if (applicationStore.data.productType === ProductTypes.PACKAGES) {
      return renderPackageClassicVisualizerColorSelection(selectedPart, thumbnailSelectedIndex, onSelectPackageThumbnail)
    } else return false;
  } else if (applicationStore.data.productType === ProductTypes.PACKAGES) {
    return renderPackageClassicVisualizerColorSelection(selectedPart, thumbnailSelectedIndex, onSelectPackageThumbnail)
  } else return false;
};

export var renderPackageClassicVisualizerColorSelection = (selectedPart, thumbnailSelectedIndex, onSelectPackageThumbnail) => {
  var colorList = selectedPart['part'].get('secondaryImages');
  if (sizeOf(colorList) > 0) {
    return (
      <div className="vehicle-color-selection">

        {colorList.map(function(color, index) {

          var className = thumbnailSelectedIndex === index ? "vehicle-color-selection-container selected" : "vehicle-color-selection-container";

          return (
            <div key={"vehicle-selection-color-" + index} className={className} onClick={onSelectPackageThumbnail.bind(null, index)}>
              <Image src={color.get('thumbnailUrl')} alt="Image Vehicle Color"/>
            </div>
          );

        })}

      </div>
    );
  } else return false;
};

export var renderPartVisualizerPartActions = (selectedPart, selectedColor, onChangePartQuantity, onOpenDetailModal, onAddToCart) => {
  if (!!selectedPart) {
    var dictionary = languageStore.getDictionary();

    var pricing = false;
    if (isWheelVisualizer() && selectedPart.part.get('type') === ProductTypes.ALLOY_WHEELS) pricing = renderWheelPricing(selectedPart.part, onChangePartQuantity);
    else if (isAccessoryVisualizer() && selectedPart.part.get('type') === ProductTypes.ACCESSORIES) pricing = renderAccessoryPricing(selectedPart.part, onChangePartQuantity);
    else if (isPackageVisualizer() && selectedPart.part.get('type') === ProductTypes.PACKAGES) pricing = renderPackagePricing(selectedPart.part, onChangePartQuantity);

    var hasAddToCart = false;
    if ((!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) || isUserLoggedIn())) {
      if (applicationStore.data.productType === ProductTypes.ALLOY_WHEELS) {
        if (selectedPart['part'].get('frontWheel').get('hasPricing') && selectedPart['part'].get('frontWheel').get('pricing').get('displayPrice') > 0) {
          hasAddToCart = true;
        }
      }
      if (applicationStore.data.productType === ProductTypes.PACKAGES) {
        if (selectedPart['part'].get('hasPricing') && selectedPart['part'].get('pricing').get('displayPrice') > 0) {
          hasAddToCart = true;
        }
      }
    }

    return (
      <div className="part-actions">

        <button className="btn" onClick={onOpenDetailModal}>{dictionary.product.moreDetails}</button>
        {hasAddToCart ? <button className="btn" onClick={onAddToCart}>{dictionary.product.addToWishlist}</button> : false}

        {pricing}

      </div>
    );
  } else return false;
};

export var renderPartVisualizerFilterSection = (selectedSeason, seasonFilters, onSeasonFilter, selectedTireSize, tireSizeFilters, onTireSizeFilter) => {
  if (!!seasonFilters && !isNaN(selectedSeason) && !!tireSizeFilters && !isNaN(selectedTireSize) && isWheelVisualizer()) {
    var wheelSeasonCountList = new Array(new Array(), new Array(), new Array()), wheelSeasonCount = new Array(0, 0, 0);
    if (isWheelVisualizer()) {
      var wheelSizeSpecCountList = new Array(), tireSizeFiltersFull = tireSizeFilters,
        wheelSizeSpecList = Immutable.List(productListStore.data.listOfWheels).map(function (wheel) {
          return wheel ? Immutable.fromJS({
            id: wheel.get('frontWheel').get('id'),
            secondaryId: wheel.get('rearWheel') ? wheel.get('rearWheel').get('id') : 0,
            seasonalSize: wheel.get('seasonalSize'),
            diameter: wheel.get('frontWheel').get('diameter'),
            secondaryDiameter: wheel.get('rearWheel') ? wheel.get('rearWheel').get('diameter') : 0,
            width: wheel.get('frontWheel').get('width'),
            secondaryWidth: wheel.get('rearWheel') ? wheel.get('rearWheel').get('width') : 0,
            price: wheel.get('frontWheel').get('purchasable') ? wheel.get('frontWheel').get('pricing').get('displayPrice') : null,
            secondaryPrice: wheel.get('rearWheel') ? wheel.get('rearWheel').get('purchasable') ? wheel.get('rearWheel').get('pricing').get('displayPrice') : null : null
          }) : Immutable.fromJS({});
        });

      wheelSizeSpecList.forEach(function (wheelInfo) {
        if (wheelInfo) {
          var id = parseFloat(wheelInfo.get('width')) + '-' + wheelInfo.get('diameter') + '-' + parseFloat(wheelInfo.get('secondaryWidth')) + '-' + wheelInfo.get('secondaryDiameter');

          var price = null;
          if (wheelInfo.get('secondaryPrice') != null) {
            price = (wheelInfo.get('price') < wheelInfo.get('secondaryPrice')) ? wheelInfo.get('price') : wheelInfo.get('secondaryPrice');
          } else {
            price = wheelInfo.get('price');
          }

          switch (parseInt(wheelInfo.get('seasonalSize'))) {
            case 0:
              tireSizeFilters.forEach(function (tireSize) {
                if (isValidWidth(parseFloat(wheelInfo.get('width')), tireSize.get('width')) && wheelInfo.get('diameter') == tireSize.get('diameter')
                  && ((wheelInfo.get('secondaryId') === 0 && !tireSize.get('staggered'))
                  || (isValidWidth(parseFloat(wheelInfo.get('secondaryWidth')), tireSize.get('secondaryWidth')) && wheelInfo.get('secondaryDiameter') == tireSize.get('secondaryDiameter')))) {
                  switch (tireSize.get('seasonalSizeCode')) {
                    case 0:
                      wheelSeasonCountList[0].push(wheelInfo.get('id') + wheelInfo.get('secondaryId'));
                      wheelSeasonCountList[1].push(wheelInfo.get('id') + wheelInfo.get('secondaryId'));
                      wheelSeasonCountList[2].push(wheelInfo.get('id') + wheelInfo.get('secondaryId'));
                      break;
                    case 1:
                      wheelSeasonCountList[0].push(wheelInfo.get('id') + wheelInfo.get('secondaryId'));
                      break;
                    case 2:
                      wheelSeasonCountList[2].push(wheelInfo.get('id') + wheelInfo.get('secondaryId'));
                      break;
                    default:
                      break;
                  }
                }
              });
              break;
            case 1:
              tireSizeFilters.forEach(function (tireSize) {
                if (isValidWidth(parseFloat(wheelInfo.get('width')), tireSize.get('width')) && wheelInfo.get('diameter') == tireSize.get('diameter')
                  && ((wheelInfo.get('secondaryId') === 0 && !tireSize.get('staggered'))
                  || (isValidWidth(parseFloat(wheelInfo.get('secondaryWidth')), tireSize.get('secondaryWidth')) && wheelInfo.get('secondaryDiameter') == tireSize.get('secondaryDiameter')))) {
                  switch (tireSize.get('seasonalSizeCode')) {
                    case 0:
                    case 1:
                      wheelSeasonCountList[0].push(wheelInfo.get('id') + wheelInfo.get('secondaryId'));
                      break;
                    default:
                      break;
                  }
                }
              });
              break;
            case 2:
              tireSizeFilters.forEach(function (tireSize) {
                if (isValidWidth(parseFloat(wheelInfo.get('width')), tireSize.get('width')) && wheelInfo.get('diameter') == tireSize.get('diameter')
                  && ((wheelInfo.get('secondaryId') === 0 && !tireSize.get('staggered'))
                  || (isValidWidth(parseFloat(wheelInfo.get('secondaryWidth')), tireSize.get('secondaryWidth')) && wheelInfo.get('secondaryDiameter') == tireSize.get('secondaryDiameter')))) {
                  switch (tireSize.get('seasonalSizeCode')) {
                    case 0:
                    case 2:
                      wheelSeasonCountList[2].push(wheelInfo.get('id') + wheelInfo.get('secondaryId'));
                      break;
                    default:
                      break;
                  }
                }
              });
              break;
          }
        }

        if (typeof wheelSizeSpecCountList[id] === 'undefined') {
          wheelSizeSpecCountList[id] = 0;
        }
        if (isValidSeason(wheelInfo.get('seasonalSize'), selectedSeason)) {
          wheelSizeSpecCountList[id]++;
        }

      });

      wheelSeasonCountList[0] = _.uniq(wheelSeasonCountList[0]);
      wheelSeasonCountList[1] = _.uniq(wheelSeasonCountList[1]);
      wheelSeasonCountList[2] = _.uniq(wheelSeasonCountList[2]);

      if (wheelSeasonCountList[0]) wheelSeasonCountList[0].forEach(function () {
        wheelSeasonCount[0]++;
      });
      if (wheelSeasonCountList[1]) wheelSeasonCountList[1].forEach(function () {
        wheelSeasonCount[1]++;
      });
      if (wheelSeasonCountList[2]) wheelSeasonCountList[2].forEach(function () {
        wheelSeasonCount[2]++;
      });

      tireSizeFilters = tireSizeFiltersFull.filter(tireSize => {
        var id = parseFloat(tireSize.get('width')) + '-' + tireSize.get('diameter') + '-' + (tireSize.get('secondaryWidth') ? parseFloat(tireSize.get('secondaryWidth')) : 0) + '-' + (tireSize.get('secondaryDiameter') ? tireSize.get('secondaryDiameter') : 0);
        return getValueWithDecodedIndex(id, wheelSizeSpecCountList) > 0 && isValidTireSizeSeason(selectedSeason, tireSize.get('seasonalSizeCode'))
      });
      tireSizeFilters = tireSizeFilters.sortBy(tireSize => parseInt(tireSize.get('diameter'), 10));
    }

    return (
      <div className="filter-controls">

        <div className="control-container season">

          <div className="divider first"></div>

          {seasonFilters.map(function (season, index) {
            if (wheelSeasonCount[index] === 0) {
              return false;
            } else if (selectedSeason == index) {
              return (
                <div key={"season-" + index} className="season-selector" onClick={onSeasonFilter.bind(null, index)}>
                  <div className="visualizer-check-box checked"></div>
                  <div className="selector-text">{season.get(getAppLanguage())}</div>
                </div>
              );
            } else {
              return (
                <div key={"season-" + index} className="season-selector" onClick={onSeasonFilter.bind(null, index)}>
                  <div className="visualizer-check-box unchecked"></div>
                  <div className="selector-text">{season.get(getAppLanguage())}</div>
                </div>
              );
            }
          }.bind(null)).toArray()}

          <div className="divider last"></div>

        </div>

        <div className="control-container tire-size">

          <div className="divider first"></div>

          {tireSizeFilters.map(function (tiresize) {
            var tiresizeValue = false;
            if (getAppLanguage() == 'fr') {
              tiresizeValue = tiresize.get('value').fr + ' ';
            } else {
              tiresizeValue = tiresize.get('value').en + ' ';
            }
            if (tiresizeValue) {
              if (selectedTireSize == tiresize.get('key')) {
                return (
                  <div key={"tireSize-" + tiresize.get('key')} className="tire-size-selector" onClick={onTireSizeFilter.bind(null, tiresize.get('key'))}>
                    <div className="visualizer-check-box checked"></div>
                    <div className="selector-text">{tiresizeValue}</div>
                  </div>
                );
              } else {
                return (
                  <div key={"tireSize-" + tiresize.get('key')} className="tire-size-selector" onClick={onTireSizeFilter.bind(null, tiresize.get('key'))}>
                    <div className="visualizer-check-box unchecked"></div>
                    <div className="selector-text">{tiresizeValue}</div>
                  </div>
                );
              }
            } else {
              return false;
            }
          }.bind(null)).toArray()}

          <div className="divider last"></div>

        </div>

      </div>
    );
  } else return false;
};

export var renderPartVisualizerVehicleSection = (selectedPart, selectedColor, colorList, thumbnailSelectedIndex) => {
  if (!!selectedPart && !!selectedColor && sizeOf(colorList) > 0) {
    var dictionary = languageStore.getDictionary(), selectedColor = colorList.find(color => color.get('code') === selectedColor);
    var shadowImage = selectedColor.get('shadowImage');
    var foregroundImage = selectedColor.get('foregroundImage');
    var partImage = '';
    if (!!selectedPart['part']) if (typeof(selectedPart['part'].get('visualizerImage')) !== 'undefined') if (sizeOf(selectedPart['part'].get('visualizerImage')) > 0) partImage = selectedPart['part'].get('visualizerImage').get(0);
    if (!isEmpty(shadowImage) && !isEmpty(foregroundImage) && !isEmpty(partImage)) {
      return (
        <div className="visualizer-image">
          <div className="visualizer-wrapper">
            <div className="hex-base" />
            <img src={shadowImage} className="shadow-image" />
            <img src={foregroundImage} className="foreground-image" />
            <img src={partImage} className="wheel-image" />
          </div>
          <span className="vehicle-disclaimer">{dictionary.common.visualizerDisclaimer}</span>
        </div>
      );
    } else if (applicationStore.data.productType === ProductTypes.ALLOY_WHEELS) {
      var imageList = selectedPart['part'].get('images');
      if (imageList && sizeOf(imageList) > 0) {
        var selectedImageLink = imageList.get(selectedColor.get('code'));
        if (selectedImageLink) {
          return (
            <div className="visualizer-image">
              <img src={selectedImageLink} className="single-image" />
              <span className="vehicle-disclaimer">{dictionary.common.visualizerDisclaimer}</span>
            </div>
          );
        } else return false;
      } else return false;
    } else if (applicationStore.data.productType === ProductTypes.PACKAGES) {
      return renderPackageClassicVisualizerVehicleSection(selectedPart, thumbnailSelectedIndex);
    } else return false;
  } else if (applicationStore.data.productType === ProductTypes.PACKAGES) {
    return renderPackageClassicVisualizerVehicleSection(selectedPart, thumbnailSelectedIndex);
  } else return false;
};

export var renderPackageClassicVisualizerVehicleSection = (selectedPart, thumbnailSelectedIndex) => {
  var imageList = selectedPart['part'].get('secondaryImages');
  if (imageList && sizeOf(imageList) > 0) {
    var dictionary = languageStore.getDictionary(), selectedImageLink = imageList.get(thumbnailSelectedIndex).get('imageUrl');
    if (selectedImageLink) {
      return (
        <div className="visualizer-image">
          <img src={selectedImageLink} className="single-image" />
          <span className="vehicle-disclaimer">{dictionary.common.visualizerDisclaimer}</span>
        </div>
      );
    } else return false;
  } else return false;
};

export var renderPartVisualizerPartSelection = (selectedPart, partList, scrollIndexer, onScrollLeft, onScrollRight) => {
  if (!!selectedPart && sizeOf(partList) > 0) {
    var dictionary = languageStore.getDictionary(), part = getFilteredPart(selectedPart, partList);
    if (part) {
      var perPartText = part.partType === ProductTypes.ALLOY_WHEELS ? dictionary.product.perWheelLwr : false;

      var arrowRight = "", arrowLeft = "";
      switch (applicationStore.data.defaultTemplate) {
        case 'audi':
        case 'mazda':
        case 'mazdatransition':
          arrowRight = "/static/img/" + applicationStore.data.defaultTemplate + "/arrow_right.png";
          arrowLeft = "/static/img/" + applicationStore.data.defaultTemplate + "/arrow_left.png";
          break;
        default:
          arrowRight = "/static/img/arrow_right.png";
          arrowLeft = "/static/img/arrow_left.png";
          break;
      }

      return (
        <div className="part-scroll-list">
          <div className="scroll-container">

            <div className="arrow-scroll-container minus">
              <div className="arrow-container" onClick={onScrollLeft}>
                <Image className="arrow-scroll-image minus" src={arrowLeft} />
              </div>
            </div>

            <div className="part-list-container">

              {partList.map(function (part, index) {
                if (typeof part !== 'undefined') {
                  var selected = selectedPart.id === part.id;
                  var setPartForVisualizer = function () {
                    if (!selected) {
                      setTimeout(() => { productListActions.setPartForVisualizer(part); }, 10);
                    }
                  }.bind(null);
                  var className = Cx({
                    "part-container": true,
                    "selected": selected,
                    "show-one": index >= scrollIndexer && index < (scrollIndexer + 1),
                    "show-two": index >= scrollIndexer && index < (scrollIndexer + 2),
                    "show-three": index >= scrollIndexer && index < (scrollIndexer + 3),
                    "show-four": index >= scrollIndexer && index < (scrollIndexer + 4),
                    "show-five": index >= scrollIndexer && index < (scrollIndexer + 5),
                    "show-six": index >= scrollIndexer && index < (scrollIndexer + 6),
                    "show-seven": index >= scrollIndexer && index < (scrollIndexer + 7),
                    "show-eight": index >= scrollIndexer && index < (scrollIndexer + 8),
                    "show-nine": index >= scrollIndexer && index < (scrollIndexer + 9),
                    "show-ten": index >= scrollIndexer && index < (scrollIndexer + 10)
                  });

                  var selectedQuantity = getPartSelectedQuantity(part, selectedPart);
                  var hasPromotion = parseInt(selectedQuantity['pricingContextId']) > 0 && selectedQuantity['hasText'];

                  return (
                    <div key={"scroll-selector-" + index} className={className} onClick={setPartForVisualizer}>

                      <Image className="part-image" src={part.image} />
                      {parseFloat(part.displayPrice) > 0 ? <span className="price-text">{money_format(part.displayPrice)} {perPartText}</span>
                        : <span className="price-text">{dictionary.common.contactDealer}</span>}

                      {hasPromotion ? <Image className="promotion-icon" src="/static/img/promotion_corner.png" alt="Icon Promotion Corner"/> : false}
                      {hasPromotion ? <div className="visualizer-promotion-hover">
                        <pre className="promotion-hover-text">{selectedQuantity['text'][getAppLanguage()]}</pre>
                        <Image className="promotion-hover-image-right" src="/static/img/promotion_right.png" alt="Icon Promotion"/>
                      </div> : false}

                    </div>
                  );
                }

              })}

            </div>

            <div className="arrow-scroll-container plus">
              <div className="arrow-container" onClick={onScrollRight}>
                <Image className="arrow-scroll-image plus" src={arrowRight} />
              </div>
            </div>

          </div>
        </div>
      );

    } else return false;
  } else return false;
};

export var isWheelVisualizer = () => {
  return applicationStore.data.productType === ProductTypes.ALLOY_WHEELS;
};

export var isAccessoryVisualizer = () => {
  return applicationStore.data.productType === ProductTypes.ACCESSORIES;
};

export var isPackageVisualizer = () => {
  return applicationStore.data.productType === ProductTypes.PACKAGES;
};

export var getPartSelectedQuantity = (part, selectedPart) => {
  if (isWheelVisualizer()) return part.rebatePromotions.find(rebate => rebate['quantity'] == selectedPart.part.get('frontWheel').get('quantity').get('value'));
  else return part.rebatePromotions.find(rebate => rebate['quantity'] == selectedPart.part.get('quantity').get('value'));
};

export var getFilteredPart = (selectedPart, partList) => {
  if (selectedPart && selectedPart.id && partList) {
    return partList.find(part => part.id === selectedPart.id);
  } else {
    return null;
  }
};

export var getScrollListShowPartCount = (windowWidth) => {
  if (windowWidth >= 1940) return 10;
  else if (windowWidth >= 1765) return 9;
  else if (windowWidth >= 1590) return 8;
  else if (windowWidth >= 1415) return 7;
  else if (windowWidth >= 1240) return 6;
  else if (windowWidth >= 1065) return 5;
  else if (windowWidth >= 890) return 4;
  else if (windowWidth >= 715) return 3;
  else if (windowWidth >= 550) return 2;
  else return 1;
};

export var getValueWithDecodedIndex = (id, array) => {
  var decodedSelectedId = id.split("-");

  var arrayValue = 0;
  for (var id in array) {
    var decodedArrayId = id.split("-");
    if ((isValidWidth(parseFloat(decodedArrayId[0]), decodedSelectedId[0]) && decodedArrayId[1] == decodedSelectedId[1])
      && ((decodedArrayId[2] == 0 || isValidWidth(parseFloat(decodedArrayId[2]), decodedSelectedId[2])) && decodedArrayId[3] == decodedSelectedId[3])) {
      arrayValue += parseFloat(array[id]);
    }
  }

  return arrayValue;
};

export var isValidTireSizeSeason = (selectedSeason, tireSizeSeason) => {
  switch (parseInt(selectedSeason, 10)) {
    case 0:
      return parseInt(tireSizeSeason, 10) == 0 || parseInt(tireSizeSeason, 10) == 1;
      break;
    case 1:
      return parseInt(tireSizeSeason, 10) == 0;
      break;
    case 2:
      return parseInt(tireSizeSeason, 10) == 0 || parseInt(tireSizeSeason, 10) == 2;
      break;
  }
};

export var isValidSeason = (wheelSeason, selectedSeason) => {
  switch (parseInt(wheelSeason, 10)) {
    case 0:
      return true;
      break;
    case 1:
      return parseInt(selectedSeason, 10) == 0;
      break;
    case 2:
      return parseInt(selectedSeason, 10) == 2;
      break;
  }
};

export var isValidWidth = (wheelWidth, selectedWidth) => {
  var minTireWidth = 1000;
  var maxTireWidth = 0;
  switch (wheelWidth) {
    case 5:
      minTireWidth = 135;
      maxTireWidth = 205;
      break;
    case 5.5:
      minTireWidth = 145;
      maxTireWidth = 215;
      break;
    case 6:
      minTireWidth = 155;
      maxTireWidth = 225;
      break;
    case 6.5:
      minTireWidth = 165;
      maxTireWidth = 235;
      break;
    case 7:
      minTireWidth = 175;
      maxTireWidth = 245;
      break;
    case 7.5:
      minTireWidth = 185;
      maxTireWidth = 255;
      break;
    case 8:
      minTireWidth = 195;
      maxTireWidth = 265;
      break;
    case 8.5:
      minTireWidth = 205;
      maxTireWidth = 275;
      break;
    case 9:
      minTireWidth = 215;
      maxTireWidth = 285;
      break;
    case 9.5:
      minTireWidth = 225;
      maxTireWidth = 295;
      break;
    case 10:
      minTireWidth = 235;
      maxTireWidth = 305;
      break;
    case 10.5:
      minTireWidth = 245;
      maxTireWidth = 315;
      break;
    case 11:
      minTireWidth = 255;
      maxTireWidth = 325;
      break;
    case 11.5:
      minTireWidth = 265;
      maxTireWidth = 335;
      break;
    case 12:
      minTireWidth = 275;
      maxTireWidth = 345;
      break;
    case 12.5:
      minTireWidth = 285;
      maxTireWidth = 355;
      break;
    default:
      break;
  }

  return minTireWidth <= selectedWidth && selectedWidth <= maxTireWidth;
};

import { applicationActions } from "../../actions/ApplicationActions.js";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";
import { shoppingCartStore } from "../../stores/ShoppingCartStore.js";
import { Modal } from "../ux/Modal.jsx";
import { SelectBox } from "../ux/SelectBox.jsx";

import {
  sizeOf,
  isEmpty,
  getTrackingEventCategory,
  money_format,
  getDealerName,
} from "../../service/UtilityService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var Immutable = require("immutable");

export class PricingIncentive extends I18nComponent {
  updateState = (state) => {
    this.setState(state);
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("Pricing Incentive");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Pricing Incentive Access"
    );
    applicationStore.addChangeListener(this.onChange);
    shoppingCartStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
    shoppingCartStore.removeChangeListener(this.onChange);
    window.removeEventListener("keypress", this.keypress);
  }

  componentDidMount() {
    var keypress = this.keypress;
    window.addEventListener("keypress", keypress);
    shoppingCartActions.getTirePricingIncentive();
  }

  render() {
    var brandList = this.state.pricingIncentiveBrands,
      seasonList = this.state.pricingIncentiveSeasons,
      tiresizeList = this.state.pricingIncentiveTiresizes,
      onSelectExtraInfo = this.onSelectExtraInfo,
      updateState = this.updateState,
      sortlist = this.state.sortlist,
      filteredlist = this.getFilteredDataList(),
      search = this.state.search,
      indexlist = this.getPageIndexDataList(sizeOf(filteredlist));
    return (
      <Modal refactor={true} requestUnmount={this.onClose} fullWidth={true}>
        <div className="wrapper">
          <div className="modal-content">
            <box className="tablepage">
              <container>
                <box className="tablefilter">
                  <container>
                    <box>
                      <h2>
                        {getDealerName() + " " + this.t("pricingIncentive")}
                      </h2>
                    </box>
                    <box>
                      <column className="half">
                        <column>
                          <container className="side right">
                            <text>{this.t("filter")}</text>
                          </container>
                        </column>
                        <column>
                          <container className="side right">
                            <SelectBox
                              selected={this.state.brand}
                              dataList={brandList}
                              onSelect={this.onSelectBrand}
                            />
                          </container>
                        </column>
                        <column>
                          <container className="side right">
                            <SelectBox
                              selected={this.state.season}
                              dataList={seasonList}
                              onSelect={this.onSelectSeason}
                            />
                          </container>
                        </column>
                        <column>
                          <container className="side right">
                            <SelectBox
                              selected={this.state.tiresize}
                              dataList={tiresizeList}
                              onSelect={this.onSelectTiresize}
                            />
                          </container>
                        </column>
                      </column>
                    </box>
                  </container>
                </box>
                <box>
                  <column>
                    <container className="no-left small">
                      <text>{this.t("show")}</text>
                    </container>
                  </column>
                  <column>
                    <container className="side right">
                      <SelectBox
                        selected={this.state.pageCount}
                        dataList={[
                          { key: 10, value: 10 },
                          { key: 25, value: 25 },
                          { key: 50, value: 50 },
                          { key: 100, value: 100 },
                        ]}
                        onSelect={this.onSelectPageCount}
                      />
                    </container>
                  </column>
                  <column>
                    <container className="no-left small">
                      <text>{this.t("page")}</text>
                    </container>
                  </column>
                  <column>
                    <container className="side right">
                      <SelectBox
                        selected={this.state.pageIndex}
                        dataList={indexlist}
                        onSelect={this.onSelectPageIndex}
                      />
                    </container>
                  </column>
                </box>
                <box>
                  <container className="top">
                    <table>
                      <thead>
                        <th>
                          <container
                            className="side small pointer"
                            onClick={function () {
                              updateState({
                                sortlist:
                                  sortlist === 0 || sortlist === 1
                                    ? sortlist === 1
                                      ? false
                                      : 1
                                    : 0,
                              });
                            }}
                          >
                            <text>{this.t("brand")}</text>
                            <i
                              className={
                                sortlist === 0
                                  ? "fa fa-sort-up selected"
                                  : "fa fa-sort-up"
                              }
                            />
                            <i
                              className={
                                sortlist === 1
                                  ? "fa fa-sort-down selected"
                                  : "fa fa-sort-down"
                              }
                            />
                          </container>
                        </th>
                        <th>
                          <container
                            className="side small pointer"
                            onClick={function () {
                              updateState({
                                sortlist:
                                  sortlist === 2 || sortlist === 3
                                    ? sortlist === 3
                                      ? false
                                      : 3
                                    : 2,
                              });
                            }}
                          >
                            <text>{this.t("product.name")}</text>
                            <i
                              className={
                                sortlist === 2
                                  ? "fa fa-sort-up selected"
                                  : "fa fa-sort-up"
                              }
                            />
                            <i
                              className={
                                sortlist === 3
                                  ? "fa fa-sort-down selected"
                                  : "fa fa-sort-down"
                              }
                            />
                          </container>
                        </th>
                        <th>
                          <container
                            className="side small pointer"
                            onClick={function () {
                              updateState({
                                sortlist:
                                  sortlist === 4 || sortlist === 5
                                    ? sortlist === 5
                                      ? false
                                      : 5
                                    : 4,
                              });
                            }}
                          >
                            <text>{this.t("product.partNumber")}</text>
                            <i
                              className={
                                sortlist === 4
                                  ? "fa fa-sort-up selected"
                                  : "fa fa-sort-up"
                              }
                            />
                            <i
                              className={
                                sortlist === 5
                                  ? "fa fa-sort-down selected"
                                  : "fa fa-sort-down"
                              }
                            />
                          </container>
                        </th>
                        <th>
                          <container
                            className="side small pointer"
                            onClick={function () {
                              updateState({
                                sortlist:
                                  sortlist === 6 || sortlist === 7
                                    ? sortlist === 7
                                      ? false
                                      : 7
                                    : 6,
                              });
                            }}
                          >
                            <text>{this.t("product.tireSize")}</text>
                            <i
                              className={
                                sortlist === 6
                                  ? "fa fa-sort-up selected"
                                  : "fa fa-sort-up"
                              }
                            />
                            <i
                              className={
                                sortlist === 7
                                  ? "fa fa-sort-down selected"
                                  : "fa fa-sort-down"
                              }
                            />
                          </container>
                        </th>
                        <th>
                          <container
                            className="side small pointer"
                            onClick={function () {
                              updateState({
                                sortlist:
                                  sortlist === 8 || sortlist === 9
                                    ? sortlist === 9
                                      ? false
                                      : 9
                                    : 8,
                              });
                            }}
                          >
                            <text>{this.t("costPrice")}</text>
                            <i
                              className={
                                sortlist === 8
                                  ? "fa fa-sort-up selected"
                                  : "fa fa-sort-up"
                              }
                            />
                            <i
                              className={
                                sortlist === 9
                                  ? "fa fa-sort-down selected"
                                  : "fa fa-sort-down"
                              }
                            />
                          </container>
                        </th>
                        <th>
                          <container
                            className="side small pointer"
                            onClick={function () {
                              updateState({
                                sortlist:
                                  sortlist === 10 || sortlist === 11
                                    ? sortlist === 11
                                      ? false
                                      : 11
                                    : 10,
                              });
                            }}
                          >
                            <text>{this.t("listPrice")}</text>
                            <i
                              className={
                                sortlist === 10
                                  ? "fa fa-sort-up selected"
                                  : "fa fa-sort-up"
                              }
                            />
                            <i
                              className={
                                sortlist === 11
                                  ? "fa fa-sort-down selected"
                                  : "fa fa-sort-down"
                              }
                            />
                          </container>
                        </th>
                        <th>
                          <container
                            className="side small pointer"
                            onClick={function () {
                              updateState({
                                sortlist:
                                  sortlist === 12 || sortlist === 13
                                    ? sortlist === 13
                                      ? false
                                      : 13
                                    : 12,
                              });
                            }}
                          >
                            <text>{this.t("tierOne")}</text>
                            <i
                              className={
                                sortlist === 12
                                  ? "fa fa-sort-up selected"
                                  : "fa fa-sort-up"
                              }
                            />
                            <i
                              className={
                                sortlist === 13
                                  ? "fa fa-sort-down selected"
                                  : "fa fa-sort-down"
                              }
                            />
                          </container>
                        </th>

                        <th>
                          <container
                            className="side small pointer"
                            onClick={function () {
                              updateState({
                                sortlist:
                                  sortlist === 14 || sortlist === 15
                                    ? sortlist === 15
                                      ? false
                                      : 15
                                    : 14,
                              });
                            }}
                          >
                            <text>{this.t("tierTwo")}</text>
                            <i
                              className={
                                sortlist === 14
                                  ? "fa fa-sort-up selected"
                                  : "fa fa-sort-up"
                              }
                            />
                            <i
                              className={
                                sortlist === 15
                                  ? "fa fa-sort-down selected"
                                  : "fa fa-sort-down"
                              }
                            />
                          </container>
                        </th>
                        <th>
                          <container
                            className="side small pointer"
                            onClick={function () {
                              updateState({
                                sortlist:
                                  sortlist === 16 || sortlist === 17
                                    ? sortlist === 17
                                      ? false
                                      : 17
                                    : 16,
                              });
                            }}
                          >
                            <text>{this.t("tierThree")}</text>
                            <i
                              className={
                                sortlist === 16
                                  ? "fa fa-sort-up selected"
                                  : "fa fa-sort-up"
                              }
                            />
                            <i
                              className={
                                sortlist === 17
                                  ? "fa fa-sort-down selected"
                                  : "fa fa-sort-down"
                              }
                            />
                          </container>
                        </th>
                        <th>
                          <container
                            className="side small pointer"
                            onClick={function () {
                              updateState({
                                sortlist:
                                  sortlist === 18 || sortlist === 19
                                    ? sortlist === 19
                                      ? false
                                      : 19
                                    : 18,
                              });
                            }}
                          >
                            <text>{this.t("tierFour")}</text>
                            <i
                              className={
                                sortlist === 18
                                  ? "fa fa-sort-up selected"
                                  : "fa fa-sort-up"
                              }
                            />
                            <i
                              className={
                                sortlist === 19
                                  ? "fa fa-sort-down selected"
                                  : "fa fa-sort-down"
                              }
                            />
                          </container>
                        </th>
                      </thead>

                      <tbody>
                        {sizeOf(filteredlist) > 0 ? (
                          Immutable.fromJS(filteredlist)
                            .sort(this.sortList)
                            .skip(this.getPageSkipIndex())
                            .take(this.getPageCount())
                            .map(function (data) {
                              return (
                                <tr>
                                  <td>
                                    <container className="side">
                                      {data.get(0)}
                                    </container>
                                  </td>
                                  <td>
                                    <container className="side">
                                      {data.get(1)}
                                    </container>
                                  </td>
                                  <td>
                                    <container className="side">
                                      <a
                                        target="_blank"
                                        href={
                                          window.location.href +
                                          "/search/partnumber-search/" +
                                          data.get(2)
                                        }
                                      >
                                        {data.get(2)}
                                      </a>
                                    </container>
                                  </td>
                                  <td>
                                    <container className="side">
                                      {data.get(3)}
                                    </container>
                                  </td>
                                  <td className="right">
                                    <container className="side">
                                      {money_format(data.get(4))}
                                    </container>
                                  </td>
                                  <td className="right">
                                    <container className="side">
                                      {money_format(data.get(5))}
                                    </container>
                                  </td>
                                  <td className="right">
                                    <container className="side">
                                      {money_format(data.get(6))}
                                    </container>
                                  </td>
                                  <td className="right">
                                    <container className="side">
                                      {money_format(data.get(7))}
                                    </container>
                                  </td>
                                  <td className="right">
                                    <container className="side">
                                      {money_format(data.get(8))}
                                    </container>
                                  </td>
                                  <td className="right">
                                    <container className="side">
                                      {money_format(data.get(9))}
                                    </container>
                                  </td>
                                </tr>
                              );
                            })
                        ) : !isEmpty(search) ? (
                          <tr>
                            <td colSpan="10">
                              <container>
                                {this.t("dealerFilter.searchResultsEmpty")}
                              </container>
                            </td>
                          </tr>
                        ) : (
                          <tr></tr>
                        )}
                      </tbody>
                    </table>
                  </container>
                </box>
              </container>
            </box>
          </div>
        </div>
      </Modal>
    );
  }

  sortList = (a, b) => {
    var index = parseInt(this.state.sortlist / 2);
    var reverse = this.state.sortlist % 2 === 1;

    switch (index) {
      case 0:
      case 1:
      case 2:
      case 3:
        if (a.get(index) < b.get(index)) {
          return reverse ? 1 : -1;
        } else if (a.get(index) > b.get(index)) {
          return reverse ? -1 : 1;
        } else {
          return 0;
        }

      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
        return reverse
          ? b.get(index) - a.get(index)
          : a.get(index) - b.get(index);

      default:
        return true;
    }
  };

  getPageSkipIndex = () => {
    return (this.state.pageIndex - 1) * this.getPageCount();
  };

  getPageCount = () => {
    return this.state.pageCount;
  };

  renderExtraInfoModal = () => {
    if (!isEmpty(this.state.cost)) {
      return (
        <Modal
          refactor={true}
          useShortHeaderBar={true}
          requestUnmount={this.onSelectExtraInfo.bind(null, "", "")}
        >
          <div className="wrapper">
            <div className="modal-content">
              <span>
                {this.t("dealerCost")}: {money_format(this.state.cost)}
              </span>
              {parseFloat(this.state.booking) > 0 ? (
                <span>
                  {this.t("dealerBooking")}: {money_format(this.state.booking)}
                </span>
              ) : (
                false
              )}
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  getSeasonList = () => {
    return [
      { key: -1, value: this.t("product.filters.season.noSelection") },
      { key: 0, value: this.t("product.filters.season.winter") },
      { key: 1, value: this.t("product.filters.season.allSeason") },
      { key: 2, value: this.t("product.filters.season.summer") },
      { key: 3, value: this.t("product.filters.season.allWeather") },
    ];
  };

  getFilteredDataList = () => {
    var dataList = this.state.pricingIncentiveList;

    var filteredList = [];
    for (var i in dataList) {
      if (
        (this.state.brand == 0 || dataList[i][0] == this.state.brand) &&
        (this.state.season == -1 || this.getValidSeason(dataList[i])) &&
        (this.state.tiresize == 0 || dataList[i][3] == this.state.tiresize) &&
        (isEmpty(this.state.search) ||
          dataList[i][1].indexOf(this.state.search) > -1 ||
          dataList[i][2].indexOf(this.state.search) > -1 ||
          dataList[i][3].indexOf(this.state.search) > -1)
      ) {
        filteredList.push(dataList[i]);
      }
    }

    return filteredList;
  };

  getValidSeason = (data) => {
    switch (this.state.season) {
      case 0:
      case 1:
      case 2:
        return data[10] == this.state.season;
      case 3:
        return data[11] == "1";
      default:
        return false;
    }
  };

  onSelectBrand = (brand) => {
    this.setState({
      brand: brand,
      pageIndex: 1,
    });
  };

  onSelectSeason = (season) => {
    this.setState({
      season: season,
      pageIndex: 1,
    });
  };

  onSelectTiresize = (tiresize) => {
    this.setState({
      tiresize: tiresize,
      pageIndex: 1,
    });
  };

  onSelectPageCount = (count) => {
    this.setState({
      pageCount: count,
      pageIndex: 1,
    });
  };

  onSelectPageIndex = (index) => {
    this.setState({
      pageIndex: index,
    });
  };

  getPageIndexDataList = (listSize) => {
    var i = 1,
      list = [],
      size =
        listSize % this.state.pageCount > 0
          ? parseInt(listSize / this.state.pageCount) + 1
          : parseInt(listSize / this.state.pageCount);
    if (listSize > 0) {
      while (i <= size) {
        list.push({ key: i, value: i });
        i++;
      }
    }
    return list;
  };

  onSelectExtraInfo = (cost, booking) => {
    this.setState({
      cost: cost,
      booking: booking,
    });
  };

  onClose = () => {
    applicationActions.pricingIncentiveToggle();
  };

  onChange = () => {
    this.setState({
      pricingIncentiveList: shoppingCartStore.data.get("pricingIncentiveList"),
      pricingIncentiveBrands: shoppingCartStore.data.get(
        "pricingIncentiveBrands"
      ),
      pricingIncentiveTiresizes: shoppingCartStore.data.get(
        "pricingIncentiveTiresizes"
      ),
    });
  };

  state = {
    pricingIncentiveList: shoppingCartStore.data.get("pricingIncentiveList"),
    pricingIncentiveBrands: shoppingCartStore.data.get(
      "pricingIncentiveBrands"
    ),
    pricingIncentiveSeasons: this.getSeasonList(),
    pricingIncentiveTiresizes: shoppingCartStore.data.get(
      "pricingIncentiveTiresizes"
    ),

    search: "",
    brand: 0,
    season: -1,
    tiresize: 0,

    sortlist: 13,
    pageIndex: 1,
    pageCount: 25,

    cost: "",
    booking: "",
  };
}

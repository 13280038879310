import { applicationStore } from "../../stores/ApplicationStore.js";
import { applicationActions } from "../../actions/ApplicationActions.js";
import { shoppingCartStore } from "../../stores/ShoppingCartStore.js";
import { shoppingCartViewStore } from "../../stores/ShoppingCartViewStore.js";
import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";

import { Modal } from "../ux/Modal.jsx";

import { ProductTypes } from "../../constants/ProductTypes";

import { getCodeText } from "../../service/ProductList";
import {
  checkNTDStockExists,
  renderNTDButton,
} from "../../service/OrderService";
import {
  formTypes,
  money_format,
  getTrackingEventOrderId,
  getTrackingEventCategory,
  isEmpty,
  getAppLanguage,
} from "../../service/UtilityService";
import {
  renderWishlistHeader,
  renderProducts,
  renderSaving,
  renderProductsNonOrder,
  renderProductsNonOrderDisclaimer,
  renderWishlistSummaryTable,
  renderBanner,
  renderSummary,
  renderControls,
  renderEcommerceAppointmentModal,
  renderEcommerceTimeSelectionModal,
  renderFoxyCartError,
  renderFoxyCart,
  renderCompleteEcommerceModal,
  renderQuoteQuickActions,
  onFoxyCartLaunch,
  onToggleFinancing,
  getSupportConsumerOrder,
  getOrderDeposit,
  getShoppingViewNotes,
} from "../../service/WishlistService";
import { connect } from "react-redux";
import {
  shoppingCartOpenForm,
  toggleShoppingModal,
} from "../../redux/features/shoppingCart.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var Immutable = require("immutable");

var CartWarnings = {
  ITEM_WARNING_TIRE_NOT_ORIGINAL_SIZE: "ITEM_WARNING_TIRE_NOT_ORIGINAL_SIZE",
  ITEM_WARNING_TIRE_NOT_OE: "ITEM_WARNING_TIRE_NOT_OE",
  ITEM_WARNING_TIRE_NOT_RUNFLAT: "ITEM_WARNING_TIRE_NOT_RUNFLAT",
  ITEM_WARNING_TIRE_WHEEL_MISMATCH: "ITEM_WARNING_TIRE_WHEEL_MISMATCH",
  ITEM_WARNING_TIRE_WHEEL_IDEAL_SIZE_MISMATCH:
    "ITEM_WARNING_TIRE_WHEEL_IDEAL_SIZE_MISMATCH",
};

const LAYOUT = {
  MOBILE: 730,
};

class WishlistModal extends I18nComponent {
  state = {
    cartItems: Immutable.Map(),

    ecommerceAppointmentCalendar: false,
    appointmentRequestDate: null,
    appointmentRequestTime: "",
    appointmentRequestNotes: "",
    appointmentDate: null,
    appointmentTime: "",
    appointmentNotes: "",

    ecommerceAppointmentTimeModal: false,
    ecommerceAppointmentTimeAmOrPm: true,
    ecommerceAppointmentTempHour: "",
    ecommerceAppointmentTempMinute: "",

    completeEcommerceModal: false,

    foxyCart: null,
    foxyCartError: false,
    runFoxyCartForm: false,

    showWarning: shoppingCartStore.checkForWarnings(),
    showFinancingModal: shoppingCartViewStore.data.get("showFinancingModal"),

    mobile: window.innerWidth < 730,
  };

  updateState = (state) => {
    this.setState(state);
  };

  componentDidMount() {
    var updateState = this.updateState;
    window.addEventListener("resize", function () {
      updateState({
        mobile: window.innerWidth < 730,
      });
    });
  }

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("Shopping Cart Quick View");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Shopping Cart Quick View Access",
      getTrackingEventOrderId(),
      shoppingCartViewStore.data.get("total")
    );

    shoppingCartStore.addChangeListener(this.onChange);
    shoppingCartViewStore.addChangeListener(this.onChange);
    applicationStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    shoppingCartStore.removeChangeListener(this.onChange);
    shoppingCartViewStore.removeChangeListener(this.onChange);
    applicationStore.removeChangeListener(this.onChange);
  }

  shouldComponentUpdate(lastProps, nextState) {
    if (
      !this.state.foxyCart &&
      nextState.foxyCart &&
      !nextState.foxyCartError
    ) {
      this.setState({ runFoxyCartForm: true });
    }
    if (this.state.runFoxyCartForm) {
      this.setState({ runFoxyCartForm: false });
      var form = document.getElementById("orderRedirectForm");
      if (form) form.submit();
    }
    return this.props !== lastProps || this.state !== nextState;
  }

  render() {
    if (this.props.cartCount > 0) {
      if (this.props.showFinancingModal) {
        return this.renderFinanceModal();
      } else {
        return this.renderWishlistModal();
      }
    } else {
      return false;
    }
  }

  renderFinanceModal = () => {
    return (
      <Modal
        refactor={true}
        fullWidth={true}
        requestUnmount={this.onRequestCloseModal}
      >
        <div className="wrapper">
          <div className="shopping-cart-modal">
            {this.renderFinanceBody()}

            {this.renderEcommerceAppointmentModal()}
            {this.renderTimeSelectionModal()}
            {this.renderCompleteEcommerceModal()}

            {this.renderWarningModal()}

            {this.renderFoxyCart()}
            {this.renderFoxyCartError()}
          </div>
        </div>
      </Modal>
    );
  };

  renderFinanceBody = () => {
    if (this.state.mobile) {
      return (
        <div className="cart-body finance-body">
          {this.renderiFrameColumn()}
          {this.renderFinanceCart()}
        </div>
      );
    } else {
      return (
        <div className="cart-body finance-body">
          {this.renderFinanceCart()}
          {this.renderiFrameColumn()}
        </div>
      );
    }
  };

  renderFinanceCart = () => {
    return (
      <column className="half">
        <box className="finance-title">
          <box>
            <h2>
              {this.t("financeWithTaxes")}
              {" - "}
              {money_format(shoppingCartViewStore.data.get("total"))}
            </h2>
          </box>
          <box>
            <h4>{this.t("financeApproval")}</h4>
          </box>
        </box>
        <box>
          {renderProducts(ProductTypes.TIRES)}
          {renderProducts(ProductTypes.ALLOY_WHEELS)}
          {renderProducts(ProductTypes.ACCESSORIES)}
          {renderProducts(ProductTypes.PACKAGES)}

          {renderSaving()}

          {renderWishlistSummaryTable()}

          {renderSummary()}

          {renderProductsNonOrderDisclaimer()}
          {renderProductsNonOrder(ProductTypes.TIRES, this.props.cartItems)}
          {renderProductsNonOrder(
            ProductTypes.ALLOY_WHEELS,
            this.props.cartItems
          )}
          {renderProductsNonOrder(
            ProductTypes.ACCESSORIES,
            this.props.cartItems
          )}
          {renderProductsNonOrder(ProductTypes.PACKAGES, this.props.cartItems)}

          {renderBanner()}
        </box>
        <box>
          <box className="finance-benefits">
            <box className="title">
              <container className="top small">
                <h2>{this.t("benefits").toUpperCase()}</h2>
              </container>
            </box>
            <box className="content">
              <column className="fourth">
                <container className="side small">
                  <box>
                    <img src="/static/img/low-payments.png" />
                  </box>
                  <box>
                    <text className="bold">{this.t("paymentFrequency")}</text>
                  </box>
                </container>
              </column>

              <column className="fourth">
                <container className="side small">
                  <box>
                    <img src="/static/img/0-interest.png" />
                  </box>
                  <box>
                    <text className="bold">
                      {this.t("sixMonthsNoInterest")}
                    </text>
                  </box>
                </container>
              </column>

              <column className="fourth">
                <container className="side small">
                  <box>
                    <img src="/static/img/skip-payment.png" />
                  </box>
                  <box>
                    <text className="bold">{this.t("skipPaymentOption")}</text>
                  </box>
                </container>
              </column>

              <column className="fourth">
                <container className="side small">
                  <box>
                    <img src="/static/img/early-repayment.png" />
                  </box>
                  <box>
                    <text className="bold">{this.t("noChargeRepayment")}</text>
                  </box>
                </container>
              </column>
            </box>
          </box>
        </box>
        <box className="finance-switch">
          <container className="top large">
            <button className="btn" onClick={onToggleFinancing}>
              <text>{this.t("payInFull")}</text>
            </button>
          </container>
        </box>
      </column>
    );
  };

  renderQuoteNotes = () => {
    if (!isEmpty(getShoppingViewNotes())) {
      return (
        <box>
          <text className="bold">{this.td(getShoppingViewNotes())}</text>
        </box>
      );
    } else {
      return false;
    }
  };

  renderiFrameColumn = () => {
    return (
      <column className="half">
        <box>
          <container className="side">
            <iframe src={applicationStore.data.financingUrl} />
          </container>
        </box>

        <box>
          <container className="small">
            <h2 className="bold">
              {
                "After you are approved the dealer will receive a notification and will contact you directly, you can also contact the dealer using the below."
              }
            </h2>
          </container>
        </box>

        <box>
          <container className="side small">
            {renderQuoteQuickActions(true)}
          </container>
        </box>
      </column>
    );
  };

  renderWishlistModal = () => {
    return (
      <Modal
        refactor={true}
        fullWidth={true}
        requestUnmount={this.onRequestCloseModal}
      >
        <div className="wrapper">
          <div className="shopping-cart-modal">
            {renderQuoteQuickActions()}

            <div className="cart-body">
              {renderWishlistHeader(
                true,
                false,
                this.state,
                this.updateState,
                this.printQuote,
                this.downloadQuote
              )}

              {renderProducts(ProductTypes.TIRES)}
              {renderProducts(ProductTypes.ALLOY_WHEELS)}
              {renderProducts(ProductTypes.ACCESSORIES)}
              {renderProducts(ProductTypes.PACKAGES)}

              {renderSaving()}

              {renderWishlistSummaryTable()}

              {this.renderQuoteNotes()}

              {renderSummary()}
              {this.renderDepositDue()}

              {renderControls(
                true,
                this.state,
                this.updateState,
                this.printQuote,
                this.downloadQuote,
                this.emailQuote
              )}

              {this.renderNtdOrder()}

              {renderProductsNonOrderDisclaimer()}
              {renderProductsNonOrder(ProductTypes.TIRES, this.props.cartItems)}
              {renderProductsNonOrder(
                ProductTypes.ALLOY_WHEELS,
                this.props.cartItems
              )}
              {renderProductsNonOrder(
                ProductTypes.ACCESSORIES,
                this.props.cartItems
              )}
              {renderProductsNonOrder(
                ProductTypes.PACKAGES,
                this.props.cartItems
              )}

              {renderBanner()}
            </div>

            {this.renderEcommerceAppointmentModal()}
            {this.renderTimeSelectionModal()}
            {this.renderCompleteEcommerceModal()}

            {this.renderWarningModal()}

            {this.renderFoxyCart()}
            {this.renderFoxyCartError()}
          </div>
        </div>
      </Modal>
    );
  };

  renderWarningModal = () => {
    if (this.state.showWarning) {
      return (
        <Modal
          refactor={true}
          useShortHeaderBar={true}
          requestUnmount={this.closeWarningModal}
        >
          <div className="wrapper">
            <div className="modal-content">
              <h2 className="title">
                {this.t("shoppingCartWarningsModal.notification")}
              </h2>
              <span>{this.renderWarnings()}</span>
              <div className="button-container">
                <button
                  className="btn close-warning"
                  onClick={this.closeWarningModal}
                >
                  {this.t("common.close")}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  closeWarningModal = () => {
    shoppingCartActions.closeWarnings();
  };

  renderWarnings = () => {
    var items = shoppingCartStore.getItems();
    var warningArray = [],
      itemNameById = [],
      itemNames = [],
      i = 0;
    items.forEach(function (item) {
      itemNameById[item.get("cartItemId")] = item
        .get("name")
        .get(getAppLanguage());
      var warnings = item.get("itemWarnings");
      if (warnings && warnings.size > 0) {
        warningArray[i] = new Array();
        itemNames[i] = item.get("name").get(getAppLanguage());
        warnings.forEach(function (warning) {
          if (warning.get("isNew")) {
            if (
              warning.get("code") ===
              CartWarnings.ITEM_WARNING_TIRE_WHEEL_IDEAL_SIZE_MISMATCH
            ) {
              var tiresize = "";
              if (item.get("type") === ProductTypes.ALLOY_WHEELS) {
                if (item && item.get("product"))
                  tiresize = item
                    .get("product")
                    .get("description")
                    .get(getAppLanguage());
                if (tiresize.indexOf("Recommended") > -1)
                  tiresize = tiresize.replace("Recommended", "recommended");
              } else if (item.get("type") === ProductTypes.TIRES) {
                items.map(function (tempItem) {
                  if (
                    tempItem.get("cartItemId") ===
                    warning.get("associateCartItemId")
                  ) {
                    if (tempItem && tempItem.get("product"))
                      tiresize = tempItem
                        .get("product")
                        .get("description")
                        .get(getAppLanguage());
                    if (tiresize.indexOf("Recommended") > -1)
                      tiresize = tiresize.replace("Recommended", "recommended");
                  }
                });
              }

              warningArray[i].push(
                getCodeText(warning.get("code")).replace("{}", tiresize)
              );
            } else {
              warningArray[i].push(getCodeText(warning.get("code")));
            }
          }
        });
        i++;
      }
    });

    return warningArray.map((warning, key) => (
      <div key={key}>
        <span className="modal-warning-item-name">{itemNames[key]}</span>
        <p>{warning.map((warning) => warning)}</p>
      </div>
    ));
  };

  renderNtdOrder = () => {
    var ntdOrderStockEnabled = false;
    if (this.props.cartItems) {
      for (var i in ProductTypes) {
        var items = this.props.cartItems.get(i);
        if (items && typeof items !== "undefined") {
          items.map(function (item) {
            if (!ntdOrderStockEnabled)
              ntdOrderStockEnabled = checkNTDStockExists(item);
          });
        }
      }
    }

    if (
      ntdOrderStockEnabled &&
      applicationStore.data.dealerAllowNtdStockOrder
    ) {
      return renderNTDButton(this.onOpenNTDOrderModal, "order");
    } else {
      return false;
    }
  };

  onOpenNTDOrderModal = () => {
    applicationActions.onOpenNtdModal();
  };

  renderDepositDue = () => {
    if (getSupportConsumerOrder()) {
      return (
        <div className="deposit-info">
          <span>{this.t("wishlist.modal.buyNowDeposit")}:</span>
          <span className="deposit">
            {" " + money_format(getOrderDeposit())}
          </span>

          <div className="wishlist-actions-buttons wishlist-mobile">
            <button className="btn foxy-cart-launch" onClick={onFoxyCartLaunch}>
              <i className="fa fa-lock" />
              <span>{this.t("wishlist.proceedToCheckout")}</span>
            </button>
          </div>
        </div>
      );
    } else {
      return false;
    }
  };

  renderFoxyCart = () => {
    return renderFoxyCart(this.state);
  };

  renderFoxyCartError = () => {
    return renderFoxyCartError(this.state);
  };

  renderEcommerceAppointmentModal = () => {
    return renderEcommerceAppointmentModal(this.state, this.updateState);
  };

  renderTimeSelectionModal = () => {
    return renderEcommerceTimeSelectionModal(this.state, this.updateState);
  };

  renderCompleteEcommerceModal = () => {
    return renderCompleteEcommerceModal(
      this.state,
      this.updateState,
      this.refs["workOrderNumber"]
    );
  };

  onRequestCloseModal = () => {
    this.props.dispatch(toggleShoppingModal(false));
    shoppingCartActions.closeCartModal();
  };

  emailQuote = () => {
    this.props.dispatch(shoppingCartOpenForm(formTypes.SEND));
    shoppingCartActions.openWishlistFormModal(formTypes.SEND);
  };

  printQuote = () => {
    this.props.dispatch(shoppingCartOpenForm(formTypes.PRINT));
    shoppingCartActions.openWishlistFormModal(formTypes.PRINT);
  };

  downloadQuote = () => {
    this.props.dispatch(shoppingCartOpenForm(formTypes.DOWNLOAD));
    shoppingCartActions.openWishlistFormModal(formTypes.DOWNLOAD);
  };

  onChange = () => {
    this.setState({
      // cartItems: shoppingCartViewStore.data.get('cartItems'),
      foxyCart: shoppingCartViewStore.data.get("foxyCart"),
      foxyCartError: shoppingCartViewStore.data.get("foxyCartError"),

      showWarning: shoppingCartStore.checkForWarnings(),
      showFinancingModal: shoppingCartViewStore.data.get("showFinancingModal"),
    });
  };
}

const mapStateToProps = (state) => {
  return {
    cart: state.shoppingCart.shoppingCart,
    cartItems: state.shoppingCart.cartItems,
    cartCount: state.shoppingCart.shoppingCart.length,
    expanded: state.shoppingCart.toggleCart,
    showFinancingModal: state.shoppingCart.showFinancingModal,
  };
};
export default connect(mapStateToProps)(WishlistModal);

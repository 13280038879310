import { applicationActions } from "../../actions/ApplicationActions.js";
import { Modal } from "../ux/Modal.jsx";

import { applicationStore } from "../../stores/ApplicationStore.js";
import { ProductTypes } from "../../constants/ProductTypes.js";

import { money_format, getAppLanguage } from "../../service/UtilityService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");

export class NationalPartPrice extends I18nComponent {
  state = {
    nationalPartPriceInfo: applicationStore.data.nationalPartPriceInfo,
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
  }

  render() {
    var msrp,
      dealerPrice,
      dealerPriceSavings,
      regionalPrice,
      regionalPriceSavings;

    msrp = money_format(this.state.nationalPartPriceInfo.partMSRP);
    dealerPrice = money_format(
      this.state.nationalPartPriceInfo.partDealerPrice
    );
    dealerPriceSavings = false;
    if (
      this.state.nationalPartPriceInfo.partMSRP >
      this.state.nationalPartPriceInfo.partDealerPrice
    ) {
      dealerPriceSavings = money_format(
        this.state.nationalPartPriceInfo.partMSRP -
          this.state.nationalPartPriceInfo.partDealerPrice
      );
    }
    regionalPrice = money_format(
      this.state.nationalPartPriceInfo.partRegionPrice
    );
    regionalPriceSavings = false;
    if (
      this.state.nationalPartPriceInfo.partMSRP >
      this.state.nationalPartPriceInfo.partRegionPrice
    ) {
      regionalPriceSavings = money_format(
        this.state.nationalPartPriceInfo.partMSRP -
          this.state.nationalPartPriceInfo.partRegionPrice
      );
    }

    var priceLabel = this.t("product.retailPrice");
    if (
      applicationStore.data.dealerSite &&
      applicationStore.data.dealerSellingPriceLabel[ProductTypes.TIRES][
        getAppLanguage()
      ]
    ) {
      priceLabel =
        applicationStore.data.dealerSellingPriceLabel[ProductTypes.TIRES][
          getAppLanguage()
        ];
    }

    return (
      <Modal refactor={true} requestUnmount={this.closeNationalPartStockModal}>
        <div className="wrapper">
          <div className="price-info-modal">
            <div className="price-info">
              <div className="part-price-header-info">
                <p>
                  {this.t("partPriceModal.partPriceModalTitle")} -{" "}
                  {this.t("product.partNumber")}{" "}
                  {applicationStore.data.dealerSite
                    ? this.state.nationalPartPriceInfo.manufacturerPartNumber
                    : this.state.nationalPartPriceInfo.nationalPartNumber}
                </p>
              </div>
              <div className="part-price-table">
                <table>
                  <thead>
                    <th>{this.t("partInfoModal.partNumber")}</th>
                    <th>{this.t("partInfoModal.msrp")}</th>
                    <th>{this.t("partInfoModal.regionalPrice")}</th>
                    <th>{priceLabel}</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {this.state.nationalPartPriceInfo.nationalPartNumber
                          ? this.state.nationalPartPriceInfo.nationalPartNumber
                          : this.state.nationalPartPriceInfo
                              .manufacturerPartNumber}
                      </td>
                      <td>
                        {this.state.nationalPartPriceInfo.partMSRP > 0 ? (
                          <div>{msrp}</div>
                        ) : (
                          this.t("partInfoModal.priceUnavailable")
                        )}
                      </td>
                      <td>
                        {this.state.nationalPartPriceInfo.partRegionPrice >
                        0 ? (
                          <div>
                            {regionalPrice}
                            {regionalPriceSavings ? (
                              <div className="savings">
                                {this.t("product.youSave") + " "}
                                {regionalPriceSavings}
                              </div>
                            ) : (
                              false
                            )}
                          </div>
                        ) : (
                          this.t("partInfoModal.priceUnavailable")
                        )}
                      </td>
                      <td>
                        {this.state.nationalPartPriceInfo.partDealerPrice >
                        0 ? (
                          <div>
                            {dealerPrice}
                            {dealerPriceSavings ? (
                              <div className="savings">
                                {this.t("product.youSave") + " "}
                                {dealerPriceSavings}
                              </div>
                            ) : (
                              false
                            )}
                          </div>
                        ) : (
                          this.t("partInfoModal.priceUnavailable")
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  onChange = () => {
    this.setState({
      nationalPartPriceInfo: applicationStore.data.nationalPartPriceInfo,
    });
  };

  closeNationalPartStockModal = () => {
    setTimeout(() => {
      applicationActions.closeNationalPartPriceModal();
    }, 10);
  };
}

import { DispatcherActions } from '../constants/DispatcherActions.js';
import { AbstractAction } from './AbstractAction.js';
import { applicationStore } from '../stores/ApplicationStore.js';

import { getAppDealerId } from '../service/UtilityService';

var BreadcrumbActions = AbstractAction.extend({

  pushState: function(state, id, value, unclickable, translatable) {
    unclickable = unclickable || false;

    this.dispatch(DispatcherActions.BREADCRUMBS_PUSH_STATE, {
      state: state,
      id: id,
      value: value,
      unclickable: unclickable,
      translatable: translatable
    });
  },

  loadBreadcrumbs: function(year = null, make = null, category = null, model = null, trim = null) {

    if (year === null && make === null && category === null && model === null && trim === null) {
      year = applicationStore.data.year;
      make = applicationStore.data.make;
      category = applicationStore.data.category;
      model = applicationStore.data.model;
      trim = applicationStore.data.trim;
    }

    setTimeout(() => {
      this.apiGet('/vehicle/getVehicleBreadcrumbs.php', {
        vehicleYearId: year,
        vehicleManufacturerId: make,
        vehicleCategoryId: category,
        vehicleModelId: model,
        vehicleSubmodelId: trim,
        dealerId: getAppDealerId(),
        isNational: applicationStore.data.nationalSite ? 1 : 0
      }).then(function (response) {
        this.dispatch(DispatcherActions.BREADCRUMBS_LOAD, response);
      }.bind(this));
    }, 0);
  }
});

export const breadcrumbActions = new BreadcrumbActions();

import { applicationActions } from '../actions/ApplicationActions.js';

export const checkLogin = () => {
  var user = getLogin();
  if (!user.error) setTimeout(() => {
    
     applicationActions.login(user.usr, user.pwd, false);
     }, 100);
};

export const setLogin = (usrnm, pswd) => {
  var d = new Date();
  d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = getLoginLabel() + "=" + encodeStrings(usrnm, pswd) + ";" + expires + ";path=/";
  document.cookie = getLoginDataLabel() + "=" + encodeStrings(usrnm, pswd) + ";" + expires + ";path=/";
};

export const getLogin = () => {
  if (checkLoginForUser()) {
    var name = getLoginLabel() + "=", ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0 && c.indexOf('-') > -1) {
        return decodeString(c.substring(name.length, c.length));
      }
    }
    return hasError();
  } else return hasError();
};

export const getLoginData = () => {
  if (checkLoginForUserData()) {
    var name = getLoginDataLabel() + "=", ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0 && c.indexOf('-') > -1) {
        return decodeString(c.substring(name.length, c.length));
      }
    }
    return hasError();
  } else return hasError();
};

const checkLoginForUser = () => {
  var name = getLoginLabel() + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return true;
    }
  }
  return false;
};

const checkLoginForUserData = () => {
  var name = getLoginDataLabel() + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return true;
    }
  }
  return false;
};

export const getLoginLabel = () => {
  return "__rzghlv.231";
};

export const getLoginDataLabel = () => {
  return "__dsxkpw.987";
};

const encodeStrings = (ta, tb) => {
  return btoa(ta) + "-" + btoa(tb);
};

const decodeString = (t) => {
  var text = t.split("-");
  return ({
    usr: atob(text[0]),
    pwd: atob(text[1]),
    error: false
  });
};

const hasError = () => {
  return ({
    usr: "",
    pwd: "",
    error: true
  });
};
import { applicationStore } from "../../stores/ApplicationStore.js";
import { applicationActions } from "../../actions/ApplicationActions.js";
import { productListStore } from "../../stores/ProductListStore.js";
import { productListActions } from "../../actions/ProductListActions.js";

import { Image } from "../ux/Image.jsx";
import { Modal } from "../ux/Modal.jsx";

import {
  sizeOf,
  getAppLanguage,
  sortTiresizes,
} from "../../service/UtilityService";
import {
  renderFilterMultiSelectSeasonSection,
  renderFilterMultiSelectTiresizeSection,
} from "../../service/FilterService";
import { captchaOnComponentDidMount } from "../../service/CaptchaService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var Immutable = require("immutable");
var $ = require("jquery");

export class SearchBrandsByBrandId extends I18nComponent {
  state = {
    brandId: applicationStore.data.selectedBrandId,
    brandName: applicationStore.data.selectedBrandName,
    dealerTireBrands: applicationStore.data.dealerTireBrands,
    tireModelArray: productListStore.data.tireModelArray,
    filterSeasons: applicationStore.data.dealerTireSeasons,
    filterTiresizes: productListStore.data.tireModelTiresizeArray,
    selectedSeason: [],
    selectedTiresize: [],
    selectedModel: null,
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    $(function () {
      $("body").animate({ scrollTop: 0 }, "slow");
    });
    this.getDefaultFilter();
    captchaOnComponentDidMount(true, false, this.loadBrandModelList, true);
  }

  componentDidUpdate(lastProps, lastState) {
    if (
      (sizeOf(this.state.filterTiresizes) > 0 &&
        sizeOf(lastState.filterTiresizes) === 0) ||
      (sizeOf(this.state.tireModelArray) > 0 &&
        sizeOf(lastState.tireModelArray) > 0 &&
        sizeOf(this.state.tireModelArray) !== sizeOf(lastState.tireModelArray))
    ) {
      this.getDefaultFilter();
    }
    if (this.state.brandId !== lastState.brandId) {
      this.loadBrandModelList();
    }
  }

  loadBrandModelList = (token = "") => {
    productListActions.loadBrandModelList(token);
  };

  getDefaultFilter = () => {
    var seasonKeys = [],
      tiresizeKeys = [];
    for (var i in this.state.filterSeasons) seasonKeys.push(i);
    for (var i in this.state.filterTiresizes)
      tiresizeKeys.push(this.state.filterTiresizes[i]["tireSizeSpecId"]);
    this.setState({
      selectedSeason: seasonKeys,
      selectedTiresize: tiresizeKeys,
    });
  };

  render() {
    var _this = this;

    var brandImage = false,
      selectedBrand = null,
      dealerTireBrands = this.state.dealerTireBrands;
    if (sizeOf(this.state.dealerTireBrands) > 0 && this.state.brandId > 0) {
      selectedBrand = this.state.dealerTireBrands[this.state.brandId];
      if (selectedBrand)
        brandImage = <Image src={selectedBrand.part_manufacturer_logo} />;
    }

    return (
      <div className="brand-tile-list">
        <div className="brand-title">
          <h2>
            {brandImage}{" "}
            {this.state.brandName && !brandImage
              ? this.state.brandName + " " + this.t("productTypes.tires")
              : ""}
          </h2>
        </div>
        <div className="brand-model-filter">
          <div className="filter-section season">
            {renderFilterMultiSelectSeasonSection(
              true,
              this.state.selectedSeason,
              this.state.filterSeasons,
              this.onSelectedAllSeasons,
              this.onUnselectAllSeasons,
              this.onSeasonSelected
            )}
          </div>

          <div className="filter-section tiresize">
            {renderFilterMultiSelectTiresizeSection(
              true,
              this.state.selectedTiresize,
              this.state.filterTiresizes,
              this.onSelectedAllTiresizes,
              this.onUnselectAllTiresizes,
              this.onTiresizeSelected
            )}
          </div>
        </div>
        <div className="brand-model-table">
          {Immutable.fromJS(this.state.tireModelArray)
            .filter(this.filterTireModels)
            .map(function (tireModel, index) {
              var brand =
                dealerTireBrands[tireModel.get("tire_manufacturer_id")];
              return (
                <div
                  key={"tire-model-" + index}
                  className={
                    index === 0
                      ? "tire-model-container first"
                      : "tire-model-container"
                  }
                >
                  <div className="tire-model">
                    <div className="left-side">
                      <div className="tire-model-image">
                        <Image
                          className="model-image"
                          src={tireModel.get("model_image")}
                        />
                      </div>
                    </div>

                    <div className="right-side">
                      <div className="tire-model-header">
                        <h2>
                          {brand.part_manufacturer_name +
                            " - " +
                            tireModel.get("model_name")}
                        </h2>
                      </div>

                      <div className="tire-model-description">
                        <p
                          className="model-description"
                          dangerouslySetInnerHTML={{
                            __html:
                              _this.t("product.description") +
                              ": " +
                              tireModel
                                .get("model_description")
                                .get(getAppLanguage()),
                          }}
                        ></p>
                      </div>

                      <div className="tire-model-controls">
                        <button
                          className="btn"
                          onClick={_this.onViewTiresizeSelected.bind(
                            _this,
                            tireModel.get("tire_model_id")
                          )}
                        >
                          {_this.t("viewTiresize")}
                        </button>
                        <button
                          className="btn"
                          onClick={_this.onModelSelected.bind(
                            _this,
                            tireModel.get("tire_model_id")
                          )}
                        >
                          {_this.t("selectModel")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        {this.renderTiresizeModal()}
      </div>
    );
  }

  renderTiresizeModal = () => {
    var selectedModel = this.state.selectedModel;
    if (selectedModel !== null) {
      var tiresizeArray = [];
      Immutable.fromJS(this.state.tireModelArray).map(function (tireModel) {
        if (tireModel.get("tire_model_id") === selectedModel) {
          tiresizeArray = tireModel.get("tire_size_array");
        }
      });
      if (tiresizeArray) {
        return (
          <Modal refactor={true} requestUnmount={this.closeTiresizeModal}>
            <div className="wrapper">
              <div className="modal-content tire-model-sizes">
                <div className="tire-size-header">
                  <h2>{this.t("tiresizeList")}</h2>
                </div>

                <div className="tire-size-list">
                  <ul>
                    {Immutable.fromJS(this.state.filterTiresizes)
                      .sort(sortTiresizes)
                      .map(function (tiresize, index) {
                        if (
                          tiresizeArray.indexOf(
                            tiresize.get("tireSizeSpecId").toString()
                          ) > -1
                        ) {
                          return (
                            <li key={"tire-size-list-" + index}>
                              {tiresize.get("formattedTiresize")}
                            </li>
                          );
                        } else {
                          return false;
                        }
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </Modal>
        );
      } else return false;
    } else return false;
  };

  closeTiresizeModal = () => {
    this.setState({
      selectedModel: null,
    });
  };

  filterTireModels = (tireModel) => {
    var isAllWeather = tireModel.get("all_weather") === "1",
      allowSeason = false,
      allowTiresize = false;
    if (isAllWeather) {
      if (
        this.state.selectedSeason.indexOf(0) > -1 ||
        this.state.selectedSeason.indexOf(1) > -1
      )
        allowSeason = true;
    } else {
      if (this.state.selectedSeason.indexOf(tireModel.get("season")) > -1)
        allowSeason = true;
    }

    var selectedTiresizeList = this.state.selectedTiresize,
      tiresizeArray = tireModel.get("tire_size_array");

    if (sizeOf(selectedTiresizeList) > 0 && sizeOf(tiresizeArray) > 0) {
      tiresizeArray.map(function (tiresize) {
        if (
          !allowTiresize &&
          selectedTiresizeList.indexOf(parseInt(tiresize)) > -1
        ) {
          allowTiresize = true;
        }
      });
    }

    return allowSeason && allowTiresize;
  };

  onViewTiresizeSelected = (modelId) => {
    this.setState({
      selectedModel: modelId,
    });
  };

  onModelSelected = (modelId) => {
    applicationActions.loadTireModelInfo("", modelId, "");
  };

  onSeasonSelected = (season) => {
    var selectedSeasons = this.state.selectedSeason;
    if (sizeOf(selectedSeasons) > 0 && selectedSeasons.indexOf(season) > -1) {
      //Remove from List
      var temp = [];
      for (var i in selectedSeasons) {
        if (selectedSeasons[i] !== season) {
          temp.push(selectedSeasons[i]);
        }
      }
      this.setState({
        selectedSeason: temp,
      });
    } else {
      //Add to List
      var temp = [];
      for (var i in selectedSeasons) {
        temp.push(selectedSeasons[i]);
      }
      temp.push(season);
      this.setState({
        selectedSeason: temp,
      });
    }
  };

  onSelectedAllSeasons = () => {
    var seasonKeys = [];
    for (var i in this.state.filterSeasons) seasonKeys.push(i);
    this.setState({
      selectedSeason: seasonKeys,
    });
  };

  onUnselectAllSeasons = () => {
    this.setState({
      selectedSeason: [],
    });
  };

  onTiresizeSelected = (tiresize) => {
    var selectedTiresizes = this.state.selectedTiresize;
    if (
      sizeOf(selectedTiresizes) > 0 &&
      selectedTiresizes.indexOf(tiresize) > -1
    ) {
      //Remove from List
      var temp = [];
      for (var i in selectedTiresizes) {
        if (selectedTiresizes[i] !== tiresize) {
          temp.push(selectedTiresizes[i]);
        }
      }
      this.setState({
        selectedTiresize: temp,
      });
    } else {
      //Add to List
      var temp = [];
      for (var i in selectedTiresizes) {
        temp.push(selectedTiresizes[i]);
      }
      temp.push(tiresize);
      this.setState({
        selectedTiresize: temp,
      });
    }
  };

  onSelectedAllTiresizes = () => {
    var tiresizeKeys = [];
    for (var i in this.state.filterTiresizes)
      tiresizeKeys.push(this.state.filterTiresizes[i]["tireSizeSpecId"]);
    this.setState({
      selectedTiresize: tiresizeKeys,
    });
  };

  onUnselectAllTiresizes = () => {
    this.setState({
      selectedTiresize: [],
    });
  };

  onChange = () => {
    this.setState({
      brandId: applicationStore.data.selectedBrandId,
      brandName: applicationStore.data.selectedBrandName,
      dealerTireBrands: applicationStore.data.dealerTireBrands,
      tireModelArray: productListStore.data.tireModelArray,
      filterSeasons: applicationStore.data.dealerTireSeasons,
      filterTiresizes: productListStore.data.tireModelTiresizeArray,
    });
  };
}

module.exports = {
    'common': {
        'viewTires': 'Search Tires Now',
        'viewWheels': 'Search Wheels Now',
        'viewAccessories': 'Search Accessories Now',
        'viewPackages': 'Search Packages Now'
    },
    'product': {
        'dealerPrice': 'National Price',
        'retailPrice': 'National Price'
    },
    'productTypes': {
        'tiresAlt': 'Tires for your Toyota or Scion',
        'wheelsAlt': 'Wheels for your Toyota or Scion',
        'accessoriesAlt': 'Accessories for your Toyota or Scion',
        'packagesAlt': 'Packages for your Toyota or Scion',

        'tires': 'Tires',
        'tiresTab': 'Tires',
        'searchTires': 'Tires',
        'tireSelectText': 'Toyota has an extensive selection of tires from Canada’s best selling brands.'
    }
};
import { applicationActions } from "../../actions/ApplicationActions.js";
import { dealerSelectionActions } from "../../actions/DealerSelectionActions.js";
import { dealerSelectionStore } from "../../stores/DealerSelectionStore.js";

import DealerMapLegacy from "./DealerMapLegacy.jsx";
import { Modal } from "../ux/Modal.jsx";
import { Image } from "../ux/Image.jsx";

import { applicationStore } from "../../stores/ApplicationStore.js";
import {
  getTabIndex,
  getAppLanguage,
  getTrackingEventCategory,
  checkSiteHasCampaign,
} from "../../service/UtilityService.js";
import {
  getProvinceSelect,
  defaultMapLocation,
} from "../../service/DealerSelectionService.js";
import { mapZoomChanged } from "../../redux/features/dealerSections.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var ReactDOM = require("react-dom");
var _ = require("lodash");
var $ = require("jquery");

const DEALER_NAME_SEARCH = "dealer-name-search";

export class DealerSelectionLegacy extends I18nComponent {
  state = {
    visibleDealers: dealerSelectionStore.visibleDealers,
    activeDealer: dealerSelectionStore.activeDealer,
    windowWidth: dealerSelectionStore.windowWidth,
    mapWidth: this.calculateMapWidth(),
    mapHeight: dealerSelectionStore.mapHeight,
    mapZoom: dealerSelectionStore.mapZoom,
    dealers: dealerSelectionStore.dealers,
    mapCenter: defaultMapLocation(),
    userLocation: dealerSelectionStore.userLocation,
    province: dealerSelectionStore.province,
    dealerSearch: dealerSelectionStore.dealerSearch,
    dealerSearchFlag: dealerSelectionStore.dealerSearchFlag,
    searchWasEmptyFlag: dealerSelectionStore.searchWasEmptyFlag,
    searchType: dealerSelectionStore.searchType,
    showChangingSiteWarningModal: false,
    searchResults: "",
    dealerIndexSearched: "",
  };

  initializeUserLocation = () => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.foundLocation,
        this.noLocation
      );
    }
  };

  foundLocation = (position) => {
    var GoogleMaps = window.google;
    var GoogleGeoCoder = new GoogleMaps.maps.Geocoder();

    var positionArray = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };

    this.state.userLocation = positionArray;
    this.state.mapCenter = positionArray;
    this.props.dispatch(mapZoomChanged(9));
    // dealerSelectionActions.mapZoomChanged(9);

    GoogleGeoCoder.geocode({ location: positionArray }, this.saveUserLocation);
  };

  noLocation = () => {
    //default location
    this.state.userLocation = defaultMapLocation();
    this.state.mapCenter = defaultMapLocation();
  };

  saveUserLocation = (results, status) => {
    if (status === "OK") {
      var province = null;
      for (var i = 0; i < results[0].address_components.length; i++) {
        for (
          var b = 0;
          b < results[0].address_components[i].types.length;
          b++
        ) {
          if (
            results[0].address_components[i].types[b] ==
            "administrative_area_level_1"
          ) {
            province = results[0].address_components[i].short_name;
            break;
          }
        }
      }
      this.state.province = province;
      this.setInitialUserLocation(
        province,
        this.state.userLocation,
        this.state.mapCenter
      );
    }
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("Dealer Selection");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Dealer Selection Access"
    );
    dealerSelectionStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    dealerSelectionStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    window.onresize = this.updateWindow;

    var hash = window.location.hash;
    if (hash && !checkSiteHasCampaign()) {
      var splitParams = hash.split("?");
      if (splitParams.constructor == Array && 1 in splitParams) {
        var params = splitParams[1].split("=");
        if (params.constructor == Array && 0 in params && 1 in params) {
          var key = params[0];
          var value = params[1];

          console.log("DealerSelectionLegacy - setWidgetSearch");

          applicationActions.setWidgetSearch(key, value);
        }

        var disclaimer = this.t("launcher.defaultDisclaimer");
        if (disclaimer !== "") {
          this.setState({ showChangingSiteWarningModal: true });
        }
      }

      window.location.hash = "/" + getAppLanguage();
    }

    dealerSelectionActions.loadListOfDealers();
    //setTimeout(() => { dealerSelectionActions.loadListOfDealers(); }, 100);
  }

  componentDidUpdate() {
    if (this.refs.selectDealerButton)
      ReactDOM.findDOMNode(this.refs.selectDealerButton).focus();
  }

  updateWindow = () => {
    setTimeout(() => {
      this.props.dispatch;
    }, 10);
  };

  render() {
    return (
      <div className="main">
        {this.state.showChangingSiteWarningModal
          ? this.renderDisclaimerModal()
          : false}
        {this.state.activeDealer ? this.renderDealerModal() : false}
        <div className="container">
          {this.renderMapFilter()}
          <div className="select-dealer-legacy">
            <div className="dealer-map">
              <DealerMapLegacy
                zoom={this.state.mapZoom}
                center={this.state.mapCenter}
                width={this.state.mapWidth}
                height={this.state.mapHeight}
                dealers={this.state.visibleDealers}
                userLocation={this.state.userLocation}
                activeDealer={this.state.activeDealer}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDisclaimerModal = () => {
    var disclaimer = (
      <div className="disclaimer">
        <h2>this.t('launcher.disclaimer')</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: this.t("launcher.defaultDisclaimer"),
          }}
        ></p>
      </div>
    );
    switch (applicationStore.data.defaultTemplate) {
      case "gmcanada":
        disclaimer = (
          <div className="disclaimer">
            <h2>{this.t("launcher.disclaimer")}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: this.t("launcher.disclaimer1"),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: this.t("launcher.disclaimer2"),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: this.t("launcher.disclaimer3"),
              }}
            ></p>
          </div>
        );
        break;
      default:
        break;
    }
    return (
      <Modal>
        <div className="wrapper">
          <div className="modal-content national-map-disclaimer">
            <Image
              className="modal-close"
              src="/static/img/close.png"
              onClick={this.closeMapDisclaimer}
              alt="Icon Close"
            />
            {disclaimer}
          </div>
        </div>
      </Modal>
    );
  };

  closeMapDisclaimer = () => {
    this.setState({
      showChangingSiteWarningModal: false,
    });
  };

  renderMapFilter = () => {
    var nationalAccountName =
      applicationStore.data.nationalAccountName[0].toUpperCase() +
      applicationStore.data.nationalAccountName.slice(1);

    var pleaseSelectProvince = false;
    if (!this.state.province && !this.state.dealerSearch) {
      pleaseSelectProvince = (
        <span className="dealer-province-error">
          {this.t("dealerFilter.pleaseSelectProvince")}
        </span>
      );
    }

    var searchWasEmpty = false;
    if (this.state.searchWasEmptyFlag) {
      searchWasEmpty = (
        <span className="dealer-search-error">
          {this.t("dealerFilter.searchResultsEmpty")}
        </span>
      );
    }

    var dealerSelectionDropDown = false,
      _this = this;
    if (
      this.state.dealerSearch !== "" &&
      this.state.searchType === DEALER_NAME_SEARCH &&
      this.state.visibleDealers.size > 1
    ) {
      var dropdownExpandedSize =
        this.state.visibleDealers.size <= 25
          ? this.state.visibleDealers.size
          : 25;
      dealerSelectionDropDown = (
        <div className="dealer-selection-dropdown">
          <ul id="select-dealer-dropdown" size={dropdownExpandedSize}>
            {this.state.visibleDealers
              .sort(function (a, b) {
                if (a.get("name") > b.get("name")) return 1;
                else if (a.get("name") < b.get("name")) return -1;
                else return 0;
              })
              .map(function (dealer, index) {
                return (
                  <li
                    key={dealer.get("id")}
                    tabIndex={getTabIndex()}
                    label={dealer.get("name")}
                    value={dealer}
                    onClick={_this.onDealerModalSelected.bind(_this, dealer)}
                    onKeyDown={(e) =>
                      _this.onDealerModalSelectedOnKeyDown(e, dealer)
                    }
                  >
                    {dealer.get("name")}
                  </li>
                );
              })}
          </ul>
        </div>
      );
    }

    return (
      <div className="dealer-filter-legacy">
        <div className="dealer-filter-title" tabIndex={getTabIndex()}>
          <span className="title">{this.t("dealerFilter.titlePrefix")}</span>{" "}
          <span>{nationalAccountName}</span>{" "}
          <span>{this.t("dealerFilter.titleSuffix")}</span>
        </div>
        <div className="dealer-filter-reset">
          <button
            type="button"
            className="btn"
            onClick={this.onDealerReset}
            tabIndex="-1"
          >
            {this.t("dealerFilter.reset")}
          </button>
        </div>
        <div className="dealer-filter-select-province">
          <span className="dealer-filter-province">
            {this.t("dealerFilter.chooseProvince")}
          </span>
          {getProvinceSelect(
            applicationStore.data.nationalCountryCode,
            getAppLanguage(),
            this.onProvinceSelect,
            this.state.province
          )}
          {pleaseSelectProvince}
        </div>
        <div className="dealer-filter-select-dealer-name">
          <form onSubmit={this.onDealerNameSearch}>
            <span className="dealer-filter-dealer-name">
              {this.t("dealerFilter.enterDealerName")}
            </span>
            <input
              id="dealer-name-input"
              className="dealer-filter-dealer-name-value"
              tabIndex={getTabIndex()}
              autoComplete="off"
              title={this.t("dealerFilter.enterDealerName")}
              alt={this.t("dealerFilter.enterDealerName")}
              type="text"
              onChange={this.onChangeDealerName}
            ></input>
            {dealerSelectionDropDown}
            <button type="submit" className="btn">
              {this.t("dealerFilter.search")}
            </button>
            {searchWasEmpty}
          </form>
        </div>
        <div className="dealer-filter-select-dealer-name">
          <form onSubmit={this.onDealerCitySearch}>
            <span className="dealer-filter-dealer-name">
              {this.t("dealerFilter.enterDealerCity")}
            </span>
            <input
              id="dealer-city-input"
              className="dealer-filter-dealer-city-value"
              type="text"
              title={this.t("dealerFilter.enterDealerCity")}
              alt={this.t("dealerFilter.enterDealerCity")}
            ></input>
            <button type="submit" className="btn">
              {this.t("dealerFilter.search")}
            </button>
            {searchWasEmpty}
          </form>
        </div>
      </div>
    );
  };

  onChangeDealerName = (ev) => {
    if (
      typeof document.getElementsByClassName(
        "dealer-filter-dealer-name-value"
      ) !== "undefined" &&
      document.getElementsByClassName("dealer-filter-dealer-name-value") !==
        null
    ) {
      this.setState({ searchResults: "dealer-filter-dealer-name-value" });
      dealerSelectionActions.dealerNameSearched(
        document.getElementsByClassName("dealer-filter-dealer-name-value")
      );
    }
    ev.preventDefault();
    ev.stopPropagation();
  };

  renderDealerModal = () => {
    var dealer = this.state.activeDealer;

    var dealerEmail = dealer.get("email");
    var dealerPhone = dealer.get("phone");
    var dealerWebsite = dealer.get("website");
    var dealerHours = dealer.get("hours");

    var email = false;
    if (
      dealerEmail !== null &&
      typeof dealerEmail !== "undefined" &&
      dealerEmail != ""
    ) {
      email = dealerEmail;
    }

    var phone = false;
    if (
      dealerPhone !== null &&
      typeof dealerPhone !== "undefined" &&
      dealerPhone != ""
    ) {
      phone = dealerPhone;
    }

    var website = false;
    if (
      dealerWebsite !== null &&
      typeof dealerWebsite !== "undefined" &&
      dealerWebsite != ""
    ) {
      website = dealerWebsite; // dealer website needs added into the webservice
    }

    var hours = false;
    if (dealerHours !== null && typeof dealerHours !== "undefined") {
      //hours = true; //dealer hours in the modal has been turned off
    }

    var image =
      "http://images.tirelocator.ca.s3.amazonaws.com/" +
      dealer.get("template") +
      "/manufacturer_logo.png";

    return (
      <Modal>
        <div className="wrapper">
          <form onSubmit={this.onDealerSelected.bind(this, dealer)}>
            <div className="modal-content modal-map-info" aria-live="assertive">
              <Image
                className="modal-close"
                src="/static/img/close.png"
                onClick={this.onDealerUnselected}
                alt="Icon Close"
              />
              {false ? (
                <div className="dealer-image">
                  <Image src={image} alt="Dealer Image" />
                </div>
              ) : (
                ""
              )}
              <div className="dealer-info">
                <p className="dealer-name">{dealer.get("name")}</p>
                <p className="dealer-address">
                  {dealer.get("address")} {dealer.get("city")}{" "}
                  {dealer.get("province")}, {dealer.get("postalCode")}
                  {phone ? (
                    <span className="dealer-phone">
                      <Image src="/static/img/phone.png" alt="Icon Phone" />{" "}
                      {phone}
                    </span>
                  ) : (
                    ""
                  )}
                  {email ? (
                    <span className="dealer-email">
                      <Image src="/static/img/email.png" alt="Icon Email" />{" "}
                      {email}
                    </span>
                  ) : (
                    ""
                  )}
                  {website ? (
                    <span className="dealer-website">
                      <a
                        href={website}
                        tabIndex={getTabIndex()}
                        target="_blank"
                      >
                        <Image
                          src="/static/img/website.png"
                          alt="Icon Website"
                        />{" "}
                        {website}
                      </a>
                    </span>
                  ) : (
                    ""
                  )}
                  {hours ? (
                    <table className="dealer-hours">
                      <tr>
                        <th>{this.t("dealerSelect.day")}</th>
                        <th>{this.t("dealerSelect.open")}</th>
                        <th>{this.t("dealerSelect.close")}</th>
                      </tr>
                      <tr>
                        <td>
                          {dealerHours
                            .get("MON")
                            .get("day")
                            .get(getAppLanguage())}
                        </td>
                        <td>{dealerHours.get("MON").get("open")}</td>
                        <td>{dealerHours.get("MON").get("close")}</td>
                      </tr>
                      <tr>
                        <td>
                          {dealerHours
                            .get("TUE")
                            .get("day")
                            .get(getAppLanguage())}
                        </td>
                        <td>{dealerHours.get("TUE").get("open")}</td>
                        <td>{dealerHours.get("TUE").get("close")}</td>
                      </tr>
                      <tr>
                        <td>
                          {dealerHours
                            .get("WED")
                            .get("day")
                            .get(getAppLanguage())}
                        </td>
                        <td>{dealerHours.get("WED").get("open")}</td>
                        <td>{dealerHours.get("WED").get("close")}</td>
                      </tr>
                      <tr>
                        <td>
                          {dealerHours
                            .get("THU")
                            .get("day")
                            .get(getAppLanguage())}
                        </td>
                        <td>{dealerHours.get("THU").get("open")}</td>
                        <td>{dealerHours.get("THU").get("close")}</td>
                      </tr>
                      <tr>
                        <td>
                          {dealerHours
                            .get("FRI")
                            .get("day")
                            .get(getAppLanguage())}
                        </td>
                        <td>{dealerHours.get("FRI").get("open")}</td>
                        <td>{dealerHours.get("FRI").get("close")}</td>
                      </tr>
                      <tr>
                        <td>
                          {dealerHours
                            .get("SAT")
                            .get("day")
                            .get(getAppLanguage())}
                        </td>
                        <td>{dealerHours.get("SAT").get("open")}</td>
                        <td>{dealerHours.get("SAT").get("close")}</td>
                      </tr>
                      <tr>
                        <td>
                          {dealerHours
                            .get("SUN")
                            .get("day")
                            .get(getAppLanguage())}
                        </td>
                        <td>{dealerHours.get("SUN").get("open")}</td>
                        <td>{dealerHours.get("SUN").get("close")}</td>
                      </tr>
                    </table>
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <div className="dealer-controls">
                <button
                  type="submit"
                  className="btn"
                  tabIndex={getTabIndex()}
                  ref="selectDealerButton"
                  onClick={this.onDealerSelected.bind(this, dealer)}
                >
                  {this.t("common.select")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    );
  };

  onDealerModalSelected = (dealer) => {
    dealerSelectionActions.clickedOnDealer(dealer);
  };

  onDealerModalSelectedOnKeyDown = (evt, dealer) => {
    checkForEvent: if ("undefined" != typeof evt.keyCode) {
      if (evt.keyCode == 13 || evt.keyCode == 32) {
        evt.preventDefault();
        evt.stopPropagation();
        break checkForEvent;
      }
      return;
    }
    dealerSelectionActions.clickedOnDealer(dealer);
  };

  onDealerSelected = (dealer, ev) => {
    applicationActions.selectedDealer(
      dealer.get("id"),
      dealer.get("season"),
      null
    );
    ev.preventDefault();
    ev.stopPropagation();
  };

  onDealerUnselected = () => {
    dealerSelectionActions.unselectDealer();
  };

  setInitialUserLocation = (province, userLocation, mapCenter) => {
    dealerSelectionActions.mapSetInitialUserLocation(
      province,
      userLocation,
      mapCenter
    );
  };

  onProvinceSelect = (province) => {
    document.getElementsByClassName("dealer-filter-dealer-name-value").value =
      "";
    document.getElementById("dealer-name-input").value = "";
    document.getElementsByClassName("dealer-filter-dealer-city-value").value =
      "";
    document.getElementById("dealer-city-input").value = "";
    dealerSelectionActions.dealerNameReset();
    dealerSelectionActions.provinceSelected(province);
  };

  onDealerNameSearch = (ev) => {
    if (typeof $("option:selected")[0] !== "undefined") {
      var dealer = this.state.visibleDealers.filter(
        (dealer) => dealer.get("name") === $("option:selected")[0].innerHTML
      );
      dealer = dealer.get(0);
      applicationActions.selectedDealer(
        dealer.get("id"),
        dealer.get("season"),
        null
      );
      this.setState({
        dealerIndexSearched: $("option:selected")[0].innerHTML,
      });
      // dealerSelectionActions.dealerIndexSearched($('option:selected')[0].innerHTML);
    } else {
      if (this.state.activeDealer) {
        applicationActions.selectedDealer(
          this.state.activeDealer.get("id"),
          this.state.activeDealer.get("season"),
          null
        );
      } else {
        dealerSelectionActions.dealerNameSearched(
          document.getElementsByClassName("dealer-filter-dealer-name-value")
        );
      }
    }
    ev.preventDefault();
    ev.stopPropagation();
  };

  onDealerCitySearch = (ev) => {
    dealerSelectionActions.dealerCitySearched(
      document.getElementsByClassName("dealer-filter-dealer-city-value")
    );
    ev.preventDefault();
    ev.stopPropagation();
  };

  onDealerReset = () => {
    document.getElementsByClassName("dealer-filter-dealer-name-value").value =
      "";
    document.getElementById("dealer-name-input").value = "";
    document.getElementsByClassName("dealer-filter-dealer-city-value").value =
      "";
    document.getElementById("dealer-city-input").value = "";
    dealerSelectionActions.dealerNameReset();
  };

  onChange = () => {
    this.setState({
      visibleDealers: dealerSelectionStore.visibleDealers,
      activeDealer: dealerSelectionStore.activeDealer,
      windowWidth: dealerSelectionStore.windowWidth,
      mapWidth: this.calculateMapWidth(),
      mapHeight: dealerSelectionStore.mapHeight,
      mapCenter:
        dealerSelectionStore.mapCenter !== null
          ? dealerSelectionStore.mapCenter
          : this.state.mapCenter,
      mapZoom: dealerSelectionStore.mapZoom,
      dealers: dealerSelectionStore.dealers,
      province: dealerSelectionStore.province,
      dealerSearch: dealerSelectionStore.dealerSearch,
      dealerSearchFlag: dealerSelectionStore.dealerSearchFlag,
      searchWasEmptyFlag: dealerSelectionStore.searchWasEmptyFlag,
      searchType: dealerSelectionStore.searchType,
    });
  };

  calculateMapWidth = () => {
    var width = null;
    if (window.innerWidth < 700) {
      width = (window.innerWidth - 20) * 0.94;
    } else if (window.innerWidth < 1024) {
      width = window.innerWidth - 20;
    } else {
      width = (window.innerWidth - 20) * 0.8;
    }
    return parseInt(width, 10);
  };

  calculateMapHeight = () => {
    if (window.innerWidth > 1410) {
      return dealerSelectionStore.mapHeight + 100;
    } else if (window.innerWidth > 750) {
      return dealerSelectionStore.mapHeight;
    } else {
      return 0.95 * window.innerWidth;
    }
  };

  onLocationChanged = (place) => {
    if (place.geometry) {
      dealerSelectionActions.locationChanged(
        place.geometry.location,
        place.geometry.viewport
      );
    }
  };
}

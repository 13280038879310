import { applicationStore } from '../stores/ApplicationStore.js';
import { languageStore } from '../stores/LanguageStore.js';

import { ProductTypes } from '../constants/ProductTypes';
import { logoList } from '../constants/PdfGuideLogo';

import { buildHeader, buildLegalFooter, getStyles, templateBtnClr } from "./PdfService";
import { isEmpty, sizeOf, getAppTemplate, money_format, getAppLanguage, firstToUpper } from '../service/UtilityService';

var _ = require('lodash');

/*
 *
 *  GUIDE PDF
 *
 */

const PAGE_WIDTH = 595.28;
const PAGE_HEIGHT = 841.89;

const IMAGE_NATURAL_WIDTH = 640;
const IMAGE_NATURAL_HEIGHT = 400;

const IMAGE_RATIO = 0.5;

//const IMAGE_COLUMN_WIDTH = '72.5%';
//const INFO_COLUMN_WIDTH = '27.5%';

const IMAGE_COLUMN_WIDTH = 320;
const INFO_COLUMN_WIDTH = 262.5;

export const getGuidePdfFilename = () => {
  var template = applicationStore.data.defaultTemplate.toUpperCase();
  var productType = firstToUpper(applicationStore.data.productType);
  return productType + " " + "Guide" + " - " + template + ".pdf";
};

export const getGuideDocDefinition = (dealerInfo, encodedImageList, partList, vehicleInfo) => {
  var hasCustomLogo = !isEmpty(applicationStore.data.dealerRetailSiteCustomLogo) && getAppTemplate() !== 'mazdatransition' ? true : false;
  var logo = !hasCustomLogo ? logoList[getAppTemplate()] : encodedImageList.logo;

  var document = {
    pageSize: { width: PAGE_WIDTH,  height: PAGE_HEIGHT, },
    background: getDocumentBackground(),
    pageMargins: [ 5, 5, 5, 2.5 ],
    content: [
      buildTitleTable(encodedImageList, vehicleInfo, partList),
      buildGuideTable(hasCustomLogo, dealerInfo, encodedImageList, partList),
      buildLegalFooter(true),
    ],
    styles: getStyles(),
    defaultStyle: { font: getAppTemplate(), },
    images: { logo: logo },
  };

  return (
    document
  );
};

const getDocumentBackground = () => {
  return {
    canvas: [
      {
        type: 'rect',
        x: 0, y: 0, w: PAGE_WIDTH, h: PAGE_HEIGHT,
        color: '#55565A',
      }
    ]
  };
};

const buildTitleTable = (encodedImageList, vehicleInfo, partList) => {

  var table = [];

  table.push(
    {
      style: 'titleTable',
      table: {

        headerRows: 0,
        widths: [ 565 ],
        body: getTitleBody(encodedImageList, vehicleInfo),

      },
      layout: getTitleTableLayout(),
    }
  );

  return table;

};

const getTitleBody = (encodedImageList, vehicleInfo) => {

  var body = [];

  body.push([
    renderTitleImage(encodedImageList),
  ]);

  body.push([
    renderTitleVehicle(vehicleInfo),
  ]);

  body.push([
    renderTitleProduct(),
  ]);


  return body;
};

const renderTitleImage = (encodedImageList) => {
  var hasCustomLogo = !isEmpty(applicationStore.data.dealerRetailSiteCustomLogo) && getAppTemplate() !== 'mazdatransition' ? true : false;
  var logo = !hasCustomLogo ? logoList[getAppTemplate()] : encodedImageList.logo;
  if (!isEmpty(logo)) {
    return({
      image: 'logo',
      margin: [ 0, 100, 0, 75 ],
      width: 65,
      alignment: 'center',
    });
  } else {
    return({
      fontSize: 12,
      margin: [ 0, 100, 0, 75 ],
      text: '',
    });
  }
};

const renderTitleVehicle = (vehicleInfo) => {
  var year = "", model = "";
  for (var i in vehicleInfo) {
    if (vehicleInfo[i] && vehicleInfo[i].state == "YEAR") {
      year = vehicleInfo[i].value;
    } else if (vehicleInfo[i] && vehicleInfo[i].state == "MODEL") {
      model = vehicleInfo[i]['value'][getAppLanguage()];
    }
  }
  if (!isEmpty(year) && !isEmpty(model)) {
    var text = year + " " + model;
    return({
      fontSize: 20,
      margin: [ 0, 0, 0, 100 ],
      text: text,
      color: '#FFFFFF',
      alignment: 'center',
    });
  } else {
    return({
      fontSize: 20,
      margin: [ 0, 0, 0, 100 ],
      text: '',
    });
  }
};

const renderTitleProduct = () => {
  var dictionary = languageStore.getDictionary();
  return({
    fontSize: 14,
    margin: [ 0, 17.5, 0, 17.5 ],
    text: dictionary.productTypes.accessories.toUpperCase(),
    color: '#FFFFFF',
    alignment: 'center',
  });
};

const buildGuideTable = (hasCustomLogo, dealerInfo, encodedImageList, partList) => {

  var tables = [];

  for (var i in partList) {

    if (partList[i]) {

      var image = '';
      for (var j in encodedImageList) {
        if (partList[i].partDetails.primaryPart.itemId == j) {
          image = encodedImageList[j];
          break;
        }
      }

      tables.push(
        {
          style: 'guideTable',
          table: {

            headerRows: 1,
            widths: i % 2 == 0 ? [ IMAGE_COLUMN_WIDTH, INFO_COLUMN_WIDTH ] : [ INFO_COLUMN_WIDTH, IMAGE_COLUMN_WIDTH ],
            body: getGuideBody(i, image, partList[i]),

          },
          layout: getGuideTableLayout(i),
        }
      );

    }

  }

  return tables;

};

const getTitleTableLayout = () => {
  return (
    {
      hLineWidth: function (i, node) {
          if (i === node.table.body.length) {
            return 1;
          } else if (i === (node.table.body.length - 1)) {
            return 2;
          } else {
            return 0;
          }
        },
      vLineWidth: function (i, node) { return 1; },
      hLineColor: function (i, node) { return (i === (node.table.body.length - 1)) ? '#FFFFFF' : '#444444'; },
      vLineColor: function (i, node) { return '#444444'; },
      fillColor: function (i, node) { return '#55565A'; },
      paddingLeft: function (i, node) { return 0; },
      paddingRight: function (i, node) { return 0; },
      paddingTop: function (i, node) { return 0; },
      paddingBottom: function (i, node) { return 0; },
    }
  );
};

const getGuideTableLayout = (index) => {
  return (
    {
      hLineWidth: function (i, node) { return 1; },
      vLineWidth: function (i, node) { return 0; },
      hLineColor: function (i, node) { return '#55565A'; },
      vLineColor: function (i, node) { return '#CDCDCD'; },
      fillColor: function (i, node) { return '#FFFFFF'; },
      paddingLeft: function (i, node) { return 0; },
      paddingRight: function (i, node) { return 0; },
      paddingTop: function (i, node) { if (index % 2 == 0 && i == 1) return 2; else if (index % 2 == 1 && i == 0) return 1; else return 0; },
      paddingBottom: function (i, node) { return 0; },
    }
  );
};

const getGuideBody = (i, image, part) => {

  var body = [];

  if (part) {

    if (i % 2 == 0) {
      body.push([
        renderGuideImage(i, image),
        renderGuideInfo(part),
      ]);
    } else {
      body.push([
        renderGuideInfo(part),
        renderGuideImage(i, image),
      ]);
    }

  }


  return body;
};

const renderGuideImage = (i, image) => {
  if (!isEmpty(image)) {
    return({
      image: image,
      margin: i % 2 == 0 ? [ 0, 0, 0, -1 ] : [ 0, 0, 0, 0 ],
      width: IMAGE_RATIO * IMAGE_NATURAL_WIDTH,//0.715 * PAGE_WIDTH,
      alignment: i % 2 == 0 ? 'left' : 'right',
    });
  } else {
    return({
      fontSize: 12,
      margin: [ 0, 0, 0, 0 ],
      width: IMAGE_RATIO * IMAGE_NATURAL_WIDTH,//0.715 * PAGE_WIDTH,
      text: '',
    });
  }
};

const renderGuideInfo = (part) => {
  if (!isEmpty(part)) {
    var dictionary = languageStore.getDictionary(), price = part.partPricing.primaryPart.price, context = {}, contextList, total, description, title;
    for (var i in price) {
      if (price[i]) {
        contextList = price[i].contextList;
        break;
      }
    }
    for (var i in contextList) {
      if (contextList[i]) {
        context = contextList[i];
        break;
      }
    }
    if (!isEmpty(context)) {
      total = parseFloat(context.displayPrice) + parseFloat(context.displayLabour);
    }
    if (part.partDetails.primaryPart.accessoryDescription) {
      description = part.partDetails.primaryPart.accessoryDescription[getAppLanguage()];
    }
    if (part.partDetails.primaryPart.accessoryName) {
      title = part.partDetails.primaryPart.accessoryName[getAppLanguage()];
    }
    return([
      {
        fontSize: 17,
        margin: [ 10, 12.5, 10, 2.5 ],
        text: title,
      },
      {
        fontSize: 9,
        margin: getPartNumberMargins(title, description),
        text: dictionary.product.partNumber + ': ' + part.partDetails.primaryPart.accessoryPartNumber,
      },
      {
        fontSize: 10,
        margin: [ 10, 0, 10, 5 ],
        text: dictionary.product.price.toUpperCase() + ': ' + (!isEmpty(context) ? money_format(context.displayPrice) : ""),
      },
      {
        fontSize: 10,
        margin: [ 10, 0, 10, 5 ],
        text: dictionary.product.installationLong.toUpperCase() + ': ' + (!isEmpty(context) && parseFloat(context.displayLabour) > 0 ? money_format(context.displayLabour) : dictionary.notApplicable),
      },
      {
        fontSize: 10,
        margin: [ 10, 0, 10, 0 ],
        text: dictionary.product.total.toUpperCase() + ': ' + (!isEmpty(context) ? money_format(total) : ""),
      },
      {
        fontSize: 8,
        margin: getDescriptionMargins(title, description),
        text: description,
      },
    ]);
  } else {
    return({
      margin: [ 0, 5, 0, 5 ],
      text: '',
    });
  }
};

const getPartNumberMargins = (title, description) => {

  if (title.length > 26 && description.length > 465) {
    return [ 10, 0, 10, 7.5 ];
  } else if (title.length > 26 || description.length > 465) {
    return [ 10, 0, 10, 10 ];
  } else {
    return [ 10, 0, 10, 12.5 ];
  }

};

const getDescriptionMargins = (title, description) => {

  if (title.length > 26 && description.length > 465) {
    return [ 10, 7.5, 10, 2.5 ];
  } else if (title.length > 26 || description.length > 465) {
    return [ 10, 10, 10, 12.5 ];
  } else {
    return [ 10, 12.5, 10, 15 ];
  }

};

import { applicationStore } from "../stores/ApplicationStore";
import { shoppingCartViewStore } from "../stores/ShoppingCartViewStore";
import { languageStore } from "../stores/LanguageStore";

import { applicationActions } from "../actions/ApplicationActions";
import { shoppingCartActions } from "../actions/ShoppingCartActions";

import { Router } from "../stores/Router";

import { Image } from "../components/ux/Image.jsx";
import { Modal } from "../components/ux/Modal.jsx";
import WishlistReviewItem from "../components/cart/WishlistReviewItem.jsx";

import { WishlistReviewSummaryTable } from "../components/cart/WishlistReviewSummaryTable.jsx";

import { ProductTypes } from "../constants/ProductTypes";

import { Calendar } from "../components/calendar/Calendar.jsx";
import { TextField } from "../components/ux/forms/TextField.jsx";

import { collectWishlistBase64Images, PDFLauncher } from "./PrintService";
import { checkNTDStockExists, getOrderIdentifier } from "./OrderService";
import { getDestination } from "../api/RequestBuilderHelper";
import {
  convertDateToFrench,
  isDateAvailable,
  timePadMinutes,
} from "./CalendarService";
import {
  formTypes,
  getAppDealerId,
  getAppLanguage,
  getAppTemplate,
  getDealerName,
  getDealerUrlCode,
  getHrefPhoneFormat,
  getTabIndex,
  getTrackingEventCategory,
  getTrackingEventOrderId,
  getVehicleName,
  hasVehicle,
  isDealerSite,
  isEdgeBrowser,
  isEmpty,
  isGroupSite,
  isIEBrowser,
  isNationalSite,
  money_format,
  sizeOf,
} from "./UtilityService";
import { isUserLoggedIn } from "../api/AuthService";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "./AdobeLaunchService";
import {
  getNewCart,
  shoppingCartOpenForm,
} from "../redux/features/shoppingCart";
import store from "../stores/configureStore";
import { renderSavingToggleState } from "../redux/features/productList";

var React = require("react");
var Immutable = require("immutable");
var Cx = require("classnames");
var Moment = require("moment");
var Timepicker = require("react-timepicker");

export const loadWishlistWithContext = async () => {
  console.log("loadWishlistWithContext: ");
  var shoppingContext = Router.collectShoppingContext();
  if (shoppingContext.isWishlist) {
    await shoppingCartActions.loadWishlistReview();
    await store.dispatch(renderSavingToggleState(true));
  } else if (shoppingContext.isQuote) {
    await shoppingCartActions.loadWishlistReview();
    await store.dispatch(renderSavingToggleState(true));
  } else if (shoppingContext.isOrder) {
    await new Promise((resolve) =>
      setTimeout(() => {
        shoppingCartActions.loadDealerOrder();
        store.dispatch(renderSavingToggleState(true));
        resolve();
      }, 100)
    );
  } else if (shoppingContext.isEcommerce) {
    await new Promise((resolve) =>
      setTimeout(() => {
        shoppingCartActions.loadConsumerEcommerce();
        store.dispatch(renderSavingToggleState(true));
        resolve();
      }, 100)
    );
  }
};

export const getSupportConsumerOrder = () => {
  return store.getState().shoppingCart.supportConsumerOrder;
};
export const getQuoteTrackingLabelInfo = (label, orderItemList = null) => {
  var temp = " - ",
    list = shoppingCartViewStore.data.get("cartItems");

  if (!isEmpty(list)) {
    list.map(function (typelist, type) {
      switch (type.toLowerCase()) {
        case "accessories":
          temp += "Accessories" + ": ";
          break;
        case "alloy_wheels":
          temp += "Wheels" + ": ";
          break;
        case "tires":
          temp += "Tires" + ": ";
          break;
        case "packages":
          temp += "Packages" + ": ";
          break;
      }

      typelist.map(function (item) {
        temp += "{";

        switch (type) {
          case ProductTypes.TIRES:
            temp +=
              item
                .get("itemPart")
                .get("partDetails")
                .get("primaryPart")
                .get("tireManufacturerName") +
              " " +
              item
                .get("itemPart")
                .get("partDetails")
                .get("primaryPart")
                .get("tireModelName") +
              " " +
              item
                .get("itemPart")
                .get("partDetails")
                .get("primaryPart")
                .get("tireManufacturerPartNumber");
            break;
          case ProductTypes.ALLOY_WHEELS:
            temp +=
              item
                .get("itemPart")
                .get("partDetails")
                .get("primaryPart")
                .get("wheelManufacturerName") +
              " " +
              item
                .get("itemPart")
                .get("partDetails")
                .get("primaryPart")
                .get("wheelName")
                .get("en") +
              " " +
              item
                .get("itemPart")
                .get("partDetails")
                .get("primaryPart")
                .get("wheelPartNumber");
            break;
          case ProductTypes.ACCESSORIES:
            temp +=
              item
                .get("itemPart")
                .get("partDetails")
                .get("primaryPart")
                .get("accessoryManufacturerName") +
              " " +
              item
                .get("itemPart")
                .get("partDetails")
                .get("primaryPart")
                .get("accessoryName")
                .get("en") +
              " " +
              item
                .get("itemPart")
                .get("partDetails")
                .get("primaryPart")
                .get("accessoryPartNumber");
            break;
          case ProductTypes.PACKAGES:
            temp +=
              item
                .get("itemPart")
                .get("partDetails")
                .get("primaryPart")
                .get("packageManufacturerName") +
              " " +
              item
                .get("itemPart")
                .get("partDetails")
                .get("primaryPart")
                .get("packageName")
                .get("en") +
              " " +
              item
                .get("itemPart")
                .get("partDetails")
                .get("primaryPart")
                .get("packagePartNumber");
            break;
        }

        temp += " (" + item.get("quantity").get("value") + ")";

        temp += "} ";
      });
    });
  } else if (!isEmpty(orderItemList)) {
    var item,
      isTire = false,
      isWheel = false,
      isAccessory = false,
      isPackage = false;
    for (var i in orderItemList) {
      if (orderItemList[i]) {
        switch (orderItemList[i].itemType) {
          case getDestination(ProductTypes.TIRES):
            if (!isTire) temp += "Tires" + ": ";
            item = orderItemList[i].itemPart;

            temp += "{";
            temp += item.partDetails.primaryPart.tireManufacturerName;
            temp += " " + item.partDetails.primaryPart.tireModelName;

            isTire = true;
            break;
          case getDestination(ProductTypes.ALLOY_WHEELS):
            if (!isWheel) temp += "Wheels" + ": ";
            item = orderItemList[i].itemPart;

            temp += "{";
            temp += item.partDetails.primaryPart.wheelManufacturerName;
            temp += " " + item.partDetails.primaryPart.wheelName.en;

            isWheel = true;
            break;
          case getDestination(ProductTypes.ACCESSORIES):
            if (!isAccessory) temp += "Accessories" + ": ";
            item = orderItemList[i].itemPart;

            temp += "{";
            temp += item.partDetails.primaryPart.accessoryManufacturerName;
            temp += " " + item.partDetails.primaryPart.accessoryName.en;

            isAccessory = true;
            break;
          case getDestination(ProductTypes.PACKAGES):
            if (!isPackage) temp += "Packages" + ": ";
            item = orderItemList[i].itemPart;

            temp += "{";
            temp += item.partDetails.primaryPart.packageManufacturerName;
            temp += " " + item.partDetails.primaryPart.packageName.en;

            isPackage = true;
            break;
        }

        temp += " " + item.partSummary.primaryPart.itemPartNumber;
        temp += " (" + orderItemList[i].itemQty + ")";
        temp += "} ";
      }
    }
  }

  return label + temp;
};

export const callbackRequest = () => {
  googleAnalyticsEnhancedTrackEvent(
    getTrackingEventCategory(),
    "open lead form",
    getQuoteTrackingLabelInfo("callback"),
    shoppingCartViewStore.data.get("total")
  );
  store.dispatch(shoppingCartOpenForm(formTypes.CALLBACK));

  if (
    getAppTemplate() === "mercedes" ||
    getAppTemplate() === "mercedestransition" ||
    getAppTemplate() === "mercedes_2022"
  ) {
    try {
      gtag("event", "conversion", {
        allow_custom_scripts: true,
        send_to: "DC-951733/tires0/tires003+standard",
      });
    } catch (err) {
      console.log(err.message);
    }
    document.body.insertAdjacentHTML(
      "beforebegin",
      "<noscript><img src='https://ad.doubleclick.net/ddm/activity/src=951733;type=tires0;cat=tires003;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?' width='1' height='1' alt=''/></noscript>"
    );
  }

  shoppingCartActions.openWishlistFormModal(formTypes.CALLBACK);
};

export const priceMatchTires = () => {
  googleAnalyticsEnhancedTrackEvent(
    getTrackingEventCategory(),
    "Shopping Cart Quick View - Open Compare Form",
    getTrackingEventOrderId()
  );
  store.dispatch(shoppingCartOpenForm(formTypes.PRICEMATCH));

  shoppingCartActions.openWishlistFormModal(formTypes.PRICEMATCH);
};

export const printQuote = () => {
  googleAnalyticsEnhancedTrackEvent(
    getTrackingEventCategory(),
    "open lead form",
    getQuoteTrackingLabelInfo("print"),
    shoppingCartViewStore.data.get("total")
  );
  store.dispatch(shoppingCartOpenForm(formTypes.PRINT));
  shoppingCartActions.openWishlistFormModal(formTypes.PRINT);
};

export const downloadQuote = () => {
  googleAnalyticsEnhancedTrackEvent(
    getTrackingEventCategory(),
    "Shopping Cart Quick View - Open Download Form",
    getTrackingEventOrderId()
  );
  store.dispatch(shoppingCartOpenForm(formTypes.DOWNLOAD));
  shoppingCartActions.openWishlistFormModal(formTypes.DOWNLOAD);
};

export const emailQuote = () => {
  googleAnalyticsEnhancedTrackEvent(
    getTrackingEventCategory(),
    "open lead form",
    getQuoteTrackingLabelInfo("email"),
    shoppingCartViewStore.data.get("total")
  );
  store.dispatch(shoppingCartOpenForm(formTypes.SEND));

  if (
    getAppTemplate() === "mercedes" ||
    getAppTemplate() === "mercedestransition" ||
    getAppTemplate() === "mercedes_2022"
  ) {
    try {
      gtag("event", "conversion", {
        allow_custom_scripts: true,
        send_to: "DC-951733/tires0/tires000+standard",
      });
    } catch (err) {
      console.log(err.message);
    }
    document.body.insertAdjacentHTML(
      "beforebegin",
      "<noscript><img src='https://ad.doubleclick.net/ddm/activity/src=951733;type=tires0;cat=tires000;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?' width='1' height='1' alt=''/></noscript>"
    );
  }

  shoppingCartActions.openWishlistFormModal(formTypes.SEND);
};

export const saveQuote = () => {
  googleAnalyticsEnhancedTrackEvent(
    getTrackingEventCategory(),
    "open lead form",
    getQuoteTrackingLabelInfo("save"),
    shoppingCartViewStore.data.get("total")
  );
  store.dispatch(shoppingCartOpenForm(formTypes.SAVE));
  // shoppingCartActions.openWishlistFormModal(formTypes.SAVE);
};

export const reserveItem = () => {
  googleAnalyticsEnhancedTrackEvent(
    getTrackingEventCategory(),
    "Shopping Cart Quick View - Open Reserve Form",
    getTrackingEventOrderId()
  );
  store.dispatch(shoppingCartOpenForm(formTypes.RESERVE));
  // shoppingCartActions.openWishlistFormModal(formTypes.RESERVE);

  if (
    getAppTemplate() === "mercedes" ||
    getAppTemplate() === "mercedestransition" ||
    getAppTemplate() === "mercedes_2022"
  ) {
    try {
      gtag("event", "conversion", {
        allow_custom_scripts: true,
        send_to: "DC-951733/tires0/tires001+standard",
      });
    } catch (err) {
      console.log(err.message);
    }
    document.body.insertAdjacentHTML(
      "beforebegin",
      "<noscript><img src='https://ad.doubleclick.net/ddm/activity/src=951733;type=tires0;cat=tires001;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?' width='1' height='1' alt=''/></noscript>"
    );
  }

  shoppingCartActions.openWishlistFormModal(formTypes.RESERVE);
};

export const bookAppointment = () => {
  googleAnalyticsEnhancedTrackEvent(
    getTrackingEventCategory(),
    "open lead form",
    getQuoteTrackingLabelInfo("appointment"),
    shoppingCartViewStore.data.get("total")
  );
  store.dispatch(shoppingCartOpenForm(formTypes.APPOINTMENT));

  // shoppingCartActions.openWishlistFormModal(formTypes.APPOINTMENT);

  if (
    getAppTemplate() === "mercedes" ||
    getAppTemplate() === "mercedestransition" ||
    getAppTemplate() === "mercedes_2022"
  ) {
    try {
      gtag("event", "conversion", {
        allow_custom_scripts: true,
        send_to: "DC-951733/tires0/tires002+standard",
      });
    } catch (err) {
      console.log(err.message);
    }
    document.body.insertAdjacentHTML(
      "beforebegin",
      "<noscript><img src='https://ad.doubleclick.net/ddm/activity/src=951733;type=tires0;cat=tires002;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?' width='1' height='1' alt=''/></noscript>"
    );
  }

  shoppingCartActions.openWishlistFormModal(formTypes.APPOINTMENT);
};

export const bookIntegrationAppointment = () => {
  googleAnalyticsEnhancedTrackEvent(
    getTrackingEventCategory(),
    "Shopping Cart Quick View - Open Appointment Integration Form",
    getTrackingEventOrderId()
  );
  shoppingCartActions.openWishlistAppointmentIntegrationModal();
};

export const markAsComplete = () => {
  shoppingCartActions.markOrderStatusAsComplete();
};

export const openMarkAsComplete = () => {
  shoppingCartActions.openMarkAsCompleteModal();
};

const closeMarkAsComplete = () => {
  shoppingCartActions.closeMarkAsCompleteModal();
};

export const renderMarkAsCompleteModal = () => {
  if (shoppingCartViewStore.data.get("showMarkAsCompleteModal")) {
    var dictionary = languageStore.getDictionary();
    return (
      <Modal
        refactor={true}
        useShortHeaderBar={true}
        requestUnmount={closeMarkAsComplete}
      >
        <div className="wrapper">
          <div className="modal-content">
            <span className="title">
              {dictionary.wishlist.markAsCompleteQuestion}
            </span>
            <span>{dictionary.wishlist.cannotUndo}</span>
            <div className="button-container">
              <button
                className="btn new-quote"
                ref="btnNewQuote"
                tabIndex="1"
                aria-label={dictionary.startNewQuote}
                onClick={markAsComplete}
              >
                {dictionary.wishlist.markAsComplete}
              </button>
              <button
                className="btn close"
                tabIndex="1"
                aria-label={dictionary.common.close}
                onClick={closeMarkAsComplete}
              >
                {dictionary.common.close}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  } else {
    return false;
  }
};

const LAYOUT = {
  FULL: 1336,
  DESKTOP: 1143,
  TABLET: 1024,
  MOBILE: 730,
};

export const isMobile = () => {
  return window.innerWidth < LAYOUT.MOBILE;
};
export const getOrderDeposit = () => {
  return store.getState().shoppingCart.orderDeposit;
};
export const getQuickActions = (isFinanceModal) => {
  var dictionary = languageStore.getDictionary();

  var quickActions = {
    title: {
      enabled: !isFinanceModal,
      text: getCartTitle(),
      isCheckout: false,
      event: null,
      href: "",
    },
  };

  switch (applicationStore.data.defaultTemplate) {
    case "generic":
      if (isUserLoggedIn()) {
        quickActions.order = {
          enabled: !isFinanceModal,
          buttonText: dictionary.wishlist.modal.titleOrderDealerQuote,
          subtext: "",
          isCheckout: false,
          event: reserveItem,
          href: "",
        };
        quickActions.save = {
          enabled: !isFinanceModal,
          buttonText: dictionary.wishlist.modal.titleSaveDealerQuote,
          subtext: "",
          isCheckout: false,
          event: saveQuote,
          href: "",
        };
      } else {
        quickActions.email = {
          enabled: !isFinanceModal,
          buttonText: isUserLoggedIn()
            ? dictionary.wishlist.modal.titleEmailDealer
            : dictionary.wishlist.modal.emailCustomerQuote,
          subtext: "",
          isCheckout: false,
          event: emailQuote,
          href: "",
        };
      }
      break;
    default:
      if (isUserLoggedIn()) {
        quickActions.save = {
          enabled: !isFinanceModal,
          buttonText: dictionary.wishlist.modal.titleSaveDealerQuote,
          subtext: "",
          isCheckout: false,
          event: saveQuote,
          href: "",
        };
      }
      quickActions.email = {
        enabled: !isFinanceModal,
        buttonText: isUserLoggedIn()
          ? dictionary.wishlist.modal.titleEmailDealer
          : dictionary.wishlist.modal.emailCustomerQuote,
        subtext: "",
        isCheckout: false,
        event: emailQuote,
        href: "",
      };
  }

  if (!isUserLoggedIn()) {
    quickActions.reserve = {
      enabled: !isFinanceModal,
      buttonText: isUserLoggedIn()
        ? dictionary.wishlist.modal.reserveItemsCustomer
        : dictionary.wishlist.modal.reserveItems,
      subtext: "",
      isCheckout: false,
      event: reserveItem,
      href: "",
    };
  }

  //console.log('Setting Appointment, isIntegrationEnabled: '+isIntegrationEnabled()+', isXtimeEnabled: '+isXtimeEnabled());

  var appointmentEvt = bookAppointment;
  if (isIntegrationEnabled()) {
    appointmentEvt = bookIntegrationAppointment;
  } else if (isXtimeEnabled()) {
    appointmentEvt = onBookXtimeAppointment.bind(null, false);
  }

  quickActions.appointment = {
    enabled: true,
    buttonText: dictionary.wishlist.modal.bookAppointment,
    subtext: "",
    isCheckout: false,
    event: appointmentEvt,
    href: "",
  };

  if (!isUserLoggedIn()) {
    quickActions.callback = {
      enabled: !isFinanceModal,
      buttonText: dictionary.wishlist.modal.requestCallback,
      subtext: "",
      isCheckout: false,
      event: callbackRequest,
      href: "",
    };
  }

  /*
  if (!isUserLoggedIn() && !applicationStore.data.enableMercedesFeatures) {
    quickActions.priceMatch = {
      enabled: true,
      buttonText: dictionary.wishlist.modal.priceMatchTires,
      subtext: "",
      isCheckout: false,
      event: priceMatchTires,
      href: '',
    };
  }
  */

  if (isUserLoggedIn()) {
    quickActions.markAsComplete = {
      enabled: !isFinanceModal,
      buttonText: dictionary.wishlist.modal.markAsComplete,
      subtext: "",
      isCheckout: false,
      event: openMarkAsComplete,
      href: "",
    };
  }

  if (isUserLoggedIn()) {
    quickActions.print = {
      enabled: !isFinanceModal,
      buttonText: dictionary.wishlist.modal.print,
      subtext: "",
      isCheckout: false,
      event: printQuote,
      href: "",
    };
  }

  if (applicationStore.data.enableFinancing) {
    quickActions.financing = {
      enabled: !isFinanceModal,
      buttonText: dictionary.financePurchase,
      subtext: "",
      isCheckout: false,
      event: onToggleFinancing,
      href: "",
    };
  }

  if (getSupportConsumerOrder()) {
    quickActions.checkout = {
      enabled: !isFinanceModal,
      buttonText: dictionary.wishlist.modal.checkout,
      subtext: dictionary.wishlist.modal.proceedToCheckoutText.replace(
        "[]",
        money_format(getOrderDeposit())
      ),
      isCheckout: true,
      event: onFoxyCartLaunch,
      href: "",
    };
  }

  if (isFinanceModal) {
    quickActions.phone = {
      enabled: true,
      buttonText:
        dictionary.call + " " + applicationStore.data.dealerInfo.phone,
      subtext: "",
      isCheckout: false,
      event: null,
      href: "tel:" + getHrefPhoneFormat(applicationStore.data.dealerInfo.phone),
    };
  }

  //console.log(quickActions);

  return quickActions;
};

const getCartTitle = () => {
  var dictionary = languageStore.getDictionary();

  if (isMobile()) {
    return (
      dictionary.wishlist.modal.yourCart +
      " " +
      dictionary.wishlist.total +
      ": " +
      money_format(shoppingCartViewStore.data.get("total"))
    );
  } else if (getSupportConsumerOrder()) {
    return dictionary.wishlist.titleEcommerce + ":";
  } else {
    return dictionary.wishlist.modal.yourCart;
  }
};

export const renderQuoteQuickActions = (isFinanceModal = false) => {
  return (
    <div className="cart-head cart-quick-actions">
      {renderQuickActions(isFinanceModal)}
    </div>
  );
};

const renderQuickActions = (isFinanceModal) => {
  return (
    <div
      className={
        getSupportConsumerOrder() ? "quick-actions has-order" : "quick-actions"
      }
    >
      <ul>
        {Immutable.fromJS(getQuickActions(isFinanceModal)).map(function (
          action
        ) {
          if (action.get("enabled")) {
            if (!!action.get("event")) {
              return (
                <li
                  className={
                    action.get("isCheckout")
                      ? getButtonClass(isFinanceModal) + " checkout"
                      : getButtonClass(isFinanceModal)
                  }
                >
                  <h3>
                    <button className="btn" onClick={action.get("event")}>
                      {action.get("isCheckout") ? (
                        <i className="fa fa-lock" />
                      ) : (
                        ""
                      )}
                      {action.get("buttonText")}
                    </button>
                    <span className="text-small">{action.get("subtext")}</span>
                  </h3>
                </li>
              );
            } else if (!isEmpty(action.get("href"))) {
              return (
                <li
                  className={
                    action.get("isCheckout")
                      ? getButtonClass(isFinanceModal) + " checkout"
                      : getButtonClass(isFinanceModal)
                  }
                >
                  <h3>
                    <button className="btn" href={action.get("href")}>
                      {action.get("isCheckout") ? (
                        <i className="fa fa-lock" />
                      ) : (
                        ""
                      )}
                      {action.get("buttonText")}
                    </button>
                    <span className="text-small">{action.get("subtext")}</span>
                  </h3>
                </li>
              );
            } else {
              return (
                <li className={getButtonClass(isFinanceModal) + " text"}>
                  <h1 className="title">{action.get("text")}</h1>
                  <span>{hasVehicle() ? getVehicleName() : ""}</span>
                </li>
              );
            }
          } else {
            return false;
          }
        })}
      </ul>
    </div>
  );
};

const getButtonClass = (isFinanceModal) => {
  switch (getNumberOfQuickActions(isFinanceModal)) {
    case 0:
    case 1:
      return "";
    case 2:
      return "two-buttons";
    case 3:
      return "three-buttons";
    case 4:
      return "four-buttons";
    case 5:
      return "five-buttons";
    case 6:
      return "six-buttons";
    case 7:
      return "seven-buttons";
    case 8:
      return "eight-buttons";
  }
};

const getNumberOfQuickActions = (isFinanceModal) => {
  var actionCount = 0;
  Immutable.fromJS(getQuickActions(isFinanceModal)).map(function (action) {
    if (action.get("enabled")) actionCount++;
  });
  return parseInt(actionCount);
};

const getdecodeQuoteContext = (context) => {
  return {
    first: context === "first",
    last: context === "last",
    error: context !== "first" && context !== "last",
  };
};

export const decodeQuoteName = (cntxt, name) => {
  if (!isEmpty(name)) {
    var context = getdecodeQuoteContext(cntxt);
    if (!context.error) {
      if (name.indexOf(" ") > -1) {
        return name.split(" ")[context.first ? 0 : 1];
      } else {
        return context.first ? name : "";
      }
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const renderProducts = (type) => {
  var cartItems = new Map(store.getState().shoppingCart.cartItems);
  if (cartItems && cartItems.get(type) && sizeOf(cartItems.get(type)) > 0) {
    var cartItemList = cartItems.get(type);
    if (getSupportConsumerOrder()) {
      cartItemList = cartItemList.filter(
        (item) =>
          item.get("supportDealerOrder") || item.get("supportConsumerOrder")
      );
    }
    if (cartItemList && sizeOf(cartItemList) > 0) {
      return (
        <div className="wishlist-table">
          {!removeOptionalFeatures() ? renderProductTitle(type) : false}
          <table className="table-section">
            {renderTableHead(cartItemList)}
            {cartItemList && renderTableBodyHasOrder(cartItemList)}
          </table>
        </div>
      );
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const renderProductsNonOrder = (type, cartItems) => {
  cartItems = cartItems || shoppingCartViewStore.data.get("cartItems");
  if (
    cartItems &&
    cartItems.get(type) &&
    sizeOf(cartItems.get(type)) > 0 &&
    getSupportConsumerOrder()
  ) {
    var cartItemList = cartItems.get(type);
    cartItemList = cartItemList.filter(
      (item) =>
        !item.get("supportDealerOrder") && !item.get("supportConsumerOrder")
    );

    //var tablehassavings = applicationStore.data.dealerShowMsrp && !hasPromotion && totalMSRPSavings > 0;

    if (cartItemList && sizeOf(cartItemList) > 0) {
      return (
        <div className="wishlist-table">
          {renderProductTitle(type)}
          <table className="table-section">
            {renderTableHead(cartItemList)}
            {renderTableBodyNonOrder(cartItemList)}
          </table>
        </div>
      );
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const renderProductTitle = (type) => {
  if (enableProductTitle()) {
    return (
      <div className="wishlist-table-title">
        <h2>{getTitle(type)}</h2>
      </div>
    );
  } else {
    return false;
  }
};

const enableProductTitle = () => {
  switch (applicationStore.data.defaultTemplate) {
    case "mercedes":
      return false;
    case "mercedes_2022":
      return false;
    default:
      return true;
  }
};

const getTitle = (type) => {
  var dictionary = languageStore.getDictionary();
  switch (type) {
    case ProductTypes.TIRES:
      return dictionary.product.tire;
    case ProductTypes.ALLOY_WHEELS:
      return dictionary.product.wheel;
    case ProductTypes.ACCESSORIES:
      return dictionary.product.accessory;
    case ProductTypes.PACKAGES:
      return dictionary.product.package;
  }
};

const renderTableHead = (cartItemList) => {
  var dictionary = languageStore.getDictionary();
  var priceLabel = getPriceText();
  var showSavings = showSavingsColumn();
  var showMsrp = showMsrpColumn();
  var ariaTableDesc =
    dictionary.wishlist.item +
    ". " +
    (showMsrp ? dictionary.wishlist.msrp : "") +
    ". " +
    priceLabel +
    ". " +
    dictionary.wishlist.quantity +
    ". " +
    (showSavings ? dictionary.wishlist.youSave : "") +
    ". " +
    dictionary.wishlist.total;
  return (
    <thead>
      <tr tabIndex={getTabIndex()} aria-label={ariaTableDesc}>
        <th className="primary-column">{dictionary.wishlist.item}</th>
        <th className="closable">{showMsrp ? dictionary.wishlist.msrp : ""}</th>
        <th className="closable">{priceLabel}</th>
        <th className="quantity-column">{dictionary.wishlist.quantity}</th>
        <th className="closable">
          {showSavings ? dictionary.wishlist.youSave : ""}
        </th>
        <th>{dictionary.wishlist.total}</th>
      </tr>
    </thead>
  );
};

export const showSavingsColumn = (itemId = 0) => {
  const updateUrl = () => {
    let url = window.location.href;
    if (url.indexOf("undefined") > -1) {
      url = url.replace("undefined", getOrderIdentifier());
      window.location.replace(url);
    }
  };

  updateUrl();

  const shoppingCart = store.getState().shoppingCart;

  const cartInformation = shoppingCart.cartInformation;

  if (!cartInformation) {
    return false;
  }

  const showSavings = cartInformation.orderItemList
    .filter((item) => item.itemId === itemId || !itemId)
    .some(({ itemQty, itemPart }) => {
      const partPricing =
        itemPart.partPricing.primaryPart.price[itemQty].contextList[0];

      const list = Number(partPricing.listPrice);
      const display = Number(partPricing.displayPrice);
      const savings = Number(partPricing.displaySavings);
      const pricingContextId = Number(partPricing.pricingContextId);
      const showMsrp =
        list > 0 && list > display && applicationStore.data.dealerShowMsrp;
      const hasPromotion =
        pricingContextId > 0 && Boolean(partPricing?.promotionPricingText?.en);
      return (showMsrp || hasPromotion) && savings > 0;
    });
  return showSavings;
};

export const checkHasPromotion = (itemId = 0) => {
  let vehicleInfo = store.getState().shoppingCart;
  let cartItems = vehicleInfo["cartItems"] ? vehicleInfo["cartItems"] : [];
  var hasPromotion = false;

  if (sizeOf(cartItems) > 0) {
    cartItems.forEach(function (items) {
      items.forEach(function (cartItem) {
        if (itemId === 0 || itemId == cartItem["id"]) {
          hasPromotion = cartItem["hasPromotion"];
        }
      });
    });
  }

  return hasPromotion;
};

export const showMsrpColumn = (itemId = 0) => {
  let vehicleInfo = store.getState().shoppingCart;
  let cartItems = vehicleInfo["cartItems"] ? vehicleInfo["cartItems"] : [];
  var list,
    display,
    showMsrp = false;

  if (sizeOf(cartItems) > 0) {
    cartItems.forEach(function (items) {
      items.forEach(function (cartItem) {
        if (itemId === 0 || itemId == cartItem.get("id")) {
          list = parseFloat(cartItem.get("pricing").get("listPrice"));
          display = parseFloat(cartItem.get("pricing").get("displayPrice"));

          if (list > 0 && list > display) {
            showMsrp = true;
          }
        }
      });
    });
  }

  return (
    !checkHasPromotion() && applicationStore.data.dealerShowMsrp && showMsrp
  );
};

export const getPriceText = () => {
  if (
    isDealerSite() &&
    applicationStore.data.dealerSellingPriceLabel[ProductTypes.TIRES][
      getAppLanguage()
    ]
  ) {
    return applicationStore.data.dealerSellingPriceLabel[ProductTypes.TIRES][
      getAppLanguage()
    ];
  } else {
    var dictionary = languageStore.getDictionary();
    return dictionary.product.retailPrice;
  }
};

export const removeOptionalFeatures = () => {
  //var shoppingCartContext = Router.collectShoppingContext();
  //return shoppingCartContext.isEcommerce || shoppingCartContext.isOrder;
  return (
    isUnscheduledOrder() ||
    isScheduledOrder() ||
    isCompleteOrder() ||
    isConsumerComplete() ||
    isCancelledOrder()
  );
};

const renderTableBodyHasOrder = (cartItemList) => {
  var shoppingCartContext = Router.collectShoppingContext();
  var isEcommerce = shoppingCartContext.isEcommerce;
  var isOrder = shoppingCartContext.isOrder;
  var supportDealerOrder = shoppingCartViewStore.data.get("supportDealerOrder");
  var supportConsumerOrder = getSupportConsumerOrder();
  var ntdStockEnabled =
    isUserLoggedIn() && applicationStore.data.dealerAllowNtdStockOrder;
  return cartItemList
    .map(function (cartItem, index) {
      var ntdOrderStockEnabled = false;
      if (cartItem.get("type") === ProductTypes.TIRES && ntdStockEnabled)
        ntdOrderStockEnabled = checkNTDStockExists(cartItem);
      return (
        <WishlistReviewItem
          key={"cart-item-ntd-" + cartItem.get("id")}
          index={index}
          cartItem={cartItem}
          productIndex={cartItem.get("id")}
          hasNTDStock={ntdOrderStockEnabled}
          removeOptionalFeatures={removeOptionalFeatures()}
          supportDealerOrder={supportDealerOrder}
          supportConsumerOrder={supportConsumerOrder}
          isEcommerce={isEcommerce}
          isOrder={isOrder}
        />
      );
    })
    .toArray();
};

const renderTableBodyNonOrder = (cartItemList) => {
  var shoppingCartContext = Router.collectShoppingContext();
  var isEcommerce = shoppingCartContext.isEcommerce;
  var isOrder = shoppingCartContext.isOrder;
  var supportDealerOrder = shoppingCartViewStore.data.get("supportDealerOrder");
  var supportConsumerOrder = getSupportConsumerOrder();
  return cartItemList
    .map(function (cartItem, index) {
      return (
        <WishlistReviewItem
          key={"cart-item-" + cartItem.get("id")}
          index={index}
          cartItem={cartItem}
          productIndex={cartItem.get("id")}
          hasNTDStock={false}
          removeOptionalFeatures={removeOptionalFeatures()}
          supportDealerOrder={supportDealerOrder}
          supportConsumerOrder={supportConsumerOrder}
          isEcommerce={isEcommerce}
          isOrder={isOrder}
        />
      );
    })
    .toArray();
};

export const getCartInformation = () => {
  return store.getState().shoppingCart.cartInformation;
};

function isObject(item) {
  return typeof item === "object" && item !== null;
}
export const renderSaving = () => {
  let url = window.location.href;
  let cartInformation;

  if (url.includes("undefined")) {
    url = url.replace("undefined", getOrderIdentifier());
    window.location.replace(url);
  }

  cartInformation = getCartInformation();

  if (cartInformation === null) {
    loadWishlistWithContext();
    cartInformation = getCartInformation();
  }
  let shoppingCartItems;
  if (cartInformation instanceof Map) {
    shoppingCartItems = new Map(cartInformation);
  } else if (isObject(cartInformation)) {
    shoppingCartItems = new Map(Object.entries(cartInformation));
  } else {
    console.error("Unexpected data type for cartInformation");
    return;
  }

  var numberOfPromotions = shoppingCartItems.get("numberOfPromotions");
  if (
    !applicationStore.data.enableMercedesFeatures &&
    parseFloat(shoppingCartItems.get("totalSavings")) > 10 &&
    numberOfPromotions > 0
  ) {
    var dictionary = languageStore.getDictionary();
    var textTemplate =
      numberOfPromotions === 1
        ? dictionary.quote.savedThroughOnePromotion
        : dictionary.quote.savedThroughMultiplePromotions;

    var totalSavings = 0,
      cartItems = shoppingCartItems.get("cartItems");
    if (cartItems) {
      cartItems.forEach(function (productType) {
        productType.forEach(function (cartItem) {
          if (cartItem.get("hasPromotion") && cartItem.get("savings") > 0) {
            totalSavings += parseFloat(cartItem.get("savings"));
          }
        });
      });
    }

    if (totalSavings > 0) {
      return (
        <div className="wishlist-total-savings">
          <p>
            {textTemplate
              .replace("{0}", money_format(totalSavings))
              .replace(
                "{1}",
                numberOfPromotions === 1
                  ? shoppingCartItems
                      .get("firstNonNullPromotion")
                      .get("itemPromotion")
                      .get(getAppLanguage())
                  : numberOfPromotions
              )}
          </p>
        </div>
      );
    } else {
      return false;
    }
  } else {
    return false;
  }
};
export const getOrderDisclaimerList = () => {
  return store.getState().shoppingCart.orderDisclaimerList;
};
export const getTotalCost = (type) => {
  return store.getState().shoppingCart[type];
};
export const renderWishlistSummaryTable = () => {
  let shoppingCartItems;
  if (cartItemsAreValid()) {
    let cartInformation = store.getState().shoppingCart.cartInformation;
    // check if cartInformation is a Map or an Object
    if (cartInformation instanceof Map) {
      shoppingCartItems = new Map(cartInformation);
    } else if (typeof cartInformation === "object") {
      shoppingCartItems = new Map(Object.entries(cartInformation));
    } else {
      // Handle unexpected data types here, if necessary
      console.error("Unexpected data type for cartInformation");
    }
    // let shoppingCartItems = new Map(store.getState().shoppingCart.cartInformation);

    return (
      <div className="wishlist-totals">
        <WishlistReviewSummaryTable
          subtotal={getTotalCost("subtotal")}
          tax={getTotalCost("tax")}
          taxtotal={getTotalCost("taxtotal")}
          showTax={true}
          total={getTotalCost("total")}
          showTotal={true}
          referenceNumber={getOrderIdentifier()}
          referenceDate={getTotalCost("shoppingCartViewCreated")}
          tabIdx={getTabIndex()}
          hasCartItems={cartItemsAreValid()}
        />
      </div>
    );
  } else {
    return false;
  }
};

export const cartItemsAreValid = () => {
  let cartItems = new Map(store.getState().shoppingCart.cartItems);
  return typeof cartItems !== "undefined" && sizeOf(cartItems) > 0;
};
export const getShoppingViewNotes = () => {
  return store.getState().shoppingCart.shoppingCartViewNotes;
};
export const renderProductsNonOrderDisclaimer = (cartItems) => {
  if (cartItems) {
    var dictionary = languageStore.getDictionary();
    var cartItemListTires = cartItems.get(ProductTypes.TIRES)
      ? cartItems
          .get(ProductTypes.TIRES)
          .filter(
            (tire) =>
              !tire.get("supportDealerOrder") &&
              !tire.get("supportConsumerOrder")
          )
      : [];
    var cartItemListWheels = cartItems.get(ProductTypes.ALLOY_WHEELS)
      ? cartItems
          .get(ProductTypes.ALLOY_WHEELS)
          .filter(
            (wheel) =>
              !wheel.get("supportDealerOrder") &&
              !wheel.get("supportConsumerOrder")
          )
      : [];
    var cartItemListAccessories = cartItems.get(ProductTypes.ACCESSORIES)
      ? cartItems
          .get(ProductTypes.ACCESSORIES)
          .filter(
            (accessory) =>
              !accessory.get("supportDealerOrder") &&
              !accessory.get("supportConsumerOrder")
          )
      : [];
    var cartItemListPackages = cartItems.get(ProductTypes.PACKAGES)
      ? cartItems
          .get(ProductTypes.PACKAGES)
          .filter(
            (pkg) =>
              !pkg.get("supportDealerOrder") && !pkg.get("supportConsumerOrder")
          )
      : [];
    if (
      (shoppingCartViewStore.data.get("supportDealerOrder") ||
        getSupportConsumerOrder()) &&
      (sizeOf(cartItemListTires) > 0 ||
        sizeOf(cartItemListWheels) > 0 ||
        sizeOf(cartItemListAccessories) > 0 ||
        sizeOf(cartItemListPackages) > 0)
    ) {
      var itemCount =
        parseInt(sizeOf(cartItemListTires)) +
        parseInt(sizeOf(cartItemListWheels)) +
        parseInt(sizeOf(cartItemListAccessories)) +
        parseInt(sizeOf(cartItemListPackages));
      if (getSupportConsumerOrder()) {
        return (
          <div className="non-orderable-disclaimer">
            <span>
              ({itemCount}){" "}
              {itemCount === 1
                ? dictionary.wishlist.nonOrderableDisclaimer_1_a
                : dictionary.wishlist.nonOrderableDisclaimer_1_b}
            </span>
            <span>{dictionary.wishlist.nonOrderableDisclaimer_2}</span>
            <span>{dictionary.wishlist.nonOrderableDisclaimer_3}</span>
            <span className="bold">
              {dictionary.wishlist.nonOrderableDisclaimer_4}
            </span>
            <span>{dictionary.wishlist.nonOrderableDisclaimer_5}</span>
          </div>
        );
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const switchTerms = {
  proceedToCheckout: "PROCEED_TO_CHECKOUT",
  xtimeSection: "X_TIME_SECTION",
  wishlistTitle: "WISHLIST_TITLE",
  ecommerceTitle: "ECOMMERCE_TITLE",
  progressBar: "PROGRESS_BAR",
  consumerOrderSuccess: "CONSUMER_ORDER_SUCCESS",
  consumerScheduleSuccess: "CONSUMER_SCHEDULE_SUCCESS",
  consumerCompletedSuccess: "CONSUMER_COMPLETED_SUCCESS",
  dealerOrderSuccess: "DEALER_ORDER_SUCCESS",
  dealerScheduleSuccess: "DEALERR_SCHEDULE_SUCCESS",
  dealerCompletedSuccess: "DEALER_COMPLETED_SUCCESS",
  cancellationSuccess: "CANCELLATION_SUCCESS",
  orderHistory: "ORDER_HISTORY",
  clearCart: "CLEAR_CART",
  vehicleTitle: "VEHICLE_TITLE",
  tableOptions: "TABLE_OPTIONS",
};

export const renderAppointmentIntegrationToggleButton = () => {
  if (isIntegrationEnabled()) {
    var dictionary = languageStore.getDictionary();
    return (
      <div>
        <button className="btn" onClick={bookIntegrationAppointment}>
          {dictionary.wishlist.modal.bookAppointment}
        </button>
      </div>
    );
  } else {
    return false;
  }
};

export const renderWishlistHeader = (
  isModal,
  showTitle,
  state,
  updateState,
  printWishlist = function () {},
  downloadWishlist = function () {}
) => {
  return (
    <div className="wishlist-head">
      {!isModal ? renderHeaderSwitch(switchTerms.proceedToCheckout) : false}
      {renderHeaderSwitch(switchTerms.xtimeSection)}

      {showTitle ? renderHeaderSwitch(switchTerms.wishlistTitle) : false}
      {showTitle ? renderHeaderSwitch(switchTerms.ecommerceTitle) : false}
      {renderHeaderSwitch(switchTerms.progressBar)}

      {renderHeaderSwitch(switchTerms.consumerOrderSuccess)}
      {renderHeaderSwitch(switchTerms.consumerScheduleSuccess)}
      {renderHeaderSwitch(switchTerms.consumerCompletedSuccess)}

      {renderHeaderSwitch(
        switchTerms.dealerOrderSuccess,
        state,
        updateState,
        null,
        null
      )}
      {renderHeaderSwitch(
        switchTerms.dealerScheduleSuccess,
        state,
        updateState,
        null,
        null,
        null,
        null
      )}
      {renderHeaderSwitch(switchTerms.dealerCompletedSuccess)}

      {renderHeaderSwitch(switchTerms.cancellationSuccess)}

      {renderHeaderSwitch(switchTerms.orderHistory)}

      {renderHeaderSwitch(
        switchTerms.tableOptions,
        state,
        updateState,
        printWishlist,
        downloadWishlist
      )}

      {!isModal ? renderHeaderSwitch(switchTerms.clearCart) : false}
      {!isModal ? renderHeaderSwitch(switchTerms.vehicleTitle) : false}
    </div>
  );
};

const renderHeaderSwitch = (
  component,
  state = null,
  updateState = function () {},
  printWishlist = function () {},
  downloadWishlist = function () {}
) => {
  //console.log('renderHeaderSwitch');
  //console.log(state);

  var dictionary = languageStore.getDictionary();
  switch (component) {
    case switchTerms.proceedToCheckout:
      if (getSupportConsumerOrder() && !removeOptionalFeatures()) {
        return (
          <div className="wishlist-actions-buttons wishlist-mobile">
            <button className="btn foxy-cart-launch" onClick={onFoxyCartLaunch}>
              <i className="fa fa-lock" />
              <span>{dictionary.wishlist.proceedToCheckout}</span>
            </button>
          </div>
        );
      } else {
        return false;
      }
    case switchTerms.xtimeSection:
      if (isXtimeEnabled()) {
        return (
          <div className="xtime-appointment-bar">
            <Image
              className="xtime-calendar-image"
              src="/static/img/calendar.png"
            />
            <span className="xtime-message">
              {applicationStore.data.dealerAllowNtdStock
                ? dictionary.xtime.pickAppointmentTimeDealer
                : dictionary.xtime.pickAppointmentTime}
              {getDealerName()}
            </span>
            <button
              className="btn xtime-btn"
              onClick={onBookXtimeAppointment.bind(null, false)}
            >
              {dictionary.xtime.bookAppointment}
            </button>
          </div>
        );
      } else {
        return false;
      }
    case switchTerms.wishlistTitle:
      if (
        !showConsumerStep() &&
        !showDealerStep() &&
        !removeOptionalFeatures()
      ) {
        return <h1>{dictionary.wishlist.title}</h1>;
      } else {
        return false;
      }
    case switchTerms.ecommerceTitle:
      if (
        (showConsumerStep() || showDealerStep() || removeOptionalFeatures()) &&
        !isCancelledOrder()
      ) {
        return (
          <div className="order-title">
            <h1 className="review-order-title">
              {isUserLoggedIn()
                ? dictionary.wishlist.titleEcommerceDealer
                : dictionary.wishlist.titleEcommerce}
            </h1>
          </div>
        );
      } else {
        return false;
      }
    case switchTerms.progressBar:
      var orderStep = getOrderStep();
      if ((showConsumerStep() || showDealerStep()) && !isEmpty(orderStep)) {
        var checkoutStepClass = decodeOrderStepClass(1, orderStep);
        var orderedStepClass = decodeOrderStepClass(2, orderStep);
        var scheduledStepClass = decodeOrderStepClass(3, orderStep);
        var completedStepClass = decodeOrderStepClass(4, orderStep);
        return (
          <ol
            className={
              orderStep < 4 ? "progress-track" : "progress-track completed"
            }
          >
            {renderStepBarStep(
              checkoutStepClass,
              dictionary.wishlist.orderStepReview,
              dictionary.wishlist.orderStepReview
            )}
            {checkoutStepClass.current
              ? renderStepBarStepCurrentAfterBar(checkoutStepClass)
              : false}
            {renderStepBarStep(
              orderedStepClass,
              dictionary.wishlist.orderStepDespositPaid,
              dictionary.wishlist.orderStepCheckedOut
            )}
            {orderedStepClass.current
              ? renderStepBarStepCurrentAfterBar(orderedStepClass)
              : false}
            {renderStepBarStep(
              scheduledStepClass,
              dictionary.wishlist.orderStepScheduled,
              dictionary.wishlist.orderStepScheduled
            )}
            {scheduledStepClass.current
              ? renderStepBarStepCurrentAfterBar(scheduledStepClass)
              : false}
            {renderStepBarStep(
              completedStepClass,
              dictionary.wishlist.orderStepCompleted,
              dictionary.wishlist.orderStepInstalled
            )}
            {completedStepClass.current
              ? renderStepBarStepCurrentAfterBar(completedStepClass)
              : false}
          </ol>
        );
      } else {
        return false;
      }
    case switchTerms.consumerOrderSuccess:
      if (showConsumerStep() && isUnscheduledOrder()) {
        if (isIntegrationEnabled()) {
          return (
            <div className="xtime-appointment-bar">
              <Image
                className="xtime-calendar-image"
                src="/static/img/calendar.png"
              />
              <span className="xtime-message"></span>
              <button
                className="btn xtime-btn"
                onClick={bookIntegrationAppointment}
              >
                {dictionary.wishlist.scheduleEcommerceAppointment}
              </button>
            </div>
          );
        } else if (checkDealerXtimeEnabled()) {
          return (
            <div className="xtime-appointment-bar">
              <Image
                className="xtime-calendar-image"
                src="/static/img/calendar.png"
              />
              <span className="xtime-message">
                {applicationStore.data.dealerAllowNtdStock
                  ? dictionary.xtime.pickAppointmentTimeDealer
                  : dictionary.xtime.pickAppointmentTime}
                {getDealerName()}
              </span>
              <button
                className="btn xtime-btn"
                onClick={onBookXtimeAppointment.bind(null, true)}
              >
                {dictionary.xtime.bookAppointment}
              </button>
            </div>
          );
        } else {
          var phone = applicationStore.data.dealerInfo.phone,
            email = applicationStore.data.dealerInfo.email;
          return (
            <div className="order-success-div">
              <span className="title">
                <div className="title-div no-button">
                  <i className="fa fa-check" />
                  {dictionary.wishlist.orderPlaced}{" "}
                  {dictionary.wishlist.orderDealerContact
                    .replace("[]", phone)
                    .replace("{}", email)}
                </div>
              </span>
              <span className="text">
                {dictionary.wishlist.orderPlacedThankYou.replace(
                  "[]",
                  shoppingCartViewStore.data.get("customerEmail")
                )}
              </span>
            </div>
          );
        }
      } else {
        return false;
      }
    case switchTerms.consumerScheduleSuccess:
      if (showConsumerStep() && isScheduledOrder()) {
        var appointmentRequestDate = shoppingCartViewStore.data.get(
            "appointmentRequestDate"
          ),
          appointmentRequestTime = shoppingCartViewStore.data.get(
            "appointmentRequestTime"
          ),
          appointment = "";
        if (appointmentRequestDate.isValid()) {
          if (appointmentRequestTime)
            appointment =
              appointmentRequestDate.format("MM-DD-YYYY") +
              " " +
              appointmentRequestTime;
          else appointment = appointmentRequestDate.format("MM-DD-YYYY");
        }
        return (
          <div className="order-success-div">
            <span className="title">
              <div className="title-div no-button">
                <i className="fa fa-check" />
                {dictionary.wishlist.orderScheduled}
              </div>
            </span>
            <span className="text">
              {!isEmpty(appointment)
                ? dictionary.wishlist.orderScheduledMessage.replace(
                    "[]",
                    appointment
                  )
                : ""}
            </span>
          </div>
        );
      } else {
        return false;
      }
    case switchTerms.consumerCompletedSuccess:
      if (showConsumerStep() && isCompleteOrder()) {
        return (
          <div className="order-success-div">
            <span className="title">
              <div className="title-div no-button">
                <i className="fa fa-check" />
                {dictionary.wishlist.orderCompleted}
              </div>
            </span>
            <span className="text">
              {dictionary.wishlist.orderCompletedMessage}
            </span>
          </div>
        );
      } else {
        return false;
      }
    case switchTerms.dealerOrderSuccess:
      if (showDealerStep() && isUnscheduledOrder()) {
        var appointmentRequestDate = shoppingCartViewStore.data.get(
            "appointmentRequestDate"
          ),
          appointmentRequestNotes = shoppingCartViewStore.data.get(
            "appointmentRequestNotes"
          ),
          appointmentRequestTime = shoppingCartViewStore.data.get(
            "appointmentRequestTime"
          );
        return (
          <div className="order-success-div">
            <span className="title">
              <div className="title-div schedule-button">
                <i className="fa fa-check" />
                {dictionary.wishlist.orderPlacedDealer
                  .replace("[]", shoppingCartViewStore.data.get("customerName"))
                  .replace(
                    "{}",
                    shoppingCartViewStore.data.get("customerPhone")
                  )}
              </div>
            </span>
            <div className="order-dealer-contact">
              {appointmentRequestDate.isValid() ? (
                <div>
                  <span className="appointment-date-label">
                    {dictionary.wishlist.appointmentDate + ": "}
                  </span>
                  <span className="appointment-date">
                    {appointmentRequestDate.format("MM-DD-YYYY")}
                    {appointmentRequestTime
                      ? " - " + appointmentRequestTime
                      : false}
                  </span>
                </div>
              ) : (
                false
              )}
              {appointmentRequestNotes ? (
                <div>
                  <span className="appointment-time-label">
                    {dictionary.wishlist.appointmentNotes + ": "}
                  </span>
                  <span className="appointment-time">
                    {appointmentRequestNotes}
                  </span>
                </div>
              ) : (
                false
              )}
              {isIntegrationEnabled() ? (
                <button
                  className="btn schedule-appointment-button"
                  onClick={bookIntegrationAppointment}
                >
                  {dictionary.wishlist.scheduleEcommerceAppointment}
                </button>
              ) : (
                false
              )}
              {!isIntegrationEnabled() &&
              applicationStore.data.supportUserConsumerOrder &&
              isUnscheduledOrder() &&
              !appointmentRequestDate.isValid() ? (
                <button
                  className="btn schedule-appointment-button"
                  onClick={toggleScheduleEcommerceAppointment.bind(
                    null,
                    state,
                    updateState,
                    3
                  )}
                >
                  {dictionary.wishlist.scheduleEcommerceAppointment}
                </button>
              ) : (
                false
              )}
              {!isIntegrationEnabled() &&
              applicationStore.data.supportUserConsumerOrder &&
              isUnscheduledOrder() &&
              appointmentRequestDate.isValid() ? (
                <button
                  className="btn schedule-appointment-button"
                  onClick={saveEcommerceAppointmentTime.bind(
                    null,
                    state,
                    updateState
                  )}
                >
                  {dictionary.wishlist.saveAppointmentRequest}
                </button>
              ) : (
                false
              )}
            </div>
          </div>
        );
      } else {
        return false;
      }
    case switchTerms.dealerScheduleSuccess:
      if (showDealerStep() && isScheduledOrder()) {
        var appointmentRequestDate = shoppingCartViewStore.data.get(
            "appointmentRequestDate"
          ),
          appointmentRequestNotes = shoppingCartViewStore.data.get(
            "appointmentRequestNotes"
          ),
          appointmentRequestTime = shoppingCartViewStore.data.get(
            "appointmentRequestTime"
          );
        return (
          <div className="order-success-div">
            <span className="title">
              <div className="title-div">
                <i className="fa fa-check" />
                {dictionary.wishlist.orderScheduledDealer}
              </div>
            </span>
            <div className="order-dealer-contact">
              {appointmentRequestDate.isValid() ? (
                <div>
                  <span className="appointment-date-label">
                    {dictionary.wishlist.appointmentDate + ": "}
                  </span>
                  <span className="appointment-date">
                    {appointmentRequestDate.format("MM-DD-YYYY")}
                    {appointmentRequestTime
                      ? " - " + appointmentRequestTime
                      : false}
                  </span>
                </div>
              ) : (
                false
              )}
              {appointmentRequestNotes ? (
                <div>
                  <span className="appointment-time-label">
                    {dictionary.wishlist.appointmentNotes + ": "}
                  </span>
                  <span className="appointment-time">
                    {appointmentRequestNotes}
                  </span>
                </div>
              ) : (
                false
              )}
              {applicationStore.data.supportUserConsumerOrder &&
              isScheduledOrder() ? (
                <button
                  className="btn save-appointment-button"
                  onClick={toggleCompleteEcommerceModal.bind(
                    null,
                    state,
                    updateState,
                    1
                  )}
                >
                  {dictionary.wishlist.markAsComplete}
                </button>
              ) : (
                false
              )}
            </div>
          </div>
        );
      } else {
        return false;
      }
    case switchTerms.dealerCompletedSuccess:
      if (showDealerStep() && isCompleteOrder()) {
        return (
          <div className="order-success-div">
            <span className="title">
              <div className="title-div no-button">
                <i className="fa fa-check" />
                {dictionary.wishlist.orderCompletedDealer}
              </div>
            </span>
            <span className="text"></span>
          </div>
        );
      } else {
        return false;
      }
    case switchTerms.cancellationSuccess:
      if (isCancelledOrder()) {
        if (shoppingCartViewStore.data.get("orderStatus") !== "FFL") {
          return (
            <div className="order-success-div">
              <span className="title">
                <div className="title-div no-button">
                  <i className="fa fa-check" />
                  {dictionary.wishlist.orderCancelled}
                </div>
              </span>
              <span className="text">
                {dictionary.wishlist.orderCancelledMessage}
              </span>
            </div>
          );
        } else {
          return (
            <div className="order-failure-div">
              <span className="title">
                <div className="title-div no-button">
                  <i className="fa fa-close" />
                  {dictionary.wishlist.orderFailed}
                </div>
              </span>
              <span className="text"></span>
            </div>
          );
        }
      } else {
        return false;
      }
    case switchTerms.orderHistory:
      if (
        isCompleteOrder() &&
        applicationStore.data.supportUserConsumerOrder &&
        isUserLoggedIn()
      ) {
        return (
          <div className="order-dealer-contact">
            <span className="order-history btn" onClick={onOpenOrderReport}>
              {dictionary.wishlist.myOrderHistory}
            </span>
          </div>
        );
      } else {
        return false;
      }
    case switchTerms.clearCart:
      if (!isCartReview() && !removeOptionalFeatures() && cartItemsAreValid()) {
        return (
          <div className="wishlist-clear">
            <button
              className={setAdobeLaunchClass(
                "wishlist-clear-button",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.YOURQUOTE.BASE)}
              onClick={clearShoppingCart}
            >
              (<span className="select-text">{dictionary.wishlist.clear}</span>)
            </button>
          </div>
        );
      } else {
        return false;
      }
    case switchTerms.vehicleTitle:
      if (!isCartReview() && !removeOptionalFeatures()) {
        var vehicleInformation =
            shoppingCartViewStore.data.get("vehicleInformation"),
          cartItems = shoppingCartViewStore.data.get("cartItems"),
          itemIndex = 0,
          vehicleId = 0;
        if (
          typeof vehicleInformation !== "undefined" &&
          sizeOf(vehicleInformation) > 0 &&
          !removeOptionalFeatures()
        ) {
          if (typeof cartItems.get(ProductTypes.PACKAGES) !== "undefined") {
            cartItems.get(ProductTypes.PACKAGES).forEach(function (item) {
              if (item.get("vehicleId") > 0 && vehicleId === 0) {
                vehicleId = item.get("vehicleId");
              }
            });
          }
          if (vehicleId > 0)
            itemIndex = vehicleInformation.findIndex(
              (vehicleInfo) => vehicleInfo.get("vehicleId") === vehicleId
            );
          return (
            <div
              className={
                getAppLanguage() === "fr"
                  ? "vehicle-title french"
                  : "vehicle-title"
              }
            >
              {" - " +
                vehicleInformation
                  .get(itemIndex)
                  .get("vehicleTitle")
                  .get(getAppLanguage())}
            </div>
          );
        } else {
          return "";
        }
      } else {
        return false;
      }
    case switchTerms.tableOptions:
      if (
        !showConsumerStep() &&
        !showDealerStep() &&
        !removeOptionalFeatures() &&
        cartItemsAreValid()
      ) {
        return (
          <div className="wishlist-print">
            {!isEdgeBrowser() && !isIEBrowser() ? (
              <div onClick={printWishlist}>
                <Image
                  className="img"
                  src="/static/img/print.png"
                  alt="Icon Print"
                />
                <span
                  className={setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.TEXT)}
                  data-dtm={setAdobeLaunchDTM(
                    "",
                    ADOBELAUNCHER_DTM.YOURQUOTE.BASE
                  )}
                >
                  {dictionary.wishlist.print}
                </span>
              </div>
            ) : (
              false
            )}
            {isEdgeBrowser() || isIEBrowser() ? (
              <div onClick={downloadWishlist}>
                <Image
                  className="img"
                  src="/static/img/print.png"
                  alt="Icon Print"
                />
                <span
                  className={setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.TEXT)}
                  data-dtm={setAdobeLaunchDTM(
                    "",
                    ADOBELAUNCHER_DTM.YOURQUOTE.BASE
                  )}
                >
                  {dictionary.wishlist.print}
                </span>
              </div>
            ) : (
              false
            )}
            <div onClick={downloadWishlist}>
              <Image
                className="img"
                src="/static/img/download.png"
                alt="Icon Download"
              />
              <span
                className={setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.TEXT)}
                data-dtm={setAdobeLaunchDTM(
                  "",
                  ADOBELAUNCHER_DTM.YOURQUOTE.BASE
                )}
              >
                {dictionary.wishlist.download}
              </span>
            </div>
          </div>
        );
      } else {
        return false;
      }
  }
};

export const renderFoxyCart = (state) => {
  /*******************************************
   *  Required in component state:
   *
   *      - foxyCart
   *      - foxyCartError
   *
   */

  return (
    <div
      className="foxyCartCheckoutDiv"
      dangerouslySetInnerHTML={{ __html: state.foxyCart }}
    ></div>
  );
};

export const renderFoxyCartError = (state) => {
  if (state.foxyCartError) {
    return (
      <Modal refactor={true} requestUnmount={closeFoxyCartErrorModal}>
        <div className="wrapper">
          <div className="modal-container">
            <span>{languageStore.getDictionary().common.foxyCartFailed}</span>
          </div>
        </div>
      </Modal>
    );
  } else {
    return false;
  }
};

const closeFoxyCartErrorModal = () => {
  shoppingCartActions.closeFoxyCartErrorModal();
};

export const renderCompleteEcommerceModal = (
  state,
  updateState,
  workOrderNumberRef
) => {
  if (state.completeEcommerceModal) {
    var dictionary = languageStore.getDictionary();
    return (
      <Modal
        refactor={true}
        requestUnmount={toggleCompleteEcommerceModal.bind(
          null,
          state,
          updateState,
          2
        )}
      >
        <div className="wrapper complete-ecommerce-modal">
          <div className="modal-container">
            <h2>{dictionary.wishlist.completeReview}</h2>
            <TextField
              label={dictionary.wishlist.workOrderNumber}
              id="workOrderNumber"
              ref="workOrderNumber"
              required={true}
            />
            <button
              className="btn complete-review-button"
              onClick={completeEcommerceOrder.bind(
                null,
                workOrderNumberRef,
                state,
                updateState
              )}
            >
              {dictionary.wishlist.markAsComplete}
            </button>
          </div>
        </div>
      </Modal>
    );
  } else {
    return false;
  }
};

export const renderEcommerceAppointmentModal = (state, updateState) => {
  /*******************************************
   *  Required in component state:
   *
   *      - ecommerceAppointmentCalendar
   *      - appointmentRequestDate
   *      - appointmentRequestTime
   *      - appointmentDate
   *      - appointmentTime
   *      - appointmentNotes
   *
   *      - ecommerceAppointmentTimeModal
   *      - ecommerceAppointmentTimeAmOrPm
   *      - ecommerceAppointmentTempHour
   *      - ecommerceAppointmentTempMinute
   *
   *      - completeEcommerceModal
   *
   */

  if (state.ecommerceAppointmentCalendar) {
    var dictionary = languageStore.getDictionary(),
      today = new Date(),
      selected = null;
    if (state.appointmentRequestDate && state.appointmentRequestDate.isValid())
      selected = state.appointmentRequestDate;
    return (
      <Modal
        applyTemplateStyle={true}
        slideHeaderDown={true}
        requestUnmount={toggleScheduleEcommerceAppointment.bind(
          null,
          state,
          updateState,
          2
        )}
      >
        <div className="wrapper calendar-modal ecommerce-appointment-modal">
          <fieldset className="appointment-reservation">
            <div className="formInput">
              <Calendar
                onSelect={onEcommerceAppointmentDateChanged.bind(
                  null,
                  updateState
                )}
                selected={selected}
                view={today.toDateString()}
              />
            </div>
            <div className="appointment-controls">
              <span className="appointment-date-label">
                {dictionary.wishlist.appointmentDate + ":"}
              </span>
              <span className="appointment-date">
                {state.appointmentRequestDate.isValid()
                  ? state.appointmentRequestDate.format("MM-DD-YYYY")
                  : false}
              </span>
              <div className="appointment-time">
                <span className="appointment-time-label">
                  {dictionary.wishlist.appointmentTime + ":"}
                </span>
                <div className="container">
                  <input
                    onClick={toggleEcommerceAppointmentTimeModal.bind(
                      null,
                      state,
                      updateState
                    )}
                    value={state.appointmentRequestTime}
                  />
                </div>
              </div>
              <TextField
                onChange={onEcommerceAppointmentNoteChanged.bind(
                  null,
                  updateState
                )}
                label={dictionary.wishlist.appointmentNotes + ":"}
                id="ecommerce-appointment-note"
                required={false}
                value={state.appointmentRequestNotes}
                rows="3"
              />
              <button
                className="btn save-appointment-button"
                onClick={saveEcommerceAppointment.bind(
                  null,
                  state,
                  updateState
                )}
              >
                {dictionary.wishlist.saveAppointmentRequest}
              </button>
              <button
                onClick={toggleScheduleEcommerceAppointment.bind(
                  null,
                  state,
                  updateState,
                  1
                )}
                className="btn calendar-select"
              >
                {dictionary.common.close}
              </button>
            </div>
          </fieldset>
        </div>
      </Modal>
    );
  } else {
    return false;
  }
};

export const renderEcommerceTimeSelectionModal = (state, updateState) => {
  if (state.ecommerceAppointmentTimeModal) {
    var categoryToggleClass = Cx({
      "time-category-switch": true,
      checked: state.ecommerceAppointmentTimeAmOrPm,
    });
    var hour = state.ecommerceAppointmentTempHour
      ? state.ecommerceAppointmentTempHour
      : 0;
    var minute = state.ecommerceAppointmentTempMinute
      ? state.ecommerceAppointmentTempMinute
      : 0;
    var dictionary = languageStore.getDictionary();
    return (
      <Modal
        useShortHeaderBar={true}
        slideHeaderDown={true}
        requestUnmount={toggleEcommerceAppointmentTimeModal.bind(
          null,
          state,
          updateState
        )}
      >
        <div className="wrapper time-selection-modal ecommerce-appointment-modal">
          <div className="time-selection">
            <Timepicker
              militaryTime={false}
              value={`${hour}:${minute}`}
              onChange={(value) => {
                const [hours, minutes] = value.split(":");
                onChangeTime(
                  state,
                  updateState,
                  Number(hours),
                  Number(minutes)
                );
              }}
            />
            <div className="time-select-category">
              <label className={categoryToggleClass}>
                <input type="checkbox" />
                <div
                  className="time-category-slider"
                  onClick={onChangeAmOrPm.bind(null, state, updateState)}
                >
                  {state.ecommerceAppointmentTimeAmOrPm ? (
                    <span className="time-category-am">
                      {dictionary.wishlist.modal.AM}
                    </span>
                  ) : (
                    false
                  )}
                  {!state.ecommerceAppointmentTimeAmOrPm ? (
                    <span className="time-category-pm">
                      {dictionary.wishlist.modal.PM}
                    </span>
                  ) : (
                    false
                  )}
                </div>
              </label>
              <button
                onClick={saveEcommerceAppointmentTime.bind(
                  null,
                  state,
                  updateState
                )}
                className="btn calendar-select"
              >
                {dictionary.wishlist.saveAppointmentRequest}
              </button>
              <button
                onClick={toggleEcommerceAppointmentTimeModal.bind(
                  null,
                  state,
                  updateState
                )}
                className="btn close-button"
              >
                {dictionary.common.close}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  } else {
    return false;
  }
};

const toggleCompleteEcommerceModal = (state, updateState, index = 0) => {
  //console.log('toggleCompleteEcommerceModal, index: '+index);
  //console.log(state);

  if (!isEmpty(state)) {
    updateState({
      completeEcommerceModal: !state.completeEcommerceModal,
    });
  }
};

const completeEcommerceOrder = (workOrderNumberRef, state, updateState) => {
  var workOrderNumber = document.getElementById("workOrderNumber");

  //console.log('completeEcommerceOrder');
  //console.log(workOrderNumberRef);
  //console.log(state);

  if (workOrderNumberRef && isEmpty(workOrderNumber.value.trim()))
    workOrderNumberRef.setInvalid();
  else if (!isEmpty(workOrderNumber.value.trim())) {
    toggleCompleteEcommerceModal(state, updateState, 3);
    shoppingCartActions.completeConsumerEcommerce(workOrderNumber.value.trim());
  }
};

const saveEcommerceAppointment = (state, updateState) => {
  //console.log('saveEcommerceAppointment');

  shoppingCartActions.scheduleConsumerEcommerce(
    updateState,
    state.appointmentRequestDate,
    state.appointmentRequestTime,
    state.appointmentRequestNotes
  );
};

const saveEcommerceAppointmentTime = (state, updateState) => {
  var dictionary = languageStore.getDictionary(),
    time =
      state.ecommerceAppointmentTempHour +
      ":" +
      timePadMinutes(state.ecommerceAppointmentTempMinute) +
      " " +
      (state.ecommerceAppointmentTimeAmOrPm
        ? dictionary.wishlist.modal.AM
        : dictionary.wishlist.modal.PM);
  updateState({
    appointmentRequestTime: time,
    ecommerceAppointmentTimeModal: !state.ecommerceAppointmentTimeModal,
  });
};

export const toggleScheduleEcommerceAppointment = (
  state,
  updateState,
  source = 999
) => {
  updateState({
    ecommerceAppointmentCalendar: !state.ecommerceAppointmentCalendar,
  });
};

const toggleEcommerceAppointmentTimeModal = (state, updateState) => {
  updateState({
    ecommerceAppointmentTimeModal: !state.ecommerceAppointmentTimeModal,
  });
};

const onEcommerceAppointmentNoteChanged = (updateState, note) => {
  updateState({
    appointmentRequestNotes: note,
  });
};

const onEcommerceAppointmentDateChanged = (updateState, date) => {
  var dateObject = new Date(date),
    currentDateObject = new Date();
  if (isDateAvailable(dateObject) && dateObject > currentDateObject) {
    updateState({
      appointmentRequestDate: Moment(date),
    });
  }
};

const onChangeTime = (state, updateState, hours, minutes) => {
  updateAppointmentAmOrPm(state);
  updateState({
    ecommerceAppointmentTempHour: hours,
    ecommerceAppointmentTempMinute: minutes,
  });
};

const onChangeAmOrPm = (state, updateState) => {
  updateAppointmentAmOrPm(state, !state.ecommerceAppointmentTimeAmOrPm);
  updateState({
    ecommerceAppointmentTimeAmOrPm: !state.ecommerceAppointmentTimeAmOrPm,
  });
};

const updateAppointmentAmOrPm = (state, timeCategoryFlag) => {
  var dictionary = languageStore.getDictionary(),
    timeDisplay = document.getElementsByClassName("timepicker-info")[0],
    timeCategoryState =
      typeof timeCategoryFlag !== "undefined"
        ? timeCategoryFlag
        : state.ecommerceAppointmentTimeAmOrPm;
  if (timeDisplay) {
    while (sizeOf(timeDisplay.children) > 2)
      timeDisplay.removeChild(
        timeDisplay.children[sizeOf(timeDisplay.children) - 1]
      );
    var timeCategory = document.createElement("span");
    timeCategory.className = "timepicker-info-digits time-category";
    timeCategory.innerHTML = timeCategoryState
      ? " " + dictionary.wishlist.modal.AM
      : " " + dictionary.wishlist.modal.PM;
    timeDisplay.append(timeCategory);
  }
};

export const onReadyPdfPrint = (
  state,
  updateState,
  launcher = PDFLauncher.DOWNLOAD
) => {
  googleAnalyticsEnhancedTrackEvent(
    getTrackingEventCategory(),
    "Shopping Cart Review - CustomerPrint"
  );
  collectWishlistBase64Images(launcher, state, updateState);
};

export const onOpenPrinterFriendly = () => {
  googleAnalyticsEnhancedTrackEvent(
    getTrackingEventCategory(),
    "Shopping Cart Review - CustomerPrint"
  );
  var url = window.location.origin;
  if (isNationalSite()) {
    url +=
      "/national/" + encodeURIComponent(applicationStore.data.nationalUrlCode);
  } else if (isGroupSite()) {
    url += "/group/" + encodeURIComponent(applicationStore.data.groupUrlCode);
  } else {
    url += "/" + encodeURIComponent(getDealerUrlCode());
  }

  if (url) {
    url +=
      "/" +
      encodeURIComponent(getAppLanguage()) +
      "/dealer/" +
      encodeURIComponent(getAppDealerId()) +
      "/wishlist/print/" +
      encodeURIComponent(getOrderIdentifier());
  }

  var win = window.open(url, "_blank");
  if (win) win.focus();
};

const clearShoppingCart = () => {
  store.dispatch(renderSavingToggleState(false));
  store.dispatch(getNewCart());
};

const onOpenOrderReport = () => {
  applicationActions.openOrderReport(false);
};

const renderStepBarStepCurrentAfterBar = (stepClass) => {
  return <li className={stepClass.className + " after"}></li>;
};

const renderStepBarStep = (
  stepClass,
  authenticatedText,
  nonAuthenticatedText
) => {
  return (
    <li className={stepClass.className}>
      <div className="center">
        <div className="icon-wrap">
          <div className="icon-state icon-check-mark">
            <i className="fa fa-check icon-check-mark" />
          </div>
          <div className="icon-state icon-down-arrow">
            <i className="fa fa-arrow-down icon-down-arrow" />
          </div>
        </div>
        <div className="step-label">
          <h3 className={"progress-text" + " " + getStepTitleClass(stepClass)}>
            {isUserLoggedIn() ? authenticatedText : nonAuthenticatedText}
          </h3>
        </div>
      </div>
    </li>
  );
};
export const getOrderStatus = () => {
  return store.getState().shoppingCart.orderStatus;
};
const getOrderStep = () => {
  switch (getOrderStatus()) {
    case "NEW": // New
      return 1;
    case "CAN": // Cancelled
    case "CMP": // Complete
      return null;
    case "FPR": // Pre Fulfillment
    case "FFL": // Fulfillment Failed
      return null;
    case "USC": // Unscheduled
      return 3;
    case "SCH": // Scheduled
      return 4;
    case "DLD": // Delivered
      return 5;
    case "CRQ": // Cancellation Requested
    case "CNR": // Cancellation Normal Refund
    case "CFR": // Cancellation Full Refund
      return null;
    default:
      return null;
  }
};

const getStepTitleClass = (stepClass) => {
  switch (stepClass.orderStep) {
    case 1:
      return "checkout";
    case 2:
      return "order";
    case 3:
      return "schedule";
    case 4:
      return "complete";
  }
};

const decodeOrderStepClass = (orderStep, currentOrderStep) => {
  var completedStep = "progress-done",
    currentStep = "progress-done progress-current",
    incompletedStep = "progress-todo";
  if (parseInt(currentOrderStep) < parseInt(orderStep))
    return { className: incompletedStep, current: false, orderStep: orderStep };
  else if (parseInt(currentOrderStep) === parseInt(orderStep))
    return { className: currentStep, current: true, orderStep: orderStep };
  else if (parseInt(currentOrderStep) > parseInt(orderStep))
    return { className: completedStep, current: false, orderStep: orderStep };
  else return null;
};

const onBookXtimeAppointment = (completeOrder = false) => {
  //console.log('onBookXtimeAppointment, completeOrder: '+completeOrder);

  googleAnalyticsEnhancedTrackEvent(
    getTrackingEventCategory(),
    "X-time Booking Access"
  );
  if (completeOrder) {
    shoppingCartActions.scheduleConsumerEcommerce(null, null, null, null);
  }
  // store.dispatch(ntdModeToggle(true));
  applicationActions.onOpenNtdModal(true);
};

const isXtimeEnabled = () => {
  /*
  || (
        !isUserLoggedIn()
        && Router.collectShoppingContext().isWishlist
        && !shoppingCartViewStore.data.get('supportConsumerOrder')
      )
   */

  /*
  || (
      isUserLoggedIn()
      && applicationStore.data.dealerAllowNtdStock
    )
   */

  return checkDealerXtimeEnabled();
};

const isIntegrationEnabled = () => {
  return (
    !isEmpty(applicationStore.data.dealerAppointmentIntegrationId) &&
    hasVehicle()
  );
};

const checkDealerXtimeEnabled = () => {
  if (isUserLoggedIn()) {
    return (
      applicationStore.data.dealerEnableXtimeAppointmentIntegration !== "HIDE"
    );
  } else {
    return (
      applicationStore.data.dealerEnableXtimeAppointmentIntegration === "SHOW"
    );
  }
};

export const renderBanner = () => {
  var banner = applicationStore.data.wishlistBanner;
  if (sizeOf(banner) > 0) {
    return (
      <div className="wishlist-banner">
        <ul className="banner-list">
          {banner.map(function (banner, index) {
            return (
              <li key={"banner-" + index}>
                <a
                  href={banner.linkUrl[getAppLanguage()]}
                  target={banner.linkTarget}
                >
                  <Image src={banner.imageUrl[getAppLanguage()]} />
                  <p>{banner.subtitle[getAppLanguage()]}</p>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  } else {
    return false;
  }
};

export const renderSummary = () => {
  const cart = getCartInformation();
  const dictionary = languageStore.getDictionary();
  const date = new Date(cart?.orderCreatedTimestamp);
  let dateString = date.toDateString();
  if (getAppLanguage() === "fr") {
    dateString = convertDateToFrench(dateString);
  }
  return (
    <div className="wishlist-summary">
      {!isCartReview() && !removeOptionalFeatures() ? (
        <div className="summary-table-number">
          {dictionary.wishlist.reference} #{cart?.orderId}
        </div>
      ) : (
        false
      )}
      <div className="summary-table-date">
        {!isCartReview() && !removeOptionalFeatures() ? (
          <span className="summary-table-dash"> - </span>
        ) : (
          false
        )}
        {dateString} --
      </div>
      <div className="summary-table-time">
        <span className="summary-table-dash"> - </span>{" "}
        {date.toLocaleTimeString()}
      </div>
    </div>
  );
};

export const renderControls = (
  modal,
  state,
  updateState,
  printWishlist,
  downloadWishlist,
  emailWishlist
) => {
  if (!showConsumerStep() && !showDealerStep() && !removeOptionalFeatures()) {
    var dictionary = languageStore.getDictionary();
    return (
      <div className="wishlist-controls">
        <button
          className={setAdobeLaunchClass(
            "btn print-btn",
            ADOBELAUNCHER_CLASS.BUTTON.LINK
          )}
          data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.YOURQUOTE.BASE)}
          onClick={startNewQuote}
        >
          {dictionary.startNewQuote}
        </button>
        <button
          className={setAdobeLaunchClass(
            "btn print-btn",
            ADOBELAUNCHER_CLASS.BUTTON.LINK
          )}
          data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.YOURQUOTE.BASE)}
          onClick={printWishlist}
        >
          {dictionary.wishlist.print}
        </button>
        <button
          className={setAdobeLaunchClass(
            "btn print-btn",
            ADOBELAUNCHER_CLASS.BUTTON.LINK
          )}
          data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.YOURQUOTE.BASE)}
          onClick={downloadWishlist}
        >
          {dictionary.wishlist.download}
        </button>
        <button
          className={setAdobeLaunchClass(
            "btn",
            ADOBELAUNCHER_CLASS.BUTTON.LINK
          )}
          data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.YOURQUOTE.BASE)}
          onClick={emailWishlist}
        >
          {dictionary.quote.emailFieldLabel}
        </button>
        {!isUserLoggedIn() && modal ? (
          <button className="btn" onClick={saveQuote}>
            {dictionary.wishlist.saveAppointmentRequest}
          </button>
        ) : (
          false
        )}
        {isUserLoggedIn() && modal ? (
          <button className="btn" onClick={openMarkAsComplete}>
            {dictionary.wishlist.markAsComplete}
          </button>
        ) : (
          false
        )}
      </div>
    );
  } else {
    return false;
  }
};

const startNewQuote = () => {
  applicationActions.openStartNewQuoteModal();
};

export const onFoxyCartLaunch = () => {
  googleAnalyticsEnhancedTrackEvent(
    getTrackingEventCategory(),
    "Shopping Cart Quick View - Checkout",
    getTrackingEventOrderId()
  );

  setTimeout(() => {
    shoppingCartActions.loadConsumerOrder();
  }, 10);
};

export const onToggleFinancing = () => {
  googleAnalyticsEnhancedTrackEvent(
    getTrackingEventCategory(),
    "Shopping Cart Quick View - Toggle Finance Modal",
    getTrackingEventOrderId()
  );

  setTimeout(() => {
    shoppingCartActions.toggleFinanceModal();
  }, 10);
};

const showConsumerStep = () => {
  return (
    !isUserLoggedIn() &&
    !isCancelledOrder() &&
    (getSupportConsumerOrder() || isConsumerComplete() || isConsumerReview())
  );
};

const showDealerStep = () => {
  return (
    isUserLoggedIn() &&
    (isConsumerComplete() || isConsumerReview()) &&
    !isCancelledOrder()
  );
};

const isCancelledOrder = () => {
  var orderStatus = shoppingCartViewStore.data.get("orderStatus");
  return (
    orderStatus === "CRQ" ||
    orderStatus === "CNR" ||
    orderStatus === "CFR" ||
    orderStatus === "FFL"
  );
};

const isUnscheduledOrder = () => {
  return shoppingCartViewStore.data.get("orderStatus") === "USC";
};

const isScheduledOrder = () => {
  return shoppingCartViewStore.data.get("orderStatus") === "SCH";
};

export const isCompleteOrder = () => {
  return shoppingCartViewStore.data.get("orderStatus") === "DLD";
};

export const isConsumerComplete = () => {
  return (
    isEcommerce() && Router.collectShoppingContext().context === "complete"
  );
};

const isEcommerce = () => {
  return Router.collectShoppingContext().isEcommerce;
};

const isConsumerReview = () => {
  return isEcommerce() && Router.collectShoppingContext().context === "review";
};

const isCartReview = () => {
  return (
    applicationStore.data.supportDealerOrder ||
    applicationStore.data.supportConsumerOrder
  );
};

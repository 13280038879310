import { applicationActions } from "../../actions/ApplicationActions.js";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";
import { shoppingCartStore } from "../../stores/ShoppingCartStore.js";
import { Modal } from "../ux/Modal.jsx";
import { SelectBox } from "../ux/SelectBox.jsx";

import {
  sizeOf,
  isEmpty,
  getTrackingEventCategory,
  money_format,
  getDealerName,
} from "../../service/UtilityService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var Immutable = require("immutable");

export class PricingAnalysis extends I18nComponent {
  updateState = (state) => {
    this.setState(state);
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("Pricing Analysis");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Pricing Analysis Access"
    );
    applicationStore.addChangeListener(this.onChange);
    shoppingCartStore.addChangeListener(this.onChange);
  }

  componentWillUnmount = () => {
    applicationStore.removeChangeListener(this.onChange);
    shoppingCartStore.removeChangeListener(this.onChange);
    window.removeEventListener("keypress", this.keypress);
  };

  componentDidMount() {
    var keypress = this.keypress;
    window.addEventListener("keypress", keypress);
  }

  keypress = (evt) => {
    if (evt.keyCode === 13) {
      this.onSearch();
    }
  };

  render() {
    var translate = this.t,
      tempList = [],
      brandList = this.state.pricingAnalysisBrands,
      seasonList = this.state.pricingAnalysisSeasons,
      onSelectExtraInfo = this.onSelectExtraInfo,
      updateState = this.updateState,
      sortlist = this.state.sortlist,
      filteredlist = this.getFilteredDataList(),
      search = this.state.search;

    /*
		<box>

			<column className="tab selected">
				<container>
					<h2>{this.t('pricingAnalysisText')}</h2>
				</container>
			</column>

		</box>
		*/

    //onClick={function(){updateState({sortlist:sortlist===6||sortlist===7?sortlist===7?false:7:6})}}
    //<i className={sortlist===6?"fa fa-sort-up selected":"fa fa-sort-up"} /><i className={sortlist===7?"fa fa-sort-down selected":"fa fa-sort-down"} />

    //<th><container className="side small pointer" onClick={function(){updateState({sortlist:sortlist===14||sortlist===15?sortlist===15?false:15:14})}}><text>{this.t('maxPrice')}</text><i className={sortlist===14?"fa fa-sort-up selected":"fa fa-sort-up"} /><i className={sortlist===15?"fa fa-sort-down selected":"fa fa-sort-down"} /></container></th>

    return (
      <Modal refactor={true} requestUnmount={this.onClose} fullWidth={true}>
        <div className="wrapper">
          <div className="modal-content">
            <box className="tablepage">
              <container>
                <box className="tablefilter">
                  <container>
                    <box>
                      <h2>
                        {getDealerName() +
                          " " +
                          this.t("pricingAnalysisByTiresize")}
                      </h2>
                    </box>
                    {sizeOf(this.state.pricingAnalysisList) > 0 ? (
                      <box>
                        <column className="half">
                          <column>
                            <container className="side right">
                              <text>{this.t("filter")}</text>
                            </container>
                          </column>
                          <column>
                            <container className="side right">
                              <SelectBox
                                selected={this.state.brand}
                                dataList={brandList}
                                onSelect={this.onSelectBrand}
                              />
                            </container>
                          </column>
                          <column>
                            <container className="side right">
                              <SelectBox
                                selected={this.state.season}
                                dataList={seasonList}
                                onSelect={this.onSelectSeason}
                              />
                            </container>
                          </column>
                        </column>
                        <column className="half">
                          <column className="right">
                            <button
                              className="btn"
                              onClick={this.onResetSearch}
                            >
                              {this.t("dealerFilter.reset")}
                            </button>
                          </column>
                          <column className="right">
                            <container className="side small">
                              <button className="btn" onClick={this.onSearch}>
                                {this.t("dealerFilter.search")}
                              </button>
                            </container>
                          </column>
                          <column className="right">
                            <input
                              id="table-search"
                              placeholder={this.t("search.tiresizeSearch")}
                              defaultValue={this.state.search}
                            />
                          </column>
                        </column>
                      </box>
                    ) : (
                      false
                    )}
                    {sizeOf(this.state.pricingAnalysisList) === 0 ? (
                      <box>
                        <column className="half">
                          <column className="input">
                            <input
                              id="table-search"
                              placeholder={this.t(
                                "search.pricingAnalysisSearch"
                              )}
                            />
                          </column>
                          <column>
                            <container className="side small">
                              <button className="btn" onClick={this.onSearch}>
                                {this.t("dealerFilter.search")}
                              </button>
                            </container>
                          </column>
                          <column>
                            <button
                              className="btn"
                              onClick={this.onResetSearch}
                            >
                              {this.t("dealerFilter.reset")}
                            </button>
                          </column>
                        </column>
                      </box>
                    ) : (
                      false
                    )}
                    <box>
                      <container className="top large">
                        <column
                          className={
                            sizeOf(this.state.pricingAnalysisList) > 0
                              ? "right"
                              : ""
                          }
                        >
                          <button className="btn cms" onClick={this.onLoginCMS}>
                            {this.t("openCMS")}
                          </button>
                        </column>
                      </container>
                    </box>
                  </container>
                </box>
                <box>
                  <container className="top">
                    <table>
                      <thead>
                        <th>
                          <container
                            className="side small pointer"
                            onClick={function () {
                              updateState({
                                sortlist:
                                  sortlist === 0 || sortlist === 1
                                    ? sortlist === 1
                                      ? false
                                      : 1
                                    : 0,
                              });
                            }}
                          >
                            <text>{this.t("brand")}</text>
                            <i
                              className={
                                sortlist === 0
                                  ? "fa fa-sort-up selected"
                                  : "fa fa-sort-up"
                              }
                            />
                            <i
                              className={
                                sortlist === 1
                                  ? "fa fa-sort-down selected"
                                  : "fa fa-sort-down"
                              }
                            />
                          </container>
                        </th>
                        <th>
                          <container
                            className="side small pointer"
                            onClick={function () {
                              updateState({
                                sortlist:
                                  sortlist === 2 || sortlist === 3
                                    ? sortlist === 3
                                      ? false
                                      : 3
                                    : 2,
                              });
                            }}
                          >
                            <text>{this.t("product.name")}</text>
                            <i
                              className={
                                sortlist === 2
                                  ? "fa fa-sort-up selected"
                                  : "fa fa-sort-up"
                              }
                            />
                            <i
                              className={
                                sortlist === 3
                                  ? "fa fa-sort-down selected"
                                  : "fa fa-sort-down"
                              }
                            />
                          </container>
                        </th>
                        <th>
                          <container
                            className="side small pointer"
                            onClick={function () {
                              updateState({
                                sortlist:
                                  sortlist === 4 || sortlist === 5
                                    ? sortlist === 5
                                      ? false
                                      : 5
                                    : 4,
                              });
                            }}
                          >
                            <text>{this.t("product.partNumber")}</text>
                            <i
                              className={
                                sortlist === 4
                                  ? "fa fa-sort-up selected"
                                  : "fa fa-sort-up"
                              }
                            />
                            <i
                              className={
                                sortlist === 5
                                  ? "fa fa-sort-down selected"
                                  : "fa fa-sort-down"
                              }
                            />
                          </container>
                        </th>
                        <th>
                          <container className="side small">
                            <text>{this.t("product.tireSize")}</text>
                          </container>
                        </th>
                        <th>
                          <container
                            className="side small pointer"
                            onClick={function () {
                              updateState({
                                sortlist:
                                  sortlist === 8 || sortlist === 9
                                    ? sortlist === 9
                                      ? false
                                      : 9
                                    : 8,
                              });
                            }}
                          >
                            <text>{this.t("yourPrice")}</text>
                            <i
                              className={
                                sortlist === 8
                                  ? "fa fa-sort-up selected"
                                  : "fa fa-sort-up"
                              }
                            />
                            <i
                              className={
                                sortlist === 9
                                  ? "fa fa-sort-down selected"
                                  : "fa fa-sort-down"
                              }
                            />
                          </container>
                        </th>
                        <th>
                          <container
                            className="side small pointer"
                            onClick={function () {
                              updateState({
                                sortlist:
                                  sortlist === 10 || sortlist === 11
                                    ? sortlist === 11
                                      ? false
                                      : 11
                                    : 10,
                              });
                            }}
                          >
                            <text>{this.t("minPrice")}</text>
                            <i
                              className={
                                sortlist === 10
                                  ? "fa fa-sort-up selected"
                                  : "fa fa-sort-up"
                              }
                            />
                            <i
                              className={
                                sortlist === 11
                                  ? "fa fa-sort-down selected"
                                  : "fa fa-sort-down"
                              }
                            />
                          </container>
                        </th>
                        <th>
                          <container
                            className="side small pointer"
                            onClick={function () {
                              updateState({
                                sortlist:
                                  sortlist === 12 || sortlist === 13
                                    ? sortlist === 13
                                      ? false
                                      : 13
                                    : 12,
                              });
                            }}
                          >
                            <text>{this.t("average")}</text>
                            <i
                              className={
                                sortlist === 12
                                  ? "fa fa-sort-up selected"
                                  : "fa fa-sort-up"
                              }
                            />
                            <i
                              className={
                                sortlist === 13
                                  ? "fa fa-sort-down selected"
                                  : "fa fa-sort-down"
                              }
                            />
                          </container>
                        </th>

                        <th>
                          <container
                            className="side small pointer"
                            onClick={function () {
                              updateState({
                                sortlist:
                                  sortlist === 14 || sortlist === 15
                                    ? sortlist === 15
                                      ? false
                                      : 15
                                    : 14,
                              });
                            }}
                          >
                            <text>{this.t("quantityBooked")}</text>
                            <i
                              className={
                                sortlist === 14
                                  ? "fa fa-sort-up selected"
                                  : "fa fa-sort-up"
                              }
                            />
                            <i
                              className={
                                sortlist === 15
                                  ? "fa fa-sort-down selected"
                                  : "fa fa-sort-down"
                              }
                            />
                          </container>
                        </th>
                        <th>
                          <container
                            className="side small pointer"
                            onClick={function () {
                              updateState({
                                sortlist:
                                  sortlist === 16 || sortlist === 17
                                    ? sortlist === 17
                                      ? false
                                      : 17
                                    : 16,
                              });
                            }}
                          >
                            <text>{this.t("quantitySold")}</text>
                            <i
                              className={
                                sortlist === 16
                                  ? "fa fa-sort-up selected"
                                  : "fa fa-sort-up"
                              }
                            />
                            <i
                              className={
                                sortlist === 17
                                  ? "fa fa-sort-down selected"
                                  : "fa fa-sort-down"
                              }
                            />
                          </container>
                        </th>

                        <th
                          className="wide pointer"
                          onClick={function () {
                            updateState({
                              sortlist:
                                sortlist === 18 || sortlist === 19
                                  ? sortlist === 19
                                    ? false
                                    : 19
                                  : 18,
                            });
                          }}
                        >
                          <i
                            className={
                              sortlist === 18
                                ? "fa fa-sort-up selected"
                                : "fa fa-sort-up"
                            }
                          />
                          <i
                            className={
                              sortlist === 19
                                ? "fa fa-sort-down selected"
                                : "fa fa-sort-down"
                            }
                          />
                        </th>
                      </thead>

                      <tbody>
                        {sizeOf(filteredlist) > 0 ? (
                          Immutable.fromJS(filteredlist)
                            .sort(this.sortList)
                            .map(function (data) {
                              var min,
                                diff,
                                minText = "",
                                minClass = "";

                              if (
                                parseFloat(data.get(4)) >
                                parseFloat(data.get(5))
                              ) {
                                minClass = "over";
                                diff =
                                  parseFloat(data.get(4)) -
                                  parseFloat(data.get(5));
                                min = (diff / parseFloat(data.get(5))) * 100;
                                minText =
                                  min.toFixed(Math.round(min) !== min ? 2 : 0) +
                                  translate("minHigher");
                              } else if (
                                parseFloat(data.get(4)) <
                                parseFloat(data.get(5))
                              ) {
                                minClass = "under";
                                diff =
                                  parseFloat(data.get(5)) -
                                  parseFloat(data.get(4));
                                min = (diff / parseFloat(data.get(5))) * 100;
                                minText =
                                  min.toFixed(Math.round(min) !== min ? 2 : 0) +
                                  translate("minLower");
                              }

                              //<td className="right"><container className="side">{money_format(data.get(7))}</container></td>

                              return (
                                <tr>
                                  <td>
                                    <container className="side">
                                      {data.get(0)}
                                    </container>
                                  </td>
                                  <td>
                                    <container className="side">
                                      {data.get(1)}
                                    </container>
                                  </td>
                                  <td>
                                    <container className="side">
                                      <a
                                        onClick={onSelectExtraInfo.bind(
                                          null,
                                          data.get(10),
                                          data.get(11)
                                        )}
                                      >
                                        {data.get(2)}
                                      </a>
                                    </container>
                                  </td>
                                  <td>
                                    <container className="side">
                                      {data.get(3)}
                                    </container>
                                  </td>
                                  <td className="right">
                                    <container className="side">
                                      {money_format(data.get(4))}
                                    </container>
                                  </td>
                                  <td className="right">
                                    <container className="side">
                                      {money_format(data.get(5))}
                                    </container>
                                  </td>
                                  <td className="right">
                                    <container className="side">
                                      {money_format(data.get(6))}
                                    </container>
                                  </td>
                                  <td className="right">
                                    <container className="side">
                                      {data.get(7)}
                                    </container>
                                  </td>
                                  <td className="right">
                                    <container className="side">
                                      {data.get(8)}
                                    </container>
                                  </td>
                                  <td>
                                    <container className="side small">
                                      <text className={minClass}>
                                        {minText}
                                      </text>
                                    </container>
                                  </td>
                                </tr>
                              );
                            })
                        ) : !isEmpty(search) ? (
                          <tr>
                            <td colSpan="10">
                              <container>
                                {this.t("dealerFilter.searchResultsEmpty")}
                              </container>
                            </td>
                          </tr>
                        ) : (
                          <tr></tr>
                        )}
                      </tbody>
                    </table>
                  </container>
                </box>
              </container>
            </box>
          </div>
        </div>
        {this.renderExtraInfoModal()}
      </Modal>
    );
  }

  sortList = (a, b) => {
    var index = parseInt(this.state.sortlist / 2);
    var reverse = this.state.sortlist % 2 === 1;

    switch (index) {
      case 0:
      case 1:
      case 2:
      case 3:
        if (a.get(index) < b.get(index)) {
          return reverse ? 1 : -1;
        } else if (a.get(index) > b.get(index)) {
          return reverse ? -1 : 1;
        } else {
          return 0;
        }

      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
        return reverse
          ? b.get(index) - a.get(index)
          : a.get(index) - b.get(index);

      case 9:
        var diffA, diffB, minA, minB, green;

        if (parseFloat(a.get(4)) > parseFloat(b.get(4))) {
          diffA = parseFloat(a.get(4)) - parseFloat(a.get(5));
          minA = (diffA / parseFloat(a.get(5))) * 100;

          diffB = parseFloat(b.get(4)) - parseFloat(b.get(5));
          minB = (diffB / parseFloat(b.get(5))) * 100;

          green = !reverse;
        } else {
          diffA = parseFloat(a.get(5)) - parseFloat(a.get(4));
          minA = (diffA / parseFloat(a.get(5))) * 100;

          diffB = parseFloat(b.get(5)) - parseFloat(b.get(4));
          minB = (diffB / parseFloat(b.get(5))) * 100;

          green = reverse;
        }

        return green ? minA - minB : minB - minA;

      default:
        return true;
    }
  };

  renderExtraInfoModal = () => {
    if (!isEmpty(this.state.cost)) {
      return (
        <Modal
          refactor={true}
          useShortHeaderBar={true}
          requestUnmount={this.onSelectExtraInfo.bind(null, "", "")}
        >
          <div className="wrapper">
            <div className="modal-content">
              <span>
                {this.t("dealerCost")}: {money_format(this.state.cost)}
              </span>
              {parseFloat(this.state.booking) > 0 ? (
                <span>
                  {this.t("dealerBooking")}: {money_format(this.state.booking)}
                </span>
              ) : (
                false
              )}
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  getSeasonList = () => {
    return [
      { key: -1, value: this.t("product.filters.season.noSelection") },
      { key: 0, value: this.t("product.filters.season.winter") },
      { key: 1, value: this.t("product.filters.season.allSeason") },
      { key: 2, value: this.t("product.filters.season.summer") },
    ];
  };

  getFilteredDataList = () => {
    var dataList = this.state.pricingAnalysisList;

    var filteredList = [];
    for (var i in dataList) {
      if (
        (this.state.brand == 0 || dataList[i][0] == this.state.brand) &&
        (this.state.season == -1 || dataList[i][9] == this.state.season) &&
        (isEmpty(this.state.search) ||
          dataList[i][1].indexOf(this.state.search) > -1 ||
          dataList[i][2].indexOf(this.state.search) > -1 ||
          dataList[i][3].indexOf(this.state.search) > -1)
      ) {
        filteredList.push(dataList[i]);
      }
    }

    return filteredList;
  };

  onSelectBrand = (brand) => {
    this.setState({
      brand: brand,
    });
  };

  onSelectSeason = (season) => {
    this.setState({
      season: season,
    });
  };

  onSelectExtraInfo = (cost, booking) => {
    this.setState({
      cost: cost,
      booking: booking,
    });
  };

  onSearch = () => {
    var search = document.getElementById("table-search")
      ? document.getElementById("table-search").value
      : "";
    if (!isEmpty(search)) {
      shoppingCartActions.getTirePricingAnalysis(search);
    }
    this.setState({
      search: search,
    });
  };

  onResetSearch = () => {
    if (document.getElementById("table-search")) {
      document.getElementById("table-search").value = "";
    }
    /*
    this.setState({
      pricingAnalysisList: [],
      pricingAnalysisBrands: [],
    });
    */
  };

  onLoginCMS = () => {
    window.open(applicationStore.getCmsLoginLink(), "_blank");
  };

  onClose = () => {
    applicationActions.pricingAnalysisToggle();
  };

  onChange = () => {
    this.setState({
      pricingAnalysisList: shoppingCartStore.data.get("pricingAnalysisList"),
      pricingAnalysisBrands: shoppingCartStore.data.get(
        "pricingAnalysisBrands"
      ),
    });
  };

  state = {
    pricingAnalysisList: shoppingCartStore.data.get("pricingAnalysisList"),
    pricingAnalysisBrands: shoppingCartStore.data.get("pricingAnalysisBrands"),
    pricingAnalysisSeasons: this.getSeasonList(),

    search: "",
    brand: 0,
    season: -1,

    sortlist: false,

    cost: "",
    booking: "",
  };
}

import { getTabIndex, sizeOf, isEmpty } from "../../../service/UtilityService";
import { getSeasonaIcon } from "../../../service/FilterService";

var React = require("react");
var Cx = require("classnames");
var _ = require("lodash");

export class CheckBoxItem extends React.Component {
  render() {
    return (
      <li
        id={this.props.id ? this.props.id : ""}
        className={this.getClassName()}
        data-dtm={this.props.datadtm}
        tabIndex={getTabIndex()}
        onKeyDown={(e) => this.onValueClicked(e)}
        onClick={
          typeof this.props.onClick !== "undefined"
            ? this.props.onClick
            : this.onValueClicked
        }
        disabled={this.props.disabled}
      >
        {getSeasonaIcon(this.props.seasonalIcon, this.props.value)}{" "}
        {this.props.children}
      </li>
    );
  }

  getClassName = () => {
    return Cx({
      "filter-list": true,
      "show-focus": true,
      "filter-option-disabled": this.props.disabled,
      "filter-hide": this.props.hidden,
      selected:
        !this.props.disabled &&
        (this.props.value == this.props.current ||
          this.props.selected ||
          this.props.onlyChild),
      [this.props.className]: !isEmpty(this.props.className),
    });
  };

  onValueClicked = (evt) => {
    checkForEvent: if ("undefined" != typeof evt.keyCode) {
      if (evt.keyCode == 13 || evt.keyCode == 32) {
        evt.preventDefault();
        evt.stopPropagation();
        break checkForEvent;
      }
      return;
    }
    if (!this.props.disabled) {
      this.props.onValueClicked(this.props.value);
    }
  };
}

export class CheckBoxList extends React.Component {
  static defaultProps = {
    onChange: function () {},
    selectAllText: "Select All",
    deselectAllText: "Deselect All",
    singleSelect: false,
  };

  render() {
    var className = this.props.singleSelect ? "radio-list" : "checkbox-list",
      onlyChild = sizeOf(this.props.children) === 1;
    if (this.props.tireSizeFilter)
      className = this.props.singleSelect
        ? "radio-list tireSizeFilter"
        : "checkbox-list tireSizeFilter";
    return (
      <div
        id={this.props.id ? this.props.id : ""}
        className={
          className + (this.props.className ? " " + this.props.className : "")
        }
      >
        {this.renderSelectDeselectAll()}
        <ul>
          {React.Children.map(
            this.props.children,
            function (child) {
              return React.cloneElement(child, {
                onValueClicked: this.onValueClicked,
                onlyChild: onlyChild,
              });
            },
            this
          )}
        </ul>
      </div>
    );
  }

  renderSelectDeselectAll = () => {
    if (!this.props.singleSelect) {
      return (
        <p>
          <a onClick={this.onSelectAll}>{this.props.selectAllText}</a> /{" "}
          <a onClick={this.onDeselectAll}>{this.props.deselectAllText}</a>
        </p>
      );
    } else {
      return false;
    }
  };

  onValueClicked = (value) => {
    if (this.props.singleSelect) {
      this.props.onChange(value);
    } else {
      var items = [];
      _.each(this.props.children, function (child) {
        if (child && child.props && child.props.selected) {
          items.push(child.props.value);
        }
      });

      if (_.contains(items, value)) {
        items = _.without(items, value);
      } else {
        items.push(value);
      }
      this.props.onChange(items);
    }
  };

  onSelectAll = () => {
    if (!this.props.onSelectAll) {
      if (!this.singleSelect) {
        var items = _.map(this.props.children, function (child) {
          return child.props.value;
        });

        this.props.onChange(items);
      }
    } else {
      this.props.onSelectAll();
    }
  };

  onDeselectAll = () => {
    if (!this.props.onDeselectAll) {
      if (!this.singleSelect) {
        this.props.onChange([]);
      }
    } else {
      this.props.onDeselectAll();
    }
  };
}

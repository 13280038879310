import { Modal } from "../ux/Modal.jsx";

import { applicationStore } from "../../stores/ApplicationStore.js";
import { productListStore } from "../../stores/ProductListStore.js";
import { productListActions } from "../../actions/ProductListActions.js";

import { EmailField } from "../ux/forms/EmailField.jsx";
import { renderShowResultsDiv } from "../../service/RenderService";
import { connect } from "react-redux";
import { anOpenEmailPromotionalModal } from "../../redux/features/productList.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var _ = require("lodash");

class EmailPromotionModal extends I18nComponent {
  state = {
    emailTouched: false,
    emailValid: false,
    emailValue: "",

    promotionModalLink: productListStore.data.promotionModalLink,
    promotionModalDisclaimer: productListStore.data.promotionModalDisclaimer,
    emailHasBeenSent: productListStore.data.promotionEmailSent,
    emailHasSucceeded: productListStore.data.promotionEmailSentSuccessfully,
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
    productListStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    productListStore.removeChangeListener(this.onChange);
    applicationStore.removeChangeListener(this.onChange);
  }

  render() {
    return (
      <Modal
        key={"modal"}
        requestUnmount={this.onRequestCloseModal}
        applyTemplateStyle={true}
      >
        <div className="wrapper">
          <div className="email-promotion-modal">
            <div className="modal-title">
              <h2>{this.t("emailPromotion.headTag")}</h2>
            </div>
            <div className="quote-controls">
              <form onSubmit={this.onFormSubmit}>
                <div className="inputs">
                  <fieldset className="contact-information">
                    <EmailField
                      onChange={this.onEmailChange}
                      touched={this.state.emailTouched}
                      value={
                        this.state.emailValue &&
                        this.state.emailValue != null &&
                        this.state.emailValue != "null"
                          ? this.state.emailValue
                          : ""
                      }
                      label={this.t("emailPromotion.emailLabel")}
                      id="userEmailField"
                      errorNotEmpty={this.t("validation.notEmpty")}
                      errorInvalidEmail={this.t("validation.email")}
                    />
                  </fieldset>
                  <button className="btn">
                    {this.t("emailPromotion.emailButton")}
                  </button>
                  {this.state.promotionModalLink !== "// " ? (
                    <a target="_blank" href={this.state.promotionModalLink}>
                      {this.t("emailPromotion.viewRebateButton")}
                    </a>
                  ) : (
                    false
                  )}
                </div>
              </form>

              {renderShowResultsDiv(
                this.state.emailHasBeenSent,
                this.state.emailHasSucceeded,
                this.state.emailHasSucceeded
                  ? this.t("emailPromotion.emailSentSuccess")
                  : this.t("emailPromotion.emailSentFailure")
              )}
            </div>
            <hr className="modal-foot-seperator" />
            {this.state.promotionModalDisclaimer ? (
              <div className="modal-footer">
                <h2>{this.state.promotionModalDisclaimer}</h2>
              </div>
            ) : (
              false
            )}
          </div>
        </div>
      </Modal>
    );
  }

  onEmailChange = (value, valid) => {
    this.setState({
      emailValue: value,
      emailValid: valid,
      emailTouched: true,
    });
  };

  canBeSubmitted = () => {
    return this.state.emailTouched && this.state.emailValid;
  };

  onFormSubmit = (ev) => {
    this.setState({
      emailTouched: true,
    });

    if (this.canBeSubmitted()) {
      productListActions.onEmailPromotion(
        this.props.itemType,
        this.props.itemId,
        this.props.pricingContextId,
        this.state.emailValue
      );
    }

    ev.preventDefault();
    ev.stopPropagation();
  };

  onRequestCloseModal = () => {
    this.props.dispatch(
      anOpenEmailPromotionalModal({
        itemType: null,
        itemId: null,
        url: null,
        disclaimer: null,
      })
    );
    productListActions.onCloseEmailPromotionModal();
  };

  onChange = () => {
    this.setState({
      promotionModalLink: productListStore.data.promotionModalLink,
      promotionModalDisclaimer: productListStore.data.promotionModalDisclaimer,
      emailHasBeenSent: productListStore.data.promotionEmailSent,
      emailHasSucceeded: productListStore.data.promotionEmailSentSuccessfully,
    });
  };
}

export default connect(null)(EmailPromotionModal);

import { applicationStore } from "../../stores/ApplicationStore.js";
import { applicationActions } from "../../actions/ApplicationActions.js";
import { dealerSelectionStore } from "../../stores/DealerSelectionStore.js";
import { dealerSelectionActions } from "../../actions/DealerSelectionActions.js";
import { searchActions } from "../../actions/SearchActions.js";

import { SelectBox } from "../ux/SelectBox.jsx";
import { Modal } from "../ux/Modal.jsx";

import { ProductTypes } from "../../constants/ProductTypes";
import { SearchTypes } from "../../constants/SearchTypes";

import { isUserLoggedIn } from "../../api/AuthService";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";
import { getTestId, TEST_LIST } from "../../service/TestingService";
import {
  getTabIndex,
  isEmpty,
  generateGUID,
  isDealerSite,
  isNationalSite,
  isGroupSite,
  nationalSiteHasDealerSelected,
  getDealerUrlCode,
  getAppLanguage,
  getAppDealerId,
  runTiresizeSearch,
  validatePostal,
  validateZip,
  getDealerName,
  isMobile,
  getAppTemplate,
} from "../../service/UtilityService";
import { connect } from "react-redux";
import {
  onSelectModel,
  toggleSearch,
} from "../../redux/features/applicationInfo.js";
import { onSelectMake } from "../../redux/features/applicationInfo";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var _ = require("lodash");

class TopLauncher extends I18nComponent {
  constructor(props) {
    super(props);
    var productList = this.props.partLauncher ? this.loadProductTypes() : [];
    var product = this.props.partLauncher
      ? applicationStore.data.searchProductType
      : null;
    let makeList = this.props.launcherVehicleMakes
      ? this.props.launcherVehicleMakes
      : [];
    // let make = this.props.selectedMake ? this.props.selectedMake : null;
    this.state = {
      tab: true,

      yearList: [],
      makeList: makeList,
      modelList: [],
      productList: productList,
      dealerList: [],

      year: null,
      make: null,
      model: null,
      product: product,
      dealer: null,

      postalId: generateGUID(true),
      tiresizeId: generateGUID(true),

      width: window.innerWidth,

      error: false,
    };
  }

  updateState = (state) => {
    this.setState(state);
  };

  loadProductTypes = () => {
    var productList = [];
    if (_.contains(applicationStore.data.productTypes, ProductTypes.TIRES)) {
      productList.push({
        key: ProductTypes.TIRES,
        value: this.t("productTypes.tires"),
      });
    }
    if (
      _.contains(applicationStore.data.productTypes, ProductTypes.ALLOY_WHEELS)
    ) {
      productList.push({
        key: ProductTypes.ALLOY_WHEELS,
        value: this.t("productTypes.wheels"),
      });
    }
    if (
      _.contains(applicationStore.data.productTypes, ProductTypes.ACCESSORIES)
    ) {
      productList.push({
        key: ProductTypes.ACCESSORIES,
        value: this.t("productTypes.accessories"),
      });
    }
    if (_.contains(applicationStore.data.productTypes, ProductTypes.PACKAGES)) {
      productList.push({
        key: ProductTypes.PACKAGES,
        value: this.t("productTypes.packages"),
      });
    }
    return productList;
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
    window.removeEventListener("keypress", this.keypress);
  }

  componentDidMount() {
    this.initialLoad();
    //setTimeout(() => { this.initialLoad(); }, 0);
    var updateState = this.updateState;
    window.addEventListener("resize", function () {
      updateState({
        width: window.innerWidth,
      });
    });
    var keypress = this.keypress;
    window.addEventListener("keypress", keypress);
  }

  initialLoad = () => {
    if (this.props.bottomlauncher) {
      applicationActions.loadLauncherYearSelection(ProductTypes.TIRES);
    } else if (this.props.partLauncher) {
      applicationActions.loadLauncherYearSelection(
        applicationStore.data.searchProductType
      );
    } else {
      applicationActions.loadLauncherYearSelection();
    }
    if (this.dealerSelectionEnabled()) {
      // this.props.dispatch(loadListOfDealersForGroup())
      dealerSelectionActions.loadListOfDealersForGroup();
    }
  };

  keypress = (evt) => {
    if (evt.keyCode === 13) {
      if (this.getTabIndex()) {
        this.onVehicleSearch();
      } else {
        this.onTiresizeSearch();
      }
    }
  };

  getTabIndex = () => {
    return this.state.tab;
  };

  componentDidUpdate(nextProps, lastState) {
    //inivisCaptchaOnComponentDidUpdate(this.state, this.updateState, [this.onVehicleSearch, this.onTiresizeSearch]);
  }

  isFloatRight = () => {
    switch (applicationStore.data.homepageLauncherFloatOverCarousel) {
      case "RIGHT":
        return !this.props.bottomlauncher;
      default:
        return false;
    }
  };

  render() {
    if (this.props.bottomlauncher) {
      return this.renderLauncherBoxBottom();
    } else if (this.props.partLauncher) {
      switch (applicationStore.data.searchProductType) {
        case ProductTypes.TIRES:
        case ProductTypes.ALLOY_WHEELS:
        case ProductTypes.ACCESSORIES:
        case ProductTypes.PACKAGES:
        default:
          return this.renderLauncherPartBox();
      }
    } else {
      var isMobile = parseInt(this.state.width) < 1042;
      if (isMobile || this.enableExtendedMobileView()) {
        return this.renderLauncherBox("launcher");
      } else {
        switch (applicationStore.data.homepageLauncherFloatOverCarousel) {
          case "TOP":
            return this.renderTopLauncher();
          case "LEFT":
            return this.renderLauncherBox("launcher float-left");
          case "RIGHT":
            return this.renderLauncherBox(
              !isNationalSite()
                ? "launcher float-right"
                : "launcher float-right national-site"
            );
          case "BESIDE_LEFT":
            return this.renderLauncherBox("launcher beside-left");
          default:
            return false;
        }
      }
    }
  }

  enableExtendedMobileView = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "volkswagen_2020":
        return parseInt(this.state.width) < 1240;
      default:
        return false;
    }
  };

  renderLauncherBox = (className) => {
    return (
      <box className={className}>
        {this.enableLauncherBoxTitle() ? (
          <box
            className={
              isDealerSite() || nationalSiteHasDealerSelected()
                ? "toptitle has-dealer-name"
                : "toptitle"
            }
          >
            {this.renderDealerName()}
            <h2 className="title">{this.t("launcher.title")}</h2>
          </box>
        ) : (
          false
        )}
        <center>{this.renderLauncherFrame()}</center>
      </box>
    );
  };

  renderDealerName = () => {
    if (isDealerSite() || nationalSiteHasDealerSelected()) {
      return <h3 className="title">{getDealerName()}</h3>;
    } else {
      return false;
    }
  };

  enableLauncherBoxTitle = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "volkswagen_2020":
        return true;
      default:
        return false;
    }
  };

  renderLauncherBoxBottom = () => {
    return (
      <box className="launcher bottom">
        <container>
          <box className="background">
            <container className="side xlarge">
              <column className="mobilefullwidth">
                <box>
                  <text>{this.t("launcher.selectYear")}</text>
                </box>
                <box>
                  {this.renderSelectBoxColumn(
                    true,
                    this.state.year,
                    this.props.launcherVehicleYears,
                    this.onSelectYear,
                    this.t("launcher.selectYear"),
                    false,
                    true,
                    this.props.yearselect
                  )}
                </box>
              </column>
              <column className="mobilefullwidth">
                <box>
                  <text>{this.t("launcher.selectMake")}</text>
                </box>
                <box>
                  {this.renderSelectBoxColumn(
                    true,
                    this.state.make,
                    this.state.makeList,
                    this.onSelectMake,
                    this.t("launcher.selectMake"),
                    this.state.year,
                    true
                  )}
                </box>
              </column>
              <column className="mobilefullwidth">
                <box>
                  <text>{this.t("launcher.selectModel")}</text>
                </box>
                <box>
                  {this.renderSelectBoxColumn(
                    true,
                    this.state.model,
                    this.state.modelList,
                    this.onSelectModel,
                    this.t("launcher.selectModel"),
                    this.state.year,
                    true
                  )}
                </box>
              </column>
              {this.enableBottomLauncherPartType() ? (
                <column className="mobilefullwidth">
                  <box>
                    <text>{this.t("launcher.productCategory")}</text>
                  </box>
                  <box>
                    {this.renderSelectBoxColumn(
                      true,
                      this.state.product,
                      this.state.productList,
                      this.onSelectProduct,
                      this.t("launcher.productCategory"),
                      this.state.model,
                      true
                    )}
                  </box>
                </column>
              ) : (
                false
              )}
              {this.showPostalCodeInput() ? (
                <column>
                  <box>
                    <text>{this.t("launcher.selectPostal")}</text>
                  </box>
                  <box>
                    {this.renderInputColumn(
                      true,
                      this.state.postalId,
                      this.t("launcher.enterPostal").toUpperCase(),
                      true,
                      true
                    )}
                  </box>
                </column>
              ) : (
                false
              )}
              <column className="mobilefullwidth fixheight">
                <box>
                  <container className="no-left">
                    {this.renderCaptchaButton(1, "btn", this.onVehicleSearch)}
                  </container>
                </box>
              </column>
            </container>
          </box>
          {this.renderErrorModal()}
        </container>
      </box>
    );
  };

  enableBottomLauncherPartType = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "hyundai":
        return this.props.bottomlauncher;
      default:
        return false;
    }
  };

  renderLauncherPartBox = () => {
    return (
      <box className="launcher fullscreen">
        <center>
          {this.state.product === ProductTypes.TIRES ? (
            <box className="frame top">
              <column
                id={getTestId(
                  TEST_LIST.LAUNCHER.TAB,
                  this.t("launcher.shopByVehicle")
                )}
                className={
                  this.state.tab ? "half-a  selected" : "half-a  pointer"
                }
                onClick={this.toggleTabIndex.bind(null, true)}
              >
                <container>
                  <text className="bold">
                    {this.t("launcher.shopByVehicle")}
                  </text>
                </container>
              </column>
              <column
                id={getTestId(
                  TEST_LIST.LAUNCHER.TAB,
                  this.t("launcher.shopBySize")
                )}
                className={
                  !this.state.tab ? "half-a  selected" : "half-a  pointer"
                }
                onClick={this.toggleTabIndex.bind(null, false)}
              >
                <container>
                  <text className="bold">{this.t("launcher.shopBySize")}</text>
                </container>
              </column>
            </box>
          ) : (
            false
          )}
          {this.renderLauncherPartFrame()}
        </center>
      </box>
    );
  };

  renderLauncherFrame = () => {
    return (
      <box className="frame">
        <box className="background">
          <column
            id={getTestId(
              TEST_LIST.LAUNCHER.TAB,
              this.t("launcher.shopByVehicle")
            )}
            className={this.getColumnClass(true)}
          >
            <button
              className={this.getTabClass(true)}
              data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.TIREFINDER)}
              onClick={this.toggleTab.bind(null, true)}
            >
              <h2 className="bold">{this.t("launcher.shopByVehicle")}</h2>
            </button>
          </column>
          {applicationStore.data.allowSearchByTireSize ||
          (isUserLoggedIn() &&
            applicationStore.data.allowAuthenticatedSearchByTireSize) ? (
            <column
              id={getTestId(
                TEST_LIST.LAUNCHER.TAB,
                this.t("launcher.shopBySize")
              )}
              className={this.getColumnClass(false)}
            >
              <button
                className={this.getTabClass(false)}
                data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.TIREFINDER)}
                onClick={this.toggleTab.bind(null, false)}
              >
                <h2 className="bold">{this.t("launcher.shopBySize")}</h2>
              </button>
            </column>
          ) : (
            false
          )}
        </box>
        {this.state.tab ? (
          <box
            className="background"
            style={{ paddingTop: 10, paddingBottom: 10 }}
          >
            <box>
              <container className={this.getUpperContainerClass()}>
                {this.renderSelectBoxColumn(
                  true,
                  this.state.year,
                  this.props.launcherVehicleYears,
                  this.onSelectYear,
                  this.t("launcher.selectYear"),
                  false,
                  false,
                  this.props.yearselect
                )}
              </container>
            </box>
            <box>
              <container className={this.getUpperContainerClass()}>
                {this.renderSelectBoxColumn(
                  true,
                  this.state.make,
                  this.state.makeList,
                  this.onSelectMake,
                  this.t("launcher.selectMake"),
                  this.state.year
                )}
              </container>
            </box>
            <box>
              <container className={this.getUpperContainerClass()}>
                {this.renderSelectBoxColumn(
                  true,
                  this.state.model,
                  this.state.modelList,
                  this.onSelectModel,
                  this.t("launcher.selectModel"),
                  this.props.selectedMake
                )}
              </container>
            </box>
            <box>
              <container className={this.getUpperContainerClass()}>
                {this.renderSelectBoxColumn(
                  !this.showDealerSelection(),
                  this.state.product,
                  this.state.productList,
                  this.onSelectProduct,
                  this.t("launcher.productCategory"),
                  this.state.model
                )}
              </container>
            </box>
            <box>
              <container className={this.getUpperContainerClass()}>
                {this.renderInputColumn(
                  !this.showDealerSelection() && this.showPostalCodeInput(),
                  this.state.postalId,
                  this.t("launcher.enterPostal"),
                  false,
                  false,
                  true
                )}
              </container>
            </box>
            <box>
              <container className={this.getUpperContainerClass()}>
                {this.renderSelectBoxColumn(
                  this.showDealerSelection(),
                  this.state.dealer,
                  this.getLauncherDealerList(),
                  this.onSelectDealer,
                  this.t("launcher.selectYourDealer"),
                  this.state.model
                )}
              </container>
            </box>
            {this.renderVehicleSearchButton()}
          </box>
        ) : (
          <box className="background">
            <box className="titlebox">
              <container>
                <h2 className="bold">{this.t("launcher.findTires")}</h2>
              </container>
            </box>
            <box>
              {this.renderInputColumn(
                true,
                this.state.tiresizeId,
                this.t("launcher.enterTiresize"),
                false,
                false,
                false
              )}
            </box>
            <box>
              {this.renderInputColumn(
                !this.showDealerSelection() && this.showPostalCodeInput(),
                this.state.postalId,
                this.t("launcher.enterPostal"),
                false,
                false,
                false
              )}
            </box>
            <box>
              {this.renderSelectBoxColumn(
                this.showDealerSelection(),
                this.state.dealer,
                this.getLauncherDealerList(),
                this.onSelectDealer,
                this.t("launcher.selectYourDealer"),
                false
              )}
            </box>
            <box className="helpbox">
              <span
                className={setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.TEXT)}
                data-dtm={setAdobeLaunchDTM(
                  "",
                  ADOBELAUNCHER_DTM.SHOPBYTIRESIZE
                )}
                onClick={this.toggleTireSizeHelp}
              >
                <column>
                  <i className="fa fa-question-circle" />
                </column>
                <column>{this.t("launcher.howFindTireSize")}</column>
              </span>
            </box>
            {this.renderTiresizeSearchButton()}
          </box>
        )}
        {this.renderErrorModal()}
      </box>
    );
  };

  getLauncherTitleStyle = () => {
    if (isMobile() && getAppLanguage() == "fr") {
      return { fontSize: "13px" };
    } else {
      return {};
    }
  };

  renderVehicleSearchButton = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "volkswagen_2020":
        return (
          <box>
            <container>
              {this.renderCaptchaButton(
                1,
                "btn",
                this.onVehicleSearch,
                this.t("map.buyNow"),
                true
              )}
              {this.renderCaptchaButton(
                1,
                "btn",
                this.onVehicleSearch,
                this.t("map.generateQuote"),
                true
              )}
            </container>
          </box>
        );
      default:
        return (
          <box>
            <container>
              {this.renderCaptchaButton(1, "btn", this.onVehicleSearch)}
            </container>
          </box>
        );
    }
  };

  renderTiresizeSearchButton = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "volkswagen_2020":
        return (
          <box>
            <container>
              {this.renderCaptchaButton(
                2,
                "btn",
                this.onTiresizeSearch,
                this.t("map.buyNow"),
                true
              )}
              {this.renderCaptchaButton(
                2,
                "btn",
                this.onTiresizeSearch,
                this.t("map.generateQuote"),
                true
              )}
            </container>
          </box>
        );
      default:
        return (
          <box>
            <container>
              {this.renderCaptchaButton(2, "btn", this.onTiresizeSearch)}
            </container>
          </box>
        );
    }
  };

  getUpperContainerClass = () => {
    if (this.state.width < 729) {
      return "";
    } else {
      return "no-padding";
    }
  };

  renderLauncherPartFrame = () => {
    return (
      <box className="frame">
        {this.state.tab ? (
          <box className="background">
            <box>
              {this.renderSelectBoxColumn(
                true,
                this.state.product,
                this.state.productList,
                this.onSelectProduct,
                this.t("launcher.productCategory"),
                false
              )}
            </box>
            <box>
              {this.renderSelectBoxColumn(
                true,
                this.state.year,
                this.props.launcherVehicleYears,
                this.onSelectYear,
                this.t("launcher.selectYear"),
                this.state.product,
                false,
                this.props.yearselect
              )}
            </box>
            <box>
              {this.renderSelectBoxColumn(
                true,
                this.state.make,
                this.state.makeList,
                this.onSelectMake,
                this.t("launcher.selectMake"),
                this.state.year
              )}
            </box>
            <box>
              {this.renderSelectBoxColumn(
                true,
                this.state.model,
                this.state.modelList,
                this.onSelectModel,
                this.t("launcher.selectModel"),
                this.props.selectedMake
              )}
            </box>
            <box>
              {this.renderInputColumn(
                !this.showDealerSelection() && this.showPostalCodeInput(),
                this.state.postalId,
                this.t("launcher.enterPostal"),
                false,
                false,
                true
              )}
            </box>
            <box>
              {this.renderSelectBoxColumn(
                this.showDealerSelection(),
                this.state.dealer,
                this.getLauncherDealerList(),
                this.onSelectDealer,
                this.t("launcher.selectYourDealer"),
                this.state.model
              )}
            </box>
            <box>
              <container>
                {this.renderCaptchaButton(1, "btn", this.onVehicleSearch)}
              </container>
            </box>
          </box>
        ) : (
          <box className="background">
            <box className="titlebox">
              <container>
                <h2 className="bold">{this.t("launcher.findTires")}</h2>
              </container>
            </box>
            <box>
              {this.renderInputColumn(
                true,
                this.state.tiresizeId,
                this.t("launcher.enterTiresize"),
                false,
                false,
                false
              )}
            </box>
            <box>
              {this.renderInputColumn(
                !this.showDealerSelection() && this.showPostalCodeInput(),
                this.state.postalId,
                this.t("launcher.enterPostal"),
                false,
                false,
                false
              )}
            </box>
            <box>
              {this.renderSelectBoxColumn(
                this.showDealerSelection(),
                this.state.dealer,
                this.getLauncherDealerList(),
                this.onSelectDealer,
                this.t("launcher.selectYourDealer"),
                false
              )}
            </box>
            <box className="helpbox">
              <span
                className={setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.TEXT)}
                data-dtm={setAdobeLaunchDTM(
                  "",
                  ADOBELAUNCHER_DTM.SHOPBYTIRESIZE
                )}
                onClick={this.toggleTireSizeHelp}
              >
                <column>
                  <i className="fa fa-question-circle" />
                </column>
                <column>{this.t("launcher.howFindTireSize")}</column>
              </span>
            </box>
            <box>
              <container>
                {this.renderCaptchaButton(2, "btn", this.onTiresizeSearch)}
              </container>
            </box>
          </box>
        )}
        {this.renderErrorModal()}
      </box>
    );
  };

  getColumnClass = (tab) => {
    if (this.state.tab === tab) {
      return "tab selected";
    } else {
      return "tab unselected";
    }
  };

  renderTopLauncher = () => {
    return (
      <box
        className="launcher top"
        style={
          applicationStore.data.defaultTemplate === "mercedes_2022"
            ? { textAlign: "center", paddingLeft: "50px" }
            : {}
        }
      >
        <container className="side">
          <box>
            <box className={this.getTabContainerClass()}>
              <column>
                <button
                  id={getTestId(
                    TEST_LIST.LAUNCHER.TAB,
                    this.t("launcher.shopByVehicle")
                  )}
                  className={this.getTabClass(true)}
                  data-dtm={setAdobeLaunchDTM(
                    "",
                    ADOBELAUNCHER_DTM.SECONDARYNAV
                  )}
                  onClick={this.toggleTab.bind(null, true)}
                >
                  <h2 className="bold">{this.t("launcher.shopByVehicle")}</h2>
                </button>
              </column>
              {applicationStore.data.allowSearchByTireSize ||
              (isUserLoggedIn() &&
                applicationStore.data.allowAuthenticatedSearchByTireSize) ? (
                <column>
                  <button
                    id={getTestId(
                      TEST_LIST.LAUNCHER.TAB,
                      this.t("launcher.shopBySize")
                    )}
                    className={this.getTabClass(false)}
                    onClick={this.toggleTab.bind(null, false)}
                  >
                    <h2 className="bold">{this.t("launcher.shopBySize")}</h2>
                  </button>
                </column>
              ) : (
                false
              )}
            </box>
          </box>
          {this.state.tab ? (
            <box className="background">
              <column>
                {this.renderSelectBoxColumn(
                  true,
                  this.state.year,
                  this.props.launcherVehicleYears,
                  this.onSelectYear,
                  this.t("launcher.selectYear"),
                  false,
                  true,
                  this.props.yearselect
                )}
              </column>
              <column>
                {this.renderSelectBoxColumn(
                  true,
                  this.state.make,
                  this.state.makeList,
                  this.onSelectMake,
                  this.t("launcher.selectMake"),
                  this.state.year,
                  true
                )}
              </column>
              <column>
                {this.renderSelectBoxColumn(
                  true,
                  this.state.model,
                  this.state.modelList,
                  this.onSelectModel,
                  this.t("launcher.selectModel"),
                  this.props.selectedMake,
                  true
                )}
              </column>
              <column>
                {this.renderSelectBoxColumn(
                  !this.showDealerSelection(),
                  this.state.product,
                  this.state.productList,
                  this.onSelectProduct,
                  this.t("launcher.productCategory"),
                  this.state.model,
                  true
                )}
              </column>
              <column>
                {this.renderInputColumn(
                  !this.showDealerSelection() && this.showPostalCodeInput(),
                  this.state.postalId,
                  this.t("launcher.enterPostal"),
                  true,
                  false,
                  true
                )}
              </column>
              <column>
                {this.renderSelectBoxColumn(
                  this.showDealerSelection(),
                  this.state.dealer,
                  this.getLauncherDealerList(),
                  this.onSelectDealer,
                  this.t("launcher.selectYourDealer"),
                  this.state.model,
                  true
                )}
              </column>
              <column>
                <container className="no-left">
                  {this.renderCaptchaButton(1, "btn", this.onVehicleSearch)}
                </container>
              </column>
            </box>
          ) : (
            <box className="background tiresize">
              <column>
                {this.renderInputColumn(
                  true,
                  this.state.tiresizeId,
                  this.t("launcher.enterTiresize"),
                  true,
                  false,
                  false
                )}
              </column>
              <column>
                {this.renderInputColumn(
                  !this.showDealerSelection() && this.showPostalCodeInput(),
                  this.state.postalId,
                  this.t("launcher.enterPostal"),
                  true,
                  false,
                  false
                )}
              </column>
              <column>
                {this.renderSelectBoxColumn(
                  this.showDealerSelection(),
                  this.state.dealer,
                  this.getLauncherDealerList(),
                  this.onSelectDealer,
                  this.t("launcher.selectYourDealer"),
                  false,
                  true
                )}
              </column>
              <column>
                <container className="no-left">
                  {this.renderCaptchaButton(2, "btn", this.onTiresizeSearch)}
                </container>
              </column>
            </box>
          )}
        </container>
        {this.renderErrorModal()}
      </box>
    );
  };

  onRunSearch = () => {
    this.state.tab ? this.onVehicleSearch() : this.onTiresizeSearch();
  };

  renderCaptchaButton = (
    index,
    btnClass,
    onClick,
    overwriteText = "",
    multipleButtons = false
  ) => {
    return (
      <container
        className={this.getCaptchaButtonContainerClass(multipleButtons)}
      >
        <button
          id={getTestId(TEST_LIST.LAUNCHER.BUTTON)}
          className={setAdobeLaunchClass(
            btnClass,
            ADOBELAUNCHER_CLASS.BUTTON.LINK
          )}
          data-dtm={setAdobeLaunchDTM(
            "",
            index === 1
              ? ADOBELAUNCHER_DTM.SHOPBYVEHICLE
              : ADOBELAUNCHER_DTM.SHOPBYTIRESIZE
          )}
          onClick={onClick}
          tabIndex={getTabIndex()}
        >
          {!isEmpty(overwriteText) ? overwriteText : this.getButtonText()}
          {this.getButtonIcon()}
        </button>
      </container>
    );
  };

  getCaptchaButtonContainerClass = (multipleButtons) => {
    return parseInt(this.state.width) < 730
      ? "input"
      : multipleButtons
      ? "top small"
      : "no-padding";
  };

  getButtonText = () => {
    if (this.props.bottomlauncher) {
      return this.t("searchText");
    } else {
      return this.t("launcher.viewResults");
    }
  };

  getButtonIcon = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "audi_2021":
        return (
          <i
            className="fa fa-angle-right"
            style={{
              color: "#fff",
              fontSize: "25px",
              verticalAlign: "sub",
              marginLeft: "10px",
            }}
          />
        );
      default:
        if (this.props.bottomlauncher) {
          return <i className="fa fa-arrow-right" />;
        } else {
          return false;
        }
    }
  };

  getTabContainerClass = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "toyota":
        return "shift";
      default:
        return "";
    }
  };

  renderErrorModal = () => {
    if (this.state.error) {
      return (
        <Modal refactor={true} requestUnmount={this.closeError}>
          <div className="wrapper">
            <div className="modal-content">
              <span>{this.getErrorMessage()}</span>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  getErrorMessage = () => {
    if (this.state.tab) {
      switch (getAppLanguage()) {
        case "fr":
          return "Erreur de recherche. Tous les champs sont obligatoires.";
        default:
          return "Vehicle search error. All fields are required.";
      }
    } else {
      switch (getAppLanguage()) {
        case "fr":
          return "Erreur de recherche. Tous les champs sont obligatoires.";
        default:
          return "Tiresize search error. All fields are required.";
      }
    }
  };

  closeError = () => {
    this.setState({
      error: false,
    });
  };

  getPostalCode = () => {
    if (document.getElementsByClassName(this.state.postalId)[0]) {
      return document.getElementsByClassName(this.state.postalId)[0].value;
    } else {
      return "";
    }
  };

  getTiresize = () => {
    console.log(
      document.getElementsByClassName(this.state.tiresizeId),
      "this is allow us to get tire size"
    );
    if (document.getElementsByClassName(this.state.tiresizeId)[0]) {
      return document.getElementsByClassName(this.state.tiresizeId)[0].value;
    } else {
      return "";
    }
  };

  validVehicleParameters = () => {
    if (this.props.bottomlauncher) {
      if (isNationalSite() && !nationalSiteHasDealerSelected()) {
        var postal = document.getElementsByClassName(this.state.postalId)[0];
        return (
          !!this.state.year &&
          !!this.props.selectedMake &&
          !!this.state.model &&
          (!!validatePostal(postal.value) || !!validateZip(postal.value))
        );
      } else {
        return (
          !!this.state.year && !!this.props.selectedMake && !!this.state.model
        );
      }
    } else if (this.showDealerSelection()) {
      return (
        !!this.state.year &&
        !!this.props.selectedMake &&
        !!this.state.model &&
        !!this.state.dealer
      );
    } else {
      return (
        !!this.state.year &&
        !!this.props.selectedMake &&
        !!this.state.model &&
        !!this.state.product
      );
    }
  };

  validTiresizeParameters = () => {
    if (this.showDealerSelection()) {
      return !isEmpty(this.getTiresize()) && !!this.state.dealer;
    } else {
      return !isEmpty(this.getTiresize());
    }
  };

  renderSelectBoxColumn = (
    enabled,
    selected,
    dataList,
    onSelect,
    placeholder,
    openOnDataLoad,
    noleftpadding = false,
    extOpen = false
  ) => {
    console.log({
      enabled,
      selected,
      onSelect,
      openOnDataLoad,
    });
    if (enabled) {
      return (
        <container
          className={noleftpadding ? "no-left" : ""}
          style={{ marginTop: "-2.5px" }}
        >
          <SelectBox
            selected={selected}
            dataList={dataList}
            datadtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.SHOPBYVEHICLE)}
            onSelect={onSelect}
            placeholder={placeholder}
            autoSelect={true}
            extOpen={extOpen}
            openOnDataLoad={openOnDataLoad}
            rightAlign={this.isFloatRight()}
            testId={TEST_LIST.SELECTBOXSOURCE.LAUNCHER}
          />
        </container>
      );
    } else {
      return false;
    }
  };

  renderInputColumn = (
    enabled,
    className,
    placeholder,
    noleftpadding = false,
    nowidth = false,
    setadobeinfo = null
  ) => {
    if (enabled) {
      return (
        <container
          className={this.getInputContainerClass(noleftpadding, nowidth)}
        >
          <input
            className={
              setadobeinfo !== null
                ? setAdobeLaunchClass(className, ADOBELAUNCHER_CLASS.INPUT)
                : className
            }
            data-dtm={this.getAdobeDTM(setadobeinfo)}
            id={getTestId(TEST_LIST.LAUNCHER.INPUT, placeholder)}
            placeholder={placeholder}
          />
        </container>
      );
    } else {
      return false;
    }
  };

  getAdobeDTM = (setadobeinfo) => {
    switch (setadobeinfo) {
      case true:
        return setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.SHOPBYVEHICLE);
      case false:
        return setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.SHOPBYTIRESIZE);
      case null:
        return "";
    }
  };

  getInputContainerClass = (noleftpadding, nowidth) => {
    if (noleftpadding) {
      return nowidth ? "no-left" : "input no-left";
    } else {
      return nowidth ? "" : "input";
    }
  };

  getLauncherDealerList = () => {
    var dealerList = [];
    dealerSelectionStore.dealers.map(function (dealer) {
      dealerList.push({
        key: dealer.get("id"),
        value: dealer.get("name"),
      });
    });
    return dealerList;
  };

  showPostalCodeInput = () => {
    return !isDealerSite() && !nationalSiteHasDealerSelected();
  };

  showDealerSelection = () => {
    return applicationStore.data.enableLandingpageDealerSelection;
  };

  getTabClass = (tab) => {
    if (tab === this.state.tab) {
      return setAdobeLaunchClass("tab selected", ADOBELAUNCHER_CLASS.TAB);
    } else {
      return setAdobeLaunchClass("tab", ADOBELAUNCHER_CLASS.TAB);
    }
  };

  toggleTab = (tab) => {
    this.setState({
      tab: tab,
    });
  };

  onSelectYear = (year) => {
    if (this.props.bottomlauncher) {
      this.setState({
        year: year,
        makeList: [],
        model: null,
        modelList: [],
        product: ProductTypes.TIRES,
        productList: [],
      });
      setTimeout(() => {
        applicationActions.loadLauncherMakeSelection(year, ProductTypes.TIRES);
      }, 100);
    } else if (this.props.partLauncher) {
      this.setState({
        year: year,
        makeList: [],
        model: null,
        modelList: [],
      });
      setTimeout(() => {
        applicationActions.loadLauncherMakeSelection(year, this.state.product);
      }, 100);
    } else {
      switch (applicationStore.data.defaultTemplate) {
        case "hyundai":
          applicationActions.setSearchProductTypeValue(null);
      }
      this.setState({
        year: year,
        makeList: [],
        model: null,
        modelList: [],
        product: null,
        productList: [],
      });
      setTimeout(() => {
        applicationActions.loadLauncherMakeSelection(year);
      }, 100);
    }
  };

  onSelectMake = (make) => {
    if (this.props.partLauncher || this.props.bottomlauncher) {
      this.setState({
        make: make,
        model: null,
        modelList: [],
      });

      applicationActions.loadLauncherModelSelection(
        this.state.year,
        make,
        this.state.product
      );
      this.props.onSelectMake(make);
    } else {
      switch (applicationStore.data.defaultTemplate) {
        case "hyundai":
          applicationActions.setSearchProductTypeValue(null);
      }
      this.setState({
        make: make,
        model: null,
        modelList: [],
        product: null,
        productList: [],
      });
      applicationActions.loadLauncherModelSelection(this.state.year, make);
      this.props.onSelectMake(make);
    }
  };

  onSelectModel = (model) => {
    console.log("onSelectModel", model);
    if (this.skipLoadProductTypes()) {
      this.setState({
        model: model,
      });
    } else {
      switch (applicationStore.data.defaultTemplate) {
        case "hyundai":
          applicationActions.setSearchProductTypeValue(null);
      }
      this.setState({
        model: model,
        product: null,
        productList: [],
      });
      this.props.onSelectModel(model);
      setTimeout(() => {
        applicationActions.loadLauncherProductTypeSelection(
          this.state.year,
          this.props.selectedMake,
          this.getCategory(model),
          this.getModel(model),
          null,
          null
        );
      }, 100);
    }
  };
  skipLoadProductTypes = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "hyundai":
        return !!this.props.partLauncher;
      default:
        return this.props.partLauncher || this.props.bottomlauncher;
    }
  };

  onSelectProduct = (product) => {
    switch (applicationStore.data.defaultTemplate) {
      case "hyundai":
        applicationActions.setSearchProductTypeValue(product);
        this.setState({
          product: product,
        });
      default:
        if (this.props.partLauncher) {
          this.setState({
            product: product,
            year: null,
            yearList: this.props.launcherVehicleYears,
            make: null,
            makeList: [],
            model: null,
            modelList: [],
          });
          setTimeout(() => {
            applicationActions.setSearchProductTypeValue(product);
            setTimeout(() => {
              applicationActions.loadLauncherYearSelection(product);
            }, 100);
          }, 100);
        } else {
          this.setState({
            product: product,
          });
        }
    }
  };

  onSelectDealer = (dealer) => {
    this.setState({
      dealer: dealer,
    });
  };

  getCategory = (model) => {
    if (isEmpty(model)) model = this.state.model;
    if (model.indexOf("-") > -1) {
      var tempModel = model.split("-");
      return tempModel[0];
    } else {
      return null;
    }
  };

  getModel = (model) => {
    if (isEmpty(model)) model = this.state.model;
    if (model.indexOf("-") > -1) {
      var tempModel = model.split("-");
      return tempModel[1];
    } else {
      return model;
    }
  };

  onVehicleSearch = (token) => {
    if (this.validVehicleParameters()) {
      if (
        getAppTemplate() === "mercedes" ||
        getAppTemplate() === "mercedestransition" ||
        getAppTemplate() === "mercedes_2022"
      ) {
        try {
          gtag("event", "conversion", {
            allow_custom_scripts: true,
            send_to: "DC-951733/tires0/tires0+standard",
          });
        } catch (err) {
          console.log(err.message);
        }
        document.body.insertAdjacentHTML(
          "beforebegin",
          "<noscript><img src='https://ad.doubleclick.net/ddm/activity/src=951733;type=tires0;cat=tires0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?' width='1' height='' alt=''/></noscript>"
        );
      }

      //setTokenCaptcha(token);
      if (isDealerSite()) {
        //todo this was already missing, commenting out in-case it is needed
        searchActions.setSearchType(SearchTypes.SEARCH_BY_VEHICLE);

        applicationActions.setProductTypeValue(
          this.checkProductType(this.state.product).toUpperCase()
        );

        searchActions.loadVehicleSimpleSearch(
          this.state.year,
          this.props.selectedMake,
          this.getCategory(),
          this.getModel()
        );
        //setTimeout(() => { searchActions.loadVehicleSimpleSearch(this.state.year, this.props.selectedMake, this.getCategory(), this.getModel()); }, 100);
      } else {
        var dealerId = getAppDealerId();

        if (!isEmpty(dealerId) && dealerId > 0) {
          applicationActions.setProductTypeValue(
            this.checkProductType(this.state.product.toUpperCase())
          );
          searchActions.loadVehicleSimpleSearch(
            this.state.year,
            this.props.selectedMake,
            this.getCategory(),
            this.getModel()
          );
          //setTimeout(() => { searchActions.loadVehicleSimpleSearch(this.state.year, this.props.selectedMake, this.getCategory(), this.getModel()); }, 100);
        } else {
          this.buildVehicleLinkUrl();
        }
      }
      if (this.props.partLauncher) {
        this.props.onToggleSearch(false);
      }
    } else {
      this.setState({
        error: true,
      });
    }
  };

  onTiresizeSearch = (token) => {
    if (this.validTiresizeParameters()) {
      if (
        getAppTemplate() === "mercedes" ||
        getAppTemplate() === "mercedestransition" ||
        getAppTemplate() === "mercedes_2022"
      ) {
        try {
          gtag("event", "conversion", {
            allow_custom_scripts: true,
            send_to: "DC-951733/tires0/tires0+standard",
          });
        } catch (err) {
          console.log(err.message);
        }
        document.body.insertAdjacentHTML(
          "beforebegin",
          "<noscript><img src='https://ad.doubleclick.net/ddm/activity/src=951733;type=tires0;cat=tires0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?' width='1' height='' alt=''/></noscript>"
        );
      }

      //setTokenCaptcha(token);
      if (isDealerSite()) {
        runTiresizeSearch(
          false,
          SearchTypes.SEARCH_BY_TIRESIZE,
          this.buildTiresizeLinkUrl(),
          this.getTiresize()
        );
      } else {
        var dealerId = getAppDealerId();
        if (!isEmpty(dealerId) && dealerId > 0) {
          runTiresizeSearch(
            false,
            SearchTypes.SEARCH_BY_TIRESIZE,
            this.buildTiresizeLinkUrl(),
            this.getTiresize()
          );
        } else {
          this.buildTiresizeLinkUrl();
        }
      }
      if (this.props.partLauncher) {
        this.props.onToggleSearch(false);
      }
    } else {
      this.setState({
        error: true,
      });
    }
  };

  buildVehicleLinkUrl = () => {
    if (isNationalSite()) {
      if (
        this.props.landingpage ||
        (this.props.bottomlauncher && !nationalSiteHasDealerSelected())
      ) {
        if (this.dealerSelectionEnabled()) {
          dealerSelectionActions.loadDealersForProductPage(
            this.state.year,
            this.props.selectedMake,
            this.getCategory(),
            this.getModel(),
            null,
            this.checkProductType(this.state.product),
            this.getPostalCode()
          );
        } else {
          window.location.href =
            window.location.origin +
            "/national/" +
            applicationStore.data.nationalUrlCode +
            "/" +
            this.getVehicleUrlSearch();
        }
      } else {
        window.location.href =
          window.location.origin +
          "/national/" +
          applicationStore.data.nationalUrlCode +
          "/" +
          this.getSubmodelPageUrl();
      }
    } else if (isGroupSite()) {
      if (this.props.landingpage) {
        if (this.dealerSelectionEnabled()) {
          window.location.href =
            window.location.origin +
            "/" +
            this.getSelectedDealerUrlCode() +
            "/" +
            getAppLanguage() +
            this.getSubmodelPageUrl(this.state.dealer);
        } else {
          if (this.dealerSelectionEnabled()) {
            dealerSelectionActions.loadDealersForProductPage(
              this.state.year,
              this.props.selectedMake,
              this.getCategory(),
              this.getModel(),
              null,
              this.checkProductType(this.state.product),
              this.getPostalCode()
            );
          } else {
            window.location.href =
              window.location.origin +
              "/group/" +
              applicationStore.data.groupUrlCode +
              "/" +
              getAppLanguage() +
              this.getVehicleUrlSearch();
          }
        }
      } else {
        window.location.href =
          window.location.origin +
          "/group/" +
          applicationStore.data.groupUrlCode +
          "/" +
          getAppLanguage() +
          this.getSubmodelPageUrl();
      }
    }
  };

  buildTiresizeLinkUrl = () => {
    if (isDealerSite()) {
      window.location.href =
        window.location.origin +
        "/" +
        getDealerUrlCode() +
        "/" +
        getAppLanguage() +
        this.getTiresizePageUrl();
    } else if (isNationalSite()) {
      if (this.props.landingpage) {
        if (this.dealerSelectionEnabled()) {
          dealerSelectionActions.loadDealersForProductPage(
            null,
            null,
            null,
            null,
            this.getTiresize(),
            this.checkProductType("tires"),
            this.getPostalCode()
          );
        } else {
          window.location.href =
            window.location.origin +
            "/national/" +
            applicationStore.data.nationalUrlCode +
            "/" +
            getAppLanguage() +
            this.getTiresizeUrlSearch();
        }
      } else if (nationalSiteHasDealerSelected()) {
        window.location.href =
          window.location.origin +
          "/national/" +
          applicationStore.data.nationalUrlCode +
          "/" +
          getAppLanguage() +
          this.getTiresizePageUrl();
      }
    } else if (isGroupSite()) {
      if (this.props.landingpage) {
        if (this.dealerSelectionEnabled()) {
          window.location.href =
            window.location.origin +
            "/" +
            this.getSelectedDealerUrlCode() +
            "/" +
            getAppLanguage() +
            this.getTiresizePageUrl(this.state.dealer);
        } else {
          if (this.dealerSelectionEnabled()) {
            dealerSelectionActions.loadDealersForProductPage(
              null,
              null,
              null,
              null,
              this.getTiresize(),
              this.checkProductType("tires"),
              this.getPostalCode()
            );
          } else {
            window.location.href =
              window.location.origin +
              "/group/" +
              applicationStore.data.groupUrlCode +
              "/" +
              getAppLanguage() +
              this.getTiresizeUrlSearch();
          }
        }
      } else {
        window.location.href =
          window.location.origin +
          "/group/" +
          applicationStore.data.groupUrlCode +
          "/" +
          getAppLanguage() +
          this.getTiresizePageUrl();
      }
    }
  };

  getSelectedDealerUrlCode = () => {
    if (!isEmpty(this.state.dealer)) {
      var temp,
        selectedDealer = this.state.dealer;
      dealerSelectionStore.dealers.map(function (dealer) {
        if (dealer.get("id") === selectedDealer) temp = dealer;
      });
      if (!isEmpty(temp)) {
        return temp.get("urlCode");
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  getVehicleUrlSearch = () => {
    return (
      "?vehicle=" +
      this.checkProductType(this.state.product) +
      "-" +
      encodeURIComponent(this.state.year) +
      "-" +
      encodeURIComponent(this.props.selectedMake) +
      "-" +
      encodeURIComponent(this.getCategory()) +
      "-" +
      encodeURIComponent(this.getModel()) +
      "-" +
      encodeURIComponent(getAppLanguage()) +
      (this.getPostalCode() ? "-" : "") +
      encodeURIComponent(this.getPostalCode())
    );
  };

  getTiresizeUrlSearch = () => {
    return (
      "?tiresize=" +
      encodeURIComponent(this.getTiresize()) +
      "-" +
      encodeURIComponent(getAppLanguage()) +
      (this.getPostalCode() ? "-" : "") +
      encodeURIComponent(this.getPostalCode())
    );
  };

  getSubmodelPageUrl = (dealerId = false) => {
    return (
      "/dealer/" +
      (dealerId ? dealerId : encodeURIComponent(getAppDealerId())) +
      "/product/" +
      (this.dealerSelectionEnabled()
        ? encodeURIComponent(ProductTypes.TIRES)
        : encodeURIComponent(this.checkProductType(this.state.product))) +
      "/type/vehicle-search/year/" +
      encodeURIComponent(this.state.year) +
      "/make/" +
      encodeURIComponent(this.props.selectedMake) +
      "/category/" +
      encodeURIComponent(
        !isEmpty(this.getCategory()) ? this.getCategory() : null
      ) +
      "/model/" +
      encodeURIComponent(this.getModel())
    );
  };

  getTiresizePageUrl = (dealerId = false) => {
    return (
      "/dealer/" +
      (dealerId ? dealerId : encodeURIComponent(getAppDealerId())) +
      "/search/tiresize-search/" +
      encodeURIComponent(this.getTiresize())
    );
  };

  dealerSelectionEnabled = () => {
    return applicationStore.data.enableLandingpageDealerSelection;
  };

  checkProductType = (productType) => {
    if (productType.toLowerCase() === "wheels") return "ALLOY_WHEELS";
    else return productType;
  };

  toggleTireSizeHelp = () => {
    applicationActions.onOpenTireSizeHelper();
  };

  updateProduct = () => {
    if (this.props.partLauncher) {
      return applicationStore.data.searchProductType;
    } else {
      return this.state.product;
    }
  };

  updateProductList = () => {
    if (this.props.partLauncher) {
      return this.loadProductTypes();
    } else {
      return !isEmpty(this.state.model)
        ? this.props.launcherVehicleProductTypes
        : [];
    }
  };

  toggleTabIndex = (overwrite) => {
    this.setState({
      tab: !isEmpty(overwrite) ? overwrite : !this.state.tab,
    });
  };

  onChange = () => {
    var tab = this.state.tab;
    if (
      this.state.product == ProductTypes.ACCESSORIES ||
      this.state.product == ProductTypes.ALLOY_WHEELS ||
      this.state.product == ProductTypes.PACKAGES
    ) {
      tab = true;
    }
    this.setState({
      product: this.updateProduct(),

      yearList: applicationStore.data.launcherVehicleYears,
      makeList: !isEmpty(this.state.year)
        ? this.props.launcherVehicleMakes
        : [],
      modelList: !isEmpty(this.props.selectedMake)
        ? this.props.launcherVehicleModels
        : [],
      productList: this.updateProductList(),
      dealerList: dealerSelectionStore.dealers,
      make: !isEmpty(this.state.year)
        ? this.props.selectedMake
        : this.props.selectedMake,
      model: !isEmpty(this.props.selectedMake)
        ? this.props.selectedModel
        : this.props.selectedModel,
      tab: tab,
    });
  };
}

function mapStateToProps(state) {
  return {
    toggleSearch: state.info.toggleSearchFlag,
    launcherVehicleYears: state.info.launcherVehicleYears,
    launcherVehicleMakes: state.info.launcherVehicleMakes,
    launcherVehicleModels: state.info.launcherVehicleModels,
    selectedMake: state.info.selectedMake,
    selectedModel: state.info.selectedModel,
    launcherVehicleProductTypes: state.info.launcherVehicleProductTypes,
  };
}
function mapStateToDispatch(dispatch) {
  return {
    onSelectMake: (make) => dispatch(onSelectMake(make)),
    onToggleSearch: (flag) => dispatch(toggleSearch(flag)),
    onSelectModel: (model) => dispatch(onSelectModel(model)),
  };
}
export default connect(mapStateToProps, mapStateToDispatch)(TopLauncher);

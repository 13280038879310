module.exports = {
  title: 'Fitting Room',
  reducedHomepageTitle: 'Customize your Mazda',
  reducedHomepageText: 'The Mazda Fitting room will help you accessorize your vehicle to exactly fit your needs and lifestyle.',
  reducedHomepageButton: 'Configure your Mazda',
  'shoppingCartWarnings': {
    ITEM_WARNING_TIRE_NOT_ORIGINAL_SIZE: 'You have requested to upsize or downsize your tires from the original vehicle equipment. Please be aware that when you select upsized or downsized tires you must also purchase the corresponding size wheels.'
  },
  'product': {
    'good': 'Bronze',
    'better': 'Silver',
    'best': 'Gold',
    'contactDealerForPricing': 'Contact dealer for pricing',
    'requiresOtherItems': 'Requires other items',
    'requiredItems': 'Required Items',
    'price': 'Price',
    'rearPartNumber': 'R: Part Number',
    'accessoryCategories': 'Accessory categories',
    'partNumber': 'Part #',
    'dealerPrice': 'Price',
    'retailPrice': 'Price',
    'youSave': 'You Save',
    'carColour': 'Colour of your vehicle',
    'wheelDiameter': 'Wheel Diameter',
    'frontPartNumber': 'F: Part Number',
    'tireSize': 'Tire Size',
    'tireSizeAndSeason': 'Tire Size and Season',
    'tireBrands': 'Tire Brands',
    'frontTireSize': 'F: Tire Size',
    'rearTireSize': 'R: Tire Size',
    'loadRating': 'Load Rating',
    'speedRating': 'Speed Rating',
    'quantity': 'Quantity',
    'frontQuantity': 'F: Quantity',
    'rearQuantity': 'R: Quantity',
    'total': 'Total',
    'requiresInstallation': 'Requires Installation',
    'perTire': 'per Tire',
    'perFrontTire': 'per Front Tire',
    'perRearTire': 'per Rear Tire',
    'perWheel': 'per Wheel',
    'perFrontWheel': 'per Front Wheel',
    'perRearWheel': 'per Rear Wheel',
    'perPackage': 'per Package',
    'perItem': 'per Item',
    'inStock': 'in stock',
    'categories': 'Category',
    'pricingNotes': {
      'title': 'Pricing Notes',
      'description': 'Environmental fees may apply. Installation fees are priced separately. Additional charges may apply.'
    },
    'wheelFinishTypes': {
      'alloy': 'Alloy',
      'steel': 'Steel'
    },
    'addToQuote': 'Add to cart',
    'removeFromQuote': 'Remove from cart',
    'appointment': 'Appointment',
    'front': 'Front',
    'rear': 'Rear',
    'originalEquipment': 'Original equipment',
    'loadSpeed': 'Load / Speed',
    'tire': 'Tire',
    'rimDiameter': 'Rim Diameter',
    'wheelFinish': 'Wheel Finish',
    'details': 'Details',
    'approvedTire': 'Original Equipment',
    'frontAndRearWheel': 'Front and Rear Wheel',
    'frontWheel': 'Front Wheel',
    'rearWheel': 'Rear Wheel',
    'frontTire': 'Front Tire',
    'rearTire': 'Rear Tire',
    'staggeredFittment': 'Staggered Fitment',
    'selectAccessoryCategory': 'All Categories',
    'requestAppointment': 'Contact dealer',
    'runFlat': 'Run flat tire',
    'colourSelectionTitle': 'COLOUR',
    'filters': {
      'filterResults': 'Filters',
      'brand': {
        'noSelection': 'Select Brand',
        'allSelected': 'All Brands',
        'otherSelected': 'Brands',
        'selectAll': 'Select All',
        'deselectAll': 'Deselect All'
      },
      'season': {
        'noSelection': 'Select Season',
        'allSeason': 'All Season',
        'winter': 'Winter',
        'summer': 'Summer'
      },
      'loadRating': {
        'noSelection': 'Select Load Rating'
      },
      'tireSize': {
        'noSelection': 'Select Tire Size'
      },
      'speedRating': {
        'noSelection': 'Select Speed Rating'
      },
      'plyRating': {
        'noSelection': 'Select Ply Rating'
      },
      'diameter': {
        'noSelection': 'Select Diameter',
        'original': 'Original size:',
        'inchDiameter': 'inch Diameter'
      }
    }
  }
};

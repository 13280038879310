import { applicationStore } from "../stores/ApplicationStore";
import { languageStore } from "../stores/LanguageStore";
import { shoppingCartViewStore } from "../stores/ShoppingCartViewStore";

import { ProductTypes } from "../constants/ProductTypes";
import { Image } from "../components/ux/Image.jsx";
import { Modal } from "../components/ux/Modal.jsx";

import { getAppLanguage, isEmpty, sizeOf, objectSize } from "./UtilityService";
import { encodeBase64List } from "./PdfService";
import {
  getWishlistDocDefinition,
  getWishlistPdfFilename,
} from "./PdfWishlistService";
import { removeOptionalFeatures, getCartInformation } from "./WishlistService";
import { convertDateToFrench } from "./CalendarService";
import store from "../stores/configureStore";

var React = require("react");

export const PDFLauncher = {
  VIEW: "view-as-pdf",
  DOWNLOAD: "download-as-pdf",
};

export const initializeWishlistPDF = () => {
  return {
    initialize: false,
    imageList: [],
    encodedImageList: [],
  };
};

export const componentMountPrintService = (setState) => {
  resetPrintServiceState(setState);
};

export const resetPrintServiceState = (setState) => {
  setState({
    pdfLauncher: null,
    wishlistPdf: {
      initialize: false,
      imageList: [],
      encodedImageList: [],
    },
  });
};

export const renderWishlistPdfLoading = (state) => {
  if (state.wishlistPdf.initialize) {
    var dictionary = languageStore.getDictionary();
    return (
      <Modal refactor={true} useShortHeaderBar={true} hideHeader={true}>
        <div className="wrapper">
          <div className="modal-content build-pdf">
            <Image className="img" src="/static/img/loading_icon.gif" />
            <span>{dictionary.wishlist.buildPdf + window.loadingSuffix}</span>
          </div>
        </div>
      </Modal>
    );
  } else {
    return false;
  }
};

export const launchWishlistPdfMake = (pdfMake, state) => {
  const cart = getCartInformation();
  let vehicleInfo = store.getState().shoppingCart;
  var vehicleInformation = vehicleInfo.vehicleInformation
      ? vehicleInfo.vehicleInformation
      : [],
    cartItems = vehicleInfo["cartItems"] ? vehicleInfo["cartItems"] : [];
  var vehicleId = 0,
    itemIndex = 0,
    vehicleTitle = "";
  if (typeof vehicleInformation !== "undefined") {
    if (typeof cartItems.get(ProductTypes.PACKAGES) !== "undefined") {
      cartItems.get(ProductTypes.PACKAGES).forEach(function (item) {
        if (item.get("vehicleId") > 0 && vehicleId === 0) {
          vehicleId = item.get("vehicleId");
        }
      });
    }

    if (vehicleInformation.length > 0 && !removeOptionalFeatures()) {
      vehicleTitle =
        vehicleInformation[itemIndex]["vehicleTitle"][getAppLanguage()];
    }
  }

  var date = new Date(cart?.orderCreatedTimestamp);

  var dateString = date.toDateString();
  if (getAppLanguage() === "fr") {
    dateString = convertDateToFrench(dateString);
  }

  var orderId = cart?.orderId || 0;
  var notes = shoppingCartViewStore.data.get("shoppingCartViewNotes")
    ? shoppingCartViewStore.data.get("shoppingCartViewNotes")[getAppLanguage()]
    : " ";

  var orderData = {
    orderId: orderId,
    dateString: dateString,
    time: date.toLocaleTimeString(),
    notes: notes,
  };
  let total = vehicleInfo?.total || 0;
  let subtotal = vehicleInfo?.subtotal || 0;
  let tax = vehicleInfo?.tax || 0;
  let taxTotal = vehicleInfo?.taxtotal || 0;

  var tableFooterData = {
    subtotal: Number(subtotal),
    tax: Number(tax),
    taxtotal: Number(taxTotal),
    total: Number(total),
  };

  if (state.pdfLauncher === PDFLauncher.VIEW) {
    pdfMake
      .createPdf(
        getWishlistDocDefinition(
          applicationStore.data.dealerInfo,
          orderData,
          tableFooterData,
          state.wishlistPdf.encodedImageList,
          vehicleTitle,
          orderId,
          cartItems
        )
      )
      .open();
  } else if (state.pdfLauncher === PDFLauncher.DOWNLOAD) {
    pdfMake
      .createPdf(
        getWishlistDocDefinition(
          applicationStore.data.dealerInfo,
          orderData,
          tableFooterData,
          state.wishlistPdf.encodedImageList,
          vehicleTitle,
          orderId,
          cartItems
        )
      )
      .download(getWishlistPdfFilename(orderId));
  }
};

export const componentUpdatePrintService = (pdfMake, state, updateState) => {
  //console.log('componentUpdatePrintService, imageList: '+sizeOf(state.wishlistPdf.imageList)+', encodedImageList: '+objectSize(state.wishlistPdf.encodedImageList));
  //console.log(state.wishlistPdf);
  //console.log(state);

  if (
    state.wishlistPdf.initialize &&
    sizeOf(state.wishlistPdf.imageList) ==
      objectSize(state.wishlistPdf.encodedImageList) + 2
  ) {
    launchWishlistPdfMake(pdfMake, state);
    resetPrintServiceState(updateState);
  }
};

const encodeBase64Images = (state, updateState, imageList) => {
  encodeBase64List(imageList, function (id, url, image) {
    var encodedImageList = state.wishlistPdf.encodedImageList;
    if (id) {
      encodedImageList[id] = image;
    }
    updateState({
      wishlistPdf: {
        initialize: true,
        imageList: imageList,
        encodedImageList: encodedImageList,
      },
    });
  });
};

export const collectWishlistBase64Images = (launcher, state, updateState) => {
  var imageList = [];
  imageList.push({
    id: "logo",
    url: applicationStore.data.dealerRetailSiteCustomLogo,
  });
  imageList.push({
    id: "promotionalImage",
    url:
      window.location.protocol +
      applicationStore.data.promotionalImageTire.toObject()[getAppLanguage()],
  });
  let vehicleInfo = store.getState().shoppingCart;
  let cartItems = vehicleInfo["cartItems"] ? vehicleInfo["cartItems"] : [];
  var tireCartList, wheelCartList, accessoryCartList, packageCartList;
  tireCartList = cartItems.get(ProductTypes.TIRES);
  wheelCartList = cartItems.get(ProductTypes.ALLOY_WHEELS);
  accessoryCartList = cartItems.get(ProductTypes.ACCESSORIES);
  packageCartList = cartItems.get(ProductTypes.PACKAGES);

  if (sizeOf(tireCartList) > 0) {
    tireCartList.map(function (cartItem) {
      var image = cartItem ? cartItem.get("image") : null;
      if (!isEmpty(image) && typeof image !== "undefined") {
        imageList.push({ id: cartItem.get("id"), url: image });
      }
    });
  }

  if (sizeOf(wheelCartList) > 0) {
    wheelCartList.map(function (cartItem) {
      var image = cartItem ? cartItem.get("image") : null;
      if (!isEmpty(image) && typeof image !== "undefined") {
        imageList.push({ id: cartItem.get("id"), url: image });
      }
    });
  }

  if (sizeOf(accessoryCartList) > 0) {
    accessoryCartList.map(function (cartItem) {
      var image = cartItem ? cartItem.get("image") : null;
      if (!isEmpty(image) && typeof image !== "undefined") {
        imageList.push({ id: cartItem.get("id"), url: image });
      }
    });
  }

  if (sizeOf(packageCartList) > 0) {
    packageCartList.map(function (cartItem) {
      var image = cartItem ? cartItem.get("image") : null;
      if (!isEmpty(image) && typeof image !== "undefined") {
        imageList.push({ id: cartItem.get("id"), url: image });
      }
    });
  }

  //console.log('collectWishlistBase64Images');
  //console.log(imageList);

  updateState({
    pdfLauncher: launcher,
    wishlistPdf: {
      initialize: true,
      imageList: imageList,
      encodedImageList: [],
    },
  });

  encodeBase64Images(state, updateState, imageList);
};

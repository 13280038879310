import { applicationStore } from '../stores/ApplicationStore';
import { languageStore } from '../stores/LanguageStore';

import { Calendar } from '../components/calendar/Calendar.jsx';
import { Modal } from '../components/ux/Modal.jsx';

import { sizeOf, isEmpty, getAppLanguage } from './UtilityService';
import {ADOBELAUNCHER_CLASS, ADOBELAUNCHER_DTM, setAdobeLaunchClass, setAdobeLaunchDTM} from "./AdobeLaunchService";

var React = require('react');
var Immutable = require('immutable');
var Moment = require('moment');

export const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (sizeOf(month) < 2) month = '0' + month;
  if (sizeOf(day) < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const timePadMinutes = (minutes) => {
  while (minutes.toString().length < 2) { minutes = "0" + minutes; }
  return minutes;
};

export const isDateAvailable = (date, override = false) => {

  if (override) {
    return true;
  } else {
    var dealerHours = availableDaysToObject(applicationStore.data.dealer.get('hours')), dateObject = new Date(date), currentDateObject = new Date(), dateDay = dateObject.getDay();
    if (parseInt(applicationStore.data.dealerAppointmentLeadTime) > 0) {
      currentDateObject.setTime(currentDateObject.getTime() + (parseInt(applicationStore.data.dealerAppointmentLeadTime) * 86400000));
    }
    if (!isEmpty(dealerHours)) {
      return dealerHours.some(day => day.day === dateDay && (dateObject > currentDateObject));
    } else {
      return false;
    }
  }
};

export const getTimeOptions = (date) => {
  if (isDateAvailable(date)) {
    var dayOfWeek = date.getDay(), opening = availableDaysToObject(applicationStore.data.dealer.get('hours')).find(day => day.day === dayOfWeek);
    var open = Moment(opening.open, 'hh:mm').format('A'), close = Moment(opening.close, 'hh:mm').format('A');
    if (open === 'AM' && close === 'PM') {
      switch (getAppLanguage()) {
        case 'en':
          return Immutable.Map({AM: 'AM', PM: 'PM'});
        case 'fr':
          return Immutable.Map({AM: 'matin', PM: 'après-midi'});
        default:
          return Immutable.List.of();
      }
    } else if (open === 'AM' && close === 'AM') {
      switch (getAppLanguage()) {
        case 'en':
          return Immutable.Map({AM: 'AM'});
        case 'fr':
          return Immutable.Map({AM: 'matin'});
        default:
          return Immutable.List.of();
      }
    } else if (open === 'PM') {
      switch (getAppLanguage()) {
        case 'en':
          return Immutable.Map({PM: 'PM'});
        case 'fr':
          return Immutable.Map({PM: 'après-midi'});
        default:
          return Immutable.List.of();
      }
    } else {
      return Immutable.List.of();
    }
  } else {
    return Immutable.List.of();
  }
};

const availableDaysToObject = (dealerHours) => {
  return (
    Immutable
      .fromJS(dealerHours)
      .filter(day => day.get('open') !== '')
      .map((content, apiDay) => {
        switch(apiDay) {
          case 'MON':
            return {
              day: 1,
              open: content.get('open'),
              close: content.get('close')
            };
          case 'TUE':
            return {
              day: 2,
              open: content.get('open'),
              close: content.get('close')
            };
          case 'WED':
            return {
              day: 3,
              open: content.get('open'),
              close: content.get('close')
            };
          case 'THU':
            return {
              day: 4,
              open: content.get('open'),
              close: content.get('close')
            };
          case 'FRI':
            return {
              day: 5,
              open: content.get('open'),
              close: content.get('close')
            };
          case 'SAT':
            return {
              day: 6,
              open: content.get('open'),
              close: content.get('close')
            };
          case 'SUN':
            return {
              day: 0,
              open: content.get('open'),
              close: content.get('close')
            };
          default:
            throw new Error();
        }
      }).toList()
  );
};

export const checkObjectIsDate = (date) => {
  if (!isEmpty(date) && typeof(date) === 'object') {
    return date.constructor.toString().indexOf("Date()") > -1;
  } else {
    return false;
  }
};

const getFormattedDate = (dateString, format) => {
  if (!isEmpty(dateString)) {
    var date = new Date(dateString);
    if (date && format) {
      return date.format(format);
    } else {
      return languageStore.getDictionary().appointmentForm.noDateSelected;
    }
  } else {
    return languageStore.getDictionary().appointmentForm.noDateSelected;
  }
};

export const formatDateString = (dateString, format = 'Y-m-d', formatMoment = 'Y-MM-DD') => {
  if (checkObjectIsDate(dateString)) {
    return dateString.format(format);
  } else {
    var moment = Moment(dateString);
    if (moment.isValid() && formatMoment) {
      return Moment(dateString).format(formatMoment);
    } else {
      return getFormattedDate(dateString, format);
    }
  }
};

export const renderCalendarModal = (state, updateState, skipDaysAvailable = false) => {

  /*******************************************
   *  Required in component state:
   *
   *      - showCalendar
   *      - calendarDate
   *      - calendarDateTemp
   *
   *      - actionFailure
   *      - actionFailureMsg
   *
   */

  if (state.showCalendar) {
    var today = new Date(), tomorrow = new Date('tomorrow'), dictionary = languageStore.getDictionary();
    return (
      <Modal applyTemplateStyle={true} useShortHeaderBar={true} slideHeaderDown={true} requestUnmount={toggleCalendar.bind(null, state, updateState)} datadtm={setAdobeLaunchDTM('', ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.DATE)}>
        <div className="wrapper calendar-modal">
          <div className="appointment-reservation">
            <div className="formInput">
              <Calendar onSelect={setCalendarDate.bind(null, updateState)} selected={state.calendarDate ? state.calendarDate : tomorrow} view={today.toDateString()} skipDaysAvailable={skipDaysAvailable}/>
            </div>
            <div className="appointment-controls">
              <button onClick={selectDate.bind(null, state, updateState)} className={setAdobeLaunchClass("btn save-appointment-button", ADOBELAUNCHER_CLASS.BUTTON.LINK)} data-dtm={ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.DATE}>{dictionary.common.select}</button>
              <button onClick={selectDate.bind(null, state, updateState)} className={setAdobeLaunchClass("btn calendar-select", ADOBELAUNCHER_CLASS.BUTTON.LINK)} data-dtm={ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.DATE}>{dictionary.common.close}</button>
            </div>
          </div>
        </div>
      </Modal>
    );
  } else {
    return false;
  }
};

const setCalendarDate = (updateState, date) => {
  var dateObject = new Date(date);
  if (isDateAvailable(dateObject) && (dateObject > new Date())) {
    updateState({
      calendarDateTemp: Moment(date)
    });
  }
};

const selectDate = (state, updateState) => {
  updateState({
    calendarDate: state.calendarDateTemp,
    showCalendar: false
  });
};

const toggleCalendar = (state, updateState) => {
  updateState({
    showCalendar: !state.showCalendar,
    actionFailure: false,
    actionFailureMsg: ""
  });
};

export const convertDateToFrench = (dateString) => {
  //Swap position of date and month
  var dateArray = dateString.split(" ");
  dateString = dateArray[0] + " " + dateArray[2] + " " + dateArray[1] + " " + dateArray[3];

  //Convert Day
  dateString = dateString.replace("Mon", "lundi");
  dateString = dateString.replace("Tue", "mardi");
  dateString = dateString.replace("Wed", "mercredi");
  dateString = dateString.replace("Thu", "jeudi");
  dateString = dateString.replace("Fri", "vendredi");
  dateString = dateString.replace("Sat", "samedi");
  dateString = dateString.replace("Sun", "dimanche");

  //Convert Month
  dateString = dateString.replace("Jan", "janvier");
  dateString = dateString.replace("Feb", "février");
  dateString = dateString.replace("Mar", "mars");
  dateString = dateString.replace("Apr", "avril");
  dateString = dateString.replace("May", "mai");
  dateString = dateString.replace("Jun", "juin");
  dateString = dateString.replace("Jul", "juillet");
  dateString = dateString.replace("Aug", "août");
  dateString = dateString.replace("Sep", "septembre");
  dateString = dateString.replace("Oct", "octobre");
  dateString = dateString.replace("Nov", "novembre");
  dateString = dateString.replace("Dec", "décembre");

  return dateString;
};

export const decodeDayAbbrev = (day) => {
  var language = getAppLanguage();
  switch(day) {
    case 'MON':
      if (language === 'fr') return 'L';
      else return 'M';
    case 'TUE':
      if (language === 'fr') return 'M';
      else return 'T';
    case 'WED':
      if (language === 'fr') return 'M';
      else return 'W';
    case 'THU':
      if (language === 'fr') return 'J';
      else return 'T';
    case 'FRI':
      if (language === 'fr') return 'V';
      else return 'F';
    case 'SAT':
      if (language === 'fr') return 'S';
      else return 'S';
    case 'SUN':
      if (language === 'fr') return 'D';
      else return 'S';
  }
};

export const decodeStoreHours = (open, close) => {
  if (!isNaN(parseFloat(open)) && !isNaN(parseFloat(close))) {

    var openHour = parseInt(open.split(':')[0]);
    var openMinute = open.split(':')[1];

    var closeHour = parseInt(close.split(':')[0]);
    closeHour = getAppLanguage() === 'fr' ? closeHour : (closeHour > 12 ? parseInt(closeHour - 12) : closeHour);
    var closeMinute = close.split(':')[1];

    return (
      "(" + (openHour + (openMinute !== '00' ? ":" + openMinute : '')) + " - " + (closeHour + (closeMinute !== '00' ? ":" + closeMinute : '')) + ")"
    );

  } else {

    return (
      getAppLanguage() === 'fr' ? '(Fermé)' : '(Closed)'
    );

  }
};
import { globalState } from "../../GlobalState.js";
import { RouterStates } from "../../constants/RouterStates.js";
import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { shoppingCartStore } from "../../stores/ShoppingCartStore.js";
import { vehicleSelectionStore } from "../../stores/VehicleSelectionStore.js";
import { Image } from "../ux/Image.jsx";
import { ProductTypes } from "../../constants/ProductTypes.js";
import {
  getTabIndex,
  sizeOf,
  isOperaBrowser,
  getAppLanguage,
  money_format,
} from "../../service/UtilityService";
import { getDestination } from "../../api/RequestBuilderHelper";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";
import { connect } from "react-redux";
import {
  removeItemFromCart,
  toggleCart,
  toggleDetails,
} from "../../redux/features/shoppingCart.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var Cx = require("classnames");


class ShoppingCartItem extends I18nComponent {
  shouldComponentUpdate(lastProps, nextState) {
    return (
      this.props !== lastProps ||
      this.props.item !== lastProps.item ||
      this.props.cartIndex !== lastProps.cartIndex
    );
  }

  render() {
    var classList = Cx({
      "cart-item": true,
      openable: true,
      promoted: this.isPromoted(),
    });
    // let {itemPart,
    //   itemQty,
    //   itemTitle,
    //   itemType,
    //   itemDisplayPrice,
    //   orderItemId
    // }  = this.props.item;
    //
    //   let {
    //     partDetails:{
    //       primaryPart:{
    //         tireManufacturerPartNumber,
    //         tireNationalPartNumber,
    //         tireLoadRating,
    //         tireSpeedIndex,
    //         tirePlyRating
    //       }
    //     },
    //     partPricing:{
    //       primaryPart:{
    //         price
    //       }
    //     },
    //     partSummary:{
    //       primaryPart:{
    //         itemImageLink
    //       }
    //     }
    //   } = itemPart;
    //
    //   let {
    //     displayPrice,
    //     displayLabour,
    //   } = price["1"].contextList[0];
    //
    //   console.log('our DisplayPrice', displayPrice);
    //   // console.log('this is our itemPartNumber',itemPartNumber)
    // // this quantity is trying to access the dec value of itemQty
    // var quantity = itemQty;
    // let name = itemTitle;
    // let type = itemType;
    // let cartItemPrice = itemDisplayPrice;
    // let image = itemImageLink;
    // let cartItemId = orderItemId;
    // console.log(cartItemId,'this is our top cartItemId')
    // if (typeof quantity === 'string') quantity = parseInt(quantity);
    // console.log(quantity,'this is our number value now')
    // // var name = false;
    //
    // if (typeof name[getAppLanguage()] !== 'undefined') {
    //   name = name;
    // } else {
    //   name = undefined;
    // }

    console.log("this.props.item: ", this.props.item);
    var quantity = this.props.item["quantity"];
    if (typeof quantity === "object") quantity = quantity["value"];

    var name = false;

    if (typeof this.props.item["name"][getAppLanguage()] !== "undefined") {
      name = this.props.item["name"];
    } else {
      name = this.props.item["product"]["title"];
    }
    var removeButtonClass = Cx({
      btn: applicationStore.data.enableMercedesFeatures,
      "btn-remove": !applicationStore.data.enableMercedesFeatures,
      french: getAppLanguage() === "fr",
      hide: sizeOf(shoppingCartStore.getItems()) == 1,
    });

    var partNumber = false,
      loadSpeedPly = false,
      isTire = false;
    switch (this.props.item["type"]) {
      case ProductTypes.TIRES:
      case getDestination(ProductTypes.TIRES):
        partNumber = this.props.item["product"]["frontTire"]["partNumber"]
          ? this.props.item["product"]["frontTire"]["partNumber"]
          : this.props.item["product"]["frontTire"]["nationalPartNumber"];
        loadSpeedPly =
          this.props.item["product"]["loadRating"] +
          " / " +
          this.props.item["product"]["speedRating"] +
          " / " +
          this.props.item["product"]["frontTire"]["plyRating"];
        isTire = true;
        break;
      case ProductTypes.ALLOY_WHEELS:
      case getDestination(ProductTypes.ALLOY_WHEELS):
        partNumber = this.props.item["product"]["frontWheel"]["partNumber"]
          ? this.props.item["product"]["frontWheel"]["partNumber"]
          : this.props.item["product"]["frontWheel"]["nationalPartNumber"];
        break;
      case ProductTypes.ACCESSORIES:
      case ProductTypes.PACKAGES:
      case getDestination(ProductTypes.ACCESSORIES):
      case getDestination(ProductTypes.PACKAGES):
        partNumber = this.props.item["product"]["partNumber"]
          ? this.props.item["product"]["partNumber"]
          : this.props.item["product"]["nationalPartNumber"];
        break;
    }

    var quantityDropDown = this.n(quantity);

    var insufficientStockFlag = false,
      quantityValue =
        typeof quantity === "string" ? parseFloat(quantity) : this.n(quantity);
    if (
      this.props.hasNTDStock &&
      parseFloat(this.props.ntdStockCount) < quantityValue
    ) {
      insufficientStockFlag = true;
    }

    return (
      <li className={classList}>
        <div onClick={this.onClickDetail} className="item-info">
          <div className="product-image">
            {this.props.item["image"] ? (
              <img
                className={setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.IMAGE)}
                data-dtm={setAdobeLaunchDTM(
                  "",
                  ADOBELAUNCHER_DTM.MYQUOTEMODAL.BASE
                )}
                src={this.props.item["image"]}
                alt="Image Cart Item "
              />
            ) : (
              false
            )}
            <span className="show-modal-icon">
              <i className="fa fa-search-plus"></i>
            </span>
          </div>
        </div>
        <div className="product-box-1">
          <div className="product-box-2">
            <span className="product-name">{this.td(name)}</span>
            {partNumber ? (
              <span className="tire-info">{" - " + partNumber}</span>
            ) : (
              false
            )}
            {loadSpeedPly ? (
              <span className="tire-info">{" - " + loadSpeedPly}</span>
            ) : (
              false
            )}
          </div>
          {insufficientStockFlag ? (
            <div className="stock-warning">
              {"Only " +
                this.props.ntdStockCount +
                " tires are available in stock."}
            </div>
          ) : (
            false
          )}
          <div className="product-box-3">
            <div className="product-box-4">
              <span
                className={
                  !isOperaBrowser()
                    ? "product-price"
                    : "product-price ie-browser"
                }
              >
                <span className="quantity">{this.t("product.qty") + ":"} </span>
                {quantityDropDown}
              </span>
              <span className="product-price product-total">
                <span className="total">{this.t("product.total") + ":"} </span>
                {this.getPrice()}
              </span>
            </div>
            <div className="product-box-5">
              <button
                className={setAdobeLaunchClass(
                  removeButtonClass,
                  ADOBELAUNCHER_CLASS.BUTTON.CLOSE
                )}
                data-dtm={setAdobeLaunchDTM(
                  "",
                  ADOBELAUNCHER_DTM.MYQUOTEMODAL.BASE
                )}
                role="button"
                tabIndex={getTabIndex()}
                aria-label={this.t("quote.remove")}
                onKeyDown={(e) => this.onRemoveItemFromBasketWithEvent(e)}
                onClick={this.onRemoveItemFromBasket}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
          </div>
        </div>
        {this.props.hasNTDStock ? (
          <div className="ntd-cart-item">
            <Image className="ntd-item" src="/static/img/ntd_truck.png" />
          </div>
        ) : (
          false
        )}
      </li>
    );
  }

  getPrice = () => {
    // quantity,displayPrice,displayLabour,cartItemPrice
    /*
    var price = this.props.item.get('cartItemPrice');

    if (isEmpty(price)) {
      var quantity = this.props.item.get('quantity');
      if (typeof quantity === 'object') quantity = quantity.get('value');
      price = parseFloat(quantity * this.props.item.get('displayPrice'));

      if (this.props.item['product'].get('primary') && this.props.item.get('product').get('primary').get('pricing') && this.props.item.get('product').get('primary').get('pricing').get('displayLabour')) {
        price += parseFloat(quantity * this.props.item.get('product').get('primary').get('pricing').get('displayLabour'));
      } else if (this.props.item.get('product') && this.props.item.get('product').get('pricing') && this.props.item.get('product').get('pricing').get('displayLabour')) {
        price += parseFloat(quantity * this.props.item.get('product').get('pricing').get('displayLabour'));
      } else if (this.props.item.get('product') && this.props.item.get('product').get('price') && this.props.item.get('product').get('price').get('displayLabour')) {
        price += parseFloat(quantity * this.props.item.get('product').get('price').get('displayLabour'));
      } else if (this.props.item.get('product') && this.props.item.get('product').get('pricing') && this.props.item.get('product').get('pricing').get('displayLabour')) {
        price += parseFloat(quantity * this.props.item.get('product').get('pricing').get('displayLabour'));
      }
    }
    */
    // let price;
    // // var price, quantity = quantity;
    // // console.log(this.props.item['product'].get('primary'),'this is our item property')
    // // console.log(this.props.item['product'].get('primary') && this.props.item['product'].get('primary').get('pricing'),'this is item product property')
    // // console.log(this.props.item['product'].get('primary').get('pricing').get('displayLabour'),'this is our display labour')
    // if (typeof quantity === 'string') quantity = quantity;
    //
    // if (parseInt(quantity) > 0 && parseFloat(displayPrice) > 0 && parseFloat(displayLabour) > 0) {
    //   price = parseFloat(quantity * displayPrice);
    //
    //   if (itemPart.primary && displayPrice && displayLabour) {
    //     price += parseFloat(quantity * displayLabour);
    //   } else if (itemPart && partPricing  && displayLabour) {
    //     price += parseFloat(quantity * displayLabour);
    //   } else if (itemPart && partPricing && displayLabour) {
    //     price += parseFloat(quantity * displayLabour);
    //   } else if (itemPart && partPricing && displayLabour) {
    //     price += parseFloat(quantity * displayLabour);
    //   }
    // } else {
    //   console.log('listPrice',cartItemPrice)
    //   price = parseFloat(quantity * cartItemPrice);
    // }

    var price,
      quantity = this.props.item["quantity"];
    if (typeof quantity === "object") quantity = quantity["value"];

    if (
      parseInt(quantity) > 0 &&
      parseFloat(this.props.item["displayPrice"]) > 0 &&
      parseFloat(this.props.item["displayLabour"]) > 0
    ) {
      price = parseFloat(quantity * this.props.item["displayPrice"]);

      if (
        this.props.item["product"]["primary"] &&
        this.props.item["product"]["primary"]["pricing"] &&
        this.props.item["product"]["primary"]["pricing"]["displayLabour"]
      ) {
        price += parseFloat(
          quantity *
            this.props.item["product"]["primary"]["pricing"]["displayLabour"]
        );
      } else if (
        this.props.item["product"] &&
        this.props.item["product"]["pricing"] &&
        this.props.item["product"]["pricing"]["displayLabour"]
      ) {
        price += parseFloat(
          quantity * this.props.item["product"]["pricing"]["displayLabour"]
        );
      } else if (
        this.props.item["product"] &&
        this.props.item["product"]["price"] &&
        this.props.item["product"]["price"]["displayLabour"]
      ) {
        price += parseFloat(
          quantity * this.props.item["product"]["price"]["displayLabour"]
        );
      } else if (
        this.props.item["product"] &&
        this.props.item["product"]["pricing"] &&
        this.props.item["product"]["pricing"]["displayLabour"]
      ) {
        price += parseFloat(
          quantity * this.props.item["product"]["pricing"]["displayLabour"]
        );
      }
    } else {
      price = this.props.item["cartItemPrice"];
    }

    return money_format(price);
  };

  getName = () => {
    if (typeof this.props.item["name"][getAppLanguage()] !== "undefined") {
      return this.props.item["name"];
    } else {
      return this.props.item["product"]["title"];
    }
  };

  updateItemQuantity = (quantity) => {
    if (
      this.props.item["product"]["frontTire"] &&
      this.props.item["product"]["frontTire"]["hasPricing"]
    ) {
      shoppingCartActions.updateItemFromWishlist(
        this.props.item["product"]["frontTire"]["id"],
        this.props.item["cartItemId"],
        this.props.item["itemPartOption"],
        quantity,
        vehicleSelectionStore.data.vehicleId,
        quantity["pricingContextId"],
        this.props.item["product"]
      );
    }
  };

  renderPromotionText = () => {
    if (this.isPromoted()) {
      return (
        <span className="promotion-text">
          {this.td(this.props.item["promotionText"])}
        </span>
      );
    } else {
      return false;
    }
  };

  isPromoted = () => {
    // console.log(this.props,'this is our props coming in')
    // return obj.get('promotionText').toJS().get('en') !== '';

    console.log("this.props.item: ", this.props.item);
    return this.props.item.promotionText?.en !== "";
  };
  // implementation of ally code down below
  onRemoveItemFromBasketWithEvent = (evt) => {
    console.log("item has been clicked");
    checkForEvents: if ("undefined" != typeof evt) {
      if (evt.keyCode == 13 || evt.keyCode == 32) {
        evt.preventDefault();
        evt.stopPropagation();
        break checkForEvents;
      }
      return;
    }
    this.props.dispatch(removeItemFromCart(itemId));
  };

  onRemoveItemFromBasket = (itemId) => {
    this.props.dispatch(toggleCart(false));
    return this.props.dispatch(
      removeItemFromCart(this.props.item["cartItemId"])
    );
  };

  onClickDetail = () => {
    if (
      globalState.select("applicationState", "routerState").get() !==
      RouterStates.WISHLIST
    ) {
      this.props.dispatch(toggleDetails(this.props.item));
      shoppingCartActions.openDetail(this.props.item);
    }
  };
}

export default connect(null)(ShoppingCartItem);

import { I18nComponent } from "../../i18n/I18nComponent.jsx";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { Image } from "../ux/Image.jsx";

var React = require("react");

export class LoadingPage extends I18nComponent {
  state = {
    initialize: true,
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
  }

  render() {
    return (
      <div className="application-wrapper loading-page">
        <h1>{this.t("loadingPage")}</h1>
        <Image src="/static/img/loading_icon.gif" />
      </div>
    );
  }

  onChange = () => {
    this.setState({
      initialize: true,
    });
  };
}

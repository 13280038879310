import { applicationStore } from "../../stores/ApplicationStore.js";
import { Image } from "../ux/Image.jsx";

import {
  sizeOf,
  isEmpty,
  dealerSiteIsDisabled,
  getTrackingEventCategory,
  getTrackingEventObject,
} from "../../service/UtilityService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var Immutable = require("immutable");

export class NoConnectionPage extends I18nComponent {
  state = {
    httpStatusCode: this.props.httpStatusCode
      ? this.props.httpStatusCode
      : applicationStore.data.httpStatusCode,
  };

  componentWillMount() {
    if (
      window.dealerAccountInfoPayload &&
      !isEmpty(window.dealerAccountInfoPayload.dealerMapExitLink)
    ) {
      googleAnalyticsEnhancedTrackEvent(
        getTrackingEventCategory(),
        "Site Exit Link Redirect"
      );
      window.location.href = window.dealerAccountInfoPayload.dealerMapExitLink;
    } else {
      var today = new Date(),
        date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate(),
        time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
      var dateTime = date + " " + time;
      var trackingPageData = "Failed Connection Page",
        trackingEventData = "Failed Connection Page Access";
      if (
        sizeOf(window.nationalAccountInfoPayload) === 0 &&
        sizeOf(window.dealerAccountInfoPayload) === 0
      ) {
        trackingPageData = "Page Does Not Exist";
        trackingEventData = "Page Does Not Exist Access";
      }
      googleAnalyticsEnhancedTrackPageview(trackingPageData);
      googleAnalyticsEnhancedTrackEvent(
        getTrackingEventCategory(),
        trackingEventData,
        getTrackingEventObject({ url: window.location.href, ts: dateTime })
      );
    }
    applicationStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props != nextProps || this.state != nextState;
  }

  render() {
    var errorMessageList = Immutable.fromJS({
      404: "Sorry, we could not find the page you requested.",
      500: "Sorry, interval server error encountered, page failed to load.",
      505: "Sorry, interval server error encountered, page failed to load.",
    });
    var isValidCode = [404, 500, 505].indexOf(this.state.httpStatusCode) > -1;
    var imageUrl = "",
      imageUrlMobile = "",
      isValidImageUrl = false,
      isValidImageUrlMobile = false;
    if (isValidCode) {
      switch (parseInt(this.state.httpStatusCode)) {
        case 404:
          imageUrl = "/static/img/404.jpg";
          imageUrlMobile = "/static/img/404_mobile.jpg";
          isValidImageUrl = true;
          isValidImageUrlMobile = true;
          break;
        case 500:
          imageUrl = "/static/img/500.jpg";
          imageUrlMobile = "/static/img/500_mobile.jpg";
          isValidImageUrl = true;
          isValidImageUrlMobile = true;
          break;
        case 505:
          imageUrl = "/static/img/505.jpg";
          imageUrlMobile = "/static/img/505_mobile.jpg";
          isValidImageUrl = true;
          isValidImageUrlMobile = false;
          break;
        default:
          break;
      }
    }
    return (
      <div className="application-wrapper no-connection-page">
        {this.renderHeader(isValidImageUrl, isValidCode, errorMessageList)}
        {this.renderImage(isValidImageUrl, isValidCode, imageUrl)}
        {this.renderMobileImage(
          isValidImageUrlMobile,
          isValidCode,
          imageUrlMobile
        )}
        {this.renderButton()}
      </div>
    );
  }

  renderHeader = (isValidImageUrl, isValidCode, errorMessageList) => {
    if (!isValidImageUrl && isValidCode) {
      return (
        <h1>
          {this.state.httpStatusCode +
            " - " +
            errorMessageList.get(this.state.httpStatusCode)}
        </h1>
      );
    } else {
      return false;
    }
  };

  renderImage = (isValidImageUrl, isValidCode, imageUrl) => {
    if (!isValidCode && dealerSiteIsDisabled()) {
      return (
        <div className="error-status-image">
          <Image src="/static/img/offline.png" />
        </div>
      );
    } else if (isValidImageUrl) {
      return (
        <div className="error-status-image">
          <Image src={imageUrl} />
        </div>
      );
    } else {
      return <h1>{"Page failed to load."}</h1>;
    }
  };

  renderMobileImage = (isValidImageUrlMobile, isValidCode, imageUrlMobile) => {
    if (!isValidCode && dealerSiteIsDisabled()) {
      return (
        <div className="error-status-image mobile">
          <Image src="/static/img/offline_mobile.png" />
        </div>
      );
    } else if (isValidImageUrlMobile) {
      return (
        <div className="error-status-image mobile">
          <Image src={imageUrlMobile} />
        </div>
      );
    }
  };

  renderButton = () => {
    if (!dealerSiteIsDisabled() && this.state.httpStatusCode !== 404) {
      return (
        <div className="reload-container">
          <button className="btn reload-btn" onClick={this.onReloadPage}>
            {this.t("common.reload")}
          </button>
        </div>
      );
    } else {
      return false;
    }
  };

  onReloadPage = () => {
    window.location.reload();
  };

  onChange = () => {
    this.setState({
      httpStatusCode: this.state.httpStatusCode
        ? this.state.httpStatusCode
        : applicationStore.data.httpStatusCode,
    });
  };
}

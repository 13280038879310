import { applicationActions } from "../../actions/ApplicationActions.js";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { EmailField } from "../ux/forms/EmailField.jsx";
import { PasswordField } from "../ux/forms/PasswordField.jsx";
import { Modal } from "../ux/Modal.jsx";
import { Image } from "../ux/Image.jsx";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");

export class ForceLoginDialog extends I18nComponent {
  static defaultProps = {
    failure: false,
  };

  state = {
    email: {
      value: null,
      touched: false,
      forceTouch: false,
      valid: false,
    },
    password: {
      value: null,
      touched: false,
      forceTouch: false,
      valid: false,
    },
    rememberMe: false,
  };

  render() {
    var createAccount = false;
    if (applicationStore.data.allowGuestAccountCreation) {
      createAccount = (
        <div className="dont-have-email text-small">
          <a onClick={this.onCreateGuestAccount}>
            {this.t("guestAccountCreation.noEmail")}
          </a>
        </div>
      );
    }

    return (
      <Modal applyTemplateStyle={true} hideCloseButton={true}>
        <div className="login-modal-info">
          <div className="login-modal-container">
            <div className="login-modal-title">
              <h2>{this.t("loginModal.dealershipStaffLogin")}</h2>
              <div className="pair">
                <input
                  id="rememberMeField"
                  type="checkbox"
                  value={this.state.rememberMe}
                  onChange={this.onChangeRememberMe}
                />
                <label htmlFor="rememberMeField">
                  {this.t("loginDialog.rememberMeShort")}
                </label>
              </div>
            </div>
            <div className="login-modal-image">
              <Image
                src="/static/img/510x179.png"
                alt="Image Vehicle Selection"
              />
            </div>
            <div className="login-form login-extended force-login">
              <form onSubmit={this.onFormSubmit}>
                <EmailField
                  onChange={this.onEmailChange}
                  onBlur={this.onEmailChange}
                  hasOnBlur={true}
                  touched={this.state.email.forceTouch}
                  id="userEmailField"
                  errorNotEmpty={this.t("validation.notEmpty")}
                  errorInvalidEmail={this.t("validation.email")}
                  placeholder={this.t("loginDialog.email")}
                />
                <PasswordField
                  onChange={this.onPasswordChange}
                  onBlur={this.onPasswordChange}
                  hasOnBlur={true}
                  touched={this.state.password.forceTouch}
                  id="passwordFieldLogin"
                  errorNotEmpty={this.t("validation.notEmpty")}
                  placeholder={this.t("loginDialog.password")}
                />
                <button type="submit" className="btn">
                  {this.t("loginDialog.login")}
                </button>
                {createAccount}
                <div className="have-forgotten-password text-small">
                  <a onClick={this.onForgotPassword}>
                    {this.t("forgotPassword.forgotPassword2")}
                  </a>
                </div>
              </form>
              {this.props.failure ? (
                <div className="login-failure">
                  {this.t("loginDialog.loginHasFailed")}
                </div>
              ) : (
                false
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  onCreateGuestAccount = () => {
    applicationActions.openGuestAccountCreationModal();
  };

  onForgotPassword = () => {
    applicationActions.openForgotPasswordModal();
  };

  onEmailChange = (value, valid) => {
    this.setState({
      email: {
        value: value,
        valid: valid,
        touched: true,
      },
    });
  };

  onPasswordChange = (value, valid) => {
    this.setState({
      password: {
        value: value,
        valid: valid,
        touched: true,
      },
    });
  };

  onChangeRememberMe = () => {
    this.setState({
      rememberMe: !this.state.rememberMe,
    });
  };

  onFormSubmit = (ev) => {
    var email = this.state.email,
      password = this.state.password;
    email.forceTouch = true;
    password.forceTouch = true;

    if (password.value === null) {
      password.value = document.getElementById("passwordFieldLogin").value;
      password.valid = true;
    }

    if (email.value === null) {
      email.value = document.getElementById("userEmailField").value;
      email.valid = true;
    }

    this.setState({
      email: email,
      password: password,
    });
    // store.dispatch(login({email:email.value,password:password.value,rememberMe:this.state.rememberMe}))
    applicationActions.login(
      email.value,
      password.value,
      this.state.rememberMe
    );

    ev.preventDefault();
    ev.stopPropagation();
  };
}

import { applicationStore } from "../../stores/ApplicationStore.js";
import { dealerSelectionActions } from "../../actions/DealerSelectionActions.js";
import { dealerSelectionStore } from "../../stores/DealerSelectionStore.js";

import { DealerMap } from "./DealerMap.jsx";

import { Modal } from "../ux/Modal.jsx";
import { Image } from "../ux/Image.jsx";
import { CheckBoxItem, CheckBoxList } from "../ux/forms/CheckBoxList.jsx";
import { ComboBox } from "../ux/forms/ComboBox.jsx";
import { ComboBoxValue } from "../ux/forms/ComboBoxValue.jsx";

import { retrieveDealerLocation } from "../../api/ApiService";

import {
  cleanArray,
  get_browser,
  getAppLanguage,
  getTabIndex,
  getTrackingEventCategory,
  isEdgeBrowser,
  isEmpty,
  isGroupSite,
  sizeOf,
} from "../../service/UtilityService";
import {
  defaultMapLocation,
  onSelectNationalMapDealer,
  setActiveDealer,
  setDealerSearchType,
  setInitialSearch,
  setWidgetRedirect,
  updateChassisRedirect,
  urlHasPostalRedirect,
} from "../../service/DealerSelectionService.js";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";
import { connect } from "react-redux";
import {
  closeDealerNameSearchList,
  dealerDistance,
  dealerSearchReset,
  selectDealerSearchType,
  setDealerSelectionSearchTerm,
  toggleLocationModal,
} from "../../redux/features/dealerSections.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var ReactDOM = require("react-dom");
var $ = require("jquery");
var _ = require("lodash");
var Cx = require("classnames");

const DEALER_NAME_SEARCH = "dealer-name-search";
const DEALER_CITY_SEARCH = "dealer-city-search";

class DealerSelection extends I18nComponent {
  state = {
    selectedDealer: dealerSelectionStore.dealerSelectionSelectedDealer,
    dealers: this.props.dealers ? this.props.dealers : [],
    activeDealer: dealerSelectionStore.activeDealer,
    mapCenter: defaultMapLocation(),
    userLocation: dealerSelectionStore.userLocation,
    dealerSearch: dealerSelectionStore.dealerSearch,
    dealerSearchFlag: dealerSelectionStore.dealerSearchFlag,
    dealerSearchIsEmpty: dealerSelectionStore.dealerSearchIsEmpty,
    dealerSearchDistance: dealerSelectionStore.dealerSearchDistance,
    dealerSearchShowWarning: dealerSelectionStore.dealerSearchShowWarning,
    userInitialLocationModal: dealerSelectionStore.userInitialLocationModal,
    enableDealerNameList: dealerSelectionStore.enableDealerNameList,
    searchType: this.props.searchType ? this.props.searchType : null,
    setDealerAsActive: dealerSelectionStore.setDealerAsActive,
    dealerNameSearchTerm: null,
    showChangingSiteWarningModal: false,
    initializedMissingLocationModal: false,

    showConfirmTiresizeCaptchaModal: false,
    confirmTiresizeCaptchaEditTiresize: false,
    confirmTiresizeCaptchaSelectedDealer: null,
    confirmTiresizeCaptchaRenderLoading: false,
    confirmTiresizeCaptchaMissingSearchValue: false,
    tiresizeSearch: applicationStore.data.search,

    skipMapRedirect: false,
    selectedRedirectDealer: null,

    showLeavingSiteWarningModal:
      dealerSelectionStore.showLeavingSiteWarningModal,
    hasActiveDealer: false,
    checkGeoLocation: function () {},
    hasInitialLocationPostalCode: applicationStore.data.postal,
    campaignDealerSeletionVehicleInfo:
      applicationStore.data.campaignDealerSeletionVehicleInfo,
    hideFindUserLocationModal: applicationStore.data.hideFindUserLocationModal,
    chassisRedirect: {
      chassisInfo: dealerSelectionStore.redirectToChassisSearch,
      hasRunRedirect: false,
    },
    isOpen: false,
    runRecaptcha: {
      search_0: false,
      search_1: false,
    },
  };

  updateState = (state) => {
    this.setState(state);
  };

  initializeUserLocation = () => {
    if (navigator && navigator.doNotTrack !== "1" && navigator.geolocation) {
      this.checkGeoLocation();
      //setTimeout(() => { this.checkGeoLocation(); }, 200);
    } else {
      this.setState({
        checkGeoLocation: setInterval(() => {
          this.checkGeoLocation();
        }, 500),
      });
    }
  };

  checkGeoLocation = () => {
    if (navigator && navigator.doNotTrack !== "1" && navigator.geolocation)
      navigator.geolocation.getCurrentPosition(
        this.foundLocation,
        this.noLocation
      );
  };

  foundLocation = (position) => {
    clearInterval(this.state.checkGeoLocation);
    var GoogleMaps = window.google;
    if (GoogleMaps) {
      var GoogleGeoCoder = new GoogleMaps.maps.Geocoder();

      if (GoogleGeoCoder) {
        var positionArray = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        if (positionArray) {
          this.state.userLocation = positionArray;
          this.state.mapCenter = positionArray;

          GoogleGeoCoder.geocode(
            { location: positionArray },
            this.saveUserLocation
          );
        }
      }
    }
  };

  noLocation = () => {
    // default location
    clearInterval(this.state.checkGeoLocation);
    this.state.userLocation = defaultMapLocation();
    this.state.mapCenter = defaultMapLocation();
  };

  saveUserLocation = (results, status) => {
    if (status === "OK")
      this.setInitialUserLocation(
        this.state.userLocation,
        this.state.mapCenter
      );
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("Dealer Selection");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Dealer Selection Access"
    );
    applicationStore.addChangeListener(this.onChange);
    dealerSelectionStore.addChangeListener(this.onChange);
    window.addEventListener("load", this.getPostalRequest);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
    dealerSelectionStore.removeChangeListener(this.onChange);
    window.removeEventListener("load", this.getPostalRequest);

    clearInterval(this.state.checkGeoLocation);
  }

  componentDidMount() {
    if (!applicationStore.data.groupSite) {
      this.props.toggleLocationModal(true);
      dealerSelectionActions.loadListOfDealers();
    } else {
      // dealerSelectionActions.loadListOfDealersForGroup();
    }

    if (!urlHasPostalRedirect()) {
      this.initializeUserLocation();
    }

    var _this = this;
    $(".dealer-filter-dealer-search-value").on(
      "change paste keyup",
      function () {
        if (_this.props.searchType === DEALER_NAME_SEARCH)
          _this.setState({
            dealerNameSearchTerm: $(this).val(),
            enableDealerNameList: true,
          });
      }
    );

    if (
      applicationStore.data.enableHyundaiFeatures &&
      this.state.selectedRedirectDealer === null &&
      !!applicationStore.data.postal
    ) {
      retrieveDealerLocation(
        getAppLanguage(),
        applicationStore.data.postal,
        this.updateState
      );
    }
    window.addEventListener("load", this.getPostalRequest);
  }

  componentDidUpdate(lastProps, lastState) {
    updateChassisRedirect(this.state, this.updateState);
    setInitialSearch(this.state, lastState);
    setActiveDealer(this.state, this.updateState, lastState, this.refs);
    setDealerSearchType(this.state, this.updateState);
    if (this.props.dealers !== lastProps.dealers) {
      this.onChange();
    }

    var selectedDealer = this.getFirstFilteredDealer();
    setWidgetRedirect(this.state, this.updateState, selectedDealer);

    if (this.refs.selectDealerButton)
      ReactDOM.findDOMNode(this.refs.selectDealerButton).focus();
  }

  getFirstFilteredDealer = () => {
    const fallbackDealer =
      this.props.dealers.find(
        (dealer) =>
          dealer.id === window.campaignData?.campaignDealerList[0]?.dealerId
      ) ??
      this.props.dealers[0] ??
      null;
    ///*
    if (applicationStore.data.enableHyundaiFeatures) {
      var selectedRedirectDealer = this.state.selectedRedirectDealer;

      if (selectedRedirectDealer) {
        return this.props.dealers.length > 0
          ? this.props.visibleDealers.filter(function (dealer) {
              if (
                dealer["name"].toLowerCase() ===
                selectedRedirectDealer.toLowerCase()
              )
                return true;
            })[0]
          : null;
      } else {
        return fallbackDealer;
      }
    } else {
      return fallbackDealer;
    }
    //*/
  };

  render() {
    var _this = this;
    var dealerMap = false;
    var browser = get_browser();
    let data = this.props.dealers;
    // console.log("data", data);
    // console.log('browser', this.props.visibleDealers);
    if (
      browser.name !== "MSIE" ||
      (browser.name === "MSIE" && parseFloat(browser.version) > 10)
    ) {
      dealerMap = (
        <DealerMap
          google={window.google}
          ref="google-map"
          className="dealer-selection-map"
          initialCenter={this.state.mapCenter}
          hasActiveDealer={this.state.hasActiveDealer}
          dealers={this.props.dealers}
          visibleDealers={this.props.visibleDealers}
          userLocation={this.state.userLocation}
          searchFlag={this.state.dealerSearchFlag}
          mapZoom={this.props.mapZoom}
        />
      );
    }

    return (
      <div className="main">
        <div className="container">
          {this.renderMapFilter()}
          <div className="select-dealer">
            <div className="dealer-list">
              <ul className="dealer-list-container">
                {this.props.visibleDealers.map(function (dealer, index) {
                  return (
                    <li
                      key={"dealer-selection-" + index}
                      className="dealer-list-item"
                    >
                      <box>
                        <h3
                          onClick={_this.onDealerNameClicked.bind(
                            _this,
                            dealer
                          )}
                          className={setAdobeLaunchClass(
                            "",
                            ADOBELAUNCHER_CLASS.TEXT
                          )}
                          data-dtm={setAdobeLaunchDTM(
                            "",
                            ADOBELAUNCHER_DTM.RESULTS.BASE
                          )}
                          tabIndex={getTabIndex()}
                        >
                          {dealer["name"]}
                        </h3>
                        {_this.enableHasFinancing(dealer) ? (
                          <img
                            src="/static/img/volkswagen_2020/paybright_button.png"
                            style={{
                              position: "absolute",
                              top: "5px",
                              right: "-2.5px",
                            }}
                          />
                        ) : (
                          false
                        )}
                      </box>
                      <div className="dealer-info">
                        <div className="dealer-info-left">
                          <span tabIndex={getTabIndex()}>
                            {dealer["phone"] ? dealer["phone"] : false}
                          </span>
                        </div>
                        <div className="dealer-info-right">
                          {dealer["googleMapsUrl"] !== "" ? (
                            <input
                              type="button"
                              className="btn-directions"
                              id="on-select-dealer-directions"
                              tabIndex={getTabIndex()}
                              value={_this.t("search.getDirections")}
                              onClick={_this.onGetDirections.bind(
                                _this,
                                dealer
                              )}
                            />
                          ) : (
                            false
                          )}
                        </div>
                      </div>
                      <div className="dealer-address">
                        <span tabIndex={getTabIndex()}>
                          {dealer["address"]
                            ? dealer["address"].trim()
                            : dealer["address"] || dealer["city"]
                            ? dealer["city"].trim()
                            : dealer["city"] || dealer["province"]
                            ? dealer["province"].trim()
                            : dealer["province"]
                            ? cleanArray([
                                dealer["address"].trim(),
                                dealer["city"].trim(),
                                dealer["province"].trim(),
                              ]).join(", ")
                            : false}
                        </span>
                        {false ? (
                          <span className="distance">
                            {"Distance: " + dealer["distance"] + " Km"}
                          </span>
                        ) : (
                          false
                        )}
                      </div>
                      <div className="dealer-select-button">
                        {!dealer["hasNoPricing"]
                          ? _this.renderDealerButton(dealer)
                          : false}
                        {dealer["hasNoPricing"] ? (
                          <span className="text-large">
                            {_this.t("pricingNotAvailable")}
                          </span>
                        ) : (
                          false
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="dealer-map">
              {this.props.searchEmpty ? (
                <div className="dealer-map-warning">
                  <div className="warning-curtain"></div>
                  <div className="map-warning">
                    <div onClick={_this.onClickCloseEmptySearchWarning}>
                      <Image
                        className="close-x"
                        src="/static/img/close.png"
                        alt="Icon Close"
                      />
                    </div>
                    <h3>{_this.t("launcher.errorTitle")}</h3>
                    <div className="empty-search">
                      <span>{"Search Returned No Results."}</span>
                    </div>
                  </div>
                </div>
              ) : (
                false
              )}
              {dealerMap}
            </div>
          </div>
        </div>
        {this.renderGetLocationFromUser()}
        {this.renderLeavingSiteModal()}
        {this.renderConfirmTiresizeCaptcha()}
      </div>
    );
  }

  enableHasFinancing = (dealer) => {
    switch (applicationStore.data.defaultTemplate) {
      case "volkswagen_2020":
        return dealer["enableFinancing"];
      default:
        return false;
    }
  };

  renderDealerButton = (dealer) => {
    if (this.enableCommerceButton()) {
      if (dealer["enableECommerce"]) {
        return (
          <box>
            <column>
              <button
                type="button"
                className="btn"
                tabIndex={getTabIndex()}
                onClick={this.onDealerSelected.bind(this, dealer, false)}
                style={{ lineHeight: "initial", minWidth: "225px" }}
              >
                <box>
                  <text className="bold">{this.t("map.buyNow")}</text>
                </box>
                <box>
                  <text className="text-small">
                    {"(" + this.t("map.getTires") + ")"}
                  </text>
                </box>
              </button>
            </column>
            <column
              style={{ width: "30px", textAlign: "center", marginTop: "7.5px" }}
            >
              <text>{this.t("cart.or").toLowerCase()}</text>
            </column>
            <column>
              <button
                type="button"
                className="btn"
                tabIndex={getTabIndex()}
                onClick={this.onDealerSelected.bind(this, dealer, false)}
                style={{ lineHeight: "initial", minWidth: "225px" }}
              >
                <box>
                  <text className="bold">{this.t("map.generateQuote")}</text>
                </box>
                <box>
                  <text className="text-small">
                    {"(" + this.t("map.dealershipAvailability") + ")"}
                  </text>
                </box>
              </button>
            </column>
          </box>
        );
      } else {
        return (
          <button
            type="button"
            className={setAdobeLaunchClass(
              "btn",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.RESULTS.DEALERNAME,
              dealer["name"]
            )}
            id="on-select-dealer"
            tabIndex={getTabIndex()}
            onClick={this.onDealerSelected.bind(this, dealer, false)}
            style={{ lineHeight: "initial" }}
          >
            <box>
              <text className="bold">{"Generate Quote"}</text>
            </box>
            <box>
              <text className="text-small">
                {"(" + "Dealership will confirm availability" + ")"}
              </text>
            </box>
          </button>
        );
      }
    } else {
      var buttontext = this.t("search.selectDealer");
      if (!dealer["isActive"] && applicationStore.data.groupSite) {
        buttontext = this.t("search.selectDealerShort");
      }
      return (
        <input
          type="button"
          className={setAdobeLaunchClass(
            "btn",
            ADOBELAUNCHER_CLASS.BUTTON.LINK
          )}
          data-dtm={setAdobeLaunchDTM(
            "",
            ADOBELAUNCHER_DTM.RESULTS.DEALERNAME,
            dealer["name"]
          )}
          id="on-select-dealer"
          value={buttontext}
          tabIndex={getTabIndex()}
          onClick={this.onDealerSelected.bind(this, dealer, false)}
        />
      );
    }
  };

  enableCommerceButton = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "volkswagen_2020":
        return true;
      default:
        return false;
    }
  };

  renderGetLocationFromUser = () => {
    if (
      this.props.userInitialLocationModal &&
      !this.state.hideFindUserLocationModal
    ) {
      var warningClassName = Cx({
        "search-has-no-results": true,
        "show-warning": this.state.dealerSearchShowWarning,
      });

      var postalCodeText = "";
      if (applicationStore.data.nationalCountryCode === "US")
        postalCodeText = this.t("search.zipOrCity");
      else postalCodeText = this.t("search.postalOrCity");

      var countryText = "";
      switch (applicationStore.data.nationalCountryCode) {
        case "CA":
          countryText = "Canada";
          break;
        case "US":
          countryText = "USA";
          break;
      }

      return (
        <Modal
          refactor={true}
          requestUnmount={this.closeInitialUserLocationModal}
        >
          <div className="wrapper missing-location-modal">
            <div>
              <div className="dealer-filter-select-search">
                <div className="dealer-filter-select-title">
                  <h2 tabIndex={getTabIndex()}>
                    {this.t("dealerFilter.pleaseEnterLocation")}
                  </h2>
                </div>
                <form onSubmit={this.onDealerSearch}>
                  <div className="select-search-type">
                    <CheckBoxList
                      onChange={this.onChangeSearchType}
                      singleSelect={true}
                    >
                      <CheckBoxItem
                        tabIndex={getTabIndex()}
                        key={DEALER_CITY_SEARCH}
                        current={this.props.searchType}
                        value={DEALER_CITY_SEARCH}
                      >
                        {postalCodeText}
                      </CheckBoxItem>
                      <CheckBoxItem
                        tabIndex={getTabIndex()}
                        key={DEALER_NAME_SEARCH}
                        current={this.props.searchType}
                        value={DEALER_NAME_SEARCH}
                      >
                        {this.t("search.dealer")}
                      </CheckBoxItem>
                    </CheckBoxList>
                  </div>
                  <input
                    id="dealer-name-input"
                    className="dealer-filter-dealer-search-value-modal"
                    tabIndex={getTabIndex()}
                    autoComplete="off"
                    title={this.t("dealerFilter.enterDealerName")}
                    alt={this.t("dealerFilter.enterDealerName")}
                    placeholder={this.t("common.enterLocationOrRetailer")}
                    onClick={() => this.setState({ isOpen: true })}
                    onChange={(e) =>
                      this.setState({
                        dealerNameSearchTerm: e.target.value,
                        visibleDealers: this.getVisibleDealers(e.target.value),
                      })
                    }
                    type="text"
                  ></input>
                  {this.props.searchType === DEALER_NAME_SEARCH
                    ? this.renderDealerNameList(false)
                    : false}
                  <button
                    type="submit"
                    tabIndex={getTabIndex()}
                    className="btn"
                  >
                    {this.t("dealerFilter.search")}
                  </button>
                  <div className={warningClassName}>
                    <span>
                      {this.t("dealerFilter.searchResultsWarning").replace(
                        "{}",
                        countryText
                      )}
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };
  /*
   * Returns a list of dealers that match the search term
   * @param {string} searchTerm - The search term to filter dealers by
   * @returns {array} - An array of dealers that match the search term
   */
  getVisibleDealers = (searchTerm) => {
    if (searchTerm === "") return this.state.dealers;
    if (this.props.searchType === DEALER_CITY_SEARCH) {
      return this.state.dealers;
    } else {
      return this.state.dealers.filter((dealer) => {
        return dealer.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
      });
    }
  };

  closeInitialUserLocationModal = () => {
    this.props.toggleLocationModal(false);
    // dealerSelectionActions.closeInitialUserLocationModal();
    if (this.state.userLocation) {
      setTimeout(() => {
        // this.props.dispatch(filterDealerPosition(this.state.userLocation))
        // dealerSelectionActions.filterDealersPosition(this.state.userLocation);
      }, 250);
    }
  };

  sortByDistance = (a, b) => {
    let aDistance = parseFloat(a["distance"]);
    let bDistance = parseFloat(b["distance"]);
    if (isNaN(aDistance) || isNaN(bDistance)) {
      // Handle objects with missing or invalid distance property
      if (isNaN(aDistance) && isNaN(bDistance)) return 0;
      else if (isNaN(aDistance)) return 1;
      else return -1;
    } else {
      // Compare objects by their distance property
      if (aDistance < bDistance) return -1;
      else if (aDistance > bDistance) return 1;
      else return 0;
    }
  };

  renderMapFilter = () => {
    var warningClassName = Cx({
      "search-has-no-results": true,
      "show-warning":
        this.state.dealerSearchShowWarning &&
        !this.props.userInitialLocationModal,
    });

    var nationalAccountName = false;
    if (!applicationStore.data.groupSite)
      nationalAccountName =
        applicationStore.data.nationalAccountName[0].toUpperCase() +
        applicationStore.data.nationalAccountName.slice(1);
    else nationalAccountName = applicationStore.data.groupAccountName;

    var postalCodeText = "";
    if (applicationStore.data.nationalCountryCode === "US")
      postalCodeText = this.t("search.zipOrCity");
    else postalCodeText = this.t("search.postalOrCity");

    var countryText = "";
    switch (applicationStore.data.nationalCountryCode) {
      case "CA":
        countryText = "Canada";
        break;
      case "US":
        countryText = "USA";
        break;
    }

    var campaignVehicleInfo = false;
    if (
      sizeOf(this.state.campaignDealerSeletionVehicleInfo) > 0 &&
      this.state.campaignDealerSeletionVehicleInfo.vehicleModelId > 0
    ) {
      var vehicleYear =
        this.state.campaignDealerSeletionVehicleInfo.vehicleYearName;
      var vehicleMake =
        this.state.campaignDealerSeletionVehicleInfo.vehicleManufacturerName;
      var vehicleModel =
        this.state.campaignDealerSeletionVehicleInfo.vehicleModelName[
          getAppLanguage()
        ];
      var vehicleModelImage =
          this.state.campaignDealerSeletionVehicleInfo.vehicleModelImage,
        vehicleImage = false;
      if (vehicleModelImage)
        vehicleImage = (
          <img src={vehicleModelImage} className="campaign-vehicle-image" />
        );
      campaignVehicleInfo = (
        <div className="campaign-vehicle-info">
          <span>
            {this.t("campaign.yourVehicle") + ":"} {vehicleImage} {vehicleYear}{" "}
            {vehicleMake} {vehicleModel}
          </span>
        </div>
      );
    }

    return (
      <div className="dealer-filter">
        <div className="dealer-filter-title" tabIndex={getTabIndex()}>
          <h1>
            <span className="title">{this.t("dealerFilter.titlePrefix")}</span>{" "}
            <span>{nationalAccountName}</span>{" "}
            <span>{this.t("dealerFilter.titleSuffix")}</span>
          </h1>
        </div>
        <div className="dealer-filter-select-search">
          <form onSubmit={this.onDealerSearch}>
            <div className="select-search-type">
              <CheckBoxList
                onChange={this.onChangeSearchType}
                singleSelect={true}
              >
                <CheckBoxItem
                  tabIndex={getTabIndex()}
                  key={DEALER_CITY_SEARCH}
                  current={this.props.searchType}
                  value={DEALER_CITY_SEARCH}
                  className={setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.RADIO)}
                  datadtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.RETAILER)}
                >
                  {postalCodeText}
                </CheckBoxItem>
                <CheckBoxItem
                  tabIndex={getTabIndex()}
                  key={DEALER_NAME_SEARCH}
                  current={this.props.searchType}
                  value={DEALER_NAME_SEARCH}
                  className={setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.RADIO)}
                  datadtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.RETAILER)}
                >
                  {this.t("search.dealer")}
                </CheckBoxItem>
              </CheckBoxList>
            </div>
            <input
              id="dealer-name-input"
              onClick={() => this.setState({ isOpen: true })}
              onChange={(e) =>
                this.setState({
                  dealerNameSearchTerm: e.target.value,
                  visibleDealers: this.getVisibleDealers(e.target.value),
                })
              }
              className={setAdobeLaunchClass(
                "dealer-filter-dealer-search-value",
                ADOBELAUNCHER_CLASS.SEARCH.INPUT
              )}
              data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.RETAILER)}
              tabIndex={getTabIndex()}
              autoComplete="off"
              title={this.t("dealerFilter.enterDealerName")}
              alt={this.t("dealerFilter.enterDealerName")}
              placeholder={this.t("common.enterLocationOrRetailer")}
              type="text"
            />
            {this.props.searchType === DEALER_NAME_SEARCH &&
            !this.props.userInitialLocationModal
              ? this.renderDealerNameList(true)
              : false}
            <button
              type="submit"
              className={setAdobeLaunchClass(
                "btn",
                ADOBELAUNCHER_CLASS.SEARCH.SUBMIT
              )}
              data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.RETAILER)}
              tabIndex={getTabIndex()}
            >
              {this.t("dealerFilter.search")}
            </button>
            {this.props.searchType === DEALER_NAME_SEARCH ? (
              <button
                type="submit"
                className={setAdobeLaunchClass(
                  "btn",
                  ADOBELAUNCHER_CLASS.BUTTON.LINK
                )}
                data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.RETAILER)}
                tabIndex={getTabIndex()}
                onClick={this.onResetDealerSearch}
              >
                {this.t("dealerFilter.reset")}
              </button>
            ) : (
              false
            )}
            {this.props.searchType === DEALER_CITY_SEARCH ? (
              <ComboBox
                onChange={this.onSelectDealerDistance}
                value={this.state.dealerSearchDistance}
                tabIndex={getTabIndex()}
                className={setAdobeLaunchClass(
                  "select-max-distance",
                  ADOBELAUNCHER_CLASS.BUTTON.LINK
                )}
                datadtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.RETAILER)}
              >
                <ComboBoxValue key={10} value={10}>
                  {this.t("dealerFilter.withXKm").replace("[]", 10)}
                </ComboBoxValue>
                <ComboBoxValue key={25} value={25}>
                  {this.t("dealerFilter.withXKm").replace("[]", 25)}
                </ComboBoxValue>
                <ComboBoxValue key={50} value={50}>
                  {this.t("dealerFilter.withXKm").replace("[]", 50)}
                </ComboBoxValue>
                <ComboBoxValue key={75} value={75}>
                  {this.t("dealerFilter.withXKm").replace("[]", 75)}
                </ComboBoxValue>
                <ComboBoxValue key={100} value={100}>
                  {this.t("dealerFilter.withXKm").replace("[]", 100)}
                </ComboBoxValue>
                <ComboBoxValue key={150} value={150}>
                  {this.t("dealerFilter.withXKm").replace("[]", 150)}
                </ComboBoxValue>
                <ComboBoxValue key={200} value={200}>
                  {this.t("dealerFilter.withXKm").replace("[]", 200)}
                </ComboBoxValue>
                <ComboBoxValue key={300} value={300}>
                  {this.t("dealerFilter.withXKm").replace("[]", 300)}
                </ComboBoxValue>
                <ComboBoxValue key={0} value={0}>
                  {this.t("dealerFilter.withAnyKm").replace("[]", 300)}
                </ComboBoxValue>
              </ComboBox>
            ) : (
              false
            )}
            <div className={warningClassName}>
              <span>
                {this.t("dealerFilter.searchResultsWarning").replace(
                  "{}",
                  countryText
                )}
              </span>
            </div>
          </form>
        </div>
        {campaignVehicleInfo}
      </div>
    );
  };

  renderDealerNameList = (runOnClick) => {
    if (
      this.state.dealerNameSearchTerm &&
      this.state.dealerNameSearchTerm.length > 0 &&
      this.state.enableDealerNameList
    ) {
      var dealerName = this.state.dealerNameSearchTerm;
      if (
        dealerName !== "" &&
        typeof dealerName !== "undefined" &&
        dealerName !== "undefined"
      ) {
        var _this = this;

        var filteredDealers = this.props.dealers.filter((dealer) => {
          const name = dealer["name"].toLowerCase();
          const searchTerm = dealerName.toLowerCase();
          return name.indexOf(searchTerm) !== -1 || name.includes(searchTerm);
        });
        var filteredDealersCount = _.size(filteredDealers);
        if (filteredDealersCount > 0 && this.state.isOpen) {
          return (
            <div className="dealer-name-list">
              <div className="dealer-list-close">
                <button
                  className="btn-cart-close"
                  onClick={this.onCloseDealerList}
                >
                  <i className="fa fa-close"></i>
                </button>
              </div>
              <ul className="dealer-list">
                {filteredDealers
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((dealer, index) => {
                    const className = `dealer-name ${
                      index === 0 ? "first" : ""
                    } ${index === filteredDealersCount - 1 ? "last" : ""}`;
                    const splitName = dealer.name
                      .toLowerCase()
                      .split(dealerName.toLowerCase());
                    const key = `dealer-list-${index}`;

                    const clickOnDealerList = () =>
                      _this.clickOnDealerList(dealer, runOnClick);
                    const clickOnDealerListOnKeyDown = (e) =>
                      _this.clickOnDealerListOnKeyDown(e, dealer, runOnClick);

                    return (
                      <li
                        key={key}
                        tabIndex={getTabIndex()}
                        onClick={clickOnDealerList}
                        onKeyDown={clickOnDealerListOnKeyDown}
                        className={className}
                      >
                        <span>
                          {dealer.name.substring(0, splitName[0].length)}
                        </span>
                        <span className="highlight">
                          {dealer.name.substring(
                            splitName[0].length,
                            splitName[0].length + dealerName.length
                          )}
                        </span>
                        <span>
                          {dealer.name.substring(
                            splitName[0].length + dealerName.length
                          )}
                        </span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          );
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  renderDisclaimerModal = () => {
    var disclaimer = (
      <div className="disclaimer">
        <h2>{this.t("launcher.disclaimer")}</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: this.t("launcher.defaultDisclaimer"),
          }}
        ></p>
      </div>
    );
    switch (applicationStore.data.defaultTemplate) {
      default:
        disclaimer = false;
        break;
    }
    return (
      <Modal>
        <div className="wrapper">
          <div className="modal-content national-map-disclaimer">
            <Image
              className="modal-close"
              src="/static/img/close.png"
              onClick={this.closeMapDisclaimer}
              alt="Icon Close"
            />
            {disclaimer}
            <button className="btn" onClick={this.closeMapDisclaimer}>
              {this.t("common.continue")}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  renderLeavingSiteModal = () => {
    if (this.state.showLeavingSiteWarningModal) {
      return (
        <Modal>
          <div className="wrapper">
            <div className="modal-content national-map-disclaimer">
              <Image
                className="modal-close"
                src="/static/img/close.png"
                onClick={this.closeLeavingSiteModal}
                alt="Icon Close"
              />
              <div className="disclaimer">
                <h2>{this.t("launcher.disclaimer")}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: this.t("leavingSiteDisclaimer"),
                  }}
                ></p>
              </div>
              <button className="btn" onClick={this.openDealerSite}>
                {this.t("common.continue")}
              </button>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  renderConfirmTiresizeCaptcha = () => {
    if (this.state.showConfirmTiresizeCaptchaModal) {
      return (
        <Modal
          refactor={true}
          size="small"
          requestUnmount={this.closeConfirmTiresizeCaptchaModal}
        >
          <div className="wrapper">
            {!this.state.confirmTiresizeCaptchaRenderLoading ? (
              <div className="modal-container">
                {!this.state.confirmTiresizeCaptchaEditTiresize ? (
                  <div className="message-container">
                    <span>
                      {this.t("recaptcha.confirmTiresize")}{" "}
                      <span className="highlight-value">
                        {this.state.tiresizeSearch}
                      </span>
                    </span>
                  </div>
                ) : (
                  <div className="input-container">
                    <span>{this.t("recaptcha.enterTiresize")}</span>
                    <input
                      id="tire-size-confirmation"
                      className="recaptcha-field"
                      placeholder={this.t("recaptcha.inputPlaceholder")}
                    />
                    {this.state.confirmTiresizeCaptchaMissingSearchValue ? (
                      <span className="required-text">
                        {this.t("recaptcha.tiresizeIsRequired")}
                      </span>
                    ) : (
                      false
                    )}
                  </div>
                )}

                <div
                  className={
                    !this.state.confirmTiresizeCaptchaEditTiresize
                      ? "image-container two-buttons"
                      : "image-container one-button"
                  }
                >
                  <Image
                    src={
                      getAppLanguage() === "en"
                        ? "/static/img/findtiresize2_en.png"
                        : "/static/img/findtiresize2_fr.png"
                    }
                  />
                </div>

                <div className="button-container">
                  {!this.state.confirmTiresizeCaptchaEditTiresize ? (
                    <button
                      className="btn"
                      onClick={this.submitTiresizeConfirmation}
                    >
                      {this.t("recaptcha.yesConfirm")}
                    </button>
                  ) : (
                    false
                  )}
                  {this.state.confirmTiresizeCaptchaEditTiresize ? (
                    <button
                      className="btn"
                      onClick={this.submitTiresizeConfirmation}
                    >
                      {this.t("recaptcha.confirm")}
                    </button>
                  ) : (
                    false
                  )}
                  {!this.state.confirmTiresizeCaptchaEditTiresize ? (
                    <button
                      className="btn"
                      onClick={this.enableTiresizeCaptchaEditTiresize}
                    >
                      {this.t("recaptcha.noChange")}
                    </button>
                  ) : (
                    false
                  )}
                </div>
              </div>
            ) : (
              <div className="modal-container">
                <div className="loading-screen">
                  <Image src="/static/img/loading_icon.gif" />
                </div>
              </div>
            )}
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  clickOnDealerListOnKeyDown = (evt, dealer, runOnClick) => {
    checkForEvent: if ("undefined" != typeof evt.keyCode) {
      if (evt.keyCode == 13 || evt.keyCode == 32) {
        evt.preventDefault();
        evt.stopPropagation();
        break checkForEvent;
      }
      return;
    }
    this.clickOnDealerList(dealer, runOnClick);
  };

  /**

   Sets the search value for the dealer filter input and optionally dispatches an action to set the dealer selection search term.
   @param {Object} dealer - The dealer object containing the name of the selected dealer.
   @param {boolean} runOnClick - Whether or not to dispatch an action to set the dealer selection search term.
   @returns {void}
   */
  clickOnDealerList = (dealer, runOnClick) => {
    var dealerName = dealer["name"];
    var input = document.getElementsByClassName(
      "dealer-filter-dealer-search-value-modal"
    );
    if (input.length === 0)
      input = document.getElementsByClassName(
        "dealer-filter-dealer-search-value"
      );
    if (input.length > 0) {
      input[0].value = dealerName;
      if (runOnClick) this.props.setDealerSelectionSearchTerm(dealerName);
    }
  };

  onCloseDealerList = () => {
    this.props.setDealerSelectionSearchTerm("");
    this.setState({
      isOpen: false,
    });
    this.props.closeDealerNameSearchList(false);
    // dealerSelectionActions.closeDealerNameSearchList();
  };

  onChangeSearchType = (searchType) => {
    if (this.props.searchType !== searchType) {
      if (searchType === DEALER_NAME_SEARCH) {
        this.setState({ dealerNameSearchTerm: "" });
      }
      this.refs["google-map"].resetActiveDealer();
      document.getElementsByClassName(
        "dealer-filter-dealer-search-value"
      )[0].value = "";
      // this.props.dispatch(selectDealerSearchType(searchType))
      this.props.selectDealerSearchType(searchType);
      // dealerSelectionActions.selectDealerSearchType(searchType);
      //setTimeout(() => { dealerSelectionActions.selectDealerSearchType(searchType); }, 10);
    }
  };

  onResetDealerSearch = (ev) => {
    document.getElementsByClassName(
      "dealer-filter-dealer-search-value"
    )[0].value = "";
    this.props.dispatch(dealerSearchReset(true));
    // dealerSelectionActions.resetDealerSearch();
    ev.preventDefault();
    ev.stopPropagation();
  };

  onDealerNameClicked = (dealer) => {
    this.refs["google-map"].onMarkerClickFromProps(dealer);
    this.setState({ hasActiveDealer: true });
    dealerSelectionActions.mapSetLocation(dealer["position"], 11);
    //setTimeout(() => { dealerSelectionActions.mapSetLocation(dealer['position'], 11); }, 200);
  };

  onDealerSelected = (dealer, skipTiresizeCheck = false) => {
    if (!isEmpty(this.state.tiresizeSearch) && !skipTiresizeCheck) {
      this.setTiresizeCaptchaSelectedDealer(dealer);
      setTimeout(() => {
        this.openConfirmTiresizeCaptchaModal();
      }, 100);
    } else {
      if (isGroupSite()) {
        var link =
          "/" +
          dealer["urlCode"] +
          "/" +
          getAppLanguage() +
          "/dealer/" +
          dealer["id"];
        if (isEdgeBrowser()) {
          window.open(link);
        } else {
          window.open(link, "_self");
        }
      } else {
        if (isEmpty(this.getUrlPostalCode())) {
          onSelectNationalMapDealer(dealer);
        } else {
          const newDealer = dealer;
          const url = new URL(window.location.href);

          const vehicle = url.searchParams.get("vehicle");
          const parts = vehicle.split("-");
          const product = parts[0];
          const year = parts[1];
          const make = parts[2];
          const category = parts[3];
          const model = parts[4];
          const lang = parts[5];
          const zipCode = parts[6];
          const dealerId = newDealer["id"];
          let link = `/national/${
            applicationStore.data.nationalUrlCode
          }/${lang}/dealer/${dealerId}/product/${product.toUpperCase()}/type/vehicle-search/year/${year}/make/${make}/category/${category}/model/${model}`;
          console.log(link);
          window.open(link, "_self");
        }
      }
    }
  };

  openDealerSite = () => {
    var dealer = this.state.selectedDealer,
      link = "";
    if (dealer) {
      link =
        dealer.get("inactiveDealerLink") !== ""
          ? dealer.get("inactiveDealerLink")
          : "/" +
            dealer.get("urlCode") +
            "/" +
            getAppLanguage() +
            "/dealer/" +
            dealer.get("id");
      var win = window.open(link, "_blank");
      win.focus();
    }
  };

  onSelectDealerDistance = (distance) => {
    this.props.dispatch(dealerDistance(distance));
    // dealerSelectionActions.setDealerDistance(distance);
  };

  onClickCloseEmptySearchWarning = () => {
    this.props.dispatch(dealerSearchReset(true));
    // dealerSelectionActions.dealerSearchResetEmptySearch();
  };

  onGetDirections = (dealer) => {
    if (dealer["googleMapsUrl"] !== "") {
      var url = dealer["googleMapsUrl"];
      var win = window.open(url, "_blank");
      win.focus();
      dealerSelectionActions.closeLeavingSiteWarningModal();
    }
  };

  closeMapDisclaimer = () => {
    this.setState({
      showChangingSiteWarningModal: false,
    });
  };

  openConfirmTiresizeCaptchaModal = () => {
    this.setState({
      showConfirmTiresizeCaptchaModal: true,
    });
  };

  closeConfirmTiresizeCaptchaModal = () => {
    this.setState({
      showConfirmTiresizeCaptchaModal: false,
      confirmTiresizeCaptchaEditTiresize: false,
    });
  };

  enableTiresizeCaptchaEditTiresize = () => {
    this.setState({
      confirmTiresizeCaptchaEditTiresize: true,
    });
  };

  disableTiresizeCaptchaEditTiresize = () => {
    this.setState({
      confirmTiresizeCaptchaEditTiresize: false,
    });
  };

  setTiresizeCaptchaSelectedDealer = (dealer) => {
    this.setState({
      confirmTiresizeCaptchaSelectedDealer: dealer,
    });
  };

  submitTiresizeConfirmation = (token) => {
    if (this.state.confirmTiresizeCaptchaEditTiresize) {
      var search = document.getElementById("tire-size-confirmation");
      if (
        this.state.confirmTiresizeCaptchaSelectedDealer &&
        search &&
        !isEmpty(search.value)
      ) {
        this.setState({ confirmTiresizeCaptchaRenderLoading: true });
        setTimeout(() => {
          this.onDealerSelected(
            this.state.confirmTiresizeCaptchaSelectedDealer,
            true
          );
        }, 250);
      } else if (!this.state.confirmTiresizeCaptchaMissingSearchValue) {
        this.setState({ confirmTiresizeCaptchaMissingSearchValue: true });
      }
    } else if (this.state.confirmTiresizeCaptchaSelectedDealer) {
      this.setState({ confirmTiresizeCaptchaRenderLoading: true });
      setTimeout(() => {
        this.onDealerSelected(
          this.state.confirmTiresizeCaptchaSelectedDealer,
          true
        );
      }, 250);
    }
  };

  closeLeavingSiteModal = () => {
    dealerSelectionActions.closeLeavingSiteWarningModal();
  };

  setInitialUserLocation = (userLocation, mapCenter) => {
    dealerSelectionActions.mapSetInitialUserLocation(userLocation, mapCenter);
  };

  onDealerSearch = (ev) => {
    // Prevent default behavior and stop propagation of event
    ev.preventDefault();
    ev.stopPropagation();
    // Get address value from input fields
    var address = null;
    var $dealerFilterValueModal = $(".dealer-filter-dealer-search-value-modal");
    var $dealerFilterValue = $(".dealer-filter-dealer-search-value");

    if ($dealerFilterValueModal.length > 0) {
      address = $dealerFilterValueModal.val();
    }

    if (!address) {
      address = $dealerFilterValue.val();
    }
    // Dispatch action to update dealer search term
    if (address) {
      //TODO: update the setDealerSelectionSearchTerm to use redux
      // look into the actions controller
      this.props.setDealerSelectionSearchTerm(address);
      this.props.toggleLocationModal(false);

      // Toggle isOpen state
      this.setState((prevState) => ({
        isOpen: !prevState.isOpen,
      }));
    }

    // Reset hasActiveDealer state
    this.setState({ hasActiveDealer: false });

    // Set input field value to address
    if (address) {
      $dealerFilterValue.val(address);
    }
  };
  getUrlPostalCode = () => {
    const url = new URL(window.location.href);
    const vehicleParam = url.searchParams.get("vehicle");
    // if vehicleParam is null, return empty string
    if (vehicleParam === null) {
      return "";
    } else {
      return decodeURIComponent(vehicleParam.split("-").slice(-1)[0]);
    }
  };
  getPostalRequest = () => {
    console.log("this.getUrlPostalCode()", this.getUrlPostalCode());
    if (
      !isEmpty(this.getUrlPostalCode()) &&
      this.getUrlPostalCode() !== ("en" || "fr")
    ) {
      // this.props.toggleLocationModal(false);
      // get by id dealer-name-input and change the value to the this.getUrlPostalCode()
      document.getElementById("dealer-name-input").value =
        this.getUrlPostalCode();
      this.setState({
        skipMapRedirect: true,
        dealerNameSearchTerm: this.getUrlPostalCode(),
      });
      this.props.setDealerSelectionSearchTerm(this.getUrlPostalCode());
      this.props.toggleLocationModal(false);
    }
  };

  onChange = () => {
    this.setState({
      dealers: this.props.dealers,
      visibleDealers: this.props.dealers,
      selectedDealer: dealerSelectionStore.dealerSelectionSelectedDealer,
      activeDealer: dealerSelectionStore.activeDealer,
      mapCenter:
        dealerSelectionStore.mapCenter !== null
          ? dealerSelectionStore.mapCenter
          : this.state.mapCenter,
      dealerSearch: dealerSelectionStore.dealerSearch,
      dealerSearchFlag: dealerSelectionStore.dealerSearchFlag,
      dealerSearchIsEmpty: dealerSelectionStore.dealerSearchIsEmpty,
      dealerSearchDistance: dealerSelectionStore.dealerSearchDistance,
      dealerSearchShowWarning: dealerSelectionStore.dealerSearchShowWarning,
      userInitialLocationModal: this.props.userInitialLocationModal,
      enableDealerNameList: dealerSelectionStore.enableDealerNameList,
      searchType: this.props.searchType ? this.props.searchType : null,
      setDealerAsActive: dealerSelectionStore.setDealerAsActive,
      tiresizeSearch: applicationStore.data.search,
      showLeavingSiteWarningModal:
        dealerSelectionStore.showLeavingSiteWarningModal,
      hasInitialLocationPostalCode: applicationStore.data.postal,
      campaignDealerSeletionVehicleInfo:
        applicationStore.data.campaignDealerSeletionVehicleInfo,
      hideFindUserLocationModal:
        applicationStore.data.hideFindUserLocationModal,
      chassisRedirect: {
        chassisInfo: dealerSelectionStore.redirectToChassisSearch,
        hasRunRedirect: this.state.chassisRedirect.hasRunRedirect,
      },
    });
  };
}

function mapStateToProps(state) {
  return {
    searchEmpty: state.dealerSections.dealerSearchIsEmpty,
    LeaveSiteWarning: state.dealerSections.leavingSiteWarningModal,
    userInitialLocationModal: state.dealerSections.userInitialLocationModal,
    dealers: state.dealerSections.dealers,
    visibleDealers: state.dealerSections.visibleDealers || [],
    searchType: state.dealerSections.searchType,
    NameSearchList: state.dealerSections.closeDealerNameSearchList,
    mapZoom: state.dealerSections.mapZoom,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    toggleLocationModal: (value) => dispatch(toggleLocationModal(value)),
    selectDealerSearchType: (value) => dispatch(selectDealerSearchType(value)),
    setDealerSelectionSearchTerm: (value) =>
      dispatch(setDealerSelectionSearchTerm(value)),
    closeDealerNameSearchList: (value) =>
      dispatch(closeDealerNameSearchList(value)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DealerSelection);

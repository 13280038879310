module.exports = {
  loadingText: 'S\'il vous plaît, attendez',
  packagestitle: "Trouvez des pneus, des roues et des ensembles pour votre Hyundai",
  packagessubtitle: "Que vous soyez sur le marché des pneus neufs ou vous voulez donner à votre Hyundai une nouvelle vue, utilisez notre outil de recherche de pneus pour trouver exactement ce dont vous avez besoin. Nous portons les meilleures marques, une vaste sélection, et des ensembles qui apportent une valeur ajoutée. Consultez notre outil de recherche de pneus ci-dessous et si vous avez des questions, n'hésitez pas à contacter votre concessionnaire Hyundai qui sera heureux d'aider.",
  bronze: 'Bon',
  silver: 'Très bon',
  gold: 'Excellent',
  'product': {
    approvedTire: 'Pneus Approuvés',
    approvedTires: 'Pneus Approuvés'
  }
};

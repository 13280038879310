import { dispatcher } from "../dispatcher/Dispatcher.js";
import { DispatcherActions } from "../constants/DispatcherActions.js";

import {
  decrementApiCalls,
  incrementApiCalls,
  isAnyApiCallInProgress,
  get,
  post,
  getApiCallRedux,
} from "../api/ApiService.js";

var Base = require("backbone-extend-node");

/**
 * Just a base class for every action class so that it has dispatch method available
 * and also takes care about AJAX actions hooks
 *
 * @abstract
 */
export const AbstractAction = Base.extend({
  /**
   * Dispatches action through the dispatcher
   */
  dispatch: function (actionType, payload) {
    if (dispatcher && dispatcher._isDispatching) {
      setTimeout(() => {
        dispatcher.dispatch({
          actionType: actionType,
          payload: payload,
        });
      }, 0);
    } else {
      dispatcher.dispatch({
        actionType: actionType,
        payload: payload,
      });
    }
  },

  showLoadingScreen: function () {
    dispatcher.dispatch({
      actionType: DispatcherActions.API_CALL_STARTED,
      payload: {},
    });
  },

  hideLoadingScreen: function () {
    dispatcher.dispatch({
      actionType: DispatcherActions.API_CALL_FINISHED,
      payload: {},
    });
  },

  apiGet: function (endpoint, params, disableMask) {
    return this.apiCall("get", endpoint, params, disableMask);
  },

  apiPost: function (endpoint, params, disableMask) {
    return this.apiCall("post", endpoint, params, disableMask);
  },

  /**
   * API call is being wrapped into API_CALL_STARTED and API_CALL_FINISHED actions
   * @see ApiService.get
   * @private
   */
  apiCall: function (method, endpoint, params, disableMask) {
    console.log("endpoint: ", endpoint);
    var apiServiceMethod = method === "get" ? get : post;
    if (disableMask) {
      return apiServiceMethod(endpoint, params);
    } else {
      try {
        if (!isAnyApiCallInProgress()) {
          getApiCallRedux(false);
          this.dispatch(DispatcherActions.API_CALL_STARTED, {
            endpoint: endpoint,
          });
        }
        incrementApiCalls();

        return apiServiceMethod(endpoint, params).finally(
          function () {
            setTimeout(() => {
              decrementApiCalls();
              if (!isAnyApiCallInProgress()) {
                getApiCallRedux(true);
                this.dispatch(DispatcherActions.API_CALL_FINISHED, {});
              }
            }, 0);
          }.bind(this)
        );
      } catch (ex) {
        console.error(ex);
      }
    }
  },

  apiGetContextless: function (endpoint, params, disableMask) {
    var dispatch = function (actionType, payload) {
      dispatcher.dispatch({
        actionType: actionType,
        payload: payload,
      });
    };

    var apiCall = function (method, endpoint, params, disableMask) {
      var apiServiceMethod = method === "get" ? get : post;
      if (disableMask) {
        return apiServiceMethod(endpoint, params);
      } else {
        try {
          if (!isAnyApiCallInProgress()) {
            dispatch(DispatcherActions.API_CALL_STARTED, {
              endpoint: endpoint,
            });
            getApiCallRedux(false);
          }
          incrementApiCalls();

          return apiServiceMethod(endpoint, params).finally(
            function () {
              setTimeout(() => {
                decrementApiCalls();
                if (!isAnyApiCallInProgress()) {
                  dispatch(DispatcherActions.API_CALL_FINISHED, {});
                  getApiCallRedux(true);

                  /*
                if (enableAdobeLaunch()) {
                    setAdobeDigitalData(setAdobeLauncherDigitalData());
                    runAdobePageView();
                  }
                 */
                }
              }, 0);
            }.bind(this)
          );
        } catch (ex) {
          console.error(ex);
        }
      }
    };

    return apiCall("get", endpoint, params, disableMask);
  },
});

export const abstractAction = new AbstractAction();

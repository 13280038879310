
import { isEmpty, loadWishlistDealerModal } from './UtilityService';
import { applicationStore } from "../stores/ApplicationStore";

const SCREENMODES = {
  REGULAR: 'regular',
  IFRAME: 'iframe',
};

const getLocalStorage = () => {
  if (!!localStorage) {
    return localStorage;
  } else if (!!window.localStorage) {
    return window.localStorage;
  } else {
    return null;
  }
};

export const storageTerms = {
  token: "tw-token" + ((window.uniquifyToken !== null) ?  '-' + window.uniquifyToken : ''),
  showForm: "tw-storage-showForm",
  leadId: "tw-storage-leadId",
};

export const selectLocalStorage = (key) => {
  var localStorage = getLocalStorage();
  if (!isEmpty(localStorage) && !isEmpty(localStorage.getItem(key))) {
    return JSON.parse(localStorage.getItem(key));
  } else {
    return "";
  }
};

export const createInLocalStorage = (key, value) => {
  var localStorage = getLocalStorage();
  if (!isEmpty(localStorage)) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const removeFromLocalStorage = (key) => {
  var localStorage = getLocalStorage();
  for (var i in localStorage) {
    if (i.indexOf(key) > -1) {
      localStorage.removeItem(i);
    }
  }
};

export const saveStorageFormData = (showForm, leadId) => {
  createInLocalStorage(storageTerms.showForm, showForm);
  createInLocalStorage(storageTerms.leadId, leadId);
};

export const checkStorageFormData = () => {
  var showForm = selectLocalStorage(storageTerms.showForm), leadId = parseInt(selectLocalStorage(storageTerms.leadId));
  if (!isEmpty(showForm)) {
    applicationStore.data.parentLeadId = leadId;
    loadWishlistDealerModal(showForm, leadId);
    removeFromLocalStorage(storageTerms.showForm);
    removeFromLocalStorage(storageTerms.leadId);
  }
};
import { globalState } from '../GlobalState.js';
import { get } from '../api/ApiService.js';
import { searchActions } from '../actions/SearchActions.js';
import { applicationActions } from '../actions/ApplicationActions.js';
import { applicationStore } from '../stores/ApplicationStore.js';
import { productListStore } from '../stores/ProductListStore.js';
import { productListActions } from '../actions/ProductListActions.js';
import { vehicleSelectionActions } from '../actions/VehicleSelectionActions.js';

import { DispatcherActions } from '../constants/DispatcherActions';
import { AbstractStore } from './AbstractStore';
import { Router } from './Router';
import { RouterStates } from '../constants/RouterStates';

import { ProductTypes } from '../constants/ProductTypes.js';
import { SearchTypes } from '../constants/SearchTypes.js';
import { PricingFilter } from '../constants/PricingFilter.js';

import {
	isEmpty,
	sizeOf,
	getAppDealerId,
	checkArrayFieldsExist,
	listContains,
	removeFromList
} from '../service/UtilityService';
import {
	tireMappingFunction,
	getPricingByQuantity,
	getRebateByQuantity,
	getWinterSpeedRating,
	updateSpeedRatingDropDown,
	speedRatingIsValid,
	plyRatingIsValid,
	buildPlyRatingFilter,
	buildFilter,
	getMinFromList,
	getMaxFromList
} from '../service/ProductList';

var _ = require('lodash');
var Immutable = require('immutable');

const SHOW_RUN_FLAT_TIRES = "show-only-run-flat";
const SHOW_NON_RUN_FLAT_TIRES = "show-only-non-run-flat";
const SHOW_ALL_TIRES = "show-all";

const SearchStore = AbstractStore.extend({

	constructor: function () {
		this.data = {
			results: Immutable.List.of(),
			filteredResults: Immutable.List.of(),
			filteredBrandIds: [],
			season: null,
			seasonFilters: Immutable.List.of(),
			featuredTireCount: 0,
			filteredSeasonFilters: Immutable.List.of(),
			filteredSeasonCountList: Immutable.List.of(),
			loadRating: null,
			loadRatingFilters: Immutable.List.of(),
			speedIndex: null,
			speedIndexFilters: Immutable.List.of(),
			plyRating: [],
			plyRatingFilters: Immutable.List.of(),
			brandFilters: Immutable.List.of(),
			brands: [],
			tag: [],
			accessoryCategoryFilter: Immutable.List.of(),
			openedProduct: null,
			canGoNext: false,
			canGoPrev: false,
			showBrandFilter: false,
			showPlyFilter: false,
      showPricingFilter: false,
			filterMinMaxPricingList: [],
			tireListMinPrice: null,
			tireListMaxPrice: null,
			defaultSearchType: null,
			searchType: null,
			searchProductType: ProductTypes.TIRES,
			runFlatFilterValue: SHOW_ALL_TIRES,
      tireFilterTagList: [],

			searchResultLoaded: false,
			tireListLoaded: false,
			wheelListLoaded: false,
			accessoryListLoaded: false,
			packageListLoaded: false,

			originalEquipmentOnlyDefaultOn: false,

			pageIndex: 1
		};

		SearchStore.__super__.constructor.apply(this, arguments);
	},

	getActionHandlers: function () {
		return {
			[DispatcherActions.INITIALIZE_APP_STATE]: this.onInitializeAppState,
			[DispatcherActions.SEARCH_RESULT_LOADED]: this.onSearchResultLoaded,
			[DispatcherActions.SEARCH_RESULT_STOCK_REFRESHED]: this.onStockRefreshed,
			[DispatcherActions.SEARCH_RESULT_REQUEST_STOCK_REFRESH]: this.onRequestStockRefresh,
			[DispatcherActions.SEARCH_FILTER_SEASON]: this.onFilterSeason,
			[DispatcherActions.SEARCH_FILTER_LOAD_RATING]: this.onFilterLoadRating,
			[DispatcherActions.SEARCH_FILTER_SPEED_INDEX]: this.onFilterSpeedIndex,
			[DispatcherActions.SEARCH_FILTER_PLY_RATING]: this.onFilterPlyRating,
			[DispatcherActions.SEARCH_FILTER_TAG]: this.onFilterTag,
			[DispatcherActions.SEARCH_FILTER_BRANDS]: this.onFilterBrands,
      [DispatcherActions.SEARCH_PRICING_SELECTED]: this.onPricingSelected,
    	[DispatcherActions.SEARCH_PRICING_TOGGLE_ALL]: this.onPricingToggled,
			[DispatcherActions.TOGGLE_SEARCH_FLAG]: this.onSearchModalOpened,
			[DispatcherActions.PRODUCT_LIST_OPEN_DETAIL]: this.onOpenDetail,
			[DispatcherActions.PRODUCT_LIST_CLOSE_DETAIL]: this.onCloseDetail,
			[DispatcherActions.MODAL_REQUEST_UNMOUNT]: this.onCloseDetail,
			[DispatcherActions.PRODUCT_LIST_NEXT_PRODUCT]: this.onNextProduct,
			[DispatcherActions.PRODUCT_LIST_PREV_PRODUCT]: this.onPrevProduct,
			[DispatcherActions.PRODUCT_LIST_CHANGE_TIRE_QUANTITY]: this.onChangeTireQuantity,
			[DispatcherActions.LOAD_VEHICLE_SIMPLE_SEARCH]: this.onLoadVehicleSimpleSearch,
			[DispatcherActions.SET_SEARCH]: this.onSetSearchValue,
			[DispatcherActions.SEARCH_INCREMENT_PAGE_INDEX]: this.incrementPageIndex,
			[DispatcherActions.SEARCH_REQUEST_ITEM_STOCK_REFRESH]: this.onRequestStockItemRefresh,
			[DispatcherActions.SEARCH_SHOW_OR_HIDE_BRAND_FILTER]: this.showOrHideBrandFilter,
			[DispatcherActions.SEARCH_SHOW_OR_HIDE_PLY_FILTER]: this.showOrHidePlyFilter,
			[DispatcherActions.SEARCH_SHOW_OR_HIDE_PRICING_FILTER]: this.showOrHidePricingFilter,
			[DispatcherActions.SEARCH_LOAD_SEASON_FILTER]: this.loadSeasonFilters,
			[DispatcherActions.SEARCH_LOAD_TIRE_BRAND_FILTER]: this.loadTireBrandFilters,
			[DispatcherActions.SEARCH_LOAD_ACCESSORY_CATEGORY_FILTER]: this.loadAccessoryCategoryFilters,
			[DispatcherActions.SEARCH_SET_TYPE]: this.onSetSearchType,
			[DispatcherActions.SEARCH_SET_PRODUCT_TYPE]: this.onSetSearchProductType,
			[DispatcherActions.SET_OE_FILTER_VALUE]: this.setOeFilterValue,
			[DispatcherActions.SET_RUN_FLAT_FILTER_VALUE]: this.setRunFlatFilterValue,
			[DispatcherActions.WISHLIST_REVIEW_LOADED]: this.resetSearch,
			[DispatcherActions.CONSUMER_ORDER_LOADED]: this.resetSearch,
			[DispatcherActions.WISHLIST_REVIEW_LOADED]: this.resetSearch,
      [DispatcherActions.PRODUCT_QUICK_COMPARISON_RUN]: this.runQuickComparison
		};
	},

	onInitializeAppState: function (appState) {
    var routerState = Router.decodeRouterState(appState);

    this.loadAppState('search', appState, routerState.params);
    this.loadAppState('searchType', appState, routerState.params);
    this.loadAppState('season', appState, routerState.params);
    this.loadAppState('searchProductType', appState, routerState.params);


    /*
		if (appState.search) this.data.search = appState.search;
		if (appState.searchType) this.data.searchType = appState.searchType;
		if (appState.season) this.data.season = appState.season;
		if (appState.productType) this.data.searchProductType = appState.productType.toUpperCase();
		//*/

		this.emitChange();
	},

  loadAppState: function(key, appState, params) {
    var temp;
    if (params[key]) {
      temp = params;
      this.data[key] = temp[key];
    } else if (appState[key]) {
      temp = appState;
      this.data[key] = temp[key];
    }
  },

	incrementPageIndex: function () {
		var pageIndex = ++this.data.pageIndex;
		this.emitChange();
	},

	setOeFilterValue: function (oeFilterValue) {
		this.data.originalEquipmentOnlyDefaultOn = oeFilterValue;
		this.updateSearchResultList();
		this.emitChange();
	},

	setRunFlatFilterValue: function (runFlatFilterValue) {
		this.data.runFlatFilterValue = runFlatFilterValue;
		this.filterTires();
		this.emitChange();
	},

	onSearchModalOpened: function () {
		console.trace()
		if (this.data.searchProductType === null
			|| this.data.searchProductType === ProductTypes.ALLOY_WHEELS
			|| this.data.searchProductType === ProductTypes.ACCESSORIES) {
			this.data.searchProductType = ProductTypes.TIRES;
		}
		this.emitChange();
	},

	resetSearch: function() {
		this.data.results = Immutable.List.of();
		this.data.filteredResults = Immutable.List.of();
		this.data.filteredBrandIds = [];
		this.data.brands = null;
		this.data.featuredTireCount = 0;
		this.data.loadRating = null;
		this.data.speedIndex = null;
		this.data.plyRating = null;
		this.data.tag = [];
		this.data.pageIndex = 1;
		this.data.searchResultLoaded = false;
		this.data.tireListLoaded = false;
		this.data.wheelListLoaded = false;
		this.data.accessoryListLoaded = false;
		this.data.packageListLoaded = false;
	},

  onSetSearchValue: function(payload) {
    this.data.results = Immutable.List.of();
    this.data.filteredResults = Immutable.List.of();
    this.data.filteredBrandIds = [];
    this.data.brands = null;
    this.data.featuredTireCount = 0;
    this.data.loadRating = null;
    this.data.speedIndex = null;
    this.data.plyRating = null;
    this.data.tag = [];
    this.data.pageIndex = 1;
    this.data.runFlatFilterValue = SHOW_ALL_TIRES;
    this.data.searchResultLoaded = false;
    this.data.tireListLoaded = false;
    this.data.wheelListLoaded = false;
    this.data.accessoryListLoaded = false;
    this.data.packageListLoaded = false;
    this.data.search = payload.search;
    this.data.searchType = payload.type;
    this.emitChange();
	},

  onLoadVehicleSimpleSearch: function(payload) {
    applicationStore.data.year = payload.year;
    applicationStore.data.make = payload.make;
    applicationStore.data.category = payload.category;
    applicationStore.data.model = payload.model;
    this.data.searchType = SearchTypes.SEARCH_BY_VEHICLE_SIMPLE;
    productListStore.data.searchType = SearchTypes.SEARCH_BY_VEHICLE_SIMPLE;

    var routerState = globalState.select('applicationState', 'routerState').get();

    if (routerState !== RouterStates.TRIM) {
    	setTimeout(() => { applicationActions.changeRouterStateDirectly(RouterStates.TRIM); }, 100);
    }  else {
    	setTimeout(() => { vehicleSelectionActions.loadTrims(); }, 100)
		}

    this.emitChange();
	},

	onFilterSeason: function (payload) {
		/*
		var season = payload.season;
		this.data.season = season;
		if (parseInt(season) === 0) {
			this.data.speedIndex = getWinterSpeedRating(this.data.speedIndexFilters.get(0).get('key'), applicationStore.data.dealerWinterTireSpeedRatingOffset);
			this.data.speedIndexFilters = updateSpeedRatingDropDown(this.data.speedIndexFilters, this.data.speedIndex);
		} else if (parseInt(season) === 1) {
			this.data.speedIndex = getWinterSpeedRating(this.data.speedIndexFilters.get(0).get('key'), applicationStore.data.dealerAllSeasonTireSpeedRatingOffset);
			this.data.speedIndexFilters = updateSpeedRatingDropDown(this.data.speedIndexFilters, this.data.speedIndex);
		} else if (parseInt(season) === 2) {
			this.data.speedIndex = getWinterSpeedRating(this.data.speedIndexFilters.get(0).get('key'), applicationStore.data.dealerSummerTireSpeedRatingOffset);
			this.data.speedIndexFilters = updateSpeedRatingDropDown(this.data.speedIndexFilters, this.data.speedIndex);
		}
		setTimeout(() => {
			if (pro.data.searchType == SearchTypes.SEARCH_BY_TIRESIZE) {
				searchActions.loadSearchResults();
			} else if (this.data.searchType == SearchTypes.SEARCH_BY_PARTNUMBER) {
				this.updateSearchResultList();
			}
		}, 100);
		this.emitChange();
		*/
	},

	showOrHideBrandFilter: function () {
		if (this.data.showBrandFilter == false) this.data.showBrandFilter = true;
		else if (this.data.showBrandFilter == true) this.data.showBrandFilter = false;
		this.emitChange();
	},

	showOrHidePlyFilter: function () {
		if (this.data.showPlyFilter == false) this.data.showPlyFilter = true;
		else if (this.data.showPlyFilter == true) this.data.showPlyFilter = false;
		this.emitChange();
	},

  showOrHidePricingFilter: function () {
    if (this.data.showPricingFilter == false) this.data.showPricingFilter = true;
    else if (this.data.showPricingFilter == true) this.data.showPricingFilter = false;
    this.emitChange();
  },

	onFilterLoadRating: function (loadRating) {
		this.data.loadRating = loadRating;
		this.updateSearchResultList();
		this.emitChange();
	},

	onFilterSpeedIndex: function (speedIndex) {
		this.data.speedIndex = speedIndex;
		this.updateSearchResultList();
		this.emitChange();
	},

	onFilterPlyRating: function (plyRating) {
		this.data.plyRating = plyRating;
		this.updateSearchResultList();
		this.emitChange();
	},

  onFilterTag: function (tag) {
		this.data.tag = tag;
		this.updateSearchResultList();
		this.emitChange();
	},

	onFilterBrands: function (brands) {
		this.data.brands = !isEmpty(brands) ? brands : null;
		this.filterTires();
		this.emitChange();
	},

  onPricingSelected: function (pricing) {
    this.data.filterMinMaxPricingList = pricing;
		/*
    if (listContains(this.data.filterMinMaxPricingList, pricingId)) {
      this.data.filterMinMaxPricingList = removeFromList(this.data.filterMinMaxPricingList, pricingId);
    } else {
      this.data.filterMinMaxPricingList[sizeOf(this.data.filterMinMaxPricingList)] = pricingId;
    }
    */
    this.updateSearchResultList();
    this.emitChange();
  },

  onPricingToggled: function(toggleAll) {
    if (toggleAll) {
      this.data.filterMinMaxPricingList = Object.keys(PricingFilter).map(Number);
    } else {
      this.data.filterMinMaxPricingList = [];
    }
    this.updateSearchResultList();
    this.emitChange();
  },

  runQuickComparison: function() {
    if (sizeOf(this.data.filteredResults) > 0) {

      this.data.runComparisonOnLoad = false;

      var partIdArray = [];

      var featuredRequired = false;
      this.data.filteredResults.map(function(part, index) {
        if (part && part.get('frontTire').get('id') && sizeOf(partIdArray) < 3 && (!featuredRequired || part.get('featured'))) {
          if (part.get('featured')) featuredRequired = true;
          partIdArray.push(part.get('frontTire').get('id'));
        }
      });

      var firstPartId = partIdArray[0] ? partIdArray[0] : 0;
      var secondPartId = partIdArray[1] ? partIdArray[1] : 0;
      var thirdPartId = partIdArray[2] ? partIdArray[2] : 0;

      setTimeout(() => {
        productListActions.onGetProductComparison(ProductTypes.TIRES, 0, firstPartId, secondPartId, thirdPartId);
      }, 200);

    }

    this.emitChange();
  },

	onSetSearchType: function (type) {
		if (type === SearchTypes.SEARCH_BY_PARTNUMBER && this.data.searchProductType == null) {
			var defaultProductType = null;
			if (applicationStore.data.productTypes.indexOf(ProductTypes.TIRES) > -1) defaultProductType = ProductTypes.TIRES;
			else if (applicationStore.data.productTypes.indexOf(ProductTypes.ALLOY_WHEELS) > -1) defaultProductType = ProductTypes.ALLOY_WHEELS;
			else if (applicationStore.data.productTypes.indexOf(ProductTypes.ACCESSORIES) > -1) defaultProductType = ProductTypes.ACCESSORIES;
			else if (applicationStore.data.productTypes.indexOf(ProductTypes.PACKAGES) > -1) defaultProductType = ProductTypes.PACKAGES;
			this.data.searchProductType = defaultProductType;
		}
		if (type === SearchTypes.SEARCH_BY_PARTNUMBER) setTimeout(() => {
			productListActions.setOeFilterValue(false);
		}, 10);
		this.data.searchType = type;
		this.emitChange();
	},

	onSetSearchProductType: function (type) {
		this.data.searchProductType = type;
		if (type === ProductTypes.TIRES) {
			this.data.searchType = this.data.defaultSearchType;
    } else if (!applicationStore.data.enableMercedesFeatures) {
			this.data.defaultSearchType = SearchTypes.SEARCH_BY_VEHICLE_SIMPLE;
    }
		this.emitChange();
	},

	onSearchResultLoaded: function (response) {
		this.resetSearch();
		if (sizeOf(response) > 0) {
			var partList = [];
			if (response.hasOwnProperty("featuredPartList")) partList = response.featuredPartList.concat(response.partList);
			else partList = response.partList;
			this.data.results = partList
				.filter(this.filterHideItems)
				.map(tireMappingFunction(false, applicationStore.data.dealerIgnoreTireStock, "", applicationStore.data.setPartQuantityToOne));

			if (this.data.searchType === SearchTypes.SEARCH_BY_PARTNUMBER) this.data.season = applicationStore.data.originalSeason;
			this.data.season = response.resultParameters.season;

			this.data.filteredSeasonCountList = [];
			this.data.filteredSeasonCountList[0] = response.partListSummary.seasonList[0].partCount;
			this.data.filteredSeasonCountList[1] = response.partListSummary.seasonList[1].partCount;
			this.data.filteredSeasonCountList[2] = response.partListSummary.seasonList[2].partCount;

			var seasonFilters = this.data.seasonFilters.filter((seasonIndex, season) => {
				return this.data.filteredSeasonCountList[parseInt(season, 10)] > 0
			});
			this.data.filteredSeasonFilters = seasonFilters;

			var lowestFrontLoadRating = response.partListSummary.minLoadRating;
			var lowestFrontSpeedRating = response.partListSummary.minSpeedRating;
			var lowestFrontPlyRating = response.partListSummary.minPlyRating;

			if (lowestFrontLoadRating) {
				this.data.loadRating = lowestFrontLoadRating;
				if (lowestFrontLoadRating) {
					var loadRatingFilter = new Array();
					for (var i = lowestFrontLoadRating; i <= 135; i++) {
						loadRatingFilter[sizeOf(loadRatingFilter)] = parseFloat(i);
					}
					this.data.loadRatingFilters = Immutable.fromJS(loadRatingFilter.map(loadRatingFilter => buildFilter(loadRatingFilter, loadRatingFilter)));
				}
			}

			if (lowestFrontPlyRating) {
        var plyRatingFilters = ["LL", "SL", "XL", "B", "C", "D", "E", "F", "G", "H", "J", "L", "M", "N"];
				if (this.data.searchType === SearchTypes.SEARCH_BY_TIRESIZE) {

					this.data.plyRating = plyRatingFilters;

				} else {

          this.data.plyRating = plyRatingFilters.slice(plyRatingFilters.indexOf(lowestFrontPlyRating));
				}

				this.data.plyRatingFilters = Immutable.fromJS(buildPlyRatingFilter(lowestFrontPlyRating).map(plyRatingFilter => buildFilter(plyRatingFilter, plyRatingFilter)));
			}
			
			var speedIndex = null, speedIndexRear = null;
			var dealerOffset = applicationStore.data.dealerWinterTireSpeedRatingOffset;
			var dealerOffsetAllSeason = applicationStore.data.dealerAllSeasonTireSpeedRatingOffset;
			var dealerOffsetSummer = applicationStore.data.dealerSummerTireSpeedRatingOffset;
			if (this.data.season == 0 && lowestFrontSpeedRating) {
				speedIndex = getWinterSpeedRating(lowestFrontSpeedRating, (dealerOffset ? dealerOffset : 0));
				this.data.speedIndex = speedIndex;
				this.data.speedIndexFilters = updateSpeedRatingDropDown(this.data.speedIndexFilters, speedIndex);
			} else if (this.data.season == 1 && lowestFrontSpeedRating) {
				speedIndex = getWinterSpeedRating(lowestFrontSpeedRating, (dealerOffsetAllSeason ? dealerOffsetAllSeason : 0));
				this.data.speedIndex = speedIndex;
				this.data.speedIndexFilters = updateSpeedRatingDropDown(this.data.speedIndexFilters, speedIndex);
			} else if (this.data.season == 2 && lowestFrontSpeedRating) {
				speedIndex = getWinterSpeedRating(lowestFrontSpeedRating, (dealerOffsetSummer ? dealerOffsetSummer : 0));
				this.data.speedIndex = speedIndex;
				this.data.speedIndexFilters = updateSpeedRatingDropDown(this.data.speedIndexFilters, speedIndex);
			}

      this.data.tireFilterTagList = response.partListSummary.metatagList;
      this.data.minPlyRating = response.partListSummary.minPlyRating;

      this.data.pageIndex = 1;
      this.updateSearchResultList();
		}

		this.emitChange();
	},

	filterHideItems: function (item) {
		var hideItem = true;
		if (checkArrayFieldsExist(item, ['part', 'partSummary', 'primaryPart', 'hideItem'])) {
			hideItem = item['part']['partSummary']['primaryPart']['hideItem'];
		} else if (checkArrayFieldsExist(item, ['partSummary', 'primaryPart', 'hideItem'])) {
			hideItem = item['partSummary']['primaryPart']['hideItem'];
		}
		return !hideItem;
	},

	updateSearchResultList: function () {
		var featuredCount = 0, filteredResults = this.filterTires();

		var partListBrands = [];
		filteredResults.forEach(tire => {
			if (tire.get('featured')) featuredCount++;
			partListBrands[sizeOf(partListBrands)] = tire.get('manufacturerId');
		});
		partListBrands = _.uniq(partListBrands);

		this.data.featuredTireCount = featuredCount;
		this.data.filteredBrandIds = partListBrands;

		this.data.searchResultLoaded = true;
		this.data.tireListLoaded = true;
		this.data.wheelListLoaded = false;
		this.data.accessoryListLoaded = false;
		this.data.packageListLoaded = false;

		this.emitChange();
	},

	filterTires: function () {
		var originalEquipmentOnlyDefaultOn = this.data.originalEquipmentOnlyDefaultOn;
		var runFlatFilterValue = this.data.runFlatFilterValue;
		var searchType = this.data.searchType;
		var season = this.data.season;

    var staggered = this.data.isTireSizeStaggered;
    var loadRating = this.data.loadRating;
    var speedIndex = this.data.speedIndex;
    var plyRating = this.data.plyRating;
    var secondaryLoadRating = this.data.secondaryLoadRating;
    var secondarySpeedIndex = this.data.secondarySpeedIndex;
    var secondaryPlyRating = this.data.secondaryPlyRating;
    var brands = this.data.brands;
    var tag = this.data.tag;

    var tireBrandIsValid = this.tireBrandIsValid;

		var tireRunFlatCheck = (function (searchType, runFlatFilterValue, tire) {
			if (searchType === SearchTypes.SEARCH_BY_PARTNUMBER) return true;
			switch (runFlatFilterValue) {
				case SHOW_ALL_TIRES:
					return true;
				case SHOW_RUN_FLAT_TIRES:
					return tire.get('frontTire').get('runFlat') === '1';
				case SHOW_NON_RUN_FLAT_TIRES:
					return tire.get('frontTire').get('runFlat') === '0';
			}
		});

    var filterMinMaxPricingList = this.data.filterMinMaxPricingList;
    var filterMinValue = PricingFilter[getMinFromList(filterMinMaxPricingList)];
    if (typeof(filterMinValue) !== 'undefined') filterMinValue = parseInt(filterMinValue.min);
    var filterMaxValue = PricingFilter[getMaxFromList(filterMinMaxPricingList)];
    if (typeof(filterMaxValue) !== 'undefined') filterMaxValue = filterMaxValue.max !== null ? parseInt(filterMaxValue.max) : null;

    var tireMinPrice = Number.MAX_SAFE_INTEGER, tireMaxPrice = 0;
		var filteredResults = Immutable.List(this.data.results).filter(function(tire) {
      var frontDisplay = parseFloat(tire.get('frontTire').get('pricing').get('displayPrice'));
      if (frontDisplay < tireMinPrice) tireMinPrice = frontDisplay;
      if (tire.get('staggered') && parseFloat(tire.get('rearTire').get('pricing').get('displayPrice')) < tireMinPrice) tireMinPrice = parseFloat(tire.get('rearTire').get('pricing').get('displayPrice'));

      if (frontDisplay > tireMaxPrice) tireMaxPrice = frontDisplay;
      if (tire.get('staggered') && parseFloat(tire.get('rearTire').get('pricing').get('displayPrice')) > tireMaxPrice) tireMaxPrice = parseFloat(tire.get('rearTire').get('pricing').get('displayPrice'));

      return parseFloat(tire.get('frontTire').get('loadRating')) >= parseFloat(loadRating)
        && speedRatingIsValid(tire.get('speedRating'), speedIndex)
        && (typeof(filterMinValue) === 'undefined' || !tire.get('frontTire').get('hasPricing') || frontDisplay >= filterMinValue)
        && (typeof(filterMaxValue) === 'undefined' || filterMaxValue === null || !tire.get('frontTire').get('hasPricing') || frontDisplay <= filterMaxValue)

				&& (
					!tire.get('rearTire')
					|| (parseFloat(tire.get('rearTire').get('loadRating')) >= parseFloat(secondaryLoadRating)
					&& speedRatingIsValid(tire.get('rearTire').get('speedRating'), secondarySpeedIndex))
					&& plyRatingIsValid(tire.get('rearTire').get('plyRating'), secondaryPlyRating)
					&& (typeof(filterMinValue) === 'undefined' || !tire.get('rearTire').get('hasPricing') || frontDisplay >= filterMinValue)
					&& (typeof(filterMaxValue) === 'undefined' || filterMaxValue === null || !tire.get('rearTire').get('hasPricing') || frontDisplay <= filterMaxValue)
				)

        && tireRunFlatCheck(searchType, runFlatFilterValue, tire)
        && (!originalEquipmentOnlyDefaultOn || (originalEquipmentOnlyDefaultOn && tire.get('originalEquipment')))

        && (sizeOf(plyRating) === 0 || sizeOf(plyRating.filter(function(key) { if (tire.get('frontTire').get('plyRating') == key) { return key; } else { return false; } })) > 0)
        && (sizeOf(brands) === 0 || sizeOf(brands.filter(function(key) { if (tire.get('manufacturerId') == key) { return key; } else { return false; } })) > 0)
        && (sizeOf(tag) === 0 || sizeOf(tag.filter(function(key) { if (tire.get('frontTire').get('tireCategoryTags').indexOf(key) > -1) { return key; } else { return false; } })) > 0)
      }
		);

		this.data.filteredResults = filteredResults;
    this.data.tireListMinPrice = tireMinPrice;
    this.data.tireListMaxPrice = tireMaxPrice;
    this.emitChange();
		return filteredResults;
	},

	loadSeasonFilters: function (filters) {
		this.data.seasonFilters = Immutable.fromJS(filters);
		this.data.season = applicationStore.data.originalSeason;
		if (typeof this.data.season === 'undefined' && filters) {
			if (filters[0]) this.data.season = 0;
			else if (filters[1]) this.data.season = 1;
			else if (filters[2]) this.data.season = 2;
		}
		this.emitChange();
	},

	loadTireBrandFilters: function (filters) {
		if (filters) this.data.brandFilters = Immutable.fromJS(filters).map((brand, brandId) => buildFilter(brandId, brand.get('part_manufacturer_name')));
		this.emitChange();
	},

	loadAccessoryCategoryFilters: function (filters) {
		if (filters) {
			this.data.accessoryCategoryFilter = Immutable.fromJS(filters).map(function (category) {
				return Immutable.Map({
					key: category.get('accessoryCategoryId'),
					value: category.get('accessoryCategoryName')
				});
			});
		}
		this.emitChange();
	},

	refreshSpecificStock: function (url, stockLocationKey, tireId) {
		get(url, {
			dealerId: getAppDealerId(),
			stockLocationKey: stockLocationKey,
			tireId: tireId
		}).then(function (response) {
			searchActions.stockRefreshed(response[0]);
		});
	},

	tireBrandIsValid: function (tireBrand, approvedBrands) {
    return sizeOf(approvedBrands) === 0 || approvedBrands.indexOf(tireBrand) > -1;
	},

	onStockRefreshed: function (payload) {
		var immutablePayload = Immutable.fromJS(payload);

		if (sizeOf(immutablePayload) > 0) {
			var tireId = payload[0].itemId;
			var tireIndex = 0, nonFeatTireIndex = null;
			var tire = null, frontTireFound = false, rearTireFound = false;
			this.data.results.forEach(function (tireInfo, index) {
				if (tireInfo.get('frontTire').get('id') == tireId && !frontTireFound) {
					tire = tireInfo;
					tireIndex = index;
					frontTireFound = true;
				} else if (tireInfo.get('frontTire').get('id') == tireId && frontTireFound) {
					nonFeatTireIndex = index;
				} else if (tireInfo.get('rearTire') && !rearTireFound) {
					if (tireInfo.get('rearTire').get('id') == tireId) {
						tire = tireInfo;
						tireIndex = index;
						rearTireFound = true;
					}
				} else if (tireInfo.get('rearTire') && rearTireFound) {
					if (tireInfo.get('rearTire').get('id') == tireId) {
						nonFeatTireIndex = index;
					}
				}
			});

			var updateForEachStock = function (stocks, tireIndex, tireType, tireId) {
				stocks.forEach(function (stock, stockIndex) {
					refreshedStock = null;
					if (stock.get('loading')) {
						var refreshedStock = getRefreshedStock(immutablePayload, stock.get('itemId'), stock.get('stockLocationKey'));
						if (refreshedStock) {
							//this.data.results = this.data.results.setIn([tireIndex, tireType, 'stock', stockIndex], refreshedStock);
							this.data.results[tireIndex] = this.data.results[tireIndex].setIn([tireType, 'stock', stockIndex], refreshedStock);
						}
					}
				}.bind(this));
			}.bind(this);

			function getRefreshedStock(refreshedStocks, tireId, locationKey) {
				var refreshedStock = refreshedStocks.find(function (stock) {
					return stock.get('stockLocationKey') == locationKey && stock.get('itemId') == tireId;
				});

				if (refreshedStock) {
					return refreshedStock.set('loading', false);
				} else {
					return null;
				}
			}

			if (tire) {
				if (frontTireFound) {
					updateForEachStock(tire.get('frontTire').get('stock'), tireIndex, 'frontTire');
					if (nonFeatTireIndex) updateForEachStock(tire.get('frontTire').get('stock'), nonFeatTireIndex, 'frontTire', tire.get('frontTire').get('id'));
				} else if (rearTireFound) {
					updateForEachStock(tire.get('rearTire').get('stock'), tireIndex, 'rearTire');
					if (nonFeatTireIndex) updateForEachStock(tire.get('rearTire').get('stock'), nonFeatTireIndex, 'rearTire', tire.get('rearTire').get('id'));
				}
			}

			this.filterTires();
		}
		this.emitChange();
	},

	onRequestStockRefresh: function (payload) {
    var frontTireIndex = 0, nonFeatFrontTireIndex = null, frontTire = null, frontTireFound = false;
		if (payload) {
			var frontTireId = payload.frontTireId, results = this.data.results;
			for (var i in results) {
				if (results[i] && results[i].get('frontTire').get('id') == frontTireId && !frontTireFound) {
          frontTire = results[i];
          frontTireIndex = i;
          frontTireFound = true;
				} else if (results[i] && results[i].get('frontTire').get('id') == frontTireId && frontTireFound) {
          nonFeatFrontTireIndex = i;
				}
			}

			var updateForEachStock = function (stocks, tireIndex, tireType, tireIdToCompare) {
				stocks.forEach(function (stock, stockIndex) {
					if (stock.get('stockLocationKey') == payload.stockLocationKey && stock.get('itemId') == tireIdToCompare) {
						//this.data.results = this.data.results.setIn([tireIndex, tireType, 'stock', stockIndex], stock.set('loading', true));
						this.data.results[tireIndex] = this.data.results[tireIndex].setIn([ tireType, 'stock', stockIndex], stock.set('loading', true));
					}
				}.bind(this));
			}.bind(this);

			if (frontTireFound) {
				updateForEachStock(frontTire.get('frontTire').get('stock'), frontTireIndex, 'frontTire', frontTireId);
				if (nonFeatFrontTireIndex) updateForEachStock(frontTire.get('frontTire').get('stock'), nonFeatFrontTireIndex, 'frontTire', frontTireId);
			}
		}

		if (frontTire) {
			if (frontTireFound) {
				setTimeout(() => {
					this.refreshSpecificStock(payload.url, payload.stockLocationKey, frontTire.get('frontTire').get('id'));
				}, 10);
			}
      this.filterTires();
		}

		this.emitChange();
	},

	onRequestStockItemRefresh: function (payload) {
		if (payload && sizeOf(this.data.results) > 0) {
			var tireId = payload.frontTireId;
			var tireIndex = 0, nonFeatTireIndex = null;
			var tire = null, tireFound = false,  mappedTire = null;

			if (this.data.tireListLoaded) {
        var results = this.data.results;
        for (var i in results) {
        	if (results[i] && results[i].get('frontTire').get('id') == tireId) {
        		tire = results[i];
        		break;
					}
				}
				if (tire) {
          mappedTire = tire
            .get('frontTire')
            .get('stock')
            .concat(tire.get('rearTire') ? tire.get('rearTire').get('stock') : [])
            .map(function (stock) {
              return {
                tireId: stock.get('itemId'),
                stockLocationId: stock.get('stockLocationId'),
                stockLocationKey: stock.get('stockLocationKey'),
                stockApiQueryUrl: stock.get('stockApiQueryUrl')
              };
            });
        }
			}
			if (mappedTire) {
        var refreshSpecificStock = this.refreshSpecificStock;
        mappedTire.forEach(function (stock, stockIndex) {
          setTimeout(() => {
            if (tireId && stock.stockApiQueryUrl !== null)  refreshSpecificStock(stock.stockApiQueryUrl, stock.stockLocationKey, stock.tireId);
          }, 10);
        });
        if (this.data.tireListLoaded) this.filterTires();
      }
		}
		this.emitChange();
	},

	onOpenDetail: function (payload) {
		if (globalState.select('applicationState').get('routerState') === RouterStates.SEARCH_RESULT) {
			this.data.openedIndex = payload.index;
			this.setOpenedProductAndNextPrevFlags();
			this.emitChange();
		}
	},

	onNextProduct: function () {
		if (globalState.select('applicationState').get('routerState') === RouterStates.SEARCH_RESULT) {
			this.data.openedIndex++;
			this.setOpenedProductAndNextPrevFlags();
			this.emitChange();
		}
	},

	onPrevProduct: function () {
		if (globalState.select('applicationState').get('routerState') === RouterStates.SEARCH_RESULT) {
			this.data.openedIndex--;
			this.setOpenedProductAndNextPrevFlags();
		}
	},

	setOpenedProductAndNextPrevFlags: function () {
		this.data.openedProduct = this.data.results.get(this.data.openedIndex);
		this.data.canGoPrev = this.data.openedIndex > 0;
		this.data.canGoNext = this.data.openedIndex < (sizeOf(this.data.results) - 1);
		this.emitChange();
	},

	onCloseDetail: function () {
		if (globalState.select('applicationState').get('routerState') === RouterStates.SEARCH_RESULT) {
			this.data.openedProduct = null;
			this.emitChange();
		}
	},

	// Only front tires are supported for searching
	onChangeTireQuantity: function (payload) {
		if (globalState.select('applicationState').get('routerState') === RouterStates.SEARCH_RESULT) {
			var filteredTire = this.data.results.find(tire => payload.featured == tire.get('featured') && ((tire.get('frontTire').get('id') === payload.tireKey && payload.rearTireKey === null) || (tire.get('frontTire').get('id') === payload.tireKey && tire.get('rearTire') && tire.get('rearTire').get('id') === payload.rearTireKey)));
			var filteredIndex = this.data.results.findIndex(tire => payload.featured == tire.get('featured') && ((tire.get('frontTire').get('id') === payload.tireKey && payload.rearTireKey === null) || (tire.get('frontTire').get('id') === payload.tireKey && tire.get('rearTire') && tire.get('rearTire').get('id') === payload.rearTireKey)));
			var listOfTires = this.data.results;

			var filteredTireQuantity;
			if (payload.front) {
				filteredTireQuantity = filteredTire.get('frontTire').set('quantity', payload.quantity);
				filteredTireQuantity = filteredTireQuantity.set('pricing', Immutable.fromJS(getPricingByQuantity(filteredTire.get('frontTire').get('pricingDetails'), payload.quantity)));
				filteredTireQuantity = filteredTireQuantity.set('rebate', Immutable.fromJS(getRebateByQuantity(filteredTire.get('frontTire').get('pricingDetails'), payload.quantity)));
			} else {
				filteredTireQuantity = filteredTire.get('rearTire').set('quantity', payload.quantity);
				filteredTireQuantity = filteredTireQuantity.set('pricing', Immutable.fromJS(getPricingByQuantity(filteredTire.get('rearTire').get('pricingDetails'), payload.quantity)));
				filteredTireQuantity = filteredTireQuantity.set('rebate', Immutable.fromJS(getRebateByQuantity(filteredTire.get('rearTire').get('pricingDetails'), payload.quantity)));
			}

			if (payload.front) listOfTires[filteredIndex] = listOfTires[filteredIndex].set('frontTire', filteredTireQuantity);
			else listOfTires[filteredIndex] = listOfTires[filteredIndex].set('rearTire', filteredTireQuantity);

			this.data.results = listOfTires;
			this.filterTires(false);
			this.emitChange();
		}
	}
});

export const searchStore = new SearchStore();

import { applicationStore } from "../../stores/ApplicationStore.js";
import { languageStore } from "../../stores/LanguageStore.js";
import { productListStore } from "../../stores/ProductListStore.js";
import { applicationActions } from "../../actions/ApplicationActions";
import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";
import { ProductTypes } from "../../constants/ProductTypes.js";

import { Modal } from "../ux/Modal.jsx";
import { Image } from "../ux/Image.jsx";

import {
  getPricingFromQuantityAndDetailsRefactored,
  getPricingRebateByQuantity,
} from "../../service/ProductList";
import {
  formTypes,
  sizeOf,
  money_format,
  getAppLanguage,
  getTrackingEventCategory,
  getTrackingEventOrderId,
} from "../../service/UtilityService";
import {
  renderNTDButton,
  getPurchasNtdButtonText,
} from "../../service/OrderService.js";
import { isUserLoggedIn } from "../../api/AuthService";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";
import store from "../../stores/configureStore.js";
import {
  onComparisonLoadedSuccessfully,
  resetProductComparison,
} from "../../redux/features/productList";
import { shoppingCartOpenForm } from "../../redux/features/shoppingCart";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var ReactDOM = require("react-dom");
var _ = require("lodash");
var Cx = require("classnames");

export class ComparisonModal extends I18nComponent {
  state = {
    comparisonData: this.props.comparisonData,
    comparisonList: productListStore.data.comparisonPartList,
    comparisonQuantityList: productListStore.data.comparisonQuantityList,
    comparisonErrorModal: productListStore.data.failedAddToComparison,
    comparisonErrorType: productListStore.data.comparisonErrorType,
    comparisonLoadingError: productListStore.data.comparisonLoadingError,

    showProductInformation: true,
    showProductInformationTransition: false,
    showWarranty: false,
    showDescription: false,
    showOtherInfo: false,

    showPricingInformation: true,
    showPricingInformationTransition: false,

    quantityModalToggle: false,
    quantityModalPart: null,

    comparisonEmailNotAvailable: false,
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("Comparison Modal");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Comparison Modal Access"
    );
    productListStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    productListStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.refs.emailInput)
        ReactDOM.findDOMNode(this.refs.emailInput).focus();
    }, 250);
  }

  render() {
    //console.log('render comparison modal');
    //console.log(this.state.comparisonList);

    var comparisonList = this.state.comparisonList,
      _this = this,
      noCost = this.t("comparison.noCost");
    var tableClassName = Cx({
      "comparison-table": true,
      "hide-product-information": !this.state.showProductInformation,
      "hide-pricing-information": !this.state.showPricingInformation,
    });
    var dealerTax = " (" + applicationStore.data.dealerTaxPercent + "%) ";
    return (
      <Modal
        className="comparison-modal"
        refactor={true}
        requestUnmount={this.closeComparisonModal}
        datadtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.COMPARE.FULL)}
      >
        <div className="wrapper modal-wrapper comparison-modal-content">
          <div className="comparison-header">
            <h2>
              {this.t("comparison.title")}
              <button
                className={setAdobeLaunchClass(
                  "btn print-compare-button",
                  ADOBELAUNCHER_CLASS.BUTTON.LINK
                )}
                data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.COMPARE.FULL)}
                onClick={this.printCompare}
              >
                {this.t("wishlist.print")}
              </button>
              <button
                className={setAdobeLaunchClass(
                  "btn email-compare-button",
                  ADOBELAUNCHER_CLASS.BUTTON.LINK
                )}
                data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.COMPARE.FULL)}
                onClick={this.downloadCompare}
              >
                {this.t("wishlist.download")}
              </button>
              <button
                className={setAdobeLaunchClass(
                  "btn email-compare-button",
                  ADOBELAUNCHER_CLASS.BUTTON.LINK
                )}
                data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.COMPARE.FULL)}
                onClick={this.emailCompare}
              >
                {this.t("wishlist.modal.email")}
              </button>
            </h2>
          </div>
          <div className="comparison-body">
            <table className={tableClassName}>
              <tbody>
                <tr className="first-row">
                  <th className="first-column"></th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        return (
                          <td key={"first-row-part-" + index}>
                            <div className="cell-top">
                              <div className="part-name">
                                <h3>{part.get("name")}</h3>
                              </div>
                              <div className="part-image">
                                <Image
                                  className="part-main-image"
                                  src={part.get("largeImage")}
                                  alt="Part Image"
                                />
                                <Image
                                  src={part.get("brandImage")}
                                  className="part-brand"
                                  alt={
                                    "Image Brand " + part.get("manufacturer")
                                  }
                                />
                              </div>
                            </div>
                          </td>
                        );
                      })
                    : false}
                </tr>

                <tr className="section-label-row">
                  <th className="first-column first-cell">
                    <div className="section-title">
                      <span>{this.t("comparison.productInformation")}</span>
                    </div>
                  </th>
                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        switch (index) {
                          case sizeOf(comparisonList) - 1:
                            var classNamePlus = Cx({
                              "toggle-section": true,
                              fa: true,
                              "fa-plus-circle": true,
                              "run-transition":
                                _this.state.showProductInformationTransition &&
                                !_this.state.showProductInformation,
                              "hide-section":
                                _this.state.showProductInformation,
                            });
                            var classNameMinus = Cx({
                              "toggle-section": true,
                              fa: true,
                              "fa-minus-circle": true,
                              "run-transition":
                                _this.state.showProductInformationTransition &&
                                _this.state.showProductInformation,
                              "hide-section":
                                !_this.state.showProductInformation,
                            });
                            return (
                              <td
                                key={"first-section-label-row-" + index}
                                className="last-cell"
                              >
                                <div className="section-control">
                                  <i
                                    className={setAdobeLaunchClass(
                                      classNamePlus,
                                      ADOBELAUNCHER_CLASS.ICON
                                    )}
                                    data-dtm={setAdobeLaunchDTM(
                                      "",
                                      ADOBELAUNCHER_DTM.COMPARE.FULL
                                    )}
                                    aria-hidden="true"
                                    onClick={
                                      _this.toggleProductInformationSection
                                    }
                                  ></i>
                                  <i
                                    className={setAdobeLaunchClass(
                                      classNameMinus,
                                      ADOBELAUNCHER_CLASS.ICON
                                    )}
                                    data-dtm={setAdobeLaunchDTM(
                                      "",
                                      ADOBELAUNCHER_DTM.COMPARE.FULL
                                    )}
                                    aria-hidden="true"
                                    onClick={
                                      _this.toggleProductInformationSection
                                    }
                                  ></i>
                                </div>
                              </td>
                            );
                          default:
                            return (
                              <td key={"first-section-label-row-" + index}></td>
                            );
                        }
                      })
                    : false}
                </tr>

                <tr className="second-row even">
                  <th className="first-column">
                    {this.t("comparison.productDetails") + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        // Get Part Info
                        var productPartNumberLabel = "",
                          partNumber = "";
                        if (
                          typeof part
                            .get("frontTire")
                            .get("nationalPartNumber") !== "undefined" &&
                          part.get("frontTire").get("nationalPartNumber") !==
                            null &&
                          part.get("frontTire").get("nationalPartNumber") !== ""
                        ) {
                          partNumber = part
                            .get("frontTire")
                            .get("nationalPartNumber");
                          productPartNumberLabel = _this.t(
                            "product.nationalPartNumber"
                          );
                        } else {
                          partNumber = part.get("frontTire").get("partNumber");
                          productPartNumberLabel =
                            _this.t("product.partNumber");
                        }
                        var dualLoadRating = false;
                        if (
                          part.get("frontTire").get("dualFitmentLoadRating") > 0
                        ) {
                          dualLoadRating =
                            " / " +
                            part.get("frontTire").get("dualFitmentLoadRating");
                        }
                        var plyRating = false;
                        if (part.get("frontTire").get("plyRating")) {
                          plyRating =
                            " / " + part.get("frontTire").get("plyRating");
                        }
                        var hasMileageWarranty = false,
                          mileageWarranty = false,
                          milageText = "";
                        if (
                          part.get("tireTreadlifeWarranty") &&
                          part
                            .get("tireTreadlifeWarranty")
                            .get(applicationStore.data.dealerInfo.country) !==
                            "" &&
                          typeof part
                            .get("tireTreadlifeWarranty")
                            .get(applicationStore.data.dealerInfo.country) !==
                            "undefined"
                        ) {
                          hasMileageWarranty = true;
                          mileageWarranty = part
                            .get("tireTreadlifeWarranty")
                            .get(applicationStore.data.dealerInfo.country);
                          switch (applicationStore.data.dealerInfo.country) {
                            case "CA":
                              milageText =
                                getAppLanguage() === "fr" ? "km" : "Km";
                              break;
                            case "US":
                              milageText =
                                getAppLanguage() === "fr" ? "mi." : "mi.";
                              break;
                          }
                        }

                        return (
                          <td key={"second-row-part-" + index}>
                            <div className="cell-top">
                              <div className="part-info">
                                <div className="first">
                                  <span>{productPartNumberLabel + ": "}</span>
                                  <span className="feature-value part-number">
                                    {partNumber}
                                  </span>
                                </div>
                                <div className="middle">
                                  <span>
                                    {_this.t("product.tireSize") + ": "}
                                  </span>
                                  <span className="feature-value">
                                    {part
                                      .get("frontTire")
                                      .get("tireVehicleClass") +
                                      part.get("frontTire").get("tireSize")}
                                  </span>
                                </div>
                                <div className="middle">
                                  <span>
                                    {_this.t("product.loadSpeedPly") + ": "}
                                  </span>
                                  <span className="feature-value">
                                    {part.get("loadRating")} {dualLoadRating} /{" "}
                                    {part.get("speedRating")}
                                    {plyRating}
                                  </span>
                                </div>
                                {mileageWarranty ? (
                                  <div className="last">
                                    <span>
                                      {_this.t("product.mileageWarranty") +
                                        ": "}
                                    </span>
                                    <span className="feature-value">
                                      {mileageWarranty + " " + milageText}
                                    </span>
                                  </div>
                                ) : (
                                  false
                                )}
                              </div>
                            </div>
                          </td>
                        );
                      })
                    : false}
                </tr>

                <tr className="third-row">
                  <th className="first-column">
                    {this.t("product.season") + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        return (
                          <td key={"third-row-part-" + index}>
                            <div className="cell-top">
                              {part.get("tireSeason") === "0" ? (
                                <div className="part-season">
                                  <span>
                                    {_this.t("product.filters.season.winter")}
                                  </span>
                                  <Image
                                    className="season-icon"
                                    src="/static/img/winter.png"
                                  />
                                </div>
                              ) : (
                                false
                              )}
                              {part.get("tireSeason") === "1" ? (
                                <div className="part-season">
                                  <span>
                                    {_this.t(
                                      "product.filters.season.allSeason"
                                    )}
                                  </span>
                                  <Image
                                    className="season-icon"
                                    src="/static/img/allseason.png"
                                  />
                                </div>
                              ) : (
                                false
                              )}
                              {part.get("tireSeason") === "2" ? (
                                <div className="part-season">
                                  <span>
                                    {_this.t("product.filters.season.summer")}
                                  </span>
                                  <Image
                                    className="season-icon"
                                    src="/static/img/summer.png"
                                  />
                                </div>
                              ) : (
                                false
                              )}
                            </div>
                          </td>
                        );
                      })
                    : false}
                </tr>

                <tr className="fourth-row even">
                  <th className="first-column">
                    {this.t("product.description") + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        var description = languageStore.getTranslated(
                          part.get("description").toJS()
                        );

                        return (
                          <td key={"fourth-row-part-" + index}>
                            <div className="cell-top">
                              {description ? (
                                <div className="part-description">
                                  <div className="tire-description">
                                    <span
                                      className="description-link text-small"
                                      dangerouslySetInnerHTML={{
                                        __html: description,
                                      }}
                                    ></span>
                                  </div>
                                </div>
                              ) : (
                                false
                              )}
                            </div>
                          </td>
                        );
                      })
                    : false}
                </tr>

                <tr className="fifth-row">
                  <th className="first-column">
                    {this.t("product.warranty") + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        var itemWarranty = false,
                          warranty = false;
                        if (part.get("frontTire").get("warranty")) {
                          part
                            .get("frontTire")
                            .get("warranty")
                            .forEach(function (warranty) {
                              if (
                                warranty &&
                                applicationStore.data.dealerInfo &&
                                applicationStore.data.dealerInfo.country &&
                                getAppLanguage()
                              ) {
                                if (
                                  warranty.get("warrantyCountryCode") &&
                                  String(
                                    warranty.get("warrantyCountryCode")
                                  ).toUpperCase() ==
                                    applicationStore.data.dealerInfo.country &&
                                  warranty.get("warrantyLanguageCode") &&
                                  String(
                                    warranty.get("warrantyLanguageCode")
                                  ).toLowerCase() == getAppLanguage()
                                ) {
                                  itemWarranty = warranty.get("warrantyText");
                                }
                              }
                            });
                        }
                        if (
                          itemWarranty &&
                          itemWarranty.indexOf(
                            "Treadlife Warranty: 45,000 - 65,000 miles - - -"
                          ) !== -1
                        ) {
                          itemWarranty =
                            itemWarranty.substr(
                              0,
                              itemWarranty.indexOf("miles - - -") + 5
                            ) + "...";
                          warranty = (
                            <div className="tire-warranty">
                              <span
                                className="warranty-link text-small"
                                dangerouslySetInnerHTML={{
                                  __html: itemWarranty,
                                }}
                              ></span>
                            </div>
                          );
                        } else if (itemWarranty && itemWarranty.length <= 75) {
                          warranty = (
                            <div className="tire-warranty">
                              <span
                                className="warranty-link text-small"
                                dangerouslySetInnerHTML={{
                                  __html: itemWarranty,
                                }}
                              ></span>
                            </div>
                          );
                        } else {
                          if (itemWarranty && itemWarranty.length > 0) {
                            warranty = (
                              <div className="tire-warranty">
                                <span
                                  className="warranty-link text-small"
                                  dangerouslySetInnerHTML={{
                                    __html: itemWarranty,
                                  }}
                                ></span>
                              </div>
                            );
                          }
                        }

                        return (
                          <td key={"fifth-row-part-" + index}>
                            <div className="cell-top">
                              {part.get("frontTire").get("warranty") ? (
                                <div className="part-warranty">{warranty}</div>
                              ) : (
                                false
                              )}
                            </div>
                          </td>
                        );
                      })
                    : false}
                </tr>

                <tr className="sixth-row even">
                  <th className="first-column">
                    {this.t("comparison.otherInformation") + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        return (
                          <td key={"sixth-row-part-" + index}>
                            <div className="cell-top">
                              {!!part.get("originalEquipment") ? (
                                <span className="original-text">
                                  {_this.t("product.approvedTire")}
                                </span>
                              ) : (
                                false
                              )}
                            </div>
                          </td>
                        );
                      })
                    : false}
                </tr>

                <tr className="section-label-row">
                  <th className="first-column first-cell">
                    <div className="section-title">
                      <span>{this.t("comparison.pricingInformation")}</span>
                    </div>
                  </th>
                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        switch (index) {
                          case sizeOf(comparisonList) - 1:
                            var classNamePlus = Cx({
                              "toggle-section": true,
                              fa: true,
                              "fa-plus-circle": true,
                              "run-transition":
                                _this.state.showPricingInformationTransition &&
                                !_this.state.showPricingInformation,
                              "hide-section":
                                _this.state.showPricingInformation,
                            });
                            var classNameMinus = Cx({
                              "toggle-section": true,
                              fa: true,
                              "fa-minus-circle": true,
                              "run-transition":
                                _this.state.showPricingInformationTransition &&
                                _this.state.showPricingInformation,
                              "hide-section":
                                !_this.state.showPricingInformation,
                            });
                            return (
                              <td
                                key={"second-section-label-row-" + index}
                                className="last-cell"
                              >
                                <div className="section-control">
                                  <i
                                    className={setAdobeLaunchClass(
                                      classNamePlus,
                                      ADOBELAUNCHER_CLASS.ICON
                                    )}
                                    data-dtm={setAdobeLaunchDTM(
                                      "",
                                      ADOBELAUNCHER_DTM.COMPARE.FULL
                                    )}
                                    aria-hidden="true"
                                    onClick={
                                      _this.togglePricingInformationSection
                                    }
                                  ></i>
                                  <i
                                    className={setAdobeLaunchClass(
                                      classNameMinus,
                                      ADOBELAUNCHER_CLASS.ICON
                                    )}
                                    data-dtm={setAdobeLaunchDTM(
                                      "",
                                      ADOBELAUNCHER_DTM.COMPARE.FULL
                                    )}
                                    aria-hidden="true"
                                    onClick={
                                      _this.togglePricingInformationSection
                                    }
                                  ></i>
                                </div>
                              </td>
                            );
                          default:
                            return (
                              <td
                                key={"second-section-label-row-" + index}
                              ></td>
                            );
                        }
                      })
                    : false}
                </tr>

                <tr className="seventh-row">
                  <th className="first-column">
                    {this.t("comparison.pricingDetails") + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        if (!_this.hidePricing(part)) {
                          var priceLabel = _this.t("product.retailPrice");
                          if (
                            applicationStore.data.dealerSite &&
                            applicationStore.data.dealerSellingPriceLabel[
                              ProductTypes.TIRES
                            ][getAppLanguage()]
                          ) {
                            priceLabel =
                              applicationStore.data.dealerSellingPriceLabel[
                                ProductTypes.TIRES
                              ][getAppLanguage()];
                          }

                          var showMSRP =
                            applicationStore.data.dealerShowMsrp &&
                            parseFloat(
                              part
                                .get("frontTire")
                                .get("pricing")
                                .get("listPrice")
                            ) >
                              parseFloat(
                                part
                                  .get("frontTire")
                                  .get("pricing")
                                  .get("displayPrice")
                              );

                          var enableMinimumTag =
                            !isUserLoggedIn() &&
                            part
                              .get("frontTire")
                              .get("pricing")
                              .get("marketMinimum");
                          var enableMarketMinimumTag =
                            isUserLoggedIn() &&
                            parseFloat(
                              part
                                .get("frontTire")
                                .get("pricing")
                                .get("oldPrice")
                            ) > 0;

                          var quantityDropDown = false;
                          if (part.get("frontTire").get("hasPricing")) {
                            quantityDropDown = (
                              <div>
                                <span className="quantity">
                                  {part
                                    .get("frontTire")
                                    .get("quantity")
                                    .get("value")}
                                </span>
                              </div>
                            );
                          }

                          var pricing = false,
                            total =
                              parseFloat(
                                part
                                  .get("frontTire")
                                  .get("pricing")
                                  .get("privatePrice")
                              ) *
                              parseFloat(
                                part
                                  .get("frontTire")
                                  .get("quantity")
                                  .get("value")
                              );

                          //console.log(part);

                          if (part.get("hasPromotion")) {
                            // has promotion
                            var price =
                              getPricingFromQuantityAndDetailsRefactored(
                                part.get("frontTire").get("pricingDetails"),
                                part.get("frontTire").get("quantity")
                              );

                            var promotionPricingText =
                              price["promotionPricingText"];
                            var displaySavings = price["displaySavings"];

                            //var promotionText = _this.td(promotionPricingText.toJS());
                            var promotionText = "";
                            if (typeof promotionPricingText === "object")
                              promotionText = promotionPricingText.get(
                                getAppLanguage()
                              );

                            if (
                              price &&
                              price["url"] &&
                              price["url"][getAppLanguage()] !== ""
                            ) {
                              promotionText = (
                                <a
                                  className="promotion-link"
                                  href={price["url"].get(getAppLanguage())}
                                  target="_blank"
                                >
                                  <span>{promotionText}</span>
                                </a>
                              );
                            }

                            var quantityText =
                              _this.t("product.quantity") + ":";
                            if (
                              part
                                .get("frontTire")
                                .get("pricing")
                                .get("showUnitPrice")
                            ) {
                              quantityText =
                                money_format(
                                  part
                                    .get("frontTire")
                                    .get("pricing")
                                    .get("displayPrice")
                                ) +
                                " " +
                                _this.t("product.perTireLwr");
                            }

                            pricing = (
                              <ul className="tire-promoted-pricing">
                                <li className="promoted-title">
                                  <span>{promotionText}</span>
                                </li>
                                <li className="promoted-quantity">
                                  <span className="pricing-label">
                                    {quantityText}
                                  </span>
                                  <span className="pricing-value">
                                    {quantityDropDown}
                                  </span>
                                </li>
                                {displaySavings > 0 ? (
                                  <li className="promoted-savings">
                                    <span className="pricing-label">
                                      {_this.t("product.youSave") + ":"}
                                    </span>
                                    <span className="pricing-value">
                                      {money_format(
                                        displaySavings *
                                          parseInt(
                                            part
                                              .get("frontTire")
                                              .get("quantity")
                                              .get("value"),
                                            10
                                          )
                                      )}
                                    </span>
                                  </li>
                                ) : (
                                  false
                                )}
                                <li className="pricing-total">
                                  <span className="pricing-label">
                                    {_this.t("product.total") + ":"}
                                  </span>
                                  <span className="pricing-value">
                                    {money_format(total)}
                                  </span>
                                </li>
                              </ul>
                            );
                          } else if (
                            part.get("frontTire").get("hasPricing") &&
                            part
                              .get("frontTire")
                              .get("pricing")
                              .get("displayPrice") > 0
                          ) {
                            var totalList =
                                parseFloat(
                                  part
                                    .get("frontTire")
                                    .get("pricing")
                                    .get("listPrice")
                                ) *
                                parseFloat(
                                  part
                                    .get("frontTire")
                                    .get("quantity")
                                    .get("value")
                                ),
                              savings = totalList - total;

                            //{enableMinimumTag ? <li className="pricing-msrp"><span className="pricing-label"><img src="/static/img/downarrow_icon.png" />{_this.t('marketMinimum')}</span></li> : false}

                            pricing = (
                              <ul className="tire-non-promoted-pricing">
                                {enableMarketMinimumTag ? (
                                  <li className="pricing-msrp">
                                    <span className="pricing-label">
                                      <img
                                        src="/static/img/alert_icon.png"
                                        className="alert"
                                      />
                                      {_this.t("belowMarket")}{" "}
                                      {money_format(
                                        part
                                          .get("frontTire")
                                          .get("pricing")
                                          .get("oldPrice")
                                      )}
                                    </span>
                                  </li>
                                ) : (
                                  false
                                )}
                                {showMSRP ? (
                                  <li className="pricing-msrp">
                                    <span className="pricing-label">
                                      {_this.t("product.msrp") + ":"}
                                    </span>
                                    <span className="pricing-value">
                                      {money_format(
                                        part
                                          .get("frontTire")
                                          .get("pricing")
                                          .get("listPrice")
                                      )}
                                    </span>
                                  </li>
                                ) : (
                                  false
                                )}
                                <li className="pricing-retail-price">
                                  <span className="pricing-label">
                                    {priceLabel + ":"}
                                  </span>
                                  <span className="pricing-value">
                                    {money_format(
                                      part
                                        .get("frontTire")
                                        .get("pricing")
                                        .get("displayPrice")
                                    )}
                                  </span>
                                </li>
                                <li className="pricing-quantity">
                                  <span className="pricing-label">
                                    {_this.t("product.quantity") + ":"}
                                  </span>
                                  <span className="pricing-value">
                                    {quantityDropDown}
                                  </span>
                                </li>
                                <li className="pricing-total">
                                  <span className="pricing-label">
                                    {_this.t("product.total") + ":"}
                                  </span>
                                  <span className="pricing-value">
                                    {money_format(total)}
                                  </span>
                                </li>
                                {applicationStore.data.dealerShowMsrp &&
                                savings > 0 ? (
                                  <li className="pricing-savings">
                                    <span className="pricing-label">
                                      {_this.t("product.totalSavings") + ":"}
                                    </span>
                                    <span className="pricing-value">
                                      {money_format(savings)}
                                      {enableMinimumTag ? "*" : false}
                                    </span>
                                  </li>
                                ) : (
                                  false
                                )}
                              </ul>
                            );
                          } else {
                            pricing = (
                              <ul className="tire-non-promoted-pricing">
                                <li className="pricing-msrp">
                                  <p className="contact-message">
                                    {_this.t("product.contactDealerForPricing")}
                                  </p>
                                </li>
                              </ul>
                            );
                          }

                          return (
                            <td key={"seventh-row-part-" + index}>
                              <div className="cell-top">
                                <div className="tire-pricing">{pricing}</div>
                              </div>
                            </td>
                          );
                        } else {
                          return <td></td>;
                        }
                      })
                    : false}
                </tr>

                <tr className="eighth-row even">
                  <th className="first-column">
                    {this.t("comparison.promotion") + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        if (!_this.hidePricing(part)) {
                          var link = false,
                            promotionText = false;
                          if (
                            part
                              .get("frontTire")
                              .get("pricing")
                              .get("promotionText")
                              .toJS().en !== ""
                          ) {
                            var price =
                                getPricingFromQuantityAndDetailsRefactored(
                                  part.get("frontTire").get("pricingDetails"),
                                  part.get("frontTire").get("quantity")
                                ),
                              link = getPricingRebateByQuantity(
                                part.get("frontTire").get("pricingDetails"),
                                part.get("frontTire").get("quantity")
                              );
                            var promotionPricingText =
                              price["promotionPricingText"];
                            var displaySavings = price["displaySavings"];
                            var promotionText = "";
                            if (typeof promotionPricingText === "object")
                              promotionText = promotionPricingText.get(
                                getAppLanguage()
                              );
                            if (
                              price &&
                              price["url"] &&
                              price["url"][getAppLanguage()] !== ""
                            ) {
                              promotionText = (
                                <a
                                  className="promotion-link"
                                  href={price["url"].get(getAppLanguage())}
                                  target="_blank"
                                >
                                  <span>{promotionText}</span>
                                </a>
                              );
                            }
                          }

                          return (
                            <td key={"eighth-row-part-" + index}>
                              <div className="cell-top">{promotionText}</div>
                            </td>
                          );
                        } else {
                          return <td></td>;
                        }
                      })
                    : false}
                </tr>

                <tr className="ninth-row">
                  <th className="first-column">
                    {this.t("comparison.additionalCosts") + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        if (!_this.hidePricing(part)) {
                          var quantityValue = part
                            .get("frontTire")
                            .get("quantity")
                            .get("value");
                          var quantityContextId = part
                            .get("frontTire")
                            .get("quantity")
                            .get("pricingContextId");

                          var lineItemList = false;
                          var quantityContext =
                            _this.state.comparisonQuantityList[index];
                          if (quantityContext)
                            quantityContext = quantityContext[quantityValue];
                          if (quantityContext)
                            quantityContext = quantityContext["contextList"];

                          var quantityObject = null;
                          if (quantityContext) {
                            quantityContext.map(function (quantity, index) {
                              if (
                                quantity["pricingContextId"] ===
                                quantityContextId
                              ) {
                                quantityObject = quantity;
                              }
                            });
                          }

                          if (quantityObject)
                            lineItemList = quantityObject
                              ? quantityObject.lineItemList
                              : false;

                          return (
                            <td key={"ninth-row-part-" + index}>
                              <div className="cell-top">
                                <ul>
                                  {lineItemList
                                    ? lineItemList.map(function (
                                        lineItem,
                                        index
                                      ) {
                                        return (
                                          <li
                                            key={
                                              part.get("frontTire").get("id") +
                                              "-line-item-row-" +
                                              index
                                            }
                                          >
                                            <span className="left">
                                              {lineItem["qty"] > 1
                                                ? lineItem["qty"] +
                                                  " - " +
                                                  lineItem["title"][
                                                    getAppLanguage()
                                                  ]
                                                : lineItem["title"][
                                                    getAppLanguage()
                                                  ]}
                                            </span>
                                            <span className="right">
                                              {parseFloat(lineItem["price"]) > 0
                                                ? money_format(
                                                    lineItem["price"]
                                                  )
                                                : noCost}
                                            </span>
                                          </li>
                                        );
                                      })
                                    : false}
                                </ul>
                              </div>
                            </td>
                          );
                        } else {
                          return <td></td>;
                        }
                      })
                    : false}
                </tr>

                <tr className="tenth-row even">
                  <th className="first-column">
                    {this.t("comparison.subtotal") + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        if (!_this.hidePricing(part)) {
                          var quantityValue = part
                            .get("frontTire")
                            .get("quantity")
                            .get("value");
                          var quantityContextId = part
                            .get("frontTire")
                            .get("quantity")
                            .get("pricingContextId");

                          var subtotal = _this.t("notApplicable");
                          var quantityContext =
                            _this.state.comparisonQuantityList[index];
                          if (quantityContext)
                            quantityContext = quantityContext[quantityValue];
                          if (quantityContext)
                            quantityContext = quantityContext["contextList"];

                          var quantityObject = null;
                          if (quantityContext) {
                            quantityContext.map(function (quantity, index) {
                              if (
                                quantity["pricingContextId"] ===
                                quantityContextId
                              ) {
                                quantityObject = quantity;
                              }
                            });
                          }

                          if (quantityObject)
                            subtotal = quantityObject
                              ? money_format(quantityObject.subTotal)
                              : _this.t("notApplicable");

                          return (
                            <td key={"tenth-row-part-" + index}>
                              <div className="cell-top">
                                <span className="pricing-detail">
                                  {subtotal}
                                </span>
                              </div>
                            </td>
                          );
                        } else {
                          return <td></td>;
                        }
                      })
                    : false}
                </tr>

                <tr className="eleventh-row">
                  <th className="first-column">
                    {this.t("comparison.tax") + dealerTax + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        if (!_this.hidePricing(part)) {
                          var quantityValue = part
                            .get("frontTire")
                            .get("quantity")
                            .get("value");
                          var quantityContextId = part
                            .get("frontTire")
                            .get("quantity")
                            .get("pricingContextId");

                          var taxTotal = _this.t("notApplicable");
                          var quantityContext =
                            _this.state.comparisonQuantityList[index];
                          if (quantityContext)
                            quantityContext = quantityContext[quantityValue];
                          if (quantityContext)
                            quantityContext = quantityContext["contextList"];

                          var quantityObject = null;
                          if (quantityContext) {
                            quantityContext.map(function (quantity, index) {
                              if (
                                quantity["pricingContextId"] ===
                                quantityContextId
                              ) {
                                quantityObject = quantity;
                              }
                            });
                          }

                          if (quantityObject)
                            taxTotal = quantityObject
                              ? money_format(quantityObject.taxTotal)
                              : _this.t("notApplicable");

                          return (
                            <td key={"eleventh-row-part-" + index}>
                              <div className="cell-top">
                                <span className="pricing-detail">
                                  {taxTotal}
                                </span>
                              </div>
                            </td>
                          );
                        } else {
                          return <td></td>;
                        }
                      })
                    : false}
                </tr>

                <tr className="twelfth-row even">
                  <th className="first-column">
                    {this.t("comparison.total") + ":"}
                  </th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        if (!_this.hidePricing(part)) {
                          var quantityValue = part
                            .get("frontTire")
                            .get("quantity")
                            .get("value");
                          var quantityContextId = part
                            .get("frontTire")
                            .get("quantity")
                            .get("pricingContextId");

                          var grandTotal = _this.t("notApplicable");
                          var quantityContext =
                            _this.state.comparisonQuantityList[index];
                          if (quantityContext)
                            quantityContext = quantityContext[quantityValue];
                          if (quantityContext)
                            quantityContext = quantityContext["contextList"];

                          var quantityObject = null;
                          if (quantityContext) {
                            quantityContext.map(function (quantity, index) {
                              if (
                                quantity["pricingContextId"] ===
                                quantityContextId
                              ) {
                                quantityObject = quantity;
                              }
                            });
                          }

                          if (quantityObject)
                            grandTotal = money_format(
                              quantityObject.grandTotal
                            );

                          return (
                            <td key={"twelfth-row-part-" + index}>
                              <div className="cell-top">
                                <span className="pricing-detail">
                                  {grandTotal}
                                </span>
                              </div>
                            </td>
                          );
                        } else {
                          return <td></td>;
                        }
                      })
                    : false}
                </tr>

                <tr className="thirteenth-row even">
                  <th className="first-column"></th>

                  {comparisonList
                    ? comparisonList.map(function (part, index) {
                        //var hasAddToCart = part.get('frontTire').get('hasPricing') && part.get('frontTire').get('pricing').get('displayPrice') > 0;

                        //Add To Cart
                        var hasAddToCart =
                          part.get("frontTire").get("hasPricing") &&
                          part
                            .get("frontTire")
                            .get("pricing")
                            .get("displayPrice") > 0 &&
                          (!applicationStore.data.dealerHidePublicPricing(
                            isUserLoggedIn()
                          ) ||
                            isUserLoggedIn());

                        //Order Tire
                        var hasNtdOrder = part
                          .get("frontTire")
                          .get("supportDealerOrder");

                        //Purchase Tire
                        var hasNtdPurchase =
                          !hasNtdOrder &&
                          hasAddToCart &&
                          part.get("frontTire").get("supportConsumerOrder");

                        var quantityValue = part
                          .get("frontTire")
                          .get("quantity")
                          .get("value");
                        var quantityContextId = part
                          .get("frontTire")
                          .get("quantity")
                          .get("pricingContextId");

                        var quantityContext =
                          _this.state.comparisonQuantityList[index];
                        if (quantityContext)
                          quantityContext = quantityContext[quantityValue];
                        if (quantityContext)
                          quantityContext = quantityContext["contextList"];

                        var quantityObject = null;
                        if (quantityContext) {
                          quantityContext.map(function (quantity, index) {
                            if (
                              quantity["pricingContextId"] === quantityContextId
                            ) {
                              quantityObject = quantity;
                            }
                          });
                        }

                        var financingtotal = 0,
                          grandtotal = 0;
                        if (quantityObject) {
                          grandtotal = parseFloat(quantityObject.grandTotal);
                          financingtotal = grandtotal / 6;
                        }

                        if (_this.hidePricing(part)) {
                          return (
                            <td key={"thirteenth-row-part-" + index}>
                              <div className="cell-top">
                                <button
                                  className="btn"
                                  onClick={_this.openCreateCustomerAccount}
                                >
                                  {_this.t("product.loginForPricing")}
                                </button>
                              </div>
                            </td>
                          );
                        } else {
                          return (
                            <td key={"thirteenth-row-part-" + index}>
                              <div className="cell-top">
                                {_this.enableNtdOrder(part)
                                  ? renderNTDButton(
                                      _this.onAddToBasket.bind(_this, part),
                                      "order",
                                      false,
                                      grandtotal,
                                      "",
                                      false,
                                      setAdobeLaunchDTM(
                                        "",
                                        ADOBELAUNCHER_DTM.COMPARE.PRODUCT,
                                        part.get("name")
                                      )
                                    )
                                  : false}
                                {_this.enableNtdPurchase(part)
                                  ? renderNTDButton(
                                      _this.onAddToBasket.bind(_this, part),
                                      "purchase",
                                      false,
                                      grandtotal,
                                      "",
                                      _this.enablePartFinancingButton(part),
                                      setAdobeLaunchDTM(
                                        "",
                                        ADOBELAUNCHER_DTM.COMPARE.PRODUCT,
                                        part.get("name")
                                      )
                                    )
                                  : false}
                                {_this.enablePartFinancingButton(part)
                                  ? _this.renderFinancingButton(
                                      part,
                                      financingtotal
                                    )
                                  : false}
                                {hasAddToCart &&
                                !_this.enableNtdOrder(part) &&
                                !_this.enableNtdPurchase(part) ? (
                                  <button
                                    className={setAdobeLaunchClass(
                                      "btn",
                                      ADOBELAUNCHER_CLASS.BUTTON.LINK
                                    )}
                                    data-dtm={setAdobeLaunchDTM(
                                      "",
                                      ADOBELAUNCHER_DTM.COMPARE.PRODUCT,
                                      part.get("name")
                                    )}
                                    aria-label={_this.getAddToCartText(
                                      part,
                                      grandtotal
                                    )}
                                    onKeyDown={(e) =>
                                      _this.onAddToBasketOnKeyDown(part, e)
                                    }
                                    onClick={_this.onAddToBasket.bind(
                                      _this,
                                      part
                                    )}
                                  >
                                    {_this.getAddToCartText(part, grandtotal)}
                                  </button>
                                ) : (
                                  false
                                )}
                              </div>
                            </td>
                          );
                        }
                      })
                    : false}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {this.renderQuantityModal()}
      </Modal>
    );
  }

  hidePricing = (part) => {
    return (
      part.get("frontTire").get("pricing").get("marketMinimum") &&
      !isUserLoggedIn(true) &&
      applicationStore.data.dealerEnableCustomerLogin
    );
  };

  enablePartFinancingButton = (part) => {
    return (
      part.get("frontTire").get("pricing").get("isFinancingRule") &&
      applicationStore.data.enableFinancing !== "0"
    );
  };

  renderFinancingButton = (part, total) => {
    switch (applicationStore.data.enableFinancing) {
      case "1":
        return (
          <box className="line button">
            <button
              className={setAdobeLaunchClass(
                "btn no-padding",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                ADOBELAUNCHER_DTM.COMPARE.PRODUCT,
                part.get("name")
              )}
              onClick={this.onAddToBasket.bind(this, part, true)}
            >
              <text>{this.t("financeAvailable")}</text>
            </button>
          </box>
        );
      case "2":
        return (
          <box className="line button">
            <button
              className={setAdobeLaunchClass(
                "btn no-padding",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                ADOBELAUNCHER_DTM.COMPARE.PRODUCT,
                part.get("name")
              )}
              onClick={this.onAddToBasket.bind(this, part, true)}
            >
              <text>
                {this.t("financeAvailable")}
                {" - "}
                {"0%"}
              </text>
            </button>
          </box>
        );
      case "3":
        return (
          <box className="line button finance-button">
            <button
              className={setAdobeLaunchClass(
                "btn no-padding",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                ADOBELAUNCHER_DTM.COMPARE.PRODUCT,
                part.get("name")
              )}
              onClick={this.onAddToBasket.bind(this, part, true)}
            >
              {this.renderFinancingText(total)}
            </button>
          </box>
        );
      default:
        return false;
    }
  };

  renderFinancingText = (total) => {
    return (
      <box className="financing-text">
        <container className="side small">
          <column className="half-a">
            <box>
              <box>
                <h2>{this.t("payMonthly")}</h2>
              </box>
              <box className="subtext top">
                <text className="text-small">{this.t("zeroInterest")}</text>
              </box>
              <box className="subtext bottom">
                <text className="text-xsmall alt-color">
                  {this.t("taxesNotIncluded")}
                </text>
              </box>
            </box>
          </column>
          <column className="half-a right">
            <box>
              <box>
                <h2>{money_format(total)}</h2>
                <text className="text-small">{this.t("perMonth")}</text>
              </box>
            </box>
          </column>
        </container>
      </box>
    );
  };

  getAddToCartText = (part, grandtotal) => {
    if (this.enablePartFinancingButton(part) && this.enableNtdPurchase()) {
      return this.t("payInFull");
    } else if (this.enableNtdPurchase(part)) {
      return getPurchasNtdButtonText(grandtotal);
    } else {
      return this.t("product.addToWishlist");
    }
  };

  hasNtdStock = (part) => {
    var valid = false;
    if (part && part.get("frontTire")) {
      part
        .get("frontTire")
        .get("stock")
        .map(function (stock) {
          if (
            stock &&
            stock.get("stockLocationType") == "RLT" &&
            stock.get("stockQty") > 0
          ) {
            valid = true;
          }
        });
    }
    return valid;
  };

  enableNtdOrder = (part) => {
    var supportDealerOrder = false;
    if (part && part.get("frontTire")) {
      supportDealerOrder = part.get("frontTire").get("supportDealerOrder");
    }
    return (
      applicationStore.data.dealerAllowNtdStockOrder &&
      supportDealerOrder &&
      this.hasNtdStock(part)
    );
  };

  enableNtdPurchase = (part) => {
    var valid = false;
    if (part && part.get("frontTire")) {
      valid = part.get("frontTire").get("supportConsumerOrder");
    }
    return valid;
  };

  openCreateCustomerAccount = () => {
    applicationActions.openLoginTextDialog(true);
  };

  renderQuantityModal = () => {
    if (this.state.quantityModalToggle) {
      var part = this.state.quantityModalPart,
        _this = this;

      var promotionText = "";
      if (
        part.get("frontTire").get("pricing").get("promotionText").toJS().en !==
        ""
      ) {
        var price = getPricingFromQuantityAndDetailsRefactored(
          part.get("frontTire").get("pricingDetails"),
          part.get("frontTire").get("quantity")
        );
        promotionText =
          "-" + " " + this.td(price.get("promotionPricingText").toJS());
      }

      return (
        <Modal
          className="quantity-selection"
          requestUnmount={this.toggleQuantityModal}
        >
          <div className="modal-wrapper comparison-quantity-modal">
            <div className="title">
              <span className="bold">
                {this.t("comparison.quantitySelection") + ":"}
              </span>
              <span>{part.get("name")}</span>
            </div>
            <div className="quantity-select">
              <ul>
                {part
                  .get("frontTire")
                  .get("quantities")
                  .map(function (n, index) {
                    var className = Cx({
                      quantities: true,
                      selected:
                        part.get("frontTire").get("quantity").get("value") ===
                          n.get("value") &&
                        part
                          .get("frontTire")
                          .get("quantity")
                          .get("pricingContextId") ===
                          n.get("pricingContextId"),
                    });
                    return (
                      <li
                        key={"part-select-quantity-" + index}
                        className={className}
                        onClick={_this.setModalPartQuantity.bind(_this, n)}
                      >
                        <span>
                          {n.get("title").get(languageStore.data.lang)}{" "}
                          {n.get("pricingContextId") !== "0"
                            ? promotionText
                            : ""}
                        </span>
                      </li>
                    );
                  })
                  .toArray()}
              </ul>
            </div>
            <div className="quantity-select-controls">
              <button className="btn" onClick={this.setPartQuantity}>
                {this.t("comparison.selectQuantity")}
              </button>
              <button className="btn" onClick={this.setPartQuantities}>
                {this.t("comparison.selectQuantityForAllParts")}
              </button>
            </div>
            <div className="quantity-modal-close">
              <Image
                className="close"
                onClick={this.toggleQuantityModal}
                src="/static/img/close.png"
              />
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  onAddToBasketOnKeyDown = (product, evt) => {
    checkForEvent: if ("undefined" != typeof evt.keyCode) {
      if (evt.keyCode == 13 || evt.keyCode == 32) {
        evt.preventDefault();
        evt.stopPropagation();
        break checkForEvent;
      }
      return;
    }
    this.onAddToBasket(product);
  };

  onAddToBasket = (product, openFinancing = false) => {
    shoppingCartActions.addItemToShoppingCart(
      product.get("frontTire").get("id"),
      {
        en: product.get("name"),
        fr: product.get("name"),
      },
      product.get("smallImage"),
      product.get("frontTire").get("pricing").get("displayPrice"),
      product.get("frontTire").get("pricing").get("privatePrice"),
      product.get("frontTire").get("pricing").get("promotionPricingText"),
      parseInt(product.get("frontTire").get("quantity").get("value"), 10),
      ProductTypes.TIRES,
      product,
      0,
      product.get("frontTire").get("pricing").get("pricingContextId"),
      product.get("relatedParts"),
      0,
      product.get("frontTire").get("pricing").get("listPrice"),
      false,
      false,
      "Comparison",
      false,
      openFinancing === true
    );

    // setTimeout(() => { productListActions.onResetComparison(); }, 10);
  };

  downloadCompare = () => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Comparison Modal - Open Download Form",
      getTrackingEventOrderId()
    );
    // shoppingCartActions.openWishlistFormModal(formTypes.COMPARISON_DOWNLOAD);
    store.dispatch(shoppingCartOpenForm(formTypes.COMPARISON_DOWNLOAD));
  };

  emailCompare = () => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Comparison Modal - Open Email Form",
      getTrackingEventOrderId()
    );
    store.dispatch(shoppingCartOpenForm(formTypes.COMPARISON_EMAIL));
    // shoppingCartActions.openWishlistFormModal(formTypes.COMPARISON_EMAIL);
  };

  printCompare = () => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Comparison Modal - Open Print Form",
      getTrackingEventOrderId()
    );
    store.dispatch(shoppingCartOpenForm(formTypes.COMPARISON_PRINT));
    // shoppingCartActions.openWishlistFormModal(formTypes.COMPARISON_PRINT);
  };

  setModalPartQuantity = (quantity) => {
    var frontTire = this.state.quantityModalPart
      .get("frontTire")
      .set("quantity", quantity);
    var part = this.state.quantityModalPart.set("frontTire", frontTire);

    this.setState({
      quantityModalPart: part,
    });
  };

  setPartQuantity = () => {
    var partId = this.state.quantityModalPart.get("frontTire").get("id");
    var quantityValue = this.state.quantityModalPart
      .get("frontTire")
      .get("quantity")
      .get("value");
    var quantityContextId = this.state.quantityModalPart
      .get("frontTire")
      .get("quantity")
      .get("pricingContextId");

    var result = {
      partId: partId,
      quantityValue: quantityValue,
      quantityContextId: quantityContextId,
    };
    store.dispatch(onSetComparisonPartQuantity(result));
    // productListActions.onSetComparisonPartQuantity(result);
    setTimeout(() => {
      this.toggleQuantityModal();
    }, 10);
  };

  setPartQuantities = () => {
    var quantityValue = this.state.quantityModalPart
      .get("frontTire")
      .get("quantity")
      .get("value");
    var quantityContextId = this.state.quantityModalPart
      .get("frontTire")
      .get("quantity")
      .get("pricingContextId");

    var result = {
      quantityValue: quantityValue,
      quantityContextId: quantityContextId,
    };
    store.dispatch(onSetComparisonPartQuantity(result));
    // productListActions.onSetComparisonPartQuantities(result);
    setTimeout(() => {
      this.toggleQuantityModal();
    }, 10);
  };

  closeComparisonModal = () => {
    // productListActions.onResetComparison();
    store.dispatch(resetProductComparison());
    store.dispatch(onComparisonLoadedSuccessfully(false));
  };

  toggleProductInformationSection = () => {
    this.setState({
      showProductInformationTransition: true,
    });

    setTimeout(() => {
      this.setState({
        showProductInformation: !this.state.showProductInformation,
      });

      setTimeout(() => {
        this.setState({
          showProductInformationTransition: false,
        });
      }, 500);
    }, 500);
  };

  togglePricingInformationSection = () => {
    this.setState({
      showPricingInformationTransition: true,
    });

    setTimeout(() => {
      this.setState({
        showPricingInformation: !this.state.showPricingInformation,
      });

      setTimeout(() => {
        this.setState({
          showPricingInformationTransition: false,
        });
      }, 500);
    }, 500);
  };

  toggleQuantityModal = (part) => {
    if (part) {
      this.setState({
        quantityModalPart: part,
        quantityModalToggle: !this.state.quantityModalToggle,
      });
    } else {
      this.setState({
        quantityModalPart: null,
        quantityModalToggle: !this.state.quantityModalToggle,
      });
    }
  };

  onChange = () => {
    this.setState({
      comparisonData: productListStore.data.comparisonPartData,
      comparisonList: productListStore.data.comparisonPartList,
      comparisonQuantityList: productListStore.data.comparisonQuantityList,
      comparisonErrorModal: productListStore.data.failedAddToComparison,
      comparisonErrorType: productListStore.data.comparisonErrorType,
      comparisonLoadingError: productListStore.data.comparisonLoadingError,
    });
  };
}

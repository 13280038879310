import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";
import { ProductTypes } from "../../constants/ProductTypes.js";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { shoppingCartStore } from "../../stores/ShoppingCartStore.js";

import { Image } from "../ux/Image.jsx";

import {
  money_format,
  isEmpty,
  sizeOf,
  getAppLanguage,
  getTirePromotionalImage,
} from "../../service/UtilityService";
import { convertDateToFrench } from "../../service/CalendarService";
import { getDestinationInverse } from "../../api/RequestBuilderHelper.js";
import { isUserLoggedIn } from "../../api/AuthService";
import {
  getPriceText,
  loadWishlistWithContext,
  showMsrpColumn,
  showSavingsColumn,
} from "../../service/WishlistService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";
import { connect } from "react-redux";

var React = require("react");
var Immutable = require("immutable");
var Moment = require("moment");
var _ = require("lodash");
var Cx = require("classnames");

class Wishlist extends I18nComponent {
  componentDidMount() {
    loadWishlistWithContext();
    shoppingCartActions.loadWishlistPrint();
  }

  componentDidUpdate() {
    var hash = window.location.hash,
      runPrintWindow = true;
    if (hash) {
      var splitParams = hash.split("/");
      if (splitParams.constructor == Array && 1 in splitParams) {
        if (splitParams.length === 9 && splitParams[7] === "disablePrint") {
          runPrintWindow = false;
        }
      }
    }
    if (runPrintWindow && !this.state.printHasRun) {
      this.setState({
        printHasRun: true,
        suffixFunc: setInterval(() => {
          this.runWindowPrint();
        }, 1000),
      });
    }
  }

  runWindowPrint = () => {
    if (this.props.cart !== null) {
      this.setState({ printHasRun: true, runPrintFunction: function () {} });
      clearInterval(this.state.suffixFunc);
      setTimeout(() => {
        window.print();
      }, 2000);
    }
  };

  state = {
    printHasRun: false,
    runPrintFunction: function () {},
  };

  render() {
    var _this = this;
    var priceLabel = getPriceText(),
      date = false,
      dateString = "";
    if (this.props.cart) {
      date = new Date(Moment(this.props.cart.orderCreatedTimestamp));
      dateString = date.toDateString();
      date = date.toLocaleTimeString();
      if (getAppLanguage() === "fr") {
        dateString = convertDateToFrench(dateString);
      }
    }

    var hasVehicle = false,
      vehicleTitle = false;
    if (this.props.cart) {
      hasVehicle = this.props.cart.hasOwnProperty("vehicleInformation")
        ? this.props.cart.vehicleInformation.length > 0
        : false;
      if (hasVehicle) {
        vehicleTitle =
          getAppLanguage() === "fr"
            ? this.props.cart.vehicleInformation[0].vehicleTitle.fr
            : this.props.cart.vehicleInformation[0].vehicleTitle.en;
      }
    }

    var totalPromotionSavings = 0,
      promotionList = [],
      textTemplate = "";
    if (this.props.cart && sizeOf(this.props.cart.orderItemList) > 0) {
      this.props.cart.orderItemList.map(function (item) {
        var temp =
          getAppLanguage() === "fr"
            ? item.itemPromotion.fr
            : item.itemPromotion.en;
        if (!isEmpty(temp) && parseInt(item.pricingContextId) > 0) {
          promotionList.push(temp);
        }
      });
      textTemplate = this.t(
        sizeOf(promotionList) === 1
          ? "quote.savedThroughOnePromotion"
          : "quote.savedThroughMultiplePromotions"
      );
    }

    var hasCustomerInfo = this.props.cart
      ? this.props.cart.customerName !== "" ||
        this.props.cart.customerEmail !== "" ||
        this.props.cart.customerPhone !== "" ||
        this.props.cart.customerNotes !== "" ||
        this.props.cart.appointmentRequestDate !== "" ||
        this.props.cart.appointmentRequestTime !== ""
      : false;
    var customerInfoDiv = false;
    if (hasCustomerInfo) {
      customerInfoDiv = (
        <div className="print-customer-info">
          <ul className="customer-info-list">
            <li className="customer-info title">
              <h3>{this.t("quote.contactInformation")}</h3>
            </li>
            {this.props.cart.customerName ? (
              <li className="customer-info">
                <span className="label">
                  {this.t("quote.nameFieldLabel") + ":"}
                </span>
                <span className="value">{this.props.cart.customerName}</span>
              </li>
            ) : (
              false
            )}
            {this.props.cart.customerEmail ? (
              <li className="customer-info">
                <span className="label">
                  {this.t("quote.emailFieldLabel") + ":"}
                </span>
                <span className="value">{this.props.cart.customerEmail}</span>
              </li>
            ) : (
              false
            )}
            {this.props.cart.customerPhone ? (
              <li className="customer-info">
                <span className="label">
                  {this.t("appointmentForm.phone") + ":"}
                </span>
                <span className="value">{this.props.cart.customerPhone}</span>
              </li>
            ) : (
              false
            )}
            {this.props.cart.customerNotes ? (
              <li className="customer-info">
                <span className="label">
                  {this.t("quote.noteFieldLabel") + ":"}
                </span>
                <span className="value">{this.props.cart.customerNotes}</span>
              </li>
            ) : (
              false
            )}
            {this.props.cart.appointmentRequestDate ? (
              <li className="customer-info">
                <span className="label">
                  {this.t("quote.contactPreference") + ":"}
                </span>
                <span className="value">
                  {this.props.cart.customerContactMethodPreference
                    ? this.t("appointmentForm.email")
                    : this.t("appointmentForm.phone")}
                </span>
              </li>
            ) : (
              false
            )}
            {this.props.cart.appointmentRequestDate ? (
              <li className="customer-info">
                <span className="label">{this.t("quote.date") + ":"}</span>
                <span className="value">
                  {this.props.cart.appointmentRequestDate}
                </span>
              </li>
            ) : (
              false
            )}
            {this.props.cart.appointmentRequestTime ? (
              <li className="customer-info">
                <span className="label">{this.t("quote.time") + ":"}</span>
                <span className="value">
                  {this.props.cart.appointmentRequestTime}
                </span>
              </li>
            ) : (
              false
            )}
          </ul>
        </div>
      );
    }

    var hasBanner = false,
      bannerDiv = false;
    if (applicationStore.data.wishlistBanner) {
      hasBanner = applicationStore.data.wishlistBanner.length > 0;
      if (hasBanner) {
        bannerDiv = (
          <div className="banner-images">
            {applicationStore.data.wishlistBanner.map(function (banner, index) {
              return (
                <div key={"wishlist-banner-" + index} className="banner-image">
                  <Image
                    className="image"
                    src={
                      getAppLanguage() === "fr"
                        ? banner.imageUrl.fr
                        : banner.imageUrl.en
                    }
                  />
                </div>
              );
            })}
          </div>
        );
      }
    }

    var hasFooter =
        applicationStore.data.dealerInfo &&
        applicationStore.data.dealerInfo.legalFooter,
      footerDiv = false;
    if (hasFooter) {
      var footer =
        getAppLanguage() === "fr"
          ? applicationStore.data.dealerInfo.legalFooter.fr
          : applicationStore.data.dealerInfo.legalFooter.en;
      footerDiv = (
        <div className="print-footer">
          <p>{footer}</p>
        </div>
      );
    }

    var appStore = applicationStore.data;
    var dealerInfo = applicationStore.data.dealerSite
      ? Immutable.fromJS(appStore.dealerInfo)
      : Immutable.fromJS(appStore).get("dealerInfo");

    var headerLogo =
      "/static/img/" + applicationStore.data.defaultTemplate + "/logo.png";
    if (applicationStore.data.dealerRetailSiteCustomLogo)
      headerLogo = applicationStore.data.dealerRetailSiteCustomLogo;

    var wishlistClass = Cx({
      "wishlist-items": true,
      "show-msrp": showMsrpColumn() || showSavingsColumn(),
      "hide-msrp": !showMsrpColumn() && !showSavingsColumn(),
    });

    return (
      <div className="print-wishlist">
        <div className="print-header">
          <div className="print-logo">
            <Image className="logo-image" src={headerLogo} />
          </div>
          <div className="print-dealer-info">
            {dealerInfo ? (
              <ul className="dealer-info-list">
                <li className="dealer-info">{dealerInfo.get("name")}</li>
                <li className="dealer-info">
                  {dealerInfo.get("address") + ", " + dealerInfo.get("city")}
                </li>
                <li className="dealer-info">
                  {dealerInfo.get("province") +
                    ", " +
                    dealerInfo.get("postalCode")}
                </li>
                <li className="dealer-info">{dealerInfo.get("phone")}</li>
                <li className="dealer-info">{dealerInfo.get("email")}</li>
              </ul>
            ) : (
              false
            )}
          </div>
        </div>
        <div className="print-body">
          <div className="body-title">
            <h2>
              {this.t("wishlist.title")}{" "}
              {this.props.cart ? (
                <span className="cart-number">
                  ( {"#" + this.props.cart.orderId} )
                </span>
              ) : (
                false
              )}{" "}
              {hasVehicle ? (
                <span className="vehicle-information">
                  <span>{this.t("stocks.availableFor") + " "}</span>
                  <span className="vehicle-title">{vehicleTitle}</span>
                </span>
              ) : (
                false
              )}
            </h2>
            <p className="order-notes">
              {this.props.cart?.orderNotes
                ? getAppLanguage() === "fr"
                  ? this.props.cart.orderNotes.fr
                  : this.props.cart.orderNotes.en
                : false}
            </p>
          </div>
          <table className={wishlistClass}>
            <thead className="list-item">
              <tr
                key="cart-item-head"
                id="cart-item-head"
                className="wishlist-item"
              >
                <th className="table-head item-image">
                  <div className="table-head-title image-title">
                    <h3>{this.t("quoteReport.itemName")}</h3>
                  </div>
                </th>
                {showMsrpColumn() ? (
                  <th className="table-head item-msrp">
                    <div className="msrp-container">
                      <div className="table-head-title msrp-title">
                        {_this.t("wishlist.msrp")}
                      </div>
                    </div>
                  </th>
                ) : (
                  false
                )}
                <th className="table-head item-price">
                  <div className="price-container">
                    <div className="table-head-title price-title">
                      {priceLabel}
                    </div>
                  </div>
                </th>
                <th className="table-head item-quantity">
                  <div className="quantity-container">
                    <div className="table-head-title quantity-title">
                      {_this.t("wishlist.quantity")}
                    </div>
                  </div>
                </th>
                {showSavingsColumn() ? (
                  <th className="table-head item-savings">
                    <div className="savings-container">
                      <div className="table-head-title savings-title">
                        {_this.t("wishlist.totalSavings")}
                      </div>
                    </div>
                  </th>
                ) : (
                  false
                )}
                <th className="table-head item-total">
                  <div className="total-container">
                    <div className="table-head-title total-title">
                      {_this.t("wishlist.total")}
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            {this.props.cart && this.props.cart.orderItemList
              ? this.props.cart.orderItemList.map(function (cartItem, index) {
                  var itemTitle = _this.getItemTitle(cartItem);
                  var itemPartNumber = _this.getItemPartNumber(cartItem);

                  var isTire =
                    getDestinationInverse(cartItem.itemType) ===
                    ProductTypes.TIRES;
                  var isPackage =
                    getDestinationInverse(cartItem.itemType) ===
                    ProductTypes.PACKAGES;
                  var packageHasItems = isPackage
                    ? cartItem.itemPart.partDetails.primaryPart.packageItem
                        .length > 0
                    : false;

                  //<Image className="image" src={cartItem.itemImageLink} />
                  var savings = false;
                  if (parseFloat(cartItem.pricingContextId) > 0) {
                    savings = parseFloat(cartItem.rowSavings);
                    totalPromotionSavings += parseFloat(cartItem.rowSavings);
                  } else if (
                    parseFloat(cartItem.itemListPrice) >
                    parseFloat(cartItem.itemPrice)
                  ) {
                    savings =
                      (parseFloat(cartItem.itemListPrice) -
                        parseFloat(cartItem.itemPrice)) *
                      parseFloat(cartItem.itemQty);
                  }

                  var msrp = cartItem.itemListPrice;

                  var price = "";

                  var pricecontext,
                    pricecontextlist =
                      cartItem.itemPart.partPricing.primaryPart.price[
                        cartItem.itemQty
                      ].contextList;

                  for (var i in pricecontextlist) {
                    if (
                      pricecontextlist[i] &&
                      cartItem.pricingContextId ==
                        pricecontextlist[i].pricingContextId
                    ) {
                      pricecontext = pricecontextlist[i];
                    }
                  }

                  if (!!pricecontext) {
                    if (pricecontext.showUnitPrice) {
                      if (parseInt(cartItem.pricingContextId) > 0) {
                        var originalprice =
                          parseFloat(cartItem.itemPrice) +
                          parseFloat(cartItem.rowSavings) /
                            parseFloat(cartItem.itemQty);
                        price = (
                          <div>
                            <div>
                              <span className="line-through">
                                {money_format(originalprice)}
                              </span>
                            </div>
                            <div>
                              <span>{money_format(cartItem.itemPrice)}</span>
                            </div>
                          </div>
                        );
                      } else {
                        price = money_format(cartItem.itemPrice);
                      }
                    }

                    var promotionalIcon = false;
                    if (isTire) {
                      promotionalIcon = getTirePromotionalImage();
                    }

                    var enableMinimumTag =
                      !isUserLoggedIn() && pricecontext.marketMinimum;
                    var enableMarketMinimumTag =
                      isUserLoggedIn() && parseFloat(pricecontext.oldPrice) > 0;

                    //console.log(cartItem);

                    //{enableMinimumTag ? <div><span>{_this.t('marketMinimum')}</span></div> : false}

                    return (
                      <tbody
                        key={"cart-item-" + index}
                        id={"cart-item-" + index}
                        className="list-item"
                      >
                        <tr className="wishlist-item">
                          <td className="item-image">
                            <div className="item-image-container">
                              <Image
                                className="main-image"
                                src={cartItem.itemImageLink}
                              />
                              {isTire ? (
                                <div className="image-container">
                                  <Image
                                    className="image-brand"
                                    src={_this.getManufacturerLogoUrl(cartItem)}
                                  />
                                </div>
                              ) : (
                                false
                              )}
                            </div>
                            <div className="item-info-container">
                              <div className="title">
                                <h3>
                                  {itemTitle} (
                                  <span className="part-number">
                                    {itemPartNumber}
                                  </span>
                                  )
                                </h3>
                              </div>
                              {isPackage && packageHasItems
                                ? _this.renderPackageItems(cartItem)
                                : false}
                              {isTire ? _this.renderTireInfo(cartItem) : false}
                            </div>
                          </td>
                          {showMsrpColumn() ? (
                            <td className="item-msrp">
                              <div className="msrp-container">
                                <div className="msrp-value">
                                  {showMsrpColumn(cartItem.itemId)
                                    ? money_format(msrp)
                                    : ""}
                                </div>
                              </div>
                            </td>
                          ) : (
                            false
                          )}
                          <td className="item-price">
                            <div className="price-container">
                              <div className="price-value">{price}</div>
                            </div>
                          </td>
                          <td className="item-quantity">
                            <div className="quantity-container">
                              <div className="quantity-value">
                                {cartItem.itemQty}
                              </div>
                              {enableMarketMinimumTag ? (
                                <div>
                                  <span>
                                    {_this.t("belowMarket")}{" "}
                                    {money_format(pricecontext.oldPrice)}
                                  </span>
                                </div>
                              ) : (
                                false
                              )}
                            </div>
                          </td>
                          {showSavingsColumn() ? (
                            <td className="item-savings">
                              <div className="savings-container">
                                <div className="savings-value">
                                  {showSavingsColumn(cartItem.itemId)
                                    ? money_format(savings)
                                    : ""}
                                </div>
                              </div>
                            </td>
                          ) : (
                            false
                          )}
                          <td className="item-total">
                            <div className="total-container">
                              <div className="total-value">
                                {money_format(cartItem.rowPrice)}
                                {enableMinimumTag ? "*" : false}
                                {promotionalIcon}
                              </div>
                            </div>
                          </td>
                        </tr>
                        {cartItem.mandatoryLineItemList
                          ? _this.renderLineItems(
                              cartItem.mandatoryLineItemList,
                              showMsrpColumn(),
                              showSavingsColumn()
                            )
                          : false}
                        {cartItem.includedOptionalLineItemList
                          ? _this.renderLineItems(
                              cartItem.includedOptionalLineItemList,
                              showMsrpColumn(),
                              showSavingsColumn()
                            )
                          : false}
                      </tbody>
                    );
                  } else {
                    return false;
                  }
                })
              : false}
          </table>
          {sizeOf(promotionList) > 0 ? (
            <div className="print-summary-savings">
              <p>
                {textTemplate
                  .replace(
                    "{0}",
                    this.props.cart.orderSavingsTotal
                      ? money_format(this.props.cart.orderSavingsTotal)
                      : money_format(totalPromotionSavings)
                  )
                  .replace(
                    "{1}",
                    sizeOf(promotionList) === 1
                      ? promotionList[0]
                      : sizeOf(promotionList)
                  )}
              </p>
            </div>
          ) : (
            false
          )}
          <table className="wishlist-totals hide-msrp">
            <tbody className="list-item">
              <tr className="wishlist-item even">
                <td className="item-image">
                  <p className="left">
                    {this.t("wishlist.reference")} #
                    {this.props.cart ? this.props.cart.orderId : false} -{" "}
                    {dateString} - {date}
                  </p>
                </td>
                <td className="item-price"></td>
                <td className="item-quantity">
                  <span className="label">
                    {this.t("quote.subtotal") + ":"}
                  </span>
                </td>
                <td className="item-total">
                  <span className="value">
                    {this.props.cart
                      ? money_format(this.props.cart.orderSubtotal)
                      : false}
                  </span>
                </td>
              </tr>
              <tr className="wishlist-item odd">
                <td className="item-image"></td>
                <td className="item-price"></td>
                <td className="item-quantity">
                  <span className="label">
                    {this.t("quote.tax") +
                      " (" +
                      (this.props.cart
                        ? this.props.cart.orderTaxPercent
                        : false) +
                      "%)" +
                      ":"}
                  </span>
                </td>
                <td className="item-total">
                  <span className="value">
                    {this.props.cart
                      ? money_format(this.props.cart.orderTaxTotal)
                      : false}
                  </span>
                </td>
              </tr>
              <tr className="wishlist-item even">
                <td className="item-image"></td>
                <td className="item-price"></td>
                <td className="item-quantity">
                  <span className="label">
                    {this.t("wishlist.total") + ":"}
                  </span>
                </td>
                <td className="item-total">
                  <span className="value">
                    {this.props.cart
                      ? money_format(this.props.cart.orderGrandTotal)
                      : false}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {customerInfoDiv}
        {bannerDiv}
        {footer !== "" ? footerDiv : false}
      </div>
    );
  }

  renderLineItems = (lineItems, showMsrp, showSavings) => {
    var _this = this;
    return lineItems.map(function (cartItem, index) {
      var itemTitle =
        getAppLanguage() === "fr" ? cartItem.title.fr : cartItem.title.en;
      var itemDescription =
        getAppLanguage() === "fr"
          ? cartItem.description.fr
          : cartItem.description.en;
      if (
        itemDescription === "" &&
        !isEmpty(cartItem.lineItemDetails.lineItemNotes)
      )
        itemDescription = cartItem.lineItemDetails.lineItemNotes;
      return (
        <tr
          key={"wishlist-line-item-" + cartItem.lineItemId + "-" + index}
          id={"wishlist-line-item-" + cartItem.itemId + "-" + index}
          className="wishlist-item wishlist-line-item"
        >
          <td className="item-image">
            <div className="title">
              <h3>
                {itemTitle}{" "}
                {itemDescription !== "" ? (
                  <span>({itemDescription})</span>
                ) : (
                  false
                )}
              </h3>
            </div>
          </td>
          {showMsrp ? (
            <td className="item-msrp">
              <div className="msrp-container"></div>
            </td>
          ) : (
            false
          )}
          <td className="item-price">
            <div className="price-container">
              <div className="price-value">
                {cartItem.price !== 0
                  ? money_format(cartItem.price / cartItem.qty)
                  : _this.t("quote.included")}
              </div>
            </div>
          </td>
          <td className="item-quantity">
            <div className="quantity-container">
              <div className="quantity-value">{cartItem.qty}</div>
            </div>
          </td>
          {showSavings ? (
            <td className="item-savings">
              <div className="savings-container"></div>
            </td>
          ) : (
            false
          )}
          <td className="item-total">
            <div className="total-container">
              <div className="total-value">
                {cartItem.price !== 0
                  ? money_format(cartItem.price)
                  : _this.t("quote.included")}
              </div>
            </div>
          </td>
        </tr>
      );
    });
  };

  renderPackageItems = (cartItem) => {
    return (
      <div className="package-items">
        <ul>
          {cartItem.itemPart.partDetails.primaryPart.packageItem.map(function (
            packageItem,
            index
          ) {
            var itemTypeDescription =
              getAppLanguage() === "fr"
                ? packageItem.itemTypeDescription.fr
                : packageItem.itemTypeDescription.en;
            var itemDescription =
              getAppLanguage() === "fr"
                ? packageItem.itemDescription.fr
                : packageItem.itemDescription.en;
            return (
              <li
                key={"package-item-" + cartItem.itemId + "-" + index}
                id={"package-item-" + cartItem.itemId + "-" + index}
              >
                <span>{packageItem.itemQuantity}</span>
                <span> - </span>
                <span>{itemTypeDescription}</span>
                <span> - </span>
                <span>{itemDescription}</span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  renderTireInfo = (cartItem) => {
    var productPartNumberLabel = "",
      partNumber = "";
    if (
      typeof cartItem.itemPart.partDetails.primaryPart
        .tireNationalPartNumber !== "undefined" &&
      cartItem.itemPart.partDetails.primaryPart.tireNationalPartNumber !==
        null &&
      cartItem.itemPart.partDetails.primaryPart.tireNationalPartNumber !== ""
    ) {
      partNumber =
        cartItem.itemPart.partDetails.primaryPart.tireNationalPartNumber;
      productPartNumberLabel = this.t("product.nationalPartNumber");
    } else {
      partNumber =
        cartItem.itemPart.partDetails.primaryPart.tireManufacturerPartNumber;
      productPartNumberLabel = this.t("product.partNumber");
    }
    var dualLoadRating = false;
    if (
      cartItem.itemPart.partDetails.primaryPart.tireDualFitmentLoadRating > 0
    ) {
      dualLoadRating =
        " / " +
        cartItem.itemPart.partDetails.primaryPart.tireDualFitmentLoadRating;
    }
    var plyRating = false;
    if (cartItem.itemPart.partDetails.primaryPart.tirePlyRating) {
      plyRating =
        " / " + cartItem.itemPart.partDetails.primaryPart.tirePlyRating;
    }
    return (
      <div className="tire-info">
        <ul>
          <li>
            <span>{productPartNumberLabel + ": "}</span>
            <span className="feature-value">{partNumber}</span>
          </li>
          <li>
            <span>{this.t("product.tireSize") + ": "}</span>
            <span className="feature-value">
              {cartItem.itemPart.partDetails.primaryPart.tireVehicleClass +
                cartItem.itemPart.partDetails.primaryPart.formattedTiresize}
            </span>
          </li>
          <li className="info-load-speed-ply">
            <span>{this.t("product.loadSpeedPly") + ": "}</span>
            <span> </span>
            <span className="feature-value">
              {cartItem.itemPart.partDetails.primaryPart.tireLoadRating}{" "}
              {dualLoadRating} /{" "}
              {cartItem.itemPart.partDetails.primaryPart.tireSpeedIndex}
              {plyRating}
            </span>
          </li>
        </ul>
      </div>
    );
  };

  getItemPartNumber = (cartItem) => {
    return cartItem.itemPartNumber;
  };

  getManufacturerLogoUrl = (cartItem) => {
    return cartItem.itemPart.partDetails.primaryPart.tireManufacturerImage;
  };

  getItemTitle = (cartItem) => {
    switch (getDestinationInverse(cartItem.itemType)) {
      case ProductTypes.TIRES:
        return cartItem.itemPart.partDetails.primaryPart.tireModelName;
      case ProductTypes.ALLOY_WHEELS:
        return getAppLanguage() == "fr"
          ? cartItem.itemPart.partDetails.primaryPart.wheelName.fr
          : cartItem.itemPart.partDetails.primaryPart.wheelName.en;
      case ProductTypes.ACCESSORIES:
        return getAppLanguage() == "fr"
          ? cartItem.itemPart.partDetails.primaryPart.accessoryName.fr
          : cartItem.itemPart.partDetails.primaryPart.accessoryName.en;
      case ProductTypes.PACKAGES:
        return getAppLanguage() == "fr"
          ? cartItem.itemPart.partDetails.primaryPart.packageName.fr
          : cartItem.itemPart.partDetails.primaryPart.packageName.en;
      default:
        return "";
    }
  };
}

export const PrintWishlist = connect(({ shoppingCart }) => ({
  cart: shoppingCart.cartInformation,
}))(Wishlist);

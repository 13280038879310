import { AbstractStore } from './AbstractStore.js';
import { DispatcherActions } from '../constants/DispatcherActions.js';
import { ProductTypes } from '../constants/ProductTypes.js';
import { RouterStates } from '../constants/RouterStates.js';

import { applicationStore } from './ApplicationStore.js';
import { Router } from './Router.js';

import { sizeOf, checkArrayFieldsExist } from '../service/UtilityService';
import { getDestinationInverse } from '../api/RequestBuilderHelper';
import {  getPricingFromQuantityAndDetailsRefactored,
          getQuantitiesFromPricingDetailsRefactored,
          tireMappingFunction,
          wheelMappingFunction,
          accessoryMappingFunction,
          packageMappingFunction,
          getPricingByQuantity,
          getRebateByQuantity } from '../service/ProductList.js';

var Immutable = require('immutable');

const DEFAULT_SELECTED_QUANTITY_OF_PRODUCT = 3;
const DEFAULT_SELECTED_QUANTITY_OF_PRODUCT_STAGGERED = 2;

const ProductDetailStore = AbstractStore.extend({

  constructor: function() {
    this.data = {
      detail: Immutable.fromJS({}),
      detailForCart: Immutable.fromJS({}),
      productType: null,
      productId: null,
      secondaryProductId: null,
      warrantyList: null
    };

    ProductDetailStore.__super__.constructor.apply(this, arguments);
  },

  getActionHandlers: function() {
    return {
      [DispatcherActions.INITIALIZE_APP_STATE]: this.onInitializeAppState,
      [DispatcherActions.PRODUCT_DETAIL_LOADED]: this.onProductDetailLoaded,
      [DispatcherActions.PRODUCT_LIST_DETAIL_CHANGE_TIRE_QUANTITY]: this.onChangeQuantity,
      [DispatcherActions.CHANGE_STATE_FROM_URL]: this.onChangeUrl,
      [DispatcherActions.PRODUCT_DETAIL_OPENED]: this.onOpenDetail,
      [DispatcherActions.PRODUCT_DETAIL_CHANGED_TIRE_QUANTITY]: this.onChangeCartDetailTireQuantity,
      [DispatcherActions.PRODUCT_DETAIL_CHANGED_WHEEL_QUANTITY]: this.onChangeCartDetailWheelQuantity,
      [DispatcherActions.PRODUCT_DETAIL_CHANGED_QUANTITY]: this.onChangeCartDetailQuantity,
      [DispatcherActions.PRODUCT_DETAIL_CHANGED_REQUIRED_QUANTITY]: this.onChangedRequiredQuantity
    }
  },

  onOpenDetail: function(payload) {
    this.data.productId = payload.id;
    this.data.secondaryProductId = payload.secondaryProductId;
    this.data.productType = payload.productType;

    this.emitChange();
  },

  onChangeUrl: function(payload) {
    if (payload.routerState === RouterStates.PRODUCT_DETAIL) {
      this.data.productId = payload.routerParams[9];
      this.emitChange();
    }
  },

  onInitializeAppState: function(appState) {
    var routerState = Router.decodeRouterState(appState);

    this.loadAppState('productType', appState, routerState.params);
    this.loadAppState('productId', appState, routerState.params);
    this.loadAppState('secondaryProductId', appState, routerState.params);

    this.emitChange();
  },

  loadAppState: function(key, appState, params) {
    var temp;
    if (params[key]) {
      temp = params;
      this.data[key] = temp[key];
    } else if (appState[key]) {
      temp = appState;
      this.data[key] = temp[key];
    }
  },

  onChangeQuantity: function(payload) {
    const path = payload.front ? 'primary' : 'secondary';
    const quantity = this.data.detail.getIn([path, 'quantities']).find(q => q.get('id') === payload.quantity);

    this.data.detail = this.data.detail.setIn([path, 'quantity'], quantity);
    this.data.detail = this.data.detail.setIn([path, 'pricing'], getPricingFromQuantityAndDetailsRefactored(this.data.detail.getIn(['primary', 'pricingDetails']), quantity));

    this.emitChange();
  },

  onProductDetailLoaded: function(response) {
    var type = this.data.productType;

    if (!type) {
      this.data.id = response.partDetails.primaryPart.itemId;
      this.data.productId = response.partDetails.primaryPart.itemId;
      this.data.productType = getDestinationInverse(response.partDetails.primaryPart.itemType);
      type = getDestinationInverse(response.partDetails.primaryPart.itemType);
      if (response.partDetails.secondaryParts) {
        this.data.secondaryProductId = response.partDetails.secondaryParts[0].itemId;
      }
    }

    const immutableList = Immutable.List.of(response);
    const immutableResponse = Immutable.fromJS(response);
    const primaryPartPricing = checkArrayFieldsExist(response, ['partPricing', 'primaryPart']) ? response.partPricing.primaryPart : null;
    const secondaryPartPricing = checkArrayFieldsExist(response, ['partPricing', 'secondaryParts']) ? response.partPricing.secondaryParts[0] : null;

    var detail = null, detailForCart = null, warrantyList = null;
    switch (type) {
      case ProductTypes.TIRES:
        var mappedTire = immutableList.map(tireMappingFunction(false, applicationStore.data.dealerIgnoreTireStock), "", applicationStore.data.setPartQuantityToOne);
        warrantyList = response.partDetails.primaryPart.tireWarrantyList;
        detail = this.mapItem(response, {}, primaryPartPricing, secondaryPartPricing);
        detailForCart = mappedTire.get(0);
      break;
      case ProductTypes.ALLOY_WHEELS:
        var mappedWheel = immutableList.map(wheelMappingFunction(false, applicationStore.data.dealerIgnoreWheelStock, "", applicationStore.data.setPartQuantityToOne));
        //this.data.warrantyList = response.partDetails.primaryPart.wheelWarrantyList;
        detail = this.mapItem(response, this.mapWheelSpecific(immutableResponse), primaryPartPricing, secondaryPartPricing);
        detailForCart = mappedWheel.get(0);
      break;
      case ProductTypes.ACCESSORIES:
        var mappedAccessory = immutableList.map(accessoryMappingFunction(false, applicationStore.data.dealerIgnoreAccessoryStock, applicationStore.data.setPartQuantityToOne));
        //this.data.warrantyList = response.partDetails.primaryPart.accessoryWarrantyList;
        detail = this.mapItem(response, {}, primaryPartPricing);
        detailForCart = mappedAccessory.get(0);
      break;
      case ProductTypes.PACKAGES:
        var mappedPackage = immutableList.map(packageMappingFunction(false, applicationStore.data.dealerIgnorePackageStock, applicationStore.data.setPartQuantityToOne));
        //this.data.warrantyList = response.partDetails.primaryPart.packageWarrantyList;
        detail = this.mapItem(response, {}, primaryPartPricing);
        detailForCart = mappedPackage.get(0);
      break;
      default:
        throw new Error();
    }

    this.data.warrantyList = warrantyList;
    this.data.detail = detail;
    this.data.detailForCart = detailForCart;

    this.emitChange();
  },

  getDefaultQuantity: function(quantities, staggered, productType) {
    if (productType === ProductTypes.TIRES || productType === ProductTypes.ALLOY_WHEELS) {
      const quantityIndex = staggered ? DEFAULT_SELECTED_QUANTITY_OF_PRODUCT_STAGGERED : DEFAULT_SELECTED_QUANTITY_OF_PRODUCT;
      if (quantities.has(quantityIndex)) { 
        return quantities.get(quantityIndex);
      } else {
        return quantities.get(0);
      }
    } else {
      return quantities.get(0);
    }
  },

  getPartInfo: function(pricingDetails, partSummary, partDetails, required, staggered) {
    let productType = getDestinationInverse(partSummary.get('itemType'));
    let quantities = getQuantitiesFromPricingDetailsRefactored(pricingDetails);
    //let quantity = this.getDefaultQuantity(quantities, staggered, productType);
    var quantityIndex = 0;
    switch (productType) {
        case ProductTypes.TIRES:
        case ProductTypes.ALLOY_WHEELS:
            quantityIndex = staggered ? 2 : 4;
            break;
        case ProductTypes.ACCESSORIES:
        case ProductTypes.PACKAGES:
        case ProductTypes.COLLECTIONS:
            quantityIndex = 1;
            break;
        default:
            break;
    }

	  var itemQuantity = 0, quantity = {};
	  quantities.forEach(function (quantity) {
		  if (quantity['value'] == quantityIndex && itemQuantity === 0) itemQuantity = quantity;
	  });
	  if (itemQuantity === 0 || typeof itemQuantity === 'undefined') itemQuantity = quantities[0];
	  quantity = itemQuantity;

    if (required) {
      const quantities = getQuantitiesFromPricingDetailsRefactored(pricingDetails);
      const selectedQuantity = quantities[0];

      return {
        id: partSummary.get('itemId'),
        name: partSummary.get('itemTitle'),
        categories: Immutable.List.of(),
        image: partSummary.get('itemImageLink'),
        partNumber: partSummary.get('itemPartNumber'),
        relatedPartText: partDetails.get('relatedPartText'),
        pricing: pricingDetails,
        price: getPricingFromQuantityAndDetailsRefactored(pricingDetails, selectedQuantity),
        quantities: quantities,
        quantity: quantity,
        type: productType
      };
    } else {
      var categories = [];
      if (partSummary.get('itemType') === 'ACC') {
        partDetails.get('accessoryCategories').forEach(function(categoryGroup) {
          categoryGroup.forEach(function(category) {
            categories.push(category);
          });
        });
      }

      return {
        quantities: quantities,
        quantity: quantity,
        pricingDetails: pricingDetails,
        pricing: getPricingFromQuantityAndDetailsRefactored(pricingDetails, quantity),
        optionLabels: sizeOf(partDetails.get('merchandiseOption') && partDetails.get('merchandiseOption')) > 0 ? partDetails.get('merchandiseOption').getIn([0, 'optionElements']).map(function(metric) {
          return metric.get('elementName');
        }) : [],
        optionValues: partDetails.get('merchandiseOption') ? partDetails.get('merchandiseOption').map(function(option) {
          return Immutable.fromJS({
            id: option.get('optionId'),
            image: option.get('optionImageLink'),
            values: option.get('optionElements').map(function(metric) {
              return metric.get('elementValue')
            })
          });
        }) : [],
        option: sizeOf(partDetails.get('merchandiseOption') && partDetails.get('merchandiseOption')) > 0 ? partDetails.get('merchandiseOption').getIn([0, 'optionId']) : null,
        id: partSummary.get('itemId'),
        manufacturerUrl: partDetails.get('tireManufacturerUrl'),
        title: partSummary.get('itemTitle'),
        description: partSummary.get('itemDescription'),
        image: partSummary.get('itemImageLink'),
        hasPricing: partSummary.get('itemHasDealerPricing'),
        hasStock: partSummary.get('itemHasStock'),
        partNumber: partSummary.get('itemPartNumber'),
        nationalPartNumber: partSummary.get('itemNationalPartNumber'),
        tireSize: partDetails.get('formattedTiresize'),
        speedRating: partDetails.get('tireSpeedIndex'),
        loadRating: partDetails.get('tireLoadRating'),
        wheelDiameter: partDetails.get('wheelDiameter'),
        wheelWidth: partDetails.get('wheelWidth'),
        wheelOffset: partDetails.get('wheelOffset'),
        wheelColour: partDetails.get('wheelColour'),
        wheelAxlePartner: partDetails.get('wheelAxlePartner'),
        wheelComment: partDetails.get('wheelComment'),
        requiresInstallation: partDetails.get('accessoryRequiresInstallation') === '1',
        accessoryCategories: categories,
        productType: productType,
        packageManufacturerName: partDetails.get('packageManufacturerName'),
        packageItems: partDetails.get('packageItem') ? partDetails.get('packageItem').map(function(packageItem) {
          return Immutable.Map({
            id: packageItem.get('itemId'),
            partType: packageItem.get('itemType'),
            partNumber: packageItem.get('itemPartNumber'),
            quantity: packageItem.get('itemQuantity'),
            title: packageItem.get('itemTitle'),
            description: packageItem.get('itemDescription'),
            itemLineItem: packageItem.get('itemPackageLineItem').map(function(lineItem) {
              return Immutable.Map({
                lineItemId: lineItem.get('lineItemId'),
                title: lineItem.get('title'),
                description: lineItem.get('description'),
                quantity: lineItem.get('qty'),
                matchItemQty: lineItem.get('matchItemQty'),
                unitPrice: lineItem.get('unitPrice'),
                totalPrice: lineItem.get('totalPrice')
              });
            })
          })}) : Immutable.List.of()
      };
    }
  },

  mapItem: function(response, productSpecific, primaryPartPricing, secondaryPartPricing, relatedPartPricing) {
    var immutableResponse = Immutable.fromJS(response);
    const productType = getDestinationInverse(immutableResponse.getIn(['partSummary', 'primaryPart', 'itemType']));
    const staggered = sizeOf(immutableResponse.getIn(['partSummary', 'secondaryParts'])) > 0;

    let description;
    if (productType === ProductTypes.TIRES) {
      description = immutableResponse.getIn(['partDetails', 'primaryPart', 'tireModelDescription']);
    } else {
      description = immutableResponse.getIn(['partSummary', 'primaryPart', 'itemDescription']);
    }

	  var hasPromotion = false;
	  if (checkArrayFieldsExist(response, ['partPricing', 'primaryPart', 'price'])) {
		  var price = response['partPricing']['primaryPart']['price'];
		  for (var i in price) if (sizeOf(price[i]['contextList']) > 1 || price[i]['contextList'][0]['pricingContextId'] > 0) hasPromotion = true;
	  }

    var priceFront = primaryPartPricing;
    var priceRear = secondaryPartPricing;

    return Immutable.fromJS({
      title: immutableResponse.getIn(['partSummary', 'primaryPart', 'itemTitle']),
      image: immutableResponse.getIn(['partSummary', 'primaryPart', 'itemImageLink']),
      description: description,
      primary: this.getPartInfo(priceFront, immutableResponse.getIn(['partSummary', 'primaryPart']), immutableResponse.getIn(['partDetails', 'primaryPart']), false, staggered),
      secondary: staggered ? this.getPartInfo(priceRear, immutableResponse.getIn(['partSummary', 'secondaryParts']).get(0), immutableResponse.getIn(['partDetails', 'secondaryParts']).get(0), false, staggered) : null,
      required: relatedPartPricing ? Immutable.Range(0, sizeOf(immutableResponse.getIn(['partSummary', 'relatedParts']))).toList().map(function(i) {
        var priceRelated = relatedPartPricing[i];
        return Immutable.fromJS(this.getPartInfo(
	        priceRelated,
          immutableResponse.getIn(['partSummary', 'relatedParts']).get(i),
          immutableResponse.getIn(['partDetails', 'relatedParts']).get(i),
          true
        ));
      }) : Immutable.List([]),
      related: Immutable.List.of(),
      staggered: staggered,
      productSpecific: productSpecific,
	    hasPromotion: hasPromotion
    });
  },

  onChangedRequiredQuantity: function(payload) {
    this.data.detail = this.data.detail.setIn(['required', payload.productIndex, 'quantity'], payload.quantity);
    this.data.detail = this.data.detail.setIn(['required', payload.productIndex, 'price'], getPricingFromQuantityAndDetailsRefactored(this.data.detail.getIn(['required', payload.productIndex, 'pricing']), payload.quantity));

    this.emitChange();
  },

  onChangeCartDetailQuantity: function (payload) {
    var detailForCart = this.data.detailForCart;

    detailForCart = detailForCart.set('quantity', payload.quantity);
    detailForCart = detailForCart.set('pricing', Immutable.fromJS(getPricingByQuantity(detailForCart.get('pricingDetails'), payload.quantity)));
    detailForCart = detailForCart.set('rebate', Immutable.fromJS(getRebateByQuantity(detailForCart.get('pricingDetails'), payload.quantity)));

    this.data.detailForCart = detailForCart;

    this.emitChange();
  },

  onChangeCartDetailTireQuantity: function (payload) {
    var detailForCart = this.data.detailForCart, filteredTireQuantity;
    if (payload.front) {
      filteredTireQuantity = detailForCart.get('frontTire').set('quantity', payload.quantity);
      filteredTireQuantity = filteredTireQuantity.set('pricing', Immutable.fromJS(getPricingByQuantity(detailForCart.get('frontTire').get('pricingDetails'), payload.quantity)));
      filteredTireQuantity = filteredTireQuantity.set('rebate', Immutable.fromJS(getRebateByQuantity(detailForCart.get('frontTire').get('pricingDetails'), payload.quantity)));
    } else {
      filteredTireQuantity = detailForCart.get('rearTire').set('quantity', payload.quantity);
      filteredTireQuantity = filteredTireQuantity.set('pricing', Immutable.fromJS(getPricingByQuantity(detailForCart.get('rearTire').get('pricingDetails'), payload.quantity)));
      filteredTireQuantity = filteredTireQuantity.set('rebate', Immutable.fromJS(getRebateByQuantity(detailForCart.get('rearTire').get('pricingDetails'), payload.quantity)));
    }

    if (payload.front) detailForCart = detailForCart.set('frontTire', filteredTireQuantity);
    else detailForCart = detailForCart.set('rearTire', filteredTireQuantity);

    this.data.detailForCart = detailForCart;

    this.emitChange();
  },

  onChangeCartDetailWheelQuantity: function (payload) {
    var detailForCart = this.data.detailForCart;

    var filteredWheelQuantity;
    if (payload.front) {
      filteredWheelQuantity = detailForCart.get('frontWheel').set('quantity', payload.quantity);
      filteredWheelQuantity = filteredWheelQuantity.set('pricing', Immutable.fromJS(getPricingByQuantity(detailForCart.get('frontWheel').get('pricingDetails'), payload.quantity)));
      filteredWheelQuantity = filteredWheelQuantity.set('rebate', Immutable.fromJS(getRebateByQuantity(detailForCart.get('frontWheel').get('pricingDetails'), payload.quantity)));
    } else {
      filteredWheelQuantity = detailForCart.get('rearWheel').set('quantity', payload.quantity);
      filteredWheelQuantity = filteredWheelQuantity.set('pricing', Immutable.fromJS(getPricingByQuantity(detailForCart.get('rearWheel').get('pricingDetails'), payload.quantity)));
      filteredWheelQuantity = filteredWheelQuantity.set('rebate', Immutable.fromJS(getRebateByQuantity(detailForCart.get('rearWheel').get('pricingDetails'), payload.quantity)));
    }

    if (payload.front) detailForCart = detailForCart.set('frontWheel', filteredWheelQuantity);
    else detailForCart = detailForCart.set('rearWheel', filteredWheelQuantity);

    this.data.detailForCart = detailForCart;

    this.emitChange();
  },

  mapWheelSpecific: function(immutableResponse) {
    return {
      vehicleImages: immutableResponse.getIn(['partDetails', 'primaryPart', 'wheelVehicleImages']),
      selectedVehicleImage: Object.keys(immutableResponse.getIn(['partDetails', 'primaryPart', 'wheelVehicleImages']).toJS())[0]
    };
  }

});

export const productDetailStore = new ProductDetailStore();
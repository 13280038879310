module.exports = {
  'homepageTitleText': 'Votre concessionnaire Volkswagen est le meilleur endroit pour acheter des pneus',
  'homepageText': 'Trouvez des pneus parfaits pour votre véhicule. Vous n’avez qu’à nous dire votre modèle de Volkswagen et nous vous recommanderons des choix qui assurent la meilleure performance, une sécurité accrue et une économie de carburant optimale. En plus d’être facile et pratique, l’achat de pneus neufs et de roues chez votre concessionnaire Volkswagen vous permet de bénéficier d’une pose par des techniciens certifiés Volkswagen.',
  onTheRoad: 'Sur la route',
  withCompleteWheels: 'avec roues complètes',
  forTires: 'de Pneus',
  needNewWheels: 'Besoin de nouvelles roues? Du dimensionnement aux marques saisonnières les plus vendues, votre concessionnaire Volkswagen peut vous aider à déterminer le meilleur ajustement pour votre modèle spécifique.',
  youreTiresAre: 'Vos pneus sont votre seul contact avec la route. Découvrez ici les informations, les produits et services Volkswagen concernant vos roues et vos pneus.',
  rememberToBuy: 'N\'oubliez pas d\'acheter des roues lorsque vous achetez vos pneus. Volkswagen propose une large sélection de jantes en alliage et en acier pour accompagner vos nouveaux pneus, ce qui facilite leur installation et leur désinstallation, ainsi que leur entreposage chaque saison.',
  'common': {
		'featuredItems': "Articles populaires"
	},
	'productTypes': {
		'tireSelectText': 'Trouvez des pneus hiver, toute saison et été pour votre véhicule pour les meilleures marques comme: Michelin, Goodyear, Firestone, Continental, Pirelli et plus.',
		'wheelSelectText': 'Retrouvez les roues originales volkswagen pour votre véhicule et voyez comment elles ressemblent sur votre véhicule.',
		'accessorySelectText': '',
		'packageSelectText': 'Trouver des pneus et des roues montées pour votre solution clé en main pour vos besoins de pneus d\'hiver.'
	},
	'search': {
		'placeholderSearchTireSize': 'recherche par taille de pneu: ex. 195/65R15'
	},
	'launcher': {
		'enterTiresize': 'recherche par taille de pneu: ex. 195/65R15'
	}
};
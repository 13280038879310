import { DispatcherActions } from '../constants/DispatcherActions.js';
import { applicationStore } from '../stores/ApplicationStore.js';
import { productListStore } from '../stores/ProductListStore.js';
import { vehicleSelectionStore } from '../stores/VehicleSelectionStore.js';
import { shoppingCartActions } from "./ShoppingCartActions";
import { AbstractAction } from './AbstractAction.js';

import { getDestination } from '../api/RequestBuilderHelper.js';
import { sizeOf, isEmpty, getAppLanguage, getAppDealerId, getTrackingEventCategory } from '../service/UtilityService';

import { ProductTypes } from '../constants/ProductTypes.js';
import { breadcrumbsStore } from "../stores/BreadcrumbsStore";
import { getFilteredTiresizeList, getSelectedTiresizeDiameter } from "../service/VehicleService";
import { runAdobeServiceFormSubmit } from "../service/AdobeLaunchService";
import store from "../stores/configureStore";
import {
  loadVehicleInfo,
  onComparisonLoadedSuccessfully,
  onListAccessoriesLoaded,
  onListPackagesLoaded,
  onListTiresLoaded,
  onListWheelsLoaded, productListLoading
} from "../redux/features/productList";

var _ = require('lodash');

const SHOW_OE_TIRES_ONLY = "show-only-oe";
const SHOW_ALL_TIRES = "show-all";

const CONTACT_EMAIL = 'email';
const CONTACT_PHONE = 'phone';

const failover = {
  SEASON: "SEASON",
  TIRESIZE: "TIRESIZE"
};

var ProductListActions = AbstractAction.extend({

  constructor: function() {
    this.loadTiresDebounced = _.debounce(function() { this.loadTires(); }.bind(this), 1000);
    this.loadAccessoriesDebounced = _.debounce(function() { this.loadAccessories(); }.bind(this), 1000);
  },

  runGoogleAnalytics: function(label) {
    setTimeout(() => {

      googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'open search result', label);

    }, 100);
  },

  loadGoogleAnalytics: function(type) {
    var list = breadcrumbsStore.getBreadcrumbs(), temp = '';

    for (var i in list) {
      if (list[i] && !isEmpty(list[i].value)) {

        if (typeof(list[i].value) == 'string') {
          temp += list[i].value + ' ';
        } else {
          temp += list[i].value.en + ' ';
        }

      }
    }

    list = getFilteredTiresizeList();

    if (!isEmpty(list) && productListStore.data.tireSize) {

      for (var i in list) {

        if (list[i] && list[i].key == productListStore.data.tireSize) {
          temp += list[i].value.substr(0, list[i].value.indexOf(' / '));
        }

      }

    }

    var label = type + ' - ' + '{' + temp.trim() + '}';

    this.runGoogleAnalytics(label);
  },

  loadAccessories: function() {
    let VehicleId = store.getState().productList.vehicleId;
    this.apiGet('/parts/accessory/searchAccessoriesByVehicle.php', {
      dealerId: getAppDealerId(),
      vehicleId: VehicleId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      store.dispatch(onListAccessoriesLoaded(response));
      // this.dispatch(DispatcherActions.PRODUCT_LIST_ACCESSORIES_LOADED, response);
      this.loadGoogleAnalytics('accessory vehicle');
    }.bind(this));
  },

  loadWheels: function(addtoquote = false, quantity = 0) {
    let VehicleId = store.getState().productList.vehicleId;
    this.apiGet('/parts/wheel/searchWheelsByVehicle.php', {
      dealerId: getAppDealerId(),
      vehicleId: VehicleId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {

      if (addtoquote) {

        var wheel = null, itemId, contextId, contextList;
        if (!isEmpty(response)) {
          for (var i in response) {
            if (response[i] && (parseInt(response[i].partDetails.primaryPart.wheelDiameter) == getSelectedTiresizeDiameter())) {
              wheel = response[i];

              itemId = wheel.partSummary.primaryPart.itemId;
              contextList = wheel.partPricing.primaryPart.price[quantity].contextList;

              for (var j in contextList) {
                if (contextList[j]) {
                  contextId = contextList[j].pricingContextId;
                }
              }

              break;
            }
          }
        }

        if (!isEmpty(wheel)) {
          setTimeout(() => { shoppingCartActions.addItemToCart(itemId, getDestination(ProductTypes.ALLOY_WHEELS), quantity, contextId, false, false); }, 2500);
        }

      } else {
        store.dispatch(onListWheelsLoaded(response));
        // this.dispatch(DispatcherActions.PRODUCT_LIST_WHEELS_LOADED, response);
        this.loadGoogleAnalytics('wheel vehicle');
      }

    }.bind(this)).catch(function(error) {
      console.error(error);
    });
  },

  loadPackages: function() {
    let VehicleId = store.getState().productList.vehicleId;
    this.apiGet('/parts/partPackage/searchPartPackagesByVehicle.php', {
      dealerId: getAppDealerId(),
      vehicleId: VehicleId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      store.dispatch(onListPackagesLoaded(response));
      this.dispatch(DispatcherActions.PRODUCT_LIST_PACKAGES_LOADED, response);
      this.loadGoogleAnalytics('package vehicle');
    }.bind(this));
  },
  // locked 
  // loadPackagesByYear: function(year) {
  //   this.apiGet('/parts/partPackage/searchPartPackagesByYearId.php', {
  //     nationalAccountId: applicationStore.data.nationalAccountId,
  //     dealerId: getAppDealerId(),
  //     yearId: year,
  //     isNational: applicationStore.data.nationalSite ? 1 : 0
  //   }).then(function(response) {
  //     this.dispatch(DispatcherActions.PRODUCT_LIST_PACKAGES_LOADED, response);
  //     this.loadGoogleAnalytics('package vehicle');
  //   }.bind(this));
  // },

  loadTires: function(source = failover.TIRESIZE) {
    source = store.getState().productList.source || source;
    let season = store.getState().productList.selectedSeason;
    let vehicleSubModel = store.getState().productList.subModelTireId;
    let VehicleId = store.getState().productList.vehicleId;
    let filterByTireSize = store.getState().productList.filterByTireSize;
    let tireSize = store.getState().productList.tireSize;
    this.apiGet('/parts/tire/searchTiresByVehicle.php', {
      dealerId: getAppDealerId(),
      vehicleId: VehicleId,
      vehicleSubmodelTireSizeId: filterByTireSize ? filterByTireSize : vehicleSubModel,
      season: season,
      tireManufacturerIds: _.isEmpty(productListStore.data.brands) ? undefined : productListStore.data.brands,
      originalEquipmentFilter: productListStore.data.originalEquipmentOnly == 1 ? "OE" : "ALL",
      searchFailoverMethod: source,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      store.dispatch(onListTiresLoaded(response));
      // loadGoogleAnalytics('tire vehicle');
    }).catch(function(error) {
      console.error(error);
    });
  },

  setLoadTiresFromModel: function() {
    this.dispatch(DispatcherActions.PRODUCT_LIST_TIRES_LOAD_FROM_MODEL, {});
  },

  loadTiresFromModel: function(source = failover.SEASON) {
    this.apiGet('/parts/tire/searchTiresByVehicleModel.php', {
      dealerId: getAppDealerId(),
      vehicleYearId: vehicleSelectionStore.data.year,
      vehicleModelId: vehicleSelectionStore.data.model,
      originalEquipmentFilter: productListStore.data.originalEquipmentOnly ? "OE" : "ALL",
      season: productListStore.data.season,
      vehicleSubmodelTireSizeId: productListStore.data.tireSize,
      searchFailoverMethod: source,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      this.dispatch(DispatcherActions.PRODUCT_LIST_TIRES_LOADED_FROM_MODEL, response);
      store.dispatch(onListTiresLoaded(response));
      this.loadGoogleAnalytics('tire vehicle');
    }.bind(this));
  },

  setLoadTireFromChassis: function() {
    this.dispatch(DispatcherActions.PRODUCT_LIST_TIRES_LOAD_FROM_CHASSIS, {});
  },

  loadTiresFromChassis: function(source = failover.SEASON) {
    let season = store.getState().productList.selectedSeason;
    let vehicleSubModel = store.getState().productList.subModelTireId;
    let filterByTireSize = store.getState().productList.filterByTireSize;
    this.apiGet('/parts/tire/searchTiresByVehicleChassis.php', {
      dealerId: getAppDealerId(),
      vehicleChassis: productListStore.data.chassis,
      originalEquipmentFilter: productListStore.data.originalEquipmentOnly ? "OE" : "ALL",
      season:season,
      vehicleSubmodelTireSizeId: filterByTireSize ? filterByTireSize : vehicleSubModel,
      searchFailoverMethod: source,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      store.dispatch(onListTiresLoaded(response));
      this.dispatch(DispatcherActions.PRODUCT_LIST_TIRES_LOADED_FROM_CHASSIS, response);
      this.loadGoogleAnalytics('tire vehicle');
    }.bind(this));
  },

  setLoadPackagesFromModel: function() {
    this.dispatch(DispatcherActions.PRODUCT_LIST_PACKAGES_LOAD_FROM_MODEL, {});
  },

  loadPackagesFromModel: function() {
    this.apiGet('/parts/partPackage/searchPartPackagesByVehicleModel.php', {
      dealerId: getAppDealerId(),
      vehicleYearId: vehicleSelectionStore.data.year,
      vehicleModelId: vehicleSelectionStore.data.model,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      store.dispatch(onListPackagesLoaded(response));

      this.dispatch(DispatcherActions.PRODUCT_LIST_PACKAGES_LOADED_FROM_MODEL, response);
      this.loadGoogleAnalytics('package vehicle');
    }.bind(this));
  },

  // loadBrandModelList: function(token) {
  //   this.apiGet('/parts/tireModel/searchTireModelsByManufacturer.php', {
  //     dealerId: getAppDealerId(),
  //     brandId: applicationStore.data.selectedBrandId,
  //     isNational: applicationStore.data.nationalSite ? 1 : 0
  //   }).then(function(response) {
  //     this.dispatch(DispatcherActions.TIRE_BRAND_DATA_LOADED, response);
  //   }.bind(this));
  // },

  // loadBrandModelListBySeason: function(token) {
  //   this.apiGet('/parts/tireModel/searchTireModelsBySeason.php', {
  //     dealerId: getAppDealerId(),
  //     season: applicationStore.data.selectedSeason,
  //     isNational: applicationStore.data.nationalSite ? 1 : 0
  //   }).then(function(response) {
  //     this.dispatch(DispatcherActions.TIRE_BRAND_DATA_LOADED, response);
  //   }.bind(this));
  // },

  // loadTireModelPartList: function(token) {
  //   this.apiGet('/parts/tire/searchTiresByTireModel.php', {
  //     dealerId: getAppDealerId(),
  //     brandId: applicationStore.data.selectedBrandId,
  //     modelId: applicationStore.data.selectedModelId,
  //     isNational: applicationStore.data.nationalSite ? 1 : 0
  //   }).then(function(response) {
  //     this.dispatch(DispatcherActions.TIRE_MODEL_PART_DATA_LOADED, response);
  //   }.bind(this));
  // },

  onLoadProductComparsionFromTireList: function(dealerId, vehicleId, tireSize) {
    this.apiGet('/parts/tire/searchTiresByVehicle.php', {
      dealerId: dealerId,
      vehicleId: vehicleId,
      vehicleSubmodelTireSizeId: tireSize,
      searchFailoverMethod: failover.TIRESIZE,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      if (response) {
        setTimeout(() => {
          var partIdList = [];
          if (sizeOf(response.featuredPartList) > 0) {
            var featuredList = response.featuredPartList;
            for (var i in featuredList) {
              if (featuredList[i].part.partDetails.primaryPart.itemId > 0 && sizeOf(partIdList) < 3) {
                partIdList.push(featuredList[i].part.partDetails.primaryPart.itemId);
              }
              if (sizeOf(partIdList) >= 3) {
                break;
              }
            }
          } else if (sizeOf(response.partList) > 0) {
            var partList = response.partList;
            for (var i in partList) {
              if (partList[i].partDetails.primaryPart.itemId > 0 && sizeOf(partIdList) < 3) {
                partIdList.push(partList[i].partDetails.primaryPart.itemId);
              }
              if (sizeOf(partIdList) >= 3) {
                break;
              }
            }
          }
          var firstPartId = partIdList && partIdList.length >= 1 ? partIdList[0] : "0";
          var secondPartId = partIdList && partIdList.length >= 2 ? partIdList[1] : "0";
          var thirdPartId = partIdList && partIdList.length >= 3 ? partIdList[2] : "0";
          this.onGetProductComparison(ProductTypes.TIRES, vehicleId, firstPartId, secondPartId, thirdPartId);
        }, 100);
      }
    }.bind(this));
  },

  onRunPdfComparison: function(productType, vehicleId, comparisonIdList, callback) {
    this.apiPost('/parts/comparison/buildComparison.php', {
      dealerId: getAppDealerId(),
      partType: getDestination(productType),
      partId: comparisonIdList,
      vehicleId: vehicleId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      callback(response);
    }.bind(this));
  },

  onGetProductComparison: function(productType, vehicleId, firstPartId=0, secondPartId=0, thirdPartId=0) {
    this.apiPost('/parts/comparison/buildComparison.php', {
      dealerId: getAppDealerId(),
      partType: getDestination(productType),
      partId: [firstPartId, secondPartId, thirdPartId],
      vehicleId: vehicleId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      if(response){
        store.dispatch( onComparisonLoadedSuccessfully(true))
      }
      this.dispatch(DispatcherActions.PRODUCT_COMPARISON_FETCH, response);
    }.bind(this));
  },

  onEmailComparison: function(productType, vehicleId, firstPartId, secondPartId, thirdPartId, first = '', last = '', email = '', phone = '', notes = '') {
    this.apiPost('/parts/comparison/emailComparison.php', {
      dealerId: getAppDealerId(),
      partType: getDestination(productType),
      partId: [firstPartId, secondPartId, thirdPartId],
      vehicleId: vehicleId,
      customerCurrentLanguage: getAppLanguage(),
      customerFirstName: first,
      customerLastName: last,
      customerEmail: email,
      customerPhone: phone,
      customerNotes: notes,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      this.dispatch(DispatcherActions.PRODUCT_COMPARISON_EMAIL, response);
    }.bind(this));
  },

  // onEmailPromotion: function(itemType, itemId, pricingContextId, customerEmail) {
  //   this.apiPost('/shopping/lead/createPromotionLead.php', {
  //     targetDealerId: getAppDealerId(),
  //     partType: getDestination(itemType),
  //     partId: itemId,
  //     promotionId: pricingContextId,
  //     vehicleId: vehicleSelectionStore.data.vehicleId,
  //     customerFirstName: "",
  //     customerLastName: "",
  //     customerEmail: customerEmail,
  //     customerLanguage: getAppLanguage(),
  //     isNational: applicationStore.data.nationalSite ? 1 : 0
  //   }).then(function(response) {
  //     if (response && !!response.success) {
  //       this.dispatch(DispatcherActions.EMAIL_PROMOTION_SUCCEEDED, {});
  //     } else {
  //       this.dispatch(DispatcherActions.EMAIL_PROMOTION_FAILED, {});
  //     }
  //   }.bind(this));
  // },

  sendAppointmentForm: function(first, last, email, phone, contactType, message, itemId, itemType, dealerId = null, runformsubmit = '') {
    this.apiPost('/shopping/lead/createItemContactLead.php', {
      targetDealerId: dealerId ? dealerId : getAppDealerId(),
      vehicleId: vehicleSelectionStore.data.vehicleId,
      customerFirstName: first,
      customerLastName: last,
      customerEmail: email,
      customerPhone: phone,
      customerContactMethodPreference: contactType === CONTACT_EMAIL ? 0 : 1,
      customerMessage: message,
      partId: itemId,
      partType: getDestination(itemType),
      customerLanguage: getAppLanguage(),
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {

      if (response.success) {
        runAdobeServiceFormSubmit(runformsubmit);
        this.dispatch(DispatcherActions.APPOINTMENT_FORM_SENT, {});
      }

    }.bind(this));
  },

  // onShowProductListWarning: function() {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_WARNING_SHOW, {});
  // },

  // onHideProductListWarning: function() {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_WARNING_HIDE, {});
  // },

  // loadProductDetailModalPart: function() {
  //   this.dispatch(DispatcherActions.PRODUCT_DETAIL_MODAL_LOAD_PART, {});
  // },

  // changeRequiredItemQuantity: function (productIndex, quantity) {
  //   this.dispatch(DispatcherActions.PRODUCT_DETAIL_CHANGE_REQUIRED_QUANTITY, { productIndex: productIndex, quantity: quantity });
  // },

  // changeQuantityOfTires: function(tireKey, rearTireKey, quantity, front, featured, staggered) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_CHANGE_TIRE_QUANTITY, {
  //     tireKey: tireKey,
	//     rearTireKey: rearTireKey,
  //     quantity: quantity,
  //     front: front,
  //     featured: featured,
	//     staggered: staggered
  //   });
  // },

  // changeQuantityOfSelectedTire: function(tireKey, quantity) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_CHANGE_SELECTED_TIRE_QUANTITY, {
  //     tireKey: tireKey,
  //     quantity: quantity
  //   });
  // },

  // changeQuantityOfWheels: function(wheelIndex, quantity, front) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_CHANGE_WHEEL_QUANTITY, {
  //     wheelIndex: wheelIndex,
  //     quantity: quantity,
  //     front: front
  //   });
  // },

  // changeQuantityOfAccessory: function(accessoryId, quantity) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_CHANGE_ACCESSORY_QUANTITY, {
  //     accessoryId: accessoryId,
  //     quantity: quantity
  //   });
  // },

  // changeQuantityOfCollections: function(collectionsIndex, quantity) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_CHANGE_COLLECTIONS_QUANTITY, {
  //     collectionsIndex: collectionsIndex,
  //     quantity: quantity
  //   });
  // },

  // changeQuantityOfPackages: function(packageIndex, quantity) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_CHANGE_PACKAGE_QUANTITY, {
  //     packageIndex: packageIndex,
  //     quantity: quantity
  //   });
  // },

  setOeFilterValue: function (oeFilterValue) {
    this.dispatch(DispatcherActions.SET_OE_FILTER_VALUE, oeFilterValue);
  },

	// setRunFlatFilterValue: function (runFlatFilterValue) {
	// 	this.dispatch(DispatcherActions.SET_RUN_FLAT_FILTER_VALUE, runFlatFilterValue);
	// },

  // openDetail: function(index, type) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_OPEN_DETAIL, {
  //     index: index,
  //     type: type
  //   });
  // },
   // This action is not in use
  // openDetailFromCart: function(cartIndex) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_OPEN_DETAIL_FROM_CART, cartIndex);
  // },

  // closeDetail: function() {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_CLOSE_DETAIL, {});
  // },

  // nextProductDetail: function() {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_NEXT_PRODUCT, {});
  // },

  // prevProductDetail: function() {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_PREV_PRODUCT, {});
  // },

  // changeDetailQuantity: function(quantity, front) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_DETAIL_CHANGE_TIRE_QUANTITY, {
  //     quantity: quantity,
  //     front: front
  //   });
  // },

  // filterByBrands: function(brands) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_BRANDS_SELECTED, brands);
  // },

  // filterByPricing: function(pricing) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_PRICING_SELECTED, pricing);
  // },

  // filterPricingToggleAll: function(toggleAll) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_PRICING_TOGGLE_ALL, toggleAll);
  // },

  // filterPackagePricingToggleAll: function(toggleAll) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_PACKAGE_PRICING_TOGGLE_ALL, toggleAll);
  // },

  // filterBySeason: function(season) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_SEASON_SELECTED, season);
  // },

	// filterByTireSize: function(tireSize) {
	// 	this.dispatch(DispatcherActions.PRODUCT_LIST_TIRE_SIZE_SELECTED, tireSize);
	// },

  // filterByLoadRating: function(loadRating) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_LOAD_RATING_SELECTED, loadRating);
  // },

  // filterBySecondaryLoadRating: function(loadRating) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_LOAD_SECONDARY_LOAD_RATING_SELECTED, loadRating);
  // },

  // filterBySecondarySpeedIndex: function(speedIndex) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_LOAD_SECONDARY_SPEED_INDEX_SELECTED, speedIndex);
  // },

  // setTiresize: function(tiresizeId) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_TIRE_SIZE_ID_SELECTED, tiresizeId);
  // },

  // filterBySpeedIndex: function(speedIndex) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_SPEED_INDEX_SELECTED, speedIndex);
  // },

  // filterByPlyRating: function(plyRating) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_PLY_RATING_SELECTED, plyRating);
  // },

  // filterByTag: function(tag) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_TAG_SELECTED, tag);
  // },

  // filterWheelByTireSize: function(tiresize) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_WHEEL_TIRE_SIZE_SELECTED, tiresize);
  // },
  // NOT IN FUNCTION
  // filterWheelBySeason: function(season) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_WHEEL_SEASON_SELECTED, season);
  // },

  // filterByAccessoriesCategories: function(accessoryCategories) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_ACCESSORY_CATEGORIES_SELECTED, accessoryCategories);
  // },
//  Return this function 
  // showOrHideBrandFilter: function() {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_SHOW_OR_HIDE_BRAND_FILTER, {});
  // },

  // showOrHidePlyFilter: function() {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_SHOW_OR_HIDE_PLY_FILTER, {});
  // },

  // showOrHidePricingFilter: function() {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_SHOW_OR_HIDE_PRICING_FILTER, {});
  // },

  // changeColour: function(code) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_CHANGE_COLOUR, code);
  // },

  // changeColourInDetail: function(code) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_CHANGE_COLOUR_IN_DETAIL, code);
  // },

  // stockRefreshed: function(payload) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_STOCK_REFRESHED, payload);
  // },

  // requestItemStockRefresh: function(frontTireId, rearTireId) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_ITEM_STOCK_REFRESH, { frontTireId: frontTireId, rearTireId: rearTireId });
  // },

  // requestStockRefresh: function(url, stockLocationKey, frontTireId, rearTireId) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_REQUEST_STOCK_REFRESH, {
  //     stockLocationKey: stockLocationKey,
  //     frontTireId: frontTireId,
  //     rearTireId: rearTireId,
  //     url: url
  //   });
  // },

  // switchAccessoryView: function(view) {
  //   this.dispatch(DispatcherActions.SWITCH_ACCESSORY_VIEW, view);
  // },

  // noProductListResultsFound: function() {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_NO_RESULTS_FOR_FILTER, {});
  // },

  // closeTireNotOriginalSizeWarning: function() {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_CLOSE_TIRE_NOT_ORIGINAL_SIZE_WARNING, {});
  // },

  // closeWheelNotOriginalSizeWarning: function() {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_CLOSE_WHEEL_NOT_ORIGINAL_SIZE_WARNING, {});
  // },

  // resetWheelVisualizerSelectedWheel: function() {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_WHEEL_VISUALIZER_RESET_SELECTED, {});
  // },
  // FUNCTION RETURN
  // setPartForVisualizer: function (part) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_VISUALIZER_SET_PART, part);
  // },

  // loadSeasonFilter: function(filters) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_LOAD_SEASON_FILTER, filters);
  // },

  // loadTireBrandFilter: function(filters) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_LOAD_TIRE_BRAND_FILTER, filters);
  // },

  // loadAccessoryCategoryFilter: function(filters) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_LOAD_ACCESSORY_CATEGORY_FILTER, filters);
  // },

  // updateListOfTires: function(isFeatured) {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_UPDATE_TIRE_LIST, isFeatured);
  // },

  // onSetDefaultSeason: function(season) {
  //   this.dispatch(DispatcherActions.SET_DEFAULT_SEASON, season);
  // },

  setChassisSearchValue: function(chassisSearch) {
    this.dispatch(DispatcherActions.PRODUCT_LIST_SET_CHASSIS_SEARCH_VALUE, chassisSearch);
  },
  // this is not in production 
  // tireListIncrementPageIndex: function() {
  //   this.dispatch(DispatcherActions.PRODUCT_LIST_TIRE_LIST_INCREMENT_PAGE_INDEX, {});
  // },

  setProductListSearchType: function(searchType) {
    this.dispatch(DispatcherActions.PRODUCT_LIST_SET_SEARCH_TYPE, searchType);
  },

  setHideProductList: function() {
    this.dispatch(DispatcherActions.PRODUCT_LIST_HIDE, {});
  },

  setShowProductList: function() {
    this.dispatch(DispatcherActions.PRODUCT_LIST_SHOW, {});
  },

  // onOpenEmailPromotionModal: function(itemType, itemId, pricingContextId, url, disclaimer) {
  //   this.dispatch(DispatcherActions.EMAIL_PROMOTION_OPEN_MODAL, { itemType: itemType, itemId: itemId, pricingContextId: pricingContextId, url: url, disclaimer: disclaimer });
  // },

  // onCloseEmailPromotionModal: function() {
  //   this.dispatch(DispatcherActions.EMAIL_PROMOTION_CLOSE_MODAL, {});
  // },

	// onAddProductToComparison: function (partObject) {
	// 	this.dispatch(DispatcherActions.PRODUCT_COMPARISON_ADD, partObject);
	// },

	// onRemoveProductToComparison: function (partObject) {
	// 	this.dispatch(DispatcherActions.PRODUCT_COMPARISON_REMOVE, partObject);
	// },

	// onSetComparisonPartQuantity: function(quantityObject) {
	// 	this.dispatch(DispatcherActions.PRODUCT_COMPARISON_SET_PART_QUANTITY, quantityObject);
	// },

	// onSetComparisonPartQuantities: function(quantityObject) {
	// 	this.dispatch(DispatcherActions.PRODUCT_COMPARISON_SET_PART_QUANTITIES, quantityObject);
	// },

	onComparisonError: function (errorObject) {
		this.dispatch(DispatcherActions.PRODUCT_COMPARISON_ERROR, errorObject);
	},

	onResetComparisonError: function () {
		this.dispatch(DispatcherActions.PRODUCT_COMPARISON_RESET_ERROR, {});
	},

	onResetComparison: function () {
		this.dispatch(DispatcherActions.PRODUCT_COMPARISON_RESET, {});
	},

	onRunComparison: function() {
		this.dispatch(DispatcherActions.PRODUCT_COMPARISON_RUN, {});
	},

	onRunQuickComparison: function() {
		this.dispatch(DispatcherActions.PRODUCT_QUICK_COMPARISON_RUN, {});
	},

	onRunQuickComparisonOnLoad: function() {
		this.dispatch(DispatcherActions.PRODUCT_COMPARISON_ON_LOAD, {});
	},

  onSetComparisonPdfLauncher: function(setLauncher) {
    this.dispatch(DispatcherActions.SET_COMPARISON_PDF_LAUNCHER, setLauncher);
  },

  onSetGuidePdfLauncher: function(setLauncher) {
    this.dispatch(DispatcherActions.SET_GUIDE_PDF_LAUNCHER, setLauncher);
  },

});

export const productListActions = new ProductListActions();

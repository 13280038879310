module.exports = {


  homepage_tire_title: 'Inventaire des Pneus',
  homepage_wheel_title: 'Inventaire des Roues',
  homepage_package_title: 'Inventaire des Esembles d’hiver',

  homepage_tire_text: 'Que vous cherchiez à remplacer des pneus usés individuels ou à acheter un ensemble complet de pneus toutes saisons, temps froid ou haute performance, votre concessionnaire MINI offre une vaste sélection de pneus Approuvé MINI de fabricants de premier plan.',
  homepage_wheel_text: 'Ces roues haut de gamme sont conçues pour offrir des performances optimales et conçues avec précision pour compléter la dynamique de conduite unique de votre MINI. Mieux encore, vous pouvez choisir parmi une large gamme d’options de conception de roues pour créer un style qui vous convient.',
  homepage_package_text: 'N’ayez pas froid aux pieds en poursuivant vos aventures hivernales. Les ensembles de roues pour temps froid approuvés par MINI combinent de manière pratique des pneus à traction élevée avec des roues de précision conçues pour correspondre aux spécifications de sécurité de votre MINI. De cette façon, vous pouvez facilement équiper votre véhicule pour conduire en toute sécurité, et en toute confiance partout où les routes les plus froides de la saison peuvent vous mener.',

  shop_tires: 'Magasiner les Pneus',
  shop_wheels: 'Magasiner les Roues',
  shop_packages: 'Magasiner les Esembles',

  'product': {

    'showOnlyApproved': 'Show Only ApprovedTires',
    'approvedTires': 'Pneus approuvés',
    'nonApprovedTires': 'Pneus non-approuvés',
    'good': '',
    'better': '',
    'best': '',
    'dealerPrice': 'Prix',
    'retailPrice': 'Prix',
    'tire': 'Pneus approuvés par MINI',
    'approvedTire': 'Pneus approuvés par MINI',

  },

};
import { languageStore } from '../stores/LanguageStore.js';
import { applicationStore } from '../stores/ApplicationStore.js';
import { searchStore } from '../stores/SearchStore.js';
import { productListStore } from '../stores/ProductListStore.js';
import { breadcrumbsStore } from '../stores/BreadcrumbsStore.js';

import { applicationActions } from '../actions/ApplicationActions.js';

import { VehicleSelectionFilterSection } from '../components/vehicleselection/VehicleSelectionFilterSection.jsx';

import { Image } from '../components/ux/Image.jsx';
import { ComboBox } from '../components/ux/forms/ComboBox.jsx';
import { ComboBoxValue } from '../components/ux/forms/ComboBoxValue.jsx';
import { CheckBoxItem, CheckBoxList } from '../components/ux/forms/CheckBoxList.jsx'

import { PricingFilter } from '../constants/PricingFilter.js';
import { SearchTypes } from '../constants/SearchTypes.js';

import { getTabIndex, sizeOf, getAppLanguage, listContains, money_format, sortTiresizes } from '../service/UtilityService.js'
import { getCaptchaObjectId } from '../service/CaptchaService.js'
import { getPlyRatingFilterInteger } from '../service/ProductList.js'
import { isUserLoggedIn } from "../api/AuthService";

var React = require('react');
var Immutable = require('immutable');
var _ = require('lodash');

const SHOW_ALL_TIRES = "show-all";

const SHOW_OE_TIRES_ONLY = "show-only-oe";

const SHOW_RUN_FLAT_TIRES = "show-only-run-flat";
const SHOW_NON_RUN_FLAT_TIRES = "show-only-non-run-flat";

export var filterWheels = function(wheelList, tireSizeFilters, tireSize, season) {
  var tireSizeInfo = tireSizeFilters.find(tiresize => tiresize.get('key') == tireSize);
  var diameter = null, rearDiameter = null, width = null, rearWidth = null, type = null, rearType = null;
  if (tireSizeInfo) {
    type = tireSizeInfo.get('type');
    rearType = tireSizeInfo.get('secondaryType');
    diameter = tireSizeInfo.get('diameter');
    rearDiameter = tireSizeInfo.get('secondaryDiameter');
    if (type == 'IMP') width = tireSizeInfo.get('width') * 25.4; // inch to cm conversion
    else width = tireSizeInfo.get('width');
    if (rearType == 'IMP') rearWidth = tireSizeInfo.get('secondaryWidth') * 25.4; // inch to cm conversion
    else rearWidth = tireSizeInfo.get('secondaryWidth');
  }

  var filteredListOfWheels = Immutable.List([]);
  if (sizeOf(wheelList) > 0) {
    filteredListOfWheels = Immutable.List(wheelList).filter(function (wheel) {
      wheel = wheel['filterInfo']['wheel'];
      return wheel ?
        (isValidSeason(wheel['seasonalSizeCode'], season))
        && (wheel['frontWheel']['diameter'] == diameter)
        && (isValidWidth(parseFloat(wheel['frontWheel']['width']), width))
        && ((!wheel['hasRearWheel']) || (
          (wheel['rearWheel']['diameter'] == rearDiameter)
          && (isValidWidth(parseFloat(wheel['rearWheel']['width']), rearWidth))
        )): false
    }).sort(function (a, b) {
      a = a['filterInfo']['wheel'];
      b = b['filterInfo']['wheel'];
      var a_featured = a['pricingContextId'] > 0;
      var b_featured = b['pricingContextId'] > 0;
      if (a_featured) return -1;
      else if (b_featured) return 1;
      else return 0;
    });
  }

  return filteredListOfWheels;
};

export var filterPackages = function(packageList) {
  if (packageList) {
    return Immutable.List(packageList).sort(function (a, b) {
      a = a['filterInfo']['pkg'];
      b = b['filterInfo']['pkg'];
      var a_featured = a['pricingContextId'] > 0;
      var b_featured = b['pricingContextId'] > 0;
      if (a_featured) return -1;
      else if (b_featured) return 1;
      else return 0;
    });
  } else {
    return Immutable.List({});
  }
};

export var isValidSeason = function (wheelSeason, selectedSeason) {
  switch (parseInt(wheelSeason, 10)) {
    case 0:
      return true;
    case 1:
      return parseInt(selectedSeason, 10) === 0;
    case 2:
      return parseInt(selectedSeason, 10) === 2;
  }
};

export var isValidWidth = function (wheelWidth, selectedWidth) {
  var minTireWidth = 1000;
  var maxTireWidth = 0;
  switch (wheelWidth) {
    case 5:
      minTireWidth = 135;
      maxTireWidth = 205;
      break;
    case 5.5:
      minTireWidth = 145;
      maxTireWidth = 215;
      break;
    case 6:
      minTireWidth = 155;
      maxTireWidth = 225;
      break;
    case 6.5:
      minTireWidth = 165;
      maxTireWidth = 235;
      break;
    case 7:
      minTireWidth = 175;
      maxTireWidth = 245;
      break;
    case 7.5:
      minTireWidth = 185;
      maxTireWidth = 255;
      break;
    case 8:
      minTireWidth = 195;
      maxTireWidth = 265;
      break;
    case 8.5:
      minTireWidth = 205;
      maxTireWidth = 275;
      break;
    case 9:
      minTireWidth = 215;
      maxTireWidth = 285;
      break;
    case 9.5:
      minTireWidth = 225;
      maxTireWidth = 295;
      break;
    case 10:
      minTireWidth = 235;
      maxTireWidth = 305;
      break;
    case 10.5:
      minTireWidth = 245;
      maxTireWidth = 315;
      break;
    case 11:
      minTireWidth = 255;
      maxTireWidth = 325;
      break;
    case 11.5:
      minTireWidth = 265;
      maxTireWidth = 335;
      break;
    case 12:
      minTireWidth = 275;
      maxTireWidth = 345;
      break;
    case 12.5:
      minTireWidth = 285;
      maxTireWidth = 355;
      break;
    default:
      break;
  }

  return minTireWidth <= selectedWidth && selectedWidth <= maxTireWidth;
};

export const getSeasonaIcon = (hasIcon, value) => {
  if (hasIcon) {
    switch (value) {
      case 0:
        switch (applicationStore.data.defaultTemplate) {
          case "":
            return <img className="filter-seasonal-icon" src={"/static/img/" + applicationStore.data.defaultTemplate + "/winter.png"} alt="Icon Season" />;
          default:
            return <img className="filter-seasonal-icon" src="/static/img/winter.png" alt="Icon Season"/>;
        }
      case 1:
        switch (applicationStore.data.defaultTemplate) {
          case "":
            return <img className="filter-seasonal-icon" src={"/static/img/" + applicationStore.data.defaultTemplate + "/allseason.png"} alt="Icon Season"/>;
          default:
            return <img className="filter-seasonal-icon" src="/static/img/allseason.png" alt="Icon Season"/>;
        }
      case 2:
        switch (applicationStore.data.defaultTemplate) {
          case "":
            return <img className="filter-seasonal-icon" src={"/static/img/" + applicationStore.data.defaultTemplate + "/summer.png"} alt="Icon Season"/>;
          default:
            return <img className="filter-seasonal-icon" src="/static/img/summer.png" alt="Icon Season"/>;
        }
      default:
        return false;
    }
  } else return false;
};

const doNothing = (evt) => {
  if (typeof(evt) !== 'undefined') {
    evt.preventDefault();
    evt.stopPropagation();
  }
};

const openSearchModal = () => {
  applicationActions.toggleSearchFlag();
};

const renderTireCount = (count) => {
  var dictionary = languageStore.getDictionary();
  return (
    <span className="filter-item-count text-small">
      ({count ? count : 0} {count === 1 ? dictionary.stocks.tireLwrCase : dictionary.stocks.tiresLwrCase})
    </span>
  );
};

const enableSeasonCount = () => {
  switch(applicationStore.data.defaultTemplate) {
    case 'bmw':
      return false;
    default:
      return true;
  }
};

export const renderFilterSeason = (filteredSeasonFilters, filteredSeasonCountList, onSeasonFilter, selectedSeason, hasCaptcha) => {
  var dictionary = languageStore.getDictionary();
  if (applicationStore.data.useTireListDropDownFilters) {
    return (
      <ComboBox value={selectedSeason} onChange={onSeasonFilter} className="season-select" language={getAppLanguage()} emptyText={dictionary.product.filters.season.noSelection} disabled={sizeOf(filteredSeasonFilters) === 0}>
        {filteredSeasonFilters ? filteredSeasonFilters.map(function (seasonName, season) {
          return (
            <ComboBoxValue key={season} value={season} hidden={filteredSeasonCountList[parseInt(season, 10)] === 0}>

              <button type="submit" id={hasCaptcha ? getCaptchaObjectId(season, true, false, '') : ""} onClick={!hasCaptcha ? onSeasonFilter.bind(null, season) : (function() {})}>
                {seasonName.get(getAppLanguage())}
              </button>

            </ComboBoxValue>
          );
        }).toArray() : false}
      </ComboBox>
    );
  } else {
    return (
      <CheckBoxList selectAllText={dictionary.product.filters.brand.selectAll} deselectAllText={dictionary.product.filters.brand.deselectAll} singleSelect={true}>
        {sizeOf(filteredSeasonFilters) > 0 ? filteredSeasonFilters.map(function (seasonName, season) {
          var seasonCount = filteredSeasonCountList[parseInt(season, 10)], image = getSeasonaIcon(true, parseFloat(season)), disabled = typeof(seasonCount) === 'undefined' || seasonCount === 0 || sizeOf(filteredSeasonFilters) === 0;
          return (
            <CheckBoxItem key={"season-select-" + season} seasonalIcon={false} current={parseFloat(selectedSeason)} value={parseFloat(season)} disabled={disabled}>

              <button type="submit" id={hasCaptcha ? getCaptchaObjectId(season, true, false, '') : ""} onClick={!hasCaptcha ? onSeasonFilter.bind(null, season) : (function() {})}>
                {image}
                {seasonName.get(getAppLanguage())}
                {enableSeasonCount() ? renderTireCount(seasonCount) : false}
              </button>

            </CheckBoxItem>
          );
        }).toArray() : ""}
      </CheckBoxList>
    );
  }
};

export const renderFilterSeasonSection = (filteredSeasonFilters, filteredSeasonCountList, onSeasonFilter, selectedSeason, hasCaptcha) => {
  if (true) {
    var dictionary = languageStore.getDictionary();
    return (
      <div className="filter-container">
        <h3 aria-label={dictionary.common.select + ": " + dictionary.product.season}
            tabIndex={getTabIndex()}
            className="show-focus"
        >{dictionary.product.season}</h3>

        <form onSubmit={doNothing}>
          {renderFilterSeason(filteredSeasonFilters, filteredSeasonCountList, onSeasonFilter, selectedSeason, hasCaptcha)}
        </form>
      </div>
    );
  } else {
    return false;
  }
};

export const renderFilterMultiSelectSeason = (selectedSeasons, filterSeasons, onSelectedAllSeasons, onUnselectAllSeasons, onSeasonSelected) => {
  if (true) {
    return (
      Immutable.fromJS(filterSeasons).map(function(season, index) {

        var selected = false;
        if (selectedSeasons && selectedSeasons.indexOf(index) > -1) selected = true;

        return (
          <div key={"filter-season-"+index} className="checkbox-container" onClick={onSeasonSelected.bind(null, index)}>
            <div className={selected ? "select-box selected" : "select-box"} />
            {getSeasonaIcon(true, parseInt(index))}
            <span>{season.get(getAppLanguage())}</span>
          </div>
        );

      })
    );
  } else {
    return false;
  }
};

export const renderFilterMultiSelectSeasonSection = (enableSeasonFilter, selectedSeasons, filterSeasons, onSelectedAllSeasons, onUnselectAllSeasons, onSeasonSelected) => {
  if (enableSeasonFilter) {
    var dictionary = languageStore.getDictionary();
    return (
      <div>
        <h3>
          <span className="filter-title">{dictionary.product.season}</span>
          <div className="filter-controller">
            <span onClick={onSelectedAllSeasons}>{dictionary.product.filters.brand.selectAll}</span>
            <span>/</span>
            <span onClick={onUnselectAllSeasons}>{dictionary.product.filters.brand.deselectAll}</span>
          </div>
        </h3>
        <div className="filter-multi-select season">
          {renderFilterMultiSelectSeason(selectedSeasons, filterSeasons, onSelectedAllSeasons, onUnselectAllSeasons, onSeasonSelected)}
        </div>
      </div>
    );
  } else {
    return false;
  }
};

export const renderQuickFilterSeasonSection = (partListLoading, selectedSeason, tireSeasonCountList, seasonFilters, onSeasonFilter) => {
  if (!partListLoading) {
    seasonFilters = seasonFilters.filter((seasonIndex, season) => {
      return tireSeasonCountList[parseInt(season, 10)] > 0
    });
    return seasonFilters.map(function (seasonIndex, season) {
      var className = "quick-filter-season";
      if (parseFloat(season) === parseFloat(selectedSeason)) className = "quick-filter-season selected";
      if (parseFloat(season) === 1) className += " all-season";
      return <button className={className} key={"quick-filter-season-" + season} onClick={onSeasonFilter.bind(null, season)}>{seasonIndex.get(getAppLanguage())}</button>;
    });
  } else {
    return false;
  }
};

export const renderQuickFilterTiresizeSection = (partListLoading, selectedTiresize, validTireSizeList, tireSizeFilters, onTireSizeFilter) => {
  if (!partListLoading) {
    var dictionary = languageStore.getDictionary(), validTireSizeList = validTireSizeList.map(tiresize => tiresize.get('key'));
    return (
      <ComboBox value={selectedTiresize} onChange={onTireSizeFilter} emptyText={dictionary.product.filters.tireSize.noSelection} className="tiresize-select">
        {tireSizeFilters.filter(tireSize => validTireSizeList.indexOf(tireSize.get('key')) > -1).map(function (tireSize) {
          var tiresize = "";
          if (tireSize.get('staggered')) tiresize = <span>{dictionary.product.front + ":"} {tireSize.get('formattedTireSize')} {dictionary.product.rear + ":"} {tireSize.get('secondaryFormattedTireSize')}</span>;
          else tiresize = tireSize.get('value')[getAppLanguage()];
          return (
            <ComboBoxValue key={"tireSizeFilter-" + tireSize.get('key')} disabled={validTireSizeList.indexOf(tireSize.get('key')) === -1} value={tireSize.get('key')}>{tiresize}</ComboBoxValue>
          );
        }).toArray()}
      </ComboBox>
    );
  } else {
    return false;
  }
};

export const renderFilterTiresize = (tireSizeFilters, filteredTireSizeFilters, tireSizeSpecIdCount, onTireSizeFilter, selectedTiresize, hasCaptcha) => {
  var dictionary = languageStore.getDictionary(), validTireSizeList = filteredTireSizeFilters.map(tireSize => tireSize.get('key'));
  if (applicationStore.data.useTireListDropDownFilters) {
    return (
      <ComboBox value={selectedTiresize} onChange={onTireSizeFilter} emptyText={dictionary.product.filters.tireSize.noSelection} className="tiresize-select">
        {tireSizeFilters.filter(tireSize => validTireSizeList.indexOf(tireSize.get('key')) > -1).map(function (tireSize) {
          return (
            <ComboBoxValue key={"tireSizeFilter"+tireSize.get('key')} disabled={validTireSizeList.indexOf(tireSize.get('key')) === -1} value={tireSize.get('key')}>

              <button type="submit" id={hasCaptcha ? getCaptchaObjectId(tireSize.get('key'), false, true) : ""} onClick={!hasCaptcha ? onTireSizeFilter.bind(null, tireSize.get('key')) : (function() {})}>
                {getFormattedTiresize(tireSize)}
              </button>

            </ComboBoxValue>
          );
        }).toArray()}
      </ComboBox>
    );
  } else {
    return (
      <CheckBoxList onChange={onTireSizeFilter} selectAllText={dictionary.product.filters.brand.selectAll} deselectAllText={dictionary.product.filters.brand.deselectAll} singleSelect={true} tireSizeFilter={true}>
        {sizeOf(tireSizeFilters) > 0 ? tireSizeFilters.map(function (tireSize) {
          var tireSizeCount = tireSizeSpecIdCount[tireSize.get('key')], disabled = typeof(tireSizeCount) === 'undefined' || tireSizeCount === 0 || validTireSizeList.indexOf(tireSize.get('key')) === -1;
          return (
            <CheckBoxItem key={"tireSizeFilter-"+tireSize.get('key')} current={selectedTiresize} value={tireSize.get('key')} disabled={disabled}>

              <button type="submit" id={hasCaptcha ? getCaptchaObjectId(tireSize.get('key'), false, true) : ""} onClick={!hasCaptcha ? onTireSizeFilter.bind(null, tireSize.get('key')) : (function() {})}>
                {tireSize.get('value')[getAppLanguage()]}
                {renderTireCount(tireSizeCount)}
              </button>

            </CheckBoxItem>
          );
        }).toArray() : ""}
      </CheckBoxList>
    );
  }
};

const getFormattedTiresize = (tiresize) => {
  if (tiresize && tiresize.get('staggered')) {
    var dictionary = languageStore.getDictionary();
    return <span>{dictionary.product.front + ":"} {tiresize.get('formattedTireSize')} {dictionary.product.rear + ":"} {tiresize.get('secondaryFormattedTireSize')}</span>;
  } else if (tiresize) {
    return tiresize.get('formattedTireSize');
  } else {
    return "";
  }
};

export const renderFilterTiresizeSection = (tireSizeFilters, filteredTireSizeFilters, tireSizeSpecIdCount, onTireSizeFilter, selectedTiresize, hasCaptcha) => {
  if (true) {
    var dictionary = languageStore.getDictionary();
    return (
      <div className="filter-container">
        <h3 aria-label={dictionary.common.select + ": " + dictionary.product.tireSize}
            tabIndex={getTabIndex()}
            className="show-focus"
        >{dictionary.product.tireSize}</h3>

        <form onSubmit={doNothing}>
          {renderFilterTiresize(tireSizeFilters, filteredTireSizeFilters, tireSizeSpecIdCount, onTireSizeFilter, selectedTiresize, hasCaptcha)}
        </form>
      </div>
    );
  } else {
    return false;
  }
};

export const renderFilterMultiSelectTiresize = (selectedTiresizes, filterTiresizes, onSelectedAllTiresizes, onUnselectAllTiresizes, onTiresizeSelected) => {
  if (true) {
    return (
      Immutable.fromJS(filterTiresizes).sort(sortTiresizes).map(function(tiresize, index) {

        var selected = false;
        if (selectedTiresizes && selectedTiresizes.indexOf(tiresize.get('tireSizeSpecId')) > -1) selected = true;

        return (
          <div key={"filter-tiresize-"+index} className="checkbox-container" onClick={onTiresizeSelected.bind(null, tiresize.get('tireSizeSpecId'))}>
            <div className={selected ? "select-box selected" : "select-box"} />
            <span>{tiresize.get('formattedTiresize')}</span>
          </div>
        );

      })
    );
  } else {
    return false;
  }
};

export const renderFilterMultiSelectTiresizeSection = (enableTiresizeFilter, selectedTiresizes, filterTiresizes, onSelectedAllTiresizes, onUnselectAllTiresizes, onTiresizeSelected) => {
  if (enableTiresizeFilter) {
    var dictionary = languageStore.getDictionary();
    return (
      <div>
        <h3>
          <span className="filter-title">{dictionary.product.tireSize}</span>
          <div className="filter-controller">
            <span onClick={onSelectedAllTiresizes}>{dictionary.product.filters.brand.selectAll}</span>
            <span>/</span>
            <span onClick={onUnselectAllTiresizes}>{dictionary.product.filters.brand.deselectAll}</span>
          </div>
        </h3>
        <div className="filter-multi-select tiresize">
          {renderFilterMultiSelectTiresize(selectedTiresizes, filterTiresizes, onSelectedAllTiresizes, onUnselectAllTiresizes, onTiresizeSelected)}
        </div>
      </div>
    );
  } else {
    return false;
  }
};

export const renderFilterRunFlat = (runFlatFilter, onChangeRunFlatFilter) => {
  if (true) {
    var dictionary = languageStore.getDictionary();
    return (
      <ComboBox value={runFlatFilter} onChange={onChangeRunFlatFilter} emptyText={dictionary.product.filters.season.noSelection} className="original-equipment-select">
        <ComboBoxValue key={SHOW_ALL_TIRES} value={SHOW_ALL_TIRES}>{dictionary.product.showAll}</ComboBoxValue>
        <ComboBoxValue key={SHOW_RUN_FLAT_TIRES} value={SHOW_RUN_FLAT_TIRES}>{dictionary.product.showRunFlat}</ComboBoxValue>
        <ComboBoxValue key={SHOW_NON_RUN_FLAT_TIRES} value={SHOW_NON_RUN_FLAT_TIRES}>{dictionary.product.showNonRunFlat}</ComboBoxValue>
      </ComboBox>
    );
  } else {
    return false;
  }
};

export const renderFilterRunFlatSection = (runFlatFilter, onChangeRunFlatFilter) => {
  if (applicationStore.data.enableRunFlatFilter && productListStore.data.searchType !== SearchTypes.SEARCH_BY_PARTNUMBER) {
    var dictionary = languageStore.getDictionary();
    return (
      <div className="filter-container">
        <h3>{dictionary.product.runFlatText}</h3>
        {renderFilterRunFlat(runFlatFilter, onChangeRunFlatFilter)}
      </div>
    );
  } else {
    return false;
  }
};

export const renderFilterOETire = (tireOeFilter, onChangeOeFilter) => {
  if (true) {
    var dictionary = languageStore.getDictionary();
    return (
      <ComboBox value={tireOeFilter} onChange={onChangeOeFilter} emptyText={dictionary.product.filters.season.noSelection} className="original-equipment-select">
        <ComboBoxValue key={SHOW_OE_TIRES_ONLY} value={SHOW_OE_TIRES_ONLY}>{dictionary.product.showOnlyApproved}</ComboBoxValue>
        <ComboBoxValue key={SHOW_ALL_TIRES} value={SHOW_ALL_TIRES}>{dictionary.product.showAll}</ComboBoxValue>
      </ComboBox>
    );
  } else {
    return false;
  }
};

export const renderFilterOETireSection = (tireOeFilter, onChangeOeFilter) => {
  if (isUserLoggedIn() ? applicationStore.data.useOriginalEquipmentOnlyFilterWhenAuhenticated : applicationStore.data.useOriginalEquipmentOnlyFilter) {
    var dictionary = languageStore.getDictionary();
    return (
      <div className="filter-container">
        <h3>{dictionary.product.approvedTires}</h3>
        {renderFilterOETire(tireOeFilter, onChangeOeFilter)}
      </div>
    );
  } else {
    return false;
  }
};

export const renderFilterLoadSpeedRating = (isStaggered, loadRating, loadRatingFilters, onLoadRatingFilter, speedIndex, speedIndexFilters, onSpeedIndexFilter, secondaryLoadRating, secondaryLoadRatingFilters, onLoadRatingFilterSecondary, secondarySpeedIndex, secondarySpeedIndexFilters, onSpeedIndexFilterSecondary) => {
  var dictionary = languageStore.getDictionary();
  if (isStaggered) {
    return (
      <div className="filter-load-speed">
        <div className="filter-tire-front">
          <div>
            <span className="filter-tire-placement">{dictionary.product.frontAbbrv + ": "}</span>
          </div>

          {((loadRating == 84 && speedIndex == 'N') || (secondaryLoadRating == 84 && secondarySpeedIndex == 'N')) ?
            <p>{dictionary.product.missingLoadAndSpeed}</p> : false}

          {sizeOf(loadRatingFilters) > 0 && (!(loadRating == 84 && speedIndex == 'N') && !(secondaryLoadRating == 84 && secondarySpeedIndex == 'N')) ?
            <ComboBox key={"loadFilter"} value={loadRating} onChange={onLoadRatingFilter} emptyText={dictionary.product.filters.loadRating.noSelection} className="half-left">
              {loadRatingFilters.map(function (loadRating) {
                return (
                  <ComboBoxValue key={loadRating.get('key')} value={loadRating.get('key')}>{loadRating.get('value')}</ComboBoxValue>
                );
              }).toArray()}
            </ComboBox> : <span className="filter-tire-placement">{dictionary.product.missingLoadRating}</span>}

          {sizeOf(speedIndexFilters) > 0 && (!(loadRating == 84 && speedIndex == 'N') && !(secondaryLoadRating == 84 && secondarySpeedIndex == 'N')) ?
            <ComboBox key={"speedFilter"} value={speedIndex} onChange={onSpeedIndexFilter} emptyText={dictionary.product.filters.speedRating.noSelection} className="half-right">
              {speedIndexFilters.map(function (speedIndex) {
                return (
                  <ComboBoxValue key={speedIndex.get('key')} value={speedIndex.get('key')}>{speedIndex.get('value')}</ComboBoxValue>
                );
              }).toArray()}
            </ComboBox> : <span className="filter-tire-placement">{dictionary.product.missingSpeedIndex}</span>}

        </div>

        <div className="filter-tire-break-line">
          <br />
        </div>

        <div className="filter-tire-rear">

          {secondaryLoadRating && secondarySpeedIndex && (!(loadRating == 84 && speedIndex == 'N') && !(secondaryLoadRating == 84 && secondarySpeedIndex == 'N')) ?
            <div>
              <span className="filter-tire-placement">{dictionary.product.rearAbbrv + ": "}</span>
            </div> : false}

          {secondaryLoadRating && secondarySpeedIndex && (!(loadRating == 84 && speedIndex == 'N') && !(secondaryLoadRating == 84 && secondarySpeedIndex == 'N')) ?
            <ComboBox value={secondaryLoadRating} onChange={onLoadRatingFilterSecondary} emptyText={dictionary.product.filters.loadRating.noSelection} className="half-left">
              {secondaryLoadRatingFilters.map(function (loadRating) {
                return (
                  <ComboBoxValue key={loadRating.get('key')} value={loadRating.get('key')}>{loadRating.get('value')}</ComboBoxValue>
                );
              }).toArray()}
            </ComboBox> : false}

          {secondaryLoadRating && secondarySpeedIndex && (!(loadRating == 84 && speedIndex == 'N') && !(secondaryLoadRating == 84 && secondarySpeedIndex == 'N')) ?
            <ComboBox value={secondarySpeedIndex} onChange={onSpeedIndexFilterSecondary} emptyText={dictionary.product.filters.speedRating.noSelection} className="half-right">
              {secondarySpeedIndexFilters.map(function (speedIndex) {
                return (
                  <ComboBoxValue key={speedIndex.get('key')} value={speedIndex.get('key')}>{speedIndex.get('value')}</ComboBoxValue>
                );
              }).toArray()}
            </ComboBox> : false}

        </div>
      </div>
    );
  } else {
    return (
      <div className="filter-load-speed">
        {(loadRating == 84 && speedIndex == 'N') ?
          <p>{dictionary.product.missingLoadAndSpeed}</p> : false}

        {sizeOf(loadRatingFilters) > 0 && !(loadRating == 84 && speedIndex == 'N') ?
          <ComboBox value={loadRating} onChange={onLoadRatingFilter} emptyText={dictionary.product.filters.loadRating.noSelection} className="half-left">
            {loadRatingFilters.map(function (loadRating) {
              return (
                <ComboBoxValue key={loadRating.get('key')} value={loadRating.get('key')}>{loadRating.get('value')}</ComboBoxValue>
              );
            }).toArray()}
          </ComboBox> : !(loadRating == 84 && speedIndex == 'N') ? <span className="filter-tire-placement">{dictionary.product.missingLoadRating}</span> : false}

        {sizeOf(speedIndexFilters) > 0 && !(loadRating == 84 && speedIndex == 'N') ?
          <ComboBox value={speedIndex} onChange={onSpeedIndexFilter} emptyText={dictionary.product.filters.speedRating.noSelection} className="half-right">
            {speedIndexFilters.map(function (speedIndex) {
              return (
                <ComboBoxValue key={speedIndex.get('key')} value={speedIndex.get('key')}>{speedIndex.get('value')}</ComboBoxValue>
              );
            }).toArray()}
          </ComboBox> : !(loadRating == 84 && speedIndex == 'N') ? <span className="filter-tire-placement">{dictionary.product.missingSpeedIndex}</span> : false}
      </div>
    );
  }
};

export const renderFilterLoadSpeedRatingSection = (isStaggered, loadRating, loadRatingFilters, onLoadRatingFilter, speedIndex, speedIndexFilters, onSpeedIndexFilter, secondaryLoadRating = '', secondaryLoadRatingFilters = null, onLoadRatingFilterSecondary = null, secondarySpeedIndex = '', secondarySpeedIndexFilters = null, onSpeedIndexFilterSecondary = null) => {
  if (true) {
    var loadSpeedClassName = "show-focus", loadSpeedClassNameBody = "clearfix", dictionary = languageStore.getDictionary();
    if (applicationStore.data.enableMercedesFeatures) {
      loadSpeedClassName = "show-focus hide";
      loadSpeedClassNameBody = "clearfix hide";
    }
    return (
      <div className="filter-container">
        <h3 aria-label={dictionary.common.select + ": " + dictionary.product.loadAndSpeedRating}
            tabIndex={getTabIndex()}
            className={loadSpeedClassName}
        >{dictionary.product.loadAndSpeedRating}</h3>

        <div className={loadSpeedClassNameBody}>
          {renderFilterLoadSpeedRating(isStaggered, loadRating, loadRatingFilters, onLoadRatingFilter, speedIndex, speedIndexFilters, onSpeedIndexFilter, secondaryLoadRating, secondaryLoadRatingFilters, onLoadRatingFilterSecondary, secondarySpeedIndex, secondarySpeedIndexFilters, onSpeedIndexFilterSecondary)}
        </div>
      </div>
    );
  } else {
    return false;
  }
};

export const renderFilterPlyRating = (showPlyFilter, selectedPlyRating, plyRatingFilters, onPlyRatingFilter) => {
  if (true) {
    var dictionary = languageStore.getDictionary();
    return (
      <CheckBoxList onChange={onPlyRatingFilter} selectAllText={dictionary.product.filters.brand.selectAll} deselectAllText={dictionary.product.filters.brand.deselectAll}>
        {plyRatingFilters.map(function (plyRating) {
          var selected = _.contains(selectedPlyRating, plyRating.get('key'));
          var plyRatingInteger = getPlyRatingFilterInteger(plyRating.get('value'));
          var plyRatingText = plyRating.get('value') + (plyRatingInteger > 0 ? " - " + plyRatingInteger : "");
          return (
            <CheckBoxItem key={plyRating.get('key')} value={plyRating.get('key')} selected={selected}>
              {plyRatingText}
            </CheckBoxItem>
          );
        }).toArray()}
      </CheckBoxList>
    );
  } else {
    return false;
  }
};

export const renderFilterPlyRatingSection = (showPlyFilter, selectedPlyRating, plyRatingFilters, onPlyRatingFilter, showOrHidePlyFilter) => {
  if (true) {
    var dictionary = languageStore.getDictionary(), plyArrowImage = <Image src="/static/img/filter_hide.png" alt="Icon Filter"/>;
    if (!showPlyFilter) { plyArrowImage = <Image src="/static/img/filter_expand.png" alt="Icon Filter"/>; }
    return (
      <div>
        <div onClick={showOrHidePlyFilter}
             onKeyDown={(e) => showOrHidePlyFilter(e)}
             aria-label={dictionary.common.select + ": " + dictionary.product.plyRatingFilter}
             tabIndex={getTabIndex()}
             className="tire-brand-filter show-focus" >
          {plyArrowImage}
          <h3>{dictionary.product.plyRatingFilter}</h3>
        </div>
        <div className={!showPlyFilter ? "hide" : ""}>
          {renderFilterPlyRating(showPlyFilter, selectedPlyRating, plyRatingFilters, onPlyRatingFilter)}
        </div>
      </div>
    );
  } else {
    return false;
  }
};

export const renderFilterBrand = (selectedBrand, brandFilters, filteredBrandIds, onBrandFilter) => {
  if (true) {
    var dictionary = languageStore.getDictionary();
    return (
      <CheckBoxList onChange={onBrandFilter} selectAllText={dictionary.product.filters.brand.selectAll} deselectAllText={dictionary.product.filters.brand.deselectAll}>
        {brandFilters.sortBy(function (brandFilter) {
          return brandFilter.get('value');
        }).map(function (brand) {
          var selected = _.contains(selectedBrand, brand.get('key')), showBrand = _.contains(filteredBrandIds, brand.get('key'));
          return <CheckBoxItem key={brand.get('key')} value={brand.get('key')} selected={selected} disabled={!showBrand}>{brand.get('value')}</CheckBoxItem>;
        }).toArray()}
      </CheckBoxList>
    );
  } else {
    return false;
  }
};

export const renderFilterBrandSection = (enableBrandFilter, showBrandFilter, showOrHideBrandFilter, selectedBrand, brandFilters, filteredBrandIds, onBrandFilter) => {
  if (enableBrandFilter) {
    var dictionary = languageStore.getDictionary(), brandClassName = "", brandArrowImage = <Image className="icon" src="/static/img/filter_hide.png" alt="Icon Filter"/>;
    if (!showBrandFilter) {
      brandClassName = "hide";
      brandArrowImage = <Image className="icon" src="/static/img/filter_expand.png" alt="Icon Filter"/>;
    }
    return (
      <div>
        <div onClick={showOrHideBrandFilter}
             aria-label={dictionary.common.select + ": " + dictionary.product.tireBrands}
             tabIndex={getTabIndex()}
             onKeyDown={(e) => showOrHideBrandFilter(e)}
             className="tire-brand-filter show-focus" >
          {brandArrowImage}
          <h3>{dictionary.product.tireBrands}</h3>
        </div>

        <div className={brandClassName}>
          {renderFilterBrand(selectedBrand, brandFilters, filteredBrandIds, onBrandFilter)}
        </div>
      </div>
    );
  } else {
    return false;
  }
};

export const renderFilterPricing = (filterMinMaxPricingList, tireListMaxPrice, tireListMinPrice, onPricingFilter, onSelectAllPricing, onDeselectAllPricing) => {
  if (true) {
    var dictionary = languageStore.getDictionary();
    return (
      <CheckBoxList onChange={onPricingFilter}
                    onSelectAll={onSelectAllPricing}
                    onDeselectAll={onDeselectAllPricing}
                    selectAllText={dictionary.product.filters.brand.selectAll}
                    deselectAllText={dictionary.product.filters.brand.deselectAll}>
        {Immutable.fromJS(PricingFilter).map(function (pricingFilter, index) {
          var selected = listContains(filterMinMaxPricingList, index),
          disabled = tireListMaxPrice < parseFloat(pricingFilter.get('min')) || tireListMinPrice > parseFloat(pricingFilter.get('max')),
          hidden = tireListMaxPrice < parseFloat(pricingFilter.get('min'));
          return <CheckBoxItem key={index} value={index} selected={selected} disabled={disabled} hidden={hidden}>{money_format(pricingFilter.get('min'))}{pricingFilter.get('max') !== null ? " - " : " +"}{pricingFilter.get('max') !== null ? money_format(pricingFilter.get('max')) : ''}</CheckBoxItem>;
        })}
      </CheckBoxList>
    );
  } else {
    return false;
  }
};

export const renderFilterPricingSection = (enablePricingFilter, showPricingFilter, showOrHidePricingFilter, filterMinMaxPricingList, tireListMinPrice, tireListMaxPrice, onPricingFilter, onSelectAllPricing, onDeselectAllPricing) => {
  if (enablePricingFilter) {
    var dictionary = languageStore.getDictionary(), pricingClassName = "pricing-filter", pricingArrowImage = <Image src="/static/img/filter_hide.png" alt="Icon Filter"/>;
    if (!showPricingFilter) {
      pricingClassName = "pricing-filter hide";
      pricingArrowImage = <Image src="/static/img/filter_expand.png" alt="Icon Filter"/>;
    }
    return (
      <div>
        <div onClick={showOrHidePricingFilter}
             aria-label={dictionary.common.select + ": " + dictionary.product.tirePricing}
             tabIndex={getTabIndex()}
             onKeyDown={(e) => showOrHidePricingFilter(e)}
             className="tire-brand-filter show-focus" >
          {pricingArrowImage}
          <h3>{dictionary.product.tirePricing}</h3>
        </div>

        <div className={pricingClassName}>
          {renderFilterPricing(filterMinMaxPricingList, tireListMaxPrice, tireListMinPrice, onPricingFilter, onSelectAllPricing, onDeselectAllPricing)}
        </div>
      </div>
    );
  } else {
    return false;
  }
};

export const renderFilterVehicleLauncher = () => {
  if ((!applicationStore.data.enableMercedesFeatures && !applicationStore.data.enableVolkswagenFeatures) && (productListStore.data.searchType == SearchTypes.SEARCH_BY_VEHICLE_SIMPLE || productListStore.data.searchType == SearchTypes.SEARCH_BY_VEHICLE)) {
    var dictionary = languageStore.getDictionary(), vehicleInfo = "", crumbs = breadcrumbsStore.getBreadcrumbs();
    if (crumbs) {
      for (var i in crumbs) {
        if (typeof(crumbs[i]['value']) !== 'undefined' && crumbs[i]['value'] !== null && (parseFloat(i) === 0 || parseFloat(i) === 3)) {
          if (typeof(crumbs[i]['value']) === "object") {
            vehicleInfo += " " + crumbs[i]['value'][getAppLanguage()];
          } else if (typeof(crumbs[i]['value']) === "string") {
            vehicleInfo += " " + crumbs[i]['value'];
          }
        }
      }
    }
    return (
      <div className="vehicle-selection">
        <hr />
        <div className="vehicle-selection-modal-title">
          <h3>
            <span className="title">{dictionary.common.vehicleSearch + ":"}</span>
            <span className="chassis-search-value">{vehicleInfo}</span>
          </h3>
        </div>
        <div className="vehicle-selection-button-search chassis-search-button vehicle-search">
          <button className="btn" onClick={openSearchModal}>{dictionary.search.newSearch}</button>
        </div>
      </div>
    );
  } else {
    return false;
  }
};

export const getVehicleFilterInfo = () => {
  var dictionary = languageStore.getDictionary(), vehicleInfo = "", crumbs = breadcrumbsStore.getBreadcrumbs();
  if (crumbs) {
    for (var i in crumbs) {
      if (typeof(crumbs[i]['value']) !== 'undefined' && crumbs[i]['value'] !== null && (parseFloat(i) === 0 || parseFloat(i) === 3)) {
        if (typeof(crumbs[i]['value']) === "object") {
          vehicleInfo += " " + crumbs[i]['value'][getAppLanguage()];
        } else if (typeof(crumbs[i]['value']) === "string") {
          vehicleInfo += " " + crumbs[i]['value'];
        }
      }
    }
  }
  return vehicleInfo;
};

export const renderFilterVehicleSearchLauncher = () => {
  if (productListStore.data.searchType == SearchTypes.SEARCH_BY_MODEL) {
    return <VehicleSelectionFilterSection />;
  } else {
    return false;
  }
};

export const renderFilterChassisSearchLauncher = () => {
  if (productListStore.data.searchType == SearchTypes.SEARCH_BY_MODEL || productListStore.data.searchType == SearchTypes.SEARCH_BY_CHASSIS) {
    var dictionary = languageStore.getDictionary();
    return (
      <div className="vehicle-selection">
        <hr />
        <div className="vehicle-selection-modal-title">
          <h1>
            <span className="title">{dictionary.common.chassisCode + ":"}</span>
            <span className="chassis-search-value">{productListStore.data.chassis}</span>
          </h1>
        </div>
        <div className="vehicle-selection-button-search chassis-search-button">
          <button className="btn" onClick={openSearchModal}>{dictionary.search.newSearch}</button>
        </div>
      </div>
    );
  } else {
    return false;
  }
};

export const renderFilterTitle = () => {
  if (applicationStore.data.enableMercedesFeatures) {
    var dictionary = languageStore.getDictionary();
    return (
      <h1>{dictionary.tireOptions}</h1>
    );
  } else {
    return false;
  }
};

export const renderFilterLauncherDivider = () => {
  if (productListStore.data.searchType == SearchTypes.SEARCH_BY_MODEL || productListStore.data.searchType == SearchTypes.SEARCH_BY_CHASSIS) {
    return (
      <hr className="search-vehicle-div-hr" />
    );
  } else {
    return false;
  }
};

export const renderFilterDivider = (enableCondition = true, overwrite = false) => {
  if ((!applicationStore.data.enableMercedesFeatures && enableCondition) || overwrite) {
    return (
      <hr />
    );
  } else {
    return false;
  }
};

export const renderFilterCarouselSection = (showFeaturedItems, featuredItems) => {
  if (showFeaturedItems) {
    return (
      <div className="div-product-list-selection">
        <div className="div-secondary-carousel">
          {featuredItems.map(function (featuredItem, index) {
            return (
              <div key={"carousel-item-" + index} className="featured-item">

                <a href={featuredItem.get('linkUrl').get(getAppLanguage())}>
                  <div className="secondary-carousel-image">
                    <Image src={featuredItem.get('image').get(getAppLanguage())} alt="Image Featured Tire"/>
                  </div>
                  <div className="secondary-carousel-title">
                    <h4>{featuredItem.get('title').get(getAppLanguage())}</h4>
                  </div>
                  <div className="secondary-carousel-tag">
                    <p>{featuredItem.get('subtitle').get(getAppLanguage())}</p>
                  </div>
                </a>

                {index < (sizeOf(featuredItems) - 1) ? <hr /> : false}

              </div>
            );
          }).toArray()}
        </div>
      </div>
    );
  } else {
    return false;
  }
};

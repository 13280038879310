import { productListStore } from "../../stores/ProductListStore.js";
import { productListActions } from "../../actions/ProductListActions.js";
import { Modal } from "../ux/Modal.jsx";

import {
  getTabIndex,
  sizeOf,
  getAppVehicleId,
  getTrackingEventCategory,
} from "../../service/UtilityService";
import { getWarningType } from "../../service/ProductList";
import { connect } from "react-redux";
import { productListWarning } from "../../redux/features/productList.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");

class WarningModal extends I18nComponent {
  state = {
    visible: false,
    warning: {},
    tracked: false,
  };

  componentWillMount() {
    productListStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    productListStore.removeChangeListener(this.onChange);
  }

  componentDidUpdate() {
    if (
      !this.state.tracked &&
      this.state.visible &&
      sizeOf(this.state.warning) > 0
    ) {
      this.setState({ tracked: true });
      googleAnalyticsEnhancedTrackPageview(
        "ProductList Warning Modal - " + getWarningType()
      );
      googleAnalyticsEnhancedTrackEvent(
        getTrackingEventCategory(),
        "ProductList Warning Modal Access - " + getWarningType(),
        getAppVehicleId()
      );
    }
  }

  render() {
    if (sizeOf(this.state.warning) > 0 && this.state.warning[0]) {
      return (
        <Modal refactor={true} requestUnmount={this.onHideWarning}>
          <div className="wrapper">
            <div className="modal-content">
              <span>{this.renderWarningText(this.state.warning[0])}</span>
              <div className="button-container">
                <button
                  onClick={this.onHideWarning}
                  tabIndex={getTabIndex()}
                  aria-label={this.t("common.close")}
                  className="btn"
                >
                  {this.t("common.close")}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  }

  renderWarningText = (warning) => {
    if (warning.seasonHasChanged) {
      if (warning.fromWinter) {
        return this.t("warning.seasonChangedFromWinter");
      } else if (warning.toWinter) {
        return this.t("warning.seasonChangedToWinter");
      } else {
        return this.t("warning.seasonChanged");
      }
    } else if (warning.tiresizeHasChanged) {
      return this.t("warning.tiresizeChanged");
    }
  };

  onHideWarning = () => {
    this.props.dispatch(productListWarning(false));
    productListActions.onHideProductListWarning();
  };

  onChange = () => {
    this.setState({
      visible: productListStore.data.showWarning,
      warning: productListStore.data.warning,
    });
  };
}

export default connect(null)(WarningModal);

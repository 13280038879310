import { Modal } from "../ux/Modal.jsx";

import { applicationStore } from "../../stores/ApplicationStore.js";
import { shoppingCartStore } from "../../stores/ShoppingCartStore.js";
import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";
import { EmailField } from "../ux/forms/EmailField.jsx";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var _ = require("lodash");

export class WishlistEmailSimpleModal extends I18nComponent {
  state = {
    emailTouched: false,
    emailValid: false,
    emailValue: "",

    emailHasBeenSent: shoppingCartStore.data.get("emailWishlistModalEmailSent"),
    emailHasFailed: shoppingCartStore.data.get("emailWishlistModalEmailFailed"),
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
    shoppingCartStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
    shoppingCartStore.removeChangeListener(this.onChange);
  }

  render() {
    var modalClassName = "wishlist-email-simple";
    if (this.state.emailHasBeenSent && !this.state.emailHasFailed)
      modalClassName = "wishlist-email-simple return-message";

    return (
      <Modal
        refactor={true}
        useShortHeaderBar={true}
        requestUnmount={this.onRequestCloseModal}
      >
        <div className="wrapper half">
          <div className={modalClassName}>
            {!this.state.emailHasBeenSent ? (
              <div className="title">
                <h2>{this.t("wishlist.emailWishlist")}</h2>
              </div>
            ) : (
              false
            )}
            {!this.state.emailHasBeenSent ? (
              <div className="quote-controls">
                <form onSubmit={this.onFormSubmit}>
                  <div className="inputs">
                    <fieldset className="contact-information">
                      <EmailField
                        onChange={this.onEmailChange}
                        touched={this.state.emailTouched}
                        value={
                          this.state.emailValue &&
                          this.state.emailValue != null &&
                          this.state.emailValue != "null"
                            ? this.state.emailValue
                            : ""
                        }
                        label={this.t("quote.emailFieldLabel")}
                        id="userEmailField"
                        errorNotEmpty={this.t("validation.notEmpty")}
                        errorInvalidEmail={this.t("validation.email")}
                      />
                    </fieldset>
                  </div>
                  <button className="btn">
                    <i className="fa fa-envelope-o"></i>{" "}
                    {this.t("wishlist.emailWishlistBtnText")}
                  </button>
                </form>
              </div>
            ) : this.state.emailHasFailed ? (
              this.renderEmailFailed()
            ) : (
              this.renderEmailSent()
            )}
          </div>
        </div>
      </Modal>
    );
  }

  renderEmailSent = () => {
    return (
      <div className="appointment-sent-info">
        <h1 className="success">{this.t("wishlist.modal.emailSent")}</h1>
        <div>
          <h3>{this.t("wishlist.modal.contactInformation")}</h3>
          <ul>
            <li>
              <span>{this.t("wishlist.modal.email") + ":"}</span>{" "}
              <span>{this.state.emailValue}</span>
            </li>
          </ul>
        </div>
        <div className="return-message">
          <ul>
            <li>{this.t("wishlist.modal.emailDisclaimer")}</li>
          </ul>
        </div>
      </div>
    );
  };

  renderEmailFailed = () => {
    return (
      <div className="appointment-sent-info">
        <h1 className="failure">{this.t("wishlist.modal.emailFailed")}</h1>
      </div>
    );
  };

  onEmailChange = (value, valid) => {
    this.setState({
      emailValue: value,
      emailValid: valid,
      emailTouched: true,
    });
  };

  canBeSubmitted = () => {
    return this.state.emailTouched;
  };

  onFormSubmit = (ev) => {
    this.setState({
      emailTouched: true,
    });

    if (this.canBeSubmitted()) {
      shoppingCartActions.emailQuoteFromWishlist(
        "",
        this.state.emailValue,
        "",
        ""
      );
    }

    ev.preventDefault();
    ev.stopPropagation();
  };

  onRequestCloseModal = () => {
    shoppingCartActions.closeEmailWishlistModal();
  };

  onChange = () => {
    this.setState({
      emailHasBeenSent: shoppingCartStore.data.get(
        "emailWishlistModalEmailSent"
      ),
      emailHasFailed: shoppingCartStore.data.get(
        "emailWishlistModalEmailFailed"
      ),
    });
  };
}

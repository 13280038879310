import React from 'react';
import { languageStore } from '../stores/LanguageStore.js';
import _ from 'lodash';
import Moment from 'moment';
import DotAccess from 'dot-access';
import Numeral from 'numeral';

const DATETIME_FORMAT = 'MMMM Do YYYY, h:mm:ss a';

Numeral.language('fr-FR', {
  delimiters: {
    thousands: ' ',
    decimal: ','
  },
  currency: {
    symbol: '$'
  }
});

Numeral.language('en-CA', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  currency: {
    symbol: '$'
  }
});

export class I18nComponent extends React.Component {
  
  t(key, lang) {
    return DotAccess.get(languageStore.getMessages(lang), key);
  }

  tp(key, value, lang) {
    const translate = DotAccess.get(languageStore.getMessages(lang), key);

    if (parseInt(value) === 1) {
      return translate.singular.replace('{}', value);
    } else {
      return translate.plural.replace('{}', value);
    }
  }

  td(value) {
    if (value && value[languageStore.data.lang]) {
      return value[languageStore.data.lang];
    } else {
      return '';
    }
  }

  c(value) {
    const locale = languageStore.getLocale();
    Numeral.language(locale);

    switch (locale) {
      case 'en-CA':
        if (_.isNumber(value) && !isNaN(value)) {
          return Numeral(value).format('$0.00');
        } else {
          return value;
        }
      case 'fr-FR':
        if (_.isNumber(value) && !isNaN(value)) {
          return Numeral(value).format('0.00$');
        } else {
          return value;
        }
      default:
        throw new Error('Not supported locale ' + locale);
    }
  }

  dt(datetime) {
    if (Moment.isMoment(datetime)) {
      return datetime.format(DATETIME_FORMAT);
    } else {
      return datetime;
    }
  }

  n(number) {
    return number;
  }

  render() {
    // This component doesn't render anything itself,
    // it just provides methods that other components can use
    return null;
  }
}


import { applicationStore } from "../../stores/ApplicationStore.js";

import VehicleSelectionCategories from "./VehicleSelectionCategories.jsx";
import VehicleSelectionMakes from "./VehicleSelectionMakes.jsx";
import VehicleSelectionModels from "./VehicleSelectionModels.jsx";
import VehicleSelectionTrims from "./VehicleSelectionTrims.jsx";
import VehicleSelectionTrimsRefactor from "./VehicleSelectionTrimsRefactor.jsx";
import VehicleSelectionYears from "./VehicleSelectionYears.jsx";

import { RouterStates } from "../../constants/RouterStates.js";
import { ProductTypes } from "../../constants/ProductTypes.js";
import { connect } from "react-redux";

var React = require("react");

class VehicleSelection extends React.Component {
  state = {
    initialize: true,
  };

  render() {
    switch (this.props.routerstate) {
      case RouterStates.YEAR:
        return <VehicleSelectionYears />;
      case RouterStates.MAKE:
        return <VehicleSelectionMakes />;
      case RouterStates.CATEGORY:
        return <VehicleSelectionCategories />;
      case RouterStates.MODEL:
        return <VehicleSelectionModels />;
      case RouterStates.TRIM:
        if (applicationStore.data.productType === ProductTypes.TIRES) {
          switch (applicationStore.data.defaultTemplate) {
            case "volkswagen":
              return (
                <VehicleSelectionTrimsRefactor dispatch={this.props.dispatch} />
              );
            default:
              return <VehicleSelectionTrims />;
          }
        } else {
          return <VehicleSelectionTrims />;
        }
      default:
        return <box></box>;
    }
  }
}

export default connect(null)(VehicleSelection);

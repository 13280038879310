import { productListStore } from "../../stores/ProductListStore.js";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { vehicleSelectionStore } from "../../stores/VehicleSelectionStore.js";
import { shoppingCartStore } from "../../stores/ShoppingCartStore.js";
import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";

import { Modal } from "../ux/Modal.jsx";
import { Image } from "../ux/Image.jsx";
import { ProductTypes } from "../../constants/ProductTypes.js";

import { SearchTypes } from "../../constants/SearchTypes.js";

import {
  money_format,
  getAppLanguage,
  getTrackingEventCategory,
} from "../../service/UtilityService.js";
import { renderRequiredItems } from "../../service/RenderService.js";
import { getPackageItemTypeInverse } from "../../api/RequestBuilderHelper.js";
import { getTestId, TEST_LIST } from "../../service/TestingService";
import { isUserLoggedIn } from "../../api/AuthService";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";
import { connect } from "react-redux";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var Cx = require("classnames");
var Immutable = require("immutable");

class ShoppingCartDetail extends I18nComponent {
  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("Shopping Cart Detail Modal");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Shopping Cart Detail Modal Access"
    );
    shoppingCartStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    shoppingCartStore.removeChangeListener(this.onChange);
  }

  onChange = () => {
    this.setState({
      detail: shoppingCartStore.data.get("detail"),
    });
  };

  onClickRemoveFromCart = () => {
    shoppingCartActions.removeItemFromBasket(
      this.state.detail.get("cartItemId"),
      this.state.detail.get("id"),
      this.state.detail
    );
    // this.props.dispatch(toggleDetails(false))
    shoppingCartActions.closeCartDetail();
  };

  onClickRemoveRequiredItemFromCart = (item) => {
    shoppingCartActions.removeItemFromBasket(
      item.item.get("cartItemId"),
      item.item.get("id"),
      item
    );
  };

  onRequestClose = () => {
    // this.props.dispatch(toggleDetails(false))
    shoppingCartActions.closeCartDetail();
  };

  onChangeRequiredItemQuantity = (itemIndex, quantity) => {
    shoppingCartActions.changeRequiredItemQuantity(itemIndex, quantity);
  };

  onAddRequiredItemToCart = (requiredItem) => {
    var price = requiredItem.get("price");
    shoppingCartActions.addItemToShoppingCart(
      requiredItem.get("id"),
      requiredItem.get("name"),
      requiredItem.get("image"),
      price["displayPrice"],
      price["privatePrice"],
      price["promotionPricingText"],
      requiredItem.get("quantity")["id"],
      requiredItem.get("type"),
      requiredItem,
      null,
      price["pricingContextId"],
      Immutable.List.of(),
      price["displayLabour"],
      price["displayPrice"],
      false,
      false,
      "Shopping Cart Detail"
    );
  };

  openDetailPage = () => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Shopping Cart Detail Modal - GoToDetailPage"
    );
  };

  renderRequiredParts = (detail) => {
    return renderRequiredItems(
      true,
      detail.get("product").get("relatedParts"),
      this.onAddRequiredItemToCart,
      this.onClickRemoveRequiredItemFromCart,
      this.onChangeRequiredItemQuantity,
      this.onAddAllItemsToCart,
      detail.get("product").get("isPackage")
    );
  };

  onAddAllItemsToCart = () => {
    var product = this.state.detail.get("product"),
      requiredItemIdArray = [];
    product.get("relatedParts").map((requiredItem, index) => {
      if (requiredItem.get("id") > 0)
        requiredItemIdArray.push(requiredItem.get("id"));
    });
    shoppingCartActions.addAllToShoppingCart(
      product.get("id"),
      product.get("name").toJS(),
      product.get("image"),
      product.get("pricing").get("displayPrice"),
      product.get("pricing").get("privatePrice"),
      product.get("pricing").get("promotionPricingText"),
      parseInt(product.get("quantity").get("value"), 10),
      ProductTypes.ACCESSORIES,
      product,
      0,
      product.get("pricing").get("pricingContextId"),
      product.get("relatedParts"),
      product.get("pricing").get("displayLabour"),
      product.get("pricing").get("listPrice"),
      requiredItemIdArray,
      true,
      false,
      false,
      "line item"
    );
  };

  renderPromotionText = () => {
    if (this.td(this.state.detail.get("promotionText").toJS()) !== "") {
      return (
        <ul>
          <li>
            <h2>{this.td(this.state.detail.get("promotionText").toJS())}</h2>
          </li>
        </ul>
      );
    } else {
      return false;
    }
  };

  state = {
    detail: shoppingCartStore.data.get("detail"),
  };

  render() {
    var isFront = false,
      isRear = false,
      quantity = 0,
      productType = this.state.detail.get("type");
    if (productType == ProductTypes.TIRES) {
      if (
        this.state.detail.get("id") ==
        this.state.detail.get("product").get("frontTire").get("id")
      ) {
        isFront = true;
        quantity = parseFloat(
          this.state.detail
            .get("product")
            .get("frontTire")
            .get("quantity")
            .get("value")
        );
      } else if (
        this.state.detail.get("id") ==
        this.state.detail.get("product").get("rearTire").get("id")
      ) {
        isRear = true;
        quantity = parseFloat(
          this.state.detail
            .get("product")
            .get("rearTire")
            .get("quantity")
            .get("value")
        );
      }
    } else {
      quantity = parseFloat(this.state.detail.get("quantity").get("value"));
    }

    var msrp = null,
      showMsrp = false,
      dealerPrice = null,
      savings = null,
      dealerPriceTotal = null;
    if (this.state.detail) {
      var installation = 0,
        showInstallation = false;
      if (applicationStore.data.productType == ProductTypes.ACCESSORIES) {
        installation = parseFloat(this.state.detail.get("displayLabour"));
        if (installation > 0) showInstallation = true;
      }

      msrp = parseFloat(this.state.detail.get("listPrice"));
      dealerPrice = parseFloat(this.state.detail.get("displayPrice"));
      savings = (msrp - dealerPrice) * quantity;
      dealerPriceTotal =
        parseFloat(this.state.detail.get("displayPrice")) * quantity;
      if (installation > 0)
        dealerPriceTotal =
          parseFloat(this.state.detail.get("displayPrice")) * quantity +
          parseFloat(installation) * quantity;
      showMsrp =
        applicationStore.data.dealerShowMsrp ||
        applicationStore.data.useAlternateTireProductListTile
          ? true
          : false;

      var youSaveClassName = "article-pricing-value";
      var youSaveTextClassName = "article-pricing-title";
      if (msrp > dealerPrice) {
        youSaveClassName = "article-pricing-value-has-savings";
        youSaveTextClassName = "article-pricing-title-has-savings";
      } else {
        savings = 0;
        showMsrp = false;
      }

      msrp = money_format(msrp);
      dealerPrice = money_format(dealerPrice);
      installation = money_format(installation);
      savings = money_format(savings);
      dealerPriceTotal = money_format(dealerPriceTotal);
    }

    var getQuoteButton = false;
    if (applicationStore.data.useCartReviewPage) {
      getQuoteButton = (
        <li>
          <a
            id={getTestId(TEST_LIST.PRODUCT.BUTTON, "continue")}
            onClick={this.onRequestClose}
            className="btn article-actions-get-quote continue shopping-cart"
          >
            {this.t("common.continue")}
          </a>
        </li>
      );
    } else {
      getQuoteButton = (
        <li>
          <a
            id={getTestId(TEST_LIST.PRODUCT.BUTTON, "getquote")}
            href={shoppingCartStore.getQuoteLink()}
            onClick={this.openDetailPage}
            target="_blank"
            className="btn article-actions-get-quote"
          >
            {this.t("cart.getQuote")}
          </a>
        </li>
      );
    }

    var pricingNote = false;
    switch (productType) {
      case ProductTypes.TIRES:
        pricingNote =
          applicationStore.data.dealerTirePricingNote[getAppLanguage()];
        break;
      case ProductTypes.ALLOY_WHEELS:
        pricingNote =
          applicationStore.data.dealerWheelPricingNote[getAppLanguage()];
        break;
      case ProductTypes.ACCESSORIES:
        pricingNote =
          applicationStore.data.dealerAccessoryPricingNote[getAppLanguage()];
        break;
      case ProductTypes.PACKAGES:
        pricingNote =
          applicationStore.data.dealerPackagePricingNote[getAppLanguage()];
        break;
    }

    var description = false;
    if (this.state.detail.get("product").get("description")) {
      description =
        this.state.detail
          .get("product")
          .get("description")
          .get(getAppLanguage()) !== "" ? (
          <ul className="article-info-warranty">
            <h2>{this.t("product.description")}</h2>
            <hr />
            <div>
              <span>
                <p
                  dangerouslySetInnerHTML={{
                    __html: this.state.detail
                      .get("product")
                      .get("description")
                      .get(getAppLanguage()),
                  }}
                ></p>
              </span>
            </div>
          </ul>
        ) : (
          false
        );
    }

    var itemInfoDisplayLeft = false,
      itemInfoDisplayRight = false;
    if (productType == ProductTypes.TIRES) {
      var runFlatClass = "icon-run-flat-en";
      var runFlatImgSrc = "/static/img/icon_run_flat_en.png";
      if (getAppLanguage() === "fr") {
        runFlatClass = "icon-run-flat-fr";
        runFlatImgSrc = "/static/img/icon_run_flat_fr.png";
      }
      var runFlatImg = (
        <img
          className="run-flat"
          src={runFlatImgSrc}
          alt={this.t("product.runFlat")}
        ></img>
      );

      var image = false;
      switch (this.state.detail.get("product").get("tireSeason")) {
        case "0":
          image = (
            <Image
              className="filter-seasonal-icon"
              src="/static/img/winter.png"
              alt="Icon Season"
            />
          );
          break;
        case "1":
          image = (
            <Image
              className="filter-seasonal-icon"
              src="/static/img/allseason.png"
              alt="Icon Season"
            />
          );
          break;
        case "2":
          image = (
            <Image
              className="filter-seasonal-icon"
              src="/static/img/summer.png"
              alt="Icon Season"
            />
          );
          break;
      }

      var featuredPositionName;
      if (
        this.state.detail.get("product").get("featured") &&
        this.state.detail.get("product").get("position")
      ) {
        switch (this.state.detail.get("product").get("position")) {
          case 1:
            featuredPositionName = (
              <span className="featured-text">{this.t("product.good")}</span>
            );
            break;
          case 2:
            featuredPositionName = (
              <span className="featured-text">{this.t("product.better")}</span>
            );
            break;
          case 3:
            featuredPositionName = (
              <span className="featured-text">{this.t("product.best")}</span>
            );
            break;
          default:
            break;
        }
      } else {
        featuredPositionName = false;
      }

      var originalEquipmentText, originalEquipmentIcon;
      if (!!this.state.detail.get("product").get("originalEquipment")) {
        if (applicationStore.data.enableMercedesFeatures && isUserLoggedIn()) {
          originalEquipmentText = false;
          originalEquipmentIcon = (
            <Image
              className="approved-tire-icon"
              src="/static/img/mercedes/approved_tire_icon.png"
              alt="Icon Approved Tire"
            />
          );
        } else if (!applicationStore.data.enableMercedesFeatures) {
          originalEquipmentText = (
            <span className="original-text">
              {this.t("product.approvedTire")}
            </span>
          );
          originalEquipmentIcon = false;
        } else {
          originalEquipmentText = false;
          originalEquipmentIcon = false;
        }
      } else {
        originalEquipmentText = false;
        originalEquipmentIcon = false;
      }

      var itemWarranty = null;
      if (this.state.detail.get("product").get("frontTire").get("warranty")) {
        this.state.detail
          .get("product")
          .get("frontTire")
          .get("warranty")
          .forEach(function (warranty) {
            if (
              applicationStore.data.dealerInfo &&
              applicationStore.data.dealerInfo.country &&
              getAppLanguage()
            ) {
              if (
                warranty.get("warrantyCountryCode") &&
                String(warranty.get("warrantyCountryCode")).toUpperCase() ==
                  applicationStore.data.dealerInfo.country &&
                warranty.get("warrantyLanguageCode") &&
                String(warranty.get("warrantyLanguageCode")).toLowerCase() ==
                  getAppLanguage()
              ) {
                itemWarranty = warranty.get("warrantyText");
              }
            }
          });
      }

      itemWarranty = itemWarranty
        ? itemWarranty
        : this.t("product.warrantyMissing");

      var plyRatingFront = false;
      if (
        this.state.detail.get("product").get("frontTire") &&
        this.state.detail.get("product").get("frontTire").get("plyRating")
      ) {
        plyRatingFront =
          " / " +
          this.state.detail.get("product").get("frontTire").get("plyRating");
      }

      var plyRatingRear = false;
      if (
        this.state.detail.get("product").get("rearTire") &&
        this.state.detail.get("product").get("rearTire").get("plyRating")
      ) {
        plyRatingRear =
          " / " +
          this.state.detail.get("product").get("rearTire").get("plyRating");
      }

      var recommendedVehicleSection = false;
      var recommendedVehicleList = false;
      if (
        this.state.detail.get("product").get("recommendedVehicleList") !==
          null &&
        typeof this.state.detail
          .get("product")
          .get("recommendedVehicleList") !== "undefined"
      ) {
        recommendedVehicleList = this.state.detail
          .get("product")
          .get("recommendedVehicleList");
      }
      if (recommendedVehicleList && recommendedVehicleList.size > 0) {
        switch (productListStore.data.searchType) {
          case SearchTypes.SEARCH_BY_VEHICLE:
          case SearchTypes.SEARCH_BY_MODEL:
            var model =
              productListStore.data.searchType === SearchTypes.SEARCH_BY_VEHICLE
                ? applicationStore.data.model
                : vehicleSelectionStore.data.model;
            recommendedVehicleSection = (
              <ul className="article-info-recommended-for">
                <h2>{this.t("product.recommendedFor")}</h2>
                <hr />
                <div className="article-info-recommended-for-body">
                  {recommendedVehicleList
                    .filter(
                      (vehicle) =>
                        vehicle.get("recommendedVehicleModelId") == model
                    )
                    .map(function (vehicle, index) {
                      return (
                        <span key={"recommended-vehicle-" + index}>
                          {vehicle
                            .get("recommendedVehicleDescription")
                            .get(getAppLanguage())}
                          <br />
                        </span>
                      );
                    })}
                </div>
                {!applicationStore.data.enableMercedesFeatures ? (
                  <div className="article-info-recommended-show-more">
                    <div
                      className="article-info-recommended-show-more-button"
                      id="recommended-show-more-button"
                    >
                      <button
                        className={setAdobeLaunchClass(
                          "btn show-more-button",
                          ADOBELAUNCHER_CLASS.BUTTON.LINK
                        )}
                        data-dtm={setAdobeLaunchDTM(
                          "",
                          ADOBELAUNCHER_DTM.MYQUOTEMODAL.DETAIL
                        )}
                        onClick={this.expandRecommendedVehicleSection}
                      >
                        {this.t("common.showMore")}
                      </button>
                    </div>
                    <div
                      className="article-info-recommended-for-body"
                      id="recommended-list-show-more"
                    >
                      {recommendedVehicleList
                        .filter(
                          (vehicle) =>
                            vehicle.get("recommendedVehicleModelId") != model
                        )
                        .map(function (vehicle) {
                          return (
                            <span>
                              {vehicle
                                .get("recommendedVehicleDescription")
                                .get(getAppLanguage())}
                              <br />
                            </span>
                          );
                        })}
                      <button
                        className={setAdobeLaunchClass(
                          "btn show-more-button",
                          ADOBELAUNCHER_CLASS.BUTTON.LINK
                        )}
                        data-dtm={setAdobeLaunchDTM(
                          "",
                          ADOBELAUNCHER_DTM.MYQUOTEMODAL.DETAIL
                        )}
                        onClick={this.collapseRecommendedVehicleSection}
                      >
                        {this.t("common.showLess")}
                      </button>
                    </div>
                  </div>
                ) : (
                  false
                )}
              </ul>
            );
            break;
          case SearchTypes.SEARCH_BY_CHASSIS:
          case SearchTypes.SEARCH_BY_TIRESIZE:
          case SearchTypes.SEARCH_BY_PARTNUMBER:
            recommendedVehicleSection = (
              <ul className="article-info-recommended-for">
                <h2>{this.t("product.recommendedFor")}</h2>
                <hr />
                <div className="article-info-recommended-for-body">
                  {recommendedVehicleList.map(function (vehicle, index) {
                    return (
                      <span key={"recommended-vehicle-" + index}>
                        {vehicle
                          .get("recommendedVehicleDescription")
                          .get(getAppLanguage())}
                        <br />
                      </span>
                    );
                  })}
                </div>
              </ul>
            );
            break;
        }
      }

      var priceLabel = this.t("product.retailPrice");
      if (
        applicationStore.data.dealerSite &&
        applicationStore.data.dealerSellingPriceLabel[
          applicationStore.data.productType
        ][getAppLanguage()]
      ) {
        priceLabel =
          applicationStore.data.dealerSellingPriceLabel[
            applicationStore.data.productType
          ][getAppLanguage()];
      }

      var imageAlt = this.state.detail
        .get("product")
        .get("frontTire")
        .get("partNumber")
        ? this.state.detail.get("product").get("name") +
          " " +
          this.state.detail.get("product").get("frontTire").get("partNumber")
        : this.state.detail.get("product").get("name") +
          " " +
          this.state.detail
            .get("product")
            .get("frontTire")
            .get("nationalPartNumber");

      itemInfoDisplayLeft = (
        <div className="main-left">
          <ul className="article-image-container">
            <li className="article-image">
              <div className="image-main">
                <Image
                  src={this.state.detail.get("product").get("largeImage")}
                  alt={"Image Part " + imageAlt}
                />
              </div>
              {featuredPositionName}
              {originalEquipmentText}
              {isFront &&
              this.state.detail
                .get("product")
                .get("frontTire")
                .get("runFlat") === "1" ? (
                <div>
                  <i className={runFlatClass} title={this.t("product.runFlat")}>
                    {" "}
                    {runFlatImg}{" "}
                  </i>
                </div>
              ) : (
                false
              )}
              {isRear &&
              this.state.detail
                .get("product")
                .get("rearTire")
                .get("runFlat") === "1" ? (
                <div>
                  <i className={runFlatClass} title={this.t("product.runFlat")}>
                    {" "}
                    {runFlatImg}{" "}
                  </i>
                </div>
              ) : (
                false
              )}
              <div className="image-brand-div">
                <Image
                  src={this.state.detail.get("product").get("brandImage")}
                  className="image-brand"
                  alt={"Image Brand " + this.state.detail.get("manufacturer")}
                />
              </div>
            </li>
          </ul>
          <ul className="article-pricing">
            {showMsrp ? (
              <li className="article-pricing-li">
                <span className="article-pricing-title">
                  {this.t("product.msrp") + "*:"}
                </span>
                <span className="article-pricing-value">{msrp}</span>
              </li>
            ) : (
              false
            )}
            <li className="article-pricing-li">
              <span className="article-pricing-title">{priceLabel + ":"}</span>
              <span className="article-pricing-value">{dealerPrice}</span>
            </li>
            {showMsrp ? (
              <li className="article-pricing-li savings">
                <span className={youSaveTextClassName}>
                  {this.t("product.totalSavings") + ":"}
                </span>
                <span className={youSaveClassName}>{savings}</span>
              </li>
            ) : (
              false
            )}
            <li className="article-pricing-li">
              <span className="article-pricing-title">
                {this.t("product.quantity") + ":"}
              </span>
              <span className="article-pricing-value">
                {this.state.detail.get("quantity").get("value")}
              </span>
            </li>
            <li className="article-pricing-line">
              <hr />
            </li>
            <li className="article-pricing-li">
              <span className="article-pricing-title">
                {this.t("product.dealerPriceTotal") + ":"}
              </span>
              <span className="article-pricing-value value-price-total">
                {dealerPriceTotal}
              </span>
            </li>
          </ul>
          {pricingNote ? (
            <ul className="dealer-legal-text">
              <li>
                <div>
                  <span>
                    <p dangerouslySetInnerHTML={{ __html: pricingNote }}></p>
                  </span>
                </div>
              </li>
            </ul>
          ) : (
            false
          )}
          <ul className="article-actions">
            {applicationStore.data.useCartReviewPage ? (
              <li>
                <button
                  id={getTestId(TEST_LIST.PRODUCT.BUTTON, "removefromwishlist")}
                  onClick={this.onClickRemoveFromCart}
                  className={setAdobeLaunchClass(
                    "btn",
                    ADOBELAUNCHER_CLASS.BUTTON.LINK
                  )}
                  data-dtm={setAdobeLaunchDTM(
                    "",
                    ADOBELAUNCHER_DTM.MYQUOTEMODAL.DETAIL
                  )}
                >
                  {this.t("product.removeFromWishlist")}
                </button>
              </li>
            ) : (
              <li>
                <button
                  id={getTestId(TEST_LIST.PRODUCT.BUTTON, "removefromquote")}
                  onClick={this.onClickRemoveFromCart}
                  className={setAdobeLaunchClass(
                    "btn",
                    ADOBELAUNCHER_CLASS.BUTTON.LINK
                  )}
                  data-dtm={setAdobeLaunchDTM(
                    "",
                    ADOBELAUNCHER_DTM.MYQUOTEMODAL.DETAIL
                  )}
                >
                  {this.t("product.removeFromQuote")}
                </button>
              </li>
            )}
            {getQuoteButton}
          </ul>
        </div>
      );

      itemInfoDisplayRight = (
        <div className="main-right">
          <ul>
            <li className="title-container">
              <h1>
                {this.td(this.state.detail.get("name").toJS())}
                {originalEquipmentIcon}
              </h1>
            </li>
          </ul>
          {this.renderPromotionText()}
          <ul className="article-info cart">
            {isFront &&
            (applicationStore.data.dealerSite || isUserLoggedIn()) ? (
              <li>
                <span className="article-info-title">
                  {this.t("product.partNumber") + ":"}
                </span>
                <span className="article-info-value">
                  {this.state.detail
                    .get("product")
                    .get("frontTire")
                    .get("partNumber")}
                </span>
              </li>
            ) : (
              false
            )}
            {isFront &&
            (!applicationStore.data.dealerSite || isUserLoggedIn()) ? (
              this.state.detail
                .get("product")
                .get("frontTire")
                .get("nationalPartNumber") ? (
                <li>
                  <span className="article-info-title">
                    {this.t("product.nationalPartNumber") + ":"}
                  </span>
                  <span className="article-info-value">
                    {this.state.detail
                      .get("product")
                      .get("frontTire")
                      .get("nationalPartNumber")}
                  </span>
                </li>
              ) : (
                false
              )
            ) : (
              false
            )}
            {isFront ? (
              <li>
                <span className="article-info-title">
                  {this.t("product.loadSpeedPly") + ":"}
                </span>
                <span className="article-info-value">
                  {this.state.detail
                    .get("product")
                    .get("frontTire")
                    .get("loadRating")}{" "}
                  /{" "}
                  {this.state.detail
                    .get("product")
                    .get("frontTire")
                    .get("speedRating")}
                  {plyRatingFront}
                </span>
              </li>
            ) : (
              false
            )}
            {isFront ? (
              <li>
                <span className="article-info-title">
                  {this.t("product.tireSizeAndSeason") + ":"}
                </span>
                <span className="article-info-value">
                  {this.state.detail
                    .get("product")
                    .get("frontTire")
                    .get("tireSize")}{" "}
                  {image}
                </span>
              </li>
            ) : (
              false
            )}

            {isRear &&
            (applicationStore.data.dealerSite || isUserLoggedIn()) ? (
              this.state.detail.get("product").get("rearTire") ? (
                <li>
                  <span className="article-info-title">
                    {this.t("product.partNumber") + ":"}
                  </span>
                  <span className="article-info-value">
                    {this.state.detail
                      .get("product")
                      .get("rearTire")
                      .get("partNumber")}
                  </span>
                </li>
              ) : (
                false
              )
            ) : (
              false
            )}
            {isRear &&
            (!applicationStore.data.dealerSite || isUserLoggedIn()) ? (
              this.state.detail.get("product").get("rearTire") ? (
                this.state.detail
                  .get("product")
                  .get("rearTire")
                  .get("nationalPartNumber") ? (
                  <li>
                    <span className="article-info-title">
                      {this.t("product.nationalPartNumber") + ":"}
                    </span>
                    <span className="article-info-value">
                      {this.state.detail
                        .get("product")
                        .get("rearTire")
                        .get("nationalPartNumber")}
                    </span>
                  </li>
                ) : (
                  false
                )
              ) : (
                false
              )
            ) : (
              false
            )}
            {isRear ? (
              this.state.detail.get("product").get("rearTire") ? (
                <li>
                  <span className="article-info-title">
                    {this.t("product.loadSpeedPly") + ":"}
                  </span>
                  <span className="article-info-value">
                    {this.state.detail
                      .get("product")
                      .get("rearTire")
                      .get("loadRating")}{" "}
                    /{" "}
                    {this.state.detail
                      .get("product")
                      .get("rearTire")
                      .get("speedRating")}
                    {plyRatingRear}
                  </span>
                </li>
              ) : (
                false
              )
            ) : (
              false
            )}
            {isRear ? (
              this.state.detail.get("product").get("rearTire") ? (
                <li>
                  <span className="article-info-title">
                    {this.t("product.tireSize") + ":"}
                  </span>
                  <span className="article-info-value">
                    {this.state.detail
                      .get("product")
                      .get("rearTire")
                      .get("tireSize")}
                  </span>
                </li>
              ) : (
                false
              )
            ) : (
              false
            )}
          </ul>
          {recommendedVehicleSection}
          {description}
          <ul className="article-info-warranty">
            <h2>{this.t("product.warranty")}</h2>
            <hr />
            <div>
              <p dangerouslySetInnerHTML={{ __html: itemWarranty }}></p>
            </div>
          </ul>
        </div>
      );
    } else {
      var priceLabel = this.t("product.retailPrice");
      if (
        applicationStore.data.dealerSite &&
        applicationStore.data.dealerSellingPriceLabel[
          applicationStore.data.productType
        ][getAppLanguage()]
      ) {
        priceLabel =
          applicationStore.data.dealerSellingPriceLabel[
            applicationStore.data.productType
          ][getAppLanguage()];
      }

      switch (productType) {
        case ProductTypes.ALLOY_WHEELS:
          var imageAlt = this.state.detail
            .get("product")
            .get("frontWheel")
            .get("partNumber")
            ? this.td(this.state.detail.get("product").get("name").toJS()) +
              " " +
              this.state.detail
                .get("product")
                .get("frontWheel")
                .get("partNumber")
            : this.td(this.state.detail.get("product").get("name").toJS()) +
              " " +
              this.state.detail
                .get("product")
                .get("frontWheel")
                .get("nationalPartNumber");
          var image = this.state.detail.get("product").get("image");
          var imageClass = "image-main";
          break;
        case ProductTypes.ACCESSORIES:
          var imageAlt = this.state.detail.get("product").get("partNumber")
            ? this.td(this.state.detail.get("product").get("name").toJS()) +
              " " +
              this.state.detail.get("product").get("partNumber")
            : this.td(this.state.detail.get("product").get("name").toJS()) +
              " " +
              this.state.detail.get("product").get("nationalPartNumber");
          var image = this.state.detail.get("product").get("image");
          var imageClass = "image-main";
          break;
        case ProductTypes.PACKAGES:
          var imageAlt = this.state.detail.get("product").get("partNumber")
            ? this.td(this.state.detail.get("name").toJS()) +
              " " +
              this.state.detail.get("product").get("partNumber")
            : this.td(this.state.detail.get("name").toJS()) +
              " " +
              this.state.detail.get("product").get("nationalPartNumber");
          var image = this.state.detail.get("image");
          var imageClass = "image-main package-image";
          break;
      }

      itemInfoDisplayLeft = (
        <div className="main-left">
          <ul className="article-image-container">
            <li className="article-image">
              <div className={imageClass}>
                <Image src={image} alt={"Image Part " + imageAlt} />
              </div>
            </li>
          </ul>
          <ul className="article-pricing">
            {showMsrp ? (
              <li className="article-pricing-li">
                <span className="article-pricing-title">
                  {this.t("product.msrp") + "*:"}
                </span>
                <span className="article-pricing-value">{msrp}</span>
              </li>
            ) : (
              false
            )}
            <li className="article-pricing-li">
              <span className="article-pricing-title">{priceLabel + ":"}</span>
              <span className="article-pricing-value">{dealerPrice}</span>
            </li>
            {showMsrp ? (
              <li className="article-pricing-li savings">
                <span className={youSaveTextClassName}>
                  {this.t("product.totalSavings") + ":"}
                </span>
                <span className={youSaveClassName}>{savings}</span>
              </li>
            ) : (
              false
            )}
            {showInstallation ? (
              <li className="article-pricing-li">
                <span className="article-pricing-title">
                  {this.t("product.installation") + ":"}
                </span>
                <span className="article-pricing-value">{installation}</span>
              </li>
            ) : (
              false
            )}
            <li className="article-pricing-li">
              <span className="article-pricing-title">
                {this.t("product.quantity") + ":"}
              </span>
              <span className="article-pricing-value">
                {this.state.detail.get("quantity").get("value")}
              </span>
            </li>
            <li className="article-pricing-line">
              <hr />
            </li>
            <li className="article-pricing-li">
              <span className="article-pricing-title">
                {this.t("product.dealerPriceTotal") + ":"}
              </span>
              <span className="article-pricing-value">{dealerPriceTotal}</span>
            </li>
          </ul>
          {pricingNote ? (
            <ul className="dealer-legal-text">
              <li>
                <div>
                  <span>
                    <p dangerouslySetInnerHTML={{ __html: pricingNote }}></p>
                  </span>
                </div>
              </li>
            </ul>
          ) : (
            false
          )}
          <ul className="article-actions">
            {applicationStore.data.useCartReviewPage ? (
              <li>
                <button
                  id={getTestId(TEST_LIST.PRODUCT.BUTTON, "removefromwishlist")}
                  onClick={this.onClickRemoveFromCart}
                  className={setAdobeLaunchClass(
                    "btn",
                    ADOBELAUNCHER_CLASS.BUTTON.LINK
                  )}
                  data-dtm={setAdobeLaunchDTM(
                    "",
                    ADOBELAUNCHER_DTM.MYQUOTEMODAL.DETAIL
                  )}
                >
                  {this.t("product.removeFromWishlist")}
                </button>
              </li>
            ) : (
              <li>
                <button
                  id={getTestId(TEST_LIST.PRODUCT.BUTTON, "removefromquote")}
                  onClick={this.onClickRemoveFromCart}
                  className={setAdobeLaunchClass(
                    "btn",
                    ADOBELAUNCHER_CLASS.BUTTON.LINK
                  )}
                  data-dtm={setAdobeLaunchDTM(
                    "",
                    ADOBELAUNCHER_DTM.MYQUOTEMODAL.DETAIL
                  )}
                >
                  {this.t("product.removeFromQuote")}
                </button>
              </li>
            )}
            {getQuoteButton}
          </ul>
        </div>
      );

      if (productType == ProductTypes.ALLOY_WHEELS) {
        itemInfoDisplayRight = (
          <div className="main-right">
            <ul>
              <li className="title-container">
                <h1>{this.td(this.state.detail.get("name").toJS())}</h1>
              </li>
            </ul>
            {this.renderPromotionText()}
            <ul className="article-info cart">
              {applicationStore.data.dealerSite || isUserLoggedIn() ? (
                <li>
                  <span className="article-info-title">
                    {this.t("product.partNumber") + ":"}
                  </span>
                  <span className="article-info-value">
                    {this.state.detail
                      .get("product")
                      .get("frontWheel")
                      .get("partNumber")}
                  </span>
                </li>
              ) : (
                false
              )}
              {!applicationStore.data.dealerSite || isUserLoggedIn() ? (
                this.state.detail
                  .get("product")
                  .get("frontWheel")
                  .get("nationalPartNumber") ? (
                  <li>
                    <span className="article-info-title">
                      {this.t("product.nationalPartNumber") + ":"}
                    </span>
                    <span className="article-info-value">
                      {this.state.detail
                        .get("product")
                        .get("frontWheel")
                        .get("nationalPartNumber")}
                    </span>
                  </li>
                ) : (
                  false
                )
              ) : (
                false
              )}
              <li>
                <span className="article-info-title">
                  {this.t("product.wheelDiameter") + ":"}
                </span>
                <span className="article-info-value">
                  {this.state.detail
                    .get("product")
                    .get("frontWheel")
                    .get("diameter")}
                </span>
              </li>
              {this.state.detail.get("product").get("rearWheel") ? (
                <li>
                  <span className="article-info-title">
                    {this.t("product.partNumber") + ":"}
                  </span>
                  <span className="article-info-value">
                    {this.state.detail
                      .get("product")
                      .get("rearTire")
                      .get("partNumber")}
                  </span>
                </li>
              ) : (
                false
              )}
              {this.state.detail.get("product").get("rearWheel") ? (
                this.state.detail
                  .get("product")
                  .get("rearWheel")
                  .get("nationalPartNumber") ? (
                  <li>
                    <span className="article-info-title">
                      {this.t("product.nationalPartNumber") + ":"}
                    </span>
                    <span className="article-info-value">
                      {this.state.detail
                        .get("product")
                        .get("rearTire")
                        .get("nationalPartNumber")}
                    </span>
                  </li>
                ) : (
                  false
                )
              ) : (
                false
              )}
              {this.state.detail.get("product").get("rearWheel") ? (
                <li>
                  <span className="article-info-title">
                    {this.t("product.diameter") + ":"}
                  </span>
                  <span className="article-info-value">
                    {this.state.detail
                      .get("product")
                      .get("rearTire")
                      .get("diameter")}
                  </span>
                </li>
              ) : (
                false
              )}
            </ul>
            {description}
          </div>
        );
      } else if (productType == ProductTypes.ACCESSORIES) {
        itemInfoDisplayRight = (
          <div className="main-right">
            <ul>
              <li className="title-container">
                <h1>{this.td(this.state.detail.get("name").toJS())}</h1>
              </li>
            </ul>
            {this.renderPromotionText()}
            <ul className="article-info cart accessory-info">
              {applicationStore.data.dealerSite || isUserLoggedIn() ? (
                <li>
                  <span className="article-info-title">
                    {this.t("product.partNumber")}:
                  </span>
                  <span className="article-info-value">
                    {this.state.detail.get("product").get("partNumber")}
                  </span>
                </li>
              ) : (
                false
              )}
              {!applicationStore.data.dealerSite || isUserLoggedIn() ? (
                this.state.detail.get("product").get("nationalPartNumber") ? (
                  <li>
                    <span className="article-info-title">
                      {this.t("product.nationalPartNumber")}:
                    </span>
                    <span className="article-info-value">
                      {this.state.detail
                        .get("product")
                        .get("nationalPartNumber")}
                    </span>
                  </li>
                ) : (
                  false
                )
              ) : (
                false
              )}
              <li>
                <span className="article-info-title">
                  {this.t("product.requiresInstallation")}:
                </span>
                <span className="article-info-value">
                  {this.state.detail.get("product").get("requiresInstallation")
                    ? this.t("common.yes")
                    : this.t("common.no")}
                </span>
              </li>
              <li>
                <span className="article-info-title">
                  {this.t("product.categories")}:
                </span>
                <span className="article-info-value">
                  {this.state.detail
                    .get("product")
                    .get("categories")
                    .map(
                      function (category, index) {
                        return (
                          <span key={"product-category-" + index}>
                            {this.td(category.toJS())}
                          </span>
                        );
                      }.bind(this)
                    )
                    .toArray()}
                </span>
              </li>
            </ul>
            {this.renderRequiredParts(this.state.detail)}
          </div>
        );
      } else if (productType == ProductTypes.PACKAGES) {
        var hidePackagePartNumbers = false;
        if (
          !applicationStore.data.dealerSite &&
          applicationStore.data.enableMercedesFeatures
        ) {
          hidePackagePartNumbers = true;
        }

        var description =
          this.state.detail
            .get("product")
            .get("description")
            .get(getAppLanguage()) !== "" ? (
            <ul className="article-info-warranty">
              <h2>{this.t("product.description")}</h2>
              <hr />
              <div>
                <span>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.state.detail
                        .get("product")
                        .get("description")
                        .get(getAppLanguage()),
                    }}
                  ></p>
                </span>
              </div>
            </ul>
          ) : (
            false
          );

        itemInfoDisplayRight = (
          <div className="main-right">
            <ul>
              <li className="title-container">
                <h1>
                  {this.td(
                    this.state.detail.get("product").get("title").toJS()
                  )}
                </h1>
              </li>
            </ul>
            {this.renderPromotionText()}
            <ul className="article-info cart">
              {!hidePackagePartNumbers ? (
                <li>
                  <span className="article-info-title">
                    {this.t("product.packagePartNumber")}:
                  </span>
                  <span className="article-info-value">
                    {this.state.detail.get("product").get("partNumber")}
                  </span>
                </li>
              ) : (
                false
              )}
              <li>
                <span className="article-info-title">
                  {this.t("product.packageItems")}:
                </span>
              </li>
              {this.state.detail
                .get("product")
                .get("items")
                .map(function (item, index) {
                  return (
                    <li
                      key={"part-items-" + index}
                      className="article-package-items"
                    >
                      <span className="bold">
                        {item.get("quantity")}{" "}
                        {getPackageItemTypeInverse(
                          item.get("partType"),
                          getAppLanguage(),
                          item.get("typeDescription")
                            ? item.get("typeDescription").get(getAppLanguage())
                            : ""
                        )}
                      </span>
                      <span>
                        {" "}
                        - {this.td(item.get("title").toJS())}{" "}
                        {item.get("partNumber")
                          ? this.t("product.partNumber") +
                            ": " +
                            item.get("partNumber")
                          : false}
                      </span>
                      {item.get("partDescription").get(getAppLanguage()) !==
                      "" ? (
                        <span className="description">
                          {this.t("product.description") + ": "}{" "}
                          {item.get("partDescription").get(getAppLanguage())}
                        </span>
                      ) : (
                        false
                      )}
                    </li>
                  );
                }, this)
                .toArray()}
            </ul>
            {description}
          </div>
        );
      }
    }

    var requiresCloseButton = true;
    switch (applicationStore.data.defaultTemplate) {
      case "mercedes":
      case "mercedes_2022":
      case "mercedestransition":
        requiresCloseButton = false;
        break;
      default:
        break;
    }

    var hasRequired =
      this.state.detail.get("requiredItems") !== null &&
      typeof this.state.detail.get("requiredItems") !== "undefined" &&
      this.state.detail.get("requiredItems").count() > 0;
    var modalClass = Cx({
      "modal-content": true,
      "shopping-cart-detail-list": true,
      "modal-detail": true,
      "has-required": hasRequired,
      accessory: productType == ProductTypes.ACCESSORIES,
    });
    var isStaggered = this.state.detail.get("staggered");
    var wrapperClass = Cx({
      wrapper: true,
      "shopping-cart-detail": true,
      "has-required": hasRequired,
      "is-staggered": isStaggered,
    });

    var shoppingCartDetail = (
      <Modal
        refactor={true}
        requestUnmount={this.onRequestClose}
        datadtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.MYQUOTEMODAL.DETAIL)}
      >
        <div className={wrapperClass}>
          <div className={modalClass}>
            <div className="main">
              {itemInfoDisplayLeft}
              {itemInfoDisplayRight}
            </div>
          </div>
        </div>
      </Modal>
    );

    return shoppingCartDetail;
  }

  expandRecommendedVehicleSection = () => {
    document.getElementById("recommended-list-show-more").style.display =
      "block";
    document.getElementById("recommended-show-more-button").style.display =
      "none";
  };

  collapseRecommendedVehicleSection = () => {
    document.getElementById("recommended-list-show-more").style.display =
      "none";
    document.getElementById("recommended-show-more-button").style.display =
      "block";
  };
}

export default connect(null)(ShoppingCartDetail);

import { createSlice } from "@reduxjs/toolkit";


const modal = createSlice({
    name:'modal',
    initialState:{
        modal:null,
    },
    reducers:{
        requestUnmount : (state,{payload})=>{
            state.modal = 'pending'
        },
        modalMounted:(state,{payload})=>{
            state.modal = 'success'
        },
        modalUnmounted:(state,{payload})=>{
            state.modal = null
        },
    }
})

export const {
    requestUnmount,
    modalMounted,
    modalUnmounted
} = modal.actions
export default modal.reducer;
import { applicationStore } from '../stores/ApplicationStore.js'

import { ProductTypes } from '../constants/ProductTypes.js'
import { RouterStates } from '../constants/RouterStates.js'

import { sizeOf } from '../service/UtilityService.js'

var _ = require('lodash');

export var VehicleSelectionSkippingService = {

  getRouterStateForLoadedYears: function(years, forward) {
    if (this.hasPayloadEmptyKeys(years) || this.hasPayloadSingleKey(years)) {
      if (forward) {
        return RouterStates.MAKE;
      } else {
        return RouterStates.HOMEPAGE;
      }
    } else {
      return RouterStates.YEAR;
    }
  },

  getSkippedYearForLoadedYears: function(years) {
    if (this.hasPayloadEmptyKeys(years)) {
      return null;
    } else if (this.hasPayloadSingleKey(years)) {
      var keys = _.keys(years);
      return {
        id: keys[0],
        name: years[keys[0]]
      };
    } else {
      return false;
    }
  },

  getRouterStateForLoadedMakes: function(primaryMakes, secondaryMakes, showSecondaryMakes, productType, forward) {
    if (this.shouldSkipMakesBecauseOfEmpty(primaryMakes, secondaryMakes, showSecondaryMakes,productType) ||
      this.shouldSkipMakesBecauseOfSingleMake(primaryMakes, secondaryMakes, showSecondaryMakes, productType)) {
      if (forward) {
        return RouterStates.CATEGORY;
      } else {
        return RouterStates.YEAR;
      }
    } else {
      return RouterStates.MAKE;
    }
  },

  getSkippedMakeForLoadedMakes: function(primaryMakes, secondaryMakes, showSecondaryMakes, productType) {
    if (this.shouldSkipMakesBecauseOfEmpty(primaryMakes, secondaryMakes, showSecondaryMakes, productType)) {
      return null;
    } else if (this.shouldSkipMakesBecauseOfSingleMake(primaryMakes, secondaryMakes, showSecondaryMakes, productType)) {
      return {
        id: primaryMakes[0].vehicleManufacturerId,
        name: primaryMakes[0].vehicleManufacturerName
      };
    } else {
      return false;
    }
  },

  getRouterStateForLoadedCategories: function(categories, forward) {

    //console.log('getRouterStateForLoadedCategories, forward: '+forward+', hasPayloadEmptyKeys: '+this.hasPayloadEmptyKeys(categories)+', hasPayloadSingleKey: '+this.hasPayloadSingleKey(categories));

    if (this.hasPayloadEmptyKeys(categories) || this.hasPayloadSingleKey(categories)) {
      if (forward) {
        return RouterStates.MODEL;
      } else {
        return RouterStates.MAKE;
      }
    } else {
      return RouterStates.CATEGORY;
    }
  },

  getSkippedCategoryForLoadedCategories: function(categories) {

    //console.log('getSkippedCategoryForLoadedCategories, hasPayloadEmptyKeys: '+this.hasPayloadEmptyKeys(categories)+', hasPayloadSingleKey: '+this.hasPayloadSingleKey(categories));

    if (this.hasPayloadEmptyKeys(categories)) {
      return null;
    } else if (this.hasPayloadSingleKey(categories)) {
      return {
        id: categories[0].vehicleCategoryId,
        name: categories[0].vehicleCategoryName
      };
    } else {
      return false;
    }
  },

  getRouterStateForLoadedModels: function(models, forward) {
    if (this.hasPayloadEmptyKeys(models) || this.hasPayloadSingleKey(models)) {
      if (forward) {
        return RouterStates.TRIM;
      } else {
        return RouterStates.CATEGORY;
      }
    } else {
      return RouterStates.MODEL;
    }
  },

  getSkippedModelForLoadedModels: function(models) {
    if (this.hasPayloadEmptyKeys(models)) {
      return null;
    } else if (this.hasPayloadSingleKey(models)) {
      return {
        id: models[0].vehicleModelId,
        name: models[0].vehicleModelName
      };
    } else {
      return false;
    }
  },

  getRouterStateForLoadedTrims: function(trims, dealerSite, forward) {
    if (this.hasPayloadEmptyKeys(trims)) {
      return this.getStateAfterTrimSelection(trims, dealerSite, forward);
    } else {
      return RouterStates.TRIM;
    }
  },

  getSkippedTrimForLoadedTrims: function(trims) {
    if (this.hasPayloadEmptyKeys(trims)) {
      return null;
    } else if (this.hasPayloadSingleKey(trims)) {
      return {
        id: trims[0].vehicleSubmodelId,
        name: trims[0].vehicleSubmodelName
      };
    } else {
      return false;
    }
  },

  getStateAfterTrimSelection: function(trims, dealerSite, forward) {
    if (applicationStore.data.enableShowSubmodelTiresizeSelection) {
      if (forward) {
        return RouterStates.PRODUCT_LIST;
      } else {
        return RouterStates.MODEL;
      }
    } else {
      if (sizeOf(trims) === 1) {
        if (forward) {
          return RouterStates.PRODUCT_LIST;
        } else {
          return RouterStates.CATEGORY;
        }
      } else {
        if (forward) {
          return RouterStates.PRODUCT_LIST;
        } else {
          return RouterStates.MODEL;
        }
      }
    }

  },

  shouldSkipMakesBecauseOfEmpty: function(primaryMakes, secondaryMakes, showSecondaryMakes, productType) {
    return (!this.showSecondaryMakes(showSecondaryMakes, productType) || secondaryMakes.length === 0) && this.hasPayloadEmptyKeys(primaryMakes);
  },

  shouldSkipMakesBecauseOfSingleMake: function(primaryMakes, secondaryMakes, showSecondaryMakes, productType) {
    return (!this.showSecondaryMakes(showSecondaryMakes, productType) || secondaryMakes.length === 0) && this.hasPayloadSingleKey(primaryMakes);
  },

  hasPayloadEmptyKeys: function(payload) {

    //console.log('hasPayloadEmptyKeys, length: '+(_.keys(payload).length)+', check: '+(_.keys(payload).length === 0));
    //console.log(payload);

    return _.keys(payload).length === 0;
  },

  hasPayloadSingleKey: function(payload) {

    //console.log('hasPayloadSingleKey, length: '+(_.keys(payload).length)+', check: '+(_.keys(payload).length === 1));
    //console.log(payload);

    return _.keys(payload).length === 1;
  },

  showSecondaryMakes: function(showSecondaryMakes, productType) {
    switch (productType) {
      case ProductTypes.TIRES:
        return showSecondaryMakes.tires;
      case ProductTypes.ALLOY_WHEELS:
        return showSecondaryMakes.wheels;
      case ProductTypes.ACCESSORIES:
        return showSecondaryMakes.accessories;
      case ProductTypes.PACKAGES:
        return showSecondaryMakes.packages;
      default:
        return false;
        //throw new Error();
    }
  },

  getSecondaryMakesGateFromDealerInfo: function(dealerInfo) {
    if (dealerInfo) {
      return {
        tires: dealerInfo.dealerTireShowSecondaryManufacturers === true,
        wheels: dealerInfo.dealerWheelShowSecondaryManufacturers === true,
        accessories: dealerInfo.dealerAccessoryShowSecondaryManufacturers === true,
        packages: dealerInfo.dealerPackageShowSecondaryManufacturers === true
      };
    } else return { tires: false, wheels: false, accessories: false, packages: false }
  },

  getSecondaryMakesGateFromNationalAccountInfo: function(nationalAccountInfo) {
    if (nationalAccountInfo) {
      return {
        tires: nationalAccountInfo.nationalTireShowSecondaryManufacturers === true,
        wheels: nationalAccountInfo.nationalWheelShowSecondaryManufacturers === true,
        accessories: nationalAccountInfo.nationalAccessoryShowSecondaryManufacturers === true,
        packages: nationalAccountInfo.nationalPackageShowSecondaryManufacturers === true
      };
    } else return { tires: false, wheels: false, accessories: false, packages: false }
  },

	getSecondaryMakesGateFromGroupAccountInfo: function(groupInfo) {
    if (groupInfo) {
      return {
        tires: groupInfo.dealerGroupTireShowSecondaryManufacturers === true,
        wheels: groupInfo.dealerGroupShowSecondaryManufacturers === true,
        accessories: groupInfo.dealerGroupShowSecondaryManufacturers === true,
        packages: groupInfo.dealerGroupShowSecondaryManufacturers === true
      };
    } else return { tires: false, wheels: false, accessories: false, packages: false }
	}

};

import { dealerSelectionStore } from "../../stores/DealerSelectionStore.js";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { applicationActions } from "../../actions/ApplicationActions.js";
import {
  isEmpty,
  sizeOf,
  cleanArray,
  getAppLanguage,
  getTrackingEventCategory,
  getTrackingEventDealer,
} from "../../service/UtilityService.js";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  enableAdobeLaunch,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";
import { leavingSiteWarningModal } from "../../redux/features/dealerSections.js";
import { defaultMapLocation } from "../../service/DealerSelectionService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var _ = require("lodash");
var Cx = require("classnames");

var GoogleMapsReact = require("google-maps-react");
var GoogleMap = null;
var GoogleMarker = null;
var GoogleInfoWindow = null;
if (GoogleMapsReact) {
  GoogleMap = GoogleMapsReact.Map;
  GoogleMarker = GoogleMapsReact.Marker;
  GoogleInfoWindow = GoogleMapsReact.InfoWindow;
}

const DEALER_CITY_SEARCH = "dealer-city-search";

export class DealerMap extends I18nComponent {
  state = {
    activeMarker: [],
    activeDealer: null,
    activeDealerHoursToggle: false,
    showInfoWindow: false,
    infoWindowChecked: false,
    lastMarker: null,
    markerFirstClick: true,
    haveSetSearchLocation: false,
    dealerSearch: dealerSelectionStore.dealerSearch,
    dealerSearchType: dealerSelectionStore.dealerSearchType,
    mapZoom: this.props.mapZoom,
    userLocation: null,
  };

  updateState = (state) => {
    this.setState(state);
  };

  componentWillMount() {
    dealerSelectionStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    dealerSelectionStore.removeChangeListener(this.onChange);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.searchFlag &&
      this.state.dealerSearch !== nextState.dealerSearch &&
      this.state.dealerSearchType === DEALER_CITY_SEARCH
    )
      this.setMapCenter();
    return this.props !== nextProps || this.state !== nextState;
  }

  componentDidUpdate(nextProps, nextState) {
    if (
      !this.state.infoWindowChecked &&
      sizeOf(this.state.activeDealer) > 0 &&
      ((this.state.showInfoWindow && !nextState.showInfoWindow) ||
        (this.state.showInfoWindow && nextState.showInfoWindow))
    ) {
      var activeDealer = this.state.activeDealer,
        onDealerSelected = this.onDealerSelected,
        onGetDirections = this.onGetDirections;

      this.setState({ infoWindowChecked: true });

      setInterval(function () {
        var selectDealer = document.getElementById(
            "on-select-dealer-" + activeDealer["id"]
          ),
          selectDealerDirections = document.getElementById(
            "on-select-dealer-directions-" + activeDealer["id"]
          ),
          closeButton =
            document.getElementsByClassName("gm-ui-hover-effect")[0];

        if (
          !isEmpty(selectDealer) &&
          typeof selectDealer.onclick !== "function"
        ) {
          selectDealer.onclick = onDealerSelected;
        }

        if (
          !isEmpty(selectDealerDirections) &&
          isEmpty(selectDealerDirections.onclick)
        ) {
          selectDealerDirections.onclick = onGetDirections;
        }

        if (
          enableAdobeLaunch() &&
          !isEmpty(closeButton) &&
          !closeButton.classList.contains(
            setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.BUTTON.CLOSE)
          )
        ) {
          closeButton.classList.add(
            setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.BUTTON.CLOSE)
          );
          closeButton.dataset.dtm = setAdobeLaunchDTM(
            "",
            ADOBELAUNCHER_DTM.RESULTS.MAP,
            activeDealer["name"]
          );
        }
      }, 250);
    }
  }

  resetActiveDealer = () => {
    this.setState({
      activeMarker: [],
      activeDealer: null,
      activeDealerHoursToggle: false,
      showInfoWindow: false,
      infoWindowChecked: false,
    });
  };

  setMapCenter = () => {
    //console.log('setMapCenter');

    if (this.refs.googleMap) {
      var map = this.refs.googleMap.map;
      if (map) {
        var dealerSearchLocation = dealerSelectionStore.dealerSearchLocation;

        //console.log('dealerSearchLocation');
        //console.log(dealerSearchLocation);

        if (dealerSearchLocation) {
          map.setCenter(dealerSearchLocation);
          if (dealerSelectionStore.mapZoom) {
            map.setZoom(dealerSelectionStore.mapZoom);
          }
        } else {
          // this.props.dispatch(dealerSearchIsEmpty(true))
          // dealerSelectionActions.dealerSearchIsEmpty();
        }
      }
    }
    this.setState({ dealerSearch: null });
  };

  onCloseInfoWindow = () => {
    this.setState({
      showInfoWindow: false,
      infoWindowChecked: false,
    });
  };

  onMarkerClickFromProps = (dealer) => {
    var id = "dealer-marker-" + dealer["id"];
    var marker = this.refs[id];
    this.onMarkerClickRefactor(dealer, marker.props, marker.marker);
  };

  onMarkerClickRefactor = (dealer, props, marker) => {
    if (this.state.markerFirstClick) {
      for (var i = 0; i < 3; i++)
        setTimeout(() => {
          this.onMarkerClick(dealer, props, marker);
        }, 10);
      this.setState({ markerFirstClick: false });
    } else {
      this.onMarkerClick(dealer, props, marker);
    }
  };

  onMarkerClick = (dealer, props, marker) => {
    if (
      this.state.activeDealer &&
      this.state.activeDealer["id"] === dealer["id"]
    ) {
      marker.setIcon(this.getMarkerIcon(!this.state.showInfoWindow));
      this.setState({ showInfoWindow: !this.state.showInfoWindow });
    } else {
      marker.setIcon(this.getMarkerIcon(true));
      if (this.state.showInfoWindow) this.onCloseInfoWindow();
      this.setActiveDealer(marker, dealer);
    }
    if (this.state.lastMarker !== null && this.state.lastMarker !== marker) {
      this.state.lastMarker.setIcon(this.getMarkerIcon(false));
    }
    this.state.lastMarker = marker;
  };

  setActiveDealer = (marker, dealer) => {
    this.setState({
      activeMarker: marker,
      activeDealer: dealer,
      activeDealerHoursToggle: false,
      showInfoWindow: true,
    });
  };

  onDealerSelected = () => {
    var dealer = this.state.activeDealer;
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Dealer Selection - DealerSelected",
      getTrackingEventDealer(dealer)
    );
    if (!applicationStore.data.groupSite) {
      // this.props.dispatch(setDealerToken(dealer))
      // applicationActions.setSelectedDealerForToken(dealer);
      applicationActions.selectedDealer(dealer["id"], dealer["season"]);
      const newDealer = dealer;
      const url = new URL(window.location.href);

      const vehicle = url.searchParams.get("vehicle");
      const parts = vehicle.split("-");
      const product = parts[0];
      const year = parts[1];
      const make = parts[2];
      const category = parts[3];
      const model = parts[4];
      const lang = parts[5];
      const zipCode = parts[6];
      const dealerId = newDealer["id"];
      let link = `/national/${
        applicationStore.data.nationalUrlCode
      }/${lang}/dealer/${dealerId}/product/${product.toUpperCase()}/type/vehicle-search/year/${year}/make/${make}/category/${category}/model/${model}`;
      window.open(link, "_self");
    } else {
      if (!dealer["isActive"]) {
        this.openLeavingSiteModal(dealer);
      } else {
        window.location.href = this.getDealerLink(dealer);
      }
    }
  };

  getDealerLink = () => {
    return (
      "/" +
      dealer["urlCode"] +
      "/" +
      getAppLanguage() +
      "/dealer/" +
      dealer["id"]
    );
  };

  openLeavingSiteModal = (dealer) => {
    this.props.dispatch(leavingSiteWarningModal(dealer));
    // dealerSelectionActions.showLeavingSiteWarningModal(dealer);
  };

  render() {
    const style = {
      position: "relative",
      left: "0px",
      right: "0px",
      bottom: "0px",
      top: "0px",
      width: "100%",
      height: "100%",
      display: "inherit",
      overflow: "hidden",
    };

    var center = null,
      zoom = this.state.mapZoom;
    if (this.state.userLocation !== null && !this.props.hasActiveDealer) {
      center = this.state.userLocation;
    } else if (this.props) {
      center = this.props.initialCenter;
    }
    if (
      this.props.visibleDealers.length > 0 &&
      this.props.visibleDealers !== null
    ) {
      let visibleDealerLocations = this.props.visibleDealers[0];
      center = {
        lat: visibleDealerLocations.lat,
        lng: visibleDealerLocations.lng,
      };
      zoom = 10;
    } else {
      center = defaultMapLocation();
    }

    return (
      <GoogleMap
        google={this.props.google}
        key="google-map"
        ref="googleMap"
        className="map"
        style={style}
        zoom={zoom}
        center={center}
      >
        {center !== null ? this.renderUserOrSearchLocation() : <div></div>}
        {this.props.visibleDealers.length > 0 ? (
          this.renderDealerMarkers()
        ) : (
          <div></div>
        )}
        {this.state.activeDealer !== null ? (
          this.renderInfoWindow()
        ) : (
          <div></div>
        )}
      </GoogleMap>
    );
  }

  renderInfoWindow = () => {
    var dealer = this.state.activeDealer;
    var marker = this.state.activeMarker;
    var showInfoWindow = this.state.showInfoWindow;
    var translate = this.t;

    var infoClassName = Cx({
      "dealer-info-section": true,
      "missing-directions": !dealer["googleMapsUrl"],
    });

    var buttonClassName = Cx({
      "dealer-info-button": true,
      french: getAppLanguage() === "fr",
    });

    return (
      <GoogleInfoWindow
        class="google-dealer-info-window"
        marker={marker}
        visible={showInfoWindow}
        onClose={this.onCloseInfoWindow}
      >
        <div className="dealer-info-window">
          <h2>{dealer["name"]}</h2>
          <div className={infoClassName}>
            <ul>
              {dealer["phone"].trim() !== "" ? (
                <l1>
                  <p>{dealer["phone"].trim()}</p>
                </l1>
              ) : (
                false
              )}
              {dealer["address"].trim() !== "" ||
              dealer["city"].trim() !== "" ? (
                <l1>
                  <p>
                    {cleanArray([
                      dealer["address"].trim(),
                      dealer["city"].trim(),
                    ]).join(", ")}
                  </p>
                </l1>
              ) : (
                false
              )}
              {dealer["postalCode"] !== "" ? (
                <l1>
                  <p>{dealer["postalCode"]}</p>
                </l1>
              ) : (
                false
              )}
            </ul>
          </div>
          <div className={buttonClassName}>
            {this.renderGetDirectionsButton(dealer)}
            {this.renderHasPricingButton(dealer)}
            {this.renderNoPricingButton(dealer)}
          </div>
        </div>
      </GoogleInfoWindow>
    );
  };

  renderHasPricingButton = (dealer) => {
    if (!dealer["hasNoPricing"]) {
      if (enableAdobeLaunch()) {
        return (
          <input
            type="button"
            className={setAdobeLaunchClass(
              "btn",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.RESULTS.MAP,
              dealer["name"]
            )}
            id={"on-select-dealer-" + dealer["id"]}
            value={this.getSelectDealerText(dealer)}
          />
        );
      } else {
        return (
          <input
            type="button"
            className="btn"
            value={this.getSelectDealerText(dealer)}
            id={"on-select-dealer-" + dealer["id"]}
          />
        );
      }
    } else {
      return false;
    }
  };

  renderGetDirectionsButton = (dealer) => {
    if (!isEmpty(dealer["googleMapsUrl"])) {
      if (enableAdobeLaunch()) {
        return (
          <input
            type="button"
            className={setAdobeLaunchClass(
              "btn-directions",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.RESULTS.MAP,
              dealer["name"]
            )}
            id={"on-select-dealer-directions-" + dealer["id"]}
            value={this.t("search.getDirections")}
          />
        );
      } else {
        return (
          <input
            type="button"
            className="btn-directions"
            value={this.t("search.getDirections")}
            id={"on-select-dealer-directions-" + dealer["id"]}
          />
        );
      }
    } else {
      return false;
    }
  };

  renderNoPricingButton = (dealer) => {
    if (dealer["hasNoPricing"]) {
      if (enableAdobeLaunch()) {
        return (
          <span
            className={setAdobeLaunchClass(
              "text-large",
              ADOBELAUNCHER_CLASS.TEXT
            )}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.RESULTS.MAP,
              dealer["name"]
            )}
          >
            {this.t("pricingNotAvailable")}
          </span>
        );
      } else {
        return (
          <span className="text-large">{this.t("pricingNotAvailable")}</span>
        );
      }
    } else {
      return false;
    }
  };

  getSelectDealerText = (dealer) => {
    if (!dealer["isActive"] && applicationStore.data.groupSite) {
      // not active && group site
      return this.t("search.selectDealerShort");
    } else {
      return this.t("search.selectDealer");
    }
  };

  onGetDirections = () => {
    if (this.state.activeDealer["googleMapsUrl"] !== "") {
      var url = this.state.activeDealer["googleMapsUrl"];
      var win = window.open(url, "_blank");
      win.focus();
    }
  };

  renderUserOrSearchLocation = () => {
    var position = null;
    if (this.state.userLocation !== null) {
      position = this.state.userLocation;
    } else {
      position = this.props.userLocation;
    }

    return (
      <GoogleMarker
        key="customer-location"
        icon={this.getUserMarkerIcon()}
        position={position}
      />
    );
  };

  renderDealerMarkers = () => {
    var dealerSelected = this.state.activeDealer;
    return this.props.visibleDealers.map(
      function (dealer, index) {
        var selected = dealerSelected
          ? dealerSelected["id"] === dealer["id"]
          : false;
        return (
          <GoogleMarker
            key={"dealer-map-icon-" + index}
            ref={"dealer-marker-" + dealer["id"]}
            icon={this.getMarkerIcon(selected)}
            position={dealer["position"]}
            onClick={this.onMarkerClickRefactor.bind(this, dealer)}
          />
        );
      }.bind(this)
    );
  };

  getMarkerIcon = (selected) => {
    switch (applicationStore.data.defaultTemplate) {
      case "audi":
        return {
          url: selected
            ? "/static/img/" +
              applicationStore.data.defaultTemplate +
              "/marker_green.png"
            : "/static/img/" +
              applicationStore.data.defaultTemplate +
              "/marker_blue.png",
          scaledSize: {
            width: 35,
            height: 42,
            widthUnit: "px",
            heightUnit: "px",
          },
        };
      case "mercedes_2022":
        return {
          url: selected
            ? "/static/img/" +
              applicationStore.data.defaultTemplate +
              "/marker_green.png"
            : "/static/img/" +
              applicationStore.data.defaultTemplate +
              "/marker_blue.png",
          scaledSize: {
            width: 35,
            height: 42,
            widthUnit: "px",
            heightUnit: "px",
          },
        };
      case "volkswagen":
      case "volkswagen_2020":
        return {
          url: selected
            ? "/static/img/" +
              applicationStore.data.defaultTemplate +
              "/marker_green.png"
            : "/static/img/" +
              applicationStore.data.defaultTemplate +
              "/marker_blue.png",
          scaledSize: {
            width: 30,
            height: 40,
            widthUnit: "px",
            heightUnit: "px",
          },
        };
      case "lexus":
        return {
          url: selected
            ? "/static/img/" +
              applicationStore.data.defaultTemplate +
              "/marker_green.png"
            : "/static/img/" +
              applicationStore.data.defaultTemplate +
              "/marker_blue.png",
          scaledSize: {
            width: 49,
            height: 48,
            widthUnit: "px",
            heightUnit: "px",
          },
        };
      case "gmcanada":
        return {
          url: selected
            ? "/static/img/" +
              applicationStore.data.defaultTemplate +
              "/marker_blue.png"
            : "/static/img/" +
              applicationStore.data.defaultTemplate +
              "/marker_green.png",
          scaledSize: {
            width: 14,
            height: 32,
            widthUnit: "px",
            heightUnit: "px",
          },
        };
      case "mercedes":
        return {
          url: selected
            ? "/static/img/" +
              applicationStore.data.defaultTemplate +
              "/marker_green.png"
            : "/static/img/" +
              applicationStore.data.defaultTemplate +
              "/marker_blue.png",
          scaledSize: {
            width: 25,
            height: 31,
            widthUnit: "px",
            heightUnit: "px",
          },
        };
      case "mrlube":
        return {
          url: selected
            ? "/static/img/" +
              applicationStore.data.defaultTemplate +
              "/marker_green.png"
            : "/static/img/" +
              applicationStore.data.defaultTemplate +
              "/marker_blue.png",
          scaledSize: {
            width: 25,
            height: 31,
            widthUnit: "px",
            heightUnit: "px",
          },
        };
      case "subaru":
        return {
          url: selected
            ? "/static/img/" +
              applicationStore.data.defaultTemplate +
              "/marker_green.png"
            : "/static/img/" +
              applicationStore.data.defaultTemplate +
              "/marker_blue.png",
          scaledSize: {
            width: 40,
            height: 37,
            widthUnit: "px",
            heightUnit: "px",
          },
        };
      case "toyota":
        return {
          url: selected
            ? "/static/img/" +
              applicationStore.data.defaultTemplate +
              "/marker_green.png"
            : "/static/img/" +
              applicationStore.data.defaultTemplate +
              "/marker_blue.png",
          scaledSize: {
            width: 40,
            height: 37,
            widthUnit: "px",
            heightUnit: "px",
          },
        };
      default:
        return {
          url: selected
            ? "/static/img/marker_green.png"
            : "/static/img/marker_blue.png",
          scaledSize: {
            width: 14,
            height: 32,
            widthUnit: "px",
            heightUnit: "px",
          },
        };
    }
  };

  getUserMarkerIcon = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "lexus":
        return (
          "/static/img/" +
          applicationStore.data.defaultTemplate +
          "/marker_user.png"
        );
      default:
        return "/static/img/marker_user.png";
    }
  };

  onChange = () => {
    this.setState({
      dealerSearch: dealerSelectionStore.dealerSearch,
      dealerSearchType: dealerSelectionStore.dealerSearchType,
      mapZoom: dealerSelectionStore.mapZoom,
      launchMarker: dealerSelectionStore.launchMarker,
      userLocation:
        dealerSelectionStore.dealerSearchLocation !== null
          ? dealerSelectionStore.dealerSearchLocation
          : this.state.userLocation,
    });
  };
}

var Validator = require("validator");

import { FieldPrototype } from "./FieldPrototype.jsx";

export class PasswordField extends FieldPrototype {
  static defaultProps = {
    errorNotEmpty: "Password is required therefore can't be empty",
  };

  getFieldType = () => {
    return "password";
  };

  isValid = (val) => {
    var trimed = Validator.trim(val);

    if (Validator.equals(trimed, "")) {
      return [this.props.errorNotEmpty];
    } else {
      return [];
    }
  };
}

import { I18nComponent } from "../../../i18n/I18nComponent.jsx";
import { getTabIndex, isEmpty } from "../../../service/UtilityService";

var React = require("react");
var Cx = require("classnames");

export class ComboBoxValue extends I18nComponent {
  static defaultProps = {
    key: "",
    value: "",
    children: "",
    className: "",
    datadtm: "",
    selected: false,
    hidden: false,
    onClick: null,
    onValueSelected: function () {},
  };

  componentDidMount() {
    if (this.props.selected) {
      this.props.onValueSelected(this.props.value, this.props.children, true);
    }
  }

  shouldComponentUpdate(lastProps, nextState) {
    return this.props !== lastProps;
  }

  render() {
    var content = this.props.children;
    var className = Cx({
      "combo-box-value": true,
      selected: this.props.selected,
      hidden: this.props.hidden,
      [this.props.className]:
        !isEmpty(this.props.className) &&
        this.props.className.indexOf("stat-") > -1,
    });
    return (
      <li
        key={this.props.key}
        className={className}
        data-dtm={this.props.datadtm}
        tabIndex={getTabIndex()}
        onClick={
          this.props.onClick !== null
            ? this.props.onClick
            : this.onValueSelected.bind(this, this.props.value, content)
        }
      >
        <span id={this.props.value}>{content}</span>
      </li>
    );
  }

  onValueSelected = (value, content) => {
    this.props.onValueSelected(value, content, false);
  };
}

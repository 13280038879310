import { Modal } from "../ux/Modal.jsx";

import { applicationStore } from "../../stores/ApplicationStore.js";
import { applicationActions } from "../../actions/ApplicationActions.js";
import { shoppingCartViewStore } from "../../stores/ShoppingCartViewStore";

import { TEST_LIST } from "../../service/TestingService";
import { setTokenCaptcha } from "../../api/AuthService";
import { isEmpty, sizeOf, validateEmail } from "../../service/UtilityService";
import {
  buildCaptchaButton,
  inivisCaptchaOnComponentDidUpdate,
} from "../../service/CaptchaService";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  ADOBELAUNCHER_FORM_SUBMIT,
  runAdobeServiceFormStart,
  runAdobeServicePageView,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var _ = require("lodash");

export class RecaptchaModal extends I18nComponent {
  state = {
    count: applicationStore.data.getCaptchaCount,
    callback: applicationStore.data.getCaptchaCallback,
    actionSuccess: false,
    apiFailure: false,
    actionFailure: false,
    actionFailureMsg: "",
  };

  updateState = (state) => {
    this.setState(state);
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
    shoppingCartViewStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
    shoppingCartViewStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    onload("recaptcha-button", "", this.onRunRecaptcha);
    runAdobeServiceFormStart();
  }

  componentDidUpdate() {
    inivisCaptchaOnComponentDidUpdate(this.state, this.updateState, [
      this.onRunRecaptcha,
    ]);
  }

  render() {
    if (this.state.count == 1) {
      return this.renderFormCaptcha();
    } else {
      return this.renderClickCaptcha();
    }
  }

  renderClickCaptcha = () => {
    return (
      <Modal
        refactor={true}
        requestUnmount={this.onRequestCloseModal}
        hideClose={true}
        testId={TEST_LIST.MODAL.CAPTCHA}
      >
        <div className="wrapper">
          <div className="recaptcha-modal">
            <div className="recaptcha-container">
              <div id="recaptcha-button" className="recaptcha-button"></div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  renderFormCaptcha = () => {
    return (
      <Modal refactor={true} hideClose={true} testId={TEST_LIST.MODAL.CAPTCHA}>
        <div className="wrapper">
          <div className="modal-container">
            <div className="information-form">
              <div className="form-header">
                <h1>{this.t("wishlist.modal.titleRequestContact")}</h1>
              </div>

              {this.renderActionResults()}

              {this.renderFormSection()}

              <div className="form-buttons">
                <div className="form-captcha-buttons">
                  {!this.state.actionSuccess ? (
                    <button
                      className={setAdobeLaunchClass(
                        "btn",
                        ADOBELAUNCHER_CLASS.BUTTON.LINK
                      )}
                      data-dtm={setAdobeLaunchDTM(
                        "",
                        ADOBELAUNCHER_DTM.DEALERCONTACTMODAL
                      )}
                      onClick={this.requestContact}
                    >
                      {this.t("wishlist.modal.requestContact")}
                    </button>
                  ) : (
                    false
                  )}
                  {buildCaptchaButton(
                    this.state,
                    1,
                    setAdobeLaunchClass("btn", ADOBELAUNCHER_CLASS.BUTTON.LINK),
                    this.state.actionSuccess
                      ? this.t("common.close")
                      : this.t("wishlist.modal.noThanks"),
                    "",
                    setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.DEALERCONTACTMODAL)
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  requestContact = (token) => {
    this.resetErrors();
    if (this.emailIsValid()) {
      shoppingCartViewStore.setFormData(
        this.getFirstName(),
        this.getLastName(),
        this.getEmail(),
        this.getPhone(),
        this.getNotes()
      );
      applicationActions.requestDealerContact(
        this.getFirstName(),
        this.getLastName(),
        this.getEmail(),
        this.getPhone(),
        this.getNotes()
      );
    }
  };

  getFirstName = () => {
    return document.getElementById("formFirstName-captcha").value;
  };

  getLastName = () => {
    return document.getElementById("formLastName-captcha").value;
  };

  getEmail = () => {
    return document.getElementById("formEmail-captcha").value;
  };

  getPhone = () => {
    return document.getElementById("formPhone-captcha").value;
  };

  getNotes = () => {
    return document.getElementById("formNotes-captcha").value;
  };

  emailIsValid = () => {
    var email = document.getElementById("formEmail-captcha");
    if (email && sizeOf(email.value) > 0) {
      if (validateEmail(email.value)) {
        return true;
      } else {
        this.hasError(this.t("wishlist.modal.errorInvalidEmail"));
        return false;
      }
    } else {
      this.hasError(this.t("wishlist.modal.errorNoEmail"));
      return false;
    }
  };

  resetErrors = () => {
    this.setState({
      actionSuccess: false,
      actionFailure: false,
      actionFailureMsg: "",
      reminderFailure: false,
    });
  };

  hasError = (message) => {
    this.setState({
      actionSuccess: false,
      actionFailure: true,
      actionFailureMsg: message,
    });
  };

  renderActionResults = () => {
    if (this.state.actionSuccess) {
      return this.getFormSuccess();
    } else if (this.state.apiFailure) {
      return this.renderErrorSection(true);
    } else if (this.state.actionFailure) {
      return this.renderErrorSection(false);
    } else {
      return false;
    }
  };

  formController = () => {
    return {
      classList: {
        firstName: "firstName",
        lastName: "lastName",
        email: "email",
        phone: "phone",
        notes: "notes",
      },
      idList: {
        firstName: "formFirstName-captcha",
        lastName: "formLastName-captcha",
        email: "formEmail-captcha",
        phone: "formPhone-captcha",
        notes: "formNotes-captcha",
      },
      mandatory: {
        firstName: false,
        lastName: false,
        email: true,
        phone: false,
        notes: false,
      },
    };
  };

  renderFormSection = () => {
    var formController = this.formController();
    return (
      <div className="form-section">
        <ul>
          <li>
            {this.renderFormElement(
              this.t("wishlist.modal.firstName"),
              formController.classList.firstName,
              "name",
              "given-name",
              formController.idList.firstName,
              formController.mandatory.firstName
            )}
          </li>
          <li>
            {this.renderFormElement(
              this.t("wishlist.modal.lastName"),
              formController.classList.lastName,
              "name",
              "family-name",
              formController.idList.lastName,
              formController.mandatory.lastName
            )}
          </li>
          <li>
            {this.renderFormElement(
              this.t("wishlist.modal.email"),
              formController.classList.email,
              "email",
              "email",
              formController.idList.email,
              formController.mandatory.email
            )}
          </li>
          <li>
            {this.renderFormElement(
              this.t("wishlist.modal.phone"),
              formController.classList.phone,
              "phone",
              "tel",
              formController.idList.phone,
              formController.mandatory.phone
            )}
          </li>
          <li className="notes">
            {this.renderFormElement(
              this.t("wishlist.modal.notes"),
              formController.classList.notes,
              "",
              "",
              formController.idList.notes,
              formController.mandatory.notes
            )}
          </li>
        </ul>
      </div>
    );
  };

  renderFormElement = (title, className, name, autocomplete, id, mandatory) => {
    return (
      <div className={"form-element form-" + className}>
        <span>
          {mandatory ? title + " " : title}
          {mandatory ? (
            <span className="mandatory-text">
              {this.t("common.mandatoryField")}
            </span>
          ) : (
            false
          )}
        </span>
        {className === "notes" ? (
          <textarea
            className={setAdobeLaunchClass(
              className,
              ADOBELAUNCHER_CLASS.INPUT
            )}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.DEALERCONTACTMODAL
            )}
            id={id}
            maxLength="1000"
          />
        ) : (
          <input
            className={setAdobeLaunchClass(
              className,
              ADOBELAUNCHER_CLASS.INPUT
            )}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.DEALERCONTACTMODAL
            )}
            id={id}
            name={name}
            autocomplete={autocomplete}
            maxLength="120"
          />
        )}
      </div>
    );
  };

  getFormSuccess = () => {
    return this.renderSuccessSection(this.t("wishlist.modal.sendSuccess"));
  };

  renderSuccessSection = (message) => {
    return (
      <div className="order-success-div">
        <span className="title">
          <div className="title-div no-button">
            <i className="fa fa-check" />
          </div>
        </span>
        <div className="text">{message}</div>
      </div>
    );
  };

  renderErrorSection = (api) => {
    return (
      <div className="order-error-div">
        <span className="title">
          <div className="title-div no-button">
            <i className="fa fa-close" />
          </div>
        </span>
        <div className="text">
          {api
            ? this.t("wishlist.modal.errorNoReturn")
            : this.state.actionFailureMsg}
        </div>
      </div>
    );
  };

  onRunRecaptcha = (token) => {
    if (token && typeof token === "string") setTokenCaptcha(token);
    setTimeout(() => {
      if (this.state.callback && typeof this.state.callback === "function")
        this.state.callback();
      setTimeout(() => {
        //runAdobeServiceFormSubmit(this.getAdobeLauncherFormSubmitStatus());
        runAdobeServicePageView();
        this.onRequestCloseModal();
      }, 100);
    }, 100);
  };

  getAdobeLauncherFormSubmitStatus = () => {
    if (
      !isEmpty(this.getFirstName()) &&
      !isEmpty(this.getLastName()) &&
      !isEmpty(this.getEmail()) &&
      !isEmpty(this.getPhone())
    ) {
      return ADOBELAUNCHER_FORM_SUBMIT.COMPLETE;
    } else if (
      !isEmpty(this.getFirstName()) ||
      !isEmpty(this.getLastName()) ||
      !isEmpty(this.getEmail()) ||
      !isEmpty(this.getPhone())
    ) {
      return ADOBELAUNCHER_FORM_SUBMIT.PARTIAL;
    } else {
      return ADOBELAUNCHER_FORM_SUBMIT.NONCOMPLETE;
    }
  };

  onRequestCloseModal = () => {
    applicationActions.closeRecaptchaModal();
  };

  onChange = () => {
    this.setState({
      count: applicationStore.data.getCaptchaCount,
      callback: applicationStore.data.getCaptchaCallback,
      actionSuccess: shoppingCartViewStore.data.get("shoppingCartFormSuccess"),
      apiFailure: shoppingCartViewStore.data.get("shoppingCartFormError"),
    });
  };
}

import { applicationStore } from '../stores/ApplicationStore.js';
import { applicationActions } from '../actions/ApplicationActions.js';

import { cleanTextForHtml, isEmpty, getSecurityTokenWrapper, getSecurityTokenRequestObject, isNationalSite, isGroupSite, isDealerSite, isConsumerSite } from '../service/UtilityService';
import { getToken, isTokenValid, setToken, isTokenAuthenticated, isTokenExpired, checkIsTokenVersionOutdated, resetTokenCaptcha } from './AuthService.js';
import store from "../stores/configureStore";
import {changeOnLoading} from "../redux/features/productList";

var Superagent = require('superagent');
var Promise = require('bluebird');
var API_PATH_PREFIX = 'retailSiteWebService';

// Webservice Info
var apiCalls = 0;

// Reloading Token Protection
var securityTokenHasFailed = false;
var securityTokenRetryAttemptCount = 0;

const MAXIMUM_API_CALLS = 5;

export function getApiBaseUrl() {
  switch (window.environment) {
    case "DEV":
      return "http://localhost";
    case "PRODUCTION":
      return "https://api.tirelocator.ca";
    case "QA":
      return "https://api.qa-tirelocator.space";
    case "STAGE":
      return "https://api.stage-tirelocator.space";
    case "TRY":
      return "https://api.try-tirelocator.space";
    case "TIGHTEN":
      return "https://tirelocator.tgtn.co";
    case "TLV2":
      return "https://tlv2api.qa-tirelocator.space";
    case "LARAVEL":
      return "https://tighten-stage.herokuapp.com";
    default:
      return "https://api.qa-tirelocator.space";
  }
}


function performCall(resolve, reject, method, endpoint, params, count) {
	var nextCount = parseInt(count) + 1;
	var requestParams = params || {};
	var token = getToken();

	if (token && nextCount < MAXIMUM_API_CALLS) {

    requestParams.tokenId = token.tokenId;
    requestParams.ts = new Date().getTime();

    if (token.hasOwnProperty('searchToken')) {
      requestParams.searchToken = token.searchToken;
      resetTokenCaptcha();
    }

    Superagent
      [method](getApiBaseUrl() + '/' + API_PATH_PREFIX + endpoint)
      .type('form')[method === 'get' ? 'query' : 'send'](requestParams)
      .end(function (err, res) {
        if (err) {

          reject(err);
          setTimeout(() => { applicationActions.apiWebserviceHasFailed(); }, 100);

        } else {
          if (isEmpty(res) || res.status === 403 && !securityTokenHasFailed && securityTokenRetryAttemptCount === 0) {

            securityTokenHasFailed = true, securityTokenRetryAttemptCount = securityTokenRetryAttemptCount + 1;
            performSecurityWrapped(resolve, reject, method, endpoint, params, nextCount, securityTokenRetryAttemptCount);

          } else {

            if (res.ok) {

              var responseJson = JSON.parse(res.text);

							if (isTokenAuthenticated() && isTokenExpired()) {
                applicationActions.logOut();
                setTimeout(() => { applicationActions.loginHasExpired(); }, 100);

              }

							if (!isNationalSite() && !isGroupSite() && !isDealerSite() && !isConsumerSite()) {

								setTimeout(() => { applicationActions.apiWebserviceHasFailed(404); }, 100);

							} else if (typeof(responseJson) === 'object') {
							    
                if (endpoint.indexOf('getSecurityToken.php') > -1 || endpoint.indexOf('createCustomerUserAccount.php') > -1) {
                  setToken(responseJson.token);
                }
                
                if (checkIsTokenVersionOutdated(responseJson.token)) {
                  applicationActions.appRunningOutdatedVersion();
                }

              }

              //check token for security requirement
              if (responseJson.token && responseJson.token.resetSecurityCheckpoint) {
                applicationStore.data.dealerForceCaptchaPassed = false;
              }

              var htmlReadyResponse = cleanTextForHtml(responseJson.payload);

              resolve(htmlReadyResponse, res);

            } else {

              if (count < MAXIMUM_API_CALLS) {
                setTimeout(() => { performCall(resolve, reject, method, endpoint, params, nextCount, 0); }, 2000);
              } else {
                resolve('', res);
                setTimeout(() => { applicationActions.apiWebserviceHasFailed(); }, 100);
              }

            }

          }
        }

      });
  } else {

    resolve(null, null);
    setTimeout(() => { applicationActions.apiWebserviceHasFailed(); }, 100);

	}
}

function performSecurityWrapped(resolve, reject, method, endpoint, params, count, securityTokenRetryAttempts) {
  var nextCount = parseInt(count) + 1;
	if (nextCount < MAXIMUM_API_CALLS) {
    Superagent
      .post(getApiBaseUrl() + '/' + API_PATH_PREFIX + '/authentication/getSecurityToken.php')
      .type('form')['query']( getSecurityTokenRequestObject() )
      .end(function (err, res) {

        if (isEmpty(res) || res.status === 403 || !res.ok) {

          performSecurityWrapped(resolve, reject, method, endpoint, params, nextCount, securityTokenRetryAttempts);

        } else {

          var responseJson = JSON.parse(res.text);

          //check token for security requirement
          if (responseJson.token && responseJson.token.resetSecurityCheckpoint) {
            applicationStore.data.dealerForceCaptchaPassed = false;
          }

					if (!isNationalSite() && !isGroupSite() && !isDealerSite() && !isConsumerSite()) {
						setTimeout(() => { applicationActions.apiWebserviceHasFailed(404); }, 100);
					} else if (responseJson.token) {
            setToken(responseJson.token);
            if (checkIsTokenVersionOutdated(responseJson.token)) {
              applicationActions.appRunningOutdatedVersion();
            }
          }

          (function (resolve, reject, method, endpoint, params, count) {
            var nextCount = parseInt(count) + 1;
            var requestParams = params || {};
            var token = getToken();

            if (token && nextCount < MAXIMUM_API_CALLS) {

              requestParams.tokenId = token.tokenId;
              requestParams.ts = new Date().getTime();

              if (token.hasOwnProperty('searchToken')) {
                requestParams.searchToken = token.searchToken;
                resetTokenCaptcha();
              }

              Superagent
                [method](getApiBaseUrl() + '/' + API_PATH_PREFIX + endpoint)
                .type('form')[method === 'get' ? 'query' : 'send'](requestParams)
                .end(function (err, res) {
                  if (err) {

                    reject(err);
                    setTimeout(() => { applicationActions.apiWebserviceHasFailed(); }, 100);

                  } else {
                    if (isEmpty(res) || res.status === 403) {

                      performSecurityWrapped(resolve, reject, method, endpoint, params, nextCount, securityTokenRetryAttempts);

                    } else {

                      if (res.ok) {

                        var responseJson = JSON.parse(res.text);

                        if (isTokenAuthenticated() && isTokenExpired()) {
                          applicationActions.loginHasExpired();
                          setTimeout(() => { applicationActions.logOut(); }, 100);

                        }

												if (!isNationalSite() && !isGroupSite() && !isDealerSite() && !isConsumerSite()) {

													setTimeout(() => { applicationActions.apiWebserviceHasFailed(404); }, 100);

												} else if (typeof(responseJson) === 'object') {

                          if (endpoint.indexOf('getSecurityToken.php') > -1 || endpoint.indexOf('createCustomerUserAccount.php') > -1) {
                            setToken(responseJson.token);
                          }
                          
                          if (checkIsTokenVersionOutdated(responseJson.token)) {
                            applicationActions.appRunningOutdatedVersion();
                          }

                        }

                        //check token for security requirement
                        if (responseJson.token && responseJson.token.resetSecurityCheckpoint) {
                          applicationStore.data.dealerForceCaptchaPassed = false;
                        }

                        var htmlReadyResponse = cleanTextForHtml(responseJson.payload);

                        securityTokenHasFailed = false;
                        securityTokenRetryAttemptCount = 0;

                        resolve(htmlReadyResponse, res);

                      } else {

                        if (count < MAXIMUM_API_CALLS) {
                          setTimeout(() => { performSecurityWrapped(resolve, reject, method, endpoint, params, nextCount, securityTokenRetryAttempts); }, 2000);
                        } else {
                          resolve('', res);
                          setTimeout(() => { applicationActions.apiWebserviceHasFailed(); }, 100);
                        }

                      }

                    }
                  }
                });
            } else if (nextCount > (MAXIMUM_API_CALLS - 1)) {

              resolve(null, null);
              setTimeout(() => { applicationActions.apiWebserviceHasFailed(); }, 100);

            } else {

              performSecurityWrapped(resolve, reject, method, endpoint, params, nextCount, securityTokenRetryAttempts);

            }
          }(resolve, reject, method, endpoint, params, nextCount))

        }

      });
  } else {

    resolve(null, null);
    setTimeout(() => { applicationActions.apiWebserviceHasFailed(); }, 100);

	}
}

export function encodeBase64(encode, callback) {
  var type = '', url = encode.url;
  if (url) {
    if (url.indexOf('.png') > -1) {
      type = 'png';
    } else if (url.indexOf('.jpg') > -1 || url.indexOf('.jpeg') > -1) {
      type = 'jpeg';
    }
    Superagent
    .get(window.location.origin + '/encode64/' + encodeURIComponent(url))
    .type('form')
    .end(
      function (err, response) {
        if (err) {
          return "";
        } else {
          var text = response ? response.text : null, resp = text ? JSON.parse(text).payload : null;
          if (resp) callback(encode.id, url, "data:image/" + type + ";base64," + resp);
          else callback(encode.id, url, null);
        }
      }
    )
  } else callback(encode.id, url, null);
}

export function retrieveDealerLocation(language, postalCode, callback) {
  Superagent
  .get(window.location.origin + '/find/location/' + language + '/' + postalCode)
  .type('form')
  .end(
    function (err, response) {
      if (!err) {
        var text = response ? response.text : null, resp = text ? JSON.parse(text) : null;
        if (resp) {
          resp = resp ? resp.DealerList : null;
          resp = resp ? resp.Dealers : null;
          resp = resp ? resp.Dealer : null;
          callback({ selectedRedirectDealer: resp ? resp.Name : "" });
        } else {
          callback({ selectedRedirectDealer: false });
        }
      }
    }
  )
}

export function doRequest(method, endpoint, params) {
	return new Promise((resolve, reject) => {
		if (!isTokenValid()) {
			performSecurityWrapped(resolve, reject, method, endpoint, params, 0, 0);
		} else {
			performCall(resolve, reject, method, endpoint, params, 0);
		}
	});
}

export function get(endpoint, params) {
	return doRequest('get', endpoint, params);
}

export function post(endpoint, params) {
	return doRequest('post', endpoint, params);
}

export function incrementApiCalls() {
	apiCalls++;
}

export function decrementApiCalls() {
	apiCalls--;
}

export function isAnyApiCallInProgress() {
	return apiCalls !== 0;
}

export function getApiCallRedux(payload){
  store.dispatch(changeOnLoading(payload));
}
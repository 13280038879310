import { globalState } from "../GlobalState.js";
import { applicationActions } from "../actions/ApplicationActions.js";
import { RouterStates } from "../constants/RouterStates.js";
import { applicationStore } from "./ApplicationStore.js";
import { productListStore } from "./ProductListStore.js";
import { searchStore } from "./SearchStore.js";
import { dealerSelectionStore } from "./DealerSelectionStore";
import { vehicleSelectionStore } from "./VehicleSelectionStore.js";
import { breadcrumbsStore } from "./BreadcrumbsStore.js";
import { productDetailStore } from "./ProductDetailStore.js";

import { SearchTypes } from "../constants/SearchTypes.js";

import { getOrderIdentifier } from "../service/OrderService.js";
import {
  redirectSearchToWishlist,
  decodeUrlSSO,
  hashServiceShouldRun,
  processNewHash,
  getUrlOrigin,
} from "../service/HashService.js";
import {
  getAppLanguage,
  sizeOf,
  isEmpty,
  decodeUrlShoppingContext,
  getProductTypeLowerCase,
  isNationalSite,
  isGroupSite,
  isConsumerSite,
  getAppDealerId,
  isOutdatedSafari,
} from "../service/UtilityService.js";
import store from "./configureStore.js";
import { appState, decodedUrl } from "../redux/features/applicationInfo.js";

const NATIONAL_START_PAGE = {
  LANDING_PAGE: "Landing Page",
};

var initialized;

const sendToLanding = function () {
  if (window.dealerAccountInfoPayload) {
    if (window.dealerAccountInfoPayload.dealerRetailSiteStartPage !== "HOME") {
      return RouterStates.YEAR;
    } else {
      return RouterStates.HOMEPAGE;
    }
  } else {
    return RouterStates.HOMEPAGE;
  }
};

var root = function (lang) {
  var language = null,
    routerState = RouterStates.NO_CONNECTION;
  if (window.dealerAccountInfoPayload) {
    language = lang
      ? lang
      : window.dealerAccountInfoPayload.dealerDefaultLanguage;
    routerState = sendToLanding();
  } else if (window.nationalAccountInfoPayload) {
    language = lang
      ? lang
      : window.nationalAccountInfoPayload.nationalDefaultLanguage;
    if (
      applicationStore.data.nationalRetailSiteDestinationPage ==
      NATIONAL_START_PAGE.LANDING_PAGE
    ) {
      routerState = RouterStates.LANDING_PAGE;
    } else {
      routerState =
        location.pathname.indexOf("/dealer/") === -1
          ? RouterStates.DEALER_SELECTION
          : sendToLanding();
    }
  } else if (window.groupAccountInfoPayload) {
    language = lang
      ? lang
      : window.groupAccountInfoPayload.dealerGroupDefaultLanguage;
    routerState = RouterStates.DEALER_SELECTION;
  } else if (
    location.pathname.split("/")[1] === "externalWebService" &&
    location.pathname.split("/")[2] === "integrations"
  ) {
    language = "en";
  }
  store.dispatch(
    appState({
      routerState: routerState,
      lang: language,
    })
  );
  applicationActions.initializeAppState({
    routerState: routerState,
    lang: language,
  });

  initialized = true;
};

var landingpage = function (lang) {
  var language =
      window.nationalAccountInfoPayload !== null
        ? window.nationalAccountInfoPayload.nationalDefaultLanguage
        : window.groupAccountInfoPayload
        ? window.groupAccountInfoPayload.dealerGroupDefaultLanguage
        : null,
    routerState = RouterStates.LANDING_PAGE;

  store.dispatch(
    appState({
      routerState: routerState,
      lang: lang ? lang : language,
    })
  );
  applicationActions.initializeAppState({
    routerState: routerState,
    lang: lang ? lang : language,
  });

  initialized = true;
};

var homepage = function (lang, dealerId) {
  if (lang === "en" || lang === "fr") {
    store.dispatch(
      appState({
        routerState: applicationStore.data.disableHomepage
          ? RouterStates.YEAR
          : RouterStates.HOMEPAGE,
        lang: lang,
        dealerId: dealerId,
      })
    );
    applicationActions.initializeAppState({
      routerState: applicationStore.data.disableHomepage
        ? RouterStates.YEAR
        : RouterStates.HOMEPAGE,
      lang: lang,
      dealerId: dealerId,
    });

    initialized = true;
  }
};

var translationDictionary = function (lang) {
  var language =
      window.nationalAccountInfoPayload !== null
        ? window.nationalAccountInfoPayload.nationalDefaultLanguage
        : window.groupAccountInfoPayload
        ? window.groupAccountInfoPayload.dealerGroupDefaultLanguage
        : null,
    routerState = RouterStates.TRANSLATION_DICTIONARY;

  store.dispatch(
    appState({
      routerState: routerState,
      lang: lang ? lang : language,
    })
  );
  applicationActions.initializeAppState({
    routerState: routerState,
    lang: lang ? lang : language,
  });

  initialized = true;
};

var yearSelection = function (lang, dealerId, productType, searchType) {
  store.dispatch(
    appState({
      routerState: RouterStates.YEAR,
      lang: lang,
      dealerId: dealerId,
      productType: productType.toUpperCase(),
      searchType: searchType.toLowerCase(),
    })
  );
  applicationActions.initializeAppState({
    routerState: RouterStates.YEAR,
    lang: lang,
    dealerId: dealerId,
    productType: productType.toUpperCase(),
    searchType: searchType.toLowerCase(),
  });

  initialized = true;
};

var makeSelection = function (lang, dealerId, productType, searchType, year) {
  store.dispatch(
    appState({
      routerState: RouterStates.MAKE,
      lang: lang,
      dealerId: dealerId,
      productType: productType.toUpperCase(),
      searchType: searchType.toLowerCase(),
      year: year,
    })
  );

  applicationActions.initializeAppState({
    routerState: RouterStates.MAKE,
    lang: lang,
    dealerId: dealerId,
    productType: productType.toUpperCase(),
    searchType: searchType.toLowerCase(),
    year: year,
  });

  initialized = true;
};

var categorySelection = function (
  lang,
  dealerId,
  productType,
  searchType,
  year,
  make
) {
  store.dispatch(
    appState({
      routerState: RouterStates.CATEGORY,
      lang: lang,
      dealerId: dealerId,
      productType: productType.toUpperCase(),
      searchType: searchType.toLowerCase(),
      year: year,
      make: make,
    })
  );

  applicationActions.initializeAppState({
    routerState: RouterStates.CATEGORY,
    lang: lang,
    dealerId: dealerId,
    productType: productType.toUpperCase(),
    searchType: searchType.toLowerCase(),
    year: year,
    make: make,
  });

  initialized = true;
};

var modelSelection = function (
  lang,
  dealerId,
  productType,
  searchType,
  year,
  make,
  category
) {
  store.dispatch(
    appState({
      routerState: RouterStates.MODEL,
      lang: lang,
      dealerId: dealerId,
      productType: productType.toUpperCase(),
      searchType: searchType.toLowerCase(),
      year: year,
      make: make,
      category: category,
    })
  );

  applicationActions.initializeAppState({
    routerState: RouterStates.MODEL,
    lang: lang,
    dealerId: dealerId,
    productType: productType.toUpperCase(),
    searchType: searchType.toLowerCase(),
    year: year,
    make: make,
    category: category,
  });

  initialized = true;
};

var trimSelection = function (
  lang,
  dealerId,
  productType,
  searchType,
  year,
  make,
  category,
  model
) {
  store.dispatch(
    appState({
      routerState: RouterStates.TRIM,
      lang: lang,
      dealerId: dealerId,
      productType: productType.toUpperCase(),
      searchType: searchType.toLowerCase(),
      year: year,
      make: make,
      category: category,
      model: model,
    })
  );
  applicationActions.initializeAppState({
    routerState: RouterStates.TRIM,
    lang: lang,
    dealerId: dealerId,
    productType: productType.toUpperCase(),
    searchType: searchType.toLowerCase(),
    year: year,
    make: make,
    category: category,
    model: model,
  });

  initialized = true;
};

var productList = function (
  lang,
  dealerId,
  productType,
  searchType,
  year,
  make,
  category,
  model,
  trim
) {
  store.dispatch(
    appState({
      routerState: RouterStates.PRODUCT_LIST,
      lang: lang,
      dealerId: dealerId,
      productType: productType.toUpperCase(),
      searchType: searchType.toLowerCase(),
      year: year,
      make: make,
      category: category,
      model: model,
      trim: trim,
    })
  );

  applicationActions.initializeAppState({
    routerState: RouterStates.PRODUCT_LIST,
    lang: lang,
    dealerId: dealerId,
    productType: productType.toUpperCase(),
    searchType: searchType.toLowerCase(),
    year: year,
    make: make,
    category: category,
    model: model,
    trim: trim,
  });

  initialized = true;
};

var productListModel = function (
  lang,
  dealerId,
  productType,
  searchType,
  year,
  category,
  model
) {
  store.dispatch(
    appState({
      routerState: RouterStates.PRODUCT_LIST,
      lang: lang,
      productType: productType.toUpperCase(),
      searchType: searchType.toLowerCase(),
      year: year,
      category: category,
      model: model,
      dealerId: dealerId,
    })
  );

  applicationActions.initializeAppState({
    routerState: RouterStates.PRODUCT_LIST,
    lang: lang,
    productType: productType.toUpperCase(),
    searchType: searchType.toLowerCase(),
    year: year,
    category: category,
    model: model,
    dealerId: dealerId,
  });

  initialized = true;
};

var productListChassis = function (
  lang,
  dealerId,
  productType,
  searchType,
  chassis
) {
  store.dispatch(
    appState({
      routerState: RouterStates.PRODUCT_LIST,
      lang: lang,
      productType: productType.toUpperCase(),
      searchType: searchType.toLowerCase(),
      chassis: chassis,
      dealerId: dealerId,
    })
  );

  applicationActions.initializeAppState({
    routerState: RouterStates.PRODUCT_LIST,
    lang: lang,
    productType: productType.toUpperCase(),
    searchType: searchType.toLowerCase(),
    chassis: chassis,
    dealerId: dealerId,
  });

  initialized = true;
};

var productDetail = function (
  lang,
  dealerId,
  productType,
  year,
  make,
  category,
  model,
  trim,
  vehicleId,
  productId,
  secondaryProductId
) {
  store.dispatch(
    appState({
      routerState: RouterStates.PRODUCT_DETAIL,
      lang: lang,
      dealerId: dealerId,
      productType: productType.toUpperCase(),
      year: year,
      make: make,
      category: category,
      model: model,
      trim: trim,
      vehicleId: vehicleId,
      productId: productId,
      secondaryProductId: secondaryProductId,
    })
  );

  applicationActions.initializeAppState({
    routerState: RouterStates.PRODUCT_DETAIL,
    lang: lang,
    dealerId: dealerId,
    productType: productType.toUpperCase(),
    year: year,
    make: make,
    category: category,
    model: model,
    trim: trim,
    vehicleId: vehicleId,
    productId: productId,
    secondaryProductId: secondaryProductId,
  });

  initialized = true;
};

var searchBrandsBySeason = function (lang, dealerId, season) {
  store.dispatch(
    appState({
      routerState: RouterStates.SEARCH_BRANDS_BY_SEASON,
      lang: lang,
      dealerId: dealerId,
      selectedSeason: season,
    })
  );
  applicationActions.initializeAppState({
    routerState: RouterStates.SEARCH_BRANDS_BY_SEASON,
    lang: lang,
    dealerId: dealerId,
    selectedSeason: season,
  });

  initialized = true;
};

var searchBrands = function (lang, dealerId, brandId) {
  store.dispatch(
    appState({
      routerState: RouterStates.SEARCH_BRANDS,
      lang: lang,
      dealerId: dealerId,
      brandId: brandId,
    })
  );
  applicationActions.initializeAppState({
    routerState: RouterStates.SEARCH_BRANDS,
    lang: lang,
    dealerId: dealerId,
    brandId: brandId,
  });

  initialized = true;
};

var searchModelsBySeason = function (lang, dealerId, season, modelId) {
  store.dispatch(
    appState({
      routerState: RouterStates.SEARCH_MODELS_BY_SEASON,
      lang: lang,
      dealerId: dealerId,
      selectedSeason: season,
      modelId: modelId,
    })
  );
  applicationActions.initializeAppState({
    routerState: RouterStates.SEARCH_MODELS_BY_SEASON,
    lang: lang,
    dealerId: dealerId,
    selectedSeason: season,
    modelId: modelId,
  });

  initialized = true;
};

var searchModels = function (lang, dealerId, brandId, modelId) {
  store.dispatch(
    appState({
      routerState: RouterStates.SEARCH_MODELS,
      lang: lang,
      dealerId: dealerId,
      brandId: brandId,
      modelId: modelId,
    })
  );
  applicationActions.initializeAppState({
    routerState: RouterStates.SEARCH_MODELS,
    lang: lang,
    dealerId: dealerId,
    brandId: brandId,
    modelId: modelId,
  });

  initialized = true;
};

var searchTireBySeason = function (lang, dealerId, season, modelId, tireId) {
  store.dispatch(
    appState({
      routerState: RouterStates.SEARCH_TIRE_BY_SEASON,
      lang: lang,
      dealerId: dealerId,
      selectedSeason: season,
      modelId: modelId,
      tireId: tireId,
    })
  );
  applicationActions.initializeAppState({
    routerState: RouterStates.SEARCH_TIRE_BY_SEASON,
    lang: lang,
    dealerId: dealerId,
    selectedSeason: season,
    modelId: modelId,
    tireId: tireId,
  });

  initialized = true;
};

var searchTire = function (lang, dealerId, brandId, modelId, tireId) {
  store.dispatch(
    appState({
      routerState: RouterStates.SEARCH_TIRE,
      lang: lang,
      dealerId: dealerId,
      brandId: brandId,
      modelId: modelId,
      tireId: tireId,
    })
  );
  applicationActions.initializeAppState({
    routerState: RouterStates.SEARCH_TIRE,
    lang: lang,
    dealerId: dealerId,
    brandId: brandId,
    modelId: modelId,
    tireId: tireId,
  });

  initialized = true;
};

var wishlistPrint = function (lang, dealerId, cart, vehicle) {
  store.dispatch(
    appState({
      routerState: RouterStates.PRINT_WISHLIST,
      cart: cart,
      vehicle: vehicle,
      lang: lang,
      dealerId: dealerId,
    })
  );

  applicationActions.initializeAppState({
    routerState: RouterStates.PRINT_WISHLIST,
    cart: cart,
    vehicle: vehicle,
    lang: lang,
    dealerId: dealerId,
  });

  initialized = true;
};

var wishlistPrintTest = function (lang, dealerId, cart, vehicle) {
  store.dispatch(
    appState({
      routerState: RouterStates.PRINT_WISHLIST_TEST,
      cart: cart,
      vehicle: vehicle,
      lang: lang,
      dealerId: dealerId,
      disable: true,
    })
  );
  applicationActions.initializeAppState({
    routerState: RouterStates.PRINT_WISHLIST_TEST,
    cart: cart,
    vehicle: vehicle,
    lang: lang,
    dealerId: dealerId,
    disable: true,
  });

  initialized = true;
};

var wishlistReview = function (lang, dealerId, cart) {
  store.dispatch(
    appState({
      routerState: RouterStates.WISHLIST,
      cart: cart,
      vehicle: 0,
      lang: lang,
      dealerId: dealerId,
    })
  );
  applicationActions.initializeAppState({
    routerState: RouterStates.WISHLIST,
    cart: cart,
    vehicle: 0,
    lang: lang,
    dealerId: dealerId,
  });

  initialized = true;
};

var wishlistReviewWithVehicle = function (lang, dealerId, cart, vehicle) {
  store.dispatch(
    appState({
      routerState: RouterStates.WISHLIST,
      cart: cart,
      vehicle: vehicle,
      lang: lang,
      dealerId: dealerId,
    })
  );
  applicationActions.initializeAppState({
    routerState: RouterStates.WISHLIST,
    cart: cart,
    vehicle: vehicle,
    lang: lang,
    dealerId: dealerId,
  });

  initialized = true;
};

var search = function (lang, dealerId, searchType, term) {
  store.dispatch(
    appState({
      routerState: RouterStates.SEARCH_RESULT,
      lang: lang,
      dealerId: dealerId,
      searchType: searchType,
      search: decodeURIComponent(term),
    })
  );
  applicationActions.initializeAppState({
    routerState: RouterStates.SEARCH_RESULT,
    lang: lang,
    dealerId: dealerId,
    searchType: searchType,
    search: decodeURIComponent(term),
  });

  initialized = true;
};

var contactDealer = function (lang, dealerId) {
  store.dispatch(
    appState({
      routerState: RouterStates.CONTACT_DEALER,
      lang: lang,
      dealerId: dealerId,
    })
  );
  applicationActions.initializeAppState({
    routerState: RouterStates.CONTACT_DEALER,
    lang: lang,
    dealerId: dealerId,
  });

  initialized = true;
};

var comparison = function (lang, dealerId, productType, compareData, vehicle) {
  store.dispatch(
    appState({
      routerState: RouterStates.COMPARISON,
      lang: lang,
      dealerId: dealerId,
      productType: productType,
      compareData: compareData,
      vehicle: vehicle,
    })
  );
  applicationActions.initializeAppState({
    routerState: RouterStates.COMPARISON,
    lang: lang,
    dealerId: dealerId,
    productType: productType,
    compareData: compareData,
    vehicle: vehicle,
  });

  initialized = true;
};

var comparisonFromTireSize = function (
  lang,
  dealerId,
  searchType,
  term,
  productType,
  compareData
) {
  store.dispatch(
    appState({
      routerState: RouterStates.PRINT_COMPARISON_FROM_TIRESIZE,
      lang: lang,
      dealerId: dealerId,
      searchType: searchType,
      search: decodeURIComponent(term),
      productType: productType,
      compareData: compareData,
    })
  );

  applicationActions.initializeAppState({
    routerState: RouterStates.PRINT_COMPARISON_FROM_TIRESIZE,
    lang: lang,
    dealerId: dealerId,
    searchType: searchType,
    search: decodeURIComponent(term),
    productType: productType,
    compareData: compareData,
  });

  initialized = true;
};

var comparisonWithProductData = function (
  lang,
  dealerId,
  productType,
  compareData,
  vehicle,
  productdata
) {
  store.dispatch(
    appState({
      routerState: RouterStates.COMPARISON,
      lang: lang,
      dealerId: dealerId,
      productType: productType,
      compareData: compareData,
      vehicle: vehicle,
      productdata: productdata,
    })
  );

  applicationActions.initializeAppState({
    routerState: RouterStates.COMPARISON,
    lang: lang,
    dealerId: dealerId,
    productType: productType,
    compareData: compareData,
    vehicle: vehicle,
    productdata: productdata,
  });

  initialized = true;
};

var getRouterStateUrlSearchData = function (searchType) {
  switch (searchType) {
    case SearchTypes.SEARCH_BY_MODEL:
      return (
        "/type/" +
        encodeURIComponent(SearchTypes.SEARCH_BY_MODEL) +
        "/year/" +
        encodeURIComponent(vehicleSelectionStore.data.year) +
        "/category/" +
        encodeURIComponent(vehicleSelectionStore.data.category) +
        "/model/" +
        encodeURIComponent(vehicleSelectionStore.data.model)
      );
    case SearchTypes.SEARCH_BY_CHASSIS:
      return (
        "/type/" +
        encodeURIComponent(SearchTypes.SEARCH_BY_CHASSIS) +
        "/chassis/" +
        encodeURIComponent(productListStore.data.chassis)
      );
    default:
      return urlVehicleSearchEncoder(true, true, true, true, true, true);
  }
};

var urlVehicleSearchEncoder = function (
  hasType,
  hasYear,
  hasManufacturer,
  hasCategory,
  hasModel,
  hasTrim
) {
  var breadcrumbData = breadcrumbsStore.data;

  var year = applicationStore.data.year;
  if (
    breadcrumbData &&
    breadcrumbData.crumbs &&
    breadcrumbData.crumbs[RouterStates.YEAR]
  )
    year = encodeURIComponent(
      breadcrumbData.crumbs[RouterStates.YEAR].value.toString()
    );
  var manufacturer = applicationStore.data.make;
  if (
    breadcrumbData &&
    breadcrumbData.crumbs &&
    breadcrumbData.crumbs[RouterStates.MAKE]
  )
    manufacturer = encodeURIComponent(
      breadcrumbData.crumbs[RouterStates.MAKE].value.toString()
    );
  var model = applicationStore.data.model;
  if (
    breadcrumbData &&
    breadcrumbData.crumbs &&
    breadcrumbData.crumbs[RouterStates.MODEL]
  )
    model = encodeURIComponent(
      breadcrumbData.crumbs[RouterStates.MODEL]["value"]["en"].toString()
    );
  var trim = applicationStore.data.trim;
  if (
    breadcrumbData &&
    breadcrumbData.crumbs &&
    breadcrumbData.crumbs[RouterStates.TRIM]
  )
    trim = encodeURIComponent(
      breadcrumbData.crumbs[RouterStates.TRIM]["value"]["en"].toString()
    );

  var category = encodeURIComponent(applicationStore.data.category);
  if (breadcrumbData && breadcrumbData.category)
    category = encodeURIComponent(breadcrumbData.category.toString());

  var url = "";
  if (hasType) url = "/type/" + productListStore.data.searchType;
  if (hasYear) url = url + "/year/" + year;
  if (hasManufacturer) url = url + "/make/" + manufacturer;
  if (hasCategory) url = url + "/category/" + category;
  if (hasModel) url = url + "/model/" + model;
  if (hasTrim) url = url + "/trim/" + trim;
  if (url === "") url = "/";

  return url;
};

var getRouterStateUrlData = function (url, routerState, searchType) {
  switch (routerState) {
    case RouterStates.DEALER_SELECTION:
    case RouterStates.HOMEPAGE:
      return url + "";
    case RouterStates.LANDING_PAGE:
      return url + "/landingpage";
    case RouterStates.TRANSLATION_DICTIONARY:
      return url + "/translation/dictionary/";
    case RouterStates.SEARCH_BRANDS_BY_SEASON:
      return (
        url +
        "/tires/season/" +
        encodeURIComponent(applicationStore.data.selectedSeasonName)
      );
    case RouterStates.SEARCH_BRANDS:
      return (
        url +
        "/tires/brands/" +
        encodeURIComponent(applicationStore.data.selectedBrandName)
      );
    case RouterStates.SEARCH_MODELS_BY_SEASON:
      return (
        url +
        "/tires/season/" +
        encodeURIComponent(applicationStore.data.selectedSeasonName) +
        "/models/" +
        encodeURIComponent(applicationStore.data.selectedModelData.modelName)
      );
    case RouterStates.SEARCH_MODELS:
      return (
        url +
        "/tires/brands/" +
        encodeURIComponent(applicationStore.data.selectedBrandName) +
        "/models/" +
        encodeURIComponent(applicationStore.data.selectedModelData.modelName)
      );
    case RouterStates.SEARCH_TIRE_BY_SEASON:
      return (
        url +
        "/tires/season/" +
        encodeURIComponent(applicationStore.data.selectedSeasonName) +
        "/models/" +
        encodeURIComponent(applicationStore.data.selectedModelData.modelName) +
        "/partnumber/" +
        encodeURIComponent(applicationStore.data.selectedTirePartNumber)
      );
    case RouterStates.SEARCH_TIRE:
      return (
        url +
        "/tires/brands/" +
        encodeURIComponent(applicationStore.data.selectedBrandName) +
        "/models/" +
        encodeURIComponent(applicationStore.data.selectedModelData.modelName) +
        "/partnumber/" +
        encodeURIComponent(applicationStore.data.selectedTirePartNumber)
      );
    case RouterStates.CONTACT_DEALER:
      return url + "/dealer/contact/" + encodeURIComponent(getAppDealerId());
    case RouterStates.SEARCH_RESULT:
      if (isConsumerSite()) {
        return "";
      } else {
        return (
          url +
          "/search/" +
          encodeURIComponent(productListStore.data.searchType) +
          "/" +
          encodeURIComponent(applicationStore.data.search)
        );
      }

    case RouterStates.YEAR:
      return (
        url + urlVehicleSearchEncoder(true, false, false, false, false, false)
      );
    case RouterStates.MAKE:
      return (
        url + urlVehicleSearchEncoder(true, true, false, false, false, false)
      );
    case RouterStates.CATEGORY:
      return (
        url + urlVehicleSearchEncoder(true, true, true, false, false, false)
      );
    case RouterStates.MODEL:
      return (
        url + urlVehicleSearchEncoder(true, true, true, true, false, false)
      );
    case RouterStates.TRIM:
      return url + urlVehicleSearchEncoder(true, true, true, true, true, false);
    case RouterStates.PRODUCT_LIST:
      return url + getRouterStateUrlSearchData(searchType);
    case RouterStates.PRODUCT_DETAIL:
      return (
        url +
        urlVehicleSearchEncoder(false, true, true, true, true, true) +
        "/vehicle/" +
        encodeURIComponent(vehicleSelectionStore.data.vehicleId) +
        "/product/" +
        encodeURIComponent(productDetailStore.data.productId) +
        "/secondary/" +
        encodeURIComponent(productDetailStore.data.secondaryProductId)
      );
    case RouterStates.WISHLIST:
      return (
        url +
        decodeUrlShoppingContext() +
        getOrderIdentifier() +
        (applicationStore.data.vehicle
          ? "/vehicle/" + applicationStore.data.vehicle
          : "")
      );
    case RouterStates.PRINT_WISHLIST:
      return url + decodeUrlShoppingContext() + getOrderIdentifier();
    case RouterStates.PRINT_WISHLIST_TEST:
      return (
        url +
        decodeUrlShoppingContext() +
        getOrderIdentifier() +
        "/disablePrint/"
      );
    case RouterStates.PRINT_COMPARISON_FROM_TIRESIZE:
      return (
        url +
        "/search/" +
        productListStore.data.searchType +
        "/" +
        encodeURIComponent(applicationStore.data.search) +
        "/product/" +
        getProductTypeLowerCase() +
        "/compare/" +
        encodeURIComponent(applicationStore.data.compareDataLoad)
      );
  }
};

var urlEncoder = function (
  hasLanguage,
  language,
  hasDealer,
  dealerIdentifier,
  hasProductType,
  productType,
  routerState,
  searchType
) {
  var url = "";
  if (!isConsumerSite() && hasLanguage && !isEmpty(language))
    url = url + "/" + language;
  if (hasDealer && parseInt(dealerIdentifier) > 0)
    url = url + "/dealer/" + dealerIdentifier;
  if (hasProductType && !isEmpty(productType))
    url = url + "/product/" + productType;
  url = getRouterStateUrlData(url, routerState, searchType);
  return url;
};

export var Router = function () {};

/*
{ pathname: { language: true, dealer: true }, url: '/tires/season/{season}', route: RouterStates.SEARCH_BRANDS_BY_SEASON, func: searchBrandsBySeason },
{ pathname: { language: true, dealer: true }, url: '/tires/brands/{brandId}', route: RouterStates.SEARCH_BRANDS, func: searchBrands },
{ pathname: { language: true, dealer: true }, url: '/tires/season/{season}/models/{modelId}', route: RouterStates.SEARCH_MODELS_BY_SEASON, func: searchModelsBySeason },
{ pathname: { language: true, dealer: true }, url: '/tires/brands/{brandId}/models/{modelId}', route: RouterStates.SEARCH_MODELS, func: searchModels },
{ pathname: { language: true, dealer: true }, url: '/tires/season/{season}/models/{modelId}/partnumber/{tireId}', route: RouterStates.SEARCH_TIRE_BY_SEASON, func: searchTireBySeason },
{ pathname: { language: true, dealer: true }, url: '/tires/brands/{brandId}/models/{modelId}/partnumber/{tireId}', route: RouterStates.SEARCH_TIRE, func: searchTire },
*/

Router.routeList = [
  {
    pathname: { language: false, dealer: false },
    url: "",
    route: RouterStates.DEALER_SELECTION,
    func: root,
  }, // consumer site
  {
    pathname: { language: true, dealer: false },
    url: "",
    route: RouterStates.DEALER_SELECTION,
    func: root,
  },

  {
    pathname: { language: true, dealer: false },
    url: "/landingpage/",
    route: RouterStates.LANDING_PAGE,
    func: landingpage,
  },
  {
    pathname: { language: true, dealer: false },
    url: "/translation/dictionary/",
    route: RouterStates.TRANSLATION_DICTIONARY,
    func: translationDictionary,
  },

  {
    pathname: { language: true, dealer: true },
    url: "",
    route: RouterStates.HOMEPAGE,
    func: homepage,
  },

  {
    pathname: { language: false, dealer: false },
    url: "/search/{searchType}/{term}",
    route: RouterStates.SEARCH_RESULT,
    func: search,
  }, // consumer site

  {
    pathname: { language: true, dealer: true },
    url: "/search/{searchType}/{term}",
    route: RouterStates.SEARCH_RESULT,
    func: search,
  },

  {
    pathname: { language: true, dealer: true },
    url: "/search/{searchType}/{term}/product/{productType}/compare/{compareData}",
    route: RouterStates.PRINT_COMPARISON_FROM_TIRESIZE,
    func: comparisonFromTireSize,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/product/{productType}/type/{type}",
    route: RouterStates.YEAR,
    func: yearSelection,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/product/{productType}/type/{type}/year/{year}",
    route: RouterStates.MAKE,
    func: makeSelection,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/product/{productType}/type/{type}/year/{year}/make/{make}",
    route: RouterStates.CATEGORY,
    func: categorySelection,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/product/{productType}/type/{type}/year/{year}/make/{make}/category/{category}",
    route: RouterStates.MODEL,
    func: modelSelection,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/product/{productType}/type/{type}/year/{year}/make/{make}/category/{category}/model/{model}",
    route: RouterStates.TRIM,
    func: trimSelection,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/product/{productType}/type/{type}/year/{year}/make/{make}/category/{category}/model/{model}/trim/{trim}",
    route: RouterStates.PRODUCT_LIST,
    func: productList,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/product/{productType}/type/{type}/year/{year}/category/{category}/model/{model}",
    route: RouterStates.PRODUCT_LIST,
    func: productListModel,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/product/{productType}/type/{type}/chassis/{chassis}",
    route: RouterStates.PRODUCT_LIST,
    func: productListChassis,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/product/{productType}/year/{year}/make/{make}/category/{category}/model/{model}/trim/{trim}/vehicle/{vehicleId}/product/{productId}/secondary/{secondaryProductId}",
    route: RouterStates.PRODUCT_DETAIL,
    func: productDetail,
  },

  {
    pathname: { language: true, dealer: true },
    url: "/wishlist/{cart}",
    route: RouterStates.WISHLIST,
    func: wishlistReview,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/quote/{cart}",
    route: RouterStates.WISHLIST,
    func: wishlistReview,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/ecommerce/{cart}",
    route: RouterStates.WISHLIST,
    func: wishlistReview,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/order/{cart}",
    route: RouterStates.WISHLIST,
    func: wishlistReview,
  },

  {
    pathname: { language: true, dealer: true },
    url: "/order/complete/{cart}",
    route: RouterStates.WISHLIST,
    func: wishlistReview,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/order/review/{cart}",
    route: RouterStates.WISHLIST,
    func: wishlistReview,
  },

  {
    pathname: { language: true, dealer: true },
    url: "/ecommerce/checkout/{cart}",
    route: RouterStates.WISHLIST,
    func: wishlistReview,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/ecommerce/complete/{cart}",
    route: RouterStates.WISHLIST,
    func: wishlistReview,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/ecommerce/review/{cart}",
    route: RouterStates.WISHLIST,
    func: wishlistReview,
  },

  {
    pathname: { language: true, dealer: true },
    url: "/wishlist/{cart}/vehicle/{vehicle}",
    route: RouterStates.WISHLIST,
    func: wishlistReviewWithVehicle,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/quote/{cart}/vehicle/{vehicle}",
    route: RouterStates.WISHLIST,
    func: wishlistReviewWithVehicle,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/ecommerce/{cart}/vehicle/{vehicle}",
    route: RouterStates.WISHLIST,
    func: wishlistReviewWithVehicle,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/order/{cart}/vehicle/{vehicle}",
    route: RouterStates.WISHLIST,
    func: wishlistReviewWithVehicle,
  },

  {
    pathname: { language: true, dealer: true },
    url: "/order/complete/{cart}/vehicle/{vehicle}",
    route: RouterStates.WISHLIST,
    func: wishlistReviewWithVehicle,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/order/review/{cart}/vehicle/{vehicle}",
    route: RouterStates.WISHLIST,
    func: wishlistReviewWithVehicle,
  },

  {
    pathname: { language: true, dealer: true },
    url: "/ecommerce/checkout/{cart}/vehicle/{vehicle}",
    route: RouterStates.WISHLIST,
    func: wishlistReviewWithVehicle,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/ecommerce/complete/{cart}/vehicle/{vehicle}",
    route: RouterStates.WISHLIST,
    func: wishlistReviewWithVehicle,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/ecommerce/review/{cart}/vehicle/{vehicle}",
    route: RouterStates.WISHLIST,
    func: wishlistReviewWithVehicle,
  },

  {
    pathname: { language: true, dealer: true },
    url: "/wishlist/print/{cart}",
    route: RouterStates.PRINT_WISHLIST,
    func: wishlistPrint,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/quote/print/{cart}",
    route: RouterStates.PRINT_WISHLIST,
    func: wishlistPrint,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/ecommerce/print/{cart}",
    route: RouterStates.PRINT_WISHLIST,
    func: wishlistPrint,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/order/print/{cart}",
    route: RouterStates.PRINT_WISHLIST,
    func: wishlistPrint,
  },

  {
    pathname: { language: true, dealer: true },
    url: "/wishlist/print/{cart}/disablePrint",
    route: RouterStates.PRINT_WISHLIST_TEST,
    func: wishlistPrintTest,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/quote/print/{cart}/disablePrint",
    route: RouterStates.PRINT_WISHLIST_TEST,
    func: wishlistPrintTest,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/ecommerce/print/{cart}/disablePrint",
    route: RouterStates.PRINT_WISHLIST_TEST,
    func: wishlistPrintTest,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/order/print/{cart}/disablePrint",
    route: RouterStates.PRINT_WISHLIST_TEST,
    func: wishlistPrintTest,
  },

  {
    pathname: { language: true, dealer: true },
    url: "/dealer/contact/{dealerId}",
    route: RouterStates.CONTACT_DEALER,
    func: contactDealer,
  },

  {
    pathname: { language: true, dealer: true },
    url: "/product/{productType}/compare/{compareData}/vehicle/{vehicle}",
    route: RouterStates.COMPARISON,
    func: comparison,
  },
  {
    pathname: { language: true, dealer: true },
    url: "/product/{productType}/compare/{compareData}/vehicle/{vehicle}/productdata/{productdata}",
    route: RouterStates.COMPARISON,
    func: comparisonWithProductData,
  },
];

Router.decodeRouterState = function (appState = null, hash = null) {
  if (isEmpty(appState)) {
    appState = Router.appState;
  } else {
    Router.appState = appState;
  }

  var pathname,
    urlOrigin = getUrlOrigin(!isEmpty(hash));
  if (!isEmpty(hash)) {
    pathname = hash;
  } else {
    pathname = getPathname() + getHash();
  }

  //console.log('decodeRouterState, pathname: '+pathname);
  //console.log(urlOrigin);

  var temp = pathname.split("/"),
    routeList = Router.routeList,
    route = "",
    params = {},
    count = 0,
    key;
  if (sizeOf(temp) > urlOrigin.offset) {
    temp = temp.slice(urlOrigin.offset);

    //console.log(temp);

    for (var i in routeList) {
      var temp_count = 0,
        temp_routeUrlList = routeList[i].url.split("/").slice(1);
      if (sizeOf(temp) === sizeOf(temp_routeUrlList)) {
        for (var j = 0; j < sizeOf(temp); j++) {
          if (temp[j] === temp_routeUrlList[j]) {
            temp_count += 2;
          } else if (
            temp_routeUrlList[j].startsWith("{") &&
            temp_routeUrlList[j].endsWith("}")
          ) {
            temp_count++;
          }
          if (temp_count > count) {
            params = {};
            for (var m = 0; m < sizeOf(temp); m++) {
              if (
                temp_routeUrlList[m].startsWith("{") &&
                temp_routeUrlList[m].endsWith("}")
              ) {
                key = routerParamDecoder(
                  temp_routeUrlList[m].substring(
                    1,
                    sizeOf(temp_routeUrlList[m]) - 1
                  )
                );
                if (key === "productType") {
                  params[key] = temp[m].toUpperCase();
                } else {
                  params[key] = decodeURIComponent(temp[m]);
                }
              }
            }
            route = routeList[i].route;
            count = temp_count;
          }
        }
      }
    }
  } else {
    if (isNationalSite() || isGroupSite()) {
      if (pathname.indexOf("/dealer/") === -1) {
        if (
          pathname.indexOf("/landingpage") === -1 &&
          (applicationStore.data.nationalRetailSiteDestinationPage !==
            NATIONAL_START_PAGE.LANDING_PAGE ||
            window.location.href.indexOf("?") !== -1)
        ) {
          route = RouterStates.DEALER_SELECTION;
        } else {
          route = RouterStates.LANDING_PAGE;
        }
      } else {
        route = RouterStates.HOMEPAGE;
        params.dealer = decodeURIComponent(temp[5]);
      }
    } else {
      route = RouterStates.HOMEPAGE;
    }
  }

  /*
  if (
    isNationalSite()
    && window.location.href.indexOf('?vehicle=') === -1
    && route == RouterStates.DEALER_SELECTION
    && applicationStore.data.nationalRetailSiteDestinationPage == NATIONAL_START_PAGE.LANDING_PAGE
  ) {
    route = RouterStates.LANDING_PAGE;
  }
  */

  if (!isEmpty(getAppLanguage(true))) {
    params.lang = getAppLanguage(true);
  }

  //console.log('route: '+route+', appState.routerState: '+appState.routerState);

  if (!isEmpty(route) && !isEmpty(RouterStates[route])) {
    return {
      route: route ? route : appState.routerState,
      params: params,
    };
  } else {
    return {
      route: appState ? appState.routerState : null,
      params: {},
    };
  }
};

const getPathname = function () {
  if (!isEmpty(location.pathname)) {
    if (location.pathname.endsWith("/")) {
      return location.pathname;
    } else {
      if (!isEmpty(location.hash)) {
        return location.pathname + "/";
      } else {
        return location.pathname;
      }
    }
  } else {
    return "";
  }
};

const getHash = function () {
  if (!isEmpty(location.hash)) {
    if (location.hash.substring(1).startsWith("/")) {
      return location.hash.substring(2);
    } else {
      return location.hash.substring(1);
    }
  } else {
    return "";
  }
};

var routerParamDecoder = function (param) {
  switch (param) {
    case "brandId":
      return "selectedBrandId";
    case "showLoadingPage":
      return "showOnlyLoadingPage";
    case "compareData":
      return "compareDataLoad";
    case "productdata":
      return "compareProductdata";
    case "type":
      return "searchType";
    case "term":
      return "search";
    default:
      return param;
  }
};

Router.shoppingContext = {
  isWishlist: true,
  isQuote: false,
  isOrder: false,
  isEcommerce: false,
};

Router.setShoppingParameters = function (context, url) {
  switch (context) {
    case "wishlist":
      Router.shoppingContext = {
        isWishlist: true,
        isQuote: false,
        isOrder: false,
        isEcommerce: false,
        context: url,
      };
      break;
    case "quote":
      Router.shoppingContext = {
        isWishlist: false,
        isQuote: true,
        isOrder: false,
        isEcommerce: false,
        context: url,
      };
      break;
    case "order":
      Router.shoppingContext = {
        isWishlist: false,
        isQuote: false,
        isOrder: true,
        isEcommerce: false,
        context: url,
      };
      break;
    case "ecommerce":
      Router.shoppingContext = {
        isWishlist: false,
        isQuote: false,
        isOrder: false,
        isEcommerce: true,
        context: url,
      };
      break;
  }
};

Router.collectShoppingContext = function () {
  return Router.shoppingContext;
};

Router.prototype.bootstrap = function () {
  globalState
    .select("applicationState", "routerState")
    .on("update", this.onChange.bind(this));
  applicationStore.addChangeListener(this.onChange.bind(this));
  productListStore.addChangeListener(this.onChange.bind(this));
  searchStore.addChangeListener(this.onChange.bind(this));

  initialized = false;
  if (!initialized) {
    root();
  }

  decodeUrlSSO(location.href);
  redirectSearchToWishlist(location.search);

  if (window.location.href.indexOf("#") > -1) {
    history.replaceState(
      "",
      {},
      location.pathname + location.hash.substring(1) + location.search
    );
  }

  if (!isOutdatedSafari()) {
    window.onpopstate = handleBrowserBack;
  }
};

Router.history = [];

const handleBrowserBack = function () {
  //var last = Router.history.pop();

  /*
  console.log('handleBrowserBack');
  console.log(Router.history);
  console.log('check: '+(!isEmpty(Router.history) && sizeOf(Router.history) > 0));
  console.log('last: '+last);
  */

  //console.log('window.onpopstate, state: '+history.state+', router: '+RouterStates[history.state]+', check: '+(!isEmpty(RouterStates[history.state]))+', history: '+pop+', check: '+(!isEmpty(Router.history) && sizeOf(Router.history) > 0));
  //console.log(Router.history);

  if (!isEmpty(Router.history) && sizeOf(Router.history) > 0) {
    //applicationActions.changeRouterStateDirectly(RouterStates[history.state]);
    applicationActions.goBack();
  } else {
    history.back();
  }
};

Router.resetUrlSearch = function () {
  location.search = "";
};

Router.prototype.onChange = function (...arg) {
  //var decoded = decodeUrlHash(location.href);

  //console.log('Router.prototype.onChange');
  //console.log(getNewHash());
  //console.log(processNewHash(getNewHash()));

  var hashDecipher = processNewHash(getNewHash());
  if (hashDecipher.fireHashChange) {
    var href = location.href,
      search = location.search,
      hash = hashDecipher.hash,
      routerObject = Router.decodeRouterState(null, hashDecipher.hash),
      urlOrigin = getUrlOrigin(hashDecipher);

    if (!isEmpty(hash)) {
      if (hash.endsWith("?")) {
        hash = hash.slice(0, -1);
      }
    }

    if (
      isEmpty(Router.history) ||
      Router.history[sizeOf(Router.history) - 1] !== routerObject.route
    ) {
      Router.history.push(routerObject.route);
    }

    if (
      (!isEmpty(search) &&
        (search.toLowerCase().indexOf("vehicleyear=") > -1 ||
          search.toLowerCase().indexOf("vehicle=") > -1 ||
          search.toLowerCase().indexOf("tiresize=") > -1 ||
          search.toLowerCase().indexOf("user_locale=") > -1 ||
          search.toLowerCase().indexOf("currentbaumuster=") > -1 ||
          search.toLowerCase().indexOf("vehicle_servicedealerid=") > -1 ||
          search.toLowerCase().indexOf("season=") > -1 ||
          search.toLowerCase().indexOf("submodeltiresizeid=") > -1 ||
          search.toLowerCase().indexOf("showform=") > -1 ||
          search.toLowerCase().indexOf("utm_medium=") > -1 ||
          search.toLowerCase().indexOf("utm_source=") > -1 ||
          search.toLowerCase().indexOf("utm_campaign=") > -1 ||
          search.toLowerCase().indexOf("autoadd=") > -1 ||
          search.toLowerCase().indexOf("screen=") > -1)) ||
      (!isEmpty(href) && href.toLowerCase().indexOf("/campaign/") > -1)
    ) {
      if (
        hashServiceShouldRun() &&
        (search.toLowerCase().indexOf("vehicle=") === -1 ||
          sizeOf(dealerSelectionStore.dealers) > 0)
      ) {
        store.dispatch(decodedUrl({ href, search }));
        // applicationActions.decodeUrlHash(href, search);
      }
    }

    /*
    if (enableAdobeLaunch()) {
      //setAdobeDigitalData(setAdobeLauncherDigitalData());
      runAdobePageView();
    }
    //*/

    history.pushState(routerObject.route, {}, urlOrigin.url + hash + search);
  }
};

const getNewHash = function () {
  var routerState = globalState.select("applicationState", "routerState").get(),
    searchType = productListStore.data.searchType,
    language = getAppLanguage(),
    dealerId = getAppDealerId(),
    productType = getProductTypeLowerCase();
  switch (routerState) {
    case RouterStates.DEALER_SELECTION:
    case RouterStates.LANDING_PAGE:
    case RouterStates.CONTACT_DEALER:
    case RouterStates.TRANSLATION_DICTIONARY:
      return urlEncoder(
        true,
        language,
        false,
        "",
        false,
        "",
        routerState,
        null
      );
    case RouterStates.HOMEPAGE:
    case RouterStates.SEARCH_RESULT:
    case RouterStates.WISHLIST:
    case RouterStates.PRINT_WISHLIST:
    case RouterStates.PRINT_WISHLIST_TEST:
    case RouterStates.PRINT_COMPARISON_FROM_TIRESIZE:
    case RouterStates.SEARCH_BRANDS_BY_SEASON:
    case RouterStates.SEARCH_BRANDS:
    case RouterStates.SEARCH_MODELS_BY_SEASON:
    case RouterStates.SEARCH_MODELS:
    case RouterStates.SEARCH_TIRE_BY_SEASON:
    case RouterStates.SEARCH_TIRE:
      return urlEncoder(
        true,
        language,
        true,
        dealerId,
        false,
        "",
        routerState,
        null
      );
    case RouterStates.YEAR:
    case RouterStates.MAKE:
    case RouterStates.CATEGORY:
    case RouterStates.MODEL:
    case RouterStates.TRIM:
    case RouterStates.PRODUCT_DETAIL:
      return urlEncoder(
        true,
        language,
        true,
        dealerId,
        true,
        productType,
        routerState,
        null
      );
    case RouterStates.PRODUCT_LIST:
      return urlEncoder(
        true,
        language,
        true,
        dealerId,
        true,
        productType,
        routerState,
        searchType
      );
    default:
      return "";
  }
};

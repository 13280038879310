import { globalState } from '../GlobalState.js';
import { DispatcherActions } from '../constants/DispatcherActions.js';
import { RouterStates } from '../constants/RouterStates.js';
import { AbstractStore } from './AbstractStore.js';
import { applicationStore } from './ApplicationStore.js';
import { languageStore } from './LanguageStore.js';
import { shoppingCartActions } from '../actions/ShoppingCartActions.js';
import { ProductTypes } from '../constants/ProductTypes.js';

import { getDestinationInverse } from '../api/RequestBuilderHelper.js';

import { enableAdobeLaunch, setAdobeLauncherDigitalData } from "../service/AdobeLaunchService";
import { sizeOf, isEmpty, getAppLanguage, getAppDealerId, checkArrayFieldsExist } from '../service/UtilityService';
import {
	tireMappingFunction,
	wheelMappingFunction,
	accessoryMappingFunction,
	packageMappingFunction,
	getPricingFromQuantityAndDetailsRefactored
} from '../service/ProductList';
import {
	extractNationalDeliveries,
	checkForLocalStock,
	checkForLocalPlusDeliveries,
	checkForNationalDeliveries,
	getNonOrderItems,
	getIsOrderShippedWithOtherGoods
} from '../service/OrderService.js';

var _ = require('lodash');
var Immutable = require('immutable');
var Moment = require('moment');

var ShoppingCartStore = AbstractStore.extend({

	constructor: function () {
		var defaultValues = Immutable.Map({
			items: Immutable.List.of(),
			cart: null,
			openedItemType: null,
			cartOpened: false,
      cartModalOpened: false,
			detail: false,
			showWarning: false,
			showCartQuantityLimitWarning: false,
			showModal: false,
			newItemAddedFlag: false,
			scheduleWishlistModal: false,
			saveWishlistSuccess: false,
			scheduleAppointmentWishlistModal: false,
			scheduleAppointmentWishlistModalAppointmentSent: false,
			scheduleAppointmentWishlistModalAppointmentFailed: false,
			saveSessionReferenceSent: false,
			saveSessionReferenceFailed: false,
			printCart: null,
			ntdOrderData: null,
			ntdPreviewFailed: false,
			ntdPlaceOrderData: null,
			ntdPlaceOrderFailed: false,
			ntdOrderHistory: null,
      ecommerceHistory: null,
      pricingAnalysisList: [],
      pricingAnalysisBrands: [],
			enableWishlistFormModal: false,
			enableWishlistAppointmentInegrationModal: false,
			wishlistFormType: null,
			wishlistDealerFormType: null
		});

    this.data = defaultValues;

		ShoppingCartStore.__super__.constructor.apply(this, arguments);
	},

	getActionHandlers: function () {
		var handlers = {};
    handlers[DispatcherActions.INITIALIZE_APP_STATE] = this.onInitializeAppState;
		handlers[DispatcherActions.SHOPPING_CART_RELOADED] = this.onReloadCart;
    handlers[DispatcherActions.WISHLIST_REVIEW_LOADED] = this.onReloadCart;
		handlers[DispatcherActions.LIST_OF_DEALERS_LOADED] = this.emptyCart;
		handlers[DispatcherActions.LIST_OF_PROVINCIAL_DEALERS_LOADED] = this.emptyCart;
		handlers[DispatcherActions.SHOPPING_CART_REQUEST_EMPTY] = this.emptyCart;
		handlers[DispatcherActions.SHOPPING_CART_GET_NEW_CART] = this.getNewCart;
		handlers[DispatcherActions.SHOPPING_CART_MODAL_OPEN] = this.onShoppingCartModalOpen;
    handlers[DispatcherActions.SHOPPING_CART_MODAL_CLOSE] = this.onShoppingCartModalClose;
		handlers[DispatcherActions.SHOPPING_CART_OPEN] = this.onShoppingCartOpen;
		handlers[DispatcherActions.SHOPPING_CART_CLOSE] = this.onShoppingCartClose;
		handlers[DispatcherActions.SHOPPING_CART_OPEN_DETAIL] = this.onShoppingCartOpenDetail;
		handlers[DispatcherActions.SHOPPING_CART_CLOSE_DETAIL] = this.onShoppingCartCloseDetail;
		handlers[DispatcherActions.SHOPPING_CART_CHANGED_REQUIRED_QUANTITY] = this.onShoppingCartChangedRequiredQuantity;
		handlers[DispatcherActions.SHOPPING_CART_SHOW_WARNING] = this.onShowCartWarning;
		handlers[DispatcherActions.SHOPPING_CART_CLOSE_WARNING] = this.onCloseCartWarning;
		handlers[DispatcherActions.SHOPPING_CART_CLOSE_MODAL] = this.onCloseModal;
		handlers[DispatcherActions.MODAL_REQUEST_UNMOUNT] = this.onRequestWarningClose;
		handlers[DispatcherActions.SHOPPING_CART_CLOSE_QUANTITY_LIMIT_WARNING] = this.onCloseCartQuantityLimitWarning;
		handlers[DispatcherActions.SHOPPING_CART_NEW_ITEM_SEEN] = this.newItemSeen;
		handlers[DispatcherActions.OPEN_WISHLIST_SCHEDULE_MODAL] = this.openWishlistScheduleModal;
		handlers[DispatcherActions.CLOSE_WISHLIST_SCHEDULE_MODAL] = this.closeWishlistScheduleModal;
		handlers[DispatcherActions.WISHLIST_EMAIL_SENT] = this.wishlistEmailModalEmailSent; // close and show a modal with success message?
		handlers[DispatcherActions.WISHLIST_EMAIL_FAILED] = this.wishlistEmailModalEmailFailed; // close and show a modal with success message?
		handlers[DispatcherActions.WISHLIST_SAVE_SUCCESSFUL] = this.wishlistSaveSuccessful;
		handlers[DispatcherActions.WISHLIST_SAVE_FAILURE] = this.wishlistSaveFailure;
		handlers[DispatcherActions.OPEN_WISHLIST_APPOINTMENT_MODAL] = this.openAppointmentWishlistModal;
		handlers[DispatcherActions.CLOSE_WISHLIST_APPOINTMENT_MODAL] = this.closeAppointmentWishlistModal;
		handlers[DispatcherActions.WISHLIST_APPOINTMENT_SENT] = this.appointmentWishlistModalAppointmentSent; // close and show a modal with success message?
		handlers[DispatcherActions.WISHLIST_APPOINTMENT_FAILED] = this.appointmentWishlistModalAppointmentFailed; // close and show a modal with success message?
		handlers[DispatcherActions.SESSION_REFERENCE_SAVED] = this.sessionReferenceSaved;
		handlers[DispatcherActions.SESSION_REFERENCE_FAILURE] = this.sessionReferenceFailed;
		handlers[DispatcherActions.SESSION_REFERENCE_CLOSE_MODAL] = this.onCloseSessionReferenceModal;
		handlers[DispatcherActions.WISHLIST_PRINT_LOADED] = this.onWishlistPrintLoaded;
		handlers[DispatcherActions.NTD_ORDER_GET_PREVIEW] = this.onGetNtdOrderPreview;
		handlers[DispatcherActions.NTD_ORDER_GET_PLACE_ORDER] = this.onGetPlaceNtdOrder;
		handlers[DispatcherActions.NTD_CLOSE_MODAL] = this.resetNtdOrderData;
		handlers[DispatcherActions.NTD_ORDERS_GET_HISTORY] = this.ntdOrdersHistoryLoaded;
		handlers[DispatcherActions.ECOMMERCE_GET_HISTORY] = this.ecommerceHistoryLoaded;
		handlers[DispatcherActions.GET_TIRE_PRICING_ANALYSIS] = this.pricingAnalysisLoaded;
		handlers[DispatcherActions.GET_TIRE_PRICING_INCENTIVE] = this.pricingIncentiveLoaded;
		handlers[DispatcherActions.SHOPPING_CART_CLOSE_FORM] = this.shoppingCartCloseFormModal;
		handlers[DispatcherActions.SHOPPING_CART_OPEN_FORM] = this.shoppingCartOpenFormModal;
		handlers[DispatcherActions.SHOPPING_CART_APPOINTMENT_INTEGRATION_MODAL_CLOSE] = this.shoppingCartCloseAppointmentIntegrationModal;
		handlers[DispatcherActions.SHOPPING_CART_APPOINTMENT_INTEGRATION_MODAL_OPEN] = this.shoppingCartOpenAppointmentIntegrationModal;
    handlers[DispatcherActions.SHOPPING_CART_CLOSE_DEALER_FORM] = this.shoppingCartCloseDealerFormModal;
    handlers[DispatcherActions.SHOPPING_CART_OPEN_DEALER_FORM] = this.shoppingCartOpenDealerFormModal;
		return handlers;
	},

	emptyCart: function () {
		this.data = Immutable.Map({
			items: Immutable.List.of(),
			cart: null,
			openedItemType: null,
			cartOpened: false,
      cartModalOpened: false,
			detail: false,
			showWarning: false,
			newItemAddedFlag: false,
			scheduleWishlistModal: false,
			scheduleAppointmentWishlistModal: false,
			saveSessionReferenceSent: false,
			saveSessionReferenceFailed: false,
			printCart: null,
			ntdOrderData: null,
			ntdPreviewFailed: false,
			ntdPlaceOrderData: null,
			ntdPlaceOrderFailed: false,
			ntdOrderHistory: null,
      ecommerceHistory: null,
      pricingAnalysisList: [],
      pricingAnalysisBrands: [],
      enableWishlistFormModal: false,
      enableWishlistAppointmentInegrationModal: false,
      wishlistFormType: null,
      wishlistDealerFormType: null
		});
	},

	getNewCart: function (response) {
		this.data = Immutable.Map({
			items: Immutable.List.of(),
			cart: response.orderNumber,
			openedItemType: null,
			cartOpened: false,
      cartModalOpened: false,
			detail: false,
			showWarning: false,
			newItemAddedFlag: false,
			scheduleWishlistModal: false,
			scheduleAppointmentWishlistModal: false,
			saveSessionReferenceSent: false,
			saveSessionReferenceFailed: false,
			ntdOrderData: null,
			ntdPreviewFailed: false,
			ntdPlaceOrderData: null,
			ntdPlaceOrderFailed: false,
			ntdOrderHistory: null,
      ecommerceHistory: null,
      pricingAnalysisList: [],
      pricingAnalysisBrands: [],
      pricingIncentiveList: [],
      enableWishlistFormModal: false,
      enableWishlistAppointmentInegrationModal: false,
      wishlistFormType: null,
      enableWishlistDealerFormModal: false,
      wishlistDealerFormType: null
		});
		this.emitChange();
	},

  shoppingCartCloseFormModal: function() {
    this.data = this.data.set('enableWishlistFormModal', false);
    this.emitChange();
  },

  shoppingCartOpenFormModal: function(formType) {
    this.data = this.data.set('wishlistFormType', formType);
    this.data = this.data.set('enableWishlistFormModal', true);
    if (enableAdobeLaunch()) {
      setAdobeDigitalData(setAdobeLauncherDigitalData());
    }
    this.emitChange();
  },

  shoppingCartCloseAppointmentIntegrationModal: function() {
    this.data = this.data.set('enableWishlistAppointmentInegrationModal', false);
    this.emitChange();
  },

  shoppingCartOpenAppointmentIntegrationModal: function(formType) {
    this.data = this.data.set('enableWishlistAppointmentInegrationModal', true);
    this.emitChange();
  },

  shoppingCartCloseDealerFormModal: function() {
    this.data = this.data.set('enableWishlistDealerFormModal', false);
    this.emitChange();
  },

  shoppingCartOpenDealerFormModal: function(formType) {
    this.data = this.data.set('wishlistDealerFormType', formType);
    this.data = this.data.set('enableWishlistDealerFormModal', true);
    this.emitChange();
  },

	resetNtdOrderData: function() {
		this.data = this.data.set('ntdOrderData', null);
		this.data = this.data.set('ntdPreviewFailed', false);
		this.data = this.data.set('ntdPlaceOrderData', null);
		this.data = this.data.set('ntdPlaceOrderFailed', null);
		this.data = this.data.set('ntdOrderHistory', null);
		this.data = this.data.set('ecommerceHistory', null);
		this.data = this.data.set('pricingAnalysisList', null);
		this.data = this.data.set('pricingIncentiveList', null);
	},

	ntdOrdersHistoryLoaded: function(response) {
    if (response) this.data = this.data.set('ntdOrderHistory', Immutable.fromJS(response));
    this.emitChange();
  },

  ecommerceHistoryLoaded: function(response) {
    if (response) this.data = this.data.set('ecommerceHistory', Immutable.fromJS(response));
    this.emitChange();
  },

  pricingAnalysisLoaded: function(response) {
    var dictionary = languageStore.getDictionary(), dataList = [], tireInfo, dealerPrice, competitorPriceCalculation;
		if (response.data.partPriceMap) {

			for (var i in response.data.partPriceMap) {

        tireInfo = response.data.partPriceMap[i].tireInfo;
        dealerPrice = response.data.partPriceMap[i].comparisonPricingInfo.dealerPrice;
        competitorPriceCalculation = response.data.partPriceMap[i].comparisonPricingInfo.competitorPriceCalculation;

        if (!isEmpty(competitorPriceCalculation) && parseFloat(competitorPriceCalculation.minRetailPrice) > 0) {
          dataList.push([
            tireInfo.manufacturer_name,
            tireInfo.model_name,
            tireInfo.primary_part_number,
            response.tiresize,
            dealerPrice.my_price,
            competitorPriceCalculation ? competitorPriceCalculation.minRetailPrice : "",
            competitorPriceCalculation ? competitorPriceCalculation.avgRetailPrice : "",
            tireInfo.booked_qty,
            tireInfo.sales_qty,
            tireInfo.season,
            dealerPrice.cost_price,
            dealerPrice.booking_price,
          ]);
				}

			}

      var tempList = [], brandList = [{ key: 0, value: dictionary.showAllBrands }];

      for (var i in dataList) {

        if (tempList.indexOf(dataList[i][0]) === -1) {
          tempList.push(dataList[i][0]);
        }

      }

      tempList = tempList.sort(function(a, b) {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
			});

      for (var i in tempList) {
        brandList.push({
          key: tempList[i],
          value: tempList[i]
        });
			}

      if (dataList) {
        this.data = this.data.set('pricingAnalysisList', dataList);
        this.data = this.data.set('pricingAnalysisBrands', brandList);
      }

		}

    this.emitChange();
	},

  pricingIncentiveLoaded: function(response) {
    var dictionary = languageStore.getDictionary(), dataList = [], tireInfo, pricingInfo, extraInfo;
    if (!!response.partPriceMap) {

      for (var i in response.partPriceMap) {

        tireInfo = response.partPriceMap[i].tire_info;
        pricingInfo = response.partPriceMap[i].price_list_info;
        extraInfo = response.partPriceMap[i].extra;

        dataList.push([
          tireInfo.partDetails.primaryPart.tireManufacturerName,
          tireInfo.partDetails.primaryPart.tireModelName,
          tireInfo.partSummary.primaryPart.itemPartNumber,
          tireInfo.partDetails.primaryPart.formattedTiresize,
          pricingInfo.cost_price,
          pricingInfo.list_price,
          pricingInfo.tier_1_savings,
          pricingInfo.tier_2_savings,
          pricingInfo.tier_3_savings,
          pricingInfo.tier_4_savings,
          tireInfo.partDetails.primaryPart.tireSeason,
          extraInfo['all_weather'],
        ]);

      }

      var tempListBrands = [], brandList = [{ key: 0, value: dictionary.showAllBrands }], tempListTiresizes = [], tiresizeList = [{ key: 0, value: dictionary.showAllTiresizes }];

      for (var i in dataList) {

        if (tempListBrands.indexOf(dataList[i][0]) === -1) {
          tempListBrands.push(dataList[i][0]);
        }

        if (tempListTiresizes.indexOf(dataList[i][3]) === -1) {
          tempListTiresizes.push(dataList[i][3]);
        }

      }

      tempListBrands = tempListBrands.sort(function(a, b) {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });

      for (var i in tempListBrands) {
        brandList.push({
          key: tempListBrands[i],
          value: tempListBrands[i]
        });
      }

      tempListTiresizes = tempListTiresizes.sort(function(a, b) {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });

      for (var i in tempListTiresizes) {
        tiresizeList.push({
          key: tempListTiresizes[i],
          value: tempListTiresizes[i]
        });
      }

      if (dataList) {
        this.data = this.data.set('pricingIncentiveList', dataList);
        this.data = this.data.set('pricingIncentiveBrands', brandList);
        this.data = this.data.set('pricingIncentiveTiresizes', tiresizeList);
      }

    }

    this.emitChange();
	},

	onGetPlaceNtdOrder: function(response) {
		var orderPlacement = 0;
		if (response && response.hasOwnProperty("orderFulfillmentList") && response.orderFulfillmentList[0]) {
			orderPlacement = sizeOf(response.orderFulfillmentList) - 1;
			this.data = this.data.set('ntdPlaceOrderData', {
				orderNumber: response.orderFulfillmentList[orderPlacement].fulfillment_value.orderNumber,
				purchaseOrderNumber: response.orderFulfillmentList[orderPlacement].fulfillment_value.customerPoNumber,
				isPickUpOrder: response.orderFulfillmentList[orderPlacement].fulfillment_value.pickup === "Y",
				customerName: response.customerName,
				customerEmail: response.customerEmail,
				customerPhone: response.customerPhone,
				customerNotes: response.customerNotes,
				appointmentRequestDate: response.appointmentRequestDate,
				appointmentRequestTime: response.appointmentRequestTime,
				customerContactMethodPreference: response.customerContactMethodPreference
			});
		} else {
			this.data = this.data.set('ntdPlaceOrderFailed', true);
		}

		this.emitChange();
	},

	onGetNtdOrderPreview: function (response) {
		var hasNtdStockItemList = [], cartItemList = [], cartItemNameList = [];
		if (response && response.hasOwnProperty("orderFulfillmentList") && response.orderFulfillmentList[0]) {
			var dictionary = languageStore.getDictionary();
			var partNumberText = dictionary.product.partNumber;
			var language = getAppLanguage();
			var orderItems = Immutable.List.of();
			var itemListCount = sizeOf(response.orderFulfillmentList) - 1;
			if (sizeOf(response.orderItemList) > 0) {
				response.orderItemList.map(function(item, index) {
					cartItemList.push(item.itemPart.partDetails.primaryPart.tireManufacturerPartNumber);
					cartItemNameList.push(item.itemTitle);
				});
			}
			if (response.orderFulfillmentList[itemListCount].fulfillment_value.orderLines) {
				if (response.orderFulfillmentList[itemListCount].fulfillment_value.orderLines.orderLine.hasOwnProperty("fulfillments")) {
					hasNtdStockItemList.push(response.orderFulfillmentList[itemListCount].fulfillment_value.orderLines.orderLine.ATDProductNumber);
					var orderItemCartIndex = 0;
					response.orderItemList.map(function(orderItem, index) {
						if (orderItem.itemPart.partDetails.primaryPart.tireManufacturerPartNumber === response.orderFulfillmentList[itemListCount].fulfillment_value.orderLines.orderLine.ATDProductNumber) {
							orderItemCartIndex = index;
						}
					});

					orderItems = [Object.create({
						productId: response.orderItemList[orderItemCartIndex].itemId,
						productOrderItemId: response.orderItemList[orderItemCartIndex].orderItemId,
						productTitle: response.orderItemList[orderItemCartIndex].itemTitle,
						productTireSize: response.orderItemList[orderItemCartIndex].itemPart.partDetails.primaryPart.formattedTiresize,
						productPartNumber: response.orderItemList[orderItemCartIndex].itemPart.partDetails.primaryPart.tireManufacturerPartNumber,
						productLoadSpeedPly: response.orderItemList[orderItemCartIndex].itemPart.partDetails.primaryPart.tireLoadRating + " / " + response.orderItemList[orderItemCartIndex].itemPart.partDetails.primaryPart.tireSpeedIndex + " / " + response.orderItemList[orderItemCartIndex].itemPart.partDetails.primaryPart.tirePlyRating,
						ntdProductTitle: response.orderFulfillmentList[itemListCount].fulfillment_value.orderLines.orderLine.description,
						ATDProductNumber: response.orderFulfillmentList[itemListCount].fulfillment_value.orderLines.orderLine.ATDProductNumber,
						billTo: response.orderFulfillmentList[itemListCount].fulfillment_value.orderLines.orderLine.billTo,
						cartLineNumber: response.orderFulfillmentList[itemListCount].fulfillment_value.orderLines.orderLine.cartLineNumber,
						productCost: response.orderFulfillmentList[itemListCount].fulfillment_value.orderLines.orderLine.cost,
						productFet: response.orderFulfillmentList[itemListCount].fulfillment_value.orderLines.orderLine.fet,
						lineTotal: response.orderFulfillmentList[itemListCount].fulfillment_value.orderLines.orderLine.lineTotal,
						totalQuantity: response.orderFulfillmentList[itemListCount].fulfillment_value.orderLines.orderLine.quantity,
						orderBreakDown: response.orderFulfillmentList[itemListCount].fulfillment_value.orderLines.orderLine.fulfillments.fulfillments,
						hasLocalStock: checkForLocalStock(response.orderFulfillmentList[itemListCount].fulfillment_value.orderLines.orderLine),
						orderNationalShipmentProducts: extractNationalDeliveries(response.orderItemList[orderItemCartIndex].itemId, response.orderItemList[orderItemCartIndex].itemTitle[language], partNumberText + ": " + response.orderItemList[orderItemCartIndex].itemPart.partDetails.primaryPart.tireManufacturerPartNumber, response.orderFulfillmentList[itemListCount].fulfillment_value.orderLines.orderLine.fulfillments)
					})];
				} else {
					orderItems = response.orderFulfillmentList[itemListCount].fulfillment_value.orderLines.orderLine.map(function(orderLine, index) {
						hasNtdStockItemList.push(orderLine.ATDProductNumber);
						var orderItemCartIndex = 0;
						response.orderItemList.map(function(orderItem, index) {
							if (response.orderItemList[index].itemPart.partDetails.primaryPart.tireManufacturerPartNumber === orderLine.ATDProductNumber) {
								orderItemCartIndex = index;
							}
						});

						return ({
							productId: response.orderItemList[orderItemCartIndex].itemId,
							productOrderItemId: response.orderItemList[orderItemCartIndex].orderItemId,
							productTitle: response.orderItemList[orderItemCartIndex].itemTitle,
							productTireSize: response.orderItemList[orderItemCartIndex].itemPart.partDetails.primaryPart.formattedTiresize,
							productPartNumber: response.orderItemList[orderItemCartIndex].itemPart.partDetails.primaryPart.tireManufacturerPartNumber,
							productLoadSpeedPly: response.orderItemList[orderItemCartIndex].itemPart.partDetails.primaryPart.tireLoadRating + " / " + response.orderItemList[orderItemCartIndex].itemPart.partDetails.primaryPart.tireSpeedIndex + " / " + response.orderItemList[orderItemCartIndex].itemPart.partDetails.primaryPart.tirePlyRating,
							ntdProductTitle: orderLine.description,
							ATDProductNumber: orderLine.ATDProductNumber,
							billTo: orderLine.billTo,
							cartLineNumber: orderLine.cartLineNumber,
							productCost: orderLine.cost,
							productFet: orderLine.fet,
							lineTotal: orderLine.lineTotal,
							totalQuantity: orderLine.quantity,
							orderBreakDown: orderLine.fulfillments.fulfillments,
							hasLocalStock: checkForLocalStock(orderLine),
							orderNationalShipmentProducts: extractNationalDeliveries(response.orderItemList[orderItemCartIndex].itemId, response.orderItemList[orderItemCartIndex].itemTitle[language], partNumberText + ": " + response.orderItemList[orderItemCartIndex].itemPart.partDetails.primaryPart.tireManufacturerPartNumber, orderLine.fulfillments)
						})
					});
				}
			}
			this.data = this.data.set('ntdOrderData', {
				consumerData: response.orderFulfillmentList[itemListCount].fulfillment_value.consumerData,
				fillKill: response.orderFulfillmentList[itemListCount].fulfillment_value.fillKill,
				orderTotal: response.orderFulfillmentList[itemListCount].fulfillment_value.orderTotal,
				orderType: response.orderFulfillmentList[itemListCount].fulfillment_value.orderType,
				pickup: response.orderFulfillmentList[itemListCount].fulfillment_value.pickup,
        isTest: response.orderFulfillmentList[itemListCount].fulfillment_value.isTest == "1",
				poNumber: response.orderFulfillmentList[itemListCount].fulfillment_value.customerPoNumber,
				timestamp: Moment(response.orderFulfillmentList[itemListCount].created_timestamp).format('llll'),
				orderItems: response.orderFulfillmentList[itemListCount].fulfillment_value.orderLines ? orderItems : {},
				hasLocalPlusItems: checkForLocalPlusDeliveries(response.orderFulfillmentList[itemListCount].fulfillment_value.orderLines),
				hasFreightItems: checkForNationalDeliveries(response.orderFulfillmentList[itemListCount].fulfillment_value.orderLines),
				nonOrderItemList: getNonOrderItems(hasNtdStockItemList, cartItemList, cartItemNameList),
				orderShippedWithOtherGoods: getIsOrderShippedWithOtherGoods(response.orderFulfillmentList),
				customerName: response.customerName,
				customerEmail: response.customerEmail,
				customerPhone: response.customerPhone,
				customerNotes: response.customerNotes,
				appointmentRequestDate: response.appointmentRequestDate,
				appointmentRequestTime: response.appointmentRequestTime,
				customerContactMethodPreference: response.customerContactMethodPreference
			});
		} else {
			this.data = this.data.set('ntdPreviewFailed', true);
		}

		this.emitChange();
	},

	onWishlistPrintLoaded: function(cart) {
		this.data = this.data.set('printCart', cart);
		this.emitChange();
	},

	onCloseCartQuantityLimitWarning: function () {
		this.data = this.data.set('showCartQuantityLimitWarning', false);
		this.emitChange();
	},

	sessionReferenceSaved: function () {
		this.data = this.data.set('saveSessionReferenceShowModal', true);
		this.data = this.data.set('saveSessionReferenceSent', true);
		this.emitChange();
	},

	sessionReferenceFailed: function () {
		this.data = this.data.set('saveSessionReferenceShowModal', true);
		this.data = this.data.set('saveSessionReferenceFailed', true);
		this.emitChange();
	},

	onCloseSessionReferenceModal: function () {
		this.data = this.data.set('saveSessionReferenceShowModal', false);
		this.data = this.data.set('saveSessionReferenceSent', false);
		this.data = this.data.set('saveSessionReferenceFailed', false);
		this.emitChange();
	},

	shouldProceed: function(message, proceed) {
    if (message.scope === "warning") {
      switch (message.type) {
        case "PART_PRICING_MISSING":
          return false;
        default:
          return proceed;
      }
    } else {
    	return false;
		}
	},

	onReloadCart: function (response) {
		var proceed = true, shouldProceed = this.shouldProceed;
		if (sizeOf(response.orderMessageList) > 0) {
			response.orderMessageList.forEach(function (message) {
        proceed = shouldProceed(message, proceed);
			});
		}
		if (proceed) {
			this.data = this.data.set('cart', response.orderId);
			var cartItemId = 0, warnings = {}, itemNew = null, itemPart = null;
			if (response.orderItemList && sizeOf(response.orderItemList) > 0) {
				response.orderItemList.forEach(function (cartItem) {
					cartItemId = cartItem.orderItemId;
					warnings = cartItem.itemWarnings;
					itemPart = cartItem.itemPart;

					var partPricing = cartItem.itemPart.partPricing.primaryPart;
					if (checkArrayFieldsExist(itemPart, ['partPricing', 'primaryPart'])) {
						partPricing = itemPart['partPricing']['primaryPart'];
          }

					var quantity = {
						id: cartItem.pricingContextId + parseInt(cartItem.itemQty),
						value: parseInt(cartItem.itemQty),
						title: {
							en: `${parseInt(cartItem.itemQty)}${cartItem.pricingContextId > 0 ? ' - ' + cartItem.itemPromotion['en'] : ''}`,
							fr: `${parseInt(cartItem.itemQty)}${cartItem.pricingContextId > 0 ? ' - ' + cartItem.itemPromotion['fr'] : ''}`
						},
						pricingContextId: cartItem.pricingContextId
					};

					var price = getPricingFromQuantityAndDetailsRefactored(partPricing, quantity);

					var product = null;
					switch (getDestinationInverse(cartItem.itemType)) {
						case ProductTypes.TIRES:
							product = tireMappingFunction(true, applicationStore.data.dealerIgnoreTireStock, "", applicationStore.data.setPartQuantityToOne)(cartItem.itemPart);
							break;
						case ProductTypes.ALLOY_WHEELS:
							product = wheelMappingFunction(true, applicationStore.data.dealerIgnoreWheelStock, "", applicationStore.data.setPartQuantityToOne)(cartItem.itemPart);
							break;
						case ProductTypes.ACCESSORIES:
							product = accessoryMappingFunction(true, applicationStore.data.dealerIgnoreAccessoryStock, applicationStore.data.setPartQuantityToOne)(cartItem.itemPart);
							break;
						case ProductTypes.PACKAGES:
							product = packageMappingFunction(true, applicationStore.data.dealerIgnorePackageStock, applicationStore.data.setPartQuantityToOne)(cartItem.itemPart);
							break;
						default:
							break;
					}

					itemNew = {
						id: cartItem.itemId,
						cartItemId: cartItem.orderItemId,
						name: cartItem.itemTitle,
						image: cartItem.itemImageLink,
						listPrice: price['listPrice'],
            showUnitPrice: price['showUnitPrice'],
						displayPrice: price['displayPrice'],
						displayLabour: price['displayLabour'],
						privatePrice: price['privatePrice'],
						promotionText: price['promotionPricingText'],
						quantity: quantity,
						type: getDestinationInverse(cartItem.itemType),
						product: product,
						pricingContextId: cartItem.pricingContextId,
						itemPartOption: cartItem.itemPartOption,
						itemWarnings: warnings,
						requiredItems: null,
						mandatoryItems: cartItem.mandatoryLineItemList,
						includedOptionalItems: cartItem.includedOptionalLineItemList,
						optionalItems: cartItem.additionalOptionalLineItemList,
						hasRequiredItem: cartItem.hasRequiredRelatedItems === '1',
            supportDealerOrder: cartItem.supportDealerOrder,
            supportConsumerOrder: cartItem.supportConsumerOrder
					};
				});
			}

			if (sizeOf(warnings) > 0) {
				setTimeout(() => {
					shoppingCartActions.showWarnings();
				}, 0);
			}

			/*
			if (this.hasItemInBasket(itemNew.id)) {
				this.incrementQuantityOf(itemNew.id, itemNew);
			} else {
				this.pushItem(itemNew);
			}
			*/

			if (this.getItemsCount() > 0 && !this.isCartOpened()) {
				if (applicationStore.data.quoteImmediately) {
					this.data = this.data.set('cartModalOpened', true);
        } else {
					this.data = this.data.set('cartOpened', true);
        }
			}

			/*
			if (applicationStore.data.showCartReviewOnAddToCart || (itemNew.requiredItems && sizeOf(itemNew.requiredItems) > 0 && globalState.select('applicationState').get('routerState') !== RouterStates.PRODUCT_DETAIL)) {
				var cartItem = Immutable.fromJS(itemNew);
				setTimeout(() => {
					shoppingCartActions.openDetail(cartItem);
				}, 0);
			}
			*/

			this.data = this.data.set('newItemAddedFlag', true);
		} else {
			this.data = this.data.set('showCartQuantityLimitWarning', true);
		}

		this.emitChange();
	},

	pushItem: function (item) {
		this.data = this.data.set('items', this.data.get('items').push(Immutable.fromJS(item)));
	},

	incrementQuantityOf: function (itemId, item) {
		var itemInBasket = this.findItemInBasket(itemId);
		this.data = this.data.set('items', this.data.get('items').set(itemInBasket.index, Immutable.fromJS(item)));
	},

	getProductFromBasketByIndex: function (cartIndex) {
		return this.data.get('items').get(cartIndex).get('product');
	},

	hasItemInBasket: function (itemId) {
		return !!this.findItemInBasket(itemId).item;
	},

	findItemInBasket: function (itemId) {
		function findPredicate(item) {
			return parseFloat(item ? item.get('id') : 0) == itemId;
		}

		return {
			item: this.data.get('items').find(findPredicate),
			index: this.data.get('items').findIndex(findPredicate)
		};
	},

	getTotalPrice: function () {
		return this.data.get('items').reduce(function (memo, item) {
			var quantity = item.get('quantity');
			if (typeof quantity == "object") {
				quantity = quantity.get('value');
			}
			if (item.get('displayLabour') > 0) {
				memo += (item.get('displayLabour') * quantity);
			}
			return memo += (item.get('displayPrice') * quantity);
		}, 0);
	},

	hasCart: function () {
		return this.data.get('cart') !== null;
	},

	isCartOpened: function () {
		return this.data.get('cartOpened');
	},

  isCartModalOpened: function () {
    return this.data.get('cartModalOpened');
  },

	getQuoteLink: function () {
		return this
			.data
			.get('items')
			.groupBy(function (cartItem) {
				return cartItem.get('vehicleId');
			})
			.filter(function (cartItems, vehicleId, groupedVehicles) {
				return sizeOf(groupedVehicles) === 1;
			})
			.reduce(function (reduction, cartItems, vehicleId) {
				return reduction + '/vehicle/' + vehicleId;
			}, this.getQuoteLinkWithoutVehicleId());
	},

	getQuoteLinkWithoutVehicleId: function () {
		var urlPrefix;
		if (applicationStore.data.dealerSite) {
			urlPrefix = applicationStore.data.dealerUrlCode;
		} else {
			urlPrefix = 'national/' + applicationStore.data.nationalUrlCode;
		}

		return '/' + urlPrefix + '/' + getAppLanguage() + '/dealer/' + getAppDealerId() + '/quote/' + this.data.get('cart');
	},

	getWishlistReviewLink: function () {
		return this
			.data
			.get('items')
			.groupBy(function (cartItem) {
				return cartItem.get('vehicleId');
			})
			.filter(function (cartItems, vehicleId, groupedVehicles) {
				return sizeOf(groupedVehicles) === 1;
			})
			.reduce(function (reduction, cartItems, vehicleId) {
				return reduction + '/vehicle/' + vehicleId;
			}, this.getWishlistReviewLinkWithoutVehicleId());
	},

	getWishlistReviewLinkWithoutVehicleId: function () {
		var urlPrefix;
		if (applicationStore.data.dealerSite) {
			urlPrefix = applicationStore.data.dealerUrlCode;
		} else {
			urlPrefix = 'national/' + applicationStore.data.nationalUrlCode;
		}

		return '/' + urlPrefix + '/' + getAppLanguage() + '/dealer/' + getAppDealerId() + '/wishlist/' + this.data.get('cart');
	},

	getShoppingCartViewLink: function () {
		return this
			.data
			.get('items')
			.groupBy(function (cartItem) {
				return cartItem.get('vehicleId');
			})
			.filter(function (cartItems, vehicleId, groupedVehicles) {
				return sizeOf(groupedVehicles) === 1;
			})
			.reduce(function (reduction, cartItems, vehicleId) {
				return reduction + '/vehicle/' + vehicleId;
			}, this.getShoppingCartViewLinkWithoutVehicleId());
	},

	getShoppingCartViewLinkWithoutVehicleId: function () {
		var urlPrefix;
		if (applicationStore.data.dealerSite) {
			urlPrefix = applicationStore.data.dealerUrlCode;
		} else {
			urlPrefix = 'national/' + applicationStore.data.nationalUrlCode;
		}

		return '/' + urlPrefix + '/' + getAppLanguage() + '/dealer/' + getAppDealerId() + '/shoppingCartView/' + applicationStore.data.cart;
	},

	getEnableEcommerceRetailSite: function () {
		if (applicationStore.data.dealerEnableEcommerceRetailSite) return true;
		else return false;
	},

	getItemsCount: function () {
		return this.data.get('items').reduce(function (memo, cartItem) {
			var quantity = cartItem.get('quantity');
			if (typeof quantity == "object") {
				quantity = quantity.get('value');
			}
			return memo += parseInt(quantity, 10);
		}, 0);
	},

	getItems: function () {
		return this.data.get('items').map(item => item.set('openable', false));
	},

	resetCartWarnings: function () {
		this.data = this.data.set('items', this.data.get('items').map(item => item.set('itemWarnings', Immutable.List.of())));
	},

	clearItems: function () {
		this.data = this.data.set('items', Immutable.List.of());
	},

	onShoppingCartModalOpen: function() {
    this.data = this.data.set('cartModalOpened', true);
    this.emitChange();
	},

  onShoppingCartModalClose: function() {
    this.data = this.data.set('cartModalOpened', false);
    this.emitChange();
	},

	onShoppingCartOpen: function () {
		if (this.getItemsCount() > 0) {
			this.data = this.data.set('cartOpened', true);
			applicationStore.data.loginTextDialogShown = false;
			applicationStore.data.searchIsActiveFlag = false;
			this.emitChange();
		}
	},

	onShoppingCartClose: function () {
		this.data = this.data.set('cartOpened', false);
		this.emitChange();
	},

	onShoppingCartOpenDetail: function (payload) {
		this.data = this.data.set('detail', Immutable.fromJS(payload.cartItem));
		this.emitChange();
	},

	onShoppingCartCloseDetail: function () {
		this.data = this.data.set('detail', null);
		this.emitChange();
	},

	onShoppingCartChangedRequiredQuantity: function (payload) {
		this.data = this.data.setIn(['detail', 'requiredItems', payload.productIndex, 'quantity'], payload.quantity.toObject());
		this.data = this.data.setIn(['detail', 'requiredItems', payload.productIndex, 'price'], getPricingFromQuantityAndDetailsRefactored(this.data.getIn(['detail', 'requiredItems', payload.productIndex, 'pricing']), payload.quantity));

		this.emitChange();
	},

	checkForWarnings: function () {
		return this.data.get('showWarning');
	},

	onRequestWarningClose: function () {
		setTimeout(() => { shoppingCartActions.closeWarnings(); }, 0);
		this.emitChange();
	},

	onShowCartWarning: function () {
		this.data = this.data.set('showWarning', true);
		this.emitChange();
	},

	onCloseCartWarning: function () {
		this.resetCartWarnings();
		this.data = this.data.set('showWarning', false);
		this.emitChange();
	},

	openWishlistScheduleModal: function () {
		this.data = this.data.set('scheduleWishlistModal', true);
		this.emitChange();
	},

	closeWishlistScheduleModal: function () {
		this.data = this.data.set('scheduleWishlistModal', false);
		this.emitChange();
	},

	wishlistEmailModalEmailSent: function () {
		this.data = this.data.set('showModal', true);
		this.data = this.data.set('saveWishlistSuccess', false);
		this.emitChange();
	},

	wishlistEmailModalEmailFailed: function () {
		this.data = this.data.set('showModal', true);
		this.data = this.data.set('saveWishlistSuccess', false);
		this.emitChange();
	},

	wishlistSaveSuccessful: function () {
		this.data = this.data.set('showModal', true);
		this.data = this.data.set('saveWishlistSuccess', true);
		this.emitChange();
	},

	wishlistSaveFailure: function () {
		this.data = this.data.set('showModal', true);
		this.data = this.data.set('saveWishlistSuccess', false);
		this.emitChange();
	},

	onCloseModal: function () {
		this.data = this.data.set('showModal', false);
		this.emitChange();
	},

	openAppointmentWishlistModal: function () {
		this.data = this.data.set('scheduleAppointmentWishlistModalAppointmentFailed', false);
		this.data = this.data.set('scheduleAppointmentWishlistModalAppointmentSent', false);
		this.data = this.data.set('scheduleAppointmentWishlistModal', true);
		this.emitChange();
	},

	closeAppointmentWishlistModal: function () {
		this.data = this.data.set('scheduleAppointmentWishlistModal', false);
		this.emitChange();
	},

	appointmentWishlistModalAppointmentSent: function () {
		this.data = this.data.set('scheduleAppointmentWishlistModalAppointmentSent', true);
		this.data = this.data.set('scheduleAppointmentWishlistModalAppointmentFailed', false);
		this.emitChange();
	},

	appointmentWishlistModalAppointmentFailed: function () {
		this.data = this.data.set('scheduleAppointmentWishlistModalAppointmentSent', true);
		this.data = this.data.set('scheduleAppointmentWishlistModalAppointmentFailed', true);
		this.emitChange();
	},

	newItemSeen: function () {
		this.data = this.data.set('newItemAddedFlag', false);
		this.emitChange();
	}
});


export var shoppingCartStore = new ShoppingCartStore();

import { breadcrumbActions } from "../../actions/BreadcrumbActions.js";
import { applicationActions } from "../../actions/ApplicationActions.js";
import { productListActions } from "../../actions/ProductListActions.js";
import { vehicleSelectionActions } from "../../actions/VehicleSelectionActions.js";
import { RouterStates } from "../../constants/RouterStates.js";
import { vehicleSelectionStore } from "../../stores/VehicleSelectionStore.js";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { ProductTypes } from "../../constants/ProductTypes.js";
import {
  getTabIndex,
  sizeOf,
  scrollToPageTop,
  getAppLanguage,
  getTrackingEventCategory,
  getTrackingEventSubmodel,
} from "../../service/UtilityService";
import { isUserLoggedIn } from "../../api/AuthService";
import { setTireSize } from "../../redux/features/productList.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var _ = require("lodash");
var React = require("react");
var Cx = require("classnames");

class VehicleSelectionTrimsRefactor extends I18nComponent {
  state = {
    trims: [],
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("Vehicle Select Submodel");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Vehicle Select Submodel Access"
    );
    vehicleSelectionStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    vehicleSelectionStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    setTimeout(() => {
      vehicleSelectionActions.loadTrims();
    }, 100);
  }

  componentDidUpdate(lastProps, lastState) {
    scrollToPageTop(this.state.trims, lastState.trims);
  }

  render() {
    var enableTireSizeSelector =
      applicationStore.data.enableShowSubmodelTiresizeSelection &&
      applicationStore.data.productType === ProductTypes.TIRES;

    var trim = null,
      trimName = false,
      tiresizeArray = null;
    if (sizeOf(this.state.trims) > 0) {
      trim = this.state.trims[0];
      trimName = trim.name[getAppLanguage()];
      tiresizeArray = trim.tiresizeArray;
    }

    var useWideTiles = false;
    switch (applicationStore.data.defaultTemplate) {
      case "mercedes":
      case "mercedestransition":
        useWideTiles = true;
        break;
      default:
        break;
    }

    var dealerHidePublicPricing = applicationStore.data.dealerHidePublicPricing(
      isUserLoggedIn()
    );

    var firstTrimSelectionFunction = false;
    var content = _.map(
      tiresizeArray,
      function (tiresize, index) {
        var ariaLabel = this.t("breadcrumbs.trim");
        var trimArray = {
          trimId: trim.id,
          trimName: trim.name,
          vehicleId: trim.vehicleId,
          tiresizeId: tiresize.vehicleSubmodelTiresizeId,
        };
        if (index === 0)
          firstTrimSelectionFunction = this.onTrimClickRefactored.bind(
            this,
            trimArray
          );
        return (
          <li
            key={trim.id + "-" + index}
            tabIndex={getTabIndex()}
            aria-label={ariaLabel + ": " + tiresize.formattedTireSize}
            className={
              useWideTiles
                ? "trim-selection-quarter single-trim-view wide-tile"
                : "trim-selection-quarter single-trim-view"
            }
          >
            <div className="trim-selection">
              <div className="trim-image">
                <div className="trim-name">
                  <i className="selection-icon tire" />
                  <span className="trim-name">
                    {tiresize.formattedTireSize}
                  </span>
                  <button
                    className="btn btn-alt"
                    onClick={this.onTrimClickRefactored.bind(this, trimArray)}
                  >
                    {this.t("common.select")}
                  </button>
                  {applicationStore.data.enableQuickCompare &&
                  applicationStore.data.productListCompare &&
                  !dealerHidePublicPricing ? (
                    <button
                      className="btn button-compare"
                      onClick={this.onTrimClickRefactoredRunQuickCompare.bind(
                        this,
                        trimArray
                      )}
                    >
                      {this.t("comparison.quickCompare")}
                    </button>
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          </li>
        );
      },
      this
    );

    var tireSizeHelper = false;
    if (enableTireSizeSelector) {
      var tireSizeHelperClass = Cx({
        btn: true,
        "tire-size-helper": true,
        "single-trim-view": true,
        french: getAppLanguage() === "fr",
      });
      tireSizeHelper = (
        <ul className={tireSizeHelperClass}>
          <div
            className="tire-size-info"
            onClick={this.onShowTireSizeHelperModal}
          >
            <i className="fa fa-question-circle" aria-hidden="true"></i>
            <span>{this.t("findMyTireSize.howTo")}</span>
          </div>
        </ul>
      );
    }

    var skipTrimSelection = applicationStore.data.enableSubmodelSkipping;

    return (
      <div className="main">
        <div className="container">
          <container>{tireSizeHelper}</container>
          {skipTrimSelection ? (
            <div className="skip-trim-selection">
              <div className="float-container">
                <span>{this.t("common.notSureAboutYourTrim")}</span>
                <button
                  className="btn"
                  onClick={this.skipTrimSelection.bind(
                    this,
                    firstTrimSelectionFunction
                  )}
                >
                  {this.t("common.notSureAboutYourTrimButtonText")}
                </button>
              </div>
            </div>
          ) : (
            false
          )}
          <div className="trim-title">
            <h3 className="title">{trimName}</h3>
          </div>
          <ul className="select-trim refactor">{content}</ul>
        </div>
      </div>
    );
  }

  skipTrimSelection = (trimSelectFunction) => {
    trimSelectFunction();
  };

  onShowTireSizeHelperModal = () => {
    applicationActions.onOpenTireSizeHelper();
  };

  onCloseTireSizeHelperModal = () => {
    applicationActions.onCloseTireSizeHelper();
  };

  renderSelectButton = () => {
    var buttonClass = Cx({
      btn: true,
      "single-trim-btn": true,
      "has-compare":
        applicationStore.data.enableQuickCompare &&
        applicationStore.data.productListCompare &&
        !applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()),
    });
    return <span className={buttonClass}>{this.t("common.select")}</span>;
  };

  onTrimClickRefactored = (trimArray) => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Vehicle Select Submodel - SubmodelSelected",
      getTrackingEventSubmodel(trimArray)
    );
    this.props.dispatch(setTireSize(trimArray.tiresizeId));
    // roductListActions.setTiresize(trimArray.tiresizeId);
    vehicleSelectionActions.trimSelected(trimArray.trimId);
    var trimName = "",
      trimNameFrench = "";
    if (trimArray && trimArray.trimName) {
      trimName = trimArray.trimName.en;
      trimNameFrench = trimArray.trimName.fr;
    }
    breadcrumbActions.pushState(
      RouterStates.TRIM,
      trimArray.trimId,
      { en: trimName, fr: trimNameFrench },
      false,
      true
    );
  };

  onTrimClickRefactoredRunQuickCompare = (trimArray, evt) => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Vehicle Select Submodel - QuickCompareSelected",
      getTrackingEventSubmodel(trimArray)
    );
    productListActions.onRunQuickComparisonOnLoad();
    setTimeout(() => {
      this.props.dispatch(setTireSize(trimArray.tiresizeId));
      // productListActions.setTiresize(trimArray.tiresizeId);
      vehicleSelectionActions.trimSelected(trimArray.trimId);
      breadcrumbActions.pushState(
        RouterStates.TRIM,
        trimArray.trimId,
        {
          en: trimArray.trimName.en,
          fr: trimArray.trimName.fr,
        },
        false,
        true
      );
    }, 100);
  };

  onChange = () => {
    this.setState({
      trims: vehicleSelectionStore.data.trims,
    });
  };
}

export default VehicleSelectionTrimsRefactor;

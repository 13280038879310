var KeyMirror = require('keymirror');

export var RouterStates = KeyMirror({
  'DEALER_SELECTION': null,
  'HOMEPAGE': null,
  'YEAR': null,
  'MAKE': null,
  'CATEGORY': null,
  'MODEL': null,
  'TRIM': null,
  'PRODUCT_LIST': null,
  'PRODUCT_DETAIL': null,
  'QUOTE': null,
  'WISHLIST': null,
  'WISHLIST_WITH_ID': null,
  'PRINT_WISHLIST': null,
  'PRINT_WISHLIST_TEST': null,
  'SEARCH_RESULT': null,
	'CONTACT_DEALER': null,
	'COMPARISON': null,
	'PRINT_COMPARISON': null,
	'PRINT_COMPARISON_FROM_TIRESIZE': null,
  'SEARCH_BRANDS': null,
  'SEARCH_BRANDS_BY_SEASON': null,
  'SEARCH_MODELS': null,
  'SEARCH_MODELS_BY_SEASON': null,
  'SEARCH_TIRE': null,
  'SEARCH_TIRE_BY_SEASON': null,
	'LANDING_PAGE': null,
  'NO_CONNECTION': null,
  'WEBSERVICE_ERROR': null,
  'TRANSLATION_DICTIONARY': null
});

module.exports = {
  away: 'Away',
  login: 'S\'inscrire',
  logout: 'Déconnecter',
	loginExpired: 'Votre session a expiré. Merci de vous reconnecter.',
  cmsLogin: 'CMS',
  frontAbbrv: 'Av: ',
  rearAbbrv: 'Ar: ',
  bodyColor: 'Couleur du Corps',
  home: 'Page d\'accueil',
  title: '',
  searchText: 'Rechercher',
  forTires: 'des pneus',
  tireAndWheel: 'de Pneus & Jantes',
  changeDealer: 'Change Dealer',
  update: 'Mise à jour',
  page: 'Page',
  show: 'Show',
  filter: 'Filtre',
  filters: 'Filtres',
  change: 'Changer',
  changeVehicle: 'Changer de véhicule',
  tireOptions: 'Options de pneus',
  wheelOptions: 'Options de jantes',
  accessoryOptions: 'Options d\'accessories',
  packageOptions: 'Options d\'ensembles',
  contactDealer: 'Contactez le détaillant',
  contactRetailer: 'Contacter le détaillant',
	contactUs: 'Contactez-nous',
  pricingAnalysisText: 'Analyse des Prix des Pneus',
  pricingAnalysisByTiresize: 'Analyse des Prix',
  pricingIncentive: 'Prix incitatif',
  financeText: 'Paybright',
  bookingText: 'CMS',
  quoteReportText: 'Commandes',
  leadReportText: 'Leads',
  orderReportText: 'Ecommerce',
  dealerSelection: 'Sélectionnez votre détaillant',
  provinceSelection: 'Sélectionnez une autre province',
  provinceSelectionWarning:'Veuillez noter qu\'en choisissant une nouvelle province, vous serez redirigé(e) au début et votre liste de souhaits effacée.',
  success: 'Succès',
  failure: 'Échec',
  wishToContinue:'Souhaitez-vous poursuivre?',
  startNewQuote:'Nouvelle soumission',
	notApplicable: 'Non applicable',
	ourBrands: 'Nos marques',
  tiresByBrands: 'Tires By Brand',
  tiresByType: 'Tires By Type',
  tiresBySize: 'Tires By Size',
  loadingPage:'S\'il vous plaît, attendez...',
	loadingText: 'S\'il vous plaît, attendez',
	loading: 'Loading Page',
	copyrightPrefix: 'Copyright @',
  leavingSiteDisclaimer: 'Vous êtes peut-être sur le point de laisser une connexion sécurisée.',
  homepageTitleText: '',
  homepageText: '',
  ourLocation: 'Our Location',
  selectModel: 'Select Model',
  viewTiresize: 'View Tire Sizes',
  tiresizeList: 'Tire Size List',
  tireBrand: 'Marques de pneus',
  brand: 'Marque',
  yourPrice: 'Votre Prix',
  maxPrice: ' Prix Max.',
  minPrice: 'Prix Min.',
  average: 'Prix Moyen',
  quantityBooked: 'Quantity Booked',
  quantitySold: 'Quantity Sold',
  lastBooked: 'Last Booked',
  minHigher: '% supérieur à la prix minimum',
  minLower: '% inférieur à la prix minimum',
  showAllBrands: 'Marque',
  showAllTiresizes: 'Taille du pneu',
  openCMS: 'Connectez-vous pour mettre à jour les prix',
  pricingNotAvailable: 'S\'il vous plaît appeler le concessionnaire pour les prix',
  languageSwitch: 'English',
  languageSwitchShort: 'En',
  bronze: 'Bronze',
  silver: 'Silver',
  gold: 'Gold',
  dealerCost: 'Cost',
  dealerBooking: 'Booking Cost',
  nonOEWarrning: 'You have selected showing non original equipment tires.',
  reducedHomepageTitle: '',
  reducedHomepageText: '',
  reducedHomepageButton: '',
  promotions: 'promotion',
  youHaveSaved: 'Vous économiserez',
  through: 'grâce à notre',
  viewMore: 'View More',
  viewLess: 'View Less',
  noPartsError: 'L\'article recherché n\'est pas disponible.',
  searchNow: 'Rechercher maintenant',
  buyNow: 'Acheter Maintenant',
  payMonthly: 'Payez Mensuellement',
  payInFull: 'Payer Entièrement',
  zeroInterest: '6 mois 0% d\'intérêt',
  perMonth: ' / mo',
  financePurchase: 'Achat de Financement',
  financeWithTaxes: 'Financez votre achat entier avec taxes et installation',
  financeApproval: ' Appliquer et faites-vous approuver instantanément à l\'aide du formulaire sécurisé à droite',
  financingAvailable: 'Financement Disponible',
  financeAvailable: '6 Month Financing Available',
  benefits: 'Avantages',
  taxesNotIncluded: '*taxes et installation non inclus',
  paymentFrequency: 'Paiement Bimensuel / Mensuel bas.',
  sixMonthsNoInterest: '0% d\'Intérêt si payé dans les Six Mois',
  skipPaymentOption: 'Option Ignorer un Paiement',
  noChargeRepayment: 'Aucun frais pour Paiements Anticipé',
  call: 'Téléphone',
  belowMarket: 'Below Market Minimum Pricing of',
  marketMinimum: 'Market Minimum Pricing Applied',
  downloadAccessoryGuide: 'Download Accessory Guide',
  downloadPDF: 'Download PDF',
  createAccount: 'Create Account',
  createAccountForPricing: 'Click to Register',
  listPrice: 'Liste des Prix',
  costPrice: 'Prix Coûtant',
  tierOne: 'Niveau 1',
  tierTwo: 'Niveau 2',
  tierThree: 'Niveau 3',
  tierFour: 'Niveau 4',
  'common': {
    cm: 'cm',
    viewTires: 'Voir les pneus',
    viewWheels: 'Voir les jantes',
    viewAccessories: 'Voir les accessoires',
    viewPackages: 'Voir les ensembles',
    viewCollections: 'Voir les collections',
    shopTires: 'Shop Tires',
    shopWheels: 'Shop Wheels',
    shopAccessories: 'Shop Accessories',
    shopPackages: 'Shop Packages',
    welcomeMessage: 'Bienvenue au centre approuvé de pneus et jantes d\'origine',
    featuredItems: 'Articles en Vedette',
    yourResults: 'Vos résultats',
    getStarted: 'Démarrez la recherche',
    customizeYourRide: 'Personnalisez votre véhicule',
    select: 'Sélectionnez',
    unselect: 'Désélectionnez',
    mandatoryField: '(champ obligatoire *)',
    yes: 'Oui',
    no: 'Non',
    featured: 'Articles proposés',
    chassisCode: 'Code de châssis',
    tiresizeSearch: 'Dimension du pneu',
    partnumberSearch: 'Numéro de pièce',
    vehicleSearch: 'Recherche de véhicule',
    enter: 'Commencer',
    dealerSelectionPlaceholder: 'Recherche par ville, province ou code postal',
    myCart: 'Mon panier',
    myWishlist: 'Mon panier',
    itemAddedToCart: 'Un article a été ajouté (à)',
    item: '',
    items: '',
    selectByProvinceTitle: '',
    vehicleSelectionModalTitle: 'Votre sélection actuelle',
    continue: 'Continuer',
    cancel: 'Annuler',
    close: 'Fermer',
    closeMenu: 'Fermer',
    showMore: 'Plus de détails',
    showLess: 'Moins de détails',
    pleaseWait: 'S\'il vous plaît, attendez',
    searchPlaceholder: 'entrez votre recherche...',
    mobileIconPanelTitle: 'Options d\'utilisateur',
    externalLinkWarning: 'Lien d\'un site extern qui ouvrira un nouveau onglet.',
    startNewQuoteTag: 'Voulez-vous commencer une nouvelle séance de Magasinage?',
    startNewQuoteFromHomeTag: 'Vous allez revenir à la page d\'accueil pour commencer une nouvelle recherche.',
    loginSuccessful: 'Vous êtes connecté avec succès',
    logoutSuccessful: 'Vous avez été déconnecté avec succès',
    otherActions: 'd\'autres actions',
    now: '',
    visualizerDisclaimer: 'Le véhicule peut apparaître différemment',
    enterLocationOrRetailer: 'Entrez une adresse ou un détaillant',
    notSureAboutYourTrim: 'Pas sûr de la taille de votre véhicule?',
    notSureAboutYourTrimButtonText: 'Sautez cette étape',
    contactDealer: 'Contact Dealer',
    foxyCartFailed: 'There was an error with your checkout. Please try again.',
    outdatedApp: 'There is a newer version of the application available, please click the reload button below.',
    reload: 'Reload'
  },
  'consumer': {
    title: 'Tire Locator',
    landingtitle: 'Find the Cheapest Tires in your Area',
    purchaseModalTitle: 'To get these tires installed fill in your information and the installer will contact you back.',
    purchaseModalSuccess: 'The installer will contact you back.',
    search: 'Type in your Tire Size - ex. 195/65R15',
    subtitleLocations: 'We search over 1400 locations to find all the cheapest tires in your area',
    subtitleHelp: 'How it Works',
    subtitlePointTitle1: 'Input your tire size and postal code',
    subtitlePointTitle2: 'View lowest priced tires closest to you',
    subtitlePointTitle3: 'Reserve and book appointment',
    subtitlePointBody1: 'Not sure how to find your tire size?',
    subtitlePointBody2: 'We check prices, availability and distance to provide you one screen to see the best tire prices and how close the installation location is to you.',
    subtitlePointBody3: 'Once you select a tire you will recieve an appointment confirmation with installation address and  a call or text to confirm appointment time.',
  },
  'logo': {
    text: 'Centre de pneus apprové et jantes d\'origine'
  },
  'validation': {
    notEmpty: 'Cette information est obligatoire',
    email: 'Merci d\'indiquer une adresse courriel valide'
  },
  'loginDialog': {
    login: 'Se Connecter',
    signIn: 'Se Connecter',
    email: 'Courriel',
    password: 'Mot de passe',
    loginHasFailed: 'Mot de passe ou nom d\'utilisateur incorrect',
    rememberMe: 'Se souvenir de moi',
    rememberMeShort: 'Se souvenir de moi'
  },
  'breadcrumbs': {
    back: 'Précédent',
    year: 'Choisissez votre année',
    make: 'Choisissez votre marque',
    category: 'Choisissez votre modèle',
    model: 'Choisissez votre véhicle',
    trim: 'Choisissez votre version',
    tireSize: 'Sélectionnez la taille de pneu',
    dealerSelection: 'Choisissez votre détaillant',
    collectionCategorySelection: 'Sélectionnez la catégorie',
    collectionList: 'Voir les collections',
    tiresProductList: 'Voir les pneus',
    wheelsProductList: 'Voir les jantes',
    accessoriesProductList: 'Voir les accessoires',
    packagesProductList: 'Voir les ensembles',
    productList: 'Liste d\'articles',
    viewShoppingCart: 'Voir vos articles',
    staticHome: 'Accueil',
    searchResult: 'Résultat de la recherche'
  },
  'cart': {
    close: 'Fermer',
    viewAllYourItems: 'Voir tous vos articles',
    total: 'Total',
    getQuote: 'Obtenir un devis',
    getCartReview: 'Consulter le panier',
	  generateQuote: 'Demander une estimation',
    getCheckout: 'Valider la commande',
    checkout: 'Finaliser',
    hasBeenAddedToTheBasket: 'a été ajouté au panier',
    myquotes: 'Mes estimés',
    mywishlist: 'Mes estimés',
    cartBarTitlePrefix: 'Mon panier',
    cartBarTitleSuffix1: 'article',
    cartBarTitleSuffix2: 'articles',
    continueShopping: 'Continuer à magasiner',
	  orderNtd: 'Commander maintenant',
    addToOrderNtd: 'Ajouter à la commande',
    purchaseNtd: 'Ajouter au panier',
    checkoutNtd: 'Finaliser',
    orderNtdSuffix: 'Pneus dans cette commande',
    confirmAvailability: 'Item requiring availability confirmation',
    moreDetails: 'More Details',
	  or: 'OU',
    'modal': {
      quantityLimitWarning: 'Nous sommes désolés. Vous avez demandé d\'acheter plus que la quantité permise d\'articles en même temps. Pour des achats plus importants, contactez votre détaillant.'
    }
  },
  'quote': {
    quote: 'Estimé',
    missingOrAccessNotAllowMessage: 'Soit vous n\'êtes pas autorisé à voir les estimations du détaillants ou il n\'y en a pas,  désolé pour le dérangement.',
    quoteFor: 'Estimé pour',
    addToQuote: 'Ajouter',
    remove: 'Retirez l\'article',
    subtotal: 'Sous-total',
    tax: 'Taxes',
    nonTaxable: 'Non Taxable',
    total: 'Total',
    emailQuote: 'Envoyer l\'estimé par courriel',
    printQuote: 'Imprimer une estimé',
    saveQuoteText: 'Enregistrer l\'estimation',
    saveQuote: 'Demandez un rendez-vous, courriel ou estimation imprimé',
    emailHasBeenSent: 'Estimé a été envoyé à l\'adresse courriel fournie.',
    appointmentSent: 'Demande de rendez-vous a été envoyé à l\'adresse courriel fournie.',
    controlsInfo: 'Vous pouvez marquer cette estimé pour la voir plus tard. Vous pouvez aussi envoyer un courriel à personne ou l\'imprimer.',
    emailFieldLabel: 'Courriel',
    nameFieldLabel: 'Nom',
    noteFieldLabel: 'Notes',
    userNoteLabel: 'Notes',
    saveNotes: 'Enregistré',
    savedThroughOnePromotion: 'Vous économiserez {0} grâce à notre promotion {1}',
    savedThroughMultiplePromotions: 'Vous économiserez {0} grâce à notre promotion {1}',
    included: 'Inclut',
    requestAppointment: 'Demande de rendez-vous',
    day: 'Jour',
    partOfDay: 'Partie d\'un jour',
    note: 'Complétez ce formulaire pour recevoir cette communication une seule fois.',
    selectAppointmentTime: 'Sélectionnez l\'heure de rendez-vous',
    requiredField: 'Cette information est obligatoire',
    requiredFields: '*Ces champs sont obligatoires',
    contactInformation: 'Coordonnées',
    date: 'Date',
    time: 'Heure',
    youSaved: 'Vous avez économisé',
    personalizedQuote: 'Votre estimation personnalisée',
    contactPreference: 'Préférence pour communications'
  },
  'quoteModal': {
    emailSent: 'Le devis a été envoyé à l\'adresse courriel fournie.',
    appointmentSent: 'Demande de rendez-vous a été envoyé à l\'adresse courriel fournie.',
    quoteSaved: 'Votre demande a été enregistré avec succès.',
    quoteSaveFailed: 'Une erreur s’est produite lors de l’enregistrement de votre estimation.'
  },
  'dealerSelect': {
    day: 'Jour',
    open: 'Ouvrir',
    close: 'Fermer'
  },
  'shoppingCartView': {
    shoppingCartView: 'Estimé',
    shoppingCartViewFor: 'Carte',
    addToShoppingCartView: 'Ajouter',
    remove: 'Retirez l\'article',
    subtotal: 'Sous-total',
    tax: 'Taxes ([]%)',
    total: 'Total',
    emailShoppingCartView: 'Envoyer l\'estimé par courriel',
    printShoppingCartView: 'Imprimer une estimé',
    saveShoppingCartView: 'Enregistrer cette estimé',
    emailHasBeenSent: 'Votre demande a été envoyée à l\'adresse courriel fournie.',
    emailFieldLabel: 'Courriel',
    nameFieldLabel: 'Nom',
    savedThroughOnePromotion: 'Vous économiserez {0} grâce à notre promotion {1}',
    savedThroughMultiplePromotions: 'Vous économiserez {0} grâce à notre promotion {1}',
    included: 'Inclut',
    requestAppointment: 'Demande de rendez-vous',
    day: 'Jour',
    note: 'Complétez le formulaire ci-dessous pour recevoir cette communication une seule fois.',
    partOfDay: 'Partie d\'un jour',
    edit: 'Éditer',
    itemQuantity: 'Quantité',
    productTotal: 'Total des produits',
    inStock: 'en stock',
    outStock: 'Produit non disponible',
    priceMatch: 'Garantie du meilleur prix'
  },
  'shoppingCartWarnings': {
    ITEM_WARNING_TIRE_NOT_ORIGINAL_SIZE: 'Pneu n\'est pas de taille originale. ',
    ITEM_WARNING_TIRE_NOT_OE: 'Ce pneu n\'est pas un pneu d\'équipement d\'origine. Il est recommandé de toujours choisir le pneu d\'équipement d\'origine lorsque cela est possible. ',
    ITEM_WARNING_TIRE_NOT_RUNFLAT: 'Ce pneu n\'est pas un pneu de roulage à plat et ce véhicule requiert un pneu de roulage à plat. Il est recommandé de choisir les pneus de roulage à plat disponibles pour ce véhicule. ',
    ITEM_WARNING_TIRE_WHEEL_MISMATCH: 'Les roues et les pneus que vous avez choisi sont incompatibles. Consultez votre détaillant pour de plus amples renseignements. ',
    ITEM_WARNING_TIRE_WHEEL_IDEAL_SIZE_MISMATCH: 'S\'il vous plaît noter la citation que vous créez comprend des pneus qui ne répondent pas a la taille recommandé des roues: {}. Veuillez modifier votre sélection ou contacter le concessionnaire pour plus d\'informations. '
  },
  'shoppingCartWarningsModal': {
    continue: 'Continuer',
    notifications: 'Avis',
    notification: 'Avis'
  },
  'productTypes': {
    tires: 'Pneus',
	  tiresAlt: 'Pneus',
	  searchTires: 'Rechercher des pneus',
    wheels: 'Jantes',
	  wheelsAlt: 'Jantes',
	  searchWheels: 'Rechercher des roues',
    accessories: 'Accessoires',
	  accessoriesAlt: 'Accessoires',
	  searchAccessories: 'Recherche d\'accessoires',
    packages: 'Ensembles',
	  packagesAlt: 'Ensembles',
    searchPackages: 'Rechercher des ensembles',
	  tireSelectText: '',
	  wheelSelectText: '',
	  accessorySelectText: '',
	  packageSelectText: '',
    packagesTab: 'Ensembles',
    packageDisclaimer: 'Veuillez noter que certains détaillants n\'offrent pas d\'ensembles de jantes et pneus préconfigurés',
    collections: 'Collections'
  },
  'product': {
    packageItems: 'Articles de paquet',
    manufacturer: 'Manufacturer',
    moreProducts: 'Afficher plus de produits',
    partDetails: 'Détails de la pièce',
    moreDetails: 'Détails de la pièce',
    manufacturerWebsite: 'Voir le site du fabricant',
    manufacturerWebsiteLink: 'cliquez ici',
    pricing: 'Prix',
    wheel: 'Jante',
    wheelTag: '',
    tire: 'Pneu',
    tireTag: '',
    accessory: 'Accessoire',
    accessoryTag: '',
    package: 'Ensemble',
    packageTag: '',
    collection: 'Collection',
    collectionTag: '',
    description: 'Description',
    restrictions: 'Restrictions',
    options: 'Les Options',
    installation: 'Installation',
    installationLong: 'Installation',
    installationExtra: '* installation non inclus',
    installationIncluded: 'installation inclus *',
    morePossibleTireSizes: 'Assurez-vous de toujours vérifier la taille de pneu que vous sélectionnez ci-dessous avec la taille de pneu sur votre véhicule pour assurer la configuration précise du véhicule.',
    originalEqSizes: 'Taille de pneu d\'origine:',
    originalEqSizesDesc: 'Meilleure option lorsque vous souhaitez remplacer les pneus fournis avec votre véhicule',
    optionalEqSizes: 'Sélectionnez une taille en option(s)',
    optionalEqSizesDesc: 'Les tailles optionnelles ci-dessous se trouvent pour adapter une grande variété de véhicules en utilisant les roues corrects',
    winterSizes: 'Taille de pneu d\'hiver:',
    winterSizesDesc: 'Meilleure option pour les pneus d\'hiver pour votre véhicule',
    alternateSizes: 'Taille alternatif:',
    alternateSizesDesc: 'Meilleure option pour les tailles spéciales qui ont été fournis avec votre véhicule ou si vous voulez augmenter la taille de vos roues',
    missingLoadAndSpeed: 'Merci de confirmer l\'indice de charge et de vitesse du pneu pour le véhicule avant l\'achat.',
    contactDealerForPricing: 'Contactez le détaillant pour les prix',
    requiresOtherItems: 'Autres éléments requis',
    requiredItems: 'Éléments requis',
    relatedItems: 'Articles relatif',
    price: 'Prix',
    partNumber: 'Numéro de pièce',
    packagePartNumber: 'Numéro de pièce',
    nationalPartNumber: 'Pièce #',
    youSave: 'Vous économisez',
    totalSavings: 'Économies totales',
    carColour: 'Couleur de votre véhicule',
    wheelDiameter: 'Diamètre de la jante',
    wheelTiresize: 'Taille de la jante',
    wheelWidth: 'Largeur',
	  wheelNote: 'Note',
    wheelOffset: 'Déport',
    wheelColour: 'Couleur',
    wheelAxlePartner: 'partenaire d\'essieu',
    wheelComment: 'Notes',
    accessoryCategories: 'Catégories d\'accessoires',
    collectionCategories: 'Catégories de collection',
    tireSize: 'Taille du pneu',
    tireSizeAndSeason: 'Taille du pneu et Saison',
    season: 'Saison',
    tireBrands: 'Marques de pneus',
    tirePricing: 'Prix du pneu',
    packagePricing: 'Prix des ensembles',
    loadRating: 'Indice de charge',
    speedRating: 'Indice de vitesse',
    loadAndSpeedRating: 'Indices de vitesse et de charge (minimum)',
    plyRatingFilter: 'L\'indice de capacité de charge pour véhicule utilitaire',
    tagFilter: 'Catégories',
    loadSpeedRating: 'Indices de vitesse et de charge',
    quantity: 'Quantité',
    qty: 'Qté',
    total: 'Total',
    requiresInstallation: 'Installation requise',
    perTire: 'par pneu',
    perTireLwr: 'par pneu',
    perFrontTire: 'par pneu avant',
    perRearTire: 'par pneu arrière',
    perWheel: 'par jante',
    perWheelLwr: 'par jante',
    perFrontWheel: 'par jante avant',
    perRearWheel: 'par jante arrière',
	  perAccessory: 'par Accessoire',
	  perAccessoryLwr: 'par accessoire',
    perPackage: 'par ensemble',
	  perPackageLwr: 'par ensemble',
    perItem: 'par article',
    perItemLwr: 'par article',
	  mileageWarranty: 'Garantie',
	  kilometers: 'km',
	  miles: 'mi.',
    msrp: 'PDSF',
    dealerPrice: 'Prix',
    retailPrice: 'Prix',
    dealerTotal: 'Total de détaillant',
    yourTotal: 'Total',
    dealerPriceTotal: 'Votre total',
    emptyWheelList: 'Aucun jantes est actuellement disponible',
    emptyTireList: 'Aucun pneus est actuellement disponible',
    emptyPackagesList: 'Veuillez communiquer avec votre détaillant pour les ensembles de pneus et jantes convenant à votre véhicule.',
    emptyAccessoryList: 'Aucun accessoires est actuellement disponible',
    emptyCollectionsList: 'No collections currently available',
    categories: 'Catégorie',
    pricingNote: 'Notes sur la prix',
    warranty: 'Garantie',
    warrantyMissing: 'S\'il vous plaît contacter détaillant pour des renseignements de garantie.',
    recommendedFor: 'Recommandé pour',
    good: 'Bon',
    better: 'Très bon',
    best: 'Excellent',
    frontAbbrv: 'Av',
    rearAbbrv: 'Ar',
    pricesStartingAt: 'Prix à partir de',
    noTiresResultsAvailable: 'Désolé, il n\'y a pas les pneus disponibles pour ce véhicule. S\'il vous plaît contactez revendeur pour plus d\'informations.',
    noTiresResultsAvailableForTireSize: 'Désolé, il n\'y a pas les pneus disponibles pour cette taille de pneu. S\'il vous plaît contactez revendeur pour plus d\'informations.',
    noTiresResultsAvailableForChassis: 'Désolé, il n\'y a pas les pneus disponibles pour cette châssis. S\'il vous plaît contactez revendeur pour plus d\'informations.',
    noTiresResultsAvailableForPartNumber: 'Désolé, il n\'y a pas les pneus disponibles pour ce numéro de pièce. Nous vous invitons à contacter le revendeur pour plus d\'informations.',
    noWheelResultsAvailable: 'Désolé, il n\'y a pas les jantes disponibles pour ce véhicule. S\'il vous plaît contactez revendeur pour plus d\'informations.',
    noAccessoryResultsAvailable: 'Désolé, il n\'y a pas les accessories disponibles pour ce véhicule. S\'il vous plaît contactez revendeur pour plus d\'informations.',
    noPackageResultsAvailable: 'Désolé, il n\'y a pas les ensembles disponibles pour ce véhicule. S\'il vous plaît contactez revendeur pour plus d\'informations.',
    tireNotOriginalSizeWarning: 'Vous avez demandé de réduire ou d\'augmenter la taille de vos pneus par rapport à l\'équipement d\'origine. Vous devez acheter des jantes de la même taille.',
    wheelNotOriginalSizeWarning: 'Vous avez demandé de réduire ou d\'augmenter la taille de vos jantes par rapport à l\'équipement d\'origine. Vous devez acheter des pneus de la même taille.',
    goBack: 'Précédent',
    listView: 'List View',
    gridView: 'Grid View',
    name: 'Nom',
    installationManual: 'Installation Manual',
    itemInstalled: 'Item Installed',
    individualItem: 'Individual Item',
    grandTotal: 'Grand Total',
    loginForPricing: 'Sign Up for Pricing',
    'pricingNotes': {
      title: 'Remarques sur les prix',
      description: 'Les frais environnementaux peuvent s\'appliquer. Les frais d\'installation sont vendus séparément. Des frais supplémentaires peuvent s\'appliquer.'
    },
    'wheelFinishTypes': {
      alloy: 'Alliage',
      steel: 'Acier'
    },
    addToQuote: 'Ajouter au panier',
    addToQuotePrefix: 'Ajouter à ma liste',
    addToQuoteSuffix: 'de souhaits',
    addToWishlist: 'Ajouter au panier',
    addToWishlistPrefix: 'Ajouter à ma liste',
    addToWishlistSuffix: 'de souhaits',
    addAllToWishlist: 'Ajouter tout à ma liste',
    itemSearch: 'Item Search',
    byName: 'by name or part number',
    removeFromQuote: 'Retirez l\'article',
    removeFromWishlist: 'Retirez l\'article',
    removeFromWishlistPrefix: 'Enlevé de ma liste',
    removeFromWishlistSuffix: 'de souhaits',
    pricingLastUpdated: 'Ntl Account pricing last updated - []',
    appointment: 'Rendez-vous',
    front: 'Avant',
    rear: 'Arrière',
    loadSpeed: 'Indices de vitesse et de charge',
    loadSpeedPly: 'Indices de vitesse et de charge',
    rimDiameter: 'Diamètre de jante',
    wheelFinish: 'Finition de la jante',
    details: 'Détails',
    frontAndRearWheel: 'Jantes avant et arrière',
    frontWheel: 'Jante avant',
    rearWheel: 'Jante arrière',
    frontTire: 'Pneu avant',
    rearTire: 'Pneu arrière',
    staggeredFittment: 'Montage échelonné',
    selectAccessoryCategory: 'Toutes Catégories',
    requestAppointment: 'Contactez le détaillant',
    requestAppointment_alt: 'Contactez le détaillant',
    requestAppointment_sent: 'Votre message a été envoyé avec succès',
    runFlat: 'Roulage à plat',
	  runFlatText: 'Roulage à plat',
    missingLoadRating: 'Indice de charge ne ​​sont pas disponibles',
    missingSpeedIndex: 'Indice de vitesse ne ​​sont pas disponibles',
    colourSelectionTitle: 'Couleur',
    viewOnVehicle: 'Voir',
    comingSoon: '',
    approvedTire: 'PneusApprouvés',
    approvedTires: 'Original Equipment',
    nonApprovedTires: 'Non-Original Equipment',
    showOnlyApproved: 'Afficher les Pneus approuvés',
    showAll: 'Tout afficher',
	  showRunFlat: 'Roulage à plat',
	  showNonRunFlat: 'Montrer non roulage à plat',
    createPackage: 'Créer un ensemble',
    inStock: 'En stock',
    outOfStock: 'Pas en stock',
    itemAlert: '',
    'filters': {
      filterResults: 'Affichage des résultats pour',
      selectTiresize: 'Sélectionnez taille',
      hasWheels: 'A des Jantes',
      'brand': {
        noSelection: 'Sélectionnez une marque',
        allSelected: 'Toutes les marques',
        otherSelected: 'Marques',
        selectAll: 'Tout sélectionner',
        deselectAll: 'Tout désélectionner'
      },
      'season': {
        noSelection: 'Sélectionnez saison',
        allSeason: 'Toutes saisons',
        winter: 'Hiver',
        summer: 'Été',
        allWeather: 'Tout climat',
      },
      'loadRating': {
        noSelection: 'Sélectionnez l\'indice de charger'
      },
      'tireSize': {
        noSelection: 'Sélectionnez le taille des pneus'
      },
      'speedRating': {
        noSelection: 'Sélectionnez l\'indice de vitesse'
      },
      'plyRating': {
        noSelection: 'Sélectionnez l\'indice de capacité'
      },
      'diameter': {
        noSelection: 'Sélectionnez diamèter',
        original: 'Taille Original:',
        inchDiameter: 'pouces'
      }
    }
  },
  'stocks': {
    loadingStocks: 'Informations d\'inventaire en cours de chargement',
    refresh: 'Télécharger',
    clickToOrder: 'Cliquez ici pour commander',
    frontTires: 'Pneu avant',
    rearTires: 'Pneu arrière',
    tires: 'Pneus',
    tire: 'Pneus',
	  tiresLwrCase: 'pneus',
	  tireLwrCase: 'pneu',
    tireSingular: 'pneu',
    wheel: 'Jantes',
	  wheelsLwrCase: 'jantes',
	  wheelLwrCase: 'jante',
    wheelSingular: 'jante',
    accessory: 'Accessoires',
	  accessoriesLwrCase: 'accessoires',
	  accessoryLwrCase: 'accessoire',
    accessorySingular: 'accessoire',
    collection: 'collections',
    collectionSingular: 'collection',
    packages: 'Ensembles',
    packagesSingular: 'Ensemble',
    availableFor: ' disponibles à travers',
    location: 'entrepôt',
    locations: 'entrepôts',
    checkAvailabilityFor: 'Vérifiez la disponibilité de',
    checkAvailability: 'Vérifiez la disponibilité',
    noStockAvailable: 'Pas d\'inventaire disponible',
    availableAtDealer: 'disponible chez le détaillant',
    option: 'Agrandir/Cacher',
    updated: 'Mise à jour il y a {}',
	  updatedShort: 'il y a {}',
    availability: 'Disponibilité',
    atDealership: '- Détaillant',
    atOtherLocations: '- Distributeur',
    checkNationalStock: 'Inventaire national',
    checkNationalStockFront: 'Av',
    checkNationalStockRear: 'Ar',
    checkNationalPricing: 'Prix national',
    checkNationalPricingFront: 'Av',
    checkNationalPricingRear: 'Ar',
	  updatedMoreThanOneDay: 'Mis à jour il y a plus d\'une journée'
  },
  'tireSize': {
    up: 'Augmenter',
    original: 'Taille Original',
    down: 'Diminuer',
    optional: 'Taille optionnelle',
    alternate: 'Taille alternative',
    seasonal: 'Taille saisonnière',
    temporary: 'Taille temporaire'
  },
  'disabledSite': {
    title: 'Site hors-ligne'
  },
  'image': {
    placeholder: 'Image non disponible'
  },
  'errorPage': {
    internalServerError: 'Il y avait une erreur sur la page',
    notFound: 'La page que vous demandez ne pas exister'
  },
  'appointmentForm': {
    name: 'Nom',
    email: 'Courriel',
    phone: 'Téléphone',
    sent: 'Votre message a été envoyé avec succès. Merci.',
    requestMoreInformation: 'Demander plus d\'informations',
    preferredAppointmentTime: 'Heure de rendez-vous préférée',
    preferredAppointmentDate: 'Date de rendez-vous préférée',
    preferredContactMethod: 'Méthode privilégiée de contact ',
    preferredContactTime: 'Heure de contact préférée',
    speakToPartsSpecialist: 'Pour parler à un spécialiste des pièces :',
    requestAppointmentByPhone: 'Pour demander un rendez-vous par téléphone :',
    requestAppointmentByEmailPart1: 'Pour demander rendez-vous par courriel',
    requestAppointmentByEmailPart2: ', veuillez fournir les renseignements ci-dessous.',
    startOver: 'Recommencer',
    printWishlist: 'Imprimer l\'estimation',
    selectedTime: 'Heure choisie',
    selectTime: 'Choisir l\'heure',
    selectDate: 'Choisir la date',
    selectedDate: 'Date choisie',
    noDateSelected: 'Aucune date choisie',
    pleaseLeaveContactInfo: 'S\'il vous plaît laissez vos coordonnées ici afin que nous puissions vous repondre bientôt.',
    pleaseContactVia: 'Préférence',
    optionalMessage: 'Message facultatif'
  },
  'timing': {
    'minute': {
      singular: '{} minute',
      plural: '{} minutes'
    },
    'hour': {
      singular: '{} heure ',
      plural: '{} heures '
    },
    'day': {
      singular: '{} jour ',
      plural: '{} jours '
    }
  },
  'search': {
    'found': {
      singular: 'Trouvé {0} résultat pour {1}',
      plural: 'Trouvé {0} résultats pour {1}'
    },
    search: 'Recherche',
	  searchBy: 'Recherche',
	  selectDealer: 'Sélectionnez le détaillant pour les prix',
	  selectDealerShort: 'Sélectionnez le détaillant pour les prix',
	  postalOrCity: 'Code postal ou Ville',
	  zipOrCity: 'Code postal ou Ville',
	  dealer: 'Détaillant',
	  getDirections: 'Obtenir des directions',
    pricingAnalysisSearch: 'Entrez la taille de pneu pour voir les prix des concurrents',
    tiresizeSearch: 'Recherche de taille de pneu',
    partNumberSearch: 'Recherche de numéro de pièce',
    vehicleModelSearch: 'Recherche de modèle de véhicule',
    vehicleSimpleSearch: 'Recherche de modèle de véhicule',
    chassisSearch: 'Recherche de châssis',
    newSearch: 'Nouvelle recherche',
    searchFor: 'Recherche les',
    placeholderText: 'Recherche par la taille de pneu',
    placeholderSearchTireSize: 'recherche par taille de pneu',
    searchTireSizeTag: 'Recherche par Taille de Pneu',
    placeholderSearchPartNumber: 'recherche par numéro de pièce',
    searchPartNumberTag: 'Recherche par Numéro de Pièce',
    placeholderSearchChassis: 'Recherche par modèle',
    searchChassisTag: 'Recherche par modèle',
	  searchIsEmpty: 'Désolé, la recherche n\'a renvoyé aucun produit',
    tireSizePlaceholder: 'e.g. 195/65R15'
  },
  'dealerFilter': {
    titlePrefix: 'Rechercher le détaillant',
    titleSuffix: 'le plus proche',
    chooseProvince: 'Recherche par province:',
    selectProvince: 'Choisir une province',
    enterPostal: 'Entrez votre code postal',
    enterDealerName: 'Entrez le nom du détaillant:',
    enterDealerCity: 'Entrez le nom de la ville:',
    search: 'Rechercher',
    reset: 'Réinitialiser',
    pleaseSelectProvince: 'Choisir une province',
	  pleaseEnterLocation: 'Veuillez entrer vos coordonnées',
    searchResultsEmpty: 'Aucun résultat trouvé',
	  searchResultsWarning: 'Désolé, votre recherche a été incapable de trouver des resultats. Résultats sont limités pour seulement le {}.',
	  withXKm: 'à l\'intérieur de [] km',
    withAnyKm: 'Toute Distance'
  },
  'wheelStage': {
    slideshow: 'Présentation',
    selectAll: 'Tout sélectionnez',
    deselectAll: 'Tout déselectionnez'
  },
  'quoteReport': {
    quoteReport: 'Rapport de soumission',
	  orderReport: 'Recherche de commande',
	  leadReport: 'Leads Search',
    remainderDue: 'Remainder Due',
    ecommerceReport: 'Ecommerce Search',
    ecommerceReportUnscheduled: 'Commerce électronique non planifié',
    ecommerceReportScheduled: 'Commerce électronique planifié',
    ecommerceReportCompleted: 'Commerce électronique terminé',
    ecommerceReportAll: 'tous Ecommerce',
    missingOrAccessNotAllowMessage: 'Either you do not have any quotes to see or you do not have permission to see this dealers quotes, sorry for any inconvenience.',
    showing: 'Indiqué',
    quotes: 'soumissions',
	  orders: 'Commandes',
    quotesSince: 'soumissions depuis',
    requestedDate: 'Requested Date',
    options: 'Options',
    sort: 'Trier',
    filterDate: 'Filtrer',
    byMostRecent: 'Les plus récentes',
    byLeastRecent: 'Les moins récentes',
    byTotalPriceAscending: 'Par prix total  ordre ascendant',
    byTotalPriceDescending: 'Par prix total ordre décroissant',
    byCreatedAscending: 'Par order ascendant du créateur',
    byCreatedDescending: 'Par order décroissant du créateur',
    byCustomerNameAscending: 'Par nom du client order ascendant',
    byCustomerNameDescending: 'Par nom du client order décroissant',
    past: 'Passé',
    months: 'mois',
    days: 'jours',
    sinceYesterday: 'Depuis hier',
    todayOnly: 'Aujourd\'hui seulement',
    search: 'Rechercher',
    reset: 'Réinitialiser',
    show: 'Montrer',
    all: 'tous',
    quoteNumber: 'Numéro de la soumission',
	  orderNumber: 'Numéro de commande',
    createdBy: 'Créer par',
    customerName: 'Nom du client',
    customerEmail: 'Courriel du client',
    customerPhone: 'Téléphone du client',
    dateCreated: 'Date créer (mise à jour)',
    quoteTotal: 'Total de la soumission',
    orderTotal: 'Order Total',
    depositPaid: 'Deposit Paid',
    viewQuotes: 'Voir la soumission',
	  viewOrders: 'Afficher les commandes',
    view: 'View',
    status: 'Order Status',
    deliveryStatus: 'Statut de la livraison',
    checkStatus: 'Check Status',
    details: 'Détails',
    searchQuotes:'Rechercher les soumissions',
    searchOrders: 'search orders',
    searchLeads: 'search leads',
    quoteNotes: 'Notes',
    customerNotes: 'Notes',
    orderPrinted: 'Commande Imprimé',
    priceMatch: 'Prix Assorti',
    appointment: 'Appointment',
    type: 'Type',
    leadType: 'Type de client potentiel',
    itemType: 'Type',
    itemManufacturer: 'Manufacturer',
    itemPartNumber: 'Numéro de pièce',
    itemName: 'Nom',
    additionalLeadInfo: 'Infos supplémentaires',
    vehicleYear: 'Année',
    vehicleManufacturer: 'Fabricant',
    vehicleSubmodel: 'Sous-modèle',
    vehicleChassis: 'Châssis',
    customerContactMethodPreference: 'Préférence pour communications',
    customerMessage: 'Message',
    isNationalLead: 'Client potentiel national',
    isNational: 'NTL',
    isDealer: 'Détaillant',
	  poNumber: 'Numéro de PO',
	  confirmationNumber: 'Numéro de confirmation',
    createdTimestamp: 'Date de création',
    easternTimezone: 'HNE',
    quotesMaxLimit: 'Ou jusqu\'à la limite de 1000 soumissions',
    'orderStatusModal': {
      datePlaced: 'Date Placed',
      description: 'Description',
      quantity: 'Qty',
      sourceType: 'Source Type',
      sourceTypeDC: 'Source DC',
      status: 'Status',
      shipMethod: 'Ship Method'
    }
  },
  'guestAccountCreation': {
    createGuestAccount: 'Créer compte d\'invité',
    dealerUrlCode: 'Code du détaillant',
    emailAddress: 'Courriel',
    password: 'Mot de passe',
    passwordConfirm: 'Confirmer le mot de passe',
    create: 'Créer',
    noEmail: 'Pas de courriel?',
    passwordDoesNotMatch: 'Les mots de passe ne correspondent pas, s\'il vous plaît corriger.',
    dataMissingOrIncorrect: 'Tous les domaines doivent être complets et valides.',
    clickHere: 'Cliquez ici',
    successMessage: ' pour continuer, vous serez invité à re-saisir votre adresse courriel et mot de passe, si vous avez des questions s\'il vous plaît contacter le support Tire Wizard au 1-866-243-9652.'
  },
  'forgotPassword': {
    title: 'Réinitialiser le mot de passe',
    forgotPassword: 'Mot de passe oublié?',
    forgotPassword2: 'Mot de passe oublié',
    exisitingUserRegistration: 'Enregistrement de l\'utilisateur existants',
    enter: 'Entrer'
  },
  'wishlist': {
    title: 'Votre liste de souhaits',
    titleEcommerce: 'Vérifiez votre commande',
    titleEcommerceDealer: 'Review Customer Order',
    clear: 'Supprimez la soumission',
    schedule: 'Prendre rendez-vous',
    download: 'Télécharger',
    downloadAsPdf: 'Téléchargez pdf',
    viewAsPdf: 'view as pdf',
    buildPdf: 'Building PDF',
    item: 'Article',
    msrp: 'PDSF',
    dealerPrice: 'prix',
    quantity: 'Quantité',
    youSave: 'Vous économisez',
    total: 'Total',
    totalSavings: 'Économies totales',
    totalWithoutSavings: 'Total avant économies',
    saveWishlist: 'Enregistrez la soumission',
    emailWishlist: 'Envoyez par courriel',
    emailWishlistBtnText: 'Envoyer',
    scheduleAppointment: 'Demande de rendez-vous',
    cartIsEmpty: 'Le panier est vide',
    continueShopping: 'Continuer à magasiner',
    print: 'Imprimer',
    printHeader: 'Votre devis pour votre ',
    printHeaderError: 'Votre devis ',
    referenceNumber: '# de Réference',
    reference: 'Réference',
    saveSessionReference: 'Enregistrer la référence de session',
    scheduleAppointmentMsg: 'Planifier un rendez-vous avec votre détaillant pour votre liste de souhaits',
    personalizedWishlist: 'Votre soumission personnalisée',
    dateNotAvailable: 'La date choisie n\'est pas disponible, veuillez choisir une autre date.',
    completeReview: 'Complete Review',
    workOrderNumber: 'Work Order Number (mandatory; cannot be blank;)',
    markAsComplete: 'Marquer comme complet',
    markAsCompleteQuestion: 'Marquer cette Soumission comme complète?',
    cannotUndo: 'Vous ne serez pas en mesure d\'annuler cette action.',
    orderStatus: 'État',
    proceedToCheckout: 'Passer à la caisse',
    depositDue: 'Deposit Due Today',
    nonOrderableDisclaimer_1_a: 'Item in your cart is requiring availability confirmation ',
    nonOrderableDisclaimer_1_b: 'Items in your cart are requiring availability confirmation ',
    nonOrderableDisclaimer_2: '(Dealer will confirm availability upon arranging installation). ',
    nonOrderableDisclaimer_3: 'These items are',
    nonOrderableDisclaimer_4: ' NOT ',
    nonOrderableDisclaimer_5: 'included in the order total deposit above.',
    nonOrderableDisclaimerDealerOrder: 'Remaining items are not included with order.',
    orderReferemce: 'Order Reference #',
    orderDealerContact: 'You will be contacted within 1 - 2 business days by phone to book an appointment or you can contact the dealer at [], or by email at {}.',
    orderContactDisclaimer: 'You may be asked for your email during the appointment, if you have any questions you may contact the dealer at',
    orderStatusSchedule: 'Order Accepted (waiting for scheduling)',
    orderStatusScheduled: 'Order Scheduled',
    orderStatusCancelled: 'Order Cancelled',
    orderStatusComplete: 'Order Complete',
    transactionComplete: 'Transaction Complete',
    orderSuccess: 'Order Successful',
    orderCancelled: 'Order Cancelled',
    orderComplete: 'Order Complete',
    orderFailed: 'Order was not successful. A refund will be sent within 3 - 5 business days.',
    paymentDetails: 'Payment Details',
    vehicleDetails: 'Vehicle Details',
    installerDetails: 'Installer Details',
    deliveryDetails: 'Delivery Details',
    deliveryDetailsText: 'Your tires will be delivered directly to the dealership, you will be contacted to schedule an appointment.',
    deliveryDate: 'Delivery Date',
    vehicleDetailsDisclaimer_0: 'Please confirm the current tiresize on your vehicle matches the tiresize of the tires in your order - ',
    vehicleDetailsDisclaimer_1: 'how to read a tiresize',
    status: 'Status',
    sourceType: 'Source Type',
    sourceTypeDC: 'Source Type DC',
    totalPaid: 'Total Deposit Paid',
    remainderDue: 'Remainder Due at Dealership',
    remainderPaid: 'Remainder Paid at Dealership',
    myOrderHistory: 'See All Orders',
    yourContactInfo: 'Your Contact Info',
    customerContactInfo: 'Customer Contact Info',
    transactionsDate: 'Transaction Date',
    clickHere: 'clicking here',
    cancelOrder_1: 'You can cancel your order by',
    cancelOrder_2: 'For any cancelled orders the customer will be billed a restocking fee of []',
    cancelOrderDealer_1: 'You can cancel the customers order by',
    cancelOrderDealer_2: 'For any cancelled order, the customer will be billed a restocking fee of [] by Tire Wizard Inc.',
    cancelOrderDealer_3: 'The deposit will be returned within 30 days of the cancellation.',
    cancelOrderModal_1: 'By cancelling this order you will be charged a restocking fee of [] subtracted from your deposit of {}.',
    cancelOrderModal_2: '[] will be refunded on your card. This may take up to 30 days after cancellation.',
    cancelOrderInputEmail: 'your email address',
    cancelOrderReason: 'Reason for cancelation',
    cancelOrderReason_0: 'Prefer not to disclose',
    cancelOrderReason_1: 'I found a better price somewhere else',
    cancelOrderReason_2: 'Product does not fit my vehicle',
    cancelOrderReason_3: 'Decided not to purchase',
    cancelOrderButton: 'Confirm Cancellation',
    customerRequiresContact: 'You can schedule appointments here.',
    depositPaid: 'Deposit payment has been confirmed. Please schedule an appointment with the customer.',
    depositPaidSaveAppointment: 'Deposit payment has been confirmed. If appointment is correct, please save to schedule.',
    depositPaidAppointmentSaved: 'Deposit payment has been confirmed. Appointment has been scheduled.',
    customerDeposit: 'Deposit Fee',
    tireWizardFee: 'Administration Fee',
    scheduleEcommerceAppointment: 'Planifier un Rendez-vous',
    appointmentDate: 'Appointment Date',
    appointmentTime: 'Appointment Time',
    appointmentNotes: 'Notes',
    saveAppointmentRequest: 'Enregistrer',
    orderPlaced: 'Your order has been placed.',
    orderScheduled: 'Your order has been scheduled.',
    orderCompleted: 'Your order has been completed.',
    orderDepositDealer: 'Customer deposit has been paid.',
    orderPlacedDealer: 'Order recieved from [], please call them at {} to schedule an appointment.',
    orderScheduledDealer: 'Appointment Scheduled, input the work order number when available to mark the transaction as complete.',
    orderCompletedDealer: 'Customer order is completed.',
    orderPlacedThankYou: 'Thank you for shopping with us, an email confirmation is on its way to [].',
    orderScheduledMessage: 'Your tires will be ready for [].',
    orderCompletedMessage: 'Thank you for your purchase.',
    orderCancelledMessage: '',
    orderStepDespositPaid: 'Deposit Paid',
    orderStepReview: 'Revue',
    orderStepCheckedOut: 'Check-out',
    orderStepOrdered: 'Order',
    orderStepScheduled: 'Planifier un Rendez-vous',
    orderStepCompleted: 'Complete',
    orderStepInstalled: 'Installation',
    disclaimerTitle: 'Important Information',
    deposit: 'Deposit',
    orderDetails: 'Order Details',
    orderCancelledDeliveryDisclaimer: 'This order has been cancelled. Delivery will still take place. Contact NTD to return tires.',
    shipMethod: 'Ship Method',
    freight: 'Freight',
    atdConfirmationNumber: 'Confirmation Number',
    'modal': {
      findADealer: 'Trouvez un détaillant',
      province: 'Province:',
      selectNewProvince: 'Sélectionnez une Nouvelle Province',
      search: 'Recherche',
      OR: 'OU',
      placeholder: 'Ville, Nom du concessionaire',
      emailSent: 'Courriel envoyé avec succès',
      emailFailed: 'Le courriel n\'a pas pu être envoyé',
      emailDisclaimer: 'Merci.',
      appointmentSent: 'La demande de rendez-vous a été envoyée à l\'adresse courriel fournie.',
      appointmentFailed: 'La demande de rendez-vous n\'a pas pu être envoyé',
      contactInformation: 'Coordonnées',
      emailCustomer: 'Email customer',
      appointmentDetails: 'Détails du rendez-vous',
      appointmentDisclaimer: 'Merci. Un courriel a été envoyé au détaillant. On communiquera avec vous d\'ici 24 heures.',
      viewOnMap: 'Voir sur la carte',
      contactByEmail: 'Communiquer par courriel',
      contactByPhone: 'Communiquer par téléphone',
      AM: 'matin',
      PM: 'après-midi',
      name: 'Nom complet',
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      email: 'Courriel',
      phone: 'Téléphone',
      website: 'Site',
      date: 'Date',
      time: 'Heure',
      notes: 'Remarques',
      message: 'Message for Customer',
      contactPreference: 'Préférence pour communications',
      back: 'Précédent',
      yourCart: 'Votre panier',
      showAllLocations: 'Afficher tous les établissements',
      wishlistSaved: 'Votre demande a été enregistrée avec succès.',
      wishlistSaveFailed: 'La soumission n\'a pas pu être enregistrée.',
      sessionReferenceSaved: 'Session reference number has been saved.',
      sessionReferenceFailure: 'Session reference number has failed to save.',
      emailCustomerQuote: 'Devis par Courriel',

      password: 'Mot de passe',
      passwordConfirm: 'Confirmer Mot de passe',

      submit: 'Envoyer',
      retry: 'Retry',

      sendingData: 'Sending Data',
      accountCreated: 'Account Created',
      accountFailed: 'Failed to create account.',
      accountExists: 'Already have an account, click here to login',

      sendQuoteDateInstallation: 'Envoyer devis et date d\'installation',
      reserveItems: 'Réserver des articles',
      reserveItemsCustomer: 'Réserver des articles',

      bookAppointment: 'Réserver un Rendez-vous',
      appointmentForCustomer: 'Réserver rendez-vous pour le client',
      requestCallback: 'Demande de Rappel',

      requestContact: 'Demande de Contact',
      noThanks: 'Non Merci',

      priceMatchTires: 'Demande De Meilleur Prix',

      titleRequestContact: 'Souhaitez-vous que l\'un de nos experts de pneus vous contacte pour passer en revue vos besoins de pneus?',
      titleRequestCallback: 'Demander un rappel d\'un spécialiste',
      subtitleRequestCallback: 'Remplissez le formulaire ci-dessous pour obtenir un rappel d\'un spécialiste.',
      titleRequestAppointment: 'Demander rendez-vous',
      subtitleRequestAppointment: 'Remplissez le formulaire ci-dessous pour demander un rendez-vous.',

      titleReserveItemsDealer: 'Réserver un article du revendeur',
      subtitleReserveItemsDealer: 'Remplissez le formulaire ci-dessous pour réserver des articles chez le concessionnaire. Le concessionnaire confirmera la disponibilité.',
      titleReserveItems: 'Reserve items for customer',
      subtitleReserveItems: 'Fill in the the form below to reserve items for the customer.',

      titleSaveDealerQuote: 'Enregistrer',
      titleOrderDealerQuote: 'Commande',

      titleEmailDealer: 'Envoyer',
      subtitleEmailDealer: 'Fill in form to email customer this quote.',
      titleEmailCustomer: 'Devis par Courriel',
      subtitleEmailCustomer: 'Envoyez une copie du devis, ajoutez les notes ou les demandes ci-dessous pour vous aider à choisir la meilleure option.',
      titleQuoteSave: 'Enregistrer le devis',

      titlePriceMatchCustomer: 'Demande De Meilleur Prix',
      subtitlePriceMatchCustomer: 'Remplissez le formulaire ci-dessous pour demander une correspondance de prix, s\'il vous plaît inclure le prix et le détaillant où vous souhaitez comparer les prix dans la section des notes.',
      titlePrintCustomer: 'Imprimer le devis',
      subtitlePrintCustomer: 'Imprimez le devis et envoyez un courriel si nécessaire.',
      titleDownloadCustomer: 'Télécharger le devis',
      subtitleDownloadCustomer: 'Télécharger le devis en PDF et envoyez un courriel si nécessaire.',
      titleEmailComparison: 'Comparison',
      subtitleEmailComparion: 'Fill in the form below to send this comparison.',
      titlePrintComparison: 'Imprimer',
      subtitlePrintComparion: '',
      titleDownloadComparison: 'Télécharger',
      subtitleDownloadComparion: '',

      appointment: 'Soumettre',
      callback: 'Rappel',
      reserve: 'Reserve',
      send: 'Envoyer',
      markAsComplete: 'Marquer comme complet',
      compare: 'Comparer',
      priceMatch: 'Soumettre',
      print: 'Imprimer',
      printAndEmail: 'Imprimer & Courriel',
      download: 'Télécharger',
      downloadAndEmail: 'Télécharger & Courriel',
      appointmentSuccess: 'Je vous remercie. Un message a été envoyé au concessionnaire. Vous serez contacté dans les 24 heures pour confirmer l\'heure du rendez-vous.',
      callbackSuccess: 'Votre demande de rappel a été envoyée. Vous recevrez un appel sous peu de l\'un de nos spécialistes pour vous assurer d\'obtenir les bons pneus pour votre véhicule.',
      reserveSuccess: 'Vous avez demandé de réserver des articles. Vous recevrez un appel ou un courriel sous peu pour confirmer la disponibilité.',
      saveSuccess: 'Vous avez enregistré votre devis.',
      sendSuccess: 'Courriel envoyé.',

      priceMatchSuccess: 'Demande de meilleur prix a été envoyé.',
      printSuccess: 'Imprimer avec succès.',
      downloadSuccess: 'Download successful.',
      reminderSuccess: 'Reminder created for selected date.',
      emailNotificationOpened: 'Notify me when the customer opens the email.',
      emailNotificationReminder: 'Send reminder to customer in 2 days if email has not opened.',

      sendReminder: 'Envoyer un rappel au client',

      titleReceiveAppointment: 'You have received an appointment lead from a customer',
      titleReceiveCallback: 'You have received a callback lead from a customer',
      titleReceiveReserve: 'You have received a reserve lead from a customer',
      titleReceiveEmail: 'You have received an email lead from a customer',
      titleReceiveCompare: 'You have received a comparison lead from a customer',

      subtitleDealerMarkAsComplete: 'You can respond to the customer below or mark as complete.',
      subtitleReceiveLead: 'You can respond to the customer below or mark as complete.',
      subtitleReceiveAppointment: '',
      subtitleReceiveCallback: '',
      subtitleReceiveReminder: '',
      subtitleReceiveReserve: '',
      subtitleReceiveEmail: '',

      contactDetails: 'Contact Details',
      requestedDate: 'Requested Date',

      errorNoDate: 'Date is required.',
      errorNoDealerUrlCode: 'Dealer Url Code is required.',
      errorNoFirstName: 'First name is required.',
      errorNoLastName: 'Last name is required.',
      errorNoPassword: 'Password is required.',
      errorNoPasswordConfirm: 'Password confirmation is required.',
      errorNoPhone: 'Le numéro de téléphone est requis.',
      errorNoEmail: 'Email address is required.',
      errorNoTime: 'Time selection is required.',
      errorInvalidEmail: 'Email address must be valid.',
      errorNoReturn: 'Request failed to send.',
      errorNoReminderReturn: 'Request reminder failed to send.',

      invalidEmail: 'Email is not valid.',
      invalidPassword: 'Password is not valid. Must be at least 4 characters.',
      invalidPasswordConfirm: 'Les mots de passe ne correspondent pas, s\'il vous plaît corriger.',

      errorNoNotes: 'Response is required.',
      errorNoMessage: 'Message is required.',

      proceedToCheckoutText: 'Votre dépôt: []',
      checkout: 'Finaliser',
      buyNowDeposit: 'Votre dépôt'
    },
    'reminderOptions': {
      day: 'un jour',
      week: 'une semaine',
      twoWeeks: 'deux semaines',
      month: 'un mois',
      threeMonths: 'trois mois',
      sixMonths: 'six mois'
    },
    'form': {
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      email: 'Courriel',
      phone: 'Téléphone'
    }
  },
  'partInfoModal': {
    itemStock: 'Inventaire de l\'article',
    itemPricing: 'Prix de l\'article',
    location: 'Localisation',
    distributor: 'Distributeur',
    province: 'Province',
    city: 'Ville',
    phone: 'Téléphone',
    qty: 'Qté',
    partNumber: 'Numéro de pièce',
    lastUpdated: 'Dernière mise à jour',
    dealerPrice: 'Prix détaillant',
    msrp: 'PDSF',
    regionalPrice: 'Prix régional',
    priceUnavailable: 'Non disponible'
  },
  'partStockModal': {
    partStockModalTitle: 'Recherche Inventaire national'
  },
  'partPriceModal': {
    partPriceModalTitle: 'Recherche Prix national'
  },
  'loginModal': {
    dealerRequiresUserLogin: '',
    dealershipStaffLogin: 'Connexion Personnel détaillant'
  },
  'calendar': {
    mondayShort: 'LUN',
    mondayLong: 'Lundi',
    tuesdayShort: 'MAR',
    tuesdayLong: 'Mardi',
    wednesdayShort: 'MER',
    wednesdayLong: 'Mercredi',
    thursdayShort: 'JEU',
    thursdayLong: 'Jeudi',
    fridayShort: 'VEN',
    fridayLong: 'Vendredi',
    saturdayShort: 'SAM',
    saturdayLong: 'Samedi',
    sundayShort: 'DIM',
    sundayLong: 'Dimanche',
    january: 'Janvier',
    february: 'Février',
    march: 'Mars',
    april: 'Avril',
    may: 'Mai',
    june: 'Juin',
    july: 'Juillet',
    august: 'Août',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Décembre',
    to : 'à',
    EST : '(EST)',
  },
  'priceMatching': {
    qty: 'Qté',
    partNumber: 'Numéro de pièce:',
    notes: 'Notes sur l\'ajustement de prix *',
    workOrderNumber: 'Numéro du bon de travail:',
    discount: 'Rabais:',
    add: 'Ajouter',
    cancel: 'Annuler',
    close: 'Fermer',
    continue: 'Continuer',
    saveAndContinue: 'Sauvegarder et continuer',
    requestBy: 'Ajustement de prix demandé par l\'utilisateur:',
    currentTotal: 'Prix total actuel des pneus:',
    newTotal: 'Nouveau prix total des pneus:',
    WORK_ORDER_MISSING: 'Le numéro de commande de travail ne peut pas être vide',
    WORK_ORDER_TOO_LONG: 'Le numéro de commande ne peut pas dépasser 40 caractères',
    DISCOUNT_MISSING: 'Le rabais doit être un nombre.',
    DISCOUNT_NOT_A_NUMBER: 'Discount must be a number.',
    DISCOUNT_EXCEEDS_MAXIMUM: 'La valeur de réduction est trop élevée, la valeur maximale autorisée est {}.',
    NOTE_MISSING: ' La case Note ne doit pas être vide.'
  },
  'emailPromotion': {
    headTag: 'Veuillez fournir votre adresse électronique pour envoyer une copie du rabais et / ou coupon',
    emailLabel: 'Courriel:',
    emailButton: 'Courriel',
    viewRebateButton: 'Voir les détails du rabais',
    emailSentSuccess: 'Votre promotion a été envoyée par courrier électronique à votre adresse. Veuillez vérifier votre boîte de réception',
    emailSentFailure: 'Votre promotion n\'a pas été envoyée par courrier électronique à votre adresse. Veuillez réessayer',
    footTag: 'En fournissant votre adresse e-mail, vous acceptez de partager votre courrier électronique avec le détaillant pour de plus amples informations sur cette promotion et incluant, entre autres, des offres et des promotions complémentaires'
  },
  'findMyTireSize': {
    howTo: 'Comment puis-je confirmer ma taille de pneus?',
    title: 'Comment trouver votre taille de pneu? (Schéma pour Taille Des Pneus)',
    tag: 'Pour vous assurez de la taille de pneu, Vous pouvez toujours contacter votre détaillant.'
  },
  'ntdStock': {
    localDc: 'DC Local',
    localPlus: 'Local+',
    national: 'nationale',
    nationalAbbr: 'nat',
    loading: 'chargement',
    pleaseWait: 'S\'il vous plaît, attendez',
    order: 'commande',
    stockNotAvailable: 'Contact pour disponibilité',
    shippingDisclaimer_1: 'Free Shipping to your dealership on all orders',
    shippingDisclaimer_2: 'delivery as early as 1 business day'
  },
	'launcher': {
    title: "Tire & Wheel Store",
		shopByVehicle: "Recherche par véhicule",
		shopBySize: "Recherche par dimensions",
		year: "Année",
		selectYear: "Sélectionnez l'année",
		make: "Marque",
		selectMake: "Sélectionnez la marque",
		model: "Modèle",
		selectModel: "Sélectionnez le modèle",
		shoppingFor: "Je fais des achats pour",
		productCategory: "Sélectionnez produit",
		chooseProductType: "Sélectionnez produit",
    selectPostal: "Entrez votre code postal (obligatoire)*",
		tires: "Pneus",
		wheels: "Jantes",
		accessories: "Accessoires",
		packages: "Ensembles",
		findTires: "Trouvez des pneus",
		tireSize: "Taille du pneu",
		tireSizePlaceholder: "Votre taille de pneu",
		howFindTireSize: "Comment puis-je confirmer ma taille de pneu?",
		search: "Rechercher",
		searchForTires: "Rechercher des pneus",
    searchForWheels: "Recherche de roues",
    searchForAccessories: "Recherche d'accessoire",
    searchForPackages: "Recherche de ensembles",
    selectYourDealer: "Sélectionnez votre détaillant",
		errorTitle: "Erreur:",
    dealerError: "A dealer must be selected.",
		vehicleSearchError: "Tous les champs doivent être remplis et valide.",
		tireSizeSearchError: "Le champ de la taille ne peut être vide. Tous les champs doivent être valide.",
		partNumberSearchError: "Le numéro de pièce ne peut être vide. Tous les champs doivent être valide.",
		widgetConfigError: "Widget a rencontré une erreur",
		primaryMakes: "marques primaires",
		secondaryMakes: "marques secondaire",
		viewResults: "Rechercher",
		disclaimer: "Clause de non-responsabilité",
		disclaimerTitle: "",
		disclaimer1: "",
		disclaimer2: "",
		disclaimer3: "",
		defaultDisclaimer: "",
		enterTiresize: "recherche par taille de penu",
		enterPostal: "Code postal",
    searchByVehicle: "Recherche de modèle de véhicule",
    searchByTiresize: "Recherche de taille de pneu",
    searchByPartNumber: "Recherche de numéro de pièce",
    vehicleSelectionTitle: "Recherche par modèle de véhicule",
    tiresizeSelectionTitle: "Recherche par taille de pneu",
    partnumberSelectionTitle: "Recherche par numéro de pièce",
    partNumber: "Numéro de pièce"
	},
	'ntdModal': {
		title: "Aperçu de commande",
		titlePlaced: "Nous vous remercions de votre commande!",
		scheduleAppointment: "Planifier un Rendez-vous",
		togglePickUp: "Ramasser les articles",
		toggleDeliver: "Livrer des articles",
    toggleWithOtherGoods: "Shipped With Other Goods",
		tableProduct: "produit",
		tablePayment: "Paiement /quantité",
		tableQuantity: "Sourced Qty",
		tableDeliveryDate: "Date de livraison estimée",
		tableUnitCost: "Coût unitaire",
		tableTotal: "Total",
		tableDelivery: "Livraison",
		of: "de",
		shipMethodNational: "nationale",
		shipMethodLocalPlus: "Local+",
		shipMethodLocal: "Local",
		nationalPricing: "Nat",
		pickUp: "ramasser",
		delivery: "Livraison",
    withOtherGoods: "With Other Goods",
		freight: "fret: ",
		customer: "client",
		locationCode: "code de localisation",
		poNumber: "PO #",
		summarySubtotal: "Sous total",
		summaryFreight: "fret",
		summaryOrderTotal: "Total de la commande",
		sendingOrder: "Commande envoyé...",
		previewLoading: "Chargement de l'aperçu des commandes...",
		previewFailed: "L'aperçu des commandes n'a pas pu être chargé",
		failedToSend: "commande n'a pas été envoyé",
		continue: "continuer",
		goBack: "retourner",
		orderWarning: "Avertissement de commande",
		placeOrder: "Placer la commande",
    orderProcessed: "Order Processed",
		itemInformation: "Informations de l'article",
    shippingMethod: "Shipping Method",

		orderDisclaimer: "Cette commande n'a pas été soumise. Pour envoyer cette commande, cliquez sur le bouton \"Placer la commande\" ci-dessous. Les produits mis en attente sont facturés au prix en vigueur au moment de la livraison. Les commandes sont assujetties aux taxes applicables",
    orderDisclaimer2: "All orders submitted will need to qualify for shipping under the NTD Freight Policy, if you have any questions please contact your NTD representative (at 1-844-343-8722) to confirm the order(s) submitted meet the policy requirements. ",
    orderIsTest: "This is a test order. Order will not be placed.",
    confirmationNumber_part1: "Numéro de confirmation de commande #",
		confirmationNumber_part2: " a été soumis. Vous pouvez suivre ",

		orderHistoryHere: "Historique des commandes ici",
		agreeToCharges: "J'accepte de payer les frais d'expédition indiqués ci-dessus plus les frais de boxe et de manutention applicables. Les frais d'expédition et de manutention ne sont pas remboursables en cas de retour",
		chargeOnInvoice: "Cette charge apparaîtra sur votre facture pour cette commande",
		agreement: "Oui, j'accepte",

		customerInfo: "Informations du client",
		customerFirstName: "Prénom",
		customerLastName: "nom de famille",
		customerPhone: "téléphone",
		customerEmail: "Courriel",

		remove: "retirer",

		itemsNotAvailableToOrder: "Les articles suivants de votre panier ne sont pas disponibles pour commander",

		ntdFailed: "Nous nous excusons mais nous n'avons pas reçu les données appropriées de NTD",
		warningShipping: "Désolé, vous devez accepter les frais d'expédition avant de pouvoir procéder.",
		warningPO: "Veuillez confirmer que vous souhaitez continuer sans définir un numéro d'achat",
    warningPOBookAppointment: "Please confirm you wish to proceed without setting a purchase order number, or enter a purchase order number in the input field.",

    appointmentScheduler: "Calendrier de rendez-vous - Pour assurer la disponibilité des pneus, veuillez réservez un rendez-vous dans un délai de 24 heures à compter d'aujourd'hui.",

		notAvailable: "Non disponible",
		willNotBeShipped: "Ne sera pas expédié",

		tireWizardDisclaimer: "Tire Wizard Inc. n'est pas responsable de la réalisation des commandes. Pour tout changement ou question concernant votre commande, s'il vous plait contacter National Tire Distributor directement"
	},
	'comparison': {
		title: "Comparer les produits",
		compare: "Comparer",
		productInformation: "Information sur le produit",
		pricingInformation: "Information sur la prix",
		product: "Produit",
		pricing: "Prix",
		otherInformation: "Autre Information",
		quantitySelection: "Sélection de Quantité",
		productDetails: "Détails du Produit",
		pricingDetails: "Détails du Prix",
    selectQuantity: "Select Quantity",
    selectQuantityForAllParts: "Select Quantity For All Parts",
		promotion: "Promotion",
		additionalCosts: "Coûts Additionnels",
		compareSelected: "Comparer la Sélection",
		quickCompare: "Comparaison rapide",
		noPartsToCompare: "Désolé, vous devez sélectionner des pièces avant de pouvoir effectuer une comparaison.",
		partAlreadyInList: "Désolé, la partie est déjà dans la liste.",
		partListIsFull: "Désolé, la liste est pleine. Veuillez enlever les pièces avant d'en ajouter d'autres.",
		comparisonFailed: "Nous sommes désolés, la comparaison n'est pas disponible car un ou plusieurs des éléments sélectionnés ne contiennent pas de prix. Veuillez contacter le revendeur pour plus de détails.",
		subtotal: "Sous-total",
		tax: "Taxes",
		total: "Total",
    noCost: "No Cost",

		emailSuccess: "Email sent successfully.",
		emailFailure: "Email has failed to send. Please try again.",
		emailErrorText: "Email is not valid.",
		emailUnavailableText: "Cannot send. Please enter a valid email.",

		quick: "Quick",
		print: "Print",
		email: "Email"
	},
	'support': {
		title: 'Service à la clientèle Tire Wizard',
		support: 'Soutien',
		cmsPortalText: 'Entrez dans le CMS de Tire Wizard',
		businessHours: 'Heures de Travail',
		tireWizardDayHours: '8:00am à 5:00pm (EST)',
		closed: 'fermé',

		phone: 'Téléphone',
		tollFree: 'sans frais',
		email: 'Couriel',
		website: 'Site',

		tabMessage: 'Double Click on tab to close',

		tireWizardPhoneNumber: '1-866-243-9652',
		tireWizardEmailAddress: 'clientcare@tirewizard.ca',
    tireWizardWebsite: 'https://fr.tirewizard.ca/',
	},
  'campaign': {
    myDealership: 'Mon détaillant',
    dealershipHours: 'Heures du détaillant',
    campaignInactiveMessage: 'We\'re sorry, campaign is not currently available.',
    campaignInactiveMessageDealer: 'Please select dealer to see their current offers.',
    campaignInactiveMessageNational: 'Please select a dealer to see their current offers.',
    campaignInactiveButtonDealer: 'Dealer Homepage',
    campaignInactiveButtonNational: 'Select a Dealer',
    yourVehicle: 'Your Vehicle'
  },
  'xtime': {
    bookAppointment: 'Réservez un rendez-vous Maintenant',
    pickAppointmentTime: 'Réservez un rendez-vous avec ',
    pickAppointmentTimeDealer: 'Réservez un rendez-vous avec ',
    orderTiresPrompt: 'Would you like to order the selected tires before you select an appointment time?'
  },
  'recaptcha': {
    confirmTiresize: "Veuillez confirmer que la taille est exacte: ",
    enterTiresize: "Please enter your tire size: ",
    inputPlaceholder: "radial is optional",
    tiresizeIsRequired: "Tiresize is required.",
    yesConfirm: "Oui Confirmer",
    confirm: "Confirm",
    noChange: "Non Changer"
  },
  'warning': {
    seasonChanged: "No parts were found for your selected season. Season has changed.",
    seasonChangedToWinter: "No parts were found for your selected season. Season has changed to winter parts.",
    seasonChangedFromWinter: "No parts were found for your selected season. Season has changed off of winter parts.",
    tiresizeChanged: "No parts were found for your selected tiresize. Tiresize has changed."
  },
  'map': {
    buyNow: "Acheter maintenant",
    getTires: "obtenir des pneus tout de suite",
    generateQuote: "Générer un devis",
    dealershipAvailability: "Le concessionnaire confirmera la disponibilité",
  }
};

import {productListStore} from '../stores/ProductListStore';
import {applicationStore} from '../stores/ApplicationStore';
import {searchStore} from '../stores/SearchStore';
import {languageStore} from '../stores/LanguageStore.js';

import {
  getAppLanguage,
  isConsumerSite,
  isDealerSite,
  isEmpty,
  sizeOf,
  sizeTypeDescriptionWrapper
} from '../service/UtilityService.js';
import {SearchTypes} from "../constants/SearchTypes";
import {ProductTypes} from "../constants/ProductTypes";
import {isUserLoggedIn} from "../api/AuthService";
import Immutable from "immutable";
import {Languages} from "../constants/Languages";
import {
  buildFilter,
  buildPlyRatingFilter,
  getSelectedTireSizeIfPresentOrOriginal,
  getWinterSpeedRating, packageMappingFunction,
  updateSpeedRatingDropDown
} from "./ProductList";
import _ from "lodash";
import store from "../stores/configureStore";
import {collectVisualizerPartList} from "./VisualizerService";
import {setTireSize} from "../redux/features/productList";

var React = require('react');

//
// Vehicle Info
//

export const getSelectedTiresizeDiameter = function() {
  var diameter = null, vehicleInfo = productListStore.data.vehicleInfoPayload;
  if (sizeOf(vehicleInfo) > 0 && sizeOf(vehicleInfo.vehicleFullTireSizeInfo) > 0) {
    for (var i in vehicleInfo.vehicleFullTireSizeInfo) {
      if (vehicleInfo.vehicleFullTireSizeInfo[i] && vehicleInfo.vehicleFullTireSizeInfo[i].vehicleSubmodelTiresizeId == productListStore.data.tireSize) {
        diameter = parseInt(vehicleInfo.vehicleFullTireSizeInfo[i].rimDiameter);
      }
    }
  }
  return diameter;
};

export const tiresizeHasWheels = function() {

  var hasWheels = false, productList = store.getState().productList;
  let vehicleInfo = productList.vehicleInfo;
  let filterTireSize = productList.filterByTireSize ? productList.filterByTireSize : productList.tireSize;

  if (sizeOf(vehicleInfo) > 0 && sizeOf(vehicleInfo.vehicleFullTireSizeInfo) > 0) {

    for (var i in vehicleInfo.vehicleFullTireSizeInfo) {
      if (vehicleInfo.vehicleFullTireSizeInfo[i] && vehicleInfo.vehicleFullTireSizeInfo[i].vehicleSubmodelTiresizeId == filterTireSize) {
        hasWheels = vehicleInfo.vehicleFullTireSizeInfo[i].hasWheels == "1";
      }
    }
  }
  return hasWheels;
};

export const tiresizeHasSingleWheel = function() {
  var hasSingleWheel = false, vehicleInfo = productListStore.data.vehicleInfoPayload;
  if (sizeOf(vehicleInfo) > 0 && sizeOf(vehicleInfo.vehicleFullTireSizeInfo) > 0) {
    for (var i in vehicleInfo.vehicleFullTireSizeInfo) {
      if (vehicleInfo.vehicleFullTireSizeInfo[i] && vehicleInfo.vehicleFullTireSizeInfo[i].vehicleSubmodelTiresizeId == productListStore.data.tireSize) {
        hasSingleWheel = vehicleInfo.vehicleFullTireSizeInfo[i].wheelQty == 1;
      }
    }
  }
  return hasSingleWheel;
};

const displayHasWheels = function() {
  switch(applicationStore.data.defaultTemplate) {
    case "mercedes":
      return false;
    case "mercedes_2022":
      return false;
    default:
      return true;
  }
};

//
// Season
//

const getSeasonListValue = function(season) {
  var dictionary = languageStore.getDictionary();
  switch (parseInt(season)) {
    case 0:
      return dictionary.product.filters.season.winter;
    case 1:
      return dictionary.product.filters.season.allSeason;
    case 2:
      return dictionary.product.filters.season.summer;
  }
};

export const getFilteredSeasonList = function(list=[]) {
  var temp = [];
  for (var i in list) {
    if (isConsumerSite() || (list[i] && parseInt(list[i]) > 0)) {
      temp.push({ key: i, value: getSeasonListValue(i) });
    }
  }
  return temp;
};

//
// Tiresize
//

const getTypeDescription = function(info) {
  switch(applicationStore.data.defaultTemplate) {
    case "mercedes":
    case "mercedes_2022":
    case "mercedestransition":
      return '';
    default:
      return ' / ' + info.sizeTypeDescription;
  }
};

const getHasWheelsText = function() {
  switch(applicationStore.data.defaultTemplate) {
    case "mazda":
      return "";
    default:
      return " - Has Wheels";
  }
};

const getTiresizeListValue = function(tiresize,list) {
  var temp = '';
  for (var i in list) {
    if (list[i]) {
      if (list[i].secondaryTireSizeSpecId == 0) {
        if (list[i].vehicleSubmodelTiresizeId == tiresize) {

          temp = list[i].formattedTireSize + getTypeDescription(list[i]) + (list[i].hasWheels == "1" && displayHasWheels() ? getHasWheelsText() : "");

        }
      } else {
        if (list[i].vehicleSubmodelTiresizeId == tiresize) {
          var dictionary = languageStore.getDictionary();

          temp = dictionary.product.front + ': ' + list[i].formattedTireSize + ' ' + dictionary.product.rear + ': ' + list[i].secondaryFormattedTireSize + getTypeDescription(list[i]) + (list[i].hasWheels == "1" && displayHasWheels() ? getHasWheelsText() : "");

        }
      }
    }
  }
  return temp;
};

const compareTiresize = function(a_info, b_info) {
  // default sort to -1 (a is less than b)
  var sort = -1;
  var a_diameter = parseInt(a_info.rimDiameter);
  var a_width = parseInt(a_info.width);
  var a_ratio = parseInt(a_info.ratio);
  var b_diameter = parseInt(b_info.rimDiameter);
  var b_width = parseInt(b_info.width);
  var b_ratio = parseInt(b_info.ratio);
  // check to see if a is greater than b
  if (a_diameter > b_diameter) {
    sort = 1;
  } else if (a_diameter === b_diameter) {
    if (a_width > b_width) {
      sort = 1;
    } else if (a_width === b_width) {
      if (a_ratio > b_ratio) {
        sort = 1;
      }
    }
  }
  return sort;
};
const sortTiresizeList = function(list,info) {
  var temp =list;

  temp.sort(function(a, b) {
    var a_info, b_info;
    for (var i in info) {
      if (info[i]) {
        if (info[i].vehicleSubmodelTiresizeId == a.key) {
          a_info = info[i];
        }
        if (info[i].vehicleSubmodelTiresizeId == b.key) {
          b_info = info[i];
        }
      }
    }
    var sort = -1;
    var a_diameter = parseInt(a_info.rimDiameter);
    var a_width = parseInt(a_info.width);
    var a_ratio = parseInt(a_info.ratio);
    var b_diameter = parseInt(b_info.rimDiameter);
    var b_width = parseInt(b_info.width);
    var b_ratio = parseInt(b_info.ratio);
    if (a_diameter > b_diameter) {
      sort = 1;
    } else if (a_diameter == b_diameter) {
      if (a_width > b_width) {
        sort = 1;
      } else if (a_width == b_width) {
        if (a_ratio > b_ratio) {
          sort = 1;
        }
      }
    }
    return sort;
  });
  return temp;
};



export const getFilteredTiresizeList = function(list = [],countList=[]) {
  let info = list;
  var temp = [];

  for (var i in info) {

    console.log({vehicleSubmodelTiresizeId:info[i]['vehicleSubmodelTiresizeId'],i, info:info[i]},"filtering "+i);
    if (info[i] && countList.hasOwnProperty(info[i]['vehicleSubmodelTiresizeId'])) {
      temp.push({ key: info[i]['vehicleSubmodelTiresizeId'], value: getTiresizeListValue(info[i]['vehicleSubmodelTiresizeId'],info) });
    }
  }
  return sortTiresizeList(temp,info);
};

//
//  Tire Category
//

export const getFilteredTagList = function(isSearch = false) {
  let tireFliterTagList = store.getState().productList.tireFilterTagList;
  var temp = [], list = !isSearch ? tireFliterTagList : searchStore.data.tireFilterTagList;
  if (applicationStore.data.enableCategoryFilter) {
    for (var i in list) {
      if (list[i]) {
        temp.push({
          key: list[i].tag_id,
          value: getAppLanguage() === 'fr' ? list[i].tag_text_fr : list[i].tag_text
        });
      }
    }
  }
  return temp.sort(function(a, b) {if (a.value > b.value) { return 1; } else { return -1; }});
};

//
//  Accessory Category
//

export const getFilteredCategoryList = function(){
  if (sizeOf(window.dealerAccountInfoPayload.dealerProductFilters.accessoryCategories) > 0) {
    var dictionary = languageStore.getDictionary();

    const nextList =
      window.dealerAccountInfoPayload.dealerProductFilters.accessoryCategories
        .filter(Boolean)
        .sort((a, b) => Number(a.sortOrder) - Number(b.sortOrder))
        .map(({ accessoryCategoryId, accessoryCategoryName }) => ({
          key: accessoryCategoryId,
          value: accessoryCategoryName[getAppLanguage()],
        }));

    return [{ key: null, value: dictionary.product.showAll }, ...nextList];
  } else {
    return [];
  }
};

//
//  Brand
//

export const getFilteredBrandList = function() {
  var temp = [], list = applicationStore.data.dealerTireBrands;
  for (var i in list) {
    if (list[i]) {
      temp.push({
        key: list[i].part_manufacturer_id,
        value: list[i].part_manufacturer_name
      });
    }
  }
  return temp.sort(function(a, b) {if (a.value > b.value) { return 1; } else { return -1; }});
};

//
//  Pricing
//

const getPricingListValue = function(index, isLast = false) {
  if (!isLast) {
    switch(index) {
      case 0:
        if (getAppLanguage() === 'fr') return '0,00$ - 99,99$';
        else return '$0.00 - $99.99';
      case 1:
        if (getAppLanguage() === 'fr') return '100,00$ - 199,99$';
        else return '$100.00 - $199.99';
      case 2:
        if (getAppLanguage() === 'fr') return '200,00$ - 299,99$';
        else return '$200.00 - $299.99';
      case 3:
        if (getAppLanguage() === 'fr') return '300,00$ - 399,99$';
        else return '$300.00 - $399.99';
      case 4:
        if (getAppLanguage() === 'fr') return '400,00$ - 499,99$';
        else return '$400.00 - $499.99';
      case 5:
        if (getAppLanguage() === 'fr') return '500,00$ - 599,99$';
        else return '$500.00 - $599.99';
      case 6:
        if (getAppLanguage() === 'fr') return '600,00$ - 699,99$';
        else return '$600.00 - $699.99';
      case 7:
        if (getAppLanguage() === 'fr') return '700,00$ - 799,99$';
        else return '$700.00 - $799.99';
    }
  } else {
    switch(index) {
      case 6:
        if (getAppLanguage() === 'fr') return '600,00$+';
        else return '$600.00+';
      case 8:
        if (getAppLanguage() === 'fr') return '800,00$+';
        else return '$800.00+';
    }
  }
};

const getPricingList = function(max) {
  var temp = [];
  temp.push({ key: 0, value: getPricingListValue(0) });
  if (max > 100) temp.push({ key: 1, value: getPricingListValue(1) });
  if (max > 200) temp.push({ key: 2, value: getPricingListValue(2) });
  if (max > 300) temp.push({ key: 3, value: getPricingListValue(3) });
  if (max > 400) temp.push({ key: 4, value: getPricingListValue(4) });
  if (max > 500) temp.push({ key: 5, value: getPricingListValue(5) });
  if (max >= 600) temp.push({ key: 6, value: getPricingListValue(6, true) });
  return temp;
};

const getPricingShadowList = function(min) {
  var temp = [];
  if (min < 100) temp.push(0);
  if (min < 200) temp.push(1);
  if (min < 300) temp.push(2);
  if (min < 400) temp.push(3);
  if (min < 500) temp.push(4);
  if (min < 600) temp.push(5);
  temp.push(6);

  return temp;
};

const getPackagePricingList = function(max) {
  var temp = [];
  temp.push({ key: 0, value: getPricingListValue(0) });
  if (max > 100) temp.push({ key: 1, value: getPricingListValue(1) });
  if (max > 200) temp.push({ key: 2, value: getPricingListValue(2) });
  if (max > 300) temp.push({ key: 3, value: getPricingListValue(3) });
  if (max > 400) temp.push({ key: 4, value: getPricingListValue(4) });
  if (max > 500) temp.push({ key: 5, value: getPricingListValue(5) });
  if (max > 600) temp.push({ key: 6, value: getPricingListValue(6) });
  if (max > 700) temp.push({ key: 7, value: getPricingListValue(7) });
  if (max >= 800) temp.push({ key: 8, value: getPricingListValue(8, true) });
  return temp;
};

const getPackagePricingShadowList = function(min) {
  var temp = [];
  if (min < 100) temp.push(0);
  if (min < 200) temp.push(1);
  if (min < 300) temp.push(2);
  if (min < 400) temp.push(3);
  if (min < 500) temp.push(4);
  if (min < 600) temp.push(5);
  if (min < 700) temp.push(6);
  if (min < 800) temp.push(7);
  temp.push(8);
  return temp;
};

export const getFilteredPricingList = function(tireListMaxPrice = 0,tireListMinPrice = 0) {
  let isSearch = false;
  var max;
  switch(applicationStore.data.productType) {
    case ProductTypes.TIRES:
      max = !isSearch ? tireListMaxPrice: tireListMaxPrice; break;
    case ProductTypes.ACCESSORIES:
      max = tireListMaxPrice; break;
    case ProductTypes.PACKAGES:
      max = tireListMaxPrice; break;
    case ProductTypes.ALLOY_WHEELS:
      max = tireListMaxPrice; break;
  }
  return max > 0 ? getPricingList(max) : [];
};

export const getFilteredPricingShadowList = function(tireListMaxPrice = 0,tireListMinPrice = 0) {
  let isSearch = false;
  var min;
  switch(applicationStore.data.productType) {
    case ProductTypes.TIRES:
      min = !isSearch ? tireListMinPrice: tireListMinPrice; break;
    case ProductTypes.ACCESSORIES:
      min = tireListMinPrice; break;
    case ProductTypes.PACKAGES:
      min = tireListMinPrice; break;
    case ProductTypes.ALLOY_WHEELS:
      min = tireListMinPrice; break;
  }
  return min < 10000 ? getPricingShadowList(min) : [];
};

//
// Load
//

const getMinLoad = function(tiresize) {
  var temp = '', list = productListStore.data.vehicleFullTireSizeInfo;
  for (var i in list) {
    if (list[i] && list[i].vehicleSubmodelTiresizeId == tiresize) {
      temp = parseInt(list[i].loadRating);
    }
  }
  return temp;
};

export const getFilteredLoadList = function(loadRatingList = []) {
  var loadratinglist = loadRatingList, temp = [];
  for (var i in loadratinglist) {
    temp.push({
      key: parseInt(loadratinglist[i]),
      value: parseInt(loadratinglist[i]),
    });
  }
  return temp;
};

//
// Speed
//

export const getSpeedList = function() {
  return [
    'A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8',
    'B', 'C', 'D', 'E', 'F', 'G', 'J', 'K', 'L',
    'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'H',
    'V', 'Z', 'W', 'Y', '(Y)'
  ];
};

export const getFilteredSpeedList = function(speedRatingList = []) {
  var speedratinglist = speedRatingList, temp = [];
  for (var i in speedratinglist) {
    temp.push({
      key: speedratinglist[i],
      value: speedratinglist[i],
    });
  }
  return temp;
};

//
//  Ply
//

export const getPlyList = function() {
  return [
    'LL', 'SL', 'XL', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'L', 'M', 'N'
  ];
};

export const getPlyListIndex = function(index) {
  return getPlyList().indexOf(index);
};

export const getPlyListValue = function(index) {
  return ['LL', 'SL', 'XL', 'B - 4 (LT)', 'C - 6 (LT)', 'D - 8 (LT)', 'E - 10 (LT)', 'F - 12 (LT)', 'G - 14', 'H - 16', 'J - 18', 'L - 20', 'M - 22', 'N - 24'][getPlyListIndex(index)];
};

export const getFilteredPlyList = function(plyRatingList = []) {
  let isSearch = false;

  var plyratinglist = plyRatingList, temp = [];
  for (var i in plyratinglist) {
    temp.push({
      key: plyratinglist[i],
      value: getPlyListValue(plyratinglist[i]),
    });
  }
  return temp;
};

//
// Run Flat
//

export const getFilteredRunflatList = function() {
  var temp = [];
  if (applicationStore.data.enableRunFlatFilter && productListStore.data.searchType !== SearchTypes.SEARCH_BY_PARTNUMBER) {
    var dictionary = languageStore.getDictionary();
    temp.push({ key: 1, value: dictionary.product.showRunFlat });
    temp.push({ key: 2, value: dictionary.product.showNonRunFlat });
  }
  return temp;
};

//
// Original Equipment
//

export const getFilteredOeList = function() {
  var temp = [];
  if (isUserLoggedIn() ? applicationStore.data.useOriginalEquipmentOnlyFilterWhenAuhenticated : applicationStore.data.useOriginalEquipmentOnlyFilter) {
    var dictionary = languageStore.getDictionary();
    temp.push({ key: 1, value: dictionary.product.showOnlyApproved });
    temp.push({ key: 2, value: dictionary.product.showAll });
  }
  return temp;
};

//
// Wheel Utility
//

export const isValidSeason = function(wheelSeasonalCode, selectedSeason) {
  switch (wheelSeasonalCode) {
    case "0":
      return true;
    case "1":
      return selectedSeason == 0;
    case "2":
      return selectedSeason == 2;
  }
};

export const isValidWidth = function(wheelWidth, selectedWidth) {
  return(
    selectedWidth >= getWidthMin(wheelWidth)
    && selectedWidth <= getWidthMax(wheelWidth)
  );
};

const getWidthMin = function(width) {
  switch(width) {
    case 5:
      return 135;
    case 5.5:
      return 145;
    case 6:
      return 155;
    case 6.5:
      return 165;
    case 7:
      return 175;
    case 7.5:
      return 185;
    case 8:
      return 195;
    case 8.5:
      return 205;
    case 9:
      return 215;
    case 9.5:
      return 225;
    case 10:
      return 235;
    case 10.5:
      return 245;
    case 11:
      return 255;
    case 11.5:
      return 265;
    case 12:
      return 275;
    case 12.5:
      return 285;
    default:
      return 0;
  }
};

const getWidthMax = function(width) {
  switch(width) {
    case 5:
      return 205;
    case 5.5:
      return 215;
    case 6:
      return 225;
    case 6.5:
      return 235;
    case 7:
      return 245;
    case 7.5:
      return 255;
    case 8:
      return 265;
    case 8.5:
      return 275;
    case 9:
      return 285;
    case 9.5:
      return 295;
    case 10:
      return 305;
    case 10.5:
      return 315;
    case 11:
      return 325;
    case 11.5:
      return 335;
    case 12:
      return 345;
    case 12.5:
      return 355;
    default:
      return 9999;
  }
};

export const onListOfTiresLoaded = function (payload) {



  let wheelPayload = null;
  let loadRatingList = [];
  let speedRatingList = [];
  let plyRatingList = [];

  let brandList = [], min = 10000, max = 0, loadratinglist = [], speedratinglist = [], plyratinglist = [];
  if (payload && payload.partList) {

    var speedList = getSpeedList();
    var plyList = getPlyList();

    let season = payload.resultParameters.season;
    let tireSize = payload.resultParameters.vehicleSubmodelTireSizeId;
    let originalEquipmentFilter = payload.resultParameters.originalEquipmentFilter;

    if (isEmpty(payload.resultParameters.season)) {
      if (payload.partListSummary.seasonList[0].partCount > 0) {
        season = 0;
      } else if (payload.partListSummary.seasonList[1].partCount > 0) {
        season = 1;
      } else if (payload.partListSummary.seasonList[2].partCount > 0) {
        season = 2;
      }
    }

    let seasonCountArray = {
      0: payload.partListSummary.seasonList[0].partCount,
      1: payload.partListSummary.seasonList[1].partCount,
      2: payload.partListSummary.seasonList[2].partCount
    };

    let countList = {}, seasonList = payload.partListSummary.seasonList, temp = seasonList[payload.resultParameters.season] ? seasonList[payload.resultParameters.season].tireSizeList : [];


    if (!isEmpty(temp)) {
      for (var i in temp) {
        if (temp[i]) {
          countList[temp[i].vehicleSubmodelTiresizeId] = temp[i].partCount;
        }
      }
    }
    let tireSizeSpecIdCountArray = countList;

    let tireFilterTagList = payload.partListSummary.metatagList;

    let productList = payload.partList;
    let productFeaturedList = payload.featuredPartList;
    let maxLoadRating = 0;
    let maxSpeedRating = 0;
    let maxPlyRating = 0;

    var list = payload.partList;
    for (var i in list) {
      if (list[i]) {
        if (maxLoadRating < parseInt(list[i].partDetails.primaryPart.tireLoadRating)) {
          maxLoadRating = parseInt(list[i].partDetails.primaryPart.tireLoadRating);
        }
        if (loadratinglist.indexOf(list[i].partDetails.primaryPart.tireLoadRating) == -1) {
          loadratinglist.push(list[i].partDetails.primaryPart.tireLoadRating);
        }
        if (speedratinglist.indexOf(list[i].partDetails.primaryPart.tireSpeedIndex) == -1) {
          speedratinglist.push(list[i].partDetails.primaryPart.tireSpeedIndex);
        }
        if (plyratinglist.indexOf(list[i].partDetails.primaryPart.tirePlyRating) == -1) {
          plyratinglist.push(list[i].partDetails.primaryPart.tirePlyRating);
        }
        if (maxSpeedRating < speedList.indexOf(list[i].partDetails.primaryPart.tireSpeedIndex)) {
          maxSpeedRating = speedList.indexOf(list[i].partDetails.primaryPart.tireSpeedIndex);
        }
        if (maxPlyRating < plyList.indexOf(list[i].partDetails.primaryPart.tirePlyRating)) {
          maxPlyRating = plyList.indexOf(list[i].partDetails.primaryPart.tirePlyRating);
        }

        if (brandList.indexOf(list[i].partDetails.primaryPart.tireManufacturerId) === -1) {
          brandList.push(list[i].partDetails.primaryPart.tireManufacturerId);
        }

        let price = 0, context = [], temp_count = [], priceList = list[i].partPricing.primaryPart.price;
        for (var i in priceList) {
          if (priceList[i]) {
            context = priceList[i].contextList; break;
          }
        }
        for (var i in context) {
          if (context[i] && price < parseFloat(context[i].displayPrice)) {
            price = parseFloat(context[i].displayPrice);
          }
        }

        if (min > price) {
          min = price;
        }
        if (max < price) {
          max = price;
        }
      }
    }

    let filteredBrandIds = brandList;

    let tireListMinPrice = min;
    let tireListMaxPrice = max;


    if (sizeOf(loadratinglist) > 0) {
      loadRatingList = loadratinglist.sort(function(a, b) { return a - b; });
    }

    if (sizeOf(speedratinglist) > 0) {
      speedRatingList = speedratinglist.sort(function(a, b) { return a - b; });
    }

    if (sizeOf(plyratinglist) > 0) {
      plyRatingList = plyratinglist.sort(function(a, b) { return a - b; });
    }

    return {
      loadRatingList,
      speedRatingList,
      plyRatingList,
      tireListMinPrice,
      tireListMaxPrice,
      tireFilterTagList,
      tireSizeSpecIdCountArray,
      seasonCountArray,
      season,
      tireSize,
      originalEquipmentFilter,
      productList,
      productFeaturedList,
      filteredBrandIds,
      maxLoadRating,
      maxSpeedRating,
      maxPlyRating
    };
  } else {

    let productList = payload.partList;
    let productFeaturedList = payload.featuredPartList;

    return {
      productList,
      productFeaturedList
    }

  }

};

export const onListOfWheelLoaded = function(payload,tireSizeFilters,season,tireSize) {
  if (!payload) {
    return;
  }

  let productList = payload;


  const tireSizeCount = {};
  const wheelWidthArray = {};

  tireSizeFilters.forEach((tireSize) => {

    tireSizeCount[tireSize.get('key')] = 0;
    wheelWidthArray[tireSize.get('key')] = {
      diameter: tireSize.get('diameter'),
      width: tireSize.get('width')
    };
  });

  const list = payload;
  let minPrice = 10000;
  let maxPrice = 0;
  const seasonCount = {0: 0, 1: 0, 2: 0};
  function updateSeasonCounts(sizeCode, seasonCount) {
    switch (sizeCode) {
      case "0":
        seasonCount[0]++;
        seasonCount[1]++;
        seasonCount[2]++;
        break;
      case "1":
        seasonCount[0]++;
        break;
      case "2":
        seasonCount[2]++;
        break;
    }
  }



  function findHighestDisplayPrice(contextList) {
    return contextList.reduce((highestPrice, context) => {
      return context && highestPrice < parseFloat(context.displayPrice)
        ? parseFloat(context.displayPrice)
        : highestPrice;
    }, 0);
  }

  for (const item of list) {
    if (item) {
      const primaryPart = item.partDetails.primaryPart;
      const sizeCode = primaryPart.wheelSeasonalSizeCode;

      updateSeasonCounts(sizeCode, seasonCount);

      Object.entries(wheelWidthArray).forEach(([key, wheel]) => {
        if (
          wheel &&
          isValidSeason(sizeCode, season) &&
          isValidWidth(primaryPart.wheelWidth, wheel.width) &&
          primaryPart.wheelDiameter === wheel.diameter
        ) {
          tireSizeCount[key]++;
        }
      });

      const priceList = item.partPricing.primaryPart.price;
      const contextList =
        (Object.values(priceList).find((price) => price) || {}).contextList || [];
      const highestDisplayPrice = findHighestDisplayPrice(contextList);

      minPrice = Math.min(minPrice, highestDisplayPrice);
      maxPrice = Math.max(maxPrice, highestDisplayPrice);
    }
  }

  if (tireSizeCount[tireSize] === 0) {
    for (const key in tireSizeCount) {
      if (tireSizeCount[key] && tireSizeCount[key] > 0) {
       tireSize = key;
        break;
      }
    }
  }

  let seasonCountArray = seasonCount;
  let tireSizeSpecIdCountArray = tireSizeCount;
  let wheelListMinPrice = minPrice;

  let wheelListMaxPrice = maxPrice;
  let wheelListLoaded = true;
  return {
    productList,
    seasonCountArray,
    tireSizeSpecIdCountArray,
    wheelListMinPrice,
    wheelListMaxPrice,
    wheelListLoaded,
    tireSizeFilters,
}

}
/*
  * @param {Object} payload - The payload from the server
* @param {Array} tireSizeFilters - TIRES WHEEL ACCESSORIES, PACKAGE
 */
export const vehicleHasMappedParts = function (itemsToCheck) {
  const selectedItems = [];
  for (const [key, value] of Object.entries(itemsToCheck)) {
    if (value === 1) {
      switch (key) {
        case 'TRE':
          selectedItems.push('TIRES');
          break;
        case 'WHL':
          selectedItems.push('ALLOY_WHEELS');
          break;
        case 'ACC':
          selectedItems.push('ACCESSORIES');
          break;
        case 'PKG':
          selectedItems.push('PACKAGES');
          break;
        default:
          break;
      }
    }
  }

  return selectedItems;
};

export const extractTireSizeInfo= (payload)=>{

  // if (payload.vehicleFullTireSizeInfo) {
  //   this.data.tireSizeFilters = Immutable.fromJS(payload.vehicleFullTireSizeInfo)
  //       .sort(function (a, b) {
  //         if (a.get('secondaryFormattedTireSize') !== '' && b.get('secondaryFormattedTireSize') === '') return 1;
  //         else if (a.get('secondaryFormattedTireSize') === '' && b.get('secondaryFormattedTireSize') !== '') return -1;
  //         else if (a.get('rimDiameter') > b.get('rimDiameter')) return 1;
  //         else if (a.get('rimDiameter') < b.get('rimDiameter')) return -1;
  //         else if (a.get('rimDiameter') == b.get('rimDiameter') && a.get('width') > b.get('width')) return 1;
  //         else if (a.get('rimDiameter') == b.get('rimDiameter') && a.get('width') < b.get('width')) return -1;
  //       })
  //       .map(function (tireSize) {
  //         var value, tireSizeType;
  //
  //         switch (tireSize.get('sizeTypeCode')) {
  //           case 'O':
  //             tireSizeType = 'original';
  //             break;
  //           case 'D':
  //             tireSizeType = 'down';
  //             break;
  //           case 'U':
  //             tireSizeType = 'up';
  //             break;
  //           case 'A':
  //             tireSizeType = 'alternate';
  //             break;
  //           case 'S':
  //             tireSizeType = 'seasonal';
  //             break;
  //           case 'T':
  //             tireSizeType = 'temporary';
  //             break;
  //           default:
  //             throw new Error('Unknown tire size ' + tireSize.get('sizeTypeCode'));
  //         }
  //
  //         if (tireSize.get('secondaryFormattedTireSize') === '') {
  //           value = {
  //             en: tireSize.get('formattedTireSize') + sizeTypeDescriptionWrapper(languageStore.getDictionaryTranslate('tireSize.' + tireSizeType, Languages.EN)),
  //             fr: tireSize.get('formattedTireSize') + sizeTypeDescriptionWrapper(languageStore.getDictionaryTranslate('tireSize.' + tireSizeType, Languages.FR))
  //           };
  //         } else {
  //           value = {
  //             en: languageStore.getDictionaryTranslate('frontAbbrv', Languages.EN) +
  //                 tireSize.get('formattedTireSize') + ' ' + languageStore.getDictionaryTranslate('rearAbbrv', Languages.EN) +
  //                 tireSize.get('secondaryFormattedTireSize') + sizeTypeDescriptionWrapper(languageStore.getDictionaryTranslate('tireSize.' + tireSizeType, Languages.EN)),
  //
  //             fr: languageStore.getDictionaryTranslate('frontAbbrv', Languages.FR) +
  //                 tireSize.get('formattedTireSize') + ' ' + languageStore.getDictionaryTranslate('rearAbbrv', Languages.FR) +
  //                 tireSize.get('secondaryFormattedTireSize') + sizeTypeDescriptionWrapper(languageStore.getDictionaryTranslate('tireSize.' + tireSizeType, Languages.FR))
  //           };
  //         }
  //
  //         return Immutable.Map({
  //           key: tireSize.get('vehicleSubmodelTiresizeId'),
  //           tireSizeSpecId: tireSize.get('tireSizeSpecId'),
  //           secondaryTireSizeSpecId: tireSize.get('secondaryTireSizeSpecId'),
  //           seasonalSizeCode: parseInt(tireSize.get('seasonalSizeCode'), 10),
  //           value: value,
  //           formattedTireSize: tireSize.get('formattedTireSize'),
  //           secondaryFormattedTireSize: tireSize.get('secondaryFormattedTireSize'),
  //           diameter: parseInt(tireSize.get('rimDiameter'), 10),
  //           secondaryDiameter: parseInt(tireSize.get('secondaryRimDiameter'), 10),
  //           width: parseInt(tireSize.get('width'), 10),
  //           secondaryWidth: parseInt(tireSize.get('secondaryWidth'), 10),
  //           type: tireSize.get('type'),
  //           secondaryType: tireSize.get('secondaryType'),
  //           staggered: tireSize.get('secondaryFormattedTireSize') !== '',
  //           loadRating: parseInt(tireSize.get('loadRating'), 10),
  //           secondaryLoadRating: parseInt(tireSize.get('secondaryLoadRating'), 10),
  //           speedIndex: tireSize.get('speedIndex'),
  //           secondarySpeedIndex: tireSize.get('secondarySpeedIndex'),
  //           plyRating: tireSize.get('plyRating'),
  //           secondaryPlyRating: tireSize.get('secondaryPlyRating'),
  //           sizeTypeCode: tireSize.get('sizeTypeCode'),
  //           fitmentCode: tireSize.get('fitmentCode'),
  //           hasWheels: tireSize.get('hasWheels') === '1'
  //         });
  //       }.bind(this));
  // }

  let {vehicleId,vehicleFullTireSizeInfo,vehicleOriginalTiresize} = payload;

  let _vehicleFullTireSizeInfo = _.map(vehicleFullTireSizeInfo,function (tireInfo) {
    return {
      ...tireInfo,
      id: tireInfo.vehicleSubmodelTiresizeId,
      tireSizeSpecId: tireInfo.tireSizeSpecId,
      secondaryTireSizeSpecId: tireInfo.secondaryTireSizeSpecId,
      tireSize: tireInfo.formattedTireSize,
      secondaryTireSize: tireInfo.secondaryFormattedTireSize,
      sizeType: tireInfo.sizeTypeCode,
      seasonType: tireInfo.seasonalSizeCode,
      vehicleSubmodelTiresizeId:tireInfo.vehicleSubmodelTiresizeId,
      formattedTireSize:tireInfo.formattedTireSize,
      sizeTypeDescription:tireInfo.sizeTypeDescription,
      hasWheels:tireInfo.hasWheels,
    };
  });
  return {
    vehicleId,
    vehicleFullTireSizeInfo:_vehicleFullTireSizeInfo,
    vehicleOriginalTiresize
  };
};

export const onVehicleInfoLoaded = function (payload,oldTireSize) {
  let showOriginalTireSizeSelection = false;
  let originalTireSizes = Immutable.fromJS(payload.vehicleFullTireSizeInfo);
  let originalTireSize = payload.vehicleOriginalTiresize.vehicleSubmodelTiresizeId;
  let loadRatingFilters = Immutable.fromJS(payload.loadRatingFilters);
  let secondaryLoadRating = parseInt(
    payload.vehicleOriginalTiresize.secondaryLoadRating,
    10
  );
  let vehicleSubTireId = null;
  let _tireSize=oldTireSize;
  if (payload.vehicleOriginalTiresize) {
    // Check if the payload.vehicleOriginalTiresize is defined, if not skip the rest of the function
    let vehicleInfoPayload = payload;

    // Save the payload to the data object

    let speedIndex = payload.vehicleOriginalTiresize.speedIndex;
    let secondarySpeedIndex = payload.vehicleOriginalTiresize.secondarySpeedIndex;

    // Save the speedIndex and secondarySpeedIndex to the data object

    let plyRating = payload.vehicleOriginalTiresize.plyRating;
    let secondaryPlyRating = payload.vehicleOriginalTiresize.secondaryPlyRating;
    let loadRating = parseInt(payload.vehicleOriginalTiresize.loadRating, 10);


    let originalDiameter = parseInt(
      payload.vehicleOriginalTiresize.rimDiameter,
      10
    );
    let originalTireSizes = Immutable.fromJS(payload.vehicleFullTireSizeInfo);

    // Save the plyRating, secondaryPlyRating, loadRating, secondaryLoadRating, originalTireSize, originalDiameter, and originalTireSizes to the data object

    if (
      originalTireSizes.filter(
        (tireSize) =>
          tireSize.get("sizeTypeCode") === "O" ||
          tireSize.get("sizeTypeCode") === "A" ||
          tireSize.get("sizeTypeCode") === "S" ||
          sizeOf(tireSize.get("sizeTypeCode") === "T")
      ) > 1
    ) {
      showOriginalTireSizeSelection = true;
    }

    // Check if the originalTireSizes object contains more than 1 object with a sizeTypeCode of 'O', 'A', 'S', or 'T'. If so, set the showOriginalTireSizeSelection to true
  }

  let listOfTires = Immutable.List.of();
  let filteredListOfTires = Immutable.List.of();
  let filteredListOfWheels = Immutable.List.of();
  let filteredListOfPackages = Immutable.List.of();
  let featuredTireCount = 0;
  let filteredBrandIds = [];
  let listOfTireLiveStock = null;
  let listOfWheels = Immutable.List.of();
  let listOfAccessories = Immutable.List.of();
  let listOfCollections = Immutable.List.of();
  let listOfPackages = Immutable.List.of();

  let wheelPayload = null;

  let seasonCountArray = [];
  let tireSizeSpecIdCountArray = [];

  let tireListLoaded = false;
  let wheelListLoaded = false;
  let accessoryListLoaded = false;
  let packageListLoaded = false;
  let merchandiseListLoaded = false;
  let runFlatFilterValue = 'SHOW_ALL_TIRES';
  let originalSeason = null;
  let loadFilterFromUrl = false;
  let vehicleFullTireSizeInfo = payload.vehicleFullTireSizeInfo;
  let tireSizeFilters = null;
  let accessoriesCategories = null;
  // parmeters  ?
  let season = 0;
  let vehicleId = 0;

  if (originalSeason === null || typeof originalSeason === "undefined") {
    originalSeason = applicationStore.data.originalSeason;
  }

  if (
    !loadFilterFromUrl &&
    (payload.vehicleId !== vehicleId ||
      season === null ||
      typeof season === "undefined")
  ) {
    season = originalSeason;
  } else {
    loadFilterFromUrl = false;
  }

  vehicleId = payload.vehicleId;

  accessoriesCategories = null;
  var value, tireSizeType;
  if (vehicleFullTireSizeInfo) {
    tireSizeFilters = Immutable.fromJS(vehicleFullTireSizeInfo)
      .sort(function (a, b) {
        if (a.get("secondaryFormattedTireSize") === "" && b.get("secondaryFormattedTireSize") !== "")
          return -1;
        else if (a.get("secondaryFormattedTireSize") !== "" && b.get("secondaryFormattedTireSize") === "")
          return 1;
        else if (a.get("secondaryFormattedTireSize") === "" && b.get("secondaryFormattedTireSize") === "")
          return 0;
        else if (a.get("rimDiameter") > b.get("rimDiameter"))
          return 1;
        else if (a.get("rimDiameter") < b.get("rimDiameter"))
          return -1;
        else if (a.get("rimDiameter") == b.get("rimDiameter") && a.get("width") > b.get("width"))
          return 1;
        else if (a.get("rimDiameter") == b.get("rimDiameter") && a.get("width") < b.get("width"))
          return -1;
      })
      .map(function (tireSize) {

    try {
      switch (tireSize.get("sizeTypeCode")) {
        case "O":
          tireSizeType = "original";
          break;
        case "D":
          tireSizeType = "down";
          break;
        case "U":
          tireSizeType = "up";
          break;
        case "A":
          tireSizeType = "alternate";
          break;
        case "S":
          tireSizeType = "seasonal";
          break;
        case "T":
          tireSizeType = "temporary";
          break;
        default:
          throw new Error(
            "Unknown tire size " + tireSize.get("sizeTypeCode")
          );
      }

      if (tireSize.get("secondaryFormattedTireSize") === "") {
        value = {
          en:
            tireSize.get("formattedTireSize") +
            sizeTypeDescriptionWrapper(
              languageStore.getDictionaryTranslate(
                "tireSize." + tireSizeType,
                Languages.EN
              )
            ),
          fr:
            tireSize.get("formattedTireSize") +
            sizeTypeDescriptionWrapper(
              languageStore.getDictionaryTranslate(
                "tireSize." + tireSizeType,
                Languages.FR
              )
            ),
        };
      } else {
        value = {
          en:
            languageStore.getDictionaryTranslate("frontAbbrv", Languages.EN) +
            tireSize.get("formattedTireSize") +
            " " +
            languageStore.getDictionaryTranslate("rearAbbrv", Languages.EN) +
            tireSize.get("secondaryFormattedTireSize") +
            sizeTypeDescriptionWrapper(
              languageStore.getDictionaryTranslate(
                "tireSize." + tireSizeType,
                Languages.EN
              )
            ),

          fr:
            languageStore.getDictionaryTranslate("frontAbbrv", Languages.FR) +
            tireSize.get("formattedTireSize") +
            " " +
            languageStore.getDictionaryTranslate("rearAbbrv", Languages.FR) +
            tireSize.get("secondaryFormattedTireSize") +
            sizeTypeDescriptionWrapper(
              languageStore.getDictionaryTranslate(
                "tireSize." + tireSizeType,
                Languages.FR
              )
            ),
        };
      }
      vehicleSubTireId = tireSize.get("vehicleSubmodelTiresizeId");
      return Immutable.Map({
        key: tireSize.get("vehicleSubmodelTiresizeId"),
        tireSizeSpecId: tireSize.get("tireSizeSpecId"),
        secondaryTireSizeSpecId: tireSize.get("secondaryTireSizeSpecId"),
        seasonalSizeCode: parseInt(tireSize.get("seasonalSizeCode"), 10),
        value: value,
        formattedTireSize: tireSize.get("formattedTireSize"),
        secondaryFormattedTireSize: tireSize.get(
          "secondaryFormattedTireSize"
        ),
        diameter: parseInt(tireSize.get("rimDiameter"), 10),
        secondaryDiameter: parseInt(tireSize.get("secondaryRimDiameter"), 10),
        width: parseInt(tireSize.get("width"), 10),
        secondaryWidth: parseInt(tireSize.get("secondaryWidth"), 10),
        type: tireSize.get("type"),
        secondaryType: tireSize.get("secondaryType"),
        staggered: tireSize.get("secondaryFormattedTireSize") !== "",
        loadRating: parseInt(tireSize.get("loadRating"), 10),
        secondaryLoadRating: parseInt(
          tireSize.get("secondaryLoadRating"),
          10
        ),
        speedIndex: tireSize.get("speedIndex"),
        secondarySpeedIndex: tireSize.get("secondarySpeedIndex"),
        plyRating: tireSize.get("plyRating"),
        secondaryPlyRating: tireSize.get("secondaryPlyRating"),
        sizeTypeCode: tireSize.get("sizeTypeCode"),
        fitmentCode: tireSize.get("fitmentCode"),
        hasWheels: tireSize.get("hasWheels") === "1",
      });
    }
    catch (e) {
      console.error(e);
    }
      });

  }

  // Visualizer

  let visualizerColours = payload.vehicleColourCodes;
  let visualizerForeground = payload.vehicleVisualizerForegroundImageUrl;
  let visualizerShadow = payload.vehicleVisualizerShadowImageUrl;
  let colourCodes = null;
  let colourCodeInDetail = null;
  if (payload.vehicleColourCodes) {
    colourCodes = Immutable.fromJS(payload.vehicleColourCodes).map(function (
      colourCode
    ) {
      return Immutable.Map({
        code: colourCode.get("colourCode"),
        image: colourCode.get("colourCodePatchImage"),
        name: colourCode.get("colourCodeName"),
        hexCode: colourCode.get("colourHexCode"),
        shadowOpacity: colourCode.get("visualizerShadowOpacity"),
        patchImage: colourCode.get("colourCodePatchImage"),
        foregroundImage: payload.vehicleVisualizerForegroundImageUrl,
        shadowImage: payload.vehicleVisualizerShadowImageUrl,
      });
    });
  }

  if (sizeOf(colourCodes) > 0) {
    colourCodes = colourCodes.get(0).get("code");
    colourCodeInDetail = colourCodes;
  }

  var tiresize = _tireSize,
     list = payload.vehicleFullTireSizeInfo,
    isInList = false;
  for (var i in list) {
    if (list[i] && list[i].vehicleSubmodelTiresizeId == tiresize) {
      isInList = true;
      _tireSize = list[i].vehicleSubmodelTiresizeId;
      break;
    }
  }
  if (!loadFilterFromUrl && (isEmpty(_tireSize) || !isInList)) {
    var tireSize = "";
    if (
      applicationStore.data.searchType === SearchTypes.SEARCH_BY_MODEL ||
      applicationStore.data.searchType === SearchTypes.SEARCH_BY_CHASSIS
    ) {
      if (originalTireSize) {
        tireSize = originalTireSize;
      } else {
        tireSize = getSelectedTireSizeIfPresentOrOriginal(
          originalTireSize,
          tireSizeFilters,
            _tireSize,
          season
        );
      }
    } else {
      tireSize = getSelectedTireSizeIfPresentOrOriginal(
        originalTireSize,
        tireSizeFilters,
          _tireSize,
        season
      );
    }

    _tireSize = tireSize;
    // let vehicleSubmodelTiresizeId = tireSize;
    // vehicleSubTireId = vehicleSubmodelTiresizeId;
    vehicleSubTireId = tireSize;
  } else {
    var temp = _tireSize;
    // vehicleSubmodelTiresizeId = temp;
    vehicleSubTireId = _tireSize;
    loadFilterFromUrl = false;
  }

  var tireSizeFilter = null;
  if (tireSizeFilters) {
    tireSizeFilter = tireSizeFilters.find(
      (tireSizeFilter) => tireSizeFilter.get("key") == _tireSize
    );
  }
  let allowCreatePackage = false;
  if (tireSizeFilter) allowCreatePackage = tireSizeFilter.get("hasWheels");

  var speedIndex = null,
    speedIndexRear = null;
  var dealerOffset = 0;
  switch (season) {
    case 0:
      dealerOffset = applicationStore.data.dealerWinterTireSpeedRatingOffset;
      break;
    case 1:
      dealerOffset = applicationStore.data.dealerAllSeasonTireSpeedRatingOffset;
      break;
    case 2:
      dealerOffset = applicationStore.data.dealerSummerTireSpeedRatingOffset;
      break;
  }

  if (tireSizeFilter) {
    speedIndex = getWinterSpeedRating(
      tireSizeFilter.get("speedIndex"),
      dealerOffset ? dealerOffset : 0
    );
    // let speedIndexFilters = updateSpeedRatingDropDown(
    //   speedIndexFilters,
    //   speedIndex
    // );

    if (tireSizeFilter.get("staggered"))
      speedIndexRear = getWinterSpeedRating(
        tireSizeFilter.get("secondarySpeedIndex"),
        dealerOffset ? dealerOffset : 0
      );
    // this fails, can't see any use for it
    // if (tireSizeFilter.get("staggered"))
    //   secondarySpeedIndexFilters = updateSpeedRatingDropDown(
    //     secondarySpeedIndexFilters,
    //     speedIndexRear
    //   );

    speedIndex = speedIndex;
    let secondarySpeedIndex = speedIndexRear;

    let loadRating = tireSizeFilter.get("loadRating");
    if (tireSizeFilter && tireSizeFilter.get("loadRating")) {
      var loadRatingFilter = new Array();
      for (var i = tireSizeFilter.get("loadRating"); i <= 135; i++) {
        loadRatingFilter[sizeOf(loadRatingFilter)] = parseFloat(i);
      }
       loadRatingFilters = Immutable.fromJS(
        loadRatingFilter.map((loadRatingFilter) =>
          buildFilter(loadRatingFilter, loadRatingFilter)
        )
      );
    }
    var loadFilters = new Array();
    for (var i = tireSizeFilter.get("loadRating"); i <= 135; i++) {
      loadFilters[sizeOf(loadFilters)] = i;
    }
    loadRatingFilters = Immutable.fromJS(
      loadFilters.map((loadRatingFilter) =>
        buildFilter(loadRatingFilter, loadRatingFilter)
      )
    );

    secondaryLoadRating = tireSizeFilter.get("secondaryLoadRating");
    if (tireSizeFilter.get("staggered")) {
      var secondaryLoadFilters = new Array();
      for (var i = tireSizeFilter.get("secondaryLoadRating"); i <= 135; i++) {
        secondaryLoadFilters[sizeOf(secondaryLoadFilters)] = i;
      }

      // useless, commenting out
      // secondaryLoadRatingFilters = Immutable.fromJS(
      //   secondaryLoadFilters.map((loadRatingFilter) =>
      //     buildFilter(loadRatingFilter, loadRatingFilter)
      //   )
      // );


    }

    var plyRatingFilters = [
      "LL",
      "SL",
      "XL",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "J",
      "L",
      "M",
      "N",
    ];
    let secondaryPlyRating = [];
    //this.data.plyRating = plyRatingFilters.slice(plyRatingFilters.indexOf(tireSizeFilter.get('plyRating')));
    plyRatingFilters = Immutable.fromJS(
      buildPlyRatingFilter(tireSizeFilter.get("plyRating")).map(
        (plyRatingFilter) => buildFilter(plyRatingFilter, plyRatingFilter)
      )
    );

    switch (tireSizeFilter.get("secondaryPlyRating")) {
      case "LL":
        secondaryPlyRating = ["LL", "SL"];
        break;
      case "SL":
        secondaryPlyRating = ["SL", "XL"];
        break;
      default:
        secondaryPlyRating = tireSizeFilter.get("secondaryPlyRating");
        break;
    }
    let secondaryPlyRatingFilters = Immutable.fromJS(
      buildPlyRatingFilter(tireSizeFilter.get("secondaryPlyRating")).map(
        (plyRatingFilter) => buildFilter(plyRatingFilter, plyRatingFilter)
      )
    );

    tireSizeType = tireSizeFilter.get("sizeTypeCode");
    let tireSizeSpecId = tireSizeFilter.get("tireSizeSpecId");
    let secondaryTireSizeSpecId = tireSizeFilter.get("secondaryTireSizeSpecId");
    let isTireSizeStaggered = false;
    if (
      tireSizeFilter.get("secondaryTireSizeSpecId") !== null &&
      typeof tireSizeFilter.get("secondaryTireSizeSpecId") !== "undefined" &&
      tireSizeFilter.get("secondaryTireSizeSpecId") > 0
    ) {
      isTireSizeStaggered = true;
    } else {
      isTireSizeStaggered = false;
    }
    let tireNotOriginalSizeWarning = false;
    let wheelNotOriginalSizeWarning = false;
    if (
      tireSizeFilter.get("sizeTypeCode") !== "O" &&
      (tireSizeFilter.get("sizeTypeCode") === "U" ||
        tireSizeFilter.get("sizeTypeCode") === "D")
    ) {
      tireNotOriginalSizeWarning = true;
      wheelNotOriginalSizeWarning = true;
    } else {
      tireNotOriginalSizeWarning = false;
      wheelNotOriginalSizeWarning = false;
    }

    // this.updateIsListStaggeredOnly();
  }

  let productList = [];
  let productFeaturedList = [];

  let centerWheelInVisualizer = false;
  let wheelVisualizerPopModal = false;
  let wheelVisualizerSlideShowIds = Immutable.List.of();

  let showWarning = false;
  let warning = {};

  // check the length of the tire size filter object

  return {
    vehicleSubTireId,
    vehicleId,
    vehicleFullTireSizeInfo,
    tireSizeFilters,
    visualizerColours,
    visualizerForeground,
    visualizerShadow,
    colourCodes,
    colourCodeInDetail,
    tireSize:_tireSize,
  }
  // this.resetProductComparison();
};

export  const onVehicleYearsLoaded =  (payload) =>{
  let vehicleYears = _.sortBy(_.map(payload, function (year, yearId) {
    return {
      id: yearId,
      value: year
    };
  }), 'value').reverse();

  let primaryMakes = [];
  let secondaryMakes = [];
  let categories = [];
  let models = [];

  return {
    vehicleYears,
    primaryMakes,
    secondaryMakes,
    categories,
    models,
  }

};


export const onVehicleInfoPackageLoaded = (payload) => {
  const data = applicationStore.data;
  const productList = payload;
  const productFeaturedList = [];

  let visualizerPartList = [];
  let selectedVisualizerPart = null;
  const listOfPackages = Immutable.List.of();

  const listOfPackagesFiltered = payload
    .filter(filterHideItems)
    .map(packageMappingFunction(false, data.dealerIgnorePackageStock, data.setPartQuantityToOne));

  let packageMinPrice = Number.MAX_SAFE_INTEGER;
  let packageMaxPrice = 0;
  const filteredListOfPackages = listOfPackagesFiltered.sort((pkgA, pkgB) => {
    if (pkgA.get('pricing').get('displayPrice') > packageMaxPrice) packageMaxPrice = pkgA.get('pricing').get('displayPrice');
    if (pkgA.get('pricing').get('displayPrice') < packageMinPrice) packageMinPrice = pkgA.get('pricing').get('displayPrice');
    if (pkgB.get('pricing').get('displayPrice') > packageMaxPrice) packageMaxPrice = pkgB.get('pricing').get('displayPrice');
    if (pkgB.get('pricing').get('displayPrice') < packageMinPrice) packageMinPrice = pkgB.get('pricing').get('displayPrice');
    const pkgA_featured = pkgA.get('pricing').get('pricingContextId') > 0;
    const pkgB_featured = pkgB.get('pricing').get('pricingContextId') > 0;
    if (pkgA_featured) return -1;
    else if (pkgB_featured) return 1;
    else return 0;
  });

  if (sizeOf(filteredListOfPackages) > 0) {
    visualizerPartList = collectVisualizerPartList(ProductTypes.PACKAGES, payload);
    selectedVisualizerPart = {
      part: filteredListOfPackages ? filteredListOfPackages[0] : null,
      id: filteredListOfPackages ? filteredListOfPackages[0].get('id') : null
    };
  }

  const packageListMinPrice = packageMinPrice;
  const packageListMaxPrice = packageMaxPrice;

  const tireListLoaded = false;
  const wheelListLoaded = false;
  const accessoryListLoaded = false;
  const packageListLoaded = true;
  const merchandiseListLoaded = false;

  return {
    productList,
    productFeaturedList,
    visualizerPartList,
    selectedVisualizerPart,
    listOfPackages: filteredListOfPackages,
    filteredListOfPackages,
    packageListMinPrice,
    packageListMaxPrice,
    tireListLoaded,
    wheelListLoaded,
    accessoryListLoaded,
    packageListLoaded,
    merchandiseListLoaded,
  };
}
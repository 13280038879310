import {applicationStore} from '../stores/ApplicationStore.js';
import {applicationActions} from '../actions/ApplicationActions.js';
import {searchStore} from '../stores/SearchStore.js';
import {searchActions} from '../actions/SearchActions.js';
import {vehicleSelectionStore} from '../stores/VehicleSelectionStore.js';
import {shoppingCartViewStore} from "../stores/ShoppingCartViewStore";
import {languageStore} from '../stores/LanguageStore.js';
import {Router} from '../stores/Router.js';

import {shoppingCartActions} from "../actions/ShoppingCartActions";

import {ProvinceArray} from '../constants/ProvinceArray.js';
import {ProductTypes} from '../constants/ProductTypes.js';
import {RouterStates} from '../constants/RouterStates.js';

import {Image} from '../components/ux/Image.jsx';

import {getOrderIdentifier} from './OrderService';
import {cartItemsAreValid, decodeQuoteName} from './WishlistService';
import {productListStore} from "../stores/ProductListStore";
import {SearchTypes} from "../constants/SearchTypes";
import {isUserLoggedIn} from "../api/AuthService";
import store from "../stores/configureStore";

var React = require('react');
var Immutable = require('immutable');
var Moment = require('moment');
var $ = require('jquery');
var _ = require('lodash');

String.prototype.removeMultiple = function (search) {
  var target = this;
  if (search instanceof Array) {
    var temp = target;
    for (var i in search) {
      temp = temp.replace(new RegExp(search[i], 'g'), '');
    }
    return temp;
  } else {
    return target.replace(new RegExp(search, 'g'), '');
  }
};

String.prototype.replaceAll = function (search, replacement) {
  var target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
};

const languageList = [
  'en',
  'fr'
];

export const formTypes = {
  APPOINTMENT: 'form-appointment',
  CALLBACK: 'form-callback',
  RESERVE: 'form-reserve',
  SAVE: 'form-save',
  SEND: 'form-send',
  PRICEMATCH: 'form-pricematch',
  PRINT: 'form-print',
  DOWNLOAD: 'form-download',
  COMPARISON_EMAIL: 'form-comparison-email',
  COMPARISON_DOWNLOAD: 'form-comparison-download',
  COMPARISON_PRINT: 'form-comparison-print'
};

export const formValues = {
  'appointment': formTypes.APPOINTMENT,
  'callback': formTypes.CALLBACK,
  'reserve': formTypes.RESERVE,
  'save': formTypes.SAVE,
  'email': formTypes.SEND,
  'pricematch': formTypes.PRICEMATCH,
  'print': formTypes.PRINT,
  'download': formTypes.DOWNLOAD,
  'comparison-email': formTypes.COMPARISON_EMAIL,
  'comparison-download': formTypes.COMPARISON_DOWNLOAD,
  'comparison-print': formTypes.COMPARISON_PRINT,
};

export const formTracking = {
  [formTypes.APPOINTMENT]: 'appointment',
  [formTypes.CALLBACK]: 'callback',
  [formTypes.RESERVE]: 'reserve',
  [formTypes.SAVE]: 'save',
  [formTypes.SEND]: 'email',
  [formTypes.PRICEMATCH]: 'pricematch',
  [formTypes.PRINT]: 'print',
  [formTypes.DOWNLOAD]: 'download',
  [formTypes.COMPARISON_EMAIL]: 'comparison-email',
  [formTypes.COMPARISON_DOWNLOAD]: 'comparison-download',
  [formTypes.COMPARISON_PRINT]: 'comparison-print'
};

export const getPaybrightUrl = () => {
  return (
    "https://app.paybright.com/"
  );
};

export const getBookingUrl = () => {
  return applicationStore.getCmsLoginLink();
  /*
  return(
    "https://cms.qa-tirelocator.space/cms/root/purchasing/booking/loginAsDealer.php?dealerId=" + getAppDealerId()
  );
  */
};

export const sizeTypeDescriptionWrapper = (sizeType) => {
  switch (applicationStore.data.defaultTemplate) {
    case 'audi':
    case 'volkswagen':
      return '';
    default:
      return " / " + sizeType;
  }
};

export const decodeUrlShoppingContext = () => {
  var urlParams = window.location.toString().split('/').reverse();
  switch (urlParams[1]) {
    case 'wishlist':
      Router.setShoppingParameters('wishlist', '');
      return '/wishlist/';
    case 'quote':
      Router.setShoppingParameters('wishlist', '');
      return '/quote/';
    case 'order':
      Router.setShoppingParameters('wishlist', '');
      return '/order/';
    case 'ecommerce':
      Router.setShoppingParameters('wishlist', '');
      return '/ecommerce/';
  }
  switch (urlParams[2]) {
    case 'wishlist':
      // not used
      Router.setShoppingParameters(urlParams[2], urlParams[1]);
      return '/wishlist/' + urlParams[1] + '/';
    case 'quote':
      // not used
      Router.setShoppingParameters(urlParams[2], urlParams[1]);
      return '/quote/' + urlParams[1] + '/';
    case 'order':
      // urlParams[1] : 'complete', 'review'
      Router.setShoppingParameters(urlParams[2], urlParams[1]);
      return '/order/' + urlParams[1] + '/';
    case 'ecommerce':
      // urlParams[1] : 'checkout', 'complete', 'review'
      Router.setShoppingParameters(urlParams[2], urlParams[1]);
      return '/ecommerce/' + urlParams[1] + '/';
  }
  Router.setShoppingParameters('wishlist');
  return '/wishlist/';
};

export const getRouterState = () => {
  // return globalState.select('applicationState', 'routerState').get();
  return Router.decodeRouterState().route;
};

export const delay = (callback) => {
  setTimeout(function () {
    callback();
  }, 100);
};

export const extractQuantity = (quantity) => {
  if (typeof (quantity) === 'object') return quantity.get('value');
  else if (typeof (quantity) === 'number' || typeof (quantity) === 'string') return quantity;
  else return parseInt(quantity);
};

export const loadFrenchLocale = () => {
  Moment.defineLocale('fr', {
    months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
    monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
    monthsParseExact: true,
    weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin: 'di_lu_ma_me_je_ve_sa'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'YYYY-MM-DD',
      LL: 'D MMMM YYYY',
      LLL: 'D MMMM YYYY HH:mm',
      LLLL: 'dddd D MMMM YYYY HH:mm'
    },
    calendar: {
      sameDay: '[Aujourd’hui à] LT',
      nextDay: '[Demain à] LT',
      nextWeek: 'dddd [à] LT',
      lastDay: '[Hier à] LT',
      lastWeek: 'dddd [dernier à] LT',
      sameElse: 'L'
    },
    relativeTime: {
      future: 'dans %s',
      past: 'il y a %s',
      s: 'quelques secondes',
      ss: '%d secondes',
      m: 'une minute',
      mm: '%d minutes',
      h: 'une heure',
      hh: '%d heures',
      d: 'un jour',
      dd: '%d jours',
      M: 'un mois',
      MM: '%d mois',
      y: 'un an',
      yy: '%d ans'
    },
    dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
    ordinal: function (number, period) {
      switch (period) {
        // Words with masculine grammatical gender: mois, trimestre, jour
        default:
        case 'M':
        case 'Q':
        case 'D':
        case 'DDD':
        case 'd':
          return number + (number === 1 ? 'er' : 'e');

        // Words with feminine grammatical gender: semaine
        case 'w':
        case 'W':
          return number + (number === 1 ? 're' : 'e');
      }
    }
  });

};

export const hasLargeLogo = () => {
  switch (applicationStore.data.defaultTemplate) {
    case "toyota":
    case "subaru":
      return true;
    default:
      return false;
  }
};

export const stopEvent = (evt) => {
  if (evt) {
    checkForEvent: if ('undefined' != typeof evt.keyCode) {
      if (evt.keyCode == 13) {
        evt.preventDefault();
        evt.stopPropagation();
        break checkForEvent;
      }
      return;
    }
  }
};

export const listContains = (list, content) => {
  var isInList = false;
  for (var i in list) {
    if (list[i] == content) {
      isInList = true;
      break;
    }
  }
  return isInList;
};

export const removeFromList = (list, content) => {
  if (listContains(list, content)) {
    var tempList = [];
    for (var i in list) {
      if (list[i] != content) {
        tempList[sizeOf(tempList)] = list[i];
      }
    }
    return tempList;
  } else {
    return list;
  }
};

export const scrollTop = () => {
  document.body.scrollIntoView(-1)
};

export const firstToUpper = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const get_browser = () => {
  var ua = navigator.userAgent, temp,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    temp = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return {name: 'IE', version: (temp[1] || '')};
  }

  if (M[1] === 'Chrome') {
    temp = ua.match(/\bOPR|Edge\/(\d+)/);
    if (temp != null) {
      return {name: 'Opera', version: temp[1]};
    }
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((temp = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, temp[1]);
  }
  return {name: M[0], version: M[1]};
};

export const isEdgeBrowser = () => {
  var browser = get_browser();
  return browser.name === "Opera" && parseInt(browser.version) >= 14;
};

export const isIEBrowser = () => {
  var browser = get_browser();
  return browser.name === "IE" || browser.name === "MSIE";
};

export const isOperaBrowser = () => {
  var browser = get_browser();
  return browser.name === "IE" || browser.name === "MSIE" || browser.name === "Firefox";
};

export const isOutdatedSafari = () => {
  var browser = get_browser();
  return browser.name === "Safari" && parseInt(browser.version) <= 9;
};

const LAYOUT = {
  FULL: 1336,
  DESKTOP: 1143,
  TABLET: 1024,
  MOBILE: 730,
  CAROUSEL: 1240,
};

export const isMobile = () => {
  return window.innerWidth < LAYOUT.MOBILE;
};

export const isTablet = () => {
  return window.innerWidth < LAYOUT.TABLET;
};

export const isCarouselBreak = () => {
  return window.innerWidth < LAYOUT.CAROUSEL;
};

export const getTabIndex = () => {
  window.appTabIndex++;
  return parseInt(window.appTabIndex).toString();
};

export const isEmpty = (object) => {
  return object === null || typeof (object) === 'undefined' || object === '' || object === 'undefined';
};

export const elementHasValue = (elementId) => {
  return document.getElementById(elementId) && !isEmpty(document.getElementById(elementId).value);
};

export const getAppUrl = () => {
  if (isDealerSite()) {
    return applicationStore.data.dealerUrlCode;
  } else if (isNationalSite()) {
    return applicationStore.data.nationalUrlCode;
  } else if (isGroupSite()) {
    return applicationStore.data.groupUrlCode;
  }
};

export const cleanArray = (obj) => {
  var newObj = [];
  for (var i = 0; i < sizeOf(obj); i++) {
    if (!isEmpty(obj[i])) newObj[sizeOf(newObj)] = obj[i];
  }
  return newObj;
};

export const sizeOf = (element) => {
  var size = 0;
  if (typeof (element) !== 'undefined' && element !== null && element !== false) {
    size = element.size;

    if (isNaN(size) || typeof (size) === 'undefined') size = element.length;
    if (isNaN(size) || typeof (size) === 'undefined') size = _.size(element);
    if ((isNaN(size) || typeof (size) === 'undefined') && typeof (element.count) !== 'undefined') size = element.count();
    if (isNaN(size) || typeof (size) === 'undefined') size = 0;
  }
  return size;
};

export const objectSize = (object) => {
  var size = 0;
  if (typeof (object) === 'object') {
    object.forEach(function () {
      size++;
    });
  }
  return size;
};

export const arrayDiff = function (a1, a2) {

  var a = [], diff = [];

  for (var i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }

  for (var i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    } else {
      a[a2[i]] = true;
    }
  }

  for (var k in a) {
    diff.push(k);
  }

  return diff;
};

export const generateGUID = (short = false) => {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1).toUpperCase();
  };
  if (short) {
    return (S4() + S4());
  } else {
    return (S4() + S4() + "-" + S4() + S4() + "-" + S4());
  }
};

export const moment_format = (date, showTime = false) => {
  if (date instanceof Moment) {
    if (showTime) {
      return date.locale(getAppLanguage()).format('LLLL');
    } else {
      return date.locale(getAppLanguage()).format('LLLL').removeMultiple(['12:00 AM', '00:00']);
    }
  }
};

export const phonenumber_format = (s) => {
  var s2 = ("" + s).replace(/\D/g, '');
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return (!m) ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
};

export const money_format = (price) => {
  if (getAppLanguage() === "fr") {
    return number_format(parseFloat(price), 2, ",", " ") + "$";
  } else {
    return "$" + number_format(parseFloat(price), 2, ".", ",");
  }
};

export const mileage_format = (mileage) => {
  if (mileage.indexOf(',') > -1) mileage = mileage.replace(',', '');
  if (getAppLanguage() === 'fr') {
    return number_format(parseFloat(mileage), 0, ",", " ");
  } else {
    return number_format(parseFloat(mileage), 0, ".", ",");
  }
};

export const number_format = (number, decimals, dec_point, thousands_sep) => {
  if (typeof decimals === 'undefined') decimals = 2;
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
    s = '', toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec);
      return '' + (Math.round(n * k) / k).toFixed(prec);
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  if (sizeOf(s[0]) > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if (sizeOf((s[1] || '')) < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - sizeOf(s[1]) + 1).join('0');
  }
  return s.join(dec);
};

export const isValidDate = (date) => {
  return !isNaN(date.valueOf()) && (date instanceof Date || date instanceof Moment);
};

export const checkArrayFieldsExist = (array, fieldList) => {
  var arrayFieldsExist = true;
  if (typeof (array) === 'object' && sizeOf(fieldList) > 0) {
    fieldList.map(function (field, index) {
      if (arrayFieldsExist && !array.hasOwnProperty(fieldList[index])) arrayFieldsExist = false;
      else if (arrayFieldsExist) array = array[field];
    });
  }
  return arrayFieldsExist;
};

export const getVehicleName = () => {
  try {
    let shoppingCartItems;
    let vehicleInformation;
    if (cartItemsAreValid()) {
      let cartInformation = store.getState().shoppingCart.cartInformation;
      // check if cartInformation is a Map or an Object
      if (cartInformation instanceof Map) {
        shoppingCartItems = new Map(cartInformation);
      } else if (typeof cartInformation === 'object') {
        shoppingCartItems = new Map(Object.entries(cartInformation));
      } else {
        // Handle unexpected data types here, if necessary
        throw new TypeError('Unexpected data type for cartInformation');
      }
    }
    vehicleInformation = shoppingCartItems.get("vehicleInformation");

    if (sizeOf(vehicleInformation) > 0) {
      return vehicleInformation[0]["vehicleTitle"][getAppLanguage()];
    } else {
      return "";
    }
  } catch (error) {
    console.error(error);
    return "";
  }
};
export const hasVehicle = () => {
  if (getVehicleName() !== "") {
    return true;
  } else {
    return false;
  }
};

export const getDealerName = () => {
  var dealerName = false;
  if (!applicationStore.data.dealerSite && applicationStore.data.dealerIsRegionalOnly) {
    dealerName = applicationStore.data.nationalAccountName;
    if (applicationStore.data.dealerInfo && applicationStore.data.useProvincialDealers && applicationStore.data.dealerIsRegionalOnly) {
      dealerName = ProvinceArray[applicationStore.data.dealerInfo.province][getAppLanguage()];
    }
  } else if (applicationStore.data.dealerInfo) {
    dealerName = applicationStore.data.dealerInfo.name;
  } else if (applicationStore.data.nationalAccountName) {
    dealerName = applicationStore.data.nationalAccountName;
  }
  return dealerName;
};

export const loadFormUserData = (firstNameField, lastNameField, emailField, phoneField, noteField, altFirstNameField = null, altLastNameField = null, altEmailField = null, altPhoneField = null, altNoteField = null) => {
  if (!isEmpty(shoppingCartViewStore.data.get("formFirstName"))) {
    document.getElementById(firstNameField).value = shoppingCartViewStore.data.get("formFirstName");
  } else if (!isEmpty(decodeQuoteName("first", shoppingCartViewStore.data.get("customerName")))) {
    document.getElementById(firstNameField).value = decodeQuoteName("first", shoppingCartViewStore.data.get("customerName"));
  } else if (altFirstNameField && elementHasValue(altFirstNameField)) {
    document.getElementById(firstNameField).value = document.getElementById(altFirstNameField).value;
  }
  //else if (!isEmpty(getTokenFirstName())) { document.getElementById(firstNameField).value = getTokenFirstName(); }

  if (!isEmpty(shoppingCartViewStore.data.get("formLastName"))) {
    document.getElementById(lastNameField).value = shoppingCartViewStore.data.get("formLastName");
  } else if (!isEmpty(decodeQuoteName("last", shoppingCartViewStore.data.get("customerName")))) {
    document.getElementById(lastNameField).value = decodeQuoteName("last", shoppingCartViewStore.data.get("customerName"));
  } else if (altLastNameField && elementHasValue(altLastNameField)) {
    document.getElementById(lastNameField).value = document.getElementById(altLastNameField).value;
  }
  //else if (!isEmpty(getTokenLastName())) { document.getElementById(lastNameField).value = getTokenLastName(); }

  if (!isEmpty(shoppingCartViewStore.data.get("formEmail"))) {
    document.getElementById(emailField).value = shoppingCartViewStore.data.get("formEmail");
  } else if (!isEmpty(shoppingCartViewStore.data.get("customerEmail"))) {
    document.getElementById(emailField).value = shoppingCartViewStore.data.get("customerEmail");
  } else if (altEmailField && elementHasValue(altEmailField)) {
    document.getElementById(emailField).value = document.getElementById(altEmailField).value;
  }

  if (!isEmpty(shoppingCartViewStore.data.get("formrPhone"))) {
    document.getElementById(phoneField).value = shoppingCartViewStore.data.get("formrPhone");
  } else if (!isEmpty(shoppingCartViewStore.data.get("customerPhone"))) {
    document.getElementById(phoneField).value = shoppingCartViewStore.data.get("customerPhone");
  } else if (altPhoneField && elementHasValue(altPhoneField)) {
    document.getElementById(phoneField).value = document.getElementById(altPhoneField).value;
  }

  if (!isEmpty(shoppingCartViewStore.data.get("formNotes"))) {
    document.getElementById(noteField).value = shoppingCartViewStore.data.get("formNotes");
  } else if (!isEmpty(shoppingCartViewStore.data.get("customerNotes"))) {
    document.getElementById(noteField).value = shoppingCartViewStore.data.get("customerNotes");
  } else if (altNoteField && elementHasValue(altNoteField)) {
    document.getElementById(noteField).value = document.getElementById(altNoteField).value;
  }
};

const getTokenFirstName = () => {
  return JSON.parse(window.token).firstName;
};

const getTokenLastName = () => {
  return JSON.parse(window.token).lastName;
};

export const getHrefPhoneFormat = (phone) => {
  return phone.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};

export const removeFrenchCharacters = (string) => {
  var re0 = new RegExp('À', 'g');
  var re1 = new RegExp('Â', 'g');
  var re2 = new RegExp('Ä', 'g');
  var re3 = new RegExp('Ç', 'g');
  var re4 = new RegExp('É', 'g');
  var re5 = new RegExp('È', 'g');
  var re6 = new RegExp('Ê', 'g');
  var re7 = new RegExp('Ë', 'g');
  var re8 = new RegExp('Î', 'g');
  var re9 = new RegExp('Ï', 'g');
  var re10 = new RegExp('Ô', 'g');
  var re11 = new RegExp('Ù', 'g');
  var re12 = new RegExp('Û', 'g');
  var re13 = new RegExp('Ü', 'g');
  return string.replace(re0, 'A').replace(re1, 'A').replace(re2, 'A').replace(re3, 'C').replace(re4, 'E').replace(re5, 'E').replace(re6, 'E').replace(re7, 'E')
    .replace(re8, 'I').replace(re9, 'I').replace(re10, 'O').replace(re11, 'U').replace(re12, 'U').replace(re13, 'U');
};

export const validateEmail = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

export const validatePostal = (postal) => {
  var re = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
  return re.test(postal.toLowerCase());
};

export const validateZip = (zip) => {
  var re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  return re.test(zip.toLowerCase());
};

export const arraySearch = (arr, val) => {
  for (var i = 0; i < sizeOf(arr); i++) if (arr[i] === val) return i;
  return false;
};

export const arrayKeyExists = (arr, key) => {
  return typeof arr[key] === 'object';
};

export const cleanTextForHtml = (array) => {
  if (array) {
    for (var i in array) {
      if (typeof array[i] !== 'undefined') {
        if (typeof array[i] === 'object' || typeof array[i] === 'array') {
          array[i] = cleanTextForHtml(array[i]);
        } else if (typeof array[i] === 'string' && sizeOf(array[i]) > 4) {

          try {
            array[i] = decodeURIComponent(escape(array[i].replace(new RegExp("&#39;", 'g'), "'")
              .replace(new RegExp('<br />', 'g'), "\n")
              .replace(new RegExp('<br/>', 'g'), "\n")));
          } catch (e) {
            array[i] = array[i].replace(new RegExp("&#39;", 'g'), "'")
              .replace(new RegExp('<br />', 'g'), "\n")
              .replace(new RegExp('<br/>', 'g'), "\n");
          }

        }
      }
    }
  }
  return array;
};

export const sortTires = (a, b) => {
  var formattedTiresize_a = a.get('frontTire').get('tireSize');
  var formattedTiresize_b = b.get('frontTire').get('tireSize');

  var tiresizeInfoA = {};
  var tiresizeInfoB = {};

  var temp, seperator, constructCode, diameter, width, ratio;
  if (formattedTiresize_a) {
    if (formattedTiresize_a.indexOf('R') > -1) constructCode = 'R';
    else if (formattedTiresize_a.indexOf('D') > -1) constructCode = 'D';
    else if (formattedTiresize_a.indexOf('B') > -1) constructCode = 'B';
    temp = formattedTiresize_a.split(constructCode);
    diameter = temp[1];
    if (formattedTiresize_a.indexOf('/') > -1) seperator = '/';
    else if (formattedTiresize_a.indexOf('X') > -1) seperator = 'X';
    temp = temp[0].split(seperator);
    width = temp[0];
    ratio = temp[1];
    tiresizeInfoA = {
      width: width,
      seperator: seperator,
      ratio: ratio,
      constructCode: constructCode,
      diameter: diameter
    };
  }

  if (formattedTiresize_b) {
    if (formattedTiresize_b.indexOf('R') > -1) constructCode = 'R';
    else if (formattedTiresize_b.indexOf('D') > -1) constructCode = 'D';
    else if (formattedTiresize_b.indexOf('B') > -1) constructCode = 'B';
    temp = formattedTiresize_b.split(constructCode);
    diameter = temp[1];
    if (formattedTiresize_b.indexOf('/') > -1) seperator = '/';
    else if (formattedTiresize_b.indexOf('X') > -1) seperator = 'X';
    temp = temp[0].split(seperator);
    width = temp[0];
    ratio = temp[1];
    tiresizeInfoB = {
      width: width,
      seperator: seperator,
      ratio: ratio,
      constructCode: constructCode,
      diameter: diameter
    };
  }

  if (tiresizeInfoA.seperator === 'X' && tiresizeInfoB.seperator === '/') return 1;
  else if (tiresizeInfoA.seperator === '/' && tiresizeInfoB.seperator === 'X') return -1;
  else if (tiresizeInfoA.diameter > tiresizeInfoB.diameter) return 1;
  else if (tiresizeInfoA.diameter < tiresizeInfoB.diameter) return -1;
  else if (tiresizeInfoA.width > tiresizeInfoB.width) return 1;
  else if (tiresizeInfoA.width < tiresizeInfoB.width) return -1;
  else if (tiresizeInfoA.ratio > tiresizeInfoB.ratio) return 1;
  else if (tiresizeInfoA.ratio < tiresizeInfoB.ratio) return -1;
  else return 0;
};

export const sortTiresizes = (a, b) => {
  var tiresizeInfoA = {};
  var tiresizeInfoB = {};

  var temp, seperator, constructCode, diameter, width, ratio;
  if (a.get('formattedTiresize')) {
    if (a.get('formattedTiresize').indexOf('R') > -1) constructCode = 'R';
    else if (a.get('formattedTiresize').indexOf('D') > -1) constructCode = 'D';
    else if (a.get('formattedTiresize').indexOf('B') > -1) constructCode = 'B';
    temp = a.get('formattedTiresize').split(constructCode);
    diameter = temp[1];
    if (a.get('formattedTiresize').indexOf('/') > -1) seperator = '/';
    else if (a.get('formattedTiresize').indexOf('X') > -1) seperator = 'X';
    temp = temp[0].split(seperator);
    width = temp[0];
    ratio = temp[1];
    tiresizeInfoA = {
      width: width,
      seperator: seperator,
      ratio: ratio,
      constructCode: constructCode,
      diameter: diameter
    };
  }

  if (b.get('formattedTiresize')) {
    if (b.get('formattedTiresize').indexOf('R') > -1) constructCode = 'R';
    else if (b.get('formattedTiresize').indexOf('D') > -1) constructCode = 'D';
    else if (b.get('formattedTiresize').indexOf('B') > -1) constructCode = 'B';
    temp = b.get('formattedTiresize').split(constructCode);
    diameter = temp[1];
    if (b.get('formattedTiresize').indexOf('/') > -1) seperator = '/';
    else if (b.get('formattedTiresize').indexOf('X') > -1) seperator = 'X';
    temp = temp[0].split(seperator);
    width = temp[0];
    ratio = temp[1];
    tiresizeInfoB = {
      width: width,
      seperator: seperator,
      ratio: ratio,
      constructCode: constructCode,
      diameter: diameter
    };
  }

  if (tiresizeInfoA.seperator === 'X' && tiresizeInfoB.seperator === '/') return 1;
  else if (tiresizeInfoA.seperator === '/' && tiresizeInfoB.seperator === 'X') return -1;
  else if (tiresizeInfoA.diameter > tiresizeInfoB.diameter) return 1;
  else if (tiresizeInfoA.diameter < tiresizeInfoB.diameter) return -1;
  else if (tiresizeInfoA.width > tiresizeInfoB.width) return 1;
  else if (tiresizeInfoA.width < tiresizeInfoB.width) return -1;
  else if (tiresizeInfoA.ratio > tiresizeInfoB.ratio) return 1;
  else if (tiresizeInfoA.ratio < tiresizeInfoB.ratio) return -1;
  else return 0;
};

export const runTiresizeSearch = (searchModal, type, link, value, isSearchModal = false) => {
  if (isSearchModal && isDealerSite() && (isEdgeBrowser() && type !== SearchTypes.SEARCH_BY_PARTNUMBER)) {
    if (link.link !== null) {

      //window.open(link.link);
      window.open(link.link, "_self");

    }
  } else if (!searchModal && (isNationalSite() && !nationalSiteHasDealerSelected())) {
    if (link.link !== null) {
      openSiteLink(link.link);
    }
  } else {
    setTimeout(() => {
      searchActions.loadSearch(type, value);
    }, 100);
  }
};

export const openSiteLink = (link) => {
  if (
    window.location.href.indexOf('gclid') === -1
    && window.location.href.indexOf('_ga') === -1
    && window.location.href.indexOf('utm_source') === -1
    && window.location.href.indexOf('utm_medium') === -1
    && window.location.href.indexOf('utm_campaign') === -1
  ) {
    window.location = window.location.origin + link;
    //setTimeout(()=> { window.location.reload(); }, 10);
  } else {
    window.location.href = link;
  }
};

export const checkSiteHasCampaign = () => {
  var siteHasCampaign = false;
  if (!!window.campaignData) if (!!window.campaignData.campaign) {
    if (parseInt(window.campaignData.campaign.campaignId) > 0 && window.campaignData.campaign.campaignIsActive) siteHasCampaign = true;
    else if (parseInt(window.campaignData.campaign.campaignId) > 0 && !window.campaignData.campaign.campaignIsActive) {
      setTimeout(() => {
        applicationActions.loadCampaignIsInactive()
      }, 10);
    }
  }
  return siteHasCampaign;
};

export const getCampaignId = () => {
  if (checkSiteHasCampaign()) {
    return parseInt(window.campaignData.campaign.campaignId);
  } else return 0;
};

export const getCampaignCode = () => {
  if (checkSiteHasCampaign()) {
    return window.campaignData.campaign.campaignCode;
  } else return null;
};

export const getCampaignName = () => {
  if (checkSiteHasCampaign()) {
    return window.campaignData.campaign.campaignName[getAppLanguage()];
  } else return null;
};

export const getDealerCampaignBaseUrl = (dealerUrlCode) => {
  if (checkSiteHasCampaign()) {
    return "/marketing/dealer/" + dealerUrlCode + "/campaign/" + getCampaignCode();
  } else return null;
};

export const getNationalCampaignBaseUrl = (nationalUrlCode) => {
  if (checkSiteHasCampaign()) {
    return "/marketing/national/" + nationalUrlCode + "/campaign/" + getCampaignCode();
  } else return null;
};

export const getWindowTemplate = () => {

  if (isNationalSite()) {
    return window.nationalAccountInfoPayload.nationalDefaultTemplate;
  } else if (isDealerSite()) {
    return window.dealerAccountInfoPayload.dealerDefaultTemplate;
  } else {
    return "";
  }

};

export const getAppTemplate = () => {
  if (applicationStore.data.defaultTemplate) return applicationStore.data.defaultTemplate;
  else if (window.dealerAccountInfoPayload) return window.dealerAccountInfoPayload.dealerDefaultTemplate;
  else if (window.nationalAccountInfoPayload) return window.nationalAccountInfoPayload.nationalDefaultTemplate;
  else if (isConsumerSite()) return 'consumer';
  return null;
};

export const isNationalSite = () => {
  return (sizeOf(window.nationalAccountInfoPayload) > 0);
};

export const isGroupSite = () => {
  return (sizeOf(window.groupAccountInfoPayload) > 0);
};

export const isConsumerSite = () => {
  return sizeOf(window.consumerAccountInfoPayload) > 0;
};

export const isDealerSite = () => {
  return !isNationalSite() && !isGroupSite() && (sizeOf(window.dealerAccountInfoPayload) > 0);
};

export const nationalSiteHasDealerSelected = () => {
  return isNationalSite() && sizeOf(window.dealerAccountInfoPayload) > 0;
};

export const isFrenchSite = (initialization) => {
  return languageList.indexOf(getAppLanguage(initialization)) === 1;
};

export const isDesktop = () => {
  return window.innerWidth >= LAYOUT.DESKTOP;
};

export const getAppDealerId = () => {
  var dealerId = 0;
  if (!dealerIdIsValid(dealerId) && !isNaN(applicationStore.data.dealerId) && applicationStore.data.dealerId > 0) dealerId = applicationStore.data.dealerId;
  if (!dealerIdIsValid(dealerId) && applicationStore.data.dealer && checkArrayFieldsExist(applicationStore.data.dealer, ['id'])) dealerId = applicationStore.data.dealer.id;
  if (!dealerIdIsValid(dealerId) && window.dealerAccountInfoPayload && checkArrayFieldsExist(window.dealerAccountInfoPayload, ['dealerId'])) dealerId = window.dealerAccountInfoPayload.dealerId;
  if (!dealerIdIsValid(dealerId) && window.location.href.indexOf('/dealer/') > -1) {
    var temp = window.location.href.split('/dealer/');
    if (temp[1].indexOf('/')) {
      temp = temp[1].split('/');
      if (parseInt(temp[0]) > 0) dealerId = temp[0];
    } else {
      if (parseInt(temp[1]) > 0) dealerId = temp[1];
    }
    if (dealerId.indexOf('?') > -1) {
      temp = dealerId.split('?');
      dealerId = temp[0];
    }
  }
  if (!dealerIdIsValid(dealerId) && checkSiteHasCampaign()) {
    if (sizeOf(window.campaignData.campaignDealerList) === 1) {
      dealerId = window.campaignData.campaignDealerList[0].dealerId;
    }
  }
  return dealerId;
};

const dealerIdIsValid = (dealerId) => {
  var validId = true;
  if (isNaN(dealerId)) validId = false;
  if (parseInt(dealerId) === 0) validId = false;
  if (typeof (dealerId) !== 'number') validId = false;
  return validId;
};

export const getAppLanguage = (initialization = false) => {
  var language;

  // Get stored language
  if (!initialization) {
    language = applicationStore.data.lang ? applicationStore.data.lang : languageStore.data.lang;
    if(isEmpty(language)) language = store.getState().info.lang;
  }
  // Get language from url
  if (isEmpty(language) || !languageIsValid(language)) {
    if (
      window.location.href.indexOf('/en/') > -1
      || window.location.href.indexOf('#en/') > -1
      || window.location.href.indexOf('/en?') > -1
      || window.location.href.endsWith('/en')
    ) {
      language = 'en';
    } else if (
      window.location.href.indexOf('/fr/') > -1
      || window.location.href.indexOf('#fr/') > -1
      || window.location.href.indexOf('/fr?') > -1
      || window.location.href.endsWith('/fr')
    ) {
      language = 'fr';
    }
  }

  // Check language is valid
  if (!languageIsValid(language)) {
    language = null;
  }

  return language;
};

export const languageIsValid = (language) => {
  return languageList.indexOf(language) > -1;
};

export const getAppVehicleId = () => {
  if (parseInt(applicationStore.data.vehicle) > 0) return applicationStore.data.vehicle;
  else if (parseInt(vehicleSelectionStore.data.vehicleId) > 0) return vehicleSelectionStore.data.vehicleId;
  else return null;
};

export const getProductListNumberResultsToShow = () => {
  var paginationIndex = parseInt(applicationStore.data.dealerNumberOfProductsToShowBeforePagination);
  if (!isEmpty(paginationIndex) && paginationIndex > 0) {
    return paginationIndex;
  } else {
    return 15;
  }
};

export const getShowDealerInfo = () => {
  return (
    applicationStore.data.dealerInfo
    && !applicationStore.data.showCampaignDealerInfo
    && (
      !applicationStore.data.enableMercedesFeatures
      || (
        isDealerSite()
        && !applicationStore.data.useProvincialDealers
      )
    )
  );
};

export const getDealerInfo = (removeName = false) => {

  if (!isEmpty(applicationStore.data.dealerInfo)) {
    return (
      Immutable.fromJS([
        !removeName ? applicationStore.data.dealerInfo.name : '',
        applicationStore.data.dealerInfo.address,
        applicationStore.data.dealerInfo.city,
        applicationStore.data.dealerInfo.province,
        applicationStore.data.dealerInfo.postalCode
      ]).filter(function (value) {
        return value;
      }).toArray().join(', ')
    );
  } else {
    return '';
  }

};

export const getDealerContactInfo = (narrowheader = false) => {

  if (!isEmpty(applicationStore.data.dealerInfo)) {

    if (narrowheader) {
      return (
        Immutable.fromJS([
          applicationStore.data.dealerInfo.phone,
          applicationStore.data.dealerInfo.email,
        ]).filter(function (value) {
          return value;
        }).toArray().join(' | ')
      );
    } else {
      return (
        Immutable.fromJS([
          applicationStore.data.dealerInfo.email,
          applicationStore.data.dealerInfo.phone,
        ]).filter(function (value) {
          return value;
        }).toArray().join(' ')
      );
    }


  } else {
    return '';
  }

};

export const getDealerContactPhone = () => {

  if (!isEmpty(applicationStore.data.dealerInfo)) {
    return applicationStore.data.dealerInfo.phone;
  } else {
    return '';
  }

};

export const getDealerContactEmail = () => {

  if (!isEmpty(applicationStore.data.dealerInfo)) {
    return applicationStore.data.dealerInfo.email;
  } else {
    return '';
  }

};

export const getDealerContactWebsite = () => {

  if (!isEmpty(applicationStore.data.dealerInfo)) {
    return applicationStore.data.dealerInfo.website;
  } else {
    return '';
  }

};

export const getDealerContactHours = () => {

  if (!isEmpty(applicationStore.data.dealerInfo)) {
    return applicationStore.data.dealerInfo.hours;
  } else {
    return '';
  }

};

export const getFormattedStandardTime = (open, close) => {
  var temp;

  if (!isEmpty(open) && !isEmpty(close)) {

    open = open.split(':');
    close = close.split(':');

    var openHours = Number(open[0]), openMinutes = Number(open[1]), closeHours = Number(close[0]),
      closeMinutes = Number(close[1]);

    if (openHours > 0 && openHours <= 12) {
      temp = "" + openHours;
    } else if (openHours > 12) {
      temp = "" + (openHours - 12);
    } else if (openHours == 0) {
      temp = "12";
    }

    temp += (openMinutes < 10) ? ":00" + openMinutes : ":" + openMinutes;
    temp += (openHours >= 12) ? "pm" : "am";

    temp += " " + languageStore.getDictionary().calendar.to + " ";

    if (closeHours > 0 && closeHours <= 12) {
      temp += "" + closeHours;
    } else if (closeHours > 12) {
      temp += "" + (closeHours - 12);
    } else if (closeHours == 0) {
      temp += "12";
    }

    temp += (closeMinutes < 10) ? ":00" + closeMinutes : ":" + closeMinutes;
    temp += (closeHours >= 12) ? "pm" : "am";

    temp += " " + languageStore.getDictionary().calendar.EST;

  } else {

    temp = languageStore.getDictionary().support.closed;

  }

  return temp;
};

export const allowHeaderNavigation = () => {
  switch (getRouterState()) {
    case RouterStates.DEALER_SELECTION:
    case RouterStates.LANDING_PAGE:
      return false;
    default:
      switch (applicationStore.data.defaultTemplate) {
        case "audi":
        case "mazdatransition":
        case "volkswagen":
        case "volkswagen_2020":
          return false;
        default:
          return applicationStore.data.allowAdvancedSearch && !checkSiteHasCampaign();
      }
  }
};

export const loadWishlistModal = (type, leadId) => {
  setTimeout(() => {
    shoppingCartActions.openWishlistFormModal(formValues[type], leadId);
  }, 1000);
};

export const loadWishlistDealerModal = (type, leadId) => {
  setTimeout(() => {
    shoppingCartActions.openWishlistDealerFormModal(formValues[type], leadId);
  }, 1000);
};

export const leadWasActioned = (leadId) => {
  setTimeout(() => {
    shoppingCartActions.setLeadAsActioned(leadId);
  }, 100);
};

export const skipBreadcrumbsLoad = () => {
  var routerState = getRouterState();
  return routerState === RouterStates.TRANSLATION_DICTIONARY ||
    routerState === RouterStates.DEALER_SELECTION ||
    routerState === RouterStates.QUOTE ||
    routerState === RouterStates.HOMEPAGE ||
    routerState === RouterStates.CONTACT_DEALER ||
    routerState === RouterStates.COMPARISON ||
    routerState === RouterStates.PRINT_COMPARISON ||
    routerState === RouterStates.PRINT_COMPARISON_FROM_TIRESIZE ||
    routerState === RouterStates.LANDING_PAGE ||
    routerState === RouterStates.SEARCH_BRANDS ||
    routerState === RouterStates.SEARCH_BRANDS_BY_SEASON ||
    routerState === RouterStates.SEARCH_MODELS ||
    routerState === RouterStates.SEARCH_MODELS_BY_SEASON ||
    routerState === RouterStates.SEARCH_TIRE ||
    routerState === null;
};

export const dealerSiteIsDisabled = () => {
  if (isDealerSite()) {
    return !window.dealerAccountInfoPayload.dealerEnableEcommerceRetailSite;
  } else {
    return false;
  }
};

export const scrollToPageTop = (currentPayload, lastStatePayload) => {
  if (sizeOf(currentPayload) > 0 && sizeOf(lastStatePayload) === 0) {
    setTimeout(() => {
      $("html, body").animate({scrollTop: 0}, "slow");
    }, 150);
  }
};

export const setComboBoxZIndex = (currentResults, lastStateResults) => {
  if (sizeOf(currentResults) > 0 && sizeOf(lastStateResults) === 0) {
    var dropdownList = document.getElementsByClassName('combo-box');
    if (dropdownList) {
      for (var i in dropdownList) {
        if (typeof (dropdownList[i]) === 'object') dropdownList[i].setAttribute("style", "z-index: " + (90 - parseFloat(i)));
      }
    }
  }
};

export const getShowOEFilter = () => {
  if (isUserLoggedIn()) {
    return applicationStore.data.useOriginalEquipmentOnlyFilterWhenAuhenticated;
  } else {
    return applicationStore.data.useOriginalEquipmentOnlyFilter;
  }
};

export const getRequireCaptcha = () => {
  var requiresCaptcha = false;
  if (applicationStore.data.dealerEnableCaptcha) requiresCaptcha = true;
  if (applicationStore.data.dealerForceCaptchaPassed) requiresCaptcha = false;
  if (isUserLoggedIn()) requiresCaptcha = false;
  if (productListStore.data.searchType == SearchTypes.SEARCH_BY_PARTNUMBER) requiresCaptcha = false;
  //if (productListStore.data.searchType == SearchTypes.SEARCH_BY_TIRESIZE || productListStore.data.searchType == SearchTypes.SEARCH_BY_PARTNUMBER) requiresCaptcha = false;
  return requiresCaptcha;
};

export const getTirePromotionalImage = () => {
  if (applicationStore.data.promotionalImageTire.hasOwnProperty(getAppLanguage())) {

    if (applicationStore.data.promotionalImageTire[getAppLanguage()] !== "") {
      return (
        <Image className="main-image" src={applicationStore.data.promotionalImageTire[getAppLanguage()]}/>
      );
    } else {
      return false;
    }

  } else if (applicationStore.data.promotionalImageTire) {
    return (
      <Image className="main-image" src={applicationStore.data.promotionalImageTire.get(getAppLanguage())}/>
    );
  } else {
    return false;
  }
};

export const getSeasonObjectList = () => {
  var dictionaryEn = languageStore.getMessages("en"), dictionaryFr = languageStore.getMessages("fr");
  return {
    0: {en: dictionaryEn.product.filters.season.winter, fr: dictionaryFr.product.filters.season.winter},
    1: {en: dictionaryEn.product.filters.season.allSeason, fr: dictionaryFr.product.filters.season.allSeason},
    2: {en: dictionaryEn.product.filters.season.summer, fr: dictionaryFr.product.filters.season.summer}
  };
};

export const getSeasonText = () => {
  var dictionary = languageStore.getDictionary();
  let season = store.getState().productList.selectedSeason;
  season = parseInt(season);
  switch (season) {
    case 0:
      return dictionary.product.filters.season.winter;
    case 1:
      return dictionary.product.filters.season.allSeason;
    case 2:
      return dictionary.product.filters.season.summer;
    default:
      return '';
  }
};

export const getUserIpAddress = () => {
  return window.ipAddress;
};

export const getSecurityTokenWrapper = (url) => {
  var dealerId = !isEmpty(applicationStore.data.selectedDealerIdForToken) ? applicationStore.data.selectedDealerIdForToken : getAppDealerId(),
    updateUrl = url + '?userIpAddress=' + getUserIpAddress() + (dealerId ? "&dealerId=" + dealerId : "");

  if (isDealerSite()) {

    updateUrl += '&dealerUrlCode=' + getDealerUrlCode();

  } else if (isNationalSite()) {

    updateUrl += '&nationalAccountCode=' + applicationStore.data.nationalUrlCode;

  } else if (isGroupSite()) {

    updateUrl += '&dealerGroupCode=' + applicationStore.data.groupUrlCode;

  } else if (isConsumerSite()) {

    updateUrl += '&dealerGroupCode=nationalsite';

  }

  if (checkSiteHasCampaign()) {

    updateUrl += '&campaignId=' + getCampaignId();

  }

  return updateUrl;
};

export const getSecurityTokenRequestObject = (username = '', password = '') => {
  var params = {
    username: username,
    password: password,
    dealerId: !isEmpty(applicationStore.data.selectedDealerIdForToken) ? applicationStore.data.selectedDealerIdForToken : getAppDealerId(),
    userIpAddress: getUserIpAddress(),
    campaignId: checkSiteHasCampaign() ? getCampaignId() : null,
    isNational: isNationalSite() ? 1 : 0,
    rememberMe: true
  };

  if (isDealerSite()) {

    params.dealerUrlCode = getDealerUrlCode();

  } else if (isNationalSite()) {

    params.nationalAccountCode = applicationStore.data.nationalUrlCode;

  } else if (isGroupSite()) {

    params.dealerGroupCode = applicationStore.data.groupUrlCode;

  } else if (isConsumerSite()) {

    params.dealerGroupCode = 'nationalsite';

  }

  return params;
};

export const getDealerUrlCode = () => {
  if (applicationStore.data.selectedDealerForToken) {
    return applicationStore.data.selectedDealerForToken;
  } else if (applicationStore.data.dealerUrlCode) {
    return applicationStore.data.dealerUrlCode;
  } else if (applicationStore.data.dealer) {
    return applicationStore.data.dealer.get('urlCode');
  } else if (applicationStore.data.dealerInfo) {
    return applicationStore.data.dealerInfo.dealerUrlCode;
  }
};

export const getTireTrackingLabel = (props) => {
  if (typeof (props.tiresizeSearch) !== 'undefined' && props.tiresizeSearch === true) {
    return 'tiresize';
  } else if (typeof (props.partnumberSearch) !== 'undefined' && props.partnumberSearch === true) {
    return 'partnumber';
  } else {
    return 'tire';
  }
};

export const trackAddToCart = (response, product, id, source, isDealerOrder, isConsumerOrder) => {
  var supportDealerOrder = false, supportConsumerOrder = false;
  if (sizeOf(response.orderItemList) > 0 && (response.supportDealerOrder || response.supportConsumerOrder)) response.orderItemList.map(function (part) {
    if (part.itemId === id && part.supportDealerOrder) supportDealerOrder = true;
    else if (part.itemId === id && part.supportConsumerOrder) supportConsumerOrder = true;
  });

  if (source) source = " (" + source + ")";

  /*
  console.log(
    'trackAddToCart'
    +', supportDealerOrder: '+supportDealerOrder
    +', supportConsumerOrder: '+supportConsumerOrder
    +', source: '+source
    +', getTrackingEventPart: '+getTrackingEventPart(product, true)
  );
  */

  //getTrackingEventPartOrdered(product, true, isDealerOrder, isConsumerOrder))
  //getTrackingEventPart(product, true)

  if (supportDealerOrder) googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'add to order', source);
  else if (supportConsumerOrder) googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'add to cart', source);
  else googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'add to quote', source);
};

export const trackRemoveFromCart = (cart, id) => {
  var supportDealerOrder = false, supportConsumerOrder = false, product = cart.get('product');
  if (product.get('type') === ProductTypes.TIRES) {
    if (product.get('frontTire').get('id') === id) {
      if (product.get('frontTire').get('supportDealerOrder')) supportDealerOrder = true;
      else if (product.get('frontTire').get('supportConsumerOrder')) supportConsumerOrder = true;
    } else if (product.get('rearTire').get('id') === id) {
      if (product.get('rearTire').get('supportDealerOrder')) supportDealerOrder = true;
      else if (product.get('rearTire').get('supportConsumerOrder')) supportConsumerOrder = true;
    }
  }
  if (supportDealerOrder) googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'Shopping Cart - RemoveFromDealerOrder', getTrackingEventPart(product, true));
  else if (supportConsumerOrder) googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'Shopping Cart - RemoveFromConsumerOrder', getTrackingEventPart(product, true));
  else googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'Shopping Cart - RemoveFromCart', getTrackingEventPart(product, true));
};

export const trackOrder = (response, trackingText) => {
  var valid = true;
  if (!response) valid = false;
  else {
    if (sizeOf(response.orderItemList) === 0) valid = false;
    else if (sizeOf(response.orderFulfillmentList) === 0) valid = false;
  }
  if (!valid) googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'NTD Order Modal - ' + trackingText + ' [FAILED TO LOAD]', getTrackingEventOrderId());
};

export const getTrackingEventCategory = () => {
  var category = {};

  if (applicationStore.data.nationalSite) category.type = 'Ntl';
  else if (applicationStore.data.groupSite) category.type = 'Grp';
  else if (applicationStore.data.dealerSite) category.type = 'Dlr';

  if (applicationStore.data.nationalSite) category.ntlId = applicationStore.data.nationalAccountId;
  else if (applicationStore.data.groupSite) category.groupId = applicationStore.data.groupAccountId;
  else if (applicationStore.data.dealerSite) category.ntlId = applicationStore.data.nationalAccountId;

  if (applicationStore.data.nationalSite) category.ntlUrl = applicationStore.data.nationalUrlCode ? applicationStore.data.nationalUrlCode : '';
  else if (applicationStore.data.groupSite) category.groupUrl = applicationStore.data.groupUrlCode ? applicationStore.data.groupUrlCode : '';

  var dealerId = null, dealerName = null;
  if (applicationStore.data.selectedDealerIdForToken) dealerId = applicationStore.data.selectedDealerIdForToken;
  else if (applicationStore.data.dealerId) dealerId = applicationStore.data.dealerId;
  else if (applicationStore.data.dealer) dealerId = applicationStore.data.dealer.get('id');
  else if (applicationStore.data.dealerInfo) dealerId = applicationStore.data.dealerInfo.dealerId;
  if (applicationStore.data.selectedDealerForToken) dealerName = applicationStore.data.selectedDealerForToken;
  else if (applicationStore.data.dealer) dealerName = applicationStore.data.dealer.get('name');
  else if (applicationStore.data.dealerInfo) dealerName = applicationStore.data.dealerInfo.name;
  if (dealerId) category.dlrId = dealerId;
  if (dealerName) category.dlrName = dealerName;

  if (applicationStore.data.nationalSite) category.ntlName = applicationStore.data.nationalAccountName;
  else if (applicationStore.data.dealerSite) category.ntlName = applicationStore.data.dealerNationalAccountName;

  if (!isUserLoggedIn()) category.ecom = applicationStore.data.supportConsumerOrder;
  else category.order = applicationStore.data.supportDealerOrder;

  category.auth = isUserLoggedIn();

  if (checkSiteHasCampaign()) {
    category.cmpId = getCampaignId();
    category.cmpCode = getCampaignCode();
  }

  if (!isEmpty(applicationStore.data.dealer)) {
    category.province = applicationStore.data.dealer.get('province');
  }

  return JSON.stringify(category);
};

export const getTrackingEventDealer = (dealer) => {
  var dealerText = {};

  if (dealer && _.size(dealer) > 0) {
    // dealerText.dealerId = dealer.get('id');
    dealerText.dealerId = dealer['id']
    dealerText.dealerUrl = dealer['urlCode'];
    dealerText.dealerName = dealer['name'];
  }

  return JSON.stringify(dealerText);
};

export const getTrackingEventPart = (part, includePricing) => {
  var partData = {};

  switch (part.get('type')) {
    case ProductTypes.TIRES:
      partData.type = part.get('type');
      partData.partId = part.get('frontTire').get('id');
      partData.partNumber = part.get('frontTire').get('partNumber');
      if (includePricing) partData.price = part.get('frontTire').get('pricing').get('displayPrice');
      if (includePricing) partData.qty = part.get('frontTire').get('quantity').get('value');
      if (includePricing) partData.pcId = part.get('frontTire').get('quantity').get('pricingContextId');
      if (part.get('staggered')) {
        partData.rearPartId = part.get('rearTire').get('id');
        partData.rearPartNumber = part.get('rearTire').get('partNumber');
        if (includePricing) partData.rearPrice = part.get('rearTire').get('pricing').get('displayPrice');
        if (includePricing) partData.rearQty = part.get('rearTire').get('quantity').get('value');
        if (includePricing) partData.rearPcId = part.get('rearTire').get('quantity').get('pricingContextId');
      }
      break;
    case ProductTypes.ALLOY_WHEELS:
      partData.type = part.get('type');
      partData.partId = part.get('frontWheel').get('id');
      partData.partNumber = part.get('frontWheel').get('partNumber');
      if (includePricing) partData.price = part.get('frontWheel').get('pricing').get('displayPrice');
      if (includePricing) partData.qty = part.get('frontWheel').get('quantity').get('value');
      if (includePricing) partData.pcId = part.get('frontWheel').get('quantity').get('pricingContextId');
      if (part.get('staggered')) {
        partData.rearPartId = part.get('rearWheel').get('id');
        partData.rearPartNumber = part.get('rearWheel').get('partNumber');
        if (includePricing) partData.rearPrice = part.get('rearWheel').get('pricing').get('displayPrice');
        if (includePricing) partData.rearQty = part.get('rearWheel').get('quantity').get('value');
        if (includePricing) partData.rearPcId = part.get('rearWheel').get('quantity').get('pricingContextId');
      }
      break;
    case ProductTypes.ACCESSORIES:
    case ProductTypes.PACKAGES:
      partData.type = part.get('type');
      partData.partId = part.get('id');
      partData.partNumber = part.get('partNumber');
      if (includePricing) partData.price = part.get('price') && part.get('price').displayPrice ? part.get('price').displayPrice : part.get('pricing').get('displayPrice');
      if (includePricing) partData.qty = part.get('quantity').value ? part.get('quantity').value : part.get('quantity').get('value');
      if (includePricing) partData.pcId = part.get('quantity').pricingContextId ? part.get('quantity').pricingContextId : part.get('quantity').get('pricingContextId');
      break;
    default:
      partData.error = 'Part Info Failed Collection. Invalid Part Type: ' + part.get('type') + '.';
      break;
  }

  return JSON.stringify(partData);
};

export const getTrackingEventPartOrdered = (part, includePricing, dealerOrderClick = false, consumerOrderClick = false) => {
  var partData = {};

  switch (part.get('type')) {
    case ProductTypes.TIRES:
      partData.type = part.get('type');
      partData.partId = part.get('frontTire').get('id');
      partData.partNumber = part.get('frontTire').get('partNumber');
      if (includePricing) partData.price = part.get('frontTire').get('pricing').get('displayPrice');
      if (includePricing) partData.qty = part.get('frontTire').get('quantity').get('value');
      if (includePricing) partData.pcId = part.get('frontTire').get('quantity').get('pricingContextId');
      if (part.get('staggered')) {
        partData.rearPartId = part.get('rearTire').get('id');
        partData.rearPartNumber = part.get('rearTire').get('partNumber');
        if (includePricing) partData.rearPrice = part.get('rearTire').get('pricing').get('displayPrice');
        if (includePricing) partData.rearQty = part.get('rearTire').get('quantity').get('value');
        if (includePricing) partData.rearPcId = part.get('rearTire').get('quantity').get('pricingContextId');
      }
      if (dealerOrderClick === true) partData.orderNowClicked = true;
      else partData.orderNowClicked = false;
      break;
    case ProductTypes.ALLOY_WHEELS:
      partData.type = part.get('type');
      partData.partId = part.get('frontWheel').get('id');
      partData.partNumber = part.get('frontWheel').get('partNumber');
      if (includePricing) partData.price = part.get('frontWheel').get('pricing').get('displayPrice');
      if (includePricing) partData.qty = part.get('frontWheel').get('quantity').get('value');
      if (includePricing) partData.pcId = part.get('frontWheel').get('quantity').get('pricingContextId');
      if (part.get('staggered')) {
        partData.rearPartId = part.get('rearWheel').get('id');
        partData.rearPartNumber = part.get('rearWheel').get('partNumber');
        if (includePricing) partData.rearPrice = part.get('rearWheel').get('pricing').get('displayPrice');
        if (includePricing) partData.rearQty = part.get('rearWheel').get('quantity').get('value');
        if (includePricing) partData.rearPcId = part.get('rearWheel').get('quantity').get('pricingContextId');
      }
      break;
    case ProductTypes.ACCESSORIES:
    case ProductTypes.PACKAGES:
      partData.type = part.get('type');
      partData.partId = part.get('id');
      partData.partNumber = part.get('partNumber');
      if (includePricing) partData.price = part.get('price') && part.get('price').displayPrice ? part.get('price').displayPrice : part.get('pricing').get('displayPrice');
      if (includePricing) partData.qty = part.get('quantity').value ? part.get('quantity').value : part.get('quantity').get('value');
      if (includePricing) partData.pcId = part.get('quantity').pricingContextId ? part.get('quantity').pricingContextId : part.get('quantity').get('pricingContextId');
      break;
    default:
      partData.error = 'Part Info Failed Collection. Invalid Part Type: ' + part.get('type') + '.';
      break;
  }

  return JSON.stringify(partData);
};

export const getTrackingEventSubmodel = (trimArray) => {
  var submodel = {};
  if (trimArray.hasOwnProperty("vehicleId")) submodel.vehicleId = trimArray.vehicleId;
  if (trimArray.hasOwnProperty("trimId")) submodel.submodelId = trimArray.trimId;
  if (trimArray.hasOwnProperty("trimName")) submodel.submodelName = trimArray.trimName.en;
  if (trimArray.hasOwnProperty("tiresizeId")) submodel.tireSizeId = trimArray.tiresizeId;
  return JSON.stringify(submodel);
};

export const getTrackingEventSearch = (searchType, searchValue) => {
  var search = {};
  if (searchType) search.type = searchType;
  if (searchValue) search.term = searchValue;
  return JSON.stringify(search);
};

export const getTrackingEventOrderId = () => {
  return JSON.stringify({orderId: getOrderIdentifier()});
};

export const getTrackingEventObject = (object) => {
  return JSON.stringify(object);
};

export const decodeChassisSearchLanguage = (languageText) => {
  var language = "en";
  if (languageText.indexOf('fr') > -1) language = "fr";
  return language;
};

export const getProductTypeLowerCase = () => {
  var product = null;
  if (applicationStore.data.productType || vehicleSelectionStore.data.productType) {
    product = applicationStore.data.productType;
    if (!product) product = searchStore.data.searchProductType;
    if (!product) product = vehicleSelectionStore.data.productType;
    if (!product && window.dealerAccountInfoPayload) {
      if (window.dealerAccountInfoPayload.dealerRetailSiteStartPage !== 'HOME') {
        switch (window.dealerAccountInfoPayload.dealerRetailSiteStartPage) {
          case 'TRE':
            product = ProductTypes.TIRES.toLowerCase();
            break;
          case 'WHL':
            product = ProductTypes.ALLOY_WHEELS.toLowerCase();
            break;
          case 'ACC':
            product = ProductTypes.ACCESSORIES.toLowerCase();
            break;
          case 'PKG':
            product = ProductTypes.PACKAGES.toLowerCase();
            break;
        }
      }
    }
    if (!product) product = ProductTypes.TIRES.toLowerCase();
  }
  return product;
};

export const getProductTypeUpperCase = () => {
  var product = null;
  if (applicationStore.data.productType || vehicleSelectionStore.data.productType) {
    product = applicationStore.data.productType;
    if (!product) product = searchStore.data.searchProductType;
    if (!product) product = vehicleSelectionStore.data.productType;
    if (!product && window.dealerAccountInfoPayload) {
      if (window.dealerAccountInfoPayload.dealerRetailSiteStartPage !== 'HOME') {
        switch (window.dealerAccountInfoPayload.dealerRetailSiteStartPage) {
          case 'TRE':
            product = ProductTypes.TIRES.toUpperCase();
            break;
          case 'WHL':
            product = ProductTypes.ALLOY_WHEELS.toUpperCase();
            break;
          case 'ACC':
            product = ProductTypes.ACCESSORIES.toUpperCase();
            break;
          case 'PKG':
            product = ProductTypes.PACKAGES.toUpperCase();
            break;
        }
      }
    }
    if (!product) product = ProductTypes.TIRES.toUpperCase();
  }
  return product;
};

export const getProductTypeFromPartType = (partType) => {
  const map = {
    'TRE': ProductTypes.TIRES,
    'WHL': ProductTypes.ALLOY_WHEELS,
    'ACC': ProductTypes.ACCESSORIES,
    'PKG': ProductTypes.PACKAGES
  }
  const productType = map[partType]||ProductTypes.TIRES;
  return productType.toUpperCase();
}

export const enableAodaHighlighting = () => {
  $(window).keyup(function (e) {
    var code = (e.keyCode ? e.keyCode : e.which);
    if (code === 9) {
      var body = document.getElementsByTagName("BODY")[0];
      body.classList.remove("hide-focus-outline");
      body.classList.add("apply-aoda-focus");
    }
  });
  $(window).mousedown(function (e) {
    var body = document.getElementsByTagName("BODY")[0];
    body.classList.remove("apply-aoda-focus");
    body.classList.add("hide-focus-outline");
  });
};

export const updateBodyForExplorerPrint = (routerState) => {
  var browser = get_browser();
  if (((routerState === RouterStates.COMPARISON || routerState === RouterStates.PRINT_COMPARISON || routerState === RouterStates.PRINT_COMPARISON_FROM_TIRESIZE)
      || (routerState === RouterStates.PRINT_WISHLIST || routerState === RouterStates.PRINT_WISHLIST_TEST))
    && (browser.name === 'IE' || browser.name === 'MSIE')) {
    document.body.className = document.body.className + " explorer-print";
  }
};

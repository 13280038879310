import { productListActions } from "../../actions/ProductListActions.js";
import { CheckBoxList, CheckBoxItem } from "../ux/forms/CheckBoxList.jsx";
import { EmailField } from "../ux/forms/EmailField.jsx";
import { TelField } from "../ux/forms/TelField.jsx";
import { TextField } from "../ux/forms/TextField.jsx";
import { isEmpty } from "../../service/UtilityService";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  ADOBELAUNCHER_FORM_SUBMIT,
  enableAdobeLaunch,
  runAdobeServiceFormStart,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");

export const CONTACT_EMAIL = "email";
export const CONTACT_PHONE = "phone";

export class AppointmentForm extends I18nComponent {
  state = {
    contactType: CONTACT_EMAIL,
    name: this.getInitialFieldValue(),
    email: this.getInitialFieldValue(),
    phone: this.getInitialFieldValue(),
    message: null,
    dealerContacted: false,
    adobeLauncherHasRun: false,
  };

  getInitialFieldValue() {
    return {
      value: null,
      touched: false,
      forceTouch: false,
      valid: false,
    };
  }

  render() {
    var button = (
      <button type="submit" className="btn">
        {this.t("product.requestAppointment_alt")}
      </button>
    );
    if (this.state.dealerContacted) {
      button = (
        <button
          type="submit"
          className={setAdobeLaunchClass(
            "btn",
            ADOBELAUNCHER_CLASS.BUTTON.LINK
          )}
          data-dtm={setAdobeLaunchDTM(
            "",
            ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.BASE
          )}
          disabled
        >
          {this.t("product.requestAppointment_sent")}
        </button>
      );
    }
    return (
      <form
        onSubmit={this.onFormSubmit}
        onFocus={this.runAdobeLauncher}
        className="contact-form"
      >
        <h3>{this.t("appointmentForm.requestMoreInformation")}</h3>
        <p>{this.t("appointmentForm.pleaseLeaveContactInfo")}</p>
        <TextField
          onChange={this.onChangeName}
          label={this.t("appointmentForm.name")}
          touched={this.state.name.forceTouch}
          className={setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.INPUT)}
          datadtm={setAdobeLaunchDTM(
            "",
            ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.BASE
          )}
        />
        <div className="formInput">
          <label>{this.t("appointmentForm.pleaseContactVia")}</label>
          <CheckBoxList
            onChange={this.onChangeContactType}
            singleSelect={true}
            className={setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.INPUT)}
            datadtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.BASE
            )}
          >
            <CheckBoxItem
              value={CONTACT_EMAIL}
              selected={this.state.contactType === CONTACT_EMAIL}
            >
              {this.t("appointmentForm.email")}
            </CheckBoxItem>
            <CheckBoxItem
              value={CONTACT_PHONE}
              selected={this.state.contactType === CONTACT_PHONE}
            >
              {this.t("appointmentForm.phone")}
            </CheckBoxItem>
          </CheckBoxList>
        </div>
        <EmailField
          id="userEmailField"
          onChange={this.onChangeEmail}
          label={this.t("appointmentForm.email")}
          touched={this.state.email.forceTouch}
          className={setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.INPUT)}
          datadtm={setAdobeLaunchDTM(
            "",
            ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.BASE
          )}
        />
        <TelField
          id="telField"
          onChange={this.onChangePhone}
          label={this.t("appointmentForm.phone")}
          touched={this.state.phone.forceTouch}
          required={this.state.contactType == CONTACT_PHONE}
          className={setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.INPUT)}
          datadtm={setAdobeLaunchDTM(
            "",
            ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.BASE
          )}
        />
        <div className="formInput">
          <label htmlFor="optionalText">
            {this.t("appointmentForm.optionalMessage")}
          </label>
          <textarea
            onChange={this.onChangeMessage}
            rows="4"
            id="optionalText"
            className={setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.INPUT)}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.BASE
            )}
          ></textarea>
        </div>
        {button}
      </form>
    );
  }

  runAdobeLauncher = () => {
    if (enableAdobeLaunch() && !this.state.adobeLauncherHasRun) {
      this.setState({
        adobeLauncherHasRun: true,
      });
      runAdobeServiceFormStart();
    }
  };

  onChangeName = (value, valid) => {
    this.setState({
      name: {
        value: value,
        valid: valid,
        touched: true,
      },
    });
  };

  onChangeEmail = (value, valid) => {
    this.setState({
      email: {
        value: value,
        valid: valid,
        touched: true,
      },
    });
  };

  onChangePhone = (value, valid) => {
    this.setState({
      phone: {
        value: value,
        valid: valid,
        touched: true,
      },
    });
  };

  onChangeMessage = (value) => {
    this.setState({
      message: value,
    });
  };

  onChangeContactType = (value) => {
    this.setState({
      contactType: value,
    });
  };

  onFormSubmit = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    var name = this.state.name,
      email = this.state.email,
      phone = this.state.phone;

    name.forceTouch = true;
    email.forceTouch = true;
    phone.forceTouch = true;

    this.setState({
      name: name,
      email: email,
      phone: phone,
    });

    if (this.canBeSubmitted()) {
      productListActions.sendAppointmentForm(
        this.state.name.value,
        "",
        this.state.email.value,
        this.state.phone.value,
        this.state.contactType,
        document.getElementById("optionalText").value,
        this.props.itemId,
        this.props.itemType,
        null,
        this.getAdobeLauncherFormSubmitStatus()
      );
      this.setState({ dealerContacted: true });
    }
  };

  getAdobeLauncherFormSubmitStatus = () => {
    if (
      !isEmpty(this.state.name.value) &&
      !isEmpty(this.state.email.value) &&
      !isEmpty(this.state.phone.value)
    ) {
      return ADOBELAUNCHER_FORM_SUBMIT.COMPLETE;
    } else if (
      !isEmpty(this.state.name.value) ||
      !isEmpty(this.state.email.value) ||
      !isEmpty(this.state.phone.value)
    ) {
      return ADOBELAUNCHER_FORM_SUBMIT.PARTIAL;
    } else {
      return ADOBELAUNCHER_FORM_SUBMIT.NONCOMPLETE;
    }
  };

  canBeSubmitted = () => {
    return (
      (this.state.email.touched &&
        this.state.email.valid &&
        !isEmpty(document.getElementById("userEmailField").value)) ||
      (this.state.phone.touched &&
        this.state.phone.valid &&
        this.state.contactType == CONTACT_PHONE) ||
      this.state.contactType == CONTACT_EMAIL
    );
  };
}

import { globalState } from '../GlobalState.js';
import { applicationActions } from '../actions/ApplicationActions.js';
import { productListActions } from '../actions/ProductListActions.js';
import { breadcrumbActions } from '../actions/BreadcrumbActions.js';
import { Router } from '../stores/Router.js';
import { dealerSelectionActions } from '../actions/DealerSelectionActions.js';
import { DispatcherActions } from '../constants/DispatcherActions.js';
import { ProductTypes } from '../constants/ProductTypes.js';
import { RouterStates } from '../constants/RouterStates.js';
import { VehicleSelectionSkippingService } from '../service/VehicleSelectionSkippingService.js';
import { AbstractStore } from './AbstractStore.js';
import { shoppingCartViewStore } from './ShoppingCartViewStore.js';

import { i18nMixin } from '../i18n/i18nMixin.js';

import { get } from '../api/ApiService';
import { getToken, initializeApp, logOut } from '../api/AuthService.js';

import { shoppingCartStore } from '../stores/ShoppingCartStore.js';
import { shoppingCartActions } from '../actions/ShoppingCartActions.js';
import { breadcrumbsStore } from '../stores/BreadcrumbsStore.js';
import { productDetailStore } from '../stores/ProductDetailStore.js';
import { vehicleSelectionStore } from '../stores/VehicleSelectionStore.js';
import { productListStore } from '../stores/ProductListStore.js';
import { languageStore } from '../stores/LanguageStore.js';

import { SearchTypes } from '../constants/SearchTypes.js';

import { getDestination } from '../api/RequestBuilderHelper.js';

import { getNationalRouterOrder, getDealerRouterOrder } from '../service/RouterService.js';
import { sizeOf, isEmpty, isDealerSite, isNationalSite, getAppLanguage, getSeasonObjectList, skipBreadcrumbsLoad } from '../service/UtilityService.js';
import { loadDealerConfig, loadDealerInfoConfig, loadNationalDealerConfig, loadNationalConfig, loadGroupConfig } from '../service/SiteConfigService.js';
import { enableAdobeLaunch, setAdobeLauncherDigitalData } from '../service/AdobeLaunchService.js';
import { checkStorageFormData } from '../service/StorageService.js';
import { loadWishlistWithContext } from '../service/WishlistService.js';
import { getRouterState } from "../service/UtilityService";
import { isDealerAuthentication, isUserLoggedIn } from "../api/AuthService";
import { checkLogin } from "../service/LoginService";
import { decodeUrlHash } from "../service/HashService";
import { setDealerSelectionSearchTerm } from '../redux/features/dealerSections.js';
import store from './configureStore.js';

var React = require('react');
var _ = require('lodash');
var Immutable = require('immutable');
var GoogleMapsAPI = window.google.maps;

function predicateFn(predicate) {
	return function (value) {
		return value === predicate;
	};
}

const filter_4M = '4 months';
const filter_3M = '3 months';
const filter_2M = '2 months';
const filter_1M = '1 month';
const filter_7D = '7 days';
const filter_1D = '1 day';
const filter_T = 'today';

const sort_DATE = 'date';
const sort_TOTAL = 'total';
const sort_CREATEDBY = 'createdBy';
const sort_CUSTOMERNAME = 'customerName';

const sort_DESC = -1;
const sort_ASC = 1;

const QUOTE_REPORT = 'quote-report';
const LEAD_REPORT = 'lead-report';
const ORDER_REPORT = 'order-report';

const SCREENMODES = {
	REGULAR: 'regular',
	IFRAME: 'iframe',
	GUIDE: 'guide',
};

var ApplicationStore = AbstractStore.extend({

	mixins: [i18nMixin],

	constructor: function () {
		this.reset();
		ApplicationStore.__super__.constructor.apply(this, arguments);
	},

	reset: function () {
		var loggedIn = initializeApp();
		this.data = {};
		this.data.loggedIn = loggedIn;
		this.data.userName = "";
		this.data.apiCallInProgress = false;
		this.data.apiCallInProgressCount = 0;
		this.data.productTypes = [];
		this.data.productListProductTypes = [];
		this.data.productType = ProductTypes.TIRES;
		this.data.searchProductType = ProductTypes.TIRES;
		this.data.nationalAccountId = null;
		this.data.minimumYears = {};
		this.data.loginFailure = false;
		this.data.search = null;
		this.data.season = null;
		this.data.originalSeason = null;
		this.data.cart = null;
		this.data.searchIsActiveFlag = false;
		this.data.loginTextDialogShown = false;
		this.data.loginCreateCustomerAccount = false;
		this.data.disableHomepage = false;

    this.data.year = null;
    this.data.make = null;
    this.data.category = null;
    this.data.model = null;
    this.data.trim = null;
    this.data.lang = null;
    this.data.postal = null;

    //this.data.screenMode = window.location !== window.parent.location ? SCREENMODES.IFRAME : SCREENMODES.REGULAR;
    this.data.screenMode = SCREENMODES.REGULAR;

		this.data.dealerEnableShowPricingLastUpdate = false;

    this.data.dealerWinterTireSpeedRatingOffset = 0;
		this.data.dealerAllSeasonTireSpeedRatingOffset = 0;
		this.data.dealerSummerTireSpeedRatingOffset = 0;

		this.data.quoteList = Immutable.List.of();
		this.data.leadList = Immutable.List.of();
		this.data.quoteReportType = QUOTE_REPORT;
		this.data.QuoteReportNumQuotesToShow = 50;
		this.data.showPricingAnalysis = false;
		this.data.showPricingIncentive = false;
		this.data.showQuoteReport = false;
		this.data.showLeadReport = false;
		this.data.showOrderReport = false;
		this.data.quoteReportSelectedFilterDate = filter_4M;
		this.data.quoteReportSelectedFilterSearchInput = null;
		this.data.quoteReportSelectedSortType = sort_DATE;
		this.data.quoteReportSortByFlipFlop = sort_DESC;

		this.data.nationalRetailSiteDestinationPage = false;

		this.data.useProvincialDealers = false;
		this.data.provincialDealersList = Immutable.List.of();
		this.data.provincialDealerDealersList = Immutable.List.of();
		this.data.provincialDealerDealersListSelectedDealer = null;
		this.data.dealerModalSearchTerm = null;

		this.data.guestAccountCreationModal = false;
		this.data.guestAccountCreationReturnMessage = false;
		this.data.guestAccountCreationSuccessful = false;

		this.data.allowSearchByTireSize = false;
		this.data.allowSearchByVehicleModel = false;
		this.data.allowSearchByPartNumber = false;
		this.data.allowSearchByChassis = false;

		this.data.forgotPasswordModal = false;
		this.data.forgotPasswordReturnMessage = false;

		this.data.showNationalPartStockModal = false;
		this.data.nationalPartStockInfo = null;
		this.data.showNationalPartPricingModal = false;
		this.data.nationalPartPricingInfo = null;

		this.data.returnToDealerSelectionWarning = false;
		this.data.useOriginalEquipmentOnlyFilter = false;
		this.data.originalEquipmentOnlyDefaultOn = false;

		this.data.carouselItems = Immutable.List.of();
		this.data.featuredItems = Immutable.List.of();

		this.data.dealerSellingPriceLabel = [];

		this.data.externalLinkModalFlag = false;
		this.data.externalLink = "";

    this.data.dealerHidePublicPricing = (function() { return true });

		this.data.allowAdvancedSearch = false;

		this.data.sentFromTireCreateWheelPackage = false;

		this.data.showStartNewQuoteModal = false;
		this.data.showAuthenticationStateModal = false;

		this.data.showOnlyLoadingPage = false;
		this.data.compareDataLoad = [];
		this.data.compareProductdata = null;

		this.data.launcherVehicleYears = [];
		this.data.launcherVehicleMakes = [];
		this.data.launcherVehicleModels = [];
		this.data.launcherVehicleProductTypes = [];

		this.data.enableHomepageLauncher = false;
		this.data.enableHomepagePanelLauncher = false;

		this.data.selectedYear = null;
		this.data.selectedMake = null;
		this.data.selectedCategory = "null";
		this.data.selectedModel = null;
		this.data.selectedProductType = null;

		this.data.enableNTDOrderModal = false;
		this.data.enableNTDOrderModalXtimePrompt = false;

		this.data.loginHasExpired = false;
		this.data.userLoggedOut = false;

		this.data.nationalAccountTireBrands = [];
		this.data.dealerTireBrands = [];
		this.data.dealerTireSeasons = [];

		this.data.nationalLandingPageTitle = null;

		this.data.dealerUrlCode = null;
		this.data.nationalUrlCode = null;
		this.data.groupUrlCode = null;

    this.data.dealerEnableCustomerLogin = false;

    this.data.dealerEnableBookingSystem = false;

    this.data.enableFinancing = false;
    this.data.financingUrl = '';

		this.data.homepageLauncherFloatOverCarousel = null;
		this.data.homepageLauncherLockToPage = false;

		this.data.showTireSizeHelper = false;
		this.data.tireSizeHelperFromTrim = false;

		this.data.webserviceFailed = false;
		this.data.webserviceFailedHttpStatus = 0;

		this.data.orderStatus = null;

		this.data.userGoBack = false;
		this.data.userGoBackWithCrumb = false;

		this.data.hideFindUserLocationModal = false;

    this.data.useAlternateProductTypeSelection = false;
    this.data.enablePaddedProductTileSelection = false;
    this.data.enableAlternateProductTileSelection = false;

    this.data.dealerEnableEcommerceRetailSite = false;
    this.data.dealerEnablePricingAnalysis = false;

    this.data.dealerAllowNtdStock = false;
    this.data.dealerAllowNtdStockOrder = false;
    this.data.dealerAllowNtdConsumerPurchase = false;
    this.data.dealerAtdConnectCustomerNumber = '';
    this.data.dealerAtdConnectLocationCode = '';

    this.data.dealerAllowNTDStockBar = false;

    this.data.supportDealerOrder = false;
    this.data.supportUserDealerOrder = false;
    this.data.supportConsumerOrder = false;
    this.data.supportUserConsumerOrder = false;

    this.data.enableRunFlatFilter = false;
    this.data.enablePricingFilter = false;
    this.data.oeTireFilter = false;

    this.data.showCampaignDealerInfo = false;
    this.data.urlContainsCampaign = false;
    this.data.campaignIsInactive = false;
    this.data.campaignDealerSeletionVehicleInfo = {};

    this.data.selectedDealerForToken = null;
    this.data.selectedDealerIdForToken = null;

    this.data.forceUserLogin = false;

    this.data.appRunningOutdatedVersion = false;

    this.data.dealerEnableXtimeAppointmentIntegration = false;
    this.data.dealerXtimeAppointmentIntegrationError = false;
    this.data.dealerXtimeAppointmentIntegrationLink = '';

    this.data.getCaptchaToken = false;
    this.data.getCaptchaCount = 0;
    this.data.getCaptchaCallback = (function() {});
    this.data.dealerEnableCaptcha = false;
    this.data.dealerForceCaptchaPassed = false;

    this.data.legalFooter = '';
    this.data.useAdvancedFooter = false;
    this.data.fullAdvancedFooter = false;

    this.data.quoteImmediately = false;

    this.data.selectedTireId = null;
    this.data.selectedTirePartNumber = null;
    this.data.selectedBrandId = null;
    this.data.selectedBrandName = null;
    this.data.selectedSeason = null;
    this.data.selectedSeasonName = null;
    this.data.selectedModelId = null;
    this.data.selectedModelData = {
    	brandId: null,
      modelName: '',
			modelImage: '',
			description: { en : '', fr: '' },
			season: null,
			allWeather: null
		};

		this.data.enableAccessoryViewSwitch = false;

		this.data.dealerEnableCustomerChat = false;

		this.data.nationalAccountName = null;
		this.data.dealerNationalAccountName = null;

		this.data.nationalEnableSkipMap = false;
    this.data.widgetSearchActive = false;

    this.data.supportToggle = false;

    this.data.forceLoadingScreen = false;

		this.errorPage = null;

    this.data.longInitialDataLoad = false;
	},

	getActionHandlers: function () {
		var handlers = {};
		handlers[DispatcherActions.INITIALIZE_APP_STATE] = this.onInitializeAppState;
		handlers[DispatcherActions.INITIALIZE_VEHICLE_PARAMETERS] = this.loadVehicleParameters;
		handlers[DispatcherActions.API_CALL_STARTED] = this.onApiCallStarted;
		handlers[DispatcherActions.API_CALL_FINISHED] = this.onApiCallFinished;
		handlers[DispatcherActions.API_WEBSERVICE_HAS_FAILED] = this.onApiCallFailed;
		handlers[DispatcherActions.DEALER_ACCOUNT_INFO_LOADED] = this.onDealerAccountInfoLoaded;
		handlers[DispatcherActions.NATIONAL_ACCOUNT_INFO_LOADED] = this.onNationalAccountInfoLoaded;
		handlers[DispatcherActions.GROUP_ACCOUNT_INFO_LOADED] = this.onGroupAccountInfoLoaded;
		handlers[DispatcherActions.LIST_OF_PROVINCIAL_DEALERS_LOADED] = this.onProvincialDealersLoaded;
		handlers[DispatcherActions.PROVINCIAL_DEALERS_LIST_OF_DEALERS_LOADED] = this.onProvincialDealerDealersListLoaded;
		handlers[DispatcherActions.PROVINCIAL_DEALERS_LIST_OF_DEALERS_SELECTED] = this.onProvincialDealerDealersListSelected;
		handlers[DispatcherActions.WISHLIST_DEALER_SELECT_MODAL_SEARCH] = this.onWishlistDealerSelectSearch;
		// handlers[DispatcherActions.FORCE_LOGIN_OPEN] = this.openForceDialog;
		handlers[DispatcherActions.LOGIN_TEXT_DIALOG_OPEN] = this.onOpenLoginTextDialog;
		handlers[DispatcherActions.LOGIN_TEXT_DIALOG_CLOSE] = this.onCloseLoginTextDialog;
		handlers[DispatcherActions.LOGIN_DIALOG_CLOSE] = this.onCloseLoginDialog;
		handlers[DispatcherActions.LOGIN_DIALOG_OPEN] = this.onOpenLoginDialog;
		handlers[DispatcherActions.LOGIN_SUCCESSFUL] = this.onLoginSuccessful;
		handlers[DispatcherActions.LOGIN_FAILURE] = this.onLoginFailure;
		handlers[DispatcherActions.LOGOUT_SUCCESSFUL] = this.onLogOutSuccessful;
		handlers[DispatcherActions.DEALER_SELECTED] = this.onDealerSelected;
		handlers[DispatcherActions.DEALER_INFO_LOAD] = this.onDealerInfoLoaded;
		handlers[DispatcherActions.CHANGE_LANG] = this.onChangeLang;
		handlers[DispatcherActions.PRODUCT_TYPE_SELECTED] = this.onProductTypeSelected;
		handlers[DispatcherActions.SET_PRODUCT_TYPE_VALUE] = this.onSetProductTypeValue;
		handlers[DispatcherActions.SET_SEARCH_PRODUCT_TYPE_VALUE] = this.onSetSearchProductTypeValue;
		handlers[DispatcherActions.VEHICLE_SELECTION_YEARS_SELECTED] = this.onYearSelected;
		handlers[DispatcherActions.VEHICLE_SELECTION_YEARS_SELECTED_FROM_MODAL] = this.onYearSelectedFromModal;
		handlers[DispatcherActions.VEHICLE_SELECTION_CATEGORY_SELECTED_FROM_MODAL] = this.onCategorySelectedFromModal;
		handlers[DispatcherActions.VEHICLE_SELECTION_MODEL_SELECTED_FROM_MODAL] = this.onModelSelectedFromModal;
		handlers[DispatcherActions.VEHICLE_SELECTION_MAKE_SELECTED] = this.onMakeSelected;
		handlers[DispatcherActions.VEHICLE_SELECTION_CATEGORY_SELECTED] = this.onCategorySelected;
		handlers[DispatcherActions.VEHICLE_SELECTION_MODEL_SELECTED] = this.onModelSelected;
		handlers[DispatcherActions.VEHICLE_SELECTION_TRIM_SELECTED] = this.onTrimSelected;
		handlers[DispatcherActions.VEHICLE_MAKES_LOADED] = this.onVehicleMakesLoaded;
		handlers[DispatcherActions.VEHICLE_YEARS_LOADED] = this.onVehicleYearsLoaded;
		handlers[DispatcherActions.VEHICLE_CATEGORIES_LOADED] = this.onVehicleCategoriesLoaded;
		handlers[DispatcherActions.VEHICLE_MODELS_LOADED] = this.onVehicleModelsLoaded;
		handlers[DispatcherActions.VEHICLE_TRIMS_LOADED] = this.onVehicleTrimsLoaded;
		handlers[DispatcherActions.VEHICLE_INFO_LOADED] = this.onVehicleInfoLoaded;
		handlers[DispatcherActions.CHANGE_ROUTER_STATE_DIRECTLY] = this.onChangeStateDirectly;
		handlers[DispatcherActions.CHANGE_PRODUCT_TYPE] = this.onChangeProductType;
		handlers[DispatcherActions.WISHLIST_REVIEW_LOADED] = this.onWishlistReviewLoaded;
		handlers[DispatcherActions.CONSUMER_ORDER_LOADED] = this.onWishlistReviewLoaded;
		handlers[DispatcherActions.CHANGE_STATE_FROM_URL] = this.onChangeStateFromUrl;
		handlers[DispatcherActions.GO_BACK] = this.onGoBack;
		handlers[DispatcherActions.DISPLAY_ERROR_PAGE] = this.onDisplayErrorPage;
		handlers[DispatcherActions.PRODUCT_DETAIL_OPENED] = this.onProductDetailOpened;
		handlers[DispatcherActions.TOGGLE_SEARCH_FLAG] = this.onToggleSearchBar;
		handlers[DispatcherActions.ACTIVATE_SEARCH_FLAG] = this.onActivateSearchBar;
		handlers[DispatcherActions.DEACTIVATE_SEARCH_FLAG] = this.onDeactivateSearchBar;
		handlers[DispatcherActions.PRODUCT_LIST_TIRES_LOAD_FROM_MODEL] = this.onListOfTiresLoadedFromModel;
		handlers[DispatcherActions.PRODUCT_LIST_TIRES_LOAD_FROM_CHASSIS] = this.onListOfTiresLoadedFromChassis;
		handlers[DispatcherActions.PRODUCT_LIST_PACKAGES_LOAD_FROM_MODEL] = this.onListOfPackagesLoadedFromModel;
		handlers[DispatcherActions.QUOTE_REPORT_LOADED] = this.quoteReportLoaded;
		handlers[DispatcherActions.LEAD_REPORT_HISTORY] = this.leadReportLoaded;
		handlers[DispatcherActions.QUOTE_REPORT_UPDATE_SEARCH_INPUT] = this.quoteReportUpdateSearchInput;
		handlers[DispatcherActions.QUOTE_REPORT_UPDATE_FILTER_COUNT] = this.quoteReportUpdateFilterCount;
		handlers[DispatcherActions.QUOTE_REPORT_UPDATE_FILTER_DATE] = this.quoteReportUpdateFilterDate;
		handlers[DispatcherActions.QUOTE_REPORT_UPDATE_SORT] = this.quoteReportUpdateSort;
		handlers[DispatcherActions.PRICING_ANALYSIS_TOGGLE] = this.pricingAnaylsisToggle;
		handlers[DispatcherActions.PRICING_INCENTIVE_TOGGLE] = this.pricingIncentiveToggle;
		handlers[DispatcherActions.QUOTE_REPORT_REQUEST_OPEN] = this.quoteReportRequestOpen;
		handlers[DispatcherActions.QUOTE_REPORT_REQUEST_CLOSE] = this.quoteReportRequestClose;
    handlers[DispatcherActions.ORDER_REPORT_REQUEST_OPEN] = this.orderReportRequestOpen;
    handlers[DispatcherActions.ORDER_REPORT_REQUEST_CLOSE] = this.orderReportRequestClose;
		handlers[DispatcherActions.OPEN_WISHLIST_SCHEDULE_MODAL] = this.resetDealerSelectSearchTerm;
		handlers[DispatcherActions.OPEN_WISHLIST_APPOINTMENT_MODAL] = this.resetDealerSelectSearchTerm;
		handlers[DispatcherActions.OPEN_GUEST_ACCOUNT_CREATION_MODAL] = this.openGuestAccountCreationModal;
		handlers[DispatcherActions.CLOSE_GUEST_ACCOUNT_CREATION_MODAL] = this.closeGuestAccountCreationModal;
		handlers[DispatcherActions.SUBMIT_GUEST_ACCOUNT_FOR_CREATION] = this.submitGuestAccountCreation;
		handlers[DispatcherActions.OPEN_FORGOT_PASSWORD_MODAL] = this.openForgotPasswordModal;
		handlers[DispatcherActions.CLOSE_FORGOT_PASSWORD_MODAL] = this.closeForgotPasswordModal;
		handlers[DispatcherActions.SUBMIT_FORGOT_PASSWORD] = this.submitForgotPassword;
		handlers[DispatcherActions.LOAD_NATIONAL_PART_STOCK_INFO] = this.loadNationalPartStock;
		handlers[DispatcherActions.CLOSE_NATIONAL_PART_STOCK_MODAL] = this.closeNationalPartStockModal;
		handlers[DispatcherActions.LOAD_NATIONAL_PART_PRICING_INFO] = this.loadNationalPartPricing;
		handlers[DispatcherActions.CLOSE_NATIONAL_PART_PRICING_MODAL] = this.closeNationalPartPricingModal;
		handlers[DispatcherActions.LOAD_DEALER_APPOINTMENT_INFO] = this.loadDealerAppointmentInfo;
		handlers[DispatcherActions.OPEN_RETURN_TO_DEALER_SELECTION_WARNING] = this.openReturnToDealerSelectionWarning;
		handlers[DispatcherActions.CLOSE_RETURN_TO_DEALER_SELECTION_WARNING] = this.closeReturnToDealerSelectionWarning;
		handlers[DispatcherActions.SHOW_EXTERNAL_LINK_WARNING] = this.showExternalLinkWarning;
		handlers[DispatcherActions.CLOSE_EXTERNAL_LINK_WARNING] = this.closeExternalLinkWarning;
		handlers[DispatcherActions.SET_OE_FILTER_VALUE] = this.setOeFilterValue;
		handlers[DispatcherActions.OPEN_WISHLIST_REVIEW] = this.onOpenWishlist;
		handlers[DispatcherActions.ATTEMPT_TIRE_WHEEL_PACKAGE_CREATION] = this.attemptTireWheelPackageCreation;
		handlers[DispatcherActions.ATTEMPTED_TIRE_WHEEL_PACKAGE_CREATION] = this.attemptedTireWheelPackageCreation;
		handlers[DispatcherActions.START_NEW_QUOTE_OPEN_MODAL] = this.onOpenStartNewQuote;
		handlers[DispatcherActions.START_NEW_QUOTE_CLOSE_MODAL] = this.onCloseStartNewQuote;
		// handlers[DispatcherActions.AUTHENTICATION_STATE_OPEN_MODAL] = this.onOpenShowAuthenticationModal;
		handlers[DispatcherActions.AUTHENTICATION_STATE_CLOSE_MODAL] = this.onCloseShowAuthenticationModal;
		handlers[DispatcherActions.DECODE_URL_HASH] = this.onDecodeUrlHash;
		handlers[DispatcherActions.SET_WIDGET_SEARCH] = this.setWidgetSearch;
		handlers[DispatcherActions.LAUNCHER_VEHICLE_YEARS_LOADED] = this.launcherLoadYears;
		handlers[DispatcherActions.LAUNCHER_VEHICLE_MAKES_LOADED] = this.launcherLoadMakes;
		handlers[DispatcherActions.LAUNCHER_VEHICLE_MODELS_LOADED] = this.launcherLoadModels;
		handlers[DispatcherActions.LAUNCHER_VEHICLE_PRODUCT_TYPES_LOADED] = this.launcherLoadProductTypes;
		handlers[DispatcherActions.LAUNCHER_UPDATE_VALUES] = this.launcherUpdateValues;
		handlers[DispatcherActions.LOGIN_HAS_EXPIRED] = this.loginHasExpired;
		handlers[DispatcherActions.LOGIN_HAS_EXPIRED_RESET] = this.resetLoginHasExpired;
		handlers[DispatcherActions.NTD_OPEN_MODAL] = this.onOpenNTDModal;
		handlers[DispatcherActions.NTD_CLOSE_MODAL] = this.onCloseNTDModal;
		handlers[DispatcherActions.TIRE_SIZE_HELPER_OPEN] = this.onOpenTireSizeHelper;
		handlers[DispatcherActions.TIRE_SIZE_HELPER_CLOSE] = this.onCloseTireSizeHelper;
		handlers[DispatcherActions.ORDER_STATUS_LOADED] = this.onOrderStatusLoaded;
		handlers[DispatcherActions.ORDER_STATUS_RESET] = this.onResetOrderStatus;
		handlers[DispatcherActions.DEALER_SELECTED_FOR_TOKEN] = this.onSetSelectedDealerForToken;
		handlers[DispatcherActions.CAMPAIGN_IS_INACTIVE] = this.onCampaignIsInactive;
		handlers[DispatcherActions.CAMPAIGN_IS_INACTIVE_RESET] = this.onResetCampaignIsInactive;
		handlers[DispatcherActions.CAMPAIGN_LOAD_FOR_DEALER] = this.onLoadCampaignForDealer;
		handlers[DispatcherActions.CAMPAIGN_DEALER_SELECTION_VEHICLE_LOADED] = this.onLoadCampaignDealerSelectionVehicle;
		handlers[DispatcherActions.RECAPTCHA_OPEN_MODAL] = this.onGetCaptchaToken;
		handlers[DispatcherActions.RECAPTCHA_CLOSE_MODAL] = this.onCloseGetCaptchaToken;
		handlers[DispatcherActions.APPLICATION_VERSION_IS_OUTDATED] = this.onAppVersionIsOutdated;
		handlers[DispatcherActions.SET_SELECTED_SEASON] = this.onSeasonSelected;
		handlers[DispatcherActions.SET_SELECTED_BRAND_ID] = this.onBrandIdSelected;
		handlers[DispatcherActions.SET_SELECTED_TIRE_PART] = this.onSetSelectedTire;
		handlers[DispatcherActions.XTIME_INTEGRATION_URL_GATHERED] = this.onXtimeIntegrationUrlGathered;
		handlers[DispatcherActions.XTIME_INTEGRATION_RESET_LINK_ERROR] = this.onResetXtimeIntegrationError;
		handlers[DispatcherActions.SET_SEARCH] = this.onSetSearch;
		handlers[DispatcherActions.TOGGLE_SUPPORT] = this.onToggleSupport;
		return handlers;
	},

	onDisplayErrorPage: function (errorPage) {
		this.errorPage = errorPage;
		this.emitChange();
	},

	onInitializeAppState: function (appState) {
    var routerState = Router.decodeRouterState(appState);

    this.loadAppState('lang', appState, routerState.params);
    this.loadAppState('dealerId', appState, routerState.params);
    this.loadAppState('productType', appState, routerState.params);
    this.loadAppState('search', appState, routerState.params);
    this.loadAppState('cart', appState, routerState.params);
    this.loadAppState('vehicle', appState, routerState.params);

    this.loadAppState('showLoadingPage', appState, routerState.params);
    this.loadAppState('compareDataLoad', appState, routerState.params);
    this.loadAppState('productdata', appState, routerState.params);

    this.loadAppState('year', appState, routerState.params);
    this.loadAppState('make', appState, routerState.params);
    this.loadAppState('category', appState, routerState.params);
    this.loadAppState('model', appState, routerState.params);
    this.loadAppState('trim', appState, routerState.params);

    this.loadAppState('selectedSeason', appState, routerState.params);
    this.loadAppState('selectedBrandId', appState, routerState.params);

    globalState.select('applicationState').set('routerState', routerState.route);

    /*
    if (isUserLoggedIn(true)) {
    	this.data.showAuthenticationStateModal = true;
		}
		*/

    if (isDealerSite()) checkLogin();

		this.emitChange();
	},

	loadAppState: function(key, appState, params) {
		var temp;
		if (params[key]) {
      temp = params;
			this.data[key] = decodeURIComponent(temp[key]);
		} else if (appState[key]) {
      temp = appState;
      this.data[key] = decodeURIComponent(temp[key]);
		} else {
			temp = params;
		}

		if (temp.hasOwnProperty(key)) {
      switch (key) {
        case 'year':
          if (parseFloat(temp.year) > 1950) {
            this.data.longInitialDataLoad = true;
            setTimeout(() => {
				// console.trace()
				
				applicationActions.loadVehicleSearchParameters(getDestination(temp.productType.toUpperCase()), temp.year, temp.make, temp.category, temp.model, temp.trim); }, 0);
          } else if (temp.year) {
            if (temp.year) this.data.year = temp.year;
            if (temp.make) this.data.make = temp.make;
            if (temp.category) this.data.category = temp.category;
            if (temp.model) this.data.model = temp.model;
            if (temp.trim) this.data.trim = temp.trim;
          }
          break;
        case 'selectedSeason':
          if (temp.selectedSeason || temp.selectedSeason === 0) {
            if (isNaN(temp.selectedSeason)) {
              var seasonList = getSeasonObjectList(), seasonName = decodeURIComponent(temp.selectedSeason);
              for (var i in seasonList) {
                if (seasonList[i] && seasonList[i]['en'].toLowerCase() === seasonName.toLowerCase()) {
                  this.data.selectedSeason = i;
                  this.data.selectedSeasonName = seasonList[i]['en'];
                }
              }
            } else {
              this.data.selectedSeason = temp.selectedSeason;
              var seasonList = getSeasonObjectList(), seasonObject = seasonList[this.data.selectedSeason];
              this.data.selectedSeasonName = seasonObject['en'];
            }
          }
          break;
        case 'selectedBrandId':
          if (temp.selectedBrandId) {
            if (isNaN(temp.selectedBrandId)) {
              if (isDealerSite()) {
                var brandList = window.dealerAccountInfoPayload.dealerProductFilters.tireManufacturers, brandName = decodeURIComponent(temp.selectedBrandId);
                for (var i in brandList) {
                  if (brandList[i].part_manufacturer_name.toLowerCase() === brandName.toLowerCase()) {
                    this.data.selectedBrandId = brandList[i].part_manufacturer_id;
                    this.data.selectedBrandName = brandList[i].part_manufacturer_name;
                  }
                }
              } else if (isNationalSite()) {
                var brandList = window.nationalAccountInfoPayload.nationalTireManufacturerInfoList, brandName = decodeURIComponent(temp.selectedBrandId);
                for (var i in brandList) {
                  if (brandList[i].tireManufacturerName.toLowerCase() === brandName.toLowerCase()) {
                    this.data.selectedBrandId = brandList[i].tireManufacturerId;
                    this.data.selectedBrandName = brandList[i].tireManufacturerName;
                  }
                }
              }
            } else {
              if (isDealerSite()) {
                this.data.selectedBrandId = temp.selectedBrandId;
                var brandObject = window.dealerAccountInfoPayload.dealerProductFilters.tireManufacturers[this.data.selectedBrandId];
                this.data.selectedBrandName = brandObject.part_manufacturer_name;
              } else if (isNationalSite()) {
                this.data.selectedBrandId = temp.selectedBrandId;
                var brandObject = window.nationalAccountInfoPayload.nationalTireManufacturerInfoList[this.data.selectedBrandId];
                this.data.selectedBrandName = brandObject.tireManufacturerName;
              }
            }
          }
          break;
				case 'modelId':
          if (temp.modelId) {
            if (isNaN(temp.modelId)) {
              this.data.selectedModelData.modelName = decodeURIComponent(temp.modelId);
              //setTimeout(() => { applicationActions.loadTireModelInfo('', '', decodeURIComponent(temp.modelId)); }, 10);
              applicationActions.loadTireModelInfo('', '', decodeURIComponent(temp.modelId));
            } else {
              this.data.selectedModelId = temp.modelId;
              //setTimeout(() => { applicationActions.loadTireModelInfo('', temp.modelId, ''); }, 10);
              applicationActions.loadTireModelInfo('', temp.modelId, '');
            }
          }
          break;
				case 'tireId':
          if (temp.tireId) {
            this.data.selectedTireId = temp.tireId;
            this.data.selectedTirePartNumber = temp.tireId;
            //setTimeout(() => { applicationActions.loadTireById(temp.tireId); }, 10);
            applicationActions.loadTireById(temp.tireId);
          }
          break;
      }
    }
	},

	loadVehicleParameters: function(response) {
		if (!isEmpty(response)) {
      if (response.vehicleYearId > 0) {
        this.data.year = response.vehicleYearId;
        breadcrumbsStore.data.year = response.vehicleYearId;
        vehicleSelectionStore.data.year = response.vehicleYearId;
      }
      if (response.vehicleManufacturerId > 0) {
        this.data.make = response.vehicleManufacturerId;
        breadcrumbsStore.data.make = response.vehicleManufacturerId;
      }
      if (response.vehicleCategoryId > 0) {
        this.data.category = response.vehicleCategoryId;
        vehicleSelectionStore.data.category = response.vehicleCategoryId;
      }
      if (response.vehicleModelId > 0) {
        this.data.model = response.vehicleModelId;
        breadcrumbsStore.data.model = response.vehicleModelId;
        vehicleSelectionStore.data.selectedModelId = response.vehicleModelId;
      }
      if (response.vehicleSubmodelId > 0) {
        this.data.trim = response.vehicleSubmodelId;
        breadcrumbsStore.data.trim = response.vehicleSubmodelId;
      }
    }
    this.data.longInitialDataLoad = false;
	},

  onSetSearch: function(payload) {
    this.data.productType = ProductTypes.TIRES;
    this.data.search = payload.search;

    if (getRouterState() !== RouterStates.SEARCH_RESULT) {
      globalState.select('applicationState').set('routerState', RouterStates.SEARCH_RESULT);
    }
    this.emitChange();
	},

	onSetSelectedTire: function(tire) {
    this.data.selectedTireId = tire.partId;
    this.data.selectedTirePartNumber = tire.partNumber;
    this.emitChange();
	},

  onAppVersionIsOutdated: function() {
    this.data.appRunningOutdatedVersion = true;
    this.emitChange();
	},

	onGetCaptchaToken: function(callback) {
		var count = this.data.getCaptchaCount;
    this.data.getCaptchaToken = true;
    this.data.getCaptchaCount = ++count;
    this.data.getCaptchaCallback = callback;
    this.emitChange();
	},

  onCloseGetCaptchaToken: function() {
    this.data.getCaptchaToken = false;
    this.data.getCaptchaCallback = (function() {});
    this.emitChange();
  },

	onToggleSupport: function() {
		this.data.supportToggle = !this.data.supportToggle;
    this.emitChange();
	},

  onSeasonSelected: function(season) {
		this.data.selectedSeason = season;
    var seasonObject = applicationStore.data.dealerTireSeasons[this.data.selectedSeason];
    this.data.selectedSeasonName = seasonObject['en'];
    globalState.select('applicationState').set('routerState', RouterStates.SEARCH_BRANDS_BY_SEASON);
    this.emitChange();
	},

  onBrandIdSelected: function(brandId) {
		this.data.selectedBrandId = brandId;
    var brandObject = null;
    if (this.data.selectedBrandId && sizeOf(this.data.dealerTireBrands) > 0) {
      brandObject = this.data.dealerTireBrands[this.data.selectedBrandId];
      this.data.selectedBrandName = brandObject.part_manufacturer_name;
    }
    globalState.select('applicationState').set('routerState', RouterStates.SEARCH_BRANDS);
    this.emitChange();
	},

	// openForceDialog: function() {
    // this.data.forceUserLogin = true;
    // this.emitChange();
	// },

	onDecodeUrlHash: function(payload) {
    decodeUrlHash(payload.href, payload.search);
    //Router.resetUrlSearch();
    this.emitChange();
	},

	setWidgetSearch: function(searchData) {
		this.data.widgetSearchActive = true;
		if (searchData.key === 'forceLogin') {
			this.data.forceUserLogin = true;
		} else if (searchData.key === 'tiresize') {
			var vehicleParams = searchData.value.split("-");
			this.data.search = vehicleParams[0];
			this.data.lang = vehicleParams[1];
			setTimeout(() => {
				productListActions.setProductListSearchType(SearchTypes.SEARCH_BY_TIRESIZE);
				if (vehicleParams[2]) setTimeout(() => {
          this.data.postal = vehicleParams[2];
					if (document.getElementById("dealer-name-input")) document.getElementById("dealer-name-input").value = vehicleParams[2];
					store.dispatch(setDealerSelectionSearchTerm(vehicleParams[2]));
				}, 100);
			}, 100);
		} else if (searchData.key === 'vehicle') {
			var vehicleParams = searchData.value.split("-");
			var producttype = vehicleParams[0];
			if (!isEmpty(producttype)) {
        switch(producttype.toLowerCase()) {
          case "tires":
            this.data.productType = ProductTypes.TIRES;
            break;
          case "alloy_wheels":
            this.data.productType = ProductTypes.ALLOY_WHEELS;
            break;
          case "accessories":
            this.data.productType = ProductTypes.ACCESSORIES;
            break;
          case "packages":
            this.data.productType = ProductTypes.PACKAGES;
            break;
        }
			}
			this.data.year = vehicleParams[1];
			this.data.make = vehicleParams[2];
			this.data.category = vehicleParams[3];
			this.data.model = vehicleParams[4];
			this.data.lang = vehicleParams[5];
			this.data.postal = vehicleParams[6];

      productListActions.setProductListSearchType(SearchTypes.SEARCH_BY_VEHICLE);
      applicationActions.changeLanguage(vehicleParams[5]);

      if (!skipBreadcrumbsLoad()) {
      	breadcrumbActions.loadBreadcrumbs(vehicleParams[1], vehicleParams[2], vehicleParams[3], vehicleParams[4], null);
      }
      if (!isEmpty(vehicleParams[6])) {
        if (document.getElementById("dealer-name-input")) {
        	document.getElementById("dealer-name-input").value = vehicleParams[6];
        }
				store.dispatch(setDealerSelectionSearchTerm(vehicleParams[6]));
      }

			/*
			setTimeout(() => {
				productListActions.setProductListSearchType(SearchTypes.SEARCH_BY_VEHICLE);
				applicationActions.changeLanguage(vehicleParams[5]);
				setTimeout(() => {
					if (!skipBreadcrumbsLoad()) breadcrumbActions.loadBreadcrumbs(vehicleParams[1], vehicleParams[2], vehicleParams[3], vehicleParams[4], null);
					if (vehicleParams[6]) setTimeout(() => {
            if (document.getElementById("dealer-name-input")) document.getElementById("dealer-name-input").value = vehicleParams[6];
            dealerSelectionActions.setDealerSelectionSearchTerm(vehicleParams[6]);
					}, 100);
				}, 100);
			}, 100);
			*/
		}
    this.emitChange();
	},

  onXtimeIntegrationUrlGathered: function(response) {
    if (!isEmpty(response.xtimeAppointmentIntegrationUrl)) {
      this.data.dealerXtimeAppointmentIntegrationLink = response.xtimeAppointmentIntegrationUrl.toString();
    }
    this.emitChange();
	},

	onResetXtimeIntegrationError: function() {
		this.data.dealerXtimeAppointmentIntegrationError = false;
		this.data.dealerXtimeAppointmentIntegrationLink = '';
    this.emitChange();
	},

  onCampaignIsInactive: function() {
    this.data.campaignIsInactive = true;
    this.data.urlContainsCampaign = true;
    this.emitChange();
	},

  onResetCampaignIsInactive: function() {
    this.data.campaignIsInactive = false;
    this.data.showCampaignDealerInfo = false;
    if (isNationalSite()) setTimeout(() => { applicationActions.changeRouterStateDirectly(RouterStates.DEALER_SELECTION) }, 10);
    this.emitChange();
  },

  onLoadCampaignForDealer: function(payload) {
    this.data.showCampaignDealerInfo = true;
    this.data.urlContainsCampaign = true;
    if (payload.destination !== true) globalState.select('applicationState').set('routerState', payload.destination);
    this.emitChange();
  },

  onLoadCampaignDealerSelectionVehicle: function(payload) {
    this.data.campaignDealerSeletionVehicleInfo = payload;
    this.emitChange();
  },

	onSetSelectedDealerForToken: function(dealer) {
		if (!!dealer) {
			this.data.selectedDealerForToken = dealer.name;
			this.data.selectedDealerIdForToken = dealer.id;
			applicationActions.selectedDealer(dealer.id, dealer.season, null);
			//setTimeout(() => { applicationActions.selectedDealer(dealer.id, dealer.season, null); }, 15000);
		}
    this.emitChange();
	},

  onResetOrderStatus: function() {
    this.data.orderStatus = null;
    this.emitChange();
  },

  onOrderStatusLoaded: function(response) {
    this.data.orderStatus = response;
    this.emitChange();
	},

	onToggleSearchBar: function() {
		// console.trace()
		if (this.data.searchIsActiveFlag) {
			this.data.searchIsActiveFlag = false;
			this.data.searchProductType = null;
		} else {
			this.data.searchIsActiveFlag = true;
			this.data.loginTextDialogShown = false;
			this.data.loginCreateCustomerAccount = false;
			shoppingCartStore.onShoppingCartClose();
		}

		this.emitChange();
	},

	launcherLoadYears: function(data) {
		if (data) {

			this.data.launcherVehicleYears = [];
			var size = 0, key;
			for (key in data) {
				if (data.hasOwnProperty(key)) size++;
			}

			var index = 1;
			for (var i in data) {
				this.data.launcherVehicleYears[(size - index)] = { key: i, value: data[i] };
				index++;
			}

			this.data.selectedYear = null;
			this.data.selectedMake = null;
			this.data.selectedModel = null;
			this.data.selectedCategory = "null";
			this.data.selectedProductType = null;

			this.data.launcherVehicleMakes = [];
			this.data.launcherVehicleModels = [];
			this.data.launcherVehicleProductTypes = [];

		}
		this.emitChange();
	},

	launcherLoadMakes: function(data) {
		if (data) {

			this.data.launcherVehicleMakes = [];
			var primaryData = data.primaryMakes;
			var secondaryData = data.secondaryMakes;

			for (var i in primaryData) {
				this.data.launcherVehicleMakes[sizeOf(this.data.launcherVehicleMakes)] = { key: primaryData[i].vehicleManufacturerId, value: primaryData[i].vehicleManufacturerName };
			}

			if (vehicleSelectionStore.shouldShowSecondaryMakes()) {
				for (var i in secondaryData) {
					this.data.launcherVehicleMakes[sizeOf(this.data.launcherVehicleMakes)] = {
						key: secondaryData[i].vehicleManufacturerId,
						value: secondaryData[i].vehicleManufacturerName
					};
				}
			}

			if (sizeOf(this.data.launcherVehicleMakes) === 1) {
				this.data.selectedMake = this.data.launcherVehicleMakes[0].key;
				this.data.selectedModel = null;
				this.data.selectedCategory = "null";
				this.data.selectedProductType = null;
				/*
				setTimeout(() => {
          applicationActions.loadLauncherModelSelection(this.data.selectedYear, this.data.launcherVehicleMakes[0].key);
				}, 100);
				*/
			} else {
				this.data.selectedMake = null;
				this.data.selectedModel = null;
				this.data.selectedCategory = "null";
				this.data.selectedProductType = null;
			}

			this.data.launcherVehicleModels = [];
			this.data.launcherVehicleProductTypes = [];

		}
		this.emitChange();
	},

	launcherLoadModels: function(data) {
		if (data.response) {

			this.data.launcherVehicleModels = [];
			for (var i in data.response) {
				var modelId = data.response[i].vehicleModelClassId === "0" || data.response[i].vehicleModelClassId === 0 ? "null-"+data.response[i].vehicleModelId : data.response[i].vehicleModelClassId+"-"+data.response[i].vehicleModelId;
				var modelName = "";
				if (this.data.lang === "fr") {
					modelName = data.response[i].vehicleModelClassId === "0" || data.response[i].vehicleModelClassId === 0 ? data.response[i].vehicleModelName.fr : data.response[i].vehicleModelClassName.fr + " " + data.response[i].vehicleModelName.fr;
				} else {
					modelName = data.response[i].vehicleModelClassId === "0" || data.response[i].vehicleModelClassId === 0 ? data.response[i].vehicleModelName.en : data.response[i].vehicleModelClassName.en + " " + data.response[i].vehicleModelName.en;
				}
				this.data.launcherVehicleModels[sizeOf(this.data.launcherVehicleModels)] = { key: modelId , value: modelName };
			}

			if (sizeOf(this.data.launcherVehicleModels) === 1) {
				var tempModel = this.data.launcherVehicleModels[0].key.split("-");
				var category = tempModel[0];
				var model = tempModel[1];
				this.data.selectedModel = model;
				this.data.selectedCategory = category;
				this.data.selectedProductType = null;
				setTimeout(() => {
          applicationActions.loadLauncherProductTypeSelection(this.data.selectedYear, this.data.selectedMake, category, model);
				}, 100);
			} else {
				this.data.selectedModel = null;
				this.data.selectedCategory = "null";
				this.data.selectedProductType = null;
			}

			this.data.launcherVehicleProductTypes = [];

		}

	},

	launcherLoadProductTypes: function(data) {
		if (data.response) {

			var tires = languageStore.getDictionaryTranslate('launcher.tires', this.data.lang);
			var wheels = languageStore.getDictionaryTranslate('launcher.wheels', this.data.lang);
			var accessories =languageStore.getDictionaryTranslate('launcher.accessories', this.data.lang);
			var packages = languageStore.getDictionaryTranslate('launcher.packages', this.data.lang);

			this.data.launcherVehicleProductTypes = [];
			if (data.response.has_TRE) this.data.launcherVehicleProductTypes[sizeOf(this.data.launcherVehicleProductTypes)] = { key: "tires", value: tires };
			if (data.response.has_WHL) this.data.launcherVehicleProductTypes[sizeOf(this.data.launcherVehicleProductTypes)] = { key: "wheels", value: wheels };
			if (data.response.has_ACC) this.data.launcherVehicleProductTypes[sizeOf(this.data.launcherVehicleProductTypes)] = { key: "accessories", value: accessories };
			if (data.response.has_PKG) this.data.launcherVehicleProductTypes[sizeOf(this.data.launcherVehicleProductTypes)] = { key: "packages", value: packages };

			if (sizeOf(this.data.launcherVehicleProductTypes) === 1) {
				this.data.selectedProductType = this.data.launcherVehicleProductTypes[0].key;
			} else {
				this.data.selectedProductType = null;
			}

		}
		this.emitChange();
	},

	launcherUpdateValues: function(data) {
		if (data) {
			this.data.selectedYear = data.selectedYear ? data.selectedYear : this.data.selectedYear;
			this.data.selectedMake = data.selectedMake ? data.selectedMake : this.data.selectedMake;
			this.data.selectedCategory = data.selectedCategory ? data.selectedCategory : this.data.selectedCategory;
			this.data.selectedModel = data.selectedModel ? data.selectedModel : this.data.selectedModel;
			this.data.selectedProductType = data.selectedProductType ? data.selectedProductType : this.data.selectedProductType;
		}
		this.emitChange();
	},

	onOpenTireSizeHelper: function(fromTrim = false) {
		this.data.showTireSizeHelper = true;
		this.data.tireSizeHelperFromTrim = fromTrim;
		this.emitChange();
	},

	onCloseTireSizeHelper: function() {
		this.data.showTireSizeHelper = false;
		this.emitChange();
	},

	onOpenNTDModal: function(enablePrompt) {
		// console.trace()
		this.data.enableNTDOrderModal = true;
		this.data.enableNTDOrderModalXtimePrompt = enablePrompt;
		this.emitChange();
	},

	onCloseNTDModal: function() {
		this.data.enableNTDOrderModal = false;
		this.data.enableNTDOrderModalXtimePrompt = false;
		this.emitChange();
	},

	openGuestAccountCreationModal: function() {
		this.data.guestAccountCreationModal = true;
		this.emitChange();
	},

	closeGuestAccountCreationModal: function() {
		this.data.guestAccountCreationModal = false;
		this.data.guestAccountCreationReturnMessage = false;
		this.data.guestAccountCreationSuccessful = false;
		this.emitChange();
	},

	submitGuestAccountCreation: function(response) {
		if (response.success) {
			this.data.guestAccountCreationReturnMessage = <p className="guest-account-creation-success">{response.message[this.data.lang]}</p>;
			this.data.guestAccountCreationSuccessful = true;
		} else {
			this.data.guestAccountCreationReturnMessage = <p className="guest-account-creation-error">{response.message[this.data.lang]}</p>;
			this.data.guestAccountCreationSuccessful = false;
		}

		this.emitChange();
	},

	openForgotPasswordModal: function () {
		this.data.forgotPasswordModal = true;
		this.emitChange();
	},

	closeForgotPasswordModal: function () {
		this.data.forgotPasswordModal = false;
		this.emitChange();
	},

	submitForgotPassword: function (response) {
		if (response.success) {
			this.data.forgotPasswordReturnMessage = <p className="forgot-password-success">{response.message[this.data.lang]}</p>;
		} else {
			this.data.forgotPasswordReturnMessage = <p className="forgot-password-error">{response.message[this.data.lang]}</p>;
		}

		this.emitChange();
	},

	onActivateSearchBar: function () {
		this.data.searchIsActiveFlag = true;
		this.emitChange();
	},

	onDeactivateSearchBar: function () {
		this.data.searchIsActiveFlag = false;
		this.emitChange();
	},

	attemptTireWheelPackageCreation: function () {
		this.data.sentFromTireCreateWheelPackage = true;
		this.emitChange();
	},

	attemptedTireWheelPackageCreation: function () {
		this.data.sentFromTireCreateWheelPackage = false;
		this.emitChange();
	},

	onGoBack: function () {
		var appStateCursor = globalState.select('applicationState');
		var routerState = globalState.select('applicationState', 'routerState').get();

		var routesOrder = isDealerSite() ? getDealerRouterOrder() : getNationalRouterOrder();
		var currentIndex = _.findIndex(routesOrder, predicateFn(routerState));
		var previousState = routesOrder[currentIndex - 1];

		switch (routerState) {
			case RouterStates.PRODUCT_DETAIL:
				if (this.data.search
					&& (productListStore.data.searchType !== SearchTypes.SEARCH_BY_VEHICLE
					&& productListStore.data.searchType !== SearchTypes.SEARCH_BY_MODEL)) {
					previousState = RouterStates.SEARCH_RESULT;
				} else if (this.data.productType == ProductTypes.TIRES
					|| this.data.productType == ProductTypes.ALLOY_WHEELS
					|| this.data.productType == ProductTypes.ACCESSORIES
					|| this.data.productType == ProductTypes.PACKAGES) {
					previousState = RouterStates.PRODUCT_LIST;
				} else previousState = RouterStates.HOMEPAGE;
				break;
			case RouterStates.YEAR:
			case RouterStates.CONTACT_DEALER:
				if (!this.data.disableHomepage) previousState = RouterStates.HOMEPAGE;
				else if (!isDealerSite() || this.data.disableHomepage) previousState = RouterStates.DEALER_SELECTION;
				break;
			case RouterStates.MAKE:
			case RouterStates.MODEL:
			case RouterStates.CATEGORY:
				this.data.category = null;
				break;
			case RouterStates.PRODUCT_LIST:
				if (this.data.loadSearchModalFromProductTypeSelection && this.data.productType !== ProductTypes.TIRES) previousState = RouterStates.HOMEPAGE;
				else if (this.data.useProvincialDealers || productListStore.data.searchType !== SearchTypes.SEARCH_BY_VEHICLE) previousState = RouterStates.HOMEPAGE;
				break;
			case RouterStates.WISHLIST:
			case RouterStates.WISHLIST_WITH_ID:
				if (this.data.search
					&& (productListStore.data.searchType !== SearchTypes.SEARCH_BY_VEHICLE
					&& productListStore.data.searchType !== SearchTypes.SEARCH_BY_MODEL)) {
					previousState = RouterStates.SEARCH_RESULT;
				} else if ((this.data.productType == ProductTypes.TIRES
					|| this.data.productType == ProductTypes.ALLOY_WHEELS
					|| this.data.productType == ProductTypes.ACCESSORIES
					|| this.data.productType == ProductTypes.PACKAGES)
					&& productListStore.data.searchType !== null) previousState = RouterStates.PRODUCT_LIST;
				else if (this.data.productType == ProductTypes.COLLECTIONS) previousState = RouterStates.COLLECTION_LIST;
				else previousState = RouterStates.HOMEPAGE;
				break;
			case RouterStates.HOMEPAGE:
				if (this.data.useProvincialDealers) {
					previousState = RouterStates.DEALER_SELECTION;
				}
				break;
		}

		if (previousState == RouterStates.DEALER_SELECTION) {
			breadcrumbsStore.resetCrumbs();
		}

		if (previousState == RouterStates.HOMEPAGE) {
			if (isDealerSite()) breadcrumbsStore.resetCrumbs();
			this.data.search = null;
			this.data.year = null;
			this.data.category = null;
		}

		if (this.data.disableHomepage && previousState == RouterStates.HOMEPAGE) {
			previousState = RouterStates.YEAR;
		}

		this.data.userGoBack = true;

		setTimeout(() => { shoppingCartActions.closeCart(); }, 10);

		appStateCursor.set('routerState', previousState);
		appStateCursor.set('routerForward', false);
	},

	onChangeStateFromUrl: function (actionPayload) {
    var shoppingContext = Router.collectShoppingContext();
    if (shoppingContext.isEcommerce || shoppingContext.isOrder)  shoppingCartActions.getNewShoppingCart();

		var routerState = actionPayload.routerState;
		var appStateCursor = globalState.select('applicationState');

		var routesOrder = isDealerSite() ? getDealerRouterOrder() : getNationalRouterOrder();
		var previousRouterState = globalState.select('applicationState', 'routerState').get();
		var forward = _.findIndex(routesOrder, predicateFn(previousRouterState)) < _.findIndex(routesOrder, predicateFn(routerState));

		if (routerState == RouterStates.HOMEPAGE) {

			breadcrumbsStore.resetCrumbs();
			this.data.search = null;
			this.data.year = null;

		} else if (routerState == RouterStates.YEAR) {


		} else if (routerState == RouterStates.PRODUCT_LIST) {

			this.data.lang = actionPayload.routerParams[0];
			this.data.dealerId = actionPayload.routerParams[1];
			this.data.productType = actionPayload.routerParams[2].toUpperCase();

			if (actionPayload.routerParams[3] === SearchTypes.SEARCH_BY_VEHICLE) {

				this.data.year = actionPayload.routerParams[4];
				this.data.make = actionPayload.routerParams[5];
				this.data.category = actionPayload.routerParams[6];
				this.data.model = actionPayload.routerParams[7];
				this.data.trim = actionPayload.routerParams[8];

				productListStore.data.searchType = SearchTypes.SEARCH_BY_VEHICLE;

			} else if (actionPayload.routerParams[3] === SearchTypes.SEARCH_BY_MODEL) {

				productListStore.data.searchType = SearchTypes.SEARCH_BY_MODEL;
				vehicleSelectionStore.data.year = actionPayload.routerParams[4];
				vehicleSelectionStore.data.category = actionPayload.routerParams[5];
				vehicleSelectionStore.data.selectedModelId = actionPayload.routerParams[6];

			} else if (actionPayload.routerParams[3] === SearchTypes.SEARCH_BY_CHASSIS) {

				productListStore.data.searchType = SearchTypes.SEARCH_BY_CHASSIS;
				productListActions.setChassisSearchValue(actionPayload.routerParams[4]);

			}

		} else if (routerState == RouterStates.PRODUCT_DETAIL) {

			this.data.lang = actionPayload.routerParams[0];
			this.data.dealerId = actionPayload.routerParams[1];
			this.data.productType = actionPayload.routerParams[2].toUpperCase();
			this.data.year = actionPayload.routerParams[3];
			this.data.make = actionPayload.routerParams[4];
			this.data.category = actionPayload.routerParams[5];
			this.data.model = actionPayload.routerParams[6];
			this.data.trim = actionPayload.routerParams[7];
			vehicleSelectionStore.data.vehicleId = actionPayload.routerParams[8];
			productDetailStore.data.productId = actionPayload.routerParams[9];
			productDetailStore.data.productType = actionPayload.routerParams[2].toUpperCase();
			productDetailStore.data.secondaryProductId = actionPayload.routerParams[10];

			/*
			if (!skipBreadcrumbsLoad()) {
				setTimeout(() => {
					breadcrumbActions.loadBreadcrumbs(actionPayload.routerParams[3], actionPayload.routerParams[4], actionPayload.routerParams[5], actionPayload.routerParams[6], actionPayload.routerParams[7]); // (year, make, category, model, trim)
				}, 10);
			}
			*/

		} else if (routerState == RouterStates.SEARCH_RESULT) {

			this.data.lang = actionPayload.routerParams[0];
			this.data.dealerId = actionPayload.routerParams[1];
			this.data.search = actionPayload.routerParams[3];

			productListStore.data.searchType = actionPayload.routerParams[2];

		}

		if (this.data.disableHomepage && routerState == RouterStates.HOMEPAGE) {
			routerState = RouterStates.YEAR;
		}

		appStateCursor.set('routerState', routerState);
		appStateCursor.set('routerForward', forward);
	},

	onChangeProductType: function (productType) {
		this.data.productType = productType;
		this.emitChange();
	},

	onChangeStateDirectly: function (payload) {
    var routerState = payload.routerstate, shoppingContext = Router.collectShoppingContext();
		if (globalState.select('applicationState').get('routerState') !== RouterStates.DEALER_SELECTION || this.data.dealerInfo !== null) {
			if (routerState === RouterStates.HOMEPAGE) {
        breadcrumbsStore.resetCrumbs();
				this.resetVehicleData("SEARCH");
			} else if (routerState === RouterStates.CONTACT_DEALER) {
        breadcrumbsStore.resetCrumbs();
        this.resetVehicleData("SEARCH");
			} else if (routerState === RouterStates.YEAR) {
        this.resetVehicleData("YEAR");
			} else if (routerState === RouterStates.MAKE) {
        this.resetVehicleData("MAKE");
			} else if (routerState === RouterStates.CATEGORY) {
        this.resetVehicleData("CATEGORY");
			} else if (routerState === RouterStates.MODEL) {
        this.resetVehicleData("MODEL");
			} else if (routerState === RouterStates.TRIM) {
        this.resetVehicleData("TRIM");
			} else if (routerState === RouterStates.DEALER_SELECTION) {
        breadcrumbsStore.resetCrumbs();
        this.resetVehicleData("SEARCH");
        this.data.dealerInfo = null;
        this.data.campaignDealerSeletionVehicleInfo = {};
      }
			if (this.data.disableHomepage && routerState === RouterStates.HOMEPAGE) {
				routerState = RouterStates.YEAR;
			}
      if (shoppingContext.isEcommerce || shoppingContext.isOrder) {
				setTimeout(() => { shoppingCartActions.getNewShoppingCart(); }, 100);
      }
      if (payload.goback) {
        this.data.userGoBackWithCrumb = true;
			}
			globalState.select('applicationState').set('routerState', routerState);
		}
	},

	resetVehicleData: function(dataType) {
		switch(dataType) {
			case "SEARCH":
			case "YEAR":
        this.data.search = null;
        this.data.year = null;
        this.data.make = null;
        this.data.category = null;
        this.data.model = null;
        this.data.trim = null;
        break;
			case "MAKE":
        this.data.make = null;
        this.data.category = null;
        this.data.model = null;
        this.data.trim = null;
        break;
			case "CATEGORY":
        this.data.category = null;
        this.data.model = null;
        this.data.trim = null;
        break;
			case "MODEL":
        this.data.model = null;
        this.data.trim = null;
        break;
			case "TRIM":
        this.data.trim = null;
        break;
		}
	},

	onChangeLang: function (lang) {
		this.data.lang = lang;
		this.emitChange();
	},

	openReturnToDealerSelectionWarning: function () {
		this.data.returnToDealerSelectionWarning = true;
		this.emitChange();
	},

	closeReturnToDealerSelectionWarning: function () {
		this.data.returnToDealerSelectionWarning = false;
		this.emitChange();
	},

	onOpenWishlist: function () {
		if (globalState.select('applicationState').get('routerState') !== RouterStates.WISHLIST) {
			var appStateCursor = globalState.select('applicationState');
			appStateCursor.set('routerForward', true);
			appStateCursor.set('routerState', RouterStates.WISHLIST);
		}
		this.emitChange();
	},

	onListOfTiresLoadedFromModel: function () {
		if (globalState.select('applicationState').get('routerState') !== RouterStates.PRODUCT_LIST) {
			this.data.productType = ProductTypes.TIRES;
			var appStateCursor = globalState.select('applicationState');
			appStateCursor.set('routerForward', true);
			appStateCursor.set('routerState', RouterStates.PRODUCT_LIST);
		}

		this.emitChange();
	},

	onListOfTiresLoadedFromChassis: function () {
		if (globalState.select('applicationState').get('routerState') !== RouterStates.PRODUCT_LIST) {
			this.data.productType = ProductTypes.TIRES;
			var appStateCursor = globalState.select('applicationState');
			appStateCursor.set('routerForward', true);
			appStateCursor.set('routerState', RouterStates.PRODUCT_LIST);
		}

		this.emitChange();
	},

	onListOfPackagesLoadedFromModel: function () {
		if (globalState.select('applicationState').get('routerState') !== RouterStates.PRODUCT_LIST) {
			this.data.productType = ProductTypes.PACKAGES;
			var appStateCursor = globalState.select('applicationState');
			appStateCursor.set('routerForward', true);
			appStateCursor.set('routerState', RouterStates.PRODUCT_LIST);
		}

		this.emitChange();
	},

	onProductTypeSelected: function (productType) {
		var appStateCursor = globalState.select('applicationState');

		appStateCursor.set('routerForward', true);
		appStateCursor.set('routerState', RouterStates.YEAR);

		this.data.productType = productType;

		this.emitChange();
	},

	onSetProductTypeValue: function (productType) {
		this.data.productType = productType;
		this.emitChange();
	},

  onSetSearchProductTypeValue: function (productType) {
		this.data.searchProductType = productType;
		this.emitChange();
	},

	onProductDetailOpened: function (payload) {
		var appStateCursor = globalState.select('applicationState');

		appStateCursor.set('routerForward', true);
    appStateCursor.set('routerState', RouterStates.PRODUCT_DETAIL);

		this.data.productType = payload.productType;

		// this.onChangeStateDirectly({ routerState: RouterStates.PRODUCT_DETAIL, goBack: false, });
		// applicationActions.changeRouterStateDirectly(RouterStates.PRODUCT_DETAIL);

		this.emitChange();
	},

	onYearSelectedFromModal: function (year) {
		this.data.year = year;
		this.emitChange();
	},

	onCategorySelectedFromModal: function (category) {
		this.data.category = category;
		this.emitChange();
	},

	onModelSelectedFromModal: function (model) {
		this.data.model = model;
		this.emitChange();
	},

  onYearSelected: function (year) {
    var appStateCursor = globalState.select('applicationState');

    appStateCursor.set('routerForward', true);
    appStateCursor.set('routerState', RouterStates.MAKE);

    this.data.year = year;
    this.resetVehicleData("MAKE");

    this.emitChange();
  },

	onMakeSelected: function (make) {
		var appStateCursor = globalState.select('applicationState');

		appStateCursor.set('routerForward', true);
		appStateCursor.set('routerState', RouterStates.CATEGORY);

		this.data.make = make;
    this.resetVehicleData("CATEGORY");

		this.emitChange();
	},

	onCategorySelected: function (category) {
		var appStateCursor = globalState.select('applicationState');

		appStateCursor.set('routerForward', true);
		appStateCursor.set('routerState', RouterStates.MODEL);

		this.data.category = category;
    this.resetVehicleData("MODEL");

		this.emitChange();
	},

	onModelSelected: function (model) {
		var appStateCursor = globalState.select('applicationState');

		appStateCursor.set('routerForward', true);
		appStateCursor.set('routerState', RouterStates.TRIM);

		this.data.model = model;
    this.resetVehicleData("TRIM");

		this.emitChange();
	},

	onTrimSelected: function (trim) {
		var appStateCursor = globalState.select('applicationState');
		var forwardState = VehicleSelectionSkippingService.getStateAfterTrimSelection(vehicleSelectionStore.data.trims, isDealerSite(), true);

		appStateCursor.set('routerForward', true);
		appStateCursor.set('routerState', forwardState);

		this.data.trim = trim;

		this.emitChange();
	},

	onVehicleYearsLoaded: function (payload) {
    if (!this.data.userGoBack) {
      globalState.select('applicationState').set('routerState', VehicleSelectionSkippingService.getRouterStateForLoadedYears(payload, globalState.select('applicationState', 'routerForward').get()));

      var skippedYear = VehicleSelectionSkippingService.getSkippedYearForLoadedYears(payload);
      if (skippedYear !== false) {
        this.data.year = skippedYear ? skippedYear.id : null;
        this.data.make = null;
        this.data.category = null;
        this.data.model = null;
      }

      if (typeof this.data.productType === 'undefined') {
        this.data.productType = this.data.defaultProductType;
      } else {
        this.data.userGoBack = false;
        this.data.userGoBackWithCrumb = false;
      }

      this.data.search = null;
    } else {

      if (sizeOf(payload) > 1) {
        globalState.select('applicationState').set('routerState', VehicleSelectionSkippingService.getRouterStateForLoadedYears(payload, globalState.select('applicationState', 'routerForward').get()));
        this.data.userGoBack = false;
        this.data.userGoBackWithCrumb = false;
      } else {
        globalState.select('applicationState').set('routerState', RouterStates.HOMEPAGE);
      }

		}

		this.emitChange();
	},

	onVehicleMakesLoaded: function (payload) {
    if (!this.data.userGoBack) {

    	var routerState = RouterStates.MAKE;

      globalState.select('applicationState').set('routerState', routerState);

      var skippedMake = VehicleSelectionSkippingService.getSkippedMakeForLoadedMakes(
        payload.primaryMakes,
        payload.secondaryMakes,
        this.data.showSecondaryMakes,
        this.data.productType
      );

      if (skippedMake !== false) {
        this.data.make = skippedMake ? skippedMake.id : null;
        this.data.category = null;
        this.data.model = null;
      } else {
        this.data.userGoBack = false;
        this.data.userGoBackWithCrumb = false;
      }

    } else {

      if ((sizeOf(payload.primaryMakes) + sizeOf(payload.secondaryMakes)) > 1) {

        var routerState = VehicleSelectionSkippingService.getRouterStateForLoadedMakes(
          payload.primaryMakes,
          payload.secondaryMakes,
          this.data.showSecondaryMakes,
          this.data.productType,
          globalState.select('applicationState', 'routerForward').get()
        );

        globalState.select('applicationState').set('routerState', routerState);
        this.data.userGoBack = false;
        this.data.userGoBackWithCrumb = false;

      } else {

        globalState.select('applicationState').set('routerState', RouterStates.YEAR);

      }

		}

		this.emitChange();
	},

	onVehicleCategoriesLoaded: function (payload) {
    if (!this.data.userGoBack) {
      globalState.select('applicationState').set('routerState', VehicleSelectionSkippingService.getRouterStateForLoadedCategories(payload, globalState.select('applicationState', 'routerForward').get()));

      var skippedCategory = VehicleSelectionSkippingService.getSkippedCategoryForLoadedCategories(payload);
      if (skippedCategory !== false && skippedCategory !== null) {
        this.data.category = skippedCategory ? skippedCategory.id : null;
        this.data.model = null;
      } else {
        this.data.userGoBack = false;
        this.data.userGoBackWithCrumb = false;
      }
    } else {

      if (sizeOf(payload) > 1) {
        globalState.select('applicationState').set('routerState', VehicleSelectionSkippingService.getRouterStateForLoadedCategories(payload, globalState.select('applicationState', 'routerForward').get()));
        this.data.userGoBack = false;
        this.data.userGoBackWithCrumb = false;
      } else {
        globalState.select('applicationState').set('routerState', RouterStates.MAKE);
      }

		}

		this.emitChange();
	},

	onVehicleModelsLoaded: function (payload) {
    if (!this.data.userGoBack) {
      globalState.select('applicationState').set('routerState', VehicleSelectionSkippingService.getRouterStateForLoadedModels(payload, globalState.select('applicationState', 'routerForward').get()));

      var skippedModel = VehicleSelectionSkippingService.getSkippedModelForLoadedModels(payload);
      if (skippedModel !== false) {
        this.data.model = skippedModel ? skippedModel.id : null;
      } else {
        this.data.userGoBack = false;
        this.data.userGoBackWithCrumb = false;
      }
    } else {

      if (sizeOf(payload) > 1) {
        globalState.select('applicationState').set('routerState', VehicleSelectionSkippingService.getRouterStateForLoadedModels(payload, globalState.select('applicationState', 'routerForward').get()));
        this.data.userGoBack = false;
      } else {
        globalState.select('applicationState').set('routerState', RouterStates.CATEGORY);
			}

		}

		this.emitChange();
	},

	onVehicleTrimsLoaded: function (payload) {
		this.data.search = null;
		if (!this.data.userGoBack) {

      globalState.select('applicationState').set('routerState', VehicleSelectionSkippingService.getRouterStateForLoadedTrims(payload, isDealerSite(), globalState.select('applicationState', 'routerForward').get()));
      var skippedTrim = VehicleSelectionSkippingService.getSkippedTrimForLoadedTrims(payload);
      if (skippedTrim) {
        this.data.trim = skippedTrim ? skippedTrim.id : null;
      } else {
        this.data.userGoBack = false;
        this.data.userGoBackWithCrumb = false;
      }

    } else {

			if (!this.data.enableShowSubmodelTiresizeSelection) {
        if (sizeOf(payload) > 1) {
          globalState.select('applicationState').set('routerState', VehicleSelectionSkippingService.getRouterStateForLoadedTrims(payload, isDealerSite(), globalState.select('applicationState', 'routerForward').get()));
        } else if (sizeOf(payload) === 1 && this.data.allowSearchByVehicleSimple) {
          if (!this.data.disableHomepage) setTimeout(() => { applicationActions.changeRouterStateDirectly(RouterStates.HOMEPAGE); }, 100);
          else setTimeout(() => { applicationActions.changeRouterStateDirectly(RouterStates.YEAR); }, 100);
        } else if (sizeOf(payload) === 1) {
          globalState.select('applicationState').set('routerState', RouterStates.MODEL);
        }
      }

      this.data.userGoBack = false;
      this.data.userGoBackWithCrumb = false;

		}

		this.emitChange();
	},

	onVehicleInfoLoaded: function (payload) {

		if (payload.vehicleHasMappedParts) {
			this.data.productListProductTypes = [];

			if (_.contains(this.data.productTypes, ProductTypes.TIRES)) { // Preserve Tire Status
				this.data.productListProductTypes.push(ProductTypes.TIRES);
			}
			if (payload.vehicleHasMappedParts["WHL"] == '1' && _.contains(this.data.productTypes, ProductTypes.ALLOY_WHEELS)) {
				this.data.productListProductTypes.push(ProductTypes.ALLOY_WHEELS);
			}
			if (payload.vehicleHasMappedParts["ACC"] == '1' && _.contains(this.data.productTypes, ProductTypes.ACCESSORIES)) {
				this.data.productListProductTypes.push(ProductTypes.ACCESSORIES);
			}
			if (payload.vehicleHasMappedParts["PKG"] == '1' && _.contains(this.data.productTypes, ProductTypes.PACKAGES)) {
				this.data.productListProductTypes.push(ProductTypes.PACKAGES);
			}
		}

		this.emitChange();
	},

	onWishlistReviewLoaded: function (payload) {
    if (payload.skipRedirect && sizeOf(payload.orderItemList) > 0 && globalState.get() !== RouterStates.WISHLIST) {
      // setTimeout(() => { shoppingCartActions.openCart() }, 100);
    }


		if (!payload.skipRedirect) globalState.select('applicationState').set('routerState', RouterStates.WISHLIST);

		this.data.cart = payload.orderId;
		shoppingCartStore.data = shoppingCartStore.data.set('cart', payload.orderId);

		this.emitChange();
	},

	onApiCallStarted: function () {
		this.data.apiCallInProgressCount++;
		if (!this.data.apiCallInProgress) this.data.apiCallInProgress = true;
		this.emitChange();
	},

	onApiCallFinished: function () {

		this.data.webserviceFailed = false;
		this.data.apiCallInProgressCount--;
		if (this.data.apiCallInProgressCount === 0) this.data.apiCallInProgress = false;

    if (enableAdobeLaunch()) {
      setAdobeDigitalData(setAdobeLauncherDigitalData());
      //runAdobePageView();
    }

		this.emitChange();

	},

	onApiCallFailed: function (httpStatus) {
		globalState.select('applicationState').set('routerState', RouterStates.WEBSERVICE_ERROR);
		this.data.webserviceFailed = true;
		this.data.webserviceFailedHttpStatus = httpStatus;
		this.emitChange();
	},

	onCloseLoginTextDialog: function () {
		this.data.loginDialogShown = false;
		this.data.loginTextDialogShown = false;
		this.data.loginCreateCustomerAccount = false;
		this.emitChange();
	},
	//TODO open create store for this function and createAcccount if possible
	onOpenLoginTextDialog: function (createAccount) {
		this.data.loginTextDialogShown = true;
		this.data.loginFailure = false;
		this.data.searchIsActiveFlag = false;
		this.data.loginCreateCustomerAccount = createAccount;
		shoppingCartStore.onShoppingCartClose();
		this.emitChange();
	},

	onCloseLoginDialog: function () {
		this.data.loginDialogShown = false;
		this.emitChange();
	},

	onOpenLoginDialog: function () {
		// console.trace()
		this.data.loginFailure = false;
		this.data.loginDialogShown = true;
		this.emitChange();
	},

	onOpenStartNewQuote: function () {
		this.data.showStartNewQuoteModal = true;
		this.emitChange();
	},

	onCloseStartNewQuote: function () {
		this.data.showStartNewQuoteModal = false;
		this.emitChange();
	},

	// onOpenShowAuthenticationModal: function () {
	// 	this.data.showAuthenticationStateModal = true;
	// 	this.emitChange();
	// },

	onCloseShowAuthenticationModal: function () {
		this.data.showAuthenticationStateModal = false;
		this.emitChange();
	},

	onLoginSuccessful: function () {
		this.data.loggedIn = true;
		this.data.userLoggedOut = false;
		this.data.loginHasExpired = false;
		if (!this.data.enableMercedesFeatures) this.data.forceUserLogin = false;

		var routerState = globalState.select('applicationState', 'routerState').get();

		shoppingCartStore.emptyCart();
		setTimeout(() => { shoppingCartActions.closeCart(); }, 0);

		if (isDealerAuthentication()) {
      this.data.loginDialogShown = false;
      this.data.loginTextDialogShown = false;
      this.data.loginCreateCustomerAccount = false;

      switch(routerState) {
        case RouterStates.WISHLIST:
          loadWishlistWithContext();
          break;
      }

			// setTimeout(() => { applicationActions.openAuthenticationStateModal(); }, 100);

      // check local storage for wishlist modal
      checkStorageFormData();
    } else if (!this.data.loginCreateCustomerAccount) {
      this.data.loginDialogShown = false;
      this.data.loginTextDialogShown = false;
		}

		this.emitChange();
	},

	setOeFilterValue: function (oeFilterValue) {
		this.data.originalEquipmentOnlyDefaultOn = oeFilterValue;
		this.emitChange();
	},

	// onLoginFailure: function () {
	// 	this.data.loginFailure = true;
	// 	this.emitChange();
	// },

	onLogOutSuccessful: function () {
		shoppingCartStore.emptyCart();
		setTimeout(() => { shoppingCartActions.closeCart(); }, 10);

		if (!this.data.loginHasExpired) {
			logOut();
			var forwardState = false;
			if (!this.data.disableHomepage) {
				forwardState = RouterStates.HOMEPAGE;
			} else {
				this.data.productType = this.data.defaultProductType;
				forwardState = RouterStates.YEAR;
			}

			globalState.select('applicationState').set('routerState', forwardState);
			// setTimeout(() => { applicationActions.openAuthenticationStateModal(); }, 10);
		}

		this.data.loggedIn = false;
		this.data.userLoggedOut = true;
		this.emitChange();
	},

	showExternalLinkWarning: function (link) {
		this.data.externalLinkModalFlag = true;
		this.data.externalLink = link;
		this.emitChange();
	},

	closeExternalLinkWarning: function () {
		this.data.externalLinkModalFlag = false;
		this.data.externalLink = "";
		this.emitChange();
	},

	onDealerAccountInfoLoaded: function (response) {
    loadDealerConfig(response);
		this.emitChange();
	},

  onDealerInfoLoaded: function (object) {
    loadDealerInfoConfig(object);
    this.emitChange();
	},

	onDealerSelected: function (payload) {
		loadNationalDealerConfig(payload);
		this.data.searchIsActiveFlag = false;
    this.emitChange();
	},

	onNationalAccountInfoLoaded: function (response) {
		loadNationalConfig(response);
		this.emitChange();
	},

	onGroupAccountInfoLoaded: function (response) {
    loadGroupConfig(response);
		this.emitChange();
	},

	loadDealerAppointmentInfo: function (dealerUrlCode) {
		if (dealerUrlCode) {
			get('/dealer/getDealerAccountInfoByDealerUrlCode.php', {
        dealerUrlCode: dealerUrlCode,
        isNational: this.data.nationalSite ? 1 : 0
			}).then(response => {
        shoppingCartViewStore.onDealerAccountInfoLoaded(response);
				this.emitChange();
			});
		}
	},

	onProvincialDealersLoaded: function (listOfDealers) {
		this.data.provincialDealersList = Immutable.fromJS(listOfDealers).map(function (dealer) { return this.getDealerObject(dealer); }.bind(this));
		this.emitChange();
	},

	onProvincialDealerDealersListLoaded: function (listOfDealers) {
		this.data.provincialDealerDealersList = Immutable.fromJS(listOfDealers).map(function (dealer) { return this.getDealerObject(dealer); }.bind(this));
		this.emitChange();
	},

	getDealerObject: function(dealer) {
		return Immutable.Map({
      id: dealer.get('dealerId'),
      name: dealer.get('dealerName'),
      urlCode: dealer.get('dealerUrlCode'),
      address: dealer.get('dealerAddress'),
      city: dealer.get('dealerCity'),
      postalCode: dealer.get('dealerPostalCode'),
      province: dealer.get('dealerState'),
      country: dealer.get('dealerCountry'),
      phone: dealer.get('dealerContactPhone'),
      email: dealer.get('dealerContactEmail'),
      website: dealer.get('dealerWebsiteUrl'),
      hours: dealer.get('dealerHours'),
      season: dealer.get('dealerDefaultSearchSeason'),
      position: new GoogleMapsAPI.LatLng(parseFloat(dealer.get('dealerLatitude')), parseFloat(dealer.get('dealerLongitude'))),
      googleMapsUrl: dealer.get('dealerRetailSiteGoogleMapsExternalUrl'),
      latitude: dealer.get('dealerLatitude'),
      longitude: dealer.get('dealerLongitude'),
      template: dealer.get('dealerDefaultTemplate'),
      legalFooter: dealer.get('legalFooter'),
      active: false
    });
	},

	onWishlistDealerSelectSearch: function (search) {
		this.data.dealerModalSearchTerm = search;
		this.emitChange();
	},

	resetDealerSelectSearchTerm: function () {
		this.data.dealerModalSearchTerm = null;
		this.emitChange();
	},

	onProvincialDealerDealersListSelected: function (dealer) {
		this.data.provincialDealerDealersListSelectedDealer = dealer;
		this.emitChange();
	},

	quoteReportLoaded: function (response) {
		if (response) this.data.quoteList = Immutable.fromJS(response);
		this.emitChange();
	},

	leadReportLoaded: function (response) {
		if (response) this.data.leadList = Immutable.fromJS(response);
		this.emitChange();
	},

	quoteReportUpdateSort: function (response) {
		// console.trace()
		this.data.quoteReportSelectedSortType = response.selectedType;
		this.data.quoteReportSortByFlipFlop = response.direction;
		this.emitChange();
	},

	quoteReportUpdateFilterCount: function (response) {
		this.data.QuoteReportNumQuotesToShow = response;
		this.emitChange();
	},

	quoteReportUpdateFilterDate: function (response) {
		this.data.quoteReportSelectedFilterDate = response;
		this.emitChange();
	},

	quoteReportUpdateSearchInput: function (response) {
		// console.trace()
		this.data.quoteReportSelectedFilterSearchInput = response;
		this.emitChange();
	},

	pricingAnaylsisToggle: function() {
		this.data.showPricingAnalysis = !this.data.showPricingAnalysis;
    this.emitChange();
	},

	pricingIncentiveToggle: function() {
		this.data.showPricingIncentive = !this.data.showPricingIncentive;
    this.emitChange();
	},

	quoteReportRequestOpen: function (payload) {
		this.data.showQuoteReport = true;
    if (payload.openLeads) {
    	this.data.showLeadReport = true;
      this.data.quoteReportType = LEAD_REPORT;
    } else if (payload.openOrders) {
    	this.data.quoteReportType = ORDER_REPORT;
    } else {
    	this.data.quoteReportType = QUOTE_REPORT;
    }
		this.emitChange();
	},

	quoteReportRequestClose: function () {
		this.data.showQuoteReport = false;
		this.data.showLeadReport = false;
		this.data.quoteList = Immutable.List.of();
		this.data.leadList = Immutable.List.of();
		this.data.QuoteReportNumQuotesToShow = 50;
		this.data.quoteReportSelectedFilterDate = filter_4M;
		this.data.quoteReportSelectedFilterSearchInput = null;
		this.data.quoteReportSelectedSortType = sort_DATE;
		this.data.quoteReportSortByFlipFlop = sort_DESC;
		this.emitChange();
	},

  orderReportRequestOpen: function (loadOrderReport) {
    this.data.quoteReportSortByFlipFlop = sort_ASC;
    this.data.showOrderReport = true;
    if (loadOrderReport) this.data.quoteReportType = ORDER_REPORT;
    else this.data.quoteReportType = QUOTE_REPORT;
    this.emitChange();
  },

  orderReportRequestClose: function () {
    this.data.showOrderReport = false;
    this.data.quoteList = Immutable.List.of();
    this.data.leadList = Immutable.List.of();
    this.data.QuoteReportNumQuotesToShow = 50;
    this.data.quoteReportSelectedFilterDate = filter_4M;
    this.data.quoteReportSelectedFilterSearchInput = null;
    this.data.quoteReportSelectedSortType = sort_DATE;
    this.data.quoteReportSortByFlipFlop = sort_DESC;
    this.emitChange();
  },

	loadNationalPartStock: function (payload) {
		this.data.nationalPartStockInfo = payload.nationalStockTable;
		this.data.showNationalPartStockModal = true;
		this.emitChange();
	},

	closeNationalPartStockModal: function () {
		this.data.showNationalPartStockModal = false;
		this.emitChange();
	},

	loadNationalPartPricing: function (payload) {
		this.data.nationalPartPriceInfo = payload.nationalPriceTable;
		this.data.showNationalPartPricingModal = true;
		this.emitChange();
	},

	closeNationalPartPricingModal: function () {
		this.data.showNationalPartPricingModal = false;
		this.emitChange();
	},

	isApiCallInProgress: function () {
		return this.data.apiCallInProgress;
	},

	getCmsAccountCreationLink: function () {
		return window.cmsBaseUrl + '/authentication/index.php?showForm=confirm';
	},

	getCmsLoginLink: function () {
		var token = getToken();
		if (token) {
			return window.cmsBaseUrl + '/authentication/sso/retailSite.php?tokenId=' + token.tokenId;
		} else {
			return '';
		}
	},

	loginHasExpired: function() {
		if (!this.data.userLoggedOut) this.data.loginHasExpired = true;
		this.data.showAuthenticationStateModal = false;
		this.emitChange()
	},

	resetLoginHasExpired: function() {
		this.data.loginHasExpired = false;
		this.data.showAuthenticationStateModal = false;
		this.emitChange()
	}
});

export var applicationStore = new ApplicationStore();

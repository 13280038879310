import { applicationActions } from "../../actions/ApplicationActions.js";
import { applicationStore } from "../../stores/ApplicationStore.js";

import { EmailField } from "../ux/forms/EmailField.jsx";
import { PasswordField } from "../ux/forms/PasswordField.jsx";
import { Image } from "../ux/Image.jsx";
import { Modal } from "../ux/Modal.jsx";

import { isEmpty, sizeOf, validateEmail } from "../../service/UtilityService";
import { getLoginData } from "../../service/LoginService";
import { isUserLoggedIn } from "../../api/AuthService";
import { connect } from "react-redux";
import {
  forgotPasswordToggle,
  toggleLoginDialog,
} from "../../redux/features/applicationInfo.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");

class LoginDialog extends I18nComponent {
  static defaultProps = {
    failure: false,
  };

  state = {
    email: {
      value: null,
      touched: false,
      forceTouch: false,
      valid: false,
    },
    password: {
      value: null,
      touched: false,
      forceTouch: false,
      valid: false,
    },
    rememberMe: false,
    createDealerAccountModal: this.props.createDealerAccountModal,
    createCustomerAccountModal: this.props.loginTextDialogShown,
    dealerAccountStatus: null,
    customerAccountStatus: null,
    dealerUrlCodeEmptyError: false,
    firstNameEmptyError: false,
    lastNameEmptyError: false,
    emailError: false,
    emailEmptyError: false,
    passwordError: false,
    passwordEmptyError: false,
    passwordConfirmError: false,
    passwordConfirmEmptyError: false,
  };

  updateState = (state) => {
    this.setState(state);
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.createDealerAccountModal &&
      !prevProps.createDealerAccountModal
    ) {
      this.setState({
        createCustomerAccountModal: this.props.createDealerAccountModal,
      });
    }
  }

  componentDidMount() {
    var login = getLoginData();
    if (!login.error && !this.state.createCustomerAccountModal) {
      document.getElementById("userEmailField").value = login.usr;
      document.getElementById("passwordFieldLogin").value = login.pwd;
      this.setState({
        email: {
          value: login.usr,
          touched: true,
          forceTouch: false,
          valid: true,
        },
        password: {
          value: login.pwd,
          touched: true,
          forceTouch: false,
          valid: true,
        },
      });
    }
  }

  render() {
    if (this.state.createDealerAccountModal) {
      return this.renderDealerAccountModal();
    } else if (this.state.createCustomerAccountModal) {
      return this.renderCustomerAccountModal();
    } else {
      return this.renderLoginForm();
    }
  }

  renderLoginForm = () => {
    return (
      <div className="login-form">
        {applicationStore.data.dealerEnableCustomerLogin ? (
          <container className="top small">
            <div className="text-small">
              <a onClick={this.toggleCreateCustomerAccount}>
                {this.t("createAccountForPricing")}
              </a>
            </div>
          </container>
        ) : (
          false
        )}
        <form onSubmit={this.onFormSubmit}>
          {this.renderCreateAccount()}
          <EmailField
            label={this.t("loginDialog.email")}
            touched={this.state.email.forceTouch}
            onChange={this.onSetUsername}
            id="userEmailField"
            className="userEmailField"
            errorNotEmpty={this.t("validation.notEmpty")}
            errorInvalidEmail={this.t("validation.email")}
          />
          <label htmlFor="passwordField">
            {this.t("loginDialog.password")}
          </label>
          <div className="have-forgotten-password text-small">
            (
            <a onClick={this.onForgotPassword}>
              {this.t("forgotPassword.forgotPassword")}
            </a>
            )
          </div>
          <PasswordField
            ref="passwordField"
            touched={this.state.password.forceTouch}
            onChange={this.onSetPassword}
            id="passwordFieldLogin"
            className="passwordFieldLogin"
            required={true}
            errorNotEmpty={this.t("validation.notEmpty")}
          />
          <div className="pair">
            <label htmlFor="rememberMeField">
              {this.t("loginDialog.rememberMe")}
            </label>
            <input
              id="rememberMeField"
              type="checkbox"
              value={this.state.rememberMe}
              onChange={this.onChangeRememberMe}
            />
          </div>
          {applicationStore.data.dealerEnableGuestAccountConfirmation ? (
            <div className="existing-user-registration text-small">
              <a href={applicationStore.getCmsAccountCreationLink()}>
                {this.t("forgotPassword.exisitingUserRegistration")}
              </a>
            </div>
          ) : (
            false
          )}
          <container className="top">
            <button type="submit" className="btn">
              {this.t("loginDialog.signIn")}
            </button>
          </container>
        </form>
        {this.props.failure ? (
          <div className="text-danger" style={{ color: "red" }}>
            {this.t("loginDialog.loginHasFailed")}
          </div>
        ) : (
          false
        )}
        {this.renderCloseButton()}
      </div>
    );
  };

  renderCreateAccount = () => {
    if (applicationStore.data.allowGuestAccountCreation) {
      return (
        <div className="dont-have-email text-small">
          (
          <a onClick={this.toggleCreateDealerAccount}>
            {this.t("guestAccountCreation.noEmail")}
          </a>
          )
        </div>
      );
    } else {
      return false;
    }
  };

  renderCloseButton = () => {
    if (applicationStore.data.enableMercedesFeatures) {
      return (
        <div onClick={this.onClickClose}>
          <Image
            className="close-x"
            src="/static/img/mercedes/close.png"
            alt="Icon Close"
          />
        </div>
      );
    } else if (applicationStore.data.enableSubaruFeatures) {
      return (
        <div onClick={this.onClickClose}>
          <Image
            className="close-x"
            src="/static/img/subaru/close.png"
            alt="Icon Close"
          />
        </div>
      );
    } else if (applicationStore.data.enableToyotaFeatures) {
      return (
        <div onClick={this.onClickClose}>
          <Image
            className="close-x"
            src="/static/img/toyota/close.png"
            alt="Icon Close"
          />
        </div>
      );
    } else {
      return (
        <div onClick={this.onClickClose}>
          <Image
            className="close-x"
            src="/static/img/close.png"
            alt="Icon Close"
          />
        </div>
      );
    }
  };

  renderDealerAccountModal = () => {
    if (this.state.createDealerAccountModal) {
      var updateState = this.updateState;
      return (
        <Modal refactor={true} requestUnmount={this.toggleCreateDealerAccount}>
          <div className="wrapper">
            <div className="modal-container">
              <box className="customer-create-account">
                <container className="large">
                  <box>
                    <container className="top large">
                      <box>
                        <text className="bold">
                          {this.t("guestAccountCreation.createGuestAccount")}
                        </text>
                      </box>
                    </container>
                  </box>

                  {this.renderSubmitMessage()}

                  <box>
                    <container className="top">
                      <box>
                        <column>
                          <text>
                            {this.t("guestAccountCreation.dealerUrlCode")}
                            {":"}
                          </text>
                        </column>
                        <right>
                          <input
                            id="dealer-url-code"
                            onFocus={updateState.bind(null, {
                              dealerUrlCodeEmptyError: false,
                            })}
                          />
                          {this.renderInputError("dealerurlcode")}
                        </right>
                      </box>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <box>
                        <column>
                          <text>
                            {this.t("wishlist.modal.email")}
                            {":"}
                          </text>
                        </column>
                        <right>
                          <input
                            id="dealer-email"
                            name="email"
                            onFocus={updateState.bind(null, {
                              emailError: false,
                              emailEmptyError: false,
                            })}
                          />
                          {this.renderInputError("email")}
                        </right>
                      </box>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <box>
                        <column>
                          <text>
                            {this.t("wishlist.modal.password")}
                            {":"}
                          </text>
                        </column>
                        <right>
                          <input
                            id="dealer-password"
                            type="password"
                            name="password"
                            onFocus={updateState.bind(null, {
                              passwordError: false,
                              passwordEmptyError: false,
                            })}
                          />
                          {this.renderInputError("password")}
                        </right>
                      </box>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <box>
                        <column>
                          <text>
                            {this.t("wishlist.modal.passwordConfirm")}
                            {":"}
                          </text>
                        </column>
                        <right>
                          <input
                            id="dealer-password-confirm"
                            type="password"
                            name="password"
                            onFocus={updateState.bind(null, {
                              passwordConfirmError: false,
                              passwordConfirmEmptyError: false,
                            })}
                          />
                          {this.renderInputError("passwordconfirm")}
                        </right>
                      </box>
                    </container>
                  </box>

                  {this.renderSubmit()}
                </container>
              </box>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  renderCustomerAccountModal = () => {
    if (this.state.createCustomerAccountModal) {
      var updateState = this.updateState;
      return (
        <Modal
          refactor={true}
          requestUnmount={this.toggleCreateCustomerAccount}
        >
          <div className="wrapper">
            <div className="modal-container">
              <box className="customer-create-account">
                <container className="large">
                  <box>
                    <container className="top large">
                      <box>
                        <text className="bold">{this.t("createAccount")}</text>
                      </box>
                    </container>
                  </box>

                  {this.renderSubmitMessage()}

                  <box>
                    <container className="top">
                      <box>
                        <column>
                          <text>
                            {this.t("wishlist.modal.firstName")}
                            {":"}
                          </text>
                        </column>
                        <right>
                          <input
                            id="customer-first-name"
                            name="fname"
                            onFocus={updateState.bind(null, {
                              firstNameEmptyError: false,
                            })}
                          />
                          {this.renderInputError("firstname")}
                        </right>
                      </box>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <box>
                        <column>
                          <text>
                            {this.t("wishlist.modal.lastName")}
                            {":"}
                          </text>
                        </column>
                        <right>
                          <input
                            id="customer-last-name"
                            name="lname"
                            onFocus={updateState.bind(null, {
                              lastNameEmptyError: false,
                            })}
                          />
                          {this.renderInputError("lastname")}
                        </right>
                      </box>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <box>
                        <column>
                          <text>
                            {this.t("wishlist.modal.email")}
                            {":"}
                          </text>
                        </column>
                        <right>
                          <input
                            id="customer-email"
                            name="email"
                            onFocus={updateState.bind(null, {
                              emailError: false,
                              emailEmptyError: false,
                            })}
                          />
                          {this.renderInputError("email")}
                        </right>
                      </box>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <box>
                        <column>
                          <text>
                            {this.t("wishlist.modal.password")}
                            {":"}
                          </text>
                        </column>
                        <right>
                          <input
                            id="customer-password"
                            type="password"
                            name="password"
                            onFocus={updateState.bind(null, {
                              passwordError: false,
                              passwordEmptyError: false,
                            })}
                          />
                          {this.renderInputError("password")}
                        </right>
                      </box>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <box>
                        <column>
                          <text>
                            {this.t("wishlist.modal.passwordConfirm")}
                            {":"}
                          </text>
                        </column>
                        <right>
                          <input
                            id="customer-password-confirm"
                            type="password"
                            name="password"
                            onFocus={updateState.bind(null, {
                              passwordConfirmError: false,
                              passwordConfirmEmptyError: false,
                            })}
                          />
                          {this.renderInputError("passwordconfirm")}
                        </right>
                      </box>
                    </container>
                  </box>

                  {this.renderSubmit()}
                </container>
              </box>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  renderSubmit = () => {
    var status = this.state.createDealerAccountModal
      ? this.state.dealerAccountStatus
      : this.state.customerAccountStatus;
    switch (status) {
      case null:
        return (
          <box>
            <container className="top large">
              <box className="button-box">
                <button
                  className="btn"
                  onClick={
                    this.state.createDealerAccountModal
                      ? this.submitDealerAccountCreation
                      : this.submitCustomerAccountCreation
                  }
                >
                  {this.t("wishlist.modal.submit")}
                </button>
              </box>
            </container>
          </box>
        );
      case 2:
        return (
          <box>
            <container className="top large">
              <box className="button-box">
                <button
                  className="btn"
                  onClick={
                    this.state.createDealerAccountModal
                      ? this.submitDealerAccountCreation
                      : this.submitCustomerAccountCreation
                  }
                >
                  {this.t("wishlist.modal.retry")}
                </button>
              </box>
            </container>
          </box>
        );
      default:
        return (
          <box>
            <container className="top large"></container>
          </box>
        );
    }
  };

  renderSubmitMessage = () => {
    var status = this.state.createDealerAccountModal
      ? this.state.dealerAccountStatus
      : this.state.customerAccountStatus;
    switch (status) {
      case 0:
        return (
          <box className="loading-box">
            <container className="top large">
              <box>
                <container className="side">
                  <box>
                    <text>
                      {this.t("wishlist.modal.sendingData") +
                        window.loadingSuffix}
                    </text>
                  </box>
                </container>
              </box>
            </container>
          </box>
        );
      case 1:
        return (
          <box className="success-box">
            <container className="top large">
              <box>
                <container className="side">
                  <box>
                    <i className="fa fa-check"></i>
                    <text>{this.t("wishlist.modal.accountCreated")}</text>
                  </box>
                </container>
              </box>
            </container>
          </box>
        );
      case 2:
        return (
          <box className="error-box">
            <container className="top large">
              <box>
                <container className="side">
                  <box>
                    <i className="fa fa-close"></i>
                    <text>{this.t("wishlist.modal.accountFailed")}</text>
                  </box>
                </container>
              </box>
            </container>
          </box>
        );
      default:
        return (
          <box>
            <container className="top small">
              <a
                onClick={
                  this.state.createDealerAccountModal
                    ? this.toggleCreateDealerAccount
                    : this.toggleCreateCustomerAccount
                }
                className="text-small"
              >
                {this.t("wishlist.modal.accountExists")}
              </a>
            </container>
          </box>
        );
    }
  };

  renderInputError = (index) => {
    switch (index) {
      case "dealerurlcode":
        if (this.state.dealerUrlCodeEmptyError)
          return (
            <text className="error">
              {this.t("wishlist.modal.errorNoDealerUrlCode")}
            </text>
          );
        else return false;
      case "firstname":
        if (this.state.firstNameEmptyError)
          return (
            <text className="error">
              {this.t("wishlist.modal.errorNoFirstName")}
            </text>
          );
        else return false;
      case "lastname":
        if (this.state.lastNameEmptyError)
          return (
            <text className="error">
              {this.t("wishlist.modal.errorNoLastName")}
            </text>
          );
        else return false;
      case "email":
        if (this.state.emailError)
          return (
            <text className="error">
              {this.t("wishlist.modal.invalidEmail")}
            </text>
          );
        else if (this.state.emailEmptyError)
          return (
            <text className="error">
              {this.t("wishlist.modal.errorNoEmail")}
            </text>
          );
        else return false;
      case "password":
        if (this.state.passwordError)
          return (
            <text className="error">
              {this.t("wishlist.modal.invalidPassword")}
            </text>
          );
        else if (this.state.passwordEmptyError)
          return (
            <text className="error">
              {this.t("wishlist.modal.errorNoPassword")}
            </text>
          );
        else return false;
      case "passwordconfirm":
        if (this.state.passwordConfirmError)
          return (
            <text className="error">
              {this.t("wishlist.modal.invalidPasswordConfirm")}
            </text>
          );
        else if (this.state.passwordConfirmEmptyError)
          return (
            <text className="error">
              {this.t("wishlist.modal.errorNoPasswordConfirm")}
            </text>
          );
        else return false;
      default:
        return false;
    }
  };

  submitDealerAccountCreation = () => {
    var dealerurlcode,
      email,
      password,
      passwordconfirm,
      error = false;

    dealerurlcode = document.getElementById("dealer-url-code")
      ? document.getElementById("dealer-url-code").value
      : "";
    email = document.getElementById("dealer-email")
      ? document.getElementById("dealer-email").value
      : "";
    password = document.getElementById("dealer-password")
      ? document.getElementById("dealer-password").value
      : "";
    passwordconfirm = document.getElementById("dealer-password-confirm")
      ? document.getElementById("dealer-password-confirm").value
      : "";

    if (sizeOf(dealerurlcode) == 0) {
      error = true;
      this.setState({
        dealerUrlCodeEmptyError: true,
      });
    }

    if (sizeOf(email) == 0) {
      error = true;
      this.setState({
        emailEmptyError: true,
      });
    } else if (!validateEmail(email)) {
      error = true;
      this.setState({
        emailError: true,
      });
    }

    if (sizeOf(password) == 0) {
      error = true;
      this.setState({
        passwordEmptyError: true,
      });
    } else if (sizeOf(password) < 4) {
      error = true;
      this.setState({
        passwordError: true,
      });
    }

    if (sizeOf(passwordconfirm) == 0) {
      error = true;
      this.setState({
        passwordConfirmEmptyError: true,
      });
    } else if (password != passwordconfirm) {
      error = true;
      this.setState({
        passwordConfirmError: true,
      });
    }

    if (!error) {
      var updateState = this.updateState;
      applicationActions.submitGuestAccountCreation(
        dealerurlcode,
        email,
        password,
        passwordconfirm,
        function (result) {
          updateState({ dealerAccountStatus: result });
        }
      );
    } else {
      this.setState({
        dealerAccountStatus: null,
      });
    }
  };

  submitCustomerAccountCreation = () => {
    var firstname,
      lastname,
      email,
      password,
      passwordconfirm,
      error = false;

    firstname = document.getElementById("customer-first-name")
      ? document.getElementById("customer-first-name").value
      : "";
    lastname = document.getElementById("customer-last-name")
      ? document.getElementById("customer-last-name").value
      : "";
    email = document.getElementById("customer-email")
      ? document.getElementById("customer-email").value
      : "";
    password = document.getElementById("customer-password")
      ? document.getElementById("customer-password").value
      : "";
    passwordconfirm = document.getElementById("customer-password-confirm")
      ? document.getElementById("customer-password-confirm").value
      : "";

    if (sizeOf(firstname) == 0) {
      error = true;
      this.setState({
        firstNameEmptyError: true,
      });
    }

    if (sizeOf(lastname) == 0) {
      error = true;
      this.setState({
        lastNameEmptyError: true,
      });
    }

    if (sizeOf(email) == 0) {
      error = true;
      this.setState({
        emailEmptyError: true,
      });
    } else if (!validateEmail(email)) {
      error = true;
      this.setState({
        emailError: true,
      });
    }

    if (sizeOf(password) == 0) {
      error = true;
      this.setState({
        passwordEmptyError: true,
      });
    } else if (sizeOf(password) < 4) {
      error = true;
      this.setState({
        passwordError: true,
      });
    }

    if (sizeOf(passwordconfirm) == 0) {
      error = true;
      this.setState({
        passwordConfirmEmptyError: true,
      });
    } else if (password != passwordconfirm) {
      error = true;
      this.setState({
        passwordConfirmError: true,
      });
    }

    if (!error) {
      var updateState = this.updateState;
      applicationActions.submitCustomerAccountCreation(
        firstname,
        lastname,
        email,
        password,
        passwordconfirm,
        function (result) {
          updateState({ customerAccountStatus: result });
        }
      );
    } else {
      this.setState({
        customerAccountStatus: null,
      });
    }
  };

  onSetUsername = (email) => {
    this.setState({
      email: {
        value: email,
        touched: this.state.email.touched,
        forceTouch: this.state.email.forceTouch,
        valid: this.state.email.valid,
      },
    });
  };

  onSetPassword = (password) => {
    this.setState({
      password: {
        value: password,
        touched: this.state.password.touched,
        forceTouch: this.state.password.forceTouch,
        valid: this.state.password.valid,
      },
    });
  };

  onClickClose = () => {
    this.props.dispatch(toggleLoginDialog(false));
    // applicationActions.closeLoginTextDialog();
  };

  onCreateGuestAccount = () => {
    applicationActions.openGuestAccountCreationModal();
  };

  toggleCreateDealerAccount = () => {
    this.setState({
      createDealerAccountModal: !this.state.createDealerAccountModal,
      dealerUrlCodeEmptyError: false,
      firstNameEmptyError: false,
      lastNameEmptyError: false,
      emailError: false,
      emailEmptyError: false,
      passwordError: false,
      passwordEmptyError: false,
      passwordConfirmError: false,
      passwordConfirmEmptyError: false,
    });
  };

  toggleCreateCustomerAccount = () => {
    if (isUserLoggedIn(true)) {
      console.log("we hit here for the logginIn");
      this.props.dispatch(toggleLoginDialog(false));
      this.onClickClose();
    } else {
      this.setState({
        createCustomerAccountModal: !this.state.createCustomerAccountModal,
        dealerUrlCodeEmptyError: false,
        firstNameEmptyError: false,
        lastNameEmptyError: false,
        emailError: false,
        emailEmptyError: false,
        passwordError: false,
        passwordEmptyError: false,
        passwordConfirmError: false,
        passwordConfirmEmptyError: false,
      });
    }
  };

  onForgotPassword = () => {
    this.props.dispatch(forgotPasswordToggle(true));
  };

  onChangeRememberMe = () => {
    this.setState({
      rememberMe: !this.state.rememberMe,
    });
  };

  onFormSubmit = (ev) => {
    var userEmailField = "",
      passwordFieldLogin = "";

    if (document.getElementById("userEmailField"))
      userEmailField = document.getElementById("userEmailField").value;
    if (
      isEmpty(userEmailField) &&
      document.getElementsByClassName("userEmailField")
    )
      userEmailField =
        document.getElementsByClassName("userEmailField")[0].value;
    if (isEmpty(userEmailField) && this.state.email.value)
      userEmailField = this.state.email.value;

    if (document.getElementById("passwordFieldLogin"))
      passwordFieldLogin = document.getElementById("passwordFieldLogin").value;
    if (
      isEmpty(passwordFieldLogin) &&
      document.getElementsByClassName("passwordFieldLogin")
    )
      passwordFieldLogin =
        document.getElementsByClassName("passwordFieldLogin")[0].value;
    if (isEmpty(passwordFieldLogin) && this.state.password.value)
      passwordFieldLogin = this.state.password.value;
    // this.props.dispatch(login({username:userEmailField,password:passwordFieldLogin,rememberMe:this.state.rememberMe}))
    applicationActions.login(
      userEmailField,
      passwordFieldLogin,
      this.state.rememberMe
    );

    ev.preventDefault();
    ev.stopPropagation();
  };
}

function mapStateToProps(state) {
  return {
    loginVisible: state.info.loginDialog,
    failure: state.login.loginFailure,
    loginTextDialogShown: state.info.loginTextDialogShown,
    createDealerAccountModal: state.productList.createDealerAccountModal,
  };
}
export default connect(mapStateToProps)(LoginDialog);

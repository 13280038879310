import { applicationStore } from "../../stores/ApplicationStore.js";
import { applicationActions } from "../../actions/ApplicationActions.js";
import { ProductTypes } from "../../constants/ProductTypes.js";
import { Image } from "../ux/Image.jsx";

import {
  getTabIndex,
  getTrackingEventCategory,
  getTrackingEventObject,
  scrollTop,
} from "../../service/UtilityService";
import { inivisCaptchaOnComponentDidUpdate } from "../../service/CaptchaService";
import { connect } from "react-redux";
import { searchProductType } from "../../redux/features/searchTires";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var _ = require("lodash");
var Cx = require("classnames");

class ProductTypeTile extends I18nComponent {
  state = {
    productTypes: this.props.productType,
  };

  updateState = (state) => {
    this.setState(state);
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
  }

  componentDidUpdate(nextProps, lastState) {
    var callbackList = [];
    for (var i in this.state.productTypes) {
      switch (this.state.productTypes[i]) {
        case ProductTypes.TIRES:
          callbackList.push(this.clickTires);
          break;
        case ProductTypes.ALLOY_WHEELS:
          callbackList.push(this.clickAlloyWheels);
          break;
        case ProductTypes.ACCESSORIES:
          callbackList.push(this.clickAccessories);
          break;
        case ProductTypes.PACKAGES:
          callbackList.push(this.clickPackages);
          break;
      }
    }

    inivisCaptchaOnComponentDidUpdate(
      this.state,
      this.updateState,
      callbackList
    );
  }

  render() {
    var floatChecker = null;

    var productTypes = this.state.productTypes;
    if (applicationStore.data.enableMercedesFeatures) {
      productTypes = [];
      if (this.state.productTypes.indexOf(ProductTypes.PACKAGES) > -1)
        productTypes.push(ProductTypes.PACKAGES);
      if (this.state.productTypes.indexOf(ProductTypes.TIRES) > -1)
        productTypes.push(ProductTypes.TIRES);
      if (this.state.productTypes.indexOf(ProductTypes.ALLOY_WHEELS) > -1)
        productTypes.push(ProductTypes.ALLOY_WHEELS);
      if (this.state.productTypes.indexOf(ProductTypes.ACCESSORIES) > -1)
        productTypes.push(ProductTypes.ACCESSORIES);
    }

    var content = _.map(
      productTypes,
      function (productType, index) {
        switch (productType) {
          case ProductTypes.TIRES:
            floatChecker = this.checkForWhereToFloat(floatChecker);

            return (
              <li
                className={"show-focus" + floatChecker.background}
                role="button"
                tabIndex={getTabIndex()}
                key={"homepage-select-" + productType}
                onClick={this.clickTires}
                onKeyDown={(e) => this.selectTires(e)}
              >
                <a alt={this.t("common.viewTires")}>
                  <Image
                    src={
                      "/static/img/" +
                      applicationStore.data.defaultTemplate +
                      "/product_selection_tires.png"
                    }
                    className={
                      "product-select-image" + floatChecker.imageClassName
                    }
                  />
                  <div
                    className={
                      "product-select-info" + floatChecker.textClassName
                    }
                  >
                    <h1 className="product-type-title">
                      {this.t("productTypes.searchTires")}
                    </h1>
                    <span className="product-type-text">
                      {this.t("productTypes.tireSelectText")}
                    </span>

                    <span className="btn">
                      {this.t("productTypes.searchTires")}
                    </span>
                  </div>
                </a>
              </li>
            );

          case ProductTypes.ALLOY_WHEELS:
            floatChecker = this.checkForWhereToFloat(floatChecker);

            return (
              <li
                className={"show-focus" + floatChecker.background}
                role="button"
                tabIndex={getTabIndex()}
                key={"homepage-select-" + productType}
                onClick={this.clickAlloyWheels}
                onKeyDown={(e) => this.selectAlloyWheels(e)}
              >
                <a alt={this.t("common.viewWheels")}>
                  <Image
                    src={
                      "/static/img/" +
                      applicationStore.data.defaultTemplate +
                      "/product_selection_wheels.png"
                    }
                    className={
                      "product-select-image" + floatChecker.imageClassName
                    }
                  />
                  <div
                    className={
                      "product-select-info" + floatChecker.textClassName
                    }
                  >
                    <h1 className="product-type-title">
                      {this.t("productTypes.searchWheels")}
                    </h1>
                    <span className="product-type-text">
                      {this.t("productTypes.wheelSelectText")}
                    </span>

                    <span className="btn">
                      {this.t("productTypes.searchWheels")}
                    </span>
                  </div>
                </a>
              </li>
            );

          case ProductTypes.ACCESSORIES:
            floatChecker = this.checkForWhereToFloat(floatChecker);

            return (
              <li
                className={"show-focus" + floatChecker.background}
                role="button"
                tabIndex={getTabIndex()}
                key={"homepage-select-" + productType}
                onClick={this.clickAccessories}
                onKeyDown={(e) => this.selectAccessories(e)}
              >
                <a alt={this.t("common.viewAccessories")}>
                  <Image
                    src={
                      "/static/img/" +
                      applicationStore.data.defaultTemplate +
                      "/product_selection_accessories.png"
                    }
                    className={
                      "product-select-image" + floatChecker.imageClassName
                    }
                  />
                  <div
                    className={
                      "product-select-info" + floatChecker.textClassName
                    }
                  >
                    <h1 className="product-type-title">
                      {this.t("productTypes.searchAccessories")}
                    </h1>
                    <span className="product-type-text">
                      {this.t("productTypes.accessorySelectText")}
                    </span>

                    <span className="btn">
                      {this.t("productTypes.searchAccessories")}
                    </span>
                  </div>
                </a>
              </li>
            );

          case ProductTypes.PACKAGES:
            floatChecker = this.checkForWhereToFloat(floatChecker);

            return (
              <li
                className={"show-focus" + floatChecker.background}
                role="button"
                tabIndex={getTabIndex()}
                key={"homepage-select-" + productType}
                onClick={this.clickPackages}
                onKeyDown={(e) => this.selectPackages(e)}
              >
                <a alt={this.t("common.viewPackages")}>
                  <Image
                    src={
                      "/static/img/" +
                      applicationStore.data.defaultTemplate +
                      "/product_selection_packages.png"
                    }
                    className={
                      "product-select-image" + floatChecker.imageClassName
                    }
                  />
                  <div
                    className={
                      "product-select-info" + floatChecker.textClassName
                    }
                  >
                    <h1 className="product-type-title">
                      {this.t("productTypes.searchPackages")}
                    </h1>
                    <span className="product-type-text">
                      {this.t("productTypes.packageSelectText")}
                    </span>

                    <span className="btn">
                      {this.t("productTypes.searchPackages")}
                    </span>
                  </div>
                </a>
              </li>
            );
        }
      },
      this
    );

    var productSelectorClass = Cx({
      "select-product-tile": true,
      "has-launcher": applicationStore.data.enableHomepageLauncher,
      "float-launcher":
        applicationStore.data.homepageLauncherFloatOverCarousel === "LEFT" ||
        applicationStore.data.homepageLauncherFloatOverCarousel === "RIGHT",
      "float-launcher locked": applicationStore.data.homepageLauncherLockToPage,
      "alternate-background":
        applicationStore.data.enablePaddedProductTileSelection,
      "has-padded-images":
        applicationStore.data.enablePaddedProductTileSelection,
    });

    return (
      <div className={productSelectorClass}>
        <ul>{content}</ul>
      </div>
    );
  }

  checkForWhereToFloat = (floatChecker) => {
    if (floatChecker === null) {
      return {
        flag: true,
        imageClassName: " float-left",
        textClassName: " float-right",
        background: " apply-color",
      };
    } else if (typeof floatChecker === "object" && floatChecker.flag) {
      return {
        flag: !floatChecker.flag,
        imageClassName: " float-right",
        textClassName: " float-left",
        background: "",
      };
    } else if (typeof floatChecker === "object") {
      return {
        flag: !floatChecker.flag,
        imageClassName: " float-left",
        textClassName: " float-right",
        background: " apply-color",
      };
    }
  };

  onChange = () => {
    this.setState({
      productTypes: this.props.productTypes,
    });
  };

  openLauncherYearSelection = () => {
    scrollTop();
    this.props.setYearSelect(true);
  };

  openLauncherOnSelect = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "audi":
      case "volkswagen":
      case "volkswagen_2020":
        return true; //isNationalSite();
      default:
        return false;
    }
  };

  clickTires = (token) => {
    console.log("we have clicked tires");
    if (!this.openLauncherOnSelect()) {
      console.log("we have clicked tires and we are not opening the launcher");
      if (!this.props.landingpageTile) {
        console.log(
          "we have clicked tires and we are not opening the launcher and we are not on the landing page"
        );
        if (!applicationStore.data.loadSearchModalFromProductTypeSelection) {
          console.log(
            "we have clicked tires and we are not opening the launcher and we are not on the landing page and we are not loading the search modal"
          );
          googleAnalyticsEnhancedTrackEvent(
            getTrackingEventCategory(),
            "Product Tile Selection - ProductSelected",
            getTrackingEventObject({ type: ProductTypes.TIRES })
          );
          this.props.selectProductType(ProductTypes.TIRES);
          // applicationActions.productTypeSelected(ProductTypes.TIRES);
        } else {
          console.log(
            "we have clicked tires and we are not opening the launcher and we are not on the landing page and we are loading the search modal"
          );
          applicationActions.activateSearchFlag();
        }
      }
    } else {
      this.openLauncherYearSelection();
    }
  };

  clickAlloyWheels = (token) => {
    if (!this.openLauncherOnSelect()) {
      if (!this.props.landingpageTile) {
        if (!applicationStore.data.loadSearchModalFromProductTypeSelection) {
          googleAnalyticsEnhancedTrackEvent(
            getTrackingEventCategory(),
            "Product Tile Selection - ProductSelected",
            getTrackingEventObject({ type: ProductTypes.ALLOY_WHEELS })
          );
          applicationActions.productTypeSelected(ProductTypes.ALLOY_WHEELS);
        } else {
          applicationActions.activateSearchFlag();
        }
      }
    } else {
      this.openLauncherYearSelection();
    }
  };

  clickAccessories = (token) => {
    if (!this.openLauncherOnSelect()) {
      if (!this.props.landingpageTile) {
        if (!applicationStore.data.loadSearchModalFromProductTypeSelection) {
          googleAnalyticsEnhancedTrackEvent(
            getTrackingEventCategory(),
            "Product Tile Selection - ProductSelected",
            getTrackingEventObject({ type: ProductTypes.ACCESSORIES })
          );
          applicationActions.productTypeSelected(ProductTypes.ACCESSORIES);
        } else {
          applicationActions.activateSearchFlag();
        }
      }
    } else {
      this.openLauncherYearSelection();
    }
  };

  clickPackages = (token) => {
    if (!this.openLauncherOnSelect()) {
      if (!this.props.landingpageTile) {
        if (!applicationStore.data.loadSearchModalFromProductTypeSelection) {
          googleAnalyticsEnhancedTrackEvent(
            getTrackingEventCategory(),
            "Product Tile Selection - ProductSelected",
            getTrackingEventObject({ type: ProductTypes.PACKAGES })
          );
          applicationActions.productTypeSelected(ProductTypes.PACKAGES);
        } else {
          applicationActions.activateSearchFlag();
        }
      }
    } else {
      this.openLauncherYearSelection();
    }
  };

  // AODA: Navigate on Space or Enter keys
  selectTires = (e) => {
    if (e.keyCode == 13 || e.keyCode == 32) this.clickTires();
  };

  selectAlloyWheels = (e) => {
    if (e.keyCode == 13 || e.keyCode == 32) this.clickAlloyWheels();
  };

  selectAccessories = (e) => {
    if (e.keyCode == 13 || e.keyCode == 32) this.clickAccessories();
  };

  selectPackages = (e) => {
    if (e.keyCode == 13 || e.keyCode == 32) this.clickPackages();
  };
}

const mapStateToProps = function (store) {
  return {
    productType: store.tire.searchProductType,
  };
};
const mapDispatchToProps = function (dispatch) {
  // allow to dispatch selectProductType action
  return {
    selectProductType: function (productType) {
      dispatch(searchProductType(productType));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductTypeTile);

import { isEmpty } from "./UtilityService";

export const TEST_LIST = {
  SELECTBOX: {
    ARROW: "selectbox-arrow",
    OPTION: "selectbox-option",
    VALUE: "selectbox-value",
  },
  SELECTBOXSOURCE: {
    LAUNCHER: "launcher",
  },

  HEADER: {
    CART: "header-cart",
    LANGUAGE: "header-language",
    LOGIN: "header-login",
    LOGO: "header-logo",
    LOGOUT: "header-logout",
    SEARCH: "header-search",
    FINANCE: "header-finance",
    BOOKING: "header-booking",
  },
  NAVIGATION: {
    BACK: "navigation-back",
    SEARCH: "navigation-search",
    YEAR: "navigation-year",
    MANUFACTURER: "navigation-manufacturer",
    MODEL: "navigation-model",
    CATEGORY: "navigation-category",
    SUBMODEL: "navigation-submodel",
    PRODUCT: "navigation-product",
  },

  HOMEPAGE: {},
  LANDINGPAGE: {},

  LAUNCHER: {
    BUTTON: "launcher-button",
    INPUT: "launcher-input",
    TAB: "launcher-tab",
  },

  MODAL: {
    CAPTCHA: "modal-captcha",
  },

  PRODUCT_LIST: {
    MOBILE: {
      FILTER: {
        TITLE: "productliat-mobile-filter-title",
        TOGGLEARROW: "productliat-mobile-filter-togglearrow",
      },
      PRODUCT_TILE: {},
    },
    FILTER: {
      SEARCH: {
        ACCESSORY: {
          INPUT: "productlist-filter-search-accessory-input",
          TITLE: "productlist-filter-search-accessory-title",
          SELECT: {
            GRID: "productlist-filter-search-accessory-select-grid",
            TILE: "productlist-filter-search-accessory-select-tile",
          },
        },
        BUTTON: "productlist-filter-search-button",
      },
      SECTION: {
        TITLE: "productlist-filter-section-title",
        TOGGLEARROW: "productlist-filter-section-arrowtoggle",
        SELECTALL: "productlist-filter-section-selectall",
        DESELECTALL: "productlist-filter-section-deselectall",
        SELECT: "productlist-filter-section-select",
      },
      CAROUSEL: {
        BUTTON: "productlist-filter-carousel-button",
        IMAGE: "productlist-filter-carousel-image",
      },
    },
    PRODUCT_TILE: {
      IMAGE: {},
      INFO: {
        PARTNUMBER: "",
      },
      PRICING: {
        QUANTITY: {
          SELECTBOX: "productlist-producttile-pricing-quantity-selectbox",
          OPTIONSELECT: "productlist-producttile-pricing-quantity-optionselect",
        },
        BUTTON: "productlist-producttile-pricing-button",
      },
    },
  },
  PRODUCT: {
    REQUIRED_ITEM: {
      BUTTON: "product-requireditem-button",
    },
    BUTTON: "product-button",
  },
};

export const getModifiedTestId = (originalId, testId, suf) => {
  return originalId + " " + getTestId(testId, suf);
};

export const getTestId = (id, suf) => {
  switch (window.environment) {
    case "DEV":
    case "QA":
    case "STAGE":
    case "TRY":
    case "TIGHTEN":
    case "LARAVEL":
    case "PRODUCTION":
      var temp = "";
      if (!isEmpty(suf)) {
        temp = suf.toString();
        temp = temp.toLowerCase();
        temp = temp.replaceAll(" ", "_");
      }

      return isEmpty(temp) ? id : id + "-" + temp;

    default:
      return "";
  }
};

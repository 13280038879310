import { applicationActions } from "../actions/ApplicationActions";
import { applicationStore } from "../stores/ApplicationStore";

import { dealerSelectionActions } from "../actions/DealerSelectionActions";
import { dealerSelectionStore } from "../stores/DealerSelectionStore";

import { ComboBox } from "../components/ux/forms/ComboBox.jsx";
import { ComboBoxValue } from "../components/ux/forms/ComboBoxValue.jsx";

import { ProductTypes } from "../constants/ProductTypes";
import { SearchTypes } from "../constants/SearchTypes";

import {
  sizeOf,
  isEmpty,
  getAppLanguage,
  isGroupSite,
  getTrackingEventCategory,
  getTrackingEventDealer,
  getProductTypeUpperCase,
  getProductTypeFromPartType,
} from "./UtilityService";
import store from "../stores/configureStore";
import { setDealerToken } from "../redux/features/dealer";
import { setDealerSelectionSearchTerm } from "../redux/features/dealerSections";

var React = require("react");
var _ = require("lodash");
var $ = require("jquery");

const DEALER_NAME_SEARCH = "dealer-name-search";
const DEALER_CITY_SEARCH = "dealer-city-search";
const DEALER_PROVINCE_SEARCH = "dealer-province-search";
const DEALER_POSTAL_CODE_SEARCH = "dealer-postal-code-search";

export const defaultMapLocation = () => {
  return { lat: 43.65, lng: -79.38 }; // Toronto, ON, CA
};

export const onSelectNationalMapDealer = (dealer) => {
  googleAnalyticsEnhancedTrackEvent(
    getTrackingEventCategory(),
    "Dealer Selection - DealerSelected",
    getTrackingEventDealer(dealer)
  );
  if (!isGroupSite()) {
    store.dispatch(setDealerToken(dealer));
    applicationActions.setSelectedDealerForToken(dealer);
    //setTimeout(() => { applicationActions.setSelectedDealerForToken(dealer); }, 100);
  } else {
    if (!dealer["isActive"] && dealer["inactiveDealerLink"] !== "") {
      setTimeout(() => {
        dealerSelectionActions.showLeavingSiteWarningModal(dealer);
      }, 100);
    } else {
      window.location.href =
        "/dealer/" +
        encodeURIComponent(dealer["urlCode"]) +
        "/" +
        getAppLanguage();

      //window.location.href = "/group/" + encodeURIComponent(applicationStore.data.groupUrlCode) + "/" + getAppLanguage() + "/dealer/" + dealer['id'];
    }
  }
};

export const updateChassisRedirect = (state, updateState) => {
  if (
    sizeOf(state.dealers) > 0 &&
    state.chassisRedirect &&
    !!state.chassisRedirect.chassisInfo &&
    !isEmpty(state.chassisRedirect.chassisInfo.chassis) &&
    !state.chassisRedirect.hasRunRedirect
  ) {
    updateState({
      chassisRedirect: {
        chassisInfo: state.chassisRedirect.chassisInfo,
        hasRunRedirect: true,
      },
    });

    var selectedDealer = null,
      chassisDealer = state.chassisRedirect.chassisInfo.dealer;
    state.dealers.map(function (dealer) {
      if (dealer["alias"] === chassisDealer) selectedDealer = dealer;
    });

    if (selectedDealer) {
      var link =
        window.location.origin +
        "/national/" +
        applicationStore.data.nationalUrlCode +
        "/" +
        state.chassisRedirect.chassisInfo.language +
        "/dealer/" +
        selectedDealer.get("id") +
        "/product/" +
        ProductTypes.TIRES +
        "/type/" +
        SearchTypes.SEARCH_BY_CHASSIS +
        "/chassis/" +
        state.chassisRedirect.chassisInfo.chassis;

      setTimeout(() => {
        window.location.href = link;
        //window.location.reload();
      }, 100);
    }
  }
};

export const setActiveDealer = (state, updateState, lastState, refs) => {
  if (state.setDealerAsActive && !lastState.setDealerAsActive) {
    var dealer = this.state.visibleDealers.get(0);
    refs["google-map"].onMarkerClickFromProps(dealer);
    updateState({ hasActiveDealer: true });
    //TODO:  activeMarkers
    setTimeout(() => {
      dealerSelectionActions.mapSetLocation(dealer["position"], 11);
    }, 100);
  }
};

export const setInitialSearch = (state, lastState) => {
  if (
    state.hasInitialLocationPostalCode &&
    sizeOf(state.dealers) > 0 &&
    sizeOf(lastState.dealers) === 0
  ) {
    const action = setDealerSelectionSearchTerm(
      state.hasInitialLocationPostalCode
    );
    store.dispatch(action);
    //setTimeout(() => { dealerSelectionActions.setDealerSelectionSearchTerm(state.hasInitialLocationPostalCode); }, 100);
  }
};

export const setDealerSearchType = (state, updateState) => {
  if (state.searchType === DEALER_NAME_SEARCH) {
    setTimeout(() => {
      if (
        document.getElementsByClassName(
          "dealer-filter-dealer-search-value-modal"
        ).length > 0
      ) {
        $(".dealer-filter-dealer-search-value-modal").on(
          "change paste keyup",
          function () {
            updateState({
              dealerNameSearchTerm: $(this).val(),
              enableDealerNameList: true,
              initializedMissingLocationModal: true,
            });
          }
        );
      }
    }, 100);
  }
};

export const setWidgetRedirect = (state, updateState, dealer) => {
  const { pathname, search } = window.location;
  const params = new URLSearchParams(search);
  const lowercaseParams = new URLSearchParams();

  for (const [key, value] of params.entries()) {
    lowercaseParams.append(key.toLowerCase(), value);
  }

  if (dealer && lowercaseParams.has("vehicleyear")) {
    const entries = [];
    const product = getProductTypeFromPartType(
      lowercaseParams.get("product") ||
        lowercaseParams.get("producttype") ||
        window.campaignData?.campaign?.campaignPartType
    );
    entries.push(["dealer", dealer.id]);
    entries.push(["product", product]);
    entries.push(["type", "vehicle-search-simple"]);
    entries.push(["year", lowercaseParams.get("vehicleyear")]);

    if (
      lowercaseParams.has("vehiclemanufacturername") &&
      lowercaseParams.has("vehiclemodelname")
    ) {
      entries.push(["make", lowercaseParams.get("vehiclemanufacturername")]);
      entries.push(["category", "null"]);
      entries.push(["model", lowercaseParams.get("vehiclemodelname")]);
    }

    const suffix = entries.map(([key, value]) => `${key}/${value}`).join("/");

    if (pathname.includes("marketing/national")) {
      const dealerPathname = `/${dealer.urlCode}/${getAppLanguage()}/${suffix}`;
      window.open(dealerPathname, "_self");
    } else {
      window.location = `${pathname}/${suffix}`;
    }
  }

  if (
    sizeOf(dealerSelectionStore.dealers) > 0 &&
    dealerSelectionStore.dealersFilteredFromPosition &&
    applicationStore.data.nationalEnableSkipMap &&
    !state.skipMapRedirect
  ) {
    /*
    console.log('setWidgetRedirect, nationalEnableSkipMap: '+applicationStore.data.nationalEnableSkipMap
      +', postal: '+applicationStore.data.postal
      +', dealerSearch: '+dealerSelectionStore.dealerSearch
      +', dealersFilteredFromPosition: '+dealerSelectionStore.dealersFilteredFromPosition
      +', sizeOf(dealers): '+sizeOf(dealerSelectionStore.dealers)
      +', widgetSearchActive: '+applicationStore.data.widgetSearchActive
      +', skipMapRedirect: '+state.skipMapRedirect
    );
    console.log(dealer);
    */

    updateState({ skipMapRedirect: true });

    onSelectNationalMapDealer(dealer);

    /*
    if (applicationStore.data.postal) {

      console.log('Result(select dealer)');

      onSelectNationalMapDealer(dealer);
    }
    */
  }
};

export const urlHasPostalRedirect = () => {
  //console.log('urlHasPostalRedirect, nationalEnableSkipMap: '+applicationStore.data.nationalEnableSkipMap);

  if (applicationStore.data.nationalEnableSkipMap) {
    var hash = window.location.search;

    if (hash.indexOf("vehicle=") > -1) {
      hash = hash.split("?")[1];
      hash = hash.split("=")[1];
      hash = hash.split("-");

      //console.log('urlHasPostalRedirect - check: '+(sizeOf(hash) === 7)+', size: '+sizeOf(hash));

      return sizeOf(hash) === 7;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getProvinceSelect = (
  countryCode,
  languageCode,
  onChange,
  currentProvince
) => {
  //<ComboBoxValue key="AS" value="AS">American Samoa</ComboBoxValue> //?
  //<ComboBoxValue key="MH" value="MH">Marshall Islands</ComboBoxValue> //?
  //<ComboBoxValue key="FM" value="FM">Micronesia</ComboBoxValue> //?
  //<ComboBoxValue key="NP" value="NP">Northern Marianas</ComboBoxValue> //?
  //<ComboBoxValue key="PW" value="PW">Palau</ComboBoxValue> //?
  //<ComboBoxValue key="PR" value="PR">Puerto Rico</ComboBoxValue> //?
  //<ComboBoxValue key="VI" value="VI">Virgin Islands</ComboBoxValue> //?

  switch (countryCode) {
    case "CA":
      switch (languageCode) {
        case "en":
          return (
            <ComboBox
              onChange={onChange}
              value={currentProvince}
              className="province-select"
              emptyText="--"
            >
              <ComboBoxValue key={null} value={null}>
                View All
              </ComboBoxValue>
              <ComboBoxValue key="AB" value="AB">
                Alberta
              </ComboBoxValue>
              <ComboBoxValue key="BC" value="BC">
                British Columbia
              </ComboBoxValue>
              <ComboBoxValue key="MB" value="MB">
                Manitoba
              </ComboBoxValue>
              <ComboBoxValue key="NB" value="NB">
                New Brunswick
              </ComboBoxValue>
              <ComboBoxValue key="NL" value="NL">
                Newfoundland and Labrador
              </ComboBoxValue>
              <ComboBoxValue key="NT" value="NT">
                Northwest Territories
              </ComboBoxValue>
              <ComboBoxValue key="NS" value="NS">
                Nova Scotia
              </ComboBoxValue>
              <ComboBoxValue key="NU" value="NU">
                Nunavut
              </ComboBoxValue>
              <ComboBoxValue key="ON" value="ON">
                Ontario
              </ComboBoxValue>
              <ComboBoxValue key="PE" value="PE">
                Prince Edward Island
              </ComboBoxValue>
              <ComboBoxValue key="QC" value="QC">
                Québec
              </ComboBoxValue>
              <ComboBoxValue key="SK" value="SK">
                Saskatchewan
              </ComboBoxValue>
              <ComboBoxValue key="YT" value="YT">
                Yukon
              </ComboBoxValue>
            </ComboBox>
          );
          break;
        case "fr":
          return (
            <ComboBox
              onChange={onChange}
              value={currentProvince}
              className="province-select"
              emptyText="--"
            >
              <ComboBoxValue key={null} value={null}>
                Voir tous
              </ComboBoxValue>
              <ComboBoxValue key="AB" value="AB">
                Alberta
              </ComboBoxValue>
              <ComboBoxValue key="BC" value="BC">
                Colombie-Britannique
              </ComboBoxValue>
              <ComboBoxValue key="PE" value="PE">
                L'île du Prince-Édouard
              </ComboBoxValue>
              <ComboBoxValue key="MB" value="MB">
                Manitoba
              </ComboBoxValue>
              <ComboBoxValue key="NB" value="NB">
                Nouveau-Brunswick
              </ComboBoxValue>
              <ComboBoxValue key="NS" value="NS">
                Nouvelle-Écosse
              </ComboBoxValue>
              <ComboBoxValue key="NU" value="NU">
                Nunavut
              </ComboBoxValue>
              <ComboBoxValue key="ON" value="ON">
                Ontario
              </ComboBoxValue>
              <ComboBoxValue key="QC" value="QC">
                Québec
              </ComboBoxValue>
              <ComboBoxValue key="SK" value="SK">
                Saskatchewan
              </ComboBoxValue>
              <ComboBoxValue key="NL" value="NL">
                Terre-Neuve et Labrador
              </ComboBoxValue>
              <ComboBoxValue key="NT" value="NT">
                Territoires du Nord-Ouest
              </ComboBoxValue>
              <ComboBoxValue key="YT" value="YT">
                Yukon
              </ComboBoxValue>
            </ComboBox>
          );
          break;
        default:
          break;
      }
      break;
    case "US":
      return (
        <ComboBox
          onChange={onChange}
          value={currentProvince}
          className="province-select"
          emptyText="--"
        >
          <ComboBoxValue key={null} value={null}>
            View All
          </ComboBoxValue>
          <ComboBoxValue key="AL" value="AL">
            Alabama
          </ComboBoxValue>
          <ComboBoxValue key="AK" value="AK">
            Alaska
          </ComboBoxValue>
          <ComboBoxValue key="AZ" value="AZ">
            Arizona
          </ComboBoxValue>
          <ComboBoxValue key="AR" value="AR">
            Arkansas
          </ComboBoxValue>
          <ComboBoxValue key="CA" value="CA">
            California
          </ComboBoxValue>
          <ComboBoxValue key="CO" value="CO">
            Colorado
          </ComboBoxValue>
          <ComboBoxValue key="CT" value="CT">
            Connecticut
          </ComboBoxValue>
          <ComboBoxValue key="DE" value="DE">
            Delaware
          </ComboBoxValue>
          <ComboBoxValue key="DC" value="DC">
            Dist. of Columbia
          </ComboBoxValue>
          <ComboBoxValue key="FL" value="FL">
            Florida
          </ComboBoxValue>
          <ComboBoxValue key="GA" value="GA">
            Georgia
          </ComboBoxValue>
          <ComboBoxValue key="GU" value="GU">
            Guam
          </ComboBoxValue>
          <ComboBoxValue key="HI" value="HI">
            Hawaii
          </ComboBoxValue>
          <ComboBoxValue key="ID" value="ID">
            Idaho
          </ComboBoxValue>
          <ComboBoxValue key="IL" value="IL">
            Illinois
          </ComboBoxValue>
          <ComboBoxValue key="IN" value="IN">
            Indiana
          </ComboBoxValue>
          <ComboBoxValue key="IA" value="IA">
            Iowa
          </ComboBoxValue>
          <ComboBoxValue key="KS" value="KS">
            Kansas
          </ComboBoxValue>
          <ComboBoxValue key="KY" value="KY">
            Kentucky
          </ComboBoxValue>
          <ComboBoxValue key="LA" value="LA">
            Louisiana
          </ComboBoxValue>
          <ComboBoxValue key="ME" value="ME">
            Maine
          </ComboBoxValue>
          <ComboBoxValue key="MD" value="MD">
            Maryland
          </ComboBoxValue>
          <ComboBoxValue key="MA" value="MA">
            Massachusetts
          </ComboBoxValue>
          <ComboBoxValue key="MI" value="MI">
            Michigan
          </ComboBoxValue>
          <ComboBoxValue key="MN" value="MN">
            Minnesota
          </ComboBoxValue>
          <ComboBoxValue key="MS" value="MS">
            Mississippi
          </ComboBoxValue>
          <ComboBoxValue key="MO" value="MO">
            Missouri
          </ComboBoxValue>
          <ComboBoxValue key="MT" value="MT">
            Montana
          </ComboBoxValue>
          <ComboBoxValue key="NE" value="NE">
            Nebraska
          </ComboBoxValue>
          <ComboBoxValue key="NV" value="NV">
            Nevada
          </ComboBoxValue>
          <ComboBoxValue key="NH" value="NH">
            New Hampshire
          </ComboBoxValue>
          <ComboBoxValue key="NJ" value="NJ">
            New Jersey
          </ComboBoxValue>
          <ComboBoxValue key="NM" value="NM">
            New Mexico
          </ComboBoxValue>
          <ComboBoxValue key="NY" value="NY">
            New York
          </ComboBoxValue>
          <ComboBoxValue key="NC" value="NC">
            North Carolina
          </ComboBoxValue>
          <ComboBoxValue key="ND" value="ND">
            North Dakota
          </ComboBoxValue>
          <ComboBoxValue key="OH" value="OH">
            Ohio
          </ComboBoxValue>
          <ComboBoxValue key="OK" value="OK">
            Oklahoma
          </ComboBoxValue>
          <ComboBoxValue key="OR" value="OR">
            Oregon
          </ComboBoxValue>
          <ComboBoxValue key="PA" value="PA">
            Pennsylvania
          </ComboBoxValue>
          <ComboBoxValue key="RI" value="RI">
            Rhode Island
          </ComboBoxValue>
          <ComboBoxValue key="SC" value="SC">
            South Carolina
          </ComboBoxValue>
          <ComboBoxValue key="SD" value="SD">
            South Dakota
          </ComboBoxValue>
          <ComboBoxValue key="TN" value="TN">
            Tennessee
          </ComboBoxValue>
          <ComboBoxValue key="TX" value="TX">
            Texas
          </ComboBoxValue>
          <ComboBoxValue key="UT" value="UT">
            Utah
          </ComboBoxValue>
          <ComboBoxValue key="VT" value="VT">
            Vermont
          </ComboBoxValue>
          <ComboBoxValue key="VA" value="VA">
            Virginia
          </ComboBoxValue>
          <ComboBoxValue key="WA" value="WA">
            Washington
          </ComboBoxValue>
          <ComboBoxValue key="WV" value="WV">
            West Virginia
          </ComboBoxValue>
          <ComboBoxValue key="WI" value="WI">
            Wisconsin
          </ComboBoxValue>
          <ComboBoxValue key="WY" value="WY">
            Wyoming
          </ComboBoxValue>
        </ComboBox>
      );
      break;
    default:
      break;
  }
};

export const getMapCenterFromLocation = (country, province) => {
  //console.log('getMapCenterFromLocation - country: '+country+', province: '+province);

  var pos = { lat: null, lng: null };
  switch (country) {
    case "CA":
      switch (province) {
        case "AB":
          pos = { lat: 53.93327, lng: -116.576504 };
          break;
        case "BC":
          pos = { lat: 53.72667, lng: -127.647621 };
          break;
        case "MB":
          pos = { lat: 53.76086, lng: -98.813876 };
          break;
        case "NB":
          pos = { lat: 46.56532, lng: -66.461916 };
          break;
        case "NL":
          pos = { lat: 53.13551, lng: -57.660436 };
          break;
        case "NS":
          pos = { lat: 44.68199, lng: -63.744311 };
          break;
        case "NT":
          pos = { lat: 64.82554, lng: -124.84573 };
          break;
        case "NU":
          pos = { lat: 69.07717, lng: -84.15463 };
          break;
        case "ON":
          pos = { lat: 51.25377, lng: -85.323214 };
          break;
        case "PE":
          pos = { lat: 46.510712, lng: -63.416814 };
          break;
        case "QC":
          pos = { lat: 52.939916, lng: -73.549136 };
          break;
        case "SK":
          pos = { lat: 52.93992, lng: -106.450864 };
          break;
        case "YT":
          pos = { lat: 64.282327, lng: -135.0 };
          break;
        default:
          break;
      }
      break;
    case "US":
      switch (province) {
        case "AL":
          pos = { lat: 32.318231, lng: -86.902298 };
          break;
        case "AK":
          pos = { lat: 64.200841, lng: -149.493673 };
          break;
        case "AS":
          pos = { lat: null, lng: null };
          break;
        case "AZ":
          pos = { lat: 34.048928, lng: -111.093731 };
          break;
        case "AR":
          pos = { lat: 35.20105, lng: -91.831833 };
          break;
        case "CA":
          pos = { lat: 36.778261, lng: -119.417932 };
          break;
        case "CO":
          pos = { lat: 39.550051, lng: 39.550051 };
          break;
        case "CT":
          pos = { lat: 41.603221, lng: -73.087749 };
          break;
        case "DE":
          pos = { lat: 38.910832, lng: -75.52767 };
          break;
        case "DC":
          pos = { lat: 38.907192, lng: -77.036871 };
          break;
        case "FL":
          pos = { lat: 27.664827, lng: -81.515754 };
          break;
        case "GA":
          pos = { lat: 32.165622, lng: -82.900075 };
          break;
        case "GU":
          pos = { lat: 36.90194, lng: -89.82361 };
          break;
        case "HI":
          pos = { lat: 19.896766, lng: -155.582782 };
          break;
        case "ID":
          pos = { lat: 44.068202, lng: -114.742041 };
          break;
        case "IL":
          pos = { lat: 40.633125, lng: -89.398528 };
          break;
        case "IN":
          pos = { lat: 40.267194, lng: -86.134902 };
          break;
        case "IA":
          pos = { lat: 41.878003, lng: -93.097702 };
          break;
        case "KS":
          pos = { lat: 39.011902, lng: -98.484246 };
          break;
        case "KY":
          pos = { lat: 37.839333, lng: -84.270018 };
          break;
        case "LA":
          pos = { lat: 30.984298, lng: -91.962333 };
          break;
        case "ME":
          pos = { lat: 45.253783, lng: -69.445469 };
          break;
        case "MD":
          pos = { lat: 39.045755, lng: -76.641271 };
          break;
        case "MH":
          pos = { lat: null, lng: null };
          break;
        case "MA":
          pos = { lat: 42.390731, lng: -71.385527 };
          break;
        case "MI":
          pos = { lat: 44.314844, lng: -85.602364 };
          break;
        case "FM":
          pos = { lat: null, lng: null };
          break;
        case "MN":
          pos = { lat: 46.729553, lng: -94.6859 };
          break;
        case "MS":
          pos = { lat: 32.354668, lng: -89.398528 };
          break;
        case "MO":
          pos = { lat: 37.964253, lng: -91.831833 };
          break;
        case "MT":
          pos = { lat: 46.879682, lng: -110.362566 };
          break;
        case "NE":
          pos = { lat: 41.492537, lng: -99.901813 };
          break;
        case "NV":
          pos = { lat: 38.80261, lng: -116.419389 };
          break;
        case "NH":
          pos = { lat: 43.193852, lng: -71.572395 };
          break;
        case "NJ":
          pos = { lat: 40.058324, lng: -74.405661 };
          break;
        case "NM":
          pos = { lat: 34.51994, lng: -105.87009 };
          break;
        case "NY":
          pos = { lat: 40.712784, lng: -74.005941 };
          break;
        case "NC":
          pos = { lat: 35.759573, lng: -79.0193 };
          break;
        case "ND":
          pos = { lat: 47.551493, lng: -101.002012 };
          break;
        case "NP":
          pos = { lat: null, lng: null };
          break;
        case "OH":
          pos = { lat: 40.417287, lng: -82.907123 };
          break;
        case "OK":
          pos = { lat: 35.46756, lng: -97.516428 };
          break;
        case "OR":
          pos = { lat: 43.804133, lng: -120.554201 };
          break;
        case "PW":
          pos = { lat: null, lng: null };
          break;
        case "PA":
          pos = { lat: 41.203322, lng: -77.194525 };
          break;
        case "PR":
          pos = { lat: null, lng: null };
          break;
        case "RI":
          pos = { lat: 41.580095, lng: -71.477429 };
          break;
        case "SC":
          pos = { lat: 33.836081, lng: -81.163725 };
          break;
        case "SD":
          pos = { lat: 43.969515, lng: -99.901813 };
          break;
        case "TN":
          pos = { lat: 35.517491, lng: -86.580447 };
          break;
        case "TX":
          pos = { lat: 31.968599, lng: -99.901813 };
          break;
        case "UT":
          pos = { lat: 39.32098, lng: -111.093731 };
          break;
        case "VT":
          pos = { lat: 44.558803, lng: -72.577841 };
          break;
        case "VA":
          pos = { lat: 37.431573, lng: -78.656894 };
          break;
        case "VI":
          pos = { lat: null, lng: null };
          break;
        case "WA":
          pos = { lat: 38.907192, lng: -77.036871 };
          break;
        case "WV":
          pos = { lat: 38.597626, lng: -80.454903 };
          break;
        case "WI":
          pos = { lat: 43.78444, lng: -88.787868 };
          break;
        case "WY":
          pos = { lat: 43.075968, lng: -107.290284 };
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
  return pos;
};

import { applicationStore } from "../stores/ApplicationStore.js";
import { applicationActions } from "../actions/ApplicationActions";

import {
  sizeOf,
  isEmpty,
  isNationalSite,
  isDealerSite,
  isGroupSite,
  isFrenchSite,
  loadFrenchLocale,
} from "../service/UtilityService";
import {
  storageTerms,
  selectLocalStorage,
  createInLocalStorage,
  removeFromLocalStorage,
} from "../service/StorageService";
import { loadPdfMakeFonts } from "../service/PdfService";
import { getLoginLabel } from "../service/LoginService";
import store from "../stores/configureStore.js";
import { login } from "../redux/features/loginInfo.js";

var Moment = require("moment");

const TOKEN_KEY = "tw-token";

const AUTH_TYPE = {
  ADMIN: "Admin",
  CUSTOMER: "Customer",
  GUEST: "Guest",
  SALES_STAFF: "Sales",
  USER: "User",
};

export function initializeApp() {
  // initialize extra window components
  window.loadingSuffix = "";

  // check local storage for saved token
  var localStorageData = selectLocalStorage(storageTerms.token),
    token = null;
  if (
    sizeOf(localStorageData) > 0 &&
    typeof localStorageData !== "undefined" &&
    serverDataLoadedSuccessfully()
  )
    token = localStorageData;
  if (token && checkIsTokenValid(token) && checkIsTokenAuthenticated(token))
    setTokenInWindow(token);
  else setTokenInLocalStorage(getToken());

  // load google maps
  if (typeof window.google.maps === "undefined") loadgooglemaps();

  // load french locale
  if (isFrenchSite(true)) loadFrenchLocale();

  // load pdf maker fonts
  loadPdfMakeFonts();

  return isUserLoggedIn();
}

export const checkLogin = () => {
  var user = getLogin();
  if (!user.error)
    setTimeout(() => {
      store.dispatch(login({ username: user.usr, password: user.pwd }));
      applicationActions.login(user.usr, user.pwd, false);
    }, 100);
};

export function tokenIsAuthenticated() {
  if (window.tokenIsAuthenticated == "true") {
    return true;
  } else {
    return false;
  }
}

export function serverDataLoadedSuccessfully() {
  if (isNationalSite() || isDealerSite() || isGroupSite())
    if (!isEmpty(window.uniquifyToken)) return true;
  return false;
}

export function setToken(token) {
  try {
    if (checkIsTokenValid(token)) {
      setTokenInLocalStorage(token);
      setTokenInWindow(token);
    }
  } catch (e) {
    console.error(e);
  }
  return checkIsTokenExpired(token);
}

export function setTokenInWindow(token) {
  if (token && token.tokenId) {
    window.token = JSON.stringify(token);
  }
}

export function setTokenInLocalStorage(token) {
  createInLocalStorage(storageTerms.token, token);
}

export function getToken() {
  if (window.token) {
    return JSON.parse(window.token);
  }
}

export function setTokenCaptcha(captchaToken) {
  if (
    !isEmpty(captchaToken) &&
    typeof captchaToken === "string" &&
    window.token
  ) {
    applicationStore.data.dealerForceCaptchaPassed = true;
    var token = JSON.parse(window.token);
    token.searchToken = captchaToken;
    if (token && token.tokenId) {
      window.token = JSON.stringify(token);
    }
  }
}

export function resetTokenCaptcha() {
  if (window.token) {
    var token = JSON.parse(window.token);
    delete token.searchToken;
    if (token && token.tokenId) {
      window.token = JSON.stringify(token);
    }
  }
}

export function tokenHasSessionReference() {
  if (isTokenValid()) {
    return getToken().sessionReferenceNumber;
  } else {
    return false;
  }
}

export function isTokenExpired() {
  var token = getToken();
  return Moment().isAfter(Moment(token.tokenExpirationTimestamp));
}

export function checkIsTokenExpired(token) {
  return Moment().isAfter(Moment(token.tokenExpirationTimestamp));
}

export function isTokenValid() {
  var token = getToken();
  return token && token.tokenId && !isTokenExpired();
}

export function checkIsTokenValid(token) {
  return token && token.tokenId && !checkIsTokenExpired(token);
}

function getAuthenticationType() {
  if (isTokenValid() && isTokenAuthenticated()) {
    return getToken().userRoleName;
  } else {
    return "";
  }
}

export function isDealerAuthentication() {
  return (
    getAuthenticationType() === AUTH_TYPE.ADMIN ||
    getAuthenticationType() === AUTH_TYPE.SALES_STAFF ||
    getAuthenticationType() === AUTH_TYPE.USER
  );
}

export function isAdministratorAuthentication() {
  return getAuthenticationType() === AUTH_TYPE.ADMIN;
}

export function isCustomerAuthentication() {
  return (
    getAuthenticationType() === AUTH_TYPE.CUSTOMER ||
    getAuthenticationType() === AUTH_TYPE.GUEST
  );
}

export function isUserLoggedIn(customerPermission = false) {
  return (
    isTokenValid() &&
    isTokenAuthenticated() &&
    (isDealerAuthentication() ||
      (customerPermission && isCustomerAuthentication()))
  );
}

/**
 * This function is used to check if the user has the permission to see the pricing analysis
 * @param item
 * @returns {boolean}
 */
export function isPricingAnalysisEnabled(item) {
  if (typeof item !== "string") {
    return false;
  }
  // this check if the has the permission to see the pricing analysis
  return item?.toLowerCase() === "auth" || item?.toLowerCase() === "show";
}
function getCustomerAuthenticationFirstName() {
  if (isUserLoggedIn(true)) {
    return getToken().firstName;
  } else {
    return "";
  }
}

function getCustomerAuthenticationLastName() {
  if (isUserLoggedIn(true)) {
    return getToken().lastName;
  } else {
    return "";
  }
}

export function getCustomerAuthenticationName() {
  return (
    getCustomerAuthenticationFirstName() +
    (!isEmpty(getCustomerAuthenticationFirstName()) &&
    !isEmpty(getCustomerAuthenticationLastName())
      ? " " + getCustomerAuthenticationLastName()
      : "")
  );
}

export function isTokenAuthenticated() {
  return (
    getToken().tokenIsAuthenticated === "true" ||
    getToken().tokenIsAuthenticated === true
  );
}

export function checkIsTokenAuthenticated(token) {
  return (
    token.tokenIsAuthenticated === "true" || token.tokenIsAuthenticated === true
  );
}

export function checkIsTokenVersionOutdated(token) {
  // return token.applicationVersionNumber !== window.appVersion;
  return 1 !== 1;
}

export function logOut() {
  try {
    removeFromLocalStorage(TOKEN_KEY);
    document.cookie =
      getLoginLabel() + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  } catch (e) {
    console.error(e);
  }
}

import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";
import { languageStore } from "../../stores/LanguageStore.js";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { Image } from "../ux/Image.jsx";
import { Modal } from "../ux/Modal.jsx";
import { WishlistReviewLineItem } from "./WishlistReviewLineItem.jsx";
import { ProductTypes } from "../../constants/ProductTypes.js";

import PricingContext from "../productlist/PricingContext.jsx";
import { shoppingCartStore } from "../../stores/ShoppingCartStore.js";

import { isUserLoggedIn } from "../../api/AuthService";
import { getPackageItemTypeInverse } from "../../api/RequestBuilderHelper";
import { shoppingCartViewStore } from "../../stores/ShoppingCartViewStore";
import {
  showMsrpColumn,
  showSavingsColumn,
} from "../../service/WishlistService";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";
import {
  firstToUpper,
  get_browser,
  getTabIndex,
  money_format,
  getAppLanguage,
  getTirePromotionalImage,
} from "../../service/UtilityService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var _ = require("lodash");

class WishlistReviewItem extends I18nComponent {
  state = {
    showPriceMatchModal: false,
    selectedPriceMatchItem: null,
    priceMatchNotesTouched: false,
    workOrderNumberValue: null,
    discountValue: 0,
    noteValue: null,
    applyPriceMatchDiscount: false,
    errorState: [],
  };

  componentDidMount() {
    var offset = 0;
    switch (this.props.cartItem.get("type")) {
      case ProductTypes.TIRES:
        offset = 90;
        break;
      case ProductTypes.ALLOY_WHEELS:
        offset = 70;
        break;
      case ProductTypes.ACCESSORIES:
        offset = 50;
        break;
      case ProductTypes.PACKAGES:
        offset = 30;
        break;
      default:
        offset = 10;
        break;
    }
    var tile = document.getElementsByClassName(
      "wishlist-combo-box-" +
        this.props.cartItem.get("type") +
        "-" +
        this.props.cartItem.get("id")
    );
    if (tile && tile[0])
      tile[0].setAttribute(
        "style",
        "z-index: " + (offset - parseFloat(this.props.index))
      );
  }

  render() {
    var msrp = this.props.cartItem.get("pricing").get("listPrice");
    var dealerPrice = parseFloat(
      this.props.cartItem.get("pricing").get("displayPrice")
    );
    var quantityValue = parseFloat(
      this.props.cartItem.get("quantity").get("value")
    );

    var savings, total;
    savings = this.props.cartItem.get("savings")
      ? this.props.cartItem.get("savings")
      : parseFloat(this.props.cartItem.get("pricing").get("displaySavings")) *
        quantityValue;
    total = parseFloat(this.props.cartItem.get("price"));

    var promotionalIcon = false;
    switch (this.props.cartItem.get("type")) {
      case ProductTypes.TIRES:
        promotionalIcon = getTirePromotionalImage();
        break;
      default:
        break;
    }

    var hasPromotion = false,
      cartItems = shoppingCartViewStore.data.get("cartItems"),
      itemId = this.props.cartItem.get("id");
    if (cartItems) {
      cartItems.forEach(function (productType) {
        productType.forEach(function (cartItem) {
          if (cartItem.get("id") == itemId && cartItem.get("hasPromotion")) {
            hasPromotion = true;
          }
        });
      });
    }
    var showSavings = hasPromotion && savings > 0;

    var promotionTitle = false;
    if (
      this.props.cartItem.get("hasPromotion") &&
      this.props.cartItem.get("pricing").get("showUnitPrice") &&
      this.props.cartItem.get("pricing").get("displaySavings") > 0
    ) {
      var originalprice =
        parseFloat(this.props.cartItem.get("pricing").get("displayPrice")) +
        parseFloat(this.props.cartItem.get("pricing").get("displaySavings"));
      promotionTitle = (
        <div className="show-pricing">
          <span className="original-price">{money_format(originalprice)}</span>
          <span className="savings-price">
            {this.t("common.now") +
              " " +
              money_format(
                this.props.cartItem.get("pricing").get("displayPrice")
              )}
          </span>
        </div>
      );
    }

    var dealerPriceFormatted = this.props.cartItem
      .get("pricing")
      .get("showUnitPrice")
      ? money_format(dealerPrice)
      : "";

    msrp = money_format(msrp);
    savings = money_format(savings);
    total = money_format(total);

    var productPartNumberLabel = "",
      partNumber = "";
    if (
      typeof this.props.cartItem.get("nationalPartNumber") !== "undefined" &&
      this.props.cartItem.get("nationalPartNumber") !== null &&
      this.props.cartItem.get("nationalPartNumber") !== ""
    ) {
      partNumber = this.props.cartItem.get("nationalPartNumber");
      productPartNumberLabel = this.t("product.nationalPartNumber");
    } else {
      partNumber = this.props.cartItem.get("partNumber");
      productPartNumberLabel = this.t("product.partNumber");
    }

    var productInfo = false;
    switch (this.props.cartItem.get("type")) {
      case ProductTypes.TIRES:
        productInfo = (
          <div className="item-partnumber">
            {productPartNumberLabel}: {partNumber}
            <br />
            {this.t("product.tireSize")}: {this.props.cartItem.get("tiresize")}
            <br />
            {this.t("product.loadSpeedRating")}:{" "}
            {this.props.cartItem.get("loadRating")} /{" "}
            {this.props.cartItem.get("speedRating")}
          </div>
        );
        break;
      case ProductTypes.ALLOY_WHEELS:
        productInfo = (
          <div className="item-partnumber">
            {productPartNumberLabel}: {partNumber}
            <br />
            {this.t("product.wheelDiameter")}:{" "}
            {this.props.cartItem.get("diameter")}
          </div>
        );
        break;
      default:
        productInfo = (
          <div className="item-partnumber">
            {productPartNumberLabel}: {partNumber}
            <br />
            {this.props.cartItem
              .get("items")
              .map(function (item) {
                return (
                  <li className="article-package-items">
                    <span>
                      {item.get("quantity")}{" "}
                      {getPackageItemTypeInverse(
                        item.get("partType"),
                        getAppLanguage(),
                        item.get("typeDescription")
                          ? item.get("typeDescription").get(getAppLanguage())
                          : ""
                      )}
                    </span>
                    <span> - {this.td(item.get("title").toJS())}</span>
                    <span className="package-part-number">
                      {item.get("partNumber") ? (
                        <span>
                          <span>{this.t("product.partNumber")}:</span>
                          <span>{item.get("partNumber")}</span>
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </li>
                );
              }, this)
              .toArray()}
            {this.props.cartItem.get("type") === ProductTypes.ACCESSORIES &&
            this.props.cartItem
              .get("itemPart")
              .get("partDetails")
              .get("primaryPart")
              .get("isPackage") === "1"
              ? this.props.cartItem
                  .get("itemPart")
                  .get("partDetails")
                  .get("relatedParts")
                  .map(function (relatedPart) {
                    return (
                      <li>
                        <span>
                          {relatedPart
                            .get("accessoryName")
                            .get(getAppLanguage())}{" "}
                          {this.t("product.partNumber")}:{" "}
                          {relatedPart.get("accessoryPartNumber")}
                        </span>
                      </li>
                    );
                  }, this)
                  .toArray()
              : false}
            {this.props.cartItem
              .get("lineItems")
              .map(function (item) {
                return (
                  <li className="article-package-items">
                    <span>{item.get("quantity")} </span>
                    <span>{this.td(item.get("title").toJS())}</span>
                  </li>
                );
              }, this)
              .toArray()}
          </div>
        );
        break;
    }

    var runFlatClass = "icon-run-flat-en";
    var runFlatImgSrc = "/static/img/icon_run_flat_en.png";
    if (languageStore.data.lang == "fr" || getAppLanguage() === "fr") {
      runFlatClass = "icon-run-flat-fr";
      runFlatImgSrc = "/static/img/icon_run_flat_fr.png";
    }
    var runFlatImg = false;
    if (
      this.enableRunFlatImage() &&
      this.props.cartItem.get("runFlat") === "1"
    ) {
      runFlatImg = (
        <div className="run-flat-icon">
          <img
            className={runFlatClass}
            src={runFlatImgSrc}
            alt={this.t("product.runFlat")}
          ></img>
        </div>
      );
    }

    var originalEquipmentText = false,
      originalEquipmentIcon = false;
    if (
      !!this.props.cartItem.get("originalEquipment") &&
      applicationStore.data.enableMercedesFeatures
    ) {
      originalEquipmentText = this.t("product.approvedTire");
    }

    var itemDataClassName = "item-data";
    if (this.props.cartItem.get("type") == ProductTypes.PACKAGES) {
      itemDataClassName = "item-data package-data";
    }

    var imageAlt = this.props.cartItem.get("partNumber")
      ? languageStore.getTranslated(this.props.cartItem.get("title").toJS()) +
        " " +
        this.props.cartItem.get("partNumber")
      : languageStore.getTranslated(this.props.cartItem.get("title").toJS()) +
        " " +
        this.props.cartItem.get("nationalPartNumber");

    var pricingClassName = "pricing-container";
    if (!applicationStore.data.dealerShowMsrp) {
      pricingClassName = "pricing-container hide-msrp";
    }

    var imageClass = "item-image";
    if (this.props.cartItem.get("type") == ProductTypes.PACKAGES) {
      imageClass = "item-image package-item";
    }

    var includedOptionalItems = this.props.cartItem.get(
      "includedOptionalItems"
    );
    var optionalItems = this.props.cartItem.get("optionalItems");

    includedOptionalItems = includedOptionalItems.map(function (
      includedOptionalItem
    ) {
      includedOptionalItem = includedOptionalItem.set("included", true);
      return includedOptionalItem;
    });

    optionalItems = optionalItems.map(function (optionalItem) {
      optionalItem = optionalItem.set("included", false);
      return optionalItem;
    });

    var priceMatchItemIncluded = includedOptionalItems.filter(
      (includedItem) => includedItem.get("isPriceMatch") === "1"
    );
    includedOptionalItems = includedOptionalItems.filter(
      (includedItem) => includedItem.get("isPriceMatch") === "0"
    );

    var priceMatchItemOptional = optionalItems.filter(
      (optionalItem) => optionalItem.get("isPriceMatch") === "1"
    );
    optionalItems = optionalItems.filter(
      (optionalItem) => optionalItem.get("isPriceMatch") === "0"
    );

    /*
		optionalItems = optionalItems.concat(includedOptionalItems).sort(function (a, b) {
			if (languageStore.getTranslated(a.get('title').toJS()) > languageStore.getTranslated(b.get('title').toJS())) return 1;
			else if (languageStore.getTranslated(a.get('title').toJS()) < languageStore.getTranslated(b.get('title').toJS())) return -1;
			else return 0;
		});
		 */

    optionalItems = optionalItems.sort(function (a, b) {
      if (
        languageStore.getTranslated(a.get("title").toJS()) >
        languageStore.getTranslated(b.get("title").toJS())
      )
        return 1;
      else if (
        languageStore.getTranslated(a.get("title").toJS()) <
        languageStore.getTranslated(b.get("title").toJS())
      )
        return -1;
      else return 0;
    });

    includedOptionalItems = includedOptionalItems.sort(function (a, b) {
      if (
        languageStore.getTranslated(a.get("title").toJS()) >
        languageStore.getTranslated(b.get("title").toJS())
      )
        return 1;
      else if (
        languageStore.getTranslated(a.get("title").toJS()) <
        languageStore.getTranslated(b.get("title").toJS())
      )
        return -1;
      else return 0;
    });

    var priceLabel = this.t("product.retailPrice");
    if (
      applicationStore.data.dealerSite &&
      applicationStore.data.dealerSellingPriceLabel[
        this.props.cartItem.get("type")
      ][getAppLanguage()]
    ) {
      priceLabel =
        applicationStore.data.dealerSellingPriceLabel[
          this.props.cartItem.get("type")
        ][getAppLanguage()];
    }

    var translate = this.t,
      renderUpdatedAgo = this.renderUpdatedAgo;

    var enableMinimumTag =
      !isUserLoggedIn() &&
      this.props.cartItem.get("pricing").get("marketMinimum");
    var enableMarketMinimumTag =
      isUserLoggedIn() &&
      parseFloat(this.props.cartItem.get("pricing").get("oldPrice")) > 0;

    //{enableMinimumTag ? <div><span>{this.t('marketMinimum')}</span></div> : false}

    // {showSavingsColumn() ? <li className="savings">
    return (
      <tbody>
        <tr
          className={
            this.props.cartItem.get("hasRequiredItem")
              ? "has-required-item"
              : ""
          }
        >
          <td
            tabIndex={getTabIndex()}
            aria-label={languageStore.getTranslated(
              this.props.cartItem.get("title").toJS()
            )}
            className="item-info"
          >
            <div className={imageClass}>
              <Image
                src={this.props.cartItem.get("image")}
                alt={"Image Part " + imageAlt}
              />
              {runFlatImg}
              {this.props.cartItem.get("brandImage") ? (
                <Image
                  src={this.props.cartItem.get("brandImage")}
                  className="image-brand"
                  alt={"Image Brand " + this.props.cartItem.get("manufacturer")}
                />
              ) : (
                false
              )}
            </div>
            <div className={itemDataClassName}>
              <h3 className="item-name">
                {languageStore.getTranslated(
                  this.props.cartItem.get("title").toJS()
                )}
              </h3>
              {originalEquipmentText}
              {productInfo}
              {this.renderItemOptions()}
            </div>
          </td>
          <td
            tabIndex={
              applicationStore.data.dealerShowMsrp ? getTabIndex() : "-1"
            }
            className="pricing-total closable"
          >
            {showMsrpColumn(itemId) ? msrp : ""}
          </td>
          <td
            tabIndex={getTabIndex()}
            aria-label={
              !!promotionTitle
                ? promotionTitle
                : parseFloat(dealerPrice) > 0
                ? dealerPriceFormatted
                : ""
            }
            className="pricing-total closable"
          >
            {!!promotionTitle
              ? promotionTitle
              : parseFloat(dealerPrice) > 0
              ? dealerPriceFormatted
              : ""}
          </td>
          <td tabIndex={getTabIndex()} className="controls">
            <div className="shopping-cart-view">
              <div className={pricingClassName}>
                {!this.props.removeOptionalFeatures &&
                !this.props.supportConsumerOrder ? (
                  <PricingContext
                    productIndex={this.props.productIndex}
                    productId={this.props.productIndex}
                    className={setAdobeLaunchClass(
                      "",
                      ADOBELAUNCHER_CLASS.BUTTON.LINK
                    )}
                    datadtm={setAdobeLaunchDTM(
                      "",
                      ADOBELAUNCHER_DTM.YOURQUOTE.PRODUCT,
                      languageStore.getTranslated(
                        this.props.cartItem.get("title").toJS()
                      )
                    )}
                    pricingDetails={this.props.cartItem.get("pricingDetails")}
                    pricing={this.props.cartItem.get("pricing")}
                    hasPricing={this.props.cartItem.get("hasPricing")}
                    quantity={this.props.cartItem.get("quantity")}
                    quantities={this.props.cartItem.get("quantities")}
                    rebate={this.props.cartItem.get("rebate")}
                    optionId={this.props.cartItem.get("optionId")}
                    vehicleId={this.props.cartItem.get("vehicleId")}
                    front={true}
                    openedFromDetail={false}
                    openedFromShoppingView={false}
                    openedFromDetailModal={false}
                    openedFromWishlist={true}
                    productType={this.props.cartItem.get("type")}
                    product={this.props.cartItem}
                    productPriceText={this.getProductPriceText(
                      this.props.cartItem.get("type")
                    )}
                    classSuffix={
                      "wishlist-combo-box-" +
                      this.props.cartItem.get("type") +
                      "-" +
                      this.props.cartItem.get("id")
                    }
                  />
                ) : (
                  <span>
                    {this.props.cartItem.get("quantity").get("value")}
                  </span>
                )}
              </div>
              {enableMarketMinimumTag ? (
                <div>
                  <span>
                    {this.t("belowMarket")}{" "}
                    {money_format(
                      this.props.cartItem.get("pricing").get("oldPrice")
                    )}
                  </span>
                </div>
              ) : (
                false
              )}
              <div className="mobile-only-data no-print">
                <ul>
                  {showMsrpColumn(itemId) ? (
                    <li>
                      <label>{this.t("wishlist.msrp") + ":"}</label>
                      <span>{msrp}</span>
                    </li>
                  ) : (
                    false
                  )}
                  <li>
                    <label>{priceLabel + ":"}</label>
                    <span>
                      {!!promotionTitle
                        ? promotionTitle
                        : parseFloat(dealerPrice) > 0
                        ? dealerPriceFormatted
                        : ""}
                    </span>
                  </li>
                  {showSavingsColumn(itemId) ? (
                    <li className="savings">
                      <label>{this.t("wishlist.youSave") + ":"}</label>
                      <span>{savings}</span>
                    </li>
                  ) : (
                    false
                  )}
                </ul>
              </div>
            </div>
          </td>
          {showSavingsColumn(itemId) ? (
            <td className="pricing-total pricing-savings closable">
              {savings}
            </td>
          ) : (
            <td></td>
          )}
          <td tabIndex={getTabIndex()} className="pricing-total">
            <div className="pricing-total-price">
              {total}
              {enableMinimumTag ? "*" : false}
            </div>
            {!this.props.removeOptionalFeatures ? (
              <div className="item-remove no-print">
                ({this.renderRemoveButton()})
              </div>
            ) : (
              false
            )}
            {promotionalIcon}
            {this.props.hasNTDStock ? (
              <div className="ntd-cart-item">
                <Image className="ntd-item" src="/static/img/ntd_truck.png" />
              </div>
            ) : (
              false
            )}
          </td>
        </tr>

        {this.enableStock()
          ? this.props.cartItem
              .get("itemPart")
              .get("partStock")
              .get("primaryPart")
              .map(function (item) {
                if (
                  item.get("stockQty") > 0 &&
                  item.get("stockLocationType") !== "LOC"
                ) {
                  return (
                    <tr className="highlight-stock">
                      <td tabIndex={getTabIndex()}>
                        {item.get("stockQty")} {translate("stocks.tires")} {"-"}{" "}
                        {item.get("stockLocationName")}
                      </td>
                      <td tabIndex={getTabIndex()} colSpan="5">
                        {renderUpdatedAgo(
                          item.get("minutesSinceLastStockUpdate")
                        )}
                      </td>
                    </tr>
                  );
                } else {
                  return false;
                }
              })
          : false}

        {priceMatchItemIncluded.map(this.getLineItemMapper()).toArray()}
        {!this.props.removeOptionalFeatures
          ? priceMatchItemOptional.map(this.getLineItemMapper()).toArray()
          : false}
        {this.props.cartItem
          .get("mandatoryItems")
          .map(this.getLineItemMapper())
          .toArray()}
        {includedOptionalItems.map(this.getLineItemMapper()).toArray()}
        {!this.props.removeOptionalFeatures
          ? optionalItems.map(this.getLineItemMapper()).toArray()
          : false}
        <tr className="price-match-table-row">
          <td>{this.renderPriceMatchModal()}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    );
  }

  renderUpdatedAgo = (lastUpdated) => {
    var minutes = "",
      hours = "",
      days = "";
    if (lastUpdated < 60) {
      minutes = this.tp("timing.minute", lastUpdated);
      if (!days) {
        return (
          <text className="updated">
            {this.t("stocks.updatedShort").replace("{}", hours + minutes)}
          </text>
        );
      } else {
        return (
          <text className="updated">
            {this.t("stocks.updatedShort").replace("{}", days)}
          </text>
        );
      }
    } else if (lastUpdated < 60 * 24) {
      if (lastUpdated % 60 !== 0) {
        minutes = this.tp("timing.minute", lastUpdated % 60);
      }
      hours = this.tp("timing.hour", Math.floor(lastUpdated / 60));
      if (!days) {
        return (
          <text className="updated">
            {this.t("stocks.updatedShort").replace("{}", hours + minutes)}
          </text>
        );
      } else {
        return (
          <text className="updated">
            {this.t("stocks.updatedShort").replace("{}", days)}
          </text>
        );
      }
    } else {
      return (
        <text className="updated">
          {this.t("stocks.updatedMoreThanOneDay")}
        </text>
      );
    }
  };

  enableStock = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "generic":
        return (
          isUserLoggedIn() &&
          this.props.hasNTDStock &&
          this.props.supportDealerOrder
        );
      default:
        return false;
    }
  };

  enableRunFlatImage = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mercedes":
        return false;
      case "mercedes_2022":
        return false;
      default:
        return true;
    }
  };

  getProductPriceText = (type) => {
    switch (type) {
      case ProductTypes.TIRES:
        return this.t("product.perTire");
      case ProductTypes.ALLOY_WHEELS:
        return this.t("product.perWheel");
      case ProductTypes.ACCESSORIES:
        return this.t("product.perItem");
      case ProductTypes.PACKAGES:
        return this.t("product.perPackage");
    }
  };

  displaySavings = () => {
    if (
      this.props.cartItem.get("hasPromotion") &&
      parseFloat(this.props.cartItem.get("savings")) > 0
    ) {
      return (
        <div>
          {this.t("product.youSave") +
            " " +
            this.c(parseFloat(this.props.cartItem.get("savings")))}
        </div>
      );
    } else {
      return false;
    }
  };

  getLineItemMapper = (evt) => {
    checkForEvent: if ("undefined" != typeof evt) {
      if (evt.keyCode == 13 || evt.keyCode == 32) {
        evt.preventDefault();
        evt.stopPropagation();
        break checkForEvent;
      }
      return;
    }

    return function (lineItem) {
      return (
        <WishlistReviewLineItem
          key={lineItem.get("lineItemId")}
          id={lineItem.get("lineItemId")}
          lineItem={lineItem.get("lineItem")}
          notTaxable={lineItem.get("isTaxable") == 0}
          quantity={parseInt(lineItem.get("qty"), 10)}
          title={languageStore.getTranslated(lineItem.get("title").toJS())}
          notes={
            lineItem.get("lineItemDetails")
              ? lineItem.get("lineItemDetails").get("lineItemNotes")
              : ""
          }
          description={languageStore.getTranslated(
            lineItem.get("description").toJS()
          )}
          price={parseFloat(lineItem.get("price", 10))}
          hasRemoveButton={lineItem.get("included") === true}
          hasAddButton={lineItem.get("included") === false}
          onRemove={this.onRemoveLineItem.bind(
            this,
            lineItem.get("lineItemId")
          )}
          onRemoveHandleEvent={this.onRemoveLineItemHandleEvent.bind(
            this,
            lineItem.get("lineItemId")
          )}
          onAdd={this.onAddLineItem.bind(this, lineItem)}
          onAddHandleEvent={this.onAddLineItemHandleEvent.bind(this, lineItem)}
          hasPriceMatch={
            isUserLoggedIn() && lineItem.get("isPriceMatch") === "1"
          }
          onPriceMatch={this.onOpenPriceMatchModal.bind(
            this,
            lineItem.get("lineItemId")
          )}
          tabIdx={getTabIndex()}
          removeOptionalFeatures={this.props.removeOptionalFeatures}
        />
      );
    }.bind(this);
  };

  renderRemoveButton = () => {
    return (
      <span
        className={setAdobeLaunchClass(
          "button button-remove no-print",
          ADOBELAUNCHER_CLASS.TEXT
        )}
        data-dtm={setAdobeLaunchDTM(
          "",
          ADOBELAUNCHER_DTM.YOURQUOTE.PRODUCT,
          languageStore.getTranslated(this.props.cartItem.get("title").toJS())
        )}
        role="button"
        tabIndex={getTabIndex()}
        aria-label={this.t("shoppingCartView.remove")}
        onKeyDown={(e) => this.onRemoveCartItem(e)}
        onClick={this.onRemoveCartItem}
      >
        <span className="button-text">{this.t("shoppingCartView.remove")}</span>
      </span>
    );
  };

  renderPriceMatchModal = () => {
    if (this.state.showPriceMatchModal) {
      var type = firstToUpper(this.props.cartItem.get("type"));
      var qty = parseInt(this.props.cartItem.get("quantity").get("value"), 10);
      var partNumber = this.props.cartItem.get("nationalPartNumber")
        ? this.props.cartItem.get("nationalPartNumber")
        : this.props.cartItem.get("partNumber");
      var originalPrice =
        this.props.cartItem.get("pricing").get("displayPrice") * qty;
      var discount = this.state.discountValue;
      if (this.state.applyPriceMatchDiscount) {
        var newPrice = originalPrice - discount;
      } else {
        var newPrice = originalPrice;
      }
      var priceMax = this.state.selectedPriceMatchItem.get("priceMax");
      var discountClassName = "value";
      if (this.state.applyPriceMatchDiscount && discount > 0) {
        discountClassName = "value has-discount";
      }

      originalPrice = money_format(originalPrice);
      newPrice = money_format(newPrice);
      discount = money_format(discount);

      var errorHandler = {
        WORK_ORDER_MISSING: this.t("priceMatching.WORK_ORDER_MISSING"),
        WORK_ORDER_TOO_LONG: this.t("priceMatching.WORK_ORDER_TOO_LONG"),
        DISCOUNT_MISSING: this.t("priceMatching.DISCOUNT_MISSING"),
        DISCOUNT_NOT_A_NUMBER: this.t("priceMatching.DISCOUNT_NOT_A_NUMBER"),
        DISCOUNT_EXCEEDS_MAXIMUM: this.t(
          "priceMatching.DISCOUNT_EXCEEDS_MAXIMUM"
        ).replace("{}", this.state.selectedPriceMatchItem.get("priceMax")),
        NOTE_MISSING: this.t("priceMatching.NOTE_MISSING"),
      };
      var continueButtonText = this.t("priceMatching.close");
      if (this.state.applyPriceMatchDiscount) {
        continueButtonText = this.t("priceMatching.saveAndContinue");
      }
      return (
        <Modal
          refactor={true}
          useShortHeaderBar={true}
          requestUnmount={this.onClosePriceMatchModal}
        >
          <div className="wrapper">
            <div className="modal-price-matching">
              <div className="head">
                <h1>
                  {type} - {this.t("priceMatching.qty")} {qty} -
                  <span className="part-number style-font-bold">
                    {this.t("priceMatching.partNumber")} {partNumber}
                  </span>
                </h1>
              </div>
              <div className="body">
                <div className="price-match-data">
                  <div className="user-data-left">
                    <div className="work-order">
                      <label>{this.t("priceMatching.workOrderNumber")}</label>
                      <input
                        type="text"
                        className="price-match-work-order-number"
                        onBlur={this.onWorkOrderNumberUpdated}
                      />
                    </div>
                    <div className="discount">
                      <label>{this.t("priceMatching.discount")}</label>
                      <input
                        type="number"
                        step="0.01"
                        className="price-match-discount"
                        onChange={this.onDiscountUpdated}
                      />
                      <button
                        className="btn apply-price-match"
                        onClick={this.onCheckPriceMatch}
                      >
                        {this.t("priceMatching.add")}
                      </button>
                    </div>
                  </div>
                  <div className="user-data-right">
                    <div className="notes">
                      <label>{this.t("priceMatching.notes")}</label>
                      <textarea
                        className="price-match-note"
                        onChange={this.onNoteUpdated}
                      />
                    </div>
                  </div>
                </div>
                <div className="user-info">
                  {_.size(this.state.errorState) > 0 ? (
                    <div className="error-message">
                      {this.state.errorState.map(function (error) {
                        return <p>{errorHandler[error]}</p>;
                      })}
                    </div>
                  ) : (
                    false
                  )}
                  <p
                    className={
                      applicationStore.data.userName && false
                        ? "created-by"
                        : "created-by extended"
                    }
                  >
                    {applicationStore.data.userName
                      ? this.t("priceMatching.requestBy") +
                        " " +
                        applicationStore.data.userName
                      : ""}
                  </p>
                </div>
                <div className="total">
                  <div className="price-total">
                    <div className="current-total">
                      <p className="label">
                        {this.t("priceMatching.currentTotal")}
                      </p>
                      <p className="value">{originalPrice}</p>
                    </div>
                    {this.state.applyPriceMatchDiscount ? (
                      <div className="current-total">
                        <p className="label">
                          {this.t("priceMatching.discount")}
                        </p>
                        <p className="value">{discount}</p>
                      </div>
                    ) : (
                      false
                    )}
                    <div className="new-total">
                      <p className="label">
                        {this.t("priceMatching.newTotal")}
                      </p>
                      <p className={discountClassName}>{newPrice}</p>
                    </div>
                  </div>
                  <div
                    className={
                      !this.state.applyPriceMatchDiscount
                        ? "button"
                        : "button extra-button"
                    }
                  >
                    <button
                      className="btn"
                      onClick={
                        this.state.applyPriceMatchDiscount
                          ? this.onApplyPriceMatch
                          : this.onClosePriceMatchModal
                      }
                    >
                      {continueButtonText}
                    </button>
                    {this.state.applyPriceMatchDiscount ? (
                      <button
                        className="btn"
                        onClick={this.onClosePriceMatchModal}
                      >
                        {this.t("priceMatching.cancel")}
                      </button>
                    ) : (
                      false
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  onWorkOrderNumberUpdated = () => {
    if (
      typeof document.getElementsByClassName(
        "price-match-work-order-number"
      ) !== "undefined" &&
      document.getElementsByClassName("price-match-work-order-number") !== null
    ) {
      var workOrderNumberValue = document.getElementsByClassName(
        "price-match-work-order-number"
      )[0].value;
      if (_.size(workOrderNumberValue) > 40) {
        this.checkAndAddErrorState("WORK_ORDER_TOO_LONG");
      } else {
        var check1 = true;
        this.checkAndRemoveErrorState("WORK_ORDER_TOO_LONG");
      }

      if (!check1) {
        this.setState({
          applyPriceMatchDiscount: false,
          workOrderNumberValue: null,
        });
      }
    }
  };

  onDiscountUpdated = () => {
    if (
      typeof document.getElementsByClassName("price-match-discount") !==
        "undefined" &&
      document.getElementsByClassName("price-match-discount") !== null
    ) {
      var check1 = false,
        check2 = false,
        currentErrors = [],
        passedErrors = [],
        browser = get_browser();
      if (browser.name === "IE" || browser.name === "MSIE") {
        var discountValueAsNumber = parseFloat(
          document.getElementsByClassName("price-match-discount")[0].value
        );
        var discountValueExists = document.getElementsByClassName(
          "price-match-discount"
        )[0].valid;
      } else {
        var discountValueAsNumber = document.getElementsByClassName(
          "price-match-discount"
        )[0].valueAsNumber;
        var discountValueExists = document.getElementsByClassName(
          "price-match-discount"
        )[0].validity.badInput;
      }

      if (_.isNaN(discountValueAsNumber) && discountValueExists) {
        currentErrors[currentErrors.length] = "DISCOUNT_NOT_A_NUMBER";
      } else {
        check1 = true;
        passedErrors[passedErrors.length] = "DISCOUNT_NOT_A_NUMBER";
      }

      var priceMax = this.state.selectedPriceMatchItem.get("priceMax");
      if (!discountValueExists && discountValueAsNumber > priceMax) {
        currentErrors[currentErrors.length] = "DISCOUNT_EXCEEDS_MAXIMUM";
      } else if (!discountValueExists) {
        check2 = true;
        passedErrors[passedErrors.length] = "DISCOUNT_EXCEEDS_MAXIMUM";
      }

      if (currentErrors.length > 0) {
        currentErrors = _.uniq(currentErrors);
        this.checkAndAddErrorState(currentErrors);
      }
      if (passedErrors.length > 0) {
        passedErrors = _.uniq(passedErrors);
        this.checkAndRemoveErrorState(passedErrors);
      }

      if (!check1 || !check2) {
        this.setState({
          applyPriceMatchDiscount: false,
          discountValue: 0,
        });
      }
    }
  };

  onNoteUpdated = () => {
    if (
      typeof document.getElementsByClassName("price-match-note") !==
        "undefined" &&
      document.getElementsByClassName("price-match-note") !== null
    ) {
      var noteValue =
        document.getElementsByClassName("price-match-note")[0].value;
      if (_.size(noteValue) === 0) {
        this.setState({
          applyPriceMatchDiscount: false,
          noteValue: null,
        });
      }
    }
  };

  onCheckPriceMatch = () => {
    var check1 = false,
      check2 = false,
      check3 = false,
      currentErrors = [],
      passedErrors = [];
    if (
      typeof document.getElementsByClassName("price-match-discount") !==
        "undefined" &&
      document.getElementsByClassName("price-match-discount") !== null
    ) {
      var browser = get_browser();
      if (browser.name === "IE" || browser.name === "MSIE") {
        var discountValueAsNumber = parseFloat(
          document.getElementsByClassName("price-match-discount")[0].value
        );
        var discountValueExists = document.getElementsByClassName(
          "price-match-discount"
        )[0].valid;
      } else {
        var discountValueAsNumber = document.getElementsByClassName(
          "price-match-discount"
        )[0].valueAsNumber;
        var discountValueExists = document.getElementsByClassName(
          "price-match-discount"
        )[0].validity.badInput;
      }
      if (_.isNaN(discountValueAsNumber) && !discountValueExists) {
        currentErrors[currentErrors.length] = "DISCOUNT_MISSING";
      } else {
        var check1 = true;
        passedErrors[passedErrors.length] = "DISCOUNT_MISSING";
      }
      if (_.isNaN(discountValueAsNumber) && discountValueExists) {
        currentErrors[currentErrors.length] = "DISCOUNT_NOT_A_NUMBER";
      } else {
        check2 = true;
        passedErrors[passedErrors.length] = "DISCOUNT_NOT_A_NUMBER";
      }
      var priceMax = this.state.selectedPriceMatchItem.get("priceMax");
      if (!discountValueExists && discountValueAsNumber > priceMax) {
        currentErrors[currentErrors.length] = "DISCOUNT_EXCEEDS_MAXIMUM";
      } else if (!discountValueExists) {
        check3 = true;
        passedErrors[passedErrors.length] = "DISCOUNT_EXCEEDS_MAXIMUM";
      }
    }

    if (
      typeof document.getElementsByClassName("price-match-note") !==
        "undefined" &&
      document.getElementsByClassName("price-match-note") !== null
    ) {
      var noteValue =
        document.getElementsByClassName("price-match-note")[0].value;
      if (noteValue === "") {
        currentErrors[currentErrors.length] = "NOTE_MISSING";
      } else {
        var check4 = true;
        passedErrors[passedErrors.length] = "NOTE_MISSING";
      }
    }

    if (
      typeof document.getElementsByClassName(
        "price-match-work-order-number"
      ) !== "undefined" &&
      document.getElementsByClassName("price-match-work-order-number") !== null
    ) {
      var workOrderNumberValue = document.getElementsByClassName(
        "price-match-work-order-number"
      )[0].value;
      if (_.size(workOrderNumberValue) > 40) {
        currentErrors[currentErrors.length] = "WORK_ORDER_TOO_LONG";
      } else {
        var check5 = true;
        passedErrors[passedErrors.length] = "WORK_ORDER_TOO_LONG";
      }
    }

    if (currentErrors.length > 0) {
      currentErrors = _.uniq(currentErrors);
      this.checkAndAddErrorState(currentErrors);
    }
    if (passedErrors.length > 0) {
      passedErrors = _.uniq(passedErrors);
      this.checkAndRemoveErrorState(passedErrors);
    }

    if (check1 && check2 && check3 && check4 && check5) {
      this.setState({
        applyPriceMatchDiscount: true,
        discountValue: discountValueAsNumber,
        noteValue: noteValue,
        workOrderNumberValue: workOrderNumberValue,
      });
    } else {
      this.setState({
        applyPriceMatchDiscount: false,
        discountValue: 0,
        noteValue: null,
        workOrderNumberValue: null,
      });
    }
  };

  checkAndAddErrorState = (error) => {
    if (typeof error === "string") {
      if (!_.contains(this.state.errorState, error)) {
        var newErrorState = this.state.errorState.concat([error]);
        newErrorState = _.uniq(newErrorState);
        this.setState({
          errorState: newErrorState,
        });
      }
    } else {
      var newErrorState = this.state.errorState.concat(error);
      newErrorState = _.uniq(newErrorState);
      this.setState({
        errorState: newErrorState,
      });
    }
  };

  checkAndRemoveErrorState = (error) => {
    if (typeof error === "string") {
      if (_.contains(this.state.errorState, error)) {
        var index = this.state.errorState.indexOf(error);
        var newErrorState = null;
        if (index === 0) {
          newErrorState = this.state.errorState.slice(1);
        } else {
          var length = _.size(this.state.errorState);
          newErrorState = this.state.errorState
            .slice(0, index)
            .concat(this.state.errorState.slice(index - 1, length - 2));
        }
        newErrorState = _.uniq(newErrorState);
        this.setState({
          errorState: newErrorState,
        });
      }
    } else {
      var count = 0;
      while (count < error.length) {
        if (_.contains(this.state.errorState, error[count])) {
          var index = this.state.errorState.indexOf(error[count]);
          var newErrorState = null;
          if (index === 0) {
            newErrorState = this.state.errorState.slice(1);
          } else {
            var length = _.size(this.state.errorState);
            newErrorState = this.state.errorState
              .slice(0, index)
              .concat(this.state.errorState.slice(index - 1, length - 2));
          }
          newErrorState = _.uniq(newErrorState);
          this.setState({
            errorState: newErrorState,
          });
        }
        count++;
      }
    }
  };

  onApplyPriceMatch = () => {
    if (
      this.state.selectedPriceMatchItem &&
      this.state.discountValue !== null &&
      this.state.noteValue !== null
    ) {
      shoppingCartActions.addOptionalLineItemFromWishlist(
        this.props.cartItem.get("cartItemId"),
        this.state.selectedPriceMatchItem.get("lineItemId"),
        this.state.discountValue,
        this.state.workOrderNumberValue,
        this.state.noteValue
      );
      this.setState({
        showPriceMatchModal: false,
        selectedPriceMatchItem: null,
        priceMatchNotesTouched: false,
        workOrderNumberValue: null,
        discountValue: 0,
        noteValue: null,
        applyPriceMatchDiscount: false,
        errorState: [],
      });
    }
  };

  renderItemOptions = () => {
    var optionId = this.props.cartItem.get("optionId");
    if (optionId > 0) {
      var option = this.props.cartItem.get("itemPartOption");
      var name = option.get("optionElements").get(0).get("elementName");
      var value = option.get("optionElements").get(0).get("elementValue");
      var lang = shoppingCartStore.data.get("lang");
      return name.get(lang) + ": " + value.get(lang);
    } else {
      return false;
    }
  };

  onRemoveCartItem = (evt) => {
    checkForEvent: if ("undefined" != typeof evt.keyCode) {
      if (evt.keyCode == 13 || evt.keyCode == 32) {
        evt.preventDefault();
        evt.stopPropagation();
        break checkForEvent;
      }
      return;
    }
    shoppingCartActions.removeItemFromWishlistReview(
      this.props.cartItem.get("cartItemId"),
      this.props.cartItem.get("id"),
      false
    );
  };

  onRemoveLineItemHandleEvent = (lineItemId, evt) => {
    if (evt) {
      checkForEvent: if ("undefined" != typeof evt) {
        if (evt.keyCode == 13 || evt.keyCode == 32) {
          evt.preventDefault();
          evt.stopPropagation();
          break checkForEvent;
        }
        return;
      }
    }
    shoppingCartActions.removeOptionalLineItemFromWishlist(
      this.props.cartItem.get("cartItemId"),
      lineItemId
    );
  };

  onRemoveLineItem = (lineItemId) => {
    shoppingCartActions.removeOptionalLineItemFromWishlist(
      this.props.cartItem.get("cartItemId"),
      lineItemId
    );
  };

  onAddLineItemHandleEvent = (lineItem, evt) => {
    if (evt) {
      checkForEvent: if ("undefined" != typeof evt) {
        if (evt.keyCode == 13 || evt.keyCode == 32) {
          evt.preventDefault();
          evt.stopPropagation();
          break checkForEvent;
        }
        return;
      }
    }
    shoppingCartActions.addOptionalLineItemFromWishlist(
      this.props.cartItem.get("cartItemId"),
      lineItem.get("lineItemId"),
      0,
      "",
      ""
    );
  };

  onAddLineItem = (lineItem, evt) => {
    shoppingCartActions.addOptionalLineItemFromWishlist(
      this.props.cartItem.get("cartItemId"),
      lineItem.get("lineItemId"),
      0,
      "",
      ""
    );
  };

  onOpenPriceMatchModal = (lineItemId) => {
    var lineItem = this.props.cartItem
      .get("optionalItems")
      .filter((tempLineItem) => tempLineItem.get("lineItemId") === lineItemId);
    this.setState({
      showPriceMatchModal: true,
      selectedPriceMatchItem: lineItem.get(0),
    });
  };

  onClosePriceMatchModal = () => {
    this.setState({
      showPriceMatchModal: false,
      selectedPriceMatchItem: null,
      priceMatchNotesTouched: false,
      workOrderNumberValue: null,
      discountValue: 0,
      noteValue: null,
      applyPriceMatchDiscount: false,
      errorState: [],
    });
  };
}
export default WishlistReviewItem;

import { DispatcherActions } from '../constants/DispatcherActions.js';
import { AbstractStore } from './AbstractStore.js';
import { applicationStore } from "./ApplicationStore";

import { getRouterState, sizeOf } from "../service/UtilityService";

import { RouterStates } from "../constants/RouterStates";

var CartStore = AbstractStore.extend({

	constructor: function () {
    this.data = {};
    this.data.shoppingcart = {};
    this.data.togglecart = false;
    this.data.togglecartmodal = false;
    CartStore.__super__.constructor.apply(this, arguments);
	},

	getActionHandlers: function () {
		var handlers = {};
    handlers[DispatcherActions.WISHLIST_REVIEW_LOADED] = this.onReloadCart;
    handlers[DispatcherActions.WISHLIST_REVIEW_CHANGE_ITEM] = this.onReloadCart;
    handlers[DispatcherActions.SHOPPING_CART_REQUEST_EMPTY] = this.emptyCart;
    handlers[DispatcherActions.TOGGLE_CART_MODAL] = this.toggleCartModal;
		return handlers;
	},

	emptyCart: function () {
		this.data.shoppingcart = {};
	},

	onReloadCart: function (response) {
		if (this.enableCartModal()) {
      this.data.shoppingcart = response;
      this.data.togglecartmodal = getRouterState() !== RouterStates.WISHLIST && sizeOf(response.orderItemList) > 0;
		}
		this.emitChange();
	},

	toggleCartModal: function() {
    this.data.togglecartmodal = !this.data.togglecartmodal;
	},

	enableCartModal: function() {
		switch(applicationStore.data.defaultTemplate) {
			case "mazda":
				return true;
			default:
				return false;
		}
	},

});


export var cartStore = new CartStore();

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getApiBaseUrl, getApiCallRedux} from '../../api/ApiService';
import {getToken, initializeApp} from '../../api/AuthService';
import baseInstance from "../features/api/api";
import {getAppDealerId, getSeasonObjectList} from '../../service/UtilityService';
import {vehicleSelectionStore} from "../../stores/VehicleSelectionStore";
import {languageStore} from "../../stores/LanguageStore";

export const selectedDealer = createAsyncThunk(
    'appInfo/selectedDealer',
    async (arg, {getState}) => {
        return fetch(`${getApiBaseUrl()}/retailSiteWebService/dealer/getDealerAccountInfoByDealerId.php`, {
            method: "POST",
            body: JSON.stringify({})
        })
    }
)
export const loadVehicleParameters = createAsyncThunk(
    'appInfo/loadVehicle',
    async ({arg}, {getState}) => {
        let res = await baseInstance.get('/vehicle/validateVehicleKeys.php', {
            dealerId: getAppDealerId(),
            nationalAccountId: 0,
            partType: partType,
            vehicleYear: vehicleYear,
            vehicleManufacturerName: decodeURIComponent(make),
            vehicleCategoryName: decodeURIComponent(category),
            vehicleModelName: decodeURIComponent(model),
            vehicleSubmodelName: decodeURIComponent(trim),
            isNational: applicationStore.data.nationalSite ? 1 : 0
        })

        return res.data;
    }
)

export const loadTireById = createAsyncThunk(
    'appInfo/loadTireById',
    async (arg, {getState}) => {
        let res = await baseInstance.get('/parts/tire/getTireByPartInfo.php', {
            dealerId: getAppDealerId(),
            nationalAccountId: 0,
            brandId: brandId,
            partId: identifier,
            partNumber: identifier,
            isNational: 0,
        });
        // if the response is value add a dispatch to validiate the first response
        return res.data;
    }
)


// loadTireById: function(identifier) {
//   this.apiGet('/parts/tire/getTireByPartInfo.php', {
//     dealerId: getAppDealerId(),
//     brandId: applicationStore.data.selectedBrandId,
//     partId: identifier,
//     partNumber: identifier,
//     isNational: applicationStore.data.nationalSite ? 1 : 0
//   }).then(function (response) {
//     if (response) {
//       applicationStore.data.selectedTireId = response.partDetails.primaryPart.itemId;
//       applicationStore.data.selectedTirePartNumber = response.partDetails.primaryPart.tireManufacturerPartNumber;
//     }
//     this.dispatch(DispatcherActions.TIRE_LOADED_FROM_PART_DATA, response);
//   }.bind(this));
// },

export const loadTireModelInfo = createAsyncThunk(
    'appInfo/loadTireModelInfo',
    async ({arg}, {getState}) => {
        let res = await baseInstance.get('/parts/tireModel/searchTireModelsByModelName.php', {
            dealerId: getAppDealerId(),
            brandId: brandId,
            modelId: modelId,
            modelName: modelName,
            isNational: 0,
        });
        return res.data;
    }
);
// const seasonSelector = (state) => state.appInfo.seasonObjectList;

const loadApplicationInfo = createSlice({
    name: 'appInfo',
    initialState: {
        dealerId: "",
        tireId: "",
        season: 0,
        tokenId: getToken().tokenId,
        appState: [],
        decodeURL: "",
        lang: "en",
        supportVisible: false,
        loginDialog: false,
        forceUserLogin: false,
        displayError: null,
        toggleSearchFlag: false,
        NtdModal: false,
        forgotPasswordModal: false,
        guestAccountModal: false,
        closeOrderStatus: null,
        appRunningOutdatedVersion: null,
        dealerIgnoreTireStock: null,
        dealerIgnoreWheelStock: null,
        dealerIgnoreAccessoryStock: null,
        dealerIgnorePackageStock: null,
        dealerIgnoreMerchandiseStock: null,
        setPartQuantityToOne: null,
        loginIn: null,
        openAuthenticationStateModal: false,
        loginHasExpired: true,
        launcherVehicleYears: [],
        launcherVehicleMakes: [],
        launcherVehicleModels: [],
        selectedMake: null,
        selectedModel: null,
        launcherVehicleProductTypes: [],
        selectedProductType: null,
        loginTextDialogShown:false,

    },
    reducers: {
        dealerId: (state) => {
            let temp = window.location.href;
            let dealer = temp.split('/')
            let lastSegment = dealer.pop() || dealer.pop();
            state.dealerId = lastSegment;
        },
        onSelectMake:(state,{payload}) =>{
            state.selectedMake = payload;
        },
        onSelectModel(state,{payload}){
            state.selectedModel = payload;
        },
        tireId: (state, {payload}) => {
            state.tireId = payload
        },
        getSeason: (state) => {

        },
        appState: (state, {payload}) => {
            state.appState = payload;
            // getApiCallRedux(false);
        },
        decodedUrl: (state, {payload}) => {
            state.decodeURL = payload
        },
        changeLang: (state, {payload}) => {
            state.lang = payload
        },
        toggleSupport: (state, {payload}) => {
            state.supportVisible = payload
        },
        toggleLoginDialog: (state, {payload}) => {
            state.loginDialog = payload;
        },
        toggleLoginTextDialog: (state, {payload}) => {
            state.loginTextDialogShown = payload;
            state.loginDialog = true;
        },
        openForceDialog: (state, {payload}) => {
            state.forceUserLogin = true;
        },
        onDisplayErrorPage: (state, {payload}) => {
            state.displayError = payload
        },
        toggleSearch: (state, {payload}) => {
            state.toggleSearchFlag = payload
        },
        ntdModeToggle: (state, {payload}) => {
            state.NtdModal = payload
        },
        forgotPasswordToggle: (state, {payload}) => {
            state.forgotPasswordModal = payload
        },
        guestModalToggle: (state, {payload}) => {
            state.guestAccountModal = payload;
        },
        closeOrderStatus: (state, {payload}) => {
            state.closeOrderStatus = payload
        },
        dealerIgnoreTireStock: (state, {payload}) => {
            state.dealerIgnoreTireStock = payload;
        },
        dealerIgnoreWheelStock: (state, {payload}) => {
            state.dealerIgnoreWheelStock = payload
        },
        dealerIgnoreAccessoryStock: (state, {payload}) => {
            state.dealerIgnoreAccessoryStock = payload
        },
        dealerIgnorePackageStock: (state, {payload}) => {
            state.dealerIgnorePackageStock = payload;
        },
        dealerIgnoreMerchandiseStock: (state, {payload}) => {
            state.dealerIgnoreMerchandiseStock = payload;
        },
        setPartQuantityToOne: (state, {payload}) => {
            state.setPartQuantityToOne = payload;
        },
        setLogin: (state, {payload}) => {
            state.loginIn = initializeApp();
        },
        toggleAuthenticationStateModal:(state,{payload}) => {
            state.openAuthenticationStateModal = payload;
        },
        setExpiredLogin: (state, {payload}) => {
            state.loginHasExpired = payload;
        },
        loadAppState: (state, {payload}) => {
            let {key, appState, params} = payload;
            var temp;
            if (params[key]) {
                temp = params;
                this.data[key] = decodeURIComponent(temp[key]);
            } else if (appState[key]) {
                temp = appState;
                this.data[key] = decodeURIComponent(temp[key]);
            } else {
                temp = params;
            }
            if (temp.hasOwnProperty(key)) {
                switch (key) {
                    case 'year':
                        if (parseFloat(temp.year) > 1950) {
                            this.data.longInitialDataLoad = true;
                            // setTimeout(() => {setTimeout
                                // console.trace()

                            //     loadVehicleParameters(getDestination(temp.productType.toUpperCase()), temp.year, temp.make, temp.category, temp.model, temp.trim);
                            // }, 0);
                        } else if (temp.year) {
                            if (temp.year) this.data.year = temp.year;
                            if (temp.make) this.data.make = temp.make;
                            if (temp.category) this.data.category = temp.category;
                            if (temp.model) this.data.model = temp.model;
                            if (temp.trim) this.data.trim = temp.trim;
                        }
                        break;
                    case 'selectedSeason':
                        if (temp.selectedSeason || temp.selectedSeason === 0) {
                            if (isNaN(temp.selectedSeason)) {
                                var seasonList = getSeasonObjectList(),
                                    seasonName = decodeURIComponent(temp.selectedSeason);
                                for (var i in seasonList) {
                                    if (seasonList[i] && seasonList[i]['en'].toLowerCase() === seasonName.toLowerCase()) {
                                        this.data.selectedSeason = i;
                                        this.data.selectedSeasonName = seasonList[i]['en'];
                                    }
                                }
                            } else {
                                this.data.selectedSeason = temp.selectedSeason;
                                var seasonList = getSeasonObjectList(),
                                    seasonObject = seasonList[this.data.selectedSeason];
                                this.data.selectedSeasonName = seasonObject['en'];
                            }
                        }
                        break;
                    case 'selectedBrandId':
                        if (temp.selectedBrandId) {
                            if (isNaN(temp.selectedBrandId)) {
                                if (isDealerSite()) {
                                    var brandList = window.dealerAccountInfoPayload.dealerProductFilters.tireManufacturers,
                                        brandName = decodeURIComponent(temp.selectedBrandId);
                                    for (var i in brandList) {
                                        if (brandList[i].part_manufacturer_name.toLowerCase() === brandName.toLowerCase()) {
                                            this.data.selectedBrandId = brandList[i].part_manufacturer_id;
                                            this.data.selectedBrandName = brandList[i].part_manufacturer_name;
                                        }
                                    }
                                } else if (isNationalSite()) {
                                    var brandList = window.nationalAccountInfoPayload.nationalTireManufacturerInfoList,
                                        brandName = decodeURIComponent(temp.selectedBrandId);
                                    for (var i in brandList) {
                                        if (brandList[i].tireManufacturerName.toLowerCase() === brandName.toLowerCase()) {
                                            this.data.selectedBrandId = brandList[i].tireManufacturerId;
                                            this.data.selectedBrandName = brandList[i].tireManufacturerName;
                                        }
                                    }
                                }
                            } else {
                                if (isDealerSite()) {
                                    this.data.selectedBrandId = temp.selectedBrandId;
                                    var brandObject = window.dealerAccountInfoPayload.dealerProductFilters.tireManufacturers[this.data.selectedBrandId];
                                    this.data.selectedBrandName = brandObject.part_manufacturer_name;
                                } else if (isNationalSite()) {
                                    this.data.selectedBrandId = temp.selectedBrandId;
                                    var brandObject = window.nationalAccountInfoPayload.nationalTireManufacturerInfoList[this.data.selectedBrandId];
                                    this.data.selectedBrandName = brandObject.tireManufacturerName;
                                }
                            }
                        }
                        break;
                    case 'modelId':
                        if (temp.modelId) {
                            if (isNaN(temp.modelId)) {
                                this.data.selectedModelData.modelName = decodeURIComponent(temp.modelId);
                                //setTimeout(() => { applicationActions.loadTireModelInfo('', '', decodeURIComponent(temp.modelId)); }, 10);
                                loadTireModelInfo('', '', decodeURIComponent(temp.modelId));
                            } else {
                                this.data.selectedModelId = temp.modelId;
                                //setTimeout(() => { applicationActions.loadTireModelInfo('', temp.modelId, ''); }, 10);
                                loadTireModelInfo('', temp.modelId, '');
                            }
                        }
                        break;
                    case 'tireId':
                        if (temp.tireId) {
                            this.data.selectedTireId = temp.tireId;
                            this.data.selectedTirePartNumber = temp.tireId;
                            //setTimeout(() => { applicationActions.loadTireById(temp.tireId); }, 10);
                            loadTireById(temp.tireId);
                        }
                        break;
                }
            }
        },
        loadYearLauncher: (state, {payload}) => {
            // this is one for loadYearLauncher
            if (payload) {
                const data = payload;
                const launcherVehicleYears = [];

                Object.keys(data).reverse().forEach((key) => {
                    launcherVehicleYears.push({ key, value: data[key] });
                });

                state.launcherVehicleYears = launcherVehicleYears;
            }
        },
        loadMakeLauncher: (state, {payload}) => {
            // loadMakeLauncher
        const data = payload;
        if (data) {
            let launcherVehicleMakes = [];
            const primaryData = data.primaryMakes;
            const secondaryData = data.secondaryMakes;

            primaryData.forEach((item) => {
                launcherVehicleMakes.push({
                    key: item.vehicleManufacturerId,
                    value: item.vehicleManufacturerName
                });
            });

            if (vehicleSelectionStore.shouldShowSecondaryMakes()) {
                secondaryData.forEach((item) => {
                    launcherVehicleMakes.push({
                        key: item.vehicleManufacturerId,
                        value: item.vehicleManufacturerName
                    });
                });
            }

            if (launcherVehicleMakes.length === 1) {
                // state.selectedMake = launcherVehicleMakes[0].key;
                state.launcherVehicleMakes = launcherVehicleMakes;
                /*
                setTimeout(() => {
                  applicationActions.loadLauncherModelSelection(state.data.selectedYear, state.data.launcherVehicleMakes[0].key);
                }, 100);
                */
            } else {
               state.launcherVehicleMakes = launcherVehicleMakes;
            }
        }


        },
        loadModelLauncher: (state, {payload}) => {
            let data = payload;


            if (data.response) {
                let launcherVehicleModels = [];
                let lang = state.lang;
                let selectedModel = null;
                let selectedCategory = "null";
                let selectedProductType = null;

                for (const item of data.response) {

                    let modelId = item.vehicleModelClassId === "0" || item.vehicleModelClassId === 0 ? `null-${item.vehicleModelId}` : `${item.vehicleModelClassId}-${item.vehicleModelId}`;
                    let modelName = item.vehicleModelClassId === "0" || item.vehicleModelClassId === 0 ? item.vehicleModelName[lang] : `${item.vehicleModelClassName[lang]} ${item.vehicleModelName[lang]}`;
                    launcherVehicleModels.push({ key: modelId, value: modelName });
                }
                state.launcherVehicleModels = launcherVehicleModels;
                if (launcherVehicleModels.length === 1) {
                    let tempModel = launcherVehicleModels[0].key.split("-");
                    let category = tempModel[0];
                    selectedModel = tempModel[1];
                    selectedCategory = category;
                    selectedProductType = null;
                    state.selectedModel = launcherVehicleModels[0].key;
                    // setTimeout(() => {
                    //     applicationActions.loadLauncherProductTypeSelection(selectedYear, selectedMake, category, model);
                    // }, 100);
                }
                else{
                    state.selectedMake = data.make;

                }

                // let launcherVehicleProductTypes = [];

                // state.launcherVehicleModels = launcherVehicleModels;
            }
        },
        setLauncherVehicleProductTypes: (state, action) => {
            const {response} = action.payload;
            const {lang } = state;
            const tires = languageStore.getDictionaryTranslate('launcher.tires', lang);
            const wheels = languageStore.getDictionaryTranslate('launcher.wheels', lang);
            const accessories = languageStore.getDictionaryTranslate('launcher.accessories', lang);
            const packages = languageStore.getDictionaryTranslate('launcher.packages', lang);
            state.launcherVehicleProductTypes = [];
            if (response.has_TRE) state.launcherVehicleProductTypes.push({ key: 'tires', value: tires });
            if (response.has_WHL) state.launcherVehicleProductTypes.push({ key: 'wheels', value: wheels });
            if (response.has_ACC) state.launcherVehicleProductTypes.push({ key: 'accessories', value: accessories });
            if (response.has_PKG) state.launcherVehicleProductTypes.push({ key: 'packages', value: packages });

            if (state.launcherVehicleProductTypes.length === 1) {
                state.selectedProductType = state.launcherVehicleProductTypes[0].key;
            } else {
                state.selectedProductType = null;
            }
        },

    },

    extraReducers: {
        [loadVehicleParameters.pending]: (state, {payload}) => {
            state.status = 'pending';
        },
        [loadVehicleParameters.fulfilled]: (state, {payload}) => {
            state.status = 'fulfilled';

        },
        [loadVehicleParameters.rejected]: (state, {payload}) => {
            state.status = 'rejected'
        },
        [loadTireModelInfo.pending]: (state, {payload}) => {
            state.status = 'pending';
        },
        [loadTireModelInfo.fulfilled]: (state, {payload}) => {
            state.status = 'fulfilled';

        },
        [loadTireModelInfo.rejected]: (state, {payload}) => {
            state.status = 'rejected'
        },
        [loadTireById.pending]: (state, {payload}) => {
            state.status = 'pending';
        },
        [loadTireById.fulfilled]: (state, {payload}) => {
            state.status = 'fulfilled';

        },
        [loadTireById.rejected]: (state, {payload}) => {
            state.status = 'rejected'
        }
    }
})

// loadTireById
export const {
    dealerId,
    tireId,
    appState,
    decodedUrl,
    changeLang,
    toggleSupport,
    toggleLoginDialog,
    toggleLoginTextDialog,
    openForceDialog,
    onDisplayErrorPage,
    toggleSearch,
    ntdModeToggle,
    forgotPasswordToggle,
    guestModalToggle,
    closeOrderStatus,
    dealerIgnoreTireStock,
    dealerIgnoreWheelStock,
    dealerIgnoreAccessoryStock,
    dealerIgnorePackageStock,
    dealerIgnoreMerchandiseStock,
    setPartQuantityToOne,
    loadAppState,
    toggleAuthenticationStateModal,
    setLogin,
    setExpiredLogin,
    loadYearLauncher,
    loadMakeLauncher,
    loadModelLauncher,
    setLauncherVehicleProductTypes,
    onSelectMake,
    onSelectModel,

} = loadApplicationInfo.actions;
export default loadApplicationInfo.reducer;
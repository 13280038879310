import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getApiBaseUrl} from "../../api/ApiService";
import {getAppDealerId, sizeOf} from "../../service/UtilityService";
import {
    extractTireSizeInfo,
    onListOfTiresLoaded,
    onListOfWheelLoaded,
    onVehicleInfoLoaded,
    vehicleHasMappedParts
} from "../../service/VehicleService";
import {applicationStore} from "../../stores/ApplicationStore";
import {ProductTypes} from "../../constants/ProductTypes";
import {SearchTypes} from "../../constants/SearchTypes";
import {productListStore} from "../../stores/ProductListStore";
import {getToken} from "../../api/AuthService";


export const loadAccessories = createAsyncThunk(
    'productList/loadAccessories',
    async(arg,{getState})=>{
        let {tokenId,dealerId} = getState().info
        return fetch(`${getApiBaseUrl()}/retailSiteWebService/parts/accessory/searchAccessoriesByVehicle.php`,{
            dealerId:dealerId,
            vehicleId:null,
            isNational:1,
            tokenId:tokenId
        }).then(res => {
            if(!res.ok){
                throw Error(res.statusText);
                return res.json();
            }
        })
        .then(json => json)
    }
)
export const loadWheels = createAsyncThunk(
    'productList/searchWheels',
    async(arg,{getState})=>{
        let {tokenId,dealerId} = getState().info
        return fetch(`${getApiBaseUrl()}/retailSiteWebService/parts/wheel/searchWheelsByVehicle.php`,{
            dealerId:dealerId,
            vehicleId:null,
            isNational:1,
            tokenId:tokenId
        }).then(res => {
            if(!res.ok){
                throw Error(res.statusText);
                return res.json();
            }
        })
        .then(json => json)
    }
)
export const loadPackages = createAsyncThunk(
    'productList/loadPackage',
    async(arg,{getState})=>{
        let {tokenId,dealerId} = getState().info;
        return fetch(`${getApiBaseUrl()}/retailSiteWebService/parts/partPackage/searchPartPackagesByVehicle.php`,{
            dealerId:dealerId,
            vehicleId:null,
            isNational:1,
            tokenId:tokenId
        })
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
                return res.json();
            }
        })
        .then(json => json)
    }
)

export const loadPackagesByYear = createAsyncThunk(
    'productList/loadPackagesYear',
    async(arg,{getState})=>{
        let {tokenId,dealerId} = getState().info;
        return fetch(`${getApiBaseUrl()}/retailSiteWebService/parts/partPackage/searchPartPackagesByYearId.php`,{
            nationalAccountId: applicationStore.data.nationalAccountId,
            dealerId: getAppDealerId(),
            yearId: year,
            isNational: 1,
        })
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
                return res.json();
            }
        })
        .then(json => json)
    }
    )
    export const loadTires = createAsyncThunk(
        'productList/loadTire',
        async(arg,{getState})=>{
            let {tokenId,dealerId} = getState().info;
            let {tireSize,season} = getState().productList
            return fetch(`${getApiBaseUrl()}/retailSiteWebService/parts/tire/searchTiresByVehicle.php`,{
                dealerId:dealerId,
                vehicleId: vehicleSelectionStore.data.vehicleId,
                vehicleSubmodelTireSizeId: tireSize,
                season: season,
                tireManufacturerIds: productListStore.data.brands,
                originalEquipmentFilter: productListStore.data.originalEquipmentOnly == 1 ? "OE" : "ALL",
                searchFailoverMethod: 'TIRESIZE',
                isNational: applicationStore.data.nationalSite ? 1 : 0
            })
            .then(res => {
                if(!res.ok){
                    throw Error(res.statusText);
                    return res.json()
                }
            })
            .then(json => json)
        }
    )
    
    export const loadTiresFromModel = createAsyncThunk(
        'productList/loadTireFromModel',
        async(arg,{getState})=>{
            let {tokenId,dealerId} = getState().info;

            return fetch(`${getApiBaseUrl()}/retailSiteWebService/parts/tire/searchTiresByVehicleModel.php`,{
                dealerId: dealerId,
                vehicleYearId: vehicleSelectionStore.data.year,
                vehicleModelId: vehicleSelectionStore.data.model,
                originalEquipmentFilter: false ? "OE" : "ALL",
                season: productListStore.data.season,
                vehicleSubmodelTireSizeId: productListStore.data.tireSize,
                searchFailoverMethod: "TIRESIZE",
                isNational: applicationStore.data.nationalSite ? 1 : 0,
                tokenId:tokenId
            })
        }
    )

    export const loadTiresFromChassic = createAsyncThunk(
        'productList/loadTiresFromChassis',
        async(arg,{getState})=>{
            let {tokenId,dealerId} = getState().info;
            return fetch(`${getApiBaseUrl()}/retailSiteWebService/parts/tire/searchTiresByVehicleChassis.php`,{
                dealerId: dealerId,
                vehicleChassis: productListStore.data.chassis,
                originalEquipmentFilter: productListStore.data.originalEquipmentOnly ? "OE" : "ALL",
                season: productListStore.data.season,
                vehicleSubmodelTireSizeId: productListStore.data.tireSize,
                searchFailoverMethod: source,
                isNational: applicationStore.data.nationalSite ? 1 : 0
            })
        }
    );

export const fetchTireSearch = createAsyncThunk(
  'search/TireSize',
  async (args,{getState}) =>{
      let {tireId,tokenId} = getState().info
      return fetch(`${getApiBaseUrl()}/retailSiteWebService/parts/tire/searchTiresByTiresize.php`,{
          method:"POST",
          headers:{"Content-Type":"application/json"},
          body:JSON.stringify({
              dealerId:getAppDealerId(),
              tireSize:parseInt(tireId),
              season:0,
              originalEquipment:'ALL',
              isNational:0,
              tokenId:tokenId
          })
      })
        .then(response =>{
            if(!response.ok) throw Error(response.statusText);
            return response.json();
        })
        .then(json => json);

  }
);
function getShadowList(list){
    let shadowList = [];
    let min = 0;
    let max = 0;
        for (let i in list) {
            if (list[i]) {

                if (sizeOf(list[i].partDetails.primaryPart.accessoryCategories) > 0) {

                    for(const j in list[i].partDetails.primaryPart.accessoryCategories) {

                        for (const n in list[i].partDetails.primaryPart.accessoryCategories[j]) {

                            if (shadowList.indexOf(n) === -1 ) {
                                shadowList.push(n);
                            }

                        }

                    }

                }

                var price = 0, context = [], temp = [], priceList = list[i].partPricing.primaryPart.price;
                for (var l in priceList) {
                    if (priceList[l]) {
                        context = priceList[l].contextList; break;
                    }
                }
                for (var m in context) {
                    if (context[m] && (price < parseFloat(context[m].displayPrice))) {
                        price = parseFloat(context[m].displayPrice);
                    }
                }

                if (min > price) {
                    min = price;
                }
                if (max < price) {
                    max = price;
                }

            }
        }
        return {shadowList,min,max};
    }

const productList = createSlice({
    name:'productList',
    initialState:{
        productListWarning:false,
        productDetialsModalPart:null,
        requireItemQuantity:null,
        quantityOfTires:{
            tireKey:null,
            rearTire:null,
            quantity:null,
            front:null,
            featured:null,
            staggered:null,
        },
        quantityOfSelectedTire:{
            tireKey:null,
            quantity:null
        },
        quantityOfWheels:{
            wheelIndex:null,
            quantity:null,
            front:null
        },
        quantityOfAccessory:{
            accessoryId:null,
            quantity:null,
        },
        quantityOfCollections:{
            collectionsIndex:null,
            quantity:null,
        },
        quantityOfPackages:{
            packageIndex:null,
            quantity:null

        },
        oeFilterValue:null,
        runFlatFilterValue:null,
        openDetail:{
            index:null,
            type:null
        },
        detailQuantity:{
            quantity:null,
            front:null
        },
        selectedSeason: window.dealerAccountInfoPayload?.dealerDefaultSearchSeason || 0,
        filterTireSize:null,
        tireSize:null,
        wheelByTireSize:null,
        changeColor:null,
        switchAccessoryView:null,
        closeTireNotOriginalSizeWarning:null,
        closeWheelNotOriginalSizeWarning:null,
        setPartForVisualizer:null,
        setChassicSearchValue:null,
        productListSearch:null,
        promotionalModal:{
            itemType:null,
            itemId:null,
            pricingContextId:null,
            url:null,
            disclaimer:null
        },
        comparisonList:[],
        quanityObject:null,
        onRunQuickComparison:false,
        pdfLauncher:false,
        pdfGuideLauncher:false,
        productList:null,
        WheelList:null,
        accessoryList:null,
        packageList:null,
        productListLoaded:false,
        reduxCall:true,
        seasonCountArray:[],
        tireSizeSpecIdCountArray:[],
        accessoryFilterTagList:[],
        productListFeaturedList:[],
        accessoryCategoryShadowList:[],
        accessoryListMinPrice:0,
        accessoryListMaxPrice:0,
        tireListMinPrice:0,
        tireListMaxPrice:0,
        loadRatingList:[],
        speedRatingList:[],
        plyRatingList:[],
        vehicleId:null,
        subModelTireId:null,
        filterByTireSize:null,
        productFeaturedList:[],
        filteredBrandIds:[],
        tireFilterTagList:[],
        source:null,
        comparisonLoadedSuccessfully:false,
        renderSavingToggle:false,
        visualizerColours:[],
        visualizerForeground:'',
        visualizerShadow:'',
        colourCodes:[],
        colourCodeInDetail:null,
        vehiclesMapped:[],
        tireSizeFilters:[],
        createDealerAccountModal:false,
        vehicleFullTireSizeInfo:undefined,
        VehicleOriginalTireSizeInfo:undefined,




    },
    reducers:{
        resettireSizeFilters:(state)=>{
            state.tireSizeFilters = [];
            state.filterByTireSize = null;
            state.tireSize = null;
        },
        signUpRequestModal:(state,{payload})=>{
            state.createDealerAccountModal = payload;
        },
        changeOnLoading:(state,{payload})=>{
            state.reduxCall = payload;
        },
        productListWarning:(state,{payload})=>{
            state.productListWarning = payload
        },
        loadProductDetailModalPart:(state,{payload})=>{
            state.productDetailModal = payload
        },
        changeRequiredItemQuantity:(state,{payload})=>{
            state.requireItemQuantity = payload
        },
        renderSavingToggleState:(state,{payload})=>{
            state.renderSavingToggle = payload
        },
        changeQuantityOfTires:(state,{payload})=>{
            state.renderSavingToggle = false;
            state.quantityOfTires.tireKey = payload.tireKey;
            state.quantityOfTires.rearTire = payload.rearTire;
            state.quantityOfTires.quantity = payload.quantity;
            state.quantityOfTires.front = payload.front;
            state.quantityOfTires.featured = payload.featured;
            state.quantityOfTires.staggered = payload.staggered;
            state.renderSavingToggle = true;
        },
        changeQuantityOfSelectedTire:(state,{payload})=>{
            state.quantityOfSelectedTire.tireKey = payload.tireKey;
            state.quantityOfSelectedTire.quantity = payload.quantity;
        },
        changeQuantityOfWheels:(state,{payload})=>{
            state.quantityOfWheels.wheelIndex = payload.wheelIndex;
            state.quantityOfWheels.quantity = payload.quantity;
            state.quantityOfWheels.front = payload.front;
        },
        changeQuantityOfAccesory:(state,{payload})=>{
            state.quantityOfAccessory.accessoryId = payload.accessoryId;
            state.quantityOfAccessory.quantity = payload.quantity;
        },
        changeQuantityOfCollections:(state,{payload})=>{
            state.quantityOfCollections.collectionsIndex = payload.collectionsIndex;
            state.quantityOfCollections.quantity = payload.quantity;
        },
        changeQuantityOfPackages:(state,{payload})=>{
            state.quantityOfPackages.packageIndex = payload.packageIndex;
            state.quantityOfPackages.quantity = payload.quantity;

        },
        setOeFilterValue:(state,{payload})=>{
            state.oeFilterValue = payload;
        },
        setRunFlatFilterValue:(state,{payload})=>{
            state.runFlatFilterValue = payload;
        },
        openDetail:(state,{payload})=>{
            state.openDetail.index = payload.index;
            state.openDetail.type = payload.type;
        },
        changeDetailQuantity:(state,{payload})=>{
            state.detailQuantity.quantity = payload.quantity;
            state.detailQuantity.front = payload.front;
        },
        filterBySeason:(state,{payload})=>{
            state.productListLoaded = false;
            state.selectedSeason = payload;
            if (applicationStore.data.productType === ProductTypes.TIRES) {
                if (productListStore.data.searchType === SearchTypes.SEARCH_BY_MODEL) {
                    state.source = 'SEASON';
                } else if (productListStore.data.searchType === SearchTypes.SEARCH_BY_CHASSIS) {
                    state.source = 'SEASON';
                } else if (productListStore.data.searchType === SearchTypes.SEARCH_BY_VEHICLE) {
                    state.source = 'SEASON';
                }
            }
        },
        filterByTireSize:(state,{payload})=>{
            state.filterByTireSize = payload;
        },
        setTireSize:(state,{payload})=>{
            state.tireSize = payload;
        },
        filterWheelByTireSize:(state,{payload})=>{
            state.filterWheelByTireSize = payload;
        },
        changeColor:(state,{payload})=>{
            state.changeColor = payload
        },
        switchAccessoryView:(state,{payload})=>{
            state.switchAccessoryView = payload
        },
        closeTireNotOriginalSizeWarning:(state,{payload})=>{
            state.closeTireNotOriginalSizeWarning = payload
        },
        closeWheelNotOriginalSizeWarning:(state,{payload})=>{
            state.closeWheelNotOriginalSizeWarning = payload;
        },
        setChassicSearchValue:(state,{payload})=>{
            state.setChassicSearchValue = payload;
        },
        setProductListSearchType:(state,{payload})=>{
            state.productListSearch = payload;
        },
        anOpenEmailPromotionalModal:(state,{payload})=>{
            state.promotionalModal.itemType = payload.itemType;
            state.promotionalModal.itemId = payload.itemId;
            state.promotionalModal.pricingContextId = payload.url;
            state.promotionalModal.disclaimer = payload.disclaimer;

        },
        resetProductComparison:(state,{payload})=>{
            state.comparisonList = [];
        },
        onAddProductToComparison:(state,{payload})=>{
            // Find the index of the payload in the comparisonList
            const index = state.comparisonList.findIndex((item) => item.id === payload.id);

            // If the payload is already in the comparisonList, remove it
            if (index !== -1) {
                state.comparisonList.splice(index, 1);
            }
            // If the list has less than 3 items and the payload is not in the comparisonList, add it
            else if (state.comparisonList.length < 3) {
                state.comparisonList.push(payload);
            }
        },
        removeProductFromComparison:(state,{payload})=>{
            const index = state.comparisonList.findIndex((item) => item.id === payload.id);
            if (index !== -1) {
                state.comparisonList.splice(index, 1);
            }

        },
        onComparisonLoadedSuccessfully:(state,{payload})=>{
            state.comparisonLoadedSuccessfully = payload;
        },
        onSetComparisonPartQuantity:(state,{payload})=>{
            state.quanityObject = payload;
        },
        onRunQuickComparison:(state,{payload})=>{
            state.onRunQuickComparison = payload;
        },
        onSetComparisonPdfLauncher:(state,{payload})=>{
            state.pdfLauncher = payload;
        },
        onSetGuidePdfLauncher:(state,{payload})=>{
            state.pdfGuideLauncher = payload;
        },
        loadVehicleInfo:(state,{payload})=>{
            if (Object.keys(payload).length<2){
                return;
            }

            // this fixes the marriage between redux and flux
            let oldTireSize = productListStore.data.tireSize||state.tireSize;
            let vehicleInfo = onVehicleInfoLoaded(payload,oldTireSize);
            state.productList = [];
            state.subModelTireId = vehicleInfo.vehicleSubTireId;
            state.tireSize = vehicleInfo.tireSize;
            productListStore.data.tireSize = vehicleInfo.tireSize;
            let vehicleSizeInfo =extractTireSizeInfo(payload);

            state.vehicleId = vehicleInfo.vehicleId;
            state.vehicleFullTireSizeInfo = vehicleSizeInfo.vehicleFullTireSizeInfo;
            state.VehicleOriginalTireSizeInfo = vehicleSizeInfo.originalTireSizeInfo;
            // state.vehicleFullTireSizeInfo = vehicleInfo.vehicleFullTireSizeInfo;
            // state.productFeaturedList = vehicleInfo.productFeaturedList;
            state.tireSizeFilters = vehicleInfo.tireSizeFilters;
            state.vehicleInfo = payload;
            state.vehiclesMapped = vehicleHasMappedParts(payload.vehicleHasMappedParts);
            // Visualizer data
            state.visualizerColours = vehicleInfo.visualizerColours;
            state.visualizerForeground = vehicleInfo.visualizerForeground;
            state.visualizerShadow = vehicleInfo.visualizerShadow;
            state.colourCodes = vehicleInfo.colourCodes;
            state.colourCodeInDetail = vehicleInfo.colourCodeInDetail;
            // // access state from another slice of state


        },
        onListAccessoriesLoaded:(state,{payload})=>{
            let productData = getShadowList(payload.partList);
            state.productList = payload.partList;
              state.accessoryList = payload.partList;
            state.accessoryCategoryShadowList = productData.shadowList;
            state.accessoryListMinPrice = productData.min;
            state.accessoryListMaxPrice = productData.max;
            state.productListLoaded = true;
            state.reduxCall = false;
        },
        onListTiresLoaded:(state,{payload})=>{


            if (Object.keys(payload).length<2){
                state.productListLoaded = true;
                state.reduxCall = false;
                state.productList=[];
                return;
            }
            let productData = onListOfTiresLoaded(payload);
            state.productList = payload.partList;
            state.seasonCountArray = productData.seasonCountArray;
            state.tireSizeSpecIdCountArray = productData.tireSizeSpecIdCountArray;
            state.tireListMinPrice = productData.tireListMinPrice;
            state.tireListMaxPrice = productData.tireListMaxPrice;
            state.loadRatingList = productData.loadRatingList;
            state.speedRatingList = productData.speedRatingList;
            state.plyRatingList = productData.plyRatingList;
            state.productFeaturedList = productData.productFeaturedList;
            state.filteredBrandIds = productData.filteredBrandIds;
            state.tireFilterTagList = productData.tireFilterTagList;
            state.tireSizeFilters =  payload.resultParameters.vehicleSubmodelTireSizeId;
            state.productListLoaded = true;
            state.filterByTireSize = payload.resultParameters.vehicleSubmodelTireSizeId;
            state.tireSize =  payload.resultParameters.vehicleSubmodelTireSizeId;
            state.selectedSeason = payload.resultParameters.season;
            state.reduxCall = false;
            // state.vehicleId = payload.vehicleId;
        },
        onListWheelsLoaded:(state,{payload})=>{
            let filterTireSize = state.tireSizeFilters;
            let productData = onListOfWheelLoaded(payload,filterTireSize,state.selectedSeason,state.selectedSeason,state.subModelTireId);
            state.productList = productData.productList;
            state.WheelList = productData.productList;
            state.tireListMinPrice = productData.wheelListMinPrice;
            state.tireListMaxPrice = productData.wheelListMaxPrice;
            state.tireFilterTagList = productData.wheelFilterTagList;
            state.filteredBrandIds = productData.filteredBrandIds;
            state.productFeaturedList = productData.productFeaturedList;
            state.seasonCountArray = productData.seasonCountArray;
            state.tireSizeSpecIdCountArray = productData.tireSizeSpecIdCountArray;
            state.tireSizeFilters = productData.tireSizeFilters;
            state.productListLoaded = true;
            state.reduxCall = false;

        },
        onListPackagesLoaded:(state,{payload})=>{
            state.productList = payload;
            state.packageList =  payload;

            state.productListLoaded = true;
            state.reduxCall = false;
        },
        productListLoading:(state,action)=>{
            state.productListLoaded = false;
            state.productList=[];
        },
        toggleRenderSavingToggle:(state,action)=>{
            state.renderSavingToggle = action.payload;
        },


    },
    extraReducers:{
        [loadAccessories.pending]:(state,action)=>{
            state.status = 'pending';
        
        },
        [loadAccessories.fulfilled]:(state,action)=>{
            state.status = 'fulfilled';

        },
        [loadAccessories.rejected]:(state,action)=>{
            state.status = 'rejected';
        },
        [loadWheels.pending]:(state,action)=>{
            state.status = 'pending';

        },
        [loadWheels.fulfilled]:(state,action)=>{
            state.status = 'fulfilled';

        },
        [loadWheels.rejected]:(state,action)=>{
            state.status = 'rejected'
        },
        [loadPackagesByYear.pending]:(state,action)=>{
            state.status = 'pending'
        },
        [loadPackagesByYear.fulfilled]:(state,action)=>{
            state.status = 'fulfilled'
        },
        [loadPackagesByYear.rejected]:(state,action)=>{
            state.status = 'rejected'
        },
        [loadTires.pending]:(state,action)=>{
            state.status = 'pending'
        },
        [loadTires.fulfilled]:(state,action)=>{
            state.status = 'fulfilled'
        },
        [loadTires.rejected]:(state,action)=>{
            state.status = 'rejected'
        },
        [fetchTireSearch.pending]:(state,action) =>{
            state.status = 'loading';
        },
        [fetchTireSearch.fulfilled]:(state,{payload})=>{
            if (!payload.success&&Object.keys(payload).length<2){
                state.status = 'loading';
               return;
            }
            let vehicleInfo = onVehicleInfoLoaded(payload);
            state.subModelTireId = vehicleInfo.vehicleSubTireId;
            state.vehicleId = vehicleInfo.vehicleId;
            state.vehicleFullTireSizeInfo = vehicleInfo.vehicleFullTireSizeInfo;
            // state.productFeaturedList = vehicleInfo.productFeaturedList;
            state.vehicleInfo = payload;
            state.status = 'loading';
        },
        [fetchTireSearch.rejected]:(state,action)=>{
            state.status = 'failed'
        },
    }
})


export const {
    productListWarning,
    loadProductDetailModalPart,
    changeRequiredItemQuantity,
    changeQuantityOfTires,
    changeQuantityOfSelectedTire,
    changeQuantityOfWheels,
    changeQuantityOfAccesory,
    changeQuantityOfCollections,
    changeQuantityOfPackages,
    setOeFilterValue,
    setRunFlatFilterValue,
    openDetail,
    changeDetailQuantity,
    filterBySeason,
    filterByTireSize,
    setTireSize,
    filterWheelByTireSize,
    changeColor,
    switchAccessoryView,
    closeTireNotOriginalSizeWarning,
    setChassicSearchValue,
    setProductListSearchType,
    anOpenEmailPromotionalModal,
    onAddProductToComparison,
    onSetComparisonPartQuantity,
    onSetComparisonPdfLauncher,
    onSetGuidePdfLauncher,
    onListAccessoriesLoaded,
    onListTiresLoaded,
    loadVehicleInfo,
    onListWheelsLoaded,
    onListPackagesLoaded,
    onComparisonLoadedSuccessfully,
    resetProductComparison,
    renderSavingToggleState,
    changeOnLoading,
    productListLoading,
    resettireSizeFilters,
    removeProductFromComparison,
    signUpRequestModal,
    toggleRenderSavingToggle,
    onRunQuickComparison,




} = productList.actions;
export default productList.reducer;
import { languageStore } from "../../stores/LanguageStore.js";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { productListStore } from "../../stores/ProductListStore.js";
import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";
import { applicationActions } from "../../actions/ApplicationActions.js";

import { Image } from "../ux/Image.jsx";
import { ProductTypes } from "../../constants/ProductTypes.js";

import { renderTirePricing } from "../../service/RenderService.js";
import { renderNTDButton } from "../../service/OrderService.js";
import {
  sizeOf,
  getTabIndex,
  getAppLanguage,
} from "../../service/UtilityService";
import { isUserLoggedIn } from "../../api/AuthService";
import { connect } from "react-redux";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var $ = require("jquery");

class SearchTire extends I18nComponent {
  state = {
    tireId: applicationStore.data.selectedTireId,
    partNumber: applicationStore.data.selectedTirePartNumber,
    tire: productListStore.data.selectedTireData,
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
    productListStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
    productListStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    $(function () {
      $("body").animate({ scrollTop: 0 }, "slow");
    });
    if (sizeOf(this.state.tire) === 0)
      setTimeout(() => {
        applicationActions.loadTireById(this.state.tireId);
      }, 10);
  }

  render() {
    var product = this.state.tire;
    if (product) {
      return (
        <div className="tire-model-list">
          <div className="tire-model-header">
            <div className="model-title">
              <h2>{product.get("name")}</h2>
            </div>
          </div>

          <div className="tire-model-body">
            <div className="tire-model-image">{this.renderTireImage()}</div>

            <div className="info-section">
              <div className="tire-model-info tire-info">
                <div className="info-section">{this.renderTireInfo()}</div>

                <div className="info-section pricing">
                  {this.renderPricing()}
                  {this.renderActions()}
                </div>
              </div>

              <div className="tire-model-info">
                {this.renderDescription()}
                {this.renderWarranty()}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div className="tire-model-list"></div>;
    }
  }

  renderTireInfo = () => {
    var product = this.state.tire,
      hasNationalPartNumber = false;
    if (
      typeof product.get("frontTire").get("nationalPartNumber") !==
        "undefined" &&
      product.get("frontTire").get("nationalPartNumber") !== null &&
      product.get("frontTire").get("nationalPartNumber") !== ""
    ) {
      hasNationalPartNumber = true;
    }
    var dualLoadRating = false;
    if (product.get("frontTire").get("dualFitmentLoadRating") > 0) {
      dualLoadRating = product.get("frontTire").get("dualFitmentLoadRating");
    }
    var plyRating = false;
    if (product.get("frontTire").get("plyRating")) {
      plyRating = product.get("frontTire").get("plyRating");
    }
    return (
      <div className="tire-info">
        <ul>
          <li>
            <span>{this.t("product.partNumber") + ":"}</span>
            <span className="feature-value part-number">
              {product.get("frontTire").get("partNumber")}
            </span>
          </li>
          {hasNationalPartNumber ? (
            <li>
              <span>{this.t("product.nationalPartNumber") + ":"}</span>
              <span className="feature-value part-number">
                {product.get("frontTire").get("nationalPartNumber")}
              </span>
            </li>
          ) : (
            false
          )}
          <li>
            <span>{this.t("product.tireSize") + ":"}</span>
            <span className="feature-value">
              {product.get("frontTire").get("tireVehicleClass") +
                product.get("frontTire").get("tireSize")}
            </span>
          </li>
          <li className="info-load-speed-ply">
            <span>{this.t("product.loadSpeedPly") + ":"}</span>
            <span> </span>
            <span className="feature-value">
              {product.get("loadRating")}{" "}
              {dualLoadRating ? <span className="slash"> / </span> : false}{" "}
              {dualLoadRating} / {product.get("speedRating")}{" "}
              {plyRating ? <span className="slash"> / </span> : false}{" "}
              {plyRating}
            </span>
          </li>
        </ul>
      </div>
    );
  };

  renderDescription = () => {
    var product = this.state.tire,
      description = languageStore.getTranslated(
        product.get("description").toJS()
      );
    if (description) {
      return (
        <div className="tire-description">
          <span className="label-bold">
            {this.t("product.description") + ": "}
          </span>
          <span
            className="description-link text-small"
            dangerouslySetInnerHTML={{ __html: description }}
          ></span>
        </div>
      );
    } else {
      return false;
    }
  };

  renderWarranty = () => {
    var product = this.state.tire,
      itemWarranty = false;
    if (product.get("frontTire").get("warranty")) {
      product
        .get("frontTire")
        .get("warranty")
        .forEach(function (warranty) {
          if (
            warranty &&
            applicationStore.data.dealerInfo &&
            applicationStore.data.dealerInfo.country &&
            getAppLanguage()
          ) {
            if (
              warranty.get("warrantyCountryCode") &&
              String(warranty.get("warrantyCountryCode")).toUpperCase() ==
                applicationStore.data.dealerInfo.country &&
              warranty.get("warrantyLanguageCode") &&
              String(warranty.get("warrantyLanguageCode")).toLowerCase() ==
                getAppLanguage()
            ) {
              itemWarranty = warranty.get("warrantyText");
            }
          }
        });
    }
    if (
      itemWarranty &&
      itemWarranty.indexOf(
        "Treadlife Warranty: 45,000 - 65,000 miles - - -"
      ) !== -1
    ) {
      itemWarranty =
        itemWarranty.substr(0, itemWarranty.indexOf("miles - - -") + 5) + "...";
      return (
        <div className="tire-warranty">
          <span className="label-bold">
            {this.t("product.warranty") + ": "}
          </span>
          <span
            className="warranty-link text-small"
            dangerouslySetInnerHTML={{ __html: itemWarranty }}
          ></span>
        </div>
      );
    } else if (itemWarranty && itemWarranty.length <= 75) {
      return (
        <div className="tire-warranty">
          <span className="label-bold">
            {this.t("product.warranty") + ": "}
          </span>
          <span
            className="warranty-link text-small"
            dangerouslySetInnerHTML={{ __html: itemWarranty }}
          ></span>
        </div>
      );
    } else {
      if (itemWarranty && itemWarranty.length > 0) {
        return (
          <div className="tire-warranty">
            <span className="label-bold">
              {this.t("product.warranty") + ": "}
            </span>
            <span
              className="warranty-link text-small"
              dangerouslySetInnerHTML={{ __html: itemWarranty }}
            ></span>
          </div>
        );
      } else {
        return false;
      }
    }
  };

  renderPricing = () => {
    return renderTirePricing(this.state.tire, this.onChangeTireQuantity);
  };

  renderActions = () => {
    //Collect Stock
    var product = this.state.tire,
      stockList = product.get("frontTire").get("stock"),
      stockTotal = 0;
    if (stockList)
      stockList.map(function (stock) {
        stockTotal += parseInt(stock.get("stockQty"));
      });

    //TBC Stock
    var inStock =
      (!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) ||
        isUserLoggedIn()) &&
      (this.state.stockQueryEmpty
        ? this.state.stockQueryEmpty.indexOf(
            product.get("frontTire").get("id")
          ) === -1
        : true) &&
      (product.get("frontTire").get("hasStock") ||
        applicationStore.data.dealerIgnoreTireStock);

    //Add To Cart
    var hasAddToCart =
      product.get("frontTire").get("hasPricing") &&
      product.get("frontTire").get("pricing").get("displayPrice") > 0 &&
      (!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) ||
        isUserLoggedIn()) &&
      (inStock || stockTotal > 0);

    //Order Tire
    var hasNtdOrder = product.get("frontTire").get("supportDealerOrder");

    //Purchase Tire
    var hasNtdPurchase =
      !hasNtdOrder &&
      hasAddToCart &&
      product.get("frontTire").get("supportConsumerOrder");

    return (
      <div className="tire-actions tire-actions-desktop">
        <ul>
          {hasNtdOrder
            ? renderNTDButton(
                this.onAddToBasket.bind(this, product, "order"),
                "order",
                true
              )
            : false}
          {hasNtdPurchase
            ? renderNTDButton(
                this.onAddToBasket.bind(this, product, "purchase"),
                "purchase",
                true
              )
            : false}
          {hasAddToCart &&
          !hasNtdOrder &&
          !hasNtdPurchase &&
          (inStock || stockTotal > 0) ? (
            <li>
              <a
                className="btn"
                role="button"
                tabIndex={getTabIndex()}
                aria-label={this.t("product.addToWishlist")}
                onKeyDown={(e) => this.onAddToBasketOnKeyDown(product, e)}
                onClick={this.onAddToBasket.bind(this, product, "")}
              >
                {this.t("product.addToWishlist")}
              </a>
            </li>
          ) : (
            false
          )}
        </ul>
      </div>
    );
  };

  renderTireImage = () => {
    var product = this.state.tire,
      imageAlt = product.get("frontTire").get("partNumber")
        ? product.get("name") + " " + product.get("frontTire").get("partNumber")
        : product.get("name") +
          " " +
          product.get("frontTire").get("nationalPartNumber");
    var featuredPositionName = false;
    var ariaLabelFeatured = "";
    if (product.get("featured") && product.get("position")) {
      switch (product.get("position")) {
        case 1:
          featuredPositionName = (
            <span className="featured-text">{this.t("product.good")}</span>
          );
          ariaLabelFeatured = this.t("product.good");
          break;
        case 2:
          featuredPositionName = (
            <span className="featured-text">{this.t("product.better")}</span>
          );
          ariaLabelFeatured = this.t("product.better");
          break;
        case 3:
          featuredPositionName = (
            <span className="featured-text">{this.t("product.best")}</span>
          );
          ariaLabelFeatured = this.t("product.best");
          break;
        default:
          break;
      }
    }
    var originalEquipmentText;
    if (!!product.get("originalEquipment")) {
      originalEquipmentText = (
        <span
          className="original-text"
          onClick={this.addTireToCompare.bind(this, false)}
        >
          {this.t("product.approvedTire")}
        </span>
      );
    } else {
      originalEquipmentText = false;
    }
    var runFlatClass = "icon-run-flat-en";
    var runFlatImgSrc = "/static/img/icon_run_flat_en.png";
    if (languageStore.data.lang == "fr" || getAppLanguage() === "fr") {
      runFlatClass = "icon-run-flat-fr";
      runFlatImgSrc = "/static/img/icon_run_flat_fr.png";
    }

    return (
      <div className="tire-image">
        <Image
          className="tire-main-image"
          src={product.get("largeImage")}
          alt={"Image Part " + imageAlt}
        />
        {featuredPositionName}
        {originalEquipmentText}
        <Image
          src={product.get("brandImage")}
          className="image-brand"
          alt={"Image Brand " + product.get("manufacturer")}
        />
        {product.get("frontTire").get("runFlat") === "1" ? (
          <div className={runFlatClass}>
            <i title={this.t("product.runFlat")}>
              {" "}
              <img
                src={runFlatImgSrc}
                alt={this.t("product.runFlat")}
              ></img>{" "}
            </i>
          </div>
        ) : (
          false
        )}
      </div>
    );
  };

  onChangeTireQuantity = (quantity) => {
    var product = this.state.tire;
    this.props.dispatch({
      tireKey: product.get("frontTire").get("id"),
      quantity: quantity,
    });
    // productListActions.changeQuantityOfSelectedTire(product.get('frontTire').get('id'), quantity);
  };

  onAddToBasketOnKeyDown = (product, evt) => {
    checkForEvent: if ("undefined" != typeof evt.keyCode) {
      if (evt.keyCode == 13 || evt.keyCode == 32) {
        evt.preventDefault();
        evt.stopPropagation();
        break checkForEvent;
      }
      return;
    }
    this.onAddToBasket(product, "");
  };

  onAddToBasket = (product, source = "") => {
    $(function () {
      $("body").animate({ scrollTop: 0 }, "slow");
    });
    shoppingCartActions.addItemToShoppingCart(
      product.get("frontTire").get("id"),
      {
        en: product.get("name"),
        fr: product.get("name"),
      },
      product.get("smallImage"),
      product.get("frontTire").get("pricing").get("displayPrice"),
      product.get("frontTire").get("pricing").get("privatePrice"),
      product.get("frontTire").get("pricing").get("promotionPricingText"),
      parseInt(product.get("frontTire").get("quantity").get("value"), 10),
      ProductTypes.TIRES,
      product,
      0,
      product.get("frontTire").get("pricing").get("pricingContextId"),
      product.get("relatedParts"),
      0,
      product.get("frontTire").get("pricing").get("listPrice"),
      source === "purchase",
      source === "order",
      "Tire Page"
    );
  };

  onChange = () => {
    this.setState({
      tireId: applicationStore.data.selectedTireId,
      partNumber: applicationStore.data.selectedTirePartNumber,
      tire: productListStore.data.selectedTireData,
    });
  };
}

export default connect(null)(SearchTire);

//import { enableAdobeLaunch, setAdobeLauncherDigitalData } from "../service/AdobeLaunchService";

var Base = require('backbone-extend-node');
var _ = require('lodash');
var Assert = require('assert');


var FluxDispatcher = require('flux').Dispatcher;

FluxDispatcher.extend = Base.extend;


/**
 * Custom implementation of Flux dispatcher
 * allowing easily reset to initial state,
 * this implementation makes testing easier
 */

var Dispatcher = FluxDispatcher.extend({

  dispatch: function(action) {
    Assert.notEqual(action.actionType, null);
    Assert.notEqual(action.actionType, undefined);

    console.log(action.actionType);

    /*
    if (enableAdobeLaunch()) {
      setAdobeDigitalData(setAdobeLauncherDigitalData());
      //runAdobePageView();
    }
    */

    Dispatcher.__super__.dispatch.apply(this, arguments);
  },

  register: function() {
    if (!this.callbackIds) {
      this.callbackIds = [];
    }

    var callbackId = Dispatcher.__super__.register.apply(this, arguments);
    this.callbackIds.push(callbackId);

    return callbackId;
  },

  /**
   * Unregisters all the callbacks so that Dispatcher is empty
   */
  reset: function() {
    if (this.isDispatching()) {
      throw new Error('Cannot reset dispatcher while dispatching is in progress');
    }

    if (this.callbackIds) {
      var callbacksCount = this.callbackIds.length;

      _.each(this.callbackIds, (function(callbackId) {
        this.unregister(callbackId);
      }).bind(this));
      this.callbackIds = [];

      return callbacksCount;
    } else {
      return 0;
    }
  },

  getNumberOfCallbacks: function() {
    return this.callbackIds ? this.callbackIds.length : 0;
  },

});

if (!window.dispatcher) {
  window.dispatcher = new Dispatcher();
}

export var dispatcher = window.dispatcher;

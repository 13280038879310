import { globalState } from '../GlobalState.js';
import { ProductTypes } from '../constants/ProductTypes.js';
import { RouterStates } from '../constants/RouterStates.js';
import { AbstractStore } from './AbstractStore.js';
import { applicationStore } from '../stores/ApplicationStore.js';
import { Router } from '../stores/Router.js';
import { productListActions } from '../actions/ProductListActions.js';
import { shoppingCartStore } from '../stores/ShoppingCartStore.js';
import { shoppingCartActions } from '../actions/ShoppingCartActions.js';

import { getDestinationInverse } from '../api/RequestBuilderHelper.js';

import { DispatcherActions } from '../constants/DispatcherActions.js';

import { checkOrderIdentifierIsGUID } from '../service/OrderService';
import { decodeQuoteName, isCompleteOrder, isConsumerComplete } from '../service/WishlistService';
import { sizeOf, isEmpty, checkArrayFieldsExist } from '../service/UtilityService';
import {
  getPricingByQuantity,
  getRebateByQuantity,
  getPricingFromQuantityAndDetailsRefactored,
  tireMappingFunction,
  wheelMappingFunction,
  accessoryMappingFunction,
  packageMappingFunction,
  getQuantitiesFromPricingDetailsRefactored
} from '../service/ProductList';

var Immutable = require('immutable');
var Moment = require('moment');

var ShoppingCartViewStore = AbstractStore.extend({

	constructor: function () {
		ShoppingCartViewStore.__super__.constructor.apply(this, arguments);
    this.resetShoppingCartView();
	},

	getActionHandlers: function () {
		var handlers = {};
    handlers[DispatcherActions.INITIALIZE_APP_STATE] = this.onInitializeAppState;
    handlers[DispatcherActions.QUOTE_EMAIL_SENT] = this.onQuoteEmailSent;
    handlers[DispatcherActions.QUOTE_APPOINTMENT_SENT] = this.onAppointmentSent;
		handlers[DispatcherActions.WISHLIST_REVIEW_LOADED] = this.shoppingCartViewLoaded;
		handlers[DispatcherActions.SHOPPING_CART_REQUEST_EMPTY] = this.resetShoppingCartView;
    handlers[DispatcherActions.SHOPPING_CART_RELOADED] = this.updateOrderStatus;
		handlers[DispatcherActions.FOXY_CART_GENERATED] = this.foxyCartGenerated;
    handlers[DispatcherActions.CONSUMER_ORDER_LOADED] = this.foxyCartGenerated;
    handlers[DispatcherActions.FOXY_CART_ERROR_CLOSE] = this.onCloseFoxyCartErrorModal;
    handlers[DispatcherActions.SHOPPING_CART_GET_NEW_CART] = this.getNewCart;
    handlers[DispatcherActions.SHOPPING_CART_FORM_SUCCESS] = this.shoppingCartFormSuccess;
    handlers[DispatcherActions.SHOPPING_CART_FORM_ERROR] = this.shoppingCartFormError;
    handlers[DispatcherActions.SHOPPING_CART_REMINDER_SUCCESS] = this.shoppingCartReminderSuccess;
    handlers[DispatcherActions.SHOPPING_CART_REMINDER_ERROR] = this.shoppingCartReminderError;
    handlers[DispatcherActions.SHOPPING_CART_DEALER_FORM_SUCCESS] = this.shoppingCartDealerFormSuccess;
    handlers[DispatcherActions.SHOPPING_CART_DEALER_FORM_ERROR] = this.shoppingCartDealerFormError;
    handlers[DispatcherActions.SHOPPING_CART_DEALER_FORM_RESET_ERROR] = this.shoppingCartDealerResetError;
    handlers[DispatcherActions.OPEN_MARK_AS_COMPLETE_MODAL] = this.openMarkAsCompleteModal;
    handlers[DispatcherActions.CLOSE_MARK_AS_COMPLETE_MODAL] = this.closeMarkAsCompleteModal;
    handlers[DispatcherActions.TOGGLE_FINANCE_MODAL] = this.toggleFinancingModal;
		return handlers;
	},

	resetShoppingCartView: function () {
		this.data = Immutable.Map({
      shoppingCartViewCreated: Moment(),
      appointmentRequestDate: Moment(),
      appointmentRequestTime: '',
      appointmentRequestNote: '',
			total: 0,
			subtotal: 0,
			tax: 0,
      taxtotal: 0,
      totalSavings: 0,
      totalMSRPSavings: 0,
      customerName: "",
      customerEmail: "",
      customerPhone: "",
      customerNotes: "",
      formFirstName: "",
      formLastName: "",
      formEmail: "",
      formPhone: "",
      formNotes: "",
      orderId: null,
      orderGUID: "",
      orderTypeCode: "",
			cartItems: Immutable.List.of(),
			vehicleInformation: Immutable.List.of(),
			shoppingCartViewNotes: Immutable.Map({en: '', fr: ''}),
      supportDealerOrder: false,
      supportConsumerOrder: false,
      foxyCart: null,
      foxyCartError: false,
      shoppingCartFormSuccess: false,
      shoppingCartFormError: false,
      shoppingCartReminderSuccess: false,
      shoppingCartReminderError: false,
      shoppingCartDealerFormSuccess: false,
      shoppingCartDealerFormError: false,
      showMarkAsCompleteModal: false,

      showFinancingModal: false,
		});
	},

	setFormData: function(firstName, lastName, email, phone, notes) {
    if (!isEmpty(firstName)) this.data = this.data.set('formFirstName', firstName);
    if (!isEmpty(lastName)) this.data = this.data.set('formLastName', lastName);
    if (!isEmpty(email)) this.data = this.data.set('formEmail', email);
    if (!isEmpty(phone)) this.data = this.data.set('formPhone', phone);
    if (!isEmpty(notes)) this.data = this.data.set('formNotes', notes);
	},

  getNewCart: function (response) {
    this.resetShoppingCartView();
    this.data = Immutable.Map({
      orderId: response.orderId,
      orderGUID: response.orderNumber,
      orderTypeCode: response.orderTypeCode,
      supportDealerOrder: response.supportDealerOrder,
      supportConsumerOrder: response.supportConsumerOrder
    });
  },

  onInitializeAppState: function (appState) {
    var routerState = Router.decodeRouterState(appState);

    this.loadAppState('cart', appState, routerState.params);

    this.emitChange();
  },

  loadAppState: function(key, appState, params) {
    var temp;
    if (params[key]) {
      temp = params;
      this.data[key] = temp[key];
    } else if (appState[key]) {
      temp = appState;
      this.data[key] = temp[key];
    } else {
      temp = params;
    }

    if (temp.hasOwnProperty(key)) {
      switch (key) {
        case 'cart':
          if (temp.cart) {
            if (checkOrderIdentifierIsGUID(temp.cart)) {
              this.data = this.data.set('orderGUID', temp.cart);
            } else {
              this.data = this.data.set('orderId', temp.cart);
            }
          }
          break;
      }
    }
  },

  shoppingCartFormSuccess: function() {
    this.data = this.data.set('shoppingCartFormSuccess', true);
    this.data = this.data.set('shoppingCartFormError', false);
    this.emitChange();
  },

	openMarkAsCompleteModal: function() {
    this.data = this.data.set('showMarkAsCompleteModal', true);
    this.emitChange();
  },

  closeMarkAsCompleteModal: function() {
    this.data = this.data.set('showMarkAsCompleteModal', false);
    this.emitChange();
  },

  toggleFinancingModal: function() {
	  if (applicationStore.data.enableFinancing && this.data.get('showFinancingModal') === false) {
      this.data = this.data.set('showFinancingModal', true);
    } else {
      this.data = this.data.set('showFinancingModal', false);
    }
    this.emitChange();
  },

  shoppingCartFormError: function() {
    this.data = this.data.set('shoppingCartFormSuccess', false);
    this.data = this.data.set('shoppingCartFormError', true);
    this.emitChange();
  },

  shoppingCartReminderSuccess: function() {
    this.data = this.data.set('shoppingCartReminderSuccess', true);
    this.data = this.data.set('shoppingCartReminderError', false);
    this.emitChange();
  },

  shoppingCartReminderError: function() {
    this.data = this.data.set('shoppingCartReminderSuccess', false);
    this.data = this.data.set('shoppingCartReminderError', true);
    this.emitChange();
  },

  shoppingCartDealerFormSuccess: function() {
    this.data = this.data.set('shoppingCartDealerFormSuccess', true);
    this.data = this.data.set('shoppingCartDealerFormError', false);
    this.emitChange();
  },

  shoppingCartDealerFormError: function() {
    this.data = this.data.set('shoppingCartDealerFormSuccess', false);
    this.data = this.data.set('shoppingCartDealerFormError', true);
    this.emitChange();
  },

  shoppingCartDealerResetError: function() {
    this.data = this.data.set('shoppingCartDealerFormSuccess', false);
    this.data = this.data.set('shoppingCartDealerFormError', false);
    this.emitChange();
  },

  onQuoteEmailSent: function() {
    this.data = this.data.set('emailSent', true);
    this.data = this.data.set('showModal', true);
    this.emitChange();
  },

  onAppointmentSent: function() {
    this.data = this.data.set('appointmentSent', true);
    this.data = this.data.set('showModal', true);
    this.emitChange();
  },

  updateOrderStatus: function(response) {
		if (response && sizeOf(response) > 0) {
      var supportDealerOrder = false;
      if (checkArrayFieldsExist(response, ['supportDealerOrder'])) supportDealerOrder = response.supportDealerOrder;
      else if (checkArrayFieldsExist(response, ['response', 'supportDealerOrder'])) supportDealerOrder = response.response.supportDealerOrder;

      var supportConsumerOrder = false;
      if (checkArrayFieldsExist(response, ['supportConsumerOrder'])) supportConsumerOrder = response.supportConsumerOrder;
      else if (checkArrayFieldsExist(response, ['response', 'supportConsumerOrder'])) supportConsumerOrder = response.response.supportConsumerOrder;

      var orderTypeCode = response.orderTypeCode;
      if (typeof(orderTypeCode) === 'undefined' && checkArrayFieldsExist(response, ['response', 'orderTypeCode'])) orderTypeCode = response.response.orderTypeCode;

      var orderNumber = response.orderNumber;
      if (typeof(orderNumber) === 'undefined' && checkArrayFieldsExist(response, ['response', 'orderNumber'])) orderNumber = response.response.orderNumber;

      var orderId = response.orderId;
      if (typeof(orderId) === 'undefined' && checkArrayFieldsExist(response, ['response', 'orderId'])) orderId = response.response.orderId;

      this.data = this.data.set('supportDealerOrder', supportDealerOrder);
      this.data = this.data.set('supportConsumerOrder', supportConsumerOrder);
      this.data = this.data.set('orderTypeCode', orderTypeCode);
      this.data = this.data.set('orderGUID', orderNumber);
      this.data = this.data.set('orderId', orderId);
    }
		this.emitChange();
	},

	updateOrderNumber: function(orderNumber) {
    this.data = this.data.set('orderGUID', orderNumber);
    this.emitChange();
	},

	foxyCartGenerated: function (response) {
		if (isEmpty(response.orderRedirect)) {
      this.data = this.data.set('foxyCart', null);
      this.data = this.data.set('foxyCartError', true);
    } else {
      this.data = this.data.set('foxyCart', response.orderRedirect);
      this.data = this.data.set('foxyCartError', false);
    }
		this.emitChange();
	},

	onCloseFoxyCartErrorModal: function () {
    this.data = this.data.set('foxyCartError', false);
    this.emitChange();
	},

	shoppingCartViewLoaded: function (response) {
		if (response && sizeOf(response) > 0) {

      this.updateOrderStatus(response);

      if (response.itemId > 0) {
        response = response.response;
      }

      if (shoppingCartStore.data.get('cart') === null && response.orderId !== null) {
        shoppingCartStore.data = shoppingCartStore.data.set('cart', response.orderId);
      }

      var item = null, packageVisualizerSet = false;
      if (typeof response.orderItemList !== 'undefined') {
        // shoppingCartStore.clearItems();
        // setTimeout(() => {
        //   productListActions.setPartForVisualizer(null);
        // }, 0);
        response.orderItemList.forEach(function (cartItem) {
          if (!packageVisualizerSet && getDestinationInverse(cartItem.itemType) === ProductTypes.PACKAGES) {
            if (sizeOf(cartItem.itemPart.partSummary.primaryPart.itemImageSecondaryLinkList) > 0) {
              packageVisualizerSet = true;
              var visualizerItem = Immutable.fromJS({
                title: cartItem.itemTitle,
                secondaryImages: cartItem.itemPart.partSummary.primaryPart.itemImageSecondaryLinkList.map(function (image) {
                  return Immutable.Map({
                    imageUrl: image.imageUrl,
                    thumbnailUrl: image.thumbnailUrl,
                    sortOrder: image.imageSortOrder
                  });
                })
              });
              setTimeout(() => {
                productListActions.setPartForVisualizer(visualizerItem);
              }, 10);
            }
          }

          var quantity = {
            id: cartItem.pricingContextId + parseInt(cartItem.itemQty, 10),
            value: parseInt(cartItem.itemQty, 10),
            title: {
              en: `${parseInt(cartItem.itemQty, 10)}${cartItem.pricingContextId > 0 ? ' - ' + cartItem.itemPromotion['en'] : ''}`,
              fr: `${parseInt(cartItem.itemQty, 10)}${cartItem.pricingContextId > 0 ? ' - ' + cartItem.itemPromotion['fr'] : ''}`
            },
            pricingContextId: cartItem.pricingContextId
          };

          var itemPart = cartItem['itemPart'], partPricing = null;
          if (checkArrayFieldsExist(itemPart, ['partPricing', 'primaryPart'])) {
            partPricing = itemPart['partPricing']['primaryPart'];
          } else {
            partPricing = cartItem.itemPart.partPricing.primaryPart;
          }

          var warnings = cartItem.itemWarnings;

          var price = getPricingFromQuantityAndDetailsRefactored(partPricing, quantity);

          var product = null;
          switch (getDestinationInverse(cartItem.itemType)) {
            case ProductTypes.TIRES:
              product = tireMappingFunction(true, applicationStore.data.dealerIgnoreTireStock, "", applicationStore.data.setPartQuantityToOne)(cartItem.itemPart);
              break;
            case ProductTypes.ALLOY_WHEELS:
              product = wheelMappingFunction(true, applicationStore.data.dealerIgnoreWheelStock, "", applicationStore.data.setPartQuantityToOne)(cartItem.itemPart);
              break;
            case ProductTypes.ACCESSORIES:
              product = accessoryMappingFunction(true, applicationStore.data.dealerIgnoreAccessoryStock, applicationStore.data.setPartQuantityToOne)(cartItem.itemPart);
              break;
            case ProductTypes.PACKAGES:
              product = packageMappingFunction(true, applicationStore.data.dealerIgnorePackageStock, applicationStore.data.setPartQuantityToOne)(cartItem.itemPart);
              break;
            default:
              break;
          }

          item = {
            id: cartItem.itemId,
            cartItemId: cartItem.orderItemId,
            name: cartItem.itemTitle,
            image: cartItem.itemImageLink,
            listPrice: price['listPrice'],
            showUnitPrice: price['showUnitPrice'],
            displayPrice: price['displayPrice'],
            privatePrice: price['privatePrice'],
            displayLabour: price['displayLabour'],
            promotionText: price['promotionPricingText'],
            rowPrice: price['rowPrice'],
            cartItemPrice: cartItem['rowPrice'],
            quantity: quantity,
            type: getDestinationInverse(cartItem.itemType),
            product: product,
            pricingContextId: cartItem.pricingContextId,
            itemPartOption: cartItem.itemPartOption,
            itemWarnings: warnings,
            supportDealerOrder: cartItem.supportDealerOrder,
            supportConsumerOrder: cartItem.supportConsumerOrder
          };
          shoppingCartStore.data = shoppingCartStore.data.set('items', shoppingCartStore.data.get('items').push(Immutable.fromJS(item)));
        });
        shoppingCartStore.data = shoppingCartStore.data.set('itemsCount', sizeOf(response.orderItemList));
      }

      if (
        sizeOf(response.orderItemList) > 0
        && !shoppingCartStore.isCartModalOpened()
        && applicationStore.data.quoteImmediately
        && !response.fromCreatePackage
        && !isCompleteOrder()
        && !isConsumerComplete()
      ) {

        // setTimeout(() => {
        //   shoppingCartActions.closeCart();
        //   setTimeout(() => { shoppingCartActions.openCartModal(); }, 100);
        // }, 100);

      } else if (response.addedToCart && this.openCartAfterAdd(item)) {

        var cartItem = Immutable.fromJS(item);
        setTimeout(() => {
          shoppingCartActions.openDetail(cartItem);
        }, 100);

      }

      if (globalState.select('applicationState').get('routerState') === RouterStates.WISHLIST) {
        // setTimeout(() => { shoppingCartActions.closeCart(); }, 100);
      }

      if (applicationStore.data.enableFinancing && !!response.openFinancing) {
        this.data = this.data.set('showFinancingModal', true);
      } else {
        this.data = this.data.set('showFinancingModal', false);
      }

      const cartItemList = Immutable.fromJS(response.orderItemList);

      var firstName = decodeQuoteName("first", response.customerName), lastName = decodeQuoteName("last", response.customerName), email = response.customerEmail, phone = response.customerPhone, notes = response.customerNotes;
      if (firstName && document.getElementById('firstNameQuoteField')) document.getElementById('firstNameQuoteField').value = firstName;
      if (lastName && document.getElementById('lastNameQuoteField')) document.getElementById('lastNameQuoteField').value = lastName;
      if (email && document.getElementById('userEmailField')) document.getElementById('userEmailField').value = email;
      if (phone && document.getElementById('telQuoteField')) document.getElementById('telQuoteField').value = phone;
      if (notes && document.getElementById('userNoteQuoteField')) document.getElementById('userNoteQuoteField').value = notes;

      if (firstName && document.getElementById('formFirstName')) document.getElementById('formFirstName').value = firstName;
      if (lastName && document.getElementById('formLastName')) document.getElementById('formLastName').value = lastName;
      if (email && document.getElementById('formEmail')) document.getElementById('formEmail').value = email;
      if (phone && document.getElementById('formPhone')) document.getElementById('formPhone').value = phone;
      if (notes && document.getElementById('formNotes')) document.getElementById('formNotes').value = notes;

      this.data = this.data.set('formFirstName', firstName);
      this.data = this.data.set('formLastName', lastName);
      this.data = this.data.set('formEmail', email);
      this.data = this.data.set('formPhone', phone);
      this.data = this.data.set('formNotes', notes);
      this.data = this.data.set('shoppingCartViewCreated', Moment(response.shoppingCartViewCreatedTimestamp));
      this.data = this.data.set('appointmentRequestDate', Moment(response.appointmentRequestDate));
      this.data = this.data.set('appointmentRequestTime', response.appointmentRequestTime);
      this.data = this.data.set('appointmentRequestNotes', response.dealerNotes);
      this.data = this.data.set('cartItems', this.transformShoppingCartViewItemsFromApi(response.orderItemList));
      this.data = this.data.set('total', parseFloat(response.orderGrandTotal));
      this.data = this.data.set('subtotal', parseFloat(response.orderSubtotal));
      this.data = this.data.set('tax', parseFloat(response.orderTaxPercent));
      this.data = this.data.set('taxtotal', parseFloat(response.orderTaxTotal));
      this.data = this.data.set('numberOfPromotions', this.getNumberOfPromotions(cartItemList));
      this.data = this.data.set('totalSavings', this.getTotalSavingsThroughPromotion(cartItemList));
      this.data = this.data.set('totalMSRPSavings', this.getTotalSavingsThroughMSRP(cartItemList));
      this.data = this.data.set('firstNonNullPromotion', this.getFirstNonNullPromotion(cartItemList));
      this.data = this.data.set('orderRestockingFee', response.orderRestockingFee);
      this.data = this.data.set('shoppingCartViewNotes', response.orderNotes);
      this.data = this.data.set('customerName', response.customerName);
      this.data = this.data.set('customerEmail', response.customerEmail);
      this.data = this.data.set('customerPhone', response.customerPhone);
      this.data = this.data.set('customerNotes', response.customerNotes);
      this.data = this.data.set('orderStatus', response.orderStatus);
      this.data = this.data.set('supportDealerOrder', response.supportDealerOrder);
      this.data = this.data.set('supportConsumerOrder', response.supportConsumerOrder);
      this.data = this.data.set('orderDeposit', response.orderDeposit);
      this.data = this.data.set('orderDepositDealer', response.orderDepositDealer);
      this.data = this.data.set('orderDepositInternal', response.orderDepositInternal);
      this.data = this.data.set('orderDueAtDealer', response.orderDueAtDealer);
      this.data = this.data.set('orderDisclaimerList', response.orderDisclaimerList);
      this.data = this.data.set('orderId', response.orderId);
      this.data = this.data.set('orderGUID', response.orderNumber);
      this.data = this.data.set('orderTypeCode', response.orderTypeCode);
      this.data = this.data.set('orderStatusDescription', Immutable.fromJS(response.orderStatusDescription));
      this.data = this.data.set('vehicleInformation', Immutable.fromJS(response.vehicleInformation));
      this.data = this.data.set('orderFulfillmentList', response.orderFulfillmentList);

		}

		this.emitChange();
	},

  openCartAfterAdd: function(item) {
	  if (item && item.type === ProductTypes.ACCESSORIES) {
	  	var requiredItems = item.requiredItems;
	  	if (isEmpty(requiredItems)) requiredItems = item.product.get('relatedParts');
      return applicationStore.data.showCartReviewOnAddToCart || (requiredItems && sizeOf(requiredItems) > 0 && globalState.select('applicationState').get('routerState') !== RouterStates.PRODUCT_DETAIL);
    } else {
	    return applicationStore.data.showCartReviewOnAddToCart;
    }
  },

	getCheckoutLink: function () {
		return "javascript:document.getElementById('checkout-loading-image').style.display = 'block';setTimeout(function(){document.getElementById('initFoxyCartForm').submit();}, 2000);";
	},

	getTotalSavingsThroughPromotion: function (apiQuoteItems) {
		if (typeof apiQuoteItems !== 'undefined') {
			return apiQuoteItems
				.map(quoteItem => parseFloat(quoteItem.get('rowSavings')))
				.reduce((memo, savings) => memo + savings, 0);
		}
	},

	getTotalSavingsThroughMSRP: function (apiShoppingCartViewItems) {
		if (typeof apiShoppingCartViewItems !== 'undefined') {
			return apiShoppingCartViewItems
				.map(shoppingCartViewItem => (parseFloat(shoppingCartViewItem.get('itemListPrice')) - parseFloat(shoppingCartViewItem.get('itemPrice'))) * parseFloat(shoppingCartViewItem.get('itemQty')))
				.reduce((memo, savings) => memo + savings, 0);
		}
	},

	getNumberOfPromotions: function (apiShoppingCartViewItems) {
		if (typeof apiShoppingCartViewItems !== 'undefined') {
			return sizeOf(apiShoppingCartViewItems
				.filter(shoppingCartViewItem => shoppingCartViewItem.getIn(['itemPromotion', 'en']) !== '')
				.map(shoppingCartViewItem => shoppingCartViewItem.set('rowSavings', parseFloat(shoppingCartViewItem.get('rowSavings'))))
				.filter(shoppingCartViewItem => shoppingCartViewItem.get('rowSavings') > 0)
				.groupBy(shoppingCartViewItem => shoppingCartViewItem.getIn(['itemPromotion', 'en'])));
		}
	},

	getFirstNonNullPromotion: function (apiShoppingCartViewItems) {
		if (typeof apiShoppingCartViewItems !== 'undefined') {
			return apiShoppingCartViewItems.find(shoppingCartViewItem => parseFloat(shoppingCartViewItem.get('rowSavings')) > 0);
		}
	},

	transformShoppingCartViewItemsFromApi: function (apiShoppingCartViewItems) {
		var shoppingCartViewItems = Immutable.fromJS(apiShoppingCartViewItems);
		if (typeof shoppingCartViewItems !== 'undefined') {
			shoppingCartViewItems = shoppingCartViewItems.map(function (shoppingCartViewItem, index) {
				var quantity = {
					id: apiShoppingCartViewItems[index].pricingContextId + parseInt(apiShoppingCartViewItems[index].itemQty, 10),
					value: parseInt(apiShoppingCartViewItems[index].itemQty, 10),
					title: {
						en: `${parseInt(apiShoppingCartViewItems[index].itemQty, 10)}${apiShoppingCartViewItems[index].pricingContextId > 0 ? ' - ' + apiShoppingCartViewItems[index].itemPromotion['en'] : ''}`,
						fr: `${parseInt(apiShoppingCartViewItems[index].itemQty, 10)}${apiShoppingCartViewItems[index].pricingContextId > 0 ? ' - ' + apiShoppingCartViewItems[index].itemPromotion['fr'] : ''}`
					},
					pricingContextId: apiShoppingCartViewItems[index].pricingContextId
				};

				var itemPart = apiShoppingCartViewItems[index]['itemPart'], partPricing = null;

				if (checkArrayFieldsExist(itemPart, ['partPricing', 'primaryPart'])) partPricing = itemPart['partPricing']['primaryPart'];
				else partPricing = apiShoppingCartViewItems[index].itemPart.partPricing.primaryPart;

				return Immutable.fromJS({
					id: shoppingCartViewItem.get('itemId'),
					cartItemId: shoppingCartViewItem.get('orderItemId'),
					title: shoppingCartViewItem.get('itemTitle'),
					image: shoppingCartViewItem.get('itemImageLink'),
					description: shoppingCartViewItem.get('itemDescription'),
					type: getDestinationInverse(shoppingCartViewItem.get('itemType')),
					price: parseFloat(shoppingCartViewItem.get('rowPrice')),
					savings: parseFloat(shoppingCartViewItem.get('rowSavings')),
					promotion: shoppingCartViewItem.get('itemPromotion'),
          promotionId: shoppingCartViewItem.get('pricingContextId'),
					promotionName: shoppingCartViewItem.get('itemPromotionPricing'),
					hasPromotion: parseFloat(shoppingCartViewItem.get('pricingContextId')) > 0,
					quantity: quantity,
					quantities: getQuantitiesFromPricingDetailsRefactored(partPricing),

          itemPart: shoppingCartViewItem.get('itemPart'),

					pricingDetails: partPricing,
					pricing: getPricingByQuantity(partPricing, quantity),
					rebate: getRebateByQuantity(partPricing, quantity),
					optionId: shoppingCartViewItem.get('optionId'),
					vehicleId: shoppingCartViewItem.get('vehicleId'),
					hasPricing: shoppingCartViewItem.get('itemPart').get('partSummary').get('primaryPart').get('itemHasDealerPricing'),

					itemPartOption: shoppingCartViewItem.get('itemPartOption'),

					mandatoryItems: shoppingCartViewItem.get('mandatoryLineItemList'),
					includedOptionalItems: shoppingCartViewItem.get('includedOptionalLineItemList'),
					optionalItems: shoppingCartViewItem.get('additionalOptionalLineItemList'),
					hasRequiredItem: shoppingCartViewItem.get('hasRequiredRelatedItems') === '1',
					partNumber: shoppingCartViewItem.get('itemPartNumber'),

					nationalPartNumber: shoppingCartViewItem.get('itemPart').get('partSummary').get('primaryPart').get('itemNationalPartNumber'),

					secondaryImages: shoppingCartViewItem.get('itemPart').get('partSummary').get('primaryPart').get('itemImageSecondaryLinkList') ? shoppingCartViewItem.get('itemPart').get('partSummary').get('primaryPart').get('itemImageSecondaryLinkList').map(function (image) {
						return Immutable.Map({
							url: image.get('imageUrl'),
							sortOrder: image.get('imageSortOrder')
						});
					}) : null,

          supportDealerOrder: shoppingCartViewItem.get('supportDealerOrder'),
          supportConsumerOrder: shoppingCartViewItem.get('supportConsumerOrder'),

					/* TIRE */
					tiresize: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('formattedTiresize'),
					loadRating: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('tireLoadRating'),
					speedRating: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('tireSpeedIndex'),
					brandImage: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('tireManufacturerImage'),
					runFlat: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('tireRunFlat'),
					originalEquipment: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('tireOriginalEquipmentFlag'),
          tireManufacturerName: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('tireManufacturerName'),

					/* WHEEL */
					diameter: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('wheelDiameter'),

					/* PACKAGE */
					packageManufacturerName: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('packageManufacturerName'),
					lineItems: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('packageLineItem') ? shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('packageLineItem').map(function(lineItem) {
            return Immutable.Map({
							title: lineItem.get('title'),
              quantity: lineItem.get('qty')
            });
					}) : Immutable.List.of(),
					items: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('packageItem') ? shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('packageItem').map(function (packageItem) {
						return Immutable.Map({
							id: packageItem.get('itemId'),
							partType: packageItem.get('itemType'),
							partNumber: packageItem.get('itemPartNumber'),
							quantity: packageItem.get('itemQuantity'),
							title: packageItem.get('itemTitle'),
							description: packageItem.get('itemDescription'),
							typeDescription: packageItem.get('itemTypeDescription'),
							itemLineItem: packageItem.get('itemPackageLineItem').map(function (lineItem, index) {
								return Immutable.Map({
									lineItemId: lineItem.get('lineItemId'),
									title: lineItem.get('title'),
									description: lineItem.get('description'),
									quantity: lineItem.get('qty'),
									matchItemQty: lineItem.get('matchItemQty') === '1',
									unitPrice: lineItem.get('unitPrice'),
									totalPrice: lineItem.get('totalPrice')
								});
							})
						});
					}) : Immutable.List.of()
				});
			}.bind(this));
			return shoppingCartViewItems.groupBy(function (item) { return item.get('type'); });
		} else {
			return shoppingCartViewItems;
		}
	}

});

export var shoppingCartViewStore = new ShoppingCartViewStore();

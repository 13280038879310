import { I18nComponent } from "../../i18n/I18nComponent.jsx";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";

var React = require("react");

import { DateUtilities } from "../calendar/DateUtilities.jsx";

export class MonthHeader extends I18nComponent {
  componentWillReceiveProps(nextProps) {
    if (nextProps.view !== null && typeof nextProps.view !== "undefined") {
      this.setState({ view: DateUtilities.clone(nextProps.view) });
    }
  }

  moveBackward = () => {
    var current = new Date();
    current.setDate(1);
    var backwards = DateUtilities.clone(this.state.view);
    backwards.setMonth(backwards.getMonth() - 1);
    if (DateUtilities.isAfter(backwards, current)) {
      this.props.onSetDate(backwards);
    } else {
      backwards.setMonth(backwards.getMonth() + 1);
    }
  };

  moveForward = () => {
    var nextYear = new Date();
    nextYear.setFullYear(nextYear.getFullYear() + 1);
    nextYear.setMonth(nextYear.getMonth() + 1);

    var forwards = this.state.view;
    forwards.setMonth(forwards.getMonth() + 1);

    if (DateUtilities.isBefore(forwards, nextYear)) {
      this.props.onSetDate(forwards);
    } else {
      forwards.setMonth(forwards.getMonth() - 1);
    }
  };

  state = {
    view: DateUtilities.clone(this.props.view),
    enabled: true,
  };

  render() {
    var enabled = this.state.enabled;
    return React.createElement(
      "div",
      { className: "month-header" },
      React.createElement(
        "i",
        {
          onClick: this.moveBackward,
          className: setAdobeLaunchClass(
            enabled + " left-arrow" ? "" : " disabled",
            ADOBELAUNCHER_CLASS.ARROW.PREV
          ),
          "data-dtm": setAdobeLaunchDTM(
            "",
            ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.DATE
          ),
        },
        String.fromCharCode(9664)
      ),
      React.createElement(
        "h1",
        null,
        DateUtilities.toMonthAndYearString(this.state.view)
      ),
      React.createElement(
        "i",
        {
          onClick: this.moveForward,
          className: setAdobeLaunchClass(
            enabled + " right-arrow" ? "" : " disabled",
            ADOBELAUNCHER_CLASS.ARROW.NEXT
          ),
          "data-dtm": setAdobeLaunchDTM(
            "",
            ADOBELAUNCHER_DTM.REQUESTAPPOINTMENT.DATE
          ),
        },
        String.fromCharCode(9654)
      )
    );
  }
}

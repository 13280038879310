import {isEmpty} from 'lodash';
import store from '../../../stores/configureStore';
import {setDealerSearch, setFilterDealerData, setMapSize} from "../dealerSections";
import {applicationStore} from "../../../stores/ApplicationStore";

let DEALER_CITY_SEARCH = "dealer-city-search";
let DEALER_NAME_SEARCH = "dealer-name-search";
let reduxState = store;
let reduxDispatch = store;
var GoogleMapsAPI = window.google.maps;
var searchName;

/**
 * This function gets the dealer list from the redux store
 * @returns {*|[]}
 */
function getDealerNonFilter() {
  let nonFilterDealers = store.getState();

  return nonFilterDealers.dealerSections.dealers;
}

/**
 * This function gets the dealer list from the redux store
 * @param selectedItem
 * @returns {*} - The dealer list from the redux store
 */
function getDealerReduxStore(selectedItem) {
  let dealers = store.getState();
  return dealers.dealerSections[selectedItem];
}

/**
 * This function sets the dealer SearchName
 * @param searchName
 * @returns void  - sets the dealer SearchName
 */
function setSearchName(searchName) {
  store.dispatch(setDealerSearch(searchName));
}

/**

 Sorts an array of dealer objects by distance property.
 @param {Object} a - The first dealer object to compare.
 @param {Object} b - The second dealer object to compare.
 @returns {number} - A number indicating the sort order.
 If aDistance < bDistance, return -1
 If aDistance > bDistance, return 1
 If aDistance = bDistance, return 0
 If either object is missing or has an invalid distance property:
 If both objects are missing or have an invalid distance property, return 0
 If only the first object is missing or has an invalid distance property, return 1
 If only the second object is missing or has an invalid distance property, return -1
 */

let sortByDistance = (a, b) => {
  let aDistance = parseFloat(a['distance']);
  let bDistance = parseFloat(b['distance']);
  if (isNaN(aDistance) || isNaN(bDistance)) {
    // Handle objects with missing or invalid distance property
    if (isNaN(aDistance) && isNaN(bDistance)) return 0;
    else if (isNaN(aDistance)) return 1;
    else return -1;
  } else {
    // Compare objects by their distance property
    if (aDistance < bDistance) return -1;
    else if (aDistance > bDistance) return 1;
    else return 0;
  }
};
/**
 * Updates the dealer search type based on the user's selection.
 * @param {Array} listOfDealers - The list of dealers to be loaded.
 * @param {object} userLocation - The user's current location.
 * @param {string} dealerSearch - The dealer search.
 * @param {function} sortByName - The function used to sort the dealers by name.
 * @param {function} getDistance - The function used to get the distance between two points.
 * @returns {object} An object containing the updated dealer search properties.
 */
export const onListOfDealersLoaded = (listOfDealers, userLocation = null, dealerSearch = null, sortByName = null, getDistance = null) => {


  let startingLocation = userLocation;
  let userInitialLocationModal = false;
  let dealerSearchFlag = false;
  let dealerSearchLocation = null;
  let dealerSearchIsEmpty = false;
  let dealerSearchShowWarning = false;
  let enableDealerNameList = false;
  let visibleDealers = [];
  let dealers = [];

  if (startingLocation === null && isEmpty(dealerSearch)) {
    //startingLocation = mapCenter;
    userInitialLocationModal = true;
    dealerSearch = null;
    dealerSearchFlag = false;
    dealerSearchLocation = null;
    dealerSearchIsEmpty = false;
    dealerSearchShowWarning = false;
    enableDealerNameList = true;
  }
  // dealers = Immutable
  //   .fromJS(listOfDealers)
  //   .sort(sortByName)
  //   .map((dealer) => {
  //
  //     let position = new GoogleMapsAPI.LatLng(parseFloat(dealer.get('dealerLatitude')), parseFloat(dealer.get('dealerLongitude')));
  //     return  Immutable.Map({
  //       id: dealer.get('dealerId'),
  //       name: dealer.get('dealerName'),
  //       alias: dealer.get('dealerNumberAlias'),
  //       urlCode: dealer.get('dealerUrlCode'),
  //       address: dealer.get('dealerAddress'),
  //       city: dealer.get('dealerCity'),
  //       postalCode: dealer.get('dealerPostalCode'),
  //       province: dealer.get('dealerState'),
  //       country: dealer.get('dealerCountry'),
  //       phone: dealer.get('dealerContactPhone'),
  //       email: dealer.get('dealerContactEmail'),
  //       website: dealer.get('dealerWebsiteUrl'),
  //       hours: dealer.get('dealerHours'),
  //       season: dealer.get('dealerDefaultSearchSeason'),
  //       googleMapsUrl: dealer.get('dealerRetailSiteGoogleMapsExternalUrl'),
  //       inactiveDealerLink: dealer.get('dealerMapExitLink'),
  //       hasNoPricing: dealer.get('dealerShowNoPricingOnNationalMap'),
  //       nationalAccountId: dealer.get('nationalAccountId'),
  //       isActive: dealer.get('dealerIsActive'),
  //       enableECommerce: dealer.get('dealerEnableECommerce'),
  //       enableFinancing: dealer.get('dealerEnableFinancing'),
  //       position: position,
  //       distance: startingLocation === null ? null : getDistance(startingLocation, position)
  //     });
  //
  //   });


  for (let i = 0; i < listOfDealers.length; i++) {
    let dealer = listOfDealers[i];
    let position = new GoogleMapsAPI.LatLng(parseFloat(dealer.dealerLatitude), parseFloat(dealer.dealerLongitude));
    let dealerMap = {
      id: dealer.dealerId,
      name: dealer.dealerName,
      alias: dealer.dealerNumberAlias,
      urlCode: dealer.dealerUrlCode,
      address: dealer.dealerAddress,
      city: dealer.dealerCity,
      postalCode: dealer.dealerPostalCode,
      province: dealer.dealerState,
      country: dealer.dealerCountry,
      phone: dealer.dealerContactPhone,
      email: dealer.dealerContactEmail,
      website: dealer.dealerWebsiteUrl,
      hours: dealer.dealerHours,
      season: dealer.dealerDefaultSearchSeason,
      googleMapsUrl: dealer.dealerRetailSiteGoogleMapsExternalUrl,
      inactiveDealerLink: dealer.dealerMapExitLink,
      hasNoPricing: dealer.dealerShowNoPricingOnNationalMap,
      nationalAccountId: dealer.nationalAccountId,
      isActive: dealer.dealerIsActive,
      enableECommerce: dealer.dealerEnableECommerce,
      enableFinancing: dealer.dealerEnableFinancing,
      position: position,
      lng:dealer.dealerLongitude,
      lat:dealer.dealerLatitude,
      distance: startingLocation === null ? null : getDistance(startingLocation, position)
    };

    dealers.push(dealerMap);
  }

  dealers = dealers.sort(sortByDistance);
  visibleDealers = dealers.sort(sortByDistance);
  return {
    visibleDealers: visibleDealers,
    dealers: dealers,
    userInitialLocationModal
  };
};

/*
  * Returns the radian of a given degree.
  * @param {number} x - The degree to be converted to radian.
 */
function getRadian(x) {
  return x * Math.PI / 180;
}

/*
  * Returns the distance between two points.
  * @param {object} p1 - The first point.
  * @param {object} p2 - The second point.
  * @returns {number} The distance between the two points.
  *
 */
function getDistance(p1, p2) {
  const R = 6378137; // Earth’s mean radius in meter
  const getRadian = (deg) => (deg * Math.PI) / 180;
  const dLat = getRadian(p2.lat() - p1.lat);
  const dLong = getRadian(p2.lng() - p1.lng);
  const a = Math.sin(dLat / 2) ** 2 + Math.cos(getRadian(p1.lat)) * Math.cos(getRadian(p2.lat())) * Math.sin(dLong / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return Math.round(d / 1000); // returns the distance in kilometers
}

/*
* Updates DealerSearchType based on the user's selection.
* @param {string} searchType - The selected search type.
* @returns {object} An object containing the updated dealer search properties.
* @export onDealerSelectionChangeSearchType
 */
export function onDealerSelectionChangeSearchType(searchType) {

  let dealerSearch = null;
  let dealerSearchFlag = false;
  let dealerSearchShowWarning = false;
  let enableDealerNameList;
  let DEALER_NAME_SEARCH = "dealer-name-search";
  enableDealerNameList = searchType === DEALER_NAME_SEARCH;
  return {
    dealerSearch,
    dealerSearchFlag,
    dealerSearchShowWarning,
    enableDealerNameList,
    searchType
  };
}

/*
* Updates DealerSearch based on the user's input search using positioning.
* @param {string} dealerSearch - The user's input.
* @param {array} dealers - The list of dealers.
* @returns {object} An object containing the updated dealer search properties.
 */
function getFilteredDealersRefactor(setFlag, dealers) {
  let dealerSearchDistance = 0;
  let searchType = getDealerReduxStore('searchType');
  let dealerSearch = searchName;

  let filteredDealers = dealers;
  let dealerSearchIsEmpty = false;
  let visibleDealers = [];
  let setDealerAsActive = false;
  let dealersFilteredFromPosition = false;

  if (filteredDealers.length > 0) {
    if (searchType === DEALER_CITY_SEARCH) {
      filteredDealers = filteredDealers.filter(dealer => dealer.distance <= dealerSearchDistance || dealerSearchDistance === 0);
      if (filteredDealers.length === 0) {
        dealerSearchIsEmpty = true;
      }
    } else if (searchType === DEALER_NAME_SEARCH && dealerSearch !== null && dealerSearch !== "") {
      filteredDealers = filteredDealers.filter(dealer => dealer.name.toLowerCase().indexOf(dealerSearch.toLowerCase()) > -1);
      if (filteredDealers.length === 0) {
        dealerSearchIsEmpty = true;
      }
    }
    visibleDealers = filteredDealers;
    setDealerAsActive = (filteredDealers.length === 1);

    if (setFlag) {
      dealersFilteredFromPosition = true;
    }
  }
  setDealerFilter({
    filteredDealers: filteredDealers.sort(sortByDistance),
    visibleDealers: visibleDealers.sort(sortByDistance),
    setDealerAsActive,
    dealersFilteredFromPosition,
    dealerSearchIsEmpty
  });

  return {
    filteredDealers,
    visibleDealers,
    setDealerAsActive,
    dealersFilteredFromPosition,
    dealerSearchIsEmpty
  };
}

/**
 * Returns the filtered dealers based on the user's search.
 * @param {boolean} setFlag - A flag to indicate if the dealers are filtered from the user's position.
 * @param {object} dealers - The list of dealers.
 * @returns {object} An object containing the filtered dealers.
 **/
function getFilteredDealers(setFlag = false, dealers) {
  // if (!checkShouldUseLegacyMap) {
  //     getFilteredDealersRefactor(setFlag);
  // } else {
  //   this.getFilteredDealersLegacy();
  // }

  return getFilteredDealersRefactor(setFlag, dealers);
}

/**
 * Returns the country text for the given country code.
 * @param {string} countryCode - The country code.
 * @returns {string} The country text.
 */
function getCountryText(countryCode) {
  switch (countryCode) {
    case "CA":
      return "Canada";
    case "US":
      return "USA";
  }
}

/**
 * Returns an array of country text variations for the given country code.
 * @param {string} countryCode - The country code.
 * @returns {string[]} An array of country text variations.
 */
function getCountryTextArray(countryCode) {
  switch (countryCode) {
    case "CA":
      return ["Canada"];
    case "US":
      return ["United States of America", "United States", "USA", "US"];
  }
}

/**
 * Checks whether the given search term is missing the country text variations.
 * @param {string} search - The search term.
 * @param {string[]} countryTextArray - An array of country text variations.
 * @returns {boolean} True if the search term is missing the country text variations, false otherwise.
 */
function checkCountryIsMissing(search, countryTextArray) {
  return countryTextArray.every(text => !search.toLowerCase().includes(text.toLowerCase()));
}

/**
 * Returns the first result location that matches the country text.
 * @param {Object[]} results - An array of geocoding results.
 * @param {string} countryText - The country text to match.
 * @returns {Object} The first result location that matches the country text, or null if none found.
 */
function getResultLocation(results, countryText) {
  for (const result of results) {
    const split = result.formatted_address.split(", ");
    if (split.includes(countryText)) return result;
  }
}

/**
 * Returns the first result location that matches the country text variations.
 * @param zoomNumber
 */
function setMapZoom(zoomNumber) {
  store.dispatch(setMapSize(zoomNumber));
}

/**
 * Returns the first result location that matches the country text variations.
 * @param filterData
 */
function setDealerFilter(filterData) {
  store.dispatch(setFilterDealerData(filterData));
}

/**
 * Returns the first result location that matches the country text variations.
 * @param position - The position to search for.
 * @param skipConvert - A flag to indicate if the position should be converted.
 */
function filterDealersFromPosition(position, skipConvert) {
  var location = null;
  let mapZoom = 7;
  if (!skipConvert) {
    location = {
      lat: position.geometry.location.lat(),
      lng: position.geometry.location.lng()
    };
    let dealerSearchLocation;
    dealerSearchLocation = location;

    switch (position.types[0].toString()) {
      case "locality":
        mapZoom = 9;
        break;
      case "sublocality":
        mapZoom = 8;
        break;
      case "political":
        mapZoom = 9;
        break;
      case "postal_code":
        mapZoom = 10;
        break;
      case "country":
        mapZoom = 4;
        break;
      case "administrative_area_level_1":
        // mapZoom = applicationStore.data.nationalCountryCode === "US" ? 7 : 5;
        mapZoom = 7;
        break;
      case "administrative_area_level_2":
        // mapZoom = applicationStore.data.nationalCountryCode === "US" ? 8 : 6;
        mapZoom = 8;
        break;
      default:
        mapZoom = 7;
        break;
    }
  } else {
    mapZoom = 9;
  }
  // update the map zoom
  setMapZoom(mapZoom);
  //recalculate dealer distances

  let dealers = getDealerNonFilter();

  dealers = dealers.map(function (dealer) {
    return {
      ...dealer,  // Spread the original object properties
      distance: getDistance(location, dealer['position']) // Add new distance property
    };
  });

  return getFilteredDealers(true, dealers);

}

/**
 * Handles the dealer selection change search event.
 * @param {string} search - The search type.
 * @param {string} searchType - The dealer search type.
 * @param {boolean} dealerSearchFlag - The dealer search flag.
 * @param {Object} dealerSearchLocation - The dealer search location.
 * @param {boolean} dealerSearchIsEmpty - The dealer search is empty flag.
 * @param {boolean} dealerSearchShowWarning - The dealer search show warning flag.
 * @param {boolean} userInitialLocationModal - The user initial location modal flag.
 * @param {boolean} enableDealerNameList - The enable dealer name list flag.
 */
export function onDealerSelectionChangeSearch(search, searchType) {
  // searchType, dealerSearch, dealerSearchFlag, dealerSearchLocation, dealerSearchIsEmpty, dealerSearchShowWarning, userInitialLocationModal, enableDealerNameList
  // use the above variables to set the state
  let dealerSearchFlag,
    dealerSearchLocation,
    dealerSearch,
    dealerSearchIsEmpty,
    dealerSearchShowWarning,
    userInitialLocationModal,
    enableDealerNameList,
    filterDataDealer,
    filteredPositions;

  const maps = window.google.maps;

  const geocoder = new maps.Geocoder();

  if (searchType === DEALER_CITY_SEARCH) {
    if (!geocoder || !search) return console.error("Geocoder not found");
    // get the country code
    // const countryCode = applicationStore.data.nationalSite ? applicationStore.data.nationalCountryCode : applicationStore.data.groupCountryCode;
    let countryCode = applicationStore.data.nationalSite ? applicationStore.data.nationalCountryCode : applicationStore.data.groupCountryCode;
    if (!countryCode) {
      countryCode = "CA";
    }
    // get the country text
    const countryText = getCountryText(countryCode);
    // get the country text variations
    const countryTextArray = getCountryTextArray(countryCode);
    // return if the search term already contains the country text
    const countryIsMissing = checkCountryIsMissing(search, countryTextArray);

    if (countryIsMissing) search = `${search}, ${countryText}`;

    searchName = search;
    geocoder.geocode({'address': search}, function (results, status) {
      if (status !== maps.GeocoderStatus.OK) {
        dealerSearchShowWarning = true;
        return;
      }

      // get the first result location that matches the country text
      const resultLocation = getResultLocation(results, countryText);

      if (resultLocation) {
        userInitialLocationModal = false;
        filteredPositions = filterDealersFromPosition(resultLocation, false);
      } else {
        dealerSearchShowWarning = true;
        getFilteredDealers();
      }
    });
  } else if (searchType === "DEALER_NAME_SEARCH") {

    dealerSearch = search;
    dealerSearchFlag = true;
    dealerSearchIsEmpty = false;
    dealerSearchShowWarning = false;
    userInitialLocationModal = false;
    enableDealerNameList = false;
    getFilteredDealers();
  }
  return {
    searchType,
    dealerSearch,
    dealerSearchFlag,
    dealerSearchLocation,
    dealerSearchIsEmpty,
    dealerSearchShowWarning,
    userInitialLocationModal,
    enableDealerNameList,
    filteredPositions,
    filterDataDealer

  };
}

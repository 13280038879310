module.exports = {
  'startNewQuote':'Nouvelle Soumission',
  'title':'Mercedes-Benz',
	'ourBrands': 'Nos Marques',
  'MO':'Mercedes Original',
  'MOE':'Mercedes Original étendu',

  homepage_long_1: 'Les pneus Mercedes Original sont conçus pour répondre aux exigences et aux caractéristiques de conduite uniques de chaque modèle Mercedes-Benz. Ils travaillent de pair avec la surface de la route, la suspension et les systèmes de sécurité pour vous assurer une sécurité optimale, surtout en matière de freinage et d’aquaplanage.',
  homepage_long_2: 'Comparativement aux pneus classiques, les pneus Mercedes Original sont conçus pour offrir une faible résistance au roulement en optimisant la maniabilité et le confort. Ces caractéristiques se traduisent par de meilleures performances de conduite, mais aussi par une consommation de carburant réduite et une meilleure rentabilité.',
  homepage_long_3_b: 'Les Pneus d\'hiver Approuvés Mercedes-Benz possèdent des sculptures de semelle et des composés de caoutchouc évolués qui fournissent une traction supérieure sur les routes froides et glacées',
  homepage_long_3_c: 'Prix des pneus concurrentiels',
  homepage_long_3_d: 'Installation experte par des techniciens formés par Mercedes-Benz',
  homepage_long_3_e: 'Toutes les grandes marques, incluant Pirelli, Continental, Michelin, Dunlop et plus encore',
  homepage_long_4: 'MOE signifie Mercedes Original Extended. Cette variante des pneus Mercedes Original présente des propriétés de roulage à plat qui vous permettent de garder le contrôle de votre véhicule après une perte de pression. Vous pourrez ainsi vous rendre à un point de service Mercedes-Benz ou un centre de remplacement de pneus, en toute sécurité.',
  homepage_long_5: 'Vos pneus MO lient votre Mercedes-Benz à la route. Dès que le mercure dépasse régulièrement les 7ºC, il est essentiel d’installer des pneus adaptés à la saison pour optimiser la sécurité, les performances et le rendement en carburant de votre véhicule. Soyez prêt à temps pour le printemps. Prenez rendez-vous dès maintenant.',

  homepage_title: 'PneusApprouvés Mercedes-Benz',
  homepage_winterpackages: 'Ensembles de pneu et roue d\'hiver',
  homepage_genuinewheels: 'Les Avantages Sur La Route',
  homepage_wheeldevelopment: 'Sécurité accrue',
  homepage_design: 'Performance et efficacité',
  homepage_quality: 'Plus de mobilité avec MOE',

  nonOEWarrning: 'Vous avez sélectionné montrer des pneus non approuvés. Mercedes recommande toujours d\'utiliser des pneus approuvés et adaptés à votre véhicule.',
  homepage_your_first_choice:'Conçus et testés pour Mercedes-Benz\n' +
      '\n' +
      'La différence MO',
  homepage_wrapup:'Seasons change, and so should your tires.',
  time_to_change:'7ºC? C’est le temps de changer vos pneus.',
  homepage_why_mercedes:'Why Purchase Your Tires From A Mercedes-Benz Dealer',

  'logo': {
    'text': 'Les pneus approuvés Mercedes-Benz'
  },
  'header': {
    'mercedesHeader': 'Centre de pneus approuvés'
  },
  'common': {
    'viewTires': 'Voir les pneus',
    'viewWheels': 'Voir les jantes',
    'viewAccessories': 'Voir les accessoires',
    'viewPackages': 'Voir les packages',
    'welcomeMessage': 'Bienvenue au Centre de pneus apprové et jantes d\'origine de Mercedes-Benz',
    'getStarted': 'Démarrer la recherche',
    'customizeYourRide': 'Personnalisez votre vehicle',
    'myWishlist': 'Liste de souhaits',
    'select': 'Sélectionnez',
    'yes': 'Oui',
    'no': 'Non',
    'featured': 'Ensembles de pneu et roue d\'hiver',
    'enter': 'Entrer',
    'dealerSelectionPlaceholder': 'Recherche par ville, province ou code postal',
    'selectByProvinceTitle': 'Sélectionnez votre province',
    'selectByProvinceHeaderTitle': 'Centre de PneusApprouvés',
    'myCart': 'Liste de souhaits'
  },
  'breadcrumbs': {
    'staticHome': 'Accueil'
  },
  'cart': {
    'getCartReview': 'Revoir ma liste de souhaits',
    'cartBarTitlePrefix': 'Aperçu de ma liste de souhaits',
    'cartBarTitleSuffix1': 'article',
    'cartBarTitleSuffix2': 'articles',
    'mywishlist': 'Liste de souhaits'
  },
  'search': {
    'placeholderSearchTireSize': '225/45R17 ou 2254517',
    'placeholderSearchPartNumber': 'CON15480150000AS ou 15480150000',
    'placeholderSearchChassis': 'w204 ou w204.012',
    'searchChassisTag': 'Recherche de Châssis'
  },
  'productTypes': {
    'packages': 'Ensemble de jantes et pneus',
    'packagesTab': 'Ensemble de jantes et pneus',
	  'searchTires': 'Pneus d\'Origine Mercedes',//'Les pneus approuvés Mercedes-Benz',
	  'searchWheels': 'Genuine Wheels',
	  'searchPackages': 'Ensemble de jantes et pneus',
	  'tireSelectText': 'Les pneus d\'hiver approuvés Mercedes-Benz ont des bandes de roulement et des composés de caoutchouc perfectionnés qui offrent une adhérence supérieure sur les routes froides et verglacées. Ils offrent également une meilleure stabilité en cas de freinage et une meilleure maniabilité.',
	  'wheelSelectText': 'Mercedes-Benz suit un programme intensif de développement et d’essais pour assurer la qualité de ses différents modèles de jantes. Chez Mercedes-Benz, nous ajustons le développement et la phase d’essais au profil de résistance des jantes en alliage léger sous des conditions réelles. Cela nous permet de nous assurer que nos jantes en alliage léger figurent parmi les plus sécuritaires, les plus fiables et les plus durables sur le marché automobile d’aujourd’hui.',
	  'accessorySelectText': '',
	  'packageSelectText': 'Les pneus font partie intégrante des performances de votre Mercedes-Benz. En effet, seuls les pneus unissent votre voiture à la route. Ils ont donc un impact sur l\'accélération, le freinage, la prise de virage et la sécurité globale de votre véhicule.'
  },
  'quote': {
    'noteFieldLabel': 'Remarques',
    'userNoteLabel': 'Remarques'
  },
  'quoteReport': {
    'quoteNotes': 'Remarques'
   },
  'product': {
    'showOnlyApproved': 'Afficher les PneusApprouvés',
    'nonApprovedTires': 'Non PneusApprouvés',
    'approvedTires':'Pourquoi Choisir Les Pneus Mo?',
    'good': '',
    'better': '',
    'best': '',
    'dealerPrice': 'Prix de vente',
    'retailPrice': 'Prix de détail',
    'wheel': 'Jante',
    'wheelTag': '',
    'tire': 'Pneus d\'Origine Mercedes',//'Les pneus approuvés Mercedes-Benz',
    'tireTag': 'Trouver les pneus approuvés par Mercedes-Benz soit par véhicule ou par grandeur de pneu',
    'accessory': 'Accessoire',
    'accessoryTag': '',
    'package': 'Ensemble de jantes et pneus',
    'packageTag': 'Trouver le meilleur ensemble de pneus d\'hiver et de roues pour votre véhicule',
    'collection': 'Collection',
    'collectionTag': '',
    'addToQuote': 'Ajouter au panier',
    'addToWishlist': 'Ajouter au panier',
    'removeFromWishlist': 'Enlevé de panier',
    'wheelComment': 'Remarques',
    'approvedTire': 'Pneus d\'Origine Mercedes',//'Les pneus approuvés Mercedes-Benz',
    'itemAlert': '!S\'il vous plaît voir plus de détails pour les véhicules qui vont avec ce pneu',
    'modal': {
      'wishlistSaved': 'La liste de souhaits a été enregistrée avec succès.',
      'wishlistSaveFailed': 'La liste de souhaits n\'a pas pu être enregistrée.'
    }
  },
  'partInfoModal': {
    'regionalPrice': 'Prix MB (Région)'
  },
  'appointmentForm': {
    'printWishlist': 'Imprimer la Liste de souhaits'
  },
  'wishlist': {
    'title': 'Votre liste de souhaits',
    'clear': 'Supprimer la liste de souhaits',
    'saveWishlist': 'Enregistrer la liste de souhaits',
    'emailWishlist': 'Expédier la liste de souhaits par courriel',
    'scheduleAppointmentMsg': 'Planifier un rendez-vous avec votre détaillant pour votre liste de souhaits',
    'personalizedWishlist': 'Votre liste de souhaits personnalisée',
    'wishlistSaved': 'Votre liste de souhaits a été enregistré avec succès.',
    'wishlistSaveFailed': 'L\'enregistrement de votre liste de souhaits a échoué.'
  },
  'guestAccountCreation': {
    'noEmail': 'Crée un compte'
  }
};

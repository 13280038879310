module.exports = {
	
	findThePerfectTires: 'Find the perfect Tires',
	chooseTires: 'Choose Tires',
	
	homepage_title_1: 'Savez-vous quels pneus conviennent à votre véhicule?',
	homepage_title_2: 'Nous savons.',
	homepage_subtitle: 'Chaque Audi a besoin d\'une parfaite paire de pneus. L\'adaptation du pneu à la vitesse maximale et aux exigences de chargement de votre véhicule assurera une traction, une maniabilité et une sécurité améliorées. Lorsque votre véhicule n\'est pas équipé avec des pneus appropriés, les performances en souffrent, ce qui réduit la traction, la maniabilité et le contrôle. Pour vous assurer que votre Audi est équipée avec les bons pneus, votre concessionnaire Audi peut vous fournir la recommandation appropriée qui répond parfaitement aux besoins de votre Audi.',

	homepage_wheel_title: 'Roues',
	homepage_wheel_subtitle: 'Que ce soit pour se rendre au travail ou parcourir de longs trajets, une chose est certaine : Les roues et accessoires Audi d’origine sont vos parfaits alliés pour tous vos déplacements, tout au long de l’année.',
	homepage_wheel_button: 'Trouvez des roues pour votre Audi',

	homepage_tire_title: 'Pneus',
	homepage_tire_subtitle: 'La conception unique des pneus d’hiver permet d’affronter les défis de la conduite hivernale d’une manière inégalée par les pneus toutes saisons. Leur bande de roulement est également conçue pour améliorer la traction sur des surfaces humides, enneigées, glacées, gelées ou recouvertes de neige fondante.',
	homepage_tire_button: 'Trouvez des pneus pour votre Audi ',
	
	homepage_package_title: 'Ensembles',
	homepage_package_subtitle: 'Les roues d’hiver et les ensembles Audi sont développés en complémentarité avec les véhicules Audi, ce qui signifie qu’ils s’harmonisent à merveille avec les modèles pour lesquels ils sont construits. Ils sont conçus pour offrir l’ajustement le plus optimal, garantissant que les roues ne dépassent pas la coque de votre véhicule, et ce, peu importe leur largeur.',
	homepage_package_button: 'Trouvez des ensembles pour votre Audi ',
	
  homepageTitleText: 'Votre concessionnaire Audi est le meilleur endroit pour acheter des pneus',
  homepageText: 'Trouvez des pneus adaptés à votre véhicule. Indiquez-nous votre modèle Audi et nous vous recommanderons des choix qui assurent la meilleure performance, une sécurité accrue et une économie de carburant optimale. En plus d’être facile et pratique, l’achat de pneus neufs et de jantes chez votre concessionnaire Audi vous permet de bénéficier d’une pose par des techniciens certifiés Audi.',
	'common': {
		featuredItems: "Articles populaires"
	},
	'productTypes': {
		tireSelectText: 'Les générations plus récentes de pneus d\'hiver sont conçues avec un composé de caoutchouc plus flexible qui est moins enclin à se raidir dans des températures plus froides. Leur bande de roulement est également conçu pour offrir une meilleure traction sur les surfaces humide, neigeuses, glacées et froid sec.',
		wheelSelectText: 'Que vous conduissez au travail ou que vous voyagez de longues distances, une chose est sûre: Les ensembles et les accessoires de roues d\'hiver authentiques d\'Audi sont votre combinaison idéal pour aller partout où vous voulez aller cet hiver.',
		accessorySelectText: '',
		packageSelectText: 'Les ensembles et accessoires de roues d\'hiver d\'Audi sont votre combinaison idéal pour aller partout où vous voulez aller cet hiver.'
	},
	'search': {
		placeholderSearchTireSize: 'recherche par taille de pneu: e.g. 245/40R18'
	},
	'launcher': {
		enterTiresize: 'Enter your tire size: e.g. 245/40R18'
	}
};
import { applicationActions } from "../../actions/ApplicationActions";
import { applicationStore } from "../../stores/ApplicationStore";

import TopLauncher from "../homepage/TopLauncher.jsx";

import { RouterStates } from "../../constants/RouterStates";

import {
  getTrackingEventCategory,
  getRouterState,
} from "../../service/UtilityService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");

export class SearchModalFullScreen extends I18nComponent {
  state = {
    initialize: true,
  };

  updateState = (state) => {
    this.setState(state);
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("Search Modal");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Search Modal Access"
    );
  }

  render() {
    return (
      <search>
        <curtain>
          <text onClick={this.onCloseSearchModal}>
            {this.t("common.close").toUpperCase()}
          </text>
        </curtain>
        <box>
          {this.enableLauncherTitle() ? (
            <box className="launchertitle mobile">
              <h1>{this.t("reducedHomepageTitle")}</h1>
            </box>
          ) : (
            false
          )}
          <TopLauncher
            landingpage={getRouterState() === RouterStates.LANDING_PAGE}
            partLauncher={true}
          />
        </box>
      </search>
    );
  }

  enableLauncherTitle = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mazda":
        return true;
      default:
        return false;
    }
  };

  onCloseSearchModal = () => {
    applicationActions.deactivateSearchFlag();
  };
}

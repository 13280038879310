import { applicationStore } from "../../stores/ApplicationStore.js";
import { isEmpty } from "../../service/UtilityService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");

export class Image extends I18nComponent {
  getDefaultState = () => ({
    error: false,
    loaded: false,
    disabledImages: false,
  });
  state = this.getDefaultState();

  componentWillUpdate(nextProps, nextState) {
    if (
      nextProps.src !== this.props.src ||
      nextProps.className !== this.props.className ||
      nextState.disabledImages !== this.state.disabledImages
    ) {
      this.setState(this.getDefaultState());
    }
  }

  render() {
    var altTag = "Image";
    if (this.props.alt) altTag = this.props.alt;
    if (!this.state.loaded || !this.state.error) {
      if (this.props.wheelVisualizerImage) {
        return (
          <div>
            <img
              style={this.props.style}
              src={this.getImageSrc()}
              onClick={this.props.onClick}
              onError={this.onError}
              className={this.props.className}
              alt={altTag}
            />
            <span className="vehicle-disclaimer">
              {this.t("common.visualizerDisclaimer")}
            </span>
          </div>
        );
      } else {
        return (
          <img
            style={this.props.style}
            src={this.getImageSrc()}
            onClick={this.props.onClick}
            onError={this.onError}
            className={this.props.className}
            data-dtm={!isEmpty(this.props.datadtm) ? this.props.datadtm : ""}
            alt={altTag}
          />
        );
      }
    } else if (this.props.useMissingImageOnError) {
      return (
        <img
          src="/static/img/no_image_icon.png"
          onClick={this.props.onClick}
          onError={this.onError}
          className={this.props.className}
          alt="Image Missing"
        />
      );
    } else {
      if (this.props.wheelVisualizerImage || this.props.icon) return false;
      else return <div className="image-placeholder"></div>;
    }
  }

  getImageSrc = () => {
    if (this.state.disabledImages) {
      return "IMAGE_NOT_FOUND";
    } else {
      return this.props.src;
    }
  };

  onError = () => {
    this.setState({
      error: true,
      loaded: true,
    });
  };
}

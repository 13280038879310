var $ = require("jquery");
import { FieldPrototype } from "./FieldPrototype.jsx";

var React = require("react");
var Validator = require("validator");

export class TelField extends FieldPrototype {
  static defaultProps = {
    errorNotEmpty: "The field is required therefore can't be empty",
    required: true,
  };

  renderCustomInput = () => {
    return (
      <div className="tel-field">
        <input
          id={this.props.id}
          className={this.props.className}
          data-dtm={this.props.datadtm}
          type="text"
          placeholder="(___) ___-____"
          onChange={this.onChangeInternal}
          onBlur={this.onBlur}
          ref="input"
          name={this.props.id}
          value={this.props.value}
        />
      </div>
    );
  };

  onChangeInternal = (ev) => {
    this.props.onChange(
      ev.target.value,
      this.isValid(ev.target.value).length === 0
    );
  };

  isValid = (val) => {
    var trimed = Validator.trim(val);
    if (this.props.required && Validator.equals(trimed, "")) {
      return [this.props.errorNotEmpty];
    } else {
      return [];
    }
  };
}

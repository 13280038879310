import { DispatcherActions } from '../constants/DispatcherActions.js';
import { applicationStore } from '../stores/ApplicationStore.js';
import { productListStore } from '../stores/ProductListStore.js';
import { vehicleSelectionStore } from '../stores/VehicleSelectionStore.js';
import { AbstractAction } from './AbstractAction.js';

import { getDestination } from '../api/RequestBuilderHelper.js';

import { getAppDealerId } from '../service/UtilityService';
import store from "../stores/configureStore";
import {loadVehicleInfo, onListTiresLoaded} from "../redux/features/productList";
import {vehicleYear} from "../redux/features/searchTires";

var VehicleSelectionActions = AbstractAction.extend({

  loadYears: function() {
    this.apiGet('/vehicle/getVehicleYears.php', {
      dealerId: getAppDealerId(),
      nationalAccountId: applicationStore.data.nationalAccountId,
      partType: getDestination(applicationStore.data.productType ? applicationStore.data.productType : applicationStore.data.defaultProductType),
      minimumYear: applicationStore.data.minimumYears[applicationStore.data.productType ? applicationStore.data.productType : applicationStore.data.defaultProductType],
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      store.dispatch(vehicleYear(response));
      this.dispatch(DispatcherActions.VEHICLE_YEARS_LOADED, response);
    }.bind(this));
  },

	loadYearsForSearchModal: function(productType) {
		this.apiGet('/vehicle/getVehicleYears.php', {
      dealerId: getAppDealerId(),
      nationalAccountId: applicationStore.data.nationalAccountId,
      partType: getDestination(productType ?? applicationStore.data.defaultProductType),
      minimumYear: applicationStore.data.minimumYears[productType],
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      this.dispatch(DispatcherActions.SEARCH_MODAL_VEHICLE_YEARS_LOADED, response);
    }.bind(this));
	},

  loadMakes: function() {
    this.apiGet('/vehicle/getVehicleManufacturers.php', {
      nationalAccountId: applicationStore.data.nationalAccountId,
      vehicleYearId: applicationStore.data.year,
      partType: getDestination(applicationStore.data.productType ? applicationStore.data.productType : applicationStore.data.defaultProductType),
      dealerId: getAppDealerId(),
      dealerGroupId: applicationStore.data.groupAccountId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      this.dispatch(DispatcherActions.VEHICLE_MAKES_LOADED, {
        primaryMakes: response.primaryVehicleManufacturers,
        secondaryMakes: response.secondaryVehicleManufacturers
      });
    }.bind(this));
  },

	loadMakesForSearchModal: function(productType, yearId) {
		this.apiGet('/vehicle/getVehicleManufacturers.php', {
      nationalAccountId: applicationStore.data.nationalAccountId,
      vehicleYearId: yearId,
      partType: getDestination(productType),
      dealerId: getAppDealerId(),
      dealerGroupId: applicationStore.data.groupAccountId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      this.dispatch(DispatcherActions.SEARCH_MODAL_VEHICLE_MAKES_LOADED, {
        primaryMakes: response.primaryVehicleManufacturers,
        secondaryMakes: response.secondaryVehicleManufacturers
      });
    }.bind(this));
	},

  loadCategories: function() {
    this.apiGet('/vehicle/getVehicleCategories.php', {
      dealerId: getAppDealerId(),
      partType: getDestination(applicationStore.data.productType ? applicationStore.data.productType : applicationStore.data.defaultProductType),
      vehicleYearId: applicationStore.data.year,
      vehicleManufacturerId: applicationStore.data.make,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      this.dispatch(DispatcherActions.VEHICLE_CATEGORIES_LOADED, response);
    }.bind(this));
  },

  loadModels: function() {
    this.apiGet('/vehicle/getVehicleModels.php', {
      dealerId: getAppDealerId(),
      partType: getDestination(applicationStore.data.productType ? applicationStore.data.productType : applicationStore.data.defaultProductType),
      vehicleYearId: applicationStore.data.year,
      vehicleManufacturerId: applicationStore.data.make,
      vehicleCategoryId: applicationStore.data.category,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      this.dispatch(DispatcherActions.VEHICLE_MODELS_LOADED, response);
    }.bind(this));
  },

	loadModelsForSearchModal: function(productType, yearId, makeId) {
		this.apiGet('/vehicle/getVehicleModels.php', {
      dealerId: getAppDealerId(),
      partType: getDestination(productType),
      vehicleYearId: yearId,
      vehicleManufacturerId: makeId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      this.dispatch(DispatcherActions.SEARCH_MODAL_VEHICLE_MODELS_LOADED, response);
    }.bind(this));
	},

  loadTrims: function() {
    this.apiGet('/vehicle/getVehicleSubmodels.php', {
      partType: getDestination(applicationStore.data.productType ? applicationStore.data.productType : applicationStore.data.defaultProductType),
      vehicleYearId: applicationStore.data.year,
      vehicleManufacturerId: applicationStore.data.make,
      vehicleCategoryId: applicationStore.data.category,
      vehicleModelId: applicationStore.data.model,
      isNational: applicationStore.data.nationalSite ? 1 : 0,
      dealerId: getAppDealerId()
    }).then(function(response) {
      this.dispatch(DispatcherActions.VEHICLE_TRIMS_LOADED, response);
    }.bind(this));
  },

  loadVehicleInfo: function() {
    return this.apiGet('/vehicle/getVehicleInfoByVehicleKeys.php', {
      dealerId: getAppDealerId(),
      productType: getDestination(applicationStore.data.productType),
      vehicleYearId: applicationStore.data.year,
      vehicleManufacturerId: applicationStore.data.make,
      vehicleCategoryId: applicationStore.data.category,
      vehicleModelId: applicationStore.data.model,
      vehicleSubmodelId: applicationStore.data.trim,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then((response)=> {
      // store.dispatch(onListTiresLoaded(response));
      store.dispatch(loadVehicleInfo(response));
      // this.dispatch(DispatcherActions.VEHICLE_INFO_LOADED, response);
    })
      .catch(function(error) {
        console.error( error);
      });
  },

  loadVehicleInfoByModel: function() {
    return this.apiGet('/vehicle/getVehicleInfoByVehicleYearAndModel.php', {
      vehicleYearId: vehicleSelectionStore.data.year ? vehicleSelectionStore.data.year : applicationStore.data.year,
      vehicleModelId: vehicleSelectionStore.data.model ? vehicleSelectionStore.data.model : applicationStore.data.model,
      isNational: applicationStore.data.nationalSite ? 1 : 0,
      dealerId: getAppDealerId()
    }).then(function(response) {
      store.dispatch(loadVehicleInfo(response));
    }.bind(this));
  },

  loadVehicleInfoByChassis: function() {

    return this.apiGet('/vehicle/getVehicleInfoByVehicleChassis.php', {
      dealerId: getAppDealerId(),
      vehicleChassis: productListStore.data.chassis,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      store.dispatch(loadVehicleInfo(response));
      // this.dispatch(DispatcherActions.VEHICLE_INFO_LOADED, response);
    }.bind(this));
  },

  loadVehicleSelectionModal: function() {
    this.apiGet('/vehicle/getVehicleTreeByManufacturer.php', {
      nationalAccountId: applicationStore.data.nationalAccountId,
      minimumYear: applicationStore.data.minimumYears[applicationStore.data.productType],
      isNational: applicationStore.data.nationalSite ? 1 : 0,
      dealerId: getAppDealerId()
    }).then(function(response) {
      this.dispatch(DispatcherActions.VEHICLE_TREE_LOADED, response);
    }.bind(this));
  },

  yearSelectedFromModal: function(yearId) {
    this.dispatch(DispatcherActions.VEHICLE_SELECTION_YEARS_SELECTED_FROM_MODAL, yearId);
  },

  categorySelectedFromModal: function(categoryId) {
    this.dispatch(DispatcherActions.VEHICLE_SELECTION_CATEGORY_SELECTED_FROM_MODAL, categoryId);
  },

  modelSelectedFromModal: function(modelId) {
    this.dispatch(DispatcherActions.VEHICLE_SELECTION_MODEL_SELECTED_FROM_MODAL, modelId);
  },

  yearSelected: function(yearId) {
    this.dispatch(DispatcherActions.VEHICLE_SELECTION_YEARS_SELECTED, yearId);
  },

  makeSelected: function(makeId) {
    this.dispatch(DispatcherActions.VEHICLE_SELECTION_MAKE_SELECTED, makeId);
  },

  categorySelected: function(categoryId) {
    this.dispatch(DispatcherActions.VEHICLE_SELECTION_CATEGORY_SELECTED, categoryId);
  },

  modelSelected: function(modelId) {
    this.dispatch(DispatcherActions.VEHICLE_SELECTION_MODEL_SELECTED, modelId);
  },

  trimSelected: function(trimId) {
    this.dispatch(DispatcherActions.VEHICLE_SELECTION_TRIM_SELECTED, trimId);
  },

  resetVehicleSearch: function() {
    this.dispatch(DispatcherActions.RESET_VEHICLE_SEARCH, {});
  }

});

export const vehicleSelectionActions = new VehicleSelectionActions();

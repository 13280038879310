import { applicationStore } from "../../stores/ApplicationStore.js";
import { applicationActions } from "../../actions/ApplicationActions.js";
import { ProductTypes } from "../../constants/ProductTypes.js";
import { i18nMixin } from "../../i18n/i18nMixin.js";
import { Image } from "../ux/Image.jsx";

import {
  getTabIndex,
  getTrackingEventCategory,
  getTrackingEventObject,
  scrollTop,
} from "../../service/UtilityService";
import { inivisCaptchaOnComponentDidUpdate } from "../../service/CaptchaService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var _ = require("lodash");
var Cx = require("classnames");

export class ProductTypeTilePadded extends I18nComponent {
  state = {
    productTypes: applicationStore.data.productTypes,
  };

  updateState = (state) => {
    this.setState(state);
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
  }

  componentDidUpdate(nextProps, lastState) {
    var callbackList = [];
    for (var i in this.state.productTypes) {
      switch (this.state.productTypes[i]) {
        case ProductTypes.TIRES:
          callbackList.push(this.clickTires);
          break;
        case ProductTypes.ALLOY_WHEELS:
          callbackList.push(this.clickAlloyWheels);
          break;
        case ProductTypes.ACCESSORIES:
          callbackList.push(this.clickAccessories);
          break;
        case ProductTypes.PACKAGES:
          callbackList.push(this.clickPackages);
          break;
      }
    }

    inivisCaptchaOnComponentDidUpdate(
      this.state,
      this.updateState,
      callbackList
    );
  }

  render() {
    var floatChecker = null;

    var productTypes = this.state.productTypes;
    if (applicationStore.data.enableMercedesFeatures) {
      productTypes = [];
      if (this.state.productTypes.indexOf(ProductTypes.PACKAGES) > -1)
        productTypes.push(ProductTypes.PACKAGES);
      if (this.state.productTypes.indexOf(ProductTypes.TIRES) > -1)
        productTypes.push(ProductTypes.TIRES);
      if (this.state.productTypes.indexOf(ProductTypes.ALLOY_WHEELS) > -1)
        productTypes.push(ProductTypes.ALLOY_WHEELS);
      if (this.state.productTypes.indexOf(ProductTypes.ACCESSORIES) > -1)
        productTypes.push(ProductTypes.ACCESSORIES);
    }

    var content = _.map(
      productTypes,
      function (productType, index) {
        switch (productType) {
          case ProductTypes.TIRES:
            floatChecker = this.checkForWhereToFloat(floatChecker);

            if (floatChecker.flipflop) {
              return (
                <li
                  className={
                    "product-selection-row" +
                    floatChecker.background +
                    (floatChecker.firstRow ? " first-row" : "")
                  }
                  role="button"
                  tabIndex={getTabIndex()}
                  key={"homepage-select-" + productType}
                  onClick={this.clickTires}
                  onKeyDown={(e) => this.selectTires(e)}
                >
                  <div className={"product-info" + floatChecker.textClassName}>
                    <div className="info-container">
                      <div className="info-display">
                        <h2 className="product-title">
                          {this.t("productTypes.searchTires")}
                        </h2>
                        <span className="product-text">
                          {this.t("productTypes.tireSelectText")}
                        </span>

                        <span className="product-select btn">
                          {this.t("productTypes.searchTires")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={"product-image" + floatChecker.imageClassName}
                  >
                    <div className="image-container">
                      <Image
                        src={
                          "/static/img/" +
                          applicationStore.data.defaultTemplate +
                          "/product_selection_tires.png"
                        }
                        className="product-select-image"
                      />
                    </div>
                  </div>
                </li>
              );
            } else {
              return (
                <li
                  className={
                    "product-selection-row" +
                    floatChecker.background +
                    (floatChecker.firstRow ? " first-row" : "")
                  }
                  role="button"
                  tabIndex={getTabIndex()}
                  key={"homepage-select-" + productType}
                  onClick={this.clickTires}
                  onKeyDown={(e) => this.selectTires(e)}
                >
                  <div
                    className={"product-image" + floatChecker.imageClassName}
                  >
                    <div className="image-container">
                      <Image
                        src={
                          "/static/img/" +
                          applicationStore.data.defaultTemplate +
                          "/product_selection_tires.png"
                        }
                        className="product-select-image"
                      />
                    </div>
                  </div>
                  <div className={"product-info" + floatChecker.textClassName}>
                    <div className="info-container">
                      <div className="info-display">
                        <h2 className="product-title">
                          {this.t("productTypes.searchTires")}
                        </h2>
                        <span className="product-text">
                          {this.t("productTypes.tireSelectText")}
                        </span>

                        <span className="product-select btn">
                          {this.t("productTypes.searchTires")}
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              );
            }

          case ProductTypes.ALLOY_WHEELS:
            floatChecker = this.checkForWhereToFloat(floatChecker);

            if (floatChecker.flipflop) {
              return (
                <li
                  className={
                    "product-selection-row" +
                    floatChecker.background +
                    (floatChecker.firstRow ? " first-row" : "")
                  }
                  role="button"
                  tabIndex={getTabIndex()}
                  key={"homepage-select-" + productType}
                  onClick={this.clickAlloyWheels}
                  onKeyDown={(e) => this.selectAlloyWheels(e)}
                >
                  <div className={"product-info" + floatChecker.textClassName}>
                    <div className="info-container">
                      <div className="info-display">
                        <h2 className="product-title">
                          {this.t("productTypes.searchWheels")}
                        </h2>
                        <span className="product-text">
                          {this.t("productTypes.wheelSelectText")}
                        </span>

                        <span className="product-select btn">
                          {this.t("productTypes.searchWheels")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={"product-image" + floatChecker.imageClassName}
                  >
                    <div className="image-container">
                      <Image
                        src={
                          "/static/img/" +
                          applicationStore.data.defaultTemplate +
                          "/product_selection_wheels.png"
                        }
                        className="product-select-image"
                      />
                    </div>
                  </div>
                </li>
              );
            } else {
              return (
                <li
                  className={
                    "product-selection-row" +
                    floatChecker.background +
                    (floatChecker.firstRow ? " first-row" : "")
                  }
                  role="button"
                  tabIndex={getTabIndex()}
                  key={"homepage-select-" + productType}
                  onClick={this.clickAlloyWheels}
                  onKeyDown={(e) => this.selectAlloyWheels(e)}
                >
                  <div
                    className={"product-image" + floatChecker.imageClassName}
                  >
                    <div className="image-container">
                      <Image
                        src={
                          "/static/img/" +
                          applicationStore.data.defaultTemplate +
                          "/product_selection_wheels.png"
                        }
                        className="product-select-image"
                      />
                    </div>
                  </div>
                  <div className={"product-info" + floatChecker.textClassName}>
                    <div className="info-container">
                      <div className="info-display">
                        <h2 className="product-title">
                          {this.t("productTypes.searchWheels")}
                        </h2>
                        <span className="product-text">
                          {this.t("productTypes.wheelSelectText")}
                        </span>

                        <span className="product-select btn">
                          {this.t("productTypes.searchWheels")}
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              );
            }

          case ProductTypes.ACCESSORIES:
            floatChecker = this.checkForWhereToFloat(floatChecker);

            if (floatChecker.flipflop) {
              return (
                <li
                  className={"product-selection-row" + floatChecker.background}
                  role="button"
                  tabIndex={getTabIndex()}
                  key={"homepage-select-" + productType}
                  onClick={this.clickAccessories}
                  onKeyDown={(e) => this.selectAccessories(e)}
                >
                  <div className={"product-info" + floatChecker.textClassName}>
                    <div className="info-container">
                      <div className="info-display">
                        <h2 className="product-title">
                          {this.t("productTypes.searchAccessories")}
                        </h2>
                        <span className="product-text">
                          {this.t("productTypes.accessorySelectText")}
                        </span>

                        <span className="product-select btn">
                          {this.t("productTypes.searchAccessories")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={"product-image" + floatChecker.imageClassName}
                  >
                    <div className="image-container">
                      <Image
                        src={
                          "/static/img/" +
                          applicationStore.data.defaultTemplate +
                          "/product_selection_accessories.png"
                        }
                        className="product-select-image"
                      />
                    </div>
                  </div>
                </li>
              );
            } else {
              return (
                <li
                  className={"product-selection-row" + floatChecker.background}
                  role="button"
                  tabIndex={getTabIndex()}
                  key={"homepage-select-" + productType}
                  onClick={this.clickAccessories}
                  onKeyDown={(e) => this.selectAccessories(e)}
                >
                  <div
                    className={"product-image" + floatChecker.imageClassName}
                  >
                    <div className="image-container">
                      <Image
                        src={
                          "/static/img/" +
                          applicationStore.data.defaultTemplate +
                          "/product_selection_accessories.png"
                        }
                        className="product-select-image"
                      />
                    </div>
                  </div>
                  <div className={"product-info" + floatChecker.textClassName}>
                    <div className="info-container">
                      <div className="info-display">
                        <h2 className="product-title">
                          {this.t("productTypes.searchAccessories")}
                        </h2>
                        <span className="product-text">
                          {this.t("productTypes.accessorySelectText")}
                        </span>

                        <span className="product-select btn">
                          {this.t("productTypes.searchAccessories")}
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              );
            }

          case ProductTypes.PACKAGES:
            floatChecker = this.checkForWhereToFloat(floatChecker);

            if (floatChecker.flipflop) {
              return (
                <li
                  className={"product-selection-row" + floatChecker.background}
                  role="button"
                  tabIndex={getTabIndex()}
                  key={"homepage-select-" + productType}
                  onClick={this.clickPackages}
                  onKeyDown={(e) => this.selectPackages(e)}
                >
                  <div className={"product-info" + floatChecker.textClassName}>
                    <div className="info-container">
                      <div className="info-display">
                        <h2 className="product-title">
                          {this.t("productTypes.searchPackages")}
                        </h2>
                        <span className="product-text">
                          {this.t("productTypes.packageSelectText")}
                        </span>

                        <span className="product-select btn">
                          {this.t("productTypes.searchPackages")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={"product-image" + floatChecker.imageClassName}
                  >
                    <div className="image-container">
                      <Image
                        src={
                          "/static/img/" +
                          applicationStore.data.defaultTemplate +
                          "/product_selection_packages.png"
                        }
                        className="product-select-image"
                      />
                    </div>
                  </div>
                </li>
              );
            } else {
              return (
                <li
                  className={"product-selection-row" + floatChecker.background}
                  role="button"
                  tabIndex={getTabIndex()}
                  key={"homepage-select-" + productType}
                  onClick={this.clickPackages}
                  onKeyDown={(e) => this.selectPackages(e)}
                >
                  <div
                    className={"product-image" + floatChecker.imageClassName}
                  >
                    <div className="image-container">
                      <Image
                        src={
                          "/static/img/" +
                          applicationStore.data.defaultTemplate +
                          "/product_selection_packages.png"
                        }
                        className="product-select-image"
                      />
                    </div>
                  </div>
                  <div className={"product-info" + floatChecker.textClassName}>
                    <div className="info-container">
                      <div className="info-display">
                        <h2 className="product-title">
                          {this.t("productTypes.searchPackages")}
                        </h2>
                        <span className="product-text">
                          {this.t("productTypes.packageSelectText")}
                        </span>

                        <span className="product-select btn">
                          {this.t("productTypes.searchPackages")}
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              );
            }
        }
      },
      this
    );

    var productSelectorClass = Cx({
      "select-product-tile-padded": true,
      "has-launcher": applicationStore.data.enableHomepageLauncher,
      "homepage-refactor":
        applicationStore.data.enableAudiFeatures ||
        applicationStore.data.enableVolkswagenFeatures,
      "float-launcher":
        applicationStore.data.homepageLauncherFloatOverCarousel === "LEFT" ||
        applicationStore.data.homepageLauncherFloatOverCarousel === "RIGHT",
      "float-launcher locked": applicationStore.data.homepageLauncherLockToPage,
      "alternate-background":
        applicationStore.data.enablePaddedProductTileSelection,
      "has-padded-images":
        applicationStore.data.enablePaddedProductTileSelection,
    });

    return (
      <div className={productSelectorClass}>
        <ul>{content}</ul>
      </div>
    );
  }

  checkForWhereToFloat = (floatChecker) => {
    if (floatChecker === null) {
      return {
        flag: true,
        imageClassName: " float-left",
        textClassName: " float-right",
        background: " apply-color",
        flipflop: false,
        firstRow: true,
      };
    } else if (typeof floatChecker === "object" && floatChecker.flag) {
      return {
        flag: !floatChecker.flag,
        imageClassName: " float-right",
        textClassName: " float-left",
        background: "",
        flipflop: true,
        firstRow: false,
      };
    } else if (typeof floatChecker === "object") {
      return {
        flag: !floatChecker.flag,
        imageClassName: " float-left",
        textClassName: " float-right",
        background: " apply-color",
        flipflop: false,
        firstRow: false,
      };
    }
  };

  onChange = () => {
    this.setState({
      productTypes: applicationStore.data.productTypes,
    });
  };

  openLauncherYearSelection = () => {
    scrollTop();
    this.props.setYearSelect(true);
  };

  openLauncherOnSelect = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "audi":
      case "volkswagen":
      case "volkswagen_2020":
        return true; //isNationalSite();
      default:
        return false;
    }
  };

  clickTires = (token) => {
    if (!this.openLauncherOnSelect()) {
      if (!applicationStore.data.loadSearchModalFromProductTypeSelection) {
        googleAnalyticsEnhancedTrackEvent(
          getTrackingEventCategory(),
          "Product Tile Selection - ProductSelected",
          getTrackingEventObject({ type: ProductTypes.TIRES })
        );
        applicationActions.productTypeSelected(ProductTypes.TIRES);
      } else {
        applicationActions.activateSearchFlag();
      }
    } else {
      this.openLauncherYearSelection();
    }
  };

  clickAlloyWheels = (token) => {
    if (!this.openLauncherOnSelect()) {
      if (!applicationStore.data.loadSearchModalFromProductTypeSelection) {
        googleAnalyticsEnhancedTrackEvent(
          getTrackingEventCategory(),
          "Product Tile Selection - ProductSelected",
          getTrackingEventObject({ type: ProductTypes.ALLOY_WHEELS })
        );
        applicationActions.productTypeSelected(ProductTypes.ALLOY_WHEELS);
      } else {
        applicationActions.activateSearchFlag();
      }
    } else {
      this.openLauncherYearSelection();
    }
  };

  clickAccessories = (token) => {
    if (!this.openLauncherOnSelect()) {
      if (!applicationStore.data.loadSearchModalFromProductTypeSelection) {
        googleAnalyticsEnhancedTrackEvent(
          getTrackingEventCategory(),
          "Product Tile Selection - ProductSelected",
          getTrackingEventObject({ type: ProductTypes.ACCESSORIES })
        );
        applicationActions.productTypeSelected(ProductTypes.ACCESSORIES);
      } else {
        applicationActions.activateSearchFlag();
      }
    } else {
      this.openLauncherYearSelection();
    }
  };

  clickPackages = (token) => {
    if (!this.openLauncherOnSelect()) {
      if (!applicationStore.data.loadSearchModalFromProductTypeSelection) {
        googleAnalyticsEnhancedTrackEvent(
          getTrackingEventCategory(),
          "Product Tile Selection - ProductSelected",
          getTrackingEventObject({ type: ProductTypes.PACKAGES })
        );
        applicationActions.productTypeSelected(ProductTypes.PACKAGES);
      } else {
        applicationActions.activateSearchFlag();
      }
    } else {
      this.openLauncherYearSelection();
    }
  };

  // AODA: Navigate on Space or Enter keys
  selectTires = (e) => {
    if (e.keyCode == 13 || e.keyCode == 32) this.clickTires();
  };

  selectAlloyWheels = (e) => {
    if (e.keyCode == 13 || e.keyCode == 32) this.clickAlloyWheels();
  };

  selectAccessories = (e) => {
    if (e.keyCode == 13 || e.keyCode == 32) this.clickAccessories();
  };

  selectPackages = (e) => {
    if (e.keyCode == 13 || e.keyCode == 32) this.clickPackages();
  };
}

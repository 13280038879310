import { createSlice } from "@reduxjs/toolkit";
import {
  onDealerSelectionChangeSearch,
  onDealerSelectionChangeSearchType,
  onListOfDealersLoaded,
} from "./DealerSelection/dealerHelper";

// loadListOfDealersForGroup: function() {
//     this.apiGet('/dealerGroup/getDealerGroupDealerList.php', {
//         dealerGroupId: applicationStore.data.groupAccountId,
//         isNational: applicationStore.data.nationalSite ? 1 : 0
//     }).then(function(response) {
//         this.dispatch(DispatcherActions.LIST_OF_DEALERS_LOADED, response);
//     }.bind(this));
// },

// export const selectedDealer = createAsyncThunk(
//     'appInfo/selectedDealer',
//     async(arg,{getState})=>{
//         return fetch(`${getApiBaseUrl()}/retailSiteWebService/dealer/getDealerAccountInfoByDealerId.php`,{
//             method:"POST",
//             body:JSON.stringify({

//             })
//         })
//     }
// )
const dealerSection = createSlice({
  name: "dealerSection",
  initialState: {
    dealerSearch: null,
    dealerSearchType: null,
    searchType: "dealer-city-search",
    dealerSearchIsEmpty: false,
    dealerDistance: null,
    dealerSearchReset: false,
    filterDealerPosition: null,
    closeDealerNameSearchList: true,
    closeInitalLocationModal: false,
    LeavingSiteWarningModal: false,
    unselectDealer: null,
    provinceSelected: null,
    mapCenterChanged: null,
    mapZoom: 8,
    mapBoundsChanged: null,
    mapWindowWidth: null,
    getDistance: null,
    mapSetInitialUserLocation: null,
    startingLocation: null,
    userInitialLocationModal: false,
    dealerSearchFlag: false,
    dealerSearchLocation: null,
    dealerSearchShowWarning: false,
    enableDealerNameList: false,
    visibleDealers: [],
    dealers: [],
  },
  reducers: {
    setMapSize: (state, { payload }) => {
      state.mapSize = payload;
    },
    setFilterDealerData: (state, { payload }) => {
      state.dealers = payload.filteredDealers;
      state.visibleDealers = payload.filteredDealers;
    },
    setDealerSelectionSearchTerm: (state, { payload }) => {
      let searchType = state.searchType;
      let selectionAddress = onDealerSelectionChangeSearch(payload, searchType);
      state.dealerSearch = selectionAddress.dealerSearch;
      // state.dealers = selectionAddress.filteredDealers;
    },
    setDealerSearch: (state, { payload }) => {
      state.dealerSearch = payload;
    },
    selectDealerSearchType: (state, { payload }) => {
      // this function is for toggling the search type between dealer name and postal code
      let selectedDealerSearchType = onDealerSelectionChangeSearchType(payload);
      state.dealerSearch = selectedDealerSearchType.dealerSearch;
      state.dealerSearchFlag = selectedDealerSearchType.dealerSearchFlag;
      state.searchShowWarning = selectedDealerSearchType.searchShowWarning;

      state.searchType = selectedDealerSearchType.searchType;
    },
    dealerSearchIsEmpty: (state, { payload }) => {
      state.dealerSearchIsEmpty = payload;
    },
    dealerSearchReset: (state, { payload }) => {
      state.dealerSearchReset = payload;
    },
    dealerDistance: (state, { payload }) => {
      state.dealerDistance = payload;
    },
    disableDealerNameList: (state, { payload }) => {
      state.enableDealerNameList = payload;
    },
    filterDealerPosition: (state, { payload }) => {
      //ref this back to dealerSelectionStore if any errors pop
      state.filterDealerPosition = payload;
    },
    closeDealerNameSearchList: (state, { payload }) => {
      state.closeDealerNameSearchList = payload;
    },
    closeInitalUserLocationModal: (state, { payload }) => {
      state.closeInitalLocationModal = payload;
    },
    leavingSiteWarningModal: (state, { payload }) => {
      state.leavingSiteWarningModal = payload;
    },
    unselectDealer: (state, { payload }) => {
      state.unselectDealer = payload;
    },
    provinceSelected: (state, { payload }) => {
      state.provinceSelected = payload;
    },
    mapCenterChanged: (state, { payload }) => {
      state.mapCenterChanged = payload;
    },
    mapZoomChanged: (state, { payload }) => {
      state.mapZoom = payload;
    },
    mapBoundsChanged: (state, { payload }) => {
      state.mapBoundsChanged = payload;
    },
    mapUpdateWindowWidth: (state, { payload }) => {
      state.mapWindowWidth = payload;
    },
    getDistance: (state, { payload }) => {
      let getRadian = function (x) {
        return (x * Math.PI) / 180;
      };
      let R = 6378137;
      let dLat = getRadian(payload.p2.lat - payload.p1.lat);
      let dLong = getRadian(payload.p2.lng - payload.p1.lng);
      let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(getRadian(payload.p1.lat)) *
          Math.cos(getRadian(payload.p2.lat)) *
          Math.sin(dLong / 2) *
          Math.sin(dLong / 2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      let d = R * c;
      state.getDistance = parseFloat(d / 1000).toFixed(0);
    },
    mapInitialLocation: (state, { payload }) => {
      state.mapSetInitialUserLocation = payload;
    },
    onDealerLoaded: (state, { payload }) => {
      let dealer = onListOfDealersLoaded(payload);
      state.dealers.push(...dealer.visibleDealers);
      state.visibleDealers.push(...dealer.visibleDealers);
    },
    toggleLocationModal: (state, { payload }) => {
      state.userInitialLocationModal = payload;
    },
  },
  extraReducers: {},
});

export const {
  setDealerSelectionSearchTerm,
  selectDealerSearchType,
  dealerSearchIsEmpty,
  dealerSearchReset,
  dealerDistance,
  filterDealerPosition,
  closeDealerNameSearchList,
  closeInitalUserLocationModal,
  leavingSiteWarningModal,
  unselectDealer,
  provinceSelected,
  mapCenterChanged,
  mapZoomChanged,
  mapBoundsChanged,
  mapUpdateWindowWidth,
  getDistance,
  onDealerLoaded,
  toggleLocationModal,
  setMapSize,
  setDealerSearch,
  setFilterDealerData,
} = dealerSection.actions;
export default dealerSection.reducer;

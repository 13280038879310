module.exports = {
  away: 'Away',
  login: 'Login',
  logout: 'Logout',
  loginExpired: 'Your login has expired, please relogin to continue. Thank you.',
  cmsLogin: 'CMS',
  frontAbbrv: 'F: ',
  rearAbbrv: 'R: ',
  bodyColor: 'Body Color',
  home: 'Home',
  title: '',
  searchText: 'Search',
  forTires: 'for Tires',
  tireAndWheel: 'Tire & Wheel',
  changeDealer: 'Change Dealer',
  update: 'Update',
  page: 'Page',
  show: 'Show',
  filter: 'Filter',
  filters: 'Filters',
  change: 'Change',
  changeVehicle: 'Change Vehicle',
  clickHere: 'Click here',
  tireOptions: 'Tire Options',
  wheelOptions: 'Wheel Options',
  accessoryOptions: 'Accessory Options',
  packageOptions: 'Package Options',
  contactDealer: 'Contact Dealer',
  contactRetailer: 'Contact Retailer',
  contactUs: 'Contact Us',
  pricingAnalysisText: 'Tire Pricing Analysis',
  pricingAnalysisByTiresize: 'Price Analysis',
  pricingIncentive: 'Incentive Pricing',
  financeText: 'Paybright',
  bookingText: 'CMS',
  quoteReportText: 'Quotes & Orders',
  leadReportText: 'Leads',
  orderReportText: 'Ecommerce',
  dealerSelection: 'Dealer Selection',
  provinceSelection: 'Select New Province',
  provinceSelectionWarning:'Please note that by selecting a new province that you will be redirected back to the beginning and all wishlist information will be set to default.',
  successful: 'Success!',
  failure: 'Failure!',
  wishToContinue:'Do you wish to Continue',
  startNewQuote:'Start New Quote',
	notApplicable: 'N/A',
	ourBrands: 'Our Brands',
	tiresByBrands: 'Tires By Brand',
	tiresByType: 'Tires By Type',
	tiresBySize: 'Tires By Size',
  loadingPage:'Please wait while your page loads.',
	loadingText: 'Retrieving Data',
	loading: 'Loading Page',
	copyrightPrefix: 'Copyright @',
	leavingSiteDisclaimer: 'You may be about to leave a secure connection.',
  homepageTitleText: '',
  homepageText: '',
  ourLocation: 'Our Location',
  selectModel: 'Select Model',
  viewTiresize: 'View Tire Sizes',
  tiresizeList: 'Tire Size List',
  tireBrand: 'Tire Brand',
  brand: 'Brand',
  yourPrice: 'Your Price',
  maxPrice: 'Max Price',
  minPrice: 'Min Price',
  average: 'Average Price',
  quantityBooked: 'Quantity Booked',
  quantitySold: 'Quantity Sold',
  lastBooked: 'Last Booked',
  minHigher: '% higher than minimum price',
  minLower: '% lower than minimum price',
  showAllBrands: 'Show All Brands',
  showAllTiresizes: 'Show All Tiresizes',
  openCMS: 'Login to update pricing',
  pricingNotAvailable: 'Call Dealer For Details',
  languageSwitch: 'Français',
  languageSwitchShort: 'Fr',
  dealerCost: 'Cost',
  dealerBooking: 'Booking Cost',
  bronze: 'Bronze',
  silver: 'Silver',
  gold: 'Gold',
  searchByName: 'Search by name or part number',
  nonOEWarrning: 'You have selected showing non original equipment.',
  reducedHomepageTitle: '',
  reducedHomepageText: '',
  reducedHomepageButton: '',
  promotions: 'Promotions',
  youHaveSaved: 'You have saved',
  through: 'through',
  viewMore: 'View More',
  viewLess: 'View Less',
  noPartsError: 'The item searched is unavailable.',
  searchNow: 'Search Now',
  buyNow: 'Buy Now',
  payMonthly: 'Pay Monthly',
  payInFull: 'Pay in Full',
  zeroInterest: '6 months 0% interest',
  perMonth: ' / mo',
  financePurchase: 'Finance Purchase',
  financeWithTaxes: 'Finance you\'re entire purchase with taxes and installation',
  financeApproval: 'Apply and get approved instantly use the secure form to the right',
  financingAvailable: 'Financing Available',
  financeAvailable: '6 Month Financing Available',
  benefits: 'Benefits',
  taxesNotIncluded: '*taxes & installation not included',
  paymentFrequency: 'Low Bi-Weekly / Monthly Payments',
  sixMonthsNoInterest: '0% Interest if Paid Back Within Six Months',
  skipPaymentOption: 'Skip a Payment Option',
  noChargeRepayment: 'No Charge for Early Repayment',
  call: 'Call',
  belowMarket: 'Below Market Minimum Pricing of',
  marketMinimum: 'Market Minimum Pricing Applied',
  downloadAccessoryGuide: 'Download Accessory Guide',
  downloadPDF: 'Download PDF',
  createAccount: 'Create Account',
  createAccountForPricing: 'Click to Register',
  listPrice: 'List Price',
  costPrice: 'Cost Price',
  tierOne: 'Tier 1',
  tierTwo: 'Tier 2',
  tierThree: 'Tier 3',
  tierFour: 'Tier 4',
  'common': {
    cm: 'cm',
    viewTires: 'Find Tires',
    viewWheels: 'Find Wheels',
    viewAccessories: 'Find Accessories',
    viewPackages: 'Find Packages',
    shopTires: 'Shop Tires',
    shopWheels: 'Shop Wheels',
    shopAccessories: 'Shop Accessories',
    shopPackages: 'Shop Packages',
    viewCollections: 'Find Collections',
    welcomeMessage: 'Welcome to the ApprovedTire and Wheel Center',
    featuredItems: "Featured Items",
    yourResults: "Your Results",
    getStarted: 'Get started',
    customizeYourRide: 'Customize your ride',
    select: 'Select',
    unselect: 'Unselect',
    mandatoryField: '(mandatory field *)',
    yes: 'Yes',
    no: 'No',
    featured: 'Featured',
    chassisCode: 'Chassis Code',
    tiresizeSearch: 'Tire Size',
    partnumberSearch: 'Part #',
    vehicleSearch: 'Vehicle Search',
    enter: 'Enter',
    dealerSelectionPlaceholder: 'Search by city, province or postal code',
    myCart: 'My Quote',
    myWishlist: 'My Quote',
    itemAddedToCart: 'An item has been added to',
    item: 'item,',
    items: 'items,',
    selectByProvinceTitle: '',
    vehicleSelectionModalTitle: 'Your Current Selection',
    continue: 'Continue',
    cancel: 'Cancel',
    close: 'Close',
    closeMenu: 'Close Menu',
    showMore: 'Show More',
    showLess: 'Show Less',
    pleaseWait: 'Please Wait',
    searchPlaceholder: 'enter search...',
    mobileIconPanelTitle: 'User Options',
    externalLinkWarning: 'Link to external website will open in new tab.',
    startNewQuoteTag: 'Do you want to start a new shopping session?',
    startNewQuoteFromHomeTag: 'You will be redirected to the Homepage to start a new shopping session.',
    loginSuccessful: 'You have logged in successfully.',
    logoutSuccessful: 'You have logged out successfully.',
    otherActions: 'Other Actions',
    now: 'Now',
    visualizerDisclaimer: 'Vehicle may not appear exactly as shown',
    enterLocationOrRetailer: 'Enter a location or retailer',
    notSureAboutYourTrim: 'Not sure about your vehicle trim?',
    notSureAboutYourTrimButtonText: 'Skip this step',
    contactDealer: 'Contact Dealer',
    foxyCartFailed: 'There was an error with your checkout. Please try again.',
    outdatedApp: 'There is an updated version of the application available, please click the reload button below.',
    reload: 'Reload'
  },
  'consumer': {
    title: 'Tire Locator',
    landingtitle: 'Find the Cheapest Tires in your Area',
    purchaseModalTitle: 'To get these tires installed fill in your information and the installer will contact you back.',
    purchaseModalSuccess: 'The installer will contact you back.',
    search: 'Type in your Tire Size - ex. 195/65R15',
    subtitleLocations: 'We search over 1400 locations to find all the cheapest tires in your area',
    subtitleHelp: 'How it Works',
    subtitlePointTitle1: 'Input your tire size and postal code',
    subtitlePointTitle2: 'View lowest priced tires closest to you',
    subtitlePointTitle3: 'Reserve and book appointment',
    subtitlePointBody1: 'Not sure how to find your tire size?',
    subtitlePointBody2: 'We check prices, availability and distance to provide you one screen to see the best tire prices and how close the installation location is to you.',
    subtitlePointBody3: 'Once you select a tire you will recieve an appointment confirmation with installation address and  a call or text to confirm appointment time.',
    cm: 'cm',
    viewTires: 'Find Tires',
    viewWheels: 'Find Wheels',
    viewAccessories: 'Find Accessories',
    viewPackages: 'Find Packages',
    viewCollections: 'Find Collections',
    welcomeMessage: 'Welcome to the ApprovedTire and Wheel Center',
	  featuredItems: "Featured Items",
	  yourResults: "Your Results",
    getStarted: 'Get started',
    customizeYourRide: 'Customize your ride',
    select: 'Select',
    unselect: 'Unselect',
    mandatoryField: '(mandatory field *)',
    yes: 'Yes',
    no: 'No',
    featured: 'Featured',
    chassisCode: 'Chassis Code',
    tiresizeSearch: 'Tire Size',
    partnumberSearch: 'Part #',
    vehicleSearch: 'Vehicle Search',
    enter: 'Enter',
    dealerSelectionPlaceholder: 'Search by city, province or postal code',
    myCart: 'My Quote',
    myWishlist: 'My Quote',
    itemAddedToCart: 'An item has been added to',
    item: 'item,',
    items: 'items,',
    selectByProvinceTitle: '',
    vehicleSelectionModalTitle: 'Your Current Selection',
    continue: 'Continue',
    cancel: 'Cancel',
    close: 'Close',
    showMore: 'Show More',
    showLess: 'Show Less',
    pleaseWait: 'Please Wait',
    searchPlaceholder: 'enter search...',
    mobileIconPanelTitle: 'User Options',
    externalLinkWarning: 'Link to external website will open in new tab.',
    startNewQuoteTag: 'Do you want to start a new shopping session?',
    startNewQuoteFromHomeTag: 'You will be redirected to the Homepage to start a new shopping session.',
	  loginSuccessful: 'You have logged in successfully.',
	  logoutSuccessful: 'You have logged out successfully.',
	  otherActions: 'Other Actions',
	  now: 'Now',
	  visualizerDisclaimer: 'Vehicle may not appear exactly as shown',
	  enterLocationOrRetailer: 'Enter a location or retailer',
	  notSureAboutYourTrim: 'Not sure about your vehicle trim?',
	  notSureAboutYourTrimButtonText: 'Skip this step',
    contactDealer: 'Contact Dealer',
    foxyCartFailed: 'There was an error with your checkout. Please try again.',
      outdatedApp: 'There is an updated version of the application available, please click the reload button below.',
      reload: 'Reload'
  },
  'logo': {
    text: 'ApprovedTire and Wheel Center'
  },
  'validation': {
    notEmpty: 'This field is required',
    email: 'Please enter valid e-mail address'
  },
  'loginDialog': {
    login: 'Login',
    signIn: 'Sign In',
    email: 'E-mail address',
    password: 'Password',
    loginHasFailed: 'Incorrect username or password',
    rememberMe: 'Remember me',
    rememberMeShort: 'Remember me'
  },
  'breadcrumbs': {
    back: 'Back',
    year: 'Select Your Year',
    make: 'Select Your Make',
    category: 'Select Your Category',
    model: 'Select Your Model',
    trim: 'Select Your Trim',
    tireSize: 'Select Your Tire Size',
    dealerSelection: 'Select Your Dealer',
    collectionCategorySelection: 'Select Category',
    collectionList: 'View Collections',
    tiresProductList: 'View Tires',
    wheelsProductList: 'View Wheels',
    accessoriesProductList: 'View Accessories',
    packagesProductList: 'View Packages',
    productList: 'Product List',
    viewShoppingCart: 'Review Shopping Cart',
    staticHome: 'Home',
    searchResult: 'Search Result'
  },
  'cart': {
    close: 'Close',
    viewAllYourItems: 'View all your items',
    total: 'Total',
    getQuote: 'Get Quote',
    getCartReview: 'Review Quote',
    generateQuote: 'Generate Quote',
    getCheckout: 'Proceed to Checkout',
    checkout: 'Checkout',
    hasBeenAddedToTheBasket: 'has been added to the basket.',
    myquotes: 'My Quotes',
    mywishlist: 'My Quotes',
    cartBarTitlePrefix: 'My Quote',
    cartBarTitleSuffix1: 'Item',
    cartBarTitleSuffix2: 'Items',
    continueShopping: 'Continue Shopping',
    orderNtd: 'Order Now',
    addToOrderNtd: 'Add to Order',
    purchaseNtd: 'Add to Cart',
    checkoutNtd: 'Checkout',
    orderNtdSuffix: 'Tires in this Order',
    confirmAvailability: 'Item requiring availability confirmation',
    moreDetails: 'More Details',
    or: 'OR',
    'modal': {
      quantityLimitWarning: 'We\'re sorry. You\'ve requested to purchase more than the allotted quantity of the same item at one time. For larger purchases please contact your dealer.'
    }
  },
  'quote': {
    quote: 'Quote',
    quoteFor: 'Quote for',
    addToQuote: 'Add',
    remove: 'Remove',
    subtotal: 'Subtotal',
    tax: 'Tax',
    nonTaxable: 'Non-Taxable',
    total: 'Total',
    emailQuote: 'E-mail quote',
    printQuote: 'Print quote',
    saveQuoteText: 'Save quote',
    saveQuote: 'Request an appointment, email or print quote',
    emailHasBeenSent: 'Quote has been sent to the provided e-mail address.',
    appointmentSent: 'Appointment has been successfully requested.',
    controlsInfo: 'You can bookmark this quote to view it again later. You can also e-mail it to anybody or print it.',
    emailFieldLabel: 'E-mail',
    nameFieldLabel: 'Name',
    noteFieldLabel: 'Notes',
    userNoteLabel: 'Quote Notes',
    saveNotes: 'Save Notes',
    savedThroughOnePromotion: 'You have saved {0} through our {1} Promotion',
    savedThroughMultiplePromotions: 'You have saved {0} through {1} Promotions',
    included: 'Included',
    note: 'Complete this form to receive this one-time only communication.',
    requestAppointment: 'Request Appointment',
    selectAppointmentTime: 'Please Select Appointment Time',
    requiredField: 'This field is required',
    requiredFields: '*These fields are required',
    contactInformation: 'Contact Information',
    date: 'Date',
    time: 'Time',
    youSaved: 'You Saved',
    personalizedQuote: 'Your Personalized Quote',
    contactPreference: 'Contact Preference'
  },
  'quoteModal': {
    emailSent: 'Quote has been sent to the provided e-mail address.',
    appointmentSent: 'Appointment has been successfully requested.',
    quoteSaved: 'Quote has been successfully saved.',
    quoteSaveFailed: 'Quote has failed to save.'
  },
  'dealerSelect': {
    day: 'Day',
    open: 'Open',
    close: 'Close'
  },
  'shoppingCartView': {
    shoppingCartView: 'Cart View',
    shoppingCartViewFor: 'Shopping Cart',
    addToShoppingCartView: 'Add',
    remove: 'Remove',
    subtotal: 'Subtotal',
    tax: 'Tax ([]%)',
    total: 'Total',
    emailShoppingCartView: 'E-mail quote',
    printShoppingCartView: 'Print quote',
    saveShoppingCartView: 'Save this cart view',
    emailHasBeenSent: 'Cart View has been sent to the provided e-mail address.',
    controlsInfo: 'You can bookmark this quote to view it again later. You can also e-mail it to anybody or print it.',
    emailFieldLabel: 'E-mail',
    nameFieldLabel: 'Name',
    savedThroughOnePromotion: 'You have saved {0} through our {1} Promotion',
    savedThroughMultiplePromotions: 'You have saved {0} through {1} Promotions',
    included: 'Included',
    requestAppointment: 'Request Appointment',
    day: 'Day',
    partOfDay: 'Part of a day',
    note: 'By selecting e-mail quote or request appointment you will receive a one-time only communication with information about this quote.',
    edit: 'Edit',
    itemQuantity: 'Quantity',
    productTotal: 'Product Total',
    inStock: 'in stock',
    outStock: 'out of stock',
    priceMatch: 'Price Match'
  },
  'shoppingCartWarnings': {
    ITEM_WARNING_TIRE_NOT_ORIGINAL_SIZE: 'Tire is not original size. ',
    ITEM_WARNING_TIRE_NOT_OE: 'Tire is not an Original Equipment Tire. It is recommended to always choose the original equipment tire when possible. ',
    ITEM_WARNING_TIRE_NOT_RUNFLAT: 'Please be advised this tire is not a runflat tire and this vehicle requires a runflat tire. It is recommended to choose an available runflat tire for this vehicle. ',
    ITEM_WARNING_TIRE_WHEEL_MISMATCH: 'Please be advised the quote you are creating includes incompatible tires and wheels. If necessary, contact the dealer for more information. ',
    ITEM_WARNING_TIRE_WHEEL_IDEAL_SIZE_MISMATCH: 'Please be advised the quote you are creating includes tires that do not meet the wheels {}. Please change your selection or contact the dealer for additional information. '
  },
  'shoppingCartWarningsModal': {
    continue: 'Continue',
    notifications: 'Notifications',
    notification: 'Notification'
  },
  'productTypes': {
    tires: 'Tires',
    tiresAlt: 'Tires',
    searchTires: 'Search Tires',
    wheels: 'Wheels',
    wheelsAlt: 'Wheels',
    searchWheels: 'Search Wheels',
    accessories: 'Accessories',
    accessoriesAlt: 'Accessories',
    searchAccessories: 'Search Accessories',
    packages: 'Packages',
    packagesAlt: 'Packages',
    searchPackages: 'Search Packages',
	  tireSelectText: '',
	  wheelSelectText: '',
	  accessorySelectText: '',
	  packageSelectText: '',
    packagesTab: 'Packages',
    packageDisclaimer: 'Please note not all dealerships have pre-configured wheel and tire packages',
    collections: 'Collections'
  },
  'product': {
	  accessory: 'Accessory',
	  accessoryCategories: 'Accessory categories',
	  accessoryTag: '',
	  addToQuote: 'Add to quote',
	  addToQuotePrefix: '',
	  addToQuoteSuffix: '',
	  addToWishlist: 'Add to quote',
	  addToWishlistPrefix: '',
	  addToWishlistSuffix: '',
    addAllToWishlist: 'Add all to quote',
    itemSearch: 'Item Search',
    byName: 'by name or part number',
	  pricingLastUpdated: 'Ntl Account pricing last updated - []',
	  alternateSizes: 'Alternate Tire Size(s):',
	  alternateSizesDesc: 'Best option for special sizes that may have come with your vehicle or you want to upsize your wheels',
	  appointment: 'Appointment',
	  approvedTire: 'Original Equipment',
	  approvedTires: 'Original Equipment',
	  nonApprovedTires: 'Non-Original Equipment',
	  best: 'Best',
	  better: 'Better',
	  carColour: 'Colour of your vehicle',
	  categories: 'Category',
	  collection: 'Collection',
	  collectionCategories: 'Collection categories',
	  collectionTag: '',
	  colourSelectionTitle: 'Colour',
	  comingSoon: 'Recommendations coming soon!',
	  contactDealerForPricing: 'Contact dealer for pricing',
	  createPackage: 'Create Package',
	  dealerPrice: 'Price',
	  dealerPriceTotal: 'Your Total',
	  dealerTotal: 'Dealer Total',
	  description: 'Description',
	  restrictions: 'Restrictions',
	  details: 'Details',
	  emptyAccessoryList: 'No accessories currently available',
	  emptyCollectionsList: 'No collections currently available',
	  emptyPackagesList: 'Please contact your dealership for packages specific to your vehicle.',
	  emptyTireList: 'No tires currently available',
	  emptyWheelList: 'No wheels currently available',
	  front: 'Front',
	  frontAbbrv: 'F',
	  frontAndRearWheel: 'Front and Rear Wheel',
	  frontTire: 'Front Tire',
	  frontWheel: 'Front Wheel',
	  goBack: 'Go Back',
	  good: 'Good',
	  installation: 'Install',
    installationLong: 'Installation',
	  installationExtra: '* Installation Extra',
	  installationIncluded: 'Installation Included *',
	  inStock: 'In Stock',
	  itemAlert: '',
	  kilometers: 'Km',
	  loadAndSpeedRating: 'Load / Speed (minimum)',
	  loadRating: 'Load Rating',
	  loadSpeed: 'Load / Speed',
	  loadSpeedPly: 'Load / Speed / Ply',
	  loadSpeedRating: 'Load / Speed Rating',
	  manufacturer: 'Manufacturer',
	  manufacturerWebsite: 'View manufacturer\'s website',
	  manufacturerWebsiteLink: 'click here',
	  mileageWarranty: 'Mileage Warranty',
	  miles: 'mi.',
	  missingLoadAndSpeed: 'Please confirm load rating and speed rating for your vehicle tire size before purchase.',
	  missingLoadRating: 'Loading Rating Data Unavailable',
	  missingSpeedIndex: 'Speed Index Data Unavailable',
	  moreDetails: 'More Details',
	  morePossibleTireSizes: 'Always make sure to verify the tire size you select below with the tire size listed on your vehicle to ensure accurate vehicle fitment',
	  moreProducts: 'Show more products',
	  msrp: 'MSRP',
	  nationalPartNumber: 'Nat. Part #',
	  noTiresResultsAvailable: 'Sorry, there are no tires available for this vehicle. Please contact dealer for more information.',
	  noTiresResultsAvailableForChassis: 'Sorry, there are no tires available for this chassis. Please contact dealer for more information.',
	  noTiresResultsAvailableForPartNumber: 'Sorry, there are no tires available for this part number. Please contact dealer for more information.',
	  noTiresResultsAvailableForTireSize: 'Sorry, there are no tires available for this tire size. Please contact dealer for more information.',
	  noWheelResultsAvailable: 'Sorry, there are no wheels available for this vehicle. Please contact dealer for more information.',
	  noAccessoryResultsAvailable: 'Sorry, there are no accessories available for this vehicle. Please contact dealer for more information.',
	  noPackageResultsAvailable: 'Sorry, there are no packages available for this vehicle. Please contact dealer for more information.',
	  optionalEqSizes: 'Select an Optional Size(s)',
	  optionalEqSizesDesc: 'The optional sizes below are found to fit a large variety of vehicles when using the correct wheels',
	  options: 'Options',
	  originalEqSizes: 'Original Equipment Tire Size(s):',
	  originalEqSizesDesc: 'Best option when you want to replace the tires that came with your vehicle',
	  outOfStock: 'Out of Stock',
	  package: 'Package',
	  packageItems: 'Package Items',
	  packagePartNumber: 'Package Part Number',
	  packageTag: '',
	  partDetails: 'Part Details',
	  partNumber: 'Part #',
	  perAccessory: 'per Accessory',
	  perAccessoryLwr: 'per accessory',
	  perFrontTire: 'per Front Tire',
	  perFrontWheel: 'per Front Wheel',
	  perItem: 'per Item',
	  perItemLwr: 'per item',
	  perPackage: 'per Package',
	  perPackageLwr: 'per package',
	  perRearTire: 'per Rear Tire',
	  perRearWheel: 'per Rear Wheel',
	  perTire: 'per Tire',
	  perTireLwr: 'per tire',
	  perWheel: 'per Wheel',
	  perWheelLwr: 'per wheel',
	  plyRatingFilter: 'Ply Rating / LT Rating',
	  tagFilter: 'Category',
	  price: 'Price',
	  pricesStartingAt: 'Prices starting at',
	  pricing: 'Pricing',
	  pricingNote: 'Pricing Note',
	  qty: 'Qty',
	  quantity: 'Quantity',
	  rear: 'Rear',
	  rearAbbrv: 'R',
	  rearTire: 'Rear Tire',
	  rearWheel: 'Rear Wheel',
	  recommendedFor: 'Recommended For',
	  relatedItems: 'Related Items',
	  removeFromQuote: 'Remove from quote',
	  removeFromWishlist: 'Remove from quote',
	  removeFromWishlistPrefix: '',
	  removeFromWishlistSuffix: '',
	  requestAppointment: 'Contact dealer',
	  requestAppointment_alt: 'Contact dealer',
	  requestAppointment_sent: 'Message Sent',
	  requiredItems: 'Required Items',
	  requiresInstallation: 'Requires Installation',
	  requiresOtherItems: 'Requires other items',
	  retailPrice: 'Price',
	  rimDiameter: 'Rim Diameter',
	  runFlat: 'Run flat tire',
	  runFlatText: 'Run Flat',
	  season: 'Season',
	  selectAccessoryCategory: 'All Categories',
	  showAll: 'Show All',
	  showNonRunFlat: 'Show Non Run Flat',
	  showOnlyApproved: 'Show Only Original Equipment',
	  showRunFlat: 'Show Run Flat',
	  speedRating: 'Speed Index',
	  staggeredFittment: 'Staggered Fitment',
	  tire: 'Tire',
	  tireBrands: 'Brands',
	  tirePricing: 'Pricing',
	  packagePricing: 'Package Pricing',
	  tireNotOriginalSizeWarning: 'You have requested to upsize or downsize your tires from the original vehicle equipment. The corresponding wheels must also be purchased.',
	  tireSize: 'Tire Size',
	  tireSizeAndSeason: 'Tire Size and Season',
	  tireTag: '',
	  total: 'Total',
	  totalSavings: 'Total Savings',
	  viewOnVehicle: 'View on Vehicle',
	  warranty: 'Warranty',
	  warrantyMissing: 'Please contact dealer for warranty information.',
	  wheel: 'Wheel',
	  wheelAxlePartner: 'Axle Partner',
	  wheelColour: 'Colour',
	  wheelComment: 'Notes',
	  wheelDiameter: 'Wheel Diameter',
	  wheelFinish: 'Wheel Finish',
	  wheelNotOriginalSizeWarning: 'You have requested to upsize or downsize your wheels from the original vehicle equipment. The corresponding tire size must also be purchased.',
	  wheelOffset: 'Offset',
	  wheelTag: '',
	  wheelTiresize: 'Wheel Tire Size',
	  wheelWidth: 'Width',
	  wheelNote: 'Note',
	  winterSizes: 'Winter Tire Size(s):',
	  winterSizesDesc: 'Best option for winter tires for your vehicle',
	  yourTotal: 'Your Total',
	  youSave: 'You Save',
    listView: 'List View',
    gridView: 'Grid View',
    name: 'Name',
    installationManual: 'Installation Manual',
    itemInstalled: 'Item Installed',
    individualItem: 'Individual Item',
    grandTotal: 'Grand Total',
    loginForPricing: 'Sign Up for Pricing',
	  'pricingNotes': {
		  title: 'Pricing Notes',
		  description: 'Environmental fees may apply. Installation fees are priced separately. Additional charges may apply.'
	  },
	  'wheelFinishTypes': {
		  alloy: 'Alloy',
		  steel: 'Steel'
	  },
    'filters': {
      filterResults: 'Showing Results For',
      selectTiresize: 'Select Tire Size',
      hasWheels: 'Has Wheels',
      'brand': {
        noSelection: 'Select Brand',
        allSelected: 'All Brands',
        otherSelected: 'Brands',
        selectAll: 'Select All',
        deselectAll: 'Deselect All'
      },
      'season': {
        noSelection: 'Select Season',
        allSeason: 'All Season',
        winter: 'Winter',
        summer: 'Summer',
        allWeather: 'All Weather',
      },
      'loadRating': {
        noSelection: 'Select Load Rating'
      },
      'tireSize': {
        noSelection: 'Select Tire Size'
      },
      'speedRating': {
        noSelection: 'Select Speed Rating'
      },
      'plyRating': {
        noSelection: 'Select Ply Rating'
      },
      'diameter': {
        noSelection: 'Select Diameter',
        original: 'Original size:',
        inchDiameter: 'inch Diameter'
      }
    }
  },
  'stocks': {
    loadingStocks: 'Loading stock information',
    refresh: 'Refresh',
    clickToOrder: 'Click to Order',
    frontTires: 'Front Tire(s)',
    rearTires: 'Rear Tire(s)',
    tires: 'Tires',
    tire: 'Tires',
    tiresLwrCase: 'tires',
    tireLwrCase: 'tire',
    tireSingular: 'Tire',
    tirePrefix: 'Tire(s)',
    wheels: 'Wheels',
    wheel: 'Wheels',
	  wheelsLwrCase: 'wheels',
	  wheelLwrCase: 'wheel',
    wheelSingular: 'Wheel',
    wheelPrefix: 'Wheel(s)',
    accessories: 'Accessories',
    accessory: 'Accessories',
	  accessoriesLwrCase: 'accessories',
	  accessoryLwrCase: 'accessory',
    accessorySingular: 'Accessory',
    collections: 'Collections',
    collection: 'Collections',
    collectionSingular: 'Collection',
    packages: 'Packages',
    packagesSingular: 'Package',
    availableFor: 'for',
    location: 'location',
    locations: 'locations',
    checkAvailabilityFor: 'Check Availability for',
    checkAvailability: 'Check Availability',
    noStockAvailable: 'No stock at other locations',
    availableAtDealer: 'In house',
    option: 'Expand/Collapse',
    updated: 'Updated {} ago',
    updatedShort: '{} ago',
    availability: 'Availability',
    atDealership: '- Dealership',
    atOtherLocations: '- Warehouse(s)',
    checkNationalStock: 'National Stock',
    checkNationalStockFront: 'F',
    checkNationalStockRear: 'R',
    checkNationalPricing: 'National Price',
    checkNationalPricingFront: 'F',
    checkNationalPricingRear: 'R',
	  updatedMoreThanOneDay: 'Updated more than 1 day ago'
  },
  'tireSize': {
    up: 'Up Size',
    original: 'Original Size',
    down: 'Down Size',
    optional: 'Optional Size',
    alternate: 'Alternate Size',
    seasonal: 'Seasonal Size',
    temporary: 'Temporary Size'
  },
  'disabledSite': {
    title: 'Site offline'
  },
  'image': {
    placeholder: 'Image is not available'
  },
  'errorPage': {
    internalServerError: 'There was an error on the page',
    notFound: 'The page you are requesting does not exist'
  },
  'appointmentForm': {
    name: 'Name',
    email: 'E-mail',
    phone: 'Phone',
    sent: 'Your message has been successfully sent. Thank you.',
    requestMoreInformation: 'Request more information',
    preferredAppointmentTime: 'Preferred Appointment Time',
    preferredAppointmentDate: 'Preferred Appointment Date',
    preferredContactMethod: 'Preferred Contact Method',
    preferredContactTime: 'Preferred Contact Time',
    speakToPartsSpecialist: 'To speak to a parts specialist:',
    requestAppointmentByPhone: 'To request an appointment by phone:',
    requestAppointmentByEmailPart1: 'To request an appointment by email',
    requestAppointmentByEmailPart2: ', please complete the information below.',
    startOver: 'Start Over',
    printWishlist: 'Print Quote',
    selectedTime: 'Selected Time',
    selectTime: 'Select Time',
    selectDate: 'Select Date',
    selectedDate: 'Selected Date',
    noDateSelected: 'No Date Selected',
    pleaseLeaveContactInfo: 'Please leave your contact information here so that we can get back to you soon.',
    pleaseContactVia: 'Please contact me via',
    optionalMessage: 'Optional Message'
  },
  'timing': {
    'minute': {
      singular: '{} minute',
      plural: '{} minutes'
    },
    'hour': {
      singular: '{} hour ',
      plural: '{} hours '
    },
    'day': {
      singular: '{} day ',
      plural: '{} days '
    }
  },
  'search': {
    'found': {
      singular: 'Found {0} result for {1}',
      plural: 'Found {0} results for {1}'
    },
    search: 'Search',
    searchBy: 'Search By',
    selectDealer: 'Select dealer for pricing',
    selectDealerShort: 'Select dealer for pricing',
    postalOrCity: 'Postal Code or City',
    zipOrCity: 'Zip Code or City',
    dealer: 'Dealer',
    getDirections: 'Get Directions',
    pricingAnalysisSearch: 'Input tiresize to see competitor pricing',
    tiresizeSearch: 'Search by Tire Size',
    partNumberSearch: 'Search by Part Number',
    vehicleModelSearch: 'Search by Vehicle',
    vehicleSimpleSearch: 'Search by Vehicle',
    chassisSearch: 'Search by Chassis',
    newSearch: 'New Search',
    searchFor: 'Search for',
    placeholderText: 'Tire size search',
    placeholderSearchTireSize: 'tire size search',
    searchTireSizeTag: 'Tire Size Search',
    placeholderSearchPartNumber: 'Part number search',
    searchPartNumberTag: 'Part Number Search',
    placeholderSearchChassis: 'model code search',
    searchChassisTag: 'Model Code Search',
	  searchIsEmpty: 'Sorry, search has returned no products.',
    tireSizePlaceholder: 'e.g. 195/65R15'
  },
  'dealerFilter': {
    titlePrefix: 'Search for your nearest',
    titleSuffix: 'retailer',
    chooseProvince: 'Choose Your Province:', // it's important to add the colon into here
    selectProvince: 'Select A Province',
    selectState: 'Select A State',
    enterPostal: 'Enter Your Postal Code', // it's important to add the colon into here
    enterDealerName: 'Enter Dealership Name:', // it's important to add the colon into here
    enterDealerCity: 'Enter Dealership City:', // it's important to add the colon into here
    search: 'Search',
    reset: 'Reset',
    pleaseSelectProvince: 'Please Select Province',
    pleaseEnterLocation: 'Store Locator',
    searchResultsEmpty: 'No Results Found',
    searchResultsWarning: 'Sorry, your search was unable to find any results. Results are limited to {}.',
    withXKm: 'Within [] Km',
    withAnyKm: 'Any Distance'
  },
  'wheelStage': {
    slideshow: 'Slide Show',
    selectAll: 'Select All',
    deselectAll: 'Deselect All'
  },
  'quoteReport': {
    quoteReport: 'Quote Search',
    orderReport: 'Order Search',
    leadReport: 'Lead Search',
    remainderDue: 'Remainder Due',
    ecommerceReport: 'Ecommerce Search',
    ecommerceReportUnscheduled: 'Unscheduled Ecommerce',
    ecommerceReportScheduled: 'Scheduled Ecommerce',
    ecommerceReportCompleted: 'Completed Ecommerce',
    ecommerceReportAll: 'All Ecommerce',
    missingOrAccessNotAllowMessage: 'Either you do not have any quotes to see or you do not have permission to see this dealers quotes, sorry for any inconvenience.',
    showing: 'Showing',
    quotes: 'Quotes',
    orders: 'Orders',
    sales: 'Sales',
    quotesSince: 'Quotes Since',
    requestedDate: 'Requested Date',
    options: 'Options',
    sort: 'Sort',
    filterDate: 'Filter Date',
    byMostRecent: 'By Most Recent',
    byLeastRecent: 'By Least Recent',
    byTotalPriceAscending: 'By Total Price Ascending',
    byTotalPriceDescending: 'By Total Price Descending',
    byCreatedAscending: 'By Created Ascending',
    byCreatedDescending: 'By Created Descending',
    byCustomerNameAscending: 'By Customer Name Ascending',
    byCustomerNameDescending: 'By Customer Name Descending',
    past: 'Past',
    months: 'months',
    days: 'days',
    sinceYesterday: 'Since Yesterday',
    todayOnly: 'Today Only',
    search: 'Search',
    reset: 'Reset',
    show: 'Show',
    all: 'All',
    quoteNumber: 'Quote #',
    orderNumber: 'Order #',
    createdBy: 'Created By',
    customerName: 'Customer Name',
    customerEmail: 'Customer Email',
    customerPhone: 'Customer Phone',
    dateCreated: 'Date Created (Updated)',
    quoteTotal: 'Quote Total',
    orderTotal: 'Order Total',
    depositPaid: 'Deposit Paid',
    viewQuotes: 'View Quote',
    viewOrders: 'View Order',
    view: 'View',
    status: 'Order Status',
    deliveryStatus: 'Delivery Status',
    checkStatus: 'Check Status',
    details: 'Details',
    searchQuotes: 'search quotes',
    searchOrders: 'search orders',
    searchLeads: 'search leads',
    quoteNotes: 'Notes',
    customerNotes: 'Notes',
    orderPrinted: 'Order Printed',
    priceMatch: 'Price Match',
    appointment: 'Appointment',
    type: 'Type',
    leadType: 'Lead Type',
    itemType: 'Type',
    itemManufacturer: 'Manufacturer',
    itemPartNumber: 'Part Number',
    itemName: 'Name',
    additionalLeadInfo: 'Additional Info',
    vehicleYear: 'Year',
    vehicleManufacturer: 'Manufacturer',
    vehicleSubmodel: 'Submodel',
    vehicleChassis: 'Chassis',
    customerContactMethodPreference: 'Contact Preference',
    customerMessage: 'Message',
    isNationalLead: 'National Lead',
    isNational: 'NTL',
    isDealer: 'DLR',
	  poNumber: 'PO Number',
	  confirmationNumber: 'Confirmation Number',
    createdTimestamp: 'Created Date',
    easternTimezone: 'EST',
    quotesMaxLimit: 'Or up to limit of 1000 quotes',
    'orderStatusModal': {
      datePlaced: 'Date Placed',
      description: 'Description',
      quantity: 'Qty',
      sourceType: 'Source Type',
      sourceTypeDC: 'Source DC',
      status: 'Status',
      shipMethod: 'Ship Method'
    }
  },
  'guestAccountCreation': {
    createGuestAccount: 'Create Guest Account',
    dealerUrlCode: 'Dealer Code',
    emailAddress: 'Email Address',
    password: 'Password',
    passwordConfirm: 'Confirm Password',
    create: 'Create',
    noEmail: 'Don\'t Have An Email?',
    passwordDoesNotMatch: 'Passwords do not match, please correct.',
    dataMissingOrIncorrect: 'All fields must be complete and valid.',
    clickHere: 'Click here',
    successMessage: ' to continue, you will be prompted to retype your email address and password, if you have any questions please contact Tire Wizard Support at 1-866-243-9652.'
  },
  'forgotPassword': {
    title: 'Reset Password',
    forgotPassword: 'Forgot Password?',
    forgotPassword2: 'Forgot Password',
    exisitingUserRegistration: 'Existing User Registration',
    enter: 'Enter'
  },
  'wishlist': {
    title: 'Your Quote',
    titleEcommerce: 'Your Order',
    titleEcommerceDealer: 'Customer Order',
    clear: 'Clear Quote',
    schedule: 'Request',
    download: 'Download',
    downloadAsPdf: 'download as pdf',
    viewAsPdf: 'view as pdf',
    buildPdf: 'Building PDF',
    item: 'Item',
    items: 'Items',
    msrp: 'MSRP',
    dealerPrice: 'Selling Price',
    quantity: 'Qty',
    youSave: 'You Saved',
    total: 'Total',
    totalSavings: 'Total Savings',
    totalWithoutSavings: 'Total Before Savings',
    saveWishlist: 'Save Quote',
    emailWishlist: 'Email Quote',
    emailWishlistBtnText: 'Email Quote',
    scheduleAppointment: 'Request Appointment',
    cartIsEmpty: 'Cart is Empty',
    continueShopping: 'Continue Shopping',
    print: 'Print',
    printHeader: 'Your Quote for your ',
    printHeaderError: 'Your Quote ',
    referenceNumber: 'Reference Number',
    reference: 'Reference',
    saveSessionReference: 'Save Session Reference',
    scheduleAppointmentMsg: 'Schedule an appointment with your retailer for your quote',
    personalizedWishlist: 'Your Personalized Quote',
    dateNotAvailable: 'Selected date is not available, please select another date.',
    completeReview: 'Complete Review',
    workOrderNumber: 'Work Order Number (mandatory; cannot be blank;)',
    markAsComplete: 'Mark as Complete',
    markAsCompleteQuestion: 'Mark this Quote as Complete?',
    cannotUndo: 'You will not be able to undo this action.',
    orderStatus: 'Status',
    proceedToCheckout: 'Proceed to Checkout',
    depositDue: 'Deposit Due Today',
    nonOrderableDisclaimer_1_a: 'Item in your cart is requiring availability confirmation ',
    nonOrderableDisclaimer_1_b: 'Items in your cart are requiring availability confirmation ',
    nonOrderableDisclaimer_2: '(Dealer will confirm availability upon arranging installation). ',
    nonOrderableDisclaimer_3: 'These items are',
    nonOrderableDisclaimer_4: ' NOT ',
    nonOrderableDisclaimer_5: 'included in the order total deposit above.',
    nonOrderableDisclaimerDealerOrder: 'Remaining items are not included with order.',
    orderReferemce: 'Order Reference #',
    orderDealerContact: 'You will be contacted within 1 - 2 business days by phone to book an appointment or you can contact the dealer at [], or by email at {}.',
    orderContactDisclaimer: 'You may be asked for your email during the appointment, if you have any questions you may contact the dealer at',
    orderStatusSchedule: 'Order Accepted (waiting for scheduling)',
    orderStatusScheduled: 'Order Scheduled',
    orderStatusCancelled: 'Order Cancelled',
    orderStatusComplete: 'Order Complete',
    transactionComplete: 'Transaction Complete',
    orderSuccess: 'Order Successful',
    orderCancelled: 'Order Cancelled',
    orderComplete: 'Order Complete',
    orderFailed: 'Order was not successful. A refund will be sent within 3 - 5 business days.',
    paymentDetails: 'Payment Details',
    vehicleDetails: 'Vehicle Details',
    installerDetails: 'Installer Details',
    deliveryDetails: 'Delivery Details',
    deliveryDetailsText: 'Your tires will be delivered directly to the dealership, you will be contacted to schedule an appointment.',
    deliveryDate: 'Delivery Date',
    vehicleDetailsDisclaimer_0: 'Please confirm the current tiresize on your vehicle matches the tiresize of the tires in your order - ',
    vehicleDetailsDisclaimer_1: 'how to read a tiresize',
    status: 'Status',
    sourceType: 'Source Type',
    sourceTypeDC: 'Source Type DC',
    totalPaid: 'Total Deposit Paid',
    remainderDue: 'Remainder Due at Dealership',
    remainderPaid: 'Remainder Paid at Dealership',
    myOrderHistory: 'See All Orders',
    yourContactInfo: 'Your Contact Info',
    customerContactInfo: 'Customer Contact Info',
    transactionsDate: 'Transaction Date',
    clickHere: 'clicking here',
    cancelOrder_1: 'You can cancel your order by',
    cancelOrder_2: 'For any cancelled orders the customer will be billed a restocking fee of []',
    cancelOrderDealer_1: 'You can cancel the customers order by',
    cancelOrderDealer_2: 'For any cancelled order, the customer will be billed a restocking fee of [] by Tire Wizard Inc.',
    cancelOrderDealer_3: 'The remainder of the deposit will be returned within 30 days of cancellation.',
    cancelOrderModal_1: 'By cancelling this order you will be charged a restocking fee of [] subtracted from your deposit of {}.',
    cancelOrderModal_2: '[] will be refunded on your card. This may take up to 30 days after cancellation.',
    cancelOrderInputEmail: 'your email address',
    cancelOrderReason: 'Reason for cancelation',
    cancelOrderReason_0: 'Prefer not to disclose',
    cancelOrderReason_1: 'I found a better price somewhere else',
    cancelOrderReason_2: 'Product does not fit my vehicle',
    cancelOrderReason_3: 'Decided not to purchase',
    cancelOrderButton: 'Confirm Cancellation',
    customerRequiresContact: 'You can schedule appointments here.',
    depositPaid: 'Deposit payment has been confirmed. Please schedule an appointment with the customer.',
    depositPaidSaveAppointment: 'Deposit payment has been confirmed. If appointment is correct, please save to schedule.',
    depositPaidAppointmentSaved: 'Deposit payment has been confirmed. Appointment has been scheduled.',
    customerDeposit: 'Deposit Fee',
    tireWizardFee: 'Administration Fee',
    scheduleEcommerceAppointment: 'Schedule Appointment',
    appointmentDate: 'Appointment Date',
    appointmentTime: 'Appointment Time',
    appointmentNotes: 'Notes',
    saveAppointmentRequest: 'Save',
    orderPlaced: 'Your order has been placed.',
    orderScheduled: 'Your order has been scheduled.',
    orderCompleted: 'Your order has been completed.',
    orderDepositDealer: 'Customer deposit has been paid.',
    orderPlacedDealer: 'Order recieved from [], please call them at {} to schedule an appointment.',
    orderScheduledDealer: 'Appointment Scheduled, input the work order number when available to mark the transaction as complete.',
    orderCompletedDealer: 'Customer order is completed.',
    orderPlacedThankYou: 'Thank you for shopping with us, an email confirmation is on its way to [].',
    orderScheduledMessage: 'Your tires will be ready for [].',
    orderCompletedMessage: 'Thank you for your purchase.',
    orderCancelledMessage: '',
    orderStepDespositPaid: 'Deposit Paid',
    orderStepReview: 'Review',
    orderStepCheckedOut: 'Checkout',
    orderStepOrdered: 'Order',
    orderStepScheduled: 'Appointment',
    orderStepCompleted: 'Complete',
    orderStepInstalled: 'Installation',
    disclaimerTitle: 'Important Information',
    deposit: 'Deposit',
    orderDetails: 'Order Details',
    orderCancelledDeliveryDisclaimer: 'This order has been cancelled. Delivery will still take place. Contact NTD to return tires.',
    shipMethod: 'Ship Method',
    freight: 'Freight',
    atdConfirmationNumber: 'Confirmation Number',
    'modal': {
      findADealer: 'Find a Retailer',
      province: 'Province:',
      selectNewProvince: 'Select New Province',
      search: 'Search',
      OR: 'OR',
      emailSent: 'Email Sent Successfully',
      emailFailed: 'Email Failed to Send',
      emailDisclaimer: 'Thank you.',
      appointmentSent: 'Appointment Sent Successfully',
      appointmentFailed: 'Appointment Failed to Send',
      contactInformation: 'Contact Information',
      emailCustomer: 'Email customer',
      appointmentDetails: 'Appointment Details',
      appointmentDisclaimer: 'Thank you. An email has been sent to the dealer. You will be contacted within 24 hours.',
      placeholder: 'City, Retailer Name',
      viewOnMap: 'View on Map',
      contactByEmail: 'Contact by email',
      contactByPhone: 'Contact by phone',
      AM: 'AM',
      PM: 'PM',
      name: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      phone: 'Phone',
      website: 'Website',
      date: 'Date',
      time: 'Time',
      notes: 'Notes',
      message: 'Message for Customer',
      contactPreference: 'Contact Preference',
      back: 'Back',
      yourCart: 'Your Cart',
      showAllLocations: 'Show all locations',
      wishlistSaved: 'Quote has been successfully saved.',
      wishlistSaveFailed: 'Quote has failed to save.',
      sessionReferenceSaved: 'Session reference number has been saved.',
      sessionReferenceFailure: 'Session reference number has failed to save.',
      emailCustomerQuote: 'Email quote',

      password: 'Password',
      passwordConfirm: 'Confirm Password',

      submit: 'Submit',
      retry: 'Retry',

      sendingData: 'Sending Data',
      accountCreated: 'Account Created',
      accountFailed: 'Failed to create account.',
      accountExists: 'Already have an account, click here to login',

      sendQuoteDateInstallation: 'Send quote and date of installation',
      reserveItems: 'Reserve items',
      reserveItemsCustomer: 'Reserve items',

      bookAppointment: 'Book Appointment',
      appointmentForCustomer: 'Book appointment for customer',
      requestCallback: 'Request Callback',

      requestContact: 'Request Contact',
      noThanks: 'No Thanks',

      priceMatchTires: 'Price Match Request',

      titleRequestContact: 'Would you like the dealer to contact you to review your tire needs?',
      titleRequestCallback: 'Request callback from expert',
      subtitleRequestCallback: 'Fill in the form below to get a callback from an expert.',
      titleRequestAppointment: 'Request appointment',
      subtitleRequestAppointment: 'Fill in the form below to request an appointment.',

      titleReserveItemsDealer: 'Reserve items from dealer',
      subtitleReserveItemsDealer: 'Fill in the the form below to reserve items from the dealer. Dealer will confirm availability.',
      titleReserveItems: 'Reserve items for customer',
      subtitleReserveItems: 'Fill in the the form below to reserve items for the customer.',

      titleSaveDealerQuote: 'Save',
      titleOrderDealerQuote: 'Order Tires',

      titleEmailDealer: 'Email',
      subtitleEmailDealer: 'Fill in form to email customer this quote.',
      titleEmailCustomer: 'Email quote',
      subtitleEmailCustomer: 'Send a copy of the quote to yourself, add any notes or requests below to help get the best option for you.',
      titleQuoteSave: 'Save Quote',

      titlePriceMatchCustomer: 'Price Match Request',
      subtitlePriceMatchCustomer: 'Fill in the form below to request a price match, please include the price and the retailer of where you would like to price match in the notes section.',
      titlePrintCustomer: 'Print Quote',
      subtitlePrintCustomer: 'Print your quote and optionally send an email.',
      titleDownloadCustomer: 'Download Quote',
      subtitleDownloadCustomer: 'Download quote as PDF and optionally send an email.',
      titleEmailComparison: 'Email',
      subtitleEmailComparion: 'Fill in the form below to send this comparison.',
      titlePrintComparison: 'Print',
      subtitlePrintComparion: '',
      titleDownloadComparison: 'Download',
      subtitleDownloadComparion: '',

      appointment: 'Submit',
      callback: 'Callback',
      reserve: 'Reserve',
      send: 'Submit',
      markAsComplete: 'Mark as Complete',
      compare: 'Compare',
      priceMatch: 'Price Match',
      print: 'Print',
      printAndEmail: 'Print & Email',
      download: 'Download',
      downloadAndEmail: 'Download & Email',
      appointmentSuccess: 'Thank you. A message has been sent to the dealer. You will be contacted within 24 hours to confirm appointment time.',
      callbackSuccess: 'Your callback request has been submitted. You will receive a call shortly from one of our experts to make sure you get the right tires for your vehicle.',
      reserveSuccess: 'You have requested to reserve items. You will receive a call or email shortly to confirm availability.',
      saveSuccess: 'You have successfully saved your quote.',
      sendSuccess: 'Email sent.',

      priceMatchSuccess: 'Price match request sent.',
      printSuccess: 'Print successful.',
      downloadSuccess: 'Download successful.',
      reminderSuccess: 'Reminder created for selected date.',
      emailNotificationOpened: 'Notify me when the customer opens the email.',
      emailNotificationReminder: 'Send reminder to customer in 2 days if email has not opened.',

      sendReminder: 'Send reminder to customer',

      titleReceiveAppointment: 'You have received an appointment lead from a customer',
      titleReceiveCallback: 'You have received a callback lead from a customer',
      titleReceiveReserve: 'You have received a reserve lead from a customer',
      titleReceiveEmail: 'You have received an email lead from a customer',
      titleReceiveCompare: 'You have received a comparison lead from a customer',

      subtitleDealerMarkAsComplete: 'You can respond to the customer below or mark as complete.',
      subtitleReceiveLead: 'You can respond to the customer below or mark as complete.',
      subtitleReceiveAppointment: '',
      subtitleReceiveCallback: '',
      subtitleReceiveReminder: '',
      subtitleReceiveReserve: '',
      subtitleReceiveEmail: '',

      contactDetails: 'Contact Details',
      requestedDate: 'Requested Date',

      errorNoDate: 'Date is required.',
      errorNoDealerUrlCode: 'Dealer Url Code is required.',
      errorNoFirstName: 'First name is required.',
      errorNoLastName: 'Last name is required.',
      errorNoPassword: 'Password is required.',
      errorNoPasswordConfirm: 'Password confirmation is required.',
      errorNoPhone: 'Phone number is required.',
      errorNoEmail: 'Email address is required.',
      errorNoTime: 'Time selection is required.',
      errorInvalidEmail: 'Email address must be valid.',
      errorNoReturn: 'Request failed to send.',
      errorNoReminderReturn: 'Request reminder failed to send.',

      invalidEmail: 'Email is not valid.',
      invalidPassword: 'Password is not valid. Must be at least 4 characters.',
      invalidPasswordConfirm: 'Password does not match.',

      errorNoNotes: 'Response is required.',
      errorNoMessage: 'Message is required.',

      proceedToCheckoutText: 'Your deposit due: []',
      checkout: 'Checkout',
      buyNowDeposit: 'If you buy now, your deposit due'
    },
    'reminderOptions': {
      day: '1 day',
      week: '1 week',
      twoWeeks: '2 weeks',
      month: '1 month',
      threeMonths: '3 months',
      sixMonths: '6 months'
    },
    'form': {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      phone: 'Phone'
    }
  },
  'partInfoModal': {
    itemStock: 'Item Stock',
    itemPricing: 'Item Pricing',
    location: 'Location',
    distributor: 'Distributor',
    province: 'Province',
    city: 'City',
    phone: 'Phone',
    qty: 'Qty',
    partNumber: 'Part Number',
    lastUpdated: 'Last Updated',
    dealerPrice: 'Dealer Price',
    msrp: 'MSRP',
    regionalPrice: 'Regional Price',
    priceUnavailable: 'Unavailable'
  },
  'partStockModal': {
    partStockModalTitle: 'National Stock Search'
  },
  'partPriceModal': {
    partPriceModalTitle: 'National Price Search'
  },
  'loginModal': {
    dealerRequiresUserLogin: 'users are required to login.',
    dealershipStaffLogin: 'Dealership Staff Login'
  },
  'calendar': {
    mondayShort : 'MON',
    mondayLong : 'Monday',
    tuesdayShort : 'TUE',
    tuesdayLong : 'Tuesday',
    wednesdayShort : 'WED',
    wednesdayLong : 'Wednesday',
    thursdayShort : 'THU',
    thursdayLong : 'Thursday',
    fridayShort : 'FRI',
    fridayLong : 'Friday',
    saturdayShort : 'SAT',
    saturdayLong : 'Saturday',
    sundayShort : 'SUN',
    sundayLong : 'Sunday',
    january : 'January',
    february : 'February',
    march : 'March',
    april : 'April',
    may : 'May',
    june : 'June',
    july : 'July',
    august : 'August',
    september : 'September',
    october : 'October',
    november : 'November',
    december : 'December',
    to : 'to',
    EST : '(EST)',
  },
  'priceMatching': {
    qty: 'Qty',
    partNumber: 'Part #:',
    notes: 'Price Match Notes *',
    workOrderNumber: 'Work Order Number:',
    discount: 'Discount:',
    add: 'Add',
    cancel: 'Cancel',
    close: 'Close',
    continue: 'Continue',
    saveAndContinue: 'Save & Continue',
    requestBy: 'Price Match Request by User:',
    currentTotal: 'Current Total Tire Price:',
    newTotal: 'New Total Tire Price:',
    WORK_ORDER_MISSING: 'Work Order Number cannot be empty.',
    WORK_ORDER_TOO_LONG: 'Work Order Number cannot be more than 40 characters.',
    DISCOUNT_MISSING: 'Discount cannot be empty.',
    DISCOUNT_NOT_A_NUMBER: 'Discount must be a number.',
    DISCOUNT_EXCEEDS_MAXIMUM: 'Discount value is too high, maximum value allowed is {}.',
    NOTE_MISSING: 'Note cannot be empty.'
  },
  'emailPromotion': {
    headTag: 'Please provide your email address to be sent a copy of the rebate and / or coupon.',
    emailLabel: 'Email Address:',
    emailButton: 'Email',
    viewRebateButton: 'View Rebate Details',
    emailSentSuccess: 'Your Promotion has been emailed to your address. Please check your inbox.',
    emailSentFailure: 'Your Promotion has failed to be emailed to your address. Please try again.',
    footTag: 'By providing your email address, you agree to share your email with the dealership for further communication about this promotion and also including but not limited to addtions offers and promotions.'
  },
  'findMyTireSize': {
    howTo: 'How do I find my tire size?',
    title: 'How to find your tire size (Tire Size Diagram)',
    tag: 'To ensure proper sizing always contact your local vehicle service center.'
  },
  'ntdStock': {
    localDc: 'Local DC',
    localPlus: 'Local+',
    national: 'National',
    nationalAbbr: 'Nat',
    loading: 'Loading',
    pleaseWait: 'Please Wait',
    order: 'Order',
    stockNotAvailable: 'Contact for Availability',
    shippingDisclaimer_1: 'Free Shipping to your dealership on all orders',
    shippingDisclaimer_2: 'delivery as early as 1 business day'
  },
	'launcher': {
    title: "Tire & Wheel Store",
		shopByVehicle: "Shop By Vehicle",
		shopBySize: "Shop By Size",
		year: "Year",
		selectYear: "Select Year",
		make: "Make",
		selectMake: "Select Make",
		model: "Model",
		selectModel: "Select Model",
		selectPostal: "Enter Postal Code (Mandatory)*",
		shoppingFor: "I'm shopping for",
		productCategory: "Select Product Type",
		chooseProductType: "Choose Product Type",
		tires: "Tires",
		wheels: "Wheels",
		accessories: "Accessories",
		packages: "Packages",
		findTires: "Find Tires",
		tireSize: "Tire Size",
		tireSizePlaceholder: "Enter your Tire Size",
		howFindTireSize: "How do I find my tire size?",
		search: "Search",
		searchForTires: "Search for Tires",
		searchForWheels: "Search for Wheels",
		searchForAccessories: "Search for Accessories",
		searchForPackages: "Search for Packages",
		selectYourDealer: "Select Your Dealer",
		errorTitle: "Error:",
		dealerError: "A dealer must be selected.",
		vehicleSearchError: "All values must be entered and valid.",
		tireSizeSearchError: "Tire size cannot be blank. Values must be valid.",
		partNumberSearchError: "Part Number cannot be blank. Values must be valid.",
		widgetConfigError: "Widget has encountered an error.",
		primaryMakes: "Primary Makes",
		secondaryMakes: "Secondary Makes",
		viewResults: "View Results",
		disclaimer: "Disclaimer",
		disclaimerTitle: "",
		disclaimer1: "",
		disclaimer2: "",
		disclaimer3: "",
		defaultDisclaimer: "",
		enterTiresize: "Enter your Tire Size (ex. 1956515)",
		enterPostal: "Postal code",
    searchByVehicle: "Search by Vehicle",
    searchByTiresize: "Search by Tire Size",
    searchByPartNumber: "Search by Part Number",
    vehicleSelectionTitle: "Please provide your vehicle information",
    tiresizeSelectionTitle: "Please provide your tire size",
    partnumberSelectionTitle: "Please provide your part number",
    partNumber: "Part Number"
	},
	'ntdModal': {
		title: "Order Preview",
		titlePlaced: "Thank you for your order!",
		scheduleAppointment: "Schedule Appointment",
		togglePickUp: "Pick up items",
		toggleDeliver: "Deliver items",
    toggleWithOtherGoods: "Shipped With Other Goods",
		tableProduct: "Product",
		tablePayment: "Payment/Qty",
		tableQuantity: "Sourced Qty",
		tableDeliveryDate: "Est. Delivery Date",
		tableUnitCost: "Unit Cost",
		tableTotal: "Total",
		tableDelivery: "Delivery",
		of: "of",
		shipMethodNational: "National",
		shipMethodLocalPlus: "Local+",
		shipMethodLocal: "Local",
		nationalPricing: "NAT",
		pickUp: "Pick-Up",
		delivery: "Delivery",
		withOtherGoods: "With Other Goods",
		freight: "freight: ",
		customer: "Customer",
		locationCode: "Location Code",
		poNumber: "PO #",
		summarySubtotal: "Subtotal",
		summaryFreight: "Freight",
		summaryOrderTotal: "Order Total",
		sendingOrder: "Sending Order...",
		previewLoading: "Order Preview Loading...",
		previewFailed: "Order Preview Failed To Load",
		failedToSend: "Order Failed To Send",
		continue: "Continue",
		goBack: "Go Back",
		orderWarning: "Order Warning",
		placeOrder: "Place Order",
		orderProcessed: "Order Processed",
		itemInformation: "Item Information",
    shippingMethod: "Shipping Method",

		orderDisclaimer: "This order has not been submitted. To submit this order click the \"place order\" button below. Products placed on backorder will be invoiced at the price in effect at the time of delivery. Orders subject to applicable taxes. ",
		orderDisclaimer2: "All orders submitted will need to qualify for shipping under the NTD Freight Policy, if you have any questions please contact your NTD representative (at 1-844-343-8722) to confirm the order(s) submitted meet the policy requirements. ",
		orderIsTest: "This is a test order. Order will not be placed.",
    confirmationNumber_part1: "Order confirmation number # ",
		confirmationNumber_part2: " has been submitted. You can track ",

		orderHistoryHere: "order history here",
		agreeToCharges: "I agree to pay the shipping charges as shown above plus applicable boxing and handling charges. The shipping and handeling charges are non-refundable in the event of a return.",
		chargeOnInvoice: "This charge will appear on your invoice for this order.",
		agreement: "Yes, I agree",

		customerInfo: "Customer Information",
		customerFirstName: "First Name",
		customerLastName: "Last Name",
		customerPhone: "Phone",
		customerEmail: "Email",

		remove: "Remove",

		itemsNotAvailableToOrder: "The following items from your cart are not available to order",

		ntdFailed: "We apologize but we failed to receive the proper data from NTD.",
		warningShipping: "Sorry, you must accept the shipping charges before you may proceed.",
		warningPO: "Please confirm you wish to proceed without setting a purchase order number.",
		warningPOBookAppointment: "Please confirm you wish to proceed without setting a purchase order number, or enter a purchase order number in the input field.",

    appointmentScheduler: "Appointment Schedule - To ensure tires availability, please book appointment 24 hours from today.",

		notAvailable: "Not Available",
		willNotBeShipped: "Will not be shipped",

		tireWizardDisclaimer: "Tire Wizard Inc. is not responsible for order fulfilment. For any changes or questions regarding your order please contact National Tire Distributor directly."
	},
	'comparison': {
		title: "Compare Products",
		compare: "Compare",
		productInformation: "Product Information",
		pricingInformation: "Pricing Information",
		product: "Product",
		pricing: "Pricing",
		otherInformation: "Other Information",
		quantitySelection: "Quantity Selection",
		productDetails: "Product Details",
		pricingDetails: "Pricing Details",
		selectQuantity: "Select Quantity",
		selectQuantityForAllParts: "Select Quantity For All Parts",
		promotion: "Promotion",
		additionalCosts: "Additonal Costs",
		compareSelected: "Compare Selected",
		quickCompare: "Quick Compare",
		noPartsToCompare: "Sorry, you need to select a tire before you can run a comparison.",
		partAlreadyInList: "Sorry, part is already in the list.",
		partListIsFull: "Sorry, list is full. Please remove part(s) before adding others.",
		comparisonFailed: "We're sorry, comparison is unavailable because one or more of the items selected does not have pricing. Please contact the dealer for further details.",
		subtotal: "Subtotal",
		tax: "Tax",
		total: "Grand Total",
    noCost: "No Cost",

		emailSuccess: "Email sent successfully.",
		emailFailure: "Email has failed to send. Please try again.",
		emailErrorText: "Email is not valid.",
		emailUnavailableText: "Cannot send. Please enter a valid email.",

		quick: "Quick",
		print: "Print",
		email: "Email"
	},
	'support': {
		title: 'Tire Wizard Customer Service',
		support: 'Support',
		cmsPortalText: 'Enter the Tire Wizard CMS',
		businessHours: 'Business Hours',
		tireWizardDayHours: '8:00am to 5:00pm (EST)',
		closed: 'Closed',

		phone: 'Phone',
		tollFree: 'Toll Free',
		email: 'Email',
		website: 'Website',

		tabMessage: 'Double Click on tab to close',

		tireWizardPhoneNumber: '1-866-243-9652',
		tireWizardEmailAddress: 'clientcare@tirewizard.ca',
		tireWizardWebsite: 'https://www.tirewizard.ca/',
	},
  'campaign': {
    myDealership: 'My Dealership',
    dealershipHours: 'Dealership Hours',
    campaignInactiveMessage: 'We\'re sorry, campaign is not currently available.',
    campaignInactiveMessageDealer: 'Please select dealer to see their current offers.',
    campaignInactiveMessageNational: 'Please select a dealer to see their current offers.',
    campaignInactiveButtonDealer: 'Dealer Homepage',
    campaignInactiveButtonNational: 'Select a Dealer',
    yourVehicle: 'Your Vehicle'
  },
  'xtime': {
    bookAppointment: 'Book Appointment',
    pickAppointmentTime: 'Pick an appointment time with ',
    pickAppointmentTimeDealer: 'Book an appointment with ',
    orderTiresPrompt: 'Would you like to order the selected tires before you select an appointment time?'
  },
  'recaptcha': {
    confirmTiresize: "Please confirm your tire size is correct: ",
    enterTiresize: "Please enter your tire size: ",
    inputPlaceholder: "radial is optional",
    tiresizeIsRequired: "Tiresize is required.",
    yesConfirm: "Yes, Confirm",
    confirm: "Confirm",
    noChange: "No, Change"
  },
  'tireModel': {
    sizeAndPricing: "Size And Pricing",
    tireDetails: "Tire Details",
    warranty: "Warranty"
  },
  'warning': {
    seasonChanged: "No parts were found for your selected season. Season has changed.",
    seasonChangedToWinter: "No parts were found for your selected season. Season has changed to winter parts.",
    seasonChangedFromWinter: "No parts were found for your selected season. Season has changed off of winter parts.",
    tiresizeChanged: "No parts were found for your selected tiresize. Tiresize has changed."
  },
  'map': {
    buyNow: "Buy Now",
    getTires: "get tires right away",
    generateQuote: "Generate Quote",
    dealershipAvailability: "Dealership will confirm availability",
  }
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApiBaseUrl } from "../../api/ApiService";
import { getAppDealerId } from "../../service/UtilityService";



// loadVehicleSearchParameters: function(partType, year, make, category, model, trim) {
//     this.apiGet('/vehicle/validateVehicleKeys.php', {
//       dealerId: getAppDealerId(),
//       nationalAccountId: applicationStore.data.nationalAccountId,
//       partType: partType,
//       vehicleYear: decodeURIComponent(year),
//       vehicleManufacturerName: decodeURIComponent(make),
//       vehicleCategoryName: decodeURIComponent(category),
//       vehicleModelName: decodeURIComponent(model),
//       vehicleSubmodelName: decodeURIComponent(trim),
//       isNational: applicationStore.data.nationalSite ? 1 : 0
//     }).then(function (response) {
//       this.dispatch(DispatcherActions.INITIALIZE_VEHICLE_PARAMETERS, response);
//     }.bind(this));
//   }

export const loadVehicleParameters = createAsyncThunk(
    'vehicleInfo/loadVehicleSearch',
    async (arg,{getState})=>{
        let {tokenId} = getState().info;
        return fetch(`${getApiBaseUrl()}/retailSiteWebService/vehicle/validateVehicleKeys.php`,{
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({
                dealerId: getAppDealerId(),
                nationalAccountId: 3,
                partType: arg.partType,
                vehicleYear: decodeURIComponent(arg.year),
                vehicleManufacturerName: decodeURIComponent(arg.make),
                vehicleCategoryName: decodeURIComponent(arg.category),
                vehicleModelName: decodeURIComponent(arg.model),
                vehicleSubmodelName: decodeURIComponent(arg.trim),
                isNational: true ? 1 : 0,
                tokenId:tokenId
            })
        })
        .then(res =>{
            if(!res.ok) throw Error(res.statusText)
            return res.json()
        })
        .then(json => json)
    }
)
// loadTireById: function(identifier) {
//     this.apiGet('/parts/tire/getTireByPartInfo.php', {
//       dealerId: getAppDealerId(),
//       brandId: applicationStore.data.selectedBrandId,
//       partId: identifier,
//       partNumber: identifier,
//       isNational: applicationStore.data.nationalSite ? 1 : 0
//     }).then(function (response) {
//       if (response) {
//         applicationStore.data.selectedTireId = response.partDetails.primaryPart.itemId;
//         applicationStore.data.selectedTirePartNumber = response.partDetails.primaryPart.tireManufacturerPartNumber;
//       }
//       this.dispatch(DispatcherActions.TIRE_LOADED_FROM_PART_DATA, response);
//     }.bind(this));
//   }

export const loadTireById = createAsyncThunk(
    'vehicleInfo/loadTiresId',
    async(args,{getState}) =>{
        const {tokenId} = getState.info;
        return fetch(`${getApiBaseUrl()}/retailSiteWebService/arts/tire/getTireByPartInfo.php`,{
            method:"GET",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({
                dealerId:getAppDealerId(),
                brandId:3,
                partId:args.identifier,
                partNumber:args.identifier,
                isNational:true ? 1 : 0,
                tokenId:tokenId
            })
        })
    }
)
const vehicleInfo = createSlice({
    name:"vehicleInfo",
    initialState:{
        vehicleInfo:''

    },
    extraReducers:{
        [loadVehicleParameters.pending]:(state,action)=>{
            state.status = 'loading'
        },
        [loadVehicleParameters.fulfilled]:(state,action)=>{
            state.status = 'sucess'
        },
        [loadVehicleParameters.rejected]:(state,action)=>{
            state.status = 'failed'
        },
        [loadTireById.pending]:(state,action)=>{
            state.status = 'loading'
        },
        [loadTireById.fulfilled]:(state,action)=>{
            state.status = 'sucess'
        },
        [loadTireById.failed]:(state,action)=>{
            state.status = 'failed'
        }
        
    }
})


export default vehicleInfo.reducer;
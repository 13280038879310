import { breadcrumbActions } from "../../actions/BreadcrumbActions.js";
import { vehicleSelectionActions } from "../../actions/VehicleSelectionActions.js";
import { vehicleSelectionStore } from "../../stores/VehicleSelectionStore.js";
import { applicationStore } from "../../stores/ApplicationStore.js";

import { Image } from "../ux/Image.jsx";

import {
  sizeOf,
  getTabIndex,
  scrollToPageTop,
  getTrackingEventCategory,
} from "../../service/UtilityService";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";

import { RouterStates } from "../../constants/RouterStates.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var _ = require("lodash");
var React = require("react");

class VehicleSelectionMakes extends I18nComponent {
  state = {
    primaryMakes: [],
    secondaryMakes: [],
    showSecondaryMakes: false,
    skipMakes: false,
    userGoBack: applicationStore.data.userGoBack,
    userGoBackWithCrumb: applicationStore.data.userGoBackWithCrumb,
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("Vehicle Select Manufacturer");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Vehicle Select Manufacturer Access"
    );
    applicationStore.addChangeListener(this.onChange);
    vehicleSelectionStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
    vehicleSelectionStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    //setTimeout(() => { vehicleSelectionActions.loadMakes(); }, 250);
    vehicleSelectionActions.loadMakes();
  }

  componentDidUpdate(lastProps, lastState) {
    ///*
    if (!this.state.userGoBack && !this.state.userGoBackWithCrumb) {
      if (
        sizeOf(this.state.primaryMakes) + sizeOf(this.state.secondaryMakes) ===
          1 &&
        !this.state.skipMakes
      ) {
        var selectedMake = {};
        if (sizeOf(this.state.primaryMakes) === 1)
          this.state.primaryMakes.map((make) => (selectedMake = make));
        else if (sizeOf(this.state.secondaryMakes) === 1)
          this.state.secondaryMakes.map((make) => (selectedMake = make));

        ///*
        if (sizeOf(selectedMake) > 0) {
          this.setState({ skipMakes: true });
          vehicleSelectionActions.makeSelected(selectedMake.id);
          breadcrumbActions.pushState(
            RouterStates.MAKE,
            selectedMake.id,
            selectedMake.name,
            false,
            false
          );
        }
        //*/
      }
    }
    //*/
    scrollToPageTop(
      this.state.primaryMakes.concat(this.state.secondaryMakes),
      lastState.primaryMakes.concat(lastState.secondaryMakes)
    );
  }

  render() {
    return (
      <div className="main" id="select-page">
        <div className="container">
          <ul className="select-simple-image">
            {_.map(
              this.state.primaryMakes,
              function (make, index) {
                return (
                  <li
                    role="button"
                    tabIndex={getTabIndex()}
                    aria-label={this.t("breadcrumbs.make") + ": " + make.name}
                    onKeyDown={(e) => this.onMakeClick(make.id, make.name, e)}
                    key={make.id}
                    onClick={this.onMakeClick.bind(this, make.id, make.name)}
                  >
                    <a>
                      <Image
                        src={make.image}
                        alt={make.name}
                        className={setAdobeLaunchClass(
                          "",
                          ADOBELAUNCHER_CLASS.IMAGE
                        )}
                        datadtm={setAdobeLaunchDTM(
                          "",
                          ADOBELAUNCHER_DTM.BRANDSELECTION
                        )}
                      />
                      <span>{make.name}</span>
                    </a>
                  </li>
                );
              },
              this
            )}
          </ul>
          <ul className="select-simple">
            {_.map(
              this.state.secondaryMakes,
              function (make, index) {
                return (
                  <li
                    role="button"
                    tabIndex={getTabIndex()}
                    aria-label={this.t("breadcrumbs.make") + ": " + make.name}
                    onKeyDown={(e) => this.onMakeClick(make.id, make.name, e)}
                    key={make.id}
                    onClick={this.onMakeClick.bind(this, make.id, make.name)}
                  >
                    <a
                      alt={make.name}
                      className={setAdobeLaunchClass(
                        "",
                        ADOBELAUNCHER_CLASS.BUTTON.LINK
                      )}
                      data-dtm={setAdobeLaunchDTM(
                        "",
                        ADOBELAUNCHER_DTM.BRANDSELECTION
                      )}
                    >
                      <span>{make.name}</span>
                    </a>
                  </li>
                );
              },
              this
            )}
          </ul>
        </div>
      </div>
    );
  }

  onChange = () => {
    this.setState({
      primaryMakes: vehicleSelectionStore.data.primaryMakes,
      secondaryMakes: vehicleSelectionStore.data.secondaryMakes,
      showSecondaryMakes: vehicleSelectionStore.shouldShowSecondaryMakes(),
      userGoBack: applicationStore.data.userGoBack,
      userGoBackWithCrumb: applicationStore.data.userGoBackWithCrumb,
    });
  };

  onMakeClick = (makeId, make, evt) => {
    checkForEvent: if ("undefined" != typeof evt.keyCode) {
      if (evt.keyCode == 13 || evt.keyCode == 32) {
        evt.preventDefault();
        evt.stopPropagation();
        break checkForEvent;
      }
      return;
    }
    vehicleSelectionActions.makeSelected(makeId);
    breadcrumbActions.pushState(RouterStates.MAKE, makeId, make, false, false);
  };
}

export default VehicleSelectionMakes;

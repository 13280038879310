module.exports = {
  'common': {
    'viewTires': 'shop tires now',
    'viewWheels': 'shop wheels now',
    'viewAccessories': 'shop accessories now',
    'viewPackages': 'shop packages now',
    'welcomeMessage': 'Welcome to the ApprovedTire and Wheel Center',
    'getStarted': 'Commencez',
    'customizeYourRide': 'Customize your ride',
    'select': 'Select',
    'yes': 'Yes',
    'no': 'No',
    'featured': 'Featured',
    'enter': 'Enter',
    'dealerSelectionPlaceholder': 'Search by city, province or postal code'
  }
}

module.exports = {
    'common': {
        'viewTires': 'Recherche De Pneus Maintenant',
        'viewWheels': 'Recherche De Jantes Maintenant',
        'viewAccessories': 'Recherche D’accessoire Maintenant',
        'viewPackages': 'Recherche D’ensemble Maintenant'
    },
    'product': {
        'dealerPrice': 'Prix national',
        'retailPrice': 'Prix national'
    },
    'productTypes': {
        'tiresAlt': 'Pneus pour votre Toyota ou Scion',
        'wheelsAlt': 'Jantes pour votre Toyota ou Scion',
        'accessoriesAlt': 'Accessoire pour votre Toyota ou Scion',
        'packagesAlt': 'Ensemble pour votre Toyota ou Scion',

        'tires': 'Pneus',
        'tiresTab': 'Pneus',
        'searchTires': 'Pneus',
        'tireSelectText': 'Toyota propose une vaste gamme de pneus des principales marques au Canada.'
    }
};
module.exports = {
  homepageTitleText: 'Votre concessionnaire Audi est le meilleur endroit pour acheter des pneus',
  homepageText: 'Trouvez des pneus adaptés à votre véhicule. Indiquez-nous votre modèle Audi et nous vous recommanderons des choix qui assurent la meilleure performance, une sécurité accrue et une économie de carburant optimale. En plus d’être facile et pratique, l’achat de pneus neufs et de jantes chez votre concessionnaire Audi vous permet de bénéficier d’une pose par des techniciens certifiés Audi.',
	'common': {
		featuredItems: "Articles populaires"
	},
	'productTypes': {
		tireSelectText: 'Les générations plus récentes de pneus d\'hiver sont conçues avec un composé de caoutchouc plus flexible qui est moins enclin à se raidir dans des températures plus froides. Leur bande de roulement est également conçu pour offrir une meilleure traction sur les surfaces humide, neigeuses, glacées et froid sec.',
		wheelSelectText: 'Que vous conduissez au travail ou que vous voyagez de longues distances, une chose est sûre: Les ensembles et les accessoires de roues d\'hiver authentiques d\'Audi sont votre combinaison idéal pour aller partout où vous voulez aller cet hiver.',
		accessorySelectText: '',
		packageSelectText: 'Les ensembles et accessoires de roues d\'hiver d\'Audi sont votre combinaison idéal pour aller partout où vous voulez aller cet hiver.'
	},
	'search': {
		placeholderSearchTireSize: 'recherche par taille de pneu: e.g. 245/40R18'
	},
	'launcher': {
		enterTiresize: 'Enter your tire size: e.g. 245/40R18'
	}
};
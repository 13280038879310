import { I18nComponent } from "../../../i18n/I18nComponent.jsx";
import { getTabIndex, sizeOf, isEmpty } from "../../../service/UtilityService";

var React = require("react");
var ReactDOM = require("react-dom");
var Cx = require("classnames");
var $ = require("jquery");
var _ = require("lodash");

var DEFAULT_VALUE = "Select a value";
var DEFAULT_CLASS = "combo-box";
var EXPANDED_CLASS = "expanded";
var DISABLED = "disabled";

export class ComboBox extends I18nComponent {
  state = {
    expanded: false,
    selectedOnDataLoad: false,
    content: this.props.value ? this.props.value : this.props.emptyText,
  };

  static defaultProps = {
    onChange: function () {},
    emptyText: DEFAULT_VALUE,
    className: "",
    datadtm: "",
  };

  componentWillMount() {
    $(document).click(this.onClickDocument);
  }

  componentWillUnmount() {
    $(document).unbind("click", this.onClickDocument);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isQuantityDropDown) {
      if (nextProps.value.get("id") !== this.props.value.get("id")) {
        if (nextProps.value.get("id") === null) {
          this.setState({
            content: this.props.emptyText,
          });
        } else {
          this.props = nextProps;
          _.each(
            this.props.children,
            function (child) {
              if (child.props.value.get("id") === nextProps.value.get("id")) {
                this.setState({
                  content: child.props.children,
                });
              }
            },
            this
          );
        }
      }
    } else {
      if (nextProps.value !== this.props.value) {
        if (nextProps.value === null) {
          this.setState({
            content: this.props.emptyText,
          });
        } else {
          this.props = nextProps;
          _.each(
            this.props.children,
            function (child) {
              if (child.props && child.props.value === nextProps.value) {
                this.setState({
                  content: child.props.children,
                });
              }
            },
            this
          );
        }
      }
    }
  }

  componentDidMount() {
    var _this = this,
      refs = this.refs;
    $(window).keyup(function (e) {
      var code = e.keyCode ? e.keyCode : e.which;
      if (code === 13) {
        var focusedElement = document.querySelector(":focus");
        if (
          focusedElement &&
          focusedElement.classList[0] === "combo-box" &&
          !focusedElement.classList.contains("expanded")
        ) {
          $(focusedElement).addClass("expanded");
        } else if (
          focusedElement &&
          focusedElement.classList[0] === "combo-box-value"
        ) {
          var parents = $(focusedElement).parents();
          for (var i in refs) {
            if (refs[i] === parents[1]) {
              _this.onValueSelected(
                focusedElement.children[0].id,
                focusedElement.children[0].innerHTML
              );
              $(parents[1]).removeClass("expanded");
            }
          }
        }
      }
    });
  }

  componentDidUpdate(lastProps, nextState) {
    //console.log('componentDidUpdate - ' + this.props.value);

    if (sizeOf(this.props.list) !== sizeOf(lastProps.list)) {
      this.autoSelectValue();
    }
    this.autoSelectValue();
    if (
      nextState.content === this.props.emptyText &&
      this.state.content !== this.props.emptyText
    ) {
      this.setState({
        expanded: false,
      });
    }
  }

  autoSelectValue = () => {
    if (!this.state.selectedOnDataLoad && sizeOf(this.props.list) == 1) {
      var selected;
      for (var i in this.props.list) {
        selected = this.props.list[i];
        this.setState({
          expanded: false,
          selectedOnDataLoad: true,
        });
      }
      if (!isEmpty(selected)) {
        this.onValueSelected(selected.id, selected.name, false);
      }
    }
  };

  selectComboBoxFirstValue = () => {
    this.setState({
      content: this.props.children[1][0],
      expanded: false,
    });
  };

  onClickDocument = (ev) => {
    if (
      !ReactDOM.findDOMNode(this).contains(ev.target) &&
      this.state.expanded
    ) {
      this.setState({
        expanded: false,
      });
    }
  };

  onToggleComponent = () => {
    if (!this.props.disabled) {
      this.setState({
        expanded: !this.state.expanded,
      });
    }
  };

  onExpandComponent = () => {
    if (!this.props.disabled) {
      this.setState({
        expanded: true,
      });
    }
  };

  onValueSelected = (value, content, supressCallback) => {
    if (this.props.getValuesByClass) {
      this.setState({
        value: value,
        content: content,
        expanded: false,
      });
    } else {
      this.setState({
        content: content,
        expanded: false,
      });
    }

    if (!supressCallback) {
      this.props.onChange(value);
    }
  };

  render() {
    const classList = {};
    classList[DEFAULT_CLASS] = true;
    classList[DISABLED] = this.props.disabled;
    if (this.props.className) {
      classList[this.props.className] =
        this.props.className.indexOf("stat-") === -1;
    }
    classList[EXPANDED_CLASS] = this.state.expanded;

    var givenValueOfZeroFlag = parseInt(this.props.value, 10) === 0;

    var content = this.state.content;
    if (this.props.hideTextInDisplay) {
      if (this.props.isQuantityDropDown && typeof content === "object") {
        content = content.toObject();
        content = content.value;
      } else {
        if (content.indexOf("-") > -1) {
          var temp = content.split(" - ");
          content = temp[0];
        }
      }
    }

    var expandClassName = "no-print";
    if (this.props.scrollBar) {
      expandClassName = "no-print scroll-bar";
    }

    if (this.props.className === "season-select") {
      switch (parseInt(this.props.value)) {
        case 0:
          content = this.t("product.filters.season.winter");
          break;
        case 1:
          content = this.t("product.filters.season.allSeason");
          break;
        case 2:
          content = this.t("product.filters.season.summer");
          break;
        default:
          content = this.t("product.filters.season.noSelection");
          break;
      }
    }

    var selectedValue = this.state.value;

    /* TODO
     * add aria label, as combobox overrides content reading
     * add keyboard handlers, navigate contents somehow...
     */
    var onValueSelected = this.onValueSelected,
      select = null;
    if (this.props.getValuesByClass) {
      select = (
        <div onClick={this.onToggleComponent}>
          <div className="hidden" id={selectedValue}>
            {selectedValue}
          </div>
          <span>{content}</span>
          <i></i>
        </div>
      );
    } else {
      select = (
        <div onClick={this.onToggleComponent}>
          <span>{content}</span>
          <i></i>
        </div>
      );
    }

    var tabIndex = getTabIndex();
    var classSuffix = this.props.classSuffix ? this.props.classSuffix : "";

    return (
      <div
        className={Cx(classList) + " show-focus " + classSuffix}
        ref={"comboBox-" + tabIndex}
        role="combobox"
        tabIndex={tabIndex}
        onClick={this.onClickDocument}
      >
        {select}

        <ul className={expandClassName}>
          {this.props.getValuesByClass
            ? React.Children.map(
                this.props.children,
                function (child, index) {
                  var selected = this.state.value == child.props.value;
                  return React.cloneElement(child, {
                    onValueSelected: onValueSelected,
                    className: this.props.className,
                    datadtm: !isEmpty(this.props.datadtm)
                      ? this.props.datadtm
                      : "",
                    selected: selected,
                    key: child.props.key,
                  });
                }.bind(this)
              )
            : React.Children.map(
                this.props.children,
                function (child, index) {
                  var selected = false;
                  if (typeof this.props.value !== "object") {
                    selected = this.props.value == child.props.value;
                  } else if (this.props.value && child.props.value) {
                    if (this.props.objectQuantities)
                      selected =
                        this.props.value["id"] == child.props.value["id"];
                    else
                      selected =
                        this.props.value.get("id") ==
                        child.props.value.get("id");
                  }
                  if (givenValueOfZeroFlag && child.props.value === 0)
                    selected = true;
                  return React.cloneElement(child, {
                    onValueSelected: onValueSelected,
                    className: this.props.className,
                    datadtm: !isEmpty(this.props.datadtm)
                      ? this.props.datadtm
                      : "",
                    selected: selected,
                    key: child.props.key,
                  });
                }.bind(this)
              )}
        </ul>
      </div>
    );
  }
}

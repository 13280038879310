import { AbstractStore } from './AbstractStore.js';
import { applicationStore } from './ApplicationStore.js';
import { Router } from './Router.js';
import { vehicleSelectionActions } from '../actions/VehicleSelectionActions.js';
import { VehicleSelectionSkippingService } from '../service/VehicleSelectionSkippingService.js';

import { DispatcherActions } from '../constants/DispatcherActions.js';
import { ProductTypes } from '../constants/ProductTypes.js';

import { sizeOf, isEmpty, getAppLanguage, getTrackingEventCategory, getProductTypeUpperCase } from '../service/UtilityService.js';

var _ = require('lodash');

var VehicleSelectionStore = AbstractStore.extend({

	constructor: function () {
		this.reset();
		VehicleSelectionStore.__super__.constructor.apply(this, arguments);
	},

	reset: function () {
		this.data = {};
		this.data.vehicleYears = [];
		this.data.primaryMakes = [];
		this.data.secondaryMakes = [];
		this.data.categories = [];
		this.data.models = [];
		this.data.fullVehicleSelectionTree = null;

		this.data.vehicleId = null;

		this.data.year = null;
		this.data.category = null;
		this.data.model = null;
	},

	getActionHandlers: function () {
		var handlers = {};
		handlers[DispatcherActions.INITIALIZE_APP_STATE] = this.onInitializeAppState;
		handlers[DispatcherActions.VEHICLE_YEARS_LOADED] = this.onVehicleYearsLoaded;
		handlers[DispatcherActions.SEARCH_MODAL_VEHICLE_YEARS_LOADED] = this.onVehicleYearsLoaded;
		handlers[DispatcherActions.VEHICLE_MAKES_LOADED] = this.onVehicleMakesLoaded;
		handlers[DispatcherActions.SEARCH_MODAL_VEHICLE_MAKES_LOADED] = this.onVehicleMakesLoaded;
		handlers[DispatcherActions.VEHICLE_CATEGORIES_LOADED] = this.onVehicleCategoriesLoaded;
		handlers[DispatcherActions.VEHICLE_MODELS_LOADED] = this.onModelsLoaded;
		handlers[DispatcherActions.SEARCH_MODAL_VEHICLE_MODELS_LOADED] = this.onModelsLoaded;
		handlers[DispatcherActions.VEHICLE_TRIMS_LOADED] = this.onTrimsLoaded;
		handlers[DispatcherActions.VEHICLE_INFO_LOADED] = this.onVehicleInfoLoaded;
		handlers[DispatcherActions.VEHICLE_TREE_LOADED] = this.onVehicleTreeLoaded;
		handlers[DispatcherActions.VEHICLE_SELECTION_YEARS_SELECTED_FROM_MODAL] = this.vehicleYearSelected;
		handlers[DispatcherActions.VEHICLE_SELECTION_CATEGORY_SELECTED_FROM_MODAL] = this.vehicleCategorySelected;
		handlers[DispatcherActions.VEHICLE_SELECTION_MODEL_SELECTED_FROM_MODAL] = this.vehicleModelSelected;
		handlers[DispatcherActions.PRODUCT_TYPE_SELECTED] = this.onProductTypeSelected;
		handlers[DispatcherActions.LOAD_VEHICLE_SIMPLE_SEARCH] = this.resetLoadedVehicle;
		handlers[DispatcherActions.RESET_VEHICLE_SEARCH] = this.resetVehicleSearch;
		return handlers;
	},

	onInitializeAppState: function (appState) {
    var routerState = Router.decodeRouterState(appState);

    this.loadAppState('productType', appState, routerState.params);
    this.loadAppState('vehicleId', appState, routerState.params);
    this.loadAppState('year', appState, routerState.params);
    this.loadAppState('category', appState, routerState.params);
    this.loadAppState('model', appState, routerState.params);

		this.emitChange();
	},

  loadAppState: function(key, appState, params) {
    var temp;
    if (params[key]) {
      temp = params;
      this.data[key] = temp[key];
    } else if (appState[key]) {
      temp = appState;
      this.data[key] = temp[key];
    }
  },

	onProductTypeSelected: function (productType) {
		this.data.productType = productType;
		this.emitChange();
	},

	resetVehicleSearch: function() {
    this.data.year = null;
    this.data.category = null;
    this.data.model = null;
    this.emitChange();
	},

  resetLoadedVehicle: function() {
    this.data.vehicleYears = [];
    this.data.primaryMakes = [];
    this.data.secondaryMakes = [];
    this.data.categories = [];
    this.data.models = [];
    this.data.trims = [];
    this.emitChange();
  },

	onVehicleInfoLoaded: function (payload) {

		this.data.vehicleId = payload.vehicleId;

		this.data.tireSizeInfo = _.map(payload.vehicleFullTireSizeInfo, function (tireInfo) {
			return {
				id: tireInfo.vehicleSubmodelTiresizeId,
				tireSizeSpecId: tireInfo.tireSizeSpecId,
				secondaryTireSizeSpecId: tireInfo.secondaryTireSizeSpecId,
				tireSize: tireInfo.formattedTireSize,
				secondaryTireSize: tireInfo.secondaryFormattedTireSize,
				sizeType: tireInfo.sizeTypeCode,
				seasonType: tireInfo.seasonalSizeCode
			};
		});
		this.data.originalTireSizeInfo = payload.vehicleOriginalTiresize;

		this.emitChange();
	},

	onVehicleTreeLoaded: function (payload) {
		this.data.fullVehicleSelectionTree = payload;

		this.data.vehicleYears = _.map(payload, function (yearObject) {
			return {
				id: yearObject['yearId'],
				value: yearObject['yearValue']
			};
		});

		if (this.data.year === null || typeof this.data.year === 'undefined') {
			if (typeof this.data.vehicleYears[0] !== 'undefined') {
				this.data.year = this.data.vehicleYears[0]['id'];
				applicationStore.data.year = this.data.vehicleYears[0]['id'];
			} else {
				this.data.year = applicationStore.data.year;
			}
		}

		var yearId = this.data.year, selectedYearIndex = null;
		this.data.vehicleYears.map(function (year, yearIndex) {
			if (year['id'] == yearId) {
				selectedYearIndex = yearIndex;
			}
		});

		this.data.categories = _.map(payload[selectedYearIndex]['yearCategories'], function (categoryObject) {
			return {
				id: categoryObject['vehicleCategoryId'],
				name: categoryObject['vehicleCategoryName'],
				order: categoryObject['vehicleCategorySortOrder']
			};
		});

		if (this.data.category === null || typeof this.data.category === 'undefined') {
			if (typeof this.data.categories[0] !== 'undefined') {
				this.data.category = this.data.categories[0]['id'];
				applicationStore.data.category = this.data.categories[0]['id'];
			} else {
				this.data.category = applicationStore.data.category;
			}
		}

		var categoryId = this.data.category, selectedCategoryIndex = null;
		this.data.categories.map(function (category, categoryIndex) {
			if (category['id'] == categoryId) {
				selectedCategoryIndex = categoryIndex;
			}
		});

    if (!isEmpty(this.data.category)) {
      this.data.models = _.map(payload[selectedYearIndex]['yearCategories'][selectedCategoryIndex]['vehicleCategoryModels'], function (modelObject) {
        return {
          id: modelObject['vehicleModelId'],
          name: modelObject['vehicleModelName'],
          order: modelObject['vehicleModelSortOrder']
        };
      });
    }

		if (!isEmpty(this.data.model)) {
			if (typeof this.data.models[0] !== 'undefined') {
				this.data.model = this.data.models[0]['id'];
				applicationStore.data.model = this.data.models[0]['id'];
			} else {
				this.data.model = applicationStore.data.model;
			}
		}

		this.emitChange();
	},

	vehicleYearSelected: function (yearId) {
		this.data.year = yearId;
		this.data.categories = [];
		var yearIndex = null;
		this.data.vehicleYears.map(function (yearObject, index) {
			if (yearObject['id'] == yearId) {
				yearIndex = index;
			}
		});

		this.data.categories = _.map(this.data.fullVehicleSelectionTree[yearIndex]['yearCategories'], function (yearObject) {
			return {
				id: yearObject['vehicleCategoryId'],
				name: yearObject['vehicleCategoryName'],
				order: yearObject['vehicleCategorySortOrder']
			};
		});
		this.data.category = this.data.categories[0]['id'];
		applicationStore.data.category = this.data.categories[0]['id'];

		this.data.models = _.map(this.data.fullVehicleSelectionTree[yearIndex]['yearCategories'][0]['vehicleCategoryModels'], function (modelObject) {
			return {
				id: modelObject['vehicleModelId'],
				name: modelObject['vehicleModelName'],
				order: modelObject['vehicleModelSortOrder']
			};
		});
		this.data.model = this.data.models[0]['id'];
		applicationStore.data.model = this.data.models[0]['id'];

		this.emitChange();
	},

	vehicleCategorySelected: function (categoryId) {
		this.data.category = categoryId;
		applicationStore.data.category = categoryId;
		var yearIndex = null;
		var yearId = this.data.year;
		this.data.vehicleYears.map(function (yearObject, index) {
			if (yearObject['id'] == yearId) {
				yearIndex = index;
			}
		});
		this.data.models = [];
		var categoryIndex = 0, categoryFound = false;
		this.data.categories.map(function (categoryObject) {
			if (categoryObject['id'] == categoryId) {
				categoryFound = true;
			} else if (!categoryFound) {
				categoryIndex++;
			}
		});

		this.data.models = _.map(this.data.fullVehicleSelectionTree[yearIndex]['yearCategories'][categoryIndex]['vehicleCategoryModels'], function (modelObject) {
			return {
				id: modelObject['vehicleModelId'],
				name: modelObject['vehicleModelName'],
				order: modelObject['vehicleModelSortOrder']
			};
		});
		this.data.model = this.data.models[0]['id'];
		applicationStore.data.model = this.data.models[0]['id'];
		this.emitChange();
	},

	vehicleModelSelected: function (modelId) {
		this.data.model = modelId;
		applicationStore.data.model = this.data.models[0]['id'];
		this.emitChange();
	},

	onVehicleYearsLoaded: function (payload) {
		this.data.vehicleYears = _.sortBy(_.map(payload, function (year, yearId) {
			return {
				id: yearId,
				value: year
			};
		}), 'value').reverse();

		this.data.primaryMakes = [];
		this.data.secondaryMakes = [];
		this.data.categories = [];
		this.data.models = [];

		this.emitChange();
	},

	onVehicleMakesLoaded: function (payload) {
		payload.primaryMakes = this.sortManufacturerPayload(payload.primaryMakes);

		function mapFn(make) {
			return {
				id: make.vehicleManufacturerId,
				name: make.vehicleManufacturerName,
				image: make.vehicleManufacturerImage
			};
		}

		this.data.primaryMakes = _.map(payload.primaryMakes, mapFn);
		this.data.secondaryMakes = _.map(payload.secondaryMakes, mapFn);

		this.data.categories = [];
		this.data.models = [];

		this.emitChange();
	},

	onVehicleCategoriesLoaded: function (payload) {
		this.data.categories = this.sortCategoryPayload(payload);
		this.data.models = [];
		this.emitChange();
	},

	onModelsLoaded: function (payload) {
		this.data.models = this.sortModelPayload(payload);
		this.emitChange();
	},

	onTrimsLoaded: function (payload) {
		function mapFn(trim) {
			return {
				id: trim.vehicleSubmodelId,
				name: trim.vehicleSubmodelName,
				image: trim.vehicleSubmodelImage,
				chassis: trim.vehicleChassisNumber,
				order: trim.vehicleSubmodelSortOrder,
				tiresizeArray: trim.vehicleSubmodelTiresizeArray,
				vehicleId: trim.vehicleId
			};
		}

		if (sizeOf(payload) === 1 && (!applicationStore.data.enableShowSubmodelTiresizeSelection || applicationStore.data.productType !== ProductTypes.TIRES)) {
      googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'Vehicle Select Submodel - SkipSubmodelSelection', [ payload[0].vehicleSubmodelId, "[" + payload[0].vehicleSubmodelName[getAppLanguage()] + "]" ].join('-'));
			setTimeout(() => { vehicleSelectionActions.trimSelected(payload[0].vehicleSubmodelId); }, 10);
    }

    payload = this.sortSubmodelPayload(payload);
		this.data.trims = _.map(payload, mapFn);
		this.emitChange();
	},

	sortManufacturerPayload: function (payload) {
		if (payload) {
			return payload.sort(function (a, b) {
				if (parseInt(a.vehicleManufacturerSortOrder, 10) < parseInt(b.vehicleManufacturerSortOrder, 10)) return -1;
				else if (parseInt(a.vehicleManufacturerSortOrder, 10) > parseInt(b.vehicleManufacturerSortOrder, 10)) return 1;
				else if (a.vehicleManufacturerName < b.vehicleManufacturerName) return -1;
        else if (a.vehicleManufacturerName > b.vehicleManufacturerName) return 1;
				else return 0;
			})
		} else {
			return payload;
		}
	},

	sortCategoryPayload: function (payload) {
		if (payload) {
			return payload.sort(function (a, b) {
				if (parseInt(a.vehicleCategorySortOrder, 10) < parseInt(b.vehicleCategorySortOrder, 10)) return -1;
				else if (parseInt(a.vehicleCategorySortOrder, 10) > parseInt(b.vehicleCategorySortOrder, 10)) return 1;
				else return 0;
			});
		} else {
			return payload;
		}
	},

  sortModelPayload: function (payload) {
    if (payload) {
    	var lang = getAppLanguage();
      var sortedList = payload.sort(function (a, b) {
        if (parseInt(a.vehicleModelSortOrder, 10) < parseInt(b.vehicleModelSortOrder, 10)) return -1;
        else if (parseInt(a.vehicleModelSortOrder, 10) > parseInt(b.vehicleModelSortOrder, 10)) return 1;
        else if (a.vehicleModelClassName[lang] < b.vehicleModelClassName[lang]) return -1;
        else if (a.vehicleModelClassName[lang] > b.vehicleModelClassName[lang]) return 1;
        else if (a.vehicleModelClassName[lang] < b.vehicleModelClassName[lang]) return -1;
        else if (a.vehicleModelClassName[lang] > b.vehicleModelClassName[lang]) return 1;
        else return 0;
      });
      return sortedList;
    } else {
      return payload;
    }
  },

  sortSubmodelPayload: function (payload) {
    if (payload) {
      var lang = getAppLanguage();
      var sortedList = payload.sort(function (a, b) {
        if (parseInt(a.vehicleSubmodelSortOrder, 10) < parseInt(b.vehicleSubmodelSortOrder, 10)) return -1;
        else if (parseInt(a.vehicleSubmodelSortOrder, 10) > parseInt(b.vehicleSubmodelSortOrder, 10)) return 1;
        else if (a.vehicleSubmodelName[lang] < b.vehicleSubmodelName[lang]) return -1;
        else if (a.vehicleSubmodelName[lang] > b.vehicleSubmodelName[lang]) return 1;
        else return 0;
      });
      return sortedList;
    } else {
      return payload;
    }
  },

	shouldShowSecondaryMakes: function () {
		return VehicleSelectionSkippingService.showSecondaryMakes(this.data.showSecondaryMakes, getProductTypeUpperCase());
	}
});

export var vehicleSelectionStore = new VehicleSelectionStore();

import { applicationStore } from "../../stores/ApplicationStore.js";

import { Modal } from "../ux/Modal.jsx";
import { renderSupportInfo } from "../../service/RenderService";
import { connect } from "react-redux";
import { toggleSupport } from "../../redux/features/applicationInfo.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");

class SupportPane extends I18nComponent {
  render() {
    if (!this.props.visible) {
      return (
        <supportbox>
          <img src="/static/img/support.png" onClick={this.onToggle} />
        </supportbox>
      );
    } else {
      return (
        <Modal refactor={true} requestUnmount={this.onToggle}>
          <div className="wrapper">
            <div className="modal-content">{renderSupportInfo()}</div>
          </div>
        </Modal>
      );
    }
  }

  onToggle = () => {
    // applicationActions.toggleSupport();
    this.props.dispatch(toggleSupport(!this.props.visible));
  };
}

function mapStateToProps(state) {
  return {
    visible: state.info.supportVisible,
  };
}
export default connect(mapStateToProps)(SupportPane);

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getApiBaseUrl } from "../../api/ApiService";
import { isUserLoggedIn } from "../../api/AuthService";
import { getAppLanguage } from "../../service/UtilityService";

export const forgotPassword = createAsyncThunk(
    'login/forgotpassword',
    async (arg,{getState})=>{
        let {tokenId} = getState().info;
        return fetch(`${getApiBaseUrl()}/retailSiteWebService/authentication/sendForgotPasswordEmail.php`,{
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({
                customerLanguage:getAppLanguage(),
                email:arg,
                isNational:0,
                tokenId:tokenId
            })
        })
        .then(res =>{
            if(!res.ok) throw Error(res.statusText)
            return res.json()
        })
        .then(json => json)
    }
)
export const login = createAsyncThunk(
    'login/userLogin',
    async (arg,{getState,dispatch})=>{
        let {tokenId} = getState().info;
        let {rememberMe} = getState().login
        return fetch(`${getApiBaseUrl()}/retailSiteWebService/authentication/getSecurityToken.php`,{
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({
                username:arg.username,
                password:arg.password,
                userIpAddress:arg.ip,
                isNational:0,
                rememberMe:rememberMe,
                tokenId:tokenId
            })
        })
        .then(res =>{
            if(!res.ok) {
                dispatch(onLoginFailure(true))
                throw Error(res.statusText)}
            return res.json()
        })
        .then(json => json)
    }
)

// logOut: function() {
//     this.apiGet('/authentication/terminateSecurityToken.php').then(function() {

//       var captchaicon = document.getElementsByClassName("grecaptcha-badge");
//       if (!isEmpty(captchaicon) && captchaicon[0]) {
//         captchaicon[0].className = "grecaptcha-badge";
//       }

//       this.dispatch(DispatcherActions.LOGOUT_SUCCESSFUL, {});
//     }.bind(this));
//   },

export const logOut = createAsyncThunk(
    'login/logoutInfo',
    async (arg,{getState})=>{
        let {tokenId} = getState().info;
        return fetch(`${getApiBaseUrl()}/retailSiteWebService/authentication/terminateSecurityToken.php`,{
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({
                tokenId:tokenId
            })
        })
        .then(res =>{
            if(!res.ok) throw Error(res.statusText)
            return res.json()
        })
        .then(json => json)
    } 
)
const loginInfo = createSlice({
    name:"loginInfo",
    initialState:{
        loginFailure:false,
        loginMsg:null,
        returnMessage:null,
        rememberMe:false,
    },
    reducers:{
        onLoginFailure:(state,{payload})=>{
            state.loginFailure = payload
        }
    },
    extraReducers:{
        [forgotPassword.pending]:(state,action)=>{
            state.status = 'loading';
        },
        [forgotPassword.fulfilled]:(state,{payload})=>{
            state.status = 'sucess'
            state.returnMessage = true;
            state.loginMsg = payload
        },
        [forgotPassword.rejected]:(state,{payload})=>{
            state.status = 'failed'
            state.returnMessage = false;
            state.loginMsg = payload
        },
        [login.pending]:(state,action)=>{
            state.loginStatus = 'loading'
        },

        [login.fulfilled]:(state,action)=>{
            isUserLoggedIn(true)
            state.loginStatus = true;
        },

        [login.rejected]:(state,action)=>{
            state.loginStatus = false;
        },
        [logOut.pending]:(state,action)=>{
            state.loginStatus = 'loading'
        },
        [logOut.fulfilled]:(state,action)=>{
            state.loginStatus = false
        },
        [logOut.rejected]:(state,action)=>{
            state.loginStatus = true
        }
    }
})

export const {onLoginFailure} = loginInfo.actions;
export default loginInfo.reducer;
module.exports = {
	'common': {
		'featuredItems': "Popular Items"
	},
	'productTypes': {
		'tireSelectText': 'Newer generations of winter tires are designed with a more flexible rubber compound that is less prone to stiffen in colder temperatures. Their tread is also designed to provide improved traction on wet, slushy, snowy, icy and dry cold surfaces.',
		'wheelSelectText': 'Whether you’re driving to work or traveling long distances, one thing is certain: Audi Genuine Winter Wheel Packages and Accessories are your perfect match to getting everywhere you want to go this winter.',
		'accessorySelectText': '',
		'packageSelectText': 'Audi Genuine Winter Wheel Packages and Accessories are your perfect match to getting everywhere you want to go this winter.'
	},
	'search': {
		'placeholderSearchTireSize': 'Enter your tire size: e.g. 245/40R18'
	},
	'launcher': {
		'enterTiresize': 'Enter your tire size: e.g. 245/40R18'
	}
};
import { FieldPrototype } from "./FieldPrototype.jsx";

var Validator = require("validator");

export class TextField extends FieldPrototype {
  static defaultProps = {
    errorNotEmpty: "The field is required therefore can't be empty",
    required: true,
  };

  getFieldType = () => {
    return "text";
  };

  isValid = (val) => {
    if (this.props.required) {
      var trimed = Validator.trim(val);

      if (this.props.required && Validator.equals(trimed, "")) {
        return [this.props.errorNotEmpty];
      } else {
        return [];
      }
    } else {
      return [];
    }
  };
}

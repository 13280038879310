import { connect } from "react-redux";

import { applicationStore } from "../../stores/ApplicationStore";
import { applicationActions } from "../../actions/ApplicationActions";
import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";
import { cartStore } from "../../stores/CartStore.js";
import _ from "lodash";

import { Modal } from "../ux/Modal.jsx";
import { SelectBox } from "../ux/SelectBox.jsx";
import { globalState } from "../../GlobalState.js";

import {
  sizeOf,
  isEmpty,
  getAppLanguage,
  getTrackingEventCategory,
  money_format,
} from "../../service/UtilityService";
import {
  getQuickActions,
  printQuote,
  downloadQuote,
  emailQuote,
  saveQuote,
} from "../../service/WishlistService.js";
import { renderNTDButton } from "../../service/OrderService";
import { isUserLoggedIn } from "../../api/AuthService";
import { getDestinationInverse } from "../../api/RequestBuilderHelper";
import { ProductTypes } from "../../constants/ProductTypes";
import { toggleCart } from "../../redux/features/shoppingCart";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var Immutable = require("immutable");

class CartModal extends I18nComponent {
  componentWillMount() {
    if (sizeOf(this.state.shoppingcart) > 0) {
      googleAnalyticsEnhancedTrackPageview("Shopping Cart");
      googleAnalyticsEnhancedTrackEvent(
        getTrackingEventCategory(),
        "Shopping Cart Access"
      );
    }
    globalState.select("applicationState").on("update", this.onChange);
    cartStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    cartStore.removeChangeListener(this.onChange);
  }

  state = {
    shoppingcart: this.props.shoppingCart ? this.props.shoppingCart : [],
  };

  render() {
    if (sizeOf(this.state.shoppingcart) > 0) {
      var translate = this.t,
        getQuantitySelect = this.getQuantitySelect,
        onRemoveItem = this.onRemoveItem,
        onAddLineItem = this.onAddLineItem,
        onRemoveLineItem = this.onRemoveLineItem;
      return (
        <Modal
          refactor={true}
          fullWidth={true}
          requestUnmount={this.onCloseModal}
        >
          <div className="wrapper">
            <box className="shoppingcartmodal">
              <box className="cartactions">
                {_.pairs(getQuickActions()).map(([index, action]) => {
                  if (action.enabled) {
                    switch (index) {
                      case "title":
                        return (
                          <column>
                            <h1 className="bold">{action.text}</h1>
                          </column>
                        );
                      case "priceMatch":
                        return false;
                      default:
                        return (
                          <column>
                            <button className="btn" onClick={action.event}>
                              {action.buttonText}
                            </button>
                          </column>
                        );
                    }
                  } else {
                    return null;
                  }
                })}
              </box>
              <box className="actions">
                <right>
                  <text className="pointer" onClick={printQuote}>
                    {this.t("wishlist.print")}
                  </text>
                </right>
              </box>
              <box className="cartitems">
                {Immutable.fromJS(this.state.shoppingcart.orderItemList).map(
                  function (item) {
                    var istire =
                      getDestinationInverse(item.get("itemType")) ==
                      ProductTypes.TIRES;
                    var brandlink = istire
                      ? item
                          .get("itemPart")
                          .get("partDetails")
                          .get("primaryPart")
                          .get("tireManufacturerImage")
                      : "";

                    var minimum,
                      oldprice = 0;
                    var temp = item
                      .get("itemPart")
                      .get("partPricing")
                      .get("primaryPart")
                      .get("price");
                    temp.map(function (context, index) {
                      if (context.get("quantity") == index) {
                        context.get("contextList").map(function (list) {
                          if (
                            item.get("pricingContextId") ==
                            list.get("pricingContextId")
                          ) {
                            oldprice = list.get("oldPrice");
                            minimum = list.get("marketMinimum");
                          }
                        });
                      }
                    });

                    var enableMinimumTag = !isUserLoggedIn() && minimum;
                    var enableMarketMinimumTag =
                      isUserLoggedIn() && parseFloat(oldprice) > 0;

                    //console.log(item);

                    //{enableMinimumTag ? <box><span className="text-small">{translate('marketMinimum')}</span></box> : false}

                    return [
                      <box>
                        <column className="image">
                          <container className="side no-left">
                            {istire ? (
                              <box className="crop">
                                <img
                                  className="main"
                                  src={item.get("itemImageLink")}
                                />
                                {!isEmpty(brandlink) ? (
                                  <img className="brand" src={brandlink} />
                                ) : (
                                  false
                                )}
                              </box>
                            ) : (
                              <img src={item.get("itemImageLink")} />
                            )}
                          </container>
                        </column>
                        <column className="info">
                          <container className="side">
                            <box>
                              <h3>
                                {item.get("itemTitle").get(getAppLanguage())}
                              </h3>
                            </box>
                            {istire ? (
                              <box>
                                <text>
                                  {item
                                    .get("itemDescription")
                                    .get(getAppLanguage())}
                                </text>
                              </box>
                            ) : (
                              false
                            )}
                            <box>
                              <text>
                                {translate("launcher.partNumber")}
                                {":"} {item.get("itemPartNumber")}
                              </text>
                            </box>
                          </container>
                        </column>
                        <column className="quantity">
                          <container className="side">
                            <text>{getQuantitySelect(item)}</text>
                            {enableMarketMinimumTag ? (
                              <box>
                                <span className="text-small">
                                  {translate("belowMarket")}{" "}
                                  {money_format(oldprice)}
                                </span>
                              </box>
                            ) : (
                              false
                            )}
                          </container>
                        </column>
                        <column className="total">
                          <container className="side no-right">
                            <box>
                              <text>
                                {money_format(item.get("rowPrice"))}
                                {enableMinimumTag ? "*" : false}
                              </text>
                            </box>
                            <box>
                              <text
                                className="bold text-small pointer"
                                onClick={onRemoveItem.bind(
                                  null,
                                  item.get("orderItemId")
                                )}
                              >
                                {translate("quote.remove")}
                              </text>
                            </box>
                          </container>
                        </column>
                      </box>,
                      <box>
                        {item
                          .get("mandatoryLineItemList")
                          .map(function (lineitem) {
                            return (
                              <box className="lineitems text-small">
                                <column className="image">
                                  <container className="side no-left"></container>
                                </column>
                                <column className="info">
                                  <container className="side">
                                    <box>
                                      <h3>
                                        {lineitem
                                          .get("title")
                                          .get(getAppLanguage())}
                                      </h3>
                                    </box>
                                    {!isEmpty(
                                      lineitem
                                        .get("description")
                                        .get(getAppLanguage())
                                    ) ? (
                                      <box>
                                        <text>
                                          {"("}
                                          {lineitem
                                            .get("description")
                                            .get(getAppLanguage())}
                                          {")"}
                                        </text>
                                      </box>
                                    ) : (
                                      <box className="spacing">
                                        <text></text>
                                      </box>
                                    )}
                                  </container>
                                </column>
                                <column className="quantity">
                                  <container className="side"></container>
                                </column>
                                <column className="total">
                                  <container className="side no-right">
                                    <box>
                                      <text>
                                        {money_format(lineitem.get("price"))}
                                      </text>
                                    </box>
                                  </container>
                                </column>
                              </box>
                            );
                          })}
                      </box>,
                      <box>
                        {item
                          .get("includedOptionalLineItemList")
                          .map(function (lineitem) {
                            return (
                              <box className="lineitems text-small">
                                <column className="image">
                                  <container className="side no-left"></container>
                                </column>
                                <column className="info">
                                  <container className="side">
                                    <box>
                                      <h3>
                                        {lineitem
                                          .get("title")
                                          .get(getAppLanguage())}
                                      </h3>
                                    </box>
                                    {!isEmpty(
                                      lineitem
                                        .get("description")
                                        .get(getAppLanguage())
                                    ) ? (
                                      <box>
                                        <text>
                                          {"("}
                                          {lineitem
                                            .get("description")
                                            .get(getAppLanguage())}
                                          {")"}
                                        </text>
                                      </box>
                                    ) : (
                                      false
                                    )}
                                  </container>
                                </column>
                                <column className="quantity">
                                  <container className="side"></container>
                                </column>
                                <column className="total">
                                  <container className="side no-right">
                                    <box>
                                      <text>
                                        {money_format(lineitem.get("price"))}
                                      </text>
                                    </box>
                                    <box>
                                      <text
                                        className="bold text-small pointer"
                                        onClick={onRemoveLineItem.bind(
                                          null,
                                          item.get("orderItemId"),
                                          lineitem.get("lineItemId")
                                        )}
                                      >
                                        {translate("quote.remove")}
                                      </text>
                                    </box>
                                  </container>
                                </column>
                              </box>
                            );
                          })}
                      </box>,
                      <box>
                        {item
                          .get("additionalOptionalLineItemList")
                          .map(function (lineitem) {
                            return (
                              <box className="lineitems greyed text-small">
                                <column className="image">
                                  <container className="side no-left"></container>
                                </column>
                                <column className="info">
                                  <container className="side">
                                    <box>
                                      <h3>
                                        {lineitem
                                          .get("title")
                                          .get(getAppLanguage())}
                                      </h3>
                                    </box>
                                    {!isEmpty(
                                      lineitem
                                        .get("description")
                                        .get(getAppLanguage())
                                    ) ? (
                                      <box>
                                        <text>
                                          {"("}
                                          {lineitem
                                            .get("description")
                                            .get(getAppLanguage())}
                                          {")"}
                                        </text>
                                      </box>
                                    ) : (
                                      false
                                    )}
                                  </container>
                                </column>
                                <column className="quantity">
                                  <container className="side"></container>
                                </column>
                                <column className="total">
                                  <container className="side no-right">
                                    <box>
                                      <text>
                                        {money_format(lineitem.get("price"))}
                                      </text>
                                    </box>
                                    <box>
                                      <text
                                        className="bold text-small pointer"
                                        onClick={onAddLineItem.bind(
                                          null,
                                          item.get("orderItemId"),
                                          lineitem.get("lineItemId")
                                        )}
                                      >
                                        {translate("quote.addToQuote")}
                                      </text>
                                    </box>
                                  </container>
                                </column>
                              </box>
                            );
                          })}
                      </box>,
                    ];
                  }
                )}
              </box>
              {this.renderPromotionSavings()}
              <box className="totals">
                <column>
                  <column>
                    <container className="side small no-left">
                      <text className="pointer select" onClick={printQuote}>
                        {this.t("wishlist.print")}
                      </text>
                    </container>
                  </column>
                  <column>
                    <container className="side small">
                      <text className="pointer select" onClick={downloadQuote}>
                        {this.t("wishlist.download")}
                      </text>
                    </container>
                  </column>
                  <column>
                    <container className="side small">
                      <text className="pointer select" onClick={emailQuote}>
                        {this.t("quote.emailFieldLabel")}
                      </text>
                    </container>
                  </column>
                  {isUserLoggedIn() ? (
                    <column>
                      <container className="side small">
                        <text className="pointer select" onClick={saveQuote}>
                          {this.t("wishlist.save")}
                        </text>
                      </container>
                    </column>
                  ) : (
                    false
                  )}
                </column>
                <right className="totalbox">
                  <box>
                    <box className="fixheight">
                      <column>
                        <text>
                          {this.t("shoppingCartView.subtotal")}
                          {":"}
                        </text>
                      </column>
                      <right>
                        <text>
                          {money_format(this.state.shoppingcart.orderSubtotal)}
                        </text>
                      </right>
                    </box>
                    <box className="fixheight">
                      <column>
                        <text>
                          {this.t("shoppingCartView.tax").replace(
                            "[]",
                            parseFloat(
                              this.state.shoppingcart.orderTaxPercent
                            ).toFixed(getAppLanguage() == "fr" ? 3 : 0)
                          )}
                          {":"}
                        </text>
                      </column>
                      <right>
                        <text>
                          {money_format(this.state.shoppingcart.orderTaxTotal)}
                        </text>
                      </right>
                    </box>
                    <box className="fixheight">
                      <column>
                        <text>
                          {this.t("shoppingCartView.total")}
                          {":"}
                        </text>
                      </column>
                      <right>
                        <text>
                          {money_format(
                            this.state.shoppingcart.orderGrandTotal
                          )}
                        </text>
                      </right>
                    </box>
                  </box>
                </right>
              </box>
              <box style={{ width: "200px" }}>{this.renderNtdOrder()}</box>
            </box>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  }

  renderNtdOrder = () => {
    var ntdOrderStockEnabled = false,
      list = this.state.shoppingcart.orderItemList;
    for (var i in list) {
      if (list[i].supportDealerOrder) {
        ntdOrderStockEnabled = true;
        break;
      }
    }

    if (
      ntdOrderStockEnabled &&
      applicationStore.data.dealerAllowNtdStockOrder
    ) {
      return renderNTDButton(this.onOpenNTDOrderModal, "order");
    } else {
      return false;
    }
  };

  onOpenNTDOrderModal = () => {
    applicationActions.onOpenNtdModal();
  };

  renderPromotionSavings = () => {
    var enable = false,
      count = 0,
      savings = 0,
      title = "",
      list = this.state.shoppingcart.orderItemList;
    for (var i in list) {
      if (list[i] && !isEmpty(list[i]["itemPromotion"][getAppLanguage()])) {
        if (!enable) {
          enable = parseFloat(list[i]["itemSavings"]) > 0;
        }
        savings +=
          parseFloat(list[i]["itemSavings"]) * parseInt(list[i]["itemQty"]);
        count++;
        if (isEmpty(title)) {
          title = list[i]["itemPromotion"][getAppLanguage()];
        } else {
          title = this.t("promotions");
        }
      }
    }

    if (enable && savings > 0) {
      return (
        <box className="promotion">
          <text>
            {this.t("youHaveSaved")} {money_format(savings)} {this.t("through")}{" "}
            {count > 1 ? count + " " + title : title}
          </text>
        </box>
      );
    } else {
      return false;
    }
  };

  getQuantitySelect = (item) => {
    var temp = [],
      quantity = parseInt(item.get("itemQty")),
      list = item
        .get("itemPart")
        .get("partPricing")
        .get("primaryPart")
        .get("price"),
      onSelectQuantity = this.onSelectQuantity.bind(null, item);

    list.map(function (price) {
      price.get("contextList").map(function (context) {
        temp.push({
          key: price.get("quantity"),
          contextId: context.get("pricingContextId"),
          displayPrice: context.get("displayPrice"),
          value:
            price.get("quantity") +
            (context.get("pricingContextId") > 0
              ? " - " + context.get("promotionText").get(getAppLanguage())
              : ""),
        });
      });
    });

    temp = temp.sort(function (a, b) {
      if (parseInt(a.key) < parseInt(b.key)) return -1;
      else if (parseInt(a.key) > parseInt(b.key)) return 1;
      else return 0;
    });

    return (
      <SelectBox
        selected={quantity}
        dataList={temp}
        onSelect={onSelectQuantity}
        getObject={true}
        displayKey={true}
      />
    );
  };

  onSelectQuantity = (item, quantity) => {
    var vehicleId = 0;
    if (sizeOf(this.state.shoppingcart.vehicleInformation) == 1) {
      vehicleId = this.state.shoppingcart.vehicleInformation[0]["vehicleId"];
    }
    shoppingCartActions.updateCartItem(
      item.get("orderItemId"),
      quantity.get("key"),
      quantity.get("contextId"),
      item.get("optionId"),
      vehicleId
    );
  };

  onRemoveItem = (orderItemId) => {
    shoppingCartActions.removefromCart(orderItemId);
  };

  onAddLineItem = (orderItemId, lineItemId) => {
    shoppingCartActions.addLineItemToCart(orderItemId, lineItemId);
  };

  onRemoveLineItem = (orderItemId, lineItemId) => {
    shoppingCartActions.removeLineItemFromCart(orderItemId, lineItemId);
  };

  onCloseModal = () => {
    this.props.toggleCart(false);
  };

  onChange = () => {
    this.setState({
      shoppingcart: cartStore.data.shoppingcart,
    });
  };
}

function mapStateToProps(state) {
  return {
    state,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    toggleCart: (toggleType) => dispatch(toggleCart(toggleType)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CartModal);

import { dispatcher } from '../dispatcher/Dispatcher.js'

var EventEmitter = require('events').EventEmitter;
var Base = require('backbone-extend-node');

/**
 * Abstract store implementation, this class has two goals:
 * - Implements handlers for dispatcher actions
 * - Registers change events
 *
 * @abstract
 */
export var AbstractStore = Base.extend({
  constructor: function() {
    this.emitter = this.buildEmitter();
    this.assignAllHandlers();
  },

  /**
   * Actuall magic responsible for assigning all handlers to corresponding dispatcher actions
   */
  assignAllHandlers: function() {
    var actionHandlers = this.getActionHandlers();
    dispatcher.register(function(action) {
      if (actionHandlers[action.actionType]) {
        actionHandlers[action.actionType].call(this, action.payload);
      }
    }.bind(this));
  },

  /**
   * This is not in const as we use this for testing
   * @access private
   * @visibleForTesting
   */
  getChangeEvent: function() {
    return 'change';
  },

  /**
   * We should seperate it for easy testing
   * @access private
   * @visibleForTesting
   */
  buildEmitter: function() {
    var eventEmitter = new EventEmitter();
    eventEmitter.setMaxListeners(0);

    return eventEmitter;
  },

  emitChange: function() {
    this.emitter.emit(this.getChangeEvent(), this);
  },

  addChangeListener: function(listener) {
    this.emitter.on(this.getChangeEvent(), listener);
  },

  /**
  * It's essential to remove listeners to prevent memory leaks
  */
  removeChangeListener: function(listener) {
    this.emitter.removeListener(this.getChangeEvent(), listener);
  },

  removeAllListeners: function() {
    this.emitter.removeAllListeners(this.getChangeEvent());
  }
});

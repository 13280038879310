import { applicationStore } from "../../stores/ApplicationStore.js";
import { dealerSelectionStore } from "../../stores/DealerSelectionStore";
import { applicationActions } from "../../actions/ApplicationActions";
import { homepageStore } from "../../stores/HomepageStore.js";
import { languageStore } from "../../stores/LanguageStore.js";

import { Image } from "../ux/Image.jsx";
import TopLauncher from "./TopLauncher.jsx";
import ProductTypeTile from "./ProductTypeTile.jsx";
import ProductTypeSelector from "./ProductTypeSelector.jsx";
import { ProductTypeTilePadded } from "./ProductTypeTilePadded.jsx";

import {
  renderCarousel,
  renderYouTubeVideo,
} from "../../service/RenderService";
import {
  sizeOf,
  isEmpty,
  getTrackingEventCategory,
  isDealerSite,
  isNationalSite,
  isGroupSite,
  getAppLanguage,
  isMobile,
  nationalSiteHasDealerSelected,
  getAppTemplate,
} from "../../service/UtilityService";
import { connect } from "react-redux";
import { toggleSearch } from "../../redux/features/applicationInfo";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var Immutable = require("immutable");
var Cx = require("classnames");

class Landingpage extends I18nComponent {
  state = {
    carouselItems: applicationStore.data.carouselItems,
    featuredItems: applicationStore.data.featuredItems,
    carousel: applicationStore.data.carousel,

    tireBrandList: applicationStore.data.nationalAccountTireBrands,
    manufacturerList:
      applicationStore.data.dealerGroupPrimaryVehicleManufacturerList,
    selectedManufacturerId: null,

    carouselIndex: 0,
    lastCarouselIndex: 0,

    switchingFunc: function () {},
    allowSwitching: true,

    launcheryearselect: false,
    openOnDataLoad: false,

    initialize: false,
  };

  isMounted = false;

  updateState = (state) => {
    if (this.isMounted) {
      this.setState(state);
    }
  };

  componentWillUnmount() {
    this.isMounted = false;
    clearInterval(this.state.switchingFunc);
    this.setState({ switchingFunc: function () {} });
    homepageStore.removeChangeListener(this.onChange);
    languageStore.removeChangeListener(this.onChange);
  }

  onChange = () => {
    if (!this.isMounted) return;
    this.setState({
      carouselItems: applicationStore.data.carouselItems,
      featuredItems: applicationStore.data.featuredItems,
      carousel: applicationStore.data.carousel,
      tireBrandList: applicationStore.data.nationalAccountTireBrands,
    });
  };

  componentDidMount() {
    this.isMounted = true;
    googleAnalyticsEnhancedTrackPageview("Landingpage");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Landingpage Access"
    );
    homepageStore.addChangeListener(this.onChange);
    languageStore.addChangeListener(this.onChange);
    this.onChange();
    this.runCarouselSwitching();
    var updateState = this.updateState;
    setTimeout(() => {
      updateState({ initialize: true });
    }, 1000);
    if (this.startWithSearch()) {
      setTimeout(() => {
        applicationActions.toggleSearchFlag();
      }, 250);
    }
    if (
      getAppTemplate() === "mercedes" ||
      getAppTemplate() === "mercedestransition" ||
      getAppTemplate() === "mercedes_2022"
    ) {
      try {
        gtag("event", "conversion", {
          allow_custom_scripts: true,
          send_to: "DC-951733/tires0/tires004+standard",
        });
      } catch (err) {
        console.log(err.message);
      }

      document.body.insertAdjacentHTML(
        "beforebegin",
        "<noscript><img src='https://ad.doubleclick.net/ddm/activity/src=951733;type=tires0;cat=tires004;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?' width='1' height='1' alt=''/></noscript>"
      );
    }
  }

  startWithSearch = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mazda":
        return isMobile();
      default:
        return false;
    }
  };

  setYearSelect = (select) => {
    this.setState({
      launcheryearselect: select,
    });
  };

  updateCount = () => {
    if (this.state.allowSwitching && this.isMounted) {
      var lastIndex = this.state.carouselIndex,
        index = this.state.carouselIndex + 1,
        decodeIndex = this.decodeIndex;
      this.setState({
        carouselIndex: decodeIndex(index),
        lastCarouselIndex: lastIndex,
      });
    }
  };

  decodeIndex = (index) => {
    if (index < 0) index = sizeOf(this.state.carouselItems) - 1;
    else if (index > sizeOf(this.state.carouselItems) - 1) index = 0;
    return index;
  };

  runCarouselSwitching = () => {
    this.setState({
      switchingFunc: (() => {
        setInterval(() => {
          this.updateCount();
        }, 5000);
      })(),
    });
  };

  render = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "audi_2021":
        return this.renderLandingpageAudi();
      case "hyundai":
        return this.renderLandingpageHyundai();
      case "mazda":
        return this.renderLandingpageReduced();
      case "mercedes_2022":
        return this.renderLandingpageMercedes();
      case "volkswagen_2020":
        return this.renderLandingpageVolkswagen();
      default:
        return this.renderLandingpage();
    }
  };

  renderLandingpage = () => {
    var landingClass = Cx({
      landingpage: true,
      main: true,
      "no-full-width-carousel":
        applicationStore.data.homepageLauncherLockToPage,
    });

    return (
      <box className={landingClass}>
        <box className="container">
          {this.renderGroupManufacturerList()}
          {this.renderCarouselSection()}
          {this.renderBMWCarousel()}
          {this.renderMiniCarousel()}
          {this.renderFeatured()}
        </box>
      </box>
    );
  };

  renderLandingpageAudi = () => {
    var homepageClass = Cx({
      homepage: true,
      main: true,
      "has-launcher":
        applicationStore.data.enableHomepageLauncher &&
        applicationStore.data.homepageLauncherFloatOverCarousel === "TOP",
    });

    return (
      <box className={homepageClass}>
        <box className="container">
          {this.renderCarousel()}
          <TopLauncher landingpage={true} />
          <box className="home-images">
            <container className="large">
              <box style={{ marginTop: "60px", marginBottom: "40px" }}>
                <h1
                  style={{
                    fontSize: "35px",
                    lineHeight: "40px",
                    marginBottom: "10px",
                  }}
                >
                  {this.t("homepage_title_1")}
                </h1>
                <h1 style={{ fontSize: "30px" }}>
                  {this.t("homepage_title_2")}
                </h1>
              </box>
              <box style={{ marginBottom: "20px" }}>
                <span>{this.t("homepage_subtitle")}</span>
              </box>
              <box>
                <column
                  className="third pointer"
                  onClick={this.clickAlloyWheels}
                >
                  <box>
                    <img src="/static/img/audi_2021/wheel_image.png" />
                  </box>
                  <box style={{ marginBottom: "20px" }}>
                    <h2>{this.t("homepage_wheel_title")}</h2>
                  </box>
                  <box
                    style={{
                      margin: "0 auto 20px auto",
                      minHeight: "150px",
                      maxWidth: "80%",
                    }}
                  >
                    <span>{this.t("homepage_wheel_subtitle")}</span>
                  </box>
                  <box style={{ marginBottom: "20px" }}>
                    <button style={{ background: "none", border: "none" }}>
                      <img
                        style={{ width: "30px", margin: "0 auto 10px auto" }}
                        src="/static/img/audi_2021/search_icon.png"
                      />
                      {this.t("homepage_wheel_button")}
                    </button>
                  </box>
                </column>
                <column className="third pointer" onClick={this.clickTires}>
                  <box>
                    <img src="/static/img/audi_2021/tire_image.png" />
                  </box>
                  <box style={{ marginBottom: "20px" }}>
                    <h2>{this.t("homepage_tire_title")}</h2>
                  </box>
                  <box
                    style={{
                      margin: "0 auto 20px auto",
                      minHeight: "150px",
                      maxWidth: "80%",
                    }}
                  >
                    <span>{this.t("homepage_tire_subtitle")}</span>
                  </box>
                  <box style={{ marginBottom: "20px" }}>
                    <button style={{ background: "none", border: "none" }}>
                      <img
                        style={{ width: "30px", margin: "0 auto 10px auto" }}
                        src="/static/img/audi_2021/search_icon.png"
                      />
                      {this.t("homepage_tire_button")}
                    </button>
                  </box>
                </column>
                <column className="third pointer" onClick={this.clickPackages}>
                  <box>
                    <img src="/static/img/audi_2021/package_image.png" />
                  </box>
                  <box style={{ marginBottom: "20px" }}>
                    <h2>{this.t("homepage_package_title")}</h2>
                  </box>
                  <box
                    style={{
                      margin: "0 auto 20px auto",
                      minHeight: "150px",
                      maxWidth: "80%",
                    }}
                  >
                    <span>{this.t("homepage_package_subtitle")}</span>
                  </box>
                  <box style={{ marginBottom: "20px" }}>
                    <button style={{ background: "none", border: "none" }}>
                      <img
                        style={{ width: "30px", margin: "0 auto 10px auto" }}
                        src="/static/img/audi_2021/search_icon.png"
                      />
                      {this.t("homepage_package_button")}
                    </button>
                  </box>
                </column>
              </box>
            </container>
          </box>
        </box>
      </box>
    );
  };

  renderBMWCarousel = () => {
    if (this.enableBMWCarousel()) {
      return (
        <box>
          <box>
            <column className="half">
              <box>
                <container className="xxlarge">
                  <box>
                    <container className="top">
                      <h1>{this.t("homepage_tire_title")}</h1>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <h3>{this.t("homepage_tire_subtitle")}</h3>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <text>{this.t("homepage_tire_text")}</text>
                    </container>
                  </box>
                </container>
              </box>
            </column>
            <column className="half">
              <box>
                <img
                  style={{ width: "100%" }}
                  src="/static/img/bmw/tires.png"
                />
              </box>
            </column>
          </box>

          <box>
            <column className="half">
              <box>
                <img
                  style={{ width: "100%" }}
                  src="/static/img/bmw/wheels.png"
                />
              </box>
            </column>
            <column className="half">
              <box>
                <container className="xxlarge">
                  <box>
                    <container className="top">
                      <h1>{this.t("homepage_wheel_title")}</h1>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <h3>{this.t("homepage_wheel_subtitle")}</h3>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <text>{this.t("homepage_wheel_text")}</text>
                    </container>
                  </box>
                </container>
              </box>
            </column>
          </box>
        </box>
      );
    } else {
      return false;
    }
  };

  renderMiniCarousel = () => {
    if (this.enableMiniCarousel()) {
      return (
        <box>
          <box>
            <column className="half">
              <box>
                <img
                  src="/static/img/mini/tires.jpg"
                  style={{ width: "90%" }}
                />
              </box>
            </column>
            <column className="half">
              <box>
                <container className="xxlarge">
                  <box>
                    <container className="top">
                      <h1 style={{ fontSize: "34px" }}>
                        {this.t("homepage_tire_title")}
                      </h1>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <text>{this.t("homepage_tire_text")}</text>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <button className="btn" onClick={this.onToggleSearch}>
                        {this.t("shop_tires")}
                      </button>
                    </container>
                  </box>
                </container>
              </box>
            </column>
          </box>

          <box>
            <column className="half">
              <box>
                <container className="xxlarge">
                  <box>
                    <container className="top">
                      <h1 style={{ fontSize: "34px" }}>
                        {this.t("homepage_wheel_title")}
                      </h1>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <text>{this.t("homepage_wheel_text")}</text>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <button className="btn" onClick={this.onToggleSearch}>
                        {this.t("shop_wheels")}
                      </button>
                    </container>
                  </box>
                </container>
              </box>
            </column>
            <column className="half">
              <box>
                <img
                  src="/static/img/mini/wheels.jpg"
                  style={{ width: "90%", float: "right" }}
                />
              </box>
            </column>
          </box>

          <box>
            <column className="half">
              <box>
                <img
                  src="/static/img/mini/packages.jpg"
                  style={{ width: "90%" }}
                />
              </box>
            </column>
            <column className="half">
              <box>
                <container className="xxlarge">
                  <box>
                    <container className="top">
                      <h1 style={{ fontSize: "34px" }}>
                        {this.t("homepage_package_title")}
                      </h1>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <text>{this.t("homepage_package_text")}</text>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <button className="btn" onClick={this.onToggleSearch}>
                        {this.t("shop_packages")}
                      </button>
                    </container>
                  </box>
                </container>
              </box>
            </column>
          </box>
        </box>
      );
    } else {
      return false;
    }
  };

  renderLandingpageVolkswagen = () => {
    var landingClass = Cx({
      landingpage: true,
      main: true,
      "no-full-width-carousel":
        applicationStore.data.homepageLauncherLockToPage,
    });

    return (
      <box className={landingClass}>
        <box className="container">
          {this.renderCarousel()}
          <TopLauncher landingpage={true} />
          <box className="home-images">
            <box>
              <container className="side large">
                <column className="half">
                  <container>
                    <box>
                      <container>
                        <img src="/static/img/volkswagen_2020/wheel_landing_page.jpg" />
                      </container>
                    </box>
                    <box>
                      <box>
                        <box>
                          <container>
                            <h2>
                              {this.t("onTheRoad")}{" "}
                              <text className="bold">
                                {this.t("withCompleteWheels")}
                                {":"}
                              </text>
                            </h2>
                          </container>
                        </box>
                        <box>
                          <container>
                            <text className="light-bold">
                              {this.t("needNewWheels")}
                            </text>
                          </container>
                        </box>
                      </box>
                    </box>
                    <box>
                      <container>
                        {isDealerSite() || nationalSiteHasDealerSelected() ? (
                          <text onClick={this.onToggleSearch}>
                            <a className="search-text bold">
                              {this.t("searchNow")}
                            </a>
                            <i className="fa fa-arrow-right" />
                          </text>
                        ) : (
                          false
                        )}
                      </container>
                    </box>
                  </container>
                </column>
                <column className="half">
                  <container>
                    <box>
                      <container>
                        <img src="/static/img/volkswagen_2020/vw_tires.jpg" />
                      </container>
                    </box>
                    <box>
                      <box>
                        <container>
                          <h2>
                            {this.t("searchText")}{" "}
                            <text className="bold">{this.t("forTires")}</text>
                          </h2>
                        </container>
                      </box>
                      <box>
                        <container>
                          <text className="light-bold">
                            {this.t("youreTiresAre")}
                          </text>
                        </container>
                      </box>
                    </box>
                    <box>
                      <box>
                        <container>
                          <h2>
                            {this.t("tireAndWheel")}{" "}
                            <text className="bold">
                              {this.t("productTypes.packages")}
                            </text>
                          </h2>
                        </container>
                      </box>
                      <box>
                        <container>
                          <text className="light-bold">
                            {this.t("rememberToBuy")}
                          </text>
                        </container>
                      </box>
                    </box>
                  </container>
                </column>
              </container>
            </box>
            <box className="first-images">
              <column className="third">
                <img src="/static/img/volkswagen_2020/woman_cellphone_car.jpg" />
              </column>
              <column className="two-thirds">
                <img src="/static/img/volkswagen_2020/highwat_landingpage.jpg" />
              </column>
            </box>
            <box className="second-images">
              <container className="side xlarge">
                <column className="two-thirds">
                  <img src="/static/img/volkswagen_2020/kneeling_landingpage.jpg" />
                </column>
                <column className="third">
                  <img src="/static/img/volkswagen_2020/steering_wheel_langinpage.jpg" />
                </column>
              </container>
            </box>
          </box>
        </box>
      </box>
    );
  };

  renderLandingpageHyundai = () => {
    var landingClass = Cx({
      landingpage: true,
      main: true,
    });

    return (
      <box className={landingClass}>
        <box className="container">
          {this.renderCarousel()}
          <box>
            <TopLauncher bottomlauncher={true} />
          </box>
          <box className="textbox">
            <box>
              <container>
                <h2>{this.t("packagestitle")}</h2>
              </container>
            </box>
            <box>
              <container>
                <box>
                  <container className="side xlarge">
                    <text>{this.t("packagessubtitle")}</text>
                  </container>
                </box>
              </container>
            </box>
          </box>
        </box>
      </box>
    );
  };
  renderLandingpageMercedes = () => {
    var landingClass = Cx({
      landingpage: true,
      main: true,
      "no-full-width-carousel":
        applicationStore.data.homepageLauncherLockToPage,
    });
    return (
      <box className={landingClass}>
        <box className="container">
          {this.renderGroupManufacturerList()}

          {this.renderCarouselSection()}

          {this.renderBMWCarousel()}

          {this.renderMiniCarousel()}

          {this.renderFeatured()}
        </box>
      </box>
    );
  };

  renderLandingpageReduced = () => {
    var imageclass = Cx({
      unblur: this.state.initialize,
    });
    var floatclass = Cx({
      floattext: true,
      show: this.state.initialize,
    });

    return (
      <div className="main homepage" id="homepage">
        <div className="container">
          <box style={{ position: "relative" }}>
            <box className="image">
              <img className={imageclass} src="/static/img/mazda/main.png" />
            </box>

            <box className={floatclass}>
              <box style={{ position: "relative" }}>
                <box>
                  <h1>{this.t("reducedHomepageTitle").toUpperCase()}</h1>
                </box>
                <box className="textbox">
                  <text>{this.t("reducedHomepageText")}</text>
                </box>
                <box>
                  <button className="btn" onClick={this.onToggleSearch}>
                    {this.t("reducedHomepageButton").toUpperCase()}
                  </button>
                </box>
              </box>
            </box>
          </box>

          {this.renderSecondaryGroupManufacturerList()}
        </div>
      </div>
    );
  };

  onToggleSearch = () => {
    if (
      applicationStore.data.enableMazdaFeatures ||
      !isNationalSite() ||
      nationalSiteHasDealerSelected()
    ) {
      this.props.dispatch(toggleSearch(true));
      applicationActions.toggleSearchFlag();
    } else if (applicationStore.data.defaultTemplate === "mini") {
      this.setState({ openOnDataLoad: true });
      scrollTop();
    }
  };

  renderCarouselSection = () => {
    return (
      <box className="carousel-section">
        {this.renderHomepageText()}
        {this.renderTopLauncher()}
        {this.renderCarousel()}
        {this.renderEnhancedCarousel()}
        {this.renderProductTileSelection()}
        {this.renderTireBrandSection()}
      </box>
    );
  };

  renderEnhancedCarousel = () => {
    if (this.enableEnhancedCarousel()) {
      return (
        <box className="enhanced-carousel">
          <box>
            <container className="top">
              <h1>{this.t("product.approvedTires")}</h1>
            </container>
          </box>
          <box>{this.renderEnhancedVideo()}</box>
          <box>
            <container>
              <column className="half">
                <box>
                  <container className="top">
                    <h2 className="bold">
                      {this.t("homepage_yourfirstchoice")}
                    </h2>
                  </container>
                </box>
                <box>
                  <text>{this.t("homepage_long_4")}</text>
                </box>
              </column>
              <column className="half">
                <container className="top">
                  <img src="https://www.mercedes-benz.ca/content/dam/mb-nafta/ca/owners/genuineparts/wheels-tires/Keyvisual_Reifen_V09_01_cmyk_690x450.jpg" />
                </container>
              </column>
            </container>
          </box>
          <box>
            <container className="top">
              <h1>{this.t("homepage_genuinewheels")}</h1>
            </container>
          </box>
          <box>
            <container className="top">
              <column className="third">
                <container>
                  <box>
                    <container className="top">
                      <img src="/static/img/mercedes/homepage2.png" />
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <h2 className="bold">
                        {this.t("homepage_wheeldevelopment")}
                      </h2>
                    </container>
                  </box>
                  <box>
                    <text>{this.t("homepage_long_1")}</text>
                  </box>
                </container>
              </column>
              <column className="third">
                <container>
                  <box>
                    <container className="top">
                      <img src="/static/img/mercedes/homepage3.png" />
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <h2 className="bold">{this.t("homepage_design")}</h2>
                    </container>
                  </box>
                  <box>
                    <text>{this.t("homepage_long_2")}</text>
                  </box>
                </container>
              </column>
              <column className="third">
                <container>
                  <box>
                    <container className="top">
                      <img src="/static/img/mercedes/homepage4.png" />
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <h2 className="bold">{this.t("homepage_quality")}</h2>
                    </container>
                  </box>
                  {this.renderListOrOrganizer()}
                </container>
              </column>
            </container>
          </box>
          {this.renderAdditionalMercedesContent()}
        </box>
      );
    } else {
      return false;
    }
  };
  renderMercedesImage = (
    url,
    url2,
    isExternalUrl = false,
    typeOfMercedes = "mercedes"
  ) => {
    if (typeOfMercedes === "mercedes") {
      if (isExternalUrl) {
        return url;
      }
      return `/static/img/${typeOfMercedes}/${url}`;
    } else {
      return `/static/img/${typeOfMercedes}/${url2}`;
    }
  };
  renderListOrOrganizer = () => {
    if (applicationStore.data.defaultTemplate === "mercedes_2022") {
      return (
        <box>
          <container className="top">
            <text>{this.t("homepage_long_4")}</text>
          </container>
        </box>
      );
    } else {
      return (
        <box>
          <box>
            <column style={{ width: "30px" }}>
              <text>{"1."}</text>
            </column>
            <column style={{ width: "calc(100% - 30px)" }}>
              <text>{this.t("homepage_long_3_a")}</text>
            </column>
          </box>
          <box>
            <column style={{ width: "30px" }}>
              <text>{"2."}</text>
            </column>
            <column style={{ width: "calc(100% - 30px)" }}>
              <text>{this.t("homepage_long_3_b")}</text>
            </column>
          </box>
          <box>
            <column style={{ width: "30px" }}>
              <text>{"3."}</text>
            </column>
            <column style={{ width: "calc(100% - 30px)" }}>
              <text>{this.t("homepage_long_3_c")}</text>
            </column>
          </box>
          <box>
            <column style={{ width: "30px" }}>
              <text>{"4."}</text>
            </column>
            <column style={{ width: "calc(100% - 30px)" }}>
              <text>{this.t("homepage_long_3_d")}</text>
            </column>
          </box>
          <box>
            <column style={{ width: "30px" }}>
              <text>{"5."}</text>
            </column>
            <column style={{ width: "calc(100% - 30px)" }}>
              <text>{this.t("homepage_long_3_e")}</text>
            </column>
          </box>
        </box>
      );
    }
  };
  renderAdditionalMercedesContent = () => {
    if (applicationStore.data.defaultTemplate === "mercedes_2022") {
      return (
        <box>
          <box>
            <container className="top">
              <h1>{this.t("homepage_wrapup")}</h1>
            </container>
          </box>

          <box>
            <container>
              <column className="half">
                <container className="half large">
                  <img src="/static/img/mercedes_2022/homepage5.jpg" />
                </container>
              </column>
              <column className="half right" style={{ maxWidth: "600px" }}>
                <box>
                  <container className="top">
                    <h2 className="bold">{this.t("time_to_change")}</h2>
                  </container>
                </box>
                <box>
                  <text>{this.t("homepage_long_5")}</text>
                </box>
              </column>
            </container>
          </box>
          <box>
            <container className="top">
              <h1>{this.t("homepage_why_mercedes")}</h1>
            </container>

            <container className="full-width right">
              <img
                src="/static/img/mercedes_2022/homepage6.jpg"
                style={{ width: "100%", margin: "0 auto" }}
              />
            </container>
            <container>
              <column className="half">
                <box>
                  <column style={{ width: "30px" }}>
                    <text>{"1."}</text>
                  </column>
                  <column style={{ width: "calc(100% - 30px)" }}>
                    <text>{this.t("homepage_long_3_a")}</text>
                  </column>
                </box>
                <box>
                  <column style={{ width: "30px" }}>
                    <text>{"2."}</text>
                  </column>
                  <column style={{ width: "calc(100% - 30px)" }}>
                    <text>{this.t("homepage_long_3_b")}</text>
                  </column>
                </box>
                <box>
                  <column style={{ width: "30px" }}>
                    <text>{"3."}</text>
                  </column>
                  <column style={{ width: "calc(100% - 30px)" }}>
                    <text>{this.t("homepage_long_3_c")}</text>
                  </column>
                </box>
              </column>
              <column className="half right">
                <box>
                  <column style={{ width: "30px" }}>
                    <text>{"4."}</text>
                  </column>
                  <column style={{ width: "calc(100% - 30px)" }}>
                    <text>{this.t("homepage_long_3_d")}</text>
                  </column>
                </box>
                <box>
                  <column style={{ width: "30px" }}>
                    <text>{"5."}</text>
                  </column>
                  <column style={{ width: "calc(100% - 30px)" }}>
                    <text>{this.t("homepage_long_3_e")}</text>
                  </column>
                </box>
              </column>
            </container>
          </box>
        </box>
      );
    } else {
      return false;
    }
  };

  renderEnhancedVideo = () => {
    var video = this.getVideoCarousel();
    if (sizeOf(video) > 0) {
      var link = video[0]["videoYouTubeUrl"][getAppLanguage()],
        style = {
          width: "100%",
          height: video[0]["videoYouTubeHeight"] + "px",
          left: "0",
        };
      return renderYouTubeVideo(link, "", "", style);
    } else {
      return false;
    }
  };

  getVideoCarousel = () => {
    return this.getCarousels().filter(function (carousel) {
      if (carousel.resourceType === "youtube") {
        return true;
      } else {
        return false;
      }
    });
  };

  getImageCarouselList = () => {
    return this.getCarousels().filter(function (carousel) {
      if (carousel.resourceType === "image") {
        return true;
      } else {
        return false;
      }
    });
  };

  getCarousels = () => {
    var temp = [];
    if (isDealerSite()) {
      if (
        window.dealerAccountInfoPayload &&
        sizeOf(window.dealerAccountInfoPayload.dealerCarousels) > 0
      ) {
        if (
          sizeOf(
            window.dealerAccountInfoPayload.dealerCarousels.home_secondary
          ) > 0
        ) {
          return window.dealerAccountInfoPayload.dealerCarousels.home_secondary;
        }
      }
    } else if (isNationalSite()) {
      if (
        window.nationalAccountInfoPayload &&
        sizeOf(window.nationalAccountInfoPayload.nationalCarousels) > 0
      ) {
        if (
          sizeOf(
            window.nationalAccountInfoPayload.nationalCarousels.home_secondary
          ) > 0
        ) {
          return window.nationalAccountInfoPayload.nationalCarousels
            .home_secondary;
        }
      }
    }
    return temp;
  };

  enableEnhancedCarousel = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mercedes":
      case "mercedes_2022":
      case "mercedestransition":
        return true;
      default:
        return false;
    }
  };

  enableBMWCarousel = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "bmw":
        return true;
      default:
        return false;
    }
  };

  enableMiniCarousel = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mini":
        return true;
      default:
        return false;
    }
  };

  renderGroupManufacturerList = () => {
    var selectManufacturerList = this.selectManufacturerList;
    var selectedManufacturerId = this.state.selectedManufacturerId;
    var renderDealerList = this.renderDealerList;
    var dealerList = dealerSelectionStore.dealers;
    if (isGroupSite() && sizeOf(this.state.manufacturerList) > 0) {
      return (
        <box className="brandlist">
          <center>
            {Immutable.fromJS(this.state.manufacturerList).map(function (
              manufacturer
            ) {
              if (!isEmpty(manufacturer.get("manufacturerLogoUrl"))) {
                var selected =
                  manufacturer.get("manufacturerId") === selectedManufacturerId;
                var showDealerList = false;
                if (sizeOf(dealerList) > 0) {
                  dealerList.map(function (dealer) {
                    if (
                      dealer.get("nationalAccountId") ===
                      manufacturer.get("nationalAccountId")
                    ) {
                      showDealerList = true;
                    }
                  });
                }
                return (
                  <column>
                    <img
                      className={selected ? "selected" : ""}
                      src={manufacturer.get("manufacturerLogoUrl")}
                      onClick={selectManufacturerList.bind(
                        null,
                        manufacturer.get("manufacturerId")
                      )}
                    />
                    {selected && showDealerList
                      ? renderDealerList(dealerList, manufacturer)
                      : false}
                  </column>
                );
              } else {
                return false;
              }
            })}
          </center>
        </box>
      );
    } else {
      return false;
    }
  };

  renderSecondaryGroupManufacturerList = () => {
    var getSecondaryGroupImage = this.getSecondaryGroupImage;
    return (
      <box className="brandlistsecondary">
        <box>
          <div style={{ background: "#000" }}>
            <img
              style={{ margin: "0 auto" }}
              src="/static/img/mazda/mazda_tire_centre.png"
            />
          </div>
        </box>
        <box className="background">
          <container className="xxlarge">
            <ul>
              {this.state.tireBrandList.map(function (brand, index) {
                console.log(brand);

                return getSecondaryGroupImage(brand, index);
              })}
            </ul>
          </container>
        </box>
      </box>
    );
  };

  getTireBrandList = () => {
    return [
      "BFGoodrich",
      "Bridgestone",
      "Continental",
      "Dunlop",
      "Firestone",
      "General",
      "Goodyear",
      "Hankook",
      "Michelin",
      "Pirelli",
      "Uniroyal",
      "Yokohama",
      "Toyo",
      "Kelly",
      "Gislaved",
    ];
  };

  getSecondaryGroupImage = (brand, index) => {
    return (
      <li key={"tire-brand-image-" + index}>
        <Image
          className="brand-image"
          src={this.getSecondaryGroupImageLink(brand)}
          alt={brand.imageUrl}
        />
      </li>
    );
  };

  getSecondaryGroupImageLink = (brand) => {
    /*
    switch(brand.brandId) {
      case "264":
      case "265":
      case "266":
      case "267":
      case "268":
      case "269":
      case "270":
      case "271":
      case "272":
      case "273":
      case "274":
      case "296":
      case "299":
      case "300":
      case "309":
    }
    */

    switch (brand.brandName) {
      case "BFGoodrich":
        return "/static/img/mazda/brands/bfgoodrich.png";
      case "Bridgestone":
        return "/static/img/mazda/brands/bridgestone.png";
      case "Continental":
        return "/static/img/mazda/brands/continental.png";
      case "Dunlop":
        return "/static/img/mazda/brands/dunlop.png";
      case "Firestone":
        return "/static/img/mazda/brands/firestone.png";
      case "General":
        return "/static/img/mazda/brands/general.png";
      case "Goodyear":
        return "/static/img/mazda/brands/goodyear.png";
      case "Hankook":
        return "/static/img/mazda/brands/hankook.png";
      case "Michelin":
        return "/static/img/mazda/brands/michelin.png";
      case "Pirelli":
        return "/static/img/mazda/brands/pirelli.png";
      case "Uniroyal":
        return "/static/img/mazda/brands/uniroyal.png";
      case "Yokohama":
        return "/static/img/mazda/brands/yokohama.png";
      case "Toyo":
        return getAppLanguage() == "en"
          ? "/static/img/mazda/brands/toyo.png"
          : "/static/img/mazda/brands/toyo_fr.png";
      case "Kelly":
        return "/static/img/mazda/brands/kelly.png";
      case "Gislaved":
        return "/static/img/mazda/brands/gislaved.png";
      default:
        return brand.imageUrl;
    }
  };

  renderDealerList = (dealerList, manufacturer) => {
    var openDealerSite = this.openDealerSite;
    return (
      <list>
        {dealerList.map(function (dealer) {
          if (
            dealer.get("nationalAccountId") ===
            manufacturer.get("nationalAccountId")
          ) {
            return (
              <box onClick={openDealerSite.bind(null, dealer)}>
                <span>{dealer.get("name")}</span>
              </box>
            );
          } else {
            return false;
          }
        })}
      </list>
    );
  };

  openDealerSite = (dealer) => {
    var link = window.location.origin + "/" + dealer.get("urlCode");
    window.location.href = link;
  };

  selectManufacturerList = (manufacturerId) => {
    if (this.state.selectedManufacturerId !== manufacturerId) {
      this.setState({
        selectedManufacturerId: manufacturerId,
      });
    } else {
      this.setState({
        selectedManufacturerId: null,
      });
    }
  };

  renderHomepageText = () => {
    if (
      applicationStore.data.enableHomepagePanelLauncher &&
      this.t("homepageTitleText") !== ""
    ) {
      return (
        <box className="landing-title-text">
          <div className="text-container">
            <div className="text-title">
              <h1>{this.t("homepageTitleText")}</h1>
            </div>
            <div className="text-body">
              <p
                dangerouslySetInnerHTML={{ __html: this.t("homepageText") }}
              ></p>
            </div>
          </div>
        </box>
      );
    } else {
      return false;
    }
  };

  renderProductTileSelection = () => {
    if (applicationStore.data.enablePaddedProductTileSelection) {
      return <ProductTypeTilePadded setYearSelect={this.setYearSelect} />;
    } else if (applicationStore.data.enableAlternateProductTileSelection) {
      return (
        <ProductTypeTile
          landingpageTile={true}
          setYearSelect={this.setYearSelect}
        />
      );
    } else if (applicationStore.data.enableKiaFeatures) {
      return (
        <ProductTypeSelector
          landingpageTile={true}
          setYearSelect={this.setYearSelect}
        />
      );
    } else {
      return false;
    }
  };

  renderTopLauncher = () => {
    if (applicationStore.data.enableHomepageLauncher) {
      return (
        <TopLauncher
          landingpage={true}
          yearselect={this.state.launcheryearselect}
          openOnDataLoad={this.state.openOnDataLoad}
        />
      );
    } else {
      return false;
    }
  };

  renderTireBrandSection = () => {
    if (this.enableBrandList() && sizeOf(this.state.tireBrandList) > 0) {
      var brandClass = Cx({
        "tire-brand-list": true,
        "no-full-width-carousel":
          applicationStore.data.homepageLauncherLockToPage,
        "product-tile-no-padding":
          !applicationStore.data.enablePaddedProductTileSelection,
      });
      return (
        <box className={brandClass}>
          <div className="tire-brand-header">
            <h1>{this.t("ourBrands")}</h1>
          </div>
          <div className="tire-brand-body">
            <ul>
              {this.state.tireBrandList.map(function (brand, index) {
                return (
                  <li key={"tire-brand-image-" + index}>
                    <Image className="brand-image" src={brand.imageUrl} />
                  </li>
                );
              })}
            </ul>
          </div>
        </box>
      );
    }
  };

  enableBrandList = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "bmw":
      case "kia":
      case "mini":
      case "mercedes":
      case "mercedes_2022":
      case "mercedestransition":
        return false;
      default:
        return true;
    }
  };

  renderCarousel = () => {
    if (this.state.carousel && sizeOf(this.state.carouselItems) > 0) {
      return renderCarousel(
        this.state.carouselItems,
        true,
        this.state.carouselIndex,
        this.state.lastCarouselIndex,
        this.setCarouselIndex
      );
    } else {
      return false;
    }
  };

  renderFeatured = () => {
    if (
      this.enableFeatured() &&
      this.state.carousel &&
      sizeOf(this.state.carouselItems) > 0
    ) {
      var featuredClass = Cx({
        "main-featured": true,
        empty: sizeOf(this.state.featuredItems) === 0,
      });
      return (
        <div className={featuredClass}>
          {sizeOf(this.state.featuredItems) > 0 ? (
            <div className="featured-heading"></div>
          ) : (
            false
          )}
          {this.state.featuredItems
            .map(
              function (featuredItem, index) {
                return (
                  <div key={index} className="featured-item">
                    <a
                      href={this.td(featuredItem.get("linkUrl").toJS())}
                      target={featuredItem.get("linkTarget")}
                    >
                      <Image
                        src={this.td(featuredItem.get("image").toJS())}
                        alt="Image Carousel"
                        key={index}
                      />
                    </a>
                    <h3>{this.td(featuredItem.get("title").toJS())}</h3>
                    <h4>{this.td(featuredItem.get("subtitle").toJS())}</h4>
                    <a
                      href={this.td(featuredItem.get("linkUrl").toJS())}
                      target={featuredItem.get("linkTarget")}
                    >
                      {this.td(featuredItem.get("linkText").toJS())}
                    </a>
                  </div>
                );
              }.bind(this)
            )
            .toArray()}
        </div>
      );
    } else {
      return false;
    }
  };

  enableFeatured = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mercedes":
      case "mercedes_2022":
      case "mercedestransition":
        return false;
      default:
        return true;
    }
  };

  setCarouselIndex = (index) => {
    var lastIndex = this.state.carouselIndex;
    if (index < 0) index = sizeOf(this.state.carouselItems) - 1;
    else if (index > sizeOf(this.state.carouselItems) - 1) index = 0;
    if (index !== this.state.carouselIndex) {
      this.setState({
        carouselIndex: index,
        lastCarouselIndex: lastIndex,
        allowSwitching: false,
      });
    } else if (this.state.allowSwitching) {
      this.setState({
        allowSwitching: false,
      });
    }
  };
}
// use connect to out Landingpage component to the stores
export default connect(null)(Landingpage);

import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";
import { applicationStore } from "../../stores/ApplicationStore.js";

import { vehicleSelectionStore } from "../../stores/VehicleSelectionStore.js";
import { vehicleSelectionActions } from "../../actions/VehicleSelectionActions.js";

import { productListActions } from "../../actions/ProductListActions.js";
import { ProductTypes } from "../../constants/ProductTypes.js";

import { ComboBox } from "../ux/forms/ComboBox.jsx";
import { ComboBoxValue } from "../ux/forms/ComboBoxValue.jsx";

import { getAppLanguage } from "../../service/UtilityService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var _ = require("lodash");

export class VehicleSelectionFilterSection extends I18nComponent {
  state = {
    yearList: vehicleSelectionStore.data.vehicleYears,
    year: vehicleSelectionStore.data.year,
    categoryList: vehicleSelectionStore.data.categories,
    category: vehicleSelectionStore.data.category,
    modelList: vehicleSelectionStore.data.models,
    model: vehicleSelectionStore.data.model,
  };

  componentWillMount() {
    vehicleSelectionStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    vehicleSelectionStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    if (vehicleSelectionStore.data.fullVehicleSelectionTree == null) {
      setTimeout(() => {
        vehicleSelectionActions.loadVehicleSelectionModal();
      }, 100);
    }
  }

  render() {
    var yearList = this.state.yearList;
    var categoryList = this.state.categoryList;
    var modelList = this.state.modelList;

    var year = parseInt(this.state.year, 10);
    var category = parseInt(this.state.category, 10);
    var model = parseInt(this.state.model, 10);

    var lang = getAppLanguage();

    return (
      <div className="vehicle-selection">
        <hr />
        <div className="vehicle-selection-modal-title current-selection-title">
          <h3>{this.t("common.vehicleSelectionModalTitle")}</h3>
        </div>
        <div className="vehicle-selection-options">
          <div className="vehicle-selection-options-year">
            {yearList.length > 0 ? (
              <ComboBox value={year} onChange={this.onYearChange}>
                {yearList.map(function (year) {
                  return (
                    <ComboBoxValue key={year["id"]} value={year["id"]}>
                      {year["value"]}
                    </ComboBoxValue>
                  );
                })}
              </ComboBox>
            ) : (
              false
            )}
          </div>
          <div className="vehicle-selection-options-category">
            {categoryList.length > 0 ? (
              <ComboBox
                value={category}
                onChange={this.onCategoryChange}
                scrollBar={true}
              >
                {categoryList.map(function (category) {
                  return (
                    <ComboBoxValue
                      key={category["id"]}
                      value={parseInt(category["id"], 10)}
                    >
                      {category["name"][lang]}
                    </ComboBoxValue>
                  );
                })}
              </ComboBox>
            ) : (
              false
            )}
          </div>
          <div className="vehicle-selection-options-model">
            {modelList.length > 0 ? (
              <ComboBox value={model} onChange={this.onModelChange}>
                {modelList.map(function (model) {
                  return (
                    <ComboBoxValue
                      key={parseInt(model["id"], 10)}
                      value={parseInt(model["id"], 10)}
                    >
                      {model["name"][lang]}
                    </ComboBoxValue>
                  );
                })}
              </ComboBox>
            ) : (
              false
            )}
          </div>
        </div>
        <div className="vehicle-selection-button-search">
          <button className="btn" onClick={this.onSearch}>
            {this.t("update")}
          </button>
        </div>
      </div>
    );
  }

  onYearChange = (yearId) => {
    vehicleSelectionActions.yearSelectedFromModal(yearId);
  };

  onCategoryChange = (categoryId) => {
    vehicleSelectionActions.categorySelectedFromModal(categoryId);
  };

  onModelChange = (modelId) => {
    vehicleSelectionActions.modelSelectedFromModal(modelId);
  };

  onSearch = () => {
    setTimeout(() => {
      productListActions.setShowProductList();
    }, 10);
    setTimeout(() => {
      shoppingCartActions.closeCart();
    }, 10);
    if (applicationStore.data.productType == ProductTypes.TIRES) {
      vehicleSelectionActions.loadVehicleInfoByModel().then(function () {
        productListActions.loadTiresFromModel();
      });
    } else if (applicationStore.data.productType == ProductTypes.PACKAGES) {
      vehicleSelectionActions.loadVehicleInfoByModel().then(function () {
        productListActions.loadPackagesFromModel();
      });
    }
  };

  onChange = () => {
    this.setState({
      yearList: vehicleSelectionStore.data.vehicleYears,
      year: vehicleSelectionStore.data.year,
      categoryList: vehicleSelectionStore.data.categories,
      category: vehicleSelectionStore.data.category,
      modelList: vehicleSelectionStore.data.models,
      model: vehicleSelectionStore.data.model,
    });
  };
}

import { globalState } from "../../GlobalState.js";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { languageStore } from "../../stores/LanguageStore.js";

var React = require("react");
var Immutable = require("immutable");

var dictionaryConfig = {
  rowIndex: 0,
};

export class DictionaryViewer extends React.Component {
  state = {
    routerState: globalState.select("applicationState", "routerState").get(),
  };

  componentWillMount() {
    globalState
      .select("applicationState", "routerState")
      .on("update", this.onChange);
    applicationStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    globalState
      .select("applicationState", "routerState")
      .on("update", function () {});
    applicationStore.removeChangeListener(this.onChange);
  }

  componentDidUpdate() {
    this.resetRowIndex();
  }

  render() {
    var dictionaryTermSet = {
      en: languageStore.getMessages("en"),
      fr: languageStore.getMessages("fr"),
    };

    var termArray = [],
      termSetArray = [],
      missingTermArray = [];
    for (var i in dictionaryTermSet.en) {
      if (
        dictionaryTermSet["fr"][i] &&
        typeof dictionaryTermSet["fr"][i] !== "object"
      )
        termArray.push({
          field: i,
          en: dictionaryTermSet["en"][i],
          fr: dictionaryTermSet["fr"][i],
        });
      else if (
        dictionaryTermSet["fr"][i] &&
        typeof dictionaryTermSet["fr"][i] === "object"
      )
        termSetArray.push({
          field: i,
          en: dictionaryTermSet["en"][i],
          fr: dictionaryTermSet["fr"][i],
        });
      else
        missingTermArray.push({
          field: i,
          en: dictionaryTermSet["en"][i],
          fr: "",
        });
    }

    return (
      <div className="dictionary">
        <h2>TRANSLATIONS / DICTIONARY</h2>

        <table>
          <thead>
            <tr>
              <th> Fieldname </th>
              <th> English </th>
              <th> Français </th>
            </tr>
          </thead>

          <tbody>{this.renderTable(termArray)}</tbody>

          <tbody className="embedded">
            {this.collectEmbbedTerms(termSetArray, 0, 250)}
            {this.collectEmbbedTerms(termSetArray, 250, 250)}
            {this.collectEmbbedTerms(termSetArray, 500, 250)}
            {this.collectEmbbedTerms(termSetArray, 750, 250)}
          </tbody>

          <tbody>{this.renderTable(missingTermArray)}</tbody>
        </table>
      </div>
    );
  }

  renderTable = (termArray) => {
    if (termArray) {
      return termArray.map((term) => {
        return (
          <tr className={this.getRowIndex()}>
            <td>{term.field}</td>
            <td className={term.en === "" ? "empty" : ""}>{term.en}</td>
            <td className={term.fr === "" ? "empty" : ""}>{term.fr}</td>
          </tr>
        );
      });
    } else {
      return false;
    }
  };

  collectEmbbedTerms = (termSetArray, skip, take) => {
    var getRowIndex = this.getRowIndex,
      collectEmbbedTerms = this.collectEmbbedTerms,
      renderTable = this.renderTable;
    return Immutable.fromJS(termSetArray)
      .skip(skip)
      .take(take)
      .map((termSet) => {
        var keys = Object.keys(termSet.get("en").toObject());
        return Immutable.fromJS(keys).map(function (key) {
          if (typeof termSet.get("en").get(key) === "string") {
            return (
              <tr className={getRowIndex()}>
                <td>{termSet.get("field") + "." + key}</td>
                <td
                  className={termSet.get("en").get(key) === "" ? "empty" : ""}
                >
                  {termSet.get("en").get(key)}
                </td>
                <td
                  className={termSet.get("fr").get(key) === "" ? "empty" : ""}
                >
                  {termSet.get("fr").get(key)}
                </td>
              </tr>
            );
          } else {
            var termSetArray = [],
              field = termSet.get("field") + "." + key;
            termSet
              .get("en")
              .get(key)
              .map(function (term, index) {
                var en = typeof term === "string" ? term : term.toObject();
                var fr =
                  typeof term === "string"
                    ? termSet.get("fr").get(key).get(index)
                    : termSet.get("fr").get(key).get(index).toObject();
                termSetArray.push({
                  field: field + "." + index,
                  en: en,
                  fr: fr,
                });
              });

            return Immutable.fromJS(termSetArray).map(function (termSet) {
              if (typeof termSet.get("en") === "string") {
                return renderTable([
                  {
                    field: termSet.get("field"),
                    en: termSet.get("en"),
                    fr: termSet.get("fr"),
                  },
                ]);
              } else {
                return collectEmbbedTerms(
                  [
                    {
                      field: termSet.get("field"),
                      en: termSet.get("en").toObject(),
                      fr: termSet.get("fr").toObject(),
                    },
                  ],
                  0,
                  100
                );
              }
            });
          }
        });
      });
  };

  resetRowIndex = () => {
    dictionaryConfig.rowIndex = 0;
  };

  incrementRowIndex = () => {
    var newRowIndex = parseInt(dictionaryConfig.rowIndex) + 1;
    dictionaryConfig.rowIndex = newRowIndex;
    return newRowIndex;
  };

  getRowIndex = () => {
    var rowIndex = this.incrementRowIndex();
    return rowIndex % 2 === 0 ? "even-row row-" + rowIndex : "row-" + rowIndex;
  };

  onChange = () => {
    this.setState({
      routerState: globalState.select("applicationState", "routerState").get(),
    });
  };
}

import { applicationActions } from "../../actions/ApplicationActions.js";
import { Modal } from "../ux/Modal.jsx";

import { applicationStore } from "../../stores/ApplicationStore.js";

import { getProvinceSelect } from "../../service/DealerSelectionService.js";
import { getAppLanguage } from "../../service/UtilityService.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");

export class NationalPartStock extends I18nComponent {
  state = {
    nationalPartStockInfo: applicationStore.data.nationalPartStockInfo,
    country: applicationStore.data.dealerInfo
      ? applicationStore.data.dealerInfo.country
      : applicationStore.data.dealer.country,
    province: applicationStore.data.dealerInfo
      ? applicationStore.data.dealerInfo.province
      : applicationStore.data.dealer.province,
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
  }

  render() {
    var province = this.state.province;
    return (
      <Modal refactor={true} requestUnmount={this.closeNationalPartStockModal}>
        <div className="wrapper">
          <div className="stock-info-modal">
            <div className="stock-info">
              <div className="part-stock-header-info">
                <p>
                  {this.t("partStockModal.partStockModalTitle")} -{" "}
                  {this.t("product.partNumber")}{" "}
                  {applicationStore.data.dealerSite
                    ? this.state.nationalPartStockInfo.manufacturerPartNumber
                    : this.state.nationalPartStockInfo.nationalPartNumber}
                </p>
              </div>
              <div className="stock-info-filter">
                {getProvinceSelect(
                  this.state.country,
                  getAppLanguage(),
                  this.onSelectProvince,
                  province
                )}
              </div>
              <div className="part-stock-table">
                <table>
                  <thead>
                    <th>{this.t("partInfoModal.distributor")}</th>
                    <th>{this.t("partInfoModal.location")}</th>
                    <th>{this.t("partInfoModal.province")}</th>
                    <th>{this.t("partInfoModal.city")}</th>
                    <th>{this.t("partInfoModal.phone")}</th>
                    <th>{this.t("partInfoModal.qty")}</th>
                    <th>{this.t("partInfoModal.lastUpdated")}</th>
                  </thead>
                  <tbody>
                    {this.state.nationalPartStockInfo.nationalStockList
                      .filter(
                        (stock) =>
                          stock.locationItemQty > 0 &&
                          (province === null ||
                            stock.locationState === province)
                      )
                      .sort(function (a, b) {
                        if (
                          parseInt(a.locationSortOrder, 10) <
                          parseInt(b.locationSortOrder, 10)
                        )
                          return 1;
                        else if (
                          parseInt(a.locationSortOrder, 10) >
                          parseInt(b.locationSortOrder, 10)
                        )
                          return -1;
                        else if (
                          parseInt(a.locationItemQty, 10) <
                          parseInt(b.locationItemQty, 10)
                        )
                          return 1;
                        else if (
                          parseInt(a.locationItemQty, 10) >
                          parseInt(b.locationItemQty, 10)
                        )
                          return -1;
                        else if (
                          a.locationDistributorName > b.locationDistributorName
                        )
                          return 1;
                        else if (
                          a.locationDistributorName < b.locationDistributorName
                        )
                          return -1;
                        return 0;
                      })
                      .map(function (stock) {
                        var date = new Date();
                        date.setTime(
                          date.getTime() -
                            stock.locationMinutesSinceLastStockUpdate * 60000
                        ); // multiply by 60000 to convert to milliseconds
                        return (
                          <tr>
                            <td>{stock.locationDistributorName}</td>
                            <td>{stock.locationName}</td>
                            <td>{stock.locationState}</td>
                            <td>{stock.locationCity}</td>
                            <td>{stock.locationPhone}</td>
                            <td>{stock.locationItemQty}</td>
                            <td>{date.toLocaleString()}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  onSelectProvince = (province) => {
    this.setState({
      province: province,
    });
  };

  onChange = () => {
    this.setState({
      nationalPartStockInfo: applicationStore.data.nationalPartStockInfo,
    });
  };

  closeNationalPartStockModal = () => {
    setTimeout(() => {
      applicationActions.closeNationalPartStockModal();
    }, 10);
  };
}

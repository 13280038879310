import { globalState } from '../GlobalState.js';
import { DispatcherActions } from '../constants/DispatcherActions.js';
import { RouterStates } from '../constants/RouterStates.js';
import { ProductTypes } from '../constants/ProductTypes.js';
import { VehicleSelectionSkippingService } from '../service/VehicleSelectionSkippingService.js';
import { AbstractStore } from './AbstractStore.js';
import { languageStore } from './LanguageStore.js';
import { applicationStore } from './ApplicationStore.js';
import { Router } from './Router.js';

import { sizeOf, skipBreadcrumbsLoad } from '../service/UtilityService';
import { getTestId, TEST_LIST } from "../service/TestingService";

var _ = require('lodash');

var BREADCRUMBS_ORDER = [
  RouterStates.HOMEPAGE,
  RouterStates.SEARCH_RESULT,
  RouterStates.YEAR,
  RouterStates.MAKE,
  RouterStates.CATEGORY,
  RouterStates.MODEL,
  RouterStates.TRIM,
  RouterStates.PRODUCT_LIST,
  RouterStates.WISHLIST
];

var BreadcrumbsStore = AbstractStore.extend({

  constructor: function() {
    this.data = {
      crumbs: {}
    };

    BreadcrumbsStore.__super__.constructor.apply(this, arguments);
  },

  getActionHandlers: function() {
    var handlers = {};
    handlers[DispatcherActions.BREADCRUMBS_PUSH_STATE] = this.pushState;
    handlers[DispatcherActions.BREADCRUMBS_LOAD] = this.loadBreadcrumbs;
    handlers[DispatcherActions.VEHICLE_MODELS_LOADED] = this.onVehicleModelsLoaded;
    handlers[DispatcherActions.VEHICLE_CATEGORIES_LOADED] = this.onVehicleCategoriesLoaded;
    handlers[DispatcherActions.VEHICLE_MAKES_LOADED] = this.onVehicleMakesLoaded;
    handlers[DispatcherActions.VEHICLE_YEARS_LOADED] = this.onVehicleYearsLoaded;
    handlers[DispatcherActions.VEHICLE_TRIMS_LOADED] = this.onVehicleTrimsLoaded;
    handlers[DispatcherActions.INITIALIZE_APP_STATE] = this.onInitializeAppState;
    handlers[DispatcherActions.PRODUCT_TYPE_SELECTED] = this.onProductTypeSelected;
    handlers[DispatcherActions.VEHICLE_SELECTION_YEARS_SELECTED] = this.onYearSelected;
    handlers[DispatcherActions.VEHICLE_SELECTION_MAKE_SELECTED] = this.onMakeSelected;
    handlers[DispatcherActions.VEHICLE_SELECTION_CATEGORY_SELECTED] = this.onCategorySelected;
    handlers[DispatcherActions.VEHICLE_SELECTION_MODEL_SELECTED] = this.onModelSelected;
    handlers[DispatcherActions.VEHICLE_SELECTION_TRIM_SELECTED] = this.onTrimSelected;
    handlers[DispatcherActions.CHANGE_PRODUCT_TYPE] = this.onChangeProductType;
    handlers[DispatcherActions.GO_BACK] = this.onGoBack;
    return handlers;
  },

  onInitializeAppState: function(appState) {
    var routerState = Router.decodeRouterState(appState);

    this.loadAppState('productType', appState, routerState.params);
    this.loadAppState('search', appState, routerState.params);

    this.data.showSecondaryMakes = false;

    this.emitChange();
  },

  loadAppState: function(key, appState, params) {
    var temp;
    if (params[key]) {
      temp = params;
      this.data[key] = temp[key];
    } else if (appState[key]) {
      temp = appState;
      this.data[key] = temp[key];
    } else {
      temp = params;
    }

    if (temp.hasOwnProperty(key)) {
      switch (key) {
        case 'year':
          if (temp.year && parseFloat(temp.year) < 1950) {
            if (temp.year) this.data.year = temp.year;
            if (temp.make) this.data.make = temp.make;
            if (temp.category) this.data.category = temp.category;
            if (temp.model) this.data.model = temp.model;
            if (temp.trim) this.data.trim = temp.trim;
          }
          break;
      }
    }
  },

  onChangeProductType: function(productType) {
    this.data.productType = productType;
    this.emitChange();
  },

  onProductTypeSelected: function(productType) {
    this.data.productType = productType;
    this.emitChange();
  },

  onYearSelected: function(year) {
    this.data.year = year;
    this.emitChange();
  },

  onMakeSelected: function(make) {
    this.data.make = make;
    this.emitChange();
  },

  onCategorySelected: function(category) {
    this.data.category = category;
    this.emitChange();
  },

  onModelSelected: function(model) {
    this.data.model = model;
    this.emitChange();
  },

  onTrimSelected: function(trim) {
    this.data.trim = trim;
    this.emitChange();
  },

  buildState: function(id, state, name, unclickable, translatable, elementId) {
    return {
      id: id,
      state: state,
      value: name,
      unclickable: unclickable,
      translatable: translatable,
      elementId: elementId,
    };
  },

  onVehicleYearsLoaded: function(payload) {
    var skippedYear = VehicleSelectionSkippingService.getSkippedYearForLoadedYears(payload);
    if (skippedYear !== false) {
      this.data.year = skippedYear.id;
      this.data.make = null;
      this.data.category = null;
      this.data.model = null;
      this.data.trim = null;
      this.data.crumbs[RouterStates.YEAR] = skippedYear ? this.buildState(skippedYear.id, RouterStates.YEAR, skippedYear.name, true, false, getTestId(TEST_LIST.NAVIGATION.YEAR)) : null;
      this.data.crumbs[RouterStates.MAKE] = null;
      this.data.crumbs[RouterStates.CATEGORY] = null;
      this.data.crumbs[RouterStates.MODEL] = null;
      this.data.crumbs[RouterStates.TRIM] = null;
    }

    this.emitChange();
  },

  onVehicleMakesLoaded: function(payload) {
    if (VehicleSelectionSkippingService.shouldSkipMakesBecauseOfEmpty(payload.primaryMakes, payload.secondaryMakes, this.data.showSecondaryMakes, this.data.productType)) {
      this.data.crumbs[RouterStates.MAKE] = null;
    } else if (VehicleSelectionSkippingService.shouldSkipMakesBecauseOfSingleMake(payload.primaryMakes, payload.secondaryMakes, this.data.showSecondaryMakes, this.data.productType)) {
      var make = VehicleSelectionSkippingService.getSkippedMakeForLoadedMakes(
        payload.primaryMakes,
        payload.secondaryMakes,
        this.data.showSecondaryMakes,
        this.data.productType
      );
      this.data.make = make.id;
      this.data.category = null;
      this.data.model = null;
      this.data.trim = null;
      this.data.crumbs[RouterStates.MAKE] = this.buildState(make.id, RouterStates.MAKE, make.name, true, false, getTestId(TEST_LIST.NAVIGATION.MANUFACTURER));
      this.data.crumbs[RouterStates.CATEGORY] = null;
      this.data.crumbs[RouterStates.MODEL] = null;
      this.data.crumbs[RouterStates.TRIM] = null;
    }

    this.emitChange();
  },

  onVehicleCategoriesLoaded: function(payload) {
    var skippedCategory = VehicleSelectionSkippingService.getSkippedCategoryForLoadedCategories(payload);
    if (skippedCategory !== false && skippedCategory !== null) {
      this.data.category = skippedCategory.id;
      this.data.model = null;
      this.data.trim = null;
      this.data.crumbs[RouterStates.CATEGORY] = skippedCategory ? this.buildState(skippedCategory.id, RouterStates.CATEGORY, skippedCategory.name, true, true, getTestId(TEST_LIST.NAVIGATION.CATEGORY)) : null;
      this.data.crumbs[RouterStates.MODEL] = null;
      this.data.crumbs[RouterStates.TRIM] = null;
    } else if (skippedCategory === null) {
      this.data.category = null;
      this.data.model = null;
      this.data.trim = null;
      this.data.crumbs[RouterStates.CATEGORY] = null;
      this.data.crumbs[RouterStates.MODEL] = null;
      this.data.crumbs[RouterStates.TRIM] = null;
    }

    this.emitChange();
  },

  onVehicleModelsLoaded: function(payload) {
    if (sizeOf(payload) > 0) {
      var skippedModel = VehicleSelectionSkippingService.getSkippedModelForLoadedModels(payload);
      if (skippedModel !== false) {
        this.data.model = skippedModel.id;
        this.data.trim = null;
        this.data.crumbs[RouterStates.MODEL] = skippedModel ? this.buildState(skippedModel.id, RouterStates.MODEL, skippedModel.name, true, true, getTestId(TEST_LIST.NAVIGATION.MODEL)) : null;
        this.data.crumbs[RouterStates.TRIM] = null;
      }
    }

    this.emitChange();
  },

  onVehicleTrimsLoaded: function(payload) {
    var skippedTrim = VehicleSelectionSkippingService.getSkippedTrimForLoadedTrims(payload);
    if (skippedTrim) {
      this.data.trim = skippedTrim.id;
      this.data.crumbs[RouterStates.TRIM] = skippedTrim ? this.buildState(skippedTrim.id, RouterStates.TRIM, skippedTrim.name, true, true, getTestId(TEST_LIST.NAVIGATION.SUBMODEL)) : null;
    }
    this.emitChange();
  },

  pushState: function(payload) {
	  if (payload) this.data.crumbs[payload.state] = payload;
    this.emitChange();
  },

  getLastCrumb: function() {
    var routerState = globalState.select('applicationState', 'routerState').get();
    switch (routerState) {
      case RouterStates.YEAR:
        return languageStore.getDictionary().breadcrumbs.year;
      case RouterStates.MAKE:
        return languageStore.getDictionary().breadcrumbs.make;
      case RouterStates.CATEGORY:
        return languageStore.getDictionary().breadcrumbs.category;
      case RouterStates.MODEL:
        return languageStore.getDictionary().breadcrumbs.model;
      case RouterStates.TRIM:
        if (applicationStore.data.enableShowSubmodelTiresizeSelection && applicationStore.data.productType === ProductTypes.TIRES) return languageStore.getDictionary().breadcrumbs.tireSize;
        return languageStore.getDictionary().breadcrumbs.trim;
      default:
        return null;
    }
  },

  loadBreadcrumbs: function(payload) {
    this.data.crumbs = {};
    _.each(payload, function(payloadValue, payloadKey) {

      var id = null,
          state = null,
          value = null,
          translatable = null,
          elementId = null;

      switch (payloadKey) {
        case 'vehicleYear':
          state = RouterStates.YEAR;
          id = this.data.year;
          value = payloadValue;
          translatable = false;
          elementId = getTestId(TEST_LIST.NAVIGATION.YEAR);
        break;
        case 'vehicleManufacturer':
          state = RouterStates.MAKE;
          id = this.data.make;
          value = payloadValue;
          translatable = false;
          elementId = getTestId(TEST_LIST.NAVIGATION.MANUFACTURER);
        break;
        case 'vehicleCategory':
          state = RouterStates.CATEGORY;
          id = this.data.category;
          value = payloadValue;
          translatable = true;
          elementId = getTestId(TEST_LIST.NAVIGATION.CATEGORY);
        break;
        case 'vehicleModel':
          state = RouterStates.MODEL;
          id = this.data.model;
          value = payloadValue;
          translatable = true;
          elementId = getTestId(TEST_LIST.NAVIGATION.MODEL);
        break;
        case 'vehicleSubmodel':
          state = RouterStates.TRIM;
          id = this.data.trim;
          value = payloadValue;
          translatable = true;
          elementId = getTestId(TEST_LIST.NAVIGATION.SUBMODEL);
        break;
      }

      this.data.crumbs[state] = {
        state: state,
        id: id,
        value: value,
        translatable: translatable,
        elementId: elementId,
      };
    }, this);

    this.emitChange();
  },

  getBreadcrumbs: function() {
    var routerState = globalState.select('applicationState', 'routerState').get(), orderArray = BREADCRUMBS_ORDER, currentRouteIndex = orderArray.indexOf(routerState), breadcrumbs = [];

    if (!skipBreadcrumbsLoad() && currentRouteIndex) {
      _.each(orderArray, function(state, index) {

        if (state === routerState) return false;
        if (index < currentRouteIndex && !!this.data.crumbs[state]) breadcrumbs.push(this.data.crumbs[state]);

      }, this);
      breadcrumbs.push({ value: this.getLastCrumb() });
    }

	  return breadcrumbs;
  },

  resetCrumbs: function() {
    this.data.crumbs = {};
  },

  onGoBack: function() {
    var routerState = globalState.select('applicationState', 'routerState').get();
    switch (routerState) {
      case RouterStates.YEAR:
        this.data.crumbs[RouterStates.YEAR] = null;
        this.data.crumbs[RouterStates.MAKE] = null;
        this.data.crumbs[RouterStates.CATEGORY] = null;
        this.data.crumbs[RouterStates.MODEL] = null;
        this.data.crumbs[RouterStates.TRIM] = null;
        break;
      case RouterStates.MAKE:
        this.data.crumbs[RouterStates.MAKE] = null;
        this.data.crumbs[RouterStates.CATEGORY] = null;
        this.data.crumbs[RouterStates.MODEL] = null;
        this.data.crumbs[RouterStates.TRIM] = null;
        break;
      case RouterStates.CATEGORY:
        this.data.crumbs[RouterStates.CATEGORY] = null;
        this.data.crumbs[RouterStates.MODEL] = null;
        this.data.crumbs[RouterStates.TRIM] = null;
        break;
      case RouterStates.MODEL:
        this.data.crumbs[RouterStates.MODEL] = null;
        this.data.crumbs[RouterStates.TRIM] = null;
        break;
      case RouterStates.TRIM:
        this.data.crumbs[RouterStates.TRIM] = null;
        break;
      default:
        break;
    }
    this.emitChange();
  }
});

export var breadcrumbsStore = new BreadcrumbsStore();

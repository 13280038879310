import { languageStore } from '../stores/LanguageStore.js';

import { ProductTypes } from '../constants/ProductTypes';

import { isEmpty, sizeOf, checkArrayFieldsExist, arraySearch } from './UtilityService';
import { getDestinationInverse } from '../api/RequestBuilderHelper';
import { vehicleSelectionStore } from "../stores/VehicleSelectionStore";
import { productListStore } from "../stores/ProductListStore";
import { productListActions } from "../actions/ProductListActions";
import { applicationStore } from "../stores/ApplicationStore";
import store from "../stores/configureStore";

var React = require('react');
var Immutable = require('immutable');
var _ = require('lodash');

export const buildFilter = (key, value) => new Immutable.fromJS({ key, value });

const DEFAULT_SELECTED_QUANTITY_OF_PRODUCT = 4;
const DEFAULT_SELECTED_QUANTITY_OF_PRODUCT_STAGGERED = 2;

export const getInitialQuantity = (staggered, pvisualizer = false) => {
  switch(applicationStore.data.productType) {
    case ProductTypes.ACCESSORIES:
    case ProductTypes.PACKAGES:
      return 1;
    case ProductTypes.TIRES:
    case ProductTypes.ALLOY_WHEELS:
      return pvisualizer ? 1 : staggered ? 2 : 4;
  }
};

export const printComparison = () => {
	let comparisonList = store.getState().productList.comparisonList;
	let VehicleId = store.getState().productList.vehicleId;
  var comparePartIdList = [];
	for (let i = 0; i < comparisonList.length; i++) {
		comparePartIdList.push(comparisonList[i].id);
	}
  var temp = window.location.href.split("/product/");

  var url = null;
  if (comparePartIdList) {
  	url = temp[0] + "/product/" + ProductTypes.TIRES.toLowerCase() + "/compare/"
					+ comparePartIdList.join() + (VehicleId ? "/vehicle/" : "")
					+ (VehicleId ? VehicleId : "");
  }

  var customerInformation = "?", firstName = '', lastName = '', email = '', phone = '', notes = '';
  if (document.getElementById("formFirstName") && !isEmpty(document.getElementById("formFirstName").value)) firstName = document.getElementById("formFirstName").value;
  if (document.getElementById("formLastName") && !isEmpty(document.getElementById("formLastName").value)) lastName = document.getElementById("formLastName").value;
  if (document.getElementById("formEmail") && !isEmpty(document.getElementById("formEmail").value)) email = document.getElementById("formEmail").value;
  if (document.getElementById("formPhone") && !isEmpty(document.getElementById("formPhone").value)) phone = document.getElementById("formPhone").value;
  if (document.getElementById("formNotes") && !isEmpty(document.getElementById("formNotes").value)) notes = document.getElementById("formNotes").value;

  if (!isEmpty(firstName) || !isEmpty(lastName) || !isEmpty(email) || !isEmpty(phone) || !isEmpty(notes)) {
  	customerInformation = customerInformation + "firstName" + "=" + encodeURIComponent(firstName) + "&";
  	customerInformation = customerInformation + "lastName" + "=" + encodeURIComponent(lastName) + "&";
  	customerInformation = customerInformation + "email" + "=" + encodeURIComponent(email) + "&";
  	customerInformation = customerInformation + "phone" + "=" + encodeURIComponent(phone) + "&";
  	customerInformation = customerInformation + "notes" + "=" + encodeURIComponent(notes);
  }

  if (!isEmpty(customerInformation)) url = url + customerInformation;

  if (url) {
    var win = window.open(url, '_blank');
    win.focus();
  }
};

export const emailComparison = (first = '', last = '', email = '', phone = '', notes = '') => {
  if (isEmpty(email) && isEmpty(document.getElementsByClassName("email-compare-input")[0]) && email) {
  	email = document.getElementsByClassName("email-compare-input")[0].value;
  }

  var firstPartId = 0, secondPartId = 0, thirdPartId = 0;
  productListStore.data.comparisonPartList.map(function(part, index) {
    if (index === 0) firstPartId = part.get('frontTire').get('id');
    else if (index === 1) secondPartId = part.get('frontTire').get('id');
    else if (index === 2) thirdPartId = part.get('frontTire').get('id');
  });


  var vehicle = vehicleSelectionStore.data.vehicleId;
  if (isEmpty(vehicle)) vehicle = applicationStore.data.vehicle;

  productListActions.onEmailComparison(ProductTypes.TIRES, vehicle, firstPartId, secondPartId, thirdPartId, first, last, email, phone, notes);
};

const getComparisonEmailBody = () => {
  var dictionary = languageStore.getDictionary();
  var comparison = document.getElementsByClassName('comparison-body')[0];

  if (comparison) {
    if (comparison.childNodes[0].childNodes[0].childNodes[0].childNodes) {
      for (var i in comparison.childNodes[0].childNodes[0].childNodes[0].childNodes) {
        if (i > 0) {
          var partImage = comparison.childNodes[0].childNodes[0].childNodes[0].childNodes[i].childNodes[0].childNodes[1].childNodes[0];
          var brandImage = comparison.childNodes[0].childNodes[0].childNodes[0].childNodes[i].childNodes[0].childNodes[1].childNodes[1];

          partImage.src = partImage.src;
          brandImage.src = brandImage.src;
        }
      }
    }
  }

  var outerHtml = comparison.outerHTML, title = dictionary.comparison.title, title = "<h2>" + title + "</h2>";

  var customerInformation = '', firstName = '', lastName = '', email = '', phone = '', notes = '';
  if (document.getElementById("formFirstName") && !isEmpty(document.getElementById("formFirstName").value)) firstName = document.getElementById("formFirstName").value;
  if (document.getElementById("formLastName") && !isEmpty(document.getElementById("formLastName").value)) lastName = document.getElementById("formLastName").value;
  if (document.getElementById("formEmail") && !isEmpty(document.getElementById("formEmail").value)) email = document.getElementById("formEmail").value;
  if (document.getElementById("formPhone") && !isEmpty(document.getElementById("formPhone").value)) phone = document.getElementById("formPhone").value;
  if (document.getElementById("formNotes") && !isEmpty(document.getElementById("formNotes").value)) notes = document.getElementById("formNotes").value;

  if (!isEmpty(firstName) || !isEmpty(lastName) || !isEmpty(email) || !isEmpty(phone) || !isEmpty(notes)) {
		customerInformation = (
			"<div class='customer-info'>" +
				(!isEmpty(firstName) ? "<div><span>" + dictionary.wishlist.modal.firstName + ": " + "</span><span>" + firstName + "</span></div>" : '') +
				(!isEmpty(lastName) ? "<div><span>" + dictionary.wishlist.modal.lastName + ": " + "</span><span>" + lastName + "</span></div>" : '') +
				(!isEmpty(email) ? "<div><span>" + dictionary.wishlist.modal.email + ": " + "</span><span>" + email + "</span></div>" : '') +
				(!isEmpty(phone) ? "<div><span>" + dictionary.wishlist.modal.phone + ": " + "</span><span>" + phone + "</span></div>" : '') +
				(!isEmpty(notes) ? "<div><span>" + dictionary.wishlist.modal.notes + ": " + "</span><span>" + notes + "</span></div>" : '') +
			"</div>"
		);
	}

  var html = (
    "<html xmlns='http://www.w3.org/1999/xhtml'>" +
    "<head>" +
    "<meta http-equiv='Content-Type' content='text/html; charset=utf-8' />" +
    "<meta name='viewport' content='width=device-width, initial-scale=1.0'/>" +
    "<title>{dictionary.comparison.title}</title>" +

    "<style type='text/css'>" +
    "h2 { color: #000; font-size: 28px; margin-bottom: 25px; }" +
    "div.customer-info { display: block; margin-top: -25px; margin-bottom: 25px; }" +
    "table { color: #000; margin-bottom: 30px; }" +
    "tr { background: #f8f8f8; }" +
    "th { border: 1px solid #dcdcdc; font-size: 13px; padding: 5px 10px; }" +
    "td { border: 1px solid #dcdcdc; font-size: 11px; padding: 2.5px 5px; width: auto; max-width: 300px; height: auto; max-height: 175px; }" +

    "ul.tire-promoted-pricing { margin: 0; padding: 0; }" +
    "ul.tire-promoted-pricing li { display: inline-block; width: 100%; margin: 0; height: 15px; line-height: 15px; }" +
    "span.pricing-label { float: left; }" +
    "span.pricing-value { float: right; }" +

    "tr.even { background: #f1f1f1; }" +
    "tr.section-label-row { background: #fff; border-top: 2px solid #000; border-bottom: 2px solid #000; }" +
    "tr.first-row th.first-column { background: #fff; border: none; }" +
    "tr.fourth-row th { overflow: hidden;; }" +
    "tr.fourth-row td { overflow-y: auto; overflow-x: hidden; }" +
    "tr.fifth-row th { overflow: hidden; }" +
    "tr.fifth-row td { overflow-y: auto; overflow-x: hidden; }" +
    "tr.twelfth-row td { font-weight: bold; font-size: 12px; }" +
    "tr.thirteenth-row { display: none; }" +

    ".first-column { width: 175px; text-align: left; }" +

    ".part-name h3 { font-size: 18px; font-weight: bold; text-align: center; }" +

    ".part-main-image { display: block; height: 175px; margin: 0 auto; }" +
    ".season-icon { display: none; }" +

    ".print-compare-button { display: none; }" +
    ".email-compare-button { display: none; }" +
    ".email-compare-input { display: none; }" +
    ".email-success-text { display: none; }" +
    ".input-wrapper { display: none; }" +
    ".btn { display: none; }" +
    "</style>" +

    "</head>" +
    "<body>" +

    title +

    customerInformation +

    outerHtml +

    "</body>" +
    "</html>"
  );

  return html;
};

const getRelatedPartName = (relatedPart) => {
	switch (getDestinationInverse(relatedPart.get('itemType'))) {
		case ProductTypes.TIRES:
			return Map({
				en: relatedPart.get('tireModelName'),
				fr: relatedPart.get('tireModelName')
			});
		case ProductTypes.ALLOY_WHEELS:
			return relatedPart.get('wheelName');
		case ProductTypes.PACKAGES:
			return relatedPart.get('packageName');
		case ProductTypes.ACCESSORIES:
			return relatedPart.get('accessoryName');
		default:
			throw new Error();
	}
};

const getRelatedPartImage = (relatedPart) => {
	switch (getDestinationInverse(relatedPart.get('itemType'))) {
		case ProductTypes.TIRES:
			return relatedPart.get('tireImageLink');
		case ProductTypes.ALLOY_WHEELS:
			return relatedPart.get('wheelImageLink');
		case ProductTypes.PACKAGES:
			return relatedPart.get('packageImageLink');
		case ProductTypes.ACCESSORIES:
			return relatedPart.get('accessoryImageLink');
		default:
			throw new Error();
	}
};

const getRelatedPartNumber = (relatedPart) => {
	switch (getDestinationInverse(relatedPart.get('itemType'))) {
		case ProductTypes.TIRES:
			return relatedPart.get('tireNationalPartNumber');
		case ProductTypes.ALLOY_WHEELS:
			return relatedPart.get('wheelPartNumber');
		case ProductTypes.PACKAGES:
			return relatedPart.get('packagePartNumber');
		case ProductTypes.ACCESSORIES:
			return relatedPart.get('accessoryPartNumber');
		default:
			throw new Error();
	}
};

export const getCodeText = (code) => {
	var dictionary = languageStore.getDictionary();
	switch (code) {
		case 'ITEM_WARNING_TIRE_NOT_ORIGINAL_SIZE':
			return dictionary.shoppingCartWarnings.ITEM_WARNING_TIRE_NOT_ORIGINAL_SIZE;
		case 'ITEM_WARNING_TIRE_NOT_OE':
			return dictionary.shoppingCartWarnings.ITEM_WARNING_TIRE_NOT_OE;
		case 'ITEM_WARNING_TIRE_NOT_RUNFLAT':
			return dictionary.shoppingCartWarnings.ITEM_WARNING_TIRE_NOT_RUNFLAT;
		case 'ITEM_WARNING_TIRE_WHEEL_MISMATCH':
			return dictionary.shoppingCartWarnings.ITEM_WARNING_TIRE_WHEEL_MISMATCH;
    case 'ITEM_WARNING_TIRE_WHEEL_IDEAL_SIZE_MISMATCH':
      return dictionary.shoppingCartWarnings.ITEM_WARNING_TIRE_WHEEL_IDEAL_SIZE_MISMATCH;
		default:
			return "";
	}
};

export const getMinFromList = (list) => {
  if (list) {
    var minValIndex = Number.MAX_SAFE_INTEGER;
    for (var i in list) if (parseFloat(list[i]) < minValIndex) minValIndex = parseInt(list[i]);
    return minValIndex !== Number.MAX_SAFE_INTEGER ? minValIndex : null;
  } else {
    return null;
  }
};

export const getMaxFromList = (list) => {
  if (list) {
    var maxValIndex = -1;
    for (var i in list) if (parseFloat(list[i]) > maxValIndex) maxValIndex = parseInt(list[i]);
    return maxValIndex !== -1 ? maxValIndex : null;
  } else {
    return null;
  }
};

export const speedRatingIsValid = (tireSpeedRating, vehicleSpeedRating) => {

	var speedRatingArray = [
		'A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8',
		'B', 'C', 'D', 'E', 'F', 'G', 'J', 'K', 'L',
		'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'H',
		'V', 'Z', 'W', 'Y', '(Y)'
	];

	return speedRatingArray.indexOf(tireSpeedRating) >= speedRatingArray.indexOf(vehicleSpeedRating);
};

export const getWinterSpeedRating = (speedRating, dealerOffset) => {
	if (typeof dealerOffset === 'undefined') {
		dealerOffset = 0;
	}

	var speedRatingArray = [
		'A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8',
		'B', 'C', 'D', 'E', 'F', 'G', 'J', 'K', 'L',
		'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'H',
		'V', 'Z', 'W', 'Y', '(Y)'
	];

	var index = arraySearch(speedRatingArray, speedRating);

	return speedRatingArray[index - dealerOffset];
};

export const updateSpeedRatingDropDown = (originalDropDown, speedRating) => {
	var speedRatingArray = [
		'A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8',
		'B', 'C', 'D', 'E', 'F', 'G', 'J', 'K', 'L',
		'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'H',
		'V', 'Z', 'W', 'Y', '(Y)'
	];

	var speedRatingFound = false, updatedSpeedRatingArray = [];
	for (var i = 0; i < sizeOf(speedRatingArray); i++) {
		if (speedRatingArray[i] == speedRating) {
			speedRatingFound = true;
			updatedSpeedRatingArray[sizeOf(updatedSpeedRatingArray)] = speedRatingArray[i];
		} else if (speedRatingFound) {
			updatedSpeedRatingArray[sizeOf(updatedSpeedRatingArray)] = speedRatingArray[i];
		}
	}

	updatedSpeedRatingArray = Immutable.fromJS(updatedSpeedRatingArray.map(speedIndexFilter => buildFilter(speedIndexFilter, speedIndexFilter)));

	if (speedRatingFound && sizeOf(updatedSpeedRatingArray) > 0) {
		return updatedSpeedRatingArray;
	} else {
		return originalDropDown;
	}
};

export const plyRatingIsValid = (tirePlyRating, selectedPlyRating) => {

	var plyRatingArray = [
		'LL', 'SL', 'XL', 'B', 'C', 'D', 'E', 'F',
		'G', 'H', 'J', 'L', 'M', 'N'
	];

	var tirePlyRatingNotValid = tirePlyRating == '' || tirePlyRating === null || typeof tirePlyRating === 'undefined';

	return _.contains(selectedPlyRating, tirePlyRating);
};

export const buildPlyRatingFilter = (plyRating) => {
	var plyRatingArray = [
		'LL', 'SL', 'XL', 'B', 'C', 'D', 'E', 'F',
		'G', 'H', 'J', 'L', 'M', 'N'
	];

	var plyRatingFound = false, plyRatingFilters = [];
	for (var i = 0; i < 8; i++) {
		if (plyRatingArray.indexOf(plyRating) == i) {
			plyRatingFound = true;
			plyRatingFilters[sizeOf(plyRatingFilters)] = plyRatingArray[i];
		} else if (plyRatingFound) {
			plyRatingFilters[sizeOf(plyRatingFilters)] = plyRatingArray[i];
		}
	}

	return plyRatingFilters;
};

export const getPlyRatingFilterInteger = (plyRating) => {
	var plyRatingList = {
		LL: 0,
		SL: 0,
		XL: 0,
		B: 4,
		C: 6,
		D: 8,
		E: 10,
		F: 12,
		G: 14,
		H: 16,
		J: 18,
		L: 20,
		M: 22,
		N: 24,
	};

	return plyRatingList[plyRating];
};

export const checkForValidSeason = (seasonalSizeCode, seasonCode) => {
	switch (seasonalSizeCode) {
		case '0':
		case 0: // Any Season
			return true;
		case '1':
		case 1: // Winter Only
			return seasonCode === 0; // Winter
		case '2':
		case 2: // Summer Only
			return seasonCode === 2; // Summer
		default:
			return false;
	}
};

export const getSelectedTireSizeIfPresentOrOriginal = (originalTireSize, tireSizes, currentTireSize, currentSeason) => {
	var tireSize = tireSizes.find(record => record.get('key') === currentTireSize);
	var season = parseInt(currentSeason, 10);

	if (typeof tireSize === 'undefined') {
		var tireSizeList = tireSizes.filter(tireSize => checkForValidSeason(tireSize.get('seasonalSizeCode'), season));
		if (tireSizeList.size > 1) {
			if (tireSizes.filter(tireSize => tireSize.get('sizeTypeCode') === "S").size > 0) {
				tireSizeList = tireSizeList.filter(tireSize => tireSize.get('sizeTypeCode') === "S");
			} else if (tireSizes.filter(tireSize => tireSize.get('sizeTypeCode') === "O").size > 0) {
				tireSizeList = tireSizeList.filter(tireSize => tireSize.get('sizeTypeCode') === "O");
			} else if (tireSizes.filter(tireSize => tireSize.get('sizeTypeCode') === "A").size > 0) {
				tireSizeList = tireSizeList.filter(tireSize => tireSize.get('sizeTypeCode') === "A");
			} else if (tireSizes.filter(tireSize => tireSize.get('sizeTypeCode') === "D").size > 0) {
				tireSizeList = tireSizeList.filter(tireSize => tireSize.get('sizeTypeCode') === "D");
			} else if (tireSizes.filter(tireSize => tireSize.get('sizeTypeCode') === "U").size > 0) {
				tireSizeList = tireSizeList.filter(tireSize => tireSize.get('sizeTypeCode') === "U");
			} else if (tireSizes.filter(tireSize => tireSize.get('sizeTypeCode') === "T").size > 0) {
				tireSizeList = tireSizeList.filter(tireSize => tireSize.get('sizeTypeCode') === "T");
			}
		}
		if (tireSizeList.size > 1) {
			tireSizeList = tireSizeList.sort((a, b) => a.get('diameter') > b.get('diameter'));
		}
		if (tireSizeList) {
			tireSize = tireSizeList.get(0);
		}
	}

	if (tireSize) {
		return tireSize.get('key');
	} else {
		return originalTireSize;
	}
};

export const getQuantitiesFromPricingDetailsRefactored = (pricingDetails) => {
  var quantities = [];
	if (!isEmpty(pricingDetails) && sizeOf(pricingDetails) > 0) {
		var priceList = pricingDetails['price'], convertToObject = false;
		if (typeof(priceList) === 'undefined') {
			priceList = pricingDetails.toObject();
			priceList = priceList['price'];
			priceList = priceList.toObject();
			convertToObject = true;
		}
		for (var i in priceList) {
			var price = convertToObject ? priceList[i].toObject() : priceList[i];
			if (sizeOf(price['contextList']) === 1) {
				var context = convertToObject ? price['contextList'].toObject()[0].toObject() : price['contextList'][0];
				var pricingContextId = context['pricingContextId'];
				var promotionText_en = typeof(context['promotionText']['en']) !== 'undefined' ? context['promotionText']['en'] : "";
				var promotionText_fr = typeof(context['promotionText']['fr']) !== 'undefined' ? context['promotionText']['fr'] : "";
				var quantity = price['quantity'];
				quantities.push({
					id: pricingContextId + quantity,
					value: quantity,
					title: {
						en: `${quantity}${promotionText_en ? " - " : ""}${promotionText_en}`,
						fr: `${quantity}${promotionText_fr ? " - " : ""}${promotionText_fr}`
					},
					pricingContextId: pricingContextId
				});
			} else if (sizeOf(price['contextList']) > 1) {
				for (var j in price['contextList']) {
					var context = convertToObject ? price['contextList'].toObject()[j].toObject() : price['contextList'][j];
					var pricingContextId = context['pricingContextId'];
					var promotionText_en = typeof(context['promotionText']['en']) !== 'undefined' ? context['promotionText']['en'] : "";
					var promotionText_fr = typeof(context['promotionText']['fr']) !== 'undefined' ? context['promotionText']['fr'] : "";
					var quantity = price['quantity'];
					quantities.push({
						id: pricingContextId + quantity,
						value: quantity,
						title: {
							en: `${quantity}${promotionText_en ? " - " : ""}${promotionText_en}`,
							fr: `${quantity}${promotionText_fr ? " - " : ""}${promotionText_fr}`
						},
						pricingContextId: pricingContextId
					});
				}
			}
		}
	}

	return quantities;
};

export const mapRelatedParts = (relatedPart, relatedPricings, index) => {
	return Map({
		id: relatedPart.get('itemId'),
		name: getRelatedPartName(relatedPart),
		categories: Immutable.List.of(),
		image: getRelatedPartImage(relatedPart),
		partNumber: getRelatedPartNumber(relatedPart),
		relatedPartText: relatedPart.get('relatedPartText'),
		pricing: relatedPricings.get(index),
		type: getDestinationInverse(relatedPart.get('itemType'))
	});
};

export const getPricingByQuantity = (pricingDetails, quantity) => {
	var price = null;
	if (pricingDetails && quantity) {
		price = getPricingFromQuantityAndDetailsRefactored(pricingDetails, quantity);
		if (price['itemType'] === 'undefined') price['itemType'] = pricingDetails['itemType'];
		if (price['itemId'] === 'undefined') price['itemId'] = pricingDetails['itemId'];
		if (price['prioritizeInSearch'] === 'undefined') price['prioritizeInSearch'] = pricingDetails['prioritizeInSearch'];
	}
	return Immutable.fromJS(price);
};

export const getRebateByQuantity = (pricingDetails, quantity) => {
	var link = null;
	if (pricingDetails && quantity) {
		link = getLinkFromQuantityAndDetailsRefactored(pricingDetails, quantity);
	}

	return Immutable.fromJS(link);
};

export const getCouponRebateByQuantity = (pricingDetails, quantity) => {
	var link = null;
	if (pricingDetails && quantity) {
		link = getLinkFromQuantityAndDetailsRefactored(pricingDetails, quantity);
	}

	var couponLinks = [];
	for (var i in link) {
		if (link[i]['linkLocation'] == "COUPON") {
			couponLinks.push({
				url: link[i]['linkURL'],
				text: link[i]['linkText'],
				body: link[i]['linkBody'],
				pricingContextId: link[i]['pricingContextId'],
				enableModal: link[i]['leadEnabled'] === "1",
				disclaimer: link[i]['leadDisclaimer']
			});
		}
	}

	return Immutable.fromJS(couponLinks);
};

export const getPricingRebateByQuantity = (pricingDetails, quantity) => {
	var link = null;
	if (pricingDetails && quantity) {
		link = getLinkFromQuantityAndDetailsRefactored(pricingDetails, quantity);
	}

	var pricingLinks = [];
	for (var i in link) {
		if (link[i]['linkLocation'] == "PRICE") {
			pricingLinks.push({
				url: link[i]['linkURL'],
				text: link[i]['linkText'],
				body: link[i]['linkBody'],
				pricingContextId: link[i]['pricingContextId'],
				enableModal: link[i]['leadEnabled'] === "1",
				disclaimer: link[i]['leadDisclaimer']
			});
		}
	}

	return Immutable.fromJS(pricingLinks);
};

export const getPricingFromQuantityAndDetailsRefactored = (pricingDetails, quantity) => {
	var pricing = [], priceList = pricingDetails['price'], convertToObject = false;
	// this is partPricing.primaryPart.price;
	// this is the item that is coming in pricingDetails = object
	if (pricingDetails && quantity) {
		if (typeof(priceList) === 'undefined') {
			priceList = pricingDetails.toObject()['price'].toObject();
			if (quantity && typeof(quantity) !== 'object') quantity = quantity.toObject();
			convertToObject = true;
		}

		var quantityValue = parseFloat(quantity['value']), quantityPricingContextId = parseFloat(quantity['pricingContextId']);
		if (quantity && (typeof(quantityValue) === 'undefined' || isNaN(quantityValue))) quantityValue = parseFloat(quantity.get('value'));
		if (quantity && (typeof(quantityPricingContextId) === 'undefined' || isNaN(quantityPricingContextId))) quantityPricingContextId = parseFloat(quantity.get('pricingContextId'));

		var context = null;
		for (var i in priceList) {
			var price = convertToObject ? priceList[i].toObject() : priceList[i];
			if (parseFloat(price['quantity']) === quantityValue) {
				if (sizeOf(price['contextList']) === 1) {
					context = convertToObject ? price['contextList'].toObject()[0].toObject() : price['contextList'][0];
					pricing.push({
						displayLabour: context['displayLabour'],
						displayPrice: context['displayPrice'],
						displaySavings: context['displaySavings'],
						privateLabour: context['privateLabour'],
						privatePrice: context['privatePrice'],
						privateSavings: context['privateSavings'],
						listPrice: context['listPrice'],
						rowPrice: context['listPrice'],
						pricingContextId: context['pricingContextId'],
						showUnitPrice: context['showUnitPrice'],
						promotionText: context['promotionText'],
						promotionPricingText: context['promotionPricingText'],
            oldPrice: context['oldPrice'],
            marketMinimum: context['marketMinimum'],
            isFinancingRule: pricingDetails['isFinancingRule'],
					});
				} else if (sizeOf(price['contextList']) > 1) {
					var contextList = price['contextList'];
					if (typeof(contextList[0]) === 'undefined') contextList = contextList.toObject();
					for (var j in contextList) {
						context = convertToObject ? price['contextList'].toObject()[j].toObject() : price['contextList'][j];
						if (parseFloat(context['pricingContextId']) === quantityPricingContextId) {
							pricing.push({
								displayLabour: context['displayLabour'],
								displayPrice: context['displayPrice'],
								displaySavings: context['displaySavings'],
								privateLabour: context['privateLabour'],
								privatePrice: context['privatePrice'],
								privateSavings: context['privateSavings'],
								listPrice: context['listPrice'],
								rowPrice: context['listPrice'],
								pricingContextId: context['pricingContextId'],
								showUnitPrice: context['showUnitPrice'],
								promotionText: context['promotionText'],
								promotionPricingText: context['promotionPricingText'],
                oldPrice: context['oldPrice'],
                marketMinimum: context['marketMinimum'],
                isFinancingRule: pricingDetails['isFinancingRule'],
							});
						}
					}
				}
			}
		}
	}

	if (sizeOf(pricing) === 0) {
		return {
			displayLabour: 0,
			displayPrice: 0,
			displaySavings: 0,
			privateLabour: 0,
			privatePrice: 0,
			privateSavings: 0,
			listPrice: 0,
			rowPrice: 0,
			pricingContextId: 0,
			showUnitPrice: false,
			promotionText: Immutable.fromJS({ en: "", fr: "" }),
			promotionPricingText: Immutable.fromJS({ en: "", fr: "" }),
			pricingIsValid: false
		};
	} else {
		var link = getPricingRebateByQuantity(pricingDetails, quantity);
		var couponLink = getCouponRebateByQuantity(pricingDetails, quantity);

		if (sizeOf(link) > 0) {
			pricing[0]['url'] = link.get(0).get('url');
			pricing[0]['text'] = link.get(0).get('text');
			pricing[0]['body'] = link.get(0).get('body');
			pricing[0]['pricingIsValid'] = true;
		}

    if (sizeOf(couponLink) > 0) {
      pricing[0]['couponUrl'] = couponLink.get(0).get('url');
      pricing[0]['couponText'] = couponLink.get(0).get('text');
      pricing[0]['couponBody'] = couponLink.get(0).get('body');
    }

		return pricing[0];
	}
};

export const getLinkFromQuantityAndDetailsRefactored = (pricingDetails, quantity) => {
	var links = [], linkList = pricingDetails['link'], convertToObject = false;
	if (pricingDetails && quantity) {
		if (typeof(linkList) === 'undefined') {
			linkList = pricingDetails.toObject()['link'].toObject();
			if (quantity && typeof(quantity) !== 'object') quantity = quantity.toObject();
			convertToObject = true;
		}

		var quantityValue = parseFloat(quantity['value']), quantityPricingContextId = parseFloat(quantity['pricingContextId']);
		if (quantity && (typeof(quantityValue) === 'undefined' || isNaN(quantityValue))) quantityValue = parseFloat(quantity.get('value'));
		if (quantity && (typeof(quantityPricingContextId) === 'undefined' || isNaN(quantityPricingContextId))) quantityPricingContextId = parseFloat(quantity.get('pricingContextId'));

		var link = null, context = null;
		for (var i in linkList) {
			link = convertToObject ? linkList[i].toObject() : linkList[i];
			if (parseFloat(link['quantity']) === quantityValue) {
				if (sizeOf(link['contextList']) === 1) {
					context = convertToObject ? link['contextList'].toObject()[0].toObject() : link['contextList'][0];
					links.push({
						leadDisclaimer: context['leadDisclaimer'],
						leadEnabled: context['leadEnabled'],
						linkBody: context['linkBody'],
						linkLocation: context['linkLocation'],
						linkText: context['linkText'],
						linkURL: context['linkURL'],
						pricingContextId: context['pricingContextId'],
						promotionPricingText: context['promotionPricingText'],
						promotionText: context['promotionText']
					});
				} else if (sizeOf(link['contextList']) > 1) {
					var contextList = link['contextList'];
					if (typeof(contextList[0]) === 'undefined') contextList = contextList.toObject();
					for (var j in contextList) {
					context = convertToObject ? link['contextList'].toObject()[j].toObject() : link['contextList'][j];
						links.push({
							leadDisclaimer: context['leadDisclaimer'],
							leadEnabled: context['leadEnabled'],
							linkBody: context['linkBody'],
							linkLocation: context['linkLocation'],
							linkText: context['linkText'],
							linkURL: context['linkURL'],
							pricingContextId: context['pricingContextId'],
							promotionPricingText: context['promotionPricingText'],
							promotionText: context['promotionText']
						});
					}
				}
			}
		}
	}

	if (!links) {
		return {
			leadDisclaimer: { en: "", fr: "" },
			leadEnabled: null,
			linkBody: { en: "", fr: "" },
			linkLocation: "",
			linkText: { en: "", fr: "" },
			linkURL: { en: "", fr: "" },
			pricingContextId: 0,
			promotionPricingText: { en: "", fr: "" },
			promotionText: { en: "", fr: "" }
		};
	} else {
		return links;
	}
};

export const packageMappingFunction = (featured, enableStock, quantityOverride) => {
	return (pkg) => {
		var hasPromotion = false, primaryPartPricing = null, relatedPartPricing = null;
		if (checkArrayFieldsExist(pkg, ['partPricing', 'primaryPart', 'price'])) {
			var price = pkg['partPricing']['primaryPart']['price'];
			for (var i in price) if (sizeOf(price[i]['contextList']) > 1 || parseInt(price[i]['contextList'][0]['pricingContextId']) > 0) hasPromotion = true;
			primaryPartPricing = pkg['partPricing']['primaryPart'];
			relatedPartPricing = pkg['partPricing']['relatedParts'];
		}

		pkg = Immutable.fromJS(pkg);

		var packageDetail = pkg.get('partDetails').get('primaryPart');
		var pricingDetails = primaryPartPricing;
		var packageSummary = pkg.get('partSummary').get('primaryPart');
		var relatedPricing = pkg.get('partPricing').get('relatedParts');
		var dynamicName = pkg.get('partDynamicName');
		var quantities = getQuantitiesFromPricingDetailsRefactored(pricingDetails);
		var quantityIndex = quantityOverride ? 1 : 1;

		var quantity = {
			id: 0,
			value: 0,
			title: { en: "", fr: "" },
			pricingContextId: 0
		};

		for (var i in quantities) {
			if (quantity.id === 0) {
				if (parseFloat(quantities[i]['value']) === quantityIndex) quantity = quantities[i];
			}
		}

		if (quantity.id === 0 || typeof quantity === 'undefined') {
			for (var i in quantities) {
				quantity = quantities[i];
				break;
			}
		}

		function mapRelatedParts(relatedPart, index) {
			const quantities = getQuantitiesFromPricingDetailsRefactored(relatedPartPricing[index]);
			const quantity = quantities[0];

			return Immutable.Map({
				id: relatedPart.get('itemId'),
				name: getRelatedPartName(relatedPart),
				categories: Immutable.List.of(),
				image: getRelatedPartImage(relatedPart),
				partNumber: getRelatedPartNumber(relatedPart),
				relatedPartText: relatedPart.get('relatedPartText'),
				pricing: relatedPartPricing[index],
				price: getPricingFromQuantityAndDetailsRefactored(relatedPartPricing[index], quantity),
				quantities: quantities,
				quantity: quantity,
				type: getDestinationInverse(relatedPart.get('itemType'))
			});
		}

		return Immutable.fromJS({
			id: packageDetail.get('itemId'),
			title: packageDetail.get('packageName'),
			dynamicTitle: dynamicName,
			description: packageDetail.get('packageDescription'),
			partNumber: packageDetail.get('packagePartNumber'),
			manufacturerName: packageDetail.get('packageManufacturerName'),
			image: packageDetail.get('packageImageLink'),
      hasRunFlatPart: packageDetail.get('packageItemHasRunFlat'),
			pricingDetails: pricingDetails,
			pricing: getPricingByQuantity(pricingDetails, quantity),
			rebate: getRebateByQuantity(pricingDetails, quantity),
			purchasable: packageSummary.get('itemHasDealerPricing') && packageSummary.get('itemHasStock'),
			hasPricing: packageSummary.get('itemHasDealerPricing'),
			hasStock: packageSummary.get('itemHasStock'),
			quantities: quantities,
			quantity: quantity,
			hasPromotion: hasPromotion,
			relatedParts: pkg.get('partDetails').get('relatedParts').map(mapRelatedParts),
			recommendedVehicleList: packageDetail.get('packageRecommendedVehiclesList'),
			packageWarning: packageDetail.get('itemWarning'),
			impressionData: packageSummary.get('itemImpressionData'),
      visualizerImage: pkg.get('partVisualizerAsset'),
      type: ProductTypes.PACKAGES,
			secondaryImages: packageSummary.get('itemImageSecondaryLinkList') ? packageSummary.get('itemImageSecondaryLinkList').map(function (image) {
				return Immutable.Map({
					imageUrl: image.get('imageUrl'),
					thumbnailUrl: image.get('thumbnailUrl'),
					sortOrder: image.get('imageSortOrder')
				});
			}) : null,
      lineItems: packageDetail.get('packageLineItem') ? packageDetail.get('packageLineItem').map(function (lineItem) {
        return Immutable.Map({
          title: lineItem.get('title'),
          quantity: lineItem.get('qty')
        });
      }) : Immutable.List.of({}),
			items: packageDetail.get('packageItem').map(function (packageItem) {
				return Immutable.Map({
					id: packageItem.get('itemId'),
					partType: packageItem.get('itemType'),
					partNumber: packageItem.get('itemPartNumber'),
					quantity: packageItem.get('itemQuantity'),
					title: packageItem.get('itemTitle'),
					description: packageItem.get('itemDescription'),
					partDescription: packageItem.get('itemPartDescription'),
					typeDescription: packageItem.get('itemTypeDescription'),
					itemLineItem: packageItem.get('itemPackageLineItem').map(function (lineItem) {
						return Immutable.Map({
							lineItemId: lineItem.get('lineItemId'),
							title: lineItem.get('title'),
							description: lineItem.get('description'),
							quantity: lineItem.get('qty'),
							matchItemQty: lineItem.get('matchItemQty'),
							unitPrice: lineItem.get('unitPrice'),
							totalPrice: lineItem.get('totalPrice')
						});
					})
				});
			})
		});
	};
};

export const accessoryMappingFunction = (featured, enableStock, quantityOverride) => {
	return (accessory) => {
		var hasPromotion = false, primaryPartPricing = null, relatedPartPricing = null;
		if (checkArrayFieldsExist(accessory, ['partPricing', 'primaryPart', 'price'])) {
			var price = accessory['partPricing']['primaryPart']['price'];
			for (var i in price) if (sizeOf(price[i]['contextList']) > 1 || parseInt(price[i]['contextList'][0]['pricingContextId']) > 0) hasPromotion = true;
			primaryPartPricing = accessory['partPricing']['primaryPart'];
			relatedPartPricing = accessory['partPricing']['relatedParts'];
		}

		accessory = Immutable.fromJS(accessory);

		var accessoryDetail = accessory.get('partDetails').get('primaryPart');
		var accessorySummary = accessory.get('partSummary').get('primaryPart');
		var stock = accessory.get('partStock').get('primaryPart').map(stockDetail => stockDetail.set('loading', stockDetail.get('stockApiForceRefreshOnLoad') && stockDetail.get('stockApiQueryUrl') !== null));
		var categories = [];
		var categoryIdList = [];
		accessoryDetail.get('accessoryCategories').forEach(function (categoryGroup) {
			categoryGroup.forEach(function (category, index) {
				categories.push(category);
				categoryIdList.push(index);
			});
		});

		var pricingDetails = primaryPartPricing;
		var relatedPricings = accessory.get('partPricing').get('relatedParts');
		var quantities = getQuantitiesFromPricingDetailsRefactored(pricingDetails);
		var quantityIndex = quantityOverride ? 1 : 1;

		var quantity = {
			id: 0,
			value: 0,
			title: { en: "", fr: "" },
			pricingContextId: 0
		};

		for (var i in quantities) {
			if (quantity.id === 0) {
				if (parseFloat(quantities[i]['value']) === quantityIndex) quantity = quantities[i];
			}
		}

		if (quantity.id === 0 || typeof quantity === 'undefined') {
			for (var i in quantities) {
				quantity = quantities[i];
				break;
			}
		}

		function mapRelatedParts(relatedPart, index) {
			const quantities = getQuantitiesFromPricingDetailsRefactored(relatedPartPricing[index]);
			const quantity = quantities[0];

			var pricing = relatedPartPricing[index];

			return Immutable.Map({
				id: relatedPart.get('itemId'),
				name: getRelatedPartName(relatedPart),
				categories: relatedPart.get('accessoryCategories').get(0),
				image: getRelatedPartImage(relatedPart),
				partNumber: getRelatedPartNumber(relatedPart),
				relatedPartText: relatedPart.get('relatedPartText'),
				description: relatedPart.get('accessoryDescription'),
				pricing: relatedPartPricing[index],
				price: getPricingFromQuantityAndDetailsRefactored(pricing, quantity),
				quantities: quantities,
				quantity: quantity,
				type: getDestinationInverse(relatedPart.get('itemType'))
			});
		}

		return Immutable.fromJS({
			id: accessoryDetail.get('itemId'),
			stock: stock,
			name: accessoryDetail.get('accessoryName'),
			description: accessoryDetail.get('accessoryDescription'),
			partNumber: accessoryDetail.get('accessoryPartNumber'),
			pricingDetails: pricingDetails,
			pricing: getPricingByQuantity(pricingDetails, quantity),
			rebate: getRebateByQuantity(pricingDetails, quantity),
			purchasable: accessorySummary.get('itemHasDealerPricing') && (accessorySummary.get('itemHasStock') || enableStock),
			hasPricing: accessorySummary.get('itemHasDealerPricing'),
			hasStock: accessorySummary.get('itemHasStock'),
			image: accessoryDetail.get('accessoryImageLink'),
			restrictions: accessoryDetail.get('accessoryRestrictions'),
			quantities: quantities,
			quantity: quantity,
			hasPromotion: hasPromotion,
			requiresInstallation: accessoryDetail.get('accessoryRequiresInstallation') === '1',
			recommendedVehicleList: accessoryDetail.get('accessoryRecommendedVehiclesList'),
      isPackage: accessoryDetail.get('isPackage'),
			categories: categories,
			categoryIdList: categoryIdList,
			relatedParts: accessory.get('partDetails').get('relatedParts').map(mapRelatedParts),
			impressionData: accessorySummary.get('itemImpressionData'),
      type: ProductTypes.ACCESSORIES
		});
	};
};

export const wheelMappingFunction = (featured, enableStock, fitmentCode, quantityOverride) => {
	const mapWheel = (partDetail, pricingDetails, stockDetails, staggered, partSummary, enableStock, front, fitmentCode) => {
		var stock = stockDetails.map(stockDetail => stockDetail.set('loading', stockDetail.get('stockApiForceRefreshOnLoad') && stockDetail.get('stockApiQueryUrl') !== null));
		var quantities = getQuantitiesFromPricingDetailsRefactored(pricingDetails);

		var quantityIndex = null;
		if (!quantityOverride) {
			switch (fitmentCode) {
				case "AFS":
					quantityIndex = 4;
					break;
				case "SFS":
					quantityIndex = 2;
					break;
				case "AFD":
					quantityIndex = 6;
					break;
				case "SFD":
					quantityIndex = front ? 2 : 4;
					break;
				default:
					quantityIndex = staggered ? DEFAULT_SELECTED_QUANTITY_OF_PRODUCT_STAGGERED : DEFAULT_SELECTED_QUANTITY_OF_PRODUCT;
					break;
			}
		} else {
			quantityIndex = 1;
		}

		var quantity = {
			id: 0,
			value: 0,
			title: { en: "", fr: "" },
			pricingContextId: 0
		};

		for (var i in quantities) {
			if (parseFloat(quantities[i]['value']) === quantityIndex) {
				quantity = quantities[i];
				break;
			}
		}

		if (quantity.id === 0 || typeof quantity === 'undefined') {
			for (var i in quantities) {
				quantity = quantities[i];
				break;
			}
		}

		return {
			id: partDetail.get('itemId'),
			stock: stock,
			quantity: quantity,
			partNumber: partDetail.get('wheelNationalPartNumber') ? partDetail.get('wheelNationalPartNumber') : partDetail.get('wheelPartNumber'),
			pricingDetails: pricingDetails,
			pricing: getPricingByQuantity(pricingDetails, quantity),
			rebate: getRebateByQuantity(pricingDetails, quantity),
			purchasable: partSummary.get('itemHasDealerPricing') && (partSummary.get('itemHasStock') || enableStock),
			hasPricing: partSummary.get('itemHasDealerPricing'),
			hasStock: partSummary.get('itemHasStock'),
			recommendedVehicleList: partDetail.get('wheelRecommendedVehiclesList'),
			quantities: quantities,
			diameter: partDetail.get('wheelDiameter'),
			width: partDetail.get('wheelWidth'),
			offset: partDetail.get('wheelOffset'),
			note: partDetail.get('wheelComment'),
			impressionData: partSummary.get('itemImpressionData')
		};
	};

	return (wheel) => {
		var hasPromotion = false, primaryPartPricing = null, secondaryPartPricing = null, relatedPartPricing = null;
		if (checkArrayFieldsExist(wheel, ['partPricing', 'primaryPart', 'price'])) {
			var price = wheel['partPricing']['primaryPart']['price'];
			for (var i in price) if (sizeOf(price[i]['contextList']) > 1 || parseInt(price[i]['contextList'][0]['pricingContextId']) > 0) hasPromotion = true;
			primaryPartPricing = wheel['partPricing']['primaryPart'];
			secondaryPartPricing = wheel['partPricing']['secondaryParts'][0];
			relatedPartPricing = wheel['partPricing']['relatedParts'];
		}

		wheel = Immutable.fromJS(wheel);

		function mapRelatedParts(relatedPart, index) {
			const quantities = getQuantitiesFromPricingDetailsRefactored(relatedPartPricing[index]);
			const quantity = quantities[0];

			return Immutable.Map({
				id: relatedPart.get('itemId'),
				name: getRelatedPartName(relatedPart),
				categories: Immutable.List.of(),
				image: getRelatedPartImage(relatedPart),
				partNumber: getRelatedPartNumber(relatedPart),
				relatedPartText: relatedPart.get('relatedPartText'),
				pricing: relatedPartPricing[index],
				price: getPricingFromQuantityAndDetailsRefactored(relatedPartPricing[index], quantity),
				quantities: quantities,
				quantity: quantity,
				type: getDestinationInverse(relatedPart.get('itemType'))
			});
		}

		var staggered = sizeOf(wheel.get('partDetails').get('secondaryParts')) > 0;

		var primaryWheelSummary = wheel.get('partDetails').get('primaryPart');

		return Immutable.fromJS({
			name: primaryWheelSummary.get('wheelName'),
			seasonalSize: primaryWheelSummary.get('wheelSeasonalSizeCode'),
			description: primaryWheelSummary.get('wheelDescription'),
			frontWheel: mapWheel(wheel.get('partDetails').get('primaryPart'), primaryPartPricing, wheel.get('partStock').get('primaryPart'), staggered, wheel.get('partSummary').get('primaryPart'), enableStock, true, fitmentCode),
			rearWheel: staggered ? mapWheel(wheel.get('partDetails').get('secondaryParts').get(0), secondaryPartPricing, wheel.get('partStock').get('secondaryParts').get(0), staggered, wheel.get('partSummary').get('secondaryParts').get(0), enableStock, false, fitmentCode) : null,
			image: primaryWheelSummary.get('wheelImageLink'),
			images: primaryWheelSummary.get('wheelVehicleImages'),
			alloy: !!primaryWheelSummary.get('wheelFinish'),
			staggered: staggered,
			hasPromotion: hasPromotion,
			relatedParts: wheel.get('partDetails').get('relatedParts').map(mapRelatedParts),
			visualizerImage: wheel.get('partVisualizerAsset'),
      type: ProductTypes.ALLOY_WHEELS
		});
	};
};

export const tireMappingFunction = (featured, enableStock, fitmentCode, quantityOverride) => {

	const mapTire = (partDetails, pricing, pricingDetails, stockDetails, staggered, front, partSummary, enableStock, fitmentCode, hasPromotion) => {
		const stock = stockDetails.map(function(stockDetail) {
			return stockDetail.set('loading', stockDetail.get('stockApiForceRefreshOnLoad') && stockDetail.get('stockApiQueryUrl') !== null)
    });
		const quantities = getQuantitiesFromPricingDetailsRefactored(pricing);
		var quantityIndex = null;
		if (!quantityOverride) {
			switch (fitmentCode) {
				case "AFS":
					quantityIndex = 4;
					break;
				case "SFS":
					quantityIndex = 2;
					break;
				case "AFD":
					quantityIndex = 6;
					break;
				case "SFD":
					quantityIndex = !front ? 2 : 4;
					break;
				default:
					quantityIndex = staggered ? DEFAULT_SELECTED_QUANTITY_OF_PRODUCT_STAGGERED : DEFAULT_SELECTED_QUANTITY_OF_PRODUCT;
					break;
			}
		} else {
			quantityIndex = 1;
		}

		var tireQuantity = {
			id: 0,
			value: 0,
			title: { en: "", fr: "" },
			pricingContextId: 0
		};

		for (var i in quantities) {
			if (tireQuantity.id === 0) {
				if (parseFloat(quantities[i]['value']) === quantityIndex) tireQuantity = quantities[i];
			}
		}

		if (tireQuantity.id === 0 || typeof tireQuantity === 'undefined') {
			for (var i in quantities) {
				tireQuantity = quantities[i];
				break;
			}
		}

		var tire = {
			id: partDetails.get('itemId'),
			stock: stock,
			pricingDetails: pricing,
			pricing: getPricingByQuantity(pricing, tireQuantity),
      pricingLastUpdated: pricing['priceLastUpdated'],
			rebate: getRebateByQuantity(pricing, tireQuantity),
			purchasable: partSummary.get('itemHasDealerPricing') && (partSummary.get('itemHasStock') || enableStock),
			hasPricing: partSummary.get('itemHasDealerPricing'),
			hasStock: partSummary.get('itemHasStock'),
			quantities: quantities,
			quantity: tireQuantity,
			tireSize: partDetails.get('formattedTiresize'),
			tireSizeSpecId: partDetails.get('tireSizeSpecId'),
			nationalPartNumber: partSummary.get('itemNationalPartNumber'),
			partNumber: partDetails.get('tireManufacturerPartNumber') ? partDetails.get('tireManufacturerPartNumber') : partDetails.get('itemNationalPartNumber'),
			loadRating: partDetails.get('tireLoadRating'),
			dualFitmentLoadRating: partDetails.get('tireDualFitmentLoadRating'),
			speedRating: partDetails.get('tireSpeedIndex'),
			plyRating: partDetails.get('tirePlyRating'),
			tireVehicleClass: partDetails.get('tireVehicleClass'),
      tireCategoryTags: partDetails.get('tireCategoryTags'),
			front: front,
			runFlat: partDetails.get('tireRunFlat'),
			warranty: partDetails.get('tireWarrantyList'),
			impressionData: partSummary.get('itemImpressionData'),
      supportDealerOrder: partSummary.get('supportDealerOrder'),
      supportConsumerOrder: partSummary.get('supportConsumerOrder')
		};

		return tire;
	};

	return (tire) => {
		var featured = false, position = null;
		if (typeof(tire['part']) !== 'undefined') {
			position = typeof(tire['position']) !== 'undefined' ? tire['position'] : null;
			featured = position !== null ? true : false;
			tire = tire['part'];
		}

		var hasPromotion = false, primaryPartPricing = null, secondaryPartPricing = null;
		if (checkArrayFieldsExist(tire, ['partPricing', 'primaryPart', 'price'])) {
			var price = tire['partPricing']['primaryPart']['price'];
			for (var i in price) {
				if (sizeOf(price[i]['contextList']) > 1 || parseInt(price[i]['contextList'][0]['pricingContextId']) > 0) hasPromotion = true;
			}
			primaryPartPricing = tire['partPricing']['primaryPart'];
			secondaryPartPricing = tire['partPricing']['secondaryParts'][0];
		}

		tire = Immutable.fromJS(tire);

    if (tire) {

      const staggered = sizeOf(tire.get('partDetails').get('secondaryParts')) > 0;
      const relatedPricings = tire.get('partPricing').get('relatedParts');
      const primaryPartDetails = tire.get('partDetails').get('primaryPart');

      var relatedParts = tire.get('partDetails').get('relatedParts').map(mapRelatedParts.bind(null, relatedPricings));

      var tempMilage = Immutable.fromJS(primaryPartDetails.get('tireTreadlifeWarranty'));
      var mileageWarranty = {
        "CA": tempMilage.get("CA"),
        "US": tempMilage.get("US")
      };

      return Immutable.fromJS({
        manufacturer: primaryPartDetails.get('tireManufacturerName'),
        manufacturerId: primaryPartDetails.get('tireManufacturerId'),
        loadRating: primaryPartDetails.get('tireLoadRating'),
        speedRating: primaryPartDetails.get('tireSpeedIndex'),
        brandImage: primaryPartDetails.get('tireManufacturerImage'),
        smallImage: primaryPartDetails.get('tireImageLink'),
        image: primaryPartDetails.get('tireImageLink'),
        largeImage: primaryPartDetails.get('tireImageLink'),
        name: primaryPartDetails.get('tireModelName'),
        description: primaryPartDetails.get('tireModelDescription'),
        tireSeason: primaryPartDetails.get('tireSeason'),
        featured: featured,
        position: position,
        frontTire: mapTire(tire.get('partDetails').get('primaryPart'), primaryPartPricing, tire.get('partPricing').get('primaryPart'), tire.get('partStock').get('primaryPart'), staggered, true, tire.get('partSummary').get('primaryPart'), enableStock, fitmentCode, hasPromotion),
        rearTire: staggered ? mapTire(tire.get('partDetails').get('secondaryParts').get(0), secondaryPartPricing, tire.get('partPricing').get('secondaryParts').get(0), tire.get('partStock').get('secondaryParts').get(0), staggered, false, tire.get('partSummary').get('secondaryParts').get(0), enableStock, fitmentCode, hasPromotion) : null,
        staggered: staggered,
        hasPromotion: hasPromotion,
        relatedParts: relatedParts,
        tireTreadlifeWarranty: mileageWarranty,
        originalEquipment: primaryPartDetails.get('tireOriginalEquipmentFlag'),
        recommendedVehicleList: primaryPartDetails.get('tireRecommendedVehiclesList'),
        type: ProductTypes.TIRES
      });

		} else {

      return Immutable.fromJS({});

		}

	};
};

export const tireObjectFunction = (tire, featured, enableStock, fitmentCode, quantityOverride) => {
	const mapTire = (partDetails, pricing, pricingDetails, stockDetails, staggered, front, partSummary, enableStock, fitmentCode, hasPromotion) => {
    const stock = stockDetails.map(function(stockDetail) {
      return stockDetail.set('loading', stockDetail.get('stockApiForceRefreshOnLoad') && stockDetail.get('stockApiQueryUrl') !== null)
    });
		const quantities = getQuantitiesFromPricingDetailsRefactored(pricing);
		var quantityIndex = null;
		if (!quantityOverride) {
			switch (fitmentCode) {
				case "AFS":
					quantityIndex = 4;
					break;
				case "SFS":
					quantityIndex = 2;
					break;
				case "AFD":
					quantityIndex = 6;
					break;
				case "SFD":
					quantityIndex = !front ? 2 : 4;
					break;
				default:
					quantityIndex = staggered ? DEFAULT_SELECTED_QUANTITY_OF_PRODUCT_STAGGERED : DEFAULT_SELECTED_QUANTITY_OF_PRODUCT;
					break;
			}
		} else {
			quantityIndex = 1;
		}

		var tireQuantity = {
			id: 0,
			value: 0,
			title: { en: "", fr: "" },
			pricingContextId: 0
		};

		for (var i in quantities) {
			if (tireQuantity.id === 0) {
				if (parseFloat(quantities[i]['value']) === quantityIndex) tireQuantity = quantities[i];
			}
		}

		if (tireQuantity.id === 0 || typeof tireQuantity === 'undefined') {
			for (var i in quantities) {
				tireQuantity = quantities[i];
				break;
			}
		}

		var tire = {
			id: partDetails.get('itemId'),
			stock: stock,
			pricingDetails: pricing,
			pricing: getPricingByQuantity(pricing, tireQuantity),
			pricingLastUpdated: pricing['priceLastUpdated'],
			rebate: getRebateByQuantity(pricing, tireQuantity),
			purchasable: partSummary.get('itemHasDealerPricing') && (partSummary.get('itemHasStock') || enableStock),
			hasPricing: partSummary.get('itemHasDealerPricing'),
			hasStock: partSummary.get('itemHasStock'),
      supportConsumerOrder: partSummary.get('supportConsumerOrder'),
      supportDealerOrder: partSummary.get('supportDealerOrder'),
			quantities: quantities,
			quantity: tireQuantity,
			tireSize: partDetails.get('formattedTiresize'),
			tireSizeSpecId: partDetails.get('tireSizeSpecId'),
			nationalPartNumber: partSummary.get('itemNationalPartNumber'),
			partNumber: partDetails.get('tireManufacturerPartNumber') ? partDetails.get('tireManufacturerPartNumber') : partDetails.get('itemNationalPartNumber'),
			loadRating: partDetails.get('tireLoadRating'),
			dualFitmentLoadRating: partDetails.get('tireDualFitmentLoadRating'),
			speedRating: partDetails.get('tireSpeedIndex'),
			plyRating: partDetails.get('tirePlyRating'),
			tireVehicleClass: partDetails.get('tireVehicleClass'),
      tireCategoryTags: partDetails.get('tireCategoryTags'),
			front: front,
			runFlat: partDetails.get('tireRunFlat'),
			warranty: partDetails.get('tireWarrantyList'),
			impressionData: partSummary.get('itemImpressionData')
		};

		return tire;
	};

	var featured = false, position = null;
	if (typeof(tire['part']) !== 'undefined') {
		position = typeof(tire['position']) !== 'undefined' ? tire['position'] : null;
		featured = position !== null ? true : false;
		tire = tire['part'];
	}

	var hasPromotion = false, primaryPartPricing = null, secondaryPartPricing = null;
	if (checkArrayFieldsExist(tire, ['partPricing', 'primaryPart', 'price'])) {
		var price = tire['partPricing']['primaryPart']['price'];
		for (var i in price) {
			if (sizeOf(price[i]['contextList']) > 1 || parseInt(price[i]['contextList'][0]['pricingContextId']) > 0) {
				hasPromotion = true;
				break;
	    }
		}
		primaryPartPricing = tire['partPricing']['primaryPart'];
		secondaryPartPricing = tire['partPricing']['secondaryParts'][0];
	}

	tire = Immutable.fromJS(tire);

	const staggered = sizeOf(tire.get('partDetails').get('secondaryParts')) > 0;
	const relatedPricings = tire.get('partPricing').get('relatedParts');
	const primaryPartDetails = tire.get('partDetails').get('primaryPart');

	var relatedParts = tire.get('partDetails').get('relatedParts').map(mapRelatedParts.bind(null, relatedPricings));

	var tempMilage = Immutable.fromJS(primaryPartDetails.get('tireTreadlifeWarranty'));
	var mileageWarranty = {
		"CA": tempMilage.get("CA"),
		"US": tempMilage.get("US")
	};

	var tireObject = Immutable.fromJS({
		manufacturer: primaryPartDetails.get('tireManufacturerName'),
		manufacturerId: primaryPartDetails.get('tireManufacturerId'),
		loadRating: primaryPartDetails.get('tireLoadRating'),
		speedRating: primaryPartDetails.get('tireSpeedIndex'),
		brandImage: primaryPartDetails.get('tireManufacturerImage'),
		smallImage: primaryPartDetails.get('tireImageLink'),
		image: primaryPartDetails.get('tireImageLink'),
		largeImage: primaryPartDetails.get('tireImageLink'),
		name: primaryPartDetails.get('tireModelName'),
		description: primaryPartDetails.get('tireModelDescription'),
		tireSeason: primaryPartDetails.get('tireSeason'),
		featured: featured,
		position: position,
		frontTire: mapTire(tire.get('partDetails').get('primaryPart'), primaryPartPricing, tire.get('partPricing').get('primaryPart'), tire.get('partStock').get('primaryPart'), staggered, true, tire.get('partSummary').get('primaryPart'), enableStock, fitmentCode, hasPromotion),
		rearTire: staggered ? mapTire(tire.get('partDetails').get('secondaryParts').get(0), secondaryPartPricing, tire.get('partPricing').get('secondaryParts').get(0), tire.get('partStock').get('secondaryParts').get(0), staggered, false, tire.get('partSummary').get('secondaryParts').get(0), enableStock, fitmentCode, hasPromotion) : null,
		staggered: staggered,
		hasPromotion: hasPromotion,
		relatedParts: relatedParts,
		tireTreadlifeWarranty: mileageWarranty,
		originalEquipment: primaryPartDetails.get('tireOriginalEquipmentFlag'),
		recommendedVehicleList: primaryPartDetails.get('tireRecommendedVehiclesList'),
		type: ProductTypes.TIRES
	});

	return tireObject;
};

export const getWarningType = () => {
  var warning = productListStore.data.warning;
  if (sizeOf(warning) > 0) {
		if (warning.seasonHasChanged) {
			return "Season";
		} else if (warning.tiresizeHasChanged) {
      return "Tiresize";
		}
	}
	return "";
};
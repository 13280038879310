
export var ProvinceArray = {
    AB: {en: 'Alberta', fr: 'Alberta'},
    BC: {en: 'British Columbia', fr: 'Colombie-Britannique'},
    MB: {en: 'Manitoba', fr: 'Manitoba'},
    NB: {en: 'New Brunswick', fr: 'Nouveau-Brunswick'},
    NL: {en: 'Newfoundland and Labrador', fr: 'Terre-Neuve et Labrador'},
    NT: {en: 'Northwest Territories', fr: 'Territoires du Nord-Ouest'},
    NS: {en: 'Nova Scotia', fr: 'Nouvelle-Écosse'},
    NU: {en: 'Nunavut', fr: 'Nunavut'},
    ON: {en: 'Ontario', fr: 'Ontario'},
    PE: {en: 'Prince Edward Island', fr: 'L\'île du Prince-Édouard'},
    QC: {en: 'Québec', fr: 'Québec'},
    SK: {en: 'Saskatchewan', fr: 'Saskatchewan'},
    YT: {en: 'Yukon', fr: 'Yukon'}
};

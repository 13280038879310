import {createSlice} from '@reduxjs/toolkit';
export const routes = createSlice({
    name:"routes",
    initialState:{
        routerState:null,
        productType:null,
        routerForward:null
    },
    reducers:{
        routerState:(state,action) => {
            state.routerState = action.payload;
        },
        productType:(state,action) => {
            state.productType = action.payload;
        },
        routerForward:(state,action) => {
            state.routerForward = action.payload;
        },

    }
});


export const {routerState,productType,routerForward} = routes.actions;
export default routes.reducer;

import { connect } from "react-redux";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { applicationActions } from "../../actions/ApplicationActions.js";
import { productListStore } from "../../stores/ProductListStore.js";
import { productListActions } from "../../actions/ProductListActions.js";
import { productDetailActions } from "../../actions/ProductDetailActions.js";
import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";

import { SelectBox } from "../ux/SelectBox.jsx";

import { RouterStates } from "../../constants/RouterStates";
import { ProductTypes } from "../../constants/ProductTypes.js";

import {
  isEmpty,
  sizeOf,
  getAppLanguage,
  isDealerSite,
  isConsumerSite,
  mileage_format,
  money_format,
  validateEmail,
  getAppDealerId,
  isIEBrowser,
} from "../../service/UtilityService.js";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  ADOBELAUNCHER_FORM_SUBMIT,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";
import {
  tiresizeHasSingleWheel,
  tiresizeHasWheels,
} from "../../service/VehicleService.js";
import {
  renderNTDButton,
  getPurchasNtdButtonText,
} from "../../service/OrderService.js";
import { getTestId, TEST_LIST } from "../../service/TestingService.js";
import {
  getInitialQuantity,
  // NOTE: Not implemented
  // anOpenEmailPromotionalModal
} from "../../service/ProductList.js";
import { isUserLoggedIn } from "../../api/AuthService";
import { get } from "../../api/ApiService";
import store from "../../stores/configureStore.js";
import { openDetail } from "../../redux/features/productDetails.js";
import {
  onAddProductToComparison,
  resettireSizeFilters,
  signUpRequestModal,
} from "../../redux/features/productList.js";
import { addToCart } from "../../redux/features/shoppingCart.js";
import {
  toggleLoginDialog,
  toggleLoginTextDialog,
} from "../../redux/features/applicationInfo";
import { getAppTemplate } from "../../service/UtilityService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var ReactDOM = require("react-dom");
var Immutable = require("immutable");
var $ = require("jquery");
var _ = require("lodash");
var Cx = require("classnames");

const VIEW = {
  GRID: "grid",
  LIST: "list",
  GUIDE: "guide",
};

const checkTypes = {
  STOCK: "check-stock",
  PRICE: "check-price",
};

const placements = {
  FRONT: "front",
  REAR: "rear",
};

class ProductTile extends I18nComponent {
  constructor(props) {
    super(props);
    var staggered =
        !isEmpty(this.props.part) &&
        this.props.part.get("partDetails").get("secondaryParts").size > 0,
      quantity = !staggered ? getInitialQuantity(staggered) : 2,
      contextId = 0,
      finance =
        this.props.part
          .get("partPricing")
          .get("primaryPart")
          .get("isFinancingRule") &&
        applicationStore.data.enableFinancing !== "0";
    if (
      !isEmpty(this.props.part) &&
      sizeOf(
        this.props.part.get("partPricing").get("primaryPart").get("price")
      ) > 0
    ) {
      this.props.part
        .get("partPricing")
        .get("primaryPart")
        .get("price")
        .map(function (price) {
          if (price.get("quantity") == quantity) {
            price.get("contextList").map(function (context, index) {
              if (index == 0) {
                contextId = parseInt(context.get("pricingContextId"));
              }
            });
          }
        });
    }

    //console.log('getInitialState, id: '+this.props.part.get('partDetails').get('primaryPart').get('itemId')+', finance: '+finance);

    this.state = {
      quantity: quantity,
      rearquantity: 2,
      contextId: contextId,
      rearcontextId: 0,

      stock: false,
      updatingStock: [],
      updatedStock: [],
      staggered: staggered,

      consumerModalSendSuccess: false,
      consumerModalSendError: false,

      autoadd: false,

      enableFinancing: finance,

      accessorypackageimageindex: 0,
      accessorypackageimagelink: this.isAccessoryList()
        ? this.props.part
            .get("partDetails")
            .get("primaryPart")
            .get("accessoryImageLink")
        : "",

      didupdaatenormal: false,
      didupdaatestaggered: false,
      mobile: window.innerWidth < 1024,
    };
  }

  updateState = (state) => {
    this.setState(state);
  };
  reSize = () => {
    this.updateState({
      mobile: window.innerWidth < 1024,
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.reSize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.reSize);
  }

  componentDidUpdate(lastProps) {
    if (
      !this.state.didupdaatestaggered &&
      this.props.part.get("partDetails").get("secondaryParts").size > 0
    ) {
      this.setState({
        quantity: 2,
        staggered: true,
        didupdaatestaggered: true,
        didupdaatenormal: false,
      });
    } else if (
      !this.state.didupdaatenormal &&
      this.props.part.get("partDetails").get("secondaryParts").size == 0
    ) {
      this.setState({
        quantity: getInitialQuantity(),
        staggered: false,
        didupdaatenormal: true,
        didupdaatestaggered: false,
      });
    }

    if (
      !this.state.autoadd &&
      parseInt(this.props.autoadd) > 0 &&
      this.props.autoadd ==
        this.props.part.get("partDetails").get("primaryPart").get("itemId")
    ) {
      this.setState({ autoadd: true });
      setTimeout(() => {
        this.props.resetAutoAdd();
        setTimeout(() => {
          this.onAddToCart();
        }, 100);
      }, 100);
    }
  }

  render() {
    if (this.props.visualizer) {
      return this.renderVisualizer();
    } else if (this.props.pvisualizer) {
      return this.renderPackageVisualizer();
    } else if (this.props.view === VIEW.LIST) {
      return this.renderTable();
    } else if (this.props.view === VIEW.GUIDE) {
      return this.renderGuide();
    } else if (this.enableReducedTile()) {
      return this.renderReduced();
    } else if (this.props.featured || this.state.mobile) {
      return this.renderFeatured();
    } else {
      return this.renderNormal();
    }
  }

  isTireList = () => {
    return applicationStore.data.productType === ProductTypes.TIRES;
  };

  isAccessoryList = () => {
    return applicationStore.data.productType === ProductTypes.ACCESSORIES;
  };

  isPackageList = () => {
    return applicationStore.data.productType === ProductTypes.PACKAGES;
  };

  isWheelList = () => {
    return applicationStore.data.productType === ProductTypes.ALLOY_WHEELS;
  };

  isAccessoryPackagePart = (check = "1", isDetail = false) => {
    return (
      this.isAccessoryList() &&
      (isDetail || !this.state.mobile) &&
      this.props.part.get("partDetails").get("primaryPart").get("isPackage") ===
        check
    );
  };

  renderVisualizer = () => {
    return (
      <column className="tile">
        <box className="visualizerinfo">
          <column className="width">
            <text>{this.getTitle()}</text>
          </column>
          <column>{this.renderPartImage()}</column>
        </box>
        <box className="visualizerprice">{this.renderPrice()}</box>
      </column>
    );
  };

  renderPackageVisualizer = () => {
    var tire, wheel;
    this.props.part
      .get("partDetails")
      .get("primaryPart")
      .get("packageItem")
      .map(function (part) {
        if (part.get("itemType") == "TRE") {
          tire = part;
        } else if (part.get("itemType") == "WHL") {
          wheel = part;
        }
      });

    return (
      <box className="product pvisualizer">
        <column className="image fixwidth">
          <box>
            <img
              className="main"
              src={this.props.part
                .get("partDetails")
                .get("primaryPart")
                .get("packageImageLink")}
            />
            {this.renderRunflat(this.props.part)}
          </box>
        </column>
        {!isEmpty(wheel) ? (
          <column className="info spacing fixwidth">
            <container className="side small">
              <box>
                <h2 className="bold">
                  {this.props.part
                    .get("partDetails")
                    .get("primaryPart")
                    .get("packageName")
                    .get(getAppLanguage())}
                </h2>
              </box>
              <box>
                <text>{this.t("stocks.wheelSingular")}</text>
              </box>
              <box>
                <text>{wheel.get("itemTitle").get(getAppLanguage())}</text>
              </box>
              <box>
                <text>
                  {this.t("product.partNumber")}
                  {":"} {wheel.get("itemPartNumber")}
                </text>
              </box>
            </container>
          </column>
        ) : (
          false
        )}
        {!isEmpty(tire) ? (
          <column className="info fixwidth">
            <container className="side small">
              <box className="fixheight"></box>
              <box>
                <text>{this.t("stocks.tireSingular")}</text>
              </box>
              <box>
                <text>{tire.get("itemTitle").get(getAppLanguage())}</text>
              </box>
              <box>
                <text>
                  {this.t("product.partNumber")}
                  {":"} {tire.get("itemPartNumber")}
                </text>
              </box>
            </container>
          </column>
        ) : (
          false
        )}
        <column className="right fixmobile">{this.renderPrice()}</column>
      </box>
    );
  };

  renderGuide = () => {
    return (
      <box className="product reduced accessory guide">
        <box className="shadow">
          <box>
            {this.props.index % 2 === 0
              ? this.renderGuideImageColumn()
              : this.renderGuideInfoColumn()}
            {this.props.index % 2 === 0
              ? this.renderGuideInfoColumn()
              : this.renderGuideImageColumn()}
          </box>
        </box>
        {this.renderDealerStockBar()}
        {this.renderNtdStockBar()}
        {this.renderStockBar()}
        {this.renderStock()}
        {this.renderStaggeredStock()}
      </box>
    );
  };

  renderGuideImageColumn = () => {
    return (
      <column className="imagecolumn">
        <box>
          <box className="crop">
            <img
              className="main"
              src={this.renderGuideImagePlaceHolder(
                this.props.part
                  .get("partDetails")
                  .get("primaryPart")
                  .get("accessoryImageLink")
              )}
              onError={this.handleImageFallback}
            />
          </box>
        </box>
      </column>
    );
  };
  handleImageFallback = (e) => {
    const { currentTarget } = e;
    currentTarget.onerror = null;
    currentTarget.src = this.renderGuideImagePlaceHolder(null, true);
  };
  renderGuideImagePlaceHolder = (imageLink, errorMethod = false) => {
    let invalidValues = [null, undefined, ""];
    switch (applicationStore.data.defaultTemplate) {
      case "audi":
      case "audi_2021":
        if (!errorMethod) {
          return !invalidValues.includes(imageLink)
            ? imageLink
            : `${window.location.origin}/static/img/${applicationStore.data.defaultTemplate}/placeholder.png`;
        } else {
          return `${window.location.origin}/static/img/${applicationStore.data.defaultTemplate}/placeholder.png`;
        }
      case "honda":
        if (!errorMethod) {
          return !invalidValues.includes(imageLink)
            ? imageLink
            : `${window.location.origin}/static/img/${applicationStore.data.defaultTemplate}/placeholder.png`;
        } else {
          return `${window.location.origin}/static/img/${applicationStore.data.defaultTemplate}/placeholder.png`;
        }
      case "hyundai":
        if (!errorMethod) {
          return !invalidValues.includes(imageLink)
            ? imageLink
            : `${window.location.origin}/static/img/${applicationStore.data.defaultTemplate}/placeholder.png`;
        } else {
          return `${window.location.origin}/static/img/${applicationStore.data.defaultTemplate}/placeholder.png`;
        }
      case "mazda":
        if (!errorMethod) {
          return !invalidValues.includes(imageLink)
            ? imageLink
            : `${window.location.origin}/static/img/${applicationStore.data.defaultTemplate}/placeholder.png`;
        } else {
          return `${window.location.origin}/static/img/${applicationStore.data.defaultTemplate}/placeholder.png`;
        }
      case "kia_2021":
        if (!errorMethod) {
          return !invalidValues.includes(imageLink)
            ? imageLink
            : `${window.location.origin}/static/img/${applicationStore.data.defaultTemplate}/placeholder.png`;
        } else {
          return `${window.location.origin}/static/img/${applicationStore.data.defaultTemplate}/placeholder.png`;
        }
      case "volkswagen_2020":
        if (!errorMethod) {
          return !invalidValues.includes(imageLink)
            ? imageLink
            : `${window.location.origin}/static/img/${applicationStore.data.defaultTemplate}/placeholder.png`;
        } else {
          return `${window.location.origin}/static/img/${applicationStore.data.defaultTemplate}/placeholder.png`;
        }
      default:
        if (!errorMethod) {
          return !invalidValues.includes(imageLink)
            ? imageLink
            : `${window.location.origin}/static/img/default_placeholder.png`;
        } else {
          return `${window.location.origin}/static/img/default_placeholder.png`;
        }
    }
  };

  renderGuideInfoColumn = () => {
    return (
      <column className="infocolumn">
        <container className="small">
          <box>
            <container className="top small">
              <box>
                <h2>{this.getTitle()}</h2>
              </box>
              <box>
                <text>
                  {this.t("product.partNumber")}
                  {":"}{" "}
                  {this.props.part
                    .get("partDetails")
                    .get("primaryPart")
                    .get("accessoryPartNumber")}
                </text>
              </box>
            </container>
          </box>
          <box>{this.renderPrice()}</box>
          <box>{this.renderDescription(true)}</box>
          <box className="quotebutton">
            <container className="top large">
              {this.renderAddToCart(null, true)}
            </container>
          </box>
        </container>
      </column>
    );
  };

  renderTable = () => {
    if (
      this.enablePricing() &&
      this.props.part.get("partDetails").get("relatedParts").size > 0
    ) {
      var selected = this.decodePriceInfo(true, "selected"),
        quantity = this.state.quantity,
        total = parseFloat(
          selected.get("displayPrice") * quantity +
            selected.get("displayLabour") * quantity
        ),
        requiredcount = 0,
        requiredprice = 0,
        requiredinstall = 0,
        requiredtotal = 0;

      if (this.props.part.get("partDetails").get("relatedParts").size > 0) {
        var getRelatedPartPriceAndInstallation =
            this.getRelatedPartPriceAndInstallation,
          partnumberText = this.t("product.partNumber"),
          priceText = this.getPriceText();
        this.props.part
          .get("partDetails")
          .get("relatedParts")
          .map(function (relatedPart, index) {
            var price = getRelatedPartPriceAndInstallation(index);
            requiredcount++;
            requiredprice += parseFloat(price.price);
            requiredinstall += parseFloat(price.installation);
            requiredtotal +=
              parseFloat(price.price) + parseFloat(price.installation);
          });
      }

      return (
        <tr className={this.props.index % 2 == 0 ? "" : "odd"}>
          <td>
            <container className="side small">
              {this.renderPartImage()}
            </container>
          </td>
          <td>
            <container className="side small">
              <text className="bold">{this.getTitle()}</text>
            </container>
          </td>
          <td className="right-border">
            <container className="side small">
              <box>
                <box>
                  <column>
                    <text>
                      {this.getPriceText()}
                      {":"}
                    </text>
                  </column>
                  <right>
                    <text>{money_format(selected.get("displayPrice"))}</text>
                  </right>
                </box>
                <box>
                  <column>
                    <text>
                      {this.t("product.installation")}
                      {":"}
                    </text>
                  </column>
                  <right>
                    <text>{this.getInstallationValue()}</text>
                  </right>
                </box>
              </box>
            </container>
          </td>
          <td>
            <container className="side small">
              <box>
                <column>
                  <text>
                    {this.t("product.total")}
                    {":"}
                  </text>
                </column>
                <right>
                  <text className="bold">{money_format(total)}</text>
                </right>
              </box>
            </container>
          </td>
          <td className="right-border">
            <container className="side small">
              {this.renderAddToCart(total)}
            </container>
          </td>
          <td>
            <container className="side small">
              <box>
                <box>
                  <column>
                    <text>
                      {this.getPriceText()} {"("}
                      <text
                        className="highlight pointer"
                        onClick={this.onClickImage}
                      >
                        {requiredcount}{" "}
                        {requiredcount === 1
                          ? this.t("wishlist.item")
                          : this.t("wishlist.items")}
                      </text>
                      {")"}
                      {":"}
                    </text>
                  </column>
                  <right>
                    <text>{money_format(requiredprice)}</text>
                  </right>
                </box>
                <box>
                  <column>
                    <text>
                      {this.t("product.installation")}
                      {":"}
                    </text>
                  </column>
                  <right>
                    <text>{this.getInstallationValue(requiredinstall)}</text>
                  </right>
                </box>
              </box>
            </container>
          </td>
          <td className="right-border">
            <container className="side small">
              <box>
                <column>
                  <text>
                    {this.t("product.total")}
                    {":"}
                  </text>
                </column>
                <right>
                  <text>{money_format(requiredtotal)}</text>
                </right>
              </box>
            </container>
          </td>
          <td>
            <container className="side small">
              <box>
                <text className="total bold">
                  {money_format(total + requiredtotal)}
                </text>
              </box>
            </container>
          </td>
          <td>
            <container className="side small">
              <box className="line">
                <button className="btn" onClick={this.onAddAllToCart}>
                  {this.t("product.addAllToWishlist")}
                </button>
              </box>
            </container>
          </td>
        </tr>
      );
    } else if (this.enablePricing()) {
      var selected = this.decodePriceInfo(true, "selected"),
        quantity = this.state.quantity,
        total =
          selected.get("displayPrice") * quantity +
          selected.get("displayLabour") * quantity;
      return (
        <tr className={this.props.index % 2 == 0 ? "" : "odd"}>
          <td>
            <container className="side small">
              {this.renderPartImage()}
            </container>
          </td>
          <td>
            <container className="side small">
              <text className="bold">{this.getTitle()}</text>
            </container>
          </td>
          <td className="right-border">
            <container className="side small">
              <box>
                <box>
                  <column>
                    <text>
                      {this.getPriceText()}
                      {":"}
                    </text>
                  </column>
                  <right>
                    <text>{money_format(selected.get("displayPrice"))}</text>
                  </right>
                </box>
                <box>
                  <column>
                    <text>
                      {this.t("product.installation")}
                      {":"}
                    </text>
                  </column>
                  <right>
                    <text>{this.getInstallationValue()}</text>
                  </right>
                </box>
              </box>
            </container>
          </td>
          <td>
            <container className="side small">
              <box>
                <column>
                  <text>
                    {this.t("product.total")}
                    {":"}
                  </text>
                </column>
                <right>
                  <text className="bold">{money_format(total)}</text>
                </right>
              </box>
            </container>
          </td>
          <td className="right-border">
            <container className="side small">
              {this.renderAddToCart(total)}
            </container>
          </td>
          <td>
            <container className="side small"></container>
          </td>
          <td className="right-border">
            <container className="side small"></container>
          </td>
          <td>
            <container className="side small"></container>
          </td>
          <td>
            <container className="side small"></container>
          </td>
        </tr>
      );
    } else {
      return (
        <tr className={this.props.index % 2 == 0 ? "" : "odd"}>
          <td>
            <container className="side small">
              {this.renderPartImage()}
            </container>
          </td>
          <td>
            <container className="side small">
              <text className="bold">{this.getTitle()}</text>
            </container>
          </td>
          <td className="right-border">
            <container className="side small"></container>
          </td>
          <td>
            <container className="side small"></container>
          </td>
          <td className="right-border">
            <container className="side small"></container>
          </td>
          <td>
            <container className="side small"></container>
          </td>
          <td className="right-border">
            <container className="side small"></container>
          </td>
          <td>
            <container className="side small"></container>
          </td>
          <td>
            <container className="side small"></container>
          </td>
        </tr>
      );
    }
  };

  getInstallationValue = (installationprice = "") => {
    if (isEmpty(installationprice)) {
      var selected = this.decodePriceInfo(true, "selected");
      installationprice = selected.get("displayLabour");
    }
    if (parseFloat(installationprice) > 0) {
      return money_format(installationprice);
    } else if (
      applicationStore.data.enableKiaFeatures &&
      this.isAccessoryList() &&
      installationprice == 0 &&
      this.props.part
        .get("partDetails")
        .get("primaryPart")
        .get("accessoryRequiresInstallation") == 1
    ) {
      return this.t("common.yes");
    } else {
      return <text className="italic">{this.t("notApplicable")}</text>;
    }
  };

  enableReducedTile = () => {
    return this.props.featured || this.isAccessoryList();
  };

  renderReduced = (isRear = false) => {
    if (this.isTireList()) {
      return this.renderReducedTireTile(isRear);
    } else if (this.isAccessoryList()) {
      if (this.isAccessoryPackagePart()) {
        return this.renderReducedAccessoryPackageTile();
      } else {
        return this.renderReducedAccessoryTile();
      }
    }
    return null;
  };

  renderReducedTireTile = (isRear) => {
    var load = !isRear
        ? this.props.part
            .get("partDetails")
            .get("primaryPart")
            .get("tireLoadRating")
        : this.getSecondaryInfo("tireLoadRating"),
      speed = !isRear
        ? this.props.part
            .get("partDetails")
            .get("primaryPart")
            .get("tireSpeedIndex")
        : this.getSecondaryInfo("tireSpeedIndex"),
      ply = !isRear
        ? this.props.part
            .get("partDetails")
            .get("primaryPart")
            .get("tirePlyRating")
        : this.getSecondaryInfo("tirePlyRating"),
      tiresize = !isRear
        ? this.props.part
            .get("partDetails")
            .get("primaryPart")
            .get("formattedTiresize")
        : this.getSecondaryInfo("formattedTiresize"),
      vehicleclass = !isRear
        ? this.props.part
            .get("partDetails")
            .get("primaryPart")
            .get("tireVehicleClass")
        : this.getSecondaryInfo("tireVehicleClass");
    return (
      <box className={this.getProductClass()}>
        {this.enableFeatureName() ? (
          <box className="featured">
            <text>{this.getFeaturedName()}</text>
          </box>
        ) : (
          false
        )}
        <box className="shadow">
          {this.renderRebates()}
          {this.renderReducedTileImage()}
          <box className="titlebox title">
            <container className="side small">{this.renderTitle()}</container>
          </box>
          <box>
            <container className="side small">
              <text>
                {"#"}
                {this.getManufacturerPartnumber(isRear, this.props.part)}
                {" - "}
                {!isEmpty(vehicleclass) ? vehicleclass + " " : ""}
                {tiresize}
                {" - "}
                {load}
                {" / "}
                {speed}
                {" / "}
                {ply}
              </text>
            </container>
          </box>
          <box>{this.renderPrice()}</box>
        </box>
        {this.renderDealerStockBar()}
        {this.renderNtdStockBar()}
        {this.renderStockBar()}
        {this.renderStock()}
        {this.renderStaggeredStock()}
      </box>
    );
  };

  enableFeatureName = () => {
    switch (applicationStore.data.defaultTemplate) {
      default:
        return false;
    }
  };

  renderReducedAccessoryTile = () => {
    return (
      <box className="product reduced accessory">
        <box className="shadow">
          {this.renderRebates()}
          {this.renderReducedTileImage()}
          <box className="titlebox title">
            <container className="side small">
              <box className="titleheight">{this.renderTitle()}</box>
            </container>
          </box>
          <box>{this.renderPrice()}</box>
        </box>
        {this.renderDealerStockBar()}
        {this.renderNtdStockBar()}
        {this.renderStockBar()}
        {this.renderStock()}
        {this.renderStaggeredStock()}
      </box>
    );
  };

  renderReducedAccessoryPackageTile = () => {
    return (
      <box className="product reduced accessory isaccessorypackage">
        <box className="shadow">
          {this.renderRebates()}
          <box>
            <column style={{ width: "31.33333333%" }}>
              {this.renderReducedTileImage()}
            </column>
            <column className="titlebox" style={{ width: "34.33333333%" }}>
              <box className="title">
                <container className="side small">
                  <box className="titleheight">{this.renderTitle()}</box>
                  {this.renderAccessoryPackageRelatedInfo()}
                </container>
              </box>
            </column>
            <column style={{ width: "34.33333333%" }}>
              {this.renderPrice()}
            </column>
          </box>
        </box>
        {this.renderDealerStockBar()}
        {this.renderNtdStockBar()}
        {this.renderStockBar()}
        {this.renderStock()}
        {this.renderStaggeredStock()}
      </box>
    );
  };

  renderAccessoryPackageRelatedInfo = () => {
    return (
      <box>
        <column style={{ marginRight: "20px" }}>
          {this.props.part
            .get("partDetails")
            .get("relatedParts")
            .map(function (relatedPart) {
              return (
                <box>
                  <text>
                    {relatedPart.get("accessoryName").get(getAppLanguage())}
                  </text>
                </box>
              );
            })}
        </column>
        <column>
          {this.props.part
            .get("partDetails")
            .get("relatedParts")
            .map(function (relatedPart) {
              return (
                <box>
                  <text className="bold">
                    {relatedPart.get("accessoryPartNumber")}
                  </text>
                </box>
              );
            })}
        </column>
      </box>
    );
  };

  renderReducedTileImage = (detail = false) => {
    switch (applicationStore.data.productType) {
      case ProductTypes.ACCESSORIES:
        return (
          <box className="pointer" onClick={this.onClickImage}>
            <box className="crop">
              <img
                className={setAdobeLaunchClass(
                  "main",
                  ADOBELAUNCHER_CLASS.IMAGE
                )}
                data-dtm={setAdobeLaunchDTM(
                  "",
                  this.getButtonAdobeLauncherDTM(),
                  this.getDataDTMTitle()
                )}
                src={this.renderGuideImagePlaceHolder(
                  this.props.part
                    .get("partDetails")
                    .get("primaryPart")
                    .get("accessoryImageLink")
                )}
                onError={this.handleImageFallback}
              />
            </box>
          </box>
        );
      case ProductTypes.TIRES:
        return (
          <box
            className={this.getImageContainerClass(detail)}
            onClick={this.onClickImage}
          >
            <box className="crop">
              <img
                className={setAdobeLaunchClass(
                  "main",
                  ADOBELAUNCHER_CLASS.IMAGE
                )}
                data-dtm={setAdobeLaunchDTM(
                  "",
                  this.getButtonAdobeLauncherDTM(),
                  this.getDataDTMTitle()
                )}
                src={this.props.part
                  .get("partDetails")
                  .get("primaryPart")
                  .get("tireImageLink")}
              />
              {this.renderFeaturedImage()}
              {this.renderFinancingImage()}
              {this.renderCompare(detail)}
            </box>
            <img
              className="brand"
              src={this.props.part
                .get("partDetails")
                .get("primaryPart")
                .get("tireManufacturerImage")}
            />
          </box>
        );
      case ProductTypes.PACKAGES:
        return (
          <box
            className={this.getImageContainerClass(detail)}
            onClick={this.onClickImage}
          >
            <box>
              <img
                className={setAdobeLaunchClass(
                  "main",
                  ADOBELAUNCHER_CLASS.IMAGE
                )}
                data-dtm={setAdobeLaunchDTM(
                  "",
                  this.getButtonAdobeLauncherDTM(),
                  this.getDataDTMTitle()
                )}
                src={this.props.part
                  .get("partDetails")
                  .get("primaryPart")
                  .get("packageImageLink")}
              />
            </box>
          </box>
        );
      case ProductTypes.ALLOY_WHEELS:
        return (
          <box
            className={this.getImageContainerClass(detail)}
            onClick={this.onClickImage}
          >
            <box>
              <img
                className={setAdobeLaunchClass(
                  "main",
                  ADOBELAUNCHER_CLASS.IMAGE
                )}
                data-dtm={setAdobeLaunchDTM(
                  "",
                  this.getButtonAdobeLauncherDTM(),
                  this.getDataDTMTitle()
                )}
                src={this.props.part
                  .get("partDetails")
                  .get("primaryPart")
                  .get("wheelImageLink")}
              />
            </box>
          </box>
        );
    }
  };

  getDataDTMTitle = () => {
    switch (applicationStore.data.productType) {
      case ProductTypes.TIRES:
        return this.props.part.getIn([
          "partDetails",
          "primaryPart",
          "tireModelName",
        ]);
      case ProductTypes.ALLOY_WHEELS:
        return this.props.part.getIn([
          "partDetails",
          "primaryPart",
          "wheelName",
          "en",
        ]);
      case ProductTypes.ACCESSORIES:
        return this.props.part.getIn([
          "partDetails",
          "primaryPart",
          "accessoryName",
          "en",
        ]);
      case ProductTypes.PACKAGES:
        return this.props.part.getIn([
          "partDetails",
          "primaryPart",
          "packageName",
          "en",
        ]);
    }
  };

  getImageContainerClass = (detail) => {
    return Cx({
      fiximagewidth: !this.props.featured && !detail,
      extended: this.enableExtendedPartInfo(),
      pointer: true,
    });
  };

  getFeaturedName = () => {
    switch (this.props.index) {
      case 1:
        return this.t("bronze").toUpperCase();
      case 2:
        return this.t("silver").toUpperCase();
      case 3:
        return this.t("gold").toUpperCase();
    }
  };

  renderFeatured = () => {
    return (
      <box className="product featured">
        {this.renderRebates()}
        <box className={this.getProductBoxClass()}>
          <box>
            <box className="info title">{this.renderTitle()}</box>
            {this.renderImage(false, true)}
          </box>
          <box>{this.renderInfo()}</box>
          <box>{this.renderPrice()}</box>
        </box>
        {this.renderDealerStockBar()}
        {this.renderNtdStockBar()}
        {this.renderStockBar()}
        {this.renderStock()}
        {this.renderStaggeredStock()}
      </box>
    );
  };

  renderNormal = () => {
    return (
      <box className={this.getProductClass()}>
        {this.enableSeparatorBar() ? (
          <box>
            <hr />
          </box>
        ) : (
          false
        )}
        {this.renderRebates()}
        <box className={this.getProductBoxClass()}>
          <column className={this.getImageColumnClass()}>
            {this.renderImageContainer()}
          </column>
          <column className={this.getPartInfoColumnClass()}>
            <container className="no-top">{this.renderInfo()}</container>
          </column>
          <column className={this.getPricingColumnClass()}>
            <container className="no-top">{this.renderPrice()}</container>
          </column>
        </box>
        {this.renderDealerStockBar()}
        {this.renderNtdStockBar()}
        {this.renderStockBar()}
        {this.renderStock()}
        {this.renderStaggeredStock()}
      </box>
    );
  };

  renderImageContainer = () => {
    switch (applicationStore.data.productType) {
      case ProductTypes.TIRES:
        return <container className="small">{this.renderImage()}</container>;
      case ProductTypes.ALLOY_WHEELS:
        return <container>{this.renderImage()}</container>;
      case ProductTypes.PACKAGES:
        return this.renderImage();
      default:
        return false;
    }
  };

  getImageColumnClass = () => {
    if (this.enableExtendedPartInfo()) {
      return "fixmaxwidth";
    } else {
      return "";
    }
  };

  getPartInfoColumnClass = () => {
    if (this.enableExtendedPartInfo()) {
      return isIEBrowser() ? "title extended isie" : "title extended";
    } else {
      return isIEBrowser() ? "title isie" : "title";
    }
  };

  getPricingColumnClass = () => {
    if (this.enableExtendedPartInfo()) {
      return "fixpricewidth";
    } else {
      return "";
    }
  };

  enableExtendedPartInfo = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mercedes":
        return true;
      case "mercedes_2022":
        return true;

      default:
        return false;
    }
  };

  getProductClass = () => {
    return Cx({
      product: true,
      featured: this.props.featured,
      reduced: this.enableEnhancedFilter(),
      financing: this.state.enableFinancing,
      tire: applicationStore.data.productType == ProductTypes.TIRES,
      wheel: applicationStore.data.productType == ProductTypes.ALLOY_WHEELS,
      accessory: applicationStore.data.productType == ProductTypes.ACCESSORIES,
      package: applicationStore.data.productType == ProductTypes.PACKAGES,
    });
  };

  enableSeparatorBar = () => {
    if (this.enableReducedTile()) {
      return true;
    } else {
      return false;
    }
  };

  renderImage = (detail = false, featured = false) => {
    return this.renderReducedTileImage(detail);
  };

  renderPartImage = (detail = false) => {
    return (
      <box className="image">
        {this.isAccessoryList() ? this.renderAccessoryImage(detail) : false}
        {this.isTireList() ? this.renderTireImage(detail) : false}
        {this.isPackageList() ? this.renderPackageImage() : false}
        {this.isWheelList() ? this.renderWheelImage() : false}
      </box>
    );
  };

  enableCroppedImage = () => {
    return this.isTireList() || this.isAccessoryList();
  };

  renderAccessoryImage = () => {
    return (
      <box className="holder pointer" onClick={this.onClickImage}>
        <img
          className="main"
          src={this.renderGuideImagePlaceHolder(
            this.props.part
              .get("partDetails")
              .get("primaryPart")
              .get("accessoryImageLink")
          )}
          onError={this.handleImageFallback}
        />
      </box>
    );
  };

  renderTireImage = (detail = false) => {
    return (
      <box className="holder pointer" onClick={this.onClickImage}>
        <img
          className="main"
          src={this.props.part
            .get("partDetails")
            .get("primaryPart")
            .get("tireImageLink")}
        />
        <img
          className="brand"
          src={this.props.part
            .get("partDetails")
            .get("primaryPart")
            .get("tireManufacturerImage")}
        />
        {this.renderRunflat(this.props.part)}
        {this.renderFeaturedImage()}
        {this.renderFinancingImage()}
        {this.renderCompare(detail)}
      </box>
    );
  };

  renderPackageImage = () => {
    return (
      <box className="holder pointer" onClick={this.onClickImage}>
        <img
          className="main"
          src={this.props.part
            .get("partDetails")
            .get("primaryPart")
            .get("packageImageLink")}
        />
        {this.renderRunflat(this.props.part)}
        {this.props.selected ? <i className="fa fa-check-circle" /> : false}
      </box>
    );
  };

  renderWheelImage = () => {
    return (
      <box className="holder pointer" onClick={this.onClickImage}>
        <img
          className="main"
          src={this.props.part
            .get("partDetails")
            .get("primaryPart")
            .get("wheelImageLink")}
        />
        {this.props.selected ? <i className="fa fa-check-circle" /> : false}
      </box>
    );
  };

  renderFeaturedImage = () => {
    if (this.props.featured && this.enableFeaturedImage()) {
      return (
        <h2 className={"featured-text " + this.getFeaturedIndex()}>
          {this.getFeaturedText()}
        </h2>
      );
    } else {
      return false;
    }
  };

  renderFinancingImage = () => {
    if (!this.props.featured && this.state.enableFinancing && false) {
      return (
        <h2>
          <box></box>
          {this.renderFinancingText()}
        </h2>
      );
    } else {
      return false;
    }
  };

  renderFinancingButton = (boxClass = null) => {
    switch (applicationStore.data.enableFinancing) {
      case "1":
        return (
          <box
            className={
              !isEmpty(boxClass) ? boxClass + " button" : "line button"
            }
          >
            <button
              id={this.getButtonId(true)}
              className={setAdobeLaunchClass(
                "btn no-padding financing-btn",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                this.getButtonAdobeLauncherDTM(),
                this.getDataDTMTitle()
              )}
              onClick={this.onAddToCart.bind(null, true)}
            >
              <text>{this.t("financeAvailable")}</text>
            </button>
          </box>
        );
      case "2":
        return (
          <box
            className={
              !isEmpty(boxClass) ? boxClass + " button" : "line button"
            }
          >
            <button
              id={this.getButtonId(true)}
              className={setAdobeLaunchClass(
                "btn no-padding financing-btn",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                this.getButtonAdobeLauncherDTM(),
                this.getDataDTMTitle()
              )}
              onClick={this.onAddToCart.bind(null, true)}
            >
              <text>
                {this.t("financeAvailable")}
                {" - "}
                {"0%"}
              </text>
            </button>
          </box>
        );
      case "3":
        return (
          <box
            className={
              !isEmpty(boxClass)
                ? boxClass + " promotionfinancing"
                : "line button finance-button"
            }
          >
            <button
              id={this.getButtonId(true)}
              className={setAdobeLaunchClass(
                "btn no-padding financing-btn",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                this.getButtonAdobeLauncherDTM(),
                this.getDataDTMTitle()
              )}
              onClick={this.onAddToCart.bind(null, true)}
            >
              {this.renderFinancingText(true)}
            </button>
          </box>
        );
      default:
        return false;
    }
  };

  renderFinancingText = (isButton = false) => {
    var total,
      quantity = this.state.quantity,
      rearquantity = this.state.rearquantity,
      selected = this.decodePriceInfo(true, "selected"),
      rearselected = this.decodePriceInfo(false, "selected"),
      tax = parseFloat(applicationStore.data.dealerTaxPercent) / 100;
    if (this.state.staggered && !isEmpty(rearselected)) {
      total =
        selected.get("displayPrice") * quantity +
        rearselected.get("displayPrice") * rearquantity +
        (!!selected.get("displayLabour")
          ? selected.get("displayLabour") * quantity
          : 0) +
        (!!rearselected.get("displayLabour")
          ? rearselected.get("displayLabour") * rearquantity
          : 0);
    } else {
      total =
        selected.get("displayPrice") * quantity +
        (!!selected.get("displayLabour")
          ? selected.get("displayLabour") * quantity
          : 0);
    }

    if (!isEmpty(total)) {
      total = total + tax * total;
    }

    if (!isEmpty(total)) {
      total = total / 6;
    }

    return (
      <box className={!isButton ? "financing-text is-image" : "financing-text"}>
        <container className="side small">
          <column className="half-a">
            {!isButton && false ? (
              <box className="financing-icon">
                <img
                  src={
                    getAppLanguage() == "fr"
                      ? "/static/img/0percentfinancing_icon_fr.png"
                      : "/static/img/0percentfinancing_icon.png"
                  }
                />
              </box>
            ) : (
              false
            )}
            <box>
              <box>
                <h2>{this.t("payMonthly")}</h2>
              </box>
              <box className="subtext top">
                <text className="text-small">{this.t("zeroInterest")}</text>
              </box>
              <box className="subtext bottom">
                <text className="text-xsmall alt-color">
                  {this.t("taxesNotIncluded")}
                </text>
              </box>
            </box>
          </column>
          <column className="half-a right">
            <box>
              <box>
                <h2>{money_format(total)}</h2>
                <text className="text-small">{this.t("perMonth")}</text>
              </box>
            </box>
          </column>
        </container>
      </box>
    );
  };

  enableFeaturedImage = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "bmw":
      case "volkswagen":
      case "volkswagen_2020":
        return false;
      default:
        return true;
    }
  };

  getFeaturedIndex = () => {
    switch (this.props.index) {
      case 1:
        return "good";
      case 2:
        return "better";
      case 3:
        return "best";
    }
  };

  getFeaturedText = () => {
    switch (this.props.index) {
      case 1:
        return this.t("product.good");
      case 2:
        return this.t("product.better");
      case 3:
        return this.t("product.best");
    }
  };

  renderCompare = (detail = false) => {
    if (this.props.enableCompare && !detail) {
      return (
        <box
          className={
            sizeOf(this.getRebateList()) > 0 && this.props.featured
              ? "comparebox has-rebates"
              : "comparebox"
          }
        >
          <box className="line">
            <right>
              <i
                className={
                  this.props.compare ? "fa fa-check-square" : "fa fa-square"
                }
              />
            </right>
            <right>
              <text>{this.t("comparison.compare")}</text>
            </right>
          </box>
        </box>
      );
    } else {
      return false;
    }
  };

  onClickImage = () => {
    switch (applicationStore.data.productType) {
      case ProductTypes.TIRES:
        if (this.props.enableCompare) {
          this.onToggleCompare();
        } else {
          this.onClickDetail();
        }
        break;
      case ProductTypes.ACCESSORIES:
      case ProductTypes.PACKAGES:
      case ProductTypes.ALLOY_WHEELS:
        this.onClickDetail();
        break;
    }
  };

  enableVisualizer = () => {
    return (
      !productListStore.data.hideProductList &&
      this.state.routerState === RouterStates.PRODUCT_LIST
    );
  };

  onToggleCompare = () => {
    this.toggleCompare();
  };

  renderInfo = () => {
    if (this.props.featured || this.state.mobile) {
      return this.renderInfoReduced();
    } else {
      return this.renderInfoNormal();
    }
  };

  renderInfoReduced = (part = this.props.part) => {
    var infoClass = Cx({
      info: true,
      mileagespacing: !this.state.mobile && this.mileageSpacing(),
    });
    return (
      <box className={infoClass}>
        <box>
          <hr />
          <container className="side small">
            {this.state.staggered ? (
              <box>
                {this.t("product.frontTire")}
                {":"}
              </box>
            ) : (
              false
            )}
            <box>{this.renderPartnumber(part)}</box>
            {this.isTireList() ? <box>{this.renderTiresize(part)}</box> : false}
            {this.isTireList() ? <box>{this.renderRating(part)}</box> : false}
          </container>
          {this.state.staggered ? (
            <container className="side small">
              <box>
                {this.t("product.rearTire")}
                {":"}
              </box>
              <box>{this.renderPartnumber(part, true)}</box>
              <box>{this.renderTiresize(part, true)}</box>
              <box>{this.renderRating(part, true)}</box>
            </container>
          ) : (
            false
          )}
          <hr />
        </box>
        {this.isTireList() ? this.renderMileage(part, true) : false}
        {!this.isTireList() ? this.renderDescription() : false}
        {!this.isTireList() ? this.renderWarranty() : false}
      </box>
    );
  };

  mileageSpacing = () => {
    var hasmileage = false,
      list = productListStore.data.productFeaturedList,
      country = applicationStore.data.dealerInfo
        ? applicationStore.data.dealerInfo.country
        : "";
    for (var i in list) {
      if (list[i]) {
        if (
          list[i].part &&
          list[i].part.partDetails.primaryPart &&
          list[i].part.partDetails.primaryPart.tireTreadlifeWarranty &&
          list[i].part.partDetails.primaryPart.tireTreadlifeWarranty[country] &&
          !isEmpty(
            list[i].part.partDetails.primaryPart.tireTreadlifeWarranty[country]
          )
        ) {
          hasmileage = true;
        }
      }
    }
    return this.isTireList() && hasmileage;
  };

  renderInfoNormal = (part = this.props.part, detail = false) => {
    return (
      <box className="info">
        {this.renderTitle()}
        <box>
          <column>
            <container className="side right">
              {this.state.staggered ? (
                <text>
                  {this.t("product.frontTire")}
                  {": "}
                </text>
              ) : (
                false
              )}
              {this.renderPartnumber(part)}
            </container>
          </column>
          {this.isTireList() ? (
            <column>
              <container className="side right">
                {this.renderTiresize(part)}
              </container>
            </column>
          ) : (
            false
          )}
          {this.isTireList() ? (
            <column>{this.renderRating(part)}</column>
          ) : (
            false
          )}
          {this.isWheelList() ? (
            <column>{this.renderDiameter(part)}</column>
          ) : (
            false
          )}
        </box>
        {this.state.staggered ? (
          <box>
            <column>
              <container className="side right">
                <text>
                  {this.t("product.rearTire")}
                  {": "}
                </text>
                {this.renderPartnumber(part, true)}
              </container>
            </column>
            {this.isTireList() ? (
              <column>
                <container className="side right">
                  {this.renderTiresize(part, true)}
                </container>
              </column>
            ) : (
              false
            )}
            {this.isTireList() ? (
              <column>{this.renderRating(part, true)}</column>
            ) : (
              false
            )}
            {this.isWheelList() ? (
              <column>{this.renderDiameter(part, true)}</column>
            ) : (
              false
            )}
          </box>
        ) : (
          false
        )}
        {this.isTireList() ? this.renderMileage(part) : false}
        {!this.isPackageList() ? this.renderDescription() : false}
        {this.isTireList() ? this.renderWarranty(!detail) : false}
        {this.isTireList() ? this.renderDetailRecommended(detail) : false}
        {this.isTireList() ? this.renderDetailWarranty(detail) : false}
        {!this.isPackageList() ? this.renderDetailDescription(detail) : false}
        {this.isAccessoryList() ? this.renderRestrictions(!detail) : false}
        {this.isAccessoryList() ? this.renderDetailRestrictions(detail) : false}
        {this.isPackageList() ? this.renderPackageItems(detail) : false}
        {this.renderPricingNote(!detail)}
      </box>
    );
  };

  renderInfoDetailReduced = (part) => {
    var description = this.getDescription();
    var restrictions = this.props.part
      .get("partDetails")
      .get("primaryPart")
      .get("accessoryRestrictions")
      .get(getAppLanguage());
    var requiresInstallation =
      this.props.part
        .get("partDetails")
        .get("primaryPart")
        .get("accessoryRequiresInstallation") == 1;
    return (
      <box className="info">
        {this.renderTitle()}
        <box>
          <container className="side right">
            {this.renderPartnumber(part)}
          </container>
        </box>
        <box>
          <container className="side right">
            <text className="bold">{description}</text>
          </container>
        </box>
        {this.isAccessoryPackagePart() ? (
          <box>
            <container className="side right">
              <text className="bold">
                {this.renderAccessoryPackageRelatedInfo()}
              </text>
            </container>
          </box>
        ) : (
          false
        )}
        <box>
          <container className="side right">
            <text>
              {this.t("product.restrictions")}
              {": "}
              <text className="bold">{restrictions}</text>
            </text>
          </container>
        </box>
        <box>
          <container className="side right">
            <text>
              {this.t("product.requiresInstallation")}
              {": "}
              <text className="bold">
                {requiresInstallation
                  ? this.t("common.yes")
                  : this.t("common.no")}
              </text>
            </text>
          </container>
        </box>
        <box>
          <container className="side right">
            <text>
              {this.t("product.categories")}
              {": "}
              <text className="bold">{this.getAccessoryCategoryList()}</text>
            </text>
          </container>
        </box>
      </box>
    );
  };

  getAccessoryCategoryList = () => {
    const language = getAppLanguage();
    const list = this.props.part
      .getIn(["partDetails", "primaryPart", "accessoryCategories"])
      .map((category) => category.valueSeq().first().get(language))
      .toJS()
      .join(", ");
    return list;
  };

  renderTiresize = (part, isRear = false) => {
    var tiresize = !isRear
        ? part.get("partDetails").get("primaryPart").get("formattedTiresize")
        : this.getSecondaryInfo("formattedTiresize"),
      vehicleclass = !isRear
        ? this.props.part
            .get("partDetails")
            .get("primaryPart")
            .get("tireVehicleClass")
        : this.getSecondaryInfo("tireVehicleClass");
    return (
      <text>
        {this.t("product.tireSize")}
        {": "}
        <text className="bold">
          {!isEmpty(vehicleclass) ? vehicleclass + " " : ""}
          {tiresize}
        </text>
      </text>
    );
  };

  renderRating = (part, isRear = false) => {
    var load = !isRear
        ? part.get("partDetails").get("primaryPart").get("tireLoadRating")
        : this.getSecondaryInfo("tireLoadRating"),
      speed = !isRear
        ? part.get("partDetails").get("primaryPart").get("tireSpeedIndex")
        : this.getSecondaryInfo("tireSpeedIndex"),
      ply = !isRear
        ? part.get("partDetails").get("primaryPart").get("tirePlyRating")
        : this.getSecondaryInfo("tirePlyRating");
    return (
      <text>
        {this.t("product.loadSpeedPly")}
        {": "}
        <text className="bold">
          {load}
          {" / "}
          {speed}
          {" / "}
          {ply}
        </text>
      </text>
    );
  };

  renderDiameter = (part, isRear = false) => {
    var diameter = !isRear
      ? part.get("partDetails").get("primaryPart").get("wheelDiameter")
      : this.getSecondaryInfo("wheelDiameter");
    return (
      <text>
        {" "}
        {this.t("product.wheelDiameter")}
        {": "}
        <text className="bold">{diameter}</text>
      </text>
    );
  };

  renderTitle = () => {
    return (
      <box>
        <column>
          <h2 onClick={this.onClickDetail}>
            <text
              className={setAdobeLaunchClass(
                this.getTextClass(),
                ADOBELAUNCHER_CLASS.TEXT
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                ADOBELAUNCHER_DTM.FEATUREDITEMS.BASE
              )}
            >
              {this.getTitle()}
            </text>
            {!this.enableOriginalText() ? (
              <i className="fa fa-info-circle" />
            ) : (
              false
            )}
          </h2>
        </column>
        {this.renderDistance(this.props.part)}
        {this.enableOriginalText()
          ? this.renderOriginalRefactor(this.props.part)
          : false}
      </box>
    );
  };

  isExcludedPartNumber = () => {
    switch (
      this.props.part
        .get("partDetails")
        .get("primaryPart")
        .get("tireNationalPartNumber")
    ) {
      case "TPIR2281800W":
      case "TPIR2751900W":
        return true;
      default:
        return false;
    }
  };

  renderOriginalRefactor = (part) => {
    if (
      part
        .get("partDetails")
        .get("primaryPart")
        .get("tireOriginalEquipmentFlag") &&
      !this.isExcludedPartNumber()
    ) {
      var text = "";
      switch (applicationStore.data.defaultTemplate) {
        case "bmw":
        case "mini":
          text = this.t("product.approvedTire");
          break;
        case "mercedes":
          if (
            this.props.part
              .get("partDetails")
              .get("primaryPart")
              .get("tireRunFlat") == "1"
          ) {
            text = this.t("MOE");
          } else {
            text = this.t("MO");
          }
          break;
      }
      return (
        <column>
          <text className="original text-small">{text}</text>
        </column>
      );
    } else {
      return false;
    }
  };

  getOriginalText = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mercedes":
        if (
          this.props.part
            .get("partSummary")
            .get("primaryPart")
            .get("itemOriginalEquipmentFlag") == "1" &&
          !this.isExcludedPartNumber()
        ) {
          if (
            this.props.part
              .get("partDetails")
              .get("primaryPart")
              .get("tireRunFlat") == "1"
          ) {
            return " - MOE";
          } else {
            return " - MO";
          }
        } else {
          return "";
        }
      default:
        return "";
    }
  };

  enableOriginalText = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "bmw":
      case "mercedes":
        return applicationStore.data.productType === ProductTypes.TIRES;
      default:
        return false;
    }
  };

  getTextClass = () => {
    if (this.props.featured) {
      switch (applicationStore.data.defaultTemplate) {
        case "hyundai":
          switch (this.props.index) {
            case 1:
              return "bronze";
            case 2:
              return "silver";
            case 3:
              return "gold";
            default:
              return "";
          }
        default:
          return "";
      }
    } else {
      return "";
    }
  };

  getTitle = () => {
    switch (applicationStore.data.productType) {
      case ProductTypes.ACCESSORIES:
        return this.props.part.getIn([
          "partDetails",
          "primaryPart",
          "accessoryName",
          getAppLanguage(),
        ]);
      case ProductTypes.TIRES:
        return (
          this.props.part.getIn([
            "partDetails",
            "primaryPart",
            "tireModelName",
          ]) + (this.enableOriginalText() ? this.getOriginalText() : "")
        );
      case ProductTypes.PACKAGES:
        return this.props.part.getIn([
          "partDetails",
          "primaryPart",
          "packageName",
          getAppLanguage(),
        ]);
      case ProductTypes.ALLOY_WHEELS:
        return this.props.part.getIn([
          "partDetails",
          "primaryPart",
          "wheelName",
          getAppLanguage(),
        ]);
    }
  };

  getProductBoxClass = () => {
    return Cx({
      shadow: true,
      hasrebate: !isEmpty(this.getRebateList()),
    });
  };

  renderRebates = () => {
    var list = this.getRebateList(),
      onOpenRebate = this.onOpenRebate,
      contextId = this.state.contextId;
    if (!isEmpty(list)) {
      return (
        <box className="rebate">
          {Immutable.fromJS(list).map(function (rebate, index) {
            if (rebate.get("enableModal")) {
              return (
                <box
                  className="link"
                  onClick={onOpenRebate.bind(
                    null,
                    rebate.get("url"),
                    rebate.get("disclaimer"),
                    contextId
                  )}
                >
                  <container className="side small">
                    <text className="bold">{rebate.get("text")}</text>
                  </container>
                </box>
              );
            } else if (!isEmpty(rebate.get("url"))) {
              return (
                <a
                  key={"rebate-" + index}
                  target="_blank"
                  href={rebate.get("url")}
                  className={setAdobeLaunchClass(
                    "",
                    ADOBELAUNCHER_CLASS.BUTTON.LINK
                  )}
                  data-dtm={setAdobeLaunchDTM(
                    "",
                    ADOBELAUNCHER_DTM.FEATUREDITEMS.BASE
                  )}
                >
                  <container className="side small">
                    <text className="bold">{rebate.get("text")}</text>
                  </container>
                </a>
              );
            } else {
              return (
                <box>
                  <container className="side small">
                    <text className="bold">{rebate.get("text")}</text>
                  </container>
                </box>
              );
            }
          })}
        </box>
      );
    } else {
      return false;
    }
  };

  getRebateList = () => {
    var text,
      url,
      disclaimer,
      list = [],
      quantity = this.state.quantity;
    this.props.part
      .get("partPricing")
      .get("primaryPart")
      .get("link")
      .map(function (temp) {
        if (temp.get("quantity") == quantity) {
          temp.get("contextList").map(function (link) {
            text = link.get("linkText").get(getAppLanguage());
            url = link.get("linkURL").get(getAppLanguage());
            disclaimer = link.get("leadDisclaimer").get(getAppLanguage());
            if (!isEmpty(temp) && list.indexOf(temp) === -1) {
              list.push({
                text: text,
                url: url,
                disclaimer: disclaimer,
              });
            }
          });
        }
      });
    return list;
  };

  //
  // Product Stock
  //

  renderDealerStockBar = () => {
    if (isUserLoggedIn()) {
      if (!isEmpty(this.props.part.get("partStock").get("primaryPart"))) {
        return this.renderDealerStock();
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  renderDealerStock = () => {
    var part = this.props.part,
      getStockPartText = this.getStockPartText;
    return part
      .get("partStock")
      .get("primaryPart")
      .filter(function (stock) {
        return stock.get("stockLocationType") == "DLR";
      })
      .map(function (stock) {
        var count = parseInt(stock.get("stockQty"));
        if (count > 0) {
          var count_text = count < 20 ? count : "20+",
            part_text = getStockPartText(count !== 1),
            location = stock.get("stockLocationName");
          return (
            <box className="dealerstock">
              <container className="side small">
                <text className="bold">
                  {count_text + " " + part_text + " - " + location}
                </text>
              </container>
            </box>
          );
        } else {
          return false;
        }
      });
  };

  getStockPartText = (plurial = false) => {
    switch (applicationStore.data.productType) {
      case ProductTypes.TIRES:
        return plurial ? this.t("stocks.tires") : this.t("stocks.tireSingular");
      case ProductTypes.ALLOY_WHEELS:
        return plurial
          ? this.t("stocks.wheels")
          : this.t("stocks.wheelSingular");
      case ProductTypes.ACCESSORIES:
        return plurial
          ? this.t("stocks.accessories")
          : this.t("stocks.accessorySingular");
      case ProductTypes.PACKAGES:
        return plurial
          ? this.t("stocks.packages")
          : this.t("stocks.packagesSingular");
      default:
        return "";
    }
  };

  renderLabel = (type) => {
    if (this.state.staggered) {
      return (
        <label className="modal-label">
          {type === checkTypes.STOCK
            ? this.t("stocks.checkNationalStock")
            : this.t("stocks.checkNationalPricing")}
        </label>
      );
    } else {
      return false;
    }
  };

  renderButton = (type, placement) => {
    var className = "",
      onClick = function () {},
      btnText = "";
    switch (type) {
      case checkTypes.STOCK:
        className = "btn collapse-stock-btn check-pricing";
        btnText = this.state.staggered
          ? this.t("stocks.checkNationalStockFront")
          : this.t("stocks.checkNationalStock");
        onClick = this.checkPartStock.bind(
          this,
          this.props.part.get("partDetails").get("primaryPart").get("itemId")
        );
        if (placement === placements.REAR) {
          btnText = this.state.staggered
            ? this.t("stocks.checkNationalStockRear")
            : this.t("stocks.checkNationalStock");
          onClick = this.checkPartStock.bind(
            this,
            this.props.part.get("partDetails").get("secondaryParts").size > 0
              ? this.props.part
                  .get("partDetails")
                  .get("secondaryParts")
                  .get(0)
                  .get("itemId")
              : 0
          );
        }
        break;
      case checkTypes.PRICE:
        className = "btn collapse-stock-btn check-pricing";
        btnText = this.state.staggered
          ? this.t("stocks.checkNationalPricingFront")
          : this.t("stocks.checkNationalPricing");
        onClick = this.checkPartPricing.bind(
          this,
          this.props.part.get("partDetails").get("primaryPart").get("itemId")
        );
        if (placement === placements.REAR) {
          btnText = this.state.staggered
            ? this.t("stocks.checkNationalPricingRear")
            : this.t("stocks.checkNationalPricing");
          onClick = this.checkPartPricing.bind(
            this,
            this.props.part.get("partDetails").get("secondaryParts").size > 0
              ? this.props.part
                  .get("partDetails")
                  .get("secondaryParts")
                  .get(0)
                  .get("itemId")
              : 0
          );
        }
        break;
    }
    if (
      placement === placements.FRONT ||
      (placement === placements.REAR && this.state.staggered)
    ) {
      return (
        <button className={className} onClick={onClick}>
          {btnText}
        </button>
      );
    } else {
      return false;
    }
  };

  renderIcon = (type, placement) => {
    var data = this.getIconData(type, placement);
    if (
      (!isEmpty(data) && placement === placements.FRONT) ||
      (placement === placements.REAR && this.state.staggered)
    ) {
      return (
        <button className={data.className} onClick={data.onClick}>
          {data.icon}
        </button>
      );
    } else {
      return false;
    }
  };

  getIconData = (type, placement) => {
    switch (type) {
      case checkTypes.STOCK:
        return {
          className: "btn collapse-stock-btn check-pricing-icon",
          onClick: this.checkPartStock.bind(
            this,
            placement === placements.FRONT
              ? this.props.part
                  .get("partDetails")
                  .get("primaryPart")
                  .get("itemId")
              : this.props.part.get("partDetails").get("secondaryParts").size >
                0
              ? this.props.part
                  .get("partDetails")
                  .get("secondaryParts")
                  .get(0)
                  .get("itemId")
              : 0
          ),
          icon: <img src="/static/img/stock.png" alt="Icon National Stock" />,
        };
      case checkTypes.PRICE:
        return {
          className: "btn collapse-stock-btn check-pricing-icon",
          onClick: this.checkPartPricing.bind(
            this,
            placement === placements.FRONT
              ? this.props.part
                  .get("partDetails")
                  .get("primaryPart")
                  .get("itemId")
              : this.props.part.get("partDetails").get("secondaryParts").size >
                0
              ? this.props.part
                  .get("partDetails")
                  .get("secondaryParts")
                  .get(0)
                  .get("itemId")
              : 0
          ),
          icon: <img src="/static/img/price.png" alt="Icon National Price" />,
        };
      default:
        return {};
    }
  };

  checkPartStock = (partId) => {
    applicationActions.getNationalPartStockByPartId(
      applicationStore.data.productType,
      partId,
      0
    );
  };

  checkPartPricing = (partId) => {
    applicationActions.getNationalPartPricingByPartId(
      applicationStore.data.productType,
      partId,
      0
    );
  };

  renderNtdStockBar = () => {
    if (!(isUserLoggedIn() && applicationStore.data.dealerAllowNtdStock)) {
      return null;
    }
    var local_dc = 0,
      local_plus = 0,
      national = 0,
      part = this.props.part;
    let tireLinkEnabled = false;
    if (!isEmpty(part.get("partStock").get("primaryPart"))) {
      part
        .get("partStock")
        .get("primaryPart")
        .filter((stock) => stock.get("stockLocationType") == "RLT")
        .map((stock) => {
          if (
            parseInt(stock.get("stockQtyLocal")) > 0 ||
            parseInt(stock.get("stockQtyLocalPlus")) > 0 ||
            parseInt(stock.get("stockQtyNationWide")) > 0
          ) {
            local_dc = parseInt(stock.get("stockQtyLocal"));
            local_plus = parseInt(stock.get("stockQtyLocalPlus"));
            national = parseInt(stock.get("stockQtyNationWide"));
            tireLinkEnabled = stock.get("tireLinkEnabled");
          }
        });
      if (local_dc > 0 || local_plus > 0 || national > 0) {
        var image =
          getAppLanguage() === "en"
            ? "/static/img/ntd_logo_en.png"
            : "/static/img/ntd_logo_fr.png";
        if (applicationStore.data.dealerSite) {
          if (applicationStore.data.dealerInfo.country.toLowerCase() === "us") {
            image = "/static/img/atd_logo.png";
          }
        } else {
          if (
            applicationStore.data.nationalCountryCode.toLowerCase() === "us"
          ) {
            image = "/static/img/atd_logo.png";
          }
        }
        let style = {};

        return (
          <box className="ntdstock">
            <left>
              <img style={style} src={image} />
            </left>
            <box className="list">
              <container className="side small">
                <box>
                  <text>
                    {local_dc < 99 ? local_dc : "99+"}
                    {" - "}
                    {this.t("ntdStock.localDc")}
                  </text>
                </box>
                <box>
                  <text>
                    {local_plus < 99 ? local_plus : "99+"}
                    {" - "}
                    {this.t("ntdStock.localPlus")}
                  </text>
                </box>
                {!tireLinkEnabled ? (
                  <box>
                    <text>
                      {national < 99 ? national : "99+"}
                      {" - "}
                      {this.t("ntdStock.national")}
                    </text>
                  </box>
                ) : (
                  false
                )}
              </container>
            </box>
          </box>
        );
      } else {
        return false;
      }
    } else {
      return null;
    }
  };

  renderStockBar = () => {
    if (
      isUserLoggedIn() ||
      applicationStore.data.dealerEnableStockForCustomers
    ) {
      var count = 0,
        location_count = 0,
        part = this.props.part;
      if (!isEmpty(part.get("partStock").get("primaryPart"))) {
        console.log("primaryPart: ");
        part
          .get("partStock")
          .get("primaryPart")
          .filter((stock) => stock.get("stockLocationType") == "LOC")
          .map((stock) => {
            const stockQty = parseInt(stock.get("stockQty"));
            if (stockQty > 0) {
              location_count++;
              count += stockQty;
            }
          });

        if (count > 0) {
          var count_text = count < 20 ? count : "20+",
            part_text = this.getStockPartText(count !== 1),
            for_text = this.t("stocks.availableFor"),
            location_text =
              location_count == 1
                ? this.t("stocks.location")
                : this.t("stocks.locations");

          const tireLinkEnabled = part
            .get("partStock")
            .get("primaryPart")
            .some(
              (stock) =>
                Number(stock.get("stockQty")) && stock.get("tireLinkEnabled")
            );

          const rlt = part
            .get("partStock")
            .get("primaryPart")
            .find((stock) => stock.get("stockLocationType") == "RLT");

          const localDc = rlt?.get("stockQtyLocal") || 0;
          const localPlus = rlt?.get("stockQtyLocalPlus") || 0;

          return (
            <>
              {tireLinkEnabled && (
                <box className="tirelink-logo">
                  <img alt="TireLink logo" src="/static/img/tirelink.png" />
                  <span>
                    <div>
                      {localDc < 99 ? localDc : "99+"}
                      {" - "}
                      {this.t("ntdStock.localDc")}
                    </div>
                    <div>
                      {localPlus < 99 ? localPlus : "99+"}
                      {" - "}
                      {this.t("ntdStock.localPlus")}
                    </div>
                  </span>
                </box>
              )}
              <box className="stock">
                {applicationStore.data.allowStockCheckNationalStock ? (
                  <column>
                    <box className="check-national">
                      {this.renderLabel(checkTypes.STOCK)}
                      {this.renderButton(checkTypes.STOCK, placements.FRONT)}
                      {this.renderButton(checkTypes.STOCK, placements.REAR)}
                      {this.renderIcon(checkTypes.STOCK, placements.FRONT)}
                      {this.renderIcon(checkTypes.STOCK, placements.REAR)}
                    </box>
                  </column>
                ) : (
                  false
                )}
                {applicationStore.data.allowStockCheckNationalPrice ? (
                  <column>
                    <box className="check-national">
                      {this.renderLabel(checkTypes.PRICE)}
                      {this.renderButton(checkTypes.PRICE, placements.FRONT)}
                      {this.renderButton(checkTypes.PRICE, placements.REAR)}
                      {this.renderIcon(checkTypes.PRICE, placements.FRONT)}
                      {this.renderIcon(checkTypes.PRICE, placements.REAR)}
                    </box>
                  </column>
                ) : (
                  false
                )}
                <column>
                  <container className="side small">
                    <text>
                      {`${count_text} ${part_text} ${for_text} ${location_count} ${location_text}`}
                    </text>
                  </container>
                </column>
                {this.renderShowStock()}
              </box>
            </>
          );
        } else if (applicationStore.data.dealerAllowNtdStock) {
          return (
            <box className="stock">
              <column>
                <container className="side small">
                  <text>{this.t("stocks.noStockAvailable")}</text>
                </container>
              </column>
            </box>
          );
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  renderShowStock = () => {
    if (isUserLoggedIn()) {
      return (
        <column className="posright">
          <button onClick={this.toggleStock}>
            <i className={!this.state.stock ? "fa fa-plus" : "fa fa-minus"} />
          </button>
        </column>
      );
    } else {
      return false;
    }
  };

  renderStock = () => {
    if (this.state.stock) {
      var renderUpdatedAgo = this.renderUpdatedAgo,
        part = this.props.part,
        updatingStock = this.state.updatingStock,
        updatedStock = this.state.updatedStock,
        singular = this.t("stocks.tireSingular"),
        plurial = this.t("stocks.tires"),
        refresh = this.t("stocks.refresh"),
        loadingStocks = this.t("stocks.loadingStocks"),
        openExternalSite = this.openExternalSite,
        refreshStock = this.refreshStock,
        featured = this.props.featured;
      return (
        <box className="stocklist">
          {part
            .get("partStock")
            .get("primaryPart")
            .filter((stock) => stock.get("stockLocationType") == "LOC")
            .map((stock) => {
              var quantity,
                updatedAgo,
                isUpdating = false,
                text = stock.get("stockQty") == 1 ? singular : plurial,
                website = stock.get("stockLocationWebsite"),
                refreshLink = stock.get("stockApiQueryUrl");

              quantity = stock.get("stockQty");
              updatedAgo = stock.get("minutesSinceLastStockUpdate");

              if (!isEmpty(updatedStock)) {
                for (var i in updatedStock) {
                  if (
                    updatedStock[i] &&
                    updatedStock[i].itemId == stock.get("itemId") &&
                    updatedStock[i].stockLocationKey ==
                      stock.get("stockLocationKey")
                  ) {
                    quantity = updatedStock[i].stockQty;
                    updatedAgo = updatedStock[i].minutesSinceLastStockUpdate;
                  }
                }
              }

              if (!isEmpty(updatingStock)) {
                for (var i in updatingStock) {
                  if (
                    updatingStock[i] &&
                    updatingStock[i] ==
                      stock.get("itemId") + "-" + stock.get("stockLocationKey")
                  ) {
                    isUpdating = updatingStock[i];
                  }
                }
              }

              return (
                <box>
                  <container className="side small">
                    <column>
                      {stock.get("stockLocationName")}
                      {!isEmpty(website) ? (
                        <i
                          className="fa fa-external-link"
                          onClick={openExternalSite.bind(null, website)}
                        />
                      ) : (
                        false
                      )}
                      {" - " + quantity + " " + text}
                      {!isEmpty(refreshLink) ? (
                        <text
                          className="pointer"
                          onClick={refreshStock.bind(
                            null,
                            refreshLink,
                            stock.get("itemId"),
                            stock.get("stockLocationKey")
                          )}
                        >
                          {" (" + refresh + ") "}
                        </text>
                      ) : (
                        false
                      )}
                    </column>
                    <right>
                      {isUpdating ? (
                        <text className="updated">{loadingStocks + "..."}</text>
                      ) : (
                        renderUpdatedAgo(updatedAgo)
                      )}
                    </right>
                  </container>
                </box>
              );
            })}
        </box>
      );
    } else {
      return false;
    }
  };

  renderStaggeredStock = () => {
    if (!this.state.staggered) {
      return null;
    }
    return (
      <div>
        {this.renderStaggeredFrontStock()}
        {this.staggeredFrontData()}
        {this.renderStaggeredBackStock()}
        {this.staggeredBackData()}
      </div>
    );
  };

  renderStaggeredFrontStock = () => {
    if (
      isUserLoggedIn() ||
      applicationStore.data.dealerEnableStockForCustomers
    ) {
      let count = 0;
      let location_count = 0;
      let part = this.props.part;
      if (!isEmpty(part.get("partStock").get("primaryPart"))) {
        part
          .get("partStock")
          .get("primaryPart")
          .filter(function (stock) {
            return stock.get("stockLocationType") == "LOC";
          })
          .map(function (stock) {
            if (stock.get("stockQty") > 0) {
              location_count++;
              count += parseInt(stock.get("stockQty"));
            }
          });
        if (count > 0) {
          return (
            <box className="stock">
              {applicationStore.data.allowStockCheckNationalStock ? (
                <column>
                  <box className="check-national">
                    {this.renderLabel(checkTypes.STOCK)}
                    {this.renderButton(checkTypes.STOCK, placements.FRONT)}
                    {this.renderButton(checkTypes.STOCK, placements.REAR)}
                    {this.renderIcon(checkTypes.STOCK, placements.FRONT)}
                    {this.renderIcon(checkTypes.STOCK, placements.REAR)}
                  </box>
                </column>
              ) : (
                false
              )}
              {applicationStore.data.allowStockCheckNationalPrice ? (
                <column>
                  <box className="check-national">
                    {this.renderLabel(checkTypes.PRICE)}
                    {this.renderButton(checkTypes.PRICE, placements.FRONT)}
                    {this.renderButton(checkTypes.PRICE, placements.REAR)}
                    {this.renderIcon(checkTypes.PRICE, placements.FRONT)}
                    {this.renderIcon(checkTypes.PRICE, placements.REAR)}
                  </box>
                </column>
              ) : (
                false
              )}
              <column>
                <container className="side small">
                  <text>Front Tire {this.renderPartnumber(part)}</text>
                </container>
              </column>
              {isUserLoggedIn() && (
                <column className="posright">
                  <button
                    onClick={() => {
                      this.setState({ frontStock: !this.state.frontStock });
                    }}
                  >
                    <i
                      className={
                        !this.state.frontStock ? "fa fa-plus" : "fa fa-minus"
                      }
                    />
                  </button>
                </column>
              )}
            </box>
          );
        } else if (applicationStore.data.dealerAllowNtdStock) {
          return (
            <box className="stock">
              <column>
                <container className="side small">
                  <text>{this.t("stocks.noStockAvailable")}</text>
                </container>
              </column>
            </box>
          );
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  staggeredFrontData = () => {
    if (this.state.frontStock) {
      var renderUpdatedAgo = this.renderUpdatedAgo,
        part = this.props.part,
        updatingStock = this.state.updatingStock,
        updatedStock = this.state.updatedStock,
        singular = this.t("stocks.tireSingular"),
        plurial = this.t("stocks.tires"),
        refresh = this.t("stocks.refresh"),
        loadingStocks = this.t("stocks.loadingStocks"),
        openExternalSite = this.openExternalSite,
        refreshStock = this.refreshStock,
        featured = this.props.featured;
      return (
        <box className="stocklist">
          {part
            .get("partStock")
            .get("primaryPart")
            .filter(function (stock) {
              return stock.get("stockLocationType") == "LOC";
            })
            .map(function (stock) {
              var quantity,
                updatedAgo,
                isUpdating = false,
                text = stock.get("stockQty") == 1 ? singular : plurial,
                website = stock.get("stockLocationWebsite"),
                refreshLink = stock.get("stockApiQueryUrl");

              quantity = stock.get("stockQty");
              updatedAgo = stock.get("minutesSinceLastStockUpdate");

              if (!isEmpty(updatedStock)) {
                for (var i in updatedStock) {
                  if (
                    updatedStock[i] &&
                    updatedStock[i].itemId == stock.get("itemId") &&
                    updatedStock[i].stockLocationKey ==
                      stock.get("stockLocationKey")
                  ) {
                    quantity = updatedStock[i].stockQty;
                    updatedAgo = updatedStock[i].minutesSinceLastStockUpdate;
                  }
                }
              }

              if (!isEmpty(updatingStock)) {
                for (var i in updatingStock) {
                  if (
                    updatingStock[i] &&
                    updatingStock[i] ==
                      stock.get("itemId") + "-" + stock.get("stockLocationKey")
                  ) {
                    isUpdating = updatingStock[i];
                  }
                }
              }

              return (
                <box>
                  <container className="side small">
                    <column>
                      {quantity} at {stock.get("stockLocationName")} ||{" "}
                      {renderUpdatedAgo(updatedAgo)}
                      <br />
                      {quantity} at {stock.get("stockLocationName")} ||{" "}
                      {renderUpdatedAgo(updatedAgo)}
                      <br />
                      {quantity} at {stock.get("stockLocationName")} ||{" "}
                      {renderUpdatedAgo(updatedAgo)}
                      <br />
                      {stock.get("stockLocationName")}
                      {!isEmpty(website) ? (
                        <i
                          className="fa fa-external-link"
                          onClick={openExternalSite.bind(null, website)}
                        />
                      ) : (
                        false
                      )}
                      {" - " + quantity + " " + text}
                      {!isEmpty(refreshLink) ? (
                        <text
                          className="pointer"
                          onClick={refreshStock.bind(
                            null,
                            refreshLink,
                            stock.get("itemId"),
                            stock.get("stockLocationKey")
                          )}
                        >
                          {" (" + refresh + ") "}
                        </text>
                      ) : (
                        false
                      )}
                    </column>
                    <right>
                      {isUpdating ? (
                        <text className="updated">{loadingStocks + "..."}</text>
                      ) : (
                        renderUpdatedAgo(updatedAgo)
                      )}
                    </right>
                  </container>
                </box>
              );
            })}
        </box>
      );
    } else {
      return false;
    }
  };
  renderStaggeredBackStock = () => {
    if (
      isUserLoggedIn() ||
      applicationStore.data.dealerEnableStockForCustomers
    ) {
      var count = 0,
        location_count = 0,
        part = this.props.part,
        featured = this.props.featured;
      if (!isEmpty(part.get("partStock").get("primaryPart"))) {
        if (isUserLoggedIn()) {
          part
            .get("partStock")
            .get("primaryPart")
            .filter(function (stock) {
              return stock.get("stockLocationType") == "LOC";
            })
            .map(function (stock) {
              if (stock.get("stockQty") > 0) {
                location_count++;
                count += parseInt(stock.get("stockQty"));
              }
            });
        } else {
          part
            .get("partStock")
            .get("primaryPart")
            .map(function (stock) {
              if (stock.get("stockQty") > 0) {
                location_count++;
                count += parseInt(stock.get("stockQty"));
              }
            });
        }

        if (count > 0) {
          return (
            <box className="stock">
              {applicationStore.data.allowStockCheckNationalStock ? (
                <column>
                  <box className="check-national">
                    {this.renderLabel(checkTypes.STOCK)}
                    {this.renderButton(checkTypes.STOCK, placements.FRONT)}
                    {this.renderButton(checkTypes.STOCK, placements.REAR)}
                    {this.renderIcon(checkTypes.STOCK, placements.FRONT)}
                    {this.renderIcon(checkTypes.STOCK, placements.REAR)}
                  </box>
                </column>
              ) : (
                false
              )}
              {applicationStore.data.allowStockCheckNationalPrice ? (
                <column>
                  <box className="check-national">
                    {this.renderLabel(checkTypes.PRICE)}
                    {this.renderButton(checkTypes.PRICE, placements.FRONT)}
                    {this.renderButton(checkTypes.PRICE, placements.REAR)}
                    {this.renderIcon(checkTypes.PRICE, placements.FRONT)}
                    {this.renderIcon(checkTypes.PRICE, placements.REAR)}
                  </box>
                </column>
              ) : (
                false
              )}
              <column>
                <container className="side small">
                  <text>Rear:{this.renderPartnumber(part, true)}</text>
                </container>
              </column>
              {isUserLoggedIn() && (
                <column className="posright">
                  <button
                    onClick={() => {
                      this.setState({ backStock: !this.state.backStock });
                    }}
                  >
                    <i
                      className={
                        !this.state.backStock ? "fa fa-plus" : "fa fa-minus"
                      }
                    />
                  </button>
                </column>
              )}
            </box>
          );
        } else if (applicationStore.data.dealerAllowNtdStock) {
          return (
            <box className="stock">
              <column>
                <container className="side small">
                  <text>{this.t("stocks.noStockAvailable")}</text>
                </container>
              </column>
            </box>
          );
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  staggeredBackData = () => {
    if (this.state.backStock) {
      var renderUpdatedAgo = this.renderUpdatedAgo,
        part = this.props.part,
        updatingStock = this.state.updatingStock,
        updatedStock = this.state.updatedStock,
        singular = this.t("stocks.tireSingular"),
        plurial = this.t("stocks.tires"),
        refresh = this.t("stocks.refresh"),
        loadingStocks = this.t("stocks.loadingStocks"),
        openExternalSite = this.openExternalSite,
        refreshStock = this.refreshStock,
        featured = this.props.featured;
      return (
        <box className="stocklist">
          {part
            .get("partStock")
            .get("primaryPart")
            .filter(function (stock) {
              return stock.get("stockLocationType") == "LOC";
            })
            .map(function (stock) {
              var quantity,
                updatedAgo,
                isUpdating = false,
                text = stock.get("stockQty") == 1 ? singular : plurial,
                website = stock.get("stockLocationWebsite"),
                refreshLink = stock.get("stockApiQueryUrl");

              quantity = stock.get("stockQty");
              updatedAgo = stock.get("minutesSinceLastStockUpdate");

              if (!isEmpty(updatedStock)) {
                for (var i in updatedStock) {
                  if (
                    updatedStock[i] &&
                    updatedStock[i].itemId == stock.get("itemId") &&
                    updatedStock[i].stockLocationKey ==
                      stock.get("stockLocationKey")
                  ) {
                    quantity = updatedStock[i].stockQty;
                    updatedAgo = updatedStock[i].minutesSinceLastStockUpdate;
                  }
                }
              }

              if (!isEmpty(updatingStock)) {
                for (var i in updatingStock) {
                  if (
                    updatingStock[i] &&
                    updatingStock[i] ==
                      stock.get("itemId") + "-" + stock.get("stockLocationKey")
                  ) {
                    isUpdating = updatingStock[i];
                  }
                }
              }

              return (
                <box>
                  <container className="side small">
                    <column>
                      {stock.get("stockLocationName")}
                      {!isEmpty(website) ? (
                        <i
                          className="fa fa-external-link"
                          onClick={openExternalSite.bind(null, website)}
                        />
                      ) : (
                        false
                      )}
                      {" - " + quantity + " " + text}
                      {!isEmpty(refreshLink) ? (
                        <text
                          className="pointer"
                          onClick={refreshStock.bind(
                            null,
                            refreshLink,
                            stock.get("itemId"),
                            stock.get("stockLocationKey")
                          )}
                        >
                          {" (" + refresh + ") "}
                        </text>
                      ) : (
                        false
                      )}
                    </column>
                    <right>
                      {isUpdating ? (
                        <text className="updated">{loadingStocks + "..."}</text>
                      ) : (
                        renderUpdatedAgo(updatedAgo)
                      )}
                    </right>
                  </container>
                </box>
              );
            })}
        </box>
      );
    } else {
      return false;
    }
  };

  refreshStock = (url, tireId, stockLocationKey) => {
    var updateState = this.updateState,
      updatingStock = this.state.updatingStock,
      updatedStock = this.state.updatedStock;

    updatingStock.push(tireId + "-" + stockLocationKey);
    this.setState({
      updatingStock: updatingStock,
    });

    get(url, {
      dealerId: getAppDealerId(),
      tireId: tireId,
      stockLocationKey: stockLocationKey,
    }).then(function (response) {
      if (!isEmpty(response) && sizeOf(response) > 0) {
        updatedStock.push({
          itemId: response[0][0].itemId,
          stockLocationKey: response[0][0].stockLocationKey,
          stockQty: response[0][0].stockQty,
          minutesSinceLastStockUpdate:
            response[0][0].minutesSinceLastStockUpdate,
        });
        updateState({
          updatedStock: updatedStock,
        });
      }

      var index = updatingStock.indexOf(tireId + "-" + stockLocationKey);
      if (index > -1) {
        updatingStock.splice(index, 1);
      }
      updateState({
        updatingStock: updatingStock,
      });
    });
  };

  openExternalSite = (website) => {
    applicationActions.showExternalLinkWarningModal(website);
  };

  renderUpdatedAgo = (lastUpdated) => {
    var minutes = "",
      hours = "",
      days = "";
    if (lastUpdated < 60) {
      minutes = this.tp("timing.minute", lastUpdated);
      if (!days) {
        return (
          <text className="updated">
            {this.t("stocks.updatedShort").replace("{}", hours + minutes)}
          </text>
        );
      } else {
        return (
          <text className="updated">
            {this.t("stocks.updatedShort").replace("{}", days)}
          </text>
        );
      }
    } else if (lastUpdated < 60 * 24) {
      if (lastUpdated % 60 !== 0) {
        minutes = this.tp("timing.minute", lastUpdated % 60);
      }
      hours = this.tp("timing.hour", Math.floor(lastUpdated / 60));
      if (!days) {
        return (
          <text className="updated">
            {this.t("stocks.updatedShort").replace("{}", hours + minutes)}
          </text>
        );
      } else {
        return (
          <text className="updated">
            {this.t("stocks.updatedShort").replace("{}", days)}
          </text>
        );
      }
    } else {
      return (
        <text className="updated">
          {this.t("stocks.updatedMoreThanOneDay")}
        </text>
      );
    }
  };

  toggleStock = () => {
    this.setState({
      stock: !this.state.stock,
    });
  };

  //
  //  Tire Info
  //

  renderMileage = (part, featured) => {
    const mileage = this.getMileage(part);
    const mileageText = this.getMileageText();
    if (this.enableMilage() && mileage) {
      var containerClass = Cx({
        top: !featured,
        side: featured,
        small: true,
      });
      return (
        <box>
          <container className={containerClass}>
            <text>
              {this.t("product.mileageWarranty")}
              {": "}
              <text className="bold">
                {isNaN(parseFloat(mileage))
                  ? mileage
                  : `${mileage_format(mileage)} ${mileageText}`}
              </text>
            </text>
          </container>
          {featured ? <hr /> : false}
        </box>
      );
    } else {
      return false;
    }
  };

  enableMilage = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mercedes":
        return false;
      default:
        return true;
    }
  };

  getMileage = (part) => {
    const value = part.getIn([
      "partDetails",
      "primaryPart",
      "tireTreadlifeWarranty",
      applicationStore.data.dealerInfo?.country,
    ]);
    return value || null;
  };

  getMileageText = () => {
    var country = applicationStore.data.dealerInfo
      ? applicationStore.data.dealerInfo.country
      : "";
    switch (country) {
      case "CA":
        return getAppLanguage() === "fr" ? "km" : "Km";
      case "US":
        return getAppLanguage() === "fr" ? "mi." : "mi.";
      default:
        return "";
    }
  };

  renderDetailRecommended = (enable) => {
    if (enable) {
      var list = this.props.part
        .get("partDetails")
        .get("primaryPart")
        .get("tireRecommendedVehiclesList");
      if (sizeOf(list) > 0) {
        return (
          <box>
            <container className="top">
              <box>
                <h2 className="bold">{this.t("product.recommendedFor")}</h2>
              </box>
              <box>
                <hr />
              </box>
              <box className="paragraph large">
                {list.map(function (data) {
                  return (
                    <span>
                      {data
                        .get("recommendedVehicleDescription")
                        .get(getAppLanguage())}
                    </span>
                  );
                })}
              </box>
            </container>
          </box>
        );
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  renderRestrictions = (enable) => {
    if (enable) {
      var restrictions = this.props.part
        .get("partDetails")
        .get("primaryPart")
        .get("accessoryRestrictions")
        .get(getAppLanguage());
      if (!isEmpty(restrictions)) {
        return (
          <box className="paragraph">
            <container className="top">
              <text>
                <text className="bold">
                  {this.t("product.restrictions")}
                  {": "}
                </text>
                <span
                  className="text-small"
                  dangerouslySetInnerHTML={{ __html: restrictions }}
                />
              </text>
            </container>
          </box>
        );
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  renderDetailRestrictions = (enable) => {
    if (enable) {
      var restrictions = this.props.part
        .get("partDetails")
        .get("primaryPart")
        .get("accessoryRestrictions")
        .get(getAppLanguage());
      if (!isEmpty(restrictions)) {
        return (
          <box>
            <container className="top">
              <box>
                <h2 className="bold">{this.t("product.restrictions")}</h2>
              </box>
              <box>
                <hr />
              </box>
              <box className="paragraph large">
                <span dangerouslySetInnerHTML={{ __html: restrictions }} />
              </box>
            </container>
          </box>
        );
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  getDescription = () => {
    switch (applicationStore.data.productType) {
      case ProductTypes.ACCESSORIES:
        return this.props.part
          .get("partDetails")
          .get("primaryPart")
          .get("accessoryDescription")
          .get(getAppLanguage());
      case ProductTypes.TIRES:
        return this.props.part
          .get("partDetails")
          .get("primaryPart")
          .get("tireModelDescription")
          .get(getAppLanguage());
      case ProductTypes.ALLOY_WHEELS:
        return this.props.part
          .get("partDetails")
          .get("primaryPart")
          .get("wheelDescription")
          .get(getAppLanguage());
    }
  };

  renderDescription = (enable) => {
    if (enable && !isEmpty(this.getDescription())) {
      //<text className="bold">{this.t('product.description')}{': '}</text>

      return (
        <box className="paragraph">
          <container className="top">
            <span
              className="text-small"
              dangerouslySetInnerHTML={{ __html: this.getDescription() }}
            />
          </container>
        </box>
      );
    } else {
      return false;
    }
  };

  renderDetailDescription = (enable) => {
    if (enable && !isEmpty(this.getDescription())) {
      return (
        <box>
          <container className="top">
            <box>
              <h2 className="bold">{this.t("product.description")}</h2>
            </box>
            <box>
              <hr />
            </box>
            <box className="paragraph large">
              <span
                dangerouslySetInnerHTML={{ __html: this.getDescription() }}
              />
            </box>
          </container>
        </box>
      );
    } else {
      return false;
    }
  };

  getWarranty = () => {
    var warranty = "",
      country = applicationStore.data.dealerInfo
        ? applicationStore.data.dealerInfo.country
        : "",
      list = this.props.part
        .get("partDetails")
        .get("primaryPart")
        .get("tireWarrantyList");
    list.map(function (temp) {
      if (
        temp &&
        temp.get("warrantyCountryCode") == country.toLowerCase() &&
        temp.get("warrantyLanguageCode") == getAppLanguage()
      ) {
        warranty = temp.get("warrantyText");
      }
    });
    return warranty;
  };

  renderWarranty = (enable) => {
    if (this.enableWarranty(enable) && !isEmpty(this.getWarranty())) {
      return (
        <box className="paragraph">
          <container className="top">
            <text>
              <text className="bold">
                {this.t("product.warranty")}
                {": "}
              </text>
              <span
                className="text-small"
                dangerouslySetInnerHTML={{ __html: this.getWarranty() }}
              />
            </text>
          </container>
        </box>
      );
    } else {
      return false;
    }
  };

  renderDetailWarranty = (enable) => {
    if (enable && !isEmpty(this.getWarranty())) {
      return (
        <box>
          <container className="top">
            <box>
              <h2 className="bold">{this.t("product.warranty")}</h2>
            </box>
            <box>
              <hr />
            </box>
            <box className="paragraph large">
              <span>{this.getWarranty()}</span>
            </box>
          </container>
        </box>
      );
    } else {
      return false;
    }
  };

  enableDescription = (enable) => {
    return false;
  };

  enableWarranty = (enable) => {
    return false;
  };

  //
  //  Package Info
  //

  renderPackageItems = (detail) => {
    var translate = this.t;
    const packageItem = this.props.part
      .get("partDetails")
      .get("primaryPart")
      .get("packageItem");
    return (
      <box>
        {packageItem?.map((item) => {
          return (
            <box>
              <box>
                <text>
                  {item.get("itemQuantity")}{" "}
                  {item.get("itemTypeDescription").get(getAppLanguage())}
                  {" - "}
                  {item.get("itemTitle").get(getAppLanguage())}{" "}
                  {item.get("itemPartNumber")
                    ? translate("common.partnumberSearch") +
                      ": " +
                      item.get("itemPartNumber")
                    : false}
                </text>
              </box>
              {detail ? (
                <box className="itemdescription">
                  <text className="text-small">
                    {item.get("itemPartDescription").get(getAppLanguage())}
                  </text>
                </box>
              ) : (
                false
              )}
            </box>
          );
        })}
      </box>
    );
  };

  renderPricingNote = (enable) => {
    if (enable) {
      var note = this.getPricingNote();
      if (!isEmpty(note)) {
        return (
          <box>
            <text className="bold">
              {this.t("product.pricingNote")}
              {": "}
              {note}
            </text>
          </box>
        );
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  getPricingNote = () => {
    switch (applicationStore.data.productType) {
      case ProductTypes.ACCESSORIES:
        return applicationStore.data.dealerAccessoryPricingNote
          ? applicationStore.data.dealerAccessoryPricingNote[getAppLanguage()]
          : "";
      case ProductTypes.TIRES:
        return applicationStore.data.dealerTirePricingNote
          ? applicationStore.data.dealerTirePricingNote[getAppLanguage()]
          : "";
      case ProductTypes.PACKAGES:
        return applicationStore.data.dealerPackagePricingNote
          ? applicationStore.data.dealerPackagePricingNote[getAppLanguage()]
          : "";
      case ProductTypes.ALLOY_WHEELS:
        return applicationStore.data.dealerWheelPricingNote
          ? applicationStore.data.dealerWheelPricingNote[getAppLanguage()]
          : "";
    }
  };

  renderPrice = (detail = false) => {
    this.autoSelectPricingContext();
    var selected = this.decodePriceInfo(true, "selected");
    if (
      this.enablePricing() &&
      !isEmpty(selected) &&
      selected.get("displayPrice") > 0
    ) {
      if (this.props.view === VIEW.GUIDE) {
        return this.renderGuidePricing(selected);
      } else if (
        !isUserLoggedIn(true) &&
        applicationStore.data.dealerEnableCustomerLogin &&
        selected.get("marketMinimum") &&
        !this.props.visualizer
      ) {
        return this.renderLoginForPricing();
      } else if (this.decodePriceInfo(true, "promoted")) {
        return this.renderPromotedPricing(
          selected,
          this.decodePriceInfo(false, "selected"),
          detail
        );
      } else if (
        !detail &&
        this.isAccessoryList() &&
        this.enableAlternatePricing() &&
        this.isAccessoryPackagePart("0")
      ) {
        return this.renderAlternatePricing(selected);
      } else {
        return this.renderNormalPricing(
          selected,
          this.decodePriceInfo(false, "selected"),
          detail
        );
      }
    } else {
      return this.renderNoPricing();
    }
  };

  renderLoginForPricing = () => {
    return (
      <box className={this.getPricingClass()}>
        <box className="line button">
          <button
            className={setAdobeLaunchClass(
              "btn",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM(
              "",
              this.getButtonAdobeLauncherDTM(),
              this.getDataDTMTitle()
            )}
            onClick={this.openCreateCustomerAccount}
          >
            {this.t("product.loginForPricing")}
          </button>
        </box>
      </box>
    );
  };

  openCreateCustomerAccount = () => {
    this.props.dispatch(toggleLoginDialog(!this.props.loginVisible));
    this.props.dispatch(toggleLoginTextDialog(true));

    // NOTE: previous actions
    // this.props.dispatch(signUpRequestModal(true));
    // applicationActions.openLoginTextDialog(true);
  };

  decodePriceInfo = (front, index) => {
    var context,
      selected,
      promoted,
      contextId = this.state.contextId,
      quantity = this.state.quantity,
      list = this.getDecodedPriceList(front);
    if (!isEmpty(list)) {
      list.map(function (data) {
        if (data.get("quantity") == quantity) {
          context = data.get("contextList");
        }
        switch (index) {
          case "selected":
          case "promoted":
            data.get("contextList").map(function (temp) {
              if (
                data.get("quantity") == quantity &&
                temp.get("pricingContextId") == contextId
              ) {
                selected = temp;
              }
              if (temp.get("pricingContextId") != 0) {
                promoted = true;
              }
            });
        }
      });
    }
    switch (index) {
      case "context":
        return context;
      case "selected":
        return selected;
      case "promoted":
        return promoted;
    }
  };

  getDecodedPriceList = (front) => {
    if (front) {
      return this.props.part.get("partPricing").get("primaryPart").get("price");
    } else {
      if (this.props.part.get("partPricing").get("secondaryParts").size > 0) {
        return this.props.part
          .get("partPricing")
          .get("secondaryParts")
          .get(0)
          .get("price");
      } else {
        return null;
      }
    }
  };

  autoSelectPricingContext = () => {
    var selected = this.decodePriceInfo(true, "selected"),
      context = this.decodePriceInfo(true, "context"),
      rearselected = this.decodePriceInfo(false, "selected"),
      rearcontext = this.decodePriceInfo(false, "context");
    if (isEmpty(selected) && sizeOf(context) > 0) {
      console.log("autoSelectPricingContext", selected, context);
      context.map(function (price) {
        selected = price;
      });
      this.setState({ contextId: selected.get("pricingContextId") });
    }
    if (this.state.staggered) {
      if (isEmpty(rearselected) && sizeOf(rearcontext) > 0) {
        rearcontext.map(function (price) {
          rearselected = price;
        });
        this.setState({ rearcontextId: rearselected.get("pricingContextId") });
      }
    }
  };

  renderGuidePricing = (selected) => {
    var quantity = this.state.quantity,
      total =
        selected.get("displayPrice") * quantity +
        selected.get("displayLabour") * quantity;
    return (
      <box className="price">
        <box className="line">
          <text>
            {this.getPriceText().toUpperCase()}
            {": "}
            {money_format(selected.get("displayPrice"))}
          </text>
        </box>
        <box className="line">
          <text>
            {this.t("product.installationLong").toUpperCase()}
            {": "}
            {this.getInstallationValue()}
          </text>
        </box>
        <box className="line">
          <text>
            {this.t("product.total").toUpperCase()}
            {": "}
            {money_format(total)}
          </text>
        </box>
      </box>
    );
  };

  renderAlternatePricing = (selected) => {
    var part = this.props.part,
      quantity = this.state.quantity,
      total =
        selected.get("displayPrice") * quantity +
        selected.get("displayLabour") * quantity;
    return (
      <box className="price">
        <box className="line">
          <center>
            <text>
              {this.getPriceText()}
              {": "}
              {money_format(selected.get("displayPrice"))}
              {" - "}
              {this.t("wishlist.orderStepInstalled")}
              {": "}
              {this.getInstallationValue()}
            </text>
          </center>
        </box>
        <box className="line">
          <center>
            <text>
              {this.t("product.total")}
              {": "}
              {money_format(total)}
            </text>
          </center>
        </box>
        {this.renderAddToCart(total)}
      </box>
    );
  };

  renderAddToCart = (total, fullwidth = false) => {
    if (isEmpty(total)) {
      var total,
        selected = this.decodePriceInfo(true, "selected"),
        quantity = this.state.quantity;

      switch (applicationStore.data.productType) {
        case ProductTypes.TIRES:
          total = selected.get("displayPrice") * quantity;
          break;
        default:
          total =
            selected.get("displayPrice") * quantity +
            (selected.get("displayLabour")
              ? selected.get("displayLabour") * quantity
              : 0);
          break;
      }
    }

    return (
      <box>
        {!isConsumerSite() && this.enableNtdOrder() ? (
          <box className="line">
            {renderNTDButton(
              this.onAddToCart,
              "order",
              true,
              total,
              this.getButtonId()
            )}
          </box>
        ) : (
          false
        )}
        {!isConsumerSite() && this.enableNtdPurchase() ? (
          <box className="line">
            {renderNTDButton(
              this.onAddToCart,
              "purchase",
              true,
              total,
              this.getButtonId()
            )}
          </box>
        ) : (
          false
        )}
        {!isConsumerSite() && this.enableAddToQuote() ? (
          <box className="line">
            <button
              id={this.getButtonId()}
              className={fullwidth ? "btn full-width" : "btn"}
              onClick={this.onAddToCart}
            >
              {this.getAddToCartText()}
            </button>
          </box>
        ) : (
          false
        )}
        {isConsumerSite() ? (
          <box className="line promotiontotal">
            <button
              className={fullwidth ? "btn full-width" : "btn"}
              onClick={this.onClickConsumerPurchaseModal}
            >
              {this.getAddToCartText(true, total)}
            </button>
          </box>
        ) : (
          false
        )}
        {!this.enableAddToQuote() && !this.enableNtdPurchase() ? (
          <box className="line">
            <button
              className={fullwidth ? "btn full-width" : "btn"}
              onClick={
                isConsumerSite()
                  ? this.onClickConsumerPurchaseModal
                  : this.onClickDetailPage
              }
            >
              {this.t("product.requestAppointment")}
            </button>
          </box>
        ) : (
          false
        )}
      </box>
    );
  };

  getPricingText = () => {
    switch (applicationStore.data.productType) {
      case ProductTypes.ACCESSORIES:
        return this.t("product.perAccessoryLwr");
      case ProductTypes.ALLOY_WHEELS:
        return this.t("product.perWheelLwr");
      case ProductTypes.TIRES:
        return this.t("product.perTireLwr");
      case ProductTypes.PACKAGES:
        return this.t("product.perPackageLwr");
    }
  };

  renderPromotedPricing = (selected, rearselected, detail) => {
    var text,
      part = this.props.part,
      quantity = this.state.quantity,
      total = selected.get("displayPrice") * quantity;
    if (
      selected.get("showUnitPrice") &&
      selected.get("pricingContextId") != 0
    ) {
      text =
        money_format(selected.get("displayPrice")) +
        " " +
        this.getPricingText();
    } else {
      text = this.t("product.quantity");
    }
    var promotedText = "";
    if (selected.get("pricingContextId") > 0) {
      promotedText = selected.get("promotionPricingText").get(getAppLanguage());
    }
    return (
      <box className="price">
        {!this.isAccessoryList() ? (
          <box className="line promotiontext">
            <container className="side small">
              <text className="bold">{promotedText}</text>
            </container>
          </box>
        ) : (
          false
        )}
        {!this.isAccessoryList() ? (
          <box className="line promotionquantity">
            <container className="side small">
              <left>
                <text className="bold displayprice">{text}</text>
              </left>
              <right>{this.getQuantitySelect(part, false, true, false)}</right>
            </container>
          </box>
        ) : (
          false
        )}
        {this.isAccessoryList() ? (
          <box className="line promotionaccessorytext">
            <container className="side small">
              <text className="bold">{promotedText}</text>
            </container>
          </box>
        ) : (
          false
        )}
        {selected.get("displaySavings") > 0 &&
        selected.get("pricingContextId") > 0 ? (
          <box className="line promotiontotal">
            <container className="side small">
              <left>
                <text className="bold highlight">
                  {this.t("product.youSave")}
                </text>
              </left>
              <right>
                <text className="bold highlight">
                  {money_format(selected.get("displaySavings") * quantity)}
                </text>
              </right>
            </container>
          </box>
        ) : (
          false
        )}
        <box className="line promotiontotal">
          <container className="side small">
            <left>
              <text className="bold">{this.t("product.total")}</text>
            </left>
            <right>
              <text className="bold">{money_format(total)}</text>
            </right>
          </container>
        </box>
        {this.isAccessoryList() && selected.get("displayLabour") != 0 ? (
          <box className="line promotiontotal">
            <container className="side small">
              <left>
                <text className="bold">{this.t("product.installation")}</text>
              </left>
              <right>
                <text className="bold">
                  {this.getInstallationValue(
                    parseFloat(selected.get("displayLabour")) * quantity
                  )}
                </text>
              </right>
            </container>
          </box>
        ) : (
          false
        )}
        {!isConsumerSite() && !detail && this.state.enableFinancing
          ? this.renderFinancingButton("line promotiontotal")
          : false}
        {!isConsumerSite() ? (
          <box className="line promotiontotal">
            <button
              id={this.getButtonId()}
              className={setAdobeLaunchClass(
                "btn",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                this.getButtonAdobeLauncherDTM(),
                this.getDataDTMTitle()
              )}
              onClick={this.onAddToCart}
            >
              {this.getAddToCartText(true, total)}
            </button>
          </box>
        ) : (
          false
        )}
        {isConsumerSite() ? (
          <box className="line promotiontotal">
            <button
              className={setAdobeLaunchClass(
                "btn",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                this.getButtonAdobeLauncherDTM(),
                this.getDataDTMTitle()
              )}
              onClick={this.onClickConsumerPurchaseModal}
            >
              {this.getAddToCartText(true, total)}
            </button>
          </box>
        ) : (
          false
        )}
        {this.enableCreatePackage() ? (
          <box className="line promotiontotal">
            <button
              id={this.getButtonId(false, true)}
              className={setAdobeLaunchClass(
                "btn create-package",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                this.getButtonAdobeLauncherDTM(),
                this.getDataDTMTitle()
              )}
              onClick={this.onCreatePackage}
            >
              {this.t("product.createPackage")}
            </button>
          </box>
        ) : (
          false
        )}
        {this.showPricingNote(detail) ? (
          <box className="line note">{this.renderPricingNote(true)}</box>
        ) : (
          false
        )}
      </box>
    );
  };

  renderNormalPricing = (selected, rearselected, detail) => {
    var total,
      part = this.props.part,
      quantity = this.state.quantity,
      rearquantity = this.state.rearquantity,
      enableMinimumTag = !isUserLoggedIn() && selected.get("marketMinimum"),
      enableMarketMinimumTag =
        isUserLoggedIn() && parseFloat(selected.get("oldPrice")) > 0,
      enableMSRP =
        !this.state.staggered &&
        applicationStore.data.dealerShowMsrp &&
        !this.props.pvisualizer &&
        selected.get("displaySavings") > 0 &&
        !applicationStore.data.enableFinancing;
    if (this.state.staggered && !isEmpty(rearselected)) {
      total =
        selected.get("displayPrice") * quantity +
        rearselected.get("displayPrice") * rearquantity;
    } else {
      switch (applicationStore.data.productType) {
        case ProductTypes.TIRES:
          total = selected.get("displayPrice") * quantity;
          break;
        default:
          total =
            selected.get("displayPrice") * quantity +
            (selected.get("displayLabour")
              ? selected.get("displayLabour") * quantity
              : 0);
          break;
      }
    }

    /*
    {enableMinimumTag ? <box className="line minimum-tag">
      <img src="/static/img/downarrow_icon.png" /><text>{this.t('marketMinimum')}</text>
    </box> : false}
    */

    return (
      <box className={this.getPricingClass()}>
        {enableMarketMinimumTag ? (
          <box className="line minimum-tag">
            <img src="/static/img/alert_icon.png" className="alert" />
            <text>
              {this.t("belowMarket")} {money_format(selected.get("oldPrice"))}
            </text>
          </box>
        ) : (
          false
        )}
        {enableMarketMinimumTag && !this.props.featured ? <hr /> : false}
        {enableMSRP ? (
          <box className="line">
            <left>
              <text>
                {this.t("product.msrp")}
                {":"}
              </text>
            </left>
            <right>
              <text>{money_format(selected.get("listPrice"))}</text>
            </right>
          </box>
        ) : (
          false
        )}
        {this.enableSeparator() && enableMSRP ? <hr /> : false}
        {this.showPricing(detail) ? (
          <box className="line">
            <left>
              <text>
                {this.getPriceText()}
                {":"}
              </text>
            </left>
            <right>
              {this.state.staggered && !isEmpty(rearselected) ? (
                <text>
                  {this.t("frontAbbrv")}{" "}
                  {money_format(selected.get("displayPrice"))}{" "}
                  {this.t("rearAbbrv")}{" "}
                  {money_format(rearselected.get("displayPrice"))}
                </text>
              ) : (
                <text>{money_format(selected.get("displayPrice"))}</text>
              )}
            </right>
          </box>
        ) : (
          false
        )}
        {this.showPricing(detail) &&
        !this.isTireList() &&
        selected.get("displayLabour") > 0 ? (
          <box className="line">
            <left>
              <text>
                {this.t("wishlist.orderStepInstalled")}
                {":"}
              </text>
            </left>
            <right>
              <text>{money_format(selected.get("displayLabour"))}</text>
            </right>
          </box>
        ) : (
          false
        )}
        {this.showPricing(detail) && this.enableSeparator() ? <hr /> : false}
        <box className="line">
          <left>
            <text>
              {this.state.staggered && !isEmpty(rearselected)
                ? this.t("product.front") + " " + this.t("product.quantity")
                : this.t("product.quantity")}
              {":"}
            </text>
          </left>
          <right>{this.getQuantitySelect(part, false, false, detail)}</right>
        </box>
        {this.state.staggered && !isEmpty(rearselected) ? (
          <box className="line">
            <left>
              <text>
                {this.t("product.rear") + " " + this.t("product.quantity")}
                {":"}
              </text>
            </left>
            <right>{this.getQuantitySelect(part, true, false, detail)}</right>
          </box>
        ) : (
          ""
        )}
        {this.enableSeparator() ? <hr /> : false}
        <box className="line total">
          <left>
            <text className="bold">
              {this.t("product.total")}
              {":"}
            </text>
          </left>
          <right>
            <text className="bold">
              {money_format(total)}
              {enableMinimumTag ? "*" : false}
            </text>
          </right>
        </box>
        {this.enableSeparator() && enableMSRP ? <hr /> : false}
        {enableMSRP ? (
          <box className="line">
            <left>
              <text className="bold highlight">
                {this.t("product.totalSavings")}
                {":"}
              </text>
            </left>
            <right>
              <text className="bold highlight">
                {money_format(selected.get("displaySavings") * quantity)}
              </text>
            </right>
          </box>
        ) : (
          false
        )}
        {!this.isTireList() && selected.get("displayLabour") > 0 ? (
          <box className="line">
            <right>
              <text>{this.t("product.installationIncluded")}</text>
            </right>
          </box>
        ) : (
          false
        )}
        {this.showPricingNote(detail) ? (
          <box className="line note">{this.renderPricingNote(true)}</box>
        ) : (
          false
        )}
        {!isConsumerSite() &&
        !detail &&
        this.state.enableFinancing &&
        !this.props.visualizer
          ? this.renderFinancingButton()
          : false}
        {!isConsumerSite() && this.enableNtdOrder() ? (
          <box className="line button ntd-button">
            {renderNTDButton(
              this.onAddToCart,
              "order",
              true,
              total,
              this.getButtonId(),
              true,
              setAdobeLaunchDTM(
                "",
                this.getButtonAdobeLauncherDTM(),
                this.getDataDTMTitle()
              )
            )}
          </box>
        ) : (
          false
        )}
        {!isConsumerSite() && this.enableNtdPurchase() ? (
          <box className={this.getNtdButtonClass()}>
            {renderNTDButton(
              this.onAddToCart,
              "purchase",
              true,
              total,
              this.getButtonId(),
              this.state.enableFinancing,
              setAdobeLaunchDTM(
                "",
                this.getButtonAdobeLauncherDTM(),
                this.getDataDTMTitle()
              )
            )}
          </box>
        ) : (
          false
        )}
        {!isConsumerSite() && this.enableAddToQuote() ? (
          <box className="line button">
            <button
              id={this.getButtonId()}
              className={setAdobeLaunchClass(
                "btn",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                this.getButtonAdobeLauncherDTM(),
                this.getDataDTMTitle()
              )}
              onClick={this.onAddToCart}
            >
              {this.getAddToCartText()}
            </button>
          </box>
        ) : (
          false
        )}
        {isConsumerSite() ? (
          <box className="line promotiontotal">
            <button
              className={setAdobeLaunchClass(
                "btn",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                this.getButtonAdobeLauncherDTM(),
                this.getDataDTMTitle()
              )}
              onClick={this.onClickConsumerPurchaseModal}
            >
              {this.getAddToCartText(true, total)}
            </button>
          </box>
        ) : (
          false
        )}
        {this.enableCreatePackage() ? (
          <box className="line button">
            <button
              id={this.getButtonId(false, true)}
              className={setAdobeLaunchClass(
                "btn create-package",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                this.getButtonAdobeLauncherDTM(),
                this.getDataDTMTitle()
              )}
              onClick={this.onCreatePackage}
            >
              {this.t("product.createPackage")}
            </button>
          </box>
        ) : (
          false
        )}
        {!this.enableAddToQuote() && !this.enableNtdPurchase() ? (
          <box className="line button">
            <button
              className={setAdobeLaunchClass(
                "btn",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                this.getButtonAdobeLauncherDTM(),
                this.getDataDTMTitle()
              )}
              onClick={
                isConsumerSite()
                  ? this.onClickConsumerPurchaseModal
                  : this.onClickDetailPage
              }
            >
              {this.t("product.requestAppointment")}
            </button>
          </box>
        ) : (
          false
        )}
        {!isConsumerSite() && this.enableMoreDetails() && detail ? (
          <box className="line button">
            <button
              className={setAdobeLaunchClass(
                "btn",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                this.getButtonAdobeLauncherDTM(),
                this.getDataDTMTitle()
              )}
              onClick={
                isConsumerSite()
                  ? this.onClickConsumerPurchaseModal
                  : this.onClickDetailPage
              }
            >
              {this.t("product.moreDetails")}
            </button>
          </box>
        ) : (
          false
        )}
      </box>
    );
  };

  getButtonAdobeLauncherDTM = () => {
    if (this.props.visualizer) {
      return ADOBELAUNCHER_DTM.FEATUREDITEMS.VISUALIZERPRODUCT;
    } else {
      return ADOBELAUNCHER_DTM.FEATUREDITEMS.PRODUCT;
    }
  };

  getNtdButtonClass = () => {
    switch (applicationStore.data.enableFinancing) {
      case "1":
      case "2":
        return "line button";
      case "3":
      default:
        return "line button ntd-button";
    }
  };

  getButtonId = (isFinancing = false, isPackage = false) => {
    return getTestId(
      TEST_LIST.PRODUCT_LIST.PRODUCT_TILE.PRICING.BUTTON,
      this.props.featured
        ? "featured" +
            "-" +
            this.props.part
              .get("partDetails")
              .get("primaryPart")
              .get("itemId") +
            (isFinancing ? "-" + "financing" : "") +
            (isPackage ? "-" + "package" : "")
        : this.props.part.get("partDetails").get("primaryPart").get("itemId") +
            (isFinancing ? "-" + "financing" : "") +
            (isPackage ? "-" + "package" : "")
    );
  };

  getAddToCartText = (useQuoteText = false, total = "") => {
    if (isConsumerSite()) {
      return this.t("product.addToQuote");
    } else {
      switch (applicationStore.data.defaultTemplate) {
        case "generic":
          return isUserLoggedIn()
            ? this.t("cart.addToOrderNtd")
            : this.t("product.addToWishlist");
        default:
          if (this.state.enableFinancing && this.enableNtdPurchase()) {
            return this.t("payInFull");
          } else if (this.enableNtdPurchase()) {
            return getPurchasNtdButtonText(total);
          } else {
            return useQuoteText
              ? this.t("product.addToQuote")
              : this.t("product.addToWishlist");
          }
      }
    }
  };

  getPricingClass = () => {
    if (this.enableEnhancedFilter()) {
      return "price reduced";
    } else {
      return "price";
    }
  };

  enableMoreDetails = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mazda":
      case "mercedes":
        return false;
      default:
        return true;
    }
  };

  enableAlternatePricing = () => {
    if (this.enableReducedTile()) {
      return true;
    } else {
      return false;
    }
  };

  showPricing = (detail) => {
    if (this.enableReducedTile() && this.isAccessoryPackagePart("0")) {
      return (
        detail &&
        this.isAccessoryList() &&
        !this.props.pvisualizer &&
        !this.state.enableFinancing
      );
    } else {
      return !this.props.pvisualizer && !this.state.enableFinancing;
    }
  };

  showPricingNote = (enable) => {
    return enable && !isEmpty(this.getPricingNote());
  };

  enableSeparator = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mercedes":
      case "mercedestransition":
        return true;
      default:
        return false;
    }
  };

  getQuantitySelect = (
    part,
    isRear = false,
    isPromoted = false,
    isDetail = false
  ) => {
    var temp = [],
      quantity = !isRear ? this.state.quantity : this.state.rearquantity,
      onSelectQuantity = !isRear
        ? this.onSelectQuantity
        : this.onSelectQuantityRear,
      list = !isRear
        ? part.get("partPricing").get("primaryPart").get("price")
        : this.getSecondaryPrice("price"),
      enableEnhancedFilter = this.enableEnhancedFilter(),
      isAccessoryList = this.isAccessoryList(),
      hideUnitPrice = this.hideUnitPrice();
    list.map(function (price) {
      price.get("contextList").map(function (context) {
        temp.push({
          key: price.get("quantity"),
          contextId: context.get("pricingContextId"),
          displayPrice: context.get("displayPrice"),
          value:
            price.get("quantity") +
            (context.get("pricingContextId") > 0
              ? " - " + context.get("promotionText").get(getAppLanguage())
              : ""),
        });
      });
    });

    temp = temp.sort(function (a, b) {
      if (parseInt(a.key) < parseInt(b.key)) return -1;
      else if (parseInt(a.key) > parseInt(b.key)) return 1;
      else return 0;
    });

    return (
      <SelectBox
        selected={quantity}
        dataList={temp}
        datadtm={setAdobeLaunchDTM(
          "",
          this.getButtonAdobeLauncherDTM(),
          this.getDataDTMTitle()
        )}
        onSelect={onSelectQuantity}
        getObject={true}
        displayKey={true}
        rightAlign={true}
        showUnitPrice={
          !isAccessoryList &&
          enableEnhancedFilter &&
          !hideUnitPrice &&
          !isPromoted &&
          !isDetail
        }
      />
    );
  };

  hideUnitPrice = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mrlube":
        return true;
      default:
        return false;
    }
  };

  enableEnhancedFilter = () => {
    if (this.enableReducedTile()) {
      return true;
    } else {
      return false;
    }
  };

  onSelectQuantity = (price) => {
    this.setState({
      quantity: price.get("key"),
      contextId: price.get("contextId"),
    });
    setTimeout(() => {
      if (this.props.detail)
        this.props.clickImage(this.renderDetail(), this.getDataDTMTitle());
    }, 100);
  };

  onSelectQuantityRear = (price) => {
    this.setState({
      rearquantity: price.get("key"),
      rearcontextId: price.get("contextId"),
    });
    setTimeout(() => {
      if (this.props.detail)
        this.props.clickImage(this.renderDetail(), this.getDataDTMTitle());
    }, 100);
  };

  getPriceText = () => {
    if (
      isDealerSite() &&
      applicationStore.data.dealerSellingPriceLabel[ProductTypes.TIRES][
        getAppLanguage()
      ]
    ) {
      return applicationStore.data.dealerSellingPriceLabel[ProductTypes.TIRES][
        getAppLanguage()
      ];
    } else {
      return this.t("product.retailPrice");
    }
  };

  renderNoPricing = () => {
    return (
      <box className="price">
        <box className="line">
          <text>{this.t("product.contactDealerForPricing")}</text>
        </box>
        {this.enableNoPricingButton() ? (
          <box className="line">
            <button
              className="btn"
              onClick={
                isConsumerSite()
                  ? this.onClickConsumerPurchaseModal
                  : this.onClickDetailPage
              }
            >
              {this.t("product.requestAppointment")}
            </button>
          </box>
        ) : (
          false
        )}
      </box>
    );
  };

  enableNoPricingButton = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mercedes":
        return !this.isPackageList();
      default:
        return true;
    }
  };

  hasNtdStock = () => {
    var valid = false;
    this.props.part
      .get("partStock")
      .get("primaryPart")
      .map(function (stock) {
        if (
          stock &&
          stock.get("stockLocationType") == "RLT" &&
          stock.get("stockQty") > 0
        ) {
          valid = true;
        }
      });
    return valid;
  };

  enableNtdOrder = () => {
    return (
      applicationStore.data.dealerAllowNtdStockOrder &&
      this.hasNtdStock() &&
      this.props.part
        .get("partSummary")
        .get("primaryPart")
        .get("supportDealerOrder")
    );
  };

  enableNtdPurchase = () => {
    return (
      applicationStore.data.dealerAllowNtdStockOrder &&
      this.props.part
        .get("partSummary")
        .get("primaryPart")
        .get("supportConsumerOrder")
    );
  };

  enableAddToQuote = () => {
    return !this.enableNtdPurchase() && this.enablePricing();
  };

  enablePricing = () => {
    return (
      !applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) ||
      isUserLoggedIn(true) ||
      isConsumerSite()
    );
  };

  enableCreatePackage = () => {
    return (
      (this.enableAddToQuote() || this.enableNtdPurchase()) &&
      applicationStore.data.dealerEnableCreatePartPackage &&
      isEmpty(applicationStore.data.search) &&
      _.contains(
        applicationStore.data.productListProductTypes,
        ProductTypes.ALLOY_WHEELS
      ) &&
      this.isTireList() &&
      applicationStore.data.productType === ProductTypes.TIRES &&
      tiresizeHasWheels()
    );
  };

  getSecondaryInfo = (key) => {
    if (
      this.props.part &&
      this.props.part.get("partDetails").get("secondaryParts").size > 0
    ) {
      return this.props.part
        .get("partDetails")
        .get("secondaryParts")
        .get(0)
        .get(key);
    } else {
      return "";
    }
  };

  getSecondaryPrice = (key) => {
    if (
      this.props.part &&
      this.props.part.get("partPricing").get("secondaryParts").size > 0
    ) {
      return this.props.part
        .get("partPricing")
        .get("secondaryParts")
        .get(0)
        .get(key);
    } else {
      return "";
    }
  };

  renderPartnumber = (part, isRear = false) => {
    var natpartnumber = this.getNationalPartnumber(isRear, part);
    if (!isConsumerSite() && !isEmpty(natpartnumber)) {
      return (
        <text>
          {this.t("product.nationalPartNumber")}
          {": "}
          <text className="bold">{natpartnumber}</text>
        </text>
      );
    } else {
      return (
        <text>
          {this.t("product.partNumber")}
          {": "}
          <text className="bold">
            {this.getManufacturerPartnumber(isRear, part)}
          </text>
        </text>
      );
    }
  };

  getNationalPartnumber = (isRear, part) => {
    switch (applicationStore.data.productType) {
      case ProductTypes.TIRES:
        return !isRear
          ? part
              .get("partDetails")
              .get("primaryPart")
              .get("tireNationalPartNumber")
          : this.getSecondaryInfo("tireNationalPartNumber");
      case ProductTypes.ACCESSORIES:
      case ProductTypes.PACKAGES:
      case ProductTypes.ALLOY_WHEELS:
        return "";
    }
  };

  getManufacturerPartnumber = (isRear, part) => {
    switch (applicationStore.data.productType) {
      case ProductTypes.ACCESSORIES:
        return part
          .get("partDetails")
          .get("primaryPart")
          .get("accessoryPartNumber");
      case ProductTypes.TIRES:
        return !isRear
          ? part
              .get("partDetails")
              .get("primaryPart")
              .get("tireManufacturerPartNumber")
          : this.getSecondaryInfo("tireManufacturerPartNumber");
      case ProductTypes.PACKAGES:
        return part
          .get("partDetails")
          .get("primaryPart")
          .get("packagePartNumber");
      case ProductTypes.ALLOY_WHEELS:
        return part
          .get("partDetails")
          .get("primaryPart")
          .get("wheelPartNumber");
    }
  };

  renderDistance = (part) => {
    if (isConsumerSite()) {
      return (
        <column>
          <text className="original text-small">
            {part.get("dealerDetails").get("distance")}{" "}
            {this.t("product.kilometers")} {this.t("away")}
          </text>
        </column>
      );
    } else {
      return false;
    }
  };

  renderOriginal = (part) => {
    if (
      part
        .get("partDetails")
        .get("primaryPart")
        .get("tireOriginalEquipmentFlag")
    ) {
      return (
        <column>
          <text className="original text-small">
            {this.t("product.approvedTire")}
          </text>
        </column>
      );
    } else {
      return false;
    }
  };

  renderRunflat = (part) => {
    switch (applicationStore.data.defaultTemplate) {
      case "mercedes":
      case "mercedestransition":
        return false;
      default:
        if (this.isRunflat(part)) {
          return (
            <box className="runflat">
              <img src="/static/img/icon_run_flat_en.png" />
            </box>
          );
        } else {
          return false;
        }
    }
  };

  isRunflat = (part) => {
    switch (applicationStore.data.productType) {
      case ProductTypes.TIRES:
        return (
          part.get("partDetails").get("primaryPart").get("tireRunFlat") == 1
        );
      case ProductTypes.PACKAGES:
        return (
          part
            .get("partDetails")
            .get("primaryPart")
            .get("packageItemHasRunFlat") == true
        );
    }
  };

  renderDetail = () => {
    if (this.isAccessoryList()) {
      return this.renderAccessoryDetail();
    } else {
      return this.renderDetailNormal();
    }
  };

  renderAccessoryDetail = () => {
    var setAccessoryPackageImageLink = this.setAccessoryPackageImageLink;
    return (
      <box className="detail reduced">
        <box>
          <column className="side">
            <box>
              <container className="top">
                <img
                  className="main"
                  src={
                    this.isAccessoryPackagePart("1", true)
                      ? this.state.accessorypackageimagelink
                      : this.renderGuideImagePlaceHolder(
                          this.props.part
                            .get("partDetails")
                            .get("primaryPart")
                            .get("accessoryImageLink")
                        )
                  }
                  onError={this.handleImageFallback}
                />
              </container>
            </box>
            {this.isAccessoryPackagePart("1", true) ? (
              <box>
                <column
                  className="pointer"
                  style={{
                    height: "85px",
                    width: "20px",
                    padding: "0 5px",
                    textAlign: "center",
                    margin: "0",
                  }}
                  onClick={this.decrementAccessoryPackageImageIndex}
                >
                  <i
                    className="fa fa-angle-left"
                    style={{ marginTop: "35px" }}
                  />
                </column>
                {this.state.accessorypackageimageindex === 0 ? (
                  <column
                    onClick={this.setAccessoryPackageImageLink.bind(
                      null,
                      this.props.part
                        .get("partDetails")
                        .get("primaryPart")
                        .get("accessoryImageLink")
                    )}
                  >
                    <img
                      src={this.props.part
                        .get("partDetails")
                        .get("primaryPart")
                        .get("accessoryImageLink")}
                      style={{ height: "85px", margin: "0 10px 0 0" }}
                    />
                  </column>
                ) : (
                  false
                )}
                {this.props.part
                  .get("partDetails")
                  .get("relatedParts")
                  .skip(this.state.accessorypackageimageindex)
                  .take(this.state.accessorypackageimageindex === 0 ? 1 : 2)
                  .map(function (relatedPart) {
                    return (
                      <column
                        onClick={setAccessoryPackageImageLink.bind(
                          null,
                          relatedPart.get("accessoryImageLink")
                        )}
                      >
                        <img
                          src={relatedPart.get("accessoryImageLink")}
                          style={{ height: "85px", margin: "0 10px 0 0" }}
                        />
                      </column>
                    );
                  })}
                <column
                  className="pointer"
                  style={{
                    height: "85px",
                    width: "20px",
                    padding: "0 5px",
                    marginLeft: "-10px",
                    textAlign: "center",
                  }}
                  onClick={this.incrementAccessoryPackageImageIndex}
                >
                  <i
                    className="fa fa-angle-right"
                    style={{ marginTop: "35px" }}
                  />
                </column>
              </box>
            ) : (
              false
            )}
          </column>
          <column className="list">
            <box>{this.renderInfoDetailReduced(this.props.part)}</box>
            <box>{this.renderPrice(true)}</box>
          </column>
        </box>
        <box>
          {this.isAccessoryPackagePart("0", true)
            ? this.renderRequiredItemsReduced(this.props.part)
            : false}
        </box>
      </box>
    );
  };

  setAccessoryPackageImageLink = (link) => {
    this.setState({ accessorypackageimagelink: link });
    setTimeout(() => {
      if (this.props.detail)
        this.props.clickImage(this.renderDetail(), this.getDataDTMTitle());
    }, 100);
  };

  incrementAccessoryPackageImageIndex = () => {
    var index =
      this.state.accessorypackageimageindex <
      sizeOf(this.props.part.get("partDetails").get("relatedParts")) - 2
        ? this.state.accessorypackageimageindex + 1
        : sizeOf(this.props.part.get("partDetails").get("relatedParts")) - 2;
    this.setState({ accessorypackageimageindex: index });
    setTimeout(() => {
      if (this.props.detail)
        this.props.clickImage(this.renderDetail(), this.getDataDTMTitle());
    }, 100);
  };

  decrementAccessoryPackageImageIndex = () => {
    var index =
      this.state.accessorypackageimageindex > 0
        ? this.state.accessorypackageimageindex - 1
        : 0;
    this.setState({ accessorypackageimageindex: index });
    setTimeout(() => {
      if (this.props.detail)
        this.props.clickImage(this.renderDetail(), this.getDataDTMTitle());
    }, 100);
  };

  renderRequiredItemsReduced = (part) => {
    if (part.get("partDetails").get("relatedParts").size > 0) {
      var language = getAppLanguage(),
        getRelatedPartPrice = this.getRelatedPartPrice,
        partnumberText = this.t("product.partNumber"),
        priceText = this.getPriceText();
      return [
        <box>
          <hr />
        </box>,
        <box>
          <h2>
            {this.t("product.requiredItems")}
            {" - "}
            <text className="pointer" onClick={this.onAddAllToCart}>
              {this.t("product.addAllToWishlist")}
            </text>
          </h2>
        </box>,
        part
          .get("partDetails")
          .get("relatedParts")
          .map(function (relatedPart, index) {
            return (
              <box className="related">
                <column>
                  <img src={relatedPart.get("accessoryImageLink")} />
                </column>
                <column>
                  <container className="side">
                    <box>
                      <h2>
                        <text>
                          {relatedPart.get("accessoryName").get(language)}
                        </text>
                      </h2>
                    </box>
                    <box>
                      <text>
                        {partnumberText +
                          ": " +
                          relatedPart.get("accessoryPartNumber")}
                      </text>
                    </box>
                    <box>
                      <text>
                        {priceText +
                          ": " +
                          money_format(getRelatedPartPrice(index))}
                      </text>
                    </box>
                  </container>
                </column>
              </box>
            );
          }),
      ];
    } else {
      return false;
    }
  };

  getRelatedPartPrice = (index) => {
    if (
      this.props.part.get("partPricing").get("relatedParts").size > 0 &&
      this.isAccessoryPackagePart("0")
    ) {
      var price = "";
      this.props.part
        .get("partPricing")
        .get("relatedParts")
        .map(function (relatedPart, i) {
          if (i == index) {
            price =
              relatedPart
                .get("price")
                .get("1")
                .get("contextList")
                .get(0)
                .get("displayPrice") +
              relatedPart
                .get("price")
                .get("1")
                .get("contextList")
                .get(0)
                .get("displayLabour");
          }
        });
      return price;
    } else {
      return "";
    }
  };

  getRelatedPartPriceAndInstallation = (index) => {
    if (
      this.props.part.get("partPricing").get("relatedParts").size > 0 &&
      this.isAccessoryPackagePart("0")
    ) {
      var price = 0,
        installation = 0;
      this.props.part
        .get("partPricing")
        .get("relatedParts")
        .map(function (relatedPart, i) {
          if (i == index) {
            price = relatedPart
              .get("price")
              .get("1")
              .get("contextList")
              .get(0)
              .get("displayPrice");
            installation = relatedPart
              .get("price")
              .get("1")
              .get("contextList")
              .get(0)
              .get("displayLabour");
          }
        });
      return { price: price, installation: installation };
    } else {
      return { price: null, installation: null };
    }
  };

  renderDetailNormal = () => {
    return (
      <box className={this.getDetailClass()}>
        <column className="side">
          {this.renderImage(true)}
          {this.renderPrice(true)}
        </column>
        <column className="list">
          {this.renderInfoNormal(this.props.part, true)}
        </column>
      </box>
    );
  };

  getDetailClass = () => {
    var type;
    switch (applicationStore.data.productType) {
      case ProductTypes.TIRES:
        type = "tire";
        break;
      case ProductTypes.ALLOY_WHEELS:
        type = "wheel";
        break;
      case ProductTypes.ACCESSORIES:
        type = "accessory";
        break;
      case ProductTypes.PACKAGES:
        type = "package";
        break;
    }
    if (this.enableEnhancedFilter()) {
      return "detail reduced" + " " + type;
    } else {
      return "detail" + " " + type;
    }
  };

  renderConsumerPurchaseModal = () => {
    return <box>{this.renderFormSection()}</box>;
  };

  renderFormSection = () => {
    var formController = this.formController();
    return (
      <box>
        <box>
          <h2>{this.t("consumer.purchaseModalTitle")}</h2>
        </box>
        {this.state.consumerModalSendSuccess ? (
          <box className="success-section">
            <text>{this.t("consumer.purchaseModalSuccess")}</text>
          </box>
        ) : (
          false
        )}
        {this.state.consumerModalSendError ? (
          <box className="error-section">
            <text>{this.t("wishlist.modal.errorInvalidEmail")}</text>
          </box>
        ) : (
          false
        )}
        <box>
          <container className="top">
            <h2>
              {this.getTitle()}
              {" - "}
              {this.props.part.get("dealerDetails").get("distance")}{" "}
              {this.t("product.kilometers")}
            </h2>
          </container>
        </box>
        <div className="form-section">
          <ul>
            <li>
              {this.renderFormElement(
                this.t("wishlist.modal.firstName"),
                formController.classList.firstName,
                "name",
                "given-name",
                formController.idList.firstName,
                formController.mandatory.firstName
              )}
            </li>
            <li>
              {this.renderFormElement(
                this.t("wishlist.modal.lastName"),
                formController.classList.lastName,
                "name",
                "family-name",
                formController.idList.lastName,
                formController.mandatory.lastName
              )}
            </li>
            <li>
              {this.renderFormElement(
                this.t("wishlist.modal.email"),
                formController.classList.email,
                "email",
                "email",
                formController.idList.email,
                formController.mandatory.email
              )}
            </li>
            <li>
              {this.renderFormElement(
                this.t("wishlist.modal.phone"),
                formController.classList.phone,
                "phone",
                "tel",
                formController.idList.phone,
                formController.mandatory.phone
              )}
            </li>
            <li className="notes">
              {this.renderFormElement(
                this.t("wishlist.modal.notes"),
                formController.classList.notes,
                "",
                "",
                formController.idList.notes,
                formController.mandatory.notes
              )}
            </li>
          </ul>
        </div>
        <box>
          <container className="top">
            <button className="btn" onClick={this.onSendConsumerPurchaseLead}>
              {this.t("common.enter")}
            </button>
          </container>
        </box>
      </box>
    );
  };

  renderFormElement = (title, className, name, autocomplete, id, mandatory) => {
    return (
      <div className={"form-element form-" + className}>
        <span>
          {mandatory ? title + " " : title}
          {mandatory ? (
            <span className="mandatory-text">
              {this.t("common.mandatoryField")}
            </span>
          ) : (
            false
          )}
        </span>
        {className === "notes" ? (
          <textarea className={className} id={id} maxLength="1000" />
        ) : (
          <input
            className={className}
            id={id}
            name={name}
            autocomplete={autocomplete}
            maxLength="120"
          />
        )}
      </div>
    );
  };

  formController = () => {
    return {
      classList: {
        firstName: "firstName",
        lastName: "lastName",
        email: "email",
        phone: "phone",
        notes: "notes",
      },
      idList: {
        firstName: "formFirstName",
        lastName: "formLastName",
        email: "formEmail",
        phone: "formPhone",
        notes: "formNotes",
      },
      mandatory: {
        firstName: false,
        lastName: false,
        email: true,
        phone: false,
        notes: false,
      },
    };
  };

  getFirstName = () => {
    return document.getElementById("formFirstName").value;
  };

  getLastName = () => {
    return document.getElementById("formLastName").value;
  };

  getEmail = () => {
    return document.getElementById("formEmail").value;
  };

  getPhone = () => {
    return document.getElementById("formPhone").value;
  };

  getNotes = () => {
    return document.getElementById("formNotes").value;
  };

  onSendConsumerPurchaseLead = () => {
    if (!isEmpty(this.getEmail()) && validateEmail(this.getEmail())) {
      this.setState({
        consumerModalSendSuccess: true,
        consumerModalSendError: false,
      });
      setTimeout(() => {
        this.props.clickImage(this.renderConsumerPurchaseModal());
        productListActions.sendAppointmentForm(
          this.getFirstName(),
          this.getLastName(),
          this.getEmail(),
          this.getPhone(),
          "email",
          this.getNotes(),
          this.props.part.get("partDetails").get("primaryPart").get("itemId"),
          ProductTypes.TIRES,
          this.props.part.get("dealerDetails").get("dealerId"),
          this.getAdobeLauncherFormSubmitStatus()
        );
      }, 100);
    } else {
      this.setState({
        consumerModalSendSuccess: false,
        consumerModalSendError: true,
      });
      setTimeout(() => {
        this.props.clickImage(this.renderConsumerPurchaseModal());
      }, 100);
    }
  };

  getAdobeLauncherFormSubmitStatus = () => {
    if (
      !isEmpty(this.getFirstName()) &&
      !isEmpty(this.getLastName()) &&
      !isEmpty(this.getEmail()) &&
      !isEmpty(this.getPhone())
    ) {
      return ADOBELAUNCHER_FORM_SUBMIT.COMPLETE;
    } else if (
      !isEmpty(this.getFirstName()) ||
      !isEmpty(this.getLastName()) ||
      !isEmpty(this.getEmail()) ||
      !isEmpty(this.getPhone())
    ) {
      return ADOBELAUNCHER_FORM_SUBMIT.PARTIAL;
    } else {
      return ADOBELAUNCHER_FORM_SUBMIT.NONCOMPLETE;
    }
  };

  onClickConsumerPurchaseModal = () => {
    this.props.clickImage(this.renderConsumerPurchaseModal());
  };

  onAddToCart = (openFinancing = false) => {
    console.log("this is was added to the cart ");
    // this.props.dispatch(addToCart(
    //   {
    //     part:this.props.part,
    //     quantity:this.state.quantity,
    //     contextId:this.state.contextId,
    //     rearquantity:this.state.rearquantiy,
    //     rearcontextId:this.state.rearcontextId,
    //     index:this.props.featured ? this.props.index :null,
    //     requiredItemIdArray:[],
    //     openFinancing:openFinancing === true,
    //   }
    // ))
    if (
      getAppTemplate() === "mercedes" ||
      getAppTemplate() === "mercedestransition" ||
      getAppTemplate() === "mercedes_2022"
    ) {
      try {
        gtag("event", "conversion", {
          allow_custom_scripts: true,
          send_to: "DC-951733/tires0/tires00+standard",
        });
      } catch (err) {
        console.log(err.message);
      }
      document.body.insertAdjacentHTML(
        "beforebegin",
        "<noscript><img src='https://ad.doubleclick.net/ddm/activity/src=951733;type=tires0;cat=tires00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?' width='1' height='1' alt=''/></noscript>"
      );
    }

    shoppingCartActions.addToCart(
      this.props.part,
      this.state.quantity,
      this.state.contextId,
      this.state.rearquantity,
      this.state.rearcontextId,
      this.props.featured ? this.props.index : null,
      [],
      openFinancing === true
    );
  };

  onAddAllToCart = () => {
    var requireditems = [];
    console.log(this.props.part, "this is our parts");
    this.props.part
      .get("partDetails")
      .get("relatedParts")
      .map(function (part) {
        requireditems.push(part.get("itemId"));
      });
    // this.props.dispatch(addToCart({part: this.props.part,}))

    if (
      getAppTemplate() === "mercedes" ||
      getAppTemplate() === "mercedestransition" ||
      getAppTemplate() === "mercedes_2022"
    ) {
      try {
        gtag("event", "conversion", {
          allow_custom_scripts: true,
          send_to: "DC-951733/tires0/tires00+standard",
        });
      } catch (err) {
        console.log(err.message);
      }
      document.body.insertAdjacentHTML(
        "beforebegin",
        "<noscript><img src='https://ad.doubleclick.net/ddm/activity/src=951733;type=tires0;cat=tires00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?' width='1' height='1' alt=''/></noscript>"
      );
    }

    shoppingCartActions.addAllToCart(
      this.props.part,
      this.state.quantity,
      this.state.contextId,
      this.state.rearquantity,
      this.state.rearcontextId,
      this.props.featured ? this.props.index : null,
      requireditems
    );
  };

  toggleCompare = () => {
    if (this.props.compare) {
      this.props.dispatch(onAddProductToComparison(this.getComparisonObject()));
    } else {
      this.props.dispatch(onAddProductToComparison(this.getComparisonObject()));
      // productListActions.onAddProductToComparison(this.getComparisonObject());
    }
  };

  getComparisonObject = () => {
    let list = store.getState().productList.productList;
    var part = null;
    for (var i in list) {
      if (
        list[i] &&
        list[i].partDetails.primaryPart.itemId ===
          this.props.part.get("partDetails").get("primaryPart").get("itemId")
      ) {
        part = list[i];
        break;
      }
    }

    return {
      id: this.props.part.get("partDetails").get("primaryPart").get("itemId"),
      imageUrl: this.props.part
        .get("partDetails")
        .get("primaryPart")
        .get("tireImageLink"),
      quantity: this.state.quantity,
      pricingContextId: this.state.contextId,
      part: part,
    };
  };

  onCreatePackage = () => {
    this.onAddToCart();
    if (tiresizeHasSingleWheel()) {
      this.props.dispatch(resettireSizeFilters());
      setTimeout(() => {
        productListActions.loadWheels(true, this.state.quantity);
      }, 0);
    } else {
      this.props.dispatch(resettireSizeFilters());
      applicationActions.setProductTypeValue(ProductTypes.ALLOY_WHEELS);
    }
  };

  onOpenRebate = (url, disclaimer, pricingContextId) => {
    {
      /* this.props.dispatch(anOpenEmailPromotionalModal({itemType:ProductTypes.TIRES, itemId:this.props.part.get('partDetails').get('primaryPart').get('itemId'), pricingContextId:pricingContextId, url:url, disclaimer:disclaimer})) */
    }
    // productListActions.onOpenEmailPromotionModal(ProductTypes.TIRES, this.props.part.get('partDetails').get('primaryPart').get('itemId'), pricingContextId, url, disclaimer);
  };

  onClickDetailPage = () => {
    store.dispatch(
      openDetail({
        id: this.props.part.get("partDetails").get("primaryPart").get("itemId"),
        productType: applicationStore.data.productType,
        secondaryProductId: null,
      })
    );
    productDetailActions.openDetail(
      this.props.part.get("partDetails").get("primaryPart").get("itemId"),
      applicationStore.data.productType,
      null
    );
  };

  onClickDetail = () => {
    this.props.clickImage(this.renderDetail(), this.getDataDTMTitle());
  };
}
export default connect(null)(ProductTile);

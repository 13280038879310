import { ProductTypes } from "../../constants/ProductTypes.js";
import { ComboBox } from "../ux/forms/ComboBox.jsx";
import { ComboBoxValue } from "../ux/forms/ComboBoxValue.jsx";

import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";
import { languageStore } from "../../stores/LanguageStore.js";
import { applicationStore } from "../../stores/ApplicationStore.js";

import {
  getPricingFromQuantityAndDetailsRefactored,
  getPricingRebateByQuantity,
} from "../../service/ProductList";
import {
  getTabIndex,
  money_format,
  getAppLanguage,
} from "../../service/UtilityService.js";
import { isEmpty } from "../../service/UtilityService";
import { connect } from "react-redux";
import {
  changeDetailQuantity,
  changeQuantityOfAccesory,
  changeQuantityOfPackages,
  changeQuantityOfTires,
  changeQuantityOfWheels,
} from "../../redux/features/productList.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var Cx = require("classnames");

class PricingContext extends I18nComponent {
  shouldComponentUpdate(lastProps, nextState) {
    return lastProps.quantity.get("id") !== this.props.quantity.get("id");
  }

  render() {
    if (this.props.hasPricing) {
      if (this.isPromotional() && !this.props.openedFromShoppingView) {
        return this.renderPromotional();
      } else {
        return this.renderNonPromotional();
      }
    } else {
      return false;
    }
  }

  renderPromotional = () => {
    var className = "count has-promotion";
    if (this.props.staggered && this.props.front) {
      className = "count has-promotion front";
    }

    var classSuffix = this.props.classSuffix ? this.props.classSuffix : "";

    return (
      <div className="price-box promotion-box">
        <div className="promotion-name">{this.renderPromotionLink()}</div>
        <div className="price-item">
          {this.renderUnitPrice()}
          <ComboBox
            onChange={this.onChangeQuantity}
            value={this.props.quantity}
            className={className}
            hideTextInDisplay={true}
            isQuantityDropDown={true}
            classSuffix={classSuffix}
          >
            {this.props.quantities
              .map(function (n) {
                return (
                  <ComboBoxValue key={n} value={n}>
                    {n.get("title").get(languageStore.data.lang)}
                  </ComboBoxValue>
                );
              })
              .toArray()}
          </ComboBox>
        </div>
        {this.renderInstallation()}
        {this.renderYouSave()}
      </div>
    );
  };

  renderPromotionLink = () => {
    var price = getPricingFromQuantityAndDetailsRefactored(
      this.props.pricingDetails,
      this.props.quantity
    );
    var link = getPricingRebateByQuantity(
      this.props.pricingDetails,
      this.props.quantity
    );
    var promotionPricingText = price["promotionPricingText"];
    var promotionText = "";
    if (typeof promotionPricingText === "object")
      promotionText = promotionPricingText.get(getAppLanguage());

    if (promotionText) {
      if (link && link["url"] && link["url"].get(getAppLanguage()) !== "") {
        return (
          <a
            className="promotion-link"
            href={link["url"].get(getAppLanguage())}
            target="_blank"
          >
            <span>{promotionText}</span>
          </a>
        );
      } else {
        return <span>{promotionText}</span>;
      }
    } else {
      return false;
    }
  };

  renderNonPromotional = () => {
    var className = "count";
    if (this.props.staggered && this.props.front) {
      className = "count front";
    }
    if (!isEmpty(this.props.className)) {
      className += " " + this.props.className;
    }

    var msrp = null,
      installation = null,
      showMsrp =
        applicationStore.data.dealerShowMsrp &&
        parseFloat(this.props.pricing.get("listPrice")) >
          parseFloat(this.props.pricing.get("displayPrice"));
    msrp = money_format(this.props.pricing.get("listPrice"));
    installation = !this.props.openedFromDetailModal
      ? money_format(this.props.installation)
      : null;

    var nonPromotionalClass = Cx({
      "price-box": true,
      "regular-context": true,
      "has-installation":
        !this.props.openedFromDetailModal &&
        parseFloat(this.props.installation) > 0,
      "show-msrp": showMsrp,
    });

    var classSuffix = this.props.classSuffix ? this.props.classSuffix : "";

    return (
      <div className={nonPromotionalClass}>
        <div className="promotion-name">
          {showMsrp ? (
            <span className="label">{this.t("product.msrp") + ":"}</span>
          ) : (
            false
          )}
          {showMsrp ? <span className="value">{msrp}</span> : false}
        </div>
        <div className="price-item">
          {this.renderNonPromotedUnitPrice()}
          {!this.props.openedFromDetailModal &&
          parseFloat(this.props.installation) > 0 ? (
            <div className="installation">
              <span className="label">
                {this.t("product.installation") + ":"}
              </span>{" "}
              <span className="value">{installation}</span>
            </div>
          ) : (
            false
          )}
          <ComboBox
            tabIndex={getTabIndex()}
            onChange={this.onChangeQuantity}
            value={this.props.quantity}
            className={className}
            datadtm={!isEmpty(this.props.datadtm) ? this.props.datadtm : ""}
            hideTextInDisplay={true}
            isQuantityDropDown={true}
            classSuffix={classSuffix}
          >
            {this.props.quantities
              .map(function (n) {
                return (
                  <ComboBoxValue key={n} value={n}>
                    {n.get("title").get(languageStore.data.lang)}
                  </ComboBoxValue>
                );
              })
              .toArray()}
          </ComboBox>
        </div>
      </div>
    );
  };

  renderNonPromotedUnitPrice = () => {
    if (this.props.pricing.get("showUnitPrice")) {
      var price = money_format(this.props.pricing.get("displayPrice"));
      var priceLabel = this.t("product.retailPrice");
      if (
        applicationStore.data.dealerSite &&
        applicationStore.data.dealerSellingPriceLabel[this.props.productType][
          getAppLanguage()
        ]
      ) {
        priceLabel =
          applicationStore.data.dealerSellingPriceLabel[this.props.productType][
            getAppLanguage()
          ];
      }
      return (
        <div
          className="price-item-display"
          tabIndex={getTabIndex()}
          aria-label={priceLabel + ": " + price}
        >
          <span className="label">{priceLabel + ": "}</span>
          <span className="value">{price}</span>
        </div>
      );
    } else {
      return (
        <span
          tabIndex={getTabIndex()}
          className="label"
          aria-label={this.t("product.quantity")}
        >
          {this.t("product.quantity") + ":"}
        </span>
      );
    }
  };

  renderInstallation = () => {
    var installation = money_format(this.props.installation);
    if (parseFloat(this.props.installation) > 0) {
      return (
        <div className="price-installation">
          <span>{this.t("product.installation") + ":"}</span>{" "}
          <span>{installation}</span>
        </div>
      );
    } else {
      return false;
    }
  };

  renderYouSave = () => {
    var price = getPricingFromQuantityAndDetailsRefactored(
      this.props.pricingDetails,
      this.props.quantity
    );
    if (price["privateSavings"] > 0) {
      return (
        <div className="price-you-save">
          <span>{this.t("product.youSave") + ":"}</span>{" "}
          <span>
            {this.c(
              price["privateSavings"] *
                parseInt(this.props.quantity.get("value"), 10)
            )}
          </span>
        </div>
      );
    }
  };

  renderUnitPrice = () => {
    if (this.props.pricing.get("showUnitPrice")) {
      return (
        <span className="price-item-display">
          {this.c(this.props.pricing.get("displayPrice"))}{" "}
          {this.props.productPriceText}
        </span>
      );
    } else {
      return (
        <span className="price-item-quantity-label">
          {this.t("product.quantity")}:
        </span>
      );
    }
  };

  unitPriceDescription = () => {
    if (this.props.pricing.get("showUnitPrice")) {
      return (
        this.c(this.props.pricing.get("displayPrice")) +
        " " +
        this.props.productPriceText
      );
    } else {
      return this.t("product.quantity");
    }
  };

  renderRebateButton = () => {
    if (this.props.rebate) {
      var rebate = this.props.rebate;
      return (
        <a
          className="btn"
          target="_blank"
          title={this.td(rebate.get("body").toJS())}
          href={this.td(rebate.get("url").toJS())}
        >
          {this.td(rebate.get("text").toJS())}
        </a>
      );
    } else {
      return false;
    }
  };

  isPromotional = () => {
    return this.props.pricing.get("promotionText").toJS().en !== "";
  };

  onChangeQuantity = (quantity) => {
    if (this.props.openedFromShoppingView) {
      var price = getPricingFromQuantityAndDetailsRefactored(
        this.props.pricingDetails,
        quantity
      );
      shoppingCartActions.updateItemFromCartView(
        this.props.pricing.get("itemId"),
        this.props.productIndex,
        this.props.optionId,
        quantity,
        this.props.vehicleId,
        price["pricingContextId"],
        this.props.product
      );
    } else if (this.props.openedFromDetail) {
      this.props.dispatch(
        changeDetailQuantity({ quantity: quantity, front: this.props.front })
      );
      // productListActions.changeDetailQuantity(quantity, this.props.front);
    } else if (this.props.openedFromWishlist) {
      var itemId = this.props.productId;
      if (!itemId) itemId = this.props.pricing.get("itemId");
      shoppingCartActions.updateItemFromWishlist(
        itemId,
        this.props.product.get("cartItemId"),
        this.props.optionId,
        quantity,
        this.props.vehicleId,
        quantity.get("pricingContextId"),
        this.props.product,
        true
      );
    } else {
      switch (this.props.productType) {
        case ProductTypes.TIRES:
          this.props.dispatch(
            changeQuantityOfTires({
              tireKey: this.props.productId,
              rearTire: null,
              quantity: quantity,
              front: this.props.front,
              featured: this.props.featured,
              staggered: this.props.staggered,
            })
          );
          // productListActions.changeQuantityOfTires(this.props.productId, null, quantity, this.props.front, this.props.featured, this.props.staggered);
          break;
        case ProductTypes.ALLOY_WHEELS:
          this.props.dispatch(
            changeQuantityOfWheels({
              wheelIndex: this.props.productId,
              quantity: quantity,
              front: this.props.front,
            })
          );
          // productListActions.changeQuantityOfWheels(this.props.productId, quantity, this.props.front);
          break;
        case ProductTypes.ACCESSORIES:
          this.props.dispatch(
            changeQuantityOfAccesory({
              accessoryId: this.props.productId,
              quantity: quantity,
            })
          );
          // productListActions.changeQuantityOfAccessory(this.props.productId, quantity);
          break;
        case ProductTypes.PACKAGES:
          this.props.dispatch(
            changeQuantityOfPackages({
              packageIndex: this.props.productId,
              quantity: quantity,
            })
          );
          // productListActions.changeQuantityOfPackages(this.props.productId, quantity);
          break;
        default:
          throw new Error();
      }
    }
  };
}

export default connect(null)(PricingContext);

module.exports = {
  'startNewQuote':'Start New Wishlist',
  'title':'Mercedes-Benz',
  'ourBrands':'Mercedes-Benz ApprovedTire Brands',
  'MO':'Mercedes Original',
  'MOE':'Mercedes Original Extended',

  homepage_long_1: 'Mercedes Original tires are designed for the unique handling requirements and characteristics of each Mercedes-Benz vehicle model. The tire, road surface, suspension and driving safety systems work together to ensure MO tires deliver  optimal safety, especially when it comes to braking and hydroplaning.',
  homepage_long_2: 'Compared to conventional tires, Mercedes Original tires are designed for low rolling resistance, as well as ideal handling and comfort. These characteristics not only lead to better overall performance, but also reduced fuel consumption and greater cost efficiency.',
  homepage_long_3_a: 'The right tires designed to work seamlessly with the safety systems in your Mercedes',
  homepage_long_3_b: 'Mercedes-Benz Approved Winter Tires have advanced tread designs and rubber compounds that provide a superior grip on cold and icy roads',
  homepage_long_3_c: 'Competitive tire prices',
  homepage_long_3_d: 'Expert installation by factory-trained technicians',
  homepage_long_3_e: 'All major brands, including Pirelli, Continental, Michelin, Dunlop, and more',
  homepage_long_4: 'MOE stands for Mercedes Original Extended. This variant features run-flat properties that keep your vehicle under control following a loss of tire pressure. This helps you arrive at your local Mercedes-Benz service department, or tire-change location safely.',
  homepage_long_5: 'Your MO tires are your Mercedes-Benz vehicle’s connection to the road. Once the weather warms up to be consistently above 7ºC, it’s crucial to switch over to the right tire for the season, optimizing driving safety, performance, and fuel efficiency. Spring is coming. Book early and be ready.',
  homepage_long_4_a: 'The right tires designed to work seamlessly with the safety system in your Mercedes',
  homepage_long_4_b: 'Mercedes-Benz Approved Winter Tires have advanced tread designs and rubber compounds that provide a superior grip on cold and icy roads',
  homepage_long_4_c: 'Competitive tire prices',
  homepage_long_4_d: 'Expert installation by factory-trained technicians',
  homepage_long_4_e: 'All major brands, including Pirelli, Continental, Michelin, Dunlop, and more',
  homepage_title: 'Why Buy Mercedes-Benz ApprovedTires',
  homepage_winterpackages: 'Winter Tire & Wheel Packages',
  homepage_genuinewheels: 'Advantages on the road',
  homepage_wheeldevelopment: 'Increased Safety',
  homepage_design: 'Performance and Efficiency',
  homepage_quality: 'Additional Mobility with MOE',
  homepage_your_first_choice:'Designed and tested for Mercedes-Benz.\n' +
      '\n' +
      'The MO difference',
  homepage_wrapup:'Seasons change, and so should your tires.',
  time_to_change:'7º Celcius? Time for a change.',
  homepage_why_mercedes:'Why Purchase Your Tires From A Mercedes-Benz Dealer',
  nonOEWarrning: 'You have selected showing non approved tires. Mercedes always recommends using approved tires that are designed to your vehicle.',

  'logo': {
    'text': 'Mercedes ApprovedTire and Wheel Center'
  },
  'header': {
    'mercedesHeader': 'Approved TireCentre'
  },
  'common': {
    'viewTires': 'Find Tires',
    'viewWheels': 'Find Wheels',
    'viewAccessories': 'Find Accessories',
    'viewPackages': 'Find Packages',
    'viewCollections': 'Find Collections',
    'welcomeMessage': 'Welcome to the Mercedes ApprovedTire and Wheel Center',
    'getStarted': 'Get Started',
    'customizeYourRide': 'Customize your ride',
    'myWishlist': 'Wishlist',
    'select': 'Select',
    'yes': 'Yes',
    'no': 'No',
    'featured': 'Winter Tire Packages',
    'enter': 'Enter',
    'dealerSelectionPlaceholder': 'Search by city, province or postal code',
    'selectByProvinceTitle': 'Select your Province',
    'selectByProvinceHeaderTitle': 'ApprovedTire Centre',
    'myCart': 'My Wishlist'
  },
  'breadcrumbs': {
    'staticHome': 'Home'
  },
  'cart': {
    'getCartReview': 'Review My Wishlist',
    'cartBarTitlePrefix': 'Wishlist Preview',
    'cartBarTitleSuffix1': 'Item',
    'cartBarTitleSuffix2': 'Items',
    'mywishlist': 'Wishlist'
  },
  'search': {
    'placeholderSearchTireSize': '225/45R17 or 2254517',
    'placeholderSearchPartNumber': 'CON15480150000AS or 15480150000',
    'placeholderSearchChassis': 'w204 or w204.012',
    'searchChassisTag': 'Chassis Search'
  },
  'productTypes': {
    'packages': 'Winter Tire Packages',
    'packagesTab': 'Winter Tire Packages',
	  'searchTires': 'Mercedes Original Tires',//''Mercedes-Benz ApprovedTires',
	  'searchWheels': 'Genuine Wheels',
    'searchPackages': 'Winter Tire Packages',
	  'tireSelectText': 'Mercedes-Benz "Approved" Winter Tires have advanced tread designs and rubber compounds that provide a superior grip on cold and icy roads. They also provide greater stability when breaking, and better vehicle handling.',
	  'wheelSelectText': 'Mercedes-Benz uses an extensive development and testing program to ensure the quality of its various wheels. We tailor the development and testing phase to the actual stress profile of light-alloy wheels under real operating conditions – ensuring that our light-alloy wheels are among the most safe, reliable and durable products available in the car market today.',
	  'accessorySelectText': '',
	  'packageSelectText': 'Tires are a vital part of your Mercedes-Benz performance. They\'re the only contact your vehicle has with the road, and affect acceleration, braking, cornering, and the overall safety of your vehicle.'
  },
  'product': {
    'showOnlyApproved': 'Show Only ApprovedTires',
    'approvedTires': 'Why MO Tires?',
    'whyMo': 'Why MO Tires?',
    'DesignedFor': 'Designed and tested for Mercedes-Benz.',
    'nonApprovedTires': 'Non ApprovedTires',
    'good': '',
    'better': '',
    'best': '',
    'dealerPrice': 'Price',
    'retailPrice': 'Price',
    'wheel': 'Mercedes-Benz Approved Wheels',
    'wheelTag': '',
    'tire': 'Mercedes Original Tires',//'Mercedes-Benz ApprovedTires',
    'tireTag': 'Find the right Mercedes-Benz ApprovedTires by vehicle or tiresize',
    'accessory': 'Mercedes-Benz Approved Accessories.',
    'accessoryTag': '',
    'package': 'Winter Tire Packages',
    'packageTag': 'Find the right Tire and Wheel package for your vehicle',
    'collection': 'Collection',
    'collectionTag': '',
    'addToQuote': 'Add to Wishlist',
    'addToWishlist': 'Add to Wishlist',
    'removeFromWishlist': 'Remove from Wishlist',
    'itemAlert': '!Please see more details for vehicle applications',
	  'approvedTire': 'Mercedes Original Tires',//'Mercedes-Benz ApprovedTires',
    'nationalPartNumber': 'Part #',
    'tireSize': 'Size'
  },
  'partInfoModal': {
    'regionalPrice': 'MB Price (Region)'
  },
  'appointmentForm': {
    'printWishlist': 'Print Wishlist'
  },
  'launcher': {
    'viewResults': 'Shop Now',
  },
  'wishlist': {
    'title': 'Your Wishlist',
    'clear': 'Clear Wishlist',
    'saveWishlist': 'Save Wishlist',
    'emailWishlist': 'Email Wishlist',
    'scheduleAppointmentMsg': 'Schedule an appointment with your retailer for your wishlist',
    'personalizedWishlist': 'Your Personalized Wishlist',
    'wishlistSaved': 'Wishlist has been successfully saved.',
    'wishlistSaveFailed': 'Wishlist has failed to save.'
  },
  'guestAccountCreation': {
    'noEmail': 'Create an Account'
  }
};

module.exports = {
	
	findThePerfectTires: 'Find the perfect Tires',
	chooseTires: 'Choose Tires',
	
	homepage_title_1: 'Do you know which tires fit your vehicle?',
	homepage_title_2: 'We do.',
	homepage_subtitle: 'Every Audi needs the perfect pair of tires. Matching the tire to the top speed and loading requirement of your vehicle will ensure enhanced traction, handling and safety. When your vehicle isn\'t fitted with the proper tires, performance suffers, reducing traction, handling and control. To ensure your Audi is equipped with the right tires, your Audi dealer can help provide you with the proper recommendation that meets your Audi\'s needs perfectly.',
	
	homepage_wheel_title: 'Wheels',
	homepage_wheel_subtitle: 'Whether you\'re driving to work or traveling long distances, one thing is certain: Audi Genuine Wheels and Accessories are your perfect match to getting everywhere you want to go all year round.',
	homepage_wheel_button: 'Find wheels for your Audi',
	
	homepage_tire_title: 'Tires',
	homepage_tire_subtitle: 'Winter tires are uniquely engineered to take on the challenges that winter driving presents in ways that all-season tires simply can\'t. Their tread is also designed to provide improved traction on wet, slushy, snowy, icy and dry cold surfaces.',
	homepage_tire_button: 'Find tires for your Audi',
	
	homepage_package_title: 'Packages',
	homepage_package_subtitle: 'Audi winter wheels and packages are developed parallel to Audi vehicles, which means they work in total harmony with the models they are built for. They are designed to achieve the most optimal fit, ensuring that the wheels are flush to your vehicle\'s bodywork, no matter what their width.',
	homepage_package_button: 'Find packages for your Audi',
	
	'common': {
		'featuredItems': "Popular Items"
	},
	'productTypes': {
		'tireSelectText': 'Newer generations of winter tires are designed with a more flexible rubber compound that is less prone to stiffen in colder temperatures. Their tread is also designed to provide improved traction on wet, slushy, snowy, icy and dry cold surfaces.',
		'wheelSelectText': 'Whether you’re driving to work or traveling long distances, one thing is certain: Audi Genuine Winter Wheel Packages and Accessories are your perfect match to getting everywhere you want to go this winter.',
		'accessorySelectText': '',
		'packageSelectText': 'Audi Genuine Winter Wheel Packages and Accessories are your perfect match to getting everywhere you want to go this winter.'
	},
	'search': {
		'placeholderSearchTireSize': 'Enter your tire size: e.g. 245/40R18'
	},
	'launcher': {
		'enterTiresize': 'Enter your tire size: e.g. 245/40R18'
	}
};
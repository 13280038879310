var KeyMirror = require("keymirror");

export var DispatcherActions = KeyMirror({
  ACTIVATE_SEARCH_FLAG: null,
  API_CALL_FINISHED: null,
  API_CALL_STARTED: null,
  API_WEBSERVICE_HAS_FAILED: null,
  APPLICATION_VERSION_IS_OUTDATED: null,
  APPOINTMENT_FORM_SENT: null,
  ATTEMPTED_TIRE_WHEEL_PACKAGE_CREATION: null,
  ATTEMPT_TIRE_WHEEL_PACKAGE_CREATION: null,
  AUTHENTICATION_STATE_CLOSE_MODAL: null,
  // 'AUTHENTICATION_STATE_OPEN_MODAL': null,
  BREADCRUMBS_LOAD: null,
  BREADCRUMBS_PUSH_STATE: null,
  CAMPAIGN_DEALER_SELECTION_VEHICLE_LOADED: null,
  CAMPAIGN_IS_INACTIVE: null,
  CAMPAIGN_IS_INACTIVE_RESET: null,
  CAMPAIGN_LOAD_FOR_DEALER: null,
  CAROUSEL_DATA_LOADED: null,
  CHANGE_LANG: null,
  CHANGE_PRODUCT_TYPE: null,
  CHANGE_ROUTER_STATE_DIRECTLY: null,
  CHANGE_STATE_FROM_URL: null,
  CLICKED_ON_DEALER: null,
  CLOSE_EXTERNAL_LINK_WARNING: null,
  CLOSE_FORGOT_PASSWORD_MODAL: null,
  CLOSE_GUEST_ACCOUNT_CREATION_MODAL: null,
  CLOSE_NATIONAL_PART_PRICING_MODAL: null,
  CLOSE_NATIONAL_PART_STOCK_MODAL: null,
  CLOSE_MARK_AS_COMPLETE_MODAL: null,
  CLOSE_RETURN_TO_DEALER_SELECTION_WARNING: null,
  CLOSE_WISHLIST_APPOINTMENT_MODAL: null,
  CLOSE_WISHLIST_EMAIL_MODAL: null,
  CLOSE_WISHLIST_SCHEDULE_MODAL: null,
  COMMERCE_GENERATED: null,
  COMMERCE_LOADED: null,
  CONSUMER_ORDER_LOADED: null,
  DEACTIVATE_SEARCH_FLAG: null,
  DEALER_ACCOUNT_INFO_LOADED: null,
  DEALER_CITY_RESET: null,
  DEALER_CITY_SEARCHED: null,
  DEALER_INDEX_SEARCHED: null,
  DEALER_INFO_LOAD: null,
  DEALER_NAME_RESET: null,
  DEALER_NAME_SEARCHED: null,
  DEALER_SELECTED: null,
  DEALER_SELECTED_FOR_TOKEN: null,
  DEALER_SELECTION_CENTER_ON_DEALER: null,
  DEALER_SELECTION_CHANGE_SEARCH: null,
  DEALER_SELECTION_CHANGE_SEARCH_TYPE: null,
  DEALER_SELECTION_CHASSIS_SEARCH_FROM_URL: null,
  DEALER_SELECTION_CLOSE_DEALER_NAME_SEARCH_LIST: null,
  DEALER_SELECTION_CLOSE_INITIAL_LOCATION_MODAL: null,
  DEALER_SELECTION_CLOSE_WARNING_MODAL: null,
  DEALER_SELECTION_FILTER_DEALERS_POSITION: null,
  DEALER_SELECTION_RESET_DEALER_SEARCH: null,
  DEALER_SELECTION_RESET_EMPTY_SEARCH: null,
  DEALER_SELECTION_RESET_LAUNCH_DEALER: null,
  DEALER_SELECTION_SEARCH_IS_EMPTY: null,
  DEALER_SELECTION_SET_DISTANCE: null,
  DEALER_SELECTION_SHOW_WARNING_MODAL: null,
  DEALER_UNSELECTED: null,
  DECODE_URL_HASH: null,
  DEVELOPER_DISABLE_IMAGES: null,
  DEVELOPER_ENABLE_IMAGES: null,
  DISABLE_APPLICATION: null,
  DISPLAY_ERROR_PAGE: null,
  ECOMMERCE_GET_HISTORY: null,
  EMAIL_PROMOTION_CLOSE_MODAL: null,
  EMAIL_PROMOTION_FAILED: null,
  EMAIL_PROMOTION_OPEN_MODAL: null,
  EMAIL_PROMOTION_SUCCEEDED: null,
  FORCE_LOGIN_OPEN: null,
  FOXY_CART_ERROR_CLOSE: null,
  FOXY_CART_GENERATED: null,
  FOXY_CART_LOADED: null,
  GET_NATIONAL_PART_PRICING_BY_PART_ID: null,
  GET_NATIONAL_PART_STOCK_BY_PART_ID: null,
  GET_TIRE_PRICING_ANALYSIS: null,
  GET_TIRE_PRICING_INCENTIVE: null,
  GO_BACK: null,
  GROUP_ACCOUNT_INFO_LOADED: null,
  IMPRESSION_DATA_SAVED: null,
  INITIALIZE_APP_STATE: null,
  INITIALIZE_VEHICLE_PARAMETERS: null,
  LANDING_PAGE: null,
  LAUNCHER_UPDATE_VALUES: null,
  LAUNCHER_VEHICLE_MAKES_LOADED: null,
  LAUNCHER_VEHICLE_MODELS_LOADED: null,
  LAUNCHER_VEHICLE_PRODUCT_TYPES_LOADED: null,
  LAUNCHER_VEHICLE_YEARS_LOADED: null,
  LEAD_REPORT_HISTORY: null,
  LEAD_WAS_ACTIONED: null,
  LIST_OF_DEALERS_LOADED: null,
  LIST_OF_PROVINCIAL_DEALERS_LOADED: null,
  LOAD_DEALER_APPOINTMENT_INFO: null,
  LOAD_DEALER_CONFIG: null,
  LOAD_NATIONAL_PART_PRICING_INFO: null,
  LOAD_NATIONAL_PART_STOCK_INFO: null,
  LOAD_VEHICLE_SIMPLE_SEARCH: null,
  LOCATION_CHANGED: null,
  LOGIN_DIALOG_CLOSE: null,
  LOGIN_DIALOG_OPEN: null,
  LOGIN_FAILURE: null,
  LOGIN_HAS_EXPIRED: null,
  LOGIN_HAS_EXPIRED_RESET: null,
  LOGIN_SUCCESSFUL: null,
  LOGIN_TEXT_DIALOG_CLOSE: null,
  LOGIN_TEXT_DIALOG_OPEN: null,
  LOGOUT_SUCCESSFUL: null,
  MAP_BOUNDS_CHANGED: null,
  MAP_CENTER_CHANGED: null,
  MAP_FILTER_PROVINCE_SELECTED: null,
  MAP_SET_LOCATION: null,
  MAP_SET_USER_LOCATION: null,
  MAP_UPDATE_WINDOW_WIDTH: null,
  MAP_ZOOM_CHANGED: null,
  MODAL_MOUNTED: null,
  MODAL_REQUEST_UNMOUNT: null,
  MODAL_UNMOUNTED: null,
  NATIONAL_ACCOUNT_INFO_LOADED: null,
  NTD_CLOSE_MODAL: null,
  NTD_OPEN_MODAL: null,
  NTD_ORDERS_GET_HISTORY: null,
  NTD_ORDER_CUSTOMER_INFO_SAVED: null,
  NTD_ORDER_GET_PLACE_ORDER: null,
  NTD_ORDER_GET_PREVIEW: null,
  OPEN_FORGOT_PASSWORD_MODAL: null,
  OPEN_GUEST_ACCOUNT_CREATION_MODAL: null,
  OPEN_NATIONAL_PART_PRICING_MODAL: null,
  OPEN_MARK_AS_COMPLETE_MODAL: null,
  OPEN_RETURN_TO_DEALER_SELECTION_WARNING: null,
  OPEN_WISHLIST_APPOINTMENT_MODAL: null,
  OPEN_WISHLIST_REVIEW: null,
  OPEN_WISHLIST_SCHEDULE_MODAL: null,
  ORDER_REPORT_REQUEST_CLOSE: null,
  ORDER_REPORT_REQUEST_OPEN: null,
  ORDER_STATUS_LOADED: null,
  ORDER_STATUS_RESET: null,
  PRICING_ANALYSIS_TOGGLE: null,
  PRICING_INCENTIVE_TOGGLE: null,
  PRODUCT_COMPARISON_ADD: null,
  PRODUCT_COMPARISON_EMAIL: null,
  PRODUCT_COMPARISON_ERROR: null,
  PRODUCT_COMPARISON_FETCH: null,
  PRODUCT_COMPARISON_ON_LOAD: null,
  PRODUCT_COMPARISON_REMOVE: null,
  PRODUCT_COMPARISON_RESET: null,
  PRODUCT_COMPARISON_RESET_ERROR: null,
  PRODUCT_COMPARISON_RUN: null,
  PRODUCT_COMPARISON_SET_PART_QUANTITIES: null,
  PRODUCT_COMPARISON_SET_PART_QUANTITY: null,
  PRODUCT_DETAIL_CHANGED_REQUIRED_QUANTITY: null,
  PRODUCT_DETAIL_CHANGE_REQUIRED_QUANTITY: null,
  PRODUCT_DETAIL_CHANGED_QUANTITY: null,
  PRODUCT_DETAIL_CHANGED_TIRE_QUANTITY: null,
  PRODUCT_DETAIL_CHANGED_WHEEL_QUANTITY: null,
  PRODUCT_DETAIL_LOADED: null,
  PRODUCT_DETAIL_MODAL_LOAD_PART: null,
  PRODUCT_DETAIL_OPENED: null,
  PRODUCT_LIST_ACCESSORIES_LOADED: null,
  PRODUCT_LIST_ACCESSORY_CATEGORIES_SELECTED: null,
  PRODUCT_LIST_BRANDS_SELECTED: null,
  PRODUCT_LIST_CHANGE_ACCESSORY_QUANTITY: null,
  PRODUCT_LIST_CHANGE_COLLECTIONS_QUANTITY: null,
  PRODUCT_LIST_CHANGE_COLOUR: null,
  PRODUCT_LIST_CHANGE_COLOUR_IN_DETAIL: null,
  PRODUCT_LIST_CHANGE_PACKAGE_QUANTITY: null,
  PRODUCT_LIST_CHANGE_SELECTED_TIRE_QUANTITY: null,
  PRODUCT_LIST_CHANGE_TIRE_QUANTITY: null,
  PRODUCT_LIST_CHANGE_WHEEL_QUANTITY: null,
  PRODUCT_LIST_CLOSE_DETAIL: null,
  PRODUCT_LIST_CLOSE_TIRE_NOT_ORIGINAL_SIZE_WARNING: null,
  PRODUCT_LIST_CLOSE_WHEEL_NOT_ORIGINAL_SIZE_WARNING: null,
  PRODUCT_LIST_DETAIL_CHANGE_TIRE_QUANTITY: null,
  PRODUCT_LIST_HIDE: null,
  PRODUCT_LIST_ITEM_STOCK_REFRESH: null,
  PRODUCT_LIST_LIVE_STOCK_LOADED: null,
  PRODUCT_LIST_LOAD_ACCESSORY_CATEGORY_FILTER: null,
  PRODUCT_LIST_LOAD_RATING_SELECTED: null,
  PRODUCT_LIST_LOAD_SEASON_FILTER: null,
  PRODUCT_LIST_LOAD_SECONDARY_LOAD_RATING_SELECTED: null,
  PRODUCT_LIST_LOAD_SECONDARY_SPEED_INDEX_SELECTED: null,
  PRODUCT_LIST_LOAD_TIRE_BRAND_FILTER: null,
  PRODUCT_LIST_NEXT_PRODUCT: null,
  PRODUCT_LIST_NO_RESULTS_FOR_FILTER: null,
  PRODUCT_LIST_OPEN_DETAIL: null,
  PRODUCT_LIST_OPEN_DETAIL_FROM_CART: null,
  PRODUCT_LIST_PACKAGES_LOADED: null,
  PRODUCT_LIST_PACKAGES_LOADED_FROM_MODEL: null,
  PRODUCT_LIST_PACKAGES_LOAD_FROM_MODEL: null,
  PRODUCT_LIST_PACKAGE_PRICING_TOGGLE_ALL: null,
  PRODUCT_LIST_PLY_RATING_SELECTED: null,
  PRODUCT_LIST_PREV_PRODUCT: null,
  PRODUCT_LIST_PRICING_SELECTED: null,
  PRODUCT_LIST_PRICING_TOGGLE_ALL: null,
  PRODUCT_LIST_REQUEST_STOCK_REFRESH: null,
  PRODUCT_LIST_SEASON_SELECTED: null,
  PRODUCT_LIST_SET_CHASSIS_SEARCH_VALUE: null,
  PRODUCT_LIST_SET_SEARCH_TYPE: null,
  PRODUCT_LIST_SHOW: null,
  PRODUCT_LIST_SHOW_OR_HIDE_BRAND_FILTER: null,
  PRODUCT_LIST_SHOW_OR_HIDE_PLY_FILTER: null,
  PRODUCT_LIST_SHOW_OR_HIDE_PRICING_FILTER: null,
  PRODUCT_LIST_SPEED_INDEX_SELECTED: null,
  PRODUCT_LIST_STOCK_REFRESHED: null,
  PRODUCT_LIST_STOCK_TOGGLE: null,
  PRODUCT_LIST_TAG_SELECTED: null,
  PRODUCT_LIST_TIRES_LOADED: null,
  PRODUCT_LIST_TIRES_LOADED_FROM_CHASSIS: null,
  PRODUCT_LIST_TIRES_LOADED_FROM_MODEL: null,
  PRODUCT_LIST_TIRES_LOAD_FROM_CHASSIS: null,
  PRODUCT_LIST_TIRES_LOAD_FROM_MODEL: null,
  PRODUCT_LIST_TIRE_LIST_INCREMENT_PAGE_INDEX: null,
  PRODUCT_LIST_TIRE_SIZE_ID_SELECTED: null,
  PRODUCT_LIST_TIRE_SIZE_SELECTED: null,
  PRODUCT_LIST_UPDATE_TIRE_LIST: null,
  PRODUCT_LIST_VISUALIZER_SET_PART: null,
  PRODUCT_LIST_WARNING_SHOW: null,
  PRODUCT_LIST_WARNING_HIDE: null,
  PRODUCT_LIST_WHEELS_LOADED: null,
  PRODUCT_LIST_WHEEL_SEASON_SELECTED: null,
  PRODUCT_LIST_WHEEL_TIRE_SIZE_SELECTED: null,
  PRODUCT_LIST_WHEEL_VISUALIZER_RESET_SELECTED: null,
  PRODUCT_LIST_WHEEL_VISUALIZER_SET_SELECTOR: null,
  PRODUCT_QUICK_COMPARISON_RUN: null,
  PRODUCT_TYPE_SELECTED: null,
  PROVINCIAL_DEALERS_LIST_OF_DEALERS_LOADED: null,
  PROVINCIAL_DEALERS_LIST_OF_DEALERS_SELECTED: null,
  QUOTE_APPOINTMENT_SENT: null,
  QUOTE_CHANGE_DATE: null,
  QUOTE_CHANGE_TIME: null,
  QUOTE_CLOSE_WARNING: null,
  QUOTE_EMAIL_SENT: null,
  QUOTE_REPORT_LOADED: null,
  QUOTE_REPORT_REQUEST_CLOSE: null,
  QUOTE_REPORT_REQUEST_OPEN: null,
  QUOTE_REPORT_UPDATE_FILTER_COUNT: null,
  QUOTE_REPORT_UPDATE_FILTER_DATE: null,
  QUOTE_REPORT_UPDATE_SEARCH_INPUT: null,
  QUOTE_REPORT_UPDATE_SORT: null,
  QUOTE_SAVE_FAILURE: null,
  QUOTE_SAVE_SUCCESSFUL: null,
  RECAPTCHA_CLOSE_MODAL: null,
  RECAPTCHA_OPEN_MODAL: null,
  RESET_VEHICLE_SEARCH: null,
  SEARCH_FILTER_BRANDS: null,
  SEARCH_FILTER_LOAD_RATING: null,
  SEARCH_FILTER_PLY_RATING: null,
  SEARCH_FILTER_SEASON: null,
  SEARCH_FILTER_SPEED_INDEX: null,
  SEARCH_FILTER_TAG: null,
  SEARCH_INCREMENT_PAGE_INDEX: null,
  SEARCH_LOAD_ACCESSORY_CATEGORY_FILTER: null,
  SEARCH_LOAD_SEASON_FILTER: null,
  SEARCH_LOAD_TIRE_BRAND_FILTER: null,
  SEARCH_MODAL_VEHICLE_MAKES_LOADED: null,
  SEARCH_MODAL_VEHICLE_MODELS_LOADED: null,
  SEARCH_MODAL_VEHICLE_YEARS_LOADED: null,
  SEARCH_POPOVER_CLOSE: null,
  SEARCH_PRICING_SELECTED: null,
  SEARCH_PRICING_TOGGLE_ALL: null,
  SEARCH_REQUEST_ITEM_STOCK_REFRESH: null,
  SEARCH_RESULT_LOADED: null,
  SEARCH_RESULT_REQUEST_STOCK_REFRESH: null,
  SEARCH_RESULT_STOCK_REFRESHED: null,
  SEARCH_SET_PRODUCT_TYPE: null,
  SEARCH_SET_SEARCH_AND_TOKEN: null,
  SEARCH_SET_TOKEN: null,
  SEARCH_SET_TYPE: null,
  SEARCH_SHOW_OR_HIDE_BRAND_FILTER: null,
  SEARCH_SHOW_OR_HIDE_PLY_FILTER: null,
  SEARCH_SHOW_OR_HIDE_PRICING_FILTER: null,
  SESSION_REFERENCE_CLOSE_MODAL: null,
  SESSION_REFERENCE_FAILURE: null,
  SESSION_REFERENCE_SAVED: null,
  SET_COMPARISON_PDF_LAUNCHER: null,
  SET_DEFAULT_SEASON: null,
  SET_GUIDE_PDF_LAUNCHER: null,
  SET_OE_FILTER_VALUE: null,
  SET_PART_FOR_VISUALIZER: null,
  SET_PRODUCT_TYPE_VALUE: null,
  SET_RUN_FLAT_FILTER_VALUE: null,
  SET_SEARCH: null,
  SET_SEARCH_PRODUCT_TYPE_VALUE: null,
  SET_SELECTED_BRAND_ID: null,
  SET_SELECTED_SEASON: null,
  SET_SELECTED_TIRE_PART: null,
  SET_WIDGET_SEARCH: null,
  SHOPPING_CART_APPOINTMENT_INTEGRATION_MODAL_CLOSE: null,
  SHOPPING_CART_APPOINTMENT_INTEGRATION_MODAL_OPEN: null,
  SHOPPING_CART_CHANGED_REQUIRED_QUANTITY: null,
  SHOPPING_CART_CLOSE: null,
  SHOPPING_CART_CLOSE_DETAIL: null,
  SHOPPING_CART_CLOSE_FORM: null,
  SHOPPING_CART_CLOSE_DEALER_FORM: null,
  SHOPPING_CART_CLOSE_MODAL: null,
  SHOPPING_CART_CLOSE_QUANTITY_LIMIT_WARNING: null,
  SHOPPING_CART_CLOSE_WARNING: null,
  SHOPPING_CART_DEALER_FORM_ERROR: null,
  SHOPPING_CART_DEALER_FORM_RESET_ERROR: null,
  SHOPPING_CART_DEALER_FORM_SUCCESS: null,
  SHOPPING_CART_GET_NEW_CART: null,
  SHOPPING_CART_FORM_ERROR: null,
  SHOPPING_CART_FORM_SUCCESS: null,
  SHOPPING_CART_MODAL_CLOSE: null,
  SHOPPING_CART_MODAL_OPEN: null,
  SHOPPING_CART_NEW_ITEM_SEEN: null,
  SHOPPING_CART_OPEN: null,
  SHOPPING_CART_OPEN_DETAIL: null,
  SHOPPING_CART_OPEN_FORM: null,
  SHOPPING_CART_OPEN_DEALER_FORM: null,
  SHOPPING_CART_PRINTED: null,
  SHOPPING_CART_RELOADED: null,
  SHOPPING_CART_REMINDER_ERROR: null,
  SHOPPING_CART_REMINDER_SUCCESS: null,
  SHOPPING_CART_REQUEST_EMPTY: null,
  SHOPPING_CART_SHOW_WARNING: null,
  SHOW_EXTERNAL_LINK_WARNING: null,
  START_NEW_QUOTE_CLOSE_MODAL: null,
  START_NEW_QUOTE_OPEN_MODAL: null,
  SUBMIT_FORGOT_PASSWORD: null,
  SUBMIT_GUEST_ACCOUNT_FOR_CREATION: null,
  SWITCH_ACCESSORY_VIEW: null,
  TIRE_BRAND_DATA_LOADED: null,
  TOGGLE_FINANCE_MODAL: null,
  TIRE_LOADED_FROM_PART_DATA: null,
  TIRE_MODEL_PART_DATA_LOADED: null,
  TIRE_SIZE_HELPER_CLOSE: null,
  TIRE_SIZE_HELPER_OPEN: null,
  TOGGLE_CART_MODAL: null,
  TOGGLE_SUPPORT: null,
  TOGGLE_SEARCH_FLAG: null,
  VEHICLE_CATEGORIES_LOADED: null,
  VEHICLE_INFO_LOADED: null,
  VEHICLE_MAKES_LOADED: null,
  VEHICLE_MODELS_LOADED: null,
  VEHICLE_SELECTION_CATEGORY_SELECTED: null,
  VEHICLE_SELECTION_CATEGORY_SELECTED_FROM_MODAL: null,
  VEHICLE_SELECTION_MAKE_SELECTED: null,
  VEHICLE_SELECTION_MODEL_SELECTED: null,
  VEHICLE_SELECTION_MODEL_SELECTED_FROM_MODAL: null,
  VEHICLE_SELECTION_TRIM_SELECTED: null,
  VEHICLE_SELECTION_YEARS_SELECTED: null,
  VEHICLE_SELECTION_YEARS_SELECTED_FROM_MODAL: null,
  VEHICLE_TREE_LOADED: null,
  VEHICLE_TRIMS_LOADED: null,
  VEHICLE_YEARS_LOADED: null,
  VIEW_SHOPPING_CART_LOADED: null,
  VIEW_SHOPPING_CART_REMOVE_ITEM: null,
  WISHLIST_APPOINTMENT_FAILED: null,
  WISHLIST_APPOINTMENT_SENT: null,
  WISHLIST_DEALER_SELECT_MODAL_SEARCH: null,
  WISHLIST_EMAIL_FAILED: null,
  WISHLIST_EMAIL_SENT: null,
  WISHLIST_PRINT_LOADED: null,
  WISHLIST_REVIEW_CHANGE_ITEM: null,
  WISHLIST_REVIEW_LOADED: null,
  WISHLIST_SAVE_FAILURE: null,
  WISHLIST_SAVE_SUCCESSFUL: null,
  XTIME_INTEGRATION_RESET_LINK_ERROR: null,
  XTIME_INTEGRATION_URL_GATHERED: null,
});

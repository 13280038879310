module.exports = {
  'startNewQuote':'Nouvelle Soumission',
  'title':'Mercedes-Benz',
	'ourBrands': 'Nos Marques',

  homepage_long_1: 'Why choose MO Tires? When it comes to delivering optimal performance - and meeting your high standards - Mercedes Original (MO) Tires are the ideal match for your vehicle. Specifically designed for an exact fit and configured to work seamlessly with the safety systems inside a Mercedes-Benz, MO Tires provide maximum reliability, longevity, excellent ride confort, and low noise levels.',
  homepage_long_2: 'MOE tires feature run-flat properties thanks to renforced side walls so you can drive to safety in the event of an unexpected tire pressure loss for 80km at a maximum speed of 80 km/h. MOE tires are used exclusively in conjunction with a tire pressure monitoring system.',
  homepage_long_3_a: 'The right tires designed to work seamlessly with the safety systems in your Mercedes',
  homepage_long_3_b: 'Mercedes-Benz Approved Winter Tires have advanced tread designs and rubber compounds that provide a superior grip on cold and icy roads',
  homepage_long_3_c: 'Competitive tire prices',
  homepage_long_3_d: 'Expert installation by factory-trained technicians',
  homepage_long_3_e: 'All major brands, including Pirelli, Continental, Michelin, Dunlop, and more',
  homepage_long_4: 'Mercedes-Benz has skillfully designed wheels that harmoniously complement the bodywork and contribute to the overall design quality of our vehicles. Our wheels undergo extensive development and a testing program to measure the actual stress profile of light-alloy wheels under real operating conditions. This ensures that our light-alloy wheels are among the safest, most reliable and durable products available in the market today. In addition, only the most reputed and best suppliers are selected to manufacture the light-alloy wheels worldwide, and production subject to stringent Mercedes-Benz specifications.',
  homepage_title: 'Why Buy Mercedes-Benz ApprovesTires',
  homepage_winterpackages: 'Winter Tire & Wheel Packages',
  homepage_genuinewheels: 'Jantes d\'origine et pneus approuvés',
  homepage_wheeldevelopment: 'Mercedes-Benz Original (MO)',
  homepage_design: 'Mercedes-Benz Original Extended (MOE)',
  homepage_quality: 'Reason to Purchase ApprovedTires from an Authorized Mercedes-Benz Dealer',

  'logo': {
    'text': 'Les pneus approuvés Mercedes-Benz'
  },
  'header': {
    'mercedesHeader': 'Centre de pneus approuvés'
  },
  'common': {
    'viewTires': 'Voir les pneus',
    'viewWheels': 'Voir les jantes',
    'viewAccessories': 'Voir les accessoires',
    'viewPackages': 'Voir les packages',
    'welcomeMessage': 'Bienvenue au Centre de pneus apprové et jantes d\'origine de Mercedes-Benz',
    'getStarted': 'Démarrer la recherche',
    'customizeYourRide': 'Personnalisez votre vehicle',
    'myWishlist': 'Liste de souhaits',
    'select': 'Sélectionnez',
    'yes': 'Oui',
    'no': 'Non',
    'featured': 'Ensembles de pneu et roue d\'hiver',
    'enter': 'Entrer',
    'dealerSelectionPlaceholder': 'Recherche par ville, province ou code postal',
    'selectByProvinceTitle': 'Sélectionnez votre province',
    'selectByProvinceHeaderTitle': 'Centre de PneusApprouvés',
    'myCart': 'Liste de souhaits'
  },
  'breadcrumbs': {
    'staticHome': 'Accueil'
  },
  'cart': {
    'getCartReview': 'Revoir ma liste de souhaits',
    'cartBarTitlePrefix': 'Aperçu de ma liste de souhaits',
    'cartBarTitleSuffix1': 'article',
    'cartBarTitleSuffix2': 'articles',
    'mywishlist': 'Liste de souhaits'
  },
  'search': {
    'placeholderSearchTireSize': '225/45R17 ou 2254517',
    'placeholderSearchPartNumber': 'CON15480150000AS ou 15480150000',
    'placeholderSearchChassis': 'w204 ou w204.012',
    'searchChassisTag': 'Recherche de Châssis'
  },
  'productTypes': {
    'packages': 'Ensemble de jantes et pneus',
    'packagesTab': 'Ensemble de jantes et pneus',
	  'searchTires': 'Pneus d\'Origine Mercedes',//'Les pneus approuvés Mercedes-Benz',
	  'searchWheels': 'Genuine Wheels',
	  'searchPackages': 'Ensemble de jantes et pneus',
	  'tireSelectText': 'Les pneus d\'hiver approuvés Mercedes-Benz ont des bandes de roulement et des composés de caoutchouc perfectionnés qui offrent une adhérence supérieure sur les routes froides et verglacées. Ils offrent également une meilleure stabilité en cas de freinage et une meilleure maniabilité.',
	  'wheelSelectText': 'Mercedes-Benz suit un programme intensif de développement et d’essais pour assurer la qualité de ses différents modèles de jantes. Chez Mercedes-Benz, nous ajustons le développement et la phase d’essais au profil de résistance des jantes en alliage léger sous des conditions réelles. Cela nous permet de nous assurer que nos jantes en alliage léger figurent parmi les plus sécuritaires, les plus fiables et les plus durables sur le marché automobile d’aujourd’hui.',
	  'accessorySelectText': '',
	  'packageSelectText': 'Les pneus font partie intégrante des performances de votre Mercedes-Benz. En effet, seuls les pneus unissent votre voiture à la route. Ils ont donc un impact sur l\'accélération, le freinage, la prise de virage et la sécurité globale de votre véhicule.'
  },
  'quote': {
    'noteFieldLabel': 'Remarques',
    'userNoteLabel': 'Remarques'
  },
  'quoteReport': {
    'quoteNotes': 'Remarques'
   },
  'product': {
    'approvedTires': 'PneusApprouvés',
    'nonApprovedTires': 'Non PneusApprouvés',
    'good': '',
    'better': '',
    'best': '',
    'dealerPrice': 'Prix de vente',
    'retailPrice': 'Prix de détail',
    'wheel': 'Jante',
    'wheelTag': '',
    'tire': 'Pneus d\'Origine Mercedes',//'Les pneus approuvés Mercedes-Benz',
    'tireTag': 'Trouver les pneus approuvés par Mercedes-Benz soit par véhicule ou par grandeur de pneu',
    'accessory': 'Accessoire',
    'accessoryTag': '',
    'package': 'Ensemble de jantes et pneus',
    'packageTag': 'Trouver le meilleur ensemble de pneus d\'hiver et de roues pour votre véhicule',
    'collection': 'Collection',
    'collectionTag': '',
    'addToQuote': 'Ajouter au panier',
    'addToWishlist': 'Ajouter au panier',
    'removeFromWishlist': 'Enlevé de panier',
    'wheelComment': 'Remarques',
    'approvedTire': 'Pneus d\'Origine Mercedes',//'Les pneus approuvés Mercedes-Benz',
    'itemAlert': '!S\'il vous plaît voir plus de détails pour les véhicules qui vont avec ce pneu',
    'modal': {
      'wishlistSaved': 'La liste de souhaits a été enregistrée avec succès.',
      'wishlistSaveFailed': 'La liste de souhaits n\'a pas pu être enregistrée.'
    }
  },
  'partInfoModal': {
    'regionalPrice': 'Prix MB (Région)'
  },
  'appointmentForm': {
    'printWishlist': 'Imprimer la Liste de souhaits'
  },
  'wishlist': {
    'title': 'Votre liste de souhaits',
    'clear': 'Supprimer la liste de souhaits',
    'saveWishlist': 'Enregistrer la liste de souhaits',
    'emailWishlist': 'Expédier la liste de souhaits par courriel',
    'scheduleAppointmentMsg': 'Planifier un rendez-vous avec votre détaillant pour votre liste de souhaits',
    'personalizedWishlist': 'Votre liste de souhaits personnalisée',
    'wishlistSaved': 'Votre liste de souhaits a été enregistré avec succès.',
    'wishlistSaveFailed': 'L\'enregistrement de votre liste de souhaits a échoué.'
  },
  'guestAccountCreation': {
    'noEmail': 'Crée un compte'
  }
};

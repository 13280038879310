import { DateUtilities } from "../calendar/DateUtilities.jsx";
import { MonthHeader } from "../calendar/MonthHeader.jsx";
import { WeekHeader } from "../calendar/WeekHeader.jsx";
import { Weeks } from "../calendar/Weeks.jsx";

import { isDateAvailable } from "../../service/CalendarService";

var React = require("react");

export class Calendar extends React.Component {
  shouldComponentUpdate(lastProps, lastState) {
    return this.state !== lastState;
  }

  onSetDate = (date) => {
    this.setState({
      date: date,
    });
  };

  onSetView = (date) => {
    this.setState({
      view: date,
    });
  };

  onSetSelected = (date) => {
    this.setState({
      selected: date,
    });
    this.props.onSelect(date);
  };

  onMove = (view, isForward) => {
    this.refs.weeks.moveTo(view, isForward);
  };

  onTransitionEnd = () => {
    this.refs.monthHeader.enable();
  };

  render() {
    var startDate = new Date();

    var endDate = new Date();
    endDate.setFullYear(endDate.getFullYear() + 1);

    return React.createElement(
      "div",
      {
        className: "calendar" + (this.props.visible ? " visible" : ""),
      },
      React.createElement(MonthHeader, {
        ref: "monthHeader",
        view: this.state.date,
        onSetDate: this.onSetDate,
      }),
      React.createElement(WeekHeader, null),
      React.createElement(Weeks, {
        ref: "weeks",
        view: this.state.date,
        calendarProps: this.props,
        isDateAvailable: isDateAvailable,
        selected: this.state.selected,
        onTransitionEnd: this.onTransitionEnd,
        onSelect: this.onSetSelected,
        minDate: startDate,
        maxDate: endDate,
      })
    );
  }

  state = {
    date: DateUtilities.clone(this.props.view),
    view: DateUtilities.clone(this.props.view),
    selected: DateUtilities.clone(this.props.selected),
  };
}

import { applicationStore } from "../../stores/ApplicationStore.js";
import { modalActions } from "../../actions/ModalActions.js";
import { Image } from "../ux/Image.jsx";

import {
  getTabIndex,
  sizeOf,
  isEmpty,
  get_browser,
  isIEBrowser,
  generateGUID,
} from "../../service/UtilityService.js";
import {
  ADOBELAUNCHER_CLASS,
  setAdobeLaunchClass,
} from "../../service/AdobeLaunchService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var _ = require("lodash");
var Cx = require("classnames");

export class Modal extends I18nComponent {
  state = {
    uniqueIdentifier: generateGUID(),
    classUpdated: false,
  };

  componentDidMount() {
    if (this.props.refactor) {
      this.toggleAppScroll("modal-refactor", false);
      this.applyHeader();
      this.updateWrapperClass();
    } else {
      this.toggleAppScroll("modal", false);
    }
  }

  componentWillUnmount() {
    if (this.props.refactor) {
      this.toggleAppScroll("modal-refactor", true);
    } else {
      this.toggleAppScroll("modal", true);
    }
    setTimeout(function () {
      modalActions.modalUnmounted();
    }, 0);
  }

  componentDidUpdate() {
    if (this.props.refactor) this.updateWrapperClass();
  }

  applyHeader = () => {
    if (
      !(this.props.hideClose || this.props.hideHeader) &&
      this.checkModalClassForHeader(this.props.className)
    ) {
      var wrapper = document.getElementsByClassName("wrapper")[0];
      if (!wrapper)
        wrapper = document.getElementsByClassName("search-wrapper")[0];
      if (wrapper) {
        var div = document.createElement("div");
        var image = document.createElement("img");
        div.className = this.props.expandedHeader
          ? "modal-header expanded"
          : "modal-header";
        if (this.props.dontDisplayHeader) div.style = "display:none";
        image.className = this.getCloseButtonAdobeClass(
          "close-icon",
          ADOBELAUNCHER_CLASS.BUTTON.CLOSE
        );
        image.dataset.dtm = !isEmpty(this.props.datadtm)
          ? this.props.datadtm
          : "";
        image.alt = "Close";
        image.src = this.getCloseLink();
        image.onclick = this.onClickClose;
        div.appendChild(image);
        wrapper.insertBefore(div, wrapper.firstChild);
      }
    }
  };

  updateWrapperClass = () => {
    if (!this.state.classUpdated) {
      var wrapper = document.getElementsByClassName("wrapper")[0];
      if (wrapper) {
        wrapper.classList.add("wrapper-" + this.state.uniqueIdentifier);
        wrapper.classList.add("wrapper-alt");
        wrapper.classList.remove("wrapper");
      } else {
        wrapper = document.getElementsByClassName("search-wrapper")[0];
        if (wrapper) {
          wrapper.classList.add(
            "search-wrapper-" + this.state.uniqueIdentifier
          );
          wrapper.classList.add("search-wrapper-alt");
          wrapper.classList.remove("search-wrapper");
        }
      }
      this.setState({ classUpdated: true });
    }
  };

  toggleAppScroll = (className, enable) => {
    if (
      !this.props.keepBodyScroll &&
      sizeOf(document.getElementsByClassName(className)) > 0
    ) {
      document.body.setAttribute(
        "style",
        "overflow-y: " + enable ? "scroll" : "hidden" + " !important;"
      );
    }
  };

  render() {
    var browser = get_browser();
    var modalClassName = Cx({
      modal: !this.props.refactor,
      "modal-refactor": this.props.refactor,
      "full-width": this.props.fullWidth,
      "is-explorer": isIEBrowser(),
      "explorer-modal": browser.name === "IE" || browser.name === "MSIE",
      "small-modal": this.props.refactor && this.props.useShortHeaderBar,
      "no-print": true,
      "modal-warning": this.props.includeCloseButton,
      "province-selection-modal": this.props.includeCloseButton,
      "no-scroll": this.props.noScroll,
    });
    if (this.props.className) modalClassName += " " + this.props.className;
    var headerClassName = Cx({
      "modal-header": true,
      "detail-header": this.props.productDetailModal,
      half: this.props.useShortHeaderBar,
      tablet: !this.props.useShortHeaderBar && this.props.useTabletHeaderBar,
      up: this.props.productDetailModal || this.props.shoppingCartModal,
      down: this.props.slideHeaderDown,
      "dealer-selection-modal": this.props.dealerSelectionModal,
      "full-header": this.props.fullHeader,
      "ntd-modal-header": this.props.className === "ntd-modal",
    });
    if (this.props.size) modalClassName += " " + this.props.size;
    if (this.props.applyTemplateStyle) {
      var closeButton = (
        <div onClick={this.onClickClose}>
          <Image
            className={setAdobeLaunchClass(
              "close-x",
              ADOBELAUNCHER_CLASS.BUTTON.CLOSE
            )}
            datadtm={this.props.datadtm}
            src="/static/img/close.png"
            alt="Icon Close"
          />
        </div>
      );
      switch (applicationStore.data.defaultTemplate) {
        case "mercedes":
          closeButton = (
            <div onClick={this.onClickClose}>
              <Image
                className={setAdobeLaunchClass(
                  "close-x",
                  ADOBELAUNCHER_CLASS.BUTTON.CLOSE
                )}
                datadtm={this.props.datadtm}
                src="/static/img/mercedes/close.png"
                alt="Icon Close"
              />
            </div>
          );
          break;
        case "mercedes_2022":
          closeButton = (
            <div onClick={this.onClickClose}>
              <Image
                className={setAdobeLaunchClass(
                  "close-x",
                  ADOBELAUNCHER_CLASS.BUTTON.CLOSE
                )}
                datadtm={this.props.datadtm}
                src="/static/img/mercedes/close.png"
                alt="Icon Close"
              />
            </div>
          );
          break;
        case "toyota":
          closeButton = (
            <div onClick={this.onClickClose}>
              <Image
                className={setAdobeLaunchClass(
                  "close-x",
                  ADOBELAUNCHER_CLASS.BUTTON.CLOSE
                )}
                datadtm={this.props.datadtm}
                src="/static/img/toyota/close.png"
                alt="Icon Close"
              />
            </div>
          );
          break;
        default:
          break;
      }
      return (
        <div id={this.state.uniqueIdentifier} className={modalClassName}>
          <div id={this.props.testId} className="modal-cont">
            <div className={headerClassName}>
              <h3>{this.props.title}</h3>
              {!this.props.hideCloseButton ? closeButton : false}
            </div>
            {this.props.children}
            {!this.props.hideHeader ? (
              <div
                id={this.state.uniqueIdentifier + "-curtain"}
                className="curtain"
                onClick={this.onClickCurtain}
              ></div>
            ) : (
              false
            )}
          </div>
        </div>
      );
    } else if (this.props.applyHeaderBar) {
      return (
        <div id={this.state.uniqueIdentifier} className={modalClassName}>
          <div id={this.props.testId} className="modal-cont">
            <div className="modal-with-bar-header">
              <div className="header-with-bar">
                <div onClick={this.onClickClose}>
                  <Image
                    className={setAdobeLaunchClass(
                      "close-x",
                      ADOBELAUNCHER_CLASS.BUTTON.CLOSE
                    )}
                    datadtm={this.props.datadtm}
                    src="/static/img/close.png"
                    alt="Icon Close"
                  />
                </div>
              </div>
              {this.props.children}
              {!this.props.hideHeader ? (
                <div
                  id={this.state.uniqueIdentifier + "-curtain"}
                  className={
                    this.props.fullCurtain ? "curtain full" : "curtain"
                  }
                  onClick={this.onClickCurtain}
                ></div>
              ) : (
                false
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id={this.state.uniqueIdentifier} className={modalClassName}>
          <div id={this.props.testId} className="modal-cont">
            {this.renderCloseButtonCorner()}
            {this.props.children}
            {this.renderCloseButton()}
            {!this.props.fullCurtain && !this.props.hideHeader ? (
              <div
                id={this.state.uniqueIdentifier + "-curtain"}
                className="curtain"
                onClick={this.onClickCurtain}
              ></div>
            ) : (
              false
            )}
          </div>
        </div>
      );
    }
  }

  getCloseButtonAdobeClass = (className) => {
    if (!isEmpty(this.props.datadtm)) {
      return setAdobeLaunchClass(className, ADOBELAUNCHER_CLASS.BUTTON.CLOSE);
    } else {
      return className;
    }
  };

  renderCloseButton = () => {
    if (this.props.includeCloseButton) {
      return (
        <div className="close-modal no-print">
          <button
            className="btn"
            tabIndex={getTabIndex()}
            aria-label={this.t("shoppingCartWarningsModal.continue")}
            onClick={this.onClickClose}
          >
            {this.t("shoppingCartWarningsModal.continue")}
          </button>
        </div>
      );
    } else {
      return false;
    }
  };

  renderCloseButtonCorner = () => {
    if (this.props.closeButton) {
      return (
        <div className="modal-close" onClick={this.onClickClose}>
          <Image
            className={setAdobeLaunchClass(
              "close-x",
              ADOBELAUNCHER_CLASS.BUTTON.CLOSE
            )}
            datadtm={this.props.datadtm}
            src="/static/img/close.png"
            alt="Icon Close"
          />
        </div>
      );
    } else {
      return false;
    }
  };

  getCloseLink = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mazda":
        return "/static/img/mazda/close.png";
      case "mercedes":
        return "/static/img/mercedes/close.png";
      case "mercedes_2022":
        return "/static/img/mercedes/close.png";
      case "volkswagen":
        return "/static/img/volkswagen/close_icon.png";
      default:
        return "/static/img/close_circle.png";
    }
  };

  checkModalClassForHeader = (className) => {
    if (className === "quote-report-modal") return false;
    else return true;
  };

  onClickClose = () => {
    if (this.props.requestUnmount) {
      this.props.requestUnmount();
    } else {
      modalActions.requestUnmount();
    }
  };

  onClickCurtain = () => {
    if (!this.props.hideClose) {
      if (this.props.requestUnmount) {
        this.props.requestUnmount();
      } else {
        modalActions.requestUnmount();
      }
    }
  };
}

import { applicationStore } from "../../stores/ApplicationStore.js";
import { applicationActions } from "../../actions/ApplicationActions.js";
import { productListStore } from "../../stores/ProductListStore.js";
import { productListActions } from "../../actions/ProductListActions.js";

import { Image } from "../ux/Image.jsx";
import { Modal } from "../ux/Modal.jsx";

import { RouterStates } from "../../constants/RouterStates";

import {
  sizeOf,
  getAppLanguage,
  sortTiresizes,
} from "../../service/UtilityService";
import { captchaOnComponentDidMount } from "../../service/CaptchaService";
import {
  renderFilterBrandSection,
  renderFilterMultiSelectTiresizeSection,
} from "../../service/FilterService";
import { buildFilter } from "../../service/ProductList";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var Immutable = require("immutable");
var $ = require("jquery");

export class SearchBrandsBySeason extends I18nComponent {
  state = {
    season: applicationStore.data.selectedSeason,
    seasonName: applicationStore.data.selectedSeasonName,
    tireModelArray: productListStore.data.tireModelArray,

    filterTiresizes: productListStore.data.tireModelTiresizeArray,
    selectedTiresize: [],

    dealerTireBrands: applicationStore.data.dealerTireBrands,
    tireBrandList: Immutable.fromJS([]),
    filteredTireBrands: [],
    selectedBrands: [],

    selectedModel: null,
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    $(function () {
      $("body").animate({ scrollTop: 0 }, "slow");
    });
    this.getDefaultFilter();
    captchaOnComponentDidMount(
      true,
      false,
      this.loadBrandModelListBySeason,
      true
    );
  }

  componentDidUpdate(lastProps, lastState) {
    if (
      (sizeOf(this.state.filterTiresizes) > 0 &&
        sizeOf(lastState.filterTiresizes) === 0) ||
      (sizeOf(this.state.tireModelArray) > 0 &&
        sizeOf(lastState.tireModelArray) > 0 &&
        sizeOf(this.state.tireModelArray) !== sizeOf(lastState.tireModelArray))
    ) {
      this.getDefaultFilter();
    }
    if (this.state.season !== lastState.season) {
      this.loadBrandModelListBySeason();
    }
  }

  loadBrandModelListBySeason = (token = "") => {
    productListActions.loadBrandModelListBySeason(token);
  };

  getDefaultFilter = () => {
    var tiresizeKeys = [],
      tireBrandKeys = [],
      filteredTireBrandKeys = [],
      tireBrandList = {};
    for (var i in this.state.filterTiresizes)
      tiresizeKeys.push(this.state.filterTiresizes[i]["tireSizeSpecId"]);
    for (var i in this.state.dealerTireBrands)
      tireBrandKeys.push(
        this.state.dealerTireBrands[i]["part_manufacturer_id"]
      );
    for (var i in this.state.tireModelArray)
      if (
        filteredTireBrandKeys.indexOf(
          this.state.tireModelArray[i]["tire_manufacturer_id"]
        ) === -1
      )
        filteredTireBrandKeys.push(
          this.state.tireModelArray[i]["tire_manufacturer_id"]
        );
    tireBrandList = Immutable.fromJS(this.state.dealerTireBrands).map(
      (brand, brandId) =>
        buildFilter(brandId, brand.get("part_manufacturer_name"))
    );
    this.setState({
      selectedTiresize: tiresizeKeys,
      selectedBrands: tireBrandKeys,
      filteredTireBrands: filteredTireBrandKeys,
      tireBrandList: tireBrandList,
    });
  };

  render() {
    var _this = this;
    return (
      <div className="brand-tile-list">
        <div className="brand-title">
          <h2>
            {this.getSeasonText(this.state.season)}{" "}
            {this.t("productTypes.tires")}
          </h2>
        </div>
        <div className="brand-model-filter">
          <div className="filter-section brand">
            <div>
              {renderFilterBrandSection(
                true,
                true,
                null,
                this.state.selectedBrands,
                this.state.tireBrandList,
                this.state.filteredTireBrands,
                this.onBrandSelected
              )}
            </div>
            <div>
              {renderFilterMultiSelectTiresizeSection(
                true,
                this.state.selectedTiresize,
                this.state.filterTiresizes,
                this.onSelectedAllTiresizes,
                this.onUnselectAllTiresizes,
                this.onTiresizeSelected
              )}
            </div>
          </div>
        </div>
        <div className="brand-model-table">
          {Immutable.fromJS(this.state.tireModelArray)
            .filter(this.filterTireModels)
            .map(function (tireModel, index) {
              return (
                <div
                  key={"tire-model-" + index}
                  className={
                    index === 0
                      ? "tire-model-container first"
                      : "tire-model-container"
                  }
                >
                  <div className="tire-model">
                    <div className="left-side">
                      <div className="tire-model-image">
                        <Image
                          className="model-image"
                          src={tireModel.get("model_image")}
                        />
                        <Image
                          className="brand-image"
                          src={tireModel.get("tire_manufacturer_logo")}
                        />
                      </div>
                    </div>

                    <div className="right-side">
                      <div className="tire-model-header">
                        <h2>{tireModel.get("model_name")}</h2>
                      </div>

                      <div className="tire-model-description">
                        <p
                          className="model-description"
                          dangerouslySetInnerHTML={{
                            __html:
                              _this.t("product.description") +
                              ": " +
                              tireModel
                                .get("model_description")
                                .get(getAppLanguage()),
                          }}
                        ></p>
                      </div>

                      <div className="tire-model-controls">
                        <button
                          className="btn"
                          onClick={_this.onViewTiresizeSelected.bind(
                            _this,
                            tireModel.get("tire_model_id")
                          )}
                        >
                          {_this.t("viewTiresize")}
                        </button>
                        <button
                          className="btn"
                          onClick={_this.onModelSelected.bind(_this, tireModel)}
                        >
                          {_this.t("selectModel")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        {this.renderTiresizeModal()}
      </div>
    );
  }

  renderTiresizeModal = () => {
    var selectedModel = this.state.selectedModel;
    if (selectedModel !== null) {
      var tiresizeArray = [];
      Immutable.fromJS(this.state.tireModelArray).map(function (tireModel) {
        if (tireModel.get("tire_model_id") === selectedModel) {
          tiresizeArray = tireModel.get("tire_size_array");
        }
      });
      if (tiresizeArray) {
        return (
          <Modal refactor={true} requestUnmount={this.closeTiresizeModal}>
            <div className="wrapper">
              <div className="modal-content tire-model-sizes">
                <div className="tire-size-header">
                  <h2>{this.t("tiresizeList")}</h2>
                </div>

                <div className="tire-size-list">
                  <ul>
                    {Immutable.fromJS(this.state.filterTiresizes)
                      .sort(sortTiresizes)
                      .map(function (tiresize, index) {
                        if (
                          tiresizeArray.indexOf(
                            tiresize.get("tireSizeSpecId").toString()
                          ) > -1
                        ) {
                          return (
                            <li key={"tire-size-list-" + index}>
                              {tiresize.get("formattedTiresize")}
                            </li>
                          );
                        } else {
                          return false;
                        }
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </Modal>
        );
      } else return false;
    } else return false;
  };

  closeTiresizeModal = () => {
    this.setState({
      selectedModel: null,
    });
  };

  getSeasonText = (season) => {
    switch (season) {
      case "0":
      case 0:
        return this.t("product.filters.season.winter");
      case "1":
      case 1:
        return this.t("product.filters.season.allSeason");
      case "2":
      case 2:
        return this.t("product.filters.season.summer");
    }
  };

  filterTireModels = (tireModel) => {
    var selectedTiresizeList = this.state.selectedTiresize,
      tiresizeArray = tireModel.get("tire_size_array"),
      allowTiresize = false;

    if (sizeOf(selectedTiresizeList) > 0 && sizeOf(tiresizeArray) > 0) {
      tiresizeArray.map(function (tiresize) {
        if (
          !allowTiresize &&
          selectedTiresizeList.indexOf(parseInt(tiresize)) > -1
        ) {
          allowTiresize = true;
        }
      });
    }

    if (allowTiresize) {
      if (
        this.state.selectedBrands.indexOf(
          tireModel.get("tire_manufacturer_id")
        ) === -1
      ) {
        allowTiresize = false;
      }
    }

    return allowTiresize;
  };

  onViewTiresizeSelected = (modelId) => {
    this.setState({
      selectedModel: modelId,
    });
  };

  onModelSelected = (tireModel) => {
    applicationActions.loadTireModelInfo(
      tireModel.get("tire_manufacturer_id"),
      tireModel.get("tire_model_id"),
      ""
    );
    setTimeout(() => {
      applicationActions.changeRouterStateDirectly(
        RouterStates.SEARCH_MODELS_BY_SEASON
      );
    }, 100);
  };

  onBrandSelected = (brands) => {
    this.setState({
      selectedBrands: brands,
    });
  };

  onTiresizeSelected = (tiresize) => {
    var selectedTiresizes = this.state.selectedTiresize;
    if (
      sizeOf(selectedTiresizes) > 0 &&
      selectedTiresizes.indexOf(tiresize) > -1
    ) {
      //Remove from List
      var temp = [];
      for (var i in selectedTiresizes) {
        if (selectedTiresizes[i] !== tiresize) {
          temp.push(selectedTiresizes[i]);
        }
      }
      this.setState({
        selectedTiresize: temp,
      });
    } else {
      //Add to List
      var temp = [];
      for (var i in selectedTiresizes) {
        temp.push(selectedTiresizes[i]);
      }
      temp.push(tiresize);
      this.setState({
        selectedTiresize: temp,
      });
    }
  };

  onSelectedAllTiresizes = () => {
    var tiresizeKeys = [];
    for (var i in this.state.filterTiresizes)
      tiresizeKeys.push(this.state.filterTiresizes[i]["tireSizeSpecId"]);
    this.setState({
      selectedTiresize: tiresizeKeys,
    });
  };

  onUnselectAllTiresizes = () => {
    this.setState({
      selectedTiresize: [],
    });
  };

  onChange = () => {
    this.setState({
      season: applicationStore.data.selectedSeason,
      seasonName: applicationStore.data.selectedSeasonName,
      dealerTireBrands: applicationStore.data.dealerTireBrands,
      tireModelArray: productListStore.data.tireModelArray,
      filterTiresizes: productListStore.data.tireModelTiresizeArray,
    });
  };
}

module.exports = {

  homepage_tire_title: 'Tire Inventory',
  homepage_wheel_title: 'Wheel Inventory',
  homepage_package_title: 'Winter Package Inventory',

  homepage_tire_text: 'Whether you re looking to replace individual worn tires or purchase a complete set of All-Season, Cold Weather, or High-Performance Tires, your MINI Retailer offers a wide selection of MINI-Approved tires from industry-leading manufacturers.',
  homepage_wheel_text: 'These premium wheels are engineered to help deliver peak performance and precisely designed to complement your MINI\'s unique driving dynamics. Best of all, you can choose from a wide range of wheel design options to create a style that suits you.',
  homepage_package_text: 'Don\'t get cold feet while chasing adventure on your winter wanderings. MINI Approved Cold Weather Wheel Packages conveniently combine high traction tires with precision crafted wheels designed to match your MINI\'s safety specifications. That way, you can easily equip your vehicle to drive safely, securely, and confidently wherever the colder roads of the season may lead.',


  shop_tires: 'Shop Tires',
  shop_wheels: 'Shop Wheels',
  shop_packages: 'Shop Packages',

  changeDealer: 'Change Retailer',
  contactDealer: 'Contact Retailer',
  dealerSelection: 'Retailer Selection',
  'common': {
    contactDealer: 'Contact Retailer',
  },
  'breadcrumbs': {
    dealerSelection: 'Select Your Retailer',
  },
  'cart': {
    'modal': {
      quantityLimitWarning: 'We\'re sorry. You\'ve requested to purchase more than the allotted quantity of the same item at one time. For larger purchases please contact your retailer.',
    }
  },
  'shoppingCartWarnings': {
    ITEM_WARNING_TIRE_WHEEL_MISMATCH: 'Please be advised the quote you are creating includes incompatible tires and wheels. If necessary, contact the retailer for more information. ',
    ITEM_WARNING_TIRE_WHEEL_IDEAL_SIZE_MISMATCH: 'Please be advised the quote you are creating includes tires that do not meet the wheels {}. Please change your selection or contact the retailer for additional information.',
  },
  'search': {
    selectDealer: 'Contact Retailer for Pricing',
    selectDealerShort: 'Contact Retailer for Pricing',
    retailer: 'Retailer',
    dealer: 'Retailer',
  },
  'guestAccountCreation': {
    dealerUrlCode: 'Retailer Code',
  },
  'wishlist': {
    nonOrderableDisclaimer_2: '(Retailer will confirm availability upon arranging installation). ',
    orderDealerContact: 'You will be contacted within 1 - 2 business days by phone to book an appointment or you can contact the retailer at [], or by email at {}.',
    orderContactDisclaimer: 'You may be asked for your email during the appointment, if you have any questions you may contact the retailer at',
    appointmentDisclaimer: 'Thank you. An email has been sent to the retailer. You will be contacted within 24 hours.',
    titleRequestContact: 'Would you like the retailer to contact you to review your tire needs?',
    titleReserveItemsDealer: 'Reserve items from retailer',
    subtitleReserveItemsDealer: 'Fill in the the form below to reserve items from the retailer. Retailer will confirm availability.',
    appointmentSuccess: 'Thank you. A message has been sent to the retailer. You will be contacted within 24 hours to confirm appointment time.',
    errorNoDealerUrlCode: 'Retailer Url Code is required.',
  },
  'partInfoModal': {
    dealerPrice: 'Retailer Price',
  },
  'launcher': {
    selectYourDealer: "Select Your Retailer",
    dealerError: "A retailer must be selected.",
  },
  'comparison': {
    comparisonFailed: "We're sorry, comparison is unavailable because one or more of the items selected does not have pricing. Please contact the retailer for further details.",
  },
  'campaign': {
    campaignInactiveMessageDealer: 'Please select retailer to see their current offers.',
    campaignInactiveMessageNational: 'Please select a retailer to see their current offers.',
    campaignInactiveButtonDealer: 'Retailer Homepage',
    campaignInactiveButtonNational: 'Select a Retailer',
  },
  'dealerFilter': {
    titleSuffix: 'Retailer',
  },

  'product': {

    contactDealerForPricing: 'Contact Retailer for Pricing',
    dealerTotal: 'Retailer Total',
    noTiresResultsAvailable: 'Sorry, there are no tires available for this vehicle. Please contact retailer for more information.',
    noTiresResultsAvailableForChassis: 'Sorry, there are no tires available for this chassis. Please contact retailer for more information.',
    noTiresResultsAvailableForPartNumber: 'Sorry, there are no tires available for this part number. Please contact retailer for more information.',
    noTiresResultsAvailableForTireSize: 'Sorry, there are no tires available for this tire size. Please contact retailer for more information.',
    noWheelResultsAvailable: 'Sorry, there are no wheels available for this vehicle. Please contact retailer for more information.',
    noAccessoryResultsAvailable: 'Sorry, there are no accessories available for this vehicle. Please contact retailer for more information.',
    noPackageResultsAvailable: 'Sorry, there are no packages available for this vehicle. Please contact retailer for more information.',
    requestAppointment: 'Contact retailer',
    requestAppointment_alt: 'Contact retailer',
    warrantyMissing: 'Please contact retailer for warranty information.',

    tireWarranty: 'Tire Manufacturer Warranty',

    addToQuote: 'Add to Quote',
    addToWishlist: 'Add to Quote',

    'showOnlyApproved': 'Show Only Approved Tires',
    'approvedTires': 'ApprovedTires',
    'nonApprovedTires': 'Non-Approved Tires',
    'good': '',
    'better': '',
    'best': '',
    'dealerPrice': 'Price',
    'retailPrice': 'Price',
    'tire': 'MINI ApprovedTires',
    'approvedTire': 'MINI Star Approved Tires',

  },

};
import { applicationStore } from '../stores/ApplicationStore.js';
import { languageStore } from '../stores/LanguageStore.js';

import { ProductTypes } from '../constants/ProductTypes';
import { logoList } from '../constants/PdfHeaderLogo';

import { buildHeader, buildCustomerInformation, buildLegalFooter, getStyles, templateBtnClr } from "./PdfService";
import { isEmpty, sizeOf, getAppTemplate, money_format, getAppLanguage } from '../service/UtilityService';
import { isUserLoggedIn } from "../api/AuthService";

var _ = require('lodash');

/*
 *
 *  COMPARISON PDF
 *
 */

export const getComparisonPdfFilename = () => {
  var template = applicationStore.data.defaultTemplate.toUpperCase();
  return "Tire Comparison" + " - " + template + ".pdf";
};

export const getComparisonDocDefinition = (dealerInfo, encodedImageList, comparisonDataList, comparisonList) => {
  var hasCustomLogo = !isEmpty(applicationStore.data.dealerRetailSiteCustomLogo) && getAppTemplate() !== 'mazdatransition' ? true : false;
  var logo = !hasCustomLogo ? logoList[getAppTemplate()] : encodedImageList.logo;

  //console.log('getComparisonDocDefinition, hasCustomLogo: '+hasCustomLogo);
  //console.log(comparisonDataList);
  //console.log(comparisonList);

  var document = {
    header: buildHeader(dealerInfo),
    content: [
      buildComparisonTitle(),
      buildCustomerInformation(),
      buildComparisonTable(hasCustomLogo, dealerInfo, encodedImageList, comparisonDataList, comparisonList),
      buildLegalFooter()
    ],
    styles: getStyles(),
    defaultStyle: { font: getAppTemplate() },
    images: { logo: logo }
  };

  return (
    document
  );
};

const buildComparisonTitle = () => {
  var dictionary = languageStore.getDictionary();
  return (
    {
      margin: [ -17.5, 52.5, -17.5, 0 ],
      text: [
        {
          text: dictionary.comparison.title + " ",
          style: 'title'
        }
      ]
    }
  );
};

const buildComparisonTable = (hasCustomLogo, dealerInfo, encodedImageList, comparisonDataList, comparisonList) => {
  var dictionary = languageStore.getDictionary(), rowWidthList = [ 'auto' ];
  switch(sizeOf(comparisonDataList)) {
    case 1:
      rowWidthList = [ 'auto', '*' ];
      break;
    case 2:
      rowWidthList = [ 'auto', '*', '*' ];
      break;
    case 3:
      rowWidthList = [ 'auto', '*', '*', '*' ];
      break;
  }

  return (
    {
      style: 'table',
      table: {

        headerRows: 0,
        widths: rowWidthList,
        body: getComparisonBody(hasCustomLogo, dictionary, encodedImageList, comparisonDataList, comparisonList)

      },
      layout: getComparisonTableLayout()
    }
  );
};

const getComparisonTableLayout = () => {
  return (
    {
      hLineWidth: function (i, node) {
        if (i === 1 || i === 2 || i === 7 || i === 8) return 2;
        else return 1;
      },
      vLineWidth: function (i, node) {
        return 1;
      },
      hLineColor: function (i, node) {
        if (i === 1 || i === 2 || i === 7 || i === 8) return '#000000';
        else return '#CDCDCD';
      },
      vLineColor: function (i, node) {
        return '#CDCDCD';
      },
      fillColor: function (i, node) {
        if (i === 0 || i === 2 || i === 4 || i === 6 || i === 8 || i === 10 || i === 12) return '#F1F1F1';
        if (i === 1 || i === 7) return '#FFFFFF';
        if (i === 3 || i === 5 || i === 9 || i === 11) return '#F8F8F8';
        if (i === 13) return templateBtnClr();
      }
    }
  );
};

const getComparisonBody = (hasCustomLogo, dictionary, encodedImageList, comparisonDataList, comparisonList) => {
  var body = [
    getComparisonImageRow(hasCustomLogo, encodedImageList, comparisonDataList),
    getComparisonTitleRow(1, dictionary, comparisonDataList),
    getComparisonProductDetailsRow(dictionary, comparisonDataList),
    getComparisonProductSeasonRow(dictionary, comparisonDataList),
    getComparisonProductDescriptionRow(dictionary, comparisonDataList),
    getComparisonProductWarrantyRow(dictionary, comparisonDataList),
    getComparisonProductOtherInformationRow(dictionary, comparisonDataList),
    getComparisonTitleRow(2, dictionary, comparisonDataList),
    getComparisonPricingDetailsRow(dictionary, comparisonDataList, comparisonList),
    getComparisonProductPromotionRow(dictionary, comparisonDataList, comparisonList),
    getComparisonProductAdditionalCostsRow(dictionary, comparisonDataList, comparisonList),
    getComparisonProductSubtotalRow(dictionary, comparisonDataList, comparisonList),
    getComparisonProductTaxRow(dictionary, comparisonDataList, comparisonList),
    getComparisonProductGrandTotalRow(dictionary, comparisonDataList, comparisonList)
  ];
  return body;
};

const hidePricing = (tire, quantity) => {

  //console.log('hidePricing');
  //console.log(tire);
  //console.log(quantity);

  return tire && tire.partPricing.primaryPart.price[quantity].contextList[0].marketMinimum && !isUserLoggedIn(true) && applicationStore.data.dealerEnableCustomerLogin;
};

const getComparisonProductGrandTotalRow = (dictionary, comparisonDataList, comparisonList) => {

  var rowContent = [ getComparisonTableTitleCell(14, dictionary.comparison.total) ]; // Title Column

  for (var i in comparisonDataList) {
    var contextList = comparisonDataList[i].quantity, quantity = '';
    for (var j in contextList) {
      contextList = contextList[j].contextList[0];
      quantity = j;
    }

    //console.log('getComparisonProductGrandTotalRow');
    ///console.log(contextList);

    rowContent.push(
      {
        text: !hidePricing(getTireFromComparisonList(comparisonDataList, comparisonList, i), quantity) ? money_format(contextList.grandTotal) : "",
        color: '#FFFFFF',
        margin: [ 0, 3, 0, 1 ],
        alignment: 'left',
        fontSize: 11
      }
    );
  }

  return rowContent;

};

const getComparisonProductTaxRow = (dictionary, comparisonDataList, comparisonList) => {

  var taxPercentage = '', temp;
  if (comparisonDataList && sizeOf(comparisonDataList) > 0) {
    temp = comparisonDataList[0].quantity;
    for (var i in temp) {
      if (isEmpty(taxPercentage)) {
        taxPercentage = temp[i].contextList[0].taxPercent;
      }
    }
  }

  var rowContent = [ getComparisonTableTitleCell(13, dictionary.comparison.tax + " (" + taxPercentage + "%)") ]; // Title Column

  for (var i in comparisonDataList) {
    var contextList = comparisonDataList[i].quantity, quantity = '';
    for (var j in contextList) {
      contextList = contextList[j].contextList[0];
      quantity = j;
    }

    rowContent.push(
      {
        text: !hidePricing(getTireFromComparisonList(comparisonDataList, comparisonList, i), quantity) ? money_format(contextList.taxTotal) : "",
        margin: [ 0, 3, 0, 1 ],
        alignment: 'left',
        fontSize: 10
      }
    );
  }

  return rowContent;

};

const getComparisonProductSubtotalRow = (dictionary, comparisonDataList, comparisonList) => {

  var rowContent = [ getComparisonTableTitleCell(12, dictionary.comparison.subtotal) ]; // Title Column

  for (var i in comparisonDataList) {
    var contextList = comparisonDataList[i].quantity, quantity = '';
    for (var j in contextList) {
      contextList = contextList[j].contextList[0];
      quantity = j;
    }

    rowContent.push(
      {
        text: !hidePricing(getTireFromComparisonList(comparisonDataList, comparisonList, i), quantity) ? money_format(contextList.subTotal) : "",
        margin: [ 0, 3, 0, 1 ],
        alignment: 'left',
        fontSize: 10
      }
    );
  }

  return rowContent;

};

const getComparisonProductAdditionalCostsRow = (dictionary, comparisonDataList, comparisonList) => {

  //console.log('getComparisonProductAdditionalCostsRow');
  //console.log(comparisonDataList);

  var rowContent = [ getComparisonTableTitleCell(11, dictionary.comparison.additionalCosts) ]; // Title Column

  for (var i in comparisonDataList) {
    var contextList = comparisonDataList[i].quantity, quantity = '';
    for (var j in contextList) {
      contextList = contextList[j].contextList[0];
      quantity = j;
    }

    //console.log('check: '+(sizeOf(contextList.lineItemList) > 0 && !hidePricing(getTireFromComparisonList(comparisonDataList, comparisonList, i), quantity)));

    if (sizeOf(contextList.lineItemList) > 0 && !hidePricing(getTireFromComparisonList(comparisonDataList, comparisonList, i), quantity)) {

      rowContent.push({
        style: {
          margin: [ 0, 3, 0, 1 ]
        },
        table: {

          widths: [ '*', 'auto' ],
          body: getComparisonAdditionalCostsItemRow(dictionary, contextList.lineItemList),

        },
        layout: {
          hLineWidth: function () { return 0; },
          vLineWidth: function () { return 0; },
        }
      });

    } else {

      rowContent.push([]);

    }

  }

  return rowContent;

};

const getComparisonAdditionalCostsItemRow = (dictionary, lineItemList) => {

  var rowContent = [];

  for (var i in lineItemList) {

    rowContent.push(
      [
        {
          text: parseFloat(lineItemList[i].qty) > 1 ? lineItemList[i].qty + " - " + lineItemList[i].title[getAppLanguage()] : lineItemList[i].title[getAppLanguage()],
          margin: [ 0, 3, 0, 1 ],
          alignment: 'left',
          fontSize: 7.5
        },
        {
          text: parseFloat(lineItemList[i].price) > 0 ? money_format(lineItemList[i].price) : dictionary.comparison.noCost,
          margin: [ 0, 3, 0, 1 ],
          alignment: 'right',
          fontSize: 7.5
        }
      ]
    );

  }

  return rowContent;

};

const getComparisonProductPromotionRow = (dictionary, comparisonDataList, comparisonList) => {

  //console.log('getComparisonProductPromotionRow');
  //console.log(comparisonDataList);
  //console.log(comparisonList);

  var rowContent = [ getComparisonTableTitleCell(10, dictionary.comparison.promotion) ]; // Title Column

  for (var i in comparisonDataList) {
    var contextList = comparisonDataList[i].quantity, quantity = '';
    for (var j in contextList) {
      contextList = contextList[j].contextList[0];
      quantity = j;
    }

    var tire = getTireFromComparisonList(comparisonDataList, comparisonList, i);

    var promotionText = '', tempList = tire.partPricing.primaryPart.price[quantity].contextList;

   //console.log(tempList);

    for (var i in tempList) {
      if (tempList[i] && contextList.pricingContextId == tempList[i].pricingContextId) {
        promotionText = tempList[i].promotionPricingText[getAppLanguage()];
        break;
      }
    }

    /*
    var tire = getTireFromComparisonList(comparisonDataList, comparisonList, i);

    var price = getPricingFromQuantityAndDetailsRefactored(tire.get('frontTire').get('pricingDetails'), tire.get('frontTire').get('quantity'));

    var promotionPricingText = price['promotionPricingText'];

    var promotionText = "";
    if (typeof(promotionPricingText) === 'object') promotionText = promotionPricingText.get(getAppLanguage());
    */

    rowContent.push(
      {
        text: !hidePricing(tire, quantity) ? promotionText : "",
        margin: [ 0, 3, 0, 1 ],
        alignment: 'left',
        fontSize: 9
      }
    );

  }

  return rowContent;

};

const getComparisonPricingDetailsRow = (dictionary, comparisonDataList, comparisonList) => {

  //console.log('getComparisonPricingDetailsRow');
  //console.log(comparisonDataList);

  var rowContent = [ getComparisonTableTitleCell(9, dictionary.comparison.pricingDetails) ]; // Title Column

  for (var i in comparisonDataList) {

    //console.log(comparisonDataList[i]);

    var contextList = comparisonDataList[i].quantity, quantity = '';
    for (var j in contextList) {
      contextList = contextList[j].contextList[0];
      quantity = j;
    }

    //console.log(contextList);

    var tire = getTireFromComparisonList(comparisonDataList, comparisonList, i);

    if (!hidePricing(tire, quantity)) {
      rowContent.push(
        {
          style: {
            margin: [ 0, 3, 0, 1 ],
          },
          table: {

            widths: [ '*', 'auto' ],
            body: getComparisonPricingDetailTableBody(dictionary, tire, quantity, contextList)

          },
          layout: {
            hLineWidth: function () { return 0; },
            vLineWidth: function () { return 0; },
          }
        }
      );
    } else {

      rowContent.push([]);

    }

  }

  return rowContent;

};

const getTireFromComparisonList = (comparisonDataList, comparisonList, index) => {
  var tire = null;

  //console.log('getTireFromComparisonList, index: '+index);
  //console.log(comparisonDataList);
  //console.log(comparisonList);

  for (var i in comparisonList) {

    //console.log('id: '+comparisonDataList[i].id+', itemId: '+comparisonList[i].partDetails.primaryPart.itemId+', i: '+i+', index: '+index);
    //console.log('check: '+(comparisonList[i] && comparisonDataList[i].id == comparisonList[i].partDetails.primaryPart.itemId && i == index));

    if (comparisonList[i] && comparisonDataList[i].id == comparisonList[i].partDetails.primaryPart.itemId && i == index) {
      tire = comparisonList[i];
      break;
    }
  }

  //console.log(tire);

  return tire;
};

const getComparisonPricingDetailTableBody = (dictionary, tire, quantity, contextList) => {
  var body = [], price, savings = 0, hasPromotion = parseInt(contextList.pricingContextId) > 0, showMSRP = applicationStore.data.dealerShowMsrp, tempList = tire.partPricing.primaryPart.price[quantity].contextList;

  for (var i in tempList) {
    if (tempList[i] && contextList.pricingContextId == tempList[i].pricingContextId) {
      price = parseFloat(tempList[i].displayPrice);
      savings = parseFloat(tempList[i].displaySavings) * parseInt(quantity);
      if (showMSRP && !hasPromotion && parseFloat(tempList[i].listPrice) > price) {
        savings = (parseFloat(tempList[i].listPrice) * parseInt(quantity)) - (price * parseInt(quantity));
        body.push(getComparisonPricingDetailsPartMSRP(dictionary, tempList[i].listPrice));
      } else {
        showMSRP = false;
      }
      break;
    }
  }

  if (!hasPromotion) body.push(getComparisonPricingDetailsPartPrice(dictionary, price));
  body.push(getComparisonPricingDetailsPartQuantity(dictionary, quantity));

  if (savings > 0 && showMSRP) {
    body.push(getComparisonPricingDetailsPartSavings(dictionary, savings));
  }

  body.push(getComparisonPricingDetailsPartTotal(dictionary, contextList));

  return body;
};

const getComparisonPricingDetailsPartSavings = (dictionary, savings) => {
  return (
    [
      {
        text: dictionary.product.totalSavings + ":",
        margin: [ 0, 3, 0, 1 ],
        alignment: 'left',
        fontSize: 8.5
      },
      {
        text: money_format(savings),
        margin: [ 0, 3, 0, 1 ],
        alignment: 'right',
        fontSize: 8.5
      }
    ]
  );
};

const getComparisonPricingDetailsPartMSRP = (dictionary, listPrice) => {
  return (
    [
      {
        text: dictionary.product.msrp + ":",
        margin: [ 0, 3, 0, 1 ],
        alignment: 'left',
        fontSize: 8.5
      },
      {
        text: money_format(listPrice),
        margin: [ 0, 3, 0, 1 ],
        alignment: 'right',
        fontSize: 8.5
      }
    ]
  );
};

const getComparisonPricingDetailsPartTotal = (dictionary, contextList) => {
  return (
    [
      {
        text: dictionary.product.total + ":",
        margin: [ 0, 3, 0, 1 ],
        alignment: 'left',
        fontSize: 8.5
      },
      {
        text: money_format(contextList.itemTotal),
        margin: [ 0, 3, 0, 1 ],
        alignment: 'right',
        fontSize: 8.5
      }
    ]
  );
};

const getComparisonPricingDetailsPartQuantity = (dictionary, quantity) => {
  return (
    [
      {
        text: dictionary.product.quantity + ":",
        margin: [ 0, 3, 0, 1 ],
        alignment: 'left',
        fontSize: 8.5
      },
      {
        text: quantity,
        margin: [ 0, 3, 0, 1 ],
        alignment: 'right',
        fontSize: 8.5
      }
    ]
  );
};

const getComparisonPricingDetailsPartPrice = (dictionary, displayPrice) => {

  //console.log('getComparisonPricingDetailsPartPrice, displayPrice: '+displayPrice);

  var priceLabel = dictionary.product.retailPrice;
  if (applicationStore.data.dealerSite && applicationStore.data.dealerSellingPriceLabel[ProductTypes.TIRES][getAppLanguage()]) {
    priceLabel = applicationStore.data.dealerSellingPriceLabel[ProductTypes.TIRES][getAppLanguage()];
  }
  return (
    [
      {
        text: priceLabel + ":",
        margin: [ 0, 3, 0, 1 ],
        alignment: 'left',
        fontSize: 8.5
      },
      {
        text: money_format(displayPrice),
        margin: [ 0, 3, 0, 1 ],
        alignment: 'right',
        fontSize: 8.5
      }
    ]
  );
};

const getComparisonProductOtherInformationRow = (dictionary, comparisonDataList) => {

  var rowContent = [ getComparisonTableTitleCell(7, dictionary.comparison.otherInformation) ]; // Title Column

  for (var i in comparisonDataList) {

    rowContent.push(
      {
        text: comparisonDataList[i].originalEquipment ? dictionary.product.approvedTire : "",
        margin: [ 0, 3, 0, 1 ],
        alignment: 'center',
        fontSize: 10
      }
    );

  }

  return rowContent;

};

const getComparisonProductWarrantyRow = (dictionary, comparisonDataList) => {

  var rowContent = [ getComparisonTableTitleCell(6, dictionary.product.warranty) ]; // Title Column
  var appCountry = '';
  if (applicationStore.data && applicationStore.data.dealerInfo && applicationStore.data.dealerInfo.country) {
    appCountry = applicationStore.data.dealerInfo.country;
  }

  for (var i in comparisonDataList) {

    var warranty = comparisonDataList[i].warranty, selectedWarranty = '';
    for (var j in warranty) {
      if (warranty[j].warrantyCountryCode.toUpperCase() == appCountry.toUpperCase() && warranty[j].warrantyLanguageCode.toLowerCase() == getAppLanguage()) {
        selectedWarranty = warranty[j].warrantyText
      }
    }

    rowContent.push(
      {
        text: selectedWarranty,
        margin: [ 0, 3, 0, 1 ],
        alignment: 'left',
        fontSize: 7.5
      }
    );

  }

  return rowContent;

};

const getComparisonProductDescriptionRow = (dictionary, comparisonDataList) => {

  var rowContent = [ getComparisonTableTitleCell(5, dictionary.product.description) ]; // Title Column

  for (var i in comparisonDataList) {

    rowContent.push(
      {
        text: comparisonDataList[i].description[getAppLanguage()],
        margin: [ 0, 3, 0, 1 ],
        alignment: 'left',
        fontSize: 7.5
      }
    );

  }

  return rowContent;

};

const getComparisonProductSeasonRow = (dictionary, comparisonDataList) => {

  var rowContent = [ getComparisonTableTitleCell(4, dictionary.product.season) ]; // Title Column

  for (var i in comparisonDataList) {

    var seasonText = '';
    switch(parseInt(comparisonDataList[i].season)) {
      case 0:
        seasonText = dictionary.product.filters.season.winter;
        break;
      case 1:
        seasonText = dictionary.product.filters.season.allSeason;
        break;
      case 2:
        seasonText = dictionary.product.filters.season.summer;
        break;
    }

    rowContent.push(
      {
        text: seasonText,
        margin: [ 0, 3, 0, 1 ],
        alignment: 'center',
        fontSize: 10
      }
    );

  }

  return rowContent;

};

const getComparisonProductDetailsRow = (dictionary, comparisonDataList) => {

  var rowContent = [ getComparisonTableTitleCell(3, dictionary.comparison.productDetails) ]; // Title Column

  for (var i in comparisonDataList) {

    var partNumberRow = '';
    if (comparisonDataList[i].nationalPartNumber !== '') {
      partNumberRow = dictionary.product.nationalPartNumber + ": " + comparisonDataList[i].nationalPartNumber;
    } else if (comparisonDataList[i].partNumber !== '') {
      partNumberRow = dictionary.product.partNumber + ": " + comparisonDataList[i].partNumber;
    }

    rowContent.push(
      {
        stack: [
          {
            text: partNumberRow,
            margin: [ 0, 3, 0, 1 ],
            alignment: 'left',
            fontSize: 9
          },
          {
            text: dictionary.product.tireSize + ": " + comparisonDataList[i].tiresize,
            margin: [ 0, 3, 0, 1 ],
            alignment: 'left',
            fontSize: 9
          },
          {
            text: dictionary.product.loadSpeedPly + ": " + comparisonDataList[i].load + " / " + comparisonDataList[i].speed + " / " + comparisonDataList[i].ply,
            margin: [ 0, 3, 0, 1 ],
            alignment: 'left',
            fontSize: 9
          }
        ]
      }
    );

  }

  return rowContent;

};

const getComparisonTitleRow = (row, dictionary, comparisonDataList) => {
  var rowTitle = '', columnSpan = parseInt(sizeOf(comparisonDataList) + 1);
  switch(row) {
    case 1:
      rowTitle = dictionary.comparison.productInformation;
      break;
    case 2:
      rowTitle = dictionary.comparison.pricingInformation;
      break;
  }

  return (
    [
      {
        fontSize: 14,
        colSpan: columnSpan,
        margin: [ 0, 2.5, 0, 2.5 ],
        text: rowTitle
      }
    ]
  );
};

const getComparisonImageRow = (hasCustomLogo, encodedImageList, comparisonDataList) => {

  //console.log('getComparisonImageRow');
  //console.log(encodedImageList);
  //console.log(comparisonDataList);

  var rowContent = [ getComparisonTableTitleCell(1, '') ]; // Title Column

  for (var i in comparisonDataList) {

    rowContent.push(
      {
        stack: [
          {
            text: comparisonDataList[i].name,
            margin: [ 0, 3, 0, 1 ],
            height: 125,
            alignment: 'center',
            fontSize: 14
          },
          {
            image: encodedImageList[comparisonDataList[i].id],
            margin: [ 0, 3, 0, 0 ],
            width: 110,
            alignment: 'center'
          },
          {
            image: encodedImageList[comparisonDataList[i].manufacturerId],
            margin: [ 0, 0, 0, 5 ],
            width: 110,
            alignment: 'center'
          }
        ]
      }
    );

  }

  //(rowContent);

  return rowContent;

};

const getComparisonTableTitleCell = (row, text = '') => {
  if (row === 1) { // First Row
    return (
      {
        border: [ false, false, false, false ],
        fillColor: '#FFFFFF',
        text: text
      }
    );
  } else if (row === 14) { // Last Row
    return (
      {
        margin: [ 1, 3.5, 1, 3.5 ],
        color: '#FFFFFF',
        fontSize: 11,
        text: text
      }
    );
  } else {
    return (
      {
        margin: [ 1, 3.5, 1, 3.5 ],
        fontSize: 11,
        text: text
      }
    );
  }
};

var React = require("react");
var Cx = require("classnames");
var _ = require("lodash");

import { I18nComponent } from "../../../i18n/I18nComponent.jsx";
import { get_browser } from "../../../service/UtilityService.js";

export const CONTACT_EMAIL = "email";
export const CONTACT_PHONE = "phone";

export class FieldPrototype extends I18nComponent {
  static defaultProps = {
    onChange: function () {},
    onBlur: function () {},
    id: null,
    label: null,
    touched: false,
    className: "",
    datadtm: "",
    specialRequirementField: false,
  };

  state = {
    value: this.props.value,
    showError: false,
    touched: false,
    pristine: true,
    valid: true,
    validationMessages: [],
  };

  setInvalid = () => {
    var validationResult = this.isValid("");
    this.setState({
      valid: false,
      invalid: true,
      touched: true,
      showError: true,
      validationMessages: validationResult,
    });
  };

  render() {
    var wrapperClass = Cx({
      formInput: true,
      passwordInput:
        this.props.id === "passwordField" ||
        this.props.id === "passwordFieldLogin",
      touched: this.state.touched,
      untouched: !this.state.touched,
      valid: this.state.valid,
      invalid: !this.state.valid,
      pristine: this.state.pristine,
      dirty: !this.state.pristine,
    });

    var showError = true;
    if (this.props.id === "userEmailField") {
      if (this.state.valid && this.state.pristine) showError = false;
    }

    return (
      <div className={wrapperClass}>
        <label htmlFor={this.props.id}>{this.props.label}</label>
        {this.renderInput()}
        {this.props.specialRequirementField && !this.state.valid ? (
          <div className="special-requirement-error">
            <ul>
              <li key={this.t("validation.email")}>
                {this.t("validation.email")}
              </li>
            </ul>
          </div>
        ) : (
          false
        )}
        {!this.props.specialRequirementField && showError ? (
          <div className="error">
            {_.map(this.state.validationMessages, function (message) {
              return <li key={message}>{message}</li>;
            })}
          </div>
        ) : (
          false
        )}
      </div>
    );
  }

  renderInput = () => {
    var className = "";
    if (
      this.props.id == "passwordField-unq" ||
      this.props.id == "passwordConfirmField"
    )
      className = " password-field";
    if (this.props.className)
      className =
        (className === "" ? "" : className + " ") + this.props.className;
    if (this.renderCustomInput) {
      return this.renderCustomInput();
    } else {
      var validationResult = this.isValid(this.state.value);
      var classNameFull =
        validationResult.length === 0 ? "valid" + " " + className : className;
      var browser = get_browser();
      if (this.props.rows) {
        if (browser.name === "IE" || browser.name === "MSIE")
          return (
            <textarea
              id={this.props.id}
              className={classNameFull}
              ref={this.props.ref}
              type={this.getFieldType()}
              onChange={this.onChangeExplorer}
              value={this.props.value}
              placeholder={this.props.placeholder}
              rows={this.props.rows}
            />
          );
        else
          return (
            <textarea
              id={this.props.id}
              className={classNameFull}
              ref={this.props.ref}
              type={this.getFieldType()}
              onBlur={this.onBlur}
              onChange={this.onChange}
              value={this.props.value}
              placeholder={this.props.placeholder}
              rows={this.props.rows}
            />
          );
      } else {
        if (browser.name === "IE" || browser.name === "MSIE")
          return (
            <input
              id={this.props.id}
              className={classNameFull}
              ref={this.props.ref}
              type={this.getFieldType()}
              onChange={this.onChangeExplorer}
              value={this.props.value}
              placeholder={this.props.placeholder}
              data-dtm={this.props.datadtm}
            />
          );
        else
          return (
            <input
              id={this.props.id}
              className={classNameFull}
              ref={this.props.ref}
              type={this.getFieldType()}
              onBlur={this.onBlur}
              onChange={this.onChange}
              value={this.props.value}
              placeholder={this.props.placeholder}
              data-dtm={this.props.datadtm}
            />
          );
      }
    }
  };

  onChange = (ev) => {
    var value = ev.target.value;
    if (this.props.id !== "userEmailField") {
      var validationResult = this.isValid(value);
      this.setState({
        value: validationResult.length === 0 ? value : null,
        valid: validationResult.length === 0,
        invalid: !(validationResult.length === 0),
        showError: validationResult.length === 0 ? false : true,
        validationMessages: validationResult,
      });
      this.props.onChange(value, validationResult.length === 0);
    } else {
      var validationResult = this.hasValue(value);
      this.setState({
        value: validationResult.length === 0 ? value : null,
        valid: validationResult.length === 0,
        validationMessages: validationResult,
      });
      this.props.onChange(value, true);
    }
    ev.preventDefault();
    ev.stopPropagation();
  };

  onChangeExplorer = (ev) => {
    var value = ev.target.value;
    if (this.props.id !== "userEmailField") {
      var validationResult = this.isValid(value);
      this.setState({
        value: validationResult.length === 0 ? value : null,
        valid: validationResult.length === 0,
        invalid: !(validationResult.length === 0),
        showError: validationResult.length === 0 ? false : true,
        validationMessages: validationResult,
      });
      this.props.onChange(value, validationResult.length === 0);
    } else {
      var validationResult = this.hasValue(value);
      this.setState({
        value: validationResult.length === 0 ? value : null,
        valid: validationResult.length === 0,
        validationMessages: validationResult,
      });
      this.props.onChange(value, true);
    }
    //ev.preventDefault();
    //ev.stopPropagation();
  };

  onBlur = (ev) => {
    var value = ev.target.value;
    var pristine = value === "";
    if (this.props.id === "userEmailField" || this.props.required === true) {
      if (!this.state.touched || this.state.pristine) {
        var validationResult = this.isValid(value);
        this.setState({
          value: validationResult.length === 0 ? value : null,
          showError: validationResult.length === 0 ? false : true,
          touched: true,
          pristine: pristine,
          valid: validationResult.length === 0,
          invalid: !(validationResult.length === 0),
          validationMessages: validationResult,
        });
        if (this.props.hasOnBlur === true) {
          this.props.onBlur(value, validationResult.length === 0);
        }
      }
    } else {
      if (!this.state.touched || this.state.pristine) {
        var validationResult = this.isValid(value);
        this.setState({
          value: validationResult.length === 0 ? value : null,
          touched: true,
          pristine: pristine,
        });
      }
    }
    ev.preventDefault();
    ev.stopPropagation();
  };
}

import { DispatcherActions } from '../constants/DispatcherActions.js';
import { Languages } from '../constants/Languages.js';
import { applicationStore } from '../stores/ApplicationStore.js';
import { languageStore } from '../stores/LanguageStore.js';
import { shoppingCartStore } from '../stores/ShoppingCartStore.js';
import { shoppingCartViewStore } from '../stores/ShoppingCartViewStore.js';
import { vehicleSelectionStore } from '../stores/VehicleSelectionStore.js';
import { AbstractAction } from './AbstractAction.js';

import { getDestination } from '../api/RequestBuilderHelper.js';

import { runAdobeServiceFormSubmit } from "../service/AdobeLaunchService";
import { getOrderIdentifier } from '../service/OrderService';
import { formatDate } from '../service/CalendarService';
import { getQuoteTrackingLabelInfo, loadWishlistWithContext } from '../service/WishlistService';
import {
  sizeOf,
  isEmpty,
  getAppLanguage,
  getAppDealerId,
  extractQuantity,
  trackRemoveFromCart,
  trackOrder,
  getTrackingEventCategory,
  getTrackingEventOrderId,
  money_format
} from '../service/UtilityService';
import store from "../stores/configureStore";
import {
  newShoppingCart,
  wishListReview,
  addItemToCart,
  updateItemToCart,
  toggleCart
} from "../redux/features/shoppingCart";
import {onComparisonLoadedSuccessfully, renderSavingToggleState} from "../redux/features/productList";

var Immutable = require('immutable');

const COMPLETE_ORDER = "CMP";

const CONTACT_EMAIL = 'email';
const CONTACT_PHONE = 'phone';

const ORDERSTATUS_SCHEDULE = "SCHEDULED";
const ORDERSTATUS_CANCEL = "CANCELLED";
const ORDERSTATUS_COMPLETE = "DELIVERED";

var ShoppingCartActions = AbstractAction.extend({

  toggleCartModal: function() {
    this.dispatch(DispatcherActions.TOGGLE_CART_MODAL, {});
  },

	generateFoxyCartFromCart: function () {
		this.apiPost('/shopping/thirdParty/generateFoxyCartFromCart.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      customerLanguage: getAppLanguage(),
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
			this.dispatch(DispatcherActions.FOXY_CART_GENERATED, response);
			this.loadShoppingCartView();
		}.bind(this));
	},

	loadShoppingCartView: function () {
		this.apiGet('/shopping/cart/getCartContents.php', {
			dealerId: getAppDealerId(),
			cartId: getOrderIdentifier(),
			orderNumber: getOrderIdentifier(),
			markAsQuote: false,
			isNational: applicationStore.data.nationalSite ? 1 : 0
		}).then(function (response) {
			this.dispatch(DispatcherActions.VIEW_SHOPPING_CART_LOADED, response);
		}.bind(this));
	},

	loadWishlistReview: function () {
		this.apiGet('/shopping/cart/getCartContents.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      markAsQuote: true,
      isNational: applicationStore.data.nationalSite ? 1 : 0
		}).then(function (response) {
      response.fromCreatePackage = false;
      store.dispatch(wishListReview(response));
			// this.dispatch(DispatcherActions.WISHLIST_REVIEW_LOADED, response);
		})
      .catch((e)=>{
        console.error(e);
      });
	},

  loadWishlistPrint: function () {
    this.apiGet('/shopping/cart/getCartContents.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      markAsQuote: false,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      this.dispatch(DispatcherActions.WISHLIST_PRINT_LOADED, response);
    }.bind(this));
  },

	loadConsumerOrder: function() {
    googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'Shopping Cart Review - FoxyCartAccessed');
    this.apiGet('/shopping/consumerOrder/generateConsumerOrderFromCart.php', {
    	dealerId: getAppDealerId(),
			orderNumber: getOrderIdentifier(),
			// orderNumber: shoppingCartViewStore.data.get('orderGUID'),
			isNational: applicationStore.data.nationalSite ? 1 : 0
		}).then(function (response) {
      this.dispatch(DispatcherActions.CONSUMER_ORDER_LOADED, response);
    }.bind(this));
	},

  loadDealerOrder: function () {
    const orderNumber = getOrderIdentifier();
    if (!orderNumber){
      return Promise.reject(new Error('Order number is not available'));
    }
    this.apiGet("/shopping/dealerOrder/getDealerOrder.php", {
      dealerId: getAppDealerId(),
      orderNumber,
      isNational: applicationStore.data.nationalSite ? 1 : 0,
    }).then(
      function (response) {
        response.fromCreatePackage = false;
        this.dispatch(DispatcherActions.WISHLIST_REVIEW_LOADED, response);
      }.bind(this)
    );
  },

  loadConsumerEcommerce: function () {
    this.apiGet('/shopping/consumerOrder/getConsumerOrder.php', {
      dealerId: getAppDealerId(),
      orderNumber: getOrderIdentifier(),
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      response.fromCreatePackage = false;
      this.dispatch(DispatcherActions.WISHLIST_REVIEW_LOADED, response);
    }.bind(this));
  },

	scheduleConsumerEcommerce: function (updateState, date, time, notes) {
    this.apiGet('/shopping/consumerOrder/updateConsumerOrderStatus.php', {
      dealerId: getAppDealerId(),
      orderNumber: getOrderIdentifier(),
      status: ORDERSTATUS_SCHEDULE,
      appointmentDate: date ? formatDate(date) : null,
      appointmentTime: time,
      workOrderNumber: null,
      dealerNotes: notes,
      consumerNotes: null,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      response.fromCreatePackage = false;
      if (!!updateState) {
        updateState({
          ecommerceAppointmentCalendar: false
        });
      }
      this.dispatch(DispatcherActions.WISHLIST_REVIEW_LOADED, response);
    }.bind(this));
	},

	cancelConsumerEcommerce: function (reasonForCancelling) {
    this.apiGet('/shopping/consumerOrder/updateConsumerOrderStatus.php', {
      dealerId: getAppDealerId(),
      orderNumber: getOrderIdentifier(),
      status: ORDERSTATUS_CANCEL,
      appointmentDate: null,
      appointmentTime: null,
      workOrderNumber: null,
      dealerNotes: null,
      consumerNotes: reasonForCancelling,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      response.fromCreatePackage = false;
      this.dispatch(DispatcherActions.WISHLIST_REVIEW_LOADED, response);
    }.bind(this));
  },

  completeConsumerEcommerce: function (workOrderNumber) {
    this.apiGet('/shopping/consumerOrder/updateConsumerOrderStatus.php', {
      dealerId: getAppDealerId(),
      orderNumber: getOrderIdentifier(),
      status: ORDERSTATUS_COMPLETE,
      appointmentDate: null,
      appointmentTime: null,
      workOrderNumber: workOrderNumber,
      dealerNotes: null,
      consumerNotes: null,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      response.fromCreatePackage = false;
      this.dispatch(DispatcherActions.WISHLIST_REVIEW_LOADED, response);
    }.bind(this));
  },

  markOrderStatusAsComplete: function() {
    this.apiGet('/shopping/cart/setCartStatus.php', {
      dealerId: getAppDealerId(),
      cartId: applicationStore.data.cart,
      orderNumber: getOrderIdentifier(),
			cartStatus: COMPLETE_ORDER,
      isNational: applicationStore.data.nationalSite ? 1 : 0
		}).then(function () {
			window.location.reload();
		}.bind(this));
	},

	updateItemFromCartView: function (itemId, cartItemId, optionId, itemQty, vehicleId, pricingContextId, product) {
		this.apiPost('/shopping/cart/updateItemInCart.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      cartItemId: itemId,
      itemQty: itemQty,
      pricingContextId: pricingContextId,
      optionId: optionId,
      vehicleId: vehicleId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      response.skipRedirect = true;
      response.fromCreatePackage = false;
      this.dispatch(DispatcherActions.WISHLIST_REVIEW_LOADED, response);
			this.generateFoxyCartFromCart();
		}.bind(this));
	},

	removeItemFromCartView: function (cartItemId) {
		this.apiPost('/shopping/cart/removeItemFromCart.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      cartItemId: cartItemId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      response.skipRedirect = true;
      response.fromCreatePackage = false;
      this.dispatch(DispatcherActions.WISHLIST_REVIEW_LOADED, response);
			this.generateFoxyCartFromCart();
      // this will item remove from the redux store
      store.dispatch(updateItemToCart(response));

		}.bind(this));
	},

  updateCartItem: function (cartItemId, itemQty, pricingContextId, optionId, vehicleId) {
    this.apiPost('/shopping/cart/updateItemInCart.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      cartItemId: cartItemId,
      itemQty: itemQty,
      pricingContextId: pricingContextId,
      optionId: optionId,
      vehicleId: vehicleId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      response.skipRedirect = true;
      response.fromCreatePackage = false;
      this.dispatch(DispatcherActions.WISHLIST_REVIEW_LOADED, response);
    }.bind(this));
  },

	updateItemFromWishlist: function (itemId, cartItemId, optionId, itemQty, vehicleId, pricingContextId, product, dontLaunchCart = false) {
    store.dispatch(renderSavingToggleState(false));
		this.apiPost('/shopping/cart/updateItemInCart.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      cartItemId: cartItemId,
      itemQty: extractQuantity(itemQty),
      pricingContextId: pricingContextId,
      optionId: optionId,
      vehicleId: vehicleId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      response.skipRedirect = true;
      response.fromCreatePackage = false;
      store.dispatch(wishListReview(response));
      // this.dispatch(DispatcherActions.WISHLIST_REVIEW_LOADED, response);
      store.dispatch(renderSavingToggleState(true));
		}.bind(this));
	},

	removeItemFromWishlistReview: function (cartItemId, itemId, loadWishlist) {
		this.apiPost('/shopping/cart/removeItemFromCart.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      cartItemId: cartItemId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      response.skipRedirect = true;
      response.fromCreatePackage = false;
      this.dispatch(DispatcherActions.WISHLIST_REVIEW_LOADED, response);
      // this will item remove from the redux store
      store.dispatch(updateItemToCart(response));
			if (loadWishlist) loadWishlistWithContext();
		}.bind(this));
	},

	saveSessionReference: function (sessionReference) {
		this.apiPost('/shopping/cart/addReferenceNumberToCart.php', {
			dealerId: getAppDealerId(),
			cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
			referenceName: 'sessionReference',
			referenceValue: sessionReference,
      isNational: applicationStore.data.nationalSite ? 1 : 0
		}).then(function (response) {
			if (response) this.dispatch(DispatcherActions.SESSION_REFERENCE_SAVED, {});
			else this.dispatch(DispatcherActions.SESSION_REFERENCE_FAILURE, {});
		}.bind(this));
	},

	saveWishlist: function (name, email, phone, date, time, note, contactType) {
		this.apiPost('/shopping/cart/saveCart.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      customerName: name,
      customerEmail: email,
      customerPhone: phone,
      appointmentRequestDate: date ? formatDate(date) : null,
      appointmentRequestTime: time,
      notes: note,
      customerContactMethodPreference: contactType === CONTACT_EMAIL ? 0 : 1,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
			if (response) this.dispatch(DispatcherActions.WISHLIST_SAVE_SUCCESSFUL, {});
			else this.dispatch(DispatcherActions.WISHLIST_SAVE_FAILURE, {});
		}.bind(this));
	},

	saveOrderCustomerInfo: function (name, email, phone, date, time, note, contactType) {
		this.apiPost('/shopping/cart/saveCart.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      customerName: name,
      customerEmail: email,
      customerPhone: phone,
      appointmentRequestDate: date ? formatDate(date) : null,
      appointmentRequestTime: time,
      notes: note,
      customerContactMethodPreference: contactType === CONTACT_EMAIL ? 0 : 1,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function () {
			this.dispatch(DispatcherActions.NTD_ORDER_CUSTOMER_INFO_SAVED, {});
		}.bind(this));
	},

	emailQuoteFromWishlist: function (name, email, phone, note) {
    //this.apiPost('/shopping/cart/sendCart.php', {
    this.apiGet('/shopping/lead/createQuoteContactLead.php', {
      targetDealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      customerName: name,
      customerEmail: email,
      customerLanguage: getAppLanguage(),
      customerPhone: phone,
      notes: note,
      generatedBy: "email",
      customerContactMethodPreference: null,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      if (response && !!response.success) this.dispatch(DispatcherActions.WISHLIST_EMAIL_SENT, {});
      else this.dispatch(DispatcherActions.WISHLIST_EMAIL_FAILED, {});
		}.bind(this));
	},

	requestAppointmentFromWishlist: function (firstName, lastName, email, phone, date, time, note, contactType, runformsubmit = '') {
		//this.apiPost('/shopping/cart/sendCart.php', {
    this.apiPost('/shopping/lead/createQuoteAppointmentLead.php', {
      targetDealerId: getAppDealerId(),
      orderNumber: getOrderIdentifier(),
      customerFirstName: firstName,
      customerLastName: lastName,
      customerEmail: email,
      customerPhone: phone,
      customerMessage: note,
      customerLanguage: getAppLanguage(),
      appointmentRequestDate:  date ? formatDate(date) : null,
      appointmentRequestTime: time,
      sendCustomerEmail: true,
      customerContactMethodPreference: contactType === CONTACT_EMAIL ? 0 : 1,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      if (response && !!response.success) {
        runAdobeServiceFormSubmit(runformsubmit);
        this.dispatch(DispatcherActions.WISHLIST_APPOINTMENT_SENT, {});
      } else {
        this.dispatch(DispatcherActions.WISHLIST_APPOINTMENT_FAILED, {});
      }
		}.bind(this));
	},

	transferQuoteToEmail: function (name, email, phone, note) {
		//this.apiPost('/shopping/cart/sendCart.php', {
    this.apiGet('/shopping/lead/createQuoteContactLead.php', {
      targetDealerId: getAppDealerId(),
      orderNumber: getOrderIdentifier(),
      customerFirstName: name,
      customerEmail: email,
      customerLanguage: getAppLanguage(),
      customerPhone: phone,
      notes: note,
      customerMessage: note,
      customerContactMethodPreference: null,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      if (response && !!response.success) this.dispatch(DispatcherActions.WISHLIST_EMAIL_SENT, {});
      else this.dispatch(DispatcherActions.WISHLIST_EMAIL_FAILED, {});
		}.bind(this));
	},

	transferQuoteToAppointment: function (dealerId, name, email, phone, date, time, note, contactType, runformsubmit = '') {
		this.apiPost('/shopping/lead/createQuoteAppointmentLead.php', {
      targetDealerId: dealerId,
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      customerFirstName: name,
      customerLastName: "",
      customerEmail: email,
      customerLanguage: getAppLanguage(),
      customerPhone: phone,
      appointmentRequestDate: date ? formatDate(date) : null,
      appointmentRequestTime: time,
      notes: note,
      customerContactMethodPreference: contactType === CONTACT_EMAIL ? 0 : 1,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
			if (response) {
        runAdobeServiceFormSubmit(runformsubmit);
			  this.dispatch(DispatcherActions.WISHLIST_APPOINTMENT_SENT, {});
      } else {
			  this.dispatch(DispatcherActions.WISHLIST_APPOINTMENT_FAILED, {});
      }
		}.bind(this));
	},

	addOptionalLineItemFromWishlist: function (itemId, lineItemId, lineItemPrice, lineItemWorkOrderNumber, lineItemNotes) {
    this.apiPost('/shopping/cart/addLineItemToCart.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      cartItemId: itemId,
      lineItemId: lineItemId,
      lineItemPrice: lineItemPrice,
      lineItemWorkOrderNumber: lineItemWorkOrderNumber,
      lineItemNotes: lineItemNotes,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      response.fromCreatePackage = false;
			// this.dispatch(DispatcherActions.WISHLIST_REVIEW_LOADED, response);
      store.dispatch(wishListReview(response));
		}.bind(this));
	},

	removeOptionalLineItemFromWishlist: function (itemId, lineItemId) {
		this.apiPost('/shopping/cart/removeLineItemFromCart.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      cartItemId: itemId,
      lineItemId: lineItemId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      response.fromCreatePackage = false;
      store.dispatch(wishListReview(response));
			// this.dispatch(DispatcherActions.WISHLIST_REVIEW_LOADED, response);
		}.bind(this));
	},

	callAddItemToCart: function (id, name, image, displayPrice, privatePrice, promotionText, quantity, type, product, optionId, pricingContextId, requiredItems, displayLabour, displayMsrp, isDealerOrder = false, isConsumerOrder = false, source = '', fromCreatePackage = false, openFinancing = false) {
    return this.apiPost('/shopping/cart/addItemToCart.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      itemId: id,
      itemType: getDestination(type),
      itemQty: quantity,
      pricingContextId: pricingContextId,
      optionId: optionId,
      vehicleId: applicationStore.data.search ? 0 : vehicleSelectionStore.data.vehicleId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {

      var total = 'N/A';
      if (response && response.orderGrandTotal) {
        total = response.orderGrandTotal;
      }

      googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'add to quote', getQuoteTrackingLabelInfo('request quote', response.orderItemList), total);

      response.skipRedirect = true;
      response.addedToCart = true;
      response.fromCreatePackage = fromCreatePackage;
      response.openFinancing = openFinancing;

      store.dispatch(wishListReview(response));
      store.dispatch(addItemToCart(response));
      store.dispatch( onComparisonLoadedSuccessfully(false));



      // this.dispatch(DispatcherActions.WISHLIST_REVIEW_LOADED, response);
    }.bind(this));
  },

	callAddItemToCartForStaggered: function (id, name, image, displayPrice, privatePrice, promotionText, quantity, type, product, optionId, pricingContextId, requiredItems, displayLabour, displayMsrp, rearFunc, source) {
		return this.apiPost('/shopping/cart/addItemToCart.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      itemId: id,
      itemType: getDestination(type),
      itemQty: quantity,
      pricingContextId: pricingContextId,
      optionId: optionId,
      vehicleId: applicationStore.data.search ? 0 : vehicleSelectionStore.data.vehicleId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {

      var total = 'N/A';
      if (response && response.orderGrandTotal) {
        total = response.orderGrandTotal;
      }

      googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'add to quote', getQuoteTrackingLabelInfo('request quote', response.orderItemList), total);

      response.skipRedirect = true;
      response.addedToCart = true;
      response.fromCreatePackage = false;
      this.dispatch(DispatcherActions.WISHLIST_REVIEW_LOADED, response);
			if (rearFunc !== null) rearFunc();
		}.bind(this));
	},

  callAddAllToCart: function (id, name, image, displayPrice, privatePrice, promotionText, quantity, type, product, optionId, pricingContextId, requiredItems, displayLabour, displayMsrp, requiredItemIdArray = [], requiredOnly = false, isDealerOrder = false, isConsumerOrder = false, source = '') {
	  return this.apiPost('/shopping/cart/addAllToCart.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      itemId: id,
      itemType: getDestination(type),
      itemQty: quantity,
      pricingContextId: pricingContextId,
      optionId: optionId,
      requiredItemId: requiredItemIdArray,
      requiredOnly: requiredOnly ? 1 : 0,
      vehicleId: applicationStore.data.search ? 0 : vehicleSelectionStore.data.vehicleId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {

      var total = 'N/A';
      if (response && response.orderGrandTotal) {
        total = response.orderGrandTotal;
      }

      googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'add to quote', getQuoteTrackingLabelInfo('request quote', response.orderItemList), total);

      response.skipRedirect = true;
      response.addedToCart = true;
      response.fromCreatePackage = false;
      this.dispatch(DispatcherActions.WISHLIST_REVIEW_LOADED, response);
    }.bind(this));
  },

  addAllToShoppingCart: function (id, name, image, displayPrice, privatePrice, promotionText, quantity, type, product, option, pricingContextId, requiredItems, displayLabour, displayMsrp, requiredItemIdArray, requiredOnly, isDealerOrder = false, isConsumerOrder = false, source = '') {
    if (!shoppingCartStore.hasCart()) {
      localStorage.removeItem('orderNumber'); // ensure that the orderNumber is cleared
      this.apiGet('/shopping/cart/getNewCart.php', {
        dealerId: getAppDealerId(),
        isNational: applicationStore.data.nationalSite ? 1 : 0
      }).then(function (response) {
        localStorage.setItem('orderNumber', response.payload.orderNumber);
        this.dispatch(DispatcherActions.SHOPPING_CART_GET_NEW_CART, response);
        this.callAddAllToCart(id, name, image, displayPrice, privatePrice, promotionText, quantity, type, product, option, pricingContextId, requiredItems, displayLabour, displayMsrp, requiredItemIdArray, requiredOnly, isDealerOrder === true, isConsumerOrder === true, source);
      }.bind(this));
    } else {
      this.callAddAllToCart(id, name, image, displayPrice, privatePrice, promotionText, quantity, type, product, option, pricingContextId, requiredItems, displayLabour, displayMsrp, requiredItemIdArray, requiredOnly, isDealerOrder === true, isConsumerOrder === true, source);
    }
  },

	addStaggeredItemToShoppingCart: function (frontId, rearId, name, image, frontPricing, rearPricing, frontQuantity, rearQuantity, type, product, option, frontPricingContextId, rearPricingContextId, requiredItems, displayMsrpFront, displayMsrpRear, source = '') {
		if (rearQuantity !== 0 && frontQuantity !== 0) {
      if (!shoppingCartStore.hasCart()) {
        localStorage.removeItem('orderNumber'); // ensure that the orderNumber is cleared
        this.apiGet('/shopping/cart/getNewCart.php', {
          dealerId: getAppDealerId(),
          isNational: applicationStore.data.nationalSite ? 1 : 0
        }).then(function (response) {
          localStorage.setItem('orderNumber', response.payload.orderNumber);
          this.dispatch(DispatcherActions.SHOPPING_CART_GET_NEW_CART, response);
          var rearFunc = null;
          if (frontQuantity > 0) {
            if (rearQuantity > 0) {
              rearFunc = this.callAddItemToCart.bind(this, rearId, this.getName(false, name), image, rearPricing.get('displayPrice'), rearPricing.get('privatePrice'), rearPricing.get('promotionText'), rearQuantity, type, product, option, rearPricingContextId, requiredItems, 0, displayMsrpRear);
            }
            this.callAddItemToCartForStaggered(frontId, this.getName(true, name), image, frontPricing.get('displayPrice'), frontPricing.get('privatePrice'), frontPricing.get('promotionText'), frontQuantity, type, product, option, frontPricingContextId, requiredItems, 0, displayMsrpFront, rearFunc, source);
          } else {
            this.callAddItemToCart(rearId, this.getName(false, name), image, rearPricing.get('displayPrice'), rearPricing.get('privatePrice'), rearPricing.get('promotionText'), rearQuantity, type, product, option, rearPricingContextId, requiredItems, 0, displayMsrpRear);
          }
        }.bind(this));
      } else {
        var rearFunc = null;
        if (frontQuantity > 0) {
          if (rearQuantity > 0) {
            rearFunc = this.callAddItemToCart.bind(this, rearId, this.getName(false, name), image, rearPricing.get('displayPrice'), rearPricing.get('privatePrice'), rearPricing.get('promotionText'), rearQuantity, type, product, option, rearPricingContextId, requiredItems, 0, displayMsrpRear);
          }
          this.callAddItemToCartForStaggered(frontId, this.getName(true, name), image, frontPricing.get('displayPrice'), frontPricing.get('privatePrice'), frontPricing.get('promotionText'), frontQuantity, type, product, option, frontPricingContextId, requiredItems, 0, displayMsrpFront, rearFunc, source);
        } else {
          this.callAddItemToCart(rearId, this.getName(false, name), image, rearPricing.get('displayPrice'), rearPricing.get('privatePrice'), rearPricing.get('promotionText'), rearQuantity, type, product, option, rearPricingContextId, requiredItems, 0, displayMsrpRear);
        }
      }

		}
	},

  getName: function (front, name) {
    var prefixEnFront = languageStore.getDictionaryTranslate('frontAbbrv', Languages.EN);
    var prefixEnRear = languageStore.getDictionaryTranslate('rearAbbrv', Languages.EN);
    var prefixFrFront = languageStore.getDictionaryTranslate('frontAbbrv', Languages.FR);
    var prefixFrRear = languageStore.getDictionaryTranslate('rearAbbrv', Languages.FR);

    return Immutable.Map({
      en: (front ? prefixEnFront : prefixEnRear) + name.en,
      fr: (front ? prefixFrFront : prefixFrRear) + name.fr
    });
  },
/*
  * @param {Immutable.Map} part
  * @param {index} index
  * @return {Object}
  * @private
  * @description
  * Returns an object with the following properties:
  * itemId, itemType, gbb, isApproved, manufacturer, partnumber
 */
   getCartInfo:function(part, index) {
  return {
    itemId: part.get('partSummary').get('primaryPart').get('itemId'),
    itemType: part.get('partSummary').get('primaryPart').get('itemType'),
    gbb: !isEmpty(index) ? 'gbb' + (index + 1) : 'nongbb',
    isApproved: part.get('partSummary').get('primaryPart').get('itemOriginalEquipmentFlag') == "1",
    manufacturer: this.getPartManufacturer(part),
    partnumber: part.get('partSummary').get('primaryPart').get('itemPartNumber')
  };
},
  processAddToCart(part, quantity, contextId, index, openFinancing, requiredItemIdArray = [], rearInfo = null) {
    const cartInfo = this.getCartInfo(part, index);

    if (rearInfo) {
      this.addItemToCart(cartInfo.itemId, cartInfo.itemType, quantity, contextId, cartInfo.gbb, cartInfo.isApproved, cartInfo.manufacturer, cartInfo.partnumber, rearInfo, openFinancing);
    } else {
      this.addItemToCart(cartInfo.itemId, cartInfo.itemType, quantity, contextId, cartInfo.gbb, cartInfo.isApproved, cartInfo.manufacturer, cartInfo.partnumber, null, openFinancing);
    }
  },

  /////////
  // Start
  /////////

  addToCart: function(part, quantity, contextId, rearquantity = 0, rearcontextId = 0, index = null, requiredItemIdArray = [], openFinancing = false) {
     try {
       let hasCart = store.getState().shoppingCart.cart;
       const processAddToCartWithRearIfNeeded = () => {
         if (sizeOf(part.get('partSummary').get('secondaryParts')) > 0 && rearquantity > 0) {
           const rearitemId = part.get('partSummary').get('secondaryParts').get(0).get('itemId');
           const rearitemType = part.get('partSummary').get('secondaryParts').get(0).get('itemType');
           const rear = this.addItemToCart.bind(this, rearitemId, rearitemType, rearquantity, rearcontextId);
           this.processAddToCart(part, quantity, contextId, index, openFinancing, requiredItemIdArray, rear);
         } else {
           this.processAddToCart(part, quantity, contextId, index, openFinancing, requiredItemIdArray);
         }
       };
       const handleNewCartResponse = (response) => {
         store.dispatch(newShoppingCart(response));
         // this.dispatch(DispatcherActions.SHOPPING_CART_GET_NEW_CART, response);
         processAddToCartWithRearIfNeeded();
       };
       if (!hasCart) {
         localStorage.removeItem('orderNumber'); // ensure that the orderNumber is cleared
         this.apiGet('/shopping/cart/getNewCart.php', {
           dealerId: getAppDealerId(),
           isNational: applicationStore.data.nationalSite ? 1 : 0
         }).then((response) => {
           handleNewCartResponse(response);


         })
         .catch((error) => {
            console.error(error);
         });
       } else {
         processAddToCartWithRearIfNeeded();
       }
     }
      catch (e) {
        console.error(e);
      }
  },

  addAllToCart: function(part, quantity, contextId, rearquantity = 0, rearcontextId = 0, index = null, requiredItemIdArray = []) {
    if (!shoppingCartStore.hasCart()) {
      localStorage.removeItem('orderNumber'); // ensure that the orderNumber is cleared

      this.apiGet('/shopping/cart/getNewCart.php', {
        dealerId: getAppDealerId(),
        isNational: applicationStore.data.nationalSite ? 1 : 0
      }).then(function (response) {
        localStorage.setItem('orderNumber', response.payload.orderNumber);

        this.dispatch(DispatcherActions.SHOPPING_CART_GET_NEW_CART, response);
        // we are getting the part from the response object
        console.log(sizeOf(part.get('partSummary').get('secondaryParts')),'this is our sizeOF PART')
          // console.log comes out to zero for the lenghth part
          // 
        if (sizeOf(part.get('partSummary').get('secondaryParts')) > 0 && rearquantity > 0) {
          // 0 > 0 && 0 > 0 => false,
          //skip
          // this part.get is checking the size of the object / MAP_SET_USER_LOCATION

          var itemId = part.get('partSummary').get('primaryPart').get('itemId'),
            itemType = part.get('partSummary').get('primaryPart').get('itemType'),
            gbb = !isEmpty(index) ? 'gbb' + (index + 1) : 'nongbb',
            isApproved = part.get('partSummary').get('primaryPart').get('itemOriginalEquipmentFlag') == "1",
            manufacturer = this.getPartManufacturer(part),
            partnumber = part.get('partSummary').get('primaryPart').get('itemPartNumber'),
            rearitemId = part.get('partSummary').get('secondaryParts').get(0).get('itemId'),
            rearitemType = part.get('partSummary').get('secondaryParts').get(0).get('itemType');

          var rear = this.addItemToCart.bind(this, rearitemId, rearitemType, rearquantity, rearcontextId, gbb, isApproved, manufacturer, partnumber);

          if (isEmpty(requiredItemIdArray)) this.addItemToCart(itemId, itemType, quantity, contextId, gbb, isApproved, manufacturer, partnumber, rear);
          else this.addAllItemsToCart(itemId, itemType, quantity, contextId, gbb, isApproved, manufacturer, partnumber, requiredItemIdArray, rear);

        } else {

          var itemId = part.get('partSummary').get('primaryPart').get('itemId'),
            itemType = part.get('partSummary').get('primaryPart').get('itemType'),
            gbb = !isEmpty(index) ? 'gbb' + (index + 1) : 'nongbb',
            isApproved = part.get('partSummary').get('primaryPart').get('itemOriginalEquipmentFlag') == "1",
            manufacturer = this.getPartManufacturer(part),
            partnumber = part.get('partSummary').get('primaryPart').get('itemPartNumber');

          if (isEmpty(requiredItemIdArray)) this.addItemToCart(itemId, itemType, quantity, contextId, gbb, isApproved, manufacturer, partnumber);
          else this.addAllItemsToCart(itemId, itemType, quantity, contextId, gbb, isApproved, manufacturer, partnumber, requiredItemIdArray);

        }
      }.bind(this));
    } else {

      if (sizeOf(part.get('partSummary').get('secondaryParts')) > 0 && rearquantity > 0) {

        var itemId = part.get('partSummary').get('primaryPart').get('itemId'),
          itemType = part.get('partSummary').get('primaryPart').get('itemType'),
          gbb = !isEmpty(index) ? 'gbb' + (index + 1) : 'nongbb',
          isApproved = part.get('partSummary').get('primaryPart').get('itemOriginalEquipmentFlag') == "1",
          manufacturer = this.getPartManufacturer(part),
          partnumber = part.get('partSummary').get('primaryPart').get('itemPartNumber'),
          rearitemId = part.get('partSummary').get('secondaryParts').get(0).get('itemId'),
          rearitemType = part.get('partSummary').get('secondaryParts').get(0).get('itemType');

        var rear = this.addItemToCart.bind(this, rearitemId, rearitemType, rearquantity, rearcontextId, gbb, isApproved, manufacturer, partnumber);

        if (isEmpty(requiredItemIdArray)) this.addItemToCart(itemId, itemType, quantity, contextId, gbb, isApproved, manufacturer, partnumber, rear);
        else this.addAllItemsToCart(itemId, itemType, quantity, contextId, gbb, isApproved, manufacturer, partnumber, requiredItemIdArray, rear);

      } else {

        var itemId = part.get('partSummary').get('primaryPart').get('itemId'),
          itemType = part.get('partSummary').get('primaryPart').get('itemType'), //itemPartNumber
          gbb = !isEmpty(index) ? 'gbb' + (index + 1) : 'nongbb',
          isApproved = part.get('partSummary').get('primaryPart').get('itemOriginalEquipmentFlag') == "1",
          manufacturer = this.getPartManufacturer(part),
          partnumber = part.get('partSummary').get('primaryPart').get('itemPartNumber');

        if (isEmpty(requiredItemIdArray)) this.addItemToCart(itemId, itemType, quantity, contextId, gbb, isApproved, manufacturer, partnumber);
        else this.addAllItemsToCart(itemId, itemType, quantity, contextId, gbb, isApproved, manufacturer, partnumber, requiredItemIdArray);

      }

    }
  },

  getPartManufacturer: function(part) {
    switch(part.get('partSummary').get('primaryPart').get('itemType')) {
      case "TRE":
        return part.get('partDetails').get('primaryPart').get('tireManufacturerName');
      case "WHL":
        return part.get('partDetails').get('primaryPart').get('wheelManufacturerName');
      case "ACC":
        return part.get('partDetails').get('primaryPart').get('accessoryManufacturerName');
      case "PKG":
        return part.get('partDetails').get('primaryPart').get('packageManufacturerName');
    }
  },
  // else this.addAllItemsToCart(itemId, itemType, quantity, contextId, gbb, isApproved, manufacturer, partnumber, requiredItemIdArray);
  addItemToCart: function(id, type, quantity, contextId, gbb, isApproved, manufacturer = '', partnumber = '', callafter = null, openFinancing = false) {
    let VehicleId = store.getState().productList.vehicleId;
    //console.log('addItemToCart, openFinancing: '+openFinancing);
    setTimeout(() => {
      this.apiPost('/shopping/cart/addItemToCart.php', {
        dealerId: getAppDealerId(),
        cartId: getOrderIdentifier(),
        orderNumber: getOrderIdentifier(),
        itemId: id,
        itemType: type,
        itemQty: quantity,
        pricingContextId: contextId,
        optionId: 0,
        vehicleId:VehicleId,
        isNational: applicationStore.data.nationalSite ? 1 : 0
      }).then(function (response) {

        var total = 'N/A';
        if (response && response.orderGrandTotal) {
          total = response.orderGrandTotal;
        }

        googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'add to quote', (openFinancing ? 'financed' : 'not financed') + ' ' + getQuoteTrackingLabelInfo('request quote', response.orderItemList), Number(total).toFixed(0));
        response.skipRedirect = true;
        response.addedToCart = true;
        response.openFinancing = openFinancing;
        //response.fromCreatePackage = fromCreatePackage;

        if (typeof(callafter) == 'function') { callafter(); }
        else {
          store.dispatch(toggleCart(false));
          store.dispatch(wishListReview(response));
          store.dispatch(addItemToCart(response));
        }

      }.bind(this));
    }, 100);
  },

  addAllItemsToCart: function(id, type, quantity, contextId, gbb, isApproved, manufacturer = '', partnumber = '', requiredItemIdArray = [], callafter = null) {

    this.apiPost('/shopping/cart/addAllToCart.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      itemId: id,
      itemType: type,
      itemQty: quantity,
      pricingContextId: contextId,
      optionId: 0,
      requiredItemId: requiredItemIdArray,
      vehicleId: applicationStore.data.search ? 0 : vehicleSelectionStore.data.vehicleId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {

      var total = 'N/A';
      if (response && response.orderGrandTotal) {
        total = response.orderGrandTotal;
      }

      googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'add all to quote', getQuoteTrackingLabelInfo('request quote', response.orderItemList), Number(total).toFixed(0));
      response.skipRedirect = true;
      response.addedToCart = true;
      //response.fromCreatePackage = fromCreatePackage;

      if (!!callafter) { callafter(); }
      else  store.dispatch(wishListReview(response));

    }.bind(this));

  },

  removefromCart: function (cartItemId) {
    this.apiPost('/shopping/cart/removeItemFromCart.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      cartItemId: cartItemId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      response.cartItemId = cartItemId;
      // this will item remove from the backend store
      this.dispatch(DispatcherActions.WISHLIST_REVIEW_CHANGE_ITEM, response);
      // this will item remove from the redux store
      store.dispatch(updateItemToCart(response));
    }.bind(this)).catch((e)=>{
      console.error(e);
    });
  },

  addLineItemToCart: function (itemId, lineItemId) {
    this.apiPost('/shopping/cart/addLineItemToCart.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      cartItemId: itemId,
      lineItemId: lineItemId,
      lineItemPrice: 0,
      lineItemWorkOrderNumber: "",
      lineItemNotes: "",
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      this.dispatch(DispatcherActions.WISHLIST_REVIEW_CHANGE_ITEM, response);
    }.bind(this));
  },

  removeLineItemFromCart: function (itemId, lineItemId) {
    this.apiPost('/shopping/cart/removeLineItemFromCart.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      cartItemId: itemId,
      lineItemId: lineItemId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      store.dispatch(updateItemToCart(response));
      this.dispatch(DispatcherActions.WISHLIST_REVIEW_CHANGE_ITEM, response);
    }.bind(this));
  },

  /////////
  // End
  /////////

  addItemToShoppingCart: function (id, name, image, displayPrice, privatePrice, promotionText, quantity, type, product, option, pricingContextId, requiredItems, displayLabour, displayMsrp, isDealerOrder = false, isConsumerOrder = false, source = '', fromCreatePackage = false, openFinancing = false) {
    try {
      let hasCart = store.getState().shoppingCart.cart;
      // checks for the cart using the redux system
      if (!hasCart) {
        // if not them we need to get a new cart
        localStorage.removeItem('orderNumber'); // ensure that the orderNumber is cleared
        // this will be used when download PDF is Clicked
        this.apiGet('/shopping/cart/getNewCart.php', {
          dealerId: getAppDealerId(),
          isNational: applicationStore.data.nationalSite ? 1 : 0
        }).then(function (response) {
          // console.log('getNewCart', response);
          localStorage.setItem('orderNumber', response.orderNumber);
          store.dispatch(newShoppingCart(response));
          this.callAddItemToCart(id, name, image, displayPrice, privatePrice, promotionText, quantity, type, product, option, pricingContextId, requiredItems, displayLabour, displayMsrp, isDealerOrder === true, isConsumerOrder === true, source, fromCreatePackage, openFinancing);
        }.bind(this)).catch(function(error){
          // handle error here
          console.error(error);
        });
      } else {
        this.callAddItemToCart(id, name, image, displayPrice, privatePrice, promotionText, quantity, type, product, option, pricingContextId, requiredItems, displayLabour, displayMsrp, isDealerOrder === true, isConsumerOrder === true, source, fromCreatePackage, openFinancing);
      }
    } catch (err) {
      // handle error here
      console.error(err);
    }
  },

	removeItemFromBasket: function (cartItemId, id, cart) {
		this.apiPost('/shopping/cart/removeItemFromCart.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      cartItemId: cartItemId,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      trackRemoveFromCart(cart, id);
      response.skipRedirect = true;
      response.fromCreatePackage = false;
      this.dispatch(DispatcherActions.WISHLIST_REVIEW_LOADED, response);
      // this will item remove from the redux store
      store.dispatch(updateItemToCart(response));
		}.bind(this));
	},

	duplicateCart: function() {
		this.apiPost('/shopping/cart/generateCartFromCart.php', {
			dealerId: getAppDealerId(),
			cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier()
		}).then(function (response) {
			this.dispatch(DispatcherActions.SHOPPING_CART_RELOADED, response);
		}.bind(this));
	},

	getNewShoppingCart: function () {
		this.apiGet('/shopping/cart/getNewCart.php', {
			dealerId: getAppDealerId(),
			isNational: applicationStore.data.nationalSite ? 1 : 0
		}).then(function (response) {
			// this.dispatch(DispatcherActions.SHOPPING_CART_GET_NEW_CART, response);
      store.dispatch(newShoppingCart(response));
      store.dispatch(toggleCart(false));
		}.bind(this));
	},

	clearShoppingCart: function () {
		this.apiPost('/shopping/cart/emptyCart.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function () {
			this.dispatch(DispatcherActions.SHOPPING_CART_REQUEST_EMPTY, {});
		}.bind(this));
	},

	printShoppingCart: function () {
		this.apiPost('/shopping/cart/printCart.php', {
      dealerId: getAppDealerId(),
      cartId: getOrderIdentifier(),
      orderNumber: getOrderIdentifier(),
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function () {
			this.dispatch(DispatcherActions.SHOPPING_CART_PRINTED, {});
		}.bind(this));
	},

  loadQuoteReport: function() {
    this.apiGet('/shopping/history/getOrderHistory.php', {
    	dealerId: getAppDealerId(),
			isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
			this.dispatch(DispatcherActions.QUOTE_REPORT_LOADED, response);
      this.getOrderHistory();
		}.bind(this));
  },

  getOrderHistory: function () {
    this.apiPost('/shopping/dealerOrder/getDealerOrderHistory.php', {
      dealerId: getAppDealerId(),
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      this.dispatch(DispatcherActions.NTD_ORDERS_GET_HISTORY, response);
      this.getLeadHistory();
    }.bind(this));
  },

  getLeadHistory: function() {
    this.apiGet('/shopping/lead/getDealerLeads.php', {
      dealerId: getAppDealerId(),
      ageDays: 30,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      this.dispatch(DispatcherActions.LEAD_REPORT_HISTORY, response);
    }.bind(this));
  },

  getEcommerceHistory: function () {
    this.apiPost('/shopping/consumerOrder/getConsumerOrderHistory.php', {
      dealerId: getAppDealerId(),
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      this.dispatch(DispatcherActions.ECOMMERCE_GET_HISTORY, response);
    }.bind(this));
  },

  getTirePricingAnalysis: function (tiresize) {
    this.apiPost('/parts/tire/getDealerComparisonPricesByTireSize.php', {
      dealerId: getAppDealerId(),
      tireSize: encodeURIComponent(tiresize),
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      this.dispatch(DispatcherActions.GET_TIRE_PRICING_ANALYSIS, { data: response, tiresize: tiresize });
    }.bind(this));
  },

  getTirePricingIncentive: function () {
    this.apiPost('/parts/tire/getTireTieredPriceListInfo.php', {
      dealerId: getAppDealerId(),
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      this.dispatch(DispatcherActions.GET_TIRE_PRICING_INCENTIVE, response);
    }.bind(this));
  },

	loadNTDOrderPreview: function() {
    googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'NTD Order Modal - PreviewOrder', getTrackingEventOrderId());
    this.apiPost('/shopping/dealerOrder/previewDealerOrderFromCart.php', {
      dealerId: getAppDealerId(),
      orderNumber: getOrderIdentifier(),
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      trackOrder(response, 'PreviewOrder');
      this.dispatch(DispatcherActions.NTD_ORDER_GET_PREVIEW, response);
    }.bind(this));
	},

  loadPlaceOrderNTD: function(purchaseOrderNumber, pickUpOrder) {
    googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'NTD Order Modal - PlaceOrder', getTrackingEventOrderId());
    this.apiPost('/shopping/dealerOrder/generateDealerOrderFromCart.php', {
      dealerId: getAppDealerId(),
      orderNumber: getOrderIdentifier(),
      poNumber: purchaseOrderNumber,
      isPickup: pickUpOrder ? 1 : 0,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function (response) {
      trackOrder(response, 'PlaceOrder');
      this.dispatch(DispatcherActions.NTD_ORDER_GET_PLACE_ORDER, response);
    }.bind(this));
  },

  getDealerOrderStatusForReport: function (orderId, orderNumber) {
    this.apiGet('/shopping/dealerOrder/getDealerOrder.php', {
      dealerId: getAppDealerId(),
      cartId: orderId,
      orderNumber: orderNumber,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      this.dispatch(DispatcherActions.ORDER_STATUS_LOADED, response);
    }.bind(this));
  },

  getConsumerOrderStatusForReport: function(orderId, orderNumber) {
    this.apiGet('/shopping/consumerOrder/getConsumerOrder.php', {
      dealerId: getAppDealerId(),
      cartId: orderId,
      orderNumber: orderNumber,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      this.dispatch(DispatcherActions.ORDER_STATUS_LOADED, response);
    }.bind(this));
  },

  setLeadAsActioned: function(leadId) {
	  var status = {
	    CREATED: "created",
	    OPENED: "opened",
	    ACTIONED: "actioned",
	    CLOSED: "closed",
    };
    this.apiGet('/shopping/lead/updateLeadStatus.php', {
      dealerId: getAppDealerId(),
      leadId: leadId,
      newStatus: status.ACTIONED,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function() {
      this.dispatch(DispatcherActions.LEAD_WAS_ACTIONED, {});
      //this.wishlistFormDealerModalSuccess();
    }.bind(this));
  },

  requestFormAppointment: function(date, firstName, lastName, email, phone, notes, runformsubmit = '') {
    this.apiGet('/shopping/lead/createQuoteAppointmentLead.php', {
      targetDealerId: getAppDealerId(),
      orderNumber: getOrderIdentifier(),
      appointmentRequestDate:  date ? formatDate(date) : null,
      customerFirstName: firstName,
      customerLastName: lastName,
      customerEmail: email,
      customerPhone: phone,
      customerMessage: notes,
      customerLanguage: getAppLanguage(),
      sendCustomerEmail: true,
      generatedBy: "appointment",
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      this.handleFormEventResponse(response, runformsubmit);
    }.bind(this));
  },

  requestQuoteContact: function(type, sendEmail, firstName, lastName, email, phone, notes, sendCustomerEmail, runformsubmit = '') {
     this.apiGet('/shopping/lead/createQuoteContactLead.php', {
      targetDealerId: getAppDealerId(),
      orderNumber: getOrderIdentifier(),
      customerFirstName: firstName,
      customerLastName: lastName,
      customerEmail: email,
      customerPhone: phone,
      customerMessage: notes,
      customerLanguage: getAppLanguage(),
      sendCustomerEmail: sendCustomerEmail,
      generatedBy: type,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      this.handleFormEventResponse(response, runformsubmit);
    }.bind(this)).catch(function(error) {
      console.error("An error occurred while sending the request: " + error);
    });
  },

  requestFormCallback: function(firstName, lastName, email, phone, notes, runformsubmit = '') {
    this.requestQuoteContact("callback", false, firstName, lastName, email, phone, notes, false, runformsubmit);
  },

  requestFormEmail: function(firstName, lastName, email, phone, notes, runformsubmit = '') {
    this.requestQuoteContact("email", true, firstName, lastName, email, phone, notes, true, runformsubmit);
  },

  requestFormPrint: function(print, sendEmail, firstName, lastName, email, phone, notes, sendCustomerEmail, runformsubmit = '') {
    this.requestQuoteContact("print", sendEmail, firstName, lastName, email, phone, notes, sendCustomerEmail, runformsubmit);
  },

  requestFormDownload: function(download, sendEmail, firstName, lastName, email, phone, notes, sendCustomerEmail, runformsubmit = '') {
    this.requestQuoteContact("download", sendEmail, firstName, lastName, email, phone, notes, sendCustomerEmail, runformsubmit);
  },

  requestFormReserve: function(date, firstName, lastName, email, phone, notes, runformsubmit = '') {
    this.apiGet('/shopping/lead/createQuoteReservationLead.php', {
      targetDealerId: getAppDealerId(),
      orderNumber: getOrderIdentifier(),
      reservationRequestDate: date ? formatDate(date) : null,
      customerFirstName: firstName,
      customerLastName: lastName,
      customerEmail: email,
      customerPhone: phone,
      customerMessage: notes,
      customerLanguage: getAppLanguage(),
      sendCustomerEmail: true,
      generatedBy: "reserve",
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      this.handleFormEventResponse(response, runformsubmit);
    }.bind(this));
  },

  requestFormReminder: function(type, callbackFunction, date, firstName, lastName, email, phone, notes, runformsubmit = '') {
    this.apiGet('/shopping/reminder/createReminderQuoteContactLead.php', {
      targetDealerId: getAppDealerId(),
      orderNumber: getOrderIdentifier(),
      reminderDate: date ? formatDate(date) : null,
      customerFirstName: firstName,
      customerLastName: lastName,
      customerEmail: email,
      customerPhone: phone,
      customerMessage: notes,
      customerLanguage: getAppLanguage(),
      sendCustomerEmail: false,
      generatedBy: type,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      if (response && !!response.success) {
        runAdobeServiceFormSubmit(runformsubmit);
        this.dispatch(DispatcherActions.SHOPPING_CART_REMINDER_SUCCESS, {});
        callbackFunction();
      } else {
        this.dispatch(DispatcherActions.SHOPPING_CART_REMINDER_ERROR, {});
      }
    }.bind(this));
  },

  requestComparisonLead: function(type, firstName, lastName, email, phone, notes, firstPartId = 0, secondPartId = 0, thirdPartId = 0) {
    this.apiGet('/shopping/lead/createComparisonLead.php', {
      targetDealerId: getAppDealerId(),
      orderNumber: getOrderIdentifier(),
      customerFirstName: firstName,
      customerLastName: lastName,
      customerEmail: email,
      customerPhone: phone,
      customerMessage: notes,
      customerLanguage: getAppLanguage(),
      sendCustomerEmail: false,
      partType: 'TRE',
      partIds: [firstPartId, secondPartId, thirdPartId],
      generatedBy: type,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      this.handleFormEventResponse(response);
    }.bind(this));
  },

  requestFormCompare: function(firstName, lastName, email, phone, notes, firstPartId, secondPartId, thirdPartId) {
    this.requestComparisonLead("compare", firstName, lastName, email, phone, notes, firstPartId, secondPartId, thirdPartId);
  },

  requestComparisonEmail: function(send, firstName, lastName, email, phone, notes, firstPartId, secondPartId, thirdPartId) {
    this.requestComparisonLead("compare-email", firstName, lastName, email, phone, notes, firstPartId, secondPartId, thirdPartId);
  },

  requestComparisonPrint: function(print, firstName, lastName, email, phone, notes, firstPartId, secondPartId, thirdPartId) {
    this.requestComparisonLead("compare-print", firstName, lastName, email, phone, notes, firstPartId, secondPartId, thirdPartId);
  },

  requestComparisonDownload: function(download, firstName, lastName, email, phone, notes, firstPartId, secondPartId, thirdPartId) {
    this.requestComparisonLead("compare-download", firstName, lastName, email, phone, notes, firstPartId, secondPartId, thirdPartId);
  },

  submitFormMarkAsComplete: function() {
    var status = {
      CREATED: "created",
      OPENED: "opened",
      ACTIONED: "actioned",
      CLOSED: "closed",
    };
    this.apiGet('/shopping/lead/updateLeadStatus.php', {
      targetDealerId: getAppDealerId(),
      leadId: applicationStore.data.parentLeadId,
      newStatus: status.CLOSED,
      isNational: applicationStore.data.nationalSite ? 1 : 0
    }).then(function(response) {
      applicationStore.data.parentLeadId = null;
      this.handleDealerFormEventResponse(response);
    }.bind(this));
  },

  handleFormEventResponse: function(response, runformsubmit = '') {
    if (response && !!response.success) {
      if (!isEmpty(runformsubmit)) runAdobeServiceFormSubmit(runformsubmit);
      this.wishlistFormModalSuccess();
    } else {
      this.wishlistFormModalError();
    }
  },

  handleDealerFormEventResponse: function(response) {
    if (response && !!response.success) {
      this.wishlistFormDealerModalSuccess();
    } else {
      this.wishlistFormDealerModalError();
    }
  },

  wishlistFormModalSuccess: function() {
    this.dispatch(DispatcherActions.SHOPPING_CART_FORM_SUCCESS, {});
  },

  wishlistFormModalError: function() {
    this.dispatch(DispatcherActions.SHOPPING_CART_FORM_ERROR, {});
  },

  wishlistFormDealerModalSuccess: function() {
    this.dispatch(DispatcherActions.SHOPPING_CART_DEALER_FORM_SUCCESS, {});
  },

  wishlistFormDealerModalError: function() {
    this.dispatch(DispatcherActions.SHOPPING_CART_DEALER_FORM_ERROR, {});
  },

  wishlistFormDealerModalResetError: function() {
    this.dispatch(DispatcherActions.SHOPPING_CART_DEALER_FORM_RESET_ERROR, {});
  },

  closeWishlistAppointmentIntegrationModal: function() {
    this.dispatch(DispatcherActions.SHOPPING_CART_APPOINTMENT_INTEGRATION_MODAL_CLOSE, {});
  },

  openWishlistAppointmentIntegrationModal: function() {
    this.dispatch(DispatcherActions.SHOPPING_CART_APPOINTMENT_INTEGRATION_MODAL_OPEN, {});
  },

  openWishlistFormModal: function(formType) {
    this.dispatch(DispatcherActions.SHOPPING_CART_OPEN_FORM, formType);
  },

  closeWishlistFormModal: function() {
    this.dispatch(DispatcherActions.SHOPPING_CART_CLOSE_FORM, {});
  },

  openMarkAsCompleteModal: function() {
    this.dispatch(DispatcherActions.OPEN_MARK_AS_COMPLETE_MODAL, {});
  },

  closeMarkAsCompleteModal: function() {
    this.dispatch(DispatcherActions.CLOSE_MARK_AS_COMPLETE_MODAL, {});
  },
  // come back to this toggle Finace 
  toggleFinanceModal: function() {
    this.dispatch(DispatcherActions.TOGGLE_FINANCE_MODAL, {});
  },

  // openWishlistDealerFormModal: function(formType) {
  //   this.dispatch(DispatcherActions.SHOPPING_CART_OPEN_DEALER_FORM, formType);
  // },

  // closeWishlistDealerFormModal: function() {
  //   this.dispatch(DispatcherActions.SHOPPING_CART_CLOSE_DEALER_FORM, {});
  // },

  // closeFoxyCartErrorModal: function () {
  //   this.dispatch(DispatcherActions.FOXY_CART_ERROR_CLOSE, {});
  // },

  // changeRequiredItemQuantity: function (productIndex, quantity) {
  //   this.dispatch(DispatcherActions.SHOPPING_CART_CHANGED_REQUIRED_QUANTITY, { productIndex, quantity });
  // },E
 //  Has a closing, but no opening towards the action creator 
  // closeSessionReferenceModal: function () {
  //   this.dispatch(DispatcherActions.SESSION_REFERENCE_CLOSE_MODAL, {});
  // },
 //  Has a closing, but no opening towards the action creator 
  // openScheduleWishlistModal: function () {
  //   this.dispatch(DispatcherActions.OPEN_WISHLIST_SCHEDULE_MODAL, {});
  // },

  // closeScheduleWishlistModal: function () {
  //   this.dispatch(DispatcherActions.CLOSE_WISHLIST_SCHEDULE_MODAL, {});
  // },
 //  Has a closing, but no opening towards the action creator 
  // openEmailWishlistModal: function () {
  //   this.dispatch(DispatcherActions.OPEN_WISHLIST_EMAIL_MODAL, {});
  // },

  // closeEmailWishlistModal: function () {
  //   this.dispatch(DispatcherActions.CLOSE_WISHLIST_EMAIL_MODAL, {});
  // },

  openAppointmentWishlistModal: function () {
    this.dispatch(DispatcherActions.OPEN_WISHLIST_APPOINTMENT_MODAL, {});
  },

  // closeAppointmentWishlistModal: function () {
  //   this.dispatch(DispatcherActions.CLOSE_WISHLIST_APPOINTMENT_MODAL, {});
  // },

  // closeModal: function () {
  //   this.dispatch(DispatcherActions.SHOPPING_CART_CLOSE_MODAL, {});
  // },

  openCartModal: function () {
    this.dispatch(DispatcherActions.SHOPPING_CART_MODAL_OPEN, {});
  },

  closeCartModal: function () {
    this.dispatch(DispatcherActions.SHOPPING_CART_MODAL_CLOSE, {});
  },

  // openCart: function () {
  //   this.dispatch(DispatcherActions.SHOPPING_CART_OPEN, {});
  // },

  closeCart: function () {
    this.dispatch(DispatcherActions.SHOPPING_CART_CLOSE, {});
  },

  openDetail: function (cartItem) {
    this.dispatch(DispatcherActions.SHOPPING_CART_OPEN_DETAIL, { cartItem: cartItem });
  },

  closeCartDetail: function () {
    this.dispatch(DispatcherActions.SHOPPING_CART_CLOSE_DETAIL, {});
  },

  // showWarnings: function () {
  //   this.dispatch(DispatcherActions.SHOPPING_CART_SHOW_WARNING, {});
  // },

  // closeWarnings: function () {
  //   this.dispatch(DispatcherActions.SHOPPING_CART_CLOSE_WARNING, {});
  // },

  // closeCartQuantityLimitWarnings: function () {
  //   this.dispatch(DispatcherActions.SHOPPING_CART_CLOSE_QUANTITY_LIMIT_WARNING, {});
  // },

  // cartNewItemSeen: function () {
  //   this.dispatch(DispatcherActions.SHOPPING_CART_NEW_ITEM_SEEN, {});
  // },

  onOpenWishlistReview: function (enableNtd) {
    this.dispatch(DispatcherActions.OPEN_WISHLIST_REVIEW, enableNtd);
  }
});


export const shoppingCartActions = new ShoppingCartActions();

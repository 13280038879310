import { applicationStore } from "../../stores/ApplicationStore.js";
import { applicationActions } from "../../actions/ApplicationActions.js";
import { breadcrumbsStore } from "../../stores/BreadcrumbsStore.js";
import { breadcrumbActions } from "../../actions/BreadcrumbActions.js";
import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";

import { SelectBox } from "../ux/SelectBox.jsx";

import { RouterStates } from "../../constants/RouterStates.js";
import { ProductTypes } from "../../constants/ProductTypes.js";

import { tiresizeHasWheels } from "../../service/VehicleService";
import { getTestId, TEST_LIST } from "../../service/TestingService";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";
import {
  getTabIndex,
  sizeOf,
  isEmpty,
  getAppLanguage,
  getRouterState,
  delay,
  hasLargeLogo,
  isConsumerSite,
  isMobile,
  isTablet,
  isDealerSite,
  nationalSiteHasDealerSelected,
  getDealerName,
} from "../../service/UtilityService";
import { connect } from "react-redux";
import {
  resettireSizeFilters,
  productListLoading,
} from "../../redux/features/productList";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var Immutable = require("immutable");
var _ = require("lodash");

class Breadcrumbs extends I18nComponent {
  updateState = (state) => {
    this.setState(state);
  };

  isMounted = false;

  componentWillUnmount() {
    this.isMounted = false;
    applicationStore.removeChangeListener(this.onChange);
    breadcrumbsStore.removeChangeListener(this.onChange);
    window.removeEventListener("resize", this.handleWindowResize);
  }

  componentDidMount() {
    this.isMounted = true;
    applicationStore.addChangeListener(this.onChange);
    breadcrumbsStore.addChangeListener(this.onChange);
    window.addEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize = () => {
    this.updateState({
      mobile: isMobile(),
      tablet: isTablet(),
    });
  };

  onChange = () => {
    if (!this.isMounted) return;
    this.setState({
      crumbs: breadcrumbsStore.getBreadcrumbs(),

      year: applicationStore.data.year,
      make: applicationStore.data.make,
      model: applicationStore.data.model,
      category: applicationStore.data.category,
      trim: applicationStore.data.trim,
      productListProductTypes: this.props.productListProductTypes,
      productType: applicationStore.data.productType,
    });
  };

  componentDidUpdate(lastProps, lastState) {
    if (
      lastProps.productListProductTypes !== this.props.productListProductTypes
    ) {
      this.onChange();
    }
    if (!this.state.loaded && parseInt(applicationStore.data.year) < 1950) {
      this.setState({ loaded: true });
      breadcrumbActions.loadBreadcrumbs();
    } else if (
      this.state.year !== lastState.year ||
      this.state.make !== lastState.make ||
      this.state.model !== lastState.model ||
      this.state.trim !== lastState.trim
    ) {
      breadcrumbActions.loadBreadcrumbs();
    }
  }

  render() {
    return (
      <box className={this.getBreadcrumbsClass()}>
        <container className="side">
          <left className={this.getLogoContainerClass()}>
            {this.renderBackButton()}
            {this.renderCrumbs()}
            {this.renderDealerName()}
          </left>

          <right>{this.renderProductSelection()}</right>

          {this.renderBreadcrumbBar()}
        </container>
      </box>
    );
  }

  getBreadcrumbsClass = () => {
    if (this.enableReduced()) {
      return "breadcrumbs reduced";
    } else {
      return "breadcrumbs";
    }
  };

  renderBreadcrumbBar = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mercedes":
      case "mercedes_2022":
      case "mercedestransition":
        return <hr className="divide" />;
      default:
        return false;
    }
  };

  getLogoContainerClass = () => {
    if (hasLargeLogo()) {
      return "shift";
    } else {
      return "false";
    }
  };

  shouldEnableProductSelection = (productType) => {
    const map = {
      [ProductTypes.TIRES]:
        window.dealerAccountInfoPayload.dealerEnableTireSearch,
      [ProductTypes.ALLOY_WHEELS]:
        window.dealerAccountInfoPayload.dealerEnableWheelSearch,
      [ProductTypes.ACCESSORIES]:
        window.dealerAccountInfoPayload.dealerEnableAccessorySearch,
      [ProductTypes.PACKAGES]:
        window.dealerAccountInfoPayload.dealerEnablePackageSearch,
    };
    return Boolean(map[productType]);
  };

  renderProductSelection = () => {
    if (this.props.isGuide) {
      return false;
    } else if (this.showProductSelection()) {
      var getProductSelectionClass = this.getProductSelectionClass,
        getProductSelectionText = this.getProductSelectionText,
        onSelectProductType = this.onSelectProductType,
        getProductSelectionIcon = this.getProductSelectionIcon,
        hasWheels = tiresizeHasWheels();

      if (this.enableProductSelectionSelectbox()) {
        var list = [];
        let items = this.state.productListProductTypes;
        for (var i in this.state.productListProductTypes) {
          let enable = true;
          // if (items[i] === ProductTypes.ALLOY_WHEELS) {
          //   enable = hasWheels;
          // }
          if (enable) {
            list.push({
              key: items[i],
              value: getProductSelectionText(
                this.props.productListProductTypes[i]
              ),
            });
          }
        }
        return (
          <column>
            <SelectBox
              selected={applicationStore.data.productType}
              dataList={list}
              onSelect={onSelectProductType}
              datadtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.SECONDARYNAV)}
            />
          </column>
        );
      } else {
        var enableSelectedProductIcon = this.enableSelectedProductIcon();

        return Immutable.fromJS(this.props.productListProductTypes).map(
          (productType, index) => {
            const enable = this.shouldEnableProductSelection(productType);
            // if (productType == ProductTypes.ALLOY_WHEELS) {
            //   enable = hasWheels;
            // }
            if (enable) {
              return (
                <column
                  key={"seelct-" + productType}
                  className={getProductSelectionClass(productType)}
                  tabIndex={
                    productType === applicationStore.data.productType
                      ? "-1"
                      : getTabIndex()
                  }
                  onClick={onSelectProductType.bind(null, productType)}
                >
                  <span
                    id={getTestId(TEST_LIST.NAVIGATION.PRODUCT, productType)}
                    className={setAdobeLaunchClass(
                      "bold",
                      ADOBELAUNCHER_CLASS.BUTTON.LINK
                    )}
                    data-dtm={setAdobeLaunchDTM(
                      "",
                      ADOBELAUNCHER_DTM.SECONDARYNAV
                    )}
                  >
                    {getProductSelectionText(productType)}
                  </span>
                  {enableSelectedProductIcon &&
                  productType === applicationStore.data.productType ? (
                    <i className="fa fa-caret-up" />
                  ) : (
                    false
                  )}
                </column>
              );
            } else {
              return false;
            }
          }
        );
      }
    } else {
      return false;
    }
  };

  enableProductSelectionSelectbox = () => {
    return this.state.mobile;
  };

  enableSelectedProductIcon = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "tirecraft":
        return true;
    }
  };

  getProductSelectionIcon = (productType) => {
    switch (productType) {
      case ProductTypes.TIRES:
        return "/static/img/icon_tire.png";
      case ProductTypes.ALLOY_WHEELS:
        return "/static/img/icon_wheel.png";
      case ProductTypes.ACCESSORIES:
        return "/static/img/icon_accessory.png";
      case ProductTypes.PACKAGES:
        return "/static/img/icon_package.png";
      default:
        return "";
    }
  };

  getProductSelectionClass = (productType) => {
    var className = "desktop";
    if (productType === applicationStore.data.productType) {
      className += this.selectedProductTypeClass();
    } else {
      className += " product-type unselected";
    }
    return className;
  };

  selectedProductTypeClass = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "hyundai":
      case "mazda":
      case "mercedes":
      case "mercedes_2022":
      case "tirecraft":
        return " product-type selected";
      default:
        return " product-type has-border selected";
    }
  };

  onSelectProductType = (productType) => {
    this.props.dispatch(resettireSizeFilters());
    if (
      !this.props.isGuide &&
      productType !== applicationStore.data.productType
    ) {
      this.props.dispatch(productListLoading());
      applicationActions.changeProductType(productType);
    }
  };

  getProductSelectionText = (productType) => {
    switch (productType) {
      case ProductTypes.TIRES:
        return this.t("productTypes.tires");
      case ProductTypes.ALLOY_WHEELS:
        return this.t("productTypes.wheels");
      case ProductTypes.ACCESSORIES:
        return this.t("productTypes.accessories");
      case ProductTypes.PACKAGES:
        return this.t("productTypes.packagesTab");
    }
  };

  showProductSelection = () => {
    return getRouterState() === RouterStates.PRODUCT_LIST;
  };

  renderBackButton = () => {
    if (this.props.isGuide) {
      return false;
    } else {
      switch (applicationStore.data.defaultTemplate) {
        case "audi_2021":
          return (
            <column className="desktop">
              <text
                id={getTestId(TEST_LIST.NAVIGATION.SEARCH)}
                className={setAdobeLaunchClass(
                  "bold pointer",
                  ADOBELAUNCHER_CLASS.BUTTON.LINK
                )}
                data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.SECONDARYNAV)}
                tabIndex={getTabIndex()}
                onClick={this.onToggleSearch}
              >
                {this.t("search.newSearch")}
                <i className="fa fa-angle-right" />
              </text>
            </column>
          );
        case "bmw":
        case "kia_2021":
        case "mazda":
        case "mini":
        case "mrlube":
        case "pneuselect":
          return false;
        case "hyundai":
        case "mercedes":
        case "mercedes_2022":
        case "mercedestransition":
          return (
            <column className="desktop">
              <button
                id={getTestId(TEST_LIST.NAVIGATION.SEARCH)}
                className={setAdobeLaunchClass(
                  "btn",
                  ADOBELAUNCHER_CLASS.BUTTON.LINK
                )}
                data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.SECONDARYNAV)}
                tabIndex={getTabIndex()}
                onClick={this.onToggleSearch}
              >
                {this.t("search.newSearch")}
              </button>
            </column>
          );
        case "volkswagen_2020":
          return (
            <column className="desktop">
              <text
                id={getTestId(TEST_LIST.NAVIGATION.SEARCH)}
                className={setAdobeLaunchClass(
                  "bold pointer",
                  ADOBELAUNCHER_CLASS.BUTTON.LINK
                )}
                data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.SECONDARYNAV)}
                tabIndex={getTabIndex()}
                onClick={this.onToggleSearch}
              >
                {this.t("search.newSearch")}
                <i className="fa fa-arrow-right" />
              </text>
            </column>
          );
        default:
          return (
            <column className="desktop">
              <button
                id={getTestId(TEST_LIST.NAVIGATION.BACK)}
                className={setAdobeLaunchClass(
                  "btn",
                  ADOBELAUNCHER_CLASS.BUTTON.LINK
                )}
                data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.SECONDARYNAV)}
                tabIndex={getTabIndex()}
                onClick={this.onCrumbGoBack}
              >
                {this.t("breadcrumbs.back")}
              </button>
            </column>
          );
      }
    }
  };

  renderCrumbs = () => {
    var crumbs = this.state.crumbs,
      renderCrumb = this.renderCrumb,
      enableCrumbImageSeperator = this.enableCrumbImageSeperator(),
      getCrumbSeperatorImage = this.getCrumbSeperatorImage();
    if (this.showCrumbs() && sizeOf(crumbs) > 0) {
      var validCount = 0;
      for (var i in crumbs) {
        if (crumbs[i] && !isEmpty(crumbs[i]["value"])) {
          validCount++;
        }
      }
      return Immutable.fromJS(crumbs).map(function (crumb, index) {
        return [
          renderCrumb(crumb, index),
          enableCrumbImageSeperator && index < validCount - 1 ? (
            <img className="divider" src={getCrumbSeperatorImage} />
          ) : (
            false
          ),
        ];
      });
    } else {
      return false;
    }
  };

  renderDealerName = () => {
    if (this.enableDealerName()) {
      if (isDealerSite() || nationalSiteHasDealerSelected()) {
        return (
          <column>
            <container className="side">
              <text className="dealer-name">{getDealerName()}</text>
            </container>
          </column>
        );
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  enableDealerName = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "volkswagen_2020":
        return true;
      default:
        return false;
    }
  };

  getCrumbSeperatorImage = () => {
    return (
      "/static/img/" +
      applicationStore.data.defaultTemplate +
      "/breadcrumb_divider.png"
    );
  };

  enableCrumbImageSeperator = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "tirecraft":
        return true;
      default:
        return false;
    }
  };

  showCrumbs = () => {
    return (
      !this.disableCrumbs() &&
      getRouterState() !== RouterStates.HOMEPAGE &&
      getRouterState() !== RouterStates.CONTACT_DEALER &&
      getRouterState() !== RouterStates.COMPARISON &&
      getRouterState() !== RouterStates.PRINT_COMPARISON &&
      getRouterState() !== RouterStates.PRINT_COMPARISON_FROM_TIRESIZE
    );
  };

  disableCrumbs = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "volkswagen_2020":
        return true;
      default:
        return false;
    }
  };

  hideCrumb = (crumb) => {
    if (crumb && crumb.get("state") === RouterStates.TRIM) {
      switch (applicationStore.data.defaultTemplate) {
        case "audi":
        case "volkswagen":
          return true;
        default:
          return false;
      }
    } else {
      return false;
    }
  };

  renderCrumb = (crumb, index) => {
    if (!this.hideCrumb()) {
      var className = "";
      if (
        crumb.get("state") === RouterStates.MAKE ||
        crumb.get("state") === RouterStates.MODEL
      ) {
        className = "desktop";
      }
      if (
        !isEmpty(this.getCrumbText(crumb)) &&
        !this.turnOffLinks() &&
        sizeOf(crumb) > 1
      ) {
        return (
          <column key={"crumb-" + index} className={className}>
            <container className="side small">
              <span
                id={crumb.get("elementId")}
                className={setAdobeLaunchClass(
                  this.state.mobile ? "text-small" : "",
                  ADOBELAUNCHER_CLASS.TEXT
                )}
                data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.SECONDARYNAV)}
                tabIndex={getTabIndex()}
                onClick={this.onClickCrumb.bind(null, crumb.get("state"))}
              >
                {this.getCrumbText(crumb)}
              </span>
            </container>
          </column>
        );
      } else if (!isEmpty(this.getCrumbText(crumb))) {
        return (
          <column key={"crumb-" + index} className={className}>
            <container className="side small">
              <span
                id={crumb.get("elementId")}
                className={
                  this.state.mobile ? "text-small noselect" : "noselect"
                }
                tabIndex={getTabIndex()}
              >
                {this.getCrumbText(crumb)}
              </span>
            </container>
          </column>
        );
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  enableReduced = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "hyundai":
      case "mazda":
        return true;
      default:
        return false;
    }
  };

  getCrumbText = (crumb) => {
    switch (crumb.get("state")) {
      case RouterStates.CATEGORY:
      case RouterStates.MODEL:
        return crumb.get("value").get(getAppLanguage());
      case RouterStates.TRIM:
        return this.showVehicleSubmodel()
          ? crumb.get("value").get(getAppLanguage())
          : "";
      default:
        return crumb.get("value");
    }
  };
  getrumbArrow = (value) => {
    switch (applicationStore.data.defaultTemplate) {
      case "pneuselect":
        return !!value ? "< " + value : null;
      default:
        return !!value ? value : null;
    }
  };
  showVehicleSubmodel = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mercedes":
        return false;
      case "mercedes_2022":
        return false;
      default:
        return true;
    }
  };

  turnOffLinks = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "audi":
      case "mercedes":
      case "mercedes_2022":
      case "volkswagen":
        return true;
      default:
        return !!this.props.isGuide;
    }
  };

  onClickCrumb = (state) => {
    applicationActions.changeRouterStateDirectly(state, true);
  };

  onToggleSearch = () => {
    applicationActions.toggleSearchFlag();
  };

  onCrumbGoBack = () => {
    if (isConsumerSite()) {
      window.location.reload();
    } else {
      if (getRouterState() === RouterStates.WISHLIST) {
        shoppingCartActions.duplicateCart();
      }
      applicationActions.goBack();
    }
  };

  state = {
    loaded: !!this.props.isGuide,

    year: null,
    make: null,
    model: null,
    category: null,
    trim: null,

    crumbs: breadcrumbsStore.getBreadcrumbs(),

    mobile: isMobile(),
    tablet: isTablet(),
    productListProductTypes: this.props.productListProductTypes
      ? this.props.productListProductTypes
      : [],
    productType: applicationStore.data.productType
      ? applicationStore.data.productType
      : null,
  };
}
const mapStateToProps = (state) => {
  return {
    productListProductTypes: state.productList.vehiclesMapped,
  };
};
export default connect(mapStateToProps)(Breadcrumbs);

import { AbstractAction } from './AbstractAction.js';
import { ProductTypes } from '../constants/ProductTypes.js';
import { DispatcherActions } from '../constants/DispatcherActions.js';
import { applicationStore } from '../stores/ApplicationStore.js';
import { vehicleSelectionStore } from '../stores/VehicleSelectionStore.js';
import { productDetailStore } from '../stores/ProductDetailStore.js';

import { getAppDealerId } from '../service/UtilityService';
import store from "../stores/configureStore";

const ProductDetailActions = AbstractAction.extend({

  openDetail: function(id, productType, secondaryProductId) {
    this.dispatch(DispatcherActions.PRODUCT_DETAIL_OPENED, {
      id: id,
      productType: productType,
      secondaryProductId: secondaryProductId
    });
  },

  loadDetail: function() {
    var productType = applicationStore.data.productType ? applicationStore.data.productType : productDetailStore.data.productType;
    let VehicleId = store.getState().productList.vehicleId;
    this.apiGet(this.getDetailUrl(productType), {
      partId: productDetailStore.data.productId,
      dealerId: getAppDealerId(),
      vehicleId: VehicleId ? VehicleId : null,
      secondaryPartId: productDetailStore.data.secondaryProductId
    }).then(response => this.dispatch(DispatcherActions.PRODUCT_DETAIL_LOADED, response));
  },

  getDetailUrl: function(productType) {
    switch (productType) {
      case ProductTypes.TIRES:
        return '/parts/tire/getTireByPartId.php';
      case ProductTypes.ALLOY_WHEELS:
        return '/parts/wheel/getWheelByPartId.php';
      case ProductTypes.ACCESSORIES:
        return '/parts/accessory/getAccessoryByPartId.php';
      case ProductTypes.PACKAGES:
        return '/parts/partPackage/getPartPackageByPartId.php';
      default:
        throw new Error();
    }
  },

  // changeCartDetailTireQuantity: function(payload) {
  //   this.dispatch(DispatcherActions.PRODUCT_DETAIL_CHANGED_TIRE_QUANTITY, payload);
  // },

  // changeCartDetailWheelQuantity: function(payload) {
  //   this.dispatch(DispatcherActions.PRODUCT_DETAIL_CHANGED_WHEEL_QUANTITY, payload);
  // },

  // changeCartDetailQuantity: function(payload) {
  //   this.dispatch(DispatcherActions.PRODUCT_DETAIL_CHANGED_QUANTITY, payload);
  // },

  // changeRequiredItemQuantity: function(productIndex, quantity) {
  //   this.dispatch(DispatcherActions.PRODUCT_DETAIL_CHANGED_REQUIRED_QUANTITY, {
  //     productIndex,
  //     quantity
  //   });
  // }
});

export const productDetailActions = new ProductDetailActions();
import { productDetailActions } from "../../actions/ProductDetailActions.js";
import { vehicleSelectionActions } from "../../actions/VehicleSelectionActions.js";
import { productListActions } from "../../actions/ProductListActions.js";
import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";
import { productDetailStore } from "../../stores/ProductDetailStore.js";
import { productListStore } from "../../stores/ProductListStore.js";
import { ProductTypes } from "../../constants/ProductTypes.js";
import { Image } from "../ux/Image.jsx";
import { ComboBox } from "../ux/forms/ComboBox.jsx";
import { ComboBoxValue } from "../ux/forms/ComboBoxValue.jsx";
import { Modal } from "../ux/Modal.jsx";
import { AppointmentForm } from "./AppointmentForm.jsx";

import { languageStore } from "../../stores/LanguageStore.js";
import { applicationStore } from "../../stores/ApplicationStore.js";

import {
  sizeOf,
  getTabIndex,
  getAppLanguage,
  getTrackingEventCategory,
} from "../../service/UtilityService";
import {
  renderTirePricing,
  renderWheelPricing,
  renderAccessoryPricing,
  renderPackagePricing,
} from "../../service/RenderService";
import { renderNTDButton } from "../../service/OrderService";
import { isUserLoggedIn } from "../../api/AuthService";
import {
  changeCartDetailTireQuantity,
  changeCartDetailWheelQuantity,
  changeCartDetailQuantity,
  changeRequiredItemQuantity,
} from "../../redux/features/productDetails";
import { connect } from "react-redux";
import { changeColor } from "../../redux/features/productList.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var ReactDOM = require("react-dom");
var $ = require("jquery");
var Immutable = require("immutable");
var Cx = require("classnames");

class ProductDetail extends I18nComponent {
  state = {
    detail: productDetailStore.data.detail,
    detailForCart: productDetailStore.data.detailForCart,
    productType: productDetailStore.data.productType,
    colour: productListStore.data.colourCodeInDetail,
    vehicleColours: productListStore.data.colourCodes,
    warrantyList: productDetailStore.data.warrantyList,
    showingImage: false,
    showingAltImage: false,
    option: null,
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("Product Detail");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Product Detail Access"
    );
    productDetailStore.addChangeListener(this.onChange);
    productListStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    productDetailStore.removeChangeListener(this.onChange);
    productListStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    vehicleSelectionActions.loadVehicleInfo();
    productDetailActions.loadDetail();
    /*
    setTimeout(() => {
      vehicleSelectionActions.loadVehicleInfo();
      setTimeout(() => { productDetailActions.loadDetail(); }, 100);
    }, 100);
    */
  }

  componentDidUpdate() {
    if (this.refs.lightbox && this.refs.lightboxImage) {
      var lb = ReactDOM.findDOMNode(this.refs.lightbox),
        lbImg = ReactDOM.findDOMNode(this.refs.lightboxImage);
      if (
        lbImg.offsetHeight / lbImg.offsetWidth >
        lb.offsetHeight / lb.offsetWidth
      ) {
        lb.className = "modal-content flex-height";
      } else {
        lb.className = "modal-content flex-width";
      }
    }
  }

  onChangeColour = (colour) => {
    this.props.dispatch(changeColor(colour));
    productListActions.changeColourInDetail(colour);
  };

  onChangeRequiredItemQuantity = (itemIndex, quantity) => {
    productDetailActions.changeRequiredItemQuantity(itemIndex, quantity);
  };

  onChangeOption = (option) => {
    this.setState({
      option: option,
    });
  };

  onAddToBasket = (source = "") => {
    $(function () {
      $("body").animate({ scrollTop: 0 }, "slow");
    });
    var product = this.state.detailForCart;
    switch (this.state.productType) {
      case ProductTypes.TIRES:
        if (this.state.detail.get("staggered")) {
          shoppingCartActions.addStaggeredItemToShoppingCart(
            product.get("frontTire").get("id"),
            product.get("rearTire").get("id"),
            {
              en: product.get("name"),
              fr: product.get("name"),
            },
            product.get("image"),
            product.get("frontTire").get("pricing"),
            product.get("rearTire").get("pricing"),
            parseInt(product.get("frontTire").get("quantity").get("value"), 10),
            parseInt(product.get("rearTire").get("quantity").get("value"), 10),
            ProductTypes.TIRES,
            product,
            0,
            product.get("frontTire").get("pricing").get("pricingContextId"),
            product.get("rearTire").get("pricing").get("pricingContextId"),
            product.get("relatedParts")
          );
        } else {
          shoppingCartActions.addItemToShoppingCart(
            product.get("frontTire").get("id"),
            {
              en: product.get("name"),
              fr: product.get("name"),
            },
            product.get("smallImage"),
            product.get("frontTire").get("pricing").get("displayPrice"),
            product.get("frontTire").get("pricing").get("privatePrice"),
            product.get("frontTire").get("pricing").get("promotionPricingText"),
            parseInt(product.get("frontTire").get("quantity").get("value"), 10),
            ProductTypes.TIRES,
            product,
            0,
            product.get("frontTire").get("pricing").get("pricingContextId"),
            product.get("relatedParts"),
            0,
            product.get("frontTire").get("pricing").get("listPrice"),
            source === "purchase",
            source === "order",
            "Part Detail"
          );
        }
        break;
      case ProductTypes.ALLOY_WHEELS:
        if (this.state.detail.get("staggered")) {
          shoppingCartActions.addStaggeredItemToShoppingCart(
            product.get("frontWheel").get("id"),
            product.get("rearWheel").get("id"),
            product.get("name").toJS(),
            product.get("image"),
            product.get("frontWheel").get("pricing"),
            product.get("rearWheel").get("pricing"),
            parseInt(
              product.get("frontWheel").get("quantity").get("value"),
              10
            ),
            parseInt(product.get("rearWheel").get("quantity").get("value"), 10),
            ProductTypes.ALLOY_WHEELS,
            product,
            0,
            product.get("frontWheel").get("pricing").get("pricingContextId"),
            product.get("rearWheel").get("pricing").get("pricingContextId"),
            product.get("relatedParts"),
            product.get("frontWheel").get("pricing").get("listPrice"),
            product.get("rearWheel").get("pricing").get("listPrice")
          );
        } else {
          shoppingCartActions.addItemToShoppingCart(
            product.get("frontWheel").get("id"),
            product.get("name").toJS(),
            product.get("image"),
            product.get("frontWheel").get("pricing").get("displayPrice"),
            product.get("frontWheel").get("pricing").get("privatePrice"),
            product
              .get("frontWheel")
              .get("pricing")
              .get("promotionPricingText"),
            parseInt(
              product.get("frontWheel").get("quantity").get("value"),
              10
            ),
            ProductTypes.ALLOY_WHEELS,
            product,
            0,
            product.get("frontWheel").get("pricing").get("pricingContextId"),
            product.get("relatedParts"),
            product.get("frontWheel").get("pricing").get("listPrice"),
            0,
            false,
            false,
            "Part Detail"
          );
        }
        break;
      case ProductTypes.ACCESSORIES:
        shoppingCartActions.addItemToShoppingCart(
          product.get("id"),
          product.get("name").toJS(),
          product.get("image"),
          product.get("pricing").get("displayPrice"),
          product.get("pricing").get("privatePrice"),
          product.get("pricing").get("promotionPricingText"),
          parseInt(product.get("quantity").get("value"), 10),
          ProductTypes.ACCESSORIES,
          product,
          0,
          product.get("pricing").get("pricingContextId"),
          product.get("relatedParts"),
          product.get("pricing").get("displayLabour"),
          product.get("pricing").get("listPrice"),
          false,
          false,
          "Part Detail"
        );
        break;
      case ProductTypes.PACKAGES:
        shoppingCartActions.addItemToShoppingCart(
          product.get("id"),
          {
            en: product.get("name"),
            fr: product.get("name"),
          },
          product.get("smallImage"),
          product.get("pricing").get("displayPrice"),
          product.get("pricing").get("privatePrice"),
          product.get("pricing").get("promotionPricingText"),
          parseInt(product.get("quantity").get("value"), 10),
          ProductTypes.PACKAGES,
          product,
          0,
          product.get("pricing").get("pricingContextId"),
          product.get("relatedParts"),
          0,
          product.get("pricing").get("listPrice"),
          false,
          false,
          "Part Detail"
        );
        break;
    }
  };

  onAddRequiredItemToCart = (requiredItem) => {
    shoppingCartActions.addItemToShoppingCart(
      requiredItem.get("id"),
      requiredItem.get("name"),
      requiredItem.get("image"),
      requiredItem.getIn(["price", "displayPrice"]),
      requiredItem.getIn(["price", "privatePrice"]),
      requiredItem.getIn(["price", "promotionPricingText"]),
      parseInt(requiredItem.getIn(["quantity", "value"]), 10),
      requiredItem.get("type"),
      requiredItem,
      null,
      requiredItem.getIn("quantity", "pricingContextId"),
      Immutable.List.of(),
      this.state.productType == ProductTypes.ACCESSORIES
        ? requiredItem.getIn(["price", "displayPrice"])
        : 0,
      0,
      false,
      false,
      "Product Detail Required Item"
    );
  };

  onShowImage = () => {
    this.setState({
      showingImage: true,
      showingAltImage: false,
    });
  };

  onShowAlternativeImage = () => {
    this.setState({
      showingAltImage: true,
      showingImage: false,
    });
  };

  onRequestCloseModal = () => {
    this.setState({
      showingImage: false,
      showingAltImage: false,
    });
  };

  renderPricingContext = () => {
    switch (this.state.productType) {
      case ProductTypes.TIRES:
        return renderTirePricing(
          this.state.detailForCart,
          this.onChangeProductQuantity
        );
      case ProductTypes.ALLOY_WHEELS:
        return renderWheelPricing(
          this.state.detailForCart,
          this.onChangeProductQuantity
        );
      case ProductTypes.ACCESSORIES:
        return renderAccessoryPricing(
          this.state.detailForCart,
          this.onChangeProductQuantity
        );
      case ProductTypes.PACKAGES:
        return renderPackagePricing(
          this.state.detailForCart,
          this.onChangeProductQuantity
        );
    }
  };

  onChangeProductQuantity = (quantity) => {
    var payload = {
      id: this.state.detail.getIn(["primary", "id"]),
      quantity: quantity,
    };
    switch (this.state.productType) {
      case ProductTypes.TIRES:
        payload.front = !this.state.detail.get("staggered");
        this.props.dispatch(changeCartDetailTireQuantity(payload));
        // productDetailActions.changeCartDetailTireQuantity(payload);
        break;
      case ProductTypes.ALLOY_WHEELS:
        payload.front = !this.state.detail.get("staggered");
        this.props.dispatch(changeCartDetailWheelQuantity(payload));
        // productDetailActions.changeCartDetailWheelQuantity(payload);
        break;
      case ProductTypes.ACCESSORIES:
        this.props.dispatch(changeCartDetailQuantity(payload));
        // productDetailActions.changeCartDetailQuantity(payload);
        break;
      case ProductTypes.PACKAGES:
        this.props.dispatch(changeCartDetailQuantity(payload));
        // productDetailActions.changeCartDetailQuantity(payload);
        break;
    }
  };

  renderImage = () => {
    var imageAlt = this.state.detail.getIn(["primary", "partNumber"])
      ? this.state.detail.getIn(["primary", "partNumber"])
      : this.state.detail.getIn(["primary", "nationalPartNumber"]);
    if (this.state.productType === ProductTypes.ALLOY_WHEELS) {
      return (
        <div className="product-section product-image">
          <div className="image-cont">
            <div className="image-cont-outer">
              <div
                className="image-cont-inner"
                onClick={this.onShowAlternativeImage}
              >
                <Image
                  src={this.state.detail.getIn([
                    "productSpecific",
                    "vehicleImages",
                    this.state.colour,
                  ])}
                  alt="Image Vehicle And Wheel"
                />
              </div>
            </div>
            <ComboBox
              onChange={this.onChangeColour}
              value={this.state.colour}
              className="colour-combo"
            >
              {this.state.vehicleColours
                .map(
                  function (colour) {
                    return (
                      <ComboBoxValue
                        key={colour.get("code")}
                        value={colour.get("code")}
                      >
                        <Image
                          src={colour.get("image")}
                          alt="Image Color Code"
                        />
                      </ComboBoxValue>
                    );
                  }.bind(this)
                )
                .toArray()}
            </ComboBox>
          </div>
          <ul className="multiple-images">
            <li>
              <div className="image-cont">
                <div className="image-cont-outer">
                  <div className="image-cont-inner" onClick={this.onShowImage}>
                    <Image
                      src={this.state.detail.get("image")}
                      alt={"Image Part " + imageAlt}
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      );
    } else {
      return (
        <div className="product-section product-image">
          <div className="image-cont">
            <div className="image-cont-outer">
              <div className="image-cont-inner" onClick={this.onShowImage}>
                <Image
                  src={this.state.detail.get("image")}
                  alt={"Image Part " + imageAlt}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  renderNationalPartNumber = (nationalPartNumber) => {
    if (nationalPartNumber) {
      return (
        <tr>
          <th>{this.t("product.nationalPartNumber")}:</th>
          <td className="part-number">{nationalPartNumber}</td>
        </tr>
      );
    } else {
      return <tr></tr>;
    }
  };

  renderProductSpecific = () => {
    switch (this.state.productType) {
      case ProductTypes.TIRES:
        if (this.state.detail.get("staggered")) {
          return (
            <div className="product-section product-info">
              <h2>{this.t("product.frontTire")}</h2>
              <table>
                <tbody>
                  <tr>
                    <th>{this.t("product.partNumber")}:</th>
                    <td className="part-number">
                      {this.state.detail.getIn(["primary", "partNumber"])}
                    </td>
                  </tr>
                  {this.renderNationalPartNumber(
                    this.state.detail.getIn(["primary", "nationalPartNumber"])
                  )}
                  <tr>
                    <th>{this.t("product.tireSize")}:</th>
                    <td>{this.state.detail.getIn(["primary", "tireSize"])}</td>
                  </tr>
                  <tr>
                    <th>{this.t("product.loadSpeed")}:</th>
                    <td>
                      {this.state.detail.getIn(["primary", "loadRating"])} /{" "}
                      {this.state.detail.getIn(["primary", "speedRating"])}
                    </td>
                  </tr>
                </tbody>
              </table>
              <h2>{this.t("product.rearTire")}</h2>
              <table>
                <tbody>
                  <tr>
                    <th>{this.t("product.partNumber")}:</th>
                    <td className="part-number">
                      {this.state.detail.getIn(["secondary", "partNumber"])}
                    </td>
                  </tr>
                  {this.renderNationalPartNumber(
                    this.state.detail.getIn(["secondary", "nationalPartNumber"])
                  )}
                  <tr>
                    <th>{this.t("product.tireSize")}:</th>
                    <td>
                      {this.state.detail.getIn(["secondary", "tireSize"])}
                    </td>
                  </tr>
                  <tr>
                    <th>{this.t("product.loadSpeed")}:</th>
                    <td>
                      {this.state.detail.getIn(["secondary", "loadRating"])} /{" "}
                      {this.state.detail.getIn(["secondary", "speedRating"])}
                    </td>
                  </tr>
                  {this.state.detail.get("primary").get("manufacturerUrl") !==
                  "" ? (
                    <tr>
                      <th>{this.t("product.manufacturerWebsite")}:</th>
                      <td>
                        <a
                          href={this.state.detail
                            .get("primary")
                            .get("manufacturerUrl")}
                          target="_blank"
                        >
                          {this.t("product.manufacturerWebsiteLink")}
                        </a>
                      </td>
                    </tr>
                  ) : (
                    false
                  )}
                </tbody>
              </table>
            </div>
          );
        } else {
          return (
            <div className="product-section product-info">
              <h2>{this.t("product.partDetails")}</h2>
              <table>
                <tbody>
                  <tr>
                    <th>{this.t("product.partNumber")}:</th>
                    <td className="part-number">
                      {this.state.detail.getIn(["primary", "partNumber"])}
                    </td>
                  </tr>
                  {this.renderNationalPartNumber(
                    this.state.detail.getIn(["primary", "nationalPartNumber"])
                  )}
                  <tr>
                    <th>{this.t("product.tireSize")}:</th>
                    <td>{this.state.detail.getIn(["primary", "tireSize"])}</td>
                  </tr>
                  <tr>
                    <th>{this.t("product.loadSpeed")}:</th>
                    <td>
                      {this.state.detail.getIn(["primary", "loadRating"])} /{" "}
                      {this.state.detail.getIn(["primary", "speedRating"])}
                    </td>
                  </tr>
                  {this.state.detail.get("primary").get("manufacturerUrl") !==
                  "" ? (
                    <tr>
                      <th>{this.t("product.manufacturerWebsite")}:</th>
                      <td>
                        <a
                          href={this.state.detail
                            .get("primary")
                            .get("manufacturerUrl")}
                          target="_blank"
                        >
                          {this.t("product.manufacturerWebsiteLink")}
                        </a>
                      </td>
                    </tr>
                  ) : (
                    false
                  )}
                </tbody>
              </table>
            </div>
          );
        }
      case ProductTypes.ALLOY_WHEELS:
        if (this.state.detail.get("staggered")) {
          return (
            <div className="product-section product-info">
              <h2>{this.t("product.frontWheel")}</h2>
              <table>
                <tbody>
                  <tr>
                    <th>{this.t("product.partNumber")}:</th>
                    <td className="part-number">
                      {this.state.detail.getIn(["primary", "partNumber"])}
                    </td>
                  </tr>
                  {this.renderNationalPartNumber(
                    this.state.detail.getIn(["primary", "nationalPartNumber"])
                  )}
                  <tr>
                    <th>{this.t("product.wheelDiameter")}:</th>
                    <td>
                      {this.state.detail.getIn(["primary", "wheelDiameter"])}
                    </td>
                  </tr>
                  <tr>
                    <th>{this.t("product.wheelWidth")}:</th>
                    <td>
                      {this.state.detail.getIn(["primary", "wheelWidth"])}
                    </td>
                  </tr>
                  <tr>
                    <th>{this.t("product.wheelOffset")}:</th>
                    <td>
                      {this.state.detail.getIn(["primary", "wheelOffset"])}
                    </td>
                  </tr>
                  <tr>
                    <th>{this.t("product.wheelColour")}:</th>
                    <td>
                      {this.state.detail.getIn(["primary", "wheelColour"])}
                    </td>
                  </tr>
                  {!applicationStore.data.enableAudiFeatures &&
                  !applicationStore.data.enableVolkswagenFeatures ? (
                    <tr>
                      <th>{this.t("product.wheelAxlePartner")}:</th>
                      <td>
                        {this.state.detail.getIn([
                          "primary",
                          "wheelAxlePartner",
                        ])}
                      </td>
                    </tr>
                  ) : (
                    false
                  )}
                  {!applicationStore.data.enableAudiFeatures &&
                  !applicationStore.data.enableVolkswagenFeatures ? (
                    <tr>
                      <th>{this.t("product.wheelComment")}:</th>
                      <td>
                        {this.state.detail.getIn(["primary", "wheelComment"])}
                      </td>
                    </tr>
                  ) : (
                    false
                  )}
                </tbody>
              </table>
              <h2>{this.t("product.rearWheel")}</h2>
              <table>
                <tbody>
                  <tr>
                    <th>{this.t("product.partNumber")}:</th>
                    <td className="part-number">
                      {this.state.detail.getIn(["secondary", "partNumber"])}
                    </td>
                  </tr>
                  {this.renderNationalPartNumber(
                    this.state.detail.getIn(["secondary", "nationalPartNumber"])
                  )}
                  <tr>
                    <th>{this.t("product.wheelDiameter")}:</th>
                    <td>
                      {this.state.detail.getIn(["secondary", "wheelDiameter"])}
                    </td>
                  </tr>
                  <tr>
                    <th>{this.t("product.wheelWidth")}:</th>
                    <td>
                      {this.state.detail.getIn(["secondary", "wheelWidth"])}
                    </td>
                  </tr>
                  <tr>
                    <th>{this.t("product.wheelOffset")}:</th>
                    <td>
                      {this.state.detail.getIn(["secondary", "wheelOffset"])}
                    </td>
                  </tr>
                  <tr>
                    <th>{this.t("product.wheelColour")}:</th>
                    <td>
                      {this.state.detail.getIn(["secondary", "wheelColour"])}
                    </td>
                  </tr>
                  <tr>
                    <th>{this.t("product.wheelAxlePartner")}:</th>
                    <td>
                      {this.state.detail.getIn([
                        "secondary",
                        "wheelAxlePartner",
                      ])}
                    </td>
                  </tr>
                  <tr>
                    <th>{this.t("product.wheelComment")}:</th>
                    <td>
                      {this.state.detail.getIn(["secondary", "wheelComment"])}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        } else {
          return (
            <div className="product-section product-info">
              <h2>{this.t("product.partDetails")}</h2>
              <table>
                <tbody>
                  <tr>
                    <th>{this.t("product.partNumber")}:</th>
                    <td className="part-number">
                      {this.state.detail.getIn(["primary", "partNumber"])}
                    </td>
                  </tr>
                  {this.renderNationalPartNumber(
                    this.state.detail.getIn(["primary", "nationalPartNumber"])
                  )}
                  <tr>
                    <th>{this.t("product.wheelDiameter")}:</th>
                    <td>
                      {this.state.detail.getIn(["primary", "wheelDiameter"])}
                    </td>
                  </tr>
                  <tr>
                    <th>{this.t("product.wheelWidth")}:</th>
                    <td>
                      {this.state.detail.getIn(["primary", "wheelWidth"])}
                    </td>
                  </tr>
                  <tr>
                    <th>{this.t("product.wheelOffset")}:</th>
                    <td>
                      {this.state.detail.getIn(["primary", "wheelOffset"])}
                    </td>
                  </tr>
                  <tr>
                    <th>{this.t("product.wheelColour")}:</th>
                    <td>
                      {this.state.detail.getIn(["primary", "wheelColour"])}
                    </td>
                  </tr>
                  <tr>
                    <th>{this.t("product.wheelAxlePartner")}:</th>
                    <td>
                      {this.state.detail.getIn(["primary", "wheelAxlePartner"])}
                    </td>
                  </tr>
                  <tr>
                    <th>{this.t("product.wheelComment")}:</th>
                    <td>
                      {this.state.detail.getIn(["primary", "wheelComment"])}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        }
      case ProductTypes.ACCESSORIES:
        var categories = this.state.detail.getIn([
          "primary",
          "accessoryCategories",
        ]);
        if (typeof categories.get(0) !== "undefined")
          categories = categories.get(0);
        return (
          <div className="product-section product-info">
            <h2>{this.t("product.partDetails")}</h2>
            <table>
              <tbody>
                <tr>
                  <th>{this.t("product.partNumber")}:</th>
                  <td className="part-number">
                    {this.state.detail.getIn(["primary", "partNumber"])}
                  </td>
                </tr>
                {this.renderNationalPartNumber(
                  this.state.detail.getIn(["primary", "nationalPartNumber"])
                )}
                <tr>
                  <th>{this.t("product.requiresInstallation")}:</th>
                  <td>
                    {this.state.detail.getIn([
                      "primary",
                      "requiresInstallation",
                    ])
                      ? this.t("common.yes")
                      : this.t("common.no")}
                  </td>
                </tr>
                <tr>
                  <th>{this.t("product.categories")}:</th>
                  <td>{categories.get(languageStore.data.lang)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      case ProductTypes.PACKAGES:
        return (
          <div className="product-section product-info">
            <h2>{this.t("product.partDetails")}</h2>
            <table>
              <tbody>
                <tr>
                  <th>{this.t("product.partNumber")}:</th>
                  <td>{this.state.detail.getIn(["primary", "partNumber"])}</td>
                </tr>
              </tbody>
            </table>
            {this.renderPackageItems()}
          </div>
        );
      case ProductTypes.COLLECTIONS:
        return (
          <div className="product-section product-info">
            <h2>{this.t("product.partDetails")}</h2>
            <table>
              <tbody>
                <tr>
                  <th>{this.t("product.partNumber")}:</th>
                  <td>{this.state.detail.getIn(["primary", "partNumber"])}</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      default:
        return false;
    }
  };

  renderPackageItems = () => {
    if (sizeOf(this.state.detail.getIn(["primary", "packageItems"])) > 0) {
      return (
        <div>
          <h3>{this.t("product.packageItems")}</h3>
          <ul>
            {this.state.detail
              .getIn(["primary", "packageItems"])
              .map((item) => {
                return (
                  <li className="article-package-items">
                    {item.get("quantity")} - {this.td(item.get("title").toJS())}{" "}
                    {item.get("partNumber")
                      ? "(" + item.get("partNumber") + ")"
                      : ""}
                  </li>
                );
              })}
            {sizeOf(this.state.detail.getIn(["primary", "packageLineItem"])) > 0
              ? this.state.detail
                  .getIn(["primary", "packageLineItem"])
                  .map((item) => {
                    return (
                      <li className="article-package-items">
                        {item.get("qty")} - {this.td(item.get("title").toJS())}
                      </li>
                    );
                  })
              : false}
          </ul>
        </div>
      );
    } else {
      return false;
    }
  };

  renderSideRequiredItems = () => {
    if (this.state.detail.get("required").count() > 0) {
      var priceLabel = this.t("product.retailPrice");
      if (
        applicationStore.data.dealerSite &&
        applicationStore.data.dealerSellingPriceLabel[this.state.productType][
          getAppLanguage()
        ]
      ) {
        priceLabel =
          applicationStore.data.dealerSellingPriceLabel[this.state.productType][
            getAppLanguage()
          ];
      }
      return (
        <div className="controls-required">
          <h3>{this.t("product.requiredItems")}</h3>
          <ul>
            {this.state.detail.get("required").map((requiredItem, index) => {
              var showMsrp = applicationStore.data.dealerShowMsrp;
              var msrp = parseFloat(requiredItem.getIn(["price", "listPrice"]));
              var dealerPrice = parseFloat(
                requiredItem.getIn(["price", "privatePrice"])
              );
              var installation =
                this.state.productType == ProductTypes.ACCESSORIES
                  ? parseFloat(requiredItem.getIn(["price", "displayLabour"]))
                  : 0;
              var savings =
                (msrp - dealerPrice) *
                requiredItem.getIn(["quantity", "value"]);
              var total =
                dealerPrice * requiredItem.getIn(["quantity", "value"]) +
                installation * requiredItem.getIn(["quantity", "value"]);
              return (
                <li>
                  <div>
                    <div className="item-image">
                      <div className="image-cont">
                        <div className="image-cont-outer">
                          <div className="image-cont-inner">
                            <Image
                              src={requiredItem.get("image")}
                              alt="Image Required Item"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="item-title"
                      title={this.td(requiredItem.get("name").toJS())}
                    >
                      {this.td(requiredItem.get("name").toJS())}
                      {this.td(requiredItem.get("relatedPartText").toJS())}
                      {applicationStore.data.useCartReviewPage ? (
                        <button
                          onClick={this.onAddRequiredItemToCart.bind(
                            this,
                            requiredItem
                          )}
                          className="btn"
                        >
                          {this.t("product.addToWishlist")}
                        </button>
                      ) : (
                        <button
                          onClick={this.onAddRequiredItemToCart.bind(
                            this,
                            requiredItem
                          )}
                          className="btn"
                        >
                          {this.t("product.addToQuote")}
                        </button>
                      )}
                    </div>
                    <div className="item-price">
                      <div className="product-pricing-body">
                        <ul>
                          {showMsrp && msrp > dealerPrice ? (
                            <li>
                              <span className="pricing-title">
                                {this.t("product.msrp") + ":"}
                              </span>
                              <span className="pricing-value">
                                {this.c(msrp)}
                              </span>
                            </li>
                          ) : (
                            false
                          )}
                          {showMsrp && msrp > dealerPrice ? <br /> : false}
                          <li>
                            <span className="pricing-title">
                              {priceLabel + ":"}
                            </span>
                            <span className="pricing-value">
                              {this.c(dealerPrice)}
                            </span>
                          </li>
                          {installation > 0 ? <br /> : false}
                          {installation > 0 ? (
                            <li>
                              <span className="pricing-title">
                                {this.t("product.installation") + ":"}
                              </span>
                              <span className="pricing-value">
                                {this.c(installation)}
                              </span>
                            </li>
                          ) : (
                            false
                          )}
                          {installation > 0 ? <li></li> : false}
                          <li className="total-li">
                            <div className="div-total-hr"></div>
                            <span className="total-title">
                              {this.t("product.total") + ":"}
                            </span>
                            <span className="total-value">{this.c(total)}</span>
                          </li>
                          {showMsrp && savings > 0 ? (
                            <li className="savings-li">
                              <span className="total-title">
                                {this.t("product.youSave") + ":"}
                              </span>
                              <span className="total-value">
                                {this.c(savings)}
                              </span>
                            </li>
                          ) : (
                            false
                          )}
                          {installation > 0 ? (
                            <li className="installation-li">
                              <span className="total-title">
                                {this.t("product.installationIncluded")}
                              </span>
                            </li>
                          ) : (
                            false
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="item-quantity">
                      <ComboBox
                        value={requiredItem.get("quantity")}
                        onChange={this.onChangeRequiredItemQuantity.bind(
                          this,
                          index
                        )}
                      >
                        {requiredItem
                          .get("quantities")
                          .map((quantity) => {
                            return (
                              <ComboBoxValue key={quantity} value={quantity}>
                                {this.td(quantity.get("title").toJS())} &times;
                              </ComboBoxValue>
                            );
                          })
                          .toArray()}
                      </ComboBox>
                    </div>
                    <div className="item-hr">
                      <hr />
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      );
    } else {
      return false;
    }
  };

  renderRequiredItems = () => {
    if (this.state.detail.get("required").count() > 0) {
      return (
        <div className="product-section product-required">
          <h2>{this.t("product.requiredItems")}</h2>
          <ul>
            {this.state.detail.get("required").map((requiredItem, index) => {
              return (
                <li>
                  <div className="image-cont">
                    <div className="image-cont-outer">
                      <div className="image-cont-inner">
                        <Image
                          src={requiredItem.get("image")}
                          alt="Image Required Item"
                        />
                      </div>
                    </div>
                  </div>
                  <h3>{this.td(requiredItem.get("relatedPartText").toJS())}</h3>
                  <p>{this.td(requiredItem.get("name").toJS())}</p>
                </li>
              );
            })}
          </ul>
        </div>
      );
    } else {
      return false;
    }
  };

  renderRelatedItems = () => {
    return false;
  };

  renderAppointmentForm = () => {
    if (this.state.detail)
      return (
        <AppointmentForm
          itemId={this.state.detail.getIn(["primary", "id"])}
          itemType={this.state.productType}
        />
      );
    else return false;
  };

  renderDescription = () => {
    var description = this.td(this.state.detail.get("description").toJS());
    if (this.td(this.state.detail.get("description").toJS())) {
      return (
        <div className="product-section product-description">
          <h2>{this.t("product.description")}</h2>
          <p dangerouslySetInnerHTML={{ __html: description }}></p>
        </div>
      );
    } else {
      return false;
    }
  };

  renderWarranty = () => {
    if (this.state.productType == ProductTypes.TIRES) {
      var itemWarranty = null;
      if (this.state.warrantyList && this.state.warrantyList.length > 0) {
        this.state.warrantyList.forEach(function (warranty) {
          if (
            applicationStore.data.dealerInfo &&
            applicationStore.data.dealerInfo.country &&
            getAppLanguage()
          ) {
            if (
              warranty.warrantyCountryCode &&
              String(warranty.warrantyCountryCode).toUpperCase() ==
                applicationStore.data.dealerInfo.country &&
              warranty.warrantyLanguageCode &&
              String(warranty.warrantyLanguageCode).toLowerCase() ==
                getAppLanguage()
            ) {
              itemWarranty = warranty.warrantyText;
            }
          }
        });
      }

      itemWarranty = itemWarranty
        ? itemWarranty
        : this.t("product.warrantyMissing");

      return (
        <div className="product-section product-warranty">
          <h2>{this.t("product.warranty")}</h2>
          <p dangerouslySetInnerHTML={{ __html: itemWarranty }}></p>
        </div>
      );
    } else {
      return false;
    }
  };

  renderModal = () => {
    if (this.state.showingImage || this.state.showingAltImage) {
      var leftArrowClass = Cx({
        fa: true,
        "fa-angle-left": true,
        disabled:
          this.state.showingAltImage ||
          this.state.productType !== ProductTypes.ALLOY_WHEELS,
      });

      var rightArrowClass = Cx({
        fa: true,
        "fa-angle-right": true,
        disabled:
          this.state.showingImage ||
          this.state.productType !== ProductTypes.ALLOY_WHEELS,
      });

      return (
        <Modal requestUnmount={this.onRequestCloseModal}>
          <div className="wrapper lightbox">
            <a
              className={leftArrowClass}
              onClick={this.onShowAlternativeImage}
            ></a>
            <a className={rightArrowClass} onClick={this.onShowImage}></a>
            <div className="modal-content" ref="lightbox">
              <Image
                src={
                  this.state.showingImage
                    ? this.state.detail.get("image")
                    : this.state.detail.getIn([
                        "productSpecific",
                        "vehicleImages",
                        this.state.colour,
                      ])
                }
                ref="lightboxImage"
                alt="Image Vehicle"
              />
            </div>
            <a className="btn-close" onClick={this.onRequestCloseModal}>
              <i className="fa fa-close"></i>
            </a>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  interposeWithValue = (values, value) => {
    if (values.count() > 1) {
      return values.interpose(value);
    } else {
      return values;
    }
  };

  renderOptions = () => {
    if (this.state.detail.getIn(["primary", "optionLabels"]).count() > 0) {
      return (
        <div className="controls-options">
          <h2>{this.t("product.options")}</h2>
          {this.interposeWithValue(
            this.state.detail.getIn(["primary", "optionLabels"]),
            Immutable.fromJS({ en: " & ", fr: " & " })
          )
            .map(
              function (label) {
                return <span>{this.td(label.toJS())}</span>;
              }.bind(this)
            )
            .toJS()}
          :&nbsp;
          <ComboBox
            value={
              this.state.option ||
              this.state.detail.getIn(["primary", "option"])
            }
            onChange={this.onChangeOption}
          >
            {this.state.detail
              .getIn(["primary", "optionValues"])
              .map(
                function (value) {
                  return (
                    <ComboBoxValue
                      key={value.get("id")}
                      value={value.get("id")}
                    >
                      {this.interposeWithValue(
                        value.get("values"),
                        Immutable.fromJS({ en: " & ", fr: " & " })
                      )
                        .map((v) => {
                          return <span>{this.td(v.toJS())}</span>;
                        })
                        .toArray()}
                    </ComboBoxValue>
                  );
                }.bind(this)
              )
              .toArray()}
          </ComboBox>
        </div>
      );
    } else {
      return false;
    }
  };

  renderAddToCart = () => {
    //If the product has pricing and is in stock, or if the products are staggered and both products have pricing and are in stock.
    var buttonText = "";
    if (applicationStore.data.useCartReviewPage) {
      buttonText = this.t("product.addToWishlist");
    } else {
      buttonText = this.t("product.addToQuote");
    }
    var hidePricing =
      applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) &&
      !isUserLoggedIn();
    var showPricing = !hidePricing
      ? this.state.detail.getIn(["primary", "hasPricing"]) &&
        this.state.detail.getIn(["primary", "hasStock"])
      : false;
    if (this.state.detail.get("staggered")) {
      showPricing = !hidePricing
        ? this.state.detail.getIn(["primary", "hasPricing"]) &&
          this.state.detail.getIn(["primary", "hasStock"]) &&
          this.state.detail.getIn(["secondary", "hasPricing"]) &&
          this.state.detail.getIn(["secondary", "hasStock"])
        : false;
    }
    if (showPricing) {
      return (
        <div className="controls-add-to-cart">
          <button className="btn" onClick={this.onAddToBasket}>
            {buttonText}
          </button>
        </div>
      );
    } else {
      return false;
    }
  };

  renderAddToBasket = () => {
    var product = this.state.detailForCart;
    switch (this.state.productType) {
      case ProductTypes.TIRES:
        //Collect Stock
        var stockList = product.get("frontTire").get("stock"),
          stockTotal = 0;
        if (stockList)
          stockList.map(function (stock) {
            stockTotal += parseInt(stock.get("stockQty"));
          });

        //TBC Stock
        var inStock =
          (!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) ||
            isUserLoggedIn()) &&
          (this.state.stockQueryEmpty
            ? this.state.stockQueryEmpty.indexOf(
                product.get("frontTire").get("id")
              ) === -1
            : true) &&
          (product.get("frontTire").get("hasStock") ||
            applicationStore.data.dealerIgnoreTireStock);

        //Add To Cart
        var hasAddToCart =
          product.get("frontTire").get("hasPricing") &&
          product.get("frontTire").get("pricing").get("displayPrice") > 0 &&
          (!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) ||
            isUserLoggedIn()) &&
          (inStock || stockTotal > 0) &&
          !this.props.ntdOrderButton;

        //Order Tire
        var hasNtdOrder = product.get("frontTire").get("supportDealerOrder");

        //Purchase Tire
        var hasNtdPurchase =
          !hasNtdOrder &&
          hasAddToCart &&
          product.get("frontTire").get("supportConsumerOrder");

        return (
          <div className="tire-actions tire-actions-desktop">
            <ul>
              {hasNtdOrder
                ? renderNTDButton(
                    this.onAddToBasket.bind(this, product, "order"),
                    "order",
                    true
                  )
                : false}
              {hasNtdPurchase
                ? renderNTDButton(
                    this.onAddToBasket.bind(this, product, "purchase"),
                    "purchase",
                    true
                  )
                : false}
              {hasAddToCart &&
              !hasNtdOrder &&
              !hasNtdPurchase &&
              (inStock || stockTotal > 0) ? (
                <li>
                  <a
                    className="btn"
                    role="button"
                    tabIndex={getTabIndex()}
                    aria-label={this.t("product.addToWishlist")}
                    onClick={this.onAddToBasket.bind(this, product, "")}
                  >
                    {this.t("product.addToWishlist")}
                  </a>
                </li>
              ) : (
                false
              )}
            </ul>
          </div>
        );
      case ProductTypes.ALLOY_WHEELS:
        //Add To Cart
        var hasAddToCart =
          product.get("frontWheel").get("hasPricing") &&
          product.get("frontWheel").get("pricing").get("displayPrice") > 0 &&
          (!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) ||
            isUserLoggedIn());

        var addToCartClass = Cx({
          btn: true,
          french:
            getAppLanguage() === "fr" &&
            applicationStore.data.enableAudiFeatures,
        });

        var _this = this;

        return (
          <div className="wheel-actions">
            <ul>
              {hasAddToCart ? (
                <li>
                  <a
                    className={addToCartClass}
                    role="button"
                    tabIndex={getTabIndex()}
                    aria-label={_this.t("product.addToWishlist")}
                    onClick={_this.onAddToBasket.bind(_this, product)}
                  >
                    {_this.t("product.addToWishlist")}
                  </a>
                </li>
              ) : (
                false
              )}
            </ul>
          </div>
        );
      case ProductTypes.ACCESSORIES:
      case ProductTypes.PACKAGES:
        //Add To Cart
        var hasAddToCart =
          product.get("hasPricing") &&
          product.get("pricing").get("displayPrice") > 0 &&
          (!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) ||
            isUserLoggedIn());

        var addToCartClass = Cx({
          btn: true,
          french:
            getAppLanguage() === "fr" &&
            applicationStore.data.enableAudiFeatures,
        });

        var _this = this;

        return (
          <div className="accessory-actions">
            <ul>
              {hasAddToCart ? (
                <li>
                  <a
                    className={addToCartClass}
                    role="button"
                    tabIndex={getTabIndex()}
                    aria-label={_this.t("product.addToWishlist")}
                    onClick={_this.onAddToBasket.bind(_this, product)}
                  >
                    {_this.t("product.addToWishlist")}
                  </a>
                </li>
              ) : (
                false
              )}
            </ul>
          </div>
        );
    }
  };

  render() {
    if (this.state.detail && this.state.detail.has("title")) {
      var productClass = Cx({
        "product-section": true,
        "product-controls": true,
        tire: this.state.productType === ProductTypes.TIRES,
        wheel: this.state.productType === ProductTypes.ALLOY_WHEELS,
        accessory: this.state.productType === ProductTypes.ACCESSORIES,
        package: this.state.productType === ProductTypes.PACKAGES,
      });
      var title = this.state.detail.get("title").get(getAppLanguage());
      if (this.state.productType === ProductTypes.PACKAGES) {
        switch (applicationStore.data.defaultTemplate) {
          case "mercedes":
          case "volkswagen":
            title = this.state.detail.get("dynamicTitle").get(getAppLanguage());
            break;
          default:
            break;
        }
      }
      return (
        <div className="container product-detail">
          <h1>{title}</h1>
          {this.renderImage()}
          {this.renderProductSpecific()}

          <div className={productClass}>
            {this.renderOptions()}
            {this.renderPricingContext()}
            {this.renderAddToBasket()}
            {this.renderAppointmentForm()}
            {this.renderSideRequiredItems()}
          </div>

          {this.renderDescription()}
          {this.renderWarranty()}
          {this.renderRelatedItems()}
          {this.renderModal()}
        </div>
      );
    } else {
      return false;
    }
  }

  onChange = () => {
    this.setState({
      detail: productDetailStore.data.detail,
      detailForCart: productDetailStore.data.detailForCart,
      productType: productDetailStore.data.productType,
      colour: productListStore.data.colourCodeInDetail,
      vehicleColours: productListStore.data.colourCodes,
      warrantyList: productDetailStore.data.warrantyList,
    });
  };
}

function mapStateToProps(state) {
  return {
    state,
  };
}
export default connect(mapStateToProps)(ProductDetail);

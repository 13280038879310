import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApiBaseUrl } from "../../api/ApiService";
import { getOrderIdentifier } from "../../service/OrderService";
import {
  checkArrayFieldsExist,
  getAppDealerId,
  getAppLanguage,
  getRouterState,
  isEmpty,
  sizeOf
} from "../../service/UtilityService";
import { dealerId } from "./applicationInfo";
import baseInstance from '../features/api/api';
import { getDestination, getDestinationInverse } from "../../api/RequestBuilderHelper";
import { accessoryMappingFunction, getPricingFromQuantityAndDetailsRefactored, packageMappingFunction, tireMappingFunction, wheelMappingFunction } from "../../service/ProductList";
import { ProductTypes } from "../../constants/ProductTypes";
import {shoppingCartViewLoaded, transformShoppingCartViewItemsFromApi} from "../../helpers/ShoppingCartHelpers";
import store from "../../stores/configureStore";
import { shoppingCartViewStore } from "../../stores/ShoppingCartViewStore";

// function removeFromCart(cart, itemId) {
//   const index = cart.findIndex(item => item.id === itemId);
//   if (index !== -1) {
//     const updatedCart = [...cart];
//     updatedCart.splice(index, 1);
//     return updatedCart;
//   }
//   return cart;
// }


function getPartManufacturer(part) {
    switch(part.get('partSummary').get('primaryPart').get('itemType')) {
      case "TRE":
        return part.get('partDetails').get('primaryPart').get('tireManufacturerName');
      case "WHL":
        return part.get('partDetails').get('primaryPart').get('wheelManufacturerName');
      case "ACC":
        return part.get('partDetails').get('primaryPart').get('accessoryManufacturerName');
      case "PKG":
        return part.get('partDetails').get('primaryPart').get('packageManufacturerName');
    }
  } 

  function wait(ms) {
      //allows us to set a timeout within async function
    return new Promise(r => setTimeout(r, ms));
  }
export const generateFoxyCartFromCart = createAsyncThunk(
    'shoppingCart/generateFoxyCart',
    async(arg,{getState})=>{
        return fetch(`${getApiBaseUrl()}/retailWebService/shopping/thirdParty/generateFoxyCartFromCart.php`,{
            dealerId:getAppDealerId(),
            cartId:getOrderIdentifier(),
            orderNumber:getOrderIdentifier(),
            customerLanguage:getAppLanguage(),
            isNational:1,

        })
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText)
                return res.json()
            }
        })
        .then(json => json)
    }
)

export const loadShoppingCartView = createAsyncThunk(
    'shoppingCart/loadShoppingCart',
    async(arg,{getState})=>{
        return fetch(`${getApiBaseUrl()}/retailWebService/shopping/cart/getCartContents.php`,{
            dealerId:dealerId(),
            cartId:getOrderIdentifier(),
            orderNumber:getOrderIdentifier(),
            markAsQuote:false,
            isNational:1
        })
        .then(res =>{
            if(!res.ok){
                throw Error(res.statusText)
                return res.json()
            }
        })
        .then(json => json)
    }
)

export const loadWishlistReview = createAsyncThunk(
    'shoppingCart/loadWishList',
    async(arg,{getState})=>{
        let {tokenId} = getState().info;
        return fetch(`${getApiBaseUrl()}/retailWebService/shopping/cart/getCartContents.php`,{
            dealerId:getAppDealerId(),
            cartId:getOrderIdentifier(),
            orderNumber:getOrderIdentifier(),
            markAsQuote:false,
            isNational:1,
            tokenId:tokenId
        })
    }
)
export const loadWishListPrint = createAsyncThunk(
    'shoppingCart/loadWishListPrint',
    async(arg,{getState})=>{
        let {tokenId} = getState.info();
        return baseInstance.get('/shopping/cart/getCartContents.php',{
            dealerId:getAppDealerId(),
            cartId:getOrderIdentifier(),
            orderNumber:getOrderIdentifier(),
            markAsQuote:true,
            isNational:1
        })
        .then((res)=>{
            if(!res.ok)throw Error(res.statusText)
        })
        .then(res => res.data)
        .catch(err => err)
    }
)

export const loadConsumerOrder = createAsyncThunk(
    'shoppingCart/loadConsumerOrder',
    async(arg,{getState})=>{
        let {tokenId} = getState.info();
        return  baseInstance.get('/shopping/consumerOrder/getConsumerOrder.php',{
            dealerId:getAppDealerId(),
            orderNumber:getOrderIdentifier()
        })
    }
)
export const loadDealerOrder = createAsyncThunk(
    'shoppingCart/loadDealer',
    async(args,{getState})=>{
        let {tokenId} = getState.info();
        return baseInstance.get('/shopping/dealerOrder/getDealerOrder.php',{
            dealerId:getAppDealerId(),
            orderNumber:getOrderIdentifier(),
            isNational: 1,
        })
        .then((res)=>{
            if(!res.ok) throw Error(res.statusText);

        })
        .then( json => json)
    }
)
export const callAddItemToCart = createAsyncThunk(
    'shoppingCart/callAddItemToCart',
    async(args,{getState})=>{
        let {tokenId} = getState.info();
        return baseInstance.get('/shopping/cart/addItemToCart.php',{
            params: {
            dealerId:getAppDealerId(),
            cartId:getOrderIdentifier(),
            itemId:args.id,
            itemType:getDestination(type),
            itemQty:args.quantity, 
            pricingContextId:pricingContextId,
            opitionId:opitionId,
            vehicleId:application
        }
        })
        .then(res => res)
        .catch(e => e)
    }
)

 export const addStaggeredItemToShoppingCart = createAsyncThunk(
     'shoppingCart/addItem',
     async(args,{getState})=>{
         let {tokenId} = getState.info();
         return baseInstance.get('/shopping/cart/getNewCart.php',{
         dealerId:getAppDealerId(),
         isNational:1    
         })
     }
 )
 export const getNewCart = createAsyncThunk(
    'shoppingCart/getNewCart',
    async(args,{getState,rejectWithValue})=>{
        let {tokenId} = getState().info;
        return baseInstance.get('/shopping/cart/getNewCart.php',{
          params: { 
            dealerId:getAppDealerId(),
            isNational: false ? 1 : 0,
            tokenId:tokenId
          }
        })
        .then((response) => {
          return response.data.payload;
        })
        .catch(e =>{
            rejectWithValue(e);
        });
    }
);

 export const addToCart = createAsyncThunk(
     'shoppingCart/addToCart',
     async(args,{getState,dispatch})=>{
         let {cart} = getState().shoppingCart;
         let {quantity,contextId,rearquantity,rearcontextId,index,requiredItemIdArray,openFinancing} = args;
         if (!cart) {
            await dispatch(getNewCart());
               
              if (sizeOf(args.part.get('partSummary').get('secondaryParts')) > 0 && rearquantity > 0) {
                // 0 > 0 && 0 > 0 => false,
                //skip
                // this part.get is checking the size of the object / MAP_SET_USER_LOCATION
      
                var itemId = args.part.get('partSummary').get('primaryPart').get('itemId'),
                  itemType = args.part.get('partSummary').get('primaryPart').get('itemType'),
                  gbb = !isEmpty(args.index) ? 'gbb' + (args.index + 1) : 'nongbb',
                  isApproved = args.part.get('partSummary').get('primaryPart').get('itemOriginalEquipmentFlag') == "1",
                  manufacturer = getPartManufacturer(args.part),
                  partnumber = args.part.get('partSummary').get('primaryPart').get('itemPartNumber'),
                  rearitemId = args.part.get('partSummary').get('secondaryParts').get(0).get('itemId'),
                  rearitemType = args.part.get('partSummary').get('secondaryParts').get(0).get('itemType');
                // var rear = this.addItemToCart.bind(this, rearitemId, rearitemType, rearquantity, rearcontextId, gbb, isApproved, manufacturer, partnumber);
                let rear = dispatch(addItemToCart({itemId: rearitemId, itemType: rearitemType, quantity: rearquantity,rearcontextId:rearcontextId,gbb:gbb,isApproved:isApproved,manufacturer:manufacturer,partnumber:partnumber}));
                    if (isEmpty(requiredItemIdArray)) dispatch(addItemToCart({itemId, itemType, quantity, contextId, gbb, isApproved, manufacturer, partnumber, rear}));
                else dispatch(addItemToCart({itemId, itemType, quantity, contextId, gbb, isApproved, manufacturer, partnumber, requiredItemIdArray, rear}));
      
              } else {
      
                var itemId = args.part.get('partSummary').get('primaryPart').get('itemId'),
                    itemType = args.part.get('partSummary').get('primaryPart').get('itemType'),
                    gbb = !isEmpty(args.index) ? 'gbb' + (args.index + 1) : 'nongbb',
                    isApproved = args.part.get('partSummary').get('primaryPart').get('itemOriginalEquipmentFlag') == "1",
                    manufacturer = getPartManufacturer(args.part),
                    partnumber = args.part.get('partSummary').get('primaryPart').get('itemPartNumber');
      
                if (isEmpty(args.requiredItemIdArray)) dispatch(addItemToCart({itemId, itemType, quantity, contextId, gbb, isApproved, manufacturer, partnumber, rear}));
                else dispatch(addItemToCart({itemId, itemType, quantity, contextId, gbb, isApproved, manufacturer, partnumber, requiredItemIdArray}));
      
              }
            }
           else {
      
            if (sizeOf(args.part.get('partSummary').get('secondaryParts')) > 0 && rearquantity > 0) {
                
                var itemId = args.part.get('partSummary').get('primaryPart').get('itemId'),
                itemType = args.part.get('partSummary').get('primaryPart').get('itemType'),
                gbb = !isEmpty(args.index) ? 'gbb' + (args.index + 1) : 'nongbb',
                isApproved = args.part.get('partSummary').get('primaryPart').get('itemOriginalEquipmentFlag') == "1",
                manufacturer = getPartManufacturer(args.part),
                partnumber = args.part.get('partSummary').get('primaryPart').get('itemPartNumber'),
                rearitemId = args.part.get('partSummary').get('secondaryParts').get(0).get('itemId'),
                rearitemType = args.part.get('partSummary').get('secondaryParts').get(0).get('itemType');
      
                let rear = dispatch(addItemToCart({itemId: rearitemId, itemType: rearitemType, quantity: rearquantity,rearcontextId:rearcontextId,gbb:gbb,isApproved:isApproved,manufacturer:manufacturer,partnumber:partnumber}));

              if (isEmpty(args.requiredItemIdArray)) dispatch(addItemToCart({itemId, itemType, quantity, contextId, gbb, isApproved, manufacturer, partnumber, rear}));
              else dispatch(addItemToCart({itemId, itemType, quantity, contextId, gbb, isApproved, manufacturer, partnumber, requiredItemIdArray}));
      
            } else {
                var itemId = args.part.get('partSummary').get('primaryPart').get('itemId'),
                    itemType = args.part.get('partSummary').get('primaryPart').get('itemType'),
                    gbb = !isEmpty(args.index) ? 'gbb' + (args.index + 1) : 'nongbb',
                    isApproved = args.part.get('partSummary').get('primaryPart').get('itemOriginalEquipmentFlag') == "1",
                    manufacturer = getPartManufacturer(args.part),
                    partnumber = args.part.get('partSummary').get('primaryPart').get('itemPartNumber');

      
                    if (isEmpty(args.requiredItemIdArray)) dispatch(addItemToCart({itemId, itemType, quantity, contextId, gbb, isApproved, manufacturer, partnumber, rear}));
                    else dispatch(addItemToCart({itemId, itemType, quantity, contextId, gbb, isApproved, manufacturer, partnumber, requiredItemIdArray}));
      
            }
      
          }
        })


 // const addItemToCart = createAsyncThunk(
 //     'shoppingCart/addItem',
 //     async(args,{getState,rejectWithValue})=>{
 //             let {tireId,tokenId,dealerIgnoreTireStock,
 //                dealerIgnoreWheelStock,
 //                dealerIgnoreAccessoryStock,
 //                dealerIgnorePackageStock,
 //                dealerIgnoreMerchandiseStock,
 //                setPartQuantityToOne} = getState().info;
 //             let {orderNumber} = getState().shoppingCart;
 //             let {itemId,quantity,itemType,contextId,requiredItemId} = args;
 //              let VehicleId = store.getState().productList.vehicleId;
 //             let failValues = ['',null,undefined];
 //             console.table({
 //               dealerId:getAppDealerId(),
 //               cartId:orderNumber,
 //               orderNumber:orderNumber,
 //               itemId:itemId,
 //               itemType:itemType,
 //               itemQty:quantity,
 //               pricingContextId:contextId,
 //               optionId:0,
 //               vehicleId:!failValues.includes(tireId) ? tireId : VehicleId,
 //               tokenId:tokenId
 //             })
 //             let res = await baseInstance.post('/shopping/cart/addItemToCart.php',{
 //                    dealerId:getAppDealerId(),
 //                    cartId:orderNumber,
 //                    orderNumber:orderNumber,
 //                    itemId:itemId,
 //                    itemType:itemType,
 //                    itemQty:quantity,
 //                    pricingContextId:contextId,
 //                    optionId:0,
 //                    vehicleId:!failValues.includes(tireId) ? tireId : VehicleId,
 //                    isNational : 0,
 //                    tokenId:tokenId
 //
 //
 //            });
 //       // response.skipRedirect = true;
 //       // response.addedToCart = true;
 //            res.data.payload.skipRedirect = true;
 //            res.data.payload.addedToCart = true;
 //            res.data.payload.dealerIgnoreTireStock = dealerIgnoreTireStock;
 //            res.data.payload.dealerIgnoreWheelStock =dealerIgnoreWheelStock;
 //            res.data.payload.dealerIgnoreAccessoryStock =dealerIgnoreAccessoryStock;
 //            res.data.payload.dealerIgnorePackageStock  =dealerIgnorePackageStock;
 //            res.data.payload.dealerIgnoreMerchandiseStock = dealerIgnoreMerchandiseStock;
 //            res.data.payload.setPartQuantityToOne = setPartQuantityToOne;
 //            return res.data.payload;
 //
 //
 //
 //     });

    //  removeItemFromCart: function (cartItemId) {
	// 	this.apiPost('/shopping/cart/removeItemFromCart.php', {
    //   dealerId: getAppDealerId(),
    //   cartId: getOrderIdentifier(),
    //   orderNumber: getOrderIdentifier(),
    //   cartItemId: cartItemId,
    //   isNational: applicationStore.data.nationalSite ? 1 : 0
    // }).then(function (response) {
    //   response.skipRedirect = true;
    //   response.fromCreatePackage = false;
    //   this.dispatch(DispatcherActions.WISHLIST_REVIEW_LOADED, response);
	// 		this.generateFoxyCartFromCart();
	// 	}.bind(this));
	// },

    export const removeItemFromCart = createAsyncThunk(
        'shoppingCart/removeItemFromCart',
        async(args,{getState,rejectWithValue})=>{
            let {tokenId,nationalSite} = getState().info;
            let {orderNumber } = getState().shoppingCart;
            let itemId = args;

          try{
            let res = await baseInstance.post('/shopping/cart/removeItemFromCart.php',{
             
                    dealerId: getAppDealerId(),
                    cartId: orderNumber,
                    orderNumber: orderNumber,
                    cartItemId: itemId,
                    isNational: nationalSite ? 1 : 0,
                    tokenId:tokenId
              
            });
          return res.data.payload;
          } 
          catch(err){
              console.error(err);
              rejectWithValue(err)
          }
        }
    )
    // removeItemFromBasket: function (cartItemId, id, cart) {
	// 	this.apiPost('/shopping/cart/removeItemFromCart.php', {
    //   dealerId: getAppDealerId(),
    //   cartId: getOrderIdentifier(),
    //   orderNumber: getOrderIdentifier(),
    //   cartItemId: cartItemId,
    //   isNational: applicationStore.data.nationalSite ? 1 : 0
    // }).then(function (response) {
    //   trackRemoveFromCart(cart, id);
    //   response.skipRedirect = true;
    //   response.fromCreatePackage = false;
    //   this.dispatch(DispatcherActions.WISHLIST_REVIEW_LOADED, response);
	// 	}.bind(this));
	// },
	// generateFoxyCartFromCart: function () {
	// 	this.apiPost('/shopping/thirdParty/generateFoxyCartFromCart.php', {
    //   dealerId: getAppDealerId(),
    //   cartId: getOrderIdentifier(),
    //   orderNumber: getOrderIdentifier(),
    //   customerLanguage: getAppLanguage(),
    //   isNational: applicationStore.data.nationalSite ? 1 : 0
    // }).then(function (response) {
	// 		this.dispatch(DispatcherActions.FOXY_CART_GENERATED, response);
	// 		this.loadShoppingCartView();
	// 	}.bind(this));
	// },


export const selectCartItems = ({shoppingCart}) => {
  const orderItemList = shoppingCart.cartInformation?.orderItemList || [];
  return orderItemList.map((cartItem) => {
    const partPricing = cartItem.itemPart.partPricing.primaryPart;

    let quantity = {
      id: cartItem.pricingContextId + parseInt(cartItem.itemQty),
      value: parseInt(cartItem.itemQty),
      title: {
        en: `${parseInt(cartItem.itemQty)}${cartItem.pricingContextId > 0 ? ' - ' + cartItem.itemPromotion['en'] : ''}`,
        fr: `${parseInt(cartItem.itemQty)}${cartItem.pricingContextId > 0 ? ' - ' + cartItem.itemPromotion['fr'] : ''}`
      },
      pricingContextId: cartItem.pricingContextId
    };

    const price = getPricingFromQuantityAndDetailsRefactored(partPricing, quantity);

    let product = null;

    switch (getDestinationInverse(cartItem.itemType)) {
      case ProductTypes.TIRES:
        product = tireMappingFunction(true, shoppingCart.cartInformation.dealerIgnoreTireStock, "", shoppingCart.cartInformation.setPartQuantityToOne)(cartItem.itemPart);
        break;
      case ProductTypes.ALLOY_WHEELS:
        product = wheelMappingFunction(true, shoppingCart.cartInformation.dealerIgnoreWheelStock, "", shoppingCart.cartInformation.setPartQuantityToOne)(cartItem.itemPart);
        break;
      case ProductTypes.ACCESSORIES:
        product = accessoryMappingFunction(true, shoppingCart.cartInformation.dealerIgnoreAccessoryStock, shoppingCart.cartInformation.setPartQuantityToOne)(cartItem.itemPart);
        break;
      case ProductTypes.PACKAGES:
        product = packageMappingFunction(true, shoppingCart.cartInformation.dealerIgnorePackageStock, shoppingCart.cartInformation.setPartQuantityToOne)(cartItem.itemPart);
        break;
      default:
        break;
    }
    const itemNew = {
      id: cartItem.itemId,
      cartItemId: cartItem.orderItemId,
      name: cartItem.itemTitle,
      image: cartItem.itemImageLink,
      listPrice: price['listPrice'],
      showUnitPrice: price['showUnitPrice'],
      displayPrice: price['displayPrice'],
      displayLabour: price['displayLabour'],
      privatePrice: price['privatePrice'],
      promotionText: price['promotionPricingText'],
      cartItemPrice: cartItem['rowPrice'],
      quantity: quantity,
      type: getDestinationInverse(cartItem.itemType),
      product: product.toJS(),
      pricingContextId: cartItem.pricingContextId,
      itemPartOption: cartItem.itemPartOption,
      itemWarnings: cartItem.itemWarnings,
      requiredItems: null,
      mandatoryItems: cartItem.mandatoryLineItemList,
      includedOptionalItems: cartItem.includedOptionalLineItemList,
      optionalItems: cartItem.additionalOptionalLineItemList,
      hasRequiredItem: cartItem.hasRequiredRelatedItems === '1',
      supportDealerOrder: cartItem.supportDealerOrder,
      supportConsumerOrder: cartItem.supportConsumerOrder
    }
    return itemNew;
  });
};


const defaulState = {
  appointmentRequestDate: null,
  appointmentRequestTime: null,
  appointmentWishList: [],
  cart: null,
  cartDemo: null,
  cartInformation: null,
  cartItemSeen: false,
  cartItems: [],
  cartItemsCount: 0,
  CartModal: null,
  changeRequiredItemQuantity: null,
  completedModal: false,
  detail: null,
  emailSuccess: false,
  firstNonNullPromotion: null,
  formModal: false,
  formType: null,
  itemNew: null,
  newCart: null,
  numberOfPromotions: null,
  orderDeposit: null,
  orderDueAtDealer: null,
  orderId: null,
  orderGUID: null,
  orderNumber: null,
  orderDisclaimerList: null,
  orderRestockingFee: null,
  orderStatus: null,
  orderStatusDescription: null,
  quantity: 0,
  requestModal: false,
  requestModalAppointment: false,
  scheduleWishList: null,
  shoppingCart: [],
  shoppingCartViewCreated: null,
  shoppingCartViewNotes: null,
  showFinancingModal: false,
  subtotal: null,
  supportConsumerOrder: false,
  supportDealerOrder: false,
  tax: null,
  taxtotal: null,
  toggleCart: false,
  toggleCartModal:false,
  total: 'N/A',
  totalMSRPSavings: null,
  totalSavings: null,
  warnings: false,
  wishListFormType: [],
  wishListForm: null,
  wishListReview: false,
};

const createCart = (payload) => {
  localStorage.removeItem('orderNumber');
  localStorage.setItem('orderNumber', payload.orderNumber);
  return {
    ...defaulState,
    cart: payload.orderId,
    orderNumber: payload.orderNumber,
    cartInformation: payload,
    quantity: parseInt(payload.itemQty),
    supportConsumerOrder: payload.supportConsumerOrder,
    supportDealerOrder: payload.supportDealerOrder,
  }
  
}

const shoppingCart = createSlice({
    name:"shoppingCart",
    initialState: defaulState,
    reducers:{
      EmailSendSuccessful:(state,{payload})=>{
        state.emailSuccess = payload;
      },
      wishListForm:(state,{payload})=>{
          state.wishlistForm.push(payload)
      },
      // SHOPPING_CART_OPEN_FORM
      shoppingCartOpenForm: (state, { payload }) => {
          state.formType = payload;
          state.formModal = true;
      },
      shoppingCartCloseForm: (state, { payload }) => {
          state.formType = null;
          state.formModal = !state.formModal;
      },
        wishListFormType:(state,{payload})=>{
          try {
            state.wishListFormType.push(payload)
        }
        catch (e) {
          console.error(e);
        }

        },
        markAsCompleteModal:(state,{payload})=>{
            state.completedModal.push(payload)
        },
        requiredItemQuantity:(state,{payload})=>{
            state.changeRequiredItemQuantity.push(payload)
        },
        appointmentWishList:(state,{payload})=>{
            state.appointmentWishList.push(payload)
        },
        toggleShoppingModal:(state,{payload})=>{
          state.CartModal = payload;
          state.toggleCartModal = payload;
        },
        toggleCart:(state,{payload})=>{
            state.toggleCart = payload
        },
        toggleDetails:(state,{payload})=>{
            state.detail.push(payload)
        },
        cartItemSeen:(state,{payload})=>{
            state.cartItemSeen = payload;
        },
        wishListReview:(state,{payload})=>{
          let wishListReview = shoppingCartViewLoaded(payload);
          // NOTE: inconsistent data type, eveverywhere elese it is a plain object, here it is a Map
          // state.cartInformation = wishListReview;
          state.cartInformation = payload;
          state.cartItems = wishListReview.get('cartItems');
          state.vehicleInformation = wishListReview.get('vehicleInformation');
          state.orderTypeCode = wishListReview.get('orderTypeCode');
          state.supportConsumerOrder = wishListReview.get('supportConsumerOrder');
          state.shoppingCartViewCreated = wishListReview.get('shoppingCartViewCreated');
          state.appointmentRequestDate = wishListReview.get('appointmentRequestDate');
          state.appointmentRequestTime = wishListReview.get('appointmentRequestTime');
          state.subtotal = wishListReview.get('subtotal');
          state.total = wishListReview.get('total');
          state.taxtotal = wishListReview.get('taxtotal');
          state.tax = wishListReview.get('tax');
          state.orderRestockingFee = wishListReview.get('orderRestockingFee');
          state.orderDueAtDealer = wishListReview.get('orderDueAtDealer');
          state.orderDisclaimerList = wishListReview.get('orderDisclaimerList');
          state.numberOfPromotions = wishListReview.get('numberOfPromotions');
          state.totalSavings = wishListReview.get('totalSavings');
          state.totalMSRPSavings = wishListReview.get('totalMSRPSavings');
          state.firstNonNullPromotion = wishListReview.get('firstNonNullPromotion');
          state.shoppingCartViewNotes = wishListReview.get('shoppingCartViewNotes');
          state.orderStatusDescription = wishListReview.get('orderStatusDescription');
          state.orderGUID = wishListReview.get('orderGUID');
          state.orderId = wishListReview.get('orderId');
          state.orderDeposit = wishListReview.get('orderDeposit');
          state.orderStatus = wishListReview.get('orderStatus');
          state.showFinancingModal = wishListReview.get('showFinancingModal');
          state.toggleCartModal = wishListReview.get('toggleCartModal');
            state.supportConsumerOrder = wishListReview.get('supportConsumerOrder');
            state.supportDealerOrder = wishListReview.get('supportDealerOrder');

        },
      saveCartNumber:(state,{payload})=>{
        state.orderNumber = payload;
      },
      requestModalToggle:(state,{payload})=>{
        state.requestModalAppointment = payload;
      },
      newShoppingCart:(state,{payload})=>{
        return createCart(payload);
      },
      addItemToCart:(state,{payload})=>{
        state.toggleCart = true;
        state.cartInformation = payload;
        state.total = payload.orderGrandTotal;
      },
      updateItemToCart: (state, action) => {
        //  any updates need to the cart Items need to be done here
        state.cartInformation = action.payload;
        state.cartItems = transformShoppingCartViewItemsFromApi(action.payload.orderItemList);
        state.total = action.payload.orderGrandTotal;
      },
      emptyShoppingCart: (state) => {
        localStorage.removeItem('orderNumber');
        return defaulState;
      }
    },
    extraReducers:{
        [getNewCart.pending]:(state,actions)=>{
            state.status = 'pending'
        },
        [getNewCart.fulfilled]:(state, { payload })=>{
            shoppingCartViewStore.getNewCart(payload);
            return {...createCart(payload), status: 'fulfilled'};
        },
        [getNewCart.rejected]:(state,actions)=>{
            state.status = 'rejected'
        },
        [loadWishlistReview.pending]:(state,actions)=>{
            state.status = 'pending'
        },

        [loadWishlistReview.fulfilled]:(state,{payload})=>{
            state.status = 'fulfilled'
        },

        [loadWishlistReview.rejected]:(state,actions)=>{
            state.status = 'rejected'
        },
        [loadWishListPrint.pending]:(state,actions)=>{
            state.status = 'pending';
        },
        [loadWishListPrint.fulfilled]:(state,actions)=>{
            state.status = 'fulfilled';
        },
        [loadWishListPrint.rejected]:(state,actions)=>{
            state.status = 'rejected';
        }, 
        [generateFoxyCartFromCart.pending]:(state,actions)=>{
            state.status = 'pending'
        },
        [generateFoxyCartFromCart.fulfilled]:(state,actions)=>{
            state.status = 'fulfilled'
        },
        [generateFoxyCartFromCart.rejected]:(state,actions)=>{
            state.status = 'rejected'
        },
        [removeItemFromCart.pending]:(state,{payload})=>{
            state.status = 'pending';
        },
        [removeItemFromCart.fulfilled]:(state,{payload})=>{
            state.status = 'fulfilled';
            state.total = payload.orderGrandTotal;
            state.cartInformation = payload;
        },
        [removeItemFromCart.rejected]:(state,{payload})=>{
            state.status = 'rejected'
        }
    }

})

export const {
    wishListForm,
    wishListFormType,
    markAsCompleteModal,
    requiredItemQuantity,
    appointmentWishList,
    toggleShoppingModal,
    toggleCart,
    toggleDetails,
    cartItemSeen,
    wishListReview,
    saveCartNumber,
  requestModalToggle,
  shoppingCartOpenForm,
  shoppingCartCloseForm,
  newShoppingCart,
  addItemToCart,
  updateItemToCart,
  emptyShoppingCart
    
} = shoppingCart.actions;

export default shoppingCart.reducer;
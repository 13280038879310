import { get } from '../api/ApiService.js';
import { productListActions } from '../actions/ProductListActions.js';
import { DispatcherActions } from '../constants/DispatcherActions.js';
import { Languages } from '../constants/Languages.js';
import { ProductTypes } from '../constants/ProductTypes.js';
import { RouterStates } from '../constants/RouterStates.js';
import { AbstractStore } from './AbstractStore.js';
import { languageStore } from './LanguageStore.js';
import { searchStore } from './SearchStore.js';
import { vehicleSelectionStore } from './VehicleSelectionStore.js';
import { modalStore } from './ModalStore.js';
import { shoppingCartStore } from './ShoppingCartStore.js';
import { globalState } from '../GlobalState';
import { applicationStore } from './ApplicationStore.js';
import { Router } from './Router.js';

import {
	isEmpty,
	sizeOf,
	getAppDealerId,
	checkArrayFieldsExist,
	getAppLanguage,
	sizeTypeDescriptionWrapper
} from '../service/UtilityService';
import {
	tireMappingFunction,
	tireObjectFunction,
	packageMappingFunction,
	getPricingByQuantity,
	getRebateByQuantity,
	getWinterSpeedRating,
	updateSpeedRatingDropDown,
	speedRatingIsValid,
	buildPlyRatingFilter,
	plyRatingIsValid,
	getSelectedTireSizeIfPresentOrOriginal,
	buildFilter,
	getMinFromList,
	getMaxFromList
} from '../service/ProductList';
import { getSpeedList, getPlyList, isValidWidth, isValidSeason } from '../service/VehicleService';

import { collectVisualizerPartList } from '../service/VisualizerService.js';

import { SearchTypes } from '../constants/SearchTypes.js';
import { PricingFilter } from '../constants/PricingFilter.js';
import { PricingFilterPackage } from '../constants/PricingFilterPackage.js';
import { searchActions } from "../actions/SearchActions";

var _ = require('lodash');
var Immutable = require('immutable');

var DEFAULT_SELECTED_QUANTITY_OF_PRODUCT = 4;
var DEFAULT_SELECTED_QUANTITY_OF_PRODUCT_STAGGERED = 2;
var STAGGERED_KEY = 'staggered';

const SHOW_RUN_FLAT_TIRES = "show-only-run-flat";
const SHOW_NON_RUN_FLAT_TIRES = "show-only-non-run-flat";
const SHOW_ALL_TIRES = "show-all";

const MAXIMUM_NUMBER_PRODUCTS_IN_COMPARISON = 3;

const COMPARISON_ERROR_NO_PARTS = "comparison-error-no-parts";
const COMPARISON_ERROR_PART_ALREADY_IN_LIST = "comparison-error-part-already-in-list";
const COMPARISON_ERROR_PART_LIST_IS_FULL = "comparison-error-part-list-is-full";
const COMPARISON_ERROR_FAILED_TO_LOAD = "comparison-error-failed-to-load";

const AccessoryViewer = {
  "TILE_VIEW": "view-accessories-list",
  "TABLE_VIEW": "view-accessories-table"
};

var ProductListStore = AbstractStore.extend({

	constructor: function () {
		this.data = {};
		this.data.vehicleId = null;
		this.data.vehicleFullTireSizeInfo = null;
		this.data.vehicleInfoPayload = {};
		this.data.listOfTires = Immutable.List.of();
		this.data.listOfTireLiveStock = null;
		this.data.listOfWheels = Immutable.List.of();
		this.data.listOfAccessories = Immutable.List.of();
		this.data.listOfCollections = Immutable.List.of();
		this.data.listOfPackages = Immutable.List.of();
		this.data.filteredListOfTires = Immutable.List.of();
    this.data.filteredListOfWheels = Immutable.List.of();
    this.data.filteredListOfPackages = Immutable.List.of();
    this.data.wheelPayload = null;
		this.data.featuredTireCount = 0;
		this.data.filteredBrandIds = [];
		this.data.filteredTireSizeFilters = Immutable.List.of();
		this.data.seasonCountArray = [];
		this.data.tireSizeSpecIdCountArray = [];
		this.data.tireFilterTagList = [];
		this.data.accessoryFilterTagList = [];

		this.data.autoAddToCart = false;
		this.data.autoAddProduct = { manufacturer: "", partnumber: "" };

    this.data.visualizerColours = [];
    this.data.visualizerForeground = "";
    this.data.visualizerShadow = "";

		this.data.colourCodes = Immutable.List.of();
		this.data.wheelDiamaters = Immutable.List.of();
		this.data.accessoryCategoryFilter = Immutable.List.of();
		this.data.speedIndexFilters = Immutable.List.of();
		this.data.loadRatingFilters = Immutable.List.of();
		this.data.plyRatingFilters = Immutable.List.of();
		this.data.secondarySpeedIndexFilters = Immutable.List.of();
		this.data.secondaryLoadRatingFilters = Immutable.List.of();
		this.data.secondaryPlyRatingFilters = Immutable.List.of();
		this.data.tireSizeFilters = Immutable.List.of();
		this.data.tireSize = null;
		this.data.tireSizeType = null;
		this.data.tireSizeSpecId = null;
		this.data.secondaryTireSizeSpecId = null;
		this.data.vehicleSubmodelTiresizeId = null;
		this.data.originalEquipmentFilter = null;
		this.data.brandFilters = Immutable.List.of();
		this.data.openedIndex = null;
		this.data.openedCartIndex = null;
		this.data.brands = [];
		this.data.seasonFilters = Immutable.List.of();
		this.data.season = null;
		this.data.originalSeason = null;
		this.data.loadRating = null;
		this.data.secondaryLoadRating = null;
		this.data.speedIndex = null;
		this.data.secondarySpeedIndex = null;
		this.data.plyRating = null;
		this.data.secondaryPlyRating = null;
		this.data.tag = [];
		this.data.accessoriesCategories = null;
		this.data.showOriginalTireSizeSelection = false;
		this.data.productListShowNoResultsModal = false;
		this.data.wheelNotOriginalSizeWarning = false;
		this.data.tireNotOriginalSizeWarning = false;
		this.data.allowCreatePackage = false;

    this.data.originalEquipment = 1;

    this.data.originalEquipmentOnly = false;

		this.data.productList = [];
		this.data.productFeaturedList = [];
		this.data.maxLoadRating = 0;
    this.data.loadRatingList = [];
    this.data.speedRatingList = [];
    this.data.plyRatingList = [];
    this.data.maxSpeedRating = 0;
    this.data.maxPlyRating = 0;

		this.data.tireModelArray = {};
		this.data.tireModelTiresizeArray = {};

		this.data.loadFilterFromUrl = false;

    this.data.productDetailPart = null;

		this.data.tireListPageIndex = 1;

		this.data.wheelPageSelection = 0;
		this.data.centerWheelInVisualizer = false;
		this.data.wheelVisualizerPopModal = false;
		this.data.wheelVisualizerSlideShowIds = Immutable.List.of();
		this.data.windowSize = window.innerWidth;

		this.data.tireListLoaded = false;
		this.data.wheelListLoaded = false;
		this.data.accessoryListLoaded = false;
		this.data.packageListLoaded = false;
		this.data.merchandiseListLoaded = false;

		this.data.chassis = null;

    this.data.showBrandFilter = false;
    this.data.showPlyFilter = false;
    this.data.showPricingFilter = false;
    this.data.filterMinMaxPricingList = [];
    this.data.tireListMinPrice = null;
    this.data.tireListMaxPrice = null;
    this.data.wheelListMinPrice = null;
    this.data.wheelListMaxPrice = null;
    this.data.accessoryCategoryShadowList = [];
    this.data.accessoryListMinPrice = null;
    this.data.accessoryListMaxPrice = null;
    this.data.packageListMinPrice = null;
    this.data.packageListMaxPrice = null;
    this.data.runFlatFilterValue = SHOW_ALL_TIRES;

		this.data.searchType = null;
		this.data.hideProductList = false;

		this.data.promotionModalItemType = null;
		this.data.promotionModalItemId = null;
		this.data.promotionModalPricingContextId = null;
		this.data.promotionModalLink = null;
		this.data.promotionModalDisclaimer = null;
		this.data.showPromotionModal = false;
		this.data.promotionEmailSent = false;
		this.data.promotionEmailSentSuccessfully = false;

		this.data.hideWheelVisualizer = false;

    this.data.accessoryView = AccessoryViewer.TABLE_VIEW;

		this.data.comparisonPartData = [];
		this.data.comparisonPartList = Immutable.List.of();
		this.data.comparisonQuantityList = [];
		this.data.showComparison = false;
		this.data.comparisonErrorType = null;
		this.data.comparisonLoadingError = false;
		this.data.comparisonLoadedSuccessfully = false;
		this.data.comparisonEmailSent = false;
		this.data.comparisonEmailSuccessful = false;

		this.data.runComparisonOnLoad = false;

		this.data.visualizerPartList = [];
		this.data.selectedVisualizerPart = null;

		this.data.stockQueryEmpty = [];

		this.data.selectedTireData = null;

    this.data.showWarning = false;
    this.data.warning = {};

		this.data.launchComparisonPdf = false;
		this.data.launchGuidePdf = false;

		ProductListStore.__super__.constructor.apply(this, arguments);
	},

	getActionHandlers: function () {
		var handlers = {};
		handlers[DispatcherActions.INITIALIZE_APP_STATE] = this.onInitializeAppState;
		handlers[DispatcherActions.PRODUCT_LIST_TIRES_LOADED] = this.onListOfTiresLoaded;
		handlers[DispatcherActions.PRODUCT_LIST_TIRES_LOADED_FROM_MODEL] = this.onListOfTiresLoadedFromModel;
		handlers[DispatcherActions.PRODUCT_LIST_TIRES_LOADED_FROM_CHASSIS] = this.onListOfTiresLoadedFromChassis;
		handlers[DispatcherActions.PRODUCT_LIST_TIRE_LIST_INCREMENT_PAGE_INDEX] = this.tireListIncrementPageIndex;
		handlers[DispatcherActions.PRODUCT_LIST_WHEELS_LOADED] = this.onListOfWheelsLoaded;
		handlers[DispatcherActions.PRODUCT_LIST_ACCESSORIES_LOADED] = this.onListOfAccessoriesLoaded;
		handlers[DispatcherActions.PRODUCT_LIST_PACKAGES_LOADED] = this.onListOfPackagesLoaded;
		handlers[DispatcherActions.PRODUCT_LIST_PACKAGES_LOADED_FROM_MODEL] = this.onListOfPackagesLoaded;
		handlers[DispatcherActions.PRODUCT_LIST_CHANGE_TIRE_QUANTITY] = this.onChangeTireQuantity;
		handlers[DispatcherActions.PRODUCT_LIST_CHANGE_WHEEL_QUANTITY] = this.onChangeWheelQuantity;
		handlers[DispatcherActions.PRODUCT_LIST_OPEN_DETAIL] = this.onOpenDetail;
		handlers[DispatcherActions.PRODUCT_LIST_OPEN_DETAIL_FROM_CART] = this.onOpenDetailFromCart;
		handlers[DispatcherActions.PRODUCT_LIST_CLOSE_DETAIL] = this.onCloseDetail;
		handlers[DispatcherActions.PRODUCT_LIST_NEXT_PRODUCT] = this.onNextClick;
		handlers[DispatcherActions.PRODUCT_LIST_PREV_PRODUCT] = this.onPrevClick;
		handlers[DispatcherActions.PRODUCT_LIST_DETAIL_CHANGE_TIRE_QUANTITY] = this.onChangeDetailTireQuantity;
		handlers[DispatcherActions.PRODUCT_LIST_CHANGE_ACCESSORY_QUANTITY] = this.onChangeAccessoryQuantity;
		handlers[DispatcherActions.PRODUCT_LIST_CHANGE_PACKAGE_QUANTITY] = this.onChangePackageQuantity;
		handlers[DispatcherActions.MODAL_REQUEST_UNMOUNT] = this.onRequestDetailClose;
		handlers[DispatcherActions.PRODUCT_LIST_BRANDS_SELECTED] = this.onBrandsSelected;
		handlers[DispatcherActions.PRODUCT_LIST_PRICING_SELECTED] = this.onPricingSelected;
		handlers[DispatcherActions.PRODUCT_LIST_PRICING_TOGGLE_ALL] = this.onPricingToggled;
		handlers[DispatcherActions.PRODUCT_LIST_PACKAGE_PRICING_TOGGLE_ALL] = this.onPackagePricingToggled;
		handlers[DispatcherActions.PRODUCT_LIST_SEASON_SELECTED] = this.onSeasonSelected;
		handlers[DispatcherActions.PRODUCT_LIST_WHEEL_SEASON_SELECTED] = this.onWheelSeasonSelected;
		handlers[DispatcherActions.PRODUCT_LIST_LOAD_RATING_SELECTED] = this.onLoadRatingSelected;
		handlers[DispatcherActions.PRODUCT_LIST_TIRE_SIZE_SELECTED] = this.onTireSizeSelected;
		handlers[DispatcherActions.PRODUCT_LIST_TIRE_SIZE_ID_SELECTED] = this.onTireSizeIdSelected;
		handlers[DispatcherActions.PRODUCT_LIST_SPEED_INDEX_SELECTED] = this.onSpeedIndexSelected;
		handlers[DispatcherActions.PRODUCT_LIST_LOAD_SECONDARY_SPEED_INDEX_SELECTED] = this.onSecondarySpeedIndexSelected;
		handlers[DispatcherActions.PRODUCT_LIST_LOAD_SECONDARY_LOAD_RATING_SELECTED] = this.onSecondaryLoadRatingSelected;
		handlers[DispatcherActions.PRODUCT_LIST_PLY_RATING_SELECTED] = this.onPlyRatingSelected;
		handlers[DispatcherActions.PRODUCT_LIST_TAG_SELECTED] = this.onTagSelected;
		handlers[DispatcherActions.PRODUCT_LIST_WHEEL_TIRE_SIZE_SELECTED] = this.onWheelTireSizeSelected;
		handlers[DispatcherActions.PRODUCT_LIST_CLOSE_WHEEL_NOT_ORIGINAL_SIZE_WARNING] = this.closeWheelNotOriginalSizeWarning;
		handlers[DispatcherActions.PRODUCT_LIST_CLOSE_TIRE_NOT_ORIGINAL_SIZE_WARNING] = this.closeTireNotOriginalSizeWarning;
		handlers[DispatcherActions.PRODUCT_LIST_ACCESSORY_CATEGORIES_SELECTED] = this.onAccessoriesCategoriesSelected;
		handlers[DispatcherActions.VEHICLE_INFO_LOADED] = this.onVehicleInfoLoaded;
		handlers[DispatcherActions.PRODUCT_LIST_CHANGE_COLOUR] = this.onChangeColour;
		handlers[DispatcherActions.PRODUCT_LIST_CHANGE_COLOUR_IN_DETAIL] = this.onChangeColourInDetail;
		handlers[DispatcherActions.PRODUCT_LIST_STOCK_REFRESHED] = this.onStockRefreshed;
		handlers[DispatcherActions.PRODUCT_LIST_REQUEST_STOCK_REFRESH] = this.onRequestStockRefresh;
		handlers[DispatcherActions.APPOINTMENT_FORM_SENT] = this.onAppointmentFormSent;
		handlers[DispatcherActions.PRODUCT_LIST_NO_RESULTS_FOR_FILTER] = this.noProductListResultsFound;
		handlers[DispatcherActions.GO_BACK] = this.resetProductListLoaded;
		handlers[DispatcherActions.CHANGE_ROUTER_STATE_DIRECTLY] = this.resetProductListLoaded;
		handlers[DispatcherActions.CHANGE_STATE_FROM_URL] = this.resetProductListLoaded;
		handlers[DispatcherActions.PRODUCT_LIST_WHEEL_VISUALIZER_RESET_SELECTED] = this.resetWheelVisualizerSelectedWheel;
		handlers[DispatcherActions.PRODUCT_LIST_ITEM_STOCK_REFRESH] = this.onRequestStockItemRefresh;
		handlers[DispatcherActions.PRODUCT_LIST_SHOW_OR_HIDE_BRAND_FILTER] = this.showOrHideBrandFilter;
		handlers[DispatcherActions.PRODUCT_LIST_SHOW_OR_HIDE_PLY_FILTER] = this.showOrHidePlyFilter;
		handlers[DispatcherActions.PRODUCT_LIST_SHOW_OR_HIDE_PRICING_FILTER] = this.showOrHidePricingFilter;
		handlers[DispatcherActions.PRODUCT_LIST_LOAD_SEASON_FILTER] = this.loadSeasonFilters;
		handlers[DispatcherActions.PRODUCT_LIST_LOAD_TIRE_BRAND_FILTER] = this.loadTireBrandFilters;
		handlers[DispatcherActions.PRODUCT_LIST_LOAD_ACCESSORY_CATEGORY_FILTER] = this.loadAccessoryCategoryFilters;
		handlers[DispatcherActions.VEHICLE_SELECTION_TRIM_SELECTED] = this.onTrimSelected;
		handlers[DispatcherActions.PRODUCT_LIST_UPDATE_TIRE_LIST] = this.updateListOfTires;
		handlers[DispatcherActions.SET_OE_FILTER_VALUE] = this.setOeFilterValue;
		handlers[DispatcherActions.SET_RUN_FLAT_FILTER_VALUE] = this.setRunFlatFilterValue;
		handlers[DispatcherActions.SET_DEFAULT_SEASON] = this.onSetDefaultSeason;
		handlers[DispatcherActions.PRODUCT_LIST_SET_CHASSIS_SEARCH_VALUE] = this.onSetChassisSearchValue;
		handlers[DispatcherActions.PRODUCT_LIST_SET_SEARCH_TYPE] = this.onSetProductListSearchType;
		handlers[DispatcherActions.PRODUCT_TYPE_SELECTED] = this.onVehicleSearchSelected;
		handlers[DispatcherActions.VEHICLE_SELECTION_YEARS_SELECTED] = this.onVehicleSearchSelected;
		handlers[DispatcherActions.VEHICLE_SELECTION_MAKE_SELECTED] = this.onVehicleSearchSelected;
		handlers[DispatcherActions.VEHICLE_SELECTION_CATEGORY_SELECTED] = this.onVehicleSearchSelected;
		handlers[DispatcherActions.VEHICLE_SELECTION_MODEL_SELECTED] = this.onVehicleSearchSelected;
		handlers[DispatcherActions.PRODUCT_LIST_HIDE] = this.onHideProductList;
		handlers[DispatcherActions.PRODUCT_LIST_SHOW] = this.onShowProductList;
		handlers[DispatcherActions.EMAIL_PROMOTION_OPEN_MODAL] = this.emailPromotionOpenModal;
		handlers[DispatcherActions.EMAIL_PROMOTION_CLOSE_MODAL] = this.emailPromotionCloseModal;
		handlers[DispatcherActions.EMAIL_PROMOTION_SUCCEEDED] = this.emailPromotionSucceeded;
		handlers[DispatcherActions.EMAIL_PROMOTION_FAILED] = this.emailPromotionFailed;
		handlers[DispatcherActions.PRODUCT_LIST_LIVE_STOCK_LOADED] = this.liveStockLoaded;
		handlers[DispatcherActions.PRODUCT_COMPARISON_ADD] = this.productComparisonAdd;
		handlers[DispatcherActions.PRODUCT_COMPARISON_REMOVE] = this.productComparisonRemove;
		handlers[DispatcherActions.PRODUCT_COMPARISON_FETCH] = this.productComparisonFetched;
		handlers[DispatcherActions.PRODUCT_COMPARISON_ERROR] = this.productComparisonError;
		handlers[DispatcherActions.PRODUCT_COMPARISON_RESET_ERROR] = this.resetProductComparisonError;
		handlers[DispatcherActions.PRODUCT_COMPARISON_RESET] = this.resetProductComparison;
		handlers[DispatcherActions.PRODUCT_COMPARISON_RUN] = this.runProductComparison;
		handlers[DispatcherActions.PRODUCT_COMPARISON_SET_PART_QUANTITY] = this.setComparisonPartQuantity;
		handlers[DispatcherActions.PRODUCT_COMPARISON_SET_PART_QUANTITIES] = this.setComparisonPartQuantities;
		handlers[DispatcherActions.PRODUCT_COMPARISON_EMAIL] = this.comparisonEmailRun;
		handlers[DispatcherActions.PRODUCT_QUICK_COMPARISON_RUN] = this.runQuickComparison;
		handlers[DispatcherActions.PRODUCT_COMPARISON_ON_LOAD] = this.runComparisonOnLoad;
		handlers[DispatcherActions.SEARCH_RESULT_LOADED] = this.resetProductComparison;
		handlers[DispatcherActions.PRODUCT_LIST_VISUALIZER_SET_PART] = this.onSetPartForVisualizer;
    handlers[DispatcherActions.PRODUCT_DETAIL_MODAL_LOAD_PART] = this.getProductDetailModalPart;
    handlers[DispatcherActions.PRODUCT_DETAIL_CHANGE_REQUIRED_QUANTITY] = this.onChangeProductDetailModalRequiredItemQuantity;
    handlers[DispatcherActions.TIRE_BRAND_DATA_LOADED] = this.onTireBrandDataLoaded;
    handlers[DispatcherActions.TIRE_MODEL_PART_DATA_LOADED] = this.onListOfTiresLoadedByModel;
    handlers[DispatcherActions.PRODUCT_LIST_CHANGE_SELECTED_TIRE_QUANTITY] = this.onChangeSelectedTireQuantity;
    handlers[DispatcherActions.TIRE_LOADED_FROM_PART_DATA] = this.onTireLoadedFromInfo;
    handlers[DispatcherActions.SWITCH_ACCESSORY_VIEW] = this.onSwitchAccessoryView;
    handlers[DispatcherActions.SET_COMPARISON_PDF_LAUNCHER] = this.onSetComparisonPdfLauncher;
    handlers[DispatcherActions.SET_GUIDE_PDF_LAUNCHER] = this.onSetGuidePdfLauncher;
    handlers[DispatcherActions.PRODUCT_LIST_WARNING_SHOW] = this.onShowProductListWarning;
    handlers[DispatcherActions.PRODUCT_LIST_WARNING_HIDE] = this.onHideProductListWarning;
    handlers[DispatcherActions.SEARCH_FILTER_SEASON] = this.onFilterSearchSeason;
    handlers[DispatcherActions.SET_SEARCH] = this.onSetSearch;
		return handlers;
	},

	onInitializeAppState: function (appState) {
    var routerState = Router.decodeRouterState(appState);

    this.loadAppState('season', appState, routerState.params);
    this.loadAppState('searchType', appState, routerState.params);
    this.loadAppState('chassis', appState, routerState.params);

		this.emitChange();
	},

  loadAppState: function(key, appState, params) {
    var temp;
    if (params[key]) {
      temp = params;
      this.data[key] = temp[key];
    } else if (appState[key]) {
      temp = appState;
      this.data[key] = temp[key];
    }
  },

	resetProductListLoaded: function () {
		this.data.tireListLoaded = false;
		this.data.wheelListLoaded = false;
		this.data.accessoryListLoaded = false;
		this.data.packageListLoaded = false;
		this.data.merchandiseListLoaded = false;
		this.data.allowCreatePackage = false;
		this.data.brands = null;
    this.data.filterMinMaxPricingList = [];
    this.data.tireListMinPrice = null;
    this.data.tireListMaxPrice = null;
    this.data.wheelListMinPrice = null;
    this.data.wheelListMaxPrice = null;
    this.data.accessoryCategoryShadowList = [];
    this.data.accessoryListMinPrice = null;
    this.data.accessoryListMaxPrice = null;
    this.data.packageListMinPrice = null;
    this.data.packageListMaxPrice = null;
		this.data.runFlatFilterValue = SHOW_ALL_TIRES;
    this.data.productList = [];
    this.data.productFeaturedList = [];
    this.data.maxLoadRating = 0;
    this.data.loadRatingList = [];
    this.data.speedRatingList = [];
    this.data.plyRatingList = [];
    this.data.maxSpeedRating = 0;
    this.data.maxPlyRating = 0;
		this.emitChange();
	},

  onSetSearch: function(payload) {
		this.data.searchType = payload.type;

		this.resetProductListLoaded();

    this.emitChange();
	},

  onSetComparisonPdfLauncher: function(setLauncher) {
    this.data.launchComparisonPdf = setLauncher;
    this.emitChange();
	},

  onSetGuidePdfLauncher: function(setLauncher) {
    this.data.launchGuidePdf = setLauncher;
    this.emitChange();
	},
	loadingTires: function (payload) {
		this.data.partListLoading = payload;
		this.emitChange();
	},
  onSwitchAccessoryView: function(view) {
    this.data.accessoryView = view;
    this.emitChange();
  },

  onTireBrandDataLoaded: function(response) {
		var tireModelArray = [];
		for (var i in response.tire_model_data) {
      tireModelArray.push(response.tire_model_data[i]);
		}

    tireModelArray = tireModelArray.sort(function(a, b) {
    	if (a.model_name.toLowerCase() > b.model_name.toLowerCase()) return 1;
    	else if (a.model_name.toLowerCase() < b.model_name.toLowerCase()) return -1;
    	else return 0;
		});

    var tireModelTiresizeArray = [];
    for (var i in response.tiresize_data) {
      tireModelTiresizeArray.push({
				tireSizeSpecId: parseInt(response.tiresize_data[i].tiresizeId),
        formattedTiresize: response.tiresize_data[i].formattedTiresize
			});
		}

		this.data.tireModelArray = tireModelArray;
		this.data.tireModelTiresizeArray = tireModelTiresizeArray;
    this.emitChange();
  },

  onFilterSearchSeason: function (payload) {
    this.data.season = payload.season;
    setTimeout(() => {
      searchActions.loadSearchResults();
    }, 100);
    this.emitChange();
  },

	onShowProductListWarning: function() {
		this.data.showWarning = true;
    this.emitChange();
	},

  onHideProductListWarning: function() {
    this.data.showWarning = false;
    this.emitChange();
  },

	onHideProductList: function() {
		this.data.hideProductList = true;
		this.emitChange();
	},

	onShowProductList: function() {
		this.data.hideProductList = false;
		this.emitChange();
	},

	onVehicleSearchSelected: function() {
		this.data.searchType = SearchTypes.SEARCH_BY_VEHICLE;
		this.emitChange();
	},

	onBrandsSelected: function(brands) {
		this.data.brands = !isEmpty(brands) ? brands : null;
		this.filterTires(true, null);
		this.emitChange();
	},

  onPricingSelected: function (pricing) {
		this.data.filterMinMaxPricingList = pricing;
		/*
		if (listContains(this.data.filterMinMaxPricingList, pricingId)) {
      this.data.filterMinMaxPricingList = removeFromList(this.data.filterMinMaxPricingList, pricingId);
		} else {
      this.data.filterMinMaxPricingList[sizeOf(this.data.filterMinMaxPricingList)] = pricingId;
		}
		*/
		if (applicationStore.data.productType === ProductTypes.TIRES) this.filterTires(true, null);
		else if (applicationStore.data.productType === ProductTypes.PACKAGES) this.filterPackages(null);
    this.emitChange();
  },

  onPricingToggled: function(toggleAll) {
		if (toggleAll) {
      this.data.filterMinMaxPricingList = Object.keys(PricingFilter).map(Number);
    } else {
      this.data.filterMinMaxPricingList = [];
    }
    this.filterTires(true, null);
    this.emitChange();
	},

  onPackagePricingToggled: function(toggleAll) {
		if (toggleAll) {
      this.data.filterMinMaxPricingList = Object.keys(PricingFilterPackage).map(Number);
    } else {
      this.data.filterMinMaxPricingList = [];
    }
		this.filterPackages(null);
    this.emitChange();
	},

	setOeFilterValue: function (oeFilterValue) {
		this.data.originalEquipmentOnly = oeFilterValue;
		this.emitChange();
	},

	setRunFlatFilterValue: function (runFlatFilterValue) {
		this.data.runFlatFilterValue = runFlatFilterValue;
		this.filterTires(true, null);
		this.emitChange();
	},

	liveStockLoaded: function (stock) {
		this.data.listOfTireLiveStock = stock;
		this.emitChange();
	},

	setComparisonPartQuantity: function(quantityObject) {
		var filteredTire = this.data.comparisonPartList.find(tire => ((tire.get('frontTire').get('id') === quantityObject.partId)));
		var filteredIndex = this.data.comparisonPartList.findIndex(tire => ((tire.get('frontTire').get('id') === quantityObject.partId)));
		var filterQuantity = filteredTire.get('frontTire').get('quantities').find(quantity => quantity.get('value') === quantityObject.quantityValue && quantity.get('pricingContextId') === quantityObject.quantityContextId);
		var listOfParts = this.data.comparisonPartList;

		var filteredTireQuantity= filteredTire.get('frontTire').set('quantity', filterQuantity);

		listOfParts = listOfParts.set(
			filteredIndex,
			listOfParts
				.get(filteredIndex)
				.set('frontTire', filteredTireQuantity)
		);

		listOfParts = listOfParts.setIn(
			[filteredIndex, 'frontTire', 'pricing'],
			getPricingByQuantity(
				listOfParts
					.get(filteredIndex)
					.get('frontTire')
					.get('pricingDetails'), filterQuantity));

		listOfParts = listOfParts.setIn(
			[filteredIndex, 'frontTire', 'rebate'],
			getRebateByQuantity(
				listOfParts
					.get(filteredIndex)
					.get('frontTire')
					.get('pricingDetails'), filterQuantity));

		this.data.comparisonPartList = listOfParts;

		this.emitChange();
	},

	setComparisonPartQuantities: function(quantityObject) {
		var comparisonList = this.data.comparisonPartList, _this = this;
		comparisonList.map(function(part, index) {

			var filteredTire = comparisonList.find(tire => ((tire.get('frontTire').get('id') === part.get('frontTire').get('id'))));
			var filteredIndex = index;

			var filterQuantity = filteredTire.get('frontTire').get('quantities').find(quantity => quantity.get('value') === quantityObject.quantityValue
														&& quantity.get('pricingContextId') === quantityObject.quantityContextId);

			if (!filterQuantity) filterQuantity = filteredTire.get('frontTire').get('quantities').find(quantity => quantity.get('value') === quantityObject.quantityValue
																							&& quantity.get('pricingContextId') === 0);

			if (!filterQuantity) filterQuantity = filteredTire.get('frontTire').get('quantities').find(quantity => quantity.get('value') === quantityObject.quantityValue);

			if (filterQuantity) {

				var listOfParts = _this.data.comparisonPartList;

				var filteredTireQuantity = filteredTire.get('frontTire').set('quantity', filterQuantity);

				listOfParts = listOfParts.set(
					filteredIndex,
					listOfParts
						.get(filteredIndex)
						.set('frontTire', filteredTireQuantity)
				);

				listOfParts = listOfParts.setIn(
					[filteredIndex, 'frontTire', 'pricing'],
					getPricingByQuantity(
						listOfParts
							.get(filteredIndex)
							.get('frontTire')
							.get('pricingDetails'), filterQuantity));

				listOfParts = listOfParts.setIn(
					[filteredIndex, 'frontTire', 'rebate'],
					getRebateByQuantity(
						listOfParts
							.get(filteredIndex)
							.get('frontTire')
							.get('pricingDetails'), filterQuantity));

				_this.data.comparisonPartList = listOfParts;

			}

		});

		this.emitChange();
	},

	comparisonEmailRun: function(response) {
		this.data.comparisonEmailSent = true;
		this.data.comparisonEmailSuccessful = response ? true : false;
		this.emitChange();
	},

	productComparisonAdd: function(partObject) {
		var tireIsInListAlready = this.data.comparisonPartData.map(part => part.id === partObject.id);
		if (tireIsInListAlready.indexOf(true) === -1) {
			if (sizeOf(this.data.comparisonPartData) < MAXIMUM_NUMBER_PRODUCTS_IN_COMPARISON) {
				this.data.comparisonPartData.push(partObject);
				this.data.failedAddToComparison = false;
				this.data.comparisonErrorType = null;
			} else {
				this.data.failedAddToComparison = true;
				this.data.comparisonErrorType = COMPARISON_ERROR_PART_LIST_IS_FULL;
			}
		} else {
			this.data.failedAddToComparison = true;
			this.data.comparisonErrorType = COMPARISON_ERROR_PART_ALREADY_IN_LIST;
		}
		this.emitChange();
	},

	productComparisonRemove: function(partObject) {
		var tireIsInListAlready = this.data.comparisonPartData.map(part => part.id === partObject.id);
		if (tireIsInListAlready.indexOf(true) > -1) {
			_.remove(this.data.comparisonPartData, partObject);
		}
		this.emitChange();
	},

	productComparisonFetched: function(comparisonResult) {
		if (comparisonResult && comparisonResult.compareResults && sizeOf(comparisonResult.compareResults) > 0) {
			var listOfTires = comparisonResult.compareResults
				.filter(this.filterHideItems)
				.map(tireMappingFunction(false, applicationStore.data.dealerIgnoreTireStock, '', applicationStore.data.setPartQuantityToOne));

			this.data.comparisonPartList = listOfTires;
			this.data.comparisonQuantityList = comparisonResult.compareResults.map(function(partData) {
				return partData.partQuantity;
			});

			this.data.showComparison = true;
			this.data.comparisonLoadingError = false;
			this.data.comparisonErrorType = null;
			this.data.comparisonLoadedSuccessfully = true;
		} else {
			this.data.comparisonPartList = Immutable.List.of();
			this.data.comparisonQuantityList = [];
			this.data.showComparison = false;
			this.data.comparisonLoadingError = true;
			this.data.comparisonErrorType = COMPARISON_ERROR_FAILED_TO_LOAD;
			this.data.comparisonLoadedSuccessfully = false;
		}

		this.emitChange();
	},

	productComparisonError: function(errorObject) {
		this.data.failedAddToComparison = true;
		this.data.comparisonErrorType = errorObject.errorType;
		this.emitChange();
	},

	resetProductComparisonError: function() {
		this.data.failedAddToComparison = false;
		this.data.comparisonLoadingError = false;
		this.data.comparisonErrorType = null;
		this.data.comparisonLoadedSuccessfully = false;
		this.emitChange();
	},

	resetProductComparison: function() {
		//this.data.comparisonPartData = [];
		this.data.comparisonPartList = Immutable.List.of();
		this.data.comparisonQuantityList = [];
		this.data.failedAddToComparison = false;
		this.data.comparisonLoadingError = false;
		this.data.comparisonErrorType = null;
		this.data.comparisonLoadedSuccessfully = false;
		this.data.comparisonEmailSent = false;
		this.data.comparisonEmailSuccessful = false;
		this.data.runComparisonOnLoad = false;
		this.emitChange();
	},

  runProductComparison: function() {
    if (sizeOf(this.data.comparisonPartData) > 0) {

      this.data.runComparisonOnLoad = false;

      var partIdArray = [];

      this.data.comparisonPartData.map(function(part) {
        if (part && part.id) {
          partIdArray.push(part.id);
        }
      });

      var firstPartId = partIdArray[0] ? partIdArray[0] : 0;
      var secondPartId = partIdArray[1] ? partIdArray[1] : 0;
      var thirdPartId = partIdArray[2] ? partIdArray[2] : 0;

      setTimeout(() => {
        productListActions.onGetProductComparison(ProductTypes.TIRES, vehicleSelectionStore.data.vehicleId, firstPartId, secondPartId, thirdPartId);
      }, 200);

    } else {

      this.data.failedAddToComparison = true;
      this.data.comparisonErrorType = COMPARISON_ERROR_NO_PARTS;

    }

    this.emitChange();
  },

	runQuickComparison: function() {
		/*
		var tireList = this.data.filteredListOfTires;
    if (sizeOf(tireList) === 0) {
    	tireList = searchStore.data.filteredResults;
    }
    */

    this.data.runComparisonOnLoad = false;

    var partIdArray = [], list = sizeOf(this.data.productFeaturedList) > 0 ? this.data.productFeaturedList : this.data.productList;

		if (sizeOf(list) == 0) {

      list = sizeOf(this.data.filteredListOfTires) === 0 ? searchStore.data.filteredResults : this.data.filteredListOfTires;

      if (sizeOf(list) > 0) {

        partIdArray = [];
        var featuredRequired = false;
        for (var i in list.toObject()) {
          var part = list.toObject()[i];
          if (part && part.get('frontTire').get('id') && sizeOf(partIdArray) < 3 && (!featuredRequired || part.get('featured'))) {
            if (part.get('featured')) featuredRequired = true;
            this.productComparisonAdd({
              id: part.get('frontTire').get('id'),
              imageUrl: part.get('largeImage'),
              quantity: parseInt(part.get('frontTire').get('quantity').get('value'), 10),
              pricingContextId: part.get('frontTire').get('pricing').get('pricingContextId'),
            });
            partIdArray.push(part.get('frontTire').get('id'));
          }
        }

      }

		} else {

			for (var i in list) {
			  if (list[i] && i < 3) {
          if (list[i].part) {
            partIdArray.push(list[i].part.partDetails.primaryPart.itemId);
					} else {
            partIdArray.push(list[i].partDetails.primaryPart.itemId);
					}
        }
      }

		}

		if (sizeOf(partIdArray) > 0) {
      var firstPartId = partIdArray[0] ? partIdArray[0] : 0;
      var secondPartId = partIdArray[1] ? partIdArray[1] : 0;
      var thirdPartId = partIdArray[2] ? partIdArray[2] : 0;

      setTimeout(() => {
        productListActions.onGetProductComparison(ProductTypes.TIRES, vehicleSelectionStore.data.vehicleId, firstPartId, secondPartId, thirdPartId);
      }, 200);
		}

		this.emitChange();
	},

	runComparisonOnLoad: function() {
		this.data.runComparisonOnLoad = true;
		this.emitChange();
	},

	tireListIncrementPageIndex: function () {
		var tireList = this.data.filteredListOfTires, pageIndex = this.data.tireListPageIndex++;
		this.emitChange();
	},

	showOrHideBrandFilter: function () {
		if (this.data.showBrandFilter == false) this.data.showBrandFilter = true;
		else if (this.data.showBrandFilter == true) this.data.showBrandFilter = false;
		this.emitChange();
	},

	showOrHidePlyFilter: function () {
		if (this.data.showPlyFilter == false) this.data.showPlyFilter = true;
		else if (this.data.showPlyFilter == true) this.data.showPlyFilter = false;
		this.emitChange();
	},

  showOrHidePricingFilter: function () {
    if (this.data.showPricingFilter == false) this.data.showPricingFilter = true;
    else if (this.data.showPricingFilter == true) this.data.showPricingFilter = false;
    this.emitChange();
  },

	onSetChassisSearchValue: function (chassisSearch) {
		this.data.chassis = chassisSearch;
		this.emitChange();
	},

	emailPromotionOpenModal: function (data) {
		this.data.promotionModalItemType = data.itemType;
		this.data.promotionModalItemId = data.itemId;
		this.data.promotionModalPricingContextId = data.pricingContextId;
		this.data.promotionModalLink = data.url;
		this.data.promotionModalDisclaimer = data.disclaimer;

		this.data.showPromotionModal = true;
		this.data.promotionEmailSentSuccessfully = false;
		this.emitChange();
	},

	emailPromotionCloseModal: function () {
		this.data.promotionModalItemType = null;
		this.data.promotionModalItemId = null;
		this.data.promotionModalPricingContextId = null;
		this.data.promotionModalLink = null;
		this.data.promotionModalDisclaimer = null;
		this.data.showPromotionModal = false;
		this.data.promotionEmailSent = false;
		this.data.promotionEmailSentSuccessfully = false;
		this.emitChange();
	},

	emailPromotionSucceeded: function () {
		this.data.promotionEmailSent = true;
		this.data.promotionEmailSentSuccessfully = true;
		this.emitChange();
	},

	emailPromotionFailed: function () {
		this.data.promotionEmailSent = true;
		this.data.promotionEmailSentSuccessfully = false;
		this.emitChange();
	},

	onSeasonSelected: function (season) {
		this.data.season = season;

		if (applicationStore.data.productType === ProductTypes.TIRES) {
			// reload tire list
			if (this.data.searchType === SearchTypes.SEARCH_BY_MODEL) {
				setTimeout(() => { productListActions.loadTiresFromModel("SEASON"); }, 100);
			} else if (this.data.searchType === SearchTypes.SEARCH_BY_CHASSIS) {
				setTimeout(() => { productListActions.loadTiresFromChassis("SEASON"); }, 100);
			} else if (this.data.searchType === SearchTypes.SEARCH_BY_VEHICLE) {
				setTimeout(() => { productListActions.loadTires("SEASON"); }, 100);
			}
		}
		if (applicationStore.data.productType === ProductTypes.ALLOY_WHEELS) {
			this.resetWheelVisualizerSelectedWheel();
    }

		this.emitChange();
	},

  onWheelSeasonSelected: function (season) {
		this.data.season = season;
    this.resetWheelVisualizerSelectedWheel();
		this.emitChange();
	},

	onTireSizeSelected: function (tireSize) {
		this.data.tireSize = tireSize;
		this.data.vehicleSubmodelTireSizeId = tireSize;

		const tireSizeFilter = this.data.tireSizeFilters.find(tireSizeFilter => tireSizeFilter.get('key') == tireSize);

		if (tireSizeFilter) this.data.allowCreatePackage = tireSizeFilter.get('hasWheels');

		if (applicationStore.data.productType === ProductTypes.TIRES) {
			// reload tire list
			if (this.data.searchType === SearchTypes.SEARCH_BY_MODEL) {
				setTimeout(() => { productListActions.loadTiresFromModel("TIRESIZE"); }, 100);
			} else if (this.data.searchType === SearchTypes.SEARCH_BY_CHASSIS) {
				setTimeout(() => { productListActions.loadTiresFromChassis("TIRESIZE"); }, 100);
			} else if (this.data.searchType === SearchTypes.SEARCH_BY_VEHICLE) {
				setTimeout(() => { productListActions.loadTires("TIRESIZE"); }, 100);
			}
		}
		this.resetWheelVisualizerSelectedWheel();

		this.emitChange();
	},

	onLoadRatingSelected: function (loadRating) {
		this.data.loadRating = loadRating;
		this.filterTires(true, null);
		this.emitChange();
	},

	onSecondaryLoadRatingSelected: function (loadRating) {
		this.data.secondaryLoadRating = loadRating;
		this.filterTires(true, null);
		this.emitChange();
	},

	onTireSizeIdSelected: function (tireSizeId) {
		this.data.tireSize = tireSizeId;
		this.data.vehicleSubmodelTireSizeId = tireSizeId;
		const tireSizeFilter = this.data.tireSizeFilters.find(tireSizeFilter => tireSizeFilter.get('key') == tireSizeId);

		if (tireSizeFilter) this.data.allowCreatePackage = tireSizeFilter.get('hasWheels');
		this.emitChange();
	},

	resetWheelVisualizerSelectedWheel: function () {

		//console.log('resetWheelVisualizerSelectedWheel');
		//console.log(this.data.listOfWheels);
		//console.log(this.filterWheels(this.data.listOfWheels));
		//console.log(this.data.productList);

    //this.filterWheels(this.data.listOfWheels);
		this.data.wheelPageSelection = 0;
		this.emitChange();
	},

	updateIsListStaggeredOnly: function () {
		this.data.tireSizeFilters.forEach(function (tireSizeFilter) {
			if (tireSizeFilter.get('key') === this.data.tireSize && tireSizeFilter.get('staggered')) this.data.isTireSizeStaggered = true;
		}.bind(this));
	},

	onSpeedIndexSelected: function (speedIndex) {
		this.data.speedIndex = speedIndex;
		this.filterTires(true, null);
		this.emitChange();
	},

	onSecondarySpeedIndexSelected: function (speedIndex) {
		this.data.secondarySpeedIndex = speedIndex;
		this.filterTires(true, null);
		this.emitChange();
	},

	onPlyRatingSelected: function (plyRating) {
		this.data.plyRating = plyRating;
		this.filterTires(true, null);
		this.emitChange();
	},

  onTagSelected: function (tag) {
    this.data.tag = tag;
    this.filterTires(true, null);
    this.emitChange();
  },

	onWheelTireSizeSelected: function (tireSize) {
		this.data.tireSize = tireSize;
		this.data.vehicleSubmodelTireSizeId = tireSize;
		const tireSizeFilter = this.data.tireSizeFilters.find(tireSizeFilter => tireSizeFilter.get('key') == tireSize);

		if (tireSizeFilter.get('sizeTypeCode') == "U" || tireSizeFilter.get('sizeTypeCode') == "D") {
			this.data.wheelNotOriginalSizeWarning = true;
		} else {
			this.data.wheelNotOriginalSizeWarning = false;
		}

		this.resetWheelVisualizerSelectedWheel();

		this.emitChange();
	},

	closeWheelNotOriginalSizeWarning: function () {
		this.data.wheelNotOriginalSizeWarning = false;
		this.emitChange();
	},

	closeTireNotOriginalSizeWarning: function () {
		this.data.tireNotOriginalSizeWarning = false;
		this.emitChange();
	},

	onAccessoriesCategoriesSelected: function (accessoryCategories) {
		if (!isEmpty(accessoryCategories) && sizeOf(accessoryCategories) > 0) {
			this.data.accessoriesCategories = accessoryCategories;
    } else {
      this.data.accessoriesCategories = null;
		}
		this.emitChange();
	},

	onSetDefaultSeason: function (season) {
		this.data.season = parseInt(season, 10);
		this.data.originalSeason = parseInt(season, 10);
		this.emitChange();
	},

	noProductListResultsFound: function () {
		this.data.productListShowNoResultsModal = true;
		this.emitChange();
	},

	onTrimSelected: function () {
		this.data.searchType = SearchTypes.SEARCH_BY_VEHICLE;
		this.data.listOfPackages = Immutable.List.of();
		this.data.listOfWheels = Immutable.List.of();
    this.data.wheelPayload = null;
		this.data.wheelPageSelection = 0;
		this.emitChange();
	},

	onVehicleInfoLoaded: function (payload) {

    this.resetProductListLoaded();
		if (payload.vehicleOriginalTiresize) {
			this.data.vehicleInfoPayload = payload;

			this.data.speedIndex = payload.vehicleOriginalTiresize.speedIndex;
			this.data.secondarySpeedIndex = payload.vehicleOriginalTiresize.secondarySpeedIndex;

			this.data.plyRating = payload.vehicleOriginalTiresize.plyRating;
			this.data.secondaryPlyRating = payload.vehicleOriginalTiresize.secondaryPlyRating;
			this.data.loadRating = parseInt(payload.vehicleOriginalTiresize.loadRating, 10);
			this.data.secondaryLoadRating = parseInt(payload.vehicleOriginalTiresize.secondaryLoadRating, 10);
			this.data.originalTireSize = payload.vehicleOriginalTiresize.vehicleSubmodelTiresizeId;
			this.data.originalDiameter = parseInt(payload.vehicleOriginalTiresize.rimDiameter, 10);
			this.data.originalTireSizes = Immutable.fromJS(payload.vehicleFullTireSizeInfo);

			if (this.data.originalTireSizes.filter(tireSize => tireSize.get('sizeTypeCode') === 'O'
				|| tireSize.get('sizeTypeCode') === 'A'
				|| tireSize.get('sizeTypeCode') === 'S'
				|| sizeOf(tireSize.get('sizeTypeCode') === 'T')) > 1) {
				this.data.showOriginalTireSizeSelection = true;
			}
		}

    this.data.listOfTires = Immutable.List.of();
    this.data.filteredListOfTires = Immutable.List.of();
    this.data.filteredListOfWheels = Immutable.List.of();
    this.data.filteredListOfPackages = Immutable.List.of();
    this.data.featuredTireCount = 0;
    this.data.filteredBrandIds = [];
    this.data.listOfTireLiveStock = null;
    this.data.listOfWheels = Immutable.List.of();
    this.data.listOfAccessories = Immutable.List.of();
    this.data.listOfCollections = Immutable.List.of();
    this.data.listOfPackages = Immutable.List.of();

    this.data.wheelPayload = null;

    this.data.seasonCountArray = [];
    this.data.tireSizeSpecIdCountArray = [];

    this.data.tireListLoaded = false;
    this.data.wheelListLoaded = false;
    this.data.accessoryListLoaded = false;
    this.data.packageListLoaded = false;
    this.data.merchandiseListLoaded = false;

		this.data.runFlatFilterValue = SHOW_ALL_TIRES;

		if (this.data.originalSeason === null || typeof this.data.originalSeason === 'undefined') {
			this.data.originalSeason = applicationStore.data.originalSeason;
    }

		if (!this.data.loadFilterFromUrl && (payload.vehicleId !== this.data.vehicleId || this.data.season === null || typeof this.data.season === 'undefined')) {
      this.data.season = this.data.originalSeason;
		} else {
			this.data.loadFilterFromUrl = false;
		}

		this.data.vehicleId = payload.vehicleId;
		this.data.vehicleFullTireSizeInfo = payload.vehicleFullTireSizeInfo;

		this.data.accessoriesCategories = null;

		if (payload.vehicleFullTireSizeInfo) {
			this.data.tireSizeFilters = Immutable.fromJS(payload.vehicleFullTireSizeInfo)
				.sort(function (a, b) {
					if (a.get('secondaryFormattedTireSize') !== '' && b.get('secondaryFormattedTireSize') === '') return 1;
					else if (a.get('secondaryFormattedTireSize') === '' && b.get('secondaryFormattedTireSize') !== '') return -1;
					else if (a.get('rimDiameter') > b.get('rimDiameter')) return 1;
					else if (a.get('rimDiameter') < b.get('rimDiameter')) return -1;
					else if (a.get('rimDiameter') == b.get('rimDiameter') && a.get('width') > b.get('width')) return 1;
					else if (a.get('rimDiameter') == b.get('rimDiameter') && a.get('width') < b.get('width')) return -1;
				})
				.map(function (tireSize) {
					var value, tireSizeType;

					switch (tireSize.get('sizeTypeCode')) {
						case 'O':
							tireSizeType = 'original';
							break;
						case 'D':
							tireSizeType = 'down';
							break;
						case 'U':
							tireSizeType = 'up';
							break;
						case 'A':
							tireSizeType = 'alternate';
							break;
						case 'S':
							tireSizeType = 'seasonal';
							break;
						case 'T':
							tireSizeType = 'temporary';
							break;
						default:
							throw new Error('Unknown tire size ' + tireSize.get('sizeTypeCode'));
					}

					if (tireSize.get('secondaryFormattedTireSize') === '') {
						value = {
							en: tireSize.get('formattedTireSize') + sizeTypeDescriptionWrapper(languageStore.getDictionaryTranslate('tireSize.' + tireSizeType, Languages.EN)),
							fr: tireSize.get('formattedTireSize') + sizeTypeDescriptionWrapper(languageStore.getDictionaryTranslate('tireSize.' + tireSizeType, Languages.FR))
						};
					} else {
						value = {
							en: languageStore.getDictionaryTranslate('frontAbbrv', Languages.EN) +
							tireSize.get('formattedTireSize') + ' ' + languageStore.getDictionaryTranslate('rearAbbrv', Languages.EN) +
							tireSize.get('secondaryFormattedTireSize') + sizeTypeDescriptionWrapper(languageStore.getDictionaryTranslate('tireSize.' + tireSizeType, Languages.EN)),

							fr: languageStore.getDictionaryTranslate('frontAbbrv', Languages.FR) +
							tireSize.get('formattedTireSize') + ' ' + languageStore.getDictionaryTranslate('rearAbbrv', Languages.FR) +
							tireSize.get('secondaryFormattedTireSize') + sizeTypeDescriptionWrapper(languageStore.getDictionaryTranslate('tireSize.' + tireSizeType, Languages.FR))
						};
					}

					return Immutable.Map({
						key: tireSize.get('vehicleSubmodelTiresizeId'),
						tireSizeSpecId: tireSize.get('tireSizeSpecId'),
						secondaryTireSizeSpecId: tireSize.get('secondaryTireSizeSpecId'),
						seasonalSizeCode: parseInt(tireSize.get('seasonalSizeCode'), 10),
						value: value,
						formattedTireSize: tireSize.get('formattedTireSize'),
						secondaryFormattedTireSize: tireSize.get('secondaryFormattedTireSize'),
						diameter: parseInt(tireSize.get('rimDiameter'), 10),
						secondaryDiameter: parseInt(tireSize.get('secondaryRimDiameter'), 10),
						width: parseInt(tireSize.get('width'), 10),
						secondaryWidth: parseInt(tireSize.get('secondaryWidth'), 10),
						type: tireSize.get('type'),
						secondaryType: tireSize.get('secondaryType'),
						staggered: tireSize.get('secondaryFormattedTireSize') !== '',
						loadRating: parseInt(tireSize.get('loadRating'), 10),
						secondaryLoadRating: parseInt(tireSize.get('secondaryLoadRating'), 10),
						speedIndex: tireSize.get('speedIndex'),
						secondarySpeedIndex: tireSize.get('secondarySpeedIndex'),
						plyRating: tireSize.get('plyRating'),
						secondaryPlyRating: tireSize.get('secondaryPlyRating'),
						sizeTypeCode: tireSize.get('sizeTypeCode'),
						fitmentCode: tireSize.get('fitmentCode'),
						hasWheels: tireSize.get('hasWheels') === '1'
					});
				}.bind(this));
		}

		// Visualizer

		this.data.visualizerColours = payload.vehicleColourCodes;
		this.data.visualizerForeground = payload.vehicleVisualizerForegroundImageUrl;
		this.data.visualizerShadow = payload.vehicleVisualizerShadowImageUrl;

		if (payload.vehicleColourCodes) {
			this.data.colourCodes = Immutable.fromJS(payload.vehicleColourCodes).map(function (colourCode) {
				return Immutable.Map({
					code: colourCode.get('colourCode'),
					image: colourCode.get('colourCodePatchImage'),
					name: colourCode.get('colourCodeName'),
          hexCode: colourCode.get('colourHexCode'),
          shadowOpacity: colourCode.get('visualizerShadowOpacity'),
					patchImage: colourCode.get('colourCodePatchImage'),
          foregroundImage: payload.vehicleVisualizerForegroundImageUrl,
          shadowImage: payload.vehicleVisualizerShadowImageUrl
				});
			});
		}

		if (sizeOf(this.data.colourCodes) > 0) {
			this.data.colourCode = this.data.colourCodes.get(0).get('code');
			this.data.colourCodeInDetail = this.data.colourCode;
		}

		var tiresize = this.data.tireSize, list = payload.vehicleFullTireSizeInfo, isInList = false;
		for (var i in list) {
			if (list[i] && list[i].vehicleSubmodelTiresizeId == tiresize) {
        isInList = true;
        this.data.tireSize = list[i].vehicleSubmodelTiresizeId;
        break;
			}
		}

		if (!this.data.loadFilterFromUrl && (isEmpty(this.data.tireSize) || !isInList)) {

			var tireSize = '';
			if (this.data.searchType === SearchTypes.SEARCH_BY_MODEL || this.data.searchType === SearchTypes.SEARCH_BY_CHASSIS) {
				if (this.data.originalTireSize) {
					tireSize = this.data.originalTireSize;
        } else {
					tireSize = getSelectedTireSizeIfPresentOrOriginal(this.data.originalTireSize, this.data.tireSizeFilters, this.data.tireSize, this.data.season);
        }
			} else {
				tireSize = getSelectedTireSizeIfPresentOrOriginal(this.data.originalTireSize, this.data.tireSizeFilters, this.data.tireSize, this.data.season);
			}

			this.data.tireSize = tireSize;
			this.data.vehicleSubmodelTiresizeId = tireSize;
		} else {

			var temp = this.data.tireSize;
      this.data.vehicleSubmodelTiresizeId = temp;
      this.data.loadFilterFromUrl = false;

		}

		var tireSizeFilter = null;
		if (this.data.tireSizeFilters) {
			tireSizeFilter = this.data.tireSizeFilters.find(tireSizeFilter => tireSizeFilter.get('key') == this.data.tireSize);
		}

		if (tireSizeFilter) this.data.allowCreatePackage = tireSizeFilter.get('hasWheels');

		var speedIndex = null, speedIndexRear = null;
		var dealerOffset = 0;
		switch (this.data.season) {
			case 0:
				dealerOffset = applicationStore.data.dealerWinterTireSpeedRatingOffset;
				break;
			case 1:
				dealerOffset = applicationStore.data.dealerAllSeasonTireSpeedRatingOffset;
				break;
			case 2:
				dealerOffset = applicationStore.data.dealerSummerTireSpeedRatingOffset;
				break;
		}

		if (tireSizeFilter) {
			speedIndex = getWinterSpeedRating(tireSizeFilter.get('speedIndex'), (dealerOffset ? dealerOffset : 0));
			this.data.speedIndexFilters = updateSpeedRatingDropDown(this.data.speedIndexFilters, speedIndex);

			if (tireSizeFilter.get('staggered')) speedIndexRear = getWinterSpeedRating(tireSizeFilter.get('secondarySpeedIndex'), (dealerOffset ? dealerOffset : 0));
			if (tireSizeFilter.get('staggered')) this.data.secondarySpeedIndexFilters = updateSpeedRatingDropDown(this.data.secondarySpeedIndexFilters, speedIndexRear);

			this.data.speedIndex = speedIndex;
			this.data.secondarySpeedIndex = speedIndexRear;

			this.data.loadRating = tireSizeFilter.get('loadRating');
			if (tireSizeFilter && tireSizeFilter.get('loadRating')) {
				var loadRatingFilter = new Array();
				for (var i = tireSizeFilter.get('loadRating'); i <= 135; i++) {
					loadRatingFilter[sizeOf(loadRatingFilter)] = parseFloat(i);
				}
				this.data.loadRatingFilters = Immutable.fromJS(loadRatingFilter.map(loadRatingFilter => buildFilter(loadRatingFilter, loadRatingFilter)));
			}
			var loadFilters = new Array();
			for (var i = tireSizeFilter.get('loadRating'); i <= 135; i++) {
				loadFilters[sizeOf(loadFilters)] = i;
			}
			this.data.loadRatingFilters = Immutable.fromJS(loadFilters.map(loadRatingFilter => buildFilter(loadRatingFilter, loadRatingFilter)));

			this.data.secondaryLoadRating = tireSizeFilter.get('secondaryLoadRating');
			if (tireSizeFilter.get('staggered')) {
				var secondaryLoadFilters = new Array();
				for (var i = tireSizeFilter.get('secondaryLoadRating'); i <= 135; i++) {
					secondaryLoadFilters[sizeOf(secondaryLoadFilters)] = i;
				}
				this.data.secondaryLoadRatingFilters = Immutable.fromJS(secondaryLoadFilters.map(loadRatingFilter => buildFilter(loadRatingFilter, loadRatingFilter)));
			}

      var plyRatingFilters = ["LL", "SL", "XL", "B", "C", "D", "E", "F", "G", "H", "J", "L", "M", "N"];
      //this.data.plyRating = plyRatingFilters.slice(plyRatingFilters.indexOf(tireSizeFilter.get('plyRating')));
			this.data.plyRatingFilters = Immutable.fromJS(buildPlyRatingFilter(tireSizeFilter.get('plyRating')).map(plyRatingFilter => buildFilter(plyRatingFilter, plyRatingFilter)));

			switch (tireSizeFilter.get('secondaryPlyRating')) {
				case "LL":
					this.data.secondaryPlyRating = ["LL", "SL"];
					break;
				case "SL":
					this.data.secondaryPlyRating = ["SL", "XL"];
					break;
				default:
					this.data.secondaryPlyRating = tireSizeFilter.get('secondaryPlyRating');
					break;
			}
			this.data.secondaryPlyRatingFilters = Immutable.fromJS(buildPlyRatingFilter(tireSizeFilter.get('secondaryPlyRating')).map(plyRatingFilter => buildFilter(plyRatingFilter, plyRatingFilter)));

			this.data.tireSizeType = tireSizeFilter.get('sizeTypeCode');
			this.data.tireSizeSpecId = tireSizeFilter.get('tireSizeSpecId');
			this.data.secondaryTireSizeSpecId = tireSizeFilter.get('secondaryTireSizeSpecId');
			this.data.vehicleSubmodelTiresizeId = tireSizeFilter.get('key');

			if (tireSizeFilter.get('secondaryTireSizeSpecId') !== null
				&& typeof tireSizeFilter.get('secondaryTireSizeSpecId') !== 'undefined'
				&& tireSizeFilter.get('secondaryTireSizeSpecId') > 0) {
				this.data.isTireSizeStaggered = true;
			} else {
				this.data.isTireSizeStaggered = false;
			}

			if (tireSizeFilter.get('sizeTypeCode') !== "O" && (tireSizeFilter.get('sizeTypeCode') == "U" || tireSizeFilter.get('sizeTypeCode') == "D")) {
				this.data.tireNotOriginalSizeWarning = true;
				this.data.wheelNotOriginalSizeWarning = true;
			} else {
				this.data.tireNotOriginalSizeWarning = false;
				this.data.wheelNotOriginalSizeWarning = false;
			}

			this.updateIsListStaggeredOnly();
		}

    this.data.productList = [];
    this.data.productFeaturedList = [];

		this.data.centerWheelInVisualizer = false;
		this.data.wheelVisualizerPopModal = false;
		this.data.wheelVisualizerSlideShowIds = Immutable.List.of();

    this.data.showWarning = false;
    this.data.warning = {};

		this.resetProductComparison();

		this.emitChange();
	},

	onChangeColour: function (code) {
		this.data.colourCode = code;
		this.emitChange();
	},

	onChangeColourInDetail: function (code) {
		this.data.colourCodeInDetail = code;
		this.emitChange();
	},

	filterHideItems: function (item) {
		var hideItem = true;
		if (checkArrayFieldsExist(item, ['part', 'partSummary', 'primaryPart', 'hideItem'])) {
			hideItem = item['part']['partSummary']['primaryPart']['hideItem'];
		} else if (checkArrayFieldsExist(item, ['partSummary', 'primaryPart', 'hideItem'])) {
			hideItem = item['partSummary']['primaryPart']['hideItem'];
		}
		return !hideItem;
	},

	onListOfTiresLoaded: function (payload) {

    this.data.wheelPayload = null;
    this.data.loadRatingList = [];
    this.data.speedRatingList = [];
    this.data.plyRatingList = [];

    var brandList = [], min = 10000, max = 0, loadratinglist = [], speedratinglist = [], plyratinglist = [];
		if (payload && payload.partList) {

			var speedList = getSpeedList();
			var plyList = getPlyList();

      this.data.season = payload.resultParameters.season;
      this.data.tireSize = payload.resultParameters.vehicleSubmodelTireSizeId;
      this.data.originalEquipmentFilter = payload.resultParameters.originalEquipmentFilter;

      if (isEmpty(payload.resultParameters.season)) {
      	if (payload.partListSummary.seasonList[0].partCount > 0) {
          this.data.season = 0;
        } else if (payload.partListSummary.seasonList[1].partCount > 0) {
          this.data.season = 1;
        } else if (payload.partListSummary.seasonList[2].partCount > 0) {
          this.data.season = 2;
        }
			}

      this.data.seasonCountArray = {
        0: payload.partListSummary.seasonList[0].partCount,
        1: payload.partListSummary.seasonList[1].partCount,
        2: payload.partListSummary.seasonList[2].partCount
      };

      var countList = [], seasonList = payload.partListSummary.seasonList, temp = seasonList[payload.resultParameters.season] ? seasonList[payload.resultParameters.season].tireSizeList : [];
      if (!isEmpty(temp)) {
        for (var i in temp) {
          if (temp[i]) {
            countList[temp[i].vehicleSubmodelTiresizeId] = temp[i].partCount;
          }
        }
      }
      this.data.tireSizeSpecIdCountArray = countList;

      this.data.tireFilterTagList = payload.partListSummary.metatagList;

      this.data.productList = payload.partList;
      this.data.productFeaturedList = payload.featuredPartList;

      var list = payload.partList;
      for (var i in list) {
        if (list[i]) {
          if (this.data.maxLoadRating < parseInt(list[i].partDetails.primaryPart.tireLoadRating)) {
            this.data.maxLoadRating = parseInt(list[i].partDetails.primaryPart.tireLoadRating);
          }
          if (loadratinglist.indexOf(list[i].partDetails.primaryPart.tireLoadRating) == -1) {
            loadratinglist.push(list[i].partDetails.primaryPart.tireLoadRating);
          }
          if (speedratinglist.indexOf(list[i].partDetails.primaryPart.tireSpeedIndex) == -1) {
            speedratinglist.push(list[i].partDetails.primaryPart.tireSpeedIndex);
          }
          if (plyratinglist.indexOf(list[i].partDetails.primaryPart.tirePlyRating) == -1) {
            plyratinglist.push(list[i].partDetails.primaryPart.tirePlyRating);
          }
          if (this.data.maxSpeedRating < speedList.indexOf(list[i].partDetails.primaryPart.tireSpeedIndex)) {
            this.data.maxSpeedRating = speedList.indexOf(list[i].partDetails.primaryPart.tireSpeedIndex);
          }
          if (this.data.maxPlyRating < plyList.indexOf(list[i].partDetails.primaryPart.tirePlyRating)) {
            this.data.maxPlyRating = plyList.indexOf(list[i].partDetails.primaryPart.tirePlyRating);
          }

					if (brandList.indexOf(list[i].partDetails.primaryPart.tireManufacturerId) === -1) {
          	brandList.push(list[i].partDetails.primaryPart.tireManufacturerId);
					}

          var price = 0, context = [], temp = [], priceList = list[i].partPricing.primaryPart.price;
          for (var i in priceList) {
          	if (priceList[i]) {
              context = priceList[i].contextList; break;
						}
					}
					for (var i in context) {
          	if (context[i] && price < parseFloat(context[i].displayPrice)) {
          		price = parseFloat(context[i].displayPrice);
						}
					}

          if (min > price) {
            min = price;
          }
          if (max < price) {
            max = price;
          }
        }
      }

      this.data.filteredBrandIds = brandList;

      this.data.tireListMinPrice = min;
      this.data.tireListMaxPrice = max;

      this.data.tireListLoaded = true;
      this.data.wheelListLoaded = false;
      this.data.accessoryListLoaded = false;
      this.data.packageListLoaded = false;
      this.data.merchandiseListLoaded = false;

      if (sizeOf(loadratinglist) > 0) {
        this.data.loadRatingList = loadratinglist.sort(function(a, b) { return a - b; });
      }

      if (sizeOf(speedratinglist) > 0) {
        this.data.speedRatingList = speedratinglist.sort(function(a, b) { return a - b; });
      }

      if (sizeOf(plyratinglist) > 0) {
        this.data.plyRatingList = plyratinglist.sort(function(a, b) { return a - b; });
      }
		} else {

      this.data.productList = payload.partList;
			this.data.productFeaturedList = payload.featuredPartList;

		}

		this.emitChange();
	},

  onListOfTiresLoadedFromModel: function (payload) {
    this.onListOfTiresLoaded(payload);
    this.emitChange();
  },

  onListOfTiresLoadedFromChassis: function (payload) {
    this.onListOfTiresLoaded(payload);
    this.emitChange();
  },

	onListOfTiresLoadedByModel: function(payload) {
    if (payload && payload.hasOwnProperty("partList")) {

      this.data.tireModelArray = {};
      this.data.tireModelTiresizeArray = {};

      var listOfTires = [], filteredList = payload.partList.filter(this.filterHideItems);
      for (var i in filteredList) {
        listOfTires[sizeOf(listOfTires)] = tireObjectFunction(filteredList[i], false, applicationStore.data.dealerIgnoreTireStock, '', applicationStore.data.setPartQuantityToOne);
      }

      this.data.listOfTires = listOfTires;
      this.data.filteredListOfTires = listOfTires;
    }
    this.emitChange();
	},

  onTireLoadedFromInfo: function(payload) {
    var tire = null;
    if (payload) tire = tireObjectFunction(payload, false, applicationStore.data.dealerIgnoreTireStock, '', applicationStore.data.setPartQuantityToOne);
    this.data.selectedTireData = tire;
    this.emitChange();
	},

  onListOfPackagesLoaded: function (payload) {
    this.data.productList = payload;
    this.data.productFeaturedList = [];

    this.data.visualizerPartList = [];
    this.data.selectedVisualizerPart = null;
    this.data.listOfPackages = Immutable.List.of();
    var listOfPackages = payload
      .filter(this.filterHideItems)
      .map(packageMappingFunction(false, applicationStore.data.dealerIgnorePackageStock, applicationStore.data.setPartQuantityToOne));

    var packageMinPrice = Number.MAX_SAFE_INTEGER, packageMaxPrice = 0;
    var filteredListOfPackages = listOfPackages.sort(function(pkgA, pkgB) {
      if (pkgA.get('pricing').get('displayPrice') > packageMaxPrice) packageMaxPrice = pkgA.get('pricing').get('displayPrice');
      if (pkgA.get('pricing').get('displayPrice') < packageMinPrice) packageMinPrice = pkgA.get('pricing').get('displayPrice');
      if (pkgB.get('pricing').get('displayPrice') > packageMaxPrice) packageMaxPrice = pkgB.get('pricing').get('displayPrice');
      if (pkgB.get('pricing').get('displayPrice') < packageMinPrice) packageMinPrice = pkgB.get('pricing').get('displayPrice');
      var pkgA_featured = pkgA.get('pricing').get('pricingContextId') > 0;
      var pkgB_featured = pkgB.get('pricing').get('pricingContextId') > 0;
      if (pkgA_featured) return -1;
      else if (pkgB_featured) return 1;
      else return 0;
    });

    if (sizeOf(filteredListOfPackages) > 0) {
      this.data.visualizerPartList = collectVisualizerPartList(ProductTypes.PACKAGES, payload);
      this.data.selectedVisualizerPart = {
        part: filteredListOfPackages ? filteredListOfPackages[0] : null,
        id: filteredListOfPackages ? filteredListOfPackages[0].get('id') : null
      };
    }

    this.data.listOfPackages = filteredListOfPackages;
    this.data.filteredListOfPackages = filteredListOfPackages;
    this.data.packageListMinPrice = packageMinPrice;
    this.data.packageListMaxPrice = packageMaxPrice;


    this.data.tireListLoaded = false;
    this.data.wheelListLoaded = false;
    this.data.accessoryListLoaded = false;
    this.data.packageListLoaded = true;
    this.data.merchandiseListLoaded = false;

    this.emitChange();
  },

	onSetProductListSearchType: function (searchType) {
		this.data.searchType = searchType;
		this.emitChange();
	},

	onSetPartForVisualizer: function (part) {
		var newPart = null, getVisualizerPartId = this.getVisualizerPartId;
		if (part && part.partType === ProductTypes.ALLOY_WHEELS) newPart = this.data.listOfWheels.find(wheel => getVisualizerPartId(wheel) == part.id);
		else if (part && part.partType === ProductTypes.ACCESSORIES) newPart = this.data.listOfAccessories.find(accessory => getVisualizerPartId(accessory) == part.id);
		else if (part && part.partType === ProductTypes.PACKAGES) newPart = this.data.listOfPackages.find(pkg => getVisualizerPartId(pkg) == part.id);
		if (newPart) {
			this.data.selectedVisualizerPart = {
				part: newPart,
				id: part.id
			};
		}
    this.emitChange();
	},

	getVisualizerPartId: function(part) {
		switch(applicationStore.data.productType) {
			case ProductTypes.TIRES:
				return part.get('frontTire').get('id');
			case ProductTypes.ALLOY_WHEELS:
        return part.get('frontWheel').get('id');
			case ProductTypes.ACCESSORIES:
			case ProductTypes.PACKAGES:
        return part.get('id');
		}
	},

	onListOfWheelsLoaded: function (payload) {


    if (payload) {

      //this.data.season = payload.resultParameters.season;
      //this.data.tireSize = payload.resultParameters.vehicleSubmodelTireSizeId;

			/*
      this.data.seasonCountArray = {
        0: payload.partListSummary.seasonList[0].partCount,
        1: payload.partListSummary.seasonList[1].partCount,
        2: payload.partListSummary.seasonList[2].partCount
      };
      */

      this.data.productList = payload;

      var tiresizeCount = [], wheelWidthArray = [];
      this.data.tireSizeFilters.map(function(tiresize) {
        tiresizeCount[tiresize.get('key')] = 0;
        wheelWidthArray[tiresize.get('key')] = { diameter: tiresize.get('diameter'), width: tiresize.get('width') };
			});

      var list = payload, min = 10000, max = 0, seasonCount = { 0: 0, 1: 0, 2: 0 }, season = this.data.season;
      for (var i in list) {
        if (list[i]) {

					switch(list[i].partDetails.primaryPart.wheelSeasonalSizeCode) {
						case "0":
              seasonCount[0]++;
              seasonCount[1]++;
              seasonCount[2]++;
              break;
						case "1":
              seasonCount[0]++;
              break;
						case "2":
              seasonCount[2]++;
              break;
					}

					for (var j in wheelWidthArray) {
						if (
							wheelWidthArray[j]
							&& isValidSeason(list[i].partDetails.primaryPart.wheelSeasonalSizeCode, season)
							&& isValidWidth(list[i].partDetails.primaryPart.wheelWidth, wheelWidthArray[j].width)
							&& list[i].partDetails.primaryPart.wheelDiameter == wheelWidthArray[j].diameter
							) {
              tiresizeCount[j]++;
						}
					}

          var price = 0, context = [], temp = [], priceList = list[i].partPricing.primaryPart.price;
          for (var i in priceList) {
            if (priceList[i]) {
              context = priceList[i].contextList;
              break;
            }
          }
          for (var i in context) {
            if (context[i] && price < parseFloat(context[i].displayPrice)) {
              price = parseFloat(context[i].displayPrice);
            }
          }

          if (min > price) {
            min = price;
          }
          if (max < price) {
            max = price;
          }
        }
      }

      if (tiresizeCount[this.data.tireSize] == 0) {
				for (var i in tiresizeCount) {
					if (tiresizeCount[i] && tiresizeCount[i] > 0) {
            this.data.tireSize = i;
					}
				}
			}

      this.data.seasonCountArray = seasonCount;
      this.data.tireSizeSpecIdCountArray = tiresizeCount;

      this.data.wheelListMinPrice = min;
      this.data.wheelListMaxPrice = max;

      this.data.tireListLoaded = false;
      this.data.wheelListLoaded = true;
      this.data.accessoryListLoaded = false;
      this.data.packageListLoaded = false;
      this.data.merchandiseListLoaded = false;

    }

    this.emitChange();


		/*
		if (payload) {
			this.data.wheelPayload = payload;
      this.data.visualizerPartList = [];
      this.data.selectedVisualizerPart = null;
      const tireSizeFilter = this.data.tireSizeFilters.find(tireSizeFilter => tireSizeFilter.get('key') == this.data.tireSize);
      var fitmentCode = '';
      if (tireSizeFilter) fitmentCode = tireSizeFilter.get('fitmentCode');

      var listOfWheels = payload
        .filter(this.filterHideItems)
        .map(wheelMappingFunction(false, applicationStore.data.dealerIgnoreWheelStock, fitmentCode, applicationStore.data.setPartQuantityToOne));

      this.data.visualizerPartList = collectVisualizerPartList(ProductTypes.ALLOY_WHEELS, payload);
      this.filterWheels(listOfWheels);

			if (tireSizeFilter) {
        var originalSizeExistsInList = false;
        for (var i in payload) {
          if (payload[i].partDetails.primaryPart.wheelDiameter === tireSizeFilter.get('diameter')) {
            originalSizeExistsInList = true;
            break;
          }
        }

        if (tireSizeFilter.get('sizeTypeCode') !== "O" && (tireSizeFilter.get('sizeTypeCode') == "U" || tireSizeFilter.get('sizeTypeCode') == "D" || !originalSizeExistsInList)) {
          this.data.wheelNotOriginalSizeWarning = true;
        } else {
          this.data.wheelNotOriginalSizeWarning = false;
        }
      }

      this.data.listOfWheels = listOfWheels;
    }

    this.getWheelCountList();

		this.emitChange();
		*/
	},

	getWheelCountList: function() {
    var payload = this.data.wheelPayload, seasonCount = { 0: 0, 1: 0, 2: 0 }, tiresizeCount = [], tiresizeList = this.data.vehicleFullTireSizeInfo, wheelCount = 0;
    for (var n in tiresizeList) {
      if (tiresizeList[n]) {
      	if (tiresizeList[n].vehicleSubmodelTiresizeId) {
          tiresizeCount[tiresizeList[n].vehicleSubmodelTiresizeId] = 0;
				} else {

				}
      }
    }

    for (var i in payload) {
      if (payload[i]) {

        if (payload[i].partDetails.primaryPart.wheelSeasonalSizeCode === "0") {
          seasonCount[0]++;
          seasonCount[1]++;
          seasonCount[2]++;
          for (var n in tiresizeList) {
            if (tiresizeList[n]) {
              if (parseInt(tiresizeList[n].rimDiameter) === parseInt(payload[i].partDetails.primaryPart.wheelDiameter)) {
                tiresizeCount[tiresizeList[n].vehicleSubmodelTiresizeId]++;
                wheelCount++;
              }
            }
          }
        } else if (payload[i].partDetails.primaryPart.wheelSeasonalSizeCode === "1") {
          seasonCount[0]++;
          if (this.data.season == 0) {
            for (var n in tiresizeList) {
              if (tiresizeList[n]) {
                if (parseInt(tiresizeList[n].rimDiameter) === parseInt(payload[i].partDetails.primaryPart.wheelDiameter)) {
                  tiresizeCount[tiresizeList[n].vehicleSubmodelTiresizeId]++;
                  wheelCount++;
                }
              }
            }
          }
        } else if (payload[i].partDetails.primaryPart.wheelSeasonalSizeCode === "2") {
          seasonCount[2]++;
          if (this.data.season == 2) {
            for (var n in tiresizeList) {
              if (tiresizeList[n]) {
                if (parseInt(tiresizeList[n].rimDiameter) === parseInt(payload[i].partDetails.primaryPart.wheelDiameter)) {
                  tiresizeCount[tiresizeList[n].vehicleSubmodelTiresizeId]++;
                  wheelCount++;
                }
              }
            }
          }
        }

      }
    }

    if (wheelCount === 0) {

      for (var i in seasonCount) {
        if (seasonCount[i] && seasonCount[i] > 0) {
          setTimeout(() => { productListActions.filterWheelBySeason(i); }, 100);
          break;
        }
      }

    } else if (tiresizeCount[this.data.tireSize] === 0) {
      for (var i in tiresizeCount) {
        if (tiresizeCount[i] && tiresizeCount[i] > 0) {
          setTimeout(() => { productListActions.filterWheelByTireSize(i); }, 100);
          break;
        }
      }
    } else  {

      this.data.seasonCountArray = seasonCount;
      this.data.tireSizeSpecIdCountArray = tiresizeCount;

      this.data.tireListLoaded = false;
      this.data.wheelListLoaded = true;
      this.data.accessoryListLoaded = false;
      this.data.packageListLoaded = false;
      this.data.merchandiseListLoaded = false;

    }
	},

	// onListOfAccessoriesLoaded: function (payload) {
	//
  //   console.log('onListOfAccessoriesLoaded');
  //   console.log(payload);
	//
  //   this.data.productList = payload.partList;
  //   this.data.accessoryFilterTagList = payload.partListSummary.metatagList;
  //   this.data.productFeaturedList = [];
	//
  //   this.data.tireListLoaded = false;
  //   this.data.wheelListLoaded = false;
  //   this.data.accessoryListLoaded = true;
  //   this.data.packageListLoaded = false;
  //   this.data.merchandiseListLoaded = false;
	//
	//
  //   var categoryShadowLisr = [], list = payload.partList, min = 10000, max = 0;
  //   for (var i in list) {
  //     if (list[i]) {
	//
  //       if (sizeOf(list[i].partDetails.primaryPart.accessoryCategories) > 0) {
	//
  //       	for (var j in list[i].partDetails.primaryPart.accessoryCategories) {
	//
  //       		for (var n in list[i].partDetails.primaryPart.accessoryCategories[j]) {
	//
  //             if (categoryShadowLisr.indexOf(n) === -1 ) {
  //               categoryShadowLisr.push(n);
  //             }
	//
	// 					}
	//
	// 				}
	//
  //       }
	//
  //       var price = 0, context = [], temp = [], priceList = list[i].partPricing.primaryPart.price;
  //       for (var i in priceList) {
  //         if (priceList[i]) {
  //           context = priceList[i].contextList; break;
  //         }
  //       }
  //       for (var i in context) {
  //         if (context[i] && price < parseFloat(context[i].displayPrice)) {
  //           price = parseFloat(context[i].displayPrice);
  //         }
  //       }
	//
  //       if (min > price) {
  //         min = price;
  //       }
  //       if (max < price) {
  //         max = price;
  //       }
	//
  //     }
  //   }
	// 	console.log(categoryShadowLisr);
	// 	console.log(min);
	// 	consoneol.log(max);
  //   this.data.accessoryCategoryShadowList = categoryShadowLisr;
  //   this.data.accessoryListMinPrice = min;
  //   this.data.accessoryListMaxPrice = max;
	//
	//
	// 	/*
	// 	this.data.listOfAccessories = Immutable.List.of();
	// 	payload = this.sortAccessoryListAlphabetically(payload);
	// 	payload = this.sortAccessoryListPromotedFirst(payload);
	// 	var listOfAccessories = payload.filter(this.filterHideItems).map(accessoryMappingFunction(false, applicationStore.data.dealerIgnoreAccessoryStock, applicationStore.data.setPartQuantityToOne));;
	//
	// 	this.data.listOfAccessories = listOfAccessories;
	//
	// 	this.data.tireListLoaded = false;
	// 	this.data.wheelListLoaded = false;
	// 	this.data.accessoryListLoaded = true;
	// 	this.data.packageListLoaded = false;
	// 	this.data.merchandiseListLoaded = false;
	// 	*/
	//
	// 	this.emitChange();
	// },

	sortAccessoryListAlphabetically: function (list) {
		return list.sort(function (a, b) {
			if (a.partDetails.primaryPart.accessoryName[getAppLanguage()].toLowerCase() < b.partDetails.primaryPart.accessoryName[getAppLanguage()].toLowerCase()) return -1;
			else if (a.partDetails.primaryPart.accessoryName[getAppLanguage()].toLowerCase() > b.partDetails.primaryPart.accessoryName[getAppLanguage()].toLowerCase()) return 1;
			else return 0;
		})
	},

	sortAccessoryListPromotedFirst: function (list) {
		return list.sort(function (a, b) {
			var promotionFlag1 = false;
			for (var i in a.partPricing.primaryPart.price) {
				for (var j in a.partPricing.primaryPart.price[i].contextList) {
					if (a.partPricing.primaryPart.price[i].contextList[j].pricingContextId > 0) promotionFlag1 = true;
				}
			}
			if (!promotionFlag1) {
				for (var i in a.partPricing.primaryPart.link) {
					for (var j in a.partPricing.primaryPart.link[i].contextList) {
						if (a.partPricing.primaryPart.link[i].contextList[j].pricingContextId > 0) promotionFlag1 = true;
					}
				}
			}
			var promotionFlag2 = false;
			for (var i in b.partPricing.primaryPart.price) {
				for (var j in b.partPricing.primaryPart.price[i].contextList) {
					if (b.partPricing.primaryPart.price[i].contextList[j].pricingContextId > 0) promotionFlag2 = true;
				}
			}
			if (!promotionFlag2) {
				for (var i in b.partPricing.primaryPart.link) {
					for (var j in b.partPricing.primaryPart.link[i].contextList) {
						if (b.partPricing.primaryPart.link[i].contextList[j].pricingContextId > 0) promotionFlag2 = true;
					}
				}
			}
			if (promotionFlag1 && promotionFlag2) return 0;
			else if (promotionFlag1 && !promotionFlag2) return -1;
			else if (!promotionFlag1 && promotionFlag2) return 1;
			else if (!promotionFlag1 && !promotionFlag2) return 0;
		});
	},

	updateListOfTires: function (updateQuantities) {
		var staggered = this.data.isTireSizeStaggered;
		var vehicleSubmodelTireSizeId = this.data.vehicleSubmodelTireSizeId;

		if (updateQuantities) {
			var selectedTireSizeFilter = this.data.tireSizeFilters.find(function (tireSizeFilter) {
				return parseFloat(tireSizeFilter.get('key')) === vehicleSubmodelTireSizeId
			});

			var quantityIndex = { front: null, rear: null };
			switch (selectedTireSizeFilter.get('fitmentCode')) {
				case "AFS":
					quantityIndex = { front: 4, rear: null };
					break;
				case "SFS":
					quantityIndex = { front: 2, rear: 2 };
					break;
				case "AFD":
					quantityIndex = { front: 6, rear: null };
					break;
				case "SFD":
					quantityIndex = { front: 2, rear: 4 };
					break;
				default:
					quantityIndex = staggered ? { front: 2, rear: 2 } : { front: 4, rear: null };
					break;
			}

			this.data.listOfTires = this.data.listOfTires.map(function (tire) {
				var frontTire = tire.get('frontTire'), quantities = frontTire.get('quantities'), tempQuantity = 0;
				quantities.forEach(function (quantity) {
					if (quantity.get('value') == quantityIndex.front && tempQuantity === 0) {
						tempQuantity = quantity;
					}
				});
				if (tempQuantity === 0 || typeof tempQuantity === 'undefined') {
					tempQuantity = quantities.get(0);
				}
				var quantity = tempQuantity;
				frontTire = frontTire.set('quantity', quantity);
				tire = tire.set('frontTire', frontTire);

				var rearTire = tire.get('rearTire');
				if (rearTire) {
					var quantitiesRear = rearTire.get('quantities');
					var tempQuantityRear = 0;
					quantitiesRear.forEach(function (quantity) {
						if (quantity.get('value') == quantityIndex.rear && tempQuantityRear === 0) {
							tempQuantityRear = quantity;
						}
					});
					if (tempQuantityRear === 0 || typeof tempQuantityRear === 'undefined') {
						tempQuantityRear = quantitiesRear.get(0);
					}
					var quantityRear = tempQuantityRear;
					rearTire = rearTire.set('quantity', quantityRear);
					tire = tire.set('rearTire', rearTire);
				}

				return tire;
			});
		}

		var featuredCount = 0;
		for (var i in this.data.listOfTires) if (this.data.listOfTires[i].get('featured')) { featuredCount++; }

		var filteredListOfTires = this.filterTires(false, null);

		var partListBrands = [];
		filteredListOfTires.forEach(tire => {
			partListBrands[sizeOf(partListBrands)] = tire.get('manufacturerId');
		});
		if (partListBrands) partListBrands = _.uniq(partListBrands);

		this.data.filteredListOfTires = filteredListOfTires;
		this.data.featuredTireCount = featuredCount;
		this.data.filteredBrandIds = partListBrands;

		this.data.tireListLoaded = true;
		this.data.wheelListLoaded = false;
		this.data.accessoryListLoaded = false;
		this.data.packageListLoaded = false;
		this.data.merchandiseListLoaded = false;

		this.emitChange();
	},

	filterPackages: function(packageList) {
    var filterMinMaxPricingList = this.data.filterMinMaxPricingList;
    var filterMinValue = PricingFilter[getMinFromList(filterMinMaxPricingList)];
    if (typeof(filterMinValue) !== 'undefined') filterMinValue = parseInt(filterMinValue.min);
    var filterMaxValue = PricingFilter[getMaxFromList(filterMinMaxPricingList)];
    if (typeof(filterMaxValue) !== 'undefined') filterMaxValue = filterMaxValue.max !== null ? parseInt(filterMaxValue.max) : null;
    var listOfPackages = packageList !== null ? packageList : this.data.listOfPackages;

    var packageMinPrice = Number.MAX_SAFE_INTEGER, packageMaxPrice = 0;
    var filteredListOfPackages = Immutable.List(listOfPackages).filter(function (pkg, index) {

      var frontDisplay = parseFloat(pkg.get('pricing').get('displayPrice'));
      if (frontDisplay < packageMinPrice) packageMinPrice = frontDisplay;
      if (frontDisplay > packageMaxPrice) packageMaxPrice = frontDisplay;

			return (typeof(filterMinValue) === 'undefined' || !pkg.get('hasPricing') || frontDisplay >= filterMinValue)
        && (typeof(filterMaxValue) === 'undefined' || filterMaxValue === null || !pkg.get('hasPricing') || frontDisplay <= filterMaxValue)
    });

    this.data.filteredListOfPackages = filteredListOfPackages;
    this.data.packageListMinPrice = packageMinPrice;
    this.data.packageListMaxPrice = packageMaxPrice;
    this.emitChange();

    return filteredListOfPackages;
	},

	filterTires: function (logImpressions, tireList) {
		var staggered = this.data.isTireSizeStaggered;
		var season = this.data.season;
		var tireSizeSpecId = this.data.tireSizeSpecId;
		var loadRating = this.data.loadRating;
		var speedIndex = this.data.speedIndex;
		var plyRating = this.data.plyRating;
		var tag = this.data.tag;
		var secondaryTireSizeSpecId = this.data.secondaryTireSizeSpecId;
		var secondaryLoadRating = this.data.secondaryLoadRating;
		var secondarySpeedIndex = this.data.secondarySpeedIndex;
		var secondaryPlyRating = this.data.plyRating;
		var brands = this.data.brands;
		var originalEquipmentOnly = this.data.originalEquipmentOnly;
		var runFlatFilterValue = this.data.runFlatFilterValue;

		var filterMinMaxPricingList = this.data.filterMinMaxPricingList;
		var filterMinValue = PricingFilter[getMinFromList(filterMinMaxPricingList)];
		if (typeof(filterMinValue) !== 'undefined') filterMinValue = parseInt(filterMinValue.min);
		var filterMaxValue = PricingFilter[getMaxFromList(filterMinMaxPricingList)];
    if (typeof(filterMaxValue) !== 'undefined') filterMaxValue = filterMaxValue.max !== null ? parseInt(filterMaxValue.max) : null;

		var tireBrandIsValid = this.tireBrandIsValid;

		var tireRunFlatCheck = (function (runFlatFilterValue, tire) {
			switch (runFlatFilterValue) {
				case SHOW_ALL_TIRES:
					return true;
				case SHOW_RUN_FLAT_TIRES:
					return tire.get('frontTire').get('runFlat') === '1';
				case SHOW_NON_RUN_FLAT_TIRES:
					return tire.get('frontTire').get('runFlat') === '0';
			}
		});

		var listOfTires = tireList !== null ? tireList : this.data.listOfTires;

		var tireMinPrice = Number.MAX_SAFE_INTEGER, tireMaxPrice = 0;
		var filteredListOfTires = Immutable.List(listOfTires).filter(function (tire, index) {

			var frontDisplay = parseFloat(tire.get('frontTire').get('pricing').get('displayPrice'));
			if (frontDisplay < tireMinPrice) tireMinPrice = frontDisplay;
			if (tire.get('staggered') && parseFloat(tire.get('rearTire').get('pricing').get('displayPrice')) < tireMinPrice) tireMinPrice = parseFloat(tire.get('rearTire').get('pricing').get('displayPrice'));

			if (frontDisplay > tireMaxPrice) tireMaxPrice = frontDisplay;
			if (tire.get('staggered') && parseFloat(tire.get('rearTire').get('pricing').get('displayPrice')) > tireMaxPrice) tireMaxPrice = parseFloat(tire.get('rearTire').get('pricing').get('displayPrice'));

			return tire.get('frontTire').get('loadRating') >= loadRating
				&& speedRatingIsValid(tire.get('speedRating'), speedIndex)
        && (typeof(filterMinValue) === 'undefined' || !tire.get('frontTire').get('hasPricing') || frontDisplay >= filterMinValue)
        && (typeof(filterMaxValue) === 'undefined' || filterMaxValue === null || !tire.get('frontTire').get('hasPricing') || frontDisplay <= filterMaxValue)

				&& (!tire.get('staggered') || (
					tire.get('rearTire').get('tireSizeSpecId') == secondaryTireSizeSpecId
					&& tire.get('rearTire').get('loadRating') >= secondaryLoadRating
					&& speedRatingIsValid(tire.get('rearTire').get('speedRating'), secondarySpeedIndex)
					&& plyRatingIsValid(tire.get('rearTire').get('plyRating'), secondaryPlyRating)
					&& (typeof(filterMinValue) === 'undefined' || !tire.get('rearTire').get('hasPricing') || parseFloat(tire.get('rearTire').get('pricing').get('displayPrice')) >= filterMinValue)
					&& (typeof(filterMaxValue) === 'undefined' || filterMaxValue === null || !tire.get('rearTire').get('hasPricing') || parseFloat(tire.get('rearTire').get('pricing').get('displayPrice')) <= filterMaxValue)
				))

        && tireRunFlatCheck(runFlatFilterValue, tire)
				&& (!originalEquipmentOnly || (originalEquipmentOnly && tire.get('originalEquipment')))

        && (sizeOf(plyRating) === 0 || sizeOf(plyRating.filter(function(key) { if (tire.get('frontTire').get('plyRating') == key) { return key; } else { return false; } })) > 0)
        && (sizeOf(brands) === 0 || sizeOf(brands.filter(function(key) { if (tire.get('manufacturerId') == key) { return key; } else { return false; } })) > 0)
        && (sizeOf(tag) === 0 || sizeOf(tag.filter(function(key) { if (tire.get('frontTire').get('tireCategoryTags').indexOf(key) > -1) { return key; } else { return false; } })) > 0)
			}
		);

		this.data.filteredListOfTires = filteredListOfTires;
		this.data.tireListMinPrice = tireMinPrice;
		this.data.tireListMaxPrice = tireMaxPrice;
		this.emitChange();
    return filteredListOfTires;
	},

  filterWheels: function (listOfWheels) {
    var tireSizeInfo = this.data.tireSizeFilters.find(tireSize => tireSize.get('key') === this.data.tireSize);
    var diameter = null, rearDiameter = null, width = null, rearWidth = null, type = null, rearType = null;
    if (tireSizeInfo) {
      type = tireSizeInfo.get('type');
      rearType = tireSizeInfo.get('secondaryType');
      diameter = tireSizeInfo.get('diameter');
      rearDiameter = tireSizeInfo.get('secondaryDiameter');
      if (type == 'IMP') width = tireSizeInfo.get('width') * 25.4; // inch to cm conversion
      else width = tireSizeInfo.get('width');
      if (rearType == 'IMP') rearWidth = tireSizeInfo.get('secondaryWidth') * 25.4; // inch to cm conversion
      else rearWidth = tireSizeInfo.get('secondaryWidth');
    }

    var season = this.data.season;
    var isValidSeason = this.isValidSeason;
    var isValidWidth = this.isValidWidth;

    var filteredListOfWheels = Immutable.List([]);
    if (sizeOf(listOfWheels) > 0) {
      filteredListOfWheels = listOfWheels.filter(function (wheel) {
				return wheel ? (isValidSeason(wheel.get('seasonalSize'), season))
					&& wheel.get('frontWheel').get('diameter') == diameter && ((!rearDiameter && !wheel.get('rearWheel'))
					|| wheel.get('rearWheel') && wheel.get('rearWheel').get('diameter') == rearDiameter)
					&& isValidWidth(parseFloat(wheel.get('frontWheel').get('width')), width) && ((!rearWidth && !wheel.get('rearWheel'))
					|| wheel.get('rearWheel') && isValidWidth(parseFloat(wheel.get('rearWheel').get('width')), rearWidth)) : false
			}).sort(function (a, b) {
				var a_featured = a.get('frontWheel').get('pricing').get('pricingContextId') > 0;
				var b_featured = b.get('frontWheel').get('pricing').get('pricingContextId') > 0;

				if (a_featured) return -1;
				else if (b_featured) return 1;
				else return 0;
			});

      if (sizeOf(filteredListOfWheels) > 0) {
        this.data.selectedVisualizerPart = {
          part: filteredListOfWheels ? filteredListOfWheels[0] : null,
          id: filteredListOfWheels ? filteredListOfWheels[0].get('frontWheel').get('id') : null
        };
      }

    }

    this.data.filteredListOfWheels = filteredListOfWheels;

    this.getWheelCountList();

    this.emitChange();
  },

  isValidSeason: function (wheelSeason, selectedSeason) {
    switch (parseInt(wheelSeason, 10)) {
      case 0:
        return true;
      case 1:
        return parseInt(selectedSeason, 10) == 0;
      case 2:
        return parseInt(selectedSeason, 10) == 2;
    }
  },

  isValidWidth: function (wheelWidth, selectedWidth) {
    var minTireWidth = 1000;
    var maxTireWidth = 0;
    switch (wheelWidth) {
      case 5:
        minTireWidth = 135;
        maxTireWidth = 205;
        break;
      case 5.5:
        minTireWidth = 145;
        maxTireWidth = 215;
        break;
      case 6:
        minTireWidth = 155;
        maxTireWidth = 225;
        break;
      case 6.5:
        minTireWidth = 165;
        maxTireWidth = 235;
        break;
      case 7:
        minTireWidth = 175;
        maxTireWidth = 245;
        break;
      case 7.5:
        minTireWidth = 185;
        maxTireWidth = 255;
        break;
      case 8:
        minTireWidth = 195;
        maxTireWidth = 265;
        break;
      case 8.5:
        minTireWidth = 205;
        maxTireWidth = 275;
        break;
      case 9:
        minTireWidth = 215;
        maxTireWidth = 285;
        break;
      case 9.5:
        minTireWidth = 225;
        maxTireWidth = 295;
        break;
      case 10:
        minTireWidth = 235;
        maxTireWidth = 305;
        break;
      case 10.5:
        minTireWidth = 245;
        maxTireWidth = 315;
        break;
      case 11:
        minTireWidth = 255;
        maxTireWidth = 325;
        break;
      case 11.5:
        minTireWidth = 265;
        maxTireWidth = 335;
        break;
      case 12:
        minTireWidth = 275;
        maxTireWidth = 345;
        break;
      case 12.5:
        minTireWidth = 285;
        maxTireWidth = 355;
        break;
      default:
        break;
    }

    return minTireWidth <= selectedWidth && selectedWidth <= maxTireWidth;
  },

	isValidTireSizeSeason: function (selectedSeason, tireSizeSeason) {
		switch (parseInt(selectedSeason, 10)) {
			case 0:
				return parseInt(tireSizeSeason, 10) == 0 || parseInt(tireSizeSeason, 10) == 1;
			case 1:
				return parseInt(tireSizeSeason, 10) == 0;
			case 2:
				return parseInt(tireSizeSeason, 10) == 0 || parseInt(tireSizeSeason, 10) == 2;
		}
	},

	tireBrandIsValid: function (tireBrand, approvedBrands) {
		return sizeOf(approvedBrands) === 0 || approvedBrands.indexOf(tireBrand) > -1;
	},

	refreshSpecificStock: function (url, stockLocationKey, tireId) {
		get(url, {
			dealerId: getAppDealerId(),
			stockLocationKey: stockLocationKey,
			tireId: tireId
		}).then(function (response) {
			productListActions.stockRefreshed(response[0]);
		});
	},

	onStockRefreshed: function (payload) {
		var immutablePayload = Immutable.fromJS(payload);
		if (sizeOf(immutablePayload) > 0) {
			var tireId = payload[0].itemId;
			var tireIndex = 0, nonFeatTireIndex = null;
			var tire = null, frontTireFound = false, rearTireFound = false;
			this.data.listOfTires.forEach(function (tireInfo, index) {
				if (tireInfo.get('frontTire').get('id') == tireId && !frontTireFound) {
					tire = tireInfo;
					tireIndex = index;
					frontTireFound = true;
				} else if (tireInfo.get('frontTire').get('id') == tireId && frontTireFound) {
					nonFeatTireIndex = index;
				} else if (tireInfo.get('rearTire') && !rearTireFound) {
					if (tireInfo.get('rearTire').get('id') == tireId) {
						tire = tireInfo;
						tireIndex = index;
						rearTireFound = true;
					}
				} else if (tireInfo.get('rearTire') && rearTireFound) {
					if (tireInfo.get('rearTire').get('id') == tireId) {
						nonFeatTireIndex = index;
					}
				}
			});

			var updateForEachStock = function (stocks, tireIndex, tireType, tireId) {
				stocks.forEach(function (stock, stockIndex) {
					if (stock.get('loading')) {
						var refreshedStock = getRefreshedStock(immutablePayload, stock.get('itemId'), stock.get('stockLocationKey'));
						if (refreshedStock) {
              this.data.listOfTires[tireIndex] = this.data.listOfTires[tireIndex].setIn([tireType, 'stock', stockIndex], refreshedStock);
						}
					}
				}.bind(this));
			}.bind(this);

			var _this = this;
			function getRefreshedStock(refreshedStocks, tireId, locationKey) {
				var stockQtyTotal = 0;
				var refreshedStock = refreshedStocks.find(function (stock) {
					stockQtyTotal += parseInt(stock.get('stockQty'));
					return stock.get('stockLocationKey') == locationKey && stock.get('itemId') == tireId;
				});

        if (stockQtyTotal === 0) _this.pushToEmptyStockQueryList(tireId+"-"+locationKey);
        else _this.removeFromEmptyStockQueryList(tireId+"-"+locationKey);

				if (refreshedStock) {
					return refreshedStock.set('loading', false);
				} else {
					return null;
				}
			}

			if (tire) {
				if (frontTireFound) {
					updateForEachStock(tire.get('frontTire').get('stock'), tireIndex, 'frontTire');
					if (nonFeatTireIndex) updateForEachStock(tire.get('frontTire').get('stock'), nonFeatTireIndex, 'frontTire', tire.get('frontTire').get('id'));
				} else if (rearTireFound) {
					updateForEachStock(tire.get('rearTire').get('stock'), tireIndex, 'rearTire');
					if (nonFeatTireIndex) updateForEachStock(tire.get('rearTire').get('stock'), nonFeatTireIndex, 'rearTire', tire.get('rearTire').get('id'));
				}
			}
		}

		if (this.data.tireListLoaded) {
			this.filterTires(false, null);
		}

		this.emitChange();
	},

  pushToEmptyStockQueryList: function (tireId) {
    if (this.data.stockQueryEmpty.indexOf(tireId) === -1) this.data.stockQueryEmpty.push(tireId);
    this.emitChange();
	},

  removeFromEmptyStockQueryList: function (tireId) {
    if (this.data.stockQueryEmpty.indexOf(tireId) > -1) {
    	//this.data.stockQueryEmpty.remove(tireId);
      this.data.stockQueryEmpty.splice(tireId, 1);
    }
    this.emitChange();
  },

	onRequestStockRefresh: function (payload) {
		if (payload) {
      var frontTireId = payload.frontTireId;
      var rearTireId = payload.rearTireId;
      var frontTireIndex = 0, rearTireIndex = 0, nonFeatFrontTireIndex = null, nonFeatRearTireIndex = null;
      var frontTire = null, rearTire = null, frontTireFound = false, rearTireFound = false;
      this.data.listOfTires.forEach(function (tireInfo, index) {
        if (tireInfo.get('frontTire').get('id') == frontTireId && !frontTireFound) {
          frontTire = tireInfo;
          frontTireIndex = index;
          frontTireFound = true;
        } else if (tireInfo.get('frontTire').get('id') == frontTireId && frontTireFound) {
          nonFeatFrontTireIndex = index;
        }
        if (tireInfo.get('rearTire') && !rearTireFound) {
          if (tireInfo.get('rearTire').get('id') == rearTireId) {
            rearTire = tireInfo;
            rearTireIndex = index;
            rearTireFound = true;
          }
        } else if (tireInfo.get('rearTire') && rearTireFound) {
          if (tireInfo.get('rearTire').get('id') == rearTireId) {
            nonFeatRearTireIndex = index;
          }
        }
      });

      var updateForEachStock = function (stocks, tireIndex, tireType, tireIdToCompare) {
        stocks.forEach(function (stock, stockIndex) {
          if (stock.get('stockLocationKey') == payload.stockLocationKey && stock.get('itemId') == tireIdToCompare) {
            this.data.listOfTires[tireIndex] = this.data.listOfTires[tireIndex].setIn([tireType, 'stock', stockIndex], stock.set('loading', true));
          }
        }.bind(this));
      }.bind(this);

      if (frontTireFound) {
        updateForEachStock(frontTire.get('frontTire').get('stock'), frontTireIndex, 'frontTire', frontTireId);
        if (nonFeatFrontTireIndex) updateForEachStock(frontTire.get('frontTire').get('stock'), nonFeatFrontTireIndex, 'frontTire', frontTireId);
      } else if (rearTireFound) {
        updateForEachStock(rearTire.get('rearTire').get('stock'), rearTireIndex, 'rearTire', rearTireId);
        if (nonFeatRearTireIndex) updateForEachStock(rearTire.get('rearTire').get('stock'), nonFeatRearTireIndex, 'rearTire', rearTireId);
      }


      if (frontTire) {
        if (frontTireFound) {
          setTimeout(() => {
            this.refreshSpecificStock(payload.url, payload.stockLocationKey, frontTire.get('frontTire').get('id'));
          }, 10);
        }
      }
      if (rearTire) {
        if (rearTireFound) {
          setTimeout(() => {
            this.refreshSpecificStock(payload.url, payload.stockLocationKey, rearTire.get('rearTire').get('id'));
          }, 10);
        }
      }
    }

		if (this.data.tireListLoaded) {
			this.filterTires(false, null);
		}

		this.emitChange();
	},

	onRequestStockItemRefresh: function (payload) {
		if (payload) {
			var tireId = payload.frontTireId;
			var tireIndex = 0, nonFeatTireIndex = null;
			var tire = null, tireFound = false, mappedTire = null;
			if (this.data.tireListLoaded) {
				this.data.listOfTires.forEach(function (tireInfo, index) {
					if (tireInfo && tireInfo.get('frontTire').get('id') == tireId && !tireFound && ((!payload.rearTireId && !tireInfo.get('rearTire')) || payload.rearTireId == tireInfo.get('rearTire').get('id'))) {
						tire = tireInfo;
						tireIndex = index;
						tireFound = true;
					} else if (tireInfo && tireInfo.get('frontTire').get('id') == tireId && tireFound && ((!payload.rearTireId && !tireInfo.get('rearTire')) || payload.rearTireId == tireInfo.get('rearTire').get('id'))) {
						nonFeatTireIndex = index;
					}
				});

				mappedTire = tire
					.get('frontTire')
					.get('stock')
					.concat(tire.get('rearTire') ? tire.get('rearTire').get('stock') : [])
					.map(function (stock) {
						return {
							tireId: stock.get('itemId'),
							stockLocationId: stock.get('stockLocationId'),
							stockLocationKey: stock.get('stockLocationKey'),
							stockApiQueryUrl: stock.get('stockApiQueryUrl')
						};
					});
			} else if (this.data.wheelListLoaded) {
				this.data.listOfWheels.forEach(function (tireInfo, index) {
					if (tireInfo && tireInfo.get('frontWheel').get('id') == tireId && !tireFound && ((!payload.rearWheelId && !tireInfo.get('rearWheel')) || payload.rearWheelId == tireInfo.get('rearWheel').get('id'))) {
						tire = tireInfo;
						tireIndex = index;
						tireFound = true;
					} else if (tireInfo && tireInfo.get('frontWheel').get('id') == tireId && tireFound && ((!payload.rearWheelId && !tireInfo.get('rearWheel')) || payload.rearWheelId == tireInfo.get('rearWheel').get('id'))) {
						nonFeatTireIndex = index;
					}
				});

				mappedTire = tire
					.get('frontWheel')
					.get('stock')
					.concat(tire.get('rearWheel') ? tire.get('rearWheel').get('stock') : [])
					.map(function (stock) {
						return {
							tireId: stock.get('itemId'),
							stockLocationId: stock.get('stockLocationId'),
							stockLocationKey: stock.get('stockLocationKey'),
							stockApiQueryUrl: stock.get('stockApiQueryUrl')
						};
					});
			} else if (this.data.accessoryListLoaded) {
				this.data.listOfAccessories.forEach(function (tireInfo, index) {
					if (tireInfo && tireInfo.get('id') == tireId && !tireFound) {
						tire = tireInfo;
						tireIndex = index;
						tireFound = true;
					} else if (tireInfo && tireInfo.get('id') == tireId && tireFound) {
						nonFeatTireIndex = index;
					}
				});

				mappedTire = tire
					.get('stock')
					.map(function (stock) {
						return {
							tireId: stock.get('itemId'),
							stockLocationId: stock.get('stockLocationId'),
							stockLocationKey: stock.get('stockLocationKey'),
							stockApiQueryUrl: stock.get('stockApiQueryUrl')
						};
					});
			}

			var refreshSpecificStock = this.refreshSpecificStock;
			mappedTire.forEach(function (stock, stockIndex) {
				setTimeout(() => {
					if (payload.frontTireId && stock.stockApiQueryUrl !== null) { // this will call api for rear stock as well
						refreshSpecificStock(stock.stockApiQueryUrl, stock.stockLocationKey, stock.tireId);
					}
				}, 10);
			});

			if (this.data.tireListLoaded) {
				this.filterTires(false, null);
			}
		}
		this.emitChange();
	},

	getTireSizeFiltersForSelectedSeason: function () {
		return this.data.tireSizeFilters.filter(function (tireSize) {
			return tireSize.get('seasonalSizeCode') === 0 || (parseInt(this.data.season, 10) === 0 && tireSize.get('seasonalSizeCode') === 1) || (parseInt(this.data.season, 10) === 2 && tireSize.get('seasonalSizeCode') === 2);
		}.bind(this)).sortBy(tireSize => tireSize.get('diameter'));
	},

	loadSeasonFilters: function (filters) {
		if (filters) {
			this.data.seasonFilters = Immutable.fromJS(filters);
			this.data.season = this.data.originalSeason;
			if (typeof this.data.season === 'undefined') {
				if (filters[0]) this.data.season = 0;
				else if (filters[1]) this.data.season = 1;
				else if (filters[2]) this.data.season = 2;
			}
		}
		this.emitChange();
	},

	loadTireBrandFilters: function (filters) {
		if (filters) {
			this.data.brandFilters = Immutable.fromJS(filters).map((brand, brandId) => buildFilter(brandId, brand.get('part_manufacturer_name')));
		}
		this.emitChange();
	},

	loadAccessoryCategoryFilters: function (filters) {
		if (filters) {
			this.data.accessoryCategoryFilter = Immutable.fromJS(filters).map(function (category) {
				return Immutable.Map({
					key: category.get('accessoryCategoryId'),
					value: category.get('accessoryCategoryName')
				});
			});
		}

		this.emitChange();
	},

	onChangeTireQuantity: function (payload) {
		if (this.guardSearchResultState()) {
			var filteredTire = this.data.listOfTires.find(tire => payload.featured == tire.get('featured') && ((tire.get('frontTire').get('id') === payload.tireKey && payload.rearTireKey === null) || (tire.get('frontTire').get('id') === payload.tireKey && tire.get('rearTire') && tire.get('rearTire').get('id') === payload.rearTireKey)));
			var filteredIndex = this.data.listOfTires.findIndex(tire => payload.featured == tire.get('featured') && ((tire.get('frontTire').get('id') === payload.tireKey && payload.rearTireKey === null) || (tire.get('frontTire').get('id') === payload.tireKey && tire.get('rearTire') && tire.get('rearTire').get('id') === payload.rearTireKey)));
			var listOfTires = this.data.listOfTires;
			var filteredTireQuantity;
			if (payload.front) {
				filteredTireQuantity = filteredTire.get('frontTire').set('quantity', payload.quantity);
				filteredTireQuantity = filteredTireQuantity.set('pricing', Immutable.fromJS(getPricingByQuantity(filteredTire.get('frontTire').get('pricingDetails'), payload.quantity)));
				filteredTireQuantity = filteredTireQuantity.set('rebate', Immutable.fromJS(getRebateByQuantity(filteredTire.get('frontTire').get('pricingDetails'), payload.quantity)));
			} else {
				filteredTireQuantity = filteredTire.get('rearTire').set('quantity', payload.quantity);
				filteredTireQuantity = filteredTireQuantity.set('pricing', Immutable.fromJS(getPricingByQuantity(filteredTire.get('rearTire').get('pricingDetails'), payload.quantity)));
				filteredTireQuantity = filteredTireQuantity.set('rebate', Immutable.fromJS(getRebateByQuantity(filteredTire.get('rearTire').get('pricingDetails'), payload.quantity)));
			}

			if (payload.front) listOfTires[filteredIndex] = listOfTires[filteredIndex].set('frontTire', filteredTireQuantity);
			else listOfTires[filteredIndex] = listOfTires[filteredIndex].set('rearTire', filteredTireQuantity);

			this.data.listOfTires = listOfTires;

			this.filterTires(false, listOfTires);

			this.emitChange();
		}
	},

  onChangeSelectedTireQuantity: function (payload) {
    var filteredTire = this.data.selectedTireData, filteredTireQuantity;

    if (filteredTire.get('frontTire').get('id') === payload.tireKey) {
      filteredTireQuantity = filteredTire.get('frontTire').set('quantity', payload.quantity);
      filteredTireQuantity = filteredTireQuantity.set('pricing', Immutable.fromJS(getPricingByQuantity(filteredTire.get('frontTire').get('pricingDetails'), payload.quantity)));
      filteredTireQuantity = filteredTireQuantity.set('rebate', Immutable.fromJS(getRebateByQuantity(filteredTire.get('frontTire').get('pricingDetails'), payload.quantity)));

      filteredTire = filteredTire.set('frontTire', filteredTireQuantity);
    }

    this.data.selectedTireData = filteredTire;

    this.emitChange();
  },

	onChangeWheelQuantity: function (payload) {
		var wheel = null;
		if (payload.front) wheel = this.data.listOfWheels.find(wheel => wheel.get('frontWheel').get('id') === payload.wheelIndex);
		else wheel = this.data.listOfWheels.find(wheel => wheel.get('rearWheel') && wheel.get('rearWheel').get('id') === payload.wheelIndex);
		var index = this.data.listOfWheels.findIndex(wheel => wheel.get('frontWheel').get('id') === payload.wheelIndex || (wheel.get('rearWheel') && wheel.get('rearWheel').get('id') === payload.wheelIndex));
		var wheelList = this.data.listOfWheels;

		var filteredWheelQuantity;
		if (payload.front) {
			filteredWheelQuantity = wheel.get('frontWheel').set('quantity', payload.quantity);
			filteredWheelQuantity = filteredWheelQuantity.set('pricing', Immutable.fromJS(getPricingByQuantity(wheel.get('frontWheel').get('pricingDetails'), payload.quantity)));
			filteredWheelQuantity = filteredWheelQuantity.set('rebate', Immutable.fromJS(getRebateByQuantity(wheel.get('frontWheel').get('pricingDetails'), payload.quantity)));
		} else {
			filteredWheelQuantity = wheel.get('rearWheel').set('quantity', payload.quantity);
			filteredWheelQuantity = filteredWheelQuantity.set('pricing', Immutable.fromJS(getPricingByQuantity(wheel.get('rearWheel').get('pricingDetails'), payload.quantity)));
			filteredWheelQuantity = filteredWheelQuantity.set('rebate', Immutable.fromJS(getRebateByQuantity(wheel.get('rearWheel').get('pricingDetails'), payload.quantity)));
		}

		if (payload.front) wheelList[index] = wheelList[index].set('frontWheel', filteredWheelQuantity);
		else wheelList[index] = wheelList[index].set('rearWheel', filteredWheelQuantity);

		this.data.listOfWheels = wheelList;
    this.filterWheels(wheelList);

		this.emitChange();
	},

	onChangeAccessoryQuantity: function (payload) {
		var filteredAccessory = this.data.listOfAccessories.find(accessory => accessory.get('id') === payload.accessoryId);
		var filteredIndex = this.data.listOfAccessories.findIndex(accessory => accessory.get('id') === payload.accessoryId);
		var filteredAccessoryList = this.data.listOfAccessories;

		filteredAccessory = filteredAccessory.set('quantity', payload.quantity);
		filteredAccessory = filteredAccessory.set('pricing', Immutable.fromJS(getPricingByQuantity(filteredAccessory.get('pricingDetails'), payload.quantity)));
		filteredAccessory = filteredAccessory.set('rebate', Immutable.fromJS(getRebateByQuantity(filteredAccessory.get('pricingDetails'), payload.quantity)));

		filteredAccessoryList[filteredIndex] = filteredAccessory;

		this.data.listOfAccessories = filteredAccessoryList;

		this.emitChange();
	},

	onChangePackageQuantity: function (payload) {
		var filteredPackage = this.data.listOfPackages.find(packageItem => packageItem.get('id') === payload.packageIndex);
		var filteredIndex = this.data.listOfPackages.findIndex(packageItem => packageItem.get('id') === payload.packageIndex);
		var filteredPackageList = this.data.listOfPackages;

		filteredPackage = filteredPackage.set('quantity', payload.quantity);
		filteredPackage = filteredPackage.set('pricing', Immutable.fromJS(getPricingByQuantity(filteredPackage.get('pricingDetails'), payload.quantity)));
		filteredPackage = filteredPackage.set('rebate', Immutable.fromJS(getRebateByQuantity(filteredPackage.get('pricingDetails'), payload.quantity)));

		filteredPackageList[filteredIndex] = filteredPackage;

		this.data.listOfPackages = filteredPackageList;

		this.emitChange();
	},

  onChangeProductDetailModalRequiredItemQuantity: function(payload) {
    var detailModalProductType = applicationStore.data.detailModalProductType;
    var selectedPart, requiredItems, requiredItem, requiredItemPrice;
    if (detailModalProductType === ProductTypes.TIRES) {

		} else if (detailModalProductType === ProductTypes.ALLOY_WHEELS) {

		} else if (detailModalProductType === ProductTypes.ACCESSORIES) {

      selectedPart = this.data.productDetailPart;

      requiredItems = selectedPart.get('relatedParts');
      requiredItem = requiredItems.get(payload.productIndex);
      requiredItemPrice = requiredItem.get('price');

      var rowPrice = parseInt(payload.quantity.get('value')) * parseInt(requiredItemPrice.displayPrice);
      requiredItemPrice.rowPrice = rowPrice.toString();

      requiredItem = requiredItem.set('quantity', payload.quantity.toObject());
      requiredItem = requiredItem.set('price', requiredItemPrice);

      requiredItems = requiredItems.set(payload.productIndex, requiredItem);
      this.data.productDetailPart = selectedPart.set('relatedParts', requiredItems);

		} else if (detailModalProductType === ProductTypes.PACKAGES) {

		}
    this.emitChange();
  },

  getProductDetailModalPart: function() {
    var detailModalProductId = applicationStore.data.detailModalProductId, detailModalProductRearId = applicationStore.data.detailModalProductRearId;
    var modalPart = null, listOfParts = null, frontTire, rearTire;
    if (applicationStore.data.search) {
      listOfParts = searchStore.data.results;
      if (listOfParts) {
        for (var i in listOfParts) {
          frontTire = listOfParts[i].get('frontTire');
          rearTire = listOfParts[i].get('rearTire');
          if (frontTire.get('id') == detailModalProductId && (!rearTire || (rearTire && rearTire.get('id') == detailModalProductRearId))) {
          	modalPart = listOfParts[i];
            break;
          }
        }
      }
    } else {
      switch (applicationStore.data.detailModalProductType) {
        case ProductTypes.TIRES:
          listOfParts = this.data.listOfTires;
          if (listOfParts) {
            for (var i in listOfParts) {
              frontTire = listOfParts[i].get('frontTire');
              rearTire = listOfParts[i].get('rearTire');
              if (frontTire.get('id') == detailModalProductId && (!rearTire || (rearTire && rearTire.get('id') == detailModalProductRearId))) {
              	modalPart = listOfParts[i];
                break;
              }
            }
          }
          break;
        case ProductTypes.ALLOY_WHEELS:
          listOfParts = this.data.listOfWheels;
          if (listOfParts) {
            for (var i in listOfParts) {
              if (parseFloat(listOfParts[i].get('frontWheel').get('id')) === detailModalProductId) {
                if (detailModalProductRearId !== null) {
                  if (parseFloat(listOfParts[i].get('rearWheel').get('id')) === detailModalProductRearId) {
                  	modalPart = listOfParts[i];
                    break;
                  }
                } else {
                  modalPart = listOfParts[i];
                  break;
                }
              }
            }
          }
          break;
        case ProductTypes.ACCESSORIES:
          listOfParts = this.data.listOfAccessories;
          if (listOfParts) {
            for (var i in listOfParts) {
              if (parseFloat(listOfParts[i].get('id')) === detailModalProductId) {
                modalPart = listOfParts[i];
                break;
              }
            }
          }
          break;
        case ProductTypes.PACKAGES:
          listOfParts = this.data.listOfPackages;
          if (listOfParts) {
            for (var i in listOfParts) {
              if (parseFloat(listOfParts[i].get('id')) === detailModalProductId) {
                modalPart = listOfParts[i];
                break;
              }
            }
          }
          break;
        default:
          break;
      }
    }

    this.data.productDetailPart = modalPart;
    this.emitChange();
  },

	onOpenDetail: function (payload) {
		if (this.guardSearchResultState()) {
			if (modalStore.mounted) {
				throw new Error('Modal has been mounted already');
			}

			this.data.openedIndex = payload.index;
			this.data.openedProductType = payload.type;

			this.resetDetailQuantity(this.data.openedProductType, this.data.openedIndex);
			this.data.colourCodeInDetail = null;

			this.emitChange();
		}
	},

	onOpenDetailFromCart: function (cartIndex) {
		this.data.openedCartIndex = cartIndex;
		this.data.detailTireQuantity = DEFAULT_SELECTED_QUANTITY_OF_PRODUCT;
		this.emitChange();
	},

	onCloseDetail: function () {
		if (this.guardSearchResultState()) {
			if (!modalStore.mounted) {
				throw new Error('Modal has been unmounted already');
			}
			this.data.openedIndex = null;
			this.data.openedCartIndex = null;

			this.emitChange();
		}
	},

	guardSearchResultState: function () {
		var routerState = globalState.select('applicationState').get('routerState');
		return routerState === RouterStates.PRODUCT_LIST;
	},

	onAppointmentFormSent: function () {
		this.data.openedIndex = null;
		this.data.openedCartIndex = null;

		this.emitChange();
	},

	isModalOpened: function () {
		return this.data.openedIndex !== null || this.data.openedCartIndex !== null;
	},

	isModalOpenedFromCart: function () {
		return this.data.openedCartIndex !== null;
	},

	getOpenedProduct: function () {
		if (this.data.openedCartIndex !== null) {
			return shoppingCartStore.getProductFromBasketByIndex(this.data.openedCartIndex);
		} else {
			switch (this.data.openedProductType) {
				case ProductTypes.TIRES:
					return this.data.listOfTires.get(this.data.openedIndex);
				case ProductTypes.ALLOY_WHEELS:
					return this.data.listOfWheels.get(this.data.openedIndex);
				case ProductTypes.ACCESSORIES:
					return this.data.listOfAccessories.get(this.data.openedIndex);
				case ProductTypes.PACKAGES:
					return this.data.listOfPackages.get(this.data.openedIndex);
				default:
					return null;
			}
		}
	},

	onPrevClick: function () {
		if (this.guardSearchResultState()) {
			if (!this.canGoBack()) {
				throw new Error('Can\'t go below first product');
			}

			this.data.openedIndex--;
			this.resetDetailQuantity(this.data.openedProductType, this.data.openedIndex);
			this.emitChange();
		}
	},

	onNextClick: function () {
		if (this.guardSearchResultState()) {
			if (!this.canGoNext()) {
				throw new Error('Can\'t go after last product');
			}

			this.data.openedIndex++;
			this.resetDetailQuantity(this.data.openedProductType, this.data.openedIndex);
			this.emitChange();
		}
	},

	resetDetailQuantity: function (productType, index) {
		var isStaggered;
		switch (productType) {
			case ProductTypes.TIRES:
				isStaggered = !!this.data.listOfTires.get(index).get(STAGGERED_KEY);
				break;
			case ProductTypes.ALLOY_WHEELS:
				isStaggered = !!this.data.listOfWheels.get(index).get(STAGGERED_KEY);
				break;
		}

		if (productType === ProductTypes.COLLECTIONS ||
			productType === ProductTypes.ACCESSORIES ||
			productType === ProductTypes.PACKAGES) {
			this.data.detailFrontQuantity = 1;
		} else {
			if (isStaggered) {
				this.data.detailFrontQuantity = DEFAULT_SELECTED_QUANTITY_OF_PRODUCT_STAGGERED;
				this.data.detailRearQuantity = DEFAULT_SELECTED_QUANTITY_OF_PRODUCT_STAGGERED;
			} else {
				this.data.detailFrontQuantity = DEFAULT_SELECTED_QUANTITY_OF_PRODUCT;
			}
		}
	},

	canGoBack: function () {
		return this.data.openedCartIndex === null && this.data.openedIndex !== 0;
	},

	canGoNext: function () {
		return this.data.openedCartIndex === null && this.data.openedIndex < (this.getCountOfProducts() - 1);
	},

	getCountOfProducts: function () {
		switch (this.data.openedProductType) {
			case ProductTypes.TIRES:
				return sizeOf(this.data.listOfTires);
			case ProductTypes.ALLOY_WHEELS:
				return sizeOf(this.data.listOfWheels);
			case ProductTypes.ACCESSORIES:
				return sizeOf(this.data.listOfAccessories);
			default:
				return 0;
		}
	},

	onRequestDetailClose: function () {
		if (this.isModalOpened()) {
			this.onCloseDetail();
		}
	},

	onChangeDetailTireQuantity: function (payload) {
		if (payload.front) {
			this.data.detailFrontQuantity = payload.quantity;
		} else {
			this.data.detailRearQuantity = payload.quantity;
		}

		this.emitChange();
	},

});

export var productListStore = new ProductListStore();

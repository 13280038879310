import { Modal } from "../ux/Modal.jsx";

import { applicationStore } from "../../stores/ApplicationStore.js";

import { EmailField } from "../ux/forms/EmailField.jsx";
import { forgotPasswordToggle } from "../../redux/features/applicationInfo.js";
import { connect } from "react-redux";
import { forgotPassword } from "../../redux/features/loginInfo.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var _ = require("lodash");

class ForgotPasswordModal extends I18nComponent {
  state = {
    emailAddress: {
      value: null,
      touched: false,
      forceTouch: false,
      valid: false,
    },
    returnMessage: null,
    modalMessage: false,
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
  }

  render() {
    return (
      <Modal
        refactor={true}
        useShortHeaderBar={true}
        requestUnmount={this.onRequestCloseModal}
      >
        <div className="wrapper">
          <div className="modal-content forgot-password">
            <form onSubmit={this.onFormSubmit} className="contact-form">
              <span className="title">{this.t("forgotPassword.title")}</span>
              <EmailField
                onChange={this.onChangeEmail}
                id="userEmailField"
                label={this.t("guestAccountCreation.emailAddress")}
                touched={this.state.emailAddress.forceTouch}
              />
              {this.state.modalMessage ? (
                <span className="form-report">{this.state.modalMessage}</span>
              ) : (
                false
              )}
              {this.props.returnMessage ? (
                <span className="form-return">{this.returnMessage}</span>
              ) : (
                false
              )}
              {this.props.returnMessage ? (
                <p className="forgot-password-success">
                  {this.state.returnMessage}
                </p>
              ) : (
                <p className="forgot-password-error">
                  {this.state.returnMessage}
                </p>
              )}
              <div className="button-container">
                <button type="submit" className="btn">
                  {this.t("forgotPassword.enter")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }

  onChangeEmail = (value, valid) => {
    this.setState({
      emailAddress: {
        value: value,
        valid: valid,
        touched: true,
      },
    });
  };

  onFormSubmit = (ev) => {
    if (this.canBeSubmitted()) {
      // this.props.dispatch(forgotPassword(this.state.emailAddress.value))
      this.submitForgotPassword();
    } else {
      this.state.modalMessage = (
        <p className="guest-account-creation-error">
          {this.t("guestAccountCreation.dataMissingOrIncorrect")}
        </p>
      );
    }

    ev.preventDefault();
    ev.stopPropagation();
  };

  canBeSubmitted = () => {
    return this.state.emailAddress.touched && this.state.emailAddress.valid;
  };

  onRequestCloseModal = () => {
    this.props.dispatch(forgotPasswordToggle(false));
  };
  submitForgotPassword = async () => {
    await this.props.dispatch(forgotPassword(this.state.emailAddress.value));
    let response = this.props.returnMessage;
    let msg = this.props.loginMsg;
    let lang = this.props.lang;
    if (response) {
      this.setState({
        returnMessage: msg.payload.message[lang],
      });
      // this.state.returnMessage = <p className="forgot-password-success">{msg.payload.message[lang]}</p>;
    } else {
      this.setState({
        returnMessage: msg.payload.message[lang],
      });
      // this.state.returnMessage = <p className="forgot-password-error">{msg.payload.message[lang]}</p>;
    }
  };
  onChange = () => {};
}

function mapStateToProps(state) {
  return {
    returnMessage: state.login.returnMessage,
    loginMsg: state.login.loginMsg,
    lang: state.info.lang,
  };
}
export default connect(mapStateToProps)(ForgotPasswordModal);

import Immutable from "immutable";

import { applicationStore } from "../../stores/ApplicationStore.js";
import { shoppingCartViewStore } from "../../stores/ShoppingCartViewStore";
import { productListActions } from "../../actions/ProductListActions";
import { shoppingCartActions } from "../../actions/ShoppingCartActions";

import { Modal } from "../ux/Modal.jsx";
import { ProductTypes } from "../../constants/ProductTypes";

import {
  formatDateString,
  renderCalendarModal,
} from "../../service/CalendarService";
import {
  componentMountPrintService,
  componentUpdatePrintService,
  initializeWishlistPDF,
  renderWishlistPdfLoading,
} from "../../service/PrintService";
import {
  formTypes,
  formTracking,
  sizeOf,
  isEmpty,
  validateEmail,
  isValidDate,
  loadFormUserData,
  getTrackingEventCategory,
  isNationalSite,
} from "../../service/UtilityService";
import { printComparison, emailComparison } from "../../service/ProductList";
import { productListStore } from "../../stores/ProductListStore";
import { isUserLoggedIn } from "../../api/AuthService";
import {
  renderProducts,
  renderProductsNonOrder,
  onOpenPrinterFriendly,
  onReadyPdfPrint,
  getQuoteTrackingLabelInfo,
} from "../../service/WishlistService";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  runAdobeServiceFormStart,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
  ADOBELAUNCHER_FORM_SUBMIT,
} from "../../service/AdobeLaunchService";
import { connect } from "react-redux";
import { shoppingCartCloseForm } from "../../redux/features/shoppingCart.js";
import { getOrderIdentifier } from "../../service/OrderService";
import store from "../../stores/configureStore";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");

class WishlistFormModal extends I18nComponent {
  state = {
    cartItems: Immutable.Map(),
    showCalendar: false,
    calendarDate: null,
    calendarDateTemp: null,
    reminderNotification: false,
    actionSuccess: false,
    apiFailure: false,
    actionFailure: false,
    actionFailureMsg: "",
    reminderSuccess: false,
    reminderFailure: false,
    pdfLauncher: null,
    wishlistPdf: initializeWishlistPDF(),
    adobeLauncherHasRun: false,
  };

  updateState = (state) => {
    this.setState(state);
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview(
      "Shopping Cart Quick View Form - " + formTracking[this.props.formType]
    );
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "open lead form",
      formTracking[this.props.formType]
    );
    shoppingCartViewStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    shoppingCartViewStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    componentMountPrintService(this.updateState);
    runAdobeServiceFormStart();
    loadFormUserData(
      "formFirstName",
      "formLastName",
      "formEmail",
      "formPhone",
      "formNotes",
      "firstNameQuoteField",
      "lastNameQuoteField",
      "userEmailField",
      "telQuoteField",
      "userNoteQuoteField"
    );
  }

  componentDidUpdate(prevProps, prevState) {
    // if this.props.shoppingCartView does not equal prevProps.shoppingCartView then update the state
    if (this.props.shoppingCartView !== prevProps.shoppingCartView) {
      // update the state with the new props
      this.setState({
        cartItems: this.props.shoppingCartView.get("cartItems"),
      });
    }

    componentUpdatePrintService(pdfMake, this.state, this.updateState);
  }

  render() {
    return (
      <Modal
        refactor={true}
        datadtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.CONTACT.TYPE)}
        requestUnmount={this.closeWislistFormModal}
      >
        <div className="wrapper">
          <div className="modal-container">
            <div className="information-form">
              <div className="form-header">
                <h1>{this.getFormTitle()}</h1>
                <span>{this.getFormSubtitle()}</span>
              </div>

              {this.renderReminderResults()}
              {this.renderActionResults()}

              {this.reserveSwitchSelection()}

              {this.renderAppointmentCalendar()}

              {this.renderCartItemList()}

              {this.renderFormSection()}

              <div className="form-buttons">{this.getFormActions()}</div>

              {this.renderCalendar()}
              {renderWishlistPdfLoading(this.state)}
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderAppointmentCalendar = () => {
    switch (this.props.formType) {
      case formTypes.APPOINTMENT:
      case formTypes.RESERVE:
        return (
          <div className="select-calendar">
            <div className="select-date">
              <span className="label">
                {this.t("appointmentForm.selectedDate")}:
              </span>
              <span className="value">
                {formatDateString(this.state.calendarDate)}
              </span>
              <button className="btn" onClick={this.toggleCalendar}>
                {this.t("appointmentForm.selectDate")}
              </button>
            </div>
          </div>
        );
      case formTypes.CALLBACK:
      case formTypes.SAVE:
      case formTypes.SEND:
      case formTypes.PRICEMATCH:
      case formTypes.PRINT:
      case formTypes.DOWNLOAD:
        if (this.state.reminderNotification) {
          return (
            <div className="select-calendar">
              <div className="select-date">
                <span className="label">
                  {this.t("appointmentForm.selectedDate")}:
                </span>
                <span className="value">
                  {formatDateString(this.state.calendarDate)}
                </span>
                <button className="btn" onClick={this.toggleCalendar}>
                  {this.t("appointmentForm.selectDate")}
                </button>
              </div>
            </div>
          );
        }
      case formTypes.COMPARISON_EMAIL:
      case formTypes.COMPARISON_PRINT:
      case formTypes.COMPARISON_DOWNLOAD:
        return false;
    }
  };

  reserveSwitchSelection = () => {
    switch (this.props.formType) {
      case formTypes.SEND:
      case formTypes.CALLBACK:
      case formTypes.SAVE:
      case formTypes.PRICEMATCH:
      case formTypes.PRINT:
      case formTypes.DOWNLOAD:
        if (applicationStore.data.quoteImmediately && isUserLoggedIn()) {
          return (
            <div className="reserve-checkbox">
              <ul className="select-reserve-options">
                <li onClick={this.toggleReminderNotification}>
                  <i
                    className={
                      this.state.reminderNotification
                        ? "fa fa-check-square-o"
                        : "fa fa-square-o"
                    }
                  />
                  <span>{this.t("wishlist.modal.sendReminder")}</span>
                </li>
              </ul>
            </div>
          );
        } else {
          return false;
        }
      case formTypes.RESERVE:
      case formTypes.APPOINTMENT:
      case formTypes.COMPARISON_EMAIL:
      case formTypes.COMPARISON_PRINT:
      case formTypes.COMPARISON_DOWNLOAD:
        return false;
    }
  };

  toggleReminderNotification = () => {
    this.setState({
      reminderNotification: !this.state.reminderNotification,
    });
  };

  renderCalendar = () => {
    return renderCalendarModal(
      this.state,
      this.updateState,
      this.getShowAllCalendarDays()
    );
  };

  getShowAllCalendarDays = () => {
    return (
      this.props.formType === formTypes.SEND ||
      this.props.formType === formTypes.SAVE ||
      this.props.formType === formTypes.PRINT ||
      this.props.formType === formTypes.DOWNLOAD
    );
  };

  toggleCalendar = () => {
    this.setState({
      showCalendar: !this.state.showCalendar,
      actionFailure: false,
      actionFailureMsg: "",
    });
  };

  renderCartItemList = () => {
    switch (this.props.formType) {
      case formTypes.RESERVE:
        return (
          <div className="item-list hide-other">
            {renderProducts(ProductTypes.TIRES)}
            {renderProducts(ProductTypes.ALLOY_WHEELS)}
            {renderProducts(ProductTypes.ACCESSORIES)}
            {renderProducts(ProductTypes.PACKAGES)}
            {renderProductsNonOrder(ProductTypes.TIRES)}
            {renderProductsNonOrder(ProductTypes.ALLOY_WHEELS)}
            {renderProductsNonOrder(ProductTypes.ACCESSORIES)}
            {renderProductsNonOrder(ProductTypes.PACKAGES)}
          </div>
        );
      case formTypes.APPOINTMENT:
      case formTypes.CALLBACK:
      case formTypes.SAVE:
      case formTypes.SEND:
      case formTypes.PRICEMATCH:
      case formTypes.PRINT:
      case formTypes.DOWNLOAD:
      case formTypes.COMPARISON_EMAIL:
      case formTypes.COMPARISON_PRINT:
      case formTypes.COMPARISON_DOWNLOAD:
        return false;
    }
  };

  renderActionResults = () => {
    if (this.state.actionSuccess) {
      return this.getFormSuccess();
    } else if (this.state.apiFailure) {
      return this.renderErrorSection(true);
    } else if (this.state.actionFailure) {
      return this.renderErrorSection(false);
    } else {
      return false;
    }
  };

  renderReminderResults = () => {
    if (this.state.reminderSuccess) {
      return this.getReminderSuccess();
    } else if (this.state.reminderFailure) {
      return this.renderReminderError();
    } else {
      return false;
    }
  };

  getFormSuccess = () => {
    switch (this.props.formType) {
      case formTypes.APPOINTMENT:
        return this.renderSuccessSection(
          this.t("wishlist.modal.appointmentSuccess")
        );
      case formTypes.CALLBACK:
        return this.renderSuccessSection(
          this.t("wishlist.modal.callbackSuccess")
        );
      case formTypes.RESERVE:
        return this.renderSuccessSection(
          this.t("wishlist.modal.reserveSuccess")
        );
      case formTypes.SAVE:
        return this.renderSuccessSection(this.t("wishlist.modal.saveSuccess"));
      case formTypes.SEND:
      case formTypes.COMPARISON_EMAIL:
        return this.renderSuccessSection(this.t("wishlist.modal.sendSuccess"));
      case formTypes.PRICEMATCH:
        return this.renderSuccessSection(
          this.t("wishlist.modal.priceMatchSuccess")
        );
      case formTypes.PRINT:
      case formTypes.COMPARISON_PRINT:
        return this.renderSuccessSection(this.t("wishlist.modal.printSuccess"));
      case formTypes.DOWNLOAD:
      case formTypes.COMPARISON_DOWNLOAD:
        return this.renderSuccessSection(
          this.t("wishlist.modal.downloadSuccess")
        );
    }
  };

  getReminderSuccess = () => {
    return this.renderSuccessSection(this.t("wishlist.modal.reminderSuccess"));
  };

  renderSuccessSection = (message) => {
    return (
      <div className="order-success-div">
        <span className="title">
          <div className="title-div no-button">
            <i className="fa fa-check" />
          </div>
        </span>
        <div className="text">{message}</div>
      </div>
    );
  };

  renderErrorSection = (api) => {
    return (
      <div className="order-error-div">
        <span className="title">
          <div className="title-div no-button">
            <i className="fa fa-close" />
          </div>
        </span>
        <div className="text">
          {api
            ? this.t("wishlist.modal.errorNoReturn")
            : this.state.actionFailureMsg}
        </div>
      </div>
    );
  };

  renderReminderError = () => {
    return (
      <div className="order-error-div">
        <span className="title">
          <div className="title-div no-button">
            <i className="fa fa-close" />
          </div>
        </span>
        <div className="text">
          {this.t("wishlist.modal.errorNoReminderReturn")}
        </div>
      </div>
    );
  };

  getFormActions = () => {
    switch (this.props.formType) {
      case formTypes.APPOINTMENT:
        return (
          <button
            className={setAdobeLaunchClass(
              "btn",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.CONTACT.TYPE,
              this.props.formType
            )}
            onClick={this.requestAppointment}
          >
            {this.t("wishlist.modal.appointment")}
          </button>
        );
      case formTypes.CALLBACK:
        return (
          <button
            className={setAdobeLaunchClass(
              "btn",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.CONTACT.TYPE,
              this.props.formType
            )}
            onClick={this.requestCallback}
          >
            {this.t("wishlist.modal.callback")}
          </button>
        );
      case formTypes.RESERVE:
        var text = "";
        switch (applicationStore.data.defaultTemplate) {
          case "generic":
            if (isUserLoggedIn()) {
              text = this.t("wishlist.modal.titleOrderDealerQuote");
            } else {
              text = this.t("wishlist.modal.reserve");
            }
            break;
          default:
            text = this.t("wishlist.modal.reserve");
        }

        return (
          <button
            className={setAdobeLaunchClass(
              "btn",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.CONTACT.TYPE,
              this.props.formType
            )}
            onClick={this.requestReserve}
          >
            {text}
          </button>
        );
      case formTypes.SAVE:
      case formTypes.SEND:
        return (
          <button
            className={setAdobeLaunchClass(
              "btn",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.CONTACT.TYPE,
              this.props.formType
            )}
            onClick={this.requestSend}
          >
            {this.t("wishlist.modal.send")}
          </button>
        );
      case formTypes.PRICEMATCH:
        return (
          <button
            className={setAdobeLaunchClass(
              "btn",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.CONTACT.TYPE,
              this.props.formType
            )}
            onClick={this.requestCompare}
          >
            {this.t("wishlist.modal.priceMatch")}
          </button>
        );
      case formTypes.PRINT:
        return (
          <div>
            <button
              className={setAdobeLaunchClass(
                "btn",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                ADOBELAUNCHER_DTM.CONTACT.TYPE,
                this.props.formType
              )}
              onClick={() => this.requestPrint(false)}
            >
              {this.t("wishlist.modal.print")}
            </button>
            <button
              className={setAdobeLaunchClass(
                "btn",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                ADOBELAUNCHER_DTM.CONTACT.TYPE,
                this.props.formType
              )}
              onClick={() => this.requestPrint(true)}
            >
              {this.t("wishlist.modal.printAndEmail")}
            </button>
          </div>
        );
      case formTypes.DOWNLOAD:
        return (
          <div>
            <button
              className={setAdobeLaunchClass(
                "btn",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                ADOBELAUNCHER_DTM.CONTACT.TYPE,
                this.props.formType
              )}
              onClick={this.requestDownload.bind(this, false)}
            >
              {this.t("wishlist.modal.download")}
            </button>
            <button
              className={setAdobeLaunchClass(
                "btn",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                ADOBELAUNCHER_DTM.CONTACT.TYPE,
                this.props.formType
              )}
              onClick={this.requestDownload.bind(this, true)}
            >
              {this.t("wishlist.modal.downloadAndEmail")}
            </button>
          </div>
        );
      case formTypes.COMPARISON_EMAIL:
        return (
          <button
            className={setAdobeLaunchClass(
              "btn",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.CONTACT.TYPE,
              this.props.formType
            )}
            onClick={this.requestComparisonEmail}
          >
            {this.t("wishlist.modal.send")}
          </button>
        );
      case formTypes.COMPARISON_PRINT:
        return (
          <button
            className={setAdobeLaunchClass(
              "btn",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.CONTACT.TYPE,
              this.props.formType
            )}
            onClick={this.requestComparisonPrint}
          >
            {this.t("wishlist.modal.print")}
          </button>
        );
      case formTypes.COMPARISON_DOWNLOAD:
        return (
          <button
            className={setAdobeLaunchClass(
              "btn",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.CONTACT.TYPE,
              this.props.formType
            )}
            onClick={this.requestComparisonDownload}
          >
            {this.t("wishlist.modal.download")}
          </button>
        );
    }
  };

  requestReminder = (type, callbackFunction) => {
    if (this.state.reminderNotification) {
      if (this.dateIsValid(this.state.calendarDate)) {
        googleAnalyticsEnhancedTrackEvent(
          getTrackingEventCategory(),
          "Shopping Cart Quick View Form - Request Reminder",
          formTracking[this.props.formType]
        );
        shoppingCartViewStore.setFormData(
          this.getFirstName(),
          this.getLastName(),
          this.getEmail(),
          this.getPhone(),
          this.getNotes()
        );
        shoppingCartActions.requestFormReminder(
          type,
          callbackFunction,
          this.state.calendarDate,
          this.getFirstName(),
          this.getLastName(),
          this.getEmail(),
          this.getPhone(),
          this.getNotes(),
          this.getAdobeLauncherFormSubmitStatus()
        );
      }
    } else {
      callbackFunction();
    }
  };

  requestAppointment = () => {
    this.resetErrors();
    if (this.dateIsValid(this.state.calendarDate)) {
      if (this.emailIsValid()) {
        googleAnalyticsEnhancedTrackEvent(
          getTrackingEventCategory(),
          "lead modal complete",
          formTracking[this.props.formType]
        );
        shoppingCartViewStore.setFormData(
          this.getFirstName(),
          this.getLastName(),
          this.getEmail(),
          this.getPhone(),
          this.getNotes()
        );
        shoppingCartActions.requestFormAppointment(
          this.state.calendarDate,
          this.getFirstName(),
          this.getLastName(),
          this.getEmail(),
          this.getPhone(),
          this.getNotes(),
          this.getAdobeLauncherFormSubmitStatus()
        );
      }
    }
  };

  requestCallback = () => {
    this.resetErrors();
    if (this.phoneIsValid()) {
      var _this = this,
        formType = this.props.formType;
      this.requestReminder("callback", function () {
        googleAnalyticsEnhancedTrackEvent(
          getTrackingEventCategory(),
          "lead modal complete",
          formTracking[formType]
        );
        shoppingCartViewStore.setFormData(
          _this.getFirstName(),
          _this.getLastName(),
          _this.getEmail(),
          _this.getPhone(),
          _this.getNotes()
        );
        setTimeout(() => {
          shoppingCartActions.requestFormCallback(
            _this.getFirstName(),
            _this.getLastName(),
            _this.getEmail(),
            _this.getPhone(),
            _this.getNotes(),
            _this.getAdobeLauncherFormSubmitStatus()
          );
        }, 100);
      });
    }
  };

  requestReserve = () => {
    this.resetErrors();
    if (this.dateIsValid(this.state.calendarDate, isUserLoggedIn())) {
      if (this.emailIsValid()) {
        googleAnalyticsEnhancedTrackEvent(
          getTrackingEventCategory(),
          "lead modal complete",
          formTracking[this.props.formType]
        );
        shoppingCartViewStore.setFormData(
          this.getFirstName(),
          this.getLastName(),
          this.getEmail(),
          this.getPhone(),
          this.getNotes()
        );
        shoppingCartActions.requestFormReserve(
          this.state.calendarDate,
          this.getFirstName(),
          this.getLastName(),
          this.getEmail(),
          this.getPhone(),
          this.getNotes(),
          this.getAdobeLauncherFormSubmitStatus()
        );
      }
    }
  };

  requestSend = () => {
    var _this = this;
    if (!this.emailIsValid()) console.warn("Invalid email!");
    if (!this.phoneIsValid()) console.warn("Invalid phone number!");
    this.resetErrors();
    if (
      this.emailIsValid() ||
      (isEmpty(this.getEmail()) && this.props.formType === formTypes.SAVE)
    ) {
      var formType = this.props.formType;
      this.requestReminder("email", function () {
        googleAnalyticsEnhancedTrackEvent(
          getTrackingEventCategory(),
          "lead form complete",
          getQuoteTrackingLabelInfo("email"),
          shoppingCartViewStore.data.get("total")
        );
        shoppingCartViewStore.setFormData(
          _this.getFirstName(),
          _this.getLastName(),
          _this.getEmail(),
          _this.getPhone(),
          _this.getNotes()
        );
        setTimeout(() => {
          shoppingCartActions.requestFormEmail(
            _this.getFirstName(),
            _this.getLastName(),
            _this.getEmail(),
            _this.getPhone(),
            _this.getNotes(),
            _this.getAdobeLauncherFormSubmitStatus()
          );
        }, 100);
      });
    }
  };

  requestCompare = () => {
    this.resetErrors();
    var _this = this,
      formType = this.props.formType;
    this.requestReminder("pricematch", function () {
      var firstPartId = 0,
        secondPartId = 0,
        thirdPartId = 0;
      productListStore.data.comparisonPartList.map(function (part, index) {
        if (index === 0) firstPartId = part.get("frontTire").get("id");
        else if (index === 1) secondPartId = part.get("frontTire").get("id");
        else if (index === 2) thirdPartId = part.get("frontTire").get("id");
      });
      googleAnalyticsEnhancedTrackEvent(
        getTrackingEventCategory(),
        "lead modal complete",
        formTracking[formType]
      );
      shoppingCartViewStore.setFormData(
        _this.getFirstName(),
        _this.getLastName(),
        _this.getEmail(),
        _this.getPhone(),
        _this.getNotes()
      );
      setTimeout(() => {
        shoppingCartActions.requestFormCompare(
          _this.getFirstName(),
          _this.getLastName(),
          _this.getEmail(),
          _this.getPhone(),
          _this.getNotes(),
          firstPartId,
          secondPartId,
          thirdPartId
        );
      }, 100);
    });
  };

  requestPrint = (sendEmail) => {
    this.resetErrors();
    localStorage.setItem("orderNumber", getOrderIdentifier());
    if (sendEmail) {
      if (this.emailIsValid()) {
        var _this = this,
          formType = this.props.formType;
        this.requestReminder("print", function () {
          googleAnalyticsEnhancedTrackEvent(
            getTrackingEventCategory(),
            "lead form complete",
            getQuoteTrackingLabelInfo("print"),
            shoppingCartViewStore.data.get("total")
          );
          shoppingCartViewStore.setFormData(
            _this.getFirstName(),
            _this.getLastName(),
            _this.getEmail(),
            _this.getPhone(),
            _this.getNotes()
          );
          shoppingCartActions.requestFormPrint(
            onOpenPrinterFriendly(),
            true,
            _this.getFirstName(),
            _this.getLastName(),
            _this.getEmail(),
            _this.getPhone(),
            _this.getNotes(),
            true,
            _this.getAdobeLauncherFormSubmitStatus()
          );
        });
      }
    } else {
      var _this = this,
        formType = this.props.formType;
      this.requestReminder("download", function () {
        if (_this.informationValid()) {
          googleAnalyticsEnhancedTrackEvent(
            getTrackingEventCategory(),
            "lead form complete",
            getQuoteTrackingLabelInfo("print"),
            shoppingCartViewStore.data.get("total")
          );
          shoppingCartViewStore.setFormData(
            _this.getFirstName(),
            _this.getLastName(),
            _this.getEmail(),
            _this.getPhone(),
            _this.getNotes()
          );
          shoppingCartActions.requestFormPrint(
            onOpenPrinterFriendly(),
            false,
            _this.getFirstName(),
            _this.getLastName(),
            _this.getEmail(),
            _this.getPhone(),
            _this.getNotes(),
            false,
            _this.getAdobeLauncherFormSubmitStatus()
          );
        } else {
          onOpenPrinterFriendly();
        }
      });
    }
  };

  requestDownload = (sendEmail) => {
    this.resetErrors();
    if (sendEmail) {
      if (this.emailIsValid()) {
        var _this = this,
          formType = this.props.formType;
        this.requestReminder("callback", function () {
          googleAnalyticsEnhancedTrackEvent(
            getTrackingEventCategory(),
            "lead modal complete",
            formTracking[formType]
          );
          shoppingCartViewStore.setFormData(
            _this.getFirstName(),
            _this.getLastName(),
            _this.getEmail(),
            _this.getPhone(),
            _this.getNotes()
          );
          shoppingCartActions.requestFormDownload(
            onReadyPdfPrint(_this.state, _this.updateState),
            true,
            _this.getFirstName(),
            _this.getLastName(),
            _this.getEmail(),
            _this.getPhone(),
            _this.getNotes(),
            true,
            _this.getAdobeLauncherFormSubmitStatus()
          );
        });
      }
    } else {
      var _this = this,
        formType = this.props.formType;
      this.requestReminder("callback", function () {
        if (_this.informationValid()) {
          googleAnalyticsEnhancedTrackEvent(
            getTrackingEventCategory(),
            "lead modal complete",
            formTracking[formType]
          );
          shoppingCartViewStore.setFormData(
            _this.getFirstName(),
            _this.getLastName(),
            _this.getEmail(),
            _this.getPhone(),
            _this.getNotes()
          );
          shoppingCartActions.requestFormDownload(
            onReadyPdfPrint(_this.state, _this.updateState),
            false,
            _this.getFirstName(),
            _this.getLastName(),
            _this.getEmail(),
            _this.getPhone(),
            _this.getNotes(),
            false,
            _this.getAdobeLauncherFormSubmitStatus()
          );
        } else {
          onReadyPdfPrint(_this.state, _this.updateState);
        }
      });
    }
  };

  requestComparisonEmail = () => {
    this.resetErrors();
    if (this.emailIsValid()) {
      var firstPartId = 0,
        secondPartId = 0,
        thirdPartId = 0;
      productListStore.data.comparisonPartList.map(function (part, index) {
        if (index === 0) firstPartId = part.get("frontTire").get("id");
        else if (index === 1) secondPartId = part.get("frontTire").get("id");
        else if (index === 2) thirdPartId = part.get("frontTire").get("id");
      });
      googleAnalyticsEnhancedTrackEvent(
        getTrackingEventCategory(),
        "lead modal complete",
        formTracking[this.props.formType]
      );
      shoppingCartViewStore.setFormData(
        this.getFirstName(),
        this.getLastName(),
        this.getEmail(),
        this.getPhone(),
        this.getNotes()
      );
      shoppingCartActions.requestComparisonEmail(
        emailComparison(
          this.getFirstName(),
          this.getLastName(),
          this.getEmail(),
          this.getPhone(),
          this.getNotes()
        ),
        this.getFirstName(),
        this.getLastName(),
        this.getEmail(),
        this.getPhone(),
        this.getNotes(),
        firstPartId,
        secondPartId,
        thirdPartId
      );
    }
  };

  requestComparisonPrint = () => {
    this.resetErrors();
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "lead modal complete",
      formTracking[this.props.formType]
    );
    if (this.informationValid()) {
      var firstPartId = 0,
        secondPartId = 0,
        thirdPartId = 0;
      productListStore.data.comparisonPartList.map(function (part, index) {
        if (index === 0) firstPartId = part.get("frontTire").get("id");
        else if (index === 1) secondPartId = part.get("frontTire").get("id");
        else if (index === 2) thirdPartId = part.get("frontTire").get("id");
      });
      shoppingCartViewStore.setFormData(
        this.getFirstName(),
        this.getLastName(),
        this.getEmail(),
        this.getPhone(),
        this.getNotes()
      );
      shoppingCartActions.requestComparisonPrint(
        printComparison(),
        this.getFirstName(),
        this.getLastName(),
        this.getEmail(),
        this.getPhone(),
        this.getNotes(),
        firstPartId,
        secondPartId,
        thirdPartId
      );
    } else {
      printComparison();
    }
  };

  requestComparisonDownload = () => {
    this.resetErrors();
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "lead modal complete",
      formTracking[this.props.formType]
    );
    if (this.informationValid()) {
      var firstPartId = 0,
        secondPartId = 0,
        thirdPartId = 0;
      let comparisonPartList = store.getState().productList.comparisonList;
      comparisonPartList.map(function (part, index) {
        if (index === 0) firstPartId = part.id;
        else if (index === 1) secondPartId = part.id;
        else if (index === 2) thirdPartId = part.id;
      });
      shoppingCartViewStore.setFormData(
        this.getFirstName(),
        this.getLastName(),
        this.getEmail(),
        this.getPhone(),
        this.getNotes()
      );
      // shoppingCartActions.requestComparisonDownload(this.props.dispatch(onSetComparisonPdfLauncher(true)), this.getFirstName(), this.getLastName(), this.getEmail(), this.getPhone(), this.getNotes(), firstPartId, secondPartId, thirdPartId);
      shoppingCartActions.requestComparisonDownload(
        productListActions.onSetComparisonPdfLauncher(true),
        this.getFirstName(),
        this.getLastName(),
        this.getEmail(),
        this.getPhone(),
        this.getNotes(),
        firstPartId,
        secondPartId,
        thirdPartId
      );
    } else {
      // this.props.dispatch(onSetComparisonPdfLauncher(true))
      productListActions.onSetComparisonPdfLauncher(true);
    }
  };

  dateIsValid = (date, skipValidation = false) => {
    if (skipValidation || (date !== null && isValidDate(date))) {
      return true;
    } else {
      this.hasError(this.t("wishlist.modal.errorNoDate"));
      return false;
    }
  };

  phoneIsValid = () => {
    var phone = document.getElementById("formPhone");
    if (phone && sizeOf(phone.value) > 0) {
      return true;
    } else {
      this.hasError(this.t("wishlist.modal.errorNoPhone"));
      return false;
    }
  };

  emailIsValid = () => {
    var email = document.getElementById("formEmail");
    if (email && sizeOf(email.value) > 0) {
      if (validateEmail(email.value)) {
        return true;
      } else {
        this.hasError(this.t("wishlist.modal.errorInvalidEmail"));
        return false;
      }
    } else {
      this.hasError(this.t("wishlist.modal.errorNoEmail"));
      return false;
    }
  };

  getFirstName = () => {
    return document.getElementById("formFirstName").value;
  };

  getLastName = () => {
    return document.getElementById("formLastName").value;
  };

  getEmail = () => {
    return document.getElementById("formEmail").value;
  };

  getPhone = () => {
    return document.getElementById("formPhone").value;
  };

  getNotes = () => {
    return document.getElementById("formNotes").value;
  };

  getAdobeLauncherFormSubmitStatus = () => {
    if (
      !isEmpty(this.getFirstName()) &&
      !isEmpty(this.getLastName()) &&
      !isEmpty(this.getEmail()) &&
      !isEmpty(this.getPhone())
    ) {
      return ADOBELAUNCHER_FORM_SUBMIT.COMPLETE;
    } else if (
      !isEmpty(this.getFirstName()) ||
      !isEmpty(this.getLastName()) ||
      !isEmpty(this.getEmail()) ||
      !isEmpty(this.getPhone())
    ) {
      return ADOBELAUNCHER_FORM_SUBMIT.PARTIAL;
    } else {
      return ADOBELAUNCHER_FORM_SUBMIT.NONCOMPLETE;
    }
  };

  informationValid = () => {
    return (
      this.getFirstName() !== "" ||
      this.getLastName() !== "" ||
      this.getEmail() !== "" ||
      this.getPhone() !== "" ||
      this.getNotes() !== ""
    );
  };

  getEmailMandatory = () => {
    switch (this.props.formType) {
      case formTypes.APPOINTMENT:
      case formTypes.RESERVE:
      case formTypes.SEND:
      case formTypes.COMPARISON_EMAIL:
        return true;
      case formTypes.CALLBACK:
      case formTypes.PRICEMATCH:
      case formTypes.DOWNLOAD:
      case formTypes.COMPARISON_PRINT:
      case formTypes.COMPARISON_DOWNLOAD:
      case formTypes.SAVE:
        return false;
      case formTypes.PRINT:
        return isNationalSite();
    }
  };

  getPhoneMandatory = () => {
    switch (this.props.formType) {
      // case formTypes.APPOINTMENT:
      // case formTypes.RESERVE:
      // case formTypes.SEND:
      // case formTypes.COMPARISON_EMAIL:
      // case formTypes.PRICEMATCH:
      // case formTypes.PRINT:
      // case formTypes.DOWNLOAD:
      // case formTypes.COMPARISON_PRINT:
      // case formTypes.COMPARISON_DOWNLOAD:
      // case formTypes.SAVE:
      //   return false;
      case formTypes.CALLBACK:
        return true;
      default:
        return false;
    }
  };

  formController = () => {
    return {
      classList: {
        firstName: "firstName",
        lastName: "lastName",
        email: "email",
        phone: "phone",
        notes: "notes",
      },
      idList: {
        firstName: "formFirstName",
        lastName: "formLastName",
        email: "formEmail",
        phone: "formPhone",
        notes: "formNotes",
      },
      mandatory: {
        firstName: false,
        lastName: false,
        email: this.getEmailMandatory(),
        phone: this.getPhoneMandatory(),
        notes: false,
      },
    };
  };

  renderFormSection = () => {
    var formController = this.formController();
    return (
      <div className="form-section">
        <ul>
          <li>
            {this.renderFormElement(
              this.t("wishlist.modal.firstName"),
              formController.classList.firstName,
              "name",
              "given-name",
              formController.idList.firstName,
              formController.mandatory.firstName
            )}
          </li>
          <li>
            {this.renderFormElement(
              this.t("wishlist.modal.lastName"),
              formController.classList.lastName,
              "name",
              "family-name",
              formController.idList.lastName,
              formController.mandatory.lastName
            )}
          </li>
          <li>
            {this.renderFormElement(
              this.t("wishlist.modal.email"),
              formController.classList.email,
              "email",
              "email",
              formController.idList.email,
              formController.mandatory.email
            )}
          </li>
          <li>
            {this.renderFormElement(
              this.t("wishlist.modal.phone"),
              formController.classList.phone,
              "phone",
              "tel",
              formController.idList.phone,
              formController.mandatory.phone
            )}
          </li>
          <li className="notes">
            {this.renderFormElement(
              this.t("wishlist.modal.notes"),
              formController.classList.notes,
              "",
              "",
              formController.idList.notes,
              formController.mandatory.notes
            )}
          </li>
        </ul>
      </div>
    );
  };

  renderFormElement = (title, className, name, autocomplete, id, mandatory) => {
    return (
      <div className={"form-element form-" + className}>
        <span>
          {mandatory ? title + " " : title}
          {mandatory ? (
            <span className="mandatory-text">
              {this.t("common.mandatoryField")}
            </span>
          ) : (
            false
          )}
        </span>
        {className === "notes" ? (
          <textarea
            className={setAdobeLaunchClass(
              className,
              ADOBELAUNCHER_CLASS.INPUT
            )}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.CONTACT.TYPE,
              this.props.formType
            )}
            id={id}
            maxLength="1000"
            onFocus={this.onFormFocus}
          />
        ) : (
          <input
            className={setAdobeLaunchClass(
              className,
              ADOBELAUNCHER_CLASS.INPUT
            )}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.CONTACT.TYPE,
              this.props.formType
            )}
            id={id}
            name={name}
            autocomplete={autocomplete}
            maxLength="120"
            onFocus={this.onFormFocus}
          />
        )}
      </div>
    );
  };

  onFormFocus = () => {};

  getFormTitle = () => {
    switch (this.props.formType) {
      case formTypes.APPOINTMENT:
        return this.t("wishlist.modal.titleRequestAppointment");
      case formTypes.CALLBACK:
        return this.t("wishlist.modal.titleRequestCallback");
      case formTypes.RESERVE:
        return isUserLoggedIn()
          ? this.t("wishlist.modal.titleReserveItems")
          : this.t("wishlist.modal.titleReserveItemsDealer");

      case formTypes.SAVE:
        return this.t("wishlist.modal.titleQuoteSave");
      case formTypes.SEND:
        return isUserLoggedIn()
          ? this.t("wishlist.modal.titleEmailDealer")
          : this.t("wishlist.modal.titleEmailCustomer");
      case formTypes.PRICEMATCH:
        return this.t("wishlist.modal.titlePriceMatchCustomer");
      case formTypes.PRINT:
        return this.t("wishlist.modal.titlePrintCustomer");
      case formTypes.DOWNLOAD:
        return this.t("wishlist.modal.titleDownloadCustomer");
      case formTypes.COMPARISON_EMAIL:
        return this.t("wishlist.modal.titleEmailComparison");
      case formTypes.COMPARISON_PRINT:
        return this.t("wishlist.modal.titlePrintComparison");
      case formTypes.COMPARISON_DOWNLOAD:
        return this.t("wishlist.modal.titleDownloadComparison");
    }
  };

  getFormSubtitle = () => {
    switch (this.props.formType) {
      case formTypes.APPOINTMENT:
        return this.t("wishlist.modal.subtitleRequestAppointment");
      case formTypes.CALLBACK:
        return this.t("wishlist.modal.subtitleRequestCallback");
      case formTypes.RESERVE:
        return isUserLoggedIn()
          ? this.t("wishlist.modal.subtitleReserveItems")
          : this.t("wishlist.modal.subtitleReserveItemsDealer");

      case formTypes.SAVE:
        return this.t("wishlist.modal.subtitleQuoteSave");
      case formTypes.SEND:
        return isUserLoggedIn()
          ? this.t("wishlist.modal.subtitleEmailDealer")
          : this.t("wishlist.modal.subtitleEmailCustomer");
      case formTypes.PRICEMATCH:
        return this.t("wishlist.modal.subtitlePriceMatchCustomer");
      case formTypes.PRINT:
        return this.t("wishlist.modal.subtitlePrintCustomer");
      case formTypes.DOWNLOAD:
        return this.t("wishlist.modal.subtitleDownloadCustomer");
      case formTypes.COMPARISON_EMAIL:
        return this.t("wishlist.modal.subtitleEmailComparion");
      case formTypes.COMPARISON_PRINT:
        return this.t("wishlist.modal.subtitlePrintComparion");
      case formTypes.COMPARISON_DOWNLOAD:
        return this.t("wishlist.modal.subtitleDownloadComparion");
    }
  };

  closeWislistFormModal = () => {
    // shoppingCartActions.closeWishlistFormModal();
    this.props.dispatch(shoppingCartCloseForm());
  };

  resetErrors = () => {
    this.setState({
      actionSuccess: false,
      actionFailure: false,
      actionFailureMsg: "",
      reminderFailure: false,
    });
  };

  hasError = (message) => {
    this.setState({
      actionSuccess: false,
      actionFailure: true,
      actionFailureMsg: message,
    });
  };

  onChange = () => {
    this.setState({
      cartItems: shoppingCartViewStore.data.get("cartItems"),
      actionSuccess: shoppingCartViewStore.data.get("shoppingCartFormSuccess"),
      apiFailure: shoppingCartViewStore.data.get("shoppingCartFormError"),
      reminderSuccess: shoppingCartViewStore.data.get(
        "shoppingCartReminderSuccess"
      ),
      reminderError: shoppingCartViewStore.data.get(
        "shoppingCartReminderError"
      ),
    });
  };
}

const mapStateToProps = (state) => {
  return {
    cartInformation: state.shoppingCart.cartInformation,
    shoppingCartView: state.shoppingCart.cartItems,
  };
};
export default connect(mapStateToProps)(WishlistFormModal);

import { applicationStore } from "../stores/ApplicationStore";
import { breadcrumbsStore } from "../stores/BreadcrumbsStore";
import { shoppingCartStore } from "../stores/ShoppingCartStore";
import { productDetailStore } from "../stores/ProductDetailStore";

import { RouterStates } from "../constants/RouterStates";
import { ProductTypes } from "../constants/ProductTypes";

import { isUserLoggedIn } from "../api/AuthService";
import {
  isEmpty,
  getWindowTemplate,
  formTypes,
  getRouterState,
  getAppLanguage,
  getDealerName,
  getAppDealerId,
  isNationalSite,
  sizeOf,
  isMobile,
  isTablet,
} from '../service/UtilityService';
import store from "../stores/configureStore";

export const ADOBELAUNCHER_FORM_SUBMIT = {
  COMPLETE: 'form-complete',
  NONCOMPLETE: 'form-noncomplete',
  PARTIAL: 'form-partial',
};

export const ADOBELAUNCHER_CLASS = {
  ARROW: {
    PREV: 'stat-arrow-prev',
    NEXT: 'stat-arrow-next',
  },
  BUTTON: {
    CLOSE: 'stat-button-close',
    LINK: 'stat-button-link',
  },
  CHECKBOX: 'stat-checkbox',
  DOTS: 'stat-dots',
  DROPDOWN: 'stat-dropdown',
  ICON: 'stat-expand-icon',
  IMAGE: 'stat-image-link',
  INPUT: 'stat-input-field',
  RADIO: 'stat-radio',
  SEARCH: {
    INPUT: 'stat-search-input',
    SUBMIT: 'stat-search-submit',
  },
  TAB: 'stat-tab-link',
  TEXT: 'stat-text-link',
};

export const ADOBELAUNCHER_DTM = {
  GLOBALNAV: {
    BASE: 'global nav',
    RESTART: 'global nav:restart',
  },
  SECONDARYNAV: 'secondary nav',
  SEARCH: {
    BASE: 'search',
    TIRESWHEELS: 'search:<<tires||wheels>>',
    TIRESWHEELSVEHICLE: 'search:<<tires||wheels>>:vehicle',
    TIRESIZE: 'search:tires:size',
  },
  TIRESIZE: {
    BASE: 'tire size',
    FIND: 'find tire size',
    VEHICLE: 'tire size:<<vehicle name>>',
  },
  OPTIONS: {
    TIREWHEEL: '<<tire||wheel>> options',
    TIREWHEELFILTER: '<<tire||wheel>> options:<<filter category>>',
  },
  FEATUREDITEMS: {
    BASE: 'featured items',
    PRODUCT: 'featured items:<<product name>>',
    VISUALIZER: 'featured items:visualizer',
    VISUALIZERPRODUCT: 'featured items:visualizer:<<product name>>',
  },
  YOURQUOTE: {
    BASE: 'your quote',
    PRODUCT: 'your quote:<<product name>>',
  },
  CONTACT: {
    BASE: 'contact',
    TYPE: 'contact:<<print||download||email||save>> quote',
  },
  REQUESTAPPOINTMENT: {
    BASE: 'request appointment',
    DATE: 'request appointment:date',
  },
  CAROUSELDOTS: 'masthead',
  PRODUCTSELECTTILE: 'shop',
  COMPARE: {
    BASE: 'compare',
    FULL: 'compare:full list',
    NOTIFICATION: 'compare:notification',
    PRODUCT: 'compare:<<product name>>',
  },
  FINDTIRESIZE: 'find tire size',
  MYQUOTEMODAL: {
    BASE: 'my quote modal',
    DETAIL: 'my quote modal:product detail',
  },
  DEALERCONTACTMODAL: 'dealer contact modal',
  TIREFINDER: 'tire finder',
  SHOPBYVEHICLE: 'shop by vehicle',
  SHOPBYTIRESIZE: 'shop by size',
  YEARSELECTION: 'year selection',
  BRANDSELECTION: 'brand selection',
  MODELSELECTION: 'model selection',
  PARTDETAILS: 'part details',
  RETAILER: 'retailer',
  RESULTS: {
    BASE: 'results',
    DEALERNAME: 'results:<<dealer name>>',
    MAP: 'results map:<<dealer name>>',
  },
};

export const runAdobeServicePageView = () => {

  if (enableAdobeLaunch()) {

    var interval = setInterval(function() {
      if (!applicationStore.isApiCallInProgress()) {

        runAdobePageView();
        clearInterval(interval);
      }
    }, 1000);

  }

};

export const runAdobeServiceFormStart = () => {

  if (enableAdobeLaunch()) {
    runAdobeFormStart();
  }

};

export const runAdobeServiceFormSubmit = (submissiontype) => {
  switch(submissiontype) {
    case ADOBELAUNCHER_FORM_SUBMIT.COMPLETE:
      runAdobeServiceFormComplete(); break;
    case ADOBELAUNCHER_FORM_SUBMIT.NONCOMPLETE:
      runAdobeServiceFormNonComplete(); break;
    case ADOBELAUNCHER_FORM_SUBMIT.PARTIAL:
      runAdobeServiceFormPartial(); break;
  }
};

const runAdobeServiceFormComplete = () => {

  if (enableAdobeLaunch()) {
    runAdobeFormComplete();
  }

};

const runAdobeServiceFormPartial = () => {

  if (enableAdobeLaunch()) {
    runAdobeFormPartial();
  }

};

const runAdobeServiceFormNonComplete = () => {

  if (enableAdobeLaunch()) {
    runAdobeFormNonComplete();
  }

};

export const enableAdobeLaunch = () => {

  switch(getWindowTemplate()) {
    case "gmcanada":
      return !isEmpty(window.adobeLauncherUrl);
    default:
      return false;
  }

};

export const setAdobeLaunchClass = (originalclass, adobeclass) => {
  
  if (enableAdobeLaunch()) {
    return (!isEmpty(originalclass) ? originalclass + " " : "") + adobeclass;
  } else {
    return originalclass;
  }
  
};

export const setAdobeLaunchDTM = (originaldtm, adobedtm, value = null) => {

  if (enableAdobeLaunch()) {
    return (!isEmpty(originaldtm) ? originaldtm + " " : "");
      // + setAdobeLaunchDTMValue(adobedtm, value).toLowerCase();


  } else {
    return originaldtm;
  }

};

const setAdobeLaunchDTMValue = (dtm, value) => {
  if (!isEmpty(dtm)) {
    if (dtm.indexOf('<<tire||wheel>>') > -1) {
      var type = value;
      if (dtm.indexOf('<<filter category>>') > -1) {
        dtm = dtm.replace('<<filter category>>', value);
        type = applicationStore.data.productType;
      }
      switch(type) {
        case ProductTypes.TIRES:
          return dtm.replace('<<tire||wheel>>', 'tire');
        case ProductTypes.ALLOY_WHEELS:
          return dtm.replace('<<tire||wheel>>', 'wheel');
        case ProductTypes.ACCESSORIES:
          return dtm.replace('<<tire||wheel>>', 'accessory');
        case ProductTypes.PACKAGES:
          return dtm.replace('<<tire||wheel>>', 'package');
        default:
          return dtm.replace('<<tire||wheel>>', '');
      }
    } else if (dtm.indexOf('<<tires||wheels>>') > -1) {
      switch(value) {
        case ProductTypes.TIRES:
          return dtm.replace('<<tires||wheels>>', 'tires');
        case ProductTypes.ALLOY_WHEELS:
          return dtm.replace('<<tires||wheels>>', 'wheels');
        case ProductTypes.ACCESSORIES:
          return dtm.replace('<<tires||wheels>>', 'accessories');
        case ProductTypes.PACKAGES:
          return dtm.replace('<<tires||wheels>>', 'packages');
        default:
          return dtm.replace('<<tires||wheels>>', '');
      }
    } else if (dtm.indexOf('<<print||download||email||save>>') > -1) {
      switch(shoppingCartStore.data.get('wishlistFormType')) {
        case formTypes.SEND:
          return dtm.replace('<<print||download||email||save>>', 'email');
        case formTypes.CALLBACK:
          return dtm.replace('<<print||download||email||save>>', 'callback');
        case formTypes.SAVE:
          return dtm.replace('<<print||download||email||save>>', 'save');
        case formTypes.PRICEMATCH:
          return dtm.replace('<<print||download||email||save>>', 'pricematch');
        case formTypes.PRINT:
          return dtm.replace('<<print||download||email||save>>', 'print');
        case formTypes.DOWNLOAD:
          return dtm.replace('<<print||download||email||save>>', 'download');
        case formTypes.RESERVE:
          return dtm.replace('<<print||download||email||save>>', 'reserve');
        case formTypes.APPOINTMENT:
          return dtm.replace('<<print||download||email||save>>', 'appointment');
        case formTypes.COMPARISON_EMAIL:
        case formTypes.COMPARISON_PRINT:
        case formTypes.COMPARISON_DOWNLOAD:
          return dtm.replace('<<print||download||email||save>>', '');
      }
    } else if (dtm.indexOf('<<product name>>') > -1) {
      return dtm.replace('<<product name>>', value.trim());
    } else if (dtm.indexOf('<<filter category>>') > -1) {
      return dtm.replace('<<filter category>>', value.trim());
    } else if (dtm.indexOf('<<vehicle name>>') > -1) {
      return dtm.replace('<<vehicle name>>', value.trim());
    } else if (dtm.indexOf('<<dealer name>>') > -1) {
      return dtm.replace('<<dealer name>>', value.trim());
    } else {
      return dtm;
    }
  } else {
    return "";
  }
};

export const setAdobeLauncherDigitalData = function() {
  /*
    case RouterStates.DEALER_SELECTION:
    case RouterStates.LANDING_PAGE:
    case RouterStates.CONTACT_DEALER:
    case RouterStates.TRANSLATION_DICTIONARY:
    case RouterStates.HOMEPAGE:
   */
  switch (getRouterState()) {
    case RouterStates.YEAR:
    case RouterStates.MAKE:
    case RouterStates.CATEGORY:
      return {
        pageInfo: getPageInfo(),
        vehicleInfo: getVehicleInfo(true, true),
        toolsInfo: getToolsInfo(),
        formInfo: getFormInfo(),
        visitorProfile: getVisitorInfo(),
      };
    case RouterStates.MODEL:
    case RouterStates.TRIM:
      return {
        pageInfo: getPageInfo(),
        vehicleInfo: getVehicleInfo(true, true, true),
        toolsInfo: getToolsInfo(),
        formInfo: getFormInfo(),
        visitorProfile: getVisitorInfo(),
      };
    case RouterStates.PRODUCT_DETAIL:
    case RouterStates.PRODUCT_LIST:
    case RouterStates.SEARCH_RESULT:
    case RouterStates.WISHLIST:
    case RouterStates.PRINT_WISHLIST:
    case RouterStates.PRINT_WISHLIST_TEST:
    case RouterStates.PRINT_COMPARISON_FROM_TIRESIZE:
    case RouterStates.SEARCH_BRANDS_BY_SEASON:
    case RouterStates.SEARCH_BRANDS:
    case RouterStates.SEARCH_MODELS_BY_SEASON:
    case RouterStates.SEARCH_MODELS:
    case RouterStates.SEARCH_TIRE_BY_SEASON:
    case RouterStates.SEARCH_TIRE:
      return {
        pageInfo: getPageInfo(),
        vehicleInfo: getVehicleInfo(true, true, true, true, true),
        toolsInfo: getToolsInfo(),
        formInfo: getFormInfo(),
        visitorProfile: getVisitorInfo(),
      };
    default:
      return {
        pageInfo: getPageInfo(),
        vehicleInfo: getVehicleInfo(),
        toolsInfo: getToolsInfo(),
        formInfo: getFormInfo(),
        visitorProfile: getVisitorInfo(),
      };
  }
};

const getPageInfo = function() {
  return({
    pageName: getPageName(),
    siteSectionsLevel1: getSiteSectionLevels().siteSectionsLevel1,
    siteSectionsLevel2: getSiteSectionLevels().siteSectionsLevel2,
    siteSectionsLevel3: getSiteSectionLevels().siteSectionsLevel3,
    siteSectionsLevel4: getSiteSectionLevels().siteSectionsLevel4,
    siteSectionsLevel5: getSiteSectionLevels().siteSectionsLevel5,
    url: getUrL(),
    seoStrategyPageName: "",
    languageSelected: getAppLanguage(),
    siteName: "tirelocator",
    brand: "gm",
    country: "ca",
    region: "na",
    renderExperience: getRenderExperience(),
    viewport: getViewport(),
    orientation: getOrientation(),
  });
};

const getSiteSectionLevels = function() {

  /*
    case RouterStates.PRINT_WISHLIST:
    case RouterStates.PRINT_WISHLIST_TEST:
    case RouterStates.PRINT_COMPARISON_FROM_TIRESIZE:
    case RouterStates.SEARCH_BRANDS_BY_SEASON:
    case RouterStates.SEARCH_BRANDS:
    case RouterStates.SEARCH_MODELS_BY_SEASON:
    case RouterStates.SEARCH_MODELS:
    case RouterStates.SEARCH_TIRE_BY_SEASON:
    case RouterStates.SEARCH_TIRE:
    case RouterStates.CONTACT_DEALER:
    case RouterStates.TRANSLATION_DICTIONARY:
    case RouterStates.LANDING_PAGE:
    case RouterStates.HOMEPAGE:
    case RouterStates.CATEGORY:
  */

  switch (getRouterState()) {
    case RouterStates.DEALER_SELECTION:
      return {
        siteSectionsLevel1: "tirelocator",
        siteSectionsLevel2: "retailer",
        siteSectionsLevel3: "undefined",
        siteSectionsLevel4: "undefined",
        siteSectionsLevel5: setAdobeDigitalDataSiteSection("tirelocator", "retailer", "undefined", "undefined"),
      };
      /*
    case RouterStates.LANDING_PAGE:
      return {
        siteSectionsLevel1: "tirelocator",
        siteSectionsLevel2: "landingpage",
        siteSectionsLevel3: "undefined",
        siteSectionsLevel4: "undefined",
        siteSectionsLevel5: setAdobeDigitalDataSiteSection("tirelocator", "undefined", "undefined", "undefined"),
      };
      */
    case RouterStates.YEAR:
      return {
        siteSectionsLevel1: "tirelocator",
        siteSectionsLevel2: getAppProductType(),
        siteSectionsLevel3: "year",
        siteSectionsLevel4: "undefined",
        siteSectionsLevel5: setAdobeDigitalDataSiteSection("tirelocator", getAppProductType(), "year", "undefined"),
      };
    case RouterStates.MAKE:
      return {
        siteSectionsLevel1: "tirelocator",
        siteSectionsLevel2: getAppProductType(),
        siteSectionsLevel3: "brand",
        siteSectionsLevel4: "undefined",
        siteSectionsLevel5: setAdobeDigitalDataSiteSection("tirelocator", getAppProductType(), "brand", "undefined"),
      };
    case RouterStates.MODEL:
      return {
        siteSectionsLevel1: "tirelocator",
        siteSectionsLevel2: getAppProductType(),
        siteSectionsLevel3: "model",
        siteSectionsLevel4: "undefined",
        siteSectionsLevel5: setAdobeDigitalDataSiteSection("tirelocator", getAppProductType(), "model", "undefined"),
      };
    case RouterStates.TRIM:
      return {
        siteSectionsLevel1: "tirelocator",
        siteSectionsLevel2: getAppProductType(),
        siteSectionsLevel3: "trim",
        siteSectionsLevel4: "undefined",
        siteSectionsLevel5: setAdobeDigitalDataSiteSection("tirelocator", getAppProductType(), "trim", "undefined"),
      };
    case RouterStates.PRODUCT_LIST:
    case RouterStates.SEARCH_RESULT:
      return {
        siteSectionsLevel1: "tirelocator",
        siteSectionsLevel2: getAppProductType(),
        siteSectionsLevel3: "products",
        siteSectionsLevel4: "undefined",
        siteSectionsLevel5: setAdobeDigitalDataSiteSection("tirelocator", getAppProductType(), "products", "undefined"),
      };
    case RouterStates.PRODUCT_DETAIL:
      return {
        siteSectionsLevel1: "tirelocator",
        siteSectionsLevel2: getAppProductType(),
        siteSectionsLevel3: "products",
        siteSectionsLevel4: getTireTypeFromDetail(),
        siteSectionsLevel5: setAdobeDigitalDataSiteSection("tirelocator", getAppProductType(), "products", getTireTypeFromDetail()),
      };
    case RouterStates.WISHLIST:
      return {
        siteSectionsLevel1: "tirelocator",
        siteSectionsLevel2: "quote",
        siteSectionsLevel3: "undefined",
        siteSectionsLevel4: "undefined",
        siteSectionsLevel5: setAdobeDigitalDataSiteSection("tirelocator", "quote", "undefined", "undefined"),
      };
    default:
      return {
        siteSectionsLevel1: "tirelocator",
        siteSectionsLevel2: "undefined",
        siteSectionsLevel3: "undefined",
        siteSectionsLevel4: "undefined",
        siteSectionsLevel5: setAdobeDigitalDataSiteSection("tirelocator", "undefined", "undefined", "undefined"),
      };
  }

};

const getAppProductType = function() {
  switch(applicationStore.data.productType) {
    case ProductTypes.TIRES:
      return "tires";
    case ProductTypes.ALLOY_WHEELS:
      return "wheels";
    case ProductTypes.ACCESSORIES:
      return "accessories";
    case ProductTypes.PACKAGES:
      return "packages";
    default:
      return "";
  }
};

const getVehicleInfo = function(hasVehicleType = false, hasModelYear = false, hasModelName = false, hasBodyStyle = false, hasTrimLevel = false) {
  return({
    vehicleType: hasVehicleType ? "" : "",
    modelName: hasModelName ? getModelName() : "",
    modelYear: hasModelYear ? getModelYear() : "",
    bodyStyle: hasBodyStyle ? "" : "",
    trimLevel: hasTrimLevel ? "" : "",
    tireSize: getTireSizeFromCart(),
    tireType: getTireTypeFromCart(),
    price: getPriceFromCart(),
  });
};

const getToolsInfo = function() {
  return({
    dealerName: !isEmpty(getDealerName()) ? getDealerName().toLowerCase() : "",
    dealerId: !isEmpty(getAppDealerId()) ? getAppDealerId() : "",
  });
};

const getFormInfo = function() {
  return({
    formName: getFormName(),
  });
};

const getVisitorInfo = function() {
  return({
    userProfileStateAuthentication: getUserAuthentication(),
  });
};

const getUserAuthentication = function() {
  if (isUserLoggedIn()) {
    return "logged in";
  } else {
    return "logged out";
  }
};

const getPageName = function() {
  return([
    "gm",
    "na",
    "ca",
    getAppLanguage(),
    getSiteTier(),
    "tirelocator",
    !isEmpty(getSiteSectionLevels().siteSectionsLevel2) ? getSiteSectionLevels().siteSectionsLevel2 : "",
    !isEmpty(getSiteSectionLevels().siteSectionsLevel3) ? getSiteSectionLevels().siteSectionsLevel3 : "",
    !isEmpty(getSiteSectionLevels().siteSectionsLevel3) && getRouterState() === RouterStates.PRODUCT_DETAIL ? getSiteSectionLevels().siteSectionsLevel4 : "",
  ].join(':').replace(/:+$/, ""));
};

const getSiteTier = function() {
  if (isNationalSite()) {
    return "t1";
  } else {
    return "t3";
  }
};

const getModelYear = function() {
  var breadcrumbs = breadcrumbsStore.data.crumbs, year = "";

  for (var i in breadcrumbs) {
    if (breadcrumbs[i] && breadcrumbs[i].state === RouterStates.YEAR) {
      year = breadcrumbs[i].value;
    }
  }

  return year;
};

const getModelName = function() {
  var breadcrumbs = breadcrumbsStore.data.crumbs, model = "";

  for (var i in breadcrumbs) {
    if (breadcrumbs[i] && breadcrumbs[i].state === RouterStates.MODEL) {
      model = breadcrumbs[i].value[getAppLanguage()];
    }
  }

  return model;
};

const getTireSizeFromCart = function() {

  return "";

  /*
  var cartItems = shoppingCartStore.getItems(), list = [];
  cartItems.map(function(item) {
    if (!isEmpty(list.push(item.get('name').get(getAppLanguage())))) { list.push(item.get('name').get(getAppLanguage()).toLowerCase()); }
  });
  return list.join(':').replace(/:+$/, "");
  */
};

const getTireTypeFromCart = function() {
  var cartItems = shoppingCartStore.getItems(), list = [];
  if (sizeOf(cartItems) > 0) {
    cartItems.map(function(item) {
      list.push(item.get('name').get(getAppLanguage()).toLowerCase());
    });
    return list.join(':').replace(/:+$/, "");
  } else {
    return "";
  }
};

const getPriceFromCart = function() {
  var cartItems = shoppingCartStore.getItems(), list = [];
  cartItems.map(function(item) {
    list.push(parseFloat(item.get('displayPrice')).toFixed(2));
  });
  return list.join(':').replace(/:+$/, "");
};

const getTireTypeFromDetail = function() {
  return sizeOf(productDetailStore.data.detail) > 0 ? productDetailStore.data.detail.get('title').get(getAppLanguage()) : "";
};

const getRenderExperience = function() {
  if (isMobile()) {
    return "Mobile";
  } else if (isTablet()) {
    return "Tablet";
  } else {
    return "Desktop";
  }
};

const getFormName = function() {
  let formType = store.getState().shoppingCart.formType;
  let formModal = store.getState().shoppingCart.formModal;
  if (formModal) {
    switch(formType) {
      case formTypes.SAVE:
        return "Save Quote";
      case formTypes.SEND:
        return "Email Quote";
      case formTypes.CALLBACK:
        return "Contact Us";
      case formTypes.APPOINTMENT:
        return "Get Appointment";
      case formTypes.RESERVE:
        return "Reserve Quote";
      case formTypes.PRICEMATCH:
        return "Price Match Quote";
      case formTypes.PRINT:
        return "Print Quote";
      case formTypes.DOWNLOAD:
        return "Download Quote";
      case formTypes.COMPARISON_EMAIL:
        return "Email Comparison";
      case formTypes.COMPARISON_PRINT:
        return "Print Comparison";
      case formTypes.COMPARISON_DOWNLOAD:
        return "Download Comparison";
    }
  } else if (getRouterState() === RouterStates.PRODUCT_DETAIL) {
    return "Request Information";
  } else if ((getRouterState() === RouterStates.PRODUCT_LIST || getRouterState() === RouterStates.SEARCH_RESULT) && applicationStore.data.productType === ProductTypes.TIRES && applicationStore.data.getCaptchaToken) {
    return "Dealer Contact";
  } else {
    return "";
  }
};

const getUrL = function() {
  return window.location.href;
};

const getViewport = function() {
  return window.innerWidth + 'x' + window.innerHeight;
};

const getOrientation = function() {
  return window.screen && window.screen.orientation ? decodeWindowOrientation() : "";
};

const decodeWindowOrientation = function() {
  if (window.screen.orientation.type && window.screen.orientation.type.toLowerCase().indexOf('portrait') > -1) {
    return "portrait";
  } else {
    return "landscape";
  }
};



import {createSlice,createAsyncThunk} from '@reduxjs/toolkit';
// import {loadAuthToken} from '../local-storage/local-storage';
import {getToken} from '../../api/AuthService';
import {getAppDealerId} from '../../service/UtilityService';
import {getApiBaseUrl} from '../../api/ApiService';
import {ProductTypes} from "../../constants/ProductTypes";
import {onVehicleYearsLoaded} from "../../service/VehicleService";
import {globalState} from "../../GlobalState";
import {RouterStates} from "../../constants/RouterStates";

export const fetchTireSearch = createAsyncThunk(
  'search/TireSize',
  async (args,{getState}) =>{
      let {tireId,tokenId} = getState().info
      return fetch(`${getApiBaseUrl()}/retailSiteWebService/parts/tire/searchTiresByTiresize.php`,{
          method:"POST",
          headers:{"Content-Type":"application/json"},
          body:JSON.stringify({
              dealerId:getAppDealerId(),
              tireSize:parseInt(tireId),
              season:0,
              originalEquipment:'ALL',
              isNational:0,
              tokenId:tokenId
          })
      })
        .then(response =>{
            if(!response.ok) throw Error(response.statusText);
            return response.json();
        })
        .then(json => json);

  }
);

const tireSearch = createSlice({
    name:"tireSearch",
    initialState:{
        searchList:[],
        searchProductType: ProductTypes.TIRES,
        vehicleYear: [],
    },
    reducers:{
        searchResults:(state) => ({...state.searchList}),
        searchProductType:(state,payload) => {
            // ProductSelect when search for Tires;

            var appStateCursor = globalState.select('applicationState');
            appStateCursor.set('routerForward', true);
            appStateCursor.set('routerState', RouterStates.YEAR);
            state.searchProductType = payload;
        },

        vehicleYear:(state,payload) => {
            // this function allow to search for vehicle year with modal, and search dropdown list
            let vehicleYearSearch = onVehicleYearsLoaded(payload);
            state.vehicleYear = vehicleYearSearch.vehicleYears;
        }
    },
    extraReducers:{
        [fetchTireSearch.pending]:(state,action) =>{
            state.status = 'loading';
        },
        [fetchTireSearch.fulfilled]:(state,{payload})=>{
            state.searchList = payload;
            state.status = 'sucess'
        },
        [fetchTireSearch.rejected]:(state,action)=>{
            state.status = 'failed'
        }
    }

});


export const {searchResults,searchProductType,vehicleYear} = tireSearch.actions;
export default tireSearch.reducer;
import { applicationStore } from "../../stores/ApplicationStore.js";
import { applicationActions } from "../../actions/ApplicationActions";
import { productListStore } from "../../stores/ProductListStore.js";
import { productListActions } from "../../actions/ProductListActions";
import { languageStore } from "../../stores/LanguageStore.js";

import TopLauncher from "./TopLauncher.jsx";
import { PanelLauncher } from "./PanelLauncher.jsx";
import ProductTile from "../product/ProductTile.jsx";
import ProductTypeSelector from "./ProductTypeSelector.jsx";
import ProductTypeTile from "./ProductTypeTile.jsx";

import { ProductTypeTilePadded } from "./ProductTypeTilePadded.jsx";

import { Image } from "../ux/Image.jsx";
import { SelectBox } from "../ux/SelectBox.jsx";

import { ProductTypes } from "../../constants/ProductTypes";

import {
  renderCarousel,
  renderAlternateProductSelection,
  renderYouTubeVideo,
} from "../../service/RenderService";
import {
  sizeOf,
  isEmpty,
  getAppLanguage,
  getTrackingEventCategory,
  getTrackingEventObject,
  isDealerSite,
  isNationalSite,
  isMobile,
  nationalSiteHasDealerSelected,
} from "../../service/UtilityService";
import { connect } from "react-redux";
import { dealerId, toggleSearch } from "../../redux/features/applicationInfo";
import _ from "lodash";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";
var React = require("react");
var Immutable = require("immutable");
var Cx = require("classnames");

class Homepage extends I18nComponent {
  state = {
    carouselItems: applicationStore.data.carouselItems,
    featuredItems: applicationStore.data.featuredItems,

    carousel: applicationStore.data.carousel,
    carouselIndex: 0,
    lastCarouselIndex: 0,

    allowSwitching: true,
    switchingFunc: null,

    yearlist: [],
    yearselect: null,

    yearselected: false,

    partList: productListStore.data.productList,
    partListShowAll: false,

    initialize: false,
  };

  updateState = (state) => {
    this.setState(state);
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("Homepage");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Homepage Access"
    );
    applicationStore.addChangeListener(this.onChange);
    languageStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    clearInterval(this.state.switchingFunc);
    applicationStore.removeChangeListener(this.onChange);
    languageStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    //allow our redux system to capture the dealerId below
    setTimeout(() => {
      this.props.dispatch(dealerId());
    }, 1000);
    this.runCarouselSwitching();
    var updateState = this.updateState;
    setTimeout(() => {
      updateState({ initialize: true });
    }, 500);
    if (this.startWithSearch()) {
      applicationActions.toggleSearchFlag();
    }
    //if (this.startWithSearch()) { setTimeout(() => { applicationActions.toggleSearchFlag(); }, 250); }
    if (applicationStore.data.enableHomepagePackages) {
      applicationActions.loadLauncherYearSelection(ProductTypes.PACKAGES);
    }
    //if (applicationStore.data.enableHomepagePackages) { setTimeout(() => { applicationActions.loadLauncherYearSelection(ProductTypes.PACKAGES); }, 250); }
  }
  componentDidUpdate(lastProps, lastState) {
    if (
      applicationStore.data.enableHomepagePackages &&
      !this.state.yearselected &&
      sizeOf(this.state.yearList) > 0
    ) {
      var temp = null,
        list = this.state.yearList;
      for (var i in list) {
        if (list[i]) {
          temp = list[i].key;
          break;
        }
      }
      this.setState({
        yearselect: temp,
        yearselected: true,
      });

      productListActions.loadPackagesByYear(temp);
      //setTimeout(() => { productListActions.loadPackagesByYear(temp); }, 100);
    }
  }

  startWithSearch = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mazda":
        return isMobile();
      default:
        return false;
    }
  };
  updateCount = () => {
    if (this.state.allowSwitching) {
      var lastIndex = this.state.carouselIndex,
        index = this.state.carouselIndex + 1,
        decodeIndex = this.decodeIndex;
      this.setState({
        carouselIndex: decodeIndex(index),
        lastCarouselIndex: lastIndex,
      });
    }
  };

  runCarouselSwitching = () => {
    const updateCount = this.updateCount;
    this.setState({
      switchingFunc: setInterval(() => {
        updateCount();
      }, 5000),
    });
  };

  render = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "audi_2021":
        return this.renderHomepageAudi();
      case "hyundai":
        return this.renderHomepageHyundai();
      case "kia_2021":
        return this.renderHomepageKia();
      case "mazda":
        return this.renderHomepageReduced();
      case "volkswagen":
        return this.renderHomepageRefactor();
      case "mercedes_2022":
        return this.renderHomepageMercedes();
      case "volkswagen_2020":
        return this.renderHomepageVolkswagen();
      default:
        return this.renderHomepage();
    }
  };

  renderHomepage = () => {
    var homepageClass = Cx({
      main: true,
      homepage: true,
      "has-launcher":
        applicationStore.data.enableHomepageLauncher &&
        applicationStore.data.homepageLauncherFloatOverCarousel === "TOP",
    });

    return (
      <div className={homepageClass} id="homepage">
        <div className="container">
          {this.renderTopLauncher()}
          {this.renderCarousel()}
          {this.renderEnhancedCarousel()}
          {this.renderBMWCarousel()}
          {this.renderMiniCarousel()}
          {this.enableProductTypeSelector()
            ? this.renderProductTypeSelector()
            : false}
          {this.renderFeatured()}
        </div>
      </div>
    );
  };

  renderHomepageVolkswagen = () => {
    var homepageClass = Cx({
      homepage: true,
      main: true,
      "has-launcher":
        applicationStore.data.enableHomepageLauncher &&
        applicationStore.data.homepageLauncherFloatOverCarousel === "TOP",
    });

    return (
      <box className={homepageClass}>
        <box className="container">
          {this.renderCarousel()}
          <TopLauncher />
          <box className="home-images">
            <box>
              <container className="side large">
                <column className="half">
                  <container>
                    <box>
                      <container>
                        <img src="/static/img/volkswagen_2020/wheel_landing_page.jpg" />
                      </container>
                    </box>
                    <box>
                      <box>
                        <box>
                          <container>
                            <h2>
                              {this.t("onTheRoad")}{" "}
                              <text className="bold">
                                {this.t("withCompleteWheels")}
                                {":"}
                              </text>
                            </h2>
                          </container>
                        </box>
                        <box>
                          <container>
                            <text className="light-bold">
                              {this.t("needNewWheels")}
                            </text>
                          </container>
                        </box>
                      </box>
                    </box>
                    <box>
                      <container>
                        <text onClick={this.onToggleSearch}>
                          <a className="search-text bold">
                            {this.t("searchNow")}
                          </a>
                          <i className="fa fa-arrow-right" />
                        </text>
                      </container>
                    </box>
                  </container>
                </column>
                <column className="half">
                  <container>
                    <box>
                      <container>
                        <img src="/static/img/volkswagen_2020/vw_tires.jpg" />
                      </container>
                    </box>
                    <box>
                      <box>
                        <container>
                          <h2>
                            {this.t("searchText")}{" "}
                            <text className="bold">{this.t("forTires")}</text>
                          </h2>
                        </container>
                      </box>
                      <box>
                        <container>
                          <text className="light-bold">
                            {this.t("youreTiresAre")}
                          </text>
                        </container>
                      </box>
                    </box>
                    <box>
                      <box>
                        <container>
                          <h2>
                            {this.t("tireAndWheel")}{" "}
                            <text className="bold">
                              {this.t("productTypes.packages")}
                            </text>
                          </h2>
                        </container>
                      </box>
                      <box>
                        <container>
                          <text className="light-bold">
                            {this.t("rememberToBuy")}
                          </text>
                        </container>
                      </box>
                    </box>
                  </container>
                </column>
              </container>
            </box>
            <box className="first-images">
              <column className="third">
                <img src="/static/img/volkswagen_2020/woman_cellphone_car.jpg" />
              </column>
              <column className="two-thirds">
                <img src="/static/img/volkswagen_2020/highwat_landingpage.jpg" />
              </column>
            </box>
            <box className="second-images">
              <container className="side xlarge">
                <column className="two-thirds">
                  <img src="/static/img/volkswagen_2020/kneeling_landingpage.jpg" />
                </column>
                <column className="third">
                  <img src="/static/img/volkswagen_2020/steering_wheel_langinpage.jpg" />
                </column>
              </container>
            </box>
          </box>
        </box>
      </box>
    );
  };
  renderHomepageMercedes = () => {
    var homepageClass = Cx({
      main: true,
      homepage: true,
      "has-launcher":
        applicationStore.data.enableHomepageLauncher &&
        applicationStore.data.homepageLauncherFloatOverCarousel === "TOP",
    });

    return (
      <div className={homepageClass} id="homepage">
        <div className="container">
          {this.renderTopLauncher()}
          {this.renderCarousel()}
          {this.renderEnhancedCarousel()}
          {this.renderBMWCarousel()}
          {this.renderMiniCarousel()}
          {this.enableProductTypeSelector()
            ? this.renderProductTypeSelector()
            : false}
          {this.renderFeatured()}
        </div>
      </div>
    );
  };
  renderHomepageAudi = () => {
    var homepageClass = Cx({
      homepage: true,
      main: true,
      "has-launcher":
        applicationStore.data.enableHomepageLauncher &&
        applicationStore.data.homepageLauncherFloatOverCarousel === "TOP",
    });

    return (
      <box className={homepageClass}>
        <box className="container">
          {this.renderCarousel()}
          <TopLauncher />
          <box className="home-images">
            <container className="large">
              <box style={{ marginTop: "60px", marginBottom: "40px" }}>
                <h1
                  style={{
                    fontSize: "35px",
                    lineHeight: "40px",
                    marginBottom: "10px",
                  }}
                >
                  {this.t("homepage_title_1")}
                </h1>
                <h1 style={{ fontSize: "30px" }}>
                  {this.t("homepage_title_2")}
                </h1>
              </box>
              <box style={{ marginBottom: "20px" }}>
                <span>{this.t("homepage_subtitle")}</span>
              </box>
              <box>
                <column
                  className="third pointer"
                  onClick={this.clickAlloyWheels}
                >
                  <box>
                    <img src="/static/img/audi_2021/wheel_image.png" />
                  </box>
                  <box style={{ marginBottom: "20px" }}>
                    <h2>{this.t("homepage_wheel_title")}</h2>
                  </box>
                  <box
                    style={{
                      margin: "0 auto 20px auto",
                      minHeight: "150px",
                      maxWidth: "80%",
                    }}
                  >
                    <span>{this.t("homepage_wheel_subtitle")}</span>
                  </box>
                  <box style={{ marginBottom: "20px" }}>
                    <button style={{ background: "none", border: "none" }}>
                      <img
                        style={{ width: "30px", margin: "0 auto 10px auto" }}
                        src="/static/img/audi_2021/search_icon.png"
                      />
                      {this.t("homepage_wheel_button")}
                    </button>
                  </box>
                </column>
                <column className="third pointer" onClick={this.clickTires}>
                  <box>
                    <img src="/static/img/audi_2021/tire_image.png" />
                  </box>
                  <box style={{ marginBottom: "20px" }}>
                    <h2>{this.t("homepage_tire_title")}</h2>
                  </box>
                  <box
                    style={{
                      margin: "0 auto 20px auto",
                      minHeight: "150px",
                      maxWidth: "80%",
                    }}
                  >
                    <span>{this.t("homepage_tire_subtitle")}</span>
                  </box>
                  <box style={{ marginBottom: "20px" }}>
                    <button style={{ background: "none", border: "none" }}>
                      <img
                        style={{ width: "30px", margin: "0 auto 10px auto" }}
                        src="/static/img/audi_2021/search_icon.png"
                      />
                      {this.t("homepage_tire_button")}
                    </button>
                  </box>
                </column>
                <column className="third pointer" onClick={this.clickPackages}>
                  <box>
                    <img src="/static/img/audi_2021/package_image.png" />
                  </box>
                  <box style={{ marginBottom: "20px" }}>
                    <h2>{this.t("homepage_package_title")}</h2>
                  </box>
                  <box
                    style={{
                      margin: "0 auto 20px auto",
                      minHeight: "150px",
                      maxWidth: "80%",
                    }}
                  >
                    <span>{this.t("homepage_package_subtitle")}</span>
                  </box>
                  <box style={{ marginBottom: "20px" }}>
                    <button style={{ background: "none", border: "none" }}>
                      <img
                        style={{ width: "30px", margin: "0 auto 10px auto" }}
                        src="/static/img/audi_2021/search_icon.png"
                      />
                      {this.t("homepage_package_button")}
                    </button>
                  </box>
                </column>
              </box>
            </container>
          </box>
        </box>
      </box>
    );
  };
  renderHomepageHyundai = () => {
    var homepageClass = Cx({
      main: true,
      homepage: true,
      "has-launcher":
        applicationStore.data.enableHomepageLauncher &&
        applicationStore.data.homepageLauncherFloatOverCarousel === "TOP",
    });

    return (
      <box className={homepageClass}>
        <box className="container">
          {this.renderCarousel()}
          <box>
            <TopLauncher bottomlauncher={true} />
          </box>
          <box className="textbox">
            <box>
              <container>
                <h2>{this.t("packagestitle")}</h2>
              </container>
            </box>
            <box>
              <container>
                <box>
                  <container className="side xlarge">
                    <text>{this.t("packagessubtitle")}</text>
                  </container>
                </box>
              </container>
            </box>
          </box>
        </box>
      </box>
    );
  };
  renderHomepageKia = () => {
    var homepageClass = Cx({
      homepage: true,
      main: true,
      "has-launcher":
        applicationStore.data.enableHomepageLauncher &&
        applicationStore.data.homepageLauncherFloatOverCarousel === "TOP",
    });

    return (
      <box className={homepageClass}>
        <box className="container">
          {this.renderCarousel()}
          <TopLauncher />
          <box className="home-images">
            <box>
              <column className="half">
                {_.includes(
                  applicationStore.data.productTypes,
                  ProductTypes.TIRES
                ) ? (
                  <box
                    className="tire-background"
                    style={{ cursor: "pointer" }}
                    onClick={this.clickTires}
                  >
                    <box>
                      <h3
                        className="tire"
                        style={{
                          paddingBottom: "0",
                          paddingLeft: "50px",
                          textAlign: "left",
                        }}
                      >
                        {this.t("findThePerfectTires")}
                      </h3>
                    </box>
                    <box>
                      <h3
                        style={{
                          paddingTop: "15px",
                          paddingLeft: "50px",
                          textAlign: "left",
                          color: "#fff",
                          fontSize: "25px",
                        }}
                      >
                        {" "}
                        {this.t("chooseTires")}
                      </h3>
                    </box>
                  </box>
                ) : (
                  false
                )}
              </column>
              <column className="half">
                <column
                  className="half"
                  style={{ boxShadow: "1px 0 0 #cdcdcd" }}
                >
                  {_.includes(
                    applicationStore.data.productTypes,
                    ProductTypes.ALLOY_WHEELS
                  ) ? (
                    <box
                      style={{ cursor: "pointer" }}
                      onClick={this.clickAlloyWheels}
                    >
                      <img src="/static/img/kia_2021/wheel_icon.png" />
                      <h3>{this.t("common.shopWheels")}</h3>
                    </box>
                  ) : (
                    false
                  )}
                </column>
                <column className="half">
                  {_.includes(
                    applicationStore.data.productTypes,
                    ProductTypes.ACCESSORIES
                  ) ? (
                    <box
                      style={{ cursor: "pointer" }}
                      onClick={this.clickAccessories}
                    >
                      <img
                        className="accessory"
                        src="/static/img/kia_2021/accessory_icon.png"
                      />
                      <h3>{this.t("common.shopAccessories")}</h3>
                    </box>
                  ) : (
                    false
                  )}
                </column>
              </column>
            </box>
          </box>
        </box>
      </box>
    );
  };

  clickTires = () => {
    if (!applicationStore.data.loadSearchModalFromProductTypeSelection) {
      googleAnalyticsEnhancedTrackEvent(
        getTrackingEventCategory(),
        "Product Tile Selection - ProductSelected",
        getTrackingEventObject({ type: ProductTypes.TIRES })
      );
      applicationActions.productTypeSelected(ProductTypes.TIRES);
    } else {
      this.props.dispatch(toggleSearch(true));
      applicationActions.activateSearchFlag();
    }
  };

  clickAlloyWheels = () => {
    if (!applicationStore.data.loadSearchModalFromProductTypeSelection) {
      googleAnalyticsEnhancedTrackEvent(
        getTrackingEventCategory(),
        "Product Tile Selection - ProductSelected",
        getTrackingEventObject({ type: ProductTypes.ALLOY_WHEELS })
      );
      applicationActions.productTypeSelected(ProductTypes.ALLOY_WHEELS);
    } else {
      this.props.dispatch(toggleSearch(true));
      applicationActions.activateSearchFlag();
    }
  };

  clickAccessories = () => {
    if (!applicationStore.data.loadSearchModalFromProductTypeSelection) {
      googleAnalyticsEnhancedTrackEvent(
        getTrackingEventCategory(),
        "Product Tile Selection - ProductSelected",
        getTrackingEventObject({ type: ProductTypes.ACCESSORIES })
      );
      applicationActions.productTypeSelected(ProductTypes.ACCESSORIES);
    } else {
      applicationActions.activateSearchFlag();
    }
  };

  renderHomepageReduced = () => {
    var imageclass = Cx({
      unblur: this.state.initialize,
    });

    var floatclass = Cx({
      floattext: true,
      show: this.state.initialize,
    });

    return (
      <div className="main homepage" id="homepage">
        <div className="container">
          <box style={{ position: "relative" }}>
            <box className="image">
              <img className={imageclass} src="/static/img/mazda/main.png" />
            </box>

            <box className={floatclass}>
              <box style={{ position: "relative" }}>
                <box>
                  <h1>{this.t("reducedHomepageTitle").toUpperCase()}</h1>
                </box>
                <box className="textbox">
                  <text>{this.t("reducedHomepageText")}</text>
                </box>
                <box>
                  <button className="btn" onClick={this.onToggleSearch}>
                    {this.t("reducedHomepageButton").toUpperCase()}
                  </button>
                </box>
              </box>
            </box>
          </box>

          {this.renderSecondaryGroupManufacturerList()}
        </div>
      </div>
    );
  };

  renderSecondaryGroupManufacturerList = () => {
    var getSecondaryGroupImage = this.getSecondaryGroupImage,
      list =
        !isNationalSite() || nationalSiteHasDealerSelected()
          ? this.getTireBrandList()
          : this.state.tireBrandList;
    return (
      <box className="brandlistsecondary">
        <box>
          <div style={{ background: "#000" }}>
            <img
              style={{ margin: "0 auto" }}
              src="/static/img/mazda/mazda_tire_centre.png"
            />
          </div>
        </box>
        <box className="background">
          <container className="xxlarge">
            <ul style={{ textAlign: "center" }}>
              {!isEmpty(list)
                ? list.map(function (brand, index) {
                    return getSecondaryGroupImage(brand, index);
                  })
                : false}
            </ul>
          </container>
        </box>
      </box>
    );
  };

  getTireBrandList = () => {
    return [
      "BFGoodrich",
      "Bridgestone",
      "Continental",
      "Dunlop",
      "Firestone",
      "General",
      "Goodyear",
      "Hankook",
      "Michelin",
      "Pirelli",
      "Uniroyal",
      "Yokohama",
      "Toyo",
      "Kelly",
      "Gislaved",
    ];
  };

  getSecondaryGroupImage = (brand, index) => {
    return (
      <li key={"tire-brand-image-" + index}>
        <img
          className="brand-image"
          src={this.getSecondaryGroupImageLink(brand)}
          alt=""
        />
      </li>
    );
  };

  getSecondaryGroupImageLink = (brand) => {
    if (typeof brand == "object") brand = brand.brandName;
    switch (brand) {
      case "BFGoodrich":
        return "/static/img/mazda/brands/bfgoodrich.png";
      case "Bridgestone":
        return "/static/img/mazda/brands/bridgestone.png";
      case "Continental":
        return "/static/img/mazda/brands/continental.png";
      case "Dunlop":
        return "/static/img/mazda/brands/dunlop.png";
      case "Firestone":
        return "/static/img/mazda/brands/firestone.png";
      case "General":
        return "/static/img/mazda/brands/general.png";
      case "Goodyear":
        return "/static/img/mazda/brands/goodyear.png";
      case "Hankook":
        return "/static/img/mazda/brands/hankook.png";
      case "Michelin":
        return "/static/img/mazda/brands/michelin.png";
      case "Pirelli":
        return "/static/img/mazda/brands/pirelli.png";
      case "Uniroyal":
        return "/static/img/mazda/brands/uniroyal.png";
      case "Yokohama":
        return "/static/img/mazda/brands/yokohama.png";
      case "Toyo":
        return getAppLanguage() == "en"
          ? "/static/img/mazda/brands/toyo.png"
          : "/static/img/mazda/brands/toyo_fr.png";
      case "Kelly":
        return "/static/img/mazda/brands/kelly.png";
      case "Gislaved":
        return "/static/img/mazda/brands/gislaved.png";
      default:
        return brand.imageUrl;
    }
  };

  onToggleSearch = () => {
    applicationActions.toggleSearchFlag();
  };

  onSelectYear = (year) => {
    this.setState({
      yearselect: year,
    });

    setTimeout(() => {
      productListActions.loadPackagesByYear(year);
    }, 100);
  };

  togglePartListShowAll = () => {
    this.setState({
      partListShowAll: !this.state.partListShowAll,
    });
  };

  renderEnhancedCarousel = () => {
    if (this.enableEnhancedCarousel()) {
      return (
        <box className="enhanced-carousel">
          {applicationStore.data.enableHomepagePackages ? (
            <box>
              <container className="top">
                <box>
                  <h1>{this.t("homepage_winterpackages")}</h1>
                </box>
                <column className="right fixmargin">
                  <SelectBox
                    selected={this.state.yearselect}
                    dataList={this.state.yearList}
                    onSelect={this.onSelectYear}
                    rightAlign={true}
                  />
                </column>
              </container>
            </box>
          ) : (
            false
          )}
          {applicationStore.data.enableHomepagePackages ? (
            <box>
              <container className="large">
                <box style={{ marginTop: -25 }}>
                  {sizeOf(this.state.partList) > 0
                    ? Immutable.fromJS(this.state.partList)
                        .take(
                          this.state.partListShowAll
                            ? sizeOf(this.state.partList)
                            : 3
                        )
                        .map(function (part) {
                          return <ProductTile part={part} pvisualizer={true} />;
                        })
                    : false}
                </box>
                <box className="icon" onClick={this.togglePartListShowAll}>
                  <container classNae="small">
                    <column>
                      <text>
                        {this.state.partListShowAll
                          ? this.t("viewLess")
                          : this.t("viewMore")}
                      </text>
                    </column>
                    <column>
                      <i
                        className={
                          this.state.partListShowAll
                            ? "fa fa-angle-up"
                            : "fa fa-angle-down"
                        }
                      />
                    </column>
                  </container>
                </box>
              </container>
            </box>
          ) : (
            false
          )}

          {/*<box>*/}
          {/*  <container className="top">*/}
          {/*    <h1>{this.t('homepage_title')}</h1>*/}
          {/*  </container>*/}
          {/*</box>*/}
          {/*<box>*/}
          {/*  <container className="top">*/}
          {/*    {this.renderEnhancedVideo()}*/}
          {/*  </container>*/}
          {/*</box>*/}
          <box>
            <container className="top">
              <h1>{this.t("product.approvedTires")}</h1>
            </container>
          </box>
          <box>
            <container>
              <column className="half">
                <container className="half large">
                  <img
                    src={this.renderMercedesImage(
                      "https://www.mercedes-benz.ca/content/dam/mb-nafta/ca/owners/genuineparts/wheels-tires/Keyvisual_Reifen_V09_01_cmyk_690x450.jpg",
                      "homepage1.jpg",
                      true,
                      applicationStore.data.defaultTemplate
                    )}
                  />
                </container>
              </column>
              <column className="half right">
                <box>
                  <container className="top">
                    <h2 className="bold">
                      {this.t("homepage_your_first_choice", true)}
                    </h2>
                  </container>
                </box>
                <box>
                  <text>{this.t("homepage_long_4")}</text>
                </box>
              </column>
            </container>
          </box>
          <box>{this.renderEnhancedVideo()}</box>
          <box>
            <container className="top">
              <h1 style={{ textTransform: "capitalize" }}>
                {this.t("homepage_genuinewheels")}
              </h1>
            </container>
          </box>
          <box>
            <container className="top">
              <column className="third">
                <container>
                  <box>
                    <container className="top">
                      <img
                        src={this.renderMercedesImage(
                          "homepage2.png",
                          "homepage2.jpg",
                          false,
                          applicationStore.data.defaultTemplate
                        )}
                      />
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <h2 className="bold">
                        {this.t("homepage_wheeldevelopment")}
                      </h2>
                    </container>
                  </box>
                  <box>
                    <text>{this.t("homepage_long_1")}</text>
                  </box>
                </container>
              </column>
              <column className="third">
                <container>
                  <box>
                    <container className="top">
                      <img
                        src={this.renderMercedesImage(
                          "homepage3.png",
                          "homepage3.jpg",
                          false,
                          applicationStore.data.defaultTemplate
                        )}
                      />
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <h2 className="bold">{this.t("homepage_design")}</h2>
                    </container>
                  </box>
                  <box>
                    <text>{this.t("homepage_long_2")}</text>
                  </box>
                </container>
              </column>
              <column className="third">
                <container>
                  <box>
                    <container className="top">
                      <img
                        src={this.renderMercedesImage(
                          "homepage4.png",
                          "homepage4.jpeg",
                          false,
                          applicationStore.data.defaultTemplate
                        )}
                      />
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <h2 className="bold">{this.t("homepage_quality")}</h2>
                    </container>
                  </box>
                  {this.renderListOrOrganizer()}
                </container>
              </column>
            </container>
          </box>
          {this.renderAdditionalMercedesContent()}
        </box>
      );
    } else {
      return false;
    }
  };
  renderMercedesImage = (
    url,
    url2,
    isExternalUrl = false,
    typeOfMercedes = "mercedes"
  ) => {
    if (typeOfMercedes === "mercedes") {
      if (isExternalUrl) {
        return url;
      }
      return `/static/img/${typeOfMercedes}/${url}`;
    } else {
      return `/static/img/${typeOfMercedes}/${url2}`;
    }
  };
  renderListOrOrganizer = () => {
    if (applicationStore.data.defaultTemplate === "mercedes_2022") {
      return (
        <box>
          <container className="top">
            <text>{this.t("homepage_long_4")}</text>
          </container>
        </box>
      );
    } else {
      return (
        <box>
          <box>
            <column style={{ width: "30px" }}>
              <text>{"1."}</text>
            </column>
            <column style={{ width: "calc(100% - 30px)" }}>
              <text>{this.t("homepage_long_3_a")}</text>
            </column>
          </box>
          <box>
            <column style={{ width: "30px" }}>
              <text>{"2."}</text>
            </column>
            <column style={{ width: "calc(100% - 30px)" }}>
              <text>{this.t("homepage_long_3_b")}</text>
            </column>
          </box>
          <box>
            <column style={{ width: "30px" }}>
              <text>{"3."}</text>
            </column>
            <column style={{ width: "calc(100% - 30px)" }}>
              <text>{this.t("homepage_long_3_c")}</text>
            </column>
          </box>
          <box>
            <column style={{ width: "30px" }}>
              <text>{"4."}</text>
            </column>
            <column style={{ width: "calc(100% - 30px)" }}>
              <text>{this.t("homepage_long_3_d")}</text>
            </column>
          </box>
          <box>
            <column style={{ width: "30px" }}>
              <text>{"5."}</text>
            </column>
            <column style={{ width: "calc(100% - 30px)" }}>
              <text>{this.t("homepage_long_3_e")}</text>
            </column>
          </box>
        </box>
      );
    }
  };

  renderAdditionalMercedesContent = () => {
    if (applicationStore.data.defaultTemplate === "mercedes_2022") {
      return (
        <box>
          <box>
            <container className="top">
              <h1>{this.t("homepage_wrapup")}</h1>
            </container>
          </box>

          <box>
            <container>
              <column className="half">
                <container className="half large">
                  <img src="/static/img/mercedes_2022/homepage5.jpg" />
                </container>
              </column>
              <column className="half right">
                <box>
                  <container className="top">
                    <h2 className="bold">{this.t("time_to_change")}</h2>
                  </container>
                </box>
                <box>
                  <text>{this.t("homepage_long_5")}</text>
                </box>
              </column>
            </container>
          </box>
          <box>
            <container className="top">
              <h1>{this.t("homepage_why_mercedes")}</h1>
            </container>

            <container className="full-width right">
              <img
                src="/static/img/mercedes_2022/homepage6.jpg"
                style={{ width: "100%", margin: "0 auto" }}
              />
            </container>
            <container>
              <column className="half">
                <box>
                  <column style={{ width: "30px" }}>
                    <text>{"1."}</text>
                  </column>
                  <column style={{ width: "calc(100% - 30px)" }}>
                    <text>{this.t("homepage_long_3_a")}</text>
                  </column>
                </box>
                <box>
                  <column style={{ width: "30px" }}>
                    <text>{"2."}</text>
                  </column>
                  <column style={{ width: "calc(100% - 30px)" }}>
                    <text>{this.t("homepage_long_3_b")}</text>
                  </column>
                </box>
                <box>
                  <column style={{ width: "30px" }}>
                    <text>{"3."}</text>
                  </column>
                  <column style={{ width: "calc(100% - 30px)" }}>
                    <text>{this.t("homepage_long_3_c")}</text>
                  </column>
                </box>
              </column>
              <column className="half right">
                <box>
                  <column style={{ width: "30px" }}>
                    <text>{"4."}</text>
                  </column>
                  <column style={{ width: "calc(100% - 30px)" }}>
                    <text>{this.t("homepage_long_3_d")}</text>
                  </column>
                </box>
                <box>
                  <column style={{ width: "30px" }}>
                    <text>{"5."}</text>
                  </column>
                  <column style={{ width: "calc(100% - 30px)" }}>
                    <text>{this.t("homepage_long_3_e")}</text>
                  </column>
                </box>
              </column>
            </container>
          </box>
        </box>
      );
    } else {
      return false;
    }
  };
  renderBMWCarousel = () => {
    if (this.enableBMWCarousel()) {
      return (
        <box>
          <box>
            <column className="half">
              <box>
                <container className="xxlarge">
                  <box>
                    <container className="top">
                      <h1>{this.t("homepage_tire_title")}</h1>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <h3>{this.t("homepage_tire_subtitle")}</h3>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <text>{this.t("homepage_tire_text")}</text>
                    </container>
                  </box>
                </container>
              </box>
            </column>
            <column className="half">
              <box>
                <img
                  style={{ width: "100%" }}
                  src="/static/img/bmw/tires.png"
                />
              </box>
            </column>
          </box>

          <box>
            <column className="half">
              <box>
                <img
                  style={{ width: "100%" }}
                  src="/static/img/bmw/wheels.png"
                />
              </box>
            </column>
            <column className="half">
              <box>
                <container className="xxlarge">
                  <box>
                    <container className="top">
                      <h1>{this.t("homepage_wheel_title")}</h1>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <h3>{this.t("homepage_wheel_subtitle")}</h3>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <text>{this.t("homepage_wheel_text")}</text>
                    </container>
                  </box>
                </container>
              </box>
            </column>
          </box>
        </box>
      );
    } else {
      return false;
    }
  };

  renderMiniCarousel = () => {
    if (this.enableMiniCarousel()) {
      return (
        <box>
          <box>
            <column className="half">
              <box>
                <img
                  src="/static/img/mini/tires.jpg"
                  style={{ width: "90%" }}
                />
              </box>
            </column>
            <column className="half">
              <box>
                <container className="xxlarge">
                  <box>
                    <container className="top">
                      <h1 style={{ fontSize: "34px" }}>
                        {this.t("homepage_tire_title")}
                      </h1>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <text>{this.t("homepage_tire_text")}</text>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <button className="btn" onClick={this.onToggleSearch}>
                        {this.t("shop_tires")}
                      </button>
                    </container>
                  </box>
                </container>
              </box>
            </column>
          </box>

          <box>
            <column className="half">
              <box>
                <container className="xxlarge">
                  <box>
                    <container className="top">
                      <h1 style={{ fontSize: "34px" }}>
                        {this.t("homepage_wheel_title")}
                      </h1>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <text>{this.t("homepage_wheel_text")}</text>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <button className="btn" onClick={this.onToggleSearch}>
                        {this.t("shop_wheels")}
                      </button>
                    </container>
                  </box>
                </container>
              </box>
            </column>
            <column className="half">
              <box>
                <img
                  src="/static/img/mini/wheels.jpg"
                  style={{ width: "90%", float: "right" }}
                />
              </box>
            </column>
          </box>

          <box>
            <column className="half">
              <box>
                <img
                  src="/static/img/mini/packages.jpg"
                  style={{ width: "90%" }}
                />
              </box>
            </column>
            <column className="half">
              <box>
                <container className="xxlarge">
                  <box>
                    <container className="top">
                      <h1 style={{ fontSize: "34px" }}>
                        {this.t("homepage_package_title")}
                      </h1>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <text>{this.t("homepage_package_text")}</text>
                    </container>
                  </box>
                  <box>
                    <container className="top">
                      <button className="btn" onClick={this.onToggleSearch}>
                        {this.t("shop_packages")}
                      </button>
                    </container>
                  </box>
                </container>
              </box>
            </column>
          </box>
        </box>
      );
    } else {
      return false;
    }
  };

  renderEnhancedVideo = () => {
    var video = this.getVideoCarousel();
    if (sizeOf(video) > 0) {
      var link = video[0]["videoYouTubeUrl"][getAppLanguage()],
        style = {
          width: "100%",
          height: video[0]["videoYouTubeHeight"] + "px",
          left: "0",
        };
      return renderYouTubeVideo(link, "", "", style);
    } else {
      return false;
    }
  };

  getVideoCarousel = () => {
    return this.getCarousels().filter(function (carousel) {
      if (carousel.resourceType === "youtube") {
        return true;
      } else {
        return false;
      }
    });
  };

  getCarousels = () => {
    var temp = [];
    if (isDealerSite()) {
      if (
        window.dealerAccountInfoPayload &&
        sizeOf(window.dealerAccountInfoPayload.dealerCarousels) > 0
      ) {
        if (
          sizeOf(
            window.dealerAccountInfoPayload.dealerCarousels.home_secondary
          ) > 0
        ) {
          return window.dealerAccountInfoPayload.dealerCarousels.home_secondary;
        }
      }
    } else if (isNationalSite()) {
      if (
        window.nationalAccountInfoPayload &&
        sizeOf(window.nationalAccountInfoPayload.nationalCarousels) > 0
      ) {
        if (
          sizeOf(
            window.nationalAccountInfoPayload.nationalCarousels.home_secondary
          ) > 0
        ) {
          return window.nationalAccountInfoPayload.nationalCarousels
            .home_secondary;
        }
      }
    }
    return temp;
  };

  enableEnhancedCarousel = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mercedes":
        return true;
      case "mercedes_2022":
        return true;
      default:
        return false;
    }
  };

  enableBMWCarousel = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "bmw":
        return true;
      default:
        return false;
    }
  };

  enableMiniCarousel = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mini":
        return true;
      default:
        return false;
    }
  };

  enableProductTypeSelector = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "bmw":
      case "mercedes":
      case "mercedes_2022":
      case "mini":
        return false;
      default:
        return true;
    }
  };

  renderProductTypeSelector = () => {
    if (applicationStore.data.enablePaddedProductTileSelection) {
      return <ProductTypeTilePadded />;
    } else if (applicationStore.data.enableAlternateProductTileSelection) {
      return <ProductTypeTile />;
    } else if (applicationStore.data.useAlternateProductTypeSelection) {
      switch (applicationStore.data.defaultTemplate) {
        case "mercedes":
        case "mercedes_2022":
        case "mercedestransition":
          return renderAlternateProductSelection(this.state.featuredItems);
        default:
          return <ProductTypeSelector />;
      }
    } else {
      return <ProductTypeSelector />;
    }
  };

  renderTopLauncher = () => {
    if (applicationStore.data.enableHomepageLauncher) {
      return <TopLauncher />;
    } else {
      return false;
    }
  };

  renderFeatured = () => {
    var useCarouselSecondaryFunction =
      (applicationStore.data.useAlternateProductTypeSelection &&
        !applicationStore.data.enableMercedesFeatures) ||
      (!applicationStore.data.enablePaddedProductTileSelection &&
        !applicationStore.data.enableAlternateProductTileSelection);
    var hasImage = false,
      items = this.state.featuredItems,
      item = null;
    if (items && sizeOf(items) > 0) {
      item = items.get(0);
      if (item && sizeOf(item.get("image")) > 0) {
        if (item.get("image").get(getAppLanguage()) !== "") {
          hasImage = true;
        }
      }
    }
    if (
      this.enableFeatured() &&
      this.state.carousel &&
      useCarouselSecondaryFunction &&
      hasImage
    ) {
      return (
        <div className="main-featured">
          <div className="featured-heading"></div>
          {items
            .map(
              function (featuredItem, index) {
                return (
                  <div
                    key={"homepage-featured-" + index}
                    className="featured-item"
                  >
                    <a
                      href={this.td(featuredItem.get("linkUrl").toJS())}
                      target={featuredItem.get("linkTarget")}
                    >
                      <Image
                        src={this.td(featuredItem.get("image").toJS())}
                        alt="Image Carousel"
                        key={index}
                      />
                    </a>
                    <h3>{this.td(featuredItem.get("title").toJS())}</h3>
                    <h4>{this.td(featuredItem.get("subtitle").toJS())}</h4>
                    <a
                      href={this.td(featuredItem.get("linkUrl").toJS())}
                      target={featuredItem.get("linkTarget")}
                    >
                      {this.td(featuredItem.get("linkText").toJS())}
                    </a>
                  </div>
                );
              }.bind(this)
            )
            .toArray()}
        </div>
      );
    } else {
      return false;
    }
  };

  enableFeatured = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "bmw":
      case "mercedes":
      case "mercedes_2022":
      case "mercedestransition":
        return false;
      default:
        return true;
    }
  };

  renderHomepageRefactor = () => {
    var homepageClass = Cx({
      main: true,
      "homepage-refactor": true,
      "has-launcher":
        applicationStore.data.enableHomepageLauncher &&
        !applicationStore.data.enableHomepagePanelLauncher,
      "has-panel-launcher": applicationStore.data.enableHomepagePanelLauncher,
    });
    var carouselClass = Cx({
      "carousel-container": true,
      "has-launcher":
        applicationStore.data.enableHomepageLauncher &&
        !applicationStore.data.enableHomepagePanelLauncher,
      "has-panel-launcher": applicationStore.data.enableHomepagePanelLauncher,
    });
    return (
      <box className={homepageClass} id="homepage">
        <box className="container">
          <box className="carousel-section">
            <div className="homepage-text">{this.renderHomepageText()}</div>
            <div className="launcher-container"></div>
            <div className="launcher-panel-container">
              {this.renderPanelLauncher()}
            </div>
            <div className={carouselClass}>{this.renderCarousel()}</div>
          </box>
        </box>
      </box>
    );
  };

  renderHomepageText = () => {
    if (
      applicationStore.data.enableHomepagePanelLauncher &&
      this.t("homepageTitleText") !== ""
    ) {
      return (
        <div className="text-container">
          <div className="text-title">
            <h1>{this.t("homepageTitleText")}</h1>
          </div>
          <div className="text-body">
            <p dangerouslySetInnerHTML={{ __html: this.t("homepageText") }}></p>
          </div>
        </div>
      );
    } else {
      return false;
    }
  };

  renderCarousel = () => {
    if (this.state.carousel && sizeOf(this.state.carouselItems) > 0) {
      return renderCarousel(
        this.state.carouselItems,
        false,
        this.state.carouselIndex,
        this.state.lastCarouselIndex,
        this.setCarouselIndex
      );
    } else {
      return false;
    }
  };

  renderPanelLauncher = () => {
    if (applicationStore.data.enableHomepagePanelLauncher) {
      return <PanelLauncher />;
    } else {
      return false;
    }
  };

  setCarouselIndex = (index) => {
    var lastIndex = this.state.carouselIndex,
      decodeIndex = this.decodeIndex;
    if (index !== this.state.carouselIndex) {
      this.setState({
        carouselIndex: decodeIndex(index),
        lastCarouselIndex: lastIndex,
        allowSwitching: false,
      });
    } else if (this.state.allowSwitching) {
      this.setState({
        allowSwitching: false,
      });
    }
  };

  decodeIndex = (index) => {
    if (index < 0) index = sizeOf(this.state.carouselItems) - 1;
    else if (index > sizeOf(this.state.carouselItems) - 1) index = 0;
    return index;
  };

  onChange = () => {
    this.setState({
      carouselItems: applicationStore.data.carouselItems,
      featuredItems: applicationStore.data.featuredItems,
      carousel: applicationStore.data.carousel,

      yearList: applicationStore.data.launcherVehicleYears,
      partList: productListStore.data.productList,
    });
  };
}

export default connect()(Homepage);

import { globalState } from '../GlobalState.js';

import { languageStore } from '../stores/LanguageStore.js';
import { applicationStore } from '../stores/ApplicationStore.js';
import { shoppingCartStore } from '../stores/ShoppingCartStore.js';

import { applicationActions } from "../actions/ApplicationActions";

import { ProductTypeSelectorRefactored } from '../components/homepage/ProductTypeSelectorRefactored.jsx';

import { Image } from '../components/ux/Image.jsx';

import { CarouselPlayerTypes } from '../constants/CarouselPlayerTypes.js';
import { RouterStates } from '../constants/RouterStates.js';
import { ProductTypes } from '../constants/ProductTypes.js';
import { ComboBox } from '../components/ux/forms/ComboBox.jsx';
import { ComboBoxValue } from '../components/ux/forms/ComboBoxValue.jsx';

import { SearchTypes } from "../constants/SearchTypes";

import { TEST_LIST, getTestId } from './TestingService';
import { decodeDayAbbrev, decodeStoreHours } from './CalendarService.js';
import { isAdministratorAuthentication, isUserLoggedIn } from "../api/AuthService";
import { getPricingFromQuantityAndDetailsRefactored } from './ProductList.js';
import { sizeOf, money_format, getAppLanguage, isNationalSite, isEmpty, getTabIndex } from './UtilityService.js';
import { ADOBELAUNCHER_CLASS, ADOBELAUNCHER_DTM, setAdobeLaunchClass, setAdobeLaunchDTM } from "./AdobeLaunchService";
import { checkSiteHasCampaign, getAppDealerId, getDealerCampaignBaseUrl, getNationalCampaignBaseUrl, isDealerSite } from "./UtilityService";
import {
  getDealerContactEmail,
  getDealerContactHours,
  getDealerContactPhone,
  getDealerContactWebsite,
  getFormattedStandardTime,
  isTablet,
  nationalSiteHasDealerSelected,
} from "./UtilityService";

var React = require('react');
var Immutable = require('immutable');
var _ = require('lodash');
var Cx = require('classnames');

export const renderRequiredItems = (isShoppingCartDetail, relatedParts, onAddRequiredItemToCart, onClickRemoveRequiredItemFromCart, onChangeRequiredItemQuantity, onAddAllItemsToCart, isPackage) => {
  if (relatedParts !== null && typeof relatedParts !== 'undefined' && sizeOf(relatedParts) > 0) {
    var dictionary = languageStore.getDictionary();
    return (
      <div className={isPackage !== '1' ? "required-item-container" : "required-item-container has-accessory-packages"}>
        {isPackage !== '1' ? <div className="required-item-title"><h3>{dictionary.product.requiredItems} - <span className="add-all" onClick={onAddAllItemsToCart}>{dictionary.product.addAllToWishlist}</span></h3></div> : false}
        <ul>

          {relatedParts.map((requiredItem, index) => {
            var inCart = false;
            shoppingCartStore.data.get('items').map(function(cartItem) {
              if (cartItem.get('id') === requiredItem.get('id')) {
                inCart = true;
              }
            });

            return (

              <li className={inCart ? "in-cart" : ""}>
                <div className="required-item">
                  <div className="item-image">
                    <Image src={requiredItem.get('image')} alt="Image Required Item"/>
                  </div>
                  <div className="item-info">
                    <ul>
                      {!isEmpty(requiredItem.get('relatedPartText').get(getAppLanguage())) ? <li>{requiredItem.get('relatedPartText').get(getAppLanguage())}</li> : false}
                      {!isEmpty(requiredItem.get('name').get(getAppLanguage())) ? <li>{requiredItem.get('name').get(getAppLanguage())}</li> : false}
                      {!isEmpty(requiredItem.get('partNumber')) ? <li>{dictionary.product.partNumber+": "+requiredItem.get('partNumber')}</li> : false}
                    </ul>
                  </div>
                  <div className="item-pricing">
                    {renderAddToCartRequiredItem(onAddRequiredItemToCart, onClickRemoveRequiredItemFromCart, onChangeRequiredItemQuantity, requiredItem, isShoppingCartDetail, index)}
                  </div>
                </div>
              </li>

            );
          })}

        </ul>
      </div>
    )
  } else {
    return false;
  }
};

export const renderAddToCartRequiredItem = (onAddRequiredItemToCart, onClickRemoveRequiredItemFromCart, onChangeRequiredItemQuantity, requiredItem, isShoppingCartDetail, index) => {
  var dictionary = languageStore.getDictionary();
  var msrp = applicationStore.data.dealerShowMsrp ? parseFloat(requiredItem.get('price')['listPrice']) : 0;
  var dealerPrice = parseFloat(requiredItem.get('price')['privatePrice']);
  var installation = requiredItem.get('type') == ProductTypes.ACCESSORIES ? parseFloat(requiredItem.get('price')['displayLabour']) : 0;

  var priceLabel = dictionary.product.retailPrice;
  if (applicationStore.data.dealerSite && applicationStore.data.dealerSellingPriceLabel[requiredItem.get('type')][getAppLanguage()]) {
    priceLabel = applicationStore.data.dealerSellingPriceLabel[requiredItem.get('type')][getAppLanguage()];
  }

  var shoppingCartRemoveItemText = <span>{dictionary.product.removeFromWishlist}</span>;
  if (getAppLanguage() === 'fr') shoppingCartRemoveItemText = <span><span className="article-button-text">{dictionary.product.removeFromWishlistPrefix}</span><span className="article-button-text">{dictionary.product.removeFromWishlistSuffix}</span></span>

  var quantity = !isEmpty(requiredItem.get('quantity')) ? parseInt(requiredItem.get('quantity')['value']) : 0;
  var savings = (msrp - dealerPrice) * quantity;
  var total = (dealerPrice * quantity) + (installation * quantity);

  var requiredId = getTestId(TEST_LIST.PRODUCT.REQUIRED_ITEM.BUTTON, requiredItem.get('id'));

  if (shoppingCartStore.hasItemInBasket(requiredItem.get('id'))) {
    return (
      quantity > 0 ? <div className="basic-controls">
        <div className="price-box">
          <div className="price-item">
            <div className="product-pricing-body">
              <ul>
                {msrp > dealerPrice ?
                  <li>
                    <span className="pricing-title">{dictionary.product.msrp + ":"}</span>
                    <span className="pricing-value">{money_format(msrp)}</span>
                  </li> : false}
                  <li>
                    <span className="pricing-title">{priceLabel + ":"}</span>
                    <span className="pricing-value">{money_format(dealerPrice)}</span>
                  </li>
                {installation > 0 ?
                  <li>
                    <span className="pricing-title">{dictionary.product.installation + ":"}</span>
                    <span className="pricing-value">{money_format(installation)}</span>
                  </li> : false}
                {installation > 0 ?
                  <li>
                    <hr />
                  </li> : false}
                  <li className="total-li">
                    <span className="pricing-title">{dictionary.product.total + ":"}</span>
                    <span className="pricing-value">{money_format(total)}</span>
                  </li>
                {savings > 0 ?
                  <li className="savings-li">
                    <span className="pricing-title">{dictionary.product.totalSavings + ":"}</span>
                    <span className="pricing-value">{money_format(savings)}</span>
                  </li> : false}
                {installation > 0 ?
                  <li className="installation-li">
                    <span className="total-title">{dictionary.product.installationIncluded}</span>
                  </li> : false}
              </ul>
            </div>
          </div>
        </div>
        {applicationStore.data.useCartReviewPage ?
          <button id={requiredId} onClick={onClickRemoveRequiredItemFromCart.bind(null, shoppingCartStore.findItemInBasket(requiredItem.get('id')))} className="btn">{dictionary.product.removeFromQuote}</button> :
          <button id={requiredId} onClick={onClickRemoveRequiredItemFromCart.bind(null, shoppingCartStore.findItemInBasket(requiredItem.get('id')))} className="btn">{shoppingCartRemoveItemText}</button>}
      </div> : false
    )
  } else if (!applicationStore.data.dealerHidePublicPricing(getAppLanguage()) || isUserLoggedIn() || isShoppingCartDetail) {
    return (
      quantity > 0 ? <div className="basic-controls">
        <div className="price-box">
          <div className="price-item">
            <div className="product-pricing-body">
              <ul>
                {msrp > dealerPrice ?
                  <li>
                    <span className="pricing-title">{dictionary.product.msrp + ":"}</span>
                    <span className="pricing-value">{money_format(msrp)}</span>
                  </li> : false}
                  <li>
                    <span className="pricing-title">{priceLabel + ":"}</span>
                    <span className="pricing-value">{money_format(dealerPrice)}</span>
                  </li>
                {installation > 0 ?
                  <li>
                    <span className="pricing-title">{dictionary.product.installation + ":"}</span>
                    <span className="pricing-value">{money_format(installation)}</span>
                  </li> : false}
                {installation > 0 ?
                  <li className="total-li">
                    <span className="pricing-title">{dictionary.product.total + ":"}</span>
                    <span className="pricing-value">{money_format(total)}</span>
                  </li> : false}
                {savings > 0 ?
                  <li className="savings-li">
                    <span className="pricing-title">{dictionary.product.totalSavings + ":"}</span>
                    <span className="pricing-value">{money_format(savings)}</span>
                  </li> : false}
                {installation > 0 ?
                  <li className="installation-li">
                    <span className="total-title">{dictionary.product.installationIncluded}</span>
                  </li> : false}
              </ul>
            </div>
            <ComboBox value={Immutable.fromJS(requiredItem.get('quantity'))} onChange={onChangeRequiredItemQuantity.bind(null, index)} isQuantityDropDown={true}>
              {Immutable.List(requiredItem.get('quantities')).map(quantity => {
                return (
                  <ComboBoxValue key={requiredItem.get('quantity') + '-related-part-quantity-' + quantity['id']} value={Immutable.fromJS(quantity)}>
                    {quantity['title'][getAppLanguage()]}
                  </ComboBoxValue>
                );
              }).toArray()}
            </ComboBox>
          </div>
        </div>
        <button id={requiredId} onClick={onAddRequiredItemToCart.bind(null, requiredItem)} className="btn">
          {applicationStore.data.useCartReviewPage ? dictionary.product.addToWishlist : dictionary.product.addToQuote}
        </button>
      </div> : false
    );
  } else {
    return <div></div>;
  }
};

export const renderTirePricing = (tire, onChangeTireQuantity) => {
  var dictionary = languageStore.getDictionary();
  var priceLabel = dictionary.product.retailPrice;
  if (applicationStore.data.dealerSite && applicationStore.data.dealerSellingPriceLabel[ProductTypes.TIRES][getAppLanguage()]) {
    priceLabel = applicationStore.data.dealerSellingPriceLabel[ProductTypes.TIRES][getAppLanguage()];
  }

  var showMSRP = applicationStore.data.dealerShowMsrp && parseFloat(tire.get('frontTire').get('pricing').get('listPrice')) > parseFloat(tire.get('frontTire').get('pricing').get('displayPrice'));

  var quantityDropDown = false;
  if (tire.get('frontTire').get('hasPricing')) {
    quantityDropDown =
      <ComboBox
        onChange={onChangeTireQuantity}
        value={tire.get('frontTire').get('quantity')}
        className={setAdobeLaunchClass("count front", ADOBELAUNCHER_CLASS.DROPDOWN)}
        datadtm={setAdobeLaunchDTM('', ADOBELAUNCHER_DTM.PARTDETAILS)}
        hideTextInDisplay={true}
        isQuantityDropDown={true}
      >
        {tire.get('frontTire').get('quantities').map(function (n, index) {
          return <ComboBoxValue key={tire.get('frontTire').get('id') + '-part-quantity-' + (tire.get('featured') ? 'feat' : 'nonfeat') + '-' + n.get('id') + '-' + index} value={n}>{n.get('title').get(getAppLanguage())}</ComboBoxValue>;
        }).toArray()}
      </ComboBox>;
  }

  var pricing = false, total = parseFloat(tire.get('frontTire').get('pricing').get('privatePrice')) * parseFloat(tire.get('frontTire').get('quantity').get('value'));
  if (tire.get('hasPromotion') && tire.get('frontTire').get('hasPricing') && (!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) || isUserLoggedIn())) {
    // has promotion
    var price = getPricingFromQuantityAndDetailsRefactored(tire.get('frontTire').get('pricingDetails'), tire.get('frontTire').get('quantity'));

    var useCouponText = price['promotionPricingText'].get(getAppLanguage()) === '';
    var promotionPricingText = !useCouponText ? price['promotionPricingText'] :  price['couponText'];
    var displaySavings = price['displaySavings'];

    var promotionText = "";
    if (typeof(promotionPricingText) === 'object') promotionText = promotionPricingText.get(getAppLanguage());

    if (price && price['url'] && price['url'][getAppLanguage()] !== '') {
      promotionText = <a className="promotion-link" href={price['url'].get(getAppLanguage())} target="_blank"><span>{promotionText}</span></a>;
    }

    var quantityText = dictionary.product.quantity+":";
    if (tire.get('frontTire').get('pricing').get('showUnitPrice')) {
      quantityText = money_format(tire.get('frontTire').get('pricing').get('displayPrice'))+" "+dictionary.product.perTireLwr;
    }

    pricing = (
      <ul className="tire-promoted-pricing">
        <li className="promoted-title"><span>{promotionText}</span></li>
        <li className="promoted-quantity"><span className="pricing-label">{quantityText}</span><span className="pricing-value">{quantityDropDown}</span></li>
        {displaySavings > 0 && !useCouponText ? <li className="promoted-savings"><span className="pricing-label">{dictionary.product.youSave+":"}</span><span className="pricing-value">{money_format(displaySavings * parseInt(tire.get('frontTire').get('quantity').get('value'), 10))}</span></li> : false}
        <li className="pricing-total"><span className="pricing-label">{dictionary.product.total+":"}</span><span className="pricing-value">{money_format(total)}</span></li>
        {applicationStore.data.dealerEnableShowPricingLastUpdate ? renderPricingLastUpdated(tire.get('frontTire').get('pricingLastUpdated')) : false}
      </ul>
    );
  } else if (tire.get('frontTire').get('hasPricing') && tire.get('frontTire').get('pricing').get('displayPrice') > 0 && (!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) || isUserLoggedIn())) {
    var totalList = parseFloat(tire.get('frontTire').get('pricing').get('listPrice')) * parseFloat(tire.get('frontTire').get('quantity').get('value')),
      savings = totalList - total;

    pricing = (
      <ul className="tire-non-promoted-pricing">
        {showMSRP ? <li className="pricing-msrp"><span className="pricing-label">{dictionary.product.msrp+":"}</span><span className="pricing-value">{money_format(tire.get('frontTire').get('pricing').get('listPrice'))}</span></li> : false}
        <li className="pricing-retail-price"><span className="pricing-label">{priceLabel+":"}</span><span className="pricing-value">{money_format(tire.get('frontTire').get('pricing').get('displayPrice'))}</span></li>
        <li className="pricing-quantity"><span className="pricing-label">{dictionary.product.quantity+":"}</span><span className="pricing-value">{quantityDropDown}</span></li>
        <li className="pricing-total"><span className="pricing-label">{dictionary.product.total+":"}</span><span className="pricing-value">{money_format(total)}</span></li>
        {savings > 0 && showMSRP ? <li className="pricing-savings"><span className="pricing-label">{dictionary.product.totalSavings+":"}</span><span className="pricing-value">{money_format(savings)}</span></li> : false}
        {applicationStore.data.dealerEnableShowPricingLastUpdate ? renderPricingLastUpdated(tire.get('frontTire').get('pricingLastUpdated')) : false}
      </ul>
    );
  } else {
    pricing = (
      <ul className="tire-non-promoted-pricing">
        <li className="pricing-msrp"><p className="contact-message">{dictionary.product.contactDealerForPricing}</p></li>
      </ul>
    );
  }

  return (
    <div className="tire-pricing">
      {pricing}
    </div>
  );
};

export const getTirePricingLabel = () => {
  var dictionary = languageStore.getDictionary();
  var priceLabel = dictionary.product.retailPrice;
  if (applicationStore.data.dealerSite && applicationStore.data.dealerSellingPriceLabel[ProductTypes.TIRES][getAppLanguage()]) {
    priceLabel = applicationStore.data.dealerSellingPriceLabel[ProductTypes.TIRES][getAppLanguage()];
  }
  return priceLabel;
};

export const renderTirePricingStaggered = (tire, onChangeTireQuantity, onChangeTireQuantityRear) => {
  var dictionary = languageStore.getDictionary();
  var priceLabel = getTirePricingLabel();

  var showMSRP = applicationStore.data.dealerShowMsrp
    && parseFloat(tire.get('frontTire').get('pricing').get('listPrice')) > parseFloat(tire.get('frontTire').get('pricing').get('displayPrice'))
    && parseFloat(tire.get('rearTire').get('pricing').get('listPrice')) > parseFloat(tire.get('rearTire').get('pricing').get('displayPrice'));

  var quantityDropDown = false;
  if (tire.get('frontTire').get('hasPricing')) {
    quantityDropDown =
      <ComboBox
        onChange={onChangeTireQuantity}
        value={tire.get('frontTire').get('quantity')}
        className={setAdobeLaunchClass("count front", ADOBELAUNCHER_CLASS.DROPDOWN)}
        datadtm={setAdobeLaunchDTM('', ADOBELAUNCHER_DTM.PARTDETAILS)}
        hideTextInDisplay={true}
        isQuantityDropDown={true}
      >
        {tire.get('frontTire').get('quantities').map(function (n) {
          return <ComboBoxValue key={tire.get('frontTire').get('id') + '-part-quantity-front-' + (tire.get('featured') ? 'feat' : 'nonfeat') + '-' + n.get('id')} value={n}>{n.get('title').get(languageStore.data.lang)}</ComboBoxValue>;
        }).toArray()}
      </ComboBox>;
  }

  var quantityDropDownRear = false;
  if (tire.get('rearTire').get('hasPricing')) {
    quantityDropDownRear =
      <ComboBox
        onChange={onChangeTireQuantityRear}
        value={tire.get('rearTire').get('quantity')}
        className={setAdobeLaunchClass("count rear", ADOBELAUNCHER_CLASS.DROPDOWN)}
        datadtm={setAdobeLaunchDTM('', ADOBELAUNCHER_DTM.PARTDETAILS)}
        hideTextInDisplay={true}
        isQuantityDropDown={true}
      >
        {tire.get('rearTire').get('quantities').map(function (n) {
          return <ComboBoxValue key={tire.get('frontTire').get('id') + '-part-quantity-rear-' + (tire.get('featured') ? 'feat' : 'nonfeat') + '-' + n.get('id')} value={n}>{n.get('title').get(languageStore.data.lang)}</ComboBoxValue>;
        }).toArray()}
      </ComboBox>;
  }

  var pricing = false,
    total = (parseFloat(tire.get('frontTire').get('pricing').get('displayPrice')) * parseFloat(tire.get('frontTire').get('quantity').get('value'))
    + parseFloat(tire.get('rearTire').get('pricing').get('displayPrice')) * parseFloat(tire.get('rearTire').get('quantity').get('value')));
  if (tire.get('hasPromotion') && tire.get('frontTire').get('hasPricing') && (!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) || isUserLoggedIn())) {
    // has promotion
    var price = getPricingFromQuantityAndDetailsRefactored(tire.get('frontTire').get('pricingDetails'), tire.get('frontTire').get('quantity'));
    var rearPrice = getPricingFromQuantityAndDetailsRefactored(tire.get('rearTire').get('pricingDetails'), tire.get('rearTire').get('quantity'));

    var useCouponText = price['promotionPricingText'].get(getAppLanguage()) === '';
    var promotionPricingText = !useCouponText ? price['promotionPricingText'] :  price['couponText'];
    var displaySavings = price['displaySavings'];
    var rearDisplaySavings = rearPrice['displaySavings'];

    var promotionText = "";
    if (typeof(promotionPricingText) === 'object') promotionText = promotionPricingText.get(getAppLanguage());

    if (price && price['url'] && price['url'][getAppLanguage()] !== '') {
      promotionText = <a className="promotion-link" href={price['url'].get(getAppLanguage())} target="_blank"><span>{promotionText}</span></a>;
    }

    var quantityText = dictionary.product.front+" "+dictionary.product.quantity+":";
    var quantityTextRear = dictionary.product.rear+" "+dictionary.product.quantity+":";

    pricing = (
      <ul className="tire-promoted-pricing">
        <li className="promoted-title"><span>{promotionText}</span></li>
        <li className="promoted-quantity"><span className="pricing-label">{quantityText}</span><span className="pricing-value">{quantityDropDown}</span></li>
        <li className="promoted-quantity"><span className="pricing-label">{quantityTextRear}</span><span className="pricing-value">{quantityDropDownRear}</span></li>
        {displaySavings > 0 && !useCouponText ? <li className="promoted-savings"><span className="pricing-label">{dictionary.product.youSave+":"}</span><span className="pricing-value">{money_format(((displaySavings * parseInt(tire.get('frontTire').get('quantity').get('value'), 10)) + (rearDisplaySavings * parseInt(tire.get('rearTire').get('quantity').get('value'), 10))))}</span></li> : false}
        <li className="pricing-total"><span className="pricing-label">{dictionary.product.total+":"}</span><span className="pricing-value">{money_format(total)}</span></li>
        {applicationStore.data.dealerEnableShowPricingLastUpdate ? renderPricingLastUpdated(tire.get('frontTire').get('pricingLastUpdated')) : false}
      </ul>
    );
  } else if (tire.get('frontTire').get('hasPricing') && tire.get('frontTire').get('pricing').get('displayPrice') > 0 && (!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) || isUserLoggedIn())) {
    var totalList = (parseFloat(tire.get('frontTire').get('pricing').get('listPrice')) * parseFloat(tire.get('frontTire').get('quantity').get('value'))
      + parseFloat(tire.get('rearTire').get('pricing').get('listPrice')) * parseFloat(tire.get('rearTire').get('quantity').get('value'))),
      savings = totalList - total;

    pricing = (
      <ul className="tire-non-promoted-pricing">
        {showMSRP ? <li className="pricing-msrp"><span className="pricing-label">{dictionary.product.msrp+":"}</span><span className="pricing-value">{dictionary.frontAbbrv+" "+money_format(tire.get('frontTire').get('pricing').get('listPrice'))+" "+dictionary.rearAbbrv+" "+money_format(tire.get('rearTire').get('pricing').get('listPrice'))}</span></li> : false}
        <li className="pricing-retail-price"><span className="pricing-label">{getAppLanguage() !== 'fr' ? priceLabel+":" : ""}</span><span className="pricing-value">{dictionary.frontAbbrv+" "+money_format(tire.get('frontTire').get('pricing').get('displayPrice'))+" "+dictionary.rearAbbrv+" "+money_format(tire.get('rearTire').get('pricing').get('displayPrice'))}</span></li>
        <li className="pricing-quantity staggered"><span className="pricing-label">{dictionary.product.front+" "+dictionary.product.quantity+":"}</span><span className="pricing-value">{quantityDropDown}</span></li>
        <li className="pricing-quantity staggered"><span className="pricing-label">{dictionary.product.rear+" "+dictionary.product.quantity+":"}</span><span className="pricing-value">{quantityDropDownRear}</span></li>
        <li className="pricing-total"><span className="pricing-label">{dictionary.product.total+":"}</span><span className="pricing-value">{money_format(total)}</span></li>
        {savings > 0 && showMSRP ? <li className="pricing-savings"><span className="pricing-label">{dictionary.product.totalSavings+":"}</span><span className="pricing-value">{money_format(savings)}</span></li> : false}
        {applicationStore.data.dealerEnableShowPricingLastUpdate ? renderPricingLastUpdated(tire.get('frontTire').get('pricingLastUpdated')) : false}
      </ul>
    );
  } else {
    pricing = (
      <ul className="tire-non-promoted-pricing">
        <li className="pricing-msrp"><p className="contact-message">{dictionary.product.contactDealerForPricing}</p></li>
      </ul>
    );
  }

  return (
    <div className="tire-pricing">
      {pricing}
    </div>
  );
};

export const renderWheelPricing = (wheel, onChangeWheelQuantity) => {
  var dictionary = languageStore.getDictionary();
  var priceLabel = dictionary.product.retailPrice;
  if (applicationStore.data.dealerSite && applicationStore.data.dealerSellingPriceLabel
    && typeof applicationStore.data.dealerSellingPriceLabel.en !== 'undefined'
    && applicationStore.data.dealerSellingPriceLabel.en !== false
    && applicationStore.data.dealerSellingPriceLabel.en !== null) {
    if (getAppLanguage() === 'fr') {
      priceLabel = applicationStore.data.dealerSellingPriceLabel.fr;
    } else {
      priceLabel = applicationStore.data.dealerSellingPriceLabel.en;
    }
  }

  var showMSRP = applicationStore.data.dealerShowMsrp && parseFloat(wheel.get('frontWheel').get('pricing').get('listPrice')) > parseFloat(wheel.get('frontWheel').get('pricing').get('displayPrice'));

  var quantityDropDown = false;
  if (wheel.get('frontWheel').get('hasPricing') && (!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) || isUserLoggedIn())) {
    quantityDropDown =
      <ComboBox
        onChange={onChangeWheelQuantity}
        value={wheel.get('frontWheel').get('quantity')}
        className={setAdobeLaunchClass("count front", ADOBELAUNCHER_CLASS.DROPDOWN)}
        datadtm={setAdobeLaunchDTM('', ADOBELAUNCHER_DTM.PARTDETAILS)}
        hideTextInDisplay={true}
        isQuantityDropDown={true}
      >
        {wheel.get('frontWheel').get('quantities').map(function (n) {
          return <ComboBoxValue key={wheel.get('id') + '-part-quantity-' + n.get('id')} value={n}>{n.get('title').get(languageStore.data.lang)}</ComboBoxValue>;
        }).toArray()}
      </ComboBox>;
  }

  var pricing = false, total = parseFloat(wheel.get('frontWheel').get('pricing').get('displayPrice')) * parseFloat(wheel.get('frontWheel').get('quantity').get('value')) +
                                (wheel.get('frontWheel').get('pricing').get('displayLabour') * parseInt(parseFloat(wheel.get('frontWheel').get('quantity').get('value')), 10));
  if (wheel.get('hasPromotion') && (!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) || isUserLoggedIn())) {
    // has promotion
    var price = getPricingFromQuantityAndDetailsRefactored(wheel.get('frontWheel').get('pricingDetails'), wheel.get('frontWheel').get('quantity'));

    var useCouponText = price['promotionPricingText'].get(getAppLanguage()) === '';
    var promotionPricingText = !useCouponText ? price['promotionPricingText'] :  price['couponText'];
    var displaySavings = price['displaySavings'];
    var displayLabour = price['displayLabour'];

    var promotionText = "";
    if (typeof(promotionPricingText) === 'object') promotionText = promotionPricingText.get(getAppLanguage());

    if (price && price['url'] && price['url'][getAppLanguage()] !== '') {
      promotionText = <a className="promotion-link" href={price['url'].get(getAppLanguage())} target="_blank"><span>{promotionText}</span></a>;
    }

    var quantityText = dictionary.product.quantity+":";
    if (wheel.get('frontWheel').get('pricing').get('showUnitPrice')) {
      quantityText = money_format(wheel.get('frontWheel').get('pricing').get('displayPrice'))+" "+dictionary.product.perWheelLwr;
    }

    pricing = (
      <ul className="wheel-promoted-pricing">
        <li className="promoted-title"><span>{promotionText}</span></li>
        <li className="promoted-quantity"><span className="pricing-label">{quantityText}</span><span className="pricing-value">{quantityDropDown}</span></li>
        {displaySavings > 0 && !useCouponText ? <li className="promoted-savings"><span className="pricing-label">{dictionary.product.youSave+":"}</span><span className="pricing-value">{money_format(displaySavings * parseInt(wheel.get('frontWheel').get('quantity').get('value'), 10))}</span></li> : false}
        <li className="pricing-total"><span className="pricing-label">{dictionary.product.total+":"}</span><span className="pricing-value">{money_format(total)}</span></li>
        {displayLabour > 0 ? <li className="promoted-installation"><span className="pricing-label">{dictionary.product.installation+":"}</span><span className="pricing-value">{money_format(displayLabour)}</span></li> : false}
      </ul>
    );
  } else if (wheel.get('frontWheel').get('hasPricing') && wheel.get('frontWheel').get('pricing').get('displayPrice') > 0 && (!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) || isUserLoggedIn())) {
    var totalList = parseFloat(wheel.get('frontWheel').get('pricing').get('listPrice')) * parseFloat(wheel.get('frontWheel').get('quantity').get('value')),
      savings = totalList - total;

    pricing = (
      <ul className="wheel-non-promoted-pricing">
        {showMSRP ? <li className="pricing-msrp"><span className="pricing-label">{dictionary.product.msrp+":"}</span><span className="pricing-value">{money_format(wheel.get('frontWheel').get('pricing').get('listPrice'))}</span></li> : false}
        <li className="pricing-retail-price"><span className="pricing-label">{priceLabel+":"}</span><span className="pricing-value">{money_format(wheel.get('frontWheel').get('pricing').get('displayPrice'))}</span></li>
        {parseFloat(wheel.get('frontWheel').get('pricing').get('displayLabour')) > 0 ? <li className="pricing-retail-price pricing-labour"><span className="pricing-label">{dictionary.product.installation+":"}</span><span className="pricing-value">{money_format(wheel.get('frontWheel').get('pricing').get('displayLabour'))}</span></li> : false}
        <li className="pricing-quantity"><span className="pricing-label">{dictionary.product.quantity+":"}</span><span className="pricing-value">{quantityDropDown}</span></li>
        <li className="pricing-total"><span className="pricing-label">{dictionary.product.total+":"}</span><span className="pricing-value">{money_format(total)}</span></li>
        {savings > 0 && showMSRP ? <li className="pricing-savings"><span className="pricing-label">{dictionary.product.totalSavings+":"}</span><span className="pricing-value">{money_format(savings)}</span></li> : false}
        {parseFloat(wheel.get('frontWheel').get('pricing').get('displayLabour')) > 0 ? <li className="pricing-installation"><span className="pricing-label">{dictionary.product.installationIncluded}</span></li> : false}
      </ul>
    );
  } else {
    pricing = (
      <ul className="wheel-non-promoted-pricing">
        <li className="pricing-msrp"><p className="contact-message">{dictionary.product.contactDealerForPricing}</p></li>
      </ul>
    );
  }

  return(
    <div className="wheel-pricing">
      {pricing}
    </div>
  );
};

export const renderWheelPricingStaggered = (wheel, onChangeWheelQuantity, onChangeWheelQuantityRear) => {
  var dictionary = languageStore.getDictionary();
  var priceLabel = dictionary.product.retailPrice;
  if (applicationStore.data.dealerSite && applicationStore.data.dealerSellingPriceLabel
    && typeof applicationStore.data.dealerSellingPriceLabel.en !== 'undefined'
    && applicationStore.data.dealerSellingPriceLabel.en !== false
    && applicationStore.data.dealerSellingPriceLabel.en !== null) {
    if (getAppLanguage() === 'fr') {
      priceLabel = applicationStore.data.dealerSellingPriceLabel.fr;
    } else {
      priceLabel = applicationStore.data.dealerSellingPriceLabel.en;
    }
  }

  var showMSRP = applicationStore.data.dealerShowMsrp && parseFloat(wheel.get('frontWheel').get('pricing').get('listPrice')) > parseFloat(wheel.get('frontWheel').get('pricing').get('displayPrice'));

  var quantityDropDown = false;
  if (wheel.get('frontWheel').get('hasPricing') && (!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) || isUserLoggedIn())) {
    quantityDropDown =
      <ComboBox
        onChange={onChangeWheelQuantity}
        value={wheel.get('frontWheel').get('quantity')}
        className={setAdobeLaunchClass("count front", ADOBELAUNCHER_CLASS.DROPDOWN)}
        datadtm={setAdobeLaunchDTM('', ADOBELAUNCHER_DTM.PARTDETAILS)}
        hideTextInDisplay={true}
        isQuantityDropDown={true}
      >
        {wheel.get('frontWheel').get('quantities').map(function (n) {
          return <ComboBoxValue key={wheel.get('id') + '-part-quantity-' + n.get('id')} value={n}>{n.get('title').get(languageStore.data.lang)}</ComboBoxValue>;
        }).toArray()}
      </ComboBox>;
  }

  var quantityDropDownRear = false;
  if (wheel.get('rearWheel').get('hasPricing') && (!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) || isUserLoggedIn())) {
    quantityDropDownRear =
      <ComboBox
        onChange={onChangeWheelQuantityRear}
        value={wheel.get('rearWheel').get('quantity')}
        className={setAdobeLaunchClass("count rear", ADOBELAUNCHER_CLASS.DROPDOWN)}
        datadtm={setAdobeLaunchDTM('', ADOBELAUNCHER_DTM.PARTDETAILS)}
        hideTextInDisplay={true}
        isQuantityDropDown={true}
      >
        {wheel.get('rearWheel').get('quantities').map(function (n) {
          return <ComboBoxValue key={wheel.get('id') + '-part-quantity-' + n.get('id')} value={n}>{n.get('title').get(languageStore.data.lang)}</ComboBoxValue>;
        }).toArray()}
      </ComboBox>;
  }

  var pricing = false, total = (parseFloat(wheel.get('frontWheel').get('pricing').get('displayPrice')) * parseFloat(wheel.get('frontWheel').get('quantity').get('value'))) +
                                  (parseFloat(wheel.get('rearWheel').get('pricing').get('displayPrice')) * parseFloat(wheel.get('rearWheel').get('quantity').get('value'))) +
                                    (wheel.get('frontWheel').get('pricing').get('displayLabour') * parseInt(parseFloat(wheel.get('frontWheel').get('quantity').get('value')), 10));
  if (wheel.get('hasPromotion') && (!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) || isUserLoggedIn())) {
    // has promotion
    var price = getPricingFromQuantityAndDetailsRefactored(wheel.get('frontWheel').get('pricingDetails'), wheel.get('frontWheel').get('quantity'));
    var priceRear = getPricingFromQuantityAndDetailsRefactored(wheel.get('rearWheel').get('pricingDetails'), wheel.get('frontWheel').get('quantity'));

    var useCouponText = price['promotionPricingText'].get(getAppLanguage()) === '';
    var promotionPricingText = !useCouponText ? price['promotionPricingText'] :  price['couponText'];
    var displaySavings = price['displaySavings'];
    var displaySavingsRear = priceRear['displaySavings'];
    var displayLabour = price['displayLabour'];

    var promotionText = "";
    if (typeof(promotionPricingText) === 'object') promotionText = promotionPricingText.get(getAppLanguage());

    if (price && price['url'] && price['url'][getAppLanguage()] !== '') {
      promotionText = <a className="promotion-link" href={price['url'].get(getAppLanguage())} target="_blank"><span>{promotionText}</span></a>;
    }

    var quantityText = dictionary.product.quantity+":";
    if (wheel.get('frontWheel').get('pricing').get('showUnitPrice')) {
      quantityText = money_format(wheel.get('frontWheel').get('pricing').get('displayPrice'))+" "+dictionary.product.perWheelLwr;
    }

    var quantityTextRear = dictionary.product.quantity+":";
    if (wheel.get('rearWheel').get('pricing').get('showUnitPrice')) {
      quantityTextRear = money_format(wheel.get('rearWheel').get('pricing').get('displayPrice'))+" "+dictionary.product.perWheelLwr;
    }

    pricing = (
      <ul className="wheel-promoted-pricing">
        <li className="promoted-title"><span>{promotionText}</span></li>
        <li className="promoted-quantity"><span className="pricing-label">{quantityText}</span><span className="pricing-value">{quantityDropDown}</span></li>
        <li className="promoted-quantity"><span className="pricing-label">{quantityTextRear}</span><span className="pricing-value">{quantityDropDownRear}</span></li>
        {displaySavings > 0 && !useCouponText ? <li className="promoted-savings"><span className="pricing-label">{dictionary.product.youSave+":"}</span><span className="pricing-value">{money_format((displaySavings * parseInt(wheel.get('frontWheel').get('quantity').get('value'), 10)) + (displaySavingsRear * parseInt(wheel.get('rearWheel').get('quantity').get('value'), 10)))}</span></li> : false}
        <li className="pricing-total"><span className="pricing-label">{dictionary.product.total+":"}</span><span className="pricing-value">{money_format(total)}</span></li>
        {displayLabour > 0 ? <li className="promoted-installation"><span className="pricing-label">{dictionary.product.installation+":"}</span><span className="pricing-value">{money_format(displayLabour)}</span></li> : false}
      </ul>
    );
  } else if (wheel.get('frontWheel').get('hasPricing') && wheel.get('frontWheel').get('pricing').get('displayPrice') > 0 && (!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) || isUserLoggedIn())) {
    var totalList = (parseFloat(wheel.get('frontWheel').get('pricing').get('listPrice')) * parseFloat(wheel.get('frontWheel').get('quantity').get('value')))
                    + (parseFloat(wheel.get('rearWheel').get('pricing').get('listPrice')) * parseFloat(wheel.get('rearWheel').get('quantity').get('value'))),
      savings = totalList - total;

    pricing = (
      <ul className="wheel-non-promoted-pricing">
        {showMSRP ? <li className="pricing-msrp"><span className="pricing-label">{dictionary.product.msrp+":"}</span><span className="pricing-value">{dictionary.frontAbbrv+" "+money_format(wheel.get('frontWheel').get('pricing').get('listPrice'))+" "+dictionary.rearAbbrv+" "+money_format(wheel.get('rearWheel').get('pricing').get('listPrice'))}</span></li> : false}
        <li className="pricing-retail-price"><span className="pricing-label">{priceLabel+":"}</span><span className="pricing-value">{dictionary.frontAbbrv+" "+money_format(wheel.get('frontWheel').get('pricing').get('displayPrice'))+" "+dictionary.rearAbbrv+" "+money_format(wheel.get('rearWheel').get('pricing').get('displayPrice'))}</span></li>
        {parseFloat(wheel.get('frontWheel').get('pricing').get('displayLabour')) > 0 ? <li className="pricing-retail-price pricing-labour"><span className="pricing-label">{dictionary.product.installation+":"}</span><span className="pricing-value">{money_format(wheel.get('frontWheel').get('pricing').get('displayLabour'))}</span></li> : false}
        <li className="pricing-quantity staggered"><span className="pricing-label">{dictionary.product.front+" "+dictionary.product.quantity+":"}</span><span className="pricing-value">{quantityDropDown}</span></li>
        <li className="pricing-quantity staggered"><span className="pricing-label">{dictionary.product.rear+" "+dictionary.product.quantity+":"}</span><span className="pricing-value">{quantityDropDownRear}</span></li>
        <li className="pricing-total"><span className="pricing-label">{dictionary.product.total+":"}</span><span className="pricing-value">{money_format(total)}</span></li>
        {savings > 0 && showMSRP ? <li className="pricing-savings"><span className="pricing-label">{dictionary.product.totalSavings+":"}</span><span className="pricing-value">{money_format(savings)}</span></li> : false}
        {parseFloat(wheel.get('frontWheel').get('pricing').get('displayLabour')) > 0 ? <li className="pricing-installation"><span className="pricing-label">{dictionary.product.installationIncluded}</span></li> : false}
      </ul>
    );
  } else {
    pricing = (
      <ul className="wheel-non-promoted-pricing">
        <li className="pricing-msrp"><p className="contact-message">{dictionary.product.contactDealerForPricing}</p></li>
      </ul>
    );
  }

  return(
    <div className="wheel-pricing">
      {pricing}
    </div>
  );
};

export const renderAccessoryPricing = (accessory, onChangeAccessoryQuantity, requiredItemTotal = false) => {
  var dictionary = languageStore.getDictionary();
  var priceLabel = dictionary.product.retailPrice;
  if (applicationStore.data.dealerSite && applicationStore.data.dealerSellingPriceLabel[ProductTypes.ACCESSORIES][getAppLanguage()]) {
    priceLabel = applicationStore.data.dealerSellingPriceLabel[ProductTypes.ACCESSORIES][getAppLanguage()];
  }

  var quantityDropDown = false;
  if (accessory.get('hasPricing')) {
    quantityDropDown =
      <ComboBox
        onChange={onChangeAccessoryQuantity}
        value={accessory.get('quantity')}
        className={setAdobeLaunchClass("count front", ADOBELAUNCHER_CLASS.DROPDOWN)}
        datadtm={setAdobeLaunchDTM('', ADOBELAUNCHER_DTM.PARTDETAILS)}
        hideTextInDisplay={true}
        isQuantityDropDown={true}
      >
        {accessory.get('quantities').map(function (n) {
          return <ComboBoxValue key={accessory.get('id') + '-part-quantity-' + n.get('id')} value={n}>{n.get('title').get(languageStore.data.lang)}</ComboBoxValue>;
        }).toArray()}
      </ComboBox>;
  }

  var pricing = false;
  if (accessory.get('hasPromotion') && (!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) || isUserLoggedIn())) {
    var total = (parseFloat(accessory.get('pricing').get('privatePrice')) * parseFloat(accessory.get('quantity').get('value'))) + (accessory.get('pricing').get('displayLabour') * parseInt(parseFloat(accessory.get('quantity').get('value')), 10));
    if (requiredItemTotal !== false && parseFloat(requiredItemTotal) > 0) total += parseFloat(requiredItemTotal);
    // has promotion
    var price = getPricingFromQuantityAndDetailsRefactored(accessory.get('pricingDetails'), accessory.get('quantity'));

    var useCouponText = price['promotionPricingText'].get(getAppLanguage()) === '';
    var promotionPricingText = !useCouponText ? price['promotionPricingText'] :  price['couponText'];
    var displaySavings = price['displaySavings'];
    var displayLabour = price['displayLabour'];

    var promotionText = "";
    if (typeof(promotionPricingText) === 'object') promotionText = promotionPricingText.get(getAppLanguage());

    if (price && price['url'] && price['url'][getAppLanguage()] !== '') {
      promotionText = <a className="promotion-link" href={price['url'].get(getAppLanguage())} target="_blank"><span>{promotionText}</span></a>;
    }

    var quantityText = dictionary.product.quantity+":";
    if (accessory.get('pricing').get('showUnitPrice')) {
      quantityText = money_format(accessory.get('pricing').get('displayPrice'))+" "+dictionary.product.perItemLwr;
    }

    pricing = (
      <ul className="accessory-promoted-pricing">
        <li className="promoted-title"><span>{promotionText}</span></li>
        <li className="promoted-quantity"><span className="pricing-label">{quantityText}</span><span className="pricing-value">{quantityDropDown}</span></li>
        {displaySavings > 0 && !useCouponText ? <li className="promoted-savings"><span className="pricing-label">{dictionary.product.youSave+":"}</span><span className="pricing-value">{money_format(displaySavings * parseInt(accessory.get('quantity').get('value'), 10))}</span></li> : false}
        <li className="pricing-total"><span className="pricing-label">{dictionary.product.total+":"}</span><span className="pricing-value">{money_format(total)}</span></li>
        {displayLabour > 0 ? <li className="promoted-installation"><span className="pricing-label">{dictionary.product.installation+":"}</span><span className="pricing-value">{money_format(displayLabour)}</span></li> : false}
      </ul>
    );
  } else if (accessory.get('hasPricing') && accessory.get('pricing').get('displayPrice') > 0 && (!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) || isUserLoggedIn())) {
    var showMSRP = applicationStore.data.dealerShowMsrp && parseFloat(accessory.get('pricing').get('listPrice')) > parseFloat(accessory.get('pricing').get('displayPrice'));
    var total = (parseFloat(accessory.get('pricing').get('privatePrice')) * parseFloat(accessory.get('quantity').get('value'))) + (accessory.get('pricing').get('privateLabour') * parseInt(parseFloat(accessory.get('quantity').get('value')), 10));
    if (requiredItemTotal !== false && parseFloat(requiredItemTotal) > 0) total += parseFloat(requiredItemTotal);
    var totalList = parseFloat(accessory.get('pricing').get('listPrice')) * parseFloat(accessory.get('quantity').get('value')), savings = totalList - total;

    pricing = (
      <ul className="accessory-non-promoted-pricing">
        {showMSRP ? <li className="pricing-msrp"><span className="pricing-label">{dictionary.product.msrp+":"}</span><span className="pricing-value">{money_format(accessory.get('pricing').get('listPrice'))}</span></li> : false}
        <li className="pricing-retail-price"><span className="pricing-label">{priceLabel+":"}</span><span className="pricing-value">{money_format(accessory.get('pricing').get('displayPrice'))}</span></li>
        {parseFloat(accessory.get('pricing').get('displayLabour')) > 0 ? <li className="pricing-retail-price pricing-labour"><span className="pricing-label">{dictionary.product.installation+":"}</span><span className="pricing-value">{money_format(accessory.get('pricing').get('displayLabour'))}</span></li> : false}
        <li className="pricing-quantity"><span className="pricing-label">{dictionary.product.quantity+":"}</span><span className="pricing-value">{quantityDropDown}</span></li>
        <li className="pricing-total"><span className="pricing-label">{dictionary.product.total+":"}</span><span className="pricing-value">{money_format(total)}</span></li>
        {savings > 0 && showMSRP ? <li className="pricing-savings"><span className="pricing-label">{dictionary.product.totalSavings+":"}</span><span className="pricing-value">{money_format(savings)}</span></li> : false}
        {parseFloat(accessory.get('pricing').get('displayLabour')) > 0 ? <li className="pricing-installation"><span className="pricing-label">{dictionary.product.installationIncluded}</span></li> : false}
      </ul>
    );
  } else {
    pricing = (
      <ul className="accessory-non-promoted-pricing">
        <li className="pricing-msrp"><p className="contact-message">{dictionary.product.contactDealerForPricing}</p></li>
      </ul>
    );
  }

  return (
    <div className="accessory-pricing">
      {pricing}
    </div>
  );
};

export const renderPackagePricing = (pkg, onChangePackageQuantity) => {
  var dictionary = languageStore.getDictionary();
  var priceLabel = dictionary.product.retailPrice;
  if (applicationStore.data.dealerSite && applicationStore.data.dealerSellingPriceLabel[ProductTypes.PACKAGES][getAppLanguage()]) {
    priceLabel = applicationStore.data.dealerSellingPriceLabel[ProductTypes.PACKAGES][getAppLanguage()];
  }

  var showMSRP = applicationStore.data.dealerShowMsrp && parseFloat(pkg.get('pricing').get('listPrice')) > parseFloat(pkg.get('pricing').get('displayPrice'));

  var quantityDropDown = false;
  if (pkg.get('hasPricing')) {
    quantityDropDown =
      <ComboBox
        onChange={onChangePackageQuantity}
        value={pkg.get('quantity')}
        className={setAdobeLaunchClass("count front", ADOBELAUNCHER_CLASS.DROPDOWN)}
        datadtm={setAdobeLaunchDTM('', ADOBELAUNCHER_DTM.PARTDETAILS)}
        hideTextInDisplay={true}
        isQuantityDropDown={true}
      >
        {pkg.get('quantities').map(function (n) {
          return <ComboBoxValue key={pkg.get('id') + '-part-quantity-' + n.get('id')} value={n}>{n.get('title').get(languageStore.data.lang)}</ComboBoxValue>;
        }).toArray()}
      </ComboBox>;
  }

  var pricing = false, total = parseFloat(pkg.get('pricing').get('displayPrice')) * parseFloat(pkg.get('quantity').get('value'));
  if (pkg.get('hasPromotion') && (!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) || isUserLoggedIn())) {
    //     // has promotion
    var price = getPricingFromQuantityAndDetailsRefactored(pkg.get('pricingDetails'), pkg.get('quantity'));

    var useCouponText = price['promotionPricingText'].get(getAppLanguage()) === '';
    var promotionPricingText = !useCouponText ? price['promotionPricingText'] :  price['couponText'];
    var displaySavings = price['displaySavings'];
    var displayLabour = price['displayLabour'];

    var promotionText = "";
    if (typeof(promotionPricingText) === 'object') promotionText = promotionPricingText.get(getAppLanguage());

    if (price && price['url'] && price['url'][getAppLanguage()] !== '') {
      promotionText = <a className="promotion-link" href={price['url'].get(getAppLanguage())} target="_blank"><span>{promotionText}</span></a>;
    }

    var quantityText = dictionary.product.quantity+":";
    if (pkg.get('pricing').get('showUnitPrice')) {
      quantityText = money_format(pkg.get('pricing').get('displayPrice'))+" "+dictionary.product.perPackageLwr;
    }

    pricing = (
      <ul className="package-promoted-pricing">
        <li className="promoted-title"><span>{promotionText}</span></li>
        <li className="promoted-quantity"><span className="pricing-label">{quantityText}</span><span className="pricing-value">{quantityDropDown}</span></li>
        {price['displaySavings'] > 0 && !useCouponText ? <li className="promoted-savings"><span className="pricing-label">{dictionary.product.youSave+":"}</span><span className="pricing-value">{money_format(price['displaySavings'] * parseInt(pkg.get('quantity').get('value'), 10))}</span></li> : false}
        <li className="pricing-total"><span className="pricing-label">{dictionary.product.total+":"}</span><span className="pricing-value">{money_format(total)}</span></li>
      </ul>
    );
  } else if (pkg.get('hasPricing') && pkg.get('pricing').get('displayPrice') > 0 && (!applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) || isUserLoggedIn())) {
    var totalList = parseFloat(pkg.get('pricing').get('listPrice')) * parseFloat(pkg.get('quantity').get('value')), savings = totalList - total;

    pricing = (
      <ul className="package-non-promoted-pricing">
        {showMSRP ? <li className="pricing-msrp"><span className="pricing-label">{dictionary.product.msrp+":"}</span><span className="pricing-value">{money_format(pkg.get('pricing').get('listPrice'))}</span></li> : false}
        <li className="pricing-retail-price"><span className="pricing-label">{priceLabel+":"}</span><span className="pricing-value">{money_format(pkg.get('pricing').get('displayPrice'))}</span></li>
        <li className="pricing-quantity"><span className="pricing-label">{dictionary.product.quantity+":"}</span><span className="pricing-value">{quantityDropDown}</span></li>
        <li className="pricing-total"><span className="pricing-label">{dictionary.product.total+":"}</span><span className="pricing-value">{money_format(total)}</span></li>
        {savings > 0 && showMSRP ? <li className="pricing-savings"><span className="pricing-label">{dictionary.product.totalSavings+":"}</span><span className="pricing-value">{money_format(savings)}</span></li> : false}
      </ul>
    );
  } else {
    pricing = (
      <ul className="package-non-promoted-pricing">
        <li className="pricing-msrp"><p className="contact-message">{dictionary.product.contactDealerForPricing}</p></li>
      </ul>
    );
  }

  return(
    <div className="package-pricing">
      {pricing}
    </div>
  );
};

export const renderPricingLastUpdated = (lastUpdated) => {
  if (isUserLoggedIn() && lastUpdated) {
    var dictionary = languageStore.getDictionary();
    return (
      <li className="pricing-message"><span className="last-updated text-small">{dictionary.product.pricingLastUpdated.replace('[]', lastUpdated)}</span></li>
    );
  } else {
    return false;
  }
};

const enableFloatRightTablet = () => {
  switch(applicationStore.data.defaultTemplate) {
    case "volkswagen":
      return !isMobile() && !isTablet();
    default:
      return true;
  }
};

export const renderCarousel = (carouselItems, fromLandingPage, selectedIndex = 0, lastIndex = 0, onSetSelectedIndex = (function() {})) => {
  var carouselClass = Cx({
    "carousel": true,
    "has-launcher": applicationStore.data.enableHomepageLauncher || applicationStore.data.defaultTemplate === 'kia_2021' || applicationStore.data.defaultTemplate === 'audi_2021',
    "hang-launcher": applicationStore.data.enableHomepageLauncher && applicationStore.data.homepageLauncherFloatOverCarousel === "TOP",
    "homepage-refactor": applicationStore.data.enableAudiFeatures || applicationStore.data.enableVolkswagenFeatures || applicationStore.data.defaultTemplate === 'kia_2021' || applicationStore.data.defaultTemplate === 'audi_2021',
    "float-launcher left": applicationStore.data.enableHomepageLauncher && applicationStore.data.homepageLauncherFloatOverCarousel === "LEFT",
    "float-launcher right": applicationStore.data.enableHomepageLauncher && applicationStore.data.homepageLauncherFloatOverCarousel === "RIGHT",
    "float-launcher locked": applicationStore.data.enableHomepageLauncher && applicationStore.data.homepageLauncherLockToPage,
    "has-panel-launcher": applicationStore.data.enableHomepagePanelLauncher && !applicationStore.data.enableHomepageLauncher,
    "homepage": applicationStore.data.enableHomepageLauncher && !fromLandingPage
  });
  return (
    <div className={carouselClass}>
      <div className="carousel-wrapper">

        {carouselItems.sort(sortCarouselList).map(function(carouselItem, index) {

          var itemClass = Cx({
            "carousel-item": true,
            "selected": index === selectedIndex,
            "last-selected": index === lastIndex && (index !== selectedIndex)
          }), language = getAppLanguage(), renderButton = carouselItem.get('title').get(language) !== '' || carouselItem.get('linkUrl').get(language) !== '';

          if (carouselItem.get('playerType') === CarouselPlayerTypes.IMAGE_PLAYER) {

            return (
              <div key={"carousel-image-" + index} className={itemClass}>
                <Image src={carouselItem.get('image').get(language)} alt="Image Carousel"/>
                {renderButton ? renderCarouselItemText(carouselItem) : false}
              </div>
            );

          } else if (carouselItem.get('playerType') === CarouselPlayerTypes.VIDEO_IMAGE_PLAYER) {

            return (
              <div key={"carousel-video-" + index} className={itemClass}>
                <div className="video-wrapper hidden-xs">
                  {carouselItem.get('playerControls')['mute'] ?
                    <video className="carousel-video" poster={carouselItem.get('image').get(language)} id="bgvid" autoPlay={carouselItem.get('playerControls')['autoPlay']} controls={false} loop={carouselItem.get('playerControls')['loop']} style={elementStyle} muted>
                      <source src={carouselItem.get('videoMp4Url').get(language)} type="video/mp4" />
                      <source src={carouselItem.get('videoWebMUrl').get(language)} type="video/webm" />
                      <source src={carouselItem.get('videoOggUrl').get(language)} type="video/ogg" />
                    </video>
                    : <video className="carousel-video" poster={carouselItem.get('image').get(language)} id="bgvid" autoPlay={carouselItem.get('playerControls')['autoPlay']} controls={false} loop={carouselItem.get('playerControls')['loop']} style={elementStyle}>
                      <source src={carouselItem.get('videoMp4Url').get(language)} type="video/mp4" />
                      <source src={carouselItem.get('videoWebMUrl').get(language)} type="video/webm" />
                      <source src={carouselItem.get('videoOggUrl').get(language)} type="video/ogg" />
                    </video>}
                </div>
                {renderButton ? renderCarouselItemText(carouselItem) : false}
              </div>
            );

          } else if (carouselItem.get('playerType') === CarouselPlayerTypes.YOUTUBE_PLAYER) {

            var youtubeVideoStyle = {
              width: parseFloat(100/carouselItems.size)+"%",
              left: parseFloat(index * (100/carouselItems.size))+"%",
              height: carouselItem.get('videoHeight') + "px"
            };
            var controls = '?controls=0&showinfo=0&autoplay='+(carouselItem.get('playerControls')['autoPlay'] ? '1' : '0')+'&rel=0&modestbranding=1&iv_load_policy=3&loop='+(carouselItem.get('playerControls')['loop'] ? '1' : '0');

            return renderYouTubeVideo(carouselItem.get('videoYouTubeUrl').get(language)+controls, "carousel-youtube-video-" + index, itemClass, youtubeVideoStyle, true);

          }

        })}

        <div className="carousel-curtain" />

      </div>
      {getCarouselDots(carouselItems, selectedIndex, onSetSelectedIndex)}
      {getCarouselArrow('left')}
      {getCarouselArrow('right')}
    </div>
  );
};

const sortCarouselList = (a, b) => {

  if (a.get('sortOrder') > b.get('sortOrder')) {
    return 1;
  } else if (b.get('sortOrder') > a.get('sortOrder')) {
    return -1;
  } else {
    return 0;
  }

};

export const renderYouTubeVideo = (link, key = '', itemClass = '', youtubeVideoStyle = {}, controlsIncluded = false) => {

  var controls = '?rel=0&wmode=transparent&modestbranding=1&showinfo=0&iv_load_policy=3&controls=2';

  return (
    <div key={key} className={itemClass}>
      <div className="carousel-item-actions">
        <div className="video-wrapper hidden-xs">
          <iframe className="youtube-player" type="text/html" style={youtubeVideoStyle} src={controlsIncluded ? link : link + controls} frameBorder="0"></iframe>
        </div>
      </div>
    </div>
  );
};

const showControlArrow = (carouselItems) => {
  return sizeOf(carouselItems) > 1;
};

const getCarouselDots = (carouselItems, selectedIndex, onSetSelectedIndex) => {
  if (showControlArrow(carouselItems)) {
    return(
      <ul className="carousel-dots">

        {carouselItems.map(function(carouselItem, index) {

          return (
            <li key={"carousel-select-" + index} className={setAdobeLaunchClass(getCarouselDotsClass(selectedIndex, index), ADOBELAUNCHER_CLASS.DOTS)} data-dtm={setAdobeLaunchDTM('', ADOBELAUNCHER_DTM.CAROUSELDOTS)} onClick={onSetSelectedIndex.bind(null, index)}></li>
          );

        })}

      </ul>
    );
  } else {
    return false;
  }
};

const getCarouselDotsClass = (selectedIndex, index) => {
  return(Cx({ 'active': index === selectedIndex }));
};

const getCarouselArrow = (carouselItems, side, onSetSelectedIndex, selectedIndex) => {
  if (applicationStore.data.enableMercedesFeatures) {
    switch(side) {
      case "left":
        return(
          <div className={!showControlArrow(carouselItems) ? 'carousel-arrow-left disabled' :  'carousel-arrow-left'} onClick={onSetSelectedIndex.bind(null, (selectedIndex - 1))}>
            <img className="arrow-noshade" src="/static/img/mercedes/carousel_arrow_left.png" alt="Button Select Left"/>
          </div>
        );
      case "right":
        return(
          <div className={!showControlArrow(carouselItems) ? 'carousel-arrow-right disabled' : 'carousel-arrow-right'} onClick={onSetSelectedIndex.bind(null, (selectedIndex + 1))}>
            <img className="arrow-noshade" src="/static/img/mercedes/carousel_arrow_right.png" alt="Button Select Right"/>
          </div>
        );
    }
  } else {
    switch(side) {
      case "left":
        return(
          <div className={!showControlArrow(carouselItems) ? 'carousel-arrow-left disabled' :  'carousel-arrow-left'} onClick={onSetSelectedIndex.bind(null, (selectedIndex - 1))}>
            <div className="arrow-shade"></div><i className="fa fa-angle-left"></i>
          </div>
        );
      case "right":
        return(
          <div className={!showControlArrow(carouselItems) ? 'carousel-arrow-left disabled' :  'carousel-arrow-left'} onClick={onSetSelectedIndex.bind(null, (selectedIndex - 1))}>
            <div className="arrow-shade"></div><i className="fa fa-angle-left"></i>
          </div>
        );
    }
  }
};

const getCarouselItemClass = () => {
  switch (applicationStore.data.defaultTemplate) {
    case "mercedes":
    case "mercedes_2022":
    case "mercedestransition":
      return "10%";
    default:
      return "40px";
  }
};

const renderCarouselItemText = (carouselItem) => {
  var style = {
    position: "absolute",
    width: "auto",
    top: carouselItem.get('titleVAlign') === 'top' ? "40px" : (carouselItem.get('titleVAlign') === 'center' ? (window.innerWidth > 1024 ? "175px" : "40px") : "auto"),
    bottom: carouselItem.get('titleVAlign') === 'bottom' ? "40px" : "auto",
    left: carouselItem.get('titleHAlign') === 'left' ? getCarouselItemClass() : "20px",
    right: carouselItem.get('titleHAlign') === 'right' ? getCarouselItemClass() : "auto"
  };
  return (
    <div className="carousel-item-text" style={style}>
      {renderCarouselShade(carouselItem)}
      {renderTitle(carouselItem)}
      {renderSubtitle(carouselItem)}
      {renderLinkButton(carouselItem)}
    </div>
  );
};

export const renderCarouselShade = (carouselItem) => {
  var language = getAppLanguage();
  if (carouselItem.get('title').get(language) !== '' ||
    carouselItem.get('subtitle').get(language) !== '' ||
    carouselItem.get('linkText').get(language) !== '') {
    return <div className="carousel-shade"></div>;
  } else {
    return false;
  }
};

export const renderTitle = (carouselItem) => {
  var language = getAppLanguage();
  if (carouselItem.get('title') && carouselItem.get('title').get(language) !== '') {
    return (
      <h2>
        {carouselItem.get('title').get(language)}
      </h2>
    );
  } else {
    return false;
  }
};

export const renderSubtitle = (carouselItem) => {
  var language = getAppLanguage();
  if (carouselItem.get('subtitle') && carouselItem.get('subtitle').get(language) !== '') {
    return (
      <p>
        {carouselItem.get('subtitle').get(language)}
      </p>
    );
  } else {
    return false;
  }
};

export const renderLinkButton = (carouselItem) => {
  var language = getAppLanguage();
  if (carouselItem.get('linkUrl') && carouselItem.get('linkUrl').get(language) !== '') {
    return (
      <a href={carouselItem.get('linkUrl').get(language)} className="btn">
        {carouselItem.get('linkText') ? carouselItem.get('linkText').get(language) : ""}
      </a>
    );
  } else {
    return false;
  }
};

export const renderShowResultsDiv = (showDiv, success, userMessage) => {
  var dictionary = languageStore.getDictionary();
  if (showDiv) {
    if (success) {
      return (
        <div className="return-user-action-results success">
          <span className="stroke">{dictionary.successful}</span>
          <span>{userMessage}</span>
        </div>
      );
    } else {
      return (
        <div className="return-user-action-results failure">
          <span className="stroke">{dictionary.failure}</span>
          <span>{userMessage}</span>
        </div>
      );
    }
  } else {
    return false;
  }
};

export const renderCampaignDealerInfo = (isValidState) => {
  if (applicationStore.data.showCampaignDealerInfo && isValidState) {
    var className = Cx({
    "dealer-info-campaign": true,
      "homepage": applicationStore.data.routerState === RouterStates.HOMEPAGE
  }), dictionary = languageStore.getDictionary(), dealer = applicationStore.data.dealer, hours = '', dealerHourList = applicationStore.data.dealer ? applicationStore.data.dealer.get('hours') : null, isMazda = applicationStore.data.defaultTemplate === "mazda" || applicationStore.data.defaultTemplate === "mazdatransition";
    if (applicationStore.data.dealer && dealerHourList) {
      for (var i in dealerHourList) {
        if (i !== 'SUN') hours += decodeDayAbbrev(i)+" "+decodeStoreHours(dealerHourList[i].open, dealerHourList[i].close)+', ';
        else hours += decodeDayAbbrev(i)+" "+decodeStoreHours(dealerHourList[i].open, dealerHourList[i].close);
      }
    }
    return (
      <div className={className}>

        <div className="title-bar">
          <h2>{dealer.get('name') + " - " + dealer.get('address') + ", " + dealer.get('city') + ', ' + dealer.get('province') + ', ' + dealer.get('postalCode')}</h2>
          <div className="my-dealership">
            <i className="fa fa-check" />
            <span>{dictionary.campaign.myDealership}</span>
          </div>
        </div>

        <div className="dealer-contact">
          {dealer.get('email') ? <span>{dealer.get('email')}</span> : false}
          {dealer.get('phone') ? <span>{dealer.get('phone')}</span> : false}
        </div>

        {!isMazda ? <div className="dealer-hours">
          <h2>{dictionary.campaign.dealershipHours}</h2>
          <span>{hours}</span>
        </div> : false}

      </div>
    );
  } else {
    return false;
  }
};

export const renderSiteAdvancedFooter = () => {
  var language = getAppLanguage(), footerObject = applicationStore.data.dealerInfo ? applicationStore.data.dealerInfo.legalFooter : Immutable.List.of({ en: "", fr: "" }),
    footer = footerObject ? (typeof footerObject[language] !== 'undefined' ? footerObject[language] : footerObject.get(language)) : false;
  footer = _.trim(footer);
  if (globalState.select('applicationState', 'routerState').get() !== RouterStates.DEALER_SELECTION && sizeOf(footer) > 0) {

    return (
      <container className="footer">
        <box></box>
        <box className="text">
          <span className="text-small">{footer}</span>
        </box>
        <box></box>
      </container>
    );

  } else {
    return false;
  }
};

const onSeasonSelected = (season) => {
  console.log(season,'this is our season')
  applicationActions.setSelectedSeason(season);
};

const onBrandSelected = (brandId) => {
  console.log(brandId,'this is our brandID')
  applicationActions.setSelectedBrandId(brandId);
};

const onTiresizeSelected = (tiresize) => {
  if (isNationalSite()) {
    var baseUrl = '/national/' + applicationStore.data.nationalUrlCode;
    if (checkSiteHasCampaign()) baseUrl = window.location.origin + getNationalCampaignBaseUrl(applicationStore.data.nationalUrlCode);
    var url = baseUrl + '/' + getAppLanguage() + '/dealer/' + getAppDealerId() + '/search/' + SearchTypes.SEARCH_BY_TIRESIZE + '/' + tiresize;
    window.location.href = url;
  } else if (isDealerSite()) {
    var baseUrl =  '/' + applicationStore.data.dealerUrlCode;
    if (checkSiteHasCampaign()) baseUrl = window.location.origin + getDealerCampaignBaseUrl(applicationStore.data.dealerUrlCode);
    var url = baseUrl + '/' + getAppLanguage() + '/dealer/' + getAppDealerId() + '/search/' + SearchTypes.SEARCH_BY_TIRESIZE + '/' + tiresize;
    window.location.href = url;
  }
};

export const renderAlternateProductSelection = (featuredItems) => {
  return (
    <div className="div-homepage-selection">
      <div className="div-secondary-carousel">
        {featuredItems.map(function (featuredItem, index) {
          return (
            <div key={"secondary-carousel-"+index} className="featured-item" tabIndex={getTabIndex()}>

              <a href={featuredItem.get('linkUrl').get(getAppLanguage())} target={featuredItem.get('linkTarget')}>
                <div className="secondary-carousel-image">
                  <Image src={featuredItem.get('image').get(getAppLanguage())} alt="Image Carousel"/>
                </div>
                <div className="secondary-carousel-title">
                  <h4>{featuredItem.get('title').get(getAppLanguage())}</h4>
                </div>
                <div className="secondary-carousel-tag">
                  <p>{featuredItem.get('subtitle').get(getAppLanguage())}</p>
                </div>
              </a>

              <hr />

            </div>
          );
        }.bind(null)).toArray()}
      </div>
      <div className="div-product-type-selector">
        <ProductTypeSelectorRefactored />
      </div>
      <div className="div-secondary-carousel-mobile">
        {featuredItems.map(function (featuredItem, index) {
          return (
            <div key={"secondary-carousel-mobile-"+index} className="featured-item" tabIndex={getTabIndex()}>

              <a href={featuredItem.get('linkUrl').get(getAppLanguage())} target={featuredItem.get('linkTarget')}>
                <div className="secondary-carousel-image">
                  <Image src={featuredItem.get('image').get(getAppLanguage())} alt="Image Carousel"/>
                </div>
                <div className="secondary-carousel-title">
                  <h4>{featuredItem.get('title').get(getAppLanguage())}</h4>
                </div>
                <div className="secondary-carousel-tag">
                  <p>{featuredItem.get('subtitle').get(getAppLanguage())}</p>
                </div>
              </a>

            </div>
          );
        }.bind(null)).toArray()}
      </div>
    </div>
  );
};

export const renderTireRebates = (_this, rebates) => {
  return renderRebates(_this, ProductTypes.TIRES, rebates);
};

export const renderWheelRebates = (_this, rebates) => {
  return renderRebates(_this, ProductTypes.ALLOY_WHEELS, rebates);
};

export const renderAccessoryRebates = (_this, rebates, requiresOtherItems) => {
  return renderRebates(_this, ProductTypes.ACCESSORIES, rebates, requiresOtherItems);
};

export const renderPackageRebates = (_this, rebates) => {
  return renderRebates(_this, ProductTypes.PACKAGES, rebates);
};

export const renderRebates = (_this, productType, rebates, requiresOtherItems = false) => {
  var linkClass = getLinkClass(productType);
  return (
  <div className={getRebateClass(productType, rebates, requiresOtherItems)}>
    {rebates ? rebates.map(function (rebate, index) {
      var body = rebate.get('body') ? rebate.get('body').get(getAppLanguage()) : "";
      var text = rebate.get('text') ? rebate.get('text').get(getAppLanguage()) : "";
      var pricingContextId = rebate.get('pricingContextId');
      var disclaimer = rebate.get('disclaimer') ? rebate.get('disclaimer').get(getAppLanguage()) : "";
      var url = '#', hasUrl = false;
      if (rebate.get('url')) {
        url = rebate.get('url').get(getAppLanguage());
        if (!isEmpty(url) && url !== '// ') hasUrl = true;
      }
      if (text) {
        if (rebate.get('enableModal')) {
          return <a key={linkClass + index} className={hasUrl ? "badge has-link": "badge no-link"} title={body} onClick={_this.onOpenRebate.bind(_this, url, disclaimer, pricingContextId)}><span>{text}</span></a>;
        } else if (url !== '// ') {
          return <a key={linkClass + index} className={hasUrl ? "badge has-link": "badge no-link"} title={body} target="_blank" href={url}><span>{text}</span></a>;
        } else {
          return <a key={linkClass + index} className={hasUrl ? "badge has-link": "badge no-link"} title={body}><span>{text}</span></a>;
        }
      }
    }) : false}
  </div>
  );
};

const getRebateClass = function(productType, rebates, requiresOtherItems) {
  var rebateClass = rebateClassSwitch(productType), rebateCount = 0;
  rebates.map(function(rebate) {
    if (rebate.get('text').get(getAppLanguage()) !== "") {
      rebateCount++;
    }
  });
  if (requiresOtherItems && productType === ProductTypes.ACCESSORIES) rebateCount++;
  return rebateClass + rebateCount;
};

const rebateClassSwitch = function(productType) {
  switch (productType) {
    case ProductTypes.TIRES:
      return "tire-rebates rebates-";
    case ProductTypes.ALLOY_WHEELS:
      return "wheel-rebates rebates-";
    case ProductTypes.ACCESSORIES:
      return "accessory-rebates rebates-";
    case ProductTypes.PACKAGES:
      return "package-rebates rebates-";
  }
};

const getLinkClass = function(productType) {
  switch (productType) {
    case ProductTypes.TIRES:
      return "tire-rebate-";
    case ProductTypes.ALLOY_WHEELS:
      return "wheel-rebate-";
    case ProductTypes.ACCESSORIES:
      return "accessory-rebate-";
    case ProductTypes.PACKAGES:
      return "package-rebate-";
  }
};

export const renderSupportInfo = function(altimages = false) {

  var dictionary = languageStore.getDictionary();

  return(
    <div className="panel-container">

      {isAdministratorAuthentication() ? <div className="cms-entrance">
        <img className="cms-icon" src={altimages ? "/static/img/lock_white.png" : "/static/img/lock.png"} />
        <a className="cms-portal" href={applicationStore.getCmsLoginLink()} target="_blank">{dictionary.support.cmsPortalText}</a>
      </div> : false}

      <div className="support-info">

        <h2>{dictionary.support.title}</h2>
        <span className="hours-title">{dictionary.support.businessHours + ":"}</span>
        <div className="hours-section">
          <span className="hours-detail left">{dictionary.calendar.mondayLong+":"}</span> <span className="hours-detail right">{dictionary.support.tireWizardDayHours}</span>
          <span className="hours-detail left">{dictionary.calendar.tuesdayLong+":"}</span> <span className="hours-detail right">{dictionary.support.tireWizardDayHours}</span>
          <span className="hours-detail left">{dictionary.calendar.wednesdayLong+":"}</span> <span className="hours-detail right">{dictionary.support.tireWizardDayHours}</span>
          <span className="hours-detail left">{dictionary.calendar.thursdayLong+":"}</span> <span className="hours-detail right">{dictionary.support.tireWizardDayHours}</span>
          <span className="hours-detail left">{dictionary.calendar.fridayLong+":"}</span> <span className="hours-detail right">{dictionary.support.tireWizardDayHours}</span>
          <span className="hours-detail left">{dictionary.calendar.saturdayLong+":"}</span> <span className="hours-detail right">{dictionary.support.closed}</span>
          <span className="hours-detail left">{dictionary.calendar.sundayLong+":"}</span> <span className="hours-detail right">{dictionary.support.closed}</span>
        </div>

      </div>

      {
        altimages && !isUserLoggedIn() && (isDealerSite() || nationalSiteHasDealerSelected()) ?
      <div className="dealer-contact-info">
        <box>
          <box><column><img className="icon" src="/static/img/phone_white.png"/></column><column><text>{getDealerContactPhone()}</text></column></box>
          <box><column><img className="icon" src="/static/img/email_white.png"/></column><column><text>{getDealerContactEmail()}</text></column></box>
          <box><column><img className="icon" src="/static/img/website_white.png"/></column><column><text>{getDealerContactWebsite()}</text></column></box>
        </box>
      </div>
        :
      <div className="contact-info">
        <div className="phone-contact">
          <img className="icon" src={altimages ? "/static/img/phone_white.png" : "/static/img/phone_black.png"} />
          <div className="contact-info">
            <div className="top"><span className="label">{dictionary.support.phone + ": "}</span><span className="tag">{"(" + dictionary.support.tollFree + ")"}</span></div>
            <div className="bottom">{dictionary.support.tireWizardPhoneNumber}</div>
          </div>
        </div>
        <div className="email-contact">
          <img className="icon" src={altimages ? "/static/img/email_white.png" : "/static/img/email_black.png"} />
          <div className="contact-info">
            <div className="top"><span className="label">{dictionary.support.email + ":"}</span></div>
            <div className="bottom">{dictionary.support.tireWizardEmailAddress}</div>
          </div>
        </div>
        <div className="email-contact">
          <img className="icon" src={altimages ? "/static/img/website_white.png" : "/static/img/website_black.png"} />
          <div className="contact-info">
            <div className="top"><span className="label">{dictionary.support.website + ": "}</span></div>
            <div className="bottom">{dictionary.support.tireWizardWebsite}</div>
          </div>
        </div>
      </div>
      }


    </div>
  );

};

export const renderDealerSupportInfo = function() {

  if (isDealerSite() || nationalSiteHasDealerSelected()) {
    var dictionary = languageStore.getDictionary(), hours = getDealerContactHours();

    return(
      <div className="panel-container">

        <div className="support-info">
          <span className="hours-title">{dictionary.support.businessHours + ":"}</span>
          <div className="hours-section">
            <span className="hours-detail left">{dictionary.calendar.mondayLong+":"}</span> <span className="hours-detail right">{getFormattedStandardTime(hours.MON.open, hours.MON.close)}</span>
            <span className="hours-detail left">{dictionary.calendar.tuesdayLong+":"}</span> <span className="hours-detail right">{getFormattedStandardTime(hours.TUE.open, hours.TUE.close)}</span>
            <span className="hours-detail left">{dictionary.calendar.wednesdayLong+":"}</span> <span className="hours-detail right">{getFormattedStandardTime(hours.WED.open, hours.WED.close)}</span>
            <span className="hours-detail left">{dictionary.calendar.thursdayLong+":"}</span> <span className="hours-detail right">{getFormattedStandardTime(hours.THU.open, hours.THU.close)}</span>
            <span className="hours-detail left">{dictionary.calendar.fridayLong+":"}</span> <span className="hours-detail right">{getFormattedStandardTime(hours.FRI.open, hours.FRI.close)}</span>
            <span className="hours-detail left">{dictionary.calendar.saturdayLong+":"}</span> <span className="hours-detail right">{getFormattedStandardTime(hours.SAT.open, hours.SAT.close)}</span>
            <span className="hours-detail left">{dictionary.calendar.sundayLong+":"}</span> <span className="hours-detail right">{getFormattedStandardTime(hours.SUN.open, hours.SUN.close)}</span>
          </div>

        </div>

        <div className="dealer-contact-info">
          <box>
            <box><column><img className="icon" src="/static/img/phone_white.png"/></column><column><text>{getDealerContactPhone()}</text></column></box>
            <box><column><img className="icon" src="/static/img/email_white.png"/></column><column><text>{getDealerContactEmail()}</text></column></box>
            <box><column><img className="icon" src="/static/img/website_white.png"/></column><column><text>{getDealerContactWebsite()}</text></column></box>
          </box>
        </div>


      </div>
    );
  } else {
    return false;
  }

};

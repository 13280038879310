import { applicationStore } from "../../stores/ApplicationStore.js";

import { sizeOf, isEmpty, isConsumerSite } from "../../service/UtilityService";
import { getTestId, TEST_LIST } from "../../service/TestingService";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var Immutable = require("immutable");
var Cx = require("classnames");

export class FilterPanel extends I18nComponent {
  getInitialValue = () => {
    if (!isEmpty(this.props.initial)) {
      return [this.props.initial];
    } else if (this.props.enableShowAll) {
      return [null];
    } else {
      return [];
    }
  };

  componentDidUpdate(lastProps) {
    if (this.props.initial !== lastProps.initial) {
      this.setState({
        selected: !isEmpty(this.props.initial) ? [this.props.initial] : [],
      });
    }
  }

  render() {
    if (sizeOf(this.props.list) > 0) {
      return this.renderFilter();
    } else {
      return false;
    }
  }

  renderFilter = () => {
    var enableShowListCount = this.enableShowListCount();
    var enableSquareSelect = this.enableSquareSelect();

    var getFilterClassSet = this.getFilterClassSet;
    var getFilterBoxClass = this.getFilterBoxClass;
    var getColumnOnClick = this.getColumnOnClick;
    var isSelected = this.isSelected;

    var countList = this.props.countList;
    var single = this.props.single;
    var title = this.props.title;

    return (
      <box className="filter">
        <box className="title" onClick={this.toggleFilter}>
          <column>
            <h4
              id={getTestId(
                TEST_LIST.PRODUCT_LIST.FILTER.SECTION.TITLE,
                this.props.title
              )}
            >
              {this.props.title}
            </h4>
          </column>
          <right>
            <i
              id={getTestId(
                TEST_LIST.PRODUCT_LIST.FILTER.SECTION.TOGGLEARROW,
                this.props.title
              )}
              className={this.getToggleClass()}
              data-dtm={setAdobeLaunchDTM(
                "",
                ADOBELAUNCHER_DTM.OPTIONS.TIREWHEEL,
                applicationStore.data.productType
              )}
            />
          </right>
        </box>
        <box className="enhanced">
          <hr />
        </box>
        {this.state.showList ? (
          <box className="selectionbox">
            {this.renderToggleAll()}

            {Immutable.fromJS(this.props.list).map(function (data, index) {
              var classSet = getFilterClassSet(data),
                onClick = getColumnOnClick(data);
              if (
                enableShowListCount &&
                !isEmpty(countList) &&
                countList[parseInt(data.get("key"))] > 0
              ) {
                return (
                  <box
                    key={"select-" + index}
                    className={getFilterBoxClass(data)}
                  >
                    <column onClick={onClick}>
                      <i
                        className={classSet.icon}
                        data-dtm={setAdobeLaunchDTM(
                          "",
                          ADOBELAUNCHER_DTM.OPTIONS.TIREWHEELFILTER,
                          title
                        )}
                      />
                      {!enableSquareSelect && single && isSelected(data) ? (
                        <i className="fa fa-circle" />
                      ) : (
                        false
                      )}
                      <text
                        id={getTestId(
                          TEST_LIST.PRODUCT_LIST.FILTER.SECTION.SELECT,
                          title + "-" + data.get("value")
                        )}
                        className={classSet.text}
                      >
                        {data.get("value")}
                      </text>
                    </column>
                    <right className={classSet.right}>
                      <text className="count text-small">
                        {countList[parseInt(data.get("key"))]}
                      </text>
                    </right>
                  </box>
                );
              } else {
                return (
                  <box
                    key={"select-" + index}
                    className={getFilterBoxClass(data)}
                  >
                    <column onClick={onClick}>
                      <i
                        className={classSet.icon}
                        data-dtm={setAdobeLaunchDTM(
                          "",
                          ADOBELAUNCHER_DTM.OPTIONS.TIREWHEELFILTER,
                          title
                        )}
                      />
                      {!enableSquareSelect && single && isSelected(data) ? (
                        <i className="fa fa-circle" />
                      ) : (
                        false
                      )}
                      <text
                        id={getTestId(
                          TEST_LIST.PRODUCT_LIST.FILTER.SECTION.SELECT,
                          title + "-" + data.get("value")
                        )}
                        className={classSet.text}
                      >
                        {data.get("value")}
                      </text>
                    </column>
                  </box>
                );
              }
            })}
          </box>
        ) : (
          false
        )}
      </box>
    );
  };

  renderToggleAll = () => {
    if (!this.props.single) {
      return (
        <box className="select">
          <a
            id={getTestId(
              TEST_LIST.PRODUCT_LIST.FILTER.SECTION.SELECTALL,
              this.props.title
            )}
            className={setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.TEXT)}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.OPTIONS.TIREWHEELFILTER,
              this.props.title
            )}
            onClick={this.onSelectAll}
          >
            {this.t("product.filters.brand.selectAll")}
          </a>
          <span> / </span>
          <a
            id={getTestId(
              TEST_LIST.PRODUCT_LIST.FILTER.SECTION.DESELECTALL,
              this.props.title
            )}
            className={setAdobeLaunchClass("", ADOBELAUNCHER_CLASS.TEXT)}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.OPTIONS.TIREWHEELFILTER,
              this.props.title
            )}
            onClick={this.onDeselectAll}
          >
            {this.t("product.filters.brand.deselectAll")}
          </a>
        </box>
      );
    } else {
      return false;
    }
  };

  getColumnOnClick = (data) => {
    var shadowList = this.getShadowList();
    if (
      typeof shadowList !== "undefined" &&
      shadowList.indexOf(data.get("key")) === -1
    ) {
      return false;
    } else {
      return this.onSelect.bind(null, data.get("key"));
    }
  };

  getFilterClassSet = (data) => {
    var isSelected = this.isSelected(data);
    if (this.enableSquareSelect()) {
      return {
        icon: setAdobeLaunchClass(
          Cx({ fa: true, "fa-square": true, selected: isSelected }),
          ADOBELAUNCHER_CLASS.CHECKBOX
        ),
        text: Cx({ "text-small": sizeOf(data.get("value")) > 30 }),
        right: "fixmargin",
      };
    } else {
      if (this.props.single) {
        return {
          icon: setAdobeLaunchClass(
            "fa fa-circle-thin",
            ADOBELAUNCHER_CLASS.CHECKBOX
          ),
          text: Cx({
            super: true,
            bold: isSelected,
            "text-small": sizeOf(data.get("value")) > 30,
          }),
          right: "",
        };
      } else {
        return {
          icon: setAdobeLaunchClass(
            Cx({
              fa: true,
              "fa-check-square": isSelected,
              "fa-square": !isSelected,
              selected: isSelected,
            }),
            ADOBELAUNCHER_CLASS.CHECKBOX
          ),
          text: Cx({
            "text-small": sizeOf(data.get("value")) > 30,
            bold: sizeOf(data.get("value")) > 30 && isSelected,
          }),
          right: "fixmargin",
        };
      }
    }
  };

  getFilterBoxClass = (data) => {
    var shadowList = this.getShadowList(),
      countList = this.props.countList;
    return Cx({
      select: true,
      single: this.props.single,
      unselectable:
        !isConsumerSite() &&
        ((typeof shadowList !== "undefined" &&
          shadowList.indexOf(data.get("key")) === -1) ||
          (!isEmpty(countList) && countList[parseInt(data.get("key"))] == 0)),
    });
  };

  isSelected = (data) => {
    var selected = this.state.selected;
    if (sizeOf(selected) > 0) {
      for (var i in selected) {
        if (selected[i] == data.get("key")) {
          return true;
        }
      }
    }
    return false;
  };

  getToggleClass = () => {
    return setAdobeLaunchClass(
      Cx({
        fa: true,
        "fa-angle-down": this.state.showList,
        "fa-angle-right": !this.state.showList,
      }),
      ADOBELAUNCHER_CLASS.ICON
    );
  };

  getShadowList = () => {
    if (this.props.enableShowAll) {
      var temp = this.props.shadowList;
      // remove all the null values
      temp = temp.filter(function (n) {
        return n != undefined;
      });
      // push in one null value
      temp.push(null);

      return temp;
    } else {
      return this.props.shadowList;
    }
  };

  enableEnhancedFilter = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "gmcanada":
      case "honda":
      case "hyundai":
      case "mazda":
        return true;
      default:
        return false;
    }
  };

  enableShowListCount = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mazda":
        return this.props.countoverride;
      default:
        return !isConsumerSite();
    }
  };

  enableSquareSelect = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "honda":
        return true;
      default:
        return false;
    }
  };

  toggleFilter = () => {
    this.setState({
      showList: !this.state.showList,
    });
  };

  onSelectAll = () => {
    var temp = [],
      list = this.props.list;
    for (var i in list) {
      if (
        list[i] &&
        (typeof this.props.shadowList === "undefined" ||
          this.props.shadowList.indexOf(list[i].key) !== -1)
      ) {
        temp.push(list[i].key);
      }
    }
    this.setState({
      selected: temp,
    });
    this.props.onSelect(temp);
  };

  onDeselectAll = () => {
    this.setState({
      selected: [],
    });
    this.props.onSelect([]);
  };

  onSelect = (key) => {
    if (this.props.single) {
      this.setState({
        selected: [key],
      });
      this.props.onSelect(key);
    } else {
      var temp,
        selectedList = this.state.selected;
      if (!!selectedList && selectedList.indexOf(key) > -1) {
        temp = selectedList.filter(function (n) {
          if (n === key) {
            return false;
          } else {
            return n;
          }
        });
      } else {
        temp = selectedList;
        temp.push(key);
      }
      this.setState({
        selected: temp,
      });
      this.props.onSelect(temp);
    }
  };

  state = {
    showList: this.props.open === true,
    selected: this.getInitialValue(),
  };
}

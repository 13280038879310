import { i18nMixin } from "../../i18n/i18nMixin.js";

import { breadcrumbActions } from "../../actions/BreadcrumbActions.js";
import { vehicleSelectionActions } from "../../actions/VehicleSelectionActions.js";
import { vehicleSelectionStore } from "../../stores/VehicleSelectionStore.js";

import {
  getTabIndex,
  scrollToPageTop,
  getTrackingEventCategory,
} from "../../service/UtilityService";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";

import { RouterStates } from "../../constants/RouterStates.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var _ = require("lodash");
var React = require("react");

class VehicleSelectionYears extends I18nComponent {
  state = {
    vehicleYears: [],
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("Vehicle Select Year");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Vehicle Select Year Access"
    );
    vehicleSelectionStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    vehicleSelectionStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    //setTimeout(() => { vehicleSelectionActions.loadYears(); }, 250);
    vehicleSelectionActions.loadYears();
  }

  componentDidUpdate(lastProps, lastState) {
    scrollToPageTop(this.state.vehicleYears, lastState.vehicleYears);
  }

  render() {
    return (
      <div className="main">
        <div className="container">
          <ul className="select-simple year-selection">
            {_.map(
              this.state.vehicleYears,
              function (year, index) {
                return (
                  <li
                    key={year.id}
                    role="button"
                    tabIndex={getTabIndex()}
                    aria-label={
                      "Vehicle " +
                      this.t("quoteReport.vehicleYear") +
                      " " +
                      year.value
                    }
                    onKeyDown={(e) => this.onYearClick(year.id, year.value, e)}
                    onClick={this.onYearClick.bind(this, year.id, year.value)}
                  >
                    <a
                      aria-hidden="true"
                      className={setAdobeLaunchClass(
                        "",
                        ADOBELAUNCHER_CLASS.BUTTON.LINK
                      )}
                      data-dtm={setAdobeLaunchDTM(
                        "",
                        ADOBELAUNCHER_DTM.YEARSELECTION
                      )}
                    >
                      {year.value}
                    </a>
                  </li>
                );
              },
              this
            )}
          </ul>
        </div>
      </div>
    );
  }

  onChange = () => {
    this.setState({
      vehicleYears: vehicleSelectionStore.data.vehicleYears,
    });
  };

  onYearClick = (yearId, year, evt) => {
    checkForEvent: if ("undefined" != typeof evt.keyCode) {
      if (evt.keyCode == 13 || evt.keyCode == 32) {
        evt.preventDefault();
        evt.stopPropagation();
        break checkForEvent;
      }
      return;
    }
    vehicleSelectionActions.yearSelected(yearId);
    breadcrumbActions.pushState(RouterStates.YEAR, yearId, year, false, false);
  };
}

export default VehicleSelectionYears;

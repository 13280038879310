import { DispatcherActions } from '../constants/DispatcherActions.js'
import { AbstractStore } from './AbstractStore.js'

var ModalStore = AbstractStore.extend({

  constructor: function() {
    this.mounted = false;

    ModalStore.__super__.constructor.apply(this, arguments);
  },

  getActionHandlers: function() {
    var handlers = {};
    handlers[DispatcherActions.MODAL_MOUNTED] = this.onModalMounted;
    handlers[DispatcherActions.MODAL_UNMOUNTED] = this.onModalUnmounted;
    handlers[DispatcherActions.APPOINTMENT_FORM_SENT] = this.onAppointmentFormSent;

    return handlers;
  },

  onModalMounted: function() {
    this.mounted = true;

    this.emitChange();
  },

  onModalUnmounted: function() {
    this.mounted = false;

    this.emitChange();
  },

  onAppointmentFormSent: function() {
    this.mounted = false;

    this.emitChange();
  }

});

export var modalStore = new ModalStore();

import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { Image } from "../ux/Image.jsx";
import { Modal } from "../ux/Modal.jsx";

import { OrderTypes } from "../../constants/OrderTypes.js";

import { sizeOf, isEmpty } from "../../service/UtilityService.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");

export class OrderStatusReport extends I18nComponent {
  state = {
    orderStatus: applicationStore.data.orderStatus,
    orderStatusReportLoading: true,
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    if (!isEmpty(this.props.orderNumber)) {
      if (this.props.orderType === OrderTypes.DEALER_ORDER)
        setTimeout(() => {
          shoppingCartActions.getDealerOrderStatusForReport(
            this.props.orderId,
            this.props.orderNumber
          );
        }, 10);
      else if (this.props.orderType === OrderTypes.CONSUMER_ORDER)
        setTimeout(() => {
          shoppingCartActions.getConsumerOrderStatusForReport(
            this.props.orderId,
            this.props.orderNumber
          );
        }, 10);
    }
  }

  render() {
    // date ordered
    // billing address
    // product description
    // status
    // shipping method
    // source

    var orderStatus = this.state.orderStatus,
      orderList = [];
    if (orderStatus) {
      /*
			orderStatus = orderStatus.orderFulfillmentList;
			for (var n in orderStatus) {
				if (orderStatus[n].fulfillment_type === "ATD_ORDER_STATUS") {
					orderStatus = orderStatus[n];
					break;
        }
			}
			*/
      for (var i in orderStatus) {
        //console.log(orderStatus[i]);

        if (orderStatus[i]) {
          var className = "quote-report-table-row";
          if (i % 2 != 0) {
            className = "quote-report-table-row-odd";
          }

          var offset = -5.0;

          var date = new Date(
            orderStatus[i].datePlaced.replace("+0000", ".000Z")
          );
          var utc = date.getTime() + date.getTimezoneOffset() * 60000;
          date = new Date(utc + 3600000 * offset);

          orderList.push(
            <tr className={className} key={"order-status-row-" + i}>
              <td>{date.toLocaleDateString()}</td>
              <td>{orderStatus[i].description}</td>
              <td>{orderStatus[i].quantity}</td>
              <td>{orderStatus[i].sourceType}</td>
              <td>{orderStatus[i].sourceTypeDC}</td>
              <td>{orderStatus[i].status}</td>
              <td>{orderStatus[i].shipMethod}</td>
            </tr>
          );
        }
      }
    }

    return (
      <Modal requestUnmount={this.closeOrderStatusReport}>
        <div className="quote-report-header-bar order-status">
          {applicationStore.data.enableMercedesFeatures ? (
            <div onClick={this.closeOrderStatusReport}>
              <Image
                className="close-x"
                src="/static/img/mercedes/close.png"
                alt="Icon Close"
              />
            </div>
          ) : (
            <div onClick={this.closeOrderStatusReport}>
              <Image
                className="close-x"
                src="/static/img/close.png"
                alt="Icon Close"
              />
            </div>
          )}
        </div>
        <div className="quote-report order-status">
          <div className="quote-report-header"></div>
          {sizeOf(orderStatus) > 0 ? (
            <table className="quote-report-table">
              <thead>
                <tr className="quote-report-table-header">
                  <th>{this.t("quoteReport.orderStatusModal.datePlaced")}</th>
                  <th>{this.t("quoteReport.orderStatusModal.description")}</th>
                  <th>{this.t("quoteReport.orderStatusModal.quantity")}</th>
                  <th>{this.t("quoteReport.orderStatusModal.sourceType")}</th>
                  <th>{this.t("quoteReport.orderStatusModal.sourceTypeDC")}</th>
                  <th>{this.t("quoteReport.orderStatusModal.status")}</th>
                  <th>{this.t("quoteReport.orderStatusModal.shipMethod")}</th>
                </tr>
              </thead>

              <tbody>{orderList}</tbody>
            </table>
          ) : (
            false
          )}
          {sizeOf(orderStatus) == 0 && !this.state.orderStatusReportLoading ? (
            <div className="quote-report">
              <h1 className="quote-report-error">
                {this.t("quoteReport.missingOrAccessNotAllowMessage")}
              </h1>
            </div>
          ) : (
            false
          )}
          {sizeOf(orderStatus) == 0 && this.state.orderStatusReportLoading ? (
            <div className="quote-report">
              <div className="loading-page">
                <h1>{this.t("loadingPage")}</h1>
                <Image src="/static/img/loading_icon.gif" />
              </div>
            </div>
          ) : (
            false
          )}
        </div>
      </Modal>
    );
  }

  closeOrderStatusReport = () => {
    this.props.closeReport();
  };

  onChange = () => {
    this.setState({
      orderStatus: applicationStore.data.orderStatus,
      quoteReportLoading: applicationStore.isApiCallInProgress(),
    });
  };
}

import { applicationStore } from "../../stores/ApplicationStore.js";
import { applicationActions } from "../../actions/ApplicationActions.js";
import { productListActions } from "../../actions/ProductListActions.js";

import { ProductTypes } from "../../constants/ProductTypes.js";

import { isUserLoggedIn } from "../../api/AuthService";
import { inivisCaptchaOnComponentDidUpdate } from "../../service/CaptchaService";
import {
  getTabIndex,
  getTrackingEventCategory,
  getTrackingEventObject,
  scrollTop,
} from "../../service/UtilityService";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";
import { switchAccessoryView } from "../../redux/features/productList.js";
import { connect } from "react-redux";
import { searchProductType } from "../../redux/features/searchTires";
import { routerState } from "../../redux/features/routes";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var _ = require("lodash");
var Cx = require("classnames");

const AccessoryViewer = {
  TILE_VIEW: "view-accessories-list",
  TABLE_VIEW: "view-accessories-table",
};

class ProductTypeSelector extends I18nComponent {
  state = {
    productTypes: applicationStore.data.productTypes,
  };

  static defaultProps = {
    landingpageTile: false,
  };

  updateState = (state) => {
    this.setState(state);
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
  }

  componentDidUpdate(nextProps, lastState) {
    var callbackList = [];
    for (var i in this.state.productTypes) {
      switch (this.state.productTypes[i]) {
        case ProductTypes.TIRES:
          callbackList.push(this.clickTires);
          break;
        case ProductTypes.ALLOY_WHEELS:
          callbackList.push(this.clickAlloyWheels);
          break;
        case ProductTypes.ACCESSORIES:
          if (isUserLoggedIn()) {
            callbackList.push(this.clickAccessories.bind(this, true));
            callbackList.push(this.clickAccessories.bind(this, false, true));
          } else {
            callbackList.push(this.clickAccessories);
          }
          break;
        case ProductTypes.PACKAGES:
          callbackList.push(this.clickPackages);
          break;
      }
    }
    inivisCaptchaOnComponentDidUpdate(
      this.state,
      this.updateState,
      callbackList
    );
  }

  render() {
    var productSelectorClass = Cx({
      "select-product-type": true,
      "has-launcher": applicationStore.data.enableHomepageLauncher,
      "float-launcher":
        applicationStore.data.homepageLauncherFloatOverCarousel === "LEFT" ||
        applicationStore.data.homepageLauncherFloatOverCarousel === "RIGHT",
      "float-launcher locked": applicationStore.data.homepageLauncherLockToPage,
    });
    return (
      <box className={productSelectorClass}>
        <container>{this.renderProductTileContent()}</container>
      </box>
    );
  }

  renderProductTileContent = () => {
    return _.map(
      this.state.productTypes,
      (productType, index) => {
        switch (productType) {
          case ProductTypes.TIRES:
            return (
              <box
                className="show-focus"
                role="button"
                tabIndex={getTabIndex()}
                key={"homepage-select-" + productType}
                onClick={() => this.clickTires()}
                onKeyDown={(e) => this.selectTires(e)}
              >
                <box
                  className={setAdobeLaunchClass(
                    "product-selector",
                    ADOBELAUNCHER_CLASS.IMAGE
                  )}
                  data-dtm={setAdobeLaunchDTM(
                    "",
                    ADOBELAUNCHER_DTM.PRODUCTSELECTTILE
                  )}
                  alt={this.t("common.viewTires")}
                >
                  <i className="tires"></i>
                  <h2 className="product-type-text-alt">
                    {this.t("productTypes.tiresAlt")}
                  </h2>
                  <button
                    className={setAdobeLaunchClass(
                      "btn",
                      ADOBELAUNCHER_CLASS.BUTTON.LINK
                    )}
                    data-dtm={setAdobeLaunchDTM(
                      "",
                      ADOBELAUNCHER_DTM.PRODUCTSELECTTILE
                    )}
                  >
                    {this.t("common.viewTires")}
                  </button>
                </box>
              </box>
            );
          case ProductTypes.ALLOY_WHEELS:
            return (
              <box
                className="show-focus"
                role="button"
                tabIndex={getTabIndex()}
                key={"homepage-select-" + productType}
                onClick={this.clickAlloyWheels}
                onKeyDown={(e) => this.selectAlloyWheels(e)}
              >
                <box
                  className={setAdobeLaunchClass(
                    "product-selector",
                    ADOBELAUNCHER_CLASS.IMAGE
                  )}
                  data-dtm={setAdobeLaunchDTM(
                    "",
                    ADOBELAUNCHER_DTM.PRODUCTSELECTTILE
                  )}
                  alt={this.t("common.viewWheels")}
                >
                  <i className="wheels"></i>
                  <h2 className="product-type-text-alt">
                    {this.t("productTypes.wheelsAlt")}
                  </h2>
                  <button
                    className={setAdobeLaunchClass(
                      "btn",
                      ADOBELAUNCHER_CLASS.BUTTON.LINK
                    )}
                    data-dtm={setAdobeLaunchDTM(
                      "",
                      ADOBELAUNCHER_DTM.PRODUCTSELECTTILE
                    )}
                  >
                    {this.t("common.viewWheels")}
                  </button>
                </box>
              </box>
            );
          case ProductTypes.ACCESSORIES:
            return this.renderAccessoryTile(productType, index);
          case ProductTypes.PACKAGES:
            return (
              <box
                className="show-focus"
                role="button"
                tabIndex={getTabIndex()}
                key={"homepage-select-" + productType}
                onClick={this.clickPackages}
                onKeyDown={(e) => this.selectPackages(e)}
              >
                <box
                  className={setAdobeLaunchClass(
                    "product-selector",
                    ADOBELAUNCHER_CLASS.IMAGE
                  )}
                  data-dtm={setAdobeLaunchDTM(
                    "",
                    ADOBELAUNCHER_DTM.PRODUCTSELECTTILE
                  )}
                  alt={this.t("common.viewPackages")}
                >
                  <i className="packages"></i>
                  <h2 className="product-type-text-alt">
                    {this.t("productTypes.packagesAlt")}
                  </h2>
                  <button
                    className={setAdobeLaunchClass(
                      "btn",
                      ADOBELAUNCHER_CLASS.BUTTON.LINK
                    )}
                    data-dtm={setAdobeLaunchDTM(
                      "",
                      ADOBELAUNCHER_DTM.PRODUCTSELECTTILE
                    )}
                  >
                    {this.t("common.viewPackages")}
                  </button>
                </box>
              </box>
            );
        }
      },
      this
    );
  };

  renderAccessoryTile = (productType, index) => {
    if (isUserLoggedIn()) {
      return (
        <box
          className="show-focus"
          role="button"
          tabIndex={getTabIndex()}
          key={"homepage-select-" + productType}
        >
          <box
            className={setAdobeLaunchClass(
              "product-selector",
              ADOBELAUNCHER_CLASS.IMAGE
            )}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.PRODUCTSELECTTILE
            )}
            alt={this.t("common.viewAccessories")}
          >
            <i className="accessories"></i>
            <h2 className="product-type-text-alt">
              {this.t("productTypes.accessoriesAlt")}
            </h2>
            <button
              className={setAdobeLaunchClass(
                "btn list-view-btn",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                ADOBELAUNCHER_DTM.PRODUCTSELECTTILE
              )}
              onClick={this.clickAccessories.bind(this, true)}
            >
              {this.t("product.listView")}
            </button>
            <button
              className={setAdobeLaunchClass(
                "btn list-view-btn",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                ADOBELAUNCHER_DTM.PRODUCTSELECTTILE
              )}
              onClick={this.clickAccessories.bind(this, false, true)}
            >
              {this.t("common.viewAccessories")}
            </button>
          </box>
        </box>
      );
    } else {
      return (
        <box
          className="show-focus"
          role="button"
          tabIndex={getTabIndex()}
          key={"homepage-select-" + productType}
          onClick={this.clickAccessories}
          onKeyDown={(e) => this.selectAccessories(e)}
        >
          <box
            className={setAdobeLaunchClass(
              "product-selector",
              ADOBELAUNCHER_CLASS.IMAGE
            )}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.PRODUCTSELECTTILE
            )}
            alt={this.t("common.viewAccessories")}
          >
            <i className="accessories"></i>
            <h2 className="product-type-text-alt">
              {this.t("productTypes.accessoriesAlt")}
            </h2>
            <button
              className={setAdobeLaunchClass(
                "btn",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                ADOBELAUNCHER_DTM.PRODUCTSELECTTILE
              )}
            >
              {this.t("common.viewAccessories")}
            </button>
          </box>
        </box>
      );
    }
  };

  clickTires = () => {
    if (this.props.landingpageTile) {
      scrollTop();
      this.props.setYearSelect(true);
    } else {
      if (!applicationStore.data.loadSearchModalFromProductTypeSelection) {
        googleAnalyticsEnhancedTrackEvent(
          getTrackingEventCategory(),
          "Product Tile Selection - ProductSelected",
          getTrackingEventObject({ type: ProductTypes.TIRES })
        );
        // event.stopPropagation();
        this.props.selectProductType(ProductTypes.TIRES);
        applicationActions.productTypeSelected(ProductTypes.TIRES);
      } else {
        applicationActions.activateSearchFlag();
      }
    }
  };

  clickAlloyWheels = () => {
    if (this.props.landingpageTile) {
      scrollTop();
      this.props.setYearSelect(true);
    } else {
      if (!applicationStore.data.loadSearchModalFromProductTypeSelection) {
        googleAnalyticsEnhancedTrackEvent(
          getTrackingEventCategory(),
          "Product Tile Selection - ProductSelected",
          getTrackingEventObject({ type: ProductTypes.ALLOY_WHEELS })
        );
        this.props.selectProductType(ProductTypes.ALLOY_WHEELS);
        applicationActions.productTypeSelected(ProductTypes.ALLOY_WHEELS);
      } else {
        applicationActions.activateSearchFlag();
      }
    }
  };

  clickAccessories = (withListView = false, withGridView = false) => {
    if (this.props.landingpageTile) {
      scrollTop();
      this.props.setYearSelect(true);
    } else {
      if (withListView) {
        this.props.switchAccessoryView(AccessoryViewer.TABLE_VIEW);
        setTimeout(() => {
          this.launchAccessories();
        }, 100);
      } else if (withGridView) {
        this.props.switchAccessoryView(AccessoryViewer.TABLE_VIEW);
        setTimeout(() => {
          this.launchAccessories();
        }, 100);
      } else {
        this.launchAccessories();
      }
    }
  };

  launchAccessories = () => {
    if (this.props.landingpageTile) {
      scrollTop();
      this.props.setYearSelect(true);
    } else {
      if (!applicationStore.data.loadSearchModalFromProductTypeSelection) {
        googleAnalyticsEnhancedTrackEvent(
          getTrackingEventCategory(),
          "Product Tile Selection - ProductSelected",
          getTrackingEventObject({ type: ProductTypes.ACCESSORIES })
        );
        applicationActions.productTypeSelected(ProductTypes.ACCESSORIES);
      } else {
        applicationActions.activateSearchFlag();
      }
    }
  };

  clickPackages = () => {
    if (this.props.landingpageTile) {
      scrollTop();
      this.props.setYearSelect(true);
    } else {
      if (!applicationStore.data.loadSearchModalFromProductTypeSelection) {
        googleAnalyticsEnhancedTrackEvent(
          getTrackingEventCategory(),
          "Product Tile Selection - ProductSelected",
          getTrackingEventObject({ type: ProductTypes.PACKAGES })
        );
        applicationActions.productTypeSelected(ProductTypes.PACKAGES);
      } else {
        applicationActions.activateSearchFlag();
      }
    }
  };

  // AODA: Navigate on Space or Enter keys
  selectTires = (e) => {
    if (e.keyCode == 13 || e.keyCode == 32) this.clickTires();
  };

  selectAlloyWheels = (e) => {
    if (e.keyCode == 13 || e.keyCode == 32) this.clickAlloyWheels();
  };

  selectAccessories = (e) => {
    if (e.keyCode == 13 || e.keyCode == 32) this.clickAccessories();
  };

  selectPackages = (e) => {
    if (e.keyCode == 13 || e.keyCode == 32) this.clickPackages();
  };

  onChange = () => {
    this.setState({
      productTypes: applicationStore.data.productTypes,
    });
  };
}

const mapStateToProps = (state) => {
  return {
    state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    switchAccessoryView(viewId) {
      dispatch(switchAccessoryView(viewId));
    },
    selectProductType(productType) {
      dispatch(searchProductType(productType));
    },
    setYearSelect(yearSelect) {
      dispatch(routerState(yearSelect));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductTypeSelector);

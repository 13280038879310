import { DispatcherActions } from '../constants/DispatcherActions.js'
import { AbstractStore } from './AbstractStore.js'

var Immutable = require('immutable');

var HomepageStore = AbstractStore.extend({

  constructor: function() {
    this.data = {
      carouselItems: Immutable.List.of(),
      featuredItems: Immutable.List.of()
    };

    HomepageStore.__super__.constructor.apply(this, arguments);
  },

  getActionHandlers: function() {
    var handlers = {};
    handlers[DispatcherActions.CAROUSEL_DATA_LOADED] = this.onCarouselDataLoaded;
    return handlers;
  },

  onCarouselDataLoaded: function(carouselData) {
    this.data.carouselItems = Immutable.fromJS(carouselData.home_primary).map(function(item) {
      return Immutable.Map({
        time: parseInt(item.get('rotationHoldDelayMS'), 10),
        image: item.get('imageURL'),
        title: item.get('title'),
        titleHAlign: item.get('titleHAlign'),
        titleVAlign: item.get('titleVAlign'),
        subtitle: item.get('subtitle'),
        linkText: item.get('linkText'),
        linkBody: item.get('linkBody'),
        linkUrl: item.get('linkURL'),
        linkHAlign: item.get('linkHAlign'),
        linkVAlign: item.get('linkVAlign')
      });
    });

    this.data.featuredItems = Immutable.fromJS(carouselData.home_secondary).map(function(item) {
      return Immutable.Map({
        title: item.get('title'),
        image: item.get('imageURL'),
        subtitle: item.get('subtitle'),
        linkUrl: item.get('linkURL'),
        linkText: item.get('linkText'),
        linkTarget: item.get('linkTarget')
      });
    });

    this.emitChange();
  }

});

export var homepageStore = new HomepageStore();

import { Modal } from "../ux/Modal.jsx";

import { applicationStore } from "../../stores/ApplicationStore.js";
import { applicationActions } from "../../actions/ApplicationActions.js";

import { EmailField } from "../ux/forms/EmailField.jsx";
import { TextField } from "../ux/forms/TextField.jsx";
import { PasswordField } from "../ux/forms/PasswordField.jsx";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var _ = require("lodash");

export class GuestAccountCreationModal extends I18nComponent {
  state = {
    dealerUrlCode: this.getInitialFieldValue(),
    emailAddress: this.getInitialFieldValue(),
    password: this.getInitialFieldValue(),
    passwordConfirm: this.getInitialFieldValue(),
    returnMessage: applicationStore.data.guestAccountCreationReturnMessage,
    creationSuccessful: applicationStore.data.guestAccountCreationSuccessful,
    modalMessage: false,
  };

  getInitialFieldValue = () => {
    return {
      value: null,
      touched: false,
      forceTouch: false,
      valid: false,
    };
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
  }

  render() {
    return (
      <Modal
        refactor={true}
        useShortHeaderBar={true}
        requestUnmount={this.onRequestCloseModal}
      >
        <div className="wrapper">
          <div className="modal-content guest-account-creation">
            <form onSubmit={this.onFormSubmit} className="contact-form">
              <span className="title">
                {this.t("guestAccountCreation.createGuestAccount")}
              </span>
              <TextField
                onChange={this.onChangeDealerUrl}
                id="dealerUrlField"
                label={this.t("guestAccountCreation.dealerUrlCode")}
                touched={this.state.dealerUrlCode.forceTouch}
              />
              <EmailField
                onChange={this.onChangeEmail}
                id="userEmailField"
                label={this.t("guestAccountCreation.emailAddress")}
                touched={this.state.emailAddress.forceTouch}
              />
              <PasswordField
                onChange={this.onPasswordChange}
                label={this.t("guestAccountCreation.password")}
                id="passwordField-unq"
                errorNotEmpty={this.t("validation.notEmpty")}
                touched={this.state.password.forceTouch}
              />
              <PasswordField
                onChange={this.onPasswordConfirmChange}
                label={this.t("guestAccountCreation.passwordConfirm")}
                id="passwordConfirmField"
                errorNotEmpty={this.t("validation.notEmpty")}
                touched={this.state.passwordConfirm.forceTouch}
              />
              {this.state.modalMessage ? (
                <span className="form-report">{this.state.modalMessage}</span>
              ) : (
                false
              )}
              {this.state.returnMessage ? (
                <span className="form-return">{this.state.returnMessage}</span>
              ) : (
                false
              )}
              {this.state.creationSuccessful ? (
                <div>
                  <p>
                    <a onClick={this.onRequestCloseModal}>
                      {this.t("guestAccountCreation.clickHere")}
                    </a>
                    {this.t("guestAccountCreation.successMessage")}
                  </p>
                </div>
              ) : (
                false
              )}
              <div className="button-container">
                <button type="submit" className="btn">
                  {this.t("guestAccountCreation.create")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }

  onChangeDealerUrl = (value, valid) => {
    this.setState({
      dealerUrlCode: {
        value: value,
        valid: valid,
        touched: true,
      },
    });
  };

  onChangeEmail = (value, valid) => {
    this.setState({
      emailAddress: {
        value: value,
        valid: valid,
        touched: true,
      },
    });
  };

  onPasswordChange = (value, valid) => {
    this.setState({
      password: {
        value: value,
        valid: valid,
        touched: true,
      },
    });
  };

  onPasswordConfirmChange = (value, valid) => {
    if (this.state.password.value == value) {
      this.state.modalMessage = false;
      this.setState({
        passwordConfirm: {
          value: value,
          valid: valid,
          touched: true,
        },
      });
    } else {
      this.state.modalMessage = (
        <p className="guest-account-creation-error">
          {this.t("guestAccountCreation.passwordDoesNotMatch")}
        </p>
      );
      this.setState({
        passwordConfirm: {
          value: value,
          valid: false,
          touched: true,
        },
      });
    }
  };

  onFormSubmit = (ev) => {
    if (this.canBeSubmitted()) {
      applicationActions.submitGuestAccountCreation(
        this.state.dealerUrlCode.value,
        this.state.emailAddress.value,
        this.state.password.value,
        this.state.passwordConfirm.value
      );
    } else {
      this.state.modalMessage = (
        <p className="guest-account-creation-error">
          {this.t("guestAccountCreation.dataMissingOrIncorrect")}
        </p>
      );
    }

    ev.preventDefault();
    ev.stopPropagation();
  };

  canBeSubmitted = () => {
    return (
      this.state.dealerUrlCode.touched &&
      this.state.dealerUrlCode.valid &&
      this.state.emailAddress.touched &&
      this.state.emailAddress.valid &&
      this.state.password.touched &&
      this.state.password.valid &&
      this.state.passwordConfirm.touched &&
      this.state.passwordConfirm.valid
    );
  };

  onRequestCloseModal = () => {
    applicationActions.closeGuestAccountCreationModal();
  };

  onChange = () => {
    this.setState({
      returnMessage: applicationStore.data.guestAccountCreationReturnMessage,
      creationSuccessful: applicationStore.data.guestAccountCreationSuccessful,
    });
  };
}

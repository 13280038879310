import { FieldPrototype } from "./FieldPrototype.jsx";

var _ = require("lodash");

export class EmailField extends FieldPrototype {
  static defaultProps = {
    errorNotEmpty: "Field is required therefore can't be empty",
    errorInvalidEmail: "Provided e-mail address is invalid",
  };

  getFieldType = () => {
    return "email";
  };

  hasValue = (val) => {
    var result = _.size(val) > 0;
    return result ? [] : [this.props.errorNotEmpty];
  };

  isValid = (val) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var result = re.test(val);
    return result ? [] : [this.props.errorInvalidEmail];
  };
}

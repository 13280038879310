import { globalState } from '../GlobalState.js';

import { applicationStore } from '../stores/ApplicationStore.js';
import { productListStore } from '../stores/ProductListStore.js';
import { breadcrumbsStore } from '../stores/BreadcrumbsStore.js';
import { languageStore } from '../stores/LanguageStore.js';
import { searchStore } from '../stores/SearchStore.js';
import { vehicleSelectionStore } from '../stores/VehicleSelectionStore.js';

import { applicationActions } from '../actions/ApplicationActions.js';

import { ProductTypes } from '../constants/ProductTypes.js';
import { RouterStates } from '../constants/RouterStates.js';
import { SearchTypes } from '../constants/SearchTypes.js';
import { CarouselPlayerTypes } from '../constants/CarouselPlayerTypes.js';

import { getDestinationInverse } from '../api/RequestBuilderHelper';
import { buildFilter } from '../service/ProductList';
import { isEmpty, sizeOf, checkSiteHasCampaign, getAppDealerId } from '../service/UtilityService';
import { VehicleSelectionSkippingService } from '../service/VehicleSelectionSkippingService';

import { get } from '../api/ApiService';
import { getToken } from "../api/AuthService";
import { dealerIgnoreAccessoryStock, dealerIgnoreMerchandiseStock, dealerIgnorePackageStock, dealerIgnoreTireStock, dealerIgnoreWheelStock,setPartQuantityToOne } from '../redux/features/applicationInfo.js';
import store from '../stores/configureStore.js';

var Immutable = require('immutable');
var GoogleMapsAPI = window.google.maps;

const SCREENMODES = {
  REGULAR: 'regular',
  IFRAME: 'iframe',
  GUIDE: 'guide',
};

export const loadDealerConfig = (response) => {
  window.dealerAccountInfoPayload = response;

  if (response.dealerIframeHiddenHeader) {
    applicationStore.data.screenMode = window.location !== window.parent.location ? SCREENMODES.IFRAME : SCREENMODES.REGULAR;
  }

  applicationStore.data.productTypes = [];
  applicationStore.data.productListProductTypes = [];

  if (response.dealerEnableTireSearch) {
    applicationStore.data.productTypes.push(ProductTypes.TIRES);
    applicationStore.data.productListProductTypes.push(ProductTypes.TIRES);
  }
  if (response.dealerEnableWheelSearch) {
    applicationStore.data.productTypes.push(ProductTypes.ALLOY_WHEELS);
    applicationStore.data.productListProductTypes.push(ProductTypes.ALLOY_WHEELS);
  }
  if (response.dealerEnableAccessorySearch) {
    applicationStore.data.productTypes.push(ProductTypes.ACCESSORIES);
    applicationStore.data.productListProductTypes.push(ProductTypes.ACCESSORIES);
  }
  if (response.dealerEnablePackageSearch) {
    applicationStore.data.productTypes.push(ProductTypes.PACKAGES);
    applicationStore.data.productListProductTypes.push(ProductTypes.PACKAGES);
  }

  applicationStore.data.dealerSite = true;
  applicationStore.data.nationalSite = false;
  applicationStore.data.groupSite = false;
  applicationStore.data.consumerSite = false;
  applicationStore.data.dealerAccountId = null;
  applicationStore.data.nationalAccountId = response.dealerNationalAccountId;
  applicationStore.data.groupAccountId = null;
  applicationStore.data.consumerAccountId = null;
  applicationStore.data.defaultTemplate = response.dealerDefaultTemplate;
  applicationStore.data.dealerUrlCode = response.dealerUrlCode;
  applicationStore.data.nationalUrlCode = response.nationalAccountUrlCode;
  applicationStore.data.groupUrlCode = null;
  applicationStore.data.dealerId = response.dealerId;
  applicationStore.data.dealerAppointmentLeadTime = response.dealerAppointmentLeadTime;
  applicationStore.data.minimumYears[ProductTypes.TIRES] = response.minimumTireYear;
  applicationStore.data.minimumYears[ProductTypes.PACKAGES] = response.minimumPackageYear;
  applicationStore.data.minimumYears[ProductTypes.ACCESSORIES] = response.minimumAccessoryYear;
  applicationStore.data.minimumYears[ProductTypes.ALLOY_WHEELS] = response.minimumWheelYear;
  applicationStore.data.carousel = response.dealerEnableHomepageCarousel === true;
  applicationStore.data.siteDisabled = response.dealerEnableRetailSite !== true;
  applicationStore.data.dealerEnableAdvancedSearchModal = response.dealerEnableAdvancedSearchModal === true;
  applicationStore.data.dealerWinterTireSpeedRatingOffset = parseInt(response.dealerWinterTireSpeedRatingOffset, 10);
  applicationStore.data.dealerAllSeasonTireSpeedRatingOffset = parseInt(response.dealerAllSeasonTireSpeedRatingOffset, 10);
  applicationStore.data.dealerSummerTireSpeedRatingOffset = parseInt(response.dealerSummerTireSpeedRatingOffset, 10);

  applicationStore.data.enableLandingpageDealerSelection = false;
  applicationStore.data.enableHomepagePackages = response.dealerEnableHomepagePackageModule;

  applicationStore.data.dealerEnableCustomerLogin = response.dealerEnableMembershipPricing === "1";

  applicationStore.data.dealerEnableBookingSystem = response.dealerEnableBookingSystem;

  applicationStore.data.enableFinancing = response.enableFinancing === "0" ? false : response.enableFinancing;
  applicationStore.data.financingUrl = response.financingUrl;

  applicationStore.data.quoteImmediately = response.dealerEnablePopUpQuote;
  applicationStore.data.dealerEnableCustomerChat = response.dealerEnableLiveChat;
  applicationStore.data.dealerEnableWheelTireInstallationLabourPrice = response.dealerEnableWheelTireInstallationLabourPrice;
  applicationStore.data.dealerNumberOfProductsToShowBeforePagination = response.dealerNumberOfProductsToShowBeforePagination;

  applicationStore.data.legalFooter = response.legalFooter;
  applicationStore.data.useAdvancedFooter = response.dealerEnableAdvancedFooter;
  applicationStore.data.fullAdvancedFooter = response.dealerEnableShowHomepageFooterCatalogue;

  applicationStore.data.dealerEnableShowPricingLastUpdate = response.dealerEnableShowPricingLastUpdate === "1";
  applicationStore.data.dealerEnableXtimeAppointmentIntegration = response.dealerEnableXtimeAppointmentIntegration;
  applicationStore.data.dealerEnablePromptToOrderTires = response.dealerEnablePromptToOrderTires;

  applicationStore.data.dealerTaxPercent = response.dealerTaxPercent;
  applicationStore.data.defaultLanguage = response.dealerDefaultLanguage;
  applicationStore.data.allowedLanguages = response.dealerLanguageSet;
  applicationStore.data.originalSeason = response.dealerDefaultSearchSeason;
  store.dispatch(setPartQuantityToOne(response.dealerRetailSiteDefaultPartQuantityFromVehicle === false))
  // applicationStore.data.setPartQuantityToOne = response.dealerRetailSiteDefaultPartQuantityFromVehicle === false;

  applicationStore.data.dealerEnableStockForCustomers = response.dealerEnableStockForCustomers === true;
  
  store.dispatch(dealerIgnoreTireStock(response.dealerIgnoreTireStock === true));
  store.dispatch(dealerIgnoreWheelStock(response.dealerIgnoreWheelStock === true));
  store.dispatch(dealerIgnoreAccessoryStock(response.dealerIgnoreAccessoryStock === true));
  store.dispatch(dealerIgnorePackageStock(response.dealerIgnorePackageStock === true));
  store.dispatch(dealerIgnoreMerchandiseStock(response.dealerIgnoreMerchandiseStock === true));
  
  
  
  // applicationStore.data.dealerIgnoreTireStock = response.dealerIgnoreTireStock === true;
  // applicationStore.data.dealerIgnoreWheelStock = response.dealerIgnoreWheelStock === true;
  // applicationStore.data.dealerIgnoreAccessoryStock = response.dealerIgnoreAccessoryStock === true;
  // applicationStore.data.dealerIgnorePackageStock = response.dealerIgnorePackageStock === true;
  // applicationStore.data.dealerIgnoreMerchandiseStock = response.dealerIgnoreMerchandiseStock === true;

  applicationStore.data.dealerAllowNtdStock = response.supportConsumerOrder || response.supportDealerOrder;
  applicationStore.data.dealerAllowNtdStockOrder = response.supportDealerOrder;
  applicationStore.data.dealerAllowNtdConsumerPurchase = response.supportConsumerOrder && !response.supportDealerOrder;
  applicationStore.data.dealerAtdConnectCustomerNumber = '';
  applicationStore.data.dealerAtdConnectLocationCode = '';

  applicationStore.data.dealerAllowNTDStockBar = response.dealerAtdConnectIntegrationEnabled;

  applicationStore.data.dealerEnableCaptcha = response.dealerEnableCaptcha;
  applicationStore.data.dealerAppointmentIntegrationId = response.dealerAppointmentIntegrationId;

  applicationStore.data.enableAccessoryViewSwitch = true;

  applicationStore.data.supportDealerOrder = response.supportDealerOrder;
  applicationStore.data.supportUserDealerOrder = response.supportUserDealerOrder !== "NONE" && response.supportUserDealerOrder !== false;
  applicationStore.data.supportConsumerOrder = response.supportConsumerOrder;
  applicationStore.data.supportUserConsumerOrder = response.supportUserConsumerOrder !== "NONE" && response.supportUserConsumerOrder !== false;

  getDealerPublicPricing(response);

  applicationStore.data.enableHomepageLauncher = response.dealerEnableShowHomepageWidget === true;
  applicationStore.data.enableHomepagePanelLauncher = response.dealerDefaultTemplate === "volkswagen" || response.dealerDefaultTemplate === "volkswagen_2020";
  applicationStore.data.homepageLauncherFloatOverCarousel = response.dealerRetailSiteHomeWidgetPlacement;//values: "TOP", "RIGHT", "LEFT", "BOTTOM"
  applicationStore.data.homepageLauncherLockToPage = response.dealerRetailSiteHomeWidgetPlacement === "BESIDE_LEFT";
  applicationStore.data.loadSearchModalFromProductTypeSelection = response.dealerRetailSiteHomeProductTileSearchPath === "MODAL";

  applicationStore.data.disableHomepage = response.dealerRetailSiteStartPage !== 'HOME';
  applicationStore.data.defaultProductType = response.dealerRetailSiteStartPage !== 'HOME' ? getDestinationInverse(response.dealerRetailSiteStartPage) : ProductTypes.TIRES;

  applicationStore.data.allowGuestAccountCreation = response.dealerEnableGuestAccountCreation ? true : false;
  applicationStore.data.dealerEnableGuestAccountConfirmation = response.dealerEnableGuestAccountConfirmation;

  applicationStore.data.dealerIsRegionalOnly = response.dealerRegionalOnly === true;

  applicationStore.data.enableFeaturedItems = response.dealerEnableFeaturedItems === true;
  applicationStore.data.enableQuickCompare =
    response.dealerDefaultTemplate !== "mazda"
    && response.dealerDefaultTemplate !== "bmw"
    && response.dealerDefaultTemplate !== "mazdatransition"
    && response.dealerDefaultTemplate !== "mercedes"
    && response.dealerDefaultTemplate !== "mercedestransition";
  applicationStore.data.productListCompare = response.dealerDefaultTemplate !== "mercedes" && response.dealerDefaultTemplate !== "mercedestransition" && response.dealerDefaultTemplate  !== "mercedes_2022";

  applicationStore.data.showCartReviewOnAddToCart = response.dealerEnableShowCartModalOnAddItem === true;
  applicationStore.data.dealerShowMsrp = response.dealerEnableShowMsrpForItems === true;

  applicationStore.data.useCartReviewPage = response.dealerEnableCartReview === true;

  applicationStore.data.useTireListDropDownFilters = response.dealerRetailSiteProductFilterLayout === 'DROP';
  applicationStore.data.enableRunFlatFilter = response.dealerProductFilters.runFlatTireFilter === true;
  applicationStore.data.enablePricingFilter = response.dealerProductFilters.pricingFilter === true;
  applicationStore.data.enableCategoryFilter = response.dealerProductFilters.categoryFilter === true;

  applicationStore.data.useAlternateTireProductListTile = response.dealerRetailSiteProductListTileLayout === 'COLU';
  applicationStore.data.useAlternatePackageProductListTile = false;
  applicationStore.data.enableShowSubmodelTiresizeSelection = response.dealerEnableShowSubmodelTiresizeSelection === true;
  applicationStore.data.enableQuarterWidthTiresizeSelection = response.dealerSubmodelTileLayout === "HORI_LIST";

  applicationStore.data.dealerForceSiteLogin = response.dealerRetailSiteForceUserAuthentication === true;
  applicationStore.data.allowStockCheckNationalPrice = response.dealerEnableNationalPriceCheck === true;
  applicationStore.data.allowStockCheckNationalStock = response.dealerEnableNationalStockCheck === true;

  applicationStore.data.promotionalImageTire = Immutable.fromJS(response.promotionalImageTire);
  applicationStore.data.dealerEnableCreatePartPackage = true;
  applicationStore.data.dealerRetailSiteCustomLogo = response.dealerRetailSiteCustomLogo;

  applicationStore.data.carouselItems = gatherCarouselData(response.dealerCarousels);
  applicationStore.data.featuredItems = gatherFeaturedItems(response.dealerCarousels);
  applicationStore.data.wishlistBanner = gatherWishlistBanner(response.dealerCarousels);

  applicationStore.data.enableMercedesFeatures = response.dealerDefaultTemplate === "mercedes" || response.dealerDefaultTemplate === "mercedestransition" || response.dealerDefaultTemplate === "mercedes_2022";
  applicationStore.data.enableToyotaFeatures = response.dealerDefaultTemplate === "toyota";
  applicationStore.data.enableSubaruFeatures = response.dealerDefaultTemplate === "subaru";
  applicationStore.data.enableVolkswagenFeatures = response.dealerDefaultTemplate === "volkswagen" || response.dealerDefaultTemplate === "volkswagen_2020";
  applicationStore.data.enableAudiFeatures = response.dealerDefaultTemplate === "audi";
  applicationStore.data.enableMazdaFeatures = response.dealerDefaultTemplate === "mazda" || response.dealerDefaultTemplate === "mazdatransition";
  applicationStore.data.enableHyundaiFeatures = response.dealerDefaultTemplate === "hyundai";
  applicationStore.data.enableKiaFeatures = response.dealerDefaultTemplate === "kia";

  getDealerProductTileLayout(response);

  applicationStore.data.customTabTitle = response.dealerRetailSiteCustomTitle[response.dealerDefaultLanguage];
  applicationStore.data.customTabFavicon = response.dealerRetailSiteCustomFaviconImage;

  getDealerOriginalEquipmentFilters(response);

  applicationStore.data.dealerMediaUrlFacebook = response.dealerRetailSiteSocialMediaUrlFacebook;
  applicationStore.data.dealerMediaUrlTwitter = response.dealerRetailSiteSocialMediaUrlTwitter;
  applicationStore.data.dealerMediaUrlInstagram = response.dealerRetailSiteSocialMediaUrlInstagram;

  applicationStore.data.allowAdvancedSearch = response.dealerEnableAdvancedSearchModal;

  applicationStore.data.enableSubmodelSkipping = response.dealerEnableShowSubmodelSkipSelection;

  applicationStore.data.allowSearchByTireSize = response.dealerEnableAdvancedSearchTiresize === 'SHOW';
  applicationStore.data.allowSearchByPartNumber = response.dealerEnableAdvancedSearchPartNumber === 'SHOW';
  applicationStore.data.allowSearchByVehicleModel = response.dealerEnableAdvancedSearchVehicleModel === 'SHOW';
  applicationStore.data.allowSearchByChassis = response.dealerEnableAdvancedSearchVehicleChassis === 'SHOW';
  applicationStore.data.allowSearchByVehicleSimple = !applicationStore.data.allowSearchByVehicleModel;

  applicationStore.data.allowAuthenticatedSearchByTireSize = response.dealerEnableAdvancedSearchTiresize === 'SHOW' || response.dealerEnableAdvancedSearchTiresize === 'AUTH';
  applicationStore.data.allowAuthenticatedSearchByPartNumber = response.dealerEnableAdvancedSearchPartNumber === 'SHOW' || response.dealerEnableAdvancedSearchPartNumber === 'AUTH';
  applicationStore.data.allowAuthenticatedSearchByVehicleModel = response.dealerEnableAdvancedSearchVehicleModel === 'SHOW' || response.dealerEnableAdvancedSearchVehicleModel === 'AUTH';
  applicationStore.data.allowAuthenticatedSearchByChassis = response.dealerEnableAdvancedSearchVehicleChassis === 'SHOW' || response.dealerEnableAdvancedSearchVehicleChassis === 'AUTH';
  applicationStore.data.allowAuthenticatedSearchByVehicleSimple = !applicationStore.data.allowAuthenticatedSearchByVehicleModel;

  applicationStore.data.dealerTirePricingNote = response.dealerTirePricingNote;
  applicationStore.data.dealerWheelPricingNote = response.dealerWheelPricingNote;
  applicationStore.data.dealerAccessoryPricingNote = response.dealerAccessoryPricingNote;
  applicationStore.data.dealerPackagePricingNote = response.dealerPackagePricingNote;
  applicationStore.data.dealerMerchandisePricingNote = response.dealerMerchandisePricingNote;

  applicationStore.data.dealerSellingPriceLabel[ProductTypes.TIRES] = response.dealerTirePricingLabelOverride;
  applicationStore.data.dealerSellingPriceLabel[ProductTypes.ALLOY_WHEELS] = response.dealerWheelPricingLabelOverride;
  applicationStore.data.dealerSellingPriceLabel[ProductTypes.ACCESSORIES] = response.dealerAccessoryPricingLabelOverride;
  applicationStore.data.dealerSellingPriceLabel[ProductTypes.PACKAGES] = response.dealerPackagePricingLabelOverride;

  applicationStore.data.dealerRetailSiteGoogleMapsExternalUrl = response.dealerRetailSiteGoogleMapsExternalUrl;

  applicationStore.data.dealerTireBrands = response.dealerProductFilters.tireManufacturers;
  applicationStore.data.dealerTireSeasons = response.dealerProductFilters.seasonCodes;

  applicationStore.data.dealer = getAppDealerInfo(response);

  applicationStore.data.showSecondaryMakes = VehicleSelectionSkippingService.getSecondaryMakesGateFromDealerInfo(response);
  vehicleSelectionStore.data.showSecondaryMakes = VehicleSelectionSkippingService.getSecondaryMakesGateFromDealerInfo(response);
  breadcrumbsStore.data.showSecondaryMakes = VehicleSelectionSkippingService.getSecondaryMakesGateFromDealerInfo(response);

  breadcrumbsStore.data.dealerSite = true;

  languageStore.data.languageTemplate = response.dealerDefaultTemplate;

  applicationStore.data.dealerEnableEcommerceRetailSite = response.dealerEnableEcommerceRetailSite;
  applicationStore.data.dealerEnablePricingAnalysis = response.dealerEnablePricingAnalysis;
  applicationStore.data.dealerEnablePricingIncentive = response.dealerEnableIncentivePricing === "1";

  applicationStore.data.dealerNationalAccountName = response.dealerNationalAccountName;

  applicationStore.data.dealerInfo = getDealerInfo(response);

  saveDealerConfigObject(response);

};

const getDealerProductTileLayout = (response) => {
  switch (response.dealerRetailSiteHomepageProductTypeTileLayout) {
    case 'FWOG': // Full Width Original
      applicationStore.data.useAlternateProductTypeSelection = false;
      applicationStore.data.enablePaddedProductTileSelection = false;
      applicationStore.data.enableAlternateProductTileSelection = false;
      break;
    case 'FWFI': // Full Width Full Image
      applicationStore.data.useAlternateProductTypeSelection = false;
      applicationStore.data.enablePaddedProductTileSelection = false;
      applicationStore.data.enableAlternateProductTileSelection = true;
      break;
    case 'FWPI': // Full Width Padded Image
      applicationStore.data.useAlternateProductTypeSelection = false;
      applicationStore.data.enablePaddedProductTileSelection = true;
      applicationStore.data.enableAlternateProductTileSelection = false;
      break;
    case 'HWOG': // Half Width Original
      applicationStore.data.useAlternateProductTypeSelection = true;
      applicationStore.data.enablePaddedProductTileSelection = false;
      applicationStore.data.enableAlternateProductTileSelection = false;
      break;
  }
};

const getDealerOriginalEquipmentFilters = (response) => {
  /*
   HIDE_ALL = Hide Filter (Default All)
   HIDE_OE = Hide Filter (Default Approved Only)
   ALL_ALL = Show Filter (Default All)
   ALL_OE = Show Filter (Default Approved Only)
   AUTH_ALL = Show Filter when Authenticated (Default All)
   AUTH_OE = Show Filter when Authenticated (Default Approved Only) */

  switch (response.dealerProductFilters.originalEquipmentFilter) {
    case "HIDE_ALL":
      applicationStore.data.useOriginalEquipmentOnlyFilter = false;
      applicationStore.data.useOriginalEquipmentOnlyFilterWhenAuhenticated = false;
      productListStore.data.originalEquipmentOnly = 2;
      break;
    case "HIDE_OE":
      applicationStore.data.useOriginalEquipmentOnlyFilter = false;
      applicationStore.data.useOriginalEquipmentOnlyFilterWhenAuhenticated = false;
      productListStore.data.originalEquipmentOnly = 1;
      break;
    case "ALL_ALL":
      applicationStore.data.useOriginalEquipmentOnlyFilter = true;
      applicationStore.data.useOriginalEquipmentOnlyFilterWhenAuhenticated = true;
      productListStore.data.originalEquipmentOnly = 2;
      break;
    case "ALL_OE":
      applicationStore.data.useOriginalEquipmentOnlyFilter = true;
      applicationStore.data.useOriginalEquipmentOnlyFilterWhenAuhenticated = true;
      productListStore.data.originalEquipmentOnly = 1;
      break;
    case "AUTH_ALL":
      applicationStore.data.useOriginalEquipmentOnlyFilter = false;
      applicationStore.data.useOriginalEquipmentOnlyFilterWhenAuhenticated = true;
      productListStore.data.originalEquipmentOnly = 2;
      break;
    case "AUTH_OE":
      applicationStore.data.useOriginalEquipmentOnlyFilter = false;
      applicationStore.data.useOriginalEquipmentOnlyFilterWhenAuhenticated = true;
      productListStore.data.originalEquipmentOnly = 1;
      break;
  }
};

export const loadDealerInfoConfig = (payload) => {
  window.dealerAccountInfoPayload = payload;

  if (payload.dealerIframeHiddenHeader) {
    applicationStore.data.screenMode = window.location !== window.parent.location ? SCREENMODES.IFRAME : SCREENMODES.REGULAR;
  }

  applicationStore.data.dealerAllowNtdStock = payload.supportConsumerOrder || payload.supportDealerOrder;
  applicationStore.data.dealerAllowNtdStockOrder = payload.supportDealerOrder;
  applicationStore.data.dealerAllowNtdConsumerPurchase = payload.supportConsumerOrder && !payload.supportDealerOrder;
  applicationStore.data.dealerAtdConnectCustomerNumber = '';
  applicationStore.data.dealerAtdConnectLocationCode = '';
  applicationStore.data.supportDealerOrder = payload.supportDealerOrder;
  applicationStore.data.supportUserDealerOrder = payload.supportUserDealerOrder !== "NONE" && payload.supportUserDealerOrder !== false;
  applicationStore.data.supportConsumerOrder = payload.supportConsumerOrder;
  applicationStore.data.supportUserConsumerOrder = payload.supportUserConsumerOrder !== "NONE" && payload.supportUserConsumerOrder !== false;

  applicationStore.data.dealerTireBrands = payload.dealerProductFilters.tireManufacturers;
  applicationStore.data.dealerTireSeasons = payload.dealerProductFilters.seasonCodes;

  applicationStore.data.carouselItems = gatherCarouselData(payload.dealerCarousels);
  applicationStore.data.featuredItems = gatherFeaturedItems(payload.dealerCarousels);
  applicationStore.data.wishlistBanner = gatherWishlistBanner(payload.dealerCarousels);

  saveDealerConfigObject(payload);
  setTimeout(() => { applicationActions.loginSuccessful(); }, 100);
};

export const loadNationalDealerConfig = (payload) => {
  var response = payload.response;
  if (!isEmpty(response) && sizeOf(response) > 0) {
    window.dealerAccountInfoPayload = response;

    if (response.dealerIframeHiddenHeader) {
      applicationStore.data.screenMode = window.location !== window.parent.location ? SCREENMODES.IFRAME : SCREENMODES.REGULAR;
    }

    applicationStore.data.dealerId = response.dealerId;
    applicationStore.data.dealerInfo = getDealerInfo(response);
    applicationStore.data.dealer = getAppDealerInfo(response);

    applicationStore.data.useAdvancedFooter = response.dealerEnableAdvancedFooter;
    applicationStore.data.fullAdvancedFooter = response.dealerEnableShowHomepageFooterCatalogue;

    applicationStore.data.dealerAppointmentLeadTime = response.dealerAppointmentLeadTime;

    applicationStore.data.dealerEnableShowPricingLastUpdate = response.dealerEnableShowPricingLastUpdate === "1";
    applicationStore.data.dealerEnableXtimeAppointmentIntegration = response.dealerEnableXtimeAppointmentIntegration;
    applicationStore.data.dealerEnablePromptToOrderTires = response.dealerEnablePromptToOrderTires;

    applicationStore.data.dealerTaxPercent = response.dealerTaxPercent;
    applicationStore.data.defaultLanguage = response.dealerDefaultLanguage;
    applicationStore.data.allowedLanguages = response.dealerLanguageSet;
    applicationStore.data.originalSeason = response.dealerDefaultSearchSeason;
    applicationStore.data.setPartQuantityToOne = response.dealerRetailSiteDefaultPartQuantityFromVehicle === false;

    applicationStore.data.dealerWinterTireSpeedRatingOffset = 0;
    applicationStore.data.dealerAllSeasonTireSpeedRatingOffset = 0;
    applicationStore.data.dealerSummerTireSpeedRatingOffset = 0;

    applicationStore.data.dealerEnableStockForCustomers = response.dealerEnableStockForCustomers === true;
    applicationStore.data.dealerIgnoreTireStock = response.dealerIgnoreTireStock === true;
    applicationStore.data.dealerIgnoreWheelStock = response.dealerIgnoreWheelStock === true;
    applicationStore.data.dealerIgnoreAccessoryStock = response.dealerIgnoreAccessoryStock === true;
    applicationStore.data.dealerIgnorePackageStock = response.dealerIgnorePackageStock === true;
    applicationStore.data.dealerIgnoreMerchandiseStock = response.dealerIgnoreMerchandiseStock === true;

    applicationStore.data.dealerAllowNtdStock = response.supportConsumerOrder || response.supportDealerOrder;
    applicationStore.data.dealerAllowNtdStockOrder = response.supportDealerOrder;
    applicationStore.data.dealerAllowNtdConsumerPurchase = response.supportConsumerOrder && !response.supportDealerOrder;
    applicationStore.data.dealerAtdConnectCustomerNumber = '';
    applicationStore.data.dealerAtdConnectLocationCode = '';

    applicationStore.data.enableFinancing = response.enableFinancing === "0" ? false : response.enableFinancing;
    applicationStore.data.financingUrl = response.financingUrl;

    applicationStore.data.quoteImmediately = response.dealerEnablePopUpQuote;
    applicationStore.data.dealerEnableCustomerChat = response.dealerEnableLiveChat;
    applicationStore.data.dealerEnableWheelTireInstallationLabourPrice = response.dealerEnableWheelTireInstallationLabourPrice;
    applicationStore.data.dealerNumberOfProductsToShowBeforePagination = response.dealerNumberOfProductsToShowBeforePagination;

    applicationStore.data.dealerAllowNTDStockBar = response.dealerAtdConnectIntegrationEnabled;

    applicationStore.data.dealerEnableCaptcha = response.dealerEnableCaptcha;
    applicationStore.data.dealerAppointmentIntegrationId = response.dealerAppointmentIntegrationId;

    applicationStore.data.enableAccessoryViewSwitch = true;

    applicationStore.data.supportDealerOrder = response.supportDealerOrder;
    applicationStore.data.supportUserDealerOrder = response.supportUserDealerOrder !== "NONE" && response.supportUserDealerOrder !== false;
    applicationStore.data.supportConsumerOrder = response.supportConsumerOrder;
    applicationStore.data.supportUserConsumerOrder = response.supportUserConsumerOrder !== "NONE" && response.supportUserConsumerOrder !== false;

    getDealerPublicPricing(response);

    applicationStore.data.enableHomepageLauncher = response.dealerEnableShowHomepageWidget === true;
    applicationStore.data.enableHomepagePanelLauncher = response.dealerDefaultTemplate === "volkswagen" || response.dealerDefaultTemplate === "volkswagen_2020";
    applicationStore.data.homepageLauncherFloatOverCarousel = response.dealerRetailSiteHomeWidgetPlacement; //values: "TOP", "RIGHT", "LEFT"
    applicationStore.data.homepageLauncherLockToPage = response.dealerRetailSiteHomeWidgetPlacement === "BESIDE_LEFT";
    applicationStore.data.loadSearchModalFromProductTypeSelection = response.dealerRetailSiteHomeProductTileSearchPath === "MODAL";

    applicationStore.data.allowGuestAccountCreation = response.dealerEnableGuestAccountCreation ? true : false;
    applicationStore.data.dealerEnableGuestAccountConfirmation = response.dealerEnableGuestAccountConfirmation;

    applicationStore.data.dealerIsRegionalOnly = response.dealerRegionalOnly === true;

    applicationStore.data.enableFeaturedItems = response.dealerEnableFeaturedItems === true;
    applicationStore.data.enableQuickCompare =
      response.dealerDefaultTemplate !== "mazda"
      && response.dealerDefaultTemplate !== "bmw"
      && response.dealerDefaultTemplate !== "mazdatransition"
      && response.dealerDefaultTemplate !== "mercedes"
        && response.dealerDefaultTemplate !== "mercedes_2022"
      && response.dealerDefaultTemplate !== "mercedestransition";
    applicationStore.data.productListCompare = response.dealerDefaultTemplate !== "mercedes" && response.dealerDefaultTemplate !== "mercedestransition" && response.dealerDefaultTemplate === "mercedes_2022";

    applicationStore.data.showCartReviewOnAddToCart = response.dealerEnableShowCartModalOnAddItem === true;
    applicationStore.data.dealerShowMsrp = response.dealerEnableShowMsrpForItems === true;

    applicationStore.data.useCartReviewPage = response.dealerEnableCartReview === true;

    applicationStore.data.useTireListDropDownFilters = response.dealerRetailSiteProductFilterLayout === 'DROP';

    applicationStore.data.enableRunFlatFilter = response.dealerProductFilters ? response.dealerProductFilters.runFlatTireFilter === true : false;
    applicationStore.data.enablePricingFilter = response.dealerProductFilters ? response.dealerProductFilters.pricingFilter === true : false;
    applicationStore.data.enableCategoryFilter = response.dealerProductFilters ? response.dealerProductFilters.categoryFilter === true : false;

    applicationStore.data.useAlternateTireProductListTile = response.dealerRetailSiteProductListTileLayout === 'COLU';
    applicationStore.data.useAlternatePackageProductListTile = false;
    applicationStore.data.enableShowSubmodelTiresizeSelection = response.dealerEnableShowSubmodelTiresizeSelection === true;
    applicationStore.data.enableQuarterWidthTiresizeSelection = response.dealerSubmodelTileLayout === "HORI_LIST";

    applicationStore.data.dealerForceSiteLogin = response.dealerRetailSiteForceUserAuthentication === true;
    applicationStore.data.allowStockCheckNationalPrice = response.dealerEnableNationalPriceCheck === true;
    applicationStore.data.allowStockCheckNationalStock = response.dealerEnableNationalStockCheck === true;

    applicationStore.data.promotionalImageTire = Immutable.fromJS(response.promotionalImageTire);
    applicationStore.data.dealerEnableCreatePartPackage = true;
    applicationStore.data.dealerRetailSiteCustomLogo = response.dealerRetailSiteCustomLogo;

    applicationStore.data.enableMercedesFeatures = response.dealerDefaultTemplate === "mercedes" || response.dealerDefaultTemplate === "mercedestransition" || response.dealerDefaultTemplate === "mercedes_2022";
    applicationStore.data.enableToyotaFeatures = response.dealerDefaultTemplate === "toyota";
    applicationStore.data.enableSubaruFeatures = response.dealerDefaultTemplate === "subaru";
    applicationStore.data.enableVolkswagenFeatures = response.dealerDefaultTemplate === "volkswagen" || response.dealerDefaultTemplate === "volkswagen_2020";
    applicationStore.data.enableAudiFeatures = response.dealerDefaultTemplate === "audi";
    applicationStore.data.enableMazdaFeatures = response.dealerDefaultTemplate === "mazda" || response.dealerDefaultTemplate === "mazdatransition";
    applicationStore.data.enableHyundaiFeatures = response.dealerDefaultTemplate === "hyundai";
    applicationStore.data.enableKiaFeatures = response.dealerDefaultTemplate === "kia";

    getDealerProductTileLayout(response);

    applicationStore.data.customTabTitle = response.dealerRetailSiteCustomTitle[response.dealerDefaultLanguage];
    applicationStore.data.customTabFavicon = response.dealerRetailSiteCustomFaviconImage;

    getDealerOriginalEquipmentFilters(response);

    applicationStore.data.dealerMediaUrlFacebook = response.dealerRetailSiteSocialMediaUrlFacebook;
    applicationStore.data.dealerMediaUrlTwitter = response.dealerRetailSiteSocialMediaUrlTwitter;
    applicationStore.data.dealerMediaUrlInstagram = response.dealerRetailSiteSocialMediaUrlInstagram;

    applicationStore.data.enableSubmodelSkipping = response.dealerEnableShowSubmodelSkipSelection;

    applicationStore.data.allowSearchByTireSize = response.dealerEnableAdvancedSearchTiresize === 'SHOW';
    applicationStore.data.allowSearchByPartNumber = response.dealerEnableAdvancedSearchPartNumber === 'SHOW';
    applicationStore.data.allowSearchByVehicleModel = response.dealerEnableAdvancedSearchVehicleModel === 'SHOW';
    applicationStore.data.allowSearchByChassis = response.dealerEnableAdvancedSearchVehicleChassis === 'SHOW';
    applicationStore.data.allowSearchByVehicleSimple = !applicationStore.data.allowSearchByVehicleModel;

    applicationStore.data.allowAuthenticatedSearchByTireSize = response.dealerEnableAdvancedSearchTiresize === 'SHOW' || response.dealerEnableAdvancedSearchTiresize === 'AUTH';
    applicationStore.data.allowAuthenticatedSearchByPartNumber = response.dealerEnableAdvancedSearchPartNumber === 'SHOW' || response.dealerEnableAdvancedSearchPartNumber === 'AUTH';
    applicationStore.data.allowAuthenticatedSearchByVehicleModel = response.dealerEnableAdvancedSearchVehicleModel === 'SHOW' || response.dealerEnableAdvancedSearchVehicleModel === 'AUTH';
    applicationStore.data.allowAuthenticatedSearchByChassis = response.dealerEnableAdvancedSearchVehicleChassis === 'SHOW' || response.dealerEnableAdvancedSearchVehicleChassis === 'AUTH';
    applicationStore.data.allowAuthenticatedSearchByVehicleSimple = !applicationStore.data.allowAuthenticatedSearchByVehicleModel;

    applicationStore.data.dealerTirePricingNote = response.dealerTirePricingNote;
    applicationStore.data.dealerWheelPricingNote = response.dealerWheelPricingNote;
    applicationStore.data.dealerAccessoryPricingNote = response.dealerAccessoryPricingNote;
    applicationStore.data.dealerPackagePricingNote = response.dealerPackagePricingNote;
    applicationStore.data.dealerMerchandisePricingNote = response.dealerMerchandisePricingNote;

    applicationStore.data.dealerSellingPriceLabel[ProductTypes.TIRES] = response.dealerTirePricingLabelOverride;
    applicationStore.data.dealerSellingPriceLabel[ProductTypes.ALLOY_WHEELS] = response.dealerWheelPricingLabelOverride;
    applicationStore.data.dealerSellingPriceLabel[ProductTypes.ACCESSORIES] = response.dealerAccessoryPricingLabelOverride;
    applicationStore.data.dealerSellingPriceLabel[ProductTypes.PACKAGES] = response.dealerPackagePricingLabelOverride;

    applicationStore.data.dealerRetailSiteGoogleMapsExternalUrl = response.dealerRetailSiteGoogleMapsExternalUrl;

    applicationStore.data.carouselItems = gatherCarouselData(response.dealerCarousels);
    applicationStore.data.featuredItems = gatherFeaturedItems(response.dealerCarousels);
    applicationStore.data.wishlistBanner = gatherWishlistBanner(response.dealerCarousels);

    applicationStore.data.dealerTireBrands = response.dealerProductFilters.tireManufacturers;
    applicationStore.data.dealerTireSeasons = response.dealerProductFilters.seasonCodes;

    if (payload.destination) {
      applicationStore.data.showCampaignDealerInfo = true;
      applicationStore.data.urlContainsCampaign = true;
      if (payload.destination !== true) globalState.select('applicationState').set('routerState', payload.destination);
    } else {
      if (applicationStore.data.search) {
        globalState.select('applicationState').set('routerState', RouterStates.SEARCH_RESULT);
      } else if (applicationStore.data.year && applicationStore.data.make && applicationStore.data.model) {
        globalState.select('applicationState').set('routerState', RouterStates.TRIM);
      } else {
        if (applicationStore.data.disableHomepage) {
          applicationStore.data.productType = applicationStore.data.defaultProductType;
          globalState.select('applicationState').set('routerState', RouterStates.YEAR);
        } else {
          globalState.select('applicationState').set('routerState', RouterStates.HOMEPAGE);
        }
      }
    }

    if (payload.language) {
      applicationStore.data.lang = payload.language;
    }

    saveDealerConfigObject(response);
    if (!checkSiteHasCampaign()) applicationActions.getDealerSecurityToken();
    //if (!checkSiteHasCampaign()) setTimeout(() => { applicationActions.getDealerSecurityToken(); }, 100);
  }
};

export const loadNationalConfig = (response) => {
  applicationStore.data.productTypes = [];
  applicationStore.data.productListProductTypes = [];

  if (response.nationalEnableTireSearch) {
    applicationStore.data.productTypes.push(ProductTypes.TIRES);
    applicationStore.data.productListProductTypes.push(ProductTypes.TIRES);
  }
  if (response.nationalEnableWheelSearch) {
    applicationStore.data.productTypes.push(ProductTypes.ALLOY_WHEELS);
    applicationStore.data.productListProductTypes.push(ProductTypes.ALLOY_WHEELS);
  }
  if (response.nationalEnableAccessorySearch) {
    applicationStore.data.productTypes.push(ProductTypes.ACCESSORIES);
    applicationStore.data.productListProductTypes.push(ProductTypes.ACCESSORIES);
  }
  if (response.nationalEnablePackageSearch) {
    applicationStore.data.productTypes.push(ProductTypes.PACKAGES);
    applicationStore.data.productListProductTypes.push(ProductTypes.PACKAGES);
  }

  applicationStore.data.nationalAccountName = response.nationalAccountName;
  applicationStore.data.nationalCountryCode = response.nationalCountryCode;

  applicationStore.data.nationalRetailSiteDestinationPage = response.nationalRetailSiteDestinationPage;

  //nationalEnableAdvancedTiresizeSearch

  applicationStore.data.allowSearchByTireSize = response.nationalEnableAdvancedTiresizeSearch;
  applicationStore.data.allowAuthenticatedSearchByTireSize = response.nationalEnableAdvancedTiresizeSearch;

  applicationStore.data.nationalAccountTireBrands = response.nationalTireManufacturerInfoList.map(function(brand, index) {
    return {
      brandId: brand.tireManufacturerId,
      brandName: brand.tireManufacturerName,
      imageUrl: brand.tireManufacturerImage
    };
  });

  applicationStore.data.dealerSite = false;
  applicationStore.data.nationalSite = true;
  applicationStore.data.groupSite = false;
  applicationStore.data.consumerSite = false;
  applicationStore.data.dealerAccountId = null;
  applicationStore.data.nationalAccountId = response.nationalAccountId;
  applicationStore.data.groupAccountId = null;
  applicationStore.data.consumerAccountId = null;
  applicationStore.data.defaultTemplate = response.nationalDefaultTemplate;
  applicationStore.data.dealerUrlCode = null;
  applicationStore.data.nationalUrlCode = response.nationalAccountUrlCode;
  applicationStore.data.groupUrlCode = null;
  applicationStore.data.minimumYears[ProductTypes.TIRES] = response.minimumTireYear;
  applicationStore.data.minimumYears[ProductTypes.PACKAGES] = response.minimumPackageYear;
  applicationStore.data.minimumYears[ProductTypes.ACCESSORIES] = response.minimumAccessoryYear;
  applicationStore.data.minimumYears[ProductTypes.ALLOY_WHEELS] = response.minimumWheelYear;
  applicationStore.data.dealerInfo = null;
  applicationStore.data.carousel = response.nationalEnableHomepageCarousel === true;
  applicationStore.data.defaultLanguage = response.nationalDefaultLanguage;
  applicationStore.data.siteDisabled = response.nationalEnableRetailSite !== true;
  applicationStore.data.allowedLanguages = response.nationalLanguageSet;

  applicationStore.data.enableLandingpageDealerSelection = false;

  applicationStore.data.hideFindUserLocationModal = !response.nationalRetailSiteShowLocationPromptOnMap;

  applicationStore.data.nationalAccountName = response.nationalAccountName;

  applicationStore.data.quoteImmediately = response.dealerEnablePopUpQuote;
  applicationStore.data.dealerEnableCustomerChat = response.dealerEnableLiveChat;
  applicationStore.data.dealerEnableWheelTireInstallationLabourPrice = response.dealerEnableWheelTireInstallationLabourPrice;
  applicationStore.data.dealerNumberOfProductsToShowBeforePagination = response.dealerNumberOfProductsToShowBeforePagination;

  applicationStore.data.legalFooter = response.legalFooter;
  applicationStore.data.useAdvancedFooter = false;
  applicationStore.data.fullAdvancedFooter = false;

  applicationStore.data.showSecondaryMakes = VehicleSelectionSkippingService.getSecondaryMakesGateFromNationalAccountInfo(response);
  vehicleSelectionStore.data.showSecondaryMakes = VehicleSelectionSkippingService.getSecondaryMakesGateFromNationalAccountInfo(response);
  breadcrumbsStore.data.showSecondaryMakes = VehicleSelectionSkippingService.getSecondaryMakesGateFromNationalAccountInfo(response);

  breadcrumbsStore.data.dealerSite = false;
  if (response.nationalRetailSiteStartPage !== 'HOME') {
    breadcrumbsStore.data.productType = getDestinationInverse(response.nationalRetailSiteStartPage);
  }

  languageStore.data.languageTemplate = response.nationalDefaultTemplate;

  applicationStore.data.allowAdvancedSearch = response.nationalEnableAdvancedSearchModal === true;

  applicationStore.data.nationalEnableSkipMap = response.nationalEnableSkipMap;

  applicationStore.data.enableHomepageLauncher = true;
  applicationStore.data.enableHomepagePanelLauncher = response.nationalDefaultTemplate === "volkswagen" || response.dealerDefaultTemplate === "volkswagen_2020";
  applicationStore.data.homepageLauncherFloatOverCarousel = response.nationalRetailSiteHomeWidgetPlacement; //values: "TOP", "RIGHT", "LEFT"
  applicationStore.data.homepageLauncherLockToPage = response.nationalRetailSiteHomeWidgetPlacement === "BESIDE_LEFT";

  applicationStore.data.nationalLandingPageTitle = response.nationalLandingPageTitle;

  applicationStore.data.disableHomepage = response.nationalRetailSiteStartPage !== 'HOME';
  applicationStore.data.defaultProductType = response.nationalRetailSiteStartPage !== 'HOME' ? getDestinationInverse(response.nationalRetailSiteStartPage) : ProductTypes.TIRES;

  applicationStore.data.useProvincialDealers = response.nationalRetailSiteDealerSelectionMethod === 'DLR';

  applicationStore.data.showCartReviewOnAddToCart = response.showCartReviewOnAddToCart === true;
  applicationStore.data.dealerShowMsrp = response.nationalEnableShowMsrpForItems === true;

  applicationStore.data.useCartReviewPage = response.useCartReviewPage === true;

  applicationStore.data.promotionalImageTire = response.promotionalImageTire;
  applicationStore.data.promotionalImageTireFr = response.promotionalImageTireFr;

  applicationStore.data.enableMercedesFeatures = response.nationalDefaultTemplate === "mercedes";
  applicationStore.data.enableMercedesFeatures = response.nationalDefaultTemplate === "mercedes_2022";
  applicationStore.data.enableMercedesFeatures = response.nationalDefaultTemplate === "mercedestransition";
  applicationStore.data.enableToyotaFeatures = response.nationalDefaultTemplate === "toyota";
  applicationStore.data.enableSubaruFeatures = response.dealerDefaultTemplate === "subaru";
  applicationStore.data.enableVolkswagenFeatures = response.nationalDefaultTemplate === "volkswagen" || response.nationalDefaultTemplate === "volkswagen_2020";
  applicationStore.data.enableAudiFeatures = response.nationalDefaultTemplate === "audi";
  applicationStore.data.enableMazdaFeatures = response.nationalDefaultTemplate === "mazda" || response.nationalDefaultTemplate === "mazdatransition";
  applicationStore.data.enableHyundaiFeatures = response.nationalDefaultTemplate === "hyundai";
  applicationStore.data.enableKiaFeatures = response.nationalDefaultTemplate === "kia";

  switch(response.nationalDefaultTemplate) {
    case 'audi':
    case 'hyundai':
    case 'toyota':
      applicationStore.data.enablePaddedProductTileSelection = true;
      break;
    case 'volkswagen':
      applicationStore.data.enableAlternateProductTileSelection = true;
      break;
    default:
      break;
  }

  applicationStore.data.customTabTitle = response.nationalRetailSiteCustomTitle[response.nationalDefaultLanguage];
  applicationStore.data.customTabFavicon = response.nationalRetailSiteCustomFaviconImage;

  applicationStore.data.enableShowSubmodelTiresizeSelection = response.nationalEnableShowSubmodelTiresizeSelection === true;

  applicationStore.data.carouselItems = gatherCarouselData(response.nationalCarousels);
  applicationStore.data.featuredItems = gatherFeaturedItems(response.nationalCarousels);

  loadNationalOrGroupDealer();
};

export const loadGroupConfig = (response) => {
  applicationStore.data.dealerSite = false;
  applicationStore.data.nationalSite = false;
  applicationStore.data.groupSite = true;
  applicationStore.data.consumerSite = false;
  applicationStore.data.dealerId = null;
  applicationStore.data.nationalAccountId = null;
  applicationStore.data.groupAccountId = response.dealerGroupId;
  applicationStore.data.consumerAccountId = null;
  applicationStore.data.defaultTemplate = response.dealerGroupDefaultTemplate;
  applicationStore.data.dealerUrlCode = null;
  applicationStore.data.nationalUrlCode = null;
  applicationStore.data.groupUrlCode = response.dealerGroupUrlCode;
  applicationStore.data.dealerInfo = null;
  applicationStore.data.groupCountryCode = response.dealerGroupCountry;
  applicationStore.data.groupRetailSiteCustomLogo = response.dealerGroupRetailSiteCustomLogo;
  applicationStore.data.allowAdvancedSearch = false;

  applicationStore.data.enableLandingpageDealerSelection = true;

  applicationStore.data.hideFindUserLocationModal = !response.groupRetailSiteShowLocationPromptOnMap;

  applicationStore.data.quoteImmediately = response.dealerEnablePopUpQuote;
  applicationStore.data.dealerEnableCustomerChat = response.dealerEnableLiveChat;
  applicationStore.data.dealerEnableWheelTireInstallationLabourPrice = response.dealerEnableWheelTireInstallationLabourPrice;
  applicationStore.data.dealerNumberOfProductsToShowBeforePagination = response.dealerNumberOfProductsToShowBeforePagination;

  applicationStore.data.legalFooter = response.legalFooter;
  applicationStore.data.useAdvancedFooter = response.dealerEnableAdvancedFooter;
  applicationStore.data.fullAdvancedFooter = response.dealerEnableShowHomepageFooterCatalogue;

  applicationStore.data.nationalAccountName = null;
  applicationStore.data.nationalCountryCode = null;
  applicationStore.data.legalFooter = null;

  applicationStore.data.minimumYears[ProductTypes.TIRES] = response.dealerGroupMinimumTireYear;
  applicationStore.data.minimumYears[ProductTypes.PACKAGES] = response.dealerGroupMinimumPackageYear;
  applicationStore.data.minimumYears[ProductTypes.ACCESSORIES] = response.dealerGroupMinimumAccessoryYear;
  applicationStore.data.minimumYears[ProductTypes.ALLOY_WHEELS] = response.dealerGroupMinimumWheelYear;

  applicationStore.data.carousel = response.dealerGroupEnableHomepageCarousel === true;

  applicationStore.data.showSecondaryMakes = VehicleSelectionSkippingService.getSecondaryMakesGateFromGroupAccountInfo(response);
  vehicleSelectionStore.data.showSecondaryMakes = VehicleSelectionSkippingService.getSecondaryMakesGateFromGroupAccountInfo(response);
  breadcrumbsStore.data.showSecondaryMakes = VehicleSelectionSkippingService.getSecondaryMakesGateFromGroupAccountInfo(response);

  breadcrumbsStore.data.dealerSite = false;

  languageStore.data.languageTemplate = response.dealerGroupDefaultTemplate;

  applicationStore.data.enableHomepageLauncher = true;
  applicationStore.data.enableHomepagePanelLauncher = response.dealerGroupDefaultTemplate === "volkswagen" || response.dealerDefaultTemplate === "volkswagen_2020";
  applicationStore.data.homepageLauncherFloatOverCarousel = "RIGHT";
  applicationStore.data.homepageLauncherLockToPage = false;

  applicationStore.data.dealerGroupPrimaryVehicleManufacturerList = response.dealerGroupPrimaryVehicleManufacturerList;

  applicationStore.data.groupAccountName = response.dealerGroupName;
  applicationStore.data.groupAccountDealerCount = parseFloat(response.dealerGroupDealerCount);
  applicationStore.data.defaultLanguage = response.dealerGroupDefaultLanguage;
  applicationStore.data.allowedLanguages = response.dealerGroupLanguageSet;
  applicationStore.data.siteDisabled = false;
  applicationStore.data.useProvincialDealers = false;

  applicationStore.data.customTabTitle = response.dealerGroupSiteCustomTitle[response.nationalDefaultLanguage];
  applicationStore.data.customTabFavicon = response.dealerGroupSiteCustomFaviconImage;

  switch(response.dealerGroupDefaultTemplate) {
    case 'audi':
    case 'hyundai':
    case 'toyota':
      applicationStore.data.enablePaddedProductTileSelection = true;
      break;
    case 'volkswagen':
      applicationStore.data.enableAlternateProductTileSelection = true;
      break;
    default:
      break;
  }

  applicationStore.data.carouselItems = gatherCarouselData(response.dealerGroupCarousels);
  applicationStore.data.featuredItems = gatherFeaturedItems(response.dealerGroupCarousels);
};

export const loadConsumerConfig = (response) => {

  //console.log('loadConsumerConfig');
  //console.log(response);

  applicationStore.data.dealerSite = false;
  applicationStore.data.nationalSite = false;
  applicationStore.data.groupSite = false;
  applicationStore.data.consumerSite = true;
  applicationStore.data.dealerId = null;
  applicationStore.data.nationalAccountId = null;
  applicationStore.data.groupAccountId = null;
  applicationStore.data.consumerAccountId = response.dealerGroupId;
  applicationStore.data.defaultTemplate = 'consumer';
  applicationStore.data.dealerUrlCode = null;
  applicationStore.data.nationalUrlCode = null;
  applicationStore.data.groupUrlCode = null;
  applicationStore.data.dealerInfo = null;
  applicationStore.data.groupCountryCode = response.dealerGroupCountry;
  applicationStore.data.groupRetailSiteCustomLogo = response.dealerGroupRetailSiteCustomLogo;
  applicationStore.data.allowAdvancedSearch = false;

  applicationStore.data.enableLandingpageDealerSelection = true;

  applicationStore.data.hideFindUserLocationModal = !response.groupRetailSiteShowLocationPromptOnMap;

  applicationStore.data.quoteImmediately = response.dealerEnablePopUpQuote;
  applicationStore.data.dealerEnableCustomerChat = response.dealerEnableLiveChat;
  applicationStore.data.dealerEnableWheelTireInstallationLabourPrice = response.dealerEnableWheelTireInstallationLabourPrice;
  applicationStore.data.dealerNumberOfProductsToShowBeforePagination = response.dealerNumberOfProductsToShowBeforePagination;

  applicationStore.data.legalFooter = response.legalFooter;
  applicationStore.data.useAdvancedFooter = response.dealerEnableAdvancedFooter;
  applicationStore.data.fullAdvancedFooter = response.dealerEnableShowHomepageFooterCatalogue;

  applicationStore.data.nationalAccountName = null;
  applicationStore.data.nationalCountryCode = null;
  applicationStore.data.legalFooter = null;

  applicationStore.data.minimumYears[ProductTypes.TIRES] = response.dealerGroupMinimumTireYear;
  applicationStore.data.minimumYears[ProductTypes.PACKAGES] = response.dealerGroupMinimumPackageYear;
  applicationStore.data.minimumYears[ProductTypes.ACCESSORIES] = response.dealerGroupMinimumAccessoryYear;
  applicationStore.data.minimumYears[ProductTypes.ALLOY_WHEELS] = response.dealerGroupMinimumWheelYear;

  applicationStore.data.carousel = response.dealerGroupEnableHomepageCarousel === true;

  applicationStore.data.showSecondaryMakes = VehicleSelectionSkippingService.getSecondaryMakesGateFromGroupAccountInfo(response);
  vehicleSelectionStore.data.showSecondaryMakes = VehicleSelectionSkippingService.getSecondaryMakesGateFromGroupAccountInfo(response);
  breadcrumbsStore.data.showSecondaryMakes = VehicleSelectionSkippingService.getSecondaryMakesGateFromGroupAccountInfo(response);

  breadcrumbsStore.data.dealerSite = false;

  languageStore.data.languageTemplate = 'consumer';

  applicationStore.data.enableHomepageLauncher = true;
  applicationStore.data.homepageLauncherFloatOverCarousel = "RIGHT";
  applicationStore.data.homepageLauncherLockToPage = false;

  applicationStore.data.dealerGroupPrimaryVehicleManufacturerList = response.dealerGroupPrimaryVehicleManufacturerList;

  applicationStore.data.groupAccountName = response.dealerGroupName;
  applicationStore.data.groupAccountDealerCount = parseFloat(response.dealerGroupDealerCount);
  applicationStore.data.defaultLanguage = response.dealerGroupDefaultLanguage;
  applicationStore.data.allowedLanguages = response.dealerGroupLanguageSet;
  applicationStore.data.siteDisabled = false;
  applicationStore.data.useProvincialDealers = false;

  applicationStore.data.customTabTitle = response.dealerGroupSiteCustomTitle[response.nationalDefaultLanguage];
  applicationStore.data.customTabFavicon = response.dealerGroupSiteCustomFaviconImage;

  applicationStore.data.carouselItems = gatherCarouselData(response.dealerGroupCarousels);
  applicationStore.data.featuredItems = gatherFeaturedItems(response.dealerGroupCarousels);
};

const loadNationalOrGroupDealer = () => {
  var dealerId = getAppDealerId();
  if (!!dealerId) {
    var token = getToken();
    get('/dealer/getDealerAccountInfoByDealerId.php', {
      dealerId: dealerId,
      isNational: 1,
      tokenId: token.tokenId,
      ts: new Date().getTime()
    }).then(response => {
      if (!isEmpty(response)) {
        window.dealerAccountInfoPayload = response;

        if (response.dealerIframeHiddenHeader) {
          applicationStore.data.screenMode = window.location !== window.parent.location ? SCREENMODES.IFRAME : SCREENMODES.REGULAR;
        }

        applicationStore.data.dealerInfo = getDealerInfo(response);

        applicationStore.data.dealerAppointmentLeadTime = response.dealerAppointmentLeadTime;

        applicationStore.data.dealerTaxPercent = response.dealerTaxPercent;
        applicationStore.data.originalSeason = response.dealerDefaultSearchSeason;
        applicationStore.data.setPartQuantityToOne = response.dealerRetailSiteDefaultPartQuantityFromVehicle === false;

        applicationStore.data.useAdvancedFooter = response.dealerEnableAdvancedFooter;
        applicationStore.data.fullAdvancedFooter = response.dealerEnableShowHomepageFooterCatalogue;

        applicationStore.data.dealerEnableShowPricingLastUpdate = response.dealerEnableShowPricingLastUpdate === "1";
        applicationStore.data.dealerEnableXtimeAppointmentIntegration = response.dealerEnableXtimeAppointmentIntegration;
        applicationStore.data.dealerEnablePromptToOrderTires = response.dealerEnablePromptToOrderTires;

        applicationStore.data.dealerWinterTireSpeedRatingOffset = 0;
        applicationStore.data.dealerAllSeasonTireSpeedRatingOffset = 0;
        applicationStore.data.dealerSummerTireSpeedRatingOffset = 0;

        applicationStore.data.dealerEnableStockForCustomers = response.dealerEnableStockForCustomers === true;
        applicationStore.data.dealerIgnoreTireStock = response.dealerIgnoreTireStock === true;
        applicationStore.data.dealerIgnoreWheelStock = response.dealerIgnoreWheelStock === true;
        applicationStore.data.dealerIgnoreAccessoryStock = response.dealerIgnoreAccessoryStock === true;
        applicationStore.data.dealerIgnorePackageStock = response.dealerIgnorePackageStock === true;
        applicationStore.data.dealerIgnoreMerchandiseStock = response.dealerIgnoreMerchandiseStock === true;

        applicationStore.data.dealerAllowNtdStock = response.supportConsumerOrder || response.supportDealerOrder;
        applicationStore.data.dealerAllowNtdStockOrder = response.supportDealerOrder;
        applicationStore.data.dealerAllowNtdConsumerPurchase = response.supportConsumerOrder && !response.supportDealerOrder;
        applicationStore.data.dealerAtdConnectCustomerNumber = '';
        applicationStore.data.dealerAtdConnectLocationCode = '';

        applicationStore.data.dealerEnableCustomerLogin = response.dealerEnableMembershipPricing === "1";

        applicationStore.data.enableFinancing = response.enableFinancing === "0" ? false : response.enableFinancing;
        applicationStore.data.financingUrl = response.financingUrl;

        applicationStore.data.quoteImmediately = response.dealerEnablePopUpQuote;
        applicationStore.data.dealerEnableCustomerChat = response.dealerEnableLiveChat;
        applicationStore.data.dealerEnableWheelTireInstallationLabourPrice = response.dealerEnableWheelTireInstallationLabourPrice;
        applicationStore.data.dealerNumberOfProductsToShowBeforePagination = response.dealerNumberOfProductsToShowBeforePagination;

        applicationStore.data.dealerAllowNTDStockBar = response.dealerAtdConnectIntegrationEnabled;

        applicationStore.data.dealerEnableCaptcha = response.dealerEnableCaptcha;
        applicationStore.data.dealerAppointmentIntegrationId = response.dealerAppointmentIntegrationId;

        applicationStore.data.enableAccessoryViewSwitch = true;

        applicationStore.data.supportDealerOrder = response.supportDealerOrder;
        applicationStore.data.supportUserDealerOrder = response.supportUserDealerOrder !== "NONE" && response.supportUserDealerOrder !== false;
        applicationStore.data.supportConsumerOrder = response.supportConsumerOrder;
        applicationStore.data.supportUserConsumerOrder = response.supportUserConsumerOrder !== "NONE" && response.supportUserConsumerOrder !== false;

        getDealerPublicPricing(response);

        applicationStore.data.enableHomepageLauncher = response.dealerEnableShowHomepageWidget === true;
        applicationStore.data.enableHomepagePanelLauncher = response.dealerDefaultTemplate === "volkswagen" || response.dealerDefaultTemplate === "volkswagen_2020";
        applicationStore.data.homepageLauncherFloatOverCarousel = response.dealerRetailSiteHomeWidgetPlacement; //values: "TOP", "RIGHT", "LEFT"
        applicationStore.data.homepageLauncherLockToPage = response.dealerRetailSiteHomeWidgetPlacement === "BESIDE_LEFT";
        applicationStore.data.loadSearchModalFromProductTypeSelection = response.dealerRetailSiteHomeProductTileSearchPath === "MODAL";

        applicationStore.data.allowGuestAccountCreation = response.dealerEnableGuestAccountCreation ? true : false;
        applicationStore.data.dealerEnableGuestAccountConfirmation = response.dealerEnableGuestAccountConfirmation;

        applicationStore.data.dealerIsRegionalOnly = response.dealerRegionalOnly === true;

        applicationStore.data.enableFeaturedItems = response.dealerEnableFeaturedItems === true;
        applicationStore.data.enableQuickCompare =
          response.dealerDefaultTemplate !== "mazda"
          && response.dealerDefaultTemplate !== "bmw"
          && response.dealerDefaultTemplate !== "mazdatransition"
          && response.dealerDefaultTemplate !== "mercedes"
            && response.dealerDefaultTemplate !== "mercedes_2022"
          && response.dealerDefaultTemplate !== "mercedestransition";
        applicationStore.data.productListCompare = response.dealerDefaultTemplate !== "mercedes" && response.dealerDefaultTemplate !== "mercedestransition" && response.dealerDefaultTemplate  !== "mercedes_2022";

        applicationStore.data.showCartReviewOnAddToCart = response.dealerEnableShowCartModalOnAddItem === true;
        applicationStore.data.dealerShowMsrp = response.dealerEnableShowMsrpForItems === true;

        applicationStore.data.useCartReviewPage = response.dealerEnableCartReview === true;

        applicationStore.data.useTireListDropDownFilters = response.dealerRetailSiteProductFilterLayout === 'DROP';
        applicationStore.data.enableRunFlatFilter = response.dealerProductFilters.runFlatTireFilter === true;
        applicationStore.data.enablePricingFilter = response.dealerProductFilters.pricingFilter === true;
        applicationStore.data.enableCategoryFilter = response.dealerProductFilters.categoryFilter === true;

        applicationStore.data.useAlternateTireProductListTile = response.dealerRetailSiteProductListTileLayout === 'COLU';
        applicationStore.data.useAlternatePackageProductListTile = false;
        applicationStore.data.enableShowSubmodelTiresizeSelection = response.dealerEnableShowSubmodelTiresizeSelection === true;
        applicationStore.data.enableQuarterWidthTiresizeSelection = response.dealerSubmodelTileLayout === "HORI_LIST";

        applicationStore.data.dealerForceSiteLogin = response.dealerRetailSiteForceUserAuthentication === true;
        applicationStore.data.allowStockCheckNationalPrice = response.dealerEnableNationalPriceCheck === true;
        applicationStore.data.allowStockCheckNationalStock = response.dealerEnableNationalStockCheck === true;

        applicationStore.data.promotionalImageTire = Immutable.fromJS(response.promotionalImageTire);
        applicationStore.data.dealerEnableCreatePartPackage = true;
        applicationStore.data.dealerRetailSiteCustomLogo = response.dealerRetailSiteCustomLogo;

        applicationStore.data.enableMercedesFeatures = response.dealerDefaultTemplate === "mercedes" || response.dealerDefaultTemplate === "mercedestransition"  || response.dealerDefaultTemplate === "mercedes_2022";
        applicationStore.data.enableToyotaFeatures = response.dealerDefaultTemplate === "toyota";
        applicationStore.data.enableSubaruFeatures = response.dealerDefaultTemplate === "subaru";
        applicationStore.data.enableVolkswagenFeatures = response.dealerDefaultTemplate === "volkswagen" || response.dealerDefaultTemplate === "volkswagen_2020";
        applicationStore.data.enableAudiFeatures = response.dealerDefaultTemplate === "audi";
        applicationStore.data.enableMazdaFeatures = response.dealerDefaultTemplate === "mazda" || response.dealerDefaultTemplate === "mazdatransition";
        applicationStore.data.enableHyundaiFeatures = response.dealerDefaultTemplate === "hyundai";
        applicationStore.data.enableKiaFeatures = response.dealerDefaultTemplate === "kia";

        getDealerProductTileLayout(response);

        applicationStore.data.customTabTitle = response.dealerRetailSiteCustomTitle[response.dealerDefaultLanguage];
        applicationStore.data.customTabFavicon = response.dealerRetailSiteCustomFaviconImage;

        getDealerOriginalEquipmentFilters(response);

        applicationStore.data.dealerMediaUrlFacebook = response.dealerRetailSiteSocialMediaUrlFacebook;
        applicationStore.data.dealerMediaUrlTwitter = response.dealerRetailSiteSocialMediaUrlTwitter;
        applicationStore.data.dealerMediaUrlInstagram = response.dealerRetailSiteSocialMediaUrlInstagram;

        applicationStore.data.enableSubmodelSkipping = response.dealerEnableShowSubmodelSkipSelection;

        applicationStore.data.allowSearchByTireSize = response.dealerEnableAdvancedSearchTiresize === 'SHOW';
        applicationStore.data.allowSearchByPartNumber = response.dealerEnableAdvancedSearchPartNumber === 'SHOW';
        applicationStore.data.allowSearchByVehicleModel = response.dealerEnableAdvancedSearchVehicleModel === 'SHOW';
        applicationStore.data.allowSearchByChassis = response.dealerEnableAdvancedSearchVehicleChassis === 'SHOW';
        applicationStore.data.allowSearchByVehicleSimple = !applicationStore.data.allowSearchByVehicleModel;

        applicationStore.data.allowAuthenticatedSearchByTireSize = response.dealerEnableAdvancedSearchTiresize === 'SHOW' || response.dealerEnableAdvancedSearchTiresize === 'AUTH';
        applicationStore.data.allowAuthenticatedSearchByPartNumber = response.dealerEnableAdvancedSearchPartNumber === 'SHOW' || response.dealerEnableAdvancedSearchPartNumber === 'AUTH';
        applicationStore.data.allowAuthenticatedSearchByVehicleModel = response.dealerEnableAdvancedSearchVehicleModel === 'SHOW' || response.dealerEnableAdvancedSearchVehicleModel === 'AUTH';
        applicationStore.data.allowAuthenticatedSearchByChassis = response.dealerEnableAdvancedSearchVehicleChassis === 'SHOW' || response.dealerEnableAdvancedSearchVehicleChassis === 'AUTH';
        applicationStore.data.allowAuthenticatedSearchByVehicleSimple = !applicationStore.data.allowAuthenticatedSearchByVehicleModel;

        applicationStore.data.dealerTirePricingNote = response.dealerTirePricingNote;
        applicationStore.data.dealerWheelPricingNote = response.dealerWheelPricingNote;
        applicationStore.data.dealerAccessoryPricingNote = response.dealerAccessoryPricingNote;
        applicationStore.data.dealerPackagePricingNote = response.dealerPackagePricingNote;
        applicationStore.data.dealerMerchandisePricingNote = response.dealerMerchandisePricingNote;

        applicationStore.data.dealerSellingPriceLabel[ProductTypes.TIRES] = response.dealerTirePricingLabelOverride;
        applicationStore.data.dealerSellingPriceLabel[ProductTypes.ALLOY_WHEELS] = response.dealerWheelPricingLabelOverride;
        applicationStore.data.dealerSellingPriceLabel[ProductTypes.ACCESSORIES] = response.dealerAccessoryPricingLabelOverride;
        applicationStore.data.dealerSellingPriceLabel[ProductTypes.PACKAGES] = response.dealerPackagePricingLabelOverride;

        applicationStore.data.dealerRetailSiteGoogleMapsExternalUrl = response.dealerRetailSiteGoogleMapsExternalUrl;

        applicationStore.data.dealer = getAppDealerInfo(response);

        applicationStore.data.carouselItems = gatherCarouselData(response.dealerCarousels);
        applicationStore.data.featuredItems = gatherFeaturedItems(response.dealerCarousels);
        applicationStore.data.wishlistBanner = gatherWishlistBanner(response.dealerCarousels);

        applicationStore.data.dealerTireBrands = response.dealerProductFilters.tireManufacturers;
        applicationStore.data.dealerTireSeasons = response.dealerProductFilters.seasonCodes;

        saveDealerConfigObject(response);
  
        if (!checkSiteHasCampaign()) applicationActions.getDealerSecurityToken();
        //if (!checkSiteHasCampaign()) setTimeout(() => { applicationActions.getDealerSecurityToken(); }, 100);
      }
    });
  }
};

const getAppDealerInfo = (response) => {
  if (checkResponse(response)) {
    return Immutable.Map({
      id: response.dealerId,
      name: response.dealerName,
      urlCode: response.dealerUrlCode,
      address: response.dealerAddress,
      city: response.dealerCity,
      postalCode: response.dealerPostalCode,
      province: response.dealerState,
      country: response.dealerCountry,
      phone: response.dealerContactPhone ? response.dealerContactPhone : '',
      email: response.dealerContactEmail,
      website: response.dealerWebsiteUrl,
      hours: response.dealerHours,
      season: getDefaultSeason(response),
      position: new GoogleMapsAPI.LatLng(parseFloat(response.dealerLatitude), parseFloat(response.dealerLongitude)),
      latitude: response.dealerLatitude,
      longitude: response.dealerLongitude,
      template: response.dealerDefaultTemplate,
      legalFooter: response.legalFooter,
      active: false
    });
  }
};

const getDealerInfo = (response) => {
  if (checkResponse(response)) {
    return ({
      dealerId: response.dealerId,
      name: response.dealerName,
      dealerUrlCode: response.dealerUrlCode,
      city: response.dealerCity,
      address: response.dealerAddress,
      postalCode: response.dealerPostalCode,
      province: response.dealerState,
      phone: response.dealerContactPhone ? response.dealerContactPhone : '',
      email: response.dealerContactEmail,
      country: response.dealerCountry,
      position: new GoogleMapsAPI.LatLng(parseFloat(response.dealerLatitude), parseFloat(response.dealerLongitude)),
      season: getDefaultSeason(response),
      template: response.dealerDefaultTemplate,
      website: response.dealerWebsiteUrl,
      hours: response.dealerHours,
      legalFooter: response.legalFooter,
      term: applicationStore.data.search
    });
  }
};

const getDealerConfigObject = (response) => {
  if (checkResponse(response)) {
    return {
      season: getDefaultSeason(response),
      seasonFilters: response.dealerProductFilters.seasonCodes,
      brandFilters: response.dealerProductFilters.tireManufacturers,
      accessoryCategoryFilter: response.dealerProductFilters.accessoryCategories,
      dealerSearchPreferences: {
        dealerEnableAdvancedSearchVehicleSimple: response.dealerEnableAdvancedSearchVehicleModel === "HIDE",
        dealerEnableAdvancedSearchVehicleModel: response.dealerEnableAdvancedSearchVehicleModel,
        dealerEnableAdvancedSearchTiresize: response.dealerEnableAdvancedSearchTiresize,
        dealerEnableAdvancedSearchPartNumber: response.dealerEnableAdvancedSearchPartNumber,
        dealerEnableAdvancedSearchVehicleChassis: response.dealerEnableAdvancedSearchVehicleChassis
      }
    };
  }
};

const saveDealerConfigObject = (response) => {
  if (checkResponse(response)) {

    var config = getDealerConfigObject(response);

    //ProductListStore
    if (typeof(config) !== 'undefined' && config !== null) {
      if (config.season) {
        productListStore.data.season = parseInt(config.season, 10);
        productListStore.data.originalSeason = parseInt(config.season, 10);
      }

      if (config.seasonFilters) {
        productListStore.data.seasonFilters = Immutable.fromJS(config.seasonFilters);
        productListStore.data.season = productListStore.data.originalSeason;
        if (typeof productListStore.data.season === 'undefined') {
          if (config.seasonFilters[0]) productListStore.data.season = 0; else if (config.seasonFilters[1]) productListStore.data.season = 1; else if (config.seasonFilters[2]) productListStore.data.season = 2;
        }
      }

      if (config.brandFilters) {
        productListStore.data.brandFilters = Immutable.fromJS(config.brandFilters).map((brand, brandId) => buildFilter(brandId, brand.get('part_manufacturer_name')));
      }

      if (config.accessoryCategoryFilter) {
        productListStore.data.accessoryCategoryFilter = Immutable.fromJS(config.accessoryCategoryFilter).map(function (category) {
          return Immutable.Map({
            key: category.get('accessoryCategoryId'), value: category.get('accessoryCategoryName')
          });
        });
      }
    }

    //SearchStore
    if (typeof config.season !== 'undefined') {
      searchStore.data.season = config.season;
    }

    if (config.seasonFilters !== 'undefined') {
      searchStore.data.seasonFilters = Immutable.fromJS(config.seasonFilters);
      if (typeof searchStore.data.season === 'undefined' && config.seasonFilters) {
        if (config.seasonFilters[0]) searchStore.data.season = 0; else if (config.seasonFilters[1]) searchStore.data.season = 1; else if (config.seasonFilters[2]) searchStore.data.season = 2;
      }
    }

    if (typeof config.brandFilters !== 'undefined') {
      searchStore.data.brandFilters = Immutable.fromJS(config.brandFilters).map((brand, brandId) => buildFilter(brandId, brand.get('part_manufacturer_name')));
    }

    if (typeof config.accessoryCategoryFilter !== 'undefined') {
      searchStore.data.accessoryCategoryFilter = Immutable.fromJS(config.accessoryCategoryFilter).map(function (category) {
        return Immutable.Map({
          key: category.get('accessoryCategoryId'), value: category.get('accessoryCategoryName')
        });
      });
    }

    if (applicationStore.data.enableAudiFeatures || applicationStore.data.enableVolkswagenFeatures || applicationStore.data.enableMercedesFeatures) {
      if (typeof config.dealerSearchPreferences !== 'undefined') {
        if (config.dealerSearchPreferences.dealerEnableAdvancedSearchVehicleSimple === true) {
          searchStore.data.defaultSearchType = SearchTypes.SEARCH_BY_VEHICLE_SIMPLE;
        } else if (config.dealerSearchPreferences.dealerEnableAdvancedSearchVehicleModel === 'SHOW') {
          searchStore.data.defaultSearchType = SearchTypes.SEARCH_BY_MODEL;
        } else if (config.dealerSearchPreferences.dealerEnableAdvancedSearchTiresize === 'SHOW') {
          searchStore.data.defaultSearchType = SearchTypes.SEARCH_BY_TIRESIZE;
        } else if (config.dealerSearchPreferences.dealerEnableAdvancedSearchPartNumber === 'SHOW') {
          searchStore.data.defaultSearchType = SearchTypes.SEARCH_BY_PARTNUMBER;
        } else if (config.dealerSearchPreferences.dealerEnableAdvancedSearchVehicleChassis === 'SHOW') {
          searchStore.data.defaultSearchType = SearchTypes.SEARCH_BY_CHASSIS;
        }
      }
    } else {
      if (typeof config.dealerSearchPreferences !== 'undefined') {
        if (config.dealerSearchPreferences.dealerEnableAdvancedSearchVehicleSimple === true) {
          searchStore.data.defaultSearchType = SearchTypes.SEARCH_BY_VEHICLE_SIMPLE;
        } else if (config.dealerSearchPreferences.dealerEnableAdvancedSearchVehicleModel === 'SHOW') {
          searchStore.data.defaultSearchType = SearchTypes.SEARCH_BY_MODEL;
        } else if (config.dealerSearchPreferences.dealerEnableAdvancedSearchTiresize === 'SHOW') {
          searchStore.data.defaultSearchType = SearchTypes.SEARCH_BY_TIRESIZE;
        } else if (config.dealerSearchPreferences.dealerEnableAdvancedSearchPartNumber === 'SHOW') {
          searchStore.data.defaultSearchType = SearchTypes.SEARCH_BY_PARTNUMBER;
        } else if (config.dealerSearchPreferences.dealerEnableAdvancedSearchVehicleChassis === 'SHOW') {
          searchStore.data.defaultSearchType = SearchTypes.SEARCH_BY_CHASSIS;
        }
      }
    }

  }
};

const checkResponse = (response) => {
  if (typeof(response) !== 'undefined' && response !== null) return true;
  else return false;
};

const getDealerPublicPricing = (response) => {
  if (response.dealerEnableHidePublicPricing === 'SHOW_ALL') {
    applicationStore.data.dealerHidePublicPricing = (function() {
      return false;
    });
  } else if (response.dealerEnableHidePublicPricing === 'HIDE_ALL') {
    applicationStore.data.dealerHidePublicPricing = (function(loggedIn) {
      return true;
    });
  }
  else if (response.dealerEnableHidePublicPricing === 'SHOW_AUTH') {
    applicationStore.data.dealerHidePublicPricing = (function(loggedIn) {
      return !loggedIn;
    });
  }
};

const getDefaultSeason = (response) => {
  if (response && response.hasOwnProperty("dealerDefaultSearchSeason")) return response.dealerDefaultSearchSeason
  else return null;
};

const gatherCarouselData = (carousels) => {
  return Immutable.fromJS(carousels.home_primary).map(function (item) {
    var playerType = null;
    switch(item.get('resourceType')) {
      case "image":
        playerType = CarouselPlayerTypes.IMAGE_PLAYER;
        break;
      case "video":
        playerType = CarouselPlayerTypes.VIDEO_IMAGE_PLAYER;
        break;
      case "youtube":
        playerType = CarouselPlayerTypes.YOUTUBE_PLAYER;
        break;
    }

    return Immutable.Map({
      time: parseInt(item.get('rotationHoldDelayMS'), 10),

      image: item.get('imageURL'),

      videoMp4Url: item.get('videoMpeg4Url'),
      videoWebMUrl: item.get('videoWebMUrl'),
      videoOggUrl: item.get('videoOggUrl'),

      videoYouTubeUrl: item.get('videoYouTubeUrl'),

      title: item.get('title'),
      titleHAlign: item.get('titleHAlign'),
      titleVAlign: item.get('titleVAlign'),
      subtitle: item.get('subtitle'),
      linkText: item.get('linkText'),
      linkBody: item.get('linkBody'),
      linkUrl: item.get('linkURL'),
      linkHAlign: item.get('linkHAlign'),
      linkVAlign: item.get('linkVAlign'),

      playerType: playerType,
      playerControls: {
        autoPlay: item.get('videoAutoPlay'),
        mute: item.get('videoMute'),
        loop: item.get('videoLoop')
      },
      videoHeight: item.get('videoYouTubeHeight')
    });
  });
};

const gatherFeaturedItems = (carousels) => {
  return Immutable.fromJS(carousels.home_secondary).map(function (item) {
    return Immutable.Map({
      title: item.get('title'),
      image: item.get('imageURL'),
      subtitle: item.get('subtitle'),
      linkUrl: item.get('linkURL'),
      linkText: item.get('linkText'),
      linkTarget: item.get('linkTarget')
    });
  });
};

const gatherWishlistBanner = (carousels) => {
  if (carousels && carousels.order_summary) {
    return carousels.order_summary.map(function (banner) {
      return {
        imageUrl: banner.imageURL,
        linkUrl: banner.linkURL,
        linkTarget: banner.linkTarget,
        subtitle: banner.subtitle
      }
    });
  } else {
    return null;
  }
};
import { applicationStore } from '../stores/ApplicationStore.js'
import { productListStore } from '../stores/ProductListStore.js'
import { productDetailStore } from '../stores/ProductDetailStore.js'
import { searchStore } from '../stores/SearchStore.js'

import { RouterStates } from '../constants/RouterStates';
import { ProductTypes } from '../constants/ProductTypes';

import { sizeOf } from '../service/UtilityService'

var ROUTER_ORDER_NATIONAL = [
  RouterStates.DEALER_SELECTION,
  RouterStates.HOMEPAGE,

  RouterStates.SEARCH_RESULT,

  RouterStates.YEAR,
  RouterStates.MAKE,
  RouterStates.CATEGORY,
  RouterStates.MODEL,
  RouterStates.TRIM,
  RouterStates.PRODUCT_LIST,
  RouterStates.WISHLIST,
  RouterStates.PRODUCT_DETAIL,

  RouterStates.SEARCH_BRANDS,
  RouterStates.SEARCH_MODELS,
  RouterStates.SEARCH_TIRE,

  RouterStates.SEARCH_BRANDS_BY_SEASON,
  RouterStates.SEARCH_MODELS_BY_SEASON,
  RouterStates.SEARCH_TIRE_BY_SEASON
];

var ROUTER_ORDER_DEALER = [
  RouterStates.HOMEPAGE,
  RouterStates.SEARCH_RESULT,

  RouterStates.HOMEPAGE,
  RouterStates.YEAR,
  RouterStates.MAKE,
  RouterStates.CATEGORY,
  RouterStates.MODEL,
  RouterStates.TRIM,
  RouterStates.PRODUCT_LIST,
  RouterStates.WISHLIST,
  RouterStates.PRODUCT_DETAIL,

  RouterStates.HOMEPAGE,
  RouterStates.SEARCH_BRANDS,
  RouterStates.SEARCH_MODELS,
  RouterStates.SEARCH_TIRE,

  RouterStates.HOMEPAGE,
  RouterStates.SEARCH_BRANDS_BY_SEASON,
  RouterStates.SEARCH_MODELS_BY_SEASON,
  RouterStates.SEARCH_TIRE_BY_SEASON
];

export const getNationalRouterOrder = (function() {
  if (applicationStore.data.disableHomepage) {
    return [
      RouterStates.DEALER_SELECTION,
      RouterStates.SEARCH_RESULT,

      RouterStates.DEALER_SELECTION,
      RouterStates.YEAR,
      RouterStates.MAKE,
      RouterStates.CATEGORY,
      RouterStates.MODEL,
      RouterStates.TRIM,
      RouterStates.PRODUCT_LIST,
      RouterStates.WISHLIST,
      RouterStates.PRODUCT_DETAIL,

      RouterStates.DEALER_SELECTION,
      RouterStates.YEAR,
      RouterStates.SEARCH_BRANDS,
      RouterStates.SEARCH_MODELS,
      RouterStates.SEARCH_TIRE,

      RouterStates.DEALER_SELECTION,
      RouterStates.YEAR,
      RouterStates.SEARCH_BRANDS_BY_SEASON,
      RouterStates.SEARCH_MODELS_BY_SEASON,
      RouterStates.SEARCH_TIRE_BY_SEASON
    ];
  } else {
    return [
      RouterStates.DEALER_SELECTION,
      RouterStates.HOMEPAGE,
      RouterStates.SEARCH_RESULT,

      RouterStates.DEALER_SELECTION,
      RouterStates.HOMEPAGE,
      RouterStates.YEAR,
      RouterStates.MAKE,
      RouterStates.CATEGORY,
      RouterStates.MODEL,
      RouterStates.TRIM,
      RouterStates.PRODUCT_LIST,
      RouterStates.WISHLIST,
      RouterStates.PRODUCT_DETAIL,

      RouterStates.DEALER_SELECTION,
      RouterStates.HOMEPAGE,
      RouterStates.SEARCH_BRANDS,
      RouterStates.SEARCH_MODELS,
      RouterStates.SEARCH_TIRE,

      RouterStates.DEALER_SELECTION,
      RouterStates.HOMEPAGE,
      RouterStates.SEARCH_BRANDS_BY_SEASON,
      RouterStates.SEARCH_MODELS_BY_SEASON,
      RouterStates.SEARCH_TIRE_BY_SEASON
    ];
  }
});

export const getDealerRouterOrder = (function() {
  return [
    RouterStates.HOMEPAGE,
    RouterStates.SEARCH_RESULT,

    RouterStates.HOMEPAGE,
    RouterStates.YEAR,
    RouterStates.MAKE,
    RouterStates.CATEGORY,
    RouterStates.MODEL,
    RouterStates.TRIM,
    RouterStates.PRODUCT_LIST,
    RouterStates.WISHLIST,
    RouterStates.PRODUCT_DETAIL,

    RouterStates.HOMEPAGE,
    RouterStates.SEARCH_BRANDS,
    RouterStates.SEARCH_MODELS,
    RouterStates.SEARCH_TIRE,

    RouterStates.HOMEPAGE,
    RouterStates.SEARCH_BRANDS_BY_SEASON,
    RouterStates.SEARCH_MODELS_BY_SEASON,
    RouterStates.SEARCH_TIRE_BY_SEASON
  ];
});

/*
 *  Tire Search Path (tiresize, partnumber, chassis search)
 */
var getTireSearchPath = (function() {

});

/*
 *  Tire By Brand Search Path (tire brand, tire model, tire)
 */
var getTireBrandSearchPath = (function() {

});

/*
 *  Tire By Season Search Path (tire model, tire)
 */
var getTireSeasonSearchPath = (function() {

});

/*
 *  Vehicle Search Path (tire, wheel, accessory, package vehicle search)
 */
var getVehicleSearchPath = (function() {

});

/*
 *  Check for valid routes
 */

var canLoadProductList = (function() {
  switch(applicationStore.data.productType) {
    case ProductTypes.TIRES:
      if (sizeOf(productListStore.data.listOfTires) > 0) return true;
      return false;
    case ProductTypes.ALLOY_WHEELS:
      if (sizeOf(productListStore.data.listOfWheels) > 0) return true;
      return false;
    case ProductTypes.ACCESSORIES:
      if (sizeOf(productListStore.data.listOfAccessories) > 0) return true;
      return false;
    case ProductTypes.PACKAGES:
      if (sizeOf(productListStore.data.listOfPackages) > 0) return true;
      return false;
  }
});

var canLoadSearchResult = (function() {
  if (sizeOf(searchStore.data.results) > 0) return true;
  else return false;
});

var canLoadProductDetail = (function() {
  if (productDetailStore.data.detail) return true;
  else return false;
});

var canLoadProductDetailModal = (function() {
  if (productListStore.data.productDetailPart) return true;
  else return false;
});

var canLoadSearchTire = (function() {
  if (applicationStore.data.selectedTireId) return true;
  else return false;
});
import { ProvinceArray } from "../../constants/ProvinceArray.js";
import { ProductTypes } from "../../constants/ProductTypes.js";
import { SearchTypes } from "../../constants/SearchTypes.js";

import { Modal } from "../ux/Modal.jsx";
import { Image } from "../ux/Image.jsx";

import { dealerSelectionStore } from "../../stores/DealerSelectionStore.js";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { applicationActions } from "../../actions/ApplicationActions.js";

import {
  sizeOf,
  getAppLanguage,
  getTrackingEventCategory,
} from "../../service/UtilityService";
import { connect } from "react-redux";
import { changeLang } from "../../redux/features/applicationInfo.js";
import { setDealerToken } from "../../redux/features/dealer.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

// NOTE: Not implemented in redux
// import { loadListOfDealers } from '../../redux/features/dealerSections.js';

var React = require("react");
var _ = require("lodash");

class ProvinceSelection extends I18nComponent {
  state = {
    initialize: applicationStore.data.useProvincialDealers,
    dealers: applicationStore.data.provincialDealersList,
    chassisRedirectdealers: dealerSelectionStore.dealers,
    chassisRedirect: {
      chassisInfo: dealerSelectionStore.redirectToChassisSearch,
      hasCalledForDealers: false,
      hasRunRedirect: false,
    },
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("Province Selection");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Province Selection Access"
    );
    applicationStore.addChangeListener(this.onChange);
    dealerSelectionStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
    dealerSelectionStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    applicationActions.loadListOfProvincialDealers();
    //setTimeout(() => { applicationActions.loadListOfProvincialDealers(); }, 100);
  }

  componentDidUpdate(lastProps, lastState) {
    if (
      sizeOf(this.state.chassisRedirectdealers) === 0 &&
      !this.state.chassisRedirect.hasCalledForDealers
    ) {
      this.setState({
        chassisRedirect: {
          chassisInfo: this.state.chassisRedirect.chassisInfo,
          hasCalledForDealers: true,
          hasRunRedirect: this.state.chassisRedirect.hasRunRedirect,
        },
      });
      // this.props.dispatch(loadListOfDealers())
      // dealerSelectionActions.loadListOfDealers();
      //setTimeout(() => { dealerSelectionActions.loadListOfDealers(); }, 100);
    }
    if (
      sizeOf(this.state.chassisRedirectdealers) > 0 &&
      sizeOf(this.state.chassisRedirect.chassisInfo) > 0 &&
      !this.state.chassisRedirect.hasRunRedirect
    ) {
      this.setState({
        chassisRedirect: {
          chassisInfo: this.state.chassisRedirect.chassisInfo,
          hasCalledForDealers: this.state.chassisRedirect.hasCalledForDealers,
          hasRunRedirect: true,
        },
      });
      var selectedDealer = null,
        chassisDealer = this.state.chassisRedirect.chassisInfo.dealer;
      this.state.chassisRedirectdealers.map(function (dealer) {
        if (dealer.get("alias") === chassisDealer) selectedDealer = dealer;
      });
      if (selectedDealer) {
        var link =
          window.location.origin +
          "/national/" +
          applicationStore.data.nationalUrlCode +
          "/" +
          this.state.chassisRedirect.chassisInfo.language +
          "/dealer/" +
          selectedDealer.get("id") +
          "/product/" +
          ProductTypes.TIRES +
          "/type/" +
          SearchTypes.SEARCH_BY_CHASSIS +
          "/chassis/" +
          this.state.chassisRedirect.chassisInfo.chassis;

        window.location.href = link;
        //setTimeout(() => { window.location.href = link; }, 100);
      }
    }
  }

  render() {
    var langEnBtnClass = "btn-lang";
    var langFrBtnClass = "btn-lang";
    var titleClass = "";
    if (getAppLanguage() == "en") {
      langEnBtnClass = "btn-lang selected";
    } else {
      langFrBtnClass = "btn-lang selected";
      titleClass = "french";
    }

    var dealerList = false;
    var onDealerSelected = this.onDealerSelected;
    if (this.state.dealers && this.state.dealers.count() > 0) {
      dealerList = this.state.dealers.map(function (dealer) {
        return (
          <ul onClick={onDealerSelected.bind(this, dealer)}>
            <li>
              <Image
                src="/static/img/mercedes/button_left.png"
                alt={
                  "Button Select " +
                  ProvinceArray[dealer.get("province")][getAppLanguage()]
                }
              />
            </li>
            <li>
              <h2>{ProvinceArray[dealer.get("province")][getAppLanguage()]}</h2>
            </li>
          </ul>
        );
      });
    }

    return (
      <Modal
        provinceSelectionModal={true}
        fullCurtain={true}
        noScroll={true}
        keepBodyScroll={true}
      >
        <div className="wrapper-alt">
          <div className="province-selection-modal">
            <div className="province-selection-modal-select-lang">
              <button onClick={this.onChangeLangFr} className={langFrBtnClass}>
                Français
              </button>
              <button onClick={this.onChangeLangEn} className={langEnBtnClass}>
                English
              </button>
            </div>
            <div className="province-selection-modal-title">
              <h1 className={titleClass}>
                {this.t("common.selectByProvinceTitle")}
              </h1>
            </div>
            <div className="province-selection-modal-province-select">
              {dealerList}
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  onChangeLangE = () => {
    if (getAppLanguage() !== "en") {
      setTimeout(() => {
        this.props.dispatch(changeLang("en"));
        // applicationActions.changeLanguage('en');
      }, 10);
    }
  };

  onChangeLangFr = () => {
    if (getAppLanguage() !== "fr") {
      setTimeout(() => {
        this.pros.dispatch(changeLang("fr"));
        // applicationActions.changeLanguage('fr');
      }, 10);
    }
  };

  onDealerSelected = (dealer) => {
    this.props.dispatch(setDealerToken(dealer));
    // applicationActions.setSelectedDealerForToken(dealer);
    //setTimeout(() => { applicationActions.selectedDealer(dealer.get('id'), dealer.get('season'), null) }, 200);
  };

  onChange = () => {
    this.setState({
      initialize: applicationStore.data.useProvincialDealers,
      dealers: applicationStore.data.provincialDealersList,
      chassisRedirectdealers: dealerSelectionStore.dealers,
      chassisRedirect: {
        chassisInfo: dealerSelectionStore.redirectToChassisSearch,
        hasCalledForDealers: this.state.chassisRedirect.hasCalledForDealers,
        hasRunRedirect: this.state.chassisRedirect.hasRunRedirect,
      },
    });
  };
}

export default connect(null)(ProvinceSelection);

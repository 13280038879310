import { Modal } from "../ux/Modal.jsx";
import { Image } from "../ux/Image.jsx";

import { applicationStore } from "../../stores/ApplicationStore.js";
import { applicationActions } from "../../actions/ApplicationActions.js";
import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";

import { RouterStates } from "../../constants/RouterStates.js";
import { ProvinceArray } from "../../constants/ProvinceArray.js";

import { getAppLanguage } from "../../service/UtilityService.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var _ = require("lodash");

export class WishlistDealerSelectionModal extends I18nComponent {
  state = {
    dealerList: applicationStore.data.provincialDealerDealersList,
    selectedProvince: applicationStore.data.dealerInfo.province,
    selectedDealer:
      applicationStore.data.provincialDealerDealersListSelectedDealer,
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    if (applicationStore.data.provincialDealerDealersList.size == 0) {
      setTimeout(() => {
        applicationActions.loadProvincialDealerListOfDealers();
      });
    }
  }

  render() {
    var onSelectedDealer = this.onSelectedDealer;
    return (
      <Modal
        requestUnmount={this.onRequestCloseModal}
        applyTemplateStyle={true}
      >
        <div className="wrapper">
          <div className="wishlist-dealer-selection">
            <div className="dealer-selection">
              <div className="title">
                <h1>{this.t("wishlist.modal.findADealer")}</h1>
              </div>
              <div className="current-selection">
                <span className="span-no-left-margin">
                  {this.t("wishlist.modal.province")}
                </span>
                <span className="span-with-margin">
                  {ProvinceArray[this.state.selectedProvince][getAppLanguage()]}
                </span>
                <span
                  className="select-new-province"
                  onClick={this.onSelectNewProvince}
                >
                  (
                  <span className="select-new-province-text">
                    {this.t("wishlist.modal.selectNewProvince")}
                  </span>
                  )
                </span>
              </div>
              <div className="separator">
                <span>{this.t("wishlist.modal.OR")}</span>
              </div>
              <div className="search-box">
                <form onSubmit={this.onSubmitSearch}>
                  <input
                    className="search-input"
                    type="text"
                    placeholder={this.t("wishlist.modal.placeholder")}
                  />
                  <button className="btn" type="submit">
                    {this.t("wishlist.modal.search")}
                  </button>
                </form>
              </div>
              <div className="dealer-list">
                <ul>
                  {this.state.dealerList.map(function (dealer) {
                    return (
                      <li onClick={onSelectedDealer.bind(this, dealer)}>
                        <Image
                          className="dealer-list-image"
                          src="/static/img/mercedes/button_left.png"
                          alt={"Button Select " + dealer.get("name")}
                        />
                        <span>{dealer.get("name")}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            {this.state.selectedDealer != null ? (
              <div className="dealer-info">
                <div className="image">
                  <Image
                    src="/static/img/mercedes/dealer_image.png"
                    alt={
                      "Image Dealership " +
                      this.state.selectedDealer.get("name")
                    }
                  />
                </div>
                <div className="title">
                  <h2>{this.state.selectedDealer.get("name")}</h2>
                </div>
                <div className="address">
                  <span>{this.state.selectedDealer.get("address") + ","}</span>
                  <span>{this.state.selectedDealer.get("city") + ","}</span>
                  <span>{this.state.selectedDealer.get("province")}</span>
                  <span>{this.state.selectedDealer.get("postalCode")}</span>
                </div>
                <div className="view-on-map">
                  <a href="#" target="_blank">
                    {this.t("wishlist.modal.viewOnMap")}
                  </a>
                </div>
              </div>
            ) : (
              false
            )}
          </div>
        </div>
      </Modal>
    );
  }

  onRequestCloseModal = () => {
    shoppingCartActions.closeScheduleWishlistModal();
  };

  onSubmitSearch = () => {};

  onSelectedDealer = (dealer) => {
    applicationActions.provincialDealerListSelectDealer(dealer);
  };

  onSelectNewProvince = () => {
    applicationActions.changeRouterStateDirectly(RouterStates.DEALER_SELECTION);
  };

  onChange = () => {
    this.setState({
      dealerList: applicationStore.data.provincialDealerDealersList,
      selectedProvince: applicationStore.data.dealerInfo.province,
      selectedDealer:
        applicationStore.data.provincialDealerDealersListSelectedDealer,
    });
  };
}

import {checkArrayFieldsExist, isEmpty, sizeOf} from "../service/UtilityService";
import {shoppingCartStore} from "../stores/ShoppingCartStore";
import {productListActions} from "../actions/ProductListActions";
import {getDestinationInverse} from "../api/RequestBuilderHelper";
import {ProductTypes} from "../constants/ProductTypes";
import Immutable from "immutable";
import {
  accessoryMappingFunction,
  getPricingByQuantity,
  getPricingFromQuantityAndDetailsRefactored,
  getQuantitiesFromPricingDetailsRefactored,
  getRebateByQuantity,
  packageMappingFunction,
  tireMappingFunction,
  wheelMappingFunction
} from "../service/ProductList";
import {applicationStore} from "../stores/ApplicationStore";
import {decodeQuoteName, isCompleteOrder, isConsumerComplete} from "../service/WishlistService";
import {shoppingCartActions} from "../actions/ShoppingCartActions";
import {globalState} from "../GlobalState";
import {RouterStates} from "../constants/RouterStates";
import Moment from "moment";
import store from '../stores/configureStore';

// let state = store.getState();

function updateOrderStatus(response) {
  if (response && sizeOf(response) > 0) {
    var supportDealerOrder = false;
    if (checkArrayFieldsExist(response, ['supportDealerOrder'])) supportDealerOrder = response.supportDealerOrder;
    else if (checkArrayFieldsExist(response, ['response', 'supportDealerOrder'])) supportDealerOrder = response.response.supportDealerOrder;

    var supportConsumerOrder = false;
    if (checkArrayFieldsExist(response, ['supportConsumerOrder'])) supportConsumerOrder = response.supportConsumerOrder;
    else if (checkArrayFieldsExist(response, ['response', 'supportConsumerOrder'])) supportConsumerOrder = response.response.supportConsumerOrder;

    var orderTypeCode = response.orderTypeCode;
    if (typeof(orderTypeCode) === 'undefined' && checkArrayFieldsExist(response, ['response', 'orderTypeCode'])) orderTypeCode = response.response.orderTypeCode;

    var orderNumber = response.orderNumber;
    if (typeof(orderNumber) === 'undefined' && checkArrayFieldsExist(response, ['response', 'orderNumber'])) orderNumber = response.response.orderNumber;

    var orderId = response.orderId;
    if (typeof(orderId) === 'undefined' && checkArrayFieldsExist(response, ['response', 'orderId'])) orderId = response.response.orderId;

    this.data = this.data.set('supportDealerOrder', supportDealerOrder);
    this.data = this.data.set('supportConsumerOrder', supportConsumerOrder);
    this.data = this.data.set('orderTypeCode', orderTypeCode);
    this.data = this.data.set('orderGUID', orderNumber);
    this.data = this.data.set('orderId', orderId);
  }

};



export function transformShoppingCartViewItemsFromApi (apiShoppingCartViewItems) {
  var shoppingCartViewItems = Immutable.fromJS(apiShoppingCartViewItems);
  if (typeof shoppingCartViewItems !== 'undefined') {
    shoppingCartViewItems = shoppingCartViewItems.map(function (shoppingCartViewItem, index) {
      var quantity = {
        id: apiShoppingCartViewItems[index].pricingContextId + parseInt(apiShoppingCartViewItems[index].itemQty, 10),
        value: parseInt(apiShoppingCartViewItems[index].itemQty, 10),
        title: {
          en: `${parseInt(apiShoppingCartViewItems[index].itemQty, 10)}${apiShoppingCartViewItems[index].pricingContextId > 0 ? ' - ' + apiShoppingCartViewItems[index].itemPromotion['en'] : ''}`,
          fr: `${parseInt(apiShoppingCartViewItems[index].itemQty, 10)}${apiShoppingCartViewItems[index].pricingContextId > 0 ? ' - ' + apiShoppingCartViewItems[index].itemPromotion['fr'] : ''}`
        },
        pricingContextId: apiShoppingCartViewItems[index].pricingContextId
      };

      var itemPart = apiShoppingCartViewItems[index]['itemPart'], partPricing = null;

      if (checkArrayFieldsExist(itemPart, ['partPricing', 'primaryPart'])) partPricing = itemPart['partPricing']['primaryPart'];
      else partPricing = apiShoppingCartViewItems[index].itemPart.partPricing.primaryPart;

      return Immutable.fromJS({
        id: shoppingCartViewItem.get('itemId'),
        cartItemId: shoppingCartViewItem.get('orderItemId'),
        title: shoppingCartViewItem.get('itemTitle'),
        image: shoppingCartViewItem.get('itemImageLink'),
        description: shoppingCartViewItem.get('itemDescription'),
        type: getDestinationInverse(shoppingCartViewItem.get('itemType')),
        price: parseFloat(shoppingCartViewItem.get('rowPrice')),
        savings: parseFloat(shoppingCartViewItem.get('rowSavings')),
        promotion: shoppingCartViewItem.get('itemPromotion'),
        promotionId: shoppingCartViewItem.get('pricingContextId'),
        promotionName: shoppingCartViewItem.get('itemPromotionPricing'),
        hasPromotion: parseFloat(shoppingCartViewItem.get('pricingContextId')) > 0,
        quantity: quantity,
        quantities: getQuantitiesFromPricingDetailsRefactored(partPricing),

        itemPart: shoppingCartViewItem.get('itemPart'),

        pricingDetails: partPricing,
        pricing: getPricingByQuantity(partPricing, quantity),
        rebate: getRebateByQuantity(partPricing, quantity),
        optionId: shoppingCartViewItem.get('optionId'),
        vehicleId: shoppingCartViewItem.get('vehicleId'),
        hasPricing: shoppingCartViewItem.get('itemPart').get('partSummary').get('primaryPart').get('itemHasDealerPricing'),

        itemPartOption: shoppingCartViewItem.get('itemPartOption'),

        mandatoryItems: shoppingCartViewItem.get('mandatoryLineItemList'),
        includedOptionalItems: shoppingCartViewItem.get('includedOptionalLineItemList'),
        optionalItems: shoppingCartViewItem.get('additionalOptionalLineItemList'),
        hasRequiredItem: shoppingCartViewItem.get('hasRequiredRelatedItems') === '1',
        partNumber: shoppingCartViewItem.get('itemPartNumber'),

        nationalPartNumber: shoppingCartViewItem.get('itemPart').get('partSummary').get('primaryPart').get('itemNationalPartNumber'),

        secondaryImages: shoppingCartViewItem.get('itemPart').get('partSummary').get('primaryPart').get('itemImageSecondaryLinkList') ? shoppingCartViewItem.get('itemPart').get('partSummary').get('primaryPart').get('itemImageSecondaryLinkList').map(function (image) {
          return Immutable.Map({
            url: image.get('imageUrl'),
            sortOrder: image.get('imageSortOrder')
          });
        }) : null,

        supportDealerOrder: shoppingCartViewItem.get('supportDealerOrder'),
        supportConsumerOrder: shoppingCartViewItem.get('supportConsumerOrder'),

        /* TIRE */
        tiresize: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('formattedTiresize'),
        loadRating: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('tireLoadRating'),
        speedRating: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('tireSpeedIndex'),
        brandImage: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('tireManufacturerImage'),
        runFlat: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('tireRunFlat'),
        originalEquipment: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('tireOriginalEquipmentFlag'),
        tireManufacturerName: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('tireManufacturerName'),

        /* WHEEL */
        diameter: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('wheelDiameter'),

        /* PACKAGE */
        packageManufacturerName: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('packageManufacturerName'),
        lineItems: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('packageLineItem') ? shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('packageLineItem').map(function(lineItem) {
          return Immutable.Map({
            title: lineItem.get('title'),
            quantity: lineItem.get('qty')
          });
        }) : Immutable.List.of(),
        items: shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('packageItem') ? shoppingCartViewItem.get('itemPart').get('partDetails').get('primaryPart').get('packageItem').map(function (packageItem) {
          return Immutable.Map({
            id: packageItem.get('itemId'),
            partType: packageItem.get('itemType'),
            partNumber: packageItem.get('itemPartNumber'),
            quantity: packageItem.get('itemQuantity'),
            title: packageItem.get('itemTitle'),
            description: packageItem.get('itemDescription'),
            typeDescription: packageItem.get('itemTypeDescription'),
            itemLineItem: packageItem.get('itemPackageLineItem').map(function (lineItem, index) {
              return Immutable.Map({
                lineItemId: lineItem.get('lineItemId'),
                title: lineItem.get('title'),
                description: lineItem.get('description'),
                quantity: lineItem.get('qty'),
                matchItemQty: lineItem.get('matchItemQty') === '1',
                unitPrice: lineItem.get('unitPrice'),
                totalPrice: lineItem.get('totalPrice')
              });
            })
          });
        }) : Immutable.List.of()
      });
    });
    return shoppingCartViewItems.groupBy(function (item) { return item.get('type'); });
  } else {
    return shoppingCartViewItems;
  }
}

function  getNumberOfPromotions(apiShoppingCartViewItems) {
  if (typeof apiShoppingCartViewItems !== 'undefined') {
    return sizeOf(apiShoppingCartViewItems
      .filter(shoppingCartViewItem => shoppingCartViewItem.getIn(['itemPromotion', 'en']) !== '')
      .map(shoppingCartViewItem => shoppingCartViewItem.set('rowSavings', parseFloat(shoppingCartViewItem.get('rowSavings'))))
      .filter(shoppingCartViewItem => shoppingCartViewItem.get('rowSavings') > 0)
      .groupBy(shoppingCartViewItem => shoppingCartViewItem.getIn(['itemPromotion', 'en'])));
  }
}

function getTotalSavingsThroughPromotion (apiQuoteItems) {
  if (typeof apiQuoteItems !== 'undefined') {
    return apiQuoteItems
      .map(quoteItem => parseFloat(quoteItem.get('rowSavings')))
      .reduce((memo, savings) => memo + savings, 0);
  }
};

function getTotalSavingsThroughMSRP (apiShoppingCartViewItems) {
  if (typeof apiShoppingCartViewItems !== 'undefined') {
    return apiShoppingCartViewItems
      .map(shoppingCartViewItem => (parseFloat(shoppingCartViewItem.get('itemListPrice')) - parseFloat(shoppingCartViewItem.get('itemPrice'))) * parseFloat(shoppingCartViewItem.get('itemQty')))
      .reduce((memo, savings) => memo + savings, 0);
  }
}

function getFirstNonNullPromotion (apiShoppingCartViewItems) {
  if (typeof apiShoppingCartViewItems !== 'undefined') {
    return apiShoppingCartViewItems.find(shoppingCartViewItem => parseFloat(shoppingCartViewItem.get('rowSavings')) > 0);
  }
}

const openCartAfterAdd = (item) => {
  if (item && item.type === ProductTypes.ACCESSORIES) {
    var requiredItems = item.requiredItems;
    if (isEmpty(requiredItems)) requiredItems = item.product.get('relatedParts');
    return applicationStore.data.showCartReviewOnAddToCart || (requiredItems && sizeOf(requiredItems) > 0 && globalState.select('applicationState').get('routerState') !== RouterStates.PRODUCT_DETAIL);
  } else {
    return applicationStore.data.showCartReviewOnAddToCart;
  }
};

export const shoppingCartViewLoaded =  (response) => {

  let data = new Map();
  if (response && sizeOf(response) > 0) {

    // updateOrderStatus(response);

    if (response.itemId > 0) {
      response = response.response;
    }

    if (shoppingCartStore.data.get('cart') === null && response.orderId !== null) {
      shoppingCartStore.data = shoppingCartStore.data.set('cart', response.orderId);
    }

    var item = null, packageVisualizerSet = false;
    if (typeof response.orderItemList !== 'undefined') {
      // shoppingCartStore.clearItems();
      // setTimeout(() => {
      //   productListActions.setPartForVisualizer(null);
      // }, 0);
      response.orderItemList.forEach(function (cartItem) {
        if (!packageVisualizerSet && getDestinationInverse(cartItem.itemType) === ProductTypes.PACKAGES) {
          if (sizeOf(cartItem.itemPart.partSummary.primaryPart.itemImageSecondaryLinkList) > 0) {
            packageVisualizerSet = true;
            var visualizerItem = Immutable.fromJS({
              title: cartItem.itemTitle,
              secondaryImages: cartItem.itemPart.partSummary.primaryPart.itemImageSecondaryLinkList.map(function (image) {
                return Immutable.Map({
                  imageUrl: image.imageUrl,
                  thumbnailUrl: image.thumbnailUrl,
                  sortOrder: image.imageSortOrder
                });
              })
            });
            setTimeout(() => {
              productListActions.setPartForVisualizer(visualizerItem);
            }, 10);
          }
        }

        var quantity = {
          id: cartItem.pricingContextId + parseInt(cartItem.itemQty, 10),
          value: parseInt(cartItem.itemQty, 10),
          title: {
            en: `${parseInt(cartItem.itemQty, 10)}${cartItem.pricingContextId > 0 ? ' - ' + cartItem.itemPromotion['en'] : ''}`,
            fr: `${parseInt(cartItem.itemQty, 10)}${cartItem.pricingContextId > 0 ? ' - ' + cartItem.itemPromotion['fr'] : ''}`
          },
          pricingContextId: cartItem.pricingContextId
        };

        var itemPart = cartItem['itemPart'], partPricing = null;
        if (checkArrayFieldsExist(itemPart, ['partPricing', 'primaryPart'])) {
          partPricing = itemPart['partPricing']['primaryPart'];
        } else {
          partPricing = cartItem.itemPart.partPricing.primaryPart;
        }

        var warnings = cartItem.itemWarnings;

        var price = getPricingFromQuantityAndDetailsRefactored(partPricing, quantity);

        var product = null;
        switch (getDestinationInverse(cartItem.itemType)) {
          case ProductTypes.TIRES:
            product = tireMappingFunction(true, applicationStore.data.dealerIgnoreTireStock, "", applicationStore.data.setPartQuantityToOne)(cartItem.itemPart);
            break;
          case ProductTypes.ALLOY_WHEELS:
            product = wheelMappingFunction(true, applicationStore.data.dealerIgnoreWheelStock, "", applicationStore.data.setPartQuantityToOne)(cartItem.itemPart);
            break;
          case ProductTypes.ACCESSORIES:
            product = accessoryMappingFunction(true, applicationStore.data.dealerIgnoreAccessoryStock, applicationStore.data.setPartQuantityToOne)(cartItem.itemPart);
            break;
          case ProductTypes.PACKAGES:
            product = packageMappingFunction(true, applicationStore.data.dealerIgnorePackageStock, applicationStore.data.setPartQuantityToOne)(cartItem.itemPart);
            break;
          default:
            break;
        }

        item = {
          id: cartItem.itemId,
          cartItemId: cartItem.orderItemId,
          name: cartItem.itemTitle,
          image: cartItem.itemImageLink,
          listPrice: price['listPrice'],
          showUnitPrice: price['showUnitPrice'],
          displayPrice: price['displayPrice'],
          privatePrice: price['privatePrice'],
          displayLabour: price['displayLabour'],
          promotionText: price['promotionPricingText'],
          rowPrice: price['rowPrice'],
          cartItemPrice: cartItem['rowPrice'],
          quantity: quantity,
          type: getDestinationInverse(cartItem.itemType),
          product: product,
          pricingContextId: cartItem.pricingContextId,
          itemPartOption: cartItem.itemPartOption,
          itemWarnings: warnings,
          supportDealerOrder: cartItem.supportDealerOrder,
          supportConsumerOrder: cartItem.supportConsumerOrder
        };
       data.set('items', shoppingCartStore.data.get('items').push(Immutable.fromJS(item)));
      });
      shoppingCartStore.data = shoppingCartStore.data.set('itemsCount', sizeOf(response.orderItemList));
    }

    if (
      sizeOf(response.orderItemList) > 0
      && !shoppingCartStore.isCartModalOpened()
      && applicationStore.data.quoteImmediately
      && !response.fromCreatePackage
      && !isCompleteOrder()
      && !isConsumerComplete()
    ) {
      data.set('toggleCartModal', true);
      // setTimeout(() => {
      //   shoppingCartActions.closeCart();
      //   setTimeout(() => { shoppingCartActions.openCartModal(); }, 100);
      // }, 100);

    } else if (response.addedToCart && openCartAfterAdd(item)) {
      data.set('toggleCartModal', false);
      var cartItem = Immutable.fromJS(item);
      setTimeout(() => {
        shoppingCartActions.openDetail(cartItem);
      }, 100);

    }

    if (globalState.select('applicationState').get('routerState') === RouterStates.WISHLIST) {
      setTimeout(() => { shoppingCartActions.closeCart(); }, 100);
    }

    if (applicationStore.data.enableFinancing && !!response.openFinancing) {
      data.set('showFinancingModal', true);
    } else {
      data.set('showFinancingModal', false);
    }

    const cartItemList = Immutable.fromJS(response.orderItemList);

    var firstName = decodeQuoteName("first", response.customerName), lastName = decodeQuoteName("last", response.customerName), email = response.customerEmail, phone = response.customerPhone, notes = response.customerNotes;
    if (firstName && document.getElementById('firstNameQuoteField')) document.getElementById('firstNameQuoteField').value = firstName;
    if (lastName && document.getElementById('lastNameQuoteField')) document.getElementById('lastNameQuoteField').value = lastName;
    if (email && document.getElementById('userEmailField')) document.getElementById('userEmailField').value = email;
    if (phone && document.getElementById('telQuoteField')) document.getElementById('telQuoteField').value = phone;
    if (notes && document.getElementById('userNoteQuoteField')) document.getElementById('userNoteQuoteField').value = notes;

    if (firstName && document.getElementById('formFirstName')) document.getElementById('formFirstName').value = firstName;
    if (lastName && document.getElementById('formLastName')) document.getElementById('formLastName').value = lastName;
    if (email && document.getElementById('formEmail')) document.getElementById('formEmail').value = email;
    if (phone && document.getElementById('formPhone')) document.getElementById('formPhone').value = phone;
    if (notes && document.getElementById('formNotes')) document.getElementById('formNotes').value = notes;

    data.set('formFirstName', firstName);
    data.set('formLastName', lastName);
    data.set('formEmail', email);
    data.set('formPhone', phone);
    data.set('formNotes', notes);
    data.set('shoppingCartViewCreated', Moment(response.shoppingCartViewCreatedTimestamp));
    data.set('appointmentRequestDate', Moment(response.appointmentRequestDate));
    data.set('appointmentRequestTime', response.appointmentRequestTime);
    data.set('appointmentRequestNotes', response.dealerNotes);
    data.set('cartItems', transformShoppingCartViewItemsFromApi(response.orderItemList));
    data.set('total', parseFloat(response.orderGrandTotal));
    data.set('subtotal', parseFloat(response.orderSubtotal));
    data.set('tax', parseFloat(response.orderTaxPercent));
    data.set('taxtotal', parseFloat(response.orderTaxTotal));
    data.set('numberOfPromotions', getNumberOfPromotions(cartItemList));
    data.set('totalSavings', getTotalSavingsThroughPromotion(cartItemList));
    data.set('totalMSRPSavings', getTotalSavingsThroughMSRP(cartItemList));
    data.set('firstNonNullPromotion', getFirstNonNullPromotion(cartItemList));
    data.set('orderRestockingFee', response.orderRestockingFee);
    data.set('shoppingCartViewNotes', response.orderNotes);
    data.set('customerName', response.customerName);
    data.set('customerEmail', response.customerEmail);
    data.set('customerPhone', response.customerPhone);
    data.set('customerNotes', response.customerNotes);
    data.set('orderStatus', response.orderStatus);
    data.set('supportDealerOrder', response.supportDealerOrder);
    data.set('supportConsumerOrder', response.supportConsumerOrder);
    data.set('orderDeposit', response.orderDeposit);
    data.set('orderDepositDealer', response.orderDepositDealer);
    data.set('orderDepositInternal', response.orderDepositInternal);
    data.set('orderDueAtDealer', response.orderDueAtDealer);
    data.set('orderDisclaimerList', response.orderDisclaimerList);
    data.set('orderId', response.orderId);
    data.set('orderGUID', response.orderNumber);
    data.set('orderTypeCode', response.orderTypeCode);
    data.set('orderStatusDescription', Immutable.fromJS(response.orderStatusDescription));
    data.set('vehicleInformation', response.vehicleInformation);
    data.set('orderFulfillmentList', response.orderFulfillmentList);
    return data;
  }
  return data;
};


import { i18nMixin } from "../../i18n/i18nMixin.js";
import { applicationActions } from "../../actions/ApplicationActions.js";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { shoppingCartActions } from "../../actions/ShoppingCartActions.js";
import { shoppingCartStore } from "../../stores/ShoppingCartStore.js";
import { OrderStatusReport } from "./OrderStatusReport.jsx";
import { Image } from "../ux/Image.jsx";
import { Modal } from "../ux/Modal.jsx";

import { OrderTypes } from "../../constants/OrderTypes";

import {
  get_browser,
  money_format,
  sizeOf,
  getAppLanguage,
  moment_format,
} from "../../service/UtilityService";
import { connect } from "react-redux";
import {
  quoteReportUpdateSort,
  quoteDateFilter,
  quoteFilterCount,
  quoteSearchInput,
  showLeadReport,
  showQuoteReport,
} from "../../redux/features/quote.js";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var React = require("react");
var Moment = require("moment");

const filter_4M = "4 months";
const filter_3M = "3 months";
const filter_2M = "2 months";
const filter_1M = "1 month";
const filter_7D = "7 days";
const filter_1D = "1 day";
const filter_T = "today";

const sort_DATE = "date";
const sort_TOTAL = "total";
const sort_CREATEDBY = "createdBy";
const sort_CUSTOMERNAME = "customerName";

const sort_DESC = -1;
const sort_ASC = 1;

const QUOTE_REPORT = "quote-report";
const LEAD_REPORT = "lead-report";
const ORDER_REPORT = "order-report";

class QuoteReport extends I18nComponent {
  state = {
    quotes: applicationStore.data.quoteList,
    leads: applicationStore.data.leadList,
    orders: shoppingCartStore.data.get("ntdOrderHistory"),
    numQuotesToShow: applicationStore.data.QuoteReportNumQuotesToShow,
    searchInput: this.props.searchInput,
    selectedFilterDate: applicationStore.data.quoteReportSelectedFilterDate,
    selectedSortType: applicationStore.data.quoteReportSelectedSortType,
    sortByFlipFlop: this.props.sortbyFlip, // Descending: -1, Ascending: 1
    quoteReportLoading: applicationStore.isApiCallInProgress(),
    reportType: applicationStore.data.quoteReportType,
    showOrderStatusReport: false,
    orderStatusOrderId: null,
    orderStatusOrderNumber: null,
    orderStatusOrderType: null,
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
    shoppingCartStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
    shoppingCartStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    shoppingCartActions.loadQuoteReport();
  }

  render() {
    var quoteList = [],
      orderList = [],
      leadList = [];
    if (this.state.quotes) quoteList = this.sortList(this.state.quotes);
    if (this.state.orders) orderList = this.sortList(this.state.orders);
    if (this.state.leads) leadList = this.sortLeadList(this.state.leads);

    //EST
    var quoteCount = 0;
    var orderCount = 0;
    var leadCount = 0;
    var details = this.t("quoteReport.details");
    var est = this.t("quoteReport.easternTimezone");

    var browserIsIE = get_browser().name == "MSIE"; // covers ie 8, 9 & 10

    var nationalLead = this.t("quoteReport.isNational");
    var dealerLead = this.t("quoteReport.isDealer");

    var language = getAppLanguage();

    var _this = this; // access translations within map

    var closeImageLink = "/static/img/close_circle.png";
    switch (applicationStore.data.defaultTemplate) {
      case "mercedes":
        closeImageLink = "/static/img/mercedes/close.png";
        break;
      case "volkswagen":
        closeImageLink = "/static/img/volkswagen/close_icon.png";
        break;
      default:
        break;
    }

    var placeholder;

    if (this.state.reportType === LEAD_REPORT)
      placeholder = this.t("quoteReport.searchLeads");
    if (this.state.reportType === QUOTE_REPORT)
      placeholder = this.t("quoteReport.searchQuotes");
    if (this.state.reportType === ORDER_REPORT)
      placeholder = this.t("quoteReport.searchOrders");

    return (
      <Modal
        className="quote-report-modal"
        refactor={true}
        requestUnmount={this.closeQuoteReport}
      >
        {this.state.quotes.size > 0 ? (
          <div className="wrapper quote-report">
            <div className="modal-header">
              <img
                className="close-icon"
                alt="Icon Close"
                src={closeImageLink}
                onClick={this.closeQuoteReport}
              />
            </div>
            <div className="quote-report-header">
              <div className="report-selection">
                <div
                  className={
                    this.state.reportType === LEAD_REPORT
                      ? "select-leads selected"
                      : "select-leads"
                  }
                  onClick={this.selectReportType.bind(this, LEAD_REPORT)}
                >
                  <h1>{this.t("quoteReport.leadReport")}</h1>
                  <h4>
                    {sizeOf(leadList)} / {sizeOf(this.state.leads)}{" "}
                    {this.t("quoteReport.leads")}
                  </h4>
                </div>
                <div
                  className={
                    this.state.reportType === QUOTE_REPORT
                      ? "select-quotes selected"
                      : "select-quotes"
                  }
                  onClick={this.selectReportType.bind(this, QUOTE_REPORT)}
                >
                  <h1>{this.t("quoteReport.quoteReport")}</h1>
                  <h4>
                    {sizeOf(quoteList)} / {sizeOf(this.state.quotes)}{" "}
                    {this.t("quoteReport.quotes")}
                  </h4>
                </div>
                <div
                  className={
                    this.state.reportType === ORDER_REPORT
                      ? "select-orders selected"
                      : "select-orders"
                  }
                  onClick={this.selectReportType.bind(this, ORDER_REPORT)}
                >
                  <h1>{this.t("quoteReport.orderReport")}</h1>
                  <h4>
                    {sizeOf(orderList)} / {sizeOf(this.state.orders)}{" "}
                    {this.t("quoteReport.orders")}
                  </h4>
                </div>
              </div>
              <div className="quote-report-options">
                <h3>{this.t("quoteReport.options")}</h3>
                <div className="quote-report-options-left">
                  <div className="quote-report-sort">
                    <p>{this.t("quoteReport.sort")}: </p>
                    <select
                      className="quote-report-sort-select"
                      onChange={this.selectSort.bind(this, this.value)}
                    >
                      <option
                        className="quote-report-sort-selection"
                        value={sort_DATE + "-DESC"}
                      >
                        {this.t("quoteReport.byMostRecent")}
                      </option>
                      <option
                        className="quote-report-sort-selection"
                        value={sort_DATE + "-ASC"}
                      >
                        {this.t("quoteReport.byLeastRecent")}
                      </option>
                      {this.state.reportType === QUOTE_REPORT ||
                      this.state.reportType === ORDER_REPORT ? (
                        <option
                          className="quote-report-sort-selection"
                          value={sort_TOTAL + "-ASC"}
                        >
                          {this.t("quoteReport.byTotalPriceAscending")}
                        </option>
                      ) : (
                        ""
                      )}
                      {this.state.reportType === QUOTE_REPORT ||
                      this.state.reportType === ORDER_REPORT ? (
                        <option
                          className="quote-report-sort-selection"
                          value={sort_TOTAL + "-DESC"}
                        >
                          {this.t("quoteReport.byTotalPriceDescending")}
                        </option>
                      ) : (
                        ""
                      )}
                      {this.state.reportType === QUOTE_REPORT ||
                      this.state.reportType === ORDER_REPORT ? (
                        <option
                          className="quote-report-sort-selection"
                          value={sort_CREATEDBY + "-ASC"}
                        >
                          {this.t("quoteReport.byCreatedAscending")}
                        </option>
                      ) : (
                        ""
                      )}
                      {this.state.reportType === QUOTE_REPORT ||
                      this.state.reportType === ORDER_REPORT ? (
                        <option
                          className="quote-report-sort-selection"
                          value={sort_CREATEDBY + "-DESC"}
                        >
                          {this.t("quoteReport.byCreatedDescending")}
                        </option>
                      ) : (
                        ""
                      )}
                      <option
                        className="quote-report-sort-selection"
                        value={sort_CUSTOMERNAME + "-ASC"}
                      >
                        {this.t("quoteReport.byCustomerNameAscending")}
                      </option>
                      <option
                        className="quote-report-sort-selection"
                        value={sort_CUSTOMERNAME + "-DESC"}
                      >
                        {this.t("quoteReport.byCustomerNameDescending")}
                      </option>
                    </select>
                  </div>
                  {!browserIsIE ? (
                    <div className="quote-report-filter-date">
                      <p>{this.t("quoteReport.filterDate")}: </p>
                      <select
                        className="quote-report-filter-date-select"
                        onChange={this.selectDateFilter.bind(this, this.value)}
                      >
                        <option
                          className="quote-report-filter-date-selection"
                          value={filter_4M}
                        >
                          {this.t("quoteReport.past")} 4{" "}
                          {this.t("quoteReport.months")}
                        </option>
                        <option
                          className="quote-report-filter-date-selection"
                          value={filter_3M}
                        >
                          {this.t("quoteReport.past")} 3{" "}
                          {this.t("quoteReport.months")}
                        </option>
                        <option
                          className="quote-report-filter-date-selection"
                          value={filter_2M}
                        >
                          {this.t("quoteReport.past")} 2{" "}
                          {this.t("quoteReport.months")}
                        </option>
                        <option
                          className="quote-report-filter-date-selection"
                          value={filter_1M}
                        >
                          {this.t("quoteReport.past")} 1{" "}
                          {this.t("quoteReport.months")}
                        </option>
                        <option
                          className="quote-report-filter-date-selection"
                          value={filter_7D}
                        >
                          {this.t("quoteReport.past")} 7{" "}
                          {this.t("quoteReport.days")}
                        </option>
                        <option
                          className="quote-report-filter-date-selection"
                          value={filter_1D}
                        >
                          {this.t("quoteReport.sinceYesterday")}
                        </option>
                        <option
                          className="quote-report-filter-date-selection"
                          value={filter_T}
                        >
                          {this.t("quoteReport.todayOnly")}
                        </option>
                      </select>
                    </div>
                  ) : (
                    false
                  )}
                </div>
                <form className="quote-report-filter-search">
                  <input
                    className="quote-report-search-input"
                    type="text"
                    placeholder={placeholder}
                  />
                  <button
                    type="submit"
                    className="btn"
                    onClick={this.selectSearchInput}
                  >
                    {this.t("quoteReport.search")}
                  </button>
                  <button
                    type="button"
                    className="btn"
                    onClick={this.resetSearchInput}
                  >
                    {this.t("quoteReport.reset")}
                  </button>
                </form>
                <div className="quote-report-filter-count">
                  <p>{this.t("quoteReport.show")}: </p>
                  <select
                    className="quote-report-filter-count-select"
                    onChange={this.selectFilterCount.bind(this, this.value)}
                  >
                    <option
                      className="quote-report-filter-date-selection"
                      value={50}
                    >
                      50
                    </option>
                    <option
                      className="quote-report-filter-date-selection"
                      value={100}
                    >
                      100
                    </option>
                    <option
                      className="quote-report-filter-date-selection"
                      value={150}
                    >
                      150
                    </option>
                    <option
                      className="quote-report-filter-date-selection"
                      value={200}
                    >
                      200
                    </option>
                    <option
                      className="quote-report-filter-date-selection"
                      value={500}
                    >
                      500
                    </option>
                    <option
                      className="quote-report-filter-date-selection"
                      value={9999}
                    >
                      {this.t("quoteReport.all")}
                    </option>
                  </select>
                </div>
                <div className="max-limit-warning">
                  <p>{this.t("quoteReport.quotesMaxLimit")}</p>
                </div>
              </div>
            </div>
            {this.state.reportType === LEAD_REPORT ? (
              <table className="quote-report-table">
                <thead>
                  <tr className="quote-report-table-header">
                    <th>{this.t("quoteReport.type")}</th>
                    <th>{this.t("quoteReport.type")}</th>
                    <th>{this.t("quoteReport.quoteNumber")}</th>
                    <th>{this.t("quoteReport.status")}</th>
                    <th>{this.t("quoteReport.customerName")}</th>
                    <th>{this.t("quoteReport.customerEmail")}</th>
                    <th>{this.t("quoteReport.customerPhone")}</th>
                    <th>{this.t("quoteReport.requestedDate")}</th>
                    <th>{this.t("quoteReport.dateCreated")}</th>
                    <th>{this.t("quoteReport.customerNotes")}</th>
                  </tr>
                </thead>
                <tbody>
                  {leadList.map(function (lead, index) {
                    var className = "quote-report-table-row";
                    if (leadCount % 2 != 0) {
                      className = "quote-report-table-row-odd";
                    }

                    var type,
                      actionType,
                      quoteNumber,
                      status,
                      firstName,
                      lastName,
                      email,
                      phone,
                      date = "",
                      created = "",
                      notes;

                    type = lead.get("ownerType");
                    actionType = lead.get("generatedByActionType");
                    quoteNumber = lead.get("orderId");
                    status = lead.get("customerStatus").get("status");

                    firstName = lead.get("customer").get("firstName");
                    lastName = lead.get("customer").get("lastName");
                    email = lead.get("customer").get("email");
                    phone = lead.get("customer").get("phone");

                    if (lead.get("date")) {
                      date = lead.get("date").get("date");
                      date = moment_format(Moment(date));
                    }

                    if (lead.get("createdTimestamp")) {
                      created = lead.get("createdTimestamp").get("date");
                      created = moment_format(Moment(created));
                    }

                    notes = lead.get("message");

                    leadCount++;
                    return (
                      <tr className={className} key={"quote-row-" + index}>
                        <td>{type}</td>
                        <td>{actionType}</td>
                        <td>{quoteNumber}</td>
                        <td>{status}</td>
                        <td>{firstName + " " + lastName}</td>
                        <td>{email}</td>
                        <td>{phone}</td>
                        <td>{date}</td>
                        <td>{created}</td>
                        <td>{notes}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              false
            )}
            {this.state.reportType === QUOTE_REPORT ? (
              <table className="quote-report-table">
                <thead>
                  <tr className="quote-report-table-header">
                    <th>{this.t("quoteReport.type")}</th>
                    <th>{this.t("quoteReport.quoteNumber")}</th>
                    <th>{this.t("quoteReport.createdBy")}</th>
                    <th>{this.t("quoteReport.customerName")}</th>
                    <th>{this.t("quoteReport.customerEmail")}</th>
                    <th>{this.t("quoteReport.customerPhone")}</th>
                    <th>{this.t("quoteReport.dateCreated")}</th>
                    <th>{this.t("quoteReport.quoteTotal")}</th>
                    <th>{this.t("quoteReport.customerNotes")}</th>
                    <th>{this.t("quoteReport.orderPrinted")}</th>
                    <th>{this.t("quoteReport.priceMatch")}</th>
                    <th>{this.t("quoteReport.status")}</th>
                    <th>{this.t("quoteReport.viewQuotes")}</th>
                  </tr>
                </thead>
                <tbody>
                  {quoteList.map(function (quote, index) {
                    var date = new Date(
                      quote
                        .get("orderCreatedTimestamp")
                        .replace("+0000", ".000Z")
                    );
                    var updateDate = new Date(
                      quote
                        .get("orderLastUpdatedTimestamp")
                        .replace("+0000", ".000Z")
                    );

                    var updateDateFormatted = false;
                    if (
                      updateDate.toLocaleDateString() !=
                      date.toLocaleDateString()
                    ) {
                      updateDateFormatted =
                        "(" + updateDate.toLocaleDateString() + ")";
                    }

                    var grandTotal = money_format(quote.get("orderGrandTotal"));
                    var href = "#";
                    if (
                      quote.get("orderAbsoluteUrl") !== null &&
                      typeof quote.get("orderAbsoluteUrl") !== "undefined"
                    ) {
                      href = quote.get("orderAbsoluteUrl").get(language);
                    }

                    var className = "quote-report-table-row";
                    if (quoteCount % 2 != 0) {
                      className = "quote-report-table-row-odd";
                    }
                    quoteCount++;
                    return (
                      <tr className={className} key={"quote-row-" + index}>
                        <td>
                          {quote.get("isNational") === "1"
                            ? nationalLead
                            : dealerLead}
                        </td>
                        <td>
                          <a
                            className="quote-report-link"
                            href={href}
                            target="_blank"
                          >
                            {quote.get("orderId")}
                          </a>
                        </td>
                        <td>{quote.get("orderCreatedUserName")}</td>
                        <td>{quote.get("customerName")}</td>
                        <td>{quote.get("customerEmail")}</td>
                        <td>{quote.get("customerPhone")}</td>
                        <td>
                          {date.toLocaleDateString()}{" "}
                          {date.toLocaleTimeString()} ({est}){" "}
                          {updateDateFormatted}
                        </td>
                        <td>{grandTotal}</td>
                        <td>
                          {quote.get("customerNotes")
                            ? _this.t("common.yes")
                            : ""}
                        </td>
                        <td>
                          {quote.get("orderIsPrinted")
                            ? _this.t("common.yes")
                            : ""}
                        </td>
                        <td>
                          {quote.get("orderIsPriceMatched")
                            ? _this.t("common.yes")
                            : ""}
                        </td>
                        <td>
                          <span>
                            {quote && quote.get("orderStatusDescription")
                              ? quote
                                  .get("orderStatusDescription")
                                  .get(language)
                              : false}
                          </span>
                        </td>
                        <td>
                          <a
                            className="quote-report-link-blue"
                            href={href}
                            target="_blank"
                          >
                            {details}
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              false
            )}
            {this.state.reportType === ORDER_REPORT ? (
              <table className="quote-report-table">
                <thead>
                  <tr className="quote-report-table-header">
                    <th>{this.t("quoteReport.type")}</th>
                    <th>{this.t("quoteReport.orderNumber")}</th>
                    <th>{this.t("quoteReport.createdBy")}</th>
                    <th>{this.t("quoteReport.customerName")}</th>
                    <th>{this.t("quoteReport.customerEmail")}</th>
                    <th>{this.t("quoteReport.customerPhone")}</th>
                    <th>{this.t("quoteReport.dateCreated")}</th>
                    <th>{this.t("quoteReport.orderTotal")}</th>
                    <th>{this.t("quoteReport.customerNotes")}</th>
                    <th>{this.t("quoteReport.poNumber")}</th>
                    <th>{this.t("quoteReport.confirmationNumber")}</th>
                    <th>{this.t("quoteReport.deliveryStatus")}</th>
                    <th>{this.t("quoteReport.viewOrders")}</th>
                  </tr>
                </thead>
                <tbody>
                  {orderList.map(function (order, index) {
                    var date = new Date(
                      order
                        .get("orderCreatedTimestamp")
                        .replace("+0000", ".000Z")
                    );
                    var updateDate = new Date(
                      order
                        .get("orderLastUpdatedTimestamp")
                        .replace("+0000", ".000Z")
                    );

                    var updateDateFormatted = false;
                    if (
                      updateDate.toLocaleDateString() !=
                      date.toLocaleDateString()
                    ) {
                      updateDateFormatted =
                        "(" + updateDate.toLocaleDateString() + ")";
                    }

                    var grandTotal = money_format(order.get("orderGrandTotal"));
                    var href = "#";
                    if (
                      order.get("orderAbsoluteUrl") !== null &&
                      typeof order.get("orderAbsoluteUrl") !== "undefined"
                    ) {
                      href = order.get("orderAbsoluteUrl").get(language);
                    }

                    var hrefStatus = "#";

                    var orderFulfillment = order.get("orderFulfillmentList");
                    var orderFulfillmentExists = sizeOf(orderFulfillment) > 0;
                    if (orderFulfillmentExists) {
                      var poNumbers = orderFulfillment.map(function (order) {
                        return (
                          <span>
                            {order &&
                            order.get("fulfillment_value") &&
                            order
                              .get("fulfillment_value")
                              .get("customerPoNumber")
                              ? order
                                  .get("fulfillment_value")
                                  .get("customerPoNumber")
                              : "N/A"}
                          </span>
                        );
                      });
                      var coNumbers = orderFulfillment.map(function (order) {
                        return (
                          <span>
                            {order &&
                            order.get("fulfillment_value") &&
                            order.get("fulfillment_value").get("orderNumber")
                              ? order
                                  .get("fulfillment_value")
                                  .get("orderNumber")
                              : "N/A"}
                          </span>
                        );
                      });
                    }

                    var className = "quote-report-table-row";
                    if (orderCount % 2 != 0) {
                      className = "quote-report-table-row-odd";
                    }
                    orderCount++;
                    return (
                      <tr className={className} key={"order-row-" + index}>
                        <td>
                          {order.get("isNational") === "1"
                            ? nationalLead
                            : dealerLead}
                        </td>
                        <td>
                          <a
                            className="quote-report-link"
                            href={href}
                            target="_blank"
                          >
                            {order.get("orderId")}
                          </a>
                        </td>
                        <td>{order.get("orderCreatedUserName")}</td>
                        <td>{order.get("customerName")}</td>
                        <td>{order.get("customerEmail")}</td>
                        <td>{order.get("customerPhone")}</td>
                        <td>
                          {date.toLocaleDateString()}{" "}
                          {date.toLocaleTimeString()} ({est}){" "}
                          {updateDateFormatted}
                        </td>
                        <td>{grandTotal}</td>
                        <td>
                          {order.get("customerNotes")
                            ? _this.t("common.yes")
                            : ""}
                        </td>
                        <td>{orderFulfillmentExists ? poNumbers : false}</td>
                        <td>{orderFulfillmentExists ? coNumbers : false}</td>
                        <td>
                          <span>
                            <a
                              onClick={_this.launchGetStatusModal.bind(
                                _this,
                                order.get("orderId"),
                                order.get("orderNumber"),
                                OrderTypes.CONSUMER_ORDER
                              )}
                            >
                              {_this.t("quoteReport.checkStatus")}
                            </a>
                          </span>
                        </td>
                        <td>
                          <a
                            className="quote-report-link-blue"
                            href={href}
                            target="_blank"
                          >
                            {details}
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              false
            )}
          </div>
        ) : (
          false
        )}
        {sizeOf(this.state.quotes) == 0 && !this.state.quoteReportLoading ? (
          <div className="quote-report">
            <h1 className="quote-report-error">
              {this.t("quoteReport.missingOrAccessNotAllowMessage")}
            </h1>
          </div>
        ) : (
          false
        )}
        {sizeOf(this.state.quotes) == 0 && this.state.quoteReportLoading ? (
          <div className="quote-report">
            <div className="loading-page">
              <h1>{this.t("loadingPage")}</h1>
              <Image src="/static/img/loading_icon.gif" />
            </div>
          </div>
        ) : (
          false
        )}
        {this.state.showOrderStatusReport ? (
          <OrderStatusReport
            closeReport={this.closeGetStatusModal}
            orderId={this.state.orderStatusOrderId}
            orderNumber={this.state.orderStatusOrderNumber}
            orderType={this.state.orderStatusOrderType}
          />
        ) : (
          false
        )}
      </Modal>
    );
  }

  sortList = (list) => {
    var selectedFilterDate = this.props.filterDate;
    var selectedSortType = this.props.sortType;
    var sortByFlipFlop = this.props.sortbyFlip;
    var searchInput = this.props.searchInput;
    var numQuotesToShow = this.props.numQuote;
    return list
      .filter(function (order) {
        if (selectedFilterDate == filter_4M) {
          var date = new Date(
            order.get("orderCreatedTimestamp").replace("+0000", ".000Z")
          );
          if (typeof searchInput === "string")
            searchInput = searchInput.toLowerCase();
          return (
            searchInput == null ||
            order.get("orderGrandTotal").indexOf(searchInput) != -1 ||
            date.toString().indexOf(searchInput) != -1 ||
            date.toLocaleDateString().indexOf(searchInput) != -1 ||
            date.toLocaleTimeString().indexOf(searchInput) != -1 ||
            order.get("customerPhone").indexOf(searchInput) != -1 ||
            order.get("customerName").toLowerCase().indexOf(searchInput) !=
              -1 ||
            order
              .get("orderCreatedUserName")
              .toLowerCase()
              .indexOf(searchInput) != -1 ||
            order.get("orderId").indexOf(searchInput) != -1
          );
        } else {
          var date = new Date(
            order.get("orderCreatedTimestamp").replace("+0000", ".000Z")
          );
          var dateConstraint = new Date();

          switch (selectedFilterDate) {
            case filter_3M:
              dateConstraint.setMonth(dateConstraint.getMonth() - 3);
              break;
            case filter_2M:
              dateConstraint.setMonth(dateConstraint.getMonth() - 2);
              break;
            case filter_1M:
              dateConstraint.setMonth(dateConstraint.getMonth() - 1);
              break;
            case filter_7D:
              dateConstraint.setDate(dateConstraint.getDate() - 7);
              break;
            case filter_1D:
              dateConstraint.setDate(dateConstraint.getDate() - 2);
              break;
            case filter_T:
              dateConstraint.setDate(dateConstraint.getDate() - 1);
              break;
          }

          return (
            (searchInput == null ||
              order.get("notes").indexOf(searchInput) != -1 ||
              order.get("orderGrandTotal").indexOf(searchInput) != -1 ||
              date.toString().indexOf(searchInput) != -1 ||
              date.toLocaleDateString().indexOf(searchInput) != -1 ||
              date.toLocaleTimeString().indexOf(searchInput) != -1 ||
              order.get("customerPhone").indexOf(searchInput) != -1 ||
              order.get("customerName").indexOf(searchInput) != -1 ||
              order.get("orderCreatedUserName").indexOf(searchInput) != -1 ||
              order.get("orderId").indexOf(searchInput) != -1) &&
            date.getTime() >= dateConstraint.getTime()
          );
        }
      })
      .sort(function (a, b) {
        if (selectedSortType == sort_DATE) {
          var date1 = new Date(
            a.get("orderCreatedTimestamp").replace("+0000", ".000Z")
          );
          var date2 = new Date(
            b.get("orderCreatedTimestamp").replace("+0000", ".000Z")
          );
          return date1.getTime() > date2.getTime()
            ? parseFloat(sortByFlipFlop)
            : parseFloat(sortByFlipFlop * -1);
        } else if (selectedSortType == sort_TOTAL) {
          var total1 = parseFloat(a.get("orderGrandTotal"));
          var total2 = parseFloat(b.get("orderGrandTotal"));
          return parseFloat(total1) > parseFloat(total2)
            ? parseFloat(sortByFlipFlop)
            : parseFloat(sortByFlipFlop * -1);
        } else if (selectedSortType == sort_CREATEDBY) {
          var name1 = a.get("orderCreatedUserName").toLowerCase();
          var name2 = b.get("orderCreatedUserName").toLowerCase();
          if (name1 == "") return 1;
          else if (name2 == "") return -1;
          else if (name1 > name2) return parseFloat(sortByFlipFlop);
          else if (name1 < name2) return parseFloat(sortByFlipFlop * -1);
          else return 0;
        } else if (selectedSortType == sort_CUSTOMERNAME) {
          var name1 = a.get("customerName").toLowerCase();
          var name2 = b.get("customerName").toLowerCase();
          if (name1 == "") return 1;
          else if (name2 == "") return -1;
          else if (name1 > name2 || name2 == "")
            return parseFloat(sortByFlipFlop);
          else if (name1 < name2) return parseFloat(sortByFlipFlop * -1);
          else return 0;
        } else {
          return true;
        }
      })
      .take(numQuotesToShow);
  };

  sortLeadList = (list) => {
    var selectedFilterDate = this.props.filterDate;
    var selectedSortType = this.props.sortType;
    var sortByFlipFlop = this.props.sortbyFlip;
    var searchInput = this.props.searchInput;
    var numQuotesToShow = this.props.numQuote;
    return list
      .filter(function (lead) {
        var orderId = lead.get("orderId") ? lead.get("orderId") : "";
        if (selectedFilterDate == filter_4M) {
          var date = new Date(
            lead.get("createdTimestamp").get("date").replace("+0000", ".000Z")
          );
          if (typeof searchInput === "string")
            searchInput = searchInput.toLowerCase();
          return (
            searchInput == null ||
            lead.get("customer").get("firstName").indexOf(searchInput) != -1 ||
            lead.get("customer").get("lastName").indexOf(searchInput) != -1 ||
            lead.get("customer").get("email").indexOf(searchInput) != -1 ||
            lead.get("customer").get("phone").indexOf(searchInput) != -1 ||
            lead.get("message").indexOf(searchInput) != -1 ||
            date.toString().indexOf(searchInput) != -1 ||
            date.toLocaleDateString().indexOf(searchInput) != -1 ||
            date.toLocaleTimeString().indexOf(searchInput) != -1 ||
            orderId.indexOf(searchInput) != -1 ||
            lead.get("ownerType").indexOf(searchInput) != -1 ||
            lead.get("generatedByActionType").indexOf(searchInput) != -1
          );
        } else {
          var date = new Date(
            lead.get("createdTimestamp").get("date").replace("+0000", ".000Z")
          );
          var dateConstraint = new Date();
          switch (selectedFilterDate) {
            case filter_3M:
              dateConstraint.setMonth(dateConstraint.getMonth() - 3);
              break;
            case filter_2M:
              dateConstraint.setMonth(dateConstraint.getMonth() - 2);
              break;
            case filter_1M:
              dateConstraint.setMonth(dateConstraint.getMonth() - 1);
              break;
            case filter_7D:
              dateConstraint.setDate(dateConstraint.getDate() - 7);
              break;
            case filter_1D:
              dateConstraint.setDate(dateConstraint.getDate() - 2);
              break;
            case filter_T:
              dateConstraint.setDate(dateConstraint.getDate() - 1);
              break;
          }
        }

        return (
          (searchInput == null ||
            lead.get("customer").get("firstName").indexOf(searchInput) != -1 ||
            lead.get("customer").get("lastName").indexOf(searchInput) != -1 ||
            lead.get("customer").get("email").indexOf(searchInput) != -1 ||
            lead.get("customer").get("phone").indexOf(searchInput) != -1 ||
            lead.get("message").indexOf(searchInput) != -1 ||
            date.toString().indexOf(searchInput) != -1 ||
            date.toLocaleDateString().indexOf(searchInput) != -1 ||
            date.toLocaleTimeString().indexOf(searchInput) != -1 ||
            orderId.indexOf(searchInput) != -1 ||
            lead.get("ownerType").indexOf(searchInput) != -1 ||
            lead.get("generatedByActionType").indexOf(searchInput) != -1) &&
          date.getTime() >= dateConstraint.getTime()
        );
      })
      .sort(function (a, b) {
        if (selectedSortType == sort_DATE) {
          var date1 = new Date(
            a.get("createdTimestamp").get("date").replace("+0000", ".000Z")
          );
          var date2 = new Date(
            b.get("createdTimestamp").get("date").replace("+0000", ".000Z")
          );
          return date1.getTime() > date2.getTime()
            ? parseFloat(sortByFlipFlop)
            : parseFloat(sortByFlipFlop * -1);
        } else if (selectedSortType == sort_TOTAL) {
          return true;
        } else if (selectedSortType == sort_CREATEDBY) {
          return true;
        } else if (selectedSortType == sort_CUSTOMERNAME) {
          var firstName_a, lastName_a, firstName_b, lastName_b;

          firstName_a = a.get("customer").get("firstName");
          lastName_a = a.get("customer").get("lastName");

          firstName_b = b.get("customer").get("firstName");
          lastName_b = b.get("customer").get("lastName");

          var name1 = firstName_a + " " + lastName_a;
          name1 = name1.trim().toLowerCase();
          var name2 = firstName_b + " " + lastName_b;
          name2 = name2.trim().toLowerCase();
          if (name1 == "") return 1;
          else if (name2 == "") return -1;
          else if (name1 > name2 || name2 == "")
            return parseFloat(sortByFlipFlop);
          else if (name1 < name2) return parseFloat(sortByFlipFlop * -1);
          else return 0;
        } else {
          return true;
        }
      })
      .take(numQuotesToShow);
  };

  selectReportType = (type) => {
    this.setState({
      reportType: type,
    });
  };

  selectSort = (sort, x) => {
    var temp = x.target.value.split("-");
    var type = temp[0];
    var direction = temp[1];
    console.log(type, "this is our direction");
    this.props.dispatch(
      quoteReportUpdateSort({
        newType: type,
        load: direction == "DESC" ? sort_DESC : sort_ASC,
      })
    );
    // applicationActions.quoteReportUpdateSort(type, direction == 'DESC' ? sort_DESC : sort_ASC);
  };

  selectDateFilter = (filter, x) => {
    console.log(x.target.value, "this is our date filter");
    this.props.dispatch(quoteDateFilter(x.target.value));

    // applicationActions.quoteReportUpdateDateFilter(x.target.value);
  };

  selectFilterCount = (filter, x) => {
    console.log(x.target.value, "this is our filterCount");
    this.props.dispatch(quoteFilterCount(x.target.value));
    // applicationActions.quoteReportUpdateFilterCount(x.target.value);
  };

  selectSearchInput = (ev) => {
    var search = document.getElementsByClassName("quote-report-search-input");
    this.props.dispatch(quoteSearchInput(search[0].value));
    // applicationActions.quoteReportUpdateSearchInput(search[0].value);
    ev.preventDefault();
    ev.stopPropagation();
  };

  resetSearchInput = () => {
    document.getElementsByClassName("quote-report-search-input")[0].value = "";
    this.props.dispatch(quoteSearchInput(null));
    // applicationActions.quoteReportUpdateSearchInput(null);
  };

  closeQuoteReport = () => {
    setTimeout(() => {
      this.props.dispatch(showQuoteReport(false));
      applicationActions.closeQuoteReport();
    }, 10);
  };

  launchGetStatusModal = (orderId, orderNumber, orderType) => {
    this.setState({
      showOrderStatusReport: true,
      orderStatusOrderId: orderId,
      orderStatusOrderNumber: orderNumber,
      orderStatusOrderType: orderType,
    });
  };

  closeGetStatusModal = () => {
    applicationActions.closeOrderStatusModal();
    this.setState({
      showOrderStatusReport: false,
      orderStatusOrderId: null,
      orderStatusOrderNumber: null,
      orderStatusOrderType: null,
    });
  };

  onChange = () => {
    this.setState({
      quotes: applicationStore.data.quoteList,
      leads: applicationStore.data.leadList,
      orders: shoppingCartStore.data.get("ntdOrderHistory"),
      numQuotesToShow: applicationStore.data.QuoteReportNumQuotesToShow,
      searchInput: this.props.searchInput,
      selectedFilterDate: applicationStore.data.quoteReportSelectedFilterDate,
      selectedSortType: applicationStore.data.quoteReportSelectedSortType,
      sortByFlipFlop: applicationStore.data.quoteReportSortByFlipFlop, // Descending: -1, Ascending: 1
      quoteReportLoading: applicationStore.isApiCallInProgress(),
    });
  };
}

function mapStateToProps(state) {
  console.log(state.quote.sortByFlipFlop);
  return {
    numQuote: state.quote.numQuotesToShow,
    sortbyFlip: state.quote.sortByFlipFlop,
    filterDate: state.quote.sortFilterDate,
    sortType: state.quote.sortType,
    searchInput: state.quote.searchInput,
    showLeadReport: state.quote.showLeadReport,
  };
}
export default connect(mapStateToProps)(QuoteReport);

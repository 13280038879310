module.exports = {
    'quote': {
        'quote': 'Quote',
        'quoteFor': 'Quote for',
        'addToQuote': 'Add',
        'remove': 'Remove',
        'subtotal': 'Subtotal',
        'tax': 'Tax',
        'total': 'Total',
        'emailQuote': 'E-mail quote',
        'printQuote': 'Print quote',
        'saveQuote': 'Request a Service Appointment',
        'emailHasBeenSent': 'Quote has been sent to the provided e-mail address.',
        'appointmentSent': 'Appointment has been successfully requested',
        'emailFieldLabel': 'E-mail',
        'nameFieldLabel': 'Name',
        'savedThroughOnePromotion': 'You have saved {0} through our {1} Promotion',
        'savedThroughMultiplePromotions': 'You have saved {0} through {1} Promotions',
        'included': 'Included',
        'requestAppointment': 'Request Appointment',
        'day': 'Day',
        'partOfDay': 'Part of a day'
    },
    'dealerFilter': {
        'titlePrefix': 'Find a',
        'titleSuffix': 'Canada Dealer'
    }
};
import { I18nComponent } from "../../i18n/I18nComponent.jsx";
import { i18nMixin } from "../../i18n/i18nMixin.js";

import {
  getTabIndex,
  money_format,
  getAppLanguage,
} from "../../service/UtilityService";

var React = require("react");
var Cx = require("classnames");

export class WishlistReviewLineItem extends I18nComponent {
  state = {
    tabIndex: parseInt(this.props.tabIdx, 10),
  };

  static defaultProps = {
    quantity: 0,
    title: null,
    description: null,
    price: 0,
    onRemove: this.emptyFn,
    onAdd: this.emptyFn,
    hasRemoveButton: false,
    hasAddButton: false,
    isPriceMatch: false,
  };

  emptyFn() {}

  render() {
    var itemClass = Cx({
      required: !this.props.hasAddButton,
      optional: this.props.hasAddButton,
      "no-print": this.props.hasAddButton,
      "grey-out":
        (this.props.hasPriceMatch && this.props.hasRemoveButton) ||
        parseFloat(this.props.price) !== 0,
    });

    var button = false;
    if (this.props.hasRemoveButton) {
      button = this.renderRemoveButton();
    } else if (this.props.hasPriceMatch) {
      button = this.renderPriceMatchButton();
    } else {
      button = this.renderAddButton();
    }

    /*

	   (this.state.tabIndex++).toString()

	   */

    /* TODO
     *  Once unitPrice is returned from backend, use it
     *  rather than dividing total price by quantity.
     */

    return (
      <tr className={itemClass}>
        <td
          className="item-name-optional"
          tabIndex={getTabIndex()}
          aria-label={this.props.title}
        >
          <span className="item-name-optional">{this.props.title}</span>
          {this.displayDescription()}
        </td>
        <td className="closable"></td>
        <td
          className="closable"
          tabIndex={this.props.price !== 0 ? getTabIndex() : "-1"}
          aria-label={
            this.props.price !== 0 && this.props.quantity !== 0
              ? money_format(
                  this.props.price / this.props.quantity,
                  getAppLanguage()
                )
              : ""
          }
        >
          {this.props.price !== 0 && this.props.quantity !== 0
            ? money_format(this.props.price / this.props.quantity)
            : false}
        </td>
        <td
          className=""
          tabIndex={!this.props.hasPriceMatch ? getTabIndex() : "-1"}
          aria-label={this.n(this.props.quantity)}
        >
          {!this.props.hasPriceMatch ? this.n(this.props.quantity) : false}
        </td>
        <td className="closable"></td>
        <td
          className="price"
          tabIndex={getTabIndex()}
          aria-label={
            this.props.price !== 0
              ? this.c(this.props.price)
              : this.t("shoppingCartView.included")
          }
        >
          {this.renderPrice()}
          <div className="controls-line">{button}</div>
        </td>
      </tr>
    );
  }

  renderPrice = () => {
    if (
      (this.props.hasPriceMatch && this.props.hasRemoveButton) ||
      parseFloat(this.props.price) !== 0 ||
      (this.props.hasAddButton && parseFloat(this.props.price) === 0)
    ) {
      return <span className="line-through">{this.c(this.props.price)}</span>;
    } else if (!this.props.hasPriceMatch) {
      return this.t("shoppingCartView.included");
    } else {
      return false;
    }
  };

  displayDescription = () => {
    if (this.props.description) {
      return (
        <span className="details text-small">
          ({this.props.description}){this.renderIsNotTaxable()}
        </span>
      );
    } else if (this.props.hasPriceMatch && this.props.notes) {
      return (
        <span className="details text-small">
          ({this.props.notes}){this.renderIsNotTaxable()}
        </span>
      );
    } else if (this.props.notTaxable) {
      return (
        <span className="details text-small">
          {this.renderIsNotTaxable(true)}
        </span>
      );
    } else {
      return false;
    }
  };

  renderIsNotTaxable = (nospace = false) => {
    if (this.props.notTaxable) {
      return (nospace ? "" : " ") + "(" + this.t("quote.nonTaxable") + ")";
    } else {
      return false;
    }
  };

  renderRemoveButton = () => {
    if (this.props.hasRemoveButton) {
      return (
        <span
          className="button button-remove no-print"
          role="button"
          tabIndex={getTabIndex()}
          aria-label={this.t("shoppingCartView.remove")}
          onClick={this.props.onRemove.bind(this, this.props.lineItem)}
          onKeyDown={(e) => this.props.onRemoveHandleEvent(e)}
        >
          <i className="fa fa-remove"></i>{" "}
          <span className="button-text">
            {this.t("shoppingCartView.remove")}
          </span>
        </span>
      );
    } else {
      return false;
    }
  };

  renderAddButton = () => {
    if (this.props.hasAddButton) {
      return (
        <span
          className="button button-add"
          role="button"
          tabIndex={getTabIndex()}
          aria-label={this.t("shoppingCartView.addToShoppingCartView")}
          onClick={this.props.onAdd.bind(this, this.props.lineItem)}
          onKeyDown={(e) => this.props.onAddHandleEvent(e)}
        >
          <i className="fa fa-cart-plus"></i>{" "}
          <span className="button-text">
            {this.t("shoppingCartView.addToShoppingCartView")}
          </span>
        </span>
      );
    } else {
      return false;
    }
  };

  renderPriceMatchButton = () => {
    return (
      <span
        className="button button-price-match"
        onClick={this.props.onPriceMatch}
      >
        <a className="btn">{this.t("shoppingCartView.priceMatch")}</a>
      </span>
    );
  };
}

module.exports = {
  'startNewQuote':'Start New Wishlist',
  'title':'Mercedes-Benz',
  'ourBrands':'Mercedes-Benz ApprovedTire Brands',
  'MO':'Mercedes Original',
  'MOE':'Mercedes Original Extended',

  homepage_long_1: 'Why choose MO Tires? When it comes to delivering optimal performance - and meeting your high standards - Mercedes Original (MO) Tires are the ideal match for your vehicle. Specifically designed for an exact fit and configured to work seamlessly with the safety systems inside a Mercedes-Benz, MO Tires provide maximum reliability, longevity, excellent ride confort, and low noise levels.',
  homepage_long_2: 'MOE tires feature run-flat properties thanks to renforced side walls so you can drive to safety in the event of an unexpected tire pressure loss for 80km at a maximum speed of 80 km/h. MOE tires are used exclusively in conjunction with a tire pressure monitoring system.',
  homepage_long_3_a: 'The right tires designed to work seamlessly with the safety systems in your Mercedes',
  homepage_long_3_b: 'Mercedes-Benz Approved Winter Tires have advanced tread designs and rubber compounds that provide a superior grip on cold and icy roads',
  homepage_long_3_c: 'Competitive tire prices',
  homepage_long_3_d: 'Expert installation by factory-trained technicians',
  homepage_long_3_e: 'All major brands, including Pirelli, Continental, Michelin, Dunlop, and more',
  homepage_long_4: 'Mercedes-Benz has skillfully designed wheels that harmoniously complement the bodywork and contribute to the overall design quality of our vehicles. Our wheels undergo extensive development and a testing program to measure the actual stress profile of light-alloy wheels under real operating conditions. This ensures that our light-alloy wheels are among the safest, most reliable and durable products available in the market today. In addition, only the most reputed and best suppliers are selected to manufacture the light-alloy wheels worldwide, and production subject to stringent Mercedes-Benz specifications.',
  homepage_title: 'Why Buy Mercedes-Benz ApprovedTires',
  homepage_winterpackages: 'Winter Tire & Wheel Packages',
  homepage_genuinewheels: 'Genuine Wheels',
  homepage_wheeldevelopment: 'Mercedes-Benz Original (MO)',
  homepage_design: 'Mercedes-Benz Original Extended (MOE)',
  homepage_quality: 'Reason to Purchase ApprovedTires from an Authorized Mercedes-Benz Dealer',

  nonOEWarrning: 'You have selected showing non approved tires. Mercedes always recommends using approved tires that are designed to your vehicle.',

  'logo': {
    'text': 'Mercedes ApprovedTire and Wheel Center'
  },
  'header': {
    'mercedesHeader': 'Approved TireCentre'
  },
  'common': {
    'viewTires': 'Find Tires',
    'viewWheels': 'Find Wheels',
    'viewAccessories': 'Find Accessories',
    'viewPackages': 'Find Packages',
    'viewCollections': 'Find Collections',
    'welcomeMessage': 'Welcome to the Mercedes ApprovedTire and Wheel Center',
    'getStarted': 'Get Started',
    'customizeYourRide': 'Customize your ride',
    'myWishlist': 'Wishlist',
    'select': 'Select',
    'yes': 'Yes',
    'no': 'No',
    'featured': 'Winter Tire Packages',
    'enter': 'Enter',
    'dealerSelectionPlaceholder': 'Search by city, province or postal code',
    'selectByProvinceTitle': 'Select your Province',
    'selectByProvinceHeaderTitle': 'ApprovedTire Centre',
    'myCart': 'My Wishlist'
  },
  'breadcrumbs': {
    'staticHome': 'Home'
  },
  'cart': {
    'getCartReview': 'Review My Wishlist',
    'cartBarTitlePrefix': 'Wishlist Preview',
    'cartBarTitleSuffix1': 'Item',
    'cartBarTitleSuffix2': 'Items',
    'mywishlist': 'Wishlist'
  },
  'search': {
    'placeholderSearchTireSize': '225/45R17 or 2254517',
    'placeholderSearchPartNumber': 'CON15480150000AS or 15480150000',
    'placeholderSearchChassis': 'w204 or w204.012',
    'searchChassisTag': 'Chassis Search'
  },
  'productTypes': {
    'packages': 'Winter Tire Packages',
    'packagesTab': 'Winter Tire Packages',
	  'searchTires': 'Mercedes Original Tires',//''Mercedes-Benz ApprovedTires',
	  'searchWheels': 'Genuine Wheels',
    'searchPackages': 'Winter Tire Packages',
	  'tireSelectText': 'Mercedes-Benz "Approved" Winter Tires have advanced tread designs and rubber compounds that provide a superior grip on cold and icy roads. They also provide greater stability when breaking, and better vehicle handling.',
	  'wheelSelectText': 'Mercedes-Benz uses an extensive development and testing program to ensure the quality of its various wheels. We tailor the development and testing phase to the actual stress profile of light-alloy wheels under real operating conditions – ensuring that our light-alloy wheels are among the most safe, reliable and durable products available in the car market today.',
	  'accessorySelectText': '',
	  'packageSelectText': 'Tires are a vital part of your Mercedes-Benz performance. They\'re the only contact your vehicle has with the road, and affect acceleration, braking, cornering, and the overall safety of your vehicle.'
  },
  'product': {
    'showOnlyApproved': 'Show Only ApprovedTires',
    'approvedTires': 'ApprovedTires',
    'nonApprovedTires': 'Non ApprovedTires',
    'good': '',
    'better': '',
    'best': '',
    'dealerPrice': 'Price',
    'retailPrice': 'Price',
    'wheel': 'Mercedes-Benz Approved Wheels',
    'wheelTag': '',
    'tire': 'Mercedes Original Tires',//'Mercedes-Benz ApprovedTires',
    'tireTag': 'Find the right Mercedes-Benz ApprovedTires by vehicle or tiresize',
    'accessory': 'Mercedes-Benz Approved Accessories.',
    'accessoryTag': '',
    'package': 'Winter Tire Packages',
    'packageTag': 'Find the right Tire and Wheel package for your vehicle',
    'collection': 'Collection',
    'collectionTag': '',
    'addToQuote': 'Add to Wishlist',
    'addToWishlist': 'Add to Wishlist',
    'removeFromWishlist': 'Remove from Wishlist',
    'itemAlert': '!Please see more details for vehicle applications',
	  'approvedTire': 'Mercedes Original Tires',//'Mercedes-Benz ApprovedTires',
    'nationalPartNumber': 'Part #',
    'tireSize': 'Size'
  },
  'partInfoModal': {
    'regionalPrice': 'MB Price (Region)'
  },
  'appointmentForm': {
    'printWishlist': 'Print Wishlist'
  },
  'launcher': {
    'viewResults': 'Shop Now',
  },
  'wishlist': {
    'title': 'Your Wishlist',
    'clear': 'Clear Wishlist',
    'saveWishlist': 'Save Wishlist',
    'emailWishlist': 'Email Wishlist',
    'scheduleAppointmentMsg': 'Schedule an appointment with your retailer for your wishlist',
    'personalizedWishlist': 'Your Personalized Wishlist',
    'wishlistSaved': 'Wishlist has been successfully saved.',
    'wishlistSaveFailed': 'Wishlist has failed to save.'
  },
  'guestAccountCreation': {
    'noEmail': 'Create an Account'
  }
};

import { productListStore } from "../../stores/ProductListStore.js";
import { productListActions } from "../../actions/ProductListActions.js";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { breadcrumbsStore } from "../../stores/BreadcrumbsStore";
import { applicationActions } from "../../actions/ApplicationActions.js";
import { vehicleSelectionStore } from "../../stores/VehicleSelectionStore.js";
import { vehicleSelectionActions } from "../../actions/VehicleSelectionActions.js";
import { searchActions } from "../../actions/SearchActions";

import ProductTile from "./ProductTile.jsx";

import Breadcrumbs from "../breadcrumbs/Breadcrumbs.jsx";

import { ComparisonModal } from "../productlist/ComparisonModal.jsx";

import { Modal } from "../ux/Modal.jsx";

import { FilterPanel } from "../filter/FilterPanel.jsx";

import { SearchTypes } from "../../constants/SearchTypes.js";
import { ProductTypes } from "../../constants/ProductTypes.js";
import { PricingFilter } from "../../constants/PricingFilter.js";

import { encodeBase64List } from "../../service/PdfService.js";
import { getInitialQuantity } from "../../service/ProductList";
import { guardRequiresTokenEvent } from "../../service/CaptchaService.js";
import {
  sizeOf,
  isEmpty,
  objectSize,
  getAppLanguage,
  getRequireCaptcha,
  getTrackingEventCategory,
  getProductListNumberResultsToShow,
  scrollToPageTop,
  isConsumerSite,
  getRouterState,
  getSeasonText,
} from "../../service/UtilityService.js";
import {
  getComparisonDocDefinition,
  getComparisonPdfFilename,
} from "../../service/PdfComparisonService.js";
import {
  getGuideDocDefinition,
  getGuidePdfFilename,
} from "../../service/PdfGuideService.js";
import { getTestId, TEST_LIST } from "../../service/TestingService";
import { getVehicleFilterInfo } from "../../service/FilterService.js";
import {
  getFilteredPlyList,
  getFilteredTagList,
  getFilteredBrandList,
  getFilteredPricingList,
  getFilteredPricingShadowList,
  getFilteredSeasonList,
  getFilteredTiresizeList,
  getFilteredLoadList,
  getFilteredSpeedList,
  getFilteredRunflatList,
  getFilteredOeList,
  getFilteredCategoryList,
  isValidSeason,
  isValidWidth,
} from "../../service/VehicleService";
import { RouterStates } from "../../constants/RouterStates";
import { isUserLoggedIn } from "../../api/AuthService";
import { renderTemplateLogo } from "../../service/LogoService";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  runAdobeServicePageView,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";
import {
  closeTireNotOriginalSizeWarning,
  filterBySeason,
  filterByTireSize,
  onAddProductToComparison,
  onSetComparisonPdfLauncher,
  onSetGuidePdfLauncher,
  removeProductFromComparison,
  setOeFilterValue,
  setTireSize,
} from "../../redux/features/productList";
import { connect } from "react-redux";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var _ = require("lodash");
var Immutable = require("immutable");
var React = require("react");
var Cx = require("classnames");

const COMPARISON_ERROR_NO_PARTS = "comparison-error-no-parts";
const COMPARISON_ERROR_PART_ALREADY_IN_LIST =
  "comparison-error-part-already-in-list";
const COMPARISON_ERROR_PART_LIST_IS_FULL = "comparison-error-part-list-is-full";
const COMPARISON_ERROR_FAILED_TO_LOAD = "comparison-error-failed-to-load";

const PDFLauncher = {
  VIEW: "view-as-pdf",
  DOWNLOAD: "download-as-pdf",
};

const VIEW = {
  GRID: "grid",
  LIST: "list",
  GUIDE: "guide",
};

const SCREENMODES = {
  REGULAR: "regular",
  IFRAME: "iframe",
  GUIDE: "guide",
};

const LAYOUT = {
  DESKTOP: 1336,
  TABLET: 1143,
  MOBILE: 1024,
};

class ProductListRefactor extends I18nComponent {
  static defaultProps = {
    searchType: null,
  };

  isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      filterload: [],
      filterspeed: [],
      filterply: [],
      filterrunflat: [],
      filtertag: [],
      filtercategory: [],
      filterbrand: [],
      filterpricing: [],

      filteraccessory: "",

      filterhide: !isUserLoggedIn(),

      togglegfilter: window.innerWidth >= LAYOUT.TABLET,

      modal: null,
      modaldtm: null,

      externalLink: "",

      pageIndex: 1,

      loggedIn: isUserLoggedIn(true),

      partListLoading: this.props.partLoading,

      partList: this.props.productList,

      autoaddtocart: false,

      search: applicationStore.data.search,
      consumersearch: applicationStore.data.consumerTiresize,
      consumerpostal: applicationStore.data.consumerPostal,
      searchType: productListStore.data.searchType,
      productType: applicationStore.data.productType,

      routerState: getRouterState(),

      season: this.props.selectedSeason,
      tiresize: productListStore.data.tireSize,

      colors: productListStore.data.visualizerColours,
      foreground: productListStore.data.visualizerForeground,
      shadow: productListStore.data.visualizerShadow,
      hidevisualizer: false,

      selectedcolor: null,
      selectedpart: null,
      count: this.getPartCount(window.innerWidth),
      offset: 0,

      comparisonData: this.props.comparisonList,
      comparisonErrorModal: productListStore.data.failedAddToComparison,
      comparisonErrorType: productListStore.data.comparisonErrorType,
      comparisonLoadingError: productListStore.data.comparisonLoadingError,
      comparisonLoadedSuccessfully: this.props.comparisonLoadedSuccessfully,
      runComparisonOnLoad: productListStore.data.runComparisonOnLoad,

      launchComparisonPdf: productListStore.data.launchComparisonPdf,
      launchGuidePdf: productListStore.data.launchGuidePdf,
      pdfComparisonLauncher: null,
      pdfGuideLauncher: null,
      pdfData: {
        initializeComparison: false,
        initializeGuide: false,
        imageList: [],
        encodedImageList: [],
      },

      view: this.enableListView() ? VIEW.LIST : VIEW.GRID,
      showGuide: false,

      tablet: window.innerWidth < LAYOUT.TABLET,
      mobile: window.innerWidth < LAYOUT.MOBILE,
      tireSizeSpecIdCountArray: this.props.tireSizeSpecIdCountArray,
    };
  }

  updateState = (state) => {
    this.setState(state);
  };

  enableListView = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mazda":
        return (
          isUserLoggedIn() &&
          applicationStore.data.productType === ProductTypes.ACCESSORIES
        );
      default:
        return false;
    }
  };

  getPartCount = (windowWidth) => {
    if (windowWidth >= 1800) return 6;
    else if (windowWidth >= 1500) return 5;
    else if (windowWidth >= 1200) return 4;
    else if (windowWidth >= 900) return 3;
    else if (windowWidth >= 600) return 2;
    else return 1;
  };

  componentWillUnmount() {
    this.isMounted = false;
    applicationStore.removeChangeListener(this.onChange);
    productListStore.removeChangeListener(this.onChange);
    window.removeEventListener("keypress", this.keypress);
    window.removeEventListener("resize", this.reSize);
  }

  componentDidMount() {
    this.isMounted = true;
    //googleAnalyticsEnhancedTrackPageview('Tire List');
    //googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'open search result', 'tire');
    applicationStore.addChangeListener(this.onChange);
    productListStore.addChangeListener(this.onChange);
    productListStore.loadingTires(true);
    this.loadAllParts();
    // var updateState = this.updateState,
    //   getPartCount = this.getPartCount;
    window.addEventListener("resize", this.reSize);

    var keypress = this.keypress;
    window.addEventListener("keypress", keypress);

    switch (applicationStore.data.productType) {
      case ProductTypes.TIRES:
      case ProductTypes.ALLOY_WHEELS:
      case ProductTypes.ACCESSORIES:
      case ProductTypes.PACKAGES:
        this.loadParts();
    }
  }
  componentDidUpdate(lastProps, lastState) {
    if (this.props.tireLoading && this.shouldLoadParts(lastProps, lastState)) {
      this.loadParts();
    }
    if (this.refs.notOriginalWarningModal)
      ReactDOM.findDOMNode(this.refs.notOriginalWarningModal).focus();

    if (
      productListStore.data.autoAddToCart &&
      sizeOf(this.state.partList) > 0
    ) {
      var list = this.state.partList;
      for (var i in list) {
        if (
          list[i] &&
          this.getAddToCartManufacturer(list[i]).toLowerCase() ==
            productListStore.data.autoAddProduct.manufacturer.toLowerCase() &&
          list[i].partSummary.primaryPart.itemPartNumber.toLowerCase() ==
            productListStore.data.autoAddProduct.partnumber.toLowerCase()
        ) {
          this.setState({
            autoaddtocart: list[i].partDetails.primaryPart.itemId,
          });
        }
      }

      productListStore.data.autoAddToCart = false;
    }

    if (!this.state.comparsionOnLoadHasRun && !this.state.partListLoading) {
      if (this.state.runComparisonOnLoad) {
        this.setState({ comparsionOnLoadHasRun: true });
      }
    }
    if (this.props.comparisonList !== lastProps.comparisonList) {
      console.log("comparisonList changed");
      this.forceUpdate();
      this.setState({ comparisonData: this.props.comparisonList });
    }
    var tempLengthList = [];
    for (var i in this.state.pdfData.imageList) {
      if (
        this.state.pdfData.imageList[i] &&
        this.state.pdfData.imageList[i].id &&
        tempLengthList.indexOf(this.state.pdfData.imageList[i].id) === -1
      ) {
        tempLengthList.push(this.state.pdfData.imageList[i].id);
      }
    }

    if (
      (this.state.pdfData.initializeComparison ||
        this.state.pdfData.initializeGuide) &&
      sizeOf(tempLengthList) ==
        objectSize(this.state.pdfData.encodedImageList) + 1
    ) {
      var imageList = this.state.pdfData.imageList,
        encodedImageList = this.state.pdfData.encodedImageList;
      this.setState({
        pdfData: {
          initializeComparison: false,
          initializeGuide: false,
          imageList: imageList,
          encodedImageList: encodedImageList,
        },
      });

      this.launchPdfMake();
      this.resetPdfData();
    }

    if (this.state.launchComparisonPdf && !lastState.launchComparisonPdf) {
      this.props.dispatch(onSetComparisonPdfLauncher(false));
      // productListActions.onSetComparisonPdfLauncher(false);
      setTimeout(() => {
        this.onReadyPdfPrint(true, PDFLauncher.DOWNLOAD);
      }, 100);
    }

    if (this.state.launchGuidePdf && !lastState.launchGuidePdf) {
      this.props.dispatch(onSetGuidePdfLauncher(false));
      // productListActions.onSetGuidePdfLauncher(false);
      setTimeout(() => {
        this.onReadyPdfPrint(false, PDFLauncher.DOWNLOAD);
      }, 100);
    }

    if (
      this.state.partList !== lastState.partList ||
      this.state.season !== lastState.season ||
      this.state.tiresize !== lastState.tiresize
    ) {
      setTimeout(() => {
        this.initializeVisualizer();
      }, 100);
    }

    if (this.state.loggedIn !== lastState.loggedIn) {
      this.setState({ filterhide: !isUserLoggedIn() });
    }

    if (this.state.productType !== lastState.productType) {
      runAdobeServicePageView();
    }

    scrollToPageTop(this.state.tires, lastState.tires);
  }

  componentWillReceiveProps(lastProps, lastState) {
    if (!this.state.loggedIn && lastState.loggedIn) {
      this.loadParts();
    }
  }

  keypress = (evt) => {
    if (evt.keyCode === 13) {
      var temp = document.getElementsByClassName("filter-accessory")[0],
        updateState = this.updateState;
      if (temp) {
        setTimeout(() => {
          updateState(
            {
              filteraccessory: temp.value,
            },
            100
          );
        });
      }
    }
  };
  reSize = () => {
    setTimeout(() => {
      this.updateState({
        count: this.getPartCount(window.innerWidth),
        offset: 0,
        tablet: window.innerWidth < LAYOUT.TABLET,
        mobile: window.innerWidth < LAYOUT.MOBILE,
      });
    }, 100);
  };

  resetAutoAdd = () => {
    this.setState({
      autoaddtocart: false,
    });
  };

  getAddToCartManufacturer = (part) => {
    switch (applicationStore.data.productType) {
      case ProductTypes.ACCESSORIES:
        return part.partDetails.primaryPart.accessoryManufacturerName;
      case ProductTypes.TIRES:
        return part.partDetails.primaryPart.tireManufacturerName;
      case ProductTypes.PACKAGES:
        return part.partDetails.primaryPart.packageManufacturerName;
      case ProductTypes.ALLOY_WHEELS:
        return part.partDetails.primaryPart.wheelManufacturerName;
    }
  };

  initializeVisualizer = () => {
    var list = this.getFilteredProductList();
    if (
      !isEmpty(this.props.visualizerColours) &&
      sizeOf(list) > 0 &&
      sizeOf(list[0].partVisualizerAsset) > 0
    ) {
      var image = list[0].partVisualizerAsset[0];
      if (!isEmpty(image)) {
        this.setState({
          selectedcolor: this.props.visualizerColours[0].colourCodeId,
          selectedpart: list[0].partDetails.primaryPart.itemId,
        });
      }
    }
  };

  hideVisualizer = () => {
    this.setState({
      hidevisualizer: true,
    });
  };

  loadAllParts = async () => {
    await vehicleSelectionActions.loadVehicleInfo();
    switch (productListStore.data.productType) {
      case ProductTypes.TIRES:
        await productListActions.loadTires();
        break;
      case ProductTypes.PACKAGES:
        await productListActions.loadPackages();
        break;
      case ProductTypes.ALLOY_WHEELS:
        await productListActions.loadWheels();
        break;
      case ProductTypes.ACCESSORIES:
        productListActions.loadAccessories();
        break;
      default:
        break;
    }
  };

  shouldLoadParts = (lastProps, lastState) => {
    const hasTireSizeChanged = this.props.tireSize !== lastProps.tireSize;

    const hasProductListChanged =
      this.props.state.productList.selectedSeason !==
        lastProps.state.productList.selectedSeason ||
      this.props.state.productList.filterByTireSize !==
        lastProps.state.productList.filterByTireSize ||
      this.props.state.productList.productListLoaded !==
        lastProps.state.productList.productListLoaded;

    const hasStateOrAppStoreChanged =
      this.state.routerState !== lastState.routerState ||
      this.state.productType !== lastState.productType ||
      this.state.searchType !== lastState.searchType ||
      this.state.search !== lastState.search ||
      this.state.loggedIn !== lastState.loggedIn ||
      (applicationStore.data.dealerEnableCaptcha &&
        !applicationStore.data.dealerForceCaptchaPassed &&
        isEmpty(this.state.partList) &&
        this.state.partList !== lastState.partList);

    return (
      hasTireSizeChanged || hasProductListChanged || hasStateOrAppStoreChanged
    );
  };

  loadParts = () => {
    this.setState({
      filterload: [],
      filterspeed: [],
      filterply: [],
      filterrunflat: [],
      filtertag: [],
      filtercategory: [],
      filterbrand: [],
      filterpricing: [],
      filteraccessory: "",
      hidevisualizer: false,
      autoaddtocart: false,
    });

    if (isConsumerSite()) {
      searchActions.loadConsumerTires();
    } else if (!isEmpty(this.state.search)) {
      if (getRequireCaptcha()) {
        guardRequiresTokenEvent(this.loadSearch);
      } else {
        this.loadSearch();
      }
    } else if (this.state.routerState === RouterStates.PRODUCT_LIST) {
      if (this.state.productType === ProductTypes.ACCESSORIES) {
        this.loadAccessories();
      } else if (this.state.productType === ProductTypes.TIRES) {
        if (getRequireCaptcha()) {
          guardRequiresTokenEvent(this.loadTires);
        } else {
          this.loadTires();
        }
      } else if (this.state.productType === ProductTypes.PACKAGES) {
        this.loadPackages();
      } else if (this.state.productType === ProductTypes.ALLOY_WHEELS) {
        this.loadWheels();
      }
    }
  };

  loadAccessories = () => {
    vehicleSelectionActions.loadVehicleInfo().then(function () {
      productListActions.loadAccessories();
    });
  };

  loadSearch = (token) => {
    if (productListStore.data.searchType == SearchTypes.SEARCH_BY_TIRESIZE) {
      searchActions.loadSearchResults();
    } else if (
      productListStore.data.searchType == SearchTypes.SEARCH_BY_PARTNUMBER
    ) {
      searchActions.loadSearchResultsByPartNumber();
    }
  };

  loadTires = () => {
    if (productListStore.data.searchType === SearchTypes.SEARCH_BY_MODEL) {
      vehicleSelectionActions.loadVehicleInfoByModel().then(function () {
        productListActions.loadTiresFromModel();
      });
    } else if (
      productListStore.data.searchType === SearchTypes.SEARCH_BY_CHASSIS
    ) {
      vehicleSelectionActions.loadVehicleInfoByChassis().then(function () {
        productListActions.loadTiresFromChassis();
      });
    } else if (
      productListStore.data.searchType === SearchTypes.SEARCH_BY_VEHICLE
    ) {
      vehicleSelectionActions.loadVehicleInfo().then(function () {
        productListActions.loadTires();
      });
    }
  };

  loadPackages = () => {
    if (productListStore.data.searchType === SearchTypes.SEARCH_BY_MODEL) {
      vehicleSelectionActions.loadVehicleInfoByModel().then(function () {
        productListActions.loadPackagesFromModel();
      });
    } else if (
      productListStore.data.searchType === SearchTypes.SEARCH_BY_VEHICLE
    ) {
      vehicleSelectionActions.loadVehicleInfo().then(function () {
        productListActions.loadPackages();
      });
    }
  };

  loadWheels = async () => {
    await vehicleSelectionActions.loadVehicleInfo();
    await productListActions.loadWheels();
  };

  render() {
    if (!this.state.partListLoading) {
      switch (applicationStore.data.screenMode) {
        case SCREENMODES.GUIDE:
          return this.renderGuide();
        default:
          return (
            <box>
              <container
                className={
                  this.state.tablet || this.state.mobile
                    ? "side"
                    : "side no-left"
                }
              >
                {this.renderVisualizer()}
                {this.renderMain()}

                {this.renderNotOriginalSizeWarningModal()}
                {this.renderComparisonErrorModal()}
                {this.renderComparisonModal()}
                {this.renderGuideModal()}
                {this.renderInfoModal()}
              </container>
            </box>
          );
      }
    } else {
      return false;
    }
  }

  renderVisualizer = () => {
    if (
      !this.state.hidevisualizer &&
      sizeOf(this.props.visualizerColours) > 0 &&
      !isEmpty(this.props.visualizerShadow) &&
      !isEmpty(this.props.visualizerForeground) &&
      !isEmpty(this.state.selectedpart) &&
      ((applicationStore.data.productType === ProductTypes.ALLOY_WHEELS &&
        !productListStore.data.hideWheelVisualizer) ||
        (applicationStore.data.productType === ProductTypes.PACKAGES &&
          !productListStore.data.hideProductList))
    ) {
      var image = "",
        selectedpart = this.state.selectedpart,
        selectedcolor = this.state.selectedcolor,
        setVisualizerColor = this.setVisualizerColor,
        setVisualizerPart = this.setVisualizerPart,
        opacity = {},
        background = {},
        wheel = "",
        list = this.getFilteredProductList(),
        size = sizeOf(list),
        offset = this.state.offset,
        count = this.state.count,
        onAdjustIndex = this.onAdjustIndex,
        hideVisualizer = this.hideVisualizer;

      for (var i in list) {
        if (list[i] && selectedpart == list[i].partDetails.primaryPart.itemId) {
          image = list[i].partVisualizerAsset[0];
        }
      }
      if (!isEmpty(image)) {
        return (
          <box className="visualizer">
            <box className="colors">
              <container className="side">
                <column>
                  <container className="top small right-only no-bottom">
                    <text className="bold">{this.t("bodyColor")}</text>
                  </container>
                </column>
                {Immutable.fromJS(this.props.visualizerColours).map(function (
                  color
                ) {
                  var selected = false;
                  if (selectedcolor == color.get("colourCodeId")) {
                    selected = true;
                    opacity = {
                      opacity: (
                        parseInt(color.get("visualizerShadowOpacity")) / 100
                      ).toFixed(1),
                    };
                    background = {
                      background: "#" + color.get("colourHexCode"),
                    };
                  }

                  return (
                    <column>
                      <container className="side small">
                        <img
                          className={setAdobeLaunchClass(
                            "pointer",
                            ADOBELAUNCHER_CLASS.IMAGE
                          )}
                          data-dtm={setAdobeLaunchDTM(
                            "",
                            ADOBELAUNCHER_DTM.FEATUREDITEMS.VISUALIZER
                          )}
                          src={color.get("colourCodePatchImage")}
                          onClick={setVisualizerColor.bind(
                            null,
                            color.get("colourCodeId")
                          )}
                          alt={"0x" + color.get("colourHexCode").toUpperCase()}
                        />
                        {selected ? (
                          <i className="fa fa-check-circle" />
                        ) : (
                          false
                        )}
                      </container>
                    </column>
                  );
                })}
              </container>
            </box>
            <box>
              <container className="side">
                <box className="images">
                  <box className="background" style={background} />
                  <img
                    className="shadow"
                    style={opacity}
                    src={this.props.visualizerShadow}
                    onError={hideVisualizer}
                  />
                  <img
                    className="layer"
                    src={this.props.visualizerForeground}
                    onError={hideVisualizer}
                  />
                  <img className="layer" src={image} />
                </box>
                <box>
                  <center>
                    <text className="bold">
                      {this.t("common.visualizerDisclaimer")}
                    </text>
                  </center>
                </box>
              </container>
            </box>
            {this.enableBoxBorder() ? (
              <box className="border">
                <left>
                  <i
                    className={setAdobeLaunchClass(
                      "fa fa-angle-left",
                      ADOBELAUNCHER_CLASS.ARROW.PREV
                    )}
                    data-dtm={setAdobeLaunchDTM(
                      "",
                      ADOBELAUNCHER_DTM.FEATUREDITEMS.VISUALIZER
                    )}
                    onClick={onAdjustIndex.bind(null, -1)}
                  />
                </left>

                <center>
                  {Immutable.fromJS(list)
                    .skip(size > count ? offset : 0)
                    .take(count)
                    .map(function (part, index) {
                      var selected = false;
                      if (
                        selectedpart ==
                        part.get("partDetails").get("primaryPart").get("itemId")
                      ) {
                        selected = true;
                      }

                      return [
                        <ProductTile
                          key={"product-" + index}
                          index={index}
                          part={part}
                          visualizer={true}
                          selected={selected}
                          clickImage={setVisualizerPart.bind(
                            null,
                            part
                              .get("partDetails")
                              .get("primaryPart")
                              .get("itemId")
                          )}
                        />,
                        index != count - 1 && index != size - 1 ? (
                          <line />
                        ) : (
                          false
                        ),
                      ];
                    })}
                </center>

                <right>
                  <i
                    className={setAdobeLaunchClass(
                      "fa fa-angle-right",
                      ADOBELAUNCHER_CLASS.ARROW.NEXT
                    )}
                    data-dtm={setAdobeLaunchDTM(
                      "",
                      ADOBELAUNCHER_DTM.FEATUREDITEMS.VISUALIZER
                    )}
                    onClick={onAdjustIndex.bind(null, 1)}
                  />
                </right>
              </box>
            ) : (
              false
            )}
          </box>
        );
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  enableBoxBorder = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "volkswagen_2020":
        return applicationStore.data.productType !== ProductTypes.PACKAGES;
      default:
        return true;
    }
  };

  onAdjustIndex = (index) => {
    var temp,
      offset = this.state.offset,
      count = this.state.count,
      size = sizeOf(this.getFilteredProductList());
    if (offset + index <= 0) temp = 0;
    else if (offset == size - count && index > 0) temp = offset;
    else temp = offset + index;
    this.setState({
      offset: temp,
    });
  };

  setVisualizerPart = (id) => {
    this.setState({
      selectedpart: id,
    });
  };

  setVisualizerColor = (color) => {
    this.setState({
      selectedcolor: color,
    });
  };

  renderMain = () => {
    try {
      if (sizeOf(this.state.partList) > 0) {
        if (!this.state.tablet && !this.state.mobile) {
          return (
            <box
              className={
                (sizeOf(this.props.visualizerColours) > 0 ? " " : "fixmargin") +
                " " +
                applicationStore.data.productType.toLowerCase()
              }
              style={{ marginLeft: "40px" }}
            >
              <column className="fixheight">
                {this.renderFilterSection()}
              </column>
              <column className="fixwidth">{this.renderPartList()}</column>
            </box>
          );
        } else {
          return (
            <box>
              {this.renderFilterSection()}
              {this.renderPartList()}
            </box>
          );
        }
      } else {
        return (
          <box>
            <container className="side">
              <column className="fixheight">
                <container className="top">
                  {this.renderFilterSection()}
                </container>
              </column>
              <column className="fixwidth">
                <container>
                  <text>{this.getNoResultsText()}</text>
                </container>
              </column>
            </container>
          </box>
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  renderPartList = () => {
    if (sizeOf(this.state.partList) > 0) {
      if (this.state.view == VIEW.LIST) {
        return <box className="products">{this.renderListTable()}</box>;
      } else {
        return (
          <box className="products reduced">
            {this.renderListNormal()}
            {this.renderListOriginal()}
          </box>
        );
      }
    } else {
      return false;
    }
  };

  getNoResultsText = () => {
    if (!isEmpty(this.state.search)) {
      switch (productListStore.data.searchType) {
        case SearchTypes.SEARCH_BY_TIRESIZE:
          return this.t("product.noTiresResultsAvailableForTireSize");
        case SearchTypes.SEARCH_BY_PARTNUMBER:
          return this.t("product.noTiresResultsAvailableForPartNumber");
        case SearchTypes.SEARCH_BY_MODEL:
        case SearchTypes.SEARCH_BY_VEHICLE:
          return this.t("product.noTiresResultsAvailable");
        case SearchTypes.SEARCH_BY_CHASSIS:
          return this.t("product.noTiresResultsAvailableForChassis");
      }
    } else {
      switch (applicationStore.data.productType) {
        case ProductTypes.ACCESSORIES:
          return this.t("product.noAccessoryResultsAvailable");
        case ProductTypes.TIRES:
          if (
            productListStore.data.searchType === SearchTypes.SEARCH_BY_CHASSIS
          ) {
            return this.t("product.noTiresResultsAvailableForChassis");
          } else {
            return this.t("product.noTiresResultsAvailable");
          }
        case ProductTypes.PACKAGES:
          return this.t("product.noPackageResultsAvailable");
        case ProductTypes.ALLOY_WHEELS:
          return this.t("product.noWheelResultsAvailable");
      }
    }
  };

  renderListTable = () => {
    return (
      <container className="top large">
        <table>
          <tr className="first">
            <th colSpan="5">{this.t("product.individualItem")}</th>
            <th colSpan="2">{this.t("product.requiredItems")}</th>
            <th colSpan="2">{this.t("product.grandTotal")}</th>
          </tr>
          <tr className="second">
            <th className="image">
              <container className="small"></container>
            </th>
            <th>
              <container className="small">{this.t("product.name")}</container>
            </th>
            <th className="right-border">
              <container className="small">{this.t("product.msrp")}</container>
            </th>
            <th>
              <container className="small">
                {this.t("product.itemInstalled")}
              </container>
            </th>
            <th className="right-border">
              <container className="small"></container>
            </th>
            <th>
              <container className="small">
                {this.t("product.requiredItems")}
              </container>
            </th>
            <th className="right-border">
              <container className="small">
                {this.t("product.itemInstalled")}
              </container>
            </th>
            <th>
              <container className="small">{this.t("product.total")}</container>
            </th>
            <th>
              <container className="small"></container>
            </th>
          </tr>

          {this.renderList(null, VIEW.LIST)}
        </table>
      </container>
    );
  };

  renderListNormal = () => {
    if (!this.enableOriginalSeparator()) {
      var featuredtext = this.t("common.featuredItems"),
        listtext = this.t("common.yourResults");

      if (
        this.enableProductListTitle() &&
        sizeOf(this.getFilteredFeaturedProductList())
      ) {
        featuredtext = this.t("common.featuredItems") + " - " + getSeasonText();
      } else if (
        this.enableProductListTitle() &&
        sizeOf(this.getFilteredFeaturedProductList()) == 0
      ) {
        listtext = this.t("common.yourResults") + " - " + getSeasonText();
      }

      return (
        <container className="top large no-top">
          {this.enableProductListTitle() &&
          sizeOf(this.getFilteredFeaturedProductList()) > 0 ? (
            <h1>{featuredtext}</h1>
          ) : (
            false
          )}
          {sizeOf(this.getFilteredFeaturedProductList()) > 0
            ? this.renderCompare()
            : false}
          {this.enableProductListTitle() &&
          sizeOf(this.getFilteredFeaturedProductList()) > 0 ? (
            <hr />
          ) : (
            false
          )}
          {sizeOf(this.getFilteredFeaturedProductList()) > 0
            ? this.renderFeaturedList()
            : false}
          {this.enableProductListTitle() &&
          sizeOf(this.getFilteredFeaturedProductList()) == 0 ? (
            <h1>{listtext}</h1>
          ) : (
            false
          )}
          {sizeOf(this.getFilteredFeaturedProductList()) == 0
            ? this.renderCompare()
            : false}
          {this.renderList()}
          {this.renderMoreButton()}
        </container>
      );
    } else {
      return false;
    }
  };

  renderListOriginal = () => {
    if (this.enableOriginalSeparator()) {
      return (
        <container className="top large no-top">
          {sizeOf(this.getFilteredOriginalProductList()) > 0 ? (
            <h1>{this.t("product.approvedTire")}</h1>
          ) : (
            false
          )}
          {sizeOf(this.getFilteredOriginalProductList()) > 0
            ? this.renderCompare()
            : false}
          {sizeOf(this.getFilteredOriginalProductList()) > 0
            ? this.renderList(true)
            : false}
          {sizeOf(this.getFilteredOriginalProductList()) > 0 &&
          sizeOf(this.getFilteredNonOriginalProductList()) > 0 ? (
            <hr />
          ) : (
            false
          )}
          {sizeOf(this.getFilteredNonOriginalProductList()) > 0 ? (
            <h1>{this.t("product.nonApprovedTires")}</h1>
          ) : (
            false
          )}
          {sizeOf(this.getFilteredOriginalProductList()) == 0 &&
          sizeOf(this.getFilteredNonOriginalProductList()) > 0
            ? this.renderCompare()
            : false}
          {this.renderList(false)}
          {this.renderMoreButton()}
        </container>
      );
    } else {
      return false;
    }
  };

  renderGuide = () => {
    return (
      <container className={this.getGuideContainerClass()}>
        {this.renderDownloadGuide()}
        {this.renderList(null, VIEW.GUIDE, true)}
      </container>
    );
  };

  getGuideContainerClass = () => {
    return Cx({
      top: applicationStore.data.screenMode !== SCREENMODES.GUIDE,
      large: applicationStore.data.screenMode !== SCREENMODES.GUIDE,
      "no-top": true,
    });
  };

  enableProductListTitle = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "bmw":
      case "mazda":
        return false;
      default:
        return applicationStore.data.productType !== ProductTypes.ACCESSORIES;
    }
  };

  enableOriginalSeparator = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mercedes":
      case "mercedes_2022":
      case "mercedestransition":
      case "mini":
        return applicationStore.data.productType === ProductTypes.TIRES;
      default:
        return false;
    }
  };

  renderDownloadGuide = () => {
    return (
      <box className="guidebar">
        <column>{renderTemplateLogo()}</column>
        <column
          className={
            applicationStore.data.screenMode === SCREENMODES.GUIDE
              ? "darkfont"
              : ""
          }
        >
          <Breadcrumbs isGuide={true} />
        </column>
        {applicationStore.data.screenMode === SCREENMODES.GUIDE ? (
          <column className="right">
            <img
              className="close pointer"
              src={this.getCloseLink()}
              onClick={function () {
                window.location.reload();
              }}
            />
          </column>
        ) : (
          false
        )}
        <column className="right">
          <button className="btn" onClick={this.onDownloadGuide}>
            {this.t("downloadPDF")}
          </button>
        </column>
      </box>
    );
  };

  getCloseLink = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mazda":
        return "/static/img/mazda/close.png";
      case "mercedes":
      case "mercedes_2022":
        return "/static/img/mercedes/close.png";
      case "volkswagen":
        return "/static/img/volkswagen/close_icon.png";
      default:
        return "/static/img/close_circle.png";
    }
  };

  onDownloadGuide = () => {
    this.props.dispatch(onSetGuidePdfLauncher(true));
    // productListActions.onSetGuidePdfLauncher(true);
  };

  renderCompare = () => {
    if (this.enableCompare()) {
      if (this.enableProductListTitle()) {
        return (
          <box className="comparebar">
            {this.renderCompareList()}
            {this.renderCompareButton()}
            {this.renderQuickCompare()}
          </box>
        );
      } else {
        return (
          <box className="comparebar enhanced">
            <container className="top no-bottom">
              {this.renderQuickCompare()}
              {this.renderCompareButton()}
              {this.renderCompareList()}
            </container>
          </box>
        );
      }
    } else {
      return false;
    }
  };

  renderCompareList = () => {
    if (sizeOf(this.props.comparisonList) > 0) {
      var removeFromComparisonList = this.removeFromComparisonList,
        getCompareColumnClass = this.getCompareColumnClass(),
        translate = this.t;
      return this.getCompareList().map(function (part) {
        return (
          <column className={getCompareColumnClass}>
            <box>
              <img src={part.get("imageUrl")} alt={translate("common.close")} />
              <i
                className={setAdobeLaunchClass(
                  "fa fa-times",
                  ADOBELAUNCHER_CLASS.IMAGE
                )}
                data-dtm={setAdobeLaunchDTM(
                  "",
                  ADOBELAUNCHER_DTM.FEATUREDITEMS.BASE
                )}
                onClick={removeFromComparisonList.bind(null, part)}
              />
            </box>
          </column>
        );
      });
    } else {
      return false;
    }
  };

  getCompareList = () => {
    if (this.enableProductListTitle()) {
      return Immutable.fromJS(this.props.comparisonList);
    } else {
      return Immutable.fromJS(this.props.comparisonList).reverse();
    }
  };

  renderCompareButton = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "tirecraft":
        return (
          <column className={this.getCompareColumnClass()}>
            <a
              className={setAdobeLaunchClass(
                "btn btn-alt",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                ADOBELAUNCHER_DTM.FEATUREDITEMS.BASE
              )}
              onClick={this.runPartComparison}
            >
              {this.t("comparison.compareSelected")}
            </a>
          </column>
        );
      default:
        return (
          <column className={this.getCompareColumnClass()}>
            <button
              className={setAdobeLaunchClass(
                "btn",
                ADOBELAUNCHER_CLASS.BUTTON.LINK
              )}
              data-dtm={setAdobeLaunchDTM(
                "",
                ADOBELAUNCHER_DTM.FEATUREDITEMS.BASE
              )}
              onClick={this.runPartComparison}
            >
              {this.t("comparison.compareSelected")}
            </button>
          </column>
        );
    }
  };

  renderQuickCompare = () => {
    if (this.enableQuickCompare()) {
      switch (applicationStore.data.defaultTemplate) {
        case "tirecraft":
          return (
            <column className={this.getCompareColumnClass()}>
              <a
                className={setAdobeLaunchClass(
                  "btn btn-alt",
                  ADOBELAUNCHER_CLASS.BUTTON.LINK
                )}
                data-dtm={setAdobeLaunchDTM(
                  "",
                  ADOBELAUNCHER_DTM.FEATUREDITEMS.BASE
                )}
                onClick={this.runQuickComparison}
              >
                {this.t("comparison.quickCompare")}
              </a>
            </column>
          );
        default:
          return (
            <column className={this.getCompareColumnClass()}>
              <button
                className={setAdobeLaunchClass(
                  "btn",
                  ADOBELAUNCHER_CLASS.BUTTON.LINK
                )}
                data-dtm={setAdobeLaunchDTM(
                  "",
                  ADOBELAUNCHER_DTM.FEATUREDITEMS.BASE
                )}
                onClick={this.runQuickComparison}
              >
                {this.t("comparison.quickCompare")}
              </button>
            </column>
          );
      }
    } else {
      return false;
    }
  };

  getCompareColumnClass = () => {
    if (this.enableProductListTitle()) {
      return "";
    } else {
      return "right";
    }
  };

  runPartComparison = () => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Tire List - CompareSelected"
    );
    // productListActions.onRunComparison();
    if (sizeOf(this.props.comparisonList) > 0) {
      // this.data.runComparisonOnLoad = false;

      let partIdArray = [];

      this.props.comparisonList.map(function (part) {
        if (part && part.id) {
          partIdArray.push(part.id);
        }
      });

      var firstPartId = partIdArray[0] ? partIdArray[0] : 0;
      var secondPartId = partIdArray[1] ? partIdArray[1] : 0;
      var thirdPartId = partIdArray[2] ? partIdArray[2] : 0;

      setTimeout(() => {
        productListActions.onGetProductComparison(
          ProductTypes.TIRES,
          vehicleSelectionStore.data.vehicleId,
          firstPartId,
          secondPartId,
          thirdPartId
        );
      }, 200);
    } else {
      // this.data.failedAddToComparison = true;
      // this.data.comparisonErrorType = COMPARISON_ERROR_NO_PARTS;
    }
  };

  runQuickComparison = () => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Tire List - QuickCompareSelected"
    );
    this.onQuickCompare();
  };

  onQuickCompare = () => {
    var list =
      sizeOf(this.getFilteredFeaturedProductList()) > 0
        ? this.getFilteredFeaturedProductList()
        : this.getFilteredProductList();
    let partIdArray = [];
    for (var i in list) {
      if (list[i] && i < 3) {
        var quantity = getInitialQuantity(false),
          pricingContextId = null,
          temp = Object.keys(
            list[i].partPricing.primaryPart.price[quantity].contextList
          );

        pricingContextId = parseFloat(
          list[i].partPricing.primaryPart.price[quantity].contextList[temp[0]]
            .pricingContextId
        );

        if (list[i].part) {
          this.props.dispatch(
            onAddProductToComparison({
              id: list[i].partDetails.primaryPart.itemId,
              imageUrl: list[i].partDetails.primaryPart.tireImageLink,
              quantity: quantity,
              pricingContextId: pricingContextId,
              part: list[i].part,
            })
          );
          partIdArray.push(list[i].partDetails.primaryPart.itemId);
          // productListActions.onAddProductToComparison({
          //   id: list[i].partDetails.primaryPart.itemId,
          //   imageUrl: list[i].partDetails.primaryPart.tireImageLink,
          //   quantity: quantity,
          //   pricingContextId: pricingContextId,
          //   part: list[i].part,
          // });
        } else {
          this.props.dispatch(
            onAddProductToComparison({
              id: list[i].partDetails.primaryPart.itemId,
              imageUrl: list[i].partDetails.primaryPart.tireImageLink,
              quantity: quantity,
              pricingContextId: pricingContextId,
              part: list[i],
            })
          );
          partIdArray.push(list[i].partDetails.primaryPart.itemId);

          // productListActions.onAddProductToComparison({
          //   id: list[i].partDetails.primaryPart.itemId,
          //   imageUrl: list[i].partDetails.primaryPart.tireImageLink,
          //   quantity: quantity,
          //   pricingContextId: pricingContextId,
          //   part: list[i],
          // });
        }
      }
    }

    setTimeout(() => {
      productListActions.onGetProductComparison(
        ProductTypes.TIRES,
        vehicleSelectionStore.data.vehicleId,
        ...partIdArray
      );
    }, 200);
  };

  enableCompare = () => {
    return (
      !this.isStaggeredList() &&
      applicationStore.data.productListCompare &&
      !applicationStore.data.dealerHidePublicPricing(isUserLoggedIn()) &&
      applicationStore.data.productType === ProductTypes.TIRES
    );
  };

  enableQuickCompare = () => {
    return applicationStore.data.enableQuickCompare && this.enableCompare();
  };

  isStaggeredList = () => {
    let staggered = false;
    let tiresize = this.props.filterByTireSize
      ? this.props.filterByTireSize
      : this.props.tireSize;
    let list = this.props.vehicleFullTireSizeInfo;
    for (var i in list) {
      if (list[i] && list[i].vehicleSubmodelTiresizeId == tiresize) {
        if (list[i].secondaryTireSizeSpecId > 0) {
          staggered = true;
        }
      }
    }
    return staggered;
  };

  renderFilterSection = () => {
    if (sizeOf(this.state.partList) > 0 && !this.state.partListLoading) {
      return (
        <box className="filterbar reduced">
          <container className="top no-top">
            {this.enableFilterBarTitle() ? (
              <box className="filtertitle" onClick={this.toggleFilterBar}>
                <column>
                  <h1
                    id={getTestId(TEST_LIST.PRODUCT_LIST.MOBILE.FILTER.TITLE)}
                  >
                    {this.renderFilterPartTitle()}
                  </h1>
                </column>
                <right>
                  <i
                    id={getTestId(
                      TEST_LIST.PRODUCT_LIST.MOBILE.FILTER.TOGGLEARROW
                    )}
                    className={this.getFilterToggleClass()}
                    data-dtm={setAdobeLaunchDTM(
                      "",
                      ADOBELAUNCHER_DTM.OPTIONS.TIREWHEEL,
                      applicationStore.data.productType
                    )}
                  />
                </right>
              </box>
            ) : (
              <box></box>
            )}
            {this.state.togglefilter ||
            !this.enableFilterBarTitle() ||
            (!this.state.tablet && !this.state.mobile)
              ? this.renderFilterPart()
              : false}
          </container>
        </box>
      );
    } else {
      return (
        <box className="filterbar reduced">
          <container className="top no-top">
            {this.enableFilterBarTitle() ? (
              <box className="filtertitle" onClick={this.toggleFilterBar}>
                <column>
                  <h1
                    id={getTestId(TEST_LIST.PRODUCT_LIST.MOBILE.FILTER.TITLE)}
                  >
                    {this.renderFilterPartTitle()}
                  </h1>
                </column>
                <right>
                  <i
                    id={getTestId(
                      TEST_LIST.PRODUCT_LIST.MOBILE.FILTER.TOGGLEARROW
                    )}
                    className={this.getFilterToggleClass()}
                    data-dtm={setAdobeLaunchDTM(
                      "",
                      ADOBELAUNCHER_DTM.OPTIONS.TIREWHEEL,
                      applicationStore.data.productType
                    )}
                  />
                </right>
              </box>
            ) : (
              <box></box>
            )}
            <box>
              <FilterPanel
                onSelect={this.onSeasonFilter}
                title={this.t("product.season")}
                list={this.getDefaultSeasonList()}
                initial={this.state.season}
                open={true}
                single={true}
              />
              {isUserLoggedIn() ? (
                <FilterPanel
                  onSelect={this.onOriginalFilter}
                  title={this.t("product.approvedTires")}
                  list={getFilteredOeList()}
                  initial={productListStore.data.originalEquipmentOnly}
                  open={true}
                  single={true}
                />
              ) : (
                false
              )}
            </box>
          </container>
        </box>
      );
    }
  };

  getDefaultSeasonList = () => {
    var list = [];
    if (applicationStore.data.dealerTireSeasons.hasOwnProperty(0))
      list.push({ key: 0, value: this.t("product.filters.season.winter") });
    if (applicationStore.data.dealerTireSeasons.hasOwnProperty(1))
      list.push({ key: 1, value: this.t("product.filters.season.allSeason") });
    if (applicationStore.data.dealerTireSeasons.hasOwnProperty(2))
      list.push({ key: 2, value: this.t("product.filters.season.summer") });
    return list;
  };

  getFilterToggleClass = () => {
    return setAdobeLaunchClass(
      Cx({
        fa: true,
        "fa-angle-down": this.state.togglefilter,
        "fa-angle-right": !this.state.togglefilter,
      }),
      ADOBELAUNCHER_CLASS.ICON
    );
  };

  toggleFilterBar = () => {
    this.setState({
      togglefilter: !this.state.togglefilter,
    });
  };

  enableFilterBarTitle = () => {
    return this.state.mobile || this.state.tablet;
  };

  renderFilterPartTitle = () => {
    switch (applicationStore.data.productType) {
      case ProductTypes.ACCESSORIES:
        return this.t("accessoryOptions");
      case ProductTypes.TIRES:
        return this.t("tireOptions");
      case ProductTypes.PACKAGES:
        return this.t("packageOptions");
      case ProductTypes.ALLOY_WHEELS:
        return this.t("wheelOptions");
    }
  };

  renderFilterPart = () => {
    switch (applicationStore.data.productType) {
      case ProductTypes.ACCESSORIES:
        var updateState = this.updateState;

        //{this.renderDownloadAcessoryGuide()}
        return (
          <box>
            {this.renderDownloadAcessoryGuide()}
            {this.renderFilterSearch()}
            {this.renderFilterAccessorySearch()}
            <FilterPanel
              onSelect={(key) => {
                updateState({ filtercategory: key });
              }}
              title={this.t("product.tagFilter")}
              list={getFilteredCategoryList()}
              shadowList={this.props.accessoryCategoryShadowList}
              enableShowAll={true}
              open={true}
              single={true}
            />
            {this.renderImageList()}
          </box>
        );

      case ProductTypes.TIRES:
        var updateState = this.updateState,
          filterhide = this.state.filterhide;
        return (
          <box>
            {this.renderFilterSearch()}
            <FilterPanel
              onSelect={this.onSeasonFilter}
              title={this.t("product.season")}
              list={getFilteredSeasonList(this.props.seasonCountArray)}
              countList={this.props.seasonCountArray}
              initial={this.state.season}
              open={true}
              single={true}
            />
            <FilterPanel
              onSelect={this.onTiresizeFilter}
              title={this.t("product.tireSize")}
              list={getFilteredTiresizeList(
                this.props.vehicleFullTireSizeInfo,
                this.props.tireSizeSpecIdCountArray
              )}
              countList={this.props.tireSizeSpecIdCountArray}
              initial={this.props.tireSizeFilters}
              open={true}
              single={true}
            />
            <FilterPanel
              onSelect={this.onOriginalFilter}
              title={this.t("product.approvedTires")}
              list={getFilteredOeList()}
              initial={productListStore.data.originalEquipmentOnly}
              open={true}
              single={true}
            />

            <FilterPanel
              onSelect={function (key) {
                updateState({ filterrunflat: key });
              }}
              title={this.t("product.runFlatText")}
              list={getFilteredRunflatList()}
            />
            <FilterPanel
              onSelect={function (key) {
                updateState({ filtertag: key });
              }}
              title={this.t("product.tagFilter")}
              list={getFilteredTagList()}
              open={true}
            />
            <FilterPanel
              onSelect={this.onSelectBrand}
              title={this.t("product.tireBrands")}
              list={getFilteredBrandList()}
              shadowList={this.props.filteredBrandIds}
            />
            {!isConsumerSite() ? (
              <FilterPanel
                onSelect={function (key) {
                  updateState({ filterpricing: key });
                }}
                title={this.t("product.tirePricing")}
                list={getFilteredPricingList(
                  this.props.tireListMaxPrice,
                  this.props.tireListMinPrice
                )}
                shadowList={getFilteredPricingShadowList(
                  this.props.tireListMaxPrice,
                  this.props.tireListMinPrice
                )}
              />
            ) : (
              false
            )}
            {!isConsumerSite() ? (
              <FilterPanel
                onSelect={function (key) {
                  updateState({ filterload: key });
                }}
                title={this.t("product.loadRating")}
                list={getFilteredLoadList(this.props.loadRatingList)}
              />
            ) : (
              false
            )}
            {!isConsumerSite() ? (
              <FilterPanel
                onSelect={function (key) {
                  updateState({ filterspeed: key });
                }}
                title={this.t("product.speedRating")}
                list={getFilteredSpeedList(this.props.speedRatingList)}
              />
            ) : (
              false
            )}
            {!isConsumerSite() ? (
              <FilterPanel
                onSelect={function (key) {
                  updateState({ filterply: key });
                }}
                title={this.t("product.plyRatingFilter")}
                list={getFilteredPlyList(this.props.plyRatingList)}
              />
            ) : (
              false
            )}

            {this.renderImageList()}
          </box>
        );

      case ProductTypes.PACKAGES:
        return (
          <box>
            {this.renderFilterSearch()}
            {this.renderImageList()}
          </box>
        );

      case ProductTypes.ALLOY_WHEELS:
        var updateState = this.updateState;

        return (
          <box>
            {this.renderFilterSearch()}
            <FilterPanel
              onSelect={this.onSeasonFilter}
              title={this.t("product.season")}
              list={getFilteredSeasonList(this.props.seasonCountArray)}
              countList={this.props.seasonCountArray}
              initial={this.state.season}
              open={true}
              single={true}
            />
            <FilterPanel
              onSelect={this.onTiresizeFilter}
              title={this.t("product.tireSize")}
              list={getFilteredTiresizeList(
                this.props.vehicleFullTireSizeInfo,
                this.props.tireSizeSpecIdCountArray
              )}
              countList={this.getSelectedPin(
                this.props.tireSizeSpecIdCountArray
              )}
              initial={this.props.tireSize}
              countoverride={true}
              open={true}
              single={true}
            />
            <FilterPanel
              onSelect={function (key) {
                updateState({ filterpricing: key });
              }}
              title={this.t("product.tirePricing")}
              list={getFilteredPricingList(
                this.props.tireListMaxPrice,
                this.props.tireListMinPrice
              )}
              shadowList={getFilteredPricingShadowList(
                this.props.tireListMaxPrice,
                this.props.tireListMinPrice
              )}
            />
            {this.renderImageList()}
          </box>
        );
    }
  };

  onSelectBrand = (key) => {
    var list = getFilteredBrandList(),
      label = "";

    for (var i in list) {
      if (list[i] && key.indexOf(list[i].key) > -1) {
        label += list[i].value + " ";
      }
    }

    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "filter brand",
      "{" + label.trim() + "}"
    );

    this.setState({ filterbrand: key });
  };
  /*
   * Returns the tireSize with the lowest value greater than the minimum value
   * If there are less than 2 non-null values, returns the lowest non-null value
   * If there are no non-null values, returns null
   * @param {Object} pins - An object containing PINs as keys and values as values
   * @returns {Number} The selected PIN
   */
  getSelectedPin = (pins) => {
    // Convert the input object to an array of PIN objects
    const pinArray = Object.entries(pins).map(([id, value]) => ({
      id: parseInt(id),
      value,
    }));

    // Filter out null values and sort the remaining values in ascending order
    const sortedNonNullPins = pinArray
      .filter((pin) => pin.value !== null)
      .sort((a, b) => a.id - b.id);

    // If there are less than 2 non-null values, return the lowest non-null value
    if (sortedNonNullPins.length < 2) {
      return sortedNonNullPins[0] ? sortedNonNullPins[0].id : null;
    }

    // Get the lowest non-null value
    const lowestValue = sortedNonNullPins[0].value;

    // Find the PIN with the lowest value greater than the minimum value
    const selectedPin = sortedNonNullPins
      .slice(1)
      .find((pin) => pin.value !== null && pin.value === lowestValue);

    // If a suitable PIN is found, return its id; otherwise, return the last non-null value
    return selectedPin
      ? selectedPin.id
      : sortedNonNullPins[sortedNonNullPins.length - 1].id;
  };

  setView = (view) => {
    this.setState({
      view: view,
    });
  };

  renderDownloadAcessoryGuide = () => {
    if (sizeOf(this.getFilteredGuideList()) > 0) {
      return (
        <box>
          <box>
            <h2>
              <container className="top no-bottom">
                <button
                  className="btn full-width"
                  onClick={this.toggleGuideModal}
                >
                  {this.t("downloadAccessoryGuide")}
                </button>
              </container>
            </h2>
          </box>
        </box>
      );
    } else {
      return false;
    }
  };

  renderFilterSearch = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "bmw":
      case "mrlube":
        return false;
      case "mini":
        return (
          <box>
            <box>
              <container className="top">
                <column>
                  <h2>{this.getFilterSearchValue()}</h2>
                </column>
                <column className="right">
                  <button
                    id={getTestId(TEST_LIST.PRODUCT_LIST.FILTER.SEARCH.BUTTON)}
                    className={setAdobeLaunchClass(
                      "btn icon right",
                      ADOBELAUNCHER_CLASS.BUTTON.LINK
                    )}
                    data-dtm={setAdobeLaunchDTM(
                      "",
                      ADOBELAUNCHER_DTM.OPTIONS.TIREWHEEL,
                      applicationStore.data.productType
                    )}
                    onClick={this.onToggleSearch}
                  >
                    {this.t("change")}
                  </button>
                </column>
              </container>
            </box>
          </box>
        );
      case "mazda":
        if (this.state.routerState === RouterStates.SEARCH_RESULT) {
          return (
            <box>
              <box>
                <hr />
                <h2>
                  {this.getFilterSearchValue()}{" "}
                  <button
                    className={setAdobeLaunchClass(
                      "btn icon",
                      ADOBELAUNCHER_CLASS.BUTTON.LINK
                    )}
                    data-dtm={setAdobeLaunchDTM(
                      "",
                      ADOBELAUNCHER_DTM.OPTIONS.TIREWHEEL,
                      applicationStore.data.productType
                    )}
                    onClick={this.onToggleSearch}
                  >
                    {this.t("change")}
                  </button>
                </h2>
                <hr />
              </box>
            </box>
          );
        } else {
          return (
            <box>
              <container className="top small"></container>
            </box>
          );
        }
      case "volkswagen_2020":
        return (
          <box>
            <box>
              <container className="top large">
                <h2>
                  <a
                    id={getTestId(TEST_LIST.PRODUCT_LIST.FILTER.SEARCH.BUTTON)}
                    className={setAdobeLaunchClass(
                      "bold",
                      ADOBELAUNCHER_CLASS.BUTTON.LINK
                    )}
                    data-dtm={setAdobeLaunchDTM(
                      "",
                      ADOBELAUNCHER_DTM.OPTIONS.TIREWHEEL,
                      applicationStore.data.productType
                    )}
                    onClick={this.onToggleSearch}
                  >
                    {this.getFilterSearchValue()}
                  </a>
                </h2>
              </container>
            </box>
          </box>
        );
      default:
        return (
          <box>
            <box>
              {this.showFilterTitle() ? (
                <h1>{this.renderFilterPartTitle()}</h1>
              ) : (
                false
              )}
            </box>
            <box>
              <hr />
              <h2>
                {this.getFilterSearchValue()}{" "}
                <button
                  id={getTestId(TEST_LIST.PRODUCT_LIST.FILTER.SEARCH.BUTTON)}
                  className={setAdobeLaunchClass(
                    "btn icon",
                    ADOBELAUNCHER_CLASS.BUTTON.LINK
                  )}
                  data-dtm={setAdobeLaunchDTM(
                    "",
                    ADOBELAUNCHER_DTM.OPTIONS.TIREWHEEL,
                    applicationStore.data.productType
                  )}
                  onClick={this.onToggleSearch}
                >
                  {this.t("change")}
                </button>
              </h2>
            </box>
            {isConsumerSite() ? (
              <box>
                <hr />
                <h2>
                  {this.t("launcher.enterPostal")}
                  {": "}
                  {this.state.consumerpostal.toUpperCase()}
                </h2>
              </box>
            ) : (
              false
            )}
          </box>
        );
    }
  };

  renderFilterAccessorySearch = () => {
    return (
      <box className="search">
        <box>
          <h2
            id={getTestId(TEST_LIST.PRODUCT_LIST.FILTER.SEARCH.ACCESSORY.TITLE)}
          >
            {this.t("filters").toUpperCase()}
          </h2>
        </box>
        {this.enableListView() ? (
          <box>
            <container className="top small">
              <column
                className={
                  this.state.view == VIEW.GRID ? "selected" : "pointer"
                }
                onClick={this.setView.bind(null, VIEW.GRID)}
              >
                <container>
                  <text
                    id={getTestId(
                      TEST_LIST.PRODUCT_LIST.FILTER.SEARCH.ACCESSORY.SELECT.GRID
                    )}
                  >
                    {this.t("product.gridView")}
                  </text>
                </container>
              </column>
              <column
                className={
                  this.state.view == VIEW.LIST ? "selected" : "pointer"
                }
                onClick={this.setView.bind(null, VIEW.LIST)}
              >
                <container>
                  <text
                    id={getTestId(
                      TEST_LIST.PRODUCT_LIST.FILTER.SEARCH.ACCESSORY.SELECT.TILE
                    )}
                  >
                    {this.t("product.listView")}
                  </text>
                </container>
              </column>
            </container>
          </box>
        ) : (
          false
        )}
        <box className="searchinput">
          <input
            className="filter-accessory"
            id={getTestId(TEST_LIST.PRODUCT_LIST.FILTER.SEARCH.ACCESSORY.INPUT)}
            placeholder={this.t("searchByName")}
          />
          {!isEmpty(this.state.filteraccessory) ? (
            <i onClick={this.resetAccessoryFilter} className="fa fa-close" />
          ) : (
            false
          )}
        </box>
      </box>
    );
  };

  showFilterTitle = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "hyundai":
      case "mazda":
        return false;
      default:
        return true;
    }
  };

  resetAccessoryFilter = () => {
    this.setState({
      filteraccessory: "",
    });
    if (document.getElementsByClassName("filter-accessory")[0]) {
      document.getElementsByClassName("filter-accessory")[0].value = "";
    }
  };

  getFilterSearchValue = () => {
    if (isConsumerSite()) {
      return this.state.consumersearch;
    } else if (!isEmpty(this.state.search)) {
      return this.state.search;
    } else {
      return getVehicleFilterInfo();
    }
  };

  getFilterSearchTitle = () => {
    switch (productListStore.data.searchType) {
      case SearchTypes.SEARCH_BY_TIRESIZE:
        return this.t("common.tiresizeSearch");
      case SearchTypes.SEARCH_BY_PARTNUMBER:
        return this.t("common.partnumberSearch");
      case SearchTypes.SEARCH_BY_MODEL:
      case SearchTypes.SEARCH_BY_VEHICLE:
        return this.t("common.vehicleSearch");
      case SearchTypes.SEARCH_BY_CHASSIS:
        return this.t("common.chassisCode");
    }
  };

  renderImageList = () => {
    if (
      !isConsumerSite() &&
      !isEmpty(window.dealerAccountInfoPayload) &&
      !isEmpty(window.dealerAccountInfoPayload.dealerCarousels) &&
      !isEmpty(window.dealerAccountInfoPayload.dealerCarousels.home_secondary)
    ) {
      var carousels = window.dealerAccountInfoPayload.dealerCarousels;
      return (
        <box>
          {Immutable.fromJS(carousels.home_secondary).map(function (
            image,
            index
          ) {
            return (
              <box key={"filter-ad-" + index}>
                <box className="imagelist">
                  <img
                    id={getTestId(
                      TEST_LIST.PRODUCT_LIST.FILTER.CAROUSEL.IMAGE,
                      index
                    )}
                    src={image.get("imageURL").get(getAppLanguage())}
                  />
                  {!isEmpty(image.get("title").get(getAppLanguage())) ? (
                    <h2>{image.get("title").get(getAppLanguage())}</h2>
                  ) : (
                    false
                  )}
                  {!isEmpty(image.get("subtitle").get(getAppLanguage())) ? (
                    <container>
                      <text>{image.get("subtitle").get(getAppLanguage())}</text>
                    </container>
                  ) : (
                    false
                  )}
                  {!isEmpty(image.get("linkURL").get(getAppLanguage())) &&
                  !isEmpty(image.get("linkText").get(getAppLanguage())) ? (
                    <container>
                      <button
                        id={getTestId(
                          TEST_LIST.PRODUCT_LIST.FILTER.CAROUSEL.BUTTON,
                          index
                        )}
                        className="btn"
                        onClick={function () {
                          window.open(
                            image.get("linkURL").get(getAppLanguage())
                          );
                        }}
                      >
                        {image.get("linkText").get(getAppLanguage())}
                      </button>
                    </container>
                  ) : (
                    false
                  )}
                </box>
              </box>
            );
          })}
        </box>
      );
    } else {
      return false;
    }
  };

  renderInfoModal = () => {
    if (!isEmpty(this.state.modal)) {
      return (
        <Modal
          refactor={true}
          requestUnmount={this.closeInfoModal}
          expandedHeader={this.enableExpandedHeader()}
          datadtm={this.getDataDTMValue()}
        >
          <div className="wrapper">
            <div className="modal-content info-modal">
              <container className="side">{this.state.modal}</container>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  getDataDTMValue = () => {
    if (!isEmpty(this.state.modaldtm)) {
      return setAdobeLaunchDTM(
        "",
        ADOBELAUNCHER_DTM.FEATUREDITEMS.PRODUCT,
        this.state.modaldtm
      );
    } else {
      return "";
    }
  };

  enableExpandedHeader = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mazda":
        return true;
      default:
        return false;
    }
  };

  openInfoModal = (modal, modaldtm = null) => {
    this.setState({
      modal: modal,
      modaldtm: modaldtm,
    });
  };

  closeInfoModal = () => {
    this.setState({
      modal: null,
      modaldtm: null,
    });
  };

  renderComparisonErrorModal = () => {
    if (
      this.state.comparisonErrorModal ||
      (this.state.comparisonLoadingError && !this.state.partListLoading)
    ) {
      return (
        <Modal
          refactor={true}
          useShortHeaderBar={true}
          requestUnmount={this.closeComparisonModal}
          datadtm={setAdobeLaunchDTM(
            "",
            ADOBELAUNCHER_DTM.COMPARE.NOTIFICATION
          )}
        >
          <div className="wrapper">
            <div className="modal-content">
              {this.state.comparisonErrorType === COMPARISON_ERROR_NO_PARTS ? (
                <span>{this.t("comparison.noPartsToCompare")}</span>
              ) : (
                false
              )}
              {this.state.comparisonErrorType ===
              COMPARISON_ERROR_PART_ALREADY_IN_LIST ? (
                <span>{this.t("comparison.partAlreadyInList")}</span>
              ) : (
                false
              )}
              {this.state.comparisonErrorType ===
              COMPARISON_ERROR_PART_LIST_IS_FULL ? (
                <span>{this.t("comparison.partListIsFull")}</span>
              ) : (
                false
              )}
              {this.state.comparisonErrorType ===
              COMPARISON_ERROR_FAILED_TO_LOAD ? (
                <span>{this.t("comparison.comparisonFailed")}</span>
              ) : (
                false
              )}
              <div className="button-container">
                <button
                  className={setAdobeLaunchClass(
                    "btn",
                    ADOBELAUNCHER_CLASS.BUTTON.LINK
                  )}
                  data-dtm={setAdobeLaunchDTM(
                    "",
                    ADOBELAUNCHER_DTM.COMPARE.NOTIFICATION
                  )}
                  onClick={this.closeComparisonModal}
                >
                  {this.t("common.close")}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  renderComparisonModal = () => {
    // console.log("comparisonLoadedSuccessfully: " + this.state.comparisonLoadedSuccessfully);
    if (this.props.comparisonLoadedSuccessfully) {
      return <ComparisonModal comparisonData={this.props.comparisonList} />;
    } else {
      return false;
    }
  };

  closeComparisonModal = () => {};

  renderNotOriginalSizeWarningModal = () => {
    if (
      !this.state.showOriginalTireSizeSelection &&
      this.state.notOriginalSizeWarning &&
      !this.state.partListLoading
    ) {
      return (
        <Modal
          refactor={true}
          useShortHeaderBar={true}
          requestUnmount={this.closeWarningModal}
        >
          <div className="wrapper">
            <div className="modal-content">
              <h2 className="title">
                {this.t("shoppingCartWarningsModal.notification")}
              </h2>
              <span>{this.t("product.tireNotOriginalSizeWarning")}</span>
              <div className="button-container">
                <button
                  className="btn"
                  ref="notOriginalWarningModal"
                  onClick={this.closeWarningModal}
                >
                  {this.t("shoppingCartWarningsModal.continue")}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );
    }
  };

  closeWarningModal = () => {
    this.props.dispatch(closeTireNotOriginalSizeWarning(false));
    // productListActions.closeTireNotOriginalSizeWarning();
  };

  renderGuideModal = () => {
    if (this.state.showGuide) {
      return (
        <Modal
          refactor={true}
          fullWidth={true}
          expandedHeader={this.enableExpandedHeader()}
          requestUnmount={this.toggleGuideModal}
        >
          <div className="wrapper">
            <div className="modal-content">
              <box className="guide-modal">
                <container className="small">
                  <box className="products reduced">{this.renderGuide()}</box>
                </container>
              </box>
            </div>
          </div>
        </Modal>
      );
    } else {
      return false;
    }
  };

  toggleGuideModal = () => {
    this.setState({
      showGuide: !this.state.showGuide,
    });
  };

  renderFeaturedList = () => {
    var openInfoModal = this.openInfoModal,
      mobile = this.state.mobile,
      enableCompare = this.enableCompare(),
      comparisonData = this.state.comparisonData,
      modal = this.state.modal;
    return (
      <box className="featuredlist">
        <box className="partlist">
          {Immutable.fromJS(this.getFilteredFeaturedProductList()).map(
            function (part, index) {
              var compare = false;
              if (sizeOf(comparisonData) > 0) {
                for (var i in comparisonData) {
                  if (
                    comparisonData[i] &&
                    comparisonData[i].id ==
                      part
                        .get("part")
                        .get("partDetails")
                        .get("primaryPart")
                        .get("itemId")
                  ) {
                    compare = true;
                  }
                }
              }
              if (!mobile) {
                return (
                  <column
                    className={index == 1 ? "featured second" : "featured"}
                  >
                    <ProductTile
                      key={"product-" + index}
                      index={part.get("position")}
                      part={part.get("part")}
                      featured={true}
                      compare={compare}
                      enableCompare={enableCompare}
                      clickImage={openInfoModal}
                      detail={!isEmpty(modal)}
                    />
                  </column>
                );
              } else {
                return (
                  <ProductTile
                    key={"product-" + index}
                    index={part.get("position")}
                    part={part.get("part")}
                    featured={true}
                    compare={compare}
                    enableCompare={enableCompare}
                    clickImage={openInfoModal}
                    detail={!isEmpty(modal)}
                  />
                );
              }
            }
          )}
        </box>
      </box>
    );
  };

  getFilteredFeaturedProductList = () => {
    // console.log('getFilteredFeaturedProductList',this.props.productFeaturedList);
    return this.getFilteredList(this.props.productFeaturedList, true);
  };

  getFilteredProductList = () => {
    if (!Array.isArray(this.state.partList)) {
      return [];
    }
    if (!isEmpty(this.state.partList)) {
      return this.getFilteredList(this.state.partList).sort(
        this.sortList.bind(null, false)
      );
    } else {
      return this.state.partList;
    }
  };

  getFilteredOriginalProductList = () => {
    if (!isEmpty(this.state.partList)) {
      return this.getFilteredList(this.state.partList, false, true).sort(
        this.sortList.bind(null, false)
      );
    } else {
      return this.state.partList;
    }
  };

  getFilteredNonOriginalProductList = () => {
    if (!isEmpty(this.state.partList)) {
      return this.getFilteredList(this.state.partList, false, false).sort(
        this.sortList.bind(null, true)
      );
    } else {
      return this.state.partList;
    }
  };

  getFilteredGuideList = () => {
    var list = this.props.productList.partList || this.props.productList;
    return list.filter(function (part) {
      if (part && part.partDetails) {
        var templist = part.partDetails.primaryPart.accessoryCategoryTags,
          taglist = productListStore.data.accessoryFilterTagList,
          id;

        if (!isEmpty(taglist)) {
          for (var i in taglist) {
            if (taglist[i] && taglist[i].tag_text.toLowerCase() == "popular") {
              id = taglist[i].tag_id;
            }
          }
        }

        if (!isEmpty(templist)) {
          for (var i in templist) {
            if (templist[i] && templist[i] == id) {
              return true;
            }
          }
        }
      }

      return false;
    });
  };

  sortList = (nonoe, a, b) => {
    if (isConsumerSite()) {
      return this.sortListByPrice(a, b);
    } else {
      switch (applicationStore.data.productType) {
        case ProductTypes.ACCESSORIES:
          return this.sortAccessoryList(a, b);
        case ProductTypes.PACKAGES:
        case ProductTypes.ALLOY_WHEELS:
          return 0;
        case ProductTypes.TIRES:
          return nonoe ? this.sortListByPrice(a, b, true) : 0;
      }
    }
  };

  sortListByPrice = (a, b, reverse = false) => {
    if (
      !!a.partPricing &&
      !!b.partPricing &&
      !!a.partPricing.primaryPart &&
      !!b.partPricing.primaryPart
    ) {
      if (
        sizeOf(a.partPricing.primaryPart.price) > 0 &&
        sizeOf(a.partPricing.primaryPart.price) > 0
      ) {
        var priceA = 0,
          priceB = 0;
        for (var i in a.partPricing.primaryPart.price) {
          if (sizeOf(a.partPricing.primaryPart.price[i].contextList) > 0) {
            priceA =
              a.partPricing.primaryPart.price[i].contextList[0].displayPrice;
          }
        }
        for (var i in b.partPricing.primaryPart.price) {
          if (sizeOf(b.partPricing.primaryPart.price[i].contextList) > 0) {
            priceB =
              b.partPricing.primaryPart.price[i].contextList[0].displayPrice;
          }
        }

        if (priceA == 0) {
          return !reverse ? 1 : -1;
        } else if (priceB == 0) {
          return !reverse ? -1 : 1;
        } else {
          return !reverse ? priceA - priceB : priceB - priceA;
        }
      } else if (sizeOf(a.partPricing.primaryPart.price) > 0) {
        return 1;
      } else {
        return -1;
      }
    } else {
      return 0;
    }
  };

  sortListByHasPricing = (a, b) => {
    if (
      !!a.partPricing &&
      !!b.partPricing &&
      !!a.partPricing.primaryPart &&
      !!b.partPricing.primaryPart
    ) {
      if (
        sizeOf(a.partPricing.primaryPart.price) > 0 &&
        sizeOf(a.partPricing.primaryPart.price) > 0
      ) {
        var hasPromotionA = false,
          hasPromotionB = false;
        for (var i in a.partPricing.primaryPart.price) {
          if (sizeOf(a.partPricing.primaryPart.price[i].contextList) > 0) {
            for (var j in a.partPricing.primaryPart.price[i].contextList) {
              if (
                a.partPricing.primaryPart.price[i].contextList[j]
                  .pricingContextId > 0
              ) {
                hasPromotionA = true;
              }
            }
          }
        }
        for (var i in a.partPricing.primaryPart.link) {
          if (sizeOf(a.partPricing.primaryPart.link[i].contextList) > 0) {
            for (var j in a.partPricing.primaryPart.link[i].contextList) {
              if (
                a.partPricing.primaryPart.link[i].contextList[j]
                  .pricingContextId > 0
              ) {
                hasPromotionA = true;
              }
            }
          }
        }
        for (var i in b.partPricing.primaryPart.price) {
          if (sizeOf(b.partPricing.primaryPart.price[i].contextList) > 0) {
            for (var j in b.partPricing.primaryPart.price[i].contextList) {
              if (
                b.partPricing.primaryPart.price[i].contextList[j]
                  .pricingContextId > 0
              ) {
                hasPromotionB = true;
              }
            }
          }
        }
        for (var i in b.partPricing.primaryPart.link) {
          if (sizeOf(b.partPricing.primaryPart.link[i].contextList) > 0) {
            for (var j in b.partPricing.primaryPart.link[i].contextList) {
              if (
                b.partPricing.primaryPart.link[i].contextList[j]
                  .pricingContextId > 0
              ) {
                hasPromotionB = true;
              }
            }
          }
        }

        var priceA = 0,
          priceB = 0;
        for (var i in a.partPricing.primaryPart.price) {
          if (sizeOf(a.partPricing.primaryPart.price[i].contextList) > 0) {
            priceA =
              a.partPricing.primaryPart.price[i].contextList[0].displayPrice;
          }
        }
        for (var i in b.partPricing.primaryPart.price) {
          if (sizeOf(b.partPricing.primaryPart.price[i].contextList) > 0) {
            priceB =
              b.partPricing.primaryPart.price[i].contextList[0].displayPrice;
          }
        }

        if (hasPromotionA) {
          return -1;
        } else if (hasPromotionB) {
          return 1;
        } else if (priceA > 0) {
          return -1;
        } else if (priceB > 0) {
          return 0;
        } else {
          return 1;
        }
      } else if (sizeOf(a.partPricing.primaryPart.price) > 0) {
        return 1;
      } else {
        return -1;
      }
    } else {
      return 0;
    }
  };

  sortAccessoryList = (a, b) => {
    if (
      !!a.partPricing &&
      !!b.partPricing &&
      !!a.partPricing.primaryPart &&
      !!b.partPricing.primaryPart
    ) {
      if (
        sizeOf(a.partPricing.primaryPart.price) > 0 &&
        sizeOf(a.partPricing.primaryPart.price) > 0
      ) {
        var hasPromotionA = false,
          hasPromotionB = false;
        for (var i in a.partPricing.primaryPart.price) {
          if (sizeOf(a.partPricing.primaryPart.price[i].contextList) > 0) {
            for (var j in a.partPricing.primaryPart.price[i].contextList) {
              if (
                a.partPricing.primaryPart.price[i].contextList[j]
                  .pricingContextId > 0
              ) {
                hasPromotionA = true;
              }
            }
          }
        }
        for (var i in a.partPricing.primaryPart.link) {
          if (sizeOf(a.partPricing.primaryPart.link[i].contextList) > 0) {
            for (var j in a.partPricing.primaryPart.link[i].contextList) {
              if (
                a.partPricing.primaryPart.link[i].contextList[j]
                  .pricingContextId > 0
              ) {
                hasPromotionA = true;
              }
            }
          }
        }
        for (var i in b.partPricing.primaryPart.price) {
          if (sizeOf(b.partPricing.primaryPart.price[i].contextList) > 0) {
            for (var j in b.partPricing.primaryPart.price[i].contextList) {
              if (
                b.partPricing.primaryPart.price[i].contextList[j]
                  .pricingContextId > 0
              ) {
                hasPromotionB = true;
              }
            }
          }
        }
        for (var i in b.partPricing.primaryPart.link) {
          if (sizeOf(b.partPricing.primaryPart.link[i].contextList) > 0) {
            for (var j in b.partPricing.primaryPart.link[i].contextList) {
              if (
                b.partPricing.primaryPart.link[i].contextList[j]
                  .pricingContextId > 0
              ) {
                hasPromotionB = true;
              }
            }
          }
        }

        if (hasPromotionA) {
          return -1;
        } else if (hasPromotionB) {
          return 1;
        } else {
          return this.sortAccessoryByName(a, b);
        }
      } else if (sizeOf(a.partPricing.primaryPart.price)) {
        return 1;
      } else {
        return -1;
      }
    } else {
      return this.sortAccessoryByName(a, b);
    }
  };

  sortAccessoryByName = (a, b) => {
    const appLanguage = getAppLanguage() || "en";
    const aName = a.partDetails.primaryPart.accessoryName[appLanguage];
    const bName = b.partDetails.primaryPart.accessoryName[appLanguage];

    if (aName === undefined || bName === undefined) {
      return 0;
    }

    const aNameLower = aName.toLowerCase();
    const bNameLower = bName.toLowerCase();

    if (aNameLower < bNameLower) {
      return -1;
    } else if (aNameLower > bNameLower) {
      return 1;
    } else {
      return 0;
    }
  };

  getFilteredList = (list, featured = false, original = null) => {
    try {
      var temp,
        templist,
        contextlist,
        price,
        pricelist,
        filterseason = this.state.season,
        filtertiresize = this.props.tiresize,
        filterrunflat = this.state.filterrunflat,
        filtertag = this.state.filtertag,
        filtercategory = this.state.filtercategory,
        filterbrand = this.state.filterbrand,
        filterpricing = this.state.filterpricing,
        filterload = this.state.filterload,
        filterspeed = this.state.filterspeed,
        filterply = this.state.filterply,
        filteraccessory = this.state.filteraccessory,
        istirefilter = applicationStore.data.productType === ProductTypes.TIRES,
        iswheelfilter =
          applicationStore.data.productType === ProductTypes.ALLOY_WHEELS,
        isaccessoryfilter =
          applicationStore.data.productType === ProductTypes.ACCESSORIES,
        ispackagefilter =
          applicationStore.data.productType === ProductTypes.PACKAGES;
      if (iswheelfilter) {
        let filterTireSize = this.props.filterByTireSize
          ? this.props.filterByTireSize
          : this.props.tireSize;
        var width, diameter;
        const tireSizeFilters = this.props.tireSizeFilters;
        const filterSize = tireSizeFilters.size;

        for (let i = 0; i < filterSize; i++) {
          const tireSize = tireSizeFilters.get(i);
          if (filterTireSize === tireSize.get("key")) {
            width = tireSize.get("width");
            diameter = tireSize.get("diameter");
          }
        }
      }
      // if list is an object convert it to array
      if (typeof list === "object") {
        templist = [];
        for (temp in list) {
          templist.push(list[temp]);
        }
        list = templist;
      }
      if (!isEmpty(list)) {
        let data = list.filter(function (part) {
          // Tires
          try {
            if (istirefilter) {
              if (isConsumerSite()) {
                if (filterseason != part.partDetails.primaryPart.tireSeason) {
                  return false;
                }
              }

              if (featured) part = part.part;

              if (original !== null) {
                if (
                  part.partDetails.primaryPart.tireOriginalEquipmentFlag !==
                  original
                ) {
                  return false;
                }
              }

              if (sizeOf(filterrunflat) === 1) {
                if (
                  filterrunflat.indexOf(1) > -1 &&
                  part.partDetails.primaryPart.tireRunFlat == 0
                ) {
                  return false;
                } else if (
                  filterrunflat.indexOf(2) > -1 &&
                  part.partDetails.primaryPart.tireRunFlat == 1
                ) {
                  return false;
                }
              }

              if (sizeOf(filtertag) > 0) {
                templist = part.partDetails.primaryPart.tireCategoryTags;
                temp = false;
                for (var i in templist) {
                  if (templist[i]) {
                    if (filtertag.indexOf(templist[i]) > -1) {
                      temp = true;
                    }
                  }
                }
                if (!temp) {
                  return temp;
                }
              }

              if (sizeOf(filterbrand) > 0) {
                if (
                  filterbrand.indexOf(
                    part.partDetails.primaryPart.tireManufacturerId
                  ) == -1
                ) {
                  return false;
                }
              }

              if (sizeOf(filterload) > 0) {
                if (
                  filterload.indexOf(
                    parseInt(part.partDetails.primaryPart.tireLoadRating)
                  ) == -1
                ) {
                  return false;
                }
              }

              if (sizeOf(filterspeed) > 0) {
                if (
                  filterspeed.indexOf(
                    part.partDetails.primaryPart.tireSpeedIndex
                  ) == -1
                ) {
                  return false;
                }
              }

              if (sizeOf(filterply) > 0) {
                if (
                  filterply.indexOf(
                    part.partDetails.primaryPart.tirePlyRating
                  ) == -1
                ) {
                  return false;
                }
              }
            }

            // Accessories

            if (isaccessoryfilter) {
              if (sizeOf(filtercategory) > 0) {
                (templist = []),
                  (temp = part.partDetails.primaryPart.accessoryCategories);
                for (var i in temp) {
                  if (temp[i]) {
                    templist.push(Object.keys(temp[i]).pop());
                  }
                }
                temp = false;
                for (var i in templist) {
                  if (templist[i]) {
                    if (filtercategory.indexOf(templist[i]) > -1) {
                      temp = true;
                    }
                  }
                }
                if (!temp) {
                  return false;
                }
              }

              if (!isEmpty(filteraccessory)) {
                if (
                  part.partDetails.primaryPart.accessoryName[getAppLanguage()]
                    .toLowerCase()
                    .indexOf(filteraccessory.toLowerCase()) === -1 &&
                  part.partDetails.primaryPart.accessoryPartNumber
                    .toLowerCase()
                    .indexOf(filteraccessory.toLowerCase()) === -1
                ) {
                  return false;
                }
              }
            }

            // Wheels

            if (iswheelfilter) {
              if (!part.partDetails || !part.partDetails.primaryPart) {
                return false;
              }

              if (
                !isValidSeason(
                  part.partDetails.primaryPart.wheelSeasonalSizeCode,
                  filterseason
                )
              ) {
                return false;
              }

              if (
                !isValidWidth(part.partDetails.primaryPart.wheelWidth, width)
              ) {
                return false;
              }

              if (part.partDetails.primaryPart.wheelDiameter != diameter) {
                return false;
              }
            }

            // All Parts

            if (part.partSummary && part.partSummary.primaryPart.hideItem) {
              return false;
            }

            if (sizeOf(filterpricing) > 0) {
              (temp = false), (pricelist = part.partPricing.primaryPart.price);
              for (var i in pricelist) {
                contextlist = pricelist[i].contextList;
                for (var i in contextlist) {
                  price = contextlist[i].displayPrice;
                  break;
                }
                break;
              }
              for (var i in filterpricing) {
                if (
                  filterpricing[i] &&
                  price >= PricingFilter[filterpricing[i]].min &&
                  (price <= PricingFilter[filterpricing[i]].max ||
                    !PricingFilter[filterpricing[i]].max)
                ) {
                  temp = true;
                }
              }
              if (!temp) {
                return temp;
              }
            }

            return true;
          } catch (e) {
            console.error(e);
          }
        });
        return data;
      } else {
        return list;
      }
    } catch (e) {
      console.error(e);
    }
  };

  getAccessorySortOrder = (part) => {
    if (!part?.accessoryCategories) {
      return Infinity;
    }
    return part.accessoryCategories.reduce((acc, item) => {
      const sortOrder = Object.values(item)?.[0]?.sortOrder || Infinity;
      return Math.min(acc, Number(sortOrder));
    }, Infinity);
  };

  renderList = (originalonly = null, view = null, isguide = false) => {
    // var openInfoModal = this.openInfoModal,
    //   enableCompare = this.enableCompare(),
    //   comparisonData = this.state.comparisonData,
    //   modal = this.state.modal,
    //   list = isguide ? this.getFilteredGuideList() : this.getListFilter(originalonly),
    //   autoadd = this.state.autoaddtocart,
    //   resetAutoAdd = this.resetAutoAdd;

    // Destructure variables for easier access
    const { filteraccessory, modal, productType } = this.state;
    let autoadd = this.state.autoaddtocart;
    let comparisonData = this.props.comparisonList;
    // Determine which list to display based on a condition
    const list = isguide
      ? this.getFilteredGuideList()
      : this.getListFilter(originalonly);
    // Assign variables

    const openInfoModal = this.openInfoModal;
    const enableCompare = this.enableCompare();
    const resetAutoAdd = this.resetAutoAdd;

    // Reset auto-add to cart function
    // const resetAutoAddFunction = resetAutoAdd;
    if (sizeOf(list) > 0) {
      return list
        .sort((a, b) => {
          if (productType === ProductTypes.ACCESSORIES) {
            const partA = a.partDetails?.primaryPart || {};
            const partB = b.partDetails?.primaryPart || {};
            const sortOrderA = this.getAccessorySortOrder(partA);
            const sortOrderB = this.getAccessorySortOrder(partB);
            const nameA =
              partA.accessoryName?.[getAppLanguage()]?.toLowerCase() || "";
            const nameB =
              partB.accessoryName?.[getAppLanguage()]?.toLowerCase() || "";

            if (sortOrderA !== sortOrderB && !filteraccessory) {
              return sortOrderA - sortOrderB;
            }
            return nameA.localeCompare(nameB);
          }
          return 0;
        })
        .slice(0, this.getFilterListCount(list, isguide))
        .map((part, index) => {
          let compare = false;
          if (comparisonData.length > 0) {
            for (const data of comparisonData) {
              if (data?.id === part.partDetails?.primaryPart?.itemId) {
                compare = true;
              }
            }
          }
          const key = `product-${index}-${
            part.partDetails?.primaryPart?.itemId
          }${
            part.partDetails?.secondaryParts?.length > 0
              ? `-${part.partDetails.secondaryParts[0].itemId}`
              : ""
          }`;

          return (
            <ProductTile
              key={key}
              index={index}
              part={Immutable.fromJS(part)}
              compare={compare}
              enableCompare={enableCompare}
              clickImage={openInfoModal}
              detail={!isEmpty(modal)}
              autoadd={autoadd}
              resetAutoAdd={resetAutoAdd}
              view={view}
            />
          );
        });
    } else if (
      sizeOf(list) == 0 &&
      sizeOf(this.state.partList) > 0 &&
      sizeOf(this.getFilteredFeaturedProductList()) == 0 &&
      sizeOf(this.getFilteredOriginalProductList()) == 0 &&
      sizeOf(this.getFilteredNonOriginalProductList()) == 0
    ) {
      return (
        <box className="noparts">
          <container>
            <text>{this.t("noPartsError")}</text>
          </container>
        </box>
      );
    } else {
      return false;
    }
  };

  getFilterListCount = (list, isguide) => {
    if (isguide || (this.enableListView() && this.state.view == VIEW.LIST)) {
      return sizeOf(list);
    } else {
      return this.state.pageIndex * getProductListNumberResultsToShow();
    }
  };

  getListFilter = (originalonly) => {
    switch (applicationStore.data.productType) {
      case ProductTypes.ACCESSORIES:
      case ProductTypes.PACKAGES:
      case ProductTypes.ALLOY_WHEELS:
        return this.getFilteredProductList();
      case ProductTypes.TIRES:
        switch (originalonly) {
          case true:
            return this.getFilteredOriginalProductList();
          case false:
            return this.getFilteredNonOriginalProductList();
          case null:
            return this.getFilteredProductList();
        }
    }
  };

  getGuideFilterList = () => {
    switch (applicationStore.data.productType) {
      case ProductTypes.ACCESSORIES:
        return this.getFilteredGuideList();
      case ProductTypes.PACKAGES:
      case ProductTypes.ALLOY_WHEELS:
      case ProductTypes.TIRES:
        return [];
    }
  };

  renderMoreButton = () => {
    if (this.showMoreResultsButton()) {
      return (
        <box className="showmore">
          <hr />
          <button
            className={setAdobeLaunchClass(
              "btn",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM(
              "",
              ADOBELAUNCHER_DTM.FEATUREDITEMS.BASE
            )}
            onClick={this.onClickMore}
          >
            {this.t("product.moreProducts")}
          </button>
        </box>
      );
    } else {
      return false;
    }
  };

  showMoreResultsButton = () => {
    return (
      sizeOf(this.getFilteredProductList()) >
      this.state.pageIndex * getProductListNumberResultsToShow()
    );
  };

  onSeasonFilter = (season) => {
    //guardRequiresTokenEvent(this.seasonFilter.bind(this, season));
    this.seasonFilter(season);
    // this.props.dispatch(filterBySeason(season))
  };

  seasonFilter = (season) => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Part List Filter (TIRES) - Season Filter Changed"
    );
    if (isConsumerSite()) {
      searchActions.loadConsumerTires(season);
    } else if (!isEmpty(applicationStore.data.search)) {
      searchActions.filterSeason(season);
    } else {
      // Update tiresize list count
      if (applicationStore.data.productType === ProductTypes.ALLOY_WHEELS) {
        var tiresizeCount = [],
          wheelWidthArray = [],
          list = this.state.partList;
        this.props.tireSizeFilters.map(function (tiresize) {
          tiresizeCount[tiresize.get("key")] = 0;
          wheelWidthArray[tiresize.get("key")] = {
            diameter: tiresize.get("diameter"),
            width: tiresize.get("width"),
          };
        });
        for (var i in list) {
          if (list[i]) {
            for (var j in wheelWidthArray) {
              if (
                wheelWidthArray[j] &&
                isValidSeason(
                  list[i].partDetails.primaryPart.wheelSeasonalSizeCode,
                  season
                ) &&
                isValidWidth(
                  list[i].partDetails.primaryPart.wheelWidth,
                  wheelWidthArray[j].width
                ) &&
                list[i].partDetails.primaryPart.wheelDiameter ==
                  wheelWidthArray[j].diameter
              ) {
                tiresizeCount[j]++;
              }
            }
          }
        }
        if (tiresizeCount[this.props.tireSize] == 0) {
          for (var i in tiresizeCount) {
            if (tiresizeCount[i] && tiresizeCount[i] > 0) {
              this.props.tireSize = i;
            }
          }
        }
        this.updateState({ tireSizeSpecIdCountArray: tiresizeCount });
        // this.props.tireSizeSpecIdCountArray = tiresizeCount;
      }
      this.props.dispatch(filterBySeason(season));

      // productListActions.filterBySeason(season);
    }
  };

  onTiresizeFilter = (tireSize) => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Part List Filter (TIRES) - Tire Size Filter Changed"
    );
    this.props.dispatch(filterByTireSize(tireSize));
    this.props.dispatch(setTireSize(tireSize));

    // productListActions.filterByTireSize(tireSize);
    this.updateState({});
  };

  onOriginalFilter = (oe) => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Part List Filter (TIRES) - OE Filter Changed"
    );

    if (oe == 2) {
      var modal = (
        <box>
          <container className="top">
            <text>{this.t("nonOEWarrning")}</text>
          </container>
        </box>
      );

      this.setState({
        modal: modal,
      });
    }
    this.props.dispatch(setOeFilterValue(oe));
    // productListActions.setOeFilterValue(oe);
    setTimeout(() => {
      this.loadTires();
    }, 0);
  };

  removeFromComparisonList = (part) => {
    var temp,
      list = this.state.comparisonData;
    for (var i in list) {
      if (list[i].id === part.get("id")) {
        temp = list[i];
      }
    }
    this.props.dispatch(removeProductFromComparison(temp));
    // this.props.dispatch(onAddProductToComparison(part))
    // productListActions.onRemoveProductToComparison(temp);
  };

  onToggleSearch = () => {
    if (isConsumerSite()) {
      applicationActions.changeRouterStateDirectly(RouterStates.HOMEPAGE);
    } else {
      applicationActions.toggleSearchFlag();
    }
  };

  onClickMore = () => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Tire List - seeMoreProducts"
    );
    var temp = this.state.pageIndex + 1;
    this.setState({
      pageIndex: temp,
    });
  };

  onChange = () => {
    if (!this.isMounted) return;
    let reset = this.state.productType !== applicationStore.data.productType;
    this.setState({
      loggedIn: isUserLoggedIn(true),

      partList: reset ? [] : this.props.productList,

      search: applicationStore.data.search,
      consumersearch: applicationStore.data.consumerTiresize,
      consumerpostal: applicationStore.data.consumerPostal,
      searchType: productListStore.data.searchType,
      productType: applicationStore.data.productType,

      routerState: getRouterState(),

      season: this.props.selectedSeason,
      tiresize: productListStore.data.tireSize,

      colors: productListStore.data.visualizerColours,
      foreground: productListStore.data.visualizerForeground,
      shadow: productListStore.data.visualizerShadow,

      comparisonData: this.props.comparisonList,
      comparisonErrorModal: productListStore.data.failedAddToComparison,
      comparisonErrorType: productListStore.data.comparisonErrorType,
      comparisonLoadingError: productListStore.data.comparisonLoadingError,
      comparisonLoadedSuccessfully: this.props.comparisonLoadedSuccessfully,

      launchComparisonPdf: productListStore.data.launchComparisonPdf,
      launchGuidePdf: productListStore.data.launchGuidePdf,

      view: this.enableListView() ? VIEW.LIST : VIEW.GRID,

      partListLoading:
        (!this.props.partLoading &&
          applicationStore.data.productType === ProductTypes.ACCESSORIES) ||
        (!this.props.partLoading &&
          applicationStore.data.productType === ProductTypes.TIRES) ||
        (!this.props.partLoading &&
          applicationStore.data.productType === ProductTypes.PACKAGES) ||
        (!this.props.partLoading &&
          applicationStore.data.productType === ProductTypes.ALLOY_WHEELS),
    });
  };

  onReadyPdfPrint = (isComparison, launcher) => {
    applicationActions.showLoadingScreen();

    if (isComparison) {
      googleAnalyticsEnhancedTrackEvent(
        getTrackingEventCategory(),
        "Tire List - CompareSelected"
      );
      this.collectComparisonBase64Images(launcher);
    } else {
      this.collectGuideBase64Images(launcher);
    }
  };

  collectComparisonBase64Images = (launcher) => {
    var imageList = [],
      comparisonData = this.state.comparisonData;
    imageList.push({
      id: "logo",
      url: applicationStore.data.dealerRetailSiteCustomLogo,
    });

    for (var i in comparisonData) {
      if (comparisonData[i]) {
        imageList.push({
          id: comparisonData[i].id,
          url: comparisonData[i].imageUrl,
        });
        imageList.push({
          id: comparisonData[i].part.partDetails.primaryPart.tireManufacturerId,
          url: comparisonData[i].part.partDetails.primaryPart
            .tireManufacturerImage,
        });
      }

      /*
      for (var j in this.state.partList) {
        if (this.state.partList[j] && comparisonData[i].id == this.state.partList[j].partDetails.primaryPart.itemId) {
          imageList.push({ id: comparisonData[i].id, url: comparisonData[i].imageUrl });
          imageList.push({ id: this.state.partList[j].partDetails.primaryPart.tireManufacturerId, url: this.state.partList[j].partDetails.primaryPart.tireManufacturerImage });
          break;
        }
      }
      */
    }

    this.setState({
      pdfComparisonLauncher: launcher,
      pdfData: {
        initializeComparison: true,
        imageList: imageList,
        encodedImageList: [],
      },
    });

    this.encodeBase64Images(true, imageList);
  };

  collectGuideBase64Images = (launcher) => {
    var imageList = [],
      list = this.getFilteredProductList();
    imageList.push({
      id: "logo",
      url: applicationStore.data.dealerRetailSiteCustomLogo,
    });

    for (var i in list) {
      if (list[i]) {
        imageList.push({
          id: list[i].partDetails.primaryPart.itemId,
          url: list[i].partDetails.primaryPart.accessoryImageLink,
        });
      }
    }

    this.setState({
      pdfGuideLauncher: launcher,
      pdfData: {
        initializeGuide: true,
        imageList: imageList,
        encodedImageList: [],
      },
    });

    this.encodeBase64Images(false, imageList);
  };

  launchPdfMake = () => {
    if (this.state.pdfData.initializeComparison) {
      this.launchComparisonPdf();
    } else if (this.state.pdfData.initializeGuide) {
      this.launchGuidePdf();
    }
  };

  launchGuidePdf = () => {
    var pdfGuideLauncher = this.state.pdfGuideLauncher,
      encodedImageList = this.state.pdfData.encodedImageList,
      partList = this.getFilteredGuideList();

    applicationActions.hideLoadingScreen();

    if (pdfGuideLauncher === PDFLauncher.VIEW) {
      pdfMake
        .createPdf(
          getGuideDocDefinition(
            applicationStore.data.dealerInfo,
            encodedImageList,
            partList,
            breadcrumbsStore.getBreadcrumbs()
          )
        )
        .open();
    } else if (pdfGuideLauncher === PDFLauncher.DOWNLOAD) {
      pdfMake
        .createPdf(
          getGuideDocDefinition(
            applicationStore.data.dealerInfo,
            encodedImageList,
            partList,
            breadcrumbsStore.getBreadcrumbs()
          )
        )
        .download(getGuidePdfFilename());
    }
  };

  launchComparisonPdf = () => {
    var comparisonIdList = [],
      comparisonList = [];

    //console.log('launchComparisonPdf');
    //console.log(this.state.comparisonData);
    //console.log(this.state.partList);

    for (var i in this.state.comparisonData) {
      comparisonIdList.push(this.state.comparisonData[i].id);
      comparisonList.push(this.state.comparisonData[i].part);
      /*
      for (var j in this.state.partList) {
        if (this.state.partList[j] && this.state.comparisonData[i].id == this.state.partList[j].partDetails.primaryPart.itemId) {
          comparisonList.push(this.state.partList[j]);
          break;
        }
      }
      */
    }

    var pdfComparisonLauncher = this.state.pdfComparisonLauncher,
      encodedImageList = this.state.pdfData.encodedImageList;

    var callback = function (comparisonData) {
      //console.log('launchComparisonPdf - callback');
      //console.log(encodedImageList);
      //console.log(comparisonData);

      comparisonData = comparisonData.compareResults;

      var comparisonDataList = [];
      for (var i in comparisonData) {
        comparisonDataList.push({
          id: comparisonData[i].part.partDetails.primaryPart.itemId,
          name: comparisonData[i].part.partDetails.primaryPart.tireModelName,
          manufacturer:
            comparisonData[i].part.partDetails.primaryPart.tireManufacturerName,
          manufacturerId:
            comparisonData[i].part.partDetails.primaryPart.tireManufacturerId,
          tiresize:
            comparisonData[i].part.partDetails.primaryPart.formattedTiresize,
          partNumber:
            comparisonData[i].part.partDetails.primaryPart
              .tireManufacturerPartNumber,
          nationalPartNumber:
            comparisonData[i].part.partDetails.primaryPart
              .tireNationalPartNumber,
          load: comparisonData[i].part.partDetails.primaryPart.tireLoadRating,
          speed: comparisonData[i].part.partDetails.primaryPart.tireSpeedIndex,
          ply: comparisonData[i].part.partDetails.primaryPart.tirePlyRating,
          season: comparisonData[i].part.partDetails.primaryPart.tireSeason,
          originalEquipment:
            comparisonData[i].part.partDetails.primaryPart
              .tireOriginalEquipmentFlag,
          quantity: comparisonData[i].partQuantity,
          description:
            comparisonData[i].part.partDetails.primaryPart.tireModelDescription,
          warranty:
            comparisonData[i].part.partDetails.primaryPart.tireWarrantyList,
        });
      }

      //console.log(comparisonData);
      //console.log(comparisonDataList);
      //console.log(comparisonList);

      applicationActions.hideLoadingScreen();

      if (pdfComparisonLauncher === PDFLauncher.VIEW) {
        pdfMake
          .createPdf(
            getComparisonDocDefinition(
              applicationStore.data.dealerInfo,
              encodedImageList,
              comparisonDataList,
              comparisonList
            )
          )
          .open();
      } else if (pdfComparisonLauncher === PDFLauncher.DOWNLOAD) {
        pdfMake
          .createPdf(
            getComparisonDocDefinition(
              applicationStore.data.dealerInfo,
              encodedImageList,
              comparisonDataList,
              comparisonList
            )
          )
          .download(getComparisonPdfFilename());
      }
    };

    productListActions.onRunPdfComparison(
      ProductTypes.TIRES,
      vehicleSelectionStore.data.vehicleId,
      comparisonIdList,
      callback
    );
  };

  encodeBase64Images = (isComparison, imageList) => {
    var setEncodedImageList = this.setEncodedImageList;
    encodeBase64List(imageList, function (id, url, image) {
      setEncodedImageList(isComparison, id, url, image, imageList);
    });
  };

  setEncodedImageList = (isComparison, id, url, image, imageList) => {
    var encodedImageList = this.state.pdfData.encodedImageList;
    if (id) {
      encodedImageList[id] = image;
    }
    this.setState({
      pdfData: {
        initializeComparison: isComparison,
        initializeGuide: !isComparison,
        imageList: imageList,
        encodedImageList: encodedImageList,
      },
    });
  };

  resetPdfData = () => {
    this.setState({
      pdfComparisonLauncher: null,
      pdfGuideLauncher: null,
      pdfData: {
        initializeComparison: false,
        initializeGuide: false,
        imageList: [],
        encodedImageList: [],
      },
    });
  };
}

function mapStateToProps(state) {
  return {
    state,
    comparisonList: state.productList.comparisonList,
    comparisonLoadedSuccessfully:
      state.productList.comparisonLoadedSuccessfully,
    productList: state.productList.productList,
    partLoading: state.productList.productListLoaded,
    seasonCountArray: state.productList.seasonCountArray,
    selectedSeason: state.productList.selectedSeason,
    tireSizeSpecIdCountArray: state.productList.tireSizeSpecIdCountArray,
    tireListMaxPrice: state.productList.tireListMaxPrice,
    tireListMinPrice: state.productList.tireListMinPrice,
    loadRatingList: state.productList.loadRatingList,
    speedRatingList: state.productList.speedRatingList,
    plyRatingList: state.productList.plyRatingList,
    vehicleFullTireSizeInfo: state.productList.vehicleFullTireSizeInfo,
    tireSize: state.productList.subModelTireId,
    productFeaturedList: state.productList.productFeaturedList,
    filteredBrandIds: state.productList.filteredBrandIds,
    tireSizeFilters: state.productList.tireSizeFilters,
    accessoryCategoryShadowList: state.productList.accessoryCategoryShadowList,
    visualizerColours: state.productList.visualizerColours,
    visualizerForeground: state.productList.visualizerForeground,
    visualizerShadow: state.productList.visualizerShadow,
    colourCodes: state.productList.colourCodes,
    colourCodeInDetail: state.productList.colourCodeInDetail,
    filterByTireSize: state.productList.filterByTireSize,
    tireLoading: state.productList.reduxCall,
  };
}

export default connect(mapStateToProps)(ProductListRefactor);

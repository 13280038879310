import { AbstractAction } from './AbstractAction.js';
import { DispatcherActions } from '../constants/DispatcherActions.js';

var ModalActions = AbstractAction.extend({

  modalMounted: function() {
    this.dispatch(DispatcherActions.MODAL_MOUNTED, {});
  },

  modalUnmounted: function() {
    this.dispatch(DispatcherActions.MODAL_UNMOUNTED, {});
  },

  requestUnmount: function() {
    this.dispatch(DispatcherActions.MODAL_REQUEST_UNMOUNT, {});
  },

});

export const modalActions = new ModalActions();

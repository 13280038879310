import { breadcrumbActions } from "../../actions/BreadcrumbActions.js";
import { applicationActions } from "../../actions/ApplicationActions.js";
import { productListActions } from "../../actions/ProductListActions.js";
import { vehicleSelectionActions } from "../../actions/VehicleSelectionActions.js";

import { vehicleSelectionStore } from "../../stores/VehicleSelectionStore.js";
import { applicationStore } from "../../stores/ApplicationStore.js";

import { ProductTypes } from "../../constants/ProductTypes.js";
import { SelectBox } from "../ux/SelectBox.jsx";
import { Image } from "../ux/Image.jsx";

import { isUserLoggedIn } from "../../api/AuthService";
import {
  getTabIndex,
  scrollToPageTop,
  getAppLanguage,
  getTrackingEventCategory,
  getTrackingEventSubmodel,
  sizeOf,
  isEmpty,
} from "../../service/UtilityService";
import {
  ADOBELAUNCHER_CLASS,
  ADOBELAUNCHER_DTM,
  setAdobeLaunchClass,
  setAdobeLaunchDTM,
} from "../../service/AdobeLaunchService";

import { RouterStates } from "../../constants/RouterStates.js";
import { setTireSize } from "../../redux/features/productList.js";
import { connect } from "react-redux";
import { I18nComponent } from "../../i18n/I18nComponent.jsx";

var _ = require("lodash");
var React = require("react");
var Immutable = require("immutable");
var Cx = require("classnames");

class VehicleSelectionTrims extends I18nComponent {
  state = {
    year: applicationStore.data.year,
    make: applicationStore.data.make,
    category: applicationStore.data.category,
    model: applicationStore.data.model,
    trims: vehicleSelectionStore.data.trims,
    immediateskiptrim: false,
  };

  updateState = (state) => {
    this.setState(state);
  };

  componentWillMount() {
    googleAnalyticsEnhancedTrackPageview("Vehicle Select Submodel");
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Vehicle Select Submodel Access"
    );
    vehicleSelectionStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    vehicleSelectionStore.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    vehicleSelectionActions.loadTrims();
  }

  componentDidUpdate(lastProps, lastState) {
    scrollToPageTop(this.state.trims, lastState.trims);
    if (
      !this.state.immediateskiptrim &&
      this.enableImmediateSkipTrim() &&
      sizeOf(this.state.trims) > 0
    ) {
      this.setState({ immediateskiptrim: true });

      var firstTrimSelectionFunction = false,
        trim = this.state.trims[0],
        tiresizeArray = trim.tiresizeArray;
      _.map(
        tiresizeArray,
        function (tiresize, index) {
          var trimArray = {
            trimId: trim.id,
            trimName: trim.name,
            vehicleId: trim.vehicleId,
            tiresizeId: tiresize.vehicleSubmodelTiresizeId,
          };
          if (index === 0)
            firstTrimSelectionFunction = this.onTrimClickRefactored.bind(
              this,
              trimArray
            );
        },
        this
      );

      setTimeout(() => {
        this.skipTrimSelection(firstTrimSelectionFunction);
      }, 100);
    }
  }

  enableImmediateSkipTrim = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mercedes":
      case "mercedestransition":
        return true;
      default:
        return false;
    }
  };

  render() {
    var firstTrimSelectionFunction = this.getFirstTrimSelectionFunction(),
      enableTireSizeSelector = this.getEnableTiresizeSelector();
    return (
      <div className="main">
        <div className="container">
          <container>{this.renderTiresizeHelper()}</container>
          {applicationStore.data.enableSubmodelSkipping ? (
            <div className="skip-trim-selection">
              <div className="float-container">
                <span>{this.t("common.notSureAboutYourTrim")}</span>
                <button
                  className="btn"
                  onClick={this.skipTrimSelection.bind(
                    this,
                    firstTrimSelectionFunction
                  )}
                >
                  {this.t("common.notSureAboutYourTrimButtonText")}
                </button>
              </div>
            </div>
          ) : (
            false
          )}
          <ul
            className={
              enableTireSizeSelector
                ? "select-trim"
                : "select-trim no-help-button"
            }
          >
            {this.renderTiresizeList()}
          </ul>
        </div>
      </div>
    );
  }

  getFirstTrimSelectionFunction = () => {
    if (!isEmpty(this.state.trims)) {
      var onTrimClick = this.onTrimClick;
      this.state.trims.map(function (trim, index) {
        if (index === 0) {
          return onTrimClick.bind(this, trim.id, trim.name);
        }
      });
    }
    return function () {};
  };

  renderTiresizeList = () => {
    var enableTireSizeSelector = this.getEnableTiresizeSelector(),
      enableQuarterWidth = this.getEnableQuarterWidthTiles();
    if (!isEmpty(this.state.trims)) {
      return this.state.trims.map(function (trim, index) {
        return (
          <li
            key={trim.id}
            tabIndex={this.getTileTabIndex()}
            aria-label={this.getTileAriaLabel(trim)}
            className={this.getQuarterTileClass()}
            onClick={this.getTileOnClick.bind(this, trim)}
          >
            <div className="trim-selection">
              <div className="trim-image">
                {this.renderImage(trim)}
                <div className="trim-name">
                  <span className="trim-name">
                    {trim.name[getAppLanguage()]}{" "}
                    {this.renderChassisNumber(trim)}
                  </span>
                </div>
              </div>
              {enableTireSizeSelector
                ? this.renderSelectButtonRefactor(trim)
                : false}
              {enableTireSizeSelector
                ? this.renderSelectDropDownRefactor(trim)
                : false}
              {!enableTireSizeSelector
                ? this.renderSelectButton(
                    trim.tiresizeArray[0].vehicleSubmodelTiresizeId
                  )
                : false}
              {applicationStore.data.dealerAllowNtdConsumerPurchase &&
              (enableTireSizeSelector || !enableQuarterWidth)
                ? this.renderConsumerPurchaseShippingDisclaimer()
                : false}
            </div>
          </li>
        );
      }, this);
    } else {
      return false;
    }
  };

  getTileAriaLabel = (trim) => {
    return this.t("breadcrumbs.trim") + ": " + trim.name[getAppLanguage()];
  };

  getTileTabIndex = () => {
    if (!this.getEnableTiresizeSelector()) {
      return getTabIndex();
    } else {
      return "-1";
    }
  };

  getTileOnClick = (trim) => {
    if (!this.getEnableTiresizeSelector()) {
      return this.onTrimClick.bind(this, trim.id, trim.name);
    } else {
      return function () {};
    }
  };

  getQuarterTileClass = () => {
    if (!this.getEnableTiresizeSelector()) {
      if (this.enableWideTiles()) {
        return "trim-selection-quarter wide-tile";
      } else {
        return "trim-selection-quarter";
      }
    } else {
      return "";
    }
  };

  enableWideTiles = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "mercedes":
      case "mercedestransition":
        return true;
      default:
        return false;
    }
  };

  renderTiresizeHelper = () => {
    if (this.getEnableTiresizeSelector()) {
      return (
        <ul className={this.getTiresizeHelperClass()}>
          <button
            className={setAdobeLaunchClass(
              "btn tire-size-info",
              ADOBELAUNCHER_CLASS.BUTTON.LINK
            )}
            data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.TIRESIZE.BASE)}
            onClick={this.onShowTireSizeHelperModal}
          >
            <i className="fa fa-question-circle" aria-hidden="true"></i>
            <span>{this.t("findMyTireSize.howTo")}</span>
          </button>
        </ul>
      );
    } else {
      return false;
    }
  };

  getTiresizeHelperClass = () => {
    return Cx({
      btn: true,
      "tire-size-helper": true,
      french: getAppLanguage() === "fr",
    });
  };

  getEnableTiresizeSelector = () => {
    return (
      applicationStore.data.enableShowSubmodelTiresizeSelection &&
      applicationStore.data.productType === ProductTypes.TIRES
    );
  };

  getEnableQuarterWidthTiles = () => {
    return (
      applicationStore.data.enableQuarterWidthTiresizeSelection &&
      (applicationStore.data.productType === ProductTypes.TIRES ||
        applicationStore.data.productType === ProductTypes.PACKAGES)
    );
  };

  getEnableCompare = () => {
    return (
      applicationStore.data.enableQuickCompare &&
      applicationStore.data.productListCompare &&
      !applicationStore.data.dealerHidePublicPricing(isUserLoggedIn())
    );
  };

  renderImage = (trim) => {
    if (trim.image) {
      return (
        <Image
          src={trim.image}
          alt={this.t("breadcrumbs.trim") + " " + trim.name[getAppLanguage()]}
        />
      );
    } else {
      return false;
    }
  };

  renderConsumerPurchaseShippingDisclaimer = () => {
    return (
      <div className="shipping-disclaimer">
        <i className="fa fa-truck" />
        <span className="disclaimer-part-1">
          {this.t("ntdStock.shippingDisclaimer_1")}
        </span>
        <i className="fa fa-clock-o" />
        <span className="disclaimer-part-2">
          {this.t("ntdStock.shippingDisclaimer_2")}
        </span>
      </div>
    );
  };

  skipTrimSelection = (trimSelectFunction) => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Vehicle Select Submodel - SkipSubmodelSelection"
    );
    trimSelectFunction();
  };

  onShowTireSizeHelperModal = () => {
    applicationActions.onOpenTireSizeHelper(true);
  };

  renderSelectButton = (id) => {
    return (
      <div>
        <button
          className={setAdobeLaunchClass(
            this.getSelectButtonClass(),
            ADOBELAUNCHER_CLASS.BUTTON.LINK
          )}
          data-dtm={setAdobeLaunchDTM("", ADOBELAUNCHER_DTM.MODELSELECTION)}
          onClick={this.onTrimClick.bind(this, id)}
        >
          {this.t("common.select")}
        </button>
      </div>
    );
  };

  getSelectButtonClass = () => {
    return Cx({
      btn: true,
      "single-trim-btn": true,
      "has-compare": this.getEnableCompare(),
    });
  };

  renderSelectButtonRefactor = (trim) => {
    var getSelectedTrimData = this.getSelectedTrimData,
      selectedTrim = this.getSelectedTrim(trim.id),
      renderTiresizeButton = this.renderTiresizeButton,
      renderQuickCompare = this.renderQuickCompare,
      getSelectButtonAriaLabel = this.getSelectButtonAriaLabel;
    return (
      <div className={this.getSelectButtonRefactorClass(trim.id)}>
        {selectedTrim.get("tiresizeArray").map(function (tiresize) {
          var trimData = getSelectedTrimData(trim.id, tiresize);
          return (
            <div
              className="select-trim-button"
              key={
                "vehicleTireSize-" + tiresize.get("vehicleSubmodelTiresizeId")
              }
              aria-label={getSelectButtonAriaLabel(trim.id, tiresize)}
            >
              {renderTiresizeButton(tiresize, trimData, trim)}
              {renderQuickCompare(trimData, trim)}
            </div>
          );
        })}
      </div>
    );
  };

  getSelectedTrim = (trimId) => {
    if (sizeOf(this.state.trims) > 0 && trimId > 0) {
      return Immutable.fromJS(
        this.state.trims.filter((trim) => trim.id === trimId)
      ).get(0);
    } else {
      return null;
    }
  };

  getTrimFromTiresizeId = (tiresizeId) => {
    var trims = this.state.trims,
      trim = null;
    for (var i in trims) {
      if (trims[i] && trims[i].tiresizeArray) {
        for (var j in trims[i].tiresizeArray) {
          if (
            trims[i].tiresizeArray[j] &&
            trims[i].tiresizeArray[j].vehicleSubmodelTiresizeId == tiresizeId
          ) {
            trim = trims[i];
          }
        }
      }
    }
    return trim;
  };

  getTiresizeCount = (trimId) => {
    var selectedTrim = this.getSelectedTrim(trimId),
      tireSizeCount = 0;
    if (!isEmpty(selectedTrim)) {
      selectedTrim.get("tiresizeArray").map(function () {
        tireSizeCount++;
      });
    }
    return tireSizeCount;
  };

  getSelectButtonRefactorClass = (trimId) => {
    return Cx({
      "select-trim-button-group": true,
      "full-trim-list": this.getTiresizeCount(trimId) > 4,
      desktop: this.getTiresizeCount(trimId) <= 4,
    });
  };

  getSelectedTrimData = (trimId, tiresize) => {
    var selectedTrim = this.getSelectedTrim(trimId);
    return {
      trimId: selectedTrim.get("id"),
      trimName: selectedTrim.get("name"),
      vehicleId: selectedTrim.get("vehicleId"),
      tiresizeId: tiresize.get("vehicleSubmodelTiresizeId"),
    };
  };

  getSelectButtonAriaLabel = (trimId, tiresize) => {
    var selectedTrim = this.getSelectedTrim(trimId);
    return (
      "(" +
      tiresize.get("tiresizeTypeDescription").get(getAppLanguage()) +
      ")" +
      selectedTrim.get("name").get(getAppLanguage()) +
      ": " +
      (tiresize.get("secondaryFormattedTireSize")
        ? this.t("frontAbbrv") + tiresize.get("formattedTireSize")
        : "") +
      (tiresize.get("secondaryFormattedTireSize")
        ? this.t("rearAbbrv") + tiresize.get("secondaryFormattedTireSize")
        : "") +
      (!tiresize.get("secondaryFormattedTireSize")
        ? tiresize.get("formattedTireSize")
        : "")
    );
  };

  renderTiresizeButton = (tiresize, trimData, trim) => {
    return (
      <button
        className={this.getTiresizeButtonClass(tiresize)}
        aria-hidden="true"
        tabIndex={getTabIndex()}
        data-dtm={setAdobeLaunchDTM(
          "",
          ADOBELAUNCHER_DTM.TIRESIZE.VEHICLE,
          trim.name.en
        )}
        onClick={this.onTrimClickRefactored.bind(this, trimData)}
      >
        {tiresize.get("secondaryFormattedTireSize") ? (
          <span className="staggered-tiresize front">
            {this.t("frontAbbrv") + tiresize.get("formattedTireSize")}
          </span>
        ) : (
          false
        )}
        {tiresize.get("secondaryFormattedTireSize") ? (
          <span className="staggered-tiresize">
            {this.t("rearAbbrv") + tiresize.get("secondaryFormattedTireSize")}
          </span>
        ) : (
          false
        )}
        {!tiresize.get("secondaryFormattedTireSize")
          ? tiresize.get("formattedTireSize")
          : false}
        {this.enableButtonTag() ? (
          <span
            className={
              tiresize
                .get("tiresizeTypeDescription")
                .get("en")
                .indexOf("Original") > -1
                ? "tag original"
                : "tag"
            }
          >
            {tiresize.get("tiresizeTypeDescription").get(getAppLanguage())}
          </span>
        ) : (
          false
        )}
      </button>
    );
  };

  getTiresizeButtonClass = (tiresize) => {
    return setAdobeLaunchClass(
      Cx({
        btn: true,
        staggered: tiresize.get("secondaryFormattedTireSize"),
        "has-compare": this.getEnableCompare(),
      }),
      ADOBELAUNCHER_CLASS.BUTTON.LINK
    );
  };

  enableButtonTag = () => {
    switch (applicationStore.data.defaultTemplate) {
      case "audi":
      case "volkswagen":
        return false;
      default:
        return true;
    }
  };

  renderQuickCompare = (trimData, trim) => {
    if (this.getEnableCompare()) {
      return (
        <button
          tabIndex={getTabIndex()}
          className={this.getQuickCompareClass()}
          data-dtm={setAdobeLaunchDTM(
            "",
            ADOBELAUNCHER_DTM.TIRESIZE.VEHICLE,
            trim.name.en
          )}
          onClick={this.onTrimClickRefactoredRunQuickCompare.bind(
            this,
            trimData
          )}
        >
          {this.t("comparison.quickCompare")}
        </button>
      );
    } else {
      return false;
    }
  };

  getQuickCompareClass = () => {
    return setAdobeLaunchClass(
      Cx({
        btn: true,
        "quick-compare-section": true,
        french: getAppLanguage() === "fr",
      }),
      ADOBELAUNCHER_CLASS.BUTTON.LINK
    );
  };

  renderSelectDropDownRefactor = (trim) => {
    var trimId = trim.id;
    var selectedTrim = this.getSelectedTrim(trimId);

    var list = [],
      getTiresizeTitle = this.getTiresizeTitle;
    selectedTrim.get("tiresizeArray").map(function (tiresize) {
      list.push({
        key: tiresize.get("vehicleSubmodelTiresizeId"),
        value: getTiresizeTitle(tiresize),
      });
    });

    return (
      <div className={this.getDropDownClass(trimId)}>
        <SelectBox
          dataList={list}
          onSelect={this.onTrimClick}
          datadtm={setAdobeLaunchDTM(
            "",
            ADOBELAUNCHER_DTM.TIRESIZE.VEHICLE,
            trim.name.en
          )}
          rightAlign={true}
        />
      </div>
    );
  };

  getDropDownClass = (trimId) => {
    return Cx({
      "select-trim-button-group": true,
      "tiresize-drop-down": true,
      "drop-down": true,
      "full-trim-list": this.getTiresizeCount(trimId) > 4,
      mobile: this.getTiresizeCount(trimId) <= 4,
    });
  };

  getTiresizeTitle = (tiresize) => {
    return (
      (!isEmpty(tiresize.get("secondaryFormattedTireSize"))
        ? this.t("frontAbbrv") + tiresize.get("formattedTireSize")
        : tiresize.get("formattedTireSize")) +
      (!isEmpty(tiresize.get("secondaryFormattedTireSize"))
        ? " " + this.t("rearAbbrv") + tiresize.get("secondaryFormattedTireSize")
        : "") +
      (!isEmpty(tiresize.get("secondaryFormattedTireSize"))
        ? tiresize.get("formattedTireSize")
        : "") +
      " - " +
      tiresize.get("tiresizeTypeDescription").get(getAppLanguage())
    );
  };

  renderChassisNumber = (trim) => {
    if (trim.chassis) {
      return (
        <span>
          /<span className="trim-chassis">{" " + trim.chassis}</span>
        </span>
      );
    } else {
      return false;
    }
  };

  onTrimClick = (tiresizeId) => {
    var selectedTrim = this.getTrimFromTiresizeId(tiresizeId);

    var trimArray = {
      tiresizeId: tiresizeId,
      trimId: selectedTrim.id,
      trimName: selectedTrim.name,
    };
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Vehicle Select Submodel - SubmodelSelected",
      getTrackingEventSubmodel(trimArray)
    );
    this.props.dispatch(setTireSize(trimArray.tiresizeId));
    // productListActions.setTireSize(trimArray.tiresizeId);
    vehicleSelectionActions.trimSelected(trimArray.trimId);
    breadcrumbActions.pushState(
      RouterStates.TRIM,
      trimArray.trimId,
      {
        en:
          typeof trimArray.trimName.en !== "undefined"
            ? trimArray.trimName.en
            : trimArray.trimName.get("en"),
        fr:
          typeof trimArray.trimName.fr !== "undefined"
            ? trimArray.trimName.fr
            : trimArray.trimName.get("fr"),
      },
      false,
      true
    );
    /*
		googleAnalyticsEnhancedTrackEvent(getTrackingEventCategory(), 'Vehicle Select Submodel - SubmodelSelected', getTrackingEventSubmodel({ trimId: trimId, trimName: trimName }));
		vehicleSelectionActions.trimSelected(trimId);
		breadcrumbActions.pushState(RouterStates.TRIM, trimId, trimName, false, true);
		*/
  };

  onTrimClickRefactored = (trimArray) => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Vehicle Select Submodel - SubmodelSelected",
      getTrackingEventSubmodel(trimArray)
    );
    // this.props.dispatch(setTireSize(trimArray.tiresizeId))
    // productListActions.setTiresize(trimArray.tiresizeId);
    vehicleSelectionActions.trimSelected(trimArray.trimId);
    breadcrumbActions.pushState(
      RouterStates.TRIM,
      trimArray.trimId,
      {
        en:
          typeof trimArray.trimName.en !== "undefined"
            ? trimArray.trimName.en
            : trimArray.trimName.get("en"),
        fr:
          typeof trimArray.trimName.fr !== "undefined"
            ? trimArray.trimName.fr
            : trimArray.trimName.get("fr"),
      },
      false,
      true
    );
    this.props.dispatch(setTireSize(trimArray.tiresizeId));
  };

  onTrimClickRefactoredRunQuickCompare = (trimArray, evt) => {
    googleAnalyticsEnhancedTrackEvent(
      getTrackingEventCategory(),
      "Vehicle Select Submodel - QuickCompareSelected",
      getTrackingEventSubmodel(trimArray)
    );
    productListActions.onRunQuickComparisonOnLoad();
    setTimeout(() => {
      // this.props.tireSize(trimArray.tiresizeId)
      productListActions.setTiresize(trimArray.tiresizeId);
      vehicleSelectionActions.trimSelected(trimArray.trimId);
      breadcrumbActions.pushState(
        RouterStates.TRIM,
        trimArray.trimId,
        {
          en:
            typeof trimArray.trimName.en !== "undefined"
              ? trimArray.trimName.en
              : trimArray.trimName.get("en"),
          fr:
            typeof trimArray.trimName.fr !== "undefined"
              ? trimArray.trimName.fr
              : trimArray.trimName.get("fr"),
        },
        false,
        true
      );
    }, 100);
  };

  onChange = () => {
    this.setState({
      year: applicationStore.data.year,
      make: applicationStore.data.make,
      category: applicationStore.data.category,
      model: applicationStore.data.model,
      trims: vehicleSelectionStore.data.trims,
    });
  };
}

export default connect(null)(VehicleSelectionTrims);

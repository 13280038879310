import { DispatcherActions } from '../constants/DispatcherActions.js';
import { AbstractStore } from './AbstractStore.js';
import { applicationStore } from '../stores/ApplicationStore.js';

import { getBoundsZoomLevel } from '../helpers/GoogleMapsHelper.js';
import { sizeOf, isEmpty, removeFrenchCharacters } from '../service/UtilityService.js';
import { getMapCenterFromLocation, defaultMapLocation } from '../service/DealerSelectionService.js';

var Immutable = require('immutable');
var _ = require('lodash');

var GoogleMapsAPI = window.google.maps;

const DEALER_NAME_SEARCH = "dealer-name-search";
const DEALER_CITY_SEARCH = "dealer-city-search";
const DEALER_PROVINCE_SEARCH = "dealer-province-search";
const DEALER_POSTAL_CODE_SEARCH = "dealer-postal-code-search";

var DealerSelectionStore = AbstractStore.extend({

	constructor: function () {
		DealerSelectionStore.__super__.constructor.apply(this, arguments);
		if (!checkShouldUseLegacyMap) {
			this.dealers = Immutable.List.of();
			this.visibleDealers = Immutable.List.of();
			this.setDealerAsActive = false;
			this.userLocation = null;
			this.userInitialLocationModal = false;
			this.mapCenter = { lat: 43.65, lng: -79.38 }; // Toronto, ON, CA
			this.mapZoom = 8;
			this.dealerSearch = null;
			this.dealerSearchFlag = false;
			this.dealerSearchLocation = null;
			this.dealerSearchIsEmpty = false;
			this.dealerSearchDistance = 0;
			this.dealerSearchShowWarning = false;
			this.enableDealerNameList = true;
			this.searchType = DEALER_CITY_SEARCH;
			this.showLeavingSiteWarningModal = false;
			this.dealerSelectionSelectedDealer = null;
			this.redirectToChassisSearch = {};
      this.dealersFilteredFromPosition = false
		} else {
			this.dealers = Immutable.List.of();
			this.mapBounds = null;
			this.mapZoom = 4;
			this.mapWidth = null; // not inherited ( not used )
			this.windowWidth = window.innerWidth;
			this.mapHeight = 518; //718 originally
			this.activeDealer = null;
			this.userLocation = null;
			this.mapCenter = null;
			this.province = null;
			this.dealerSearch = null;
			this.dealerSearchFlag = false;
			this.searchWasEmptyFlag = false;
			this.searchType = false;
		}
	},

	getActionHandlers: function () {
		var handlers = {};
		if (!checkShouldUseLegacyMap) {
			handlers[DispatcherActions.LIST_OF_DEALERS_LOADED] = this.onListOfDealersLoaded;
			handlers[DispatcherActions.MAP_SET_LOCATION] = this.onSetLocation;
			handlers[DispatcherActions.MAP_SET_USER_LOCATION] = this.onSetUserLocation;
			handlers[DispatcherActions.DEALER_SELECTED] = this.onDealerSelected;
			handlers[DispatcherActions.DEALER_SELECTION_CHANGE_SEARCH] = this.onDealerSelectionChangeSearch;
			handlers[DispatcherActions.DEALER_SELECTION_CHANGE_SEARCH_TYPE] = this.onDealerSelectionChangeSearchType;
			handlers[DispatcherActions.DEALER_SELECTION_SEARCH_IS_EMPTY] = this.onDealerSelectionSearchIsEmpty;
			handlers[DispatcherActions.DEALER_SELECTION_RESET_EMPTY_SEARCH] = this.onResetEmptyDealerSelectionSearch;
			handlers[DispatcherActions.DEALER_SELECTION_SET_DISTANCE] = this.onSetDealerDistance;
			handlers[DispatcherActions.DEALER_SELECTION_RESET_DEALER_SEARCH] = this.onResetDealerSearch;
			handlers[DispatcherActions.DEALER_SELECTION_CLOSE_DEALER_NAME_SEARCH_LIST] = this.onCloseDealerNameSearchList;
			handlers[DispatcherActions.DEALER_SELECTION_CLOSE_INITIAL_LOCATION_MODAL] = this.onCloseInitialUserLocationModal;
			handlers[DispatcherActions.DEALER_SELECTION_FILTER_DEALERS_POSITION] = this.filterDealersPosition;
			handlers[DispatcherActions.DEALER_SELECTION_SHOW_WARNING_MODAL] = this.onShowLeavingSiteWarningModal;
			handlers[DispatcherActions.DEALER_SELECTION_CLOSE_WARNING_MODAL] = this.onCloseLeavingSiteWarningModal;
			handlers[DispatcherActions.DEALER_SELECTION_CHASSIS_SEARCH_FROM_URL] = this.onChassisSearchFromUrl;
		} else {
			handlers[DispatcherActions.LIST_OF_DEALERS_LOADED] = this.onListOfDealersLoaded;
			handlers[DispatcherActions.DEALER_NAME_SEARCHED] = this.onDealerNameSearched;
			handlers[DispatcherActions.DEALER_INDEX_SEARCHED] = this.onDealerIndexSearched;
			handlers[DispatcherActions.DEALER_CITY_SEARCHED] = this.onDealerCitySearched;
			handlers[DispatcherActions.DEALER_NAME_RESET] = this.onDealerNameReset;
			handlers[DispatcherActions.CLICKED_ON_DEALER] = this.onClickedOnDealer;
			handlers[DispatcherActions.LOCATION_CHANGED] = this.onLocationChanged;
			handlers[DispatcherActions.MAP_CENTER_CHANGED] = this.onMapCenterChanged;
			handlers[DispatcherActions.MAP_ZOOM_CHANGED] = this.onMapZoomChanged;
			handlers[DispatcherActions.MAP_BOUNDS_CHANGED] = this.onMapBoundsChanged;
			handlers[DispatcherActions.MAP_SET_USER_LOCATION] = this.onSetUserLocation;
			handlers[DispatcherActions.MAP_FILTER_PROVINCE_SELECTED] = this.onMapFilterProvinceSelected;
			handlers[DispatcherActions.DEALER_SELECTED] = this.onDealerSelected;
			handlers[DispatcherActions.DEALER_UNSELECTED] = this.onDealerUnselected;
			handlers[DispatcherActions.MAP_UPDATE_WINDOW_WIDTH] = this.onUpdateWindowWidth;
		}
		return handlers;
	},

	onDealerSelected: function () {
		this.mapCenter = this.userLocation;
		this.dealerSearch = null;
		this.dealerSearchFlag = false;
		this.dealerSearchLocation = null;
		this.dealerSearchIsEmpty = false;
		this.dealerSearchShowWarning = false;
		this.enableDealerNameList = false;
		this.mapZoom = 8;
		this.searchType = DEALER_CITY_SEARCH;
		this.emitChange();
	},

	onDealerUnselected: function() {
		this.activeDealer = null;
		this.emitChange();
	},

	onDealerNameSearched: function(search) {
		this.province = null;
		this.activeDealer = null;
		this.dealerSearch = search[0].value;
		this.dealerSearchFlag = true;
		this.searchType = DEALER_NAME_SEARCH;
		this.getFilteredDealers();
		this.emitChange();
	},

	onDealerNameReset: function() {
		this.dealerSearch = null;
		this.activeDealer = null;
		this.searchType = false;
		this.dealerSearchFlag = false;
		this.enableDealerNameList = true;
		this.getFilteredDealers();
		this.emitChange();
	},

	onDealerCitySearched: function(search) {
		this.province = null;
		this.activeDealer = null;
		this.dealerSearch = search[0].value;
		this.dealerSearchFlag = true;
		this.searchType = DEALER_CITY_SEARCH;
		this.getFilteredDealers();
		this.emitChange();
	},

	onDealerCityReset: function() {
		this.dealerSearch = null;
		this.activeDealer = null;
		this.searchType = false;
		this.enableDealerNameList = false;
		this.getFilteredDealers();
		this.emitChange();
	},

	onCloseDealerNameSearchList: function() {
		this.enableDealerNameList = false;
		this.emitChange();
	},

	onClickedOnDealer: function(payload) {
		if (this.activeDealer !== null && payload.dealer.get('id') == this.activeDealer.get('id')) this.activeDealer = null;
		else this.activeDealer = payload.dealer;
		this.emitChange();
	},

	onShowLeavingSiteWarningModal: function(dealer) {
		this.showLeavingSiteWarningModal = true;
		this.dealerSelectionSelectedDealer = dealer;
    this.emitChange();
	},

  onCloseLeavingSiteWarningModal: function() {
    this.showLeavingSiteWarningModal = false;
    this.dealerSelectionSelectedDealer = null;
    this.emitChange();
  },

  onChassisSearchFromUrl: function(chassisObject) {
		this.redirectToChassisSearch = chassisObject;
    this.emitChange();
	},

	onDealerIndexSearched: function(search) {
    var dealers
		if (search) {
			dealers = this.dealers.filter(function (dealer) {
				return dealer.get('name') === search
			});
			this.activeDealer = dealers.get(0);
		}
		this.visibleDealers = dealers;
		this.emitChange();
	},

	onMapFilterProvinceSelected: function(province) {
		this.activeDealer = null;
		this.dealerSearch = null;
		this.province = province;
		this.dealerSearchFlag = true;
		this.getFilteredDealers();
		this.emitChange();
	},

	onLocationChanged: function(payload) {
		this.mapCenter = payload.location;
		this.mapZoom = getBoundsZoomLevel(payload.viewport, {
			width: this.mapWidth,
			height: this.mapHeight
		});
		this.emitChange();
	},

	onMapCenterChanged: function(mapCenter) {
		this.mapCenter = mapCenter;
		this.emitChange();
	},

	onMapZoomChanged: function(mapZoom) {
		this.mapZoom = mapZoom;
		this.emitChange();
	},

	onMapBoundsChanged: function(mapBounds) {
		this.mapBounds = mapBounds;
		this.emitChange();
	},

	onUpdateWindowWidth: function() {
		this.windowWidth = window.innerWidth;
		this.emitChange();
	},

	onCloseInitialUserLocationModal: function() {
		this.userInitialLocationModal = false;
		this.emitChange();
	},

	onDealerSelectionChangeSearch: function(search) {
		if (this.searchType === DEALER_CITY_SEARCH) {
			var maps = window.google.maps;
			var geocoder = new maps.Geocoder(), location = null;
			var _this = this;
			if (geocoder && search) {
				var countryText = '', countryTextArray = [];
				var countryCode = applicationStore.data.nationalSite ? applicationStore.data.nationalCountryCode : applicationStore.data.groupCountryCode;
				switch (countryCode) {
					case "CA":
						countryText = "Canada";
						countryTextArray = ["Canada"];
						break;
					case "US":
						countryText = "USA";
						countryTextArray = ["United States of America", "United States", "USA", "US"];
						break;
				}

				var countryIsMissing = true;
				for (var i = 0; i < countryTextArray.length; i++) {
					if (search.toLowerCase().indexOf(countryTextArray[i].toLowerCase()) > -1) { countryIsMissing = false; }
				}

				if (countryIsMissing) { search = search + ", " + countryText; }

				geocoder.geocode({'address': search}, function (results, status) {
					_this.dealerSearch = search;
					_this.dealerSearchFlag = true;
					_this.dealerSearchLocation = null;
					_this.dealerSearchIsEmpty = false;
					_this.dealerSearchShowWarning = false;

					if (status === maps.GeocoderStatus.OK) {
						// find first option with matching country
						var resultLocation = null;
						if (results.length > 1) {
							for (var i = 0; i < results.length; i++) {
								var split = results[i].formatted_address.split(", ");
								if (_.contains(split, countryText)) {
									resultLocation = results[i];
									break;
								}
							}
						} else {
							var split = results[0].formatted_address.split(", ");
							if (_.contains(split, countryText)) {
								resultLocation = results[0];
							}
						}

						if (resultLocation) {
							_this.userInitialLocationModal = false;
							_this.filterDealersFromPosition(resultLocation, false);
              _this.emitChange();
						} else {
							_this.dealerSearchShowWarning = true;
							_this.getFilteredDealers();
							_this.emitChange();
						}
					} else {
						_this.dealerSearchShowWarning = true;
						_this.emitChange();
					}
				});
			}
		} else if (this.searchType === DEALER_NAME_SEARCH) {
			this.dealerSearch = search;
			this.dealerSearchFlag = true;
			this.dealerSearchIsEmpty = false;
			this.dealerSearchShowWarning = false;
			this.userInitialLocationModal = false;
			this.enableDealerNameList = false;
			this.getFilteredDealers();
			this.emitChange();
		}
	},

	filterDealersPosition: function(position) {
		if (position) {
			var location = null, _this = this;
			location = {
				lat: position.lat,
				lng: position.lng
			};
			this.dealerSearchLocation = location;
			this.mapZoom = 7;

			//recalculate dealer distances
			this.dealers = this.dealers.map(function (dealer) {
				return dealer.set("distance", _this.getDistance(_this.dealerSearchLocation, dealer.get('position')));
			});

			this.getFilteredDealers();
		}
		this.emitChange();
	},

	filterDealersFromPosition: function(position, skipConvert) {
		var location = null, _this = this;
		if (!skipConvert) {
			location = {
				lat: position.geometry.location.lat(),
				lng: position.geometry.location.lng()
			};
			this.dealerSearchLocation = location;
			switch (position.types[0].toString()) {
				case "locality":
					this.mapZoom = 9;
					break;
				case "sublocality":
					this.mapZoom = 8;
					break;
				case "political":
					this.mapZoom = 9;
					break;
				case "postal_code":
					this.mapZoom = 10;
					break;
				case "country":
					this.mapZoom = 4;
					break;
				case "administrative_area_level_1":
					this.mapZoom = applicationStore.data.nationalCountryCode === "US" ? 7 : 5;
					break;
				case "administrative_area_level_2":
					this.mapZoom = applicationStore.data.nationalCountryCode === "US" ? 8 : 6;
					break;
				default:
					this.mapZoom = 7;
					break;
			}
		} else {
			this.mapZoom = 9;
		}

		//recalculate dealer distances
		this.dealers = this.dealers.map(function(dealer) {
			return dealer.set("distance", _this.getDistance(location, dealer.get('position')));
		});

		this.getFilteredDealers(true);
		this.emitChange();
	},

	onResetDealerSearch: function() {
		this.dealerSearch = null;
		this.dealerSearchFlag = false;
		this.dealerSearchLocation = null;
		this.dealerSearchIsEmpty = false;
		this.dealerSearchShowWarning = false;
		this.enableDealerNameList = false;
		this.getFilteredDealers();
		this.emitChange();
	},

	onDealerSelectionChangeSearchType: function(searchType) {
		this.dealerSearch = null;
		this.dealerSearchFlag = false;
		this.dealerSearchShowWarning = false;
		this.searchType = searchType;
		if (searchType === DEALER_NAME_SEARCH) {
			this.enableDealerNameList = true;
		} else {
			this.enableDealerNameList = false;
		}
		this.emitChange();
	},

	onDealerSelectionSearchIsEmpty: function() {
		this.dealerSearchIsEmpty = true;
		this.emitChange();
	},

	onSetDealerDistance: function(distance) {
		this.dealerSearchDistance = distance;
		this.getFilteredDealers();
		this.emitChange();
	},

	onResetEmptyDealerSelectionSearch: function() {
		this.dealerSearch = null;
		this.dealerSearchFlag = false;
		this.dealerSearchLocation = null;
		this.dealerSearchIsEmpty = false;
		this.dealerSearchShowWarning = false;
		this.enableDealerNameList = false;
		this.emitChange();
	},

	onListOfDealersLoaded: function (listOfDealers) {
		var startingLocation = this.userLocation, _this = this;
		if (startingLocation === null && isEmpty(this.dealerSearch)) {
			//startingLocation = this.mapCenter;
			this.userInitialLocationModal = true;
			this.dealerSearch = null;
			this.dealerSearchFlag = false;
			this.dealerSearchLocation = null;
			this.dealerSearchIsEmpty = false;
			this.dealerSearchShowWarning = false;
			this.enableDealerNameList = true;
		}

		this.visibleDealers = Immutable.List.of();
		this.dealers = Immutable
			.fromJS(listOfDealers)
			.sort(this.sortByName)
			.map(function (dealer) {
				var position = new GoogleMapsAPI.LatLng(parseFloat(dealer.get('dealerLatitude')), parseFloat(dealer.get('dealerLongitude')));
				return Immutable.Map({
					id: dealer.get('dealerId'),
					name: dealer.get('dealerName'),
					alias: dealer.get('dealerNumberAlias'),
					urlCode: dealer.get('dealerUrlCode'),
					address: dealer.get('dealerAddress'),
					city: dealer.get('dealerCity'),
					postalCode: dealer.get('dealerPostalCode'),
					province: dealer.get('dealerState'),
					country: dealer.get('dealerCountry'),
					phone: dealer.get('dealerContactPhone'),
					email: dealer.get('dealerContactEmail'),
					website: dealer.get('dealerWebsiteUrl'),
					hours: dealer.get('dealerHours'),
					season: dealer.get('dealerDefaultSearchSeason'),
					googleMapsUrl: dealer.get('dealerRetailSiteGoogleMapsExternalUrl'),
					inactiveDealerLink: dealer.get('dealerMapExitLink'),
					hasNoPricing: dealer.get('dealerShowNoPricingOnNationalMap'),
					nationalAccountId: dealer.get('nationalAccountId'),
					isActive: dealer.get('dealerIsActive'),
          enableECommerce: dealer.get('dealerEnableECommerce'),
          enableFinancing: dealer.get('dealerEnableFinancing'),
					position: position,
					distance: startingLocation === null ? null : _this.getDistance(startingLocation, position)
				});
			}.bind(this));

		this.getFilteredDealers();

		this.emitChange();
	},

	sortByName: function(a, b) {
		if (a.get('dealerName') < b.get('dealerName')) return -1;
		else if (a.get('dealerName') > b.get('dealerName')) return 1;
		else return 0;
	},

	getRadian: function(x) {
		return x * Math.PI / 180;
	},

	getDistance: function(p1, p2) {
		var R = 6378137; // Earth’s mean radius in meter
		var dLat = this.getRadian(p2.lat() - p1.lat);
		var dLong = this.getRadian(p2.lng() - p1.lng);
		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.getRadian(p1.lat)) * Math.cos(this.getRadian(p2.lat())) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return parseFloat(d / 1000).toFixed(0); // returns the distance in kilometers
	},

	getFilteredDealers: function (setFlag = false) {
		if (!checkShouldUseLegacyMap) {
			this.getFilteredDealersRefactor(setFlag);
		} else {
			this.getFilteredDealersLegacy();
		}
	},

	getFilteredDealersRefactor: function (setFlag) {
		var dealers = this.dealers, search = this.dealerSearch, dealerMaxDistance = this.dealerSearchDistance;
		if (sizeOf(dealers) > 0) {
      if (this.searchType === DEALER_CITY_SEARCH) {
        dealers = dealers.filter(function (dealer) {
          return dealer.get('distance') <= dealerMaxDistance || dealerMaxDistance === 0
        });
        if (sizeOf(dealers) === 0) {
          this.dealerSearchIsEmpty = true;
        }
      } else if (this.searchType === DEALER_NAME_SEARCH && search !== null && search !== "") {
        dealers = dealers.filter(function (dealer) {
          return dealer.get('name').toLowerCase().indexOf(search.toLowerCase()) > -1
        });
        if (sizeOf(dealers) === 0) {
          this.dealerSearchIsEmpty = true;
        }
      }
      this.visibleDealers = dealers;
      if (sizeOf(dealers) === 1) this.setDealerAsActive = true;
      else this.setDealerAsActive = false;

      if (setFlag) this.dealersFilteredFromPosition = true;
    }
		this.emitChange();
	},

	getFilteredDealersLegacy: function() {
		var dealers = null;
		var overwriteZoom = false;
		if (applicationStore.data.defaultTemplate == "lexus" && (this.province == "NL" || this.province == "NT" || this.province == "NU" || this.province == "PE" || this.province == "YT")) {

			switch (this.province) {
				case "NL":
					dealers = this.dealers.filter(dealer => dealer.get('id') == 1144 || dealer.get('id') == 10615 || dealer.get('id') == 10595 || dealer.get('id') == 10621);
					break;
				case "NT":
					overwriteZoom = true;
					dealers = this.dealers.filter(dealer => dealer.get('id') == 10614 || dealer.get('id') == 10597 || dealer.get('id') == 10608 || dealer.get('id') == 10617);
					break;
				case "NU":
					dealers = this.dealers.filter(dealer => dealer.get('id') == 10608 || dealer.get('id') == 10597 || dealer.get('id') == 1552 || dealer.get('id') == 1553);
					break;
				case "PE":
					dealers = this.dealers.filter(dealer => dealer.get('id') == 1144 || dealer.get('id') == 10615 || dealer.get('id') == 10595 || dealer.get('id') == 10621);
					break;
				case "YT":
					overwriteZoom = true;
					dealers = this.dealers.filter(dealer => dealer.get('id') == 10600 || dealer.get('id') == 10619 || dealer.get('id') == 10624 || dealer.get('id') == 10620);
					break;
				default:
					break;
			}

			if (this.dealerSearchFlag) {
				var bounds = new google.maps.LatLngBounds();
				dealers.forEach(function (dealer) {
					bounds.extend(dealer.get('position'));
				});

				if (this.province !== null || this.searchType == DEALER_CITY_SEARCH) {
					this.mapZoom = 5;
					if (bounds.H && bounds.j && !overwriteZoom) {
						var latRange = bounds.H;
						var lngRange = bounds.j;
						var latDiff = Math.abs(parseInt(latRange.H, 10) - parseInt(latRange.j, 10));
						var lngDiff = Math.abs(parseInt(lngRange.H, 10) - parseInt(lngRange.j, 10));
						var constraint = (latDiff > lngDiff) ? latDiff : lngDiff;

						if (this.dealerSearch != null) {
							if (constraint == 0) this.mapZoom = 10;//(latDiff > lngDiff) ? 8 : 8;
							else if (constraint < 10) this.mapZoom = 9;//(latDiff > lngDiff) ? 7 : 7;
							else if (constraint < 30) this.mapZoom = 8;//(latDiff > lngDiff) ? 6 : 6;
							else if (constraint < 50) this.mapZoom = 7;//(latDiff > lngDiff) ? 5 : 5;
							else if (constraint < 70) this.mapZoom = 6;//(latDiff > lngDiff) ? 4 : 4;
							else this.mapZoom = 5;//(latDiff > lngDiff) ? 3 : 3;
						} else {
							if (constraint == 0) this.mapZoom = (latDiff > lngDiff) ? 8 : 8;
							else if (constraint < 10) this.mapZoom = (latDiff > lngDiff) ? 7 : 7;
							else if (constraint < 30) this.mapZoom = (latDiff > lngDiff) ? 6 : 6;
							else if (constraint < 50) this.mapZoom = (latDiff > lngDiff) ? 5 : 5;
							else if (constraint < 70) this.mapZoom = (latDiff > lngDiff) ? 4 : 4;
							else this.mapZoom = (latDiff > lngDiff) ? 3 : 3;
						}
					} else {
						switch (this.province) {
							case "NL":
								break;
							case "NT":
								this.mapZoom = 7;
								break;
							case "NU":
								break;
							case "PE":
								break;
							case "YT":
								this.mapZoom = 8;
								break;
							default:
								break;
						}
					}
				}

				if (this.province !== null || this.searchType == DEALER_CITY_SEARCH) this.mapCenter = bounds.getCenter();

				this.dealerSearchFlag = false;
			}

		} else {
			if (sizeOf(this.dealers) < 150) {
				if (this.province && this.dealerSearch) {
					dealers = this.dealers.filter(function (dealer) {
						var search = removeFrenchCharacters(this.dealerSearch.toUpperCase());
						if (this.searchType == DEALER_NAME_SEARCH) {
							var name = removeFrenchCharacters(dealer.get('name').toUpperCase());
							return this.province == dealer.get('province') && (name.indexOf(search) > -1 || search == '');
						} else if (this.searchType == DEALER_CITY_SEARCH) {
							var city = removeFrenchCharacters(dealer.get('city').toUpperCase());
							return this.province == dealer.get('province') && (city.indexOf(search) > -1 || search == '');
						}
					}.bind(this));
				} else if (this.province) {
					dealers = this.dealers.filter(function (dealer) {
						return this.province == dealer.get('province');
					}.bind(this));
				} else if (this.dealerSearch) {
					dealers = this.dealers.filter(function (dealer) {
						var search = removeFrenchCharacters(this.dealerSearch.toUpperCase());
						if (this.searchType == DEALER_NAME_SEARCH) {
							var name = removeFrenchCharacters(dealer.get('name').toUpperCase());
							return name.indexOf(search) > -1 || search == '';
						} else if (this.searchType == DEALER_CITY_SEARCH) {
							var city = removeFrenchCharacters(dealer.get('city').toUpperCase());
							return city.indexOf(search) > -1 || search == '';
						}
					}.bind(this));
				} else {
					dealers = this.dealers;
				}
			} else {
				if (this.province && this.dealerSearch) {
					dealers = this.dealers.filter(function (dealer) {
						var search = removeFrenchCharacters(this.dealerSearch.toUpperCase());
						if (this.searchType == DEALER_NAME_SEARCH) {
							var name = removeFrenchCharacters(dealer.get('name').toUpperCase());
							return this.province == dealer.get('province') && (name.indexOf(search) > -1 || search == '');
						} else if (this.searchType == DEALER_CITY_SEARCH) {
							var city = removeFrenchCharacters(dealer.get('city').toUpperCase());
							return this.province == dealer.get('province') && (city.indexOf(search) > -1 || search == '');
						}
					}.bind(this));
				} else if (this.province) {
					dealers = this.dealers.filter(function (dealer) {
						return this.province == dealer.get('province');
					}.bind(this));
				} else if (this.dealerSearch) {
					dealers = this.dealers.filter(function (dealer) {
						var search = removeFrenchCharacters(this.dealerSearch.toUpperCase());
						if (this.searchType == DEALER_NAME_SEARCH) {
							var name = removeFrenchCharacters(dealer.get('name').toUpperCase());
							return name.indexOf(search) > -1 || search == '';
						} else if (this.searchType == DEALER_CITY_SEARCH) {
							var city = removeFrenchCharacters(dealer.get('city').toUpperCase());
							return city.indexOf(search) > -1 || search == '';
						}
					}.bind(this));
				} else {
					dealers = this.dealers.filter(function (dealer) {
						return this.mapBounds.contains(dealer.get('position'));
					}.bind(this));
				}
			}

			this.searchWasEmptyFlag = false;

			if (dealers && (this.dealerSearch !== null || this.province !== null) && sizeOf(dealers) === 1 && this.dealerSearchFlag) {

				// single result found
				//set as active dealer
				this.activeDealer = dealers.get(0);
				this.mapCenter = dealers.get(0).get('position');
				this.mapZoom = 8;
				this.dealerSearchFlag = false;

			} else if (dealers && sizeOf(dealers) > 1 && (this.dealerSearch !== null || this.province !== null) && this.dealerSearchFlag) {

				// multiple results found
				var bounds = new google.maps.LatLngBounds();

				dealers.forEach(function (dealer) {
					bounds.extend(dealer.get('position'));
				});

				if (this.province !== null || this.searchType == DEALER_CITY_SEARCH) {
					this.mapZoom = 4;
					if (bounds.H && bounds.j) {
						var latRange = bounds.H;
						var lngRange = bounds.j;
						var latDiff = Math.abs(parseInt(latRange.H, 10) - parseInt(latRange.j, 10));
						var lngDiff = Math.abs(parseInt(lngRange.H, 10) - parseInt(lngRange.j, 10));
						var constraint = (latDiff > lngDiff) ? latDiff : lngDiff;
						if (this.dealerSearch != null) {
							if (constraint == 0) this.mapZoom = 10;//(latDiff > lngDiff) ? 8 : 8;
							else if (constraint < 10) this.mapZoom = 9;//(latDiff > lngDiff) ? 7 : 7;
							else if (constraint < 30) this.mapZoom = 8;//(latDiff > lngDiff) ? 6 : 6;
							else if (constraint < 50) this.mapZoom = 7;//(latDiff > lngDiff) ? 5 : 5;
							else if (constraint < 70) this.mapZoom = 6;//(latDiff > lngDiff) ? 4 : 4;
							else this.mapZoom = 5;//(latDiff > lngDiff) ? 3 : 3;
						} else {
							if (constraint == 0) this.mapZoom = (latDiff > lngDiff) ? 8 : 8;
							else if (constraint < 10) this.mapZoom = (latDiff > lngDiff) ? 7 : 7;
							else if (constraint < 30) this.mapZoom = (latDiff > lngDiff) ? 6 : 6;
							else if (constraint < 50) this.mapZoom = (latDiff > lngDiff) ? 5 : 5;
							else if (constraint < 70) this.mapZoom = (latDiff > lngDiff) ? 4 : 4;
							else this.mapZoom = (latDiff > lngDiff) ? 3 : 3;
						}
					}
				}

				if (this.province !== null || this.searchType == DEALER_CITY_SEARCH) this.mapCenter = bounds.getCenter();

				this.dealerSearchFlag = false;

			} else if (this.province !== null && sizeOf(dealers) === 0 && this.dealerSearchFlag) {

				// no results found province search
				var country = applicationStore.data.nationalCountryCode ? applicationStore.data.nationalCountryCode : null;
				if (country !== null) {
					this.mapCenter = getMapCenterFromLocation(country, this.province);
					switch (this.province) {
						case "PE":
							this.mapZoom = 6;
							break;
						default:
							this.mapZoom = 4;
							break;
					}
				}
				dealers = this.dealers;
				this.province = null;
				this.dealerSearchFlag = false;

			} else if (this.dealerSearch !== null && sizeOf(dealers) === 0 && this.dealerSearchFlag) {

				// no results found dealer search
				this.searchWasEmptyFlag = true;
				this.dealerSearchFlag = false;

			}
		}

		this.visibleDealers = dealers;
		this.emitChange();
	},

	onSetLocation: function (dataArray) {
		this.mapCenter = dataArray.mapCenter;
		this.mapZoom = dataArray.mapZoom;
		this.emitChange();
	},

	onSetUserLocation: function (dataArray) {
		this.userLocation = dataArray.userLocation;
		this.dealerSearchLocation = dataArray.userLocation;
		this.mapCenter = dataArray.mapCenter;
		this.filterDealersPosition();
		this.emitChange();
	}
});

export var dealerSelectionStore = new DealerSelectionStore();

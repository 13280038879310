var React = require("react");
var ReactDOM = require("react-dom");
var $ = require("jquery");

export class Popover extends React.Component {
  /**
   * justShowed state is necessary to avoid onClickDocument right after document is showed using any click event
   */
  state = {
    justShowed: false,
  };

  static defaultProps = {
    show: false,
    onClose: function () {
      throw new Error("Provide onClose callback");
    },
  };

  componentDidMount() {
    $(document).click(this.onClickDocument);
  }

  componentWillUnmount() {
    $(document).unbind("click", this.onClickDocument);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show) {
      this.setState({
        justShowed: true,
      });
    }
  }

  render() {
    if (this.props.show) {
      return <div>{this.props.children}</div>;
    } else {
      return false;
    }
  }

  onClickDocument = (ev) => {
    if (this.state.justShowed) {
      this.setState({
        justShowed: false,
      });
    } else {
      if (this.props.show && !ReactDOM.findDOMNode(this).contains(ev.target)) {
        this.props.onClose();
      }
    }
  };
}

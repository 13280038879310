import { I18nComponent } from "../../i18n/I18nComponent.jsx";
import { applicationStore } from "../../stores/ApplicationStore.js";
import { homepageStore } from "../../stores/HomepageStore.js";
import { languageStore } from "../../stores/LanguageStore.js";

var React = require("react");

export class ContactDealer extends I18nComponent {
  state = {
    dealerInfo: applicationStore.data.dealerInfo,
  };

  componentWillMount() {
    applicationStore.addChangeListener(this.onChange);
    homepageStore.addChangeListener(this.onChange);
    languageStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    applicationStore.removeChangeListener(this.onChange);
    homepageStore.removeChangeListener(this.onChange);
    languageStore.removeChangeListener(this.onChange);
  }

  render() {
    return (
      <div>
        <span>{"Dealer Contact Info"}</span>
      </div>
    );
  }

  onChange = () => {
    this.setState({
      dealerInfo: applicationStore.data.dealerInfo,
    });
  };
}
